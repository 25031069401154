'use strict';

var $ = require('jquery');
var app = require('../../app');
var sessionMgmtTpl = require('./session-management.hbs');
var platform = require('./../../../platform/platform');

app.component('SessionManagement', {
    className: 'full-height',
    template: sessionMgmtTpl,

    getAccessKey: function() {
        return {
            'roleName': 'vMeeting',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.sessionMgmtService = this.getService('SessionManagementService');
        this.authService = this.getService('AuthenticationService');
        this.remote = this.getService('RemoteService');
        this.powerService = this.getService('PowerService');
        this.init = true;
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindDOMEvents();
        this.bindEvents();
    },

    serialize: function() {
        return {
            darkUi: this.sessionMgmtService.darkUi
        };
    },

    bindEvents: function() {
        this.on('session-mgmt.update', this.updateSessionMgmt.bind(this));
        this.on('session-mgmt.meeting-list.focus.changed', this.updateSessionMgmtButtons.bind(this));
        this.on('session-mgmt.meeting-check-in.open', this.createCheckInComponent.bind(this));
        this.on('session-mgmt.meeting-ad-hoc.open', this.createAdHocComponent.bind(this));
        this.on('session-mgmt.meeting-check-in.closed', this.onCheckInClosed.bind(this));
        this.on('session-mgmt.meeting-ad-hoc.closed', this.onAdHocClosed.bind(this));
        this.on('session-mgmt.close', this.close.bind(this));
        this.on('session-mgmt.open', this.open.bind(this));
        this.on('session-mgmt.update-ui', this.updateUi.bind(this));
        this.on('session-mgmt.update-wallpaper', this.setWallpaper.bind(this));
        this.on('modal.closed', this.focusRemoteArea.bind(this));
        this.on('modal.opened', this.unfocusRemoteArea.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.session-management-btn', this.onAction.bind(this));
    },

    storeSelectors: function() {
        this.$container = this.$el.find('#session-management');
        this.$meetingListContainer = this.$el.find('#session-management-meeting-list');
        this.$nowButton = this.$el.find('#session-management-now');
        this.$downButton = this.$el.find('#session-management-down');
        this.$upButton = this.$el.find('#session-management-up');
        this.$timelineContainer = this.$el.find('#session-management-timeline');
        this.$checkInContainer = this.$el.find('#session-management-check-in');
        this.$adHocContainer = this.$el.find('#session-management-ad-hoc');
        this.$adminButton = this.$el.find('#session-management-admin-login');
        this.$endPresentationButton = this.$el.find('#session-management-end-presentation');
        this.$statusBarContainer = this.$el.find('#session-management-status-bar-container');
    },

    /**
     * Create status bar for vMeeting. Remove when it already exists.
     */
    createStatusBar: function() {
        app.removeComponent('statusBarVMeeting');
        app.createComponent('statusBarVMeeting', {
            type: 'StatusBar',
            container: '#session-management-status-bar-container',
            vMeeting: true
        });
    },

    /**
     * Show new wallpaper.
     *
     * @param wallpaper
     */
    setWallpaper: function(wallpaper) {
        this.$el.parent().css('background-image', wallpaper);
    },

    /**
     * Update ui theme light/dark.
     */
    updateUi: function() {
        this.createStatusBar();

        if (this.sessionMgmtService.showStatusBar) {
            this.$container.removeClass('no-status-bar');
        } else {
            this.$container.addClass('no-status-bar');
        }

        if (this.sessionMgmtService.darkUi) {
            this.$container.addClass('darkUi');
            this.$downButton.addClass('darkUi');
            this.$upButton.addClass('darkUi');
            this.$adminButton.addClass('darkUi');
            this.$endPresentationButton.addClass('darkUi');
        } else {
            this.$container.removeClass('darkUi');
            this.$downButton.removeClass('darkUi');
            this.$upButton.removeClass('darkUi');
            this.$adminButton.removeClass('darkUi');
            this.$endPresentationButton.removeClass('darkUi');
        }

        switch (this.sessionMgmtService.wallpaper) {
            case 'wvdark':
                this.$container.removeClass('custom');
                this.$container.addClass('wvdark');
                break;
            case 'wvlight':
                this.$container.removeClass('custom');
                this.$container.removeClass('wvdark');
                break;
            case 'custom':
                this.$container.removeClass('wvdark');
                this.$container.addClass('custom');
                break;
        }
    },

    /**
     * Create Check-In overlay when 'Check-In'-button is pressed.
     */
    createCheckInComponent: function(meeting) {
        this.handleStatusBar(false);
        this.remote.unfocus(this.$container);
        this.remote.destroyArea(this.$container);

        app.removeComponent('SessionMgmtCheckIn');
        app.createComponent('SessionMgmtCheckIn', {
            type: 'CheckIn',
            container: this.$checkInContainer,
            meeting: meeting
        });

        this.$meetingListContainer
            .addClass('slide-up')
            .find('.current')
            .addClass('slide-up');
        this.$el.find('.session-management-btn-container').addClass('slide-up');
        this.$timelineContainer.hide();
    },

    /**
     * Create Ad-Hoc overlay when 'Start Ad Hoc Meeting'-button is pressed.
     */
    createAdHocComponent: function() {
        this.handleStatusBar(false);
        this.remote.unfocus(this.$container);
        this.remote.destroyArea(this.$container);

        app.removeComponent('SessionMgmtAdHoc');
        app.createComponent('SessionMgmtAdHoc', {
            type: 'AdHoc',
            container: this.$adHocContainer
        });
    },

    /**
     * Create Session Management components.
     */
    createComponents: function() {
        app.removeComponent('SessionMgmtMeetingList');
        app.createComponent('SessionMgmtMeetingList', {
            type: 'MeetingList',
            container: this.$meetingListContainer
        });

        app.removeComponent('SessionMgmtTimeline');
        app.createComponent('SessionMgmtTimeline', {
            type: 'Timeline',
            container: this.$timelineContainer
        });
    },

    /**
     * Update Session Management if there are some new meetings etc.
     */
    updateSessionMgmt: function(meetingList) {
        this.createComponents();
        this.updateSessionMgmtButtons(meetingList, 2);
    },

    /**
     * Handle action buttons.
     *
     * @param event
     */
    onAction: function(event) {
        var $el = this.$(event.currentTarget);
        var action = $el.data('action');

        switch (action) {
            case 'now':
            case 'up':
            case 'down':
                this.emit('session-mgmt.meeting-list.scroll', action);
                break;
            case 'admin-login':
                var role = {};
                role.name = 'vMeeting';
                role.key = 'admin';

                this.authService.checkAccess(role, function() {
                    app.emit('session-mgmt.close', true);
                }.bind(this), function() {
                    this.emit('modal.close');
                }.bind(this));
                break;
            case 'end-presentation':
                this.powerService.openEndPresentationModal();
                break;
        }
    },

    /**
     * Handle if Check-In overlay closed.
     */
    onCheckInClosed: function() {
        app.removeComponent('SessionMgmtCheckIn');

        this.$meetingListContainer
            .removeClass('slide-up')
            .find('.current')
            .removeClass('slide-up');
        this.$el.find('.session-management-btn-container').removeClass('slide-up');
        this.$timelineContainer.show();

        this.handleStatusBar(true);
        this.focusRemoteArea();
    },

    /**
     * Handle if Check-In overlay closed.
     */
    onAdHocClosed: function() {
        app.removeComponent('SessionMgmtAdHoc');

        this.handleStatusBar(true);
        this.focusRemoteArea();
    },

    /**
     * Hide or show status bar when ad hoc or check-in component is opened.
     * @param show
     */
    handleStatusBar: function(show) {
        if (this.sessionMgmtService.showStatusBar && show) {
            this.$statusBarContainer.show();
            this.$container.removeClass('no-status-bar');
        } else {
            this.$statusBarContainer.hide();
            this.$container.addClass('no-status-bar');
        }
    },

    /**
     * Update Session Management Buttons (Now, Arrows)
     */
    updateSessionMgmtButtons: function(meetingList) {
        var focusedIndex = this.sessionMgmtService.getFocusedMeetingIndex();
        var visibleMeetings = $.grep(meetingList, function(m) {
            return !m.hidden;
        });

        if ((meetingList && meetingList.length <= visibleMeetings.length) || focusedIndex === -1) {
            this.$downButton.addClass('hidden');
            this.$upButton.addClass('hidden');
            this.$nowButton.addClass('hidden');
        } else {
            this.$downButton.removeClass('hidden');
            this.$upButton.removeClass('hidden');
            this.$nowButton.removeClass('hidden');
        }

        if (focusedIndex <= 0) {
            this.$nowButton.addClass('hidden');
            this.$upButton.addClass('is-disabled');
        } else {
            this.$nowButton.removeClass('hidden');
            this.$upButton.removeClass('is-disabled');
        }

        if (visibleMeetings.length <= 0
            || (visibleMeetings[visibleMeetings.length - 1].index === meetingList[meetingList.length - 1].index)) {
            this.$downButton.addClass('is-disabled');
        } else {
            this.$downButton.removeClass('is-disabled');
        }
    },

    /**
     * Open Session Management overlay.
     * @param animate
     */
    open: function(animate) {
        this.createComponents();

        if (this.$el.parent().css('display') !== 'none') {
            return;
        }

        if (animate && !this.init) {
            this.$el.parent()
                .stop()
                .slideDown(200, function() {
                    this.focusRemoteArea();
                    this.emit('layout-size.update');
                }.bind(this))
                .show();
        } else {
            this.$el.parent().show();
            this.focusRemoteArea();
            this.emit('layout-size.update');
        }

        this.emit('ui.block.events');
        this.remote.permitLimitedAccess(true);
        this.init = false;
    },

    /**
     * Focus area.
     */
    focusRemoteArea: function() {
        if (!platform.checks.isCbox || this.$el.parent().css('display') === 'none'
            || this.getService('ModalHandlerService').hasOpenModal()) {
            return;
        }

        this.remote.focusArea(this.$container, {
            area: '.is-focusable:not(.is-disabled)',
            trapped: true
        });
    },

    /**
     * Unfocus area.
     */
    unfocusRemoteArea: function() {
        if (!platform.checks.isCbox || this.$el.parent().css('display') === 'none') {
            return;
        }

        this.remote.unfocus(this.$container);
        this.remote.destroyArea(this.$container);
    },

    /**
     * Close Session management overlay when meeting has started.
     * @param animate
     */
    close: function(animate) {
        if (this.$el.parent().css('display') === 'none') {
            return;
        }

        if (animate) {
            this.$el.parent()
                .animate({
                    top: '-' + this.$el.parent().height() + 'px'
                },
                {
                    duration: 200,
                    complete: function() {
                        if (this.options.onClose) {
                            this.options.onClose();
                        }
                        this.$el.parent()
                            .css('top', 'unset')
                            .hide();

                        this.remote.unfocus(this.$container);
                        this.remote.destroyArea(this.$container);
                        this.emit('layout-size.update');
                    }.bind(this)
                });
        } else {
            if (this.options.onClose) {
                this.options.onClose();
            }

            this.$el.parent().hide();
            this.remote.unfocus(this.$container);
            this.remote.destroyArea(this.$container);
            this.emit('layout-size.update');
        }

        this.remote.permitLimitedAccess(false);
        this.emit('ui.unblock.events');
        this.emit('session-mgmt.meeting-check-in.closed');
    },

    destroy: function() {
        this.remote.permitLimitedAccess(false);
        this.emit('ui.unblock.events');
    }
});
