'use strict';

const app = require('../../app');
const fileBrowserTpl = require('./file-browser-v3.html');

const vueUtils = require('../util');
const Vuex = require('vuex');

app.component('FileBrowserV3', {
    engine: 'vue',
    template: fileBrowserTpl,

    components: {
        'overlay2': require('../overlay2/overlay2')
    },

    data: function() {
        return {
            show: false,
            dragging: false,
            focusArea: '.is-focusable:not(.disabled), .media-selectable, .header-action:not([disabled]), .preview:not([disabled]), .item-action'
        };
    },

    computed: {
        ...Vuex.mapGetters('uiSettings', ['getUiScalingSize']),

        /**
         * Height in percent of viewport height depending on UI scaling setting
         */
        heightInPercent: function() {
            if (this.getUiScalingSize === 'large') {
                return 60;
            } else if (this.getUiScalingSize === 'small') {
                return 35;
            } else {
                return 50;
            }
        }
    },

    methods: {
        /**
         * Open file browser
         * @param mediaId ID of media to select in the media list
         */
        open: function(mediaId) {
            if (mediaId) {
                this.component.emit('media-list.select', mediaId);
            }
            this.show = true;
        },

        /**
         * Close file browser
         */
        close: function() {
            this.show = false;
        },

        /**
         * Navigate one folder up if file browser is shown/open.
         */
        back: function() {
            if (this.show) {
                this.component.emit('file-browser.back');
            }
        }
    },

    watch: {
        show: function(val) {
            if (val) {
                this.component.emit('file-browser.opened');
            } else {
                this.component.emit('file-browser.closed');
            }
        }
    },

    created: function() {
        this.evctx = vueUtils.eventContext();
        this.evctx.on('file-browser.open', this.open);
        this.evctx.on('file-browser.close', this.close);

        this.evctx.on('menu.opened', this.close);
        this.evctx.on('control-center.opened', this.close);
        this.evctx.on('edit-mode.opened', this.close);

        this.evctx.on('overlay.history.back', this.back);

        this.evctx.on('file-drag.start', function() {
            this.dragging = true;
        }.bind(this));
        this.evctx.on('file-drag.end', function() {
            this.dragging = false;
        }.bind(this));
    },

    mounted: function() {
        app.createComponent('FileBrowser', {
            type: 'FileBrowser',
            container: '.file-browser-wrapper',
            configs: {
                isClosed: !this.show
            }
        });
    },

    destroyed: function() {
        this.evctx.close();
        app.removeComponent('FileBrowser');
    }
});
