'use strict';

require('./request-animation-frame.js');
require('./url.js');

var instance;
var phoneMQ = window.matchMedia('(max-width: 736px)');
var smallMQ = window.matchMedia('(max-width: 900px)');
var mediumMQ = window.matchMedia('(max-width: 1200px)');
var fullsizeMQ = window.matchMedia('(max-width: 960px)');

function Platform() {
    /**
     * Check if we are on the cbox.
     *
     * @return {Boolean}
     */
    function isCbox() {
        return (/wolfvision-cbox/i).test(navigator.userAgent);
    }

    /**
     * Check if the app is running in dual projection mode on HDMI 1 output.
     *
     * @returns {Boolean}
     */
    function isCboxProjectionHdmi1() {
        return navigator.appVersion === 'wolfvision-cbox-control-hdmi1';
    }

    /**
     * Check if the app is running in dual projection mode on HDMI 2 output.
     *
     * @returns {Boolean}
     */
    function isCboxProjectionHdmi2() {
        return navigator.appVersion === 'wolfvision-cbox-control-hdmi2';
    }

    /**
     * Normal mode: Check if the app is running on HDMI 2 output.
     * Dual projection mode: Check if the app is running on HDMI 1 or HDMI 2 output.
     *
     * @returns {Boolean}
     */
    function isCboxAux() {
        return navigator.appVersion === 'wolfvision-cbox-aux'
            || isCboxProjectionHdmi1()
            || isCboxProjectionHdmi2();
    }

    /**
     * Check if the app is running in dual projection mode on control screen output (HDMI 3).
     *
     * @returns {Boolean}
     */
    function isCboxControlScreen() {
        return navigator.appVersion === 'wolfvision-cbox-control-confidence';
    }

    /**
     * Check if the app is running on remote browser and not on cbox.
     *
     * @returns {Boolean}
     */
    function isRemote() {
        return !isCbox();
    }

    /**
     * Check if we are on a android device.
     *
     * @return {Boolean}
     */
    function isAndroid() {
        return (/android/i).test(navigator.userAgent);
    }

    /**
     * Check if we are on a ios device.
     *
     * @return {Boolean}
     */
    function isIOS() {
        var isIos = (/(iPad|iPhone|iPod)/g).test(navigator.userAgent);

        // 3518: workaround for iPadPro with iOS 13 --> userAgent of MacOS and iOS are the same --> check for touch
        if (!isIos) {
            isIos = navigator.userAgent.toLowerCase().indexOf('macintosh') > -1 && 'ontouchend' in document;
        }

        return isIos;
    }

    /**
     * Check if we are on a ios 9 device.
     *
     * @return {Boolean}
     */
    function isIOS9() {
        return (/(iPad|iPhone|iPod)/g).test(navigator.userAgent)
            ? navigator.appVersion.indexOf('OS 9') !== -1
            : false;
    }

    /**
     * Check if we are on a ios 13 device.
     *
     * @return {Boolean}
     */
    function isIOS13() {
        return isIOS()
            ? (
                navigator.appVersion.indexOf('OS X 10_15') !== -1
                ||                navigator.appVersion.indexOf('OS 13') !== -1)
            : false;
    }

    /**
     * Check if we are on a Firefox browser.
     *
     * @return {Boolean}
     */
    function isFirefox() {
        return (/Firefox/i).test(navigator.userAgent);
    }

    /**
     * Check if we are on a Firefox browser.
     *
     * @return {Boolean}
     */
    function isSafari() {
        return (/Safari/i).test(navigator.userAgent) && !(/Chrome/i).test(navigator.userAgent);
    }

    /**
     * Check if we are on a IE 10 browser.
     *
     * @returns {string[]}
     */
    function isIe10() {
        return (/MSIE 10/).test(navigator.userAgent);
    }

    /**
     * Check if we are on a IE 11 browser.
     *
     * @returns {string[]}
     */
    function isIe11() {
        return (/rv:11/).test(navigator.userAgent);
    }

    /**
     * Browser can use Websocket? (WS is not working for Safari with ssl connection)
     *
     * @return {Boolean}
     */
    function canUseWS() {
        return !isSafari() || window.location.protocol !== 'https:';
    }

    /**
     * Check if we are on a Microsoft Edge browser.
     *
     * @returns {string[]}
     */
    function isEdge() {
        return (/Edge/).test(navigator.userAgent);
    }

    /**
     * Check if we are on a touch device.
     *
     * @return {Boolean}
     */
    function isTouchDevice() {
        if (isIe10() || isIe11() || isEdge()) {
            return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
        } else {
            return 'ontouchstart' in window || 'onmsgesturechange' in window;
        }
    }

    /**
     * Check if we are on a Windows OS.
     *
     * @returns {Boolean}
     */
    function isWindowsOS() {
        return navigator.appVersion.indexOf('Win') !== -1;
    }

    /**
     * Check if we are on a OSX.
     *
     * @returns {Boolean}
     */
    function isMacOS() {
        return navigator.appVersion.indexOf('Mac OS') !== -1;
    }

    /**
     * Check if we are on a Chrome-Book.
     *
     * @returns {Boolean}
     */
    function isChromeOS() {
        return navigator.appVersion.indexOf('CrOS') !== -1;
    }

    /**
     * Check if we are on a Windows Phone OS.
     *
     * @returns {Boolean}
     */
    function isWindowsPhone() {
        return navigator.appVersion.indexOf('Windows Phone') !== -1;
    }

    /**
     * Check if we are on a Chrome browser.
     *
     * @returns {string[]}
     */
    function isChrome() {
        return (/chrome/).test(navigator.userAgent.toLowerCase());
    }

    /**
     * Check if we are on a a fullsize screen.
     *
     * @return {Boolean}
     */
    var isFullsize = function() {
        return fullsizeMQ.matches;
    }.bind(this);

    /**
     * Check if we are on a Smartphone.
     * (max width: 736px)
     *
     * @return {Boolean}
     */
    var isSmartphone = function() {
        return phoneMQ.matches;
    }.bind(this);

    /**
     * Check if we are on a a small sized device
     * (max with: 900px).
     *
     * @return {Boolean}
     */
    var isSmallSize = function() {
        return smallMQ.matches;
    }.bind(this);

    /**
     * Check if we are on a medium sized device
     * (max with: 1200px).
     *
     * @return {Boolean}
     */
    var isMediumSize = function() {
        return mediumMQ.matches;
    }.bind(this);

    /**
     * Set a class to the main element so we apply custom css.
     *
     * @param {Object} $el
     */
    this.setPlatformClass = function setPlatformClass($el) {
        for (var checkName in this.checks) {
            var check = this.checks[checkName];

            if (typeof check === 'function') {
                check = check.call(this);
            }

            if (check) {
                checkName = checkName.replace(/([A-Z])/g, function(string) {
                    return '-' + string.toLowerCase();
                });

                $el.addClass(checkName);
            }
        }
    };

    /**
     * @type {Object}
     */
    this.checks = {
        /**
         * @type {Boolean}
         */
        isCbox: isCbox(),

        /**
         * @type {Boolean}
         */
        isTouchDevice: isTouchDevice(),

        /**
         * @type {Boolean}
         */
        isAndroid: isAndroid(),

        /**
         * @type {Boolean}
         */
        isIOS: isIOS(),

        /**
         * @type {Boolean}
         */
        isIOS9: isIOS9(),

        /**
         * @type {Boolean}
         */
        isIOS13: isIOS13(),

        /**
         * @type {Boolean}
         */
        isFirefox: isFirefox(),

        /**
         * @type {Boolean}
         */
        isIe10: isIe10(),

        /**
         * @type {Boolean}
         */
        isIe11: isIe11(),

        /**
         * @type {Boolean}
         */
        isIe: isIe10() || isIe11(),

        /**
         * @type {Boolean}
         */
        isEdge: isEdge(),

        /**
         * @type {Boolean}
         */
        isWindowsOS: isWindowsOS(),

        /**
         * @type {Boolean}
         */
        isMacOS: isMacOS(),

        /**
         * @type {Boolean}
         */
        isChromeOs: isChromeOS(),

        /**
         * @type {Boolean}
         */
        isWindowsPhone: isWindowsPhone(),

        /**
         * @type {Boolean}
         */
        isSafari: isSafari(),

        /**
         * @type {Boolean}
         */
        isFullsize: isFullsize,

        /**
         * @type {Boolean}
         */
        isSmartphone: isSmartphone,

        /**
         * @type {Boolean}
         */
        isSmallSize: isSmallSize,

        /**
         * @type {Boolean}
         */
        isMediumSize: isMediumSize,

        /**
         * @type {Boolean}
         */
        isChrome: isChrome(),

        /**
         * @type {Boolean}
         */
        isCboxAux: isCboxAux(),

        /**
         * @type {Boolean}
         */
        canUseWS: canUseWS(),

        /**
         * @type {Boolean}
         */
        isRemote: isRemote(),

        /**
         * @type {Boolean}
         */
        isCboxControlScreen: isCboxControlScreen(),

        /**
         * @type {Boolean}
         */
        isCboxProjectionHdmi1: isCboxProjectionHdmi1(),

        /**
         * @type {Boolean}
         */
        isCboxProjectionHdmi2: isCboxProjectionHdmi2()
    };
}

/**
 * Singleton pattern
 */
function getInstance() {
    if (!instance) {
        instance = new Platform();
    }

    return instance;
}

module.exports = getInstance();
