'use strict';

var CustomizedEventEmitter = require('./event-emitter');

/**
 * Event emitter mixin
 *
 * @class eventEmitterMixin
 * @param {Object} initialObject
 * @param {Function} [EventEmitter]
 *
 * @property {Function} off
 * @property {Function} emit
 * @property {Function} on
 * @property {Function} once
 *
 * @return {Object}
 */
function eventEmitterMixin(initialObject, EventEmitter) {
    var Emitter = EventEmitter || CustomizedEventEmitter;

    initialObject.events = new Emitter();

    initialObject.off = initialObject.events.off.bind(initialObject.events);
    initialObject.emit = initialObject.events.emit.bind(initialObject.events);

    initialObject.on = function() {
        var listenerArgs = arguments;

        initialObject.events.on.apply(initialObject.events, listenerArgs);

        return function() {
            initialObject.off.apply(initialObject.events, listenerArgs);
        };
    };

    initialObject.once = function() {
        var listenerArgs = arguments;

        initialObject.events.once.apply(initialObject.events, listenerArgs);

        return function() {
            initialObject.off.apply(initialObject.events, listenerArgs);
        };
    };

    return initialObject;
}

module.exports = {
    EventEmitter: CustomizedEventEmitter,
    /**
     * @type {eventEmitterMixin}
     */
    eventEmitterMixin: eventEmitterMixin
};
