'use strict';

var app = require('../../../app');
var listTpl = require('./user-settings.hbs');

app.component('UserSettings', {
    template: listTpl,

    initialize: function() {
        this.frameboxService = this.getService('FrameBoxService');
        this.formManager = this.getService('FormManager');
        this.cloudAuth = this.getService('CloudAuthenticationService');
        this.userSettingsService = this.getService('UserSettingsService');

        this.settingsLoaded = false;
        this.features = this.userSettingsService.features;
        this.office365 = this.userSettingsService.office365;

        if (this.features.office) {
            this.office365Service = this.getService('Office365Service');
        }

        this.loadConfigs();
    },

    postPlaceAt: function() {
        this.initForm();
        this.storeSelectors();
        this.bindEvents();
        this.bindDOMEvents();
        if (this.features.office) {
            this.handleLocalOffice365Account();
        }
    },

    serialize: function() {
        var dfd = this.$.Deferred();
        var data = {
            error: false,
            serviceList: this.serviceList,
            load: this.loadDialog,
            loginService: this.loginService.service,
            loginServiceTitle: this.loginService.title
        };

        if (this.serviceList) {
            dfd.resolve(data);

            return dfd.promise();
        }

        this.userSettingsService.prepareUserSettings(this.loadDialog)
            .then(function(userSettings) {
                data.serviceList = userSettings;
                dfd.resolve(data);
            }.bind(this), function() {
                dfd.resolve({
                    error: true
                });
            });

        return dfd.promise();
    },

    /**
     * Load configs from options.
     */
    loadConfigs: function() {
        this.tryToConnect = this.options.configs.tryToConnect;
        this.serviceList = this.options.configs.serviceList;
        this.loadDialog = this.options.configs.dialog === 'load';
        this.loginService = {
            service: this.options.configs.loginService,
            title: this.options.configs.loginServiceTitle
        };
        this.loginOfficeApp = this.options.configs.loginOfficeApp;
        this.loadBookmarks = this.options.configs.loadBookmarks;
        this.onLoad = this.options.configs.onLoad;
        this.onCancel = this.options.configs.onCancel;
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#user-settings-form')
        });
    },

    storeSelectors: function() {
        this.$saveSettings = this.$el.find('.save-settings');
        this.$localOffice365Account = this.$el.find('.local-office-account-container');

        if (!this.userSettingsService.isAnyServiceConnected()) {
            this.$saveSettings.addClass('is-disabled');
        }
    },

    bindEvents: function() {
        this.on('usb.mount.changed', this.onCheckDialog.bind(this));
        this.on('user-settings.settings.loaded', this.setSettingsLoaded.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.save-settings:not(.is-disabled)', this.onSaveUserSettings.bind(this));
        this.$el.on('click', '.load-settings', this.onLoadUserSettings.bind(this));
    },

    /**
     * Handle local office 365 account.
     * If there is more than one account (usb and local settings) show msg and  toggle switch for the user to choose.
     */
    handleLocalOffice365Account: function() {
        this.office365Service.localOffice365AccountAvailable().done(function(data) {
            if (!this.loadDialog || !this.features.office || !this.office365.function
                || !this.userSettingsService.office365Credentials || !data.available) {
                this.$localOffice365Account.addClass('hidden');

                return;
            }

            this.$localOffice365Account.removeClass('hidden');
        }.bind(this));
    },

    /**
     * Set setting list loaded on loaded event.
     *
     * @param loaded true/false
     */
    setSettingsLoaded: function(loaded) {
        this.settingsLoaded = loaded;
    },

    /**
     * Check if dialog is mounted. Close if it's not.
     *
     * @param data.mounted true/false
     */
    onCheckDialog: function(data) {
        if (!data.mounted) {
            this.close();
        }
    },

    /**
     * Handle on save user settings.
     */
    onSaveUserSettings: function() {
        this.deviceConnection
            .send('setUserSettings');

        this.close();
    },

    /**
     * Handle on load user settings.
     */
    onLoadUserSettings: function() {
        this.tryToConnect = true;

        if (this.features.office) {
            this.office365Service.localOffice365AccountAvailable().done(function(data) {
                if (data.available) {
                    this.office365Service.onChangeOffice365Origin(this.$localOffice365Account.find('input').prop('checked'));
                }
            }.bind(this));
        }

        this.userSettingsService.loadUserSettings(true);

        if (this.loginService.service) {
            setTimeout(() => {
                let cloudProvider = this.cloudAuth.getProvider(this.loginService.service);
                // Open file browser only if oauth is not in progress
                if (!(cloudProvider.connection === 'oauth'
                    && ['onedrive', 'gdrive', 'dropbox'].includes(this.loginService.service))) {
                    this.emit('file-browser.open', this.loginService.service);
                }
            }, 2000);
        } else if (this.loginOfficeApp) {
            this.openOffice365(this.loginOfficeApp, true);
        } else if (this.loadBookmarks) {
            this.openBookmarksOverlay();
        }

        // Execute callback, if specified
        if (typeof this.onLoad === 'function') {
            this.onLoad();
        }

        this.settingsLoaded = true;

        this.close();
    },

    /**
     * Close user settings modal.
     */
    close: function() {
        this.emit('modal.close', { id: 'userSettings' });
    },

    destroy: function() {
        if (this.settingsLoaded || this.beingDestroyed) {
            return;
        }
        this.beingDestroyed = true;

        this.userSettingsService.loadUserSettings(false);

        // Service
        if (this.loginService.service) {
            this.emit('cloud-login.show', {
                service: this.loginService.service,
                title: this.loginService.title
            });

            app.emit('main-loop.fast.start', {
                id: 'cloud'
            });

            if (this.tryToConnect) {
                this.cloudAuth.connectProvider(this.loginService.service);
            }

            this.tryToConnect = false;

            app.emit('file-browser.close');

            return;
        }

        // Office 365 App
        if (this.loginOfficeApp) {
            if (this.tryToConnect) {
                this.openOffice365(this.loginOfficeApp, false);
            }

            this.tryToConnect = false;

            return;
        }

        // Bookmarks
        if (this.loadBookmarks) {
            if (this.tryToConnect) {
                this.openBookmarksOverlay();
            }

            this.tryToConnect = false;

            return;
        }

        // Execute callback, if specified
        if (typeof this.onCancel === 'function') {
            this.onCancel();
        }
    },

    /**
     * Opens the framebox for the focused app.
     *
     * @param name Name of Office365 app to open (msword, msexcel,...)
     * @param loadSettings false: settings not loaded, true: load settings
     */
    openOffice365: function(itemName) {
        this.frameboxService.openFrameBox(itemName);
    },

    /**
     * Open bookmarks overlay.
     */
    openBookmarksOverlay: function() {
        this.emit('overlay.open', {
            component: 'Bookmarks',
            titleKey: 'bookmarks.bookmarks',
            closeButton: true,
            framebox: this.loadBookmarks.framebox,
            url: this.loadBookmarks.url,
            title: this.loadBookmarks.title,
            role: {
                name: 'Bookmarks',
                key: 'show'
            }
        });
    }
});
