// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list wlan-list-container\" data-nav-area=\".is-focusable\" data-nav-trapped data-nav-track>\n    <div class=\"row settings-list-row\">\n        <div id=\"wlan-list-header\" class=\"wlan-list-header hidden\">\n            <div class=\"col-xs-1 wlan-group-icon folded clickable is-focusable\" data-action=\"expand-collapse\">\n                <span class=\"icon-arrow-options folded\" ></span>\n            </div>\n            <div class=\"col-xs-4 asc clickable is-focusable\" data-action=\"sort\">\n                <h2 class=\"wlan-list-header-title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ssid",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":51},"end":{"line":8,"column":72}}}))
    + "</h2>\n                <h2 class=\"icon-arrow-nav asc\"></h2>\n            </div>\n            <div class=\"col-xs-3\">\n                <h2 class=\"wlan-list-header-title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.bssid",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":51},"end":{"line":12,"column":73}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-2 text-right\">\n                <h2 class=\"wlan-list-header-title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.channel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":51},"end":{"line":15,"column":75}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-2 text-right p-right-15\">\n                <h2 class=\"wlan-list-header-title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.signallevel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":51},"end":{"line":18,"column":79}}}))
    + "</h2>\n            </div>\n        </div>\n    </div>\n    <div id=\"wlan-list-items\" class=\"hidden\"></div>\n        <div id=\"wlan-list-loader\" class=\"row settings-list-primary wlan-list-item wlan-list-loader has-content hidden\">\n            <div class=\"col-xs-12\">\n                <div class=\"loader\"></div>\n            </div>\n        </div>\n        <div id=\"wlan-list-no-entry\" class=\"row settings-list-primary wlan-list-item wlan-list-no-entry has-content hidden\">\n            <div class=\"col-xs-12\">\n                <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wlan_list_no_entry",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":19},"end":{"line":30,"column":54}}}))
    + "</p>\n            </div>\n        </div>\n    </div>\n    <div id=\"wlan-settings\"></div>\n</div>\n";
},"useData":true});
