'use strict';

var app = require('../../../app');
var platform = require('./../../../../platform/platform');
var $ = require('jquery');
var mirrorSettingsTpl = require('./mirror-settings.hbs');
var FormActionView = require('../form-action-view');

app.component('MirrorSettings', {
    template: mirrorSettingsTpl,

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.validate = this.getService('ValidateService');
        this.outputService = this.getService('OutputService');
        this.frontendSettings = this.getService('FrontendSettings');
        this.deviceService = this.getService('DeviceService');
        this.isDualProjection = this.deviceService.isCboxProDualProjection();
        this.presentationMode = null;
    },

    serialize: function() {
        return {
            showFullsize: platform.checks.isFullsize(),
            isCboxPureOrPro: this.deviceService.isCboxPureOrPro(),
            isCboxPureMini: this.deviceService.isCboxPureMini(),
            isCbox: this.deviceService.isCbox(),
            isDualProjection: this.isDualProjection,
            cynapName: app.getService('Model-View').name,
            supports4kMirroring: !this.deviceService.isLivaQ2Hardware()
        };
    },

    postPlaceAt: function() {
        this.createSelects();
        this.initForm();
        this.storeSelectors();

        $.when(this.loadMirrorSettings()).done(function() {
            this.initFormAction();
            this.bindEvents();
            this.updatePinInfo('miracastPin');
            this.updatePinInfo('airplayPin');
            this.updatePinInfo('vCastPin');
        }.bind(this));
    },

    storeSelectors: function() {
        // Airplay
        this.$airplayEnable = this.$el.find('.airplay-enable');
        this.$airplaySettings = this.$el.find('#airplay-settings-container');
        this.$airplayPin = this.$el.find('.airplay-pin-enable');
        this.$airplayBtDiscovery = this.$el.find('.airplay-bt-discovery');
        this.$airplayInterface = this.$el.find('.airplay-interface');

        // Miracast
        this.$miracastEnable = this.$el.find('.miracast-enable');
        this.$miracastSettings = this.$el.find('#miracast-settings-container');
        this.$miracastPin = this.$el.find('.miracast-pin-enable');
        this.$miracastMode = this.$el.find('#miracast-mode');

        // Mice
        this.$miceInterfaceContainer = this.$el.find('#miracast-mice-interface');

        // VConnect / vCast
        this.$vConnectEnable = this.$el.find('.vconnect-enable');
        this.$vCastSettings = this.$el.find('#vcast-settings-container');
        this.$vCastPin = this.$el.find('.vcast-pin-enable');
        this.$vCastCameraBackRecEnable = this.$el.find('.camera-back-rec-enable');

        this.$mirrorInterfacesContainer = this.$el.find('#mirroring-interfaces-container');
        this.$presentationModeTimeout = this.$el.find('#presentation-mode-timeout-select');
        this.$wlanInfraMiracastInfo = this.$el.find('#wlan-infrastructure-miracast-info');
    },

    bindEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.form.get('presentationMode').$el.on('change', this.onPresentationModeChanged.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
    },

    /**
     * Handle presentation mode change and update mirror service settings.
     */
    onPresentationModeChanged: function() {
        this.presentationMode = this.form.get('presentationMode').getValue();

        if (this.init) {
            this.hideInfoMessages(true);
            this.showInfoMessages(true, this.presentationMode);
        } else {
            this.showInfoMessages(false, this.presentationMode);

            this.init = true;
        }

        if (this.presentationMode === 'lecture') {
            this.$presentationModeTimeout.show();
        } else {
            this.$presentationModeTimeout.hide();
        }

        this.handleAirplaySettings(this.formData.airplayEnable);
        this.handleMiracastSettings(this.formData.miracastEnable);
        this.handleMiceSettings(this.formData.miceEnable);
        this.handleVCastSettings(this.formData.vConnectEnable);
        this.handleMirrorInterfaces(this.formData.airplayEnable || this.formData.chromecastEnable || this.formData.vConnectEnable);
    },

    /**
     * Handle show/hide of info message for a specific element
     *
     * @param name - data-name to find element.
     * @param show - true/false
     */
    handleShowInfoMessage: function(name, show) {
        if (show) {
            this.animateShowInfoMessages(name);
        } else {
            this.animateHideInfoMessages(name);
        }
    },

    /**
     * Animate show info messages.
     *
     * @param name data-name to find element.
     */
    animateShowInfoMessages: function(name) {
        var $el = this.$el.find('.settings-list-info[data-name="' + name + '"]');

        if ($el.css('display') === 'none') {
            $el.stop()
                .slideDown(200);
        }
    },

    /**
     * Animate hide all or specific info message.
     *
     * @param name Info message name
     */
    animateHideInfoMessages: function(name) {
        var $el = this.$el.find('.settings-list-info');

        if (name) {
            $el = this.$el.find('.settings-list-info[data-name="' + name + '"]');
        }

        $el.stop()
            .slideUp(200);
    },

    /**
     * Show info messages for current presentation mode.
     *
     * @param animate Animated showing true/false
     * @param mode Presentation mode (lecture or meeting)
     */
    showInfoMessages: function(animate, mode) {
        if (animate) {
            this.$el.find('.settings-list-info[data-show-on="' + mode + '"]')
                .stop()
                .slideDown();
        } else {
            this.$el.find('.settings-list-info[data-show-on="' + mode + '"]').show();
        }
    },

    /**
     * Hide info messages for current presentation mode.
     *
     * @param animate Animated closing true/false
     */
    hideInfoMessages: function(animate) {
        if (animate) {
            this.$el.find('.settings-list-info')
                .stop()
                .slideUp();
        } else {
            this.$el.find('.settings-list-info').hide();
        }
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    /**
     * Calls on clicked the save button. Save frontend and backend settings.
     */
    saveHandler: function() {
        if (this.form.validate()) {
            const networkDiscovery = this.form.get('networkDeviceDiscovery').getValue();
            const mode = this.form.get('presentationMode').getValue();
            const timeout = this.form.get('presentationModeTimeout').getValue();

            const mirroringLan1 = this.form.get('mirroringLan1').getValue();
            const mirroringWlan = this.form.get('mirroringWlan').getValue();

            // Airplay
            const airplay = this.form.get('airplayEnable').getValue();
            const airplayPin = this.form.get('airplayPin').getValue();
            const airplayBtDiscovery = this.form.get('airplayBtDiscovery').getValue();
            const airplayInterface = this.form.get('airplayInterface').getValue();

            // Miracast
            const miracast = this.form.get('miracastEnable').getValue();
            const miracastPin = this.form.get('miracastPin').getValue();
            const miracastBackChannel = this.form.get('miracastBackChannelEnable').getValue();
            const miracastLowLatency = this.form.get('miracastLowLatency').getValue();

            // Mice
            const mice = this.form.get('miceEnable').getValue();
            const miceInterface = this.form.get('miceInterface').getValue();

            // Chromecast
            const chromecast = this.form.get('chromecastEnable').getValue();

            // VCast/vConnect
            const vconnect = this.form.get('vConnectEnable').getValue();
            const vCastPin = this.form.get('vCastPin').getValue();

            if (!this.isDualProjection) {
                // Mirroring
                const avoidInfinityMirror = this.form.get('avoidInfinityMirror').getValue();

                this.frontendSettings.updateSetting({
                    tag: 'avoidInfinityMirrorVApp',
                    value: avoidInfinityMirror
                });
            }

            this.frontendSettings.updateSetting({
                tag: 'networkDeviceDiscovery',
                value: networkDiscovery
            });

            this.frontendSettings.saveSettings();

            this.saveMirroringLan2();
            this.saveMiracastP2pMode();

            let cmds = [
                { command: 'setPresentationMode', data: { mode: mode, timeout: timeout } },
                { command: 'setMiracastState', data: { enable: miracast } },
                { command: 'setMiceState', data: { enable: mice, interface: miceInterface } },
                { command: 'setAirplayState', data: { enable: airplay } },
                { command: 'setVconnectState', data: { enable: vconnect } },
                { command: 'setChromecastState', data: { enable: chromecast } },
                { command: 'setAirplayMode', data: { mode: airplayPin } },
                { command: 'setVCastPin', data: { pin: vCastPin } },
                { command: 'setMiracastPin', data: { pin: miracastPin } },
                { command: 'setAirplayBleDiscovery', data: { enable: airplayBtDiscovery } },
                { command: 'setAirplayInterface', data: { interface: airplayInterface } },
                { command: 'setMirroringInterfaceFunction', data: { interface: 'lan1', enable: mirroringLan1 } },
                { command: 'setMirroringInterfaceFunction', data: { interface: 'wlan', enable: mirroringWlan } },
                { command: 'setMiracastBackChannel', data: { enabled: miracastBackChannel } },
                { command: 'setMiracastLowLatency', data: { enabled: miracastLowLatency } }
            ];

            if (!this.deviceService.isCboxPureMini()) {
                cmds.push({ command: 'setCameraBackFunction', data: { enabled: this.form.get('vCastCameraBack').getValue() } });
            }

            if (!this.deviceService.isCboxPureMini() && !this.deviceService.isCboxProDualProjection()) {
                cmds.push({ command: 'setCameraBackRecordingEnable', data: { enabled: this.form.get('vCastCameraBackRecording').getValue() } });
            }

            if (!this.deviceService.isLivaQ2Hardware()) {
                cmds.push({ command: 'setAirplay4kMode', data: { enabled: this.form.get('airplay4kMode').getValue() } });
                cmds.push({ command: 'setMiracast4kMode', data: { enabled: this.form.get('miracast4kMode').getValue() } });
            }

            setTimeout(function() {
                this.deviceConnection
                    .send(cmds)
                    .then(this.handleSettingsSave.bind(this));

                this.emit('save-changes.finished');
            }.bind(this), 250);
        }
    },

    /**
     * Save Miracast P2pMode.
     */
    saveMiracastP2pMode: function() {
        if ((this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) && this.wlanMode !== 'off') {
            return;
        }

        var miracastMode = this.form.get('miracastP2p').getValue();

        this.deviceConnection
            .send([
                {
                    command: 'setMiracastP2PMode',
                    data: {
                        role: miracastMode
                    }
                }
            ]);
    },

    /**
     * Save Mirroring LAN 2 settings.
     */
    saveMirroringLan2: function() {
        if (this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) {
            return;
        }

        var mirroringLan2 = this.form.get('mirroringLan2').getValue();

        this.deviceConnection
            .send([
                {
                    command: 'setMirroringInterfaceFunction',
                    data: {
                        interface: 'lan2',
                        enable: mirroringLan2
                    }
                }
            ])
            .then(this.handleSettingsSave.bind(this));
    },

    /**
     * Handle save settings.
     */
    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.form.setDefaultValues();
        this.emit('overlay.remote.focus', true);

        this.animateHideInfoMessages();
        this.changes = false;
    },

    /**
     * Handle form change.
     *
     * @param data Changed data
     */
    handleFormChange: function(data) {
        // Update formdata.
        this.formData[data.$el.attr('name')] = data.value;

        switch (data.$el.attr('name')) {
            case 'airplayEnable':
                this.handleAirplaySettings(data.$el.is(':checked'));
                break;
            case 'vConnectEnable':
                this.handleVCastSettings(data.$el.is(':checked'));
                break;
            case 'chromecastEnable':
                this.handleShowInfoMessage(data.$el.attr('name'), data.$el.is(':checked'));
                break;
            case 'miracastEnable':
                this.handleMiracastSettings(data.$el.is(':checked'));
                break;
            case 'miceEnable':
                this.handleMiceSettings(data.$el.is(':checked'));
                break;
            case 'airplayPin':
            case 'vCastPin':
            case 'miracastPin':
                this.updatePinInfo(data.$el.attr('name'));
                break;
            case 'airplayBtDiscovery':
                this.handleAirplayInterface(data.$el.is(':checked'));
                break;
            case 'presentationMode':
                if (data.$el.val() === 'lecture') {
                    this.$presentationModeTimeout.show();
                    // RELEASE-4121: disable vCast PIN in protected mode
                    this.form.get('vCastPin').setValue(false);
                } else {
                    this.$presentationModeTimeout.hide();
                }
                break;
            case 'vCastCameraBack':
                this.handleCameraBack(data.$el.is(':checked'));
                break;
        }

        if ((this.deviceService.isCboxPure() || this.deviceService.isCboxPureMini()) && this.wlanMode === 'infrastructure'
            && this.form.get('miracastEnable').getValue() && !this.form.get('miceEnable').getValue()) {
            this.$wlanInfraMiracastInfo
                .stop()
                .slideDown(300);
        } else {
            this.$wlanInfraMiracastInfo
                .stop()
                .slideUp(300);
        }

        this.handleMirrorInterfaces(this.formData.airplayEnable || this.formData.chromecastEnable || this.formData.vConnectEnable);

        this.actionView.open();
        this.changes = true;
    },

    /**
     * Handle Airplay settings.
     *
     * @param enabled true/false - Airplay enabled/disabled
     */
    handleAirplaySettings: function(enabled) {
        if (enabled) {
            if (this.presentationMode === 'meeting') { // Open mode
                this.$airplayPin.show();
            } else {
                this.$airplayPin.hide();
            }

            this.$airplayBtDiscovery.show();
            this.handleAirplayInterface(this.formData.airplayBtDiscovery);

            this.$airplayEnable.removeClass('settings-list-primary');
            this.$airplayEnable.addClass('settings-list-row');

            this.$airplaySettings
                .stop()
                .slideDown();
        } else {
            this.$airplaySettings
                .stop()
                .slideUp();

            this.$airplayEnable.removeClass('settings-list-row');
            this.$airplayEnable.addClass('settings-list-primary');
        }
    },

    /**
     * Handle Miracast settings.
     *
     * @param enabled true/false - Miracast enabled/disabled
     */
    handleMiracastSettings: function(enabled) {
        if (enabled) {
            if (this.presentationMode === 'meeting') { // Open mode
                this.$miracastPin.show();
            } else {
                this.$miracastPin.hide();
            }

            this.$miracastEnable.removeClass('settings-list-primary');
            this.$miracastEnable.addClass('settings-list-row');

            this.$miracastSettings
                .stop()
                .slideDown();
        } else {
            this.$miracastSettings
                .stop()
                .slideUp();

            this.$miracastEnable.removeClass('settings-list-row');
            this.$miracastEnable.addClass('settings-list-primary');
        }
    },

    /**
     * Handle Mice settings.
     * Disable mice interface when mice is disabled otherwise enable.
     *
     * @param {boolean} enabled
     */
    handleMiceSettings: function(enabled) {
        if (this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) {
            this.$miceInterfaceContainer.hide();

            return;
        }

        if (enabled) {
            this.$miceInterfaceContainer
                .stop()
                .slideDown();
        } else {
            this.$miceInterfaceContainer
                .stop()
                .slideUp();
        }
    },

    /**
     * Handle vCast/vConnect settings.
     *
     * @param enabled true/false - vConnect enabled/disabled
     */
    handleVCastSettings: function(enabled) {
        if (enabled) {
            if (this.presentationMode === 'meeting') { // Open mode
                this.$vCastPin.show();
            } else {
                this.$vCastPin.hide();
            }

            if (!this.deviceService.isCboxPureMini()) {
                this.handleCameraBack(this.form.get('vCastCameraBack').getValue());
            }
            this.$vConnectEnable.removeClass('settings-list-primary');
            this.$vConnectEnable.addClass('settings-list-row');

            this.$vCastSettings
                .stop()
                .slideDown();
        } else {
            this.$vCastSettings
                .stop()
                .slideUp();

            this.$vConnectEnable.removeClass('settings-list-row');
            this.$vConnectEnable.addClass('settings-list-primary');
        }
    },

    /**
     * Show/hide mirror interfaces.
     * @param show
     */
    handleMirrorInterfaces: function(show) {
        if (show) {
            this.$mirrorInterfacesContainer
                .stop()
                .slideDown();

            this.$mirrorInterfacesContainer.removeClass('hidden');
        } else {
            this.$mirrorInterfacesContainer
                .stop()
                .slideUp();

            this.$mirrorInterfacesContainer.addClass('hidden');
        }
    },

    /**
     * Hide/show Airplay interface for bluetooth discovery setting.
     *
     * @param airplayBtDiscoveryEnabled true/false
     */
    handleAirplayInterface: function(airplayBtDiscoveryEnabled) {
        if (airplayBtDiscoveryEnabled) {
            this.$airplayInterface
                .stop()
                .slideDown();
        } else {
            this.$airplayInterface
                .stop()
                .slideUp();
        }
    },

    /**
     * Hide/show camera back sub-settings.
     */
    handleCameraBack: function(enabled) {
        if (enabled) {
            this.$vCastCameraBackRecEnable
                .stop()
                .slideDown();
        } else {
            this.$vCastCameraBackRecEnable
                .stop()
                .slideUp();
        }
    },

    /**
     * Calls on clicked the cancel button.
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
        this.handleAirplayInterface(this.form.get('airplayBtDiscovery').getValue());
        if (!this.deviceService.isCboxPureMini()) {
            this.handleCameraBack(this.form.get('vCastCameraBack').getValue());
        }
        this.checkActionForm();

        this.animateHideInfoMessages();
        this.changes = false;
    },

    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * Load mirroring settings.
     */
    loadMirrorSettings: function() {
        return $.when(this.loadBackendSettings(this), this.loadFrontendSettings(this))
            .done(this.updateForm.bind(this));
    },

    /**
     * Load backend settings.
     */
    loadBackendSettings: function() {
        var dfd = $.Deferred();

        this.deviceConnection
            .send([
                'getPresentationMode',
                'getMiracastState',
                'getMiceState',
                'getAirplayState',
                'getVconnectState',
                'getChromecastState',
                'getAirplayMode',
                'getMiracastP2PMode',
                'getAirplayBleDiscovery',
                'getAirplayInterface',
                'getAirplay4kMode',
                'getVCastPin',
                'getMiracastPin',
                { command: 'getMirroringInterfaceFunction', data: { interface: 'lan1' } },
                { command: 'getMirroringInterfaceFunction', data: { interface: 'lan2' } },
                { command: 'getMirroringInterfaceFunction', data: { interface: 'wlan' } },
                'getMiracastBackChannel',
                'getWlanMode',
                'getMiracast4kMode',
                'getMiracastLowLatency',
                'getCameraBackFunction',
                'getCameraBackRecordingEnable'
            ])
            .then(function(presentationMode, miracastState, miceState, airplayState, vconnectState, chromecastState,
                airplayMode, miracastP2p, airplayBtDiscovery, airplayInterface, airplay4kMode, vCastPin,
                miracastPin, mirroringLan1, mirroringLan2, mirroringWlan, miracastBackChannel, wlan,
                miracast4kMode, miracastLowLatency, vCastCameraBack, vCastCameraBackRecording) {
                this.formData = {
                    presentationMode: presentationMode.presentationMode,
                    miracastEnable: miracastState.miracastState,
                    miceEnable: miceState.miceState,
                    miceInterface: miceState.interface,
                    airplayEnable: airplayState.airplayState,
                    vConnectEnable: vconnectState.vconnectState,
                    chromecastEnable: chromecastState.chromecastState,
                    airplayPin: airplayMode.airplayPin,
                    miracastP2p: miracastP2p.role,
                    airplayBtDiscovery: airplayBtDiscovery.enable,
                    airplayInterface: airplayInterface.interface,
                    vCastPin: vCastPin.vCastPin,
                    miracastPin: miracastPin.miracastPin,
                    mirroringLan1: mirroringLan1.enabled,
                    mirroringLan2: mirroringLan2.enabled,
                    mirroringWlan: mirroringWlan.enabled,
                    miracastBackChannelEnable: miracastBackChannel.enabled,
                    presentationModeTimeout: presentationMode.timeout,
                    airplay4kMode: airplay4kMode.enabled,
                    miracast4kMode: miracast4kMode.enabled,
                    miracastLowLatency: miracastLowLatency.enabled,
                    vCastCameraBack: vCastCameraBack.enabled,
                    vCastCameraBackRecording: vCastCameraBackRecording.enabled
                };

                this.wlanMode = wlan.wlanMode;
                this.presentationMode = presentationMode.presentationMode;

                if (this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) {
                    this.$miceInterfaceContainer.hide();

                    if (this.wlanMode !== 'off') {
                        this.$miracastMode.hide();
                    }
                }

                if ((this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) && this.wlanMode === 'infrastructure'
                    && !!this.formData.miracastEnable && !this.formData.miceEnable) {
                    this.$wlanInfraMiracastInfo.removeClass('hidden');
                } else {
                    this.$wlanInfraMiracastInfo.addClass('hidden');
                }

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Load frontend settings.
     */
    loadFrontendSettings: function() {
        var dfd = $.Deferred();

        this.frontendSettings
            .getSettings([
                'networkDeviceDiscovery',
                'avoidInfinityMirrorVApp'
            ])
            .then(function(discovery, avoidInfinityMirror) {
                this.formData.networkDeviceDiscovery = discovery ? discovery : 'supported';
                this.formData.avoidInfinityMirror = avoidInfinityMirror;

                if (this.formData.presentationMode === 'lecture') {
                    this.$presentationModeTimeout.show();
                } else {
                    this.$presentationModeTimeout.hide();
                }

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#mirror-settings'),
            validationContainer: '.input-group'
        });
    },

    updateForm: function() {
        this.form.setValues(this.formData);
        this.onPresentationModeChanged();
    },

    createSelects: function() {
        var items = [];

        for (var i = 1; i <= 12; i++) {
            items[i] = { text: i * 10 + ' sec', value: i * 10 };
        }

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#presentation-mode-select'),
            label: 'settings.menu_mirroring_settings',
            native: true,
            name: 'presentationMode',
            items: [
                {
                    text: 'settings.lecture_mode',
                    value: 'lecture'
                },
                {
                    text: 'settings.meeting_mode',
                    value: 'meeting'
                }
            ]
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#presentation-mode-timeout-select'),
            label: 'settings.timeout',
            native: true,
            name: 'presentationModeTimeout',
            items: items
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#network-discovery-select'),
            label: 'settings.network_discovery',
            native: true,
            name: 'networkDeviceDiscovery',
            items: app.getService('Model-View').getNetworkDeviceDiscovery()
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#miracast-mode-select'),
            label: 'settings.mirror_miracast_p2p',
            native: true,
            name: 'miracastP2p',
            items: [
                {
                    text: 'settings.client',
                    value: 'client'
                },
                {
                    text: 'settings.master',
                    value: 'go'
                }
            ]
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#airplay-interface-select'),
            label: 'settings.mirror_airplay_interface',
            native: true,
            name: 'airplayInterface',
            items: app.getService('Model-View').getAirplayInterfaces()
        });

        var interfaceItems = [
            {
                text: 'settings.all',
                value: 'all'
            },
            {
                text: 'settings.lan1',
                value: 'lan1'
            },
            {
                text: 'settings.lan2',
                value: 'lan2'
            },
            {
                text: 'settings.wlan',
                value: 'wlan'
            }
        ];

        if (this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) {
            interfaceItems = [
                {
                    text: 'settings.all',
                    value: 'all'
                },
                {
                    text: 'settings.lan',
                    value: 'lan1'
                },

                {
                    text: 'settings.wlan',
                    value: 'wlan'
                }
            ];
        }

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#mice-interface-select'),
            label: 'settings.mirror_mice_interface',
            native: true,
            name: 'miceInterface',
            items: interfaceItems
        });
    },

    /**
     * Show info messages for PIN configuration.
     *
     * @param pin Show Pin Configuration Info for pin (airplay, miracast, vCast) true/false
     */
    updatePinInfo: function(pin) {
        const $info = this.$el.find('.input-info-message[data-pin="' + pin + '"]');

        if (this.form.get(pin).getValue()) {
            $info.show();
        } else {
            $info.hide();
        }
    },

    destroy: function() {
        this.formManager.destroy(this.form);
    },

    hasChanges: function() {
        if (!this.actionView) {
            return {
                hasChanges: false,
                invalid: false
            };
        }

        return {
            hasChanges: this.changes,
            invalid: $(this.actionView.$submitBtn).prop('disabled'),
            waitOnSave: true
        };
    }
});
