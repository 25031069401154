'use strict';

const app = require('../../app');
const $ = require('jquery');
const _ = require('lodash');
const collabUserTpl = require('./collaboration-user.hbs');
const rbac = require('./../../../rbac/rbac.js');
const i18n = require('i18next');
const { strengthMapping, opacityMapping } = require('./constants');
const { reverseQuadraticFunction, averageMultiplePoints } = require('../../helper');

const planeIndices = {
    annotation: '-1',
    matrixAnnotation: '-2',
    magicPen: 255
};

const states = {
    disable: 'disable',
    enable: 'enable',
    pause: 'pause',
    matrixEnable: 'matrixEnable'
};

const defaultSettings = {
    tool: 'pen',
    opacity: opacityMapping.opaque,
    strength: 5,
    eraserStrength: 80,
    colorString: 'red',
    rgb: {
        red: 254,
        green: 65,
        blue: 97
    }
};

const defaultSettingsMagicPen = {
    tool: 'pen',
    opacity: opacityMapping.transparent,
    strength: strengthMapping.medium,
    eraserStrength: 80,
    colorString: 'red',
    rgb: {
        red: 254,
        green: 65,
        blue: 97
    }
};

/**
 * AnnotationAbstract blueprint
 */
app.component('AnnotationAbstract', {
    lastState: 'disable',
    lastStateMatrix: 'disabled',
    blockUpdate: false,

    getAccessKey: function() {
        return {
            'roleName': 'Annotation',
            'roleKey': 'show'
        };
    },

    /**
     * @method drawer
     */
    drawer: function() {
        if (this.position.state === 'painting') {
            this.sendDraw();

            window.requestAnimationFrame(this.drawer.bind(this));
        }
    },

    /**
     * @method toolUpdateHandler
     */
    toolUpdateHandler: function() {
        this.on('main-loop.update', this.onToolHandler.bind(this));
        this.on('main-loop.update.annotation', this.onToolHandler.bind(this));
    },

    /**
     * @method onToolHandler
     */
    onToolHandler: function() {
        var index = this.getIndex();

        if (index !== planeIndices.annotation && index !== planeIndices.matrixAnnotation) { // Not Annotation
            return;
        }

        this.deviceConnection
            .send([
                {
                    command: 'getAnnotationSettings',
                    data: {
                        planeIndex: index
                    }
                },
                {
                    command: 'getAnnotationState'
                },
                {
                    command: 'getMatrixMasterAnnotationActive'
                },
                {
                    command: 'getAnnotationUsers',
                    data: {
                        planeIndex: index,
                        allUsers: true
                    }
                },
                {
                    command: 'getAnnotationUsers',
                    data: {
                        planeIndex: index,
                        allUsers: false
                    }
                },
                'getAnnotationPinStatus'
            ])
            .fail(function() {
                this.checkForPauseDialog();
            }.bind(this))
            .then(function(settings, state, stateMatrix, users, currentUser, pinState) {
                var user = currentUser.userList[0];

                // Check user is already registered, if the user was kicked we stop annotation.
                if (user) {
                    this.setCurrentUserData(user);
                }

                this.onToolData(settings, state, stateMatrix);

                if (this.$collab) {
                    this.renderUserList(users);
                    this.updateCollabState(pinState);
                }
            }.bind(this));
    },

    /**
     * @param {Object} user
     */
    setCurrentUserData: function(user) {
        if (this.storage.get('clientId') !== user.clientId) {
            this.storage.set('clientId', user.clientId);
        }

        if (this.$userName) {
            this.$userName.html(user.nick);
            this.updateUserState(user);
        }
    },

    /**
     * @method onToolData
     */
    onToolData: function(settings, state, stateMatrix) {
        if (settings.undoOps) {
            this.$undoEl.removeClass('is-disabled');
            this.remote.enable(this.$undoEl.get(0));
        } else {
            this.$undoEl.addClass('is-disabled');
            this.remote.disable(this.$undoEl.get(0));
        }

        if (settings.redoOps) {
            this.$redoEl.removeClass('is-disabled');
            this.remote.enable(this.$redoEl.get(0));
        } else {
            this.$redoEl.addClass('is-disabled');
            this.remote.disable(this.$redoEl.get(0));
        }

        if (settings.clearAll !== 'disable') {
            this.$clearAllEl.removeClass('is-disabled');
            this.remote.enable(this.$clearAllEl.get(0));
        } else {
            this.$clearAllEl.addClass('is-disabled');
            this.remote.disable(this.$clearAllEl.get(0));
        }

        if ((
            stateMatrix.status !== states.enable
                && (
                    (state.state === states.disable && this.lastState !== states.disable)
                    || (state.state === states.pause && this.lastState !== states.pause)
                )
        )
            || (stateMatrix.state === states.disable && this.lastStateMatrix !== states.disable)
            || (stateMatrix.state === states.pause && this.lastStateMatrix !== states.pause)
        ) {
            this.annotationService.stopAnnotation();

            this.checkForPauseDialog();
        }
        this.lastState = state.state;
        this.lastStateMatrix = stateMatrix.state;
    },

    /**
     * Check if the pause dialog needs to be shown. (Collaboration User)
     */
    checkForPauseDialog: function() {
        if (this.authService && this.authService.getIsCollab() && !this.pauseDialogShown) {
            this.emit('modal.open', {
                id: 'pause-collaboration',
                onConfirm: function() {}
            });
            this.pauseDialogShown = true;
        }
    },

    /**
     * @constructor position
     */
    position: {
        x: -1,
        y: -1,
        state: 'watching',
        pointerid: 0,
        currentMode: null,

        /**
         * @method position.initialize
         */
        initialize: function(parent, $el) {
            this.$el = $el;
            this.parent = parent;
            this.bindDOMEvents();
            this.userList = [];
            this.liveStream = this.parent.getService('LiveStreamService');
            this.frameBoxService = this.parent.getService('FrameBoxService');
            this.stationService = app.getService('StationService');
            this.pointerid = 0;
            this.lastDownEvent = null;
        },

        unbindDOMEvents: function() {
            this.$el.off('mousedown.annotation touchstart.annotation');
            this.$el.off('pointerdown.annotation');
            this.$el.off('mouseup.annotation touchend.annotation mouseleave.annotation');
            this.$el.off('mousemove.annotation');
            this.$el.off('pointermove.annotation');
            this.$el.off('touchmove.annotation');
        },

        /**
         * @method position.bindDOMEvents
         */
        bindDOMEvents: function() {
            this.unbindDOMEvents();

            this.$el.on('mousedown.annotation touchstart.annotation', this.onMouseDown.bind(this));
            this.$el.on('pointerdown.annotation', this.onMouseDown.bind(this));
            this.$el.on('mouseup.annotation touchend.annotation mouseleave.annotation', this.onMouseUp.bind(this));
            this.$el.on('mousemove.annotation', this.onMouseMove.bind(this));
            this.$el.on('pointermove.annotation', this.onPointerMove.bind(this));
            this.$el.on('touchmove.annotation', this.onTouchMove.bind(this));
        },

        /**
         * @method position.onMouseDown
         */
        onMouseDown: function(event) {
            var $el = $(event.target);
            var pointerDiff = 0;
            var isDrawArea = $el.hasClass('annotation-container') || $el.hasClass('framebox-action') || $el.hasClass('magic-pen-container');

            if (this.stationService.getLockStatus()) {
                return;
            }

            if ('pointerdown' === event.type && this.parent.getIndex() !== planeIndices.magicPen) {
                // RELEASE-3065 - After moving thickness/darkness slider, first stroke is not drawn.
                // Pointer event id does not start at 0.
                if (0 !== this.pointerid) {
                    pointerDiff = event.originalEvent.pointerId - this.pointerid;
                }

                // RELEASE-3438: prevent from erasing instead of drawing after switching between windows.
                if ((Date.now() - this.lastDownEvent) <= 200) {
                    this.pointerid = event.originalEvent.pointerId;
                } else {
                    pointerDiff = 0;
                }
            }

            this.lastDownEvent = Date.now();

            // Enable rubber when 2 or more touches has been released.
            if (isDrawArea && this.parent.getIndex() !== planeIndices.magicPen
                    && ('touchstart' === event.type && 2 <= event.originalEvent.touches.length
                        || 'pointerdown' === event.type && 2 <= pointerDiff)) {
                if (!this.hasRubberActivatedByTouches) {
                    this.hasRubberActivatedByTouches = this.parent.tool;
                }

                this.startMultiTouchRubber();

                this.isMultiTouch = true;
            }

            // Block when mode has been choosed.
            if (this.currentMode) {
                return;
            }

            switch (event.type) {
                case 'pointerdown':
                case 'touchstart':
                    this.currentMode = 'touch';
                    break;
                case 'mousedown':
                    this.currentMode = 'mouse';
                    break;
            }

            if (isDrawArea) {
                if (this.state !== 'painting') {
                    this.parent.hideUi(event);
                }

                app.emit('main-loop.fast.start', {
                    id: 'annotation'
                });

                this.state = 'painting';

                switch (event.type) {
                    case 'touchstart':
                        this.setPositionByTouchEvent(event);
                        break;
                    case 'pointerdown':
                    case 'mousedown':
                        this.setPositionByMouseEvent(event);
                        break;
                }

                this.parent.drawer();
                this.startPaintTimestamp = (new Date()).getTime();
            } else {
                this.x = -1;
                this.y = -1;
            }
        },

        /**
         * Switch to the rubber with the maximum size.
         */
        startMultiTouchRubber: function() {
            // Stop drawing from the first click.
            this.parent.deviceConnection
                .send('setAnnotationPaint', {
                    painting: 'finished',
                    planeIndex: this.parent.getIndex(),
                    positionX: this.x,
                    positionY: this.y
                });

            // RELEASE-2974: Delete first drawn point when changing to multitouch rubber.
            if (((new Date()).getTime() - this.startPaintTimestamp) < 300) {
                this.parent.sendAction('undo');
                this.startPaintTimestamp = 0;
            }

            // Set tool to rubber.
            this.parent.actionHandlers['set-tool'].call(this.parent, $('[data-tool="rubber"]'));

            // Set rubber strength to max size.
            this.eraserStrength = 90;
            this.parent.$strength
                .find('input[type=range]')
                .val(Math.ceil(reverseQuadraticFunction(parseInt(this.eraserStrength)) / 5) * 5)
                .trigger('change');
        },

        /**
         * Handle mouse up.
         *
         */
        onMouseUp: function(event) {
            var firstTouch;

            // Workaround: It should be possible to draw "behind" hte Magic Pen Menu.
            if (this.parent.getIndex() === planeIndices.magicPen && event.type === 'mouseleave'
                && $(event.relatedTarget).is('[class^="magic-pen"]')) {
                return;
            }

            // RELEASE-1342 - When drawing fast, the strokes are interrupted on Touch monitor.
            // Count if there is more then one touches.
            if (event.originalEvent && event.originalEvent.touches && 0 < event.originalEvent.touches.length) {
                firstTouch = event.originalEvent.touches[0];

                /*
                 * Check if first touch is on current stored position, so we know that this touch is the main touch.
                 * Also we know the released touch is not the touch with the draw function, so we do not stop the draw.
                */
                if (firstTouch.pageX === this.x && firstTouch.pageY === this.y
                    || (this.hasRubberActivatedByTouches && 2 <= event.originalEvent.touches.length)
                ) {
                    return;
                }
            } else {
                this.isMultiTouch = false;
            }

            if (this.state !== 'watching') {
                this.parent.stopDraw();
                this.parent.showUi();
            }

            this.x = -1;
            this.y = -1;
            this.currentMode = null;
            this.state = 'watching';

            if (this.hasRubberActivatedByTouches) {
                this.parent.actionHandlers['set-tool']
                    .call(
                        this.parent,
                        $('[data-tool="' + this.hasRubberActivatedByTouches + '"]'
                        )
                    );
            }

            this.hasRubberActivatedByTouches = false;
        },

        /**
         * We use three types of move events to handle all situations.
         *
         * mousemove: Used on desktop devices.
         * touchmove: Used on touch devices without pointer events.
         * pointermove: Used on chromium, because chromium does not fire the touchevent in the first time.
         *
         * @param {jQuery.Event} event
         */
        onMouseMove: function(event) {
            if (this.currentMode !== 'mouse') {
                return;
            }

            this.setPositionByMouseEvent(event);
        },

        /**
         * First events not fired on chromium so we use a fallback with the pointermove event.
         *
         * @param {jQuery.Event} event
         */
        onTouchMove: function(event) {
            if (this.currentMode !== 'touch') {
                return;
            }

            if (this.isMultiTouch) {
                var position = averageMultiplePoints(event.originalEvent.touches);

                this.setPositionByCoordinate(position);
            } else {
                this.setPositionByTouchEvent(event);
            }

            if (this.state !== 'watching') {
                event.preventDefault();
            }
        },

        /**
         * Pointermove will always be fired, but that is not working on some browsers,
         * so we need the touchmove fallback.
         *
         * @param {jQuery.Event} event
         */
        onPointerMove: function(event) {
            if (this.currentMode !== 'touch') {
                return;
            }

            this.setPositionByMouseEvent(event);

            if (this.state !== 'watching') {
                event.preventDefault();
            }
        },

        /**
         * Calculate current point of the mouse/pointer and set it to the position property.
         *
         * @param {jQuery.Event} event
         */
        setPositionByMouseEvent: function(event) {
            var containerData = this.getContainerData();
            var clientX = event.clientX - containerData.position.x;
            var clientY = event.clientY - containerData.position.y;

            this.x = (clientX / containerData.size.width) * 1920;
            this.y = (clientY / containerData.size.height) * 1080;
        },

        /**
         * Calculate current point of the finger and set it to the position property.
         *
         * @param {jQuery.Event} event
         */
        setPositionByCoordinate: function(coordinate) {
            var containerData = this.getContainerData();
            var clientX = coordinate.x - containerData.position.x;
            var clientY = coordinate.y - containerData.position.y;

            this.x = (clientX / containerData.size.width) * 1920;
            this.y = (clientY / containerData.size.height) * 1080;
        },

        /**
         * Calculate current point of the finger and set it to the position property.
         *
         * @param {jQuery.Event} event
         */
        setPositionByTouchEvent: function(event) {
            var containerData = this.getContainerData();
            var clientX = event.originalEvent.touches[0].clientX - containerData.position.x;
            var clientY = event.originalEvent.touches[0].clientY - containerData.position.y;

            this.x = (clientX / containerData.size.width) * 1920;
            this.y = (clientY / containerData.size.height) * 1080;
        },

        /**
         * RELEASE-2398 - Whiteboard: Position of the finger/pen doesn't match the position of the drawing.
         *
         * This method will return the correct offset and windows size to calculate mouse/touch position.
         *
         * There is some difference between annotation and whiteboard.
         * - On Annotation we are able to calculate the position from the liveStream data.
         * - On Whiteboard we must use the offset of the framebox-container.
         */
        getContainerData: function() {
            if (planeIndices.annotation === this.parent.getIndex()
                || planeIndices.matrixAnnotation === this.parent.getIndex()) {
                return {
                    position: {
                        x: this.liveStream.getOffset().left,
                        y: this.liveStream.getOffset().top
                    },
                    size: {
                        width: this.liveStream.getSize().width,
                        height: this.liveStream.getSize().height
                    }
                };
            }

            return {
                position: {
                    x: this.$el.offset().left,
                    y: this.$el.offset().top
                },
                size: {
                    width: this.$el.width(),
                    height: this.$el.height()
                }
            };
        }
    },

    /**
     * @method sendDraw
     */
    sendDraw: function() {
        if (this.getIndex() !== planeIndices.magicPen
            && (this.getIndex() === planeIndices.annotation || this.getIndex() === planeIndices.matrixAnnotation
            || (this.options.isFullscreen ? this.frameBoxService.getActiveFrameBox() === this.getIndex() : true))) {
            if (this.position.x > 0 && this.position.y > 0) {
                this.deviceConnection
                    .send('setAnnotationPaint', {
                        painting: 'continued',
                        planeIndex: this.getIndex(),
                        positionX: this.position.x,
                        positionY: this.position.y
                    });

                this.emit('control-bar.hide');
            }
        } else if (this.annotationService.isMagicPenStarted() && this.getIndex() === planeIndices.magicPen) {
            if (this.position.x > 0 && this.position.y > 0) {
                this.deviceConnection
                    .send('setAnnotationMagicPenPaint', {
                        painting: 'continued',
                        positionX: this.position.x,
                        positionY: this.position.y
                    });
            }
        }
    },

    /**
     * @method stopDraw
     */
    stopDraw: function() {
        if (this.getIndex() === planeIndices.annotation || this.getIndex() === planeIndices.matrixAnnotation
            || (this.options.isFullscreen ? this.frameBoxService.getActiveFrameBox() === this.getIndex() : true)) {
            if (!!this.position.state && this.position.state !== 'watching') {
                if (this.position.x === -1 && this.position.y === -1) {
                    return;
                }

                if (this.getIndex() !== planeIndices.magicPen) {
                    this.deviceConnection
                        .send('setAnnotationPaint', {
                            painting: 'finished',
                            planeIndex: this.getIndex(),
                            positionX: this.position.x,
                            positionY: this.position.y
                        });
                } else if (this.annotationService.isMagicPenStarted()) {
                    this.deviceConnection
                        .send('setAnnotationMagicPenPaint', {
                            painting: 'finished',
                            positionX: this.position.x,
                            positionY: this.position.y
                        });
                }
            }

            this.position.x = -1;
            this.position.y = -1;

            this.emit('main-loop.fast.start', {
                id: 'annotation'
            });
        }
    },

    /**
     * @method actionHandlers
     */
    actionHandlers: {
        'close': function() {
            if (this.annotationService.hasCloseAccess()) {
                this.emit('modal.open', {
                    id: 'end-annotation',
                    index: this.getIndex(),
                    onCancel: this.onCancel.bind(this)
                });
            }
        },
        'clear-all': function() {
            if (!this.$clearAllEl.hasClass('is-disabled')) {
                this.emit('modal.open', {
                    component: 'Confirm',
                    view: 'small',
                    messageKey: 'annotation.clear_annotation_question',
                    successTextKey: 'annotation.clear_annotation_confirm',
                    navArea: '.focusable',
                    role: {
                        name: 'Annotation',
                        key: 'clearSnapshot'
                    },
                    onCancel: this.onCancel.bind(this),
                    onConfirm: function() {
                        this.connect();
                        this.sendAction('cleanAll');
                        this.emit('annotation.cleared');
                        this.currentMode = null;
                    }.bind(this)
                });
            }
        },
        'snapshot': function() {
            this.takeSnapshotHandler();
        },
        'redo': function() {
            this.sendAction('redo');
        },
        'undo': function() {
            this.sendAction('undo');
        },
        'back': function() {
            if (rbac.hasAccess('Annotation', 'close')) {
                if (this.annotationService.isCollaboration() || (this.$userList.children().length > 1)) {
                    this.pauseDialogShown = true;

                    this.emit('modal.open', {
                        id: 'pause-annotation',
                        index: this.getIndex(),
                        onCancel: this.onCancel.bind(this)
                    });
                } else {
                    this.emit('modal.open', {
                        id: 'end-annotation',
                        index: this.getIndex(),
                        onCancel: this.onCancel.bind(this)
                    });
                }
            }
        },
        'select-color': function(event) {
            var $el = this.$(event.currentTarget);

            if (!$el.hasClass('color')) {
                $el = $el.find('.color');
            }

            this.$el.find('.color').removeClass('active');
            this.$el.find('.annotation-color').removeClass('active');

            $el.addClass('active');
            $el.parent().addClass('active');

            this.updateRGBFromDom();
            this.sendSettings();
            this.emit('framebox.submenu.close');
        },
        'set-tool': function($el) {
            this.tool = $el.attr('data-tool');

            $('[data-tool]').removeClass('active');
            $el.addClass('active');

            if ('rubber' === this.tool && this.$strength) {
                this.$strength
                    .find('input[type=range]')
                    .val(Math.ceil(reverseQuadraticFunction(parseInt(this.eraserStrength)) / 5) * 5)
                    .trigger('change');
            } else if (this.$strength) {
                this.$strength
                    .find('input[type=range]')
                    .val(Math.ceil(reverseQuadraticFunction(parseInt(this.strength)) / 5) * 5)
                    .trigger('change');
            }

            this.sendSettings();

            if ('pen' !== this.tool && 'rubber' !== this.tool) {
                this.emit('framebox.submenu.close');
            }
        },
        'remove-user': function($el) {
            this.deviceConnection
                .send([
                    {
                        command: 'setAnnotationAction',
                        data: {
                            action: 'clean',
                            planeIndex: this.getIndex(),
                            clientId: $el.data('id')
                        }
                    },
                    {
                        command: 'setAnnotationAction',
                        data: {
                            action: 'disconnect',
                            planeIndex: this.getIndex(),
                            clientId: $el.data('id')
                        }
                    }
                ]);
        },
        'toggle-user': function($el) {
            this.deviceConnection
                .send('setAnnotationUser', {
                    clientId: $el.data('id'),
                    planeIndex: this.getIndex(),
                    visible: $el.is(':checked')
                });

            this.emit('main-loop.fast.start', {
                id: 'annotation'
            });
            this.blockUpdates(1000);
        }
    },

    onCancel: function() {
        var modalHelperService = this.getService('ModalHandlerService');

        // Event to show annotation toolbox.
        if (!modalHelperService.hasOpenModal()) {
            this.emit('annotation.open.menu');
        }

        this.currentMode = null;
    },

    /**
     * Sets a variable which blocks updates for given time in ms.
     *
     * @param {number} duration
     */
    blockUpdates: function(duration) {
        duration = duration || 1000;

        this.blockUpdate = true;
        if (this.blockUpdateTimeout) {
            clearTimeout(this.blockUpdateTimeout);
        }
        this.blockUpdateTimeout = setTimeout(function() {
            this.blockUpdate = false;
        }.bind(this), duration);
    },

    /**
     * @method sendAction
     */
    sendAction: function(action) {
        this.deviceConnection
            .send('setAnnotationAction', {
                action: action,
                planeIndex: this.getIndex()
            });

        this.emit('main-loop.fast.start', {
            id: 'annotation'
        });
    },

    /**
     * Take a snapshot.
     */
    takeSnapshotHandler: function() {
        this.emit('main-loop.fast.start', {
            id: 'osd'
        });

        if (this.annotationService.isMatrixAnnotation()) {
            this.deviceConnection
                .send('setSnapshot');
        } else {
            this.deviceConnection
                .send('setAnnotationAction', {
                    action: 'snapshot',
                    planeIndex: this.getIndex()
                });
        }
    },

    /**
     * @method sendSettings
     */
    sendSettings: function() {
        const settings = this.getSettings();
        const data = {
            planeIndex: this.getIndex(),
            tool: settings.tool,
            red: settings.rgb.red,
            green: settings.rgb.green,
            blue: settings.rgb.blue,
            alpha: settings.alpha,
            penSize: settings.penSize,
            eraserStrength: settings.eraserStrength
        };

        this.position.x = -1;
        this.position.y = -1;

        this.deviceConnection
            .send('setAnnotationSettings', data);

        // RELEASE-2593 remove active color to avoid wrongly selected color
        this.$el.find('.annotation-color.active').removeClass('active');

        // Set the selected color in the color-selection-"Menue"
        var selectedCol = this.$el.find('.annotation-color [data-color="' + this.colorString + '"]');
        selectedCol.find('.color').removeClass('active');
        selectedCol.find('.annotation-color').removeClass('active');
        selectedCol.addClass('active');
        selectedCol.parent().addClass('active');

        this.storeSettings(this.getIndex(), settings);
    },

    /**
     * Starts the connection to annotation module
     * @method connect
     */
    connect: function() {
        this.deviceConnection
            .send('setAnnotationAction', {
                action: 'connect',
                planeIndex: this.getIndex()
            });

        this.deviceConnection
            .send([
                {
                    command: 'getAnnotationSettings',
                    data: {
                        planeIndex: this.getIndex()
                    }
                }
            ])
            .then(function(state) {
                if (state.status !== 0) {
                    setTimeout(this.connect.bind(this), 1000);
                } else {
                    this.sendSettings();
                }
            }.bind(this));
    },

    /**
     * @method getIndex
     * @returns {int||-1}
     */
    getIndex: function() {
        // RELEASE-2354: workaround to clear whiteboard strokes -->
        // The index is set to undefined if clear all modal is opened therefore we have to re set the last index.
        this.index = this.index === undefined ? this.lastIndex : this.index;

        if (this.index !== 0 && !this.index) {
            return this.annotationService.getPlaneIndex();
        }

        return this.index;
    },

    getDefaultSettings: function() {
        var settings = this.index === planeIndices.magicPen ? defaultSettingsMagicPen : defaultSettings;

        this.tool = settings.tool;
        this.opacity = settings.opacity;
        this.strength = settings.strength;
        this.eraserStrength = settings.eraserStrength;
        this.colorString = settings.colorString;
        this.rgb = settings.rgb;
    },

    getStorageService: function() {
        if (!this.storage) {
            this.storage = app.getService('DataStorage');
        }
    },

    /**
     * @param {int} index
     * @param {object} settings
     */
    storeSettings: function(index, settings) {
        this.getStorageService();

        if (this.colorString) {
            settings.colorString = this.colorString;
        }
        this.storage.set('drawSettings-' + index, settings);
    },

    /**
     * @param {int} index
     */
    loadStoredSettings: function(index) {
        var settings;

        this.getStorageService();
        this.getDefaultSettings();

        settings = this.storage.get('drawSettings-' + index);

        if (settings
            && settings.rgb
            && settings.tool
            && settings.alpha
            && settings.penSize
            && settings.eraserStrength
        ) {
            this.rgb = settings.rgb;
            this.tool = settings.tool;
            this.opacity = settings.alpha;
            this.strength = settings.penSize;
            this.eraserStrength = settings.eraserStrength;

            if (settings.colorString) {
                this.colorString = settings.colorString;
            }
        }
    },

    /**
     * @method renderUserList
     * @param {object} users
     */
    renderUserList: function(users) {
        if (this.blockUpdate) {
            return;
        }

        if (this.$userList.find('.collaboration-user-item').length > 0) {
            this.$userList.find('.collaboration-user-item').data('updated', false);
        }

        _.each(users.userList, function(item) {
            var $found = this.$userList.find('.collaboration-user-item[data-id="' + item.clientId + '"]');
            var $item = null;

            if ($found.length === 0) {
                // Create new
                $item = this.$(collabUserTpl(item));
                $item.find('.btn-mini-switch').prop('checked', item.visible === 1);
                $item.find('[data-action]').on('click', this.actionHandler.bind(this));

                this.$userList.append($item);
            } else {
                // Update
                $item = $found;
                $item.find('h3').html(item.nick);
                $item.find('.btn-mini-switch').prop('checked', item.visible === 1);

                if ($item.find('h3')[0].scrollWidth > $item.find('h3').width()) {
                    $item.find('h3').addClass('two-lines');
                }
            }

            if (item.clientId === this.storage.get('clientId')) {
                this.updateUserState(item);

                $item.find('.icon-close').addClass('hidden');
                $item.find('.icon-circle-active').removeClass('hidden');
            } else if (this.annotationService.isMatrixMasterAnnotation()) {
                this.updateUserState(item);

                $item.find('.icon-close').addClass('hidden');
                $item.find('.icon-circle-active').addClass('hidden');
            }

            $item.data('updated', true);
        }.bind(this));

        // Remove all items with data-updated=false
        _.each(this.$userList.find('.collaboration-user-item'), function(el) {
            var $el = this.$(el);
            var updated = $el.data('updated');

            if (!updated) {
                $el.remove();
            }
        }.bind(this));

        this.$userList.show();

        if (!this.pauseDialogShown) {
            this.remote.focus(this.$userList.find('.is-focusable').get(0));
        }
    },

    /**
     * @method updateUserState
     * @param {object} user
     */
    updateUserState: function(user) {
        if (this.storage.get('clientId') === user.clientId && this.userState !== user.visible) {
            this.userState = user.visible;
            this.emit('collaboration-user.update', user);

            if (this.userState) {
                this.$userState.html(i18n.t('annotation.active'));
                this.$userStateIcon.addClass('active');
                this.$userStateIcon.removeClass('inactive');

                if ((this.authService.getIsCollab() || this.annotationService.isMatrixAnnotation()) && this.annotationService.autoShowAnnotationMenu) {
                    this.openMenu();
                }
            } else {
                this.$userState.html(i18n.t('annotation.inactive'));
                this.$userStateIcon.addClass('inactive');
                this.$userStateIcon.removeClass('active');

                /**
                 * Timeout because if start annotation and user is invisible from the beginning,
                 * the control center opens and closes immediately.
                 */
                if (this.authService.getIsCollab() || this.annotationService.isMatrixAnnotation()) {
                    setTimeout(function() {
                        this.closeMenu();
                    }.bind(this), 1500);
                }
            }
        }
    }
});
