const template = require('./command-button.html');

module.exports = {
    template,
    props: {
        icon: {
            type: String,
            required: false
        },
        fixed: {
            type: Boolean,
            required: false,
            default: false
        },
        selected: {
            type: Boolean,
            required: false,
            default: false
        },
        active: {
            type: Boolean,
            required: false,
            default: false
        },
        highlightOnPress: {
            type: Boolean,
            required: false,
            default: false
        },
        busy: {
            type: Boolean,
            required: false,
            default: false
        },
        small: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        maxWidth: {
            type: Number,
            required: false,
            default: 288
        }
    },

    data: function() {
        return {
            displayBusy: false,
            highlight: false,
            hasRemoteControlFocus: false
        };
    },

    methods: {
        click: function() {
            if (!this.disabled) {
                this.$emit('click');
            }
        },

        press: function() {
            if (this.disabled) {
                return;
            }

            if (this.highlightOnPress) {
                this.highlight = true;
                this.highlightTimeoutHandle = setTimeout(() => {
                    this.highlight = false;
                }, 2000);
            }
        },

        release: function() {
            if (this.disabled) {
                return;
            }

            if (this.highlightOnPress) {
                if (this.highlightTimeoutHandle) {
                    clearTimeout(this.highlightTimeoutHandle);
                }

                this.highlight = false;
            }
        }
    },

    watch: {
        busy: function() {
            if (!this.busy) {
                if (this.timeoutHandle) {
                    clearTimeout(this.timeoutHandle);
                }

                this.displayBusy = false;
            } else if (this.busy && !this.timeoutHandle) {
                this.timeoutHandle = setTimeout(function() {
                    this.timeoutHandle = undefined;

                    this.displayBusy = this.busy;
                }.bind(this), 200);
            }
        }
    },

    beforeUpdate() {
        this.hasRemoteControlFocus = this.$el.classList.contains('focused');
    },

    updated() {
        if (this.hasRemoteControlFocus) {
            this.$el.classList.add('focused');
        }
    },

    destroyed: function() {
        if (this.timeoutHandle) {
            clearTimeout(this.timeoutHandle);
            this.timeoutHandle = undefined;
        }

        if (this.highlightTimeoutHandle) {
            clearTimeout(this.highlightTimeoutHandle);
            this.highlightTimeoutHandle = undefined;
        }
    }
};
