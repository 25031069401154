'use strict';

/**
 * RBACException
 *
 * @param {string} message
 */
module.exports = function RBACException(message, isInfo) {
    this.message = message;
    this.name = 'RBAC-Exception';
    this.isInfo = !!isInfo;
};
