'use strict';

var app = require('../../../app');
var resetTpl = require('./factory-reset.hbs');

app.component('FactoryReset', {
    template: resetTpl,

    initialize: function() {
        this.remote = this.getService('RemoteService');
    },

    serialize: function() {
        return {
            'cynapName': app.getService('Model-View').name
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindDOMEvents();
    },

    bindDOMEvents: function() {
        this.$resetBtn.on('click', this.resetHandler.bind(this));
        this.$stopBtn.on('click', this.stopHandler.bind(this));
        this.$actionBtn.on('click', this.showWarningHandler.bind(this));
    },

    storeSelectors: function() {
        this.$actionBtn = this.$el.find('.show-warning-action');
        this.$stopBtn = this.$el.find('.stop-action');
        this.$resetBtn = this.$el.find('.restore-action');
        this.$step1 = this.$el.find('.factory-reset-step-1');
        this.$step2 = this.$el.find('.factory-reset-step-2');
        this.$step3 = this.$el.find('.factory-reset-step-3');
    },

    /**
     * Reset CYNAP to factory.
     */
    resetHandler: function() {
        this.$actionBtn.hide();

        this.deviceConnection
            .send('setFactoryReset')
            .then(this.handleReset.bind(this));

        this.$step2.slideUp(150);
        this.$step3.slideDown(150);
    },

    /**
     * Stop factory reset and go back to the startscreen.
     */
    stopHandler: function() {
        this.$step2.slideUp();
        this.$step1.slideDown();

        setTimeout(function() {
            this.remote.focus(this.$actionBtn.get(0));
        }.bind(this), 100);
    },

    /**
     * Show the second warnin before reseting to default.
     */
    showWarningHandler: function() {
        this.$step1.slideUp();
        this.$step2.slideDown();

        setTimeout(function() {
            this.remote.focus(this.$stopBtn.get(0));
        }.bind(this), 100);
    },

    /**
     * @method handleReset
     */
    handleReset: function() {
        this.emit('system-reboot.started');
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_reset_complete',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });
    },

    hasChanges: function() {
        return {
            hasChanges: false,
            invalid: false
        };
    }
});
