'use strict';

var _ = require('lodash');
var $ = require('jquery');
var app = require('../../../app');
var Hammer = require('hammerjs');
var frameboxHeaderTpl = require('./framebox-ui.hbs');
var platform = require('../../../../platform/platform');
var contentTypeKeyboardEnabled = require('./../../../settings').contentTypeKeyboardEnabled;
var componentControlBarDisabled = require('./../../../settings').componentControlBarDisabled;
var keyboardViews = require('./../../../settings').keyboardViews;
var LayoutManager = require('../../../../layout/layout');
var keyMapper = require('./../../../../../modules/keyboard/key-mapper');

/**
 * Pinky and Brain modes.
 * (Standard and Control mode)
 *
 * @type {{
 *  pinky: string (control bar closed),
 *  brain: string (control bar opened)
 *  }}
 */
var modes = {
    pinky: 'pinky', // STANDARD MODE: control bar closed.
    brain: 'brain' // CONTROL MODE: control bar opened.
};

/**
 * Active and passive states.
 * Is the framebox clickable or inactive (z.B. Freeze ON; Overlay, Menu opened,...)
 *
 * @type {{
 * inactive: string (not clickable),
 * active: string (clickable)
 * }}
 */
var activeStates = {
    inactive: 'inactive',
    active: 'active'
};

/**
 * Animation duration.
 * @type {number}
 */
var DURATION = 300;

var PREVENT_KEYCODES = [
    37, // Arrow left
    38, // Arrow up
    39, // Arrow right
    40, // Arrow down
    13 // Enter
];

app.component('FrameBoxUI', {
    className: 'framebox-action has-action framebox-ui full-height stop-overscroll',
    template: frameboxHeaderTpl,

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.controlsScrollable = false;
        this.preventKeyEvents = false;
        this.isMatrix = this.options.component === 'FrameBoxMatrix' || this.frameboxComponent === 'FrameBoxMatrixGroupwork';
        this.webRtcOut = this.options.webRtcOut;
        this.customClose = this.options.customClose;
        this.mode = this.createStateMachine({
            state: this.options.withoutControlBar ? modes.brain : modes.pinky,
            states: modes
        });

        this.activeState = this.createStateMachine({
            state: activeStates.active,
            states: activeStates
        });

        this.addStateTransitions();
        this.getServices();

        this.$el.attr('data-index', this.index);
        this.$el.attr('data-content-type', this.options.contentType);
    },

    getServices: function() {
        this.stationService = this.getService('StationService');
        this.frameBoxService = this.getService('FrameBoxService');
        this.outputService = this.getService('OutputService');
        this.overlayHandlerService = this.getService('OverlayHandlerService');
        this.keyboard = this.getService('KeyboardService');
        this.freezeService = this.getService('FreezeService');
        this.remoteService = this.getService('RemoteService');
        this.webconferenceService = this.getService('WebconferenceService');

        if (this.isMatrix) {
            this.matrixConfigs = this.getService('MatrixConfigs');
            this.matrixConfigs.parseStations();
        }
    },

    /**
     * Serialize Data for component-rendering.
     */
    serialize: function() {
        return {
            hasShowKeyboard: this.hasKeyboardButton(),
            options: this.options,
            isMatrix: this.isMatrix
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.initHammerJs();
        this.startComponent();

        this.bindEvents();
        this.bindDOMEvents();
        if (platform.checks.isCbox) {
            this.bindRemoteEvents();
        }

        this.hdmiOut2StateHandler();
        this.webRtcOutHandler();

        this.transparentStateHandler({
            index: this.index,
            transparent: this.frameBoxService.isTransparent(this.index)
        });

        this.wipeOutStateHandler({
            index: this.index,
            wipeOut: this.frameBoxService.isWipedOut(this.index)
        });

        if (this.isMatrix) {
            this.emit('framebox.control-bar.update.' + this.index, this.frameBoxService.frameboxes.boxes[this.index]);
        }

        this.backgroundFreezeHandler();
    },

    storeSelectors: function() {
        this.$stateEffect = this.$el.find('.framebox-state-effect');
        this.$header = this.$el.find('.framebox-standard-header');
        this.$hdmiOut2Button = this.$el.find('.framebox-show-hdmi-out-2');
        this.$keyboardButton = this.$el.find('.framebox-show-keyboard');
    },

    /**
     * Initialize HammerJs.
     */
    initHammerJs: function() {
        var tap = new Hammer.Tap({
            event: 'singletap'
        });
        var doubleTap = new Hammer.Tap({
            event: 'doubletap',
            taps: 2,
            interval: 200
        });
        var pinch = new Hammer.Pinch();
        var pan = new Hammer.Pan();
        var hammerRecognizers;

        doubleTap.recognizeWith(tap);
        tap.requireFailure(doubleTap);

        pan.recognizeWith(pinch);
        pinch.requireFailure(pan);

        this.hammer = new Hammer.Manager(this.$el.get(0));

        hammerRecognizers = [doubleTap, tap];

        // Pan and pinch are only used in touch devices. Otherwise we have a bug in safari
        // With range inputs: https://jira.wolfvision-at.intra:8443/browse/RELEASE-1500
        if (platform.checks.isTouchDevice) {
            hammerRecognizers.push(pan, pinch);
        }

        this.hammer.add(hammerRecognizers);
    },

    /**
     * Start special controls components inside the header bar.
     */
    startComponent: function() {
        if (this.options.withoutControlBar) {
            return;
        }

        app.createComponent('controlBar' + this.options.index, {
            type: 'FrameboxControlBar',
            container: this.$header,
            frameboxComponent: this.options.component,
            index: this.options.index,
            appId: this.options.appId,
            title: this.options.titleKey,
            titleIcon: this.options.titleIconKey,
            frameBoxOptions: this.options.frameBoxOptions,
            isFullscreen: false,
            $actionEl: this.$el
        });

        this.$controlBar = this.$el.find('.framebox-control-bar-item');

        if (-1 !== componentControlBarDisabled.indexOf(this.options.component)
            || this.stationService.getLockStatus()) {
            this.hideControlBar();
        } else {
            this.showControlBar();
        }
    },

    /**
     * Remove control bar component.
     */
    stopComponent: function() {
        app.removeComponent('controlBar' + this.options.index);
    },

    /**
     * Bind events.
     */
    bindEvents: function() {
        this.on('framebox.mode.brain', this.onChangeBrainMode.bind(this));
        this.on('framebox.focus', this.focusHandler.bind(this));
        this.on('framebox.in-background.change', _.debounce(this.backgroundFreezeHandler.bind(this), 350));
        this.on('framebox.change.' + this.options.index, this.onFrameboxUpdate.bind(this));
        this.on('framebox.play-state-effect', this.playStateEffect.bind(this));
        this.on('framebox.hdmi-out-2.update', this.hdmiOut2StateHandler.bind(this));
        this.on('framebox.transparent.update', this.transparentStateHandler.bind(this));
        this.on('framebox.wipe-out.update', this.wipeOutStateHandler.bind(this));
        this.on('remote.framebox.focus', this.remoteFocusHandler.bind(this));
        this.on('remote.framebox.toggle', this.toggleFullscreen.bind(this));
        this.on('hdmi-out-2-mode.changed', this.hdmiOut2StateHandler.bind(this));
        this.on('freeze-state.update', _.debounce(this.backgroundFreezeHandler.bind(this), 350));
        this.on('framebox.controls.scrollable.changed', this.onScrollableChanged.bind(this));
        this.on('framebox-input.focused', this.handleInputFocus.bind(this));
        this.on('framebox-input.unfocused', this.handleInputFocus.bind(this));
        this.on('menu.opened', this.updateKeyboardButton.bind(this, false));
        this.on('control-center.opened', this.updateKeyboardButton.bind(this, false));
        this.on('file-browser.opened', this.updateKeyboardButton.bind(this, false));
        this.on('modal.opened', this.updateKeyboardButton.bind(this, false));
        this.on('overlay.opened', this.updateKeyboardButton.bind(this, false));
        this.on('menu.opened', this.updateHdmiOut2.bind(this, false));
        this.on('file-browser.opened', this.updateHdmiOut2.bind(this, false));
        this.on('control-center.opened', this.updateHdmiOut2.bind(this, false));
        this.on('modal.opened', this.updateHdmiOut2.bind(this, false));
        this.on('overlay.opened', this.updateHdmiOut2.bind(this, false));
        this.on('matrix.station-status.update', this.handleMatrixStationUpdate.bind(this));
        this.on('framebox-control-bar.hide', this.onChangeBrainMode.bind(this));
        this.on('webconference.screenshare.state.change', this.webRtcOutHandler.bind(this));
        this.on('control-center.opened', this.backgroundFreezeHandler.bind(this));
        this.on('menu.opened', this.backgroundFreezeHandler.bind(this));
        this.on('file-browser.opened', this.backgroundFreezeHandler.bind(this));

        LayoutManager.on('window.resize', this.onWindowResizeHandler.bind(this));
    },

    /**
     * Bind standard DOM events.
     */
    bindDOMEvents: function() {
        // Keyboard Button event
        if (-1 !== keyboardViews.indexOf(this.options.component)) {
            this.$el.addClass('can-use-touch-keyboard');
            this.$el.on('click', '.framebox-show-keyboard', this.handleOpenKeyboard.bind(this));
        }

        // HammerJS Events
        this.hammer.on('doubletap', this.onDoubleTap.bind(this));
        this.hammer.on('singletap', this.focusHandler.bind(this));

        this.$el.on('click', this.focusHandler.bind(this));

        this.bindControlBarDOMEvents();
        this.bindFrameboxDOMEvents();
    },

    /**
     * Bind click/touch events for window control bar.
     */
    bindControlBarDOMEvents: function() {
        this.$el.on('click', '#framebox-control-bar-title-container', this.toggleFrameboxMode.bind(this));
        this.$el.on('click', '.framebox-option-toggle-control-bar', this.toggleFrameboxMode.bind(this));
        this.$el.on('click', '.framebox-option-close', this.closeFrameBox.bind(this));
        this.$el.on('click', '.framebox-option-toggle-fullscreen', this.openFullscreen.bind(this));
        this.$el.on('click', '.framebox-option-toggle-hdmi-out-2', this.toggleHdmiOut2.bind(this));
        this.$el.on('click', '#framebox-control-bar-submenu-close-container', this.closeSubmenu.bind(this));
    },

    /**
     * Bind all events (click, touch, key and remote) for framebox interactions.
     */
    bindFrameboxDOMEvents: function() {
        if (this.frameboxDOMEventBinding) {
            return;
        }

        // Do not bind touch-events on Window-tablets :), they also trigger mouse-events on touch geasture.
        if (platform.checks.isEdge) {
            this.$el.on('mousedown.ui mouseup.ui', this.onMouseEventsHandler.bind(this));
        } else if (platform.checks.isAndroid) { // Since we support touchevents we only need touch event on android.
            this.$el.on('touchstart.ui touchend.ui', this.onMouseEventsHandler.bind(this));
        } else {
            this.$el.on('mousedown.ui mouseup.ui touchstart.ui touchend.ui', this.onMouseEventsHandler.bind(this));
        }

        this.$el.on('click.standard', this.onClickHandler.bind(this));
        this.$el.on('normalized.mousewheel.up', _.throttle(this.onWheelUp.bind(this), 100));
        this.$el.on('normalized.mousewheel.down', _.throttle(this.onWheelDown.bind(this), 100));
        this.$el.on('mousemove.ui touchmove.ui', this.onMouseEventsHandler.bind(this)); // TODO: touchevents .. send all events without filtering

        this.hammer.on('singletap', this.onTapHandler.bind(this));

        if (platform.checks.isTouchDevice) {
            this.hammer.on('pinch', _.throttle(this.onPinch.bind(this), 200));
            this.hammer.on('pinchin', _.throttle(this.onPinchIn.bind(this), 200));
            this.hammer.on('pinchout', _.throttle(this.onPinchOut.bind(this), 200));
            this.hammer.on('pinchend', _.throttle(this.onPinchEnd.bind(this), 200));
            this.hammer.on('pinchcancel', _.throttle(this.onPinchCancel.bind(this), 200));
            this.hammer.on('pandown', _.throttle(this.onPanDown.bind(this), 100));
            this.hammer.on('panup', _.throttle(this.onPanUp.bind(this), 100));
            this.hammer.on('panleft', _.throttle(this.onPanLeft.bind(this), 100));
            this.hammer.on('panright', _.throttle(this.onPanRight.bind(this), 100));
            this.hammer.on('panend', _.throttle(this.onPanEnd.bind(this), 100));
        }

        this.bindFrameboxKeyEvents();
        this.bindFrameboxRemoteEvents();

        this.frameboxDOMEventBinding = true;
    },

    /**
     * Bind key events for framebox interactions.
     * Events are triggered by remote, touch, click.
     */
    bindFrameboxKeyEvents: function() {
        if (this.frameboxKeyEventBinding) {
            return;
        }

        this.$(document).on('keydown.framebox-' + this.index, this.onKeyDown.bind(this));
        this.$(document).on('keyup.framebox-' + this.index, this.onKeyUp.bind(this));

        if (platform.checks.isAndroid && platform.checks.isChrome && platform.checks.isTouchDevice) {
            this.$(document).off('keypress.framebox-' + this.index, this.onKeyPress.bind(this));
        }

        // Special characters in firefox OS (e.g. Norwegian ø, æ, å)
        // See https://www.fxsitecompat.com/en-CA/docs/2018/keydown-and-keyup-events-are-now-fired-during-ime-composition/
        if (platform.checks.isFirefox) {
            this.$(document).on('compositionupdate.framebox-' + this.index, this.onKeyDown.bind(this));
            this.$(document).on('compositionend.framebox-' + this.index, this.onKeyUp.bind(this));
        }

        this.frameboxKeyEventBinding = true;
    },

    /**
     * Bind events for framebox interaction with the remote control.
     */
    bindFrameboxRemoteEvents: function() {
        if (this.frameboxRemoteEventBinding) {
            return;
        }

        this.on('remote.left.keydown', this.onRemoteKeyDown.bind(this, 'left'));
        this.on('remote.right.keydown', this.onRemoteKeyDown.bind(this, 'right'));
        this.on('remote.up.keydown', this.onRemoteKeyDown.bind(this, 'up'));
        this.on('remote.down.keydown', this.onRemoteKeyDown.bind(this, 'down'));
        this.on('remote.pageUp.keydown', this.onRemoteKeyDown.bind(this, 'pageUp'));
        this.on('remote.pageDown.keydown', this.onRemoteKeyDown.bind(this, 'pageDown'));

        this.on('remote.left.keyup', this.onRemoteKeyUp.bind(this, 'left'));
        this.on('remote.right.keyup', this.onRemoteKeyUp.bind(this, 'right'));
        this.on('remote.up.keyup', this.onRemoteKeyUp.bind(this, 'up'));
        this.on('remote.down.keyup', this.onRemoteKeyUp.bind(this, 'down'));

        this.on('remote.enter.keydown', this.onRemoteKeyDown.bind(this, 'enter'));
        this.on('remote.enter.keyup', this.onRemoteKeyUp.bind(this, 'enter'));

        this.frameboxRemoteEventBinding = true;
    },

    /**
     * Unbind all events to stop interaction with the framebox.
     * E.g. freeze mode gets enabled, overlay is opened.
     */
    unbindFrameboxDOMEvents: function() {
        if (!this.frameboxDOMEventBinding) {
            return;
        }

        this.$el.off('click.standard');
        this.$el.off('normalized.mousewheel.up');
        this.$el.off('normalized.mousewheel.down');
        this.$el.off('mousemove.ui');
        this.$el.off('mousedown.ui mouseup.ui touchstart.ui touchend.ui');

        if (this.hammer) {
            this.hammer.off('singletap');
            this.hammer.off('pinch');
            this.hammer.off('pinchin');
            this.hammer.off('pinchout');
            this.hammer.off('pinchend');
            this.hammer.off('pinchcancel');
            this.hammer.off('pandown');
            this.hammer.off('panup');
            this.hammer.off('panleft');
            this.hammer.off('panright');
            this.hammer.off('panend');
        }

        this.unbindFrameboxKeyEvents();
        this.unbindFrameboxRemoteEvents();

        this.frameboxDOMEventBinding = false;
    },

    /**
     * Unind key events to stop framebox interactions.
     * Avoid that two events are triggered at the same time
     * (e.g. control bar event and framebox event at the same time)
     */
    unbindFrameboxKeyEvents: function() {
        if (!this.frameboxKeyEventBinding) {
            return;
        }

        this.$(document).off('keyup.framebox-' + this.index);
        this.$(document).off('keydown.framebox-' + this.index);
        this.$(document).off('keypress.framebox-' + this.index);
        this.$(document).off('compositionupdate.framebox-' + this.index);
        this.$(document).off('compositionend.framebox-' + this.index);

        this.frameboxKeyEventBinding = false;
    },

    /**
     * Unbind events to stop framebox interaction with the remote control.
     * E.g. freeze mode gets enabled, overlay is opened.
     */
    unbindFrameboxRemoteEvents: function() {
        if (!this.frameboxRemoteEventBinding) {
            return;
        }

        this.off('remote.left.keydown');
        this.off('remote.right.keydown');
        this.off('remote.up.keydown');
        this.off('remote.down.keydown');
        this.off('remote.enter.keydown');
        this.off('remote.pageUp.keydown');
        this.off('remote.pageDown.keydown');

        this.off('remote.left.keyup');
        this.off('remote.right.keyup');
        this.off('remote.up.keyup');
        this.off('remote.down.keyup');
        this.off('remote.enter.keyup');

        this.frameboxRemoteEventBinding = false;
    },

    /**
     * Bind all remote events for control bar interaction.
     */
    bindRemoteEvents: function() {
        this.on('remote.zoom-in.keydown', this.onRemoteKeyDown.bind(this, 'zoom-in'));
        this.on('remote.zoom-out.keydown', this.onRemoteKeyDown.bind(this, 'zoom-out'));
        this.on('remote.focus-in.keydown', this.onRemoteKeyDown.bind(this, 'focus-in'));
        this.on('remote.focus-out.keydown', this.onRemoteKeyDown.bind(this, 'focus-out'));
        this.on('remote.zoom-in.keyup', this.onRemoteKeyUp.bind(this, 'zoom-in'));
        this.on('remote.zoom-out.keyup', this.onRemoteKeyUp.bind(this, 'zoom-out'));
        this.on('remote.focus-in.keyup', this.onRemoteKeyUp.bind(this, 'focus-in'));
        this.on('remote.focus-out.keyup', this.onRemoteKeyUp.bind(this, 'focus-out'));
        this.on('remote.preset', this.onRemotePreset.bind(this, 'preset'));
    },

    /**
     * Update framebox options and trigger a control bar update of specific framebox.
     * @param framebox.options Updated framebox options
     */
    onFrameboxUpdate: function(framebox) {
        var component = this.frameBoxService.getConfig(framebox.contentType).component;

        /**
         * RELEASE-2843: USB-Input has content type webcam.
         * If there is a VZ connected, stay component "FrameBoxVisualizer" to show VZ controls.
         */
        if (framebox.contentType === 'webcam') {
            component = framebox.component;
        }

        this.options.frameBoxOptions = framebox.options;

        // When matrix framebox changes to matrix control framebox, sometimes the component is not destroyed.
        if (this.options.component !== component) {
            this.stopComponent();

            this.options.component = component;
            this.startComponent();
        }

        if (framebox.webRtcOut !== this.webRtcOut) {
            this.webRtcOut = framebox.webRtcOut;
            this.webRtcOutHandler();
        }
        this.emit('framebox.control-bar.update.' + this.index, this.options);
    },

    /**
     * Handle framebox url focus.
     *
     * @param index - framebox index
     * @param focused - url input focused
     */
    handleInputFocus: function(index, focused) {
        if (!focused) {
            this.preventKeyEvents = false;

            return;
        }

        if (this.mode.getState() === modes.brain && index === this.options.index) {
            this.preventKeyEvents = true;
        }
    },

    /**
     * Set active framebox if framebox is focused.
     * @param data.index framebox index
     */
    focusHandler: function(data) {
        var index = data.index || this.index;
        var keyboardAction = (data.target && $(data.target).is('.has-action'));

        this.frameBoxService.setActiveFrameBox(index);
        this.emit('active-framebox.changed', index, this.options.component, keyboardAction);
    },

    /**
     * Set active framebox and open/close control bar if framebox is focused by the remote.
     * @param data.index framebox index
     */
    remoteFocusHandler: function(data) {
        if (data.index !== this.options.index) {
            return;
        }

        this.frameBoxService.setActiveFrameBox(data.index);

        if (this.activeState.getState() === activeStates.active && !this.frameBoxService.isInBackground) {
            this.toggleFrameboxMode();
        }
    },

    /**
     * Call event to close submenu.
     */
    closeSubmenu: function() {
        this.emit('framebox.submenu.close');
    },

    /**
     * Open fullscreen.
     *
     * @param data.index Framebox Index
     */
    toggleFullscreen: function(data) {
        if (!this.frameBoxService.frameboxes.boxes[data.index] || data.index === this.index) {
            this.openFullscreen();
        }
    },

    /**
     * Send Fullscreen-Open Event.
     */
    openFullscreen: function() {
        if (this.stationService.getLimitedAccessStatus()) {
            return;
        }

        this.emit('framebox.fullscreen.open', {
            index: this.index
        });

        if (!platform.checks.isCbox && platform.checks.isTouchDevice) {
            this.emit('framebox.controls.check', {
                isScrollable: this.controlsScrollable,
                index: this.index
            });
        }
    },

    /**
     * Plays the State effect, this will open a modal with a icon and a animation.
     * E.g. freeze, VZ states,...
     *
     * @param {object} options
     */
    playStateEffect: function(options) {
        var index = options.index;
        var className = options.className;

        if (index !== this.index) {
            return;
        }

        if (this.lastStateClassName) {
            this.$stateEffect
                .removeClass('play-framebox-state-effect')
                .removeClass(this.lastStateClassName);
        }

        this.lastStateClassName = className;
        this.$stateEffect.show()
            .removeClass('play-framebox-state-effect')
            .addClass(this.lastStateClassName);

        // Wait for browser.
        setTimeout(function() {
            this.$stateEffect.addClass('play-framebox-state-effect');
        }.bind(this), 100);

        this.$stateEffect.one('transitionend MSTransitionEnd webkitTransitionEnd oTransitionEnd',
            function() {
                this.$stateEffect
                    .removeClass('play-framebox-state-effect')
                    .removeClass(this.lastStateClassName);
            }.bind(this));
    },

    /**
     * Handle freeze mode ON/OF and foreground/background handling
     * (E.g. modal, overlay, etc. is opened).
     */
    backgroundFreezeHandler: function() {
        if (this.frameBoxService.isInBackground || this.freezeService.isFreeze()) {
            if (this.activeState.getState() === activeStates.active) {
                this.activeState.changeState(activeStates.inactive);
            }
        } else if (this.activeState.getState() === activeStates.inactive) {
            this.activeState.changeState(activeStates.active);
        }
    },

    /**
     * Set controls are scrollable storage.
     *
     * @param {Object} data
     * @param {index} data.index
     * @param {Boolean} data.isScrollable
     */
    onScrollableChanged: function(data) {
        if (this.index === data.index) {
            this.controlsScrollable = data.isScrollable;
        }
    },

    /**
     * Switch icon depending on hdmi out 2 (aux) state.
     */
    hdmiOut2StateHandler: function() {
        this.checkForBackdrop();
        this.hasHdmiOut2Button();
    },

    /**
     * Check if framebox backdrop should be shown (in Moderator mode) and update.
     */
    checkForBackdrop: function() {
        this.updateBackdrop(!this.frameBoxService.isHdmiOut2(this.index), this.index);

        if (!this.outputService.isModerator()) {
            this.updateBackdrop(false, 0);
            this.updateBackdrop(false, 1);
            this.updateBackdrop(false, 2);
            this.updateBackdrop(false, 3);
        }
    },

    /**
     * Show/hide framebox backdrop.
     *
     * @param show true/false
     * @param index framebox index to update
     */
    updateBackdrop: function(show, index) {
        if (show && !this.options.hideBackdrop) {
            this.$el.find('#framebox-backdrop-' + index).show();
        } else {
            this.$el.find('#framebox-backdrop-' + index).hide();
        }
    },

    /**
     * Show HDMI Out 2 Icon (Aux) if content mode is set and content recording mode is 'aux'.
     */
    hasHdmiOut2Button: function() {
        var isHdmiOut2 = this.frameBoxService.isHdmiOut2(this.index);

        if (this.outputService.isOverrideMirror()) {
            this.updateHdmiOut2(false);

            return;
        }

        if (!this.outputService.isContent()) {
            return;
        }

        if (isHdmiOut2
            && this.outputService.contentModeRecording.currentState === 'aux'
            && !this.overlayHandlerService.hasOpenOverlay()
        ) {
            this.updateHdmiOut2(true);
        } else {
            this.updateHdmiOut2(false);
        }
    },

    /**
     * Show or hide HDMI Out 2 Button.
     *
     * @param show true/false
     */
    updateHdmiOut2: function(show) {
        if (show) {
            this.$hdmiOut2Button.removeClass('hidden');
        } else {
            this.$hdmiOut2Button.addClass('hidden');
        }
    },

    /**
     * Show or hide Keyboard Button.
     *
     * @param show true/false
     */
    updateKeyboardButton: function(show) {
        if (show) {
            this.$keyboardButton.removeClass('hidden');
        } else {
            this.$keyboardButton.addClass('hidden');
        }
    },

    /**
     * Check if a black background on remote browsers should be shown.
     *
     * @param {Object} options
     * @param {Number} options.index
     * @param {Boolean} options.transparent
     */
    transparentStateHandler: function(options) {
        if (platform.checks.isCbox || this.index !== options.index) {
            return;
        }

        this.updateTransparentOverlay(options.transparent, this.index);
    },

    /**
     * Show a black background on remote browsers.
     * @param show true/false
     * @param index framebox index
     */
    updateTransparentOverlay: function(show, index) {
        if (show) {
            this.$el.find('#framebox-transparent-' + index).show();
        } else {
            this.$el.find('#framebox-transparent-' + index).hide();
        }
    },

    /**
     * Check if a black background on remote browsers should be shown.
     *
     * @param {Object} options
     * @param {Number} options.index
     * @param {Boolean} options.wipeOut
     */
    wipeOutStateHandler: function(options) {
        if (platform.checks.isCbox || this.index !== options.index) {
            return;
        }

        this.updateWipeOutOverlay(options.wipeOut, this.index);
    },

    /**
     * Show a black background on remote browsers.
     * @param show true/false
     * @param index framebox index
     */
    updateWipeOutOverlay: function(show, index) {
        if (show) {
            this.$el.find('#framebox-wipe-out-' + index + ' .wipe-out-text').html(this.frameBoxService.frameboxes.boxes[index].options.DeviceName);
            this.$el.find('#framebox-wipe-out-' + index).show();
        } else {
            this.$el.find('#framebox-wipe-out-' + index).hide();
        }
    },

    /**
     * Update WebRtcOut: When framebox is shared (Zoom or WebRTC), highlight window.
     */
    webRtcOutHandler: function() {
        if (this.webRtcOut && this.webconferenceService.screenShareActive) {
            this.$el.addClass('webRtcOut');

            return;
        }

        this.$el.removeClass('webRtcOut');
    },

    /**
     * Open Keyboard on Touchdevices.
     */
    handleOpenKeyboard: function() {
        this.keyboard.setManuallyOpened(true);

        if (platform.checks.isIOS) {
            this.keyboard.open(this.keyboard, 'ios', this.index);
        } else if (platform.checks.isAndroid) {
            this.keyboard.open(this.keyboard, 'android', this.index);
        } else {
            this.keyboard.open();
        }
    },

    /**
     * Send an event to toggle hdmi out 2 framebox.
     */
    toggleHdmiOut2: function() {
        this.emit('framebox.toggle.hdmi-out-2', { index: this.index });
    },

    /**
     * Send a close framebox event.
     */
    closeFrameBox: function() {
        if (this.customClose) {
            this.emit('framebox.close.' + this.options.contentType, { index: this.index });

            return;
        }

        this.emit('framebox.close', { index: this.index });
    },

    /**
     * Send an event to toggle framebox mode of a framebox.
     */
    toggleFrameboxMode: function() {
        if (this.mode.getState() === modes.pinky) {
            this.mode.changeState(modes.brain);
        } else {
            this.mode.changeState(modes.pinky);
        }
    },

    /**
     * If a framebox changed mode to brain mode set the others to pinky mode.
     *
     * @param index Index of framebox in brain mode.
     */
    onChangeBrainMode: function(options) {
        if ((options.index !== this.index || options.force) && this.mode.getState() === modes.brain) {
            this.setPinkyMode();
        }
    },

    /**
     * Handle mode changes.
     *
     * '> pinky': Start pinky mode (former standard mode or state 'is-invisible'). Control bar is hidden.
     * '> brain': Start brain mode (former control mode and state 'focused'). Control bar is shown.
     */
    addStateTransitions: function() {
        this.mode.addTransitions({
            '> pinky': function() {
                this.hideControls(true);
                this.$el.removeClass('is-brain-mode');

                this.emit('framebox.submenu.close');
                this.emit('framebox.mode.pinky', {
                    index: this.index
                });

                this.unbindFrameboxKeyEvents();
                this.bindFrameboxKeyEvents();
                this.bindFrameboxRemoteEvents();
            },

            '> brain': function() {
                this.showControls();
                this.$el.addClass('is-brain-mode');

                this.emit('framebox.mode.brain', {
                    index: this.index
                });

                this.unbindFrameboxRemoteEvents();
            }
        });

        this.activeState.addTransitions({
            '> inactive': function() {
                this.setPinkyMode();

                this.unbindFrameboxDOMEvents();

                // Update Buttons
                this.updateHdmiOut2(false);
                this.updateKeyboardButton(false);

                this.keyboard.close();
            },

            '> active': function() {
                this.bindFrameboxDOMEvents();

                // Update Buttons
                setTimeout(function() {
                    this.hasHdmiOut2Button();
                    this.updateKeyboardButton(this.hasKeyboardButton());
                }.bind(this), 300);
            }
        });
    },

    /**
     * Show control bar.
     */
    showControls: function() {
        if (this.options.withoutControlBar) {
            return;
        }

        this.$header.find('.framebox-control-bar-item-inner').addClass('animating');

        this.$header
            .stop()
            .animate({
                width: '100%'
            }, {
                duration: DURATION,
                complete: function() {
                    this.$header.addClass('has-controls');
                    this.$controlBar.addClass('has-controls');
                    this.$header.find('.framebox-control-bar-item-inner').removeClass('animating');

                    this.emit('reinit.scrolling.' + this.index);
                    this.emit('framebox.controls.focus', {
                        index: this.index
                    });
                }.bind(this)
            });
    },

    /**
     * Hide control bar.
     *
     * @param animate {boolean} true/false
     */
    hideControls: function(animate) {
        if (this.options.withoutControlBar) {
            return;
        }

        this.$header.removeClass('has-controls');
        this.$controlBar.removeClass('has-controls');

        if (animate) {
            this.$header.find('.framebox-control-bar-item-inner').addClass('animating');

            this.$header
                .stop()
                .animate({
                    width: platform.checks.isMediumSize() ? '145px' : '190px' // $frameboxBarTitleWidth + toggle btn
                }, {
                    duration: DURATION,
                    complete: function() {
                        this.$header.find('.framebox-control-bar-item-inner').removeClass('animating');
                    }.bind(this)
                });
        } else {
            this.$header
                .css({
                    width: platform.checks.isMediumSize() ? '145px' : '190px' // $frameboxBarTitleWidth + toggle btn
                });
        }
    },

    /**
     * Show control bar.
     */
    showControlBar: function() {
        if (-1 !== componentControlBarDisabled.indexOf(this.options.component)
            || this.stationService.getLockStatus() || this.options.withoutControlBar) {
            return;
        }

        this.$controlBar.removeClass('has-controls');
        this.$header.removeClass('has-controls');
        this.$header.css('width', platform.checks.isMediumSize() ? '145px' : '190px'); // $frameboxBarTitleWidth + toggle btn

        this.$header.show();
    },

    /**
     * Hide control bar.
     */
    hideControlBar: function() {
        this.setPinkyMode();
        this.$header.hide();
    },

    /**
     * Change state to pinky mode (if it has a collapsible control bar)
     */
    setPinkyMode: function() {
        if (this.options.withoutControlBar) {
            return;
        }

        this.mode.changeState(modes.pinky);
    },

    /**
     * Update control bar if the status if this is a Matrix station and the status has changed.
     */
    handleMatrixStationUpdate: function() {
        if (this.stationService.getLockStatus()) {
            this.hideControlBar();
            this.unbindFrameboxDOMEvents();
        } else {
            this.showControlBar();
            this.bindFrameboxDOMEvents();
        }
    },

    /**
     * Checks if this framebox has an open Keyboard-Button.
     *
     * Show button on Touch-device with content-type "Office365"
     * or show button on IOS-Device with content-types which need a keyboard.
     *
     * @return {Boolean}
     */
    hasKeyboardButton: function() {
        if (
            -1 !== keyboardViews.indexOf(this.options.component)
            && (
                platform.checks.isIOS
                || (-1 !== contentTypeKeyboardEnabled.indexOf(this.options.contentType) && platform.checks.isTouchDevice)
            ) && !platform.checks.isWindowsOS
        ) {
            return true;
        }

        return false;
    },

    /**
     * Handle window resizing.
     */
    onWindowResizeHandler: function() {
        if (this.$header.hasClass('has-controls')) {
            setTimeout(function() {
                this.emit('reinit.scrolling.' + this.index);
            }.bind(this), DURATION);
        } else {
            this.$header
                .css({
                    width: platform.checks.isMediumSize() ? '145px' : '190px' // $frameboxBarTitleWidth + toggle btn
                });
        }
    },

    /**
     * On Double-Tap event. Open Fullscreen on double tab.
     *
     * @param {Object} event
     * @param {String} event.target
     * @param {Number} event.tapCount
     * @param {String} event.type
     */
    onDoubleTap: function(event) {
        var $el = this.$(event.target);

        this.frameBoxService.supportsDoubleTap(this.index)
            .then(function(doubleTap) {
                if (doubleTap.supported && !this.freezeService.isFreeze()) {
                    if ($el.closest('.framebox-header').length > 0) {
                        return;
                    }

                    // Singletap on windows chrome is recognized as double tap, therefore added a workaround.
                    // TODO: Remove if bug is fixed in hammer.js library. --> Last check on May 2017.
                    if (platform.checks.isWindowsOS
                            && platform.checks.isChrome
                            && !platform.checks.isEdge
                            && platform.checks.isTouchDevice
                    ) {
                        if (event.tapCount > 2 && event.type === 'doubletap') {
                            this.openFullscreen();
                        }
                    } else {
                        this.openFullscreen();
                    }
                }
            }.bind(this));
    },

    onKeyDown: function(event) {
        // Prevent sending refresh commands to the overlay browser to avoid race condition (reloading)
        // Block Tab-Key otherwise we will loose the focus on the framebox.
        if ('Tab' === event.key
            || 'F5' === event.key
            || (event.keyCode === '17' && event.ctrlKey)
        ) {
            event.preventDefault();
        }

        if (this.remoteService.isSpecialKey(event)) {
            return;
        }

        setTimeout(function() {
            if (this.frameBoxService.activeFrameBox !== this.index
                || this.preventKeyEvents) {
                return;
            }

            event = this.fixAndroidEvent(event);

            // Prevent from sending arrow and enter key if framebox is in brain mode.
            if (event.isOnScreenKeyboardEvent || this.options.withoutControlBar
                || !(this.mode.getState() === modes.brain && PREVENT_KEYCODES.indexOf(event.which) >= 0)) {
                this.emit('framebox.standard.keydown', event, this.index);
            }
        }.bind(this), 0);
    },

    onKeyUp: function(event) {
        // Prevent sending refresh commands to the overlay browser to avoid race condition (reloading)
        if (event.key === 'F5' || (event.ctrlKey && event.keyCode === '17')) {
            this.emit('framebox.standard.keyup', event, this.index);

            event.preventDefault();
        }

        setTimeout(function() {
            if (this.frameBoxService.activeFrameBox !== this.index
                || this.preventKeyEvents) {
                return;
            }

            event = this.fixAndroidEvent(event);

            this.emit('framebox.standard.keyup', event, this.index);
        }.bind(this), 0);
    },

    onKeyPress: function(event) {
        if (this.frameBoxService.activeFrameBox !== this.index
            || this.preventKeyEvents) {
            return;
        }

        this.emit('framebox.standard.keypress', event, this.index);
    },

    onMouseEventsHandler: function(event) {
        // Click on header. Because we don't unbind the framebox events anymore.
        if ($(event.target).closest('.framebox-standard-header').length > 0) {
            return;
        }

        // RELEASE-2687: prevent sending mouseevent if show keyboard was clicked.
        if (event.target.parentElement.id !== 'framebox-show-keyboard') {
            this.emit('framebox.standard.mouseevent', event, this.index);
        }
    },

    onPanEnd: function(event) {
        var direction;

        switch (event.direction) {
            case Hammer.DIRECTION_LEFT:
                direction = 'left';
                break;
            case Hammer.DIRECTION_RIGHT:
                direction = 'right';
                break;
            case Hammer.DIRECTION_UP:
                direction = 'up';
                break;
            case Hammer.DIRECTION_DOWN:
                direction = 'down';
                break;
        }

        if (direction) {
            this.emit('framebox.standard.pan' + direction + '-single', {
                index: this.index,
                appId: this.appId
            });
        }
    },

    onWheelUp: function() {
        this.emit('framebox.standard.mousewheel-up', {
            index: this.index,
            appId: this.appId
        });
    },

    onWheelDown: function() {
        this.emit('framebox.standard.mousewheel-down', {
            index: this.index,
            appId: this.appId
        });
    },

    onPanDown: function(event) {
        var $el = $(event.target);

        if (!$el.hasClass('has-action')) {
            return;
        }

        this.emit('framebox.standard.pandown', {
            index: this.index,
            appId: this.appId,
            x: event.pointers.length > 0 ? event.pointers[0].x : 0,
            y: event.pointers.length > 0 ? event.pointers[0].y : 0
        });
    },

    onPanUp: function(event) {
        var $el = $(event.target);

        if (!$el.hasClass('has-action')) {
            return;
        }

        this.emit('framebox.standard.panup', {
            index: this.index,
            appId: this.appId,
            x: event.pointers.length > 0 ? event.pointers[0].x : 0,
            y: event.pointers.length > 0 ? event.pointers[0].y : 0
        });
    },

    onPanLeft: function(event) {
        var $el = $(event.target);

        if (!$el.hasClass('has-action')) {
            return;
        }

        this.emit('framebox.standard.panleft', {
            index: this.index,
            appId: this.appId,
            x: event.pointers.length > 0 ? event.pointers[0].x : 0,
            y: event.pointers.length > 0 ? event.pointers[0].y : 0
        });
    },

    onPanRight: function(event) {
        var $el = $(event.target);

        if (!$el.hasClass('has-action')) {
            return;
        }

        this.emit('framebox.standard.panright', {
            index: this.index,
            appId: this.appId,
            x: event.pointers.length > 0 ? event.pointers[0].x : 0,
            y: event.pointers.length > 0 ? event.pointers[0].y : 0
        });
    },

    onPinch: function(event) {
        this.emit('framebox.standard.pinch', {
            index: this.index,
            appId: this.appId,
            event: event
        });
    },

    onPinchIn: function(event) {
        this.emit('framebox.standard.pinchin', {
            index: this.index,
            appId: this.appId,
            event: event
        });
    },

    onPinchOut: function(event) {
        this.emit('framebox.standard.pinchout', {
            index: this.index,
            appId: this.appId,
            event: event
        });
    },

    onPinchEnd: function(event) {
        this.emit('framebox.standard.pinchend', {
            index: this.index,
            appId: this.appId,
            event: event
        });
    },

    onPinchCancel: function(event) {
        this.emit('framebox.standard.pinchcancel', {
            index: this.index,
            appId: this.appId,
            event: event
        });
    },

    onClickHandler: function(event) {
        var $el = this.$(event.target);

        if (!this.freezeService.isFreeze()) {
            if ($el.is('.has-action')) {
                this.emit('framebox.standard.click', {
                    index: this.index,
                    appId: this.appId
                });
            }
        }
    },

    onTapHandler: function(event) {
        var $el = this.$(event.target);

        if (!this.freezeService.isFreeze()) {
            if ($el.is('.has-action')) {
                this.emit('framebox.standard.tap', {
                    index: this.index,
                    appId: this.appId,
                    event: event
                });
            }
        }
    },

    onRemotePreset: function() {
        if (this.frameBoxService.activeFrameBox !== this.index) {
            return;
        }

        this.emit('framebox.standard.preset', {
            index: this.index,
            appId: this.appId
        });
    },

    onRemoteKeyDown: function(action) {
        if (this.frameBoxService.activeFrameBox !== this.index) {
            return;
        }

        this.emit('framebox.standard.' + action + '.keydown', {
            index: this.index,
            appId: this.appId
        });
    },

    onRemoteKeyUp: function(action) {
        if (this.frameBoxService.activeFrameBox !== this.index) {
            return;
        }

        this.emit('framebox.standard.' + action + '.keyup', {
            index: this.index,
            appId: this.appId
        });
    },

    destroy: function() {
        this.unbindFrameboxDOMEvents();
        this.stopComponent();
    },

    /**
     * Android devices will return a wrong key-event on every key.
     * FIXME: @google keyCode on Key-Events.
     *
     * @param {jQuey.Event} event
     * @return {jQuey.Event}
     */
    fixAndroidEvent: function(event) {
        if (platform.checks.isAndroid && platform.checks.isChrome && platform.checks.isTouchDevice) {
            if (event.which === 229) {
                var value = event.originalEvent.target.value;
                var keyCode = this.keyboard.getKeyCode(value);
                var character = value.slice(0, 1);

                // RELEASE-3871
                if (event.type === 'keyup') {
                    event.originalEvent.target.value = '';
                }

                event = $.Event(
                    event.type,
                    {
                        which: keyCode, // 65
                        key: character, // A
                        keyCode: keyCode, // 65
                        isOnScreenKeyboardEvent: true,
                        originalEvent: {
                            repeat: false,
                            code: keyMapper.characterToKeyCharacter(character), // KeyA
                            key: character, // A
                            keyCode: keyCode, // 65
                            type: event.type,
                            which: keyCode // 65
                        }
                    }
                );
            }
        }

        return event;
    }

});
