// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"lcs-settings\" name=\"input-settings\">\n    <div class=\"row settings-list-row\"\n         data-nav-area=\".is-focusable\"\n         data-nav-trapped>\n        <div class=\"col-xs-12\">\n            <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.lecture_capture",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":48}}}))
    + "</h2>\n        </div>\n    </div>\n\n    <div class=\"row settings-list-secundary\" data-nav-area=\"select, .is-focusable\" data-nav-trapped>\n        <div class=\"col-xs-6\">\n            <div id=\"lcs-type-select\"></div>\n        </div>\n        <div class=\"col-xs-6\"></div>\n    </div>\n\n    <div id=\"lecture-capture-settings-container\" class=\"lecture-capture-settings-container\">\n        <div class=\"row settings-list-secundary\"\n             data-nav-area=\"select, .is-focusable\"\n             data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div id=\"lcs-ip-camera-mode-select\"></div>\n            </div>\n            <div class=\"col-xs-6\" data-panopto-view>\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"filename\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.lcs_file_prefix",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":62},"end":{"line":26,"column":94}}}))
    + "</label>\n                    <input id=\"filename\"\n                           type=\"text\"\n                           class=\"input is-focusable\"\n                           name=\"filename\"\n                           data-validation=\"checkCharacters\"\n                           data-not-allowed=\". / §\"/>\n                    <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\" id=\"filename-error\">\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.characters_not_allowed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":24},"end":{"line":35,"column":78}}}))
    + "\n                    </span>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"ingest-time-settings-container\"\n             class=\"row settings-list-secundary\"\n             data-nav-area=\"select, .is-focusable\"\n             data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div id=\"lcs-mode-select\"></div>\n            </div>\n\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"startTime\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.lcs_time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":63},"end":{"line":52,"column":88}}}))
    + "</label>\n\n                    <input id=\"startTime\"\n                           type=\"text\"\n                           class=\"input is-focusable\"\n                           name=\"startTime\"\n                           placeholder=\"00:00\"\n                           data-validation=\"checkIsCorrectTime\"/>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row settings-list-secundary settings-list-info lcs-hdcp-deactivation-info hidden\">\n        <div class=\"col-xs-12\">\n            <p class=\"input-info-message error-message\">\n                "
    + alias3((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias2).call(alias1,depth0,{"name":"tr","hash":{"key":"settings.hdcp_deactivation_info"},"data":data,"loc":{"start":{"line":68,"column":16},"end":{"line":68,"column":65}}}))
    + "\n            </p>\n        </div>\n    </div>\n\n    <div id=\"form-action-container\" class=\"form-primary-background\"></div>\n</form>\n";
},"useData":true});
