'use strict';

require('./status-widget/status-widget');
require('./action-widget/action-widget');

const platform = require('../../../platform/platform');
const app = require('../../app');
const rbac = require('../../../rbac/rbac');
const statusBarTpl = require('./status-bar.hbs');
const { statusIcons } = require('../../framebox-types');
const formatConnectionId = require('../../helper').formatConnectionId;

/**
 * Stream Input Health States
 * @type {{none: string, ok: string, error: string}}
 */
var healthState = {
    none: 'none',
    ok: 'ok',
    error: 'error'
};

/**
 * Network device discovery states
 * @type {{lan1: string, lan2: string, wlan: string}}
 */
var networkDeviceDiscovery = {
    lan1: 'notSupportedLan1',
    lan2: 'notSupportedLan2',
    wlan: 'notSupportedWlan'
};

var states = {
    visible: 'visible',
    hidden: 'hidden'
};

/**
 * StatusBar Component
 */
app.component('StatusBar', {
    template: statusBarTpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.liveStream = this.getService('LiveStreamService');
        this.frontendSettings = this.getService('FrontendSettings');
        this.frameboxService = this.getService('FrameBoxService');
        this.annotationService = this.getService('AnnotationService');
        this.authService = this.getService('AuthenticationService');
        this.deviceService = this.getService('DeviceService');
        this.isDualProjection = this.deviceService.isCboxProDualProjection();
        this.screensaverService = this.getService('ScreensaverService');
        this.stationService = this.getService('StationService');

        if (this.options.vMeeting) {
            this.isVMeetingStatusBar = true;
            this.sessionMgmtService = this.getService('SessionManagementService');
        }

        this.showStatusBar = true;
        this.mirrorPinProjection = true;
        this.moderatorPinHdmi2 = true;
        this.userPinHdmi2 = true;
        this.isFullscreen = false;
        this.pinMirrorPop = false;

        this.state = this.createStateMachine({
            state: states.visible,
            states: states
        });

        this.bindEvents();
        this.hide();
        this.updateStatusBarVisibility();

        app.emit('main-loop.fast.start', {
            id: 'screensaver'
        });
    },

    serialize: function() {
        return {
            isCbox: platform.checks.isCbox,
            isCboxPure: this.deviceService.isCboxPure(),
            isCboxPureMini: this.deviceService.isCboxPureMini(),
            lightUi: this.sessionMgmtService ? !this.sessionMgmtService.darkUi : false,
            isDualProjection: this.deviceService.isCboxProDualProjection(),
            isControlScreen: platform.checks.isCboxControlScreen,
            isRemote: platform.checks.isRemote,
            hasActionWidgets: rbac.hasAccess('ControlScreen', 'show', true)
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.loopUpdateHandler();
        this.addStateTransitions();

        // TODO: Rework widget-view if multiple statusbar widget instances are needed.
        if (!this.options.vMeeting) {
            this.startWidgetComponent();
            this.startMirrorPinComponent();

            // Check if action widget should be shown
            if (rbac.hasAccess('ControlScreen', 'show', true)) {
                this.startActionWidgetComponent();
            }
        }

        if (true === platform.checks.isCbox && !platform.checks.isCboxControlScreen) {
            this.$el.addClass('is-big');
        }

        this.handleVMeetingStatusBar();
    },

    /**
     * Handle status bar when vMeeting is active.
     */
    handleVMeetingStatusBar: function() {
        if (!this.isVMeetingStatusBar) {
            return;
        }

        if (this.sessionMgmtService.showStatusBar) {
            this.show();
        } else {
            this.hide();
        }

        if (!this.sessionMgmtService.darkUi) { // Light status bar
            this.$statusBarContainer
                .addClass('lightUi')
                .find('.marquee-content').addClass('lightUi');
        } else {
            this.$statusBarContainer
                .removeClass('lightUi')
                .find('.marquee-content').removeClass('lightUi');
        }
    },

    storeSelectors: function() {
        this.$statusBarContainer = this.$el.find('#status-bar');
        this.$castId = this.$el.find('#status-bar-cast-id');
        this.$lan1 = this.$el.find('#device-lan1-ip');
        this.$lan2 = this.$el.find('#device-lan2-ip');
        this.$wlan = this.$el.find('#device-wlan-ip');
        this.$ssid = this.$el.find('#device-ssid-container');
        this.$pinMirror = this.$el.find('#mirror-pin');
        this.$moderatorPin = this.$el.find('#moderator-pin');
        this.$mirrorPinContainer = this.$(document).find('#pin-container');
        this.$pinCollaboration = this.$el.find('#user-pin');
        this.$connectionId = this.$el.find('#status-bar-connection-id');
    },

    bindEvents: function() {
        if (!platform.checks.isCboxAux) {
            this.on('status-bar.hide', this.hide.bind(this));
            this.on('status-bar.show', this.show.bind(this));
            this.on('framebox.fullscreen.open', this.show.bind(this));
            this.on('framebox.fullscreen.close', this.hide.bind(this));
        }

        this.on('status-bar.update.ipcam-health-status', this.updateIpCamHealthStatus.bind(this));
        this.on('status-bar.update.box-name', this.renderBoxName.bind(this));
        this.on('main-loop.update', this.loopUpdateHandler.bind(this));
        this.on('main-loop.update.status-bar', this.loopUpdateHandler.bind(this));
        this.on('main-loop.update.pin', this.updatePin.bind(this));

        this.on('status-widget.item.updated', this.handleWidgetMarquee.bind(this));
        this.on('status-widget.item.appended', this.handleWidgetMarquee.bind(this));
        this.on('status-widget.item.removed', this.handleWidgetMarquee.bind(this));

        this.on('station-status.push', this.hide.bind(this));
        this.on('station-status.lock', this.hide.bind(this));
        this.on('station-status.standard', this.show.bind(this));

        if (this.isVMeetingStatusBar) {
            this.on('session-mgmt.update-ui', this.handleVMeetingStatusBar.bind(this));
        }
    },

    /**
     * Show/hide divider between status widgets and action widgets and resize widget marquee.
     */
    handleWidgetMarquee: function(nrOfStatusWidgets) {
        const divider = this.$el.find('.divider');

        if (divider) {
            if (nrOfStatusWidgets > 0) {
                divider.show();
            } else {
                divider.hide();
            }
        }

        this.$el.find('#status-widget-marquee').trigger('marquee.resize');
    },

    /**
     * Update handler.
     */
    loopUpdateHandler: function() {
        this.updateBoxName();
        this.updateBoxIp();
        this.updateSsid();
        this.updatePin();
        this.updateStatusBarVisibility();

        if (platform.checks.isCbox) {
            this.updateCastId();
            this.updateConnectionId();
        }
    },

    /**
     * Update IP camera health status icon.
     *
     * @param health Health state
     */
    updateIpCamHealthStatus: function(health) {
        var options = {
            icon: '',
            clickable: false,
            state: healthState.none,
            visible: false
        };

        if (app.getService('RecordService').getState() !== 'stop') {
            switch (health.status) {
                case healthState.none:
                    options = {
                        icon: '',
                        clickable: false,
                        state: healthState.none,
                        visible: false
                    };
                    break;
                case healthState.ok:
                    options = {
                        icon: statusIcons['webConferenceRecord'],
                        clickable: false,
                        state: healthState.ok,
                        visible: true
                    };
                    break;
                case healthState.error:
                    options = {
                        icon: statusIcons['webConferenceError'],
                        clickable: false,
                        state: healthState.error,
                        visible: true
                    };
                    break;
            }
        }

        this.emit('status-widget.item.update', {
            id: 'stream-input-health',
            options: options
        });
    },

    /**
     * Update box name.
     */
    updateBoxName: function() {
        this.deviceConnection
            .send('getBoxName')
            .then(this.renderBoxName.bind(this));
    },

    /**
     * Update status bar visibility (show/hide).
     */
    updateStatusBarVisibility: function() {
        this.frontendSettings
            .getSettings([
                'showStatusbar',
                'airplayPinHdmi2', // NOTE: === Mirror PIN (Airplay/Miracast/vCast). No name change for backwards compatibility
                'showPinProjections',
                'userPinHdmi2',
                'mirrorPinSize',
                'mirrorPinMainScreen',
                'showPinControlScreen'
            ])
            .then(function(statusbar, mirrorPin, showPinProjections, user, pinSize, mirrorPinMainScreen, showPinControlScreen) {
                if (platform.checks.isCboxAux) {
                    if (statusbar) {
                        statusbar = false;

                        if ((!this.$pinMirror.hasClass('is-hidden') && !this.isFullscreen)
                        || !this.$pinCollaboration.hasClass('is-hidden')
                        || !this.isFullscreen) {
                            statusbar = true;
                        }
                    }
                } else if (rbac.hasAccess('ControlScreen', 'show', true)) {
                    statusbar = true;
                }

                // RELEASE-3287
                if (statusbar) {
                    this.show();
                    this.showStatusBar = true;
                    this.$el.find('#status-bar-marquee').trigger('marquee.resize');
                } else {
                    this.showStatusBar = false;
                    this.hide();
                }

                this.mirrorPinMainScreen = this.isDualProjection ? showPinControlScreen : mirrorPinMainScreen;
                this.mirrorPinProjection = this.isDualProjection ? showPinProjections : mirrorPin;
                this.userPinHdmi2 = user;

                if (this.mirrorPinSize !== pinSize) {
                    this.mirrorPinSize = pinSize;
                    this.startMirrorPinComponent();
                }
            }.bind(this));
    },

    /**
     * Update Box IP.
     */
    updateBoxIp: function() {
        this.frontendSettings.getSettings([
            'statusBarSettings'
        ])
            .then(function(statusbarSettings) {
                // RELEASE-3429
                if (!statusbarSettings) {
                    statusbarSettings = app.getService('Model-View').statusBarSettingsDefault;

                    this.frontendSettings.updateSetting({
                        tag: 'statusBarSettings',
                        value: statusbarSettings
                    });
                    this.frontendSettings.saveSettings();
                }

                if (null === statusbarSettings.lan2) {
                    statusbarSettings.lan2 = app.getService('Model-View').statusBarSettingsDefault.lan2;
                }

                if (statusbarSettings) {
                    this.deviceConnection
                        .send([
                            { command: 'getEthIpAddress', data: { interfaceIp: true } },
                            { command: 'getLan2IpAddress', data: { interfaceIp: true } },
                            { command: 'getWlanIpAddress', data: { interfaceIp: true } },
                            'getWlanApIpAddress',
                            'getWlanMode',
                            'getLan2Mode'
                        ])
                        .then(this.renderBoxIp.bind(this, false, statusbarSettings), this.hideBoxIp.bind(this));
                } else { // Fallback
                    this.deviceConnection
                        .send([
                            'getStatusBarSettings',
                            { command: 'getEthIpAddress', data: { interfaceIp: true } },
                            { command: 'getLan2IpAddress', data: { interfaceIp: true } },
                            { command: 'getWlanIpAddress', data: { interfaceIp: true } },
                            'getWlanApIpAddress',
                            'getWlanMode',
                            'getLan2Mode'
                        ])
                        .then(this.renderBoxIp.bind(this, true), this.hideBoxIp.bind(this));
                }
            }.bind(this));
    },
    /**
     * Update SSID.
     */
    updateSsid: async function() {
        this.frontendSettings.getSettings('statusBarSettings').then((result) => {
            if (false === result.ssid) {
                this.$ssid.hide();
            } else {
                this.deviceConnection
                    .send([
                        'getInfraSsidName',
                        'getApSsidName',
                        'getWlanMode',
                        'getWlanStatus'
                    ])
                    .then(this.renderSsid.bind(this));
            }
        });
    },

    /**
     * Update PINs: Collaboration PIN, Admin/Moderator PIN, Mirroring PIN (Airplay/Miracast/vCast)
     */
    updatePin: function() {
        if (this.authService.getIsCollab()) {
            this.renderPin(false);
            this.renderPinCollaboration(false);
            this.renderPinMirror(false);
            this.moderatorPinHdmi2 = false;
        } else {
            this.deviceConnection
                .send([
                    'getPinStatusLocal',
                    'getAnnotationPinStatus',
                    'getMirrorPinStatus',
                    'getPinDestination',
                    'getPinStatusHdmi2'
                ])
                .then(function(pinLocal, pinCollaboration, pinMirror, pinDestinations, pinHdmi2) {
                    if (platform.checks.isCboxAux) {
                        this.renderPinHdmi2(pinHdmi2);
                    } else {
                        this.renderPin(pinLocal);
                    }

                    this.renderPinCollaboration(pinCollaboration);
                    this.renderPinMirror(pinMirror);

                    this.pinMirrorPop = pinMirror.popPin;
                    this.moderatorPinHdmi2 = this.$.inArray('hdmi2', pinDestinations.destinations) > -1;
                    this.isFullscreen = (platform.checks.isCboxAux && this.frameboxService.isFullscreenOpen());
                }.bind(this));
        }
    },

    /**
     * Render Admin/Moderator PIN
     *
     * @param pin.showPin: true/false
     *        pin.pin: pin to show
     */
    renderPin: function(pin) {
        if (this.isVMeetingStatusBar || platform.checks.isCboxControlScreen) {
            this.renderPinModerator(pin);
        }

        if (platform.checks.isCbox && !platform.checks.isCboxControlScreen && pin.showPin && pin.pin) {
            this.emit('moderator-pin.update', true, pin.pin);
        } else {
            this.emit('moderator-pin.update', false);
        }
    },

    /**
     * Handle Admin/Moderator PIN on HDMI 2.
     *
     * @param pin.showPin: true/false
     *        pin.pin: pin to show
     */
    renderPinHdmi2: function(pin) {
        if (platform.checks.isCboxAux && !this.isDualProjection) {
            if (this.moderatorPinHdmi2 && pin.showPin && pin.pin) {
                this.emit('moderator-pin.update', true, pin.pin);
            } else {
                this.emit('moderator-pin.update', false);
            }
        }
    },

    /**
     * Handle mirroring pin.
     *
     * @param pin.showPin: true/false
     *        pin.pin: pin to show
     *        pin.popPin: true/false
     */
    renderPinMirror: function(pin) {
        // Check specific device logic.
        if (pin.showPin && pin.pin
            && (
                (platform.checks.isCbox && !platform.checks.isCboxAux && this.mirrorPinMainScreen)
                || (platform.checks.isCboxAux && this.mirrorPinProjection)
            )
        ) {
            if (!this.isVMeetingStatusBar && !platform.checks.isCboxControlScreen && pin.popPin) {
                this.$pinMirror.addClass('is-hidden');
                this.emit('mirror-pin.update', true, pin.pin);
            } else {
                this.$pinMirror.removeClass('is-hidden');
                this.$pinMirror.find('.mirror-pin-view').html(pin.pin);
                this.emit('mirror-pin.update', false);
            }

            return;
        }

        this.$pinMirror.addClass('is-hidden');
        this.emit('mirror-pin.update', false);
    },

    /**
     * Handle moderator pin.
     *
     * @param pin.showPin: true/false
     *        pin.pin: pin to show
     *        pin.popPin: true/false
     */
    renderPinModerator: function(pin) {
        if (pin.showPin && pin.pin
            && (
                (platform.checks.isCbox && !platform.checks.isCboxAux)
                || (platform.checks.isCboxAux && this.mirrorPinProjection)
            )
        ) {
            this.$moderatorPin.removeClass('is-hidden');
            this.$moderatorPin.find('.moderator-pin-view').html(pin.pin);

            return;
        }

        this.$moderatorPin.addClass('is-hidden');
    },

    /**
     * Handle collaboration PIN.
     *
     * @param pin.showPin: true/false
     *        pin.pin: pin to show
     */
    renderPinCollaboration: function(pin) {
        if (pin.showPin && pin.pin
            && (platform.checks.isCbox && !platform.checks.isCboxAux
                || platform.checks.isCboxAux
                && ((!this.isDualProjection && this.userPinHdmi2) || this.isDualProjection))) {
            this.$pinCollaboration.removeClass('is-hidden');
            this.$pinCollaboration.find('.user-pin-view').html(pin.pin);
        } else {
            this.$pinCollaboration.addClass('is-hidden');
        }
    },

    /**
     * Render BOX name.
     */
    renderBoxName: function(data) {
        this.$el.find('#device-name').html(data.boxName);
    },

    /**
     * Render Box IP address.
     *
     * @param {Boolean} fallback if frontend setting isn't defined yet
     * @param {String} networkInterface
     * @param {String} lan
     * @param {String} wlan
     */
    renderBoxIp: function(fallback, networkInterface, lan1, lan2, wlan, wlanApIp, wlanMode, lan2Mode) {
        if (fallback) {
            this.$lan2.hide();

            switch (networkInterface.networkInterface) {
                default:
                case 'nothing':
                    this.$lan1.hide();
                    this.$wlan.hide();
                    break;
                case 'lan':
                    this.$lan1.show();
                    this.$wlan.hide();
                    break;
                case 'wlan':
                    this.$lan1.hide();
                    this.$wlan.show();
                    break;
                case 'both':
                    this.$lan1.show();
                    this.$wlan.show();
                    break;
            }
        } else {
            this.$lan1.hide();
            this.$lan2.hide();
            this.$wlan.hide();

            if (networkInterface.lan1) {
                this.$lan1.show();
            }

            if (!this.deviceService.isCboxPureOrPro() && !this.deviceService.isCboxPureMini()
                && lan2Mode.mode === 'lan' && networkInterface.lan2) {
                this.$lan2.show();
            }

            if (networkInterface.wlan) {
                this.$wlan.show();
            }
        }

        this.$lan1
            .find('.device-ip')
            .html('IP: ' + lan1.ipAddress);

        this.$lan2
            .find('.device-ip')
            .html('IP: ' + lan2.ipAddress);

        if (wlanMode.wlanMode === 'off') {
            this.$wlan.hide();
        } else if (wlanMode.wlanMode === 'infrastructure') {
            this.$wlan.html('IP: ' + wlan.ipAddress);
        } else {
            this.$wlan.html('IP: ' + wlanApIp.ipAddress);
        }
    },

    /**
     * Hide box IPs.
     */
    hideBoxIp: function() {
        this.$lan1.hide();
        this.$lan2.hide();
        this.$wlan.hide();
    },

    /**
     * Render SSID.
     *
     * @param {Object} ssidData,
     * @param {Object} wlanModeData
     */
    renderSsid: function(ssidInfraData, ssidApData, wlanModeData, wlanStatus) {
        var wlanQuality = null;
        if (wlanModeData.wlanMode !== 'off') {
            this.$ssid.removeClass('hidden');

            if (ssidInfraData.ssidName && wlanModeData.wlanMode === 'infrastructure') {
                this.$ssid.find('#device-ssid').html(ssidInfraData.ssidName);
            } else if (ssidApData.ssidName) {
                this.$ssid.find('#device-ssid').html(ssidApData.ssidName);
            }

            if (wlanStatus.wlanStatus === 'connected') {
                if (wlanStatus.qualityValue !== null && wlanModeData.wlanMode === 'infrastructure') {
                    wlanQuality = parseInt(wlanStatus.qualityValue);

                    if (wlanQuality === 0) {
                        wlanQuality = 'disconnected';
                    } else if (wlanQuality > 0 && wlanQuality < 26) {
                        wlanQuality = 'low';
                    } else if (wlanQuality > 25 && wlanQuality < 51) {
                        wlanQuality = 'mid';
                    } else if (wlanQuality > 50 && wlanQuality < 76) {
                        wlanQuality = 'normal';
                    } else if (wlanQuality > 75 || !wlanQuality) {
                        wlanQuality = 'high';
                    }
                } else {
                    wlanQuality = 'high';
                }
            } else {
                wlanQuality = 'disconnected';
            }
        } else {
            wlanQuality = 'disconnected';
            this.$ssid.addClass('hidden');
        }
        this.$ssid.find('.wlan-quality-state').attr('data-wlan-quality-state', wlanQuality);
        this.$ssid.attr('data-status', wlanQuality);
    },

    /**
     * Start widget component.
     */
    startWidgetComponent: function() {
        this.createComponent({
            type: 'StatusWidget',
            container: this.$el.find('#status-widget-container')
        });
    },

    /**
     * Start widget component.
     */
    startActionWidgetComponent: function() {
        this.createComponent({
            type: 'ActionWidget',
            container: this.$el.find('#action-widget-container')
        });
    },

    /**
     * Start mirror component.
     */
    startMirrorPinComponent: function() {
        var top = (this.state.getState() === states.hidden) ? 0 : this.$statusBarContainer.height();
        this.$mirrorPinContainer.css('top', top);

        this.app.removeComponent('pinFlap');
        this.app.createComponent('pinFlap', {
            type: 'PinFlap',
            container: this.$mirrorPinContainer,
            pinSize: this.mirrorPinSize || 'small'
        });
    },

    /**
     * Update Cast ID.
     */
    updateCastId: function() {
        this.frontendSettings.getSettings('networkDeviceDiscovery').then(function(result) {
            if (result && result !== 'supported') {
                this.deviceConnection
                    .send([
                        'getCastId'
                    ])
                    .then(this.onCastIdUpdate.bind(this, result));

                this.$castId.show();
            } else {
                this.$castId.hide();
            }
        }.bind(this));
    },

    /**
     * Update Connect ID.
     */
    updateConnectionId: function() {
        this.deviceConnection
            .send([
                'getConnectionID',
                'getConnectionIdEnable'
            ])
            .then(function(id, enable) {
                let connectionId = this.onConnectionIdUpdate(id);
                let showOnScreen = (platform.checks.isCbox && !platform.checks.isCboxAux && this.mirrorPinMainScreen)
                    || (platform.checks.isCboxAux && this.mirrorPinProjection);
                let showInStatusBar = this.frameboxService.getNumberOpenFrameboxes() > 0
                    || this.stationService.getPushStatus()
                    || this.annotationService.isActive()
                    || this.annotationService.isMagicPenActive()
                    || this.isVMeetingStatusBar
                    || platform.checks.isCboxControlScreen;

                if (showOnScreen && enable.enable && showInStatusBar) {
                    this.$connectionId.show();
                } else {
                    this.$connectionId.hide();
                }

                this.emit('connection-id.update', (showOnScreen && enable.enable && !showInStatusBar), connectionId);
            }.bind(this));
    },

    /**
     * Update Connect ID.
     *
     * @param {string} result
     * @param {string} data lan/wlanId
     */
    onConnectionIdUpdate: function(data) {
        let id = '';

        if (data) {
            if (data.connectionId) {
                id = formatConnectionId(data.connectionId);
                this.$el.find('.connection-id').html(id);

                return id;
            }

            id = 'Not Connected';
            this.$el.find('.connection-id').html(id);
        }

        return id;
    },

    /**
     * Update Cast ID.
     *
     * @param {string} result
     * @param {string} data lan/wlanId
     */
    onCastIdUpdate: function(result, data) {
        if (result === networkDeviceDiscovery.lan1) {
            this.$el.find('.cast-id').html(data.lan1Id ? data.lan1Id : 'Not Connected');
        } else if (result === networkDeviceDiscovery.lan2) {
            this.$el.find('.cast-id').html(data.lan2Id ? data.lan2Id : 'Not Connected');
        } else {
            this.$el.find('.cast-id').html(data.wlanId ? data.wlanId : 'Not Connected');
        }
    },

    /**
     * Show status bar.
     */
    show: function() {
        if (this.screensaverService.isScreensaverActive() || this.screensaverService.isScreenOffActive()
            || (this.isVMeetingStatusBar && !this.sessionMgmtService.showStatusBar)) {
            return;
        }

        if (platform.checks.isCboxAux) {
            this.$el.show();
            this.state.changeState(states.visible);

            return;
        }

        if (this.showStatusBar && !this.stationService.getPushStatus()) {
            this.frameboxService.isFullscreen()
                .then(function(data) {
                    if (data.isFullscreen) {
                        this.hide();
                    } else if (this.state.getState() === states.hidden) {
                        this.$el.show();
                        this.state.changeState(states.visible);
                        this.$el.find('.col-xs-5').hide();

                        /* RELEASE-2063 - MS Edge: Icons from status bar appear in wrong position
                           after minimizing a window. */
                        setTimeout(function() {
                            this.$el.find('.col-xs-5').show();
                        }.bind(this), 300);
                    }
                }.bind(this));
        }
    },

    /**
     * Hide status bar.
     */
    hide: function() {
        this.$el.hide();
        this.state.changeState(states.hidden);

        if (platform.checks.isCbox && this.liveStream.getOffset().top > 0) {
            this.liveStream.setOffset({ top: 0 });
        }
    },

    addStateTransitions: function() {
        this.state.addTransitions({
            '> hidden': function() {
                this.$mirrorPinContainer.css('top', 0);
            },
            '> visible': function() {
                this.$mirrorPinContainer.css('top', this.$statusBarContainer.height());
            }
        });
    },

    /**
     * Pad number with 0.
     *
     * @param {number} number
     * @returns {string}
     */
    padZero: function(number) {
        if (number < 10) {
            number = '0' + number;
        }

        return number;
    },

    destroy: function() {
        this.emit('status-bar.destroy');
    }
});
