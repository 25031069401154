const template = require('./sources-sub-menu.html');

const vueUtils = require('../../util');
const i18n = require('i18next');

module.exports = {
    template,
    props: {
        title: {
            type: String,
            required: true
        },
        uiScalingSize: {
            type: String,
            required: false,
            default: 'medium'
        },
        actionSwitch: {
            type: Object,
            required: false,
            default: function() {
                return {
                    show: false,
                    title: 'Toggle',
                    checked: false,
                    onToggle: function() {
                    }
                };
            }
        },
        items: {
            type: Array,
            required: true,
            default: function() {
                return [
                    {
                        id: 'msoutlook',
                        icon: 'icon-v3-office-outlook',
                        name: i18n.t('office365.outlook')
                    }
                ];
            }
        },
        scalingFactor: {
            type: Number,
            required: true
        }
    },

    data: function() {
        return {
            focusable: true,
            isDraggable: true
        };
    },

    methods: {
        menuItemClick: function(item) {
            this.$emit('click', item);
        },

        navigateBack: function() {
            this.$emit('close');
        },

        dragStart: function() {
            this.$store.dispatch('controlScreen/setDragging', true);
            this.$emit('dragging-change', true);

            const draggedElement = document.getElementsByClassName('sortable-drag')[0];
            if (draggedElement) {
                const draggedIcon = draggedElement.getElementsByClassName('icon')[0];
                draggedIcon.style.transform = `scale(${this.scalingFactor})`;
            }
        },

        dragEnd: function() {
            this.$store.dispatch('controlScreen/setDragging', false);
            this.$emit('dragging-change', false);
        },

        actionSwitchToggled: function() {
            this.actionSwitch.onToggle(this.actionSwitch.checked);
        },

        i18n: vueUtils.i18n
    },

    components: {
        'sources-menu-item': require('../sources-menu-item/sources-menu-item'),
        'draggable': require('vuedraggable')
    }
};
