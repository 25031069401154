// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"splash-screen-settings\" name=\"splash-screen-settings\">\n        <div class=\"row settings-list-row\"\n             data-nav-area=\".btn-switch\"\n             data-nav-trapped\n             data-nav-area-default>\n            <div class=\"col-xs-8\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.splash_screen",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":50}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input id=\"enabled\" name=\"enabled\" type=\"checkbox\" class=\"btn-switch\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"splash-screen-all-settings\">\n            <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable:not(:disabled)\" data-nav-trapped>\n                <div class=\"col-xs-3\">\n                    <div class=\"input-group\">\n                        <label class=\"input-label\" for=\"url\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.splash_screen_timeout",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":61},"end":{"line":24,"column":99}}}))
    + "</label>\n                        <input type=\"text\"\n                               class=\"input is-focusable\"\n                               name=\"splashScreenManuallyTimeout\"\n                               id=\"splashScreenManuallyTimeout\"\n                               placeholder=\"\"\n                               tabIndex=\"-1\"\n                               data-from=\"5\"\n                               data-to=\"30\"\n                               data-validation=\"checkNumberFromTo\"/>\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\">\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.wrong_value_between_5_30_seconds",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":32},"end":{"line":36,"column":96}}}))
    + "\n                            </span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.show_on_remote",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":44},"end":{"line":45,"column":75}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-2 pull-right\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"showOnRemote\" id=\"showOnRemote\" type=\"checkbox\" class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-row\">\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wlan_connection_details",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":24},"end":{"line":59,"column":64}}}))
    + "</h2>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.display_wlan",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":44},"end":{"line":65,"column":73}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-2 pull-right\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"displayWlan\" id=\"displayWlan\" type=\"checkbox\" class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.show_qr_code",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":44},"end":{"line":79,"column":73}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-2 pull-right\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"showQRCode\" id=\"showQRCode\" type=\"checkbox\" class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div id=\"splash-screen-wlan-settings\">\n                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\".is-focusable:not(:disabled)\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div id=\"display-wlan-type-select\"></div>\n                    </div>\n                    <div class=\"col-xs-6\"/>\n                </div>\n\n                <div id=\"splash-screen-wlan-type-settings\">\n                    <div class=\"row settings-list-secundary\"\n                         data-nav-area=\".is-focusable:not(:disabled)\"\n                         data-nav-trapped>\n                        <div class=\"col-xs-6\">\n                            <div class=\"input-group\">\n                                <label class=\"input-label\" for=\"url\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wlan_ssid",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":107,"column":69},"end":{"line":107,"column":95}}}))
    + "</label>\n                                <input type=\"text\"\n                                       class=\"input is-focusable\"\n                                       name=\"splashScreenDisplayWlanSSID\"\n                                       id=\"splashScreenDisplayWlanSSID\"\n                                       placeholder=\"\"\n                                       tabIndex=\"-1\"/>\n                                <div class=\"input-info\">\n                                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.enter_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":115,"column":83},"end":{"line":115,"column":109}}}))
    + "</span>\n                                </div>\n                            </div>\n                        </div>\n\n                        <div class=\"col-xs-6\">\n                            <div class=\"input-group\">\n                                <label class=\"input-label\" for=\"user\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wlan_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":122,"column":70},"end":{"line":122,"column":100}}}))
    + "</label>\n                                <input type=\"password\"\n                                       class=\"input is-focusable\"\n                                       name=\"splashScreenDisplayWlanPassword\"\n                                       id=\"splashScreenDisplayWlanPassword\"\n                                       placeholder=\"\"\n                                       tabIndex=\"-1\"/>\n                            </div>\n                        </div>\n                    </div>\n\n                    <div class=\"row settings-list-secundary\"\n                         data-nav-area=\".is-focusable:not(:disabled)\"\n                         data-nav-trapped>\n                        <div class=\"col-xs-6\">\n                            <div class=\"input-group\">\n                                <div id=\"display-wlan-encryption-select\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"splash-screen-form-action-container\" class=\"form-primary-background\"></div>\n    </form>\n</div>\n";
},"useData":true});
