'use strict';

var app = require('../../../../../app');
var platform = require('../../../../../../platform/platform');
var customStreamSettingsTpl = require('./custom-stream-settings.hbs');
var $ = require('jquery');

app.component('CustomStreamSettings', {
    template: customStreamSettingsTpl,

    /**
     * @method getAccessKey
     * @returns {{roleName: string, roleKey: string}}
     */
    getAccessKey: function() {
        return {
            'roleName': 'StreamingSettings',
            'roleKey': 'show'
        };
    },

    /**
     * @method initialize
     * @returns {Object}
     */
    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.deviceService = this.getService('DeviceService');
    },

    /**
     * @returns {Object}
     */
    serialize: function() {
        return {
            showFullsize: platform.checks.isFullsize(),
            isCorePro: this.deviceService.isCboxCorePro(),
            isDualProjection: this.deviceService.isCboxProDualProjection()
        };
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.initForm();
        this.bindEvents();

        $.when(this.loadCustomStreamSettings()).done(function() {
            this.bindDOMEvents();
        }.bind(this));
    },

    /**
     * @method initForm
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#custom-stream-settings'),
            validationContainer: '.input-group'
        });
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        this.on('custom-stream.save', this.saveHandler.bind(this));
        this.on('custom-stream.cancel', this.cancelHandler.bind(this));
        this.on('custom-stream.checkActionForm', this.checkActionForm.bind(this));
    },

    /**
     * Method handleFormChange
     */
    handleFormChange: function() {
        this.checkActionForm();
        this.emit('streaming-settings.openActionView', true);
    },

    /**
     * @method checkActionForm
     */
    checkActionForm: function() {
        this.emit('streaming-settings.checkActionFormComponent', (!!this.form.validate()));
    },

    /**
     * Calls on clicked the save button from streaming-settings
     * @method saveHandler
     */
    saveHandler: function() {
        if (this.form.validate()) {
            var data = this.form.serialize();

            this.emit('overlay.remote.focus', true);

            this.deviceConnection
                .send([
                    {
                        command: 'setWebcastCustomUrls',
                        data: {
                            urlHdmi1: data.urlHdmi1,
                            urlHdmi2: data.urlHdmi2,
                            urlExt: data.urlExt
                        }
                    }
                ])
                .done(function() {
                    this.form.setDefaultValues();
                }.bind(this));
        }
    },

    /**
     * Calls on clicked the cancel button from streaming-settings
     * @method cancelHandler
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
    },

    /**
     * @method updateForm
     */
    updateForm: function() {
        this.form.setValues(this.formData);
    },

    /**
     * @method loadRemoteStreamingSettings
     * @returns {*}
     */
    loadCustomStreamSettings: function() {
        return $.when(this.loadBackendSettings(this))
            .done(function() {
                this.updateForm();
                this.form.setDefaultValues();
            }.bind(this));
    },

    /**
     * @method loadBackendSettings
     */
    loadBackendSettings: function() {
        var dfd = $.Deferred();

        this.deviceConnection
            .send([
                'getWebcastCustomUrls'
            ])
            .then(function(customUrls) {
                this.formData = {
                    urlHdmi1: customUrls.urlHdmi1,
                    urlHdmi2: customUrls.urlHdmi2,
                    urlExt: customUrls.urlExt
                };
                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    }
});
