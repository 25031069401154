'use strict';

var app = require('../../../app');
var fileBoxTpl = require('./file-box.hbs');
var i18n = require('i18next');

app.component('FileBox', {
    template: fileBoxTpl,
    titles: {
        'webdav': 'WebDav',
        'netdrive0': 'Network Drive 0',
        'netdrive1': 'Network Drive 1',
        'netdrive2': 'Network Drive 2',
        'netdrive3': 'Network Drive 3',
        'netdrive4': 'Network Drive 4',
        'netdrive5': 'Network Drive 5',
        'netdrive6': 'Network Drive 6',
        'netdrive7': 'Network Drive 7',
        'netdrive8': 'Network Drive 8',
        'netdrive9': 'Network Drive 9',
        'netdrive10': 'Network Drive 10'
    },

    initialize: function() {
        this.URIService = this.getService('URIService');
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();
        this.bindDOMEvents();
        this.createFileList();
    },

    /**
     * Create file list component.
     */
    createFileList: function(path, mountType) {
        this.app.createComponent('MatrixFileList', {
            type: 'MatrixFileList',
            container: this.$fileList,
            path: path,
            mountType: mountType
        });
    },

    storeSelectors: function() {
        this.$fileList = this.$el.find('#file-list');
        this.$btnBack = this.$el.find('.btn-back');
        this.$title = this.$el.find('.file-box-header-text');

        this.current = {
            path: null,
            mountType: null
        };
    },

    bindEvents: function() {
        this.on('matrix-box.update', this.refreshFileList.bind(this));
        this.on('matrix-box.add.title', this.addTitle.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.file-box-header', this.historyBack.bind(this));
    },

    /**
     * Add special title into array when mounts are parsed.
     *
     * @param id Mount id
     * @param name WebDav or network drive name set by the user
     */
    addTitle: function(id, name) {
        if (this.titles[id]) {
            this.titles[id] = name;
        }
    },

    /**
     * Handle file box header click to go back (go to parent folder) and refresh list.
     */
    historyBack: function() {
        this.refreshFileList(false, this.backPath, this.backMountType);
    },

    /**
     * Refresh file list.
     *
     * @param onOpen true: Matrix box opened, false: file list update
     * @param path Directory
     * @param mountType Mount type
     */
    refreshFileList: function(onOpen, path, mountType) {
        // Remember last directory
        if (onOpen) {
            path = this.current.path;
            mountType = this.current.mountType;
        }

        this.app.removeComponent('MatrixFileList');

        this.backPath = this.getBackPath(path);
        this.backMountType = this.backPath ? mountType : '';
        this.title = this.getTitle(path);

        this.$title.find('.text').text(this.title);
        this.$btnBack.css('visibility', path ? 'visible' : 'hidden');

        this.createFileList(path, mountType);

        this.current.path = path;
        this.current.mountType = mountType;
    },

    /**
     * Get parent folder path.
     *
     * @param path {string} Path of current location
     * @returns {string} Parent folder path
     */
    getBackPath: function(path) {
        if (!path || path.lastIndexOf('///') === path.length - 3) {
            return '';
        }

        path = path.substring(0, path.length - 1);

        return path.substring(0, path.lastIndexOf('/') + 1);
    },

    /**
     * Get title for current location.
     *
     * @param path {string} Path of current location
     * @returns {string} Current directory title
     */
    getTitle: function(path) {
        if (!path) {
            return i18n.t('sources.filebrowser');
        }

        path = this.URIService.decode(path);

        if (path.lastIndexOf('///') === path.length - 3) {
            var title = path.substring(0, path.lastIndexOf(':'));

            return this.titles[title] || title;
        }

        path = path.substring(0, path.length - 1);

        return path.substring(path.lastIndexOf('/') + 1, path.length);
    },

    /**
     * Destroy and remove file list component.
     */
    destroy: function() {
        this.app.removeComponent('MatrixFileList');
    }

});
