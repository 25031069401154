'use strict';

var app = require('../app');
var $ = require('jquery');
var i18n = require('i18next');
var i18nXHRBackend = require('i18next-xhr-backend');
var platform = require('../../platform/platform');

app.service('LanguageService', function() {
    return {

        /**
         * @method initialize
         */
        initialize: function() {
            this.keyboardLayout = 'en';
            this.storage = app.getService('DataStorage');

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;

                    this.bindEvents();
                }.bind(this));
        },

        /**
         * @method bindEvents
         */
        bindEvents: function() {
            app.on('main-loop.update', this.onUpdateLanguage.bind(this));
            app.on('status-widget.language.action', this.showLanguageSelectionModal.bind(this));
        },

        showLanguageSelectionModal: function() {
            app.emit('modal.open', {
                id: 'languageSelect',
                role: {
                    name: 'LanguageSelect',
                    key: 'show'
                }
            });
        },

        /**
         * Initialize i18n language.
         *
         * @return {jQuery.Promise}
         */
        initLanguage: function() {
            var dfd = $.Deferred();

            i18n
                .use(i18nXHRBackend)
                .init({
                    lng: this.getDeviceLanguage(),
                    fallbackLng: 'en',
                    debug: false,
                    backend: {
                        loadPath: '/locales/{{lng}}/{{ns}}.json',
                        crossDomain: true
                    }
                }, function(error) {
                    if (error) {
                        window.app
                            .getService('ExceptionsManager')
                            .throw('i18next error: ' + error);
                    }

                    app.emit('language:changed', this.getDeviceLanguage());
                    dfd.resolve();
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Fetch the new default language. If the default language was changed the app will be reloaded.
         * - Be secure only call this method on local browser, otherwise this will break all tests sometimes.
         */
        onUpdateLanguage: function() {
            this.getKeyboardLayout();

            if (!platform.checks.isCbox && !platform.checks.isCboxAux) {
                return;
            }

            var lng = this.getDefaultLanguage();

            this
                .loadDefaultLanguage()
                .then(function(defaultlng) {
                    // Reload after default language was changed.
                    if (defaultlng !== lng) {
                        window.location.reload();
                    }
                });
        },

        /**
         * Load Default-Language from settings.
         */
        loadDefaultLanguage: function() {
            return this.deviceConnection
                .send('getSystemLanguage')
                .then(function(data) {
                    this.setDefaultLanguage(data.language);

                    return data.language;
                }.bind(this));
        },

        /**
         * Returns device specific language.
         * - CYNAP will always get the default language from box-backend.
         * - User can choose his own language.
         *
         * @return {string}
         */
        getDeviceLanguage: function() {
            var lng = this.getDefaultLanguage();

            if (!platform.checks.isCbox && !platform.checks.isCboxAux) {
                lng = this.getLanguage();
            }

            // For testing: add an indicator to html main component
            app.$el.attr('data-language', lng);

            return lng;
        },

        /**
         * Returns the system language.
         *
         * @returns {*}
         */
        getLanguage: function() {
            var lng = this.storage.get('language');

            if (!lng) {
                lng = this.getDefaultLanguage();
            }

            return lng;
        },

        /**
         * Returns the current keyboard layout.
         *
         * @returns {*}
         */
        getKeyboardLayout: function() {
            return this.deviceConnection
                .send('getKeyboardLayout')
                .then(function(keyboardLayout) {
                    if (this.keyboardLayout !== keyboardLayout.layout) {
                        app.emit('language:changed', this.keyboardLayout);
                    }

                    this.keyboardLayout = keyboardLayout.layout;

                    return keyboardLayout.layout;
                }.bind(this));
        },

        /**
         * Set language on i18n.
         *
         * @method setLanguage
         * @param {string} lng
         */
        setLanguage: function(lng) {
            this.storage.set('language', lng);
        },

        /**
         * @method getLanguage
         */
        getDefaultLanguage: function() {
            return this.storage.get('default-language');
        },

        /**
         * Set default language.
         *
         * @method setLanguage
         * @param {string} lng
         */
        setDefaultLanguage: function(lng) {
            this.storage.set('default-language', lng);
        }
    };
});
