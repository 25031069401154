'use strict';

const $ = require('jquery');
const _ = require('lodash');
const app = require('../../../app');
const webconferenceTpl = require('./webconference-settings.hbs');
const serviceItemTpl = require('./service-item.hbs');
const FormActionView = require('../form-action-view');
const i18n = require('i18next');

const controlBarPositions = [
    {
        text: 'settings.upper_bottom',
        value: 'upperBottom'
    },
    {
        text: 'settings.bottom',
        value: 'bottom'
    },
    {
        text: 'settings.lower_bottom',
        value: 'lowerBottom'
    }
];

app.component('WebconferenceSettings', {
    template: webconferenceTpl,
    actionView: null,

    initialize: function() {
        this.frontendSettings = this.getService('FrontendSettings');
        this.formManager = this.getService('FormManager');
        this.deviceService = this.getService('DeviceService');
        this.isDualProjection = this.deviceService.isCboxProDualProjection();
        this.formData = {};
        this.formEntries = [];
        this.actionViewEntries = [];
        this.entries = [];
        this.changesEntries = [];
        this.entriesCount = 0;
    },

    serialize: function() {
        return {
            supportsRecording: this.deviceService.isCbox()
                || (this.deviceService.isCboxCorePro && this.deviceService.getFeatures().capture),
            isCbox: this.deviceService.isCbox(),
            isDualProjection: this.isDualProjection
        };
    },

    postPlaceAt: function() {
        this.$entriesList = this.$el.find('#webconference-items');
        this.storeSelectors();
        this.createSelects();
        this.initForm();
        this.initFormAction();

        return $.when(
            this.loadBackendSettings(),
            this.loadFrontendSettings()
        )
            .done(function() {
                this.form.setValues(this.formData);
                this.bindDOMEvents();
                this.bindEvents();
            }.bind(this));
    },

    bindEvents: function() {
        this.on('settings.save-changes', this.saveChangesHandler.bind(this));
    },

    bindDOMEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.$el.find('.webconference-settings-add-new-service').on('click', this.onAddItemClicked.bind(this));
    },

    storeSelectors: function()  {
        this.$webrtcEntryList = this.$el.find('#webconference-items');
        this.$addNewEntry = this.$el.find('.webconference-settings-add-new-service');
    },

    /**
     * Will be called after the plus-button has been clicked.
     */
    onAddItemClicked: function() {
        var maxReached = true;

        _.each(this.$entriesList.children().get().reverse(), function(entry) {
            if ($(entry).is(':hidden')) {
                $(entry).show();
                maxReached = false;

                return false;
            }
        }.bind(this));

        if (maxReached) {
            this.$addNewEntry.addClass('is-disabled');
        }
    },

    initFormAction: function($item, index) {
        if (index !== undefined) {
            if (!this.actionViewEntries[index]) {
                this.actionViewEntries[index] = new FormActionView(app, {
                    id: index,
                    selector: $item.find('#form-action-container'),
                    onSubmit: this.saveHandler.bind(this, index),
                    onCancel: this.cancelHandler.bind(this, index)
                });
            }

            this.actionViewEntries[index].render();
        } else {
            if (!this.actionView) {
                this.actionView = new FormActionView(app, {
                    selector: this.$el.find('#webconference-form-action-container'),
                    onSubmit: this.saveHandler.bind(this),
                    onCancel: this.cancelHandler.bind(this)
                });
            }

            this.actionView.render();
        }
    },

    initForm: function(child) {
        if (child && child.Index !== undefined) {
            var $item = this.$el.find('.webconference-settings-item[data-index=' + child.Index + ']');

            this.formEntries[child.Index] = this.formManager.create({
                el: $item,
                validationContainer: '.input-group'
            });

            this.formEntries[child.Index].on('change.input', this.handleFormChange.bind(this));
            $item.find('.delete-service').on('click', this.removeItem.bind(this));

            this.initFormAction($item, child.Index);

            this.formEntries[child.Index].setValues({
                name: child.Name,
                id: child.Index,
                url: child.URL,
                nativeScreenshare: child.Native
            });

            this.formEntries[child.Index].setDefaultValues();

            if (child.Name === '') {
                $item.hide();
            }
        } else {
            this.form = this.formManager.create({
                el: this.$el.find('#webconference-settings'),
                validationContainer: '.input-group'
            });
        }
    },

    saveChangesHandler: function() {
        if (this.changes) {
            _.each(this.changesEntries, function(value, index) {
                if (value) {
                    this.updateEntries(index);
                }
            }.bind(this));

            this.saveHandler();

            if (this.changesEntries.length > 0) {
                this.saveHandler(parseInt(Object.keys(this.changesEntries)[0]));
            }
        }
    },

    saveHandler: function(index) {
        this.updateEntries(index);

        this.frontendSettings.updateSetting({
            tag: 'webrtcControlBarPosition',
            value: this.form.get('controlBarPosition').getValue()
        });

        this.frontendSettings.saveSettings();

        if (isNaN(index) || index.hasOwnProperty('originalEvent')) {
            if (this.form.validate()) {
                const cmds = [
                    {
                        command: 'setWebconferenceFunction',
                        data: {
                            function: this.form.get('enable').getValue()
                        }
                    }];

                if (!this.isDualProjection) {
                    cmds.push({
                        command: 'setWebconferenceRecording',
                        data: {
                            enabled: this.form.get('webconferenceRecording').getValue()
                        }
                    });
                }

                this.deviceConnection
                    .send(cmds).done(function() {
                        this.handleSettingsSave();
                        this.form.setDefaultValues();
                        this.emit('overlay.remote.focus', true);
                    }.bind(this));
            }
        } else {
            this.deviceConnection
                .send([
                    {
                        command: 'setWebconferenceEntries',
                        data: {
                            entries: JSON.stringify(this.entries)
                        }
                    }
                ]).done(function() {
                    this.handleSettingsSave();
                    this.changesEntries[index] = false;
                    this.formEntries[index].setDefaultValues();
                    this.emit('overlay.remote.focus', true);
                }.bind(this));
        }
    },

    /**
     * Update entry with DOM values.
     *
     * @param index
     */
    updateEntries: function(index) {
        _.each(this.entries, function(entry) {
            if (entry.Index === parseInt(index)) {
                var form = this.formEntries[index];

                entry.URL = form.get('url').getValue();
                entry.Name = form.get('name').getValue();
                entry.Native = (form.get('nativeScreenshare').getValue() ? 1 : 0);
            }
        }.bind(this));
    },

    /**
     * Calls on clicked the cancel button
     */
    cancelHandler: function(index) {
        this.emit('overlay.remote.focus', true);

        if (this.formEntries[index]) {
            this.formEntries[index].resetValues();
            this.formEntries[index].$el.find('.delete-service').show();
            this.changesEntries[index] = false;
        } else {
            this.form.resetValues();
            this.updateForm();
        }

        this.changes = false;
    },

    /**
     * Update overlay header and set changes to false.
     */
    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.changes = false;
    },

    handleFormChange: function(data) {
        var index = data.input.form.$el.data('index');

        if (this.actionViewEntries[index]) {
            this.actionViewEntries[index].open();
            this.changesEntries[index] = true;
            this.checkActionForm(index);

            this.changesEntries[index] = true;
        } else {
            this.actionView.open();
            this.checkActionForm();
        }

        this.changes = true;
    },

    /**
     * Check entry form validation.
     *
     * @returns {boolean}
     */
    checkEntryValidation: function() {
        var isValid = true;

        _.each(this.entries, function(entry) {
            var valid = this.formEntries[entry.Index].validate();

            if (!valid) {
                isValid = false;
            }
        }.bind(this));

        return isValid;
    },

    checkActionForm: function(index) {
        var isValid = true;

        if (index === undefined) {
            if (this.form.validate()) {
                this.actionView.enableSubmitButton();
            } else {
                this.actionView.disableSubmitButton();

                isValid = false;
            }
        } else if (this.formEntries[index].validate()) {
            this.actionViewEntries[index].enableSubmitButton();
        } else {
            this.actionViewEntries[index].disableSubmitButton();

            isValid = false;
        }

        return isValid;
    },

    loadBackendSettings: function() {
        var dfd = $.Deferred();

        this.deviceConnection
            .send([
                'getWebconferenceFunction',
                'getWebconferenceEntries',
                'getWebconferenceRecording'
            ])
            .then(function(webconferenceFunction, entries, webconferenceRecording) {
                this.entries = entries.entries;
                this.formData.enable = webconferenceFunction.function;
                this.formData.webconferenceRecording = webconferenceRecording.enabled;

                _.each(this.entries, function(entry) {
                    this.appendServices(entry);
                }.bind(this));

                if (!this.$webrtcEntryList.children().is(':hidden')) {
                    this.$addNewEntry.addClass('is-disabled');
                }

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    loadFrontendSettings: function() {
        var dfd = $.Deferred();

        this.frontendSettings
            .getSettings([
                'webrtcControlBarPosition'
            ])
            .then(function(webrtcControlBarPosition) {
                this.formData.controlBarPosition = webrtcControlBarPosition;
                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Set form data and append services.
     */
    updateForm: function() {
        var child;
        var i;

        this.$entriesList.html('');
        for (i = 0; i < this.entries.length; i++) {
            child = this.entries[i];

            var formData = {
                url: child.URL,
                name: child.Name,
                nativeScreenshare: child.Native
            };

            this.formEntries[child.Index].setValues(formData);

            if ('' === child.URL) {
                return;
            }

            this.appendServices(child);

            this.formEntries[i].setValues(formData);
        }
    },

    /**
     * Add item to the entries list.
     */
    appendServices: function(child) {
        this.entriesCount++;

        const minUrlLength = 1;
        const maxUrlLength = 2047;

        // Generate child-element.
        this.$webrtcEntryList.prepend(serviceItemTpl({
            id: child.Index,
            minUrlLength: minUrlLength,
            maxUrlLength: maxUrlLength,
            urlValidationError: i18n.t('settings.error_messages.invalid_length', {
                minLength: minUrlLength,
                maxLength: maxUrlLength
            })
        }));

        this.initForm(child);
    },

    /**
     * Remove item from list.
     *
     * @param event
     */
    removeItem: function(event) {
        var index = $(event.currentTarget).attr('data-index');
        var $item = this.$el.find('.webconference-settings-item[data-index="' + index + '"]');
        var form = this.formEntries[index];

        $item.hide();
        form.get('url').setValue('');
        form.get('name').setValue('');
        form.get('nativeScreenshare').setValue(true);

        this.saveHandler(index);

        this.$addNewEntry.removeClass('is-disabled');
    },

    destroy: function() {
        var count = 0;

        _.each(this.formEntries, function(item) {
            this.formManager.destroy(item);

            if (this.actionViewEntries[count]) {
                this.actionViewEntries[count].destroy();
            }
            count++;
        }.bind(this));

        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    createSelects: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#control-bar-position-select'),
            label: 'settings.control_bar_position',
            native: true,
            name: 'controlBarPosition',
            items: controlBarPositions
        });
    },

    /**
     * Check if there are some changes and update action view (Cancel/Save)
     * @returns {*}
     */
    hasChanges: function() {
        if (!this.actionView && this.actionViewEntries.length <= 0) {
            return {
                hasChanges: false,
                invalid: false
            };
        }

        var configChanged = $.grep(this.changesEntries, function(change) {
            return change;
        });

        return {
            hasChanges: this.changes || configChanged.length > 0,
            invalid: !this.checkActionForm()
        };
    }
});
