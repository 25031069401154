// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"media-casting-overlay\">\n    <div class=\"row title-container\">\n        <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.media_cast",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":36}}}))
    + "</h2>\n    </div>\n    <div class=\"row media-cast-item-container clickable focusable streaming-widget-states is-stopped\"\n         id=\"streaming-item\" data-action=\"streaming-settings\" tabIndex=\"-1\">\n        <div class=\"col-xs-12 media-casting-item\">\n            <span class=\"icon-stream_l\"></span>\n            <div class=\"streaming-state-stopped\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"control_center.streaming_start",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":49},"end":{"line":9,"column":87}}}))
    + "</div>\n            <div class=\"streaming-state-streaming\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"control_center.streaming_stop",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":51},"end":{"line":10,"column":88}}}))
    + "</div>\n        </div>\n    </div>\n    <div class=\"row media-cast-item-container clickable focusable streaming-widget-states is-stopped\"\n         id=\"webcasting-item\" data-action=\"webcasting-settings\" tabIndex=\"-1\">\n        <div class=\"col-xs-12 media-casting-item\">\n            <span class=\"icon-webcasting\"></span>\n            <div class=\"webcasting-state-stopped\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"control_center.webcast_streaming_start",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":50},"end":{"line":17,"column":96}}}))
    + "</div>\n            <div class=\"webcasting-state-streaming\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"control_center.webcast_streaming_stop",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":52},"end":{"line":18,"column":97}}}))
    + "</div>\n        </div>\n    </div>\n    <div class=\"row media-cast-item-container clickable focusable recording-widget-states is-stopped\"\n         id=\"record-item\" data-action=\"recording\" tabIndex=\"-1\">\n        <div class=\"col-xs-12 media-casting-item\">\n            <span class=\"icon-recording\"></span>\n            <div class=\"record-state-recording record-state-paused\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"control_center.stop_recording",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":68},"end":{"line":25,"column":105}}}))
    + "</div>\n            <div class=\"record-state-stopped\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"control_center.start_recording",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":46},"end":{"line":26,"column":84}}}))
    + "</div>\n        </div>\n    </div>\n    <div class=\"row\" id=\"services-disabled\">\n        <div class=\"col-xs-12 media-casting-item\">\n            <div class=\"services-disabled info-text\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.services_disabled",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":53},"end":{"line":31,"column":84}}}))
    + "</div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});
