'use strict';

var $ = require('jquery');
var _ = require('lodash');
var app = require('../../app');
var i18n = require('i18next');
var downloadAppsTpl = require('./download-apps.hbs');
var vcastTpl = require('./vcast.hbs');
var platform = require('./../../../platform/platform');

app.component('DownloadApps', {
    template: downloadAppsTpl,
    className: 'download-apps-container',

    initialize: function() {
        this.deviceService = this.getService('DeviceService');
        this.linkList = {};
        this.vCastUrl = '';
    },

    postPlaceAt: function() {
        $.when(this.loadData()).done(function() {
            this.storeSelectors();

            this.changeViewTemplate('vCast');
            this.bindEvents();
        }.bind(this));
    },

    storeSelectors: function() {
        this.$container = this.$el.find('.download-apps-overlay');
        this.$instructionContainer = this.$el.find('#instruction-page');
    },

    bindEvents: function() {
        this.on('overlay.opened-end', function() {
            // We wait until the overlay open is finished and the focus got set, otherwise the
            // VCast view is rendered twice.
            this.bindDOMEvents();
        }.bind(this));
    },

    bindDOMEvents: function() {
        this.$container.on('click', '.item-nav-inner', this.appendView.bind(this));
        this.$container.on('focus', '.item-nav-inner', this.appendView.bind(this));
    },

    serialize: function() {
        return {
            isCbox: platform.checks.isCbox
        };
    },

    loadData: function() {
        var dfd = this.$.Deferred();

        this.deviceConnection
            .send([
                'getDownloadLinkList'
            ])
            .then(function(links) {
                this.linkList = links.list;

                this.parseList();

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Parse link list json.
     */
    parseList: function() {
        _.each(this.linkList, function(link) {
            switch (link.source) {
                case 0:
                    this.vCastUrl = link.url;
                    break;
            }
        }.bind(this));
    },

    /**
     * Append a new view to the container.
     *
     * @param event
     */
    appendView: function(event) {
        var $item = this.$(event.currentTarget);
        var itemName = $item.data('name');

        this.$instructionContainer.find('.instruction-container').remove();
        this.changeViewTemplate(itemName);

        this.$el.find('.item-nav-inner').removeClass('focused');
        $item.addClass('focused');
    },

    /**
     * Switching app view template.
     *
     * @param itemName
     */
    changeViewTemplate: function(itemName) {
        var isCbox = platform.checks.isCbox;

        switch (itemName) {
            case 'vCast':
                this.$instructionContainer.append(vcastTpl);

                if (isCbox) {
                    this.$instructionContainer.find('.instruction-url').text(this.vCastUrl);
                } else {
                    this.$el.find('.download-link').attr('href', this.vCastUrl);

                    this.$instructionContainer.find('.instruction-url').text(i18n.t('download_apps.download_vcast'));
                    this.$instructionContainer.find('.instruction-url').attr('href', this.vCastUrl);
                    this.$instructionContainer.find('.instruction-url').attr('target', '_blank');
                }
                break;
        }

        if (platform.checks.isIe) { // RELEASE-1587: workaround for internet explorer because position:absolute is not working as expected
            this.$el.find('.next-instruction').addClass('is-ie');
        }
    }
});
