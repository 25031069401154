'use strict';

var _ = require('lodash');
var app = require('../app');
var StateMachine = require('./../state-machine');
var platform = require('./../../platform/platform');

var filesStates = {
    pending: 'pending',
    downloading: 'running',
    done: 'done',
    failed: 'failed',
    abort: 'aborted'
};

var downloadStates = {
    none: 'none',
    downloading: 'downloading'
};

/**
 * Download file states.
 *
 * Following states are given for Downloads:
 * - pending - Wait to download. (only a X to abort download is displayed)
 * - running - File is currently on downloading. (process-bar is displayed)
 * - done    - Download is ready. (now you can open this file)
 * - failed  - Download has failed, (e.g. connection lost,…)
 */
app.service('DownloadsService', function() {
    return {
        downloadList: {},

        /**
         * @method initialize
         */
        initialize: function() {
            /** @var URIService this.URIService  **/
            this.URIService = app.getService('URIService');
            this.state = new StateMachine({
                context: this,
                state: downloadStates.none,
                states: downloadStates
            });

            this.addStateTransitions();

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.onUpdateHandler();
                    this.bindEvents();
                }.bind(this));
        },

        /**
         * @method bindEvents
         */
        bindEvents: function() {
            app.on('main-loop.update', this.onUpdateHandler.bind(this));
            app.on('main-loop.update.downloads', this.onUpdateHandler.bind(this));
            app.on('reinit.updownloads', this.clearDownloadList.bind(this));
        },

        /**
         * @method onUpdateHandler
         */
        onUpdateHandler: function() {
            this.deviceConnection
                .send('getCloudDownloadList', {
                    service: 'all'
                })
                .then(this.onDataLoaded.bind(this));
        },

        /**
         * @method onDataLoaded
         * @param {object} data
         */
        onDataLoaded: function(data) {
            // Set all uptodate to false.
            this.downloadList = _.mapValues(this.downloadList, function(item) {
                return _.extend(item, {
                    uptodate: false
                });
            });

            // Update/add items.
            _.forEach(data.downloadList, function(item) {
                var listItem;

                item.uptodate = false;
                item.filename = this.URIService.encode(item.filename);
                item.name = this.getFileNameByPath(this.URIService.decode(item.filename));

                listItem = this.downloadList[item.filename];

                // Add file if status is not abort.
                if (!listItem && item.status !== filesStates.abort) {
                    this.downloadList[item.filename] = item;
                    app.emit('download-list.add', item);
                    // Remove item on abort and file not in cache.
                } else if (!listItem && item.status === filesStates.abort) {
                    this.removeFromDownloadList(item.filename);
                    app.emit('download-list.remove', item);
                    // Update file.
                } else if (JSON.stringify(this.downloadList[item.filename]) !== JSON.stringify(item)) {
                    if (this.downloadList[item.filename].status === filesStates.done) {
                        app.emit('download-list.remove', item);

                        this.downloadList[item.filename] = item;
                        app.emit('download-list.add', item);
                    } else if (item.status === filesStates.abort) {
                        app.emit('download-list.remove', item);
                        this.removeFromDownloadList(item.filename);
                        delete this.downloadList[item.filename];
                    } else {
                        this.downloadList[item.filename] = item;
                        app.emit('download-list.update', item);
                    }
                }

                if (this.downloadList[item.filename]) {
                    this.downloadList[item.filename].uptodate = true;
                }
            }.bind(this));

            // Remove items.
            _.forEach(this.downloadList, function(item, key) {
                if (!item.uptodate) {
                    app.emit('download-list.remove', item);
                    delete this.downloadList[key];
                }
            }.bind(this));

            // Set download header state.
            if (this.countDownloadList() > 0 && this.state.getState() !== downloadStates.downloading) {
                this.state.changeState(downloadStates.downloading);
            } else if (this.countDownloadList() === 0 && this.state.getState() !== downloadStates.none) {
                this.state.changeState(downloadStates.none);
            } else if (this.downloadList
                && 0 === _.keys(this.downloadList).length
                && 0 === this.countDownloadList()
                && downloadStates.none === this.state.getState()
            ) {
                app.emit('download-list.clear');
            }
        },

        /**
         * @param {string} filepath
         * @returns {string} filename
         */
        getFileNameByPath: function(filepath) {
            var filename = filepath.replace(/^.*[\\\/]/, '');

            return filename;
        },

        /**
         * Count Files in Downloads List and returns an integer.
         *
         * @return {int}
         */
        countDownloadList: function() {
            var count = 0;

            _.forEach(this.downloadList, function(item) {
                if (item.status === filesStates.pending || item.status === filesStates.downloading) {
                    count++;
                }
            });

            return count;
        },

        /**
         * Count Failed downloads in current List and returns as an integer.
         *
         * @return {int}
         */
        countDownloadedFailed: function() {
            var count = 0;

            _.forEach(this.downloadList, function(item) {
                if (item.status === filesStates.failed) {
                    count++;
                }
            });

            return count;
        },

        /**
         * Count successfully downloaded files.
         *
         * @return {int}
         */
        countDownloadedList: function() {
            var count = 0;

            _.forEach(this.downloadList, function(item) {
                if (item.status === filesStates.done) {
                    count++;
                }
            });

            return count;
        },

        /**
         * Remove all Files from current Downloads-List.
         */
        clearDownloadList: function() {
            _.forEach(this.downloadList, function(item, key) {
                delete this.downloadList[key];
            }.bind(this));

            app.emit('main-loop.fast.start', {
                id: 'downloads'
            });
        },

        /**
         * @return {Array}
         */
        getDownloadList: function() {
            return this.downloadList;
        },

        /**
         * Start a new Download.
         *
         * @param {string} path
         */
        startDownload: function(path, action) {
            action = action || 'preload';

            this.deviceConnection
                .send('setCloudPreload', {
                    filename: this.URIService.decode(path),
                    action: action
                });

            app.emit('main-loop.fast.start', {
                id: 'downloads'
            });
        },

        /**
         * Start Downloads from given file-list.
         *
         * @param {SelectedFilesList} selectedFilesList
         * @param {String} action
         */
        startDownloads: function(selectedFilesList, action) {
            var file = selectedFilesList.first();

            while (file.done === false) {
                this.startDownload(file.value.path, action);

                file = selectedFilesList.next();
            }
        },

        /**
         * Abort a download.
         *
         * @param {string} path
         */
        abortDownload: function(path) {
            this.deviceConnection
                .send('setCloudPreload', {
                    filename: this.URIService.decode(path),
                    action: 'abort'
                });

            app.emit('main-loop.fast.start', {
                id: 'downloads'
            });
        },

        /**
         * Removes a Download from List.
         *
         * @param {string} path
         */
        removeFromDownloadList: function(path) {
            this.deviceConnection
                .send('setRemoveFromDownloadList', {
                    filename: this.URIService.decode(path)
                });

            app.emit('main-loop.fast.start', {
                id: 'downloads'
            });
        },

        addStateTransitions: function() {
            this.state.addTransitions({
                '> downloading': function() {
                    if (!platform.checks.isCboxAux) {
                        app.emit('status-widget.item.append', {
                            id: 'downloading',
                            accessKey: 'UpDownloads',
                            options: {
                                icon: 'icon-v2-download',
                                clickable: false
                            }
                        });
                    }
                },
                '> none': function() {
                    app.emit('status-widget.item.remove', {
                        id: 'downloading'
                    });
                }
            });
        }
    };
});
