// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".btn-switch\"\n                 data-nav-trapped\n                 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"defaultArea") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":17},"end":{"line":12,"column":69}}})) != null ? stack1 : "")
    + ">\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"text") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":44},"end":{"line":14,"column":59}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-2 pull-right\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\" id=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\" type=\"checkbox\" class=\"btn-switch\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "data-nav-area-default";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"hdcp-settings\" name=\"hdcp-settings\">\n        <div class=\"row settings-list-row\">\n            <div class=\"col-xs-12\">\n                <h2>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(alias1,"settings.hdcp",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":41}}}))
    + "</h2>\n            </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"hdcpTypes") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":25,"column":17}}})) != null ? stack1 : "")
    + "        <div id=\"form-action-container\" class=\"form-primary-background\"></div>\n    </form>\n</div>\n";
},"useData":true});
