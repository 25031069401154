'use strict';

const app = require('../app');
const _ = require('lodash');
const $ = require('jquery');
const i18n = require('i18next');
const StateMachine = require('./../state-machine');
const platform = require('../../platform/platform');
const pollyfillUnidentifiedKey = require('./../../keyboard/pollyfills/unidentified-keys');
const uiStates = require('./../states').uiStates;
const contentControlBarPinSupport = require('./../settings').contentControlBarPinSupport;
const titleKeys = require('../framebox-types').contentTypeTitleKeys;
const helper = require('../helper.js');

/** @var {KeyMapper} keyMapper **/
const keyMapper = require('./../../keyboard/key-mapper');
const keyToKeyCodeMappingDe = require('./../../keyboard/key-to-key-code-de.json');
const keyToKeyCodeMappingEn = require('./../../keyboard/key-to-key-code-en.json');
const keyToKeyCodeMappingFr = require('./../../keyboard/key-to-key-code-fr.json');
const keyToKeyCodeMappingNo = require('./../../keyboard/key-to-key-code-no.json');
const keyToKeyCodeMappingSv = require('./../../keyboard/key-to-key-code-sv.json');
const keyToKeyCodeMappingIt = require('./../../keyboard/key-to-key-code-it.json');
const keyToKeyCodeMappingCh = require('./../../keyboard/key-to-key-code-ch.json');
const keyToKeyCodeMappingUk = require('./../../keyboard/key-to-key-code-uk.json');
const keyToKeyCodeMappingJp = require('./../../keyboard/key-to-key-code-jp.json');
const keyToKeyCodeMappingFrCa = require('./../../keyboard/key-to-key-code-fr-ca.json');

const fullscreenStates = {
    none: 'none',
    open: 'open',
    closed: 'closed'
};

/**
 * Control bar pinned (never hidden) or unpinned.
 */
const pinStates = {
    pinned: 'pinned',
    unpinned: 'unpinned'
};

const keyDownEvents = ['keydown', 'touchstart'];
const keyUpEvents = ['keyup', 'touchend'];

let keyModifier = ['Alt', 'AltGraph', 'Control'];

/**
 * Map frameBoxComponent to componentType
 * @type {{FrameBoxVSolution}}
 */
const componentMapping = {
    'FrameBoxVisualizer': 'Visualizer',
    'FrameBoxMirroring': 'Mirroring',
    'FrameBoxBrowserViewer': 'Browser',
    'FrameBoxOffice365Viewer': 'Office365',
    'FrameBoxTeamsViewer': 'teams',
    'FrameBoxHdmi': 'HdmiIn',
    'FrameBoxVideoViewer': 'Video',
    'FrameBoxVSolution': 'vSolution',
    'FrameBoxImageViewer': 'Image',
    'FrameBoxPDFViewer': 'PDF',
    'FrameBoxCalcViewer': 'calc',
    'FrameBoxPresentationViewer': 'presentation',
    'FrameBoxTextViewer': 'text',
    'FrameBoxWhiteboardViewer': 'whiteboard',
    'FrameBoxAudioViewer': 'Audio',
    'FrameBoxWebconferenceViewer': 'webconference',
    'FrameBoxStreamInputViewer': 'streaminput',
    'FrameBoxWebcamViewer': 'webcam',
    'FrameBoxMatrix': 'matrix',
    'FrameBoxMatrixControl': 'matrixControl',
    'FrameBoxMatrixGroupwork': 'matrixGroupwork',
    'FrameBoxZoomViewer': 'zoom'
};

app.service('FrameBoxService', function FrameBoxService(app) {
    var componentConfigs = {
        'Visualizer': {
            titleKey: titleKeys['Visualizer'],
            component: 'FrameBoxVisualizer'
        },

        'Mirroring': {
            titleKey: titleKeys['Mirroring'],
            component: 'FrameBoxMirroring'
        },

        'Browser': {
            titleKey: titleKeys['Browser'],
            component: 'FrameBoxBrowserViewer'
        },

        'Office365': {
            titleKey: titleKeys['Office365'],
            component: 'FrameBoxOffice365Viewer'
        },

        'teams': {
            titleKey: titleKeys['teams'],
            component: 'FrameBoxTeamsViewer',
            customClose: true
        },

        'HdmiIn': {
            titleKey: titleKeys['HdmiIn'],
            component: 'FrameBoxHdmi'
        },

        'Video': {
            titleKey: titleKeys['Video'],
            component: 'FrameBoxVideoViewer'
        },

        'vSolution': {
            titleKey: titleKeys['vSolution'],
            component: 'FrameBoxVSolution',
            customClose: true
        },

        'otherContent': {
            titleKey: '',
            titleIconKey: 'icon-lock1',
            component: 'FrameBoxOthers'
        },

        'Image': {
            titleKey: titleKeys['Image'],
            component: 'FrameBoxImageViewer'
        },

        'PDF': {
            titleKey: titleKeys['PDF'],
            component: 'FrameBoxPDFViewer'
        },

        'calc': {
            titleKey: titleKeys['calc'],
            component: 'FrameBoxCalcViewer'
        },

        'presentation': {
            titleKey: titleKeys['presentation'],
            component: 'FrameBoxPresentationViewer'
        },

        'text': {
            titleKey: titleKeys['text'],
            component: 'FrameBoxTextViewer'
        },

        'whiteboard': {
            titleKey: titleKeys['whiteboard'],
            component: 'FrameBoxWhiteboardViewer'
        },

        'Audio': {
            titleKey: titleKeys['Audio'],
            component: 'FrameBoxAudioViewer'
        },

        'webconference': {
            titleKey: titleKeys['webconference'],
            component: 'FrameBoxWebconferenceViewer'
        },

        'zoom': {
            titleKey: titleKeys['zoom'],
            component: 'FrameBoxZoomViewer',
            customClose: true
        },

        'streaminput': {
            titleKey: titleKeys['streaminput'],
            component: 'FrameBoxStreamInputViewer'
        },

        'webcam': {
            titleKey: titleKeys['webcam'],
            component: 'FrameBoxWebcamViewer'
        },

        'matrix': {
            titleKey: titleKeys['matrix'],
            component: 'FrameBoxMatrix'
        },

        'matrixControl': {
            titleKey: titleKeys['matrixControl'],
            component: 'FrameBoxMatrixControl'
        },

        'matrixGroupwork': {
            titleKey: titleKeys['matrixGroupwork'],
            component: 'FrameBoxMatrixGroupwork'
        }
    };

    // TODO: touchevents
    // /**
    //  * @method normalizeMouseEventType
    //  *
    //  * @param {String} eventType
    //  *
    //  * @returns {String}
    //  */
    // Var getNormalizedMouseEventType = function(eventType) {
    //     Var events = {
    //         'touchstart': 'mousedown',
    //         'touchend': 'mouseup'
    //     };
    //
    //     If (events[eventType]) {
    //         Return events[eventType];
    //     } else {
    //         Return eventType;
    //     }
    // };

    var frameBoxesLoaded = true;

    return {
        isInBackground: false,
        menuOpened: false,
        isTouched: false,

        /**
         * Every property represents a frambeox.
         *
         * @var {Array}
         */
        forceFrameboxUpdate: [false, false, false, false],

        /**
         * @method initialize
         */
        initialize: function() {
            this.resetDataStore();
            this.lastKeyEvent = null;
            this.keyModifierEvent = null;
            this.lastKeydownEvent = null;
            this.fullscreenState = new StateMachine({
                context: this,
                state: fullscreenStates.none,
                states: fullscreenStates
            });
            this.uiState = new StateMachine({
                context: this,
                state: uiStates.visible,
                states: uiStates
            });

            this.controlBarPinned = new StateMachine({
                state: pinStates.pinned,
                states: pinStates
            });

            this.liveStream = app.getService('LiveStreamService');
            this.freezeService = app.getService('FreezeService');
            this.uploadService = app.getService('UploadService');
            this.URIService = app.getService('URIService');
            this.languageService = app.getService('LanguageService');
            this.frontendSettings = app.getService('FrontendSettings');
            this.lastUnresolvedEvents = [];

            // Set default key mapping
            this.initKeyMapping();

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                }.bind(this));

            app.on('framebox.speed.full', this.fullSpeed.bind(this));
            app.on('framebox.speed.slow', this.slowDown.bind(this));
            app.on('control-center.opened', this.setControlCenter.bind(this, true));
            app.on('control-center.closed', this.setControlCenter.bind(this, false));
            app.on('overlay.opened-end', this.setOverlay.bind(this, true));
            app.on('overlay.closed', this.setOverlay.bind(this, false));
            app.on('file-browser.opened', this.setFilebrowser.bind(this, true));
            app.on('file-browser.closed', this.setFilebrowser.bind(this, false));
            app.on('menu.opened', this.setMenu.bind(this, true));
            app.on('menu.closed', this.setMenu.bind(this, false));
            app.on('modal.opened-end', this.setModal.bind(this, true));
            app.on('modal.opened', this.setModal.bind(this, true));
            app.on('modal.closed', this.setModal.bind(this, false));
            app.on('keyboard.update', this.initKeyMapping.bind(this));
            app.on('control-bar.pin.toggle', this.updateControlBarPinned.bind(this));
            app.on('ui.show', function() {
                this.uiState.changeState(uiStates.visible);
            });
            app.on('ui.hide', function() {
                this.uiState.changeState(uiStates.hidden);
            });

            $(window).on('blur', this.onWindowInactive.bind(this));
        },

        initKeyMapping: function(layout) {
            this.keyToKeyCodeMappingFallback = keyToKeyCodeMappingEn;

            switch (layout) {
                case 'de':
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingDe;

                    return;
                case 'fr':
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingFr;

                    return;
                case 'fr-ca':
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingFrCa;

                    return;
                case 'no':
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingNo;

                    return;
                case 'sv':
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingSv;

                    return;
                case 'it':
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingIt;

                    return;
                case 'ch':
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingCh;

                    return;
                case 'uk':
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingUk;

                    return;
                case 'jp':
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingJp;

                    return;
                default: // Layout === us = en
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingEn;
            }
        },

        /**
         * Reset all data to start-value.
         * Called after Service-initialize and after annotation was closed.
         */
        resetDataStore: function() {
            this.controlCenterOpened = false;
            this.modalOpened = false;
            this.menuOpened = false;
            this.overlayOpened = false;
            this.filebrowserOpened = false;
            this.standardMode = [];
            this.referenceDimension = {
                width: 0,
                height: 0
            };
            this.activeFrameBox = '-1';
            this.activeHdmiOut2Matrix = [
                true,
                true,
                true,
                true
            ];
            this.activeHdmiOut2MatrixChanged = false;

            this.activeTransparencyMatrix = [
                false,
                false,
                false,
                false
            ];

            this.wipeOutMatrix = [
                false,
                false,
                false,
                false
            ];
        },

        /**
         * @method setIsInBackground
         */
        setIsInBackground: function() {
            this.isInBackground = !!(this.overlayOpened || this.menuOpened || this.controlCenterOpened
                || this.modalOpened || this.filebrowserOpened);

            app.emit('framebox.in-background.change', this.isInBackground);
        },

        setOverlay: function(isOpened) {
            this.overlayOpened = isOpened;
            this.setIsInBackground();
        },

        setMenu: function(isOpened) {
            this.menuOpened = isOpened;
            this.setIsInBackground();
        },

        setControlCenter: function(isOpened) {
            this.controlCenterOpened = isOpened;
            this.setIsInBackground();
        },

        setFilebrowser: function(isOpened) {
            this.filebrowserOpened = isOpened;
            this.setIsInBackground();
        },

        setModal: function(isOpened) {
            this.modalOpened = isOpened;
            this.setIsInBackground();
        },

        /**
         * @method handleFramesError
         */
        handleFramesError: function() {
            frameBoxesLoaded = true;
        },

        /**
         * This function handles the framebox overlays.
         *
         * @method handleFrames
         * @param {Object} frameBoxes
         */
        handleFrames: function(frameBoxes) {
            var targetDimensions = this.liveStream.getSize();
            this.frameboxes = frameBoxes;
            this.referenceDimension = frameBoxes.reference;

            frameBoxes = this.convertAllCoordinates(frameBoxes, targetDimensions);

            app.emit('frameboxes.update', frameBoxes);
            frameBoxesLoaded = true;

            this.checkFullscreen();
        },

        /**
         * Check whether the fullscreen-state has changed.
         * If fullscreen-state has been changed, this method will call open- or close-fullscreen mehtod.
         */
        checkFullscreen: function() {
            this.isFullscreen()
                .then(function(data) {
                    if (data.isFullscreen && this.fullscreenState.getState() !== fullscreenStates.open) {
                        this.openFullsceen();
                    } else if (!data.isFullscreen && this.fullscreenState.getState() !== fullscreenStates.closed) {
                        this.closeFullscreen();
                    }
                }.bind(this));
        },

        /**
         * Open fullscreen.
         * This method will change the app state that fullscreen is working everywhere.
         */
        openFullsceen: function() {
            // Wait for keyboard is closed because the windows-size will get smaller on Android Devices.
            // See RELEASE-1110
            setTimeout(function() {
                this.liveStream.setOffset({
                    top: 0
                });
                app.emit('app.resize');
            }.bind(this), 300);

            app.emit('control-center.close');
            app.emit('menu.close');
            app.emit('status-bar.hide');
            app.emit('app.state.change', {
                'is-fullscreen': true
            });
            app.emit('fullscreen.opened');

            this.fullscreenState.changeState(fullscreenStates.open);
        },

        /**
         * Close fullscreen.
         * This method will remove all app-states that we needed for a fullscreenmode.
         */
        closeFullscreen: function() {
            // Wait for keyboard is closed because the windows-size will get smaller on Android Devices.
            // See RELEASE-1110
            setTimeout(function() {
                this.liveStream.setOffset({
                    top: 40,
                    bottom: 0
                });
                app.emit('app.resize');
            }.bind(this), 300);

            app.emit('control-center.close');
            app.emit('menu.close');
            app.emit('status-bar.show');
            app.emit('app.state.change', {
                'is-fullscreen': false
            });
            app.emit('show.ui');
            app.emit('fullscreen.framebox.standard.controls.show');
            app.emit('fullscreen.closed');

            this.fullscreenState.changeState(fullscreenStates.closed);
        },

        /**
         * Loads the framebox info from the box and calls handle frames afterwards.
         */
        loadFrameBoxes: function() {
            if (frameBoxesLoaded === true && !this.freezeService.isFreeze()) {
                frameBoxesLoaded = false;
                this.deviceConnection
                    .send('getContentPerOutput')
                    .then(data => this.frameBoxAdapter(data))
                    .then(
                        this.handleFrames.bind(this),
                        this.handleFramesError.bind(this)
                    );
            }
        },

        /**
         * For backwards-compatibility transform data to legacy structure
         */
        frameBoxAdapter: function(data) {
            let boxes = [];
            let reference = null;

            for (let i = 0; i < 4; i++) {
                boxes[i] = data.windows.find(w => w.index === i);
            }

            if (data.windows.length > 0 && data.windows.every(w => _.isEqual(w.reference, data.windows[0].reference))) {
                reference = data.windows[0].reference;
            }

            return {
                boxes: boxes,
                reference: reference,
                fullscreenFrameBox: boxes.find(b => b?.fullscreen)
            };
        },

        /**
         * @type {Object}
         */
        Engine: null,

        /**
         * @method setEngine
         * @param {Object} Engine
         */
        setEngine: function(Engine) {
            if (Engine) {
                this.Engine = Engine;
            }

            return this;
        },

        /**
         * Starts the framebox handling.
         */
        start: function() {
            if (!this.Engine) {
                throw new Error('No Engine defined!');
            }

            this.Engine.start();
            this.Engine.on('redraw.update', function() {
                if (!this.paused) {
                    this.loadFrameBoxes();
                }
            }.bind(this));

            return this;
        },

        /**
         * @type {Boolean}
         */
        paused: false,

        /**
         * Pause the stream
         * @method pause
         */
        pause: function() {
            this.paused = true;
        },

        /**
         * Unpause the stream
         * @method pause
         */
        unpause: function() {
            this.paused = false;
        },

        /**
         * Stop the framebox handling
         * @method stop
         */
        stop: function() {
            if (this.Engine) {
                this.Engine.off('redraw.update', this.loadFrameBoxes);
            }

            return this;
        },

        /**
         * @method slowDown
         */
        slowDown: function() {
            if (this.Engine) {
                this.Engine.slowDown();
            }
        },

        /**
         * @method fullSpeed
         */
        fullSpeed: function() {
            if (this.Engine) {
                this.Engine.fullSpeed();
            }
        },

        /**
         * Returns the config for the needed Framebox-Type.
         *
         * @param {String} frameType
         *
         * @return {Object}
         */
        getConfig: function(frameType) {
            return componentConfigs[frameType] || null;
        },

        /**
         * This function scales the coordinates from the reference dimensions
         * to the target dimensions
         * @method convertCoordinates
         * @param {Object} coordinates the coordinates in the reference dimensions
         * @param {Object} referenceDimensions the reference dimension
         * @param {Object} targetDimensions the target dimensions
         * @return {Object} returns the scaled coordinates
         */
        convertCoordinates: function(coordinates, referenceDimensions, targetDimensions) {
            targetDimensions = targetDimensions || this.referenceDimension;

            return helper.convertCoordinates(coordinates, referenceDimensions, targetDimensions);
        },

        /**
         * Converts all coordinates from the reference to the target dimensions
         * @method convertAllCoordinates
         * @param {Object} frameBoxes
         * @param {Object} targetDimension
         *
         * @return {Object} the frame positons
         */
        convertAllCoordinates: function(frameBoxes, targetDimension) {
            var i;

            for (i = 0; i < frameBoxes.boxes.length; i++) {
                if (typeof frameBoxes.boxes[i] !== 'undefined') {
                    frameBoxes.boxes[i].coordinates = this.convertCoordinates(
                        frameBoxes.boxes[i].coordinates,
                        frameBoxes.reference,
                        targetDimension
                    );
                }
            }

            return frameBoxes;
        },

        /**
         * @method openFrameBox
         * @param  {String} frameBoxType
         * @param {String} parameter
         */
        openFrameBox: function(frameBoxType, parameter, privateData) {
            this.deviceConnection
                .send('setAddNewFrameboxPrivate', {
                    content: frameBoxType,
                    parameter: parameter,
                    privateData: privateData
                });
        },

        /**
         * @method openFile
         * @param file
         * @returns {*}
         */
        openFile: function(file) {
            if (this.freezeService.isFreeze()) {
                app.emit('frontend-osd-message.show', {
                    osdMessage: i18n.t('framebox.freeze_activated'),
                    icon: 'info'
                });

                return;
            }

            app.emit('framebox.coming');
            this.deviceConnection
                .send('setOpenFile', {
                    filename: this.URIService.decode(file.path)
                });
        },

        /**
         * Returns the number of open frameboxes/windows
         * @returns {*}
         */
        getNumberOpenFrameboxes: function() {
            var count = 0;

            if (!this.frameboxes || !this.frameboxes.boxes) {
                return 0;
            }

            _.each(this.frameboxes.boxes, function(box) {
                if (typeof box !== 'undefined') {
                    count++;
                }
            }.bind(this));

            return count;
        },

        /**
         * @param {object} options
         *
         * @returns {jQuery.Deferred.promise}
         */
        hasFrameboxByName: function(names) {
            var dfd = $.Deferred();
            var hasFramebox = false;

            if (!this.frameboxes || !this.frameboxes.boxes) {
                dfd.resolve({
                    hasFramebox: false
                });

                return dfd.promise();
            }

            _.each(this.frameboxes.boxes, function(box) {
                if (!box) {
                    return;
                }

                for (var i = 0; i < names.length; i++) {
                    if (box.contentType === names[i]) {
                        hasFramebox = true;
                    }
                }
            }.bind(this));

            dfd.resolve({
                hasFramebox: hasFramebox
            });

            return dfd.promise();
        },

        /**
         * @method closeFrameBox
         * @param  {Number} frameBoxId
         */
        closeFrameBox: function(frameBoxId) {
            this.deviceConnection
                .send('setFrameboxControl', {
                    frameboxControl: 'close',
                    frameBoxId: frameBoxId
                });
        },

        /**
         * @method toggleAuxFrameBox
         * @param  {Number} frameBoxId
         */
        toggleAuxFrameBox: function(frameBoxId) {
            this.deviceConnection
                .send('setFrameboxControl', {
                    frameboxControl: 'auxToggle',
                    frameBoxId: frameBoxId
                });
        },

        /**
         * @method fullscreenFrameBox
         * @param {Number} frameBoxId
         */
        fullscreenFrameBox: function(frameBoxId) {
            this.deviceConnection
                .send('setFrameboxControl', {
                    frameboxControl: 'fullscreen',
                    frameBoxId: frameBoxId
                });
        },

        /**
         * @method setActiveFrameBox
         */
        setActiveFrameBox: function(frameBoxId) {
            this.activeFrameBox = frameBoxId;
        },

        /**
         * @method getActiveFrameBox
         */
        getActiveFrameBox: function() {
            return this.activeFrameBox;
        },

        /**
         * Update backdrop status matrix
         * @method getActiveFrameBox
         * @param {string} index
         * @param {boolean} active
         */
        setActiveAux: function(index, active) {
            if (this.activeHdmiOut2Matrix[index] !== active) {
                this.activeHdmiOut2MatrixChanged = true;
            }

            this.activeHdmiOut2Matrix[index] = active;
        },

        /**
         * Update transparency
         * @method getActiveFrameBox
         * @param {string} index
         * @param {boolean} transparent
         */
        setTransparency: function(index, transparent) {
            if (this.activeTransparencyMatrix[index] !== transparent) {
                app.emit('framebox.transparent.update', {
                    index: index,
                    transparent: transparent
                });
            }

            this.activeTransparencyMatrix[index] = transparent;
        },

        /**
         * Update wipe out.
         * @method getActiveFrameBox
         * @param {string} index
         * @param {boolean} transparent
         */
        setWipeOut: function(index, wipeout) {
            this.frontendSettings
                .getSettings(['avoidInfinityMirrorVApp'])
                .then(function(avoidInfinityMirror) {
                    wipeout = avoidInfinityMirror ? wipeout : false;

                    if (this.wipeOutMatrix[index] !== wipeout) {
                        app.emit('framebox.wipe-out.update', {
                            index: index,
                            wipeOut: wipeout
                        });
                    }

                    this.wipeOutMatrix[index] = wipeout;
                }.bind(this));
        },

        /**
         * Send event on aux status changed
         * @method sendActiveAuxEvent
         */
        sendActiveAuxEvent: function() {
            if (this.activeHdmiOut2MatrixChanged) {
                _.forEach(this.activeHdmiOut2Matrix, function(value, index) {
                    app.emit('framebox.hdmi-out-2.update', {
                        index: index,
                        isHdmiOut2: value
                    });
                }.bind(this));
            }

            this.activeHdmiOut2MatrixChanged = false;
        },

        /**
         * Update pin state for control bar.
         */
        updateControlBarPinned: function() {
            var pinState;

            switch (this.controlBarPinned.getState()) {
                case pinStates.pinned:
                    pinState = pinStates.unpinned;
                    break;
                case pinStates.unpinned:
                    pinState = pinStates.pinned;
                    break;
            }

            this.controlBarPinned.changeState(pinState);
            app.emit('control-bar.pin.update', pinState);
        },

        /**
         * Check if control bar is pinned.
         * Only in fullscreen mode, if active framebox supports pinning and pin state is 'pinned'
         *
         * @returns {*|boolean}
         */
        isControlBarPinned: function() {
            if (!this.frameboxes || !this.frameboxes.boxes[this.activeFrameBox]) {
                return;
            }

            return (this.fullscreenBox && this.controlBarPinned.getState() === pinStates.pinned
                && -1 !== contentControlBarPinSupport.indexOf(this.frameboxes.boxes[this.activeFrameBox].contentType));
        },

        /**
         * Returns true if content is on HDMI2 out.
         *
         * @return {Boolean}
         */
        isHdmiOut2: function(index) {
            return this.activeHdmiOut2Matrix[index];
        },

        /**
         * Returns framebox transparent value
         * @method isTransparent
         */
        isTransparent: function(index) {
            return this.activeTransparencyMatrix[index];
        },

        /**
         * Returns framebox wipe out value
         */
        isWipedOut: function(index) {
            return this.wipeOutMatrix[index];
        },

        /**
         * Returns a promise that will return true or false if a window is in fullscreen.
         * @method isFullscreen
         * @return {jQuery.Deferred}
         */
        isFullscreen: function() {
            var dfd = $.Deferred();
            var isFullscreen = this.isFullscreenOpen();

            dfd.resolve({
                isFullscreen: isFullscreen
            });

            return dfd.promise();
        },

        /**
         * Returns true if a window is in fullscreen.
         *
         * @return {boolean}
         */
        isFullscreenOpen: function() {
            var isFullscreen = false;

            if (this.frameboxes) {
                _.each(this.frameboxes.boxes, function(box) {
                    if (box && box.fullscreen) {
                        isFullscreen = true;
                    }
                }.bind(this));
            }

            return isFullscreen;
        },

        /**
         * Check if framebox supports double-tap to toggle fullscreen.
         *
         * Double tap is supported for all frameboxes, except
         *  - vSolutionCast
         *  - matrixControl
         *  - Miracast when input back channel is enabled
         *
         * @param index: framebox index
         */
        supportsDoubleTap: function(index) {
            let dfd = $.Deferred();
            let framebox = this.frameboxes.boxes[index];

            // Is Miracast connection
            if (framebox.contentType === 'vSolution' && !framebox.options.isVSolutionCast) {
                this.deviceConnection
                    .send('getMiracastBackChannel')
                    .then(function(miracastBackChannel) {
                        dfd.resolve({
                            supported: !miracastBackChannel.enabled
                        });
                    }.bind(this));
            } else {
                dfd.resolve({
                    supported: framebox.contentType !== 'vSolution'
                        && framebox.contentType !== 'matrixControl'
                });
            }

            return dfd.promise();
        },

        /**
         * @method minimizeFrameBox
         * @param {Number} frameBoxId
         */
        minimizeFrameBox: function(frameBoxId) {
            this.deviceConnection
                .send('setFrameboxControl', {
                    frameboxControl: 'normal',
                    frameBoxId: frameBoxId
                }).then(function() {
                    app.emit('main-loop.fast.start', {
                        id: 'header'
                    });
                }.bind(this));
        },

        /**
         * Send mouse events.
         *
         * @param {Number} x: x coordinates
         * @param {Number} y: y coordinates
         * @param {String} eventType: the mouseevent
         * @param {Number} index: Framebox-index between 0-4.
         */
        sendMouseEvents: function(x, y, eventType, index, identifier) {
            if (!this.frameboxes.boxes[this.activeFrameBox]) {
                return;
            }

            // Prevent sending mouse events if cbox fullscreen framebox overlay ui is invisible
            // Var normalizedEventType = getNormalizedMouseEventType(eventType);
            var normalizedEventType = eventType;
            var eventList = [];

            eventList.push({
                event: normalizedEventType,
                frameBoxId: index,
                positionX: x,
                positionY: y,
                identifier: identifier
            });

            this.sendMultiEvents(index, eventList);

            // Set force update to true but only on mousedown
            // To prevent loosing focus and closing onscreenkeyboard RELEASE-3382
            if (eventType === 'mousedown') {
                this.forceFrameboxUpdate[index] = true;
            }
        },

        /**
         * This method is called when the window has been unfocused.
         * Here we will check if there is a open checkdown event then we will send a keyup event.
         */
        onWindowInactive: function() {
            var event = this.lastKeyEvent;

            // RELEASE-3867: Delete key modifier if event isn't sent.
            this.keyModifierEvent = undefined;

            if (event) {
                if ('keydown' === event.keyState) {
                    event.keyState = 'keyup';
                } else if ('touchstart' === event.keyState) {
                    event.keyState = 'touchend';
                }
            } else {
                return;
            }

            this.deviceConnection
                .send('setFrameboxMultiEvents', {
                    event: 'keyboard',
                    eventList: [event]
                });
        },

        /**
         * Send multiple events.
         *
         * @param {Number} index: Framebox-index between 0-4.
         * @param {Array} events
         */
        sendMultiEvents: function(index, events) {
            var resolvedEvents = [];
            if (!this.frameboxes.boxes[this.activeFrameBox]) {
                return;
            }

            // RELEASE-2484 - Wrong touch event in libreoffice window.
            // Removes all events that makes no sense.
            events.forEach(function(event) {
                // RELEASE-3405: Save last touchstart/mousedown events.
                if (event.event === 'touchstart' || event.event === 'mousedown') {
                    this.lastUnresolvedEvents.push(event);
                } else if (event.event === 'touchend' || event.event === 'mouseup') {
                    this.lastUnresolvedEvents = [];
                }

                if ('touchstart' === event.event && !this.isTouched) {
                    this.isTouched = true;
                } else if ('touchend' === event.event && this.isTouched) {
                    this.isTouched = false;
                }

                resolvedEvents.push(event);
            }.bind(this));

            if (0 === resolvedEvents.length) {
                return;
            }

            this.deviceConnection
                .send('setFrameboxMultiEvents', {
                    event: 'pointer',
                    eventList: resolvedEvents
                });
        },

        /**
         * RELEASE-3405: Before Quick Anntotation is started, check if there are some unresloved events
         * ("open" touchstart/mousedown) and send a touchend/mouseup.
         */
        resolveLastEvents: function() {
            if (this.lastUnresolvedEvents.length === 0) {
                return;
            }

            this.lastUnresolvedEvents.forEach(function(event) {
                switch (event.event) {
                    case 'touchstart':
                        event.event = 'touchend';
                        break;
                    case 'mousedown':
                        event.event = 'mouseup';
                        break;
                }
            }.bind(this));

            this.deviceConnection
                .send('setFrameboxMultiEvents', {
                    event: 'pointer',
                    eventList: this.lastUnresolvedEvents
                });

            this.lastUnresolvedEvents = [];
        },

        /**
         * Send Key event.
         *
         * @param {String | Number} frameBoxId [description]
         * @param {String} keyState
         * @param {Object} event
         *
         */
        sendKeyEvent: function(frameBoxId, keyState, event) {
            var eventList = [];
            var keyEvent;

            event = pollyfillUnidentifiedKey(event, this.languageService.keyboardLayout);

            // FIREFOX OS special chars (e.g. norwegian: ø, æ, å; also chinese in the future of PO's dream ;-))
            if (event.type.indexOf('composition') > -1) {
                event.key = event.originalEvent.data;
            }

            if (window.CYNAP_ENV === 'dev') {
                console.log('SEND KEY EVENT:');
                console.log(event);
            }

            /**
             * KEY TO KEY CODE MAPPING
             * - Handle ANDROID and iOS.
             */
            if (platform.checks.isAndroid || platform.checks.isIOS) {
                // OFFICE 365 (e.g. space, arrow up/down,...): Handle keyup when we get the icon-element as the event.key.
                if (event.key.trim().indexOf('<i') === 0) {
                    event.key = '';
                }

                // OFFICE 365 (e.g. space, arrow up/down,...): Use data-character when the event key is empty.
                if (event.key.length === 0 && event.$key && event.$key.data('character')) {
                    event.key = event.$key.data('character');
                }

                if (keyState === 'keydown') {
                    this.lastKeydownEvent = event;
                } else if (keyState === 'keyup' && event.key === 'Unidentified' && this.lastKeydownEvent.keyCode === event.keyCode) {
                    event.key = this.lastKeydownEvent.key;
                }

                // Handle key modifier
                keyEvent = this.getKeyEventModifier(frameBoxId, keyState, event.key);

                if (keyEvent) {
                    /**
                     * IOS: Send a shift keyup before a modifier key with AltGr is sent
                     * because the shift key and the key to change to the special keys is the same
                     * and it would send Shift + AltGr + Key.
                     */
                    if (platform.checks.isIOS && keyEvent.keyCode === 100) {
                        eventList.push({
                            event: 'keyboard',
                            frameBoxId: frameBoxId,
                            keyState: 'keyup',
                            key: 0,
                            keyCode: 42,
                            shift: 0,
                            alt: 0,
                            ctrl: 0
                        });
                    }

                    eventList.push(keyEvent);
                }

                keyEvent = this.getKeyEvent(frameBoxId, keyState, event.key);

                if (keyEvent) {
                    eventList.push(keyEvent);
                }

                if (eventList.length > 0) {
                    this.deviceConnection
                        .send('setFrameboxMultiEvents', {
                            event: 'keyboard',
                            eventList: eventList
                        });

                    if (window.CYNAP_ENV === 'dev') {
                        console.log('--- NEW MAPPING ---');
                        console.log(eventList);
                    }

                    return;
                }
            }

            // ------- DEFAULT Mapping -------

            // Send default key + key code.
            if (typeof event !== 'undefined' && (event.key || '' === event.key)) {
                if (false === event.originalEvent.repeat) {
                    var keyCode = event.keyCode;

                    // Letter has been overridden via key-code.
                    if (!event.$key || !event.$key.data('key-code')) {
                        keyCode = keyMapper.mapByEvent(event, event.keyCode);
                    }

                    /**
                     * Handle keys with modifier CONTROL, ALTGRAPH, CONTROL + ALT, ALT
                     * (e.g. @, €, $,...)
                     */
                    if (keyState === 'keydown' && -1 !== keyModifier.indexOf(event.key)) { // Save key modifier on keydown
                        this.keyModifierEvent = event;

                        return;
                    } else if (keyState === 'keydown' && this.keyModifierEvent) { // Send key modifier with at the same time as the symbol
                        keyEvent = this.getKeyEvent(frameBoxId, keyState, this.keyModifierEvent.key);
                        eventList.push(keyEvent);
                    } else if (keyState === 'keyup' && this.keyModifierEvent) { // Handle modifier on keyup
                        if (-1 !== keyModifier.indexOf(event.key)) { // Delete key modifier on keyup
                            this.keyModifierUp = this.keyModifierEvent;
                            this.keyModifierEvent = undefined;

                            return;
                        }

                        // Send same key modifier on keyup
                        keyEvent = this.getKeyEvent(frameBoxId, keyState, this.keyModifierEvent.key);
                        eventList.push(keyEvent);

                        this.keyModifierUp = undefined;
                    } else if (keyState === 'keyup' && this.keyModifierUp) {
                        // Send up event of key modifier if it wasn't sent before (e.g. CONTROL+A; Release a before control)
                        keyEvent = this.getKeyEvent(frameBoxId, keyState, this.keyModifierUp.key);
                        eventList.push(keyEvent);

                        this.keyModifierUp = undefined;
                    }

                    /** Edge, IE or 'Process' (some special keys in firefox mobile, e.g. å)
                     *  - Edge, IE: In WindowsOS with touch keyboard (e.g. surface) there are some special keys (€)
                     *    which should be sent with another keycode (ieSpecialkeyMobile)
                     */
                    if (platform.checks.isIe || platform.checks.isEdge || event.key === 'Process') {
                        keyEvent = this.getIEKeyEventModifier(frameBoxId, keyState, event.key, keyCode, eventList);
                    } else if (platform.checks.isMacOS && event.key === '@') {
                        keyEvent = this.getKeyEvent(frameBoxId, keyState, event.key);
                    } else {
                        keyEvent = {
                            event: 'keyboard',
                            frameBoxId: frameBoxId,
                            keyState: keyState,
                            key: 0,
                            keyCode: keyCode,
                            shift: 0,
                            alt: 0,
                            ctrl: 0
                        };
                    }

                    eventList.push(keyEvent);
                }
            }

            if (eventList[0] && -1 !== keyDownEvents.indexOf(eventList[0].keyState)) {
                this.lastKeyEvent = eventList[0];
            } else if (eventList[0] && -1 !== keyUpEvents.indexOf(eventList[0].keyState)) {
                this.lastKeyEvent = null;
            }

            this.deviceConnection
                .send('setFrameboxMultiEvents', {
                    event: 'keyboard',
                    eventList: eventList
                });

            if (window.CYNAP_ENV === 'dev') {
                console.log('--- DEFAULT MAPPING ---');
                console.log(eventList);
            }
        },

        /**
         * Get key from key mapping.
         *
         * @param frameBoxId
         * @param keyState
         * @param key
         * @returns {*}
         */
        getKeyEvent: function(frameBoxId, keyState, key) {
            var keyCode;
            var keyEncoded = this.encodeChar(key.toLowerCase());

            if (key.length === 1) {
                keyCode = this.keyToKeyCodeMapping[keyEncoded]
                    || this.keyToKeyCodeMappingFallback[keyEncoded];
            } else {
                keyCode = this.keyToKeyCodeMapping[key.toLowerCase()]
                    || this.keyToKeyCodeMappingFallback[key.toLowerCase()];
            }

            if (keyCode) {
                if (window.CYNAP_ENV === 'dev') {
                    console.log('getKeyEvent KEY ' + key + ' to ' + keyEncoded
                        + ' | send KeyCode ' + keyCode);
                }

                return {
                    event: 'keyboard',
                    frameBoxId: frameBoxId,
                    keyState: keyState,
                    key: 0,
                    keyCode: keyCode,
                    shift: 0,
                    alt: 0,
                    ctrl: 0
                };
            }

            if (window.CYNAP_ENV === 'dev') {
                console.log('getKeyEvent KEY ' + key + ' to ' + keyEncoded + ' | keyCode is undefined');
            }

            return undefined;
        },

        /**
         * Get key modifier from mapping for Internet Explorer and Microsoft Edge.
         * (E.g. AltGr for german @)
         *
         * @param {Number} frameBoxId
         * @param {String} keyState
         * @param {String} key
         * @param {String} keyCode
         *
         * @returns {Object}
         *
         * TODO: Move to key-mapper.js
         */
        getIEKeyEventModifier: function(frameBoxId, keyState, key, keyCode, eventList) {
            var keyModifier;
            var keyEncoded = this.encodeChar(key.toLowerCase());

            if ((eventList.length > 0 && eventList[0].keyCode === 100 && this.keyToKeyCodeMapping['ie_specialkeys_mobile'][keyEncoded])
                || this.ieSpecialkeyMobile) {
                keyModifier = this.keyToKeyCodeMapping['ie_specialkeys_mobile'][keyEncoded];
                this.ieSpecialkeyMobile = !this.ieSpecialkeyMobile;
            } else if (this.keyToKeyCodeMapping['ie_specialkeys'][keyEncoded]) {
                keyModifier = this.keyToKeyCodeMapping['ie_specialkeys'][keyEncoded];
            } else if (this.keyToKeyCodeMapping[keyEncoded]) {
                keyModifier = this.keyToKeyCodeMapping[keyEncoded];
            }

            if (keyModifier) {
                if (window.CYNAP_ENV === 'dev') {
                    console.log('getIEKeyEventModifier KEY ' + key + ' to ' + keyEncoded
                        + ' | send KeyModifier ' + keyModifier);
                }

                return {
                    event: 'keyboard',
                    frameBoxId: frameBoxId,
                    keyState: keyState,
                    key: 0,
                    keyCode: keyModifier,
                    shift: 0,
                    alt: 0,
                    ctrl: 0
                };
            }

            if (window.CYNAP_ENV === 'dev') {
                console.log('getIEKeyEventModifier KEY ' + key + ' to ' + keyEncoded
                    + ' | send KeyCode ' + keyCode + ' (keyModifier is undefined)');
            }

            return {
                event: 'keyboard',
                frameBoxId: frameBoxId,
                keyState: keyState,
                key: 0,
                keyCode: keyCode,
                shift: 0,
                alt: 0,
                ctrl: 0
            };
        },

        /**
         * Get key modifier from mapping.
         * (E.g. AltGr for german @)
         *
         * @param frameBoxId
         * @param keyState
         * @param key
         * @returns {*}
         *
         * TODO: Move to key-mapper.js
         */
        getKeyEventModifier: function(frameBoxId, keyState, key) {
            var keyModifier;
            var keyEncoded = this.encodeChar(key.toLowerCase());

            // Used for mobile devices (iOS, Android) when we get only the uppercase character (e.g. A) without the shift key.
            if (this.isUpperCase(key) && (platform.checks.isAndroid || platform.checks.isIOS)) {
                keyModifier = this.keyToKeyCodeMapping['modifier']['uppercase']
                    || this.keyToKeyCodeMappingFallback['modifier']['uppercase'];
            } else if (key.length === 1) {
                keyModifier = this.keyToKeyCodeMapping['modifier'][keyEncoded]
                        || this.keyToKeyCodeMappingFallback['modifier'][keyEncoded];

                if (!keyModifier && (platform.checks.isAndroid || platform.checks.isIOS)) {
                    keyModifier = this.keyToKeyCodeMapping['modifier_mobile'][keyEncoded];
                }
            } else {
                keyModifier = this.keyToKeyCodeMapping['modifier'][key.toLowerCase()]
                        || this.keyToKeyCodeMappingFallback['modifier'][key.toLowerCase()];
            }

            if (window.CYNAP_ENV === 'dev') {
                console.log('getKeyEventModifier KEY ' + key + ' to ' + keyEncoded + ' | send KeyModifier ' + keyModifier);
            }

            if (keyModifier) {
                return {
                    event: 'keyboard',
                    frameBoxId: frameBoxId,
                    keyState: keyState,
                    key: 0,
                    keyCode: keyModifier,
                    shift: 0,
                    alt: 0,
                    ctrl: 0
                };
            }

            return undefined;
        },

        /**
         * Check if key is uppercase.
         *
         * @param key
         * @returns {boolean}
         */
        isUpperCase: function(key) {
            return key.length === 1 && key === key.toUpperCase() && key !== key.toLowerCase();
        },

        /**
         * Encode character (Character to HTML code).
         *
         * @param char
         * @returns {string}
         */
        encodeChar: function(char) {
            var buf = [];

            for (var i = char.length - 1; i >= 0; i--) {
                buf.unshift(['&#', char[i].charCodeAt(), ';'].join(''));
            }

            return buf.join('');
        },

        /**
         * Decode character (HTML code to readable character)
         * @param char
         * @returns {*}
         */
        decodeChar: function(char) {
            return char.replace(/&#(\d+);/g, function(match, dec) {
                return String.fromCharCode(dec);
            });
        },

        /**
         * Force framebox update.
         * This method will add the forceUpdate param to the framebox object.
         *
         * @param {Object} framebox
         * @return {Object}
         */
        forceUpdate: function(framebox) {
            if (!framebox) {
                return framebox;
            }

            if (this.forceFrameboxUpdate[framebox.index]) {
                framebox.forceUpdate = true;
            }

            this.forceFrameboxUpdate[framebox.index] = false;

            return framebox;
        },

        /**
         * Returns true when ui is visible.
         */
        isUiVisible: function() {
            return uiStates.visible === this.uiState.getState();
        },

        /**
         * Map framebox component to component type.
         *
         * @param frameBoxComponent
         * @returns componentType
         */
        contentTypeMapping: function(frameBoxComponent) {
            return componentMapping[frameBoxComponent];
        },

        /**
         * Set box which is curerntly in fullscreen (= selected tab)
         * @param box
         */
        setFullscreenBox: function(box) {
            this.fullscreenBox = box;
        },

        /**
         * Get box which is curerntly in fullscreen (= selected tab).
         */
        getFullscreenBox: function() {
            return this.fullscreenBox;
        },

        /**
         * Return framebox nearest to bottom.
         *
         * @returns {int} top position including framebox height
         */
        getBottomFrameboxPosition: function() {
            var top = 0;
            _.each($(document).find('.framebox'), function(framebox) {
                if (($(framebox).position().top + $(framebox).height()) > top) {
                    top = $(framebox).position().top + $(framebox).height();
                }
            }.bind(this));

            return top + this.liveStream.getOffset().top;
        }
    };
});
