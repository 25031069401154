'use strict';

const app = require('../../../app');
const vueUtils = require('../../../components-vue/util');
const collabUsers = require('./collaboration-users.html');
const Vuex = require('vuex');

app.component('CollaborationUsers', {
    engine: 'vue',
    template: collabUsers,
    components: {
        'collab-user': require('./collab-user/collab-user.js')
    },
    className: 'collaboration-users-modal-container',

    getAccessKey: function() {
        return {
            'roleName': 'ControlScreen',
            'roleKey': 'show'
        };
    },

    data: function() {
        return {
            unsortedUsers: [],
            currentUser: {},
            showPin: false,
            pin: 0
        };
    },

    computed: {
        planeIndex: function() {
            return this.annotationService.getPlaneIndex();
        },

        /**
         * Sort users so current user is listed first.
         */
        users: function() {
            return this.unsortedUsers.sort(function(a, b) {
                return a.clientId === this.currentUser?.clientId ? -1 : b.clientId === this.currentUser?.clientId ? 1 : 0;
            }.bind(this));
        },

        isMatrixAnnotation: function() {
            return this.annotationService.isMatrixMasterAnnotation();
        },

        ...Vuex.mapGetters('controlScreen', ['isEditModeActive'])
    },

    watch: {
        isEditModeActive: function(val) {
            if (!val) {
                this.close();
            }
        }
    },

    methods: {
        toggleVisible: function(clientId, visible) {
            this.wolfprot.talk('setAnnotationUser', {
                clientId: clientId,
                planeIndex: this.planeIndex,
                visible: visible
            });

            app.emit('main-loop.fast.start', {
                id: 'annotation-collaboration'
            });
        },

        removeUser: function(clientId) {
            this.wolfprot.talkMulti([
                {
                    command: 'setAnnotationAction',
                    data: {
                        action: 'clean',
                        planeIndex: this.planeIndex,
                        clientId: clientId
                    }
                },
                {
                    command: 'setAnnotationAction',
                    data: {
                        action: 'disconnect',
                        planeIndex: this.planeIndex,
                        clientId: clientId
                    }
                }
            ]);

            app.emit('main-loop.fast.start', {
                id: 'annotation-collaboration'
            });
        },

        togglePinState: function(evt) {
            this.wolfprot.talk('setAnnotationPin', {
                collaboration: evt.target.checked
            });

            app.emit('main-loop.fast.start', {
                id: 'annotation-collaboration'
            });
        },

        close: function() {
            this.component.emit('modal.close', {
                id: 'collaboration-users'
            });
        },

        i18n: vueUtils.i18n
    },

    created: function() {
        this.annotationService = app.getService('AnnotationService');

        const { currentUser, users, pinState } = this.component.options.configs;
        this.currentUser = currentUser;
        this.unsortedUsers = users;
        this.showPin = pinState.showPin;
        this.pin = pinState.pin;

        this.wolfprot = vueUtils.wolfprot();
        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return this.wolfprot.talkMulti([
                    {
                        command: 'getAnnotationUsers',
                        data: {
                            planeIndex: this.planeIndex,
                            allUsers: true
                        }
                    },
                    'getAnnotationPinStatus'
                ]).then(function([users, pinState]) {
                    return {
                        users,
                        pinState
                    };
                }.bind(this));
            }.bind(this)
        });
        this.pollHelper.on('data', function({ users, pinState }) {
            this.unsortedUsers = users.userList;
            this.showPin = pinState.showPin;
            this.pin = pinState.pin;
        }.bind(this));
        this.pollHelper.schedulePoll();

        this.evctx = vueUtils.eventContext();
        this.evctx.on('main-loop.update', function() {
            this.pollHelper.schedulePoll();
        }.bind(this));
        this.evctx.on('main-loop.update.annotation-collaboration', function() {
            this.pollHelper.schedulePoll();
        }.bind(this));
    }
});
