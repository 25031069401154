// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "secundary";
},"3":function(container,depth0,helpers,partials,data) {
    return "row";
},"5":function(container,depth0,helpers,partials,data) {
    return "col-xs-3";
},"7":function(container,depth0,helpers,partials,data) {
    return "col-xs-6";
},"9":function(container,depth0,helpers,partials,data) {
    return "form-primary-background";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"peripheral-settings\" data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":45},"end":{"line":1,"column":53}}}) : helper)))
    + "\">\n    <form class=\"peripheral-item-form\" name=\"peripheral-settings\" data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":78},"end":{"line":2,"column":86}}}) : helper)))
    + "\">\n        <div class=\"row settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if_even","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":38},"end":{"line":3,"column":85}}})) != null ? stack1 : "")
    + "\"\n             data-nav-area=\".is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n             data-nav-trapped>\n            <div class=\"col-xs-8\">\n                <h2>"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.command_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":51}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input id=\"peripheral-commandEnabled-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":11,"column":57},"end":{"line":11,"column":65}}}) : helper)))
    + "\"\n                           name=\"commandEnabled\"\n                           type=\"checkbox\"\n                           class=\"btn-switch\"  />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"row settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if_even","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":21,"column":38},"end":{"line":21,"column":85}}})) != null ? stack1 : "")
    + "\"\n             data-nav-area=\".is-focusable:not(:disabled), .input:not([disabled]), .btn-switch\"\n             data-nav-trapped>\n            <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"useNameEvent") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":24,"column":24},"end":{"line":24,"column":75}}})) != null ? stack1 : "")
    + "\">\n                <div class=\"event-select\"></div>\n            </div>\n            <div id=\"use-name-event-container-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":27,"column":46},"end":{"line":27,"column":54}}}) : helper)))
    + "\" class=\"use-name-event-container col-xs-3 switch-below-text\">\n                <label class=\"input-label use-name-event\" for=\"useNameEvent\"></label>\n                <div class=\"btn-switch-container\">\n                    <input id=\"useNameEvent-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":30,"column":44},"end":{"line":30,"column":52}}}) : helper)))
    + "\"\n                           name=\"useNameEvent\"\n                           type=\"checkbox\"\n                           class=\"btn-switch\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"col-xs-6\">\n                <div class=\"peripheral-delay input-group\">\n                    <label class=\"input-label\" for=\"delay\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.peripheral_delay",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":59},"end":{"line":41,"column":92}}}))
    + "</label>\n                    <input id=\"peripheral-delay-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":42,"column":48},"end":{"line":42,"column":56}}}) : helper)))
    + "\"\n                           type=\"text\"\n                           class=\"input is-focusable\"\n                           name=\"peripheralDelay\"\n                           data-from=\"0\"\n                           data-to=\"120\"\n                           data-validation=\"checkNumberFromTo\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">\n                            "
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.wrong_value_between_0_120_seconds",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":28},"end":{"line":51,"column":94}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div id=\"peripheral-content-container-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":56,"column":46},"end":{"line":56,"column":52}}}) : helper)))
    + "\" class=\"peripheral-content-container\">\n            <div class=\"row settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if_even","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":57,"column":42},"end":{"line":57,"column":89}}})) != null ? stack1 : "")
    + "\"\n                 data-nav-area=\".is-focusable:not(:disabled)\"\n                 data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <div class=\"input-group\">\n                        <label class=\"input-label\" for=\"name\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":62},"end":{"line":62,"column":83}}}))
    + "</label>\n                        <input id=\"peripheral-name-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":63,"column":51},"end":{"line":63,"column":59}}}) : helper)))
    + "\"\n                               type=\"text\"\n                               class=\"input is-focusable\"\n                               name=\"name\"\n                               data-validation=\"checkLength\"\n                               data-max-length=\"255\" />\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":75},"end":{"line":70,"column":107}}}))
    + "</span>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"col-xs-6\">\n                    <div class=\"input-group\">\n                        <label class=\"input-label\" for=\"description\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":76,"column":69},"end":{"line":76,"column":97}}}))
    + "</label>\n                        <input id=\"peripheral-description-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":77,"column":58},"end":{"line":77,"column":66}}}) : helper)))
    + "\"\n                               type=\"text\"\n                               class=\"input is-focusable\"\n                               name=\"description\"\n                               placeholder=\""
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":81,"column":44},"end":{"line":81,"column":73}}}))
    + "\"\n                               data-validation=\"checkLength\"\n                               data-max-length=\"255\" />\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":75},"end":{"line":85,"column":107}}}))
    + "</span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if_even","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":90,"column":42},"end":{"line":90,"column":89}}})) != null ? stack1 : "")
    + "\"\n                 data-nav-area=\".is-focusable:not(:disabled)\"\n                 data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <div class=\"prot-select\"></div>\n                </div>\n                <div class=\"col-xs-6\">\n                    <div class=\"cmd-select is-hidden\"></div>\n                </div>\n            </div>\n            <div class=\"specific-event-content\">\n                <div class=\"row settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if_even","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":101,"column":46},"end":{"line":101,"column":93}}})) != null ? stack1 : "")
    + "\"\n                     data-nav-area=\".is-focusable:not(:disabled)\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"ip\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ip_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":106,"column":64},"end":{"line":106,"column":91}}}))
    + "</label>\n                            <input type=\"text\"\n                                   id=\"peripheral-ip-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":108,"column":53},"end":{"line":108,"column":61}}}) : helper)))
    + "\"\n                                   class=\"input is-focusable\"\n                                   name=\"ip\"\n                                   data-validation=\"checkIPv4\" />\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_ip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":113,"column":79},"end":{"line":113,"column":105}}}))
    + "</span>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"port\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.port",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":119,"column":66},"end":{"line":119,"column":87}}}))
    + "</label>\n                            <input id=\"peripheral-port-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":120,"column":55},"end":{"line":120,"column":63}}}) : helper)))
    + "\"\n                                   type=\"text\"\n                                   class=\"input is-focusable\"\n                                   name=\"port\"\n                                   placeholder=\""
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.port",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":124,"column":48},"end":{"line":124,"column":70}}}))
    + "\"\n                                   data-validation=\"checkPort\" />\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_port",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":127,"column":79},"end":{"line":127,"column":107}}}))
    + "</span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"row settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if_even","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":132,"column":46},"end":{"line":132,"column":93}}})) != null ? stack1 : "")
    + " js-hexCmd-field\"\n                     data-nav-area=\".is-focusable:not(:disabled)\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"hexCmd\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.hex_cmd",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":137,"column":68},"end":{"line":137,"column":92}}}))
    + "</label>\n                            <input id=\"peripheral-hexCmd-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":138,"column":57},"end":{"line":138,"column":65}}}) : helper)))
    + "\"\n                                   type=\"text\"\n                                   class=\"input is-focusable\"\n                                   name=\"hexCmd\"\n                                   data-validation=\"checkPeripheralCmd\"\n                                   data-max-length=\"254\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_hex_cmd",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":145,"column":79},"end":{"line":145,"column":110}}}))
    + "</span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if_even","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":151,"column":46},"end":{"line":151,"column":93}}})) != null ? stack1 : "")
    + " js-cmd-field\"\n                     data-nav-area=\".is-focusable:not(:disabled)\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-6 js-cmd-field\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"cmd\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.cmd",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":156,"column":65},"end":{"line":156,"column":85}}}))
    + "</label>\n                            <input id=\"peripheral-cmd-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":157,"column":54},"end":{"line":157,"column":62}}}) : helper)))
    + "\"\n                                   type=\"text\"\n                                   class=\"input is-focusable\"\n                                   name=\"cmd\" />\n                        </div>\n                    </div>\n\n                    <div class=\"col-xs-6 js-password-field\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"password\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":166,"column":70},"end":{"line":166,"column":95}}}))
    + "</label>\n                            <input id=\"peripheral-password-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":167,"column":59},"end":{"line":167,"column":67}}}) : helper)))
    + "\"\n                                   type=\"text\"\n                                   class=\"input is-focusable\"\n                                   name=\"password\"\n                                   data-validation=\"checkLength\"\n                                   data-max-length=\"32\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">\n                                    "
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.wrong_password_max_length_32",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":175,"column":36},"end":{"line":175,"column":97}}}))
    + "\n                                </span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if_even","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":182,"column":42},"end":{"line":182,"column":89}}})) != null ? stack1 : "")
    + "\"\n                 data-nav-area=\"btn-switch, .input, select, .is-focusable:not(:disabled)\"\n                 data-nav-trapped>\n                <div class=\"peripheral-command-test-action\">\n                    <input type=\"button\"\n                           name=\"test-command\"\n                           class=\"btn-form form-action-cancel is-focusable js-test-command\"\n                           data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":189,"column":39},"end":{"line":189,"column":47}}}) : helper)))
    + "\"\n                           value=\""
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.test_it_now",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":190,"column":34},"end":{"line":190,"column":62}}}))
    + "\">\n                </div>\n                <div class=\"peripheral-command-test-action\">\n                    <div class=\"loader small hidden\"></div>\n                </div>\n            </div>\n        </div>\n    </form>\n\n    <div class=\"js-form-action-container "
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if_even","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":199,"column":41},"end":{"line":199,"column":91}}})) != null ? stack1 : "")
    + "\" data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":199,"column":105},"end":{"line":199,"column":113}}}) : helper)))
    + "\"></div>\n</div>\n";
},"useData":true});
