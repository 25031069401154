const template = require('./streaming-controls.html');
const vueUtils = require('../../util.js');
const app = require('../../../app');
const platform = require('../../../../platform/platform');

module.exports = {
    template,
    props: {
        options: {
            type: Object
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data: function() {
        return {
            streamingEnabled: false,
            qrCodeVisible: false,
            webcastState: 'off',
            hasStreaming: true,
            hasWebcast: true
        };
    },

    computed: {
        webcastIsRunning: function() {
            return this.webcastState === 'on' || this.webcastState === 'stopping';
        }
    },

    methods: {
        /**
         * Execute Wolfprot command to change streaming mode.
         * @param mode mode to change to
         */
        changeStreamingModeTo: function(mode) {
            this.pollHelper.sync(function() {
                return this.wolfprot.talk('setStreamingMode', {
                    mode
                }).then(function() {
                    app.emit('main-loop.fast.start', {
                        id: 'streaming'
                    });
                });
            }.bind(this));
        },

        /**
         * Callback when QR code was clicked. Close control center.
         */
        toggleQrCode: function() {
            if (!this.streamingEnabled) {
                return;
            }

            if (!this.deviceService.isCboxProDualProjection() && platform.checks.isRemote && !this.qrCodeVisible) {
                // On remote browser, the QR code modal does not open automatically
                this.streamQrCodeService.openModal();
            }

            this.pollHelper.sync(function() {
                return this.wolfprot.talk('setQrCodeVisible', {
                    visible: !this.qrCodeVisible
                });
            }.bind(this));
        },

        /**
         * Execute Wolfprot command to toggle Webcast.
         */
        toggleWebcast: function() {
            this.pollHelper.sync(function() {
                var mode = this.webcastState === 'off';

                if (this.webcastState === 'starting' || this.webcastState === 'stopping') {
                    mode = false;
                }

                return this.wolfprot.talk('setWebcastStreamingMode', {
                    mode: mode
                }).then(function() {
                    app.emit('main-loop.fast.start', {
                        id: 'webcasting'
                    });
                });
            }.bind(this));
        },

        i18n: vueUtils.i18n
    },

    created: function() {
        this.matrixService = app.getService('MatrixService');
        this.streamingService = app.getService('Streaming');
        this.webcastingService = app.getService('WebcastingService');
        this.deviceService = app.getService('DeviceService');
        this.streamQrCodeService = app.getService('StreamQrCodeService');

        this.wolfprot = vueUtils.wolfprot();
        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return this.wolfprot.talkMulti([
                    'getStreamingMode',
                    'getWebcastStreamingMode',
                    'getWebcastMode',
                    'getWebcastYoutubeStreamState',
                    'getQrCode'
                ]).then(function([streamingMode, webcastStreamingMode, webcastMode, youtubeStreamState, qrCode]) {
                    return {
                        streamingMode,
                        webcastStreamingMode,
                        webcastMode,
                        youtubeStreamState,
                        qrCode
                    };
                });
            }.bind(this)
        });
        this.pollHelper.on('data', function({ streamingMode, webcastStreamingMode, webcastMode, youtubeStreamState, qrCode }) {
            this.streamingEnabled = streamingMode.mode === 1;
            this.qrCodeVisible = qrCode.visible;

            if (webcastMode.mode === 'youtube') {
                if (youtubeStreamState.state === 'live-starting') {
                    this.webcastState = 'starting';
                } else if (youtubeStreamState.state === 'live' && !webcastStreamingMode.mode) {
                    this.webcastState = 'stopping';
                } else if (youtubeStreamState.state === 'live') {
                    this.webcastState = 'on';
                } else {
                    this.webcastState = 'off';
                }
            } else {
                this.webcastState = webcastStreamingMode.mode ? 'on' : 'off';
            }

            let hasWebcast;
            let hasStreaming;

            if (this.deviceService.isCbox()) {
                if (!this.streamingService.isEnabled()) { // Streaming is disabled (globally)
                    hasStreaming = false;
                    hasWebcast = false;
                } else if (this.matrixService.isEnabled()) { // Streaming is enabled (globally) && Matrix is enabled
                    hasStreaming = false;
                    hasWebcast = this.webcastingService.isEnabled();
                } else { // Streaming is enabled (globally) && Matrix is disabled
                    hasStreaming = this.streamingService.isStreamEnabled();
                    hasWebcast = this.webcastingService.isEnabled();
                }
            } else if (this.deviceService.isCboxCorePro() && this.deviceService.getFeatures().capture) {
                hasStreaming = false;
                hasWebcast = this.webcastingService.isEnabled();
            }

            this.hasStreaming = hasStreaming;
            this.hasWebcast = hasWebcast;
        }.bind(this));
        this.pollHelper.schedulePoll();

        this.evctx = vueUtils.eventContext();
        this.evctx.on('main-loop.update', function() {
            this.pollHelper.schedulePoll();
        }.bind(this));
    },

    destroyed: function() {
        this.evctx.close();
    },

    components: {
        'boxed-control': require('../boxed-control/boxed-control.js'),
        'command-button': require('../command-button/command-button.js'),
        'slider': require('../../slider/slider.js')
    }
};
