'use strict';

const app = require('../../../app');
const zoomTpl = require('./zoom-settings.hbs');
const FormActionView = require('../form-action-view');
const loginStates = require('./../../../states').zoomLoginStates;

/**
 * Zoom Settings
 */
app.component('ZoomSettings', {
    template: zoomTpl,

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.deviceService = this.getService('DeviceService');
        this.zoomService = this.getService('ZoomService');
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.initForm();
        this.initFormAction();
        this.loadSettings()
            .then(function() {
                this.bindEvents();
                this.bindDOMEvents();
            }.bind(this));
    },

    serialize: function() {
        return {
            supportsRecording: this.deviceService.isCbox()
                || (this.deviceService.isCboxCorePro && this.deviceService.getFeatures().capture),
            isCbox: this.deviceService.isCbox(),
            isDualProjection: this.deviceService.isCboxProDualProjection()
        };
    },

    storeSelectors: function() {
        this.$settingsContainer = this.$el.find('#zoom-settings-container');
        this.$localMeetingSettingsContainer = this.$el.find('#zoom-local-container');
        this.$localLoginState = this.$el.find('#zoom-login-state');
        this.$localUsername = this.$el.find('#zoom-local-username');
        this.$localEmail = this.$el.find('#zoom-local-email');
        this.$localMeetingId = this.$el.find('#zoom-local-meeting-id');
        this.$loginBtnContainer = this.$el.find('#zoom-login-btn-container');
        this.$loginBtn = this.$el.find('.zoom-login');
        this.$logoutBtnContainer = this.$el.find('#zoom-logout-btn-container');
        this.$logoutBtn = this.$el.find('.zoom-logout');
        this.$localMeetingInfos = this.$el.find('.zoom-local-meeting-infos');
    },

    bindEvents: function() {
        this.on('zoom.update.status', this.updateZoomStatusAndInfo.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
    },

    bindDOMEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));

        this.$loginBtn.on('click', this.login.bind(this));
        this.$logoutBtn.on('click', this.logout.bind(this));
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#zoom-settings'),
            validationContainer: '.input-group'
        });
    },

    handleFormChange: function(data) {
        if (data.input.name === 'zoomEnabled') {
            this.handleZoomEnableChange();
        } else if (data.input.name === 'enableLocalMeeting') {
            this.handleLocalMeetingChange();
        }

        this.actionView.open();
        this.changes = true;
    },

    saveHandler: function() {
        let dfd = this.$.Deferred();

        if (this.form.validate()) {
            this.deviceConnection
                .send('setZoomSettings', {
                    enabled: this.form.get('zoomEnabled').getValue(),
                    enableContent: this.form.get('enableContent').getValue(),
                    enableApp: this.form.get('enableApp').getValue(),
                    enableJoinMeeting: this.form.get('enableJoinMeeting').getValue(),
                    enableLocalMeeting: this.form.get('enableLocalMeeting').getValue()
                }).then(function() {
                    dfd.resolve();
                    this.handleSettingsSave();
                    this.form.setDefaultValues();
                    this.emit('overlay.remote.focus', true);

                    this.changes = false;
                }.bind(this), function() {
                    dfd.reject();
                }.bind(this));
        } else {
            dfd.resolve();
        }

        return dfd.promise();
    },

    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
        this.handleZoomEnableChange();

        this.changes = false;
    },

    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.changes = false;
    },

    /**
     * Load settings from backend.
     */
    loadSettings: function() {
        return this.deviceConnection
            .send([
                'getZoomSettings',
                'getZoomLocalAccountStatus',
                'getZoomLocalAccountInfo'
            ])
            .then(function(settings, status, info) {
                this.formData = {
                    'zoomEnabled': settings.enabled,
                    'enableContent': settings.enableContent,
                    'enableApp': settings.enableApp,
                    'enableJoinMeeting': settings.enableJoinMeeting,
                    'enableLocalMeeting': settings.enableLocalMeeting
                };

                this.form.setValues(this.formData);
                this.form.setDefaultValues();
                this.handleZoomEnableChange();
                this.handleLocalMeetingChange();
                this.updateZoomStatusAndInfo(status, info);
            }.bind(this));
    },

    /**
     * Handle Zoom Enable change. Show or hide settings.
     */
    handleZoomEnableChange: function() {
        if (this.form.get('zoomEnabled').getValue()) {
            this.$settingsContainer.removeClass('hidden');
        } else {
            this.$settingsContainer.addClass('hidden');
        }
    },

    /**
     * Handle Zoom local meeting enable change. Show or hide settings.
     */
    handleLocalMeetingChange: function() {
        if (this.form.get('enableLocalMeeting').getValue()) {
            this.$localLoginState.show();
            this.$localMeetingSettingsContainer.removeClass('hidden');
        } else {
            this.$localLoginState.hide();
            this.$localMeetingSettingsContainer.addClass('hidden');
        }
    },

    /**
     * Click on login button and start logout process.
     */
    login: function() {
        this.handleUnsavedChanges()
            .then(function() {
                this.emit('zoom-local-account.login', {
                    login: true
                });
                this.deviceConnection
                    .send('setZoomLocalAccountLogin', {
                        login: true
                    })
                    .then(function() {
                        this.emit('overlay.close');
                    }.bind(this));
            }.bind(this));
    },

    /**
     * Click on logout button and start logout process.
     */
    logout: function() {
        this.emit('overlay.remote.focus', true);

        this.deviceConnection
            .send('setZoomLocalAccountLogin', {
                login: false
            })
            .then(function() {
                // Immediately disable logout button while logout is in progress
                if (this.zoomService.loginState.currentState !== loginStates.loggedOut) {
                    this.$logoutBtn.prop('disabled', true);
                }
            }.bind(this));
    },

    handleUnsavedChanges: function() {
        let dfd = this.$.Deferred();

        if (this.changes) {
            this.emit('modal.open', {
                id: 'settings-save-changes',
                invalid: this.hasChanges().invalid,
                onSave: function() {
                    this.saveHandler()
                        .then(() => dfd.resolve());
                }.bind(this),
                onDiscard: function() {
                    this.cancelHandler();
                    this.actionView.close();
                    dfd.reject();
                }.bind(this),
                onDestroy: function() {
                    if (dfd.state() !== 'resolved') {
                        dfd.reject();
                    }
                }.bind(this)
            });
        } else {
            dfd.resolve();
        }

        return dfd.promise();
    },

    /**
     * Update Zoom local account status and infos.
     *
     * @param status.status (loggedOut, disconnected, connected)
     * @param info Account information (name, email, meeting ID)
     */
    updateZoomStatusAndInfo: function(status, info) {
        this.$localLoginState.attr('data-zoom-login-state', status.status);

        switch (status.status) {
            case loginStates.undefined:
            case loginStates.loggedOut:
                this.$loginBtn.prop('disabled', false);
                this.$logoutBtn.prop('disabled', true);
                this.$loginBtnContainer.show();
                this.$logoutBtnContainer.hide();

                this.$localMeetingInfos.hide();
                break;
            case loginStates.connected:
                this.$loginBtn.prop('disabled', true);
                this.$logoutBtn.prop('disabled', false);
                this.$loginBtnContainer.hide();
                this.$logoutBtnContainer.show();

                this.$localUsername.text(info.name);
                this.$localEmail.text(info.email);
                this.$localMeetingId.text(info.meetingId);
                this.$localMeetingInfos.show();
                break;
            case loginStates.disconnected:
                this.$loginBtn.prop('disabled', true);
                this.$logoutBtn.prop('disabled', false);
                this.$loginBtnContainer.hide();
                this.$logoutBtnContainer.show();

                this.$localUsername.text(info.name);
                this.$localEmail.text(info.email);
                this.$localMeetingId.text(info.meetingId);
                this.$localMeetingInfos.show();
                break;
        }
    },

    destroy: function() {
        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    hasChanges: function() {
        var changes =  {
            hasChanges: this.changes,
            invalid: false
        };

        return changes;
    }
});
