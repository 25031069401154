'use strict';

var app = require('../app');

// Special keys: carriage return, backspace, tab-horizontal.
var SPECIAL_KEYS = [13, 8, 9];
var ARROW_KEY_CODES = [37, 38, 39, 40];

app.service('KeyEventHandlerService', function(app) {
    return {
        initialize: function() {
            this.remote = app.getService('RemoteService');
        },

        /**
         * @param {Object} event
         *
         * @return {Number|undefined}
         */
        getKeyCodeForPressHandler: function(event) {
            var ignoreKeyCode = [
                this.remote.KEYCODES.BACKSPACE
            ];

            var keyCode = event.which;

            if (ignoreKeyCode.indexOf(keyCode) === -1) {
                return keyCode;
            }
        },

        /**
         * @param {Object} event
         *
         * @return {Number|undefined}
         */
        getKeyCodeForKeyDownHandler: function(event) {
            var keyCode = event.which;

            // 1. Check if one of the defined special keys is pressed
            // 2. Check if ctrl key is pressed.
            // 3. Check if shift and one of the arrow-keys is pressed.
            if (SPECIAL_KEYS.indexOf(keyCode) !== -1
                || event.ctrlKey === true
                || (ARROW_KEY_CODES.indexOf(keyCode) !== -1
                    && (event.isArrowKey || typeof event.isArrowKey === 'undefined')
                )
            ) {
                return keyCode;
            }
        }
    };
});
