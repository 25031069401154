'use strict';

var _ = require('lodash');
var $ = require('jquery');
var i18n = require('i18next');
var app = require('../../../app');
var sprintf = require('./../../../helper').sprintf;
var peripheralTpl = require('./peripheral-settings.hbs');
var commandListItemTpl = require('./peripheral-command-item.hbs');
var FormActionView = require('../form-action-view');

app.component('PeripheralSettings', {
    template: peripheralTpl,
    init: false,

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.deviceService = this.getService('DeviceService');
        this.peripheralService = this.getService('PeripheralService');
        this.frontendSettings = this.getService('FrontendSettings');
        this.remote = this.getService('RemoteService');
        this.form = [];
        this.indexExists = false;
        this.actionView = [];
    },

    storeSelectors: function() {
        this.$commandList = this.$el.find('#peripheral-command-list-items');
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();
    },

    /**
     * Create commandlist from template.
     */
    renderCommandList: function() {
        this.emit('component.loader.change', {
            state: 'show'
        });

        this.frontendSettings.getSettings([
            'customCommandButtons'
        ]).then(function(customCommandButtons) {
            let defaultButtons = Object.assign({}, this.frontendSettings.getDefaultSetting('customCommandButtons'));
            this.customCommandButtons = Object.assign(defaultButtons, customCommandButtons);

            this.peripheralService
                .getAllCommandsFull()
                .forEach(function(item) {
                    var formData = [];
                    var $item = this.$(commandListItemTpl(item));
                    var $itemInputLabel = $item.find('#use-name-event-container-' + item.id).find('.input-label');

                    $itemInputLabel.html(sprintf(i18n.t('settings.use_name_event'), [item.name]));
                    item.useNameEvent = false;

                    _.each(this.customCommandButtons, function(btn) {
                        if (btn.commandIndex === item.id && btn.name === item.name) {
                            item.useNameEvent = true;
                        }
                    }.bind(this));

                    this.createSelects($item, item.id);
                    formData.event = item.event;
                    formData.ip = item.ip;
                    formData.name = item.name;
                    formData.description = item.description;
                    formData.prot = item.prot;
                    formData.wolfprot = item.wolfprot;
                    formData.hexCmd = item.cmd;
                    formData.cmd = item.cmd;
                    formData.commandEnabled = item.enabled;
                    formData.port = item.port;
                    formData.password = item.password;
                    formData.useNameEvent = item.useNameEvent;
                    formData.peripheralDelay = item.delay;

                    if (item.event === 'none') {
                        $item.find('#peripheral-content-container-' + item.id).hide();
                    } else {
                        $item.find('#peripheral-content-container-' + item.id).show();
                        this.handleWolfprotEvent(item.event === 'wolfprot', $item);
                    }

                    this.$commandList.append($item);

                    this.showHideDelay($item, item.event);
                    this.initForm(item.id);
                    this.updateForm(formData, item.id);
                    this.initFormAction($item, item.id);
                    this.checkForEnabledItems(item.id);

                    this.form[item.id].setDefaultValues(formData);
                }.bind(this));

            this.emit('component.loader.change', {
                state: 'hide'
            });
        }.bind(this));
    },

    /**
     * Switch fields when protocol has been changed.
     *
     * @param index
     */
    switchItemProtocol: function(protocol, index) {
        switch (protocol) {
            case 'PJLink':
                this.enablePJLinkProtocolFields(index);
                break;
            default:
                this.enableDefaultProtocolFields(index);
        }
    },

    /**
     * Show fields for PJLink protocol.
     *
     * @param index
     */
    enablePJLinkProtocolFields: function(index) {
        var $form = this.$el.find('.peripheral-settings[data-index="' + index + '"]');

        this.form[index].get('hexCmd').disable();
        $form.find('.js-hexCmd-field').hide();

        this.form[index].get('cmd').enable();
        $form.find('.js-cmd-field').show();

        this.form[index].get('password').enable();
        $form.find('.js-password-field').show();
    },

    /**
     * Show fields for TCP and UDP protocol.
     *
     * @param index
     */
    enableDefaultProtocolFields: function(index) {
        var $form = this.$el.find('.peripheral-settings[data-index="' + index + '"]');

        this.form[index].get('hexCmd').enable();
        $form.find('.js-hexCmd-field').show();

        this.form[index].get('cmd').disable();
        $form.find('.js-cmd-field').hide();

        this.form[index].get('password').disable();
        $form.find('.js-password-field').hide();
    },

    bindEvents: function() {
        this.on('settings.save-changes', this.saveAllHandler.bind(this));
        this.on('overlay.ready', this.preparePeripheralCommands.bind(this));
    },

    preparePeripheralCommands: function() {
        this.peripheralService
            .updateCommandsFull()
            .then(this.renderRmsCommands.bind(this));
    },

    initFormAction: function($item, index) {
        if (!this.actionView[index]) {
            this.actionView[index] = new FormActionView(app, {
                id: index,
                selector: $item.find('.js-form-action-container'),
                onSubmit: this.saveHandler.bind(this, index),
                onCancel: this.cancelHandler.bind(this, index)
            });
        }

        this.actionView[index].render();
    },

    initForm: function(index) {
        this.form[index] = this.formManager.create({
            el: this.$el.find('.peripheral-settings[data-index="' + index + '"] .peripheral-item-form'),
            validationContainer: '.input-group'
        });
        this.form[index].on('change.input', this.handleFormChange.bind(this));
        this.form[index].$el.on('click', '.js-test-command:not(.is-disabled)', this.testCommand.bind(this));
    },

    /**
     * On Testbutton click.
     */
    testCommand: function(data) {
        var $el = $(data.currentTarget.form);
        var index = $el.data('index');

        this.deviceConnection
            .send([
                {
                    command: 'setRmsClientTest',
                    data: {
                        id: index
                    }
                }
            ]);

        app.emit('main-loop.fast.start', {
            id: 'peripheral'
        });

        $el.find('.loader').removeClass('hidden');

        // RELEASE-3398
        setTimeout(function() {
            $el.find('.loader').addClass('hidden');
        }.bind(this), 3000);
    },

    /**
     * Save all changed network drives
     */
    saveAllHandler: function() {
        _.each(this.actionView, function(view, index) {
            if (view.state.getState() === 'visible') {
                this.saveHandler(index);
            }
        }.bind(this));
    },

    /**
     * Handle rms cmd save.
     */
    saveHandler: function(index) {
        var data;
        var ccBtn;
        var ccBtnIndex;

        if (this.form[index].get('prot').getValue() === 'wolfprot' || this.form[index].validate()) {
            data = this.form[index].serialize();

            this.deviceConnection
                .send([
                    {
                        command: 'setRmsClient',
                        data: {
                            id: index,
                            event: data.event,
                            name: data.name,
                            description: data.description,
                            prot: data.prot,
                            wolfprot: data.wolfprot,
                            ip: data.ip,
                            cmd: this.getCmdValueByProtocol(data),
                            enabled: data.commandEnabled,
                            port: data.port,
                            password: data.password,
                            peripheralDelay: data.peripheralDelay
                        }
                    }
                ])
                .done(function() {
                    this.handleSettingsSave(index);
                    this.$el.find('#peripheral-content-container-' + index).show();

                    if (data.event === 'none') {
                        this.$el.find('#peripheral-content-container-' + index).hide();
                    }

                    this.showHideDelay(this.form[index].$el, data.event);

                    ccBtn = this.customCommandButtons[data.event];
                    ccBtnIndex = ccBtn ? ccBtn.commandIndex : -1;

                    if (data.event.indexOf('button') >= 0 && data.useNameEvent) {
                        if (ccBtnIndex >= 0 && ccBtnIndex !== index) {
                            this.form[ccBtnIndex].get('useNameEvent').setValue(false);
                            this.form[ccBtnIndex].get('useNameEvent').setDefaultValue(false);
                        }

                        this.customCommandButtons[data.event] = {
                            name: data.name
                                || this.frontendSettings.getDefaultSetting('customCommandButtons')[data.event].name,
                            commandIndex: index
                        };
                    } else if (!data.useNameEvent || data.event.indexOf('button') < 0) {
                        // Is custom command button without 'use name as'
                        if (ccBtn && index === ccBtnIndex) {
                            this.customCommandButtons[data.event]
                                = this.frontendSettings.getDefaultSetting('customCommandButtons')[data.event];
                        } else {
                            // Not a custom command button anymore.
                            _.each(this.customCommandButtons, function(btn, i) {
                                if (btn.commandIndex === index) {
                                    this.customCommandButtons[i]
                                        = this.frontendSettings.getDefaultSetting('customCommandButtons')[i];
                                }
                            }.bind(this));
                        }
                    }

                    this.frontendSettings.updateSetting({
                        tag: 'customCommandButtons',
                        value: this.customCommandButtons
                    });

                    this.frontendSettings.saveSettings();

                    this.form[index].$el.find('.peripheral-command-test-action').show();

                    this.emit('overlay.remote.focus', true);

                    app.emit('main-loop.fast.start', {
                        id: 'peripheral'
                    });
                }.bind(this));
        }
    },

    /**
     * Returns the value from the correct cmd field.
     * @param data
     * @return {*}
     */
    getCmdValueByProtocol: function(data) {
        if ('PJLink' === data.prot) {
            return data.cmd;
        }

        return data.hexCmd;
    },

    /**
     * Calls on clicked the cancel button
     * @method cancelHandler
     */
    cancelHandler: function(index) {
        this.emit('overlay.remote.focus', true);

        this.form[index].resetValues();
        var event = this.form[index].get('event').getValue();
        var name = this.form[index].get('name').getValue();
        var $el = this.form[index].$el;

        if (event === 'none') {
            $el.find('#peripheral-content-container-' + index).hide();
        } else {
            $el.find('#peripheral-content-container-' + index).show();
        }

        $el.find('.use-name-event').html(sprintf(i18n.t('settings.use_name_event'), [name]));

        this.showHideUseNameEvent($el, event);
        this.showHideDelay($el, event);

        this.handleWolfprotEvent(
            this.form[index].get('prot').getValue() === 'wolfprot',
            this.$el.find('#peripheral-content-container-' + index)
        );
        this.checkForEnabledItems(index);

        this.form[index].$el.find('.peripheral-command-test-action').show();
        this.changes = false;
    },

    handleSettingsSave: function(index) {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.form[index].setDefaultValues();
        this.changes = false;
    },

    handleFormChange: function(data) {
        var index = data.input.form.$el.data('index');
        var $el = data.input.form.$el;
        var $useNameBtnSwitch = $el.find('#use-name-event-container-' + $el.data('index'));
        var showUseNameEvent;

        if (data.input.name === 'name') {
            $useNameBtnSwitch.find('.input-label').html(sprintf(i18n.t('settings.use_name_event'), [data.input.value]));
        } else if (data.input.name === 'event') {
            showUseNameEvent = data.input.value.indexOf('button') >= 0;
            this.showButton($useNameBtnSwitch, showUseNameEvent);
            this.showHideDelay($el, data.input.value);

            if (showUseNameEvent) {
                $el.find('.event-select').parent().addClass('col-xs-3');
                $el.find('.event-select').parent().removeClass('col-xs-6');
            } else {
                $el.find('.event-select').parent().removeClass('col-xs-3');
                $el.find('.event-select').parent().addClass('col-xs-6');
            }

            if (data.input.value === 'powerOff' && this.form[index].get('peripheralDelay').getValue() === '') {
                this.form[index].get('peripheralDelay').setValue(0);
            }

            if (data.input.value === 'none') {
                $el.find('#peripheral-content-container-' + index).hide();
            } else {
                $el.find('#peripheral-content-container-' + index).show();
            }
        } else if (data.input.name === 'prot') {
            this.handleWolfprotEvent(data.input.value === 'wolfprot', $el);
        }

        if (this.actionView[index]) {
            data.input.form.$el.find('.peripheral-command-test-action').hide();
            this.actionView[index].open();
            this.checkActionForm(index);
            this.changes = true;
        } else {
            data.input.form.$el.find('.peripheral-command-test-action').show();
        }

        this.checkForEnabledItems(index);
        this.checkActionForm(index);
    },

    /**
     * Show/Hide Use Name Event button switch.
     * @param $el
     * @param event
     */
    showHideUseNameEvent: function($el, event) {
        if (event.indexOf('button') >= 0) {
            $el.find('.event-select').parent().addClass('col-xs-3');
            $el.find('.event-select').parent().removeClass('col-xs-6');
            $el.find('.use-name-event-container').show();

            return;
        }

        $el.find('.event-select').parent().removeClass('col-xs-3');
        $el.find('.event-select').parent().addClass('col-xs-6');
        $el.find('.use-name-event-container').hide();
    },

    /**
     * Show/Hide delay input depending on event.
     *
     * @param $el
     * @param event
     */
    showHideDelay: function($el, event) {
        if (event === 'none' || event === 'powerOff') {
            $el.find('.peripheral-delay').hide();

            return;
        }

        $el.find('.peripheral-delay').show();
    },

    showButton: function($btn, show) {
        if (show) {
            $btn.show();
        } else {
            $btn.hide();
        }
    },

    checkActionForm: function(index) {
        if (!this.actionView[index]) {
            return;
        }

        if ((this.form[index].get('prot').getValue() === 'wolfprot'
            && !this.form[index].get('peripheralDelay').$el.parent().hasClass('is-invalid'))
            || this.form[index].validate()) {
            this.actionView[index].enableSubmitButton();
        } else {
            this.actionView[index].disableSubmitButton();
        }
    },

    /**
     * Initialize all command lists.
     */
    renderRmsCommands: function() {
        this.renderCommandList();
    },

    updateForm: function(formData, index) {
        this.form[index].setValues(formData);
    },

    createSelects: function($item, id) {
        this.createComponent({
            type: 'CustomSelect',
            container: $item.find('.event-select'),
            label: 'settings.event',
            native: true,
            id: 'peripheral-event-' + id,
            name: 'event',
            items: function() {
                let events = [
                    {
                        text: 'settings.none',
                        value: 'none'
                    },
                    {
                        text: 'settings.power_on',
                        value: 'powerOn'
                    },
                    {
                        text: 'settings.power_off',
                        value: 'powerOff'
                    },
                    {
                        text: 'settings.screen_on',
                        value: 'screenOn'
                    },
                    {
                        text: 'settings.screen_off',
                        value: 'screenOff'
                    },
                    {
                        text: 'control_center.custom_command_btn_1',
                        value: 'button1'
                    },
                    {
                        text: 'control_center.custom_command_btn_2',
                        value: 'button2'
                    },
                    {
                        text: 'control_center.custom_command_btn_3',
                        value: 'button3'
                    },
                    {
                        text: 'control_center.custom_command_btn_4',
                        value: 'button4'
                    },
                    {
                        text: 'control_center.custom_command_btn_5',
                        value: 'button5'
                    },
                    {
                        text: 'control_center.custom_command_btn_6',
                        value: 'button6'
                    },
                    {
                        text: 'control_center.custom_command_btn_7',
                        value: 'button7'
                    },
                    {
                        text: 'control_center.custom_command_btn_8',
                        value: 'button8'
                    }
                ];

                if (this.deviceService.isCboxPureMini()) {
                    events = events.filter(e => e.value !== 'screenOn' && e.value !== 'screenOff');
                }

                return events;
            }.bind(this).call()
        });

        this.createComponent({
            type: 'CustomSelect',
            container: $item.find('.prot-select'),
            label: 'settings.prot',
            native: true,
            id: 'peripheral-prot-' + id,
            name: 'prot',
            items: app.getService('Model-View').getPeripheralCommandProtocols()

        });

        this.createComponent({
            type: 'CustomSelect',
            container: $item.find('.cmd-select'),
            label: 'settings.wolfprot',
            native: true,
            id: 'peripheral-wolfprot-' + id,
            name: 'wolfprot',
            items: [
                {
                    text: 'settings.none',
                    value: 'none'
                },
                {
                    text: 'settings.wolfprot_mirroring',
                    value: 'mirroring'
                }
            ]
        });
    },

    /**
     * Show/Hide wolfprot dropdown.
     *
     * @param show
     * @param $item
     */
    handleWolfprotEvent: function(show, $item) {
        if (show) {
            $item.find('.cmd-select').show();
            $item.find('.specific-event-content').hide();
        } else {
            $item.find('.cmd-select').hide();
            $item.find('.specific-event-content').show();
        }
    },

    /**
     * @param {Number} index
     */
    checkForEnabledItems: function(index) {
        const cmd = this.form[index].get('commandEnabled');
        const cmdEnabled = cmd && cmd.getValue();
        const noneEvent = this.form[index].get('event').getValue() === 'none';

        if (!cmdEnabled || noneEvent) {
            this.disableAllItems(index, (cmdEnabled && noneEvent));

            return;
        }

        this.enableAllItems(index);
    },

    /**
     * @param {Number} index
     */
    enableAllItems: function(index) {
        this.form[index].get('name').enable();
        this.form[index].get('description').enable();
        this.form[index].get('ip').enable();
        this.form[index].get('port').enable();
        this.form[index].get('event').enable();
        this.form[index].get('prot').enable();
        this.form[index].get('test-command').enable();
        this.form[index].get('useNameEvent').enable();
        this.form[index].get('wolfprot').enable();
        this.form[index].get('peripheralDelay').enable();
        $(this.form[index].get('useNameEvent')).removeClass('is-disabled');

        // The protocol decides which input fields have to be enabled.
        this.switchItemProtocol(this.form[index].get('prot').getValue(), index);
    },

    /**
     * @param {Number} index
     */
    disableAllItems: function(index, enableEvent) {
        this.form[index].get('name').disable();
        this.form[index].get('description').disable();
        this.form[index].get('ip').disable();
        this.form[index].get('port').disable();
        this.form[index].get('hexCmd').disable();
        this.form[index].get('event').disable();
        this.form[index].get('prot').disable();
        this.form[index].get('cmd').disable();
        this.form[index].get('password').disable();
        this.form[index].get('test-command').disable();
        this.form[index].get('useNameEvent').disable();
        this.form[index].get('wolfprot').disable();
        this.form[index].get('peripheralDelay').disable();
        $(this.form[index].get('useNameEvent')).addClass('is-disabled');

        if (enableEvent) {
            this.form[index].get('event').enable();
        }
    },

    destroy: function() {
        var index;

        _.each(this.form, function(item) {
            if (item) {
                index = item.$el.data('index');

                this.formManager.destroy(item);

                if (this.actionView[index]) {
                    this.actionView[index].destroy();
                }
            }
        }.bind(this));
    },

    hasChanges: function() {
        var changes = {
            hasChanges: this.changes,
            invalid: false
        };

        if (this.actionView) {
            changes.invalid = $(this.actionView.$submitBtn).prop('disabled');
        }

        return changes;
    }
});
