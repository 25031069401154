'use strict';

var _ = require('lodash');
var $ = require('jquery');
var eventEmitterMixin = require('../../event-emitter').eventEmitterMixin;
var app = require('../app');
var platform = require('./../../platform/platform');
var SPECIALKEYS = [37, 38, 39, 40, 13, 9, 16, 20];

/**
 * @type {Object}
 */
var defaultFormOptions = {
    form: null,
    liveValidation: true,
    validationContainer: null,
    invalidClass: 'is-invalid',
    validClass: 'is-valid',
    isDisabled: 'is-disabled'
};

/**
 * Converts a value to the given type
 * @method typeCast
 * @param  {Mixed} value
 * @param  {String} type
 * @return {Mixed}
 */
function typeCast(value, type) {
    if (type === 'number') {
        return parseInt(value, 10) || null;
    }

    return value;
}

/**
 * @class Input
 * @constructor
 */
function Input(options, validations) {
    /**
     * @type {Object}
     */
    this.options = options;

    /**
     * @type {Object}
     */
    this.form = options.form;

    /**
     * Reference to the jquery element
     * @type {Object}
     */
    this.$el = $(this.options.el);

    /**
     * Reference to the DOM element
     * @type {Object}
     */
    this.el = this.$el.get(0);

    /**
     * Input name
     * @type {String}
     */
    this.name = this.$el.attr('name');

    /**
     * Input type
     * @type {String}
     */
    this.type = this.$el.attr('type') || this.$el.prop('tagName').toLowerCase();

    /**
     * Input value
     * @type {Mixed}
     */
    this.value = typeCast(this.$el.val(), this.type);

    /**
     * Input validity
     * @type {Boolean}
     */
    this.validity = true;

    /**
     * Validation method to use
     * @type {Function}
     */
    this.validation = validations[this.$el.data('validation')];

    /**
     * @type {Mixed}
     */
    this.initializeValue = null;

    /**
     * @type {boolean}
     */
    this.hasChanges = {
        hasChanges: false,
        invalid: false
    };

    if (!this.name) {
        throw new Error('Input fields must define a name attribute');
    }

    this.initialize();

    return this;
}

/**
 * Start to watch the input for changes
 * @method initialize
 * @chainable
 */
Input.prototype.initialize = function initialize() {
    var event = {
        'text': 'input change keyup',
        'checkbox': 'change',
        'select': 'change',
        'password': 'change keyup',
        'file': 'change'
    };

    this.$el.on(event[this.type], this.changeHandler.bind(this));
    this.$el.on('focus', this.focusHandler.bind(this));
};

/**
 * Handler method for the change event
 * @method changeHandler
 * @chainable
 */
Input.prototype.changeHandler = function changeHandler(event) {
    this.validity = this.validate();

    if ((event.type !== 'keyup' || SPECIALKEYS.indexOf(event.which) === -1)
        && (this.getValue() !== this.initializeValue || this.hasChanges.hasChanges)
    ) {
        this.hasChanges.hasChanges = true;
        this.form.emit('change.input', {
            value: this.getValue(),
            input: this,
            $el: this.$el,
            validity: this.validity
        });

        if (this.getValue() === this.initializeValue) {
            this.resetValue();
        }
    }
};

/**
 * Handler method for the focus event
 * @method focusHandler
 * @chainable
 */
Input.prototype.focusHandler = function focusHandler() {
    if (platform.checks.isSmartphone) {
        return;
    }

    _.defer(function() {
        var $el = $(this.el);
        $el.select();
    }.bind(this));
};

/**
 * Update the current value and return it
 * @method getValue
 * @return {*}
 */
Input.prototype.getValue = function getValue() {
    var value;
    var handler = this.specialInputHandlers[this.type + 'Getter'];

    if (handler) {
        value = handler.call(this, this.value);
    } else {
        value = this.$el.val();
    }

    if (this.type !== 'file') {
        this.value = typeCast(value, this.type);
    } else {
        return value;
    }

    return this.value;
};

/**
 * Set the value for the input field and update the internal value
 * @method setValue
 * @chainable
 */
Input.prototype.setValue = function setValue(value) {
    var handler = this.specialInputHandlers[this.type + 'Setter'];
    this.value = typeCast(value, this.type);

    if (handler) {
        handler.call(this, this.value);
    } else {
        this.$el.val(this.value);
    }

    if (this.initializeValue === null) {
        this.initializeValue = this.value;
    }

    return this;
};

/**
 * Set the internal default value
 * @method setDefaultValue
 * @chainable
 */
Input.prototype.setDefaultValue = function setDefaultValue(value) {
    this.initializeValue = value;

    return this;
};

Input.prototype.resetValue = function resetValue() {
    if (this.initializeValue !== null) {
        this.hasChanges.hasChanges = false;
        this.setValue(this.initializeValue);
        this.$el.trigger('change');

        this.resetValidate();
    }
};

/**
 * Set validation function for this input
 * @method setValue
 * @chainable
 */
Input.prototype.setValidation = function setValidation(validationFunction) {
    if (typeof validationFunction === 'function') {
        this.validate = validationFunction;
    }

    return this;
};

/**
 * Calls the appropriate validation method
 *
 * @return {boolean}
 */
Input.prototype.validate = function validate() {
    var validation = this.validation || function() {
        return true;
    };

    return validation.call(app.getService('ValidateService'), this.getValue(), this);
};

/**
 * @method disable
 * @chainable
 */
Input.prototype.disable = function disable() {
    this.$el.attr({
        'disabled': 'disabled',
        'data-nav-limit': 'true'
    });

    // Fix for Edge 40 --> see RELEASE-1370
    if (platform.checks.isEdge) {
        this.$el.attr('readonly', true);
    }

    var $validationContainer = this.$el.closest(this.form.options.validationContainer);

    $validationContainer.removeClass(this.form.options.validClass);
    $validationContainer.removeClass(this.form.options.invalidClass);
    $validationContainer.addClass(this.form.options.isDisabled);

    return this;
};

/**
 * @method resetValidate
 * @chainable
 */
Input.prototype.resetValidate = function() {
    var $validationContainer = this.$el.closest(this.form.options.validationContainer);

    $validationContainer.removeClass(this.form.options.validClass);
    $validationContainer.removeClass(this.form.options.invalidClass);

    return this;
};

/**
 * @method enable
 * @chainable
 */
Input.prototype.enable = function enable() {
    this.$el.removeAttr('data-nav-limit');
    this.$el.removeAttr('disabled');

    // Fix for Edge 40 --> see RELEASE-1370
    if (platform.checks.isEdge) {
        this.$el.attr('readonly', false);
    }

    var $validationContainer = this.$el.closest(this.form.options.validationContainer);

    $validationContainer.removeClass(this.form.options.isDisabled);

    return this;
};

/**
 * Returns true when input-field is disabled
 *
 * @returns {boolean}
 */
Input.prototype.isDisabled = function isDisabled() {
    return !!this.$el.attr('disabled');
};

/**
 * Handler methods for special inputs
 * @type {Object} specialInputHandlers
 */
Input.prototype.specialInputHandlers = {
    checkboxSetter: function() {
        this.$el.prop('checked', !!this.value);
    },
    checkboxGetter: function() {
        return this.$el.prop('checked');
    },
    selectSetter: function() {
        this.$el.val(this.value);
        this.$el.trigger('change');
    },
    fileGetter: function() {
        var file = this.el.files[0];

        return file;
    }
};

/**
 * @class Form
 * @constructor
 * @chainable
 */
function Form(options, validations) {
    /**
     * Add event emitter functionality
     */
    eventEmitterMixin(this);

    /**
     * @type {Object}
     */
    this.options = _.defaults(options, defaultFormOptions);

    /**
     * Reference to the jquery form object
     * @type {Object}
     */
    this.$el = $(this.options.el);

    /**
     * Reference to all input fields
     * @type {Object}
     */
    this.$allInputs = this.$el.find('input:not(.is-virtual-input), textarea, select');

    /**
     * Reference to the validations collection
     * @type {Object}
     */
    this.validations = validations;

    /**
     * Store for the input refernces
     * @type {Object}
     */
    this.inputsStore = {};

    this.getInputs();
    this.bindDOMEvents();
    this.listen();

    return this;
}

/**
 * Listen for events
 * @method listen
 */
Form.prototype.listen = function listen() {
    this.on('change.input', this.inputChangeHandler.bind(this));
};

/**
 * @method bindDOMEvents
 */
Form.prototype.bindDOMEvents = function bindDOMEvents() {
    this.$el.on('keydown', this.keyDownHandler.bind(this));
    this.$el.on('submit', this.onSubmitHandler.bind(this));
};

/**
 * @method keyDownHandler
 */
Form.prototype.keyDownHandler = function keyDownHandler(event) {
    var $target = $(event.target);

    // Block action btn (resolved a Problem on Login form)
    if (event.which === 13 && !$target.hasClass('form-action-cancel') && !$target.hasClass('form-action-submit')) {
        if (this.options.submit) {
            this.$el.trigger('submit');
        }

        app.emit('submit');
    }
};

Form.prototype.onSubmitHandler = function(event) {
    event.preventDefault();
};

/**
 * @method inputChangeHandler
 */
Form.prototype.inputChangeHandler = function inputChangeHandler(params) {
    if (this.options.liveValidation) {
        this.showValidation(params.value, params.input, params.$el);
    }
};

/**
 * Gets all the input fields of the form
 * @method getInputs
 */
Form.prototype.getInputs = function getInputs() {
    this.$allInputs.each(function(index, inputElement) {
        if (inputElement.id && inputElement.id.substring(0, 11) === 'form-action') {
            return;
        }

        var input = new Input({
            el: inputElement,
            form: this
        }, this.validations);

        this.inputsStore[input.name] = input;
    }.bind(this));
};

/**
 * Get the input instance
 * @method get
 */
Form.prototype.get = function get(name) {
    if (name === 'all') {
        return this.$allInputs;
    }

    if (!this.inputsStore[name]) {
        app.getService('ExceptionsManager')
            .throw('Field with name "' + name + '" not found.');
    }

    return this.inputsStore[name] || null;
};

/**
 * Set the values for all inputs
 * @method setValues
 * @chainable
 */
Form.prototype.setValues = function setValues(values) {
    var inputStoreKeys = _.keys(this.inputsStore);

    inputStoreKeys.forEach(function(key) {
        if (!!values[key] || values[key] === 0 || values[key] === '' || values[key] === false) {
            this.get(key).setValue(values[key]);
        }
    }.bind(this));

    return this;
};

/**
 * Set the default values for all inputs
 * @method setDefaultValues
 * @chainable
 */
Form.prototype.setDefaultValues = function setDefaultValues() {
    var inputStoreKeys = _.keys(this.inputsStore);

    inputStoreKeys.forEach(function(key) {
        this.get(key).setDefaultValue(this.get(key).value);
    }.bind(this));

    return this;
};

/**
 * @method resetValues
 */
Form.prototype.resetValues = function resetValues() {
    var inputStoreKeys = _.keys(this.inputsStore);

    inputStoreKeys.forEach(function(key) {
        this.get(key).resetValue();
    }.bind(this));
};

/**
 * Clear has changes flag for all inputs.
 */
Form.prototype.clearHasChanges = function clearHasChanges() {
    _.keys(this.inputsStore).forEach(function(key) {
        this.get(key).hasChanges.hasChanges = false;
    }.bind(this));
};

/**
 * Validates all the form fields.
 */
Form.prototype.validate = function validate() {
    var inputStoreKeys = _.keys(this.inputsStore);
    var validity = true;

    inputStoreKeys.forEach(function(key) {
        var input = this.inputsStore[key];

        if (!input.validate() && !input.isDisabled()) {
            validity = false;
            input.validity = false;
        } else {
            input.validity = true;
        }

        this.showValidation(input.getValue(), input, input.$el);
    }.bind(this));

    return validity;
};

/**
 * Add the validation classes
 * @method showValidation
 * @param  {String} name Input name
 * @param  {Object} options
 * @chainable
 */
Form.prototype.showValidation = function showValidation(value, input, $input) {
    var $validationContainer = $input.closest(this.options.validationContainer);
    var $validationElement = ($validationContainer.size()) ? $validationContainer : $input;

    if (!input.validity) {
        $validationElement.removeClass(this.options.validClass);
        $validationElement.addClass(this.options.invalidClass);
    } else {
        $validationElement.addClass(this.options.validClass);
        $validationElement.removeClass(this.options.invalidClass);
    }

    return this;
};

/**
 * Serialize the form and return an object
 */
Form.prototype.serialize = function serialize() {
    var inputStoreKeys = _.keys(this.inputsStore);
    var serialized = {};

    inputStoreKeys.forEach(function(key) {
        serialized[key] = this.get(key).getValue();
    }.bind(this));

    return serialized;
};

/**
 * Update the Form instance
 * @chainable
 */
Form.prototype.update = function update() {
    var defaultInputsStore = this.inputsStore;
    var inputStoreKeys = _.keys(defaultInputsStore);

    this.$allInputs = this.$el.find('input, textarea, select');
    this.inputsStore = {};

    this.getInputs();

    // Restore existing default values
    inputStoreKeys.forEach(function(key) {
        if (!this.inputsStore[key]) {
            return;
        }

        this.get(key).setDefaultValue(defaultInputsStore[key].initializeValue);
    }.bind(this));

    return this;
};

/**
 * Destroys the Form instance
 * @chainable
 */
Form.prototype.destroy = function destroy() {
    var inputStoreKeys = _.keys(this.inputsStore);

    inputStoreKeys.forEach(function(key) {
        this.inputsStore[key].$el.remove();
        this.inputsStore[key].$el = null;
        this.$allInputs = null;
        delete this.inputsStore[key];
    }.bind(this));

    return this;
};

app.service('FormManager', function() {
    var validations = {};

    return {
        create: function(options) {
            return new Form(options, validations);
        },

        update: function(form) {
            if (form instanceof Form) {
                form.update();
            }

            return form;
        },

        addValidation: function(name, validationFunction) {
            if (typeof name === 'string' && !!validationFunction) {
                validations[name] = validationFunction;
            } else if (_.isObject(name) && !validationFunction) {
                _.extend(validations, name);
            }
        },

        destroy: function(form) {
            if (form instanceof Form) {
                form.destroy();
            }
        }
    };
});
