'use strict';

var _ = require('lodash');
var $ = require('jquery');
var app = require('../../app');
var sprintf = require('./../../helper').sprintf;
var tpl = require('./container.hbs');
var messageTpl = require('./osd-message.hbs');
var platform = require('../../../platform/platform');
var i18n = require('i18next');

var ANIMATION_DURATION = 300;

var icons = {
    'info': 'icon-msg-system',
    'success': 'icon-msg-success',
    'warning': 'icon-msg-warning',
    'error': 'icon-msg-warning',
    'matrix': 'icon-vmatrix',
    'vmeeting': 'icon-office_calendar',
    'groupwork': 'icon-screen-share'
};

require('truncate.js');

app.component('OSDMessage', {
    template: tpl,
    messages: [],
    messagesInteractive: [],

    initialize: function() {
        this.frontendSettings = this.getService('FrontendSettings');
        this.sessionMgmtService = this.getService('SessionManagementService');
        this.remote = this.getService('RemoteService');

        this.lastMessage = '';
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();
        this.showNotificationHdmi2 = false;
    },

    storeSelectors: function() {
        this.$messages = this.$el.find('#osd-messages');
    },

    bindEvents: function() {
        this.on('main-loop.update', this.loadOsdSettings.bind(this));
        this.on('main-loop.update.osd', this.loadData.bind(this));
        this.on('frontend-osd-message.show', this.onFrontendOsdMessage.bind(this));
        this.on('frontend-osd-message.hide', this.closeFrontendOsdMessage.bind(this));
        this.on('osd-message-interactive.show', this.onOsdMessageInteractive.bind(this, true));
        this.on('osd-message-interactive.hide', this.onOsdMessageInteractive.bind(this, false));
        this.on('osd-message.show', this.onOsdMessage.bind(this));
        this.on('modal.closed', this.checkFocus.bind(this));
        this.on('control-center.closed', this.checkFocus.bind(this));
        this.on('overlay.closed', this.checkFocus.bind(this));
        this.on('menu.closed', this.checkFocus.bind(this));
        this.on('osd-message-interactive.focus', this.checkFocus.bind(this));
        this.on('osd-message-interactive.update', this.onUpdateOsdMessageInteractive.bind(this));
    },

    /**
     * Load OSD message data and frontend settings.
     *
     * @returns {*}
     */
    loadOsdSettings: function() {
        return $.when(this.loadData(this), this.loadFrontendSettings(this));
    },

    /**
     * Load OSD message data.
     */
    loadData: function() {
        this.deviceConnection
            .send('getOsdMessage')
            .then(this.onOsdMessage.bind(this));
    },

    /**
     * Load frontend settings.
     *
     * @returns {*}
     */
    loadFrontendSettings: function() {
        var dfd = $.Deferred();

        this.frontendSettings.getSettings('showNotificationHdmi2')
            .then(function(result) {
                this.showNotificationHdmi2 = result;
                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Handle getOSDMessage from backend and show OSD Message.
     *
     * @param data OSD message data
     */
    onOsdMessage: function(data) {
        var message;
        var messageObject;

        if (!this.showNotificationHdmi2 && platform.checks.isCboxAux) {
            this.closeLastMessage();

            return;
        }

        message = data.osdMessage === 255 ? '' : this.parseMessage(data);
        messageObject = {
            id: data.osdMessage,
            message: message,
            iconClass: icons.info,
            title: i18n.t('osd_messages.info'),
            type: data.icon
        };

        if (icons[data.icon]) {
            messageObject.iconClass = icons[data.icon];
            messageObject.title = i18n.t('osd_messages.' + data.icon);
        }

        if (messageObject.message !== this.lastMessage && this.lastMessage === '') {
            this.addMessage(messageObject);
        } else if (messageObject.message === '' && messageObject.message !== this.lastMessage) {
            this.closeLastMessage();
        } else if (messageObject.message !== this.lastMessage) {
            this.closeLastMessage();
            this.addMessage(messageObject);
        }

        this.lastMessage = messageObject.message;
    },

    /**
     * Handle show OSD Message triggered by the frontend.
     *
     * @param data OSD message data
     */
    onFrontendOsdMessage: function(data) {
        var messageObject;
        var messageKey = data.osdMessageKey;
        var message = data.osdMessage;

        if (!this.showNotificationHdmi2 && platform.checks.isCboxAux) {
            this.closeLastMessage();

            return;
        }

        if (messageKey) {
            message = i18n.t(messageKey);
        }

        // Open OSD with specific ID and no timeout (-1) only once. (e.g. vmeeting-service)
        if (data.id && data.timeout) {
            var msgData = $.grep(this.messages, function(m) {
                return data.id === m.id;
            }.bind(this));

            if (msgData.length > 0 && msgData[0]) {
                return;
            }
        }

        messageObject = {
            id: data.id || -1,
            message: message,
            iconClass: icons.info,
            timeout: data.timeout || 6000,
            type: data.icon,
            title: i18n.t('osd_messages.info'),
            hasAction: data.hasAction || false
        };

        if (icons[data.icon]) {
            messageObject.iconClass = icons[data.icon];
            messageObject.title = i18n.t('osd_messages.' + data.icon);
        }

        if (messageObject.message !== this.lastMessage && this.lastMessage === '') {
            this.addMessage(messageObject);

            if (messageObject.timeout >= 0) { // If timeout is -1 - do not start timer.
                this.startTimer(messageObject.timeout, false);
            }
        }
    },

    /**
     * Close frontend OSD message by event.
     * E.g. messages without timeout (vmeeting-service message)
     *
     * @param data.id OSD Message ID
     */
    closeFrontendOsdMessage: function(data) {
        var id = data.id;

        if (!id) {
            this.closeLastMessage();

            return;
        }

        var msgData = $.grep(this.messages, function(m) {
            return id === m.id;
        }.bind(this));

        for (var i = 0; i < msgData.length; i++) {
            if (msgData[i]) {
                this.messages.splice(this.messages.indexOf(msgData[i]), 1);

                msgData[i].$el
                    .velocity({
                        marginLeft: '-350px'
                    }, {
                        duration: ANIMATION_DURATION,
                        complete: this.removeMessage.bind(this, msgData[i])
                    });
            }
        }
    },

    /**
     * Handle interactive OSD message.
     *
     * @param show Show OSD message if true, else hide
     * @param data OSD message data
     */
    onOsdMessageInteractive: function(show, data) {
        var id = data.id;
        var currentId = this.$el.find('.osd-message').attr('data-id');

        if (show) {
            // Check if message already exists.
            if (currentId === 'vmeeting' || currentId === id) {
                this.onUpdateOsdMessageInteractive(data);

                return;
            }

            var messageObject;
            var message = data.osdMessage;
            var info = data.info || '';

            messageObject = {
                id: id,
                message: message,
                iconClass: data.icon,
                type: data.icon,
                info: info,
                title: i18n.t('osd_messages.info'),
                hasAction: true,
                onAllow: data.onAllow,
                onDecline: data.onDecline,
                timeout: data.timeout
            };

            if (icons[data.icon]) {
                messageObject.iconClass = icons[data.icon];
                messageObject.title = i18n.t('osd_messages.' + data.icon);
            }

            this.addMessage(messageObject);

            if (messageObject.timeout >= 0) { // If timeout is -1 - do not start timer.
                this.messages.push(messageObject);
                this.startTimer(messageObject.timeout, true);
            }
        } else {
            this.closeMessage(id);
        }
    },

    /**
     * Update interactive osd message text.
     *
     * @param data
     */
    onUpdateOsdMessageInteractive: function(data) {
        var interactiveOsdMsg = this.$el.find('.osd-message').filter(function() {
            return this.getAttribute('data-id') === 'vmeeting';
        });

        if (interactiveOsdMsg.length > 0) {
            $(interactiveOsdMsg[0]).find('.osd-message-text').get(0).innerHTML = data.osdMessage;
        }
    },

    /**
     * Parse message from data.
     *
     * @param data OSD message data
     * @returns {*}
     */
    parseMessage: function(data) {
        var text = i18n.t('osd_messages.' + data.osdMessage);

        return sprintf(text, data.parameter);
    },

    /**
     * Start OSD message timer for messages triggered by the frontend (others are handled by the backend)
     *
     * @param timeout Timeout in milliseconds
     */
    startTimer: function(timeout, interactive) {
        this.timer = setTimeout(function() {
            this.closeLastMessage(interactive);
        }.bind(this), timeout);
    },

    /**
     * Close last OSD message.
     */
    closeLastMessage: function(interactive) {
        let msgData;

        if (interactive) {
            msgData = this.messagesInteractive.pop();
        } else {
            msgData = this.messages.pop();
        }

        if (msgData) {
            msgData.$el
                .velocity({
                    marginLeft: '-350px'
                }, {
                    duration: ANIMATION_DURATION,
                    complete: this.removeMessage.bind(this, msgData)
                });
        }
    },

    /**
     * Close OSD message with ID.
     *
     * @param id
     */
    closeMessage: function(id) {
        let msgData = $.grep(this.messagesInteractive, function(m) {
            return id === m.id;
        }.bind(this));

        if (msgData.length > 0 && msgData[0]) {
            this.messagesInteractive.splice(this.messagesInteractive.indexOf(msgData[0]), 1);

            msgData[0].$el
                .velocity({
                    marginLeft: '-350px'
                }, {
                    duration: ANIMATION_DURATION,
                    complete: this.removeMessage.bind(this, msgData[0])
                });
        }
    },

    /**
     * Add OSD message to message container.
     *
     * @param data OSD message data
     */
    addMessage: function(data) {
        // RELEASE-3096 system reboot
        if (data.id === 25) {
            this.emit('system-reboot.started');
        }

        var $el = this.$(messageTpl(data));
        var msgData = {};

        this.extendMessage($el, data);

        this.$messages.append($el);

        var top = this.messagesInteractive.length > 0 ? 20 : 0; // 20px === margin

        _.each(this.messagesInteractive, function(message) {
            top += message.$el.outerHeight();
        }.bind(this));

        $el
            .css('top', top)
            .velocity({
                marginLeft: '20px'
            }, {
                duration: ANIMATION_DURATION
            });

        // Text-overflow ellipsis does not work with multi-lines in IE and Edge.
        if ((platform.checks.isEdge || platform.checks.isIe) && 'info' === data.type) {
            $el.truncate({
                lines: 3
            });
        }

        msgData.$el = $el;
        msgData.id = data.id;

        if (data.hasAction) {
            this.messagesInteractive.push(msgData);

            this.remote.focusArea($el, {
                area: '.is-focusable:not(.is-disabled)'
            });
        } else {
            this.messages.push(msgData);
        }
    },

    /**
     * Extend special/interactive messages with buttons etc.
     *
     * @param $el Container
     */
    extendMessage: function($el, data) {
        var $container = $el.find('.osd-message-extend-container');

        if ($el.data('id') === 'vmeeting') {
            app.createComponent('OSDMessageExtend' + $el.data('id'), {
                type: 'VMeetingOSDMessage',
                container: $container
            });

            $container.show();
        } else if ($el.data('id') && $el.data('id').toString().indexOf('groupwork') === 0) {
            app.createComponent('OSDMessageExtend' + $el.data('id'), {
                type: 'OSDExtendInteractive',
                container: $container,
                onAllow: function() {
                    this.getService('GroupworkService').handleScreenShareRequest(true, $el.data('info'));
                }.bind(this),
                onDecline: function() {
                    this.getService('GroupworkService').handleScreenShareRequest(false, $el.data('info'));
                }.bind(this)
            });

            $container.show();
        } else if ($el.data('id') === 'waiting-room') {
            app.createComponent('OSDMessageExtend' + $el.data('id'), {
                type: 'OSDExtendInteractive',
                container: $container,
                onAllow: data.onAllow,
                allowText: i18n.t('osd_messages.show'),
                onDecline: data.onDecline,
                declineText: i18n.t('osd_messages.hide')
            });

            $container.show();
        } else {
            $container.hide();
        }
    },

    /**
     * Remove OSD message from message container and destroy extend container if there is one.
     *
     * @param msgData OSD message data
     */
    removeMessage: function(msgData) {
        app.removeComponent('OSDMessageExtend' + msgData.$el.data('id'));

        msgData.$el.remove();
        msgData.$el.hide();
    },

    /**
     * If modal, control center, source menu or overlay is closed and there is an interactive OSD msg opened,
     * focus the OSD message.
     */
    checkFocus: function() {
        if (this.messagesInteractive.length > 0) {
            this.remote.focusArea(this.messagesInteractive[0].$el, {
                area: '.is-focusable:not(.is-disabled)'
            });
        }
    }
});
