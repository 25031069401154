'use strict';

const app = require('../app');
const d3 = require('d3');
const _ = require('lodash');
const $ = require('jquery');
const platform = require('./../../platform/platform');
const i18n = require('i18next');
const RedrawEngine = require('../../redraw-engine');
const StateMachine = require('./../state-machine');
const { outputMapping } = require('../components/matrix/constants');

const pushPullState = {
    none: 0,
    pull: 1,
    push: 2,
    pushStream: 3,
    coachingSrc: 4,
    coachingDest: 5
};

const powerStates = {
    on: 1,
    off: 0,
    none: -1
};

/**
 * Control state (aka access levels)
 * @type {{none: number, limited: number (irrelevant atm), control: number}}
 */
const controlStates = {
    none: 0,
    limited: 1,
    control: 2
};

const animationFps = 0.4;

const infoHeight = 150;

/**
 * Matrix Main service
 */
app.service('MatrixMainService', function() {
    return {

        Engine: null,

        $canvas: null,

        context: null,

        initialize: function() {
            this.statusData = null;
            this.audioStatus = null;
            this.powerEnd = 0;
            this.setupEnd = 0;
            this.matrixService = app.getService('MatrixService');
            this.matrixPreviewService = app.getService('MatrixPreviewService');
            this.configs = app.getService('MatrixConfigs');
            this.URIService = app.getService('URIService');
            this.modalHandlerService = app.getService('ModalHandlerService');
            this.deviceService = app.getService('DeviceService');
            this.isDualProjection = this.deviceService.isCboxProDualProjection();
            this.dropzone = null;
            this.replaceAppId = 0;
            this.coaching = null;
            this.groupworkEnabled = false;
            this.groupworkActive = null;

            this.$svg = null;
            this.move = false;
            this.connected = false; // True if any stations are connected to the master (pull/push)
            this.animationEngine = new RedrawEngine();
            this.showAllPreviews = false;

            this.powerState = new StateMachine({
                context: this,
                state: powerStates.none,
                states: powerStates
            });

            this.preventTouchEvents = [];

            this.bindEvents();
        },

        bindEvents: function() {
            app.on('matrix-status.update', this.updateHandler.bind(this));
            app.on('matrix-main-template.changed', this.onTemplateChange.bind(this));
            this.animationEngine.on('redraw.update', this.redrawAnimation.bind(this));
            app.on('matrix-status-audio.update', this.updateAudio.bind(this));
        },

        setSvg: function(svg) {
            this.$svg = svg;
            this.svg = d3.select(svg[0]);
            this.svgNode = svg.get(0);
            this.statusData = null;
            this.$canvas = $(document).find('.preview');
            this.$stationInfoContainer = svg.find('defs .station-info-container');
            this.init = true;
        },

        /**
         * Hide connection handles (edit points).
         *
         * @param svg {Object} svg context
         */
        removeConnectionHandles: function(svg) {
            svg.selectAll('#connections-layer').selectAll('circle').remove();
        },

        /**
         * Hide connections.
         *
         * @param svg {Object} svg context
         */
        hideConnections: function(svg) {
            svg.selectAll('#connections-layer').selectAll('path.line')
                .style('display', 'none');
        },

        /**
         * Show menu button.
         *
         * @param svg Current station/svg context
         * @param btn Button class
         */
        showMenuButton: function(svg, btn) {
            svg.selectAll('.' + btn).style('display', 'block');
        },

        /**
         * Hide menu button.
         *
         * @param svg Current station/svg context
         * @param btn Button class
         */
        hideMenuButton: function(svg, btn) {
            svg.selectAll('.' + btn).style('display', 'none');
        },

        /**
         * Set document counter of svg.
         *
         * @param svg Current station/svg context
         * @param count Number of shared documents
         */
        setDocsCounter: function(svg, count) {
            svg.selectAll('.docs-counter-text').text(count);
        },

        /**
         * Remove additional Menu button.
         *
         * @param svg {Object} svg context
         * @param btn {string} button class name
         */
        removeAdditionalMenuButton: function(svg, btn) {
            svg.selectAll('.' + btn).remove();
        },

        /**
         * If template changes, set templateChanged to true.
         */
        onTemplateChange: function() {
            this.templateChanged = true;
        },

        /**
         * Bind events to connect button.
         */
        bindStationEvents: function() {
            const stations = this.svg.selectAll('.svg-station');
            const names = this.svg.selectAll('.station-name-container');

            if (this.templateChanged) {
                this.handleSetupState(8000);

                this.templateChanged = false;
            } else {
                const currentTime = new Date().getTime();

                if (this.powerEnd > (currentTime + 1000)) {
                    this.openPowerModal(this.powerState.getState(), this.powerEnd - currentTime);
                } else if (this.setupEnd > (currentTime + 1000)) {
                    this.openSetupModal(this.setupEnd - currentTime);
                }
            }

            names
                .data(names._groups[0])
                .call(d3.drag()
                    .on('start', function(d) {
                        app.emit('matrix.preview.show', d.parentElement);

                        // RELEASE-1993 - Red pointer appears when sliding a finger on the screen.
                        app.emit('custom-cursor.disable');
                    }.bind(this))
                    .on('end', function(d) {
                        app.emit('matrix.single-preview.close', d.parentElement);

                        // RELEASE-1993 - Red pointer appears when sliding a finger on the screen.
                        app.emit('custom-cursor.enable');
                    }.bind(this)));

            stations
                .data(stations._groups[0])
                .call(d3.drag()
                    .on('start', function(d) {
                        app.emit('matrix.preview.show', d.parentElement);

                        // RELEASE-1993 - Red pointer appears when sliding a finger on the screen.
                        app.emit('custom-cursor.disable');
                    }.bind(this))
                    .on('end', function(d) {
                        app.emit('matrix.single-preview.close', d.parentElement);

                        // RELEASE-1993 - Red pointer appears when sliding a finger on the screen.
                        app.emit('custom-cursor.enable');
                    }.bind(this)));

            _.each(stations._groups[0], function(station) {
                if (!station.parentElement.getAttribute('id').startsWith('master')) {
                    const currentStation = this.svg.selectAll('.station[id=' + station.parentElement.getAttribute('id') + ']');
                    const data = currentStation.selectAll('.matrix-circle[data-action="connect"]')._groups[0];

                    if (station.parentElement.getAttribute('id').startsWith('stream')) {
                        currentStation.selectAll('.matrix-circle[data-action="connect"]')
                            .data(data)
                            .call(d3.drag()
                                .on('end', function() {
                                    if (!this.groupworkActive) {
                                        app.emit('stream-interaction.show');
                                    }
                                }.bind(this)));
                    } else if (station.parentElement.getAttribute('model') === 'CPR') {
                        currentStation.selectAll('.matrix-circle[data-action="connect"]')
                            .data(data)
                            .call(d3.drag()
                                .on('start', this.onMoveStart.bind(this))
                                .on('drag', function() {
                                    // Avoid ending push with a touch and move event
                                    this.move = true;
                                }.bind(this))
                                .on('end', this.onMoveStationEnd.bind(this)));
                    } else {
                        currentStation.selectAll('.matrix-circle[data-action="connect"]')
                            .data(data)
                            .call(d3.drag()
                                .on('start', this.onMoveStart.bind(this))
                                .on('drag', this.onMove.bind(this))
                                .on('end', this.onMoveStationEnd.bind(this)));

                        currentStation.selectAll('.matrix-circle[data-action="docs"]')
                            .call(d3.drag()
                                .on('start', function() {
                                    app.emit('matrix-main.toggle.files.menu', station.parentElement);
                                }.bind(this)));

                        currentStation.selectAll('.matrix-circle[data-action="control"]')
                            .on('touchend click', _.throttle(this.handleStationControl.bind(this), 1000));
                    }
                }
            }.bind(this));
        },

        /**
         * Bind events to master button.
         */
        bindMasterEvents: function() {
            this.masterLoop(function(master) {
                const data = master.selectAll('.matrix-circle[data-action="connect"]')._groups[0];

                master.selectAll('.matrix-circle[data-action="connect"]')
                    .data(data)
                    .call(d3.drag()
                        .on('start', this.onMoveStart.bind(this))
                        .on('drag', this.onMove.bind(this))
                        .on('end', this.onMoveMasterEnd.bind(this)));
            }.bind(this));
        },

        /**
         * Handle station control button press.
         */
        handleStationControl: function() {
            // Due to throttling the second event is undefined
            if (!d3.event) {
                return;
            }

            const d = d3.event.currentTarget;
            const id = d.parentElement.getAttribute('data-id').replace('sn', '');

            if (d.classList.contains('control')) {
                this.matrixService.controlStation(id, 0);
            } else {
                this.matrixService.controlStation(id, 1);
            }
        },

        /**
         * Start dragging file from file list.
         * Create drag element.
         *
         * @param d File element
         */
        onDragFileStart: function(d) {
            const pos = this.matrixService.convertCoordinates(
                {
                    x: d.getAttribute('cx'),
                    y: d.getAttribute('cy')
                },
                parseInt(this.configs.get('dimensions.width')),
                parseInt(this.configs.get('dimensions.height')),
                this.$svg.width(),
                this.$svg.height()
            );

            this.drawDragFile(d, pos.x, pos.y);
        },

        /**
         * Drag file and highlight element to drop to.
         *
         * @param d File element
         * @param offsetTop Offset top to correct position of drag element.
         * @param offsetLeft Offset left to correct position of drag element.
         */
        onDragFile: function() {
            if (this.showAllPreviews) {
                app.emit('matrix.previews.close');
            }

            this.dragFile
                .attr('x', d3.mouse(this.svg._groups[0][0])[0] - parseFloat(this.dragFile.attr('width')) / 2)
                .attr('y', d3.mouse(this.svg._groups[0][0])[1] - parseFloat(this.dragFile.attr('height')) / 2)
                .attr('cx', d3.mouse(this.svg._groups[0][0])[0])
                .attr('cy', d3.mouse(this.svg._groups[0][0])[1]);

            const stationId = this.getDropZoneStationId([this.dragFile.attr('cx'), this.dragFile.attr('cy')]);

            if (stationId !== this.dropZoneStationId) {
                this.svg.selectAll('#' + this.dropZoneStationId).select('.svg-station').classed('drop-zone', false);
                this.svg.selectAll('#' + stationId).select('.svg-station').classed('drop-zone', true);

                this.dropZoneStationId = stationId;
            }
        },

        /**
         * Drag file end / drop file.
         *
         * @param d File element
         * @param drag true/false
         */
        onDragFileEnd: function(d, drag) {
            if (drag) {
                const filePath = this.URIService.decode(d.getAttribute('data-path'));
                const stationId = this.getDropZoneStationId([this.dragFile.attr('cx'), this.dragFile.attr('cy')]);

                if (stationId) {
                    this.matrixService.shareFile(stationId.replace('sn', ''), filePath);
                }
            }

            this.$svg.find('#clone-element').empty();

            this.dragFile = null;
            this.svg.selectAll('#' + this.dropZoneStationId).select('.svg-station').classed('drop-zone', false);
            this.dropZoneStationId = null;
        },

        /**
         * Get id/serial from destination station when coaching is active.
         */
        getCoachingDestId: function() {
            const id = this.$svg.find('.matrix-circle.coachingDest').parent().parent().attr('id');

            if (!id) {
                return;
            }

            return id.replace('sn', '');
        },

        /**
         * Get id/serial from source station when coaching is active.
         */
        getCoachingSrcId: function() {
            const id = this.$svg.find('.matrix-circle.coachingSrc').parent().parent().attr('id');

            if (!id) {
                return;
            }

            return id.replace('sn', '');
        },

        /**
         * Get station ID from station connect button, where dragged (connect btn (push)) is currently dragged over.
         *
         * @param dragged
         * @returns {*}
         */
        getDropZoneConnectBtnId: function(dragged, idSrc, includeReceiver) {
            let id = null;
            const stations = this.svg.selectAll('.station').filter(
                function() {
                    return !this.getAttribute('id').startsWith('master')
                        && this.getAttribute('id').replace('sn', '') !== idSrc
                        && !this.getAttribute('id').startsWith('stream')
                        && (includeReceiver || this.getAttribute('model') !== 'CPR');
                });

            _.each(stations.selectAll('.matrix-circle.menu-btn[data-action="connect"]')._groups, function(b) {
                const btn = b[0].parentElement;

                const rotatedXY = this.matrixService.calculateRotatedPosition(
                    parseInt(btn.parentElement.getAttribute('cx')),
                    parseInt(btn.parentElement.getAttribute('cy')),
                    parseInt(btn.getAttribute('cx')),
                    parseInt(btn.getAttribute('cy')),
                    parseFloat(btn.parentElement.getAttribute('angle')));

                if (this.matrixService.pointInCircle(
                    dragged[0],
                    dragged[1],
                    rotatedXY[0],
                    rotatedXY[1],
                    parseFloat(btn.getAttribute('width')) / 2,
                    parseFloat(btn.getAttribute('height')) / 2
                )) {
                    id = btn.getAttribute('data-id');
                }
            }.bind(this));

            return id;
        },

        /**
         * Get station ID from station, where dragged (file or connect btn (push)) is currently dragged over.
         *
         * @param dragged
         * @returns {*} Station ID
         */
        getDropZoneStationId: function(dragged, idSrc, includeReceiver) {
            let id = null;
            const stations = this.svg.selectAll('.station').filter(
                function() {
                    return !this.getAttribute('id').startsWith('master')
                        && this.getAttribute('id').replace('sn', '') !== idSrc
                        && !this.getAttribute('id').startsWith('stream')
                        && (includeReceiver || this.getAttribute('model') !== 'CPR');
                });

            _.each(stations._groups[0], function(stationNode) {
                const station = d3.select(stationNode);
                const stationForm = station.select('.svg-station');

                if (station.classed('inactive')) {
                    return;
                }

                switch (station.attr('form')) {
                    case 'rectangle':
                        if (this.pointInRectangle(
                            dragged[0],
                            dragged[1],
                            stationForm.attr('x'),
                            stationForm.attr('y'),
                            stationForm.attr('width'),
                            stationForm.attr('height'),
                            stationForm.attr('angle')
                        )) {
                            id = station.attr('id');
                        }
                        break;
                    case 'circle':
                        if (this.matrixService.pointInCircle(
                            dragged[0],
                            dragged[1],
                            stationForm.attr('cx'),
                            stationForm.attr('cy'),
                            stationForm.attr('rx'),
                            stationForm.attr('ry'),
                            stationForm.attr('angle')
                        )) {
                            id = station.attr('id');
                        }
                        break;
                }
            }.bind(this));

            return id;
        },

        /**
         * Draw drag file.
         *
         * @param d File element
         * @param cx Current position center x
         * @param cy Current position center y
         */
        drawDragFile: function(d, cx, cy) {
            this.$svg.find('#clone-element').append(d);
            this.dragFile = this.svg.select('#clone-element').select('.svg-grid-file');
            this.dragFile.select('.btn-share').style('visibility', 'hidden');

            const factor = platform.checks.isSmallSize() ? 8 : platform.checks.isMediumSize() ? 6 : 4;
            const width = factor * parseInt(this.dragFile.attr('width'));
            const height = factor * parseInt(this.dragFile.attr('height'));

            this.dragFile
                .attr('width', width)
                .attr('height', height)
                .attr('x', cx - width / 2)
                .attr('y', cy - height / 2)
                .attr('cx', cx)
                .attr('cy', cy)
                .select('.file-box-item')
                .classed('drag-file', true)
                .style('width', width + 'px')
                .style('height', height + 'px');
        },

        /**
         * Start moving a Master/Station.
         *
         * @param d {Object} master/station element
         */
        onMoveStart: function(d) {
            // RELEASE-1993 - Red pointer appears when sliding a finger on the screen.
            app.emit('custom-cursor.disable');

            // Multitouch workaround
            if (this.move) {
                this.preventTouchEvents.push(d);

                return;
            }

            if (this.moveStart) {
                return;
            }

            this.moveStart = d;
            d = d.parentElement;
            $(d).addClass('dragged');

            const isMaster = $(d).attr('data-id').startsWith('master');
            if (isMaster) {
                this.updateMaster(false);
                this.updateStations(true);
            } else {
                this.updateMaster(true);
                app.emit('matrix.pull.start');
            }

            this.clone = this.$svg.find('.station[id=' + d.parentElement.getAttribute('id') + ']').clone();
            this.$svg.find('#clone-element').append(this.clone);

            // Hide station
            if (isMaster) {
                this.clone.children().filter(':not(.btn-center)').hide();
            } else {
                $(d.parentElement).children().hide();

                // Remove rotation while dragging
                d3.select(d.parentElement).attr('transform', '');
                d3.select(d).attr('transform', '');
            }

            this.svg.select('#clone-element').append('path')
                .attr('class', 'drag-line');

            // Workaround for touch devices using edge --> raising elements to front does not work as expected
            if (platform.checks.isEdge && platform.checks.isTouchDevice) {
                return;
            }

            // Check if a connection is active, disable further drags
            if (this.clone.find('.icon-close').length === 1) {
                this.connectionActive = true;
            }

            // Move selected element to front.
            if (!platform.checks.isIOS13) { // Special case for iOS13 devices
                d3.selectAll('.station[id=' + d.getAttribute('data-id') + ']').raise();
            }
        },

        /**
         * Move Master/Station.
         *
         * @param d {Object} master/station element
         */
        onMove: function(d) {
            // Multitouch workaround
            if ($.inArray(d, this.preventTouchEvents) >= 0
                || (d3.event.dx === 0 && d3.event.dy === 0)
                || !this.moveStart || !_.isEqual(this.moveStart, d)
                || this.groupworkActive) {
                return;
            }

            if (!this.move) {
                app.emit('matrix-main.hide.menus');
                this.updateMaster(false);
            }

            this.move = true;

            if (this.connectionActive) {
                return;
            }

            d = d.parentElement;
            const radius = parseInt(d.getAttribute('width')) / 2;
            $(d).show();

            if (this.isDualProjection && !d.parentElement.id.startsWith('master')) {
                this.setDropzone(d.parentElement);
            }

            d3.selectAll(d)
                .attr('x', d.attributes.x.value = d3.mouse(d.parentElement)[0] - radius)
                .attr('y', d.attributes.y.value = d3.mouse(d.parentElement)[1] - radius)
                .attr('cx', d.attributes.cx.value = d3.mouse(d.parentElement)[0])
                .attr('cy', d.attributes.cy.value = d3.mouse(d.parentElement)[1]);

            this.setDraggable($(d).find('.matrix-circle'));
            this.drawDragLine(this.clone, $(d));
        },

        /**
         * Draw line when btn is dragged for push/pull.
         *
         * @param startPt Start point on station button
         * @param endPt End point on dragged element
         */
        drawDragLine: function(startEl, endEl) {
            this.line = d3.line();

            const startPt = this.matrixService.calculateRotatedPosition(
                parseInt(startEl.attr('cx')),
                parseInt(startEl.attr('cy')),
                parseInt(startEl.find('.btn-center').attr('cx')),
                parseInt(startEl.find('.btn-center').attr('cy')),
                parseFloat(startEl.attr('angle'))
            );

            const endPt = [parseInt(endEl.attr('cx')), parseInt(endEl.attr('cy'))];

            this.svg.select('#clone-element').select('.drag-line')
                .attr('d', this.line([startPt, endPt]))
                .attr('stroke', this.clone.attr('color'))
                .attr('stroke-width', '5px');
        },

        /**
         * End moving Station.
         *
         * @param d {Object} station element
         */
        onMoveStationEnd: function(d) {
            // RELEASE-1993 - Red pointer appears when sliding a finger on the screen.
            app.emit('custom-cursor.enable');

            // Multitouch workaround
            if ($.inArray(d, this.preventTouchEvents) >= 0) {
                this.preventTouchEvents.splice($.inArray(d, this.preventTouchEvents), 1);

                return;
            }

            if (!this.moveStart || !_.isEqual(this.moveStart, d)) {
                return;
            }

            $(d.parentElement).removeClass('dragged');

            const id = d3.event.subject.parentElement.getAttribute('data-id').replace('sn', '');
            const btn = this.clone.find('.btn-center');
            const btnIcon = btn.find('.icon');
            const station = d.parentElement.parentElement;

            if (this.move) {
                if (!this.connectionActive) {
                    this.masterLoop(function(master) {
                        const connectToMaster = this.matrixService.pointInCircle(
                            d3.mouse(station)[0],
                            d3.mouse(station)[1],
                            master.select('.btn-center').attr('cx'),
                            master.select('.btn-center').attr('cy'),
                            parseFloat(master.select('.btn-center').attr('width')) / 2,
                            parseFloat(master.select('.btn-center').attr('height')) / 2
                        ) || (this.isDualProjection && this.pointInRectangle(
                            d3.mouse(station)[0],
                            d3.mouse(station)[1],
                            master.select('.svg-station').attr('x'),
                            master.select('.svg-station').attr('y'),
                            parseFloat(master.select('.svg-station').attr('width')),
                            parseFloat(master.select('.svg-station').attr('height'))
                        ));

                        if (connectToMaster) { // Station to master
                            if (this.isDualProjection) {
                                if (this.replaceAppId !== null && !master.classed('mirroring-active')) {
                                    this.matrixService.setMatrixMasterPull(id, 1, master.attr('id').replace('master-', ''), this.replaceAppId);
                                }
                                this.setCurrentDropzone(null);
                                this.replaceAppId = 0;
                            } else {
                                this.matrixService.setMatrixMasterPull(id, 1);
                            }
                        } else { // COACHING: station to station push
                            const pushId = this.getDropZoneConnectBtnId(d3.mouse(station), id)
                            || this.getDropZoneStationId(d3.mouse(station), id);

                            if (pushId) {
                                this.matrixService.setMatrixStationToStationPush(id, pushId.replace('sn', ''), 1);
                            }
                        }

                        $(d).find('.icon').attr('class', btnIcon.attr('class'));

                        d3.selectAll(d)
                            .attr('x', d.parentElement.attributes.x.value = btn.attr('x'))
                            .attr('y', d.parentElement.attributes.y.value = btn.attr('y'))
                            .attr('cx', d.parentElement.attributes.cx.value = btn.attr('cx'))
                            .attr('cy', d.parentElement.attributes.cy.value = btn.attr('cy'));
                    }.bind(this));

                    app.emit('main-loop.fast.start', {
                        id: 'matrix'
                    });
                }
            } else if (d.classList.contains('push')) {
                this.matrixService.setMatrixMasterPush(id, 0);
            } else if (d.classList.contains('pull')) {
                this.matrixService.setMatrixMasterPull(id, 0);
            } else if (d.classList.contains('coachingSrc')) {
                this.matrixService.setMatrixStationToStationPush(id, this.getCoachingDestId(), 0);
            } else if (d.classList.contains('coachingDest')) {
                this.matrixService.setMatrixStationToStationPush(this.getCoachingSrcId(), id, 0);
            }

            // Add rotation on drag end
            d3.select(station).attr('transform', this.clone.attr('transform'));
            d3.select(d.parentElement).attr('transform', btn.attr('transform'));

            this.removeDraggable($(d));
            this.$svg.find('#clone-element').empty();
            this.updateMaster();

            // Show station
            $(station).find('.btn-center').show();
            $(station).find('.svg-station').show();
            $(station).find('.station-name-container').show();

            // Show stream button
            if (this.clone.find('.btn-remove').css('display') !== 'none') {
                $(station).find('.btn-remove').show();
            }

            // Show document button
            if (this.clone.find('.btn-docs').css('display') !== 'none') {
                $(station).find('.btn-docs').show();
            }

            // Show control button
            if (this.clone.find('.btn-control').css('display') !== 'none') {
                $(station).find('.btn-control').show();
            }

            // Show control button
            if (this.clone.find('.station-info-container').css('display') !== 'none') {
                $(station).find('.station-info-container').show();
            }

            app.emit('matrix.pull.end');

            this.moveStart = null;
            this.connectionActive = false;
            this.move = false;
            this.clone = null;

            app.emit('main-loop.fast.start', {
                id: 'matrix'
            });
        },

        /**
         * End moving Master.
         *
         * @param d {Object} master element
         */
        onMoveMasterEnd: function(d) {
            // RELEASE-1993 - Red pointer appears when sliding a finger on the screen.
            app.emit('custom-cursor.enable');

            if ($.inArray(d, this.preventTouchEvents) >= 0) {
                this.preventTouchEvents.splice($.inArray(d, this.preventTouchEvents), 1);

                return;
            }

            if (!this.moveStart || !_.isEqual(this.moveStart, d)) {
                return;
            }

            $(d.parentElement).removeClass('dragged');

            const btn = this.clone.find('.btn-center');

            const master = d.parentElement.parentElement;
            if (this.move) {
                const pushId = this.getDropZoneConnectBtnId(d3.mouse(master), undefined, true)
                    || this.getDropZoneStationId(d3.mouse(master), undefined, true);

                if (pushId) {
                    const output = this.isDualProjection ? master.id.replace('master-', '') : 'hdmi1';
                    this.matrixService.setMatrixMasterPush(pushId.replace('sn', ''), 1, output);

                    app.emit('main-loop.fast.start', {
                        id: 'matrix'
                    });
                }

                d3.selectAll(d)
                    .attr('x', d.parentElement.attributes.x.value = btn.attr('x'))
                    .attr('y', d.parentElement.attributes.y.value = btn.attr('y'))
                    .attr('cx', d.parentElement.attributes.cx.value = btn.attr('cx'))
                    .attr('cy', d.parentElement.attributes.cy.value = btn.attr('cy'));

                this.updateMaster();
            } else if (!this.groupworkActive) {
                app.emit('master-interaction.show', { masterId: master.id });
            }

            // Add rotation
            d3.select(master).attr('transform', this.clone.attr('transform'));
            d3.select(d.parentElement).attr('transform', btn.attr('transform'));

            this.removeDraggable($(d));
            this.$svg.find('#clone-element').empty();
            this.updateStations(false);

            // Show station
            // $(master).children().show();
            $(master).find('.btn-center').show();
            $(master).find('.svg-station').show();
            $(master).find('.station-name-container').show();

            this.moveStart = null;
            this.move = false;
            this.clone = null;
        },

        /**
         * Set button draggable and set color.
         *
         * @param el Button element
         */
        setDraggable: function(el) {
            const colorIcon = this.clone.attr('color');
            const colorBtn = colorIcon.replace(/[^,]+(?=\))/, ' 0.2'); // Set opacity to 0.2

            el.addClass('dragging')
                .find('.icon')
                .addClass('icon-connector')
                .removeClass('icon-close');

            if (!platform.checks.isIOS && !platform.checks.isFirefox && !platform.checks.isSafari) {
                el.css('box-shadow', '0 0 0 100px ' + colorBtn);
            }

            el.css('background-color', colorBtn)
                .find('.icon').css('color', colorIcon);
        },

        /**
         * Remove draggable and reset color.
         *
         * @param el Button element
         */
        removeDraggable: function(el) {
            const btn = $(this.clone).find('.btn-center');
            const colorBtn = btn.find('.matrix-circle').css('background-color');
            const colorIcon = btn.find('.icon').css('color');

            el.removeClass('dragging');
            el.css('box-shadow', 'none')
                .css('background-color', colorBtn)
                .find('.icon').css('color', colorIcon);
        },

        setDropzone: function(draggedStation) {
            const svgPos = this.svgPosToPxl(d3.mouse(draggedStation)[0], d3.mouse(draggedStation)[1], true);
            const element = document.elementFromPoint(svgPos.x, svgPos.y);

            const dropzone = this.parentHasAttribute(element, 'data-drop-action', this.svgNode);
            let dropzoneId = null;

            if (dropzone) {
                if (dropzone.classList.contains('btn-center')) {
                    // Activate dropzone when hovering over btn-center
                    dropzoneId = 'master-dropzone-' + dropzone.parentElement.id.replace('master-', '');
                } else {
                    dropzoneId = dropzone.id;
                }
            }

            this.setCurrentDropzone(dropzoneId);
        },

        /**
         * Convert svg coordinates to pixel and return element at position
         *
         * @param x Position x
         * @param y Position y
         * @param global Boolean whether the x and y are global or from svg viewPort
         */
        svgPosToPxl: function(x, y, global = true) {
            const dp = DOMPoint.fromPoint({ x, y });

            const ctm = global ? this.svgNode.getScreenCTM() : this.svgNode.getCTM();

            return dp.matrixTransform(ctm);
        },

        /**
         * Convert svg coordinates to pixel and return element at position
         *
         * @param el Element with attribute
         * @param attrName Attribute name
         * @param endElement Up to which element to search for
         */
        parentHasAttribute: function(el, attrName, endElement) {
            if (!el || el.hasAttribute(attrName)) {
                return el;
            }

            while (el.parentNode && endElement.contains(el.parentNode)) {
                el = el.parentNode;
                if (el.hasAttribute(attrName)) {
                    return el;
                }
            }

            return null;
        },

        /**
         * Check if point is within rectangle.
         *
         * @param posX Current position x
         * @param posY Current position y
         * @param x Position X
         * @param y Position Y
         * @param width Width
         * @param height Height
         * @param angle Angle
         * @returns {boolean} True - point in rectangle, False - point NOT in rectangle
         */
        pointInRectangle: function(posX, posY, x, y, width, height, angle) {
            posX = parseFloat(posX);
            posY = parseFloat(posY);
            x = parseFloat(x);
            y = parseFloat(y);
            width = parseFloat(width);
            height = parseFloat(height);
            angle = parseFloat(angle);
            let rotatedPos = [posX, posY];

            if (angle && angle !== 0) {
                rotatedPos = this.matrixService.calculateRotatedPosition(x + width / 2, y + height / 2, posX, posY, angle * -1);
            }

            posX = rotatedPos[0];
            posY = rotatedPos[1];
            x = parseFloat(x);
            y = parseFloat(y);
            const x2 = x + parseFloat(width);
            const y2 = y + parseFloat(height);

            return posX >= x && posX <= x2 && posY >= y && posY <= y2;
        },

        /**
         * Update matrix station audio states.
         *
         * @param audioStatus
         */
        updateAudio: function(audioStatus) {
            this.audioStatus = audioStatus.stationAudio;
        },

        /**
         * Check if update connection is needed.
         * Check if update of file button is needed.
         *
         * @param statusList State list of all configurated stations
         * @param fileList File list (shared files) of all configurated stations
         * @param coaching Coaching state (src / dest station)
         * @param groupworkEnabled Is groupwork mode enabled (true/false)
         * @param groupworkActive Is groupwork active (true/false)
         */
        updateHandler: function(statusList, fileList, coaching, groupworkEnabled, groupworkActive) {
            if (!this.svg) {
                return;
            }

            /**
             * If waiting dialog is shown, check if all stations are already powered on/off
             * or are in use by another station and close modal.
             */
            if (this.modalHandlerService.getOpenModalId() === 'matrix' && this.statusData && this.statusData.length > 0) {
                const presentStations = $.grep(this.statusData, function(s) {
                    return s.present === this.powerState.getState() || s.masterName.length > 0;
                }.bind(this));

                if (presentStations.length === this.statusData.length) {
                    this.powerState.changeState(powerStates.none);

                    app.emit('modal.close', {
                        id: 'matrix'
                    });

                    this.powerEnd = 0;
                    this.setupEnd = 0;
                }
            }

            let updateGroupwork = false;
            this.groupworkEnabled = groupworkEnabled;

            if (this.groupworkActive !== groupworkActive) {
                this.groupworkActive = groupworkActive;
                app.emit('matrix.groupwork-mode.changed', groupworkActive);
                updateGroupwork = true;
            }

            if (!_.isEqual(this.statusData, statusList) || updateGroupwork) {
                const renameStations = [];
                let updateCoaching = false;

                this.connected = false;

                // Ensure that coaching stations are updated.
                if (!_.isEqual(this.coaching, coaching)) {
                    updateCoaching = true;
                }

                this.coaching = coaching;

                // Create fake array to init a station update
                if (this.statusData === null) {
                    this.statusData = statusList;
                }

                _.each(statusList, function(station) {
                    const compareStation = $.grep(this.statusData, function(e) {
                        return station.serial === e.serial;
                    });

                    if (!this.svg.select('.station[id=sn' + station.serial + ']')._groups[0][0]) {
                        return;
                    }

                    if (this.configs.updateStationName(station)) {
                        renameStations.push(station);
                    }

                    if ((compareStation.length > 0 && !_.isEqual(station, compareStation[0]))
                        || this.init
                        || updateGroupwork
                        || ((station.pushIndex === 254 || station.pushIndex === 253) && updateCoaching)
                    ) {
                        this.updateConnection(station);

                        this.configs.getMatrixControlMode()
                            .then(function(mode) {
                                if (mode) {
                                    this.updateControlButton(station);
                                } else {
                                    this.hideMenuButton(this.svg, 'btn-color');
                                }
                            }.bind(this));

                        if ((compareStation[0].present !== station.present) || this.init || updateGroupwork) {
                            this.updateStationStatus(station);
                        }
                    }
                }.bind(this));

                if (renameStations.length > 0) {
                    this.configs.renameStations(renameStations);
                }

                this.updateMaster();

                this.statusData = statusList;

                this.init = false;
            }

            _.each(fileList, function(stationList) {
                this.matrixService.getFiles(this.configs.get('filesharing.path') + stationList.name)
                    .then(function(data) {
                        const station = this.svg.selectAll('#stations-layer').select('#sn' + stationList.name);
                        if (data.fileList.length <= 0 || station.classed('inactive')
                            || (this.clone && station.attr('id') === this.clone.attr('id'))) {
                            this.hideMenuButton(station, 'btn-docs');
                            app.emit('matrix-main.remove.files.menu', station._groups[0][0]);
                        } else {
                            this.showMenuButton(station, 'btn-docs');
                            this.setDocsCounter(station, data.fileList.length);
                        }
                    }.bind(this));
            }.bind(this));
        },

        /**
         * Timer to redraw master animation.
         *
         */
        redrawAnimation: function() {
            const start = Date.now();

            const timer = setInterval(function() {
                // How much time passed from the start?
                const timePassed = Date.now() - start;

                if (timePassed > 1000) {
                    clearInterval(timer); // Finish the animation after 1 second

                    return;
                }

                // Draw the animation at the moment timePassed
                this.draw(timePassed);
            }.bind(this), 50);
        },

        /**
         * Draw Master animation.
         *
         * @param timePassed time in ms to calculate the box-shadow size and transparency
         */
        draw: function(timePassed) {
            this.masterLoop(function(master) {
                const circle = master.select('.btn-center .matrix-circle[data-action="connect"]');
                if (1000 - timePassed <= 50) {
                    circle.style('box-shadow', '0 0 0 0 transparent');
                    circle.style('-webkit-box-shadow', '0 0 0 0 transparent');
                    circle.style('-moz-box-shadow', '0 0 0 0 transparent');
                } else {
                    // Replace alpha parameter of css rgba with correct opacity
                    const color = master.attr('color').replace(/[\d.]+(?=\))/, (0.2 - (0.2 * timePassed / 1000)).toString());
                    // Calculation: https://www.wolframalpha.com/input/?i=0.2+-+(0.2+*+(100%2F1000*x)%2F+100)
                    circle.style('box-shadow', '0 0 0 ' + (timePassed / 10) + 'px ' + color);
                    circle.style('-webkit-box-shadow', '0 0 0 ' + (timePassed / 10) + 'px  ' + color);
                    circle.style('-moz-box-shadow', '0 0 0 ' + (timePassed / 10) + 'px  ' + color);
                }
            }.bind(this));
        },

        /**
         * Update master.
         * Master should pulsate to animate the user to interact if nothing is connected.
         *
         * @param pulsating Pulsate master true/false
         */
        updateMaster: function(pulsating) {
            pulsating = this.groupworkActive ? false : pulsating;

            switch (pulsating) {
                case true:
                    this.animationEngine.start({
                        fps: animationFps
                    });
                    break;
                case false:
                    this.animationEngine.stop();
                    break;
                default:
                    if (this.connected) {
                        this.animationEngine.stop();
                    } else {
                        this.animationEngine.start({
                            fps: animationFps
                        });
                    }
                    break;
            }
        },

        /**
         * Update stations.
         * Free stations to connect to should pulsate if master is dragged.
         *
         * @param pulsating Pulsate stations true/false
         */
        updateStations: function(pulsating) {
            const stations = this.$svg.find('.station:not(.master)').find('.matrix-circle.menu-btn[data-action="connect"]')
                .not('.push')
                .not('.pull');

            switch (pulsating) {
                case true:
                    stations.addClass('pulsating');
                    break;
                case false:
                case undefined:
                    stations.removeClass('pulsating');
                    break;
            }
        },

        /**
         * Update Station present status.
         *
         * @param station {object}
         */
        updateStationStatus: function(station) {
            const stationMenu = this.svg.select('.btn-center[data-id=sn' + station.serial + ']:not(.btn-remove)');
            const stationGroup = this.svg.select('.station[id=sn' + station.serial + ']');

            if (station.present === 1 && !this.groupworkActive) {
                stationMenu.style('display', 'block');
                stationGroup.style('fill-opacity', 1);
                stationGroup.classed('inactive', false);
                stationGroup.select('.station-info-container').remove();

                if (station.compatibility === 3) {
                    this.showOutdatedMasterInfo();
                } else if (station.compatibility !== 0) {
                    this.addStationInfo(station, i18n.t('matrix.compatibility_' + station.compatibility));
                } else if (stationGroup._groups[0][0].getAttribute('model') === 'CPR') {
                    this.addStationInfo(station, '', 'icon-v3-pure-receiver');
                }
            } else {
                stationMenu.style('display', 'none');
                stationGroup.style('fill-opacity', 0.2);
                stationGroup.classed('inactive', true);

                if (station.masterName) {
                    this.addStationInfo(station, i18n.t('matrix.in_use_by') + station.masterName);
                } else if (station.compatibility === 3) {
                    this.showOutdatedMasterInfo();
                } else if (station.compatibility !== 0) {
                    this.addStationInfo(station, i18n.t('matrix.compatibility_' + station.compatibility));
                } else if (stationGroup._groups[0][0].getAttribute('model') === 'CPR') {
                    this.addStationInfo(station, '', '');
                }
            }

            this.updateConnection(station);
        },

        /**
         * Show info dialog if master is out of date.
         */
        showOutdatedMasterInfo: function() {
            app.emit('modal.open', {
                id: 'info',
                messageKey: 'matrix.compatibility_3',
                additionalMessageKey: 'matrix.compatibility_info_3',
                backdropIndex: 107
            });
        },

        /**
         * Add info to disabled box that it's used by another presenter.
         *
         * @param station Current station
         */
        addStationInfo: function(station, infoMsg, infoIcon) {
            const activeElement = this.svg.select('.station[id=sn' + station.serial + ']');

            if (activeElement.select('.station-info-container')._groups[0][0]) {
                activeElement.select('.station-info-container').remove();
            }

            const container = this.$stationInfoContainer.clone(true);
            const cx = parseInt(activeElement.attr('cx'));
            const cy = parseInt(activeElement.attr('cy'));
            const nameHeight = parseInt(activeElement.select('.station-name-container').attr('height'));
            const infoWidth = parseInt(activeElement.select('.svg-station').attr('width'));

            if (infoMsg) {
                container.find('.matrix-station-info').text(infoMsg);
            }

            if (infoIcon) {
                container.find('.matrix-station-info').addClass(infoIcon);
            }

            const info = activeElement.append('svg:foreignObject')
                .attr('class', 'station-info-container')
                .attr('x', cx - infoWidth / 2)
                .attr('y', cy + nameHeight)
                .attr('cx', cx)
                .attr('cy', cy)
                .attr('width', infoWidth)
                .attr('height', infoHeight)
                .attr('transform', 'rotate(' + this.matrixService.toDegrees(activeElement.attr('angle'), true) * -1 + ',' + cx + ',' + cy + ')')
                .html(container.get(0).innerHTML);

            activeElement.select('.station-info-container').data(info._groups[0]);
        },

        /**
         * Update Connection line.
         *
         * @param station {object}
         */
        updateConnection: function(station) {
            const state = this.getPushPullState(station);
            const selectedStation = this.svg.select('.station[id=sn' + station.serial + ']');
            const connections = this.svg.select('#connections-layer').selectAll('[id|=sn' + station.serial + ']')._groups[0];
            let btn;

            switch (state) {
                case pushPullState.push:
                    selectedStation.select('.btn-remove[data-id=sn' + station.serial + ']').style('display', 'none');
                    selectedStation.select('.svg-station').style('stroke', null);

                    _.each(connections, function(connectionNode) {
                        const connection = d3.select(connectionNode);
                        // Draws line only when dual projection and active connection
                        // Or when not dual projection and connection does not include "hdmi1" (never included since no such destinction in normal mode)
                        if (this.isDualProjection === connectionNode.id.includes(outputMapping[station.pushIndex])) {
                            connection.select('path.line').style('display', 'block');
                            connection.select('path.line').classed('push', true);
                            connection.select('path.line').classed('pull', false);
                            connection.select('path.line').classed('coachingSrc', false);
                            connection.select('path.line').classed('coachingDest', false);
                            connection.select('path.line').style('stroke-dasharray', '');
                            connection.select('path.line').style('stroke', connection.attr('master-color') || '#F59A22');
                            connection.selectAll('.arrow.push').style('fill', connection.attr('master-color') || '#F59A22');
                            connection.selectAll('.arrow.push').style('display', 'block');
                            connection.selectAll('.arrow.pull').style('display', 'none');
                        } else {
                            this.hideConnection(connection);
                        }
                    }.bind(this));

                    this.connected = true;
                    break;
                case pushPullState.pushStream:
                    btn = selectedStation.select('.btn-remove[data-id=sn' + station.serial + ']');
                    btn.select('.icon').attr('class', 'icon stream ' + this.matrixService.getStreamIcon(this.svg.select('.btn-stream').attr('data-type')));
                    btn.style('display', 'block');

                    selectedStation.select('.svg-station').style('stroke', '#F59A22');
                    selectedStation.select('.svg-station').style('stroke-width', '5');
                    selectedStation.select('.svg-station').style('stroke-dasharray', '');
                    _.each(connections, function(connectionNode) {
                        const connection = d3.select(connectionNode);
                        connection.select('path.line').style('display', 'none');
                        connection.select('path.line').classed('push', false);
                        connection.select('path.line').classed('pull', false);
                        connection.select('path.line').classed('coachingSrc', false);
                        connection.select('path.line').classed('coachingDest', false);
                        connection.select('path.line').style('stroke-dasharray', '');
                        connection.select('path.line').style('stroke', '#F59A22');
                        connection.selectAll('.arrow').style('display', 'none');
                    }.bind(this));
                    break;
                case pushPullState.coachingSrc:
                    btn = selectedStation.select('.btn-remove[data-id=sn' + station.serial + ']');
                    btn.select('.icon').attr('class', 'icon coaching icon-v2-moderator-mode-on');
                    btn.style('display', 'block');

                    selectedStation.select('.svg-station').style('stroke', this.svg.select('.station[id=sn' + station.serial + ']').attr('color'));
                    selectedStation.select('.svg-station').style('stroke-width', '8');
                    _.each(connections, function(connectionNode) {
                        const connection = d3.select(connectionNode);
                        connection.select('path.line').style('display', 'none');
                        connection.select('path.line').classed('push', false);
                        connection.select('path.line').classed('pull', false);
                        connection.select('path.line').classed('coachingSrc', true);
                        connection.select('path.line').classed('coachingDest', false);
                        connection.select('path.line').style('stroke-dasharray', '');
                        connection.select('path.line').style('stroke', '#F59A22');
                        connection.selectAll('.arrow').style('display', 'none');
                    }.bind(this));
                    break;
                case pushPullState.coachingDest: {
                    const colorSrc = this.svg.select('.station[id=sn' + this.coaching.src + ']').attr('color');

                    btn = selectedStation.select('.btn-remove[data-id=sn' + station.serial + ']');
                    btn.select('.icon').attr('class', 'icon coaching icon-v2-moderator-mode-off');
                    btn.style('display', 'block');

                    selectedStation.select('.svg-station').style('stroke', colorSrc);
                    selectedStation.select('.svg-station').style('stroke-width', '8');
                    _.each(connections, function(connectionNode) {
                        const connection = d3.select(connectionNode);
                        connection.select('path.line').style('display', 'none');
                        connection.select('path.line').classed('push', false);
                        connection.select('path.line').classed('pull', false);
                        connection.select('path.line').classed('coachingSrc', false);
                        connection.select('path.line').classed('coachingDest', true);
                        connection.select('path.line').style('stroke-dasharray', '');
                        connection.select('path.line').style('stroke', '#F59A22');
                        connection.selectAll('.arrow').style('display', 'none');
                    }.bind(this));
                    break;
                }
                case pushPullState.pull:
                    selectedStation.select('.btn-remove[data-id=sn' + station.serial + ']').style('display', 'none');
                    selectedStation.select('.svg-station').style('stroke', null);

                    _.each(connections, function(connectionNode) {
                        const connection = d3.select(connectionNode);
                        if (this.isDualProjection === connectionNode.id.includes(station.pullOutput)) {
                            connection.select('path.line').style('display', 'block');
                            connection.select('path.line').classed('pull', true);
                            connection.select('path.line').classed('push', false);
                            connection.select('path.line').classed('coachingSrc', false);
                            connection.select('path.line').classed('coachingDest', false);
                            connection.select('path.line').style('stroke-dasharray', '15,15');
                            connection.select('path.line').style('stroke', connection.attr('color'));
                            connection.selectAll('.arrow.push').style('display', 'none');
                            connection.selectAll('.arrow.pull').style('display', 'block');
                        } else {
                            this.hideConnection(connection);
                        }
                    }.bind(this));
                    this.connected = true;
                    break;
                case pushPullState.none:
                    selectedStation.select('.btn-remove[data-id=sn' + station.serial + ']').style('display', 'none');
                    selectedStation.select('.svg-station').style('stroke', null);

                    _.each(connections, function(connectionNode) {
                        this.hideConnection(d3.select(connectionNode));
                    }.bind(this));
                    break;
            }

            this.updateMenu(station, state);
        },

        hideConnection: function(connection) {
            connection.select('path.line').style('display', 'none');
            connection.select('path.line').classed('push', false);
            connection.select('path.line').classed('pull', false);
            connection.select('path.line').classed('coachingSrc', false);
            connection.select('path.line').classed('coachingDest', false);
            connection.select('path.line').style('stroke-dasharray', '');
            connection.select('path.line').style('stroke', '#F59A22');
            connection.selectAll('.arrow').style('display', 'none');
        },

        /**
         * Update control button.
         *
         * @param station {object}
         */
        updateControlButton: function(station) {
            const group = this.svg.select('.station[id=sn' + station.serial + ']')._groups[0][0];

            if (!group) {
                return;
            }

            let control = this.svg.selectAll('.btn-control[data-id=sn' + station.serial + ']');

            // RELEASE-2849: If "old" configuration isn't updated after feature Matrix Control Switch is added
            if (control._groups[0].length === 0) {
                control = this.svg.selectAll('.btn-color[data-id=sn' + station.serial + ']');
                control.classed('btn-control', true);
            }

            const color = group.getAttribute('color');
            const pullState = this.getPushPullState(station);
            const controlState = station.accessLevel;

            if (pullState === pushPullState.pull) {
                switch (controlState) {
                    case controlStates.control:
                        control.select('button').classed('control', true);
                        control.select('button').style('background-color', color);
                        control.select('.icon').style('color', '#EDEFF5');
                        control.select('.icon').classed('icon-lock-open', false);
                        control.select('.icon').classed('icon-lock', true);
                        break;
                    case controlStates.limited:
                    case controlStates.none:
                        control.select('button').classed('control', false);
                        control.select('button').style('background-color', '#EDEFF5');
                        control.select('.icon').style('color', color);
                        control.select('.icon').classed('icon-lock-open', true);
                        control.select('.icon').classed('icon-lock', false);
                        break;
                }

                control.style('display', 'block');

                return;
            }

            // If station is no longer pulling, stop control mode (station is still locked after stop pulling)
            if (controlState === controlStates.control) {
                this.matrixService.controlStation(station.serial, 0);
            }

            control.style('display', 'none');
        },

        /**
         * Update Menu.
         *
         * @param station {object}
         * @param state {object}
         */
        updateMenu: function(station, state) {
            const group = this.svg.select('.station[id=sn' + station.serial + ']')._groups[0][0];
            const menu = this.svg.selectAll('.btn-center[data-id=sn' + station.serial + ']');
            const stationEl = this.svg.select('#sn' + station.serial);
            const control = this.svg.selectAll('.btn-control[data-id=sn' + station.serial + ']');
            const stationBtn = this.svg.selectAll('.station-button-container[data-id=sn' + station.serial + ']');
            const stationInfo = this.svg.selectAll('.station-info-container');

            if (!group) {
                return;
            }

            const isReceiver = group.getAttribute('model') === 'CPR';
            const color = group.getAttribute('color');
            const menuGroup = menu._groups[0][0];

            switch (state) {
                case pushPullState.pushStream:
                    stationBtn.select('.icon.stream').style('color', '#F59A22');

                    if (isReceiver) {
                        stationInfo.style('display', 'none');
                    }
                    break;
                case pushPullState.push:
                    menu.select('button').classed('push', true);
                    menu.select('button').classed('pull', false);
                    menu.select('button').classed('coachingSrc', false);
                    menu.select('button').classed('coachingDest', false);
                    menu.select('button').style('background-color', '#EDEFF5');
                    menu.select('.icon').style('color', color);
                    menu.select('.icon').classed('icon-connector', false);
                    menu.select('.icon').classed('icon-close', true);

                    if (isReceiver) {
                        stationInfo.style('display', 'block');
                        menu.attr('x', parseInt(menuGroup.getAttribute('cx')) - this.configs.get('station.btnWidth') / 2)
                            .attr('y', parseInt(menuGroup.getAttribute('cy')) - this.configs.get('station.btnHeight') / 2);
                    }

                    stationEl.property('data-status', 'push');
                    control.style('display', 'none');
                    break;
                case pushPullState.pull:
                    menu.select('button').classed('push', false);
                    menu.select('button').classed('pull', true);
                    menu.select('button').classed('coachingSrc', false);
                    menu.select('button').classed('coachingDest', false);
                    menu.select('button').style('background-color', color);
                    menu.select('.icon').style('color', '#EDEFF5');
                    menu.select('.icon').classed('icon-connector', false);
                    menu.select('.icon').classed('icon-close', true);

                    stationEl.property('data-status', 'pull');

                    this.configs.getMatrixControlMode()
                        .then(function(mode) {
                            if (mode) {
                                control.style('display', 'block');
                            }
                        }.bind(this));
                    break;
                case pushPullState.coachingSrc:
                    menu.select('button').classed('push', false);
                    menu.select('button').classed('pull', false);
                    menu.select('button').classed('coachingSrc', true);
                    menu.select('button').classed('coachingDest', false);
                    menu.select('button').style('background-color', color);
                    menu.select('.icon').style('color', '#EDEFF5');
                    menu.select('.icon').classed('icon-connector', false);
                    menu.select('.icon').classed('icon-close', true);
                    menu.select('.icon-close').style('color', '#EDEFF5');

                    stationBtn.select('.icon.coaching').style('color', color);
                    control.style('display', 'none');
                    break;
                case pushPullState.coachingDest: {
                    const coachingSrcColor = this.svg.select('.station[id=sn' + this.coaching.src + ']').attr('color');

                    menu.select('button').classed('push', false);
                    menu.select('button').classed('pull', false);
                    menu.select('button').classed('coachingSrc', false);
                    menu.select('button').classed('coachingDest', true);
                    menu.select('button').style('background-color', '#EDEFF5');
                    menu.select('.icon').classed('icon-connector', false);
                    menu.select('.icon').classed('icon-close', true);
                    menu.select('.icon').style('color', color);
                    stationBtn.select('.icon.coaching').style('color', coachingSrcColor);

                    control.style('display', 'none');
                    break;
                }
                case pushPullState.none:
                    menu.select('button').classed('push', false);
                    menu.select('button').classed('pull', false);
                    menu.select('button').classed('coachingSrc', false);
                    menu.select('button').classed('coachingDest', false);
                    menu.select('button').style('background-color', '#EDEFF5');
                    menu.select('.icon').style('color', color);
                    menu.select('.icon').classed('icon-connector', true);
                    menu.select('.icon').classed('icon-close', false);

                    if (isReceiver) {
                        stationInfo.style('display', 'block');
                        menu.attr('x', parseInt(menuGroup.getAttribute('cx')) - this.configs.get('receiver.btnWidth') / 2)
                            .attr('y', parseInt(menuGroup.getAttribute('cy')) - this.configs.get('receiver.btnHeight') / 2);
                    }

                    stationEl.property('data-status', 'none');
                    control.style('display', 'none');
                    break;
            }
        },

        /**
         * Check station state
         *
         * @param station {object}
         */
        getPushPullState: function(station) {
            // Station to station
            if (station.pushIndex === 254) {
                return pushPullState.coachingSrc;
            } else if (station.pushIndex === 253) {
                return pushPullState.coachingDest;
            }

            // Push (get stream from master or stream input)
            if (station.push && (station.pushIndex === 255 || station.pushIndex === 252)) {
                return pushPullState.push;
            } else if (station.push) {
                return pushPullState.pushStream;
            }

            // Pull (send stream to master)
            if (station.pull) {
                return pushPullState.pull;
            }

            // No stream
            return pushPullState.none;
        },

        /**
         * Get stations from group with groupId.
         * Exclude stations in pull state.
         *
         * @param groupId ID of group
         * @returns {array} List of stations
         */
        getStationsFromGroup: function(groupId) {
            if (!this.svg) {
                return [];
            }

            const statusData = this.statusData;

            const stations = this.svg.selectAll('.station').filter(
                function() {
                    if (this.getAttribute('group') === groupId) {
                        const station = $.grep(statusData, function(s) {
                            return parseInt(this.getAttribute('id').replace('sn', '')) === s.serial;
                        }.bind(this));

                        if (station.length >= 0) {
                            return station[0].pull === 0;
                        }
                    }
                });

            return stations._groups[0];
        },

        /**
         * Show group names in stations while matrix interaction submenu is shown.
         */
        showGroupNames: function() {
            _.each(this.statusData, function(station) {
                if (station.present === 0) {
                    return;
                }

                const el = this.svg.select('.station[id=sn' + station.serial + ']');

                if (el && el._groups[0][0]) {
                    const groupName = this.configs.getGroupNameFromId(parseInt(el._groups[0][0].getAttribute('group')));

                    if (groupName) {
                        this.hideMenuButton(this.svg, 'btn-remove');
                        this.addStationInfo(station, groupName);
                    }
                }
            }.bind(this));
        },

        /**
         * Hide group names in stations while matrix interaction submenu is hidden.
         */
        hideGroupNames: function() {
            _.each(this.statusData, function(station) {
                const e = this.svg.select('.station[id=sn' + station.serial + ']');

                if (e.select('.station-info-container')._groups[0][0]) {
                    e.select('.station-info-container').remove();
                }

                this.updateStationStatus(station);
            }.bind(this));
        },

        /**
         * Set setupEnd value.
         * Use case: if a new template is selected and the Room View is closed, the waiting dialog
         * should appear again if the timeout hasn't ended.
         *
         * @param timeout Timeout for waiting dialog
         */
        handleSetupState: function(timeout) {
            if (this.configs.matrixTemplates.length === 1) {
                return;
            }

            this.setupEnd = new Date().getTime() + timeout;

            if (!this.configs.ignoreMatrixTemplates) {
                this.openSetupModal(timeout);
            }
        },

        /**
         * Open waiting dialog when setup stations on Matrix master.
         *
         * @param timeout
         */
        openSetupModal: function(timeout) {
            app.emit('modal.open', {
                id: 'matrix',
                messageKey: 'matrix.load_template',
                backdropIndex: 107,
                timeout: timeout,
                onFinish: function() {
                    this.setupEnd = 0;
                }.bind(this)
            });
        },

        /**
         * Handle power state and set powerEnd value.
         * Use case: if user powers on/off stations and closes the Room View, the waiting dialog
         * should appear again if the timeout hasn't ended.
         *
         * @param state Power state (on, off, none)
         * @param timeout Timeout for waiting dialog
         */
        handlePowerState: function(state, timeout) {
            this.powerState.changeState(state);
            this.powerEnd = state === powerStates.none ? 0 : new Date().getTime() + timeout;

            this.openPowerModal(state, timeout);
        },

        /**
         * Open waiting dialog if powering on/off stations.
         *
         * @param state Power state (on, off, none)
         * @param timeout Timeout for waiting dialog
         */
        openPowerModal: function(state, timeout) {
            if (state === powerStates.none) {
                return;
            }

            app.emit('modal.open', {
                id: 'matrix',
                loader: true,
                messageKey: state === powerStates.on ? 'matrix.power_on_stations' : 'matrix.power_off_stations',
                backdropIndex: 107,
                timeout: timeout,
                onFinish: function() {
                    this.powerState.changeState(powerStates.none);
                }.bind(this)
            });
        },

        /**
         * Open waiting dialog if toggling between groupwork and teaching mode.
         *
         * @param state Enable/disable groupwork (true/false)
         */
        openGroupworkTeachingModal: function(enable) {
            app.emit('modal.open', {
                id: 'matrix',
                loader: true,
                messageKey: enable ? 'matrix.start_groupwork_mode' : 'matrix.start_teaching_mode',
                backdropIndex: 107,
                timeout: 5000
            });
        },

        masterLoop: function(callback) {
            const masters = this.svg.selectAll('.station[id|="master"]')._groups[0];

            _.each(masters, function(masterNode) {
                callback(d3.select(masterNode));
            });
        },

        setCurrentDropzone: function(dropzone) {
            if (dropzone !== this.dropzone) {
                this.dropzone = dropzone;
                app.emit('matrix.dropzone.changed', dropzone);
            }
        },

        setReplaceAppId: function(replaceAppId) {
            if (replaceAppId !== this.replaceAppId) {
                this.replaceAppId = replaceAppId;
            }
        },

        getGroupworkActive: function() {
            return this.groupworkActive;
        },

        setGroupworkActive: function(groupworkActive) {
            if (groupworkActive !== this.groupworkActive) {
                this.groupworkActive = groupworkActive;
            }
        },

        setShowAllPreviews: function(showAllPreviews) {
            if (showAllPreviews !== this.showAllPreviews) {
                this.showAllPreviews = showAllPreviews;
            }
        }
    };
});
