'use strict';

const app = require('../app');

const MAX_PERIPHERAL_COMMANDS = 20;
const PERIPHERAL_EVENTS = {
    'none': 'none',
    'powerOn': 'powerOn',
    'powerOff': 'powerOff',
    'button1': 'button1',
    'button2': 'button2',
    'button3': 'button3',
    'button4': 'button4',
    'button5': 'button5',
    'button6': 'button6',
    'button7': 'button7',
    'button8': 'button8',
    'screenOn': 'screenOn',
    'screenOff': 'screenOff'
};

module.exports.MAX_PERIPHERAL_COMMANDS = MAX_PERIPHERAL_COMMANDS;

/**
 * Handels all logics with the peripheral commands.
 */
app.service('PeripheralService', function() {
    return {
        commands: [],
        commandsFull: [],

        initialize: function() {
            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.bindEvents();
                    this.mirrorState = 0;
                }.bind(this));

            this.overlayHandlerService = app.getService('OverlayHandlerService');
            this.deviceService = app.getService('DeviceService');
        },

        bindEvents: function() {
            app.on('main-loop.update', this.onUpdateHandler.bind(this));
            app.on('main-loop.update.mirror-protected', this.onUpdateHandler.bind(this));
        },

        onUpdateHandler: function() {
            this.deviceConnection
                .send([
                    { command: 'getMirrorState' }
                ])
                .then(function(data) {
                    if (this.mirrorState !== data.mirrorState) {
                        if (this.deviceService.isCboxProDualProjection()) {
                            app.emit('mirror.dual-projection', data.mirrorState);
                        } else if (data.mirrorState && this.overlayHandlerService.getOpenOverlayId() !== 'mirroring') {
                            app.emit('overlay.open', {
                                id: 'mirroring',
                                closeButton: true,
                                openNew: true
                            });
                        }
                    }

                    this.mirrorState = data.mirrorState;
                }.bind(this));
        },

        /**
         * Load all peripheral commands.
         * To update full data you must be an admin.
         */
        updateCommandsFull: function() {
            var commandList = this.getCommandList('getRmsClientFull');

            // Load all commands.
            return this.deviceConnection
                .send(commandList)
                .then(function() {
                    this.commandsFull = Array.from(arguments);
                }.bind(this));
        },

        /**
         * Load all peripheral commands.
         */
        updateCommands: function() {
            var commandList = this.getCommandList('getRmsClient');

            // Load all commands.
            return this.deviceConnection
                .send(commandList)
                .then(function() {
                    this.commands = Array.from(arguments);
                }.bind(this));
        },

        /**
         * Returns a list with all commands to load peripheral-commands.
         */
        getCommandList: function(command) {
            var commandList = [];

            // Generate all commands.
            for (var i = 0; i < MAX_PERIPHERAL_COMMANDS; i++) {
                commandList[i] = {
                    command: command,
                    data: {
                        id: i
                    }
                };
            }

            return commandList;
        },

        /**
         * Returns all loaded commands.
         * Full data for Admin level.
         *
         * @return {[]}
         */
        getAllCommandsFull: function() {
            return this.commandsFull;
        },

        /**
         * Returns all loaded commands.
         *
         * @return {Array}
         */
        getAllCommands: function() {
            return this.commands;
        },

        /**
         * Returns all commands with event type button.
         *
         * @return {T[]}
         */
        getButtonEvents: function() {
            return this.getAllEnabledCommands().filter(function(command) {
                return PERIPHERAL_EVENTS.button1 === command.event
                    || PERIPHERAL_EVENTS.button2 === command.event
                    || PERIPHERAL_EVENTS.button3 === command.event
                    || PERIPHERAL_EVENTS.button4 === command.event
                    || PERIPHERAL_EVENTS.button5 === command.event
                    || PERIPHERAL_EVENTS.button6 === command.event
                    || PERIPHERAL_EVENTS.button7 === command.event
                    || PERIPHERAL_EVENTS.button8 === command.event;
            });
        },

        /**
         * Returns all commands without none-event.
         *
         * @return {T[]}
         */
        getAllEnabledCommands: function() {
            return this.getAllCommands().filter(function(command) {
                return true === command.enabled && command.event !== PERIPHERAL_EVENTS.none;
            });
        },

        getMirrorState: function() {
            return this.mirrorState;
        }
    };
});
