'use strict';

var app = require('../../../app');
var platform = require('../../../../platform/platform');
var matrixSettingsTpl = require('./matrix-settings.hbs');
var FormActionView = require('../form-action-view');
var $ = require('jquery');
var _ = require('lodash');
var i18n = require('i18next');

var MAX_CONFIGURATIONS = 5;

app.component('MatrixSettings', {
    template: matrixSettingsTpl,

    initialize: function() {
        this.changes = false;
        this.formData = {};
        this.formManager = this.getService('FormManager');
        this.configs = this.getService('MatrixConfigs');
        this.frontendSettings = app.getService('FrontendSettings');
        this.deviceService = app.getService('DeviceService');
        this.isDualProjection = this.deviceService.isCboxProDualProjection();

        this.configs.loadMatrixTemplates();
    },

    serialize: function() {
        return {
            isIE: platform.checks.isIe,
            isDualProjection: this.isDualProjection
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.initForm();
        this.createNetdriveComponent();

        $.when(this.loadFrontendSettings(this), this.loadBackendSettings(this), this.loadModeSpecificData(this))
            .done(function() {
                this.updateForm();
                this.checkMatrixMasterModeEnabled(true);
                this.parseConfigurations();
            }.bind(this));

        this.bindEvents();
        this.bindDOMEvents();
    },

    bindEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));

        this.on('settings.save-changes', this.saveAllHandler.bind(this));
        this.on('settings.matrix.changes', this.handleFormChange.bind(this));
        this.on('settings.matrix-templates.changed', this.updateStartConfiguratorBtn.bind(this));
        this.on('settings.matrix-templates.changed', this.checkMaxConfigurationInputs.bind(this));
    },

    /**
     * Bind DOM events.
     */
    bindDOMEvents: function() {
        this.$el.on('click', '.start-configurator', this.openConfigurator.bind(this));
        this.$el.on('click', '.matrix-add-new-configuration:not(.is-disabled)', this.addConfiguration.bind(this));
    },

    storeSelectors: function() {
        this.$settingsContainer = this.$el.find('.matrix-settings-container');
        this.$startConfiguratorBtn = this.$el.find('.start-configurator');
        this.$loader = this.$el.find('.matrix-loader');
        this.$mainSettingsContainer = this.$el.find('.matrix-settings-main-container');
        this.$netdriveContainer = this.$el.find('.matrix-netdrive-container');
        this.$addNewConfiguration = this.$el.find('#matrix-new-configuration');
        this.$configurationList = this.$el.find('#matrix-configuration-list-items');

        if (this.isDualProjection) {
            this.$commandButtonsHdmi2Container = this.$el.find('.command-buttons-hdmi2-container');
        } else {
            this.$limitedAccessContainer = this.$el.find('.limited-access-container');
        }
    },

    /**
     * Init form.
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#matrix-settings'),
            validationContainer: '.input-group'
        });

        this.initFormAction();
    },

    /**
     * Init action form (save & cancel)
     */
    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    createNetdriveComponent: function() {
        this.createComponent({
            type: 'MatrixNetdriveSettings',
            container: this.$netdriveContainer,
            frameboxComponent: this.options.component
        });
    },

    /**
     * Check action form.
     *
     * Returns true when the form is valid.
     * @param invalid true if netdrive component form is invalid.
     *
     * @return {Boolean}
     */
    checkActionForm: function() {
        if (this.hasChanges().invalid) {
            this.actionView.disableSubmitButton();

            return false;
        } else {
            this.actionView.enableSubmitButton();

            return true;
        }
    },

    /**
     * Check if matrix master mode is enabled.
     * @param init
     */
    checkMatrixMasterModeEnabled: function(init) {
        var matrixMasterModeEnabled = this.form.get('matrixEnabled').getValue();

        if (matrixMasterModeEnabled) {
            this.showContainer(init, this.$settingsContainer);
            this.showContainer(init, this.$mainSettingsContainer);
            this.showContainer(init, this.$netdriveContainer);
        } else {
            this.hideContainer(init, this.$settingsContainer);
            this.hideContainer(init, this.$mainSettingsContainer);
            this.hideContainer(init, this.$netdriveContainer);
        }
    },

    /**
     * Parse configurations and add to view.
     */
    parseConfigurations: function() {
        _.each(this.configs.matrixTemplates, function({ id, name, groups, background, shareToAllEnabled }) {
            this.addConfiguration(null, id, name, groups, background, shareToAllEnabled);
        }.bind(this));

        if (this.configs.matrixTemplates.length <= 0) {
            this.updateStartConfiguratorBtn();
        }
    },

    /**
     * Show container.
     *
     * @param init true -> without animation, false -> animate
     * @param $container
     */
    showContainer: function(init, $container) {
        if (init) {
            $container.show();
        } else {
            $container
                .stop()
                .slideDown(230);
        }
    },

    /**
     * Hide container.
     *
     * @param init true -> without animation, false -> animate
     * @param $container
     */
    hideContainer: function(init, $container) {
        if (init) {
            $container.hide();
        } else {
            $container
                .stop()
                .slideUp(300);
        }
    },

    /**
     * Handles saving ALL changes including the configuration templates changes.
     * Called when the save changes dialog appears.
     */
    saveAllHandler: function() {
        _.each(this.childComponentIds, function(id) {
            this.emit('settings.save-changes-' + id);
        }.bind(this));

        this.saveHandler();
    },

    /**
     * Handle save button press.
     */
    saveHandler: function() {
        if (this.form.validate()) {
            const matrixEnabled = this.form.get('matrixEnabled').getValue();
            const matrixAutoStart = this.form.get('matrixAutoStart').getValue();
            const matrixAutoOpen = this.form.get('matrixAutoOpenFile').getValue();
            const matrixCollab = this.form.get('matrixCollabEnable').getValue();
            const matrixGroupworkMode = this.form.get('matrixGroupworkMode').getValue();
            const matrixIgnoreTemplates = this.form.get('matrixIgnoreTemplates').getValue();

            const cmds = [
                {
                    command: 'setMatrixMasterMode',
                    data: {
                        enabled: matrixEnabled
                    }
                },
                {
                    command: 'setMatrixAutoOpenFile',
                    data: {
                        enabled: matrixAutoOpen
                    }
                },
                {
                    command: 'setMatrixMasterAnnotationEnabled',
                    data: {
                        enabled: matrixCollab
                    }
                },
                {
                    command: 'setMatrixGroupworkMode',
                    data: {
                        enabled: matrixGroupworkMode
                    }
                }
            ];

            let matrixControlMode;

            if (!this.isDualProjection) {
                matrixControlMode = this.form.get('matrixControlMode').getValue();

                cmds.push({
                    command: 'setMatrixMasterLimitedAccess',
                    data: {
                        enabled: matrixControlMode ? this.form.get('matrixLimitedAccess').getValue() : false
                    }
                });
            }

            this.deviceConnection
                .send(cmds)
                .then(function() {
                    this.frontendSettings.updateSetting({
                        tag: 'matrixAutoStart',
                        value: matrixAutoStart
                    });
                    this.frontendSettings.updateSetting({
                        tag: 'matrixIgnoreTemplates',
                        value: matrixIgnoreTemplates
                    });

                    if (this.isDualProjection) {
                        this.frontendSettings.updateSetting({
                            tag: 'matrixCommandButtons',
                            value: this.form.get('matrixCommandButtons').getValue()
                        });

                        this.frontendSettings.updateSetting({
                            tag: 'matrixCommandButtonsHdmi2',
                            value: this.form.get('matrixCommandButtonsHdmi2').getValue()
                        });
                    } else {
                        this.frontendSettings.updateSetting({
                            tag: 'matrixControlMode',
                            value: matrixControlMode
                        });
                    }

                    this.frontendSettings.saveSettings();

                    if (matrixEnabled) {
                        this.app.removeComponent('matrixFlap');
                        this.app.createComponent('matrixFlap', {
                            type: 'MatrixFlap',
                            container: '#matrix-flap-container'
                        });
                    }

                    if (!this.isDualProjection) {
                        this.configs.updateTemplates(matrixControlMode);
                    }
                    this.checkMatrixMasterModeEnabled();

                    this.app.emit('show.ui');
                    this.app.emit('matrix.master.mode.changed', {
                        enabled: matrixEnabled
                    });

                    if (this.isDualProjection) {
                        this.emit('matrix.command-buttons.changed', this.form.get('matrixCommandButtons').getValue());
                    }
                    this.emit('settings.matrix.saved');

                    this.changes = false;
                    this.configs.chooseTemplate = true;

                    this.updateStartConfiguratorBtn();
                    this.emit('save-changes.finished');
                }.bind(this));
        }
    },

    /**
     * Handle cancel button press.
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);

        this.form.resetValues();
        this.changes = false;

        this.checkMatrixMasterModeEnabled();
        this.updateStartConfiguratorBtn();
        this.emit('settings.matrix.cancel');
    },

    /**
     * Load frontend settings.
     */
    loadFrontendSettings: function() {
        var dfd = $.Deferred();

        this.frontendSettings
            .getSettings([
                'matrixAutoStart',
                'matrixControlMode',
                'matrixIgnoreTemplates',
                'matrixCommandButtons',
                'matrixCommandButtonsHdmi2'
            ])
            .then(function(autoStart, matrixControlMode, matrixIgnoreTemplates, matrixCommandButtons, matrixCommandButtonsHdmi2) {
                this.formData.matrixAutoStart = autoStart;
                this.formData.matrixIgnoreTemplates = matrixIgnoreTemplates;

                if (this.isDualProjection) {
                    this.formData.matrixCommandButtons = matrixCommandButtons;
                    this.formData.matrixCommandButtonsHdmi2 = matrixCommandButtonsHdmi2;

                    if (!matrixCommandButtons) {
                        this.hideContainer(false, this.$commandButtonsHdmi2Container);
                    }
                } else {
                    this.formData.matrixControlMode = matrixControlMode;

                    if (!matrixControlMode) {
                        this.$limitedAccessContainer.hide();
                    }
                }

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Load backend settings.
     */
    loadBackendSettings: function() {
        var dfd = $.Deferred();

        this.deviceConnection
            .send([
                'getMatrixMasterMode',
                'getMatrixAutoOpenFile',
                'getMatrixMasterAnnotationEnabled',
                'getMatrixGroupworkMode'
            ])
            .then(function(masterMode, autoOpen, collab, groupworkMode) {
                this.formData.matrixEnabled = masterMode.enabled;
                this.formData.matrixAutoOpenFile = autoOpen.enabled;
                this.formData.matrixCollabEnable = collab.enabled;
                this.formData.matrixGroupworkMode = groupworkMode.enabled;

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Load mode specific settings.
     */
    loadModeSpecificData: function() {
        var dfd = $.Deferred();

        if (!this.isDualProjection) {
            this.deviceConnection
                .send([
                    'getMatrixMasterLimitedAccess'
                ])
                .then(function(limitedAccess) {
                    this.formData.matrixLimitedAccess = limitedAccess.enabled;

                    dfd.resolve();
                }.bind(this));
        } else {
            dfd.resolve();
        }

        return dfd.promise();
    },

    /**
     * Update form.
     */
    updateForm: function() {
        this.form.setValues(this.formData);
    },

    /**
     * Handle form change.
     *
     * @param invalid true if netdrive component form is invalid.
     */
    handleFormChange: function(data) {
        if (data && data.$el[0].name === 'matrixEnabled') {
            if (data.$el[0].checked) {
                this.$mainSettingsContainer.show();
            } else {
                this.$mainSettingsContainer.hide();
            }
        } else if (!this.isDualProjection && data && data.$el[0].name === 'matrixControlMode') {
            if (data.$el[0].checked) {
                this.$limitedAccessContainer.show();
            } else {
                this.$limitedAccessContainer.hide();
            }
        } else if (this.isDualProjection && data && data.$el[0].name === 'matrixCommandButtons') {
            if (data.$el[0].checked) {
                this.showContainer(false, this.$commandButtonsHdmi2Container);
            } else {
                this.hideContainer(false, this.$commandButtonsHdmi2Container);
            }
        }

        this.changes = true;

        this.checkMatrixMasterModeEnabled();
        this.updateStartConfiguratorBtn();

        this.actionView.open();
        this.checkActionForm();
    },

    /**
     * Add new configuration and create component.
     *
     * @param event Current event
     * @param id Template ID
     * @param name Template Name
     */
    addConfiguration: function(event, index, name, groups, background, shareToAllEnabled) {
        const showCommandButtons = this.isDualProjection ? this.form.get('matrixCommandButtons').getValue() : false;

        if (typeof index === 'undefined') {
            const freeIndex = this.getNextFreeIndex();

            if (freeIndex >= 0) {
                this.createComponent({
                    type: 'MatrixConfigurationItem',
                    container: this.$configurationList,
                    id: freeIndex,
                    isDualProjection: this.isDualProjection,
                    showCommandButtons: showCommandButtons,
                    name: i18n.t('settings.matrix_configuration') + ' ' + (freeIndex + 1),
                    shareToAllEnabled: true,
                    groups: [],
                    background: background,
                    newConfig: true,
                    insertType: 'first'
                });
            }
        } else {
            this.createComponent({
                type: 'MatrixConfigurationItem',
                container: this.$configurationList,
                id: index,
                isDualProjection: this.isDualProjection,
                showCommandButtons: showCommandButtons,
                name: name,
                shareToAllEnabled: shareToAllEnabled,
                groups: groups ? groups : [],
                background: background,
                newConfig: false,
                insertType: 'first'
            });
        }

        this.checkMaxConfigurationInputs();
        this.updateStartConfiguratorBtn();
    },

    /**
     * Get next free index.
     */
    getNextFreeIndex: function() {
        for (let i = 0; i < MAX_CONFIGURATIONS; i++) {
            if (this.$el.find('.matrix-configuration-item[data-index="' + i + '"]').length <= 0) {
                return i;
            }
        }
    },

    /**
     * Check maximum configuration inputs and disable add config button.
     */
    checkMaxConfigurationInputs: function() {
        if (typeof this.getNextFreeIndex() === 'undefined') {
            this.$addNewConfiguration.addClass('is-disabled');
        } else {
            this.$addNewConfiguration.removeClass('is-disabled');
        }
    },

    /**
     * Open Matrix configurator.
     */
    openConfigurator: function() {
        if (!this.isDualProjection) {
            this.configs.updateTemplates();
        }
        this.updateStartConfiguratorBtn();
        this.$loader.show();

        setTimeout(function() {
            this.emit('overlay.open', {
                id: 'matrix-configurator',
                isMatrix: true,
                openNew: true
            });
        }.bind(this), 100);
    },

    /**
     * Enable or disable configurator button.
     */
    updateStartConfiguratorBtn: function() {
        var templateLength = $('#matrix-configuration-list-items .matrix-configuration-item').length;
        var isBtnDisable = 0 === templateLength || this.hasChanges().hasChanges;

        this.$startConfiguratorBtn.prop('disabled', isBtnDisable);
    },

    /**
     * Check if there are some changes and update action view (Cancel/Save)
     * @returns {*}
     */
    hasChanges: function() {
        var hasChanges = this.changes;
        var invalid = !this.form.validate();
        var matrixMasterModeEnabled = this.form.get('matrixEnabled').getValue();

        if (matrixMasterModeEnabled) {
            _.each(this.childComponentIds, function(id) {
                var c = this.app.componentHasChanges(id);

                hasChanges = hasChanges || c.hasChanges;
                invalid = invalid || c.invalid;
            }.bind(this));
        }

        return {
            hasChanges: hasChanges,
            invalid: invalid,
            waitOnSave: true
        };
    },

    destroy: function() {
        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }
    }
});
