'use strict';

const app = require('../app');
const $ = require('jquery');
const StateMachine = require('./../state-machine');
const platform = require('../../platform/platform');
const states = require('../states');

app.service('PowerService', function(app) {
    return {
        initialize: function() {
            this.state = new StateMachine({
                context: this,
                state: states.powerStates.on,
                states: states.powerStates
            });

            this.addStateTransitions();
            this.recordService = app.getService('RecordService');
            this.uploadService = app.getService('UploadService');
            this.deviceService = app.getService('DeviceService');
            this.lectureCaptureService = app.getService('LectureCaptureService');
            this.authService = app.getService('AuthenticationService');
            this.screensaverService = app.getService('ScreensaverService');
            this.modalHandlerService = app.getService('ModalHandlerService');

            this.powerDownTimestamp = null;
            this.powerDownModeShutdown = false;

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;

                    this.updateHandler();
                    this.bindEvents();
                }.bind(this));
        },

        bindEvents: function() {
            // RELEASE-2341 - When there are some failed uploads, Cynap does enter in standby.
            setInterval(this.updateHandler.bind(this), 5000);

            app.on('remote.shutdown.keyup', this.onRemoteShutdown.bind(this));
        },

        /**
         * SDM Shutdown: immediately shutdown if a remote shutdown event appears.
         */
        onRemoteShutdown: function() {
            if (this.deviceService.isSDM()) {
                const data = {
                    removeRecordings: true,
                    removeSnapshots: true,
                    removeWhiteboards: true,
                    state: 'standby' // Standby/Shutdown/Power Saving
                };

                this.deviceConnection.send('setEndPresentation', data);

                return;
            }

            this.onShutdown();
        },

        onShutdown: function() {
            this.openEndPresentationModal();
        },

        /**
         * Open end-presentation modal and check all needed states.
         *
         * @return {*|void}
         */
        openEndPresentationModal: function() {
            if (this.modalHandlerService.getOpenModalId() === 'end-presentation'
                && this.state.getState() === states.powerStates.on) {
                return;
            }

            if (this.state.getState() === states.powerStates.off) {
                return this.wakeUp();
            }

            if (this.authService.getIsLdapActive() && !this.authService.getIsLdapAuthenticated()) {
                return;
            }

            this.lectureCaptureService
                .checkUploadState()
                .then(this.checkRecordingState.bind(this))
                .then(this.checkUploads.bind(this))
                .then(function() {
                    app.emit('modal.open', {
                        id: 'end-presentation'
                    });
                }.bind(this));
        },

        /**
         * Check Recording state. If recording is enabled open warning.
         *
         * @return {Promise}
         */
        checkRecordingState: function() {
            var dfd = $.Deferred();

            if (this.recordService.getState() !== 'stop'
                && (this.recordService.recordTriggerState.getState() !== 'opencast'
                    || (this.recordService.recordTriggerState.getState() === 'opencast'
                    && this.recordService.lcs.type === states.lcsTypeToCmdMapping.opencastAdhoc))
            ) {
                var openControlCenter = function() {
                    app.emit('control-center.open');
                    dfd.reject();
                };

                app.emit('modal.open', {
                    id: 'record-warning',
                    onConfirm: openControlCenter,
                    onDiscare: openControlCenter
                });
            } else {
                dfd.resolve();
            }

            return dfd.promise();
        },

        /**
         * Check Upload states. If uploads are running or failed open warning.
         *
         * @return {Promise}
         */
        checkUploads: function() {
            var dfd = $.Deferred();

            if (!this.uploadService.checkIfAllUploadReady()) {
                app.emit('modal.open', {
                    id: 'upload-warning',
                    onConfirm: dfd.resolve,
                    onDiscare: dfd.reject
                });
            } else {
                dfd.resolve();
            }

            return dfd.promise();
        },

        updateHandler: function() {
            this.deviceConnection
                .send([
                    'getPowerStatus',
                    'getScreensaverState'
                ])
                .then(function(power, screensaver) {
                    var state = states.powerStates[power.powerStatus];

                    if (this.state.getState() !== state) {
                        this.state.changeState(state);
                    }

                    if (this.screensaverService.screensaverState.getState() !== screensaver.state) {
                        this.screensaverService.screensaverState.changeState(screensaver.state);
                    }

                    // RELEASE-2922: When the power is off, we don't get the power down mode.
                    this.getPowerDownMode();
                }.bind(this));
        },

        sleep: function() {
            this.deviceConnection
                .send('setPower', {
                    setting: 'off'
                });
        },

        /**
         * @return {boolean}
         */
        wakeUp: function() {
            if (this.powerDownMode === 'shutdown' && this.powerDownTimestamp !== null) {
                if (Date.now() - this.powerDownTimestamp > 40000 && Date.now() - this.powerDownTimestamp < 70000) {
                    return false;
                }
            }

            this.deviceConnection
                .send('setPower', {
                    setting: 'on'
                });

            if (platform.checks.isCbox) {
                $(document).off('touchstart', this.onShutdown.bind(this));
            }

            return true;
        },

        /**
         * Returns true if CYNAP is in Standbymode.
         *
         * @returns {Boolean}
         */
        isOnStandby: function() {
            return this.state.getState() === states.powerStates.off;
        },

        /**
         * Get Power Down Mode.
         */
        getPowerDownMode: function() {
            var dfd = $.Deferred();

            this.deviceConnection
                .send('getPowerDownMode')
                .then(function(powerDownMode) {
                    this.powerDownMode = powerDownMode.mode;
                    dfd.resolve(this.powerDownMode);
                }.bind(this));

            return dfd.promise();
        },

        addStateTransitions: function() {
            this.state.addTransitions({
                '> on': function() {
                    window.location.reload();
                },
                '> off': function() {
                    app.getService('AnnotationService').clearAllStoredSettings();

                    var message = '';

                    if (this.powerDownMode === 'shutdown') {
                        this.powerDownTimestamp = Date.now();
                    }

                    // Try to get powerDownMode if not defined.
                    if (this.powerDownMode === undefined) {
                        this.getPowerDownMode().then(function() {
                            this.state.changeState(states.off);
                        }.bind(this));

                        return;
                    }

                    switch (this.powerDownMode) {
                        case 'suspend':
                            message = 'modal.standby_message';
                            break;
                        case 'shutdown':
                            message = 'modal.shutdown_message';
                            break;
                        case 'power_saving':
                            message = 'modal.power_saving_message';

                            // RELEASE-3623: most commands are blocked in this mode therefore we need to create an own
                            // Component instead of starting the standard components (bootstrap, modal, ...)
                            app.createComponent('PowerWarning', {
                                type: 'PowerWarning',
                                container: '#main-warning'
                            });
                            break;
                    }

                    if (!platform.checks.isCbox) {
                        app.emit('modal.open', {
                            id: 'box-standby',
                            messageKey: message
                        });
                    } else {
                        $(document).on('touchstart', this.onShutdown.bind(this));
                    }
                }
            });
        }
    };
});
