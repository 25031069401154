'use strict';

var app = require('../../../app');
var messageTpl = require('./framebox-message.hbs');

app.component('FrameboxMessage', {
    template: messageTpl,
    className: 'framebox-message-container',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.transKey = this.options.transKey;
    },

    serialize: function() {
        return {
            transKey: this.transKey
        };
    }
});
