'use strict';

var app = require('../../../app');
var tpl = require('./ui-blocker.hbs');
var states = require('./../../../states');
var i18n = require('i18next');

app.component('UiBlocker', {
    template: tpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.hasStarted = true;
        this.emit('ui.block.events');
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();

        if (this.options.configs.forceText) {
            this.$el.find('.encrypt-message').removeClass('hidden');
            this.$el.find('.encrypt-message').show().html(i18n.t('modal.system_will_restart'));
            this.$encryptionLoader.addClass('hidden');
        } else {
            this.updateHandler();
        }
    },

    storeSelectors: function() {
        this.$encryptionLoader = this.$el.find('#disk-encryption-loader');
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        if (!this.options.configs.forceText) {
            this.on('main-loop.update', this.updateHandler.bind(this));
        }
    },

    /**
     * Update disk encryption status on every main-loop call.
     */
    updateHandler: function() {
        this.deviceConnection
            .send([
                'getDiskEncryptionStatus'
            ])
            .then(this.onDiskEncryptionStatusLoaded.bind(this));
    },

    /**
     * Change Disk encryption status message.
     *
     * @param {Object} status
     */
    onDiskEncryptionStatusLoaded: function(status) {
        if (states.encryptionStates.inProgress === status.status) {
            this.hasStarted = true;

            this.$el.find('.encrypt-message').removeClass('hidden');
            this.$el.find('.encrypt-message').html(i18n.t('modal.system_will_encrypt', {
                'cynapName': app.getService('Model-View').name
            }));
            this.$encryptionLoader.removeClass('hidden');
        } else if (states.encryptionStates.failed === status.status && this.hasStarted) {
            this.$encryptionLoader.addClass('hidden');

            this.$el.find('.encrypt-message')
                .html(i18n.t('modal.system_will_en_decrypt_failed'))
                .addClass('is-failed');
            setTimeout(this.close.bind(this), 2000);
        } else if (states.encryptionStates.decrypted !== status.status) {
            this.$encryptionLoader.addClass('hidden');
            this.$el.find('.encrypt-message').show().html(i18n.t('modal.system_will_restart'));
        }
    },

    /**
     * Close UI-Block modal and enable remote.
     */
    close: function() {
        this.emit('modal.close', {
            id: 'ui-blocker'
        });
        this.emit('app.restart.failed');
    },

    destroy: function() {
        this.close();
    }
});
