// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div id=\"overlay-action\">\n                        <button id=\"overlay-tabbed-button\"\n                                class=\"btn-no-style overlay-header-item overlay-tabbed-btn overlay-action-btn-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " ellipsis\"\n                                data-overlay=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"overlay") : depth0), depth0))
    + "\">\n                            "
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":28},"end":{"line":11,"column":45}}}))
    + "\n                        </button>\n                    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div id=\"overlay-save-btn\" class=\"overlay-save-btn pull-right\">\n                    <button id=\"overlay-action-button-save\"\n                            class=\"btn btn-no-style btn-no-active pull-right overlay-header-item focusable ellipsis\">\n                        "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.action_button_save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":24},"end":{"line":22,"column":59}}}))
    + "\n                    </button>\n                </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                <div id=\"overlay-close-btn\" class=\"overlay-close-btn\">\n                    <button\n                        class=\"btn btn-no-style btn-no-active pull-right overlay-header-item overlay-action-btn focusable\">\n                        <span class=\"icon-close\"></span>\n                    </button>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"overlay-header-tabbed\" class=\"overlay-header-tabbed\">\n    <div class=\"row\">\n        <div class=\"col-xs-2\"></div>\n        <div class=\"col-xs-8\">\n            <div class=\"tabbed-items\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"tabButtons") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":14,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n        <div class=\"col-xs-1\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"saveButton") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":25,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"col-xs-1\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"closeButton") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":35,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});
