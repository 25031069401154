// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "col-xs-12";
},"3":function(container,depth0,helpers,partials,data) {
    return "col-xs-8";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.cynap_content_url_hdmi1",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":28},"end":{"line":10,"column":69}}}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.cynap_content_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":28},"end":{"line":12,"column":63}}}))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div id=\"customHdmi2Container\" class=\"input-group\">\n                        <label class=\"input-label\" for=\"urlHdmi2\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.cynap_content_url_hdmi2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":66},"end":{"line":22,"column":107}}}))
    + "</label>\n                        <input type=\"text\" class=\"input\" name=\"urlHdmi2\" id=\"urlHdmi2\" data-validation=\"checkUrlOrEmpty\"/>\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.enter_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":75},"end":{"line":25,"column":101}}}))
    + "</span>\n                        </div>\n                    </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.hdmi_in_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":28},"end":{"line":32,"column":57}}}))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.hdmi_in_2_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":28},"end":{"line":34,"column":59}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"custom-stream-settings\" name=\"custom-stream-settings\">\n        <div class=\"row settings-list-secundary\"\n             data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n             data-nav-trapped>\n            <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFullsize") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":24},"end":{"line":6,"column":76}}})) != null ? stack1 : "")
    + "\">\n                <div id=\"customHdmi1Container\" class=\"input-group\">\n                    <label class=\"input-label\" for=\"urlHdmi1\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDualProjection") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":13,"column":31}}})) != null ? stack1 : "")
    + "                    </label>\n                    <input type=\"text\" class=\"input\" name=\"urlHdmi1\" id=\"urlHdmi1\" data-validation=\"checkUrl\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.enter_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":71},"end":{"line":17,"column":97}}}))
    + "</span>\n                    </div>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDualProjection") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":28,"column":23}}})) != null ? stack1 : "")
    + "                <div id=\"customExtContainer\" class=\"input-group\">\n                    <label class=\"input-label\" for=\"urlExt\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCorePro") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":35,"column":31}}})) != null ? stack1 : "")
    + "                    </label>\n                    <input type=\"text\" class=\"input\" name=\"urlExt\" id=\"urlExt\" data-validation=\"checkUrlOrEmpty\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message info-message\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.stream_fullhd_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":28},"end":{"line":40,"column":64}}}))
    + "\n                        </span>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});
