// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"media-casting-overlay\">\n    <div class=\"row\">\n        <div class=\"col-xs-12\">\n            <h2>\n                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.panopto_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":43}}}))
    + "\n                <span id=\"panopto-login-state\" class=\"panopto-login-state\">\n                    <span class=\"panopto-login-state-logged-in\">\n                        <div class=\"panopto-login-state-point\"></div>\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.logged_in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":9,"column":50}}}))
    + "\n                    </span>\n                    <span class=\"panopto-login-state-not-logged-in\">\n                        <div class=\"panopto-login-state-point\"></div>\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.not_logged_in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":13,"column":54}}}))
    + "\n                    </span>\n                    <span class=\"panopto-login-state-login-failed\">\n                        <div class=\"panopto-login-state-point\"></div>\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.login_failed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":53}}}))
    + "\n                    </span>\n                </span>\n            </h2>\n            <div class=\"panopto-username\"></div>\n        </div>\n    </div>\n\n    <div class=\"row modal-loader\">\n        <row class=\"col-xs-12\">\n            <div class=\"loader\"></div>\n        </row>\n    </div>\n    <div class=\"row modal-failed hidden\">\n        <row class=\"col-xs-12\">\n            <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.panopto_dir_failed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":18},"end":{"line":32,"column":50}}}))
    + "</span>\n        </row>\n    </div>\n\n    <div class=\"file-tree panopto-file-tree\">\n        <ul data-tree-id=\"00000000-0000-0000-0000-000000000000\"></ul>\n    </div>\n\n    <div class=\"row start-btn-container\">\n        <input id=\"logout-panopto\" class=\"btn-form form-action-cancel services-disabled is-focusable\"\n               tabindex=\"-1\" type=\"button\" value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"action_button.logout",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":50},"end":{"line":42,"column":78}}}))
    + "\" disabled/>\n        <input id=\"start-panopto\" class=\"btn-form form-action-submit services-disabled is-focusable\"\n               tabindex=\"-1\" type=\"button\" value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.start_panopto",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":50},"end":{"line":44,"column":77}}}))
    + "\" disabled/>\n    </div>\n</div>\n";
},"useData":true});
