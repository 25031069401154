// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"bookmarks\" name=\"bookmarks\">\n        <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable:not(:disabled)\" data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label bookmark-label\" for=\"url\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"bookmarks.url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":72},"end":{"line":6,"column":93}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input is-focusable\"\n                           name=\"url\" id=\"url\"\n                           data-validation=\"checkUrl\"\n                           tabIndex=\"-1\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"bookmarks.invalid_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":71},"end":{"line":13,"column":100}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label bookmark-label\" for=\"name\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"bookmarks.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":73},"end":{"line":19,"column":95}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input is-focusable\"\n                           name=\"name\"\n                           id=\"name\"\n                           data-validation=\"required\"\n                           tabIndex=\"-1\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"bookmarks.invalid_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":71},"end":{"line":27,"column":101}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"row settings-list-secundary settings-list-info hidden\">\n            <div class=\"col-xs-12\">\n                <p class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"bookmarks.max_number_reached",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":60},"end":{"line":34,"column":96}}}))
    + "</p>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable:not(:disabled)\" data-nav-trapped>\n            <div class=\"btn-add-bookmark\"\n                 id=\"btn-add-bookmark\"\n                 data-nav-area=\".is-focusable:not(:disabled)\"\n                 data-nav-trapped>\n                <input type=\"button\"\n                       name=\"add\"\n                       class=\"btn-form btn-add-bookmark is-focusable bookmark-action-add\"\n                       value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"bookmarks.add",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":30},"end":{"line":46,"column":51}}}))
    + "\">\n            </div>\n        </div>\n        <div class=\"row settings-list-row bookmark-list-title\">\n            <div class=\"col-xs-12\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"bookmarks.bookmark_list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":20},"end":{"line":51,"column":51}}}))
    + "</h2>\n            </div>\n        </div>\n        <div class=\"row settings-list-secundary\">\n            <div id=\"bookmark-list-header\" class=\"bookmark-list-header\">\n                <div class=\"col-xs-6\">\n                    <h2 class=\"bookmark-list-header-title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"bookmarks.url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":59},"end":{"line":57,"column":80}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"bookmark-list-header-title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"bookmarks.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":59},"end":{"line":60,"column":81}}}))
    + "</h2>\n                </div>\n            </div>\n        </div>\n        <div id=\"bookmark-list-items\" class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\"></div>\n        <div data-nav-area=\".is-focusable\" data-nav-trapped data-nav-track>\n        <div id=\"bookmark-list-no-entry\"\n             class=\"row settings-list-secundary bookmark-list-item bookmark-list-no-entry has-content hidden\">\n            <div class=\"col-xs-12\">\n                <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"bookmarks.bookmark_list_no_entry",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":19},"end":{"line":69,"column":59}}}))
    + "</p>\n            </div>\n        </div>\n    </div>\n        <div class=\"row settings-list-row btn-save-container\"\n             data-nav-area=\".is-focusable:not(:disabled)\"\n             data-nav-trapped>\n            <div class=\"btn-save-user-settings\"\n                 id=\"btn-save-user-settings\"\n                 data-nav-area=\".is-focusable:not(:disabled)\"\n                 data-nav-trapped>\n                <input type=\"button\"\n                       name=\"save\"\n                       class=\"btn-form btn-save-user-settings is-focusable save-user-settings\"\n                       value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"control_center.save_user_settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":83,"column":30},"end":{"line":83,"column":71}}}))
    + "\">\n            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});
