'use strict';

/**
 * Removes content if user has no access to this element.
 *
 * <rbac-access role-name="name" role-key="key"></rbac-access>
 *
 * @type {HTMLElement}
 */
var RBACAccessElementPrototype = Object.create(HTMLElement.prototype);

/**
 * Is called when a new element is append to the DOM.
 */
RBACAccessElementPrototype.connectedCallback = function() {
    this.checkAccess();
};

RBACAccessElementPrototype.createdCallback = function() {
    this.checkAccess();
};

/**
 * Is called when a param are changed
 */
RBACAccessElementPrototype.attributeChangedCallback = function() {
    this.checkAccess();
};

/**
 * Check access with the given params
 */
RBACAccessElementPrototype.checkAccess = function() {
    var rbac = require('./rbac.js');
    var roleName = this.getAttribute('role-name');
    var roleKey = this.getAttribute('role-key');
    var child = this.querySelector('*:first-child');

    // Set default role key
    if (!roleKey) {
        roleKey = rbac.DEFAULT_ROLE_KEY;
    }

    var hasAccess = rbac.hasAccess(roleName, roleKey);

    if (!hasAccess && child) {
        var content = this.removeChild(child);

        this.accessContent = content;
    } else if (hasAccess && this.accessContent) {
        this.appendChild(this.accessContent);
    }
};

// Register element
if (('customElements' in window) && ('define' in window.customElements)) {
    var RBACAccessElement = function() {
        return Reflect.construct(HTMLElement, [], RBACAccessElement);
    };

    RBACAccessElement.prototype = Object.assign(Object.create(HTMLElement.prototype), RBACAccessElementPrototype);

    window.customElements.define('rbac-access', RBACAccessElement);
// Microsoft Browsers :(
} else {
    document.registerElement('rbac-access', {
        prototype: RBACAccessElementPrototype
    });
}

