'use strict';

var app = require('../../../app');
var screenShareTpl = require('./screen-share.hbs');
var icons = require('./../../../framebox-types').contentTypeIcons;
var _ = require('lodash');
var $ = require('jquery');
var RedrawEngine = require('../../../../redraw-engine');
var rbac = require('../../../../rbac/rbac');

app.component('ScreenShare', {
    template: screenShareTpl,
    className: 'screen-share-container',
    Engine: null,
    canvas: null,
    context: null,
    image: null,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        var webRtcOut = false;

        // Previews
        this.frame = new Image();
        this.previewEngine = new RedrawEngine();
        this.liveStream = app.getService('LiveStreamService');
        this.webconferenceService = this.getService('WebconferenceService');

        this.frameBoxService = this.getService('FrameBoxService');
        this.frameboxes = [];
        this.activeFramebox = false;
        this.startScreenShare = false;

        this.canvas = document.createElement('canvas');
        this.context = this.canvas.getContext('2d');

        _.each(this.frameBoxService.frameboxes.boxes, function(framebox) {
            if (!framebox || ['webconference', 'zoom', 'teams'].indexOf(framebox.contentType) !== -1) {
                return;
            }

            webRtcOut = this.webconferenceService.screenShareActive && framebox.webRtcOut;
            if (webRtcOut) {
                this.activeFramebox = true;
            }

            this.frameboxes.push({
                index: framebox.index,
                coordinates: framebox.coordinates,
                contentType: framebox.contentType,
                active: webRtcOut,
                title: $(document)
                    .find('#framebox-control-bar-item[data-index="' + framebox.index + '"]')
                    .find('#framebox-title').text()
            });
        }.bind(this));
    },

    serialize: function() {
        return {
            items: this.frameboxes
        };
    },

    bindEvents: function() {
        this.on('screenshare.automatic.close', () => {
            this.automaticClose = true;
        });
    },

    postPlaceAt: function() {
        this.bindDOMEvents();
        this.bindEvents();
        this.showPreviews();
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.screen-share-item, .stop-sharing', this.selectScreen.bind(this));
    },

    /**
     * Handle screen selection.
     *
     * @param event Current event
     */
    selectScreen: function(event) {
        var $el = this.$(event.currentTarget);
        var index = $el.data('index');

        if (index < 0 || index === undefined) { // -1 === all windows || undefined === stop sharing
            _.each(this.frameboxes, function(framebox) {
                this.deviceConnection
                    .send('setFrameboxControl', {
                        frameboxControl: 'webRtcOutOff',
                        frameBoxId: framebox.index
                    });
            }.bind(this));
        } else {
            this.deviceConnection
                .send('setFrameboxControl', {
                    frameboxControl: 'webRtcOutOn',
                    frameBoxId: index
                });
        }

        this.startScreenShare = true;

        if (this.options.configs.onSelect) {
            this.options.configs.onSelect(index);
        }

        this.emit('modal.close');
    },

    /**
     * Show Previews
     */
    showPreviews: function() {
        this.createPreviews();

        this.previewEngine.start();
        this.loadNextFrame();

        this.previewEngine.on('redraw.update', this.loadNextFrame.bind(this));
    },

    closePreviews: function() {
        this.previewEngine.stop();
        this.previewEngine.off('redraw.update', this.loadNextFrame.bind(this));
    },

    /**
     * Requests the next frame from the server and
     * update previews.
     *
     * @return {Object}
     */
    loadNextFrame: function() {
        if (!rbac.hasAccess('Livestream', 'load')) {
            return {};
        }

        this.deviceConnection
            .send('getPictureCbox', {
                pictureWidth: this.pictureDimensions.width,
                pictureHeight: this.pictureDimensions.height
            })
            .then(function(data) {
                this.createFrame(data.picture, function(image) {
                    this.image = image;

                    if (this.image) {
                        this.canvas.width = this.image.width;
                        this.canvas.height = this.image.height;

                        this.context.drawImage(this.image, 0, 0);

                        this.emit('screen-share.preview.update', {
                            image: this.canvas,
                            reference: {
                                width: this.pictureDimensions.width,
                                height: this.pictureDimensions.height
                            }
                        });
                    }
                }.bind(this));
            }.bind(this));

        return this;
    },

    /**
     * Update previews.
     *
     * @param image Picture/Frame from Cbox.
     */
    createPreviews: function() {
        this.pictureDimensions = this.liveStream.getSize();

        // All windows (index -1)
        this.createComponent({
            type: 'ScreenSharePreview',
            container: '#screen-share-preview--1',
            index: -1
        });

        _.each(this.frameboxes, function(framebox) {
            this.createComponent({
                type: 'ScreenSharePreview',
                container: '#screen-share-preview-' + framebox.index,
                coordinates: framebox.coordinates,
                index: framebox.index,
                icon: icons[framebox.contentType] || 'icon-share-screen'
            });
        }.bind(this));
    },

    /**
     * Render method of the livestream.
     *
     * @param data {Mixed}
     * @param callback {Function} called after the image has loaded
     */
    createFrame: function(data, callback) {
        var blob = new Blob([data], {
            type: 'image/jpeg'
        });
        var imageUrl = URL.createObjectURL(blob);

        this.frame.onload = function onload() {
            callback(this);
            URL.revokeObjectURL(this.src);
        };

        this.frame.onerror = function onerror() {
            URL.revokeObjectURL(this.src);
        };

        this.frame.src = imageUrl;

        return this;
    },

    /**
     * Close previews and send start screen share command (done/cancel).
     */
    destroy: function() {
        this.closePreviews();

        if (!this.automaticClose && !this.startScreenShare || this.startScreenShare) {
            this.webconferenceService.startScreenShare(this.startScreenShare);
        }
    }
});
