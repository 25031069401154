// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<nav id=\"framebox-visualizer-preset\"\n     class=\"framebox-nav-container\"\n     data-scrolling-spacing=\"60\">\n    <ul class=\"framebox-controls-submenu list list-centered\">\n        <li>\n            <button id=\"visualizer-recall-preset\" name=\"visualizer-recall-preset\" class=\"btn-no-style framebox-bar-item wide focusable\" >\n                <span class=\"framebox-header-preset framebox-header-control-state-recall-preset\">\n                    <span class=\"framebox-header-control-text no-icon\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"visualizer.recall_preset",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":71},"end":{"line":8,"column":103}}}))
    + "</span>\n                </span>\n            </button>\n        </li>\n        <li>\n            <button id=\"visualizer-save-preset\" name=\"visualizer-save-preset\" class=\"btn-no-style framebox-bar-item wide focusable\" >\n                <span class=\"framebox-header-preset framebox-header-control-state-save-preset\">\n                    <span class=\"framebox-header-control-text no-icon\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"visualizer.save_preset",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":71},"end":{"line":15,"column":101}}}))
    + "</span>\n                </span>\n            </button>\n        </li>\n    </ul>\n</nav>\n";
},"useData":true});
