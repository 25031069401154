'use strict';

const $ = require('jquery');
const app = require('../../../../app');
const _ = require('lodash');
const i18n = require('i18next');
const ControlsSubmenu = require('./../../controls-sub-menu/controls-sub-menu');

/**
 * Direction mapper
 *
 * @type {{pinchout: string, pinchin: string}}
 */
const directionMapper = {
    'pinchout': 'zoom-in',
    'pinchin': 'zoom-out'
};

/**
 * States for controls (auto-focus, freeze, power)
 * @type {{none: string, on: string, off: string}}
 */
const controlStates = {
    'none': 'none',
    'on': 'on',
    'off': 'off'
};

const visualizerModes = require('./../../../../states').visualizerModes;

/**
 * Control items for VZ.
 * @type {[*]}
 */
const controlItems = [
    {
        id: 'power',
        state: 'power',
        states: {
            'power': {
                icon: 'icon-v2-power',
                titleKey: ''
            }
        }
    },
    {
        id: 'light',
        state: 'light',
        hidden: true,
        states: {
            'light': {
                icon: 'icon-v2-light',
                titleKey: ''
            }
        },
        groupEnd: true
    },
    {
        id: 'zoom-out',
        state: 'zoom-out',
        useHold: true,
        states: {
            'zoom-out': {
                icon: 'icon-v2-zoom-out',
                titleKey: ''
            }
        }
    },
    {
        id: 'zoom-in',
        state: 'zoom-in',
        useHold: true,
        states: {
            'zoom-in': {
                icon: 'icon-v2-zoom-in',
                titleKey: ''
            }
        },
        groupEnd: true
    },
    {
        id: 'focus',
        state: 'focus',
        useHold: false,
        states: {
            'focus': {
                icon: 'icon-v2-focus-near',
                titleKey: ''
            }
        },
        hasSubMenu: true
    },
    {
        id: 'auto-focus',
        state: 'off',
        states: {
            'on': {
                icon: 'icon-v2-auto-focus',
                isActive: true,
                titleKey: ''
            },
            'off': {
                icon: 'icon-v2-auto-focus',
                titleKey: ''
            }
        }
    },
    {
        id: 'freeze',
        state: 'off',
        states: {
            'on': {
                icon: 'icon-v2-freeze',
                isActive: true,
                titleKey: ''
            },
            'off': {
                icon: 'icon-v2-freeze',
                titleKey: ''
            }
        }
    },
    {
        id: 'preset',
        state: 'preset',
        useHold: false,
        hidden: true,
        states: {
            'preset': {
                icon: 'icon-v2-presets',
                titleKey: 'visualizer.preset'
            }
        },
        hasSubMenu: true,
        wide: true
    },
    {
        id: 'capture-area-shift',
        state: 'capture-area-shift',
        hidden: true,
        noIcon: true,
        states: {
            'capture-area-shift': {
                titleKey: 'visualizer.capture_area_shift'
            }
        },
        wide: true
    }
];

/**
 * Visualizer frame
 */
app.component('FrameBoxVisualizer', {
    className: 'framebox-view framebox-visualizer-view',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    /**
     * Init component.
     */
    initialize: function() {
        this.isScrollable = false;
        this.focusComponentId = null;
        this.presetComponentId = null;
        this.componentId = null;
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.animateStateEffect = false;
        this.releaseTimeout = null;
        this.previousDistance = false;
        this.previousSpeed = 1;
        this.previousEvent = null;
        this.isActive = true;
        this.visualizerService = this.getService('VisualizerService');
        this.freezeService = app.getService('FreezeService');
        this.frameBoxService = app.getService('FrameBoxService');
        this.liveStream = app.getService('LiveStreamService');
        this.deviceService = this.getService('DeviceService');

        this.afState = this.createStateMachine({
            state: controlStates.none,
            states: controlStates
        });

        this.freezeState = this.createStateMachine({
            state: controlStates.none,
            states: controlStates
        });

        this.addStateTransitions();
        this.bindVzControlEvents();
        this.visualizerService.bindVzEvents();
    },

    /**
     * Called after DOM is ready.
     */
    postPlaceAt: function() {
        this.startControls();
        this.bindEvents();
    },

    /**
     * Bind events.
     */
    bindEvents: function() {
        // Standard-mode events.
        this.on('framebox.standard.pinch', this.onPinchHandler.bind(this));
        this.on('framebox.standard.pinchin', this.onPinchInOutHandler.bind(this));
        this.on('framebox.standard.pinchout', this.onPinchInOutHandler.bind(this));
        this.on('framebox.standard.pinchend', this.onPinchEnd.bind(this));
        this.on('framebox.standard.pinchcancel', this.onPinchEnd.bind(this));
        this.on('framebox.standard.tap', this.onTap.bind(this));

        this.on('framebox.standard.zoom-in.keydown', this.onPressedhandler.bind(this, 'zoom-in'));
        this.on('framebox.standard.zoom-in.keyup', this.onReleasedHandler.bind(this, 'zoom-in'));
        this.on('framebox.standard.zoom-out.keydown', this.onPressedhandler.bind(this, 'zoom-out'));
        this.on('framebox.standard.zoom-out.keyup', this.onReleasedHandler.bind(this, 'zoom-out'));
        this.on('framebox.controls.check', this.onScrollableChanged.bind(this));
        this.on('framebox.tab.changed', this.onTabChanged.bind(this));

        this.on('visualizer.mode.changed', this.onVisualizerModeChanged.bind(this));
    },

    /**
     * Bind VZ control events.
     */
    bindVzControlEvents: function() {
        this.on('visualizer.controls.update', this.onControlsUpdate.bind(this));
    },

    /**
     * Called when the visualizer mode has been changed.
     * e.g.: Network connection to visualizer connected or disconnected.
     */
    onVisualizerModeChanged: function() {
        this.startControls();
    },

    /**
     * Call specific handler if control is pressed.
     *
     * @param {Object} data.index Control index
     */
    onPressedhandler: function(command, data) {
        var handler = this.visualizerService.getPressedHandler(command, data);

        if (data.index === this.index) {
            handler.call(this.visualizerService);
        }
    },

    /**
     * Control is released.
     *
     * @param {Object} data.index Control index
     */
    onReleasedHandler: function(command, data) {
        var handler = this.visualizerService.getReleasedHandler(command, data);

        if (data.index === this.index) {
            handler.call(this.visualizerService);
        }
    },

    /**
     * Update control state.
     *
     * @param {string} id Control ID
     * @param {bool} hidden true/false
     */
    onUpdateControlState: function(id, hidden) {
        this.emit('framebox.control-state.hide', {
            index: this.index,
            id: id,
            hidden: hidden
        });
    },

    /**
     * Scrollable changed.
     *
     * @param {object} data Infos
     */
    onScrollableChanged: function(data) {
        this.isScrollable = data.isScrollable;
    },

    /**
     * Tab changed.
     *
     * @param options.index
     */
    onTabChanged: function(options) {
        if (this.focusComponentId !== null) {
            app.destroyComponent(this.focusComponentId);
            this.focusComponentId = null;
        }

        if (this.presetComponentId !== null) {
            app.destroyComponent(this.presetComponentId);
            this.presetComponentId = null;
        }

        if (this.index === options.index) {
            this.isActive = true;
        } else {
            this.isActive = false;
        }
    },

    createFocusSubmenu: function() {
        const items = [
            {
                id: 'focus-near',
                icon: 'icon-v2-focus-near',
                title: i18n.t('visualizer.focus_in'),
                onPress: function(event) {
                    event.preventDefault();
                    const handler = this.visualizerService.getPressedHandler('focus-in', {
                        index: this.index,
                        appId: this.appId
                    });
                    handler.call(this.visualizerService);
                }.bind(this),
                onRelease: function() {
                    const handler = this.visualizerService.getReleasedHandler('focus-in', {
                        index: this.index,
                        appId: this.appId
                    });
                    handler.call(this.visualizerService);
                }.bind(this)
            },
            {
                id: 'focus-far',
                icon: 'icon-v2-focus-far',
                title: i18n.t('visualizer.focus_out'),
                onPress: function(event) {
                    event.preventDefault();
                    const handler = this.visualizerService.getPressedHandler('focus-out', {
                        index: this.index,
                        appId: this.appId
                    });
                    handler.call(this.visualizerService);
                }.bind(this),
                onRelease: function() {
                    const handler = this.visualizerService.getReleasedHandler('focus-out', {
                        index: this.index,
                        appId: this.appId
                    });
                    handler.call(this.visualizerService);
                }.bind(this)
            }
        ];

        this.createSubmenu('focus', items, this.$el.find('.sub-menu-container.is-focus'));
    },

    createPresetSubmenu: function() {
        const items = [
            {
                id: 'visualizer-save-preset',
                icon: 'icon-v2-save',
                title: i18n.t('visualizer.set_preset'),
                onPress: function(event) {
                    event.preventDefault();
                    const handler = this.visualizerService.getPressedHandler('set-preset', {
                        index: this.index,
                        appId: this.appId
                    });
                    handler.call(this.visualizerService);
                    this.emit('framebox.submenu.close-all');
                }.bind(this),
                onRelease: function() {
                    const handler = this.visualizerService.getReleasedHandler('set-preset', {
                        index: this.index,
                        appId: this.appId
                    });
                    handler.call(this.visualizerService);
                }.bind(this)

            },
            {
                id: 'visualizer-recall-preset',
                icon: 'icon-mmt-restart',
                title: i18n.t('visualizer.recall_preset'),
                onPress: function(event) {
                    event.preventDefault();
                    const handler = this.visualizerService.getPressedHandler('recall-preset', {
                        index: this.index,
                        appId: this.appId
                    });
                    handler.call(this.visualizerService);
                    this.emit('framebox.submenu.close-all');
                }.bind(this),
                onRelease: function() {
                    const handler = this.visualizerService.getReleasedHandler('recall-preset', {
                        index: this.index,
                        appId: this.appId
                    });
                    handler.call(this.visualizerService);
                }.bind(this)
            }
        ];

        this.createSubmenu('preset', items, this.$el.find('.sub-menu-container.is-preset'));
    },

    /**
     * Create sub-menu instance and add it to the specified container.
     */
    createSubmenu: function(id, items, container) {
        const subMenu = new ControlsSubmenu(this.app, {
            id: id,
            index: this.index,
            appId: this.appId,
            items: items
        });
        subMenu.render(container);
    },

    /**
     * Create and start controls.
     */
    startControls: function() {
        var data = _.clone(controlItems);

        if (visualizerModes.none === this.visualizerService.mode.getState()) {
            data = [{
                id: 'message',
                type: 'message',
                transKey: 'framebox.visualizer_not_connected',
                groupEnd: true
            }];
        } else if (visualizerModes.security === this.visualizerService.mode.getState()) {
            _.remove(data, function(el) {
                return el.id === 'freeze';
            });
        }

        if (this.componentId) {
            this.app.destroyComponent(this.componentId);
        }

        this.componentId = this.createComponent({
            type: 'FrameBoxControls',
            container: this.$el,
            index: this.options.index,
            appId: this.options.appId,
            items: data,
            onClick: this.onControlClickHandler.bind(this),
            onHoldStart: this.onControlHoldStartHandler.bind(this),
            onHoldEnd: this.onControlReleasedhandler.bind(this),
            onHold: this.onControlHold.bind(this),
            isFullscreen: this.options.isFullscreen
        });

        if (this.deviceService.isCboxProDualProjection()) {
            // VZ controls in edit mode (control screen) have flat sub-menus
            this.createFocusSubmenu();
            this.createPresetSubmenu();
        }
    },

    /**
     * Update controls of control bar.
     * Get states from visualizer service, hide/show feature controls and update states and icons.
     */
    onControlsUpdate: function() {
        var afState = this.visualizerService.afState.currentState ? controlStates.on : controlStates.off;
        var freezeState = this.visualizerService.freezeState.currentState ? controlStates.on : controlStates.off;

        if (this.visualizerService.features) {
            if (this.visualizerService.features.light) {
                this.emit('framebox.control-state.hide', {
                    index: this.index,
                    id: 'light',
                    hidden: false
                });
            }

            if (this.visualizerService.features.preset) {
                this.emit('framebox.control-state.hide', {
                    index: this.index,
                    id: 'preset',
                    hidden: false
                });
            }

            if (this.visualizerService.features.captureAreaShift) {
                this.emit('framebox.control-state.hide', {
                    index: this.index,
                    id: 'capture-area-shift',
                    hidden: false
                });
            }
        }

        if (this.afState.getState() !== afState) {
            this.afState.changeState(afState);
        }

        if (this.freezeState.getState() !== freezeState) {
            this.freezeState.changeState(freezeState);
        }

        if (!this.isActive && this.options.isFullscreen && this.animateStateEffect === true) {
            this.animateStateEffect = false;
        } else if (this.isActive && !this.animateStateEffect) {
            this.animateStateEffect = true;
        }
    },

    /**
     * Handle pinches.
     */
    onPinchHandler: function(options) {
        if (options.index !== this.index) {
            return;
        }

        clearTimeout(this.releaseTimeout);
        this.releaseTimeout = setTimeout(this.onPinchEnd.bind(this, options), 300);
    },

    /**
     * Handle pinches (in/out)
     *
     * @param options.event Pinch event
     */
    onPinchInOutHandler: function(options) {
        var event = options.event;
        var speed = 0;

        if (options.index !== this.index) {
            return;
        }

        if (!this.previousEvent || event.type !== this.previousEvent) {
            this.previousDistance = 0;
            this.previousSpeed = 0;
        } else {
            speed = this.previousSpeed;
        }

        // If you hold the position with the fingers, distance returns 0, so hold the speed from the last calculation.
        if ((event.distance - this.previousDistance) >= 3) {
            speed = this.previousSpeed += 1;
        }

        if (speed > 16) {
            speed = 16;
        }

        this.previousDistance = event.distance;

        // Check if there is any changes on the zoom direction or speed then update the zoom-handler.
        if (this.previousEvent !== event.type || speed !== this.previousSpeed) {
            this.onPressedhandler(directionMapper[event.type], {
                speed: speed,
                index: options.index,
                appId: options.appId
            });

            this.previousEvent = event.type;
            this.previousSpeed = speed;
        }
    },

    /**
     * Handle pinch if ended.
     */
    onPinchEnd: function(options) {
        if (options.index !== this.index) {
            return;
        }

        if (this.previousEvent) {
            this.visualizerService.stopZoom(options);
            this.releaseTimeout = null;
            this.previousEvent = false;
            this.previousDistance = false;
            this.previousSpeed = 0;
        }
    },

    /**
     * Handle Tap --> send 9-zones or 1-zone focus action if supported by VZ.
     *
     * @param data
     */
    onTap: function(data) {
        const hasOPAF9 = this.visualizerService?.features?.opaf9;
        const hasOPAF = this.visualizerService?.features?.opaf && !hasOPAF9;

        if (data.index !== this.index || (!hasOPAF9 && !hasOPAF)) {
            return;
        }

        var frameboxX = 0;
        var frameboxY = 0;

        if (!this.frameBoxService.frameboxes.boxes[this.index].fullscreen) {
            frameboxX = this.frameBoxService.frameboxes.boxes[this.index].coordinates.x;
            frameboxY = this.frameBoxService.frameboxes.boxes[this.index].coordinates.y;
        }

        this.calculateTouchAreas(hasOPAF9);

        var x = data.event.center.x - frameboxX - this.liveStream.getOffset().left;
        var y = data.event.center.y - frameboxY - this.liveStream.getOffset().top;

        _.each(this.touchAreas, function(area, index) {
            if (x >= area.xMin
                && x <= area.xMax
                && y >= area.yMin
                && y <= area.yMax) {
                this.$frameboxFocus.css('top',  this.touchAreas[index].yMin + 'px');
                this.$frameboxFocus.css('left',  this.touchAreas[index].xMin + 'px');

                this.deviceConnection
                    .send('setVzControl', {
                        vzControl: 'focusOnepush',
                        index: hasOPAF9 ? index + 1 : index,
                        appId: data.appId
                    }).then(function() {
                        this.$frameboxFocus
                            .css('display', 'flex')
                            .stop()
                            .fadeOut(1000);
                    }.bind(this));
            }
        }.bind(this));
    },

    /**
     * Handle click on control.
     *
     * @param id Control ID
     */
    onControlClickHandler: function(id) {
        const handler = this.visualizerService.pressed[id];
        const el = this.$el.find('#framebox-' + this.options.index + '-header-control-' + id);

        if (this.freezeService.isFreeze()) {
            return;
        }

        this.emit('main-loop.fast.start', {
            id: 'visualizer'
        });

        if (handler) {
            handler.call(this.visualizerService, {
                index: this.options.index,
                appId: this.options.appId
            });
            this.emit('framebox.focus.close');
            this.emit('framebox.preset.close');
        } else if (id === 'focus') {
            if (this.deviceService.isCboxProDualProjection()) {
                this.emit('framebox.submenu.toggle', {
                    id: id,
                    el: el
                });

                return;
            }

            if (this.focusComponentId === null) {
                this.focusComponentId = this.createComponent({
                    type: 'FrameboxFocus',
                    container: this.$el.find('.framebox-header-focus'),
                    index: this.options.index,
                    appId: this.options.appId
                });
            }

            this.emit('framebox.focus.open', {
                index: this.options.index
            });
        } else if (id === 'preset') {
            if (this.deviceService.isCboxProDualProjection()) {
                this.emit('framebox.submenu.toggle', {
                    id: id,
                    el: el
                });

                return;
            }

            if (this.presetComponentId === null) {
                this.presetComponentId = this.createComponent({
                    type: 'FrameboxPreset',
                    container: this.$el.find('.framebox-header-preset'),
                    index: this.options.index,
                    appId: this.options.appId
                });
            }

            this.emit('framebox.preset.open', {
                index: this.options.index,
                appId: this.options.appId
            });
        }
    },

    /**
     * Handle start of control hold events.
     *
     * @param {string} id Control ID
     */
    onControlHoldStartHandler: function(id) {
        var handler;

        if (this.freezeService.getFreeze()) {
            return;
        }

        handler = this.visualizerService.getPressedHandler(id, {
            index: this.index,
            appId: this.appId
        });

        if (handler) {
            handler.call(this.visualizerService);
        }
    },

    /**
     * Handle control hold event.
     *
     * @param {string} id Control ID
     */
    onControlHold: function(id) {
        clearTimeout(this.releaseTimeout);

        if (this.freezeService.getFreeze()) {
            return;
        }

        this.releaseTimeout = setTimeout(this.onControlReleasedhandler.bind(this, id), 200);
        this.emit('control-bar-timer.start');
        this.emit('activities.update');
    },

    /**
     * Handle release of control hold event.
     *
     * @param {string} id Control ID
     */
    onControlReleasedhandler: function(id) {
        var handler = this.visualizerService.getReleasedHandler(id, {
            index: this.index,
            appId: this.appId
        });

        handler.call(this.visualizerService);
    },

    /**
     * Add state transitions of auto-focus and freeze state
     */
    addStateTransitions: function() {
        this.afState.addTransitions({
            '> on': function() {
                this.emit('framebox.control-state.change', {
                    index: this.index,
                    id: 'auto-focus',
                    state: controlStates.on
                });

                if (this.animateStateEffect) {
                    this.emit('framebox.play-state-effect', {
                        index: this.options.index,
                        className: 'visualizer-state-af-on-effect'
                    });
                }
            },

            '> off': function() {
                this.emit('framebox.control-state.change', {
                    index: this.index,
                    id: 'auto-focus',
                    state: controlStates.off
                });

                if (this.animateStateEffect) {
                    this.emit('framebox.play-state-effect', {
                        index: this.options.index,
                        className: 'visualizer-state-af-off-effect'
                    });
                }
            }
        });

        this.freezeState.addTransitions({
            '> on': function() {
                this.emit('framebox.control-state.change', {
                    index: this.index,
                    id: 'freeze',
                    state: controlStates.on
                });

                if (this.animateStateEffect) {
                    this.emit('framebox.play-state-effect', {
                        index: this.options.index,
                        className: 'visualizer-state-freeze-on-effect'
                    });
                }
            },

            '> off': function() {
                this.emit('framebox.control-state.change', {
                    index: this.index,
                    id: 'freeze',
                    state: controlStates.off
                });

                if (this.animateStateEffect) {
                    this.emit('framebox.play-state-effect', {
                        index: this.options.index,
                        className: 'visualizer-state-freeze-off-effect'
                    });
                }
            }
        });
    },

    /**
     * Calculate touch areas for 9-zones or 1-zone One Push Auto Focus (OPAF).
     *
     */
    calculateTouchAreas: function(hasOPAF9) {
        let width;
        let height;

        if (this.frameBoxService.frameboxes.boxes[this.index].fullscreen) {
            width = $(document).find('.frameboxes-container').width();
            height = $(document).find('.frameboxes-container').height();

            this.$frameboxFocus = $(document).find('.framebox-fullscreen-content').find('.framebox-focus-state-effect');
        } else {
            this.$frameboxFocus = $(document).find('.framebox-ui[data-index="' + this.index + '"]').find('.framebox-focus-state-effect');

            width = this.frameBoxService.frameboxes.boxes[this.index].coordinates.width;
            height = this.frameBoxService.frameboxes.boxes[this.index].coordinates.height;
        }

        // Has 1-zone OPAF
        if (!hasOPAF9) {
            this.$frameboxFocus.width(width + 'px');
            this.$frameboxFocus.height(height + 'px');
            this.touchAreas = [{ xMin: 0, xMax: width, yMin: 0, yMax: height }];

            return;
        }

        // Has 9-zones OPAF
        const oneThirdWidth = width / 3;
        const oneThirdHeight = height / 3;

        this.$frameboxFocus.width(oneThirdWidth + 'px');
        this.$frameboxFocus.height(oneThirdHeight + 'px');

        this.touchAreas = [];

        for (var i = 1; i < 10; i++) {
            var temp = {};

            if (i <= 3) {
                temp.xMin = oneThirdWidth * (i - 1);
                temp.xMax = oneThirdWidth * i;
                temp.yMin = 0;
                temp.yMax = oneThirdHeight;
            } else if (i > 3 && i <= 6) {
                temp.xMin = oneThirdWidth * (i - 4);
                temp.xMax = oneThirdWidth * (i - 3);
                temp.yMin = oneThirdHeight * 1;
                temp.yMax = oneThirdHeight * 2;
            } else if (i > 6) {
                temp.xMin = oneThirdWidth * (i - 7);
                temp.xMax = oneThirdWidth * (i - 6);
                temp.yMin = oneThirdHeight * 2;
                temp.yMax = oneThirdHeight * 3;
            }

            this.touchAreas.push(temp);
        }
    },

    /**
     * Destroy component.
     */
    destroy: function() {
        this.visualizerService.unbindVzEvents();
    }
});
