/* jslint bitwise: true */

'use strict';

var StringConvert = {};

/**
 * Calculates the number of bytes that are necessary for the given value
 *
 * @param value: the value where we want to know the minimum amount of bytes
 * @return: the number of bytes
 */
function calcNumBytes(value) {
    for (var i = 0; value >= 1; i++) {
        value = value / 256;
    }

    return i || 1;
}

/**
 * Converts a character value in utf8 to a character value in unicode
 *
 * @param value: the utf8 value that should be converted to utf8
 * @return: utf8 value
 */
StringConvert.utf8ToUnicodeValue = function(value) {
    if ((value >= 0x0000) && (value <= 0x007f)) {
        return value;
    } else if ((value >= 0xC080) && (value <= 0xDFBF)) {
        return ((value & 0x1F00) >> 2) | value & 0x3F;
    } else if ((value >= 0xE08080) && (value <= 0xefbfbf)) {
        return ((value & 0x0f0000) >> 4) | ((value & 0x3f00) >> 2) | (value & 0x3f);
    } else if ((value >= 0xf0808080) && (value <= 0xf7bfbfbf)) {
        return ((value & 0x03000000) >> 6) | ((value & 0x3f0000) >> 4) | ((value & 0x3f00) >> 2) | (value & 0x3f);
    } else {
        console.log('ERROR invalid UTF8:' + value);

        return '-'.charCodeAt(0);
    }
};

/**
 * Converts a character value in unicode to a character value in utf8
 *
 * @param value: the unicode value that should be converted to utf8
 * @return: utf8 value
 */
StringConvert.unicodeToUtf8Value = function(value) {
    if ((value >= 0x0000) && (value <= 0x007f)) {
        return value;
    } else if ((value >= 0x0080) && (value <= 0x007ff)) {
        return 0xC080 | ((value & 0x7c0) << 2) | (value & 0x3f);
    } else if ((value >= 0x0800) && (value <= 0xffff)) {
        return 0xe08080 | ((value & 0xf000) << 4) | ((value & 0xfc0) << 2) | value & 0x3f;
    } else if ((value >= 0x10000) && (value <= 0x0010ffff)) {
        return 0xf0808080 + ((value & 0x1c0000) * 0x40) + ((value & 0x3f000) * 0x10) + ((value & 0xfc0) * 0x04) + (value & 0x3f);
    } else {
        console.log('ERROR unknown Unicode:' + value);

        return '-'.charCodeAt(0);
    }
};

/**
 * Converts an unicode value to a string
 *
 * @param unicodeValue: the unicodevalue that should be converted to string
 * @return: string with contents of array
 */
StringConvert.unicodeValueToString = function(unicodeValue) {
    if (unicodeValue <= 0xFFFF) {
        return String.fromCharCode(unicodeValue);
    } else {
        var highSurrogate;
        var lowSurrogate;
        var array = [];

        // We need to convert to UTF16 if bigger than 0xFFFF
        // In order that JS understands
        unicodeValue -= 0x10000;
        highSurrogate = (unicodeValue >> 10) + 0xD800;
        lowSurrogate = (unicodeValue % 0x400) + 0xDC00;
        array.push(highSurrogate, lowSurrogate);

        return String.fromCharCode.apply(null, array);
    }
};

/**
 * Converts an utf8 encoded array to a string
 *
 * @param array: an utf8 encoded array
 * @return: string with contents of array
 */
StringConvert.utf8ToString = function(array) {
    var i;
    var string = '';

    for (i = 0; i < array.length;) {
        if (array[i] <= 0x7f) {
            string += this.unicodeValueToString(this.utf8ToUnicodeValue(array[i]));
            i++;
        } else if (array[i] <= 0xDF) {
            string += this.unicodeValueToString(this.utf8ToUnicodeValue((array[i] << 8) | array[i + 1]));
            i += 2;
        } else if (array[i] <= 0xEF) {
            string += this.unicodeValueToString(this.utf8ToUnicodeValue((array[i] << 16) | (array[i + 1] << 8) | array[i + 2]));
            i += 3;
        } else if (array[i] <= 0xFE) {
            string += this.unicodeValueToString(this.utf8ToUnicodeValue((array[i] * 0x1000000) + (array[i + 1] * 0x10000) + (array[i + 2] * 0x100) + array[i + 3]));
            i += 4;
        } else {
            i++;    // Happens only if string is no valid utf8 string
        }
    }

    return string;
};

/**
 * Converts a string to an Utf16 Array
 *
 * @param string: the string containing the ip address
 * @return: an int containing the ip address
 */
StringConvert.stringToUtf16 = function(string) {
    var i;
    var array = [];
    var current;
    var next;

    string = string || '';

    for (i = 0; i < string.length;) {
        current = string.charCodeAt(i);

        // Check if it's a high surrogate
        if ((current >= 0xD800) && (current <= 0xDBFF) && (string.length > i + 1)) {
            next = string.charCodeAt(i + 1);
            // Check if the next is a low surrogate
            if ((next >= 0xDC00) && (next <= 0xDFFF)) { // Low surrogate
                array.push((current - 0xD800) * 0x400 + next - 0xDC00 + 0x10000);

                i += 2;
            } else { // Should not happen
                array.push(current);
                i++;
            }
        } else {
            array.push(current);
            i++;
        }
    }

    return array;
};

/**
 * Converts from a string to a utf8 encoded Uint8Array
 *
 * @param string: the string that should be converted
 * @return: an UTF8 encoded Uint8Array
 */
StringConvert.stringToUtf8 = function(string) {
    var utf16Array = this.stringToUtf16(string);
    var utf8Array = [];
    var uft8ByteArray;
    var i;
    var byteLength = 0;
    var temp;

    string = string || '';

    for (i = 0; i < utf16Array.length; i++) {
        temp = this.unicodeToUtf8Value(utf16Array[i]);

        byteLength = calcNumBytes(temp);

        while (byteLength--) {
            utf8Array.push(temp >> (byteLength * 8));
        }
    }
    uft8ByteArray = new Uint8Array(utf8Array);

    return uft8ByteArray;
};

module.exports = StringConvert;
