'use strict';

var app = require('../../app');
var btnTpl = require('./button.hbs');

app.component('Button', {
    template: btnTpl,
    className: 'btn-container',

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    serialize: function() {
        return {
            title: this.options.title || false,
            titleKey: this.options.titleKey
        };
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.bindDOMEvents();
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.$el.on('click', '.form-action-submit', this.onBtnClickHandler.bind(this));
    },

    onBtnClickHandler: function() {
        if (this.options.onClick) {
            this.options.onClick();
        }
    }
});
