'use strict';

var _ = require('lodash');
var $ = require('jquery');

function View(app, configs) {
    /**
     * Reference to the app instance
     * @property app
     */
    this.app = app;

    /**
     * JQuery Reference to component element
     * @property $el
     */
    this.$el = $('#modal-container .modal-container');

    /**
     * The container selector where the component should be placed at
     * @type {Mixed}
     */
    this.componentContainer = null;

    /**
     * The view id
     * @type {String}
     */
    this.id = _.uniqueId('modalView');

    /**
     * Here we get the overlay config
     */
    this.configs = configs;

    /**
     * True will show the backdrop
     * @type {boolean}
     */
    this.useBackdrop = true;
}

/**
 * Save the component blueprint name
 * @method addComponent
 * @param  {String}     componentType Name of the component blueprint
 */
View.prototype.addComponent = function addComponent(componentType) {
    this.componentType = componentType;

    return this;
};

/**
 * Render the view and initialize the used component
 * @method render
 */
View.prototype.render = function render(onComplete) {
    var $tpl = $(this.template(this.configs));

    if (this.className) {
        $tpl.addClass(this.className);
    }

    if (this.idName) {
        $tpl.attr('id', this.idName);
    }

    this.$el.find('#modal-view-container .modal').remove();
    this.$el.show().find('#modal-view-container').append($tpl);

    this.app.createComponent(this.id, {
        type: this.componentType,
        container: this.$el.find(this.componentContainer),
        configs: this.configs
    });

    this.animateOpen(onComplete);

    return this;
};

/**
 * @method animateOpen
 * @param  {function} onComplete
 */
View.prototype.animateOpen = function animateOpen(onComplete) {
    this.$el
        .velocity('stop')
        .velocity('fadeIn', {
            duration: 250,
            complete: onComplete
        });
};

/**
 * After the view has been rendered place it inside the dom
 * @method placeAt
 * @param  {Mixed} container Container element for the view
 * @chainable
 */
View.prototype.placeAt = function placeAt(container) {
    $(container).append(this.$el);
    this.app.getComponent(this.id).$el.trigger('modal.update');

    return this;
};

/**
 * Removes the view and the used component instance
 * @method destroy
 * @chainable
 */
View.prototype.destroy = function destroy() {
    this.app.removeComponent(this.id);
    this.$el.off();
    this.$el.find('.modal').remove();

    return this;
};

module.exports = View;
