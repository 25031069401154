'use strict';

var app = require('../app');
var platform = require('../../platform/platform');
var StateMachine = require('./../state-machine');

/**
 * Override Output states
 */
var overrideMirrorState = {
    on: 'on',
    off: 'off'
};

/**
 * Content mode recording modes
 */
var contentRecordingMode = {
    main: 'main',
    aux: 'aux'
};

/**
 * @type {object}
 */
var overrideMirrorMapping = {
    0: 'off',
    1: 'on'
};

app.service('OutputService', function(app) {
    return {
        /**
         * @method initialize
         */
        initialize: function() {
            this.overrideOutput = new StateMachine({
                context: this,
                state: overrideMirrorState.off,
                states: overrideMirrorState
            });

            this.contentModeRecording = new StateMachine({
                context: this,
                state: contentRecordingMode.main,
                states: contentRecordingMode
            });

            this.mirrorMode = null;
            this.allowOverride = null;

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.bindEvents();
                    this.updateHandler();
                }.bind(this));

            this.addStateTransitions();
        },

        /**
         * @method bindEvents
         */
        bindEvents: function() {
            app.on('main-loop.update', this.updateHandler.bind(this));
            app.on('main-loop.update.freeze', this.updateHandler.bind(this));
        },

        /**
         * @method updateHandler
         */
        updateHandler: function() {
            if (app.getService('DeviceService').isCboxProDualProjection()) {
                // In dual-projection mode there are no output modes
                return;
            }

            this.deviceConnection
                .send([
                    'getAuxMode',
                    'getAuxMirrorOverride',
                    'getAuxAllowMirrorOverride',
                    'getContentModeRecordingSource'])
                .then(function(mode, mirrorOverride, allowOverride, contentModeRecording) {
                    var state = overrideMirrorMapping[mirrorOverride.override];

                    if (this.contentModeRecording.currentState !== contentModeRecording.mode) {
                        this.contentModeRecording.changeState(contentModeRecording.mode);
                    }

                    if (!!mirrorOverride && this.overrideOutput.getState() !== state) {
                        if (!this.isMirror()) {
                            this.overrideOutput.changeState(state);
                        }

                        app.emit('hdmi-out-2-mode.changed', {
                            mode: state,
                            auxMode: mode.auxMode
                        });
                    } else if (!!mode && this.mirrorMode !== mode.auxMode) {
                        var checkAuxState = false;

                        // Only check state if we the variable is already initialized --> prevent from redundant calls.
                        if (this.mirrorMode) {
                            checkAuxState = true;
                        }

                        // Ensure that mirrorMode is set before emitting event
                        this.mirrorMode = mode.auxMode;
                        app.emit('hdmi-out-2-mode.changed', {
                            mode: state,
                            auxMode: mode.auxMode
                        });

                        // RELEASE-3787: We need to reload the aux browser if we switch to moderator mode
                        // To be sure that every component is initialized correct.
                        if (checkAuxState && this.isModerator() && platform.checks.isCboxAux) {
                            window.location.reload();
                        }
                    }

                    if (!!allowOverride && this.allowOverride !== allowOverride.allow) {
                        app.emit('override-mirror.function.changed', {
                            override: allowOverride.allow
                        });
                        this.allowOverride = allowOverride.allow;

                        if (!allowOverride.allow) {
                            this.overrideOutput.changeState(overrideMirrorState.off);
                        }
                    } else if (this.overrideOutput.getState() === overrideMirrorState.on && !this.isMirror()) {
                        this.overrideOutput.changeState(overrideMirrorState.on);
                    } else if (this.overrideOutput.getState() === overrideMirrorState.on && this.isMirror()) {
                        this.overrideOutput.changeState(overrideMirrorState.off);
                    }

                    if (this.mirrorMode !== mode.auxMode) {
                        this.mirrorMode = mode.auxMode;

                        app.emit('hdmi-out-2-mode.changed', {
                            mode: state,
                            auxMode: mode.auxMode
                        });
                    }
                }.bind(this));
        },

        /**
         * @method getOutputMode
         */
        getOutputMode: function() {
            return this.overrideOutput.getState();
        },

        /**
         * @method getAuxMode
         */
        getAuxMode: function() {
            return this.mirrorMode;
        },

        /**
         * @method isModerator
         */
        isModerator: function() {
            return (!!(this.mirrorMode && this.mirrorMode.substring(0, 4) === 'mode' && this.overrideOutput.getState() === overrideMirrorState.off));
        },

        /**
         * @method isContent
         */
        isContent: function() {
            return (!!(this.mirrorMode && this.mirrorMode.substring(0, 4) === 'cont' && this.overrideOutput.getState() === overrideMirrorState.off));
        },

        /**
         * @method isMirror
         */
        isMirror: function() {
            return (this.mirrorMode && this.mirrorMode.substring(0, 4) === 'conf');
        },

        /**
         * Checks if override mirror is active
         */
        isOverrideMirror: function() {
            return (!!(!this.isMirror() && this.overrideOutput.getState() === overrideMirrorState.on));
        },

        /**
         * @method getOverrideMirror
         */
        getOverrideMirror: function() {
            return this.allowOverride;
        },

        /**
         * @method addStateTransitions
         */
        addStateTransitions: function() {
            this.overrideOutput.addTransitions({
                '> off': function() {
                    app.emit('status-widget.item.remove', {
                        id: 'overrideMirror'
                    });
                },

                '> on': function() {
                    app.emit('status-widget.item.append', {
                        id: 'overrideMirror',
                        accessKey: 'OverrideMirror',
                        options: {
                            icon: 'icon-v2-mirror',
                            clickable: false
                        }
                    });
                }
            });
        }
    };
});
