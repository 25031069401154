'use strict';

var app = require('../app');
var privateStorageData = {};

/**
 * PrivateStorage
 * @constructor
 */
var PrivateStorage = function() {

};

/**
 * @method set
 * @param {string} key
 * @param {mixed} value
 */
PrivateStorage.prototype.set = function(key, value) {
    var type = 'string';

    if (typeof value === 'object') {
        value = JSON.stringify(value);
        type = 'object';
    }

    var data = {
        type: type,
        value: value
    };

    privateStorageData[key] = JSON.stringify(data);
};

/**
 * @method get
 * @param {string} key
 * @returns {*}
 */
PrivateStorage.prototype.get = function(key, defaultValue) {
    var data;
    var value;

    if (!privateStorageData[key]) {
        return false;
    }

    data = app.getService('JSONService').parse(privateStorageData[key]);
    value = defaultValue;

    if (data) {
        value = data.value;

        if (data.type === 'object') {
            value = app.getService('JSONService').parse(value, {});
        }
    }

    return value;
};

/**
 * Remove item from PrivateStorage.
 *
 * @param {string} key
 */
PrivateStorage.prototype.remove = function(key) {
    delete privateStorageData[key];
};

/**
 * LocalStorage
 * @constructor
 */
var LocalStorage = function() {

};

/**
 * @method set
 * @param {string} key
 * @param {mixed} value
 */
LocalStorage.prototype.set = function(key, value) {
    var type = 'string';

    if (typeof value === 'object') {
        value = JSON.stringify(value);
        type = 'object';
    }

    var data = {
        type: type,
        value: value
    };

    localStorage.setItem(key, JSON.stringify(data));
};

/**
 * @method isSupported
 * @returns {boolean}
 */
LocalStorage.prototype.isSupported = function() {
    var testKey = 'testKey';
    var storage = window.localStorage;

    try {
        if (window.localStorage) {
            storage.setItem(testKey, '1');
            storage.removeItem(testKey);

            return true;
        }
    } catch (error) {
        return false;
    }

    return false;
};

/**
 * @method get
 * @param {string} key
 * @returns {*}
 */
LocalStorage.prototype.get = function(key, defaultValue) {
    var data = app.getService('JSONService').parse(localStorage.getItem(key));
    var value = defaultValue;

    if (data) {
        value = data.value;

        if (data.type === 'object') {
            value = app.getService('JSONService').parse(value, {});
        }
    }

    return value;
};

/**
 * Remove item from localstorage.
 *
 * @param {string} key
 */
LocalStorage.prototype.remove = function(key) {
    localStorage.removeItem(key);
};

/**
 * DataStorage
 * @constructor
 */
var DataStorage = function() {
    this.adapter = {};
    this.loadAdapter();
    this.use('cynap', 'localStorage');
};

/**
 * @method loadAdapter
 */
DataStorage.prototype.loadAdapter = function() {
    this.adapter.localStorage = new LocalStorage();

    if (!this.adapter.localStorage.isSupported()) {
        this.adapter.localStorage = new PrivateStorage();
    }
};

/**
 * @method use
 * @param {string} namespace
 * @param {string} type
 */
DataStorage.prototype.use = function(namespace, type) {
    if (namespace) {
        this.namespace = namespace;
    }

    if (!!type && !!this.adapter[type]) {
        this.type = type;
    }
};

/**
 * @method set
 * @param {string} key
 * @param {mixed} value
 */
DataStorage.prototype.set = function(key, value) {
    var finalKey = this.getKey(key);
    this.adapter[this.type].set(finalKey, value);

    app.emit(finalKey + '.changed', {
        value: value
    });
};

/**
 * @method get
 * @param {string} key
 * @returns {mixed}
 */
DataStorage.prototype.get = function(key, defaultValue) {
    var finalKey = this.getKey(key);

    return this.adapter[this.type].get(finalKey, defaultValue);
};

/**
 * Remove item from Storage.
 *
 * @param {string} key
 */
DataStorage.prototype.remove = function(key) {
    var finalKey = this.getKey(key);

    return this.adapter[this.type].remove(finalKey);
};

/**
 * @method getKey
 * @param {string} key
 * @returns {string}
 */
DataStorage.prototype.getKey = function(key) {
    return this.namespace + '.' + key;
};

app.service('DataStorage', function() {
    var dataStore = new DataStorage();

    return dataStore;
});
