const Vuex = require('vuex');
const template = require('./matrix-command-buttons.html');
const vueUtils = require('../../../../../components-vue/util.js');
const app = require('../../../../../app');

module.exports = {
    template,
    components: {
        'command-button': require('../../../../../components-vue/control-center-v3/command-button/command-button')
    },

    props: {
        monitor: {
            type: Object,
            required: true
        }
    },

    data: function() {
        return {
        };
    },

    computed: {
        output: function() {
            return this.monitor.outputPort;
        },
        containerWidth: function() {
            return this.monitor.width;
        },
        ...Vuex.mapGetters('matrix', ['isPushActive', 'isShareToAllEnabled', 'isShareToAllActive', 'isShareToGroupActive', 'getPushableStationsFromGroup']),
        ...Vuex.mapGetters('matrix', { groups: 'getCurrentGroups', showCommandButtons: 'getShowCommandButtons' })
    },

    methods: {
        positionLeft: function() {
            return this.output === 'hdmi1'
                ? this.monitor.x - this.containerWidth
                : this.monitor.x + this.monitor.width;
        },
        toggleShareToAll: function() {
            this.matrixService.setMatrixMasterPush(0, Number(!this.isShareToAllActive(this.output)), this.output);

            app.emit('main-loop.fast.start', {
                id: 'matrix'
            });
        },
        toggleShareToGroup: function(groupId) {
            const stationsFromGroup = this.getPushableStationsFromGroup(groupId);
            for (let i = 0; i < stationsFromGroup.length; i++) {
                this.matrixService.setMatrixMasterPush(Number(stationsFromGroup[i].serial), Number(!this.isShareToGroupActive(this.output, groupId)), this.output);
            }

            app.emit('main-loop.fast.start', {
                id: 'matrix'
            });
        },
        stopAll: function() {
            this.matrixService.setMatrixMasterPush(0, 0, this.output);
        },
        i18n: vueUtils.i18n
    },

    created: function() {
        this.matrixService = app.getService('MatrixService');
    }
};
