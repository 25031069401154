'use strict';

var app = require('../../../../app');
var $ = require('jquery');
var recordSettingsTpl = require('./record-general-settings.hbs');
var FormActionView = require('../../form-action-view');
const i18n = require('i18next');

app.component('RecordGeneralSettings', {
    template: recordSettingsTpl,
    actionView: null,

    getAccessKey: function() {
        return {
            'roleName': 'RecordSettings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.formData = {};
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.deviceService = this.getService('DeviceService');
    },

    postPlaceAt: function() {
        this.createSelects();
        this.storeSelectors();

        $.when(this.loadRecordSettings()).done(function() {
            this.initForm();
            this.initFormAction();
            this.updateForm();
            this.handleHdcpSupport();
            this.handleLocalRecordingFunction();

            this.bindEvents();
            this.bindDomEvents();
        }.bind(this));
    },

    bindDomEvents: function() {
        this.form.$el.on('keyup, change', this.handleFormChange.bind(this));
    },

    bindEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.on('overlay.action', this.saveHandler.bind(this));
        this.on('remote.enter.keydown', this.checkActionForm.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
        this.on('settings.cancel-changes', this.cancelHandler.bind(this));
    },

    serialize: function() {
        return {
            functions: i18n.t('settings.record_title')
        };
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#record-general-settings'),
            validationContainer: '.input-group'
        });
    },

    storeSelectors: function() {
        this.$localRecordingSettingsContainer = this.$el.find('#local-recording-settings-container');
        this.$hdcpDeactivationInfo = this.$el.find('.hdcp-deactivation-info');
    },

    saveHandler: function() {
        if (this.form.validate()) {
            var data = this.form.serialize();

            let commands = [
                {
                    command: 'setRecordingResolution',
                    data: { recordingResolution: data.resolution }
                },
                {
                    command: 'setRecordingFramerate',
                    data: { framerate: data.framerate }
                },
                {
                    command: 'setRecordingFunction',
                    data: { recordingEnabled: data.recordingEnable }
                },
                {
                    command: 'setRecordingRename',
                    data: { recordingRenameEnabled: data.recordingRenameEnabled }
                }
            ];

            return this.deviceConnection
                .send(commands)
                .then(function() {
                    this.handleRecordSave();
                    this.form.setDefaultValues();
                    this.handleHdcpSupport(true);
                    this.emit('record-settings.saved');
                    this.emit('overlay.remote.focus', true);
                }.bind(this));
        }
    },

    /**
     * Calls on clicked the cancel button
     * @method cancelHandler
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
        this.actionView.close();
        this.handleHdcpSupport();
        this.handleLocalRecordingFunction();

        this.handleChanges(false);
    },

    handleRecordSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.actionView.close();
        this.handleChanges(false);
    },

    /**
     * Show/hide HDCP deactivation info message.
     *
     * @param forceHide If true info message will be hidden anyway
     */
    handleHdcpSupport: function(forceHide) {
        if (!this.deviceService.isCboxCorePro()) {
            return;
        }

        if (forceHide) {
            this.$hdcpDeactivationInfo.hide();

            return;
        }

        if (this.form.get('recordingEnable').getValue() && this.hdmi1HdcpSupportEnabled) {
            this.$hdcpDeactivationInfo.show();
        } else {
            this.$hdcpDeactivationInfo.hide();
        }
    },

    /**
     * Show/hide local recording sub-settings.
     */
    handleLocalRecordingFunction: function() {
        if (this.form.get('recordingEnable').getValue()) {
            this.$localRecordingSettingsContainer.show();
        } else {
            this.$localRecordingSettingsContainer.hide();
        }
    },

    handleFormChange: function(data) {
        this.actionView.open();
        this.remote.update();
        this.checkActionForm();

        if (data?.input?.name === 'recordingEnable') {
            this.handleHdcpSupport();
            this.handleLocalRecordingFunction();
        }

        this.handleChanges(true);
    },

    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    loadRecordSettings: function() {
        let commands = [
            'getRecordingResolution',
            'getRecordingFramerate',
            'getRecordingFunction',
            'getRecordingRename',
            'getHdmiInput1HdcpSupport'
        ];

        return this.deviceConnection
            .send(commands)
            .then(function(resolution, recFramerate, recEnabled, recRenaming, hdmi1HdcpSupport) {
                this.formData = {
                    resolution: resolution.recordingResolution,
                    framerate: recFramerate.framerate,
                    recordingEnable: recEnabled.enabled,
                    recordingRenameEnabled: recRenaming.enabled
                };

                this.hdmi1HdcpSupportEnabled = hdmi1HdcpSupport.enabled;
            }.bind(this));
    },

    updateForm: function() {
        this.form.setValues(this.formData);
    },

    createSelects: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#resolution-select'),
            label: 'settings.resolution',
            native: true,
            name: 'resolution',
            items: [
                {
                    text: '360p',
                    value: '360p'
                },
                {
                    text: '540p',
                    value: '540p'
                },
                {
                    text: '720p',
                    value: '720p'
                },
                {
                    text: '1080p',
                    value: '1080p'
                }
            ]
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#framerate-select'),
            label: 'settings.framerate',
            native: true,
            name: 'framerate',
            items: [
                {
                    text: 'settings.low',
                    value: 'low'
                },
                {
                    text: 'settings.medium',
                    value: 'medium'
                },
                {
                    text: 'settings.high',
                    value: 'high'
                }
            ]
        });
    },

    destroy: function() {
        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    handleChanges: function(change) {
        var changes =  {
            hasChanges: change,
            invalid: false
        };

        if (this.actionView) {
            changes.invalid = $(this.actionView.$submitBtn).prop('disabled');
        }

        this.$el.parent().data('has-changes', changes.hasChanges);
        this.$el.parent().data('has-changes-invalid', changes.invalid);
    }
});
