'use strict';

var app = require('../../../../app');

/**
 * Others frame
 */
app.component('FrameBoxOthers', {
    className: 'framebox-view framebox-others-view',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
    },

    postPlaceAt: function() {
        this.startControls();
    },

    startControls: function() {
        this.createComponent({
            type: 'FrameBoxControls',
            container: this.$el,
            index: this.options.index,
            appId: this.options.appId,
            items: [],
            isFullscreen: this.options.isFullscreen
        });
    }
});
