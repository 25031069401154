// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"additional-message\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"additionalMessageKey") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":36},"end":{"line":3,"column":62}}}))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input id=\"confirm-discare\"\n               type=\"button\"\n               class=\"btn-form form-action-discare focusable\"\n               value=\""
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"discareTextKey") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":22},"end":{"line":10,"column":42}}}))
    + "\"/>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"messageKey") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":20}}}))
    + "</h2>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"additionalMessageKey") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":4,"column":7}}})) != null ? stack1 : "")
    + "<div class=\"form-action-container\" style=\"display: block;\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"discareTextKey") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "\n    <input id=\"confirm-submit\"\n           type=\"button\"\n           class=\"btn-form form-action-submit focusable\"\n           value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"successTextKey") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":18},"end":{"line":16,"column":38}}}))
    + "\"/>\n</div>\n";
},"useData":true});
