module.exports={
    "modifier_mobile":
    {
        "&#92;": 100, //\
        "&#177;": 100, //±
        "&#64;": 100, //@
        "&#163;": 100, //£
        "&#162;": 100, //¢
        "&#164;": 100, //¤
        "&#172;": 100, //¬
        "&#166;": 100, //¦
        "&#178;": 100, //²
        "&#179;": 100, //³
        "&#188;": 100, //¼
        "&#189;": 100, //½
        "&#190;": 100, //¾
        "&#167;": 100, //§
        "&#182;": 100, //¶
        "&#91;": 100, //[
        "&#93;": 100, //]
        "&#126;": 100, //~
        "&#123;": 100, //{
        "&#125;": 100, //}
        "&#176;": 100, //°
        "&#181;": 100, //µ
        "&#175;": 100, //¯

        "&#124;": 42, //|
        "&#33;": 42, //!
        "&#34;": 42, //"
        "&#47;": 42, ///
        "&#36;": 42, //$
        "&#37;": 42, //%
        "&#63;": 42, //?
        "&#38;": 42, //&
        "&#42;": 42, //*
        "&#40;": 42, //(
        "&#41;": 42, //)
        "&#95;": 42, //_
        "&#43;": 42, //+
        "&#58;": 42, //:
        "&#62;": 42, //>
        "&#187;": 42, //»
        "&#39;": 42 //'
    },

    "modifier":
    {
        "uppercase": 42
    },

    "ie_specialkeys_mobile": {},

    "ie_specialkeys": {},

    "&#35;": 41, //#
    "&#124;": 41, //|
    "&#92;": 41, //\
    "&#33;": 2, //!
    "&#177;": 2, //±
    "&#34;": 3, //"
    "&#64;": 3, //@
    "&#47;": 4, ///
    "&#163;": 4, //£
    "&#36;": 5, //$
    "&#162;": 5, //¢
    "&#37;": 6, //%
    "&#164;": 6, //¤
    "&#63;": 7, //?
    "&#172;": 7, //¬
    "&#38;": 8, //&
    "&#166;": 8, //¦
    "&#42;": 9, //*
    "&#178;": 9, //²
    "&#40;": 10, //(
    "&#179;": 10, //³
    "&#41;": 11, //)
    "&#188;": 11, //¼
    "&#45;": 12, //-
    "&#95;": 12, //_
    "&#189;": 12, //½
    "&#61;": 13, //=
    "&#43;": 13, //+
    "&#190;": 13, //¾

    "&#167;": 24, //§
    "&#182;": 25, //¶
    "&#94;": 26, //^
    "&#91;": 26, //[
    "&#184;": 27, //¸
    "&#93;": 27, //]

    "&#59;": 39, //;
    "&#58;": 39, //:
    "&#126;": 39, //~
    "&#96;": 40, //`
    "&#123;": 40, //{
    "&#60;": 43, //<
    "&#62;": 43, //>
    "&#125;": 43, //}

    "&#171;": 86, //«
    "&#187;": 86, //»
    "&#176;": 86, //°
    "&#181;": 50, //µ
    "&#44;": 51, //,
    "&#39;": 51, //'
    "&#175;": 51, //¯
    "&#46;": 52, //.
    "&#233;": 53 //é
}
