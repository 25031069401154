'use strict';

var app = require('../../../app');
var pageCountTpl = require('./framebox-page-count.hbs');
var _ = require('lodash');

app.component('FrameboxPageCount', {
    template: pageCountTpl,

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.currentPage = 0;
        this.totalPages = 0;

        this.keyboard = this.getService('KeyboardService');
        this.formManager = this.getService('FormManager');
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.initForm();
        this.bindEvents();
        this.bindDOMEvents();
    },

    storeSelectors: function() {
        this.$totalPages = this.$el.find('#total-pages');
        this.$input = this.$el.find('#currentPage');
        this.$input.addClass('can-use-touch-keyboard');
    },

    bindEvents: function() {
        this.on('framebox.page.change', this.setCurrentPage.bind(this));
        this.on('framebox.submenu.close', this.closeKeyboard.bind(this));
    },

    bindDOMEvents: function() {
        this.form.on('change.input', _.debounce(this.jumpToPage.bind(this), 400));
        this.form.$el.on('submit', _.debounce(this.jumpToPage.bind(this), 400));
        this.form.$el.on('keyup, change', this.handleFormChange.bind(this));
        this.$input.on('mouseenter touchstart', this.selectInput.bind(this));
        this.$input.on('mouseout', this.deselectInput.bind(this));
        this.$input.on('focusin focusout', this.focusHandler.bind(this));
        this.$input.on('keypress.framebox-' + this.index, this.handleFormChange.bind(this));
        this.$input.on('keydown.framebox-' + this.index, this.handleFormChange.bind(this));
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('.framebox-header-page-count'),
            submit: true
        });
    },

    /**
     * Select input on mouse/touch event.
     */
    selectInput: function(data) {
        // Timeout needed for remote control.
        setTimeout(function() {
            this.$input.select();

            if (data.type === 'touchstart') {
                this.keyboard.open(this.$input);
            }
        }.bind(this), 100);

        data.preventDefault();
    },

    /**
     * Deelect input on mouse/touch event.
     */
    deselectInput: function(data) {
        this.form.get('currentPage').setValue(this.currentPage);

        data.preventDefault();
    },

    /**
     * Handle form change.
     * If entering page number, control bar shouldn't hide.
     */
    handleFormChange: function() {
        this.emit('control-bar-timer.start');
    },

    /**
     * Handle Focus
     * @param data.type focusin/focusout
     */
    focusHandler: function(data) {
        if (data.type === 'focusout') {
            this.emit('framebox-input.unfocused', this.index, false);
        } else {
            this.emit('framebox-input.focused', this.index, true);
            this.emit('control-bar-timer.start');
        }
    },

    /**
     * Jump to page.
     */
    jumpToPage: function() {
        var currentPage = parseInt(this.form.get('currentPage').getValue());

        if (this.form.validate()
            && currentPage !== this.currentPage
            && currentPage > 0 && currentPage <= this.totalPages) {
            this.emit('framebox-' + this.index + '.controls.set-page', {
                index: this.index,
                page: currentPage
            });
        }
    },

    /**
     * Set current page.
     *
     * @param options.currentPage
     * @param options.totalPages
     */
    setCurrentPage: function(options) {
        if (options.index !== this.index) {
            return;
        }

        this.currentPage = parseInt(options.currentPage);
        this.totalPages = parseInt(options.totalPages);

        this.form.get('currentPage').setValue(this.currentPage);
        this.$totalPages.text(this.totalPages);

        this.emit('framebox-input.unfocused', this.index, false);
    },

    /**
     * Handle keyboard.
     * If input field is clicked or control bar is closed or hidden.
     */
    closeKeyboard: function() {
        this.form.get('currentPage').setValue(this.currentPage);

        if (this.$input.hasClass('focused')) {
            this.keyboard.close();
        }
    }
});
