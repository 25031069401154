'use strict';

var Socket = require('./src/socket');
var RestSocket = require('./src/rest-socket2');
var WolfProtHelper = require('./src/wolfprot/wolfprot-helper');

module.exports = {
    Socket: Socket,
    RestSocket: RestSocket,
    WolfProtHelper: WolfProtHelper
};
