// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "station-menu";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <foreignObject id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                               class=\"svg-grid-item"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"isStationMenu") : depths[1]),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":51},"end":{"line":7,"column":100}}})) != null ? stack1 : "")
    + "\n                                   "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"header") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":35},"end":{"line":8,"column":68}}})) != null ? stack1 : "")
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"classed") : depth0), depth0))
    + "\"\n                               x=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"pos") : depth0)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                               y=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"pos") : depth0)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\n                               width=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"dim") : depth0)) != null ? lookupProperty(stack1,"width") : stack1), depth0))
    + "\"\n                               height=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"dim") : depth0)) != null ? lookupProperty(stack1,"height") : stack1), depth0))
    + "\"\n                               data-name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n                               data-group=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"itemGroup") : depth0), depth0))
    + "\">\n                    <div class=\"select-box-item\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"header") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":24},"end":{"line":20,"column":35}}})) != null ? stack1 : "")
    + "                        <div class=\"select-box-item-text\">\n                            <span class=\"text\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n                        </div>\n"
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||container.hooks.helperMissing).call(alias3,(depth0 != null ? lookupProperty(depth0,"itemType") : depth0),"toggle",{"name":"if_equals","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":24},"end":{"line":33,"column":38}}})) != null ? stack1 : "")
    + "                    </div>\n                </foreignObject>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " station-menu-item";
},"6":function(container,depth0,helpers,partials,data) {
    return " header";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"select-box-item-icon\">\n                                <span class=\"icon-selected icon "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"icon") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":18,"column":64},"end":{"line":18,"column":126}}})) != null ? stack1 : "")
    + "\"></span>\n                            </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"icon") : depth0), depth0));
},"11":function(container,depth0,helpers,partials,data) {
    return "icon-v2-checkmark";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"select-box-item-switch btn-mini-switch-container\">\n                                <input id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                                       type=\"checkbox\"\n                                       class=\"btn-mini-switch\"/>\n                                <div class=\"btn-mini-switch-toggle-container\">\n                                    <div class=\"btn-mini-switch-toggle\"></div>\n                                </div>\n                            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"select-box\" class=\"select-box matrix-box-list "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isStationMenu") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":55},"end":{"line":1,"column":95}}})) != null ? stack1 : "")
    + "\">\n    <svg id=\"svg-grid-select\" class=\"svg-grid-select "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isStationMenu") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":53},"end":{"line":2,"column":93}}})) != null ? stack1 : "")
    + "\"\n         viewBox=\"0, 0, "
    + alias4(((helper = (helper = lookupProperty(helpers,"width") || (depth0 != null ? lookupProperty(depth0,"width") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"width","hash":{},"data":data,"loc":{"start":{"line":3,"column":24},"end":{"line":3,"column":33}}}) : helper)))
    + ", "
    + alias4(((helper = (helper = lookupProperty(helpers,"height") || (depth0 != null ? lookupProperty(depth0,"height") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"height","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":45}}}) : helper)))
    + "\">\n        <g class=\"select-layer\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":36,"column":21}}})) != null ? stack1 : "")
    + "        </g>\n    </svg>\n</div>\n";
},"useData":true,"useDepths":true});
