/* eslint-disable no-underscore-dangle */

'use strict';

var $ = require('jquery');
var eventEmitterMixin = require('../event-emitter').eventEmitterMixin;
var Component = require('./component.js');
$.ui = require('./../../vendors/jquery-ui');
require('../../vendors/on-screen-keyboard');

/**
 * @class app
 */
var app = (function() {
    return eventEmitterMixin({
        /**
         * JQuery reference.
         *
         * @type {jQuery}
         */
        $: $,

        /**
         * JQuery window reference.
         *
         * @type {Object}
         */
        $window: $(window),

        /**
         * JQuery document reference.
         *
         * @type {Object}
         */
        $document: $(document),

        /**
         * Defined services.
         *
         * @property services
         */
        services: {},

        /**
         * Registered components.
         *
         * @property components
         * @private
         */
        components: {},

        /**
         * Started components.
         *
         * @property componentInstances
         * @private
         */
        componentInstances: {},

        /**
         * Bootstraps the framework.
         */
        start: function start(options) {
            this.setElement(options.el);

            return this;
        },

        /**
         * @param {Object} el
         */
        setElement: function(el) {
            this.$el = $(el);
            this.el = this.$el.get(0);

            return this;
        },

        /**
         * Defines a new service.
         *
         * @params {String} id
         * @params {Function} service
         */
        service: function service(id, Service) {
            if (!this.services[id]) {
                this.services[id] = Service;
            }

            return this;
        },

        /**
         * Get the service by id.
         *
         * @params {String} id
         */
        getService: function getService(id) {
            var Service = this.services[id] || null;

            if (typeof Service === 'function') {
                Service = this.services[id] = new Service(this);

                if (typeof Service.initialize === 'function') {
                    Service.initialize();
                }
            }

            return Service;
        },

        /**
         * Extend from the base component class
         * and store the reference.
         *
         * @param {String} type - Component blueprint type
         * @param {Object} componentProps - Properties of our defined component
         */
        component: function component(type, componentProps) {
            var parent = Component;
            var abstract = null;
            var child;
            var Surrogate;

            if (componentProps.extend) {
                abstract = componentProps.extend;
                parent = this.components[abstract];
            }

            child = function() {
                parent.apply(this, arguments);
            };

            this.$.extend(child, parent);

            Surrogate = function Surrogate() {
                this.constructor = child;
            };

            Surrogate.prototype = parent.prototype;
            child.prototype = new Surrogate();

            this.$.extend(child.prototype, componentProps);

            child.__super__ = parent.prototype;
            this.components[type] = child;

            return this;
        },

        /**
         * Creates a new component from the component blueprint
         * and returns the componentId.
         *
         * @param {String} id
         * @param {Object} options
         * @param {String} options.insertType
         * @param {String} options.container
         * @param {String} options.type
         *
         * @return {string}
         */
        createComponent: function createComponent(id, options) {
            var Component;
            var instance;

            /**
             * @property {String} insertType
             * @property {String} container
             * @property {String} type
             * @property {String} componentId
             * @type {Object}
             */
            options = options || {};
            options.componentId = id;
            Component = this.components[options.type];

            if (this.componentInstances[id]) {
                this.getService('ExceptionsManager')
                    .throw({
                        isInfo: false,
                        name: 'COMPONENT ERROR',
                        message: 'Component ' + id + ' already exists.'
                    }, true);

                return false;
            }

            if (Component) {
                instance = new Component(options, this);

                if (!instance.checkComponentAccess()) {
                    // Remove component if role has no access
                    this.destroyComponent(id);

                    return false;
                }

                if (instance.isAbstract && !instance.extend) {
                    instance = null;
                    throw new Error('Abstract component(' + options.type + ') can not be initialized.');
                }

                this.componentInstances[id] = instance;
                instance
                    .render()
                    .then(function() {
                        try {
                            instance.placeAt(options.container, options.insertType);
                        } catch (error) {
                            console.error(error);

                            // We want to remove the component if there was an error in the placeAt method.
                            this.destroyComponent(id);

                            return false;
                        }
                    }.bind(this));
            } else {
                throw new Error('Component with the type: ' + options.type + ' is not defined.');
            }

            return id;
        },

        /**
         * Facade for destroying a component.
         *
         * @param {String} id - component id defined with the ’createComponent’ method.
         *
         * @return {Object} component instance
         */
        getComponent: function getComponent(id) {
            return this.componentInstances[id] || null;
        },

        /**
         * Facade for destroying a component.
         *
         * @param {Object|String} component - Could be an instance or the component id.
         * @param {Object} options
         *
         * @return {Object}
         */
        removeComponent: function removeComponent(component, options) {
            if (component) {
                if (typeof component === 'string') {
                    this.destroyComponent(component, options);
                } else {
                    this.destroyComponent(component.id, options);
                }
            }

            return this;
        },

        /**
         * Check if component has changes.
         *
         * @param {Object|String} componentId - component id.
         *
         */
        componentHasChanges: function componentHasChanges(componentId) {
            var instance = this.componentInstances[componentId];

            if (instance) {
                return instance.hasChanges();
            }

            return {
                hasChanges: false,
                invalid: false
            };
        },

        /**
         * Destroys a component and removes its DOM element.
         *
         * @param {String} componentId
         * @param {Object} options
         *
         * @private
         */
        destroyComponent: function destroyComponent(componentId, options) {
            var instance = this.componentInstances[componentId];
            options = options || {};

            if (instance) {
                instance.preDestroy();
                instance.destroy();

                if (options.fadeOut) {
                    instance.$el.fadeOut(300, function() {
                        $(this).remove();
                    });
                } else {
                    instance.$el.remove();
                }

                if (instance.$viewModel)  {
                    instance.$viewModel.$el.remove();
                    instance.$viewModel.$destroy();
                }

                instance.removeAllEvents();
                instance.removeAllChildComponents();

                delete this.componentInstances[componentId];
            }

            return this;
        },

        test: function({ services, run }) {
            const origGetService = this.getService;

            this.getService = function(name) {
                if (!services[name]) {
                    throw new Error(`service "${name}" not available in test environment`);
                }

                return services[name];
            };

            return Promise.resolve().then(run).finally(function() {
                this.getService = origGetService;
            }.bind(this));
        }
    });
})();

module.exports = window.app = app;
