'use strict';

var app = require('../../app');
var lockTpl = require('./lock-indicator.hbs');

/**
 * Lock Indicator.
 */
app.component('LockIndicator', {
    template: lockTpl,

    serialize: function() {
        return {
            name: this.options.name
        };
    }
});
