// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-xs-12 software-packages-title\">\n        <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.software_packages.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":49}}}))
    + "</h2>\n    </div>\n</div>\n\n<div class=\"software-packages-text-container\" data-nav-area=\".focusable\" data-nav-trapped>\n    <div class=\"software-packages-text-scroller focusable\" tabIndex=\"-1\">\n        <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.software_packages.software_packages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":11},"end":{"line":9,"column":60}}}))
    + "</p>\n    </div>\n</div>\n\n<form id=\"software-packages-form\">\n    <div class=\"row software-packages-action-container\" data-nav-area=\".focusable\">\n        <div class=\"col-xs-6\"></div>\n        <div class=\"col-xs-6 software-packages-action-btn-container\">\n            <input id=\"software-packages-close\"\n                   name=\"accept\"\n                   type=\"button\"\n                   class=\"btn-form software-packages-close focusable\"\n                   value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.software_packages.close",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":26},"end":{"line":21,"column":64}}}))
    + "\"\n                   tabIndex=\"-1\" />\n        </div>\n    </div>\n</form>\n";
},"useData":true});
