// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"about\" class=\"about\">\n    <div class=\"about-logo\">\n        <img src=\"/images/cynap-logo.svg\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cynapName") || (depth0 != null ? lookupProperty(depth0,"cynapName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cynapName","hash":{},"data":data,"loc":{"start":{"line":3,"column":47},"end":{"line":3,"column":60}}}) : helper)))
    + "\" />\n    </div>\n\n    <div class=\"about-fakts\" data-nav-area=\".focusable\" data-nav-trapped>\n        <div class=\"about-fakt\">\n            <span class=\"about-fakt-name\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.end_of_support",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":42},"end":{"line":8,"column":70}}}))
    + ":</span>\n            <span class=\"about-fakt-value\" id=\"support-year\"></span>\n        </div>\n        <div class=\"about-fakt\">\n            <span class=\"about-fakt-name\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.build",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":42},"end":{"line":12,"column":61}}}))
    + ":</span>\n            <span class=\"about-fakt-value\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"version") || (depth0 != null ? lookupProperty(depth0,"version") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"version","hash":{},"data":data,"loc":{"start":{"line":13,"column":43},"end":{"line":13,"column":54}}}) : helper)))
    + "</span>\n        </div>\n        <div class=\"about-fakt\">\n            <span class=\"about-fakt-name\">"
    + alias4((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias2).call(alias1,depth0,{"name":"tr","hash":{"key":"about.cynap_build"},"data":data,"loc":{"start":{"line":16,"column":42},"end":{"line":16,"column":77}}}))
    + ":</span>\n            <span class=\"about-fakt-value\" id=\"cbox-build\"></span>\n        </div>\n        <div class=\"about-fakt\">\n            <span class=\"about-fakt-name\">"
    + alias4((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias2).call(alias1,depth0,{"name":"tr","hash":{"key":"about.cynap_version"},"data":data,"loc":{"start":{"line":20,"column":42},"end":{"line":20,"column":79}}}))
    + ":</span>\n            <span class=\"about-fakt-value\" id=\"cbox-version\"></span>\n        </div>\n        <div class=\"about-fakt\">\n            <span class=\"about-fakt-name\">"
    + alias4((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias2).call(alias1,depth0,{"name":"tr","hash":{"key":"about.cynap_serial_number"},"data":data,"loc":{"start":{"line":24,"column":42},"end":{"line":24,"column":85}}}))
    + ":</span>\n            <span class=\"about-fakt-value\" id=\"cbox-serial-number\"></span>\n        </div>\n        <div class=\"about-fakt hidden\" id=\"cbox-license-features-container\">\n            <span class=\"about-fakt-name\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.cynap_license_features",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":42},"end":{"line":28,"column":78}}}))
    + ":</span>\n            <span class=\"about-fakt-value\" id=\"cbox-license-features\"></span>\n        </div>\n        <div class=\"about-fakt about-new-version hidden\">\n            <span class=\"about-fakt-name\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.new_version",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":42},"end":{"line":32,"column":67}}}))
    + ":</span>\n            <span class=\"about-fakt-value\" id=\"cbox-update-version\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.version_checking",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":68},"end":{"line":33,"column":98}}}))
    + "</span><br />\n            <span class=\"about-fakt-name\"></span>\n            <span class=\"about-fakt-value \">\n                <input id=\"do-update\"\n                       name=\"do-update\"\n                       type=\"button\"\n                       class=\"btn-form about-do-update focusable\"\n                       value=\""
    + alias4((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias2).call(alias1,depth0,{"name":"tr","hash":{"key":"about.do_update"},"data":data,"loc":{"start":{"line":40,"column":30},"end":{"line":40,"column":64}}}))
    + "\" />\n            </span>\n        </div>\n        <div class=\"about-fakt about-update-question hidden\" id=\"cbox-update-question\">\n            <span class=\"about-fakt-name\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.new_version",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":42},"end":{"line":44,"column":67}}}))
    + ":</span>\n            <span class=\"about-fakt-value \">\n                "
    + alias4((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias2).call(alias1,depth0,{"name":"tr","hash":{"key":"about.update_question"},"data":data,"loc":{"start":{"line":46,"column":16},"end":{"line":46,"column":55}}}))
    + "<br />\n                <input id=\"do-update-really\"\n                       name=\"do-update\"\n                       type=\"button\"\n                       class=\"btn-form about-do-update-really focusable\"\n                       value=\""
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.yes_do_update",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":30},"end":{"line":51,"column":58}}}))
    + "\" />\n            </span>\n        </div>\n        <div class=\"about-fakt about-update-state hidden\" id=\"cbox-update-container\">\n            <span class=\"about-fakt-name\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.update_state",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":42},"end":{"line":55,"column":68}}}))
    + ":</span>\n            <span class=\"about-fakt-value\">\n                <span id=\"cbox-update-state\"></span>\n                <span class=\"icon-close about-abort-update focusable\"></span>\n            </span><br />\n            <span class=\"about-fakt-name\"></span>\n            <span class=\"about-fakt-value\">\n                <div class=\"download-progress\">\n                    <span class=\"download-progress-percent\">0%</span>\n                    <span class=\"download-progress-container\">\n                        <span class=\"download-progress-current\" style=\"width: 0%\"></span>\n                    </span>\n                </div>\n            </span>\n        </div>\n\n        <div class=\"about-fakt about-new-support hidden\">\n            <span class=\"about-fakt-name\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.new_support",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":42},"end":{"line":72,"column":67}}}))
    + ":</span>\n            <span class=\"about-fakt-value\" id=\"cbox-update-support\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.support_available",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":68},"end":{"line":73,"column":99}}}))
    + "</span><br />\n            <span class=\"about-fakt-name\"></span>\n            <span class=\"about-fakt-value \">\n                <input id=\"activate-support\"\n                       name=\"activate-support\"\n                       type=\"button\"\n                       class=\"btn-form about-activate-support focusable\"\n                       value=\""
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.activate_support",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":80,"column":30},"end":{"line":80,"column":61}}}))
    + "\" />\n            </span>\n        </div>\n        <div class=\"about-fakt about-new-feature hidden\">\n            <span class=\"about-fakt-name\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.new_feature",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":42},"end":{"line":84,"column":67}}}))
    + ":</span>\n            <span class=\"about-fakt-value\" id=\"cbox-update-feature\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.feature_available",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":68},"end":{"line":85,"column":99}}}))
    + "</span><br />\n            <span class=\"about-fakt-name\"></span>\n            <span class=\"about-fakt-value \">\n                <input id=\"activate-feature\"\n                       name=\"activate-feature\"\n                       type=\"button\"\n                       class=\"btn-form about-activate-feature focusable\"\n                       value=\""
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.activate_feature",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":92,"column":30},"end":{"line":92,"column":61}}}))
    + "\" />\n            </span>\n        </div>\n        <div class=\"about-fakt about-copy-logfile hidden\">\n            <span class=\"about-fakt-name\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.logfile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":96,"column":42},"end":{"line":96,"column":63}}}))
    + ":</span>\n            <span class=\"about-fakt-value\" id=\"copy-log\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.copy_logfile_to_usb",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":97,"column":57},"end":{"line":97,"column":90}}}))
    + "</span><br />\n            <span class=\"about-fakt-name\"></span>\n            <span class=\"about-fakt-value \">\n                <input id=\"copy-logfile\"\n                       name=\"copy-log\"\n                       type=\"button\"\n                       class=\"btn-form about-copy-log focusable\"\n                       value=\""
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.copy_to_usb",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":104,"column":30},"end":{"line":104,"column":56}}}))
    + "\" />\n                <a class=\"hidden about-fakt-name download-link\" id=\"download-link\" target=\"_blank\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.download_link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":105,"column":99},"end":{"line":105,"column":126}}}))
    + "</a>\n            </span>\n            <div class=\"about-copy-log-loader loader small\"></div>\n        </div>\n    </div>\n</div>\n\n<div class=\"about-info-buttons-container\"\n     data-nav-area=\".focusable\"\n     data-nav-trapped\n     data-nav-area-default>\n    <input id=\"about-show-eula\"\n           name=\"show-eula\"\n           type=\"button\"\n           class=\"btn-form about-show-eula focusable\"\n           value=\""
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.show_eula",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":120,"column":18},"end":{"line":120,"column":42}}}))
    + "\" />\n    <input id=\"about-show-software-packages\"\n           name=\"show-software-packages\"\n           type=\"button\"\n           class=\"btn-form about-show-software-packages focusable\"\n           value=\""
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.show_sw_pkgs",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":125,"column":18},"end":{"line":125,"column":45}}}))
    + "\" />\n    <input id=\"about-show-software-compliance\"\n           name=\"show-software-compliance\"\n           type=\"button\"\n           class=\"btn-form about-show-software-compliance focusable\"\n           value=\""
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.show_sw_compliance",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":130,"column":18},"end":{"line":130,"column":51}}}))
    + "\" />\n    <input id=\"about-show-privacy-policy\"\n           name=\"show-privacy-policy\"\n           type=\"button\"\n           class=\"btn-form about-show-privacy-policy focusable\"\n           value=\""
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"about.show_privacy_policy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":135,"column":18},"end":{"line":135,"column":52}}}))
    + "\" />\n</div>\n";
},"useData":true});
