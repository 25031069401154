// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"col-xs-6 reset-image-btn-container\">\n                        <button type=\"button\" class=\"btn-form reset-background is-focusable\">\n                            "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.reset_image",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":81,"column":28},"end":{"line":81,"column":56}}}))
    + "\n                        </button>\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"matrix-configuration-item\"\n      name=\"matrix-configuration-item\"\n      data-index=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n      data-nav-area=\".is-focusable:not(:disabled), .btn-form\"\n      data-nav-trapped>\n    <div class=\"item-container settings-list-secundary\" tabIndex=\"-1\">\n        <div class=\"configuration-template\">\n            <div class=\"row template-top\">\n                <div class=\"col-xs-6\">\n                    <div class=\"input-group\">\n                        <label class=\"input-label\" for=\"name\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.template_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":62},"end":{"line":11,"column":92}}}))
    + "</label>\n                        <input type=\"text\"\n                               class=\"input is-focusable\"\n                               name=\"templateName\"\n                               data-validation=\"checkMinMaxLength\"\n                               data-min-length=\"1\"\n                               data-max-length=\"254\"/>\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\">\n                                "
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":32},"end":{"line":20,"column":63}}}))
    + "\n                            </span>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"command-button-container col-xs-4\">\n                    <h2 class=\"input-label\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.share_to_all_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":44},"end":{"line":26,"column":80}}}))
    + "</h2>\n                    <div class=\"btn-switch-container pull-left\">\n                        <input name=\"shareToAllEnable\"\n                            type=\"checkbox\"\n                            class=\"btn-switch command-button-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"command-button-maximum col-xs-2\">\n                    <div>"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.maximum_share_to_buttons",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":25},"end":{"line":37,"column":67}}}))
    + "</div>\n                </div>\n            </div>\n\n            <div class=\"matrix-groups-container\">\n                <div class=\"matrix-group-list-items\"></div>\n\n                <div id=\"matrix-new-group\" class=\"matrix-new-group is-focusable\">\n                    <div class=\"matrix-add-new-group\"\n                         tabIndex=\"-1\">\n                        <h2 class=\"input-label matrix-new-group-item\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.add_new_group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":70},"end":{"line":47,"column":100}}}))
    + "</h2>\n                        <span class=\"icon-add-content-button matrix-new-group-item\"></span>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row\">\n                <div class=\"col-xs-6\">\n                    <div class=\"input-group\" id=\"input-group-background\">\n                        <label class=\"input-label\" for=\"background-file-select\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.background",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":80},"end":{"line":56,"column":107}}}))
    + "</label>\n                        <input type=\"text\"\n                               class=\"input is-focusable background-file-name\"\n                               name=\"backgroundFileName\"\n                               placeholder=\""
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.none",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":44},"end":{"line":60,"column":65}}}))
    + "\"\n                               disabled/>\n                        <input type=\"file\"\n                               class=\"input is-focusable background-file-select\"\n                               name=\"background-file-select\"\n                               id=\"background-file-select-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                               accept=\".jpg, .jpeg\"\n                               data-validation=\"checkImage\"/>\n                        <div class=\"input-info background-file-select\">\n                            <span class=\"input-info-message success-message\">\n                                "
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.image_error_size",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":32},"end":{"line":70,"column":66}}}))
    + "\n                            </span>\n                            <span class=\"input-info-message error-message\">\n                                "
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.image_error_size",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":32},"end":{"line":73,"column":65}}}))
    + "\n                            </span>\n                        </div>\n                    </div>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":16},"end":{"line":84,"column":27}}})) != null ? stack1 : "")
    + "            </div>\n\n            <div class=\"row text-centered p-bottom-20 p-top-20\" data-nav-area=\".is-focusable\">\n                <button type=\"button\" class=\"btn-form delete-template is-focusable\">\n                    "
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":89,"column":20},"end":{"line":89,"column":43}}}))
    + "\n                </button>\n            </div>\n        </div>\n        <div class=\"row delete-info text-centered p-top-20 hidden\">\n            <span class=\"delete-info-message\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.delete_template_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":94,"column":46},"end":{"line":94,"column":83}}}))
    + "</span>\n        </div>\n\n        <div id=\"form-action-container\" class=\"form-primary-background\"></div>\n    </div>\n</form>\n";
},"useData":true});
