'use strict';

const app = require('../../../app');
const template = require('./room-controller.html');
const vueUtils = require('../../../components-vue/util');

app.component('RoomController', {
    engine: 'vue',
    template: template,
    className: 'room-controller-v3-modal-container',

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    data: function() {
        return {
            roomControllerUrl: ''
        };
    },

    created: function() {
        this.roomControllerUrl = this.component.options.configs.url;
    },

    methods: {
        close: function() {
            this.component.emit('modal.close', { id: 'room-controller' });
        },

        i18n: vueUtils.i18n
    }
});
