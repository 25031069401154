'use strict';

var layoutManager = require('./../layout');
var app = require('./../../app/app');

/**
 * Only used on hdmi2 screen
 *
 * @param {jQuery.Element} $el
 *
 * @constructor
 */
var CboxAuxView = function($el) {
    this.$el = $el;

    this.liveStream = app.getService('LiveStreamService');

    this.initialize();
};

/**
 * @method intialize
 */
CboxAuxView.prototype.initialize = function() {
    this.bindEvents();
    this.updateLayoutSize();
};

/**
 * Bind all events
 */
CboxAuxView.prototype.bindEvents = function() {
    app.on('app.resize', this.updateLayoutSize.bind(this));
    layoutManager.on('window.resize', this.updateLayoutSize.bind(this));
};

/**
 * Set screen size to 100% of browser size
 *
 * @method updateLayoutSize
 */
CboxAuxView.prototype.updateLayoutSize = function() {
    var size = {
        width: app.$window.width(),
        height: app.$window.height()
    };

    this.$el.find('#aspect-ratio-4-3').css(size);
    this.$el.find('.framebox-fullscreen-ui').css(size);
    app.emit('aspect-ratio.changed', size);
    app.emit('ui-scaling.update', {
        scaling: this.liveStream.scaling
    });
};

module.exports = CboxAuxView;
