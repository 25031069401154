// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "data-nav-area-default";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"col-xs-6\">\n                    <div id=\"hdmi-out-control-screen-resolution-container\"></div>\n                </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"col-xs-6\">\n                    <div id=\"hdmi-out-2-mode-container\"></div>\n                </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div id=\"output-priorities\" class=\"output-priorities\">\n                <div class=\"row settings-list-secundary\" data-nav-area=\".input, select, .is-focusable:not(:disabled)\"\n                     data-nav-trapped=\"\">\n                    <div class=\"col-xs-6 pull-right p-left-30\">\n                        <div id=\"hdmi-out-2-priority-1-container\"></div>\n                    </div>\n                </div>\n                <div class=\"row settings-list-secundary\" data-nav-area=\".input, select, .is-focusable:not(:disabled)\"\n                     data-nav-trapped=\"\">\n                    <div class=\"col-xs-6 pull-right p-left-30\">\n                        <div id=\"hdmi-out-2-priority-2-container\"></div>\n                    </div>\n                </div>\n                <div class=\"row settings-list-secundary\" data-nav-area=\".input, select, .is-focusable:not(:disabled)\"\n                     data-nav-trapped=\"\">\n                    <div class=\"col-xs-6 pull-right p-left-30\">\n                        <div id=\"hdmi-out-2-priority-3-container\"></div>\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-secundary\" data-nav-area=\".input, select, .is-focusable:not(:disabled)\"\n                     data-nav-trapped=\"\">\n                    <div class=\"col-xs-6\"></div>\n                    <div class=\"col-xs-4\">\n                        <label class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.override_moderator",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":51},"end":{"line":52,"column":86}}}))
    + "</label>\n                    </div>\n                    <div class=\"col-xs-2\">\n                        <div class=\"btn-switch-container pull-right halfsize-switch\">\n                            <input name=\"allow-override\" id=\"allow-override\" type=\"checkbox\"\n                                   class=\"btn-switch is-focusable\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-secundary notifications\"\n                     data-nav-area=\".input, select, .is-focusable:not(:disabled)\"\n                     data-nav-trapped=\"\">\n                    <div class=\"col-xs-6\"></div>\n                    <div class=\"col-xs-4\">\n                        <label class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.show_notification_hdmi2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":51},"end":{"line":70,"column":91}}}))
    + "</label>\n                    </div>\n                    <div class=\"col-xs-2\">\n                        <div class=\"btn-switch-container pull-right halfsize-switch\">\n                            <input name=\"hdmi-out-2-notification\" id=\"hdmi-out-2-notification\" type=\"checkbox\"\n                                   class=\"btn-switch is-focusable\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-secundary hdmi2-recording\"\n                     data-nav-area=\".input, select, .is-focusable:not(:disabled)\"\n                     data-nav-trapped=\"\">\n                    <div class=\"col-xs-6\"></div>\n                    <div class=\"col-xs-4\">\n                        <label class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.hdmi2_content_streaming_recording",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":88,"column":51},"end":{"line":88,"column":101}}}))
    + "</label>\n                    </div>\n                    <div class=\"col-xs-2\">\n                        <div class=\"btn-switch-container pull-right halfsize-switch\">\n                            <input name=\"hdmi-out-2-content-recording\" id=\"hdmi-out-2-content-recording\"\n                                   type=\"checkbox\" class=\"btn-switch is-focusable\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\"\n                    data-nav-area=\".btn-switch, .is-focusable:not(:disabled)\"\n                    data-nav-trapped\n                    data-nav-area-default>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.webconference_hdmi2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":107,"column":44},"end":{"line":107,"column":80}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-2 pull-right\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"webconferenceHdmi2\" id=\"webconferenceHdmi2\" type=\"checkbox\" class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\"\n                    data-nav-area=\".btn-switch, .is-focusable:not(:disabled)\"\n                    data-nav-trapped\n                    data-nav-area-default>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.swap_hdmi",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":124,"column":44},"end":{"line":124,"column":70}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-2 pull-right\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"swapHdmi\" id=\"swapHdmi\" type=\"checkbox\" class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".btn-switch, .is-focusable:not(:disabled)\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.screen_off_no_content",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":143,"column":44},"end":{"line":143,"column":82}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-2 pull-right\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"screen-off-no-content\" id=\"screen-off-no-content\" type=\"checkbox\" class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div id=\"screensaver-settings\" class=\"screensaver-settings\">\n                <div class=\"row settings-list-row\">\n                    <div class=\"col-xs-8\">\n                        <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.screensaver",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":160,"column":28},"end":{"line":160,"column":56}}}))
    + "</h2>\n                    </div>\n                </div>\n                <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                    <div class=\"col-xs-4\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.screensaver",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":165,"column":48},"end":{"line":165,"column":76}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-2\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input name=\"screensaver-enable\"\n                                   id=\"screensaver-enable\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch is-focusable\"\n                                   tabIndex=\"1\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-6\"></div>\n                </div>\n                <div id=\"screensaver-sub-settings\" class=\"screensaver-sub-settings\">\n                    <div class=\"row settings-list-secundary\"\n                         data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\"\n                         data-nav-trapped>\n                        <div class=\"col-xs-6\">\n                            <div class=\"input-group\">\n                                <label class=\"input-label\" for=\"screensaver-url\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.screensaver_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":187,"column":81},"end":{"line":187,"column":113}}}))
    + "</label>\n                                <input type=\"text\" class=\"input is-focusable\" name=\"screensaver-url\" id=\"screensaver-url\"\n                                       data-validation=\"checkUrlOrEmpty\"/>\n                                <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\" id=\"url-error\">\n                                    "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":192,"column":36},"end":{"line":192,"column":62}}}))
    + "\n                                </span>\n                                </div>\n                            </div>\n                        </div>\n                        <div class=\"col-xs-6\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLocal") : depth0),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":198,"column":28},"end":{"line":219,"column":39}}})) != null ? stack1 : "")
    + "                            <div class=\"local-screensaver-delete-container\">\n                                <div class=\"input-group\">\n                                    <label class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.screensaver_local",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":222,"column":63},"end":{"line":222,"column":97}}}))
    + "</label>\n                                    <div class=\"local-screensaver-delete\">\n                                        <label id=\"local-screensaver-name\" class=\"input name\" disabled></label>\n                                        <input type=\"button\" id=\"local-screensaver-delete\" name=\"local-screensaver-delete\"\n                                               class=\"btn-form is-focusable\" value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":226,"column":84},"end":{"line":226,"column":107}}}))
    + "\">\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDualProjection") : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":232,"column":20},"end":{"line":252,"column":31}}})) != null ? stack1 : "")
    + "                    <div class=\"row settings-list-secundary\"\n                         data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\"\n                         data-nav-trapped>\n                        <div class=\"col-xs-4\">\n                            <h2 class=\"input-label p-top-15\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.auto_screensaver",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":257,"column":61},"end":{"line":257,"column":94}}}))
    + "</h2>\n                        </div>\n                        <div class=\"col-xs-2\">\n                            <div class=\"btn-switch-container pull-right halfsize-switch p-top-15\">\n                                <input name=\"auto-screensaver-enable\"\n                                       id=\"auto-screensaver-enable\"\n                                       type=\"checkbox\"\n                                       class=\"btn-switch is-focusable\"/>\n                                <div class=\"btn-switch-toggle-container\">\n                                    <div class=\"btn-switch-toggle\"></div>\n                                </div>\n                            </div>\n                        </div>\n                        <div class=\"col-xs-3\">\n                            <div name=\"delay-select\" id=\"delay-select-container\"></div>\n                        </div>\n                        <div class=\"col-xs-2\">\n                            <h2 class=\"input-label p-top-15 text-right\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.keep_files",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":274,"column":72},"end":{"line":274,"column":99}}}))
    + "</h2>\n                        </div>\n                        <div class=\"col-xs-1\">\n                            <div class=\"btn-switch-container pull-right p-top-15\">\n                                <input name=\"keep-files-enable\"\n                                       id=\"keep-files-enable\"\n                                       type=\"checkbox\"\n                                       class=\"btn-switch is-focusable\"/>\n                                <div class=\"btn-switch-toggle-container\">\n                                    <div class=\"btn-switch-toggle\"></div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\" data-nav-trapped>\n                    <div class=\"col-xs-4\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.screen_off",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":293,"column":48},"end":{"line":293,"column":75}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-2\">\n                        <div class=\"screen-off-enable-container btn-switch-container pull-right halfsize-switch p-top-15\">\n                            <input name=\"screen-off-enable\" id=\"screen-off-enable\" type=\"checkbox\"\n                                   class=\"btn-switch is-focusable\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div name=\"screen-off-delay-select\" id=\"screen-off-delay-select-container\"></div>\n                    </div>\n                </div>\n                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\" data-nav-trapped>\n                    <div class=\"col-xs-4\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.power_down",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":311,"column":48},"end":{"line":311,"column":75}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-2\">\n                        <div class=\"standby-enable-container btn-switch-container pull-right halfsize-switch p-top-15\">\n                            <input name=\"standby-enable\" id=\"standby-enable\" type=\"checkbox\"\n                                   class=\"btn-switch is-focusable\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div name=\"standby-delay-select\" id=\"standby-delay-select-container\"></div>\n                    </div>\n                </div>\n            </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"local-screensaver-select-container\">\n                                    <div class=\"input-group\">\n                                        <label class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.screensaver_local",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":201,"column":67},"end":{"line":201,"column":101}}}))
    + "</label>\n                                        <div class=\"custom-file-chooser-container\">\n                                            <label class=\"custom-file-chooser multiple\" for=\"local-screensaver-files\">\n                                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.no_folder_chosen",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":204,"column":48},"end":{"line":204,"column":81}}}))
    + "\n                                            </label>\n                                        </div>\n                                        <input type=\"file\" webkitdirectory multiple\n                                               class=\"input is-focusable is-custom\"\n                                               name=\"local-screensaver-files\"\n                                               id=\"local-screensaver-files\"\n                                               tabIndex=\"-1\"/>\n                                        <div class=\"input-info\">\n                                        <span id=\"local-screensaver-error\" class=\"input-info-message error-message\">\n                                            "
    + alias3((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias2).call(alias1,depth0,{"name":"tr","hash":{"key":"settings.screensaver_invalid_index"},"data":data,"loc":{"start":{"line":214,"column":44},"end":{"line":214,"column":96}}}))
    + "\n                                        </span>\n                                        </div>\n                                    </div>\n                                </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"row settings-list-secundary\"\n                            data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\"\n                            data-nav-trapped>\n                            <div class=\"col-xs-4\">\n                                <h2 class=\"input-label p-top-15\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.screensaver_interactive_mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":237,"column":65},"end":{"line":237,"column":110}}}))
    + "</h2>\n                            </div>\n                            <div class=\"col-xs-2\">\n                                <div class=\"btn-switch-container pull-right halfsize-switch p-top-15\">\n                                    <input name=\"interactive-mode-enable\"\n                                        id=\"interactive-mode-enable\"\n                                        type=\"checkbox\"\n                                        class=\"btn-switch is-focusable\"/>\n                                    <div class=\"btn-switch-toggle-container\">\n                                        <div class=\"btn-switch-toggle\"></div>\n                                    </div>\n                                </div>\n                            </div>\n                            <div class=\"col-xs-6\"></div>\n                        </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-row\">\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.window_mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":333,"column":24},"end":{"line":333,"column":52}}}))
    + "</h2>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary\" data-nav-area=\".input, select, .is-focusable:not(:disabled)\"\n                 data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <div id=\"window-mode-container\"></div>\n                </div>\n                <div class=\"col-xs-6\"></div>\n            </div>\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.byod_endless_mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":345,"column":44},"end":{"line":345,"column":78}}}))
    + "</h2>\n                </div>\n\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"byod-endless-mode\"\n                               name=\"byod-endless-mode\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"output-settings\" name=\"output-settings\">\n        <div class=\"row settings-list-row\">\n            <div class=\"col-xs-8\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.output_hdmi",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":48}}}))
    + "</h2>\n            </div>\n        </div>\n        <div class=\"row settings-list-secundary\"\n             data-nav-area=\".input, select, .is-focusable:not(:disabled)\"\n             data-nav-trapped\n             "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":13},"end":{"line":11,"column":63}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDualProjection") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":16,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"col-xs-6\">\n                <div id=\"hdmi-out-1-resolution-container\"></div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxNonDualProjection") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxNonDualProjection") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":135,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasScreenOffNoContent") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":137,"column":8},"end":{"line":154,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":156,"column":8},"end":{"line":327,"column":19}}})) != null ? stack1 : "")
    + "\n        <!-- Windows -->\n"
    + ((stack1 = (lookupProperty(helpers,"unless_or")||(depth0 && lookupProperty(depth0,"unless_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPureReceiver") : depth0),(depth0 != null ? lookupProperty(depth0,"isDualProjection") : depth0),{"name":"unless_or","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":330,"column":8},"end":{"line":360,"column":22}}})) != null ? stack1 : "")
    + "\n        <!-- UI Scaling -->\n        <div class=\"row settings-list-row\">\n            <div class=\"col-xs-12\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ui_scaling",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":365,"column":20},"end":{"line":365,"column":47}}}))
    + "</h2>\n            </div>\n        </div>\n        <div class=\"row settings-list-secundary\" data-nav-area=\".input, select, .is-focusable:not(:disabled)\"\n             data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div id=\"ui-scaling-container\"></div>\n            </div>\n            <div class=\"col-xs-6\"></div>\n        </div>\n\n        <div id=\"form-action-container\" class=\"form-primary-background\"></div>\n\n    </form>\n</div>\n";
},"useData":true});
