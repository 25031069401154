// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"validation") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":54},"end":{"line":11,"column":70}}}));
},"3":function(container,depth0,helpers,partials,data) {
    return "checkMinMaxLength";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"validationErrorMsg") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":24},"end":{"line":20,"column":48}}}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"error_messages.invalid_length_0_63",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":24},"end":{"line":22,"column":66}}}))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input id=\"confirm-discard\"\n                   type=\"button\"\n                   class=\"btn-form form-action-discare focusable\"\n                   value=\""
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"discareTextKey") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":26},"end":{"line":34,"column":46}}}))
    + "\"/>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"text-input-container\">\n    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"titleKey") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":22}}}))
    + "</h2>\n\n    <form id=\"text-input-form\" name=\"text-input-form\">\n        <div class=\"input-group\">\n            <label class=\"input-label\" for=\"field\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"labelKey") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":51},"end":{"line":6,"column":65}}}))
    + "</label>\n            <input type=\"text\"\n                   class=\"input focusable\"\n                   name=\"field\"\n                   placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"placeholderKey") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":32},"end":{"line":10,"column":52}}}))
    + "\"\n                   data-validation=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"validation") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":11,"column":36},"end":{"line":11,"column":102}}})) != null ? stack1 : "")
    + "\"\n                   data-min-length=\"1\"\n                   data-max-length=\"64\"\n                   data-allow-empty=\"true\"\n                   tabIndex=\"-1\" />\n\n            <div class=\"input-info\">\n                <span class=\"input-info-message error-message\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"validation") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":23,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n            </div>\n        </div>\n    </form>\n\n    <div class=\"form-action-container\" style=\"display: block;\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"discareTextKey") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":35,"column":15}}})) != null ? stack1 : "")
    + "\n        <input id=\"confirm-submit\"\n               type=\"button\"\n               class=\"btn-form form-action-submit focusable\"\n               value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"successTextKey") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":22},"end":{"line":40,"column":42}}}))
    + "\">\n    </div>\n</div>\n";
},"useData":true});
