'use strict';

var _ = require('lodash');
var $ = require('jquery');
var i18n = require('i18next');
var app = require('../../../app');
var QRCode = require('./../../../../../vendors/qrcode.js');
var qrCodeTpl = require('./qr-code.hbs');
var platform = require('../../../../platform/platform');

/**
 * Link sources
 * @type {{0: string, 1: string, 2: string, 3: string, 4: string, 5: string, 6: string, stream: string}}
 */
var sources = {
    0: 'vcast_win',
    1: 'vcast_android',
    2: 'vcast_ios',
    3: 'vcapture_win32',
    4: 'vcapture_win64',
    5: 'vcapture_android',
    6: 'vcapture_ios',
    'stream': 'link_to_stream'
};

/**
 * Carousel direction
 * back: left arrow
 * forward: right arrow
 * @type {{back: number, forward: number}}
 */
var directions = {
    back: 0,
    forward: 1
};

/**
 * Download link types.
 * Local: file available on Cynap
 * Remote: URL
 * @type {{0: string, 1: string}}
 */
var types = {
    0: 'local',
    1: 'remote'
};

app.component('QrCode', {
    template: qrCodeTpl,
    ip: null,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    /**
     * Initialize.
     */
    initialize: function() {
        this.streaming = this.getService('Streaming');
        this.remote = this.getService('RemoteService');
        this.frontendSettings = this.getService('FrontendSettings');

        this.currentSource = null;
    },

    serialize: function() {
        return {
            isCboxAux: platform.checks.isCboxAux
        };
    },

    /**
     * @method postPlaceAt
     * @return void
     */
    postPlaceAt: function() {
        this.storeSelectors();
        this.bindDOMEvents();

        this.deviceConnection
            .send([
                {
                    command: 'getQrCode',
                    data: {
                        streamIndex: this.options.configs.streamIndex
                    }
                },
                'getStreamingMode',
                'getStreamingFunction'
            ])
            .then(function(qrCode, streaming, streamingFunction) {
                this.qrCodeList = [];
                this.index = 0;
                this.urlStream = qrCode.link;

                if (streamingFunction.function && streaming.mode === 1 && this.urlStream !== '') {
                    this.qrCodeList.push({
                        type: types[0],
                        source: sources.stream,
                        url: this.urlStream,
                        error: false
                    });
                } else if (streamingFunction.function) {
                    this.qrCodeList.push({
                        type: types[0],
                        source: sources.stream,
                        url: this.urlStream,
                        error: true
                    });
                }

                this.showQRCode();

                if (platform.checks.isCboxAux) {
                    this.$arrowBack.addClass('hidden');
                    this.$arrowForward.addClass('hidden');
                } else if (this.qrCodeList.length <= 1) {
                    this.$arrowBack.addClass('hidden');
                    this.$arrowForward.addClass('hidden');
                }
            }.bind(this));
    },

    loadFrontendSettings: function() {
        var dfd = $.Deferred();

        this.frontendSettings
            .getSettings([
                'qrCodeSource'
            ])
            .then(function(source) {
                if (this.currentSource !== source) {
                    this.showSpecificQrCode(source);
                }
                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Helper function. Check if IP is valid from url.
     * @param url From download link list.
     * @returns {boolean} true: OK, false: 0.0.0.0 (no WLAN/LAN IP) || null || ''
     */
    validateIp: function(url) {
        var splitUrl = url.split('//')[1]; // Remove protocol
        var ip = splitUrl.split('/')[0]; // Get basic url/ip

        return ip !== null && ip !== '' && ip !== '0.0.0.0';
    },

    storeSelectors: function() {
        this.$qrCodeContent = this.$el.find('.qr-code-content');
        this.$arrowBack = this.$el.find('.qr-code-back');
        this.$arrowForward = this.$el.find('.qr-code-forward');
        this.$title = this.$('.qr-code-title');
        this.$subtitle = this.$('.qr-code-subtitle');
        this.$qrCodeBig = this.$el.find('#qr-code-big');
        this.$qrCodeSmall = this.$el.find('#qr-code-small');
        this.$content = this.$('#modal-full-view');
        this.$url = this.$('#qr-code-url');
        this.$container = this.$el.find('#qr-code-container');
        this.$networkInfo = this.$el.find('#qr-code-network-info');
        this.$msgError = this.$el.find('#qr-code-msg-error');
        this.$msgErrorInterface = this.$el.find('#qr-code-msg-error-interface');
        this.$msg = this.$el.find('#qr-code-msg');
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        this.resizeHandler = _.debounce(this.resizeQrCode.bind(this), 300);
        this.$(window).on('resize', this.resizeHandler);

        if (platform.checks.isCboxAux) {
            this.on('main-loop.update', this.loadFrontendSettings.bind(this));
            this.on('main-loop.update.qrcode', this.loadFrontendSettings.bind(this));
        }
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.$el.on('click', '.start-streaming', this.startStreamingHandler.bind(this));
        this.$el.on('click', '.open-streaming', this.openStreamingSettings.bind(this));
        this.$el.on('click', '.open-settings', this.openSettings.bind(this));
        this.$el.on('click', '.request-close', this.requestClose.bind(this));
    },

    /**
     * Show QR-Code from current index
     */
    showQRCode: function(item) {
        var link = typeof item === 'undefined' ? this.qrCodeList[this.index] : item;

        this.currentSource = link.source;
        this.$content.children().css('height', '100%');

        if (link.error) {
            this.showMsgError();

            if (platform.checks.isCboxAux) {
                this.bindEvents();
            }
        } else {
            this.$el.find('.qr-code-error-msg').hide();
            this.$el.find('.qr-code-info').show();
            this.bindEvents();
            this.resizeQrCode(this.index);
        }

        this.$title.text(i18n.t('modal.' + link.source));
        this.$subtitle.text(link.source === sources['stream'] ? i18n.t('modal.scan_to_connect') : i18n.t('modal.scan_to_load'));

        if (platform.checks.isCbox) {
            this.frontendSettings.updateSetting({
                tag: 'qrCodeSource',
                value: this.currentSource
            });
            this.frontendSettings.saveSettings();

            this.emit('main-loop.update.qrcode');
        }
    },

    /**
     * Show QR-Code from assigned source
     *
     * @param {string} source
     */
    showSpecificQrCode: function(source) {
        _.forEach(this.qrCodeList, function(item) {
            if (item.source === source) {
                this.showQRCode(item);
            }
        }.bind(this));
    },

    /**
     * Show error message.
     */
    showMsgError: function() {
        this.$el.find('.qr-code-info').hide();
        this.$el.find('.qr-code-error-msg').hide();

        if (this.qrCodeList[this.index].source === sources['stream']) {
            if (this.streaming.isEnabled() && this.streaming.isStreamEnabled() && this.streaming.getState() === 'off') {
                this.$msg.show();
            } else {
                this.$msgError.show();
            }
        } else {
            this.$msgErrorInterface.show();
        }
    },

    /**
     * Start Streaming handler
     */
    startStreamingHandler: function() {
        this.streaming.toggleState();
        this.emit('modal.close');
    },

    /**
     * Open streaming settings
     */
    openStreamingSettings: function() {
        this.emit('modal.close');
        this.emit('overlay.open', {
            id: 'streaming-settings',
            extendedConfigs: 'settings-list',
            animation: 'fade'
        });
    },

    /**
     * Open settings
     */
    openSettings: function() {
        this.emit('modal.close');
        this.emit('overlay.open', {
            id: 'general-settings',
            extendedConfigs: 'settings-list',
            animation: 'fade'
        });
    },

    /**
     * Request backend to hide QR code
     */
    requestClose: function() {
        this.deviceConnection
            .send('setQrCodeVisible', {
                visible: false
            }).then(function() {
                this.emit('main-loop.fast.start', {
                    id: 'stream-qrcode'
                });
            }.bind(this));
    },

    /**
     * Handle touch start event
     * @param event
     */
    onTouchStart: function(event) {
        if (event.originalEvent) {
            this.touch = event.originalEvent.touches[0].pageX;
        }
    },

    /**
     * Handle touch end event
     * @param event
     */
    onTouchEnd: function() {
        this.touch = undefined;
    },

    /** >
     * Handle touch move event
     * @param event
     */
    onTouchMove: function(event) {
        if (this.qrCodeList.length <= 1) {
            return;
        }

        if (this.touch !== undefined && this.touch > event.originalEvent.touches[0].pageX) {
            this.chooseQrCodeForward();
        } else {
            this.chooseQrCodeBack();
        }
    },

    /**
     * Handle switching between QR-Codes using the back/left arrow
     */
    chooseQrCodeBack: function() {
        this.index = ((this.index === 0) ? this.qrCodeList.length - 1 : this.index - 1);

        this.showQRCode();
        this.playAnimation(directions.back);
    },

    /**
     * Handle switching between QR-Codes using the forward/right arrow
     */
    chooseQrCodeForward: function() {
        this.index = ((this.index === this.qrCodeList.length - 1) ? 0 : this.index + 1);

        this.showQRCode();
        this.playAnimation(directions.forward);
    },

    /**
     * Play carousel animation
     * @param direction back: left arrow, forward: right arrow
     */
    playAnimation: function(direction) {
        this.$qrCodeContent
            .velocity('stop')
            .velocity({
                translateX: (direction === directions.back ? '-100%' : '100%')
            }, {
                duration: 0,
                display: 'block'
            })
            .velocity({
                translateX: 0
            }, {
                easing: [200, 25],
                duration: 500
            });
    },

    /**
     * Resize QR-Code
     */
    resizeQrCode: function() {
        var size = this.$content.height() - 240;

        this.$qrCodeBig.html('');
        this.$qrCodeSmall.html('');

        this.renderQrCode(size);
    },

    /**
     * Render QR-Code
     *
     * @param size QR-Code size
     */
    renderQrCode: function(size) {
        var url = '';
        var type = '';

        if (platform.checks.isCboxAux) {
            _.forEach(this.qrCodeList, function(item) {
                if (item.source === this.currentSource) {
                    url = item.url;
                    type = item.type;
                }
            }.bind(this));
        } else {
            url = this.qrCodeList[this.index].url;
            type = this.qrCodeList[this.index].type;
        }

        new QRCode('qr-code-big', {
            text: url,
            width: size,
            height: size,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.L
        });

        new QRCode('qr-code-small', {
            text: url,
            width: size * 0.25,
            height: size * 0.25,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.L
        });

        var text = i18n.t('modal.network_access_internet');

        if (type === types[0]) {
            text = i18n.t('modal.network_access_cynap', {
                'cynapName': app.getService('Model-View').name
            });
        }

        this.$url.text(url);
        this.$networkInfo.text(text);
    },

    destroy: function() {
        this.$(window).off('resize', this.resizeHandler);
    }
});
