module.exports={

    // NOTE:
    // 100 = AltGr
    "modifier":
    {
        "&#64;": 100, //@
        "atmark": 100, //@
        "&#123;": 100, //{
        "&#125;": 100, //}
        "&#91;": 100, //[
        "&#93;": 100, //]
        "&#126;": 100, //~
        "&#124;": 100, //|
        "&#92;": 100, //\
        "&#8364;": 100 //€
    },

    "modifier_mobile":
    {
        "&#33;": 42, //!
        "&#176;": 42, //°
        "&#167;": 42, //§
        "&#36;": 42, //$
        "&#37;": 42, //%
        "&#38;": 42, //&
        "&#47;": 42, ///
        "&#40;": 42, //(
        "&#41;": 42, //)
        "&#61;": 42, //=
        "&#63;": 42, //?
        "&#96;": 42, //`
        "&#34;": 42, //"

        "&#42;": 42, //*
        "&#39;": 42, //'

        "&#62;": 42, //>
        "&#59;": 42, //;
        "&#58;": 42, //:
        "&#95;": 42 //_
    },


    "ie_specialkeys_mobile": {},

    "ie_specialkeys":
    {
        "&#223;": 12 //ß
    },

    "&#94;": 41, //^
    "&#176;": 41, //°
    "&#34;": 3, //"
    "&#167;": 4, //§
    "&#36;": 5, //$
    "&#38;": 7, //&
    "&#47;": 8, ///
    "&#123;": 8, //{
    "&#40;": 9, //(
    "&#91;": 9, //[
    "&#41;": 10, //)
    "&#93;": 10, //]
    "&#61;": 11, //=
    "&#125;": 11, //}
    "&#223;": 12, //ß
    "&#63;": 12, //?
    "&#92;": 12, //\
    "&#180;": 13, //´
    "&#96;": 13, //`

    "&#122;": 21, //z
    "&#252;": 26, //ü
    "&#43;": 27, //+
    "&#42;": 27, //*
    "&#126;": 27, //~
    "&#246;": 39, //ö
    "&#228;": 40, //ä
    "&#35;": 43, //#
    "&#39;": 43, //'

    "&#60;": 86, //<
    "&#62;": 86, //>
    "&#124;": 86, //|
    "&#121;": 44, //y
    "&#44;": 51, //,
    "&#59;": 51, //;
    "&#46;": 52, //.
    "&#58;": 52, //:
    "&#45;": 53, //-
    "&#95;": 53, //_

    "&#64;": 16, //@
    "atmark": 16, //@
    "&#8364;": 18 //€
}
