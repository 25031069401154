const template = require('./overlay2.html');
const vueUtils = require('../util.js');
const app = require('../../app.js');
const platform = require('../../../platform/platform');
const $ = require('jquery');
const Vuex = require('vuex');

module.exports = {
    template,

    props: {
        show: {
            type: Boolean,
            required: false,
            default: true
        },
        backdrop: {
            type: Boolean,
            required: false,
            default: false
        },
        focusArea: {
            type: String,
            required: true
        },
        upscale: {
            type: Boolean,
            required: false,
            default: false
        },
        heightInPercent: {
            type: Number,
            required: false
        },
        recede: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data: function() {
        return {
            fullyOpened: false,
            showDragCancelButton: false,
            dragCancelAreaContainerId: 'drag-cancel-container',
            dragCancelButtonId: 'drag-cancel',
            scalingFactor: 1
        };
    },

    computed: {
        dragCancelAreaActive: function() {
            return this.getDragToId === this.dragCancelAreaContainerId;
        },

        ...Vuex.mapGetters('controlScreen', ['getDragToId'])
    },

    watch: {
        show: function() {
            this.handleShow();
        },

        recede: function(val) {
            this.handleShow();
            if (val) {
                setTimeout(function() {
                    if (this.recede) {
                        this.showDragCancelButton = true;
                    }
                }.bind(this), 500);
            } else {
                this.showDragCancelButton = false;
            }
        },

        heightInPercent: function() {
            this.rescale();
        }
    },

    methods: {
        onDragEnter: function(evt) {
            const id = this.dragCancelAreaContainerId;

            if (evt.target?.id === id || evt.target?.id === this.dragCancelButtonId) {
                this.$store.dispatch('controlScreen/setDragToId', id);
            }
        },

        onTouchMove: function(evt) {
            const id = this.dragCancelAreaContainerId;

            const x = evt.touches[0].clientX;
            const y = evt.touches[0].clientY;
            const elementAtTouchPoint = document.elementFromPoint(x, y);

            let dragToId = !elementAtTouchPoint.id ? '' : elementAtTouchPoint.id;
            if (elementAtTouchPoint?.id === this.dragCancelButtonId) {
                dragToId = id;
            }

            this.$store.dispatch('controlScreen/setDragToId', dragToId);
        },

        handleShow: function() {
            if (this.show && !this.recede) {
                this.timeoutHandle = setTimeout(() => {
                    this.maybeFocusRemote();
                    this.fullyOpened = !this.recede;
                }, 500);
            } else {
                if (!this.recede) {
                    this.remote.destroyArea(this.$refs['focus-area']);
                }

                if (this.timeoutHandle) {
                    clearTimeout(this.timeoutHandle);
                }
                this.fullyOpened = false;
            }
        },

        rescale: function() {
            const scalerNode = this.$refs.scaler;
            const contentNode = this.$refs['focus-area'];
            const slotNode = this.$refs.slot.children[0];

            scalerNode.style.transform = 'scale(1)';
            scalerNode.style.right = '0px';
            scalerNode.style.removeProperty('width');
            scalerNode.style.position = 'absolute';

            const scalerRealWidth = scalerNode.offsetWidth;
            scalerNode.style.width = '0px';
            scalerNode.style.removeProperty('right');

            const minimumWidth = 1024;
            const targetWidth = Math.max(scalerRealWidth, minimumWidth);

            let scale = targetWidth / contentNode.scrollWidth;
            scale = this.upscale ? scale : Math.min(scale, 1);

            // Add one pixel to avoid scrollbar in Chrome
            const scaledWidthToFillScreen = parseInt(scalerRealWidth / scale + 1);

            scalerNode.style.transform = `scale(${scale})`;
            scalerNode.style.transformOrigin = 'top left';
            scalerNode.style.left = '0px';
            scalerNode.style.removeProperty('right');
            scalerNode.style.width = `${scaledWidthToFillScreen}px`;

            // Set max height of overlay content to view port height
            const viewPort = $('#aspect-ratio-4-3');
            contentNode.style.maxHeight = `${(viewPort.height() - 100) / scale}px`;

            // Set height of overlay slot in percent relative to view port height
            if (this.heightInPercent !== undefined) {
                slotNode.style.height = `${viewPort.height() * this.heightInPercent / 100 / scale}px`;
            }

            this.scalingFactor = scale;
        },

        backdropClicked: function() {
            this.$emit('backdrop-clicked');
        },

        closeClicked: function() {
            this.$emit('backdrop-clicked');
        },

        /**
         * Focus remote area or specific element.
         * @param focusEl If a specific element should be focused, e.g. after closing a modal.
         */
        maybeFocusRemote: function(focusEl) {
            if (platform.checks.isCbox && this.show) {
                if (focusEl && focusEl.length > 0) {
                    this.remote.focus(focusEl);
                } else if (this.focusArea) {
                    this.remote.focusArea($(this.$refs['focus-area']), {
                        area: this.focusArea,
                        trapped: true
                    });
                }
            }
        },

        i18n: vueUtils.i18n
    },

    created: function() {
        this.remote = app.getService('RemoteService');

        this.evctx = vueUtils.eventContext();
        this.evctx.on('livestream-size.update', this.rescale.bind(this));
        this.evctx.on('aspect-ratio.changed', this.rescale.bind(this));
        this.evctx.on('active-framebox.changed', this.rescale.bind(this));
        this.evctx.on('overlay2.focus', function() {
            // Do not focus close button, when modal is opened and overlay2 is in background.
            if (app.getService('ModalHandlerService').hasOpenModal()) {
                return;
            }

            this.maybeFocusRemote();
        }.bind(this));
        this.evctx.on('modal.closed', function(modal) {
            if (modal) {
                this.maybeFocusRemote($(this.$refs['focus-area']).find('[data-nav-focus-id=' + modal.id + ']'));
            }
        }.bind(this));
    },

    mounted: function() {
        this.observer = new MutationObserver(() => {
            this.rescale();
        });

        this.observer.observe(this.$el, {
            childList: true,
            subtree: true,
            attributes: false
        });
    },

    destroyed: function() {
        this.evctx.close();
        this.observer.disconnect();
    }
};
