'use strict';

var app = require('../../app');
var $ = require('jquery');

/**
 * CustomCursor Component
 */
app.component('CustomCursor', {
    className: 'custom-cursor',

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.hideTimeout = null;
        this.remote = this.getService('RemoteService');
        this.isTouch = false;
        this.lastPage = {
            x: -1,
            y: -1
        };
    },

    postPlaceAt: function() {
        this.bindDOMEvents();
        this.bindEvents();

        this.visible = false;
    },

    bindDOMEvents: function() {
        $(document).on('touchmove', this.enableTouch.bind(this));
        $(document).on('touchstart', this.enableTouch.bind(this));
        $(document).on('touchend', this.disableTouch.bind(this));
        $(document).on('mousemove', this.moveHandler.bind(this));
        $(document).on('keydown touchstart', this.hide.bind(this));
    },

    bindEvents: function() {
        this.on('custom-cursor.disable', this.enableTouch.bind(this));
        this.on('custom-cursor.enable', this.disableTouch.bind(this));
    },

    /**
     * Sets the touchscreen variable to true when we got a touch-event.
     */
    enableTouch: function() {
        this.isTouch = true;
    },

    /**
     * Sets the touchscreen variable to false when touch has been stopped.
     */
    disableTouch: function() {
        // RELEASE-1993 - Red pointer appears when sliding a finger on the screen.
        // Browser will trigger a mousemove-event after a touchstart-event has been triggered.
        setTimeout(function() {
            this.isTouch = false;
        }.bind(this), 200);
    },

    show: function() {
        this.startHideTimeout();

        if (this.visible === false) {
            this.visible = true;
            this.$el.show();
            this.remote.unblock();
        }
    },

    hide: function() {
        if (this.visible === true) {
            setTimeout(this.hide.bind(this), 100);

            this.visible = false;
            this.$el.hide();
        }
    },

    startHideTimeout: function() {
        clearTimeout(this.hideTimeout);
        this.hideTimeout = setTimeout(this.hide.bind(this), 500);
    },

    /**
     * Checks if the course has moved then show custom cursor
     * @param {jQuery.Event} event
     */
    moveHandler: function(event) {
        var top;
        var left;
        var right;
        var bottom;

        if (this.isTouch) {
            return;
        }

        if (event.pageX !== this.lastPage.x || event.pageY !== this.lastPage.y) {
            this.show();
        }

        this.lastPage = {
            x: event.pageX,
            y: event.pageY
        };

        // Set cursor positions
        top = event.pageY - this.$el.height() / 2;
        left = event.pageX - this.$el.width() / 2;
        right = left + this.$el.height();
        bottom = top + this.$el.height();

        // Check is cursor in window
        if (right > app.$window.width()) {
            left = app.$window.width() - this.$el.width();
        }

        if (bottom > app.$window.height()) {
            top = app.$window.height() - this.$el.height();
        }

        this.$el.css({
            top: top + 'px',
            left: left + 'px'
        });
    }

});
