const Vue = require('vue');
const Vuex = require('vuex');

const sources = require('./modules/sources');
const uiSettings = require('./modules/ui-settings');
const controlScreen = require('./modules/control-screen');
const matrix = require('./modules/matrix');

Vue.use(Vuex);

module.exports = new Vuex.Store({
    modules: {
        sources,
        uiSettings,
        controlScreen,
        matrix
    }
});
