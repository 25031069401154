'use strict';

module.exports.namespaced = true;

module.exports.state = {
    sources: []
};

module.exports.mutations = {
    SET_SOURCES(state, sources) {
        state.sources = sources;
    }
};

module.exports.actions = {
    setSources({ commit }, sources) {
        commit('SET_SOURCES', sources);
    }
};

module.exports.getters = {
    getSources: state => {
        return state.sources;
    },
    getNumberOfSources: state => {
        return state.sources.length;
    },
    isMirrorProtected: state => {
        return state.sources.some(source => source.type === 'mirror');
    }
};
