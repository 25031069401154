'use strict';

var app = require('../../app');
var _ = require('lodash');
var $ = require('jquery');
var bookmarksTpl = require('./bookmarks.hbs');
var bookmarkTpl = require('./bookmark.hbs');
var ScrollView = require('./../../../scroll-view');

var MAX_BOOKMARKS = 100;

app.component('Bookmarks', {
    template: bookmarksTpl,

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.mountService = this.getService('MountService');
        this.userSettingsService = this.getService('UserSettingsService');
        this.url = this.options.configs.url;
        this.name = this.options.configs.title;
        this.framebox = this.options.configs.framebox;
        this.appId = this.options.configs.appId;

        if (this.options.configs.embeddedPdf) {
            this.url = this.extractUrl(this.options.configs.url);
        }
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.loadBookmarkList();
        this.initForm();
        this.bindEvents();
        this.bindDOMEvents();
        this.handleFormChange();

        if (this.mountService.isUSBmounted()) {
            this.enableBtn(this.$saveUserSettingsBtn, true);
        } else {
            this.enableBtn(this.$saveUserSettingsBtn, false);
        }
    },

    storeSelectors: function() {
        this.$bookmarkListItems = this.$el.find('#bookmark-list-items');
        this.$noEntry = this.$el.find('#bookmark-list-no-entry');
        this.$bookmarkListHeader = this.$el.find('#bookmark-list-header');
        this.$info = this.$el.find('.settings-list-info');
        this.$addBtn = this.$el.find('.btn-add-bookmark');
        this.$saveUserSettingsBtn = this.$el.find('.btn-save-user-settings');
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#bookmarks')
        });

        this.form.get('url').setValue(this.url);
        this.form.get('name').setValue(this.name);
    },

    bindEvents: function() {
        this.on('usb.mount.changed', this.onUsbMountChanged.bind(this));
        this.on('user-settings.settings.update', this.loadBookmarkList.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.bookmark-action-open', this.openBookmark.bind(this));
        this.$el.on('click', '.bookmark-action-delete', this.deleteBookmark.bind(this));
        this.$el.on('click', '.bookmark-action-add', this.addBookmark.bind(this));
        this.$el.on('click', '.save-user-settings', this.saveUserSettings.bind(this));
        this.form.on('change.input', this.handleFormChange.bind(this));
    },

    /**
     * Handle embedded files like PDF.
     *
     * @param url Original URL
     * @returns {*} extracted URL
     */
    extractUrl: function(url) {
        var s = 'url=';
        var extractedUrl = decodeURIComponent(url.substr(url.indexOf(s) + s.length));

        return (extractedUrl && extractedUrl !== '') ? extractedUrl : url;
    },

    /**
     * Call GET bookmark list command, add ID to every bookmark (unique identifier) for rendering list in frontend.
     */
    loadBookmarkList: function() {
        this.deviceConnection
            .send('getBookmarkList')
            .then(function(bookmarks) {
                this.bookmarkList = bookmarks.list;

                this.renderBookmarkList();
            }.bind(this));
    },

    /**
     * Call SET bookmark list command (without IDs) and load the updated list in frontend.
     */
    saveBookmarkList: function() {
        var i;

        // Delete IDs for saving
        for (i = 0; i < this.bookmarkList.bookmarks.length; i++) {
            this.bookmarkList.bookmarks[i].ID = undefined;
        }

        this.deviceConnection
            .send([
                {
                    command: 'setBookmarkList',
                    data: {
                        list: JSON.stringify(this.bookmarkList)
                    }
                }
            ])
            .then(function() {
                this.renderBookmarkList();
            }.bind(this));
    },

    /**
     * Clear list view and update the new bookmark list.
     */
    updateBookmarkList: function() {
        // Delete current list
        _.each(this.$bookmarkListItems.find('.bookmark-item'), function(el) {
            var $el = this.$(el);
            $el.remove();
        }.bind(this));

        // Save and load new list
        this.saveBookmarkList();
    },

    /**
     * Render bookmark list. If it doesn't exist, show no-entry-msg.
     */
    renderBookmarkList: function() {
        var i;

        if (!this.bookmarkList || !this.bookmarkList.bookmarks || this.bookmarkList.bookmarks.length <= 0) {
            this.$bookmarkListHeader.hide();
            this.$noEntry.show();

            return;
        }

        // Add IDs for rendering
        for (i = 0; i < this.bookmarkList.bookmarks.length; i++) {
            this.bookmarkList.bookmarks[i].ID = i;
        }

        this.$bookmarkListHeader.show();
        this.$noEntry.hide();

        _.each(this.bookmarkList.bookmarks, function(bookmark) {
            var $item = this.$(bookmarkTpl(bookmark));
            this.$bookmarkListItems.prepend($item);
        }.bind(this));

        this.initScrollView();
        this.handleFormChange();
    },

    /**
     * Open selected bookmark entry (url) in current browser framebox and close bookmarks overlay.
     *
     * @param event
     */
    openBookmark: function(event) {
        var $el = this.$(event.currentTarget);
        var url = $el.data('url');

        this.emit('overlay.close');
        this.emit('backdrop.hide');

        this.deviceConnection
            .send('setBrowserUrl', {
                appId: this.appId,
                url: url
            });
    },

    /**
     * Add new bookmark by pressing add btn and update list.
     */
    addBookmark: function() {
        var bookmark = {
            ID: this.bookmarkList.bookmarks.length,
            NAME: this.form.get('name').getValue(),
            URL: this.form.get('url').getValue()
        };

        this.bookmarkList.bookmarks.push(bookmark);
        this.updateBookmarkList();
    },

    /**
     * Delete bookmark entry by pressing trash can icon in column and update list.
     *
     * @param event
     */
    deleteBookmark: function(event) {
        event.stopPropagation();
        var $item = this.$(event.currentTarget);
        var tmpList = this.bookmarkList.bookmarks.filter(function(bookmark) {
            return bookmark.ID !== $item.data('id');
        });

        this.bookmarkList.bookmarks = tmpList;
        this.updateBookmarkList();
    },

    /**
     * Call save user settings modal
     */
    saveUserSettings: function() {
        this.userSettingsService.openUserSettingsDialog(false);
    },

    /**
     * Handle form change.
     * If input form is empty, disable add btn.
     */
    handleFormChange: function() {
        if ((this.bookmarkList && this.bookmarkList.bookmarks.length >= MAX_BOOKMARKS)) {
            this.$info.show();
            this.$addBtn.prop('disabled', true);
            this.$addBtn.addClass('is-disabled');

            return;
        }

        this.$info.hide();

        if (this.form.get('name').getValue() === '' || this.form.get('url').getValue() === '') {
            this.$addBtn.prop('disabled', true);
            this.$addBtn.addClass('is-disabled');
        } else {
            this.$addBtn.prop('disabled', false);
            this.$addBtn.removeClass('is-disabled');
        }
    },

    initScrollView: function() {
        var $container = $('.overlay-content-view');

        if (this.scrollView) {
            this.scrollView.destroy();
        }

        this.scrollView = new ScrollView({
            $container: $container,
            $items: $container.find('.bookmark-item')
        });
    },

    destroy: function() {
        if (this.scrollView) {
            this.scrollView.destroy();
        }
    },

    /**
     * If usb is unmounted, close overlay if it's opened.
     * Enable/Disable save user settings btn
     *
     * @param {object} data
     */
    onUsbMountChanged: function(data) {
        if (data.mounted) {
            this.enableBtn(this.$saveUserSettingsBtn, true);
        } else {
            this.enableBtn(this.$saveUserSettingsBtn, false);
            this.emit('overlay.close');
            this.emit('backdrop.hide');
        }
    },

    /**
     * Enable/Disable button
     *
     * @param btn button
     * @param enable true = enable, false = disable
     */
    enableBtn: function(btn, enable) {
        btn.prop('disabled', !enable);
    }
});
