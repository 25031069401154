const template = require('./pip-controls.html');
const vueUtils = require('../../util.js');

module.exports = {
    template,
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data: function() {
        return {
            selected: false
        };
    },

    methods: {
        /**
         * Callback when slider is changed
         */
        sliderChanged: function() {
            this.pollHelper.sync(function() {
                return this.wolfprot.talk('setPipContent', {
                    content: this.selected ? 'main' : 'camera'
                });
            }.bind(this));
        },

        i18n: vueUtils.i18n
    },

    created: function() {
        this.wolfprot = vueUtils.wolfprot();
        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return this.wolfprot.talk('getPipContent');
            }.bind(this)
        });
        this.pollHelper.on('data', function(pipContent) {
            this.selected = pipContent.content === 'main';
        }.bind(this));
        this.pollHelper.schedulePoll();

        this.evctx = vueUtils.eventContext();
        this.evctx.on('main-loop.update', function() {
            this.pollHelper.schedulePoll();
        }.bind(this));
    },

    destroyed: function() {
        this.evctx.close();
    },

    components: {
        'boxed-control': require('../boxed-control/boxed-control.js'),
        'slider': require('../../slider/slider.js')
    }
};
