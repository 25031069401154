'use strict';

const app = require('../../app');
const tpl = require('./regulatory-info.html');
const vueUtils = require('../util');

app.component('RegulatoryInfo', {
    engine: 'vue',
    className: 'regulatory-info-container',
    template: tpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    created: function() {
        this.user = this.component.options.configs.options.user;
    },

    methods: {
        i18n: vueUtils.i18n,

        close: function() {
            this.component.emit('modal.close');

            if (this.user !== 'admin') {
                window.location.reload();
            } else {
                this.component.emit('overlay.open', {
                    id: 'general-settings',
                    extendedConfigs: 'settings-list',
                    animation: 'fade'
                });
            }
        }
    }
});
