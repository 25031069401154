const template = require('./layout2.html');

module.exports = {
    template,
    props: {
        numberCustomCommandButtons: {
            type: Number,
            required: true
        },

        hasVolume: {
            type: Boolean,
            required: true
        },

        showGroupworkButton: {
            type: Boolean,
            required: true
        },

        showDownloadAppButton: {
            type: Boolean,
            required: true
        },

        showRoomControllerButton: {
            type: Boolean,
            required: true
        },

        showSaveUserSettingsButton: {
            type: Boolean,
            required: true
        },

        showSplashScreenButton: {
            type: Boolean,
            required: true
        },

        showCloseAllWindowsButton: {
            type: Boolean,
            required: true
        },

        showQuicktoolControls: {
            type: Boolean,
            required: true
        },

        limited: {
            type: Boolean
        },

        audioMode: {
            type: Boolean
        },

        focusable: {
            type: Boolean,
            required: true
        }
    },

    data: function() {
        return {
            components: [{
                type: 'vmeeting-controls',
                options: {}
            }, {
                type: 'recording-controls',
                options: {}
            }, {
                type: 'webcast-controls',
                options: {}
            }, {
                type: 'streaming-controls',
                options: {}
            }, {
                type: 'volume-controls',
                options: {}
            }, {
                type: 'custom-command-controls',
                options: {}
            }, {
                type: 'pip-controls',
                options: {}
            }, {
                type: 'mirroring-controls',
                options: {}
            }]
        };
    },

    computed: {
        focusArea: function() {
            return this.focusable ? '.is-focusable:not(.disabled)' : '';
        },

        hasCustomCommands: function() {
            return this.numberCustomCommandButtons > 0;
        }
    },

    methods: {
        /**
         * Close the control center
         */
        close: function() {
            this.$emit('close');
        }
    },

    components: {
        'command-button': require('../../../components-vue/control-center-v3/command-button/command-button.js'),
        'vmeeting-controls': require('../../../components-vue/control-center-v3/vmeeting-controls/vmeeting-controls.js'),
        'recording-controls': require('../../../components-vue/control-center-v3/recording-controls/recording-controls.js'),
        'streaming-controls': require('../../../components-vue/control-center-v3/streaming-controls/streaming-controls.js'),
        'mirroring-controls': require('../../../components-vue/control-center-v3/mirroring-controls/mirroring-controls.js'),
        'pip-controls': require('../../../components-vue/control-center-v3/pip-controls/pip-controls.js'),
        'volume-controls': require('../../../components-vue/control-center-v3/volume-controls/volume-controls.js'),
        'custom-command-controls': require('../../../components-vue/control-center-v3/custom-command-controls/custom-command-controls.js'),
        'quicktool-controls': require('../../../components-vue/control-center-v3/quicktool-controls/quicktool-controls.js'),
        'general-controls': require('../../../components-vue/control-center-v3/general-controls/general-controls.js'),
        'groupwork-controls': require('../../../components-vue/control-center-v3/groupwork-controls/groupwork-controls.js')
    }
};
