'use strict';

var platform = require('./../platform/platform');
var mapping = require('./mapping.json');
var characterMapping = require('./character-to-key-code.json');

var instance = null;

var KeyMapper = function() {

};

KeyMapper.prototype.mapByEvent = function(event, defautlKey) {
    event = event || window.event;

    // Handle special characters in IE and Edge
    if ((platform.checks.isIe || platform.checks.isEdge) && event.originalEvent.key.length === 1 && !event.originalEvent.code) {
        event.originalEvent.code = characterMapping[event.originalEvent.keyCode];
    }

    var key = this.characterToKeyCharacter(event.originalEvent.code || event.key);

    var mappedKey = this.map(key);

    if (!mappedKey) {
        return defautlKey;
    }

    return mappedKey;
};

/**
 * Maps keycode to the unix keycode.
 *
 * @param key
 * @return {*}
 */
KeyMapper.prototype.map = function(key) {
    return mapping[key];
};

/**
 * Maps the keys to the correct keyCode.
 *
 * - When the user will press a the way is like: "a -> KeyA -> 64"
 *
 * @param character
 *
 * @return {Number}
 */
KeyMapper.prototype.characterToKeyCode = function(character) {
    character = this.characterToKeyCharacter(character);

    return characterMapping.indexOf(character);
};

/**
 * Generates the correct Code for the original event.
 *
 * @param character
 *
 * @return {String}
 */
KeyMapper.prototype.characterToKeyCharacter = function(character) {
    var checkCharacter = /^[A-Z]$/i;
    var checkNumber = /^[0-9]$/i;

    // Letters became a Key prefix (a -> KeyA)
    if (checkCharacter.test(character)) {
        character = character.toUpperCase();
        character = 'Key' + character;
    }

    // Numbers became a Digit prefix (1 -> Digit1)
    if (checkNumber.test(character)) {
        character = 'Digit' + character;
    }

    // Arrows became a Arrow prefix (left -> ArrowLeft)
    if (-1 !== ['left', 'up', 'right', 'down'].indexOf(character)) {
        character = 'Arrow' + character.charAt(0).toUpperCase() + character.slice(1);
    }

    return character;
};

/**
 * Singleton pattern.
 */
function getInstance() {
    if (!instance) {
        instance = new KeyMapper();
    }

    return instance;
}

module.exports = getInstance();
