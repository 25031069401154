'use strict';

const $ = require('jquery');
const _ = require('lodash');
const app = require('../../../app');
const outputSettingsTpl = require('./output-settings.hbs');
const FormActionView = require('../form-action-view');
const platform = require('../../../../platform/platform');
const formatBytes = require('../../../helper').formatBytes;
const i18n = require('i18next');
const JSZip = require('jszip');
const path = require('path');

const localScreensaverStates = require('../../../states').localScreensaverStates;

/**
 * Index names for local screensaver supported by Lighttpd on Cynap.
 */
const supportedIndexNames = ['index.html'];

/**
 * Max size of local screensaver archive in bytes (500MB).
 */
const LOCAL_SCREENSAVER_MAX_SIZE = 524288000;

/**
 * HDMI Out 2 modes.
 */
const hdmiOut2Modes = [
    {
        text: 'settings.confidence_mirror',
        value: 'confidenceMirror'
    },
    {
        text: 'settings.confidence_full_hd',
        value: 'confidenceFullHD'
    },
    {
        text: 'settings.confidence_hd',
        value: 'confidenceHD'
    },
    {
        text: 'settings.content_full_hd',
        value: 'contentFullHD'
    },
    {
        text: 'settings.content_hd',
        value: 'contentHD'
    },
    {
        text: 'settings.moderator_full_hd',
        value: 'moderatorFullHD'
    }/* ,
    {
        text: 'moderator_hd',
        value: 'moderatorHD'
    }*/
];

/**
 * Content types.
 */
const contentTypes = [
    {
        text: 'settings.none',
        value: 'none'
    },
    {
        text: 'settings.airplay',
        value: 'airplay'
    },
    {
        text: 'settings.audio',
        value: 'audio'
    },
    {
        text: 'settings.browser',
        value: 'browser'
    },
    {
        text: 'settings.hdmi_input',
        value: 'hdmiInput'
    },
    {
        text: 'settings.hdmi_input_auto',
        value: 'hdmiInput'
    },
    {
        text: 'settings.hdmi_input_1',
        value: 'hdmiInput1'
    },
    {
        text: 'settings.hdmi_input_2',
        value: 'hdmiInput2'
    },
    {
        text: 'settings.image',
        value: 'image'
    },
    {
        text: 'settings.miracast',
        value: 'miracast'
    },
    {
        text: 'settings.office365_outlook',
        value: 'office365Outlook'
    },
    {
        text: 'settings.office365_word',
        value: 'office365Word'
    },
    {
        text: 'settings.office365_excel',
        value: 'office365Excel'
    },
    {
        text: 'settings.office365_powerpoint',
        value: 'office365PowerPoint'
    },
    {
        text: 'settings.office365_onenote',
        value: 'office365OneNote'
    },
    {
        text: 'settings.office_presentation',
        value: 'officePresentation'
    },
    {
        text: 'settings.office_text',
        value: 'officeText'
    },
    {
        text: 'settings.office_calc',
        value: 'officeCalc'
    },
    {
        text: 'settings.pdf',
        value: 'pdf'
    },
    {
        text: 'settings.stream_input',
        value: 'streamInput'
    },
    {
        text: 'settings.video',
        value: 'video'
    },
    {
        text: 'settings.visualizer_content',
        value: 'visualizer'
    },
    {
        text: 'settings.vsolution_cast',
        value: 'vSolutionCast'
    },
    {
        text: 'settings.webcam',
        value: 'webcam'
    },
    { // WebRTC, Zoom, Teams
        text: 'settings.webconf',
        value: 'webconference'
    },
    {
        text: 'settings.whiteboard',
        value: 'whiteboard'
    }
];

/**
 * Screensaver delay.
 */
const screensaverDelay = [
    {
        text: 'settings.delay_15m',
        value: 15
    },
    {
        text: 'settings.delay_30m',
        value: 30
    },
    {
        text: 'settings.delay_45m',
        value: 45
    },
    {
        text: 'settings.delay_1h',
        value: 60
    },
    {
        text: 'settings.delay_2h',
        value: 120
    },
    {
        text: 'settings.delay_3h',
        value: 180
    },
    {
        text: 'settings.delay_4h',
        value: 240
    },
    {
        text: 'settings.delay_5h',
        value: 300
    },
    {
        text: 'settings.delay_6h',
        value: 360
    },
    {
        text: 'settings.delay_7h',
        value: 420
    },
    {
        text: 'settings.delay_8h',
        value: 480
    }
];

app.component('OutputSettings', {
    template: outputSettingsTpl,

    /**
     * Initialize.
     */
    initialize: function() {
        this.init = true;
        this.formManager = this.getService('FormManager');
        this.frontendSettings = this.getService('FrontendSettings');
        this.customUiSettings = this.getService('CustomUiSettings');
        this.dataStorage = this.getService('DataStorage');
        this.deviceService = this.getService('DeviceService');
        this.isDualProjection = this.deviceService.isCboxProDualProjection();
        this.modelView = this.getService('Model-View');
        this.resolutionChanged = false;
    },

    /**
     * Store selectors.
     */
    storeSelectors: function() {
        this.priorities = this.$el.find('.output-priorities');
        this.notificationSwitch = this.$el.find('.notifications');
        this.hdmi2RecordingSwitch = this.$el.find('.hdmi2-recording');
        this.standbySwitchContainer = this.$el.find('.standby-enable-container');
        this.standbySwitch = this.$el.find('#standby-enable');
        this.screenOffSwitchContainer = this.$el.find('.screen-off-enable-container');
        this.screenOffSwitch = this.$el.find('#screen-off-enable');
        this.screensaverSettings = this.$el.find('#screensaver-settings');
        this.screensaverSubSettings = this.$el.find('#screensaver-sub-settings');
        this.localScreensaverSelectContainer = this.$el.find('.local-screensaver-select-container');
        this.localScreensaverDeleteContainer = this.$el.find('.local-screensaver-delete-container');
    },

    /**
     * Called after Component was placed inside the dom
     */
    postPlaceAt: function() {
        this.createSelects();
        this.storeSelectors();
        this.initForm();
        this.initFormAction();
        this.loadOutputSettings();
    },

    bindDOMEvents: function() {
        this.form.$el.on('change', '#local-screensaver-files', function(event) {
            this.zipLocalScreensaver(event.target.files);
        }.bind(this));
        this.form.$el.on('click', '#local-screensaver-delete', this.deleteLocalScreensaver.bind(this));
    },

    bindEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.on('remote.enter.keydown', this.checkActionForm.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
    },

    serialize: function() {
        return {
            isLocal: platform.checks.isCbox,
            isCbox: this.deviceService.isCbox(),
            isCboxPureReceiver: this.deviceService.isCboxPureReceiver(),
            isCboxPureMini: this.deviceService.isCboxPureMini(),
            isDualProjection: this.isDualProjection,
            isCboxNonDualProjection: this.deviceService.isCboxNonDualProjection(),
            hasScreenOffNoContent: this.hasScreenOffNoContent(),
            validIndexNames: supportedIndexNames.join(', '),
            maxSize: formatBytes(LOCAL_SCREENSAVER_MAX_SIZE)
        };
    },

    /**
     * 'Screen off when no content' setting is available on CBP and CPM only.
     */
    hasScreenOffNoContent: function() {
        return (this.deviceService.isCboxPure() || this.deviceService.isCboxPureMini())
            && !this.deviceService.isCboxPureReceiver();
    },

    /**
     * Load output settings.
     * @return {*}
     */
    loadOutputSettings: function() {
        return $.when(this.loadData(this), this.loadFrontendSettings(this), this.loadCustomUiSettings(this), this.loadModeSpecificData(this))
            .done(function() {
                this.dataStorage.set('output-settings', {
                    localScreensaver: this.localScreensaver,
                    formData: this.formData,
                    hdmiOut2: this.hdmiOut2 || null
                });

                this.updateForm();
                this.bindDOMEvents();
                this.bindEvents();
                this.init = false;
            }.bind(this));
    },

    /**
     * Load frontend settings.
     * @return {*}
     */
    loadFrontendSettings: function() {
        return this.frontendSettings
            .getSettings(['showNotificationHdmi2'])
            .then(function(showNotification) {
                this.formData['hdmi-out-2-notification'] = showNotification;
            }.bind(this));
    },

    /**
     * Load custom UI settings.
     * @return {*}
     */
    loadCustomUiSettings: function() {
        return this.customUiSettings
            .getSettings(['uiScalingSize'])
            .then(function(uiScalingSize) {
                this.formData['ui-scaling-size'] = uiScalingSize;
            }.bind(this));
    },

    /**
     * Load data from backend via wolfprot.
     */
    loadData: function() {
        return this.deviceConnection
            .send([
                { command: 'getMainResolution' },
                { command: 'getScreensaverSettings' },
                { command: 'getByodEndlessMode' },
                { command: 'getLocalScreensaver' },
                { command: 'getScreenOffNoContent' }
            ])
            .then(function(mainResolution, screensaverSettings, byodEndlessMode, localScreensaverAvailability, screenOffNoContent) {
                Object.assign(this.formData, {
                    'hdmi-out-1-resolution': mainResolution.resolution,
                    'screensaver-enable': screensaverSettings.screensaver,
                    'screen-off-enable': screensaverSettings.screenOff,
                    'standby-enable': screensaverSettings.standby,
                    'screensaver-url': screensaverSettings.url,
                    'screen-off-delay-select': screensaverSettings.delayScreenOff,
                    'standby-delay-select': screensaverSettings.delayStandby,
                    'byod-endless-mode': byodEndlessMode.enabled,
                    'auto-screensaver-enable': screensaverSettings.autoScreensaver,
                    'keep-files-enable': screensaverSettings.keepFilesAutoScreensaver,
                    'delay-select': screensaverSettings.delayAutoScreensaver,
                    'interactive-mode-enable': screensaverSettings.interactiveMode,
                    'screen-off-no-content': screenOffNoContent.enabled
                });

                this.localScreensaver = {
                    state: localScreensaverAvailability.state,
                    name: localScreensaverAvailability.name
                };

                this.handleScreensaverSettings(!screenOffNoContent.enabled);
                this.handleScreensaverSubSettings(screensaverSettings.screensaver);
            }.bind(this));
    },

    loadModeSpecificData: function() {
        if (this.isDualProjection) {
            return this.deviceConnection
                .send([
                    { command: 'getControlScreenResolution' }
                ])
                .then(function(controlScreenResolution) {
                    Object.assign(this.formData, {
                        'hdmi-out-control-screen-resolution': controlScreenResolution.resolution
                    });
                }.bind(this));
        } else {
            return this.deviceConnection
                .send([
                    { command: 'getAuxMode' },
                    {
                        command: 'getAuxContentPriority',
                        data: { index: 0 }
                    },
                    {
                        command: 'getAuxContentPriority',
                        data: { index: 1 }
                    },
                    {
                        command: 'getAuxContentPriority',
                        data: { index: 2 }
                    },
                    { command: 'getAuxAllowMirrorOverride' },
                    { command: 'getSwapHdmiOutputs' },
                    { command: 'getWebconferenceHdmi2' },
                    { command: 'getContentModeRecordingSource' },
                    { command: 'getWindowSettings' }
                ])
                .then(function(hdmiOut2, contentPriority1, contentPriority2,
                    contentPriority3, hdmiOut2AllowOverride, hdmiSwap, webconferenceHdmi2, contentModeRecording,
                    windowSettings) {
                    Object.assign(this.formData, {
                        'hdmi-out-2-mode': hdmiOut2.auxMode,
                        'hdmi-out-2-priority-1': contentPriority1.type,
                        'hdmi-out-2-priority-2': contentPriority2.type,
                        'hdmi-out-2-priority-3': contentPriority3.type,
                        'allow-override': hdmiOut2AllowOverride.allow,
                        'swapHdmi': hdmiSwap.swap,
                        'webconferenceHdmi2': webconferenceHdmi2.hdmi2,
                        'hdmi-out-2-content-recording': contentModeRecording.mode === 'aux',
                        'window-mode': windowSettings.mode
                    });

                    this.hdmiOut2 = hdmiOut2;

                    this.priorityVisibilityHandler(hdmiOut2.contentMode);
                    this.contentModeVisibilityHandler(hdmiOut2.auxMode);
                }.bind(this));
        }
    },

    /**
     * Handle priority field visibility.
     */
    priorityVisibilityHandler: function(contentMode) {
        if (contentMode) {
            this.priorities.removeClass('hidden');
        } else {
            this.priorities.addClass('hidden');
        }
    },

    /**
     * Handle content mode switch visibility.
     */
    contentModeVisibilityHandler: function(contentMode) {
        if (contentMode.indexOf('content') !== -1) {
            this.notificationSwitch.hide();

            if (contentMode === 'contentHD') {
                this.hdmi2RecordingSwitch.hide();
            } else {
                this.hdmi2RecordingSwitch.show();
            }
        } else {
            this.notificationSwitch.show();
            this.hdmi2RecordingSwitch.hide();
        }
    },

    /**
     * Update form.
     */
    updateForm: function() {
        this.form.setValues(this.formData);
        this.handleScreensaverChangeEnable();
        this.handleScreensaverChangeDelay();
        this.handleLocalScreensaver();
    },

    /**
     * Init form.
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#output-settings'),
            validationContainer: '.input-group'
        });
        if (!platform.checks.isCbox && !this.deviceService.isCboxPureMini()) {
            // Add custom validation for local screensaver
            this.form.inputsStore['local-screensaver-files'].initializeValue = '';
            this.form.inputsStore['local-screensaver-files'].validation = this.validateLocalScreensaver.bind(this);
        }
        this.formData = {};
    },

    /**
     * Init form action.
     */
    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this, false),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    /**
     * Save settings from form.
     *
     * @param {boolean} isCancelSave
     */
    saveHandler: function(isCancelSave) {
        this.newFormData = {
            'hdmi-out-1-resolution': this.form.get('hdmi-out-1-resolution').getValue()
        };

        let cmds = [
            {
                command: 'setMainResolution',
                data: { resolution: this.newFormData['hdmi-out-1-resolution'] }
            }
        ];

        if (this.hasScreenOffNoContent()) {
            this.newFormData['screen-off-no-content'] = this.form.get('screen-off-no-content').getValue();

            cmds.push({
                command: 'setScreenOffNoContent',
                data: { enabled: this.newFormData['screen-off-no-content'] }
            });
        }

        if (!this.deviceService.isCboxPureMini()) {
            this.newFormData = {
                ...this.newFormData,
                ...{
                    'screensaver-enable': this.form.get('screensaver-enable').getValue(),
                    'screen-off-enable': this.form.get('screen-off-enable').getValue(),
                    'standby-enable': this.form.get('standby-enable').getValue(),
                    'screensaver-url': this.form.get('screensaver-url').getValue(),
                    'screen-off-delay-select': this.form.get('screen-off-delay-select').getValue(),
                    'standby-delay-select': this.form.get('standby-delay-select').getValue(),
                    'delay-select': this.form.get('delay-select').getValue(),
                    'keep-files-enable': this.form.get('keep-files-enable').getValue(),
                    'auto-screensaver-enable': this.form.get('auto-screensaver-enable').getValue()
                }
            };

            const screensaverData = {
                standby: this.newFormData['standby-enable'],
                screenOff: this.newFormData['screen-off-enable'],
                screensaver: this.newFormData['screensaver-enable'],
                delayStandby: this.newFormData['standby-delay-select'],
                delayScreenOff: this.newFormData['screen-off-delay-select'],
                url: this.newFormData['screensaver-url'],
                autoScreensaver: this.newFormData['auto-screensaver-enable'],
                delayAutoScreensaver: this.newFormData['delay-select'],
                keepFilesAutoScreensaver: this.newFormData['keep-files-enable']
            };

            if (!this.isDualProjection) {
                this.newFormData['interactive-mode-enable'] = this.form.get('interactive-mode-enable').getValue();
                screensaverData.interactiveMode = this.newFormData['interactive-mode-enable'];
            }

            cmds = [
                ...cmds,
                ...[
                    {
                        command: 'setScreensaverSettings',
                        data: screensaverData
                    }
                ]
            ];
        }

        if (!this.deviceService.isCboxPureReceiver() && !this.isDualProjection) {
            this.newFormData = {
                ...this.newFormData,
                ...{
                    'window-mode': this.form.get('window-mode').getValue(),
                    'byod-endless-mode': this.form.get('byod-endless-mode').getValue()
                }
            };

            cmds = [
                ...cmds,
                ...[
                    {
                        command: 'setWindowSettings',
                        data: { mode: this.newFormData['window-mode'] }
                    },
                    {
                        command: 'setByodEndlessMode',
                        data: { enabled: this.newFormData['byod-endless-mode'] }
                    }
                ]
            ];
        }

        if (this.deviceService.isCbox()) {
            if (this.isDualProjection) {
                this.newFormData = {
                    ...this.newFormData,
                    'hdmi-out-control-screen-resolution': this.form.get('hdmi-out-control-screen-resolution').getValue()
                };

                cmds = [
                    ...cmds,
                    {
                        command: 'setControlScreenResolution',
                        data: { resolution: this.newFormData['hdmi-out-control-screen-resolution'] }
                    }

                ];
            } else {
                let allowOverride = this.form.get('allow-override').getValue();
                let hdmiOut2Mode = this.form.get('hdmi-out-2-mode').getValue();

                if (allowOverride && hdmiOut2Mode.substring(0, 4) === 'conf') {
                    allowOverride = false;
                }

                this.newFormData = {
                    ...this.newFormData,
                    ...{
                        'hdmi-out-2-mode': this.form.get('hdmi-out-2-mode').getValue(),
                        'hdmi-out-2-priority-1': this.form.get('hdmi-out-2-priority-1').getValue(),
                        'hdmi-out-2-priority-2': this.form.get('hdmi-out-2-priority-2').getValue(),
                        'hdmi-out-2-priority-3': this.form.get('hdmi-out-2-priority-3').getValue(),
                        'allow-override': allowOverride,
                        'hdmi-out-2-content-recording': this.form.get('hdmi-out-2-content-recording').getValue(),
                        'swapHdmi': this.form.get('swapHdmi').getValue(),
                        'webconferenceHdmi2': this.form.get('webconferenceHdmi2').getValue()
                    }
                };

                cmds = [
                    ...cmds,
                    ...[
                        {
                            command: 'setAuxMode',
                            data: { mode: this.newFormData['hdmi-out-2-mode'] }
                        },
                        {
                            command: 'setAuxContentPriority',
                            data: {
                                index: 0,
                                type: this.newFormData['hdmi-out-2-priority-1']
                            }
                        },
                        {
                            command: 'setAuxContentPriority',
                            data: {
                                index: 1,
                                type: this.newFormData['hdmi-out-2-priority-2']
                            }
                        },
                        {
                            command: 'setAuxContentPriority',
                            data: {
                                index: 2,
                                type: this.newFormData['hdmi-out-2-priority-3']
                            }
                        },
                        {
                            command: 'setAuxAllowMirrorOverride',
                            data: { allow: this.newFormData['allow-override'] }
                        },
                        {
                            command: 'setContentModeRecordingSource',
                            data: { mode: this.newFormData['hdmi-out-2-content-recording'] }
                        },
                        {
                            command: 'setSwapHdmiOutputs',
                            data: { swap: this.newFormData['swapHdmi'] }
                        },
                        {
                            command: 'setWebconferenceHdmi2',
                            data: { hdmi2: this.newFormData['webconferenceHdmi2'] }
                        }
                    ]
                ];

                this.frontendSettings.updateSetting({
                    tag: 'showNotificationHdmi2',
                    value: this.form.get('hdmi-out-2-notification').getValue()
                });
                this.frontendSettings.saveSettings();
            }
        }

        if (this.localScreensaver.dataAsArray !== undefined) {
            cmds = [
                ...cmds,
                {
                    command: 'setLocalScreensaver',
                    data: {
                        action: this.localScreensaver.dataAsArray.length > 0 ? 'save' : 'delete',
                        name: this.localScreensaver.name,
                        bytes: this.localScreensaver.dataAsArray
                    }
                }
            ];
        }
        this.deviceConnection.send(cmds).then(function() {
            if (this.localScreensaver.dataAsArray !== undefined) {
                this.localScreensaver = {
                    state: this.localScreensaver.dataAsArray.length > 0
                        ? localScreensaverStates.available : localScreensaverStates.notAvailable,
                    name: this.localScreensaver.name
                };

                this.handleLocalScreensaver(true);
            }

            this.customUiSettings.updateSetting({
                tag: 'uiScalingSize',
                value: this.form.get('ui-scaling-size').getValue()
            });
            this.customUiSettings.saveSettings();

            this.handleSettingsSave();
            this.form.setDefaultValues();
            this.emit('overlay.remote.focus', true);

            if (!isCancelSave && this.resolutionChanged) {
                this.openConfirmHandler();
            }
        }.bind(this));
    },

    /**
     * Open Display-Settings confirmation modal.
     */
    openConfirmHandler: function() {
        this.emit('modal.open', {
            id: 'displaySettings',
            onDiscare: this.onModalDiscareHandler.bind(this),
            onConfirm: this.onModalConfirmHandler.bind(this)
        });
    },

    /**
     * Called after on confirm-modal "Revert"-Button was clicked or modal was automatically closed after 10s.
     */
    onModalDiscareHandler: function() {
        var data = this.dataStorage.get('output-settings', false);

        if (data) {
            this.formData = data.formData;
            if (!this.isDualProjection) {
                this.contentModeVisibilityHandler(data.hdmiOut2.auxMode);
            }
            this.localScreensaver = data.localScreensaver;
            this.updateForm();
            this.saveHandler(true);
        }

        this.openOutputSettings();
    },

    /**
     * Called after "Keep Changes" button was clicked on the confirm-modal.
     */
    onModalConfirmHandler: function() {
        var hdmiOut2 = this.dataStorage.get('output-settings').hdmiOut2;
        if (this.newFormData && hdmiOut2) {
            hdmiOut2.hdmiOut2Mode = this.newFormData['hdmi-out-2-mode'];
            this.dataStorage.set('output-settings', {
                formData: this.newFormData,
                hdmiOut2: hdmiOut2,
                localScreensaver: this.localScreensaver
            });
        }

        this.openOutputSettings();

        this.emit('overlay.remote.focus', true);
    },

    /**
     * Open output settings overlay
     * TODO: Do not open output settings, open next component.
     */
    openOutputSettings: function() {
        this.emit('overlay.open', {
            id: 'output-settings',
            extendedConfigs: 'settings-list',
            animation: 'fade'
        });

        this.emit('navigation-bar.open', {
            id: 'output-settings'
        });
    },

    /**
     * Calls on clicked the cancel button.
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);

        this.localScreensaver = this.dataStorage.get('output-settings').localScreensaver;
        this.handleLocalScreensaver();

        this.form.resetValues();

        if (this.deviceService.isCboxNonDualProjection()) {
            var mode = this.form.get('hdmi-out-2-mode').getValue();
            this.priorityVisibilityHandler(mode.indexOf('confidence') === -1);
        }

        this.handleScreensaverSettings(!this.form.inputsStore['screen-off-no-content']?.value);

        this.resolutionChanged = false;
        this.changes = false;
    },

    /**
     * Handle settings save after save btn is clicked.
     */
    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.changes = false;
    },

    /**
     * Handle form change.
     */
    handleFormChange: function(data) {
        var input = data.input.name;

        if (input === 'hdmi-out-2-mode') {
            if (data.value.indexOf('confidence') !== -1) {
                this.priorityVisibilityHandler(false);
            } else {
                this.priorityVisibilityHandler(true);
                this.contentModeVisibilityHandler(data.value);
            }
        } else if (input === 'screen-off-no-content') {
            this.handleScreensaverSettings(!data.value);
        } else if (input === 'screensaver-enable' || input === 'screen-off-enable' || input === 'standby-enable') {
            this.handleScreensaverChangeEnable(input);
        } else if (input === 'screen-off-delay-select' || input === 'standby-delay-select') {
            this.handleScreensaverChangeDelay(input);
        } else if (input === 'auto-screensaver-enable') {
            this.handleScreensaverSubSettings(true);
        } else if (input === 'local-screensaver') {
            this.handleLocalScreensaver();
        }

        if (!this.init) {
            if (['swapHdmi', 'setWebconferenceHdmi2', 'hdmi-out-2-mode', 'hdmi-out-1-resolution', 'hdmi-out-control-screen-resolution'].indexOf(input) > -1) {
                this.resolutionChanged = true;
            }

            this.actionView.open();
            this.checkActionForm();
            this.changes = true;
        }
    },

    /**
     * Show/hide screensaver settings.
     * Screensaver settings are not available when 'Screen off when no content' setting is enabled.
     */
    handleScreensaverSettings: function(show) {
        if (!this.hasScreenOffNoContent()) {
            return;
        }

        if (show) {
            this.screensaverSettings
                .stop()
                .slideDown(300, () => {
                    this.screensaverSettings.show();
                });
        } else {
            this.screensaverSettings.hide();
        }
    },

    /**
     * Show / Hide screensaver sub-settings.
     *
     * @param show
     */
    handleScreensaverSubSettings: function(show) {
        if (this.deviceService.isCboxPureMini()) {
            return;
        }

        if (show) {
            this.screensaverSubSettings
                .stop()
                .slideDown(300);

            this.screensaverSubSettings.show();

            if (this.form.get('auto-screensaver-enable').getValue()) {
                this.enableItem('delay-select', true);
                this.enableItem('keep-files-enable', true);
            } else {
                this.enableItem('delay-select', false);
                this.enableItem('keep-files-enable', false);
            }
        } else {
            this.screensaverSubSettings.hide();
        }
    },

    /**
     * Handle screensaver form change.
     * Enable/disable affected buttons and input fields.
     */
    handleScreensaverChangeEnable: function(input) {
        if (this.deviceService.isCboxPureMini()) {
            return;
        }

        this.enableItem('screensaver-url', this.form.get('screensaver-enable').getValue()
            ? this.form.get('screensaver-enable').getValue() : false);
        this.enableItem('screen-off-delay-select', this.form.get('screen-off-enable').getValue()
            ? this.form.get('screensaver-enable').getValue() : false);
        this.enableItem('standby-delay-select', this.form.get('standby-enable').getValue()
            ? this.form.get('screensaver-enable').getValue() || this.form.get('screen-off-enable').getValue() : false);

        if (this.form.get('screensaver-enable').getValue() || this.form.get('screen-off-enable').getValue()) {
            this.standbySwitch.prop('disabled', false);
            this.standbySwitchContainer.removeClass('is-disabled');
        } else {
            this.form.get('standby-enable').setValue(false);
            this.standbySwitch.prop('disabled', true);
            this.standbySwitchContainer.addClass('is-disabled');
        }

        this.handleScreensaverSubSettings(this.form.get('screensaver-enable').getValue());
        this.handleScreensaverChangeDelay(input); // RELEASE-1643
    },

    /**
     * Handle screensaver form change.
     * Update delay dropdown/selects and enable/disable btns.
     *
     * Standby delay < screen off delay -> screen off or standby is updated.
     * Standby delay === screen off delay -> disable screen off because after screensaver, it would immediately
     * go to standby and skip screen off.
     *
     * @param input
     */
    handleScreensaverChangeDelay: function(input) {
        if (this.deviceService.isCboxPureMini()) {
            return;
        }

        var screenOffDelay = parseInt(this.form.get('screen-off-delay-select').getValue());
        var standbyDelay = parseInt(this.form.get('standby-delay-select').getValue());

        if (standbyDelay < screenOffDelay) {
            switch (input) {
                case 'screen-off-delay-select':
                    this.form.get('standby-delay-select').setValue(screenOffDelay);
                    break;
                case 'standby-delay-select':
                    this.form.get('screen-off-delay-select').setValue(standbyDelay);
                    break;
            }
        } else if (standbyDelay === screenOffDelay && this.form.get('standby-enable').getValue()) { // RELEASE-1643
            this.form.get('screen-off-enable').setValue(false);
            this.enableItem('screen-off-delay-select', false);

            this.screenOffSwitch.prop('disabled', true);
            this.screenOffSwitchContainer.addClass('is-disabled');
        } else {
            this.screenOffSwitch.prop('disabled', false);
            this.screenOffSwitchContainer.removeClass('is-disabled');
        }
    },

    handleLocalScreensaver: function(clearFilesSelect) {
        if (clearFilesSelect) {
            this.form.$el.find('#local-screensaver-files').val('');
            this.form.$el.find('.custom-file-chooser').text(i18n.t('settings.no_folder_chosen'));
        }

        if (this.localScreensaver.state === localScreensaverStates.available) {
            this.localScreensaverSelectContainer.hide();
            this.localScreensaverDeleteContainer.show();
        } else {
            this.localScreensaverSelectContainer.show();
            this.localScreensaverDeleteContainer.hide();
        }

        const el = this.form.$el.find('#local-screensaver-error');
        if (false === this.localScreensaver?.validation?.indexFilePresent) {
            el.text(i18n.t('settings.screensaver_invalid_index', { validIndexNames: supportedIndexNames.join(', ') }));
        } else if (false === this.localScreensaver?.validation?.sizeOk) {
            el.text(i18n.t('settings.screensaver_invalid_size', { maxSize: formatBytes(LOCAL_SCREENSAVER_MAX_SIZE) }));
        }

        this.form.$el.find('.custom-file-chooser').text(this.localScreensaver.name || i18n.t('settings.no_folder_chosen'));
        this.form.$el.find('#local-screensaver-name').text(this.localScreensaver.name || i18n.t('settings.screensaver_default_name'));
    },

    /**
     * Create zip archive for local screensaver.
     */
    zipLocalScreensaver: function(fileList) {
        if (fileList.length === 0) {
            return;
        }
        // Validate that index file is present in top level folder
        const indexFilePresent = Object.values(fileList)
            .findIndex(f => supportedIndexNames.includes(f.webkitRelativePath.split(path.sep)[1])) > -1;

        // Validate web page size
        const totalSize = Object.values(fileList).map(f => f.size).reduce((a, b) => a + b, 0);
        const sizeOk = totalSize <= LOCAL_SCREENSAVER_MAX_SIZE;

        if (!indexFilePresent || !sizeOk) {
            this.localScreensaver = {
                state: localScreensaverStates.notAvailable,
                dataAsArray: [42],
                validation: {
                    indexFilePresent: indexFilePresent,
                    sizeOk: sizeOk
                }
            };
            this.handleFormChange({ input: { name: 'local-screensaver' } });

            return;
        }

        const zip = new JSZip();
        let topLevelFolderName = '';

        for (let file of fileList) {
            topLevelFolderName = path.dirname(file.webkitRelativePath).split(path.sep)[0];
            // Remove top level folder from path
            const relativePath = file.webkitRelativePath.substring(topLevelFolderName.length + 1,
                file.webkitRelativePath.length);
            zip.file(relativePath, file);
        }

        zip.generateAsync({ type: 'blob' }).then(function(blobData) {
            let zipBlob = new Blob([blobData]);
            let reader = new FileReader();

            reader.onload = function(event) {
                const dataAsArray = new Uint8Array(event.target.result);
                this.localScreensaver = {
                    state: localScreensaverStates.notAvailable,
                    dataAsArray: dataAsArray,
                    name: topLevelFolderName,
                    validation: {
                        indexFilePresent: indexFilePresent,
                        sizeOk: sizeOk
                    }
                };

                this.handleFormChange({ input: { name: 'local-screensaver' } });
            }.bind(this);
            reader.readAsArrayBuffer(zipBlob);
        }.bind(this));
    },

    validateLocalScreensaver: function() {
        return this.localScreensaver?.state === localScreensaverStates.available
            || (this.localScreensaver?.state === localScreensaverStates.notAvailable
                && (!this.localScreensaver.dataAsArray
                    || this.localScreensaver?.dataAsArray?.length === 0
                    || (this.localScreensaver?.dataAsArray?.length > 0
                        && this.localScreensaver?.validation?.indexFilePresent
                        && this.localScreensaver?.validation?.sizeOk)));
    },

    deleteLocalScreensaver: function() {
        this.localScreensaver = {
            state: localScreensaverStates.notAvailable,
            dataAsArray: []
        };
        this.handleFormChange({ input: { name: 'local-screensaver' } });
    },

    /**
     * Enable/disable item.
     *
     * @param item item name
     * @param enable true/false
     */
    enableItem: function(item, enable) {
        if (enable) {
            this.form.get(item).enable();
        } else {
            this.form.get(item).disable();
        }

        this.emit('select.' + item + '.is-focusable', {
            focusable: enable
        });
    },

    /**
     * Check action form change.
     */
    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * Create selects.
     */
    createSelects: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#hdmi-out-1-resolution-container'),
            label: this.modelView.hdmi1ResolutionTitleKey,
            native: true,
            name: 'hdmi-out-1-resolution',
            items: this.modelView.getHDMIOutputResolutions()
        });

        function getContentTypes() {
            if (!this.deviceService.isCbox) {
                return contentTypes.filter(type => type.value !== 'hdmiInput1'
                    && type.value !== 'hdmiInput2' && type.text !== 'settings.hdmi_input_auto');
            }

            return contentTypes.filter(type => type.text !== 'settings.hdmi_input');
        }

        if (this.deviceService.isCbox()) {
            if (this.isDualProjection) {
                this.createComponent({
                    type: 'CustomSelect',
                    container: this.$el.find('#hdmi-out-control-screen-resolution-container'),
                    label: this.modelView.hdmiControlScreenResolutionTitleKey,
                    native: true,
                    name: 'hdmi-out-control-screen-resolution',
                    items: this.modelView.getHDMIOutputControlScreenResolutions()
                });
            } else {
                this.createComponent({
                    type: 'CustomSelect',
                    container: this.$el.find('#hdmi-out-2-mode-container'),
                    label: 'settings.hdmi2_mode_resolution',
                    native: true,
                    name: 'hdmi-out-2-mode',
                    items: hdmiOut2Modes
                });

                this.createComponent({
                    type: 'CustomSelect',
                    container: this.$el.find('#hdmi-out-2-priority-1-container'),
                    label: 'settings.hdmi_out_2_priority_1',
                    native: true,
                    name: 'hdmi-out-2-priority-1',
                    items: getContentTypes.bind(this)
                });

                this.createComponent({
                    type: 'CustomSelect',
                    container: this.$el.find('#hdmi-out-2-priority-2-container'),
                    label: 'settings.hdmi_out_2_priority_2',
                    native: true,
                    name: 'hdmi-out-2-priority-2',
                    items: getContentTypes.bind(this)
                });

                this.createComponent({
                    type: 'CustomSelect',
                    container: this.$el.find('#hdmi-out-2-priority-3-container'),
                    label: 'settings.hdmi_out_2_priority_3',
                    native: true,
                    name: 'hdmi-out-2-priority-3',
                    items: getContentTypes.bind(this)
                });
            }
        }

        if (!this.deviceService.isCboxPureMini()) {
            this.createComponent({
                type: 'CustomSelect',
                container: this.$el.find('#screen-off-delay-select-container'),
                native: true,
                name: 'screen-off-delay-select',
                items: this.screenOffDelayItems().concat(screensaverDelay)
            });

            this.createComponent({
                type: 'CustomSelect',
                container: this.$el.find('#standby-delay-select-container'),
                native: true,
                name: 'standby-delay-select',
                items: screensaverDelay
            });

            this.createComponent({
                type: 'CustomSelect',
                container: this.$el.find('#delay-select-container'),
                native: true,
                name: 'delay-select',
                items: this.autoScreensaverDelayItems()
            });
        }

        if (!this.deviceService.isCboxPureReceiver() && !this.isDualProjection) {
            this.createComponent({
                type: 'CustomSelect',
                container: this.$el.find('#window-mode-container'),
                label: 'settings.window_mode',
                native: true,
                name: 'window-mode',
                items: [
                    {
                        text: 'settings.side_by_side',
                        value: 'sideBySide'
                    },
                    {
                        text: 'settings.auto_fullscreen',
                        value: 'fullscreen'
                    },
                    {
                        text: 'settings.mixed',
                        value: 'mixed'
                    }
                ]
            });
        }

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#ui-scaling-container'),
            label: 'settings.ui_scaling_size',
            native: true,
            name: 'ui-scaling-size',
            items: [
                {
                    text: 'settings.small',
                    value: 'small'
                },
                {
                    text: 'settings.medium',
                    value: 'medium'
                },
                {
                    text: 'settings.large',
                    value: 'large'
                }
            ]
        });
    },

    /**
     * Return the screen off select items.
     *
     * @returns {Array}
     */
    screenOffDelayItems: function() {
        var items = [0, 1, 2, 3, 4, 5, 10];
        var delayItems = [];
        var i = 0;

        _.each(items, function(item) {
            delayItems[i]
                = {
                    text: (
                        item === 1
                            ? this.sprintf(i18n.t('settings.delay_after_x_min'), (item).toString())
                            : this.sprintf(i18n.t('settings.delay_after_x_m'), (item).toString())
                    ),
                    value: item
                };

            i++;
        }.bind(this));

        return delayItems;
    },

    /**
     * Return the auto screensaver select items.
     *
     * @returns {Array}
     */
    autoScreensaverDelayItems: function() {
        var unitSeconds = 0x8000;
        var items = [unitSeconds + 10, unitSeconds + 20, unitSeconds + 30, unitSeconds + 40, unitSeconds + 50, 1, 2, 3, 4, 5, 10, 15, 20, 25, 30];
        var delayItems = [];
        var i = 0;

        _.each(items, function(item) {
            delayItems[i]
                = {
                // If most significant bit is set then the interval unit is based on seconds
                    text: (
                        item >= unitSeconds
                            ? this.sprintf(i18n.t('settings.delay_after_x_s'), (item - unitSeconds).toString())
                            : item === 1
                                ? this.sprintf(i18n.t('settings.delay_after_x_min'), (item).toString())
                                : this.sprintf(i18n.t('settings.delay_after_x_m'), (item).toString())
                    ),
                    value: item
                };

            i++;
        }.bind(this));

        return delayItems;
    },

    hasChanges: function() {
        if (!this.actionView) {
            return {
                hasChanges: false,
                invalid: false
            };
        }

        return {
            hasChanges: this.changes,
            invalid: $(this.actionView.$submitBtn).prop('disabled')
        };
    },

    /**
     * Add parameter to text.
     *
     * @param {string} text
     * @param {array} parameter
     */
    sprintf: function(text, parameter) {
        var args = parameter;

        return text.replace(/%s/g, args);
    }
});
