'use strict';

const $ = require('jquery');
const app = require('../../../app');
const headerTpl = require('./overlay-header-update-notification.hbs');

/**
 * The OverlayHeaderUpdateNotification component manages the update and ignore button if a fw update is available
 */
app.component('OverlayHeaderUpdateNotification', {
    template: headerTpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.updateService = app.getService('UpdateService');
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();
        this.bindDOMEvents();

        this.updateService.startUpdateCheck();
    },

    bindEvents: function() {
        app.once('update.available', this.showUpdateAvailable.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.overlay-action-btn', this.actionBtnHandler.bind(this));
    },

    storeSelectors: function() {
        this.$overlayNotificationHeader = $(document).find('#overlay-header-update-notification-container');
        this.$contentContainer = $(document).find('#overlay-content-container');
    },

    showUpdateAvailable: function() {
        this.$overlayNotificationHeader.addClass('update-available');
        this.$contentContainer.addClass('update-available');
        this.$overlayNotificationHeader
            .stop()
            .slideDown(250);
    },

    hideUpdateAvailable: function() {
        this.$contentContainer.removeClass('update-available');
        this.$overlayNotificationHeader.removeClass('update-available');
        this.$overlayNotificationHeader
            .stop()
            .slideUp(200);
    },

    actionBtnHandler: function(event) {
        var item = event.currentTarget;

        switch (item.getAttribute('data-action')) {
            case 'update':
                this.emit('navigation-bar.open', {
                    id: 'about',
                    autoFoldId: 'general-settings',
                    navArea: '.about-do-update',
                    configs: {
                        fwUpdateChecked: true
                    }
                });

                break;
            case 'ignore':
                this.hideUpdateAvailable();
                break;
        }
    },

    /**
     * Changes focusable state of overlay-action-button
     *
     * @param enable: true => focusable, false => not
     */
    actionBtnSetFocusable: function(enable) {
        if (enable) {
            this.$el.find('#overlay-action-button').addClass('focusable');
        } else {
            this.$el.find('#overlay-action-button').removeClass('focusable');
        }
    },

    destroy: function() {
        this.hideUpdateAvailable();
    }
});
