'use strict';

var instance = null;
var $ = require('jquery');
var _ = require('lodash');
var PasswordField = require('./password-field.js');

/**
 * @class FormManager
 * @constructor
 */
var FormManager = function() {
    /**
     * @type {object}
     */
    this.instances = {};
};

/**
 * Updates FormManager
 */
FormManager.prototype.update = function() {
    var $inputs = $('.input');

    $inputs.each(function(key, input) {
        var $input = $(input);

        this.createInput($input);
    }.bind(this));
};

/**
 * Create a new input from given element
 *
 * @param {jQuery.Element} $input
 */
FormManager.prototype.createInput = function($input) {
    var id = _.uniqueId('input');
    var $el = $input.closest('.input-group');
    var type = $input.attr('type');
    var handler = this.actionHandlers[type];

    if (!$el.hasClass('input-group') || $el.data('input-id')) {
        return;
    }

    if (handler) {
        this.instances[id] = handler($el, $input);
        $el.attr('data-input-id', id);
    }

    $input.on('click', this.onInputFocused.bind(this, $input));
};

/**
 * Focus input field after a click was triggered.
 *
 * @param {jQuery.Element} $input
 */
FormManager.prototype.onInputFocused = function($input) {
    var app = require('./../app/app');
    var remote = app.getService('RemoteService');

    remote.focus($input.get(0));
};

/**
 * Checks all inputs
 */
FormManager.prototype.remove = function() {
    _.forEach(this.instances, function(instance, key) {
        if (!$('[data-input-id="' + key + '"]').length) {
            if (typeof this.instances[key].remove === 'function') {
                this.instances[key].remove();
            }

            delete this.instances[key];
        }
    }.bind(this));
};

/**
 * All actions handler
 */
FormManager.prototype.actionHandlers = {
    'password': function($el, $input) {
        return new PasswordField($el, $input);
    },
    'text': function() {
        return 'text-placeholder';
    }
};

/**
 * @returns {returns}
 */
var getInstance = function() {
    if (null === instance) {
        instance = new FormManager();
    }

    return instance;
};

module.exports = getInstance();
