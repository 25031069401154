'use strict';

var BaseView = require('./view');
var tpl = require('./full-view.hbs');

function FullView(app, configs) {
    BaseView.call(this, app, configs);

    this.template = tpl;
    this.className = configs.className || '';
    this.idName = configs.idName || '';
    this.componentContainer = '#modal-full-view';
}

FullView.prototype = new BaseView();

module.exports = FullView;
