// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"wlan-region\"\n      name=\"wlan-region\"\n      data-nav-area=\".is-focusable:not(:disabled), .focusable:not(:disabled)\"\n      data-nav-trapped\n      data-nav-area-default>\n\n    <div class=\"wlan-region-selection-container\">\n        <div class=\"notification-header\">\n            <span class=\"icon icon-msg-system\"></span>\n            <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":18},"end":{"line":10,"column":39}}}))
    + "</span>\n        </div>\n\n        <div class=\"wlan-region-container-inner\">\n            <div class=\"col-xs-12\">\n                <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wlan_region.message_law_regulation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":19},"end":{"line":15,"column":70}}}))
    + "</p>\n                <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wlan_region.message_service",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":19},"end":{"line":16,"column":63}}}))
    + "</p>\n            </div>\n\n            <div class=\"col-xs-12\">\n                <div id=\"wlan-region-select\"></div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"wlan-region-information-container\">\n        <div class=\"col-xs-12\">\n            <div id=\"wlan-region-info\" class=\"wlan-region-info wlan-region-container-inner\">\n                <span id=\"msg-selection\" class=\"input-uppercase\">\n                    "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wlan_region.message_selection",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":20},"end":{"line":29,"column":66}}}))
    + "\n                </span>\n                <h1 id=\"selected-country\"></h1>\n                <div>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wlan_region.message_countries",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":21},"end":{"line":32,"column":67}}}))
    + "</div>\n                <!-- Region 1 Info -->\n                <div id=\"region1-info-container\">\n                    <div>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wlan_region.message_countries_reg1",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":25},"end":{"line":35,"column":76}}}))
    + "</div>\n                    <div>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wlan_region.message_max_wlan_reg1",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":25},"end":{"line":36,"column":75}}}))
    + "</div>\n                </div>\n                <!-- Region 2 Info -->\n                <div id=\"region2-info-container\">\n                    <div>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wlan_region.message_countries_reg2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":25},"end":{"line":40,"column":76}}}))
    + "</div>\n                    <div>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wlan_region.message_max_wlan_reg2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":25},"end":{"line":41,"column":75}}}))
    + "</div>\n                </div>\n\n                <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wlan_region.message_law_regulation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":22},"end":{"line":44,"column":73}}}))
    + "</span>\n                <div>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wlan_region.message_service",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":21},"end":{"line":45,"column":65}}}))
    + "</div>\n                <div>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wlan_region.message_not_revised",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":21},"end":{"line":46,"column":69}}}))
    + "</div>\n            </div>\n        </div>\n\n        <div id=\"form-action-container\" class=\"form-primary-background\"></div>\n    </div>\n</form>\n";
},"useData":true});
