'use strict';

var app = require('../../../app');
var confirmTpl = require('./info.hbs');

app.component('Info', {
    template: confirmTpl,
    className: 'modal-info',

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    serialize: function() {
        return {
            messageKey: this.options.configs.messageKey,
            additionalMessageKey: this.options.configs.additionalMessageKey
        };
    }
});

