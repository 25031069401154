'use strict';

var app = require('../app');
var platform = require('../../platform/platform');

app.service('EulaService', function() {
    return {

        /**
         * @method initialize
         */
        initialize: function() {
            this.statusChecked = false;
            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;

                    this.bindEvents();
                }.bind(this));
        },

        bindEvents: function() {
            // RELEASE-3096: avoid EULA showing up after setting language to default on factory reset.
            app.on('system-reboot.started', function() {
                this.rebootStarted = true;

                app.emit('modal.close');
            }.bind(this));
        },

        /**
         * Calls the update handler, is mehtod is needed to restart the updateHandler loop.
         */
        callUpdateHandler: function() {
            this.statusChecked = false;
            this.updateHandler();
        },

        /**
         * This loop will be called until the data be loaded.
         *
         * @method updateHandler
         */
        updateHandler: function() {
            this.deviceConnection
                .send('getEulaStatus')
                .then(this.onEulaStatus.bind(this));

            if (this.statusChecked) {
                setTimeout(this.updateHandler.bind(this), 500);
            }
        },

        /**
         * @method onEulaStatus
         * @param data
         */
        onEulaStatus: function(data) {
            if (!data.eulaAccepted) {
                // RELEASE-3096 set timeout to get the rebootStarted first before showing the EULA-modal.
                var timeout = platform.checks.isCbox ? 2000 : 0;
                setTimeout(this.showModal.bind(this), timeout);
            } else if (this.onAccept) {
                this.onAccept();
            }

            this.statusChecked = true;
        },

        /**
         * @method showModal
         */
        showModal: function() {
            if (!platform.checks.isCboxAux && !this.rebootStarted) {
                app.emit('modal.open', {
                    id: 'eula',
                    closeAll: true
                });
            }
        }
    };
});
