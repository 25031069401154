'use strict';

var app = require('../app');

var URI = function() {};

/**
 * Encode URI.
 *
 * @param {string} uri
 *
 * @return {string}
 */
URI.prototype.encode = function(uri) {
    return encodeURI(uri);
};

/**
 * Unescape URI.
 *
 * @param {string} uri
 *
 * @return {string}
 */
URI.prototype.unescape = function(uri) {
    return unescape(uri);
};

/**
 * Decode URI.
 * Return URI in case an error occurs (e.g. URI is already decoded).
 *
 * @param {string} uri
 *
 * @return {string}
 */
URI.prototype.decode = function(uri) {
    try {
        return decodeURI(uri);
    } catch (e) {
        return uri;
    }
};

/**
 * Encode URI.
 *
 * @param {string} uri
 *
 * @return {string}
 */
URI.prototype.encodeFull = function(uri) {
    return encodeURIComponent(uri);
};

/**
 * Encodes URI including escape sequences for special characters - _ . ! ~ * ' ( )
 * @param {string} uri
 * @return {string}
 */
URI.prototype.encodeSpecial = function(uri) {
    return encodeURIComponent(uri).replace(/[-_.!~*'()]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
};

/**
 * Decode URI.
 * Return URI in case an error occurs (e.g. URI is already decoded).
 *
 * @param {string} uri
 * @param {boolean} fallbackEmpty: If true and decoding fails return an empty string
 *
 * @return {string}
 */
URI.prototype.decodeFull = function(uri, fallbackEmpty = false) {
    try {
        return decodeURIComponent(uri);
    } catch (e) {
        return fallbackEmpty ? '' : uri;
    }
};

app.service('URIService', URI);
