'use strict';

const app = require('../app');
const $ = require('jquery');
const _ = require('lodash');
const StateMachine = require('./../state-machine');
const store = require('../store/store');

const onOffStates = require('./../states').onOff;
const mountStates = require('./../states').mountStates;
const sendStates = require('./../states').sendCommandStates;

const TIMEOUT_SETUP = 10000;

const controlStates = {
    none: 0,
    limited: 1,
    control: 2
};

/**
 * Matrix service for general Matrix methods.
 * E.g. load matrix wolfprot commands.
 */
app.service('MatrixService', function() {
    return {
        initialize: function() {
            this.matrixMainService = app.getService('MatrixMainService');
            this.overlayHandlerService = app.getService('OverlayHandlerService');
            this.configs = app.getService('MatrixConfigs');
            this.configs.whenReady().then(function() {
                this.configs.setupMatrixTemplates();
            }.bind(this));
            this.URIService = app.getService('URIService');
            this.statusList = null;
            this.stationStatusList = null;
            this.setupFinished = true;
            this.coaching = null;
            this.groupworkStatus = {
                enabled: false,
                active: false
            };

            this.masterState = new StateMachine({
                context: this,
                state: onOffStates.off,
                states: onOffStates
            });

            this.shareWithAllState = new StateMachine({
                context: this,
                state: sendStates.finished,
                states: sendStates
            });

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                }.bind(this));

            this.bindEvents();
        },

        bindEvents: function() {
            app.on('main-loop.update.matrix', this.updateHandler.bind(this));
            app.on('main-loop.update', this.updateHandler.bind(this));
        },

        /**
         * Discover Devices.
         */
        getDevices: function() {
            const dfd = $.Deferred();

            this.deviceConnection
                .send('getMatrixDiscoverdDevices')
                .then(function(devices) {
                    this.devices = devices.deviceList;

                    dfd.resolve({
                        devices: this.devices
                    });
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Share file with station.
         *
         * @param serial Station serial number
         * @param filePath File path of shared file
         */
        shareFile: function(serial, filePath) {
            const dfd = $.Deferred();

            this.shareWithAllState.changeState(sendStates.waiting);

            this.deviceConnection
                .send('setMatrixMasterFileShareAdd', {
                    serial: serial,
                    filePath: encodeURIComponent(filePath)
                }).then(function() {
                    setTimeout(function() {
                        this.shareWithAllState.changeState(sendStates.finished);
                    }.bind(this), 100);
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Unshare file with station.
         *
         * @param serial Station serial number
         * @param fileName Name of shared file
         */
        unshareFile: function(serial, fileName) {
            const dfd = $.Deferred();

            this.deviceConnection
                .send('setMatrixMasterFileShareRemove', {
                    serial: serial,
                    fileName: encodeURIComponent(fileName)
                })
                .then(function() {
                    dfd.resolve();
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Get file list for file sharing.
         *
         * @param path Directory path
         * @param mountType Mount type
         */
        getFiles: function(path) {
            const dfd = $.Deferred();

            if (path) {
                this.deviceConnection
                    .send('getFileList', {
                        pathname: path
                    })
                    .then(function(data) {
                        const fileList = data.fileList;

                        fileList.forEach(function(file) {
                            file.path = this.createPath(path, file);
                        }.bind(this));

                        dfd.resolve({
                            fileList: fileList
                        });
                    }.bind(this));
            } else {
                this.deviceConnection
                    .send('getMounts')
                    .then(function(data) {
                        const mountList = [];
                        const cloudList = [];
                        let mountData;

                        data.mountList.forEach(function(mount) {
                            if (mount.status
                                && mount.status !== mountStates.disabled
                                && mount.status !== mountStates.notMounted
                                && !!mount.perms
                                && mount.perms !== 'wo'
                            ) {
                                mountData = {
                                    name: mount.name,
                                    id: mount.id,
                                    path: mount.id + '://',
                                    type: 'dir',
                                    mountType: mount.type
                                };

                                if (mount.type !== 'matrix_master' && mount.type !== 'system') {
                                    if (mount.type === 'cloud') {
                                        cloudList.push(mountData);
                                    } else {
                                        mountList.push(mountData);
                                    }
                                }

                                if (mount.id === 'webdav' || mount.type === 'netdrive') {
                                    app.emit('matrix-box.add.title', mount.id, mount.name);
                                }
                            }
                        }.bind(this));

                        const fileList = [
                            ...mountList,
                            ...cloudList
                        ];

                        dfd.resolve({
                            fileList: fileList
                        });
                    }.bind(this));
            }

            return dfd.promise();
        },

        /**
         * Create file path.
         * @param dir current directory
         * @param file current file
         * @returns {string} file path
         */
        createPath: function(dir, file) {
            const name = file.recentfilepath || file.name;
            let path = dir + name;

            if (file.type === 'dir') {
                path = path + '/';
            }

            return path;
        },

        /**
         * Get list of configured stations.
         *
         * @param index
         * @returns {*}
         */
        getStationSetup: function(index) {
            const dfd = $.Deferred();

            this.deviceConnection
                .send('getMatrixStationSetup', {
                    index: index
                })
                .then(function(data) {
                    dfd.resolve({
                        stationList: data.stationList,
                        audioList: data.audioList
                    });
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Save matrix station setup.
         *
         * @param serials {array} list of stations
         */
        saveStationSetup: function(serials) {
            const dfd = $.Deferred();

            // RELEASE-2605: Sort serials for backend (That the MAC-addresses are saved correctly)
            serials.sort(function(station1, station2) {
                const s1 = parseFloat(station1.serial);
                const s2 = parseFloat(station2.serial);

                return s1 - s2;
            });

            const stationList = [];
            const audioList = [];
            const limitedAccessList = [];

            for (let i = 0; i < this.configs.get('stations.max'); i++) {
                if (serials[i]) {
                    stationList.push(serials[i].serial);
                    audioList.push(serials[i].audio);
                    limitedAccessList.push(serials[i].limitedAccess);
                } else {
                    stationList.push('0');
                    audioList.push('0');
                    limitedAccessList.push('0');
                }
            }

            this.deviceConnection
                .send([
                    {
                        command: 'setMatrixStationSetup',
                        data: {
                            stationList: stationList,
                            audioList: audioList,
                            limitedAccessList: limitedAccessList,
                            templateId: this.configs.matrixTemplateId
                        }
                    },
                    {
                        command: 'setMasterActiveTemplate',
                        data: {
                            templateId: this.configs.matrixTemplateId
                        }
                    }
                ]).done(function() {
                    this.setupFinished = false;

                    setTimeout(function() {
                        this.setupFinished = true;
                    }.bind(this), TIMEOUT_SETUP);

                    dfd.resolve();
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Return if audio is enabled on station.
         *
         * @param serial (station serial)
         * @returns {*}
         */
        isAudioEnabled: function(serial) {
            const station = this.getStationStatus(serial);

            if (station) {
                return station.audio;
            } else {
                return false;
            }
        },

        /**
         * Return station status.
         *
         * @param serial (station serial)
         * @returns {*}
         */
        getStationStatus: function(serial) {
            const station = $.grep(this.stationStatusList, function(e) {
                return e.serial === serial;
            });

            return station[0];
        },

        /**
         * Pull from station.
         *
         * @param serial {int} serial number
         * @param mode {int} 0...stop / 1...pull
         * @param output {string} hdmi1 / hdmi2
         * @param replaceAppId {string} 0 if no replace is needed
         */
        setMatrixMasterPull: function(serial, mode, output, replaceAppId) {
            if (typeof output === 'undefined') {
                this.deviceConnection
                    .send('setMatrixMasterPull', {
                        serial,
                        mode
                    });
            } else {
                this.deviceConnection
                    .send('setMatrixMasterPullToOutput', {
                        serial,
                        mode,
                        output,
                        replaceAppId
                    });
            }
        },

        updateHandler: function() {
            this.updateMatrixMasterState().then(function() {
                if (this.masterState.getState() === onOffStates.true) {
                    if (this.overlayHandlerService.getOpenOverlayId() === 'matrix-main') { // Master update
                        $.when(this.getMatrixMasterStatus(), this.getFiles(this.configs.get('filesharing.path')), this.getGroupworkStatus())
                            .done(function(status, files, groupwork) {
                                app.emit('matrix-status.update', status.statusList, files.fileList, status.coaching, groupwork.enabled, groupwork.active);
                            }.bind(this));
                    } else if (this.overlayHandlerService.getOpenOverlayId() === 'matrix-configurator') {
                        this.getMatrixMasterStatus();
                        app.emit('matrix.save.enable', this.setupFinished);
                    } else if (app.getService('DeviceService').isCboxProDualProjection()) {
                        this.updateMatrixCommandButtonStates();
                    }
                }
            }.bind(this));
        },

        /**
         * Returns the matrix master status.
         */
        getMatrixMasterStatus: function() {
            const dfd = $.Deferred();

            this.deviceConnection
                .send('getMatrixStatus')
                .then(function(data) {
                    this.statusList = data.statusList;
                    this.coaching = data.coaching;

                    dfd.resolve({
                        statusList: data.statusList,
                        coaching: data.coaching
                    });
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Updates the Matrix mode state.
         */
        updateMatrixMasterState: function() {
            return this.deviceConnection
                .send('getMatrixMasterMode')
                .then(function(masterMode) {
                    if (this.masterState.getState() !== onOffStates[masterMode.enabled]) {
                        this.masterState.changeState(onOffStates[masterMode.enabled]);

                        if (masterMode.enabled) {
                            this.deviceConnection
                                .send('setStreamingFunction', {
                                    function: true
                                });
                        } else {
                            this.deviceConnection
                                .send('setMatrixMasterAnnotationEnabled', {
                                    function: false
                                });
                        }

                        app.emit('matrix.master-state.changed');
                    }

                    store.dispatch('matrix/setMatrixEnabled', masterMode.enabled);
                }.bind(this));
        },

        /**
         * Is Matrix enabled?
         *
         * @return {boolean}
         */
        isEnabled: function() {
            return this.masterState.getState() === onOffStates['true'];
        },

        /**
         * Update states for matrix command buttons
         */
        updateMatrixCommandButtonStates: function() {
            this.getMatrixActiveTemplate().then(function(templateId) {
                store.dispatch('matrix/setActiveTemplateId', templateId);

                if (templateId === -1) {
                    return;
                }

                if (this.configs.getCurrentMatrixTemplateId() !== templateId) {
                    this.configs.setCurrentMatrixTemplate(templateId);
                }

                $.when(this.getMatrixMasterStatus(), this.configs.getMatrixCommandButtonsSettings(), this.getGroupworkActive())
                    .done(function(matrixStatus, settings, groupwork) {
                        store.dispatch('matrix/setMatrixStatus', matrixStatus.statusList);
                        store.dispatch('matrix/setCommandButtonSettings', settings);
                        store.dispatch('matrix/setGroupworkActive', groupwork.active);

                        if (!settings.enabled) {
                            return;
                        }

                        $.when(this.configs.loadMatrixTemplates(), this.configs.parseStations())
                            .done(function() {
                                store.dispatch('matrix/setTemplateSettings',
                                    {
                                        groups: this.configs.getCurrentMatrixTemplateGroups(true),
                                        stations: this.configs.getCurrentMatrixTemplateStations(),
                                        shareToAllEnabled: this.configs.getShareToAllEnabled()
                                    });
                            }.bind(this));
                    }.bind(this));
            }.bind(this));
        },

        /**
         * Push to station.
         *
         * @param serial {int} serial number (0...push to all)
         * @param mode {int} 0...stop / 1...push
         * @pushIndex: 0...3 (stream)
         *             255 master
         */
        setMatrixMasterPush: function(serial, mode, pushIndex = 'hdmi1') {
            this.deviceConnection
                .send('setMatrixMasterPush', {
                    serial,
                    mode,
                    pushIndex
                });
        },

        /**
         * Push from station to station (coaching).
         *
         * @param serialSrc {int} serial number
         * @param mode {int} 0...stop / 1...push
         * @param serialDest {int} serial number
         */
        setMatrixStationToStationPush: function(serialSrc, serialDest, mode) {
            this.deviceConnection
                .send('setMatrixStationToStationPush', {
                    serialSrc: serialSrc,
                    mode: mode,
                    serialDest: serialDest
                });
        },

        /**
         *
         * Calculate rotated position.
         *
         * @param cx Rotation center x
         * @param cy Rotation center y
         * @param x Current position x
         * @param y Current position y
         * @param angle Rotation angle (radians)
         * @returns {[ new x, new y]}
         */
        calculateRotatedPosition: function(cx, cy, x, y, angle) {
            if (angle === 0) {
                return [x, y];
            }

            angle *= -1;
            const nx = (Math.cos(angle) * (x - cx)) + (Math.sin(angle) * (y - cy)) + cx;
            const ny = (Math.cos(angle) * (y - cy)) - (Math.sin(angle) * (x - cx)) + cy;

            return [nx, ny];
        },

        /**
         * Convert coordinates into another coordinate system.
         * E.g. normal coordinates to svg viewport coordinates.
         *
         * @param {{x?: number, y?: number, width?: number, height?: number}} coords from origin coordinate system
         * @param sourceWidth Width from origin coordinate system
         * @param sourceHeight Height from origin coordinate system
         * @param destWidth Width from coordinate system to convert to
         * @param destHeight Height from coordinate system to convert to
         *
         * @returns {{x?: number, y?: number, width?: number, height?: number}} Converted dimensions
         */
        convertCoordinates: function(coords, destWidth, destHeight, sourceWidth, sourceHeight) {
            const factorX = destWidth / sourceWidth;
            const factorY = destHeight / sourceHeight;

            const calcCoords = {};
            for (const key in coords) {
                if (Object.hasOwnProperty.call(coords, key)) {
                    calcCoords[key] = coords[key] * (['width', 'x'].includes(key) ? factorX : factorY);
                }
            }

            return calcCoords;
        },

        /**
         * Get preview picture.
         *
         * @param serial Station serial number
         * @param size Preview size (width/height)
         */
        getMatrixMasterPreviewPicture: function(serial, size) {
            const dfd = $.Deferred();
            const fps = 5;

            this.$canvas = $(document).find('.preview');

            if (serial.startsWith('master')) {
                this.deviceConnection
                    .send('getPictureCbox', {
                        pictureWidth: size.width,
                        pictureHeight: size.height
                    }).then(function(data) {
                        dfd.resolve({
                            imageData: data
                        });
                    }.bind(this));
            } else {
                this.deviceConnection
                    .send('getMatrixMasterPreviewPicture', {
                        serial: serial,
                        width: size.width,
                        height: size.height,
                        fps: fps
                    }).then(function(data) {
                        dfd.resolve({
                            imageData: data
                        });
                    }.bind(this));
            }

            return dfd.promise();
        },

        /**
         * Check if station is present.
         *
         * @param serial Station serial number
         */
        isStationPresent: function(serial, ignoreCompatibility) {
            let stationPresent = true;

            if (serial !== 'master') {
                _.each(this.statusList, function(status) {
                    if (status.serial === parseInt(serial)) {
                        stationPresent = status.present === 1;

                        /**
                         * NOTE: Used by Matrix checkStationStatus (don't save if they are REALLY not present)
                         * Compatibility and if they are in use by another master is not important here.
                         *
                         */
                        if (ignoreCompatibility && !status.present) {
                            stationPresent = status.compatibility !== 0 || status.masterName ? true : status.present;
                        }
                    }
                }.bind(this));

                return stationPresent;
            }

            return true;
        },

        /**
         * Get station status.
         *
         */
        getMatrixStationStatus: function() {
            const dfd = $.Deferred();

            this.deviceConnection
                .send('getMatrixStationStatus').then(function(data) {
                    dfd.resolve({
                        stationStatus: data
                    });
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Power on all stations.
         */
        powerOnStations: function() {
            this.deviceConnection
                .send('setMatrixPowerStation', {
                    action: true
                });
        },

        /**
         * Power off all stations.
         */
        powerOffStations: function() {
            this.deviceConnection
                .send('setMatrixPowerStation', {
                    action: false
                });
        },

        /**
         * Get degrees if station gets rotated.
         *
         * @param {number} rad Radius
         * @returns {number} Rotation degrees
         */
        toDegrees: function(rad, round = false) {
            rad = Number(rad) % (2 * Math.PI);
            if (rad < -Math.PI) {
                rad = rad % Math.PI + Math.PI;
            } else if (rad > Math.PI) {
                rad = rad % Math.PI - Math.PI;
            }

            const degree = rad * (180 / Math.PI);
            if (round) {
                return this.roundTo15Degree(degree);
            } else {
                return degree ;
            }
        },

        /**
         * Get rad if station gets rotated.
         *
         * @param {number} degree Degree
         * @returns {number} Rotation degrees
         */
        toRad: function(degree) {
            return degree * (Math.PI / 180);
        },

        /**
         * Round degree to 15 degree interval
         *
         * @param {number} degree Degree
         * @returns {number} Rotation degrees
         */
        roundTo15Degree: function(degree) {
            return Math.round(degree / 15) * 15;
        },

        /**
         * Set active template.
         *
         * @param id {int}
         */
        setMatrixActiveTemplate: function(id) {
            if (id !== -1) {
                this.deviceConnection
                    .send('setMasterActiveTemplate', {
                        templateId: id,
                        templateName: this.configs.getCurrentMatrixTemplateName()
                    });
            }
        },

        /**
         * Get active template.
         *
         * @param id {int}
         */
        getMatrixActiveTemplate: function() {
            const dfd = $.Deferred();

            this.deviceConnection
                .send('getMasterActiveTemplate')
                .then(function(template) {
                    dfd.resolve(template.templateId);
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Get defined input streams.
         *
         * @returns {array} input streams
         */
        getInputStreams: function() {
            const dfd = $.Deferred();

            this.deviceConnection
                .send([
                    { command: 'getStreamInput' }
                ])
                .then(function(streamInputs) {
                    const inputs = streamInputs.list;

                    dfd.resolve(inputs);
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Get current input stream type icon.
         *
         * @param type
         * @returns {string}
         */
        getStreamIcon: function(type) {
            let icon;

            switch (type) {
                case 'stream':
                    icon = 'icon-v2-stream-large';
                    break;
                case 'camera':
                    icon = 'icon-v2-webconference';
                    break;
                case 'computer':
                    icon = 'icon-v2-laptop computer';
                    break;
                case 'visualizer':
                    icon = 'icon-v2-visualizer';
                    break;
                case 'hdmi':
                    icon = 'icon-v2-hdmi';
                    break;
                case 'discPlayer':
                    icon = 'icon-v2-disc-player';
                    break;
                default:
                    icon = 'icon-device';
                    break;
            }

            return icon;
        },

        /**
         *
         * Check if point is within circle/ellipse.
         *
         * @param x Current position x
         * @param y Current position y
         * @param cx Circle center point x
         * @param cy Circle center point y
         * @param rx Circle radius x
         * @param ry Circle radius y
         * @returns {boolean} True - point in circle/ellipse, False - point NOT in circle/ellipse
         */
        pointInCircle: function(posX, posY, cx, cy, rx, ry, angle) {
            posX = parseFloat(posX);
            posY = parseFloat(posY);
            cx = parseFloat(cx);
            cy = parseFloat(cy);
            rx = parseFloat(rx);
            ry = parseFloat(ry);
            angle = parseFloat(angle);
            let rotatedPos = [posX, posY];

            if (angle && angle !== 0) {
                rotatedPos = this.calculateRotatedPosition(cx, cy, posX, posY, angle * -1);
            }

            posX = rotatedPos[0];
            posY = rotatedPos[1];

            return ((((posX - cx) * (posX - cx)) / (rx * rx))
                + (((posY - cy) * (posY - cy)) / (ry * ry))) <= 1;
        },

        /**
         * Control station through master by lock the station and start controlling.
         *
         * @param serial {int} serial number
         * @param mode {int} 0...stop controlling / 1...start controlling
         */
        controlStation: function(serial, mode) {
            if (mode === 1) {
                const stations = $.grep(this.statusList, function(e) {
                    return e.serial !== parseInt(serial);
                });

                if (stations.length > 0) {
                    _.each(stations, function(station) {
                        if (station.accessLevel === controlStates.control) {
                            this.controlStation(station.serial, 0);
                        }
                    }.bind(this));
                }
            }

            this.deviceConnection
                .send([
                    {
                        command: 'setMatrixMasterLock',
                        data: {
                            serial: serial,
                            mode: mode
                        }
                    },
                    {
                        command: 'setMatrixMasterControl',
                        data: {
                            serial: serial,
                            mode: mode
                        }
                    }
                ]).then(function() {
                    app.emit('main-loop.fast.start', {
                        id: 'matrix'
                    });
                }.bind(this));
        },

        /**
         * Get Groupwork status.
         * Is Groupwork enabled and currently active.
         */
        getGroupworkStatus: function() {
            const dfd = $.Deferred();

            this.deviceConnection
                .send([
                    'getMatrixGroupworkMode',
                    'getMatrixGroupworkForced'
                ])
                .then(function(mode, forced) {
                    this.groupworkStatus = {
                        enabled: mode.enabled,
                        active: forced.active
                    };

                    dfd.resolve(this.groupworkStatus);
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Get Groupwork active status
         */
        getGroupworkActive: function() {
            const dfd = $.Deferred();

            this.deviceConnection
                .send('getMatrixGroupworkForced')
                .then(function(active) {
                    dfd.resolve(active);
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Active groupwork mode (on-the-fly)
         * @param enable true/false
         */
        setGroupworkActiveMode: function(activate) {
            if (activate === this.groupworkStatus.active) {
                return;
            }

            this.matrixMainService.openGroupworkTeachingModal(activate);

            this.deviceConnection
                .send('setMatrixGroupworkForced', {
                    active: activate
                });
        }
    };
});
