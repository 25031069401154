'use strict';

var $ = window.$ = require('jquery');
var _ = require('lodash');
var Vue = require('vue');
var store = require('../app/store/store');

var StateMachine = require('./state-machine');
var ConnectionHelper = require('./connection-helper');
var formManager = require('./../form/form-manager.js');

/**
 * Handler method for attaching listeners and storing
 * the component event.
 *
 * @return {Function}
 */
function attachListener(listenerType) {
    return function(name, listener) {
        this.eventStore = this.eventStore || [];
        this.eventStore.push({
            name: name,
            listener: listener,
            id: this.id
        });
        this.app[listenerType](name, listener);
    };
}

/**
 * @class Component
 * @constructor
 */
function Component(options, app) {
    /**
     * @type {Object}
     */
    this.options = options || {};

    /**
     * @type {String}
     */
    this.id = '__' + options.type + '@Component' + _.uniqueId() + '__';

    /**
     * @type {String}
     */
    this.componentId = options.componentId;

    /**
     * Reference to the app instance.
     * Will be set later inside the app.
     *
     * @type {Object}
     */
    this.app = app;

    /**
     * Bind getService-method..
     */
    this.getService = app.getService.bind(app);

    /**
     * JQuery Reference.
     *
     * @type {Object}
     */
    this.$ = this.app.$;

    /**
     * JQuery Reference to the component element.
     *
     * @type {Object}
     */
    this.$el = $('<div/>', {
        'class': this.className || null,
        'data-component-instance': this.id,
        'id': this.idName || null
    });

    /**
     * Reference to component DOM element.
     *
     * @type {Object}
     */
    this.el = this.$el.get(0);

    /**
     * Component events store.
     *
     * @type {Array}
     * @private
     */
    this.eventStore = [];

    /**
     * Default role is used, when getAccessKey is not defined in the component.
     *
     * @type {object}
     */
    this.defaultRole = {
        'roleName': options.type,
        'roleKey': 'show'
    };

    this.componentStates = {
        'running': 'running',
        'initalized': 'initailized',
        'destroyed': 'destroyed'
    };

    this.componentState = new StateMachine({
        context: this,
        state: this.componentStates.running,
        states: this.componentStates
    });

    /**
     * Creates the Device Connections Helper.
     */
    this.deviceConnection = new ConnectionHelper(this.app, this);

    /**
     * Call the component initialize method.
     */
    this.initialized = this.initialize.apply(this, arguments);

    /**
     * Saves all child component ids.
     *
     * @type {Array}
     */
    this.childComponentIds = [];

    /**
     * @TYPE {RBAC}
     */
    this.rbac = require('./../rbac/rbac.js');

    /**
     * @type {boolean}
     */
    this.dataLoaded = false;

    /**
     *
     * @type {Number}
     */
    this.showLoaderTimeout = 0;
}

/**
 * @type {String}
 */
Component.prototype.template = null;

Component.prototype.engine = 'handlebars';

/**
 * @type {String}
 */
Component.prototype.className = '';

/**
 * Dummy initialize method.
 */
Component.prototype.initialize = $.noop;

/**
 * Dummy check for changes method .
 */
Component.prototype.hasChanges = function() {
    return {
        hasChanges: false,
        invalid: false
    };
};

/**
 * Dummy destroy method.
 */
Component.prototype.destroy = $.noop;

/**
 * @type {EventEmitter2.on}
 */
Component.prototype.on = attachListener('on');

/**
 * @type {EventEmitter2.once}
 */
Component.prototype.once = attachListener('once');

/**
 * Check if user has access.
 */
Component.prototype.checkComponentAccess = function checkAccess() {
    var access = this.getAccessKey();

    return this.rbac.hasAccess(access.roleName, access.roleKey);
};

/**
 * Creates a new child Component and returns the componentId.
 *
 * @param {object} options
 *
 * @returns {string}
 */
Component.prototype.createComponent = function(options) {
    var componentId = this.app.createComponent(_.uniqueId(options.type + '_childComponentFrom_' + this.id), options);

    this.childComponentIds.push(componentId);

    return componentId;
};

/**
 * Removes all child Components.
 */
Component.prototype.removeAllChildComponents = function() {
    if (this.childComponentIds.length > 0) {
        _.each(this.childComponentIds, function(id) {
            this.app.destroyComponent(id);
        }.bind(this));
    }
};

/**
 * Removes component events from the event bus.
 */
Component.prototype.off = function off(name) {
    if (!this.eventStore.length) {
        return;
    }

    this.eventStore = _.reject(this.eventStore, function(event) {
        var ret = (event.name === name);
        if (ret) {
            this.app.off(event.name, event.listener);
        }

        return ret;
    }.bind(this));
};

/**
 * @type {EventEmitter2.emit}
 */
Component.prototype.emit = function emit() {
    this.app.emit.apply(this.app.events, arguments);
};

/**
 * Removes all component events from the event bus.
 */
Component.prototype.removeAllEvents = function removeAllEvents() {
    this.eventStore = _.reject(this.eventStore, function(event) {
        this.app.off(event.name, event.listener);

        return true;
    }.bind(this));
};

/**
 * Renders the template inside the component element.
 *
 * @chainable
 */
Component.prototype.render = function render() {
    var dfd = $.Deferred();
    var data = this.serialize();
    var tpl = this.template;

    var onDataLoaded = function(data, ignoreLoaderHide) {
        this.dataLoaded = true;

        if (this.engine === 'handlebars') {
            if (this.template) {
                if (typeof this.template === 'function') {
                    tpl = this.template(data);
                }

                this.$el.html(tpl);
            }
        } else {
            this.$el.html(this.template);

            // Set viewModel to empty object here so it will be available (altough not used)
            this.$viewModel = {};

            requestAnimationFrame(function() {
                var el = this.$el.get(0);
                var componentData = this.data;
                if (typeof this.data === 'function') {
                    componentData = this.data();
                }
                var viewModel = new Vue({
                    el: el,
                    store: store,
                    data: Object.assign({}, componentData, {
                        deviceConnection: this.deviceConnection,
                        component: this
                    }),
                    methods: this.methods,
                    computed: this.computed,
                    components: this.components,
                    watch: this.watch,
                    beforeCreate: this.beforeCreate,
                    created: this.created,
                    beforeMount: this.beforeMount,
                    mounted: this.mounted,
                    beforeUpdate: this.beforeUpdate,
                    updated: this.updated,
                    beforeDestroy: this.beforeDestroy,
                    destroyed: this.destroyed
                });

                // Copy serialized data to viewModel
                Object.keys(data || {}).forEach(function(key) {
                    viewModel[key] = data[key];
                });
                this.$viewModel = viewModel;
            }.bind(this));
        }

        if (!ignoreLoaderHide) {
            this.stopLoader();
        }

        this.postRender();
        dfd.resolve();
    };

    if (data && data.done) {
        this.startLoader();

        data.done(onDataLoaded.bind(this));
    } else {
        onDataLoaded.call(this, data, true);
    }

    return dfd.promise();
};

/**
 * Called after render.
 */
Component.prototype.postRender = $.noop;

/**
 * Once the component has been rendered, it still needs to be placed in the
 * document. The `placeAt` method allows you to specify a destination for
 * the component.
 *
 * Note: If Vue component uses formManager, the formManager needs to be updated when the component is mounted
 * (e.g. see generic-login.js)
 *
 * @param {String} node The component destination
 * @param {String} position The type of placing
 *
 * @returns {Object}
 */
Component.prototype.placeAt = function placeAt(node, position) {
    var method;
    position = position || 'last';

    method = {
        'first': 'prepend',
        'last': 'append',
        'only': 'html'
    }[position] || 'only';

    if (!!this.initialized && !!this.initialized.done) {
        this.startLoader();

        this.initialized.done(function() {
            if (this.componentState.getState() !== this.componentStates.destroyed) {
                this.app.$(node)[method](this.$el);
                this.postPlaceAt();
                this.componentState.changeState(this.componentStates.initalized);
                this.$el.data('component-id', this.options.componentId);
                if (this.engine !== 'vue') {
                    formManager.update();
                }

                this.stopLoader();
            }
        }.bind(this));
    } else if (this.componentState.getState() !== this.componentStates.destroyed) {
        this.app.$(node)[method](this.$el);
        this.postPlaceAt();
        this.componentState.changeState(this.componentStates.initalized);
        if (this.engine !== 'vue') {
            formManager.update();
        }
    }

    return this;
};

/**
 * @returns {*}
 */
Component.prototype.isAlive = function() {
    return (this.componentState.getState() !== this.componentStates.destroyed);
};

/**
 * Called after render.
 */
Component.prototype.postPlaceAt = $.noop;

/**
 * The `serialize` method is responsible for taking the view's data and
 * preparing it to be used by the view's template.
 */
Component.prototype.serialize = $.noop;

/**
 * Returns a new instance of the statemachine.
 *
 * @param {Object} options
 */
Component.prototype.createStateMachine = function createStateMachine(options) {
    options.context = this;

    return new StateMachine(options);
};

/**
 * Calls on destroy component.
 */
Component.prototype.preDestroy = function() {
    this.componentState.changeState(this.componentStates.destroyed);
    formManager.remove();
};

/**
 * @returns {{role: String, key: string}}
 */
Component.prototype.getAccessKey = function() {
    return this.defaultRole;
};

Component.prototype.startLoader = function() {
    this.showLoaderTimeout = setTimeout(function() {
        this.emit('component.loader.change', {
            state: 'show'
        });
    }.bind(this), 300);
};

Component.prototype.stopLoader = function() {
    clearTimeout(this.showLoaderTimeout);
    this.emit('component.loader.change', {
        state: 'hide'
    });
};

module.exports = Component;
