'use strict';

const app = require('../app');
const _ = require('lodash');
const StateMachine = require('./../state-machine');
const raucSourceStates = require('../states').raucSourceStates;
const raucUpdateStates = require('../states').raucUpdateStates;
const updateProgressStates = require('../states').updateProgressStates;

/**
 * The legacy update states.
 */
const updateStates = {
    none: 'none',
    idle: 'idle',
    failed: 'failed',
    noconnect: 'noconnect',
    checking: 'checking',
    uptodate: 'uptodate',
    outofdate: 'outofdate',
    downloading: 'downloading',
    updating: 'updating'
};

app.service('UpdateService', function(app) {
    return {
        handlers: {},
        initialize: function() {
            this._updateHandler = this.updateHandler.bind(this);

            this.updateStateLegacy = new StateMachine({
                context: this,
                state: updateStates.none,
                states: updateStates
            });

            this.updateCheckStateRauc = new StateMachine({
                context: this,
                state: raucSourceStates.none,
                states: raucSourceStates
            });

            this.updateStateRauc = new StateMachine({
                context: this,
                state: raucUpdateStates.none,
                states: raucUpdateStates
            });

            this.addStateTransitions();

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;

                    this.updateHandler();
                    this.bindEvents();
                }.bind(this));
        },

        bindEvents: function() {
            app.on('main-loop.update', this._updateHandler);
            app.on('main-loop.update.update', this._updateHandler);
        },

        unbindEvents: function() {
            app.off('main-loop.update', this._updateHandler);
            app.off('main-loop.update.update', this._updateHandler);
        },

        updateHandler: function() {
            this.deviceConnection
                .send([
                    'getWwwUpdateStatus',
                    {
                        command: 'getRaucUpdateStatus',
                        data: {
                            source: 'web'
                        }
                    }
                ])
                .then(this.onUpdateData.bind(this));
        },

        onUpdateData: function(fwUpdateLegacy, fwUpdateRauc) {
            const state = updateStates[fwUpdateLegacy.status];

            this.fwUpdateLegacy = fwUpdateLegacy;
            this.fwUpdateRauc = fwUpdateRauc;

            if (this.updateStateLegacy.getState() !== state) {
                this.updateStateLegacy.changeState(state);
            }

            if (this.updateCheckStateRauc.getState() !== fwUpdateRauc.updateCheckStatus) {
                this.updateCheckStateRauc.changeState(fwUpdateRauc.updateCheckStatus);
            }

            if (this.updateStateRauc.getState() !== fwUpdateRauc.updateStatus) {
                this.updateStateRauc.changeState(fwUpdateRauc.updateStatus);
            }

            // Report update progress to registered handlers
            if (this.updateStateRauc.getState() === raucUpdateStates.downloading
                || this.updateStateRauc.getState() === raucUpdateStates.updating
                || this.updateStateRauc.getState() === raucUpdateStates.failed
            ) {
                this.callHandler({
                    status: updateProgressStates[fwUpdateRauc.updateStatus],
                    progress: fwUpdateRauc.progress
                });
            } else if (this.updateStateLegacy.getState() === updateStates.downloading
                || this.updateStateLegacy.getState() === updateStates.updating
                || this.updateStateLegacy.getState() === updateStates.failed
            ) {
                this.callHandler({
                    status: updateProgressStates[fwUpdateLegacy.status],
                    progress: fwUpdateLegacy.progress
                });
            }
        },

        /**
         * During the transition phase of firmware updates from legacy (wgz) to RAUC both modes must be supported.
         * However, if a RAUC firmware update is available, it takes precedence over legacy mode.
         */
        addStateTransitions: function() {
            this.updateStateLegacy.addTransitions({
                '> outofdate': function() {
                    if (this.updateCheckStateRauc.getState() === raucSourceStates.notFound) {
                        app.emit('update.available', {
                            version: this.fwUpdateLegacy.version
                        });
                    }
                }
            });

            this.updateCheckStateRauc.addTransitions({
                '> notFound': function() {
                    if (this.updateStateLegacy.getState() === updateStates.outofdate) {
                        app.emit('update.available', {
                            version: this.fwUpdateLegacy.version
                        });
                    }
                },
                '> found': function() {
                    app.emit('update.available', {
                        version: this.fwUpdateRauc.version
                    });
                }
            });
        },

        callHandler: function(data) {
            _.forEach(this.handlers, function(handler) {
                handler(data);
            }.bind(this));
        },

        addUpdaterHandler: function(handler) {
            var id = _.uniqueId();
            this.handlers[id] = handler;

            return function() {
                if (this.handlers[id]) {
                    delete this.handlers[id];
                }
            }.bind(this);
        },

        /**
         * Get name of newly available firmware version
         */
        getNewFirmwareVersion: function() {
            if (this.updateCheckStateRauc.getState() === raucSourceStates.found) {
                return this.fwUpdateRauc.version;
            }

            if (this.updateStateLegacy.getState() === updateStates.outofdate) {
                return this.fwUpdateLegacy.version;
            }

            return undefined;
        },

        /**
         * Trigger check if firmware update is available via web.
         *
         * Pauses polling of update states for a moment, triggers the update check,
         * resets the update state machines and then starts polling again.
         */
        startUpdateCheck: function() {
            this.unbindEvents();
            setTimeout(function() {
                this.bindEvents();
            }.bind(this), 1000);

            this.deviceConnection
                .send('setWwwUpdate', {
                    action: 'check'
                })
                .then(function() {
                    this.updateCheckStateRauc.changeState(raucSourceStates.none);
                    this.updateStateLegacy.changeState(updateStates.none);
                }.bind(this));
        },

        /**
         * Start firmware update via web.
         */
        startUpdate: function() {
            this.deviceConnection
                .send('setWwwUpdate', {
                    action: 'start'
                });
        },

        /**
         * Abort firmware update via web.
         */
        abortUpdate: function() {
            this.deviceConnection
                .send('setWwwUpdate', {
                    action: 'cancel'
                });
        }
    };
});
