// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row settings-list-secundary\" data-nav-area=\".focusable:not(:disabled)\" data-nav-trapped>\n                    <div class=\"upload-wallpaper-container\">\n                        <div class=\"col-xs-6\">\n                            <div class=\"input-group\">\n                                <label class=\"input-label\" for=\"wallpaper-file\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.upload_wallpaper",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":200,"column":80},"end":{"line":200,"column":113}}}))
    + "</label>\n                                <input type=\"file\" class=\"input focusable\" name=\"wallpaper-file\" id=\"wallpaper-file\"\n                                       tabIndex=\"-1\" accept=\".jpg, .jpeg\" data-validation=\"checkImage\"/>\n                                <div class=\"input-info\">\n                                <span class=\"input-info-message success-message\">\n                                    "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.image_error_size",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":205,"column":36},"end":{"line":205,"column":70}}}))
    + "\n                                </span>\n                                    <span class=\"input-info-message error-message\">\n                                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.image_error_size",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":208,"column":40},"end":{"line":208,"column":73}}}))
    + "\n                                    </span>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"vmeeting-settings\" name=\"vmeeting-settings\">\n        <div class=\"row settings-list-row\"\n             data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n             data-nav-trapped\n             data-nav-area-default>\n            <div class=\"col-xs-8\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.vmeeting_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":52}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input id=\"vMeetingEnable\" name=\"vMeetingEnable\" type=\"checkbox\" class=\"btn-switch\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"vmeeting-settings-container\" class=\"vmeeting-settings-container\">\n            <div id=\"connection-settings-container\">\n                <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.connection_state",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":24},"end":{"line":23,"column":57}}}))
    + "</h2>\n                    <h2>\n                    <span id=\"vmeeting-connection-state\" class=\"vmeeting-connection-state\">\n                        <span class=\"vmeeting-connection-state-disconnected\">\n                            <div class=\"vmeeting-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.disconnected",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":28},"end":{"line":28,"column":57}}}))
    + "\n                        </span>\n                        <span class=\"vmeeting-connection-state-failed\">\n                            <div class=\"vmeeting-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.failed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":28},"end":{"line":32,"column":51}}}))
    + "\n                        </span>\n                        <span class=\"vmeeting-connection-state-connected\">\n                            <div class=\"vmeeting-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.connected",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":28},"end":{"line":36,"column":54}}}))
    + "\n                        </span>\n                        <span class=\"vmeeting-connection-state-connecting\">\n                            <div class=\"vmeeting-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.in_progress",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":28},"end":{"line":40,"column":56}}}))
    + "\n                        </span>\n                    </span>\n                    </h2>\n                </div>\n                <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div id=\"vmeeting-mode-select\"></div>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div class=\"resource-container\">\n                            <div class=\"input-group\">\n                                <label class=\"input-label\" for=\"resource\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.resource",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":74},"end":{"line":52,"column":100}}}))
    + "</label>\n                                <input id=\"resource\"\n                                       type=\"text\"\n                                       class=\"input is-focusable\"\n                                       name=\"resource\"\n                                       placeholder=\"\"\n                                       tabIndex=\"-1\"\n                                       data-validation=\"checkLength\"\n                                       data-max-length=\"254\" />\n                                <div class=\"input-info\">\n                                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":83},"end":{"line":62,"column":113}}}))
    + "</span>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div class=\"oauth-app-id-container\">\n                            <div class=\"input-group\">\n                                <label class=\"input-label\" for=\"appId\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.app_id",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":71},"end":{"line":73,"column":95}}}))
    + "</label>\n                                <input id=\"appId\"\n                                       type=\"text\"\n                                       class=\"input is-focusable\"\n                                       name=\"appId\"\n                                       tabIndex=\"-1\"\n                                       data-validation=\"checkMinMaxLength\"\n                                       data-min-length=\"1\"\n                                       data-max-length=\"254\" />\n                                <div class=\"input-info\">\n                                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":83,"column":83},"end":{"line":83,"column":112}}}))
    + "</span>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div class=\"oauth-secret-container\">\n                            <div class=\"input-group\">\n                                <label class=\"input-label\" for=\"secret\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.oauth_secret",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":91,"column":72},"end":{"line":91,"column":102}}}))
    + "</label>\n                                <input id=\"secret\"\n                                       type=\"password\"\n                                       class=\"input is-focusable\"\n                                       name=\"secret\"\n                                       placeholder=\"\"\n                                       tabIndex=\"-1\"\n                                       data-validation=\"checkMinMaxLength\"\n                                       data-min-length=\"1\"\n                                       data-max-length=\"254\" />\n                                <div class=\"input-info\">\n                                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":102,"column":83},"end":{"line":102,"column":113}}}))
    + "</span>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div class=\"oauth-tenant-id-container\">\n                            <div class=\"input-group\">\n                                <label class=\"input-label\" for=\"tenantId\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.oauth_tenant_id",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":113,"column":74},"end":{"line":113,"column":107}}}))
    + "</label>\n                                <input id=\"tenantId\"\n                                       type=\"text\"\n                                       class=\"input is-focusable\"\n                                       name=\"tenantId\"\n                                       placeholder=\"\"\n                                       tabIndex=\"-1\"\n                                       data-validation=\"checkMinMaxLength\"\n                                       data-min-length=\"1\"\n                                       data-max-length=\"254\" />\n                                <div class=\"input-info\">\n                                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":124,"column":83},"end":{"line":124,"column":112}}}))
    + "</span>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-6\">\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                    <div id=\"vmeeting-oauth-container\" class=\"col-xs-12 vmeeting-oauth-container\">\n                        <div id=\"vmeeting-oauth-state\" class=\"vmeeting-oauth-state\">\n                            <span class=\"icon-panic-button vmeeting-oauth-failed\"></span>\n                            <span class=\"vmeeting-oauth-authenticating\">\n                                <div class=\"loader small\"></div>\n                            </span>\n                        </div>\n                        <div id=\"vmeeting-login-btn-container\" class=\"row settings-list-secundary vmeeting-login-btn-container\"\n                             data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\" data-nav-trapped>\n                            <input id=\"vmeeting-login\" type=\"button\" name=\"vmeeting-login\" class=\"btn-form form-action-cancel is-focusable vmeeting-login\"\n                                   value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.login_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":144,"column":42},"end":{"line":144,"column":70}}}))
    + "\">\n                        </div>\n                        <div id=\"vmeeting-logout-btn-container\" class=\"row settings-list-secundary vmeeting-logout-btn-container\"\n                             data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\" data-nav-trapped>\n                            <input id=\"vmeeting-logout\" type=\"button\" name=\"vmeeting-logout\" class=\"btn-form form-action-cancel is-focusable vmeeting-logout\"\n                                   value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.logout_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":149,"column":42},"end":{"line":149,"column":71}}}))
    + "\">\n                        </div>\n                        <div id=\"vmeeting-abort-btn-container\" class=\"row settings-list-secundary vmeeting-abort-btn-container\"\n                             data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\" data-nav-trapped>\n                            <input id=\"vmeeting-abort\" type=\"button\" name=\"vmeeting-abort\" class=\"btn-form form-action-cancel is-focusable vmeeting-abort\"\n                                   value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.abort_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":154,"column":42},"end":{"line":154,"column":70}}}))
    + "\">\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <!-- Data Handling -->\n            <div class=\"row settings-list-row\">\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.data_handling",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":163,"column":24},"end":{"line":163,"column":54}}}))
    + "</h2>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"col-xs-6\">\n                    <div id=\"data-hold-timeout\"></div>\n                </div>\n                <div class=\"col-xs-6\"></div>\n            </div>\n\n            <!-- Wallpaper -->\n            <div class=\"row settings-list-row\">\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wallpaper",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":180,"column":24},"end":{"line":180,"column":50}}}))
    + "</h2>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"col-xs-6\">\n                    <div id=\"wallpaper-select\"></div>\n                </div>\n                <div id=\"vmeeting-theme-select\" class=\"col-xs-6\">\n                    <div id=\"theme-select\"></div>\n                </div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":195,"column":12},"end":{"line":215,"column":23}}})) != null ? stack1 : "")
    + "\n            <!-- Statusbar -->\n            <div class=\"row settings-list-row\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.general_statusbar",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":220,"column":24},"end":{"line":220,"column":58}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"statusBar\" id=\"statusBar\" type=\"checkbox\" class=\"btn-switch is-focusable\"\n                               tabIndex=\"-1\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <!-- PIN -->\n            <div class=\"row settings-list-row\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.vmeeting_pin",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":236,"column":24},"end":{"line":236,"column":53}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"pin\" id=\"pin\" type=\"checkbox\" class=\"btn-switch is-focusable\"\n                               tabIndex=\"-1\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary settings-list-info logout-warning\"\n             data-nav-area=\".is-focusable\"\n             data-nav-trapped>\n            <div class=\"col-xs-12\">\n                <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.vmeeting_logout_warning",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":254,"column":19},"end":{"line":254,"column":59}}}))
    + "</p>\n            </div>\n        </div>\n\n        <div id=\"form-action-container\" class=\"form-primary-background\"></div>\n    </form>\n</div>\n";
},"useData":true});
