'use strict';

require('./modules/animation');
require('./modules/templating');

var $ = require('jquery');
var FastClick = require('./vendors/fastclick/lib/fastclick').FastClick;
var app = require('./modules/app');

$(document).on('ready', function() {
    // For better experience on touch devices.
    FastClick.attach(document.body);

    // Bootstrap and start the app.
    app.bootstrap({
        el: '#main'
    });
});
