'use strict';

const app = require('../../../../app');
const platform = require('../../../../../platform/platform');

const controlStates = {
    'none': 'none',
    'on': 'on',
    'off': 'off'
};

const controlItems = [
    {
        id: 'mute',
        type: 'mute',
        state: 'mute'
    },
    {
        id: 'volume',
        type: 'volume'
    },
    {
        id: 'low-latency',
        state: 'off',
        hidden: true,
        noIcon: true,
        states: {
            'on': {
                titleKey: 'miracast.low_latency',
                isActiveText: true
            },
            'off': {
                titleKey: 'miracast.low_latency'
            }
        },
        wide: true,
        groupEnd: true
    }
];

/**
 * VSolution framebox.
 */
app.component('FrameBoxVSolution', {
    extend: 'FrameBoxBaseType',
    className: 'framebox-view framebox-vsolution-view',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.keyEventHandlerService = this.getService('KeyEventHandlerService');
        this.frameBoxService = this.getService('FrameBoxService');
        this.options.allowedEvents = ['touchstart', 'touchend', 'mousedown', 'mouseup', 'touchmove', 'mousemove'];

        this.lowLatencyState = this.createStateMachine({
            state: controlStates.none,
            states: controlStates
        });

        this.addStateTransitions();
    },

    bindEvents: function() {
        this.on('framebox.change.' + this.index, this.onFrameboxChanged.bind(this));
        this.on('framebox.close.vSolution', this.onClose.bind(this));
    },

    bindInputEvents: function() {
        this.on('framebox.standard.keydown', this.keyDownHandler.bind(this));
        this.on('framebox.standard.keyup', this.keyUpHandler.bind(this));

        this.startTouchEventsEntity({
            $actionEl: this.options.$actionEl,
            allowedEvents: this.options.allowedEvents,
            disableSelection: false,
            debugging: false
        });
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.bindEvents();

        // Avoid infinite input loop for vCast window on remote browser
        if (!(platform.checks.isRemote && this.options.frameBoxOptions.isVSolutionCast)) {
            this.bindInputEvents();
        }

        this.startKeyboard();
        this.startControls();

        this.onFrameboxChanged({
            contentType: this.frameBoxService.contentTypeMapping(this.options.type),
            options: this.options.frameBoxOptions
        });

        this.checkKeyboardButton();
    },

    /**
     * Add state transitions of low latency state
     */
    addStateTransitions: function() {
        this.lowLatencyState.addTransitions({
            '> on': function() {
                this.emit('framebox.control-state.change', {
                    index: this.index,
                    id: 'low-latency',
                    state: controlStates.on
                });
            },

            '> off': function() {
                this.emit('framebox.control-state.change', {
                    index: this.index,
                    id: 'low-latency',
                    state: controlStates.off
                });
            }
        });
    },

    /**
     * Handle custom close of vCast window when camera back active.
     */
    onClose: function(data) {
        if (this.index !== data.index) {
            return;
        }

        if (this.options.frameBoxOptions.isVSolutionCast && this.options.frameBoxOptions.cameraBackActive) {
            this.emit('modal.open', {
                id: 'confirm-v3',
                messageKey: 'vcast.confirm_disconnect_camera_back',
                successTextKey: 'modal.ok',
                discareTextKey: 'modal.cancel',
                backdropClick: true,
                onConfirm: function() {
                    this.frameBoxService.closeFrameBox(this.index);
                }.bind(this),
                autoClose: true
            });

            return;
        }

        this.frameBoxService.closeFrameBox(this.index);
    },

    /**
     * Check if keyboard button should be shown.
     * Show if this is a miracast connection and the back channel is enabled in settings.
     */
    checkKeyboardButton: function() {
        if (window.navigator.standalone || this.options.frameBoxOptions.isVSolutionCast) {
            this.options.$actionEl.addClass('hide-keyboard-btn');

            return;
        }

        this.deviceConnection
            .send('getMiracastBackChannel')
            .then(function(miracastBackChannel) {
                if (!miracastBackChannel.enabled) {
                    this.options.$actionEl.addClass('hide-keyboard-btn');
                }
            }.bind(this));
    },

    /**
     * Update frame box info.
     */
    onFrameboxChanged: function(box) {
        this.options.frameBoxOptions = box.options;

        const lowLatencyMode = controlStates[box.options.lowLatencyMode];
        if (lowLatencyMode !== undefined && lowLatencyMode !== this.lowLatencyState.getState()) {
            this.lowLatencyState.changeState(lowLatencyMode);
        }

        this.keyboardAction('update', box);
    },

    startControls: function() {
        const index = controlItems.findIndex(c => c.id === 'low-latency');
        if (index > -1) {
            // Low latency toggle is only available for Miracast
            controlItems[index].hidden = this.options.frameBoxOptions.isVSolutionCast;
        }

        this.createComponent({
            type: 'FrameBoxControls',
            container: this.$el,
            index: this.options.index,
            appId: this.options.appId,
            items: controlItems,
            onClick: this.onControlClickHandler.bind(this),
            isFullscreen: this.options.isFullscreen
        });
    },

    /**
     * @param {string} id
     */
    onControlClickHandler: function(id) {
        const handler = this.controlHandlers[id];

        if (handler) {
            handler.call(this, {
                appId: this.appId
            });
        }
    },

    controlHandlers: {
        /**
         * Toggle Miracast low latency mode.
         */
        'low-latency': function(options) {
            this.send('setMiracastControl', {
                appId: options.appId,
                control: 'lowLatencyToggle'
            });
        }
    },

    /**
     * Start Keyboard Component.
     */
    startKeyboard: function() {
        this.keyboard = this.createComponent({
            type: 'FrameboxKeyboard',
            container: this.$el,
            index: this.options.index,
            allTouchDevices: true,
            avoidOpen: true,
            action: function(fn) {
                this.keyboardAction = fn;
            }.bind(this)
        });
    },

    /**
     * Used as fallback for keydown on devices which do not support event.key.
     * If they do have the key object, this call will be prevented in the keyDownHandler.
     *
     * @param {Object} event
     * @param {Number} index
     */
    keyPressHandler: function(event, index) {
        this.keyEventHandler(index, 'keydown', event);
    },

    /**
     * @param {Object} event
     * @param {Number} index
     */
    keyDownHandler: function(event, index) {
        this.keyEventHandler(index, 'keydown', event);
    },

    /**
     * @param {Object} event
     * @param {Number} index
     */
    keyUpHandler: function(event, index) {
        this.keyEventHandler(index, 'keyup', event);
    },

    /**
     * @param {Number} keyCode
     * @param {Number} index
     * @param {String} keyEvent
     * @param {Object} event
     */
    keyEventHandler: function(index, keyEvent, event) {
        if (index === this.index) {
            this.frameBoxService.sendKeyEvent(this.options.index, keyEvent, event);
        }
    },

    destroy: function() {
        this.stopTouchEventsEntitiy();
    }
});
