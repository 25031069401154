'use strict';

var $ = require('jquery');
var WolfProtHelper = require('./wolfprot/wolfprot-helper');
var app = require('../../app/app.js');

var RECONNECT_INTERVAL = 300;

function RestSocket(host, sessionId, options) {
    this.options = options || {};
    this.host = host || document.location.origin + '/rest.fcgi';
    this.closed = false;
    this.syncValue = 0;
    this.numRequests = 0;
    this.xmlHttpRequest = [];
    this.wolfprotQueue = [];
    this.connectionToken = null;
    this.connection = null;
    this.sessionId = sessionId;

    this.authService = app.getService('AuthenticationService');
}

/**
 * Create a Restsocket instance and connect to the server
 * @method connect
 * @return {Promise}
 */
RestSocket.prototype.connect = function connect() {
    var dfd = $.Deferred();

    if (this.getState() === 'closed') {
        this.connection = null;
    }

    if ((this.getState() === 'open') || (this.getState() === 'connecting')) {
        return;
    }
    this.connection = {};
    this.wolfProtHelper = new WolfProtHelper(this);

    const myHeaders = new Headers();

    myHeaders.append('Content-Type', 'application/vnd.wolfrest');
    myHeaders.append('Authorization', 0);

    const myRequest = new Request(this.host, {
        method: 'POST',
        headers: myHeaders
    });

    fetch(myRequest)
        .then(
            function(response) {
                response.json().then(function(data) {
                    if (data.connectionToken) {
                        console.log('Got Token');
                        this.connectionToken = data.connectionToken;
                        this.connection.readyState = 'open'; // Open
                        this.connection.onopen();
                    }
                }.bind(this));
            }.bind(this)
        )
        .catch(error => {
            console.error(error);
        });

    this.connection.onopen = function() {
        this.syncValue = 0;
        dfd.resolve();
        (this.options.onOpen || $.noop)();
    }.bind(this);

    this.connection.onmessage = (this.options.onMessage || $.noop).bind(this);

    this.connection.onerror = function onerror(err) {
        (this.options.onError || $.noop)();
        dfd.reject(err);
        this.reconnect();
    }.bind(this);

    this.connection.onclose = function onclose() {
        (this.options.onClose || $.noop)();
        this.connectionToken = undefined;
        this.wolfprotQueue = null;
        this.reconnect();
    }.bind(this);

    return dfd.promise();
};

/**
 * Sends the given data (internal use only)
 * @param data: Uint8Array with wolfprot data
 */
RestSocket.prototype.sendRequestWolfprot = function sendRequestWolfprot(data) {
    var dfd = $.Deferred();
    if (data) {
        if (this.sessionId) {
            data = this.wolfProtHelper.insertHeaderID(data, this.sessionId);
        }

        this.wolfprotQueue.push(data);
    }

    if (this.wolfprotQueue.length > 0) {
        this.postRequest();
        dfd.resolve();
    }

    return dfd.promise();
};

RestSocket.prototype.postRequest = async function postRequest() {
    let tmpData = this.wolfprotQueue.pop();

    const myHeaders = new Headers();

    myHeaders.append('Content-Type', 'application/vnd.wolfprot');
    myHeaders.append('Authorization', this.connectionToken);
    myHeaders.append('Rest-Sync', this.syncValue.toString(10));

    const myRequest = new Request(this.host, {
        method: 'POST',
        headers: myHeaders,
        body: tmpData,
        responseType: 'arraybuffer'
    });

    const response = await fetch(myRequest)
        .then(
            function(response) {
                response.arrayBuffer().then(function(data) {
                    this.connection.onmessage({
                        data: data
                    });
                }.bind(this));
            }.bind(this)
        )
        .catch(error => {
            console.error(error);
        });

    this.syncValue = (this.syncValue + 1) % Math.pow(2, 32);

    return response;
};

/**
 * Reconnect method
 * @method reconnect
 * @private
 */
RestSocket.prototype.reconnect = function reconnect() {
    if (!this.closed) {
        this.connection.readyState = 'inactive';
        setTimeout(this.connect.bind(this), RECONNECT_INTERVAL);
    }
};

/**
 * Check the current Restsocket connection state
 * @method getState
 * @return {String} state
 */
RestSocket.prototype.getState = function getState() {
    return this.connection ? this.connection.readyState : 'closed';
};

/**
 * Close the Restsocket connection
 * @method close
 */
RestSocket.prototype.close = function close() {
    this.connection.onclose = $.noop;
    this.connection.onerror = $.noop;
    this.closed = true;
    this.connectionToken = undefined;
    this.connection.readyState = 'closed'; // Closed
    clearInterval(this.keepAliveTimer);
};

/**
 * Sends the given data
 * @param data: Uint8Array with wolfprot data
 */
RestSocket.prototype.send = function send(data, userLevel) {
    var dfd;
    try {
        if (!!data && this.getState() === 'open') {
            if (!userLevel || userLevel <= this.authService.getUserLevel()) {
                return this.sendRequestWolfprot.bind(this)(data);
            }
        } else {
            throw new Error('socket.send failed.' + data);
        }
    } catch (error) {
        if (window.SHOW_ERROR === true) {
            console.error(error);
        }
    }
    dfd = $.Deferred();
    dfd.reject();

    return dfd.promise();
};

module.exports = RestSocket;
