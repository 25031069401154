const template = require('./sources-menu-item.html');

const vueUtils = require('../../util');

module.exports = {
    template,
    props: {
        item: {
            type: Object,
            required: true,
            default: {
                hasSubItems: false
            }
        },
        isDraggable: {
            type: Boolean,
            required: false,
            default: false
        },
        uiScalingSize: {
            type: String,
            required: false,
            default: ''
        }
    },

    computed: {
        name: function() {
            return this.item.name === '' ? this.i18n(this.item.titleKey) : this.item.name;
        },

        hasSubItems: function() {
            return this.item.hasSubItems === true;
        }
    },

    methods: {
        click: function() {
            this.$emit('click');
        },

        i18n: vueUtils.i18n
    }
};
