module.exports={
    "Escape" : 0x0001,
    "Digit1" : 0x0002,
    "Digit2" : 0x0003,
    "Digit3" : 0x0004,
    "Digit4" : 0x0005,
    "Digit5" : 0x0006,
    "Digit6" : 0x0007,
    "Digit7" : 0x0008,
    "Digit8" : 0x0009,
    "Digit9" : 0x000A,
    "Digit0" : 0x000B,
    "Minus" : 0x000C,
    "Equal" : 0x000D,
    "Backspace" : 0x000E,
    "Tab" : 0x000F,
    "KeyQ" : 0x0010,
    "KeyW" : 0x0011,
    "KeyE" : 0x0012,
    "KeyR" : 0x0013,
    "KeyT" : 0x0014,
    "KeyY" : 0x0015,
    "KeyU" : 0x0016,
    "KeyI" : 0x0017,
    "KeyO" : 0x0018,
    "KeyP" : 0x0019,
    "BracketLeft" : 0x001A,
    "BracketRight" : 0x001B,
    "Enter" : 0x001C,
    "Control" : 0x001D,
    "ControlLeft" : 0x001D,
    "KeyA" : 0x001E,
    "KeyS" : 0x001F,
    "KeyD" : 0x0020,
    "KeyF" : 0x0021,
    "KeyG" : 0x0022,
    "KeyH" : 0x0023,
    "KeyJ" : 0x0024,
    "KeyK" : 0x0025,
    "KeyL" : 0x0026,
    "Semicolon" : 0x0027,
    "Quote" : 0x0028,
    "Backquote" : 0x0029,
    "Shift" : 0x0036,
    "ShiftLeft" : 0x002A,
    "Backslash" : 0x002B,
    "KeyZ" : 0x002C,
    "KeyX" : 0x002D,
    "KeyC" : 0x002E,
    "KeyV" : 0x002F,
    "KeyB" : 0x0030,
    "KeyN" : 0x0031,
    "KeyM" : 0x0032,
    "Comma" : 0x0033,
    "Period" : 0x0034,
    "Slash" : 0x0035,
    "ShiftRight" : 0x0036,
    "NumpadMultiply" : 0x0037,
    "AltLeft" : 0x0038,
    "Spacebar" : 0x0039,
    "Space" : 0x0039,
    "SPACE" : 0x0039,
    "CapsLock" : 0x003A,
    "F1" : 0x003B,
    "F2" : 0x003C,
    "F3" : 0x003D,
    "F4" : 0x003E,
    "F5" : 0x003F,
    "F6" : 0x0040,
    "F7" : 0x0041,
    "F8" : 0x0042,
    "F9" : 0x0043,
    "F10" : 0x0044,
    "NumLock" : 0x0045,
    "ScrollLock" : 0x0046,
    "Numpad7" : 0x0047,
    "Numpad8" : 0x0048,
    "Numpad9" : 0x0049,
    "NumpadSubtract" : 0x004A,
    "Numpad4" : 0x004B,
    "Numpad5" : 0x004C,
    "Numpad6" : 0x004D,
    "NumpadAdd" : 0x004E,
    "Numpad1" : 0x004F,
    "Numpad2" : 0x0050,
    "Numpad3" : 0x0051,
    "Numpad0" : 0x0052,
    "NumpadDecimal" : 0x0053,
    "IntlBackslash" : 0x0056,
    "F11" : 0x0057,
    "F12" : 0x0058,
    "IntlRo" : 0x0059,
    "Convert" : 0x005C,
    "KanaMode" : 0x005D,
    "NonConvert" : 0x005E,
    "NumpadEnter" : 0x0060,
    "ControlRight" : 0x0061,
    "NumpadDivide" : 0x0062,
    "PrintScreen" : 0x0063,
    "Alt" : 0x0064,
    "AltRight" : 0x0064,
    "Home" : 0x0066,
    "ArrowUp" : 0x0067,
    "PageUp" : 0x0068,
    "ArrowLeft" : 0x0069,
    "ArrowRight" : 0x006A,
    "End" : 0x006B,
    "ArrowDown" : 0x006C,
    "PageDown" : 0x006D,
    "Insert" : 0x006E,
    "Delete" : 0x006F,
    "Del" : 0x006F,
    "AudioVolumeMute" : 0x0071,
    "AudioVolumeDown" : 0x0072,
    "AudioVolumeUp" : 0x0073,
    "Power" : 0x0074,
    "NumpadEqual" : 0x0075,
    "Pause" : 0x0077,
    "NumpadComma" : 0x0079,
    "Lang1" : 0x007A,
    "Lang2" : 0x007B,
    "IntlYen" : 0x007C,
    "MetaLeft" : 0x007D,
    "MetaRight" : 0x007E,
    "ContextMenu" : 0x007F,
    "BrowserStop" : 0x0080,
    "Again" : 0x0081,
    "Props" : 0x0082,
    "Undo" : 0x0083,
    "Select" : 0x0084,
    "Copy" : 0x0085,
    "Open" : 0x0086,
    "Paste" : 0x0087,
    "Find" : 0x0088,
    "Cut" : 0x0089,
    "Help" : 0x008A,
    "Sleep" : 0x008E,
    "WakeUp" : 0x008F,
    "LaunchApp1" : 0x0090,
    "BrowserFavorites" : 0x009C,
    "BrowserBack" : 0x009E,
    "BrowserForward" : 0x009F,
    "Eject" : 0x00A1,
    "MediaTrackNext" : 0x00A3,
    "MediaPlayPause" : 0x00A4,
    "MediaTrackPrevious" : 0x00A5,
    "MediaStop" : 0x00A6,
    "BrowserRefresh" : 0x00AD,
    "F13" : 0x00B7,
    "F14" : 0x00B8,
    "F15" : 0x00B9,
    "F16" : 0x00BA,
    "F17" : 0x00BB,
    "F18" : 0x00BC,
    "F19" : 0x00BD,
    "F20" : 0x00BE,
    "F21" : 0x00BF,
    "F22" : 0x00C0,
    "F23" : 0x00C1,
    "F24" : 0x00C2,
    "BrowserSearch" : 0x00D9,
    "Fn" : 0x01D0,
    "AT" : 0x01D0,
    "ё": 0x0029
}
