'use strict';

var app = require('../../../app');
var volumeTpl = require('./volume-settings.hbs');
var $ = require('jquery');

app.component('VolumeSettings', {
    template: volumeTpl,

    getAccessKey: function() {
        return {
            'roleName': 'Volume',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.closeTimeout = null;
        this.volumeService = this.getService('VolumeService');
        this.remote = this.getService('RemoteService');

        this.volumeService.startUpdate();
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        $.when(this.onVolumeUpdate(this))
            .done(function() {
                this.loadVolume();
                this.storeSelectors();
                this.bindEvents();
                this.startAutoClose();
            }.bind(this));
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        this.on('volume.master.changed', this.volumeChangedHandler.bind(this));
        this.on('main-loop.update.volume', this.onVolumeUpdate.bind(this));
        this.on('remote.left.keydown', this.onRemoteKeyDown.bind(this));
        this.on('remote.right.keydown', this.onRemoteKeyDown.bind(this));
    },

    /**
     * Set focus to slider on press left or right arrow.
     */
    onRemoteKeyDown: function() {
        this.remote.focus(this.$input.get(0));
    },

    /**
     * @method storeSelectors
     */
    storeSelectors: function() {
        this.$input = this.$el.find('input');
    },

    /**
     * @method onVolumeUpdate
     */
    onVolumeUpdate: function() {
        return this.volumeService.loadVolume();
    },

    /**
     * @method startAutoClose
     */
    startAutoClose: function() {
        clearTimeout(this.closeTimeout);
        this.closeTimeout = setTimeout(function() {
            this.emit('modal.close', {
                id: 'volume'
            });
        }.bind(this), 5000);
    },

    /**
     * @method volumeChangedHandler
     * @param options
     */
    volumeChangedHandler: function(options) {
        this.$input
            .val(options.volume)
            .trigger('change');
    },
    /**
     * @method loadVolume
     */
    loadVolume: function() {
        this.appendRangeInput(this.volumeService.getVolume());
    },

    /**
     * @method appendRangeInput
     */
    appendRangeInput: function(value) {
        this.createComponent({
            type: 'RangeInput',
            container: this.$el.find('#range-input'),
            input: {
                value: value,
                min: 0,
                minIcon: 'icon-volume-down',
                max: 100,
                maxIcon: 'icon-volume-up',
                step: 5,
                onChange: this.onChangeHandler.bind(this),
                onInput: this.onInputHandler.bind(this),
                onEnterPress: this.onEnterPress.bind(this)
            }
        });
    },

    onInputHandler: function() {
        this.volumeService.clientIsControling();
    },

    /**
     * @method onChangeHandler
     * @param newValue
     */
    onChangeHandler: function(newValue) {
        if (parseInt(newValue) !== this.volumeService.getVolume()) {
            this.volumeService.clientIsControling();
            this.volumeService.setVolume(newValue);
            this.startAutoClose();
        }
    },

    /**
     * @method onEnterPress
     */
    onEnterPress: function() {
        this.emit('modal.close', {
            id: 'volume'
        });
    },

    destroy: function() {
        this.volumeService.stopUpdate();
        clearTimeout(this.closeTimeout);
    }
});
