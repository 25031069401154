'use strict';

var app = require('../../../app');
var pinViewerTpl = require('./pin-viewer.hbs');

app.component('PinViewer', {
    template: pinViewerTpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    /**
     * @method postPlaceAt
     * @return void
     */
    postPlaceAt: function() {
        this.bindEvents();
        this.storeSelectors();
        this.updateHandler();
        this.startAutoHide();

        this.emit('main-loop.fast.start', {
            id: 'pin'
        });
    },

    /**
     * @method storeSelectors
     */
    storeSelectors: function() {
        this.$pinViewer = this.$el.find('#pin-viewer-pin');
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        this.on('main-loop.update', this.updateHandler.bind(this));
        this.on('main-loop.update.pin', this.updateHandler.bind(this));
    },

    /**
     * @method updateHandler
     */
    updateHandler: function() {
        this.deviceConnection
            .send('getPinStatusLocal')
            .then(this.onUpdate.bind(this));
    },

    /**
     * @method onUpdate
     * @param {object} data
     */
    onUpdate: function(data) {
        if (data.pin) {
            this.$pinViewer.html(data.pin);
        }
    },

    /**
     * @method startAutoHide
     */
    startAutoHide: function() {
        setTimeout(function() {
            this.emit('modal.close', {
                id: 'pin-viewer'
            });
        }.bind(this),  this.options.configs.hold || 1500);
    }
});
