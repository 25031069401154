const template = require('./annotation-plane.html');
const app = require('../../../../../app');
const { averageMultiplePoints } = require('../../../../../helper');

/**
 * The annotation plane reference dimension used for normalization
 * of the input coordinates and transmission via WPC.
 */
const PLANE_REF = {
    width: 1920,
    height: 1080
};

module.exports = {
    template,

    props: {
        planeCoordinates: {
            type: Object,
            required: true
        },
        tool: {
            type: String,
            required: true
        }
    },

    data: function() {
        return {
            position: {
                x: -1,
                y: -1
            },
            state: 'watching',
            pointerId: 0,
            currentMode: null,
            lastDownEvent: null,
            startPaintTimestamp: null,
            hasRubberActivatedByTouches: false
        };
    },

    computed: {
        containerData: function() {
            return {
                position: {
                    x: this.liveStream.getOffset().left + this.planeCoordinates.x,
                    y: this.liveStream.getOffset().top + this.planeCoordinates.y
                },
                size: {
                    width: this.planeCoordinates.width,
                    height: this.planeCoordinates.height
                }
            };
        }
    },

    methods: {
        draw: function() {
            if (this.state === 'painting') {
                this.annotationService.draw(this.position);

                window.requestAnimationFrame(this.draw.bind(this));
            }
        },

        stopDrawing: function() {
            this.annotationService.stopDrawing(this.position);
            this.$emit('draw-stop');
        },

        onMouseDown: function(event) {
            this.$emit('draw-start');

            let pointerDiff = 0;

            if ('pointerdown' === event.type) {
                if (0 !== this.pointerId) {
                    pointerDiff = event.pointerId - this.pointerId;
                }

                if ((Date.now() - this.lastDownEvent) <= 200) {
                    this.pointerId = event.pointerId;
                } else {
                    pointerDiff = 0;
                }
            }

            this.lastDownEvent = Date.now();

            // Enable rubber when 2 or more touches has been released.
            if ('touchstart' === event.type && 2 <= event.touches.length
                    || 'pointerdown' === event.type && 2 <= pointerDiff) {
                if (!this.hasRubberActivatedByTouches) {
                    this.hasRubberActivatedByTouches = this.tool;
                }

                this.startMultiTouchRubber();

                this.isMultiTouch = true;
            }

            // Block when mode has been chosen
            if (this.currentMode) {
                return;
            }

            switch (event.type) {
                case 'pointerdown':
                case 'touchstart':
                    this.currentMode = 'touch';
                    break;
                case 'mousedown':
                    this.currentMode = 'mouse';
                    break;
            }

            this.state = 'painting';

            switch (event.type) {
                case 'touchstart':
                    this.setPositionByTouchEvent(event);
                    break;
                case 'pointerdown':
                case 'mousedown':
                    this.setPositionByMouseEvent(event);
                    break;
            }

            this.draw();
            this.startPaintTimestamp = (new Date()).getTime();
        },

        onMouseUp: function(event) {
            if (event && event.touches && 0 < event.touches.length) {
                const firstTouch = event.touches[0];

                if (firstTouch.pageX === this.x && firstTouch.pageY === this.y
                    || (this.hasRubberActivatedByTouches && 2 <= event.touches.length)
                ) {
                    return;
                }
            } else {
                this.isMultiTouch = false;
            }

            if (this.state !== 'watching') {
                this.stopDrawing();
            }

            this.x = -1;
            this.y = -1;
            this.currentMode = null;
            this.state = 'watching';

            if (this.hasRubberActivatedByTouches) {
                this.$emit('set-tool', this.hasRubberActivatedByTouches);
            }

            this.hasRubberActivatedByTouches = false;
        },

        onMouseMove: function(event) {
            if (this.currentMode !== 'mouse') {
                return;
            }

            this.setPositionByMouseEvent(event);
        },

        onTouchMove: function(event) {
            if (this.currentMode !== 'touch') {
                return;
            }

            if (this.isMultiTouch) {
                const position = averageMultiplePoints(event.touches);
                this.setPositionByCoordinate(position);
            } else {
                this.setPositionByTouchEvent(event);
            }

            if (this.state !== 'watching') {
                event.preventDefault();
            }
        },

        onPointerMove: function(event) {
            if (this.currentMode !== 'touch') {
                return;
            }

            this.setPositionByMouseEvent(event);

            if (this.state !== 'watching') {
                event.preventDefault();
            }
        },

        /**
         * Calculate current point of the mouse/pointer and set it to the position property.
         */
        setPositionByMouseEvent: function(event) {
            const clientX = event.clientX - this.containerData.position.x;
            const clientY = event.clientY - this.containerData.position.y;

            this.position.x = (clientX / this.containerData.size.width) * PLANE_REF.width;
            this.position.y = (clientY / this.containerData.size.height) * PLANE_REF.height;
        },

        /**
         * Calculate current point of the finger and set it to the position property.
         */
        setPositionByCoordinate: function(coordinate) {
            const clientX = coordinate.x - this.containerData.position.x;
            const clientY = coordinate.y - this.containerData.position.y;

            this.position.x = (clientX / this.containerData.size.width) * PLANE_REF.width;
            this.position.y = (clientY / this.containerData.size.height) * PLANE_REF.height;
        },

        /**
         * Calculate current point of the finger and set it to the position property.
         */
        setPositionByTouchEvent: function(event) {
            const clientX = event.touches[0].clientX - this.containerData.position.x;
            const clientY = event.touches[0].clientY - this.containerData.position.y;

            this.position.x = (clientX / this.containerData.size.width) * PLANE_REF.width;
            this.position.y = (clientY / this.containerData.size.height) * PLANE_REF.height;
        },

        startMultiTouchRubber: function() {
            // Stop drawing from the first click.
            this.stopDrawing();

            // Delete first drawn point when changing to multitouch rubber.
            if (((new Date()).getTime() - this.startPaintTimestamp) < 300) {
                this.annotationService.undo();
                this.startPaintTimestamp = 0;
            }

            // Set tool to rubber.
            this.$emit('set-tool', 'rubber');
            this.$emit('set-strength', 90);
        }
    },

    created: function() {
        this.annotationService = app.getService('AnnotationService');
        this.liveStream = app.getService('LiveStreamService');
    }
};
