const template = require('./live-area.html');
const vueUtils = require('../../../components-vue/util.js');
const helper = require('../../../helper.js');
const app = require('../../../app');

module.exports = {
    template,
    components: {
        'confidence-monitor': require('./confidence-monitor/confidence-monitor')
    },

    data: function() {
        return {
            referenceDimensions: {
                width: 3840,
                height: 2160
            },
            confidenceMonitors: []
        };
    },

    created: function() {
        this.liveStream = app.getService('LiveStreamService');

        this.wolfprot = vueUtils.wolfprot();
        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return this.wolfprot.talk('getConfidenceMonitors');
            }.bind(this)
        });
        this.pollHelper.on('data', function(confidenceMonitors) {
            this.referenceDimensions = {
                width: confidenceMonitors.referenceWidth,
                height: confidenceMonitors.referenceHeight
            };

            confidenceMonitors.monitors.forEach(monitor => {
                const coords = helper.convertCoordinates(monitor, this.referenceDimensions, this.liveStream.getSize());

                return Object.assign(monitor, coords);
            });

            this.confidenceMonitors = confidenceMonitors.monitors;
        }.bind(this));
        this.pollHelper.schedulePoll();

        this.evctx = vueUtils.eventContext();
        this.evctx.on('livestream-size.update', this.pollHelper.schedulePoll.bind(this));
        this.evctx.on('aspect-ratio.changed', this.pollHelper.schedulePoll.bind(this));
        this.evctx.on('main-loop.update', this.pollHelper.schedulePoll.bind(this));
        this.evctx.on('main-loop.update.confidence-monitors', this.pollHelper.schedulePoll.bind(this));
    }
};
