'use strict';

var app = require('../../../app');
var $ = require('jquery');
var template = require('./collaboration-settings.hbs');
var FormActionView = require('./../form-action-view');

app.component('CollaborationSettings', {
    template: template,
    actionView: null,
    init: false,

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.validate = this.getService('ValidateService');
        this.remote = this.getService('RemoteService');
        this.frontendSettings = this.getService('FrontendSettings');
        this.customUiSettings = this.getService('CustomUiSettings');
        this.outputService = this.getService('OutputService');
        this.deviceService = this.getService('DeviceService');
        this.supportsMagicPen = !this.deviceService.isCboxPureReceiver() && !this.deviceService.isCboxProDualProjection();
        this.isCboxNonDualProjection = this.deviceService.isCboxNonDualProjection();

        this.bindEvents();
    },

    postPlaceAt: function() {
        this.initForm();
        this.storeSelectors();

        $.when(this.loadCollaborationSettings()).done(function() {
            this.initFormAction();
            this.bindDOMEvents();

            if (this.supportsMagicPen) {
                this.handleMagicPenChange();
            }
        }.bind(this));
    },

    serialize: function() {
        return {
            isCboxNonDualProjection: this.isCboxNonDualProjection,
            supportsMagicPen: this.supportsMagicPen,
            isCboxPure: this.deviceService.isCboxPure(),
            isCboxPureReceiver: this.deviceService.isCboxPureReceiver()
        };
    },

    bindDOMEvents: function() {
        if (this.supportsMagicPen) {
            this.form.get('magicPenEnabled').$el.on('change', this.handleMagicPenChange.bind(this));
        }

        this.form.on('change.input', this.handleFormChange.bind(this));
    },

    bindEvents: function() {
        this.on('remote.enter.keydown', this.checkActionForm.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
    },

    storeSelectors: function() {
        if (this.isCboxNonDualProjection) {
            this.$userPinHdmi2 = this.$el.find('.user-pin-hdmi2');
        }

        if (this.supportsMagicPen) {
            this.$magicPenSettings = this.$el.find('.magic-pen-settings-container');
        }
    },

    handleFormChange: function() {
        this.actionView.open();
        this.remote.update();
        this.checkActionForm();
        this.changes = true;
    },

    /**
     * Handle magic pen change. Show/Hide magic pen settings.
     */
    handleMagicPenChange: function() {
        var value = this.form.get('magicPenEnabled').getValue();

        if (value) {
            this.$magicPenSettings
                .stop()
                .slideDown(300);
        } else {
            this.$magicPenSettings
                .stop()
                .slideUp(230);
        }
    },

    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#collaboration-settings'),
            validationContainer: '.input-group'
        });
        this.formData = {};
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
        this.remote.update();
    },

    /**
     * Load settings and update form.
     */
    loadCollaborationSettings: function() {
        return $.when(
            this.loadBackendSettings(),
            this.loadFrontendSettings(),
            this.loadCustomUiSettings()
        )
            .done(this.updateForm.bind(this));
    },

    loadFrontendSettings: function() {
        var dfd = $.Deferred();

        if (this.isCboxNonDualProjection) {
            this.frontendSettings
                .getSettings([
                    'userPinHdmi2',
                    'autoShowAnnotationMenu'
                ])
                .then(function(userPinHdmi2, autoShowAnnotationMenu) {
                    this.formData.userPinHdmi2 = userPinHdmi2;
                    this.formData.autoShowAnnotationMenu = autoShowAnnotationMenu;
                    dfd.resolve();
                }.bind(this));
        } else {
            this.frontendSettings
                .getSettings([
                    'autoShowAnnotationMenu'
                ])
                .then(function(autoShowAnnotationMenu) {
                    this.formData.autoShowAnnotationMenu = autoShowAnnotationMenu;
                    dfd.resolve();
                }.bind(this));
        }

        return dfd.promise();
    },

    loadCustomUiSettings: function() {
        var dfd = $.Deferred();

        this.customUiSettings
            .getSettings(['whiteboardEnabled', 'annotationEnabled'])
            .then(function(whiteboardEnabled, annotationEnabled) {
                this.formData.whiteboardEnabled = whiteboardEnabled;
                this.formData.annotationEnabled = annotationEnabled;

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    loadBackendSettings: function() {
        var dfd = $.Deferred();

        this.deviceConnection
            .send([
                'getPresentationMode',
                'getCollaborationUserVisiblity'
            ])
            .then(function(presentationMode, userVisibility) {
                this.formData = {
                    presentationMode: presentationMode.presentationMode,
                    enableUser: userVisibility.visible
                };

                dfd.resolve();
            }.bind(this));

        if (this.supportsMagicPen) {
            this.deviceConnection
                .send([
                    'getAnnotationMagicPenSettings'
                ])
                .then(function(magicPenSettings) {
                    this.formData.magicPenEnabled = magicPenSettings.enabled;
                    this.formData.visibleTime = magicPenSettings.visibleTime;
                    this.formData.enableRemoteUser = magicPenSettings.remoteUserEnabled;
                    this.formData.autoSnapshot = magicPenSettings.autoSnapshot;

                    dfd.resolve();
                }.bind(this));
        }

        return dfd.promise();
    },

    updateForm: function() {
        this.form.setValues(this.formData);

        if (this.isCboxNonDualProjection) {
            if (this.outputService.isModerator()) {
                this.$userPinHdmi2.show();
            } else {
                this.$userPinHdmi2.hide();
            }
        }
    },

    saveHandler: function() {
        if (this.form.validate()) {
            $.when(this.saveFrontendSettings(), this.saveCustomUiSettings(), this.saveBackendSettings())
                .then(this.handleSettingsSave());
        }
    },

    saveFrontendSettings: function() {
        if (this.isCboxNonDualProjection) {
            const userPinHdmi2 = this.form.get('userPinHdmi2').getValue();

            this.frontendSettings.updateSetting({
                tag: 'userPinHdmi2',
                value: userPinHdmi2
            });
        }

        const autoShowAnnotationMenu = this.form.get('autoShowAnnotationMenu').getValue();

        this.frontendSettings.updateSetting({
            tag: 'autoShowAnnotationMenu',
            value: autoShowAnnotationMenu
        });

        return this.frontendSettings.saveSettings();
    },

    saveCustomUiSettings: function() {
        if (this.deviceService.isCboxPureReceiver()) {
            return;
        }

        const annotationEnabled = this.form.get('annotationEnabled').getValue();
        this.customUiSettings.updateSetting({
            tag: 'annotationEnabled',
            value: annotationEnabled
        });

        const whiteboardEnabled = this.form.get('whiteboardEnabled').getValue();
        this.customUiSettings.updateSetting({
            tag: 'whiteboardEnabled',
            value: whiteboardEnabled
        });

        return this.customUiSettings.saveSettings();
    },

    saveBackendSettings: function() {
        if (this.deviceService.isCboxPureReceiver()) {
            return;
        }
        const cmds = [];

        const enableUser = this.form.get('enableUser').getValue();

        cmds.push(
            {
                command: 'setCollaborationUserVisiblity',
                data: {
                    visible: enableUser
                }
            });

        if (this.supportsMagicPen) {
            const magicPenEnabled = this.form.get('magicPenEnabled').getValue();
            const magicPenVisibleTime = this.form.get('visibleTime').getValue();
            const magicPenEnableRemoteUser = this.form.get('enableRemoteUser').getValue();
            const magicPenAutoSnapshot = this.deviceService.isCboxPure() ? false : this.form.get('autoSnapshot').getValue();

            cmds.push({
                command: 'setAnnotationMagicPenSettings',
                data: {
                    enabled: magicPenEnabled,
                    visibleTime: magicPenVisibleTime,
                    remoteUserEnabled: magicPenEnableRemoteUser,
                    autoSnapshot: magicPenAutoSnapshot
                }
            });
        }

        return this.deviceConnection
            .send(cmds);
    },

    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });
        this.form.setDefaultValues();
        this.emit('overlay.remote.focus', true);
        this.changes = false;
    },

    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
        this.changes = false;
    },

    hasChanges: function() {
        var changes =  {
            hasChanges: this.changes,
            invalid: false
        };

        if (this.actionView) {
            changes.invalid = $(this.actionView.$submitBtn).prop('disabled');
        }

        return changes;
    }
});
