'use strict';

var _ = require('lodash');

/**
 * @class StateMachine
 * @constructor
 */
function StateMachine(options) {
    /**
     * @type {Object}
     */
    this.options = options;

    /**
     * Current state.
     *
     * @type {String}
     */
    this.currentState = options.state;

    /**
     * State transitions BEFORE the state is changed.
     *
     * @type {Object}
     */
    this.transitions = {};

    /**
     * State transitions AFTER the state is changed.
     *
     * @type {Object}
     */
    this.postTransitions = {};

    /**
     * On State changed store.
     *
     * @type {Array}
     */
    this.onStateChanged = [];
}

/**
 * Returns the current state of the state machine.
 *
 * @method getState
 */
StateMachine.prototype.getState = function getState() {
    return this.currentState;
};

/**
 * Merge into the internal transitions.
 * These transitions are performed BEFORE the state is changed.
 *
 * @param {Object} transitions
 */
StateMachine.prototype.addTransitions = function addTransitions(transitions) {
    return _.extend(this.transitions, transitions);
};

/**
 * Merge into the internal transitions.
 * These transitions are performed AFTER the state is changed.
 *
 * @param {Object} transitions
 */
StateMachine.prototype.addPostTransitions = function addPostTransitions(transitions) {
    return _.extend(this.postTransitions, transitions);
};

/**
 * Add on State Changed handler.
 *
 * @param {Function} onStateChanged
 */
StateMachine.prototype.addOnStateChanged = function addOnStateChanged(onStateChanged) {
    this.onStateChanged.push(onStateChanged);
};

/**
 * Update the current state and call the state transition method.
 *
 * @param {String} state
 */
StateMachine.prototype.changeState = function changeState() {
    // Take the given state out of the arguments array.
    var clonedArguments =  _.map(arguments, _.cloneDeep);
    var clonedArgumentsPost =  _.map(arguments, _.cloneDeep);
    var state = [].shift.apply(arguments);
    var context = this.options.context || null;

    if (this.transitions) {
        this.applyTransition(this.transitions, clonedArguments, context);
    }

    // Call on state changed handler.
    if (0 < this.onStateChanged.length && this.currentState !== state) {
        _.forEach(this.onStateChanged, function(handler) {
            handler.call(context, state, this.currentState);
        }.bind(this));
    }

    this.currentState = state;

    if (this.postTransitions) {
        this.applyTransition(this.postTransitions, clonedArgumentsPost, context);
    }
};

/**
 * Add transition to state change.
 *
 * @param transitions
 * @param state
 * @param context
 */
StateMachine.prototype.applyTransition = function applyTransition(transitions, changeArguments, context) {
    var transition;
    var fromTransition;
    var toTransition;
    var state = [].shift.apply(changeArguments);

    transition = transitions[this.currentState + ' > ' + state];
    fromTransition = transitions[this.currentState + ' >'];
    toTransition = transitions['> ' + state];

    if (fromTransition) {
        fromTransition.apply(context, changeArguments);
    }

    if (transition) {
        transition.apply(context, changeArguments);
    }

    if (toTransition) {
        toTransition.apply(context, changeArguments);
    }
};

module.exports = StateMachine;
