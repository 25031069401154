'use strict';

var $ = require('jquery');
var _ = require('lodash');
var app = require('../../../app');
var i18n = require('i18next');
var recordStreamSettingsTpl = require('./record-stream-settings.hbs');
var FormActionView = require('../form-action-view');

app.component('RecordStreamSettings', {
    template: recordStreamSettingsTpl,
    actionView: null,

    initialize: function() {
        this.init = true;
        this.formManager = this.getService('FormManager');
    },

    postPlaceAt: function() {
        this.createSelects();
        this.storeSelectors();

        $.when(this.loadSettings())
            .done(function() {
                this.initForm();
                this.initFormAction();
                this.bindEvents();
                this.bindDOMEvents();
                this.updateForm();
                this.handlePipEnableChange();
                this.init = false;
            }.bind(this));
    },

    bindEvents: function() {
        this.on('settings.save-changes', this.saveHandler.bind(this));
    },

    bindDOMEvents: function() {
        this.form.get('pipEnabled').$el.on('change', this.handlePipEnableChange.bind(this));
        this.form.on('change.input', this.handleFormChange.bind(this));
    },

    /**
     * Load Backend Settings.
     *
     * @returns {*|jQuery}
     */
    loadSettings: function() {
        var dfd = $.Deferred();

        this.deviceConnection
            .send([
                'getPipFunction',
                'getPipPosition',
                'getPipSize',
                'getPipCameraLatency',
                'getPipContent',
                'getStreamInput',
                'getPipStreamInput'
            ])
            .then(function(func, position, size, latency, content, streamInputs, streamInput) {
                var inputs = [];

                _.each(streamInputs.list, function(input) {
                    if (input.mode !== 'none') {
                        inputs.push({
                            text: input.name,
                            value: input.index
                        });
                    }
                }.bind(this));

                this.inputStreams = inputs;

                this.createInputStreamSelect();

                this.formData = {
                    pipEnabled: func.enable,
                    pipSize: size.size,
                    pipPosition: position.position,
                    pipLatency: latency.latency,
                    pipContent: content.content,
                    streamInput: streamInput.index
                };

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    saveHandler: function() {
        var data = this.form.serialize();

        if (this.form.validate() || !data.pipEnabled) {
            this.emit('overlay.remote.focus', true);

            this.deviceConnection
                .send([
                    {
                        command: 'setPipFunction',
                        data: { enable: data.pipEnabled }
                    },
                    {
                        command: 'setPipSize',
                        data: { size: data.pipSize }
                    },
                    {
                        command: 'setPipPosition',
                        data: { position: data.pipPosition }
                    },
                    {
                        command: 'setPipCameraLatency',
                        data: { latency: data.pipLatency }
                    },
                    {
                        command: 'setPipContent',
                        data: { content: data.pipContent }
                    },
                    {
                        command: 'setPipStreamInput',
                        data: { index: data.streamInput }
                    }
                ]);

            this.form.setDefaultValues();
            this.changes = false;
        }
    },

    updateForm: function() {
        this.form.setValues(this.formData);
        this.form.setDefaultValues();
    },

    storeSelectors: function() {
        this.$pipSettingsContainer = this.$el.find('#pip-settings-container');
    },

    initForm: function() {
        this.form = this.formManager
            .create({
                el: this.$el.find('#record-stream-settings-form'),
                validationContainer: '.input-group'
            });
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    createSelects: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#size-select'),
            label: 'settings.pip_size',
            native: true,
            name: 'pipSize',
            items: [
                {
                    text: 'settings.360p_wide',
                    value: '360p'
                }
            ]
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#position-select'),
            label: 'settings.pip_position',
            native: true,
            name: 'pipPosition',
            items: [
                {
                    text: 'settings.left_top',
                    value: 'leftTop'
                },
                {
                    text: 'settings.right_top',
                    value: 'rightTop'
                },
                {
                    text: 'settings.left_bottom',
                    value: 'leftBottom'
                },
                {
                    text: 'settings.right_bottom',
                    value: 'rightBottom'
                }
            ]
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#content-select'),
            label: 'settings.pip_content',
            info: '',
            native: true,
            name: 'pipContent',
            items: [
                {
                    text: 'settings.ip_camera',
                    value: 'camera'
                },
                {
                    text: 'settings.main_screen',
                    value: 'main'
                }
            ]
        });
    },

    handleFormChange: function(data) {
        if (!this.init) {
            this.actionView.open();

            this.changes = true;

            if (data.input.name === 'pipEnabled' && !data.input.value) {
                this.actionView.enableSubmitButton();

                return;
            }

            this.checkActionForm();
        }
    },

    createInputStreamSelect: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#stream-input-select'),
            label: 'settings.stream_input',
            native: true,
            name: 'streamInput',
            items: this.inputStreams,
            error: i18n.t('settings.error_messages.no_input_stream'),
            validation: 'checkEmptySelect'
        });
    },

    /**
     * Handle pip enable change. Show/Hide pip settings.
     */
    handlePipEnableChange: function() {
        var value = this.form.get('pipEnabled').getValue();

        if (value) {
            this.$pipSettingsContainer
                .stop()
                .slideDown(300);
        } else {
            this.$pipSettingsContainer
                .stop()
                .slideUp(230);
        }
    },

    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);

        this.form.resetValues();
        this.form.setDefaultValues();
        this.changes = false;
    },

    hasChanges: function() {
        if (!this.actionView) {
            return {
                hasChanges: false,
                invalid: false
            };
        }

        return {
            hasChanges: this.changes,
            invalid: $(this.actionView.$submitBtn).prop('disabled')
        };
    }
});
