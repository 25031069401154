'use strict';

const app = require('../../app');
const mainMenuTpl = require('./main-menu.html');
const vueUtils = require('../util');
const Vuex = require('vuex');
const _ = require('lodash');
const componentControlBarDisabled = require('./../../settings').componentControlBarDisabled;
const controlBarPositions = require('./../../settings').controlBarPositions;

const MAX_FRAME_BOXES = 4;

app.component('MainMenu', {
    engine: 'vue',
    template: mainMenuTpl,
    className: 'main-menu-container',

    data: function() {
        return {
            showMainMenu: false,
            showSourcesMenu: false,
            isFullscreenActive: false,
            isDualProjection: false,
            mainMenuTop: 'unset',
            mainMenuHeightFullscreen: 0,
            sourcesMenuOpened: false,
            controlCenterOpened: false,
            fileBrowserOpened: false,
            numberOfOpenFrameBoxes: 0,
            fullscreenTimeoutHandle: null
        };
    },

    computed: {
        maxNumberOfFrameboxesOpen: function() {
            return this.numberOfOpenFrameBoxes >= MAX_FRAME_BOXES;
        },
        /**
         * In dual-projection mode the file browser has a separate button next to the sources menu button.
         * However, if no other sources are available the sources menu button disappears.
         */
        hasTwoMiddleButtons: function() {
            return this.isDualProjection ? this.sourcesWithoutFileBrowser.length > 0 : false;
        },
        sourcesWithoutFileBrowser: function() {
            return this.sources.filter(s => s.type !== 'filebrowser');
        },
        hasSourcesButton: function() {
            return (this.isDualProjection && this.sourcesWithoutFileBrowser.length > 0)
                || (!this.isDualProjection && this.sources.length > 0);
        },
        sourcesButtonIcon: function() {
            if (this.isDualProjection && this.sourcesWithoutFileBrowser.length === 1) {
                return this.sourcesWithoutFileBrowser[0].icon;
            } else if (this.sources.length === 1) {
                return this.sources[0].icon;
            }

            return 'icon-v2-source-menu';
        },
        ...Vuex.mapGetters('sources', { sources: 'getSources' }),
        ...Vuex.mapGetters('sources', { numberOfSources: 'getNumberOfSources' })
    },

    watch: {
        showMainMenu: function(val) {
            if (val) {
                this.$forceUpdate();
                this.updatePosition();
            }
        },
        isFullscreenActive: function() {
            this.updatePosition();
        },
        numberOfSources: function() {
            this.showSourcesMenu = !this.doNotShowSourcesMenu();
        },
        maxNumberOfFrameboxesOpen: function() {
            this.showSourcesMenu = !this.doNotShowSourcesMenu();
        },
        showSourcesMenu: function(val) {
            this.component.emit('main-menu.show-sources-menu', val);
        },
        /**
         * Burst poll content sources when number of open frame boxes changes from max number of open
         * frame boxes +/- 1 to avoid glitches of the main menu when hiding/showing sources menu button.
         */
        numberOfOpenFrameBoxes: function(newVal, oldVal) {
            if (newVal === MAX_FRAME_BOXES && oldVal === MAX_FRAME_BOXES - 1
                || newVal === MAX_FRAME_BOXES - 1 && oldVal === MAX_FRAME_BOXES) {
                app.emit('main-loop.fast.start', {
                    id: 'sources'
                });
            }
        }
    },

    methods: {
        openSourcesMenu: function() {
            this.component.emit('menu.open');
        },

        openFileBrowser: function() {
            this.component.emit('file-browser.open');
        },

        openSource: _.throttle(function(item) {
            this.sourcesService.open(item);
        }, 500, { leading: true, trailing: false }),

        openControlCenter: function() {
            this.component.emit('control-center.open');
        },

        hide: function() {
            if (!this.sourcesMenuOpened && !this.controlCenterOpened && !this.fileBrowserOpened
                && (this.isDualProjection || this.frameBoxService.isControlBarPinned())) {
                return;
            }

            this.showMainMenu = false;
        },

        show: function() {
            this.showMainMenu = !this.doNotShowMainMenu();
            this.showSourcesMenu = !this.doNotShowSourcesMenu();

            this.annotationPainting = false;
        },

        /**
         * If fullscreen active changes and then changes back to its previous value within 100ms
         * ignore the state change. This avoids unnecessary flickering of the main menu when windows
         * are closed in fullscreen mode.
         * @param fullscreenActive
         */
        setIsFullscreen: function(fullscreenActive) {
            if (this.fullscreenTimeoutHandle && fullscreenActive === this.isFullscreenActive) {
                clearTimeout(this.fullscreenTimeoutHandle);
                this.fullscreenTimeoutHandle = null;

                return;
            }

            this.fullscreenTimeoutHandle = setTimeout(() => {
                this.isFullscreenActive = fullscreenActive;
            }, 100);
        },

        /**
         * Update Position of main menu.
         * Fullscreen: Set position to same height as control bar.
         */
        updatePosition: function() {
            if (this.isFullscreenActive) {
                this.mainMenuTop = this.liveStream.getSize().height - this.mainMenuHeightFullscreen;

                if (this.frameBoxService.getFullscreenBox()) {
                    if (this.frameBoxService.contentTypeMapping(this.frameBoxService.getFullscreenBox().component) === 'vSolution') {
                        this.mainMenuTop -= controlBarPositions.bottom.value;
                    } else if (this.frameBoxService.contentTypeMapping(this.frameBoxService.getFullscreenBox().component) === 'webconference') {
                        this.mainMenuTop -= app.getService('WebconferenceService').getControlBarPositionValue();
                    } else if (this.frameBoxService.contentTypeMapping(this.frameBoxService.getFullscreenBox().component) === 'zoom') {
                        this.mainMenuTop -= app.getService('ZoomService').getControlBarPosition();
                    }
                }
                this.$forceUpdate();
            }
        },

        doNotShowMainMenu: function() {
            return this.sourcesMenuOpened || this.controlCenterOpened || this.fileBrowserOpened
                || this.annotationService.isMagicPenActive()
                || (this.authService.getIsCollab() && !this.annotationService.isCollaborationUserActive())
                || (this.authService.getIsLdapActive() && !this.authService.getIsLdapAuthenticated())
                || this.screensaverService.isScreensaverActiveUI() || this.screensaverService.isScreensaverActive()
                || this.screensaverService.isScreenOffActive()
                || (this.stationService.getPushStatus() && !this.stationService.getAudioStatus() && !this.annotationService.isMatrixAnnotation())
                || this.stationService.getLockStatus() || (this.frameBoxService.getFullscreenBox()
                    && -1 !== componentControlBarDisabled.indexOf(this.frameBoxService.getFullscreenBox().component));
        },

        doNotShowSourcesMenu: function() {
            return this.deviceService.isCboxPureMini() || this.deviceService.isCboxPureReceiver()
                || this.annotationService.isActive() || this.stationService.getLimitedAccessStatus()
                || this.stationService.getPushStatus() || this.stationService.getAudioStatus()
                || this.numberOfSources === 0;
        }
    },

    created: function() {
        this.deviceService = app.getService('DeviceService');
        this.liveStream = app.getService('LiveStreamService');
        this.frameBoxService = app.getService('FrameBoxService');
        this.annotationService = app.getService('AnnotationService');
        this.authService = app.getService('AuthenticationService');
        this.screensaverService = app.getService('ScreensaverService');
        this.stationService = app.getService('StationService');
        this.sourcesService = app.getService('SourcesService');

        this.isDualProjection = this.deviceService.isCboxProDualProjection();

        app.emit('main-loop.fast.start', {
            id: 'station'
        });

        app.emit('main-loop.fast.start', {
            id: 'screensaver'
        });

        this.evctx = vueUtils.eventContext();

        /* Matrix station events */
        this.evctx.on('station-status.standard', this.show);
        this.evctx.on('station-status.push', this.hide);
        this.evctx.on('station-status.lock', this.hide);
        this.evctx.on('station-status.audio', this.show);
        this.evctx.on('station-status.limited', function() {
            this.showSourcesMenu = false;
        });

        /* Fullscreen events */
        this.evctx.on('fullscreen.framebox.standard.controls.show', this.show);
        this.evctx.on('fullscreen.framebox.standard.controls.hide', this.hide);
        this.evctx.on('fullscreen.closed', this.setIsFullscreen.bind(this, false));
        this.evctx.on('fullscreen.opened', this.setIsFullscreen.bind(this, true));

        /* Annotation handling */
        this.evctx.on('annotation.action.painting', function() {
            this.annotationPainting = true;
            this.hide();
        }.bind(this));
        this.evctx.on('annotation.action.watching', this.show);
        this.evctx.on('annotation.action.start', this.hide);
        this.evctx.on('annotation.action.stop', this.show);
        this.evctx.on('annotation.action.pause', this.show);
        this.evctx.on('magic-pen.state.active', this.hide);

        /* Control Center, Sources Menu and File Browser handling */
        this.evctx.on('menu.opened', function() {
            this.sourcesMenuOpened = true;
            this.hide();
        }.bind(this));
        this.evctx.on('menu.closed', function() {
            this.sourcesMenuOpened = false;
            setTimeout(function() {
                this.show();
            }.bind(this), 500);
        }.bind(this));
        this.evctx.on('control-center.opened', function() {
            this.controlCenterOpened = true;
            this.hide();
        }.bind(this));
        this.evctx.on('control-center.closed', function() {
            this.controlCenterOpened = false;
            setTimeout(function() {
                this.show();
            }.bind(this), 500);
        }.bind(this));
        this.evctx.on('file-browser.opened', function() {
            this.fileBrowserOpened = true;
            this.hide();
        }.bind(this));
        this.evctx.on('file-browser.closed', function() {
            this.fileBrowserOpened = false;
            setTimeout(function() {
                this.show();
            }.bind(this), 500);
        }.bind(this));

        /* Activities timeout events (show/hide after timeout) */
        this.evctx.on('activities.hide', function() {
            if (this.isDualProjection || this.isFullscreenActive || this.annotationService.isActive()) {
                return;
            }

            this.hide();
        }.bind(this));

        this.evctx.on('activities.show', function() {
            if (this.isFullscreenActive || this.annotationPainting) {
                return;
            }

            this.show();
        }.bind(this));

        /* Frameboxes update events */
        this.evctx.on('frameboxes.update', function() {
            this.numberOfOpenFrameBoxes = this.frameBoxService.getNumberOpenFrameboxes();
        }.bind(this));
    },

    mounted: function() {
        /* Update main menu position events */
        this.evctx.on('livestream-size.update', this.updatePosition);
        this.evctx.on('aspect-ratio.changed', this.updatePosition);
        this.evctx.on('active-framebox.changed', this.updatePosition);
    },

    updated: function() {
        if (this.$refs.mainMenuFullscreen && this.$refs.mainMenuFullscreen.clientHeight !== 0) {
            this.mainMenuHeightFullscreen = this.$refs.mainMenuFullscreen.clientHeight;
        }
    }
});
