'use strict';

var $ = require('jquery');
var _ = require('lodash');
var app = require('../../../app');
var fullScreenHeaderTpl = require('./framebox-ui-fullscreen.hbs');
var platform = require('../../../../platform/platform');
var contentTypeKeyboardEnabled = require('./../../../settings').contentTypeKeyboardEnabled;
var componentControlBarDisabled = require('./../../../settings').componentControlBarDisabled;
var keyboardViews = require('./../../../settings').keyboardViews;
var Hammer = require('hammerjs');
var LayoutManager = require('../../../../layout/layout');
var keyMapper = require('./../../../../../modules/keyboard/key-mapper');

const store = require('../../../store/store');

/**
 * Control bar visible or hidden (freeze, overlay opened, timer,...).
 *
 * @type {{
 *  visible: string,
 *  hidden: string
 * }}
 */
var visibilityStates = {
    visible: 'visible',
    hidden: 'hidden'
};

/**
 * Active and passive states.
 * Is the framebox clickable or inactive (z.B. Freeze ON; Overlay, Menu opened,...)
 *
 * @type {{
 *  inactive: string (not clickable),
 *  active: string (clickable)
 * }}
 */
var activeStates = {
    inactive: 'inactive',
    active: 'active'
};

var PREVENT_KEYCODES = [
    37,
    38,
    39,
    40,
    13
];

var TIMEOUT = 3000;

app.component('FrameBoxFullScreenUI', {
    className: 'framebox-action has-action framebox-fullscreen-ui full-height stop-overscroll',
    template: fullScreenHeaderTpl,

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.getServices();

        this.controlsScrollable = false;
        this.selectedTab = null;
        this.frameBoxService.setFullscreenBox(this.selectedTab);
        this.tabs = {};
        this.activeState = this.createStateMachine({
            state: activeStates.inactive,
            states: activeStates
        });
        this.controlBarVisibility = this.createStateMachine({
            state: visibilityStates.hidden,
            states: visibilityStates
        });

        this.addStateTransitions();
    },

    serialize: function() {
        return {
            hasNoSourcesMenu: this.deviceService.isCboxPureMini() || this.deviceService.isCboxPureReceiver()
                || this.annotationService.isActive() || this.stationService.getLimitedAccessStatus()
                || this.stationService.getAudioStatus() || store.getters['sources/getNumberOfSources'] === 0
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.initHammerJs();

        this.createControlBarTabs();

        this.bindEvents();
        this.bindDOMEvents();

        if (platform.checks.isCbox) {
            this.bindRemoteEvents();
        }

        if (this.controlBarVisibility.getState() === visibilityStates.visible) {
            this.startControlBarTimer();
        }

        // RELEASE-2062
        if (this.isMatrix()) {
            this.matrixConfigs = this.getService('MatrixConfigs');
            this.matrixConfigs.parseStations();
        }

        this.emit('fullscreen.framebox.standard.controls.hide');
        this.emit('show.ui', true);

        // Initialize/activate frameboxe state
        // RELEASE-3069
        $.when(this.screensaverService.getScreensaverState(), this.screensaverService.getScreenSaverSettings())
            .done(function(screensaverState, screensaverSettings) {
                if (screensaverState !== 'screensaver' || screensaverSettings.interactiveMode) {
                    this.activeState.changeState(activeStates.active);
                }

                // Show exit button for interactive screensaver on CBox instance only
                if (screensaverState === 'screensaver' && screensaverSettings.interactiveMode && platform.checks.isCbox
                    && this.options?.frameBoxes[this.selectedTab.index]?.contentType === 'Browser') {
                    app.createComponent('ScreensaverEnd', {
                        type: 'ScreensaverEnd',
                        container: '#screensaver-end-container'
                    });
                }

                this.updateKeyboardButton(screensaverState !== 'screensaver' && this.hasKeyboardButton());
            }.bind(this));

        // Need to reinitialize scrollbar because at the time of controlbar initialization the fullscreen component doesn't exit
        this.emit('reinit.scrolling.' + this.selectedTab.index);
    },

    getServices: function() {
        this.stationService = this.getService('StationService');
        this.frameBoxService = this.getService('FrameBoxService');
        this.overlayHandlerService = this.getService('OverlayHandlerService');
        this.outputService = this.getService('OutputService');
        this.freezeService = this.getService('FreezeService');
        this.keyboard = this.getService('KeyboardService');
        this.remote = this.getService('RemoteService');
        this.screensaverService = this.getService('ScreensaverService');
        this.deviceService = this.getService('DeviceService');
        this.annotationService = this.getService('AnnotationService');
        this.remoteService = this.getService('RemoteService');
        this.webconferenceService = this.getService('WebconferenceService');
    },

    storeSelectors: function() {
        this.$tabsContainer = this.$el.find('.framebox-tabs');
        this.$header = this.$el.find('.framebox-fullscreen-header');
        this.$stateEffect = this.$el.find('.framebox-state-effect');
        this.$hdmiOut2Button = this.$el.find('.framebox-show-hdmi-out-2');
        this.$keyboardButton = this.$el.find('.framebox-show-keyboard');
        this.$freezeState = this.$el.find('.framebox-viewer-freeze-state');

        // Title container when control bar is hidden (e.g. Matrix PULL)
        this.$matrixTitleContainer = this.$el.find('.framebox-fullscreen-header-title-container');
        this.$matrixTitleSymbol = this.$matrixTitleContainer.find('.framebox-symbol');
        this.$matrixTitle = this.$matrixTitleContainer.find('.framebox-header-title');
    },

    /**
     * Initialize HammerJs.
     */
    initHammerJs: function() {
        this.hammer = new Hammer.Manager(this.$el.get(0));
        var tap = new Hammer.Tap({ event: 'singletap' });
        var doubleTap = new Hammer.Tap({
            event: 'doubletap',
            taps: 2,
            interval: 200
        });
        var pinch = new Hammer.Pinch();
        var pan = new Hammer.Pan();
        var hammerRecognizers;

        doubleTap.recognizeWith(tap);
        tap.requireFailure(doubleTap);

        hammerRecognizers = [doubleTap, tap];

        // Pan and pinch are only used in touch devices. Otherwise we have a bug in safari
        // With range inputs: https://jira.wolfvision-at.intra:8443/browse/RELEASE-1500
        if (platform.checks.isTouchDevice) {
            hammerRecognizers.push(pan, pinch);
        }

        this.hammer.add(hammerRecognizers);
    },

    addStateTransitions: function() {
        this.activeState.addTransitions({
            '> inactive': function() {
                this.unbindFrameboxDOMEvents();
                this.updateHdmiOut2(false);
                this.updateKeyboardButton(false);
                this.hideControlBar();

                this.keyboard.close();
            },

            '> active': function() {
                this.bindFrameboxDOMEvents();
                this.hasHdmiOut2Button();
                this.updateKeyboardButton(this.hasKeyboardButton());
                this.showControlBar();
            }
        });

        this.controlBarVisibility.addTransitions({
            '> hidden': function() {
                this.$header.addClass('is-invisible');

                if (!this.frameBoxService.menuOpened) {
                    this.emit('fullscreen.framebox.standard.controls.hide');
                }

                this.emit('show.ui', true);
                this.emit('framebox.submenu.close');

                // Update button position
                this.$keyboardButton.removeClass('shift-up');
                this.$hdmiOut2Button.removeClass('shift-up');
                this.emit('framebox-icon-button.position.update');

                this.remote.destroyArea(this.$header);
                this.emit('osd-message-interactive.focus');

                if (this.isMatrix()) {
                    this.$matrixTitleContainer.removeClass('hidden');
                }
            },
            '> visible': function() {
                // If matrix control framebox - only show Matrix flap on tab.
                if (-1 !== componentControlBarDisabled.indexOf(this.selectedTab.component)) {
                    this.emit('show.ui');
                    this.startControlBarTimer();

                    return;
                }

                this.$header.removeClass('is-invisible');
                this.emit('fullscreen.framebox.standard.controls.show');
                this.emit('show.ui');

                // Update button position
                this.$keyboardButton.addClass('shift-up');
                this.$hdmiOut2Button.addClass('shift-up');
                this.emit('framebox-icon-button.position.update');

                if (platform.checks.isCbox) {
                    this.remote.focusArea(this.$header, {
                        area: 'button'
                    });
                }

                if (this.isMatrix()) {
                    this.$matrixTitleContainer.addClass('hidden');
                }

                this.closeSubmenu();
                this.startControlBarTimer();
            }
        });
    },

    /**
     * Bind Events.
     */
    bindEvents: function() {
        this.on('framebox.added', this.addTab.bind(this));
        this.on('framebox.removed', this.removeTab.bind(this));
        this.on('framebox.fullscreen.select-tab', this.selectTab.bind(this));
        this.on('framebox.hdmi-out-2.update', this.hdmiOut2StateHandler.bind(this));
        this.on('remote.framebox.focus', this.remoteFocusHandler.bind(this));
        this.on('remote.framebox.toggle', this.toggleFullscreen.bind(this));
        this.on('framebox.play-state-effect', this.playStateEffect.bind(this));
        this.on('framebox.in-background.change', _.debounce(this.backgroundFreezeHandler.bind(this), 350));
        this.on('framebox.controls.scrollable.changed', this.onScrollableChanged.bind(this));
        this.on('hdmi-out-2-mode.changed', this.hdmiOut2StateHandler.bind(this));
        this.on('freeze-state.update', _.debounce(this.backgroundFreezeHandler.bind(this), 350));
        this.on('activities.hide', _.throttle(this.updateFreezeState.bind(this, false), 350));
        this.on('activities.show', _.throttle(this.updateFreezeState.bind(this, true), 350));
        this.on('screensaver.open', this.screensaverMode.bind(this, true));
        this.on('screensaver.close', this.screensaverMode.bind(this, false));
        this.on('remote.focus.change', this.focusChangeHandler.bind(this));
        this.on('framebox-input.focused', this.handleInputFocus.bind(this));
        this.on('framebox-input.unfocused', this.handleInputFocus.bind(this));
        this.on('control-bar-timer.start', this.startControlBarTimer.bind(this));
        this.on('control-center.opened', this.hideControlBar.bind(this, true));
        this.on('menu.opened', this.hideControlBar.bind(this, true));
        this.on('file-browser.opened', this.hideControlBar.bind(this, true));
        this.on('control-bar.hide', this.hideControlBar.bind(this));
        this.on('control-bar.show', this.showControlBar.bind(this));
        this.on('control-center.closed', this.showControlBar.bind(this));
        this.on('menu.closed', this.showControlBar.bind(this));
        this.on('file-browser.closed', this.showControlBar.bind(this));
        this.on('menu.opened', this.updateKeyboardButton.bind(this, false));
        this.on('file-browser.opened', this.updateKeyboardButton.bind(this, false));
        this.on('control-center.opened', this.updateKeyboardButton.bind(this, false));
        this.on('overlay.opened', this.updateKeyboardButton.bind(this, false));
        this.on('menu.opened', this.updateHdmiOut2.bind(this, false));
        this.on('file-browser.opened', this.updateHdmiOut2.bind(this, false));
        this.on('control-center.opened', this.updateHdmiOut2.bind(this, false));
        this.on('modal.opened', this.updateHdmiOut2.bind(this, false));
        this.on('overlay.opened', this.updateHdmiOut2.bind(this, false));
        this.on('matrix.station-status.update', this.handleMatrixStationUpdate.bind(this));
        this.on('webconference.screenshare.state.change', this.webRtcOutHandler.bind(this, this.options.frameBoxes[this.selectedTab.index].webRtcOut));
        this.on('framebox.transparent.update', this.transparentStateHandler.bind(this));
        this.on('framebox.wipe-out.update', this.wipeOutStateHandler.bind(this));
        this.on('main-menu.show-sources-menu', this.updateNoSourcesMenu.bind(this));

        LayoutManager.on('window.resize', this.onWindowResizeHandler.bind(this));
    },

    /**
     * Bind standard DOM Events.
     */
    bindDOMEvents: function() {
        // HammerJS Events
        // Always first unbind to prevent multiple bindings
        this.hammer.off('doubletap', this.onDoubleTap.bind(this));
        this.hammer.off('singletap', this.onTapHandler.bind(this));

        this.hammer.on('doubletap', this.onDoubleTap.bind(this));
        this.hammer.on('singletap', this.onTapHandler.bind(this));

        this.bindControlBarDOMEvents();
    },

    /**
     * Bind click/touch events for control bar.
     */
    bindControlBarDOMEvents: function() {
        this.$el.on('click', '.framebox-option-toggle-fullscreen', this.closeFullscreen.bind(this));
        this.$el.on('click', '.framebox-tabs #framebox-control-bar-title-container', this.selectTabHandler.bind(this));
        this.$el.on('click', '.framebox-option-close', this.closeFrameBox.bind(this));
        this.$el.on('click', '.framebox-option-toggle-hdmi-out-2', this.toggleHdmiOut2.bind(this));
        this.$el.on('click', '#framebox-control-bar-submenu-close-container', this.closeSubmenu.bind(this));
    },

    /**
     * Bind all remote events for control bar interaction.
     */
    bindRemoteEvents: function() {
        this.on('remote.zoom-in.keydown', this.onRemoteKeyDown.bind(this, 'zoom-in'));
        this.on('remote.zoom-out.keydown', this.onRemoteKeyDown.bind(this, 'zoom-out'));
        this.on('remote.focus-in.keydown', this.onRemoteKeyDown.bind(this, 'focus-in'));
        this.on('remote.focus-out.keydown', this.onRemoteKeyDown.bind(this, 'focus-out'));
        this.on('remote.zoom-in.keyup', this.onRemoteKeyUp.bind(this, 'zoom-in'));
        this.on('remote.zoom-out.keyup', this.onRemoteKeyUp.bind(this, 'zoom-out'));
        this.on('remote.focus-in.keyup', this.onRemoteKeyUp.bind(this, 'focus-in'));
        this.on('remote.focus-out.keyup', this.onRemoteKeyUp.bind(this, 'focus-out'));
        this.on('remote.preset', this.onRemotePreset.bind(this, 'preset'));
    },

    /**
     * Bind all events (click, touch, key and remote) for framebox interactions.
     */
    bindFrameboxDOMEvents: function() {
        this.unbindFrameboxDOMEvents();

        this.bindFrameboxKeyEvents();
        // Do not bind touch-events on Window-tablets :), they also trigger mouse-events on touch gesture.
        if (platform.checks.isEdge) {
            this.$el.on('mousedown.ui mouseup.ui', this.onMouseEventsHandler.bind(this));
        } else if (platform.checks.isAndroid) { // Since we support touchevents we only need touch event on android.
            this.$el.on('touchstart.ui touchend.ui', this.onMouseEventsHandler.bind(this));
        } else {
            this.$el.on('mousedown.ui mouseup.ui touchstart.ui touchend.ui touchmove.ui', this.onMouseEventsHandler.bind(this));
        }

        this.$el.on('click.standard', this.onClickHandler.bind(this));
        this.$el.on('normalized.mousewheel.up', this.onWheelUp.bind(this));
        this.$el.on('normalized.mousewheel.down', this.onWheelDown.bind(this));
        this.$el.on('mousemove.ui touchmove.ui', this.onMouseEventsHandler.bind(this));

        this.on('remote.left.keydown', this.onRemoteKeyDown.bind(this, 'left'));
        this.on('remote.right.keydown', this.onRemoteKeyDown.bind(this, 'right'));
        this.on('remote.up.keydown', this.onRemoteKeyDown.bind(this, 'up'));
        this.on('remote.down.keydown', this.onRemoteKeyDown.bind(this, 'down'));
        this.on('remote.left.keyup', this.onRemoteKeyUp.bind(this, 'left'));
        this.on('remote.right.keyup', this.onRemoteKeyUp.bind(this, 'right'));
        this.on('remote.up.keyup', this.onRemoteKeyUp.bind(this, 'up'));
        this.on('remote.down.keyup', this.onRemoteKeyUp.bind(this, 'down'));
        this.on('remote.enter.keydown', this.onRemoteKeyDown.bind(this, 'enter'));
        this.on('remote.enter.keyup', this.onRemoteKeyUp.bind(this, 'enter'));
        this.on('remote.tab.keydown', this.onRemoteKeyDown.bind(this, 'tab'));
        this.on('remote.tab.keyup', this.onRemoteKeyUp.bind(this, 'tab'));

        this.on('remote.pageUp.keydown', this.onRemoteKeyDown.bind(this, 'pageUp'));
        this.on('remote.pageDown.keydown', this.onRemoteKeyDown.bind(this, 'pageDown'));

        if (platform.checks.isTouchDevice) {
            this.hammer.on('pinch', _.throttle(this.onPinch.bind(this), 200));
            this.hammer.on('pinchin', _.throttle(this.onPinchIn.bind(this), 200));
            this.hammer.on('pinchout', _.throttle(this.onPinchOut.bind(this), 200));
            this.hammer.on('pinchend', this.onPinchEnd.bind(this));
            this.hammer.on('pinchcancel', this.onPinchCancel.bind(this));
            this.hammer.on('pandown', _.throttle(this.onPanDown.bind(this), 100));
            this.hammer.on('panup', _.throttle(this.onPanUp.bind(this), 100));
            this.hammer.on('panleft', _.throttle(this.onPanLeft.bind(this), 100));
            this.hammer.on('panright', _.throttle(this.onPanRight.bind(this), 100));
            this.hammer.on('panend', _.throttle(this.onPanEnd.bind(this), 100));
        }
    },

    /**
     * Bind key events for framebox interactions (Special events for the selected tab).
     * Events are triggered by remote, touch, click.
     */
    bindFrameboxKeyEvents: function() {
        if (this.selectedTab) {
            this.unbindFrameboxKeyEvents();

            this.$(document).on('keydown.framebox-fullscreen-' + this.selectedTab.index, this.onKeyDown.bind(this));
            this.$(document).on('keyup.framebox-fullscreen-' + this.selectedTab.index, this.onKeyUp.bind(this));

            if (platform.checks.isAndroid && platform.checks.isChrome && platform.checks.isTouchDevice) {
                this.$(document).off('keypress.framebox-fullscreen-' + this.selectedTab.index, this.onKeyPress.bind(this));
            }

            // Special characters in firefox OS (e.g. Norwegian ø, æ, å)
            // See https://www.fxsitecompat.com/en-CA/docs/2018/keydown-and-keyup-events-are-now-fired-during-ime-composition/
            if (platform.checks.isFirefox) {
                this.$(document).on('compositionupdate.framebox-fullscreen-' + this.selectedTab.index, this.onKeyDown.bind(this));
                this.$(document).on('compositionend.framebox-fullscreen-' + this.selectedTab.index, this.onKeyUp.bind(this));
            }
        }
    },

    /**
     * Unbind all events to stop interaction with the framebox.
     * E.g. freeze mode gets enabled, overlay is opened.
     */
    unbindFrameboxDOMEvents: function() {
        this.unbindFrameboxKeyEvents();

        this.$el.off('click.standard');
        this.$el.off('normalized.mousewheel.up');
        this.$el.off('normalized.mousewheel.down');
        this.$el.off('mousemove.ui');
        this.$el.off('mousedown.ui mouseup.ui touchstart.ui touchend.ui');

        this.off('remote.left.keydown');
        this.off('remote.right.keydown');
        this.off('remote.up.keydown');
        this.off('remote.down.keydown');
        this.off('remote.enter.keydown');
        this.off('remote.pageUp.keydown');
        this.off('remote.pageDown.keydown');
        this.off('remote.left.keyup');
        this.off('remote.right.keyup');
        this.off('remote.up.keyup');
        this.off('remote.down.keyup');
        this.off('remote.enter.keyup');
        this.off('remote.tab.keydown');
        this.off('remote.tab.keyup');

        if (this.hammer) {
            this.hammer.off('pinch');
            this.hammer.off('pinchin');
            this.hammer.off('pinchout');
            this.hammer.off('pinchend');
            this.hammer.off('pinchcancel');
            this.hammer.off('pandown');
            this.hammer.off('panup');
            this.hammer.off('panleft');
            this.hammer.off('panright');
            this.hammer.off('panend');
        }
    },

    /**
     * Unbind key events to stop framebox interactions.
     * Avoid that two events are triggered at the same time
     * (e.g. control bar event and framebox event at the same time)
     */
    unbindFrameboxKeyEvents: function() {
        if (this.selectedTab) {
            this.$(document).off('keyup.framebox-fullscreen-' + this.selectedTab.index);
            this.$(document).off('keydown.framebox-fullscreen-' + this.selectedTab.index);
            this.$(document).off('keypress.framebox-fullscreen-' + this.selectedTab.index);
            this.$(document).off('compositionupdate.framebox-fullscreen-' + this.selectedTab.index);
            this.$(document).off('compositionend.framebox-fullscreen-' + this.selectedTab.index);
        }
    },

    /**
     * Called initially to create all current tabs of the control bar in fullscreen mode.
     */
    createControlBarTabs: function() {
        this.options.frameBoxes.forEach(this.addTab.bind(this));

        // Run tab selection only after creating all tabs to get rid of undesired behaviour
        this.options.frameBoxes.forEach(function(frameBox) {
            if (frameBox.isFullscreen) {
                this.selectTab(frameBox.index, true);
            }
        }.bind(this));
    },

    /**
     * Add a newly created tab at the right index to the control bar.
     *
     * @param {Object} frameBox
     * @param {Number} frameBox.index
     */
    addTab: function(frameBox) {
        var $tab = this.createTab(frameBox);
        var $tabs = this.$tabsContainer.children();
        var $before = $tabs.eq(frameBox.index);

        if (frameBox.index >= $tabs.size()) {
            this.$tabsContainer.append($tab);
        } else {
            $tab.insertBefore($before);
        }

        if (frameBox.contentType === 'matrixControl') {
            this.selectTab(frameBox.index, false, true);
        }
    },

    /**
     * Create a new tab for the control bar for specific framebox type.
     *
     * @param {Object} frameBox Options to create framebox controls for specific type.
     * @returns {Object} $tab newly created tab
     */
    createTab: function(frameBox) {
        var $tab;
        var componentId = this.createComponent({
            type: 'FrameboxControlBar',
            frameboxComponent: frameBox.component,
            index: frameBox.index,
            appId: frameBox.appId,
            title: frameBox.titleKey,
            titleIcon: frameBox.titleIconKey,
            frameBoxOptions: frameBox.options,
            isFullscreen: true,
            $actionEl: this.$el
        });

        $tab = this.app.getComponent(componentId).$el.find('#framebox-control-bar-item');

        // Update framebox. Change framebox title to matrix station name.
        if (frameBox.isMatrix) {
            this.emit('framebox.control-bar.update.' + frameBox.index, frameBox);
        }

        this.tabs[frameBox.index] = {
            index: frameBox.index,
            appId: frameBox.appId,
            title: frameBox.titleKey,
            component: frameBox.component,
            componentId: _.uniqueId('frameBoxFullscreen' + frameBox.contentType),
            $el: $tab,
            options: {}
        };

        return $tab;
    },

    /**
     * Remove tab from control bar.
     *
     * @param {Object} frameBox Options to create framebox controls for specific type.
     */
    removeTab: function(frameBox) {
        var tab = this.tabs[frameBox.index];
        var $tab = this.$tabsContainer.find('li[data-index="' + frameBox.index + '"]');

        if (tab && tab.componentId) {
            this.app.removeComponent(tab.componentId);
        }
        $tab.remove();
    },

    /**
     * Handle selecting a tab from control bar.
     *
     * @param {Object} event
     */
    selectTabHandler: function(event) {
        var $tab = this.$(event.currentTarget);
        var index = $tab.data('index');

        this.selectTab(index, false);
        this.openFullscreen();
    },

    /**
     *
     * Select tab from the control bar, deselect old one.
     *
     * @param {Number} index Framebox index for selecting tab.
     * @param init true/false
     * @param forced true/false
     */
    selectTab: function(index, init, forced) {
        var animationTimeout = init ? 0 : 200;

        this.frameBoxService.setActiveFrameBox(index);
        this.startControlBarTimer();

        // RELEASE-2411 - UI reloads when calling a window already closed.
        // Framebox does not extists anymore.
        if (!this.options.frameBoxes[index] || !this.tabs[index]) {
            return;
        }

        // Tab already selected.
        if (this.selectedTab && index === this.selectedTab.index && !forced) {
            return;
        }

        if (this.selectedTab) {
            this.off('framebox.change.' + this.selectedTab.index);
            this.unbindFrameboxKeyEvents();
        }

        var tab = this.tabs[index];
        var $tab = tab.$el;
        var $tabList = this.$tabsContainer.find('li');

        this.selectedTab = tab;
        this.frameBoxService.setFullscreenBox(this.selectedTab);

        this.$el.attr('data-selected-index', index);
        this.$el.attr('data-content-type', this.frameBoxService.contentTypeMapping(this.selectedTab.component));

        this.emit('framebox.tab.changed', {
            index: this.selectedTab.index
        });

        $tabList.removeClass('is-brain-mode');
        $tabList.removeClass('has-controls');

        $tab.addClass('is-brain-mode');

        setTimeout(function() {
            // RELEASE-2876
            if (this.selectedTab.component !== tab.component) {
                return;
            }

            $tab.addClass('has-controls');
            this.emit('reinit.scrolling.' + this.selectedTab.index);

            this.remote.focusArea(this.$header, {
                area: 'button'
            });
        }.bind(this), animationTimeout);

        this.emit('framebox.mode.brain', {
            index: this.selectedTab.index
        });

        this.onChangeBrainMode({
            index: this.selectedTab.index
        });

        this.bindFrameboxKeyEvents();

        // Show Matrix title when control bar is hidden
        if (this.isMatrix()) {
            if (!this.matrixConfigs) {
                this.matrixConfigs = this.getService('MatrixConfigs');
            }

            var station = this.matrixConfigs.getStationConfig(this.options.frameBoxes[index].options.DeviceName);

            // RELEASE-2714
            if (station) {
                this.$matrixTitleSymbol.css('background-color', station.color);
                this.$matrixTitle.html(station.name);
            }

            this.$matrixTitleSymbol.addClass('is-matrix');
            this.$header.addClass('is-matrix');
        } else {
            this.$matrixTitleContainer.addClass('hidden');
            this.$header.removeClass('is-matrix');
        }

        if (!platform.checks.isCbox && platform.checks.isTouchDevice) {
            this.emit('framebox.controls.check', {
                isScrollable: this.controlsScrollable,
                index: this.selectedTab.index
            });
        }

        this.hdmiOut2StateHandler({
            index: this.selectedTab.index,
            isHdmiOut2: this.frameBoxService.isHdmiOut2(this.selectedTab.index)
        });

        this.transparentStateHandler({
            index: this.selectedTab.index,
            transparent: this.frameBoxService.isTransparent(this.selectedTab.index)
        });

        this.wipeOutStateHandler({
            index: this.selectedTab.index,
            wipeOut: this.frameBoxService.isWipedOut(this.selectedTab.index)
        });

        this.on('framebox.change.' + this.selectedTab.index, this.webRtcOutHandler.bind(this));
        this.webRtcOutHandler(this.options.frameBoxes[index]);

        this.updateKeyboardButton(this.hasKeyboardButton());

        this.emit('active-framebox.changed', this.selectedTab.index, this.selectedTab.component);
    },

    /**
     * Update WebRtcOut: When framebox is shared (Zoom or WebRTC), highlight window.
     *
     * @param webRtcOut 0 === not shared, 1 === shared
     */
    webRtcOutHandler: function(framebox) {
        if (framebox.isFullscreen && framebox.webRtcOut) {
            this.$el.addClass('webRtcOut');

            return;
        }

        this.$el.removeClass('webRtcOut');
    },

    /**
     * If a framebox changed mode to brain mode set the others to pinky mode.
     *
     * @param options.index Index of framebox in brain mode.
     */
    onChangeBrainMode: function(options) {
        this.options.frameBoxes.forEach(function(frameBox) {
            if (frameBox.index !== options.index) {
                this.emit('framebox.mode.pinky', {
                    index: frameBox.index
                });
            }
        }.bind(this));
    },

    /**
     * Handle framebox input field focus (url field, page count field).
     *
     * @param index - framebox index
     * @param focused - url input focused
     */
    handleInputFocus: function(index, focused) {
        if (!focused) {
            this.preventKeyEvents = false;
            this.inputFocused = false;
            this.startControlBarTimer();

            return;
        }

        if (this.controlBarVisibility.getState() === visibilityStates.visible && index === this.selectedTab.index) {
            this.inputFocused = true;
            this.preventKeyEvents = true;
        }
    },

    /**
     * Switch icon depending on hdmi out 2 (aux) state.
     */
    hdmiOut2StateHandler: function() {
        this.checkForBackdrop();
        this.hasHdmiOut2Button();
    },

    /**
     * Check if framebox backdrop should be shown (in Moderator mode) and update.
     */
    checkForBackdrop: function() {
        this.updateBackdrop(false, 0);
        this.updateBackdrop(false, 1);
        this.updateBackdrop(false, 2);
        this.updateBackdrop(false, 3);

        if (this.outputService.isModerator()) {
            this.updateBackdrop(!this.frameBoxService.isHdmiOut2(this.selectedTab.index), this.selectedTab.index);
        }
    },

    /**
     * Show HDMI Out 2 Icon (Aux) if content mode is set and content recording mode is 'aux'.
     */
    hasHdmiOut2Button: function() {
        var isHdmiOut2 = this.frameBoxService.isHdmiOut2(this.selectedTab.index);

        if (this.outputService.isOverrideMirror()) {
            this.updateHdmiOut2(false);

            return;
        }

        if (!this.outputService.isContent()) {
            return;
        }

        if (isHdmiOut2
            && this.outputService.contentModeRecording.currentState === 'aux'
            && !this.overlayHandlerService.hasOpenOverlay()
        ) {
            this.updateHdmiOut2(true);
        } else {
            this.updateHdmiOut2(false);
        }
    },

    /**
     * Show/hide framebox backdrop.
     *
     * @param show true/false
     * @param index framebox index to update
     */
    updateBackdrop: function(show, index) {
        if (show) {
            this.$el.find('#framebox-backdrop-fullscreen-' + index).show();
        } else {
            this.$el.find('#framebox-backdrop-fullscreen-' + index).hide();
        }
    },

    /**
     * Handle freeze mode ON/OF and foreground/background handling
     * (E.g. modal, overlay, etc. is opened).
     */
    backgroundFreezeHandler: function() {
        if (this.screensaverService.isScreensaverActiveUI() || this.screensaverService.isScreensaverActive()) {
            return;
        }

        if (this.frameBoxService.isInBackground || this.freezeService.isFreeze()) {
            if (this.activeState.getState() === activeStates.active) {
                this.activeState.changeState(activeStates.inactive);
            }
        } else if (this.frameBoxService.getFullscreenBox()) {
            if (this.activeState.getState() === activeStates.inactive) {
                this.activeState.changeState(activeStates.active);
            } else if (!this.frameBoxService.isInBackground && !this.freezeService.isFreeze()) {
                // If framebox is already active but background state changed and is in foreground show the control bar, except freeze mode is active
                this.showControlBar();
            }
        }
    },

    /**
     * Handle remote focus change and clear timout to avoid hiding the control bar
     * while interacting with control bar.
     *
     * @param data.el Focused element
     */
    focusChangeHandler: function(data) {
        if ($(data.el).hasClass('framebox-bar-item')) {
            this.startControlBarTimer();
        }
    },

    /**
     * Handle remote control focus.
     *
     * @param data
     */
    remoteFocusHandler: function(data) {
        var tab = this.tabs[data.index];

        if (this.freezeService.isFreeze()) {
            this.handleFreeze();

            return;
        } else if (!tab || this.activeState.getState() === activeStates.inactive) {
            return;
        }

        this.hdmiOut2StateHandler({
            index: data.index,
            isHdmiOut2: this.frameBoxService.isHdmiOut2(data.index)
        });

        if (this.selectedTab.index === data.index) {
            this.$el.find('.browser-smartphone-input-helper').blur().prop('disabled', true);

            if (this.activeState.getState() === activeStates.inactive) {
                return;
            }

            if (this.controlBarVisibility.getState() === visibilityStates.hidden) {
                this.showControlBar();
            } else {
                this.hideControlBar();
            }
        } else {
            this.selectTab(data.index);
            this.openFullscreen();
            this.showControlBar();
        }
    },

    /**
     * Handles behavior if freeze mode is ON and a tab or remote event is fired.
     * Show controls and restart timer so that the control bar does not disappear to early while interacting.
     */
    handleFreeze: function() {
        this.emit('fullscreen.framebox.standard.controls.show');
        this.emit('show.ui');

        this.startControlBarTimer();
    },

    /**
     * Handles screensaver mode and hides control bar.
     */
    screensaverMode: function(opened) {
        this.screensaverService.getScreenSaverSettings()
            .then(settings => {
                if (settings.interactiveMode) {
                    if (!opened) {
                        this.app.removeComponent('ScreensaverEnd');
                        this.updateKeyboardButton(this.hasKeyboardButton());
                    }

                    return;
                }

                if (opened) {
                    if (this.activeState.getState() === activeStates.active) {
                        this.activeState.changeState(activeStates.inactive);
                    }
                } else if (this.activeState.getState() === activeStates.inactive) {
                    this.activeState.changeState(activeStates.active);
                }
            });
    },

    /**
     * Send an event to toggle hdmi out 2 framebox.
     */
    toggleHdmiOut2: function() {
        this.emit('framebox.toggle.hdmi-out-2', { index: this.selectedTab.index });
    },

    /**
     * Send a close framebox event.
     */
    closeFrameBox: function() {
        var options = this.options.frameBoxes[this.selectedTab.index];

        if (options.customClose) {
            this.emit('framebox.close.' + options.contentType, { index: this.selectedTab.index });

            return;
        }

        this.emit('framebox.close', { index: this.selectedTab.index });
    },

    /**
     * Call event to close submenu.
     */
    closeSubmenu: function() {
        this.emit('framebox.submenu.close');
    },

    /**
     * Close Fullscreen.
     *
     * @param {Object} data.index Frameox index.
     */
    toggleFullscreen: function(data) {
        if (data.index === this.selectedTab.index) {
            this.closeFullscreen();
        } else {
            this.closeFullscreen();

            if (this.tabs[data.index]) {
                this.selectTab(data.index);
            }
        }
    },

    /**
     * Send an event to close the fullscreen mode.
     */
    closeFullscreen: function() {
        if (this.screensaverService.isScreensaverActiveUI() || this.screensaverService.isScreensaverActive()
            || this.stationService.getLimitedAccessStatus()) {
            return;
        }

        this.emit('framebox.fullscreen.close', { index: this.selectedTab.index });

        if (!platform.checks.isCbox && platform.checks.isTouchDevice) {
            this.emit('framebox.controls.check', {
                isScrollable: this.controlsScrollable,
                index: this.selectedTab.index
            });
        }
    },

    /**
     * Send an event to open the fullscreen mode.
     */
    openFullscreen: function() {
        this.emit('framebox.fullscreen.open', { index: this.selectedTab.index });

        if (!platform.checks.isCbox && platform.checks.isTouchDevice) {
            this.emit('framebox.controls.check', {
                isScrollable: this.controlsScrollable,
                index: this.selectedTab.index
            });
        }
    },

    /**
     * Plays the State effect, this will open a modal with a icon and a animation.
     * E.g. freeze, VZ states,...
     *
     * @param {object} options
     */
    playStateEffect: function(options) {
        var className = options.className;

        if (this.lastStateClassName) {
            this.$stateEffect
                .removeClass('play-framebox-state-effect')
                .removeClass(this.lastStateClassName);
        }

        this.lastStateClassName = className;
        this.$stateEffect.show()
            .removeClass('play-framebox-state-effect')
            .addClass(className);

        // Wait for browser
        setTimeout(function() {
            this.$stateEffect.addClass('play-framebox-state-effect');
        }.bind(this), 100);

        this.$stateEffect.one('transitionend MSTransitionEnd webkitTransitionEnd oTransitionEnd', function() {
            this.$stateEffect
                .removeClass('play-framebox-state-effect')
                .removeClass(this.lastStateClassName);
        }.bind(this));
    },

    /**
     * Show or hide HDMI Out 2 Button.
     *
     * @param show true/false
     */
    updateHdmiOut2: function(show) {
        if (show) {
            this.$hdmiOut2Button.removeClass('hidden');
        } else {
            this.$hdmiOut2Button.addClass('hidden');
        }
    },

    /**
     * Show a small freeze icon if user wants to interact but freeze state is active.
     * @param show
     */
    updateFreezeState: function(show) {
        if (!this.freezeService.isFreeze()) {
            this.$freezeState.hide();

            return;
        }

        // If freeze mode is on
        if (show && !this.freezeStateVisible) {
            this.$freezeState
                .stop()
                .fadeIn({
                    duration: 300,
                    complete: function() {
                        this.freezeStateVisible = true;
                    }.bind(this)
                });
            this.$freezeState.show();
        } else if (!show && this.freezeStateVisible) {
            this.$freezeState
                .stop()
                .fadeOut({
                    duration: 300,
                    complete: function() {
                        this.freezeStateVisible = false;
                    }.bind(this)
                });
            this.$freezeState.hide();
        }
    },

    /**
     * Checks if this framebox has an Open-Keyboard-Button.
     *
     * Show button on Touch-device with content-type "Office365"
     * or show button on IOS-Device with content-types which need a keyboard.
     *
     * @return {Boolean} true/false
     */
    hasKeyboardButton: function() {
        var activeFrameboxKey = this.frameBoxService.getActiveFrameBox();
        var fullscreenFramebox = this.options.frameBoxes[activeFrameboxKey];

        // Show button on Touch-device with content-type "Office365"
        // Or show button on IOS-Device with content-types which need a keyboard.
        if (fullscreenFramebox
            && -1 !== keyboardViews.indexOf(fullscreenFramebox.component)
            && (
                platform.checks.isIOS
                || (-1 !== contentTypeKeyboardEnabled.indexOf(fullscreenFramebox.contentType)
                && platform.checks.isTouchDevice)
            && !platform.checks.isWindowsOS
            )
        ) {
            this.$el.addClass('can-use-touch-keyboard');
            this.$el.on('click.virtual-keyboard', '.framebox-show-keyboard', this.handleOpenKeyboard.bind(this));

            return true;
        }

        this.$el.removeClass('can-use-touch-keyboard');
        this.$el.off('click.virtual-keyboard');

        return false;
    },

    /**
     * Show or hide Keyboard Button.
     *
     * @param show true/false
     */
    updateKeyboardButton: function(show) {
        if (show && !this.screensaverService.isScreensaverActiveUI() && !this.screensaverService.isScreensaverActive()) {
            this.$keyboardButton.removeClass('hidden');
        } else {
            this.$keyboardButton.addClass('hidden');
        }
    },

    /**
     * Open keyboard for different Devices.
     */
    handleOpenKeyboard: function() {
        this.keyboard.setManuallyOpened(true);

        if (platform.checks.isIOS) {
            this.keyboard.open(this.keyboard, 'ios', this.selectedTab.index);
        } else if (platform.checks.isAndroid) {
            this.keyboard.open(this.keyboard, 'android', this.selectedTab.index);
        } else {
            this.keyboard.open();
        }
    },

    /**
     * Handle window resizing.
     */
    onWindowResizeHandler: function() {
        setTimeout(function() {
            this.emit('reinit.scrolling.' + this.selectedTab.index);
        }.bind(this), 200);
    },

    /**
     * Called after controls are scrollable or not.
     *
     * @param {Object} data
     * @param {Number} data.index
     */
    onScrollableChanged: function(data) {
        if (this.selectedTab.index === data.index) {
            this.controlsScrollable = data.isScrollable;
        }
    },

    /**
     * Toggle Fullscreen on Double-Tap.
     *
     * @param {Object} event
     */
    onDoubleTap: function(event) {
        var $el = this.$(event.target);

        if ($el.closest('.framebox-header').length > 0) {
            return;
        }

        this.frameBoxService.supportsDoubleTap(this.selectedTab.index)
            .then(function(doubleTap) {
                if (doubleTap.supported) {
                // Singletap on windows chrome is recognized as double tap, therefore added a workaround.
                // TODO: Remove if bug is fixed in hammer.js library. --> Last check on May 2017.
                    if (platform.checks.isWindowsOS && platform.checks.isChrome && !platform.checks.isEdge && platform.checks.isTouchDevice) {
                        if (event.tapCount > 2 && event.type === 'doubletap') {
                            this.closeFullscreen();
                        }
                    } else {
                        this.closeFullscreen();
                    }
                }
            }.bind(this));
    },

    onRemoteKeyDown: function(action) {
        if (this.activeState.getState() === activeStates.inactive
            || this.controlBarVisibility.getState() === visibilityStates.visible
            || this.preventKeyEvents) {
            return;
        }

        this.emit('framebox.standard.' + action + '.keydown', {
            index: this.selectedTab.index,
            appId: this.selectedTab.appId
        });
    },

    onRemoteKeyUp: function(action) {
        if (this.activeState.getState() === activeStates.inactive
            || this.preventKeyEvents) {
            return;
        }

        this.emit('framebox.standard.' + action + '.keyup', {
            index: this.selectedTab.index,
            appId: this.selectedTab.appId
        });
    },

    onRemotePreset: function() {
        if (this.activeState.getState() === activeStates.inactive) {
            return;
        }

        this.emit('framebox.standard.preset', {
            index: this.selectedTab.index,
            appId: this.selectedTab.appId
        });
    },

    onKeyDown: function(event) {
        if (this.remoteService.isSpecialKey(event)) {
            return;
        }

        setTimeout(function() {
            if (this.activeState.getState() === activeStates.inactive
                || this.preventKeyEvents) {
                return;
            }

            event = this.fixAndroidEvent(event);

            // Prevent from sending arrow and enter key if framebox is in brain mode
            if (event.isOnScreenKeyboardEvent || !(this.controlBarVisibility.getState() === visibilityStates.visible
                && PREVENT_KEYCODES.indexOf(event.which) >= 0)) {
                this.emit('framebox.standard.keydown', event, this.selectedTab.index);
            }
        }.bind(this), 0);
    },

    onKeyUp: function(event) {
        if (this.activeState.getState() === activeStates.inactive
            || this.preventKeyEvents) {
            return;
        }

        setTimeout(function() {
            event = this.fixAndroidEvent(event);

            this.emit('framebox.standard.keyup', event, this.selectedTab.index);
        }.bind(this), 0);
    },

    onKeyPress: function(event) {
        if (this.activeState.getState() === activeStates.inactive
            || this.preventKeyEvents) {
            return;
        }

        this.emit('framebox.standard.keypress', event, this.selectedTab.index);
    },

    onMouseEventsHandler: function(event) {
        if (this.activeState.getState() === activeStates.inactive
            || $(event.target).closest('.framebox-fullscreen-header').length > 0) { // Click on header
            this.startControlBarTimer();

            return;
        }

        // Sometimes the event is undefined
        if (!event || !event.target || !event.target.parentElement) {
            return;
        }

        // RELEASE-2687: prevent sending mouseevent if show keyboard was clicked.
        if (event.target.parentElement.id !== 'framebox-show-keyboard') {
            this.emit('framebox.standard.mouseevent', event, this.selectedTab.index);
        }
    },

    onPanEnd: function(event) {
        if (this.activeState.getState() === activeStates.inactive) {
            return;
        }

        var direction;

        switch (event.direction) {
            case Hammer.DIRECTION_LEFT:
                direction = 'left';
                break;
            case Hammer.DIRECTION_RIGHT:
                direction = 'right';
                break;
            case Hammer.DIRECTION_UP:
                direction = 'up';
                break;
            case Hammer.DIRECTION_DOWN:
                direction = 'down';
                break;
        }

        if (direction) {
            this.emit('framebox.standard.pan' + direction + '-single', {
                index: this.selectedTab.index
            });
        }
    },

    onWheelUp: function() {
        if (this.activeState.getState() === activeStates.inactive) {
            return;
        }

        this.emit('framebox.standard.mousewheel-up', {
            index: this.selectedTab.index
        });
    },

    onWheelDown: function() {
        if (this.activeState.getState() === activeStates.inactive) {
            return;
        }

        this.emit('framebox.standard.mousewheel-down', {
            index: this.selectedTab.index
        });
    },

    onPanDown: function(event) {
        if (this.activeState.getState() === activeStates.inactive) {
            return;
        }

        this.emit('framebox.standard.pandown', {
            index: this.selectedTab.index,
            x: event.pointers.length > 0 ? event.pointers[0].x : 0,
            y: event.pointers.length > 0 ? event.pointers[0].y : 0
        });
    },

    onPanUp: function(event) {
        if (this.activeState.getState() === activeStates.inactive) {
            return;
        }

        this.emit('framebox.standard.panup', {
            index: this.selectedTab.index,
            x: event.pointers.length > 0 ? event.pointers[0].x : 0,
            y: event.pointers.length > 0 ? event.pointers[0].y : 0
        });
    },

    onPanLeft: function(event) {
        if (this.activeState.getState() === activeStates.inactive) {
            return;
        }

        this.emit('framebox.standard.panleft', {
            index: this.selectedTab.index,
            x: event.pointers.length > 0 ? event.pointers[0].x : 0,
            y: event.pointers.length > 0 ? event.pointers[0].y : 0
        });
    },

    onPanRight: function(event) {
        if (this.activeState.getState() === activeStates.inactive) {
            return;
        }

        this.emit('framebox.standard.panright', {
            index: this.selectedTab.index,
            x: event.pointers.length > 0 ? event.pointers[0].x : 0,
            y: event.pointers.length > 0 ? event.pointers[0].y : 0
        });
    },

    onPinch: function(event) {
        if (this.activeState.getState() === activeStates.inactive) {
            return;
        }

        this.emit('framebox.standard.pinch', {
            index: this.selectedTab.index,
            appId: this.selectedTab.appId,
            event: event
        });
    },

    onPinchIn: function(event) {
        if (this.activeState.getState() === activeStates.inactive) {
            return;
        }

        this.emit('framebox.standard.pinchin', {
            index: this.selectedTab.index,
            appId: this.selectedTab.appId,
            event: event
        });
    },

    onPinchOut: function(event) {
        if (this.activeState.getState() === activeStates.inactive) {
            return;
        }

        this.emit('framebox.standard.pinchout', {
            index: this.selectedTab.index,
            appId: this.selectedTab.appId,
            event: event
        });
    },

    onPinchEnd: function(event) {
        if (this.activeState.getState() === activeStates.inactive) {
            return;
        }

        this.emit('framebox.standard.pinchend', {
            index: this.selectedTab.index,
            appId: this.selectedTab.appId,
            event: event
        });
    },

    onPinchCancel: function(event) {
        if (this.activeState.getState() === activeStates.inactive) {
            return;
        }

        this.emit('framebox.standard.pinchcancel', {
            index: this.selectedTab.index,
            appId: this.selectedTab.appId,
            event: event
        });
    },

    onClickHandler: function(event) {
        if (this.activeState.getState() === activeStates.inactive) {
            return;
        }

        var $el = this.$(event.target);

        if ($el.is('.has-action')) {
            this.emit('framebox.standard.click', {
                index: this.selectedTab.index,
                appId: this.selectedTab.appId
            });
        }
    },

    onTapHandler: function(event) {
        if (this.freezeService.isFreeze()) {
            this.handleFreeze();

            return;
        } else if (this.activeState.getState() === activeStates.inactive) {
            return;
        }

        var $el = this.$(event.target);

        if ($el.is('.has-action') && this.controlBarVisibility.getState() === visibilityStates.visible) {
            this.emit('framebox.standard.tap', {
                index: this.selectedTab.index,
                appId: this.selectedTab.appId,
                event: event
            });

            // RELEASE-3776, RELEASE-3747: Keyboard issues
            this.emit('active-framebox.changed', this.selectedTab.index, this.selectedTab.component, true);
        }

        this.showControlBar();
    },

    /**
     * Check if selected tab is a matrix tab.
     */
    isMatrix: function() {
        return this.selectedTab.component === 'FrameBoxMatrix' || this.frameboxComponent === 'FrameBoxMatrixGroupwork';
    },

    /**
     * Show control bar.
     */
    showControlBar: function() {
        if (this.screensaverService.isScreensaverActiveUI() || this.screensaverService.isScreensaverActive()
            || this.stationService.getLockStatus() || this.annotationService.isMagicPenActive()
            || this.frameBoxService.isInBackground || this.freezeService.isFreeze()) {
            return;
        }

        if (this.controlBarVisibility.getState() === visibilityStates.hidden) {
            this.controlBarVisibility.changeState(visibilityStates.visible);
        }
    },

    /**
     * Hide control bar.
     */
    hideControlBar: function(ignorePinned) {
        if ((!ignorePinned && !this.freezeService.isFreeze() && this.frameBoxService.isControlBarPinned()) || this.inputFocused) {
            return;
        }

        if (this.controlBarVisibility.getState() === visibilityStates.visible) {
            this.controlBarVisibility.changeState(visibilityStates.hidden);
        }
    },

    /**
     * Update control bar if the status if this is a Matrix station and the status has changed.
     */
    handleMatrixStationUpdate: function() {
        if (this.stationService.getLockStatus()) {
            this.hideControlBar(true);
            this.unbindFrameboxDOMEvents();
        } else {
            this.showControlBar();
            this.bindFrameboxDOMEvents();
        }
    },

    /**
     * Start/Restart timer to hide the control bar after some time (TIMEOUT).
     */
    startControlBarTimer: function() {
        clearTimeout(this.timer);

        if (this.selectedTab && -1 !== componentControlBarDisabled.indexOf(this.selectedTab.component)
            || this.screensaverService.isScreensaverActiveUI() || this.screensaverService.isScreensaverActive()
            || this.stationService.getLockStatus()) {
            this.hideControlBar();

            return;
        }

        this.timer = setTimeout(function() {
            this.hideControlBar();
        }.bind(this), TIMEOUT);
    },

    /**
     * Stop timer to hide the control bar after some time (TIMEOUT).
     */
    stopControlBarTimer: function() {
        clearTimeout(this.timer);
        this.timer = null;

        this.emit('fullscreen.framebox.standard.controls.show');
        this.emit('activities.start');
        this.emit('show.ui');
    },

    /**
     * Destroy Fullscreen UI.
     */
    destroy: function() {
        this.frameBoxService.setFullscreenBox(null);

        this.stopControlBarTimer();
        this.unbindFrameboxDOMEvents();

        this.$(document).off('click.fullscreen');
        this.$(document).off('touchend.fullscreen');
        this.$(document).off('focusin.fullscreen');

        if (this.hammer) {
            this.hammer.off('doubletap');
            this.hammer.off('singletap');
        }
        this.removeAllComponents();
    },

    /**
     * Remove all created controls.
     */
    removeAllComponents: function() {
        var tab;
        var tabKeys = Object.keys(this.tabs);

        tabKeys.forEach(function(tabKey) {
            tab = this.tabs[tabKey];

            if (tab && tab.componentId) {
                this.app.removeComponent(tab.componentId);
            }
        }.bind(this));

        this.app.removeComponent('ScreensaverEnd');
    },

    /**
     * Android devices will return a wrong key-event on every key.
     * FIXME: @google keyCode on Key-Events.
     *
     * @param {jQuey.Event} event
     * @return {jQuey.Event}
     */
    fixAndroidEvent: function(event) {
        if (platform.checks.isAndroid && platform.checks.isChrome && platform.checks.isTouchDevice) {
            if (event.which === 229) {
                var value = event.originalEvent.target.value;
                var keyCode = this.keyboard.getKeyCode(value);
                var character = value.slice(0, 1);

                // RELEASE-3871
                if (event.type === 'keyup') {
                    event.originalEvent.target.value = '';
                }

                event = $.Event(
                    event.type,
                    {
                        which: keyCode, // 65
                        key: character, // A
                        keyCode: keyCode, // 65
                        isOnScreenKeyboardEvent: true,
                        originalEvent: {
                            repeat: false,
                            code: keyMapper.characterToKeyCharacter(character), // KeyA
                            key: character, // A
                            keyCode: keyCode, // 65
                            type: event.type,
                            which: keyCode // 65
                        }
                    }
                );
            }
        }

        return event;
    },

    /**
     * Check if a transparent background on remote browsers should be shown.
     *
     * @param {Object} options
     * @param {Number} options.index
     * @param {Boolean} options.transparent
     */
    transparentStateHandler: function(options) {
        if (platform.checks.isCbox || !this.selectedTab || this.selectedTab.index !== options.index) {
            return;
        }

        this.updateTransparentOverlay(options.transparent);
    },

    /**
     * Show a transparent background on remote browsers.
     * @param show true/false
     * @param index framebox index
     */
    updateTransparentOverlay: function(show) {
        if (show) {
            this.$el.find('#framebox-transparent-fullscreen').show();
        } else {
            this.$el.find('#framebox-transparent-fullscreen').hide();
        }
    },

    /**
     * Check if a black background on remote browsers should be shown.
     *
     * @param {Object} options
     * @param {Number} options.index
     * @param {Boolean} options.transparent
     */
    wipeOutStateHandler: function(options) {
        if (platform.checks.isCbox || !this.selectedTab || this.selectedTab.index !== options.index) {
            return;
        }

        this.updateWipeOutOverlay(options);
    },

    /**
     * Show a black background on remote browsers.
     * @param show true/false
     * @param index framebox index
     */
    updateWipeOutOverlay: function(options) {
        if (options.wipeOut) {
            this.$el.find('#framebox-wipe-out-fullscreen .wipe-out-text').html(this.frameBoxService.frameboxes.boxes[options.index].options.DeviceName);
            this.$el.find('#framebox-wipe-out-fullscreen').show();
        } else {
            this.$el.find('#framebox-wipe-out-fullscreen').hide();
        }
    },

    /**
     * If no sources menu is shown in the main menu, the tabs container must be wider.
     * @param sourcesMenuShown
     */
    updateNoSourcesMenu: function(sourcesMenuShown) {
        if (sourcesMenuShown) {
            this.$tabsContainer.removeClass('no-sources-menu');
        } else {
            this.$tabsContainer.addClass('no-sources-menu');
        }
    }
});
