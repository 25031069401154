const app = require('../../app');
const dualViewerModeTpl = require('./dual-viewer-mode.html');

app.component('DualViewerMode', {
    engine: 'vue',
    template: dualViewerModeTpl,
    components: {
        'viewer-live-stream': require('./viewer-live-stream/viewer-live-stream')
    },

    data: function() {
        return {
            isFullscreenMode: false,
            resolution: '720p',
            monitors: [
                { outputPort: 'hdmi1' },
                { outputPort: 'hdmi2' }]
        };
    },

    methods: {
        toggleFullscreen: function(fullscreenState) {
            this.isFullscreenMode = fullscreenState;
        },
        getViewerResolution: function() {
            this.frontendSettings
                .getSetting('viewerResolution')
                .then(function(viewerResolution) {
                    this.resolution = viewerResolution;
                }.bind(this));
        }
    },

    created: function() {
        this.frontendSettings = app.getService('FrontendSettings');
        this.getViewerResolution();
        app.on('frontend-settings.initialized', this.getViewerResolution.bind(this));
    }
});
