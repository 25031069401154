// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"record-stream-settings\">\n    <form id=\"record-stream-settings-form\">\n        <div class=\"settings-list\"\n             data-nav-area=\".btn-switch, .focusable:not(:disabled), .input:not([disabled])\"\n             data-nav-trapped>\n            <div class=\"row settings-list-row\"\n                 data-nav-area-default>\n                <div class=\"col-xs-8\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.pip_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":9,"column":51}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"pipEnabled\"\n                               id=\"pipEnabled\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div id=\"pip-settings-container\" hidden>\n                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div id=\"content-select\" class=\"hidden\"></div>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div id=\"size-select\" class=\"hidden\"></div>\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div id=\"position-select\"></div>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"pipLatency\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.pip_latency",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":72},"end":{"line":44,"column":100}}}))
    + "</label>\n                            <input type=\"text\"\n                                   class=\"input is-focusable\"\n                                   name=\"pipLatency\"\n                                   id=\"pipLatency\"\n                                   data-from=\"0\"\n                                   data-to=\"2000\"\n                                   data-validation=\"checkNumberFromTo\"\n                                   tabIndex=\"-1\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">\n                                    "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.wrong_value_between_0_2000_milliseconds",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":36},"end":{"line":55,"column":107}}}))
    + "\n                                </span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div id=\"stream-input-select\"></div>\n                    </div>\n                    <div class=\"col-xs-6\">\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"form-action-container\" class=\"form-primary-background\"></div>\n    </form>\n</div>\n";
},"useData":true});
