'use strict';

var app = require('../../../app');
var languageTpl = require('./language.hbs');

app.component('LanguageSelect', {
    template: languageTpl,

    /**
     * @method initialize
     */
    initialize: function() {
        this.languageService = this.getService('LanguageService');
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.bindDOMEvents();
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.$el.on('click', '.clickable', this.onClickHandler.bind(this));
    },

    /**
     * @method onClickHandler
     * @param  {jQuery.event} event
     */
    onClickHandler: function(event) {
        var $el = this.$(event.currentTarget);
        var action = $el.data('action');

        if (action) {
            this.languageService.setLanguage(action);
            window.location.reload();
        }
    }
});
