'use strict';

var app = require('../../app');

/**
 * All settings and translations only for cynap.
 */
app.service('Model-View', function() {
    return {
        name: 'CYNAP',
        ethernet1TitleKey: 'settings.menu_ethernet1',
        hdmi1ResolutionTitleKey: 'settings.hdmi1_output_resolution',
        hdmi1OutTitleKey: 'settings.hdmi_out1',
        splashScreenSettingsDefault: {
            enabled: false
        },
        appsSettingsDefault: {
            enabled: true
        },
        statusBarSettingsDefault: {
            lan1: true,
            lan2: true,
            wlan: true,
            ssid: true
        },

        getGeneralLanSelects: function() {
            return [
                {
                    text: 'settings.lan1',
                    value: 'lan1'
                },
                {
                    text: 'settings.lan2',
                    value: 'lan2'
                },
                {
                    text: 'settings.wlan',
                    value: 'wlan'
                }
            ];
        },

        getHDCPOutputTypes: function() {
            return [
                {
                    key: 'main-out',
                    text: 'settings.main_out_1',
                    value: 'mainOut',
                    defaultArea: true
                },
                {
                    key: 'aux-out',
                    text: 'settings.hdmi_out_2',
                    value: 'auxOut'
                },
                {
                    key: 'in1',
                    text: 'settings.in1',
                    value: 'in1'
                },
                {
                    key: 'in2',
                    text: 'settings.in2',
                    value: 'in2'
                }
            ];
        },

        getHDMIOutputResolutions: function() {
            return [
                {
                    text: '1080p60',
                    value: '1080p60'
                },
                {
                    text: '2160p30',
                    value: '2160p30'
                },
                {
                    text: '2160p60',
                    value: '2160p60'
                },
                {
                    text: 'settings.auto',
                    value: 'auto'
                }
            ];
        },

        getAirplayInterfaces: function() {
            return [
                {
                    text: 'settings.lan1',
                    value: 'lan1'
                },
                {
                    text: 'settings.lan2',
                    value: 'lan2'
                },
                {
                    text: 'settings.wlan',
                    value: 'wlan'
                }
            ];
        },

        getNetworkDeviceDiscovery: function() {
            return [
                {
                    text: 'settings.supported',
                    value: 'supported'
                },
                {
                    text: 'settings.not_supported_lan1',
                    value: 'notSupportedLan1'
                },
                {
                    text: 'settings.not_supported_lan2',
                    value: 'notSupportedLan2'
                },
                {
                    text: 'settings.not_supported_wlan',
                    value: 'notSupportedWlan'
                }
            ];
        },

        getWlanModes: function() {
            return [
                {
                    text: 'settings.infrastructure',
                    value: 'infrastructure'
                },
                {
                    text: 'settings.access_point',
                    value: 'access-point'
                },
                {
                    text: 'settings.off',
                    value: 'off'
                }
            ];
        },

        getPeripheralCommandProtocols: function() {
            return [
                {
                    text: 'settings.tcp',
                    value: 'tcp'
                },
                {
                    text: 'settings.udp',
                    value: 'udp'
                },
                {
                    text: 'settings.PJLink',
                    value: 'PJLink'
                },
                {
                    text: 'settings.wolfprot',
                    value: 'wolfprot'
                }
            ];
        }
    };
});
