// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"matrix-tools\" data-nav-area=\".focusable\">\n    <div class=\"matrix-row\" data-nav-area=\".focusable\">\n        <div class=\"matrix-tool border-right col-33 focusable\" data-action=\"clear-all\">\n            <span class=\"icon-trash2\"></span>\n        </div>\n        <div class=\"matrix-connection-format col-33 focusable\" data-action=\"set-connection\" data-connection=\"curveCardinal\">\n            <span class=\"icon-path-rounded\"></span>\n        </div>\n        <div class=\"matrix-connection-format col-33 focusable active\" data-action=\"set-connection\" data-connection=\"curveLinear\">\n            <span class=\"icon-path-straight\"></span>\n        </div>\n    </div>\n\n    <div class=\"matrix-row\" data-nav-area=\".focusable\">\n        <div class=\"matrix-tool col-50 focusable active\" data-action=\"set-form\" data-form=\"rectangle\">\n            <span class=\"icon-rectangle1\"></span>\n        </div>\n        <div class=\"matrix-tool col-50 focusable\" data-action=\"set-form\" data-form=\"circle\">\n            <span class=\"icon-elypse\"></span>\n        </div>\n    </div>\n\n    <div class=\"discovery-box-header\">\n        <div class=\"discovery-box-header-text\">\n            <span class=\"text\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"matrix_configurator.device_discovery",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":31},"end":{"line":25,"column":75}}}))
    + "</span>\n        </div>\n        <div class=\"discovery-box-header-icon refresh-discovery\" data-action=\"refresh\">\n            <span class=\"icon icon-device-discovery\"></span>\n        </div>\n    </div>\n</div>\n\n<div id=\"device-list\" class=\"device-list matrix-box-list\"></div>\n";
},"useData":true});
