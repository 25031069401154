// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row settings-list-primary clickable is-focusable\" data-pn=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"pn") || (depth0 != null ? lookupProperty(depth0,"pn") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pn","hash":{},"data":data,"loc":{"start":{"line":1,"column":71},"end":{"line":1,"column":79}}}) : helper)))
    + "\" data-title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":1,"column":93},"end":{"line":1,"column":104}}}) : helper)))
    + "\" data-type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"nodeType") || (depth0 != null ? lookupProperty(depth0,"nodeType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"nodeType","hash":{},"data":data,"loc":{"start":{"line":1,"column":117},"end":{"line":1,"column":131}}}) : helper)))
    + "\" tabindex=\"-1\">\n    <div class=\"col-xs-9\">\n        <span class=\"icon-"
    + alias4(((helper = (helper = lookupProperty(helpers,"ico") || (depth0 != null ? lookupProperty(depth0,"ico") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ico","hash":{},"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":35}}}) : helper)))
    + "_l\"></span>\n        <h2 class=\"has-icon\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":4,"column":29},"end":{"line":4,"column":40}}}) : helper)))
    + "</h2>\n    </div>\n    <div class=\"col-xs-3 setting-list-forward-icon-container\">\n        <span class=\"icon-arrow-forward pull-right\"></span>\n    </div>\n</div>\n";
},"useData":true});
