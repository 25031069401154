'use strict';

var app = require('../app');
var $ = require('jquery');
var StateMachine = require('./../state-machine');

/**
 * Streaming states
 */
var freezeState = {
    none: 'none',
    on: 'on',
    off: 'off'
};

/**
 * @type {object}
 */
var freezeMapping = {
    0: 'off',
    1: 'on'
};

/**
 * @type {object}
 */
var mappingCmd = {
    off: 'off',
    on: 'on',
    toggle: 'toggle'
};

app.service('FreezeService', function(app) {
    return {
        /**
         * @method initialize
         */
        initialize: function() {
            this.$main = $('#main');
            this.freeze = new StateMachine({
                context: this,
                state: freezeState.none,
                states: freezeState
            });

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.deviceService = app.getService('DeviceService');
                    this.annotationService = app.getService('AnnotationService');
                    this.updateHandler();
                    this.bindEvents();
                }.bind(this));

            this.addStateTransitions();

            app.emit('main-loop.fast.start', {
                id: 'freeze'
            });
        },

        /**
         * @method bindEvents
         */
        bindEvents: function() {
            app.on('remote.freeze', this.toggleFreeze.bind(this));
            app.on('main-loop.update', this.updateHandler.bind(this));
            app.on('main-loop.update.freeze', this.updateHandler.bind(this));
        },

        /**
         * @method updateHandler
         */
        updateHandler: function() {
            if (this.deviceService.isCboxProDualProjection()) {
                return;
            }

            this.deviceConnection
                .send('getMainFreeze')
                .then(function(data) {
                    var state = freezeMapping[data.freeze];

                    if (!!state && this.freeze.getState() !== freezeState[state]) {
                        this.freeze.changeState(freezeState[state]);

                        app.emit('freeze-state.update');
                    }
                }.bind(this));
        },

        /**
         * @method toggleFreeze
         */
        toggleFreeze: function() {
            this.setFreeze(mappingCmd.toggle);
        },

        /**
         * @deprecated
         * Because `isFreeze` is a better naming to return the state as boolean of the freeze-service.
         */
        getFreeze: function() {
            return this.isFreeze();
        },

        /**
         * Returns true when freeze is on.
         *
         * @return {Boolean}
         */
        isFreeze: function() {
            return this.freeze.getState() === freezeState.on;
        },

        /**
         * @method setFreeze
         * @param cmd
         */
        setFreeze: function(cmd) {
            if (this.deviceService.isCboxPure() || this.deviceService.isCboxPureMini()
                || this.deviceService.isCboxProDualProjection()) {
                return;
            }

            app.emit('main-loop.fast.start', {
                id: 'freeze'
            });

            return this.deviceConnection
                .send('setMainFreeze', {
                    freeze: cmd
                });
        },

        /**
         * @method addStateTransitions
         */
        addStateTransitions: function() {
            this.freeze.addTransitions({
                '> off': function() {
                    this.$main.removeClass('is-freezed');
                    app.emit('status-widget.item.remove', {
                        id: 'freeze'
                    });
                },

                '> on': function() {
                    this.$main.addClass('is-freezed');
                    app.emit('status-widget.item.append', {
                        id: 'freeze',
                        accessKey: 'Freeze',
                        options: {
                            icon: 'icon-v2-freeze',
                            clickable: false,
                            state: 'freeze'
                        }
                    });
                },
                'off > on': function() {
                    if (this.annotationService.isMagicPenActive()) {
                        return;
                    }

                    app.emit('modal.open', {
                        id: 'freeze-on'
                    });
                },
                'on > off': function() {
                    app.emit('modal.open', {
                        id: 'freeze-off'
                    });
                }
            });
        }
    };
});
