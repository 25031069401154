'use strict';

var certListItemTpl = require('./certificate-list-item.hbs');
var _ = require('lodash');
var $ = require('jquery');
var app = require('../../../../app');
var tpl = require('./wlan-settings.hbs');
var platform = require('../../../../../platform/platform');
var FormActionView = require('./../../form-action-view');
var i18n = require('i18next');
var visibleStates = {
    visible: 'visible',
    hidden: 'hidden'
};

var states = {
    disconnected: 'disconnected',
    connected: 'connected',
    scanning: 'scanning',
    authenticating: 'authenticating',
    failed: 'failed'
};

var ssidTypes = {
    essid: 'essid',
    bssid: 'bssid'
};

app.component('WlanSettings', {
    template: tpl,

    getAccessKey: function() {
        return {
            'roleName': 'WlanSettings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.init = false;
        this.emitChanges = false;
        this.hasChangePassword = false;
        this.tryToConnectTimeout = null;
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.storage = app.getService('DataStorage');
        this.deviceService = this.getService('DeviceService');
        this.certificateError = false;
        this.certList = null;
        this.resetCertList = null;

        this.connectionState = this.createStateMachine({
            state: states.disconnected,
            states: states
        });

        this.encryptionState = this.createStateMachine({
            state: visibleStates.hidden,
            states: visibleStates
        });

        this.certificateState = this.createStateMachine({
            state: visibleStates.hidden,
            states: visibleStates
        });

        this.usernameState = this.createStateMachine({
            state: visibleStates.visible,
            states: visibleStates
        });

        this.addStateTransitions();
    },

    serialize: function() {
        return {
            isCbox: platform.checks.isCbox
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.createEncryptSelect();
        this.createAuthMethodSelect();
        this.createPrioritySelect();
        this.initForm();
        this.initFormAction();
        this.loadNetworkSettings(false);
        this.updateHandler();
        this.loadDhcpSettings();
        this.enableBssid(this.form.get('useBssid').getValue());

        setTimeout(function() {
            this.emitChanges = true;

            if (this.options.ssid !== false) {
                this.handleChanges(true);
            }
        }.bind(this), 500);
    },

    storeSelectors: function() {
        this.$security = this.$el.find('#security');
        this.$usernameEl = this.$el.find('#other-username-container');
        this.$passwordEl = this.$el.find('#other-password-container');
        this.$certEl = this.$el.find('#certificate-container');
        this.$dnsEl = this.$el.find('#dns-input-container');
        this.$connectMsg = this.$el.find('#wlan-try-to-connect');
        this.$connectError = this.$el.find('#wlan-not-able-to-connect');
        this.$authEl = this.$el.find('#auth-container');
        this.$certList = this.$el.find('#certificate-list-items');
        this.$noEntry = this.$el.find('#certificate-list-no-entry');
        this.$certFileEl = this.$el.find('.certificate-file-container');
        this.$dhcpContainer = this.$el.find('.dhcp-container');
        this.$wlanInfraMiracastInfo = this.$el.find('#wlan-infrastructure-miracast-info');
    },

    bindDOMEvents: function() {
        this.form.get('useBssid').$el.on('change', this.handleSsidTypeChange.bind(this));
        this.form.get('password').$el.on('keydown', this.onPasswordChange.bind(this));
    },

    bindEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.form.get('encryption').$el.on('change', this.handleEncryptionChange.bind(this));
        this.form.get('password').$el.on('change', this.onPasswordChange.bind(this));
        this.form.get('dhcp').$el.on('change', this.manualDhcpChange.bind(this));
        this.form.get('certificate-mode').$el.on('change', this.onCertModeChanged.bind(this));
        this.on('main-loop.update', this.updateHandler.bind(this));
        this.on('wlan-mode.changed.infrastructure', this.loadNetworkSettings.bind(this));
        this.on('wlan-settings.changed.band', this.handleFormChange.bind(this));
        this.on('wlan.change.country', this.handleFormChange.bind(this));
        this.on('wlan-settings.store.settings', this.saveStorageSettings.bind(this));
        this.on('wlan-settings.action.view.open', this.openActionView.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
        this.$el.on('click', '.activate', this.activateCertificate.bind(this));
        this.$el.on('click', '.delete', this.deleteCertificate.bind(this));
        $('input[type=file]').on('change', this.verifyCertificate.bind(this));
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container-wlan-settings'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this),
                autoClose: false
            });
        }

        this.actionView.render();

        setTimeout(function() {
            this.actionView.disableCancelButton();
        }.bind(this), 350);
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#wlan-settings-form'),
            validationContainer: '.input-group'
        });
    },

    openActionView: function() {
        if ((this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini())
            && this.miracastEnabled && !this.miceEnabled) {
            this.$wlanInfraMiracastInfo.removeClass('hidden');
        } else {
            this.$wlanInfraMiracastInfo.addClass('hidden');
        }

        if (this.actionView) {
            this.actionView.open();
        } else {
            this.initFormAction();
        }
    },

    /**
     * Get storage settings.
     */
    getStorageSettings: function() {
        var useBssid = this.storage.get('wlan.useBssid');
        var priority = this.storage.get('wlan.priority');

        if (useBssid !== undefined) {
            this.form.get('useBssid').setValue(useBssid);
        }

        if (priority !== undefined) {
            this.form.get('priority').setValue(priority);
        }

        this.storage.remove('wlan.useBssid');
        this.storage.remove('wlan.priority');
    },

    /**
     * SAve storage settings.
     */
    saveStorageSettings: function() {
        this.storage.set('wlan.useBssid', this.form.get('useBssid').getValue());
        this.storage.set('wlan.priority', this.form.get('priority').getValue());
    },

    updateHandler: function() {
        this.deviceConnection
            .send('getWlanStatus')
            .then(this.updateWlanConnectionState.bind(this));

        if (this.form && this.form.get('dhcp').getValue()) {
            this.loadDhcpSettings();
        }
    },

    /**
     * Update WLAN connection state.
     *
     * @param data
     */
    updateWlanConnectionState: function(data) {
        var state = states[data.wlanStatus];

        if (state !== this.connectionState.getState()) {
            this.connectionState.changeState(state);
        }

        this.form.get('signalLevel').setValue(data.signalLevel + ' ' + i18n.t('settings.dbm'));
        this.form.get('counterConnectionLoss').setValue(data.counterApLoss);
        this.form.get('counterLowSignalLevel').setValue(data.counterSignalLevel);
    },

    /**
     * Verify certificate if file is uploaded.
     */
    verifyCertificate: function() {
        var file = this.form.get('certificate-file').getValue();
        var reader = new FileReader();

        if (file) {
            reader.onload = function(event) {
                this.deviceConnection
                    .send([
                        {
                            command: 'setWlanCertificateFile',
                            data: {
                                name: file.name,
                                file: event.target.result,
                                action: 'verify'
                            }
                        }
                    ]).then(function() {
                        this.$certFileEl.removeClass('is-invalid');
                        this.$certFileEl.addClass('is-valid');
                        this.certificateError = false;
                        this.checkActionForm();
                    }.bind(this)).fail(function() {
                        this.$certFileEl.removeClass('is-valid');
                        this.$certFileEl.addClass('is-invalid');
                        this.actionView.disableSubmitButton();
                        this.certificateError = true;
                    }.bind(this)) ;
            }.bind(this);

            reader.readAsArrayBuffer(file);
        }
    },

    /**
     * Update certificate list (delete/activate).
     */
    updateCertificates: function() {
        var change = false;

        _.each(this.certList, function(item) {
            if (item.delete) {
                this.deviceConnection.send([
                    {
                        command: 'setWlanCertificateFile',
                        data: {
                            name: item.name,
                            action: 'delete'
                        }
                    }
                ]);
                change = true;
            } else if (item.active) {
                this.deviceConnection.send([
                    {
                        command: 'setWlanCertificateFileName',
                        data: { name: item.name }
                    }
                ]);
                change = true;
            }
        }.bind(this));

        if (change) {
            this.reloadCertificateList();
        }
    },

    /**
     * Save certificate and update list when save is clicked.
     */
    saveCertificate: function() {
        var file = this.form.get('certificate-file').getValue();
        var reader = new FileReader();

        if (file) {
            reader.onload = function(event) {
                this.deviceConnection
                    .send([
                        {
                            command: 'setWlanCertificateFile',
                            data: {
                                name: file.name,
                                file: event.target.result,
                                action: 'save'
                            }
                        }
                    ]).then(function() {
                        this.reloadCertificateList();
                    }.bind(this));
            }.bind(this);

            reader.readAsArrayBuffer(file);

            this.form.get('certificate-file')
                .setValue(null);
        }
    },

    /**
     * Reload certificate list from Backend.
     */
    reloadCertificateList: function() {
        this.deviceConnection
            .send('getWlanCertificates')
            .then(function(certificates) {
                this.certList = certificates.certList;
                this.renderCertificateList();
            }.bind(this));
    },

    /**
     * Handle click on activate certificate.
     *
     * @param data Current event
     */
    activateCertificate: function(data) {
        _.each(this.certList, function(item) {
            if (item.name === data.currentTarget.name) {
                item.delete = false;
                item.active = true;
            } else {
                item.active = false;
            }
        });

        this.renderCertificateList();
        this.handleFormChange();
    },

    /**
     * Handle click on delete certificate.
     *
     * @param data Current event
     */
    deleteCertificate: function(data) {
        this.certList = _.each(this.certList, function(item) {
            if (item.name === data.currentTarget.name) {
                item.delete = !item.delete;
                item.active = false;
            }
        });

        this.renderCertificateList();
        this.handleFormChange();
    },

    updateForm: function() {
        var update = false;
        var oldUseBssid = this.form.get('useBssid').getValue();

        if (this.options.ssid) {
            this.formData.ssid = this.options.ssid;
            this.options.ssid = null;
            update = true;
        }

        if (this.options.bssid) {
            this.formData.bssid = this.options.bssid;
            this.options.bssid = null;
            update = true;
        }

        if (this.options.encryption) {
            this.formData.encryption = this.options.encryption;
            this.options.encryption = null;
            update = true;
        }

        this.form.setValues(this.formData);
        this.handleEncryptionChange();

        if (this.form.get('useBssid').getValue() !== oldUseBssid) {
            this.handleSsidTypeChange();
        }

        if (update) {
            this.enableBssid(this.form.get('useBssid').getValue());
            this.handleFormChange();
        }
    },

    /**
     * Enable/disable BSSID.
     *
     * @param enable true/false
     */
    enableBssid: function(enable) {
        if (enable) {
            this.form.get('bssid').enable();
        } else {
            this.form.get('bssid').disable();
        }
    },

    checkActionForm: function() {
        if (!this.certificateError) {
            if (this.form.validate()) {
                this.actionView.enableSubmitButton();
            } else {
                this.actionView.disableSubmitButton();
            }
        }
    },

    /**
     * Handle password change.
     */
    onPasswordChange: function() {
        this.hasChangePassword = true;
        this.actionView.enableCancelButton();
    },

    /**
     * Handle manual DHCP change.
     */
    manualDhcpChange: function() {
        var dhcp = this.form.get('dhcp').getValue();

        if (dhcp) {
            this.handleDhcpChange();
            this.actionView.enableSubmitButton();
        } else {
            this.$dhcpContainer.show();
            this.enableSettings();
            this.updateForm();
            this.checkActionForm();
        }
    },

    /**
     * Handle DHCP change.
     */
    handleDhcpChange: function() {
        var dhcp = this.form.get('dhcp').getValue();

        if (dhcp) {
            this.loadDhcpSettings();
            this.disableSettings();
        }
    },

    handleFormChange: function(data) {
        if (data && data.mode && data.mode === 'access-point') {
            return;
        }

        if (data && data.input && data.input.name === 'encryption') {
            this.handleEncryptionChange();
        }

        this.actionView.enableCancelButton();
        this.checkActionForm();
        this.actionView.open();

        if (this.emitChanges) {
            this.handleChanges(true);
        }
    },

    /**
     * Handle Encryption change.
     */
    handleEncryptionChange: function() {
        var type = this.form.get('encryption').getValue();

        if (type === 'wep' || type === 'wpa' || type === 'wpa2') {
            this.hideCertificate();
            this.hideUsernameField();
            this.showEncryptionSettings();

            if (type === 'wpa') {
                this.$el.find('#other-password-container').find('.input-info-message')[0].innerHTML = i18n.t('settings.error_messages.wrong_password_length_8_63');
            } else {
                this.$el.find('#other-password-container').find('.input-info-message')[0].innerHTML = i18n.t('error_messages.invalid_length');
            }
        } else if (type === 'wpa-enterprise' || type === 'wpa2-enterprise') {
            this.showCertificate();
            this.showUsernameField();
            this.showEncryptionSettings();
            this.$el.find('#other-password-container').find('.input-info-message')[0].innerHTML = i18n.t('error_messages.invalid_length');
        } else {
            this.hideCertificate();
            this.hideUsernameField();
            this.hideEncryptionSettings();
        }

        if (!platform.checks.isCbox && this.form.get('certificate-file')) {
            this.onCertModeChanged();
        }

        this.form.get('password').$el
            .attr('id', 'encrypt-password-' + type)
            .attr('data-pwd-type', type);
    },

    /**
     * Handle certificate mode change.
     */
    onCertModeChanged: function() {
        var certMode;

        if (!platform.checks.isCbox && this.form.get('certificate-file')) {
            certMode = this.form.get('certificate-mode').getValue();

            if (certMode) {
                this.form.get('certificate-file').enable();
            } else {
                this.form.get('certificate-file').disable();
            }
        }
    },

    /**
     * Load DHCP settings.
     */
    loadDhcpSettings: function() {
        var dhcp = this.form.get('dhcp').getValue();

        return this.deviceConnection.send([
            {
                command: 'getWlanSettingsIpAddress',
                data: { dynamic: dhcp }
            },
            {
                command: 'getWlanSettingsSubnetMask',
                data: { dynamic: dhcp }
            },
            {
                command: 'getWlanSettingsGateway',
                data: { dynamic: dhcp }
            },
            {
                command: 'getWlanSettingsDns',
                data: { dynamic: dhcp }
            },
            {
                command: 'getWlanSettingsDns2',
                data: { dynamic: dhcp }
            }
        ])
            .then(function(ipAddress, subnetMask, gatewayAddress, dnsAddress, dnsAddress2) {
                this.formData = {
                    ip: ipAddress.ipAddress,
                    subnet: subnetMask.subnetMask,
                    gateway: gatewayAddress.gatewayAddress,
                    dns: dnsAddress.dnsAddress === '' ? '0.0.0.0' : dnsAddress.dnsAddress,
                    dns2: dnsAddress2.dnsAddress === '' ? '0.0.0.0' : dnsAddress2.dnsAddress
                };

                if (!this.init) {
                    this.updateForm();
                } else {
                    if (ipAddress.ipAddress !== '0.0.0.0' && this.connectionState.getState() === states.connected && this.$dhcpContainer.css('display') === 'none') {
                        this.$dhcpContainer
                            .stop()
                            .slideDown(200);
                    }

                    this.updateForm();
                }

                this.init = true;

                this.checkActionForm();
            }.bind(this));
    },

    /**
     * Load network settings.
     *
     * @param reset true/false
     */
    loadNetworkSettings: function(reset) {
        var dhcp = this.form.get('dhcp').getValue();

        return this.deviceConnection.send([
            'getInfraWlanSsidType',
            'getInfraSsidName',
            'getInfraWlanBssid',
            'getWlanEncryptionStandard',
            'getWlanUsername',
            'getWlanCertificateMode',
            'getWlanDhcpSetting',
            'getWlanIpAddress',
            'getWlanSubnetMask',
            'getWlanGateway',
            {
                command: 'getWlanSettingsDns',
                data: { dynamic: dhcp }
            },
            {
                command: 'getWlanSettingsDns2',
                data: { dynamic: dhcp }
            },
            'getKeyInfrastructure',
            'getWlanAuthMethod',
            'getWlanAnonymousIdentity',
            'getWlanCertificates',
            'getWlanPriority',
            'getWlanSignalLevelLimit',
            'getMiracastState',
            'getMiceState'
        ])
            .then(function(ssidType, ssidName, bssidName, encryptionStandard, username, mode, dhcpSetting, ipAddress,
                subnetMask, gatewayAddress, dnsAddress, dnsAddress2, keyInfrastructure, authMethod,
                anonymousIdentity, certificates, wlanPriority, signalLevelLimit, miracastState, miceState) {
                this.formData = {
                    useBssid: ssidType.type === ssidTypes.essid ? 0 : 1,
                    ssid: ssidName.ssidName,
                    bssid: bssidName.bssid,
                    encryption: encryptionStandard.encryptionStandard,
                    username: username.wlanUsername,
                    'certificate-mode': mode.mode,
                    password: keyInfrastructure.passwordInfrastructure,
                    dhcp: dhcpSetting.dhcpSetting,
                    ip: ipAddress.ipAddress,
                    subnet: subnetMask.subnetMask,
                    gateway: gatewayAddress.gatewayAddress,
                    dns: dnsAddress.dnsAddress === '' ? '0.0.0.0' : dnsAddress.dnsAddress,
                    dns2: dnsAddress2.dnsAddress === '' ? '0.0.0.0' : dnsAddress2.dnsAddress,
                    authMethod: authMethod.authMethod,
                    anonymous: anonymousIdentity.wlanAnonymousIdentity,
                    'priority': wlanPriority.priority,
                    signalLevelLimit: signalLevelLimit.signalLevel
                };

                this.miracastEnabled = !!miracastState.miracastState;
                this.miceEnabled = !!miceState.miceState;

                this.certList = certificates.certList;
                this.resetCertList = this.certList;
                this.renderCertificateList();

                this.updateForm();

                if (!this.init) {
                    this.bindDOMEvents();
                    this.bindEvents();
                }

                if (reset) {
                    this.manualDhcpChange();
                    this.manualDhcpChange();
                    this.actionView.disableCancelButton();
                    this.handleChanges(false);
                } else {
                    this.handleDhcpChange();
                }

                this.getStorageSettings();

                this.checkActionForm();

                this.init = true;
            }.bind(this));
    },

    /**
     * Handle SSID type change.
     */
    handleSsidTypeChange: function() {
        var value = this.form.get('useBssid').getValue();

        this.emit('wlan.changed.usebssid', {
            useBssid: value
        });

        this.enableBssid(value);

        this.remote.update();
        this.checkActionForm();
        this.handleChanges(true);
    },

    saveHandler: function() {
        var data = this.form.serialize();

        this.emit('wlan.save');

        this.deviceConnection
            .send([
                {
                    command: 'setInfraWlanBssid',
                    data: { bssid: data.bssid }
                },
                {
                    command: 'setInfraSsidName',
                    data: { ssidName: data.ssid }
                },
                {
                    command: 'setInfraWlanSsidType',
                    data: { type: (data.useBssid ? ssidTypes.bssid : ssidTypes.essid) }
                },
                {
                    command: 'setWlanEncryptionStandard',
                    data: { encryptionStandard: data.encryption }
                },
                {
                    command: 'setWlanUsername',
                    data: { name: data.username }
                },
                {
                    command: 'setWlanCertificateMode',
                    data: { mode: data['certificate-mode'] }
                },
                {
                    command: 'setWlanDhcpSetting',
                    data: { dhcpSetting: data.dhcp }
                },
                {
                    command: 'setWlanIpAddress',
                    data: { ipAddress: data.ip }
                },
                {
                    command: 'setWlanSubnetMask',
                    data: { subnetMask: data.subnet }
                },
                {
                    command: 'setWlanGatewayAddress',
                    data: { gatewayAddress: data.gateway }
                },
                {
                    command: 'setWlanDnsAddress',
                    data: { dnsAddress: data.dns }
                },
                {
                    command: 'setWlanDnsAddress2',
                    data: { dnsAddress: data.dns2 }
                },
                {
                    command: 'setWlanAuthMethod',
                    data: { authMethod: data.authMethod }
                },
                {
                    command: 'setWlanAnonymousIdentity',
                    data: { name: data.anonymous }
                },
                {
                    command: 'setWlanPriority',
                    data: { priority: this.form.get('priority').getValue() }
                },
                {
                    command: 'setWlanSignalLevelLimit',
                    data: { signalLevel: data.signalLevelLimit }
                }
            ]).then(function() {
                this.emit('overlay.header.update', {
                    actionButtonKey: 'settings.action_button_saved',
                    actionBtnType: null,
                    actionBtnDelay: 2000,
                    actionBtnFadeout: 500
                });
            }.bind(this));

        this.updateCertificates();

        if (this.hasChangePassword && !/^[*]*$/.test(data.password)) {
            this.deviceConnection
                .send([
                    {
                        command: 'setKeyInfrastructure',
                        data: { wifiKey: data.password }
                    }
                ]);
        }

        if (!platform.checks.isCbox) {
            this.saveCertificate();
        }

        this.showTryToConnectMessage();

        setTimeout(function() {
            this.actionView.disableCancelButton();
        }.bind(this), 200);

        this.handleSettingsSave();
    },

    handleSettingsSave: function() {
        setTimeout(function() {
            this.deviceConnection
                .send('setWlanSettingsActivate');

            if (this.form.get('dhcp') && this.form.get('dhcp').getValue()) {
                this.$dhcpContainer
                    .stop()
                    .slideUp(200);
            }

            this.handleChanges(false);
        }.bind(this), 300);

        this.emit('overlay.remote.focus', true);
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });
    },

    /**
     * Show message, if try to reconnect.
     */
    showTryToConnectMessage: function() {
        this.$connectError.hide();
        this.$connectMsg
            .stop()
            .slideDown(200);

        this.tryToConnectTimeout = setTimeout(function() {
            this.$connectError.show();
            this.hideTryToConnectMessage();
        }.bind(this), 60000);
    },

    /**
     * Hide message, if try to reconnect.
     */
    hideTryToConnectMessage: function() {
        this.$connectMsg
            .stop()
            .slideUp(200);
    },

    /**
     * Calls on clicked the cancel button.
     */
    cancelHandler: function() {
        this.loadNetworkSettings(true);
        this.actionView.disableCancelButton();
        this.emit('wlan.cancel');

        this.handleChanges(false);
    },

    /**
     * Show encryption settings.
     */
    showEncryptionSettings: function() {
        if (this.encryptionState.getState() === visibleStates.hidden) {
            if (this.init) {
                this.$security
                    .stop()
                    .slideDown();
            } else {
                this.$security
                    .show();
            }

            this.encryptionState.changeState(visibleStates.visible);
        }
    },

    /**
     * Hide encryption settings.
     */
    hideEncryptionSettings: function() {
        if (this.encryptionState.getState() === visibleStates.visible) {
            this.$security
                .stop()
                .slideUp();

            this.encryptionState.changeState(visibleStates.hidden);
        }
    },

    /**
     * Show Username field.
     */
    showUsernameField: function() {
        this.$passwordEl.removeClass('no-padding');

        if (this.usernameState.getState() === visibleStates.hidden) {
            this.$usernameEl
                .stop()
                .show();

            this.usernameState.changeState(visibleStates.visible);
        }
    },

    /**
     * Hide Username field.
     */
    hideUsernameField: function() {
        this.$passwordEl.addClass('no-padding');

        if (this.usernameState.getState() === visibleStates.visible) {
            this.$usernameEl
                .stop()
                .hide();

            this.usernameState.changeState(visibleStates.hidden);
        }
    },

    /**
     * Show certificate.
     */
    showCertificate: function() {
        if (this.certificateState.getState() === visibleStates.hidden) {
            if (this.init) {
                this.$certEl
                    .stop()
                    .slideDown();
            } else {
                this.$certEl
                    .stop()
                    .show();
            }

            this.certificateState.changeState(visibleStates.visible);
        }
    },

    /**
     * Hide certificate.
     */
    hideCertificate: function() {
        if (this.certificateState.getState() === visibleStates.visible) {
            if (this.init) {
                this.$certEl
                    .stop()
                    .slideUp();
            } else {
                this.$certEl
                    .stop()
                    .hide();
            }

            this.certificateState.changeState(visibleStates.hidden);
        }
    },

    addStateTransitions: function() {
        this.connectionState.addTransitions({
            '> connected': function() {
                clearTimeout(this.tryToConnectTimeout);

                this.hideTryToConnectMessage();
            }
        });
    },

    /**
     * @method createEncryptSelect
     */
    createEncryptSelect: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#encrypt-select'),
            label: 'settings.security',
            native: true,
            name: 'encryption',
            items: [
                {
                    value: 'none',
                    text: 'settings.none'
                },
                {
                    value: 'wep',
                    text: 'settings.wep'
                },
                {
                    value: 'wpa',
                    text: 'settings.wpa2'
                },
                {
                    value: 'wpa-enterprise',
                    text: 'settings.wpa2_enterprise'
                }
            ]

        });
    },

    createAuthMethodSelect: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#auth-method-select'),
            label: 'settings.auth_method',
            native: true,
            name: 'authMethod',
            items: [
                {
                    value: 'peap-mschapv2',
                    text: 'settings.peap_mschapv2'
                },
                {
                    value: 'ttls-pap',
                    text: 'settings.ttls_pap'
                }
            ]
        });
    },

    createPrioritySelect: function() {
        var itemCount = 3;
        var items = [];

        if (this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) {
            itemCount = 2;
        }

        for (var i = 1; i <= itemCount; i++) {
            items.push({
                value: i,
                text: i
            });
        }

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#priority-select'),
            label: 'settings.priority_interface_access',
            native: true,
            info: (this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini())
                ? i18n.t('settings.interface_info_pure_wlan') : i18n.t('settings.interface_info'),
            name: 'priority',
            items: items
        });
    },

    /**
     * Enable settings.
     */
    enableSettings: function() {
        this.form.get('ip').enable();
        this.form.get('subnet').enable();
        this.form.get('gateway').enable();
        this.form.get('dns').enable();
        this.form.get('dns2').enable();
        this.form.get('signalLevel')
            .enable()
            .$el.attr('disabled', 'disabled');
        this.form.get('counterConnectionLoss')
            .enable()
            .$el.attr('disabled', 'disabled');
        this.form.get('counterLowSignalLevel')
            .enable()
            .$el.attr('disabled', 'disabled');
    },

    /**
     * Disable settings.
     */
    disableSettings: function() {
        this.form.get('ip').disable();
        this.form.get('subnet').disable();
        this.form.get('gateway').disable();
        this.form.get('dns').disable();
        this.form.get('dns2').disable();
        this.form.get('signalLevel').disable();
        this.form.get('counterConnectionLoss').disable();
        this.form.get('counterLowSignalLevel').disable();
    },

    /**
     * Render certificate list.
     */
    renderCertificateList: function() {
        this.$certList.find('.certificate-list-item').data('updated', false);

        if (!this.certList.length) {
            this.$noEntry.show();
        } else {
            this.$noEntry.hide();
        }

        _.each(this.certList, function(item, key) {
            var $found = this.$certList.find('.certificate-list-item[data-key="' + key + '"]');
            var $item = null;

            if ($found.length === 0) {
                // Create new
                item.key = key;
                item.delete = false;
                item.isCbox = platform.checks.isCbox;
                $item = this.$(certListItemTpl(item));
            } else {
                // Update
                $item = $found;
                $item.find('h2').html(item.name);
                $item.find('.activate').attr('name', item.name);
                $item.find('.delete').attr('name', item.name);
            }

            $item.find('h2').removeClass('has-no-icon');
            $item.find('.icon-check-mark').hide();
            $item.find('.icon-close').hide();

            if (item.active) {
                $item.find('.icon-check-mark').show();
            } else if (item.delete) {
                $item.find('.icon-close').show();
            } else {
                $item.find('h2').addClass('has-no-icon');
            }

            this.$certList.append($item);

            $item.data('updated', true);
        }.bind(this));

        // Remove all items with data-updated=false
        _.each(this.$certList.find('.certificate-list-item'), function(el) {
            var $el = this.$(el);
            var updated = $el.data('updated');

            if (!updated) {
                $el.remove();
            }
        }.bind(this));

        this.$certList.show();
        this.remote.focus(this.$certList.find('.is-focusable').get(0));
    },

    destroy: function() {
        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    handleChanges: function(change) {
        if (this.emitChanges && this.actionView) {
            var changes = {
                hasChanges: change,
                invalid: $(this.actionView.$submitBtn).prop('disabled')
            };

            this.emit('wlan-settings.changed', changes);
        }
    }
});
