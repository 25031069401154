'use strict';

var app = require('../app');
var $ = require('jquery');
var StateMachine = require('./../state-machine');
var states = require('./../../app/states');

app.service('LectureCaptureService', function() {
    return {
        deviceConnection: null,

        initialize: function() {
            this.panoptoLoginState = new StateMachine({
                context: this,
                state: states.lectureCaptureLoginStates.notLoggedIn,
                states: states.lectureCaptureLoginStates
            });
            this.uploadState = new StateMachine({
                context: this,
                state: states.lectureCaptureUploadStates.unknown,
                states: states.lectureCaptureUploadStates
            });
            this.opencastLoginState = new StateMachine({
                context: this,
                state: states.lectureCaptureLoginStates.notLoggedIn,
                states: states.lectureCaptureLoginStates
            });

            this.panoptoUsername = '';
            this.panoptoSettingsLogin = false;

            this.recordService = app.getService('RecordService');

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;

                    this.fetchLectureCaptureData();
                    this.bindEvents();
                }.bind(this));
        },

        bindEvents: function() {
            app.on('main-loop.update', this.fetchLectureCaptureData.bind(this));
            app.on('main-loop.update.panopto', this.fetchLectureCaptureData.bind(this));

            app.on('panopto-settings.login', function(data) {
                this.panoptoSettingsLogin = data.login;
            }.bind(this));
        },

        /**
         * Load and handle the opencast/panopto data.
         */
        fetchLectureCaptureData: function() {
            this.deviceConnection
                .send(['getPanoptoLoginStatus', 'getLcsUploadStatus', 'getOpencastStatus'])
                .then(this.onLectureCaptureData.bind(this));
        },

        /**
         * Called after lecture capture data has been loaded.
         *
         * @param {Object} loginState
         * @param {Object} uploadState
         * @param {Object} opencastState
         */
        onLectureCaptureData: function(panoptoLoginState, uploadState, opencastLoginState) {
            if (panoptoLoginState.status !== this.panoptoLoginState.getState()) {
                this.panoptoLoginState.changeState(panoptoLoginState.status);
                this.panoptoUsername = panoptoLoginState.username;

                app.emit('panopto.login-state.changed', {
                    state: this.panoptoLoginState.getState()
                });

                // Re-open recording settings overlay after authentication completed
                if (this.panoptoSettingsLogin && panoptoLoginState.status === states.lectureCaptureLoginStates.loggedIn) {
                    app.emit('overlay.open', {
                        id: 'record-settings',
                        extendedConfigs: 'settings-list'
                    });

                    this.panoptoSettingsLogin = false;
                }
            }

            if (opencastLoginState.status !== this.opencastLoginState.getState()) {
                this.opencastLoginState.changeState(opencastLoginState.status);

                app.emit('opencast.login-state.changed', {
                    state: this.opencastLoginState.getState()
                });
            }

            if (uploadState.status !== this.uploadState.getState()) {
                this.uploadState.changeState(uploadState.status);
            }
        },

        /**
         * Returns the current login-state.
         *
         * @return {String}
         */
        getLoginState: function(service) {
            switch (service) {
                case 'panopto':
                    return this.panoptoLoginState.getState();
                case 'opencast':
                    return this.opencastLoginState.getState();
                default:
                    return this.panoptoLoginState.getState();
            }
        },

        /**
         * Returns the current Panopto username
         *
         * @return {string|*}
         */
        getPanoptoUsername: function() {
            return this.panoptoUsername;
        },

        /**
         * Returns the last upload state.
         *
         * @return {String}
         */
        getUploadState: function() {
            return this.uploadState.getState();
        },

        /**
         * Returns true if there are some panopto upload with failed state.
         *
         * @return {boolean}
         */
        checkUploadState: function() {
            var dfd = $.Deferred();

            if (this.getUploadState() === states.lectureCaptureUploadStates.failed) {
                app.emit('modal.open', {
                    component: 'ConfirmV3',
                    view: 'small',
                    className: 'ui-scaling',
                    discareTextKey: 'modal.cancel',
                    successTextKey: 'modal.ok',
                    navArea: '.focusable',
                    hideHeader: true,
                    backdropClick: true,
                    messageKey: 'modal.lcs_upload_warning',
                    onConfirm: dfd.resolve,
                    onDiscare: dfd.reject,
                    role: {
                        name: 'allAccess',
                        key: 'show'
                    }
                });
            } else {
                dfd.resolve();
            }

            return dfd.promise();
        }
    };
});
