'use strict';

const app = require('../../../app');
const $ = require('jquery');
const vMeetingSettingsTpl = require('./vmeeting-settings.hbs');
const FormActionView = require('../form-action-view');
const platform = require('./../../../../platform/platform');
const i18n = require('i18next');
const connectionStates = require('../../../states').connectionStates;
const modes = require('../../../states').vMeetingModes;

app.component('vMeetingSettings', {
    template: vMeetingSettingsTpl,
    actionView: null,

    initialize: function() {
        this.isInitalized = false;

        this.sessionMgmtService = this.getService('SessionManagementService');
        this.frontendSettings = this.getService('FrontendSettings');
        this.deviceService = this.getService('DeviceService');
        this.formManager = this.getService('FormManager');

        this.previousConnectionState = null;
        this.abortLoginTimeoutHandle = null;
    },

    serialize: function() {
        return {
            isCbox: platform.checks.isCbox
        };
    },

    postPlaceAt: function() {
        this.loadData()
            .then(function(settings, frontendSettings) {
                this.formData = settings.data;
                Object.assign(this.formData, {
                    theme: frontendSettings.darkUi ? 'dark' : 'light',
                    statusBar: frontendSettings.statusBar,
                    wallpaper: frontendSettings.wallpaper
                });

                this.createSelects();
                this.storeSelectors();
                this.initForm();
                this.initFormAction();

                this.updateForm();
                this.checkUploadWallpaper(this.formData.wallpaper === 'custom');
                this.updateVMeetingStatus(settings.status);

                this.form.setDefaultValues();
                this.form.clearHasChanges();

                this.bindEvents();
                this.bindDOMEvents();
                this.handleVMeetingSettingsContainer();
                this.handleVMeetingMode();

                this.isInitalized = true;
            }.bind(this));
    },

    /**
     * Load back- and frontend settings.
     *
     * @returns {*}
     */
    loadData: function() {
        return $.when(this.loadSettings(this), this.loadFrontendSettings(this));
    },

    storeSelectors: function() {
        this.$vmeetingSettingsContainer = this.$el.find('#vmeeting-settings-container');
        this.$connectionState = this.$el.find('#vmeeting-connection-state');

        this.$modeSelect = this.$el.find('#vmeeting-mode-select');
        this.$resource = this.$el.find('.resource-container');
        this.$oauthAppId = this.$el.find('.oauth-app-id-container');
        this.$oauthSecret = this.$el.find('.oauth-secret-container');
        this.$oauthTenantId = this.$el.find('.oauth-tenant-id-container');

        this.$vmeetingOAuthState = this.$el.find('#vmeeting-oauth-state');
        this.$vmeetingLoginBtnContainer = this.$el.find('#vmeeting-login-btn-container');
        this.$vmeetingLoginBtn = this.$el.find('.vmeeting-login');
        this.$vmeetingLogoutBtnContainer = this.$el.find('#vmeeting-logout-btn-container');
        this.$vmeetingLogoutBtn = this.$el.find('.vmeeting-logout');
        this.$vmeetingAbortBtnContainer = this.$el.find('#vmeeting-abort-btn-container');
        this.$vmeetingAbortBtn = this.$el.find('.vmeeting-abort');

        this.$uploadWallpaper = this.$el.find('.upload-wallpaper-container');
        this.$vmeetingThemeSelect = this.$el.find('#vmeeting-theme-select');
    },

    bindEvents: function() {
        this.on('session-mgmt.update.status', this.updateVMeetingStatus.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
    },

    bindDOMEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.$vmeetingLoginBtn.on('click', this.login.bind(this));
        this.$vmeetingLogoutBtn.on('click', this.logout.bind(this));
        this.$vmeetingAbortBtn.on('click', this.abort.bind(this));
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#vmeeting-settings'),
            validationContainer: '.input-group'
        });
    },

    updateForm: function() {
        this.form.setValues(this.formData);
        this.checkActionForm();
    },

    /**
     * Handle unsaved changes before OAuth login is clicked.
     * @return {*}
     */
    handleUnsavedChanges: function() {
        let dfd = this.$.Deferred();

        if (this.changes) {
            this.emit('modal.open', {
                id: 'settings-save-changes',
                invalid: this.hasChanges().invalid,
                onSave: function() {
                    this.saveHandler()
                        .then(() => dfd.resolve());
                }.bind(this),
                onDiscard: function() {
                    this.cancelHandler();
                    this.actionView.close();
                    dfd.reject();
                }.bind(this),
                onDestroy: function() {
                    if (dfd.state() !== 'resolved') {
                        dfd.reject();
                    }
                }.bind(this),
                waitOnSave: this.hasChanges().waitOnSave
            });
        } else {
            dfd.resolve();
        }

        return dfd.promise();
    },

    /**
     * Handle login when login button is clicked.
     * Login window is shown and settings overlay is closed.
     */
    login: function() {
        this.handleUnsavedChanges()
            .then(function() {
                this.emit('vmeeting-settings.login', {
                    login: true
                });
                this.deviceConnection
                    .send('setVMeetingOAuth', {
                        login: true
                    })
                    .then(function() {
                        this.emit('overlay.close');
                    }.bind(this));
            }.bind(this));
    },

    /**
     * Handle logout when logout button is clicked.
     */
    logout: function() {
        this.emit('overlay.remote.focus', true);

        this.deviceConnection
            .send('setVMeetingOAuth', {
                login: false
            })
            .then(function() {
                // Immediately disable logout button while logout is in progress
                if (this.sessionMgmtService.connectionState.currentState !== connectionStates.disconnected) {
                    this.$vmeetingLogoutBtn.prop('disabled', true);
                }
            }.bind(this));
    },

    /**
     * Handle abort login when abort button is clicked.
     * To abort a login, send the logout command.
     */
    abort: function() {
        this.emit('overlay.remote.focus', true);

        this.emit('vmeeting-settings.login', {
            // Set login to false to avoid re-opening vMeeting settings
            login: false
        });
        this.deviceConnection
            .send('setVMeetingOAuth', {
                login: false
            })
            .then(function() {
                // Immediately disable abort button while abort is in progress
                if (this.sessionMgmtService.connectionState.currentState !== connectionStates.disconnected) {
                    this.$vmeetingAbortBtn.prop('disabled', true);

                    // If login abort takes longer than 10sec, give user the chance to click abort button again
                    this.abortLoginTimeoutHandle = setTimeout(() => {
                        this.$vmeetingAbortBtn.prop('disabled', false);
                    }, 10000);
                }
            }.bind(this));
    },

    /**
     * Called when the save-event has been triggered or the save-button has been clicked.
     * Saves backend and frontend settings and calls the post save handler afterwards.
     */
    saveHandler: function() {
        return $.when(this.saveSettings(), this.saveFrontendSettings())
            .done(this.postSaveHandler.bind(this));
    },

    /**
     * Get WP setter command object.
     *
     * @param form inputs store key
     * @return command object
     */
    getCommand: function(key) {
        const value = this.form.get(key).getValue();

        switch (key) {
            case 'vMeetingEnable':
                return  {
                    command: 'setVMeetingFunction',
                    data: {
                        enabled: value
                    }
                };
            case 'vMeetingMode':
                return {
                    command: 'setVMeetingMode',
                    data: {
                        mode: value
                    }
                };
            case 'resource':
                return {
                    command: 'setVMeetingResource',
                    data: {
                        resource: value
                    }
                };
            case 'tenantId':
                return {
                    command: 'setVMeetingOAuthTenantId',
                    data: {
                        id: value
                    }
                };
            case 'secret':
                return {
                    command: 'setVMeetingOAuthSecret',
                    data: {
                        secret: value
                    }
                };
            case 'dataHoldTimeout':
                return {
                    command: 'setVMeetingDataHoldTimeout',
                    data: {
                        minutes: value
                    }
                };
            case 'appId':
                return {
                    command: 'setVMeetingOAuthAppId',
                    data: {
                        id: value
                    }
                };
            case 'pin':
                return {
                    command: 'setVMeetingPin',
                    data: {
                        enabled: value
                    }
                };
        }
    },

    /**
     * Save backend settings for all form inputs that have changed.
     */
    saveSettings: function() {
        let dfd = this.$.Deferred();

        if (this.form.validate()) {
            let cmdArray = [];

            Object.keys(this.form.inputsStore).forEach(key => {
                if (this.form.inputsStore[key].hasChanges.hasChanges) {
                    let cmd = this.getCommand(key);
                    if (cmd) {
                        cmdArray.push(cmd);
                    }
                }
            });

            if (cmdArray.length > 0) {
                this.deviceConnection
                    .send(cmdArray)
                    .then(function() {
                        dfd.resolve();
                    }.bind(this), function() {
                        dfd.reject();
                    }.bind(this));
            } else {
                dfd.resolve();
            }
        } else {
            dfd.resolve();
        }

        return dfd.promise();
    },

    /**
     * Save all frontend settings.
     */
    saveFrontendSettings: function() {
        return this.validateCustomWallpaper()
            .then(function(isValid) {
                if (isValid) {
                    let wallpaper = this.form.get('wallpaper').getValue();
                    let theme = this.form.get('theme').getValue();

                    if ('wvlight' === wallpaper) {
                        theme = 'light';
                    }

                    this.frontendSettings.updateSetting({
                        tag: 'vMeetingDarkUi',
                        value: theme === 'dark'
                    });

                    this.frontendSettings.updateSetting({
                        tag: 'vMeetingWallpaper',
                        value: this.form.get('wallpaper').getValue()
                    });

                    this.frontendSettings.updateSetting({
                        tag: 'showVMeetingStatusBar',
                        value: this.form.get('statusBar').getValue()
                    });

                    if (!platform.checks.isCbox && this.form.get('wallpaper-file').getValue()) {
                        this.saveWallpaper();
                    }

                    return this.frontendSettings.saveSettings();
                } else {
                    return this.$.Deferred().resolve().promise();
                }
            }.bind(this));
    },

    /**
     * Save wallpaper to storage.
     */
    saveWallpaper: function() {
        const file = this.form.get('wallpaper-file').getValue();

        if (file) { // Save new wallpaper.
            this.sessionMgmtService.setVMeetingWallpaper(file);
        }
    },

    /**
     * Post save handler.
     */
    postSaveHandler: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.changes = false;
        this.form.setDefaultValues();
        this.form.clearHasChanges();

        this.emit('overlay.remote.focus', true);
        this.emit('save-changes.finished');
    },

    /**
     * Cancel handler resets form data to the values before changes.
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
        this.changes = false;
    },

    /**
     * Form change handler.
     */
    handleFormChange: function(data) {
        if (data && data.input.name === 'wallpaper') {
            this.checkUploadWallpaper(data.input.value === 'custom');
        }

        this.handleVMeetingSettingsContainer();
        this.handleVMeetingMode();
        this.updateVMeetingStatus(this.sessionMgmtService.connectionState.currentState);
        this.actionView.open();
        this.checkActionForm();

        this.validateCustomWallpaper().then(function(isValid) {
            if (!isValid) {
                this.actionView.disableSubmitButton();
            }
        }.bind(this));

        this.changes = true;
    },

    /**
     * Handle input fields (show/hide) depending on vMeeting mode.
     */
    handleVMeetingMode: function() {
        const enabled = this.form.get('vMeetingEnable').getValue();
        const mode = this.form.get('vMeetingMode').getValue();

        switch (mode) {
            case modes.ms365:
            case modes.googleCal:
                this.$resource.find('#resource').attr('data-allow-empty', 'false');
                this.$resource.show();

                this.$oauthAppId.find('#appId').attr('data-allow-empty', 'false');
                this.$oauthAppId.show();

                this.$oauthSecret.find('#secret').attr('data-allow-empty', 'false');
                this.$oauthSecret.show();

                if (mode === modes.ms365) {
                    this.$oauthTenantId.find('#tenantId').attr('data-allow-empty', 'false');
                    this.$oauthTenantId.show();
                } else {
                    this.$oauthTenantId.find('#tenantId').attr('data-allow-empty', 'true');
                    this.$oauthTenantId.hide();
                }
                break;
            default: // Modes.ms365Basic || modes.googleCalBasic || deprecated modes
                this.$resource.find('#resource').attr('data-allow-empty', 'true');
                this.$resource.hide();

                this.$oauthAppId.find('#appId').attr('data-allow-empty', 'true');
                this.$oauthAppId.hide();

                this.$oauthSecret.find('#secret').attr('data-allow-empty', 'true');
                this.$oauthSecret.hide();

                this.$oauthTenantId.find('#tenantId').attr('data-allow-empty', 'true');
                this.$oauthTenantId.hide();
                break;
        }

        // Deactivate inputs validation when vMeeting is disabled.
        this.$modeSelect.find('#vMeetingMode').attr('data-allow-empty', enabled ? 'false' : 'true');

        if (!enabled) {
            this.$resource.find('#resource').attr('data-allow-empty', 'true');
            this.$oauthAppId.find('#appId').attr('data-allow-empty', 'true');
            this.$oauthSecret.find('#secret').attr('data-allow-empty', 'true');
            this.$oauthTenantId.find('#tenantId').attr('data-allow-empty', 'true');
        }
    },

    /**
     * Show/Hide vMeeting settings container when vMeeting is enabled/disabled.
     */
    handleVMeetingSettingsContainer: function() {
        if (this.form.get('vMeetingEnable').getValue()) {
            if (this.isInitalized) {
                this.$vmeetingSettingsContainer.stop().slideDown(300);
            } else {
                this.$vmeetingSettingsContainer.show();
            }
        } else if (this.isInitalized) {
            this.$vmeetingSettingsContainer.stop().slideUp(300);
        } else {
            this.$vmeetingSettingsContainer.hide();
        }
    },

    /**
     * Show/Hide upload wallpaper container.
     *
     * @param custom
     */
    checkUploadWallpaper: function(custom) {
        if (custom) {
            if (!platform.checks.isCbox) {
                this.$uploadWallpaper.show();
            }
            this.$vmeetingThemeSelect.show();
        } else {
            this.$uploadWallpaper.hide();
            this.$vmeetingThemeSelect.hide();
        }
    },

    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * Load Backend Settings.
     *
     * @returns {*}
     */
    loadSettings: function() {
        let dfd = $.Deferred();

        this.deviceConnection
            .send([
                'getVMeetingFunction',
                'getVMeetingMode',
                'getVMeetingResource',
                'getVMeetingOAuthTenantId',
                'getVMeetingOAuthSecret',
                'getVMeetingDataHoldTimeout',
                'getVMeetingOAuthAppId',
                'getVMeetingConnectionStatus',
                'getVMeetingPin'
            ])
            .then(function(vMeeting, mode, resource, tenantId, secret, dataHoldTimeout, appId, vMeetingStatus, pin) {
                dfd.resolve({
                    data: {
                        'vMeetingEnable': vMeeting.enabled,
                        'vMeetingMode': mode.mode,
                        'resource': resource.resource,
                        'tenantId': tenantId.id,
                        'secret': secret.secret,
                        'dataHoldTimeout': dataHoldTimeout.minutes,
                        'appId': appId.id,
                        'pin': pin.enabled
                    },
                    status: vMeetingStatus.status
                }
                );
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Load Frontend Settings.
     *
     * @returns {*}
     */
    loadFrontendSettings: function() {
        let dfd = $.Deferred();

        this.frontendSettings
            .getSettings([
                'vMeetingDarkUi',
                'vMeetingWallpaper',
                'showVMeetingStatusBar'
            ])
            .then(function(darkUi, wallpaper, statusBar) {
                dfd.resolve({
                    darkUi: darkUi,
                    wallpaper: wallpaper,
                    statusBar: statusBar
                });
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Update vMeeting connection status
     * @param state connected/connecting/disconnected
     */
    updateVMeetingStatus: function(state) {
        this.$connectionState.attr('data-vmeeting-connection-state', state);
        this.$vmeetingOAuthState.attr('oauth-state', state);

        let vMeetingEnabled = this.form.get('vMeetingEnable').getValue();

        switch (state) {
            case connectionStates.disconnected:
            case connectionStates.failed:
                this.$vmeetingLoginBtn.prop('disabled', !vMeetingEnabled);
                this.$vmeetingLogoutBtn.prop('disabled', true);
                this.$vmeetingLoginBtnContainer.show();
                this.$vmeetingLogoutBtnContainer.hide();
                this.$vmeetingAbortBtnContainer.hide();

                if (this.abortLoginTimeoutHandle) {
                    clearTimeout(this.abortLoginTimeoutHandle);
                }
                break;
            case connectionStates.loggingIn:
            case connectionStates.connecting:
                this.$vmeetingLoginBtn.prop('disabled', true);
                this.$vmeetingLogoutBtn.prop('disabled', true);
                this.$vmeetingAbortBtn.prop('disabled', false);

                if (this.previousConnectionState === connectionStates.connected) {
                    // Logging out
                    this.$vmeetingLoginBtnContainer.hide();
                    this.$vmeetingLogoutBtnContainer.show();
                    this.$vmeetingAbortBtnContainer.hide();
                } else {
                    // Logging in
                    this.$vmeetingLoginBtnContainer.hide();
                    this.$vmeetingLogoutBtnContainer.hide();
                    this.$vmeetingAbortBtnContainer.show();
                }
                break;
            case connectionStates.connected:
                this.$vmeetingLoginBtn.prop('disabled', true);
                this.$vmeetingLogoutBtn.prop('disabled', false);
                this.$vmeetingLoginBtnContainer.hide();
                this.$vmeetingLogoutBtnContainer.show();
                this.$vmeetingAbortBtnContainer.hide();
                break;
        }

        this.previousConnectionState = state;
    },

    createSelects: function() {
        let modeSelectItems = [
            {
                text: 'settings.vmeeting_microsoft',
                value: modes.ms365
            },
            {
                text: 'settings.vmeeting_microsoft_basic',
                value: modes.ms365Basic
            },
            {
                text: 'settings.vmeeting_google',
                value: modes.googleCal
            },
            {
                text: 'settings.vmeeting_google_basic',
                value: modes.googleCalBasic
            }
        ];

        // If vMeeting mode is undefined (e.g. still has deprecated value) show empty item in select
        if (undefined === this.formData.vMeetingMode) {
            modeSelectItems.unshift({
                text: '',
                value: 'none'
            });
        }

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#vmeeting-mode-select'),
            label: 'settings.vmeeting_mode',
            native: true,
            name: 'vMeetingMode',
            items: modeSelectItems,
            error: i18n.t('settings.no_item_selected'),
            validation: 'checkSelectedItemNotEmpty',
            dataAllowEmpty: false
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#theme-select'),
            label: 'settings.theme',
            native: true,
            name: 'theme',
            items: [
                {
                    text: 'settings.dark',
                    value: 'dark'
                },
                {
                    text: 'settings.light',
                    value: 'light'
                }
            ]
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#wallpaper-select'),
            label: 'settings.wallpaper_select',
            native: true,
            name: 'wallpaper',
            items: [
                {
                    text: 'settings.wv_light',
                    value: 'wvlight'
                },
                {
                    text: 'settings.custom',
                    value: 'custom'
                }
            ]
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#data-hold-timeout'),
            label: 'settings.data_hold_timeout',
            native: true,
            name: 'dataHoldTimeout',
            items: [
                {
                    text: '1',
                    value: '1'
                },
                {
                    text: '2',
                    value: '2'
                },
                {
                    text: '3',
                    value: '3'
                },
                {
                    text: '4',
                    value: '4'
                },
                {
                    text: '5',
                    value: '5'
                }
            ]
        });
    },

    /**
     * Validates the custom wallpaper. If wallpaper is set to 'custom' and no wallpaper has been uploaded
     * then the validation fails.
     *
     * @return {PromiseLike<T> | Promise<T>}
     */
    validateCustomWallpaper: function() {
        return this.sessionMgmtService.loadCustomWallpaper(null)
            .then(function(url) {
                let input = this.form.get('wallpaper-file');

                // Not a custom wallpaper.
                if ('custom' !== this.form.get('wallpaper').getValue() || !input) {
                    return true;
                }

                // No wallpaper on file-system.
                if (null === url && !input.getValue()) {
                    input.validity = false;
                    this.form.showValidation(input.getValue(), input, input.$el);

                    return false;
                }

                return true;
            }.bind(this));
    },

    destroy: function() {
        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }

        if (this.abortLoginTimeoutHandle) {
            clearTimeout(this.abortLoginTimeoutHandle);
            this.abortLoginTimeoutHandle = null;
        }
    },

    hasChanges: function() {
        const changes =  {
            hasChanges: this.changes,
            invalid: false,
            waitOnSave: true
        };

        if (this.actionView) {
            changes.invalid = $(this.actionView.$submitBtn).prop('disabled');
        }

        return changes;
    }
});
