'use strict';

var _ = require('lodash');
var app = require('../app');
var platform = require('../../platform/platform');

var devBoxIp = '192.168.1.245';

function externalDevHost() {
    if (!platform.checks.canUseWS) {
        return window.location.protocol + '//' + devBoxIp + '/cbrest';
    }

    return (window.location.protocol === 'https:' ? 'wss' : 'ws') + '://' + devBoxIp + '/xxx';
}

function externalProdHost() {
    if (!platform.checks.canUseWS) {
        return window.location.protocol + '//' + window.location.host + '/cbrest';
    }

    return (window.location.protocol === 'https:' ? 'wss' : 'ws') + '://' + window.location.host + '/xxx';
}

function externalHostToken() {
    if (!platform.checks.canUseWS) {
        return window.location.protocol + '//%s/cbrest/?token=%s';
    }

    return (window.location.protocol === 'https:' ? 'wss' : 'ws') + '://%s/?token=%s';
}

function vzDevHost() {
    var url = devBoxIp + '/vz';

    if (!platform.checks.canUseWS) {
        return window.location.protocol + '//' + devBoxIp + '/vzrest';
    }

    if (platform.checks.isCbox) {
        url = 'localhost/vz';
    }

    return (window.location.protocol === 'https:' ? 'wss' : 'ws') + '://' + url;
}

function vzProdHost() {
    if (!platform.checks.canUseWS) {
        return window.location.protocol + '//' + window.location.host + '/vzrest';
    }

    return (window.location.protocol === 'https:' ? 'wss' : 'ws') + '://' + window.location.host + '/vz';
}

var configs = {
    'cynap': {
        'defaults': {
            'complianceUrl': 'http://localhost/web/licenses/license-compliance.pdf',
            'privacyPolicyUrl': 'http://localhost/web/licenses/privacypolicy.pdf',
            'cloudProviderOauthUrl': {
                'gdrive': 'https://accounts.google.com/o/oauth2/auth',
                'dropbox': 'https://www.dropbox.com/1/oauth/authorize',
                'onedrive': 'https://onedrive.live.com'
            },
            'settingsFilename': 'frontendSettings',
            'customUiSettingsFilename': 'customUiSettings',
            'matrixTemplates': 'matrixTemplates',
            'matrixFilename': 'matrixConfig',
            'matrixDualFilename': 'matrixDualConfig',
            'matrixBackground': 'matrixBackground',
            'matrixDualBackground': 'matrixDualBackground',

            // NOTE: Used to load an old config, if available after FW update from a FW without Matrix templates.
            'matrixFilenameDeprecated': 'matrixSettings'
        },
        'local': {
            'host': 'ws://127.0.0.1:7680'
        },
        'external': {
            'dev': {
                'host': externalDevHost(),
                'hostToken': externalHostToken()
            },
            'prod': {
                'host': externalProdHost(),
                'hostToken': externalHostToken()
            }
        }
    },
    'visualizer': {
        'dev': {
            'host': vzDevHost()
        },
        'prod': {
            'host': vzProdHost()
        }
    }
};

app.service('ConfigsService', function() {
    return {
        /**
         * Get configurations based on the device
         * @method get
         * @param {String} key
         */
        get: function(key) {
            var pathList = key.split('.');
            var pathRoot = pathList[0];
            var subPath = key.replace(pathRoot + '.', '');
            var device = (platform.checks.isCbox) ? 'local' : 'external';
            var environment = (window.CYNAP_ENV === 'prod') ? 'prod' : 'dev';
            var rootConfig = _.clone(configs[pathRoot]);
            var returnValue = null;
            var tmpConfig;

            if (rootConfig) {
                if (rootConfig[device]) {
                    tmpConfig = rootConfig[device];
                } else {
                    tmpConfig = rootConfig;
                }

                if (tmpConfig[environment]) {
                    tmpConfig = _.defaults(tmpConfig[environment], rootConfig['defaults']);
                } else {
                    tmpConfig = _.defaults(tmpConfig, rootConfig['defaults']);
                }

                returnValue = this.getValueByPath(tmpConfig, subPath);
            }

            return returnValue;
        },

        /**
         * Check if the path exists in the object and return the value
         * @method getValueByPath
         * @param {Object} object
         * @param {String} path
         */
        getValueByPath: function(object, path) {
            var value = object;

            for (var i = 0, pathList = path.split('.'), len = pathList.length; i < len; i++) {
                value = value[pathList[i]];

                if (!value) {
                    break;
                }
            }

            return value;
        }
    };
});
