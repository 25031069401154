'use strict';

var app = require('../../../app');
var loginTpl = require('./upload-progress.html');
const uploadProgressComponent = require('../../../components-vue/upload-progress/upload-progress.js');
const filebrowserUtils = require('../../filebrowser/utils.js');
const vueUtils = require('../../../components-vue/util.js');
const progressCircleComponent = require('../../../components-vue/progress-circle/progress-circle.js');

app.component('UploadProgress', {
    engine: 'vue',
    template: loginTpl,
    className: 'upload-progress-modal-container',

    initialize: function() {
        this.authService = app.getService('AuthenticationService');
        this.uriService = app.getService('URIService');
        this.fileops = filebrowserUtils.fileopsFactory({
            deviceConnection: this.deviceConnection,
            uriService: this.uriService
        });

        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return this.load();
            }.bind(this)
        });
        this.pollHelper.on('data', function(data) {
            this.$viewModel.uploads = data.uploads;
        }.bind(this));

        this.bindEvents();
    },

    data: function() {
        return {
            uploads: [{
                status: 'done',
                file: 'Internal:///snapshots/Cynap-01077727_20201001_093513.jpg',
                target: 'dropbox:///wolfvision/',
                progress: 100,
                index: 1
            }, {
                status: 'aborted',
                file: 'Internal:///snapshots/Cynap-01077727_20201001_093513.jpg',
                target: 'dropbox:///wolfvision/',
                progress: 60,
                index: 1
            }, {
                status: 'pending',
                file: 'Internal:///snapshots/Cynap-01077727_20201001_093513.jpg',
                target: 'dropbox:///wolfvision/',
                progress: 35,
                index: 1
            }]
        };
    },

    computed: {
        nonAbortedUploads: function() {
            return this.uploads.filter(upload => upload.status !== 'aborted');
        }
    },

    methods: {
        basename: function(path) {
            return filebrowserUtils.basename(path);
        },

        abortUpload: function(upload) {
            this.component.fileops.abortUpload({
                index: upload.index,
                target: upload.target
            });

            app.emit('main-loop.fast.start', {
                id: 'upload-action'
            });
        },

        retryUpload: function(upload) {
            this.retryUploads(Object.assign({}, {
                uploads: [upload]
            }));
        },

        retryUploads: function({ uploads }) {
            uploads.forEach(upload => {
                this.component.fileops.restartUpload({
                    index: upload.index,
                    target: upload.target
                });
            });

            app.emit('main-loop.fast.start', {
                id: 'upload-action'
            });
        },

        close: function() {
            this.component.emit('modal.close');
        },

        i18n: vueUtils.i18n
    },

    components: {
        'upload-progress': uploadProgressComponent,
        'progress-circle': progressCircleComponent
    },

    bindEvents: function() {
        this.on('main-loop.update', this.pollHelper.schedulePoll);
        this.on('main-loop.update.upload-action', this.pollHelper.schedulePoll);
    },

    load: function() {
        return Promise.resolve(this.deviceConnection
            .send([
                {
                    command: 'getFileUploadList',
                    data: {
                        allUploads: this.authService.getIsAdmin()
                    }
                }
            ]).then(function(uploadList) {
                return {
                    uploads: uploadList.fileUploadList
                };
            }));
    },

    serialize: function() {
        return this.pollHelper.serialize();
    }
});
