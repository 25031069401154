'use strict';

var $ = require('jquery');
var _ = require('lodash');
var app = require('../../app');
var modalTpl = require('./modal.hbs');
var loaderTpl = require('./loader.hbs');
var SmallView = require('./views/small-view');
var FullView = require('./views/full-view');
var EmptyView = require('./views/empty-view');
var StateEffectView = require('./views/state-effect-view');
var TransparentView = require('./views/transparent-view');
const store = require('../../store/store');
var platform = require('../../../platform/platform');

var states = {
    open: 'open',
    closed: 'closed'
};

var loaderStates = {
    enabled: 'enabled',
    disabled: 'disabled'
};

var viewmapper = {
    full: FullView,
    small: SmallView,
    empty: EmptyView,
    stateEffect: StateEffectView,
    transparent: TransparentView
};

app.component('Modal', {
    template: modalTpl,
    className: 'modal-container',
    id: null,

    initialize: function() {
        this.modalConfigs = this.getService('ModalConfigsService');
        this.remote = this.getService('RemoteService');
        this.authService = this.getService('AuthenticationService');
        this.modalHandlerService = this.getService('ModalHandlerService');

        this.state = this.createStateMachine({
            state: states.closed,
            states: states
        });
        this.addStateTransitions();
        this.bindEvents();
    },

    postPlaceAt: function() {
        this.bindDOMEvents();
    },

    bindEvents: function() {
        this.on('modal.close', this.closeHandler.bind(this, true));
        this.on('menu.opened', this.closeHandler.bind(this, true));
        this.on('control-center.opened', this.closeHandler.bind(this, true));
        this.on('modal.open', this.openHandler.bind(this));
        this.on('component.loader.change', this.componentLoaderChanged.bind(this));
    },

    componentLoaderChanged: function(options) {
        this.$el.find('#modal-view-container .modal-loader').remove();

        if (options.state === 'show' && this.modalConfig && loaderStates.disabled !== this.modalConfig.loader) {
            this.$el.find('#modal-view-container .modal-content').append(loaderTpl());
        }
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.close-modal', this.closeHandler.bind(this));
        this.$el.on('click', '.modal-view-container.is-clickable', function(e) {
            if (e.target !== e.currentTarget) {
                return;
            }

            this.closeHandler();
        }.bind(this));
    },

    /**
     * @param {Object} configs
     */
    openHandler: function(configs) {
        if ((this.getService('StationService').getIsInPushLockOrLimitedStatus() && !this.extendConfigs(configs).pushLockLimitedStateExc)
            || (!this.extendConfigs(configs).vMeetingExc
                && this.getService('SessionManagementService').vMeetingEnabled
                && !this.getService('SessionManagementService').getActiveSessionId()
                && !this.authService.getIsAdmin())
            || this.getService('AnnotationService').isMagicPenActive()
        ) {
            return;
        }

        if (configs.closeAll) {
            this.closeHandler(true);
            this.state.changeState(states.closed);
        }

        configs = this.extendConfigsUiScaling(configs);

        this.open(configs);
    },

    /**
     * @param {Object} configs
     */
    open: function(configs) {
        this.modalHandlerService
            .check(configs, function() {
                var extendedConfigs = this.extendConfigs(configs);

                this.modalHandlerService.setOpenModalId(configs.id);
                this.authService.checkAccess(extendedConfigs.role, function() {
                    if (!extendedConfigs.isStatusEffect && !extendedConfigs.preventClose) {
                        this.emit('modal.opened');
                    }

                    this.id = configs.id;
                    this.renderModal(extendedConfigs, configs);
                    this.modalConfig = _.defaults(extendedConfigs, {
                        backdropClick: !extendedConfigs.preventClose // If preventClose is set, the backdrop is not clickable by default
                    });

                    if (this.view.useBackdrop && !this.modalConfig.ignoreBackdrop) {
                        this.emit('backdrop.show', {
                            key: 'modal'
                        });
                        this.emit('backdrop.layer.set', {
                            zIndex: this.modalConfig.backdropIndex || 110,
                            key: 'modal'
                        });
                    }

                    if (!this.modalConfig.backdropClick) {
                        this.$el.find('.modal-view-container').removeClass('is-clickable');
                    } else {
                        this.$el.find('.modal-view-container').addClass('is-clickable');
                    }

                    if (this.modalConfig.navPrevention) {
                        this.$el.find('.modal-content').attr('data-nav-prevention', '');
                        this.$el.find('#modal-header-close').attr('data-nav-prevention', '');
                    }
                }.bind(this));
            }.bind(this));
    },

    /**
     * @param {Object} extendedConfigs
     * @param {Object} configs
     */
    renderModal: function(extendedConfigs, configs) {
        if (this.view) {
            this.view.destroy();
        }

        this.view = this.createView(extendedConfigs.view, extendedConfigs.component, configs, extendedConfigs);
        this.appendView();
    },

    /**
     * @param {Object} extendedConfigs
     */
    appendRemoteControl: function(extendedConfigs) {
        if (extendedConfigs.navArea && platform.checks.isCbox) {
            this.remote.focusArea(this.view.$el.find('.modal-content'), {
                area: extendedConfigs.navArea,
                trapped: true,
                prevention: extendedConfigs.navPrevention
            });

            this.focusModal(extendedConfigs.defaultFocus);
        }
    },

    /**
     * @param {Object} configs
     *
     * @returns {Object}
     */
    extendConfigs: function(configs) {
        var defaultConfigs = this.modalConfigs.get(configs.id);

        this.id = configs.id;

        return _.defaults(configs, defaultConfigs);
    },

    /**
     * If modal config CSS includes ui-scaling add the configured scaling size
     * or if the modal is a small view
     * @param configs
     * @return {{className}|*}
     */
    extendConfigsUiScaling: function(configs) {
        if (configs.view === 'small'
            || (configs.className && configs.className.includes('ui-scaling'))) {
            configs.className += (' ' + store.getters['uiSettings/getUiScalingSize']);
        }

        return configs;
    },

    /**
     * Creates a new modalView instance and renders it.
     *
     * @param {String} viewName
     * @param {String} componentName
     */
    createView: function(viewName, componentName, configs, extendedConfigs) {
        var view = new viewmapper[viewName](this.app, configs);
        var postRender = view.postRender || this.$.noop;

        view.addComponent(componentName);
        view.render(function() {
            this.bindDOMEvents();
            this.state.changeState(states.open);
            postRender.call(view);
            this.appendRemoteControl(extendedConfigs);
            this.emit('modal.opened-end');
        }.bind(this));

        return view;
    },

    /**
     * Inserts the view into the DOM.
     */
    appendView: function(view) {
        return (view || this.view).placeAt('#modal-view');
    },

    /**
     * @param {Boolean} closeable
     */
    closeHandler: function(closeable, params) {
        var options = _.assign({
            id: null,
            exceptIds: []
        }, params);

        if (this.view && (this.modalConfig.backdropClick || closeable)
            && (!options.id || options.id === this.id)
            && (options.exceptIds.length === 0 || $.inArray(this.id, options.exceptIds))
        ) {
            if (!this.modalConfig.ignoreBackdrop) {
                this.emit('backdrop.hide', {
                    key: 'modal'
                });
            }

            this.modalHandlerService.setOpenModalId(null);
            this.closeAnimation(function() {
                this.state.changeState(states.closed);
                this.emit('modal.closed', {
                    id: this.id
                });
                this.emit('framebox.focus.stored');
                this.emit('activities.start');
            }.bind(this));

            this.$el.off('click', '.close-modal');
        }
    },

    /**
     * Focus modal and set default focus.
     *
     * @param defaultFocus If extendedConfigs.defaultFocus is defined, set first focus on element with ID <defaultFocus>
     */
    focusModal: function(defaultFocus) {
        var $focusedEl = this.$el.find('.focused');
        var $el = this.getFocusEl();

        if (defaultFocus) {
            $el = this.$el.find(defaultFocus);
        }

        var el = $el.get(0);

        if ($el.length > 1 && $el.attr('id') && $el.attr('id') !== 'username') {
            el = $el.get(1);
        }

        // FIXME: Mickey keeps the class alive (in fullscreen mode)
        $focusedEl.removeClass('focused');

        if (platform.checks.isCbox) {
            this.remote.focus(el);
        }
    },

    /**
     * @method getFocusEl
     * @returns {*}
     */
    getFocusEl: function() {
        var $el = this.$el.find('input[type=range]');

        if (!$el.length) {
            $el = this.$el.find('.close-modal');

            if (!$el.length) {
                $el = this.$el.find('.focusable');
            }
        }

        return $el;
    },

    /**
     * @method addStateTransitions
     */
    addStateTransitions: function() {
        this.state.addTransitions({
            '> closed': function() {
                if (this.view) {
                    this.view.destroy();
                    this.$el.off();

                    // RELEASE-4851 (flickering when toggling freeze)
                    if (platform.checks.isCbox && this.modalConfig.view !== 'stateEffect') {
                        this.remote.focusLast();
                    }

                    this.view = null;
                }

                this.emit('framebox.speed.full');
            },

            '> open': function() {
                this.emit('framebox.speed.slow');
            }
        });
    },

    /**
     * @method closeAnimation
     * @param complete
     */
    closeAnimation: function(complete) {
        this.$el
            .velocity('stop')
            .velocity('fadeOut', {
                duration: 250,
                complete: complete
            });
    }
});
