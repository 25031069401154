// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-xs-12 save-changes-question\">\n        <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.unsaved_changes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":44}}}))
    + "</h2>\n    </div>\n</div>\n\n<div class=\"save-changes-buttons\">\n    <input data-action=\"discare\"\n           type=\"button\"\n           class=\"btn-form focusable\"\n           value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.changes_discare",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":18},"end":{"line":11,"column":51}}}))
    + "\" />\n\n    <input data-action=\"save\"\n           type=\"button\"\n           class=\"btn-form form-action-submit focusable\"\n           value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.changes_save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":18},"end":{"line":16,"column":48}}}))
    + "\"\n           "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"invalid") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":11},"end":{"line":17,"column":46}}})) != null ? stack1 : "")
    + "/>\n</div>\n";
},"useData":true});
