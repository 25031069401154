'use strict';

const app = require('../app');
const frameboxTypes = require('./../framebox-types').types;
const icons = require('./../framebox-types').sourceIcons;
const typeIcons = require('./../framebox-types').typeIcons;
const store = require('../store/store');

/**
 * Content sources service.
 */
app.service('SourcesService', function() {
    return {
        initialize: function() {
            this.freezeService = app.getService('FreezeService');
            this.webconferenceService = app.getService('WebconferenceService');
            this.streamInputService = app.getService('StreamInputService');
            this.customUiSettings = app.getService('CustomUiSettings');
            this.office365Service = app.getService('Office365Service');
            this.zoomService = app.getService('ZoomService');
            this.deviceService = app.getService('DeviceService');

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.bindEvents();
                }.bind(this));
        },

        bindEvents: function() {
            app.on('main-loop.update', this.updateHandler.bind(this));
            app.on('main-loop.update.sources', this.updateHandler.bind(this));
        },

        /**
         * Refresh content sources items
         */
        updateHandler: function() {
            let sources = [];

            this.loadSources()
                .then(parsedSources => {
                    sources = parsedSources;
                    if (sources.some(s => s.type === 'webconference')) {
                        return this.webconferenceService.availableWebconferenceServices();
                    }
                })
                .then(webConfServices => {
                    if (webConfServices) {
                        let index = sources.findIndex(s => s.type === 'webconference');
                        if (index > -1) {
                            if (webConfServices.length > 1) {
                                sources[index].hasSubItems = true;
                                sources[index].subItems = webConfServices.map(service => {
                                    return Object.assign({
                                        type: frameboxTypes['webconference'],
                                        icon: icons['webconference'],
                                        name: service.Name,
                                        url: service.URL,
                                        nativeScreenSharing: service.Native === 1
                                    });
                                });
                            } else if (webConfServices.length === 1 && webConfServices[0].Name) {
                                Object.assign(sources[index], {
                                    name: webConfServices[0].Name,
                                    url: webConfServices[0].URL,
                                    nativeScreenSharing: webConfServices[0].Native === 1
                                });
                            }
                        }
                    }
                })
                .then(() => {
                    if (sources.findIndex(s => s.type === 'zoom') > -1) {
                        return this.deviceConnection.send('getZoomSettings');
                    }
                })
                .then(zoomSettings => {
                    if (zoomSettings) {
                        let index = sources.findIndex(s => s.type === 'zoom');
                        if (index > -1) {
                            if (zoomSettings.enableJoinMeeting && zoomSettings.enableLocalMeeting) {
                                sources[index].hasSubItems = true;
                                sources[index].subItems = this.zoomService.getListOfActions();
                            }

                            sources[index].joinMeeting = zoomSettings.enableJoinMeeting;
                        }
                    }
                })
                .then(() => this.customUiSettings.getSettings(['whiteboardEnabled', 'browserEnabled']))
                .then((whiteboardEnabled, browserEnabled) => {
                    if (!whiteboardEnabled) {
                        sources = sources.filter(s => s.type !== 'whiteboard');
                    }
                    if (!browserEnabled) {
                        sources = sources.filter(s => s.type !== 'browser');
                    }
                })
                .then(() => {
                    store.dispatch('sources/setSources', sources);
                });
        },

        /**
         * Load sources from backend
         *
         * @return {*}
         */
        loadSources: function() {
            return this.deviceConnection
                .send('getContentSources')
                .then(data => this.combineFilebrowserAndCloudSource(data.sources))
                .then(sources => this.sourcesWhenFreezeActive(sources))
                .then(sources => this.parseSources(sources));
        },

        /**
         * UI 3.0 file browser combines filebrowser source and cloud source
         * @param sources
         * @return {*}
         */
        combineFilebrowserAndCloudSource: function(sources) {
            let cloudIndex = sources.findIndex(s => s.type === 'cloud');
            if (cloudIndex > -1) {
                if (sources.findIndex(s => s.type === 'filebrowser') > -1) {
                    // File browser source is there, so we can remove the cloud source
                    sources.splice(cloudIndex, 1);
                } else {
                    // File browser source is not there, so we transform the cloud source into a file browser source
                    Object.assign(sources[cloudIndex], {
                        name: '',
                        present: true,
                        type: 'filebrowser'
                    });
                }
            }

            return sources;
        },

        /**
         * When freeze is active, only the filebrowser source shall be displayed
         * @param sources
         * @return {*}
         */
        sourcesWhenFreezeActive: function(sources) {
            return sources.filter(s => s.type === 'filebrowser' || !this.freezeService.isFreeze());
        },

        /**
         * Parse content sources
         * @param sources
         * @return {*[]}
         */
        parseSources: function(sources) {
            let parsedSources = [];

            sources.forEach(source => {
                if (source.type === 'browser') {
                    source.index = 0;
                } else if (source.type === 'browser-shortcut') {
                    source.type = 'browser';
                }

                if (source.icon >= 0) {
                    source.icon = typeIcons[source.icon];
                } else {
                    source.icon = icons[source.type];
                }

                if (source.name === '') {
                    source.titleKey = 'sources.' + source.type;
                }

                if (source.type === 'office365') {
                    source.hasSubItems = true;
                    this.office365Service.showTeamsAsSubSource(false)
                        .then(function(show) {
                            source.subItems = this.office365Service.getListOfApps();

                            if (!show || sources.some(s => s.type === 'msteams') || store.getters['controlScreen/isWebconferenceInPrepare']) {
                                source.subItems = source.subItems.filter(app => app.type !== frameboxTypes['msteams']);
                            }
                        }.bind(this));
                }

                if (source.type === 'streaminputlist') {
                    source.hasSubItems = true;
                    this.streamInputService.getStreamInputs()
                        .then(function(streamInputs) {
                            source.subItems = streamInputs;
                        }.bind(this));
                }

                parsedSources.push(source);
            });

            return parsedSources;
        },

        /**
         * Open a content source
         * @param source
         * @param controlInfo - control information for control screen
         */
        open: function(source, controlInfo) {
            let handler = this.sourceSelectionHandlers[source.type]
                || this.sourceSelectionHandlers['open-framebox'];

            if (this.deviceService.isCboxProDualProjection()) {
                if (!controlInfo) {
                    // If no control info is defined, always open in prepare area
                    controlInfo = {
                        output: 'prepare'
                    };
                }

                handler = this.getSourceSelectionHandlerControlScreen(source.type);
            }

            if (handler) {
                handler.call(this, source, controlInfo);

                if (controlInfo?.output === 'prepare') {
                    app.emit('main-loop.fast.start', {
                        id: 'prepare-list'
                    });
                }
            }
        },

        /**
         * Handlers for opening sources
         */
        sourceSelectionHandlers: {
            'filebrowser': function() {
                app.emit('file-browser.open');
            },
            'mirror': function() {
                app.emit('overlay.open', {
                    id: 'mirroring',
                    type: 'mirror',
                    closeButton: true,
                    openNew: true
                });
            },
            'webconference': function() {
                this.webconferenceService.getWebconferenceService();
            },
            'zoom': function(item) {
                if (item.hasSubItems) {
                    this.zoomService.showModal();
                } else if (item.joinMeeting) {
                    this.zoomService.showJoinMeetingModal();
                } else {
                    this.webconferenceService.startWebconferenceLocal(item.type);
                }
            },
            'open-framebox': function(item) {
                app.emit('framebox.coming');

                if (item.hdmiIn !== undefined) {
                    app.emit('framebox.add', {
                        frameBoxType: frameboxTypes[item.type],
                        parameter: Number(item.hdmiIn)
                    });
                } else if (item.type === 'streaminput' || item.type === 'webcam') {
                    app.emit('framebox.add', {
                        frameBoxType: frameboxTypes[item.type],
                        parameter: Number(item.index)
                    });
                } else {
                    app.emit('framebox.add', {
                        frameBoxType: frameboxTypes[item.type]
                    });
                }
            },
            'office365': function() {
                this.office365Service.showModal();
            },
            'streaminputlist': function() {
                this.streamInputService.showModal();
            },
            'browser': function(item) {
                if (item.url) {
                    this.deviceConnection.send('setAddNewFrameboxPrivate', {
                        content: frameboxTypes[item.type],
                        parameter: item.url
                    });
                } else {
                    this.deviceConnection.send('setBrowserShortcutOpen', { index: item.index });
                }
            },
            'pdf': function(item) {
                this.deviceConnection.send('setAddNewFrameboxPrivate', {
                    content: frameboxTypes[item.type],
                    parameter: item.url
                });
            }
        },

        /**
         * Handlers for opening sources in dual projection mode
         */
        getSourceSelectionHandlerControlScreen: function(type) {
            switch (type) {
                case 'browser':
                    return function(item, controlInfo) {
                        this.deviceConnection.send('setStartApplicationBrowser', {
                            output: controlInfo.output,
                            replaceAppId: controlInfo.replaceAppId,
                            prepareIndex: controlInfo.prepareIndex,
                            index: item.index,
                            url: item.url
                        });
                    };
                case 'hdmi':
                case 'computer':
                case 'disc':
                case 'camera':
                case 'streaminput':
                case 'webcam':
                    return function(item, controlInfo) {
                        const index = item.hdmiIn !== undefined ? item.hdmiIn : item.index;

                        this.deviceConnection.send('setStartApplicationInput', {
                            output: controlInfo.output,
                            replaceAppId: controlInfo.replaceAppId,
                            prepareIndex: controlInfo.prepareIndex,
                            content: frameboxTypes[item.type],
                            index: index
                        });
                    };
                case 'mirror':
                    return function() {
                        this.deviceConnection
                            .send('setMirrorState', {
                                enable: true
                            });
                    };
                case 'filebrowser':
                case 'office365':
                case 'streaminputlist':
                case 'dir':
                    // These sources are not supported in dual projection mode
                    return undefined;
                case 'msoutlook':
                case 'msword':
                case 'msexcel':
                case 'mspowerpoint':
                case 'msonenote':
                case 'mswhiteboard':
                case 'msplanner':
                    return function(item, controlInfo) {
                        this.deviceConnection.send('setStartApplicationMicrosoft365', {
                            output: controlInfo.output,
                            replaceAppId: controlInfo.replaceAppId,
                            prepareIndex: controlInfo.prepareIndex,
                            content: frameboxTypes[item.type]
                        });
                    };
                case 'msteams':
                    return function(item, controlInfo) {
                        this.deviceConnection.send('setStartApplicationTeamsZoom', {
                            output: controlInfo.output,
                            replaceAppId: controlInfo.replaceAppId,
                            prepareIndex: controlInfo.prepareIndex,
                            content: frameboxTypes[item.type],
                            action: 'local'
                        });
                    };
                case 'webconference':
                    return function(item, controlInfo) {
                        this.deviceConnection.send('setStartApplicationWebconference', {
                            output: controlInfo.output,
                            replaceAppId: controlInfo.replaceAppId,
                            prepareIndex: controlInfo.prepareIndex,
                            url: item.url,
                            nativeScreenSharing: item.nativeScreenSharing
                        });
                    };
                case 'zoom':
                    return function(item, controlInfo) {
                        if (item.joinMeeting || item.action === 'join') {
                            this.zoomService.showJoinMeetingModal(function(meetingId, link) {
                                this.deviceConnection.send('setStartApplicationTeamsZoom', {
                                    output: controlInfo.output,
                                    replaceAppId: controlInfo.replaceAppId,
                                    prepareIndex: controlInfo.prepareIndex,
                                    content: frameboxTypes[item.type],
                                    action: 'join',
                                    url: link ? link : this.zoomService.getZoomLink(meetingId)
                                });
                            }.bind(this));
                        } else {
                            this.deviceConnection.send('setStartApplicationTeamsZoom', {
                                output: controlInfo.output,
                                replaceAppId: controlInfo.replaceAppId,
                                prepareIndex: controlInfo.prepareIndex,
                                content: frameboxTypes[item.type],
                                action: 'local'
                            });
                        }
                    };
                case 'text':
                case 'calc':
                case 'presentation':
                case 'pdf':
                case 'video':
                case 'audio':
                case 'image':
                case 'html':
                case 'unknown':
                    return function(item, controlInfo) {
                        if (type === 'pdf' && item.url) {
                            // Open PDF from URL
                            this.deviceConnection.send('setStartApplicationPdf', {
                                output: controlInfo.output,
                                replaceAppId: controlInfo.replaceAppId,
                                prepareIndex: controlInfo.prepareIndex,
                                url: item.url
                            });
                        } else {
                            // Open file from local file system
                            this.deviceConnection.send('setStartApplicationFile', {
                                output: controlInfo.output,
                                replaceAppId: controlInfo.replaceAppId,
                                prepareIndex: controlInfo.prepareIndex,
                                filePath: item.path
                            });
                        }
                    };
                default:
                    return function(item, controlInfo) {
                        this.deviceConnection.send('setStartApplication', {
                            output: controlInfo.output,
                            replaceAppId: controlInfo.replaceAppId,
                            prepareIndex: controlInfo.prepareIndex,
                            content: frameboxTypes[item.type]
                        });
                    };
            }
        }
    };
});
