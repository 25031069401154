'use strict';

var app = require('../app');
var StateMachine = require('./../state-machine');

var states = {
    disabled: 'disabled',
    ready: 'ready',
    active: 'active',
    error: 'error'
};

var activeStates = {
    on: 'on',
    off: 'off'
};

var modes = {
    none: 'none',
    off: 'off',
    custom: 'custom',
    youtube: 'youtube'
};

var youtubeLoginStates = {
    none: 'none',
    disconnected: 'disconnected',
    authenticating: 'authenticating',
    authenticated: 'authenticated',
    tokenExpired: 'token-expired',
    failed: 'failed'
};

var youtubeStreamStates = {
    none: 'none',
    idle: 'idle',
    ready: 'ready',
    testStarting: 'test-starting',
    liveStarting: 'live-starting',
    live: 'live',
    complete: 'complete',
    error: 'error'
};

var iconStates = {
    none: 'none',
    error: 'error', // Ready
    running: 'running'
};

app.service('WebcastingService', function(app) {
    return {

        initialize: function() {
            this.deviceService = app.getService('DeviceService');

            this.webcastMode = new StateMachine({
                context: this,
                state: modes.none,
                states: modes
            });

            this.webcastActiveState = new StateMachine({
                context: this,
                state: activeStates.off,
                states: activeStates
            });

            this.webcastState = new StateMachine({
                context: this,
                state: states.disabled,
                states: states
            });

            this.youtubeLoginState = new StateMachine({
                context: this,
                state: youtubeLoginStates.none,
                states: youtubeLoginStates
            });

            this.youtubeStreamState = new StateMachine({
                context: this,
                state: youtubeStreamStates.none,
                states: youtubeStreamStates
            });

            this.webcastIconState = new StateMachine({
                context: this,
                state: iconStates.none,
                states: iconStates
            });

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;

                    this.checkFeaturePacks();
                    this.updateHandler();
                    this.bindEvents();
                    this.addStateTransitions();
                }.bind(this));

            this.streaming = app.getService('Streaming');

            this.youtubeStreamLogin = false;
        },

        bindEvents: function() {
            app.on('main-loop.update', this.updateHandler.bind(this));
            app.on('main-loop.update.webcasting', this.updateHandler.bind(this));

            app.on('youtube-stream.login', function() {
                this.youtubeStreamLogin = true;
            }.bind(this));
        },

        checkFeaturePacks: function() {
            this.deviceConnection
                .send('getLicenseFeatures')
                .then(function(features) {
                    this.features = features;
                }.bind(this));
        },

        supportsWebcasting: function() {
            return this.deviceService.isCbox() || (this.deviceService.isCboxCorePro() && this.features.capture);
        },

        /**
         * Called from event (sidebar, stream record modal)
         */
        toggleActiveState: function() {
            var state = null;

            switch (this.webcastActiveState.getState()) {
                case activeStates.off:
                    state = activeStates.on;
                    this.setWebcastActiveState(state);
                    break;
                case activeStates.on:
                    state = activeStates.off;
                    this.setWebcastActiveState(state);
                    break;
            }

            return state;
        },

        setWebcastActiveState: function(state) {
            if (this.webcastActiveState.getState() !== activeStates[state]) {
                this.webcastActiveState.changeState(activeStates[state]);

                app.emit('main-loop.fast.start', {
                    id: 'webcasting'
                });

                this.deviceConnection
                    .send('setWebcastStreamingMode', {
                        mode: (state === activeStates.on)
                    });
            }

            app.emit('webcasting.active.state.changed', {
                state: state
            });

            this.updateHeaderNotification(state);
            this.updateSidebarNotification(state);
        },

        getActiveState: function() {
            return this.webcastActiveState.getState();
        },

        /**
         * Returns if webcast is enabled.
         * @returns {boolean} true/false
         */
        isEnabled: function() {
            return this.webcastMode.getState() !== modes.off
                && this.webcastMode.getState() !== modes.none
                && this.webcastActiveProtocol !== 'lcs'; // If Webcast for LCS is active, no interaction is possible - it's handled by the backend.
        },

        /**
         * Returns if webcast is active.
         * @returns {boolean} true/false
         */
        isActive: function() {
            return this.webcastActiveState.getState() === activeStates.on;
        },

        /**
         * Returns if webcast is loading/starting.
         * At the moment, this is just Youtube.
         * @returns {boolean} true/false
         */
        isLoading: function() {
            if (this.webcastMode.getState() === modes.youtube) {
                return this.youtubeStreamState.getState() === youtubeStreamStates.liveStarting
                    || (!this.isActive() && this.youtubeStreamState.getState() === youtubeStreamStates.live);
            }

            return false;
        },

        updateHandler: function() {
            this.deviceConnection
                .send(['getWebcastMode',
                    'getWebcastState',
                    'getWebcastYoutubeLoginState',
                    'getWebcastYoutubeStreamState',
                    'getWebcastStreamingMode',
                    'getWebcastActiveProtocol'
                ])
                .then(this.onUpdate.bind(this));
        },

        onUpdate: function(mode, webcastState, youtubeLogin, youtubeStream, webcastStreamingMode, webcastActiveProtocol) {
            var update = false;
            var activeState = webcastStreamingMode.mode ? activeStates.on : activeStates.off;
            var webcastEnabled = mode.mode !== modes.off;

            this.webcastActiveProtocol = webcastActiveProtocol.protocol;

            if (this.webcastActiveState.getState() !== activeState) {
                this.webcastActiveState.changeState(activeState);

                app.emit('webcasting.active.state.changed', {
                    state: activeState
                });

                update = true;
            }

            if (this.webcastMode.getState() !== mode.mode) {
                if (this.webcastMode.getState() !== modes.none && this.isActive()) {
                    this.setWebcastActiveState(activeStates.off);
                    activeState = activeStates.off;
                }

                this.webcastMode.changeState(mode.mode);

                app.emit('webcasting.mode.changed', {
                    enabled: webcastEnabled
                });

                update = true;
            }

            if (this.webcastState.getState() !== webcastState.stateHdmi) {
                this.webcastState.changeState(webcastState.stateHdmi);

                update = true;
            }

            if (this.youtubeLoginState.getState() !== youtubeLogin.state) {
                app.emit('youtube-stream.login-state.changed', {
                    state: youtubeLogin.state
                });

                // Open streaming settings overlay (only on the device where the login was initiated)
                if (youtubeLogin.state === youtubeLoginStates.authenticated && this.youtubeStreamLogin) {
                    app.emit('overlay.open', {
                        id: 'streaming-settings',
                        extendedConfigs: 'settings-list',
                        remoteContainer: 'youtube'
                    });

                    this.youtubeStreamLogin = false;
                }

                // Stop webcasting if youtube logout happens.
                if (this.isActive() && this.webcastMode.getState() === modes.youtube
                    && youtubeLogin.state !== youtubeLoginStates.authenticated) {
                    this.setWebcastActiveState(activeStates.off);
                    activeState = activeStates.off;
                }

                this.youtubeLoginState.changeState(youtubeLogin.state);

                update = true;
            }

            if (this.youtubeStreamState.getState() !== youtubeStream.state) {
                this.youtubeStreamState.changeState(youtubeStream.state);

                update = true;
            }

            if (update) {
                this.updateHeaderNotification(activeState);
                this.updateSidebarNotification(activeState);
            }
        },

        updateSidebarNotification: function(st) {
            var state = st ? st : this.webcastActiveState.getState();
            var webcastState = this.webcastState.getState();

            if (state === states.on) {
                return;
            }

            app.emit('webcasting.enable', {
                enabled: (webcastState !== states.error)
            });

            // Short timeout to avoid flickering
            setTimeout(function() {
                app.emit('webcasting.loading.changed', this.isLoading());
            }.bind(this), 300);
        },

        /**
         * Send the upate event for the header widget
         * @method updateHeaderNotification
         * @param state
         */
        updateHeaderNotification: function(state) {
            var active = state === activeStates.on || this.isLoading();

            if (active) {
                this.updateIconState();
                var options = {
                    icon: 'icon-webcasting',
                    clickable: false,
                    state: this.webcastIconState.getState()
                };

                app.emit('status-widget.item.append', {
                    id: 'webcasting',
                    accessKey: 'Webcasting',
                    options: options
                });

                app.emit('status-widget.item.update', {
                    id: 'webcasting',
                    options: options
                });

                return;
            }

            app.emit('status-widget.item.remove', {
                id: 'webcasting'
            });
        },

        updateIconState: function() {
            if (!this.isActive()) {
                return;
            }

            var webcastState = this.webcastState.getState();
            var iconState = (webcastState !== states.active) ? iconStates.error : iconStates.running;

            if (iconState && this.webcastIconState.getState() !== iconState) {
                this.webcastIconState.changeState(iconState);
            }
        },

        /**
         * Handle youtube states.
         * Enable webcasting toggle switch in control center as long as youtube is starting ('live-starting')
         */
        addStateTransitions: function() {
            this.youtubeStreamState.addTransitions({
                '> live-starting': function() {
                    app.emit('webcasting.active.state.changed', {
                        state: activeStates.on
                    });
                },

                'live-starting >': function() {
                    app.emit('webcasting.active.state.changed', {
                        state: this.webcastActiveState.getState()
                    });
                }
            });
        }
    };
});
