// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"wlan-access-point-pure-form\" name=\"wlan-access-point-pure-form\">\n    <div class=\"row settings-list-row\" data-nav-area=\".input:not(:disabled)\" data-nav-trapped>\n        <div class=\"col-xs-6\">\n            <div class=\"input-group\">\n                <label class=\"input-label\" for=\"access-point-ipv4\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ip_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":67},"end":{"line":5,"column":94}}}))
    + "</label>\n                <input type=\"text\"\n                       class=\"input\"\n                       name=\"ip\"\n                       id=\"access-point-ipv4\"\n                       placeholder=\"0.0.0.0\"\n                       data-validation=\"checkApIp\" />\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_ap_ip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":67},"end":{"line":13,"column":95}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n        <div class=\"col-xs-6\">\n            <div class=\"input-group\">\n                <label class=\"input-label\" for=\"access-point-subnet\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.subnet",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":69},"end":{"line":19,"column":92}}}))
    + "</label>\n                <input type=\"text\"\n                       class=\"input\"\n                       name=\"subnet\"\n                       id=\"access-point-subnet\"\n                       placeholder=\"0.0.0.0\"\n                       data-validation=\"checkSubnet\" />\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.subnet_is_wrong",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":67},"end":{"line":27,"column":99}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row settings-list-row\" data-nav-area=\".btn-switch, .input:not([disabled]), select, .is-focusable:not(:disabled)\" data-nav-trapped>\n        <div class=\"col-xs-6\">\n            <div id=\"channel-select-pure\"></div>\n        </div>\n        <div class=\"col-xs-6\">\n            <div id=\"max-clients-select-pure\"></div>\n        </div>\n    </div>\n\n    <div id=\"wlan-wait-for-ap\" class=\"row settings-list-primary wlan-list-item wlan-wait-for-ap hidden\">\n        <div class=\"col-xs-12\">\n            <p><span class=\"loader small\"></span> <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wait_for_ap",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":56},"end":{"line":44,"column":84}}}))
    + "</span></p>\n        </div>\n    </div>\n    <div id=\"form-action-container-access-point\" class=\"form-primary-background\"></div>\n</form>\n";
},"useData":true});
