'use strict';

var app = require('../../../../app');

var controlItems = [
    {
        id: 'mute',
        type: 'mute',
        state: 'mute'
    },
    {
        id: 'volume',
        type: 'volume'
    }
];

/**
 * Hdmi frame
 */
app.component('FrameBoxHdmi', {
    className: 'framebox-view framebox-hdmi-view',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.startControls();
    },

    /**
     * @method startControls
     */
    startControls: function() {
        this.createComponent({
            type: 'FrameBoxControls',
            container: this.$el,
            index: this.options.index,
            appId: this.options.appId,
            items: controlItems,
            isFullscreen: this.options.isFullscreen
        });
    }
});
