'use strict';

var app = require('../../../../app');
var tpl = require('./stations-offline.hbs');

app.component('MatrixStationsOffline', {
    template: tpl,
    className: 'stations-offline',

    getAccessKey: function() {
        return {
            'roleName': 'MatrixSettings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.matrixService = this.getService('MatrixService');
        this.matrixConfigurationService = this.getService('MatrixConfiguratorService');

        this.names = this.matrixConfigurationService.getStationNames(this.options.configs.stations);
    },

    serialize: function() {
        return {
            closeConfigurator: this.options.configs.closeConfigurator,
            loadTemplate: this.options.configs.loadTemplate,
            stations: this.names,
            maxReached: this.options.configs.maxReached
        };
    },

    postPlaceAt: function() {
        this.bindDOMEvents();
    },

    bindDOMEvents: function() {
        this.$el.on('click', '[data-action]', this.onAction.bind(this));
    },

    /**
     * Handle button click.
     *
     * @param event Current event
     */
    onAction: function(event) {
        var $el = this.$(event.currentTarget);
        var action = $el.data('action');
        var handler = this.actionHandlers[action];

        if (handler) {
            handler.call(this);
            this.emit('modal.close');
        }
    },

    /**
     * Open Matrix settings.
     */
    openMatrixSettings: function() {
        setTimeout(function() {
            this.emit('overlay.open', {
                id: 'matrix-settings',
                extendedConfigs: 'settings-list'
            });
        }.bind(this), 250);
    },

    /**
     * Action handles.
     */
    actionHandlers: {
        'discare': function() {
            this.emit('overlay.close');
            this.emit('backdrop.hide');
            this.emit('menu.close');

            this.openMatrixSettings();
        },

        'load': function() {
            this.options.configs.onLoadTemplate();
        }
    }
});

