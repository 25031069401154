'use strict';

var app = require('../app');

app.service('JSONService', function() {
    return {
        /**
         * Parse json-string to a object/array.
         *
         * @param {String} jsonString
         * @param {Array|Object} defaultValue
         * @return {Array|Object}
         */
        parse: function(jsonString, defaultValue) {
            var exceptionManager = app.getService('ExceptionsManager');

            // Define default-value.
            if (!defaultValue) {
                defaultValue = [];
            }

            // Throw an error when jsonString is empty.
            if ('' === jsonString) {
                exceptionManager
                    .throw({
                        isInfo: false,
                        name: 'JSON ERROR',
                        message: 'JSON is empty.'
                    }, true);

                return defaultValue;
            }

            try {
                return JSON.parse(jsonString);
            } catch (error) {
                exceptionManager
                    .throw({
                        isInfo: false,
                        name: 'JSON ERROR',
                        message: 'JSON is not valid.'
                    }, true);
                exceptionManager
                    .throw({
                        isInfo: true,
                        name: 'JSON ERROR',
                        message: jsonString
                    }, true);

                return defaultValue;
            }
        }
    };
});
