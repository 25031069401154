'use strict';

const app = require('../../app');
const screensaverEndTpl = require('./screensaver-end.html');

app.component('ScreensaverEnd', {
    engine: 'vue',
    template: screensaverEndTpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    methods: {
        exitScreensaver: function() {
            this.screensaverService.deactivateScreensaver();
        }
    },

    created: function() {
        this.screensaverService = app.getService('ScreensaverService');
    }
});
