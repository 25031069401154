// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"record-general-settings\" name=\"input-settings\">\n    <div class=\"row settings-list-row\">\n        <div class=\"col-xs-12\">\n            <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.recording_parameters",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":53}}}))
    + "</h2>\n        </div>\n    </div>\n    <div class=\"row settings-list-secundary\" data-nav-area=\"select, .is-focusable\" data-nav-trapped\n         data-nav-area-default>\n        <div class=\"col-xs-6\">\n            <div id=\"resolution-select\"></div>\n        </div>\n        <div class=\"col-xs-6\">\n            <div id=\"framerate-select\"></div>\n        </div>\n    </div>\n    <div class=\"row settings-list-row\"\n         data-nav-area=\".is-focusable\"\n         data-nav-trapped\n         data-nav-area-default>\n        <div class=\"col-xs-8\">\n            <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.recording_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":49}}}))
    + "</h2>\n        </div>\n        <div class=\"col-xs-4\">\n            <div class=\"btn-switch-container pull-right\">\n                <input name=\"recordingEnable\"\n                       id=\"recordingEnabled\"\n                       type=\"checkbox\"\n                       class=\"btn-switch is-focusable\"\n                       tabIndex=\"-1\"/>\n                <div class=\"btn-switch-toggle-container\">\n                    <div class=\"btn-switch-toggle\"></div>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div id=\"local-recording-settings-container\">\n        <div class=\"row settings-list-secundary\"\n             data-nav-area=\".btn-switch, .focusable:not(:disabled)\"\n             data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.recording_custom_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":40},"end":{"line":41,"column":78}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-2 pull-right\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"recordingRenameEnabled\"\n                           id=\"recordingRenameEnabled\"\n                           type=\"checkbox\"\n                           class=\"btn-switch\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row settings-list-secundary settings-list-info hdcp-deactivation-info hidden\">\n        <div class=\"col-xs-12\">\n            <p class=\"input-info-message error-message\">\n                "
    + alias3((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias2).call(alias1,depth0,{"name":"tr","hash":{"key":"settings.hdcp_deactivation_info"},"data":data,"loc":{"start":{"line":60,"column":16},"end":{"line":60,"column":65}}}))
    + "\n            </p>\n        </div>\n    </div>\n\n    <div id=\"form-action-container\" class=\"form-primary-background\"></div>\n</form>\n";
},"useData":true});
