'use strict';

const app = require('../../app');
const controlScreenTpl = require('./control-screen.html');
const Vuex = require('vuex');
const vueUtils = require('../../components-vue/util.js');
const platform = require('../../../platform/platform');

app.component('ControlScreen', {
    engine: 'vue',
    template: controlScreenTpl,
    components: {
        'live-area': require('./live-area/live-area.js'),
        'prepare-area': require('./prepare-area/prepare-area.js'),
        'edit-mode': require('./edit-mode/edit-mode.js')
    },

    data: function() {
        return {
            showEditMode: false,
            showControlScreen: true
        };
    },

    computed: {
        isControlScreen: function() {
            return platform.checks.isCboxControlScreen;
        },
        ...Vuex.mapGetters('controlScreen', ['isEditModeActive', 'isWebConferenceRunning'])
    },

    watch: {
        isEditModeActive: function(newValue, oldValue) {
            if (newValue && !oldValue) {
                // Transition from edit mode inactive to active
                app.emit('edit-mode.opened');
                this.showEditMode = true;
                this.showControlScreen = false;
            } else if (!newValue && oldValue) {
                // Transition from edit mode active to inactive
                this.showEditMode = false;
                this.showControlScreen = true;
            }
        },

        isWebConferenceRunning: function(value) {
            if (value) {
                app.emit('webconference-service.available');
            }
        }
    },

    methods: {
        waitAfterRepaint: function(openEditCallback) {
            this.showControlScreen = false;

            // Wait after the repaint of showControlScreen state change before actually opening the edit mode
            requestAnimationFrame(() => {
                // Hook right before the next repaint
                requestAnimationFrame(() => {
                    // Queue the callback to be directly after the repaint
                    setTimeout(openEditCallback);

                    // Safety measure if edit mode doesn't open within 500ms show control screen again
                    setTimeout(() => {
                        if (!this.isEditModeActive) {
                            this.showControlScreen = true;
                        }
                    }, 2500);
                });
            });
        },
        startEditMode: function(appId) {
            this.waitAfterRepaint(() => {
                this.wolfprot.talk('setEditMode', {
                    appId: appId,
                    action: 'start'
                });
            });
        },

        openOutputInEditMode: function(data) {
            this.waitAfterRepaint(() => {
                this.wolfprot.talk('setOutputToEdit', {
                    output: data.output,
                    show: true
                });
            });
        },

        stopEditMode: function(data) {
            let editStopCmd;

            if (data.appId) {
                editStopCmd = {
                    command: 'setEditMode',
                    data: {
                        appId: data.appId,
                        action: 'stop'
                    }
                };
            } else {
                editStopCmd = {
                    command: 'setOutputToEdit',
                    data: {
                        show: false
                    }
                };
            }

            this.wolfprot.talk(editStopCmd.command, editStopCmd.data)
                .then(function() {
                    app.emit('main-loop.fast.start', {
                        id: 'confidence-monitors'
                    });
                }.bind(this));
        }
    },

    created: function() {
        this.wolfprot = vueUtils.wolfprot();
        this.evctx = vueUtils.eventContext();

        this.evctx.on('overlay.opened-end', function() {
            this.$store.dispatch('controlScreen/setOverlayOpen', true);
        }.bind(this));
        this.evctx.on('overlay.closed', function() {
            this.$store.dispatch('controlScreen/setOverlayOpen', false);
        }.bind(this));
    },

    beforeMount: function() {
        if (platform.checks.isRemote) {
            this.$el.parentElement.style.width = '100%';
            this.$el.parentElement.style.height = '100%';
        }
    },

    destroy: function() {
        this.evctx.close();
    }
});
