'use strict';

var app = require('../../../app');
var $ = require('jquery');
var hdcpTpl = require('./hdcp-settings.hbs');
var FormActionView = require('../form-action-view');

app.component('HDCPSettings', {
    template: hdcpTpl,
    actionView: null,

    /**
     * @method initialize
     * @returns {Object}
     */
    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.deviceService = this.getService('DeviceService');
    },

    serialize: function() {
        return {
            hdcpTypes: app.getService('Model-View').getHDCPOutputTypes()
        };
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.bindEvents();
        this.createSelects();
        this.initForm();
        this.initFormAction();
        this.loadHDCPSettings();
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
    },

    bindEvents: function() {
        this.on('settings.save-changes', this.saveHandler.bind(this));
    },

    /**
     * @method initFormAction
     */
    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    /**
     * @method initForm
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#hdcp-settings'),
            validationContainer: '.input-group'
        });
    },

    /**
     * @method saveHandler
     */
    saveHandler: function() {
        if (this.form.validate()) {
            if (this.deviceService.isCboxCoreOrCorePro()) {
                this.deviceConnection
                    .send([
                        {
                            command: 'setHdcpSettings',
                            data: {
                                interface: 'mainout',
                                enable: this.form.get('main-out').getValue()
                            }
                        },
                        {
                            command: 'setHdcpSettings',
                            data: {
                                interface: 'in1',
                                enable: this.form.get('in1').getValue()
                            }
                        }
                    ])
                    .done(function() {
                        this.handleSettingsSave();
                        this.form.setDefaultValues();
                        this.emit('overlay.remote.focus', true);
                    }.bind(this));
            } else {
                this.deviceConnection
                    .send([
                        {
                            command: 'setHdcpSettings',
                            data: {
                                interface: 'mainout',
                                enable: this.form.get('main-out').getValue()
                            }
                        },
                        {
                            command: 'setHdcpSettings',
                            data: {
                                interface: 'auxout',
                                enable: this.form.get('aux-out').getValue()
                            }
                        },
                        {
                            command: 'setHdcpSettings',
                            data: {
                                interface: 'in1',
                                enable: this.form.get('in1').getValue()
                            }
                        },
                        {
                            command: 'setHdcpSettings',
                            data: {
                                interface: 'in2',
                                enable: this.form.get('in2').getValue()
                            }
                        }
                    ])
                    .done(function() {
                        this.handleSettingsSave();
                        this.form.setDefaultValues();
                        this.emit('overlay.remote.focus', true);
                    }.bind(this));
            }
        }
    },

    /**
     * Calls on clicked the cancel button
     * @method cancelHandler
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
        this.changes = false;
    },

    /**
     * @method handleSettingsSave
     */
    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.changes = false;
    },

    /**
     * @method handleFormChange
     */
    handleFormChange: function(data) {
        this.actionView.open();
        this.checkActionForm();
        this.changes = true;

        if (this.deviceService.isCboxProDualProjection() && ['in1', 'in2'].includes(data.input.name)
            && this.form.get(data.input.name).getValue()) {
            app.emit('modal.open', {
                id: 'warning',
                isInfo: true,
                textAlign: 'justify',
                className: 'flexible-width',
                width: '540px',
                messageKey: 'settings.hdcp_copyright',
                successTextKey: 'settings.accept',
                discareTextKey: 'settings.cancel',
                onConfirm: function() {
                    app.emit('modal.open', {
                        id: 'login',
                        user: 'admin',
                        navPrevention: true,
                        preventClose: true,
                        isWarning: true,
                        cancelLoginHandler: function() {
                            this.form.get(data.input.name).resetValue();
                            this.emit('modal.close', { id: 'login' });
                        }.bind(this)
                    });
                }.bind(this),
                onDiscare: function() {
                    this.form.get(data.input.name).resetValue();
                }.bind(this)
            });
        }
    },

    /**
     * @method checkActionForm
     */
    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * @method loadHDCPSettings
     */
    loadHDCPSettings: function() {
        this.deviceConnection
            .send([
                { command: 'getHdcpSettings', data: { interface: 'mainout' } },
                { command: 'getHdcpSettings', data: { interface: 'auxout' } },
                { command: 'getHdcpSettings', data: { interface: 'in1' } },
                { command: 'getHdcpSettings', data: { interface: 'in2' } }
            ])
            .then(function(mainOut, auxOut, in1, in2) {
                if (this.deviceService.isCboxCoreOrCorePro()) {
                    this.formData = {
                        'main-out': mainOut.hdcpEnabled,
                        'in1': in1.hdcpEnabled
                    };
                } else {
                    this.formData = {
                        'main-out': mainOut.hdcpEnabled,
                        'aux-out': auxOut.hdcpEnabled,
                        'in1': in1.hdcpEnabled,
                        'in2': in2.hdcpEnabled
                    };
                }

                this.updateForm();
                this.bindDOMEvents();
            }.bind(this));
    },

    /**
     * @method updateForm
     */
    updateForm: function() {
        this.form.setValues(this.formData);
    },

    /**
     * @method creatSelects
     */
    createSelects: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#interface-select'),
            label: 'settings.interface',
            native: true,
            name: 'interface',
            items: app.getService('Model-View').getHDCPOutputTypes()
        });
    },

    /**
     * @method destroy
     */
    destroy: function() {
        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    hasChanges: function() {
        if (!this.actionView) {
            return {
                hasChanges: false,
                invalid: false
            };
        }

        return {
            hasChanges: this.changes,
            invalid: $(this.actionView.$submitBtn).prop('disabled')
        };
    }
});
