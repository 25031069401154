// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.login_admin_pin_info_dual_projection",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":209,"column":27},"end":{"line":209,"column":80}}}))
    + "</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.login_admin_pin_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":211,"column":27},"end":{"line":211,"column":64}}}))
    + "</p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                        <div class=\"col-xs-6\">\n                            <h2 class=\"input-label\">"
    + alias1((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"nameKey") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":220,"column":52},"end":{"line":220,"column":70}}}))
    + "</h2>\n                        </div>\n                        <div class=\"col-xs-2 pull-right\">\n                            <div class=\"btn-switch-container pull-right\">\n                                <input name=\"pin-destination-"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\"\n                                       id=\"pin-destination-"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\"\n                                       type=\"checkbox\"\n                                       class=\"btn-switch\" />\n                                <div class=\"btn-switch-toggle-container\">\n                                    <div class=\"btn-switch-toggle\"></div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-row\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.webbrowser_viewer",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":328,"column":24},"end":{"line":328,"column":58}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"webbrowserViewerEnabled\"\n                               name=\"webbrowserViewerEnabled\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div id=\"viewer-container\" class=\"hidden\">\n                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\".btn-switch\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-9\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.pin_required",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":348,"column":48},"end":{"line":348,"column":77}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-3\">\n                        <div class=\"input-group flex\">\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message\" data-user=\"webbrowserViewerPinEnabled\">\n                                    "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.pin_config_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":354,"column":36},"end":{"line":354,"column":68}}}))
    + "\n                                </span>\n                            </div>\n                            <div class=\"btn-switch-container pull-right no-padding p-left-15\">\n                                <input name=\"webbrowserViewerPinEnabled\"\n                                       id=\"webbrowserViewerPinEnabled\"\n                                       type=\"checkbox\"\n                                       class=\"btn-switch\"/>\n                                <div class=\"btn-switch-toggle-container\">\n                                    <div class=\"btn-switch-toggle\"></div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\".is-focusable:not(:disabled)\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div id=\"viewer-resolution-select\"></div>\n                    </div>\n                </div>\n                <div id=\"warning-message-container\" class=\"row settings-list-secundary settings-list-info hidden\">\n                    <div class=\"col-xs-12\">\n                        <p class=\"input-info-message error-message\"> "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.viewer_warning_message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":378,"column":69},"end":{"line":378,"column":108}}}))
    + "</p>\n                    </div>\n                </div>\n            </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-row\" data-nav-area=\".btn-switch, .focusable\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.login_ldap",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":388,"column":24},"end":{"line":388,"column":51}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"ldap-require\"\n                               id=\"ldap-require\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <label class=\"input-label\" for=\"certificate-file\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.ldap_certificate_file",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":461,"column":78},"end":{"line":461,"column":116}}}))
    + "</label>\n                            <label class=\"input-label ldap-filename\" id=\"ldap-filename\"></label>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <label class=\"input-label\" for=\"certificate-file\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ldap_certificate_file",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":464,"column":78},"end":{"line":464,"column":116}}}))
    + "</label>\n                            <label class=\"input-label ldap-filename\" id=\"ldap-filename\"></label>\n                            <input type=\"file\"\n                                   class=\"input focusable\"\n                                   name=\"certificate-file\"\n                                   id=\"certificate-file\"\n                                   tabIndex=\"-1\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_certificate",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":472,"column":79},"end":{"line":473,"column":68}}}))
    + "</span>\n                            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <div id=\"mirroring-compomnent-container\"></div>\n    <form id=\"login-settings\"\n          name=\"input-settings\"\n          data-nav-area=\".input:not([disabled]), .btn-switch, .focusable:not(:disabled)\"\n          data-nav-trapped\n          data-nav-area-default>\n        <!-- admin -->\n        <div class=\"row settings-list-row\">\n            <div class=\"col-xs-12\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.login_admin",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":11,"column":48}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-6\"></div>\n        </div>\n\n        <div class=\"row settings-list-secundary\">\n            <div class=\"col-xs-12\">\n                <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.login_admin_change_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":19},"end":{"line":18,"column":63}}}))
    + "</p>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\">\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"admin-password\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.login_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":68},"end":{"line":25,"column":99}}}))
    + "</label>\n                    <input type=\"password\"\n                           id=\"admin-password\"\n                           class=\"input is-focusable\"\n                           autocomplete=\"new-password\"\n                           name=\"admin-password\"\n                           placeholder=\"\"\n                           tabIndex=\"-1\"\n                           data-validation=\"checkPasswordStrengthIfExists\"\n                           data-pwd-length-min=\"6\"\n                           data-pwd-length-max=\"63\"\n                           data-allow-empty=\"true\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.wrong_password_length_6_63",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":28},"end":{"line":39,"column":86}}}))
    + "\n                        </span>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\"\n                           for=\"admin-password-repeat\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.login_password_repeat",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":55},"end":{"line":48,"column":93}}}))
    + "</label>\n                    <input type=\"password\"\n                           id=\"admin-password-repeat\"\n                           class=\"input is-focusable\"\n                           autocomplete=\"new-password\"\n                           name=\"admin-password-repeat\"\n                           placeholder=\"\"\n                           tabIndex=\"-1\"\n                           data-validation=\"checkPasswordRepeat\"\n                           data-main-password-selector=\"#admin-password\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.wrong_password_repeated",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":28},"end":{"line":60,"column":83}}}))
    + "\n                        </span>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\"\n             id=\"admin-pin-container\"\n             data-nav-area=\".btn-switch\"\n             data-nav-trapped>\n            <div class=\"col-xs-9\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.admin_login_pin",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":40},"end":{"line":72,"column":72}}}))
    + "</h2>\n            </div>\n\n            <div class=\"col-xs-3 pull-right\">\n                <div class=\"input-group flex\">\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message\" data-user=\"admin-pin\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.pin_config_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":28},"end":{"line":79,"column":60}}}))
    + "\n                        </span>\n                    </div>\n                    <div class=\"btn-switch-container pull-right no-padding p-left-15\">\n                        <input name=\"admin-pin\"\n                               id=\"admin-pin\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"row settings-list-secundary\"\n             id=\"admin-pin-only-while-presenting-container\"\n             data-nav-area=\".btn-switch\"\n             data-nav-trapped>\n            <div class=\"col-xs-9\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.admin_login_pin_only_while_presenting",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":99,"column":40},"end":{"line":99,"column":94}}}))
    + "</h2>\n            </div>\n\n            <div class=\"col-xs-2 pull-right\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"admin-pin-only-while-presenting\"\n                           id=\"admin-pin-only-while-presenting\"\n                           type=\"checkbox\"\n                           class=\"btn-switch\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <!-- Moderator -->\n        <div class=\"row settings-list-row\">\n            <div class=\"col-xs-12\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.login_guest",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":118,"column":20},"end":{"line":118,"column":48}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-6\"></div>\n        </div>\n        <div class=\"row settings-list-secundary hidden\"\n             id=\"guest-password-require-container\"\n             data-nav-area=\".btn-switch\"\n             data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.password_required",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":127,"column":40},"end":{"line":127,"column":74}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-2 pull-right\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"guest-password-require\"\n                           id=\"guest-password-require\"\n                           type=\"checkbox\"\n                           class=\"btn-switch\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"row settings-list-secundary hidden\"\n             id=\"guest-password-container\"\n             data-nav-area=\".is-focusable:not(:disabled)\"\n             data-nav-trapped>\n            <div class=\"col-xs-12\">\n                <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.login_guest_change_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":146,"column":19},"end":{"line":146,"column":63}}}))
    + "</p>\n            </div>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"guest-password\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.login_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":150,"column":68},"end":{"line":150,"column":99}}}))
    + "</label>\n                    <input type=\"password\"\n                           id=\"guest-password\"\n                           class=\"input is-focusable\"\n                           autocomplete=\"new-password\"\n                           name=\"guest-password\"\n                           placeholder=\"\"\n                           tabIndex=\"-1\"\n                           data-validation=\"checkPasswordStrengthIfExists\"\n                           data-pwd-length-min=\"6\"\n                           data-pwd-length-max=\"63\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.wrong_password_length_6_63",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":163,"column":28},"end":{"line":163,"column":86}}}))
    + "\n                        </span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"col-xs-6\"></div>\n        </div>\n        <div class=\"row settings-list-secundary hidden\"\n             id=\"guest-pin-container\"\n             data-nav-area=\".btn-switch\"\n             data-nav-trapped>\n            <div class=\"col-xs-9\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.moderator_login_pin",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":175,"column":40},"end":{"line":175,"column":76}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-3 pull-right\">\n                <div class=\"input-group flex\">\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message\" data-user=\"guest-pin\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.pin_config_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":181,"column":28},"end":{"line":181,"column":60}}}))
    + "\n                        </span>\n                    </div>\n                    <div class=\"btn-switch-container pull-right no-padding p-left-15\">\n                        <input name=\"guest-pin\"\n                               id=\"guest-pin\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <!-- Destination -->\n        <div id=\"pin-destinations-container\" class=\"hidden\">\n            <div class=\"row settings-list-row\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.pin_destination",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":201,"column":24},"end":{"line":201,"column":56}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-6\"></div>\n            </div>\n\n            <div id=\"admin-pin-destination\" class=\"row settings-list-secundary hidden\">\n                <div class=\"col-xs-12\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDualProjection") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":208,"column":20},"end":{"line":212,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n\n            <div id=\"pin-destinations\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"pinDestinations") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":217,"column":16},"end":{"line":234,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <!-- Connection ID -->\n        <div class=\"row settings-list-row\"\n             data-nav-area=\".btn-switch\"\n             data-nav-trapped>\n            <div class=\"col-xs-9\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.connection_id_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":243,"column":20},"end":{"line":243,"column":57}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-3\">\n                <div class=\"input-group flex\">\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message\" data-user=\"connection-id-enable\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.pin_config_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":249,"column":28},"end":{"line":249,"column":60}}}))
    + "\n                        </span>\n                    </div>\n                    <div class=\"btn-switch-container pull-right no-padding p-left-15\">\n                        <input name=\"connection-id-enable\"\n                               id=\"connection-id-enable\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary hidden\"\n             id=\"connection-id-interface\"\n             data-nav-area=\".is-focusable:not(:disabled)\"\n             data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div name=\"connection-id-interface-select\" id=\"connection-id-interface-select\"></div>\n            </div>\n            <div class=\"col-xs-6\"></div>\n        </div>\n\n        <!-- RMS -->\n        <div class=\"row settings-list-row\" data-nav-area=\".btn-switch\" data-nav-trapped>\n            <div class=\"col-xs-8\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.login_rms",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":279,"column":20},"end":{"line":279,"column":46}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input id=\"rmsPassword\"\n                           name=\"rmsPassword\"\n                           type=\"checkbox\"\n                           class=\"btn-switch\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary hidden\"\n             id=\"rms-password-container\"\n             data-nav-area=\".is-focusable:not(:disabled)\"\n             data-nav-trapped>\n            <div class=\"col-xs-12\">\n                <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.login_rms_change_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":299,"column":19},"end":{"line":299,"column":61}}}))
    + "</p>\n            </div>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"rms-password\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.login_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":303,"column":66},"end":{"line":303,"column":97}}}))
    + "</label>\n                    <input type=\"password\"\n                           id=\"rms-password\"\n                           class=\"input is-focusable\"\n                           autocomplete=\"new-password\"\n                           name=\"rms-password\"\n                           placeholder=\"\"\n                           tabIndex=\"-1\"\n                           data-validation=\"checkPasswordStrengthIfExists\"\n                           data-pwd-length-min=\"6\"\n                           data-pwd-length-max=\"63\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.wrong_password_length_6_63",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":316,"column":28},"end":{"line":316,"column":86}}}))
    + "\n                        </span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"col-xs-6\"></div>\n        </div>\n\n        <!-- Webbrowser Viewer Support -->\n"
    + ((stack1 = (lookupProperty(helpers,"unless_or")||(depth0 && lookupProperty(depth0,"unless_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureReceiver") : depth0),{"name":"unless_or","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":325,"column":8},"end":{"line":382,"column":22}}})) != null ? stack1 : "")
    + "\n        <!-- LDAP -->\n"
    + ((stack1 = (lookupProperty(helpers,"unless_or")||(depth0 && lookupProperty(depth0,"unless_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPure") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"unless_or","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":385,"column":8},"end":{"line":402,"column":22}}})) != null ? stack1 : "")
    + "\n        <div id=\"ldap-container\" class=\"hidden\">\n            <div class=\"row settings-list-secundary\"\n                 id=\"ldap-container\"\n                 data-nav-area=\".btn-switch, .focusable, .input\" data-nav-trapped>\n\n                <div class=\"col-xs-6\">\n                    <div class=\"input-group\">\n                        <label class=\"input-label\" for=\"url\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.server_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":411,"column":61},"end":{"line":411,"column":88}}}))
    + "</label>\n                        <input type=\"text\"\n                               class=\"input\"\n                               name=\"server-url\"\n                               id=\"server-url\"\n                               data-validation=\"checkLdapUrl\"\n                               data-max-length=\"254\" />\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\" id=\"url-error\">\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":420,"column":32},"end":{"line":420,"column":58}}}))
    + "\n                            </span>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"col-xs-6\">\n                    <div class=\"input-group\">\n                        <label class=\"input-label\" for=\"domain\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.server_domain",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":428,"column":64},"end":{"line":428,"column":94}}}))
    + "</label>\n                        <input type=\"text\"\n                               class=\"input\"\n                               name=\"server-domain\"\n                               id=\"server-domain\"\n                               data-validation=\"checkLength\"\n                               data-max-length=\"254\" />\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\" id=\"url-error\">\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":437,"column":32},"end":{"line":437,"column":61}}}))
    + "\n                            </span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch, .focusable, .input\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <label class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.tls_mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":446,"column":47},"end":{"line":446,"column":72}}}))
    + "</label>\n                    <div class=\"btn-switch-container\">\n                        <input name=\"tls-mode\"\n                               id=\"tls-mode\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"col-xs-6\">\n                    <div class=\"input-group certificate-file-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":460,"column":24},"end":{"line":475,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"form-action-container\"></div>\n    </form>\n</div>\n";
},"useData":true});
