'use strict';

var _ = require('lodash');
var $ = require('jquery');

/**
 * ConnectionHelper
 * @param {App} app
 * @param {Component} instance
 * @constructor
 */
var ConnectionHelper = function(app, instance) {
    this.connection = null;
    this.app = app;
    this.instance = instance;

    this.initialize();
};

/**
 * @method initialize
 */
ConnectionHelper.prototype.initialize = function() {
    this.app.getService('ConnectionFactoryService')
        .afterCreated('device', function(connection) {
            this.connection = connection;
        }.bind(this));
};

/**
 * @method send
 * @param {array, string} command
 * @param {object} data
 * @returns {*}
 */
ConnectionHelper.prototype.send = function(command, data) {
    return this.connection.send(command, data)
        .then(function() {
            var dfd = $.Deferred();

            if (this.instance.isAlive()) {
                dfd.resolve.apply(null, arguments);
            } else {
                dfd.reject.apply(null, arguments);
                this.printErrorMessage(command);
            }

            return dfd.promise();
        }.bind(this));
};

/**
 * @method printErrorMessage
 * @param {string, array} command
 */
ConnectionHelper.prototype.printErrorMessage = function(command) {
    var output = '';

    if (_.isArray(command)) {
        _.each(command, function(cmd) {
            if (typeof cmd !== 'string') {
                output += cmd.command + ', ';
            } else {
                output += cmd + ', ';
            }
        });
    } else {
        output = command;
    }

    window.app.getService('ExceptionsManager')
        .throw('Callback in "' + this.instance.id + '" for Command "' + output + '" blocked!');
};

module.exports = ConnectionHelper;
