'use strict';

var app = require('../../../app');
var _ = require('lodash');
var $ = require('jquery');
var template = require('./login-settings.hbs');
var FormActionView = require('./../form-action-view');
var platform = require('../../../../platform/platform');

var states = {
    visible: 'visible',
    hidden: 'hidden'
};

var pinDestinations = {
    'box': {
        key: 'box',
        nameKey: 'settings.pin_destination_box'
    },
    'vz': {
        key: 'vz',
        nameKey: 'settings.pin_destination_vz'
    },
    'local': {
        key: 'local',
        nameKey: 'settings.pin_destination_local'
    },
    'room': {
        key: 'room',
        nameKey: 'settings.pin_destination_room'
    }
};

var ANIMATION_DURATION = 200;

app.component('LoginSettings', {
    template: template,
    actionView: null,
    init: false,

    initialize: function() {
        this.frontendSettings = this.getService('FrontendSettings');
        this.formManager = this.getService('FormManager');
        this.validate = this.getService('ValidateService');
        this.remote = this.getService('RemoteService');
        this.outputService = this.getService('OutputService');
        this.deviceService = this.getService('DeviceService');
        this.isDualProjection = this.deviceService.isCboxProDualProjection();
        this.formData = {};

        this.pwdState = this.createStateMachine({
            state: states.hidden,
            states: states
        });

        this.pinState = this.createStateMachine({
            state: states.hidden,
            states: states
        });

        this.ldapState = this.createStateMachine({
            state: states.hidden,
            states: states
        });

        this.bindEvents();
    },

    /**
     * @returns {object}
     */
    serialize: function() {
        if (this.isDualProjection) {
            pinDestinations['local'].nameKey = 'settings.pin_destination_control_screen';
        } else if (this.outputService.isModerator()) {
            pinDestinations['hdmi2'] = {
                key: 'hdmi2',
                nameKey: 'settings.pin_destination_hdmi2'
            };
        } else {
            delete pinDestinations['hdmi2'];
        }

        if (this.deviceService.isCboxCoreOrCorePro() || this.deviceService.isCboxPro()) {
            delete pinDestinations['box'];
        }

        if (this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) {
            delete pinDestinations['box'];
            delete pinDestinations['vz'];
        }

        return {
            pinDestinations: pinDestinations,
            isCbox: platform.checks.isCbox,
            isCboxPure: this.deviceService.isCboxPure(),
            isCboxPureMini: this.deviceService.isCboxPureMini(),
            isCboxPureReceiver: this.deviceService.isCboxPureReceiver(),
            isDualProjection: this.deviceService.isCboxProDualProjection()
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.createSelects();
        this.initForm();
        this.initFormAction();

        $.when(this.loadLoginSettings()).done(function() {
            this.bindDOMEvents();
            this.showPasswordRequired();
            this.updateRmsPasswordContainer();
        }.bind(this));
    },

    storeSelectors: function() {
        this.$pwdRequireContainer = this.$el.find('#guest-password-require-container');
        this.$pinContainer = this.$el.find('#guest-pin-container');
        this.$pwdContainer = this.$el.find('#guest-password-container');
        this.$viewerContainer = this.$el.find('#viewer-container');
        this.$viewerPinInfo = this.$el.find('#viewer-pin-info');
        this.$destinationsContainer = this.$el.find('#pin-destinations-container');
        this.$adminPinDestination = this.$el.find('#admin-pin-destination');
        this.$pinDestinations = this.$el.find('#pin-destinations');
        this.$adminPinOnlyWhilePresenting = this.$el.find('#admin-pin-only-while-presenting-container');
        this.$pwdRmsContainer = this.$el.find('#rms-password-container');
        this.$ldapContainer = this.$el.find('#ldap-container');
        this.$connectionIdInterface = this.$el.find('#connection-id-interface');
        this.$warningMessageContainer = this.$el.find('#warning-message-container');
    },

    bindDOMEvents: function() {
        if (!this.deviceService.isCboxPure() && !this.deviceService.isCboxPureMini()) {
            this.form.get('ldap-require').$el.on('change', this.updateLdapContainer.bind(this));
        }

        this.form.get('guest-password-require').$el.on('change', this.checkPasswordMode.bind(this));
        this.form.get('guest-pin').$el.on('change', this.checkPasswordMode.bind(this));
        this.form.get('admin-pin').$el.on('change', this.onAdminPinChanged.bind(this));
        this.form.get('rmsPassword').$el.on('change', this.updateRmsPasswordContainer.bind(this));
        this.form.on('change.input', this.handleFormChange.bind(this));
    },

    bindEvents: function() {
        this.on('remote.enter.keydown', this.checkActionForm.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
    },

    /**
     * Called when the admin-pin checkbox has been clicked.
     */
    onAdminPinChanged: function() {
        this.checkPinMode();
    },

    handleFormChange: function(data) {
        if (!this.deviceService.isCboxPureMini() && !this.deviceService.isCboxPureReceiver()) {
            this.updateViewerResolutionMessage();
        }

        if (data.input.name === 'webbrowserViewerEnabled') {
            this.updateViewerContainer();
        }

        if (data.input.name === 'webbrowserViewerPinEnabled' || data.input.name === 'webbrowserViewerEnabled'
            || data.input.name === 'guest-pin' || data.input.name === 'guest-password-require'
            || data.input.name === 'admin-pin' || data.input.name === 'connection-id-enable'
        ) {
            this.updatePinInfo(data.input.name);
        }

        if (data.input.name === 'connection-id-enable') {
            this.updateConnectionID(data.input);
        }

        this.actionView.open();
        this.checkActionForm();
        this.changes = true;
    },

    /**
     * Updates the connection id.
     *
     * @param input
     */
    updateConnectionID: function(input) {
        let connectionIdEnable;

        if (!input) {
            connectionIdEnable = this.formData.connectionIdEnable;
        } else {
            connectionIdEnable = input.value;
        }

        if (connectionIdEnable) {
            this.$connectionIdInterface
                .stop()
                .slideDown(ANIMATION_DURATION);
        } else {
            this.$connectionIdInterface
                .stop()
                .slideUp(ANIMATION_DURATION);
        }
    },

    /**
     * Show info messages for PIN configuration.
     *
     * @param user Show Pin Configuration Info for user (guest/moderator, admin or viewer) true/false
     */
    updatePinInfo: function(user) {
        const $info = this.$el.find('.input-info-message[data-user="' + user + '"]');

        if (this.form.get(user).getValue()) {
            $info.show();
        } else {
            $info.hide();
        }
    },

    checkActionForm: function() {
        this.setGuestPasswordValidation();
        this.setRmsPasswordValidation();

        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * Allow empty Password field.
     */
    setGuestPasswordValidation: function() {
        if (this.pwdState.getState() === states.visible) {
            this.$pwdContainer.find('#guest-password').attr('data-pwd-validate', 'true');
        } else {
            this.$pwdContainer.find('#guest-password').attr('data-pwd-validate', 'false');
        }
    },

    setRmsPasswordValidation: function() {
        if (this.form.get('rmsPassword').getValue()) {
            this.$pwdRmsContainer.find('#rms-password').attr('data-pwd-validate', 'true');
        } else {
            this.$pwdRmsContainer.find('#rms-password').attr('data-pwd-validate', 'false');
        }
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#login-settings'),
            validationContainer: '.input-group'
        });
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }
        this.actionView.render();
    },

    loadLoginSettings: function() {
        return $.when(this.loadBackendSettings(this), this.loadFrontendSettings(this))
            .done(this.serializeData.bind(this));
    },

    loadFrontendSettings: function() {
        return this.frontendSettings.getSetting('viewerResolution')
            .then(function(viewerResolution) {
                if (!this.deviceService.isCboxPureMini() && !this.deviceService.isCboxPureReceiver()) {
                    this.formData.viewerResolution = viewerResolution;
                }
            }.bind(this));
    },

    loadBackendSettings: function() {
        return this.deviceConnection
            .send([
                'getPresentationMode',
                'getRemotePasswordRequired',
                'getPinDestination',
                'getPasswordType',
                'getRmsPasswordMode',
                'getUserPassword',
                'getRmsPassword',
                'getAdminPinStatus',
                'getAdminPinOnlyWhilePresenting',
                'getWebbrowserViewerSupport',
                'getWebbrowserViewerPinEnabled',
                'getLdapAuthMode',
                'getLdapTlsMode',
                'getLdapServerUrl',
                'getLdapServerDomain',
                'getLdapCertificateFileName',
                'getConnectionIdEnable',
                'getConnectionIdInterface'
            ])
            .then(function(
                presentationMode,
                passwordRequired,
                pinDestination,
                passwordType,
                rmsPasswordMode,
                userPassword,
                rmsPassword,
                adminPinStatus,
                adminPinOnlyWhilePresenting,
                webbrowserViewerSupport,
                webbrowserViewerPinEnabled,
                ldapAuthMode,
                ldapTlsMode,
                ldapServerUrl,
                ldapServerDomain,
                ldapCertName,
                connectionIdEnable,
                connectionIdInterface
            ) {
                this.formData.passwordRequired = passwordRequired.password;
                this.formData.pinDestination = pinDestination;
                this.formData.passwordType = passwordType.passwordType;
                this.formData.rmsPasswordMode = rmsPasswordMode.passwordRequired;
                this.formData.guestPassword = userPassword.password;
                this.formData.rmsPassword = rmsPassword.password;
                this.formData.adminPinStatus = adminPinStatus.status;
                this.formData.adminPinOnylWhilePresenting = adminPinOnlyWhilePresenting.enabled;
                this.formData.webbrowserViewerEnabled = webbrowserViewerSupport.enabled;
                this.formData.webbrowserViewerPinEnabled = webbrowserViewerPinEnabled.enabled;
                this.formData.ldapAuthMode = ldapAuthMode.enabled;
                this.formData.ldapTlsMode = ldapTlsMode.enabled;
                this.formData.ldapServerUrl = ldapServerUrl.serverUrl;
                this.formData.ldapServerDomain = ldapServerDomain.serverDomain;
                this.formData.ldapCertName = ldapCertName.fileName;
                this.formData.connectionIdEnable = connectionIdEnable.enable;
                this.formData.connectionIdInterface = connectionIdInterface.interface;
            }.bind(this));
    },

    serializeData: function() {
        var data = this.formData;
        var guestPinStatus = false;

        if (data.passwordType === 'pin') {
            guestPinStatus = true;
        }

        this.form.setValues({
            'guest-password-require': data.passwordRequired,
            'guest-pin': guestPinStatus,
            'admin-pin': data.adminPinStatus,
            'admin-pin-only-while-presenting': data.adminPinOnylWhilePresenting,
            'admin-password': '',
            'admin-password-repeat': '',
            'guest-password': data.guestPassword,
            'rmsPassword': data.rmsPasswordMode,
            'rms-password': data.rmsPassword,
            'webbrowserViewerEnabled': data.webbrowserViewerEnabled,
            'webbrowserViewerPinEnabled': data.webbrowserViewerPinEnabled,
            'server-url': data.ldapServerUrl,
            'server-domain': data.ldapServerDomain,
            'tls-mode': data.ldapTlsMode,
            'ldap-require': data.ldapAuthMode,
            'connection-id-enable': data.connectionIdEnable,
            'connectionIdInterface': data.connectionIdInterface,
            'viewerResolution': data.viewerResolution
        });

        this.$el.find('.ldap-filename').text(': ' + data.ldapCertName);

        // Set pin destinations
        if (data.pinDestination
            && data.pinDestination.destinations
            && data.pinDestination.destinations.length > 0
        ) {
            _.forEach(data.pinDestination.destinations, function(itemKey) {
                if (!this.form.get('pin-destination-' + itemKey)) {
                    return;
                }

                this.form.get('pin-destination-' + itemKey).setValue(true);
            }.bind(this));
        }

        this.init = false;
        this.checkPinMode();
        this.checkPasswordMode();
        this.updateRmsPasswordContainer();
        this.updateLdapContainer();
        this.updateViewerContainer();

        if (!this.deviceService.isCboxPureMini() && !this.deviceService.isCboxPureReceiver()) {
            this.updatePinInfo('webbrowserViewerPinEnabled');
        }

        this.updatePinInfo('guest-pin');
        this.updatePinInfo('admin-pin');
        this.updatePinInfo('connection-id-enable');
        this.updateConnectionID();
        this.form.setDefaultValues();
        this.init = true;
    },

    /**
     * Handle settings save.
     */
    saveHandler: function() {
        this.$.when(
            this.updateAdminPassword(),
            this.updateUserPassword(),
            this.updateUserPasswordType(),
            this.updatePinDestinations(),
            this.updateAdminPin(),
            this.updateRmsPasswordMode(),
            this.updateRmsPassword(),
            this.updateWebbrowserViewer(),
            this.updateLdap(),
            this.updateConnectionIdSettings(),
            this.updateViewerResolution()
        ).done(this.handleSettingsSave.bind(this));

        if (!platform.checks.isCbox) {
            this.$.when(
                this.saveCertificate()
            ).done(setTimeout(function() {
                this.updateLdapFileName();
            }.bind(this), 1000));
        }
    },

    /**
     * Save settings into form and update buttons.
     */
    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });
        this.form.setDefaultValues();
        this.emit('overlay.remote.focus', true);
        this.changes = false;
    },

    /**
     * Update pin destinations (statusbar, local/remote,...)
     */
    updatePinDestinations: function() {
        var destinations = [];

        if (this.form.get('admin-pin').getValue()) {
            destinations.push('local');
        } else {
            _.forEach(pinDestinations, function(item) {
                var value = this.form.get('pin-destination-' + item.key).getValue();

                if (value) {
                    destinations.push(item.key);
                }
            }.bind(this));
        }

        return this.deviceConnection
            .send('setPinDestination', {
                destinations: destinations
            });
    },

    /**
     * Update Admin-Pin status.
     *
     * @returns {object}
     */
    updateAdminPin: function() {
        var pinStatus = this.form.get('admin-pin').getValue() ? 'enable' : 'disable';
        var pinOnlyWhilePresenting = this.form.get('admin-pin-only-while-presenting').getValue();

        return this.deviceConnection
            .send([
                {
                    command: 'setAdminPinStatus',
                    data: {
                        status: pinStatus
                    }
                },
                {
                    command: 'setAdminPinOnlyWhilePresenting',
                    data: {
                        enabled: pinOnlyWhilePresenting
                    }
                }
            ]);
    },

    /**
     * Update Admin password.
     */
    updateAdminPassword: function() {
        var password = this.form.get('admin-password').getValue();

        if (password.length > 0) {
            return this.deviceConnection
                .send('setAdminPassword', {
                    password: password
                });
        }

        return true;
    },

    /**
     * Update User password.
     */
    updateUserPassword: function() {
        var passwordRequired = this.form.get('guest-password-require').getValue();
        var pin = this.form.get('guest-pin').getValue();
        var password = this.form.get('guest-password').getValue();

        if (passwordRequired && !pin && password.length > 0) {
            return this.deviceConnection
                .send('setUserPassword', {
                    password: password
                });
        }

        return true;
    },

    /**
     * Update user password type (password/pin/on/off)
     */
    updateUserPasswordType: function() {
        var passwordRequired = this.form.get('guest-password-require').getValue();
        var pin = this.form.get('guest-pin').getValue();
        var type = 'password';

        if (!!passwordRequired && !!pin) {
            type = 'pin';
        }

        return this.deviceConnection
            .send([
                {
                    command: 'setRemotePasswordRequired',
                    data: {
                        pwRequired: passwordRequired
                    }
                },
                {
                    command: 'setPasswordType',
                    data: {
                        pwType: type
                    }
                }
            ]);
    },

    /**
     * Update Web Browser Viewer settings.
     */
    updateRmsPassword: function() {
        var password = this.form.get('rms-password').getValue();
        var passwordRequired = this.form.get('rmsPassword').getValue();

        if (passwordRequired && password.length > 0) {
            return this.deviceConnection
                .send('setRmsPassword', {
                    password: password
                });
        } else {
            return true;
        }
    },

    /**
     * Update Web Browser Viewer settings.
     */
    updateWebbrowserViewer: function() {
        if (this.deviceService.isCboxPureMini() || this.deviceService.isCboxPureReceiver()) {
            return;
        }

        var supportEnabled = this.form.get('webbrowserViewerEnabled').getValue();
        var viewerPinEnabled = this.form.get('webbrowserViewerPinEnabled').getValue();

        var cmds = [
            {
                command: 'setWebbrowserViewerSupport',
                data: {
                    enabled: supportEnabled
                }
            },
            {
                command: 'setWebbrowserViewerPinEnabled',
                data: {
                    enabled: viewerPinEnabled
                }
            }
        ];

        return this.deviceConnection
            .send(cmds);
    },
    /**
     * Update RMS password mode.
     */
    updateRmsPasswordMode: function() {
        var passwordRequired = this.form.get('rmsPassword').getValue();

        return this.deviceConnection
            .send([
                {
                    command: 'setRmsPasswordMode',
                    data: {
                        pwRequired: passwordRequired
                    }
                }
            ]);
    },

    updateViewerResolution: function() {
        if (!this.deviceService.isCboxPureMini() && !this.deviceService.isCboxPureReceiver()) {
            this.frontendSettings.updateSetting({
                tag: 'viewerResolution',
                value: this.form.get('viewerResolution').getValue()
            });
            this.frontendSettings.saveSettings();
        }
    },

    updateLdap: function() {
        if (this.deviceService.isCboxPure() || this.deviceService.isCboxPureMini()) {
            return;
        }

        var tlsMode = this.form.get('tls-mode').getValue();
        var serverUrl = this.form.get('server-url').getValue();
        var serverDomain = this.form.get('server-domain').getValue();
        var ldapRequired = this.form.get('ldap-require').getValue();

        return this.deviceConnection
            .send([
                {
                    command: 'setLdapAuthMode',
                    data: {
                        enabled: ldapRequired
                    }
                },
                {
                    command: 'setLdapTlsMode',
                    data: {
                        enabled: tlsMode
                    }
                },
                {
                    command: 'setLdapServerUrl',
                    data: {
                        url: serverUrl
                    }
                },
                {
                    command: 'setLdapServerDomain',
                    data: {
                        domain: serverDomain
                    }
                }
            ]);
    },

    /**
     * Update Connection Id settings.
     */
    updateConnectionIdSettings: function() {
        var connectionIdEnable = this.form.get('connection-id-enable').getValue();
        var connectionIdInterface = this.form.get('connectionIdInterface').getValue();

        return this.deviceConnection
            .send([
                {
                    command: 'setConnectionIdEnable',
                    data: {
                        enabled: connectionIdEnable
                    }
                },
                {
                    command: 'setConnectionIdInterface',
                    data: {
                        interface: connectionIdInterface
                    }
                }
            ]);
    },

    /**
     * Calls on clicked the cancel button.
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
        this.changes = false;
    },

    /**
     * Check active pin mode.
     */
    checkPinMode: function() {
        var adminPin = this.form.get('admin-pin').getValue();

        if (adminPin) {
            this.$pinDestinations.hide();
            this.$adminPinDestination.show();
            this.$adminPinOnlyWhilePresenting.show();
        } else {
            this.$pinDestinations.show();
            this.$adminPinDestination.hide();
            this.$adminPinOnlyWhilePresenting.hide();
        }

        // Show pin destination if guest-pin mode is on and guest-password-required is on or admin-pin mode is on.
        if (this.checkGuestPinMode() || adminPin) {
            this.showPinDestination();
        } else {
            this.hidePinDestination();
        }
    },

    checkGuestPinMode: function() {
        var guestPin = this.form.get('guest-pin').getValue();
        var guestPasswordRequired = this.form.get('guest-password-require').getValue();

        // Show pin container if password required is on
        if (guestPasswordRequired) {
            this.showPinContainer();
        } else {
            this.hidePinContainer();
            this.form.get('guest-pin').setValue(false);
        }

        return guestPin && guestPasswordRequired;
    },

    checkPasswordMode: function() {
        this.updatePasswordContainer();
        this.checkPinMode();
    },

    updateRmsPasswordContainer: function() {
        var rmsPwd = this.form.get('rmsPassword').getValue();

        // Hide password if password-required is off
        if (rmsPwd) {
            this.showRmsPasswordRequired();
        // Show password if password-required is on
        } else {
            this.hideRmsPasswordRequired();
        }

        this.checkActionForm();
    },

    updatePasswordContainer: function() {
        var passwordRequired = this.form.get('guest-password-require').getValue();
        var pin = this.form.get('guest-pin').getValue();

        // Hide password if password-required is off or pin mode is off
        if (!!pin || !passwordRequired) {
            this.hidePasswordContainer();
        // Show password if password-required is on and pin mode is off
        } else if (passwordRequired) {
            this.showPasswordContainer();
        }
    },

    showRmsPasswordRequired: function() {
        if (this.init) {
            this.$pwdRmsContainer
                .stop()
                .slideDown(ANIMATION_DURATION);
        } else {
            this.$pwdRmsContainer.show();
        }
    },

    hideRmsPasswordRequired: function() {
        if (this.init) {
            this.$pwdRmsContainer
                .stop()
                .slideUp(ANIMATION_DURATION);
        } else {
            this.$pwdRmsContainer.hide();
        }
    },

    showPasswordRequired: function() {
        if (this.init) {
            this.$pwdRequireContainer
                .stop()
                .slideDown(ANIMATION_DURATION);
        } else {
            this.$pwdRequireContainer.show();
        }
    },

    hidePasswordRequired: function() {
        if (this.init) {
            this.$pwdRequireContainer
                .stop()
                .slideUp(ANIMATION_DURATION);
        } else {
            this.$pwdRequireContainer.hide();
        }
    },

    showPinContainer: function() {
        if (this.pinState.getState() !== states.visible) {
            if (this.init) {
                this.$pinContainer
                    .stop()
                    .slideDown(ANIMATION_DURATION);
            } else {
                this.$pinContainer.show();
            }
            this.pinState.changeState(states.visible);
        }

        this.checkActionForm();
    },

    hidePinContainer: function() {
        if (this.pinState.getState() !== states.hidden) {
            if (this.init) {
                this.$pinContainer
                    .stop()
                    .slideUp(ANIMATION_DURATION);
            } else {
                this.$pinContainer.hide();
            }

            this.pinState.changeState(states.hidden);
        }

        this.checkActionForm();
    },

    showPasswordContainer: function() {
        if (this.pwdState.getState() !== states.visible) {
            if (this.init) {
                this.$pwdContainer
                    .stop()
                    .slideDown(ANIMATION_DURATION);
            } else {
                this.$pwdContainer.show();
            }
            this.pwdState.changeState(states.visible);
        }

        this.checkActionForm();
    },

    hidePasswordContainer: function() {
        if (this.pwdState.getState() !== states.hidden) {
            if (this.init) {
                this.$pwdContainer
                    .stop()
                    .slideUp(ANIMATION_DURATION);
            } else {
                this.$pwdContainer.hide();
            }

            this.pwdState.changeState(states.hidden);
        }

        this.checkActionForm();
    },

    /**
     * Show/hide viewer container.
     * @param show
     */
    updateViewerContainer: function() {
        if (this.deviceService.isCboxPureMini() || this.deviceService.isCboxPureReceiver()) {
            return;
        }

        if (this.form.get('webbrowserViewerEnabled').getValue()) {
            this.$viewerContainer
                .stop()
                .slideDown(ANIMATION_DURATION);
        } else {
            this.$viewerContainer
                .stop()
                .slideUp(ANIMATION_DURATION);
        }
        this.checkActionForm();
    },

    updateViewerResolutionMessage: function() {
        if (this.form.get('viewerResolution').getValue() === '1080p') {
            this.$warningMessageContainer
                .stop()
                .slideDown(ANIMATION_DURATION);
        } else {
            this.$warningMessageContainer
                .stop()
                .slideUp(ANIMATION_DURATION);
        }
        this.checkActionForm();
    },

    /**
     * Show LDAP container.
     */
    showLdapContainer: function() {
        if (this.ldapState.getState() !== states.visible) {
            if (this.init) {
                this.$ldapContainer
                    .stop()
                    .slideDown(ANIMATION_DURATION);
            } else {
                this.$ldapContainer.show();
            }
            this.ldapState.changeState(states.visible);
        }

        this.checkActionForm();
    },

    /**
     * Hide LDAP container.
     */
    hideLdapContainer: function() {
        if (this.ldapState.getState() !== states.hidden) {
            if (this.init) {
                this.$ldapContainer
                    .stop()
                    .slideUp(ANIMATION_DURATION);
            } else {
                this.$ldapContainer.hide();
            }

            this.ldapState.changeState(states.hidden);
        }

        this.checkActionForm();
    },

    updateLdapContainer: function() {
        if (this.deviceService.isCboxPure() || this.deviceService.isCboxPureMini()) {
            return;
        }

        var ldap = this.form.get('ldap-require').getValue();

        // Hide password if password-required is off
        if (ldap) {
            this.showLdapContainer();
            // Show password if password-required is on
        } else {
            this.hideLdapContainer();
        }

        this.checkActionForm();
    },

    /**
     * Show destination container with all togglers.
     */
    showPinDestination: function() {
        if (this.init) {
            this.$destinationsContainer
                .stop()
                .slideDown(ANIMATION_DURATION);
        } else {
            this.$destinationsContainer.show();
        }
    },

    /**
     * Hide destination container with all togglers.
     */
    hidePinDestination: function() {
        if (this.init) {
            this.$destinationsContainer
                .stop()
                .slideUp(ANIMATION_DURATION);
        } else {
            this.$destinationsContainer.hide();
        }
    },

    hasChanges: function() {
        if (!this.actionView) {
            return {
                hasChanges: false,
                invalid: false
            };
        }

        return {
            hasChanges: this.changes,
            invalid: $(this.actionView.$submitBtn).prop('disabled')
        };
    },

    /**
     * Verify LDAP Certificate.
     */
    verifyCertificate: function() {
        var file = this.form.get('certificate-file').getValue();
        var reader = new FileReader();

        if (file) {
            reader.onload = function(event) {
                this.deviceConnection
                    .send([
                        {
                            command: 'setLdapCertificateFile',
                            data: {
                                name: file.name,
                                file: event.target.result,
                                action: 'verify'
                            }
                        }
                    ]).then(function() {
                        this.$certEl.removeClass('is-invalid');
                        this.$certEl.addClass('is-valid');
                        this.certificateError = false;
                        this.checkActionForm();
                    }.bind(this)).fail(function() {
                        this.$certEl.removeClass('is-valid');
                        this.$certEl.addClass('is-invalid');
                        this.actionView.disableSubmitButton();
                        this.certificateError = true;
                    }.bind(this)) ;
            }.bind(this);

            reader.readAsArrayBuffer(file);
        }
    },

    /**
     * Save LDAP Certificate.
     */
    saveCertificate: function() {
        var file = this.form.get('certificate-file').getValue();
        var reader = new FileReader();

        if (file) {
            reader.onload = function(event) {
                this.deviceConnection
                    .send([
                        {
                            command: 'setLdapCertificateFile',
                            data: {
                                name: file.name,
                                file: event.target.result,
                                action: 'save'
                            }
                        }
                    ]);
            }.bind(this);

            reader.readAsArrayBuffer(file);

            this.form.get('certificate-file')
                .setValue(null);
        }
    },

    createSelects: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#connection-id-interface-select'),
            label: 'settings.connection_id_interface',
            native: true,
            name: 'connectionIdInterface',
            items: app.getService('Model-View').getGeneralLanSelects()
        });

        if (!this.deviceService.isCboxPureMini() && !this.deviceService.isCboxPureReceiver()) {
            this.createComponent({
                type: 'CustomSelect',
                container: this.$el.find('#viewer-resolution-select'),
                label: 'settings.resolution',
                native: true,
                name: 'viewerResolution',
                items: [
                    {
                        text: 'settings.resolution_720p',
                        value: '720p'
                    },
                    {
                        text: 'settings.resolution_1080p',
                        value: '1080p'
                    }
                ]
            });
        }
    },

    /**
     * Update LDAP Certificate name.
     */
    updateLdapFileName: function() {
        this.deviceConnection
            .send('getLdapCertificateFileName').then(function(filename) {
                this.$el.find('.ldap-filename').text(': ' + filename.fileName);
            }.bind(this));
    }
});
