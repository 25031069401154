// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"osd-btn-container\" data-nav-area=\".is-focusable:not(.is-disabled)\">\n    <div id=\"osd-btn-allow\"\n         class=\"osd-combined-action-btn is-focusable clickable\"\n         data-action=\"allow\">\n        <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"allowText") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":14},"end":{"line":5,"column":34}}}))
    + "</span>\n    </div>\n    <div class=\"separator-mini\"></div>\n    <div id=\"osd-btn-decline\"\n         class=\"osd-combined-action-btn is-focusable clickable\"\n         data-action=\"decline\">\n        <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"declineText") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":14},"end":{"line":11,"column":36}}}))
    + "</span>\n    </div>\n</div>\n";
},"useData":true});
