'use strict';

const app = require('../../../../app');
const $ = require('jquery');
const platform = require('../../../../../platform/platform');

const controlItems = [
    {
        id: 'mute',
        type: 'mute',
        state: 'mute'
    },
    {
        id: 'volume',
        type: 'volume'
    }
];

/**
 * Zoom (Webconference/WebRTC)
 */
app.component('FrameBoxZoomViewer', {
    extend: 'FrameBoxBaseType',
    className: 'framebox-view framebox-zoom-view',
    currentInputType: 'notext',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.hasClicked = true;
        this.openTimeout = null;
        this.hasManualClick = false;
        this.clickTimeout = null;
        this.frameBoxService = this.getService('FrameBoxService');
        this.zoomService = this.getService('ZoomService');
        this.webconferenceService = this.getService('WebconferenceService');
        this.componentId = null;
        this.prevInputValue = null;
        this.options.allowedEvents = ['touchstart', 'touchend', 'mousedown', 'mouseup', 'touchmove', 'mousemove'];
    },

    serialize: function() {
        return {
            isCbox: platform.checks.isCbox
        };
    },

    postPlaceAt: function() {
        this.startControls();
        this.bindEvents();
        this.startTouchEventsEntity({
            $actionEl: this.options.$actionEl,
            allowedEvents: this.options.allowedEvents,
            disableSelection: false,
            debugging: false
        });

        this.onFrameboxChanged({
            contentType: this.frameBoxService.contentTypeMapping(this.options.type),
            options: this.options.frameBoxOptions
        });

        this.updateTransparentState({
            index: this.options.index,
            transparent: this.frameBoxService.isTransparent(this.options.index)
        });
    },

    bindEvents: function() {
        this.on('framebox.close.zoom', this.onClose.bind(this));
        this.on('framebox.change.' + this.index, this.onFrameboxChanged.bind(this));
        this.on('framebox.standard.mouseevent', this.onMouseevent.bind(this));
        this.on('framebox.standard.keydown', this.keyDownHandler.bind(this));
        this.on('framebox.standard.keyup', this.keyUpHandler.bind(this));
        this.on('active-framebox.changed', this.activeFrameboxChanged.bind(this));
        this.on('framebox-icon-button.position.update', this.positionControlBar.bind(this));
        this.on('framebox.mode.pinky', this.positionControlBar.bind(this));
        this.on('framebox.mode.brain', this.positionControlBar.bind(this));
    },

    updateTransparentState: function(options) {
        if (options.index !== this.options.index || platform.checks.isCbox) {
            return;
        }

        this.transparent = options.transparent;
    },

    /**
     * Initialize Keyboard after active framebox changed
     *
     * @param index framebox index
     * @param keyboardAction focus is on an input field --> update keyboard
     */
    activeFrameboxChanged: function(index, component, keyboardAction) {
        if (index === this.options.index) {
            if (!this.keyboard) {
                this.startKeyboard();

                this.onFrameboxChanged({
                    contentType: this.frameBoxService.contentTypeMapping(this.options.type),
                    options: this.options.frameBoxOptions
                });

                return;
            }

            if (keyboardAction) {
                this.keyboardAction('update', this.box);
            }
        }
    },

    /**
     * Zoom bar height has ~ 5% of the total height (zoom FHD framebox)
     */
    positionControlBar: function() {
        let pos = this.zoomService.getControlBarPosition();

        // Reset control bar for other contents
        $(document).find('.framebox-standard-header').css('bottom', '');
        $(document).find('.framebox-fullscreen-header').css('bottom', '');
        $(document).find('.framebox-icon-button').css('bottom', '');

        // Set position for zoom control bar
        $(document).find('[data-content-type="zoom"] .framebox-standard-header').css('bottom', pos);
        $(document).find('[data-content-type="zoom"] .framebox-fullscreen-header').css('bottom', pos);
        $(document).find('[data-content-type="zoom"] .framebox-icon-button').css('bottom', pos);

        $(document).find('[data-content-type="zoom"] .framebox-show-hdmi-out-2').css('bottom', pos);
        $(document).find('[data-content-type="zoom"] .framebox-show-keyboard').css('bottom', pos);
        $(document).find('[data-content-type="zoom"] .shift-up').css('bottom', pos + 48);
        $(document).find('[data-content-type="zoom"].is-brain-mode .framebox-show-keyboard').css('bottom', pos + 48);
    },

    /**
     * Handle framebox changed.
     * @param box framebox data
     */
    onFrameboxChanged: function(data) {
        this.box = data;

        var hasChangedKeyboard = data.options.inputType !== this.currentInputType || this.hasManualClick;

        if (this.keyboardAction && hasChangedKeyboard) {
            this.currentInputType = data.options.inputType;
            this.keyboardAction('update', data);
        }

        this.positionControlBar();

        clearTimeout(this.clickTimeout);
    },

    /**
     * Start Keyboard Component.
     */
    startKeyboard: function() {
        this.keyboard = this.createComponent({
            type: 'FrameboxKeyboard',
            container: this.$el,
            index: this.options.index,
            action: function(fn) {
                this.keyboardAction = fn;
            }.bind(this)
        });
    },

    /**
     * Start controls.
     */
    startControls: function() {
        this.componentId = this.createComponent({
            type: 'FrameBoxControls',
            container: this.$el,
            index: this.options.index,
            appId: this.options.appId,
            items: controlItems,
            onClick: this.onControlClickHandler.bind(this),
            isFullscreen: this.options.isFullscreen
        });
    },

    onControlClickHandler: function(id) {
        var handler = this.controlHandlers[id];

        if (handler) {
            handler.call(this, {
                index: this.index
            });
        }
    },

    /**
     * Handle custom close of Zoom window.
     * Framebox close is sent after the user confirms.
     */
    onClose: function(data) {
        if (this.index !== data.index) {
            return;
        }

        if (this.zoomService.getZoomLocalAccountLogin()) {
            this.frameBoxService.closeFrameBox(this.index);

            return;
        }

        this.emit('modal.open', {
            id: 'confirm-v3',
            messageKey: 'zoom.confirm_end_meeting',
            successTextKey: 'zoom.end_meeting',
            discareTextKey: 'modal.cancel',
            backdropClick: true,
            onConfirm: function() {
                this.frameBoxService.closeFrameBox(this.index);
            }.bind(this),
            autoClose: true
        });
    },

    /**
     * Called when a mouse or touch event has been triggered on active framebox.
     *
     * @param {jQuery.MouseEvent} event
     * @param {Number} index
     */
    onMouseevent: function(event, index) {
        if (index !== this.index) {
            return;
        }

        this.hasManualClick = true;
        clearTimeout(this.clickTimeout);

        this.clickTimeout = setTimeout(function() {
            this.hasManualClick = false;
        }.bind(this), 300);
    },

    keyDownHandler: function(event, index) {
        this.keyEventHandler(index, 'keydown', event);
    },

    keyUpHandler: function(event, index) {
        this.keyEventHandler(index, 'keyup', event);
    },

    keyEventHandler: function(index, keyEvent, event) {
        if (index === this.index && !this.transparent) {
            this.frameBoxService.sendKeyEvent(this.index, keyEvent, event);
        }
    },

    destroy: function() {
        this.positionControlBar();

        if (this.keyboardAction) {
            this.keyboardAction('close');
        }
    }
});
