// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal.usb_load_user_settings_message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":37},"end":{"line":5,"column":81}}}));
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal.user_settings_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":89},"end":{"line":6,"column":48}}}));
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row service-container\">\n                <div class=\"row user-settings-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasStatus") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":27,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"services") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":43,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-xs-6\">\n                            <span class=\"group-title\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n                        </div>\n                        <div class=\"col-xs-6\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"status") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":17,"column":28},"end":{"line":21,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                                <span class=\"icon-check-mark\"></span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                                <span class=\"icon-close\"></span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-xs-12\">\n                            <div class=\"group-title\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</div>\n                        </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"row user-settings-item\" id=\"user-settings-item\" data-action=\"load-user-settings\"\n                         tabIndex=\"-1\">\n                        <div class=\"col-xs-6\">\n                            <span class=\"service-title\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n                        </div>\n                        <div class=\"col-xs-6\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"status") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":36,"column":28},"end":{"line":40,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal.user_settings_warning",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":64},"end":{"line":67,"column":100}}}));
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal.user_settings_save_warning",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":108},"end":{"line":68,"column":48}}}));
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"user-settings-action\">\n                <input id=\"user-settings-load\" name=\"load-settings\" type=\"button\" class=\"btn-form form-action-submit load-settings focusable\"\n                       value=\""
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal.usb_load_user_settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":30},"end":{"line":73,"column":67}}}))
    + "\"/>\n            </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"user-settings-action\">\n                <input id=\"user-settings-save\" name=\"save-settings\" type=\"button\" class=\"btn-form form-action-submit save-settings focusable\"\n                       value=\""
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal.save_user_settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":30},"end":{"line":78,"column":63}}}))
    + "\"/>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"user-settings-container\">\n    <form id=\"user-settings-form\" name=\"user-settings-form\">\n        <div class=\"row\">\n            <div class=\"col-xs-12\">\n                <h2>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"load") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":6,"column":55}}})) != null ? stack1 : "")
    + "</h2>\n            </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"serviceList") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":45,"column":17}}})) != null ? stack1 : "")
    + "\n        <div class=\"row local-office-account-container hidden\">\n            <div class=\"col-xs-3\">\n                <div class=\"btn-switch-container office-account-switch\">\n                    <input name=\"office-account\"\n                           id=\"office-account\"\n                           type=\"checkbox\"\n                           class=\"btn-switch focusable\"\n                           tabIndex=\"1\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"col-xs-9\">\n                <div class=\"office-account-label\">\n                    "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(alias1,"modal.choose_office_account",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":20},"end":{"line":62,"column":55}}}))
    + "\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row user-settings-warning\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"load") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":67,"column":47},"end":{"line":68,"column":55}}})) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"load") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":70,"column":8},"end":{"line":80,"column":15}}})) != null ? stack1 : "")
    + "    </form>\n</div>\n";
},"useData":true});
