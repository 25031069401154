// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"input-group certificate-file-container\">\n                                <label class=\"input-label\" for=\"certificate-file\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.certificate_file",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":213,"column":82},"end":{"line":214,"column":65}}}))
    + "</label>\n                                <input type=\"file\" class=\"input focusable\" name=\"certificate-file\" id=\"certificate-file\"\n                                       tabIndex=\"-1\"/>\n                                <div class=\"input-info\">\n                                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_certificate",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":218,"column":83},"end":{"line":219,"column":72}}}))
    + "</span>\n                                </div>\n                            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "col-xs-6";
},"5":function(container,depth0,helpers,partials,data) {
    return "col-xs-12";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"ethernet-settings-form-v4\" name=\"ethernet-settings\">\n        <!-- IPv4 -->\n        <div class=\"row settings-list-row status-info\">\n            <div class=\"col-xs-8\">\n                <h2>\n                    <span id=\"lan-connection-state\" class=\"lan-connection-state\">\n                        <span class=\"lan-connection-state-disconnected\">\n                            <div class=\"lan-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.disconnected",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":28},"end":{"line":10,"column":57}}}))
    + "\n                        </span>\n                        <span class=\"lan-connection-state-connected\">\n                            <div class=\"lan-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.connected",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":28},"end":{"line":14,"column":54}}}))
    + "\n                        </span>\n                        <span class=\"lan-connection-state-failed\">\n                            <div class=\"lan-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.failed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":28},"end":{"line":18,"column":51}}}))
    + "\n                        </span>\n                        <span class=\"lan-connection-state-scanning\">\n                            <div class=\"lan-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.running",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":28},"end":{"line":22,"column":52}}}))
    + "\n                        </span>\n                        <span class=\"lan-connection-state-authenticating\">\n                            <div class=\"lan-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.authenticating",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":28},"end":{"line":26,"column":59}}}))
    + "\n                        </span>\n                    </span>\n                </h2>\n            </div>\n        </div>\n        <div class=\"row settings-list-secundary\"\n             data-nav-area=\".btn-switch, .input:not([disabled]), .select, .is-focusable, .focusable:not(:disabled)\"\n             data-nav-trapped\n             data-nav-default-focus\n             data-nav-area-default>\n            <div class=\"col-xs-6\">\n                <div id=\"mode-select\"></div>\n            </div>\n            <div class=\"col-xs-6\"/>\n        </div>\n        <div class=\"lan-mode-container\">\n            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".btn-switch, .input:not([disabled]), .select, .is-focusable, .focusable:not(:disabled)\"\n                 data-nav-trapped\n                 data-nav-default-focus>\n                <div class=\"col-xs-6\">\n                    <div id=\"priority-select\"></div>\n                </div>\n                <div class=\"col-xs-6\"/>\n            </div>\n            <div class=\"row settings-list-row\"\n                 data-nav-area=\".btn-switch, .input:not([disabled]), .select, .is-focusable, .focusable:not(:disabled)\"\n                 data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ipv4_autoconfig",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":24},"end":{"line":56,"column":57}}}))
    + "</h2>\n                    <span id=\"lan-connection-state\" class=\"lan-connection-state\">\n                        <span class=\"lan-connection-state-disconnected\">\n                            <div class=\"lan-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.disconnected",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":28},"end":{"line":60,"column":57}}}))
    + "\n                        </span>\n                        <span class=\"lan-connection-state-connected\">\n                            <div class=\"lan-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.connected",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":28},"end":{"line":64,"column":54}}}))
    + "\n                        </span>\n                    </span>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"dhcp\" id=\"dhcp\" type=\"checkbox\" class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"dhcp-container\">\n                <div class=\"row settings-list-secundary\" data-nav-area=\".input:not([disabled]), .btn-switch\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"ipv4\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ip_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":82,"column":66},"end":{"line":82,"column":94}}}))
    + "</label>\n                            <input type=\"text\" class=\"input\" name=\"ip\" id=\"ipv4\" placeholder=\"0.0.0.0\"\n                                   data-validation=\"checkIpNonVz\" data-main-input-selector=\"#dhcp\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_ip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":86,"column":79},"end":{"line":86,"column":105}}}))
    + "</span>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"subnet\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.subnet",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":92,"column":68},"end":{"line":92,"column":92}}}))
    + "</label>\n                            <input type=\"text\" class=\"input\" name=\"subnet\" id=\"subnet\" placeholder=\"0.0.0.0\"\n                                   data-validation=\"checkSubnet\" data-main-input-selector=\"#dhcp\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.subnet_is_wrong",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":96,"column":79},"end":{"line":96,"column":112}}}))
    + "</span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\".input:not([disabled]), .btn-switch, .focusable:not(:disabled)\" data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"gateway\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.gateway",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":106,"column":69},"end":{"line":106,"column":94}}}))
    + "</label>\n                            <input type=\"text\" class=\"input\" name=\"gateway\" id=\"gateway\" placeholder=\"0.0.0.0\"\n                                   data-validation=\"checkWlanLanGateway\" data-main-input-selector=\"#dhcp\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_ip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":110,"column":79},"end":{"line":110,"column":105}}}))
    + "</span>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\" id=\"dns-input-container\">\n                            <label class=\"input-label\" for=\"dns\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.dns",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":116,"column":65},"end":{"line":116,"column":86}}}))
    + "</label>\n                            <input type=\"text\" class=\"input\" name=\"dns\" id=\"dns\" placeholder=\"0.0.0.0\"\n                                   data-validation=\"checkDNS\" data-main-input-selector=\"#dhcp\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_ip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":120,"column":79},"end":{"line":120,"column":105}}}))
    + "</span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\".input:not([disabled]), .btn-switch, .focusable:not(:disabled)\" data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\" id=\"mac-container\">\n                            <label class=\"input-label\" for=\"mac\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.mac",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":129,"column":65},"end":{"line":129,"column":86}}}))
    + "</label>\n                            <input type=\"text\" class=\"input\" name=\"mac\" id=\"mac\"/>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\" id=\"dns2-input-container\">\n                            <label class=\"input-label\" for=\"dns2\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.dns2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":135,"column":66},"end":{"line":135,"column":88}}}))
    + "</label>\n                            <input type=\"text\" class=\"input\" name=\"dns2\" id=\"dns2\" placeholder=\"0.0.0.0\"\n                                   data-validation=\"checkDNS\" data-main-input-selector=\"#dhcp\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_ip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":139,"column":79},"end":{"line":139,"column":105}}}))
    + "</span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row settings-list-row\"\n                 data-nav-area=\".input:not([disabled]), .btn-switch, .focusable:not(:disabled)\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.auth_mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":148,"column":24},"end":{"line":148,"column":51}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"authMode\" type=\"checkbox\" class=\"btn-switch\" id=\"authMode\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"hidden\" id=\"auth-container\">\n                <div class=\"row settings-list-secundary\" id=\"security\"\n                     data-nav-area=\".input:not([disabled]), .select, .is-focusable, .focusable:not(:disabled)\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\" id=\"identity-container\">\n                            <label class=\"input-label\" for=\"identity\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.identity",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":165,"column":70},"end":{"line":165,"column":95}}}))
    + "</label>\n                            <input type=\"text\" class=\"input\" name=\"identity\" id=\"identity\"/>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\" id=\"other-password-container\">\n                            <label class=\"input-label\" for=\"password\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":171,"column":70},"end":{"line":171,"column":95}}}))
    + "</label>\n                            <input type=\"password\"\n                                   id=\"password\"\n                                   autocomplete=\"current-password\"\n                                   class=\"input\"\n                                   name=\"password\"\n                                   data-validation=\"checkWLANPassword\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":179,"column":79},"end":{"line":179,"column":110}}}))
    + "</span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\".input:not([disabled]), .select, .is-focusable, .focusable:not(:disabled)\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"anonymous\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.anonymous_identity",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":189,"column":71},"end":{"line":189,"column":106}}}))
    + "</label>\n                            <input type=\"text\" class=\"input\" name=\"anonymous\" id=\"anonymous\"/>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div id=\"auth-method-select-container\"></div>\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\".input:not([disabled]), .btn-switch, .select, .focusable:not(:disabled)\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <label class=\"input-label\" for=\"certMode\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.certificate_mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":202,"column":66},"end":{"line":202,"column":99}}}))
    + "</label>\n                        <div class=\"btn-switch-container\">\n                            <input name=\"certMode\" type=\"checkbox\" class=\"btn-switch\" id=\"certMode\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-6\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":211,"column":24},"end":{"line":222,"column":35}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\".input:not([disabled]), .btn-switch, .select, .focusable:not(:disabled)\"\n                     data-nav-trapped>\n                    <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":228,"column":32},"end":{"line":228,"column":78}}})) != null ? stack1 : "")
    + "\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"certificates\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.certificates",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":230,"column":74},"end":{"line":230,"column":103}}}))
    + "</label>\n                            <div id=\"certificate-list-items\" class=\"hidden\"></div>\n                            <div id=\"certificate-list-no-entry\" class=\"certificate-list-no-entry hidden\">\n                                <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.no_certificates",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":233,"column":35},"end":{"line":233,"column":67}}}))
    + "</p>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div id=\"form-action-container-v4\" class=\"form-primary-background\" data-nav-area=\"input\" data-nav-trapped></div>\n    </form>\n\n    <div class=\"row settings-list-row\">\n        <div class=\"col-xs-12\">\n            <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ping",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":245,"column":16},"end":{"line":245,"column":38}}}))
    + "</h2>\n        </div>\n    </div>\n    <div class=\"row settings-list-secundary\">\n        <div id=\"lan2-ping-container\" class=\"ping-container-outer\"></div>\n    </div>\n</div>\n";
},"useData":true});
