module.exports={

    // NOTE:
    // 100 = AltGr
    "modifier":
    {
        "&#8364;": 100, //€
        "&#91;": 100, //[
        "&#93;": 100, //]

        "&#35;": 100, //#
        "&#64;": 100, //@
        "atmark": 100, //@
    },

    "modifier_mobile":
    {
        "&#124;": 42, //|
        "&#33;": 42, //!
        "&#34;": 42, //"
        "&#163;": 42, //£
        "&#36;": 42, //$
        "&#37;": 42, //%
        "&#38;": 42, //&
        "&#47;": 42, ///
        "&#40;": 42, //(
        "&#41;": 42, //)
        "&#61;": 42, //=
        "&#63;": 42, //?
        "&#94;": 42, //^

        "&#233;": 26, //é
        "&#42;": 42, //*

        "&#231;": 42, //ç
        "&#176;": 42, //°
        "&#167;": 42, //§

        "&#62;": 42, //>
        "&#59;": 42, //;
        "&#58;": 42, //:
        "&#95;": 42, //_
    },


    "ie_specialkeys_mobile": {},

    "ie_specialkeys": {},

    "&#92;": 41, //\
    "&#124;": 41, //|
    "&#33;": 2, //!
    "&#34;": 3, //"
    "&#163;": 4, //£
    "&#36;": 5, //$
    "&#37;": 6, //%
    "&#38;": 7, //&
    "&#47;": 8, ///
    "&#40;": 9, //(
    "&#41;": 10, //)
    "&#61;": 11, //=
    "&#39;": 12, //'
    "&#63;": 12, //?
    "&#204;": 13, //ì
    "&#94;": 13, //^

    "&#8364;": 18, //€
    "&#232;": 26, //è
    "&#233;": 26, //é
    "&#91;": 26, //[
    "&#43;": 27, //+
    "&#42;": 27, //*
    "&#93;": 27, //]

    "&#242;": 39, //ò
    "&#231;": 39, //ç
    "&#64;": 39, //@
    "atmark": 39, //@
    "&#224;": 40, //à
    "&#176;": 40, //°
    "&#35;": 40, //#
    "&#249;": 43, //ù
    "&#167;": 43, //§

    "&#60;": 86, //<
    "&#62;": 86, //>
    "&#44;": 51, //,
    "&#59;": 51, //;
    "&#46;": 52, //.
    "&#58;": 52, //:
    "&#45;": 53, //-
    "&#95;": 53 //_
}
