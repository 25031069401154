'use strict';

var app = require('../../../../app');
var _ = require('lodash');

var controlItems = [
    {
        id: 'prev-page',
        state: 'prev-page',
        states: {
            'prev-page': {
                icon: 'icon-v2-arrow-left',
                titleKey: ''
            }
        }
    },
    {
        id: 'next-page',
        state: 'next-page',
        states: {
            'next-page': {
                icon: 'icon-v2-arrow-right',
                titleKey: ''
            }
        },
        groupEnd: true
    },
    {
        id: 'mute',
        type: 'mute',
        state: 'mute'
    },
    {
        id: 'volume',
        type: 'volume'
    },
    {
        id: 'title-right',
        type: 'title-right',
        state: 'title-right'
    }
];

/**
 * Presentation frame
 */
app.component('FrameBoxPresentationViewer', {
    extend: 'FrameBoxBaseType',
    className: 'framebox-view framebox-vsolution-view',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.frameBoxService = this.getService('FrameBoxService');
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.startControls();
        this.bindEvents();
        this.setCount(this.options.frameBoxOptions);
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        this.on('framebox.change.' + this.options.index, this.onFrameBoxChangeHandler.bind(this));

        // Remote-control
        this.on('framebox.standard.left.keydown', _.throttle(this.controlHandlers['prev-page'].bind(this), 500));
        this.on('framebox.standard.right.keydown', _.throttle(this.controlHandlers['next-page'].bind(this), 500));
        this.on('framebox.standard.up.keydown', _.throttle(this.controlHandlers['prev-page'].bind(this), 500));
        this.on('framebox.standard.down.keydown', _.throttle(this.controlHandlers['next-page'].bind(this), 500));
        this.on('framebox.standard.panleft-single', this.controlHandlers['next-page'].bind(this));
        this.on('framebox.standard.panright-single', this.controlHandlers['prev-page'].bind(this));
        this.on('framebox.standard.pageDown.keydown', _.throttle(this.controlHandlers['next-page'].bind(this), 500));
        this.on('framebox.standard.pageUp.keydown', _.throttle(this.controlHandlers['prev-page'].bind(this), 500));
    },

    /**
     * @method startControls
     */
    startControls: function() {
        this.createComponent({
            type: 'FrameBoxControls',
            container: this.$el,
            index: this.options.index,
            appId: this.options.appId,
            items: controlItems,
            onClick: this.onControlClickHandler.bind(this),
            isFullscreen: this.options.isFullscreen
        });
    },

    /**
     * @method onClickHandler
     * @param {string} id
     */
    onControlClickHandler: function(id) {
        var handler = this.controlHandlers[id];

        if (handler) {
            handler.call(this, {
                index: this.index
            });
        }
    },

    controlHandlers: {
        'prev-page': function(options) {
            this.sendCmd('prevPage', options.index);
        },
        'next-page': function(options) {
            this.sendCmd('nextPage', options.index);
        }
    },

    /**
     * @method cmd
     * @param {string} cmd
     * @param {int} index
     */
    sendCmd: function(cmd, index) {
        if (this.index === index) {
            this.send('setOfficeControl', {
                appId: this.options.appId,
                officeControl: cmd,
                type: 'presentation'
            });
        }
    },

    /**
     * @method onFrameBoxChangeHandler
     * @param {Object} frameBox
     */
    onFrameBoxChangeHandler: function(frameBox) {
        this.setCount(frameBox.options);
    },

    /**
     * @method setCount
     */
    setCount: function(metaData) {
        var text = metaData.currentPage + ' / ' + metaData.totalPages;

        this.emit('framebox.controls.title-right', {
            index: this.options.index,
            text: '<div class="framebox-viewer-header-count">' + text + '</div>'
        });
    }
});
