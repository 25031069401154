'use strict';

// Subcomponents
require('./ethernet-settings/ethernet-settings');
require('./mirror-settings/mirror-settings');
require('./streaming-settings/streaming-settings');
require('./input-settings/input-settings');
require('./output-settings/output-settings');
require('./general-settings/general-settings');
require('./general-settings/splash-screen-settings/splash-screen-settings');
require('./record-settings/record-settings');
require('./wlan/wlan');
require('./wlan/wlan-region');
require('./login-settings/login-settings');
require('./visualizer-settings/visualizer-settings');
require('./ftp-settings/ftp-settings');
require('./hdcp-settings/hdcp-settings');
require('./cloud-settings/cloud-settings');
require('./troubleshoot/troubleshoot');
require('./about/about');
require('./factory-reset/factory-reset');
require('./system-reboot/system-reboot');
require('./security-settings/security-settings');
require('./netdrive-settings/netdrive-settings');
require('./audio-settings/audio-settings');
require('./webconference-settings/webconference-settings');
require('./collaboration-settings/collaboration-settings');
require('./ethernet2-settings/ethernet2-settings');
require('./peripheral-settings/peripheral-settings');
require('./office365-settings/office365-settings');
require('./proxy-settings/proxy-settings');
require('./matrix-settings/matrix-settings');
require('./matrix-settings/matrix-configuration/matrix-configuration-item');
require('./matrix-settings/matrix-netdrive/matrix-netdrive');
require('./vmeeting-settings/vmeeting-settings');
require('./wallpaper-settings/wallpaper-settings');
require('./matrix-settings/matrix-streaming-settings/matrix-streaming-settings');
require('./debug-settings/debug-settings');
require('./zoom-settings/zoom-settings');
require('./record-stream-settings/record-stream-settings');
require('./routing-settings/routing-settings');
require('./ping/ping');
require('./room-controller-settings/room-controller-settings');
