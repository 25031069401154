'use strict';

var app = require('../../../app');
var debugSettingsTpl = require('./debug-settings.hbs');
var FormActionView = require('../form-action-view');
var i18n = require('i18next');
var _ = require('lodash');
var $ = require('jquery');

/**
 * Feature pack relevant IDs.
 */
var fpIds = {
    demo: [],
    capture: [0, 1, 13],
    office: [3, 4],
    vmeeting: [9],
    matrix: [10]
};

app.component('DebugSettings', {
    template: debugSettingsTpl,

    getAccessKey: function() {
        return {
            'roleName': 'GeneralSettings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.validate = this.getService('ValidateService');
        this.deviceService = this.getService('DeviceService');
        this.webcastingService = this.getService('WebcastingService');
        this.actionView = [];
        this.debugList = [];
        this.form = [];
        this.formData = [];

        /**
         * Debug IDs:
         *   0: Opencast
         *   1: Webcast
         *   2: Airplay
         *   3: Libpurple --> deprecated Skype
         *   4: Libnice --> deprecated Skype
         *   5: P2PD
         *   6: WPA Supplicant
         *   7: Miracast
         *   8: Miracast Mice
         *   9: vSolution Meeting
         *   10: Matrix (Master)
         *   11: Groupwork
         *   12: Zoom
         *   13: Panopto
         *   14: Netdrive
         *   15: Audio
         *   16: Storage
         *   17: Framegrabber
         */
        if (this.deviceService.isCboxPureReceiver()) {
            this.debugIds = [6, 15];
        } else if (this.deviceService.isCboxPure() || this.deviceService.isCboxPureMini()) {
            this.debugIds = [2, 5, 6, 7, 8, 15];
        } else if (this.deviceService.isCboxPurePro()) {
            this.debugIds = [2, 5, 6, 7, 8, 9, 12, 14, 15, 16, 17];
        } else if (this.deviceService.isCboxCore()) {
            this.debugIds = [2, 5, 6, 7, 8, 9, 11, 12, 14, 15, 16, 17];
        } else if (this.deviceService.isCboxCorePro()) {
            this.debugIds = [0, 1, 2, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17];
        } else if (this.deviceService.isCboxProDualProjection()) {
            this.debugIds = [1, 2, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
        } else {
            this.debugIds = [0, 1, 2, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
        }
    },

    postPlaceAt: function() {
        this.loadData()
            .then(function() {
                this.createSelects();
                this.initForm();
                this.initFormAction();
                this.bindDOMEvents();
                this.bindEvents();
            }.bind(this));
    },

    /**
     * Checks for feature packs especially for the office feature pack
     *
     */
    filterDebugIds: function() {
        return this.deviceConnection
            .send('getLicenseFeatures', {
                excludeDefault: false
            })
            .then(function(features) {
                var excludeIds = [];

                if (!features.capture) {
                    $.merge(excludeIds, fpIds.capture);
                }

                if (!features.vmeeting) {
                    $.merge(excludeIds, fpIds.vmeeting);
                }

                if (!features.matrix) {
                    $.merge(excludeIds, fpIds.matrix);
                }

                _.each(excludeIds, function(id) {
                    var i = this.debugIds.indexOf(id);

                    if (i > -1) {
                        this.debugIds.splice(i, 1);
                    }
                }.bind(this));
            }.bind(this));
    },

    bindDOMEvents: function() {
        var index;

        for (var i = 0; i < this.debugIds.length; i++) {
            index = this.debugIds[i];

            this.form[index].on('change.input', this.handleFormChange.bind(this, index));
        }

        this.formDebugSettings.on('change.input', this.handleFormChange.bind(this, null));
    },

    bindEvents: function() {
        this.on('settings.save-changes', this.saveAll.bind(this));
    },

    initForm: function() {
        var index;

        // Init forms for debug levels
        for (var i = 0; i < this.debugIds.length; i++) {
            index = this.debugIds[i];

            this.form[index] = this.formManager.create({
                el: this.$el.find('#debug-level-item-form' + index),
                validationContainer: '.input-group'
            });

            this.form[index].setValues(this.formData[index]);
        }

        // Init form for other debug settings
        this.formDebugSettings = this.formManager.create({
            el: this.$el.find('#debug-settings-form')
        });

        this.formDebugSettings.setValues(this.formDataDebugSettings);
    },

    initFormAction: function() {
        var index;

        for (var i = 0; i < this.debugIds.length; i++) {
            index = this.debugIds[i];

            if (!this.actionView[index]) {
                this.actionView[index] = new FormActionView(app, {
                    id: index,
                    selector: this.$el.find('#form-action-container' + index),
                    onSubmit: this.saveHandler.bind(this, index),
                    onCancel: this.cancelHandler.bind(this, index)
                });
                this.actionView[index].render();
            }
        }

        if (!this.actionViewDebugSettings) {
            this.actionViewDebugSettings = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this, null),
                onCancel: this.cancelHandler.bind(this, null)
            });
            this.actionViewDebugSettings.render();
        }
    },

    handleFormChange: function(index) {
        if (null === index) {
            this.actionViewDebugSettings.open();
        } else {
            this.actionView[index].open();
        }

        this.checkActionForm(index);
        this.changes = true;
    },

    serialize: function() {
        var dfd = this.$.Deferred();

        this.filterDebugIds()
            .then(function() {
                this.load()
                    .then(function() {
                        dfd.resolve({
                            list: this.debugList
                        });
                    }.bind(this));
            }.bind(this));

        return dfd.promise();
    },

    checkActionForm: function(index) {
        if (null === index) {
            if (this.formDebugSettings.validate()) {
                this.actionViewDebugSettings.enableSubmitButton();
            } else {
                this.actionViewDebugSettings.disableSubmitButton();
            }

            return;
        }

        if (this.form[index].validate()) {
            this.actionView[index].enableSubmitButton();
        } else {
            this.actionView[index].disableSubmitButton();
        }
    },

    /**
     * Load debug level list.
     *
     * @returns {*}
     */
    load: function() {
        var commands = [];
        var index;

        for (var i = 0; i < this.debugIds.length; i++) {
            index = this.debugIds[i];

            commands.push({
                command: 'getDebugLevel',
                data: {
                    id: index
                }
            });
        }

        return this.deviceConnection
            .send(commands)
            .then(function() {
                this.debugList = arguments;
                this.parse();
            }.bind(this));
    },

    /**
     * Load data.
     *
     */
    loadData: function() {
        return this.deviceConnection
            .send('getSystemAnalysisEnable')
            .then(function(systemAnalysis) {
                this.formDataDebugSettings = {
                    'systemAnalysis': systemAnalysis.enabled
                };
            }.bind(this));
    },

    /**
     * Parse data and provide form data.
     *
     */
    parse: function() {
        this.formData = [];

        _.each(this.debugList, function(item) {
            this.formData[item.id] = {
                level: item.level
            };

            item.label = i18n.t('settings.debug_id.' + item.id);
        }.bind(this));
    },

    saveAll: function() {
        // Save debug level forms
        this.form.forEach((f, index) => this.saveHandler(index));
        // Save other debug settings
        this.saveHandler(null);
    },

    saveHandler: function(index) {
        var data;

        if (null === index) {
            if (this.formDebugSettings.validate()) {
                data = this.formDebugSettings.serialize();
                this.deviceConnection
                    .send([
                        {
                            command: 'setSystemAnalysisEnable',
                            data: {
                                enabled: data.systemAnalysis
                            }
                        }
                    ])
                    .done(function() {
                        this.handleSettingsSave();
                        this.formDebugSettings.setDefaultValues();
                    }.bind(this));
            }

            return;
        }

        if (this.form[index].validate()) {
            data = this.form[index].serialize();
            this.deviceConnection
                .send([
                    {
                        command: 'setDebugLevel',
                        data: {
                            id: index,
                            level: data.level
                        }
                    }
                ])
                .done(function() {
                    this.handleSettingsSave();
                    this.form[index].setDefaultValues();
                }.bind(this));
        }
    },

    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.changes = false;
    },

    cancelHandler: function(index) {
        if (null === index) {
            this.formDebugSettings.resetValues();
        } else {
            this.form[index].resetValues();
        }

        this.emit('overlay.remote.focus', true);
        this.changes = false;
    },

    createSelects: function() {
        var index;

        for (var i = 0; i < this.debugIds.length; i++) {
            index = this.debugIds[i];

            this.createComponent({
                type: 'CustomSelect',
                container: this.$el.find('#level-select-container' + index),
                label: 'settings.logging_level',
                native: true,
                id: 'level-select' + index,
                name: 'level',
                items: [
                    {
                        text: 'settings.debug_level.0',
                        value: 0
                    },
                    {
                        text: 'settings.debug_level.1',
                        value: 1
                    },
                    {
                        text: 'settings.debug_level.2',
                        value: 2
                    },
                    {
                        text: 'settings.debug_level.3',
                        value: 3
                    },
                    {
                        text: 'settings.debug_level.4',
                        value: 4
                    },
                    {
                        text: 'settings.debug_level.5',
                        value: 5
                    },
                    {
                        text: 'settings.debug_level.6',
                        value: 6
                    },
                    {
                        text: 'settings.debug_level.7',
                        value: 7
                    }
                ]
            });
        }
    },

    updateChanges: function(changes) {
        this.changes = changes;
    },

    destroy: function() {
        var index;

        for (var i = 0; i < this.debugIds.length; i++) {
            index = this.debugIds[i];

            this.formManager.destroy(this.form[index]);

            if (this.actionView[index]) {
                this.actionView[index].destroy();
            }
        }

        this.formManager.destroy(this.formDebugSettings);

        if (this.actionViewDebugSettings) {
            this.actionViewDebugSettings.destroy();
        }
    },

    hasChanges: function() {
        let changes =  {
            hasChanges: this.changes,
            invalid: false
        };

        const debugLevelFormsValid = this.form.map(f => f.validate()).reduce((a, b) => {
            return a && b;
        });

        changes.invalid = !debugLevelFormsValid || !this.formDebugSettings.validate();

        return changes;
    }
});
