'use strict';

const tpl = require('./controls-sub-menu.hbs');

/**
 * @param app
 * @param options
 * @constructor
 */
function ControlsSubmenu(app, options) {
    this.app = app;
    this.options = options;

    this.id = options.id;
    this.index = options.index;
    this.items = options.items;

    this.$el = this.app.$(tpl({
        id: options.id,
        items: options.items
    }));
    this.bindDOMEvents();
}

ControlsSubmenu.prototype.render = function($container) {
    $container.append(this.$el);
};

ControlsSubmenu.prototype.bindDOMEvents = function() {
    const children = this.$el.children();
    for (let i = 0; i < children.length; i++) {
        children[i].onmousedown = this.items[i].onPress;
        children[i].ontouchstart = this.items[i].onPress;
        children[i].onmouseup = this.items[i].onRelease;
        children[i].ontouchend = this.items[i].onRelease;
        children[i].onclick = this.items[i].onClick;
    }
};

module.exports = ControlsSubmenu;
