'use strict';

var app = require('../../../app');
var loginTpl = require('./vmeeting-details.html');
const vueUtils = require('../../../components-vue/util.js');
const dateLib = require('../../../date');
const base64js = require('base64-js');
const textEncoding = require('text-encoding');
const $ = require('jquery');

app.component('VMeetingDetails', {
    engine: 'vue',
    template: loginTpl,
    className: 'vmeeting-details-component',

    initialize: function() {
        this.meetingService = app.getService('SessionManagementService');

        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return this.load();
            }.bind(this)
        });
        this.pollHelper.on('data', function(data) {
            if (!data.available) {
                this.$viewModel.close();

                return;
            }

            this.$viewModel.subject = data.subject;
            this.$viewModel.organizer = data.organizer;
            this.$viewModel.startTime = data.startTime;
            this.$viewModel.endTime = data.endTime;
            this.$viewModel.description = data.description;
            this.$viewModel.invitees = data.invitees;
            this.$viewModel.dateFormat = data.dateFormat;
            this.$viewModel.attachments = data.attachments;
        }.bind(this));
        this.on('main-loop.update', this.pollHelper.schedulePoll);
    },

    data: {
        subject: 'VMeeting',
        organizer: 'John Doe',
        startTime: new Date(),
        endTime: new Date(),
        description: '',
        invitees: [{
            name: 'John Doe'
        }],
        attachments: [],
        dateFormat: ''
    },

    methods: {
        i18n: vueUtils.i18n,

        formatTime: function(time) {
            return dateLib.formatTime(
                this.dateFormat,
                {
                    hour: time.getUTCHours(),
                    minute: time.getUTCMinutes()
                },
                false);
        },

        showFiles: function() {
            app.emit('modal.close');
            app.emit('file-browser.open', 'VMeeting');
        },

        close: function() {
            this.component.emit('modal.close');
        }
    },

    watch: {
        description: {
            handler() {
                const descElem = $(this.$refs.description);
                descElem.html(this.description);

                // Handle hyperlinks
                descElem.find('a')
                    .addClass('focusable')
                    .on('click', (event) => {
                        event.preventDefault();

                        this.sourcesService.open({
                            type: 'browser',
                            url: event.target.href
                        }, { output: 'edit' });

                        this.close();
                        app.emit('control-center.close');
                    });

                // Handle images
                descElem.find('img')
                    .css({
                        width: '1px', // At least 1px is needed to focus with remote
                        height: '1px'
                    })
                    .addClass('icon-filetype-picture')
                    .addClass('focusable')
                    .on('click', this.showFiles.bind(this));
            }
        }
    },

    computed: {
    },

    created: function() {
        this.sourcesService = app.getService('SourcesService');
    },

    /**
     * Decode base64 encoded description.
     *
     * @param str
     * @returns {string}
     * @constructor
     */
    Base64Decode: function(str) {
        if (!str || str.length === 0) {
            return '';
        }

        const bytes = base64js.toByteArray(str);
        const encoder = new textEncoding.TextDecoder('utf-8');

        return encoder.decode(bytes);
    },

    load: function() {
        const meetingDetails = this.meetingService.getActiveSessionDetails();

        return Promise.resolve({
            available: !!meetingDetails,
            subject: meetingDetails.subject || '',
            organizer: meetingDetails.organizer && meetingDetails.organizer.name || '',
            attachments: meetingDetails.attachments || [],
            startTime: new Date(meetingDetails.start * 1000),
            endTime: new Date(meetingDetails.end * 1000),
            description: this.Base64Decode(meetingDetails.body),
            invitees: meetingDetails.attendees || [],
            dateFormat: this.meetingService.getDateFormat()
        });
    },

    serialize: function() {
        return this.pollHelper.serialize();
    },

    components: {
        'command-button': require('../../../components-vue/control-center-v3/command-button/command-button.js')
    }
});
