'use strict';

var _ = require('lodash');
var app = require('../../../app');
var tpl = require('./eula.hbs');
var LayoutManager = require('../../../../layout/layout');
var platform = require('../../../../platform/platform');
var TIME_LEFT = 10; // In sec

app.component('Eula', {
    template: tpl,
    className: 'eula-container',
    idName: 'eula',

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.eula = this.getService('EulaService');
        this.remote = this.getService('RemoteService');
        this.scrolledToBottom = false;
    },

    bindEvents: function() {
        LayoutManager.on('window.resize', _.debounce(this.resizeHandler.bind(this), 100));
        this.on('modal.opened-end', function() {
            this.$el.find('.eula-text-scroller').scrollTop(0);
        }.bind(this));
    },

    postPlaceAt: function() {
        this.initForm();
        this.bindDOMEvents();
        this.bindEvents();
        this.resizeHandler();

        if (!this.options.configs.isInfo) {
            this.updateTimer(TIME_LEFT);
        }
    },

    serialize: function() {
        return {
            wlanRegion: app.getService('BootstrapService').adjustmentWlanRegion,
            configs: this.options.configs
        };
    },

    /**
     * Update timer. After 10 seconds the EULA dialog is accepted and closed.
     */
    updateTimer: function(timeLeft) {
        this.$el.find('.counter').html(timeLeft);

        if (timeLeft <= 0) {
            this.onAccept();
        } else {
            setTimeout(function() {
                this.updateTimer(timeLeft - 1);
            }.bind(this), 1000);
        }
    },

    resizeHandler: function() {
        this.$el.css({
            height: this.$('#modal-view-container').height() + 'px'
        });
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#eula-form'),
            validationContainer: '.input-group'
        });
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.eula-close', this.onClose.bind(this));
        this.$el.find('.eula-text-scroller').on('keydown', this.onKeyDown.bind(this));
        this.$el.find('.eula-text-scroller').on('scroll', this.onScroll.bind(this));
    },

    /**
     * Key down handler.
     * @param event
     */
    onKeyDown: function(event) {
        var keyCode = event.keyCode;

        if (keyCode === this.remote.KEYCODES.ENTER) {
            if (!this.blockedEl) {
                var el = this.$el.find('.eula-action-container').get(0);

                this.remote.block();
                this.blockedEl = el;
            } else {
                this.remote.unblock();
                this.blockedEl = null;
            }

            this.remote.focus(this.$el.find('.eula-action-container'));
        } else if (keyCode === 0 || keyCode === this.remote.KEYCODES.LEFT || keyCode === this.remote.KEYCODES.RIGHT
            || (keyCode === this.remote.KEYCODES.DOWN && this.scrolledToBottom)) {
            this.remote.focus(this.$el.find('.eula-action-container'));
        }
    },

    /**
     * On scroll handler checks if scrolled to bottom.
     * @param event
     */
    onScroll: function(event) {
        this.scrolledToBottom = event.target.offsetHeight + event.target.scrollTop > event.target.scrollHeight;
    },

    /**
     * Accept EULA and close dialog.
     */
    onAccept: function() {
        if (platform.checks.isCbox) {
            this.deviceConnection
                .send('setEulaAccept', {
                    accept: true
                });
        }

        if (this.eula.onAccept) {
            this.eula.onAccept();
        }

        this.emit('modal.close', {
            id: this.options.configs.id
        });
    },

    /**
     * Close handler.
     */
    onClose: function() {
        this.emit('modal.close', {
            id: this.options.configs.id
        });
    }
});

