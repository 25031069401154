'use strict';

var dynamicFontTpl = require('./dynamic-font.hbs');
var _ = require('lodash');
var $ = require('jquery');

/**
 * Claculates the font-size by text length.
 *
 * <dynamic-font min-font-size="16"></dynamic-font>
 *
 * @type {HTMLElement}
 */
var DynamicFontPrototype = Object.create(HTMLElement.prototype);

/**
 * Is called when a new element is created.
 */
DynamicFontPrototype.connectedCallback = function() {
    this.boot();
};

DynamicFontPrototype.boot = function() {
    var content = this.getAttribute('content');
    this.$el = $(this);

    $(this).append(dynamicFontTpl({
        content: content
    }));

    this.resizeHandler();
    this.bindDOMEvents();
};

/**
 * Calculate font-size after text has been changed.
 */
DynamicFontPrototype.resizeHandler = function() {
    var getFontSize = function(textLength) {
        var baseSize = 1.6;
        var fontSize;

        if (16 >= textLength) {
            return baseSize;
        }

        fontSize = baseSize - (textLength - 16) / 20;

        return 1.3 > fontSize ? 1.3 : fontSize;
    };

    var fontSize = getFontSize($(this).html().length);

    this.$el.css({
        fontSize: fontSize + 'vw'
    });

    /**
     * Do not wrap when the min. size isn't reached yet.
     */
    while (this.$el.height() > (parseInt(this.$el.css('lineHeight')) * 2) && fontSize > 1.3) {
        fontSize -= 0.1;

        if (fontSize < 1.3) {
            fontSize = 1.3;
        }

        this.$el.css({
            fontSize: fontSize + 'vw'
        });
    }
};

/**
 * Bind DOM events.
 */
DynamicFontPrototype.bindDOMEvents = function() {
    $(this).on('dynamicfont.resize', _.throttle(this.resizeHandler.bind(this), 100));
};

// Register element
// Modern Browsers.
if (('customElements' in window) && ('define' in window.customElements)) {
    var DynamicFontElement = function() {
        return Reflect.construct(HTMLElement, [], DynamicFontElement);
    };

    DynamicFontElement.prototype = Object.assign(Object.create(HTMLElement.prototype), DynamicFontPrototype);

    window.customElements.define('dynamic-font', DynamicFontElement);
// Microsoft Browsers :(
} else {
    document.registerElement('dynamic-font', {
        prototype: DynamicFontPrototype
    });
}
