'use strict';

var $ = require('jquery');
var app = require('../app');
var StateMachine = require('./../state-machine');
var platform = require('../../platform/platform');
var LayoutManager = require('../../layout/layout');

var states = {
    smartphone: 'smartphone',
    desktop: 'desktop'
};

app.service('MobileService', function() {
    return {
        /**
         * @method inittialize
         */
        initialize: function() {
            this.storeSelectors();
            this.state = new StateMachine({
                context: this,
                state: states.desktop,
                states: states
            });
            this.addStateTransitions();

            this.checkViewport();
            this.bindEvents();
        },

        /**
         * @method storeSelectors
         */
        storeSelectors: function() {
            this.$body = $('body');
            this.$main = $('#main');
            this.$document = $(document);
            this.$window = $(window);
        },

        /**
         * @method bindEvents
         */
        bindEvents: function() {
            LayoutManager.on('window.resize', this.checkViewport.bind(this));
            this.$document.on('focusin', 'input', this.onFocusInHandler.bind(this));
            this.$document.on('focusout', 'input', this.onFocusOutHandler.bind(this));
            this.$document.on('touchstart', this.enterFullScreen.bind(this));
        },

        onFocusInHandler: function() {
            var height = screen.height;

            if (platform.checks.isAndroid && this.state.getState() === states.smartphone) {
                this.$body.css({
                    overflow: 'scroll'
                });
                this.$main.css({
                    marginBottom: (height / 2) + 'px'
                });
            }
        },

        /**
         * @method onFocusOutHandler
         */
        onFocusOutHandler: function() {
            if (this.state.getState() === states.smartphone) {
                $('html, body').animate({
                    scrollTop: 0
                }, 100);

                if (platform.checks.isAndroid) {
                    this.$body.css({
                        overflow: 'hidden'
                    });
                    this.$main.css({
                        marginBottom: 0
                    });
                }
            }
        },

        /**
         * @method checkViewport
         */
        checkViewport: function() {
            if (!!platform.checks.isSmartphone() && this.state.getState() === states.desktop) {
                this.state.changeState(states.smartphone);
            } else if (!platform.checks.isSmartphone() && this.state.getState() === states.smartphone) {
                this.state.changeState(states.desktop);
            }
        },

        /**
         * @method addStateTransitions
         */
        addStateTransitions: function() {
            this.state.addTransitions({
                '> smartphone': function() {
                    var height = screen.height;

                    if (platform.checks.isIOS) {
                        height = '100%';
                    }

                    this.$body.css({
                        height: height
                    });
                },
                '> desktop': function() {
                    this.$body.css({
                        height: '100%'
                    });
                }
            });
        },

        /**
         * @method enterFullScreen
         */
        enterFullScreen: function() {
            if (this.state.getState() === states.smartphone) {
                var doc = window.document;
                var docEl = doc.documentElement;

                var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
                if (!!requestFullScreen && !doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
                    requestFullScreen.call(docEl);
                }
            }
        }
    };
});
