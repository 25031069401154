'use strict';

/** @var {Object} _ */
var _ = require('lodash');
var app = require('../../../../app');

var controlItems = [
    {
        id: 'move-down',
        state: 'move-down',
        useHold: true,
        states: {
            'move-down': {
                icon: 'icon-arrow-down6',
                titleKey: ''
            }
        }
    },
    {
        id: 'move-up',
        state: 'move-up',
        useHold: true,
        states: {
            'move-up': {
                icon: 'icon-arrow-up5',
                titleKey: ''
            }
        }
    },
    {
        id: 'move-left',
        state: 'move-left',
        states: {
            'move-left': {
                icon: 'icon-arrow-left6',
                titleKey: ''
            }
        }
    },
    {
        id: 'move-right',
        state: 'move-right',
        states: {
            'move-right': {
                icon: 'icon-uniE709',
                titleKey: ''
            }
        },
        groupEnd: true
    },
    {
        id: 'zoom-out',
        state: 'zoom-out',
        states: {
            'zoom-out': {
                icon: 'icon-v2-zoom-out',
                titleKey: ''
            }
        }
    },
    {
        id: 'zoom-in',
        state: 'zoom-in',
        states: {
            'zoom-in': {
                icon: 'icon-v2-zoom-in',
                titleKey: ''
            }
        },
        groupEnd: true
    },
    {
        id: 'full-page-width',
        state: 'full-page-width',
        states: {
            'full-page-width': {
                icon: 'icon-v2-fit-to-width',
                titleKey: ''
            }
        }
    },
    {
        id: 'full-page',
        state: 'full-page',
        states: {
            'full-page': {
                icon: 'icon-v2-fit-to-width-height',
                titleKey: ''
            }
        }
    },
    {
        id: 'page-count',
        type: 'page-count',
        state: 'page-count'
    }
];

/**
 * PDF frame
 */
app.component('FrameBoxPDFViewer', {
    extend: 'FrameBoxBaseType',
    className: 'framebox-view framebox-pdf-view',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.frameBoxService = this.getService('FrameBoxService');
    },

    postPlaceAt: function() {
        this.startControls();
        this.bindEvents();
        this.setCount(this.options.frameBoxOptions);
        this.lastMove = null;
    },

    bindEvents: function() {
        this.on('framebox.change.' + this.options.index, this.onFrameBoxChangeHandler.bind(this));

        // Standard-mode.
        this.on('framebox.standard.pinchin', this.controlHandlers['zoom-out'].bind(this));
        this.on('framebox.standard.pinchout', this.controlHandlers['zoom-in'].bind(this));
        this.on('framebox.standard.pandown', this.controlHandlers['move-up'].bind(this));
        this.on('framebox.standard.panup', this.controlHandlers['move-down'].bind(this));
        this.on('framebox.standard.panleft', this.controlHandlers['move-right'].bind(this));
        this.on('framebox.standard.panright', this.controlHandlers['move-left'].bind(this));

        // Remote-control.
        this.on('framebox.standard.left.keydown', _.throttle(this.controlHandlers['move-left'].bind(this), 150));
        this.on('framebox.standard.right.keydown', _.throttle(this.controlHandlers['move-right'].bind(this), 150));
        this.on('framebox.standard.up.keydown', this.controlHandlers['move-up'].bind(this));
        this.on('framebox.standard.down.keydown', this.controlHandlers['move-down'].bind(this));
        this.on('framebox.standard.zoom-in.keydown', this.controlHandlers['zoom-in'].bind(this));
        this.on('framebox.standard.zoom-out.keydown', this.controlHandlers['zoom-out'].bind(this));
        this.on('framebox.standard.preset', this.controlHandlers['preset'].bind(this));

        // Mouse.
        this.on('framebox.standard.mousewheel-up', this.controlHandlers['move-up'].bind(this));
        this.on('framebox.standard.mousewheel-down', this.controlHandlers['move-down'].bind(this));

        this.on('framebox-' + this.options.index + '.controls.set-page', this.controlHandlers['set-page'].bind(this));
    },

    startControls: function() {
        this.createComponent({
            type: 'FrameBoxControls',
            container: this.$el,
            index: this.options.index,
            appId: this.options.appId,
            items: controlItems,
            onClick: this.onControlClickHandler.bind(this),
            onHold: this.onControlClickHandler.bind(this),
            isFullscreen: this.options.isFullscreen
        });
    },

    /**
     * @param {string} id
     */
    onControlClickHandler: function(id) {
        var handler = this.controlHandlers[id];

        if (handler) {
            handler.call(this, {
                index: this.index
            });
        }
    },

    controlHandlers: {
        /**
         * Move up.
         */
        'move-up': function(options) {
            if (this.sendMoveEvent()) {
                this.sendCmd('move-up', options.index);
            }
        },

        /**
         * Move down.
         */
        'move-down': function(options) {
            if (this.sendMoveEvent()) {
                this.sendCmd('move-down', options.index);
            }
        },

        /**
         * Move right
         */
        'move-right': function(options) {
            if (this.sendMoveEvent()) {
                this.sendCmd('move-right', options.index);
            }
        },

        /**
         * Move left
         */
        'move-left': function(options) {
            if (this.sendMoveEvent()) {
                this.sendCmd('move-left', options.index);
            }
        },

        /**
         * Scroll view to up.
         */
        'scroll-up': function(options) {
            this.sendCmd('scrollUp', options.index);
        },

        /**
         * Scroll view to down.
         */
        'scroll-down': function(options) {
            this.sendCmd('scrollDown', options.index);
        },

        /**
         * Scroll view to right
         */
        'scroll-right': function(options) {
            this.sendCmd('scrollRight', options.index);
        },

        /**
         * Scroll view to left.
         */
        'scroll-left': function(options) {
            this.sendCmd('scrollLeft', options.index);
        },

        /**
         * Zoom into pdf.
         */
        'zoom-in': function(options) {
            this.sendCmd('zoom-in', options.index);
        },

        /**
         * Zoom out from pdf.
         */
        'zoom-out': function(options) {
            this.sendCmd('zoom-out', options.index);
        },

        /**
         * Set view to full width.
         */
        'preset': function(options) {
            this.sendCmd('fullWidth', options.index);
        },

        /**
         * Set view to full page. Everything is in sight.
         */
        'full-page': function(options) {
            this.sendCmd('fullPage', options.index);
        },

        /**
         * Set view to full width.
         */
        'full-page-width': function(options) {
            this.sendCmd('fullWidth', options.index);
        },

        /**
         * Set page.
         */
        'set-page': function(options) {
            this.sendCmd('setPage', options.index, options.page);
        }
    },

    /**
     * Avoid sending too many move events to prevent multiple page changes
     *
     * @returns {boolean}
     */
    sendMoveEvent: function() {
        if (this.options.frameBoxOptions.zoomLevel > 100) {
            this.lastMove = null;

            return true;
        } else if (this.lastMove && ((Date.now() - this.lastMove) >= 500)) {
            this.lastMove = null;
        }

        if (!this.lastMove) {
            this.lastMove = Date.now();

            return true;
        }

        return false;
    },

    /**
     * @param {string} cmd
     * @param {int} index
     */
    sendCmd: function(cmd, index, param) {
        if (this.index === index) {
            this.send('setPdfControl', {
                appId: this.options.appId,
                cmd: cmd,
                param: param
            });
        }
    },

    /**
     * @param {Object} frameBox
     */
    onFrameBoxChangeHandler: function(frameBox) {
        this.options.frameBoxOptions = frameBox.options;
        this.setCount(frameBox.options);
    },

    /**
     * Print out the number of the current pdf-page.
     *
     * @param metaData
     */
    setCount: function(metaData) {
        this.emit('framebox.page.change', {
            index: this.options.index,
            currentPage: metaData.pdfCurrentPage,
            totalPages: metaData.pdfTotalPages
        });
    }
});
