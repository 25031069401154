'use strict';

var app = require('../../../app');
var loginTpl = require('./upload-target-selection.html');
var mediaListIcons = require('../../media-list/icons.js');
const vueUtils = require('../../../components-vue/util.js');
const filebrowserUtils = require('../../filebrowser/utils');

const cloudStates = {
    synced: 'synced',
    connected: 'connected',
    disconnected: 'disconnected',
    failed: 'failed',
    oauth: 'oauth'
};

app.component('UploadTargetSelection', {
    engine: 'vue',
    template: loginTpl,
    className: 'upload-target-selection-component',

    data: function() {
        return {
            targets: [{
                icon: 'icon-google-drive',
                titleKey: 'Google Drive',
                iconConnection: 'icon-lock1',
                selected: true,
                type: 'cloud',
                connection: cloudStates.disconnected
            }]
        };
    },

    methods: {
        /**
         * Find icon for media
         * @param media media object
         */
        findMediaIcon: function(media) {
            return mediaListIcons.pictogram(media);
        },

        /**
         * Find icon for media connection state
         * @param media media object
         */
        findConnectionIcon: function(media) {
            return mediaListIcons.connection(media);
        },

        selectMedia: function(media) {
            media.selected = !media.selected;
        },

        cancel: function() {
            this.component.options.configs.onCancel();
        },

        confirm: function() {
            this.component.options.configs.onConfirm({
                targets: this.selectedTargets
            });
        },

        i18n: vueUtils.i18n
    },

    computed: {
        selectedTargets: function() {
            return this.targets.filter(function(mount) {
                return mount.selected;
            });
        }
    },

    initialize: function() {
        this.remote = this.getService('RemoteService');
        this.mountService = this.getService('MountService');
    },

    postPlaceAt: function() {
        this.bindEvents();
    },

    bindEvents: function() {
        this.on('modal.opened-end', function() {
            this.remote.focusDefault();
        }.bind(this));
    },

    load: function() {
        return vueUtils.promiseToSerialize(this.mountService.fetchMounts()
            .then(filebrowserUtils.possibleUploadTargets)
            .then(function(mounts) {
                let targets = mounts.map(function(mount) {
                    return Object.assign({}, mount, {
                        selected: false
                    });
                });
                if (targets.length === 1) {
                    targets[0].selected = true;
                }

                return {
                    targets: targets
                };
            }));
    },

    serialize: function() {
        return this.load();
    }
});
