'use strict';

var app = require('../../../app');
var $ = require('jquery');
var magicPenMenuTpl = require('./magic-pen-menu.hbs');
var platform = require('./../../../../platform/platform');

app.component('MagicPenMenu', {
    template: magicPenMenuTpl,
    className: 'magic-pen-menu-container',

    getAccessKey: function() {
        return {
            'roleName': 'MagicPen',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.frontendSettings = this.getService('FrontendSettings');
        this.annotationService = this.getService('AnnotationService');
        this.frameBoxService = this.getService('FrameBoxService');
        this.stationService = this.getService('StationService');
        this.screensaverService = this.getService('ScreensaverService');

        this.resetPositions();
    },

    resetPositions: function() {
        this.posDown = {
            x: -1,
            y: -1
        };
        this.posMove = {
            x: -1,
            y: -1
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindDOMEvents();
        this.bindEvents();
    },

    storeSelectors: function() {
        this.$container = this.$el.parent();
    },

    bindDOMEvents: function() {
        $('#magic-pen-draw-container').on('pointerdown', this.onPointerDown.bind(this));
        $('#frameboxes-container').on('pointerdown', this.onPointerDown.bind(this));

        $('#magic-pen-draw-container').on('pointerup', this.onPointerUp.bind(this));
        $('#frameboxes-container').on('pointerup', this.onPointerUp.bind(this));

        $('#magic-pen-draw-container').on('mousemove touchmove', this.onPointerMove.bind(this));
        $('#frameboxes-container').on('mousemove touchmove', this.onPointerMove.bind(this));

        this.$el.on('pointerdown', '.magic-pen-menu-item', this.onMenuClickHandler.bind(this));
        this.$el.on('pointerdown', '.magic-pen-menu-pin', this.onPinClickHandler.bind(this));

        if (platform.checks.isSafari || (platform.checks.isTouchDevice && platform.checks.isFirefox)) {
            $('#magic-pen-draw-container').on('mousedown touchstart', this.onPointerDown.bind(this));
            $('#frameboxes-container').on('mousedown touchstart', this.onPointerDown.bind(this));

            $('#magic-pen-draw-container').on('mouseup touchend', this.onPointerUp.bind(this));
            $('#frameboxes-container').on('mouseup touchend', this.onPointerUp.bind(this));

            this.$el.on('mousedown touchstart', '.magic-pen-menu-item, .magic-pen-menu-pin', this.onMenuClickHandler.bind(this));
        }
    },

    unbindDOMEvents: function() {
        $('#magic-pen-draw-container').off('pointerdown pointerup mousemove touchmove mousedown touchstart mouseup touchend');
        $('#frameboxes-container').off('pointerdown pointerup mousemove touchmove mousedown touchstart mouseup touchend');
    },

    bindEvents: function() {
        this.on('magic-pen.state.inactive', this.closeMenu.bind(this));

        if (platform.checks.isTouchDevice) {
            this.on('framebox.standard.pinch', this.onPointerUp.bind(this));
            this.on('framebox.standard.pinchin', this.onPointerUp.bind(this));
            this.on('framebox.standard.pinchout', this.onPointerUp.bind(this));
            this.on('framebox.standard.pinchend', this.onPointerUp.bind(this));
            this.on('framebox.standard.pinchcancel', this.onPointerUp.bind(this));
        }
    },

    /**
     * Handle magic pen active/inactive state.
     */
    handleMagicPen: function() {
        if ((this.annotationService.isMagicPenActive() && this.annotationService.isMagicPenStarted())
            || this.annotationService.isActive() || this.stationService.getPushStatus() || this.stationService.getLockStatus()
            || this.screensaverService.isScreensaverActive()) {
            return;
        }

        this.openMenu(this.posDown);
        this.annotationService.startMagicPen();
    },

    /**
     * Handle menu button click.
     *
     * @param event
     */
    onMenuClickHandler: function(event) {
        var $el = this.$(event.currentTarget);
        var action = $el.data('action');

        this.$el.find('.magic-pen-menu-item[data-action="' + action + '"]').removeClass('is-active');
        $el.addClass('is-active');

        this.emit('magic-pen.' + action, event);
    },

    /**
     * Handle pin button click.
     * It's a toggle button and a bit different than the other menu buttons.
     *
     * @param event
     */
    onPinClickHandler: function(event) {
        var $el = this.$(event.currentTarget);
        var action = $el.data('action');

        this.emit('magic-pen.' + action, event);
    },

    /**
     * Open submenu for Magic Pen properties.
     */
    openMenu: function(position) {
        if (this.$container.hasClass('is-opened') || this.$container.hasClass('is-closing')) {
            return;
        }

        // Calculate position of menu. It should be fully visible.
        var marginLeft = parseInt($('#aspect-ratio-4-3').css('margin-left'));
        var marginTop = parseInt($('#aspect-ratio-4-3').css('margin-top'));
        var menuWidth = this.$container.outerWidth();
        var menuHeight = this.$container.outerHeight();

        var left = position.x - marginLeft - (menuWidth / 2);
        var top = position.y - marginTop - (menuHeight / 2);

        if (left < 0) {
            left = 0;
        } else if ((left + menuWidth) > $('#aspect-ratio-4-3').width()) {
            left = $('#aspect-ratio-4-3').width() - menuWidth;
        }

        if (top < 0) {
            top = 0;
        } else if ((top + menuHeight) > $('#aspect-ratio-4-3').height()) {
            top = $('#aspect-ratio-4-3').height() - menuHeight;
        }

        this.$container.css(
            {
                left: left,
                top: top
            });

        this.$container.addClass('is-opened');

        setTimeout(function() {
            this.$el.find('.magic-pen-menu-item, .magic-pen-menu-pin').addClass('animate');
        }.bind(this), 100);

        this.emit('framebox.unbind-events');
    },

    /**
     * Close submenu for Magic Pen properties.
     */
    closeMenu: function() {
        this.$container.addClass('is-closing');
        this.$el.find('.magic-pen-menu-item .magic-pen-menu-pin').removeClass('animate');

        setTimeout(function() {
            this.$container.removeClass('is-opened');
            this.$container.removeClass('is-closing');
        }.bind(this), 500);
    },

    onPointerDown: function(event) {
        if ($(event.target).hasClass('framebox-bar-item') || $(event.target).parents().hasClass('framebox-bar-item')
            || $(event.target).hasClass('statusbar-pin-container') || $(event.target).parents().hasClass('statusbar-pin-container')) {
            return;
        }

        this.posDown = this.setPosition(event);

        this.pressTimer = setTimeout(function() {
            if (this.posDown.x === -1 || this.posDown.y === -1
                || (
                    this.posMove.x !== -1
                    && (Math.abs(this.posMove.x - this.posDown.x) > 20 || Math.abs(this.posMove.y - this.posDown.y) > 20)
                )
            ) {
                this.resetPositions();

                return;
            }

            app.emit('main-loop.fast.start', {
                id: 'annotation'
            });

            this.frameBoxService.resolveLastEvents();
            this.handleMagicPen();
        }.bind(this), 1000);
    },

    /**
     * Set position from event (touch/pointer/mouse)
     *
     * @param event Current event
     * @returns {{x: x-coordinate, y: y-coordinate}}
     */
    setPosition: function(event) {
        var position;

        switch (event.type) {
            case 'touchstart':
            case 'touchmove':
                position = {
                    x: event.originalEvent.touches[0].clientX,
                    y: event.originalEvent.touches[0].clientY
                };
                break;
            default: // Mousedown, mousemove
                position = {
                    x: event.clientX,
                    y: event.clientY
                };
                break;
        }

        return position;
    },

    onPointerMove: function(event) {
        if ($(event.target).hasClass('framebox-bar-item') || $(event.target).parents().hasClass('framebox-bar-item')) {
            return;
        }

        this.posMove = this.setPosition(event);
    },

    onPointerUp: function() {
        this.resetPositions();
        clearTimeout(this.pressTimer);
    },

    destroy: function() {
        this.unbindDOMEvents();
    }
});
