const template = require('./custom-command-controls.html');
const vueUtils = require('../../util.js');
const app = require('../../../app');

module.exports = {
    template,
    props: {
        options: {
            type: Object
        },

        flat: {
            type: Boolean,
            default: false,
            required: false
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data: function() {
        return {
            buttons: [{
                name: 'C1',
                event: 'button1',
                busy: false,
                isHalfWidth: false
            }]
        };
    },

    methods: {
        /**
         * Execute custom command
         * @param button view model of button
         */
        customCommand: function(button) {
            button.busy = true;

            this.wolfprot.talk('setRmsClientTrigger', {
                eventName: button.event
            }).finally(function() {
                this.buttons.filter(function(vmButton) {
                    return vmButton.event === button.event;
                }).forEach(function(vmButton) {
                    vmButton.busy = false;
                });
            }.bind(this));
        }
    },

    computed: {
        buttonId: function(button) {
            return 'custom-command-btn-' + button.event;
        }
    },

    created: function() {
        this.wolfprot = vueUtils.wolfprot();
        this.peripheralService = app.getService('PeripheralService');
        this.frontendSettings = app.getService('FrontendSettings');

        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return Promise.all([
                    this.frontendSettings.getSettings(['customCommandButtons']),
                    this.peripheralService.updateCommands()
                ]).then(function([customCommandButtons]) {
                    return {
                        customCommandButtons
                    };
                }.bind(this));
            }.bind(this)
        });
        this.pollHelper.on('data', function({ customCommandButtons }) {
            const buttonEvents = this.peripheralService.getButtonEvents();
            let defaultButtons = Object.assign({}, this.frontendSettings.getDefaultSetting('customCommandButtons'));
            customCommandButtons = Object.assign(defaultButtons, customCommandButtons);

            let buttonEventsNoDuplicates = [];
            buttonEvents.forEach((buttonEvent) => {
                const hasButtonEvent = buttonEventsNoDuplicates.some((buttonEventNoDuplicate) => {
                    return buttonEventNoDuplicate.event === buttonEvent.event;
                });

                if (!hasButtonEvent) {
                    buttonEventsNoDuplicates = [...buttonEventsNoDuplicates, buttonEvent];
                }
            });

            this.buttons = buttonEventsNoDuplicates.filter(function(buttonEvent) {
                return buttonEvent.enabled;
            }).map(function(buttonEvent, index, buttonEvents) {
                const oldButton = this.buttons.find(function(button) {
                    return button.event === buttonEvent.event;
                });

                let isHalfWidth = buttonEvents.length >= 8
                    || buttonEvents.length >= 7 && index < 6
                    || buttonEvents.length >= 6 && index < 4
                    || buttonEvents.length >= 5 && index < 2;

                return {
                    name: customCommandButtons[buttonEvent.event].name,
                    event: buttonEvent.event,
                    busy: (oldButton && oldButton.busy) || false,
                    isHalfWidth: isHalfWidth
                };
            }.bind(this));
        }.bind(this));
        this.pollHelper.schedulePoll();

        this.evctx = vueUtils.eventContext();
        this.evctx.on('main-loop.update', function() {
            this.pollHelper.schedulePoll();
        }.bind(this));
    },

    destroyed: function() {
        this.evctx.close();
    },

    components: {
        'command-button': require('../command-button/command-button.js')
    }
};
