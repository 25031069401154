'use strict';

var $ = require('jquery');
var app = require('../../../app');
var i18n = require('i18next');
var securitySettingsTpl = require('./security-settings.hbs');
var FormActionView = require('../form-action-view');
var states = require('../../../states');
var platform = require('./../../../../platform/platform');

app.component('SecuritySettings', {
    template: securitySettingsTpl,
    actionView: null,
    formData: {},

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.deviceService = this.getService('DeviceService');
        this.blockSaving = false;
        this.isEncrypted = false;

        return this.loadSecuritySettings();
    },

    postPlaceAt: function() {
        this.$diskEncryptionState = this.$el.find('#disk-encryption-state');
        this.$diskEncryptionToggleInfo = this.$el.find('#disk-encryption-toggle-info');
        this.$encryptionLoader = this.$el.find('#disk-encryption-loader');
        this.$diskEncryptContainer = this.$el.find('#form-encrypt-action-container');
        this.$diskEncryptButton = this.$el.find('#disk-encryption-start');
        this.$reverseShellContainer = this.$el.find('.reverse-shell-container');
        this.$reverseShellState = this.$el.find('#reverse-shell-state');

        this.initForm();
        this.updateForm();
        this.bindEvents();
        this.bindDomEvents();
        this.initFormAction();
        this.showEncryptBtn();
        this.checkReverseShell();

        this.updateHandler();
    },

    serialize: function() {
        return {
            isCboxPureOrPro: this.deviceService.isCboxPureOrPro(),
            isCboxCoreOrCoreProOrPurePro: this.deviceService.isCboxCoreOrCoreProOrPurePro(),
            isCboxPure: this.deviceService.isCboxPure(),
            isCboxPureMini: this.deviceService.isCboxPureMini(),
            isCbox: this.deviceService.isCbox(),
            isCboxPro: this.deviceService.isCboxPro(),
            'cynapName': app.getService('Model-View').name
        };
    },

    bindDomEvents: function() {
        this.form.$el.on('keyup, change', this.handleFormChange.bind(this));
        this.$diskEncryptButton.on('click', this.onEncryptBtnClicked.bind(this));
    },

    bindEvents: function() {
        this.on('overlay.action', this.saveHandler.bind(this));
        this.on('remote.enter.keydown', this.checkActionForm.bind(this));
        this.on('settings.save-changes', this.onSaveChanges.bind(this));
        this.on('main-loop.update', this.updateHandler.bind(this));
    },

    onEncryptBtnClicked: function() {
        this.hideDiskEncryptionBtn();
        this.emit('modal.open', {
            id: 'ui-blocker'
        });

        this.deviceConnection.send('setDiskEncryptionEnable');
    },

    /**
     * Show disk encryption enable information-text.
     */
    showDiskEncryptionInfo: function() {
        this.$diskEncryptionToggleInfo.show();
    },

    /**
     * Show disk encryption action button.
     */
    showDiskEncryptionBtn: function() {
        this.$diskEncryptContainer.show();
    },

    /**
     * Hide disk encryption action button.
     */
    hideDiskEncryptionBtn: function() {
        this.$diskEncryptContainer.hide();
    },

    /**
     * Shows the start-encryption button if browser is on CYNAP and status is decrypted.
     */
    showEncryptBtn: function() {
        if (platform.checks.isCbox && !this.isEncrypted) {
            this.showDiskEncryptionInfo();
            this.showDiskEncryptionBtn();
        }
    },

    /**
     * Sohw/Hide reverse shell port and enable/disable port validation.
     */
    checkReverseShell: function() {
        if (this.form.get('reverseShellEnable').getValue()) {
            this.$reverseShellContainer.show();
            this.form.get('reverseShellPort').enable();
            this.form.get('reverseShellWebpageRedirectEnable').enable();
            this.form.get('reverseShellDebugPortEnable').enable();
        } else {
            this.$reverseShellContainer.hide();
            this.form.get('reverseShellPort').disable();
            this.form.get('reverseShellWebpageRedirectEnable').disable();
            this.form.get('reverseShellDebugPortEnable').disable();
        }
    },

    /**
     * Update disk encryption status on every main-loop call.
     */
    updateHandler: function() {
        this.deviceConnection
            .send([
                'getDiskEncryptionStatus',
                'getReverseShell'
            ])
            .then(function(discEncryption, reverseShell) {
                this.onDiskEncryptionStatusLoaded(discEncryption);
                this.onReverseShellStatusLoaded(reverseShell);
            }.bind(this));
    },

    /**
     * Change Disk encryption status message.
     *
     * @param {Object} diskEncryption
     */
    onDiskEncryptionStatusLoaded: function(diskEncryption) {
        if (states.encryptionStates.failed === diskEncryption.status) {
            this.$el.find('#encryption-enable-field').remove();
            this.$diskEncryptionToggleInfo
                .show()
                .addClass('has-failed')
                .find('.text')
                .html(i18n.t('settings.encryption_failed', {
                    'cynapName': app.getService('Model-View').name
                }));

            this.hideDiskEncryptionBtn();
        } else if (states.encryptionStates.inProgress === diskEncryption.status) {
            this.hideDiskEncryptionBtn();

            this.emit('modal.open', {
                id: 'ui-blocker'
            });
        }

        this.checkActionForm();
        this.$diskEncryptionState.attr('data-disk-encryption-state', diskEncryption.status);
    },

    /**
     * Change Reverse Shell status message.
     *
     * @param {Object} reverseShell
     */
    onReverseShellStatusLoaded: function(reverseShell) {
        this.$reverseShellState.attr('data-reverse-shell-state', reverseShell.status);
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#security-settings'),
            validationContainer: '.input-group'
        });
    },

    onSaveChanges: function() {
        this.saveHandler();
    },

    saveHandler: function() {
        var data;

        if (this.form.validate()) {
            data = this.form.serialize();

            if (this.form.get('tls10Enable').hasChanges.hasChanges) {
                this.hideDiskEncryptionBtn();
            }

            if (this.form.get('tls10Enable').hasChanges.hasChanges
                || this.form.get('httpsEnable').hasChanges.hasChanges) {
                this.emit('modal.open', {
                    id: 'ui-blocker',
                    forceText: true
                });
            }

            var commands = [
                {
                    command: 'setFrontpanelLogin',
                    data: {
                        frontpanelLogin: data.fpLogin
                    }
                },
                {
                    command: 'setUsbStickSupport',
                    data: {
                        usbSupport: data.usbSupport
                    }
                },
                {
                    command: 'setSshEnable',
                    data: {
                        sshEnable: data.sshEnable
                    }
                },
                {
                    command: 'setTls10Support',
                    data: {
                        enabled: data.tls10Enable
                    }
                },
                {
                    command: 'setFirmwareSignatureEnable',
                    data: {
                        fwSignatureEnable: data.fwSignatureEnable
                    }
                },
                {
                    command: 'setFirewall',
                    data: {
                        interface: 'lan1',
                        service: 'http_https',
                        accept: data.lan1AcceptHttpHttps
                    }
                },
                {
                    command: 'setFirewall',
                    data: {
                        interface: 'wlan',
                        service: 'http_https',
                        accept: data.wlanAcceptHttpHttps
                    }
                },
                {
                    command: 'setHttpsEnable',
                    data: {
                        httpsEnable: data.httpsEnable
                    }
                },
                {
                    command: 'setReverseShell',
                    data: {
                        enable: data.reverseShellEnable,
                        port: data.reverseShellPort,
                        webpageRedirectEnable: data.reverseShellWebpageRedirectEnable,
                        debugPortEnable: data.reverseShellDebugPortEnable
                    }
                },
                {
                    command: 'setAdminMessageEnable',
                    data: {
                        enable: data.adminMessageEnable
                    }
                }
            ];

            if (!this.deviceService.isCboxPureOrPro() && !this.deviceService.isCboxPureMini()) {
                commands = commands.concat([
                    {
                        command: 'setFirewall',
                        data: {
                            interface: 'lan2',
                            service: 'http_https',
                            accept: data.lan2AcceptHttpHttps
                        }
                    }
                ]);
            }

            return this.deviceConnection
                .send(commands)
                .then(function() {
                    this.handleSave();
                    this.form.setDefaultValues();
                    this.emit('overlay.remote.focus', true);
                }.bind(this));
        }
    },

    /**
     * Calls on clicked the cancel button on the default form.
     */
    cancelHandler: function() {
        this.onCancel = true;
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
        this.animateHideInfoMessages();
        this.changes = false;
        this.onCancel = false;
    },

    /**
     * Handle save.
     */
    handleSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.animateHideInfoMessages();
        this.changes = false;
    },

    /**
     * Handle form change.
     *
     * @param data
     */
    handleFormChange: function(data) {
        this.actionView.open();
        this.remote.update();
        this.checkActionForm();

        if (data.target.name === 'tls10Enable' || data.target.name === 'httpsEnable') {
            this.animateShowInfoMessages(data.target.name);
        }

        if (!this.onCancel && !this.onDiscare && data.target.name === 'tls10Enable' && this.form.get('tls10Enable').getValue()) {
            app.emit('modal.open', {
                id: 'warning',
                messageKey: i18n.t('settings.verify_tls_1_0'),
                successTextKey: 'settings.accept',
                discareTextKey: 'settings.cancel',
                onDiscare: function() {
                    this.onDiscare = true;
                    this.form.get('tls10Enable').resetValue();
                    this.emit('modal.close');

                    if (!this.form.get('tls10Enable').hasChanges.hasChanges) {
                        this.animateHideInfoMessages(data.target.name);
                    }
                }.bind(this)
            });
        } else {
            this.onDiscare = false;
        }

        if (data.target.name === 'reverseShellEnable') {
            this.checkReverseShell();
        }

        this.changes = true;
    },

    /**
     * Enable/disable save and/or cancel button.
     */
    checkActionForm: function() {
        if (this.form.validate() && !this.blockSaving) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }

        // RELEASE-2269 - Save and Cancel buttons have to be greyed out/hidden when encrypting/decrypting.
        if (this.blockSaving) {
            this.actionView.disableCancelButton();
        } else {
            this.actionView.enableCancelButton();
        }
    },

    /**
     * Load settings via wolfprot.
     */
    loadSecuritySettings: function() {
        return this.deviceConnection
            .send([
                'getFrontpanelLogin',
                'getUsbStickSupport',
                'getSshEnable',
                'getTls10Support',
                'getFirmwareSignautreEnable',
                'getDiskEncryptionStatus',
                'getHttpsEnable',
                {
                    command: 'getFirewall',
                    data: {
                        interface: 'lan1',
                        service: 'http_https'
                    }
                },
                {
                    command: 'getFirewall',
                    data: {
                        interface: 'lan2',
                        service: 'http_https'
                    }
                },
                {
                    command: 'getFirewall',
                    data: {
                        interface: 'wlan',
                        service: 'http_https'
                    }
                },
                'getReverseShell',
                'getAdminMessageEnable'
            ])
            .then(function(frontpanel, usbStick, ssh, tls, fwSignature, diskEncryptionStatus, https,
                firewallLan1, firewallLan2, firewallWlan, reverseShell, adminMessageEnable) {
                this.formData = {
                    fpLogin: frontpanel.enabled,
                    usbSupport: usbStick.enabled,
                    sshEnable: ssh.enabled,
                    tls10Enable: tls.enabled,
                    fwSignatureEnable: fwSignature.enabled,
                    lan1AcceptHttpHttps: firewallLan1.accept,
                    wlanAcceptHttpHttps: firewallWlan.accept,
                    httpsEnable: https.httpsEnabled,
                    reverseShellEnable: reverseShell.enable,
                    reverseShellPort: reverseShell.port,
                    reverseShellWebpageRedirectEnable: reverseShell.webpageRedirectEnable,
                    reverseShellDebugPortEnable: reverseShell.debugPortEnable,
                    adminMessageEnable: adminMessageEnable.enable
                };

                if (!this.deviceService.isCboxPureOrPro() && !this.deviceService.isCboxPureMini()) {
                    this.formData.lan2AcceptHttpHttps = firewallLan2.accept;
                }

                this.isEncrypted = states.encryptionStates.encrypted === diskEncryptionStatus.status;
            }.bind(this));
    },

    updateForm: function() {
        this.form.setValues(this.formData);
        this.form.setDefaultValues();
    },

    /**
     * Animate hide all or specific info message.
     *
     * @param name Info message name
     */
    animateHideInfoMessages: function(name) {
        var $el = this.$el.find('.settings-list-info');

        if (name) {
            $el = this.$el.find('.settings-list-info[data-name="' + name + '"]');
        }

        $el
            .stop()
            .slideUp(200);
    },

    /**
     * Animate show info messages.
     *
     * @param name data-name to find element.
     */
    animateShowInfoMessages: function(name) {
        var $el = this.$el.find('.settings-list-info[data-name="' + name + '"]');
        if ($el.css('display') === 'none') {
            $el
                .stop()
                .slideDown(200);
        }
    },

    destroy: function() {
        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    hasChanges: function() {
        var changes = {
            hasChanges: this.changes,
            invalid: false
        };

        if (this.actionView) {
            changes.invalid = $(this.actionView.$submitBtn).prop('disabled');
        }

        return changes;
    }
});
