const EventEmitter = require('eventemitter2');

/**
 * Create timer instance.
 * @param alreadyElapsedMs initial elapsed time in milliseconds
 */
const create = function({ alreadyElapsedMs: initialElapsedMs }) {
    const emitter = new EventEmitter();

    // Use performance.now() instead of Date as it is a monotonic clock source
    let startTime = performance.now() - initialElapsedMs;

    const intervalHandle = setInterval(function() {
        emitter.emit('tick');
    }, 500);

    /**
     * Close timer. This is required after usage to clear the JS interval.
     */
    const close = function() {
        clearInterval(intervalHandle);
    };

    /**
     * Get elapsed time in milliseconds.
     */
    const elapsedMs = function() {
        return performance.now() - startTime;
    };

    /**
     * Synchronize timer with server time.
     * @param alreadyElapsedMs server time in milliseconds
     */
    const syncTime = function({ alreadyElapsedMs }) {
        const internalElapsed = elapsedMs();
        const skew = alreadyElapsedMs - internalElapsed;

        /* When internal timer differs from server time more than 3 seconds
           we force synchronization with server again */
        if (Math.abs(skew) > 3000) {
            startTime = performance.now() - alreadyElapsedMs;
            emitter.emit('tick');
        }
    };

    return {
        on: emitter.on.bind(emitter),
        elapsedMs,
        syncTime,
        close
    };
};

module.exports = {
    create
};
