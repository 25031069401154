'use strict';

var BaseView = require('./base-view');
var tpl = require('./simple-view.hbs');

function SimpleView(app, configs) {
    BaseView.call(this, app, configs);

    this.template = tpl;
    this.componentContainer = '#simple-view-content';
}

SimpleView.prototype = new BaseView();

module.exports = SimpleView;
