'use strict';

var app = require('../../../../app');

var commands = {
    next: 'next',
    prev: 'prev',
    first: 'first',
    last: 'last'
};

var controlItems = [
    {
        id: 'first',
        state: 'first',
        states: {
            'first': {
                icon: 'icon-v2-first',
                titleKey: ''
            }
        },
        groupEnd: true
    },
    {
        id: 'prev',
        state: 'prev',
        states: {
            'prev': {
                icon: 'icon-v2-arrow-left',
                titleKey: ''
            }
        }
    },
    {
        id: 'next',
        state: 'next',
        states: {
            'next': {
                icon: 'icon-v2-arrow-right',
                titleKey: ''
            }
        },
        groupEnd: true
    },
    {
        id: 'last',
        state: 'last',
        states: {
            'last': {
                icon: 'icon-v2-last',
                titleKey: ''
            }
        }
    },
    {
        id: 'title-right',
        type: 'title-right',
        state: 'title-right'
    }
];

/**
 * Image frame
 */
app.component('FrameBoxImageViewer', {
    extend: 'FrameBoxBaseType',
    className: 'framebox-view framebox-image-view',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.frameBoxService = this.getService('FrameBoxService');
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.startControls();
        this.bindEvents();
        this.emit('framebox.title.update.' + this.index, this.options.frameBoxOptions);
        this.setCount(this.options.frameBoxOptions);
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        this.on('framebox.change.' + this.options.index, this.onFrameBoxChangeHandler.bind(this));

        // Standard-mode
        this.on('framebox.standard.panleft-single', this.controlHandlers['next'].bind(this));
        this.on('framebox.standard.panright-single', this.controlHandlers['prev'].bind(this));

        // Remote-control
        this.on('framebox.standard.left.keydown', this.controlHandlers['prev'].bind(this));
        this.on('framebox.standard.right.keydown', this.controlHandlers['next'].bind(this));
        this.on('framebox.standard.up.keydown', this.controlHandlers['prev'].bind(this));
        this.on('framebox.standard.down.keydown', this.controlHandlers['next'].bind(this));
    },
    /**
     * @method startControls
     */
    startControls: function() {
        this.createComponent({
            type: 'FrameBoxControls',
            container: this.$el,
            index: this.options.index,
            appId: this.options.appId,
            items: controlItems,
            onClick: this.onControlClickHandler.bind(this),
            isFullscreen: this.options.isFullscreen
        });
    },

    /**
     * @method onFrameBoxChangeHandler
     * @param {Object} frameBox
     */
    onFrameBoxChangeHandler: function(frameBox) {
        this.setCount(frameBox.options);
    },

    /**
     * @method onClickHandler
     * @param {string} id
     */
    onControlClickHandler: function(id) {
        var handler = this.controlHandlers[id];

        if (handler) {
            handler.call(this, {
                index: this.index
            });
        }
    },

    controlHandlers: {
        'first': function(options) {
            this.sendCmd(commands.first, options.index);
        },

        'prev': function(options) {
            this.sendCmd(commands.prev, options.index);
        },

        'next': function(options) {
            this.sendCmd(commands.next, options.index);
        },

        'last': function(options) {
            this.sendCmd(commands.last, options.index);
        }
    },

    /**
     * @method cmd
     * @param {string} cmd
     * @param {int} index
     */
    sendCmd: function(cmd, index) {
        if (this.index === index) {
            this.send('setImageCmd', {
                cmd: cmd,
                appId: this.options.appId
            });
        }
    },

    /**
     * @method setCount
     */
    setCount: function(metaData) {
        var text = metaData.imageNumber + ' / ' + metaData.totalImages;

        this.emit('framebox.controls.title-right', {
            index: this.options.index,
            text: '<div class="framebox-viewer-header-count">' + text + '</div>'
        });
    }
});
