module.exports={
    "modifier":
    {
        "&#64;": 100, //@
        "&#163;": 100, //£
        "&#36;": 100, //$
        "&#123;": 100, //{
        "&#91;": 100, //[
        "&#93;": 100, //]
        "&#125;": 100, //}
        "&#180;": 100, //´

        "&#8364;": 100, //€
        "&#126;": 100 //~
    },

    // on mobile devices a modifier has to be sent to get some symbols
    // e.g. longpress on i is a > so we need a modifier before sending >
    "modifier_mobile": {
        "&#33;": 42, //!
        "&#34;": 42, //"
        "&#35;": 42, //#
        "&#164;": 42, //¤
        "&#37;": 42, //%
        "&#38;": 42, //&
        "&#47;": 42, ///
        "&#40;": 42, //(
        "&#41;": 42, //)
        "&#61;": 42, //=
        "&#63;": 42, //?
        "&#96;": 42, //`

        "&#94;": 42, //^
        "&#42;": 42, //*
        "&#62;": 42, //>
        "&#59;": 42, //;
        "&#58;": 42, //:
        "&#95;": 42 //_
    },

    "ie_specialkeys_mobile": {
        "&#53;": 18 //€
    },

    "ie_specialkeys": {},

    "&#167;": 41, //|
    "&#124;": 41, //§
    "&#33;": 2, //!
    "&#34;": 3, //"
    "&#64;": 3, //@
    "atmark": 3, //@
    "&#35;": 4, //#
    "&#163;": 4, //£
    "&#164;": 5, //¤
    "&#36;": 5, //$
    "&#37;": 6, //%
    "&#38;": 7, //&
    "&#47;": 8, ///
    "&#123;": 8, //{
    "&#40;": 9, //(
    "&#91;": 9, //[
    "&#41;": 10, //)
    "&#93;": 10, //]
    "&#61;": 11, //=
    "&#125;": 11, //}
    "&#43;": 12, //+
    "&#63;": 12, //?
    "&#92;": 13, //\
    "&#180;": 13, //´
    "&#96;": 13, //`

    "&#8364;": 18, //€
    "&#121;": 21, //y
    "&#229;": 26, //å
    "&#94;": 27, //^
    "&#126;": 27, //~

    "&#248;": 39, //ø
    "&#230;": 40, //æ
    "&#39;": 43, //'
    "&#42;": 43, //*

    "&#60;": 86, //<
    "&#62;": 86, //>
    "&#122;": 44, //z
    "&#44;": 51, //,
    "&#59;": 51, //;
    "&#46;": 52, //.
    "&#58;": 52, //:
    "&#45;": 53, //-
    "&#95;": 53 //_

}
