const template = require('./range-input-annotation.html');

module.exports = {
    template,

    components: {
        'range-input': require('../../../../../../components-vue/range-input/range-input')
    },

    props: {
        id: {
            type: String,
            required: true
        },
        value: {
            type: Number,
            required: true
        },
        min: {
            type: Number,
            required: false,
            default: 0
        },
        max: {
            type: Number,
            required: false,
            default: 100
        },
        step: {
            type: Number,
            required: false,
            default: 5
        },
        icon: {
            type: String,
            required: true
        },
        minIcon: {
            type: String,
            required: true
        },
        maxIcon: {
            type: String,
            required: true
        },
        close: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    data: function() {
        return {
            open: false,
            triggerCoords: { x: 0, y: 0, height: 0 }
        };
    },

    watch: {
        close: function(val) {
            if (val) {
                this.open = false;
            }
        },
        open: function(val) {
            if (val) {
                const { x, y, height } = this.$refs.trigger.getBoundingClientRect();
                this.triggerCoords = { x, y, height };
            }
        }
    },

    methods: {
        toggleSubMenu: function() {
            this.open = !this.open;
            this.$emit('sub-menu-changed', {
                id: this.id,
                open: this.open
            });
        }
    }
};
