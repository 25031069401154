'use strict';

const app = require('../../../app');
const $ = require('jquery');
const _ = require('lodash');
const d3 = require('d3');
const matrixGridTpl = require('./matrix-main-grid.hbs');
const LayoutManager = require('../../../../layout/layout');
const PreviewEngine = require('../../../../preview-engine');
const store = require('../../../store/store');
const helper = require('../../../helper');
const platform = require('../../../../platform/platform');
const i18n = require('i18next');
const { resolutions } = require('../../../states');

const iconSize = 300;

app.component('MatrixMainGrid', {
    template: matrixGridTpl,

    getAccessKey: function() {
        return {
            'roleName': 'MatrixMain',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.configs = this.getService('MatrixConfigs');
        this.matrixService = this.getService('MatrixService');
        this.matrixMainService = this.getService('MatrixMainService');
        this.matrixPreviewService = this.getService('MatrixPreviewService');
        this.deviceService = app.getService('DeviceService');
        this.isDualProjection = this.deviceService.isCboxProDualProjection();
        this.previewEngines = {};
        this.previews = [];
    },

    serialize: function() {
        return {
            width: this.configs.get('dimensions.width'),
            height: this.configs.get('dimensions.height')
        };
    },

    storeSelectors: function() {
        this.$svg = this.$el.find('#svg-grid');
        this.$matrixGroup = this.$el.find('#matrix-group');
        this.$matrixLayer = this.$el.find('#matrix-layer');
        this.$filesMenus = this.$el.find('#matrix-files-menus');
        this.$matrixBackground = this.$el.find('.matrix-background');
        this.$matrixBackgroundGroup = this.$el.find('#background-layer');

        // Hide svg until new height is calculated.
        this.$svg.css('visibility', 'hidden');
    },

    /**
     * Create the grid with points/circles, call methods to init the Matrix grid and bind Events.
     */
    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();
        this.bindDOMEvents();
        this.initMatrix();
    },

    bindEvents: function() {
        // Master events
        this.on('master-interaction.show', function(evtData) {
            this.toggleMasterInteraction(evtData);
        }.bind(this));

        // Stream events
        this.on('stream-interaction.show', this.toggleStreamInteraction.bind(this));

        // Preview events
        this.on('matrix.show-all-previews', this.showAll.bind(this));
        this.on('matrix.preview.show', this.showSinglePreview.bind(this));
        this.on('matrix.single-preview.close', this.hidePreviews.bind(this));
        this.on('matrix.previews.close', this.hidePreviews.bind(this));
        this.on('matrix.groupwork-mode.changed', this.updateMasterPreviewToggle.bind(this));

        // Files menu events
        this.on('matrix-main.toggle.files.menu', this.toggleFilesMenu.bind(this));
        this.on('matrix-main.remove.files.menu', this.removeFilesMenu.bind(this));

        // General events
        this.on('matrix-main.hide.menus', this.hideMenus.bind(this));
        this.on('overlay.opened-end', this.recalcHeight.bind(this));
        this.on('matrix.reload-config', this.initMatrixConfiguration.bind(this));
        this.on('matrix-config-selection.changed', this.onTemplateChange.bind(this));

        LayoutManager.on('window.resize', this.windowResizeHandler.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('pointerdown', '.svg-grid', this.hideMenus.bind(this));

        /*
         * RELEASE-1993 - Red pointer appears when sliding a finger on the screen.
         * D3-library calls event.stoppropagation() so we are not able to detect touchstart and touchend events
         * in the custom-cursor component. Now we have changed the event to the normalized-event so we can trigger this
         * event manually.
         */
        if (platform.checks.isTouchDevice) {
            this.$svg.on('contextmenu', e => e.preventDefault());
        }
        this.$svg.on('touchstart', this.onTouchstart.bind(this));
        this.$svg.on('touchend', this.onTouchend.bind(this));
    },

    /**
     * Init matrix after template change.
     */
    initMatrix: function() {
        this.svg = d3.select(this.$svg[0]);

        this.initMatrixConfiguration();
        this.initPanZoomEvent();

        this.emit('fullscreen.framebox.standard.controls.hide');
    },

    /**
     * Handle window resizing.
     */
    windowResizeHandler: function() {
        this.hideMenus();
        this.recalcHeight();
    },

    /**
     * Calculate the aspect width of the svg element.
     *
     */
    recalcHeight: function() {
        const newHeight = (parseInt($(document).find('.overlay-container').width())
            * parseInt(this.configs.get('dimensions.height')))
            / parseInt(this.configs.get('dimensions.width'));

        this.$svg.height(newHeight);

        this.$svg.css('visibility', 'visible');
    },

    /**
     * Triggers the normalized-touch event after a touchstart has been triggered.
     */
    onTouchstart: function() {
        this.emit('custom-cursor.disable');
    },

    /**
     * Triggers the normalized-touch event after a touchend has been triggered.
     */
    onTouchend: function() {
        this.emit('custom-cursor.enable');
    },

    /**
     * Hide all files menus and master interaction menu.
     */
    hideMenus: function() {
        this.hideMasterInteraction();
        this.removeFilesMenu();
    },

    /**
     * Hide master interaction and update master.
     */
    hideMasterInteraction: function() {
        this.removeInteractionMenu();

        this.matrixMainService.updateMaster();
    },

    /**
     * Toggle master interaction and call show/hide master interaction method.
     */
    toggleMasterInteraction: function(evtData) {
        this.removeFilesMenu();

        if (this.$el.find('.matrix-interaction-container').length <= 0) {
            this.createMasterInteraction(evtData.masterId);
        } else {
            this.hideMasterInteraction();
        }
    },

    /**
     * Hide stream interaction and update master.
     */
    hideStreamInteraction: function() {
        this.removeInteractionMenu();

        this.matrixMainService.updateMaster();
    },

    /**
     * Toggle stream interaction and call show/hide stream interaction method.
     */
    toggleStreamInteraction: function() {
        this.removeFilesMenu();

        if (this.$el.find('.matrix-interaction-container').length <= 0) {
            this.createStreamInteraction();
        } else {
            this.hideStreamInteraction();
        }
    },

    /**
     * Show hide files menu from station.
     *
     * @param station Current station
     */
    toggleFilesMenu: function(station) {
        this.hideMasterInteraction();

        if (this.$filesMenus.find('#' + station.id).length <= 0) {
            this.createFilesMenu(station);
        } else {
            this.removeFilesMenu(station);
        }
    },

    /**
     * Handle state of all masters depending on groupwork status
     *
     * @param groupworkActive
     */
    updateMasterPreviewToggle: function(groupworkActive) {
        this.matrixMainService.masterLoop(function(master) {
            master.classed('inactive', groupworkActive);
            master.style('fill-opacity', groupworkActive ? 0.2 : 1);
            master.select('.matrix-circle[data-action="connect"]').classed('inactive', groupworkActive);
        });

        if (this.isDualProjection) {
            if (groupworkActive) {
                this.removeAllMasterPreviewComponents();
            } else {
                this.showAllMasterPreviewComponents();
            }
        }

        this.svg.select('.btn-stream .matrix-circle').classed('inactive', groupworkActive);
    },

    /**
     * Create and show files menu from station.
     *
     * @param station Current station
     */
    createFilesMenu: function(station) {
        this.removeFilesMenu(station);

        const st = $(station);
        const pos = this.matrixService.calculateRotatedPosition(
            parseInt(st.attr('cx')),
            parseInt(st.attr('cy')),
            parseInt(st.find('.btn-docs').attr('cx')),
            parseInt(st.find('.btn-docs').attr('cy')),
            parseFloat(st.attr('angle')));

        this.app.createComponent(station.id, {
            type: 'MatrixFilesMenu',
            container: '#matrix-files-menus',
            id: station.id,
            serial: station.id.replace('sn', ''),
            cx: pos[0],
            cy: pos[1]
        });
    },

    /**
     * Remove files menu from station.
     *
     * @param station Current station
     */
    removeFilesMenu: function(station) {
        if (station) {
            this.app.removeComponent(station.id);
        } else {
            _.each(this.$filesMenus.children(), function(fileMenu) {
                this.app.removeComponent($(fileMenu).find('.matrix-files-menu-container').get(0).id);
            }.bind(this));
        }
    },

    /**
     * Show single live preview.
     *
     * @param station {object} station to show preview
     */
    showSinglePreview: function(station) {
        if (this.matrixService.shareWithAllState.currentState !== 'finished'
            || ($(station).hasClass('inactive') && !this.matrixMainService.groupworkActive)
            || station.getAttribute('id').startsWith('stream')) {
            return;
        }

        const id = station.getAttribute('id').replace('sn', '');

        if (id.startsWith('master') || !this.matrixService.isStationPresent(id)) {
            return;
        }

        this.hideMenus();

        const newWidth = this.$svg.width() - (this.$svg.width() * 0.20);
        const size = {
            width: Math.ceil(newWidth),
            height: Math.ceil(newWidth / (16 / 9))
        };

        const previewID = this.createComponent({
            type: 'MatrixPreview',
            container: '.station-previews',
            id: id,
            station: d3.select(station),
            cx: parseInt(this.configs.get('dimensions.width')) / 2,
            cy: parseInt(this.configs.get('dimensions.height')) / 2,
            size: size,
            serial: id,
            singlePreview: true
        });

        this.previews.push(previewID);

        this.$el.find('.station-previews').fadeIn('slow');

        this.emit('overlay-backdrop.show');
    },

    /**
     * Show all station previews.
     *
     * @param data {object} show or hide preview
     */
    showAll: function(data) {
        this.hideMenus();

        if (data.showAll) {
            const size = this.matrixPreviewService.getPreviewSize();
            const elements = this.svg.selectAll('.station');

            _.each(elements._groups[0], function(el) {
                const serial = el.getAttribute('id').replace('sn', '');

                if ((this.matrixService.isStationPresent(serial) && !el.getAttribute('id').startsWith('stream') && !el.getAttribute('id').startsWith('master'))
                    || (!this.isDualProjection && el.getAttribute('id').startsWith('master'))) {
                    const previewId = this.createComponent({
                        type: 'MatrixPreview',
                        container: '.station-previews',
                        id: el.getAttribute('id'),
                        station: d3.select(el),
                        cx: el.getAttribute('cx'),
                        cy: el.getAttribute('cy'),
                        size: size,
                        serial: serial,
                        singlePreview: false
                    });

                    this.previews.push(previewId);
                }
            }.bind(this));

            this.$el.find('.station-previews').fadeIn('slow');
            this.matrixMainService.setShowAllPreviews(true);

            this.off('matrix.preview.show');
            this.off('matrix.single-preview.close');

            this.emit('overlay-backdrop.show');
        } else {
            this.hidePreviews();

            this.on('matrix.preview.show', this.showSinglePreview.bind(this));
            this.on('matrix.single-preview.close', this.hidePreviews.bind(this));

            this.emit('overlay-backdrop.hide');
        }
    },

    /**
     * Hide all station previews.
     */
    hidePreviews: function(station) {
        if (station && $(station).hasClass('inactive') && !this.matrixMainService.getGroupworkActive()) {
            return;
        }

        this.$el.find('.station-previews').fadeOut('slow');
        this.matrixMainService.setShowAllPreviews(false);

        setTimeout(function() {
            this.removeAllPreviewComponents();
        }.bind(this), 500);

        this.emit('overlay-backdrop.hide');
    },

    /**
     * Remove all preview components.
     */
    removeAllPreviewComponents: function() {
        _.each(this.previews, function(preview) {
            this.app.removeComponent(preview);
        }.bind(this));
        this.previews = [];
    },

    removeAllMasterPreviewComponents: function() {
        this.matrixMainService.masterLoop(function(master) {
            if (this.previewEngines[master.attr('id')]) {
                this.previewEngines[master.attr('id')].stop();
            }

            master.select('.master-preview-image').attr('href', '');
        }.bind(this));
    },

    /**
     * Calculate size to query preview picture based on the svg dimensions of the master stations.
     * However, method is currently unused since previews are requested with a fixed 720p resolution.
     */
    calcMasterPreviewSize: function(master) {
        const masterStation = master.select('.svg-station');

        const width = parseInt(masterStation.attr('width'));
        const height = parseInt(masterStation.attr('height'));

        let size = this.matrixService.convertCoordinates(
            {
                width,
                height
            },
            this.$svg.width(), this.$svg.height(),
            this.configs.get('dimensions.width'), this.configs.get('dimensions.height'));

        // Min size 1/5 of full HD 1920x1080
        if (size.width <= 384 || size.height <= 216) {
            size = { width: 384, height: 216 };
        }

        return size;
    },

    showAllMasterPreviewComponents: function() {
        this.removeAllMasterPreviewComponents();

        this.matrixMainService.masterLoop(function(master) {
            const masterId = master.attr('id');
            const size = resolutions['720p'];

            this.previewEngines[masterId] = new PreviewEngine({ fps: 5 }, { output: masterId.replace('master-', ''), ...size }, function(image) {
                this.setImageFromId('master-preview-image-' + masterId, image.src);
            }.bind(this));

            this.previewEngines[masterId].start();
        }.bind(this));
    },

    setImageFromId: function(id, image) {
        this.$svg.find('#' + id).attr('href', image);
    },

    /**
     * Create master interaction menu.
     *
     */
    createMasterInteraction: function(masterId) {
        this.removeInteractionMenu();

        const master = this.svg.select('#' + masterId).select('.btn-center');

        this.app.createComponent('master', {
            type: 'MainInteraction',
            container: '#matrix-interaction',
            id: masterId,
            cx: parseInt(master.attr('cx')),
            cy: parseInt(master.attr('cy'))
        });

        this.matrixMainService.showGroupNames();
    },

    /**
     * Create stream interaction menu.
     *
     */
    createStreamInteraction: function() {
        this.removeInteractionMenu();

        const stream =  this.svg.select('.btn-stream');

        this.app.createComponent('stream', {
            type: 'MainInteraction',
            container: '#matrix-interaction',
            id: stream.attr('data-id'),
            cx: parseInt(stream.attr('cx')),
            cy: parseInt(stream.attr('cy'))
        });

        this.matrixMainService.showGroupNames();
    },

    /**
     * Remove interaction menu.
     *
     */
    removeInteractionMenu: function() {
        this.app.removeComponent('master');
        this.app.removeComponent('stream');

        this.matrixMainService.hideGroupNames();
    },

    /**
     * Init stream buttons/indicators. Add class for color and remove action.
     */
    initStreamButtons: function() {
        this.matrixMainService.hideMenuButton(this.svg, 'btn-remove');

        const btn = this.svg.selectAll('.btn-remove');
        btn.select('.matrix-circle[data-action="remove"]')
            .attr('data-action', '');
    },

    /**
     * Init document buttons. Change icon (former resize btn icon) and action.
     */
    initDocumentButtons: function() {
        this.matrixMainService.hideMenuButton(this.svg, 'btn-docs');

        const btn = this.svg.selectAll('.btn-docs');
        btn.select('.icon').classed('icon-transform', false);
        btn.select('.icon').classed('icon-document-stack', true);
        btn.select('.matrix-circle.menu-btn[data-action="resizeRotate"]')
            .attr('data-action', 'docs');
        btn.select('.docs-counter')
            .classed('hidden', false);
    },

    initControlButtons: function() {
        this.matrixMainService.hideMenuButton(this.svg, 'btn-control');

        const btn = this.svg.selectAll('.btn-control');
        btn.select('.icon').classed('icon-color', false);
        btn.select('.icon').classed('icon-lock-open', true);
        btn.select('.matrix-circle.menu-btn[data-action="color"]')
            .attr('data-action', 'control');
        btn.select('.matrix-station-color-picker').remove();
    },

    initMasterPreviews: function() {
        this.matrixMainService.masterLoop(function(master) {
            const masterStation = master.select('.svg-station');

            const dim = {};
            dim.width = parseInt(masterStation.attr('width'));
            dim.height = parseInt(masterStation.attr('height'));
            dim.cx = parseInt(master.attr('cx'));
            dim.cy = parseInt(master.attr('cy'));
            dim.x = dim.cx - dim.width / 2;
            dim.y = dim.cy - dim.height / 2;

            master.insert('image', '.btn-center')
                .attr('id', 'master-preview-image-' + master.attr('id'))
                .attr('class', 'master-preview-image')
                .attr('width', dim.width)
                .attr('height', dim.height)
                .attr('x', dim.x)
                .attr('y', dim.y)
                .attr('cx', dim.cx)
                .attr('cy', dim.cy)
                .attr('preserveAspectRatio', 'none');

            this.initDropzones(master, dim);
            if (master.attr('id').includes('hdmi2')) {
                this.initMirroringOverlay(master, dim);
            }
        }.bind(this));

        if (this.matrixMainService.getGroupworkActive() === false) {
            this.showAllMasterPreviewComponents();
        }
    },

    initDropzones: function(master, dim) {
        const output = master.attr('id').replace('master-', '');

        const group = master.insert('g', '.btn-center')
            .attr('class', 'master-dropzone-group')
            .attr('width', dim.width)
            .attr('height', dim.height)
            .attr('x', dim.x)
            .attr('y', dim.y)
            .attr('cx', dim.cx)
            .attr('cy', dim.cy);

        group.append('rect')
            .attr('id', 'master-dropzone-' + output)
            .attr('class', 'master-dropzone')
            .attr('width', dim.width)
            .attr('height', dim.height)
            .attr('x', dim.x)
            .attr('y', dim.y)
            .attr('cx', dim.cx)
            .attr('cy', dim.cy)
            .attr('data-drop-action', 'add');

        this.on('matrix.dropzone.changed', this.handleDropzoneChanged.bind(this, group));
        this.on('matrix.pull.start', this.enableDropzones.bind(this, group));
        this.on('matrix.pull.end', this.disableDropzones.bind(this, group));

        this.createDropzones(group, dim, store.getters['controlScreen/getWindowsByOutputPort'](output));
        this.unwatchWindow = store.watch((state, getters) => getters['controlScreen/getWindowsByOutputPort'](output), this.createDropzones.bind(this, group, dim));
    },

    createDropzones: function(dropzoneGroup, dim, windows) {
        // Remove all children but the master-dropzone
        dropzoneGroup.selectAll('*').filter(':not(.master-dropzone)').remove();

        windows.forEach(window => {
            const winCoords = helper.convertCoordinates(window.coordinates, window.reference, dim);
            const pullBlocked = ['webconference', 'zoom', 'teams'].includes(window.contentType)
            || window?.options?.cameraBackActive;

            if (windows.length === 1) {
                // Replace window for single window open
                // Width replace center = 45% --> 55%/2 = 27.5% x offset
                winCoords.x += winCoords.width * 0.275;
                winCoords.width *= 0.45;
            }

            winCoords.x += dim.x;
            winCoords.y += dim.y;

            dropzoneGroup.append('rect')
                .attr('id', 'dw-' + window.appId)
                .attr('class', 'dropzone-window')
                .attr('width', winCoords.width)
                .attr('height',  winCoords.height)
                .attr('x', winCoords.x)
                .attr('y', winCoords.y)
                .attr('data-drop-action', 'replace');

            const cx = winCoords.x + (winCoords.width / 2);
            const cy = winCoords.y + (winCoords.height / 2);

            if (pullBlocked) {
                dropzoneGroup.append('foreignObject')
                    .attr('class', 'dropzone-icon')
                    .attr('width', iconSize)
                    .attr('height',  iconSize)
                    .attr('x', cx - (iconSize / 2))
                    .attr('y', cy - (iconSize / 2))
                    .attr('data-icon-type', 'block-' + window.appId)
                    .style('font-size', iconSize + 'px')
                    .html('<div class="icon icon-blocked"></div>');
            } else {
                dropzoneGroup.append('foreignObject')
                    .attr('class', 'dropzone-icon')
                    .attr('width', iconSize)
                    .attr('height',  iconSize)
                    .attr('x', cx - (iconSize / 2))
                    .attr('y', cy - (iconSize / 2))
                    .attr('data-icon-type', 'replace-' + window.appId)
                    .style('font-size', iconSize + 'px')
                    .html('<div class="icon icon-v3-replace"></div>');
            }
        });

        if (windows.length < 2) {
            dropzoneGroup.append('foreignObject')
                .attr('class', 'dropzone-icon')
                .attr('width', iconSize)
                .attr('height',  iconSize)
                .attr('x', dim.cx - (iconSize / 2))
                .attr('y', dim.cy - (iconSize / 2))
                .attr('data-icon-type', 'add')
                .style('font-size', iconSize + 'px')
                .html('<div class="icon icon-mmt-add"></div>');
        }
    },

    enableDropzones: function(dropzoneGroup) {
        dropzoneGroup.classed('drag-active', true);
    },

    disableDropzones: function(dropzoneGroup) {
        dropzoneGroup.classed('drag-active', false);
    },

    handleDropzoneChanged: function(group, activeDropzone) {
        group.select('.master-dropzone').classed('active', false);
        group.selectAll('.dropzone-window').classed('active', false);
        group.selectAll('.dropzone-icon').classed('visible', false);

        if (activeDropzone === null
            || (group.attr('id') !== activeDropzone
                && group.select('#' + activeDropzone).size() === 0)) {
            return;
        }

        const isReplace = !activeDropzone.startsWith('master-dropzone');
        const windowsLength = group.selectAll('.dropzone-window').size();
        let replaceAppId;

        if (group.select('[data-icon-type|="block"]').size() === 1) { // Replace and add not allowed
            replaceAppId = null;
            group.select('.master-dropzone').classed('active', true);
            group.select('[data-icon-type|="block"]').classed('visible', true);
        } else if (isReplace) { // Dropzone is replace
            replaceAppId = activeDropzone.replace('dw-', '');
            group.select(`[data-icon-type*="${replaceAppId}"]`).classed('visible', true);

            if (windowsLength === 1) {
                group.select('.master-dropzone').classed('active', true);
            } else {
                group.select('#' + activeDropzone).classed('active', true);
            }
        } else { // Dropzone is add
            replaceAppId = 0;
            group.select('.master-dropzone').classed('active', true);
            group.select('[data-icon-type="add"]').classed('visible', true);
        }

        this.matrixMainService.setReplaceAppId(replaceAppId);
    },

    initMirroringOverlay: function(master, dim) {
        const group = master.insert('g', '.btn-center')
            .attr('id', 'mirroring-overlay-group')
            .attr('width', dim.width)
            .attr('height', dim.height)
            .attr('x', dim.x)
            .attr('y', dim.y)
            .attr('cx', dim.cx)
            .attr('cy', dim.cy);

        group.append('rect')
            .attr('class', 'mirroring-overlay-rect')
            .attr('width', dim.width)
            .attr('height', dim.height)
            .attr('x', dim.x)
            .attr('y', dim.y)
            .attr('cx', dim.cx)
            .attr('cy', dim.cy);

        group.append('foreignObject')
            .attr('class', 'mirroring-overlay-content')
            .attr('width', dim.width)
            .attr('height', dim.height)
            .attr('x', dim.x)
            .attr('y', dim.y)
            .attr('cx', dim.cx)
            .attr('cy', dim.cy)
            .html('<div class="content-flex"><div class="icon icon-v3-mirroring"></div><div class="text">'
                + i18n.t('control_screen.output_mirroring_from') + '</div></div>');

        this.setMirroringActive(store.getters['controlScreen/isOutputMirroringActive']);
        this.unwatchMirroring = store.watch((state, getters) => getters['controlScreen/isOutputMirroringActive'], this.setMirroringActive.bind(this));
    },

    /**
     * Add/Remove mirroring-active class
     * @param {Boolean} isOutputMirroringActive - if output mirroring is currently active
     */
    setMirroringActive: function(isOutputMirroringActive) {
        this.svg.select('#master-hdmi2').classed('mirroring-active', isOutputMirroringActive);
    },

    clearWindowWatch: function() {
        if (this.unwatchWindow) {
            this.unwatchWindow();
        }
    },

    clearMirroringWatch: function() {
        if (this.unwatchMirroring) {
            this.unwatchMirroring();
        }
    },

    /**
     * Transform zoom view.
     */
    zoomed: function() {
        this.$matrixGroup.attr('transform', d3.event.transform);
        this.$matrixBackgroundGroup.attr('transform', d3.event.transform);
    },

    /**
     * Activate/Deactivate pan zoom event.
     *
     * @param off true - disable zooming, else - enable zooming
     */
    initPanZoomEvent: function(off) {
        if (off) {
            this.zoom = d3.zoom()
                .on('zoom', null);
        } else {
            this.zoom = d3.zoom()
                .extent([[100, 100], [this.configs.get('dimensions.width'), this.configs.get('dimensions.height')]])
                .scaleExtent([1, 1])
                .translateExtent([[100, 100], [this.configs.get('dimensions.width'), this.configs.get('dimensions.height')]])
                .on('zoom', this.zoomed.bind(this));
        }

        this.svg.call(this.zoom);
    },

    /**
     * Initialize matrix configuration.
     * Try loading the matrix configuration and set up the default view.
     */
    initMatrixConfiguration: function() {
        $.when(this.configs.loadMatrixConfiguration(this.$matrixLayer.get(0).innerHTML), this.configs.loadMatrixBackground())
            .then(function(config, background) {
                if (config === '') {
                    return;
                }

                this.config = config;
                this.$matrixLayer.html(this.config);
                this.$matrixBackground.attr('src', background);
                this.configs.parseStations();

                this.matrixPreviewService.setSvg(this.$svg);
                this.matrixMainService.setSvg(this.$svg);
                this.matrixMainService.removeConnectionHandles(this.svg);
                this.matrixMainService.hideConnections(this.svg);

                this.initStreamButtons();
                this.initDocumentButtons();
                this.initControlButtons();

                if (this.isDualProjection) {
                    this.initMasterPreviews();
                }

                this.matrixMainService.bindMasterEvents();
                this.matrixMainService.bindStationEvents();

                this.svg.selectAll('.arrow').style('display', 'none');

                app.emit('main-loop.fast.start', {
                    id: 'matrix'
                });
            }.bind(this));
    },

    /**
     * Change Matrix Template.
     */
    onTemplateChange: function() {
        this.initMatrix();
    },

    destroy: function() {
        this.removeAllPreviewComponents();
        this.removeAllMasterPreviewComponents();
        this.clearWindowWatch();
        this.clearMirroringWatch();
        this.hidePreviews();
        this.matrixMainService.setGroupworkActive(null);

        this.emit('overlay-backdrop.hide');
        this.emit('fullscreen.framebox.standard.controls.show');
        this.emit('control-bar.show');
        this.emit('matrix-main.close');
    }
});
