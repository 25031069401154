'use strict';

var app = require('../../../../app');

/**
 * Matrix Groupwork
 */
app.component('FrameBoxMatrixGroupwork', {
    className: 'framebox-view framebox-matrix-view',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.frameBoxService = this.getService('FrameBoxService');
    },

    postPlaceAt: function() {
        this.startControls();
        this.bindEvents();
        this.initMatrixFramebox();

        this.onFrameboxChanged({
            options: this.options.frameBoxOptions
        });
    },

    /**
     * Start controls.
     * RELEASE-3338: Actually, the Matrix Groupwork framebox has no controls.
     * But to handle the focus, it's necessary to create the container.
     */
    startControls: function() {
        this.componentId = this.createComponent({
            type: 'FrameBoxControls',
            container: this.$el,
            index: this.options.index,
            appId: this.options.appId,
            items: [],
            isFullscreen: this.options.isFullscreen,
            isMatrix: true
        });
    },

    initMatrixFramebox: function() {
        this.options.$actionEl.addClass('is-matrix');
    },

    bindEvents: function() {
        this.on('framebox.change.' + this.index, this.onFrameboxChanged.bind(this));
    },

    /**
     * Handle framebox changed.
     * @param box
     */
    onFrameboxChanged: function(box) {
        this.box = box;
    },

    destroy: function() {
        this.$('html, body').scrollTop(0);
    }
});
