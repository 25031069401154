'use strict';

const app = require('../../../app');
const urlTpl = require('./framebox-url.hbs');
const platform = require('./../../../../platform/platform');

app.component('FrameboxUrl', {
    template: urlTpl,
    className: 'framebox-url-container',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.url = this.options.frameBoxOptions.url;

        this.pollFactory = this.getService('PollFactoryService');
        this.keyboard = this.getService('KeyboardService');
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.initForm();
        this.bindEvents();
        this.bindDOMEvents();
    },

    storeSelectors: function() {
        this.$input = this.$el.find('input');
        this.$input.addClass('can-use-touch-keyboard');
    },

    bindEvents: function() {
        this.on('framebox.url.change', this.pollUrl.bind(this));
        this.on('framebox.submenu.close', this.keyboardHandler.bind(this, false));
    },

    bindDOMEvents: function() {
        this.form.$el.on('submit', this.submitUrlHandler.bind(this));
        this.form.$el.on('keyup, change', this.handleFormChange.bind(this));
        this.$input.on('click', this.keyboardHandler.bind(this, true));
        this.$input.on('focus', this.focusHandler.bind(this));
        this.$input.on('focusout', this.focusHandler.bind(this));
        this.$input.on('keypress.framebox-' + this.index, this.handleFormChange.bind(this));
        this.$input.on('keydown.framebox-' + this.index, this.handleFormChange.bind(this));
    },

    focusHandler: function(data) {
        if (data.type === 'focusout') {
            this.emit('framebox-input.unfocused', this.index, false);
        } else if (data.type === 'focus') {
            this.emit('framebox-input.focused', this.index, true);
        }
    },

    /**
     * Handle form change.
     * If entering url, control bar shouldn't hide.
     */
    handleFormChange: function() {
        this.emit('control-bar-timer.start');
    },

    /**
     * Handle URL submit.
     *
     * @param event
     */
    submitUrlHandler: function(event) {
        const url = this.form.get('url').getValue();

        if (url !== '') {
            this.setUrl(url);

            if (platform.checks.isCbox) {
                this.remote.unfocus(this.$el.find('.browser-viewer-url-input'));
            }
            this.emit('framebox.mode.brain', {
                index: undefined
            });

            setTimeout(function() {
                this.keyboard.close();
                this.$input.focusout();
                this.$input[0].blur();
            }.bind(this), 500);
        }

        event.preventDefault();
    },

    /**
     * Set URL by calling wolfprot command.
     *
     * @param url URL name
     */
    setUrl: function(url) {
        this.deviceConnection
            .send('setBrowserUrl', {
                appId: this.options.appId,
                url: url
            });
    },

    /**
     * Handle keyboard.
     * If input field is clicked or control bar is closed or hidden.
     */
    keyboardHandler: function(opened) {
        if (opened) {
            this.emit('framebox.submenu.close-all');
            if (platform.checks.isCbox) {
                this.remote.focus(this.$el.find('.browser-viewer-url-input'));
            }

            this.keyboard.open(this.$input, null, 'ios', this.index);
        } else if (this.$input.hasClass('focused')) {
            this.keyboard.close();
        }
    },

    /**
     * Poll URL from Backend.
     * After setting the new browser URL it needs some time to get the new URL from the backend.
     */
    pollUrl: function() {
        this.urlPolling = this.urlPolling || this.pollFactory.create({
            pollHandler: this.fetchUrl.bind(this)
        });

        this.urlPolling.addCheck(function(stop) {
            this.form.get('url').setValue(this.url);
            stop();
        }.bind(this));

        if (!this.urlPolling.isPolling) {
            this.urlPolling.start();
        }
    },

    /**
     * Get URL from backend.
     * @param done
     */
    fetchUrl: function(done) {
        this.deviceConnection
            .send([{
                command: 'getContentPerOutput',
                data: {
                    outputs: ['hdmi1', 'hdmi2', 'edit']
                }
            }])
            .then(function(data) {
                const window = data.windows.find(w => w.appId === this.appId);
                this.url = window ? window.options.url : '';

                done();
            }.bind(this));
    },

    initForm: function() {
        this.$input.val(this.url);

        this.form = this.formManager.create({
            el: this.$el.find('.browser-url-bar'),
            submit: true
        });
    },

    destroy: function() {
        if (this.urlPolling) {
            this.urlPolling.stopPolling();
            this.urlPolling = null;
        }
    }
});
