const helper = require('../../../helper.js');
const template = require('./live-stream.html');
const vueUtils = require('../../../components-vue/util.js');
const RedrawEngine = require('../../../../redraw-engine');

module.exports = {
    template,

    props: {
        id: {
            type: String,
            required: true
        },
        source: {
            type: String,
            required: true
        },
        width: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        play: {
            type: Boolean,
            required: false,
            default: true
        },
        liveStreamSize: {
            type: Object,
            required: false
        },
        aspectRatioDisparity: {
            type: Number,
            required: false,
            default: 1
        },
        fps: {
            type: Number,
            required: false,
            default: 20
        },
        /**
         * Use a fixed resolution to query picture via Wolfprot.
         */
        resolution: {
            type: Object,
            required: false
        }
    },

    data: function() {
        return {
            canvas: null,
            context: null
        };
    },

    computed: {
        canvasId: function() {
            return 'canvas-' + this.id;
        },
        isWidescreen: function() {
            return helper.round(this.aspectRatioDisparity, 2) === 1;
        },
        isPortrait: function() {
            return helper.round(this.aspectRatioDisparity, 2) < 1;
        }
    },

    watch: {
        play: function(val) {
            if (val) {
                this.previewEngine.start({ fps: this.fps });
            } else {
                this.previewEngine.stop();
                this.clear();
            }
        }
    },

    methods: {
        getPreview: function() {
            let pictureWidth = this.isWidescreen ? this.width : this.liveStreamSize.width;
            let pictureHeight = this.isWidescreen ? this.height : this.liveStreamSize.height;

            if (this.resolution) {
                pictureWidth = this.resolution.width;
                pictureHeight = this.resolution.height;
            }

            return this.wolfprot.talk('getPictureCbox', {
                pictureWidth: pictureWidth,
                pictureHeight: pictureHeight,
                pictureSource: this.source
            }).then(data => {
                if (!this.play) {
                    return;
                }

                this.createFrame(data.picture, function(image) {
                    if (image) {
                        let imageDimension = { x: 0, y: 0, width: image.width, height: image.height };

                        if (!this.isWidescreen) {
                            imageDimension = this.cropImage(imageDimension);
                        }

                        this.context.drawImage(image, imageDimension.x, imageDimension.y,
                            imageDimension.width, imageDimension.height, 0, 0, this.width, this.height);
                    }
                }.bind(this));
            });
        },

        createFrame: function(data, callback) {
            const blob = new Blob([data], { type: 'image/jpeg' });
            const imageUrl = URL.createObjectURL(blob);

            this.IMAGE.onload = function() {
                callback(this);
                this.onload = null;
                this.src = '';
                URL.revokeObjectURL(imageUrl);
            };

            this.IMAGE.src = imageUrl;
        },

        /**
        * Removes black bars for airplay mirrors
        *
        * @param image Image dimensions
        * @returns {Object} New image dimensions
        */
        cropImage: function(image) {
            if (this.isPortrait) {
                const newWidth = Math.floor(image.width * this.aspectRatioDisparity);
                image.x = (image.width - newWidth) / 2;
                image.width = newWidth;
            } else {
                const newHeight = Math.floor(image.height / this.aspectRatioDisparity);
                image.y = (image.height - newHeight) / 2;
                image.height = newHeight;
            }

            return image;
        },

        clear: function() {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        }
    },

    created: function() {
        this.IMAGE = new Image();
        this.wolfprot = vueUtils.wolfprot();
        this.evctx = vueUtils.eventContext();
        this.pollHelper = vueUtils.pollHelper({
            load: this.getPreview
        });

        this.previewEngine = new RedrawEngine();
        this.previewEngine.on('redraw.update', function() {
            this.pollHelper.schedulePoll();
        }.bind(this));

        if (this.play) {
            this.previewEngine.start({ fps: this.fps });
        }
    },

    mounted: function() {
        this.canvas = this.$refs['live-stream'];
        this.context = this.canvas.getContext('2d');
    },

    destroyed: function() {
        this.previewEngine.stop();
        this.previewEngine = null;
        this.IMAGE = null;
    }
};
