const template = require('./confidence-monitor.html');
const vueUtils = require('../../../../components-vue/util.js');
const helper = require('../../../../helper.js');
const draggable = require('vuedraggable');
const app = require('../../../../app');
const Vuex = require('vuex');
const platform = require('../../../../../platform/platform');
const { findParentNodeById } = require('../../../../helper');
const { checkDropOnOutputValid, checkGroupValid } = require('../../rules');
const { resolutions } = require('../../../../states');

const sourceMapping = {
    'hdmi1': 'main',
    'hdmi2': 'hdmi2Out'
};

module.exports = {
    template,
    components: {
        'live-stream': require('../../live-stream/live-stream'),
        'live-window': require('./live-window/live-window'),
        'matrix-command-buttons': require('./matrix-command-buttons/matrix-command-buttons'),
        draggable
    },

    props: {
        id: {
            type: String,
            required: true
        },
        monitor: {
            type: Object,
            required: true
        }
    },

    data: function() {
        return {
            canvas: null,
            preview: new Image(),
            screenShareActive: false,
            dropBlocked: false, // Holds the state if drop on confidence monitor is blocked
            alreadyOpen: false, // Holds the state if to be dropped window is already open
            dropBlockedNoWindows: false
        };
    },

    computed: {
        draggableOptions: function() {
            return {
                group: {
                    name: 'conf-monitors',
                    pull: false,
                    put: function(to, from, dragEl) {
                        const fromValidGroup = checkGroupValid(from.options.group.name);
                        const alreadyOpen = this.isWindowOpen(dragEl._underlying_vm_.appId, this.output);

                        // Check if put should be allowed here.
                        const putAllowed = fromValidGroup && !alreadyOpen
                            && checkDropOnOutputValid(dragEl._underlying_vm_, this.output);

                        this.dropBlocked = !putAllowed;
                        // Do not show dropzone when window is already open
                        this.alreadyOpen = alreadyOpen;

                        return putAllowed;
                    }.bind(this)
                }
            };
        },
        draggableOptionsNoWindow: function() {
            return {
                group: {
                    name: 'no-window',
                    pull: false,
                    put: function(to, from, dragEl) {
                        const fromValidGroup = checkGroupValid(from.options.group.name);

                        // Check if put should be allowed here.
                        const putAllowed = fromValidGroup && checkDropOnOutputValid(dragEl._underlying_vm_, this.output);

                        this.dropBlockedNoWindows = !putAllowed;

                        return putAllowed;
                    }.bind(this)
                }
            };
        },
        idNoWindows: function() {
            return 'dz-no-windows-' + this.id;
        },
        output: function() {
            return this.monitor.outputPort;
        },
        windows: function() {
            return this.getWindowsByOutputPort(this.monitor.outputPort).map(window => {
                const coords = helper.convertCoordinates(window.coordinates, window.reference, this.monitor);

                return Object.assign({}, window, {
                    coordinates: coords
                });
            });
        },
        nrOpenWindows: function() {
            return this.windows.length;
        },
        showLiveStream: function() {
            return !platform.checks.isCboxControlScreen;
        },
        getSource: function() {
            return sourceMapping[this.monitor.outputPort];
        },
        showDropzoneNoWindows: function() {
            return this.getDragToId === this.idNoWindows && this.getDragging;
        },
        playLiveStream: function() {
            return !this.isEditModeActive && this.nrOpenWindows > 0 && !this.isOverlayOpen;
        },
        showDropzoneConfidenceMonitor: function() {
            return this.getDragToId === this.id && this.getDragging && !this.dropBlocked;
        },
        isOutputMirrored: function() {
            return this.isOutputMirroringActive && this.output === 'hdmi2';
        },
        ...Vuex.mapGetters('controlScreen', ['getDragging', 'getDragToId', 'getWindowsByOutputPort',
            'isEditModeActive', 'isWindowOpen', 'isOverlayOpen', 'isOutputMirroringActive']),
        ...Vuex.mapGetters('matrix', { showCommandButtons: 'getShowCommandButtons' })
    },

    watch: {
        showDropzoneConfidenceMonitor: function(val) {
            if (!val) {
                // Reset drop blocked / already open state when confidence monitor dropzone is left
                this.dropBlocked = false;
                this.alreadyOpen = false;
            }
        },

        showDropzoneNoWindows: function(val) {
            if (!val) {
                // Reset drop blocked when no windows dropzone is left
                this.dropBlockedNoWindows = false;
            }
        }
    },

    methods: {
        openWindow: function(e) {
            // Check if element in live window is dragged to own dropzone
            if (e.from.id === this.getDragToId) {
                return;
            }
            // The view model of the dragged item
            const vm = e.item._underlying_vm_;
            const type = vm.type ? vm.type : vm.contentType;
            console.log('Open window ' + type + ' from ' + e.from.id);

            if (vm.appId) {
                // If the application has an app id call move operation
                this.wolfprot.talk('setShowApplication', {
                    appId: vm.appId,
                    output: this.output
                });

                app.emit('main-loop.fast.start', {
                    id: 'prepare-list'
                });
            } else {
                // Otherwise, application needs to be started
                this.sourcesService.open(vm, {
                    output: this.output
                });
            }
        },

        closeWindow: function(appId) {
            console.log('Close window with app ID ' + appId);
            this.wolfprot.talk('setControlApplication', {
                appId: appId,
                action: 'close'
            });
        },

        startEditMode: function(appId) {
            this.$emit('start-edit-mode', appId);
        },

        /**
         * Handle dropzone enter for native drag & drop.
         * @param evt
         * @param id - the dropzone ID
         */
        onDropzoneEnter: function(evt, id) {
            if (this.getDragging) {
                this.$store.dispatch('controlScreen/setDragToId', id);
            }
        },

        /**
         * Handle dropzone leave for native drag & drop.
         * @param evt
         * @param id - the dropzone ID
        */
        onDropzoneLeave: function(evt) {
            if (this.getDragging) {
                this.$store.dispatch('controlScreen/setDragToId', evt.relatedTarget.id);
            }
        },

        onDropzoneOver: function(evt) {
            evt.preventDefault();
        },

        /**
         * Handle dropzone detection for drag & drop on touch device.
         */
        onTouchMove: function(evt, id) {
            if (!this.getDragging) {
                return;
            }

            const x = evt.touches[0].clientX;
            const y = evt.touches[0].clientY;
            const elementAtTouchPoint = document.elementFromPoint(x, y);
            const dropzone = findParentNodeById(elementAtTouchPoint, id);

            let dragToId = elementAtTouchPoint.id;
            if (dropzone && elementAtTouchPoint.id === '') {
                dragToId = id;
            }

            this.$store.dispatch('controlScreen/setDragToId', dragToId);
        },

        /**
         * Check if dropzone focus should be shown.
         * @param id
         * @return {false|*}
         */
        showDropzone: function(id) {
            if (Array.isArray(id)) {
                return id.includes(this.getDragToId) && this.getDragging;
            }

            return this.getDragToId === id && this.getDragging;
        },

        /**
         * Set web conference screen share active state. If screen share is active a green border is shown
         * around the confidence monitor.
         */
        updateScreenShareState: function(screenShareActive) {
            this.screenShareActive = screenShareActive && this.output === 'hdmi2';
        },

        i18n: vueUtils.i18n
    },

    created: function() {
        this.liveStream = app.getService('LiveStreamService');
        this.sourcesService = app.getService('SourcesService');
        this.wolfprot = vueUtils.wolfprot();
        this.LIVE_STREAM_RESOLUTION = resolutions['720p']; // Use 720p resolution for the live stream in confidence monitor.

        this.evctx = vueUtils.eventContext();
        this.evctx.on('webconference.screenshare.state.change', this.updateScreenShareState.bind(this));
    },

    destroyed: function() {
        this.evctx.close();
    }
};
