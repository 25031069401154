// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"matrix-not-supported\">\n            <h2 class=\"matrix-info-message-text\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"matrix.not_supported",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":49},"end":{"line":4,"column":77}}}))
    + "</h2>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"noTemplate") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"matrix-not-supported\">\n                <h2 class=\"matrix-info-message-text\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"matrix.no_template",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":53},"end":{"line":9,"column":79}}}))
    + "</h2>\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "            <div id=\"matrix-box-component-container\"></div>\n            <div id=\"matrix-select-component-container\"></div>\n            <div id=\"matrix-menu-component-container\"></div>\n            <div id=\"matrix-configurator-grid\" class=\"matrix-grid\"></div>\n            <div id=\"matrix-main-grid\" class=\"matrix-grid\"></div>\n            <div id=\"matrix-backdrop\" class=\"overlay-backdrop\"></div>\n            <div id=\"matrix-station-menu\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"matrix-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isIE") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});
