'use strict';

/**
 * @class HistoryStore
 */
function HistoryStore() {
    var historyStore = [];

    return {
        /**
         * Add item to the history store
         * @method add
         * @param  {Object} obj
         */
        add: function(obj) {
            historyStore.push(obj);
        },

        /**
         * Remove the last item from the history store
         * @method back
         */
        back: function() {
            if (historyStore.length > 1) {
                return historyStore.pop();
            }
        },

        /**
         * Returns the item of the given index
         * @method get
         * @param {index} index
         * @return {Object}
         */
        get: function(index) {
            return historyStore[index];
        },

        /**
         * Returns the current / last item in the history list
         * @method getCurrent
         * @return {Object}
         */
        getCurrent: function() {
            return this.get(historyStore.length - 1);
        },

        /**
         * Returns the next item in the stack
         * @method getNext
         * @return {Object}
         */
        getNext: function() {
            return historyStore[historyStore.length - 2];
        },

        /**
         * Cleanup the history list
         * @method destroy
         */
        destroy: function(callback) {
            if (callback) {
                historyStore.forEach(callback);
            }

            historyStore = [];
        }
    };
}

module.exports = HistoryStore;
