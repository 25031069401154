'use strict';

var app = require('../../../app');
var states = require('../../../states');
var opencastTpl = require('./opencast.hbs');
var treeListItemTpl = require('./tree-list-item.hbs');

app.component('OpencastModal', {
    template: opencastTpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.tree = {};
        this.recordService = this.getService('RecordService');
        this.lectureCaptureService = this.getService('LectureCaptureService');
        this.formManager = this.getService('FormManager');
        this.selectedFolderId = '';
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.initForm();
        this.loadSubDirectories();
        this.bindDOMEvents();
        this.bindEvents();
        this.setOpencastState();
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#opencast-form'),
            validationContainer: '.input-group'
        });

        this.handleFormChange();
    },

    bindDOMEvents: function() {
        this.$el.on('click', '#start-opencast:not(.is-disabled)', this.onStartOpencast.bind(this));
    },

    bindEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.on('opencast.login-state.changed', this.onLoginStateChanged.bind(this));
    },

    handleFormChange: function() {
        if (this.form.validate()) {
            this.$opencastStart.removeClass('is-disabled');

            this.$startBtn.prop('disabled', false);
            this.$startBtn.addClass('is-focusable');
        } else {
            this.$opencastStart.addClass('is-disabled');

            this.$startBtn.prop('disabled', true);
            this.$startBtn.removeClass('is-focusable');
        }
    },

    storeSelectors: function() {
        this.$opencastStart = this.$el.find('#start-opencast');
        this.$startBtn = this.$opencastStart.find('.btn-form');
        this.$opencastFileTree = this.$el.find('.opencast-file-tree');
        this.$opencastInfo = this.$el.find('#opencast-form');
        this.$loginState = this.$el.find('#opencast-login-state');
    },

    /**
     * Called after login-state of the opencast service has been changed.
     */
    onLoginStateChanged: function() {
        this.setOpencastState();
        this.loadSubDirectories();
    },

    /**
     * Switch between the login states.
     */
    setOpencastState: function() {
        var state = this.lectureCaptureService.getLoginState('opencast');

        this.$loginState.attr('data-opencast-login-state', state);
    },

    /**
     * Load sub-directories from cynap backend.
     */
    loadSubDirectories: function() {
        if (this.lectureCaptureService.getLoginState('opencast') === states.lectureCaptureLoginStates.loggedIn) {
            this.deviceConnection
                .send('getOpencastSeries')
                .then(function(directories) {
                    this.tree = directories.tree;
                    this.renderTree();
                }.bind(this));
        } else {
            this.renderFailedLoginMessage();
        }
    },

    /**
     * Render Login failed message.
     */
    renderFailedLoginMessage: function() {
        this.$opencastFileTree.hide();
        this.$opencastInfo.hide();
        this.$opencastStart.hide();
    },

    /**
     * Starts with the root-level on the loaded tree.
     */
    renderTree: function() {
        var $root = this.$el.find('[data-tree-id="root"]');

        this.$opencastInfo.show();
        this.$opencastStart.show();
        this.appendTreeChilds($root, this.tree);

        if (this.tree.length === 0) {
            this.$opencastFileTree.hide();

            return;
        }

        this.$opencastFileTree.show();
    },

    /**
     * Render all folders on given tree-level.
     *
     * @param {jQuery.Element} $parent
     * @param {Array} childTree
     */
    appendTreeChilds: function($parent, childTree) {
        var child;
        var i;

        for (i = 0; i < childTree.length; i++) {
            child = childTree[i];

            // Generate child-element.
            $parent.append(treeListItemTpl({
                name: child.title,
                id: child.id
            }));

            // Bind a click event to the folder-icon.
            this.$el
                .find('[data-item-id="' + child.id + '"] .file-tree-folder-title, [data-item-id="' + child.id + '"] .file-tree-icon')
                .on('click', this.selectFolder.bind(this, child.id));
        }
    },

    /**
     * Select focused folder an store folder-id on a variable.
     *
     * @param {String} folderId
     */
    selectFolder: function(folderId) {
        // Unselect folder if already selected
        if (this.selectedFolderId === folderId) {
            this.selectedFolderId = '';

            this.$el.find('.tree-list-item .file-tree-folder-title').removeClass('is-active');
            this.$el.find('[data-item-id="' + folderId + '"] .file-tree-folder-title').first().removeClass('is-active');

            return;
        }

        this.selectedFolderId = folderId;

        this.$el.find('.tree-list-item .file-tree-folder-title').removeClass('is-active');
        this.$el.find('[data-item-id="' + folderId + '"] .file-tree-folder-title').first().addClass('is-active');
    },

    /**
     * Start Panopto recording, if a folder has been choosed.
     */
    onStartOpencast: function() {
        this.emit('modal.close', {
            id: 'opencast'
        });

        this.recordService.startLcsRecording(this.selectedFolderId, this.$el.find('#creator').val(), this.$el.find('#title').val());
    }
});
