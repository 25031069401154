// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.zoom_show_window",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":28},"end":{"line":24,"column":61}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"supportsRecording") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":26,"column":28},"end":{"line":30,"column":35}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.zoom_show_window_webcast",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":32},"end":{"line":27,"column":73}}}))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.zoom_show_window_screen_share",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":32},"end":{"line":29,"column":78}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"zoom-settings\" name=\"zoom-settings\">\n        <div class=\"row settings-list-row\"\n             data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n             data-nav-trapped\n             data-nav-area-default>\n            <div class=\"col-xs-8\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.zoom_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":48}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input id=\"zoomEnabled\" name=\"zoomEnabled\" type=\"checkbox\" class=\"btn-switch\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div id=\"zoom-settings-container\" class=\"zoom-settings-container hidden\">\n            <div class=\"row settings-list-secundary"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDualProjection") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":51},"end":{"line":20,"column":94}}})) != null ? stack1 : "")
    + "\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":23,"column":24},"end":{"line":31,"column":31}}})) != null ? stack1 : "")
    + "                    </h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"enableContent\" name=\"enableContent\" type=\"checkbox\" class=\"btn-switch\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.zoom_app_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":24},"end":{"line":46,"column":56}}}))
    + "\n                    </h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"enableApp\" name=\"enableApp\" type=\"checkbox\" class=\"btn-switch\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.zoom_join_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":24},"end":{"line":61,"column":57}}}))
    + "\n                    </h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"enableJoinMeeting\" name=\"enableJoinMeeting\" type=\"checkbox\" class=\"btn-switch\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row settings-list-row\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.zoom_local",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":24},"end":{"line":75,"column":51}}}))
    + "\n                        <span id=\"zoom-login-state\" class=\"zoom-login-state\">\n                            <span class=\"zoom-login-state-connected\">\n                                <div class=\"zoom-login-state-point\"></div>\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.connected",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":32},"end":{"line":79,"column":58}}}))
    + "\n                            </span>\n                            <span class=\"zoom-login-state-logged-out\">\n                                <div class=\"zoom-login-state-point\"></div>\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.logged_out",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":83,"column":32},"end":{"line":83,"column":59}}}))
    + "\n                            </span>\n                            <span class=\"zoom-login-state-disconnected\">\n                                <div class=\"zoom-login-state-point\"></div>\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.disconnected",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":87,"column":32},"end":{"line":87,"column":61}}}))
    + "\n                            </span>\n                        </span>\n                    </h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"enableLocalMeeting\" name=\"enableLocalMeeting\" type=\"checkbox\" class=\"btn-switch\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div id=\"zoom-local-container\" class=\"zoom-local-container\">\n                <div class=\"row settings-list-secundary zoom-local-meeting-infos\">\n                    <div class=\"zoom-local-meeting-info\">\n                        <span class=\"zoom-local-meeting-info-name\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.logged_in_as",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":104,"column":67},"end":{"line":104,"column":96}}}))
    + ":</span>\n                        <span class=\"zoom-local-meeting-info-value\" id=\"zoom-local-username\"></span>\n                    </div>\n                    <div class=\"zoom-local-meeting-info\">\n                        <span class=\"zoom-local-meeting-info-name\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":108,"column":67},"end":{"line":108,"column":89}}}))
    + ":</span>\n                        <span class=\"zoom-local-meeting-info-value\" id=\"zoom-local-email\"></span>\n                    </div>\n                    <div class=\"zoom-local-meeting-info\">\n                        <span class=\"zoom-local-meeting-info-name\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.zoom_meeting_id",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":112,"column":67},"end":{"line":112,"column":99}}}))
    + ":</span>\n                        <span class=\"zoom-local-meeting-info-value\" id=\"zoom-local-meeting-id\"></span>\n                    </div>\n                </div>\n                <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                    <div class=\"col-xs-12 settings-oauth-container\">\n                        <div id=\"zoom-login-btn-container\" class=\"row settings-list-secundary zoom-login-btn-container\"\n                             data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\" data-nav-trapped>\n                            <input id=\"zoom-login\" type=\"button\" name=\"zoom-login\" class=\"btn-form form-action-cancel is-focusable zoom-login\"\n                                   value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.login_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":121,"column":42},"end":{"line":121,"column":70}}}))
    + "\">\n                        </div>\n                        <div id=\"zoom-logout-btn-container\" class=\"row settings-list-secundary zoom-logout-btn-container\"\n                             data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\" data-nav-trapped>\n                            <input id=\"zoom-logout\" type=\"button\" name=\"zoom-logout\" class=\"btn-form form-action-cancel is-focusable zoom-logout\"\n                                   value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.logout_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":126,"column":42},"end":{"line":126,"column":71}}}))
    + "\">\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div id=\"form-action-container\" class=\"form-primary-background\"></div>\n    </form>\n</div>\n";
},"useData":true});
