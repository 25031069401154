'use strict';

var certListItemTpl = require('./certificate-list-item.hbs');
var _ = require('lodash');
var app = require('../../../../app');
var ethernetSettingsTpl = require('./ethernet2-settings-v4.hbs');
var platform = require('../../../../../platform/platform');
var FormActionView = require('../../form-action-view');
var $ = require('jquery');
var i18n = require('i18next');

var states = {
    disconnected: 'disconnected',
    connected: 'connected',
    scanning: 'scanning',
    authenticating: 'authenticating',
    failed: 'failed'
};

app.component('Ethernet2SettingsV4', {
    template: ethernetSettingsTpl,
    actionView: null,

    getAccessKey: function() {
        return {
            'roleName': 'Ethernet2Settings',
            'roleKey': 'show'
        };
    },

    /**
     * @method initialize
     * @returns {*}
     */
    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.validate = this.getService('ValidateService');
        this.certificateError = false;
        this.certList = null;
        this.resetCertList = null;
        this.settingsSaved = true;

        this.connectionState = this.createStateMachine({
            state: states.scanning,
            states: states
        });

        return this.loadNetworkSettings(false);
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.createAuthMethodSelect();
        this.createPrioritySelect();
        this.createPingComponent();
        this.createModeSelect();
        this.storeSelectors();
        this.initForm();
        this.initFormAction();
        this.updateForm();
        this.bindDOMEvents();
        this.bindEvents();

        this.handleDhcpChange();
        this.handleAuthChange();
        this.handleCertModeChanged();
        this.handleLanModeChanged();
        this.renderCertificateList();
        this.resetCertList = this.certList;
        this.updateHandler();
    },

    /**
     * @method storeSelectors
     */
    storeSelectors: function() {
        this.$dnsEl = this.$el.find('#dns-input-container');
        this.$authEl = this.$el.find('#auth-container');
        this.$certEl = this.$el.find('.certificate-file-container');
        this.$certList = this.$el.find('#certificate-list-items');
        this.$noEntry = this.$el.find('#certificate-list-no-entry');
        this.$connectionState = this.$el.find('#lan-connection-state');
        this.$dhcpContainer = this.$el.find('.dhcp-container');
        this.$modeContainer = this.$el.find('.lan-mode-container');
        this.$status = this.$el.find('.status-info');
    },

    serialize: function() {
        return {
            isCbox: platform.checks.isCbox
        };
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.form.get('dhcp').$el.on('change', this.handleDhcpChange.bind(this));
        this.form.get('authMode').$el.on('change', this.handleAuthChange.bind(this));
        this.form.get('certMode').$el.on('change', this.handleCertModeChanged.bind(this));
        this.form.get('mode').$el.on('change', this.handleLanModeChanged.bind(this));
        this.$el.on('click', '.activate', this.activateCertificate.bind(this));
        this.$el.on('click', '.delete', this.deleteCertificate.bind(this));
    },

    bindEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.on('remote.enter.keydown', this.checkActionForm.bind(this));
        this.on('main-loop.update', this.updateHandler.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
        $('input[type=file]').on('change', this.verifyCertificate.bind(this));
    },

    /**
     * @method initFormAction
     */
    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container-v4'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this),
                navArea: false
            });
        }

        this.actionView.render();
    },

    /**
     * @method activateCertificate
     */
    activateCertificate: function(data) {
        _.each(this.certList, function(item) {
            if (item.name === data.currentTarget.name) {
                item.delete = false;
                item.active = true;
            } else {
                item.active = false;
            }
        });

        this.renderCertificateList();
        this.handleFormChange();
    },

    /**
     * @method deleteCertificate
     */
    deleteCertificate: function(data) {
        _.each(this.certList, function(item) {
            if (item.name === data.currentTarget.name) {
                if (item.delete) {
                    item.delete = false;
                } else {
                    item.delete = true;
                }
                item.active = false;
            }
        });

        this.renderCertificateList();
        this.handleFormChange();
    },

    /**
     * @method updateHandler
     */
    updateHandler: function() {
        if (!!this.form && this.form.get('dhcp').getValue()) {
            this.loadDhcpAutoSettings();
            this.deviceConnection
                .send('getLan2Status')
                .then(this.updateLanConnectionState.bind(this));
        }
    },

    /**
     * @method updateLanConnectionState
     * @param {object} data
     */
    updateLanConnectionState: function(data) {
        var state = states[data.ethStatus];

        if (state !== this.connectionState.getState()) {
            this.connectionState.changeState(state);
            this.$connectionState.attr('data-lan-connection-state', data.ethStatus);
        }
    },

    /**
     * @method saveHandlerV4
     */
    saveHandler: function() {
        if (this.form.validate()) {
            var data = this.form.serialize();
            this.deviceConnection
                .send([
                    {
                        command: 'setLan2DhcpSetting',
                        data: { dhcpSetting: data.dhcp }
                    },
                    {
                        command: 'setLan2IpAddress',
                        data: { ipAddress: data.ip }
                    },
                    {
                        command: 'setLan2SubnetMask',
                        data: { subnetMask: data.subnet }
                    },
                    {
                        command: 'setLan2Gateway',
                        data: { gatewayAddress: data.gateway }
                    },
                    {
                        command: 'setLan2DnsAddress',
                        data: { dnsAddress: data.dns }
                    },
                    {
                        command: 'setLan2DnsAddress2',
                        data: { dnsAddress: data.dns2 }
                    },
                    {
                        command: 'setLan2AuthMode',
                        data: { mode: data.authMode }
                    },
                    {
                        command: 'setLan2AuthMethod',
                        data: { authMethod: this.form.get('auth-method-select').getValue() }
                    },
                    {
                        command: 'setLan2Identity',
                        data: { name: data.identity }
                    },
                    {
                        command: 'setLan2AnonymousIdentity',
                        data: { name: data.anonymous }
                    },
                    {
                        command: 'setLan2Password',
                        data: { password: data.password }
                    },
                    {
                        command: 'setLan2CertificateMode',
                        data: { mode: data.certMode }
                    },
                    {
                        command: 'setLan2Priority',
                        data: { priority: this.form.get('priority').getValue() }
                    },
                    {
                        command: 'setLan2Mode',
                        data: { mode: this.form.get('mode').getValue() }
                    }
                ])
                .done(function() {
                    this.settingsSaved = true;
                    this.updateCertificates();

                    this.deviceConnection
                        .send('setLan2Activate')
                        .then(function() {
                            this.handleSettingsSave();
                            this.form.setDefaultValues();
                            this.emit('overlay.remote.focus', true);
                            this.loadNetworkSettings(true);
                        }.bind(this));

                    this.emit('save-changes.finished');
                }.bind(this));

            if (!platform.checks.isCbox) {
                this.saveCertificate();
                this.loadNetworkSettings(true);
            }
        }
    },

    /**
     * @method verifyCertificate
     */
    verifyCertificate: function() {
        var file = this.form.get('certificate-file').getValue();
        var reader = new FileReader();

        if (file) {
            reader.onload = function(event) {
                this.deviceConnection
                    .send([
                        {
                            command: 'setLan2CertificateFile',
                            data: {
                                name: file.name,
                                file: event.target.result,
                                action: 'verify'
                            }
                        }
                    ]).then(function() {
                        this.$certEl.removeClass('is-invalid');
                        this.$certEl.addClass('is-valid');
                        this.certificateError = false;
                        this.checkActionForm();
                    }.bind(this)).fail(function() {
                        this.$certEl.removeClass('is-valid');
                        this.$certEl.addClass('is-invalid');
                        this.actionView.disableSubmitButton();
                        this.certificateError = true;
                    }.bind(this)) ;
            }.bind(this);

            reader.readAsArrayBuffer(file);
        }
    },

    /**
     * @method updateCertificates
     */
    updateCertificates: function() {
        _.each(this.certList, function(item) {
            if (item.delete) {
                this.deviceConnection.send([
                    {
                        command: 'setLan2CertificateFile',
                        data: {
                            name: item.name,
                            action: 'delete'
                        }
                    }
                ]);
            } else if (item.active) {
                this.deviceConnection.send([
                    {
                        command: 'setLan2CertificateFileName',
                        data: { name: item.name }
                    }
                ]);
            }
        }.bind(this));
    },

    /**
     * @method saveCertificate
     */
    saveCertificate: function() {
        var file = this.form.get('certificate-file').getValue();
        var reader = new FileReader();

        if (file) {
            reader.onload = function(event) {
                this.deviceConnection
                    .send([
                        {
                            command: 'setLan2CertificateFile',
                            data: {
                                name: file.name,
                                file: event.target.result,
                                action: 'save'
                            }
                        }
                    ]);
            }.bind(this);

            reader.readAsArrayBuffer(file);

            this.form.get('certificate-file')
                .setValue(null);
        }
    },

    /**
     * Calls on clicked the cancel button
     * @method cancelHandler
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
        this.certList = this.resetCertList;
        this.renderCertificateList();
        this.settingsSaved = true;
        this.handleChanges(false);
    },

    /**
     * @method updateForm
     */
    updateForm: function() {
        this.form.setValues(this.formData);

        if (this.form.get('dhcp').getValue()) {
            this.disableSettings();
        }
    },

    /**
     * @method handleFormChangeV4
     */
    handleFormChange: function() {
        this.actionView.open();
        this.remote.update();
        this.checkActionForm();
        this.handleChanges(true);
    },

    /**
     * @method checkActionForm
     */
    checkActionForm: function() {
        if (!this.certificateError) {
            if (this.form.validate()) {
                this.actionView.enableSubmitButton();
            } else {
                this.actionView.disableSubmitButton();
            }
        }
    },

    /**
     * @method handleDhcpChange
     */
    handleDhcpChange: function(init) {
        var value = this.form.get('dhcp').getValue();

        if (value) {
            this.disableSettings();
        } else {
            this.loadDhcpAutoSettings();
            this.enableSettings();
        }

        if (init) {
            this.settingsSaved = false;
        }
    },

    /**
     * @method handleAuthChange
     */
    handleAuthChange: function() {
        var value = this.form.get('authMode').getValue();

        if (value) {
            this.showAuth();
        } else {
            this.hideAuth();
        }
    },

    /**
     * @method handleLanModeChanged
     */
    handleLanModeChanged: function() {
        var value = this.form.get('mode').getValue();

        if (value === 'lan') {
            this.$el.find('#mode-select').find('.success-message').hide();
            this.showLan();
        } else {
            this.$el.find('#mode-select').find('.success-message').show();
            this.hideLan();
        }
    },

    /**
     * @method initForm
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#ethernet-settings-form-v4'),
            validationContainer: '.input-group'
        });

        this.form.get('mac').disable();
    },

    /**
     * @method enableSettings
     */
    enableSettings: function() {
        this.form.get('ip').enable();
        this.form.get('subnet').enable();
        this.form.get('gateway').enable();
        this.form.get('dns').enable();
        this.form.get('dns2').enable();
    },

    /**
     * @method disableSettings
     */
    disableSettings: function() {
        this.form.get('ip')
            .disable();

        this.form.get('subnet')
            .disable();

        this.form.get('gateway')
            .disable();

        this.form.get('dns')
            .disable();

        this.form.get('dns2')
            .disable();

        this.form.get('mac')
            .disable();
    },

    /**
     * @method hideAuth
     */
    hideAuth: function() {
        this.$authEl.find('#password').attr('data-allow-empty', 'true');

        this.$authEl.addClass('hidden');
        this.$authEl
            .stop()
            .slideUp();

        this.checkActionForm();
    },

    /**
     * @method showAuth
     */
    showAuth: function() {
        this.$authEl.find('#password').removeAttr('data-allow-empty');

        this.$authEl.removeClass('hidden');
        this.$authEl
            .stop()
            .slideDown();

        this.checkActionForm();
    },

    /**
     * @method hideLan
     */
    hideLan: function() {
        this.$status.addClass('hidden');
        this.$modeContainer.addClass('hidden');
        this.$modeContainer
            .stop()
            .slideUp();
    },

    /**
     * @method showLan
     */
    showLan: function() {
        this.$status.removeClass('hidden');
        this.$modeContainer.removeClass('hidden');
        this.$modeContainer
            .stop()
            .slideDown();
    },

    /**
     * @method handleSettingsSave
     */
    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        if (this.form.get('dhcp').getValue()) {
            this.$dhcpContainer
                .stop()
                .slideUp(200);
        }

        this.handleChanges(false);
    },

    /**
     * @method loadNetworkSettings
     * @returns {*}
     */
    loadNetworkSettings: function(reloadCertificateList) {
        var dhcp = this.form ? this.form.get('dhcp').getValue() : false;

        return this.deviceConnection
            .send([
                'getLan2DhcpSetting',
                {
                    command: 'getLan2IpAddress',
                    data: { interfaceIp: dhcp }
                },
                'getLan2SubnetMask',
                'getLan2GatewayAddress',
                {
                    command: 'getLan2SettingsDnsAddress',
                    data: { dynamic: dhcp }
                },
                {
                    command: 'getLan2SettingsDnsAddress2',
                    data: { dynamic: dhcp }
                },
                'getLan2MacAddress',
                'getLan2AuthMode',
                'getLan2AuthMethod',
                'getLan2Identity',
                'getLan2AnonymousIdentity',
                'getLan2Password',
                'getLan2CertificateMode',
                'getLan2Certificates',
                'getLan2Priority',
                'getLan2Mode'
            ])
            .then(function(dhcpSetting, ipAddress, subnetMask, gatewayAddress, dnsAddress, dnsAddress2, ethMac, authMode, authMethod, identity, anonymousIdentity, password, certMode, certificates, ethPriority, lanMode) {
                this.formData = {
                    dhcp: dhcpSetting.dhcpSetting,
                    ip: ipAddress.ipAddress,
                    subnet: subnetMask.subnetMask,
                    gateway: gatewayAddress.gatewayAddress,
                    dns: dnsAddress.dnsAddress === '' ? '0.0.0.0' : dnsAddress.dnsAddress,
                    dns2: dnsAddress2.dnsAddress === '' ? '0.0.0.0' : dnsAddress2.dnsAddress,
                    mac: ethMac.macAddress,
                    authMode: authMode.mode,
                    'auth-method-select': authMethod.authMethod,
                    identity: identity.identity,
                    anonymous: anonymousIdentity.anonymousIdentity,
                    password: password.password,
                    certMode: certMode.mode,
                    'priority': ethPriority.priority,
                    'mode': lanMode.mode
                };

                this.certList = certificates.certList;

                if (reloadCertificateList) {
                    this.resetCertList = this.certList;
                    this.renderCertificateList();
                }
            }.bind(this));
    },

    /**
     * @method loadDhcpAutoSettings
     * @returns {*}
     */
    loadDhcpAutoSettings: function() {
        var dhcp = this.form.get('dhcp').getValue();

        return this.deviceConnection
            .send([
                {
                    command: 'getLan2SettingsIpAddress',
                    data: { dynamic: dhcp }
                },
                {
                    command: 'getLan2SettingsSubnetMask',
                    data: { dynamic: dhcp }
                },
                {
                    command: 'getLan2SettingsGatewayAddress',
                    data: { dynamic: dhcp }
                },
                {
                    command: 'getLan2SettingsDnsAddress',
                    data: { dynamic: dhcp }
                },
                {
                    command: 'getLan2SettingsDnsAddress2',
                    data: { dynamic: dhcp }
                }
            ]).then(function(ipAddress, subnetMask, gatewayAddress, dnsAddress, dnsAddress2) {
                if (dhcp && this.settingsSaved) {
                    this.form.setValues({
                        ip: ipAddress.ipAddress,
                        subnet: subnetMask.subnetMask,
                        gateway: gatewayAddress.gatewayAddress,
                        dns: dnsAddress.dnsAddress === '' ? '0.0.0.0' : dnsAddress.dnsAddress,
                        dns2: dnsAddress2.dnsAddress === '' ? '0.0.0.0' : dnsAddress2.dnsAddress
                    });
                }

                this.checkActionForm();

                if (ipAddress.ipAddress !== '0.0.0.0' && this.connectionState.getState() === states.connected && this.$dhcpContainer.css('display') === 'none') {
                    this.$dhcpContainer
                        .stop()
                        .slideDown(200, function() {
                            this.settingsSaved = false;
                        });
                }
            }.bind(this));
    },

    /**
     * @method createAuthMethodSelect
     */
    createAuthMethodSelect: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#auth-method-select-container'),
            label: 'settings.auth_method',
            native: true,
            name: 'auth-method-select',
            items: [
                {
                    value: 'peap-mschapv2',
                    text: 'settings.peap_mschapv2'
                },
                {
                    value: 'ttls-pap',
                    text: 'settings.ttls_pap'
                }
            ]
        });
    },

    /**
     * @method createPrioritySelect
     */
    createPrioritySelect: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#priority-select'),
            label: 'settings.priority_interface_access',
            native: true,
            info: i18n.t('settings.interface_info_lan2'),
            name: 'priority',
            items: [
                {
                    value: '1',
                    text: '1'
                },
                {
                    value: '2',
                    text: '2'
                },
                {
                    value: '3',
                    text: '3'
                }
            ]
        });
    },

    /**
     * @method createModeSelect
     */
    createModeSelect: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#mode-select'),
            label: 'settings.lan2_interface_mode',
            info: i18n.t('settings.visualizer_info'),
            native: true,
            name: 'mode',
            items: [
                {
                    value: 'vz',
                    text: 'settings.visualizer'
                },
                {
                    value: 'lan',
                    text: 'settings.lan'
                }
            ]
        });
    },

    createPingComponent: function() {
        this.createComponent({
            type: 'Ping',
            container: this.$el.find('#lan2-ping-container'),
            configs: {
                interface: 'lan2'
            }
        });
    },

    /**
     * @method renderCertificateList
     */
    renderCertificateList: function() {
        this.$certList.find('.certificate-list-item').data('updated', false);

        if (!this.certList.length) {
            this.$noEntry.show();
        } else {
            this.$noEntry.hide();
        }

        _.each(this.certList, function(item, key) {
            var $found = this.$certList.find('.certificate-list-item[data-key="' + key + '"]');
            var $item = null;

            if ($found.length === 0) {
                // Create new
                item.key = key;
                item.delete = false;
                item.isCbox = platform.checks.isCbox;
                $item = this.$(certListItemTpl(item));
            } else {
                // Update
                $item = $found;
                $item.find('h2').html(item.name);
                $item.find('.activate').attr('name', item.name);
                $item.find('.delete').attr('name', item.name);
            }

            $item.find('h2').removeClass('has-no-icon');
            $item.find('.icon-check-mark').hide();
            $item.find('.icon-close').hide();

            if (item.active) {
                $item.find('.icon-check-mark').show();
            } else if (item.delete) {
                $item.find('.icon-close').show();
            } else {
                $item.find('h2').addClass('has-no-icon');
            }

            this.$certList.append($item);

            $item.data('updated', true);
        }.bind(this));

        // Remove all items with data-updated=false
        _.each(this.$certList.find('.certificate-list-item'), function(el) {
            var $el = this.$(el);
            var updated = $el.data('updated');

            if (!updated) {
                $el.remove();
            }
        }.bind(this));

        this.$certList.show();
        this.remote.focus(this.$certList.find('.is-focusable').get(0));
    },

    /**
     * @method handleCertModeChanged
     */
    handleCertModeChanged: function() {
        var certMode;

        if (!platform.checks.isCbox && !!this.form.get('certificate-file')) {
            certMode = this.form.get('certMode').getValue();

            if (certMode) {
                this.form.get('certificate-file').enable();
            } else {
                this.form.get('certificate-file').disable();
            }
        }
    },

    /**
     * @method destroy
     */
    destroy: function() {
        this.formManager.destroy(this.form);
        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    handleChanges: function(change) {
        var changes =  {
            hasChanges: change,
            invalid: false,
            waitOnSave: true
        };

        if (this.actionView) {
            changes.invalid = $(this.actionView.$submitBtn).prop('disabled');
        }

        this.emit('ethernet-settings.changed', changes);
    }
});
