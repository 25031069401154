'use strict';

var app = require('../../app');
var platform = require('../../../platform/platform');
var tpl = require('./power-warning.hbs');
var i18n = require('i18next');

app.component('PowerWarning', {
    template: tpl,
    className: 'power-warning',

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.storeSelectors();
        this.languageService = this.getService('LanguageService');
    },

    postPlaceAt: function() {
        if (!platform.checks.isCbox && !platform.checks.isCboxAux) {
            this.languageService
                .initLanguage()
                .then(function() {
                    this.$el.find('.power-warning-info').html(i18n.t('modal.device') + i18n.t('modal.power_saving_message'));

                    this.showWarning();
                }.bind(this));
        }
    },

    storeSelectors: function() {
        this.$window = this.$(window);
    },

    showWarning: function() {
        this.$el
            .fadeIn(150);
    }
});
