'use strict';

var app = require('../../../app');
var _ = require('lodash');
var $ = require('jquery');
var matrixGridTpl = require('./matrix-configurator-grid.hbs');
var d3 = require('d3');
var LayoutManager = require('../../../../layout/layout');

app.component('MatrixConfiguratorGrid', {
    template: matrixGridTpl,

    getAccessKey: function() {
        return {
            'roleName': 'MatrixSettings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.configs = this.getService('MatrixConfigs');

        this.points = this.initPoints();
        this.connPoints = this.initConnectionPoints();

        this.matrixService = this.getService('MatrixService');
        this.matrixConfigService = this.getService('MatrixConfiguratorService');
        this.matrixConnectionService = this.getService('MatrixConnectionService');
        this.frontendSettings = this.getService('FrontendSettings');
        this.deviceService = app.getService('DeviceService');
        this.isDualProjection = this.deviceService.isCboxProDualProjection();

        this.on('connection-move.start', this.initPanZoomEvent.bind(this, true));
        this.on('connection-move.end', this.initPanZoomEvent.bind(this, false));
    },

    serialize: function() {
        return {
            width: this.configs.get('dimensions.width'),
            height: this.configs.get('dimensions.height'),
            isDualProjection: this.deviceService.isCboxProDualProjection()
        };
    },

    storeSelectors: function() {
        this.$svg = this.$el.find('#svg-grid');
        this.$matrixGroup = this.$el.find('#matrix-group');
        this.$point = this.$el.find('defs .point');
        this.$connectionPoint = this.$el.find('defs .connection-point');
        this.$pointsLayer = this.$el.find('#points-layer');
        this.$connectionPointsLayer = this.$el.find('#connection-points-layer');
        this.$matrixLayer = this.$el.find('#matrix-layer');
        this.$trashBin = this.$el.find('.btn-trash-bin');
        this.$matrixBackground = this.$el.find('.matrix-background');
        this.$matrixBackgroundGroup = this.$el.find('#background-layer');
    },

    /**
     * Create the grid with points/circles, call methods to init the Matrix grid.
     */
    postPlaceAt: function() {
        this.storeSelectors();
        // Hide svg until new height is calculated.
        this.$svg.css('visibility', 'hidden');
        this.recalcHeight();

        this.matrixConfigService.setSvg(this.$svg);
        this.matrixConnectionService.setSvg(this.$svg);

        _.each(this.points, function(point) {
            this.createClone(point, false);
        }.bind(this));

        _.each(this.connPoints, function(point) {
            this.createClone(point, true);
        }.bind(this));

        this.svg = d3.select(this.$svg[0]);

        this.initMatrixConfiguration();
        this.initPanZoomEvent();
        this.bindEvents();
        this.bindDOMEvents();

        this.emit('fullscreen.framebox.standard.controls.hide');
    },

    /**
     * Transform zoom view.
     */
    zoomed: function() {
        // Multitouch workaround
        if (this.matrixConfigService.moveEvent) {
            return;
        }

        this.$matrixGroup.attr('transform', d3.event.transform);
        this.$matrixBackgroundGroup.attr('transform', d3.event.transform);
    },

    /**
     * Activate/Deactivate pan zoom event.
     *
     * @param off true - disable zooming, else - enable zooming
     */
    initPanZoomEvent: function(off) {
        if (off) {
            this.zoom = d3.zoom()
                .on('zoom', null);
        } else {
            this.zoom = d3.zoom()
                .extent([[100, 100], [this.configs.get('dimensions.width'), this.configs.get('dimensions.height')]])
                .scaleExtent([1, 10])
                .translateExtent([[100, 100], [this.configs.get('dimensions.width'), this.configs.get('dimensions.height')]])
                .on('zoom', this.zoomed.bind(this));
        }

        this.svg.call(this.zoom);
    },

    /**
     * Initialize matrix configuration.
     * Try loading the matrix configuration and set up the default view.
     */
    initMatrixConfiguration: function() {
        this.matrixService.getStationSetup()
            .then(function(data) {
                this.stationList = data.stationList;
            }.bind(this));

        // If no current template is chosen, change template to first.
        if (this.configs.getCurrentMatrixTemplateId() < 0) {
            this.configs.changeMatrixTemplate(this.configs.matrixTemplates[0].id, true);
        }

        $.when(this.configs.loadMatrixConfiguration(this.$matrixLayer.get(0).innerHTML), this.configs.loadMatrixBackground())
            .then(function(config, background) {
                this.config = config;
                this.$matrixLayer.html(this.config);
                this.$matrixBackground.attr('src', background);
                this.matrixConfigService.createBindings(this.svg);
                this.configs.updateOldMatrixConfiguration(this.$matrixLayer);

                this.emit('matrix.discovery-box.set.format', this.svg.select('#connections-layer').attr('data-format'));

                // Deselect all Stations.
                this.matrixConfigService.deselectStation();
                this.initTrashBinPosition();
            }.bind(this));
    },

    /**
     * Init trash bin position (center/bottom).
     *
     */
    initTrashBinPosition: function() {
        this.$trashBin.attr('x', (this.configs.get('dimensions.width') / 2) - (this.$trashBin.attr('width') / 2) - 5);
        this.$trashBin.attr('y', this.configs.get('dimensions.height') - this.$trashBin.attr('height') - 30);
    },

    /**
     * Clone point to create grid.
     *
     * @param point Point to clone with coordinates (x, y) from point list
     * @param {Boolean} isConnectionPoint   true - is point from connection grid,
     *                                      false - is point from station grid
     */
    createClone: function(point, isConnectionPoint) {
        var element = isConnectionPoint ? this.$connectionPoint.clone(true) : this.$point.clone(true);

        element
            .attr('cx', point.x)
            .attr('cy', point.y);

        if (isConnectionPoint) {
            this.$connectionPointsLayer.append(element);
        } else {
            this.$pointsLayer.append(element);
        }

        return element;
    },

    bindEvents: function() {
        this.on('matrix-grid.save', this.saveHandler.bind(this));
        this.on('matrix-grid.clear', this.clearHandler.bind(this));
        this.on('matrix.reload-config', this.initMatrixConfiguration.bind(this));
        this.on('matrix-configurator-template.changed', this.onTemplateChange.bind(this));

        LayoutManager.on('window.resize', this.windowResizeHandler.bind(this));
    },

    bindDOMEvents: function() {
        /*
        * RELEASE-1993 - Red pointer appears when sliding a finger on the screen.
        * D3-library calls event.stopPropagation() so we are not able to detect touchstart and touchend events
        * in the custom-cursor component. Now we have changed the event to the normalized-event so we can trigger this
        * event manually.
        */
        this.$el.find('.svg-grid').on('touchstart mousedown', this.selectionHandler.bind(this));
        this.$el.find('.svg-grid').on('touchstart', this.onTouchstart.bind(this));
        this.$el.find('.svg-grid').on('touchend', this.onTouchend.bind(this));
    },

    /**
     * Handle window resizing.
     */
    windowResizeHandler: function() {
        this.recalcHeight();
    },

    /**
     * Calculate the aspect width of the svg element.
     *
     */
    recalcHeight: function() {
        var newHeight = (parseInt($(document).find('.overlay-container').width())
            * parseInt(this.configs.get('dimensions.height')))
            / parseInt(this.configs.get('dimensions.width'));

        this.$svg.height(newHeight);

        this.$svg.css('visibility', 'visible');
    },

    /**
     * Change Matrix Template.
     */
    onTemplateChange: function() {
        var length = this.$matrixLayer.children().length;

        for (var i = 0; i < length; i++) {
            this.$matrixLayer.children().get(i).innerHTML = '';
        }

        this.initMatrixConfiguration();
    },

    /**
     * Triggers the normalized-touch event after a touchend has been triggered.
     */
    onTouchstart: function() {
        this.emit('custom-cursor.disable');
    },

    /**
     * Triggers the normalized-touch event after a touchend has been triggered.
     */
    onTouchend: function() {
        this.emit('custom-cursor.enable');
    },

    selectionHandler: function(event) {
        this.matrixConfigService.selectionHandler(event.target);
    },

    /**
     * Initialize the points on the grid.
     *
     * @returns {*|Array} List of station grid points
     */
    initPoints: function() {
        var i;
        var j = 0;
        this.points = [];

        for (i = 0; i < this.configs.get('stationpoints.horizontal'); i++) {
            this.points.push({
                x: ((i + 1) * this.configs.get('stationpoints.distanceX')),
                y: ((j + 1) * this.configs.get('stationpoints.distanceY'))
            });

            for (j = 1; j < this.configs.get('stationpoints.vertical'); j++) {
                this.points.push({
                    x: ((i + 1) * this.configs.get('stationpoints.distanceX')),
                    y: ((j + 1) * this.configs.get('stationpoints.distanceY'))
                });
            }
            j = 0;
        }

        return this.points;
    },

    /**
     * Initialize the connection points on the grid.
     *
     * @returns {*|Array} List of connection grid points
     */
    initConnectionPoints: function() {
        var i;
        var j = 0;
        this.connPoints = [];

        for (i = 0; i < this.configs.get('connectionpoints.horizontal'); i++) {
            this.connPoints.push({
                x: ((i + 1) * this.configs.get('connectionpoints.distanceX')),
                y: ((j + 1) * this.configs.get('connectionpoints.distanceY'))
            });

            for (j = 1; j < this.configs.get('connectionpoints.vertical'); j++) {
                this.connPoints.push({
                    x: ((i + 1) * this.configs.get('connectionpoints.distanceX')),
                    y: ((j + 1) * this.configs.get('connectionpoints.distanceY'))
                });
            }
            j = 0;
        }

        return this.connPoints;
    },

    destroy: function() {
        this.emit('fullscreen.framebox.standard.controls.show');

        this.configs.checkCurrentMatrixTemplate();
        this.matrixConfigService.removeColorPicker();
    },

    /**
     * Handle save button and save config to file.
     *
     * @param onFinish {function} do after save
     */
    saveHandler: function(onFinish) {
        this.matrixConfigService.checkStationStatus().then(function(devicesOffline, maxReached) {
            if (maxReached || devicesOffline.length > 0) {
                this.emit('modal.open', {
                    id: 'matrix-stations-offline',
                    stations: devicesOffline,
                    maxReached: maxReached,
                    closeConfigurator: false,
                    role: {
                        name: 'Matrix',
                        key: 'show'
                    }
                });

                return;
            }

            this.emit('matrix.save.enable', false);
            this.emit('ui.block.events');
            this.emit('overlay-backdrop.show', true);

            var stations = this.svg.selectAll('.station');
            var stationList = [];

            _.each(stations._groups[0], function(station) {
                if (!station.getAttribute('id').startsWith('master') && !station.getAttribute('id').startsWith('stream')) {
                    var newStation = {
                        serial: station.getAttribute('id').replace('sn', ''),
                        audio: station.getAttribute('audio')
                    };

                    stationList.push(newStation);
                }
            }.bind(this));

            this.matrixConfigService.deselectStation();
            this.matrixService.saveStationSetup(stationList)
                .then(function() {
                    // Workaround RELEASE-2037: Wait until all stations are deselected and connections are redrawn.
                    setTimeout(function() {
                        this.configs.saveMatrixConfiguration(this.$matrixLayer.get(0).innerHTML, onFinish);

                        this.emit('ui.unblock.events');
                        this.emit('matrix-box.hide');
                        this.emit('matrix-menu.hide');
                        this.emit('overlay-backdrop.hide');
                    }.bind(this), 500);
                }.bind(this));

            this.configs.chooseTemplate = true;
        }.bind(this));
    },

    /**
     * Clear Matrix grid.
     * Delete all stations and connections created.
     */
    clearHandler: function() {
        const length = this.$matrixLayer.children().length;

        for (let i = 0; i < length; i++) {
            this.$matrixLayer.children().get(i).innerHTML = '';
        }

        this.matrixConfigService.initMaster(this.svg);
    }
});
