'use strict';

var app = require('../../app');
var platform = require('../../../platform/platform');
var tpl = require('./rotate-message.hbs');

app.component('RotateMessage', {
    template: tpl,
    className: 'smartphone-rotation-warning',

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.mobile = this.getService('MobileService');
        this.storeSelectors();
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.bindEvents();
        this.checkPortraitWarning();
    },

    /**
     * @method storeSelectors
     */
    storeSelectors: function() {
        this.$window = this.$(window);
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        this.$window.on('resize', this.resizeHandler.bind(this));
    },

    /**
     * @method orientationChangeHandler
     */
    resizeHandler: function() {
        this.checkPortraitWarning();
    },

    /**
     * @method checkPortraitWarning
     */
    checkPortraitWarning: function() {
        if (platform.checks.isSmartphone() && !platform.checks.isWindowsOS || (platform.checks.isSmartphone() && platform.checks.isWindowsPhone)) {
            var width = screen.width;
            var height = screen.height;

            if (platform.checks.isIOS) {
                width = this.$window.width();
                height = this.$window.height();
            }

            if (width < height) {
                this.showRotationWarning();
            } else {
                this.hideRotationWarning();
            }
        } else if (this.$el.is(':visible')) {
            this.hideRotationWarning();
        }
    },

    /**
     * @method showRotationWarning
     */
    showRotationWarning: function() {
        this.$el
            .fadeIn(150);
    },

    /**
     * @method hideRotationWarning
     */
    hideRotationWarning: function() {
        this.$el
            .fadeOut(150);
    }
});
