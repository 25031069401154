'use strict';

var app = require('../../../../app');
var panoptoSettingsTpl = require('./panopto-settings.hbs');
var FormActionView = require('../../form-action-view');
var $ = require('jquery');
var platform = require('../../../../../platform/platform');
var states = require('./../../../../../app/states');

app.component('PanoptoSettings', {
    template: panoptoSettingsTpl,
    actionView: null,

    getAccessKey: function() {
        return {
            'roleName': 'RecordSettings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.formData = {};
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.validate = this.getService('ValidateService');
        this.lectureCaptureService = this.getService('LectureCaptureService');

        this.lcsType = null;
        this.overrideDisableButton = false;
        this.changes = {};

        this.previousLoginState = null;
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.initForm();
        this.form.setValues(this.formData);
        this.initFormAction();
        this.bindDOMEvents();
        this.bindEvents();
        this.handleLcsTypeChanged();
        this.handleCertModeChanged();
        this.updatePanoptoStatus();
    },

    serialize: function() {
        var dfd = this.$.Deferred();

        this.loadPanoptoSettings()
            .then(function() {
                dfd.resolve({
                    isCbox: platform.checks.isCbox
                });
            }.bind(this));

        return dfd.promise();
    },

    storeSelectors: function() {
        this.$noEntry = this.$el.find('#certificate-list-no-entry');
        this.$loginState = this.$el.find('#panopto-login-state');

        this.$regKey = this.$el.find('#panoptoRegKey');
        this.$regKeyContainer = this.$el.find('#reg-key-container');

        this.$adHocSettingsContainer = this.$el.find('#panopto-adhoc-settings-container');

        this.$credentialsContainer = this.$el.find('#credentials-container');
        this.$username = this.$el.find('#panoptoUsername');
        this.$password = this.$el.find('#panoptoPassword');

        this.$oauthLoginLogoutContainer = this.$el.find('#panopto-oauth-login-logout-container');
        this.$oauthContainer = this.$el.find('#panopto-oauth-container');

        this.$clientId = this.$el.find('#panoptoClientId');
        this.$clientSecret = this.$el.find('#panoptoClientSecret');

        this.$loginBtnContainer = this.$el.find('#panopto-login-btn-container');
        this.$loginBtn = this.$el.find('#panopto-login');
        this.$logoutBtnContainer = this.$el.find('#panopto-logout-btn-container');
        this.$logoutBtn = this.$el.find('#panopto-logout');
    },

    bindEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.on('remote.enter.keydown', this.checkActionForm.bind(this));
        this.on('settings.save-changes', this.saveWithCertificate.bind(this));
        this.on('settings.cancel-changes', this.cancelHandler.bind(this));
        this.on('panopto.login-state.changed', this.onLoginStateChanged.bind(this));
        this.on('lcs-type.changed', this.handleLcsTypeChanged.bind(this));
    },

    bindDOMEvents: function() {
        this.form.get('panoptoCertMode').$el.on('change', this.handleCertModeChanged.bind(this));

        this.$loginBtn.on('click', function() {
            this.emit('record-settings.unsaved-changes.handle', this.login.bind(this));
        }.bind(this));
        this.$logoutBtn.on('click', this.logout.bind(this));
    },

    /**
     * Called after login-state of the panopto service has been changed.
     */
    onLoginStateChanged: function() {
        this.updatePanoptoStatus();
    },

    /**
     * Handle LCS Type. Show/Hide settings.
     * @param options
     */
    handleLcsTypeChanged: function(options) {
        if (options) {
            this.lcsType = options.lcsType;
        }

        this.customUserModeEnabled = this.form.get('panoptoCustomUserMode').getValue();

        switch (this.lcsType) {
            case states.lcsTypes.panoptoRemoteRecorder:
                this.showRemoteRecorderSettings();
                this.hideAdHocSettings();
                break;
            case states.lcsTypes.panoptoAdhocLegacy:
                this.hideRemoteRecorderSettings();
                this.showAdHocSettings(true);
                break;
            case states.lcsTypes.panoptoAdhocOAuth2:
                this.hideRemoteRecorderSettings();
                this.showAdHocSettings(false);
                break;
        }

        this.checkActionForm();
    },

    /**
     * Hide remote recorder settings.
     */
    hideRemoteRecorderSettings: function() {
        this.$regKey.prop('disabled', true);
        this.$regKeyContainer.hide();
    },

    /**
     * Show remote recorder settings.
     */
    showRemoteRecorderSettings: function() {
        this.$regKey.prop('disabled', false);
        this.$regKeyContainer.show();
    },

    /**
     * Hide AdHoc settings.
     */
    hideAdHocSettings: function() {
        this.$adHocSettingsContainer.hide();
        this.$loginState.show();

        this.handleCredentialsSettings(false);
        this.handleOAuthSettings(false);
    },

    /**
     * Show AdHoc settings depending on internal/external.
     * @param internal true/false (false = oauth)
     */
    showAdHocSettings: function(internal) {
        if (internal) {
            this.handleOAuthSettings(false);
            this.handleCredentialsSettings(!this.customUserModeEnabled);
        } else {
            this.handleOAuthSettings(true);
            this.handleCredentialsSettings(false);
        }

        if (this.customUserModeEnabled) {
            this.$loginState.hide();
        } else {
            this.$loginState.show();
        }

        this.$adHocSettingsContainer.show();
    },

    /**
     * Show/hide credential (user/pw) input fields.
     * @param show true/false
     */
    handleCredentialsSettings: function(show) {
        if (show) {
            this.$username.prop('disabled', false);
            this.$password.prop('disabled', false);
            this.$credentialsContainer.show();
        } else {
            this.$username.prop('disabled', true);
            this.$password.prop('disabled', true);
            this.$credentialsContainer.hide();
        }
    },

    /**
     * Show/hide oauth (client ID/secret) input fields.
     * @param show true/false
     */
    handleOAuthSettings: function(show) {
        if (show) {
            this.$clientId.prop('disabled', false);
            this.$clientSecret.prop('disabled', false);
            this.$oauthContainer.show();

            if (!this.customUserModeEnabled) {
                this.$oauthLoginLogoutContainer.show();
            } else {
                this.$oauthLoginLogoutContainer.hide();
            }
        } else {
            this.$clientId.prop('disabled', true);
            this.$clientSecret.prop('disabled', true);
            this.$oauthContainer.hide();
            this.$oauthLoginLogoutContainer.hide();
        }
    },

    /**
     * Switch between the login states.
     */
    updatePanoptoStatus: function() {
        const state = this.lectureCaptureService.getLoginState('panopto');

        this.$loginState.attr('data-panopto-login-state', state);

        switch (state) {
            case states.lectureCaptureLoginStates.notLoggedIn:
            case states.lectureCaptureLoginStates.loginFailed:
                this.$loginBtn.prop('disabled', false);
                this.$logoutBtn.prop('disabled', true);
                this.$loginBtnContainer.show();
                this.$logoutBtnContainer.hide();

                break;
            case states.lectureCaptureLoginStates.loggedIn:
                this.$loginBtn.prop('disabled', true);
                this.$logoutBtn.prop('disabled', false);

                this.$loginBtnContainer.hide();
                this.$logoutBtnContainer.show();
                break;
        }

        this.previousLoginState = state;
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container-panopto'),
                onSubmit: this.saveWithCertificate.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    /**
     * Read an save the added certificate.
     * If there is no certificate selected only save the other data.
     */
    saveWithCertificate: function() {
        var file;
        var reader = new FileReader();

        if (platform.checks.isCbox) {
            return this.saveHandler('');
        }

        file = this.form.get('panoptoCertificateFile').getValue();

        if (file) {
            reader.onload = function(event) {
                this.saveHandler(event.target.result);
            }.bind(this);

            reader.readAsArrayBuffer(file);

            this.form.get('panoptoCertificateFile').setValue(null);
        } else {
            this.saveHandler('');
        }
    },

    /**
     * Saves all given data on cynap.
     *
     * @param {ArrayBuffer} fileBuffer
     */
    saveHandler: function(fileBuffer) {
        var data;

        if (this.form.validate()) {
            data = this.form.serialize();
            data = {
                hostname: data.panoptoHostname,
                username: data.panoptoUsername,
                password: data.panoptoPassword,
                certificate: data.panoptoCertMode,
                regKey: data.panoptoRegKey,
                clientId: data.panoptoClientId,
                clientSecret: data.panoptoClientSecret,
                customUserMode: data.panoptoCustomUserMode
            };

            if (fileBuffer) {
                data.certificateBlob = fileBuffer;
            }

            this.deviceConnection
                .send([
                    {
                        command: 'setPanoptoSettings',
                        data: data
                    }
                ])
                .then(function() {
                    this.handleSettingsSave();
                    this.emit('record-settings.saved');

                    if (!platform.checks.isCbox) {
                        setTimeout(this.loadPanoptoSettings.bind(this), 500);
                    }
                }.bind(this));
        }
    },

    /**
     * Called when the certification-mode has been enabled or disabled.
     */
    handleCertModeChanged: function() {
        var certMode;

        if (!platform.checks.isCbox && !!this.form.get('panoptoCertificateFile')) {
            certMode = this.form.get('panoptoCertMode').getValue();

            if (certMode) {
                this.form.get('panoptoCertificateFile').enable();
            } else {
                this.form.get('panoptoCertificateFile').disable();
            }
        }
    },

    login: function() {
        this.emit('panopto-settings.login', {
            login: true
        });
        this.deviceConnection
            .send('setPanoptoBrowserLogin', {
                login: true
            })
            .then(function() {
                this.emit('overlay.close');
            }.bind(this));
    },

    logout: function() {
        this.emit('overlay.remote.focus', true);

        this.deviceConnection
            .send('setPanoptoBrowserLogin', {
                login: false
            })
            .then(function() {
                // Immediately disable logout button while logout is in progress
                if (this.lectureCaptureService.getLoginState('panopto') !== states.lectureCaptureLoginStates.notLoggedIn) {
                    this.$logoutBtn.prop('disabled', true);
                }
            }.bind(this));
    },

    /**
     * Calls on clicked the cancel button.
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
        this.handleLcsTypeChanged();
        this.actionView.close();

        this.handleChanges(false);
    },

    /**
     * Called when some data has been changed in this form.
     */
    handleFormChange: function() {
        this.actionView.open();
        this.remote.update();
        this.handleLcsTypeChanged();
        this.checkActionForm();

        this.handleChanges(true);
    },

    checkActionForm: function() {
        if (!!this.form.validate() && !this.overrideDisableButton) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * Initialize Form-manager.
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#panopto-settings-form'),
            validationContainer: '.input-group'
        });
    },

    /**
     * Update overlay after saving data.
     */
    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });
        this.form.setDefaultValues();
        this.actionView.close();
        this.emit('overlay.remote.focus', true);
        this.emit('panopto.saved');

        this.handleChanges(false);
    },

    /**
     * Load all needed settings.
     */
    loadPanoptoSettings: function() {
        return this.deviceConnection
            .send([
                'getPanoptoSettings'
            ])
            .then(function(panoptoSettings) {
                this.formData = {
                    panoptoHostname: panoptoSettings.hostname,
                    panoptoUsername: panoptoSettings.username,
                    panoptoPassword: panoptoSettings.password,
                    panoptoCertMode: panoptoSettings.certificate,
                    panoptoRegKey: panoptoSettings.remoteRecorderRegKey,
                    panoptoClientId: panoptoSettings.clientId,
                    panoptoClientSecret: panoptoSettings.clientSecret,
                    panoptoCustomUserMode: panoptoSettings.customUserMode
                };
            }.bind(this));
    },

    destroy: function() {
        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    handleChanges: function(change) {
        this.changes = {
            hasChanges: change,
            invalid: $(this.actionView.$submitBtn).prop('disabled')
        };

        this.$el.parent().data('has-changes', this.changes.hasChanges);
        this.$el.parent().data('has-changes-invalid', this.changes.invalid);
    }
});
