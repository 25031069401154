// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-12\">\n                    <div id=\"presentation-mode-select\"></div>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-12\">\n                    <div id=\"presentation-mode-timeout-select\"></div>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".is-focusable\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"col-xs-12\">\n                    <div id=\"network-discovery-select\"></div>\n                </div>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".is-focusable\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"col-xs-6\">\n                    <div id=\"presentation-mode-select\"></div>\n                </div>\n                <div class=\"col-xs-6\">\n                    <div id=\"network-discovery-select\"></div>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <div id=\"presentation-mode-timeout-select\"></div>\n                </div>\n                <div class=\"col-xs-6\"></div>\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"col-xs-12\">\n                        <div id=\"miracast-mode-select\"></div>\n                    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"col-xs-6\">\n                        <div id=\"miracast-mode-select\"></div>\n                    </div>\n                    <div class=\"col-xs-6\"></div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"col-xs-12\">\n                            <div id=\"mice-interface-select\"></div>\n                        </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"col-xs-6\">\n                            <div id=\"mice-interface-select\"></div>\n                        </div>\n                        <div class=\"col-xs-6\"></div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                    <div class=\"col-xs-8\">\n                        <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.miracast_4k_mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":170,"column":48},"end":{"line":170,"column":81}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input id=\"miracast4kMode\"\n                                   name=\"miracast4kMode\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch\" />\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row settings-list-secundary airplay-bt-discovery\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                    <div class=\"col-xs-8\">\n                        <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.airplay_4k_mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":250,"column":48},"end":{"line":250,"column":80}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input id=\"airplay4kMode\"\n                                   name=\"airplay4kMode\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch\" />\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"col-xs-12\">\n                        <div id=\"airplay-interface-select\"></div>\n                    </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"col-xs-6\">\n                        <div id=\"airplay-interface-select\"></div>\n                    </div>\n                    <div class=\"col-xs-6\"></div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row settings-list-secundary camera-back-enable has-content\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                    <div class=\"col-xs-9\">\n                        <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(alias1,"settings.vcast_camera_back",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":366,"column":48},"end":{"line":366,"column":82}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-3\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input id=\"vCastCameraBack\"\n                                   name=\"vCastCameraBack\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch\" />\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDualProjection") : depth0),{"name":"unless","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":380,"column":16},"end":{"line":397,"column":27}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"row settings-list-secundary camera-back-rec-enable has-content\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                        <div class=\"col-xs-9\">\n                            <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.vcast_camera_back_content",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":383,"column":52},"end":{"line":383,"column":94}}}))
    + "</h2>\n                        </div>\n                        <div class=\"col-xs-3\">\n                            <div class=\"btn-switch-container pull-right\">\n                                <input id=\"vCastCameraBackRecording\"\n                                       name=\"vCastCameraBackRecording\"\n                                       type=\"checkbox\"\n                                       class=\"btn-switch\" />\n                                <div class=\"btn-switch-toggle-container\">\n                                    <div class=\"btn-switch-toggle\"></div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.mirroring_lan",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":412,"column":48},"end":{"line":412,"column":78}}}))
    + "</h2>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.mirroring_lan1",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":414,"column":48},"end":{"line":414,"column":79}}}))
    + "</h2>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                    <div class=\"col-xs-8\">\n                        <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.mirroring_lan2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":433,"column":48},"end":{"line":433,"column":79}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input id=\"mirroringLan2\"\n                                   name=\"mirroringLan2\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch\" />\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div id=\"vapp-mirror-settings-container\">\n                <div class=\"row settings-list-row\">\n                    <div class=\"col-xs-12\">\n                        <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.vapp",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":470,"column":28},"end":{"line":470,"column":49}}}))
    + "</h2>\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                    <div class=\"col-xs-8\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.avoid_infinity_mirror_effect",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":476,"column":48},"end":{"line":476,"column":93}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input id=\"avoidInfinityMirror\"\n                                name=\"avoidInfinityMirror\"\n                                type=\"checkbox\"\n                                class=\"btn-switch\" />\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"mirror-settings\" name=\"mirror-settings\">\n        <div class=\"row settings-list-row\">\n            <div class=\"col-xs-12\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.menu_mirror",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":48}}}))
    + "</h2>\n            </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFullsize") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":45,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"row settings-list-secundary settings-list-info\" data-show-on=\"lecture\">\n            <div class=\"col-xs-12\">\n                <p>"
    + alias3((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias2).call(alias1,depth0,{"name":"tr","hash":{"key":"settings.lecture_info"},"data":data,"loc":{"start":{"line":49,"column":19},"end":{"line":49,"column":58}}}))
    + "</p>\n            </div>\n        </div>\n\n        <!-- Miracast -->\n        <div class=\"row settings-list-primary miracast-enable\" data-nav-area=\".btn-switch\" data-nav-trapped>\n            <div class=\"col-xs-8\">\n            <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.mirror_miracast_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":16},"end":{"line":56,"column":55}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input id=\"miracastEnable\"\n                           name=\"miracastEnable\"\n                           type=\"checkbox\"\n                           class=\"btn-switch\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"miracast-settings-container\">\n            <div id=\"wlan-infrastructure-miracast-info\" class=\"row settings-list-secundary wlan-list-item wlan-info hidden\">\n                <div class=\"col-xs-12\">\n                    <p><span class=\"icon-msg-warning\"></span>   <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.mirroring_infra_miracast",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":70},"end":{"line":74,"column":111}}}))
    + "</span></p>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\" id=\"miracast-mode\" data-nav-area=\".is-focusable\" data-nav-trapped>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFullsize") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":79,"column":16},"end":{"line":88,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n\n            <div class=\"row settings-list-secundary miracast-pin-enable has-content\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-9\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.mirror_miracast_pin",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":93,"column":44},"end":{"line":93,"column":80}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-3 pull-right\">\n                    <div class=\"input-group flex\">\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message\" data-pin=\"miracastPin\">\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.pin_config_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":99,"column":32},"end":{"line":99,"column":64}}}))
    + "\n                            </span>\n                        </div>\n                        <div class=\"btn-switch-container pull-right no-padding p-left-15\">\n                            <input id=\"miracastPin\"\n                                   name=\"miracastPin\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch\" />\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div id=\"miracast-mice\">\n                <div class=\"row settings-list-secundary has-content\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                    <div class=\"col-xs-8\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.mirror_mice",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":118,"column":48},"end":{"line":118,"column":76}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input id=\"miceEnable\"\n                                   name=\"miceEnable\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch\" />\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n                <div id=\"miracast-mice-interface\"\n                     class=\"row settings-list-secundary\"\n                     data-nav-area=\".is-focusable\"\n                     data-nav-trapped>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFullsize") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":137,"column":20},"end":{"line":146,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary has-content\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.miracast_back_channel_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":152,"column":44},"end":{"line":152,"column":89}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"miracastBackChannelEnable\"\n                               name=\"miracastBackChannelEnable\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"supports4kMirroring") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":167,"column":12},"end":{"line":184,"column":19}}})) != null ? stack1 : "")
    + "\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.miracast_enable_low_latency",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":188,"column":44},"end":{"line":188,"column":88}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"miracastLowLatency\"\n                               name=\"miracastLowLatency\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <!-- Airplay -->\n        <div class=\"row settings-list-primary airplay-enable\" data-nav-area=\".btn-switch\" data-nav-trapped>\n            <div class=\"col-xs-8\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.mirror_airplay_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":207,"column":20},"end":{"line":207,"column":58}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input id=\"airplayEnable\"\n                           name=\"airplayEnable\"\n                           type=\"checkbox\"\n                           class=\"btn-switch\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div id=\"airplay-settings-container\">\n            <div class=\"row settings-list-secundary airplay-pin-enable has-content\"\n                 data-nav-area=\".btn-switch\"\n                 data-nav-trapped>\n                <div class=\"col-xs-9\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.mirror_airplay_pin",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":226,"column":44},"end":{"line":226,"column":79}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-3 pull-right\">\n                    <div class=\"input-group flex\">\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message\" data-pin=\"airplayPin\">\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.pin_config_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":232,"column":32},"end":{"line":232,"column":64}}}))
    + "\n                            </span>\n                        </div>\n                        <div class=\"btn-switch-container pull-right no-padding p-left-15\">\n                            <input id=\"airplayPin\"\n                                   name=\"airplayPin\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch\" />\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"supports4kMirroring") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":247,"column":12},"end":{"line":264,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"row settings-list-secundary airplay-bt-discovery\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.mirror_airplay_bt_discovery",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":267,"column":44},"end":{"line":267,"column":88}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"airplayBtDiscovery\"\n                               name=\"airplayBtDiscovery\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary airplay-interface\"\n                 id=\"airplay-interface\"\n                 data-nav-area=\".is-focusable\"\n                 data-nav-trapped>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFullsize") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":285,"column":16},"end":{"line":294,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <!-- Chromecast -->\n        <div class=\"row settings-list-primary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n            <div class=\"col-xs-8\">\n            <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.mirror_chromecast_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":301,"column":16},"end":{"line":301,"column":57}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input id=\"chromecastEnable\"\n                           name=\"chromecastEnable\"\n                           type=\"checkbox\"\n                           class=\"btn-switch\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary settings-list-info hidden\" data-name=\"chromecastEnable\">\n            <div class=\"col-xs-12\">\n                <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.mirror_chromecast_enable_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":318,"column":19},"end":{"line":318,"column":65}}}))
    + "</p>\n            </div>\n        </div>\n\n        <!-- vConnect / vCast -->\n        <div class=\"row settings-list-primary vconnect-enable\" data-nav-area=\".btn-switch\" data-nav-trapped>\n            <div class=\"col-xs-8\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.mirror_vconnect_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":325,"column":20},"end":{"line":325,"column":59}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input id=\"vConnectEnable\"\n                           name=\"vConnectEnable\"\n                           type=\"checkbox\"\n                           class=\"btn-switch\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div id=\"vcast-settings-container\">\n            <div class=\"row settings-list-secundary vcast-pin-enable has-content\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-9\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.mirror_vcast_pin",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":342,"column":44},"end":{"line":342,"column":77}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-3 pull-right\">\n                    <div class=\"input-group flex\">\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message\" data-pin=\"vCastPin\">\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.pin_config_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":348,"column":32},"end":{"line":348,"column":64}}}))
    + "\n                            </span>\n                        </div>\n                        <div class=\"btn-switch-container pull-right no-padding p-left-15\">\n                            <input id=\"vCastPin\"\n                                   name=\"vCastPin\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch\" />\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"unless","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":363,"column":12},"end":{"line":398,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n\n        <!-- Mirroring Interfaces -->\n        <div id=\"mirroring-interfaces-container\" class=\"hidden\">\n            <div class=\"row settings-list-row\" id=\"airplay-chromecast-vsolution-mirroring\">\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.airp_chromec_cast_connect_mirroring",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":405,"column":24},"end":{"line":405,"column":76}}}))
    + "</h2>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n"
    + ((stack1 = (lookupProperty(helpers,"if_or")||(depth0 && lookupProperty(depth0,"if_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPureOrPro") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"if_or","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":411,"column":20},"end":{"line":415,"column":30}}})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"mirroringLan1\"\n                               name=\"mirroringLan1\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"unless_or")||(depth0 && lookupProperty(depth0,"unless_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPureOrPro") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"unless_or","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":430,"column":12},"end":{"line":447,"column":26}}})) != null ? stack1 : "")
    + "\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.mirroring_wlan",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":451,"column":44},"end":{"line":451,"column":75}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"mirroringWlan\"\n                               name=\"mirroringWlan\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDualProjection") : depth0),{"name":"unless","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":466,"column":8},"end":{"line":491,"column":19}}})) != null ? stack1 : "")
    + "\n        <div id=\"form-action-container\"></div>\n    </form>\n</div>\n";
},"useData":true});
