module.exports={
    "none": {
        "id": 0,
        "key": "none",
        "group": "none"
    },
    "user": {
        "id": 1,
        "key": "user",
        "group": "UserGroup",
        "extendGroup": "ControlScreenGroup"
    },
    "admin": {
        "id": 2,
        "key": "admin",
        "group": "AdminGroup",
        "extendGroup": "ControlScreenGroup"
    },
    "collab": {
        "id": 3,
        "key": "collab",
        "group": "CollabGroup"
    },
    "aux": {
        "id": 4,
        "key": "aux",
        "group": "onlyStatusbar"
    },
    "viewer": {
        "id": 5,
        "key": "viewer",
        "group": "CollabGroup"
    }
}
