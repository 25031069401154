'use strict';

var _ = require('lodash');
var timezones = require('./timezones.js');
var instance;

/**
 * Timezones
 * @constructor
 */
var Timezones = function() {
    this.timezones = timezones;
    this.selectList = null;
};

/**
 * @method getByIndex
 * @param index
 * @returns {*}
 */
Timezones.prototype.getByIndex = function(index) {
    if (this.timezones[index]) {
        return this.timezones[index];
    }

    return false;
};

/**
 * @method getList
 * @returns {*|timezones}
 */
Timezones.prototype.getList = function() {
    return this.timezones;
};

/**
 * @method getSelectList
 * @returns {Array}
 */
Timezones.prototype.getSelectList = function() {
    var list = [];
    var item;

    if (this.selectList) {
        return this.selectList;
    }

    _.forEach(this.timezones, function(name, index) {
        item = {
            text: name,
            value: index
        };

        list.push(item);
    });

    this.selectList = list;

    return this.selectList;
};

/**
 * Singleton pattern
 */
function getInstance() {
    if (!instance) {
        instance = new Timezones();
    }

    return instance;
}

module.exports = getInstance();
