'use strict';

var app = require('../app');

var defaultOptions = {
    browserEnabled: true,
    whiteboardEnabled: true,
    appsEnabled: null,
    helpEnabled: true,
    audioEnabled: true,
    annotationEnabled: true,
    snapshotEnabled: true,
    freezeEnabled: true,
    uiScalingSize: 'medium'
};

app.service('CustomUiSettings', function(app) {
    return {
        /**
         * @method initialize
         */
        initialize: function() {
            this.configs = app.getService('ConfigsService');
            this.JSONService = app.getService('JSONService');

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.setDefaultSettings();
                    this.getSettings();
                }.bind(this));
        },

        /**
         * @returns {string}
         */
        getSetting: function(settingName) {
            return this.deviceConnection
                .send([
                    {
                        command: 'getExtraSettings',
                        data: {
                            filename: this.configs.get('cynap.customUiSettingsFilename')
                        }
                    }
                ])
                .then(function(extraSettings) {
                    if (extraSettings) {
                        this.extraSettings = this.JSONService
                            .parse(extraSettings.string, {
                                'Settings': []
                            });
                    }

                    if (settingName) {
                        return this.findSetting(settingName);
                    }

                    return this.extraSettings;
                }.bind(this));
        },

        /**
         * Wait for multiple settings to respond.
         *
         * @method getSettings
         * @param {Array} settings
         */
        getSettings: function(settings) {
            var promises = [];
            var cmd;

            if (settings && typeof (settings) !== 'string') {
                settings.forEach(function(setting) {
                    cmd = this.getSetting(setting);
                    promises.push(cmd);
                }.bind(this));
            } else {
                cmd = this.getSetting(settings);
                promises.push(cmd);
            }

            return app.$.when.apply(app.$, promises);
        },

        /**
         * Save setting in the backend of cynap.
         */
        saveSettings: function() {
            this.deviceConnection
                .send([
                    {
                        command: 'setExtraSettings',
                        data: {
                            filename: this.configs.get('cynap.customUiSettingsFilename'),
                            string: JSON.stringify(this.extraSettings)
                        }
                    }
                ]);
        },

        /**
         * Search setting in data-store.
         *
         * @param {String} name
         * @return {*}
         */
        findSetting: function(name) {
            for (var i = 0; i < this.extraSettings.Settings.length; i++) {
                var obj = this.extraSettings.Settings[i];

                for (var key in obj) {
                    if (key === name) {
                        return obj[key];
                    }
                }
            }

            return this.setDefaultSetting(name);
        },

        /**
         * Update settings.
         */
        updateSetting: function(data) {
            var attrExists = false;

            for (var i = 0; i < this.extraSettings.Settings.length; i++) {
                var obj = this.extraSettings.Settings[i];

                for (var key in obj) {
                    if (key === data.tag) {
                        attrExists = true;
                        obj[key] = data.value;
                    }
                }
            }

            if (!attrExists) {
                var setting = {};

                setting[data.tag] = data.value;
                this.extraSettings.Settings.push(setting);
            }
        },

        /**
         * Set default value.
         */
        setDefaultSettings: function() {
            this.extraSettings = {
                'Settings': []
            };

            this.setDefaultSetting('browserEnabled');
            this.setDefaultSetting('whiteboardEnabled');
            this.setDefaultSetting('appsEnabled');
            this.setDefaultSetting('helpEnabled');
            this.setDefaultSetting('audioEnabled');
            this.setDefaultSetting('annotationEnabled');
            this.setDefaultSetting('snapshotEnabled');
            this.setDefaultSetting('freezeEnabled');
            this.setDefaultSetting('uiScalingSize');
        },

        /**
         * Returns the default settings by given name.
         *
         * @param name
         * @return {*}
         */
        setDefaultSetting: function(name) {
            var tmpSetting = {};

            // Check if default setting is set.
            if ('undefined' === typeof defaultOptions[name]) {
                app.getService('ExceptionsManager')
                    .throw('Setting with name "' + name + '" has no default setting.');

                return;
            }

            tmpSetting[name] = defaultOptions[name];
            this.extraSettings.Settings.push(tmpSetting);

            return tmpSetting[name];
        }
    };
});
