'use strict';

/**
 * Handles Drag & Drop rule. Web conferences or vCast with camera back active must always be opened on HDMI1.
 * @param dragEl - the view model of the dragged element
 * @returns {boolean|boolean}
 */
const checkDropOnOutputValid = function(vm, output) {
    return (['webconference', 'zoom', 'teams'].includes(vm.contentType)
        || ['webconference', 'zoom', 'msteams'].includes(vm.type)
        || vm?.options?.cameraBackActive) ? output === 'hdmi1' : true;
};

/**
 * Handles Drag & Drop rule. The element dropped on the confidence monitor and the prepare area must belong to one
 * of the listed groups.
 * @param fromGroupName - the group name of the dropped element
 * @returns {boolean}
 */
const checkGroupValid = function(fromGroupName) {
    return ['sources', 'files', 'prepare-items', 'live-items'].includes(fromGroupName);
};

module.exports = {
    checkDropOnOutputValid,
    checkGroupValid
};
