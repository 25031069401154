const template = require('./progress-circle.html');

/**
 * Convert polar to cartesian coordinates
 *
 * @param centerX center point on X axis
 * @param centerY center point on Y axis
 * @param radis radius
 * @param angleInDegrees angle in degress
 */
const polarToCartesian = function(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
};

/**
 * Create SVG path for an arc
 *
 * @paarm x center point on X axis
 * @param y center point on Y axis
 * @param radius radius
 * @param startAngle start angle in degrees
 * @param endAngle end angle in degrees
 */
const describeArc = function(x, y, radius, startAngle, endAngle) {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    const d = [
        'M', start.x, start.y,
        'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(' ');

    return d;
};

module.exports = {
    template,
    props: {
        progress: {
            type: Number,
            required: true
        }
    },

    methods: {
        describeArc
    },

    computed: {
        progressInDegrees: function() {
            if (this.progress === 100) {
                // When drawing 360° no progress is visible anymore
                return 359.9;
            } else {
                return this.progress / 100 * 360;
            }
        }
    }
};
