'use strict';

var app = require('../../../../app');
var template = require('./matrix-streaming-settings.hbs');
var FormActionView = require('./../../form-action-view');
var $ = require('jquery');
var i18n = require('i18next');

var bandwidthValues = [
    {
        text: 'settings.bandwidth_0_5mbit',
        value: 500
    },
    {
        text: 'settings.bandwidth_1mbit',
        value: 1000
    },
    {
        text: 'settings.bandwidth_1_5mbit',
        value: 1500
    },
    {
        text: 'settings.bandwidth_2mbit',
        value: 2000
    },
    {
        text: 'settings.bandwidth_2_5mbit',
        value: 2500
    },
    {
        text: 'settings.bandwidth_5mbit',
        value: 5000
    },
    {
        text: 'settings.bandwidth_7_5mbit',
        value: 7500
    },
    {
        text: 'settings.bandwidth_10mbit',
        value: 10000
    },
    {
        text: 'settings.bandwidth_15mbit',
        value: 15000
    },
    {
        text: 'settings.bandwidth_20mbit',
        value: 20000
    },
    {
        text: 'settings.bandwidth_25mbit',
        value: 25000
    },
    {
        text: 'settings.bandwidth_30mbit',
        value: 30000
    },
    {
        text: 'settings.bandwidth_35mbit',
        value: 35000
    },
    {
        text: 'settings.bandwidth_40mbit',
        value: 40000
    }
];

app.component('MatrixStreamingSettings', {
    template: template,
    actionView: null,
    changes: false,

    getAccessKey: function() {
        return {
            'roleName': 'MatrixSettings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.frontendSettings = this.getService('FrontendSettings');
        this.isInitalized = false;
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.createSelects();
        this.initForm();
        this.bindEvents();

        $.when(this.loadBackendSettings(this))
            .done(function() {
                this.updateForm();
                this.form.setDefaultValues();

                this.initFormAction();
                this.bindDOMEvents();

                this.updatePortInfo();
            }.bind(this));
    },

    serialize: function() {
        return {
            minPort: 2,
            maxPort: 65532 // Matrix streams (HDMI1/2) in dual-projection require four ports
        };
    },

    storeSelectors: function() {
        this.$port = this.$el.find('#port');
    },

    bindEvents: function() {
        this.on('settings.save-changes', this.saveHandler.bind(this));
    },

    bindDOMEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
    },

    /**
     * Add parameter to text.
     *
     * @param {string} text
     * @param {array} parameter
     */
    sprintf: function(text, parameter) {
        var counter = 0;
        var args = parameter;

        return text.replace(/%s/g, function() {
            return args[counter++];
        });
    },

    /**
     * Update success message.
     */
    updatePortInfo: function() {
        var $err = this.$port.parent().find('.error-message');
        var $success = this.$port.parent().find('.success-message');

        if ($err.is(':visible')) {
            $success.hide();

            return;
        }

        $success
            .html(this.sprintf(i18n.t('settings.port_range'),
                [parseInt(this.$port[0].value), parseInt(this.$port[0].value) + 3]));

        $success.show();
    },

    /**
     * Called when a input/select has been changes.
     * - Open action view.
     * - Set changes var to true.
     */
    handleFormChange: function(data) {
        if (this.isInitalized) {
            this.actionView.open();
            this.changes = true;
        }

        this.checkActionForm();

        if (data.input.name === 'port') {
            this.updatePortInfo();
        }
    },

    /**
     * Load Settings.
     *
     * @returns {*}
     */
    loadBackendSettings: function() {
        var dfd = $.Deferred();

        this.deviceConnection
            .send([
                'getMatrixMasterStreamingMulticastIp',
                'getMatrixMasterMulticastStreamingPort',
                'getMatrixMasterStreamingFramerate',
                'getMatrixMasterStreamingResolution',
                'getMatrixMasterStreamingBitrate',
                'getMatrixMasterStreamingTtl'
            ])
            .then(function(ipAddress, port, framerate, resolution, bitrate, ttl) {
                this.formData = {
                    ipAddress: ipAddress.ipAddress,
                    port: port.port,
                    framerate: framerate.framerate,
                    resolution: resolution.resolution,
                    bandwidthMode: bitrate.mode,
                    bandwidth: bitrate.bitrate,
                    ttl: ttl.ttl
                };

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Called when the save button has been clicked.
     */
    saveHandler: function() {
        var data;

        if (this.form.validate()) {
            data = this.form.serialize();

            this.deviceConnection
                .send([
                    {
                        command: 'setMatrixMasterStreamingMulticastIp',
                        data: {
                            ipAddress: data.ipAddress
                        }
                    },
                    {
                        command: 'setMatrixMasterMulticastStreamingPort',
                        data: {
                            port: data.port
                        }
                    },
                    {
                        command: 'setMatrixMasterStreamingResolution',
                        data: {
                            resolution: data.resolution
                        }
                    },
                    {
                        command: 'setMatrixMasterStreamingFramerate',
                        data: {
                            framerate: data.framerate
                        }
                    },
                    {
                        command: 'setMatrixMasterStreamingTtl',
                        data: {
                            ttl: data.ttl
                        }
                    },
                    {
                        command: 'setMatrixMasterStreamingBitrate',
                        data: {
                            mode: data.bandwidthMode,
                            bitrate: data.bandwidth
                        }
                    }
                ])
                .done(function() {
                    this.handleSettingsSave();
                    this.form.setDefaultValues();
                    this.emit('overlay.remote.focus', true);
                }.bind(this));

            this.handleSettingsSave();
        }
    },

    /**
     * Handels the feedback after saving settings.
     */
    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.changes = false;
    },

    /**
     * Calls on clicked the cancel button.
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);

        this.form.resetValues();

        this.changes = false;
    },

    /**
     * Update data on Formular.
     */
    updateForm: function() {
        this.form.setValues(this.formData);
        this.isInitalized = true;
    },

    /**
     * Enable or disable "Save"-button.
     */
    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#matrix-streaming-settings'),
            validationContainer: '.input-group'
        });
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    createSelects: function() {
        var ttlItems = [];
        var i;

        for (i = 0; i <= 254; i++) {
            ttlItems[i] = {
                text: (i + 1).toString(),
                value: i + 1
            };
        }

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#ttl-select'),
            label: 'settings.ttl',
            native: true,
            name: 'ttl',
            items: ttlItems
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#resolution-select'),
            label: 'settings.resolution',
            native: true,
            name: 'resolution',
            items: [
                {
                    text: '360p',
                    value: '360p'
                },
                {
                    text: '540p',
                    value: '540p'
                },
                {
                    text: '720p',
                    value: '720p'
                },
                {
                    text: '1080p',
                    value: '1080p'
                }
            ]
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#framerate-select'),
            label: 'settings.framerate',
            native: true,
            name: 'framerate',
            items: [
                {
                    text: 'settings.low',
                    value: 'low'
                },
                {
                    text: 'settings.medium',
                    value: 'medium'
                },
                {
                    text: 'settings.high',
                    value: 'high'
                }
            ]
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#bandwidth-mode-select'),
            label: 'settings.bandwidth_mode',
            native: true,
            name: 'bandwidthMode',
            items: [
                {
                    text: 'settings.constant',
                    value: 'constant'
                },
                {
                    text: 'settings.variable',
                    value: 'variable'
                }
            ]
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#bandwidth-select'),
            label: 'settings.bandwidth',
            native: true,
            name: 'bandwidth',
            items: bandwidthValues
        });
    },

    hasChanges: function() {
        var changes =  {
            hasChanges: this.changes,
            invalid: false
        };

        if (this.actionView) {
            changes.invalid = $(this.actionView.$submitBtn).prop('disabled');
        }

        return changes;
    }
});
