const collabUser = require('./collab-user.html');

module.exports = {
    template: collabUser,

    props: {
        nick: {
            type: String,
            required: true
        },
        clientId: {
            type: Number,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        },
        isSelf: {
            type: Boolean,
            required: true
        },
        removable: {
            type: Boolean,
            required: false,
            default: true
        }
    }
};
