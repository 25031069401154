'use strict';

const app = require('../../app');
const matrixFlapTpl = require('./matrix-flap.hbs');
const $ = require('jquery');
const LayoutManager = require('../../../layout/layout');
const componentControlBarDisabled = require('./../../settings').componentControlBarDisabled;
const _ = require('lodash');
const store = require('../../store/store');

/**
 * Matrix.
 */
app.component('MatrixFlap', {
    template: matrixFlapTpl,

    initialize: function() {
        this.frameboxService = app.getService('FrameBoxService');
        this.matrixConfigs = this.getService('MatrixConfigs');
        this.annotationService = this.getService('AnnotationService');
        this.overlayHandlerService = app.getService('OverlayHandlerService');
        this.screensaverService = app.getService('ScreensaverService');

        app.emit('main-loop.fast.start', {
            id: 'annotation'
        });
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();
        this.bindDOMEvents();
        this.setFlapVisible();

        this.$matrixFlap.addClass('right');
    },

    storeSelectors: function() {
        this.$matrixFlap = this.$el.find('.matrix-flap');
        this.$matrixFlapContainer = this.$el.parent();
        this.$matrixFlapIcon = this.$matrixFlap.find('.icon');
    },

    bindEvents: function() {
        this.on('overlay.opened', this.checkFlap.bind(this));
        this.on('overlay.closed', this.resetFlap.bind(this));
        this.on('show.ui', _.debounce(this.setFlapVisible.bind(this), 100));
        this.on('matrix.master-state.changed', _.debounce(this.setFlapVisible.bind(this), 100));
        this.on('aspect-ratio.changed', this.repositionFlap.bind(this));

        this.on('activities.hide', this.onActivitiesHide.bind(this));
        this.on('activities.show', this.onActivitiesShow.bind(this));
        this.on('menu.opened', this.onActivitiesHide.bind(this));
        this.on('control-center.opened', this.onActivitiesHide.bind(this));
        this.on('file-browser.opened', this.onActivitiesHide.bind(this));

        this.unwatchEditMode = store.watch((state, getters) => getters['controlScreen/isEditModeActive'],
            function(editModeActive) {
                if (editModeActive) {
                    this.setFlapVisible(false);
                }
            }.bind(this));

        LayoutManager.on('window.resize', this.repositionFlap.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.matrix-flap', this.toggleFlap.bind(this));
    },

    /**
     * Check if Matrix master mode is enabled.
     */
    isMatrixEnabled: function() {
        const dfd = $.Deferred();

        this.deviceConnection
            .send('getMatrixMasterMode')
            .then(function(masterMode) {
                dfd.resolve(masterMode.enabled);
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Toggle Flap if template list is not empty and there isn't already a template chosen for this presentation.
     */
    toggleFlap: function() {
        if (this.$matrixFlap.hasClass('right')) {
            this.matrixConfigs.loadMatrixTemplate()
                .then(function(loadTemplate) {
                    const list = this.matrixConfigs.matrixTemplates;

                    if (this.matrixConfigs.ignoreMatrixTemplates) {
                        // RELEASE-5103: if BE and FE have different templates configured (template was externally chagned), load BE template)
                        this.openMatrix(loadTemplate ? this.matrixConfigs.matrixTemplateId : undefined, list.length === 0);

                        return;
                    }

                    if (list.length > 1 && loadTemplate) {
                        this.openSelectTemplateModal();
                    } else if (list.length === 1 && loadTemplate) {
                        this.openMatrix(list[0].id);
                    } else {
                        this.matrixConfigs.checkCurrentMatrixTemplate()
                            .then(function(updated) {
                                if (updated) {
                                    this.openSelectTemplateModal();
                                } else {
                                    this.openMatrix(undefined, list.length === 0);
                                }
                            }.bind(this));
                    }
                }.bind(this));
        } else {
            this.closeMatrix();
        }
    },

    /**
     * Open modal to select a template.
     */
    openSelectTemplateModal: function() {
        this.matrixConfigs.loadMatrixTemplates()
            .then(function(list) {
                const templates = list;

                if (templates.length <= 1) {
                    this.openMatrix(templates[0]?.id, templates.length === 0);

                    return;
                }

                this.emit('modal.open', {
                    id: 'selection',
                    title: 'matrix.select_template',
                    items: list,
                    onSelect: this.openMatrix.bind(this),
                    autoClose: true
                });
            }.bind(this));
    },

    /**
     * Close Matrix Layer.
     */
    closeMatrix: function() {
        this.$matrixFlap.addClass('hidden');
        this.$matrixFlap.hide();

        this.emit('overlay.close');
        this.emit('modal.close');
        this.emit('backdrop.hide');
        this.emit('menu.close');

        this.resetFlap();
    },

    /**
     * Hide matrix flap if overlay !== matrix-main.
     */
    checkFlap: function() {
        if (this.overlayHandlerService.getOpenOverlayId() === 'matrix-main') {
            this.$matrixFlapContainer.show();
        } else {
            this.$matrixFlapContainer.hide();
        }
    },

    /**
     * Reset Flap to initial layout.
     */
    resetFlap: function() {
        this.$matrixFlap.addClass('right');
        this.$matrixFlap.removeClass('left');

        this.$matrixFlapContainer.css('left', '0px');
        this.$matrixFlapIcon.removeClass('icon-arrow-back');
        this.$matrixFlapIcon.addClass('icon-vmatrix');
        this.$matrixFlapContainer.show();
    },

    /**
     * Hide menu button when activities.hide event is called.
     */
    onActivitiesHide: function() {
        // RELEASE-3346: when Matrix Control framebox is open (fullscreen), there is no control bar.
        if (this.frameboxService.getFullscreenBox()
            && -1 !== componentControlBarDisabled.indexOf(this.frameboxService.getFullscreenBox().component)) {
            return;
        }

        this.setFlapVisible(true);
    },

    /**
     * Show menu button when activities.show event is called.
     */
    onActivitiesShow: function() {
        // If fullscreen is opened, only listen to click event.
        if ((this.frameboxService.getFullscreenBox() && !this.frameboxService.isControlBarPinned())
            || this.annotationService.isMagicPenActive()
            || this.annotationService.isActive()) {
            return;
        }

        this.setFlapVisible(false);
    },

    /**
     * Open Matrix Layer.
     */
    openMatrix: function(templateId, noTemplate) {
        this.$matrixFlap.addClass('hidden');
        this.$matrixFlap.hide();

        this.emit('control-center.close');

        this.emit('overlay.open', {
            id: 'matrix-main',
            menuButton: true,
            closeButton: false,
            isMatrix: true,
            animation: 'slideToRight',
            templateId: templateId,
            noTemplate: noTemplate
        });

        this.$matrixFlap.addClass('left');
        this.$matrixFlap.removeClass('right');

        this.$matrixFlapContainer.css('left', this.$matrixFlapContainer.parent().width() + 'px');
        this.$matrixFlapIcon.removeClass('icon-vmatrix');
        this.$matrixFlapIcon.addClass('icon-arrow-back');

        if (templateId !== undefined) {
            this.matrixConfigs.updateMatrixPresentationId();
        }

        this.matrixConfigs.chooseTemplate = false;
    },

    /**
     * Set visibility and z-index of flap.
     *
     * @param configs Overlay open configs
     */
    setFlapVisible: function(hide, configs) {
        setTimeout(function() {
            $.when(this.isMatrixEnabled())
                .done(function(enabled) {
                    const isMatrixOverlay = (configs ? configs.id === 'matrix-main' : false)
                        || this.$matrixFlap.hasClass('left');

                    if (!enabled || ((!isMatrixOverlay && hide) || this.annotationService.isMagicPenActive()
                        || this.screensaverService.isScreensaverActive() || this.screensaverService.isScreenOffActive())
                        && !store.getters['controlScreen/isEditModeActive']) {
                        this.$matrixFlap.addClass('hidden');
                        this.$matrixFlap.hide();

                        return;
                    }

                    this.$matrixFlapContainer.css('z-index', isMatrixOverlay ? '107' : '');
                    this.$matrixFlap.removeClass('hidden');
                    this.$matrixFlap.show();
                }.bind(this));
        }.bind(this), 500);
    },

    /**
     * Reposition flap on window resize.
     */
    repositionFlap: function() {
        this.$matrixFlapContainer.css('left', this.$matrixFlap.hasClass('right') ? '0px'
            : (this.$matrixFlapContainer.parent().width() + 'px'));
    },

    destroy: function() {
        if (this.unwatchEditMode()) {
            this.unwatchEditMode();
        }
    }
});
