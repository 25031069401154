// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"matrix-main-grid-container\">\n    <svg id=\"svg-grid\" class=\"svg-grid is-draggable\" viewBox=\"0, 0, "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"width") : depth0), depth0))
    + ", "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"height") : depth0), depth0))
    + "\">\n        <defs>\n            <foreignObject class=\"station-info-container\">\n                <div class=\"matrix-station-info\"></div>\n            </foreignObject>\n        </defs>\n\n        <g id=\"matrix-group\">\n            <g id=\"background-layer\">\n                <foreignObject width=\"100%\" height=\"100%\">\n                    <img class=\"matrix-background\" src=\"\">\n                </foreignObject>\n            </g>\n            <g id=\"matrix-layer\"></g>\n            <g id=\"clone-element\" class=\"clone-element\"></g>\n        </g>\n    </svg>\n\n    <div id=\"station-previews\" class=\"station-previews\"></div>\n    <div id=\"matrix-files-menus\" class=\"matrix-files-menus\"></div>\n    <div id=\"matrix-interaction\" class=\"matrix-interaction\"></div>\n</div>\n";
},"useData":true});
