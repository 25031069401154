// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"room-controller-settings\" name=\"room-controller-settings\">\n        <div class=\"row settings-list-row\"\n             data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n             data-nav-trapped\n             data-nav-area-default>\n            <div class=\"col-xs-8\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.room_controller_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":59}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input id=\"roomControllerEnabled\" name=\"roomControllerEnabled\" type=\"checkbox\" class=\"btn-switch\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n         <div id=\"room-controller-settings-container\" class=\"room-controller-settings-container hidden\">\n            <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-12\">\n                    <div class=\"input-group\">\n                        <label class=\"input-label\" for=\"preferredUrl\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.room_controller_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":70},"end":{"line":24,"column":107}}}))
    + "</label>\n                        <input type=\"text\"\n                            class=\"input is-focusable\"\n                            id=\"roomControllerUrl\"\n                            name=\"roomControllerUrl\"\n                            value=\"\"\n                            data-validation=\"checkUrlHostAndPath\"\n                            max-length-host=\"511\" max-length-path=\"1023\"\n                            data-allow-empty=\"false\"\n                            tabIndex=\"-1\"/>\n\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":75},"end":{"line":36,"column":101}}}))
    + "</span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"form-action-container\" class=\"form-primary-background\"></div>\n    </form>\n</div>\n";
},"useData":true});
