'use strict';

const app = require('../../../app');
const states = require('../../../states');
const panoptoTpl = require('./panopto.hbs');
const treeListItemTpl = require('./tree-list-item.hbs');

const dirListStates = {
    unknown: 0,
    incomplete: 1,
    complete: 2
};

const ROOT_ID = '00000000-0000-0000-0000-000000000000';

app.component('PanoptoModal', {
    template: panoptoTpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.tree = {};
        this.recordService = this.getService('RecordService');
        this.lectureCaptureService = this.getService('LectureCaptureService');
        this.remote = this.getService('RemoteService');
        this.selectedFolderId = '';
        this.isCustomUser = this.options.configs.isCustomUser;
        this.lcsType = this.options.configs.lcsType;
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindDOMEvents();
        this.bindEvents();
        this.setPanoptoState();
        this.handleCustomUser();

        this.showModalLoader();
        this.loadDirectories(ROOT_ID);
    },

    bindDOMEvents: function() {
        this.$el.on('click', '#start-panopto', this.onStartPanopto.bind(this));
        this.$el.on('click', '#logout-panopto', this.onLogoutPanopto.bind(this));
    },

    bindEvents: function() {
        this.on('panopto.login-state.changed', this.onLoginStateChanged.bind(this));
        this.on('modal.opened-end', function() {
            if (this.selectedFolderId) {
                this.remote.focus(this.$el.find('#start-panopto'));
            }
        }.bind(this));
    },

    storeSelectors: function() {
        this.$loginState = this.$el.find('#panopto-login-state');
        this.$username = this.$el.find('.panopto-username');
        this.$logoutBtn = this.$el.find('#logout-panopto');
        this.$startBtnContainer = this.$el.find('.start-btn-container');
    },

    /**
     * Called after login-state of the panopto service has been changed.
     */
    onLoginStateChanged: function() {
        this.setPanoptoState();
    },

    /**
     * Switch between the login states.
     */
    setPanoptoState: function() {
        var state = this.lectureCaptureService.getLoginState('panopto');
        var username = this.lectureCaptureService.getPanoptoUsername();

        this.$loginState.attr('data-panopto-login-state', state);
        this.$username.text(username);

        // Reload directories
        this.loadDirectories(ROOT_ID);
    },

    /**
     * Handle custom user mode.
     * Show/hide username and logout button if custom user mode is enabled/disabled.
     */
    handleCustomUser: function() {
        if (this.isCustomUser) {
            this.$username.show();
            this.$logoutBtn.show();
            this.$startBtnContainer.addClass('contains-logout-btn');
        } else {
            this.$username.hide();
            this.$logoutBtn.hide();
            this.$startBtnContainer.removeClass('contains-logout-btn');
        }
    },

    /**
     * Render Login failed message.
     */
    showFailedMessage: function() {
        this.$el.find('.panopto-file-tree').hide();
        this.$el.find('.modal-loader').hide();
        this.$el.find('.services-disabled').attr('disabled', 'disabled');

        this.$el.find('.modal-failed').show();
    },

    showModalLoader: function() {
        this.$el.find('.panopto-file-tree').hide();
        this.$el.find('.modal-failed').hide();
        this.$el.find('.services-disabled').attr('disabled', 'disabled');

        this.$el.find('.modal-loader').show();
    },

    showFileTree: function() {
        this.$el.find('.modal-loader').hide();
        this.$el.find('.modal-failed').hide();

        this.$el.find('.services-disabled').removeAttr('disabled');
        this.$el.find('.panopto-file-tree').show();
    },

    onFolderClick: function(id) {
        var $folder = this.$el.find('[data-tree-id="' + id  + '"]');

        if ($folder.hasClass('opened')) {
            $folder
                .removeClass('opened')
                .empty();
        } else {
            this.loadDirectories(id);
        }

        this.selectFolder(id, $folder.data('read-only'));
    },

    /**
     * NEW
     * Load sub-directories from cynap backend.
     */
    loadDirectories: function(id) {
        if (this.lectureCaptureService.getLoginState('panopto') !== states.lectureCaptureLoginStates.loggedIn) {
            this.showFailedMessage();

            return;
        }

        this.deviceConnection
            .send('getPanoptoSubDirectories', {
                folderId: id
            })
            .then(function(dir) {
                const isRoot = id === ROOT_ID;
                const completed = dir.status === dirListStates.complete;

                if (isRoot && dir.childs.length === 0 && !completed) {
                    this.showModalLoader();
                } else {
                    this.renderDirs(dir);
                    this.showFileTree();
                }

                if (!completed) {
                    setTimeout(function() {
                        this.loadDirectories(dir.id);
                    }.bind(this), 1000);

                    return;
                }

                if (isRoot && dir.childs.length === 0) {
                    this.showFailedMessage();
                } else if (isRoot) {
                    this.selectFolder(dir.childs[0].ID, dir.childs[0].RW === 0);
                }
            }.bind(this));
    },

    /**
     * Render directories and append childs to file tree parent.
     *
     * @param directories
     */
    renderDirs: function(dir) {
        var $parent = this.$el.find('[data-tree-id="' + dir.id  + '"]');
        var child;
        var i;

        $parent
            .addClass('opened')
            .empty();

        for (i = 0; i < dir.childs.length; i++) {
            child = dir.childs[i];

            // Generate child-element.
            $parent.append(treeListItemTpl({
                name: child.Name,
                id: child.ID,
                isReadOnly: '0' === child.RW,
                parentId: child.ParentID
            }));

            // Bind a click event to the folder-icon.
            this.$el
                .find('[data-item-id="' + child.ID + '"] .file-tree-folder-title, [data-item-id="' + child.ID + '"] .file-tree-icon')
                .on('click', this.onFolderClick.bind(this, child.ID));
        }

        if (dir.status !== dirListStates.complete) {
            // Generate child-element.
            $parent.append(treeListItemTpl({
                loader: true
            }));
        }
    },

    /**
     * Select focused folder an store folder-id on a variable.
     *
     * @param {String} folderId
     */
    selectFolder: function(folderId, readOnly) {
        this.$el.find('.tree-list-item .file-tree-folder-title').removeClass('is-active');
        this.selectedFolderId = '';

        if (!readOnly) {
            this.selectedFolderId = folderId;
            this.$el.find('[data-item-id="' + folderId + '"] .file-tree-folder-title').first().addClass('is-active');

            this.remote.focus(this.$el.find('#start-panopto'));
        }
    },

    /**
     * Start Panopto recording, if a folder has been chosen.
     */
    onStartPanopto: function() {
        if ('' === this.selectedFolderId) {
            return;
        }

        this.close();
        this.recordService.startLcsRecording(this.selectedFolderId);
    },

    /**
     * Logout Panopto custom user and call login handler.
     */
    onLogoutPanopto: function() {
        if (this.lcsType === states.lcsTypes.panoptoAdhocOAuth2) {
            this.deviceConnection
                .send('setPanoptoBrowserLogin', {
                    login: false
                });
        } else {
            this.deviceConnection
                .send('setPanoptoCustomUserCredentials',
                    {
                        username: '',
                        password: ''
                    });
        }

        this.close();
    },

    /**
     * Close Panopto modal.
     */
    close: function() {
        this.emit('modal.close', {
            id: 'panopto'
        });
    },

    destroy: function() {
        if (this.timeoutHandle) {
            clearTimeout(this.timeoutHandle);
            this.timeoutHandle = null;
        }

        this.deviceConnection
            .send('setPanoptoDirListAction', {
                action: 'reset'
            });
    }
});
