'use strict';

var app = require('../app');
var StateMachine = require('./../state-machine');
var $ = require('jquery');
var platform = require('../../platform/platform');
var kim = require('./../../keyboard/keyboard-input-manager.js');

var states = {
    active: 'active',
    inactive: 'inactive'
};

var fieldTypesWithKeyboard = [
    'text',
    'password',
    'search'
];

app.service('KeyboardService', function(app) {
    /**
     * @method initialize
     */
    this.initialize = function() {
        this.manuallyOpened = false;
        this.remote = app.getService('RemoteService');
        this.languageService = app.getService('LanguageService');
        this.stationService = app.getService('StationService');
        this.isOpen = false;
        this.state = new StateMachine({
            context: this,
            state: states.inactive,
            states: states
        });

        kim.setRemote(this.remote);

        app.$document.on('click', '.input', this.onInputClick.bind(this));

        this.onLanguageChanged();

        app.on('language:changed', this.onLanguageChanged.bind(this));
        app.on('modal.closed', this.close.bind(this));
        app.on('station-status.lock', this.close.bind(this));
        app.on('osk.close', this.close.bind(this));
    };

    this.initRemoteLanguage = function() {
        this.languageService
            .getKeyboardLayout()
            .then(function(layout) {
                app.emit('keyboard.update', layout);
            }.bind(this));
    };

    this.start = function() {
        this.languageService
            .getKeyboardLayout()
            .then(function(layout) {
                // RELEASE-3373: do not initialize keyboard multiple times.
                if (this.keyboard) {
                    return;
                }

                this.keyboard = $('input').onScreenKeyboard({
                    draggable: true,
                    rewireTab: false,
                    rewireReturn: true,
                    onKeyPress: this.onKeyPress.bind(this),
                    onClose: this.onClose.bind(this),
                    remote: this.remote,
                    language: layout
                });

                this.state.changeState(states.active);

                this.isOpen = false;

                app.emit('toolbox.item.show', {
                    item: 'keyboard'
                });

                app.emit('keyboard.update', layout);
            }.bind(this));
    };

    /**
     * @method destroy
     */
    this.destroy = function() {
        if (this.keyboard) {
            this.keyboard.destroy();
            this.keyboard = null;
            this.state.changeState(states.inactive);

            app.emit('toolbox.item.hide', {
                item: 'keyboard'
            });
        }
    };

    /**
     * Open the keyboard when an input has focused
     * if keyboard is already open it will reset the correct input field
     *
     * @param {jQuery.Event} event
     */
    this.onInputClick = function(event) {
        if (this.stationService.getLockStatus()) {
            return null;
        }

        var type = event.currentTarget.type;

        // RELEASE-2048 - When touching check mark box, keyboard appears.
        if (-1 === fieldTypesWithKeyboard.indexOf(type)) {
            return null;
        }

        if (platform.checks.isCbox && !this.isOpen) {
            this.open($(event.currentTarget));
        } else if (this.keyboard) {
            this.keyboard.setInput($(event.currentTarget));
        }
    };

    /**
     * @method toggle
     */
    this.change = function(data) {
        this.keyboardData = data;
        this.enableOrDisableKeyboard();
    };

    /**
     * Called after language was changed.
     */
    this.onLanguageChanged = function() {
        this.destroy();
        this.enableOrDisableKeyboard();
    };

    /**
     * Enable or disable on-screen-keyboard.
     */
    this.enableOrDisableKeyboard = function() {
        if (platform.checks.isCbox && this.keyboardData && this.keyboardData.enable) {
            this.start();
        } else if (!platform.checks.isCbox) {
            this.initRemoteLanguage();
        } else {
            this.destroy();
        }
    };

    /**
     * Open keyboard.
     * On IOS Devices we focus the first input-field to open keyboard.
     *
     * @param {jQuery.Element|Null} $el
     * @param {String} device
     * @param {Number|null} index
     */
    this.open = function($el, device, index) {
        if (device && (device === 'ios' || device === 'android')) {
            var $input = $('#browser-smartphone-input-' + index + ' input').first();
            var input = $input.get(0);

            $input.parent().removeClass('is-hidden');
            $input.prop('disabled', false);
            input.focus();

            app.emit('activities.pause');
        }

        if (!this.keyboard) {
            return;
        }

        if (!!this.remote.isTouchKeyboardEnabled() && platform.checks.isCbox) {
            if (!$el) {
                $el = $('input:focus');
            }

            this.isOpen = true;
            this.keyboard.open($el);
        }

        app.emit('activities.pause');
    };

    /**
     * @method close
     */
    this.close = function() {
        if (!this.keyboard) {
            return;
        }

        if (!!this.keyboard && this.isOpen) {
            this.isOpen = false;
            this.keyboard.close();

            app.emit('activities.start');
        }
    };

    /**
     * @method onClose
     */
    this.onClose = function() {
        this.isOpen = false;
    };

    /**
     * @method onKeyPress
     * @param {jQuery} $el
     * @param {string} key
     * @param {int} keyCode
     */
    this.onKeyPress = function($input, character, keyCode, event) {
        if (event && event === 'keyup') {
            kim.sendKeyUp(keyCode, character);

            return;
        }

        kim.setInputElement($input);
        kim.onInput(keyCode, character);
    };

    /**
     * @method getKeyCode
     * @param {string} str
     */
    this.getKeyCode = function(str) {
        if (str) {
            return str.charCodeAt(str.length - 1);
        } else {
            return 8;
        }
    };

    /**
     * @param {bool} bool
     */
    this.setManuallyOpened = function(bool) {
        this.manuallyOpened = bool;
    };

    this.getManuallyOpened = function() {
        return this.manuallyOpened;
    };

    /**
     * @method isOpened
     */
    this.isOpened = function() {
        return this.isOpen;
    };
});
