require('./framebox-ui/framebox-ui');
require('./framebox-ui/framebox-ui-control-bar');
require('./framebox-ui/framebox-ui-fullscreen');
require('./framebox-volume/framebox-volume');
require('./framebox-url/framebox-url');
require('./framebox-page-count/framebox-page-count');
require('./framebox-message/framebox-message');
require('./framebox-annotation/framebox-annotation');
require('./controls/controls');
require('./keyboard/keyboard');

require('./types/base-type');
require('./types/visualizer/visualizer');
require('./types/visualizer/visualizer-focus/visualizer-focus');
require('./types/visualizer/visualizer-preset/visualizer-preset');
require('./types/browser/browser');
require('./types/calc/calc');
require('./types/video/video');
require('./types/audio/audio');
require('./types/pdf/pdf');
require('./types/presentation/presentation');
require('./types/text/text');
require('./types/mirroring/mirroring');
require('./types/vsolution/vsolution');
require('./types/hdmi/hdmi');
require('./types/image/image');
require('./types/others/others');
require('./types/whiteboard/whiteboard');
require('./types/webconference/webconference');
require('./types/stream-input/stream-input');
require('./types/office365/office365');
require('./types/teams/teams');
require('./types/webcam/webcam');
require('./types/matrix/matrix');
require('./types/matrix-groupwork/matrix-groupwork');
require('./types/matrix-control/matrix-control');
require('./types/zoom/zoom');
require('./framebox');
