'use strict';

var app = require('../../../app');
var ethernetSettingsTpl = require('./ethernet-settings.hbs');
require('./ethernet-settings-v4/ethernet-settings-v4');
require('./ethernet-settings-v6/ethernet-settings-v6');

app.component('EthernetSettings', {
    template: ethernetSettingsTpl,
    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.storeSelectors();
        this.createComponentV4();
        // This.createComponentV6();
        this.bindEvents();
    },

    bindEvents: function() {
        this.on('ethernet-settings.changed', this.updateChanges.bind(this));
    },

    /**
     * @method storeSelectors
     */
    storeSelectors: function() {
        this.$v4 = this.$el.find('#ethernet-settings-container-v4');
        this.$v6 = this.$el.find('#ethernet-settings-container-v6');
    },

    /**
     * @method createComponentV4
     */
    createComponentV4: function() {
        this.createComponent({
            type: 'EthernetSettingsV4',
            container: this.$v4
        });
    },

    /**
     * @method createComponentV6
     */
    createComponentV6: function() {
        this.createComponent({
            type: 'EthernetSettingsV6',
            container: this.$v6
        });
    },

    updateChanges: function(changes) {
        this.changes = changes;
    },

    hasChanges: function() {
        if (this.changes === undefined) {
            return {
                hasChanges: false,
                invalid: false
            };
        }

        return this.changes;
    }
});
