const wolfprotParameters = require('../connect/src/wolfprot/wolfprot-codes').parameters;

/**
 * Power states
 *
 * @type {{on: string, off: string}}
 */
module.exports.powerStates = {
    on: 'on',
    off: 'off'
};

/**
 * Send command states
 *
 * @type {{waiting: string, finished: string}}
 */
module.exports.sendCommandStates = {
    waiting: 'waiting',
    finished: 'finished'
};

/**
 * Device connection states
 */
module.exports.deviceConnectionStates = {
    closed: 'closed',
    open: 'open',
    none: 'none'
};

/**
 * Toolbox states
 */
module.exports.toolboxStates = {
    open: 'open',
    closed: 'closed'
};

module.exports.uploadStates = {
    pending: 'pending',
    running: 'running',
    failed: 'failed',
    done: 'done',
    aborted: 'aborted'
};

module.exports.mountStates = {
    notMounted: 'notMounted',
    mountFailed: 'mountFailed',
    mounted: 'mounted',
    notResponding: 'notResponding',
    disabled: 'disabled',
    authenticate: 'auth',
    busy: 'busy'
};

module.exports.connectionStates = {
    disconnected: 'disconnected',
    loggingIn: 'loggingIn',
    connecting: 'connecting',
    failed: 'failed',
    connected: 'connected'
};

/**
 * Action view states
 */
module.exports.actionViewStates = {
    removed: 'removed',
    hidden: 'hidden',
    visible: 'visible'
};

module.exports.characterByKeyCode = {
    37: 'LEFT',
    38: 'UP',
    39: 'RIGHT',
    40: 'DOWN',
    13: 'ENTER',
    8: 'BACKSPACE',
    32: 'WHITESPACE',
    27: 'ESC',
    42: 'Shift',
    20: 'CapsLock'
};

/**
 * VMeeting modes.
 */
module.exports.vMeetingModes = {
    ms365: 'ms365',
    ms365Basic: 'ms365Basic',
    googleCal: 'googleCal',
    googleCalBasic: 'googleCalBasic'
};

/**
 * A simple true and false state.
 *
 * @type {{true: string, false: string}}
 */
module.exports.onOff = {
    true: 'on',
    false: 'off'
};

/**
 * Encryption modes.
 */
module.exports.encryptionEnable = {
    true: true,
    false: false
};

/**
 * Encryption states.
 */
module.exports.encryptionStates = {
    failed: 'failed',
    inProgress: 'inProgress',
    encrypted: 'encrypted',
    decrypted: 'decrypted'
};

/**
 * Reverse shell states.
 */
module.exports.reverseShellStates = {
    failed: 'failed',
    started: 'started',
    stopped: 'stopped'
};

/**
 * Miracast backchannel states.
 */
module.exports.miracastBackChannelEnable = {
    true: true,
    false: false
};

/**
 * IP camera mode
 * @type {{true: number, false: number}}
 */
module.exports.ipCameraMode = {
    on: 'on',
    off: 'off'
};

module.exports.ipCameraModeToCmd = {
    off: 0x00,
    on: 0x01
};

module.exports.ipCameraModeFromCmd = {
    0: 'off',
    1: 'on'
};

module.exports.lcsTypes = {
    disabled: 'disabled',
    panoptoRemoteRecorder: 'panoptoRemoteRecorder',
    panoptoAdhocLegacy: 'panoptoAdhocLegacy',
    panoptoAdhocOAuth2: 'panoptoAdhocOAuth2',
    opencastAdhoc: 'opencastAdhoc',
    opencastAuto: 'opencastAuto'
};

module.exports.lcsTypeToCmdMapping = {
    disabled: 0x00,
    panoptoRemoteRecorder: 0x01,
    opencastAdhoc: 0x02,
    opencastAuto: 0x03,
    panoptoAdhocLegacy: 0x04,
    panoptoAdhocOAuth2: 0x05
};

module.exports.lcsStartRecordingTypes = {
    panoptoAdhocLegacy: 0x01,
    panoptoAdhocOAuth2: 0x01,
    opencastAdhoc: 0x02,
    opencastAuto: 0x03
};

module.exports.lcsCmdToTypeMapping = {
    0: 'disabled',
    1: 'panoptoRemoteRecorder',
    2: 'opencastAdhoc',
    3: 'opencastAuto',
    4: 'panoptoAdhocLegacy',
    5: 'panoptoAdhocOAuth2'
};

/**
 * LCS Modes
 */
module.exports.lcsModes = {
    auto: 'auto',
    manual: 'manual'
};

module.exports.lcsModeToCmdMapping = {
    auto: 0x00,
    manual: 0x01
};

module.exports.lcsCmdToModeMapping = {
    0: 'auto',
    1: 'manual'
};

module.exports.lcsCmdToPathMapping = {
    0: 'internal',
    1: 'external'
};

/**
 * Panopto Login States
 */
module.exports.lectureCaptureLoginStates = {
    notLoggedIn: 'notLoggedIn',
    loggedIn: 'loggedIn',
    loginFailed: 'loginFailed'
};

/**
 * Directory list States
 */
module.exports.dirListStates = {
    unknown: 'unknown',
    updating: 'updating',
    ok: 'ok',
    fail: 'fail'
};

module.exports.lectureCaptureUploadStates = {
    unknown: 'unknown',
    ok: 'ok',
    failed: 'failed'
};

module.exports.panoptoLoginDialogStates = {
    none: 'none',
    usernamePassword: 'usernamePassword',
    browser: 'browser'
};

module.exports.zoomLoginStates = {
    undefined: 'undefined',
    disconnected: 'disconnected',
    connected: 'connected',
    loggedOut: 'loggedOut'
};

module.exports.uiStates = {
    visible: 'visible',
    hidden: 'hidden'
};

/**
 * VZ modes (none, basic, full integration support)
 * @type {{none: string, basic: string, full: string}}
 *        none: no support/no VZ connected
 *        basic: control support, no websocket support (settings not available)
 *        full: full support, control support, websocket support (settings available)
 */
module.exports.visualizerModes = {
    none: 'none',
    basic: 'basic',
    full: 'full',
    basicFw: 'basicFw',
    security: 'security'
};

/**
 * Local screensaver states
 */
module.exports.localScreensaverStates = {
    available: 'available',
    notAvailable: 'not-available'
};

/**
 * Ping states
 */
module.exports.pingStates = {
    none: 'none',
    start: 'start',
    started: 'started',
    stopped: 'stopped',
    failed: 'failed',
    succeeded: 'succeeded'
};

/**
 * RAUC firmware update source states
 * - notFound: no FW update available (FW is up to date)
 * - found: FW update available
 * - busy: applies during checking if FW update available and during FW update
 */
module.exports.raucSourceStates = {
    none: 'none',
    notFound: 'notFound',
    found: 'found',
    busy: 'busy'
};

/**
 * RAUC firmware update states
 */
module.exports.raucUpdateStates = {
    none: 'none',
    idle: 'idle',
    updating: 'updating',
    finished: 'finished',
    failed: 'failed',
    downloading: 'downloading'
};

/**
 * Firmware update progress states
 */
module.exports.updateProgressStates = {
    updating: 'updating',
    finished: 'finished',
    failed: 'failed',
    downloading: 'downloading'
};

module.exports.pushIndexStates = {
    'hdmi1': wolfprotParameters.WPP_CB_MATRIX_PUSH_HDMI1,
    'hdmi2': wolfprotParameters.WPP_CB_MATRIX_PUSH_HDMI2
};

/**
 * Webcast youtube stream states
 */
module.exports.youtubeStreamStates = {
    'idle': wolfprotParameters.WPP_CB_WEBCAST_YOUTUBE_STREAM_STATE_IDLE,
    'ready': wolfprotParameters.WPP_CB_WEBCAST_YOUTUBE_STREAM_STATE_READY,
    'test-starting': wolfprotParameters.WPP_CB_WEBCAST_YOUTUBE_STREAM_STATE_TEST_STARTING,
    'live-starting': wolfprotParameters.WPP_CB_WEBCAST_YOUTUBE_STREAM_STATE_LIVE_STARTING,
    'live': wolfprotParameters.WPP_CB_WEBCAST_YOUTUBE_STREAM_STATE_LIVE,
    'complete': wolfprotParameters.WPP_CB_WEBCAST_YOUTUBE_STREAM_STATE_COMPLETE,
    'error': wolfprotParameters.WPP_CB_WEBCAST_YOUTUBE_STREAM_STATE_ERROR
};

/**
 * Webcast stream states
 */
module.exports.webcastStates = {
    'disabled': wolfprotParameters.WPP_CB_WEBCAST_STATE_DISABLED,
    'ready': wolfprotParameters.WPP_CB_WEBCAST_STATE_READY,
    'active': wolfprotParameters.WPP_CB_WEBCAST_STATE_ACTIVE,
    'error': wolfprotParameters.WPP_CB_WEBCAST_STATE_ERROR
};

/**
 * Webcast types of external sources
 */
module.exports.webcastExtTypes = {
    'hdmi-in': wolfprotParameters.WPP_CB_WEBCAST_EXTERNAL_TYPE_HDMI_IN
};

/**
 * Audio/video play states used by state machines
 */
module.exports.playStates = {
    none: 'none',
    play: 'play',
    pause: 'pause',
    stop: 'stop',
    rewind: 'rewind',
    forward: 'forward'
};

/**
 * Audio/Video playback states from Wolfprot
 */
module.exports.playbackStates = {
    'playing': wolfprotParameters.WPP_PLAYBACK_STATE_PLAYING,
    'paused': wolfprotParameters.WPP_PLAYBACK_STATE_PAUSED,
    'stopped': wolfprotParameters.WPP_PLAYBACK_STATE_STOPPED,
    'seek-backward': wolfprotParameters.WPP_PLAYBACK_STATE_SEEK_BACKWARD,
    'seek-forward': wolfprotParameters.WPP_PLAYBACK_STATE_SEEK_FORWARD
};

/**
 * Mapping between Audio/Video play states from Wolfprot to state machine states
 */
module.exports.playStatesMapping = {
    'playing': 'play',
    'paused': 'pause',
    'stopped': 'stop',
    'seek-backward': 'rewind',
    'seek-forward': 'forward'
};

/**
 * HDMI resolution setting states
 */
module.exports.hdmiResolutions = {
    'auto': wolfprotParameters.WPP_CB_MAIN_RESOLUTION_AUTO,
    '720p60': wolfprotParameters.WPP_CB_MAIN_RESOLUTION_720P60,
    '1080p30': wolfprotParameters.WPP_CB_MAIN_RESOLUTION_1080P30,
    '1080p60': wolfprotParameters.WPP_CB_MAIN_RESOLUTION_1080P60,
    '2160p30': wolfprotParameters.WPP_CB_MAIN_RESOLUTION_2160P30,
    '2160p60': wolfprotParameters.WPP_CB_MAIN_RESOLUTION_2160P60
};

/**
 * Picture sources to query preview images from CBox.
 */
module.exports.pictureSources = {
    main: 'main',
    mainOsd: 'mainOsd',
    hdmi1: 'hdmi1',
    hdmi2: 'hdmi2',
    vz: 'vz',
    edit: 'edit',
    hdmi2Out: 'hdmi2Out'
};

module.exports.resolutions = {
    '180p': {
        width: 320,
        height: 180
    },
    '720p': {
        width: 1280,
        height: 720
    },
    '1080p': {
        width: 1920,
        height: 1080
    }
};

/**
 * All countries (Region 1 and Region 2, ordered)
 */
module.exports.countries = [
    {
        value: 'ad',
        text: 'Andorra'
    },
    {
        value: 'at',
        text: 'Austria'
    },
    {
        value: 'au',
        text: 'Australia'
    },
    {
        value: 'be',
        text: 'Belgium'
    },
    {
        value: 'ba',
        text: 'Bosnia and Herzegovina'
    },
    {
        value: 'bg',
        text: 'Bulgaria'
    },
    {
        value: 'ca',
        text: 'Canada'
    },
    {
        value: 'cn',
        text: 'China'
    },
    {
        value: 'hr',
        text: 'Croatia'
    },
    {
        value: 'cy',
        text: 'Cyprus'
    },
    {
        value: 'cz',
        text: 'Czech Republic'
    },
    {
        value: 'dk',
        text: 'Denmark'
    },
    {
        value: 'ee',
        text: 'Estonia'
    },
    {
        value: 'fi',
        text: 'Finland'
    },
    {
        value: 'fr',
        text: 'France'
    },
    {
        value: 'de',
        text: 'Germany'
    },
    {
        value: 'gr',
        text: 'Greece'
    },
    {
        value: 'hk',
        text: 'Hong Kong'
    },
    {
        value: 'hu',
        text: 'Hungary'
    },
    {
        value: 'in',
        text: 'India'
    },
    {
        value: 'ie',
        text: 'Ireland'
    },
    {
        value: 'it',
        text: 'Italy'
    },
    {
        value: 'jp',
        text: 'Japan'
    },
    {
        value: 'kw',
        text: 'Kuwait'
    },
    {
        value: 'lv',
        text: 'Latvia'
    },
    {
        value: 'li',
        text: 'Liechtenstein'
    },
    {
        value: 'lt',
        text: 'Lithuania'
    },
    {
        value: 'lu',
        text: 'Luxembourg'
    },
    {
        value: 'my',
        text: 'Malaysia'
    },
    {
        value: 'mt',
        text: 'Malta'
    },
    {
        value: 'mc',
        text: 'Monaco'
    },
    {
        value: 'me',
        text: 'Montenegro'
    },
    {
        value: 'nl',
        text: 'Netherlands'
    },
    {
        value: 'nz',
        text: 'New Zealand'
    },
    {
        value: 'no',
        text: 'Norway'
    },
    {
        value: 'om',
        text: 'Oman'
    },
    {
        value: 'pl',
        text: 'Poland'
    },
    {
        value: 'pt',
        text: 'Portugal'
    },
    {
        value: 'qa',
        text: 'Qatar'
    },
    {
        value: 'ro',
        text: 'Romania'
    },
    {
        value: 'ru',
        text: 'Russia'
    },
    {
        value: 'rs',
        text: 'Serbia'
    },
    {
        value: 'sg',
        text: 'Singapore'
    },
    {
        value: 'sk',
        text: 'Slovakia'
    },
    {
        value: 'si',
        text: 'Slovenia'
    },
    {
        value: 'sa',
        text: 'South Africa'
    },
    {
        value: 'es',
        text: 'Spain'
    },
    {
        value: 'se',
        text: 'Sweden'
    },
    {
        value: 'ch',
        text: 'Switzerland'
    },
    {
        value: 'th',
        text: 'Thailand'
    },
    {
        value: 'ae',
        text: 'United Arab Emirates'
    },
    {
        value: 'gb',
        text: 'United Kingdom'
    },
    {
        value: 'us',
        text: 'USA'
    }
];

/**
 * Region 1 Country listing
 */
module.exports.countriesRegion1 = [
    {
        value: 'au',
        text: 'Australia'
    },
    {
        value: 'ca',
        text: 'Canada'
    },
    {
        value: 'my',
        text: 'Malaysia'
    },
    {
        value: 'nz',
        text: 'New Zealand'
    },
    {
        value: 'us',
        text: 'USA'
    }
];

/**
 * Region 2 Country listing
 */
module.exports.countriesRegion2 = [
    {
        value: 'ad',
        text: 'Andorra'
    },
    {
        value: 'at',
        text: 'Austria'
    },
    {
        value: 'be',
        text: 'Belgium'
    },
    {
        value: 'ba',
        text: 'Bosnia and Herzegovina'
    },
    {
        value: 'bg',
        text: 'Bulgaria'
    },
    {
        value: 'cn',
        text: 'China'
    },
    {
        value: 'hr',
        text: 'Croatia'
    },
    {
        value: 'cy',
        text: 'Cyprus'
    },
    {
        value: 'cz',
        text: 'Czech Republic'
    },
    {
        value: 'dk',
        text: 'Denmark'
    },
    {
        value: 'ee',
        text: 'Estonia'
    },
    {
        value: 'fi',
        text: 'Finland'
    },
    {
        value: 'fr',
        text: 'France'
    },
    {
        value: 'de',
        text: 'Germany'
    },
    {
        value: 'gr',
        text: 'Greece'
    },
    {
        value: 'hk',
        text: 'Hong Kong'
    },
    {
        value: 'hu',
        text: 'Hungary'
    },
    {
        value: 'in',
        text: 'India'
    },
    {
        value: 'ie',
        text: 'Ireland'
    },
    {
        value: 'it',
        text: 'Italy'
    },
    {
        value: 'jp',
        text: 'Japan'
    },
    {
        value: 'kw',
        text: 'Kuwait'
    },
    {
        value: 'lv',
        text: 'Latvia'
    },
    {
        value: 'li',
        text: 'Liechtenstein'
    },
    {
        value: 'lt',
        text: 'Lithuania'
    },
    {
        value: 'lu',
        text: 'Luxembourg'
    },
    {
        value: 'mt',
        text: 'Malta'
    },
    {
        value: 'mc',
        text: 'Monaco'
    },
    {
        value: 'me',
        text: 'Montenegro'
    },
    {
        value: 'nl',
        text: 'Netherlands'
    },
    {
        value: 'no',
        text: 'Norway'
    },
    {
        value: 'om',
        text: 'Oman'
    },
    {
        value: 'pl',
        text: 'Poland'
    },
    {
        value: 'pt',
        text: 'Portugal'
    },
    {
        value: 'qa',
        text: 'Qatar'
    },
    {
        value: 'ro',
        text: 'Romania'
    },
    {
        value: 'ru',
        text: 'Russia'
    },
    {
        value: 'rs',
        text: 'Serbia'
    },
    {
        value: 'sg',
        text: 'Singapore'
    },
    {
        value: 'sk',
        text: 'Slovakia'
    },
    {
        value: 'si',
        text: 'Slovenia'
    },
    {
        value: 'sa',
        text: 'South Africa'
    },
    {
        value: 'es',
        text: 'Spain'
    },
    {
        value: 'se',
        text: 'Sweden'
    },
    {
        value: 'ch',
        text: 'Switzerland'
    },
    {
        value: 'th',
        text: 'Thailand'
    },
    {
        value: 'ae',
        text: 'United Arab Emirates'
    },
    {
        value: 'gb',
        text: 'United Kingdom'
    }
];
