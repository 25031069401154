const template = require('./edit-mode-annotation.html');
const app = require('../../../../app');
const vueUtils = require('../../../../components-vue/util');
const { quadraticFunction, reverseQuadraticFunction } = require('../../../../helper');
const Vuex = require('vuex');
const annotationIcons = require('./../../../../framebox-types').annotationIcons;

module.exports = {
    template,

    components: {
        'tool-select': require('./menu-item/tool-select/tool-select'),
        'color-select': require('./menu-item/color-select/color-select'),
        'range-input-annotation': require('./menu-item/range-input-annotation/range-input-annotation'),
        'annotation-plane': require('./annotation-plane/annotation-plane')
    },

    props: {
        content: {
            type: Object,
            required: true
        },
        planeCoordinates: {
            type: Object,
            required: true
        },
        freeze: null
    },

    data: function() {
        return {
            annotationActive: false,
            openMenuId: '',

            toolMenu: {
                id: 'tool',
                icon: annotationIcons['pen'],
                items: [
                    { id: 'pen', icon: annotationIcons['pen'] },
                    { id: 'rubber', icon: annotationIcons['rubber'] },
                    { id: 'circle', icon: annotationIcons['circle'] },
                    { id: 'rectangle', icon: annotationIcons['rectangle'] },
                    { id: 'line', icon: annotationIcons['line'] }
                ]
            },

            strengthMenu: {
                id: 'strength',
                icon: annotationIcons['strength'],
                minIcon: annotationIcons['strengthMin'],
                maxIcon: annotationIcons['strengthMax']
            },

            opacityMenu: {
                id: 'opacity',
                icon: annotationIcons['opacity'],
                minIcon: annotationIcons['opacityMin'],
                maxIcon: annotationIcons['opacityMax']
            },

            tool: 'pen',
            strength: 5,
            eraserStrength: 80,
            strengthStep: 5,
            opacity: 255,
            rgb: {
                red: 254,
                green: 65,
                blue: 97
            },
            settings: {
                undoOps: 0,
                redoOps: 0,
                clearAll: 'disable'
            },
            stateMatrix: {},
            users: [],
            currentUser: {},
            pinState: {},
            isScrollable: false,
            scrollPos: ''
        };
    },

    computed: {
        currentStrength: function() {
            const strength = this.tool === 'rubber' ? this.eraserStrength : this.strength;

            return Math.ceil(reverseQuadraticFunction(parseInt(strength)) / this.strengthStep) * this.strengthStep;
        },

        undoAvailable: function() {
            return this.settings.undoOps > 0;
        },

        redoAvailable: function() {
            return this.settings.redoOps > 0;
        },

        clearAllAvailable: function() {
            return this.settings.clearAll === 'enable';
        },

        planeIndex: function() {
            return this.annotationService.getPlaneIndex();
        },

        annotationEnabled: function() {
            return this.annotationService.isActive();
        },

        visibleCollabUsersCount: function() {
            return this.users.reduce((accumulator, currentVal) => accumulator + (currentVal.visible === 1 ? 1 : 0), 0);
        },

        isMatrixAnnotation: function() {
            return this.annotationService.isMatrixMasterAnnotation();
        },

        /**
         * Check if annotation collaboration is possible. In case of Matrix collaboration the stations can only
         * annotate over the whole output. Therefore we enable the collaboration menu only when the edited content
         * is in fullscreen or it is the whole output.
         */
        collaborationEnabled: function() {
            let matrixCollaborationPossible;

            if (this.content.appId) {
                matrixCollaborationPossible = this.getWindowsByAppId(this.content.appId)
                    .filter(w => w.outputPort === 'hdmi1' || w.outputPort === 'hdmi2')
                    .some(w => w.fullscreen);
            } else {
                matrixCollaborationPossible = this.content.outputPort === 'hdmi1' || this.content.outputPort === 'hdmi2';
            }

            return this.annotationEnabled && (this.isMatrixAnnotation && matrixCollaborationPossible
                || !this.isMatrixAnnotation);
        },

        ...Vuex.mapGetters('controlScreen', ['getWindowsByAppId', 'getOutputsInEdit'])
    },

    methods: {
        annotateContent: function(keepSubMenuOpen = false) {
            if (!keepSubMenuOpen) {
                this.closeSubMenus();
            }

            // Start annotation if not already running
            this.annotationService.startAnnotation();
            this.sendSettings();

            app.emit('main-loop.fast.start', {
                id: 'annotation'
            });

            this.fastPoll();

            // Set annotation to active -> annotation plane can receive inputs
            this.annotationActive = true;
        },

        editContent: function() {
            this.closeSubMenus();

            // Set freeze to off so the user can edit the content
            this.wolfprot.talk('setEditContentFreeze', {
                freeze: 'off'
            }).then(function() {
                app.emit('main-loop.fast.start', {
                    id: 'edit-mode'
                });
            }.bind(this));

            // Set annotation to inactive -> content in edit can receive inputs
            this.annotationActive = false;
        },

        handleSubMenus: function(menu) {
            this.openMenuId = menu.open ? menu.id : '';
        },

        switchToAnnotation: function(keepSubmenuOpen = false) {
            if (!this.annotationActive) {
                this.annotateContent(keepSubmenuOpen);
            } else {
                this.sendSettings();
            }
        },

        updateTool: function(tool) {
            this.tool = tool;

            this.sendSettings();
        },

        updateColor: function(rgb) {
            if (this.tool === 'rubber') {
                this.tool = 'pen';
            }

            this.rgb = rgb;
            this.switchToAnnotation();
        },

        updateStrength: function(val) {
            if (this.tool === 'rubber') {
                this.eraserStrength = quadraticFunction(parseInt(val));
            } else {
                this.strength = quadraticFunction(parseInt(val));
            }

            this.switchToAnnotation(true);
        },

        updateOpacity: function(val) {
            if (this.tool === 'rubber') {
                this.tool = 'pen';
            }

            this.opacity = val;
            this.switchToAnnotation(true);
        },

        undo: function() {
            this.closeSubMenus();

            this.annotationService.undo()
                .then(this.fastPoll.bind());
        },

        redo: function() {
            this.closeSubMenus();

            this.annotationService.redo()
                .then(this.fastPoll.bind());
        },

        takeSnapshot: function() {
            // TODO: if annotation is not active -> make snapshot of edit content (wpc needed)
            this.annotationService.takeSnapshot();
        },

        toggleFreeze: function() {
            this.wolfprot.talk('setEditContentFreeze', {
                freeze: 'toggle'
            }).then(function() {
                app.emit('main-loop.fast.start', {
                    id: 'edit-mode'
                });
            }.bind(this));
        },

        clearAll: function() {
            this.annotationService.clearAll()
                .then(this.fastPoll.bind(this));
        },

        resize: function() {
            this.closeSubMenus();
            this.checkIsScrollable();
        },

        scroll: function() {
            this.closeSubMenus();
            this.setScrollPos();
        },

        closeSubMenus: function() {
            this.openMenuId = '';
        },

        checkIsScrollable: function() {
            const { menu, wrapper } = this.$refs;

            if (!menu || !wrapper) {
                return;
            }

            this.isScrollable = menu.scrollHeight > wrapper.clientHeight;

            if (this.isScrollable) {
                this.setScrollPos();
            } else {
                this.scrollPos = '';
            }
        },

        setScrollPos: function() {
            const menu = this.$refs.menu;

            if (menu.scrollTop === 0) {
                this.scrollPos = 'top';
            } else if (menu.scrollHeight - Math.round(menu.scrollTop) === menu.clientHeight) {
                this.scrollPos = 'bottom';
            } else {
                this.scrollPos = 'between';
            }
        },

        sendSettings: function() {
            const settings = {
                tool: this.tool,
                red: this.rgb.red,
                green: this.rgb.green,
                blue: this.rgb.blue,
                alpha: this.opacity,
                penSize: this.strength,
                eraserStrength: this.eraserStrength
            };

            this.annotationService.sendSettings(settings);
        },

        fastPoll: function() {
            app.emit('main-loop.fast.start', {
                id: 'edit-mode-annotation'
            });
        },

        openCollabUsers: function() {
            app.emit('modal.open', {
                id: 'collaboration-users',
                users: this.users,
                currentUser: this.currentUser,
                pinState: this.pinState
            });
        }
    },

    created: function() {
        this.annotationService = app.getService('AnnotationService');
        this.wolfprot = vueUtils.wolfprot();

        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return this.wolfprot.talkMulti([
                    {
                        command: 'getAnnotationSettings',
                        data: {
                            planeIndex: this.planeIndex
                        }
                    },
                    'getMatrixMasterAnnotationActive',
                    {
                        command: 'getAnnotationUsers',
                        data: {
                            planeIndex: this.planeIndex,
                            allUsers: true
                        }
                    },
                    {
                        command: 'getAnnotationUsers',
                        data: {
                            planeIndex: this.planeIndex,
                            allUsers: false
                        }
                    },
                    'getAnnotationPinStatus'
                ]).then(function([settings, stateMatrix, users, currentUser, pinState]) {
                    return {
                        settings,
                        stateMatrix,
                        users,
                        currentUser,
                        pinState
                    };
                }.bind(this));
            }.bind(this)
        });
        this.pollHelper.on('data', function({ settings, stateMatrix, users, currentUser, pinState }) {
            this.settings = settings;
            this.stateMatrix = stateMatrix;
            this.users = users.userList;
            this.currentUser = currentUser.userList[0];
            this.pinState = pinState;
        }.bind(this));
        this.pollHelper.schedulePoll();

        this.evctx = vueUtils.eventContext();
        this.evctx.on('main-loop.update', function() {
            this.pollHelper.schedulePoll();
        }.bind(this));
        this.evctx.on('main-loop.update.edit-mode-annotation', function() {
            this.pollHelper.schedulePoll();
        }.bind(this));
        this.evctx.on('livestream-size.update', this.resize.bind(this));
        this.evctx.on('aspect-ratio.changed', this.resize.bind(this));
    },

    mounted: function() {
        this.checkIsScrollable();
    }
};
