'use strict';

var app = require('../../app');
var $ = require('jquery');
var _ = require('lodash');
var timelineTpl = require('./timeline.hbs');
var dateLib = require('../../date.js');

var START_HOUR = 6;
var END_HOUR = 18;

app.component('Timeline', {
    className: 'full-height',
    template: timelineTpl,

    getAccessKey: function() {
        return {
            'roleName': 'vMeeting',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.sessionMgmtService = this.getService('SessionManagementService');

        this.times = [];
    },

    serialize: function() {
        var dfd = $.Deferred();

        this.fetchData(dfd);

        return dfd.promise();
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();
        this.positionMeetings();
        this.positionCurrentTime();
        this.updateFocusedMeeting();
    },

    storeSelectors: function() {
        this.$timeLineCurrent = this.$el.find('#time-line-current');
    },

    bindEvents: function() {
        this.on('main-loop.update', this.updateHandler.bind(this));
        this.on('session-mgmt.meeting-list.focus.changed', this.updateFocusedMeeting.bind(this));
    },

    updateHandler: function() {
        var offset = this.sessionMgmtService.getTimezoneOffset();
        var currentTime = this.sessionMgmtService.getDateTime();
        var currentTs = Math.round(dateLib.convertToUnixTimestamp(currentTime, offset));

        this.positionCurrentTime(currentTs);
        this.highlightCurrentTime(currentTime);
        this.highlightMeeting(currentTs);
    },

    /**
     * Highlight current focused meeting in timeline when scrollin in list.
     */
    updateFocusedMeeting: function() {
        var index = this.sessionMgmtService.getFocusedMeetingIndex();
        this.$el.find('.meeting-line').removeClass('has-focus');

        if (index >= 0) {
            this.$el.find('#meeting-' + index).addClass('has-focus');
        }
    },

    /**
     * Position current time line and highlight time and current meeting in timeline.
     *
     * @param currentTime Current timestamp.
     */
    positionCurrentTime: function(currentTs) {
        this.$timeLineCurrent.css('top', 100 / this.timelineHeight * (currentTs - this.startTs) + '%');
    },

    /**
     * Highlight current time.
     *
     * @param currentTime Current date time.
     */
    highlightCurrentTime: function(currentTime) {
        this.$el.find('.time-container').removeClass('highlighted');

        if (currentTime.minute === 0) {
            this.$el.find('#time-' + currentTime.hour).addClass('highlighted');
        }
    },

    /**
     * Highlight meeting.
     *
     * @param currentTs Current time stamp.
     */
    highlightMeeting: function(currentTs) {
        _.each(this.meetings, function(meeting) {
            if (currentTs >= parseInt(meeting.start) && currentTs <= parseInt(meeting.end)) {
                this.positionMeeting(parseInt(meeting.start), currentTs, 'current');
            }
        }.bind(this));
    },

    /**
     * Fetch data and initialize start and end time, time line and meetings.
     *
     * @param dfd
     */
    fetchData: function(dfd) {
        this.sessionMgmtService.updateHandler()
            .then(function() {
                this.initStartEndTime();
                this.initTimes();
                this.initMeetings();

                dfd.resolve({
                    times: this.times,
                    meetings: this.meetings,
                    darkUi: this.sessionMgmtService.darkUi
                });
            }.bind(this));
    },

    /**
     * Init start and end time for today in unix timestamp format.
     */
    initStartEndTime: function() {
        var startDate;
        var endDate;
        startDate = endDate = this.sessionMgmtService.getDate();

        startDate.hour = START_HOUR - 1;
        startDate.minute = 0;

        this.startTs = dateLib.convertToUnixTimestamp(startDate);

        endDate.hour = END_HOUR + 1;
        endDate.minute = 0;

        this.endTs = dateLib.convertToUnixTimestamp(endDate);

        this.timelineHeight = this.endTs - this.startTs;
    },

    /**
     * Init times to show on time line with between defined start and end time.
     */
    initTimes: function() {
        var dateFormat = this.sessionMgmtService.getDateFormat();
        var times = [];
        var i;

        for (i = START_HOUR; i <= END_HOUR; i++) {
            times.push({
                index: i,
                name: dateLib.formatTime(dateFormat, {
                    hour: i,
                    minute: 0
                }, true),
                time: i
            });
        }

        this.times = times;
    },

    /**
     * Position meetings from list.
     */
    positionMeetings: function() {
        _.each(this.meetings, function(meeting) {
            this.positionMeeting(parseInt(meeting.start), parseInt(meeting.end), meeting.index);
        }.bind(this));
    },

    /**
     * Position meeting.
     *
     * @param startTs Start time (timestamp)
     * @param endTs End time (timestamp)
     * @param index Meeting index
     */
    positionMeeting: function(startTs, endTs, index) {
        var top = 100 / this.timelineHeight * (startTs - this.startTs);
        var height = 100 / this.timelineHeight * (endTs - startTs);

        this.$el.find('#meeting-' + index).css('top', top + '%');
        this.$el.find('#meeting-' + index).css('height', height + '%');
    },

    /**
     * Init meetings.
     */
    initMeetings: function() {
        var meetingList = this.sessionMgmtService.getMeetings();
        var meetings = [];
        var i = 0;

        _.each(meetingList, function(meeting) {
            if (meeting.id !== 'Ad-Hoc-Meeting') {
                meetings.push({
                    index: i,
                    meetingId: meeting.id,
                    start: meeting.start,
                    end: meeting.end
                });
            }

            i++;
        }.bind(this));

        this.meetings = meetings;
    }
});
