'use strict';

var app = require('../../../../app');

var controlItems = [
    {
        id: 'pin',
        type: 'pin',
        onlyFullscreen: true
    }
];

/**
 * Video frame
 */
app.component('FrameBoxWhiteboardViewer', {
    extend: 'FrameBoxBaseType',
    className: 'framebox-view framebox-whiteboard-view',
    prevInputValue: null,
    currentInputType: 'notext',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.clickTimeout = null;
        this.hasManualClick = false;
        this.frameBoxService = this.getService('FrameBoxService');
        this.remote = this.getService('RemoteService');
        this.keyEventHandlerService = this.getService('KeyEventHandlerService');

        this.options.allowedEvents = ['touchstart', 'touchend', 'mousedown', 'mouseup', 'touchmove', 'mousemove'];
    },

    postPlaceAt: function() {
        this.bindEvents();
        this.startTouchEventsEntity({
            $actionEl: this.options.$actionEl,
            allowedEvents: this.options.allowedEvents,
            disableSelection: false,
            debugging: false
        });

        this.startControls();

        if (window.navigator.standalone) {
            this.options.$actionEl.addClass('hide-keyboard-btn');
        }
    },

    bindEvents: function() {
        this.on('framebox.change.' + this.index, this.onFrameboxChanged.bind(this));
        this.on('framebox.standard.keydown', this.keyDownHandler.bind(this));
        this.on('framebox.standard.keyup', this.keyUpHandler.bind(this));
        this.on('framebox.standard.mouseevent', this.onMouseEvent.bind(this));
        this.on('active-framebox.changed', this.activeFrameboxChanged.bind(this));
    },

    /**
     * Initialize Keyboard after active framebox changed
     *
     * @param index framebox index
     * @param keyboardAction focus is on an input field --> update keyboard
     */
    activeFrameboxChanged: function(index, component, keyboardAction) {
        if (index === this.options.index) {
            if (!this.keyboard) {
                this.startKeyboard();

                this.onFrameboxChanged({
                    contentType: this.frameBoxService.contentTypeMapping(this.options.type),
                    options: this.options.frameBoxOptions
                });

                return;
            }

            if (keyboardAction) {
                this.keyboardAction('update', this.box);
            }
        }
    },

    /**
     * @param {Object} box
     */
    onFrameboxChanged: function(box) {
        this.box = box;

        var hasChangedKeyboard = box.options.inputType !== this.currentInputType || this.hasManualClick;

        if (this.keyboardAction && hasChangedKeyboard) {
            this.currentInputType = box.options.inputType;
            this.keyboardAction('update', box);
        }
    },

    /**
     * Called when a mouse or touch event has been triggered on active framebox.
     *
     * @param {jQuery.MouseEvent} event
     * @param {Number} index
     */
    onMouseEvent: function(event, index) {
        if (index !== this.index) {
            return;
        }

        this.hasManualClick = true;
        clearTimeout(this.clickTimeout);
        this.clickTimeout = setTimeout(function() {
            this.hasManualClick = false;
        }.bind(this), 300);
    },

    /**
     * @param {Object} event
     * @param {Number} index
     */
    keyDownHandler: function(event, index) {
        this.keyEventHandler(index, 'keydown', event);
    },

    /**
     * @param {Object} event
     * @param {Number} index
     */
    keyUpHandler: function(event, index) {
        this.keyEventHandler(index, 'keyup', event);
    },

    /**
     * @param {Number} index
     * @param {String} keyEvent
     * @param {Object} event
     */
    keyEventHandler: function(index, keyEvent, event) {
        if (index === this.index) {
            this.frameBoxService.sendKeyEvent(this.options.index, keyEvent, event);
        }
    },

    startControls: function() {
        this.createComponent({
            type: 'FrameBoxControls',
            container: this.$el,
            index: this.options.index,
            appId: this.options.appId,
            items: this.options.isFixed ? controlItems.filter(i => i.id !== 'pin') : controlItems,
            isFullscreen: this.options.isFullscreen
        });
    },

    /**
     * Start Keyboard Component.
     */
    startKeyboard: function() {
        this.keyboard = this.createComponent({
            type: 'FrameboxKeyboard',
            container: this.$el,
            index: this.options.index,
            action: function(fn) {
                this.keyboardAction = fn;
            }.bind(this)
        });
    },

    destroy: function() {
        if (this.keyboardAction) {
            this.keyboardAction('close');
        }

        this.stopTouchEventsEntitiy();

        this.$('html, body').scrollTop(0);
        this.$(document).off('mousedown.keyboard-' + this.index);
        this.$(document).off('touchstart.keyboard-' + this.index);
    }
});
