'use strict';

var app = require('../../app');
var platform = require('../../../platform/platform');
var matrixTpl = require('./matrix.hbs');
var _ = require('lodash');

app.component('Matrix', {
    template: matrixTpl,

    initialize: function() {
        this.matrixConfig = this.getService('MatrixConfigs');
        this.matrixMainService = this.getService('MatrixMainService');

        this.templateId = this.options.configs.templateId;

        // NOTE: if templateId === undefined, template is already loaded.
        if (typeof this.templateId !== 'undefined') {
            this.matrixConfig.changeMatrixTemplate(this.templateId, false);
        }
    },

    serialize: function() {
        return {
            isIE: platform.checks.isIe,
            noTemplate: this.options.configs.noTemplate
        };
    },

    /**
     * Create components for configurator or main grid.
     */
    createComponents: function() {
        this.createComponent({
            type: 'MatrixBox',
            container: this.$matrixBox,
            isConfigurator: this.options.configs.isConfigurator,
            matrixBox: this.options.configs.matrixBox
        });

        if (this.options.configs.isConfigurator) {
            this.createComponent({
                type: 'MatrixBox',
                container: this.$matrixSelect,
                isConfigurator: this.options.configs.isConfigurator,
                matrixBox: 'configs',
                items: this.parseConfigurationItems()
            });

            this.createComponent({
                type: 'MatrixConfiguratorGrid',
                container: this.$matrixConfiguratorGrid
            });
        } else {
            this.createComponent({
                type: 'MatrixMenu',
                container: this.$matrixMenu
            });

            this.createComponent({
                type: 'MatrixMainGrid',
                container: this.$matrixMainGrid
            });
        }
    },

    /**
     * Parse templates / configurations for list.
     *
     * @returns {Array}
     */
    parseConfigurationItems: function() {
        var items = [];

        _.each(this.matrixConfig.matrixTemplates, function({ id, name }) {
            items.push({
                id,
                name,
                checked: id === this.matrixConfig.getCurrentMatrixTemplateId(),
                callback: function(item) {
                    if (item.id !== this.matrixConfig.getCurrentMatrixTemplateId()) {
                        this.matrixConfig.changeMatrixTemplate(item.id, this.options.configs.isConfigurator);
                    }

                    this.emit('matrix-box.hide');
                }.bind(this),
                itemType: 'singleChoice'
            });
        }.bind(this));

        return items;
    },

    postPlaceAt: function() {
        if (platform.checks.isIe || this.options.configs.noTemplate) {
            return;
        }

        this.storeSelectors();
        this.bindEvents();
        this.bindDOMEvents();
        this.createComponents();

        this.emit('overlay.backdrop.hide');
    },

    storeSelectors: function() {
        this.$matrixBox = this.$el.find('#matrix-box-component-container');
        this.$matrixSelect = this.$el.find('#matrix-select-component-container');
        this.$matrixMenu = this.$el.find('#matrix-menu-component-container');
        this.$matrixConfiguratorGrid = this.$el.find('#matrix-configurator-grid');
        this.$matrixMainGrid = this.$el.find('#matrix-main-grid');
        this.$matrixBackdrop = this.$el.find('#matrix-backdrop');
    },

    bindEvents: function() {
        this.on('overlay-backdrop.show', this.showMatrixBackdrop.bind(this));
        this.on('overlay-backdrop.hide', this.hideMatrixBackdrop.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.overlay-backdrop', this.onBackdropClickHandler.bind(this));
    },

    /**
     * Show matrix backdrop.
     *
     * @param invisible To avoid click events, show backdrop but invisible
     */
    showMatrixBackdrop: function(invisible) {
        if (invisible) {
            this.$matrixBackdrop.addClass('invisible');
        }

        this.$matrixBackdrop.show();
    },

    /**
     * Hide matrix backdrop.
     */
    hideMatrixBackdrop: function() {
        if (this.matrixMainService.showAllPreviews) {
            return;
        }

        this.$matrixBackdrop.hide();

        this.$matrixBackdrop.removeClass('invisible');
    },

    /**
     * Handle on backdrop click and close matrix box.
     */
    onBackdropClickHandler: function() {
        this.emit('overlay-backdrop.click');
    }
});
