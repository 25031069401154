/**
 * Magic pen strength
 */
const strengthMapping = {
    'light': 10,
    'medium': 30,
    'strong': 60
};

/**
 * Pen opacity
 */
const opacityMapping = {
    'opaque': 255,
    'transparent': 150
};

module.exports = {
    strengthMapping,
    opacityMapping
};
