'use strict';

var app = require('../../../app');
var stationFilesMenu = require('./files-menu.hbs');
var $ = require('jquery');
var _ = require('lodash');

app.component('MatrixFilesMenu', {
    template: stationFilesMenu,

    getAccessKey: function() {
        return {
            'roleName': 'MatrixMain',
            'roleKey': 'show'
        };
    },

    initialize: function(options) {
        this.matrixService = this.getService('MatrixService');
        this.configs = this.getService('MatrixConfigs');
        this.options = options;
    },

    serialize: function() {
        var dfd = $.Deferred();
        this.getFileList()
            .then(function() {
                dfd.resolve({
                    id: this.options.id,
                    files: this.files
                });
            }.bind(this));

        return dfd.promise();
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindDOMEvents();

        this.setPosition();
    },

    storeSelectors: function() {
        this.$container = this.$el.find('.matrix-files-menu-container');
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.btn-unshare', this.unshareFile.bind(this));
    },

    /**
     * Set position of stations files menu.
     */
    setPosition: function() {
        var width = $(document).find('.svg-grid').width();
        var height = $(document).find('.svg-grid').height();
        var docBtn = $(document).find('.btn-docs[data-id=' + this.options.componentId + ']');

        var docBtnSize = this.matrixService.convertCoordinates(
            {
                width: parseInt(docBtn.attr('width')),
                height: parseInt(docBtn.attr('height'))
            },
            width, height,
            this.configs.get('dimensions.width'), this.configs.get('dimensions.height')
        );

        var pos = this.matrixService.convertCoordinates(
            {
                x: this.options.cx,
                y: this.options.cy
            },
            width, height,
            this.configs.get('dimensions.width'), this.configs.get('dimensions.height')
        );

        // Standard
        this.$container
            .css('left', pos.x - 45)
            .css('top', pos.y + (docBtnSize.height / 2));

        if (pos.y + this.$container.height() > height) {
            this.$container.find('.files-menu-triangle-top').css('visibility', 'hidden');
            this.$container.find('.files-menu-triangle-bottom').css('visibility', 'visible');
            this.$container
                .css('top', pos.y - this.$container.height() - (docBtnSize.height / 2));
        }

        if (pos.x + this.$container.width() > width) {
            this.$container.find('.files-menu-items')
                .css('margin-left', this.$container.width() * -1 + 90);
        }
    },

    /**
     * Unshare file / remove file from station.
     *
     * @param event Event
     */
    unshareFile: function(event) {
        var file = event.currentTarget.parentElement;
        var fileMenu = file.parentElement;

        this.matrixService.unshareFile(this.options.serial, file.getAttribute('data-name'));
        file.remove();

        if (fileMenu.children.length === 0) {
            this.app.removeComponent(this.options.id);
        }
    },

    /**
     * Get shared files / get file list from current station.
     */
    getFileList: function() {
        var dfd = $.Deferred();
        var dir = this.configs.get('filesharing.path') + this.options.serial;

        this.matrixService.getFiles(dir)
            .then(function(data) {
                this.files = this.parseFileList(data.fileList, dir);

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Parse file list.
     *
     * @param files File list to parse
     * @param path Current file sharing path (Normally matrix-folder on master and serial nr of current station)
     * @returns {Array} File list to show in UI
     */
    parseFileList: function(files, path) {
        var fileList = [];

        _.each(files, function(file) {
            var f = {
                name: file.name,
                path: path + '/' + file.name,
                type: (file.type === 'dir') ? '' : file.type,
                icon: (this.configs.get('iconMapping.' + [file.type])) || 'icon-blocked'
            };

            fileList.push(f);
        }.bind(this));

        return fileList;
    }

});
