'use strict';

var $ = require('jquery');
var app = require('../../../app');
var generalSettingsTpl = require('./general-settings.hbs');
var FormActionView = require('../form-action-view');
var platform = require('../../../../platform/platform');
var timezones = require('timezones');
var dateLib = require('../../../date');
var i18n = require('i18next');

/**
 * Timeserver states
 * @type {{none: string, testing: string, failed: string, success: string}}
 */
var timeserverTestStatus = {
    none: 'none',
    testing: 'testing',
    failed: 'failed',
    success: 'success'
};

/**
 * Statusbar network interface states (Show interface ip on statusbar)
 * @type {{nothing: string, lan1: string, wlan: string, both: string}}
 */
var statusbarNetworkInterface = {
    nothing: 'nothing',
    lan1: 'lan',
    wlan: 'wlan',
    both: 'both'
};

app.component('GeneralSettings', {
    template: generalSettingsTpl,
    actionView: null,
    pollTimeDataTimeout: null,

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.validate = this.getService('ValidateService');
        this.keyboard = this.getService('KeyboardService');
        this.frontendSettings = this.getService('FrontendSettings');
        this.customUiSettings = this.getService('CustomUiSettings');
        this.language = this.getService('LanguageService');
        this.deviceService = this.getService('DeviceService');
        this.isDualProjection = this.deviceService.isCboxProDualProjection();
        this.streaming = this.getService('Streaming');
        this.outputService = this.getService('OutputService');
        this.stopPolling = false;
        this.languageChanged = false;
        this.keyboardLayoutChanged = false;
        this.streamingIsValid = true;

        this.init = true;

        this.pollFactory = this.getService('PollFactoryService');
        this.timeserverTestPolling = null;
        this.timeserverTest = this.createStateMachine({
            state: timeserverTestStatus.none,
            states: timeserverTestStatus
        });
    },

    postPlaceAt: function() {
        this.createSelects();
        this.initForm();
        this.storeSelectors();

        $.when(this.loadGeneralSettings()).done(function() {
            this.initFormAction();
            this.bindEvents();
            this.bindDOMEvents();
            this.handleTimeserverChange();
            this.handleStatusbarChange();
            this.handleVLinkProDiscoveryModeChange();
            this.handleEddystoneChange();

            if (!this.isDualProjection) {
                this.handlePinHdmi2();
            }
            if (!this.deviceService.isCboxPureReceiver()) {
                this.handlePinDynamic(this.form.get('pinDynamicEnabled').getValue());
            }

            this.pollTimeData();
        }.bind(this));
    },

    serialize: function() {
        return {
            isCboxPureOrPro: this.deviceService.isCboxPureOrPro(),
            isCboxPure: this.deviceService.isCboxPure(),
            isCboxPureReceiver: this.deviceService.isCboxPureReceiver(),
            isCboxPureMini: this.deviceService.isCboxPureMini(),
            isCboxPro: this.deviceService.isCboxPro(),
            isDualProjection: this.deviceService.isCboxProDualProjection(),
            pinFunctions: function() {
                let functions = [
                    'AirPlay',
                    'Miracast',
                    'vSolution Cast'
                ];
                if (!this.deviceService.isCboxPureMini()) {
                    functions.push('Viewer');
                }

                return functions.join(', ');
            }.bind(this).call()
        };
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    storeSelectors: function() {
        this.$timeserverTestState = this.$el.find('#timeserver-test-state');
        this.$testBtn = this.$el.find('.timeserver-test-action');
        this.$statusBarSettings = this.$el.find('.status-bar-settings-container');
        this.$eddystoneInterfaceContainer = this.$el.find('#eddystone-interface-container');
        this.$vLinkProServerIpContainer = this.$el.find('#vlink-pro-server-ip-container');
        this.$managementServerState = this.$el.find('#management-server-connection-state');
        this.$addNewDirectLink = this.$el.find('#add-new-direct-link');
        this.$pinHdmi2 = this.$el.find('.pin-hdmi2');
        this.$pinDynamicEnabled = this.$el.find('.pin-dynamic-enabled');
        this.$pinResetTime = this.$el.find('#pin-reset-time');
        this.$pinSize = this.$el.find('#pin-size');
    },

    bindEvents: function() {
        this.on('remote.enter.keydown', this.checkActionForm.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
        this.on('main-loop.update', this.updateHandler.bind(this));
    },

    bindDOMEvents: function() {
        this.form.get('timeserver').$el.on('change', this.handleTimeserverChange.bind(this));
        this.form.get('statusbar').$el.on('change', this.handleStatusbarChange.bind(this));
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.$el.on('click', '.timeserver-test-action', this.testTimeserver.bind(this));
        this.form.get('eddystoneEnable').$el.on('change', this.handleEddystoneChange.bind(this));
        this.$addNewDirectLink.on('click', this.addDirectLink.bind(this));
        this.$el.on('click', '.delete-btn-list-item', this.deleteBrowserLink.bind(this));
    },

    /**
     * Handle timeserver change.
     */
    handleTimeserverChange: function() {
        var value = this.form.get('timeserver').getValue();

        if (value) {
            this.disableDateTime();
            this.$testBtn.prop('disabled', false);
        } else {
            this.enableDateTime();
            this.$testBtn.prop('disabled', true);
        }
    },

    /**
     * Handle status bar change.
     */
    handleStatusbarChange: function() {
        var value = this.form.get('statusbar').getValue();

        if (value) {
            this.$statusBarSettings
                .stop()
                .slideDown(300);
        } else {
            this.$statusBarSettings
                .stop()
                .slideUp(230);
        }
    },

    /**
     * Handle Eddystone settings.
     */
    handleEddystoneChange: function() {
        var value = this.form.get('eddystoneEnable').getValue();

        if (value) {
            this.$eddystoneInterfaceContainer
                .stop()
                .slideDown();

            return;
        }

        this.$eddystoneInterfaceContainer
            .stop()
            .slideUp();
    },

    updateHandler: function() {
        this.deviceConnection
            .send([
                'getManagementServerSubscribeStatus',
                'getVLinkProDiscoverySettings'
            ])
            .then(function(subscribeStatus, settings) {
                var status = subscribeStatus.status;

                if (settings.mode === 'dhcp') {
                    if (subscribeStatus.server !== this.form.get('vLinkProServerIp').getValue()
                        && this.form.get('managementServerDhcp').getValue()) {
                        this.form.get('vLinkProServerIp').setValue(subscribeStatus.server);

                        if (this.init) {
                            this.form.setDefaultValues();
                            this.init = false;
                        }
                    }
                } else if (subscribeStatus.server === '') {
                    status = 'off';
                }

                this.$managementServerState.attr('data-management-server-connection-state', status);
            }.bind(this));
    },

    /**
     * Handle vLink Pro discovery mode change (Show/hide server IP field).
     */
    handleVLinkProDiscoveryModeChange: function() {
        if (!this.form.get('managementServerDhcp').getValue()) {
            this.$vLinkProServerIpContainer.find('#vLinkProServerIp').attr('data-allow-empty', 'true');
            this.$vLinkProServerIpContainer.find('#vLinkProServerIp').removeAttr('disabled');
        } else {
            this.$vLinkProServerIpContainer.find('#vLinkProServerIp').attr('data-allow-empty', 'true');
            this.$vLinkProServerIpContainer.find('#vLinkProServerIp').attr('disabled', '');
        }

        if (this.actionView) {
            this.checkActionForm();
        }
    },

    saveHandler: function() {
        var reload = false;

        if (this.form.validate()) {
            this.saveGeneralSettings(this.form.serialize())
                .done(function() {
                    this.handleSettingsSave();
                    this.pollTimeData();
                    this.form.setDefaultValues();
                    this.emit('overlay.remote.focus', true);
                    this.loadTimeData();

                    if (this.languageChanged) {
                        this.language.setLanguage(this.form.get('language').getValue());
                        reload = true;
                    }

                    if (this.keyboardLayoutChanged) {
                        this.keyboard.destroy();
                        this.keyboard.start();
                        reload = true;
                    }

                    if (reload) {
                        window.location.reload();
                    }

                    this.emit('save-changes.finished');
                }.bind(this));
        }
    },

    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);

        this.form.resetValues();
        this.$el.find('#restart-info').addClass('hidden');
        this.$el.find('#language-select').find('.success-message').html(i18n.t(''));
        this.$el.find('#keyboard-layout-select').find('.success-message').html(i18n.t(''));
        this.$el.find('.statusbar-info').hide();

        if (!this.form.get('timeserver').getValue()) {
            this.form.get('time').enable();
            this.form.get('timezone').enable();
        }

        this.animateHideInfoMessages();
        this.pollTimeData();
        this.languageChanged = false;
        this.keyboardLayoutChanged = false;

        this.changes = false;
    },

    handleSettingsSave: function() {
        app.emit('main-loop.fast.start', {
            id: 'volume'
        });

        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.$el.find('#restart-info').addClass('hidden');

        if (this.$el.find('.statusbar-info').css('display') !== 'none') {
            window.location.reload();
        }

        this.$el.find('.statusbar-info').hide();

        if (!this.form.get('timeserver').getValue()) {
            this.form.get('time').enable();
            this.form.get('timezone').enable();
        }

        this.animateHideInfoMessages();
        this.changes = false;
    },

    /**
     * Disable date time field.
     */
    disableDateTime: function() {
        this.form.get('date').disable();
        this.form.get('time').disable();
        this.form.get('url').enable();
    },

    /**
     * Enable date time field.
     */
    enableDateTime: function() {
        this.form.get('date').enable();
        this.form.get('time').enable();
        this.form.get('url').disable();
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#general-settings'),
            validationContainer: '.input-group'
        });
    },

    /**
     * Handle form change.
     * @param data
     */
    handleFormChange: function(data) {
        this.actionView.open();

        if (data.input && data.input.name === 'boxname') {
            this.$el.find('#restart-info').removeClass('hidden');
        } else if (data.input && data.input.name === 'language') {
            this.$el.find('#language-select').find('.success-message').html(i18n.t('settings.language_warning'));
            this.languageChanged = true;
        } else if (data.input && data.input.name === 'keyboard-layout') {
            this.$el.find('#keyboard-layout-select').find('.success-message').html(i18n.t('settings.keyboard_layout_warning'));
            this.keyboardLayoutChanged = true;
        } else if (data.input && data.input.name === 'statusbar' && !platform.checks.isCbox) {
            this.$el.find('.statusbar-info').show();
        } else if (data.input && data.input.name === 'managementServerDhcp') {
            this.handleVLinkProDiscoveryModeChange();
        } else if (data.input && data.input.name === 'pinDynamicEnabled') {
            this.handlePinDynamic(data.$el.is(':checked'));
        } else if (data.input && data.input.name === 'dualProjectionEnable') {
            this.animateShowInfoMessages(data.input.name);
        }

        if (!!data.input && data.input.name === 'time') {
            this.stopPolling = true;
            this.form.get('timezone').disable();
        } else if (!!data.input && data.input.name === 'timezone') {
            this.stopPolling = true;
            this.form.get('time').disable();
        } else if (!!data.input && (data.input.name === 'date' || data.input.name === 'timeformat')) {
            this.stopPolling = true;
        } else {
            this.stopPolling = false;
        }

        this.changes = true;

        this.checkActionForm();
    },

    handlePinHdmi2: function() {
        if (this.outputService.isModerator()) {
            this.$pinHdmi2
                .stop()
                .slideDown();

            this.$pinHdmi2.removeClass('hidden');
        } else {
            this.$pinHdmi2
                .stop()
                .slideUp();

            this.$pinHdmi2.addClass('hidden');
        }
    },

    handlePinDynamic: function(enabled) {
        if (enabled) { // Open mode
            this.$pinResetTime
                .stop()
                .slideDown();

            this.$pinResetTime.removeClass('hidden');
        } else {
            this.$pinResetTime
                .stop()
                .slideUp();

            this.$pinResetTime.addClass('hidden');
        }
    },

    checkActionForm: function() {
        if (!!this.form.validate() && this.streamingIsValid) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * Load general settings.
     */
    loadGeneralSettings: function() {
        return $.when(this.loadBackendSettings())
            .then(this.loadFrontendSettings.bind(this))
            .then(this.loadCustomUiSettings.bind(this))
            .done(this.updateForm.bind(this));
    },

    /**
     * Polls the current Time from Time-Server.
     * Timeserver connection has a timeout after 3seconds.
     *
     * @param {int} index How many polls should we make, in a delay of 1seconds.
     */
    pollTimeData: function() {
        if (this.componentState.getState() === this.componentStates.destroyed) {
            return;
        }

        this.pollTimeDataTimeout = setTimeout(function() {
            // Stop polling after time or timezone is changed
            if (this.stopPolling) {
                return;
            }

            this.loadTimeData();
            this.pollTimeData();
        }.bind(this), 1000);
    },

    /**
     * Load Timedata from CYNAP.
     */
    loadTimeData: function() {
        this.deviceConnection
            .send([
                'getTimeDate',
                'getDateFormat'
            ])
            .then(this.onTimeDataLoaded.bind(this));
    },

    /**
     * Set Timedata to input field.
     *
     * @param {object} dateTime
     */
    onTimeDataLoaded: function(dateTime, dateFormat) {
        var date = dateLib.formatDate(dateFormat.dateFormat, dateTime);
        var time = dateLib.formatTime(dateFormat.dateFormat, dateTime);

        this.formData.date = date;
        this.formData.time = time;

        this.form.get('date').setValue(date);
        this.form.get('time').setValue(time);
    },

    /**
     * Load backend settings.
     * @returns {jQuery}
     */
    loadBackendSettings: function() {
        var dfd = $.Deferred();

        this.emit('main-loop.start', {
            id: 'remote'
        });

        this.deviceConnection
            .send([
                'getBoxName',
                'getTimeserverUrl',
                'getTimeDate',
                'getTimeSource',
                'getTimezone',
                'getDateFormat',
                'getLocalTouchKeyboard',
                'getSystemLanguage',
                'getBrowserShortcutsConfig',
                'getURLInterface',
                'getKeyboardLayout',
                'getPowerDownMode',
                'getEddystoneDiscovery',
                'getEddystoneInterface',
                'getVLinkProDiscoverySettings',
                'getMDNSDiscovery',
                'getMirrorPinDynamic',
                'getDualProjectionEnable'
            ])
            .then(function(boxName, timeserverUrl, dateTime, timeSource, timezone, dateFormat, touchKeyboard, language,
                browserShortcutsConfig, urlInterface, keyboardLayout, powerDownMode, eddystoneDiscovery, eddystoneInterface,
                vLinkProDiscovery, mdnsDiscovery, pinDynamic, dualProjection) {
                var date = dateLib.formatDate(dateFormat.dateFormat, dateTime);
                var time = dateLib.formatTime(dateFormat.dateFormat, dateTime);

                this.browserShortcutCount = browserShortcutsConfig.length;
                this.updateBrowserFields();

                this.formData = {
                    'boxname': boxName.boxName,
                    'url': timeserverUrl.url,
                    'date': date,
                    'time': time,
                    'timeserver': timeSource.timeSource,
                    'timeformat': dateFormat.dateFormat,
                    'timezone': timezone.timezoneIndex,
                    'keyboard': touchKeyboard.enabled,
                    'statusbar': null,
                    'statusbarLan1': false,
                    'statusbarLan2': false,
                    'statusbarWlan': false,
                    'statusbarSsid': false,
                    'language': language.language,
                    'browserName0': browserShortcutsConfig[0].name,
                    'browserName1': (browserShortcutsConfig[1] || {}).name || '',
                    'browserName2': (browserShortcutsConfig[2] || {}).name || '',
                    'browserName3': (browserShortcutsConfig[3] || {}).name || '',
                    'browserUrl0': browserShortcutsConfig[0].url,
                    'browserUrl1': (browserShortcutsConfig[1] || {}).url || '',
                    'browserUrl2': (browserShortcutsConfig[2] || {}).url || '',
                    'browserUrl3': (browserShortcutsConfig[3] || {}).url || '',
                    'url-interface': urlInterface.interface,
                    'keyboard-layout': keyboardLayout.layout,
                    'power-down-mode': powerDownMode.mode,
                    'eddystoneEnable': eddystoneDiscovery.enabled,
                    'eddystoneInterface': eddystoneInterface.interface,
                    'managementServerDhcp': vLinkProDiscovery.mode === 'dhcp',
                    'vLinkProServerIp': vLinkProDiscovery.serverIp,
                    'mdnsEnable': mdnsDiscovery.enabled,
                    'pinDynamicEnabled': pinDynamic.enabled,
                    'pinResetTime': pinDynamic.resetTime,
                    'dualProjectionEnable': dualProjection.enable
                };

                dfd.resolve();
            }.bind(this), function() {
                window.app.getService('ExceptionsManager')
                    .throw('Unable to load backendSettings');
            });

        return dfd.promise();
    },

    /**
     * Load frontend settings.
     */
    loadFrontendSettings: function() {
        var dfd = $.Deferred();

        this.frontendSettings.getSettings([
            'showStatusbar',
            'statusBarSettings',
            'airplayPinHdmi2', // NOTE: === Mirror PIN (Airplay/Miracast/vCast/Viewer). No name change for backwards compatibility
            'mirrorPinSize',
            'mirrorPinMainScreen',
            'showPinControlScreen',
            'showPinProjections'
        ])
            .then(function(statusbar, statusbarSettings, pinHdmi2, pinSize, pinMainScreen, pinControlScreen, pinProjections) {
                this.formData.statusbar = statusbar;
                this.formData.pinSize = pinSize;

                if (this.isDualProjection) {
                    this.formData.pinProjections = pinProjections;
                    this.formData.pinControlScreen = pinControlScreen;
                } else {
                    this.formData.pinMainScreen = pinMainScreen;
                    this.formData.pinHdmi2 = pinHdmi2;
                }

                if (statusbarSettings) {
                    this.formData.statusbarLan1 = statusbarSettings.lan1;
                    this.formData.statusbarLan2 = statusbarSettings.lan2;
                    this.formData.statusbarWlan = statusbarSettings.wlan;
                    this.formData.statusbarSsid = statusbarSettings.ssid;
                    dfd.resolve();
                } else { // Fallback
                    this.deviceConnection
                        .send('getStatusBarSettings')
                        .then(function(statusbar) {
                            switch (statusbar.networkInterface) {
                                case statusbarNetworkInterface.lan1:
                                    this.formData.statusbarLan1 = true;
                                    break;
                                case statusbarNetworkInterface.wlan:
                                    this.formData.statusbarWlan = true;
                                    break;
                                case statusbarNetworkInterface.both:
                                    this.formData.statusbarLan1 = true;
                                    this.formData.statusbarWlan = true;
                                    break;
                            }
                            dfd.resolve();
                        }.bind(this));
                }
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Load settings from custom-ui-file.
     */
    loadCustomUiSettings: function() {
        var dfd = $.Deferred();

        this.customUiSettings.getSettings([
            'browserEnabled',
            'appsEnabled',
            'helpEnabled',
            'snapshotEnabled',
            'freezeEnabled'
        ])
            .then(function(browserEnabled, appsEnabled, helpEnabled, snapshotEnabled, freezeEnabled) {
                var appsEnabledDefault = app.getService('Model-View').appsSettingsDefault.enabled;

                this.formData.browserEnabled = browserEnabled;
                this.formData.appsEnabled = null === appsEnabled ? appsEnabledDefault : appsEnabled;
                this.formData.helpEnabled = helpEnabled;

                if (!this.deviceService.isCboxPure() && !this.deviceService.isCboxPureMini()) {
                    this.formData.snapshotEnabled = snapshotEnabled;
                    this.formData.freezeEnabled = freezeEnabled;
                }

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Save general settings.
     * @param data
     */
    saveGeneralSettings: function(data) {
        var dateData;
        var shortcutsConfig;

        dateData = dateLib.parseTime(data.timeformat, data.time);
        dateData = this.app.$.extend(dateData, dateLib.parseDate(data.timeformat, data.date));

        this.frontendSettings.updateSetting({
            tag: 'showStatusbar',
            value: this.form.get('statusbar').getValue()
        });

        this.frontendSettings.updateSetting({
            tag: 'statusBarSettings',
            value: {
                lan1: this.form.get('statusbarLan1').getValue(),
                lan2: (this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini())
                    ? false : this.form.get('statusbarLan2').getValue(),
                wlan: this.form.get('statusbarWlan').getValue(),
                ssid: this.form.get('statusbarSsid').getValue()
            }
        });

        this.frontendSettings.updateSetting({
            tag: 'mirrorPinSize',
            value: this.form.get('pinSize').getValue()
        });

        if (this.isDualProjection) {
            this.frontendSettings.updateSetting({
                tag: 'showPinControlScreen',
                value: this.form.get('pinControlScreen').getValue()
            });

            this.frontendSettings.updateSetting({
                tag: 'showPinProjections',
                value: this.form.get('pinProjections').getValue()
            });
        } else {
            this.frontendSettings.updateSetting({
                tag: 'airplayPinHdmi2', // NOTE: === Mirror PIN (Airplay/Miracast/vCast). No name change for backwards compatibility
                value: this.form.get('pinHdmi2').getValue()
            });

            this.frontendSettings.updateSetting({
                tag: 'mirrorPinMainScreen',
                value: this.form.get('pinMainScreen').getValue()
            });
        }

        this.frontendSettings.saveSettings();

        this.customUiSettings.updateSetting({
            tag: 'browserEnabled',
            value: (this.deviceService.isCboxPure() || this.deviceService.isCboxPureMini())
                ? false : this.form.get('browserEnabled').getValue()
        });

        if (!this.deviceService.isCboxPureReceiver()) {
            this.customUiSettings.updateSetting({
                tag: 'appsEnabled',
                value: this.form.get('appsEnabled').getValue()
            });
        }

        this.customUiSettings.updateSetting({
            tag: 'helpEnabled',
            value: this.form.get('helpEnabled').getValue()
        });

        if (!this.deviceService.isCboxPure() && !this.deviceService.isCboxPureMini()) {
            this.customUiSettings.updateSetting({
                tag: 'snapshotEnabled',
                value: this.form.get('snapshotEnabled').getValue()
            });

            if (!this.isDualProjection) {
                this.customUiSettings.updateSetting({
                    tag: 'freezeEnabled',
                    value: this.form.get('freezeEnabled').getValue()
                });
            }
        }

        this.customUiSettings.saveSettings();

        this.$el.find('#language-select').find('.success-message').html(i18n.t(''));
        this.$el.find('#keyboard-layout-select').find('.success-message').html(i18n.t(''));

        if (!this.$el.find('#time').is(':disabled')) {
            this.deviceConnection
                .send([
                    {
                        command: 'setTimeDate',
                        data: dateData
                    }
                ]);
        }

        if (!this.$el.find('#timezone').is(':disabled')) {
            this.deviceConnection
                .send([
                    {
                        command: 'setTimezone',
                        data: {
                            timezoneIndex: data.timezone
                        }
                    }
                ]);
        }

        shortcutsConfig = [];
        if (data.browserName0 || data.browserUrl0) {
            shortcutsConfig.push({
                name: data.browserName0,
                url: data.browserUrl0
            });
        }
        if (data.browserName1 || data.browserUrl1) {
            shortcutsConfig.push({
                name: data.browserName1,
                url: data.browserUrl1
            });
        }
        if (data.browserName2 || data.browserUrl2) {
            shortcutsConfig.push({
                name: data.browserName2,
                url: data.browserUrl2
            });
        }
        if (data.browserName3 || data.browserUrl3) {
            shortcutsConfig.push({
                name: data.browserName3,
                url: data.browserUrl3
            });
        }

        if (shortcutsConfig.length === 0) {
            shortcutsConfig = [
                { name: '', url: '' }
            ];
        }

        this.form.setValues({
            browserName0: (shortcutsConfig[0] || {}).name || '',
            browserUrl0: (shortcutsConfig[0] || {}).url || '',
            browserName1: (shortcutsConfig[1] || {}).name || '',
            browserUrl1: (shortcutsConfig[1] || {}).url || '',
            browserName2: (shortcutsConfig[2] || {}).name || '',
            browserUrl2: (shortcutsConfig[2] || {}).url || '',
            browserName3: (shortcutsConfig[3] || {}).name || '',
            browserUrl3: (shortcutsConfig[3] || {}).url || ''
        });
        this.browserShortcutCount = shortcutsConfig.length;
        this.updateBrowserFields();
        this.changes = false;

        var cmds = [
            {
                command: 'setLocalTouchKeyboard',
                data: {
                    enabled: data.keyboard
                }
            },
            {
                command: 'setBoxName',
                data: {
                    boxname: data.boxname
                }
            },
            {
                command: 'setTimeSource',
                data: {
                    timeSource: data.timeserver
                }
            },
            {
                command: 'setTimeserverUrl',
                data: {
                    url: data.url
                }
            },
            {
                command: 'setDateFormat',
                data: {
                    dateFormat: data.timeformat
                }
            },
            {
                command: 'setSystemLanguage',
                data: {
                    language: data.language
                }
            },
            {
                command: 'setBrowserShortcutsConfig',
                data: JSON.stringify(shortcutsConfig)
            },
            {
                command: 'setKeyboardLayout',
                data: {
                    layout: data['keyboard-layout']
                }
            },
            {
                command: 'setPowerDownMode',
                data: {
                    mode: data['power-down-mode']
                }
            },
            {
                command: 'setVLinkProDiscoverySettings',
                data: {
                    mode: data['managementServerDhcp'] === true ? 'dhcp' : 'static',
                    serverIp: data['vLinkProServerIp']
                }
            },
            {
                command: 'setEddystoneDiscovery',
                data: {
                    enabled: data['eddystoneEnable']
                }
            },
            {
                command: 'setEddystoneInterface',
                data: {
                    interface: data['eddystoneInterface']
                }
            },
            {
                command: 'setMDNSDiscovery',
                data: {
                    enabled: data['mdnsEnable']
                }
            }
        ];

        if (!this.deviceService.isCboxPureReceiver()) {
            cmds = [
                ...cmds,
                ...[
                    {
                        command: 'setMirrorPinDynamic',
                        data: {
                            enabled: this.form.get('pinDynamicEnabled').getValue(),
                            resetTime: parseInt(this.form.get('pinResetTime').getValue())
                        }
                    },
                    {
                        command: 'setURLInterface',
                        data: {
                            interface: data['url-interface']
                        }
                    }
                ]
            ];
        }

        if (this.deviceService.isCboxPro()) {
            cmds = [
                ...cmds,
                ...[
                    {
                        command: 'setDualProjectionEnable',
                        data: {
                            enable: data['dualProjectionEnable']
                        }
                    }
                ]
            ];
        }

        return this.deviceConnection
            .send(cmds);
    },

    /**
     * Test timeserver & start polling timeserver status.
     */
    testTimeserver: function() {
        this.deviceConnection
            .send('setTimeserverTest', {
                url: this.form.get('url').getValue()
            });

        this.timeserverTestPolling = this.timeserverTestPolling || this.pollFactory.create({
            pollHandler: this.fetchTimeserverTestStatus.bind(this)
        });

        this.timeserverTestPolling.addCheck(function(stop) {
            switch (this.timeserverTest.getState()) {
                case timeserverTestStatus.testing:
                    this.toggleTimeserverState(timeserverTestStatus.testing);
                    break;
                case timeserverTestStatus.failed:
                    this.toggleTimeserverState(timeserverTestStatus.failed);
                    this.$testBtn.prop('disabled', false);
                    stop();
                    break;
                case timeserverTestStatus.success:
                    this.toggleTimeserverState(timeserverTestStatus.success);
                    this.$testBtn.prop('disabled', false);
                    stop();
                    break;
            }
        }.bind(this));

        this.toggleTimeserverState(timeserverTestStatus.testing);
        this.$testBtn.prop('disabled', true);

        if (!this.timeserverTestPolling.isPolling) {
            this.timeserverTestPolling.start();
        }
    },

    /**
     * Toggle timeserver state.
     * @param {string} name
     */
    toggleTimeserverState: function(name) {
        this.$timeserverTestState.attr('data-timeserver-test-status', 'is-' + name);
    },

    /**
     * Fetch timeserver test status.
     * @param {function} done
     */
    fetchTimeserverTestStatus: function(done) {
        this.deviceConnection
            .send('getTimeserverTestStatus')
            .then(function(data) {
                this.timeserverTest.changeState(timeserverTestStatus[data.status]);
                done();
            }.bind(this));
    },

    /**
     * Update Form.
     */
    updateForm: function() {
        this.form.setValues(this.formData);
    },

    /**
     * Animate hide all or specific info message.
     *
     * @param name Info message name
     */
    animateHideInfoMessages: function(name) {
        var $el = this.$el.find('.settings-list-info');

        if (name) {
            $el = this.$el.find('.settings-list-info[data-name="' + name + '"]');
        }

        $el.stop()
            .slideUp(200);
    },

    /**
     * Animate show info messages.
     *
     * @param name data-name to find element.
     */
    animateShowInfoMessages: function(name) {
        var $el = this.$el.find('.settings-list-info[data-name="' + name + '"]');

        if ($el.css('display') === 'none') {
            $el.stop()
                .slideDown(200);
        }
    },

    /**
     * Destroy.
     */
    destroy: function() {
        this.formManager.destroy(this.form);
        if (this.actionView) {
            this.actionView.destroy();
        }

        clearTimeout(this.pollTimeDataTimeout);
    },

    createSelects: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#timeformat-select'),
            label: 'settings.general_timeformat',
            native: true,
            name: 'timeformat',
            items: [
                {
                    text: dateLib.DATE_FORMATS.h24,
                    value: dateLib.DATE_FORMATS.h24
                },
                {
                    text: dateLib.DATE_FORMATS.h12,
                    value: dateLib.DATE_FORMATS.h12
                }
            ],
            onChange: this.reformatDateTime.bind(this)

        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#timezone-select'),
            label: 'settings.general_timezone',
            native: true,
            name: 'timezone',
            items: timezones.getSelectList()
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#language-select'),
            label: 'settings.language',
            info: '',
            native: true,
            name: 'language',
            items: [
                {
                    text: 'settings.zh',
                    value: 'zh'
                },
                {
                    text: 'settings.en',
                    value: 'en'
                },
                {
                    text: 'settings.fr',
                    value: 'fr'
                },
                {
                    text: 'settings.de',
                    value: 'de'
                },
                {
                    text: 'settings.ja',
                    value: 'ja'
                },
                {
                    text: 'settings.nor',
                    value: 'nor'
                },
                {
                    text: 'settings.ru',
                    value: 'ru'
                }
            ]
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#keyboard-layout-select'),
            label: 'settings.keyboard_layout',
            info: '',
            native: true,
            name: 'keyboard-layout',
            items: [
                {
                    text: 'settings.de',
                    value: 'de'
                },
                {
                    text: 'settings.en_us',
                    value: 'us'
                },
                {
                    text: 'settings.en_uk',
                    value: 'uk'
                },
                {
                    text: 'settings.fr',
                    value: 'fr'
                },
                {
                    text: 'settings.fr_ca',
                    value: 'fr-ca'
                },
                {
                    text: 'settings.it',
                    value: 'it'
                },
                {
                    text: 'settings.jp_jis',
                    value: 'jp'
                },
                {
                    text: 'settings.nor',
                    value: 'no'
                },
                {
                    text: 'settings.sv_fin',
                    value: 'sv'
                },
                {
                    text: 'settings.ch',
                    value: 'ch'
                }
            ].sort((a, b) => {
                const textA = i18n.t(a.text);
                const textB = i18n.t(b.text);

                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            })
        });

        if (!this.deviceService.isCboxPureReceiver()) {
            this.createComponent({
                type: 'CustomSelect',
                container: this.$el.find('#url-interface-select'),
                label: 'settings.apps_download_interface',
                native: true,
                name: 'url-interface',
                items: app.getService('Model-View').getGeneralLanSelects()
            });
        }

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#power-down-mode-select'),
            label: 'settings.power_down_mode',
            info: '',
            native: true,
            name: 'power-down-mode',
            items: function() {
                let modes = [
                    {
                        text: 'settings.shutdown',
                        value: 'shutdown'
                    },
                    {
                        text: 'settings.power_saving',
                        value: 'power_saving'
                    }
                ];

                if (!this.deviceService.isLivaQ2Hardware()) {
                    modes.unshift({
                        text: 'settings.standby',
                        value: 'suspend'
                    });
                }

                return modes;
            }.bind(this).call()
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#eddystone-interface-select'),
            label: 'settings.eddystone_interface',
            native: true,
            name: 'eddystoneInterface',
            items: app.getService('Model-View').getGeneralLanSelects()
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#pin-reset-time-select'),
            label: 'settings.pin_reset_time',
            native: true,
            name: 'pinResetTime',
            items: [
                {
                    text: 'settings.reset_time_10m',
                    value: 10
                },
                {
                    text: 'settings.reset_time_20m',
                    value: 20
                },
                {
                    text: 'settings.reset_time_30m',
                    value: 30
                },
                {
                    text: 'settings.reset_time_40m',
                    value: 40
                },
                {
                    text: 'settings.reset_time_50m',
                    value: 50
                },
                {
                    text: 'settings.reset_time_1h',
                    value: 60
                }
            ]
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#pin-size-select'),
            label: 'settings.flap_size',
            native: true,
            name: 'pinSize',
            items: [
                {
                    text: 'settings.small',
                    value: 'small'
                },
                {
                    text: 'settings.medium',
                    value: 'medium'
                },
                {
                    text: 'settings.large',
                    value: 'large'
                },
                {
                    text: 'settings.xlarge',
                    value: 'xlarge'
                }
            ]
        });
    },

    /**
     * Show/hide browser field with index (idx)
     *
     * @param idx browser name/url index
     * @param show true/false
     */
    showBrowserField: function(idx, show) {
        if (show) {
            this.$el.find('.browser' + idx).removeClass('hidden');
        } else {
            this.$el.find('.browser' + idx).addClass('hidden');
        }
    },

    /**
     * Update Browser fields on change.
     */
    updateBrowserFields: function() {
        this.showBrowserField(0, this.browserShortcutCount >= 1);
        this.showBrowserField(1, this.browserShortcutCount >= 2);
        this.showBrowserField(2, this.browserShortcutCount >= 3);
        this.showBrowserField(3, this.browserShortcutCount >= 4);

        this.handleAddDirectLinkButton();
    },

    /**
     * Add direct browser link (start page)
     */
    addDirectLink: function() {
        this.browserShortcutCount += 1;
        this.updateBrowserFields();
    },

    /**
     * Delete direct browser link (start page)
     */
    deleteBrowserLink: function(el) {
        let $el = $(el.currentTarget);
        let idx = $el.data('index');

        this.form.get('browserName' + idx).setValue('');
        this.form.get('browserUrl' + idx).setValue('');

        if (this.browserShortcutCount > 1) { // Not possible to delete the first browser shortcut
            this.$el.find('.browser' + $el.data('index')).addClass('hidden');
            this.browserShortcutCount -= 1;
        }

        this.changes = true;
        this.actionView.open();
        this.checkActionForm();
        this.handleAddDirectLinkButton();
    },

    /**
     * Handle direct browser link button (enable/disable)
     */
    handleAddDirectLinkButton: function() {
        if (this.browserShortcutCount < 4) {
            this.$addNewDirectLink.removeAttr('disabled');
        } else {
            this.$addNewDirectLink.attr('disabled', 'disabled');
        }
    },

    /*
     * Reformats date and time according to the timeformat
     */
    reformatDateTime: function() {
        var dateFormat = this.form.get('timeformat').getValue();

        this.deviceConnection.send('getTimeDate')
            .then(function(dateTime) {
                var date = dateLib.formatDate(dateFormat, dateTime);
                var time = dateLib.formatTime(dateFormat, dateTime);

                this.formData = {
                    'date': date,
                    'time': time
                };
                this.form.setValues({
                    'date': date,
                    'time': time
                });
            }.bind(this));
    },

    hasChanges: function() {
        var changes = {
            hasChanges: this.changes,
            invalid: false,
            waitOnSave: true
        };

        if (this.actionView) {
            changes.invalid = $(this.actionView.$submitBtn).prop('disabled');
        }

        return changes;
    }
});
