'use strict';

var app = require('../app');
var $ = require('jquery');

/**
 * Station Service
 */
app.service('StationService', function() {
    return {
        initialize: function() {
            this.pushStatus = null;
            this.accessLevel = null;
            this.lockStatus = null;
            this.limitedStatus = null;
            this.authService = app.getService('AuthenticationService');

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                }.bind(this));

            this.bindEvents();
            this.updateHandler();
        },

        bindEvents: function() {
            app.on('main-loop.update', this.updateHandler.bind(this));
            app.on('main-loop.update.station', this.updateHandler.bind(this));
        },

        updateHandler: function() {
            $.when(this.getMatrixStationStatus())
                .done(function(status) {
                    this.accessLevel = status.stationStatus.accessLevel;

                    $.when(this.getLockStatus(), this.getLimitedAccessStatus())
                        .done(function(lockStatus, limitedStatus) {
                            if (this.pushStatus !== status.stationStatus.pushStatus
                                || this.streamName !== status.stationStatus.streamName
                                || this.lockStatus !== lockStatus
                                || this.limitedStatus !== limitedStatus) {
                                this.pushStatus = status.stationStatus.pushStatus;
                                this.stationName = status.stationStatus.stationName;
                                this.audioStatus = status.stationStatus.audioStatus;
                                this.pushIndex = status.stationStatus.pushIndex;
                                this.streamName = status.stationStatus.streamName;
                                this.lockStatus = lockStatus;
                                this.limitedStatus = limitedStatus;

                                this.handlePushStatus(status);
                            }
                        }.bind(this));
                }.bind(this));
        },

        /**
         * Handles the push status.
         */
        handlePushStatus: function(status) {
            if (this.pushStatus) {
                this.showPushIndicator();

                app.emit('station-status.push');
                app.emit('overlay.close');
                app.emit('modal.close', {
                    exceptIds: [
                        'select-viewer-control',
                        'login'
                    ]
                });
                app.emit('backdrop.hide');
                app.emit('menu.close');

                if (status && status.stationStatus.audioStatus) {
                    app.emit('station-status.audio');
                }
            } else if (this.lockStatus) {
                this.showLockIndicator();

                app.emit('station-status.lock');
                app.emit('overlay.close');
                app.emit('modal.close', {
                    exceptIds: [
                        'select-viewer-control',
                        'login'
                    ]
                });
                app.emit('backdrop.hide', {
                    key: 'modal'
                });
                app.emit('menu.close');
            } else if (this.limitedStatus) {
                app.removeComponent('lockIndicator');
                app.removeComponent('pushIndicator');

                app.emit('station-status.limited');
                app.emit('app.state.change', {
                    'is-limited-access': true
                });
            } else {
                app.removeComponent('lockIndicator');
                app.removeComponent('pushIndicator');

                app.emit('station-status.standard');
                app.emit('app.state.change', {
                    'is-limited-access': false
                });

                app.emit('activities.start');
            }

            app.emit('matrix.station-status.update');
        },

        /**
         * Receive the push/station status.
         *
         */
        getMatrixStationStatus: function() {
            var dfd = $.Deferred();

            this.deviceConnection
                .send('getMatrixStationStatus').then(function(data) {
                    dfd.resolve({
                        stationStatus: data
                    });
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Returns device push status.
         *
         */
        getPushStatus: function() {
            return this.pushStatus;
        },

        /**
         * Returns device lock status.
         *
         */
        getLockStatus: function() {
            if (this.authService.isMatrixControlInstance()) {
                return false;
            }

            return this.accessLevel === 2;
        },

        /**
         * Returns device limited access status.
         *
         */
        getLimitedAccessStatus: function() {
            if (this.authService.isMatrixControlInstance()) {
                return false;
            }

            return this.accessLevel === 1;
        },

        /**
         * Returns if station is in Lock, Push or Limited access status
         *
         */
        getIsInPushLockOrLimitedStatus: function() {
            if (this.authService.isMatrixControlInstance()) {
                return false;
            }

            return !!(this.accessLevel === 1 || this.accessLevel === 2 || this.pushStatus);
        },

        /**
         * Returns station audio status.
         *
         */
        getAudioStatus: function() {
            return this.audioStatus;
        },

        showPushIndicator: function() {
            app.removeComponent('pushIndicator');
            app.createComponent('pushIndicator', {
                type: 'PushIndicator',
                container: '#indicator-container',
                name: this.streamName ? this.streamName : this.stationName,
                pushIndex: this.pushIndex
            });
        },

        showLockIndicator: function() {
            app.removeComponent('lockIndicator');
            app.createComponent('lockIndicator', {
                type: 'LockIndicator',
                container: '#indicator-container',
                name: this.stationName
            });
        }
    };
});
