module.exports={
    "modifier": {},

    "modifier_mobile":
    {
        "&#33;": 42, //!
        "&#34;": 42, //"
        "&#35;": 42, //#
        "&#36;": 42, //$
        "&#37;": 42, //%
        "&#38;": 42, //&
        "&#39;": 42, //'
        "&#40;": 42, //(
        "&#41;": 42, //)
        "&#61;": 42, //=
        "&#126;": 42, //~
        "&#124;": 42, //|

        "&#96;": 42, //`
        "&#123;": 42, //{

        "&#43;": 42, //+
        "&#42;": 42, //*
        "&#125;": 42, //}

        "&#60;": 42, //<
        "&#62;": 42, //>
        "&#63;": 42, //?
        "&#95;": 42 //_
    },

    "ie_specialkeys_mobile": {},

    "ie_specialkeys": {},

    "&#33;": 2, //!
    "&quot;": 3, //"
    "&#34;": 3, //"
    "&#35;": 4, //#
    "&#36;": 5, //$
    "&#37;": 6, //%
    "&#38;": 7, //&
    "&#39;": 8, //'
    "&#40;": 9, //(
    "&#41;": 10, //)
    "&#45;": 12, //-
    "&#61;": 12, //=
    "&#94;": 13, //^
    "&#126;": 13, //~
    "&#165;": 124, //¥
    "&#124;": 124, //|

    "&#64;": 26, //@
    "&#96;": 26, //`
    "&#91;": 27, //[
    "&#123;": 27, //{

    "&#59;": 39, //;
    "&#43;": 39, //+
    "&#58;": 40, //:
    "&#42;": 40, //*
    "&#93;": 43, //]
    "&#125;": 43, //}

    "&#44;": 51, //,
    "&#60;": 51, //<
    "&#46;": 52, //.
    "&#62;": 52, //>
    "&#47;": 53, ///
    "&#63;": 53, //?
    "&#92;": 89, //\
    "&#95;": 89 //_
}
