'use strict';

const app = require('../../../app');
const $ = require('jquery');
const _ = require('lodash');
const inputSettingsTpl = require('./input-settings.hbs');
const streamInputListItemTpl = require('./stream-input-item.hbs');
const FormActionView = require('../form-action-view');
const i18n = require('i18next');
const states = require('../../../states');

const inputs = {
    hdmi1: 'hdmi1',
    hdmi2: 'hdmi2',
    uvc0: 'uvc0',
    uvc1: 'uvc1'
};

const inputTypes = {
    camera: 'camera',
    computer: 'computer',
    cynap: 'cynap',
    discPlayer: 'discPlayer',
    hdmi: 'hdmi',
    none: 'none',
    stream: 'stream',
    visualizer: 'visualizer'
};

const selectItems = [
    {
        text: 'settings.hdmi',
        value: inputTypes.hdmi
    },
    {
        text: 'settings.visualizer',
        value: inputTypes.visualizer
    },
    {
        text: 'settings.computer',
        value: inputTypes.computer
    },
    {
        text: 'settings.discPlayer',
        value: inputTypes.discPlayer
    },
    {
        text: 'settings.camera',
        value: inputTypes.camera
    }
];

const maxResolutionItems = [
    {
        text: 'settings.2160p',
        value: '2160p'
    },
    {
        text: 'settings.1080p',
        value: '1080p'
    }
];

const selectStreamInputItems = [
    {
        text: 'settings.stream_type',
        value: inputTypes.stream
    },
    {
        text: 'settings.camera',
        value: inputTypes.camera
    },
    {
        text: 'settings.computer',
        value: inputTypes.computer
    },
    {
        text: 'settings.visualizer',
        value: inputTypes.visualizer
    },
    {
        text: 'settings.hdmi',
        value: inputTypes.hdmi
    },
    {
        text: 'settings.discPlayer',
        value: inputTypes.discPlayer
    },
    {
        text: 'settings.cynap_type',
        value: inputTypes.cynap
    }
];

const selectUvcItems = [
    {
        text: 'settings.none',
        value: inputTypes.none
    },
    {
        text: 'settings.camera',
        value: inputTypes.camera
    },
    {
        text: 'settings.visualizer',
        value: inputTypes.visualizer
    },
    {
        text: 'settings.hdmi',
        value: inputTypes.hdmi
    },
    {
        text: 'settings.computer',
        value: inputTypes.computer
    },
    {
        text: 'settings.discPlayer',
        value: inputTypes.discPlayer
    }
];

const uvcInputAutostart = {
    none: 'none',
    off: 'off',
    on: 'on'
};

app.component('InputSettings', {
    template: inputSettingsTpl,
    actionView: null,

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.deviceService = this.getService('DeviceService');
        this.formStreamInput = [];
        this.actionViewStreamInput = [];
        this.streamInputCount = 20;
        this.uvcDevices = [];
        this.uvc0Device = null;
        this.uvc1Device = null;
        this.inputStreams = [];
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.createSelects();
        this.handleHdcpSupport();

        $.when(this.loadData()).done(function() {
            this.createUvcSelects();
            this.initForm();
            this.initFormAction();
            this.form.setValues(this.formData);

            this.bindEvents();
            this.bindDOMEvents();
        }.bind(this));
    },

    serialize: function() {
        return {
            isCbox: this.deviceService.isCbox(),
            isCorePro: this.deviceService.isCboxCorePro()
        };
    },

    storeSelectors: function() {
        this.$useForWebConfHdmi1 = this.$el.find('#hdmi1-use-for-webrtc-container');
        this.$useForWebConfHdmi2 = this.$el.find('#hdmi2-use-for-webrtc-container');
        this.$useForWebConfUvc0 = this.$el.find('#uvc-input0-use-for-webrtc-container');
        this.$useForWebConfUvc1 = this.$el.find('#uvc-input1-use-for-webrtc-container');
        this.$hdcpSupportHdmi1 = this.$el.find('#hdmi1-hdcp-enable');
        this.$hdcpSupportHdmi1Switch = this.$el.find('#hdmi1Hdcp');
        this.$hdcpSupportHdmi1Info = this.$el.find('.input-info-message[data-id="hdmi1Hdcp"]');
        this.$streamInputList = this.$el.find('#stream-input-items');
        this.$addNewStream = this.$el.find('.add-new-stream');
        this.$uvcInput0AutostartContainer = this.$el.find('.uvc-input0-autostart-container');
        this.$uvcInput1AutostartContainer = this.$el.find('.uvc-input1-autostart-container');
    },

    bindEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.on('remote.enter.keydown', this.checkActionForm.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
        this.on('settings.save-changes', this.saveAllHandler.bind(this));
        this.on('overlay.ready', this.prepareStreamInputs.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.add-new-stream:not(.is-disabled)', this.addNewStreamHandler.bind(this));
        this.$el.on('click', '.webrtc-input', this.updateWebRtcInputSwitches.bind(this));
    },

    /**
     * @method addNewStreamHandler
     */
    addNewStreamHandler: function() {
        var freeIndex = this.getNextFreeIndex();

        if (freeIndex >= 0) {
            var $item = this.$streamInputList.find('#stream-input-settings-' + freeIndex);

            this.$streamInputList.prepend($item);
            $item.show();

            this.actionViewStreamInput[freeIndex].open();
            this.checkActionForm(freeIndex);
        }

        this.checkMaxStreamInputs();
    },

    prepareStreamInputs: function() {
        this.parseStreamInputs(this.inputStreams);
        this.checkMaxStreamInputs();
    },

    /**
     * @method checkMaxStreamInputs
     */
    checkMaxStreamInputs: function() {
        if (this.getNextFreeIndex() === undefined) {
            this.$addNewStream.addClass('is-disabled');
        } else {
            this.$addNewStream.removeClass('is-disabled');
        }
    },

    /**
     * @method getNextFreeIndex
     */
    getNextFreeIndex: function() {
        for (var i = 0; i < this.streamInputCount; i++) {
            this.checkIndex(i);

            if (!this.indexExists) {
                return i;
            }

            this.indexExists = false;
        }
    },

    /**
     * @param {Number} index
     */
    checkIndex: function(index) {
        _.each(this.formStreamInput, function(item) {
            if (item && item.$el.data('index') === index && item.$el.css('display') !== 'none') {
                this.indexExists = true;
            }
        }.bind(this));
    },

    /**
     * Check if HDCP support can be activated.
     * Local recording / webcasting / lecture capture must be disabled.
     *
     * @return {*}
     */
    checkHdcpAvailable: function() {
        const dfd = $.Deferred();
        this.deviceConnection
            .send(['getRecordingFunction', 'getWebcastMode', 'getLectureCaptureSettings'])
            .then(function(recording, webcasting, lectureCapture) {
                const recordingEnabled = recording.enabled;
                const webcastingEnabled = webcasting.mode !== 'off';

                // If Composer change lecture capture type to disabled if opencast was selected in normal mode
                if (this.deviceService.isCboxProDualProjection()
                    && ['opencastAdhoc', 'opencastAuto'].includes(states.lcsCmdToTypeMapping[lectureCapture.type])) {
                    lectureCapture.type = 0;
                }
                const lcsEnabled = lectureCapture.type !== states.lcsTypeToCmdMapping.disabled;

                dfd.resolve({
                    hdcpAvailable: !recordingEnabled && !webcastingEnabled && !lcsEnabled,
                    recordingEnabled: recordingEnabled,
                    webcastingEnabled: webcastingEnabled,
                    lcsEnabled: lcsEnabled
                });
            }.bind(this));

        return dfd.promise();
    },

    handleHdcpSupport: function() {
        if (!this.deviceService.isCboxCorePro()) {
            return;
        }

        this.checkHdcpAvailable()
            .then(function(data) {
                if (data.hdcpAvailable) {
                    this.$hdcpSupportHdmi1.removeClass('is-disabled');
                    this.$hdcpSupportHdmi1Info.hide();
                } else {
                    this.$hdcpSupportHdmi1.addClass('is-disabled');

                    let enabledFunctions = [];
                    if (data.recordingEnabled) {
                        enabledFunctions.push(i18n.t('settings.record_title'));
                    }
                    if (data.webcastingEnabled) {
                        enabledFunctions.push(i18n.t('settings.webcasting_title'));
                    }
                    if (data.lcsEnabled) {
                        enabledFunctions.push(i18n.t('settings.lecture_capture'));
                    }

                    this.$hdcpSupportHdmi1Info.text(i18n.t('settings.hdcp_info', {
                        functions: enabledFunctions.join(' ' + i18n.t('settings.and') + ' ')
                    }));
                    this.$hdcpSupportHdmi1Info.show();
                }

                this.$hdcpSupportHdmi1Switch.prop('disabled', !data.hdcpAvailable);
            }.bind(this));
    },

    /**
     * @method loadData
     */
    loadData: function() {
        return this.deviceConnection
            .send([
                {
                    command: 'getHdmiInputSetup',
                    data: { hdmiInput: 0 }
                },
                {
                    command: 'getHdmiInputSetup',
                    data: { hdmiInput: 1 }
                },
                {
                    command: 'getHdmiInputAutostart',
                    data: { hdmiInput: 0 }
                },
                {
                    command: 'getHdmiInputAutostart',
                    data: { hdmiInput: 1 }
                },
                {
                    command: 'getHdmiInputForWebRtc',
                    data: { hdmiInput: 0 }
                },
                {
                    command: 'getHdmiInputForWebRtc',
                    data: { hdmiInput: 1 }
                },
                {
                    command: 'getHdmiInputMaxResolution',
                    data: { input: 0 }
                },
                {
                    command: 'getHdmiInputMaxResolution',
                    data: { input: 1 }
                },
                { command: 'getHdmiInput1NoAudio' },
                { command: 'getHdmiInput1HdcpSupport' },
                { command: 'getStreamInput' },
                {
                    command: 'getUvcInput',
                    data: { index: 0 }
                },
                {
                    command: 'getUvcInput',
                    data: { index: 1 }
                },
                { command: 'getUvcInputDevices' }
            ])
            .then(function(input0, input1, autostart0, autostart1, hdmi1UseForWebRtc, hdmi2UseForWebRtc, input0MaxRes,
                input1MaxRes, hdmi1NoAudio, hdmi1HdcpSupport, streamInputs, uvcInput0, uvcInput1, uvcDevices) {
                this.inputStreams = streamInputs.list;
                this.parseUvcInputs(uvcDevices.uvcDeviceList);

                this.uvc0Device = {
                    value: uvcInput0.device,
                    text: uvcInput0.device,
                    autostartSupport: (uvcInput0.autostart !== uvcInputAutostart.none),
                    videoMode: uvcInput0.videoMode
                };

                this.uvc1Device = {
                    value: uvcInput1.device,
                    text: uvcInput1.device,
                    autostartSupport: (uvcInput1.autostart !== uvcInputAutostart.none),
                    videoMode: uvcInput1.videoMode
                };

                this.formData = {
                    'input0-name': input0.name,
                    'input0-type': input0.type,
                    'input0-max-resolution': input0MaxRes.resolution,
                    'input1-name': input1.name,
                    'input1-type': input1.type,
                    'input1-max-resolution': input1MaxRes.resolution,
                    'autostartHdmi1': autostart0.autostart,
                    'autostartHdmi2': autostart1.autostart,
                    'hdmi1UseForWebRtc': hdmi1UseForWebRtc.enabled,
                    'hdmi2UseForWebRtc': hdmi2UseForWebRtc.enabled,
                    'hdmi1Audio': !hdmi1NoAudio.enabled, // True = 'no audio' disabled, false = 'no audio' is enabled
                    'hdmi1Hdcp': hdmi1HdcpSupport.enabled,
                    'uvc-input0-device': uvcInput0.device,
                    'uvc-input1-device': uvcInput1.device,
                    'uvc-input0-type': uvcInput0.type,
                    'uvc-input1-type': uvcInput1.type,
                    'uvc-input0-name': uvcInput0.name,
                    'uvc-input1-name': uvcInput1.name,
                    'uvc-input0-audio': uvcInput0.audio,
                    'uvc-input1-audio': uvcInput1.audio,
                    'uvc-input0-use-for-webRTC': uvcInput0.useForWebRTC,
                    'uvc-input1-use-for-webRTC': uvcInput1.useForWebRTC,
                    'uvc-input0-autostart': (uvcInput0.autostart === uvcInputAutostart.on),
                    'uvc-input1-autostart': (uvcInput1.autostart === uvcInputAutostart.on)
                };
            }.bind(this));
    },

    videoModeFromSelectValue: function(value) {
        if (value === '') {
            return {};
        }

        return JSON.parse(atob(value));
    },

    videoModeToSelectOption: function(videoMode) {
        if (!videoMode.type) {
            return {
                value: '',
                text: i18n.t('settings.auto')
            };
        }

        let codec;
        let value = btoa(JSON.stringify(videoMode));
        let resolution;
        let framerate;
        let resolutionValue;
        let framerateValue;

        switch (videoMode.type) {
            case 'video/x-raw':
                codec = videoMode.format || 'RAW';
                break;
            case 'image/jpeg':
                codec = 'MJPEG';
                break;
            default:
                codec = videoMode.type;
                break;
        }

        if (videoMode.width !== 0 && videoMode.height !== 0) {
            resolution = videoMode.width + 'x' + videoMode.height;
            resolutionValue = videoMode.width * videoMode.height;
        }

        if (videoMode.framerate && videoMode.framerate.num !== 0 && videoMode.framerate.den !== 0) {
            framerateValue = (videoMode.framerate.num / videoMode.framerate.den);
            framerate = framerateValue.toFixed(1) + ' Hz';
        }

        let infos = [resolution];
        if (resolution) {
            infos = infos.concat([framerate]);
        }
        if (framerate) {
            infos = infos.concat([codec]);
        }

        return {
            text: infos.join(' '),
            resolution: resolution,
            resolutionValue: resolutionValue,
            framerate: framerate,
            framerateValue: framerateValue,
            value: value
        };
    },

    /**
     * @param {Object} uvcDevices
     */
    parseUvcInputs: function(uvcDevices) {
        _.each(uvcDevices, function(item) {
            this.uvcDevices.push({
                value: item.name,
                text: item.name,
                autostartSupport: item.autostartSupport,
                videoModes: item.videoModes
            });
        }.bind(this));
    },

    /**
     * @param {Array} streamInputs
     */
    parseStreamInputs: function(streamInputs) {
        _.each(streamInputs, function(item) {
            var formData = [];
            var $item = this.$(streamInputListItemTpl(item));

            $item.attr('data-index', item.index);
            $item.find('#form-action-container').attr('data-index', item.index);
            $item.find('#pip-warning').hide();

            this.createInputSelects($item, item.index);

            formData.name = item.name;
            formData.url = item.url;
            formData.mode = item.mode;
            formData.type = item.type;
            formData.lowlatency = item.lowlatency;
            formData.mute = item.mute;

            if (item.mode !== 'none') {
                $item.show();
            } else {
                $item.hide();
            }

            this.$streamInputList.prepend($item);

            this.initFormStreamInput(item.index);
            this.updateForm(formData, item.index);
            this.initFormActionStreamInput($item, item.index);
        }.bind(this));
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this),
                navArea: false
            });
        }

        this.actionView.render();
    },

    updateForm: function(formData, index) {
        this.formStreamInput[index].setValues(formData);
    },

    /**
     * Handles click on cancel button.
     *
     * @param {Object|Number} [index]
     */
    cancelHandler: function(index) {
        this.emit('overlay.remote.focus', true);

        if (isNaN(index)) {
            this.form.resetValues();
            this.checkActionForm();
        } else {
            this.formStreamInput[index].resetValues();
            this.formStreamInput[index].$el.find('#pip-warning').hide();

            if (this.formStreamInput[index].get('mode').getValue() === 'none') {
                this.formStreamInput[index].$el.hide();
            } else {
                this.checkActionForm(index);
            }
        }

        this.changes = false;
        this.checkMaxStreamInputs();
    },

    /**
     * @param {Object|Number} [index]
     */
    checkActionForm: function(index) {
        if (index === undefined) {
            if (this.form.validate()) {
                this.actionView.enableSubmitButton();
            } else {
                this.actionView.disableSubmitButton();
            }
        } else if (this.formStreamInput[index].validate()) {
            this.actionViewStreamInput[index].enableSubmitButton();
        } else {
            this.actionViewStreamInput[index].disableSubmitButton();
        }
    },

    /**
     * Save all changed stream inputs.
     */
    saveAllHandler: function() {
        _.each(this.actionViewStreamInput, function(view, index) {
            if (view.state.getState() === 'visible') {
                this.saveHandler(index);
            }
        }.bind(this));
    },

    /**
     * @param {Object|Number} [index]
     */
    // TODO: change to normal command when the callbackeStoragen is fixed
    saveHandler: function(index) {
        if (isNaN(index)) {
            if (this.form.validate()) {
                // 1. Check if autostart is supported; 2. if it is, check if autostart is enabled or disabled
                var autostart = this.$uvcInput0AutostartContainer.attr('data-supported') === 'false' ? uvcInputAutostart.none
                    : this.form.get('uvc-input0-autostart').getValue() ? uvcInputAutostart.on : uvcInputAutostart.off;

                var uvcInput0 = {
                    index: 0,
                    name: this.form.get('uvc-input0-name').getValue(),
                    type: this.form.get('uvc-input0-type').getValue(),
                    device: this.form.get('uvc-input0-device').getValue(),
                    audio: this.form.get('uvc-input0-audio').getValue(),
                    useForWebRTC: this.form.get('uvc-input0-use-for-webRTC').getValue(),
                    autostart: autostart,
                    videoMode: this.videoModeFromSelectValue(this.form.get('uvc-input0-resolution').getValue())
                };

                // 1. Check if autostart is supported; 2. if it is, check if autostart is enabled or disabled
                autostart = this.$uvcInput1AutostartContainer.attr('data-supported') === 'false' ? uvcInputAutostart.none
                    : this.form.get('uvc-input1-autostart').getValue() ? uvcInputAutostart.on : uvcInputAutostart.off;

                var uvcInput1 = {
                    index: 1,
                    name: this.form.get('uvc-input1-name').getValue(),
                    type: this.form.get('uvc-input1-type').getValue(),
                    device: this.form.get('uvc-input1-device').getValue(),
                    audio: this.form.get('uvc-input1-audio').getValue(),
                    useForWebRTC: this.form.get('uvc-input1-use-for-webRTC').getValue(),
                    autostart: autostart,
                    videoMode: this.videoModeFromSelectValue(this.form.get('uvc-input1-resolution').getValue())
                };

                this.deviceConnection
                    .send([
                        {
                            command: 'setUvcInput',
                            data: uvcInput0
                        },
                        {
                            command: 'setUvcInput',
                            data: uvcInput1
                        }
                    ]);

                if (this.deviceService.isCbox() || this.deviceService.isCboxCorePro()) {
                    var input0 = {
                        hdmiInput: 0,
                        name: this.form.get('input0-name').getValue(),
                        type: this.form.get('input0-type').getValue()
                    };

                    var autostart0 = {
                        hdmiInput: 0,
                        autostart: this.form.get('autostartHdmi1').getValue()
                    };

                    var hdmi1UseForWebRtc = {
                        hdmiInput: 0,
                        enabled: this.form.get('hdmi1UseForWebRtc').getValue()
                    };

                    var input0MaxRes = {
                        input: 0,
                        resolution: this.form.get('input0-max-resolution').getValue()
                    };

                    this.deviceConnection
                        .send([
                            {
                                command: 'setHdmiInputSetup',
                                data: input0
                            },
                            {
                                command: 'setHdmiInputAutostart',
                                data: autostart0
                            },
                            {
                                command: 'setHdmiInputForWebRtc',
                                data: hdmi1UseForWebRtc
                            },
                            {
                                command: 'setHdmiInputMaxResolution',
                                data: input0MaxRes
                            }
                        ]);
                }

                if (this.deviceService.isCbox()) {
                    var input1 = {
                        hdmiInput: 1,
                        name: this.form.get('input1-name').getValue(),
                        type: this.form.get('input1-type').getValue()
                    };

                    var autostart1 = {
                        hdmiInput: 1,
                        autostart: this.form.get('autostartHdmi2').getValue()
                    };

                    var hdmi2UseForWebRtc = {
                        hdmiInput: 1,
                        enabled: this.form.get('hdmi2UseForWebRtc').getValue()
                    };

                    var input1MaxRes = {
                        input: 1,
                        resolution: this.form.get('input1-max-resolution').getValue()
                    };

                    this.deviceConnection
                        .send([
                            {
                                command: 'setHdmiInputAutostart',
                                data: autostart1
                            },
                            {
                                command: 'setHdmiInputForWebRtc',
                                data: hdmi2UseForWebRtc
                            },
                            {
                                command: 'setHdmiInputMaxResolution',
                                data: input1MaxRes
                            }
                        ]);
                }

                if (this.deviceService.isCboxCorePro()) {
                    this.deviceConnection
                        .send([
                            {
                                command: 'setHdmiInput1NoAudio',
                                data: {
                                    enabled: !this.form.get('hdmi1Audio').getValue()
                                }
                            },
                            {
                                command: 'setHdmiInput1HdcpSupport',
                                data: {
                                    enabled: this.form.get('hdmi1Hdcp').getValue()
                                }
                            }
                        ]);
                }

                setTimeout(function() {
                    if (this.deviceService.isCbox()) {
                        this.deviceConnection
                            .send([
                                {
                                    command: 'setHdmiInputSetup',
                                    data: input1
                                }
                            ]);
                    }

                    this.handleSettingsSave();
                    this.form.setDefaultValues();
                    this.emit('overlay.remote.focus', true);
                    this.emit('save-changes.finished');
                }.bind(this), 200);
            }
        } else {
            var mode = this.formStreamInput[index].get('mode').getValue();

            if (this.formStreamInput[index].validate()) {
                this.deviceConnection
                    .send([
                        {
                            command: 'setStreamInput',
                            data: {
                                index: index,
                                mode: mode,
                                url: this.formStreamInput[index].get('url').getValue(),
                                name: this.formStreamInput[index].get('name').getValue(),
                                type: this.formStreamInput[index].get('type').getValue(),
                                lowLatency: this.formStreamInput[index].get('lowlatency').getValue(),
                                mute: this.formStreamInput[index].get('mute').getValue()
                            }
                        }
                    ]);
            }

            if (mode === 'none') {
                this.formStreamInput[index].$el.hide();
            }

            this.checkMaxStreamInputs();
        }

        this.changes = false;
    },

    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.changes = false;
    },

    /**
     * Only one USB Input or HDMI Input can be selected for web conferencing.
     *
     * @param event Current event
     */
    updateWebRtcInputSwitches: function(event) {
        var $el = $(event.currentTarget);

        if ($el.prop('checked')) {
            this.form.get('uvc-input1-use-for-webRTC').setValue(false);
            this.form.get('uvc-input0-use-for-webRTC').setValue(false);

            if (this.deviceService.isCbox()) {
                this.form.get('hdmi1UseForWebRtc').setValue(false);
                this.form.get('hdmi2UseForWebRtc').setValue(false);
            } else if (this.deviceService.isCboxCorePro()) {
                this.form.get('hdmi1UseForWebRtc').setValue(false);
            }

            this.form.get($el.prop('name')).setValue(true);
        }
    },

    /**
     * @param {Object} data
     */
    handleFormChange: function(data) {
        var index = data.input.form.$el.data('index');

        if (index === undefined) {
            this.actionView.open();
            this.checkActionForm();
            this.changes = true;
        } else if (this.actionViewStreamInput[index]) {
            if (!this.deviceService.isCboxProDualProjection()) {
                if (this.formStreamInput[index].get('mode').value === 'none') {
                    this.deviceConnection
                        .send([
                            { command: 'getPipStreamInput' },
                            { command: 'getPipFunction' }
                        ])
                        .then(function(input, pip) {
                            if (input.index === index && pip.enable) {
                                data.input.form.$el.find('#pip-warning').show();
                                this.actionViewStreamInput[index].disableSubmitButton();
                            } else {
                                data.input.form.$el.find('#pip-warning').hide();
                            }
                        }.bind(this));
                } else {
                    data.input.form.$el.find('#pip-warning').hide();
                }
            }

            this.actionViewStreamInput[index].open();
            this.changes = true;
            this.checkActionForm(index);
        }

        if (data.input.name === 'hdmi1Hdcp' && this.form.get('hdmi1Hdcp').getValue()) {
            app.emit('modal.open', {
                id: 'warning',
                isInfo: true,
                textAlign: 'justify',
                className: 'flexible-width',
                width: '540px',
                messageKey: 'settings.hdcp_copyright',
                successTextKey: 'settings.accept',
                discareTextKey: 'settings.cancel',
                onConfirm: function() {
                    app.emit('modal.open', {
                        id: 'login',
                        user: 'admin',
                        navPrevention: true,
                        preventClose: true,
                        isWarning: true,
                        cancelLoginHandler: function() {
                            this.form.get('hdmi1Hdcp').resetValue();
                            this.emit('modal.close', { id: 'login' });
                        }.bind(this)
                    });
                }.bind(this),
                onDiscare: function() {
                    this.form.get('hdmi1Hdcp').resetValue();
                }.bind(this)
            });
        }
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#input-settings'),
            validationContainer: '.input-group'
        });
    },

    /**
     * @param {jQuery} $item
     * @param {Object|Number} [index]
     */
    initFormActionStreamInput: function($item, index) {
        if (!this.actionViewStreamInput[index]) {
            this.actionViewStreamInput[index] = new FormActionView(app, {
                id: index,
                selector: $item.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this, index),
                onCancel: this.cancelHandler.bind(this, index)
            });
        }

        this.actionViewStreamInput[index].render();
    },

    /**
     * @param {Number} index
     */
    initFormStreamInput: function(index) {
        this.formStreamInput[index] = this.formManager.create({
            el: this.$el.find('#stream-input-settings-' + index),
            validationContainer: '.input-group'
        });
        this.formStreamInput[index].on('change.input', this.handleFormChange.bind(this));
    },

    createSelects: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#input0-type-container'),
            label: 'settings.input_type',
            native: true,
            name: 'input0-type',
            items: selectItems,
            validation: 'checkVisualizerInput',
            error: i18n.t('settings.input_error_visualizer'),
            onChange: this.inputTypeChanged.bind(this, inputs.hdmi1)
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#input1-type-container'),
            label: 'settings.input_type',
            native: true,
            name: 'input1-type',
            items: selectItems,
            validation: 'checkVisualizerInput',
            error: i18n.t('settings.input_error_visualizer'),
            onChange: this.inputTypeChanged.bind(this, inputs.hdmi2)
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#input0-max-resolution-container'),
            label: 'settings.input_max_resolution',
            native: true,
            name: 'input0-max-resolution',
            items: maxResolutionItems
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#input1-max-resolution-container'),
            label: 'settings.input_max_resolution',
            native: true,
            name: 'input1-max-resolution',
            items: maxResolutionItems
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#uvc-input0-type-container'),
            label: 'settings.input_type',
            native: true,
            name: 'uvc-input0-type',
            items: selectUvcItems,
            validation: 'checkVisualizerInput',
            error: i18n.t('settings.input_error_visualizer'),
            onChange: this.inputTypeChanged.bind(this, inputs.uvc0)
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#uvc-input1-type-container'),
            label: 'settings.input_type',
            native: true,
            name: 'uvc-input1-type',
            items: selectUvcItems,
            validation: 'checkVisualizerInput',
            error: i18n.t('settings.input_error_visualizer'),
            onChange: this.inputTypeChanged.bind(this, inputs.uvc1)
        });
    },

    /**
     * Handle input type change. Only show 'use for webconf' switch when type is camera.
     * If type is other than camera, disable 'use for webconf' switch.
     * @param input
     * @param newValue
     */
    inputTypeChanged: function(input, newValue) {
        let el;
        let formId;

        switch (input) {
            case inputs.hdmi1:
                el = this.$useForWebConfHdmi1;
                formId = 'hdmi1UseForWebRtc';
                break;
            case inputs.hdmi2:
                el = this.$useForWebConfHdmi2;
                formId = 'hdmi2UseForWebRtc';
                break;
            case inputs.uvc0:
                el = this.$useForWebConfUvc0;
                formId = 'uvc-input0-use-for-webRTC';
                break;
            case inputs.uvc1:
                el = this.$useForWebConfUvc1;
                formId = 'uvc-input1-use-for-webRTC';
                break;
        }

        if (inputTypes.camera === newValue) {
            el.show();
        } else {
            this.form.get(formId).setValue(false);
            el.hide();
        }
    },

    /**
     * Sort object array based on resolution and framerate.
     *
     * @param a - item to compare
     * @param b - item to compare
     * @returns {number}
     */
    compareResolution: function(a, b) {
        // Auto is always the first option.
        if (a.text === i18n.t('settings.auto')) {
            return -1;
        }

        if (a.resolutionValue > b.resolutionValue
            || (a.resolutionValue === b.resolutionValue && a.framerateValue > b.framerateValue)) {
            return -1;
        }
        if (a.resolutionValue < b.resolutionValue
            || (a.resolutionValue === b.resolutionValue && a.framerateValue < b.framerateValue)) {
            return 1;
        }

        return 0;
    },

    updateUvcResolutionSelects: function(index, deviceName, selectedMode) {
        let device = this.uvcDevices.find(function(dev) {
            return dev.value === deviceName;
        });

        let items = [];

        if (device) {
            items = items.concat(device.videoModes.map(function(videoMode) {
                return this.videoModeToSelectOption(videoMode);
            }.bind(this)));
        }

        items.sort(this.compareResolution);

        let selected;

        if (selectedMode) {
            let elementInList = items.find(function(elem) {
                let elemInList = this.videoModeFromSelectValue(elem.value);

                return _.isEqual(elemInList, selectedMode);
            }.bind(this));

            if (elementInList) {
                selected = elementInList.value;
            } else {
                let addedElement = this.videoModeToSelectOption(selectedMode);

                items = [
                    addedElement
                ].concat(items);

                selected = addedElement.value;
            }
        } else {
            if (items.length === 0) {
                items = [
                    // If no mode is selected and list is empty, add default option
                    this.videoModeToSelectOption({})
                ];
            }
            selected = '';
        }

        this.emit('select.uvc-input' + index + '-resolution.update', {
            items: items,
            selected: selected
        });
    },

    deviceChanged: function(index, devices,  newValue) {
        this.checkAutostartSupport(index, devices, newValue);

        let selectedMode;

        if (index === 0 && this.uvc0Device.value === newValue) {
            selectedMode = this.uvc0Device.videoMode;
        } else if (index === 1 && this.uvc1Device.value === newValue) {
            selectedMode = this.uvc1Device.videoMode;
        } else {
            selectedMode = {};
        }

        this.updateUvcResolutionSelects(index, newValue, selectedMode);
    },

    createUvcSelects: function() {
        // Copy the array so the original array won't get affected.
        var itemsInput0 = this.uvcDevices.slice(0);
        var itemsInput1 = this.uvcDevices.slice(0);

        // RELEASE-4521 always add the configured UVC0 and UVC1 device to the selection fields
        if (this.uvc0Device.value !== '' && !itemsInput0.find(i => i.value === this.uvc0Device.value)) {
            itemsInput0.push(this.uvc0Device);
        }

        if (this.uvc1Device.value !== '' && !itemsInput1.find(i => i.value === this.uvc1Device.value)) {
            itemsInput1.push(this.uvc1Device);
        }

        // Add default option.
        itemsInput0.unshift({
            'value': '',
            'text': i18n.t('settings.input_select_device')
        });
        itemsInput1.unshift({
            'value': '',
            'text': i18n.t('settings.input_select_device')
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#uvc-input0-device'),
            label: 'settings.input_device',
            native: true,
            name: 'uvc-input0-device',
            items: itemsInput0,
            error: i18n.t('settings.input_error_visualizer'),
            onChange: this.deviceChanged.bind(this, 0, itemsInput0)
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#uvc-input0-resolution'),
            label: 'settings.usb_input_resolution',
            native: true,
            name: 'uvc-input0-resolution',
            info: i18n.t('settings.input_resolution_info'),
            items: []
        });
        this.$el.find('#uvc-input0-resolution').find('.success-message').show();

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#uvc-input1-device'),
            label: 'settings.input_device',
            native: true,
            name: 'uvc-input1-device',
            items: itemsInput1,
            error: i18n.t('settings.input_error_visualizer'),
            onChange: this.deviceChanged.bind(this, 1, itemsInput1)
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#uvc-input1-resolution'),
            label: 'settings.usb_input_resolution',
            native: true,
            name: 'uvc-input1-resolution',
            info: i18n.t('settings.input_resolution_info'),
            items: []
        });
        this.$el.find('#uvc-input1-resolution').find('.success-message').show();

        this.updateUvcResolutionSelects(0, this.uvc0Device.value, this.uvc0Device.videoMode);
        this.updateUvcResolutionSelects(1, this.uvc1Device.value, this.uvc1Device.videoMode);
    },

    /**
     * Check if autostart is supported and show/hide slider.
     *
     * @param index uvc-input index (0/1)
     * @param newValue
     */
    checkAutostartSupport: function(index, devices, newValue) {
        var $el = index === 0 ? this.$uvcInput0AutostartContainer : this.$uvcInput1AutostartContainer;
        var form = this.form.get('uvc-input' + index + '-autostart');

        var selectedDevice = $.grep(devices, function(d) {
            return d.value === newValue;
        }.bind(this));

        if (selectedDevice.length > 0 && selectedDevice[0].autostartSupport) {
            $el.attr('data-supported', 'true');
            $el
                .slideDown()
                .removeClass('hidden');

            return;
        }

        $el
            .slideUp()
            .addClass('hidden');
        $el.attr('data-supported', 'false');
        form.setValue(false);
    },

    createInputSelects: function($item, index) {
        this.createComponent({
            type: 'CustomSelect',
            container: $item.find('.stream-mode'),
            label: 'settings.stream_mode',
            native: true,
            name: 'mode',
            id: 'mode-' + index,
            items: [
                {
                    text: 'settings.none',
                    value: 'none'
                },
                {
                    text: 'settings.generic',
                    value: 'generic'
                },
                {
                    text: 'settings.rtsp_rtp_tcp',
                    value: 'rtspRtp'
                },
                {
                    text: 'settings.rtsp_rtp_udp_multicast',
                    value: 'multicast'
                }
            ]
        });

        this.createComponent({
            type: 'CustomSelect',
            container: $item.find('#stream-type'),
            label: 'settings.input_type',
            native: true,
            name: 'type',
            id: 'type-' + index,
            items: selectStreamInputItems
        });
    },

    destroy: function() {
        var count = 0;

        _.each(this.formStreamInput, function(item) {
            this.formManager.destroy(item);

            if (this.actionViewStreamInput[count]) {
                this.actionViewStreamInput[count].destroy();
            }
            count++;
        }.bind(this));

        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    hasChanges: function() {
        if (!this.actionView || !this.actionViewStreamInput) {
            return {
                hasChanges: false,
                invalid: false
            };
        }

        const invalidFormFound = this.actionView.$submitBtn.prop('disabled')
            || this.actionViewStreamInput.findIndex(av => av.$submitBtn.prop('disabled')) > -1;

        return {
            hasChanges: this.changes,
            invalid: invalidFormFound,
            waitOnSave: true
        };
    }
});
