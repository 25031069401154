'use strict';

const app = require('../../../app');
const tpl = require('./troubleshoot.html');
const vueUtils = require('../../../components-vue/util');

app.component('Troubleshoot', {
    engine: 'vue',
    template: tpl,

    data: function() {
        return {
            uvcDevices: [],
            usbMics: [],
            usbSpeakers: [],
            isZeroConfig: false,
            usbDefaultDevices: {
                video: '',
                mic: '',
                speaker: ''
            }
        };
    },

    computed: {
        /**
         * Show default USB devices when no configuration was made (zero config) or device is a Cynap Pure.
         */
        showDefaultUsbDevices: function() {
            return this.isZeroConfig || this.deviceService.isCboxPure();
        },

        usbDeviceSummary: function() {
            const uvcDevicesTitle = `--- ${this.i18n('settings.uvc_devices')} ---`;
            const usbMicsTitle = `--- ${this.i18n('settings.usb_mics')} ---`;
            const usbSpeakersTitle = `--- ${this.i18n('settings.usb_speakers')} ---`;
            const noDevicesDetected = this.i18n('settings.no_devices_detected');

            let summary = uvcDevicesTitle + '\n';
            if (this.uvcDevices.length === 0) {
                summary += noDevicesDetected + '\n';
            }
            this.uvcDevices.forEach(device => {
                summary += device.name + '\n';
            });

            summary += '\n' + usbMicsTitle + '\n';
            if (this.usbMics.length === 0) {
                summary += noDevicesDetected + '\n';
            }
            this.usbMics.forEach(mic => {
                summary += mic + '\n';
            });

            summary += '\n' + usbSpeakersTitle + '\n';
            if (this.usbSpeakers.length === 0) {
                summary += noDevicesDetected + '\n';
            }
            this.usbSpeakers.forEach(speaker => {
                summary += speaker + '\n';
            });

            return summary;
        },

        hasDefaultUvcDevice: function() {
            return !!this.usbDefaultDevices.video;
        },

        defaultUvcDevice: function() {
            return this.usbDefaultDevices.video ? this.usbDefaultDevices.video : this.i18n('settings.no_devices_detected');
        },

        defaultUsbMic: function() {
            return this.usbDefaultDevices.mic ? this.usbDefaultDevices.mic : this.i18n('settings.no_devices_detected');
        },

        defaultUsbSpeaker: function() {
            return this.usbDefaultDevices.speaker ? this.usbDefaultDevices.speaker : this.i18n('settings.no_devices_detected');
        }
    },

    methods: {
        testDefaultUvcDevice: function() {
            this.wolfprot.talk('setTestUvcZeroConfig');
            app.emit('overlay.close');
        },

        i18n: vueUtils.i18n
    },

    created: function() {
        this.uriService = app.getService('URIService');
        this.deviceService = app.getService('DeviceService');
        this.wolfprot = vueUtils.wolfprot();

        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return this.wolfprot.talkMulti([
                    'getZeroConfig',
                    'getUvcInputDevices',
                    'getUsbMicCardList',
                    'getUsbSpeakerCardList'
                ]);
            }.bind(this)
        });
        this.pollHelper.on('data', function(response) {
            this.isZeroConfig = response[0].active;
            this.usbDefaultDevices = response[0].usbDefaultDevices;
            this.uvcDevices = response[1].uvcDeviceList;
            this.usbMics = response[2].cardList.map(mic => {
                return this.uriService.unescape(mic);
            });
            this.usbSpeakers = response[3].cardList.map(speaker => {
                return this.uriService.unescape(speaker);
            });
        }.bind(this));

        this.pollHelper.schedulePoll();

        this.evctx = vueUtils.eventContext();
        this.evctx.on('main-loop.update', function() {
            this.pollHelper.schedulePoll();
        }.bind(this));
    },

    destroyed: function() {
        this.evctx.close();
    }
});
