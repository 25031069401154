'use strict';

var app = require('../../../app');
var _ = require('lodash');
var controlsTpl = require('./controls.hbs');
var platform = require('./../../../../platform/platform');

/**
 * Framebox control bar controls
 */
app.component('FrameBoxControls', {
    template: controlsTpl,
    className: 'framebox-header',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.controls = {};
        this.remote = this.getService('RemoteService');
        this.frameBoxService = this.getService('FrameBoxService');
        this.outputService = this.getService('OutputService');
        this.componentInstances = {};
        this.deviceService = this.getService('DeviceService');
        this.isScrollable = false;
    },

    serialize: function() {
        var titleRight;
        var pageCount;

        if (this.options.items) {
            titleRight = this.options.items.filter(function(item) {
                return item.type === 'title-right';
            }.bind(this));

            pageCount = this.options.items.filter(function(item) {
                return item.type === 'page-count';
            }.bind(this));
        }

        return {
            index: this.options.index,
            items: this.options.items,
            titleRight: titleRight,
            pageCount: pageCount,
            isFullscreen: this.options.isFullscreen,
            isMatrix: this.options.isMatrix
        };
    },

    /**
     * Called after DOM has rendered.
     */
    postPlaceAt: function() {
        this.bindEvents();
        this.storeSelectors();
        this.bindDOMEvents();
        this.initControls();
        this.checkIsScrollable();
        this.worker();
    },

    storeSelectors: function() {
        this.$controlBar = this.$el.closest('.framebox-control-bar-item');
        this.$mainControls = this.$el.find('#framebox-controls-container-main');
        this.$subControls = this.$el.find('#framebox-controls-container-submenu');
        this.$subMenu = app.$el.find('.sub-menu-container');
        this.$controlsList = this.$el.find('#controls-list');
        this.$navControlsContainer = this.$el.find('#nav-controls-container');
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        this.on('framebox.controls.focus', this.setFocusControls.bind(this));
        this.on('framebox.mode.pinky', this.startPinkyMode.bind(this));
        this.on('framebox.mode.brain', this.startBrainMode.bind(this));
        this.on('framebox.submenu.change', this.submenuHandler.bind(this));
        this.on('framebox.control-state.change', this.setItemStateHandler.bind(this));
        this.on('framebox.control-state.hide', this.hideItemHandler.bind(this));
        this.on('control-bar.pin.update', this.updateControlBarPin.bind(this));
        this.on('framebox.submenu.toggle', this.toggleSubMenu.bind(this));
        this.on('framebox.submenu.close-all', this.closeAllSubMenus.bind(this));
        this.on('livestream-size.update', this.resize.bind(this));
        this.on('aspect-ratio.changed', this.resize.bind(this));
        this.on('reinit.scrolling.' + this.index, this.checkIsScrollable.bind(this));
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.$el.on('click', '.framebox-option-close', this.closeFrameBox.bind(this));
        this.$el.on('click', '.framebox-option-toggle-fullscreen', this.openFullScreen.bind(this));
        this.$el.on('click', '.framebox-option-toggle-hdmi-out-2', this.toggleHdmiOut2Framebox.bind(this));
        this.$controlsList.on('scroll', this.scroll.bind(this));
    },

    /**
     * Only send a close event.
     */
    closeFrameBox: function() {
        // Block controls when ui is invisible.
        if (!this.frameBoxService.isUiVisible()) {
            return;
        }

        this.emit('framebox.close', {
            index: this.index
        });
    },

    /**
     * Send an event to opne the fullscreenmode.
     */
    openFullScreen: function() {
        // Block controls when ui is invisible.
        if (!this.frameBoxService.isUiVisible()) {
            return;
        }

        this.emit('framebox.fullscreen.open', {
            index: this.index
        });
    },

    /**
     * Send an event to toggle hdmi out 2 framebox.
     */
    toggleHdmiOut2Framebox: function() {
        this.emit('framebox.toggle.hdmi-out-2', {
            index: this.index
        });
    },

    /**
     * Update pin button in control bar.
     * @param pinState
     */
    updateControlBarPin: function(pinState) {
        this.emit('framebox.control-state.change', {
            index: this.index,
            id: 'pin',
            state: pinState
        });
    },

    resize: function() {
        this.closeAllSubMenus();
        this.checkIsScrollable();
    },

    scroll: function() {
        if (this.deviceService.isCboxProDualProjection()) {
            this.closeAllSubMenus();
        }
        this.emit('control-bar-timer.start');
        this.setScrollPos();
    },

    checkIsScrollable: function() {
        this.isScrollable = this.$controlsList.get(0).scrollWidth > this.$navControlsContainer.innerWidth();

        if (this.isScrollable) {
            this.$navControlsContainer.addClass('is-scrollable');
            this.setScrollPos();
        } else {
            this.$navControlsContainer.attr('data-scroll-pos', '');
            this.$navControlsContainer.removeClass('is-scrollable');
        }
    },

    setScrollPos: function() {
        const menu = this.$controlsList.get(0);

        if (menu.scrollLeft === 0) {
            this.$navControlsContainer.attr('data-scroll-pos', 'left');
        } else if (menu.scrollWidth - Math.round(menu.scrollLeft) === menu.clientWidth) {
            this.$navControlsContainer.attr('data-scroll-pos', 'right');
        } else {
            this.$navControlsContainer.attr('data-scroll-pos', 'between');
        }
    },

    /**
     * @method initControls
     */
    initControls: function() {
        if (this.options.items) {
            this.options.items.forEach(function(item) {
                if (item.type) {
                    this.initTypeControl(item);
                } else {
                    this.initCustomControl(item);
                }
            }.bind(this));
        }
    },

    /**
     * @method initTypeControl
     * @param {object} item
     */
    initTypeControl: function(item) {
        var bindTypeDOMEvents = this.bindTypeDOMEventsHandlers[item.type] || this.$.noop;
        var typeHandler = this.typeHandlers[item.type] || this.$.noop;
        var el = {
            id: item.id,
            type: item.type,
            stateName: item.state,
            selector: '#framebox-' + this.index + '-header-control-' + item.id
        };

        // Why do we need clone?
        // ??? this.typeStates is global???
        el.states = _.clone(this.typeStates[item.type]);
        el.$el = this.$el.find(el.selector);

        if (!this.options.isFullscreen && item.onlyFullscreen) {
            el.$el.addClass('hidden');
        } else {
            el.$el.removeClass('hidden');
            el.$el.css({
                display: 'inline-block'
            });
        }

        if (typeof el.states === 'object') {
            _.forEach(el.states, function(state, key) {
                el.states[key] = {
                    $el: el.$el.find('.framebox-header-control-state-' + key)
                };
            });

            this.setItemState(el);
        }

        typeHandler.call(this, item);
        bindTypeDOMEvents.call(this, el, item.id);

        this.controls[item.id] = el;
    },

    /**
     * @method initCustomControl
     * @param {object} item
     */
    initCustomControl: function(item) {
        var bindTypeDOMEvents = this.bindTypeDOMEventsHandlers[item.id] || this.$.noop;
        var el = {
            id: item.id,
            useHold: item.useHold,
            states: {},
            stateName: item.state,
            selector: '#framebox-' + this.index + '-header-control-' + item.id
        };

        el.$el = this.$el.find(el.selector);

        _.forEach(item.states, function(state, key) {
            el.states[key] = {
                $el: el.$el.find('.framebox-header-control-state-' + key),
                icon: state.icon,
                disabled: state.disabled,
                isActive: state.isActive
            };
        });

        if (item.hidden || (!this.options.isFullscreen && item.onlyFullscreen)) {
            el.$el.addClass('hidden');
        } else {
            el.$el.removeClass('hidden');
        }

        this.checkIsScrollable();
        this.setItemState(el);
        this.bindItemEvents(el);
        bindTypeDOMEvents.call(this, el, item.id);

        this.controls[item.id] = (el);
    },

    /**
     * @method setItemStateHandler
     * @param {object} options
     */
    setItemStateHandler: function(options) {
        var stateName = options.state;
        var el = this.controls[options.id];

        if (el && options.index === this.index) {
            this.setItemState(el, stateName);
        }
    },

    /**
     * Handle hide/show item.
     *
     * @param options.hidden true/false
     *        options.id Item id
     */
    hideItemHandler: function(options) {
        var hidden = options.hidden;
        var el = this.controls[options.id];

        if (!el || options.index !== this.index) {
            return;
        }

        var $spacingEl = (el.$el.next()).hasClass('framebox-header-control-spacing') ? el.$el.next() : undefined;

        if (hidden) {
            el.$el.addClass('hidden');

            if ($spacingEl) {
                $spacingEl.addClass('hidden');
            }
        } else {
            el.$el.removeClass('hidden');

            if ($spacingEl) {
                $spacingEl.removeClass('hidden');
            }
        }

        this.checkIsScrollable();
    },

    /**
     * @method setItemState
     * @param {Object} el
     * @param {String} [newStateName]
     */
    setItemState: function(el, newStateName) {
        var stateName = newStateName || el.stateName;
        var state = el.states[stateName];

        if (state) {
            this.disableEl(state.disabled, el);
            this.hideAllStates(el.states);
            state.$el.css({
                display: 'inline-block'
            });
        }
    },

    /**
     * Disable given element.
     *
     * @param {Boolean} disabled
     * @param {Object} el
     */
    disableEl: function(disabled, el) {
        if (disabled) {
            el.$el.children(0).addClass('is-disabled');
            this.remote.disable(el.$el.children(0));
        } else {
            el.$el.children(0).removeClass('is-disabled');
            this.remote.enable(el.$el.children(0));
        }

        this.checkIsScrollable();
    },

    /**
     * @param {Array} states
     */
    hideAllStates: function(states) {
        _.forEach(states, function(state) {
            state.$el.hide();
        });
    },

    /**
     * @param {Object} el
     */
    bindItemEvents: function(el) {
        el.$el.find('button').on('click', this.onControlClick.bind(this, el));
        el.$el.find('button').on('mousedown touchstart', this.onPressedHandler.bind(this, el));
        el.$el.find('button').on('mouseup touchend', this.onReleasedHandler.bind(this, el));
    },

    /**
     * @param {object} el
     * @param {object} event
     */
    onControlClick: function(el, event) {
        var handler = this.options.onClick || this.$.noop;

        if (!el.$el.hasClass('is-submenu-open')) {
            this.closeAllSubMenus();
        }

        // Block controls when ui is invisible.
        if (!this.frameBoxService.isUiVisible() || el.$el.children(0).hasClass('is-disabled')) {
            return;
        }

        if (!el.useHold) {
            handler(el.id, event);
        }
    },

    /**
     * @method onPressedHandler
     * @param {object} el
     */
    onPressedHandler: function(el) {
        var handler = this.options.onHoldStart || this.$.noop;

        this.controlEl = el;

        // Block controls when ui is invisible.
        if (!this.frameBoxService.isUiVisible() || el.$el.children(0).hasClass('is-disabled')) {
            return;
        }

        if (!!this.controlEl && !!this.controlEl.useHold) {
            handler.call(this, this.controlEl.id);
        }
    },

    /**
     * @method onReleasedHandler
     */
    onReleasedHandler: function() {
        var handler = this.options.onHoldEnd || this.$.noop;

        // Block controls when ui is invisible.
        if (!this.frameBoxService.isUiVisible() || (this.controlEl && this.controlEl.$el.children(0).hasClass('is-disabled'))) {
            return;
        }

        if (this.controlEl && this.controlEl.useHold) {
            handler.call(this, this.controlEl.id);
        }

        this.controlEl = null;
    },

    /**
     * @method worker
     */
    worker: function() {
        var handler = this.options.onHold || this.$.noop;

        if (this.options.onHold) {
            this.workerInterval = setInterval(function() {
                if (!!this.controlEl && !!this.controlEl.useHold) {
                    handler.call(this, this.controlEl.id);
                }
            }.bind(this), 100);
        }
    },

    /**
     * Start Standard Mode. (Hide controls)
     */
    startPinkyMode: function(options) {
        if (options.index === this.index && !this.options.isFullscreen) {
            if (!this.options.isFullscreen) {
                this.remote.destroyArea(this.$controlBar);
            }
        }
    },

    /**
     * Start Control Mode. (Show controls)
     */
    startBrainMode: function(options) {
        if (options.index === this.index) {
            this.submenuHandler(this.index, false);
            this.setFocusControls(options);
        }
    },

    /**
     * @method setFocusControls
     */
    setFocusControls: function(options) {
        if (options.index === this.index && platform.checks.isCbox) {
            this.remote.focusArea(this.$controlBar, {
                area: 'button, .focusable',
                trapped: true,
                endless: true
            });
        }
    },

    typeStates: {
        'mute': {
            mute: {},
            unmute: {}
        },
        'volume': '',
        'url': '',
        'message': '',
        'annotation': '',
        'title-left': '',
        'title-right': '',
        'page-count': '',
        'pin': {
            'pinned': {},
            'unpinned': {}
        }
    },

    typeHandlers: {
        'volume': function() {
            let volumeContainer = this.$el.find('.framebox-header-volume');

            if (this.deviceService.isCboxProDualProjection()) {
                // In control screen mode volume control has a different container
                volumeContainer = this.$el.find('.volume-v3');
            }

            this.componentInstances.volume = this.createComponent({
                type: 'FrameboxVolume',
                container: volumeContainer,
                index: this.options.index,
                appId: this.options.appId
            });
        },
        'url': function() {
            this.componentInstances.url = this.createComponent({
                type: 'FrameboxUrl',
                container: this.$el.find('.framebox-' + this.index + '-header-url'),
                index: this.options.index,
                appId: this.options.appId,
                frameBoxOptions: this.options.frameBoxOptions
            });
        },
        'message': function(item) {
            this.componentInstances.message = this.createComponent({
                type: 'FrameboxMessage',
                container: this.$el.find('.framebox-' + this.index + '-header-message'),
                index: this.options.index,
                appId: this.options.appId,
                transKey: item.transKey
            });
        },
        'annotation': function() {
            if (!this.componentInstances.annotation) {
                this.componentInstances.annotation = this.createComponent({
                    type: 'FrameboxAnnotation',
                    container: this.$el.find('.framebox-header-annotation'),
                    index: this.options.index,
                    appId: this.options.appId
                });
            }
        },
        'page-count': function() {
            var $container =  this.$el.find('.framebox-header-page-count-container');

            $container.css({
                display: 'flex'
            });

            this.componentInstances.pageCount = this.createComponent({
                type: 'FrameboxPageCount',
                container: $container,
                index: this.options.index,
                appId: this.options.appId,
                insertType: 'first'
            });
        }
    },

    bindTypeDOMEventsHandlers: {
        'mute': function(el) {
            el.$el.on('click', function() {
                this.closeAllSubMenus();
                this.emit('framebox.mute.toggle', {
                    index: this.index
                });
            }.bind(this));
        },
        'volume': function(el) {
            el.$el.find('button').on('click', function(evt) {
                if (this.deviceService.isCboxProDualProjection()) {
                    this.emit('framebox.volume.toggle', {
                        index: this.index,
                        triggerCoords: evt.currentTarget.getBoundingClientRect()
                    });
                } else {
                    this.emit('framebox.volume.open', {
                        index: this.index
                    });
                }
            }.bind(this));
        },
        'url': function(el) {
            el.$el.on('click', function() {
                this.emit('framebox.url.open', {
                    index: this.index
                });
            }.bind(this));
        },
        'annotation': function(el, id) {
            el.$el.on('click', function(event) {
                var $element = this.$(event.currentTarget);

                if ($element.hasClass('js-toggle-annotation')) {
                    this.emit('framebox.annotation.open', {
                        index: this.index,
                        id: id
                    });
                }
            }.bind(this));
        },
        'title-left': function(el) {
            this.on('framebox.controls.title-left', function(options) {
                if (this.index === options.index) {
                    el.$el.html(options.text);
                }
            }.bind(this));
        },
        'title-right': function(el) {
            this.on('framebox.controls.title-right', function(options) {
                if (this.index === options.index) {
                    el.$el.html(options.text);
                }
            }.bind(this));
        },
        'rewind': function(el) {
            this.on('framebox.controls.rewind', function(options) {
                if (this.index === options.index) {
                    el.$el.find('#framebox-btn-info').text(options.text);
                }
            }.bind(this));
        },
        'forward': function(el) {
            this.on('framebox.controls.forward', function(options) {
                if (this.index === options.index) {
                    el.$el.find('#framebox-btn-info').text(options.text);
                }
            }.bind(this));
        },
        'pin': function(el) {
            this.setItemState(el, this.frameBoxService.controlBarPinned.getState());

            el.$el.on('click', function() {
                this.emit('control-bar.pin.toggle');
            }.bind(this));
        }
    },

    closeAllSubMenus: function() {
        this.emit('framebox.submenu.close');
        this.$el.find('li').removeClass('is-submenu-open');
    },

    closeSubMenu: function(options) {
        if (!options) {
            return;
        }

        options.el.removeClass('is-submenu-open');
    },

    openSubMenu: function(options) {
        this.closeAllSubMenus();

        if (!options) {
            return;
        }

        const submenu = options.el.find('.is-' + options.id);
        const triggerCoords = options.el.find('button.framebox-bar-item').get(0).getBoundingClientRect();
        const spaceBetween = 2;

        submenu.css('left', triggerCoords.x);
        submenu.css('top', triggerCoords.y - spaceBetween);
        options.el.addClass('is-submenu-open');
    },

    toggleSubMenu: function(options) {
        if (!options) {
            return;
        }

        if (options.el.hasClass('is-submenu-open')) {
            this.closeSubMenu(options);
        } else {
            this.openSubMenu(options);
        }
    },

    /**
     * Handle submenu open/close.
     * @param index
     * @param opened
     */
    submenuHandler: function(index, opened, $submenu) {
        if (this.index !== index) {
            return;
        }

        this.$el.find('.framebox-submenu').removeClass('opened');

        if (opened) {
            this.$mainControls.addClass('hidden');
            this.$subControls.removeClass('hidden');

            $submenu.addClass('opened');
        } else {
            this.$subControls.addClass('hidden');
            this.$mainControls.removeClass('hidden');
        }

        this.checkIsScrollable();
    },

    /**
     * @method destroy
     */
    destroy: function() {
        clearInterval(this.workerInterval);
    }
});
