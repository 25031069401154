'use strict';

const app = require('../app');
const StateMachine = require('./../state-machine');
const platform = require('../../platform/platform');
const _ = require('lodash');
const $ = require('jquery');

const raucSourceStates = require('../states').raucSourceStates;

const states = {
    missing: 'missing',
    available: 'available',
    loaded: 'loaded',
    rejected: 'rejected'
};

const fileStates = {
    none: 'none',
    found: 'found',
    skip: 'skip' // If rejected or loaded
};

const mountStates = require('./../../app/states').mountStates;

app.service('MountService', function(app) {
    return {

        /**
         * @method initialize
         */
        initialize: function() {
            this.mountList = null;
            this.cloudAuth = app.getService('CloudAuthenticationService');
            this.authService = app.getService('AuthenticationService');
            this.deviceService = app.getService('DeviceService');
            this.userSettingsService = app.getService('UserSettingsService');
            this.screensaverService = app.getService('ScreensaverService');

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;

                    this.bindEvents();
                    this.onUpdateHandler();
                }.bind(this));

            this.usbState = new StateMachine({
                context: this,
                state: states.loaded,
                states: states
            });
            this.lastUsbMountState = new StateMachine({
                state: mountStates.notResponding,
                states: mountStates
            });

            this.featureState = new StateMachine({
                context: this,
                state: fileStates.none,
                states: fileStates
            });

            this.supportState = new StateMachine({
                context: this,
                state: fileStates.none,
                states: fileStates
            });

            this.firmwareState = new StateMachine({
                context: this,
                state: fileStates.none,
                states: fileStates
            });

            // No need to add state transitions on aux --> only cause rbac problems
            if (!platform.checks.isCboxAux) {
                this.addTransitions();
            }
        },

        /**
         * @method bindEvents
         */
        bindEvents: function() {
            app.on('main-loop.update', this.onUpdateHandler.bind(this));
            app.on('main-loop.update.mount', this.onUpdateHandler.bind(this));
        },

        /**
         * @method onUpdateHandler
         */
        onUpdateHandler: function() {
            if (this.authService.getIsCollab() || this.authService.getIsViewer()
                || (this.authService.getIsLdapActive() && !this.authService.getIsLdapAuthenticated())) {
                return;
            }

            this.deviceConnection
                .send([
                    'getUsbFeatureUpdateStatus',
                    'getFwUpdateStatus',
                    {
                        command: 'getRaucUpdateStatus',
                        data: {
                            source: 'usb'
                        }
                    },
                    'getUsbSupportUpdateStatus',
                    'getUserDataStatus',
                    'getMounts'
                ]).then(function(feature, firmwareLegacy, firmwareRauc, support, userData, mounts) {
                    const showSupport = this.supportState.getState() !== fileStates.skip
                        && support.state === fileStates.found;
                    const showFeature = this.featureState.getState() !== fileStates.skip
                        && feature.state === fileStates.found;
                    const showFirmware = this.firmwareState.getState() !== fileStates.skip
                        && (firmwareRauc.updateCheckStatus === raucSourceStates.found
                            || (firmwareLegacy.state === fileStates.found
                                && firmwareRauc.updateCheckStatus !== raucSourceStates.busy));

                    if (!this.screensaverService.isScreensaverActiveUI()
                        && !this.screensaverService.isScreensaverActive()) {
                        if (showSupport) {
                            if (support.state !== this.supportState.getState()) {
                                this.supportState.changeState(support.state);
                            }
                        } else if (showFeature) {
                            if (feature.state !== this.featureState.getState()) {
                                this.featureState.changeState(feature.state);
                            }
                        } else if (showFirmware) {
                            if (fileStates.found !== this.firmwareState.getState()) {
                                this.firmwareState.changeState(fileStates.found);
                            }
                        } else if (userData.userData !== this.usbState.getState()) {
                            this.usbState.changeState(userData.userData);
                        }
                    }

                    this.parseMounts(mounts);
                    this.handleUSB();
                }.bind(this));
        },

        handleUSB: function() {
            var isMounted;

            if (!this.mountList || !this.mountList.USB) {
                return;
            }

            if (this.mountList.USB.state !== this.lastUsbMountState.getState()) {
                isMounted = this.mountList.USB.state === mountStates.mounted;

                app.emit('usb.mount.changed', {
                    mounted: isMounted
                });

                this.lastUsbMountState.changeState(this.mountList.USB.state);

                // Reset skip states to none.
                if (!isMounted) {
                    this.supportState.changeState(fileStates.none);
                    this.featureState.changeState(fileStates.none);
                    this.firmwareState.changeState(fileStates.none);
                }
            }
        },

        /**
         * Checks if there is a snapshot on the Internal disk.
         */
        checkHasSnapshots: function() {
            const dfd = $.Deferred();

            this.deviceConnection
                .send([{
                    command: 'getFileList',
                    data: {
                        pathname: 'Internal:///snapshots/'
                    }
                }])
                .then(function(files) {
                    dfd.resolve(0 < files.fileList.length);
                }.bind(this), function() {
                    dfd.resolve(false);
                });

            return dfd.promise();
        },

        /**
         * Checks if there is a recording on the Internal disk.
         */
        checkHasRecordings: function() {
            const dfd = $.Deferred();

            this.deviceConnection
                .send([{
                    command: 'getFileList',
                    data: {
                        pathname: 'Internal:///recordings/'
                    }
                }])
                .then(function(files) {
                    dfd.resolve(0 < files.fileList.length);
                }.bind(this), function() {
                    dfd.resolve(false);
                });

            return dfd.promise();
        },

        /**
         * Checks if there is a whiteboard on the Internal disk.
         */
        checkHasWhiteboards: function() {
            const dfd = $.Deferred();

            this.deviceConnection
                .send([{
                    command: 'getFileList',
                    data: {
                        pathname: 'Internal:///whiteboards/'
                    }
                }])
                .then(function(files) {
                    dfd.resolve(0 < files.fileList.length);
                }.bind(this), function() {
                    dfd.resolve(false);
                });

            return dfd.promise();
        },

        parseMounts: function(mounts) {
            var mountList = mounts.mountList;
            var parsedList = {};

            if (mountList) {
                mountList.forEach(function(mount) {
                    mount = this.createMount(mount);
                    parsedList[mount.id] = mount;
                }.bind(this));

                this.mountList = parsedList;
            }

            return true;
        },

        createMount: function(mount) {
            var parsedMount = {
                titleKey: mount.name,
                id: mount.id,
                isCloudProvider: this.cloudAuth.getProvider(mount.id),
                state: mount.status,
                writeable: mount.perms === 'rw'
            };

            return parsedMount;
        },

        addTransitions: function() {
            this.usbState.addTransitions({
                '> available': function() {
                    if (!this.deviceService.isCboxPure() && !this.deviceService.isCboxPureMini()) {
                        this.userSettingsService.openUserSettingsDialog(true);
                    }
                },
                '> loaded': function() {
                    // Set loaded to true so that the other instances (remote browser, cbox,...) realize
                    // That the user settings already been loaded.
                    app.emit('user-settings.settings.loaded', { loaded: true });
                    app.emit('modal.close', { id: 'userSettings' });
                    app.emit('user-settings.settings.update');
                },
                '> rejected': function() {
                    app.emit('modal.close', { id: 'userSettings' });
                }
            });

            this.featureState.addTransitions({
                '> found': function() {
                    app.emit('modal.open', {
                        id: 'activateFeature',
                        onDiscare: function() {
                            if (!this.isUSBmounted()) {
                                return;
                            }

                            this.featureState.changeState(fileStates.skip);
                            this.deviceConnection
                                .send('setFeaturePackUSBUpdate', {
                                    load: false
                                });
                        }.bind(this),
                        onConfirm: function() {
                            this.featureState.changeState(fileStates.skip);
                            this.deviceConnection
                                .send('setFeaturePackUSBUpdate', {
                                    load: true
                                });
                        }.bind(this)
                    });
                },
                '> none': function() {
                    app.emit('modal.close', { id: 'activateFeature' });
                }
            });

            this.supportState.addTransitions({
                '> found': function() {
                    app.emit('modal.open', {
                        id: 'activateSupport',
                        onDiscare: function() {
                            if (!this.isUSBmounted()) {
                                return;
                            }

                            this.supportState.changeState(fileStates.skip);
                            this.deviceConnection
                                .send('setSupportYearUSBUpdate', {
                                    load: false
                                });
                        }.bind(this),
                        onConfirm: function() {
                            this.supportState.changeState(fileStates.skip);
                            this.deviceConnection
                                .send('setSupportYearUSBUpdate', {
                                    load: true
                                });
                        }.bind(this)
                    });
                },
                '> none': function() {
                    app.emit('modal.close', { id: 'activateSupport' });
                }
            });

            this.firmwareState.addTransitions({
                '> found': function() {
                    app.emit('modal.open', {
                        id: 'firmwareUpdate',
                        onDiscare: function() {
                            if (!this.isUSBmounted()) {
                                return;
                            }

                            this.firmwareState.changeState(fileStates.skip);
                            this.deviceConnection
                                .send('setUsbFwUpdate', {
                                    load: false
                                });
                        }.bind(this),
                        onConfirm: function() {
                            var role = {};
                            role.name = 'FwUpdate';
                            role.key = 'update';

                            this.authService.checkAccess(role, function() {
                                this.firmwareState.changeState(fileStates.skip);
                                this.deviceConnection
                                    .send('setUsbFwUpdate', {
                                        load: true
                                    });
                            }.bind(this), function() {
                                this.firmwareState.changeState(fileStates.skip);
                                this.deviceConnection
                                    .send('setUsbFwUpdate', {
                                        load: false
                                    });
                                app.emit('modal.close', { id: 'login' });
                                app.emit('modal.close', { id: 'firmwareUpdate' });
                            }.bind(this));
                        }.bind(this)
                    });
                },
                '> none': function() {
                    app.emit('modal.close', { id: 'firmwareUpdate' });
                }
            });
        },

        /**
         * @method parse
         * @param {Array} data
         */
        parse: function(data) {
            var serviceList = data;
            var parsedData = [];

            _.each(serviceList, function(group, key) {
                this.serviceKey = key;
                _.each(group, function(service) {
                    _.each(service, function(service, key) {
                        var serviceData = {};

                        serviceData.name = service.name;
                        serviceData.status = service.status === 1;
                        serviceData.id = this.serviceKey + '.' + key;

                        if (serviceData.status) {
                            this.anyServiceConnected = true;
                        }

                        parsedData.push(serviceData);
                    }.bind(this));
                }.bind(this));
            }.bind(this));

            return parsedData;
        },

        /**
         * @method checkIfServiceAvailable
         * @param {string} serviceId
         */
        checkIfServiceAvailable: function(serviceId) {
            var dfd = $.Deferred();
            var serviceAvailable = false;

            // RELEASE-2461 - USB stick: User settings dialog doesn't appear when clicking on WebDav.
            serviceId = serviceId.toLowerCase();

            this.deviceConnection
                .send([
                    {
                        command: 'getUserSettingsStatus',
                        data: { mode: 'usb' }
                    }
                ])
                .then(function(userSettingsUsb) {
                    var parsedData = this.parse(userSettingsUsb.settings);
                    this.serviceListUsb = parsedData;

                    if (this.mountList.USB.state === mountStates.mounted) {
                        _.forEach(this.serviceListUsb, function(setting) {
                            if (setting.id === serviceId && setting.status) {
                                serviceAvailable = true;
                            }
                        }.bind(this));
                    }

                    dfd.resolve({ serviceAvailable: serviceAvailable });
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Check if USB is mounted.
         * @returns {boolean} true if USB is mounted, else false
         */
        isUSBmounted: function() {
            return (this.mountList && this.mountList.USB && this.mountList.USB.state === mountStates.mounted) === true;
        },

        /**
         * Check if vMeeting is mounted.
         */
        isVMeetingMounted: function() {
            return (this.mountList && this.mountList.VMeeting && this.mountList.VMeeting.state === mountStates.mounted)  === true;
        },

        /**
         * Fetch list of mounts
         * @return {Promise<unknown>}
         */
        fetchMounts: function() {
            return Promise.resolve(this.deviceConnection.send('getMounts').then(function(mounts) {
                return mounts.mountList;
            }));
        }
    };
});
