// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"wlan-access-point-form\" name=\"wlan-access-point-form\">\n    <div class=\"row settings-list-row\" data-nav-area=\".btn-switch\" data-nav-trapped>\n        <div class=\"col-xs-8\">\n            <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.access_point",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":45}}}))
    + "</h2>\n        </div>\n    </div>\n    <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch, .input:not([disabled]), select, .btn-form\" data-nav-trapped>\n        <div class=\"col-xs-8\">\n            <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ssid_auto",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":36},"end":{"line":9,"column":63}}}))
    + "</h2>\n        </div>\n        <div class=\"col-xs-4\">\n            <div class=\"btn-switch-container pull-right\">\n                <input name=\"ssidAuto\" id=\"ssidAuto\" type=\"checkbox\" class=\"btn-switch\" />\n                <div class=\"btn-switch-toggle-container\">\n                    <div class=\"btn-switch-toggle\"></div>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class=\"row settings-list-secundary\" data-nav-area=\"select, .btn-switch, .input:not([disabled]), .is-focusable:not(:disabled)\" data-nav-trapped>\n        <div class=\"col-xs-6\">\n            <div class=\"input-group\">\n                <label class=\"input-label\" for=\"access-point-ssid\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ssid",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":67},"end":{"line":23,"column":89}}}))
    + "</label>\n                <input type=\"text\" class=\"input\" name=\"ssid\" id=\"access-point-ssid\" data-validation=\"requiredOrDisabledAndMaxStrength\" data-max-length=\"32\" />\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.only_32_characters",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":67},"end":{"line":26,"column":102}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n        <div class=\"col-xs-6\">\n            <div id=\"encrypt-select\"></div>\n        </div>\n    </div>\n    <div class=\"row settings-list-secundary\" id=\"security-container\" data-nav-area=\".input:not([disabled])\" data-nav-trapped>\n        <div class=\"col-xs-6\">\n            <div class=\"input-group\" id=\"username-container\">\n                <label class=\"input-label\" for=\"access-point-username\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.username",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":71},"end":{"line":37,"column":97}}}))
    + "</label>\n                <input type=\"text\" class=\"input\" name=\"username\" id=\"access-point-username\"/>\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_username",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":67},"end":{"line":40,"column":99}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n        <div class=\"col-xs-6\">\n            <div class=\"input-group\">\n                <label class=\"input-label\" for=\"keyAccesspoint\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":64},"end":{"line":46,"column":90}}}))
    + "</label>\n                <input type=\"password\"\n                       id=\"keyAccesspoint\"\n                       autocomplete=\"current-password\"\n                       class=\"input\"\n                       name=\"keyAccesspoint\"\n                       data-validation=\"checkWLANPassword\" />\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.wrong_password_length_8_63",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":67},"end":{"line":54,"column":126}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class=\"row settings-list-secundary\" data-nav-area=\".input:not(:disabled)\" data-nav-trapped>\n        <div class=\"col-xs-6\">\n            <div class=\"input-group\">\n                <label class=\"input-label\" for=\"access-point-ipv4\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ip_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":67},"end":{"line":62,"column":94}}}))
    + "</label>\n                <input type=\"text\" class=\"input\" name=\"ip\" id=\"access-point-ipv4\" placeholder=\"0.0.0.0\" data-validation=\"checkApIp\" />\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_ap_ip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":67},"end":{"line":65,"column":95}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n        <div class=\"col-xs-6\">\n            <div class=\"input-group\">\n                <label class=\"input-label\" for=\"access-point-subnet\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.subnet",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":69},"end":{"line":71,"column":92}}}))
    + "</label>\n                <input type=\"text\" class=\"input\" name=\"subnet\" id=\"access-point-subnet\" placeholder=\"0.0.0.0\" data-validation=\"checkSubnet\" />\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.subnet_is_wrong",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":67},"end":{"line":74,"column":99}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch, .input:not([disabled]), select, .is-focusable:not(:disabled)\" data-nav-trapped>\n        <div class=\"col-xs-6\">\n            <div id=\"channel-select\"></div>\n        </div>\n        <div class=\"col-xs-6\">\n            <div id=\"dbm-select\"></div>\n        </div>\n    </div>\n    <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch, .input:not([disabled]), select, .is-focusable:not(:disabled)\" data-nav-trapped>\n        <div class=\"col-xs-6\">\n            <div id=\"max-clients-select\"></div>\n        </div>\n    </div>\n    <div id=\"wlan-wait-for-ap\" class=\"row settings-list-primary wlan-list-item wlan-wait-for-ap hidden\">\n        <div class=\"col-xs-12\">\n            <p><span class=\"loader small\"></span> <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wait_for_ap",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":94,"column":56},"end":{"line":94,"column":84}}}))
    + "</span></p>\n        </div>\n    </div>\n    <div id=\"form-action-container-access-point\" class=\"form-primary-background\"></div>\n</form>\n\n";
},"useData":true});
