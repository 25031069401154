// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "secundary";
},"3":function(container,depth0,helpers,partials,data) {
    return "row";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"routing-settings\" data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":1,"column":42},"end":{"line":1,"column":53}}}) : helper)))
    + "\">\n    <form class=\"routing-item-form\" name=\"routing-settings\" data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":2,"column":72},"end":{"line":2,"column":83}}}) : helper)))
    + "\">\n        <div class=\"row settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"index") : depth0),{"name":"if_even","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":38},"end":{"line":3,"column":88}}})) != null ? stack1 : "")
    + "\"\n             data-nav-area=\".is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n             data-nav-trapped>\n            <div class=\"col-xs-8\">\n                <h2>"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.routing_item_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":56}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input id=\"routing-enabled-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":11,"column":47},"end":{"line":11,"column":58}}}) : helper)))
    + "\" name=\"routingEnabled\" type=\"checkbox\" class=\"btn-switch\"  />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div id=\"routing-content-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":18,"column":33},"end":{"line":18,"column":42}}}) : helper)))
    + "\">\n            <div class=\"row settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"index") : depth0),{"name":"if_even","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":19,"column":42},"end":{"line":19,"column":92}}})) != null ? stack1 : "")
    + "\"\n                 data-nav-area=\".is-focusable:not(:disabled), .input:not([disabled]), .btn-switch\"\n                 data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <div class=\"routingip input-group\">\n                        <label class=\"input-label\" for=\"routingip\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ip_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":67},"end":{"line":24,"column":94}}}))
    + "</label>\n                        <input id=\"ip-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":25,"column":38},"end":{"line":25,"column":49}}}) : helper)))
    + "\"\n                               type=\"text\"\n                               class=\"input is-focusable\"\n                               name=\"routingIp\"\n                               data-validation=\"checkIPv4\" />\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\">\n                                "
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_ip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":32},"end":{"line":32,"column":58}}}))
    + "\n                            </span>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"col-xs-3\">\n                    <div class=\"prefix-select\"></div>\n                </div>\n                <div class=\"col-xs-3\">\n                    <div class=\"device-select\"></div>\n                </div>\n            </div>\n            <div class=\"row settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"index") : depth0),{"name":"if_even","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":44,"column":42},"end":{"line":44,"column":92}}})) != null ? stack1 : "")
    + "\"\n                 data-nav-area=\".is-focusable:not(:disabled), .input:not([disabled]), .btn-switch\"\n                 data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <div class=\"gateway input-group\">\n                        <label class=\"input-label\" for=\"gateway\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.gateway",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":65},"end":{"line":49,"column":89}}}))
    + "</label>\n                        <input id=\"gateway-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":50,"column":43},"end":{"line":50,"column":54}}}) : helper)))
    + "\"\n                               type=\"text\"\n                               class=\"input is-focusable\"\n                               name=\"gateway\"\n                               data-validation=\"checkIPv4\" />\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\">\n                                "
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_ip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":32},"end":{"line":57,"column":58}}}))
    + "\n                            </span>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"col-xs-6 switch-below-text\">\n                    <span class=\"input-label\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.gateway_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":46},"end":{"line":63,"column":77}}}))
    + "</span>\n                    <div class=\"btn-switch-container\">\n                        <input id=\"gateway-enabled-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":65,"column":51},"end":{"line":65,"column":62}}}) : helper)))
    + "\"\n                               name=\"gatewayEnabled\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"  />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </form>\n\n    <div id=\"reboot-info-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":78,"column":25},"end":{"line":78,"column":36}}}) : helper)))
    + "\" class=\"row settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"index") : depth0),{"name":"if_even","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":78,"column":63},"end":{"line":78,"column":113}}})) != null ? stack1 : "")
    + " reboot-info\">\n        <span class=\"input-info-message info-message\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"error_messages.manual_reboot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":54},"end":{"line":79,"column":91}}}))
    + "</span>\n    </div>\n\n    <div class=\"js-form-action-container settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"index") : depth0),{"name":"if_even","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":82,"column":55},"end":{"line":82,"column":105}}})) != null ? stack1 : "")
    + "\" data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":82,"column":119},"end":{"line":82,"column":130}}}) : helper)))
    + "\">\n    </div>\n</div>\n";
},"useData":true});
