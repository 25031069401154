'use strict';

const app = require('../../../app');
const roomControllerTpl = require('./room-controller-settings.hbs');
const FormActionView = require('../form-action-view');

/**
 * Room Controller Settings
 */
app.component('RoomControllerSettings', {
    template: roomControllerTpl,

    initialize: function() {
        this.formManager = this.getService('FormManager');
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.initForm();
        this.initFormAction();
        this.loadSettings()
            .then(function() {
                this.bindEvents();
                this.bindDOMEvents();
            }.bind(this));
    },

    storeSelectors: function() {
        this.$settingsContainer = this.$el.find('#room-controller-settings-container');
        this.$roomControllerUrl = this.$el.find('#roomControllerUrl');
    },

    bindEvents: function() {
        this.on('settings.save-changes', this.saveHandler.bind(this));
    },

    bindDOMEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#room-controller-settings'),
            validationContainer: '.input-group'
        });
    },

    handleFormChange: function() {
        this.actionView.open();
        this.changes = true;

        this.handleChange();
    },

    saveHandler: function() {
        let dfd = this.$.Deferred();

        if (this.form.validate()) {
            const url = this.form.get('roomControllerUrl').getValue();
            let parsedUrl = null;

            if (url !== '') {
                parsedUrl = new URL(url);
            }

            this.deviceConnection
                .send('setRoomControllerSettings', {
                    enabled: this.form.get('roomControllerEnabled').getValue(),
                    roomControllerHost: parsedUrl ? parsedUrl.protocol + '//' + parsedUrl.host : '',
                    roomControllerPathname: parsedUrl ? parsedUrl.pathname + parsedUrl.search : '',
                    roomControllerCert: ''
                }).then(function() {
                    dfd.resolve();

                    this.form.setDefaultValues();
                    this.emit('overlay.remote.focus', true);

                    this.changes = false;
                }.bind(this), function() {
                    dfd.reject();
                }.bind(this));
        } else {
            dfd.resolve();
        }

        return dfd.promise();
    },

    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();

        this.changes = false;

        this.handleChange();
    },

    /**
     * Load settings from backend.
     */
    loadSettings: function() {
        return this.deviceConnection
            .send([
                'getRoomControllerSettings'
            ])
            .then(function(settings) {
                this.formData = {
                    'roomControllerEnabled': settings.enabled,
                    'roomControllerUrl': settings.roomControllerHost + settings.roomControllerPathname
                };

                this.form.setValues(this.formData);
                this.form.setDefaultValues();

                this.handleChange();
            }.bind(this));
    },

    /**
     * Handle Room Controller Enable change. Show or hide settings.
     */
    handleRoomControllerEnableChange: function() {
        if (this.form.get('roomControllerEnabled').getValue()) {
            this.$roomControllerUrl.attr('data-allow-empty', 'false');
            this.$settingsContainer.removeClass('hidden');
        } else {
            this.$roomControllerUrl.attr('data-allow-empty', 'true');
            this.$settingsContainer.addClass('hidden');
        }
    },

    handleChange: function() {
        this.handleRoomControllerEnableChange();
        this.checkActionForm();
    },

    handleUnsavedChanges: function() {
        let dfd = this.$.Deferred();

        if (this.changes) {
            this.emit('modal.open', {
                id: 'settings-save-changes',
                invalid: this.hasChanges().invalid,
                onSave: function() {
                    this.saveHandler()
                        .then(() => dfd.resolve());
                }.bind(this),
                onDiscard: function() {
                    this.cancelHandler();
                    this.actionView.close();
                    dfd.reject();
                }.bind(this),
                onDestroy: function() {
                    if (dfd.state() !== 'resolved') {
                        dfd.reject();
                    }
                }.bind(this)
            });
        } else {
            dfd.resolve();
        }

        return dfd.promise();
    },

    destroy: function() {
        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    hasChanges: function() {
        const changes =  {
            hasChanges: this.changes,
            invalid: !this.form.validate()
        };

        return changes;
    }
});
