// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"pause-annotation-button-container\">\n                <input data-action=\"snapshot\" type=\"button\" class=\"btn-form focusable\" value=\""
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"annotation.end_snapshot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":94},"end":{"line":13,"column":126}}}))
    + "\" />\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"pause-annotation-form\">\n    <div class=\"row\">\n        <div class=\"col-xs-12 end-presentation-question\">\n            <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"annotation.pause_annotation_question",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":60}}}))
    + "</h2>\n        </div>\n    </div>\n    <div class=\"end-annotation-buttons\">\n        <div class=\"pause-annotation-button-container\">\n            <input data-action=\"discare\" type=\"button\" class=\"btn-form focusable\" value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"annotation.end_discare",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":89},"end":{"line":9,"column":120}}}))
    + "\" />\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPure") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":15,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n</form>\n";
},"useData":true});
