const template = require('./volume-controls.html');
const vueUtils = require('../../util.js');
const app = require('../../../app.js');

module.exports = {
    template,

    props: {
        options: {
            type: Object
        },

        small: {
            type: Boolean,
            default: false,
            required: false
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data: function() {
        return {
            volumeInPercent: 70,
            micAvailable: true,
            micMuted: true,
            speakersMuted: false
        };
    },

    created: function() {
        this.deviceService = app.getService('DeviceService');

        this.wolfprot = vueUtils.wolfprot();
        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return this.wolfprot.talkMulti([
                    'getMasterVolume',
                    'getMicMute',
                    'getMasterMute'
                ]).then(function([masterVolume, micMute, masterMute]) {
                    return {
                        masterVolume,
                        micMute,
                        masterMute
                    };
                });
            }.bind(this)
        });
        this.pollHelper.on('data', function({ masterVolume, micMute, masterMute }) {
            this.volumeInPercent = masterVolume.volume;
            this.micAvailable = !(this.deviceService.isCboxPure() || this.deviceService.isCboxPureMini());
            this.micMuted = micMute.mute === 1;
            this.speakersMuted = masterMute.mute === 1;
        }.bind(this));
        this.pollHelper.schedulePoll();

        this.evctx = vueUtils.eventContext();
        this.evctx.on('main-loop.update', function() {
            this.pollHelper.schedulePoll();
        }.bind(this));
    },

    destroyed: function() {
        this.evctx.close();
    },

    methods: {
        /**
         * Toggle Microphone mute
         */
        toggleMicMute: function() {
            this.pollHelper.sync(function() {
                return this.wolfprot.talk('setMicMute', {
                    mute: 'toggle'
                });
            }.bind(this));
        },

        /**
         * Toggle Speaker mute
         */
        toggleSpeakerMute: function() {
            this.pollHelper.sync(function() {
                return this.wolfprot.talk('setMasterMute', {
                    mute: this.speakersMuted ? 0 : 1
                });
            }.bind(this));
        },

        /**
         * Execute Wolfprot to change volume on Cynap.
         */
        updateVolume: function(percent) {
            this.pollHelper.sync(function() {
                return this.wolfprot.talk('setMasterVolume', {
                    volume: percent
                });
            }.bind(this));
        },

        i18n: vueUtils.i18n
    },

    components: {
        'boxed-control': require('../boxed-control/boxed-control.js'),
        'command-button': require('../command-button/command-button.js'),
        'range-input': require('../../range-input/range-input.js')
    }
};
