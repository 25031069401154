'use strict';

var app = require('../../../../app');
var platform = require('../../../../../platform/platform');

var controlItems = [
    {
        id: 'mute',
        type: 'mute',
        state: 'mute'
    },
    {
        id: 'volume',
        type: 'volume'
    }
];

/**
 * Matrix
 */
app.component('FrameBoxMatrix', {
    className: 'framebox-view framebox-matrix-view',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.hasClicked = true;
        this.openTimeout = null;
        this.clickTimeout = null;
        this.frameBoxService = this.getService('FrameBoxService');
        this.volumeService = this.getService('VolumeService');
        this.remote = this.getService('RemoteService');
        this.componentId = null;
        this.prevInputValue = null;
    },

    serialize: function() {
        return {
            isCbox: !!platform.checks.isCbox
        };
    },

    postPlaceAt: function() {
        this.startControls();
        this.bindEvents();
        this.initMatrixFramebox();

        this.onFrameboxChanged({
            options: this.options.frameBoxOptions
        });

        this.volumeService.updateMicStatusWidgetHandler();
    },

    initMatrixFramebox: function() {
        this.options.$actionEl.addClass('is-matrix');
    },

    bindEvents: function() {
        this.on('framebox.change.' + this.index, this.onFrameboxChanged.bind(this));
    },

    /**
     * Handle framebox changed.
     * @param box
     */
    onFrameboxChanged: function(box) {
        this.box = box;
        clearTimeout(this.clickTimeout);
    },

    /**
     * Start controls.
     */
    startControls: function() {
        this.componentId = this.createComponent({
            type: 'FrameBoxControls',
            container: this.$el,
            index: this.options.index,
            appId: this.options.appId,
            items: controlItems,
            isFullscreen: this.options.isFullscreen,
            isMatrix: true
        });
    },

    destroy: function() {
        this.$('html, body').scrollTop(0);

        this.volumeService.updateMicStatusWidgetHandler();
    }
});
