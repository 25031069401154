'use strict';

var app = require('../../../../../app');
var focusTpl = require('./focus.hbs');

app.component('FrameboxFocus', {
    template: focusTpl,
    className: 'framebox-focus-container framebox-submenu-inner',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;

        this.remote = this.getService('RemoteService');
        this.visualizer = this.getService('VisualizerService');
        this.visualizer.bindVzEvents();
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindDOMEvents();
    },

    storeSelectors: function() {
        this.$document = this.$(document);
        this.$focusIn = this.$el.find('#visualizer-focus-in');
        this.$focusOut = this.$el.find('#visualizer-focus-out');
    },

    bindDOMEvents: function() {
        this.on('framebox.focus.open', this.open.bind(this));
        this.on('framebox.focus.close', this.close.bind(this));
        this.on('framebox.submenu.close', this.close.bind(this));
    },

    bindDOMEventsOnOpen: function() {
        this.$focusIn.on('mousedown.visualizer-focus-in', this.PressedHandler.bind(this, 'focus-in'));
        this.$focusIn.on('touchstart.visualizer-focus-in', this.PressedHandler.bind(this, 'focus-in'));
        this.$focusIn.on('mouseup.visualizer-focus-in', this.ReleasedHandler.bind(this, 'focus-in'));
        this.$focusIn.on('touchend.visualizer-focus-in', this.ReleasedHandler.bind(this, 'focus-in'));
        this.$focusOut.on('mousedown.visualizer-focus-out', this.PressedHandler.bind(this, 'focus-out'));
        this.$focusOut.on('touchstart.visualizer-focus-out', this.PressedHandler.bind(this, 'focus-out'));
        this.$focusOut.on('mouseup.visualizer-focus-out', this.ReleasedHandler.bind(this, 'focus-out'));
        this.$focusOut.on('touchend.visualizer-focus-out', this.ReleasedHandler.bind(this, 'focus-out'));
    },

    unbindDOMEvents: function() {
        this.$focusIn.off('mousedown.visualizer-focus-in');
        this.$focusIn.off('touchstart.visualizer-focus-in');
        this.$focusIn.off('mouseup.visualizer-focus-in');
        this.$focusIn.off('touchend.visualizer-focus-in');
        this.$focusOut.off('mousedown.visualizer-focus-out');
        this.$focusOut.off('touchstart.visualizer-focus-out');
        this.$focusOut.off('mouseup.visualizer-focus-out');
        this.$focusOut.off('touchend.visualizer-focus-out');
    },

    /**
     * @param {String} command
     * @param {jQuery.Event} event
     */
    PressedHandler: function(command, event) {
        // Prevents the default context menu on touch devices.
        event.preventDefault();
        var handler = this.visualizer.getPressedHandler(command, {
            index: this.index,
            appId: this.appId
        });

        handler.call(this.visualizer);
    },

    /**
     * @param {String} command
     */
    ReleasedHandler: function(command) {
        var handler = this.visualizer.getReleasedHandler(command, {
            index: this.index,
            appId: this.appId
        });

        handler.call(this.visualizer);
    },

    /**
     * Open visualizer submenu.
     *
     * @param options.index Framebox index
     */
    open: function(options) {
        if (this.index === options.index) {
            this.$el.show();
            this.bindDOMEventsOnOpen();

            this.emit('framebox.submenu.change', this.index, true, this.$el.parent());
        }
    },

    /**
     * Close visualizer submenu.
     */
    close: function() {
        this.$el.hide();
        this.remote.destroyArea(this.$el);
        this.unbindDOMEvents();

        this.emit('framebox.submenu.change', this.index, false);
    }
});
