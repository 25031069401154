'use strict';

var app = require('../../../../app');
var remoteStreamingSettingsTpl = require('./remote-streaming-settings.hbs');
var platform = require('../../../../../platform/platform');
var $ = require('jquery');

require('./youtube-stream-settings/youtube-stream-settings');
require('./custom-stream-settings/custom-stream-settings');
const i18n = require('i18next');

app.component('RemoteStreamingSettings', {
    template: remoteStreamingSettingsTpl,

    /**
     * @method getAccessKey
     * @returns {{roleName: string, roleKey: string}}
     */
    getAccessKey: function() {
        return {
            'roleName': 'StreamingSettings',
            'roleKey': 'show'
        };
    },

    /**
     * @method initialize
     * @returns {Object}
     */
    initialize: function() {
        this.isInitialized = false;
        this.formManager = this.getService('FormManager');
        this.deviceService = this.getService('DeviceService');
    },

    /**
     * @returns {Object}
     */
    serialize: function() {
        return {
            showFullsize: platform.checks.isFullsize(),
            functions: i18n.t('settings.webcasting_title')
        };
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.storeSelectors();
        this.createSelects();
        this.createComponents();
        this.initForm();
        this.bindEvents();

        $.when(this.loadRemoteStreamingSettings()).done(function() {
            this.bindDOMEvents();
            this.checkRemoteStreamingEnabled();
            this.handleRemoteStreamingMode(this.options.remoteContainer);

            this.isInitialized = true;
        }.bind(this));
    },

    /**
     * @method storeSelectors
     */
    storeSelectors: function() {
        this.$remoteStreamingSettings = this.$el.find('.remote-streaming-settings');
        this.$youtubeStreamContainer = this.$el.find('#youtube-stream-container');
        this.$customStreamContainer = this.$el.find('#custom-stream-container');
        this.$hdcpDeactivationInfo = this.$el.find('.wc-hdcp-deactivation-info');
    },

    /**
     * @method createComponents
     */
    createComponents: function() {
        this.createComponent({
            type: 'YouTubeStreamSettings',
            container: this.$youtubeStreamContainer
        });
        this.createComponent({
            type: 'CustomStreamSettings',
            container: this.$customStreamContainer
        });
    },

    /**
     * @method createSelects
     */
    createSelects: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#mode-select'),
            label: 'settings.streaming_mode',
            native: true,
            name: 'mode',
            id: 'mode',
            items: [
                {
                    text: 'settings.youtube',
                    value: 'youtube'
                },
                {
                    text: 'settings.custom',
                    value: 'custom'
                }
            ]
        });
    },

    /**
     * @method initForm
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#remote-streaming-settings')
        });
    },

    /**
     * @method updateForm
     */
    updateForm: function() {
        this.form.setValues(this.formData);
    },

    /**
     * @method saveHandler
     */
    saveHandler: function() {
        var data = this.form.serialize();

        this.emit('overlay.remote.focus', true);
        this.emit('custom-stream.save');
        this.emit('youtube-stream.save');

        this.deviceConnection
            .send([
                {
                    command: 'setWebcastMode',
                    data: {
                        mode: (data.mode === null ? 'off' : data.mode)
                    }
                }
            ])
            .done(function() {
                this.form.setDefaultValues();
                this.handleHdcpSupport(true);
            }.bind(this));
    },

    /**
     * Calls on clicked the cancel button
     * @method cancelHandler
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
        this.handleHdcpSupport();

        this.emit('youtube-stream.cancel');
        this.emit('custom-stream.cancel');

        this.handleRemoteStreamingMode();
    },

    /**
     * @method loadRemoteStreamingSettings
     * @returns {*}
     */
    loadRemoteStreamingSettings: function() {
        return $.when(this.loadBackendSettings(this))
            .done(function() {
                this.updateForm();
                this.form.setDefaultValues();
            }.bind(this));
    },

    /**
     * @method loadBackendSettings
     */
    loadBackendSettings: function() {
        var dfd = $.Deferred();

        this.deviceConnection
            .send([
                'getWebcastMode',
                'getHdmiInput1HdcpSupport'
            ])
            .then(function(remoteStreaming, hdmi1HdcpSupport) {
                var enable = remoteStreaming.mode !== 'off';

                this.formData = {
                    enableRemote: enable,
                    mode: remoteStreaming.mode
                };

                this.hdmi1HdcpSupportEnabled = hdmi1HdcpSupport.enabled;

                this.emit('streaming-settings.remote-enabled', enable);
                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        this.on('remote-streaming.save', this.saveHandler.bind(this));
        this.on('remote-streaming.cancel', this.cancelHandler.bind(this));
    },

    /**
     * @method handleFormChange
     */
    handleFormChange: function(data) {
        if (!this.isInitialized) {
            return;
        }

        this.emit('streaming-settings.openActionView');
        this.checkRemoteStreamingEnabled();

        if (data?.input?.name === 'mode') {
            this.handleRemoteStreamingMode();
        } else if (data?.input?.name === 'enableRemote') {
            this.handleEnableRemote();
            this.handleHdcpSupport();
        }
    },

    /**
     * @method checkRemoteStreamingEnabled
     */
    checkRemoteStreamingEnabled: function() {
        var remoteStreaming = this.form.get('enableRemote').getValue();

        if (remoteStreaming) {
            this.$remoteStreamingSettings.show();
        } else {
            this.$remoteStreamingSettings.hide();
            // If remoteStreaming is off, disable validation for webcasting
            this.emit('streaming-settings.checkActionFormComponent', true);
        }
    },

    /**
     * Enable remote streaming / webcasting
     */
    handleEnableRemote: function() {
        var remoteStreaming = this.form.get('enableRemote').getValue();

        if (remoteStreaming) {
            this.form.get('mode').setValue('youtube');
        } else {
            this.form.get('mode').setValue('off');
        }

        this.emit('streaming-settings.remote-enabled', remoteStreaming);
        this.handleRemoteStreamingMode();
    },

    /**
     * Handle remote streaming / webcasting mode.
     *
     * @param remoteContainer Current remote streaming container
     */
    handleRemoteStreamingMode: function(remoteContainer) {
        if (remoteContainer) {
            this.form.get('enableRemote').setValue(true);
            this.form.get('mode').setValue(remoteContainer);
        }
        var remoteStreamMode = remoteContainer ? remoteContainer : this.form.get('mode').getValue();

        this.closeOtherContainers(remoteStreamMode);
        this.openContainer(remoteStreamMode);
    },

    /**
     * Show/hide HDCP deactivation info message.
     *
     * @param forceHide If true info message will be hidden anyway
     */
    handleHdcpSupport: function(forceHide) {
        if (!this.deviceService.isCboxCorePro()) {
            return;
        }

        if (forceHide) {
            this.$hdcpDeactivationInfo.hide();

            return;
        }

        if (this.form.get('enableRemote').getValue() && this.hdmi1HdcpSupportEnabled) {
            this.$hdcpDeactivationInfo.show();
        } else {
            this.$hdcpDeactivationInfo.hide();
        }
    },

    /**
     * @method closeOtherContainers
     * @param remoteStreamMode: selected webcast mode
     */
    closeOtherContainers: function(remoteStreamMode) {
        if (remoteStreamMode !== 'youtube') {
            this.$youtubeStreamContainer.hide();
        }

        if (remoteStreamMode !== 'custom') {
            this.$customStreamContainer.hide();
        }
    },

    /**
     * @method openContainer
     * @param remoteStreamMode: selected webcast mode
     */
    openContainer: function(remoteStreamMode) {
        switch (remoteStreamMode) {
            case 'youtube':
                this.emit('youtube-stream.checkActionForm');
                this.$youtubeStreamContainer.show();
                break;
            case 'custom':
                this.emit('custom-stream.checkActionForm');
                this.$customStreamContainer.show();
                break;
        }
    }
});
