'use strict';

const app = require('../../../app');
const tpl = require('./end-presentation.hbs');
const platform = require('../../../../platform/platform');
const $ = require('jquery');
const i18n = require('i18next');
const globalStates = require('../../../states');

/**
 * End presentation states.
 * @type {{newpresentation: string, screensaver: string, screenoff: string, standby: string}}
 */
const states = {
    newpresentation: 'new-presentation',
    screensaver: 'screensaver',
    screenoff: 'screen-off',
    standby: 'standby'
};

/**
 * Screensaver modes.
 * @type {{off: string, screensaver: string, screenoff: string}}
 */
const screensaverModes = {
    off: 'off',
    screensaver: 'screensaver',
    screenoff: 'screen-off'
};

app.component('EndPresentation', {
    template: tpl,
    className: 'end-presentation',

    /**
     * Initialize.
     */
    initialize: function() {
        this.annotationService = this.getService('AnnotationService');
        this.formManager = this.getService('FormManager');
        this.uploadService = this.getService('UploadService');
        this.powerService = this.getService('PowerService');
        this.recordService = this.getService('RecordService');
        this.frameBoxService = this.getService('FrameBoxService');
        this.deviceService = this.getService('DeviceService');
        this.frontendSettings = this.getService('FrontendSettings');
        this.mountService = this.getService('MountService');
        this.sessionMgmtService = this.getService('SessionManagementService');
        this.screensaverService = this.getService('ScreensaverService');
        this.adminMessageService = this.getService('AdminMessageService');
        this.reloadCounter = 0;
        this.hasConnectedApps = false;
        this.standbyPossible = true;

        // After a short period of time the functionality of the standby button changes.
        // This means that if you double-click the button it should go to standby, but if you
        // Press it again after some time it should only close the window.
        this.isOpenForLongerTime = false;

        setTimeout(function() {
            this.isOpenForLongerTime = true;
        }.bind(this), 750);
    },

    postPlaceAt: function() {
        this.initForm();
        this.bindDOMEvents();
        this.bindEvents();
        this.storeSelectors();
        this.uploadCheck({ upload: this.uploadService.getUpload() });
        this.deviceCheck();
        this.updateHandler();
    },

    serialize: function() {
        var dfd = this.$.Deferred();

        $.when(this.isScreensaverEnabled(this), this.powerService.getPowerDownMode())
            .then(function() {
                this.supportsRecording = this.deviceService.isCbox()
                    || (this.deviceService.isCboxCorePro && this.deviceService.getFeatures().capture);

                dfd.resolve({
                    screensaver: this.screensaverMode !== screensaverModes.off,
                    supportsRecording: this.supportsRecording,
                    isCboxPure: this.deviceService.isCboxPure(),
                    isCboxPureMini: this.deviceService.isCboxPureMini(),
                    powerDownMode: this.powerService.powerDownMode,
                    'cynapName': app.getService('Model-View').name
                });
            }.bind(this),
            function() {
                dfd.resolve({
                    screensaver: false,
                    supportsRecording: this.supportsRecording,
                    isCboxPure: this.deviceService.isCboxPure(),
                    isCboxPureMini: this.deviceService.isCboxPureMini(),
                    powerDownMode: this.powerService.powerDownMode,
                    'cynapName': app.getService('Model-View').name
                });
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Load source data and check localfiles.
     */
    hasFilesStored: function() {
        return this.deviceConnection
            .send('getContentSources')
            .then(function(data) {
                var localeFiles = data.sources.filter(function(source) {
                    return 'localfiles' === source.type && true === source.present;
                });

                return 0 < localeFiles.length;
            }.bind(this));
    },

    storeSelectors: function() {
        this.$uploadInfo = this.$el.find('.end-presentation-info-upload-container');
        this.$endPresentationNew = this.$el.find('.end-presentation-new');
        this.$endPresentationStandby = this.$el.find('.end-presentation-standby');
        this.$endPresentationScreensaver = this.$el.find('.end-presentation-screensaver');
        this.$pushFileInfo = this.$el.find('#end-presentation-push-file-info');
        this.$pushFilePinContainer = this.$el.find('#file-push-pin');
        this.$pushFilePin = this.$el.find('.pin');
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.end-presentation-standby', this.onStandbyClicked.bind(this));
        this.$el.on('click', '.end-presentation-new', this.onNewClicked.bind(this));
        this.$el.on('click', '.end-presentation-screensaver', this.onScreensaver.bind(this));
        this.$el.on('change', '#push-files', this.updatePin.bind(this));
    },

    bindEvents: function() {
        this.on('remote.shutdown.keyup', this.onStandby.bind(this));
        this.on('upload-state.update', this.uploadCheck.bind(this));
        this.on('main-loop.update', this.updateHandler.bind(this));
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#end-presentation-form'),
            validationContainer: '.input-group'
        });

        if (this.screensaverMode === screensaverModes.screensaver) {
            this.form.get('screensaver').setValue(i18n.t('modal.screensaver'));
        } else {
            this.form.get('screensaver').setValue(i18n.t('modal.screen_off'));
        }
    },

    /**
     * Check device and update buttons.
     */
    deviceCheck: function() {
        if (!platform.checks.isCbox
            || globalStates.lcsCmdToTypeMapping[this.recordService.lcs.type] === globalStates.lcsTypes.opencastAuto
            || !this.standbyPossible || this.adminMessageService.isAdminMessageStarted()) {
            this.$endPresentationStandby
                .addClass('is-disabled')
                .attr('disabled', 'disabled');
            this.$endPresentationScreensaver
                .addClass('is-disabled')
                .attr('disabled', 'disabled');
        } else {
            this.$endPresentationStandby
                .removeClass('is-disabled')
                .removeAttr('disabled');
            this.$endPresentationScreensaver
                .removeClass('is-disabled')
                .removeAttr('disabled');
        }
    },

    /**
     * Upload check.
     */
    uploadCheck: function(state) {
        if (state.upload) {
            this.$uploadInfo.removeClass('hidden');
        } else {
            this.$uploadInfo.addClass('hidden');
        }
    },

    /**
     * Upload handler.
     */
    updateHandler: function() {
        if (!this.$uploadInfo.hasClass('hidden')) {
            this.$uploadInfo.addClass('is-uploading');
            this.$uploadInfo.find('.upload-progress-end-percent')
                .text(this.uploadService.getUploadPercentage() + '%');
            this.$uploadInfo.find('.upload-progress-current')
                .css({
                    width: this.uploadService.getUploadPercentage() + '%'
                });
        }

        this.checkAppConnectionState();
        this.checkHasFilesStored();
        this.checkStandbyPossible();
    },

    /**
     * Check if standby is possible (e.g. panopto with registration key or opencast auto is active).
     * If 'screen off when no content' mode is enabled standby is also not possible.
     */
    checkStandbyPossible: function() {
        this.deviceConnection
            .send([
                'getStandbyPossible',
                'getScreenOffNoContent'
            ]).then(function(standby, screenOffNoContent) {
                if ((standby.possible && !screenOffNoContent.enabled) !== this.standbyPossible) {
                    this.standbyPossible = standby.possible && !screenOffNoContent.enabled;
                    this.deviceCheck();
                }
            }.bind(this));
    },

    /**
     * Check if system has some screenshots, recordings or whiteboards saved.
     * If true then show all settings otherwise hide all settings.
     */
    checkHasFilesStored: function() {
        if (this.deviceService.isCboxPure() || this.deviceService.isCboxPureMini()) {
            return;
        }

        $.when(this.mountService.checkHasSnapshots(), this.mountService.checkHasRecordings(),
            this.mountService.checkHasWhiteboards())
            .done(function(hasSnapshots, hasRecordings, hasWhiteboards) {
                if (hasSnapshots) {
                    this.form.get('snapshots').enable()
                        .$el.parent().removeClass('is-disabled');
                    this.form.get('pushFiles').enable()
                        .$el.parent().removeClass('is-disabled');
                } else {
                    this.form.get('snapshots').disable()
                        .$el.parent().addClass('is-disabled');
                    this.form.get('pushFiles').disable()
                        .$el.parent().addClass('is-disabled');
                }

                if (this.supportsRecording) {
                    if (hasRecordings) {
                        this.form.get('records').enable()
                            .$el.parent().removeClass('is-disabled');
                    } else {
                        this.form.get('records').disable()
                            .$el.parent().addClass('is-disabled');
                    }
                }

                if (hasWhiteboards) {
                    this.form.get('whiteboards').enable()
                        .$el.parent().removeClass('is-disabled');
                } else {
                    this.form.get('whiteboards').disable()
                        .$el.parent().addClass('is-disabled');
                }
            }.bind(this));
    },

    /**
     * Sets hasConnectedApps to true when there is a connected app.
     */
    checkAppConnectionState: function() {
        this.deviceConnection
            .send(
                'getAppConnectionCounter'
            ).then(function(count) {
                this.hasConnectedApps = 0 < count.count;
            }.bind(this));
    },

    /**
     * Returns if screensaver or screen off is enabled.
     */
    isScreensaverEnabled: function() {
        return this.deviceConnection
            .send('getScreensaverSettings')
            .then(function(screensaverSettings) {
                if (screensaverSettings.screensaver) {
                    this.screensaverMode = screensaverModes.screensaver;
                } else if (screensaverSettings.screenOff) {
                    this.screensaverMode = screensaverModes.screenoff;
                } else {
                    this.screensaverMode = screensaverModes.off;
                }
            }.bind(this), function() {
                this.screensaverMode = screensaverModes.off;
            });
    },

    /**
     * Called when the standby button has been clicked.
     */
    onStandbyClicked: function() {
        this.checkPushFileEnabled(this.onStandby.bind(this, true));
    },

    /**
     * Called when the new presentation button has been clicked.
     */
    onNewClicked: function() {
        this.checkPushFileEnabled(this.onNew.bind(this));
    },

    /**
     * Checks if the pushFiles toggler is enabled.
     * Is that toggle enabled then start the push file implementation.
     *
     * @param {Function} successCallback
     */
    checkPushFileEnabled: function(successCallback) {
        var pushFiles = false;

        if (this.form.get('pushFiles')) {
            pushFiles = this.form.get('pushFiles').getValue();
        }

        if (false === pushFiles) {
            successCallback(true);
        } else {
            this.pollPushFiles(successCallback);
        }
    },

    /**
     * Enables the pushFiles and polls the state.
     * After all devices has downloaded all files we will run the successCallback.
     *
     * @param {Function} successCallback
     */
    pollPushFiles: function(successCallback) {
        // Enable filePush.
        this.deviceConnection.send([{
            command: 'setFilePushMode',
            data: {
                mode: 'on'
            }
        }]).then(function() {
            // Show loader with infotext.
            this.$pushFileInfo.removeClass('is-hidden');

            // Hide all buttons.
            this.$endPresentationNew
                .addClass('is-disabled')
                .attr('disabled', 'disabled')
                .hide();
            this.$endPresentationStandby
                .addClass('is-disabled')
                .attr('disabled', 'disabled')
                .hide();
            this.$endPresentationScreensaver
                .addClass('is-disabled')
                .attr('disabled', 'disabled')
                .hide();

            this.updatePin();

            // Wait 1minute for user interaction. Then start check for connected apps.
            var waitTimeout = setTimeout(this.startAutoClose.bind(this, successCallback), 60000);

            // Skip 1minute wait time.
            // But also wait for apps that are currently downloading files.
            this.$el.find('#file-push-skip').on('click', function() {
                this.startAutoClose(successCallback);
                clearTimeout(waitTimeout);
            }.bind(this));
        }.bind(this));
    },

    /**
     * Checks if a app is connected, when no app is connected run the successCallback.
     *
     * @param {Function} successCallback
     */
    startAutoClose: function(successCallback) {
        var autoCloseInterval = setInterval(function() {
            if (false === this.hasConnectedApps) {
                clearInterval(autoCloseInterval);
                successCallback();
            }
        }.bind(this), 200);
    },

    /**
     * Handle standby mode.
     *
     * @param {boolean} isForced
     */
    onStandby: function(isForced) {
        let removeRecordings = false;
        let removeSnapshots = false;
        let removeWhiteboards = false;

        if (this.form.get('records')) {
            removeRecordings = this.form.get('records').getValue();
        }

        if (this.form.get('snapshots')) {
            removeSnapshots = this.form.get('snapshots').getValue();
        }

        if (this.form.get('whiteboards')) {
            removeWhiteboards = this.form.get('whiteboards').getValue();
        }

        if ((!this.isOpenForLongerTime
            && globalStates.lcsCmdToTypeMapping[this.recordService.lcs.type] !== globalStates.lcsTypes.opencastAuto
            && !this.adminMessageService.isAdminMessageStarted())
            || isForced) {
            const data = {
                removeRecordings: removeRecordings,
                removeSnapshots: removeSnapshots,
                removeWhiteboards: removeWhiteboards,
                state: states.standby
            };

            this.annotationService.clearAllStoredSettings();
            this.stopPresentation(data);
        } else {
            this.emit('modal.close', {
                id: 'end-presentation'
            });

            this.isOpenForLongerTime = false;
        }
    },

    /**
     * Handle screensaver mode.
     */
    onScreensaver: function() {
        let removeRecordings = false;
        let removeSnapshots = false;
        let removeWhiteboards = false;

        if (this.form.get('records')) {
            removeRecordings = this.form.get('records').getValue();
        }

        if (this.form.get('snapshots')) {
            removeSnapshots = this.form.get('snapshots').getValue();
        }

        if (this.form.get('whiteboards')) {
            removeWhiteboards = this.form.get('whiteboards').getValue();
        }

        const data = {
            removeRecordings: removeRecordings,
            removeSnapshots: removeSnapshots,
            removeWhiteboards: removeWhiteboards,
            state: this.screensaverMode === screensaverModes.screensaver ? states.screensaver : states.screenoff
        };

        this.annotationService.clearAllStoredSettings();
        this.emit('download-list.clear');
        this.emit('screensaver.active-on-ui', this.screensaverMode === screensaverModes.screensaver);

        this.screensaverService.prepareScreensaverOff();
        this.reload(data, false);
    },

    /**
     * Handle new presentation.
     */
    onNew: function() {
        let removeRecordings = false;
        let removeSnapshots = false;
        let removeWhiteboards = false;

        if (this.form.get('records')) {
            removeRecordings = this.form.get('records').getValue();
        }

        if (this.form.get('snapshots')) {
            removeSnapshots = this.form.get('snapshots').getValue();
        }

        if (this.form.get('whiteboards')) {
            removeWhiteboards = this.form.get('whiteboards').getValue();
        }

        const data = {
            removeRecordings: removeRecordings,
            removeSnapshots: removeSnapshots,
            removeWhiteboards: removeWhiteboards,
            state: states.newpresentation
        };

        this.annotationService.clearAllStoredSettings();
        this.emit('download-list.clear');

        this.reload(data, true);
    },

    /**
     * Reload app.
     */
    reload: function(data, triggerBrowserReload) {
        this.emit('frameboxes.close.all');
        this.allFrameBoxesClosed()
            .done(function() {
                this.stopPresentation(data);

                if (triggerBrowserReload) {
                    window.location.reload(true);
                }
            }.bind(this))
            .fail(this.reload.bind(this, data, triggerBrowserReload));
    },

    /**
     * Check all frameboxes are closed.
     *
     * @returns {jQuery.Prmise}
     */
    allFrameBoxesClosed: function() {
        var dfd = $.Deferred();

        this.deviceConnection
            .send('getContentPerOutput')
            .then(function(data) {
                if (data.windows.length === 0 || this.reloadCounter >= 30) {
                    dfd.resolve();

                    return;
                }

                this.reloadCounter++;
                dfd.reject();
            }.bind(this));

        setTimeout(dfd.resolve, 1000);

        return dfd.promise();
    },

    /**
     * Stop presentation.
     *
     * @param data
     */
    stopPresentation: function(data) {
        if (this.sessionMgmtService.vMeetingEnabled) {
            this.frontendSettings.updateSetting({
                tag: 'activeSessionPin',
                value: ''
            });
            this.frontendSettings.saveSettings();
        }

        this.emit('download-list.clear');
        this.deviceConnection.send('setEndPresentation', data);
        this.emit('modal.close');
    },

    updatePin: function() {
        this.deviceConnection
            .send([
                'getMirrorPinStatus'
            ])
            .then(function(pin) {
                if (platform.checks.isCbox) {
                    if (this.$el.find('#push-files').prop('checked') && pin.showPin && pin.pin) {
                        this.$pushFilePinContainer.removeClass('is-hidden');
                        this.$pushFilePin.html(pin.pin);

                        return;
                    }

                    this.$pushFilePinContainer.addClass('is-hidden');
                }
            }.bind(this));
    }
});
