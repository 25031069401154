const template = require('./color-select.html');

module.exports = {
    template,

    props: {
        value: {
            type: String,
            required: false,
            default: 'red'
        },
        id: {
            type: String,
            required: true
        },
        close: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    data: function() {
        return {
            menuItems: [
                { id: 'gold', selected: false }, { id: 'red', selected: false },
                { id: 'green', selected: false }, { id: 'deep-sky-blue', selected: false },
                { id: 'orange', selected: false }, { id: 'grey', selected: false },
                { id: 'black', selected: false }, { id: 'white', selected: false }
            ],
            open: false,
            triggerCoords: { x: 0, y: 0, height: 0 }
        };
    },

    computed: {
        selectedColor: function() {
            return this.menuItems.find(i => i.selected)?.id;
        }
    },

    watch: {
        close: function(val) {
            if (val) {
                this.open = false;
            }
        },
        open: function(val) {
            if (val) {
                const { x, y, height } = this.$refs.trigger.getBoundingClientRect();
                this.triggerCoords = { x, y, height };
            }
        }
    },

    methods: {
        toggleSubMenu: function() {
            this.open = !this.open;
            this.$emit('sub-menu-changed', {
                id: this.id,
                open: this.open
            });
        },

        selectColor: function(item) {
            this.menuItems.forEach(i => {
                i.selected = i.id === item.id;
            });
        },

        colorChange: function(event, item) {
            const color = this.getStyle(event.currentTarget.children[0], 'background-color');

            if (color) {
                const colorsArray = color.match(/\d+/g);
                const rgb = {
                    red: colorsArray[0],
                    green: colorsArray[1],
                    blue: colorsArray[2]
                };

                this.$emit('input', rgb);
            }

            this.selectColor(item);
            this.open = false;
        },

        getStyle: function(el, styleProp) {
            if (el.currentStyle) {
                return el.currentStyle[styleProp];
            }

            return window.getComputedStyle(el, null)[styleProp];
        }
    },

    created: function() {
        this.selectColor({
            id: this.value
        });
    }
};
