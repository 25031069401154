const app = require('../../../app');
const actionWidgetTpl = require('../action-widget/action-widget.hbs');
const ActionView = require('./action-view');

const platform = require('../../../../platform/platform');
const rbac = require('../../../../rbac/rbac');
const statusIcons = require('./../../../framebox-types').statusIcons;
const i18n = require('i18next');
const StateMachine = require('./../../../state-machine');
const { formatTimer } = require('../../../helper');
const store = require('../../../store/store');

const outputMirroringStates = {
    none: 'none',
    on: 'on',
    off: 'off'
};

app.component('ActionWidget', {
    template: actionWidgetTpl,
    widgetStore: {},

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.outputMirroringStateMachine = new StateMachine({
            states: outputMirroringStates,
            state: outputMirroringStates.none
        });
        this.addStateTransition();
        this.recordService = app.getService('RecordService');
        this.webconferenceService = this.getService('WebconferenceService');
    },

    addStateTransition: function() {
        this.outputMirroringStateMachine.addTransitions({
            '> none': function() {
                this.widgetStore['output-mirror']?.widget.update({ active: false });
            }.bind(this),
            '> on': function() {
                this.widgetStore['output-mirror']?.widget.update({ active: true });
                store.dispatch('controlScreen/setOutputMirroringActive', true);
            }.bind(this),
            '> off': function() {
                this.widgetStore['output-mirror']?.widget.update({ active: false });
                store.dispatch('controlScreen/setOutputMirroringActive', false);
            }.bind(this)
        });
    },

    postPlaceAt: async function() {
        this.storeSelectors();
        this.bindEvents();
        this.bindVuex();

        const isDualProjection = app.getService('DeviceService').isCboxProDualProjection();
        if (isDualProjection && platform.checks.isRemote) {
            this.addMirroringButton();
            await this.addRecordingButton();
            this.addLanguageButton();
        } else if (isDualProjection && platform.checks.isCboxControlScreen) {
            this.addMirroringButton();
            await this.addRecordingButton();
        }
    },

    addRecordingButton: async function() {
        await this.recordService.updateHandler();
        if (this.recordService.isEnabled() !== 'enabled'
            && !this.recordService.isLcsAdHocRecorderEnabled()
            && !this.recordService.isLcsRemoteRecorderActive()) {
            return Promise.resolve();
        }

        const button = {
            id: 'recording',
            accessKey: 'RecordWidget',
            options: {
                icon: statusIcons['record'],
                text: '00:00:00',
                hasCustomClass: false,
                customClass: 'is-recording',
                onClick: function() {
                    const recInfo = this.recordService.getData();

                    if (typeof recInfo === 'undefined') {
                        return;
                    }

                    const recMode = recInfo.recordingMode;

                    if (recMode === 'stop') {
                        this.recordService.startRecording();
                    } else if (recMode === 'pause' || this.recordService.isLcsRemoteRecorderActive()) {
                        // Remote recording cannot be stopped (only paused)
                        this.recordService.toggleRecordState('pause');
                    } else {
                        this.recordService.toggleRecordState('stop');
                    }

                    app.emit('main-loop.fast.start', {
                        id: 'record'
                    });
                }.bind(this)
            }
        };
        this.removeHandler(button);
        this.appendHandler(button);

        return Promise.resolve();
    },

    addLanguageButton: function() {
        const button = {
            id: 'language',
            accessKey: 'LanguageSelect',
            options: {
                icon: statusIcons['languageChooser'],
                text: i18n.t('language.' + app.getService('LanguageService').getLanguage()),
                onClick: function() {
                    app.getService('LanguageService').showLanguageSelectionModal();
                }.bind(this)
            }
        };

        this.removeHandler(button);
        this.appendHandler(button);
    },

    addMirroringButton: function() {
        const button = {
            id: 'output-mirror',
            accessKey: 'OutputMirror',
            options: {
                isToggle: true,
                activeState: {
                    icon: 'icon-v3-mirroring',
                    text: i18n.t('control_screen.start_output_mirroring')
                },
                inactiveState: {
                    icon: 'icon-v3-screen-not-shared',
                    text: i18n.t('control_screen.stop_output_mirroring')
                },
                active: false,
                onToggleActive: function() {
                    return this.deviceConnection
                        .send('setOutputMirroring', {
                            on: true
                        }).then(function() {
                            app.emit('main-loop.fast.start', {
                                id: 'output-mirror'
                            });
                            app.emit('main-loop.fast.start', {
                                id: 'prepare-list'
                            });
                        }.bind(this));
                }.bind(this),
                onToggleInactive: function() {
                    return this.deviceConnection
                        .send('setOutputMirroring', {
                            on: false
                        }).then(function() {
                            app.emit('main-loop.fast.start', {
                                id: 'output-mirror'
                            });
                        }.bind(this));
                }.bind(this)
            }
        };

        this.removeHandler(button);
        this.appendHandler(button);
    },

    storeSelectors: function() {
        this.$container = this.$el.find('#action-widget');
    },

    bindEvents: function() {
        this.on('main-loop.update', this.updateHandler.bind(this));
        this.on('main-loop.update.output-mirror', this.updateHandler.bind(this));
        this.on('record.timer.update', this.updateRecordingWidget.bind(this));
        this.on('webconference.screenshare.state.change', this.handleWebConferenceScreenShare.bind(this));
    },

    bindVuex: function() {
        this.watchHandle = store.watch((state, getters) => getters['controlScreen/isCameraBackActive'],
            this.handleCameraBack.bind(this));
    },

    /**
     * Output mirror button must be disabled when camera back or screen share is active.
     */
    handleWebConferenceScreenShare: function(screenShareActive) {
        const disableOutputMirrorWidget = screenShareActive || store.getters['controlScreen/isCameraBackActive'];
        this.enableOutputMirrorWidget(!disableOutputMirrorWidget);
    },

    /**
     * Output mirror button must be disabled when camera back or screen share is active.
     */
    handleCameraBack: function(cameraBackActive) {
        const disableOutputMirrorWidget = cameraBackActive || this.webconferenceService.isScreenShareActive();
        this.enableOutputMirrorWidget(!disableOutputMirrorWidget);
    },

    /**
     * Enable/disable output mirroring widget
     * @param show
     */
    enableOutputMirrorWidget(enable) {
        if (enable) {
            this.widgetStore['output-mirror']?.widget.enable();
        } else {
            this.widgetStore['output-mirror']?.widget.disable();
        }
    },

    updateRecordingWidget: function(time) {
        switch (this.recordService.getData()?.recordingMode) {
            case 'record':
                this.widgetStore['recording']?.widget.update({
                    text: formatTimer(time),
                    icon: statusIcons['record'],
                    hasCustomClass: true
                });
                break;
            case 'pause':
                this.widgetStore['recording']?.widget.update({
                    text: formatTimer(time),
                    icon: 'icon-v3-pause',
                    hasCustomClass: false
                });

                break;
            case 'stop':
                this.widgetStore['recording']?.widget.update({
                    text: '00:00:00',
                    icon: statusIcons['record'],
                    hasCustomClass: false
                });

                break;
            default:
                this.widgetStore['recording']?.widget.update({
                    text: '00:00:00',
                    icon: statusIcons['record'],
                    hasCustomClass: false
                });
        }
    },

    /**
     * @method appendHandler
     * @param {object} params
     */
    appendHandler: function(params) {
        const id = params.id;
        const options = params.options;
        const accessKey = params.accessKey;

        if (!accessKey || !rbac.hasAccess(accessKey, 'actionWidget')) {
            return;
        }

        if (id && !this.widgetStore[id]) {
            const widget = new ActionView(this.app, id, options);

            this.widgetStore[id] = {
                widget: widget
            };

            widget.render(this.$container);
        }
    },

    updateHandler: function() {
        this.deviceConnection
            .send(
                'getOutputMirroring'
            ).then(function(data) {
                if (data.on) {
                    if (this.outputMirroringStateMachine.getState() !== outputMirroringStates.on) {
                        this.outputMirroringStateMachine.changeState(outputMirroringStates.on);
                    }
                } else if (this.outputMirroringStateMachine.getState() !== outputMirroringStates.off) {
                    this.outputMirroringStateMachine.changeState(outputMirroringStates.off);
                }
            }.bind(this));
    },

    /**
     * @method removeHandler
     * @param {object} params
     */
    removeHandler: function(params) {
        const id = params.id;

        if (id && this.widgetStore[id]) {
            const widget = this.widgetStore[id].widget;

            widget.destroy().done(function() {
            }.bind(this));

            delete this.widgetStore[id];
        }
    },

    destroy: function() {
        // Call Vuex watch handle to unwatch
        this.watchHandle();
    }
});
