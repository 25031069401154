'use strict';

var app = require('../../../app');
var platform = require('../../../../platform/platform');
var headerTpl = require('./overlay-header-matrix.hbs');
var i18n = require('i18next');
var $ = require('jquery');

app.component('OverlayHeaderMatrix', {
    template: headerTpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function(options) {
        this.matrixConfigService = this.getService('MatrixConfiguratorService');
        this.matrixService = this.getService('MatrixService');
        this.frontendSettings = app.getService('FrontendSettings');
        this.configs = this.getService('MatrixConfigs');
        this.formManager = this.getService('FormManager');
        this.options = options;
        this.data = {
            menuButton: options.menuButton,
            closeButton: options.closeButton
        };
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#header-matrix')
        });
    },

    bindEvents: function() {
        this.on('overlay.matrix.header.update', this.updateHandler.bind(this));
        this.on('matrix.groupwork-mode.changed', this.updateGroupworkTeachingToggle.bind(this));
        this.on('matrix.previews.close', this.disableShowAll.bind(this));
        this.on('matrix-config-selection.changed', this.updateMatrixTemplate.bind(this));
        this.on('matrix.save.enable', this.enableButtons.bind(this));
        this.form.on('change.input', this.handleFormChange.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.action-btn', this.actionBtnHandler.bind(this));
        this.$el.on('click', '#overlay-close-btn', this.closeOverlay.bind(this));
        this.$el.on('click', '#overlay-save-btn', this.saveHandler.bind(this));
        this.$el.on('click', this.hideMatrixMenus.bind(this));
    },

    postPlaceAt: function() {
        this.initForm();
        this.storeSelectors();
        this.bindEvents();
        this.bindDOMEvents();
        this.updateMatrixTemplate(this.configs.getCurrentMatrixTemplateName());

        this.matrixService.getGroupworkStatus()
            .then(groupwork => this.enableButton(!groupwork.active, this.$documentsButton, false));
    },

    storeSelectors: function() {
        this.$saveButton = this.$el.find('#overlay-save-btn');
        this.$configsButton = this.$el.find('#overlay-configs-btn');
        this.$closeButton = this.$el.find('#overlay-close-btn');
        this.$documentsButton = this.$el.find('#overlay-documents-btn');
        this.$groupworkButton = this.$el.find('#groupwork-mode-btn');
        this.$teachingButton = this.$el.find('#teaching-mode-btn');
    },

    serialize: function() {
        var dfd = $.Deferred();
        var count = this.options.isConfigurator ? '3' : '2';

        $.when(this.frontendSettings.getSettings('matrixAutoStart'), this.matrixService.getGroupworkStatus(this))
            .done(function(autoStart, groupwork) {
                dfd.resolve({
                    isIE: platform.checks.isIe,
                    noTemplate: this.options.noTemplate,
                    isConfigurator: this.options.isConfigurator,
                    showGroupworkTeachingToggle: !this.options.isConfigurator && groupwork.enabled,
                    isGroupworkActive: groupwork.active,
                    btnCount: count,
                    menuButton: this.data.menuButton && autoStart,
                    closeButton: this.data.closeButton
                });
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Handle form change.
     * @param data.input Changed input
     */
    handleFormChange: function(data) {
        if ('showAll' === data.input.name) {
            this.emit('matrix.show-all-previews', {
                showAll: data.input.value
            });
        }
    },

    /**
     * Disable show all switch.
     */
    disableShowAll: function() {
        this.$el.find('#showAll').prop('checked', false);
    },

    /**
     * Handles on close button click.
     * Show confirmation dialog or close overlay.
     */
    closeOverlay: function() {
        if (this.options.overlayId === 'matrix-configurator') {
            // Multitouch workaround
            if (this.matrixConfigService.moveEvent) {
                return;
            }

            this.matrixConfigService.checkUnsavedConfig()
                .then(function(unsavedChanges) {
                    if (unsavedChanges) {
                        this.matrixConfigService.checkStationStatus().then(function(stationsOffline, maxReached) {
                            if (!maxReached && stationsOffline.length === 0) {
                                this.emit('modal.open', {
                                    id: 'matrix-close-config',
                                    enableSave: this.matrixService.setupFinished,
                                    role: {
                                        name: 'Matrix',
                                        key: 'show'
                                    }
                                });
                            } else {
                                this.emit('modal.open', {
                                    id: 'matrix-stations-offline',
                                    stations: stationsOffline,
                                    maxReached: maxReached,
                                    closeConfigurator: true,
                                    role: {
                                        name: 'Matrix',
                                        key: 'show'
                                    }
                                });
                            }
                        }.bind(this));
                    } else {
                        this.back();
                    }
                }.bind(this));
        } else {
            this.back();
        }
    },

    /**
     * Back to matrix settings.
     * TODO: Animate differently?
     */
    back: function() {
        this.emit('overlay.close');
        this.emit('backdrop.hide');
        this.emit('menu.close');

        setTimeout(function() {
            this.emit('overlay.open', {
                id: 'matrix-settings',
                extendedConfigs: 'settings-list'
            });
        }.bind(this), 250);
    },

    /**
     * Close matrix overlay.
     */
    close: function() {
        this.emit('overlay.close');
        this.emit('backdrop.hide');
        this.emit('menu.close');
    },

    /**
     * Hide matrix menus.
     */
    hideMatrixMenus: function() {
        this.emit('matrix-main.hide.menus');
    },

    /**
     * Opens a different overlay.
     *
     * @param event Button event
     */
    actionBtnHandler: function(event) {
        var $item = this.$(event.currentTarget);
        var overlay = $item.data('action');

        switch (overlay) {
            case 'devices':
                // Multitouch workaround
                if (this.matrixConfigService.moveEvent) {
                    return;
                }

                this.matrixConfigService.deselectStation();
                this.emit('matrix-box.toggle.' + overlay, true, $item.parent().position().left);
                this.highlightButton($item);
                break;
            case 'documents':
                this.emit('matrix-box.toggle.' + overlay, true, $item.parent().position().left);
                this.highlightButton($item);
                break;
            case 'configs':
                this.emit('matrix-box.toggle.' + overlay, true, $item.parent().position().left);
                this.highlightButton($item);
                break;
            case 'groupwork':
                this.matrixService.setGroupworkActiveMode(true);
                break;
            case 'teaching':
                this.matrixService.setGroupworkActiveMode(false);
                break;
            case 'menu':
                this.emit('matrix-menu.toggle');
                this.highlightButton($item);
                break;
        }
    },

    /**
     * Highlight button if pressed.
     *
     * @param el Button
     * @param disable Disable button afterwards (e.g. save button)
     */
    highlightButton: function(el) {
        el.addClass('highlighted');

        setTimeout(function() {
            el.removeClass('highlighted');
        }.bind(this), 500);
    },

    /**
     * Update groupwork / teaching mode toggle button (hightlighting).
     * @param groupworkActive true if groupwork is active, else false
     */
    updateGroupworkTeachingToggle: function(groupworkActive) {
        if (groupworkActive) {
            this.$groupworkButton.addClass('active');
            this.$teachingButton.removeClass('active');

            this.enableButton(false, this.$documentsButton, false);
            this.emit('matrix-box.hide');
        } else {
            this.$teachingButton.addClass('active');
            this.$groupworkButton.removeClass('active');

            this.enableButton(true, this.$documentsButton, false);
        }
    },

    /**
     * Updates the header view based on the given options
     *
     * @param  {Object} options contains new data and view options
     */
    updateHandler: function(options) {
        this.options = options;
        this.render();
    },

    /**
     * Handle save btn and call event to save matrix configs.
     *
     * @param event Button event
     */
    saveHandler: function(event) {
        // Multitouch workaround
        if (this.matrixConfigService.moveEvent) {
            return;
        }

        var $item = this.$(event.currentTarget);

        this.$el.find('#overlay-action-button-save')
            .html(i18n.t('settings.action_button_saved'))
            .stop()
            .fadeOut({
                delay: 2000,
                duration: 500,
                complete: function() {
                    this.$el.find('#overlay-action-button-save')
                        .show()
                        .html(i18n.t('settings.action_button_save'));
                }.bind(this)
            });

        if (this.options.overlayId === 'matrix-configurator') {
            this.emit('matrix-grid.save');
        }

        this.highlightButton($item);
    },

    /**
     * Change Selected Template.
     *
     * @param name - Template name
     */
    updateMatrixTemplate: function(name) {
        this.$el.find('#selected-config').html(name);
    },

    /**
     * Enable/disable buttons while saving.
     *
     * @param enabled true/false
     */
    enableButtons: function(enabled) {
        if (enabled === this.saveEnabled) {
            return;
        }

        if (enabled) {
            this.enableButton(true, this.$saveButton, true);
            this.enableButton(true, this.$configsButton);
            this.enableButton(true, this.$closeButton);
        } else {
            this.enableButton(false, this.$saveButton, true);
            this.enableButton(false, this.$configsButton);
            this.enableButton(false, this.$closeButton);
        }

        this.saveEnabled = enabled;
    },

    /**
     * Enable button $btn and show loader if button has one.
     *
     * @param enabled true/false
     * @param $btn button to enable/disable
     * @param hasLoader true/false
     */
    enableButton: function(enabled, $btn, hasLoader) {
        if (!$btn) {
            return;
        }

        if (enabled) {
            $btn
                .prop('disabled', false)
                .removeClass('is-disabled');

            if (hasLoader) {
                $btn.find('.loader').addClass('hidden');
                $btn.find('.icon').removeClass('hidden');
            }
        } else {
            $btn
                .prop('disabled', true)
                .removeClass('highlighted')
                .addClass('is-disabled');

            if (hasLoader) {
                $btn.find('.loader').removeClass('hidden');
                $btn.find('.icon').addClass('hidden');
            }
        }
    }
});
