'use strict';

var $ = require('jquery');
var app = require('../../../app');
var proxySettingsTpl = require('./proxy-settings.hbs');
var FormActionView = require('../form-action-view');

var ANIMATION_DURATION = 200;

app.component('ProxySettings', {
    template: proxySettingsTpl,
    actionView: null,

    /**
     * Init component.
     */
    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.validate = this.getService('ValidateService');
    },

    /**
     * Called after DOM is ready.
     */
    postPlaceAt: function() {
        this.storeSelectors();
        this.initForm();

        $.when(this.loadProxySettings())
            .done(function() {
                this.initFormAction();
                this.bindEvents();
                this.bindDOMEvents();
                this.handleAuthChange();
                this.checkActionForm();
                this.updateProxyContainer(true);
            }.bind(this));
    },

    /**
     * Store selectors.
     */
    storeSelectors: function() {
        this.$authContainer = this.$el.find('#auth-container');
        this.$proxyRebootError = this.$el.find('.proxy-reboot-error');
        this.$proxyContainer = this.$el.find('#proxy-settings-container');
    },

    /**
     * Init form.
     */
    initForm: function() {
        this.form = this.formManager
            .create({
                el: this.$el.find('#proxy-settings-form'),
                validationContainer: '.input-group'
            });
    },

    /**
     * Init action view(save and cancel button).
     */
    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    bindEvents: function() {
        this.on('settings.save-changes', this.saveHandler.bind(this));
    },

    /**
     * Bind DOM events.
     */
    bindDOMEvents: function() {
        this.form.get('proxyEnabled').$el.on('change', this.updateProxyContainer.bind(this));
        this.form.on('change.input', this.handleFormChange.bind(this));
    },

    /**
     * Checks if the proxy container should be hidden or displayed.
     *
     * @param {Boolean} isInitialCall - Tells if the method is called in init.
     */
    updateProxyContainer: function(isInitialCall) {
        var proxyEnabled = this.form.get('proxyEnabled').getValue();

        if (proxyEnabled) {
            this.showProxyContainer(isInitialCall);
        } else {
            this.hideProxyContainer(isInitialCall);
        }
    },

    /**
     * Shows the proxy container.
     *
     * @param {Boolean} isInitialCall - If the method is called in init we don't want an animation.
     */
    showProxyContainer: function(isInitialCall) {
        if (true === isInitialCall) {
            this.$proxyContainer.show();
        } else {
            this.$proxyContainer
                .stop()
                .slideDown(ANIMATION_DURATION);
        }

        this.checkActionForm();
    },

    /**
     * Hides the proxy container.
     *
     * @param {Boolean} isInitialCall - If the method is called in init we don't want an animation.
     */
    hideProxyContainer: function(isInitialCall) {
        if (true === isInitialCall) {
            this.$proxyContainer.hide();
        } else {
            this.$proxyContainer
                .stop()
                .slideUp(ANIMATION_DURATION);
        }

        this.checkActionForm();
    },

    /**
     * Load proxy settings from backend and frontend.
     * @returns {*}
     */
    loadProxySettings: function() {
        return $.when(this.loadBackendSettings(this))
            .done(this.updateForm.bind(this));
    },

    /**
     * Load backend settings via wolfprot and set form data.
     * @returns promise
     */
    loadBackendSettings: function() {
        var dfd = $.Deferred();

        this.deviceConnection
            .send([
                'getProxyFunction',
                'getProxyUrl',
                'getProxyPort',
                'getProxyAuthMode',
                'getProxyUsername',
                'getProxyPassword'
            ])
            .then(function(func, url, port, auth, username, password) {
                this.formData = {
                    proxyEnabled: func.function,
                    proxyUrl: url.url,
                    proxyPort: port.port,
                    proxyAuth: auth.mode,
                    proxyUsername: username.username,
                    proxyPassword: password.password
                };
                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Update form and set default form values.
     */
    updateForm: function() {
        this.form.setValues(this.formData);
        this.form.setDefaultValues();
    },

    /**
     * Handle form change and open action view (save/cancel).
     * @param data.input Changed input
     */
    handleFormChange: function(data) {
        if ('proxyAuth' === data.input.name) {
            this.handleAuthChange();
        }

        this.actionView.open();
        this.checkActionForm();

        this.$proxyRebootError.show();
        this.changes = true;
    },

    /**
     * Show authentication container (username/password) if authentication is enabled, hide otherwise.
     */
    handleAuthChange: function() {
        var enable = this.form.get('proxyAuth').getValue();

        if (enable) {
            this.showAuth();
        } else {
            this.hideAuth();
        }
    },

    /**
     * Enable/disable save btn depending on valid/invalid form.
     */
    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * Hide authentication container and allow empty fields for a valid form.
     */
    hideAuth: function() {
        this.$authContainer.find('#proxyUsername').attr('data-allow-empty', 'true');
        this.$authContainer.find('#proxyPassword').attr('data-allow-empty', 'true');

        this.$authContainer
            .stop()
            .slideUp(300);

        if (!this.$authContainer.hasClass('hidden')) {
            this.$authContainer.addClass('hidden');
        }
    },

    /**
     * Show authentication container  and do not allow empty fields for validation.
     */
    showAuth: function() {
        this.$authContainer.find('#proxyUsername').removeAttr('data-allow-empty');
        this.$authContainer.find('#proxyPassword').removeAttr('data-allow-empty');

        this.$authContainer
            .stop()
            .slideDown(230);

        this.$authContainer.removeClass('hidden');
    },

    /**
     * Called if save btn is pressed.
     * Save proxy settings and activate proxy if data is valid.
     */
    saveHandler: function() {
        if (this.form.validate()) {
            var data = this.form.serialize();

            this.emit('overlay.remote.focus', true);

            this.deviceConnection
                .send([
                    {
                        command: 'setProxyFunction',
                        data: { function: data.proxyEnabled }
                    },
                    {
                        command: 'setProxyUrl',
                        data: { url: data.proxyUrl }
                    },
                    {
                        command: 'setProxyPort',
                        data: { port: data.proxyPort }
                    },
                    {
                        command: 'setProxyAuthMode',
                        data: { mode: data.proxyAuth }
                    },
                    {
                        command: 'setProxyUsername',
                        data: { username: data.proxyUsername }
                    },
                    {
                        command: 'setProxyPassword',
                        data: { password: data.proxyPassword }
                    }
                ])
                .done(this.activateProxy.bind(this));
        }

        this.$proxyRebootError.hide();
    },

    /**
     * Activate proxy via wolfprot command.
     */
    activateProxy: function() {
        this.deviceConnection
            .send('setProxyActivate')
            .then(function() {
                this.handleSettingsSave();
                this.form.setDefaultValues();
                this.emit('save-changes.finished');
            }.bind(this));
    },

    /**
     * Handle header if settings are saved.
     */
    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'action_button.saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.changes = false;
    },

    /**
     * Called if cancel btn is pressed.
     * Cancel and reset form values.
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);

        this.form.resetValues();
        this.$proxyRebootError.hide();
        this.changes = false;
    },

    hasChanges: function() {
        if (!this.actionView) {
            return {
                hasChanges: false,
                invalid: false
            };
        }

        return {
            hasChanges: this.changes,
            invalid: $(this.actionView.$submitBtn).prop('disabled'),
            waitOnSave: true
        };
    }
});
