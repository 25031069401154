'use strict';

var app = require('../app');
const platform = require('../../platform/platform');

const QR_CODE_MODAL_ID = 'qr-code';

app.service('StreamQrCodeService', function(app) {
    return {
        initialize: function() {
            this.modalHandlerService = app.getService('ModalHandlerService');
            this.deviceService = app.getService('DeviceService');

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;

                    this.bindEvents();
                    this.onUpdateHandler();
                }.bind(this));
        },

        bindEvents: function() {
            app.on('main-loop.update', this.onUpdateHandler.bind(this));
            app.on('main-loop.update.stream-qrcode', this.onUpdateHandler.bind(this));
        },

        onUpdateHandler: function() {
            this.deviceConnection
                .send([
                    { command: 'getQrCode' }
                ])
                .then(this.onUpdate.bind(this));
        },

        onUpdate: function(qrCodeVisible) {
            this.updateQrCodeStatus(qrCodeVisible);
        },

        openModal: function() {
            app.emit('modal.open', {
                id: QR_CODE_MODAL_ID,
                streamIndex: platform.checks.isCboxProjectionHdmi2 ? 1 : 0
            });
        },

        updateQrCodeStatus: function(data) {
            const isDualProjection = this.deviceService.isCboxProDualProjection();

            let modalEnabledOnPlatform = false;

            if (isDualProjection) {
                modalEnabledOnPlatform = platform.checks.isCboxProjectionHdmi1 || platform.checks.isCboxProjectionHdmi2;
            } else {
                modalEnabledOnPlatform = true;
            }

            const qrCodeModalOpen = this.modalHandlerService.getOpenModalId() === QR_CODE_MODAL_ID;

            if (modalEnabledOnPlatform && platform.checks.isCbox && data.visible && !qrCodeModalOpen) {
                this.openModal();
            } else if (!data.visible && qrCodeModalOpen) {
                app.emit('modal.close', {
                    id: QR_CODE_MODAL_ID
                });
            }
        }
    };
});
