'use strict';

const _ = require('lodash');
const app = require('../../app');
const sourcesMenuTpl = require('./sources-menu.html');
const vueUtils = require('../util');
const Vuex = require('vuex');
const draggable = require('vuedraggable');
const i18n = require('i18next');
const $ = require('jquery');

const MAX_FRAME_BOXES = 4;

app.component('SourcesMenu', {
    engine: 'vue',
    template: sourcesMenuTpl,

    components: {
        'sources-sub-menu': require('./sources-sub-menu/sources-sub-menu'),
        'sources-menu-item': require('./sources-menu-item/sources-menu-item'),
        'overlay2': require('../overlay2/overlay2'),
        draggable
    },

    data: function() {
        return {
            show: false,
            dragging: false,
            numberOfOpenFrameBoxes: 0,
            fileBrowserOpened: false,
            focusArea: '.is-focusable:not(.disabled)',
            isDraggable: false,
            showSubMenu: false,
            subMenuTitle: '',
            subMenuType: null,
            subItems: [
                {
                    type: 'msoutlook',
                    icon: 'icon-v3-office-outlook',
                    name: i18n.t('office365.outlook')
                },
                {
                    type: 'msword',
                    icon: 'icon-v3-office-word',
                    name: i18n.t('office365.word')
                },
                {
                    type: 'msexcel',
                    icon: 'icon-v3-office-excel',
                    name: i18n.t('office365.excel')
                }
            ],
            subMenuActionSwitch: undefined
        };
    },

    computed: {
        maxNumberOfFrameboxesOpen: function() {
            return this.numberOfOpenFrameBoxes >= MAX_FRAME_BOXES;
        },

        items: function() {
            if (this.deviceService.isCboxProDualProjection()) {
                return this.sourcesInDualProjectionMode(this.sources);
            } else {
                return this.sources;
            }
        },

        isSingleRow: function() {
            return (this.uiScalingSize === 'small' && this.items.length <= 10)
                || (this.uiScalingSize === 'medium' && this.items.length <= 8)
                || (this.uiScalingSize === 'large' && this.items.length <= 6);
        },

        ...Vuex.mapGetters('uiSettings', { uiScalingSize: 'getUiScalingSize' }),
        ...Vuex.mapGetters('sources', { sources: 'getSources' }),
        ...Vuex.mapGetters('controlScreen', ['getDragging', 'isEditModeActive'])
    },

    watch: {
        show: function(val) {
            if (val) {
                this.component.emit('menu.opened');
                this.component.emit('activities.update');
                this.component.emit('show.ui');

                this.component.emit('framebox.speed.slow');
            } else {
                this.component.emit('menu.closed');
                this.component.emit('show.ui');

                this.component.emit('framebox.speed.full');
            }
        },

        items: function(val) {
            // Close sub-menu when item is not available anymore
            if (this.showSubMenu && !val.some(i => i.type === this.subMenuType)) {
                this.showSubMenu = false;
            }
        }
    },

    methods: {
        dragStart: function(scalingFactor) {
            this.$store.dispatch('controlScreen/setDragging', true);
            this.setDragging(true);

            const draggedElement = document.getElementsByClassName('sortable-drag')[0];
            if (draggedElement) {
                const draggedIcon = draggedElement.getElementsByClassName('icon')[0];
                draggedIcon.style.transform = `scale(${scalingFactor})`;
            }
        },

        dragEnd: function() {
            this.$store.dispatch('controlScreen/setDragging', false);
            this.setDragging(false);
        },

        setDragging: function(active) {
            this.dragging = active;
        },

        menuItemClick: function(item) {
            if (this.isDraggable && item.hasSubItems) {
                this.openSubMenu(item);
            } else {
                this.openSource(item);

                if (!item.hasSubItems && !['mirror'].includes(item.type)) {
                    this.close();
                }
            }
        },

        openSubMenu: function(item) {
            this.subMenuTitle = item.name === '' ? this.i18n(item.titleKey) : item.name;
            this.subMenuType = item.type;
            this.subItems = item.subItems;

            this.getActionSwitch(item.type)
                .then(function(actionSwitch) {
                    this.subMenuActionSwitch = actionSwitch;
                    this.showSubMenu = true;
                }.bind(this));
        },

        /**
         * Get action switch for sources sub menu depending on source type
         */
        getActionSwitch(sourceType) {
            let dfd = $.Deferred();

            switch (sourceType) {
                case 'office365':
                    this.office365Service.localOffice365AccountAvailable()
                        .then(function(account) {
                            dfd.resolve({
                                title: i18n.t('modal.sources.office365'),
                                show: account.available,
                                checked: account.origin === 'local',
                                onToggle: function(checked) {
                                    this.office365Service.onChangeOffice365Origin(checked);
                                }.bind(this)
                            });
                        }.bind(this));
                    break;
                default:
                    dfd.resolve(undefined);
            }

            return dfd.promise();
        },

        closeSubMenu: function() {
            this.showSubMenu = false;
        },

        /**
         * Open sources menu
         * only if ldap or magic pen are not active
         */
        open: function() {
            if (this.show || this.items.length === 0 || this.isEditModeActive
                || this.authService.getIsLdapActive() && !this.authService.getIsLdapAuthenticated()
                || this.annotationService.isMagicPenActive() || this.stationService.getPushStatus()) {
                return;
            }

            if (this.items.length === 1) {
                this.openSource(this.items[0]);

                return;
            }

            this.show = true;
        },

        /**
         * Open content source directly
         */
        openSource: _.throttle(function(item) {
            this.sourcesService.open(item);
        }, 500, { leading: true, trailing: false }),

        /**
         * Close sources menu
         */
        close: function() {
            this.show = false;
        },

        /**
         * Toggle sources menu open/close
         */
        toggle: function() {
            if (!this.show) {
                this.open();
            } else {
                this.close();
            }
        },

        /**
         * Update activities
         */
        updateActivities: function() {
            if (this.show) {
                this.component.emit('activities.update');
            }
        },
        /**
         * In dual projection mode the filebrowser and mirror source are not included in the sources menu
         */
        sourcesInDualProjectionMode: function(sources) {
            return sources.filter(s => !['filebrowser', 'mirror'].includes(s.type));
        },

        i18n: vueUtils.i18n
    },

    beforeCreate: function() {
        this.sourcesService = app.getService('SourcesService');
        this.authService = app.getService('AuthenticationService');
        this.annotationService = app.getService('AnnotationService');
        this.frameBoxService = app.getService('FrameBoxService');
        this.stationService = app.getService('StationService');
        this.deviceService = app.getService('DeviceService');
        this.office365Service = app.getService('Office365Service');
    },

    created: function() {
        this.isDraggable = this.deviceService.isCboxProDualProjection();

        this.evctx = vueUtils.eventContext();
        this.evctx.on('menu.open', this.open);
        this.evctx.on('menu.close', this.close);

        this.evctx.on('control-center.opened', this.close);
        this.evctx.on('file-browser.opened', function() {
            this.fileBrowserOpened = true;
            this.close();
        }.bind(this));
        this.evctx.on('file-browser.closed', function() {
            this.fileBrowserOpened = false;
        }.bind(this));

        this.evctx.on('annotation.action.start', this.close);
        this.evctx.on('magic-pen.state.active', this.close);

        this.evctx.on('station-status.push', this.close);
        this.evctx.on('station-status.lock', this.close);
        this.evctx.on('station-status.limited', this.close);

        this.evctx.on('edit-mode.opened', this.close);

        this.evctx.on('frameboxes.update', function() {
            this.numberOfOpenFrameBoxes = this.frameBoxService.getNumberOpenFrameboxes();
        }.bind(this));

        this.evctx.on('remote.menu.keyup', function() {
            // Close File Browser when source menu button is pressed ("Direct link to USB files")
            if (this.fileBrowserOpened) {
                this.component.emit('file-browser.close');
            } else {
                this.toggle();
            }
        }.bind(this));
        this.evctx.on('remote.up.keydown', _.debounce(this.updateActivities, 400));
        this.evctx.on('remote.down.keydown', _.debounce(this.updateActivities, 400));
        this.evctx.on('remote.right.keydown', _.debounce(this.updateActivities, 400));
        this.evctx.on('remote.left.keydown', _.debounce(this.updateActivities, 400));
    },

    destroyed: function() {
        this.show = false;
        this.evctx.close();
    }
});
