// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.enable_lan1",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":48},"end":{"line":16,"column":76}}}))
    + "</h2>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.enable_lan",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":48},"end":{"line":18,"column":75}}}))
    + "</h2>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\".btn-switch\"\n                     data-nav-trapped\n                     data-nav-area-default>\n                    <div class=\"col-xs-8\">\n                        <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.enable_lan2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":48},"end":{"line":41,"column":76}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input name=\"lan2AcceptHttpHttps\"\n                                   id=\"lan2AcceptHttpHttps\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCboxPro") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":141,"column":26},"end":{"line":166,"column":23}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                <div class=\"row settings-list-row\">\n                    <div class=\"col-xs-12\">\n                        <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.frontpanel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":144,"column":28},"end":{"line":144,"column":55}}}))
    + "</h2>\n                    </div>\n                </div>\n                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\".btn-switch\"\n                     data-nav-trapped\n                     data-nav-area-default>\n                    <div class=\"col-xs-6\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.frontpanel_login",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":152,"column":48},"end":{"line":152,"column":81}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-2 pull-right\">\n                        <div class=\"btn-switch-container pull-right is-security-setting\">\n                            <input name=\"fpLogin\"\n                                   id=\"fpLogin\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            ";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row settings-list-row\">\n                    <div class=\"col-xs-12\">\n                        <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.usb",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":171,"column":28},"end":{"line":171,"column":48}}}))
    + "</h2>\n                    </div>\n                </div>\n                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\".btn-switch\"\n                     data-nav-trapped\n                     "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxCoreOrCoreProOrPurePro") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":177,"column":21},"end":{"line":177,"column":85}}})) != null ? stack1 : "")
    + ">\n                    <div class=\"col-xs-6\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.usb_support",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":179,"column":48},"end":{"line":179,"column":76}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-2 pull-right\">\n                        <div class=\"btn-switch-container pull-right is-security-setting\">\n                            <input name=\"usbSupport\"\n                                   id=\"usbSupport\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "data-nav-area-default";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"security-settings\">\n    <form id=\"security-settings-form\" name=\"input-settings\">\n        <div class=\"settings-list\">\n            <div class=\"row settings-list-row\">\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.http_https",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":24},"end":{"line":6,"column":51}}}))
    + "</h2>\n                </div>\n            </div>\n            <!-- LAN 1 -->\n            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".btn-switch\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"col-xs-8\">\n"
    + ((stack1 = (lookupProperty(helpers,"unless_or")||(depth0 && lookupProperty(depth0,"unless_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPureOrPro") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"unless_or","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":19,"column":34}}})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"lan1AcceptHttpHttps\"\n                               id=\"lan1AcceptHttpHttps\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <!-- LAN 2 -->\n"
    + ((stack1 = (lookupProperty(helpers,"unless_or")||(depth0 && lookupProperty(depth0,"unless_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPureOrPro") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"unless_or","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":55,"column":26}}})) != null ? stack1 : "")
    + "\n            <!-- WLAN -->\n            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".btn-switch\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.enable_wlan",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":44},"end":{"line":63,"column":72}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"wlanAcceptHttpHttps\"\n                               id=\"wlanAcceptHttpHttps\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <!-- Secure Connection (HTTPS) -->\n            <div class=\"row settings-list-row\">\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.https",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":81,"column":24},"end":{"line":81,"column":46}}}))
    + "</h2>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".btn-switch\"\n                 data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.allow_secure_con",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":88,"column":44},"end":{"line":88,"column":77}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-2 pull-right\">\n                    <div class=\"btn-switch-container pull-right is-security-setting\">\n                        <input name=\"httpsEnable\"\n                               id=\"httpsEnable\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary settings-list-info\" data-name=\"httpsEnable\">\n                <div class=\"col-xs-12\">\n                    <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.https_restart",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":105,"column":23},"end":{"line":105,"column":53}}}))
    + "</p>\n                </div>\n            </div>\n\n            <!-- TLS -->\n            <div class=\"row settings-list-row\">\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.tls_1_0",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":112,"column":24},"end":{"line":112,"column":48}}}))
    + " </h2>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.tls_1_0_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":118,"column":24},"end":{"line":118,"column":55}}}))
    + "\n                    </h2>\n                </div>\n\n                <div class=\"col-xs-2 pull-right\">\n                    <div class=\"btn-switch-container pull-right is-security-setting\">\n                        <input name=\"tls10Enable\"\n                               id=\"tls10Enable\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary settings-list-info\" data-name=\"tls10Enable\">\n                <div class=\"col-xs-12\">\n                    <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.tls_restart",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":137,"column":23},"end":{"line":137,"column":51}}}))
    + "</p>\n                </div>\n            </div>\n\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":141,"column":12},"end":{"line":166,"column":30}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = (lookupProperty(helpers,"unless_or")||(depth0 && lookupProperty(depth0,"unless_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPure") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"unless_or","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":168,"column":12},"end":{"line":193,"column":26}}})) != null ? stack1 : "")
    + "\n            <div class=\"row settings-list-row\">\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.admin_message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":197,"column":24},"end":{"line":197,"column":54}}}))
    + "</h2>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".btn-switch\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.enable_admin_message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":205,"column":44},"end":{"line":205,"column":81}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-2 pull-right\">\n                    <div class=\"btn-switch-container pull-right is-security-setting\">\n                        <input name=\"adminMessageEnable\"\n                               id=\"adminMessageEnable\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-row\">\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ssh",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":222,"column":24},"end":{"line":222,"column":44}}}))
    + "</h2>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ssh_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":227,"column":44},"end":{"line":227,"column":71}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-2 pull-right\">\n                    <div class=\"btn-switch-container pull-right is-security-setting\">\n                        <input name=\"sshEnable\"\n                               id=\"sshEnable\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-row\">\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.reverse_shell",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":244,"column":24},"end":{"line":244,"column":54}}}))
    + "</h2>\n\n                    <span id=\"reverse-shell-state\" class=\"reverse-shell-state text-right\">\n                        <span class=\"reverse-shell-state-stopped\">\n                            <div class=\"reverse-shell-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.states.stopped",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":249,"column":28},"end":{"line":249,"column":59}}}))
    + "\n                        </span>\n\n                        <span class=\"reverse-shell-state-started\">\n                            <div class=\"reverse-shell-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.states.started",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":254,"column":28},"end":{"line":254,"column":59}}}))
    + "\n                        </span>\n\n                        <span class=\"reverse-shell-state-failed\">\n                            <div class=\"reverse-shell-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.states.failed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":259,"column":28},"end":{"line":259,"column":58}}}))
    + "\n                        </span>\n                    </span>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.reverse_shell_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":266,"column":44},"end":{"line":266,"column":81}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-2 pull-right\">\n                    <div class=\"btn-switch-container pull-right is-security-setting\">\n                        <input name=\"reverseShellEnable\"\n                               id=\"reverseShellEnable\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"reverse-shell-container\">\n                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"port\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.port",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":286,"column":66},"end":{"line":286,"column":87}}}))
    + "</label>\n                            <input id=\"port\"\n                                   type=\"text\"\n                                   class=\"input\"\n                                   name=\"reverseShellPort\"\n                                   data-from=\"1025\"\n                                   data-to=\"65535\"\n                                   data-validation=\"checkNumberFromTo\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_shell_port",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":295,"column":79},"end":{"line":295,"column":112}}}))
    + "</span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.reverse_shell_webpage_redirect_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":302,"column":48},"end":{"line":302,"column":102}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-2 pull-right\">\n                        <div class=\"btn-switch-container pull-right is-security-setting\">\n                            <input name=\"reverseShellWebpageRedirectEnable\"\n                                   id=\"reverseShellWebpageRedirectEnable\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.reverse_shell_debug_port_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":318,"column":48},"end":{"line":318,"column":96}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-2 pull-right\">\n                        <div class=\"btn-switch-container pull-right is-security-setting\">\n                            <input name=\"reverseShellDebugPortEnable\"\n                                   id=\"reverseShellDebugPortEnable\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-row\">\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.fw_signature",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":336,"column":24},"end":{"line":336,"column":53}}}))
    + " </h2>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.fw_signature_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":343,"column":24},"end":{"line":343,"column":60}}}))
    + "\n                    </h2>\n                </div>\n\n                <div class=\"col-xs-2 pull-right\">\n                    <div class=\"btn-switch-container pull-right is-security-setting\">\n                        <input name=\"fwSignatureEnable\"\n                               id=\"fwSignatureEnable\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div id=\"form-action-container\" class=\"form-primary-background\"></div>\n\n            <div class=\"row settings-list-row\">\n                <div class=\"col-xs-6\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.disk_encryption",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":365,"column":24},"end":{"line":365,"column":56}}}))
    + " </h2>\n\n                    <span id=\"disk-encryption-state\" class=\"disk-encryption-state text-right\">\n                        <span class=\"disk-encryption-state-failed\">\n                            <div class=\"disk-encryption-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.failed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":370,"column":28},"end":{"line":370,"column":51}}}))
    + "\n                        </span>\n\n                        <span class=\"disk-encryption-state-in-progress\">\n                            <div class=\"disk-encryption-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.in_progress",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":375,"column":28},"end":{"line":375,"column":56}}}))
    + "\n                        </span>\n\n                        <span class=\"disk-encryption-state-decrypted\">\n                            <div class=\"disk-encryption-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.decrypted",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":380,"column":28},"end":{"line":380,"column":54}}}))
    + "\n                        </span>\n\n                        <span class=\"disk-encryption-state-encrypted\">\n                            <div class=\"disk-encryption-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.encrypted",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":385,"column":28},"end":{"line":385,"column":54}}}))
    + "\n                        </span>\n                    </span>\n                </div>\n            </div>\n\n            <div id=\"disk-encryption-toggle-info\" class=\"row settings-list-secundary disk-encryption-toggle-info\">\n                <p class=\"text\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.encryption_restart",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":392,"column":32},"end":{"line":392,"column":67}}}))
    + "</p>\n            </div>\n\n            <div class=\"form-primary-background\"\n                 data-nav-area=\".is-focusable\"\n                 data-nav-trapped>\n                <div id=\"form-encrypt-action-container\" class=\"form-action-container\">\n                    <input id=\"disk-encryption-start\"\n                           type=\"button\"\n                           class=\"btn-form form-action-submit is-focusable is-virtual-input\"\n                           value=\""
    + alias3((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias2).call(alias1,depth0,{"name":"tr","hash":{"key":"settings.start_encryption_and_restart"},"data":data,"loc":{"start":{"line":402,"column":34},"end":{"line":402,"column":90}}}))
    + "\"/>\n                </div>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});
