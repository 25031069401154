'use strict';

var app = require('../../../app');
var loginTpl = require('./cloud-login.hbs');
var FormActionView = require('../../settings/form-action-view');

app.component('CloudLogin', {
    template: loginTpl,

    /**
     * @method initialize
     */
    initialize: function() {
        this.user = null;
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.cloudAuth = this.getService('CloudAuthenticationService');
        this.dataStorage = this.getService('DataStorage');
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.storeSelectors();
        this.initForm();
        this.bindEvents();
        this.bindDOMEvents();
        this.initFormAction();
        this.checkStorage();
        this.showLogin();
    },

    serialize: function() {
        return {
            isJianguoyun: this.options.configs.service === 'jianguoyun',
            isWebdav: this.options.configs.service === 'webdav',
            title: this.options.configs.name
        };
    },

    storeSelectors: function() {
        this.$password = this.$el.find('#password');
        this.$passwordError = this.$el.find('#password-error');
        this.$loginForm = this.$el.find('.login-form-content');
    },

    bindEvents: function() {
        this.$el.find('#cloud-login-form').on('submit', this.onSignInHandler.bind(this));
        this.on('remote.enter.keydown', this.checkActionForm.bind(this));
    },

    bindDOMEvents: function() {
        this.form.on('change.input', this.checkActionForm.bind(this));
    },

    /**
     * @method checkStorage
     */
    checkStorage: function() {
        var user = this.dataStorage.get(this.options.configs.service + 'user');

        if (user) {
            this.form.get('username').setValue(user);
        }
    },

    /**
     * @method checkActionForm
     */
    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * @method onSignInHandler
     */
    onSignInHandler: function() {
        var username = this.form.get('username').getValue();
        var password = this.form.get('password').getValue();

        if (!this.loginIsRunning && this.form.validate()) {
            this.dataStorage.set(this.options.configs.service + 'user', username);
            this.loginIsRunning = true;
            this.cloudAuth.setCloudData(username, password, this.options.configs.service);
            this.emit('modal.close');
            this.loginIsRunning = false;
        }
    },

    /**
     * @method showError
     */
    showError: function() {
        this.$loginValidateContainer
            .removeClass('is-valid')
            .addClass('is-invalid');
    },

    /**
     * @method hideError
     */
    hideError: function() {
        this.$loginValidateContainer
            .removeClass('is-invalid');
    },

    /**
     * @method initForm
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#cloud-login-form'),
            validationContainer: '.input-group',
            submit: true
        });
    },

    /**
     * @method initFormAction
     */
    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                submitTitleKey: 'action_button.login',
                cancelTitleKey: 'action_button.cancel',
                onSubmit: this.onSignInHandler.bind(this),
                onCancel: !this.options.configs.cancelLoginHandler ? this.onCancelHandler.bind(this) : this.options.configs.cancelLoginHandler.bind(this),
                autoClose: false,
                navArea: false
            });
        }

        this.actionView.render();
    },

    /**
     * @method onCancelHandler
     */
    onCancelHandler: function() {
        this.dataStorage.set(this.options.configs.service + 'user', null);
        this.emit('modal.close');
    },

    /**
     * @method showLogin
     */
    showLogin: function() {
        this.actionView.open();
    }
});
