// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div id=\"session-management-btn-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"min") : depth0), depth0))
    + "\"\n                         class=\"session-management-btn clickable "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"valid") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":65},"end":{"line":12,"column":104}}})) != null ? stack1 : "")
    + "\"\n                         data-action=\"choose-timeframe\"\n                         data-minutes=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"min") : depth0), depth0))
    + "\">\n                        <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "</span>\n                    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"meeting-ad-hoc-container\"\n     class=\"meeting-check-in-container full-height hidden\">\n    <form id=\"meeting-ad-hoc-form\" name=\"meeting-ad-hoc-form\" class=\"meeting-check-in-form\">\n        <div id=\"ad-hoc-title\" class=\"check-in-title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"session_management.ad_hoc_timeframe",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":54},"end":{"line":4,"column":97}}}))
    + "</div>\n        <div id=\"ad-hoc-time-info\" class=\"check-in-info\"></div>\n\n        <div id=\"ad-hoc-container-timeframes\"\n             class=\"ad-hoc-container\">\n            <div id=\"ad-hoc-timeframes-container\" class=\"session-management-btn-container timeframes\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"timeframes") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":17,"column":25}}})) != null ? stack1 : "")
    + "                <div id=\"session-management-other-btn\"\n                     class=\"session-management-btn clickable\"\n                     data-action=\"other-timeframe\"\n                     data-minutes=\"1\"> \n                    <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"session_management.other",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":26},"end":{"line":22,"column":58}}}))
    + "</span>\n                </div>\n            </div>\n            <div class=\"session-management-btn-container\">\n                <div id=\"session-management-check-in-btn\" class=\"session-management-ad-hoc-btn check-in-btn clickable hidden\"\n                     data-action=\"check-in\">\n                    <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"session_management.check_in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":26},"end":{"line":28,"column":61}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"ad-hoc-container-timeframe-input\"\n             class=\"ad-hoc-container hidden\">\n            <div id=\"ad-hoc-container-timeframe-input\" class=\"session-management-btn-container timeframe-input\">\n                <div id=\"timeframe-input-container\" class=\"session-management-input\">\n                    <div class=\"session-management-timeframe-input\">\n                        <input type=\"text\"\n                               id=\"timeframe-input-hour\"\n                               class=\"input is-focusable\"\n                               name=\"timeframeInputHour\"\n                               placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"session_management.hh",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":44},"end":{"line":42,"column":73}}}))
    + "\"\n                               data-validation=\"checkIsNumber\"\n                               data-allow-empty=\"true\"/>\n                        <span class=\"input separator\">:</span>\n                        <input type=\"text\"\n                               id=\"timeframe-input-min\"\n                               class=\"input is-focusable\"\n                               name=\"timeframeInputMin\"\n                               placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"session_management.mm",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":44},"end":{"line":50,"column":73}}}))
    + "\"\n                               data-validation=\"checkIsNumber\"\n                               data-allow-empty=\"true\"/>\n                    </div>\n                    <div id=\"check-in-btn\" class=\"session-management-input-btn check-in-btn clickable\" data-action=\"check-in\">\n                        <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"session_management.check_in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":30},"end":{"line":55,"column":66}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"check-in-info-container\">\n            <span id=\"ad-hoc-error\" class=\"text\"></span>\n            <span id=\"ad-hoc-loader\" class=\"loader\"></span>\n        </div>\n\n        <div class=\"session-management-close-container\">\n            <div id=\"check-in-close\" class=\"session-management-close-btn clickable\" data-action=\"close\">\n                <span class=\"icon icon-v2-close\"></span>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});
