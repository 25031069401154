'use strict';

var app = require('../app');

app.service('OverlayConfigsService', function() {
    return {
        initialize: function() {
            this.deviceService = app.getService('DeviceService');
            this.initSettingsList();
        },

        get: function(overlayId) {
            return this.configs[overlayId] || null;
        },

        /**
         * After checking WLAN region in bootstrap, the overlay config should be updated.
         * (RELEASE-3567)
         *
         * @param region 0: show WLAN settings as before
         *        region 1/2: show WLAN Region select
         */
        onAdjustmentWlanRegionChanged: function(region) {
            if (region === 0) {
                this.configs['wlan'].component = 'WlanRegion';
                this.configs['wlan'].fullHeight = true;

                return;
            }

            this.configs['wlan'].component = 'Wlan';
            this.configs['wlan'].fullHeight = null;
        },

        initSettingsList: function() {
            const isEmpty = {
                'empty': this.deviceService.isCboxProDualProjection()
            };

            this.configs = {
                'settings-list': {
                    overlayTitle: 'settings.title',
                    navigation: true,
                    fullScreen: true,
                    disconnectButton: true,
                    closeButton: true,
                    notification: true,
                    'items': [
                        {
                            'id': 'general-settings',
                            'titleKey': 'settings.menu_general_settings',
                            'icon': 'icon-settings-1',
                            'subitems': [
                                {
                                    'id': 'wallpaper-settings',
                                    'titleKey': 'settings.wallpaper',
                                    'icon': 'icon-pictures'
                                },
                                {
                                    'id': 'splash-screen-settings',
                                    'titleKey': 'settings.splash_screen',
                                    'icon': 'icon-conference-room-mode',
                                    'shownOnCPR': false
                                },
                                {
                                    'id': 'factory-reset',
                                    'titleKey': 'settings.menu_factory_reset',
                                    'icon': 'icon-reset'
                                },
                                {
                                    'id': 'system-reboot',
                                    'titleKey': 'settings.menu_system_reboot',
                                    'icon': 'icon-v2-power'
                                },
                                {
                                    'id': 'debug-settings',
                                    'titleKey': 'settings.debug_settings',
                                    'icon': 'icon-advanced-logging'
                                },
                                {
                                    'id': 'troubleshoot',
                                    'titleKey': 'settings.menu_troubleshoot',
                                    'icon': 'icon-setup_l',
                                    'shownOnCPR': false,
                                    'shownOnCPM': false
                                },
                                {
                                    'id': 'about',
                                    'titleKey': 'settings.menu_about',
                                    'icon': 'icon-info3'
                                }
                            ]
                        },
                        {
                            'id': 'login-settings',
                            'titleKey': 'settings.menu_login_settings',
                            'icon': 'icon-login1'
                        },
                        {
                            'id': 'network-settings',
                            'titleKey': 'settings.menu_network',
                            'icon': 'icon-nav-lan',
                            'empty': true,
                            'subitems': [
                                {
                                    'id': 'ethernet-settings',
                                    'titleKey': 'settings.menu_ethernet', // Will be replaced in navigation-bar.
                                    'specTitleKey': 'ethernet1TitleKey',
                                    'icon': 'icon-nav-lan'
                                },
                                {
                                    'id': 'ethernet2-settings',
                                    'titleKey': 'settings.menu_ethernet2',
                                    'icon': 'icon-nav-lan',
                                    'shownOnCPP': false,
                                    'shownOnCBP': false,
                                    'shownOnCPM': false
                                },
                                {
                                    'id': 'wlan',
                                    'titleKey': 'settings.menu_wlan',
                                    'icon': 'icon-wifi-status'
                                },
                                {
                                    'id': 'routing-settings',
                                    'titleKey': 'settings.ip_routing',
                                    'icon': 'icon-vmatrix'
                                }
                            ]
                        },
                        {
                            'id': 'security-settings',
                            'titleKey': 'settings.security_settings',
                            'icon': 'icon-security_l'
                        },
                        {
                            'id': 'proxy-settings',
                            'titleKey': 'settings.proxy_settings',
                            'icon': 'icon-proxy'
                        },
                        {
                            'id': 'ftp-settings',
                            'titleKey': 'settings.menu_ftp_settings',
                            'icon': 'icon-ftp1',
                            'shownOnCBP': false,
                            'shownOnCPM': false
                        },
                        {
                            'id': 'cloud-settings',
                            'titleKey': 'settings.menu_cloud_settings',
                            'icon': 'icon-cloud',
                            'shownOnCBP': false,
                            'shownOnCPM': false
                        },
                        {
                            'id': 'netdrive-settings',
                            'titleKey': 'settings.menu_netdrive_settings',
                            'icon': 'icon-netzlaufwerk',
                            'shownOnCBP': false,
                            'shownOnCPM': false
                        },
                        {
                            'id': 'webconference-settings',
                            'titleKey': 'settings.webconference',
                            'icon': 'icon-v2-webcast-large',
                            'shownOnCBP': false,
                            'shownOnCPM': false
                        },
                        {
                            'id': 'zoom-settings',
                            'titleKey': 'settings.zoom',
                            'icon': 'icon-v2-webconference',
                            'shownOnCBP': false,
                            'shownOnCPM': false
                        },
                        {
                            'id': 'record-stream-settings',
                            'titleKey': 'settings.record_stream',
                            'icon': 'icon-stream-record',
                            'specTitleKey': 'recordWebcastTitleKey',
                            'specEmpty': 'recordStreamSettingsEmpty',
                            'featurePack': true,
                            'shownOnCCP': true,
                            'shownOnCBC': false,
                            'shownOnCPP': false,
                            'shownOnCBP': false,
                            'shownOnCPM': false,
                            ...isEmpty,
                            'subitems': [
                                {
                                    'id': 'record-settings',
                                    'titleKey': 'settings.menu_record_settings',
                                    'icon': 'icon-record',
                                    'featurePack': true,
                                    'shownOnCCP': true,
                                    'shownOnCBC': false,
                                    'shownOnCPP': false,
                                    'shownOnCBP': false,
                                    'shownOnCPM': false
                                },
                                {
                                    'id': 'streaming-settings',
                                    'titleKey': 'settings.menu_streaming',
                                    'specTitleKey': 'webcastTitleKey',
                                    'icon': 'icon-stream_l',
                                    'featurePack': true,
                                    'shownOnCCP': true,
                                    'shownOnCBC': false,
                                    'shownOnCPP': false,
                                    'shownOnCBP': false,
                                    'shownOnCPM': false
                                }
                            ]
                        },
                        {
                            'id': 'mirror-settings',
                            'titleKey': 'settings.menu_mirror',
                            'icon': 'icon-mirror',
                            'shownOnCPR': false
                        },
                        {
                            'id': 'audio-settings',
                            'titleKey': 'settings.audio_settings',
                            'icon': 'icon-audio_l'
                        },
                        {
                            'id': 'hdcp-settings',
                            'titleKey': 'settings.menu_hdcp_settings',
                            'icon': 'icon-hdcp',
                            'shownOnCCP': false,
                            'shownOnCBC': false,
                            'shownOnCPP': false,
                            'shownOnCBP': false,
                            'shownOnCPM': false
                        },
                        {
                            'id': 'input-settings',
                            'titleKey': 'settings.menu_input_settings',
                            'icon': 'icon-v2-moderator-mode-off',
                            'shownOnCBP': false,
                            'shownOnCPM': false
                        },
                        {
                            'id': 'output-settings',
                            'titleKey': 'settings.menu_output_settings',
                            'icon': 'icon-v2-moderator-mode-on'
                        },
                        {
                            'id': 'collaboration-settings',
                            'titleKey': 'settings.collaboration_settings',
                            'icon': 'icon-annotate',
                            'shownOnCPM': false
                        },
                        {
                            'id': 'office365-settings',
                            'titleKey': 'settings.office365',
                            'icon': 'icon-office-365',
                            'featurePack': true
                        },
                        {
                            'id': 'vmeeting-settings',
                            'titleKey': 'settings.vmeeting',
                            'icon': 'icon-office_calendar',
                            'onlyCb1': true
                        },
                        {
                            'id': 'matrix-settings',
                            'titleKey': 'settings.matrix_full',
                            'icon': 'icon-vmatrix',
                            'subitems': [
                                {
                                    'id': 'matrix-streaming-settings',
                                    'titleKey': 'settings.matrix_streaming',
                                    'icon': 'icon-vmatrix'
                                }
                            ],
                            'shownOnCCP': false,
                            'shownOnCBC': false,
                            'shownOnCPP': false,
                            'shownOnCBP': false,
                            'shownOnCPM': false
                        },
                        {
                            'id': 'visualizer-settings',
                            'titleKey': 'settings.visualizer_settings',
                            'icon': 'icon-visualizer',
                            'empty': true,
                            'subitems': [
                                {
                                    'id': 'visualizer-exposure-settings',
                                    'titleKey': 'settings.visualizer_exposure_settings',
                                    'icon': 'icon-exposure_l'
                                },
                                {
                                    'id': 'visualizer-color-settings',
                                    'titleKey': 'settings.visualizer_color_settings',
                                    'icon': 'icon-color_l'
                                }
                            ],
                            'shownOnCPP': false,
                            'shownOnCBP': false,
                            'shownOnCPM': false
                        },
                        {
                            'id': 'peripheral-settings',
                            'titleKey': 'settings.peripheral_control',
                            'icon': 'icon-smarthome'
                        },
                        {
                            'id': 'room-controller-settings',
                            'titleKey': 'settings.room_controller',
                            'icon': 'icon-qsc',
                            'shownOnCBR': true,
                            'shownOnCB1': true,
                            'shownOnCCP': true,
                            'shownOnCBC': false,
                            'shownOnCPP': false,
                            'shownOnCBP': false,
                            'shownOnCPR': false,
                            'shownOnCPM': false
                        }
                    ],
                    role: {
                        name: 'Settings',
                        key: 'show'
                    }
                },

                'media-list-cloud': {
                    view: 'simple',
                    component: 'MediaList',
                    titleKey: 'files.filelist_cloud_title',
                    storageType: 'cloud',
                    role: {
                        name: 'mediaListCloud',
                        key: 'show'
                    }
                },

                'Office365': {
                    view: 'simple',
                    component: 'Office365',
                    titleKey: 'sources.office365',
                    role: {
                        name: 'Office365',
                        key: 'show'
                    }
                },

                'ethernet-settings': {
                    view: 'simple',
                    component: 'EthernetSettings',
                    titleKey: 'settings.ethernet_title', // Will be replaced in overlay-header
                    specTitleKey: 'ethernet1TitleKey',
                    role: {
                        name: 'EthernetSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },

                'ethernet2-settings': {
                    view: 'simple',
                    component: 'Ethernet2Settings',
                    titleKey: 'settings.menu_ethernet2',
                    role: {
                        name: 'Ethernet2Settings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },

                'wlan-settings': {
                    view: 'simple',
                    component: 'WlanSettings',
                    titleKey: 'settings.wlan_title',
                    role: {
                        name: 'WlanSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },

                'wlan-list': {
                    view: 'simple',
                    component: 'WlanList',
                    titleKey: 'settings.wlan_list_title',
                    role: {
                        name: 'WlanSettings',
                        key: 'show'
                    }
                },
                'dropbox': {
                    view: 'simple',
                    component: 'Cloud',
                    id: 'dropbox',
                    titleKey: 'files.dropbox',
                    icon: 'icon-dropbox1',
                    role: {
                        name: 'mediaListCloud',
                        key: 'show'
                    }
                },
                'onedrive': {
                    view: 'simple',
                    component: 'Cloud',
                    id: 'onedrive',
                    titleKey: 'files.onedrive',
                    icon: 'icon-one-drive',
                    role: {
                        name: 'mediaListCloud',
                        key: 'show'
                    }
                },
                'iCloud': {
                    view: 'simple',
                    component: 'Cloud',
                    id: 'iCloud',
                    titleKey: 'files.icloud',
                    icon: 'icon-icloud',
                    role: {
                        name: 'mediaListCloud',
                        key: 'show'
                    }
                },
                'gdrive': {
                    view: 'simple',
                    component: 'Cloud',
                    id: 'gdrive',
                    titleKey: 'files.google_drive',
                    icon: 'icon-google-drive',
                    role: {
                        name: 'mediaListCloud',
                        key: 'show'
                    }
                },
                'box': {
                    view: 'simple',
                    component: 'Cloud',
                    id: 'box',
                    titleKey: 'files.box',
                    icon: 'icon-box-01',
                    role: {
                        name: 'mediaListCloud',
                        key: 'show'
                    }
                },
                'jianguoyun': {
                    view: 'simple',
                    component: 'Cloud',
                    id: 'jianguoyun',
                    titleKey: 'files.jianguoyun',
                    icon: 'icon-jianguoyun',
                    role: {
                        name: 'mediaListCloud',
                        key: 'show'
                    }
                },
                'webdav': {
                    view: 'simple',
                    component: 'Cloud',
                    id: 'webdav',
                    titleKey: 'files.webdav',
                    icon: 'icon-cloud2',
                    role: {
                        name: 'mediaListCloud',
                        key: 'show'
                    }
                },
                'filelist': {
                    view: 'simple',
                    component: 'Filelist',
                    titleKey: 'files.filelist_title',
                    disableScrolling: true,
                    role: {
                        name: 'mediaListLocal',
                        key: 'show'
                    }
                },
                'mirror-settings': {
                    view: 'simple',
                    component: 'MirrorSettings',
                    titleKey: 'settings.mirror_title',
                    role: {
                        name: 'MirrorSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'streaming-settings': {
                    view: 'simple',
                    component: 'StreamingSettings',
                    titleKey: 'settings.streaming_title',
                    specTitleKey: 'webcastTitleKey',
                    role: {
                        name: 'StreamingSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'input-settings': {
                    view: 'simple',
                    component: 'InputSettings',
                    titleKey: 'settings.input_title',
                    role: {
                        name: 'InputSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'output-settings': {
                    view: 'simple',
                    component: 'OutputSettings',
                    titleKey: 'settings.output_title',
                    role: {
                        name: 'OutputSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'general-settings': {
                    view: 'simple',
                    component: 'GeneralSettings',
                    titleKey: 'settings.general_title',
                    role: {
                        name: 'GeneralSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'wallpaper-settings': {
                    view: 'simple',
                    component: 'Wallpaper',
                    titleKey: 'settings.wallpaper',
                    role: {
                        name: 'GeneralSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav',
                    customRemoteFocus: true
                },
                'splash-screen-settings': {
                    view: 'simple',
                    component: 'SplashScreenSettings',
                    titleKey: 'settings.splash_screen',
                    role: {
                        name: 'GeneralSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'wlan': {
                    view: 'simple',
                    component: 'Wlan', // If adjustment Wlan region isn't set, this will be WlanRegion
                    titleKey: 'settings.wlan_title',
                    role: {
                        name: 'WlanSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'record-settings': {
                    view: 'simple',
                    component: 'RecordSettings',
                    titleKey: 'settings.record_title',
                    role: {
                        name: 'RecordSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'record-stream-settings': {
                    view: 'simple',
                    component: 'RecordStreamSettings',
                    titleKey: 'settings.record_stream',
                    specTitleKey: 'recordWebcastTitleKey',
                    role: {
                        name: 'GeneralSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'mirroring-settings': {
                    view: 'simple',
                    component: 'MirroringSettings',
                    titleKey: 'settings.mirroring_title',
                    role: {
                        name: 'MirroringSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'mirroring': {
                    view: 'simple',
                    component: 'Mirroring',
                    titleKey: 'mirroring.title',
                    role: {
                        name: 'Mirroring',
                        key: 'show'
                    }
                },
                'troubleshoot': {
                    view: 'simple',
                    component: 'Troubleshoot',
                    titleKey: 'settings.menu_troubleshoot',
                    role: {
                        name: 'Troubleshoot',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'about': {
                    view: 'simple',
                    component: 'About',
                    titleKey: 'settings.about_title',
                    role: {
                        name: 'About',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'login-settings': {
                    view: 'simple',
                    component: 'LoginSettings',
                    titleKey: 'settings.menu_login_settings',
                    role: {
                        name: 'LoginSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'visualizer-settings': {
                    view: 'simple',
                    component: 'VisualizerSettings',
                    titleKey: 'settings.visualizer_settings',
                    role: {
                        name: 'VisualizerSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'visualizer-exposure-settings': {
                    view: 'simple',
                    component: 'VisualizerSettings',
                    titleKey: 'settings.visualizer_exposure_settings',
                    role: {
                        name: 'VisualizerSettings',
                        key: 'show'
                    },
                    entryId: 'EXPOSURE',
                    historyMode: 'focus-nav'
                },
                'visualizer-color-settings': {
                    view: 'simple',
                    component: 'VisualizerSettings',
                    titleKey: 'settings.visualizer_color_settings',
                    role: {
                        name: 'VisualizerSettings',
                        key: 'show'
                    },
                    entryId: 'COLOR',
                    historyMode: 'focus-nav'
                },
                'ftp-settings': {
                    view: 'simple',
                    component: 'FtpSettings',
                    titleKey: 'settings.ftp_settings',
                    role: {
                        name: 'FtpSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav',
                    'shownOnCBP': false,
                    'shownOnCPM': false
                },
                'cloud-settings': {
                    view: 'simple',
                    component: 'CloudSettings',
                    titleKey: 'settings.cloud_settings',
                    role: {
                        name: 'CloudSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav',
                    'shownOnCBP': false,
                    'shownOnCPM': false
                },
                'factory-reset': {
                    view: 'simple',
                    component: 'FactoryReset',
                    titleKey: 'settings.factory_reset',
                    role: {
                        name: 'FactoryReset',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'system-reboot': {
                    view: 'simple',
                    component: 'SystemReboot',
                    titleKey: 'settings.system_reboot',
                    role: {
                        name: 'SystemReboot',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'netdrive-settings': {
                    view: 'simple',
                    component: 'NetdriveSettings',
                    titleKey: 'settings.menu_netdrive_settings',
                    role: {
                        name: 'NetdriveSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'hdcp-settings': {
                    view: 'simple',
                    component: 'HDCPSettings',
                    titleKey: 'settings.hdcp_settings_title',
                    role: {
                        name: 'HDCPSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'security-settings': {
                    view: 'simple',
                    component: 'SecuritySettings',
                    titleKey: 'settings.security_settings',
                    role: {
                        name: 'SecuritySettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'audio-settings': {
                    view: 'simple',
                    component: 'AudioSettings',
                    titleKey: 'settings.audio_settings',
                    role: {
                        name: 'AudioSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'webconference-settings': {
                    view: 'simple',
                    component: 'WebconferenceSettings',
                    titleKey: 'settings.webconference',
                    role: {
                        name: 'WebconferenceSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav',
                    'shownOnCBP': false,
                    'shownOnCPM': false
                },
                'zoom-settings': {
                    view: 'simple',
                    component: 'ZoomSettings',
                    titleKey: 'settings.zoom',
                    role: {
                        name: 'ZoomSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav',
                    'shownOnCBP': false,
                    'shownOnCPM': false
                },
                'collaboration-settings': {
                    view: 'simple',
                    component: 'CollaborationSettings',
                    titleKey: 'settings.collaboration_settings',
                    role: {
                        name: 'CollaborationSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'Internal': {
                    role: {
                        name: 'mediaListLocal',
                        key: 'show'
                    }
                },
                'VMeeting': {
                    role: {
                        name: 'mediaListLocal',
                        key: 'show'
                    }
                },
                'System': {
                    role: {
                        name: 'mediaListLocal',
                        key: 'show'
                    }
                },
                'peripheral-settings': {
                    view: 'simple',
                    component: 'PeripheralSettings',
                    titleKey: 'settings.peripheral_control',
                    role: {
                        name: 'PeripheralSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav',
                    'shownOnCPM': false
                },
                'routing-settings': {
                    view: 'simple',
                    component: 'RoutingSettings',
                    titleKey: 'settings.ip_routing_table',
                    role: {
                        name: 'RoutingSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'office365-settings': {
                    view: 'simple',
                    component: 'Office365Settings',
                    titleKey: 'settings.office365',
                    featurePack: true,
                    role: {
                        name: 'Office365Settings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'proxy-settings': {
                    view: 'simple',
                    component: 'ProxySettings',
                    titleKey: 'settings.proxy_settings',
                    role: {
                        name: 'ProxySettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'download-apps-list': {
                    view: 'simple',
                    component: 'DownloadApps',
                    titleKey: 'download_apps.title',
                    disableScrolling: true,
                    fullScreen: true,
                    role: {
                        name: 'DownloadApps',
                        key: 'show'
                    }
                },
                'matrix-main': {
                    view: 'simple',
                    component: 'Matrix',
                    disableScrolling: true,
                    titleKey: '',
                    fullScreen: true,
                    fullHeight: true,
                    closeButton: true,
                    isConfigurator: false,
                    matrixBox: 'documents',
                    role: {
                        name: 'Matrix',
                        key: 'show'
                    }
                },
                'matrix-configurator': {
                    view: 'simple',
                    component: 'Matrix',
                    disableScrolling: true,
                    titleKey: '',
                    fullScreen: true,
                    fullHeight: true,
                    closeButton: true,
                    saveButton: true,
                    isConfigurator: true,
                    matrixBox: 'devices',
                    role: {
                        name: 'Matrix',
                        key: 'show'
                    }
                },
                'matrix-settings': {
                    view: 'simple',
                    component: 'MatrixSettings',
                    titleKey: 'settings.matrix_full',
                    role: {
                        name: 'MatrixSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'matrix-streaming-settings': {
                    view: 'simple',
                    component: 'MatrixStreamingSettings',
                    titleKey: 'settings.matrix_streaming',
                    role: {
                        name: 'MatrixSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'vmeeting-settings': {
                    view: 'simple',
                    component: 'vMeetingSettings',
                    titleKey: 'settings.vmeeting',
                    featurePack: true,
                    role: {
                        name: 'vMeetingSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'debug-settings': {
                    view: 'simple',
                    component: 'DebugSettings',
                    titleKey: 'settings.debug_settings',
                    role: {
                        name: 'GeneralSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                },
                'room-controller-settings': {
                    view: 'simple',
                    component: 'RoomControllerSettings',
                    titleKey: 'settings.room_controller',
                    role: {
                        name: 'RoomControllerSettings',
                        key: 'show'
                    },
                    historyMode: 'focus-nav'
                }
            };
        }
    };
});

