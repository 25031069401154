'use strict';

var app = require('../app');
var $ = require('jquery');
const office365Icons = require('./../framebox-types').office365Icons;
const office365TitleKeys = require('./../framebox-types').office365TitleKeys;
const frameboxTypes = require('./../framebox-types').types;
const i18n = require('i18next');

app.service('Office365Service', function() {
    return {
        initialize: function() {
            this.mountService = app.getService('MountService');
            this.userSettingsService = app.getService('UserSettingsService');
            this.webconferenceService = app.getService('WebconferenceService');

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.bindEvents();
                }.bind(this));
        },

        bindEvents: function() {
        },

        onChangeOffice365Origin: function(origin) {
            if (typeof origin === 'boolean') {
                origin = origin ? 'local' : 'user';
            }

            this.deviceConnection
                .send([
                    {
                        command: 'setOffice365CredentialsOrigin',
                        data: { origin: origin }
                    }
                ]);
        },

        /**
         * Check if service is available on USB User Settings.
         * Open User Settings dialog if user settings aren't loaded yet.
         *
         * @param itemName name of app (msword, msexcel, ...)
         */
        userSettingsAvailableHandler: function(itemName) {
            var dfd = $.Deferred();

            this.mountService.checkIfServiceAvailable('office365.credentials')
                .then(function(data) {
                    this.deviceConnection
                        .send([
                            {
                                command: 'getUserSettingsStatus',
                                data: { mode: 'internal' }
                            },
                            'getOffice365CredentialsOrigin'
                        ])
                        .then(function(userSettings, credentialsOrigin) {
                            var officeSetting = userSettings.settings.office365;
                            var loggedIn = false;

                            if (officeSetting) {
                                loggedIn = officeSetting[0].credentials.status === 1;
                            }

                            var available = data.serviceAvailable && !loggedIn;

                            if (available && credentialsOrigin.origin === 'user') {
                                this.userSettingsService.openUserSettingsDialog(true, {
                                    loginOfficeApp: itemName,
                                    tryToConnect: true
                                });
                            } else {
                                available = false;
                            }

                            dfd.resolve(available);
                        }.bind(this));
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Checks if a local office 365 account is available and also checks the credentials origin.
         *
         * @returns {*|jQuery}
         */
        localOffice365AccountAvailable: function() {
            var dfd = $.Deferred();

            this.deviceConnection
                .send([
                    {
                        command: 'getUserSettingsStatus',
                        data: { mode: 'internal' }
                    },
                    { command: 'getOffice365CredentialsOrigin' }
                ])
                .then(function(userSettings, office365Origin) {
                    var office365LocalAccount = $.grep(userSettings.settings.office365, function(e) {
                        if (e.localAccount) {
                            return (e.localAccount.name === 'localAccount');
                        }
                    });
                    var localOffice365Account = false;

                    if (office365LocalAccount) {
                        localOffice365Account = office365LocalAccount[0].localAccount.status === 1;
                    }

                    dfd.resolve({
                        available: localOffice365Account,
                        origin: office365Origin.origin
                    });
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Show selection modal for Office 365 apps
         */
        showModal: function() {
            let selectionItems = this.getListOfApps();

            this.showTeamsAsSubSource()
                .then(show => {
                    if (!show) {
                        selectionItems = selectionItems.filter(item => item.type !== frameboxTypes['msteams']);
                    }

                    return selectionItems;
                })
                .then(items => {
                    this.localOffice365AccountAvailable()
                        .then(account => {
                            app.emit('modal.open', {
                                id: 'selection-v3',
                                title: i18n.t('sources.office365'),
                                items: items.map(item => {
                                    // Map type to id since selection modal expects id
                                    item.id = item.type;
                                    delete item.type;

                                    return item;
                                }),
                                onSelect: function(id) {
                                    this.userSettingsAvailableHandler(id)
                                        .then(available => {
                                            if (!available) {
                                                app.emit('menu.close');
                                                app.getService('FrameBoxService').openFrameBox(id);
                                            }
                                        });
                                }.bind(this),
                                actionSwitch: {
                                    title: i18n.t('modal.sources.office365'),
                                    show: account.available,
                                    checked: account.origin === 'local'
                                },
                                onToggle: function(checked) {
                                    this.onChangeOffice365Origin(checked);
                                }.bind(this)
                            });
                        });
                });
        },

        /**
         * Return the list of Office 365 apps
         */
        getListOfApps: function() {
            return [
                {
                    type: frameboxTypes['msoutlook'],
                    icon: office365Icons['msoutlook'],
                    name: i18n.t(office365TitleKeys['msoutlook'])
                },
                {
                    type: frameboxTypes['msword'],
                    icon: office365Icons['msword'],
                    name: i18n.t(office365TitleKeys['msword'])
                },
                {
                    type: frameboxTypes['msexcel'],
                    icon: office365Icons['msexcel'],
                    name: i18n.t(office365TitleKeys['msexcel'])
                },
                {
                    type: frameboxTypes['mspowerpoint'],
                    icon: office365Icons['mspowerpoint'],
                    name: i18n.t(office365TitleKeys['mspowerpoint'])
                },
                {
                    type: frameboxTypes['msonenote'],
                    icon: office365Icons['msonenote'],
                    name: i18n.t(office365TitleKeys['msonenote'])
                },
                {
                    type: frameboxTypes['msplanner'],
                    icon: office365Icons['msplanner'],
                    name: i18n.t(office365TitleKeys['msplanner'])
                },
                {
                    type: frameboxTypes['msteams'],
                    icon: office365Icons['msteams'],
                    name: i18n.t(office365TitleKeys['msteams'])
                },
                {
                    type: frameboxTypes['mswhiteboard'],
                    icon: office365Icons['mswhiteboard'],
                    name: i18n.t(office365TitleKeys['mswhiteboard'])
                }
            ];
        },

        /**
         * Don't show Teams as sub source if webconference framebox is open
         * or already displayed in top level of sources menu
         *
         * @return {*}
         */
        showTeamsAsSubSource: function(checkSources = true) {
            return app.getService('FrameBoxService')
                .hasFrameboxByName(['webconference', 'zoom', 'teams', 'otherContent'])
                .then(data => {
                    return !data.hasFramebox;
                })
                .then(showTeams => {
                    if (!showTeams) {
                        return showTeams;
                    } else if (checkSources) {
                        return this.deviceConnection
                            .send('getContentSources')
                            .then(data => !data.sources.some(source => source.type === 'msteams'));
                    }

                    return true;
                });
        }
    };
});
