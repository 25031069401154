'use strict';

const app = require('../../app');
const fsUtils = require('../filebrowser/utils.js');

const filelistTpl = require('./filelist.html');
const filebrowserUtils = require('../filebrowser/utils.js');
const vueUtils = require('../../components-vue/util.js');
const draggable = require('vuedraggable');

const Vuex = require('vuex');
const VueVirtualScroller = require('vue-virtual-scroller');

const mountStates = require('./../../states').mountStates;

const MAX_POLL_INTERVAL_WHEN_CLOSED = 60000;
const MAX_POLL_INTERVAL_WHEN_OPEN = 0;

app.component('Filelist', {
    engine: 'vue',
    template: filelistTpl,
    className: 'filetree',

    data: function() {
        return {
            syncState: '',
            list: [{
                path: 'Internal:///test.png',
                type: 'image',
                icon: 'icon-filetype-picture',
                name: 'test.png',
                selected: false
            }],
            isDraggable: false,
            multiselectActive: false,
            hasAnyCopyTargets: true,
            mountState: '',
            focusable: false,
            optionsMenuState: {
                show: false,
                item: {},
                positionX: 0,
                positionY: 0
            },
            fileTreeContainerPositionTop: 0,
            fileTreeContainerPositionLeft: 0,
            scalingFactor: 1
        };
    },

    computed: {
        isMounted: function() {
            return this.mountState === mountStates.mounted;
        },
        isAuthenticating: function() {
            return this.mountState === mountStates.authenticate;
        },
        mountingBusy: function() {
            return this.mountState === mountStates.busy;
        },
        mountingFailed: function() {
            return this.mountState === mountStates.mountFailed;
        },
        notResponding: function() {
            return this.mountState === mountStates.notResponding;
        },
        isDisconnected: function() {
            return this.mountState === mountStates.notMounted;
        },
        isDisabled: function() {
            return this.mountState === mountStates.disabled;
        },

        isCloudService: function() {
            return this.component.options.configs.isCloudProvider;
        },

        hasBackButton: function() {
            var location = fsUtils.splitMountAndFilename(this.component.options.configs.path);

            return location.filename !== '/';
        },

        location: function() {
            return fsUtils.splitMountAndFilename(this.component.options.configs.path) || {
                mount: '',
                filename: ''
            };
        },

        upperFolderName: function() {
            const upperFolderPath = fsUtils.dirname(this.location.filename);

            return this.basenameOrMountName(upperFolderPath);
        },

        currentFolderName: function() {
            return this.basenameOrMountName(this.location.filename);
        },

        selectedFiles: function() {
            return this.list.filter(function(file) {
                return file.selected;
            });
        },

        numberOfFilesSelected: function() {
            return this.list.filter(function(file) {
                return file.selected;
            }).length;
        },

        allFilesSelected: function() {
            return this.list.every(function(file) {
                return file.selected;
            });
        },

        fileOperationsAvailable: function() {
            return this.location.mount === 'Internal' || this.location.mount === 'System';
        },

        multiselectAvailable: function() {
            return this.fileOperationsAvailable && this.list.length > 0;
        },

        selectionIncludesFolder: function() {
            return this.selectedFiles.some(f => f.type === 'dir');
        },

        onlyFoldersSelected: function() {
            return this.selectedFiles.length > 0
                && this.selectedFiles.length === this.selectedFiles.filter(f => f.type === 'dir').length;
        },

        /**
         * Group file list into rows to support virtual scroller
         * @return {[]}
         */
        listRows: function() {
            const itemsPerRow = this.itemsPerRow;
            let listRows = [];

            for (let i = 0; i < this.list.length; i += itemsPerRow) {
                listRows = [
                    ...listRows, Object.assign({}, {
                        id: i,
                        items: this.list.slice(i, i + itemsPerRow)
                    })
                ];
            }

            return listRows;
        },

        /**
         * Number of file items per row depends on the UI scaling setting
         * @return {number}
         */
        itemsPerRow: function() {
            if (this.getUiScalingSize === 'large') {
                return 4;
            } else if (this.getUiScalingSize === 'small') {
                return 8;
            } else {
                return 6;
            }
        },

        ...Vuex.mapGetters('uiSettings', ['getUiScalingSize'])
    },

    watch: {
        syncState: function(newVal) {
            if (newVal === 'ready') {
                this.focusFirstElement();
            }
        }
    },

    methods: {
        /**
         * Navigate one folder up
         */
        navigateBack: function() {
            const options = this.component.options;
            const location = fsUtils.splitMountAndFilename(options.configs.path);
            let upperFolder = fsUtils.dirname(location.filename);

            const isRootFolder = upperFolder === '/';
            upperFolder += isRootFolder ? '' : '/';

            this.component.emit('filebrowser.open', {
                path: location.mount + '://' + upperFolder,
                isCloudProvider: options.configs.isCloudProvider,
                mountName: options.configs.mountName
            });
        },

        /**
         * Retrieve mount name if in root directory or upper folder name
         *
         * @param path path to resolve name
         */
        basenameOrMountName: function(path) {
            var bn = fsUtils.basename(path);
            if (bn === '/') {
                return this.component.options.configs.mountName;
            } else {
                return bn;
            }
        },

        /**
         * Handles item selection
         *
         * @param file view model of file that has been clicked
         */
        selectItem: function(file) {
            if (file.unknownFileType) {
                return;
            }

            if (this.multiselectActive) {
                file.selected = !file.selected;

                return;
            }

            const handler = this.component.fileSelectHandler[file.type];

            if (handler) {
                if (!file.isDownloaded && this.component.options.configs.isCloudProvider && file.type !== 'dir') {
                    if (file.downloadStatus === 'running') {
                        this.component.downloadsService.abortDownload(file.path);
                    } else {
                        this.component.downloadsService.startDownload(file.path, 'open');
                    }

                    this.component.emit('main-loop.fast.start', {
                        id: 'filelist'
                    });
                } else {
                    handler.call(this.component, file);
                }
            }
        },

        /**
         * Handles click on more button.
         *
         * @param file view model of file that has been clicked
         *   file.item
         *   file.positionX x-coordinate of more menu click
         *   file.positionY y-coordinate of more menu click
         */
        moreSelected: function(file) {
            Object.assign(this.optionsMenuState, {
                show: true,
                ...file
            });

            this.updateScalingFactor();
        },

        /**
         * Update view port position of file tree container and calculate scaling factor
         */
        updateScalingFactor: function() {
            const fileTreeContainerRect = this.$refs.filetree.getBoundingClientRect();
            this.fileTreeContainerPositionTop = fileTreeContainerRect.top;
            this.fileTreeContainerPositionLeft = fileTreeContainerRect.left;
            this.scalingFactor = fileTreeContainerRect.width / this.$refs.filetree.offsetWidth;
        },

        /**
         * Handles click behind when more overlay is open
         */
        focusElementClicked: function() {
            this.optionsMenuState.show = false;
        },

        /**
         * Toggle Multiselect mode
         */
        toggleMultiselect: function() {
            this.multiselectActive = !this.multiselectActive;
            if (!this.multiselectActive) {
                this.list.forEach(function(file) {
                    file.selected = false;
                });
            }
        },

        /**
         * Mark all files as selected
         */
        selectAllFiles: function() {
            this.list.forEach(function(file) {
                file.selected = true;
            });
        },

        /**
         * Mark all files as not selected
         */
        deselectAllFiles: function() {
            this.list.forEach(function(file) {
                file.selected = false;
            });
        },

        /**
         * Upload files to selected targets.
         * This function opens a model to select targets.
         *
         * @param files array of files (view model) to be uploaded
         */
        uploadFiles: function(files) {
            this.component.emit('modal.open', {
                id: 'uploadTargetSelection',
                onCancel: function() {
                    this.optionsMenuState.show = false;

                    this.component.emit('modal.close');
                }.bind(this),
                onConfirm: function({ targets }) {
                    targets.forEach(function(target) {
                        files.forEach(function(source) {
                            this.component.fileops.uploadFile({
                                source: source.path,
                                target: {
                                    path: `${target.id}:///wolfvision/`,
                                    type: target.type
                                }
                            });
                        }.bind(this));
                    }.bind(this));

                    this.list.forEach(function(file) {
                        file.selected = false;
                    });
                    this.optionsMenuState.show = false;
                    this.multiselectActive = false;

                    this.component.emit('modal.close');
                    this.component.pollHelper.schedulePoll();
                    this.component.emit('main-loop.fast.start', {
                        id: 'filelist'
                    });
                }.bind(this)
            });
        },

        /**
         * Deletes given files
         *
         * @param files array of files (view model) to be deleted
         */
        deleteFiles: function(files) {
            let messageKey;
            let additionalMessageKey;
            if (files.length > 1) {
                if (files.every(file => file.isFolder)) {
                    messageKey = this.i18n('files.delete_folder_multi');
                    additionalMessageKey = this.i18n('files.delete_warning_folder_multi').replace('::count::', files.length);
                } else {
                    messageKey = this.i18n('files.delete_multi');
                    additionalMessageKey = this.i18n('files.delete_warning_file_multi').replace('::count::', files.length);
                }
            } else if (files[0].isFolder) {
                messageKey = this.i18n('files.delete_folder');
                additionalMessageKey = this.i18n('files.delete_warning_folder');
            } else {
                messageKey = this.i18n('files.delete');
                additionalMessageKey = this.i18n('files.delete_warning_file');
            }

            this.component.emit('modal.open', {
                id: 'confirmDelete',
                messageKey,
                additionalMessageKey,
                onDiscare: function() {
                    this.optionsMenuState.show = false;
                }.bind(this),
                onConfirm: function() {
                    this.list.forEach(function(file) {
                        file.selected = false;
                    });
                    this.optionsMenuState.show = false;
                    this.multiselectActive = false;

                    files.forEach(function(file) {
                        this.component.fileops.deleteFile({
                            file
                        });
                    }.bind(this));

                    this.component.emit('main-loop.fast.start', {
                        id: 'filelist'
                    });
                }.bind(this)
            });
        },

        i18n: vueUtils.i18n,

        /**
         * Re-initiate mounting of failed cloud service
         */
        retryMounting: function() {
            app.emit('media-list.authenticate', this.component.options.configs.id);
        },

        /**
         * Closes the filebrowser if a download finishes
         */
        maybeCloseWhenDownloadFinished: function(item) {
            if (item.status === 'done') {
                app.emit('file-browser.close');
            }
        },

        /**
         * Focus first element in the file list with remote control.
         */
        focusFirstElement: function() {
            setTimeout(() => {
                if (this.component.options.configs.focusable) {
                    this.component.remote.focus(this.$refs.filelist);
                }
            }, 0);
        },

        dragStart: function() {
            this.$store.dispatch('controlScreen/setDragging', true);
            this.component.emit('file-drag.start');
        },

        dragEnd: function() {
            this.$store.dispatch('controlScreen/setDragging', false);
            this.component.emit('file-drag.end');
        },

        /**
         * Chosen class is removed because otherwise the sortable-ghost element is visible during the transition
         */
        clone: function(evt) {
            evt.item.classList.remove('chosen');
        }
    },

    components: {
        'filelist-item': require('./filelist-item/filelist-item'),
        'options-menu': require('./options-menu/options-menu'),
        'dynamic-scroller': VueVirtualScroller.DynamicScroller,
        'dynamic-scroller-item': VueVirtualScroller.DynamicScrollerItem,
        draggable
    },

    created: function() {
        this.isDraggable = this.component.deviceService.isCboxProDualProjection();
        this.focusable = this.component.options.configs.focusable;
        if (this.focusable) {
            this.component.pollHelper.throttleInterval(MAX_POLL_INTERVAL_WHEN_OPEN);
        } else {
            this.component.pollHelper.throttleInterval(MAX_POLL_INTERVAL_WHEN_CLOSED);
        }

        this.evctx = vueUtils.eventContext();
        this.evctx.on('livestream-size.update', this.updateScalingFactor.bind(this));
        this.evctx.on('aspect-ratio.changed', this.updateScalingFactor.bind(this));
        this.evctx.on('active-framebox.changed', this.updateScalingFactor.bind(this));

        this.evctx.on('file-browser.opened', function() {
            this.focusable = true;
            this.component.pollHelper.throttleInterval(MAX_POLL_INTERVAL_WHEN_OPEN);
        }.bind(this));
        this.evctx.on('file-browser.closed', function() {
            this.focusable = false;
            this.$focusedEl = undefined;
            this.optionsMenuState.show = false;
            this.component.pollHelper.throttleInterval(MAX_POLL_INTERVAL_WHEN_CLOSED);
        }.bind(this));
        this.evctx.on('file-browser.back', function() {
            if (this.hasBackButton) {
                this.navigateBack();
            }
        }.bind(this));
        this.evctx.on('download-list.add', (item) => {
            this.maybeCloseWhenDownloadFinished(item);
        });
        this.evctx.on('download-list.update', (item) => {
            this.maybeCloseWhenDownloadFinished(item);
        });
        this.evctx.on('remote.focus.change', function(data) {
            if (!this.focusable) {
                return;
            }

            // Close file menu ('more') if it is no longer focused by the remote control.
            let $curFocusedEl = app.$(data.el);
            if (this.$focusedEl && this.$focusedEl.parent().hasClass('options-menu')
                && !$curFocusedEl.parent().hasClass('options-menu')) {
                this.focusElementClicked();
            }

            this.$focusedEl = $curFocusedEl;
        }.bind(this));
    },

    mounted: function() {
        this.$nextTick(function() {
            this.focusFirstElement();
        });
        this.updateScalingFactor();
    },

    destroyed: function() {
        this.evctx.close();
    },

    initialize: function() {
        this.downloadsService = this.getService('DownloadsService');
        this.framebox = this.getService('FrameBoxService');
        this.remote = this.getService('RemoteService');
        this.mountService = this.getService('MountService');
        this.customUiSettings = app.getService('CustomUiSettings');
        this.uriService = app.getService('URIService');
        this.deviceService = app.getService('DeviceService');
        this.getFileListInProgress = false;
        this.fileops = filebrowserUtils.fileopsFactory({
            deviceConnection: this.deviceConnection,
            uriService: this.uriService
        });

        this.wolfprot = vueUtils.wolfprot();
        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return this.load();
            }.bind(this)
        });
        this.pollHelper.on('data', function(data) {
            this.$viewModel.syncState = data.syncState;
            this.$viewModel.list = this.copyPreservingSelection(this.$viewModel.list || [], data.list || []);
            this.$viewModel.hasAnyCopyTargets = data.hasAnyCopyTargets;
            this.$viewModel.mountState = data.mountState;
            if (!data.list.find(item => item.path === this.$viewModel.optionsMenuState.item.path)) {
                this.$viewModel.optionsMenuState.show = false;
            }
        }.bind(this));
        this.pollHelper.throttleInterval(MAX_POLL_INTERVAL_WHEN_CLOSED);

        this.bindEvents();
    },

    bindEvents: function() {
        this.on('main-loop.update', () => {
            this.pollHelper.schedulePoll();
        });
        this.on('main-loop.update.filelist', () => {
            this.pollHelper.schedulePoll();
        });
    },

    serialize: function() {
        return this.pollHelper.serialize();
    },

    /**
     * Copy current view state to new data from backend
     * e.g. collapsed value
     *
     * @param old old view model with view state
     * @param data new data to apply view state on
     */
    copyPreservingSelection: function(old, newData) {
        return newData.map(function(item) {
            var previousFile = old.find(function(oldItem) {
                return oldItem.path === item.path;
            });

            return Object.assign({}, item, {
                selected: (previousFile || {}).selected || false
            });
        });
    },

    load: function() {
        return this.wolfprot.talkMulti([
            {
                command: 'getFileList',
                data: {
                    pathname: this.options.configs.path
                }
            },
            'getMounts'
        ]).then(function(response) {
            const fileList = response[0].fileList;
            const mountList = response[1].mountList;

            const possibleUploadTargets = filebrowserUtils.possibleUploadTargets(mountList);

            const mount = mountList.find(m => m.id === this.options.configs.id);

            let parsedData = [];
            const isUSB = ('USB' === this.options.configs.path.substring(0, 3));

            fileList.forEach(function(file) {
                var fileData = {};
                fileData.name = file.name;
                fileData.type = file.type;
                fileData.preload = file.preload;
                fileData.isDownloaded = file.preload === 100;
                fileData.isFailed = file.downloadStatus === 'failed';
                fileData.path = this.createPath(file);
                fileData.isFolder = (file.type === 'dir');
                fileData.size = '0.00';
                fileData.modified = '22.09.1991';
                fileData.unknownFileType = (file.type === 'unknown');
                fileData.downloadStatus = file.downloadStatus;

                parsedData.push(fileData);
            }.bind(this));

            const result = {
                syncState: response[0].syncState,
                list: parsedData,
                isUSB: isUSB,
                isCloud: this.options.configs.isCloudProvider,
                hasAnyCopyTargets: possibleUploadTargets.length > 0,
                mountState: mount.status
            };

            return Object.assign({}, result, {
                error: false
            });
        }.bind(this), function() {
            return {
                error: true
            };
        });
    },

    /**
     * @method createPath
     * @param {Object} file
     */
    createPath: function(file) {
        const name = file.recentfilepath || file.name;
        var path = this.options.configs.path + name;

        if (file.type === 'dir') {
            path = path + '/';
        }

        return path;
    },

    /**
     * Handlers for all known file types
     * @type {Object}
     */
    fileSelectHandler: {
        'dir': function(file) {
            this.emit('filebrowser.open', {
                'path': file.path,
                isCloudProvider: this.options.configs.isCloudProvider,
                mountName: this.options.configs.mountName
            });
        },

        'image': function(file) {
            this.emit('file-browser.close');
            this.framebox.openFile(file);
        },

        'video': function(file) {
            this.emit('file-browser.close');
            this.framebox.openFile(file);
        },

        'pdf': function(file) {
            this.emit('file-browser.close');
            this.framebox.openFile(file);
        },

        'presentation': function(file) {
            this.emit('file-browser.close');
            this.framebox.openFile(file);
        },

        'calc': function(file) {
            this.emit('file-browser.close');
            this.framebox.openFile(file);
        },

        'text': function(file) {
            this.emit('file-browser.close');
            this.framebox.openFile(file);
        },

        'html': function(file) {
            this.emit('file-browser.close');
            this.framebox.openFile(file);
        },

        'audio': function(file) {
            this.emit('file-browser.close');
            this.framebox.openFile(file);
        }
    }
});
