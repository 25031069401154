// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "is-disabled";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div id=\"osd-btn-timeframe-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"min") : depth0), depth0))
    + "\"\n             class=\"osd-action-btn is-focusable clickable "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"valid") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":58},"end":{"line":17,"column":97}}})) != null ? stack1 : "")
    + "\"\n             data-action=\"choose-timeframe\"\n             data-minutes=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"min") : depth0), depth0))
    + "\">\n            <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "</span>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"osd-btn-container\" data-nav-area=\".is-focusable:not(.is-disabled)\">\n    <div id=\"osd-btn-ignore\"\n         class=\"osd-combined-action-btn is-focusable clickable\"\n         data-action=\"ignore\">\n        <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"session_management.okay",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":14},"end":{"line":5,"column":45}}}))
    + "</span>\n    </div>\n    <div class=\"separator-mini\"></div>\n    <div id=\"osd-btn-extend\"\n         class=\"osd-combined-action-btn is-focusable clickable "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasTimeframes") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":63},"end":{"line":9,"column":110}}})) != null ? stack1 : "")
    + "\"\n         data-action=\"extend\">\n        <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"session_management.extend_meeting",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":14},"end":{"line":11,"column":55}}}))
    + "</span>\n    </div>\n</div>\n<div id=\"osd-extend-container\" class=\"osd-action-btn-container hidden\" data-nav-area=\".is-focusable:not(.hidden)\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"timeframes") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":22,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});
