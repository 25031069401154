'use strict';

var $ = require('jquery');
var _ = require('lodash');
var platform = require('./../platform/platform');

/**
 * Quickfix for rendering-bug
 * - toggles 1x1px to trigger redraw to browser
 * @class Renderer
 * @constructor
 */
var Renderer = function() {
    if (platform.checks.isCboxAux || platform.checks.isCbox) {
        this.start();
    }
};

/**
 * Start toggler
 */
Renderer.prototype.start = function() {
    this.appendDom();
    window.requestAnimationFrame(this.loop.bind(this));
};

/**
 * Toggle pixel
 */
Renderer.prototype.loop = function() {
    if (this.$div.css('backgroundColor') === 'rgb(255, 0, 0)') {
        this.$div.css({
            backgroundColor: 'yellow'
        });
    } else {
        this.$div.css({
            backgroundColor: 'red'
        });
    }

    _.delay(function() {
        window.requestAnimationFrame(this.loop.bind(this));
    }.bind(this), 500);
};

/**
 * Append dom body
 */
Renderer.prototype.appendDom = function() {
    this.$div = $('<div />');

    this.$div.css({
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: '1000',
        width: '1px',
        height: '1px',
        pointerEvents: 'none',
        backgroundColor: 'red',
        opacity: 0.01
    });

    $('body').append(this.$div);
};

module.exports = Renderer;
