'use strict';

var app = require('../../../app');
var confirmTpl = require('./confirm-v3.hbs');

app.component('ConfirmV3', {
    template: confirmTpl,

    className: 'confirm-v3',

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.confirmed = false;
        this.autohideTimeout = false;

        this.startAutoHide();
    },

    /**
     * @method serialize
     */
    serialize: function() {
        return {
            successTextKey: this.options.configs.successTextKey,
            discareTextKey: this.options.configs.discareTextKey,
            messageKey: this.options.configs.messageKey,
            additionalMessageKey: this.options.configs.additionalMessageKey,
            showCloseButton: this.options.configs.showCloseButton
        };
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.bindDOMEvents();
        this.checkFormActionSpace();
    },

    /**
     * Check if one button is below the other and remove margin (10px)
     */
    checkFormActionSpace: function() {
        if (this.$el.find('.form-action-container').width()
            < (this.$el.find('#confirm-discard').width() + this.$el.find('#confirm-submit').width() + 10)) {
            this.$el.find('.btn-form').css('margin', '5px 0');
        }
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.$el.on('click', '.form-action-submit', this.onConfirmHandler.bind(this));
        this.$el.on('click', '.form-action-discare', this.onDiscareHandler.bind(this));

        if (this.options.configs.showCloseButton) {
            this.$el.find('.close-btn').on('click', function() {
                this.emit('modal.close');
            }.bind(this));
        }
    },

    /**
     * @method onConfirmHandler
     */
    onConfirmHandler: function() {
        this.confirmed = true;
        this.emit('modal.close');

        if (this.options.configs.onConfirm) {
            this.options.configs.onConfirm();
        }
    },

    /**
     * @method onDiscareHandler
     */
    onDiscareHandler: function() {
        this.confirmed = true;
        this.emit('modal.close');

        if (this.options.configs.onDiscare) {
            this.options.configs.onDiscare();
        }
    },

    /**
     * Start timer to close confirm message automatically.
     * This is used for e.g. Display settings confirmation.
     */
    startAutoHide: function() {
        if (!this.options.configs.hold) {
            return;
        }

        this.autohideTimeout = setTimeout(function() {
            this.emit('modal.close', {
                id: this.options.configs.id
            });
        }.bind(this), this.options.configs.hold);
    },

    /**
     * @method destroy
     */
    destroy: function() {
        /**
         * @deprecated onCancel
         */
        if (this.options.configs.onCancel) {
            this.options.configs.onDiscare = this.options.configs.onCancel;
        }

        var handler = this.options.configs.onDiscare;

        if (!!handler && !this.confirmed) {
            handler();
        }

        clearTimeout(this.autohideTimeout);
    }
});
