const template = require('./prepare-area.html');
const vueUtils = require('../../../components-vue/util.js');
const Vuex = require('vuex');
const draggable = require('vuedraggable');
const app = require('../../../app');
const findParentNodeById = require('../../../helper').findParentNodeById;
const { checkGroupValid } = require('../rules');
const { resolutions } = require('../../../states');

const MAX_ITEMS = 32;

module.exports = {
    template,
    components: {
        'prepare-area-item': require('./prepare-area-item/prepare-area-item'),
        'byod-protected-mode-item': require('./byod-protected-mode-item/byod-protected-mode-item'),
        draggable
    },

    data: function() {
        return {
            id: 'prepare-area',
            dropBlocked: false,
            previews: new Map()
        };
    },

    computed: {
        draggableOptions: function() {
            return {
                group: {
                    name: 'prepare-items',
                    pull: true,
                    put: function(to, from, dragEl) {
                        // Check if put should be allowed here, e.g. max nr. items reached,
                        // Group is allowed and item is not a live web conference or a Matrix stream
                        const putAllowed = this.items.length <= MAX_ITEMS - 1
                            && checkGroupValid(from.options.group.name)
                            && !(from.options.group.name === 'live-items'
                                && (['webconference', 'zoom', 'teams', 'matrix'].includes(dragEl._underlying_vm_.contentType)
                                    || dragEl._underlying_vm_?.options?.cameraBackActive));

                        this.dropBlocked = !putAllowed;

                        return putAllowed;
                    }.bind(this)
                }
            };
        },
        items: {
            get() {
                return this.$store.getters['controlScreen/getPreparedContent'];
            },
            set(value) {
                this.$store.dispatch('controlScreen/setPreparedContent', value);
            }
        },
        onDropzone: function() {
            return this.getDragToId === this.id && this.getDragging;
        },
        playPreview: function() {
            return !this.isEditModeActive && !this.isOverlayOpen;
        },
        ...Vuex.mapGetters('sources', ['isMirrorProtected']),
        ...Vuex.mapGetters('uiSettings', ['getUiScalingSize']),
        ...Vuex.mapGetters('controlScreen',
            ['getDragging', 'getDragToId', 'isEditModeActive', 'isOverlayOpen', 'getPrepareAppIdsWithPreviews'])
    },

    watch: {
        onDropzone: function(val) {
            if (!val) {
                // Reset drop blocked when dropzone is left
                this.dropBlocked = false;
            }
        },

        /**
         * Clean up previews if apps are not in the prepare list anymore.
         */
        getPrepareAppIdsWithPreviews: function(appIds) {
            this.previews.forEach((val, key, previews) => {
                if (!appIds.includes(key)) {
                    previews.delete(key);
                }
            });
        }
    },

    methods: {
        editApplication: function(data) {
            this.$emit('start-edit-mode', data.appId);
        },

        moveApplication: function(appId, index) {
            this.wolfprot.talk('setShowApplication', {
                appId: appId,
                output: 'prepare',
                prepareIndex: index
            }).then(function() {
                app.emit('main-loop.fast.start', {
                    id: 'prepare-list'
                });
            }.bind(this));
        },

        closeApplication: function(data) {
            this.wolfprot.talk('setControlApplication', {
                appId: data.appId,
                action: 'close'
            }).then(function() {
                app.emit('main-loop.fast.start', {
                    id: 'prepare-list'
                });
            }.bind(this));
        },

        add: function(e) {
            const vm = e.item._underlying_vm_;
            const sortable = Object.keys(e.from).find(k => k.startsWith('Sortable'));
            const dragFromGroupName = e.from[sortable]?.options?.group?.name;

            if (vm.appId) {
                this.moveApplication(vm.appId, e.newDraggableIndex);
            } else if (dragFromGroupName === 'sources' || dragFromGroupName === 'files') {
                // Item was dragged from sources menu
                this.sourcesService.open(vm, {
                    output: 'prepare',
                    prepareIndex: e.newDraggableIndex
                });
            }
        },

        change: function(e) {
            if (e.moved && e.moved.element.appId) {
                this.moveApplication(e.moved.element.appId, e.moved.newIndex);
            }
        },

        checkMove: function() {
        },

        dragStart: function() {
            this.$store.dispatch('controlScreen/setDragging', true);
        },

        dragEnd: function() {
            this.$store.dispatch('controlScreen/setDragging', false);
        },

        /**
         * Handle dropzone detection for native drag & drop.
         */
        onDropzoneEnter: function(evt) {
            if (this.getDragging && !this.$el.contains(evt.relatedTarget)) {
                this.$store.dispatch('controlScreen/setDragToId', this.id);
            }
        },

        onDropzoneLeave: function(evt) {
            if (this.getDragging && !this.$el.contains(evt.relatedTarget)) {
                this.$store.dispatch('controlScreen/setDragToId', evt.relatedTarget.id);
            }
        },

        onDropzoneOver: function(evt) {
            evt.preventDefault();
        },

        /**
         * Handle dropzone detection for drag & drop on touch device.
         */
        onTouchMove: function(evt) {
            if (!this.getDragging) {
                return;
            }

            const x = evt.touches[0].clientX;
            const y = evt.touches[0].clientY;
            const elementAtTouchPoint = document.elementFromPoint(x, y);
            const dropzone = findParentNodeById(elementAtTouchPoint, this.id);

            this.$store.dispatch('controlScreen/setDragToId', dropzone ? this.id : elementAtTouchPoint.id);
        },

        /**
         * Update app previews if not already updating or edit mode / overlay is open.
         */
        updateAppPreviews: function() {
            if (this.playPreview && !this.previewsUpdating) {
                this.previewsUpdating = true;
                this.getAppPreviews().then(() => {
                    this.previewsUpdating = false;
                });
            }
        },

        /**
         * Get previews one after the other. If one fails continue with the others.
         * @returns {Promise<void>}
         */
        getAppPreviews: async function() {
            for (let appId of this.getPrepareAppIdsWithPreviews) {
                await this.getAppPreview(appId).catch(() => null);
            }

            return Promise.resolve();
        },

        /**
         * Get preview image of app.
         * @param appId
         */
        getAppPreview: function(appId) {
            return this.wolfprot.talk('getPictureApplication', {
                appId: appId,
                pictureWidth: this.PREVIEW_RESOLUTION.width,
                pictureHeight: this.PREVIEW_RESOLUTION.height
            }).then(data => {
                const imageUrl = this.createImageUrl(data.picture);
                if (imageUrl) {
                    this.previews.set(appId, imageUrl);
                    this.$forceUpdate();
                }
            });
        },

        /**
         * Try to get a preview image of app without possible exception.
         * @param appId
         */
        tryToGetAppPreview: function(appId) {
            this.getAppPreview(appId).catch(() => null);
        },

        createImageUrl: function(data) {
            let binary = '';

            for (let i = 0; i < data.length; i++) {
                binary += String.fromCharCode(data[i]);
            }

            return `data:image/jpg;base64,${btoa(binary)}`;
        },

        i18n: vueUtils.i18n
    },

    created: function() {
        this.sourcesService = app.getService('SourcesService');
        this.wolfprot = vueUtils.wolfprot();
        this.PREVIEW_RESOLUTION = resolutions['180p']; // Use 180p resolution for the previews in the prepare area

        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return this.wolfprot.talk('getPrepareList');
            }.bind(this)
        });
        this.pollHelper.on('data', function(data) {
            this.items = data.items;
        }.bind(this));
        this.pollHelper.schedulePoll();

        this.updateAppPreviews();

        this.evctx = vueUtils.eventContext();

        this.evctx.on('main-loop.update', function() {
            if (!this.onDropzone) {
                this.pollHelper.schedulePoll();
            }

            this.updateAppPreviews();
        }.bind(this));
        this.evctx.on('main-loop.update.prepare-list', function() {
            if (!this.onDropzone) {
                this.pollHelper.schedulePoll();
            }
        }.bind(this));
    },

    destroy: function() {
        this.evctx.close();
    }
};
