'use strict';

const app = require('../app');
const d3 = require('d3');
const $ = require('jquery');
const _ = require('lodash');
const matrixGridTpl = require('./../components/matrix/configurator/matrix-configurator-grid.hbs');
const platform = require('./../../../modules/platform/platform');
const i18n = require('i18next');
require('../../../vendors/spectrum/spectrum');

const transformHandlesWidth = 120;
const transformHandlesHeight = 120;
const nameHeight = 75;

const data = {
    x: 1000,
    y: 1000,
    rx: 150,
    ry: 150,
    width: 150,
    height: 150,
    angle: 0,
    lastX: 0,
    lastY: 0
};

/**
 * Form types.
 *
 * @type {{rectangle: string, circle: string}}
 */
const form = {
    rectangle: 'rectangle',
    circle: 'circle',
    stream: 'stream'
};

/**
 * Default configuration of a station in the form of a circle.
 *
 * @type {{rx: number, ry: number, angle: number, color: string}}
 */
let configCircle = {
    rx: 350,
    ry: 350,
    angle: 0,
    color: 'rgba(117, 117, 117, 1)'
};

/**
 * Default configuration of a station in the form of a rectangle.
 *
 * @type {{with: number, height: number, angle: number, color: string}}
 */
let configRect = {
    width: 1000,
    height: 640,
    angle: 0,
    color: 'rgba(117, 117, 117, 1)'
};

/**
 * Default configuration of the master.
 *
 * @type {{id: string, cx: number, cy: number, width: number, height: number, angle: number, color: string}}
 */
const configMaster = {
    id: 'master',
    cx: 3840,
    cy: 2060,
    width: 250,
    height: 250,
    angle: 0,
    color: 'rgba(245, 154, 34, 1)'
};

const configHDMI1 = {
    id: 'master-hdmi1',
    cx: 6000,
    cy: 1210,
    width: 1600,
    height: 900,
    angle: 0,
    color: 'rgba(245, 154, 34, 1)',
    outputText: 'matrix_configurator.output_text.1'
};

const configHDMI2 = {
    id: 'master-hdmi2',
    cx: 6000,
    cy: 2910,
    width: 1600,
    height: 900,
    angle: 0,
    color: 'rgba(33, 166, 155, 1)',
    outputText: 'matrix_configurator.output_text.2'
};

/**
 * Matrix configurator service
 */
app.service('MatrixConfiguratorService', function() {
    return {
        initialize: function() {
            this.el = [];
            this.activeElement = null;

            this.dragright = d3.drag()
                .subject(Object)
                .on('drag', this.resize.bind(this, true));

            this.dragbottom = d3.drag()
                .subject(Object)
                .on('drag', this.resize.bind(this, false));

            this.dragrotate = d3.drag()
                .subject(Object)
                .on('drag', this.rotateForm.bind(this))
                .on('end', this.rotateForm.bind(this));

            this.configs = app.getService('MatrixConfigs');
            this.matrixService = app.getService('MatrixService');
            this.matrixConnectionService = app.getService('MatrixConnectionService');
            this.deviceService = app.getService('DeviceService');
            this.isDualProjection = this.deviceService.isCboxProDualProjection();

            this.moveEvent = null;
        },

        setSvg: function(svg) {
            this.$svg = svg;
            this.$stationNameContainer = svg.find('defs .station-name-container');
            this.$stationBtnRemove = svg.find('defs .btn-remove');
            this.$stationBtnMenuMove = svg.find('defs .btn-menu-move');
            this.$stationBtnColor = svg.find('defs .btn-color');
            this.$stationBtnResize = svg.find('defs .btn-resize');
            this.$masterBtn = svg.find('defs .btn-master');
            this.$streamBtn = svg.find('defs .btn-stream');
            this.$controlHandleContainer = svg.find('defs .control-handle-container');
            this.$transformHandleContainer = svg.find('defs .transform-handle-container');

            this.svg = d3.select(this.$svg[0]);
        },

        /**
         * Create station form.
         *
         * @param d Dragged device
         * @param cx {String} x (position center)
         * @param cy {String} y (position center)
         */
        createForm: function(d, cx, cy, stationForm) {
            switch (stationForm) {
                case form.circle:
                    this.createCircle(this.svg, d.id, d.getAttribute('data-name'),
                        cx, cy, configCircle.rx, configCircle.ry, configCircle.angle, configCircle.color,
                        d.getAttribute('data-model'));
                    break;
                case form.rectangle:
                    this.createRect(this.svg, d.id, d.getAttribute('data-name'), cx, cy,
                        configRect.width, configRect.height, configRect.angle, configRect.color,
                        d.getAttribute('data-model'));
                    break;
                case form.stream:
                    this.createStream(this.svg, d.id, d.getAttribute('data-name'), cx, cy,
                        configRect.width, configRect.height, 0, configRect.color,
                        d.getAttribute('data-icon'), d.getAttribute('data-type'));
                    break;
            }
        },

        /**
         * Get the default configuration HTML (string) when creating a template.
         * There is only the Master btn.
         */
        getDefaultConfig: function() {
            const $svgGrid = $(matrixGridTpl({
                width: this.configs.get('dimensions.width'),
                height: this.configs.get('dimensions.height')
            })).find('#svg-grid');

            this.$streamBtn = $svgGrid.find('defs .btn-stream');

            const svgGrid = d3.select($svgGrid[0]);

            if (this.isDualProjection) {
                this.$controlHandleContainer = $svgGrid.find('defs .control-handle-container');
                this.$stationBtnRemove = $svgGrid.find('defs .btn-remove');
                this.$stationBtnMenuMove = $svgGrid.find('defs .btn-menu-move');
                this.$stationBtnColor = $svgGrid.find('defs .btn-color');
                this.$stationBtnResize = $svgGrid.find('defs .btn-resize');
                this.$stationNameContainer = $svgGrid.find('defs .station-name-container');
            } else {
                this.$masterBtn = $svgGrid.find('defs .btn-master');
            }

            this.initMaster(svgGrid);

            return $svgGrid.find('#matrix-layer').get(0).innerHTML;
        },

        initMaster: function(svgGrid) {
            if (this.isDualProjection) {
                this.createMasterDualProjection(svgGrid, configHDMI1);
                this.createMasterDualProjection(svgGrid, configHDMI2);
            } else {
                this.createMaster(svgGrid);
            }
        },

        createMasterDualProjection: function(svgGrid, config) {
            const id = config.id;
            const cx = config.cx;
            const cy = config.cy;
            const width = config.width;
            const height = config.height;
            const angle = config.angle;
            const color = config.color;
            const outputText = config.outputText;

            const svg = svgGrid.selectAll('#stations-layer');
            const group = svg.append('svg:g')
                .attr('id', id)
                .attr('class', 'station master')
                .attr('cx', cx)
                .attr('cy', cy)
                .attr('angle', angle)
                .attr('color', color)
                .attr('name', i18n.t(outputText));

            data.x = cx - width / 2;
            data.y = cy - height / 2;
            data.width = width;
            data.height = height;
            data.angle = angle;
            data.color = color;
            data.lastWidth = data.width;
            data.lastHeight = data.height;

            this.el.push(group.append('svg:rect')
                .attr('class', 'svg-station master'));

            this.activeElement = svg.select('g[id = ' + id + ']');

            this.activeElement.select('.svg-station')
                .data(this.activeElement.select('.svg-station')._groups[0])
                .attr('rx', 20)
                .attr('ry', 20)
                .attr('angle', data.angle)
                .attr('color', data.color)
                .attr('class', 'svg-station')
                .attr('width', data.width)
                .attr('height', data.height)
                .attr('x', data.x)
                .attr('y', data.y)
                .attr('cx', cx)
                .attr('cy', cy)
                .attr('data-menu', 'ctrlLeft')
                .call(d3.drag()
                    .on('start', this.onMoveStart.bind(this, svgGrid))
                    .on('drag', this.onMove.bind(this))
                    .on('end', this.onMoveEnd.bind(this, svgGrid)));

            this.createControlHandles(this.activeElement);
            this.addName();
            this.addMenu(this.activeElement.select('#ctrlLeft'));
            this.removeControlHandles(this.activeElement);
            this.activeElement = null;
        },

        /**
         * Create master station.
         *
         * @param svgGrid {Object} SVG grid context
         */
        createMaster: function(svgGrid) {
            const id = configMaster.id;
            const cx = configMaster.cx;
            const cy = configMaster.cy;
            const width = configMaster.width;
            const height = configMaster.height;
            const angle = configMaster.angle;
            const color = configMaster.color;

            const svg = svgGrid.selectAll('#stations-layer');
            const group = svg.append('svg:g')
                .attr('id', id)
                .attr('class', 'station master')
                .attr('cx', cx)
                .attr('cy', cy)
                .attr('angle', angle)
                .attr('color', color);

            data.x = cx - width / 2;
            data.y = cy - height / 2;
            data.width = width;
            data.height = height;
            data.angle = angle;
            data.color = color;
            data.lastWidth = data.width;
            data.lastHeight = data.height;

            this.el.push(group.append('svg:foreignObject')
                .attr('class', 'btn-master btn-center svg-station'));

            this.activeElement = svg.select('g[id = ' + id + ']');
            this.activeElement.select('.svg-station')
                .data(this.activeElement.select('.svg-station')._groups[0])
                .attr('x', data.x)
                .attr('y', data.y)
                .attr('cx', cx)
                .attr('cy', cy)
                .attr('width', data.width)
                .attr('height', data.height)
                .attr('data-id', id)
                .html(this.$masterBtn.get(0).innerHTML)
                .call(d3.drag()
                    .on('start', this.onMoveStart.bind(this, svgGrid))
                    .on('drag', this.onMove.bind(this))
                    .on('end', this.onMoveEnd.bind(this, svgGrid)));

            this.activeElement.attr('transform', 'rotate(' + this.matrixService.toDegrees(this.activeElement.attr('angle')) * -1 + ',' + cx + ',' + cy + ')');
        },

        /**
         * Create stream
         *
         * @param svgGrid {Object} SVG grid context
         */
        createStream: function(svgGrid, id, name, cx, cy, rx, ry, angle, color, icon, type) {
            const width = configMaster.width;
            const height = configMaster.height;

            const svg = svgGrid.selectAll('#stations-layer');
            const group = svg.append('svg:g')
                .attr('id', id)
                .attr('class', 'station')
                .attr('cx', cx)
                .attr('cy', cy)
                .attr('angle', angle)
                .attr('color', color);

            data.x = cx - width / 2;
            data.y = cy - height / 2;
            data.width = width;
            data.height = height;
            data.angle = angle;
            data.color = color;
            data.lastWidth = data.width;
            data.lastHeight = data.height;

            this.el.push(group.append('svg:foreignObject')
                .attr('class', 'btn-stream btn-center svg-station'));

            this.activeElement = svg.select('g[id = ' + id + ']');
            this.activeElement.select('.svg-station')
                .data(this.activeElement.select('.svg-station')._groups[0])
                .attr('x', data.x)
                .attr('y', data.y)
                .attr('cx', cx)
                .attr('cy', cy)
                .attr('width', data.width)
                .attr('height', data.height)
                .attr('data-id', id)
                .attr('data-type', type)
                .html(this.$streamBtn.get(0).innerHTML)
                .call(d3.drag()
                    .on('start', this.onMoveStart.bind(this, svgGrid))
                    .on('drag', this.onMove.bind(this))
                    .on('end', this.onMoveEnd.bind(this, svgGrid)));

            this.activeElement.select('.icon').attr('class', 'icon ' + icon);
            this.activeElement.attr('transform', 'rotate(' + this.matrixService.toDegrees(this.activeElement.attr('angle')) * -1 + ',' + cx + ',' + cy + ')');
        },

        /**
         * Create station in circle form.
         *
         * @param svgGrid {Object} SVG grid context
         * @param id {Sting} Station ID
         * @param name {String} Station name
         * @param x {number} Postion x
         * @param y {number} Postion y
         * @param rx {number} Radius x
         * @param ry {number} Radius y
         * @param angle {number} Angle
         * @param color {String} Station color
         */
        createCircle: function(svgGrid, id, name, cx, cy, rx, ry, angle, color, model) {
            const svg = svgGrid.selectAll('#stations-layer');
            const group = svg.append('svg:g')
                .attr('id', id)
                .attr('class', 'station')
                .attr('form', form.circle)
                .attr('cx', cx)
                .attr('cy', cy)
                .attr('angle', angle)
                .attr('color', color)
                .attr('name', name)
                .attr('model', model);

            data.x = cx;
            data.y = cy;
            data.rx = rx;
            data.ry = ry;
            data.width = rx;
            data.height = ry;
            data.angle = angle;
            data.color = color;
            data.lastWidth = data.width;
            data.lastHeight = data.height;

            this.el.push(group.append('svg:ellipse')
                .attr('class', 'svg-station'));

            this.activeElement = svg.select('g[id = ' + id + ']');
            this.activeElement.select('.svg-station')
                .data(this.activeElement.select('.svg-station')._groups[0])
                .attr('cx', data.x)
                .attr('cy', data.y)
                .attr('rx', data.rx)
                .attr('ry', data.ry)
                .attr('width', data.rx * 2)
                .attr('height', data.ry * 2)
                .attr('angle', data.angle)
                .attr('color', data.color)
                .attr('class', 'svg-station')
                .call(d3.drag()
                    .on('start', this.onMoveStart.bind(this, svgGrid))
                    .on('drag', this.onMove.bind(this))
                    .on('end', this.onMoveEnd.bind(this, svgGrid)));

            this.activeElement.attr('transform', 'rotate(' + this.matrixService.toDegrees(data.angle) + ',' + data.x + ',' + data.y + ')');
        },

        /**
         * Create station in rectangle form.
         *
         * @param svgGrid {Object} SVG grid context
         * @param id {Sting} Station ID
         * @param name {String} Station name
         * @param cx {number} Postion x
         * @param cy {number} Postion y
         * @param width {number} Station width
         * @param height {number} Station height
         * @param angle {number} Angle
         * @param color {String} Station color
         */
        createRect: function(svgGrid, id, name, cx, cy, width, height, angle, color, model) {
            const svg = svgGrid.selectAll('#stations-layer');
            const group = svg.append('svg:g')
                .attr('id', id)
                .attr('class', 'station')
                .attr('form', form.rectangle)
                .attr('cx', cx)
                .attr('cy', cy)
                .attr('angle', angle)
                .attr('color', color)
                .attr('name', name)
                .attr('model', model);

            data.x = cx - width / 2;
            data.y = cy - height / 2;
            data.width = width;
            data.height = height;
            data.angle = angle;
            data.color = color;
            data.initWidth = data.width;
            data.initHeight = data.height;

            this.el.push(group.append('svg:rect')
                .attr('class', 'svg-station'));

            this.activeElement = svg.select('g[id = ' + id + ']');
            this.activeElement.select('.svg-station')
                .data(this.activeElement.select('.svg-station')._groups[0])
                .attr('rx', 20)
                .attr('ry', 20)
                .attr('angle', data.angle)
                .attr('color', data.color)
                .attr('class', 'svg-station')
                .attr('width', data.width)
                .attr('height', data.height)
                .attr('x', data.x + this.getControlHandlesWidth() / 2)
                .attr('y', data.y + this.getControlHandlesHeight() / 2)
                .call(d3.drag()
                    .on('start', this.onMoveStart.bind(this, svgGrid))
                    .on('drag', this.onMove.bind(this))
                    .on('end', this.onMoveEnd.bind(this, svgGrid)));
        },

        /**
         * Add name to station.
         */
        addName: function() {
            const container = this.$stationNameContainer.clone(true);
            const cx = parseInt(this.activeElement.attr('cx'));
            const cy = parseInt(this.activeElement.attr('cy'));
            const nameWidth = parseInt(this.activeElement.selectAll('.svg-station').attr('width')) - this.getControlHandlesWidth();

            this.removeMenuButtons(this.activeElement, '.station-name-container');
            container.find('.matrix-station-name').text(this.activeElement.attr('name'));

            const name = this.activeElement.append('svg:foreignObject')
                .attr('class', 'station-name-container')
                .attr('x', cx - nameWidth / 2)
                .attr('y', cy - nameHeight / 2)
                .attr('cx', cx)
                .attr('cy', cy)
                .attr('width', nameWidth)
                .attr('height', nameHeight)
                .attr('transform', 'rotate(' + this.matrixService.toDegrees(this.activeElement.attr('angle'), true) * -1 + ',' + cx + ',' + cy + ')')
                .html(container.get(0).innerHTML);

            this.activeElement.select('.station-name-container')
                .data(name._groups[0])
                .call(d3.drag()
                    .on('start', this.onMoveStart.bind(this, this.svg))
                    .on('drag', this.onMove.bind(this))
                    .on('end', this.onMoveEnd.bind(this, this.svg)));
        },

        /**
         * Add menu to station.
         *
         * @param nearest Nearest element (control handle)
         */
        addMenu: function(nearest) {
            this.removeMenuButtons(this.activeElement, '.station-button-container');

            if (!nearest) {
                nearest = this.getNearestControlHandle();
            }

            const id = this.activeElement.attr('id');
            const isReceiver = this.activeElement.attr('model') === 'CPR';

            const stationBtnWidth = this.getStationBtnWidth();
            const stationBtnHeight = this.getStationBtnHeight();
            const btnRemoveContainer = this.$stationBtnRemove.clone(true);
            const btnMenuMoveContainer = this.$stationBtnMenuMove.clone(true);
            const btnColorContainer = this.$stationBtnColor.clone(true);
            const btnResizeContainer = this.$stationBtnResize.clone(true);
            const stationCx = parseInt(this.activeElement.attr('cx'));
            const stationCy = parseInt(this.activeElement.attr('cy'));
            const centerCx = nearest.attr('cx');
            const centerCy = nearest.attr('cy');
            const btnColorCx = nearest.attr('cx0');
            const btnColorCy = nearest.attr('cy0');
            const btnResizeCx = nearest.attr('cx1');
            const btnResizeCy = nearest.attr('cy1');
            const btnRemoveCx = stationCx;
            const btnRemoveCy = stationCy + nameHeight + this.configs.get('station.btnHeight') / 4;

            if (nearest) {
                nearest.classed('is-nearest', false);
            }

            const isMaster = this.activeElement.attr('id').startsWith('master');
            if (!isMaster) {
                const btnRemove = this.activeElement.append('svg:foreignObject')
                    .attr('class', 'btn-remove station-button-container')
                    .attr('x', btnRemoveCx - this.configs.get('station.btnWidth') / 2)
                    .attr('y', btnRemoveCy - this.configs.get('station.btnHeight') / 4)
                    .attr('cx', btnRemoveCx)
                    .attr('cy', btnRemoveCy)
                    .attr('width', this.configs.get('station.btnWidth'))
                    .attr('height', this.configs.get('station.btnHeight') / 2)
                    .attr('data-id', id)
                    .html(btnRemoveContainer.get(0).innerHTML)
                    .attr('transform', 'rotate(' + this.matrixService.toDegrees(this.activeElement.attr('angle'), true) * -1 + ','
                    + stationCx + ',' + stationCy + ')');

                const btnColor = this.activeElement.append('svg:foreignObject')
                    .attr('class', 'btn-color btn-control station-button-container')
                    .attr('x', btnColorCx - stationBtnWidth / 2)
                    .attr('y', btnColorCy - stationBtnHeight / 2)
                    .attr('cx', btnColorCx)
                    .attr('cy', btnColorCy)
                    .attr('width', stationBtnWidth)
                    .attr('height', stationBtnHeight)
                    .attr('data-id', id)
                    .html(btnColorContainer.get(0).innerHTML)
                    .attr('transform', 'rotate(' + this.matrixService.toDegrees(this.activeElement.attr('angle'), true) * -1 + ','
                        + btnColorCx + ',' + btnColorCy + ')');

                this.el.push(btnColor);
                this.el.push(btnRemove);
            }

            const btnMenuMove = this.activeElement.append('svg:foreignObject')
                .attr('class', 'btn-center station-button-container')
                .attr('x', centerCx - stationBtnWidth / 2)
                .attr('y', centerCy - stationBtnHeight / 2)
                .attr('cx', centerCx)
                .attr('cy', centerCy)
                .attr('width', stationBtnWidth)
                .attr('height', stationBtnHeight)
                .attr('data-id', id)
                .html(btnMenuMoveContainer.get(0).innerHTML)
                .attr('transform', 'rotate(' + this.matrixService.toDegrees(this.activeElement.attr('angle'), true) * -1 + ','
                    + centerCx + ',' + centerCy + ')');

            if (isMaster) {
                btnMenuMove.attr('data-drop-action', 'add');
            }

            const btnResize = this.activeElement.append('svg:foreignObject')
                .attr('class', 'btn-resize btn-docs station-button-container')
                .attr('x', btnResizeCx - stationBtnWidth / 2)
                .attr('y', btnResizeCy - stationBtnHeight / 2)
                .attr('cx', btnResizeCx)
                .attr('cy', btnResizeCy)
                .attr('width', stationBtnWidth)
                .attr('height', stationBtnHeight)
                .attr('data-id', id)
                .html(btnResizeContainer.get(0).innerHTML)
                .attr('transform', 'rotate(' + this.matrixService.toDegrees(this.activeElement.attr('angle'), true) * -1 + ','
                    + btnResizeCx + ',' + btnResizeCy + ')');

            this.bindMenuEvents(this.activeElement);

            this.el.push(btnMenuMove);
            this.el.push(btnResize);

            // Do not show color button if it's a Pure Receiver
            if (isReceiver) {
                this.activeElement.select('.btn-color').attr('display', 'none');
            }

            this.setStationColor(this.activeElement.attr('color'));
        },

        /**
         * Bind events to menu buttons (move, remove, color)
         *
         * @param station {Object} Station container
         */
        bindMenuEvents: function(station) {
            const menuData = station.selectAll('.matrix-circle[data-action="connect"]')._groups[0];
            const color = station._groups[0][0].getAttribute('color');

            // Resize
            station.selectAll('.matrix-circle[data-action="resizeRotate"]')
                .on('touchstart', function() {
                    this.createResizeRotationHandles();
                    // RELEASE-1993 - Red pointer appears when sliding a finger on the screen.
                    app.emit('custom-cursor.disable');
                }.bind(this))
                .on('mousedown', function() {
                    this.createResizeRotationHandles();
                }.bind(this))
                .on('touchend', function() {
                    // RELEASE-1993 - Red pointer appears when sliding a finger on the screen.
                    app.emit('custom-cursor.enable');
                }.bind(this));

            // Color
            _.each(station.selectAll('.matrix-circle[data-action="color"]')._groups[0], function(element) {
                const container = element.parentElement;

                this.bindColorPicker(container, container.getAttribute('data-id'), color);
            }.bind(this));

            // Remove
            station.selectAll('.matrix-circle[data-action="remove"]')
                .on('touchstart', function() {
                    // RELEASE-1993 - Red pointer appears when sliding a finger on the screen.
                    app.emit('custom-cursor.enable');
                }.bind(this))
                .on('touchmove', function() {
                    this.cancelRemove = true;
                }.bind(this))
                .on('touchend click', this.openSubmenu.bind(this));

            // Move menu
            station.selectAll('.matrix-circle.menu-btn[data-action="connect"]')
                .data(menuData)
                .call(d3.drag()
                    .on('start', this.onMoveMenuStart.bind(this))
                    .on('drag', this.onMoveMenu.bind(this))
                    .on('end', this.onMoveMenuEnd.bind(this)));
        },

        /**
         * Bind color picker to stations color button.
         *
         * @param container parent container
         * @param id Station ID
         * @param color Station color
         */
        bindColorPicker: function(container, id, color) {
            const colorPicker = $(container).find('.js-color-picker');

            if (!colorPicker) {
                return;
            }

            $(container).find('.sp-replacer').remove();
            colorPicker.spectrum('destroy');
            colorPicker.spectrum({
                color: color,
                showPalette: true,
                showSelectionPalette: false,
                palette: this.configs.get('colorpicker.palette'),
                move: function(id, color) {
                    this.selectStation(id);
                    this.setStationColor('rgba(' + Math.round(color._r) + ',' + Math.round(color._g) + ','
                        + Math.round(color._b) + ', 1)');
                }.bind(this, id),
                show: function(id, container) {
                    this.selectStation(id);
                    this.setColorPickerPosition(container);
                }.bind(this, id, container),
                beforeShow: function() {
                    // Multitouch workaround.
                    if (this.moveEvent) {
                        return false;
                    }
                }.bind(this)
            });

            colorPicker.parent().css('margin-top', (-1 * this.getStationBtnHeight()) + 'px');
        },

        /**
         * Set position of color picker spectrum.
         */
        setColorPickerPosition: function(container) {
            const $el = $(container);
            const $colorPicker = $(document).find('.sp-container').not('.sp-hidden');
            const $window = $(window);
            let top = $el.offset().top + 55;
            let left = $el.offset().left;

            if (platform.checks.isEdge || platform.checks.isIe) {
                top = $el.parent().offset().top + 198;
                left = $el.parent().offset().left;
            }

            if (top + $colorPicker.height() > $window.height()) {
                top = top - 55 - $colorPicker.height();
            }

            if (left + $colorPicker.width() > $window.width()) {
                left = left + 55 - $colorPicker.width();
            }

            // Set the new position for the color picker.
            $colorPicker
                .css('left', left + 'px')
                .css('top', top + 'px')
                .css('z-index', 10);
        },

        /**
         * Open station submenu.
         */
        openSubmenu: function() {
            const id = d3.event.currentTarget.parentElement.getAttribute('data-id');
            const $container = $(document).find('#matrix-station-menu');
            const station = this.svg.select('#stations-layer').select('#' + id)._groups[0][0];
            const audio = parseInt(station.getAttribute('audio'));
            const groupId = parseInt(station.getAttribute('group'));

            let pos = {
                x: d3.event.currentTarget.getBoundingClientRect().left,
                y: d3.event.currentTarget.getBoundingClientRect().top - 50
            };

            this.matrixService.getStationSetup().then(function() {
                let itemId = 0;
                const groups = this.configs.getCurrentMatrixTemplateGroups();

                const items = [
                    {
                        id: itemId++,
                        icon: 'icon-v2-speaker-up',
                        name: i18n.t('matrix_configurator.enable_audio'),
                        serial: id,
                        checked: audio === 1,
                        callback: this.toggleStationAudio.bind(this),
                        itemType: 'toggle',
                        classed: groups.length <= 0 ? 'no-border' : ''
                    }
                ];

                if (groups.length > 0) {
                    items.push(
                        {
                            id: itemId++,
                            name: i18n.t('matrix_configurator.assign_to_group'),
                            header: true
                        });
                }

                _.each(groups, function(group, i) {
                    items.push({
                        id: itemId++,
                        name: group.name,
                        groupId: group.id,
                        serial: id,
                        checked: groupId === group.id,
                        callback: this.addStationToGroup.bind(this),
                        itemType: 'singleChoice',
                        itemGroup: 'groups',
                        classed: i === (groups.length - 1) ? 'last-group-item no-border' : ''
                    });
                }.bind(this));

                this.$svg.find('.js-color-picker').spectrum('hide');

                app.removeComponent('stationSubmenu');

                app.createComponent('stationSubmenu', {
                    type: 'MatrixBox',
                    container: $container,
                    isConfigurator: true,
                    matrixBox: 'station',
                    items: items,
                    onClose: function() {
                        this.$svg.find('.btn-trash-bin').css('display', 'none');
                    }.bind(this)
                });

                pos = this.getSubmenuPosition(pos.x, pos.y, $container.find('.matrix-box'));

                app.emit('matrix-box.open.station',
                    false,
                    pos.x,
                    pos.y
                );
            }.bind(this));

            this.$svg.find('.btn-trash-bin').css('display', 'block');
        },

        /**
         * Check submenu position and calculate if it's visible or cut off.
         *
         * @param x x-coordinate of top left corner
         * @param y y-coordinate of top left corner
         * @param $el submenu
         *
         * @returns {{x: number, y: number}} new position
         */
        getSubmenuPosition: function(x, y, $el) {
            const width = $(document).find('.svg-grid').width();
            const height = $(document).find('.svg-grid').height();

            if (y + $el.height() > height) {
                if (y - $el.height() >= 0) {
                    y = y + 27 - $el.height();
                } else if ((height - y) > y) {
                    $el.css('height', (height - y));
                    $el.find('.matrix-box-list').css('height', (height - y));
                } else {
                    $el.css('height', y);
                    $el.find('.matrix-box-list').css('height', y);
                    y = 0;
                }
            }

            if (x + $el.width() > width) {
                x = x + 55 - $el.width();
            }

            return {
                x: x,
                y: y
            };
        },

        /**
         * Remove station from Matrix grid.
         *
         * @param item Selected item from list
         */
        removeStation: function(item) {
            // RELEASE-1993 - Red pointer appears when sliding a finger on the screen.
            app.emit('custom-cursor.enable');

            // Multitouch workaround
            if (this.moveEvent) {
                return;
            }

            const stationId = !item.getAttribute('id') ? d3.event.currentTarget.parentElement.getAttribute('data-id') : item.getAttribute('id');

            if (this.cancelRemove) {
                this.cancelRemove = false;

                return;
            }

            this.$svg.find('#' + stationId).find('.js-color-picker').spectrum('destroy');
            this.svg.selectAll('[id|=' + stationId + ']')
                .on('touchstart mousedown', null)
                .remove();

            this.activeElement = null;
        },

        /**
         * Update Audio attribute in station configuration.
         *
         * @param item Selected item from list
         */
        toggleStationAudio: function(item) {
            const station = this.svg.select('#stations-layer').select('#' + item.serial)._groups[0][0];
            const audio = station.getAttribute('audio');

            if (!audio) {
                station.setAttribute('audio', 1);
            } else if (parseInt(audio) === 1) {
                station.setAttribute('audio', 0);
            } else {
                station.setAttribute('audio', 1);
            }
        },

        /**
         * Add a station to a group.
         *
         * @param item Selected item from list
         */
        addStationToGroup: function(item) {
            const station = this.svg.select('#stations-layer').select('#' + item.serial)._groups[0][0];
            const groupId = parseInt(station.getAttribute('group'));

            if (groupId === item.groupId) { // Remove group
                station.setAttribute('group', '');
            } else { // Add group
                station.setAttribute('group', item.groupId);
            }
        },

        /**
         * Start dragging device from device discovery box.
         * Create station form.
         *
         * @param d {Object} device element
         * @param form {String} selected form
         */
        onDragDeviceStart: function(d, form) {
            // Multitouch workaround
            if (this.moveEvent) {
                return;
            }

            this.matrixConnectionService.hideConnectionHandles(this.svg);

            const pos = this.matrixService.convertCoordinates(
                {
                    x: d.getAttribute('cx'),
                    y: d.getAttribute('cy')
                },
                parseInt(this.configs.get('dimensions.width')),
                parseInt(this.configs.get('dimensions.height')),
                this.$svg.width(),
                this.$svg.height()
            );

            this.createForm(d, pos.x, pos.y, d.attributes.id.value.startsWith('stream') ? 'stream' : form);
            this.onMoveStart(this.svg, this.svg.select('#stations-layer').select('#' + d.id)._groups[0][0]);
        },

        /**
         * Drag device from device discovery box.
         */
        onDragDevice: function(d) {
            // Multitouch workaround
            if (this.moveEvent !== this.svg.select('#stations-layer').select('#' + d.id)._groups[0][0]) {
                return;
            }

            d3.event.x = d3.mouse(this.svg._groups[0][0])[0];
            d3.event.y = d3.mouse(this.svg._groups[0][0])[1];

            this.onMove(this.svg.select('#stations-layer').select('#' + d.id)._groups[0][0]);
        },

        /**
         * Drop device from device discovery box.
         *
         * @param d {Object} device element
         */
        onDragDeviceEnd: function(d) {
            // Multitouch workaround
            if (this.moveEvent !== this.svg.select('#stations-layer').select('#' + d.id)._groups[0][0]) {
                return;
            }

            const station = this.svg.select('#stations-layer').select('#' + d.id).select('.svg-station')._groups[0][0];

            this.onMoveEnd(this.svg, this.svg.select('#stations-layer').select('#' + d.id)._groups[0][0]);

            if (!d.attributes.id.value.startsWith('stream')) {
                const nearest = this.getNearestControlHandle();

                station.setAttribute('data-menu', nearest.attr('id'));

                this.matrixConnectionService.createConnection(this.svg, d.id, this.isDualProjection ? ['master-hdmi1', 'master-hdmi2'] : ['master']);
            }
        },

        /**
         * Start moving a menu.
         *
         * @param d {Object} device menu element
         */
        onMoveMenuStart: function(d) {
            // RELEASE-1993 - Red pointer appears when sliding a finger on the screen.
            app.emit('custom-cursor.disable');

            // Multitouch workaround
            if (this.moveEvent) {
                return;
            }

            this.moveEvent = d;

            const id = d.parentElement.getAttribute('data-id');

            this.selectStation(id);
            this.createControlHandles();

            this.removeMenuButtons(this.activeElement, '.btn-color');
            this.removeMenuButtons(this.activeElement, '.btn-resize');

            this.svg.selectAll('.controlHandle').attr('display', 'block');

            if (!platform.checks.isIOS13) {
                this.activeElement.select('.btn-center').raise();
            }

            this.matrixConnectionService.moveStart(this.svg, id);
        },

        /**
         * Move menu.
         *
         * @param d {Object} device menu element
         */
        onMoveMenu: function(d) {
            // Multitouch workaround
            if (this.moveEvent !== d) {
                return;
            }

            d = d.parentElement;

            // Remove transform rotate attribute to get the correct coordinates
            d.attributes.transform.value = 'rotate(' + (this.matrixService.toDegrees(this.activeElement.attr('angle'), true) * -1)
                + ', ' + this.activeElement.attr('cx') + ', ' + this.activeElement.attr('cy') + ')';

            d3.selectAll(d)
                .attr('x', (d.attributes.x.value = d3.mouse(this.svg._groups[0][0])[0] - (this.getStationBtnWidth() / 2)))
                .attr('y', (d.attributes.y.value = d3.mouse(this.svg._groups[0][0])[1] - (this.getStationBtnHeight() / 2)))
                .attr('cx', d.attributes.cx.value = d3.mouse(this.svg._groups[0][0])[0])
                .attr('cy', d.attributes.cy.value = d3.mouse(this.svg._groups[0][0])[1]);

            this.highlightNearestEl(this.svg.select('.btn-center[data-id=' + d.getAttribute('data-id') + ']'), null, null, '.controlHandle');
        },

        /**
         * End moving menu.
         *
         * @param d {Object} device menu element
         */
        onMoveMenuEnd: function(d) {
            // RELEASE-1993 - Red pointer appears when sliding a finger on the screen.
            app.emit('custom-cursor.enable');

            // Multitouch workaround.
            if (this.moveEvent !== d) {
                return;
            }

            if (d.parentElement.getAttribute('data-id').startsWith('stream')) {
                return;
            }

            const nearest = this.getNearestControlHandle(this.svg.selectAll('.btn-center[data-id=' + d.parentElement.getAttribute('data-id') + ']'));

            this.activeElement.select('.svg-station')._groups[0][0].setAttribute('data-menu', nearest.attr('id'));
            this.removeMenuButtons(this.activeElement, '.station-button-container');

            this.addName();
            this.addMenu(nearest);

            this.matrixConnectionService.moveEnd(this.svg, d.parentElement.getAttribute('data-id'), this.activeElement.select('.btn-center')._groups[0]);

            this.svg.selectAll('.controlHandle').attr('display', 'none');

            this.moveEvent = null;
        },

        /**
         * Remove menu buttons from el.
         * Also remove color picker if color buttons is removed.
         *
         * @param el Active element.
         * @param id ID of menu button.
         */
        removeMenuButtons: function(el, id) {
            if (id === '.btn-color' || id === '.station-button-container') {
                const colorPicker = $(el._groups[0]).find('.js-color-picker');
                $(colorPicker).find('.sp-replacer').remove();
                colorPicker.spectrum('destroy');
            }

            el.selectAll(id).remove();
        },

        /**
         * Start moving a station.
         *
         * @param svgGrid {Object} SVG grid context
         * @param d {Object} Dragged element
         */
        onMoveStart: function(svgGrid, d) {
            // RELEASE-1993 - Red pointer appears when sliding a finger on the screen.
            app.emit('custom-cursor.disable');

            // Multitouch workaround.
            if (this.moveEvent) {
                return;
            }

            this.moveEvent = d;

            if (d.tagName !== 'g') { // Get group element
                d = d.parentElement;
            }

            this.deselectStation(d3.select(d));
            this.activeElement = d3.select(d);

            if (!this.activeElement.attr('id').startsWith('master')) {
                this.$svg.find('.btn-trash-bin').css('display', 'block');
            }

            this.removeMenuButtons(this.activeElement, '.btn-color');
            this.removeMenuButtons(this.activeElement, '.btn-resize');
            this.removeMenuButtons(this.activeElement, '.btn-remove');
            this.activeElement.select('.station-name-container')
                .attr('display', 'none');

            // FIXME remove rotation and drag element without rotation. Is set in onMoveEnd - try dagging the rotated element.
            this.activeElement.attr('transform', 'rotate(0)');
            this.activeElement.select('.btn-center').attr('transform', 'rotate(0)');

            if (!platform.checks.isIOS13) {
                this.activeElement.raise().classed('active', true);
            }

            this.matrixConnectionService.moveStart(svgGrid, this.activeElement.attr('id'));
        },

        /**
         * Move station.
         */
        onMove: function(d) {
            // Multitouch workaround.
            if (this.moveEvent !== d) {
                return;
            }

            d = this.activeElement._groups[0][0];
            const menu = this.activeElement.select('.btn-center');
            let distX;
            let distY;

            if (menu._groups[0][0]) {
                distX = this.activeElement.attr('cx') - menu.attr('cx');
                distY = this.activeElement.attr('cy') - menu.attr('cy');
            }

            _.each(d.children, function(childEl) {
                const data = d3.select(childEl)._groups[0];

                d3.select(childEl)
                    .data(data)
                    .attr('x', function(el) {
                        let x = d3.event.x;

                        if (el.tagName === 'ellipse') {
                            return (d.attributes.cx.value = x);
                        }

                        if ($(el).hasClass('btn-center')) {
                            x -= distX;
                        }

                        return (d.attributes.cx.value = x) - el.attributes.width.value / 2;
                    })
                    .attr('y', function(el) {
                        let y = d3.event.y;

                        if (el.tagName === 'ellipse') {
                            return (d.attributes.cy.value = y);
                        }

                        if ($(el).hasClass('btn-center')) {
                            y -= distY;
                        }

                        return (d.attributes.cy.value = y) - el.attributes.height.value / 2;
                    })
                    .attr('cx', function(el) {
                        let cx = d3.event.x;

                        if ($(el).hasClass('btn-center')) {
                            cx -= distX;
                        }

                        return (d.attributes.cx.value = cx);
                    })
                    .attr('cy', function(el) {
                        let cy = d3.event.y;

                        if ($(el).hasClass('btn-center')) {
                            cy -= distY;
                        }

                        return (d.attributes.cy.value = cy);
                    });
            }.bind(this));

            this.activeElement
                .attr('cx', d3.mouse(this.svg._groups[0][0])[0])
                .attr('cy', d3.mouse(this.svg._groups[0][0])[1]);

            this.highlightNearestEl(
                this.activeElement,
                this.configs.get('connectionpoints.distanceX'),
                this.configs.get('connectionpoints.distanceY'),
                '.connection-point'
            );

            this.highlightTrashBin(this.activeElement);

            // FIXME remove rotation and drag element without rotation. Is set in onMoveEnd - try dagging the rotated element.
            // D3.select(d).attr('transform', 'rotate(' + this.matrixService.toDegrees(d.attributes.angle.value) + ',' +
            //     D.attributes.cx.value + ',' + d.attributes.cy.value + ')');
            // Menu.attr('transform', function(el) {
            //     Return  'rotate(' + this.matrixService.toDegrees(d.attributes.angle.value) * -1 + ',' +
            //         El.attributes.cx.value + ',' + el.attributes.cy.value + ')';
            // }.bind(this));

            if (menu._groups[0][0]) {
                this.matrixConnectionService.move(this.svg, d.id, menu._groups[0]);
            }
        },

        /**
         * End moving station.
         *
         * @param svgGrid {Object} SVG grid context
         */
        onMoveEnd: function(svgGrid, d) {
            // RELEASE-1993 - Red pointer appears when sliding a finger on the screen.
            app.emit('custom-cursor.enable');

            // Multitouch workaround.
            if (this.moveEvent !== d) {
                return;
            }

            const trashBin = this.$svg.find('.btn-trash-bin');
            const trashBinCx = parseInt(trashBin.attr('x')) + (trashBin.attr('width') / 2);
            const trashBinCy = parseInt(trashBin.attr('y')) + (trashBin.attr('height') / 2);

            // Move to trash
            if (this.matrixService.pointInCircle(
                d3.event.x,
                d3.event.y,
                trashBinCx,
                trashBinCy,
                trashBin.attr('width') / 2,
                trashBin.attr('height') / 2
            ) && !this.activeElement._groups[0][0].getAttribute('id').startsWith('master')) {
                this.moveEvent = null;
                this.removeStation(this.activeElement._groups[0][0]);

                if (this.nearestEl) {
                    this.nearestEl.classed('is-nearest', false);
                }

                trashBin.removeClass('highlighted');
                trashBin.css('display', 'none');

                return;
            }

            d = this.activeElement._groups[0][0];
            const station = this.activeElement.select('.svg-station');
            const groupElements = d3.selectAll(d.children)._groups[0];

            const nearest = this.getNearestElement(
                this.activeElement,
                this.configs.get('connectionpoints.distanceX'),
                this.configs.get('connectionpoints.distanceY'),
                '.connection-point'
            );

            this.activeElement.classed('active', false);
            d3.selectAll(d.children)
                .data(groupElements)
                .attr('x', function(el) {
                    if (el.tagName === 'ellipse') {
                        return;
                    }

                    return (d.attributes.cx.value = nearest.attr('cx')) - el.attributes.width.value / 2;
                })
                .attr('y', function(el) {
                    if (el.tagName === 'ellipse') {
                        return;
                    }

                    return (d.attributes.cy.value = nearest.attr('cy')) - el.attributes.height.value / 2;
                })
                .attr('cx', d.attributes.cx.value = nearest.attr('cx'))
                .attr('cy', d.attributes.cy.value = nearest.attr('cy'));

            if (this.nearestEl) {
                this.nearestEl.classed('is-nearest', false);
            }

            this.selectStation(d.id);
            this.createControlHandles();

            d3.select(d).attr('transform', 'rotate(' + this.matrixService.toDegrees(d.attributes.angle.value, true) + ',' + d.attributes.cx.value + ',' + d.attributes.cy.value + ')');

            if (d.id !== 'master' && !d.id.startsWith('stream')) {
                const handleName = station.attr('data-menu');
                let menuHandle;

                if (handleName) {
                    menuHandle = this.svg.select('#' + handleName);
                }

                this.addName();
                this.addMenu(menuHandle);
            }

            this.matrixConnectionService.moveEnd(svgGrid, d.id, this.activeElement.select('.btn-center')._groups[0]);

            this.moveEvent = null;
            this.$svg.find('.btn-trash-bin').css('display', 'none');
        },

        /**
         * Return angle between points p1 & p2.
         *
         * @param p1 {number} Point 1
         * @param p2 {number} Point 2
         * @returns {number} Angle between p1 & p2
         */
        angleBetweenPoints: function(p1, p2) {
            if (p1[0] === p2[0] && p1[1] === p2[1]) {
                return Math.PI / 2;
            } else {
                return Math.atan2(p2[1] - p1[1], p2[0] - p1[0]);
            }
        },

        /**
         * Create element bindings.
         *
         * @param svgGrid {Object} SVG grid context
         */
        createBindings: function(svgGrid) {
            _.each(svgGrid.selectAll('.station')._groups[0], function(stationNode) {
                const station = d3.select(stationNode);
                const stationForm = station.selectAll('.svg-station');
                const name = station.selectAll('.station-name-container');

                stationForm
                    .data(stationForm._groups[0])
                    .call(d3.drag()
                        .on('start', this.onMoveStart.bind(this, svgGrid))
                        .on('drag', this.onMove.bind(this))
                        .on('end', this.onMoveEnd.bind(this, svgGrid)));

                name
                    .data(name._groups[0])
                    .call(d3.drag()
                        .on('start', this.onMoveStart.bind(this, svgGrid))
                        .on('drag', this.onMove.bind(this))
                        .on('end', this.onMoveEnd.bind(this, svgGrid)));

                this.bindMenuEvents(station);
            }.bind(this));
        },

        /**
         * Select station and bind events.
         *
         * @param stationId {String} Station ID
         */
        selectStation: function(stationId) {
            const id = stationId ? stationId : d3.event.currentTarget.parentElement.getAttribute('data-id');
            const group = this.svg.selectAll('#stations-layer').selectAll('#' + id);
            const station = group.selectAll('.svg-station')._groups[0][0];

            if (!station) {
                return;
            }

            const isEllipse = station.tagName === 'ellipse';

            this.deselectStation(group._groups[0][0]);
            this.matrixConnectionService.hideConnectionHandles(this.svg);
            this.activeElement = group;

            if (isEllipse) {
                data.x = parseInt(station.getAttribute('cx'));
                data.y = parseInt(station.getAttribute('cy'));
                data.width = parseInt(station.getAttribute('rx'));
                data.height = parseInt(station.getAttribute('ry'));
            } else {
                data.x = parseInt(station.getAttribute('x'));
                data.y = parseInt(station.getAttribute('y'));
                data.width = parseInt(station.getAttribute('width'));
                data.height = parseInt(station.getAttribute('height'));
            }
            this.removeResizeRotationHandles();

            data.initWidth = data.width;
            data.initHeight = data.height;

            if (!id.startsWith('master') && !id.startsWith('stream')) {
                this.matrixConnectionService.showConnectionHandles(this.svg, id);
            }
        },

        /**
         * Deselect current active station.
         * Show/hide specific controls.
         */
        deselectStation: function(newElement) {
            if (this.activeElement && newElement && _.isEqual(this.activeElement._groups[0][0], newElement)) {
                return;
            }
            const el = this.activeElement ? this.activeElement : this.svg;
            const conn = this.svg.select('#connections-layer').select('#' + el.attr('id'));

            if (el.attr('id') && el.attr('id') !== 'master' && !el.attr('id').startsWith('stream') && el.attr('id') !== 'svg-grid') {
                const handleName = el.select('.svg-station').attr('data-menu');
                let menuHandle;

                this.createControlHandles(el);

                if (handleName) {
                    menuHandle = this.svg.select('#' + handleName);
                }

                this.addName();
                this.addMenu(menuHandle);

                // Update connection
                this.matrixConnectionService.moveStart(this.svg, el.attr('id'));
                this.matrixConnectionService.moveEnd(this.svg, el.attr('id'), el.select('.btn-center')._groups[0]);
            }

            this.removeResizeRotationHandles(el.attr('id'));
            this.removeControlHandles(el);
            this.matrixConnectionService.hideConnectionHandles(conn._groups[0][0] ? conn : this.svg);
            $(el).find('.js-color-picker').spectrum('hide');

            this.activeElement = null;
        },

        /**
         * Deselect station.
         *
         * @param event Touch event
         */
        selectionHandler: function(targetEl) {
            // Multitouch workaround.
            if (this.moveEvent) {
                return;
            }

            if (!this.activeElement || _.isEqual(this.activeElement._groups[0][0], targetEl)) {
                return;
            }

            const group = this.getGroupElement(targetEl);

            if (group && _.isEqual(this.activeElement._groups[0][0], group)) {
                return;
            }

            this.deselectStation();
        },

        getGroupElement: function(el) {
            if (!el || el.tagName === 'g') { // Get group element
                return el;
            }

            return this.getGroupElement(el.parentElement);
        },

        /**
         * Create Resize and Rotation handles.
         *
         * @param stationId {String} Station ID
         */
        createResizeRotationHandles: function(stationId) {
            // Multitouch workaround.
            if (this.moveEvent) {
                return;
            }

            const id = stationId ? stationId : d3.event.currentTarget.parentElement.getAttribute('data-id');
            const group = this.svg.selectAll('#stations-layer').selectAll('#' + id);
            const station = group.selectAll('.svg-station')._groups[0][0];

            if (!station) {
                return;
            }

            const isEllipse = station.tagName === 'ellipse';

            this.deselectStation(group._groups[0][0]);
            this.removeResizeRotationHandles();
            this.removeControlHandles();
            this.activeElement = group;

            // Set opacity to take up space to avoid start/drag/end events of svg-station.
            this.activeElement.selectAll('.station-button-container').style('opacity', '0');

            if (isEllipse) {
                data.x = parseInt(station.getAttribute('cx'));
                data.y = parseInt(station.getAttribute('cy'));
                data.width = parseInt(station.getAttribute('rx'));
                data.height = parseInt(station.getAttribute('ry'));
            } else {
                data.x = parseInt(station.getAttribute('x'));
                data.y = parseInt(station.getAttribute('y'));
                data.width = parseInt(station.getAttribute('width'));
                data.height = parseInt(station.getAttribute('height'));
            }

            this.draghandleright = this.addTransformHandle(
                'dragright',
                {
                    x: data.x + data.width - (transformHandlesWidth / 2),
                    y: isEllipse ? data.y - (transformHandlesHeight / 2) : data.y + (data.height / 2) - (transformHandlesHeight / 2)
                },
                this.dragright
            );
            this.draghandlebottom = this.addTransformHandle(
                'dragbottom',
                {
                    x: isEllipse ? data.x - (transformHandlesWidth / 2) : data.x + (data.width / 2) - (transformHandlesWidth / 2),
                    y: data.y + data.height - (transformHandlesHeight / 2)
                },
                this.dragbottom
            );
            if (!id.startsWith('master')) {
                this.dragrotationhandle = this.addTransformHandle(
                    'dragrotate',
                    {
                        x: isEllipse ? data.x - (transformHandlesWidth / 2) : data.x + (data.width / 2) - (transformHandlesWidth / 2),
                        y: data.y + data.height + (transformHandlesHeight / 2)
                    },
                    this.dragrotate
                );
            }

            this.matrixConnectionService.hideConnections(this.svg, id);
        },

        addTransformHandle: function(id, pos, callMethod) {
            const container = this.$transformHandleContainer.clone(true);

            const handle = this.activeElement
                .append('svg:foreignObject')
                .attr('class', 'transform-handle-container')
                .attr('x', pos.x)
                .attr('y', pos.y)
                .attr('id', id)
                .attr('height', transformHandlesHeight)
                .attr('width', transformHandlesWidth)
                .attr('transform', 'rotate(0)')
                .html(container.get(0).innerHTML)
                .call(callMethod);

            return handle;
        },

        /**
         *
         * Remove, resize and rotation handles.
         *
         * @param el Station/Element to remove handles from.
         */
        removeResizeRotationHandles: function(stationId) {
            const element = stationId ? this.svg.selectAll('#stations-layer').selectAll('#' + stationId) : this.svg;

            element.selectAll('#dragbottom').remove();
            element.selectAll('#dragright').remove();
            element.selectAll('#dragrotate').remove();

            this.matrixConnectionService.showConnections(this.svg, stationId);
        },

        /**
         * Remove menu control handles.
         *
         * @param el Station/Element to remove handles from.
         */
        removeControlHandles: function(el) {
            const element = el ?? this.svg;

            element.selectAll('.controlHandle').remove();
        },

        /**
         * Create menu control handles.
         *
         * @param el {Object} Station element
         */
        createControlHandles: function(el) {
            this.activeElement = el ?? this.activeElement;
            const station = this.activeElement.selectAll('.svg-station')._groups[0][0];

            if (!station
                || this.activeElement.attr('id') === 'master'
                || this.activeElement.attr('id').startsWith('stream')) {
                return;
            }

            const controlHandlesWidth = this.getControlHandlesWidth();
            const controlHandlesHeight = this.getControlHandlesHeight();
            const isEllipse = station.tagName === 'ellipse';
            const delta = {
                x: this.getStationBtnWidth(),
                y: this.getStationBtnHeight()
            };

            if (this.svg !== undefined) {
                this.removeControlHandles();
            }

            // RIGHT
            let btnMenuMove = { // Right center
                x: data.x + data.width - (controlHandlesWidth / 2),
                y: isEllipse ? data.y - (controlHandlesHeight / 2) : data.y + (data.height / 2) - (controlHandlesHeight / 2),
                cx: data.x + data.width,
                cy: isEllipse ? data.y : data.y + (data.height / 2)
            };
            let btnResize = { // Right top
                cx: isEllipse ? (data.x + (data.width * Math.sin(Math.acos(delta.y / data.height)))) : btnMenuMove.x + (controlHandlesWidth / 2),
                cy: (btnMenuMove.y - delta.y) + (controlHandlesHeight / 2)
            };
            let btnColor = { // Right bottom
                cx: isEllipse ? (data.x + (data.width * Math.sin(Math.acos(delta.y / data.height)))) : btnMenuMove.x + (controlHandlesWidth / 2),
                cy: (btnMenuMove.y + delta.y) + (controlHandlesHeight / 2)
            };

            this.addControlHandle('ctrlRight', btnMenuMove, btnColor, btnResize);

            // LEFT
            btnMenuMove = { // Left center
                x: isEllipse ? data.x - data.width - (controlHandlesWidth / 2) : data.x - (controlHandlesWidth / 2),
                y: isEllipse ? data.y - (controlHandlesHeight / 2) : data.y + (data.height / 2) - (controlHandlesHeight / 2),
                cx: isEllipse ? data.x - data.width : data.x,
                cy: isEllipse ? data.y : data.y + (data.height / 2)
            };
            btnResize = { // Left top
                cx: isEllipse ? (data.x - (data.width * Math.sin(Math.acos(delta.y / data.height)))) : btnMenuMove.x + (controlHandlesWidth / 2),
                cy: (btnMenuMove.y - delta.y) + (controlHandlesHeight / 2)
            };
            btnColor = { // Left bottom
                cx: isEllipse ? (data.x - (data.width * Math.sin(Math.acos(delta.y / data.height)))) : btnMenuMove.x + (controlHandlesWidth / 2),
                cy: (btnMenuMove.y + delta.y) + (controlHandlesHeight / 2)
            };

            this.addControlHandle('ctrlLeft', btnMenuMove, btnColor, btnResize);

            // BOTTOM
            btnMenuMove = { // Bottom center
                x: isEllipse ? data.x - (controlHandlesWidth / 2) : data.x + (data.width / 2) - (controlHandlesWidth / 2),
                y: data.y + data.height - (controlHandlesHeight / 2),
                cx: isEllipse ? data.x : data.x + (data.width / 2),
                cy: data.y + data.height
            };
            btnColor = { // Bottom left
                cx: (btnMenuMove.x - delta.x) + (controlHandlesWidth / 2),
                cy: isEllipse ? (data.y + (data.height * Math.sin(Math.acos(delta.x / data.width)))) : btnMenuMove.y + (controlHandlesWidth / 2)
            };
            btnResize = { // Bottom right
                cx: (btnMenuMove.x + delta.x) + (controlHandlesWidth / 2),
                cy: isEllipse ? (data.y + (data.height * Math.sin(Math.acos(delta.x / data.width)))) : btnMenuMove.y + (controlHandlesWidth / 2)
            };

            this.addControlHandle('ctrlBottom', btnMenuMove, btnColor, btnResize);

            // TOP
            btnMenuMove = { // Top
                x: isEllipse ? data.x - (controlHandlesWidth / 2) : data.x + (data.width / 2) - (controlHandlesWidth / 2),
                y: isEllipse ? data.y - data.height - (controlHandlesHeight / 2) : data.y - (controlHandlesHeight / 2),
                cx: isEllipse ? data.x : data.x + (data.width / 2),
                cy: isEllipse ? data.y - data.height : data.y
            };
            btnColor = { // Top left
                cx: (btnMenuMove.x - delta.x) + (controlHandlesWidth / 2),
                cy: isEllipse ? (data.y - (data.height * Math.sin(Math.acos(delta.x / data.width)))) : btnMenuMove.y + (controlHandlesHeight / 2)
            };
            btnResize = { // Top right
                cx: (btnMenuMove.x + delta.x) + (controlHandlesWidth / 2),
                cy: isEllipse ? (data.y - (data.height * Math.sin(Math.acos(delta.x / data.width)))) : btnMenuMove.y + (controlHandlesHeight / 2)
            };

            this.addControlHandle('ctrlTop', btnMenuMove, btnColor, btnResize);
        },

        /**
         * Add control handle to current station.
         *
         * @param id Control handle id
         * @param pos Control handle position
         * @param pos0 Left or top control handle position
         * @param pos1 Right or bottom control handle position
         */
        addControlHandle: function(id, pos, pos0, pos1) {
            const container = this.$controlHandleContainer.clone(true);

            this.activeElement
                .append('svg:foreignObject')
                .attr('class', 'control-handle-container controlHandle')
                .attr('cx', pos.cx)
                .attr('cy', pos.cy)
                .attr('x', pos.x)
                .attr('y', pos.y)
                .attr('id', id)
                .attr('height', this.getControlHandlesHeight())
                .attr('width', this.getControlHandlesWidth())
                .attr('transform', 'rotate(0)')
                .attr('cx0', Math.round(pos0.cx))
                .attr('cy0', Math.round(pos0.cy))
                .attr('cx1', Math.round(pos1.cx))
                .attr('cy1', Math.round(pos1.cy))
                .attr('display', 'none')
                .html(container.get(0).innerHTML);
        },

        resize: function(isWidth) {
            const newDimension = this.getNewDimension(isWidth, isWidth ? d3.event.dx : d3.event.dy);

            if (this.isMinDimensions(isWidth ? data.width : data.height, newDimension)) {
                return;
            }

            if (this.activeElement.attr('id').startsWith('master')) {
                if (isWidth) {
                    const newHeight = this.getNewDimension(!isWidth, d3.event.dx * (9 / 16));
                    if (!this.isMinDimensions(data.height, newHeight)
                    && newDimension + data.x < this.configs.get('dimensions.width')
                    && newHeight + data.y < this.configs.get('dimensions.height')) {
                        this.resizeFormWidth(newDimension);
                        this.resizeFormHeight(newHeight);
                    }
                } else {
                    const newWidth = this.getNewDimension(!isWidth, d3.event.dy * (16 / 9));
                    if (!this.isMinDimensions(data.width, newWidth)
                    && newDimension + data.y < this.configs.get('dimensions.height')
                    && newWidth + data.x < this.configs.get('dimensions.width')) {
                        this.resizeFormHeight(newDimension);
                        this.resizeFormWidth(newWidth);
                    }
                }
            } else if (isWidth) {
                this.resizeFormWidth(newDimension);
            } else {
                this.resizeFormHeight(newDimension);
            }
        },

        getNewDimension: function(isWidth, dragCoord) {
            if (isWidth) {
                return Math.max(
                    data.x + (this.getControlHandlesWidth() / 2),
                    Math.min(this.configs.get('dimensions.width'), data.x + data.width + dragCoord)
                ) - data.x;
            } else {
                return Math.max(
                    data.y + (this.getControlHandlesHeight() / 2),
                    Math.min(this.configs.get('dimensions.height'), data.y + data.height + dragCoord)
                ) - data.y;
            }
        },

        isMinDimensions: function(lastCoord, newCoord) {
            let stationMinWidthHeight = this.configs.get('station.minWidthHeight');
            if (this.activeElement.selectAll('.svg-station')._groups[0][0].tagName === 'ellipse') {
                stationMinWidthHeight /= 2;
            }

            return newCoord < lastCoord && newCoord < stationMinWidthHeight;
        },

        /**
         * Resize form width.
         *
         */
        resizeFormWidth: function(newWidth) {
            const dragx = newWidth + data.x;
            const lastWidth = data.width;
            let nameWidth;

            if (this.activeElement.selectAll('.svg-station')._groups[0][0].tagName === 'rect') {
                // Recalculate width
                data.width = newWidth;

                const dist = data.width - lastWidth;

                if (dist > 0) {
                    data.width += dist;
                    data.x -= dist;

                    this.activeElement.selectAll('.svg-station')
                        .attr('x', data.x);
                } else if (dist < 0) {
                    data.width += dist;
                    data.x += Math.abs(dist);

                    this.activeElement.selectAll('.svg-station')
                        .attr('x', data.x);
                }

                // Move the right drag handle
                this.draghandleright
                    .attr('x', function() {
                        return dragx - (transformHandlesWidth / 2);
                    });

                this.draghandlebottom
                    .attr('x', function() {
                        return data.x + (data.width / 2) - (transformHandlesWidth / 2);
                    });

                // Resize the drag rectangle
                // As we are only resizing from the right, the x coordinate does not need to change
                this.activeElement.selectAll('.svg-station')
                    .attr('width', data.width);
                // Resize name container
                nameWidth = data.width - this.getControlHandlesWidth();
            } else {
                // Recalculate width
                data.width = dragx - data.x;

                this.activeElement.selectAll('.svg-station')
                    .attr('cx', data.x);

                this.activeElement.selectAll('.svg-station')
                    .attr('rx', data.width);

                // Move the right drag handle
                this.draghandleright
                    .attr('x', function() {
                        return dragx - (transformHandlesWidth / 2);
                    });

                // Move the right drag handle
                this.draghandlebottom
                    .attr('x', function() {
                        return data.x - (transformHandlesWidth / 2);
                    });

                // Resize the drag rectangle
                // As we are only resizing from the right, the x coordinate does not need to change
                this.activeElement.selectAll('.svg-station')
                    .attr('width', data.width * 2);

                // Resize name container
                nameWidth = data.width * 2 - this.getControlHandlesWidth();
            }
            this.activeElement.select('.station-name-container')
                .attr('x', this.activeElement.attr('cx') - nameWidth / 2)
                .attr('width', nameWidth);

            this.updateFormConfig();
        },

        /**
         * Resize form height.
         *
         */
        resizeFormHeight: function(newHeight) {
            const dragy = newHeight + data.y;
            const lastHeight = data.height;

            if (this.activeElement.selectAll('.svg-station')._groups[0][0].tagName === 'rect') {
                // Recalculate height
                data.height = newHeight;

                const dist = data.height - lastHeight;

                if (dist > 0) {
                    data.height += dist;
                    data.y -= dist;

                    this.activeElement.selectAll('.svg-station')
                        .attr('y', data.y);
                } else if (dist < 0) {
                    data.height += dist;
                    data.y += Math.abs(dist);

                    this.activeElement.selectAll('.svg-station')
                        .attr('y', data.y);
                }

                // Move the bottom drag handle
                this.draghandlebottom
                    .attr('y', function() {
                        return dragy - (transformHandlesHeight / 2);
                    });

                if (!this.activeElement.attr('id').startsWith('master')) {
                // Move the rotation drag handle
                    this.dragrotationhandle
                        .attr('y', function() {
                            return dragy + (transformHandlesHeight / 2);
                        });
                }
                // Move the right drag handle
                this.draghandleright
                    .attr('y', function() {
                        return data.y + (data.height / 2) - (transformHandlesHeight / 2);
                    });

                // Resize the drag rectangle
                // As we are only resizing from the right, the x coordinate does not need to change
                this.activeElement.selectAll('.svg-station')
                    .attr('height', data.height);
            } else {
                // Recalculate width
                data.height = dragy - data.y;

                this.activeElement.selectAll('.svg-station')
                    .attr('cy', data.y);

                this.activeElement.selectAll('.svg-station')
                    .attr('ry', data.height);

                // Move the bottom drag handle
                this.draghandlebottom
                    .attr('y', function() {
                        return dragy - (transformHandlesHeight / 2);
                    });

                // Move the rotation drag handle
                if (!this.activeElement.attr('id').startsWith('master')) {
                    this.dragrotationhandle
                        .attr('y', function() {
                            return dragy + (transformHandlesHeight / 2);
                        });
                }

                // Move the right drag handle
                this.draghandleright
                    .attr('y', function() {
                        return data.y - (transformHandlesHeight / 2);
                    });

                // Resize the drag rectangle
                // As we are only resizing from the right, the x coordinate does not need to change
                this.activeElement.selectAll('.svg-station')
                    .attr('height', data.height * 2);
            }

            this.updateFormConfig();
        },

        /**
         * Rotate form.
         *
         */
        rotateForm: function() {
            const station = this.activeElement.selectAll('.svg-station')._groups[0][0];

            if (!station) {
                return;
            }

            const isEllipse = station.tagName === 'ellipse';

            const exy = [d3.event.x, d3.event.y];
            const dxy = [isEllipse ? data.x : data.x + (data.width / 2), isEllipse ? data.y : data.y + (data.height / 2)];
            let angle = data.angle + this.angleBetweenPoints(dxy, exy);

            angle += Math.PI / 2;
            data.angle = angle - 3.14159; // Subtract 180 degree due to the rotation handle position (bottom)

            let deg = this.matrixService.toDegrees(data.angle);

            if (d3.event.type === 'end') {
                deg = Math.round(deg / 15) * 15; // Round to 15 degree step
            }

            data.angle = this.matrixService.toRad(deg);

            // Data.angle = Math.atan2(data.y - d3.event.y, data.x - d3.event.x) * 180 / Math.PI;
            this.activeElement.attr('transform', 'rotate(' + deg + ','
                + (isEllipse ? data.x : (data.x + (data.width / 2))) + ','
                + (isEllipse ? data.y : (data.y + (data.height / 2))) + ')');

            // Menu buttons
            const btnData = this.activeElement.selectAll('.station-button-container')._groups[0];
            this.activeElement.selectAll('.station-button-container')
                .data(btnData)
                .attr('transform', function(d) {
                    return 'rotate(' + deg * -1 + ',' + d.attributes.cx.value + ',' + d.attributes.cy.value + ')';
                }.bind(this));

            // Name
            const nameData = this.activeElement.selectAll('.station-name-container')._groups[0];
            this.activeElement.selectAll('.station-name-container')
                .data(nameData)
                .attr('transform', function(d) {
                    return 'rotate(' + deg * -1 + ',' + d.attributes.cx.value + ',' + d.attributes.cy.value + ')';
                }.bind(this));

            this.activeElement
                .attr('angle', data.angle)
                .selectAll('.svg-station')
                .attr('angle', data.angle);

            this.updateFormConfig();
        },

        /**
         * Update form configuration to adapt it to the next added station.
         */
        updateFormConfig: function() {
            const el = this.activeElement.selectAll('.svg-station');
            const isReceiver = this.activeElement.attr('model') === 'CPR';

            switch (this.activeElement._groups[0][0].getAttribute('form')) {
                case form.circle:
                    configCircle = {
                        rx: parseInt(el.attr('rx')),
                        ry: parseInt(el.attr('ry')),
                        angle: parseFloat(el.attr('angle')),
                        color: isReceiver ? configCircle.color : this.activeElement.attr('color')
                    };
                    break;
                case form.rectangle:
                    configRect = {
                        width: parseInt(el.attr('width')),
                        height: parseInt(el.attr('height')),
                        angle: parseFloat(el.attr('angle')),
                        color: isReceiver ? configRect.color : this.activeElement.attr('color')
                    };
                    break;
            }
        },

        /**
         * Get distance between points p1 & p2.
         *
         * @param p1 {number} Point 1
         * @param p2 {number} Point 2
         * @returns {number} Distance between p1 & p2
         */
        distanceBetweenPoints: function(p1, p2) {
            return Math.sqrt(Math.pow(p2[1] - p1[1], 2) + Math.pow(p2[0] - p1[0], 2));
        },

        /**
         * Get nearest element for el.
         *
         * @param el {Object} Find nearest element for el.
         * @param distX {int} distance between points in x direction.
         * @param distY {int} distance between points in y direction.
         * @param elClass {string} class elements to find.
         * @returns {*}
         */
        getNearestElement: function(el, distX, distY, elClass) {
            const elements = this.svg.selectAll('#matrix-group').selectAll(elClass);
            let x = el.attr('cx') ? parseInt(el.attr('cx')) : parseInt(el.attr('x'));
            let y = el.attr('cy') ? parseInt(el.attr('cy')) : parseInt(el.attr('y'));

            x = x < 0 || x > this.configs.get('dimensions.width') ? 0 : x;
            y = y < 0 || y > this.configs.get('dimensions.height') ? 0 : y;

            distX = x < distX / 2 || x > (this.configs.get('dimensions.width') - distX / 2) ? distX : distX / 2;
            distY = y < distY / 2 || y > (this.configs.get('dimensions.height') - distY / 2) ? distY : distY / 2;

            const nearest = elements.filter(function() {
                return ((Math.abs(parseInt($(this)[0].getAttribute('cx')) - x) <= (distX))
                    && Math.abs(parseInt($(this)[0].getAttribute('cy')) - y) <= (distY));
            });

            this.nearestEl = nearest;

            return this.nearestEl;
        },

        /**
         * Highlight trash bin if element is dragged over icon.
         *
         * @param el Moved element.
         */
        highlightTrashBin: function(el) {
            const trashBin = this.$svg.find('.btn-trash-bin');

            if (this.matrixService.pointInCircle(
                el.attr('cx') ? parseInt(el.attr('cx')) : parseInt(el.attr('x')),
                el.attr('cy') ? parseInt(el.attr('cy')) : parseInt(el.attr('y')),
                parseInt(trashBin.attr('x')) + (trashBin.attr('width') / 2),
                parseInt(trashBin.attr('y')) + (trashBin.attr('height') / 2),
                trashBin.attr('width') / 2,
                trashBin.attr('height') / 2
            )) {
                if (this.nearestEl) {
                    this.nearestEl.classed('is-nearest', false);
                }

                trashBin.addClass('highlighted');
            } else {
                trashBin.removeClass('highlighted');
            }
        },

        /**
         * Highlight nearest element.
         *
         * @param el {Object} Find nearest element for el.
         * @param distX {int} distance between points in x direction.
         * @param distY {int} distance between points in y direction.
         * @param elClass {string} class elements to find.
         */
        highlightNearestEl: function(el, distX, distY, elClass) {
            const oldNearestEl = this.nearestEl;

            if (distX) {
                this.getNearestElement(el, distX, distY, elClass);
            } else {
                this.getNearestControlHandle(el);
            }

            if (this.nearestEl !== oldNearestEl) {
                if (oldNearestEl && !oldNearestEl.empty()) {
                    oldNearestEl
                        .classed('is-nearest', false)
                        .attr('r', 5);
                }

                if (this.nearestEl && !this.nearestEl.empty()) {
                    this.nearestEl
                        .classed('is-nearest', true)
                        .attr('r', 20);
                }
            }
        },

        /**
         * Get nearest control handle
         *
         * @param el {Object} Find nearest control handle for el.
         * @returns {*}
         */
        getNearestControlHandle: function(el) {
            const $elements = this.svg.selectAll('.controlHandle');
            let x;
            let y;

            if (typeof el === 'undefined') {
                el = this.svg.select('#stations-layer').select(this.isDualProjection ? '#master-hdmi1' : '#master');
            }

            if (el.tagName === 'foreignObject') {
                x = parseInt(el.attr('cx'));
                y = parseInt(el.attr('cy'));
            } else {
                x = parseInt(el.attr('cx'))
                    ? parseInt(el.attr('cx')) : parseInt(el.attr('x'));
                y = parseInt(el.attr('cy'))
                    ? parseInt(el.attr('cy')) : parseInt(el.attr('y'));
            }

            let lastDistance;

            _.each($elements._groups[0], function(element) {
                let p1 = [
                    parseInt(element.getAttribute('cx'))
                        ? parseInt(element.getAttribute('cx'))
                        : parseInt(element.getAttribute('x')),
                    parseInt(element.getAttribute('cy'))
                        ? parseInt(element.getAttribute('cy'))
                        : parseInt(element.getAttribute('y'))
                ];

                p1 = this.matrixService.calculateRotatedPosition(
                    parseInt(this.activeElement.attr('cx')),
                    parseInt(this.activeElement.attr('cy')),
                    p1[0], p1[1],
                    parseInt(this.activeElement.attr('angle')));

                const distance = this.distanceBetweenPoints(p1, [parseInt(x), parseInt(y)]);

                if (typeof lastDistance === 'undefined' || distance < lastDistance) {
                    this.nearestEl = this.svg.select('#' + element.getAttribute('id'));
                    lastDistance = distance;
                }
            }.bind(this));

            return this.nearestEl;
        },

        /**
         * Set station and connection color.
         *
         * @param color Chosen station color
         */
        setStationColor: function(color) {
            if (this.activeElement.attr('model') === 'CPR') {
                color = 'rgba(117, 117, 117, 1)';
            }

            this.activeElement.attr('color', color)
                .selectAll('.svg-station')
                .attr('color', color);

            if (this.isDualProjection && this.activeElement.attr('id').startsWith('master')) {
                $(this.activeElement._groups[0][0]).find('.station-button-container:not(.btn-center)').find('.icon')
                    .css('color', color);

                const masterBtn = $(this.activeElement._groups[0][0]).find('.btn-center').find('.matrix-circle.menu-btn');

                masterBtn
                    .css('background-color', color);
                masterBtn.find('.icon').css('color', '#edeff5');

                $(this.activeElement._groups[0][0]).find('.matrix-circle-small').find('.text')
                    .css('color', color);
            } else {
                this.matrixConnectionService.setConnectionsColor(this.activeElement.attr('id'), color);

                this.$svg.find('.station[id=' + this.activeElement.attr('id') + ']')
                    .find('.matrix-circle.menu-btn').find('.icon')
                    .css('color', color);

                this.$svg.find('.station[id=' + this.activeElement.attr('id') + ']')
                    .find('.matrix-circle-small').find('.text')
                    .css('color', color);
            }

            this.updateFormConfig();
        },

        removeColorPicker: function() {
            $(document).find('.sp-container').remove();
        },

        /**
         * Get device IDs added into configuration.
         * (not necessarily configurated in backend!!)
         */
        getConfigDeviceIds: function() {
            const deviceIds = [];

            _.each(this.$svg.find('#stations-layer').find('.station'), function(device) {
                deviceIds.push(device.id);
            }.bind(this));

            return deviceIds;
        },

        /**
         * Checks if there are some unsaved changes.
         */
        checkUnsavedConfig: function() {
            const dfd = $.Deferred();

            this.configs.loadMatrixConfiguration().then(function(config) {
                // Deselect all stations
                this.deselectStation();

                dfd.resolve(config !== '' && this.$svg.find('#matrix-layer').get(0).innerHTML !== config);
            }.bind(this));

            return dfd.promise();
        },

        /**
         * Return a list of stations which are currently not present.
         *
         * @returns {array} list of station id's, {boolean} true if max station nr is reached (> 40)
         */
        checkStationStatus: function() {
            const dfd = $.Deferred();
            let nrStations = 0;

            this.matrixService.getDevices()
                .then(function(data) {
                    const devicesOffline = [];

                    _.each(this.getConfigDeviceIds(), function(id) {
                        if (id.startsWith('master') || id.startsWith('stream')) {
                            return true;
                        }

                        const station = $.grep(data.devices, function(e) {
                            return id === e.id && this.matrixService.isStationPresent(id.replace('sn', ''), true);
                        }.bind(this));

                        if (station.length <= 0) {
                            devicesOffline.push(id);
                        }

                        nrStations++;
                    }.bind(this));

                    dfd.resolve(devicesOffline, (nrStations > this.configs.get('stations.max')));
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Return list of station names
         *
         * @param stations (id)
         * @returns {Array}
         */
        getStationNames: function(stations) {
            const stationNames = [];

            _.each(stations, function(id) {
                if (!id.startsWith('stream')) {
                    stationNames.push(this.$svg.find('#stations-layer').find('.station[id=' + id + ']')[0].attributes['name'].value);
                }
            }.bind(this));

            return stationNames;
        },

        /**
         * Get stations button width (Station vs. Receiver)
         * @returns {number} defined width
         */
        getStationBtnWidth: function() {
            return this.activeElement.attr('model') === 'CPR'
                ? this.configs.get('receiver.btnWidth') : this.configs.get('station.btnWidth');
        },

        /**
         * Get stations button height (Station vs. Receiver)
         * @returns {number} defined height
         */
        getStationBtnHeight: function() {
            return (this.activeElement && this.activeElement.attr('model') === 'CPR')
                ? this.configs.get('receiver.btnHeight') : this.configs.get('station.btnHeight');
        },

        /**
         * Get control handles width (Station vs. Receiver)
         * @returns {number} defined width
         */
        getControlHandlesWidth: function() {
            return this.activeElement.attr('model') === 'CPR'
                ? this.configs.get('receiver.handlesWidth') : this.configs.get('station.handlesWidth');
        },

        /**
         * Get control handles height (Station vs. Receiver)
         * @returns {number} defined height
         */
        getControlHandlesHeight: function() {
            return (this.activeElement && this.activeElement.attr('model') === 'CPR')
                ? this.configs.get('receiver.handlesHeight') : this.configs.get('station.handlesHeight');
        }
    };
});
