// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"meeting-check-in-container\"\n     class=\"meeting-check-in-container hidden\">\n    <form id=\"meeting-check-in-form\" name=\"meeting-check-in-form\" class=\"meeting-check-in-form\">\n        <div id=\"check-in-title\" class=\"check-in-title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"session_management.check_in_pin",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":56},"end":{"line":4,"column":95}}}))
    + "</div>\n        <div class=\"session-management-input-container\">\n            <div id=\"check-in-pin-container\" class=\"session-management-input\">\n                <input type=\"password\"\n                       id=\"check-in-pin-input\"\n                       class=\"input is-focusable\"\n                       name=\"checkInPin\"\n                       placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"session_management.enter_pin",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":36},"end":{"line":11,"column":72}}}))
    + "\"/>\n                <div id=\"check-in-btn\" class=\"session-management-input-btn clickable\" data-action=\"check-in\">\n                    <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"session_management.check_in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":26},"end":{"line":13,"column":62}}}))
    + "</span>\n                </div>\n            </div>\n            <div class=\"check-in-resend-pin-container\">\n            <span id=\"check-in-resend-pin\" class=\"text clickable\" data-action=\"resend-pin\">\n                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"session_management.resend_pin",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":18,"column":53}}}))
    + "\n            </span>\n                <span id=\"check-in-resent-info\" class=\"text no-underline\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"session_management.resent_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":74},"end":{"line":20,"column":112}}}))
    + "</span>\n            </div>\n        </div>\n        <div class=\"check-in-info-container hidden\">\n            <span id=\"check-in-error\" class=\"text\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"session_management.error_pin",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":51},"end":{"line":24,"column":87}}}))
    + "</span>\n            <span id=\"check-in-loader\" class=\"loader\"></span>\n        </div>\n        <div class=\"session-management-close-container\">\n            <div id=\"check-in-close\" class=\"session-management-close-btn clickable\" data-action=\"close\">\n                <span class=\"icon icon-v2-close\"></span>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});
