'use strict';

var layoutManager = require('./../layout');
var platform = require('./../../platform/platform');
var app = require('./../../app/app');
var _ = require('lodash');

/**
 * Used for any view
 *
 * @param {jQuery.Element} $el
 *
 * @constructor
 */
var DefaultView = function($el) {
    this.$el = $el;

    this.liveStream = app.getService('LiveStreamService');

    this.initialize();
};

/**
 * @method initialize
 */
DefaultView.prototype.initialize = function() {
    this.bindEvents();
    this.updateLayoutSize();
};

/**
 * Bind all events
 */
DefaultView.prototype.bindEvents = function() {
    app.on('app.resize', this.updateLayoutSize.bind(this));
    layoutManager.on('window.resize', this.updateLayoutSize.bind(this));
    app.on('layout-size.update', this.updateLayoutSize.bind(this));
};

/**
 * Update the livestream and the component container size
 *
 * @method updateLayoutSize
 */
DefaultView.prototype.updateLayoutSize = function() {
    var menuContainerSize = layoutManager.calculateSizeByAspectRatio(4 / 3);
    var fullscreenContainerSize = layoutManager.calculateSizeByAspectRatio(16 / 9);
    var size;
    var fullscreenSize;

    this.liveStream.updateSize();

    size = {
        width: this.liveStream.getSize().width + 'px',
        height: menuContainerSize.height + 'px'
    };

    fullscreenSize = {
        width: this.liveStream.getSize().width + 'px',
        height: fullscreenContainerSize.height + 'px'
    };

    /**
     * When vSolution Meeting overlay is openend, use aspect ratio of 16:9 because the vMeeting
     * background has this format.
     */
    if (this.$el.find('#session-management-container').is(':visible')) {
        size = {
            width: this.$el.find('#aspect-ratio-16-9').width(),
            height: this.$el.find('#aspect-ratio-16-9').height()
        };

        if (!platform.checks.isCbox && !this.$el.find('#frameboxes-container').hasClass('no-status-bar')) {
            size.height += 40;
        }
    }

    // Android Fix: Android ignores width change on this moment so we need a short break to trigger the change.
    // First we change the width to the current width minus one.
    // On second step we set attribut to current width, so android triggers a change.
    // FIXME: did we need the fix on chrome 45, check at the next chrome release?
    if (platform.checks.isAndroid) {
        setTimeout(function() {
            this.$el.find('#aspect-ratio-4-3').width(this.liveStream.getSize().width - 1);
            _.defer(function() {
                this.$el.find('#aspect-ratio-4-3').css(size);
                this.$el.find('.framebox-fullscreen-ui').css(fullscreenSize);
            }.bind(this));
        }.bind(this), 200);
    }

    this.$el.find('#aspect-ratio-4-3').css(size);

    // Wait for the DOM content to be loaded
    setTimeout(function() {
        this.$el.find('.framebox-fullscreen-ui').css(fullscreenSize);
    }.bind(this), 0);

    app.emit('aspect-ratio.changed', size);
    app.emit('ui-scaling.update', {
        scaling: this.liveStream.scaling
    });
};

module.exports = DefaultView;
