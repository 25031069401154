// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"webconference-settings-item-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\"webconference-settings-item\" data-index=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n    <div class=\"webconference-service-container\">\n        <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"preferredUrl\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.webconference_service_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":66},"end":{"line":6,"column":110}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input is-focusable\"\n                           id=\"name-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                           name=\"name\"\n                           value=\"\"\n                           data-index=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                           data-validation=\"required\"\n                           tabIndex=\"-1\"/>\n\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.error_messages.required",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":71},"end":{"line":17,"column":111}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n            <div class=\"col-xs-12\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"preferredUrl\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.webconference_service_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":66},"end":{"line":26,"column":109}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input is-focusable\"\n                           id=\"url-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                           name=\"url\"\n                           value=\"\"\n                           data-index=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                           data-validation=\"checkMinMaxLength\"\n                           data-min-length=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"minUrlLength") : depth0), depth0))
    + "\"\n                           data-max-length=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"maxUrlLength") : depth0), depth0))
    + "\"\n                           tabIndex=\"-1\"/>\n\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"urlValidationError") : depth0), depth0))
    + "</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\"\n             data-nav-area=\".btn-switch\"\n             data-nav-trapped\n             data-nav-area-default>\n            <div class=\"col-xs-8\">\n                <h2 class=\"input-label\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.webconference_native_screenshare",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":40},"end":{"line":50,"column":89}}}))
    + "</h2>\n            </div>\n\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input id=\"native-screenshare-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                           name=\"nativeScreenshare\"\n                           type=\"checkbox\"\n                           class=\"btn-switch\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary text-centered p-bottom-20\"\n             data-nav-area=\".is-focusable\"\n             data-nav-trapped>\n            <input type=\"button\"\n                   id=\"delete-service-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                   name=\"delete\"\n                   class=\"btn-form form-action-cancel is-focusable delete-service\"\n                   data-index=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                   value=\""
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":26},"end":{"line":74,"column":49}}}))
    + "\">\n        </div>\n    </div>\n\n    <div class=\"row settings-list-secundary delete-info text-centered p-top-20 p-bottom-20 hidden\">\n        <span class=\"delete-info-message\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.delete_template_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":42},"end":{"line":79,"column":79}}}))
    + "</span>\n    </div>\n\n    <div id=\"form-action-container\" class=\"settings-list-secundary\" data-index=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"></div>\n</form>\n";
},"useData":true});
