'use strict';

var app = require('../../../app');
var barTpl = require('./framebox-ui-control-bar.hbs');
var platform = require('./../../../../platform/platform');

app.component('FrameboxControlBar', {
    template: barTpl,

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.controlsComponentId = null;
        this.frameboxComponent = this.options.frameboxComponent;
        this.isMatrix = this.frameboxComponent === 'FrameBoxMatrix' || this.frameboxComponent === 'FrameBoxMatrixGroupwork';
        this.hasProgressbar = this.frameboxComponent === 'FrameBoxVideoViewer' || this.frameboxComponent === 'FrameBoxAudioViewer';

        this.outputService = this.getService('OutputService');
        this.frameBoxService = this.getService('FrameBoxService');
        this.stationService = this.getService('StationService');
        this.deviceService = this.getService('DeviceService');
        this.isDualProjection = this.deviceService.isCboxProDualProjection();

        if (this.isMatrix) {
            this.matrixConfigs = this.getService('MatrixConfigs');
        }
    },

    serialize: function() {
        return {
            index: this.options.index,
            title: this.updateFrameboxTitle(this.options.frameBoxOptions),
            titleIcon: this.options.titleIcon,
            isFullscreen: this.options.isFullscreen,
            isCbox: platform.checks.isCbox,
            isDualProjection: this.isDualProjection
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.createControls();
        this.createProgressbar();
        this.bindEvents();

        if (!this.isDualProjection) {
            this.checkAuxMode();

            this.hdmiOut2StateHandler({
                index: this.index,
                isHdmiOut2: this.frameBoxService.isHdmiOut2(this.index)
            });
        }

        /**
         * Progressbar is always shown in edit mode (control screen)
         */
        const showProgressbar = this.isDualProjection;
        this.updateProgressbar(this.index, showProgressbar);

        if (this.isMatrix) {
            this.setMatrixControlBar();
        }
    },

    storeSelectors: function() {
        this.$controlsContainer = this.$el.find('.framebox-control-bar-controls-container');
        this.$symbol = this.$el.find('.framebox-symbol');
        this.$toggleHdmiOut2 = this.$el.find('.framebox-option-toggle-hdmi-out-2');
        this.$toggleContainer = this.$el.find('#framebox-control-bar-toggle-container');
        this.$optionsContainer = this.$el.find('#framebox-control-bar-options-container');
        this.$titleContainer = this.$el.find('#framebox-control-bar-title-container');
        this.$submenuCloseContainer = this.$el.find('#framebox-control-bar-submenu-close-container');
        this.$statusbar = this.$el.find('#framebox-progressbar-' + this.index);
    },

    bindEvents: function() {
        this.on('framebox.change.' + this.options.index, this.onFrameboxUpdateControlBar.bind(this));
        this.on('framebox.control-bar.update.' + this.options.index, this.onFrameboxUpdateControlBar.bind(this));
        this.on('framebox.submenu.change', this.submenuHandler.bind(this));
        this.on('framebox.mode.pinky', this.startPinkyMode.bind(this));
        this.on('framebox.mode.brain', this.startBrainMode.bind(this));
        this.on('framebox.title.update.' + this.options.index, this.updateFrameboxTitle.bind(this));

        if (!this.isDualProjection) {
            this.on('framebox.hdmi-out-2.update', this.hdmiOut2StateHandler.bind(this));
            this.on('hdmi-out-2-mode.changed', this.checkAuxMode.bind(this));
            this.on('hdmi-out-2-mode.changed', this.hdmiOut2StateHandler.bind(this));
        }
    },

    /**
     * Check Aux Mode.
     */
    checkAuxMode: function() {
        this.deviceConnection
            .send('getAuxMode')
            .then(function(aux) {
                this.showHdmiOut2(aux.contentMode && !this.outputService.isOverrideMirror());
            }.bind(this));
    },

    /**
     * Handles Pinky mode
     *
     * @param data.index Framebox index
     */
    startPinkyMode: function(data) {
        this.submenuHandler(data.index, false);
        this.updateProgressbar(data.index, false);
    },

    /**
     * Handles Pinky mode
     *
     * @param data.index Framebox index
     */
    startBrainMode: function(data) {
        this.updateProgressbar(data.index, true);
    },

    /**
     * Show or hide progress bar.
     *
     * @param show true/false
     */
    updateProgressbar: function(index, show) {
        if (this.index !== index || !this.hasProgressbar) {
            return;
        }

        if (show) {
            this.$statusbar.show();
        } else {
            this.$statusbar.hide();
        }
    },

    /**
     * Switch icon dependending on hdmi out 2 state.
     */
    hdmiOut2StateHandler: function(data) {
        if (this.index === data.index) {
            this.updateHdmiOut2(data.isHdmiOut2);
        }
    },

    /**
     * Handle submenu change (opened/closed).
     *
     * @param index Framebox index
     * @param opened true (open submenu) / false (close submenu)
     */
    submenuHandler: function(index, opened) {
        if (this.index !== index) {
            return;
        }

        if (opened) {
            this.$controlsContainer.addClass('submenu-opened');
            this.$optionsContainer.addClass('hidden');
            this.$toggleContainer.addClass('hidden');
            this.$titleContainer.addClass('hidden');
            this.$submenuCloseContainer.removeClass('hidden');

            this.emit('reinit.scrolling.' + this.index, true);
        } else {
            this.$controlsContainer.removeClass('submenu-opened');
            this.$optionsContainer.removeClass('hidden');
            this.$toggleContainer.removeClass('hidden');
            this.$titleContainer.removeClass('hidden');
            this.$submenuCloseContainer.addClass('hidden');

            this.emit('reinit.scrolling.' + this.index, false);
        }
    },

    /**
     * Called after a framebox has been changed.
     *
     * @param {object} framebox.options Framebox Options
     * @param {object} framebox.isFullscreen true - selected tab, ignore if not in fullscreen
     */
    onFrameboxUpdateControlBar: function(framebox) {
        if (!framebox) {
            return;
        }

        this.updateFrameboxTitle(framebox.options);

        if (this.isMatrix) {
            this.updateFrameboxSymbol(framebox.options, !this.options.isFullscreen || framebox.isFullscreen);
        }
    },

    /**
     * Update framebox title.
     *
     * @param {String} frameboxOptions.DeviceName
     */
    updateFrameboxTitle: function(frameboxOptions) {
        var $titleContainer
            = this.$el.find('.framebox-control-bar-item[data-index="' + this.options.index + '"] .framebox-title');

        // Fullscreen
        if ($titleContainer.length <= 0 && this.$titleContainer) {
            $titleContainer = this.$titleContainer.find('.framebox-title');
        }

        if (frameboxOptions) {
            if (frameboxOptions.DeviceName) {
                $titleContainer.text(frameboxOptions.DeviceName);

                return (this.options.frameBoxOptions.DeviceName = frameboxOptions.DeviceName);
            }

            if (frameboxOptions.imageName) {
                $titleContainer.text(frameboxOptions.imageName);

                return (this.options.frameBoxOptions.title = frameboxOptions.imageName);
            }
        }

        return this.options.title;
    },

    /**
     * Update framebox symbol.
     *
     * @param frameboxOptions.DeviceName
     * @param hasControls true if controls are visible (on fullscreen, selected tab)
     */
    updateFrameboxSymbol: function(frameboxOptions, hasControls) {
        if (frameboxOptions && frameboxOptions.DeviceName) {
            var station = this.matrixConfigs.getStationConfig(frameboxOptions.DeviceName);

            if (station) {
                this.$symbol.css('border-color', station.color);
                this.$symbol.css('background', hasControls ? station.color : 'transparent');
            }
        }
    },

    createControls: function() {
        if (this.frameboxComponent && !this.controlsComponentId) {
            this.controlsComponentId = this.createComponent({
                type: this.frameboxComponent,
                container: this.$controlsContainer,
                index: this.options.index,
                appId: this.options.appId,
                frameBoxOptions: this.options.frameBoxOptions,
                isFullscreen: this.options.isFullscreen,
                $actionEl: this.options.$actionEl,
                isMatrix: this.isMatrix,
                isFixed: this.options.isFixed
            });
        }
    },

    createProgressbar: function() {
        if (this.frameboxComponent !== 'FrameBoxVideoViewer' && this.frameboxComponent !== 'FrameBoxAudioViewer') {
            return;
        }

        this.createComponent({
            type: 'AudioVideoStatusbar',
            container: this.$statusbar,
            index: this.options.index,
            appId: this.options.appId,
            frameboxOptions: this.options.frameBoxOptions,
            isAudio: this.frameboxComponent === 'FrameBoxAudioViewer'
        });

        this.hasProgressbar = true;
    },

    /**
     * Stops special controls components inside the header bar.
     */
    stopComponent: function() {
        if (this.controlsComponentId) {
            this.app.removeComponent(this.controlsComponentId, {});
            this.controlsComponentId = null;
        }
    },

    setMatrixControlBar: function() {
        this.$symbol.addClass('is-matrix');
    },

    showHdmiOut2: function(isContentMode) {
        if (isContentMode) {
            this.$controlsContainer.addClass('has-hdmi-out-2-btn');
            this.$toggleHdmiOut2.removeClass('hidden');
        } else {
            this.$controlsContainer.removeClass('has-hdmi-out-2-btn');
            this.$toggleHdmiOut2.addClass('hidden');
        }
    },

    updateHdmiOut2: function(isHdmiOut2) {
        if (isHdmiOut2) {
            this.$toggleHdmiOut2.find('#hdmi-out-2-on').removeClass('hidden');
            this.$toggleHdmiOut2.find('#hdmi-out-2-off').addClass('hidden');
        } else {
            this.$toggleHdmiOut2.find('#hdmi-out-2-off').removeClass('hidden');
            this.$toggleHdmiOut2.find('#hdmi-out-2-on').addClass('hidden');
        }
    }
});
