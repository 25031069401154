'use strict';

var app = require('../../app');
var QRCode = require('./../../../../vendors/qrcode');
var splashScreenTpl = require('./splash-screen.hbs');
var platform = require('./../../../../modules/platform/platform');
var $ = require('jquery');
var i18n = require('i18next');

/**
 * This component will display the connection types on the startscreen.
 */
app.component('SplashScreen', {
    template: splashScreenTpl,
    manuallyShowTimeout: null,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.data = {};
        this.deviceService = this.getService('DeviceService');
        this.frameBoxService = app.getService('FrameBoxService');
        this.frontendSettings = app.getService('FrontendSettings');
        this.annotationService = app.getService('AnnotationService');
        this.stationService = app.getService('StationService');

        this.init = true;
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();

        this.setTriggerMode();
    },

    storeSelectors: function() {
        this.$viewer = this.$el.find('#splash-screen-viewer');
        this.$qrCodeContainer = this.$el.find('#splash-screen-wlan-qr-code');
        this.$spacerContainer = this.$el.find('#splash-screen-wlan-spacer');
        this.$wlanContainer = this.$el.find('#splash-screen-wlan-container');
        this.$wlanPassword = this.$el.find('#splash-screen-wlan-password');
        this.$qrCode = this.$el.find('#qr-code-wifi');
        this.$wlanContainer = this.$el.find('#splash-screen-wlan-container');
        this.$headlineConnect = this.$el.find('#splash-screen-headline-connect');
        this.$headlineShare = this.$el.find('#splash-screen-headline-share');
    },

    bindEvents: function() {
        this.on('main-loop.update', this.updateData.bind(this));

        if (platform.checks.isCbox) {
            this.on('main-loop.update', this.getSplashScreenStatus.bind(this));
            this.on('main-loop.update.splash-screen', this.getSplashScreenStatus.bind(this));
            this.on('splash-screen.show', this.onOpenManually.bind(this));
        }
    },

    /**
     * Set trigger mode to true if splash screen is enabled and remote is enabled (frontend settings).
     */
    setTriggerMode: function() {
        this.frontendSettings.getSettings([
            'splashScreenEnabled',
            'splashScreenShowOnRemote'
        ])
            .then(function(splashScreenEnabled, showOnRemote) {
                var isEnabledDefault = app.getService('Model-View').splashScreenSettingsDefault.enabled;
                var isEnabled = null === splashScreenEnabled ? isEnabledDefault : splashScreenEnabled;

                this.deviceConnection
                    .send('setSplashScreenTriggerMode', { mode: (isEnabled && showOnRemote) });
            }.bind(this));
    },

    /**
     * Get splash screen status and open splash screen manually if status is active.
     */
    getSplashScreenStatus: function() {
        this.deviceConnection
            .send('getSplashScreenStatus')
            .then(function(status) {
                if (status.active) {
                    this.onOpenManually();
                }
            }.bind(this));
    },

    /**
     * Update frontend setting for visibility, so that all instances know if the screensaver is currently visible.
     *
     * @param visible true/false
     */
    updateVisibility: function(visible) {
        this.splashScreenVisible = visible;

        this.frontendSettings.updateSetting({
            tag: 'splashScreenVisible',
            value: visible
        });
        this.frontendSettings.saveSettings();
    },

    /**
     * Called when the "activities.hide"-event has been triggered.
     */
    onActivitiesShow: function() {
        clearTimeout(this.manuallyShowTimeout);
        this.manuallyShowTimeout = null;

        this.updateVisibility(false);

        this.updateData();
        this.off('activities.show');
        this.off('framebox.added');
    },

    /**
     * Will open the splash-screen when splash screen state is set to TRUE.
     * The timeout will close the splash-screen after some seconds.
     * A tap on the screen on some interaction also close the splash-screen.
     */
    onOpenManually: function() {
        this.updateData();
        clearTimeout(this.manuallyShowTimeout);

        this.frontendSettings
            .getSettings([
                'splashScreenManuallyTimeout'
            ])
            .then(function(timeout) {
                this.manuallyShowTimeout = setTimeout(this.onActivitiesShow.bind(this), timeout * 1000);

                this.updateVisibility(true);
                this.updateData();
            }.bind(this));

        // Bind activities.show after 1 seconds. This is needed in fullscreen-mode.
        setTimeout(function() {
            this.on('activities.show', this.onActivitiesShow.bind(this));
        }.bind(this), 1000);

        this.on('framebox.added', this.onActivitiesShow.bind(this));
    },

    /**
     * Load wlan mode and switch between off, infra and ap.
     */
    updateData: function() {
        this.frontendSettings.getSettings([
            'splashScreenEnabled',
            'splashScreenVisible'
        ])
            .then(function(splashScreenEnabled, visible) {
                var isEnabledDefault = app.getService('Model-View').splashScreenSettingsDefault.enabled;
                var isEnabled = null === splashScreenEnabled ? isEnabledDefault : splashScreenEnabled;

                // RELEASE-3321: init visibilitly = false; setting a frontend setting in initialize was to soon.
                if (this.init) {
                    this.init = false;
                    visible = false;

                    this.updateVisibility(visible);
                }

                this.splashScreenVisible = visible;

                if (platform.checks.isCbox && !platform.checks.isCboxControlScreen && isEnabled) {
                    this.loadData();
                } else {
                    this.$viewer.addClass('is-hidden');
                }
            }.bind(this));
    },

    loadData: function() {
        this.deviceConnection
            .send('getWlanMode')
            .then(function(
                wlanMode
            ) {
                this.frontendSettings
                    .getSettings([
                        'splashScreenShowQrCode',
                        'splashScreenDisplayWlan',
                        'splashScreenDisplayWlanType',
                        'splashScreenDisplayWlanSSID',
                        'splashScreenDisplayWlanPassword',
                        'splashScreenDisplayWlanEncryption'
                    ])
                    .then(function(
                        showQrCode,
                        displayWlan,
                        wlanType,
                        wlanSSid,
                        wlanPassword,
                        wlandEncryption
                    ) {
                        this.frontendSettingsData = {
                            'showQRCode': showQrCode,
                            'displayWlan': displayWlan,
                            'wlanType': wlanType,
                            'wlanSSID': wlanSSid,
                            'wlanPassword': wlanPassword,
                            'wlanEncryption': wlandEncryption
                        };

                        if ('custom' === wlanType) {
                            this.loadCustomWlanData();
                        } else if ('access-point' === wlanMode.wlanMode) {
                            this.loadAccessPointData(wlanMode.wlanMode);
                        } else if ('infrastructure' === wlanMode.wlanMode) {
                            this.loadInfrastructureData(wlanMode.wlanMode);
                        } else { // 'off' === wlanMode.wlanMode
                            this.clearWlanData();
                            this.checkVisibility(wlanMode.wlanMode);
                        }

                        this.checkWlanContainerVisibility();
                    }.bind(this));
            }.bind(this));
    },

    /**
     * Clear WLAN data and delete QR-Code.
     * Otherwise the old data is still shown when triggering Splash Screen manually.
     */
    clearWlanData: function() {
        this.data = {
            wlan: {
                ssid: '',
                password: '',
                encryption: 'NONE'
            }
        };

        this.renderData(this.data, '');

        this.$qrCode
            .html('')
            .removeAttr('title');
    },

    /**
     * Load all data to show infrastructure.
     *
     * @param {String} wlanMode
     */
    loadInfrastructureData: function(wlanMode) {
        this.deviceConnection
            .send([
                'getInfraSsidName',
                'getWlanEncryptionStandard',
                'getKeyInfrastructure',
                'getAirplayState',
                'getChromecastState',
                'getMiracastState',
                'getMiceState',
                'getVconnectState'
            ])
            .then(function(
                ssidName,
                encryptionStandard,
                keyInfrastructure,
                airplayState,
                chromecastState,
                miracastState,
                miceState,
                vconnectState
            ) {
                var windowsState;

                if ((this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) && miracastState.miracastState && !miceState.miceState) {
                    this.$viewer.addClass('is-mice-enabled');

                    windowsState = {
                        both: 0,
                        miracastMiceState: 0,
                        miracastState: 0,
                        vconnectState: vconnectState.vconnectState
                    };
                } else {
                    windowsState = this.getWindowsState(miceState.miceState, miracastState.miracastState, vconnectState.vconnectState);
                }

                this.data = {
                    wlan: {
                        ssid: ssidName.ssidName,
                        password: keyInfrastructure.passwordInfrastructure,
                        encryption: encryptionStandard.encryptionStandard
                    },
                    mirror: {
                        airplayState: airplayState.airplayState,
                        chromecastState: chromecastState.chromecastState,
                        windowsState: windowsState
                    }
                };

                this.renderData(this.data, '');
                this.updateHeadlineSubtext();
                this.generateQrCode();
                this.checkVisibility(wlanMode);
                this.checkWlanPasswordVisibility(encryptionStandard.encryptionStandard);
            }.bind(this));
    },

    /**
     * Return the combined state for Miracast and vConnect (= vSolution Cast)
     *
     * @param miceState 0: enabled, 1: disabled
     * @param miracastState 0: enabled, 1: disabled
     * @param vconnectState 0: enabled, 1: disabled
     */
    getWindowsState: function(miceState, miracastState, vconnectState) {
        if (miceState && miracastState && vconnectState) {
            this.$viewer.addClass('is-mice-enabled');

            return {
                both: 1,
                miracastMiceState: 0,
                miracastState: 0,
                vconnectState: 0
            };
        }

        // If miracast state is 0, add class to update width
        if (!miracastState || miceState) {
            this.$viewer.addClass('is-mice-enabled');
        } else {
            this.$viewer.removeClass('is-mice-enabled');
        }

        return {
            both: 0,
            miracastMiceState: (miceState && miracastState),
            vconnectState: vconnectState,
            miracastState: miracastState
        };
    },

    /**
     * Update healdine depending on enabled/disabled mirror services.
     */
    updateHeadlineSubtext: function() {
        var subtext = [];

        if (this.data.mirror.airplayState) {
            subtext.push(i18n.t('splash_screen.apple'));
        }

        if (this.data.mirror.chromecastState) {
            subtext.push(' ' + i18n.t('splash_screen.android'));
        }

        if (this.data.mirror.windowsState.both
            || this.data.mirror.windowsState.miracastMiceState
            || this.data.mirror.windowsState.vconnectState) {
            subtext.push(' ' + i18n.t('splash_screen.windows'));
        }

        if (this.data.mirror.windowsState.miracastState) {
            this.$el.find('#splash-screen-headline-window').find('.splash-screen-subtitle')
                .text(i18n.t('splash_screen.windows_8_1_later'));
        }

        this.$el.find('#splash-screen-headline-other').find('.splash-screen-subtitle').text(subtext);
    },

    /**
     * Load all data to show access-point.
     *
     * @param {String} wlanMode
     */
    loadAccessPointData: function(wlanMode) {
        this.deviceConnection
            .send([
                'getApSsidName',
                'getWlanApEncryptionStandard',
                'getPasswordAccesspoint',
                'getAirplayState',
                'getChromecastState',
                'getMiracastState',
                'getMiceState',
                'getVconnectState'
            ])
            .then(function(
                ssidName,
                encryptionStandard,
                keyAccesspoint,
                airplayState,
                chromecastState,
                miracastState,
                miceState,
                vconnectState
            ) {
                this.data = {
                    wlan: {
                        ssid: ssidName.ssidName,
                        password: keyAccesspoint.keyAccesspoint,
                        encryption: encryptionStandard.encryptionStandard
                    },
                    mirror: {
                        airplayState: airplayState.airplayState,
                        chromecastState: chromecastState.chromecastState,
                        windowsState: this.getWindowsState(miceState.miceState, miracastState.miracastState, vconnectState.vconnectState)
                    }
                };

                this.renderData(this.data, '');
                this.updateHeadlineSubtext();
                this.generateQrCode();
                this.checkWlanPasswordVisibility(encryptionStandard.encryptionStandard);
                this.checkVisibility(wlanMode);
            }.bind(this));
    },

    /**
     * Load all data to show access-point.
     *
     * @param {String} wlanMode
     */
    loadCustomWlanData: function(wlanMode) {
        this.deviceConnection
            .send([
                'getAirplayState',
                'getChromecastState',
                'getMiracastState',
                'getMiceState',
                'getVconnectState'
            ])
            .then(function(
                airplayState,
                chromecastState,
                miracastState,
                miceState,
                vconnectState
            ) {
                var windowsState;

                if ((this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) && miracastState.miracastState && !miceState.miceState) {
                    this.$viewer.removeClass('is-mice-enabled');

                    windowsState = {
                        both: 0,
                        miracastMiceState: 0,
                        miracastState: 1,
                        vconnectState: vconnectState.vconnectState
                    };
                } else {
                    windowsState = this.getWindowsState(miceState.miceState, miracastState.miracastState, vconnectState.vconnectState);
                }

                this.data = {
                    wlan: {
                        ssid: this.frontendSettingsData.wlanSSID,
                        password: this.frontendSettingsData.wlanPassword,
                        encryption: this.frontendSettingsData.wlanEncryption
                    },
                    mirror: {
                        airplayState: airplayState.airplayState,
                        chromecastState: chromecastState.chromecastState,
                        windowsState: windowsState
                    }
                };

                this.renderData(this.data, '');
                this.updateHeadlineSubtext();
                this.generateQrCode();
                this.checkWlanPasswordVisibility(this.frontendSettingsData.wlanEncryption);
                this.checkVisibility(wlanMode);
            }.bind(this));
    },

    /**
     * Check if overlay is visible or hidden.
     */
    checkVisibility: function(wlanMode) {
        if (platform.checks.isCbox && !platform.checks.isCboxControlScreen
            && !this.stationService.getPushStatus() && !this.stationService.getLimitedAccessStatus()
            && !this.annotationService.isActiveOnOutput() && !this.annotationService.isMagicPenActive()
            && (
                (0 === this.frameBoxService.getNumberOpenFrameboxes() && 'off' !== wlanMode)
                || null !== this.manuallyShowTimeout || this.splashScreenVisible
            )
        ) {
            this.$viewer.removeClass('is-hidden');
            app.$el.addClass('splash-screen-shown'); // RELEASE-3803
        } else {
            this.$viewer.addClass('is-hidden');
            app.$el.removeClass('splash-screen-shown');
        }
    },

    checkWlanContainerVisibility: function() {
        this.$spacerContainer.removeClass('is-hidden');

        this.checkQrCodeVisibility();
        this.checkWlanVisibility();
    },

    /**
     * Check if qr-code is visible or hidden.
     */
    checkQrCodeVisibility: function() {
        if (true === this.frontendSettingsData.showQRCode) {
            this.$qrCodeContainer.removeClass('is-hidden');
        } else {
            this.$qrCodeContainer.addClass('is-hidden');
            this.$spacerContainer.addClass('is-hidden');
        }
    },

    /**
     * Check if qr-code is visible or hidden.
     */
    checkWlanVisibility: function() {
        if (true === this.frontendSettingsData.displayWlan) {
            this.$wlanContainer.removeClass('is-hidden');
        } else {
            this.$wlanContainer.addClass('is-hidden');
            this.$spacerContainer.addClass('is-hidden');
        }
    },

    /**
     * Check if overlay is visible or hidden.
     */
    checkWlanPasswordVisibility: function(encryption) {
        if ('none' === encryption) {
            this.$wlanPassword.addClass('is-hidden');
        } else {
            this.$wlanPassword.removeClass('is-hidden');
        }
    },

    /**
     * Set data to the placeholder.
     *
     * @param {object} data
     * @param {string} path
     */
    renderData: function(data, path) {
        for (var key in data) {
            var value = data[key];

            if ('object' === typeof value) {
                this.renderData(value, path + key + '.');
            } else {
                this.$el.find('[data-view-path="' + path + key + '"]')
                    .html(value)
                    .trigger('dynamicfont.resize');

                if (value) {
                    this.$el.find('[data-show-path="' + path + key + '"]').removeClass('is-hidden');
                } else {
                    this.$el.find('[data-show-path="' + path + key + '"]').addClass('is-hidden');
                }
            }
        }

        // Update headlines (if there are mirror services enabled - show headline 2, else only headline 1)
        var mirrorServices = $.grep(this.$el.find('.splash-screen-connection-container'), function(el) {
            return !$(el).hasClass('is-hidden');
        }.bind(this));

        if (mirrorServices.length > 0) {
            this.$headlineShare.removeClass('hidden');
            this.$headlineConnect.find('.splash-screen-headline-count').removeClass('hidden');
        } else {
            this.$headlineShare.addClass('hidden');
            this.$headlineConnect.find('.splash-screen-headline-count').addClass('hidden');
        }
    },

    /**
     * Generates the new qr-code for the access-point data.
     */
    generateQrCode: function() {
        var size = this.$el.find('#splash-screen-qr-code-container').width();
        var encryption = this.data.wlan.encryption.toUpperCase();
        var password = this.data.wlan.password;

        if ('NONE' === encryption) {
            encryption = 'nopass';
            password = '';
        }

        // 'WIFI:T:WPA;S:SSID_NAME;P:PASSWORD;;' network with password
        // 'WIFI:T:nopass;S:SSID_NAME;P:;;' network without password
        var text = 'WIFI:T:' + encryption
            + ';S:' + this.data.wlan.ssid
            + ';P:' + password
            + ';;';

        this.$qrCode.html('');
        this.$wlanContainer
            .css({
                minHeight: this.$el.find('#splash-screen-wlan-container').outerWidth()
            });
        new QRCode('qr-code-wifi', {
            text: text,
            width: size,
            height: size,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.L
        });
    }
});
