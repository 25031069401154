'use strict';

const app = require('../../../app');
const StateMachine = require('../../../state-machine');
const pingStates = require('../../../states').pingStates;

const pingTemplate = require('./ping.html');
const vueUtils = require('../../../components-vue/util');

app.component('Ping', {
    engine: 'vue',
    template: pingTemplate,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    data: function() {
        return {
            defaultAddress: '0.0.0.0',
            pingState: {},
            response: ''
        };
    },

    computed: {
        isBusy: function() {
            return this.pingState.getState() === pingStates.start
                || this.pingState.getState() === pingStates.started;
        },

        isSuccessful: function() {
            return this.pingState.getState() === pingStates.succeeded;
        },

        isFailed: function() {
            return this.pingState.getState() === pingStates.failed;
        }
    },

    methods: {
        ping: function() {
            this.pingState.changeState(pingStates.start);
        },

        stopPolling: function() {
            if (this.pollInterval) {
                clearInterval(this.pollInterval);
                this.pollInterval = undefined;
            }
        },

        addStateTransitions: function() {
            this.pingState.addTransitions({
                '> start': function() {
                    this.response = '';
                    const address = this.$refs.destination.value;

                    this.wolfprot.talk('setPing', {
                        interface: this.component.options.configs.interface,
                        destination: address !== '' ? address : this.defaultAddress
                    });

                    if (this.pollInterval) {
                        clearInterval(this.pollInterval);
                    }
                    this.pollInterval = setInterval(this.pollHelper.schedulePoll, 1000);
                },
                '> stopped': function() {
                    this.stopPolling();
                },
                '> failed': function() {
                    this.stopPolling();
                },
                '> succeeded': function() {
                    this.stopPolling();
                }
            });
        },

        i18n: vueUtils.i18n
    },

    created: function() {
        this.pingState = new StateMachine({
            context: this,
            state: pingStates.none,
            states: pingStates
        });

        this.addStateTransitions();

        this.wolfprot = vueUtils.wolfprot();
        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return this.wolfprot.talk('getPing');
            }.bind(this)
        });
        this.pollHelper.on('data', function(result) {
            switch (result.status) {
                case pingStates.started:
                    this.pingState.changeState(pingStates.started);
                    break;
                case pingStates.stopped:
                    this.pingState.changeState(pingStates.stopped);
                    this.response = result.response;
                    break;
                case pingStates.failed:
                    this.pingState.changeState(pingStates.failed);
                    this.response = result.response;
                    break;
                case pingStates.succeeded:
                    this.pingState.changeState(pingStates.succeeded);
                    this.response = result.response;
                    break;
                default:
                    this.pingState.changeState(pingStates.none);
                    break;
            }
        }.bind(this));
    },

    destroyed: function() {
        this.stopPolling();
    }
});
