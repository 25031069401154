'use strict';

const app = require('../app');
const $ = require('jquery');
const store = require('../store/store');

/**
 * Models
 */
const models = {
    cbox: 'CB1',
    cboxCore: 'CBC',
    cboxPure: 'CBP',
    cboxPurePro: 'CPP',
    cboxCorePro: 'CCP',
    cboxPureReceiver: 'CPR',
    cboxPro: 'CBR',
    cboxPureMini: 'CPM',
    cboxVideoBar: 'CVB'
};

const hwTypes = {
    none: 0x00,
    nuc7: 0x01,
    nuc8: 0x02,
    giada7: 0x03,
    aaeon8: 0x04,
    mitac: 0x05,
    nuc8i5: 0x06,
    nuc11: 0x07,
    livaq2: 0x08
};

app.service('DeviceService', function(app) {
    return {

        /**
         * @method initialize
         */
        initialize: function() {
            this.model = undefined;
            this.boxName = '';
            this.activeSession = {};
            this.features = {};
            this.frontendSettings = app.getService('FrontendSettings');

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                }.bind(this));
        },

        bindEvents: function() {
            app.on('main-loop.update', this.updateHandler.bind(this));
        },

        /**
         * Update handler.
         */
        updateHandler: function() {
            this.updatePresentationId();
            this.updateUiScaling();
            this.updateFeatures();
        },

        /**
         * Update presentation ID.
         */
        updatePresentationId: function() {
            var dfd = $.Deferred();

            this.deviceConnection
                .send('getPresentationId')
                .then(function(presentation) {
                    if (this.presentationId !== presentation.id) {
                        this.presentationId = presentation.id;
                    }

                    dfd.resolve();
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Update custom UI setting for scaling in Vuex store.
         */
        updateUiScaling: function() {
            store.dispatch('uiSettings/fetchUiScalingSize', app);
        },

        updateFeatures: function() {
            return this.deviceConnection
                .send('getLicenseFeatures')
                .then(function(features) {
                    this.features = features;
                }.bind(this));
        },

        /**
         * @method initDevice
         */
        initDevice: function() {
            this.loadModel();
        },

        /**
         * @method loadModel
         */
        loadModel: function() {
            this.deviceConnection
                .send([
                    'getModel',
                    'getBoxName',
                    'getDualProjectionEnable'
                ])
                .then(function(model, boxName, dualProjection) {
                    this.model = model.model;
                    this.boxName = boxName.boxName;
                    this.hwType = model.hwType;
                    this.dualProjectionEnabled = dualProjection.enable;

                    this.loadFrontendSettings();
                    this.bindEvents();

                    if (this.dualProjectionEnabled) {
                        app.$el.addClass('dual-projection-mode');
                    }
                }.bind(this));
        },

        /**
         * After a session was started a page reload appears, therefore the previous saved settings needs to be loaded
         * from the frontend settings file.
         */
        loadFrontendSettings: function() {
            var dfd = $.Deferred();

            this.frontendSettings
                .getSettings([
                    'activeSessionId',
                    'activeSessionPin'
                ])
                .then(function(id, pin) {
                    if (typeof this.activeSession.id !== 'undefined'
                        && id.toString() === this.activeSession.id.toString()) {
                        this.activeSession.pwd = pin;
                    }
                    dfd.resolve();
                }.bind(this));

            return dfd.promise();
        },

        isCboxPro: function() {
            return (this.model === models.cboxPro);
        },

        isCbox: function() {
            return (this.model === models.cbox) || (this.model === models.cboxPro);
        },

        isCboxCore: function() {
            return (this.model === models.cboxCore);
        },

        isCboxCorePro: function() {
            return (this.model === models.cboxCorePro);
        },

        isCboxCoreOrCorePro: function() {
            return (this.model === models.cboxCore || this.model === models.cboxCorePro);
        },

        isCboxCoreOrCoreProOrPurePro: function() {
            return (this.model === models.cboxCore
                || this.model === models.cboxCorePro
                || this.model === models.cboxPurePro
                || this.model === models.cboxVideoBar); // HACK: for now treat CVB like CPP
        },

        isCboxPurePro: function() {
            return (this.model === models.cboxPurePro
                || this.model === models.cboxVideoBar); // HACK: for now treat CVB like CPP
        },

        isCboxPure: function() {
            return (this.model === models.cboxPure
                || this.model === models.cboxPureReceiver);
        },

        isCboxPureReceiver: function() {
            return (this.model === models.cboxPureReceiver);
        },

        isCboxPureOrPro: function() {
            return (this.model === models.cboxPure
                || this.model === models.cboxPurePro
                || this.model === models.cboxPureReceiver
                || this.model === models.cboxPureMini
                || this.model === models.cboxVideoBar); // HACK: for now treat CVB like CPP
        },

        isCboxPureMini: function() {
            return (this.model === models.cboxPureMini);
        },

        isCboxProDualProjection: function() {
            return this.model === models.cboxPro && this.dualProjectionEnabled;
        },

        isCboxNonDualProjection: function() {
            return (this.model === models.cbox || this.model === models.cboxPro) && !this.dualProjectionEnabled;
        },

        isCboxVideoBar: function() {
            return (this.model === models.cboxVideoBar);
        },

        /**
         * Return the device model.
         *
         * @returns {string}
         */
        getModel: function() {
            return this.model;
        },

        /**
         * Returns the device hw type (giada, aaeon, nuc7, nuc8, ...).
         *
         * @returns {string}
         */
        getHwType: function() {
            return this.hwType;
        },

        /**
         * Returns if the device is SDM Module.
         *
         * @returns {boolean}
         */
        isSDM: function() {
            return (this.model === models.cboxPure && (this.hwType === hwTypes.giada7 || this.hwType === hwTypes.aaeon8));
        },

        /**
         * Returns if the device is a Core Pro with a Mitac mainboard.
         *
         * @returns {boolean}
         */
        isCboxCoreProMitac: function() {
            return (this.model === models.cboxCorePro && this.hwType === hwTypes.mitac);
        },

        /**
         * Returns if the device is on LivaQ2 hardware platform.
         *
         * @returns {boolean}
         */
        isLivaQ2Hardware: function() {
            return this.hwType === hwTypes.livaq2;
        },

        /**
         * Store the id and password of the active session.
         *
         * @param id {string}
         * @param pwd {string}
         */
        setActiveSession: function(id, pwd) {
            this.activeSession.id = id;
            this.activeSession.pwd = pwd;
        },

        /**
         * Returns the active session.
         */
        getActiveSession: function() {
            var dfd = $.Deferred();

            if (typeof this.activeSession.pwd === 'undefined') {
                this.loadFrontendSettings()
                    .then(function() {
                        dfd.resolve({
                            error: false,
                            activeSession: this.activeSession
                        });
                    }.bind(this));
            } else {
                dfd.resolve({
                    error: false,
                    activeSession: this.activeSession
                });
            }

            return dfd.promise();
        },

        /**
         * Returns the active session id.
         */
        getActiveSessionId: function() {
            return this.activeSession.id;
        },

        /**
         * Returns the boxname.
         */
        getBoxName: function() {
            return this.boxName;
        },

        getFeatures: function() {
            return this.features;
        }
    };
});
