// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"overlay-header-update-notification\"\n     class=\"overlay-header-update-notification\"\n     data-nav-area=\".focusable\"\n     data-nav-trapped>\n    <div class=\"row\">\n        <div class=\"col-xs-2\" />\n        <div class=\"col-xs-10 fw-update-header\" >\n            <div class=\"col-xs-2\"/>\n            <div class=\"col-xs-8\">\n                <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.fw_update_notification",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":22},"end":{"line":10,"column":61}}}))
    + "</span>\n            </div>\n            <div class=\"col-xs-1\">\n                <button class=\"btn btn-no-style btn-no-active pull-right overlay-header-item overlay-action-btn focusable\"\n                        data-action=\"ignore\">\n                    <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ignore",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":26},"end":{"line":15,"column":49}}}))
    + "</span>\n                </button>\n            </div>\n            <div class=\"col-xs-1\">\n                <button class=\"btn btn-no-style btn-no-active pull-right overlay-header-item overlay-action-btn focusable\"\n                        data-action=\"update\">\n                    <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.update",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":26},"end":{"line":21,"column":49}}}))
    + "</span>\n                </button>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});
