'use strict';

/**
 * RequestAnimationFrame() shim by Paul Irish
 */
window.requestAnimationFrame = (function() {
    return  window.requestAnimationFrame
            ||       window.webkitRequestAnimationFrame
            || window.mozRequestAnimationFrame
            ||    window.oRequestAnimationFrame
            ||      window.msRequestAnimationFrame;
})();

/**
 * CancelAnimationFrame() shim not by Paul Irish ;)
 */
window.cancelAnimationFrame = (function() {
    return  window.cancelAnimationFrame
            ||              window.webkitCancelAnimationFrame
            ||        window.webkitCancelRequestAnimationFrame
            || window.mozCancelRequestAnimationFrame
            ||    window.oCancelRequestAnimationFrame
            ||      window.msCancelRequestAnimationFrame;
})();
