'use strict';

var _ = require('lodash');
var $ = require('jquery');

var frameBoxTpl = require('./framebox-view.hbs');

function FrameBoxView(app, options) {
    /**
     * @type {Object}
     */
    this.options = options;

    /**
     * App reference.
     *
     * @type {Object}
     */
    this.app = app;

    /**
     * JQuery Reference to component element.
     *
     * @type {jQuery.Element}
     */
    this.$el = $('<div/>', {
        'class': 'framebox',
        'data-index': this.options.index
    });

    /**
     * Framebox template.
     *
     * @type {Function}
     */
    this.template = frameBoxTpl;

    /**
     * @type {String}
     */
    this.uiComponentId = null;
}

/**
 * Removes the view and the used component instance.
 *
 * @chainable
 */
FrameBoxView.prototype.destroy = function destroy() {
    this.app.removeComponent(this.uiComponentId);

    this.uiComponentId = null;

    this.$el.off();
    this.$el.remove();

    return this;
};

/**
 * Render the view and initialize the used component.
 *
 * @param {Object} data Template data.
 */
FrameBoxView.prototype.render = function render(data) {
    var tpl = this.template(data);
    this.$el.html(tpl);

    if (!this.uiComponentId) {
        this.startComponent();
    }

    return this;
};

/**
 * Creates the needed frame component and stores references.
 */
FrameBoxView.prototype.startComponent = function startComponent() {
    this.uiComponentId = _.uniqueId('frameBoxUI' + this.options.contentType);

    this.app.createComponent(this.uiComponentId, {
        type: 'FrameBoxUI',
        container: this.$el.find('.framebox-container'),
        index: this.options.index,
        appId: this.options.appId,
        titleKey: this.options.titleKey,
        titleIconKey: this.options.titleIconKey,
        component: this.options.component,
        webRtcOut: this.options.webRtcOut,
        customClose: this.options.customClose,
        contentType: this.options.contentType,
        frameBoxOptions: this.options.frameBoxOptions,
        focus: this.options.focus
    });
};

/**
 * After the view has been rendered place it inside the dom
 *
 * @param  {String} container Container element for the view
 * @chainable
 */
FrameBoxView.prototype.append = function append(container) {
    this.app.$(container).append(this.$el);

    return this;
};

/**
 * Set the css position attributes for the framebox.
 *
 * @param {Object} coordinates
 * @param {Number} coordinates.y
 * @param {Number} coordinates.x
 * @param {Number} coordinates.width
 * @param {Number} coordinates.height
 */
FrameBoxView.prototype.setPosition = function setPosition(coordinates) {
    this.$el.css(this.getPositionOptions(coordinates));
};

/**
 * Calculates the position and size for the framebox and adds a little space if a small gap is needed. For example
 * in the control-mode or when the framebox is not focused.
 * See RELEASE-1249 and RELEASE-1395.
 * UPDATE UI 3.0 (no colored border anymore per framebox): Fixed position and size of framebox. See RELEASE-4390
 *
 * @param {Object} coordinates
 * @param {Number} coordinates.y
 * @param {Number} coordinates.x
 * @param {Number} coordinates.width
 * @param {Number} coordinates.height
 *
 * @return {Object}
 */
FrameBoxView.prototype.getPositionOptions = function getPosition(coordinates) {
    var positionOptions = {
        top: Math.floor(coordinates.y),
        left: Math.floor(coordinates.x),
        width: Math.ceil(coordinates.width),
        height: Math.ceil(coordinates.height)
    };

    return positionOptions;
};

module.exports = FrameBoxView;
