const template = require('./layout1.html');
const vueUtils = require('../../../components-vue/util.js');
const app = require('../../../app.js');
const platform = require('../../../../platform/platform');

module.exports = {
    template,

    props: {
        components: {
            type: Array,
            required: true
        },

        showGroupworkButton: {
            type: Boolean,
            required: true
        },

        showDownloadAppButton: {
            type: Boolean,
            required: true
        },

        showRoomControllerButton: {
            type: Boolean,
            required: true
        },

        showSaveUserSettingsButton: {
            type: Boolean,
            required: true
        },

        showSplashScreenButton: {
            type: Boolean,
            required: true
        },

        showCloseAllWindowsButton: {
            type: Boolean,
            required: true
        },

        showQuicktoolControls: {
            type: Boolean,
            required: true
        },

        limited: {
            type: Boolean
        },

        audioMode: {
            type: Boolean
        },

        focusable: {
            type: Boolean,
            required: true
        },

        numberCustomCommandButtons: {
            type: Number,
            required: true
        }
    },

    data: function() {
        return {
        };
    },

    computed: {
        componentsWithoutCustomCommands: function() {
            return this.components.filter((comp) => {
                return comp.type !== 'custom-command-controls';
            });
        },

        largeComponents: function() {
            return this.components.filter((comp) => {
                return [
                    'custom-command-controls',
                    'pip-controls',
                    'mirroring-controls'
                ].indexOf(comp.type) < 0;
            });
        },

        specialCaseCustomCommands: function() {
            return (this.largeComponents.length === 3
                || this.largeComponents.length === 0
                || this.componentsWithoutCustomCommands.length === 1)
           && this.numberCustomCommandButtons >= 3;
        },

        componentsFiltered: function() {
            if (this.specialCaseCustomCommands) {
                return this.componentsWithoutCustomCommands;
            } else {
                return this.components;
            }
        },

        componentsOrdered: function() {
            let result;

            if (this.largeComponents.length <= 3 || this.componentsFiltered.length <= 3) {
                result = [
                    this.componentsFiltered[0],
                    null,
                    this.componentsFiltered[1],
                    this.componentsFiltered[2],
                    ...this.componentsFiltered.slice(3)
                ];
            } else {
                result = this.componentsFiltered;
            }

            const isSmallBlock = (block) => {
                const type = block ? block.type : undefined;

                return [
                    'pip-controls',
                    'mirroring-controls'
                ].indexOf(type) >= 0 || (type === 'custom-command-controls' && this.numberCustomCommandButtons <= 2);
            };

            let resultWithPadding = [];

            // If there are two small blocks at the end that would wrap, we align them in the same column
            for (let i = 0; i < result.length; i += 1) {
                const isIndexBeforeWrap = i >= 3 && (i % 2) === 1;
                const hasBlockAfter = (i + 1) < result.length;
                const currentAndNextAreSmallBlocks = hasBlockAfter
                    && isSmallBlock(result[i])
                    && isSmallBlock(result[i + 1]);

                if (isIndexBeforeWrap && currentAndNextAreSmallBlocks) {
                    resultWithPadding = [
                        ...resultWithPadding,
                        result[i],
                        null
                    ];
                } else {
                    resultWithPadding = [
                        ...resultWithPadding,
                        result[i]
                    ];
                }
            }

            return resultWithPadding;
        },

        anyComponentOnLeftSide: function() {
            return this.componentsOrdered.slice(0, 2).some((comp) => comp);
        },

        anyComponentOnRightSide: function() {
            return this.componentsOrdered.slice(2).some((comp) => comp);
        },

        focusArea: function() {
            return this.focusable ? '.is-focusable:not(.disabled)' : '';
        }
    },

    methods: {
        /**
         * Close the control center
         */
        close: function() {
            this.$emit('close');
        },

        /**
         * Show splash screen and close control center
         */
        showSplashScreen: function() {
            if (platform.checks.isCbox) {
                app.emit('splash-screen.show');
            } else {
                this.wolfprot.talk('setSplashScreenTrigger');
            }

            this.$emit('close');
        },

        getComponentProperty: function(index, prop) {
            return this.componentsOrdered[index] ? this.componentsOrdered[index][prop] : undefined;
        },

        i18n: vueUtils.i18n
    },

    created: function() {
        this.wolfprot = vueUtils.wolfprot();
    },

    components: {
        'command-button': require('../../../components-vue/control-center-v3/command-button/command-button.js'),
        'vmeeting-controls': require('../../../components-vue/control-center-v3/vmeeting-controls/vmeeting-controls.js'),
        'recording-controls': require('../../../components-vue/control-center-v3/recording-controls/recording-controls.js'),
        'streaming-controls': require('../../../components-vue/control-center-v3/streaming-controls/streaming-controls.js'),
        'mirroring-controls': require('../../../components-vue/control-center-v3/mirroring-controls/mirroring-controls.js'),
        'pip-controls': require('../../../components-vue/control-center-v3/pip-controls/pip-controls.js'),
        'volume-controls': require('../../../components-vue/control-center-v3/volume-controls/volume-controls.js'),
        'custom-command-controls': require('../../../components-vue/control-center-v3/custom-command-controls/custom-command-controls.js'),
        'quicktool-controls': require('../../../components-vue/control-center-v3/quicktool-controls/quicktool-controls.js'),
        'general-controls': require('../../../components-vue/control-center-v3/general-controls/general-controls.js')
    }
};
