// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return " no-header";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"col-xs-2 col-xs-3-phone\">\n                <div id=\"current-overlay-title\" class=\"overlay-name title-left overlay-header-item ellipsis\">\n                    "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"overlayTitle") : stack1),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":44}}}))
    + "\n                </div>\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "col-xs-10 col-xs-9-phone";
},"7":function(container,depth0,helpers,partials,data) {
    return "col-xs-12";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button id=\"overlay-history-back\"\n                            class=\"btn btn-no-style btn-no-active overlay-header-item overlay-back-btn focusable\">\n                        <span class=\"icon-btn-back\"></span>\n                        "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"back") : stack1),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":40}}}))
    + "\n                    </button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div id=\"overlay-action\" class=\"overlay-action-item col-xs-12 flex\">\n                            <p class=\"overlay-action-title\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"actionSwitchTitle") : stack1),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":60},"end":{"line":33,"column":88}}}))
    + "</p>\n                            <div>\n                                <div class=\"btn-switch-container pull-right\">\n                                    <input name=\"actionSwitch\"\n                                           id=\"actionSwitch\"\n                                           type=\"checkbox\"\n                                           class=\"btn-switch focusable\"/>\n                                    <div class=\"btn-switch-toggle-container\">\n                                        <div class=\"btn-switch-toggle\"></div>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div id=\"overlay-action\" class=\"overlay-action-item\">\n                            <button id=\"overlay-action-button\"\n                                    class=\"btn btn-no-style btn-no-active overlay-header-item overlay-action-btn overlay-action-btn-"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"actionBtnType") : stack1), depth0))
    + " ellipsis\">\n                                "
    + alias1((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"actionButtonKey") : stack1),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":32},"end":{"line":52,"column":59}}}))
    + "\n                            </button>\n                        </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div id=\"overlay-save-btn\" class=\"overlay-save-btn overlay-action-item\">\n                            <button id=\"overlay-action-button-save\"\n                                    class=\"btn btn-no-style btn-no-active overlay-header-item focusable ellipsis\">\n                                "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.action_button_save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":32},"end":{"line":61,"column":67}}}))
    + "\n                            </button>\n                        </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div id=\"overlay-disconnect-btn\" class=\"overlay-disconnect-btn overlay-action-item\">\n                            <button id=\"overlay-action-button-disconnect\"\n                                    class=\"btn btn-no-style btn-no-active overlay-header-item focusable ellipsis\">\n                                "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"closeButton") : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":74,"column":32},"end":{"line":74,"column":124}}})) != null ? stack1 : "")
    + "\n                            </button>\n                        </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.logout_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":56},"end":{"line":74,"column":85}}}));
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"files.disconnect",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":93},"end":{"line":74,"column":117}}}));
},"22":function(container,depth0,helpers,partials,data) {
    return "                        <div id=\"overlay-close-btn\" class=\"overlay-close-btn overlay-action-item\">\n                            <button\n                                class=\"btn btn-no-style btn-no-active overlay-header-item overlay-action-btn focusable\">\n                                <span class=\"icon-close\"></span>\n                            </button>\n                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"overlay-header\" class=\"overlay-header"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"hideHeader") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":46},"end":{"line":1,"column":86}}})) != null ? stack1 : "")
    + "\" data-nav-area=\".focusable\" data-nav-trapped>\n    <div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"overlayTitle") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":9,"column":16}}})) != null ? stack1 : "")
    + "\n        <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"overlayTitle") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":11,"column":94}}})) != null ? stack1 : "")
    + "\">\n            <div class=\"col-xs-4\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"back") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":19,"column":24}}})) != null ? stack1 : "")
    + "            </div>\n\n            <div class=\"col-xs-4\">\n                <div id=\"current-overlay-name\"\n                     class=\"overlay-name overlay-header-item ellipsis\">\n                    "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"titleKey") : stack1),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":20},"end":{"line":25,"column":40}}}))
    + "\n                </div>\n            </div>\n\n            <div class=\"col-xs-4\">\n                <div class=\"overlay-action-items\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"showActionSwitch") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":46,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"actionButtonKey") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":20},"end":{"line":55,"column":28}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"saveButton") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":20},"end":{"line":64,"column":28}}})) != null ? stack1 : "")
    + "\n                    <div id=\"overlay-loader\" class=\"overlay-loader\">\n                        <div class=\"loader small\"></div>\n                    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"disconnectButton") : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":20},"end":{"line":77,"column":28}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"closeButton") : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":20},"end":{"line":86,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});
