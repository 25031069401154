/* eslint-disable */

'use strict';

const wolfProtCodes = require('./wolfprot-codes');
const StringConvert = require('../../../string-convert');
const states = require('../../../app/states');
const _ = require('lodash');
const { pad, stringToBufferWithLength } = require('../../../app/helper');
const helper = require('../../../app/helper');

const wolfProtHeaders = wolfProtCodes.headers;
const wolfProtCommands = wolfProtCodes.commands;
const wolfProtParameters = wolfProtCodes.parameters;
const wolfProtUserLevels = wolfProtCodes.userLevels;

const CONTENT_MAPPING = {
    'Visualizer': wolfProtParameters.WPP_CONTENT_VZ,
    'Browser': wolfProtParameters.WPP_CONTENT_BROWSER,
    'HDMI': wolfProtParameters.WPP_CONTENT_HDMI,
    'Image': wolfProtParameters.WPP_CONTENT_IMAGE,
    'Video': wolfProtParameters.WPP_CONTENT_VIDEO,
    'Whiteboard': wolfProtParameters.WPP_CONTENT_WHITEBOARD,
    'Audio': wolfProtParameters.WPP_CONTENT_AUDIO,
    'miracast': wolfProtParameters.WPP_CONTENT_MIRACAST,
    'calc': wolfProtParameters.WPP_CONTENT_OFFICE_CALC,
    'presentation': wolfProtParameters.WPP_CONTENT_OFFICE_PRESENTATION,
    'text': wolfProtParameters.WPP_CONTENT_OFFICE_TEXT,
    'pdf': wolfProtParameters.WPP_CONTENT_PDF,
    'webconference': wolfProtParameters.WPP_CONTENT_WEBCONFERENCE,
    'streaminput': wolfProtParameters.WPP_CONTENT_STREAM_INPUT,
    'webcam': wolfProtParameters.WPP_CONTENT_WEBCAM,
    'msoutlook': wolfProtParameters.WPP_CONTENT_OFFICE365_OUTLOOK,
    'msword': wolfProtParameters.WPP_CONTENT_OFFICE365_WORD,
    'msexcel': wolfProtParameters.WPP_CONTENT_OFFICE365_EXCEL,
    'mspowerpoint': wolfProtParameters.WPP_CONTENT_OFFICE365_POWERPOINT,
    'msonenote': wolfProtParameters.WPP_CONTENT_OFFICE365_ONENOTE,
    'msteams': wolfProtParameters.WPP_CONTENT_OFFICE365_TEAMS,
    'mswhiteboard': wolfProtParameters.WPP_CONTENT_OFFICE365_WHITEBOARD,
    'msplanner': wolfProtParameters.WPP_CONTENT_OFFICE365_PLANNER,
    'matrix': wolfProtParameters.WPP_FRAMEBOX_CONTENT_MATRIX,
    'matrixControl': wolfProtParameters.WPP_FRAMEBOX_CONTENT_MATRIX_CONTROL_SWITCH,
    'matrixGroupwork': wolfProtParameters.WPP_FRAMEBOX_CONTENT_MATRIX_GROUPWORK,
    'zoom': wolfProtParameters.WPP_FRAMEBOX_CONTENT_ZOOM
};

const OUTPUT_MAPPING = {
    'mirroring': wolfProtParameters.WPP_OUTPUT_HDMI1 | wolfProtParameters.WPP_OUTPUT_HDMI2,
    'hdmi1': wolfProtParameters.WPP_OUTPUT_HDMI1,
    'hdmi2': wolfProtParameters.WPP_OUTPUT_HDMI2,
    'prepare': wolfProtParameters.WPP_OUTPUT_PREPARE,
    'edit': wolfProtParameters.WPP_OUTPUT_EDIT,
    'webrtc': wolfProtParameters.WPP_OUTPUT_WEBRTC,
    'all': wolfProtParameters.WPP_OUTPUT_HDMI1 | wolfProtParameters.WPP_OUTPUT_HDMI2
        | wolfProtParameters.WPP_OUTPUT_PREPARE | wolfProtParameters.WPP_OUTPUT_EDIT
        | wolfProtParameters.WPP_OUTPUT_WEBRTC
};

const POINTER_INPUT_MAPPING = {
    'mousemove': 0,
    'mousedown': 1,
    'mouseup': 2,
    'touchstart': 5,
    'touchmove': 6,
    'touchend': 7,
    'touchcancel': 8
};

/**
 * converts from an ipv4 address string to an int (4 byte)
 *
 * @param string: the string containing the ip address
 * @return: an int containing the ip address
 */
function ipv4AddressString2Int(string) {
    var temp = string.split('.');
    return (parseInt(temp[0], 10) << 24) +
        (parseInt(temp[1], 10) << 16) +
        (parseInt(temp[2], 10) << 8) +
        (parseInt(temp[3], 10));
}

function calcNumBytes(value) {
    for (var i = 0; value >= 1; i++) {
        value = value / 256;
    }

    return i || 1;
}

/*
 * Writes the given value to the given buffer
 *
 * @param value: the value that should be written to the buffer
 * @param buffer: the buffer where the value should be written to
 * @param startPosition: the start position of the buffer
 * @param numBytes: the number of bytes that should be used
 *
 */
function valueToBuffer(value, buffer, startPosition, numBytes) {
    var i;

    for (i = 0; i < numBytes; i++) {
        buffer[i + startPosition] = (value >> ((numBytes - i - 1) * 8)) & 0xFF;
    }
}

/**
 * Collection of all client commands
 */
module.exports = {

    /**
     * Returns a preview picture from the cbox.
     *
     * @param data.pictureSource: 'main' => picture of main output (HDMI1 OUT),
     *                            'mainOsd' => picture of main output including osd
     *                            'hdmi1' => picture of HDMI1 IN
     *                            'hdmi2' => picture of HDMI2 IN
     *                            'vz' => picture of visualizer
     *                            'edit' => picture of window in edit mode
     *                            'hdmi2Out' => picture of HDMI2 OUT
     * @param data.pictureWidth: the requested width of the picture in pixel
     * @param data.pictureHeight: the requested height of the picture in pixel
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getPictureCbox: function getPictureCbox(data, socket) {
        let txData = new Uint8Array(10);
        const cmd = wolfProtCommands.WPC_CB_GET_PICTURE;

        data.pictureWidth = data.pictureWidth || 1920;
        data.pictureHeight = data.pictureHeight || 1080;
        data.pictureSource = data.pictureSource || 0;

        const mapping = {
            'main': wolfProtParameters.WPP_PICTURE_MAIN,
            'mainOsd': wolfProtParameters.WPP_PICTURE_MAIN_OSD,
            'hdmi1': wolfProtParameters.WPP_PICTURE_HDMI1,
            'hdmi2': wolfProtParameters.WPP_PICTURE_HDMI2,
            'vz': wolfProtParameters.WPP_PICTURE_VZ,
            'edit': wolfProtParameters.WPP_PICTURE_EDIT,
            'hdmi2Out': wolfProtParameters.WPP_PICTURE_HDMI2_OUT
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = (cmd) & 0xFF;
        txData[3] = 6;
        txData[4] = (data.pictureWidth >> 8) & 0xFF;
        txData[5] = data.pictureWidth & 0xFF;
        txData[6] = (data.pictureHeight >> 8) & 0xFF;
        txData[7] = data.pictureHeight & 0xFF;
        txData[8] = 0x00;
        txData[9] = mapping[data.pictureSource];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Returns a preview picture of the application window.
     *
     * @param data.appId: ID of the application
     * @param data.pictureWidth: the requested width of the picture in pixel
     * @param data.pictureHeight: the requested height of the picture in pixel
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getPictureApplication: function getPictureApplication(data, socket) {
        let txData = new Uint8Array(17);
        const cmd = wolfProtCommands.WPC_CB_GET_PICTURE;

        data.pictureWidth = data.pictureWidth || 1920;
        data.pictureHeight = data.pictureHeight || 1080;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = (cmd) & 0xFF;
        txData[3] = 0x0D;
        txData[4] = (data.pictureWidth >> 8) & 0xFF;
        txData[5] = data.pictureWidth & 0xFF;
        txData[6] = (data.pictureHeight >> 8) & 0xFF;
        txData[7] = data.pictureHeight & 0xFF;
        txData[8] = 0x00;
        txData.set(helper.fromHexString(data.appId), 9);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the boxname
     *
     * @param data.name: the new boxname
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setBoxName: function setBoxName(data, socket) {
        var name = data.boxname || '';
        var tempArray = StringConvert.stringToUtf8(name);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_BOXNAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = name.length;

        for (var i = 0; i < name.length; ++i) {
            txData[i + 4] = name.charCodeAt(i);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * returns the boxname
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getBoxName: function getBoxName(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_BOXNAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets dhcp of the ethernet interface
     *
     * @param data.dhcpSetting: WPP_DHCP_OFF, WPP_DHCP_ON or WPP_DHCP_TOGGLE
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setEthDhcpSetting: function setEthDhcpSetting(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ETH_DHCP_SETTING;
        data.dhcpSetting = data.dhcpSetting || 0x00;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.dhcpSetting;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the dhcp setting
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEthDhcpSetting: function getEthDhcpSetting(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ETH_DHCP_SETTING;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the dhcp setting
     *
     * @param data: dynamic dhcp
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEthSettingsDhcp: function getEthSettingsDhcp(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ETH_DHCP_SETTING;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.dynamic === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     *
     * sets the ip address of the ethernet interface
     *
     * @param data.ipAddress: the ip address as a string (e.g. '192.168.2.1')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setEthIpAddress: function setEthIpAddress(data, socket) {
        var txData = new Uint8Array(8);
        var temp = ipv4AddressString2Int(data.ipAddress);
        var cmd = wolfProtCommands.WPC_CB_ETH_IP_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the ip address of the ethernet interface
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEthIpAddress: function getEthIpAddress(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ETH_IP_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.interfaceIp === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the ip address of the ethernet interface
     *
     * @param data: dhcp dynamic
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEthSettingsIpAddress: function getEthSettingsIpAddress(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ETH_IP_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.dynamic === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the subnet mask of the ethernet interface
     *
     * @param data.ipAddress: the ip address as a string (e.g. '255.255.255.0')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setEthSubnetMask: function setEthSubnetMask(data, socket) {
        var txData = new Uint8Array(8);
        var temp = ipv4AddressString2Int(data.subnetMask);
        var cmd = wolfProtCommands.WPC_CB_ETH_SUBNET_MASK;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the subnet mask of the ethernet interface
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEthSubnetMask: function getEthSubnetMask(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ETH_SUBNET_MASK;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the subnet mask of the ethernet interface
     *
     * @param data: dynamic dhcp
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEthSettingsSubnetMask: function getEthSettingsSubnetMask(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ETH_SUBNET_MASK;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.dynamic === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the gateway address of the ethernet interface
     *
     * @param data.gatewayAddress: the gateway address as a string (e.g. '192.168.2.1')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setEthGateway: function setEthGatewayAddress(data, socket) {
        var txData = new Uint8Array(8);
        var temp = ipv4AddressString2Int(data.gatewayAddress);
        var cmd = wolfProtCommands.WPC_CB_ETH_GATEWAY_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the gateway address of the ethernet interface
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEthGatewayAddress: function getEthGatewayAddress(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ETH_GATEWAY_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the gateway address of the ethernet interface
     *
     * @param data: dhcp dynamic
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEthSettingsGatewayAddress: function getEthSettingsGatewayAddress(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ETH_GATEWAY_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.dynamic === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the dns address of the ethernet interface
     *
     * @param data.dnsAddress: the dns address as a string (e.g. '96.67.2.1')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setEthDnsAddress: function setEthDnsAddress(data, socket) {
        var txData = new Uint8Array(8);
        var temp = ipv4AddressString2Int(data.dnsAddress);
        var cmd = wolfProtCommands.WPC_CB_ETH_DNS_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the dns address of the ethernet interface
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEthDnsAddress: function getEthDnsAddress(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ETH_DNS_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the dns address of the ethernet interface
     *
     * @param data: dynamic dhcp
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEthSettingsDnsAddress: function getEthSettingsDnsAddress(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ETH_DNS_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.dynamic === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the dns2 address of the ethernet interface
     *
     * @param data: dynamic dhcp
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEthSettingsDnsAddress2: function getEthSettingsDnsAddress2(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ETH_DNS2_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.dynamic === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the dns2 address of the ethernet interface
     *
     * @param data.dnsAddress: the dns address as a string (e.g. '96.67.2.1')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setEthDnsAddress2: function setEthDnsAddress2(data, socket) {
        var txData = new Uint8Array(8);
        var temp = ipv4AddressString2Int(data.dnsAddress);
        var cmd = wolfProtCommands.WPC_CB_ETH_DNS2_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the mac address of the ethernet interface
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEthMacAddress: function getEthMacAddress(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ETH_GET_MAC_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * request the dhcp state of the ethernet interface
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEthDhcpState: function getEthDhcpState(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ETH_GET_DHCP_STATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets ip address, subnetmask and gateway address of the ethernet interface
     *
     * @param data.ipAddress: the new ipAddress
     * @param data.subnetMask: the new subnet mask
     * @param data.gatewayAddress: the new gateway address
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setEthAll: function setEthAll(data, socket) {
        var txData = new Uint8Array(17);
        var temp;
        var cmd = wolfProtCommands.WPC_CB_ETH_SET_ALL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x0D;
        txData[4] = data.dhcpSetting;

        temp = ipv4AddressString2Int(data.ipAddress);
        txData[5] = (temp >> 24) & 0xFF;
        txData[6] = (temp >> 16) & 0xFF;
        txData[7] = (temp >> 8) & 0xFF;
        txData[8] = temp & 0xFF;

        temp = ipv4AddressString2Int(data.subnetMask);
        txData[9] = (data.subnetMask >> 24) & 0xFF;
        txData[10] = (data.subnetMask >> 16) & 0xFF;
        txData[11] = (data.subnetMask >> 8) & 0xFF;
        txData[12] = data.subnetMask & 0xFF;

        temp = ipv4AddressString2Int(data.gatewayAddress);
        txData[13] = (temp >> 24) & 0xFF;
        txData[14] = (temp >> 16) & 0xFF;
        txData[15] = (temp >> 8) & 0xFF;
        txData[16] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * activates the new ethernet settings
     *
     * @param data: ununsed
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setEthActivate: function setEthActivate(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ETH_ACTIVATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the wlan status
     * @method getWlanStatus
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanStatus: function getWlanStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the eth status
     * @method getEthStatus
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEthStatus: function getEthStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ETH_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the wifi mode
     *
     * @param data.wlanMode: the wifi mode (WPP_WLAN_MODE_OFF,
     *                                      WPP_WLAN_MODE_INFRASTRUCTURE,
     *                                      WPP_WLAN_MODE_ACCESSPOINT)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanMode: function setWlanMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_MODE;
        var mapping = {
            'off': wolfProtParameters.WPP_WLAN_MODE_OFF,
            'infrastructure': wolfProtParameters.WPP_WLAN_MODE_INFRASTRUCTURE,
            'access-point': wolfProtParameters.WPP_WLAN_MODE_ACCESSPOINT
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.wlanMode];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wifi mode
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanMode: function getWlanMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the wifi channel
     *
     * @param: data.wifiChannel: WPP_WLAN_CHANNEL_1
     *                           WPP_WLAN_CHANNEL_6
     *                           WPP_WLAN_CHANNEL_11
     *                           WPP_WLAN_CHANNEL_36
     *                           WPP_WLAN_CHANNEL_40
     *                           WPP_WLAN_CHANNEL_44
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanChannel: function setWlanChannel(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_CHANNEL;
        var mapping = {
            1: wolfProtParameters.WPP_WLAN_CHANNEL_1,
            6: wolfProtParameters.WPP_WLAN_CHANNEL_6,
            11: wolfProtParameters.WPP_WLAN_CHANNEL_11,
            36: wolfProtParameters.WPP_WLAN_CHANNEL_36,
            40: wolfProtParameters.WPP_WLAN_CHANNEL_40,
            44: wolfProtParameters.WPP_WLAN_CHANNEL_44,
            48: wolfProtParameters.WPP_WLAN_CHANNEL_48,
            149: wolfProtParameters.WPP_WLAN_CHANNEL_149,
            153: wolfProtParameters.WPP_WLAN_CHANNEL_153,
            157: wolfProtParameters.WPP_WLAN_CHANNEL_157,
            161: wolfProtParameters.WPP_WLAN_CHANNEL_161
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.wifiChannel];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wifi channel
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanChannel: function getWlanChannel(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_CHANNEL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the ssid to an automatic name
     *
     * @param: data.ssidAuto: WPP_WLAN_SSID_AUTO_OFF
     *                        WPP_WLAN_SSID_AUTO_ON
     * @return cmd: the command number
     */
    setSsidAuto: function setSsidAuto(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_SSID_AUTO;
        var mapping = {
            'false': wolfProtParameters.WPP_WLAN_SSID_AUTO_OFF,
            'true': wolfProtParameters.WPP_WLAN_SSID_AUTO_ON
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.ssidAuto];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the ssid auto setting
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getSsidAuto: function getSsidAuto(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_SSID_AUTO;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the ssid name
     *
     * @param ssidName: the ssid name as a string
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setInfraSsidName: function setInfraSsidName(data, socket) {
        var name = data.ssidName || '';
        var txData = new Uint8Array(4 + name.length);
        var cmd = wolfProtCommands.WPC_CB_WLAN_INFRA_SSID_NAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = name.length;

        for (var i = 0; i < name.length; ++i) {
            txData[i + 4] = name.charCodeAt(i);
        }
        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the ssid name
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getInfraSsidName: function getSsidName(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_INFRA_SSID_NAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the dhcp setting of the wifi interface
     *
     * @param data.dhcpSetting: WPP_WLAN_DHCP_OFF,
     *                          WPP_WLAN_DHCP_ON,
     *                          WPP_WLAN_DHCP_TOGGLE
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanDhcpSetting: function setWlanDhcpSetting(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_DHCP_SETTING;

        data.dhcpSetting = data.dhcpSetting || 0x00;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.dhcpSetting;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wifi dhcp setting
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanDhcpSetting: function getWlanDhcpSetting(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_DHCP_SETTING;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     *
     * sets the ip address of the wifi interface
     *
     * @param data.ipAddress: the ip address as a string (e.g. '192.168.2.1')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanIpAddress: function setWlanIpAddress(data, socket) {
        var txData = new Uint8Array(8);
        var temp = ipv4AddressString2Int(data.ipAddress);
        var cmd = wolfProtCommands.WPC_CB_WLAN_IP_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wifi interface ip address
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanIpAddress: function getWlanIpAddress(data, socket) {
        var length = !!data.interfaceIp ? 5 : 4;
        var txData = new Uint8Array(length);
        var cmd = wolfProtCommands.WPC_CB_WLAN_IP_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;

        if (!!data.interfaceIp) {
            txData[3] = 0x01;
            txData[4] = data.interfaceIp === true ? 0x01 : 0x00;
        } else {
            txData[3] = 0x00;
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wifi interface ip address
     *
     * @param data: dynamic dhcp
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanSettingsIpAddress: function getWlanSettingsIpAddress(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_IP_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.dynamic) === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     *
     * sets the subnet mask of the wifi interface
     *
     * @param data.subnetMask: the subnet mask as a string (e.g. '255.255.255.0')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanSubnetMask: function setWlanSubnetMask(data, socket) {
        var txData = new Uint8Array(8);
        var temp = ipv4AddressString2Int(data.subnetMask);
        var cmd = wolfProtCommands.WPC_CB_WLAN_SUBNET_MASK;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wifi interface subnet mask
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanSubnetMask: function getWlanSubnetMask(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_SUBNET_MASK;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wifi interface subnet mask
     *
     * @param data: dynamic dhcp
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanSettingsSubnetMask: function getWlanSettingsSubnetMask(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_SUBNET_MASK;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.dynamic) === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     *
     * sets the ip address of the access point wifi interface
     *
     * @param data.ipAddress: the ip address as a string (e.g. '192.168.2.1')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanApIpAddress: function setWlanApIpAddress(data, socket) {
        var txData = new Uint8Array(8);
        var temp = ipv4AddressString2Int(data.ipAddress);
        var cmd = wolfProtCommands.WPC_CB_WLAN_AP_IP_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the access point wifi interface ip address
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanApIpAddress: function getWlanApIpAddress(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_AP_IP_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     *
     * sets the subnet mask of the access point wifi interface
     *
     * @param data.subnetMask: the subnet mask as a string (e.g. '255.255.255.0')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanApSubnetMask: function setWlanApSubnetMask(data, socket) {
        var txData = new Uint8Array(8);
        var temp = ipv4AddressString2Int(data.subnetMask);
        var cmd = wolfProtCommands.WPC_CB_WLAN_AP_SUBNET_MASK;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the access point wifi interface subnet mask
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanApSubnetMask: function getWlanApSubnetMask(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_AP_SUBNET_MASK;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the access point ssid name
     *
     * @param ssidName: the ssid name as a string
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setApSsidName: function setApSsidName(data, socket) {
        var name = data.ssidName || '';
        var txData = new Uint8Array(4 + name.length);
        var cmd = wolfProtCommands.WPC_CB_WLAN_AP_SSID_NAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = name.length;

        for (var i = 0; i < name.length; ++i) {
            txData[i + 4] = name.charCodeAt(i);
        }
        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests access point the ssid name
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getApSsidName: function getApSsidName(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_AP_SSID_NAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the access point wifi encryption standard
     *
     * @param data.encryptionStandard: one of WPP_WLAN_ENCRYPTION_NONE,
     *                                        WPP_WLAN_ENCRYPTION_WEP,
     *                                        WPP_WLAN_ENCRYPTION_WPA,
     *                                        WPP_WLAN_ENCRYPTION_WPA_ENTERPRISE
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanApEncryptionStandard: function setWlanApEncryptionStandard(data, socket) {
        var txData = new Uint8Array(5);
        data.encryptionStandard = data.encryptionStandard || 0x00;
        var cmd = wolfProtCommands.WPC_CB_WLAN_AP_ENCRYPTION_STANDARD;
        var mapping = {
            'none': wolfProtParameters.WPP_WLAN_ENCRYPTION_NONE,
            'wpa': wolfProtParameters.WPP_WLAN_ENCRYPTION_WPA
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.encryptionStandard];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the access point wifi encryption standard
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanApEncryptionStandard: function getWlanApEncryptionStandard(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_AP_ENCRYPTION_STANDARD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     *
     * sets the gateway address of the wifi interface
     *
     * @param data.gatewayAddress: the gateway address as a string (e.g. '34.111.34.1')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanGatewayAddress: function setWlanGatewayAddress(data, socket) {
        var txData = new Uint8Array(8);
        var temp = ipv4AddressString2Int(data.gatewayAddress);
        var cmd = wolfProtCommands.WPC_CB_WLAN_GATEWAY_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wifi interface gateway address
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanGateway: function getWlanGatewayAddress(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_GATEWAY_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wifi interface gateway address
     *
     * @param data: dynamic dhcp
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanSettingsGateway: function getWlanSettingsGateway(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_GATEWAY_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.dynamic) === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     *
     * sets the dns address of the wifi interface
     *
     * @param data.dnsAddress: the dns address as a string (e.g. '34.111.34.1')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanDnsAddress: function setWlanDnsAddress(data, socket) {
        var txData = new Uint8Array(8);
        var temp = ipv4AddressString2Int(data.dnsAddress);
        var cmd = wolfProtCommands.WPC_CB_WLAN_DNS_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     *
     * sets the dns2 address of the wifi interface
     *
     * @param data.dnsAddress: the dns address as a string (e.g. '34.111.34.1')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanDnsAddress2: function setWlanDnsAddress2(data, socket) {
        var txData = new Uint8Array(8);
        var temp = ipv4AddressString2Int(data.dnsAddress);
        var cmd = wolfProtCommands.WPC_CB_WLAN_DNS2_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wifi interface dns address
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanDns: function getWlanDnsAddress(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_DNS_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wifi interface dns address
     *
     * @param data: dynamic dhcp
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanSettingsDns: function getWlanSettingsDns(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_DNS_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.dynamic) === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wifi interface dns address
     *
     * @param data: dynamic dhcp
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanSettingsDns2: function getWlanSettingsDns2(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_DNS2_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.dynamic) === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     *
     * activates the configured wifi settings must be called after a change of the wifi settings
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanSettingsActivate: function setWlanSettingsActivate(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_ACTIVATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the mac address of the wifi interface
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanMacAddress: function getWlanMacAddress(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_GET_MAC_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wifi dhcp state
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanDhcpState: function getWlanDhcpState(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_DHCP_SETTING;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the wifi key in infrastructure mode
     *
     * @param data.wifiKey: the wifi key that should be used
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setKeyInfrastructure: function setKeyInfrastructure(data, socket) {
        var key = data.wifiKey || '';
        var cmd = wolfProtCommands.WPC_CB_WLAN_KEY_INFRASTRUCTURE;

        var txData = new Uint8Array(4 + key.length);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = key.length;

        for (var i = 0; i < key.length; ++i) {
            txData[i + 4] = key.charCodeAt(i);
        }
        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wifi key in infrastructure mode
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getKeyInfrastructure: function getKeyInfrastructure(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_KEY_INFRASTRUCTURE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the wifi encryption standard
     *
     * @param data.encryptionStandard: one of WPP_WLAN_ENCRYPTION_NONE,
     *                                        WPP_WLAN_ENCRYPTION_WEP,
     *                                        WPP_WLAN_ENCRYPTION_WPA,
     *                                        WPP_WLAN_ENCRYPTION_WPA_ENTERPRISE
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanEncryptionStandard: function setWlanEncryptionStandard(data, socket) {
        var txData = new Uint8Array(5);
        data.encryptionStandard = data.encryptionStandard || 0x00;
        var cmd = wolfProtCommands.WPC_CB_WLAN_ENCRYPTION_STANDARD;
        var mapping = {
            'none': wolfProtParameters.WPP_WLAN_ENCRYPTION_NONE,
            'wep': wolfProtParameters.WPP_WLAN_ENCRYPTION_WEP,
            'wpa': wolfProtParameters.WPP_WLAN_ENCRYPTION_WPA,
            'wpa-enterprise': wolfProtParameters.WPP_WLAN_ENCRYPTION_WPA_ENTERPRISE
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.encryptionStandard];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wifi encryption standard
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanEncryptionStandard: function getWlanEncryptionStandard(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_ENCRYPTION_STANDARD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the wifi key in accesspoint mode
     *
     * @param data.wifiKey: the wifi key that should be used
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setKeyAccesspoint: function setKeyAccesspoint(data, socket) {
        var key = data.wifiKey || '';
        var txData = new Uint8Array(4 + key.length);
        var cmd = wolfProtCommands.WPC_CB_WLAN_KEY_ACCESSPOINT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = key.length;

        for (var i = 0; i < key.length; ++i) {
            txData[i + 4] = key.charCodeAt(i);
        }
        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     *
     * requests the wifi key in accesspoint mode
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getPasswordAccesspoint: function getKeyAccesspoint(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_KEY_ACCESSPOINT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * returns the accesspoint list
     * @method getWifiApList
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWifiApList: function getWifiApList(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_AP_LIST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets wlan ssid type
     * @method setInfraWlanSsidType
     * @param data.type: the wlan ssid type (ESSID or BSSID)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setInfraWlanSsidType: function setInfraWlanSsidType(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_SSID_TYPE;
        var mapping = {
            'essid': wolfProtParameters.WPP_WLAN_SSID_TYPE_ESSID,
            'bssid': wolfProtParameters.WPP_WLAN_SSID_TYPE_BSSID
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.type];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * returns the wlan ssid type
     * @method getInfraWlanSsidType
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getInfraWlanSsidType: function getInfraWlanSsidType(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_SSID_TYPE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the bssid
     * @method setInfraWlanBssid
     * @param data.bssid: bssid (MAC address)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setInfraWlanBssid: function setInfraWlanBssid(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.bssid.toUpperCase());
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_WLAN_BSSID;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * returns the wlan bssid
     * @method getInfraWlanBssid
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getInfraWlanBssid: function getInfraWlanBssid(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_BSSID;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets wlan frequency band
     * @method setInfraWlanFrequencyBand
     * @param data.band: the wlan band (both, 2.4GHz, 5GHz)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setInfraWlanFrequencyBand: function setInfraWlanFrequencyBand(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_FREQUENCY_BAND;
        var mapping = {
            'both': wolfProtParameters.WPP_WLAN_FREQUENCY_BAND_BOTH,
            '24ghz': wolfProtParameters.WPP_WLAN_FREQUENCY_BAND_24,
            '5ghz': wolfProtParameters.WPP_WLAN_FREQUENCY_BAND_5
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.band];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * returns the wlan frequency band
     * @method getInfraWlanFrequencyBand
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getInfraWlanFrequencyBand: function getInfraWlanFrequencyBand(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_FREQUENCY_BAND;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the time source (internal realtime clock or external timeserver)
     *
     * @param data.timeSource: one of WPP_TIME_SOURCE_INTERNAL,
     *                                WPP_TIME_SOURCE_EXTERNAL
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setTimeSource: function setTimeSource(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_TIME_SOURCE;
        data.timeSource = data.timeSource || 0x00;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.timeSource === true) ? wolfProtParameters.WPP_TIME_SOURCE_EXTERNAL : wolfProtParameters.WPP_TIME_SOURCE_INTERNAL;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the time source
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getTimeSource: function getTimeSource(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_TIME_SOURCE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the url of the timeserver
     *
     * @param data.url: the url of the timeserver as a string (e.g. '12.234.12.31')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setTimeserverUrl: function setTimeserverUrl(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.url);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_TIMESERVER_URL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the timeserver url
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getTimeserverUrl: function getTimeserverUrl(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_TIMESERVER_URL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set timeserver test
     * @data.url: timeserver url
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setTimeserverTest: function setTimeserverTest(data, socket) {
        var tempArrayUrl = StringConvert.stringToUtf8(data.url);
        var txData = new Uint8Array(4 + tempArrayUrl.length);
        var cmd = wolfProtCommands.WPC_CB_TIMESERVER_TEST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArrayUrl.length;
        txData.set(tempArrayUrl, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Get timeserver test status
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getTimeserverTestStatus: function getTimeserverTestStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_TIMESERVER_TEST_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the date format
     *
     * @param data.dateFormat: one of WPP_DATEFORMAT_24H,
     *                                WPP_DATEFORMAT_12H
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setDateFormat: function setDateFormat(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_DATE_FORMAT;
        var mapping = {
            'MMDDYYYY/12H': wolfProtParameters.WPP_DATEFORMAT_12H,
            'DDMMYYYY/24H': wolfProtParameters.WPP_DATEFORMAT_24H
        };
        var dateFormat;

        data.dateFormat = data.dateFormat || 'DDMMYYYY/24H';
        dateFormat = mapping[data.dateFormat];

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = dateFormat;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the date format
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getDateFormat: function getDateFormat(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_DATE_FORMAT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets time and date
     *
     * @param data.year: the year that should be set (e.g. 2014)
     * @param data.month: the month that should be set 1-12 (e.g. 11 for November)
     * @param data.day: the day of the month from 1- 31 (e.g. 25)
     * @param data.hour: the hour of the day from 0-23 (e.g. 10)
     * @param data.minute: the minute from 0-59 (e.g. 33)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setTimeDate: function setTimeDate(data, socket) {
        var txData = new Uint8Array(9);
        var cmd = wolfProtCommands.WPC_CB_DATE_TIME;

        data.year = data.year || 0x00;
        data.month = data.month || 0x01;
        data.day = data.day || 0x01;
        data.hour = data.hour || 0x00;
        data.minute = data.minute || 0x00;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x05;
        txData[4] = data.year - 2000;
        txData[5] = data.month;
        txData[6] = data.day;
        txData[7] = data.hour;
        txData[8] = data.minute;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the current time and date
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getTimeDate: function getTimeDate(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_DATE_TIME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the streaming ip address
     * @method setStreamingIpAddress
     * @param {Object} data request parameter object
     * @param {String} data.ipAddress: the ip address as a string (e.g. '12.234.12.31')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setStreamingIpAddress: function setStreamingIpAddress(data, socket) {
        const txData = new Uint8Array(8);
        const temp = ipv4AddressString2Int(data.ipAddress);
        const cmd = wolfProtCommands.WPC_CB_STREAMING_IP_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the streaming ip address
     * @method setStreamingIpAddressOutput
     * @param {Object} data request parameter object
     * @param {String} data.ipAddress: the ip address (e.g. '12.234.12.31')
     * @param {'hdmi1'|'hdmi2'} data.output: the output
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setStreamingIpAddressOutput: function setStreamingIpAddressOutput(data, socket) {
        const txData = new Uint8Array(9);
        const temp = ipv4AddressString2Int(data.ipAddress);
        const cmd = wolfProtCommands.WPC_CB_STREAMING_IP_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x05;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;
        txData[8] = OUTPUT_MAPPING[data.output];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * returns the streaming ip address
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getStreamingIpAddress: function getStreamingIpAddress(data, socket) {
        const txData = new Uint8Array(4);
        const cmd = wolfProtCommands.WPC_CB_STREAMING_IP_ADDRESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the streaming port
     * @method setStreamingPort
     * @param {Object} data request parameter object
     * @param {String} data.port port the stream runs on (e.g. '8080')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setStreamingPort: function setStreamingPort(data, socket) {
        const txData = new Uint8Array(6);
        const temp = parseInt(data.port);
        const cmd = wolfProtCommands.WPC_CB_STREAMING_PORT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = (temp >> 8) & 0xFF;
        txData[5] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the streaming port
     * @method setStreamingPortOutput
     * @param {Object} data request parameter object
     * @param {String} data.port port the stream runs on (e.g. '8080')
     * @param {'hdmi1'|'hdmi2'} data.output output
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setStreamingPortOutput: function setStreamingPortOutput(data, socket) {
        const txData = new Uint8Array(7);
        const temp = parseInt(data.port);
        const cmd = wolfProtCommands.WPC_CB_STREAMING_PORT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x03;
        txData[4] = (temp >> 8) & 0xFF;
        txData[5] = temp & 0xFF;
        txData[6] = OUTPUT_MAPPING[data.output];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * returns the streaming ip port
     * @method getStreamingPort
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getStreamingPort: function getStreamingPort(data, socket) {
        const txData = new Uint8Array(4);
        const cmd = wolfProtCommands.WPC_CB_STREAMING_PORT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the streaming mode
     * @method setStreamingMode
     * @param data.streamingMode: the streaming mode (e.g.: WPP_ON, WPP_OFF)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setStreamingMode: function setStreamingMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_STREAMING_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = !!data.mode ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the streaming mode
     * @method: getStreamingMode
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getStreamingMode: function getStreamingMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_STREAMING_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the streaming resolution
     * @method setStreamingResolution
     * @param data.streamingResolution: the streaming resolution (e.g.: WPP_RESOLUTION_1080P,
     *                                                                  WPP_RESOLUTION_720P,
     *                                                                  WPP_RESOLUTION_540P,
     *                                                                  WPP_RESOLUTION_360P)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setStreamingResolution: function setStreamingResolution(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_STREAMING_RESOLUTION;
        var mapping = {
            '360p': wolfProtParameters.WPP_CB_RESOLUTION_360P,
            '540p': wolfProtParameters.WPP_CB_RESOLUTION_540P,
            '720p': wolfProtParameters.WPP_CB_RESOLUTION_720P,
            '1080p': wolfProtParameters.WPP_CB_RESOLUTION_1080P
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.resolution]; //Todo: Parameters why did we invent new ones?

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the streaming resolution
     * @method getStreamingResolution
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getStreamingResolution: function getStreamingResolution(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_STREAMING_RESOLUTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the streaming framerate
     * @method setStreamingFramerate
     * @param data.streamingFramerate: the streaming framerate (e.g.: WPP_FRAMERATE_LOW,
     *                                                                WPP_FRAMERATE_MEDIUM,
     *                                                                WPP_FRAMERATE_HIGH)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setStreamingFramerate: function setStreamingFramerate(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_STREAMING_FRAMERATE;
        var mapping = {
            'low': wolfProtParameters.WPP_FRAMERATE_LOW,
            'medium': wolfProtParameters.WPP_FRAMERATE_MEDIUM,
            'high': wolfProtParameters.WPP_FRAMERATE_HIGH,
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.framerate]; //Todo: Parameters

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the streaming framerate
     * @method getStreamingFramerate
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getStreamingFramerate: function getStreamingFramerate(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_STREAMING_FRAMERATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Sets the streaming bitrate mode and bitrate
     * @method setStreamingBitrate
     * @param data.mode: bitrate mode constant/variable
     * @param data.bitrate: bitrate in kbit
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setStreamingBitrate: function setStreamingBitrate(data, socket) {
        var txData = new Uint8Array(7);
        var tempBitrate = parseInt(data.bitrate);
        var cmd = wolfProtCommands.WPC_CB_STREAMING_BITRATE;
        var modeMapping = {
            'constant': wolfProtParameters.WPP_STREAMING_BITRATE_MODE_CONSTANT,
            'variable': wolfProtParameters.WPP_STREAMING_BITRATE_MODE_VARIABLE
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x03;
        txData[4] = modeMapping[data.mode];
        txData[5] = (tempBitrate >> 8) & 0xFF;
        txData[6] = tempBitrate & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Gets the streaming bitrate mode and bitrate
     * @method getStreamingBitrate
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getStreamingBitrate: function getStreamingBitrate(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_STREAMING_BITRATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the video recording state
     * @method setVideoRecordingState
     * @param data.recordingState: the streaming framerate
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVideoRecordingState: function setVideoRecordingState(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_RECORDING_STATE;
        var filename = data.filename || '';
        var txData = new Uint8Array(6 + filename.length);
        var length = 2 + filename.length;
        var mapping = {
            'record': wolfProtParameters.WPP_START,
            'pause': wolfProtParameters.WPP_PAUSE,
            'stop': wolfProtParameters.WPP_STOP
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = length;
        txData[4] = mapping[data.recordingState];
        txData[5] = filename.length;

        for (var i = 0; i < filename.length; i++) {
            txData[i + 6] = filename.charCodeAt(i);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the video recording state
     * @method getVideoRecordingState
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVideoRecordingState: function getVideoRecordingState(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_RECORDING_STATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.USER),
            cmd: cmd
        };

    },

    /**
     * sets the recording resolution
     * @method setRecordingResolution
     * @param data.recordingResolution: the recording resolution (e.g.: WPP_CB_RESOLUTION_1080P,
     *                                                                  WPP_CB_RESOLUTION_720P,
     *                                                                  WPP_CB_RESOLUTION_540P,
     *                                                                  WPP_CB_RESOLUTION_360P)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setRecordingResolution: function setRecordingResolution(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_RECORDING_RESOLUTION;
        var mapping = {
            '360p': wolfProtParameters.WPP_CB_RESOLUTION_360P,
            '540p': wolfProtParameters.WPP_CB_RESOLUTION_540P,
            '720p': wolfProtParameters.WPP_CB_RESOLUTION_720P,
            '1080p': wolfProtParameters.WPP_CB_RESOLUTION_1080P
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.recordingResolution]; //Todo: Parameters why did we invent new ones?

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the recording resolution
     * @method getRecordingResolution
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getRecordingResolution: function getRecordingResolution(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_RECORDING_RESOLUTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the video recording function
     * @method setVideoRecordingFunction
     * @param data.recordingFunction: recording enabled/disabled
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVideoRecordingFunction: function setVideoRecordingFunction(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_RECORDING_FUNCTION;
        var mapping = {
            'disable': wolfProtParameters.WPP_RECORDING_FUNCTION_DISABLE,
            'enable': wolfProtParameters.WPP_RECORDING_FUNCTION_ENABLE
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.recordingFunction];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the video recording function
     * @method getVideoRecordingFunction
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVideoRecordingFunction: function getVideoRecordingFunction(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_RECORDING_FUNCTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Adds a new private framebox
     *
     * @param data.content: the content of the window (eg. WPP_CONTENT_VZ, WPP_CONTENT_BROWSER,...)
     * @param data.windowNumber: 0-3 or undefined for autoarrange
     * @param data.parameter: parameter for starting the window (e.g. filename for video, 0 or 1 for HDMI1 or HDMI2)
     * @param data.restrictedWindow: 0 - not restricted - 1 restricted (only this client can control the new framebox)
     * @param data.privateData: not used at the moment (only vApp)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAddNewFrameboxPrivate: function setAddNewFrameboxPrivate(data, socket) {
        var paramLength = 0;
        var tempParamArray;

        var privateLength = 0;
        var tempPrivateArray;

        var pointer = 12;
        var cmd = wolfProtCommands.WPC_CB_WINDOW_START_PRIVATE;
        var length;
        var txData;

        if (typeof data.parameter === 'string') {
            tempParamArray = StringConvert.stringToUtf8(data.parameter);
            paramLength = tempParamArray.length;
        } else if (typeof data.parameter === 'number') {
            paramLength = 1;
        }

        if (data.privateData) {
            tempPrivateArray = StringConvert.stringToUtf8(data.privateData);
            privateLength = tempPrivateArray.length;
        }

        length = 8 + paramLength + privateLength;
        txData = new Uint8Array(8 + length);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_HDR | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = wolfProtHeaders.WP_EXTHEAD_EXT_LEN;
        txData[2] = (cmd >> 8) & 0xFF;
        txData[3] = cmd & 0xFF;
        txData[4] = (length >> 24) & 0xFF;
        txData[5] = (length >> 16) & 0xFF;
        txData[6] = (length >> 8) & 0xFF;
        txData[7] = length & 0xFF;
        txData[8] = data.windowNumber || -1;
        txData[9] = CONTENT_MAPPING[data.content];
        txData[10] = (paramLength >> 8) & 0xFF;
        txData[11] = paramLength & 0xFF;

        if (paramLength > 1) {
            txData.set(tempParamArray, pointer);
            pointer += paramLength;
        } else if (paramLength === 1) {
            txData[pointer] = data.parameter || 0x00;
            pointer++;
        }

        txData[pointer] = data.restrictedWindow || 0x00;
        pointer++;

        txData[pointer] = (privateLength >> 8) & 0xFF;
        pointer++;

        txData[pointer] = privateLength & 0xFF;
        pointer++;

        if (privateLength > 0) {
            txData.set(tempPrivateArray, pointer);
        }
        pointer += privateLength;

        txData[pointer] = data.uid || 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * framebox control
     * @method setFrameboxControl
     * @param data.frameBoxId: the framebox id
     * @param data.frameboxControl: (eg. WPP_FRAMEBOX_CLOSE,
     *                                   WPP_FRAMEBOX_FULLSCREEN,
     *                                   WPP_FRAMEBOX_NORMAL)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setFrameboxControl: function setFrameboxControl(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = wolfProtCommands.WPC_CB_FRAMEBOX_CONTROL;
        var mapping = {
            'close': wolfProtParameters.WPP_FRAMEBOX_CLOSE,
            'normal': wolfProtParameters.WPP_FRAMEBOX_NORMAL,
            'fullscreen': wolfProtParameters.WPP_FRAMEBOX_FULLSCREEN,
            'auxOn': wolfProtParameters.WPP_FRAMEBOX_AUX_ON,
            'auxOff': wolfProtParameters.WPP_FRAMEBOX_AUX_OFF,
            'auxToggle': wolfProtParameters.WPP_FRAMEBOX_AUX_TOGGLE,
            'webRtcOutOn': wolfProtParameters.WPP_FRAMEBOX_WEBRTC_OUT_ON,
            'webRtcOutOff': wolfProtParameters.WPP_FRAMEBOX_WEBRTC_OUT_OFF,
            'webRtcOutToggle': wolfProtParameters.WPP_FRAMEBOX_WEBRTC_OUT_TOGGLE
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = data.frameBoxId;
        txData[5] = mapping[data.frameboxControl];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Application mute
     * @method setApplicationMute
     * @param data.appId: the ID of the application where this command should be applied to
     * @param data.mute: 'on' or 'off'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setApplicationMute: function setApplicationMute(data, socket) {
        const txData = new Uint8Array(14);
        const cmd = wolfProtCommands.WPC_CB_CONTROL_APPLICATION;
        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x0A;
        txData.set(helper.fromHexString(data.appId), 4);
        txData[12] = wolfProtParameters.WPP_APPLICATION_MUTE;
        txData[13] = data.mute === 'on' ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Application volume
     * @method setApplicationVolume
     * @param data.appId: the ID of the application where this command should be applied to
     * @param data.volume: the volume in percent (0 - 100)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setApplicationVolume: function setApplicationVolume(data, socket) {
        const txData = new Uint8Array(14);
        const cmd = wolfProtCommands.WPC_CB_CONTROL_APPLICATION;
        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x0A;
        txData.set(helper.fromHexString(data.appId), 4);
        txData[12] = wolfProtParameters.WPP_APPLICATION_VOLUME;
        txData[13] = data.volume;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Framebox event.
     * @method setFrameboxEvent
     * @param data.event: 'mouse' or 'keyboard'
     * @param data.mouseEvent: 'mousedown', 'mouseup', 'mousemove'
     * @param data.positionX: the current mouse position x
     * @param data.positionY: the current mouse position y
     * @param data.frameBoxId: the framebox id
     * @param data.keyState: 'up' or 'down'
     * @param data.key: the keycode
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setFrameboxMultiEvents: function setFrameboxMultiEvents(data, socket) {
        var length = data.eventList.length * 14;
        var txData = new Uint8Array(5 + length);
        var pointer = 4;
        var cmd = wolfProtCommands.WPC_CB_MULTI_EVENTS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_LEN | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8) & 0xFF;
        txData[4] = length & 0xFF;

        _.each(data.eventList, function(eventItem) {
            txData[pointer + 1] = 13;
            txData[pointer + 2] = eventItem.frameBoxId;

            if (data.event === 'pointer') {
                if (eventItem.event.indexOf('touch') >= 0) {
                    txData[pointer + 3] = POINTER_INPUT_MAPPING[eventItem.event];
                    txData[pointer + 4] = eventItem.identifier >> 8;
                    txData[pointer + 5] = eventItem.identifier & 0x00FF;
                } else {
                    txData[pointer + 3] = wolfProtParameters.WPP_FRAMEBOX_EVENT_POINTER_ABSOLUTE;
                    txData[pointer + 4] = POINTER_INPUT_MAPPING[eventItem.event] >> 8;
                    txData[pointer + 5] = POINTER_INPUT_MAPPING[eventItem.event] & 0x00FF;
                }
            } else if (data.event === 'keyboard') {
                txData[pointer + 3] = wolfProtParameters.WPP_FRAMEBOX_EVENT_POINTER_ABSOLUTE;
                if (eventItem.keyState === 'keydown') {
                    txData[pointer + 3] = wolfProtParameters.WPP_FRAMEBOX_EVENT_KEY_HIT;
                } else if (eventItem.keyState === 'keyup') {
                    txData[pointer + 3] = wolfProtParameters.WPP_FRAMEBOX_EVENT_KEY_RELEASE;
                }
                txData[pointer + 4] = eventItem.key >> 8;
                txData[pointer + 5] = eventItem.key;
            }

            txData[pointer + 6] = (eventItem.positionX >> 8) & 0xFF;
            txData[pointer + 7] = eventItem.positionX & 0xFF;
            txData[pointer + 8] = (eventItem.positionY >> 8) & 0xFF;
            txData[pointer + 9] = eventItem.positionY & 0xFF;

            txData[pointer + 10] = eventItem.keyCode >> 8;
            txData[pointer + 11] = eventItem.keyCode;
            txData[pointer + 12] = eventItem.shift;
            txData[pointer + 13] = eventItem.ctrl;
            txData[pointer + 14] = eventItem.alt;

            pointer += 14;
        }.bind(this));

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Browser control
     * @method setBrowserControl
     * @param data.browserControl: (e.g. 'reload',
     *                                  'stop',
     *                                  'zoomIn',
     *                                  'zoomOut',
     *                                  'scrollDown',
     *                                  'scrollUp',
     *                                  'scrollRight',
     *                                  'scrollLeft',
     *                                  'back',
     *                                  'forward',
     *                                  'pdfNextPage'
     *                                  'pdfPrevPage'
     *                                  'pdfSetPage'
     *                                  'pdfZoomFullHeight'
     *                                  'pdfZoomFullWidth'
     *                                  'pdfZoomFullPage')
     *
     * @param data.appId: the ID of the application where this command should be applied to
     * @param data.pdfPage: if data.browserControl === 'pdfSetPage'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setBrowserControl: function setBrowserControl(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_CONTROL_SPECIFIC_APPLICATION;
        let length = 10;

        if (data.browserControl === 'pdfSetPage') {
            length += 2;
        }

        let txData = new Uint8Array(5 + length);

        const mapping = {
            'reload': wolfProtParameters.WPP_BROWSER_RELOAD,
            'stop': wolfProtParameters.WPP_BROWSER_STOP,
            'zoomIn': wolfProtParameters.WPP_BROWSER_ZOOM_IN,
            'zoomOut': wolfProtParameters.WPP_BROWSER_ZOOM_OUT,
            'cursorDown': wolfProtParameters.WPP_BROWSER_CURSOR_DOWN,
            'cursorUp': wolfProtParameters.WPP_BROWSER_CURSOR_UP,
            'cursorRight': wolfProtParameters.WPP_BROWSER_CURSOR_RIGHT,
            'cursorLeft': wolfProtParameters.WPP_BROWSER_CURSOR_LEFT,
            'back': wolfProtParameters.WPP_BROWSER_BACK,
            'forward': wolfProtParameters.WPP_BROWSER_FORWARD,
            'pdfNextPage': wolfProtParameters.WPP_BROWSER_PDF_NEXT_PAGE,
            'pdfPrevPage': wolfProtParameters.WPP_BROWSER_PDF_PREV_PAGE,
            'pdfSetPage': wolfProtParameters.WPP_BROWSER_PDF_SET_PAGE,
            'pdfZoomFullHeight': wolfProtParameters.WPP_BROWSER_PDF_FULL_HEIGHT,
            'pdfZoomFullWidth': wolfProtParameters.WPP_BROWSER_PDF_FULL_WIDTH,
            'pdfZoomFullPage': wolfProtParameters.WPP_BROWSER_PDF_FULL_PAGE,
            'scrollDown': wolfProtParameters.WPP_BROWSER_SCROLL_DOWN,
            'scrollUp': wolfProtParameters.WPP_BROWSER_SCROLL_UP,
            'scrollRight': wolfProtParameters.WPP_BROWSER_SCROLL_RIGHT,
            'scrollLeft': wolfProtParameters.WPP_BROWSER_SCROLL_LEFT,
            'startpage': wolfProtParameters.WPP_BROWSER_STARTPAGE
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8) & 0xFF;
        txData[4] = length & 0xFF;
        txData[5] = CONTENT_MAPPING['Browser'];
        txData.set(helper.fromHexString(data.appId), 6);
        txData[14] = mapping[data.browserControl];

        if (data.browserControl === 'pdfSetPage') {
            txData[15] = (data.pdfPage >> 8) & 0xFF;
            txData[16] = data.pdfPage & 0xFF;
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets browser URL
     * @method setBrowserUrl
     * @param data.appId: the ID of the application where this command should be applied to
     * @param data.url: the url that should be opened
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setBrowserUrl: function setBrowserUrl(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_CONTROL_SPECIFIC_APPLICATION;
        const urlArray = StringConvert.stringToUtf8(data.url || '');
        const length = 10 + urlArray.length;
        let txData = new Uint8Array(5 + length);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8) & 0xFF;
        txData[4] = length & 0xFF;
        txData[5] = CONTENT_MAPPING['Browser'];
        txData.set(helper.fromHexString(data.appId), 6);
        txData[14] = wolfProtParameters.WPP_BROWSER_URL;
        txData.set(urlArray, 15);
        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     *
     * get browser shortcuts config
     * @method getBrowserShortcutsConfig
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getBrowserShortcutsConfig: function getBrowserShortcutsConfig(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_BROWSER_SHORTCUTS_CONFIG;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets browser shortcuts config
     * @method setBrowserUrl
     * @param data: list of shortcuts in the form [{ name: "abc", url: "https://www.google.com" }]
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setBrowserShortcutsConfig: function setBrowserShortcutsConfig(data, socket)  {
        var cmd = wolfProtCommands.WPC_BROWSER_SHORTCUTS_CONFIG;
        var arrayAsUtf8Json = StringConvert.stringToUtf8(data);
        var txData = new Uint8Array(5 + arrayAsUtf8Json.length);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (arrayAsUtf8Json.length) >> 8;
        txData[4] = (arrayAsUtf8Json.length) & 0x00FF;

        txData.set(arrayAsUtf8Json, 5);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * opens browser shortcut
     * @method setBrowserShortcutOpen
     * @param data: data.index index of browser shortcut
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setBrowserShortcutOpen: function setBrowserShortcutOpen(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_BROWSER_SHORTCUT_OPEN;

        var txData = new Uint8Array(5);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 1;
        txData[4] = data.index;
        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Visualizer control
     * @method setVzControl
     * @param data.vzControl: (e.g. 'AFOn',
     *                             'zoomWide',
     *                             'zoomTele',
     *                             'focusFar',
     *                             'focusNear',
     *                             'AFOff',
     *                             'freezeOn',
     *                             'freezeOff',
     *                             'presetSet',
     *                             'presetRecall',
     *                             'AFToggle',
     *                             'freezeToggle',
     *                             'powerOn',
     *                             'powerOff',
     *                             'lightOn'
     *                             'lightOff'
     *                             'lightToggle'
     *                             'CASOn'
     *                             'CASOff'
     *                             'CASToggle')
     *
     * @param data.appId: the ID of the application where this command should be applied to
     * @param data.speed: if data.vzControl === zoomWide/Tele or focusFar/Near, value between -15 to +15 where
     *                    negative values are zoom wide and positive zoom tele 0 => stop
     * @param data.index: if data.vzControl === onepush focus then send focus area index 1-9
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVzControl: function setVzControl(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_CONTROL_SPECIFIC_APPLICATION;
        let length = 10;

        if (data.speed !== undefined || data.vzControl === 'focusOnepush') {
            length = 12;
        }
        let txData = new Uint8Array(4 + length);

        const mapping = {
            'zoomWide': wolfProtParameters.WPP_CB_VZ_ZOOM_WIDE,
            'zoomTele': wolfProtParameters.WPP_CB_VZ_ZOOM_TELE,
            'focusFar': wolfProtParameters.WPP_CB_VZ_FOCUS_FAR,
            'focusNear': wolfProtParameters.WPP_CB_VZ_FOCUS_NEAR,
            'AFOn': wolfProtParameters.WPP_CB_VZ_AF_ON,
            'AFOff': wolfProtParameters.WPP_CB_VZ_AF_OFF,
            'freezeOn': wolfProtParameters.WPP_CB_VZ_FREEZE_ON,
            'freezeOff': wolfProtParameters.WPP_CB_VZ_FREEZE_OFF,
            'presetSet': wolfProtParameters.WPP_CB_VZ_PRESET_SET,
            'presetRecall': wolfProtParameters.WPP_CB_VZ_PRESET_RECALL,
            'AFToggle': wolfProtParameters.WPP_CB_VZ_AF_TOGGLE,
            'freezeToggle': wolfProtParameters.WPP_CB_VZ_FREEZE_TOGGLE,
            'powerOn': wolfProtParameters.WPP_CB_VZ_POWER_ON,
            'powerOff': wolfProtParameters.WPP_CB_VZ_POWER_OFF,
            'powerToggle': wolfProtParameters.WPP_CB_VZ_POWER_TOGGLE,
            'lightOn': wolfProtParameters.WPP_CB_VZ_LIGHT_ON,
            'lightOff': wolfProtParameters.WPP_CB_VZ_LIGHT_OFF,
            'lightToggle': wolfProtParameters.WPP_CB_VZ_LIGHT_TOGGLE,
            'CASOn': wolfProtParameters.WPP_CB_VZ_CAS_ON,
            'CASOff': wolfProtParameters.WPP_CB_VZ_CAS_OFF,
            'CASToggle': wolfProtParameters.WPP_CB_VZ_CAS_TOGGLE,
            'focusOnepush': wolfProtParameters.WPP_CB_VZ_FOCUS_ONEPUSH
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = length;
        txData[4] = CONTENT_MAPPING['Visualizer'];
        txData.set(helper.fromHexString(data.appId), 5);
        txData[13] = mapping[data.vzControl];

        if (data.vzControl === 'focusOnepush') {
            txData[14] = 0x00;
            txData[15] = data.index;
        } else if (data.speed !== undefined) {
            txData[14] = 0x00;
            txData[15] = Math.abs(data.speed);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Miracast control
     * @method setMiracastControl
     * @param data.appId: the ID of the application where this command should be applied to
     * @param data.control {(lowLatencyOn|lowLatencyOff|lowLatencyToggle)}
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMiracastControl: function setMiracastControl(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_CONTROL_SPECIFIC_APPLICATION;
        let txData = new Uint8Array(14);

        const mapping = {
            'lowLatencyOn': wolfProtParameters.WPP_CB_MIRACAST_LOW_LATENCY_ON,
            'lowLatencyOff': wolfProtParameters.WPP_CB_MIRACAST_LOW_LATENCY_OFF,
            'lowLatencyToggle': wolfProtParameters.WPP_CB_MIRACAST_LOW_LATENCY_TOGGLE
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x0A;
        txData[4] = CONTENT_MAPPING['miracast'];
        txData.set(helper.fromHexString(data.appId), 5);
        txData[13] = mapping[data.control];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the getblock command
     * @method getBlock
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getBlock: function getBlock(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_BLOCK;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the miracast status
     * @method getMiracastState
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMiracastState: function getMiracastState(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MIRACAST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Enables / disables miracast.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMiracastState: function setMiracastState(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MIRACAST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.enable === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the mice status.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMiceState: function getMiracastState(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MICE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Enables / disables mice.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMiceState: function setMiceState(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = wolfProtCommands.WPC_CB_MICE;

        var mapping = {
            'all': wolfProtParameters.WPP_CB_MICE_INTERFACE_ALL,
            'lan1': wolfProtParameters.WPP_CB_MICE_INTERFACE_LAN1,
            'lan2': wolfProtParameters.WPP_CB_MICE_INTERFACE_LAN2,
            'wlan': wolfProtParameters.WPP_CB_MICE_INTERFACE_WLAN
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;

        txData[4] = (data.enable === true) ? 0x01 : 0x00;
        txData[5] = mapping[data.interface];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the vConnect status
     * @method getVconnectState
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVconnectState: function getVconnectState(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_VCONNECT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * enables / disables vConnect
     * @method setVconnectState
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVconnectState: function setVconnectState(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_VCONNECT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.enable === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the airplay status
     * @method getAirplayState
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAirplayState: function getAirplayState(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_AIRPLAY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * enables / disables airplay
     * @method setAirplayState
     * @param data: data.enable (true or false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAirplayState: function setAirplayState(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_AIRPLAY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.enable === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the airplay status
     * @method getAirplayState
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAirplayMode: function getAirplayMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_AIRPLAY_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * enables / disables airplay pin
     * @method setAirplayMode
     * @param data: data.pin (true or false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAirplayMode: function setAirplayMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_AIRPLAY_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.mode === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the airplay 4k mode
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAirplay4kMode: function getAirplay4kMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_AIRPLAY_4K_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * enables / disables airplay 4k mode
     * @param data: data.enabled (true or false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAirplay4kMode: function setAirplay4kMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_AIRPLAY_4K_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.enabled === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Requests the vCast PIN
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVCastPin: function getVCastPin(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_VCAST_PIN;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Enables / disables vCast PIN
     *
     * @param data: data.pin (true or false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVCastPin: function setVCastPin(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_VCAST_PIN;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.pin === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Requests the Miracast PIN
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMiracastPin: function getMiracastPin(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MIRACAST_PIN;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Enables / disables Miracast PIN
     *
     * @param data: data.pin (true or false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMiracastPin: function setMiracastPin(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MIRACAST_PIN;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.pin === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Requests the mirror status
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMirrorState: function getMirrorState(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MIRROR;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * enables / disables mirror
     * @method setMirrorState
     * @param data: data.enable (true or false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMirrorState: function setMirrorState(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MIRROR;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enable;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * returns the current presentation mode
     * @method getPresentationMode
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getPresentationMode: function getPresentationMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PRESENTATION_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the presentation mode
     * @method setPresentationMode
     * @param data.mode:"meeting" or "lecture"
     * @param data.timeout: 10 - 120 sec
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setPresentationMode: function setPresentationMode(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = wolfProtCommands.WPC_CB_PRESENTATION_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = data.mode === 'meeting' ? 0 : 1;
        txData[5] = parseInt(data.timeout);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },


    /**
     * Return current presentation ID.
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @return cmd: the command number
     */
    getPresentationId: function getPresentationId(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PRESENTATION_ID;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets image control command
     * @method setImageCmd
     * @param data.appId: the ID of the application where this command should be applied to
     * @param data.cmd {(prev|next|first|last)}: the control command
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setImageCmd: function setImageCmd(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_CONTROL_SPECIFIC_APPLICATION;
        let txData = new Uint8Array(15);

        data.cmd = data.cmd || 'prev';

        const mapping = {
            'prev': wolfProtParameters.WPP_IMAGE_VIEWER_PREVIOUS,
            'next': wolfProtParameters.WPP_IMAGE_VIEWER_NEXT,
            'first': wolfProtParameters.WPP_IMAGE_VIEWER_FIRST,
            'last': wolfProtParameters.WPP_IMAGE_VIEWER_LAST
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;
        txData[4] = 0x0A;
        txData[5] = CONTENT_MAPPING['Image'];
        txData.set(helper.fromHexString(data.appId), 6);
        txData[14] = mapping[data.cmd];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Seeks video to given time
     * @method setVideoSeek
     * @param data.appId: the ID of the application where this command should be applied to
     * @param data.time: the time in ms
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVideoSeek: function setVideoSeek(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_CONTROL_SPECIFIC_APPLICATION;
        let txData = new Uint8Array(19);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;
        txData[4] = 0x0E;
        txData[5] = CONTENT_MAPPING['Video'];
        txData.set(helper.fromHexString(data.appId), 6);
        txData[14] = wolfProtParameters.WPP_VIDEO_VIEWER_SEEK;
        txData[15] = (data.time) >> 24 & 0x000000FF;
        txData[16] = (data.time) >> 16 & 0x000000FF;
        txData[17] = (data.time) >> 8 & 0x000000FF;
        txData[18] = data.time & 0x000000FF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets video control command
     * @method setVideoCmd
     * @param data.appId: the ID of the application where this command should be applied to
     * @param data.cmd {(play|pause|stop|forward|rewind|loopOn|loopOff|loopToggle)}: the control command
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVideoCmd: function setVideoCmd(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_CONTROL_SPECIFIC_APPLICATION;
        let txData = new Uint8Array(15);

        const mapping = {
            'play': wolfProtParameters.WPP_VIDEO_VIEWER_PLAY,
            'pause': wolfProtParameters.WPP_VIDEO_VIEWER_PAUSE,
            'stop': wolfProtParameters.WPP_VIDEO_VIEWER_STOP,
            'forward': wolfProtParameters.WPP_VIDEO_VIEWER_FORWARD,
            'rewind': wolfProtParameters.WPP_VIDEO_VIEWER_REWIND,
            'loopOn': wolfProtParameters.WPP_VIDEO_VIEWER_LOOP_ON,
            'loopOff': wolfProtParameters.WPP_VIDEO_VIEWER_LOOP_OFF,
            'loopToggle': wolfProtParameters.WPP_VIDEO_VIEWER_LOOP_TOGGLE
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;
        txData[4] = 0x0A;
        txData[5] = CONTENT_MAPPING['Video'];
        txData.set(helper.fromHexString(data.appId), 6);
        txData[14] = mapping[data.cmd];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * returns the current video status
     * @method getVideoStatus
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVideoStatus: function getVideoStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_VIDEO_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Seeks audio to given time
     * @method setAudioSeek
     * @param data.appId: the ID of the application where this command should be applied to
     * @param data.time: the time in ms
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAudioSeek: function setAudioPath(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_CONTROL_SPECIFIC_APPLICATION;
        let txData = new Uint8Array(19);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;
        txData[4] = 0x0E;
        txData[5] = CONTENT_MAPPING['Audio'];
        txData.set(helper.fromHexString(data.appId), 6);
        txData[14] = wolfProtParameters.WPP_AUDIO_VIEWER_SEEK;
        txData[15] = (data.time) >> 24 & 0x000000FF;
        txData[16] = (data.time) >> 16 & 0x000000FF;
        txData[17] = (data.time) >> 8 & 0x000000FF;
        txData[18] = data.time & 0x000000FF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets audio control command
     * @method setAudioCmd
     * @param data.appId: the ID of the application where this command should be applied to
     * @param data.cmd {(play|pause|stop|forward|rewind|loopOn|loopOff|loopToggle)}: the control command
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAudioCmd: function setAudioCmd(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_CONTROL_SPECIFIC_APPLICATION;
        let txData = new Uint8Array(15);

        const mapping = {
            'play': wolfProtParameters.WPP_AUDIO_VIEWER_PLAY,
            'pause': wolfProtParameters.WPP_AUDIO_VIEWER_PAUSE,
            'stop': wolfProtParameters.WPP_AUDIO_VIEWER_STOP,
            'forward': wolfProtParameters.WPP_AUDIO_VIEWER_FORWARD,
            'rewind': wolfProtParameters.WPP_AUDIO_VIEWER_REWIND,
            'loopOn': wolfProtParameters.WPP_AUDIO_VIEWER_LOOP_ON,
            'loopOff': wolfProtParameters.WPP_AUDIO_VIEWER_LOOP_OFF,
            'loopToggle': wolfProtParameters.WPP_AUDIO_VIEWER_LOOP_TOGGLE
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;
        txData[4] = 0x0A;
        txData[5] = CONTENT_MAPPING['Audio'];
        txData.set(helper.fromHexString(data.appId), 6);
        txData[14] = mapping[data.cmd];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * returns the current audio status
     * @method getAudioStatus
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAudioStatus: function getAudioStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_AUDIO_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * returns the current mounted drives
     * @method getMounts
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMounts: function getMounts(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MOUNTS_LIST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * returns the file listing for the given path
     * @method getFileList
     * @param data.pathname: pathname where we want to know the listing (e.g. <mount>:///directory0/)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getFileList: function getFileList(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.pathname);
        var txData = new Uint8Array(5 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_FILE_LIST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (tempArray.length >> 8);
        txData[4] = (tempArray.length & 0xFF);

        txData.set(tempArray, 5);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Sets the login password.
     *
     * @param data.accessLevel: 'user' or 'admin'
     * @param data.password: the password as string
     * @param data.adminPin: the admin-pin as string
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLoginPassword: function setLoginPassword(data, socket) {
        var passwordLength = data.password.toString().length || 0;
        var adminPinLength = data.adminPin.toString().length || 0;
        var txData = new Uint8Array(7 + passwordLength + adminPinLength);
        var cmd = wolfProtCommands.WPC_CB_LOGIN;
        var mapping = {
            'user': wolfProtParameters.WPP_LOGIN_USER,
            'admin': wolfProtParameters.WPP_LOGIN_ADMIN,
            'collab': wolfProtParameters.WPP_LOGIN_COLLAB,
            'viewer': wolfProtParameters.WPP_LOGIN_VIEWER
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = passwordLength + adminPinLength + 3;
        txData[4] = mapping[data.accessLevel];
        txData[5] = passwordLength;

        for (var i = 0; i < data.password.toString().length; i++) {
            txData[i + 6] = data.password.toString().charCodeAt(i);
        }

        txData[6 + passwordLength] = adminPinLength;

        for (var p = 0; p < data.adminPin.toString().length; p++) {
            txData[p + 7 + passwordLength] = data.adminPin.toString().charCodeAt(p);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the admin password.
     *
     * @param data.password: the password as string
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAdminPassword: function setAdminPassword(data, socket) {
        var length = data.password.length || 0;
        var txData = new Uint8Array(4 + length);
        var cmd = wolfProtCommands.WPC_CB_ADMIN_PASSWORD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = length;

        for (var i = 0; i < data.password.length; i++) {
            txData[i + 4] = data.password.charCodeAt(i);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get user password
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getUserPassword: function getUserPassword(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_USER_PASSWORD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the user password
     * @method setUserPassword
     * @param data.password: the password as string
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setUserPassword: function setUserPassword(data, socket) {
        var length = data.password.length || 0;
        var txData = new Uint8Array(4 + length);
        var cmd = wolfProtCommands.WPC_CB_USER_PASSWORD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = length;

        for (var i = 0; i < data.password.length; i++) {
            txData[i + 4] = data.password.charCodeAt(i);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },
    /**
     * Stops the presentation.
     *
     * @param data.removeRecordings: true => remove recordings
     * @param data.removeSnapshots: true => remove snapshots
     * @param data.removeWhiteboards: true => remove whiteboards
     * @param data.state: end presentation, screensaver, screen off, standby
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setEndPresentation: function setEndPresentation(data, socket) {
        const txData = new Uint8Array(8);
        const cmd = wolfProtCommands.WPC_CB_END_PRESENTATION;
        const mapping = {
            'new-presentation': wolfProtParameters.WPP_CB_END_PRESENTATION_NEW_PRESENTATION,
            'screensaver': wolfProtParameters.WPP_CB_END_PRESENTATION_SCREENSAVER,
            'screen-off': wolfProtParameters.WPP_CB_END_PRESENTATION_SCREEN_OFF,
            'standby': wolfProtParameters.WPP_CB_END_PRESENTATION_STANDBY
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = data.removeRecordings === true ? 0x01 : 0x00;
        txData[5] = data.removeSnapshots === true ? 0x01 : 0x00;
        txData[6] = mapping[data.state];
        txData[7] = data.removeWhiteboards === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * opens the given file
     * @method setOpenFile
     * @param data.filename: the file that should be opened
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setOpenFile: function setOpenFile(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.filename);
        var txData = new Uint8Array(5 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_OPEN_FILE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (tempArray.length >> 8);
        txData[4] = tempArray.length & 0xFF;

        txData.set(tempArray, 5);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * enables disables a cloud service
     * @method setCloudConnection
     * @param data.cloudService: the service e.g. 'dropbox', 'gdrive',...
     * @param data.enableService: true => connect, false => disconnect
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setCloudServiceEnable: function setCloudServiceEnable(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = wolfProtCommands.WPC_CB_CLOUD_SERVICE_ENABLE;
        var serviceMapping = {
            'dropbox': wolfProtParameters.WPP_CLOUD_SERVICE_DROPBOX,
            'gdrive': wolfProtParameters.WPP_CLOUD_SERVICE_GDRIVE,
            'jianguoyun': wolfProtParameters.WPP_CLOUD_SERVICE_JIANGUOYUN,
            'onedrive': wolfProtParameters.WPP_CLOUD_SERVICE_ONEDRIVE,
            'webdav': wolfProtParameters.WPP_CLOUD_SERVICE_WEBDAV
        };
        var cloudService = data.cloudService || 'dropbox';

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 2;
        txData[4] = serviceMapping[cloudService];
        txData[5] = data.enableService === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * returns the status of the cloud drives
     * @method getCloudServicesEnabled
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getCloudServicesEnabled: function getCloudServicesEnabled(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_CLOUD_SERVICE_ENABLE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * opens/ closes a connection to a cloud service
     * @method setCloudConnection
     * @param data.cloudService: the service e.g. 'dropbox', 'gdrive',...
     * @param data.connectionStatus: true => connect, false => disconnect
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setCloudConnection: function setCloudConnection(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = wolfProtCommands.WPC_CB_CLOUD_CONNECT;
        var serviceMapping = {
            'dropbox': wolfProtParameters.WPP_CLOUD_SERVICE_DROPBOX,
            'gdrive': wolfProtParameters.WPP_CLOUD_SERVICE_GDRIVE,
            'jianguoyun': wolfProtParameters.WPP_CLOUD_SERVICE_JIANGUOYUN,
            'onedrive': wolfProtParameters.WPP_CLOUD_SERVICE_ONEDRIVE,
            'webdav': wolfProtParameters.WPP_CLOUD_SERVICE_WEBDAV
        };
        var cloudService = data.cloudService || 'dropbox';

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 2;
        txData[4] = serviceMapping[cloudService];
        txData[5] = data.connectionStatus === true ? 1 : 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * preloads the given file
     * @method setCloudPreload
     * @param data.filename: the file that should be preloaded
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setCloudPreload: function setCloudPreload(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.filename);
        var length = tempArray.length + 1;
        var txData = new Uint8Array(5 + length);
        var cmd = wolfProtCommands.WPC_CB_CLOUD_PRELOAD;
        var action = data.action || 'start';
        var mapping = {
            'open': wolfProtParameters.WPP_CLOUD_PRELOAD_START_AND_OPEN,
            'preload': wolfProtParameters.WPP_CLOUD_PRELOAD_START,
            'abort': wolfProtParameters.WPP_CLOUD_PRELOAD_ABORT
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8);
        txData[4] = length & 0xFF;
        txData[5] = mapping[action];

        txData.set(tempArray, 6);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * returns the status of the cloud drives
     * @method getCloudStatus
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getCloudStatus: function getCloudStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_CLOUD_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * PDF control
     * @method setPdfControl
     * @param data.cmd {(zoom-in|zoom-out|move-down|move-up|move-right|move-left|nextPage|prevPage
     * |setPage|fullHeight|fullWidth|fullPage|scrollDown|scrollUp|scrollRight|scrollLeft)}: the control command
     * @param data.appId: the ID of the application where this command should be applied to
     * @param data.param: the page number in case control is setPage
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setPdfControl: function setPdfControl(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_CONTROL_SPECIFIC_APPLICATION;
        let length = 10;

        if (data.cmd === 'setPage') {
            length += 2
        }

        let txData = new Uint8Array(5 + length);

        const mapping = {
            'zoom-in': wolfProtParameters.WPP_ZOOM_IN,
            'zoom-out': wolfProtParameters.WPP_ZOOM_OUT,
            'move-down': wolfProtParameters.WPP_MOVE_DOWN,
            'move-up': wolfProtParameters.WPP_MOVE_UP,
            'move-right': wolfProtParameters.WPP_MOVE_RIGHT,
            'move-left': wolfProtParameters.WPP_MOVE_LEFT,
            'nextPage': wolfProtParameters.WPP_PDF_NEXT_PAGE,
            'prevPage': wolfProtParameters.WPP_PDF_PREV_PAGE,
            'setPage': wolfProtParameters.WPP_PDF_SET_PAGE,
            'fullHeight': wolfProtParameters.WPP_PDF_FULL_HEIGHT,
            'fullWidth': wolfProtParameters.WPP_PDF_FULL_WIDTH,
            'fullPage': wolfProtParameters.WPP_PDF_FULL_PAGE,
            'scrollDown': wolfProtParameters.WPP_PDF_SCROLL_DOWN,
            'scrollUp': wolfProtParameters.WPP_PDF_SCROLL_UP,
            'scrollRight': wolfProtParameters.WPP_PDF_SCROLL_RIGHT,
            'scrollLeft': wolfProtParameters.WPP_PDF_SCROLL_LEFT
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8) & 0xFF;
        txData[4] = length & 0xFF;
        txData[5] = CONTENT_MAPPING['pdf'];
        txData.set(helper.fromHexString(data.appId), 6);
        txData[14] = mapping[data.cmd];

        if (data.cmd === 'setPage') {
            txData[15] = (data.param >> 8) & 0xFF;
            txData[16] = data.param & 0xFF;
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Office control
     * @method setOfficeControl
     * @param data.officeControl {(zoomIn|zoomOut|cursorDown|cursorUp|cursorRight|cursorLeft|nextPage|prevPage
     * |nextWorksheet|prevWorksheet|fullPage|fullWidth|scrollDown|scrollUp|scrollRight|scrollLeft)}: the control command
     * @param data.appId: the ID of the application where this command should be applied to
     * @param data.type {presentation|text|calc}: the application type
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setOfficeControl: function setOfficeControl(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_CONTROL_SPECIFIC_APPLICATION;
        let txData = new Uint8Array(15);
        const mapping = {
            'zoomIn': wolfProtParameters.WPP_OFFICE_ZOOM_IN,
            'zoomOut': wolfProtParameters.WPP_OFFICE_ZOOM_OUT,
            'cursorDown': wolfProtParameters.WPP_OFFICE_CURSOR_DOWN,
            'cursorUp': wolfProtParameters.WPP_OFFICE_CURSOR_UP,
            'cursorRight': wolfProtParameters.WPP_OFFICE_CURSOR_RIGHT,
            'cursorLeft': wolfProtParameters.WPP_OFFICE_CURSOR_LEFT,
            'nextPage': wolfProtParameters.WPP_OFFICE_NEXT_PAGE,
            'prevPage': wolfProtParameters.WPP_OFFICE_PREV_PAGE,
            'nextWorksheet': wolfProtParameters.WPP_OFFICE_NEXT_WORKSHEET,
            'prevWorksheet': wolfProtParameters.WPP_OFFICE_PREV_WORKSHEET,
            'fullPage': wolfProtParameters.WPP_OFFICE_FULL_PAGE,
            'fullWidth': wolfProtParameters.WPP_OFFICE_FULL_WIDTH,
            'scrollDown': wolfProtParameters.WPP_OFFICE_SCROLL_DOWN,
            'scrollUp': wolfProtParameters.WPP_OFFICE_SCROLL_UP,
            'scrollRight': wolfProtParameters.WPP_OFFICE_SCROLL_RIGHT,
            'scrollLeft': wolfProtParameters.WPP_OFFICE_SCROLL_LEFT
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;
        txData[4] = 0x0A;
        txData[5] = CONTENT_MAPPING[data.type];
        txData.set(helper.fromHexString(data.appId), 6);
        txData[14] = mapping[data.officeControl];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * does a snapshot on the cbox
     * @method setSnapshot
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setSnapshot: function setSnapshot(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_SNAPSHOT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * @method setMainResolution
     * @param data.resolution: the resolution that should be set e.g.: '1080p60', '2160p30', '2160p60', 'auto'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMainResolution: function setMainResolution(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MAIN_RESOLUTION;
        var resolution = data.resolution || 'auto';

        var mapping = {
            '720p60': wolfProtParameters.WPP_CB_MAIN_RESOLUTION_720P60,
            '1080p30': wolfProtParameters.WPP_CB_MAIN_RESOLUTION_1080P30,
            '1080p60': wolfProtParameters.WPP_CB_MAIN_RESOLUTION_1080P60,
            '2160p30': wolfProtParameters.WPP_CB_MAIN_RESOLUTION_2160P30,
            '2160p60': wolfProtParameters.WPP_CB_MAIN_RESOLUTION_2160P60,
            'auto': wolfProtParameters.WPP_CB_MAIN_RESOLUTION_AUTO,
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 1;
        txData[4] = mapping[resolution];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * @method getMainResolution
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMainResolution: function getMainResolution(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MAIN_RESOLUTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * checks if we need a password
     * @method getRemotePasswordRequired
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getRemotePasswordRequired: function getRemotePasswordRequired(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MEETING_MODE_PASSWORD_REQUIRED;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets meeting mode password required
     * @method setRemotePasswordRequired
     * @param data.pwRequired: true or false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setRemotePasswordRequired: function setRemotePasswordRequired(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MEETING_MODE_PASSWORD_REQUIRED;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 1;
        txData[4] = data.pwRequired === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the current login level
     * @method getLoginLevel
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLoginLevel: function getLoginLevel(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LOGIN_LEVEL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the pin destination
     * @method getPinDestination
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getPinDestination: function getPinDestination(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PIN_DESTINATION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the local pin status
     * @method getPinStatusLocal
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getPinStatusLocal: function getPinStatusLocal(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PIN_STATUS_LOCAL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the pin destinations
     * @method setPinDestination
     * @param data.destinations: the destinations where the pin should be displayed as an array with one of following:
     *                           'box','vz','local','room'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setPinDestination: function setPinDestination(data, socket) {
        var txData = new Uint8Array(9);
        var cmd = wolfProtCommands.WPC_CB_PIN_DESTINATION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 5;
        txData[4] = data.destinations.indexOf('vz') === -1 ? 0x00 : 0x01;
        txData[5] = data.destinations.indexOf('box') === -1 ? 0x00 : 0x01;
        txData[6] = data.destinations.indexOf('local') === -1 ? 0x00 : 0x01;
        txData[7] = data.destinations.indexOf('room') === -1 ? 0x00 : 0x01;
        txData[8] = data.destinations.indexOf('hdmi2') === -1 ? 0x00 : 0x01;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the password type
     * @method setPasswordType
     * @param data.pwType: 'pin' or 'password'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setPasswordType: function setPasswordType(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_PASSWORD_TYPE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 1;
        txData[4] = data.pwType === 'pin' ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the password type
     * @method getPasswordType
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getPasswordType: function getPasswordType(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PASSWORD_TYPE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the browser status
     * @method getBrowserStatus
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getBrowserStatus: function getBrowserStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_BROWSER_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * save the user settings
     * @method setUserSettings
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setUserSettings: function setUserSettings(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_USER_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the microphone mute state
     *
     * @param data.mute: 0 = off; 1 = on; 2 = toggle
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMicMute: function setMicMute(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MIC_MUTE;
        var mapping = {
            'off': 0,
            'on': 1,
            'toggle': 2
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.mute];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the mic mute
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMicMute: function getMicMute(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MIC_MUTE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.USER),
            cmd: cmd
        };

    },

    /**
     * sets the master mute state
     *
     * @param data.mute: 0 = off; 1 = on; 2 = toggle
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMasterMute: function setMasterMute(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MASTER_MUTE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.mute;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the master mute
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMasterMute: function getMasterMute(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MASTER_MUTE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.USER),
            cmd: cmd
        };

    },

    /**
     * sets the master volume
     *
     * @param data.volume: 0 - 100
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMasterVolume: function setMasterVolume(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MASTER_VOLUME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.volume;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the master volume
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMasterVolume: function getMasterVolume(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MASTER_VOLUME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the main freeze
     *
     * @param data.freeze: 0 = off; 1 = on; 2 = toggle
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMainFreeze: function setMainFreeze(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MAIN_FREEZE;
        var mapping = {
            'off': 0,
            'on': 1,
            'toggle': 2
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.freeze];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the main freeze
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMainFreeze: function getMainFreeze(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MAIN_FREEZE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.USER),
            cmd: cmd
        };

    },

    /**
     * Sets the freeze state of the edit content
     *
     * @param data.freeze: 0 = off; 1 = on; 2 = toggle
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setEditContentFreeze: function setEditContentFreeze(data, socket) {
        const txData = new Uint8Array(5);
        const cmd = wolfProtCommands.WPC_CB_EDIT_CONTENT_FREEZE;
        const mapping = {
            'off': wolfProtParameters.WPP_FREEZE_OFF,
            'on': wolfProtParameters.WPP_FREEZE_ON,
            'toggle': wolfProtParameters.WPP_FREEZE_TOGGLE,
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.freeze];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Gets the freeze state of the edit content
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEditContentFreeze: function getEditContentFreeze(data, socket) {
        const txData = new Uint8Array(4);
        const cmd = wolfProtCommands.WPC_CB_EDIT_CONTENT_FREEZE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the aux mode
     *
     * @param data.mode: (eg.  'confidenceMirror',
     *                         'confidenceFullHD',
     *                         'confidenceHD',
     *                         'contentFullHD',
     *                         'contentHD'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAuxMode: function setAuxMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_AUX_MODE;
        var mapping = {
            'confidenceMirror': wolfProtParameters.WPP_AUX_MODE_CONFIDENCE_MIRROR,
            'confidenceFullHD': wolfProtParameters.WPP_AUX_MODE_CONFIDENCE_FULLHD,
            'confidenceHD': wolfProtParameters.WPP_AUX_MODE_CONFIDENCE_HD,
            'contentFullHD': wolfProtParameters.WPP_AUX_MODE_CONTENT_FULLHD,
            'contentHD': wolfProtParameters.WPP_AUX_MODE_CONTENT_HD,
            'moderatorFullHD': wolfProtParameters.WPP_AUX_MODE_MODERATOR_FULLHD,
            'moderatorHD': wolfProtParameters.WPP_AUX_MODE_MODERATOR_HD
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.mode];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the aux mode
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAuxMode: function getAuxMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_AUX_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the streaming interface
     *
     * @param data.interface: (eg.  'lan',
     *                              'wlan'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setStreamingInterface: function setStreamingInterface(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_STREAMING_INTERFACE;
        var mapping = {
            'lan1': wolfProtParameters.WPP_STREAMING_INTERFACE_LAN1,
            'wlan': wolfProtParameters.WPP_STREAMING_INTERFACE_WLAN
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.interface];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the streaming interface
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getStreamingInterface: function getStreamingInterface(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_STREAMING_INTERFACE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the streaming function
     * @method setStreamingFunction
     * @param data.streamingFunction: streaming enabled/disabled
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setStreamingFunction: function setStreamingFunction(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_STREAMING_FUNCTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.function === true ? wolfProtParameters.WPP_STREAMING_FUNCTION_ENABLE : wolfProtParameters.WPP_STREAMING_FUNCTION_DISABLE;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the streaming function
     * @method getStreamingFunction
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getStreamingFunction: function getStreamingFunction(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_STREAMING_FUNCTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the wlan region
     *
     * @param data.region: (eg.  'us',
     *                           'others'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanRegion: function setWlanRegion(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_REGION;
        var mapping = {
            'us': wolfProtParameters.WPP_WLAN_REGION_US,
            'others': wolfProtParameters.WPP_WLAN_REGION_OTHERS
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.region];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wlan region
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanRegion: function getWlanRegion(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_REGION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the wlan certificate mode
     *
     * @param data.mode: true => on, false => off
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanCertificateMode: function setWlanCertificateMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_CERTIFICATE_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.mode === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the wlan certificate mode
     * @method getWlanCertificateMode
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanCertificateMode: function getWlanCertificateMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_CERTIFICATE_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the wlan certificate file name
     *
     * @param data.name: the filename of the certificate
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanCertificateFileName: function setWlanCertificateFileName(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.name);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_WLAN_CERTIFICATE_FILE_NAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the wlan certificate filename
     * @method getWlanCertificateFileName
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanCertificateFileName: function getWlanCertificateFileName(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_CERTIFICATE_FILE_NAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the wlan certificate file
     *
     * @param data.name: the filename
     * @param data.file: the file as ByteArray
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanCertificateFile: function setWlanCertificateFile(data, socket) {
        var tempArrayName = StringConvert.stringToUtf8(data.name);
        var tempArrayFile = !!data.file ? new Uint8Array(data.file) : new Uint8Array(0);
        var txData = new Uint8Array(10 + tempArrayName.length + tempArrayFile.length);
        var cmd = wolfProtCommands.WPC_CB_WLAN_CERTIFICATE_FILE;
        var length = tempArrayFile.length + tempArrayName.length + 2;
        var mapping = {
            'delete': wolfProtParameters.WPP_CB_CERTIFICATE_FILE_DELETE,
            'save': wolfProtParameters.WPP_CB_CERTIFICATE_FILE_SAVE,
            'verify': wolfProtParameters.WPP_CB_CERTIFICATE_FILE_VERIFY
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_HDR | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = wolfProtHeaders.WP_EXTHEAD_EXT_LEN;
        txData[2] = (cmd >> 8) & 0xFF;
        txData[3] = cmd & 0xFF;
        txData[4] = (length >> 24) & 0xff;
        txData[5] = (length >> 16) & 0xff;
        txData[6] = (length >> 8) & 0xff;
        txData[7] = length & 0xff;
        txData[8] = mapping[data.action];
        txData[9] = tempArrayName.length;

        txData.set(tempArrayName, 10);
        txData.set(tempArrayFile, 10 + tempArrayName.length);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * starts scan of AP list
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setStartWlanAccesspointScan: function setStartWlanAccesspointScan(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_AP_LIST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the wlan accesspoint list status
     * @method getWlanApListStatus
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanApListStatus: function getWlanApListStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_AP_LIST_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the wlan username
     * @method getWlanUsername
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanUsername: function getWlanUsername(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_USERNAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the wlan username
     *
     * @param data.name: the username
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanUsername: function setWlanUsername(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.name);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_WLAN_USERNAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the wlan anonymous identity
     *
     * @param data.identity: the identity
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanAnonymousIdentity: function setWlanAnonymousIdentity(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.name);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_WLAN_ANONYMOUS_IDENTITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the wlan anonymous identity
     * @method getWlanAnonymousIdentity
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanAnonymousIdentity: function getWlanAnonymousIdentity(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_ANONYMOUS_IDENTITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the wlan authentication method
     *
     * @param data.authMethode: (eg.  'peap-mschapv2',
     *                           'ttls-pap'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanAuthMethod: function setWlanAuthMethod(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_AUTH_METHOD;
        var mapping = {
            'peap-mschapv2': wolfProtParameters.WPP_CB_WLAN_AUTH_METHOD_PEAP_MSCHAPV2,
            'ttls-pap': wolfProtParameters.WPP_CB_WLAN_AUTH_METHOD_TTLS_PAP
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.authMethod];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wlan authentication method
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanAuthMethod: function getWlanAuthMethod(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_AUTH_METHOD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the image status
     * @method getImageStatus
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getImageStatus: function getImageStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_IMAGE_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the status bar settings
     *
     * @param data.networkInterface: 0 = nothing; 1 = lan ip; 2 = wlan ip;
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setStatusBarSettings: function setStatusBarSettings(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_STATUSBAR_SETTINGS;
        var mapping = {
            'nothing': wolfProtParameters.WPP_STATUSBAR_SETTINGS_NONE,
            'lan': wolfProtParameters.WPP_STATUSBAR_SETTINGS_LAN,
            'wlan': wolfProtParameters.WPP_STATUSBAR_SETTINGS_WLAN,
            'both': wolfProtParameters.WPP_STATUSBAR_SETTINGS_BOTH
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.networkInterface];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the statusbar settings
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getStatusBarSettings: function getStatusBarSettings(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_STATUSBAR_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the vz setting state
     *
     * @param data.cmd: the command, where we want to know the setting
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVzDirectSetting: function getVzDirectSetting(data, socket) {
        var cmd = data.cmd || 0;
        var txData;

        if (data.cmd < 256) {
            txData = new Uint8Array(3);

            txData[0] = wolfProtHeaders.WP_HEAD_DIR_GET;
            txData[1] = cmd & 0xFF;
            txData[2] = 0x00;
        } else {
            txData = new Uint8Array(4);

            txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
            txData[1] = (cmd >> 8) & 0xFF;
            txData[2] = cmd & 0xFF;
            txData[3] = 0x00;
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the vz setting state
     *
     * @param data.cmd: the command, where we want to know the setting
     * @param data.param: the param which we want to set
     * @param data.paramLength: the parameter length in bytes
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVzDirectSetting: function setVzDirectSetting(data, socket) {
        var cmd = data.cmd || 0;
        var txData;
        var i;

        var lengthParam = data.paramLength || calcNumBytes(data.param);
        var lengthCmd = calcNumBytes(data.cmd);

        txData = new Uint8Array(lengthParam + lengthCmd + 2);

        if (data.param < 0) {
            data.param = Math.pow(2, lengthParam * 8) + data.param;
        }

        if (lengthCmd === 2) {
            txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
            txData[1] = (cmd >> 8) & 0xFF;
            txData[2] = cmd & 0xFF;
            txData[3] = lengthParam;

            for (i = 0; i < lengthParam; i++) {
                txData[i + 4] = data.param >> ((lengthParam - i - 1) * 8) & 0xFF;
            }

        } else if (lengthCmd === 1) {

            txData[0] = wolfProtHeaders.WP_HEAD_DIR_SET;
            txData[1] = cmd & 0xFF;
            txData[2] = lengthParam;

            for (i = 0; i < lengthParam; i++) {
                txData[i + 3] = data.param >> ((lengthParam - i - 1) * 8) & 0xFF;
            }

        } else {
            console.log('invalid command length');
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the vz osd
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVzDirectOsd: function getVzDirectOsd(data, socket) {
        var txData = new Uint8Array(3);
        var cmd = wolfProtCommands.WPC_OSD_LAYOUT;

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd & 0xFF;
        txData[2] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the vz model
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVzDirectModel: function getVzDirectModel(data, socket) {
        var txData = new Uint8Array(3);
        var cmd = wolfProtCommands.WPC_MODEL;

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd & 0xFF;
        txData[2] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * @deprecated getVzStatus is used instead
     *
     * requests the vz af state
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVzDirectAFState: function getVzDirectAFState(data, socket) {
        var txData = new Uint8Array(3);
        var cmd = wolfProtCommands.WPC_AF;

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd & 0xFF;
        txData[2] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * @deprecated getVzStatus is used instead
     *
     * requests the vz freeze state
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVzDirectFreezeState: function getVzDirectFreezeState(data, socket) {
        var txData = new Uint8Array(3);
        var cmd = wolfProtCommands.WPC_FREEZE;

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd & 0xFF;
        txData[2] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * @deprecated getVzStatus is used instead
     *
     * requests the vz power state
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVzDirectPowerState: function getVzDirectPowerState(data, socket) {
        var txData = new Uint8Array(3);
        var cmd = wolfProtCommands.WPC_POWER;

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd & 0xFF;
        txData[2] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * @deprecated setVzControl is used instead
     *
     * recalls preset at vz
     *
     * @param data.preset: the preset number (0=>Power On Preset, 1=>Preset1, 2=>Preset2, 3=>Preset3, 4=>Preset Default)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVzDirectRecallPreset: function setVzDirectRecallPreset(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_RECALL_PRESET;

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd;
        txData[2] = 0x01;
        txData[3] = data.preset;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * @deprecated setVzControl is used instead
     *
     * store preset at vz
     *
     * @param data.preset: the preset number (0=>Power On Preset, 1=>Preset1, 2=>Preset2, 3=>Preset3, 4=>Preset Default)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVzDirectStorePreset: function setVzDirectStorePreset(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_STORE_PRESET;

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd;
        txData[2] = 0x01;
        txData[3] = data.preset;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * @deprecated setVzControl is used instead
     *
     * moves zoom of VZ
     *
     * @param data.speed: speed from 0 - 16 where 0 is stop and 16 is full speed
     * @param data.direction 'wide' or 'tele'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVzDirectZoom: function setVzDirectZoom(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = wolfProtCommands.WPC_ZOOM;

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd;
        txData[2] = 0x03;
        txData[3] = data.direction === 'tele' ? 0x02 : 0x01;
        txData[4] = 0;
        txData[5] = data.speed;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * @deprecated setVzControl is used instead
     *
     * moves focus of VZ
     *
     * @param data.speed: speed from 0 - 16 where 0 is stop and 16 is full speed
     * @param data.direction 'near' or 'far'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVzDirectFocus: function setVzDirectFocus(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = wolfProtCommands.WPC_FOCUS;

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd;
        txData[2] = 0x03;
        txData[3] = data.direction === 'near' ? 0x02 : 0x01;
        txData[4] = 0;
        txData[5] = data.speed;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * @deprecated setVzControl is used instead
     *
     * set af of VZ
     *
     * @param data.setting: 'on', 'off' or 'toggle'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVzDirectAF: function setVzDirectAF(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_AF;
        var mapping = {
            'off': 0x00,
            'on': 0x01,
            'toggle': 0x02
        };

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd;
        txData[2] = 0x01;
        txData[3] = mapping[data.setting];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * @deprecated setVzControl is used instead
     *
     * set Freeze of VZ
     *
     * @param data.setting: 'on', 'off' or 'toggle'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVzDirectFreeze: function setVzDirectFreeze(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_FREEZE;
        var mapping = {
            'off': 0x00,
            'on': 0x01,
            'toggle': 0x02
        };

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd;
        txData[2] = 0x01;
        txData[3] = mapping[data.setting];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * @deprecated setVzControl is used instead
     *
     * set Power of VZ
     *
     * @param data.setting: 'on', 'off' or 'toggle'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVzDirectPower: function setVzDirectPower(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_POWER;
        var mapping = {
            'off': 0x00,
            'on': 0x01,
            'toggle': 0x02
        };

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd;
        txData[2] = 0x01;
        txData[3] = mapping[data.setting];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * @deprecated setVzControl is used instead
     *
     * set VZ Capture Area Shift
     *
     * @param data.setting: 'on', 'off' or 'toggle'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVzDirectCAS: function setVzDirectCAS(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CAPTURE_AREA_SHIFT;
        var mapping = {
            'off': wolfProtParameters.WPP_CAPTURE_AREA_SHIFT_OFF,
            'on': wolfProtParameters.WPP_CAPTURE_AREA_SHIFT_ON,
            'toggle': wolfProtParameters.WPP_CAPTURE_AREA_SHIFT_TOGGLE
        };

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd;
        txData[2] = 0x01;
        txData[3] = mapping[data.setting];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * @deprecated getVzStatus is used instead
     *
     * requests the VZ Capture Area Shift
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVzDirectCASState: function getVzDirectCASState(data, socket) {
        var txData = new Uint8Array(3);
        var cmd = wolfProtCommands.WPC_CAPTURE_AREA_SHIFT;

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd & 0xFF;
        txData[2] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * @deprecated setVzControl is used instead
     *
     * set Light of VZ
     *
     * @param data.setting: 'on', 'off' or 'toggle'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVzDirectLight: function setVzDirectLight(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_LIGHT;
        var mapping = {
            'off': wolfProtParameters.WPP_LIGHT_L_ON_LB_OFF,
            'on': wolfProtParameters.WPP_LIGHT_LB_ON_L_OFF,
            'toggle': wolfProtParameters.WPP_LIGHT_SEQ_TOGGLE
        };

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd;
        txData[2] = 0x01;
        txData[3] = mapping[data.setting];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * @deprecated getVzStatus is used instead
     *
     * requests the vz light state
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVzDirectLightState: function getVzDirectLightState(data, socket) {
        var txData = new Uint8Array(3);
        var cmd = wolfProtCommands.WPC_LIGHT;

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd & 0xFF;
        txData[2] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * @deprecated getVzStatus is used instead
     *
     * get vz features
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVzDirectFeatures: function getVzDirectFeatures(data, socket) {
        var txData = new Uint8Array(3);
        var cmd = wolfProtCommands.WPC_FEATURES;

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd & 0xFF;
        txData[2] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * set ftp mode
     *
     * @param data.mode: true => enabled, false=> disabled
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setFtpMode: function setFtpMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_FTP_MODE;
        var mapping = {
            false: 0x00,
            true: 0x01,
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.mode];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get ftp mode
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getFtpMode: function setFtpMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_FTP_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * set ftp url
     *
     * @param data.url: string containing the FTP URL (max 255 characters)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setFtpUrl: function setFtpUrl(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.url);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_FTP_URL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get ftp url
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getFtpUrl: function getFtpUrl(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_FTP_URL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * set ftp user
     *
     * @param data.user: string containing the FTP username (max 63 characters)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setFtpUser: function setFtpUser(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.user);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_FTP_USER;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get ftp user
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getFtpUser: function getFtpUser(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_FTP_USER;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * set ftp password
     *
     * @param data.password: string containing the FTP password (max 63 characters)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setFtpPassword: function setFtpPassword(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.password);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_FTP_PASS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get ftp password
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getFtpPassword: function getFtpPassword(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_FTP_PASS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get ftp test status
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getFtpTestStatus: function getFtpTestStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_FTP_TEST_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * set send ftp file
     *
     * @param data.path: string containing the path of the File that should be uploaded
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    sendFtpFile: function sendFtpFile(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.path);
        var txData = new Uint8Array(5 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_FTP_PATH;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_LEN | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (tempArray.length >> 8) & 0xff;
        txData[4] = (tempArray.length) & 0xff;

        txData.set(tempArray, 5);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * set copy file to usb
     *
     * @param data.path: string containing the path of the File that should be uploaded to usb stick
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setCopyFileUsb: function setCopyFileUsb(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.path);
        var txData = new Uint8Array(5 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_USB_COPY_FILE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_LEN | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (tempArray.length >> 8) & 0xff;
        txData[4] = (tempArray.length) & 0xff;

        txData.set(tempArray, 5);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * set factory reset
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setFactoryReset: function setFactoryReset(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_FACTORY_RESET;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Set system reboot
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setSystemReboot: function setSystemReboot(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_SYSTEM_REBOOT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get vz status
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVzStatus: function getVzStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_VZ_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.USER),
            cmd: cmd
        };

    },

    /**
     * set ftp test
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setFtpTest: function setFtpTest(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_FTP_TEST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get osd message
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getOsdMessage: function getOsdMessage(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_OSD_MSG;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.COLLAB),
            cmd: cmd
        };

    },

    /**
     * get hdmi input setup
     *
     * @param data.hdmiInput: the hdmiInput where we want to know the setting
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getHdmiInputSetup: function getHdmiInputSetup(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_HDMI_INPUT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.hdmiInput === 0 ? wolfProtParameters.WPP_HDMI_INPUT_1 : wolfProtParameters.WPP_HDMI_INPUT_2;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * set hdmi input setup
     *
     * @param data.hdmiInput: the hdmi input (0 or 1)
     * @param data.type: the type (e.g.:'visualizer', 'hdmi', 'computer' or 'discPlayer')
     * @param data.name: teh name of the input as string
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setHdmiInputSetup: function setHdmiInputSetup(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.name);
        var txData = new Uint8Array(6 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_HDMI_INPUT;
        var mapping = {
            'visualizer': wolfProtParameters.WPP_HDMI_INPUT_IS_VZ,
            'hdmi': wolfProtParameters.WPP_HDMI_INPUT_IS_HDMI,
            'computer': wolfProtParameters.WPP_HDMI_INPUT_IS_COMPUTER,
            'discPlayer': wolfProtParameters.WPP_HDMI_INPUT_IS_DISC_PLAYER,
            'camera': wolfProtParameters.WPP_HDMI_INPUT_IS_CAMERA
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length + 2;
        txData[4] = data.hdmiInput === 0 ? wolfProtParameters.WPP_HDMI_INPUT_1 : wolfProtParameters.WPP_HDMI_INPUT_2;
        txData[5] = mapping[data.type];
        txData.set(tempArray, 6);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get support year
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getSupportYear: function getSupportYear(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_SUPPORT_YEAR;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get www update status
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWwwUpdateStatus: function getWwwUpdateStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WWW_UDATE_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * set www update
     *
     * @param data.action: the action that should be done ('check', 'perform', 'cancel')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWwwUpdate: function setWwwUpdate(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WWW_UDATE_STATUS;
        var mapping = {
            'check': wolfProtParameters.WPP_WWW_UPDATE_CHECK,
            'start': wolfProtParameters.WPP_WWW_UPDATE_PERFORM,
            'cancel': wolfProtParameters.WPP_WWW_UPDATE_CANCEL
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.action];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get RAUC update status
     *
     * @param data.source: {(usb|web)} the update source
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getRaucUpdateStatus: function getRaucUpdateStatus(data, socket) {
        let txData = new Uint8Array(5);
        const cmd = wolfProtCommands.WPC_CB_RAUC_UPDATE_STATUS;
        const sourceMapping = {
            'usb': 0x00,
            'web': 0x01
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = sourceMapping[data.source];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * get cloud download list
     *
     * @param data.service: the cloud service where we want to get the download list or 'all' to get a merged list
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getCloudDownloadList: function getCloudDownloadList(data, socket) {
        var txData;
        var cmd = wolfProtCommands.WPC_CB_CLOUD_DOWNLOAD_LIST;
        var mapping = {
            'dropbox': wolfProtParameters.WPP_CLOUD_SERVICE_DROPBOX,
            'gdrive': wolfProtParameters.WPP_CLOUD_SERVICE_GDRIVE,
            'jianguoyun': wolfProtParameters.WPP_CLOUD_SERVICE_JIANGUOYUN,
            'onedrive': wolfProtParameters.WPP_CLOUD_SERVICE_ONEDRIVE,
            'webdav': wolfProtParameters.WPP_CLOUD_SERVICE_WEBDAV
        };

        if (data.service === 'all') {
            txData = new Uint8Array(4);
            txData[3] = 0;
        } else {
            txData = new Uint8Array(5);
            txData[3] = 0x01;
            txData[4] = mapping[data.service];
        }

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * removes the given file from the downloadlist
     * @method setRemoveFromDownloadList
     * @param data.filename: the file that should be removed from downloadlist
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setRemoveFromDownloadList: function setRemoveFromDownloadList(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.filename);
        var txData = new Uint8Array(5 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_RM_FROM_DOWNLOAD_LIST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (tempArray.length >> 8);
        txData[4] = tempArray.length & 0xFF;

        txData.set(tempArray, 5);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * deletes the given file
     * @method setDeleteFile
     * @param data.filename: the file that should be deleted
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setDeleteFile: function setDeleteFile(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.filename);
        var txData = new Uint8Array(5 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_DELETE_FILE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (tempArray.length >> 8);
        txData[4] = tempArray.length & 0xFF;

        txData.set(tempArray, 5);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * deletes the given folder
     * @method setDeleteFolder
     * @param data.path: the path of the folder that should be deleted
     * @param data.foldername: the folder that should be deleted
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setDeleteFolder: function setDeleteFolder(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.foldername);
        var paramLength = 1 + tempArray.length;
        var txData = new Uint8Array(6 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_DELETE_FOLDER;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (paramLength >> 8);
        txData[4] = paramLength & 0xFF;
        txData[5] = tempArray.length;

        txData.set(tempArray, 6);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * set power
     *
     * @param data.setting: the setting that should be done ('off', 'on', 'toggle')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setPower: function setPower(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_POWER;
        var mapping = {
            'off': wolfProtParameters.WPP_POWER_OFF,
            'on': wolfProtParameters.WPP_POWER_ON,
            'toggle': wolfProtParameters.WPP_POWER_TOGGLE
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.setting];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get power status
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getPowerStatus: function getPowerStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_POWER;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * set EULA
     *
     * @param data.accept: true of false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setEulaAccept: function setEulaAccept(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_EULA_ACCEPT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.accept === true ? 0x00 : 0x01;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get eula status
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEulaStatus: function getEulaStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_EULA_ACCEPT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * set HDCP Settings
     *
     * @param data.interface: 'mainout', 'auxout', 'in1', 'in2'
     * @param data.enable: true of false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setHdcpSettings: function setHdcpSettings(data, socket) {
        var txData = new Uint8Array(5);
        var mapping = {
            'mainout': wolfProtCommands.WPC_CB_HDCP_MAIN_OUT,
            'auxout': wolfProtCommands.WPC_CB_HDCP_AUX_OUT,
            'in1': wolfProtCommands.WPC_CB_HDCP_IN1,
            'in2': wolfProtCommands.WPC_CB_HDCP_IN2
        };

        var cmd = mapping[data.interface];

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enable === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get HDCP Settings
     *
     * @param data.interface: 'mainout', 'auxout', 'in1', 'in2'
     * @param data.enable: true of false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getHdcpSettings: function getHdcpSettings(data, socket) {
        var txData = new Uint8Array(4);
        var mapping = {
            'mainout': wolfProtCommands.WPC_CB_HDCP_MAIN_OUT,
            'auxout': wolfProtCommands.WPC_CB_HDCP_AUX_OUT,
            'in1': wolfProtCommands.WPC_CB_HDCP_IN1,
            'in2': wolfProtCommands.WPC_CB_HDCP_IN2
        };

        var cmd = mapping[data.interface];

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the background image
     *
     * @param data.name: the filename or undefined to use default background image
     * @param data.file: the file as ByteArray
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setBackgroundImageFile: function setBackgroundImageFile(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_BACKGROUND_IMAGE;
        var length;
        var txData;

        if (data.name === undefined) {
            length = 0;
            txData = new Uint8Array(8);
        } else {
            var tempArrayFile = new Uint8Array(data.file);
            txData = new Uint8Array(8 + tempArrayFile.length);
            length = tempArrayFile.length;

            txData.set(tempArrayFile, 8);
        }

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_HDR | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = wolfProtHeaders.WP_EXTHEAD_EXT_LEN;
        txData[2] = (cmd >> 8) & 0xFF;
        txData[3] = cmd & 0xFF;
        txData[4] = (length >> 24) & 0xff;
        txData[5] = (length >> 16) & 0xff;
        txData[6] = (length >> 8) & 0xff;
        txData[7] = length & 0xff;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get storage space left
     *
     * @param data.unit: the unit that we want (actually only MB)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getStorageSpaceLeft: function getStorageSpaceLeft(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_STORAGE_SPACE_LEFT;
        var mapping = {
            'MB': wolfProtParameters.WPP_STORAGE_SPACE_UNIT_MB
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.unit];

        return {
            promise: socket.send(txData, wolfProtUserLevels.COLLAB),
            cmd: cmd
        };

    },

    /**
     * get firmware version
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getFirmwareVersion: function getFirmwareVersion(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_FIRMWARE_VERSION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get local touch keyboard
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLocalTouchKeyboard: function getLocalTouchKeyboard(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LOCAL_TOUCH_KEYBOARD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * set local touch keyboard
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLocalTouchKeyboard: function setLocalTouchKeyboard(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LOCAL_TOUCH_KEYBOARD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enabled === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get capslock status
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getCapslockStatus: function getCapslockStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_CAPSLOCK_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * set timezone
     *
     * @param data.timezone: the number for the timezone
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setTimezone: function setTimezone(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_TIMEZONE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.timezoneIndex;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get timezone
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getTimezone: function getTimezone(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_TIMEZONE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get qr code visible
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getQrCode: function getQrCode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_QR_CODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.streamIndex || 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * set qr code visible
     *
     * @param data.visible: true or false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setQrCodeVisible: function setQrCodeVisible(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_QR_CODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.visible === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * get access token
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAccessToken: function getAccessToken(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ACCESS_TOKEN;
        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * set access token
     *
     * @param data.accessToken: true or false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAccessToken: function setAccessToken(data, socket) {
        var txData = new Uint8Array(12);
        var cmd = wolfProtCommands.WPC_CB_ACCESS_TOKEN;
        var accessToken = parseInt(data.accessToken, 16);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x08;
        txData[4] = Math.floor(accessToken / Math.pow(2, 56)) % 256;
        txData[5] = Math.floor(accessToken / Math.pow(2, 48)) % 256;
        txData[6] = Math.floor(accessToken / Math.pow(2, 40)) % 256;
        txData[7] = Math.floor(accessToken / Math.pow(2, 32)) % 256;
        txData[8] = Math.floor(accessToken / Math.pow(2, 24)) % 256;
        txData[9] = Math.floor(accessToken / Math.pow(2, 16)) % 256;
        txData[10] = Math.floor(accessToken / Math.pow(2, 8)) % 256;
        txData[11] = accessToken % 256;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Get extra settings (file).
     *
     * @param data.filename: the filename of the settings file
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getExtraSettingsFile: function getExtraSettingsFile(data, socket) {
        var uint8Filename = StringConvert.stringToUtf8(data.filename || '');
        var txData = new Uint8Array(5 + uint8Filename.length);
        var cmd = wolfProtCommands.WPC_CB_XTRA_SETTINGS;
        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = uint8Filename.length + 1;
        txData[4] = uint8Filename.length;
        txData.set(uint8Filename, 5);

        return {
            promise: socket.send(txData, wolfProtUserLevels.USER),
            cmd: cmd
        };

    },

    /**
     * Set extra settings (string).
     *
     * @param data.filename: the filename of the settings file
     * @param data.string: the string that should be saved
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setExtraSettings: function setExtraSettings(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_XTRA_SETTINGS;
        var uint8Filename = StringConvert.stringToUtf8(data.filename || '');
        var uint8Content = StringConvert.stringToUtf8(data.string || '');
        var txData = new Uint8Array(9 + uint8Filename.length + uint8Content.length);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_HDR | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = wolfProtHeaders.WP_EXTHEAD_EXT_LEN;
        txData[2] = (cmd >> 8) & 0xFF;
        txData[3] = cmd & 0xFF;
        valueToBuffer(txData.length - 8, txData, 4, 4);
        txData[8] = uint8Filename.length;
        txData.set(uint8Filename, 9);
        txData.set(uint8Content, 9 + uint8Filename.length);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get extra settings (file).
     *
     * @param data.filename: the filename of the settings file
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getExtraSettings: function getExtraSettings(data, socket) {
        var uint8Filename = StringConvert.stringToUtf8(data.filename || '');
        var txData = new Uint8Array(5 + uint8Filename.length);
        var cmd = wolfProtCommands.WPC_CB_XTRA_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = uint8Filename.length + 1;
        txData[4] = uint8Filename.length;
        txData.set(uint8Filename, 5);

        return {
            promise: socket.send(txData, wolfProtUserLevels.VIEWER),
            cmd: cmd
        };

    },

    /**
     * Set extra settings (file).
     *
     * @param data.name: the filename or undefined to use default background image
     * @param data.file: the file as ByteArray
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setExtraSettingsFile: function setExtraSettingsFile(data, socket) {
        var tempArrayName = StringConvert.stringToUtf8(data.filename);
        var tempArrayFile = data.file ? new Uint8Array(data.file) : new Uint8Array(0);
        var txData = new Uint8Array(9 + tempArrayName.length + tempArrayFile.length);
        var cmd = wolfProtCommands.WPC_CB_XTRA_SETTINGS;
        var length = tempArrayFile.length + tempArrayName.length + 1;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_HDR | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = wolfProtHeaders.WP_EXTHEAD_EXT_LEN;
        txData[2] = (cmd >> 8) & 0xFF;
        txData[3] = cmd & 0xFF;
        txData[4] = (length >> 24) & 0xff;
        txData[5] = (length >> 16) & 0xff;
        txData[6] = (length >> 8) & 0xff;
        txData[7] = length & 0xff;
        txData[8] = tempArrayName.length;

        txData.set(tempArrayName, 9);
        txData.set(tempArrayFile, 9 + tempArrayName.length);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * get user data status
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getUserDataStatus: function getUserDataStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LOAD_USER_DATA;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * set load user data
     *
     * @param data.load: true or false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLoadUserData: function setLoadUserData(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LOAD_USER_DATA;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.load === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * set pin visiblity
     *
     * @param data.showPin: true or false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setPinVisibility: function setPinVisibility(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_SHOW_PIN;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.showPin === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get sources command
     *
     * @return cmd: the command number
     */
    getContentSources: function getContentSources(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_GET_CONTENT_SOURCES;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get free window command
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWindowFree: function getWindowFree(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_GET_WINDOW_FREE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get recording enable / disable
     * @method getRecordingFunction
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getRecordingFunction: function getRecordingFunction(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_RECORDING_FUNCTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * enable / disable recording
     * @method setRecordingFunction
     * @param data: enable/disable
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setRecordingFunction: function setRecordingFunction(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_RECORDING_FUNCTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.recordingEnabled === false) ? wolfProtParameters.WPP_CB_RECORDING_DISABLE : wolfProtParameters.WPP_CB_RECORDING_ENABLE;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get recording rename enable / disable
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getRecordingRename: function getRecordingRename(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_RECORDING_RENAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * enable / disable rename recording
     *
     * @param data: enable/disable
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setRecordingRename: function setRecordingRename(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_RECORDING_RENAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.recordingRenameEnabled === false) ? wolfProtParameters.WPP_CB_DISABLED : wolfProtParameters.WPP_CB_ENABLED;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Sets the annotation action
     * @method setAnnotationAction
     * @param {Object} data: Annotation action parameters
     * @param {255|254} data.planeIndex: the index of the annotation plane
     *                                   255...Annotation plane
     *                                   254...Annotation Matrix Master plane
     * @param {'redo'|'undo'|'clean'|'cleanAll'|'enableAnnotation'|'stop'|'snapshot'|'connect'|'disconnect'
     *        |'disconnect_all'|'nickname'|'pause'} data.action: the annotation action
     * @param {String} data.nickname: the nickname
     * @param {String} data.clientId: the client ID
     * @param socket: the websocket connection
     * @return {{promise, cmd: number}}
     */
    setAnnotationAction: function setAnnotationAction(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_ANNOTATION_ACTION;
        let length = 0;
        const mapping = {
            'redo': wolfProtParameters.WPP_CB_ANNOTATION_ACTION_REDO,
            'undo': wolfProtParameters.WPP_CB_ANNOTATION_ACTION_UNDO,
            'clean': wolfProtParameters.WPP_CB_ANNOTATION_ACTION_CLEAN,
            'cleanAll': wolfProtParameters.WPP_CB_ANNOTATION_ACTION_CLEAN_ALL,
            'enableAnnotation': wolfProtParameters.WPP_CB_ANNOTATION_ACTION_ENABLE_ANNOTATION,
            'stop': wolfProtParameters.WPP_CB_ANNOTATION_ACTION_STOP,
            'snapshot': wolfProtParameters.WPP_CB_ANNOTATION_ACTION_SNAPSHOT,
            'connect': wolfProtParameters.WPP_CB_ANNOTATION_ACTION_CONNECT,
            'disconnect': wolfProtParameters.WPP_CB_ANNOTATION_ACTION_DISCONNECT,
            'disconnect_all': wolfProtParameters.WPP_CB_ANNOTATION_ACTION_DISCONNECT_ALL_USERS,
            'nickname': wolfProtParameters.WPP_CB_ANNOTATION_ACTION_NICKNAME,
            'pause': wolfProtParameters.WPP_CB_ANNOTATION_ACTION_PAUSE,
            'finalSnapshot': wolfProtParameters.WPP_CB_ANNOTATION_ACTION_FINAL_SNAPSHOT
        };

        length += !!data.nickname ? StringConvert.stringToUtf8(data.nickname).length : 0;
        length += !!data.clientId ? 8 : 0;

        const txData = new Uint8Array(6 + length);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = length + 0x02;
        txData[4] = data.planeIndex;
        txData[5] = mapping[data.action];

        if (!!data.nickname) {
            const tempArray = StringConvert.stringToUtf8(data.nickname);
            txData.set(tempArray, 6);
        } else if (data.clientId) {
            for (let i = length - 1; i >= 0; i--) {
                txData[6 + i] = data.clientId & 0xFF;
                data.clientId = data.clientId / 256;
            }
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the recording framerate
     * @method setRecordingFramerate
     * @param data.recordingFramerate: the recording framerate (e.g.: WPP_CB_RECORDING_FRAMERATE_LOW,
     *                                                                WPP_CB_RECORDING_FRAMERATE_MEDIUM,
     *                                                                WPP_CB_RECORDING_FRAMERATE_HIGH)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setRecordingFramerate: function setRecordingFramerate(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_RECORDING_FRAMERATE;
        var mapping = {
            'low': wolfProtParameters.WPP_CB_RECORDING_FRAMERATE_LOW,
            'medium': wolfProtParameters.WPP_CB_RECORDING_FRAMERATE_MEDIUM,
            'high': wolfProtParameters.WPP_CB_RECORDING_FRAMERATE_HIGH,
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.framerate];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the settings of an annotation plane
     * @method setAnnotationSettings
     * @param {Object} data: Annotation settings
     * @param {255|254} data.planeIndex: the index of the annotation plane
     *                                   255...Annotation plane
     *                                   254...Annotation Matrix Master plane
     * @param {'pen'|'line'|'circle'|'rectangle'|'rubber'} data.tool: the annotation tool
     * @param {Number} data.red: RGB value (0...255)
     * @param {Number} data.green: RGB value (0...255)
     * @param {Number} data.blue: RGB value (0...255)
     * @param {Number} data.alpha: Alpha value (0...255) - opacity
     * @param {Number} data.penSize: Pen size (0...100) - the line width of the tool
     * @param {Number} data.eraserStrength: Eraser strength (0...100) - the line width of the rubber
     * @param socket: the websocket connection
     */
    setAnnotationSettings: function setAnnotationSettings(data, socket) {
        const txData = new Uint8Array(11);
        const cmd = wolfProtCommands.WPC_CB_ANNOTATION_SETTINGS;
        const mapping = {
            'pen': wolfProtParameters.WPP_CB_ANNOTATION_SETTINGS_PEN,
            'line': wolfProtParameters.WPP_CB_ANNOTATION_SETTINGS_LINE,
            'circle': wolfProtParameters.WPP_CB_ANNOTATION_SETTINGS_CIRCLE,
            'rectangle': wolfProtParameters.WPP_CB_ANNOTATION_SETTINGS_RECTANGLE,
            'rubber': wolfProtParameters.WPP_CB_ANNOTATION_SETTINGS_RUBBER,
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 7;
        txData[4] = data.planeIndex || 0xFF;
        txData[5] = mapping[data.tool];
        txData[6] = data.red;
        txData[7] = data.green;
        txData[8] = data.blue;
        txData[9] = data.alpha;
        txData[10] = data.tool === 'rubber' ? data.eraserStrength : data.penSize;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the recording framerate
     * @method getRecordingFramerate
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getRecordingFramerate: function getRecordingFramerate(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_RECORDING_FRAMERATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Gets the settings on an annotation plane
     * @method getAnnotationSettings
     * @param {255|254} data.planeIndex: the index of the annotation plane
     *                                   255...Annotation plane
     *                                   254...Annotation Matrix Master plane
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAnnotationSettings: function getAnnotationSettings(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ANNOTATION_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.planeIndex;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the annotation state
     * @method getAnnotationState
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAnnotationState: function getAnnotationState(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ANNOTATION_STATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.COLLAB),
            cmd: cmd
        };

    },

    /**
     * Paint on the annotation plane
     * @method setAnnotationPaint
     * @param {Object} data: Annotation paint parameters
     * @param {255|254} data.planeIndex: the index of the annotation plane
     *                                   255...Annotation plane
     *                                   254...Annotation Matrix Master plane
     * @param {'finished'|'continued'} data.painting: the painting state
     * @param {Number} data.positionX: the x coordinate
     * @param {Number} data.positionY: the y coordinate
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAnnotationPaint: function setAnnotationPaint(data, socket) {
        const txData = new Uint8Array(10);
        const cmd = wolfProtCommands.WPC_CB_ANNOTATION_PAINT;
        const mapping = {
            'finished': wolfProtParameters.WPP_CB_ANNOTATION_PAINT_FINISHED,
            'continued': wolfProtParameters.WPP_CB_ANNOTATION_PAINT_CONTINUED
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 6;
        txData[4] = mapping[data.painting];
        txData[5] = data.planeIndex;
        txData[6] = (data.positionX >> 8) & 0xFF;
        txData[7] = data.positionX & 0xFF;
        txData[8] = (data.positionY >> 8) & 0xFF;
        txData[9] = data.positionY & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * uploads a file to cloud service
     * @method setCloudUpload
     * @param data.service: 'dropbox', 'gdrive'
     * @param data.action: not supported at the moment
     * @param data.path: the path of the file that should be uploaded e.g. 'Internal:///myFile.txt'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setCloudUpload: function setCloudUpload(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_CLOUD_UPLOAD;
        var tempArray = StringConvert.stringToUtf8(data.path);
        var length = 2 + tempArray.length;
        var txData = new Uint8Array(7 + tempArray.length);
        var service = data.service || 'dropbox';
        var action = data.action || 'start';

        var mappingService = {
            'dropbox': wolfProtParameters.WPP_CLOUD_SERVICE_DROPBOX,
            'gdrive': wolfProtParameters.WPP_CLOUD_SERVICE_GDRIVE,
            'jianguoyun': wolfProtParameters.WPP_CLOUD_SERVICE_JIANGUOYUN,
            'onedrive': wolfProtParameters.WPP_CLOUD_SERVICE_ONEDRIVE,
            'webdav': wolfProtParameters.WPP_CLOUD_SERVICE_WEBDAV
        };

        var mappingAction = {
            'abort': wolfProtParameters.WPP_CLOUD_UPLOAD_ABORT,
            'start': wolfProtParameters.WPP_CLOUD_UPLOAD_START,
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8);
        txData[4] = length & 0xFF;
        txData[5] = mappingService[service];
        txData[6] = mappingAction[action];
        txData.set(tempArray, 7);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get front panel login enable / disable
     * @method getFrontpanelLogin
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getFrontpanelLogin: function getFrontpanelLogin(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_FRONTPANEL_LOGIN;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * enable / disable Frontpanel Login
     * @method setFrontpanelLogin
     * @param data: enable/disable
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setFrontpanelLogin: function setFrontpanelLogin(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_FRONTPANEL_LOGIN;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.frontpanelLogin === false) ? wolfProtParameters.WPP_CB_DISABLED : wolfProtParameters.WPP_CB_ENABLED;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get usb stick support enable / disable
     * @method getUsbStickSupport
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getUsbStickSupport: function getUsbStickSupport(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_USB_STICK_SUPPORT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * enable / disable usb stick support
     * @method setUsbStickSupport
     * @param data: enable/disable
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setUsbStickSupport: function setUsbStickSupport(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_USB_STICK_SUPPORT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.usbSupport === false) ? wolfProtParameters.WPP_CB_DISABLED : wolfProtParameters.WPP_CB_ENABLED;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the netdrive configuration
     * @method getNetdrives
     * @param data: netdrive index
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getNetdrive: function getNetdrives(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_NETDRIVE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.index;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the netdrive configuration
     * @method setNetdrive
     * @param data: netdrive
     * @param socket: the websocket connection
     */
    setNetdrive: function setNetdrive(data, socket) {
        var tempArrayUser = StringConvert.stringToUtf8(data.user);
        var tempArrayPassword = StringConvert.stringToUtf8(data.password);
        var tempArrayUrl = StringConvert.stringToUtf8(data.url);
        var tempArrayDomain = StringConvert.stringToUtf8(data.domain);
        var tempArrayName = StringConvert.stringToUtf8(data.name);
        var length = 7 + tempArrayDomain.length + tempArrayName.length + tempArrayPassword.length + tempArrayUrl.length + tempArrayUser.length + 2;
        var txData = new Uint8Array(5 + length);
        var cmd = wolfProtCommands.WPC_CB_NETDRIVE;

        var mapping = {
            'none': wolfProtParameters.WPP_CB_NETDRIVE_TYPE_NONE,
            'cifs': wolfProtParameters.WPP_CB_NETDRIVE_TYPE_CIFS,
            'nfs': wolfProtParameters.WPP_CB_NETDRIVE_TYPE_NFS
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_LEN | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8) & 0xFF;
        txData[4] = length & 0xFF;
        txData[5] = data.index;
        txData[6] = mapping[data.type];
        txData[7] = tempArrayName.length;
        txData.set(tempArrayName, 8);
        txData[8 + tempArrayName.length] = tempArrayUrl.length;
        txData.set(tempArrayUrl, 9 + tempArrayName.length);
        txData[9 + tempArrayName.length + tempArrayUrl.length] = tempArrayUser.length;
        txData.set(tempArrayUser, 10 + tempArrayName.length + tempArrayUrl.length);
        txData[10 + tempArrayName.length + tempArrayUrl.length + tempArrayUser.length] = tempArrayPassword.length;
        txData.set(tempArrayPassword, 11 + tempArrayName.length + tempArrayUrl.length + tempArrayUser.length);
        txData[11 + tempArrayName.length + tempArrayUrl.length + tempArrayUser.length + tempArrayPassword.length] = tempArrayDomain.length;
        txData.set(tempArrayDomain, 12 + tempArrayName.length + tempArrayUrl.length + tempArrayUser.length + tempArrayPassword.length);
        txData[12 + tempArrayName.length + tempArrayUrl.length + tempArrayUser.length + tempArrayPassword.length + tempArrayDomain.length] = (data.readonly === false) ? wolfProtParameters.WPP_CB_NETDRIVE_ACCESS_MODE_READ_WRITE : wolfProtParameters.WPP_CB_NETDRIVE_ACCESS_MODE_READONLY;
        txData[13 + tempArrayName.length + tempArrayUrl.length + tempArrayUser.length + tempArrayPassword.length + tempArrayDomain.length] = data.uploadDefault;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * support year usb update
     * @method setSupportYearUSBUpdate
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setSupportYearUSBUpdate: function setSupportYearUSBUpdate(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_SUPPORT_UPDATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = txData[4] = data.load === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * support year online update
     * @method setSupportYearOnlineUpdate
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWwwSupportYearUpdate: function setSupportYearOnlineUpdate(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_SUPPORT_ONLINE_UPDATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = txData[4] = data.load === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * feature pack usb update
     * @method setFeaturePackUSBUpdate
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setFeaturePackUSBUpdate: function setFeaturePackUSBUpdate(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_FEATURE_UPDATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = txData[4] = data.load === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * feature pack online update
     * @method setFeaturePackUSBUpdate
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWwwFeaturePackUpdate: function setFeaturePackUSBUpdate(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_FEATURE_ONLINE_UPDATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = txData[4] = data.load === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get usb feature pack update status
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getUsbFeatureUpdateStatus: function getWwwUpdateStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_FEATURE_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get usb support pack update status
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getUsbSupportUpdateStatus: function getWwwUpdateStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_SUPPORT_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get www feature pack update status
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWwwFeatureUpdateStatus: function getWwwUpdateStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_FEATURE_ONLINE_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get www support pack update status
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWwwSupportUpdateStatus: function getWwwUpdateStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_SUPPORT_ONLINE_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get license features
     *
     * @param socket: the websocket connection
     * @param excludeDefault: false - get all feature packs (also default ones), true - only get extra feature packs
     * @return cmd: the command number
     */
    getLicenseFeatures: function getLicenseFeatures(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LICENSE_FEATURES;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.excludeDefault ? 0x03 : 0x02;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the Line Out volume
     *
     * @param data.volume: 0 - 100
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLineOutVolume: function setLineOutVolume(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LINEOUT_VOLUME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.volume;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the Line Out volume
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLineOutVolume: function getLineOutVolume(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LINEOUT_VOLUME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the HDMI Out 1 volume
     *
     * @param data.volume: 0 - 100
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setHdmiOut1Volume: function setHdmiOut1Volume(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_HDMI_OUT1_VOLUME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.volume;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the HDMI Out 1 volume
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getHdmiOut1Volume: function getHdmiOut1Volume(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_HDMI_OUT1_VOLUME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the HDMI Out 1 volume
     *
     * @param data.volume: 0 - 100
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setHdmiOut2Volume: function setHdmiOut2Volume(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_HDMI_OUT2_VOLUME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.volume;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the HDMI Out 2 volume
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getHdmiOut2Volume: function getHdmiOut2Volume(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_HDMI_OUT2_VOLUME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the aux content priority
     *
     * @param data.type: (eg.  'none',
     *                         'visualizer'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAuxContentPriority: function setAuxContentPriority(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = wolfProtCommands.WPC_CB_AUX_CONTENT_PRIORITY;
        var mapping = {
            'none': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_NONE,
            'visualizer': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_VISUALIZER,
            'hdmiInput': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_HDMI_INPUT,
            'browser': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_BROWSER,
            'miracast': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_MIRACAST,
            'airplay': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_AIRPLAY,
            'video': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_VIDEO,
            'vSolutionCast': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_VSOLUTIONCAST,
            'image': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_IMAGE,
            'pdf': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_PDF,
            'officePresentation': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_OFFICE_PRESENTATION,
            'officeText': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_OFFICE_TEXT,
            'officeCalc': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_OFFICE_CALC,
            'whiteboard': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_WHITEBOARD,
            'audio': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_AUDIO,
            'webconference': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_WEBCONFERENCE,
            'webcam': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_WEBCAM,
            'streamInput': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_STREAM_INPUT,
            'office365Outlook': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_OFFICE365_OUTLOOK,
            'office365Word': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_OFFICE365_WORD,
            'office365Excel': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_OFFICE365_EXCEL,
            'office365PowerPoint': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_OFFICE365_POWER_POINT,
            'office365OneNote': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_ONE_NOTE,
            'matrix': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_MATRIX,
            'office365Teams': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_OFFICE365_TEAMS,
            'zoom': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_ZOOM,
            'hdmiInput1': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_HDMI1_INPUT,
            'hdmiInput2': wolfProtParameters.WPP_CB_CONTENT_PRIORITY_HDMI2_INPUT
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = data.index;
        txData[5] = mapping[data.type];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests aux content priority
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAuxContentPriority: function getAuxContentPriority(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_AUX_CONTENT_PRIORITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.index;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the cast id
     * @method getCastId
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getCastId: function getCastId(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_CAST_ID;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the webconference function
     * @method setWebconferenceFunction
     * @param data.function: webconference enabled/disabled
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWebconferenceFunction: function setWebconferenceFunction(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WEBCONFERENCE_FUNCTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.function === true ? wolfProtParameters.WPP_CB_ENABLED : wolfProtParameters.WPP_CB_DISABLED;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the webconference function
     * @method getWebconferenceFunction
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWebconferenceFunction: function getWebconferenceFunction(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WEBCONFERENCE_FUNCTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Returns all Webconference directories as a tree.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWebconferenceEntries: function getWebconferenceEntries(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WEBCONFERENCE_ENTRIES;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set webconference services.
     *
     * @param data.entries: All Services as a json=array.
     * @param socket: the websocket connection
     *
     * @return cmd: the command number
     */
    setWebconferenceEntries: function setWebconferenceEntries(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_WEBCONFERENCE_ENTRIES;
        var uint8Content = StringConvert.stringToUtf8(data.entries);
        var txData = new Uint8Array(8 + uint8Content.length);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_HDR | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = wolfProtHeaders.WP_EXTHEAD_EXT_LEN;
        txData[2] = (cmd >> 8) & 0xFF;
        txData[3] = cmd & 0xFF;
        txData[4] = (uint8Content.length >> 24) & 0xff;
        txData[5] = (uint8Content.length >> 16) & 0xff;
        txData[6] = (uint8Content.length >> 8) & 0xff;
        txData[7] = uint8Content.length & 0xff;
        txData.set(uint8Content, 8);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the chromecast state
     * @method getChromecastState
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getChromecastState: function getChromecastState(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_CHROMECAST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * enables / disables chromecast
     * @method setChromecastState
     *
     * @param data.enabled: chromecast state
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setChromecastState: function setChromecastState(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_CHROMECAST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.enable === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the webconference camera autostart
     * @method getWebconferenceCamAutostart
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWebconferenceCamAutostart: function getWebconferenceCamAutostart(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WEBCONFERENCE_CAMERA;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * enables / disables webconference camera autostart
     * @method setWebconferenceCamAutostart
     *
     * @param data.autostart: camera autostart
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWebconferenceCamAutostart: function setWebconferenceCamAutostart(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WEBCONFERENCE_CAMERA;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.autostart === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get hdmi input autostart
     *
     * @param data.hdmiInput: the hdmiInput where we want to know the setting
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getHdmiInputAutostart: function getHdmiInputAutostart(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_HDMI_INPUT_AUTOSTART;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.hdmiInput === 0 ? wolfProtParameters.WPP_HDMI_INPUT_1 : wolfProtParameters.WPP_HDMI_INPUT_2;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * set hdmi input setup
     *
     * @param data.hdmiInput: the hdmi input (0 or 1)
     * @param data.autostart: off or on (0 or 1)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setHdmiInputAutostart: function setHdmiInputAutostart(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = wolfProtCommands.WPC_CB_HDMI_INPUT_AUTOSTART;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = data.hdmiInput === 0 ? wolfProtParameters.WPP_HDMI_INPUT_1 : wolfProtParameters.WPP_HDMI_INPUT_2;
        txData[5] = data.autostart;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get file upload status
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getFileUploadStatus: function getFileUploadStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_FILE_UPLOAD_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.COLLAB),
            cmd: cmd
        };

    },

    /**
     * set file upload
     *
     * @param data.start: true => start, false => abort
     * @param data.src: the source file (whole path e.g. Internal:///foo/bar.txt)
     * @param data.target: the target path e.g. netdrive0:///foo/
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setFileUpload: function setFileUpload(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_FILE_UPLOAD;
        var txData = null;
        var length = null;
        var mapping = {
            'abort': wolfProtParameters.WPP_CB_FILE_UPLOAD_MODE_ABORT,
            'start': wolfProtParameters.WPP_CB_FILE_UPLOAD_MODE_START,
            'abortIndex': wolfProtParameters.WPP_CB_FILE_UPLOAD_MODE_ABORT_INDEX,
            'restartIndex': wolfProtParameters.WPP_CB_FILE_UPLOAD_MODE_RESTART_INDEX
        };
        var mode = mapping[data.mode];

        if (mode >= wolfProtParameters.WPP_CB_FILE_UPLOAD_MODE_ABORT_INDEX) {
            length = 5;
            txData = new Uint8Array(10);
            valueToBuffer(data.index, txData, 6, 4);
        } else {
            var tempSrcArray = StringConvert.stringToUtf8(data.src || '');
            var tempTargetArray = StringConvert.stringToUtf8(data.target || '');
            length = 5 + tempSrcArray.length + tempTargetArray.length;

            txData = new Uint8Array(10 + tempSrcArray.length + tempTargetArray.length);
            txData[6] = (tempSrcArray.length >> 8);
            txData[7] = (tempSrcArray.length & 0xFF);
            txData.set(tempSrcArray, 8);
            txData[8 + tempSrcArray.length] = (tempTargetArray.length >> 8);
            txData[9 + tempSrcArray.length] = (tempTargetArray.length & 0xFF);
            txData.set(tempTargetArray, 10 + tempSrcArray.length);
        }

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8);
        txData[4] = length & 0xFF;
        txData[5] = mode;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the upload percentage
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getFileUploadPercentage: function getFileUploadPercentage(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_FILE_UPLOAD_PERCENTAGE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.COLLAB),
            cmd: cmd
        };

    },

    /**
     * returns the file upload listing
     * @method getFileUploadList
     * @param data.allUploads: allUploads (only for admin) or from the current presentation only
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getFileUploadList: function getFileUploadList(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_FILE_UPLOAD_LIST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.allUploads === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.COLLAB),
            cmd: cmd
        };

    },

    /**
     * sets the lan authentication mode
     *
     * @param data.mode: true => on, false => off
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLanAuthMode: function setLanAuthMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LAN_AUTH_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.mode === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the lan authentication mode
     * @method getLanAuthMode
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLanAuthMode: function getLanAuthMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN_AUTH_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the lan authentication method
     *
     * @param data.authMethode: (eg.  'peap-mschapv2',
     *                           'ttls-pap'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLanAuthMethod: function setLanAuthMethod(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LAN_AUTH_METHOD;
        var mapping = {
            'peap-mschapv2': wolfProtParameters.WPP_CB_LAN_AUTH_METHOD_PEAP_MSCHAPV2,
            'ttls-pap': wolfProtParameters.WPP_CB_LAN_AUTH_METHOD_TTLS_PAP
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.authMethod];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the lan authentication method
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLanAuthMethod: function getLanAuthMethod(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN_AUTH_METHOD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the lan identity
     * @method getLanIdentity
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLanIdentity: function getLanIdentity(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN_IDENTITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the lan identity
     *
     * @param data.identity: the identity
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLanIdentity: function setLanIdentity(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.name);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_LAN_IDENTITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the lan anonymous identity
     * @method getLanAnonymousIdentity
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLanAnonymousIdentity: function getLanAnonymousIdentity(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN_ANONYMOUS_IDENTITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the lan anonymous identity
     *
     * @param data.anonymousIdentity: the anonymous identity
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLanAnonymousIdentity: function setLanAnonymousIdentity(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.name);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_LAN_ANONYMOUS_IDENTITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the lan password
     *
     * @param data.wifiKey: the wifi key that should be used
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLanPassword: function setLanPassword(data, socket) {
        var password = data.password || '';
        var txData = new Uint8Array(4 + password.length);
        var cmd = wolfProtCommands.WPC_CB_LAN_PASSWORD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = password.length;

        for (var i = 0; i < password.length; ++i) {
            txData[i + 4] = password.charCodeAt(i);
        }
        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     *
     * requests the lan password
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLanPassword: function getLanPassword(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN_PASSWORD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the lan certificate mode
     *
     * @param data.mode: true => on, false => off
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLanCertificateMode: function setLanCertificateMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LAN_CERT_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.mode === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the lan certificate mode
     * @method getLanCertificateMode
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLanCertificateMode: function getLanCertificateMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN_CERT_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the lan certificate file name
     *
     * @param data.name: the filename of the certificate
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLanCertificateFileName: function setLanCertificateFileName(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.name);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_LAN_CERT_NAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the lan certificate filename
     * @method getLanCertificateFileName
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLanCertificateFileName: function getLanCertificateFileName(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN_CERT_NAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the lan certificate file
     *
     * @param data.name: the filename
     * @param data.file: the file as ByteArray
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLanCertificateFile: function setLanCertificateFile(data, socket) {
        var tempArrayName = StringConvert.stringToUtf8(data.name);
        var tempArrayFile = !!data.file ? new Uint8Array(data.file) : new Uint8Array(0);
        var txData = new Uint8Array(10 + tempArrayName.length + tempArrayFile.length);
        var cmd = wolfProtCommands.WPC_CB_LAN_CERT_FILE;
        var length = tempArrayFile.length + tempArrayName.length + 2;
        var mapping = {
            'delete': wolfProtParameters.WPP_CB_CERTIFICATE_FILE_DELETE,
            'save': wolfProtParameters.WPP_CB_CERTIFICATE_FILE_SAVE,
            'verify': wolfProtParameters.WPP_CB_CERTIFICATE_FILE_VERIFY
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_HDR | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = wolfProtHeaders.WP_EXTHEAD_EXT_LEN;
        txData[2] = (cmd >> 8) & 0xFF;
        txData[3] = cmd & 0xFF;
        txData[4] = (length >> 24) & 0xff;
        txData[5] = (length >> 16) & 0xff;
        txData[6] = (length >> 8) & 0xff;
        txData[7] = length & 0xff;
        txData[8] = mapping[data.action];
        txData[9] = tempArrayName.length;

        txData.set(tempArrayName, 10);
        txData.set(tempArrayFile, 10 + tempArrayName.length);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * returns the lan certificate list
     * @method getLanCertificates
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLanCertificates: function getLanCertificates(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN_CERT_FILE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },


    /**
     * returns the wlan certificate list
     * @method getWlanCertificates
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanCertificates: function getWlanCertificates(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_CERTIFICATE_FILE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Send ping command.
     *
     * @param data.interface: {(lan1|wlan|lan2)} the network interface
     * @param data.destination: the address string of the ping destination, e.g. 192.168.0.1
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setPing: function setPing(data, socket) {
        let tempArray = data.destination.length <= 253 ? StringConvert.stringToUtf8(data.destination)
            : StringConvert.stringToUtf8(data.destination.substring(0, 253));
        let txData = new Uint8Array(6 + tempArray.length);
        const cmd = wolfProtCommands.WPC_CB_PING;

        const interfaceMapping = {
            'lan1': 0x00,
            'lan2': 0x01,
            'wlan': 0x02
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 2 + tempArray.length;
        txData[4] = interfaceMapping[data.interface];
        txData[5] = tempArray.length;

        txData.set(tempArray, 6);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get result from ping.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getPing: function getPing(data, socket) {
        let txData = new Uint8Array(4);
        const cmd = wolfProtCommands.WPC_CB_PING;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * returns the annotation users
     * @method getAnnotationUsers
     * @param {Object} data: parameter object
     * @param {255|254} data.planeIndex: the index of the annotation plane
     *                                   255...Annotation plane
     *                                   254...Annotation Matrix Master plane
     * @param data.allUsers: allUsers vs myUser
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAnnotationUsers: function getAnnotationUsers(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = wolfProtCommands.WPC_CB_ANNOTATION_USERS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = data.planeIndex || 0xFF;
        txData[5] = data.allUsers === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Sets the annotation user visibility
     *
     * @param {Object} data: parameter object
     * @param {255|254} data.planeIndex: the index of the annotation plane
     *                                   255...Annotation plane
     *                                   254...Annotation Matrix Master plane
     * @param {Number} data.clientId: the client id
     * @param {Boolean} data.visible: the annotation visibility
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAnnotationUser: function setAnnotationUser(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_ANNOTATION_USERS;
        let length = 0;
        let clientId = data.clientId;

        if (data.clientId !== 0) {
            length = 8;
        } else {
            length = 0;
        }

        const txData = new Uint8Array(4 + 2 + length);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02 + length;
        txData[4] = data.planeIndex;
        txData[5] = data.visible === true ? 1 : 0;

        if (clientId) {
            for (let i = 7; i >= 0; i--) {
                txData[6 + i] = clientId & 0xFF;
                clientId = clientId / 256;
            }
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the annotation pin status
     * @method getAnnotationPinStatus
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAnnotationPinStatus: function getAnnotationPinStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ANNOTATION_PIN_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * enables / disables collaboration pin
     * @method setAnnotationPin
     *
     * @param data.collaboration: annotation pin enable/disable
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAnnotationPin: function setAnnotationPin(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ANNOTATION_PIN;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.collaboration === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the rms password
     * @method setRmsPassword
     * @param data.password: the password as string
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setRmsPassword: function setRmsPassword(data, socket) {
        var length = data.password.length || 0;
        var txData = new Uint8Array(4 + length);
        var cmd = wolfProtCommands.WPC_CB_ROOM_PASSWORD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = length;

        for (var i = 0; i < data.password.length; i++) {
            txData[i + 4] = data.password.charCodeAt(i);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * get rms password
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getRmsPassword: function getRmsPassword(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ROOM_PASSWORD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * checks if we need a rms password
     * @method getRmsPasswordRequired
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getRmsPasswordMode: function getRmsPasswordMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ROOM_PASSWORD_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets rms password mode
     * @method setRmsPasswordMode
     * @param data.pwRequired: true or false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setRmsPasswordMode: function setRmsPasswordMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ROOM_PASSWORD_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 1;
        txData[4] = data.pwRequired === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the wlan power (dbm)
     *
     * @param data.power: dbm value
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanPower: function setWlanPower(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_AP_POWER;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.power;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wlan power
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanPower: function getWlanPower(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_AP_POWER;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the aux allow mirror override
     *
     * @param data.allow: true => on, false => off
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAuxAllowMirrorOverride: function setAuxAllowMirrorOverride(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_AUX_MIRROR_ALLOW_OVERRIDE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.allow === true ? wolfProtParameters.WPP_YES : wolfProtParameters.WPP_NO;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the aux allow mirror override
     * @method getAuxAllowMirrorOverride
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAuxAllowMirrorOverride: function getAuxAllowMirrorOverride(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_AUX_MIRROR_ALLOW_OVERRIDE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the aux mirror override
     *
     * @param data.override: true => on, false => off
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAuxMirrorOverride: function setAuxMirrorOverride(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_AUX_MIRROR_OVERRIDE;
        var mapping = {
            'on': wolfProtParameters.WPP_ON,
            'off': wolfProtParameters.WPP_OFF
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.override];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the aux mirror override
     * @method getAuxMirrorOverride
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAuxMirrorOverride: function getAuxMirrorOverride(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_AUX_MIRROR_OVERRIDE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData, wolfProtUserLevels.USER),
            cmd: cmd
        };

    },

    /**
     * checks if https is enabled
     * @method getHttpsEnable
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getHttpsEnable: function getHttpsEnable(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_FORCE_HTTPS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets https enable
     * @method setHttpsEnable
     * @param data.httpsEnabled: true or false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setHttpsEnable: function setHttpsEnable(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_FORCE_HTTPS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 1;
        txData[4] = data.httpsEnable === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * checks if rtsp streaming server is enabled
     * @method getRtspStreamingMode
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getRtspStreamingMode: function getRtspStreamingMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_RTSP_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets rtsp streaming mode
     * @method setRtspStreamingMode
     * @param data.rtsp: true or false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setRtspStreamingMode: function setRtspStreamingMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_RTSP_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 1;
        txData[4] = data.rtsp === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the streaming time to live
     * @method setStreamingTtl
     * @param {Object} data request parameter object
     * @param {1..255} data.ttl time to live
     * @param {'unicast'|'multicast'} data.mode mode
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setStreamingTtl: function setStreamingTtl(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = data.mode === 'multicast' ? wolfProtCommands.WPC_CB_MULTICAST_TTL : wolfProtCommands.WPC_CB_UNICAST_TTL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.ttl;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the streaming time to live
     * @method setStreamingTtlOutput
     * @param {Object} data request parameter object
     * @param {1..255} data.ttl time to live
     * @param {'unicast'|'multicast'} data.mode mode
     * @param {'hdmi1'|'hdmi2'} data.output the output
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setStreamingTtlOutput: function setStreamingTtlOutput(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = data.mode === 'multicast' ? wolfProtCommands.WPC_CB_MULTICAST_TTL : wolfProtCommands.WPC_CB_UNICAST_TTL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = data.ttl;
        txData[5] = OUTPUT_MAPPING[data.output];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * checks if rtp streaming server is enabled
     * @method getRtpStreamingMode
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getRtpStreamingMode: function getRtpStreamingMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_RTP_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets rtp streaming mode
     * @method setRtpStreamingMode
     * @param data.rtp: true or false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setRtpStreamingMode: function setRtpStreamingMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_RTP_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 1;
        txData[4] = data.rtp === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the streaming time to live
     *
     * @param data.mode: unicast / multicast
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getStreamingTtl: function getStreamingTtl(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = data.mode === 'multicast' ? wolfProtCommands.WPC_CB_MULTICAST_TTL : wolfProtCommands.WPC_CB_UNICAST_TTL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets line in local output enable
     * @method setLineInLocalOutput
     * @param data.enabled: true or false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLineInLocalOutput: function setLineInLocalOutput(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LINE_IN_LOCAL_OUTPUT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 1;
        txData[4] = data.enabled === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * checks if line in local output is enabled
     * @method getLineInLocalOutput
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLineInLocalOutput: function getLineInLocalOutput(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LINE_IN_LOCAL_OUTPUT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets line in remote output enable
     * @method setLineInRemoteOutput
     * @param data.enabled: true or false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLineInRemoteOutput: function setLineInRemoteOutput(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LINE_IN_REMOTE_OUTPUT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 1;
        txData[4] = data.enabled === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * checks if line in remote output is enabled
     * @method getLineInRemoteOutput
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLineInRemoteOutput: function getLineInRemoteOutput(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LINE_IN_REMOTE_OUTPUT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the opencast certificate mode
     *
     * @param data.mode: true => on, false => off
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setOpencastCertificateMode: function setOpencastCertificateMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_OPENCAST_CERTIFICATE_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.mode === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the opencast certificate mode
     * @method getOpencastCertificateMode
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getOpencastCertificateMode: function getOpencastCertificateMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_OPENCAST_CERTIFICATE_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the opencast certificate file name
     *
     * @param data.name: the filename of the certificate
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setOpencastCertificateFileName: function setOpencastCertificateFileName(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.name);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_OPENCAST_CERTIFICATE_NAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the opencast certificate filename
     * @method getOpencastCertificateFileName
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getOpencastCertificateFileName: function getOpencastCertificateFileName(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_OPENCAST_CERTIFICATE_FILE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the opencast certificate file
     *
     * @param data.name: the filename
     * @param data.file: the file as ByteArray
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setOpencastCertificateFile: function setOpencastCertificateFile(data, socket) {
        var tempArrayName = StringConvert.stringToUtf8(data.name);
        var tempArrayFile = !!data.file ? new Uint8Array(data.file) : new Uint8Array(0);
        var txData = new Uint8Array(10 + tempArrayName.length + tempArrayFile.length);
        var cmd = wolfProtCommands.WPC_CB_OPENCAST_CERTIFICATE_FILE;
        var length = tempArrayFile.length + tempArrayName.length + 2;
        var mapping = {
            'delete': wolfProtParameters.WPP_CB_CERTIFICATE_FILE_DELETE,
            'save': wolfProtParameters.WPP_CB_CERTIFICATE_FILE_SAVE,
            'verify': wolfProtParameters.WPP_CB_CERTIFICATE_FILE_VERIFY
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_HDR | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = wolfProtHeaders.WP_EXTHEAD_EXT_LEN;
        txData[2] = (cmd >> 8) & 0xFF;
        txData[3] = cmd & 0xFF;
        txData[4] = (length >> 24) & 0xff;
        txData[5] = (length >> 16) & 0xff;
        txData[6] = (length >> 8) & 0xff;
        txData[7] = length & 0xff;
        txData[8] = mapping[data.action];
        txData[9] = tempArrayName.length;

        txData.set(tempArrayName, 10);
        txData.set(tempArrayFile, 10 + tempArrayName.length);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * returns the opencast certificate list
     * @method getOpencastCertificates
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getOpencastCertificates: function getOpencastCertificates(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_OPENCAST_CERTIFICATE_FILE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the rtsp streaming interface
     *
     * @param data.interface: (eg.  'lan',
     *                              'wlan'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setRtspStreamingInterface: function setRtspStreamingInterface(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_RTSP_URL_INTERFACE;
        var mapping = {
            'lan1': wolfProtParameters.WPP_STREAMING_INTERFACE_LAN1,
            'wlan': wolfProtParameters.WPP_STREAMING_INTERFACE_WLAN,
            'lan2': wolfProtParameters.WPP_STREAMING_INTERFACE_LAN2
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.interface];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the rtsp streaming interface
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getRtspStreamingInterface: function getRtspStreamingInterface(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_RTSP_URL_INTERFACE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the mirror pin status
     *
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMirrorPinStatus: function getMirrorPinStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MIRROR_PIN_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Gets the mirror pin dynamic.
     *
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMirrorPinDynamic: function getMirrorPinDynamic(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MIRROR_PIN_DYNAMIC;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the mirror pin dynamic.
     *
     * @param data.enabled: enable dynamic mirror pin
     *        data.resetTime: reset after resetTime in minutes (between 10 and 60)
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setMirrorPinDynamic: function setMirrorPinDynamic(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = wolfProtCommands.WPC_CB_MIRROR_PIN_DYNAMIC;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = (data.enabled === true) ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;
        txData[5] = data.resetTime;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the collaboration user visibility
     *
     * @param data.visible: true => on, false => off
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setCollaborationUserVisiblity: function setCollaborationUserVisiblity(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_USER_VISIBILITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.visible === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the collaboration user visibility
     * @method getCollaborationUserVisiblity
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getCollaborationUserVisiblity: function getCollaborationUserVisiblity(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_USER_VISIBILITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the audio test
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAudioTest: function setAudioTest(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_AUDIO_TEST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = 0x01;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the hdmi2 pin status
     * @method getPinStatusHdmi2
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getPinStatusHdmi2: function getPinStatusHdmi2(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PIN_STATUS_HDMI2;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the mixer meter
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMixerMeter: function getMixerMeter(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MIXER_METER;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the line in level
     *
     * @param data.level: 0 - 100
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLineInLevel: function setLineInLevel(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LINE_IN_LEVEL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.level;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the line in level
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLineInLevel: function getLineInLevel(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LINE_IN_LEVEL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the mic status
     * @method getMicStatus
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMicStatus: function getMicStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MIC_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * gets the user settings status (usb, internal)
     * @method getUserSettingsStatus
     * @param data:mode (usb, internal)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getUserSettingsStatus: function getUserSettingsStatus(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_USER_SETTINGS_STATUS;
        var mapping = {
            'usb': wolfProtParameters.WPP_CB_USER_SETTINGS_USB,
            'internal': wolfProtParameters.WPP_CB_USER_SETTINGS_INTERNAL
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.mode];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the lan priority
     *
     * @param data.priority: 1,2 (will be converted to zero based index)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLanPriority: function setLanPriority(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LAN_PRIORITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.priority - 1;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the lan priority
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLanPriority: function getLanPriority(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN_PRIORITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the wlan priority
     *
     * @param data.priority: 1,2 (will be converted to zero based index)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanPriority: function setWlanPriority(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_PRIORITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.priority - 1;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the wlan priority
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanPriority: function getWlanPriority(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_PRIORITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Sets the webdav credentails.
     *
     * @param data.service: cloud service (jianguoyun, webdav)
     * @param data.password: the password as string
     * @param data.user: the uername as string
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWebdavCredentials: function setWebdavCredentials(data, socket) {
        var tempArrayPw = StringConvert.stringToUtf8(data.password);
        var tempArrayUser = StringConvert.stringToUtf8(data.user);
        var length = tempArrayPw.length + tempArrayUser.length + 3;
        var txData = new Uint8Array(5 + length);
        var cmd = wolfProtCommands.WPC_CB_WEBDAV_CREDENTIALS;

        var serviceMapping = {
            'jianguoyun': wolfProtParameters.WPP_CLOUD_WEBDAV_CREDENTIALS_JIANGUOYUN,
            'webdav': wolfProtParameters.WPP_CLOUD_WEBDAV_CREDENTIALS_WEBDAV
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8);
        txData[4] = length & 0xFF;
        txData[5] = serviceMapping[data.service];
        txData[6] = tempArrayUser.length;

        txData.set(tempArrayUser, 7);

        txData[7 + tempArrayUser.length] = tempArrayPw.length;

        txData.set(tempArrayPw, 8 + tempArrayUser.length);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Sets the webdav settings.
     *
     * @param data.url: webdav service url
     * @param data.name: webdav service name
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWebdavSettings: function setWebdavSettings(data, socket) {
        var tempArrayUrl = StringConvert.stringToUtf8(data.url);
        var tempArrayName = StringConvert.stringToUtf8(data.name);
        var length = tempArrayUrl.length + tempArrayName.length + 2;
        var txData = new Uint8Array(5 + length);
        var cmd = wolfProtCommands.WPC_CB_WEBDAV_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8);
        txData[4] = length & 0xFF;
        txData[5] = tempArrayUrl.length;

        txData.set(tempArrayUrl, 6);

        txData[6 + tempArrayUrl.length] = tempArrayName.length;

        txData.set(tempArrayName, 7 + tempArrayUrl.length);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the webdav settings.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWebdavSettings: function getWebdavSettings(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WEBDAV_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * disconnects the current user
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setDisconnect: function setDisconnect(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_DISCONNECT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the ssh enable status
     * @method getSshEnable
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getSshEnable: function getSshEnable(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_SSH_ENABLE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * enables / disables ssh
     * @method setSshEnable
     * @param data: data.enable (true or false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setSshEnable: function setSshEnable(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_SSH_ENABLE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.sshEnable === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the usb mic volume
     *
     * @param data.volume: 0 - 100
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setUsbMicVolume: function setUsbMicVolume(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_USB_MIC_VOLUME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.volume;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the usb mic volume
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getUsbMicVolume: function getUsbMicVolume(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_USB_MIC_VOLUME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the rms client configuration
     * @method getRmsClientFull
     * @param data: rms client index
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getRmsClientFull: function getRmsClientFull(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_RMS_CLIENT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.id;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the rms client configuration
     * @method getRmsClient
     * @param data: rms client index
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getRmsClient: function getRmsClient(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_RMS_CLIENT_ENABLED;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.id;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the rms client configuration
     *
     * @method setRmsClient
     * @param data: index
     * @param data: event
     * @param data: name
     * @param data: description
     * @param data: prot
     * @param data: ip
     * @param data: cmd
     * @param data: enabled
     * @param socket: the websocket connection
     */
    setRmsClient: function setRmsClient(data, socket) {
        var tempArrayName = StringConvert.stringToUtf8(data.name);
        var tempArrayDescription = StringConvert.stringToUtf8(data.description);
        var tempArrayCommand = StringConvert.stringToUtf8(data.cmd);
        var tempArrayPassword = StringConvert.stringToUtf8(data.password);
        var tempIp = ipv4AddressString2Int(data.ip);
        var tempPort = parseInt(data.port);
        var length = 16 + tempArrayName.length + tempArrayDescription.length + tempArrayCommand.length + tempArrayPassword.length;
        var txData = new Uint8Array(5 + length);
        var cmd = wolfProtCommands.WPC_CB_RMS_CLIENT;

        var mapping = {
            'none': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_NONE,
            'powerOn': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_POWER_ON,
            'powerOff': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_POWER_OFF,
            'button1': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_1,
            'button2': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_2,
            'button3': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_3,
            'button4': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_4,
            'button5': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_5,
            'button6': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_6,
            'button7': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_7,
            'button8': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_8,
            'screenOn': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_SCREEN_ON,
            'screenOff': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_SCREEN_OFF
        };

        var mappingProt = {
            'tcp': wolfProtParameters.WPP_CB_RMS_CLIENT_PROT_TCP,
            'udp': wolfProtParameters.WPP_CB_RMS_CLIENT_PROT_UDP,
            'PJLink': wolfProtParameters.WPP_CB_RMS_CLIENT_PROT_PJLINK,
            'wolfprot': wolfProtParameters.WPP_CB_RMS_CLIENT_PROT_WOLFPROT
        };

        var mappingWolfprot = {
            'none': wolfProtParameters.WPP_CB_RMS_CLIENT_WOLFPROT_CMD_NONE,
            'mirroring': wolfProtParameters.WPP_CB_RMS_CLIENT_WOLFPROT_CMD_MIRRORING_PERM_ALLOW
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_LEN | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8) & 0xFF;
        txData[4] = length & 0xFF;

        txData[5] = data.id;

        txData[6] = mapping[data.event];

        txData[7] = tempArrayName.length;
        txData.set(tempArrayName, 8);

        txData[8 + tempArrayName.length] = tempArrayDescription.length;
        txData.set(tempArrayDescription, 9 + tempArrayName.length);

        txData[9 + tempArrayName.length + tempArrayDescription.length] = mappingProt[data.prot];

        txData[10 + tempArrayName.length + tempArrayDescription.length] = (tempIp >> 24) & 0xFF;
        txData[11 + tempArrayName.length + tempArrayDescription.length] = (tempIp >> 16) & 0xFF;
        txData[12 + tempArrayName.length + tempArrayDescription.length] = (tempIp >> 8) & 0xFF;
        txData[13 + tempArrayName.length + tempArrayDescription.length] = tempIp & 0xFF;

        txData[14 + tempArrayName.length + tempArrayDescription.length] = (tempPort >> 8) & 0xFF;
        txData[15 + tempArrayName.length + tempArrayDescription.length] = tempPort & 0xFF;

        txData[16 + tempArrayName.length + tempArrayDescription.length] = tempArrayCommand.length;
        txData.set(tempArrayCommand, 17 + tempArrayName.length + tempArrayDescription.length);

        txData[17 + tempArrayName.length + tempArrayDescription.length + tempArrayCommand.length] = data.enabled;

        txData[18 + tempArrayName.length + tempArrayDescription.length + tempArrayCommand.length] = tempArrayPassword.length;
        txData.set(tempArrayPassword, 19 + tempArrayName.length + tempArrayDescription.length + tempArrayCommand.length);

        txData[19 + tempArrayName.length + tempArrayDescription.length + tempArrayCommand.length + tempArrayPassword.length] = mappingWolfprot[data.wolfprot];
        txData[20 + tempArrayName.length + tempArrayDescription.length + tempArrayCommand.length + tempArrayPassword.length] = data.peripheralDelay;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets dhcp of the 2nd ethernet interface
     *
     * @param data.dhcpSetting: WPP_DHCP_OFF, WPP_DHCP_ON or WPP_DHCP_TOGGLE
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLan2DhcpSetting: function setLan2DhcpSetting(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LAN2_DHCP;
        data.dhcpSetting = data.dhcpSetting || 0x00;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.dhcpSetting;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the 2nd ethernet dhcp setting
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2DhcpSetting: function getLan2DhcpSetting(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN2_DHCP;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the 2nd ethernet dhcp setting
     *
     * @param data: dynamic dhcp
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2SettingsDhcp: function getLan2SettingsDhcp(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LAN2_DHCP;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.dynamic === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     *
     * sets the ip address of the 2nd ethernet interface
     *
     * @param data.ipAddress: the ip address as a string (e.g. '192.168.2.1')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLan2IpAddress: function setLan2IpAddress(data, socket) {
        var txData = new Uint8Array(8);
        var temp = ipv4AddressString2Int(data.ipAddress);
        var cmd = wolfProtCommands.WPC_CB_LAN2_IP;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the ip address of the 2nd ethernet interface
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2IpAddress: function getLan2IpAddress(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LAN2_IP;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.interfaceIp === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the ip address of the 2nd ethernet interface
     *
     * @param data: dhcp dynamic
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2SettingsIpAddress: function getLan2SettingsIpAddress(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LAN2_IP;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.dynamic === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the subnet mask of the 2nd ethernet interface
     *
     * @param data.ipAddress: the ip address as a string (e.g. '255.255.255.0')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLan2SubnetMask: function setLan2SubnetMask(data, socket) {
        var txData = new Uint8Array(8);
        var temp = ipv4AddressString2Int(data.subnetMask);
        var cmd = wolfProtCommands.WPC_CB_LAN2_SUBNET;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the subnet mask of the 2nd ethernet interface
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2SubnetMask: function getLan2SubnetMask(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN2_SUBNET;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the subnet mask of the 2nd ethernet interface
     *
     * @param data: dynamic dhcp
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2SettingsSubnetMask: function getLan2SettingsSubnetMask(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LAN2_SUBNET;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.dynamic === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the gateway address of the 2nd ethernet interface
     *
     * @param data.gatewayAddress: the gateway address as a string (e.g. '192.168.2.1')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLan2Gateway: function setLan2Gateway(data, socket) {
        var txData = new Uint8Array(8);
        var temp = ipv4AddressString2Int(data.gatewayAddress);
        var cmd = wolfProtCommands.WPC_CB_LAN2_GATEWAY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the gateway address of the 2nd ethernet interface
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2GatewayAddress: function getLan2GatewayAddress(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN2_GATEWAY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the gateway address of the 2nd ethernet interface
     *
     * @param data: dhcp dynamic
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2SettingsGatewayAddress: function getLan2SettingsGatewayAddress(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LAN2_GATEWAY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.dynamic === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the dns address of the 2nd ethernet interface
     *
     * @param data.dnsAddress: the dns address as a string (e.g. '96.67.2.1')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLan2DnsAddress: function setLan2DnsAddress(data, socket) {
        var txData = new Uint8Array(8);
        var temp = ipv4AddressString2Int(data.dnsAddress);
        var cmd = wolfProtCommands.WPC_CB_LAN2_NAMESERVER1;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * requests the dns address of the 2nd ethernet interface
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2DnsAddress: function getLan2DnsAddress(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN2_NAMESERVER1;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the dns address of the 2nd ethernet interface
     *
     * @param data: dynamic dhcp
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2SettingsDnsAddress: function getLan2SettingsDnsAddress(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LAN2_NAMESERVER1;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.dynamic === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the dns2 address of the 2nd ethernet interface
     *
     * @param data: dynamic dhcp
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2SettingsDnsAddress2: function getLan2SettingsDnsAddress2(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LAN2_NAMESERVER2;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.dynamic === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the dns2 address of the 2nd ethernet interface
     *
     * @param data.dnsAddress: the dns address as a string (e.g. '96.67.2.1')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLan2DnsAddress2: function setLan2DnsAddress2(data, socket) {
        var txData = new Uint8Array(8);
        var temp = ipv4AddressString2Int(data.dnsAddress);
        var cmd = wolfProtCommands.WPC_CB_LAN2_NAMESERVER2;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the mac address of the 2nd ethernet interface
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2MacAddress: function getLan2MacAddress(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN2_MAC;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * request the dhcp state of the 2nd ethernet interface
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2DhcpState: function getLan2DhcpState(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN2_DHCP_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * activates the 2nd ethernet settings
     *
     * @param data: ununsed
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLan2Activate: function setLan2Activate(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LAN2_ACTIVATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the 2nd lan authentication mode
     *
     * @param data.mode: true => on, false => off
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLan2AuthMode: function setLan2AuthMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LAN2_AUTH_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.mode === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the 2nd lan authentication mode
     * @method getLanAuthMode
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2AuthMode: function getLan2AuthMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN2_AUTH_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the 2nd lan authentication method
     *
     * @param data.authMethode: (eg.  'peap-mschapv2',
     *                           'ttls-pap'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLan2AuthMethod: function setLan2AuthMethod(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LAN2_AUTH_METHOD;
        var mapping = {
            'peap-mschapv2': wolfProtParameters.WPP_CB_LAN_AUTH_METHOD_PEAP_MSCHAPV2,
            'ttls-pap': wolfProtParameters.WPP_CB_LAN_AUTH_METHOD_TTLS_PAP
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.authMethod];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests 2nd the lan authentication method
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2AuthMethod: function getLan2AuthMethod(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN2_AUTH_METHOD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the 2nd lan identity
     * @method getLanIdentity
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2Identity: function getLan2Identity(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN2_IDENTITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the 2nd lan identity
     *
     * @param data.identity: the identity
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLan2Identity: function setLan2Identity(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.name);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_LAN2_IDENTITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the 2nd lan anonymous identity
     * @method getLanAnonymousIdentity
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2AnonymousIdentity: function getLan2AnonymousIdentity(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN2_ANONYMOUS_IDENTITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the 2nd lan anonymous identity
     *
     * @param data.anonymousIdentity: the anonymous identity
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLan2AnonymousIdentity: function setLan2AnonymousIdentity(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.name);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_LAN2_ANONYMOUS_IDENTITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the 2nd lan password
     *
     * @param data.password: the password that should be used
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLan2Password: function setLan2Password(data, socket) {
        var password = data.password || '';
        var txData = new Uint8Array(4 + password.length);
        var cmd = wolfProtCommands.WPC_CB_LAN2_PASSWORD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = password.length;

        for (var i = 0; i < password.length; ++i) {
            txData[i + 4] = password.charCodeAt(i);
        }
        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     *
     * requests 2nd the lan password
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2Password: function getLan2Password(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN2_PASSWORD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the 2nd lan certificate mode
     *
     * @param data.mode: true => on, false => off
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLan2CertificateMode: function setLan2CertificateMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LAN2_CERT_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.mode === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the 2nd lan certificate mode
     * @method getLanCertificateMode
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2CertificateMode: function getLan2CertificateMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN2_CERT_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the 2nd lan certificate file name
     *
     * @param data.name: the filename of the certificate
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLan2CertificateFileName: function setLan2CertificateFileName(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.name);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_LAN2_CERT_NAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the 2nd lan certificate filename
     * @method getLanCertificateFileName
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2CertificateFileName: function getLan2CertificateFileName(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN2_CERT_NAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the 2nd lan certificate file
     *
     * @param data.name: the filename
     * @param data.file: the file as ByteArray
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLan2CertificateFile: function setLan2CertificateFile(data, socket) {
        var tempArrayName = StringConvert.stringToUtf8(data.name);
        var tempArrayFile = !!data.file ? new Uint8Array(data.file) : new Uint8Array(0);
        var txData = new Uint8Array(10 + tempArrayName.length + tempArrayFile.length);
        var cmd = wolfProtCommands.WPC_CB_LAN2_CERT_FILE;
        var length = tempArrayFile.length + tempArrayName.length + 2;
        var mapping = {
            'delete': wolfProtParameters.WPP_CB_CERTIFICATE_FILE_DELETE,
            'save': wolfProtParameters.WPP_CB_CERTIFICATE_FILE_SAVE,
            'verify': wolfProtParameters.WPP_CB_CERTIFICATE_FILE_VERIFY
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_HDR | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = wolfProtHeaders.WP_EXTHEAD_EXT_LEN;
        txData[2] = (cmd >> 8) & 0xFF;
        txData[3] = cmd & 0xFF;
        txData[4] = (length >> 24) & 0xff;
        txData[5] = (length >> 16) & 0xff;
        txData[6] = (length >> 8) & 0xff;
        txData[7] = length & 0xff;
        txData[8] = mapping[data.action];
        txData[9] = tempArrayName.length;

        txData.set(tempArrayName, 10);
        txData.set(tempArrayFile, 10 + tempArrayName.length);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * returns the 2nd lan certificate list
     * @method getLanCertificates
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2Certificates: function getLan2Certificates(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN2_CERT_FILE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the 2nd lan priority
     *
     * @param data.priority: 1,2,3 (will be converted to zero based index)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLan2Priority: function setLan2Priority(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LAN2_PRIORITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.priority - 1;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the 2nd lan priority
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2Priority: function getLan2Priority(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN2_PRIORITY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets dhcp of the 2nd lan mode
     *
     * @param data.mode: vz, lan
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLan2Mode: function setLan2Mode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LAN2_MODE;
        var mapping = {
            'vz': wolfProtParameters.WPP_CB_LAN2_MODE_VZ,
            'lan': wolfProtParameters.WPP_CB_LAN2_MODE_LAN
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.mode];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the 2nd lan mode
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2Mode: function getLan2Mode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN2_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the 2nd lan status
     * @method getLan2Status
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLan2Status: function getLan2Status(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LAN2_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the stream input configuration
     * @method setStreamInput
     * @param data: index
     * @param data: mode
     * @param data: name
     * @param data: url
     * @param socket: the websocket connection
     */
    setStreamInput: function setStreamInput(data, socket) {
        var tempArrayName = StringConvert.stringToUtf8(data.name);
        var tempArrayUrl = StringConvert.stringToUtf8(data.url);
        var length = 7 + tempArrayName.length + tempArrayUrl.length;
        var txData = new Uint8Array(5 + length);
        var cmd = wolfProtCommands.WPC_CB_STREAM_INPUT;

        var mapping = {
            'none': wolfProtParameters.WPP_CB_STREAM_INPUT_NONE,
            'generic': wolfProtParameters.WPP_CB_STREAM_INPUT_GENERIC,
            'rtspRtp': wolfProtParameters.WPP_CB_STREAM_INPUT_RTSP_RTP,
            'multicast': wolfProtParameters.WPP_CB_STREAM_INPUT_RTSP_RTP_MULTICAST
        };

        var mappingType = {
            'stream': wolfProtParameters.WPP_STREAM_INPUT_TYPE_STREAM,
            'camera': wolfProtParameters.WPP_STREAM_INPUT_TYPE_CAMERA,
            'computer': wolfProtParameters.WPP_STREAM_INPUT_TYPE_COMPUTER,
            'visualizer': wolfProtParameters.WPP_STREAM_INPUT_TYPE_VISUALIZER,
            'hdmi': wolfProtParameters.WPP_STREAM_INPUT_TYPE_HDMI,
            'discPlayer': wolfProtParameters.WPP_STREAM_INPUT_TYPE_DISCPLAYER,
            'cynap': wolfProtParameters.WPP_STREAM_INPUT_TYPE_CYNAP
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_LEN | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8) & 0xFF;
        txData[4] = length & 0xFF;

        txData[5] = data.index;
        txData[6] = mapping[data.mode];

        txData[7] = tempArrayName.length;
        txData.set(tempArrayName, 8);

        txData[8 + tempArrayName.length] = tempArrayUrl.length;
        txData.set(tempArrayUrl, 9 + tempArrayName.length);

        txData[9 + tempArrayName.length + tempArrayUrl.length] = mappingType[data.type];

        txData[10 + tempArrayName.length + tempArrayUrl.length] = data.lowLatency;
        txData[11 + tempArrayName.length + tempArrayUrl.length] = data.mute;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the recording stream input configuration
     */
    getRecordingStreamInput: function getRecordingStreamInput(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_RECORDING_STREAM_INPUT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the recording trigger
     * @method getRecordingTrigger
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getRecordingTrigger: function getRecordingTrigger(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_RECORDING_TRIGGER;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.USER),
            cmd: cmd
        };
    },

    /**
     * request status of stream input while recording
     * @method: getRecordingStreamInputHealthStatus
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getRecordingStreamInputHealthStatus: function getRecordingStreamInputHealthStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_RECORDING_STREAM_INPUT_HEALTH;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the recording stream input configuration
     * @method setRecordingStreamInput
     * @param data: mode
     * @param data: audio
     * @param data: url
     * @param socket: the websocket connection
     */
    setRecordingStreamInput: function setRecordingStreamInput(data, socket) {
        var tempArrayUrl = StringConvert.stringToUtf8(data.url);
        var length = 5 + tempArrayUrl.length;
        var txData = new Uint8Array(5 + length);
        var cmd = wolfProtCommands.WPC_CB_RECORDING_STREAM_INPUT;

        var mapping = {
            'none': wolfProtParameters.WPP_CB_STREAM_INPUT_NONE,
            'generic': wolfProtParameters.WPP_CB_STREAM_INPUT_GENERIC,
            'rtspRtp': wolfProtParameters.WPP_CB_STREAM_INPUT_RTSP_RTP,
            'multicast': wolfProtParameters.WPP_CB_STREAM_INPUT_RTSP_RTP_MULTICAST
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_LEN | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8) & 0xFF;
        txData[4] = length & 0xFF;

        txData[5] = mapping[data.mode];
        txData[6] = data.audio === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        txData[7] = (data.offset >> 8) & 0xFF;
        txData[8] = data.offset & 0xFF;

        txData[9] = tempArrayUrl.length;
        txData.set(tempArrayUrl, 10);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the usb device list
     * @method getOpencastCertificateFileName
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getUsbDeviceList: function getUsbDeviceList(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_USB_DEVICE_LIST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the recording stream input configuration
     * @param data: stream input index
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getStreamInput: function getStreamInput(data, socket) {
        var txData;
        var cmd = wolfProtCommands.WPC_CB_STREAM_INPUT;

        //List of input streams
        if (data.index === undefined) {
            txData = new Uint8Array(4);
            txData[3] = 0x00;
        } else {
            txData = new Uint8Array(5);
            txData[3] = 0x01;
            txData[4] = data.index;
        }

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the webcast mode
     * @method getWebcastMode
     * @param data: webcast mode
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWebcastMode: function getWebcastMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WEBCAST_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set webcast mode
     * @method setWebcastMode
     * @param data: webcast mode
     * @param socket: the websocket connection
     * @returns cmd: the command number
     */
    setWebcastMode: function setWebcastMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WEBCAST_MODE;
        var mapping = {
            'off': wolfProtParameters.WPP_CB_WEBCAST_MODE_OFF,
            'custom': wolfProtParameters.WPP_CB_WEBCAST_MODE_CUSTOM,
            'youtube': wolfProtParameters.WPP_CB_WEBCAST_MODE_YOUTUBE
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.mode];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the webcast streaming mode (start/stop)
     * @method getWebcastStreamingMode
     * @param data: webcast mode
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWebcastStreamingMode: function getWebcastStreamingMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WEBCAST_STREAMING_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set webcast streaming mode (start/stop)
     * @method setWebcastStreamingMode
     * @param data: webcast mode
     * @param socket: the websocket connection
     * @returns cmd: the command number
     */
    setWebcastStreamingMode: function setWebcastStreamingMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WEBCAST_STREAMING_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.mode === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * request webcast custom url
     * @method getWebcastCustomUrls
     * @param data: unused
     * @param socket: the websocket connection
     * @returns cmd: the command number
     */
    getWebcastCustomUrls: function getWebcastCustomUrls(data, socket) {
        const txData = new Uint8Array(4);
        const cmd = wolfProtCommands.WPC_CB_WEBCAST_CUSTOM_URLS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set webcast custom urls
     * @method setWebcastCustomUrls
     * @param data: unused
     * @param socket: the websocket connection
     * @returns cmd: the command number
     */
    setWebcastCustomUrls: function setWebcastCustomUrls(data, socket) {
        const urlHdmi1 = data.urlHdmi1 || '';
        const urlHdmi2 = data.urlHdmi2 || '';
        const urlExt = data.urlExt || '';
        const extType = data.extType || 'hdmi-in';

        const urlHdmi1Buffer = stringToBufferWithLength({ str: urlHdmi1, lengthBytes: 2 });
        const urlHdmi2Buffer = stringToBufferWithLength({ str: urlHdmi2, lengthBytes: 2 });
        const urlExtBuffer = stringToBufferWithLength({ str: urlExt, lengthBytes: 2 });

        const len = 1 + urlHdmi1Buffer.length + urlHdmi2Buffer.length + urlExtBuffer.length;
        const txData = new Uint8Array(5 + len);
        const cmd = wolfProtCommands.WPC_CB_WEBCAST_CUSTOM_URLS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_LEN | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (len) >> 8;
        txData[4] = (len) & 0x00FF;

        let offset = 5;
        txData.set(urlHdmi1Buffer, offset);
        offset += urlHdmi1Buffer.length;

        txData.set(urlExtBuffer, offset);
        offset += urlExtBuffer.length;

        txData[offset] = states.webcastExtTypes[extType];
        offset += 1;

        txData.set(urlHdmi2Buffer, offset);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * request webcast youtube stream name
     * @method getWebcastYoutubeStreamName
     * @param data: unused
     * @param socket: the websocket connection
     * @returns cmd: the command number
     */
    getWebcastYoutubeStreamName: function getWebcastYoutubeStreamName(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WEBCAST_YOUTUBE_STREAM_NAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * connect with webcast youtube
     * @method setWebcastYoutubeConnect
     * @param socket: the websocket connection
     * @returns cmd: the command number
     */
    setWebcastYoutubeConnect: function setWebcastYoutubeConnect(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WEBCAST_YOUTUBE_CONNECT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * request the webcast youtube login state
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWebcastYoutubeLoginState: function getWebcastYoutubeLoginState(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WEBCAST_YOUTUBE_LOGIN_STATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * logout from webcast youtube
     * @method setWebcastYoutubeLogout
     * @param socket: the websocket connection
     * @returns cmd: the command number
     */
    setWebcastYoutubeLogout: function setWebcastYoutubeLogout(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WEBCAST_YOUTUBE_LOGOUT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * request the webcast youtube stream state
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWebcastYoutubeStreamState: function getWebcastYoutubeStreamState(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WEBCAST_YOUTUBE_STREAM_STATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * request the webcast state
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWebcastState: function getWebcastState(data, socket) {
        const txData = new Uint8Array(5);
        const cmd = wolfProtCommands.WPC_CB_WEBCAST_STATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = 0x01;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Request the webcast active protocol.
     *
     * @param data unused
     * @param socket the websocket connection
     * @returns {{promise: *, cmd: number}}
     */
    getWebcastActiveProtocol: function getWebcastActiveProtocol(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WEBCAST_ACTIVE_PROTOCOL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the rms client test
     *
     * @param data.id: index
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setRmsClientTest: function setRmsClientTest(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_RMS_CLIENT_TEST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.id;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the rms client trigger
     *
     * @param data.id: index
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setRmsClientTrigger: function setRmsClientTrigger(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_RMS_CLIENT_TRIGGER;

        var mapping = {
            'button1': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_1,
            'button2': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_2,
            'button3': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_3,
            'button4': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_4,
            'button5': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_5,
            'button6': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_6,
            'button7': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_7,
            'button8': wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_8
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.eventName];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     *
     * tests the stream input recroding settings
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setTestMediaCasting: function setTestMediaCasting(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_TEST_STREAM_RECORD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the app recording enable
     *
     * @param data.mode: true => on, false => off
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAppRecordingEnable: function setAppRecordingEnable(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_APP_RECORDING;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enabled === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the app recording enabled value
     * @method: getAppRecordingEnable
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAppRecordingEnable: function getAppRecordingEnable(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_APP_RECORDING;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the webconference recording enable
     *
     * @method: setWebconferenceRecording
     * @param data.enabled: true => on, false => off
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWebconferenceRecording: function setWebconferenceRecording(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WEBCONFERENCE_ENABLE_RECORDING;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enabled === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the webconference recording enabled value
     * @method: getWebconferenceRecording
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWebconferenceRecording: function getWebconferenceRecording(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WEBCONFERENCE_ENABLE_RECORDING;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * swaps the HDMI Outputs
     *
     * @method: setSwapHdmiOutputs
     * @param data.swap: true => on, false => off
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setSwapHdmiOutputs: function setSwapHdmiOutputs(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_SWAP_HDMI_OUTPUTS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.swap === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets HDMI output swap value
     * @method: getSwapHdmiOutputs
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getSwapHdmiOutputs: function getSwapHdmiOutputs(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_SWAP_HDMI_OUTPUTS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the legacy webconference mode
     *
     * @method: setWebconferenceHdmi2
     * @param data.hdmi2: true => on, false => off
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWebconferenceHdmi2: function setWebconferenceHdmi2(data, socket) {
        const txData = new Uint8Array(5);
        const cmd = wolfProtCommands.WPC_CB_WEBCONFERENCE_HDMI2;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.hdmi2 === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets mode for Webconference handling
     * @method: getWebconferenceHdmi2
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWebconferenceHdmi2: function getWebconferenceHdmi2(data, socket) {
        const txData = new Uint8Array(4);
        const cmd = wolfProtCommands.WPC_CB_WEBCONFERENCE_HDMI2;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the system language
     *
     * @method: setSystemLanguage
     * @param data.language: en, de, ja, fr, zh
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setSystemLanguage: function setSystemLanguage(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_SYSTEM_LANGUAGE;

        var mapping = {
            'en': wolfProtParameters.WPP_CB_SYSTEM_LANGUAGE_ENGLISH,
            'de': wolfProtParameters.WPP_CB_SYSTEM_LANGUAGE_GERMAN,
            'fr': wolfProtParameters.WPP_CB_SYSTEM_LANGUAGE_FRENCH,
            'ja': wolfProtParameters.WPP_CB_SYSTEM_LANGUAGE_JAPANESE,
            'zh': wolfProtParameters.WPP_CB_SYSTEM_LANGUAGE_CHINESE,
            'nor': wolfProtParameters.WPP_CB_SYSTEM_LANGUAGE_NORWEGIAN,
            'ru': wolfProtParameters.WPP_CB_SYSTEM_LANGUAGE_RUSSIAN
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.language];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the system language value
     * @method: getSystemLanguage
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getSystemLanguage: function getSystemLanguage(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_SYSTEM_LANGUAGE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Gets the UTC offset of the timezone in minutes.
     * E.g. America/Los Angeles (GMT-8): We get -480 Minutes.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getTimezoneUTCOffset: function getTimezoneUTCOffset(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_TIMEZONE_UTC_OFFSET_MINUTES;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the miracast p2p mode
     * @method: getMiracastP2PMode
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMiracastP2PMode: function getMiracastP2PMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MIRACAST_P2P_ROLE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the miracast p2p mode
     *
     * @method: setMiracastP2PMode
     * @param data.role: client, go
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMiracastP2PMode: function setMiracastP2PMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MIRACAST_P2P_ROLE;

        var mapping = {
            'client': wolfProtParameters.WPP_CB_MIRACAST_CLIENT,
            'go': wolfProtParameters.WPP_CB_MIRACAST_GO
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.role];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * returns the model
     *
     * @method: getModel
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getModel: function getModel(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MODEL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * returns the usb mic card list
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getUsbMicCardList: function getUsbMicCardList(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_USB_MIC_CARD_LIST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the usb mic card name
     *
     * @method: setUsbMicCardName
     * @param data.name
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setUsbMicCardName: function setUsbMicCardName(data, socket) {
        var tempName = StringConvert.stringToUtf8(data.name);
        var txData = new Uint8Array(4 + tempName.length);
        var cmd = wolfProtCommands.WPC_CB_USB_MIC_CARD_NAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempName.length;

        txData.set(tempName, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * returns the usb mic card name
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getUsbMicCardName: function getUsbMicCardName(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_USB_MIC_CARD_NAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * set Airplay bluetooth device discovery
     *
     * @method: setAirplayBleDiscovery
     * @param data.enable
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAirplayBleDiscovery: function setAirplayBleDiscovery(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_AIRPLAY_BLE_DISCOVERY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enable === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * returns airplay bluetooth device discovery enable/disable
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAirplayBleDiscovery: function getAirplayBleDiscovery(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_AIRPLAY_BLE_DISCOVERY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * set Airplay interface
     *
     * @method: setAirplayInterface
     * @param data.interface (wlan/lan)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAirplayInterface: function setAirplayInterface(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_AIRPLAY_INTERFACE;
        var mapping = {
            'lan1': wolfProtParameters.WPP_CB_AIRPLAY_INTERFACE_LAN1,
            'wlan': wolfProtParameters.WPP_CB_AIRPLAY_INTERFACE_WLAN,
            'lan2': wolfProtParameters.WPP_CB_AIRPLAY_INTERFACE_LAN2,
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.interface];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * returns airplay interface
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAirplayInterface: function getAirplayInterface(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_AIRPLAY_INTERFACE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * get fw update status
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getFwUpdateStatus: function getFwUpdateStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_USB_FW_UPDATE_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * usb fw update
     * @method setUsbFwUpdate
     * @param data: load
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setUsbFwUpdate: function setUsbFwUpdate(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = data.load === true ? wolfProtCommands.WPC_CB_USB_FW_UPDATE_START : wolfProtCommands.WPC_CB_USB_FW_UPDATE_SKIP;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the office 365 function
     *
     * @method: setOffice365Function
     * @param data.function: boolean (enable/disable)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setOffice365Function: function setOffice365Function(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_OFFICE365_FUNCTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.function === true ? wolfProtParameters.WPP_CB_ENABLED : wolfProtParameters.WPP_CB_DISABLED;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * returns the office 365 function
     *
     * @method getOffice365Function
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getOffice365Function: function getOffice365Function(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_OFFICE365_FUNCTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Office 365 control
     * @method setOffice365Control
     * @param data.cmd: (e.g. 'zoomIn',
     *                        'zoomOut',
     *                        'reload'
     *                        ...)
     *
     * @param data.appId: the ID of the application where this command should be applied to
     * @param data.type {(msoutlook|msword|msexcel|mspowerpoint|msonenote|msteams|mswhiteboard|msplanner)}: application type
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setOffice365Control: function setOffice365Control(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_CONTROL_SPECIFIC_APPLICATION;
        let txData = new Uint8Array(15);

        const mapping = {
            'reload': wolfProtParameters.WPP_OFFICE365_RELOAD,
            'stop': wolfProtParameters.WPP_OFFICE365_STOP,
            'zoomIn': wolfProtParameters.WPP_OFFICE365_ZOOM_IN,
            'zoomOut': wolfProtParameters.WPP_OFFICE365_ZOOM_OUT,
            'cursorDown': wolfProtParameters.WPP_OFFICE365_CURSOR_DOWN,
            'cursorUp': wolfProtParameters.WPP_OFFICE365_CURSOR_UP,
            'cursorRight': wolfProtParameters.WPP_OFFICE365_CURSOR_RIGHT,
            'cursorLeft': wolfProtParameters.WPP_OFFICE365_CURSOR_LEFT,
            'back': wolfProtParameters.WPP_OFFICE365_BACK,
            'forward': wolfProtParameters.WPP_OFFICE365_FORWARD,
            'scrollDown': wolfProtParameters.WPP_OFFICE365_SCROLL_DOWN,
            'scrollUp': wolfProtParameters.WPP_OFFICE365_SCROLL_UP,
            'scrollRight': wolfProtParameters.WPP_OFFICE365_SCROLL_RIGHT,
            'scrollLeft': wolfProtParameters.WPP_OFFICE365_SCROLL_LEFT
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;
        txData[4] = 0x0A;
        txData[5] = CONTENT_MAPPING[data.type] || wolfProtParameters.WPP_CONTENT_OFFICE365_OUTLOOK;
        txData.set(helper.fromHexString(data.appId), 6);
        txData[14] = mapping[data.cmd];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the uvc inputs
     *
     * @method: setUvcInput
     * @param data.index
     * @param data.name
     * @param data.device
     * @param data.type
     * @param data.autostart
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setUvcInput: function setUvcInput(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_UVC_INPUT_3;
        var name = !data.name ? '' : data.name;
        var tempName = StringConvert.stringToUtf8(name);
        var device = !data.device ? '' : data.device;
        var tempDevice = StringConvert.stringToUtf8(device);
        var tempVideoModeJson = StringConvert.stringToUtf8(JSON.stringify(data.videoMode));
        var len = 13 + tempName.length + tempDevice.length + tempVideoModeJson.length;
        var txData = new Uint8Array(len + 5);
        var pos;
        var mapping = {
            'none': wolfProtParameters.WPP_UVC_INPUT_NONE,
            'camera': wolfProtParameters.WPP_UVC_INPUT_CAMERA,
            'visualizer': wolfProtParameters.WPP_UVC_INPUT_VZ,
            'hdmi': wolfProtParameters.WPP_UVC_INPUT_HDMI,
            'computer': wolfProtParameters.WPP_UVC_INPUT_COMPUTER,
            'discPlayer': wolfProtParameters.WPP_UVC_INPUT_DISCPLAYER
        };

        var mappingAutostart = {
            'none': wolfProtParameters.WPP_UVC_INPUT_AUTOSTART_NONE,
            'off': wolfProtParameters.WPP_UVC_INPUT_AUTOSTART_OFF,
            'on': wolfProtParameters.WPP_UVC_INPUT_AUTOSTART_ON
        };


        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_LEN | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = len >> 8;
        txData[4] = len;
        txData[5] = data.index;
        txData[6] = mapping[data.type];
        txData[7] = data.useForWebRTC === true ? 0x01 : 0x00;
        txData[8] = tempName.length;
        pos = 9;

        txData.set(tempName, pos);
        pos += tempName.length;

        txData[pos] = tempDevice.length;
        pos += 1;

        txData.set(tempDevice, pos);
        pos += tempDevice.length;

        txData[pos] = data.audio === true ? 0x01 : 0x00;
        pos += 1;

        txData[pos] = mappingAutostart[data.autostart];
        pos += 1;

        txData[pos] = tempVideoModeJson.length;
        pos += 1;

        txData.set(tempVideoModeJson, pos);
        pos += tempVideoModeJson.length;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * returns the connected uvc input devices
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getUvcInputDevices: function getUvcInputDevices(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_UVC_DEVICE_LIST_3;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * returns the uvc inputs
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getUvcInput: function getUvcInput(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_UVC_INPUT_3;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.index;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the state of the poxy function (on/off)
     *
     * @method: setProxyFunction
     * @param data.function: off, on
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setProxyFunction: function setProxyFunction(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_PROXY_FUNCTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.function === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the state of the proxy function (on/off)
     *
     * @method: getProxyFunction
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getProxyFunction: function getProxyFunction(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PROXY_FUNCTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets proxy url
     *
     * @method: setProxyUrl
     * @param data.url: proxy url (max. 255 chars)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setProxyUrl: function setProxyUrl(data, socket) {
        var url = data.url || '';
        var tempArray = StringConvert.stringToUtf8(url);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_PROXY_URL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the proxy url
     *
     * @method: getProxyUrl
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getProxyUrl: function getProxyUrl(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PROXY_URL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets proxy port
     *
     * @method: setProxyPort
     * @param data.port: proxy port (0..65535)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setProxyPort: function setProxyPort(data, socket) {
        var txData = new Uint8Array(6);
        var temp = parseInt(data.port);
        var cmd = wolfProtCommands.WPC_CB_PROXY_PORT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = (temp >> 8) & 0xFF;
        txData[5] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the proxy port
     *
     * @method: getProxyPort
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getProxyPort: function getProxyPort(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PROXY_PORT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the state of the poxy authentication mode (on/off)
     *
     * @method: setProxyAuthMode
     * @param data.mode: off, on
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setProxyAuthMode: function setProxyAuthMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_PROXY_AUTH_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.mode === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the state of the proxy authentication mode (on/off)
     *
     * @method: getProxyAuthMode
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getProxyAuthMode: function getProxyAuthMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PROXY_AUTH_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets proxy username
     *
     * @method: setProxyUsername
     * @param data.username: proxy username
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setProxyUsername: function setProxyUsername(data, socket) {
        var username = data.username || '';
        var tempArray = StringConvert.stringToUtf8(username);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_PROXY_USERNAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the proxy username
     *
     * @method: getProxyUsername
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getProxyUsername: function getProxyUsername(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PROXY_USERNAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets proxy password
     *
     * @method: setProxyPassword
     * @param data.username: proxy password
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setProxyPassword: function setProxyPassword(data, socket) {
        var password = data.password || '';
        var tempArray = StringConvert.stringToUtf8(password);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_PROXY_PASSWORD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the proxy password
     *
     * @method: getProxyPassword
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getProxyPassword: function getProxyPassword(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PROXY_PASSWORD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * activate proxy
     *
     * @method: setProxyActivate
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setProxyActivate: function setProxyActivate(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_PROXY_ACTIVATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },


    /**
     *
     * set browser startpage
     * @method setBrowserStartpage
     * @param data.url startpage url
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setBrowserStartpage: function setBrowserStartpage(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.url);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_BROWSER_STARTPAGE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     *
     * get browser startpage
     * @method getBrowserStartpage
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getBrowserStartpage: function getBrowserStartpage(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_BROWSER_STARTPAGE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * returns the bookmark list
     * @method getBookmarksList
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getBookmarkList: function getBookmarkList(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_BOOKMARK_LIST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * set the bookmark list
     * @method setBookmarkList
     * @param data.list: list of bookmarks (JSON String)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setBookmarkList: function setBookmarkList(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_BOOKMARK_LIST;
        var length;
        var txData;

        var tempArrayList = StringConvert.stringToUtf8(data.list);
        txData = new Uint8Array(8 + tempArrayList.length);
        length = tempArrayList.length;

        txData.set(tempArrayList, 8);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_HDR | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = wolfProtHeaders.WP_EXTHEAD_EXT_LEN;
        txData[2] = (cmd >> 8) & 0xFF;
        txData[3] = cmd & 0xFF;
        txData[4] = (length >> 24) & 0xff;
        txData[5] = (length >> 16) & 0xff;
        txData[6] = (length >> 8) & 0xff;
        txData[7] = length & 0xff;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * returns the download link list
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getDownloadLinkList: function getDownloadLinkList(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_DOWNLOAD_LINK_LIST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * sets the url interface for the qr-code
     *
     * @param data.interface: (eg.  'lan1',
     *                              'lan2'
     *                              'wlan'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setURLInterface: function setURLInterface(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_URL_INTERFACE;
        var mapping = {
            'lan1': wolfProtParameters.WPP_CB_URL_INTERFACE_LAN1,
            'lan2': wolfProtParameters.WPP_CB_URL_INTERFACE_LAN2,
            'wlan': wolfProtParameters.WPP_CB_URL_INTERFACE_WLAN
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.interface];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the url interface for the qr-code
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getURLInterface: function getURLInterface(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_URL_INTERFACE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Sets the SDP mode for the stream (static or unique dynamic link)
     *
     * @param data.interface: (eg.  'static',
     *                              'dynamic'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setStreamSDPMode: function setStreamSDPMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_STREAM_SDP_MODE;
        var mapping = {
            'static': wolfProtParameters.WPP_CB_STREAM_SDP_MODE_STATIC,
            'dynamic': wolfProtParameters.WPP_CB_STREAM_SDP_MODE_DYNAMIC
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.mode];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the SDP mode for the stream (static or unique dynamic link)
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getStreamSDPMode: function getStreamSDPMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_STREAM_SDP_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the Content mode recording source
     *
     * @param data.interface: (eg.  'main',
     *                              'aux'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setContentModeRecordingSource: function setContentModeRecordingSource(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_CONTENT_MODE_REC_SRC;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.mode ? wolfProtParameters.WPP_CB_CONTENT_MODE_REC_SRC_AUX : wolfProtParameters.WPP_CB_CONTENT_MODE_REC_SRC_MAIN;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the Content mode recording source
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getContentModeRecordingSource: function getContentModeRecordingSource(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_CONTENT_MODE_REC_SRC;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.USER),
            cmd: cmd
        };
    },

    /**
     * sets the lan, lan2, wlan mirroring (on/off)
     *
     * @method: setProxyFunction
     * @param data.function: off, on
     * @param data.interface: lan, lan2, wlan
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMirroringInterfaceFunction: function setMirroringInterfaceFunction(data, socket) {
        var txData = new Uint8Array(5);
        var mapping = {
            'lan1': wolfProtCommands.WPC_CB_MIRRORING_LAN_ENABLE,
            'lan2': wolfProtCommands.WPC_CB_MIRRORING_LAN2_ENABLE,
            'wlan': wolfProtCommands.WPC_CB_MIRRORING_WLAN_ENABLE
        };

        var cmd = mapping[data.interface];

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enable === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the mirroring interface function (lan, lan2, wlan) (on/off)
     *
     * @method: getMirroringInterfaceFunction
     * @param data.interface: lan, lan2, wlan
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMirroringInterfaceFunction: function getMirroringInterfaceFunction(data, socket) {
        var txData = new Uint8Array(4);
        var mapping = {
            'lan1': wolfProtCommands.WPC_CB_MIRRORING_LAN_ENABLE,
            'lan2': wolfProtCommands.WPC_CB_MIRRORING_LAN2_ENABLE,
            'wlan': wolfProtCommands.WPC_CB_MIRRORING_WLAN_ENABLE
        };

        var cmd = mapping[data.interface];

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Returns the upcoming events.
     *
     * @param data:unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEvents: function getEvents(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_EVENTS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the screensaver state.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getScreensaverState: function getScreensaverState(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_SCREENSAVER_STATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set screensaver settings.
     *
     * @param data.standby: true/false
     * @param data.screenOff: true/false
     * @param data.screensaver: true/false
     * @param data.delayStandby: standby delay in minutes
     * @param data.delayScreenOff: hdmi/screen off delay in minutes
     * @param data.url: screensaver url
     * @param data.autoScreensaver: enabled true/false
     * @param data.keepFilesAutoScreensaver: true/false
     * @param data.delayAutoScreensaver: auto screensaver delay in minutes
     * @param data.interactiveMode: enabled true/false
     * @param socket
     * @returns {{promise, cmd: number}}
     */
    setScreensaverSettings: function setScreensaverSettings(data, socket) {
        var tempDelayStandby = parseInt(data.delayStandby);
        var tempDelayScreenOff = parseInt(data.delayScreenOff);
        var tempUrl = StringConvert.stringToUtf8(data.url);

        var txData = new Uint8Array(22 + tempUrl.length);
        var length = 14 + tempUrl.length;
        var cmd = wolfProtCommands.WPC_CB_SCREENSAVER_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_HDR | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = wolfProtHeaders.WP_EXTHEAD_EXT_LEN;
        txData[2] = (cmd >> 8) & 0xFF;
        txData[3] = cmd & 0xFF;
        txData[4] = (length >> 24) & 0xFF;
        txData[5] = (length >> 16) & 0xFF;
        txData[6] = (length >> 8) & 0xFF;
        txData[7] = length & 0xFF;

        txData[8] = data.standby === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;
        txData[9] = data.screenOff === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;
        txData[10] = data.screensaver === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        txData[11] = (tempDelayStandby >> 8) & 0xFF;
        txData[12] = tempDelayStandby & 0xFF;

        txData[13] = (tempDelayScreenOff >> 8) & 0xFF;
        txData[14] = tempDelayScreenOff & 0xFF;

        txData[15] = (tempUrl.length >> 8) & 0xFF;
        txData[16] = tempUrl.length & 0xFF;

        txData.set(tempUrl, 17);

        //Auto screensaver settings
        var pos = 17 + tempUrl.length;
        txData[pos] = data.autoScreensaver;
        txData[pos + 1] = data.keepFilesAutoScreensaver;
        txData[pos + 2] = data.delayAutoScreensaver >> 8;
        txData[pos + 3] = data.delayAutoScreensaver & 0xFF;

        txData[pos + 4] = data.interactiveMode === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Upload local screensaver to Cbox.
     *
     * @param data.bytes Uint8Array of bytes to download
     * @param socket
     * @returns {{promise, cmd: number}}
     */
    setLocalScreensaver: function setLocalScreensaver(data, socket) {
        var tmpArrayName = data.name ? StringConvert.stringToUtf8(data.name) : StringConvert.stringToUtf8('');
        if (tmpArrayName.length > 254) {
            tmpArrayName = tmpArrayName.slice(0, 254);
        }

        var tmpArrayZipArchive = data.bytes ? new Uint8Array(data.bytes) : new Uint8Array(0);
        var txData = new Uint8Array(14 + tmpArrayName.length + tmpArrayZipArchive.length);
        var length = 6 + tmpArrayName.length + tmpArrayZipArchive.length;
        var cmd = wolfProtCommands.WPC_CB_LOCAL_SCREENSAVER;
        var mapping = {
            'delete': wolfProtParameters.WPP_CB_LOCAL_SCREENSAVER_DELETE,
            'save': wolfProtParameters.WPP_CB_LOCAL_SCREENSAVER_SAVE
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_HDR | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = wolfProtHeaders.WP_EXTHEAD_EXT_LEN;
        txData[2] = (cmd >> 8) & 0xFF;
        txData[3] = cmd & 0xFF;
        txData[4] = (length >> 24) & 0xFF;
        txData[5] = (length >> 16) & 0xFF;
        txData[6] = (length >> 8) & 0xFF;
        txData[7] = length & 0xFF;
        txData[8] = mapping[data.action];
        txData[9] = tmpArrayName.length;

        txData.set(tmpArrayName, 10);
        var i = 10 + tmpArrayName.length;
        txData[i++] = (tmpArrayZipArchive.length >> 24) & 0xFF;
        txData[i++] = (tmpArrayZipArchive.length >> 16) & 0xFF;
        txData[i++] = (tmpArrayZipArchive.length >> 8) & 0xFF;
        txData[i++] = tmpArrayZipArchive.length & 0xFF;

        txData.set(tmpArrayZipArchive, i);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the session middleware start/stop trigger.
     *
     * @param data.action: endpoint address
     * @param data.eventId: endpoint address
     * @param data.pwd: password
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setSessionMiddlewareTrigger: function setSessionMiddlewareTrigger(data, socket) {
        var length = data.eventId.length + data.pwd.length + 5;
        var txData = new Uint8Array(length + 5);
        var cmd = wolfProtCommands.WPC_CB_SESSION_MIDDLEWARE_TRIGGER;

        var actionMapping = {
            'start': wolfProtParameters.WPP_CB_SESSION_MIDDLEWARE_TRIGGER_START,
            'stop': wolfProtParameters.WPP_CB_SESSION_MIDDLEWARE_TRIGGER_STOP
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_LEN | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8) & 0xFF;
        txData[4] = (length) & 0xFF;
        txData[5] = actionMapping[data.action];
        txData[6] = (data.eventId.length >> 8) & 0xFF;
        txData[7] = (data.eventId.length) & 0xFF;

        for (var i = 0; i < data.eventId.length; ++i) {
            txData[i + 8] = data.eventId.charCodeAt(i);
        }

        txData[8 + data.eventId.length] = (data.pwd.length >> 8) & 0xFF;
        txData[9 + data.eventId.length] = (data.pwd.length) & 0xFF;

        for (i = 0; i < data.pwd.length; ++i) {
            txData[i + data.eventId.length + 10] = data.pwd.charCodeAt(i);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the screensaver settings.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getScreensaverSettings: function getScreensaverSettings(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_SCREENSAVER_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the setting for screen off when no content open.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getScreenOffNoContent: function getScreenOffNoContent(data, socket) {
        let txData = new Uint8Array(4);
        const cmd = wolfProtCommands.WPC_CB_SCREEN_OFF_NO_CONTENT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Enable/disable screen off mode when no content open.
     *
     * @param data.enabled (true|false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setScreenOffNoContent: function setScreenOffNoContent(data, socket) {
        let txData = new Uint8Array(5);
        const cmd = wolfProtCommands.WPC_CB_SCREEN_OFF_NO_CONTENT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.enabled === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the local screensaver status.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
     getLocalScreensaver: function getLocalScreensaver(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LOCAL_SCREENSAVER;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the serial number.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getSerialNumber: function getSerialNumber(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_SERIALNUMBER;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the active session.
     *
     * @param data: unused
     */
    getActiveSession: function getActiveSession(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_SESSION_ACTIVE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the signal level limit for wlan.
     *
     * @param data.signalLevel: between -40 and -85 dBm
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanSignalLevelLimit: function setWlanSignalLevelLimit(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_SIGNAL_LEVEL_LIMIT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.signalLevel;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the signal level limit for wlan.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanSignalLevelLimit: function getWlanSignalLevelLimit(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_SIGNAL_LEVEL_LIMIT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets support pin.
     *
     * @param data.pin: pin
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setSupportPin: function setSupportPin(data, socket) {
        var txData = new Uint8Array(13);
        var cmd = wolfProtCommands.WPC_CB_SUPPORT_PIN;
        var pin = StringConvert.stringToUtf8(data.pin);

        if (pin.length < 8) {
            data.pin = pad(data.pin, pin.length === 0 ? 8 : 8 - (pin.length - 1));
            pin = StringConvert.stringToUtf8(data.pin);
        } else if (pin.length > 8) {
            pin = pin.slice(0, 7);
        }

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 9;
        txData[4] = 8;

        txData.set(pin, 5);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the support pin.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getSupportPin: function getSupportPin(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_SUPPORT_PIN;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the vz pictureheader
     *
     * @param data: formatMode
     * @param data: resMode
     * @param data: resFrequency
     * @param data: resId
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVZPicHeader: function getVZPicHeader(data, socket) {
        var txData = new Uint8Array(8);
        var cmd = wolfProtCommands.WPC_PICTUREHEADER;

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd;
        txData[2] = 0x05;
        txData[3] = data.formatMode;
        txData[4] = 0x00;
        txData[5] = data.resMode;
        txData[6] = data.resFrequency;
        txData[7] = data.resId;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the vz picture
     *
     * @param data: mode
     * @param data: picSize
     * @param data: width
     * @param data: height
     * @param data: memPage
     * @param data: offset16by9
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVZPicture: function getVZPicture(data, socket) {
        var txData = new Uint8Array(25);
        var cmd = wolfProtCommands.WPC_PICTUREBLOCK2;

        txData[0] = wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd;
        txData[2] = 0x16;
        txData[3] = data.formatFlags;
        txData[4] = data.formatMode;
        txData[5] = 0x00;
        txData[6] = 0x00;
        txData[7] = 0x00;
        txData[8] = 0x00;
        valueToBuffer(data.picSize, txData, 9, 4);
        valueToBuffer(data.width, txData, 13, 2);
        valueToBuffer(data.height, txData, 15, 2);
        valueToBuffer(data.picSize, txData, 17, 4);
        valueToBuffer(data.memPage, txData, 21, 2);
        valueToBuffer(data.offset16by9, txData, 23, 2);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets echo cancel status.
     *
     * @param data.enabled: true/false (enabled/disabled)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setEchoCancel: function setEchoCancel(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ECHO_CANCEL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enabled === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the echo cancel status (enabled/disabled).
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEchoCancel: function getEchoCancel(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ECHO_CANCEL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets echo cancel delay.
     *
     * @param data.delay: delay (min 20, max 500)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setEchoCancelDelay: function setEchoCancelDelay(data, socket) {
        var txData = new Uint8Array(6);
        var tempDelay = parseInt(data.delay);
        var cmd = wolfProtCommands.WPC_CB_ECHO_CANCEL_DELAY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = (tempDelay >> 8) & 0xFF;
        txData[5] = tempDelay & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the echo cancel delay (Min 20, max 500).
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEchoCancelDelay: function getEchoCancelDelay(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ECHO_CANCEL_DELAY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the master discovered devices.
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMatrixDiscoverdDevices: function(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_DISCOVERED_DEVICES;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the master station setup.
     *
     * @param socket: the websocket connection
     * @param index
     * @return cmd: the command number
     */
    getMatrixStationSetup: function(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_SETUP_STATIONS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 1;
        txData[4] = data.index;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets matrix station setup.
     *
     * @param data.stationList: list of serials
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixStationSetup: function(data, socket) {
        var length = (data.stationList.length * 6) + data.audioList.length + data.limitedAccessList.length;
        var txData = new Uint8Array(6 + length);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_SETUP_STATIONS;
        var blockLen = 6;
        var n = 6;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_LEN | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length + 1 >> 8) & 0xFF;
        txData[4] = length + 1 & 0xFF;

        txData[5] = data.templateId;

        for (var i = 0; i < data.stationList.length; i++) {
            var serial = data.stationList[i];

            txData[n] = (blockLen >> 8) & 0xFF;
            txData[n + 1] = blockLen & 0xFF;
            txData[n + 2] = (serial >> 24) & 0xFF;
            txData[n + 3] = (serial >> 16) & 0xFF;
            txData[n + 4] = (serial >> 8) & 0xFF;
            txData[n + 5] = serial & 0xFF;
            txData[n + 6] = data.audioList[i];
            txData[n + 7] = data.limitedAccessList[i];

            n += 8;
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets matrix master pull from station.
     *
     * @param data.mode: 0.. stop / 1.. start
     * @param data.serial: serial Number
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixMasterPull: function(data, socket) {
        const txData = new Uint8Array(9);
        const cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_PULL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x05;

        txData[4] = data.mode;
        txData[5] = (data.serial >> 24) & 0xFF;
        txData[6] = (data.serial >> 16) & 0xFF;
        txData[7] = (data.serial >> 8) & 0xFF;
        txData[8] = data.serial & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets matrix master pull from station to output.
     *
     * @param {Object} data: matrix pull options
     * @param {0|1} data.mode: 0.. stop / 1.. start
     * @param {String} data.serial: serial Number
     * @param {'hdmi1'|'hdmi2'} data.output: output port, hdmi1 or hdmi2
     * @param {0|String} data.replaceAppId: 0 if no replace is needed
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixMasterPullToOutput: function(data, socket) {
        const txData = new Uint8Array(19);
        const cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_PULL;
        const output = data.output || 'hdmi1';
        const replaceAppId = data.replaceAppId || '';

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x0F;

        txData[4] = data.mode;
        txData[5] = (data.serial >> 24) & 0xFF;
        txData[6] = (data.serial >> 16) & 0xFF;
        txData[7] = (data.serial >> 8) & 0xFF;
        txData[8] = data.serial & 0xFF;

        txData[9] = (OUTPUT_MAPPING[output] >> 8) & 0xFF;
        txData[10] = OUTPUT_MAPPING[output] & 0xFF;
        txData.set(helper.fromHexString(replaceAppId), 11);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the master status.
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMatrixStatus: function(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets matrix master push to station.
     *
     * @param data.mode: 0.. stop / 1.. start
     * @param data.serial: serial Number
     * @param data.pushIndex: 0...3 (stream)
     *                        255 master hdmi1
     *                        252 master hdmi2
     *                        254 station to station (src)
     *                        253 station to station (dest)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixMasterPush: function(data, socket) {
        const txData = new Uint8Array(10);
        const cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_PUSH;

        const pushIndex = typeof data.pushIndex === 'string' ? states.pushIndexStates[data.pushIndex] : data.pushIndex;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 6;

        txData[4] = data.mode;
        txData[5] = (data.serial >> 24) & 0xFF;
        txData[6] = (data.serial >> 16) & 0xFF;
        txData[7] = (data.serial >> 8) & 0xFF;
        txData[8] = data.serial & 0xFF;
        txData[9] = pushIndex & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets matrix master push to station.
     *
     * @param data.mode: 0.. stop / 1.. start
     * @param data.serialSrc: serial Number
     * @param data.serialDest: serial Number
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixStationToStationPush: function(data, socket) {
        var txData = new Uint8Array(13);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_PUSH;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 9;

        txData[4] = data.mode;
        txData[5] = (data.serialSrc >> 24) & 0xFF;
        txData[6] = (data.serialSrc >> 16) & 0xFF;
        txData[7] = (data.serialSrc >> 8) & 0xFF;
        txData[8] = data.serialSrc & 0xFF;
        txData[9] = (data.serialDest >> 24) & 0xFF;
        txData[10] = (data.serialDest >> 16) & 0xFF;
        txData[11] = (data.serialDest >> 8) & 0xFF;
        txData[12] = data.serialDest & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get matrix master mode (Matrix enable/disable)
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMatrixMasterMode: function getMatrixMasterMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets matrix master mode (Matrix enable/disable)
     *
     * @param data.enabled: true/false (enabled/disabled)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixMasterMode: function setMatrixMasterMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enabled === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get matrix master preview picture
     *
     * @param data.serial: serial Number
     * @param data.width: image width
     * @param data.height: image height
     * @param data.fps: frames per second
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMatrixMasterPreviewPicture: function(data, socket) {
        var txData = new Uint8Array(13);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_PREVIEW;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 9;

        txData[4] = (data.serial >> 24) & 0xFF;
        txData[5] = (data.serial >> 16) & 0xFF;
        txData[6] = (data.serial >> 8) & 0xFF;
        txData[7] = data.serial & 0xFF;
        txData[8] = (data.width >> 8) & 0xFF;
        txData[9] = data.width & 0xFF;
        txData[10] = (data.height >> 8) & 0xFF;
        txData[11] = data.height & 0xFF;
        txData[12] = data.fps & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets matrix master file share.
     * Share file with station.
     *
     * @param data.serial: station serial number
     * @param data.file: shared file path
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixMasterFileShareAdd: function setMatrixMasterFileShareAdd(data, socket) {
        var length = 10 + data.filePath.length;
        var txData = new Uint8Array(length);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_FILE_SHARE_ADD;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = length - 4;
        txData[4] = (data.serial >> 24) & 0xFF;
        txData[5] = (data.serial >> 16) & 0xFF;
        txData[6] = (data.serial >> 8) & 0xFF;
        txData[7] = data.serial & 0xFF;

        txData[8] = (data.filePath.length >> 8) & 0xFF;
        txData[9] = data.filePath.length & 0xFF;

        for (var i = 0; i < data.filePath.length; i++) {
            txData[10 + i] = data.filePath.charCodeAt(i);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets matrix master file share.
     * Remove shared file from station.
     *
     * @param data.action: 0 - add, 1 - remove
     * @param data.serial: station serial number
     * @param data.file: shared file path
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixMasterFileShareRemove: function setMatrixMasterFileShareRemove(data, socket) {
        var length = 10 + data.fileName.length;
        var txData = new Uint8Array(length);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_FILE_SHARE_REMOVE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = length - 4;
        txData[4] = (data.serial >> 24) & 0xFF;
        txData[5] = (data.serial >> 16) & 0xFF;
        txData[6] = (data.serial >> 8) & 0xFF;
        txData[7] = data.serial & 0xFF;

        txData[8] = (data.fileName.length >> 8) & 0xFF;
        txData[9] = data.fileName.length & 0xFF;

        for (var i = 0; i < data.fileName.length; i++) {
            txData[10 + i] = data.fileName.charCodeAt(i);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get matrix station status (station mode on/off, station push status on/off)
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMatrixStationStatus: function getMatrixStationStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_STATION_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get matrix annotation active - if Matrix annotation enabled, started and active on master.
     *
     * @param data unused
     * @param socket the websocket connection
     * @returns cmd: the command number
     */
    getMatrixMasterAnnotationActive: function getMatrixMasterAnnotationActive(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_ANNOTATION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.COLLAB),
            cmd: cmd
        };
    },

    /**
     * Get Matrix master annotation enabled - if Matrix annotation is enabled.
     *
     * @param data unused
     * @param socket the websocket connection
     * @returns cmd: the command number
     */
    getMatrixMasterAnnotationEnabled: function getMatrixMasterAnnotationEnabled(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_ANNOTATION_COLLAB;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.USER),
            cmd: cmd
        };
    },

    /**
     * Set Matrix master annotation enabled - if Matrix annotation is enabled/disabled.
     *
     * @param data.recordingFunction: recording enabled/disabled
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixMasterAnnotationEnabled: function setMatrixMasterAnnotationEnabled(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_ANNOTATION_COLLAB;
        var enabled = data.enabled ? wolfProtParameters.WPP_CB_ENABLED : wolfProtParameters.WPP_CB_DISABLED;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = enabled;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set Matrix power station.
     *
     * @param data.stationList: list of stations with serial numbers
     * @param data.action: power on/off station (true/false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixPowerStation: function setMatrixPowerStation(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_MATRIX_POWER_STATION;
        var action = data.action  === true ? 0x01 : 0x00;
        var txData = new Uint8Array(5);

        //Power off specific stations from station list.
        if (data.stationList) {
            var len = data.stationList.length * 7;
            var pointer = 8;
            txData = new Uint8Array(8 + len);

            txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_HDR | wolfProtHeaders.WP_HEAD_DIR_SET;
            txData[1] = wolfProtHeaders.WP_EXTHEAD_EXT_LEN;
            txData[2] = (cmd >> 8) & 0xFF;
            txData[3] = cmd & 0xFF;
            txData[4] = (len >> 24) & 0xFF;
            txData[5] = (len >> 16) & 0xFF;
            txData[6] = (len >> 8) & 0xFF;
            txData[7] = len & 0xFF;

            _.each(data.stationList, function(station) {
                var serial = station.serial;

                txData[pointer] = 0x00;
                txData[pointer + 1] = 0x07; // payload: 2 byte len + 1 byte action + 4byte serial (4 byte to be consistent with other matrix cmds)

                txData[pointer + 2] = action;

                txData[pointer + 3] = (serial >> 24) & 0xFF;
                txData[pointer + 4] = (serial>> 16) & 0xFF;
                txData[pointer + 5] = (serial >> 8) & 0xFF;
                txData[pointer + 6] = (serial) & 0xFF;

                pointer += 7;
            }.bind(this));
        } else { // if (!data.stationList) power off all stations from active template.
            txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
            txData[1] = (cmd >> 8) & 0xFF;
            txData[2] = cmd & 0xFF;
            txData[3] = 0x01;
            txData[4] = action;
        }


        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get admin-pin status.
     *
     * ANSWER:
     * {
     *     status: true // or false
     * }
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAdminPinStatus: function getAdminPinStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ADMIN_PIN_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the Admin pin state.
     *
     * @param data.status: enabled/disabled
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAdminPinStatus: function setAdminPinStatus(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ADMIN_PIN_STATUS;
        var mapping = {
            'disable': wolfProtParameters.WPP_ADMIN_PIN_DISABLED,
            'enable': wolfProtParameters.WPP_ADMIN_PIN_ENABLED
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.status];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get the Admin pin only while presenting
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAdminPinOnlyWhilePresenting: function getAdminPinOnlyWhilePresenting(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ADMIN_PIN_ONLY_WHILE_PRESENTING;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the Admin pin only while presenting.
     *
     * @param data.status: enabled/disabled
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAdminPinOnlyWhilePresenting: function setAdminPinOnlyWhilePresenting(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ADMIN_PIN_ONLY_WHILE_PRESENTING;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enabled === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },



    /**
     * Set generate logfile.
     *
     * @param data.write: usb/local
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setGenerateLogfile: function setGenerateLogfile(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_GENERATE_LOGFILE;
        var mapping = {
            'usb': wolfProtParameters.WPP_CB_GENERATE_LOGFILE_USB,
            'local': wolfProtParameters.WPP_CB_GENERATE_LOGFILE_LOCAL
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.write];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get generate logfile status
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getGenerateLogfileStatus: function getGenerateLogfileStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_GENERATE_LOGFILE_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLectureCaptureSettings: function getLectureCaptureSettings(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LECTURE_CAPTURE_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.USER),
            cmd: cmd
        };
    },

    /**
     * Returns the state of the disk encryption.
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @return cmd: the command number
     */
    getDiskEncryptionStatus: function getDiskEncryptionStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_DISK_ENCRYPTION_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set lecture capture settings.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLectureCaptureSettings: function setLectureCaptureSettings(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_LECTURE_CAPTURE_SETTINGS;
        var filename = data.filename || '';
        var txData = new Uint8Array(11 + filename.length);
        var length = 7 + filename.length;

        /** Set default values. */
        data.type = states.lcsTypeToCmdMapping[data.type] || wolfProtParameters.WPP_CB_LECTURE_CAPTURE_SETTINGS_TYPE_DISABLED;
        data.mode = states.lcsModeToCmdMapping[data.mode] || wolfProtParameters.WPP_CB_LECTURE_CAPTURE_SETTINGS_MODE_AUTO;
        data.startHour = data.startHour || 0x00;
        data.startMinute = data.startMinute || 0x00;
        data.path = wolfProtParameters.WPC_CB_LECTURE_CAPTURE_SETTINGS_PATH_INTERNAL;
        data.ipCameraMode = states.ipCameraModeToCmd[data.ipCameraMode] || wolfProtParameters.WPC_CB_LECTURE_CAPTURE_SETTINGS_IP_CAMERA_MODE_OFF;
        data.filename = data.filename || '';

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = length;
        txData[4] = data.type;
        txData[5] = data.mode;
        txData[6] = data.startHour;
        txData[7] = data.startMinute;
        txData[8] = data.path;
        txData[9] = data.ipCameraMode;
        txData[10] = data.filename.length;

        for (var i = 0; i < filename.length; i++) {
            txData[i + 11] = filename.charCodeAt(i);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get Panopto Settings.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getPanoptoSettings: function getPanoptoSettings(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PANOPTO_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /*
     * Sets the disk encryption mode.
     *
     * @param data: data.enable (true or false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setDiskEncryptionEnable: function setDiskEncryptionEnable(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_DISK_ENCRYPTION_ENABLE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set panopto settings.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setPanoptoSettings: function setPanoptoSettings(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_PANOPTO_SETTINGS;
        var hostname = StringConvert.stringToUtf8(data.hostname) || new Uint8Array(0);
        var username = StringConvert.stringToUtf8(data.username) || new Uint8Array(0);
        var password = StringConvert.stringToUtf8(data.password) || new Uint8Array(0);
        var certificateBlob = data.certificateBlob ? new Uint8Array(data.certificateBlob) : new Uint8Array(0);
        var regKey = StringConvert.stringToUtf8(data.regKey) || new Uint8Array(0);
        var clientId = StringConvert.stringToUtf8(data.clientId) || new Uint8Array(0);
        var clientSecret = StringConvert.stringToUtf8(data.clientSecret) || new Uint8Array(0);
        var length = 10 + hostname.length + username.length + password.length + certificateBlob.length + regKey.length
            + clientId.length + clientSecret.length;
        var txData = new Uint8Array(length + 8);
        var x = 9;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_HDR | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = wolfProtHeaders.WP_EXTHEAD_EXT_LEN;
        txData[2] = (cmd >> 8) & 0xFF;
        txData[3] = cmd & 0xFF;
        txData[4] = (length >> 24) & 0xff;
        txData[5] = (length >> 16) & 0xff;
        txData[6] = (length >> 8) & 0xff;
        txData[7] = length & 0xff;
        txData[8] = hostname.length;

        txData.set(hostname, x);
        x += hostname.length;

        txData[x] = username.length;
        x++;
        txData.set(username, x);
        x += username.length;

        txData[x] = password.length;
        x++;
        txData.set(password, x);
        x += password.length;

        txData[x] = data.certificate === true ? 0x01 : 0x00;
        x++;

        txData[x] = (certificateBlob.length >> 8) & 0xff;
        x++;

        txData[x] = certificateBlob.length & 0xff;
        x++;

        if (certificateBlob.length > 0) {
            txData.set(certificateBlob, x);
            x += certificateBlob.length;
        }

        txData[x] = regKey.length;
        x++;

        if (regKey.length > 0) {
            txData.set(regKey, x);
            x += regKey.length;
        }

        txData[x] = clientId.length;
        x++;

        if (clientId.length > 0) {
            txData.set(clientId, x);
            x += clientId.length;
        }

        txData[x] = clientSecret.length;
        x++;

        if (clientSecret.length > 0) {
            txData.set(clientSecret, x);
            x += clientSecret.length;
        }

        txData[x] = data.customUserMode === true ? wolfProtCodes.parameters.WPP_ON : wolfProtCodes.parameters.WPP_OFF;
        x++;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get Opencast Settings.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getOpencastSettings: function getOpencastSettings(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_OPENCAST_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set opencast settings.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setOpencastSettings: function setOpencastSettings(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_OPENCAST_SETTINGS;
        var hostname = data.hostname || '';
        var hostnameLength = hostname.length;
        var captureAgent = data.captureAgent || '';
        var captureAgentLength = captureAgent.length;
        var username = data.username || '';
        var usernameLength = username.length;
        var password = data.password || '';
        var passwordLength = password.length;
        var workflowId = data.workflowId || '';
        var workflowIdLength = workflowId.length;
        var certificateBlob = data.certificateBlob ? new Uint8Array(data.certificateBlob) : new Uint8Array(0);
        var certificateLength = certificateBlob.length || 0x01;
        var length = 8 + hostnameLength + captureAgentLength + usernameLength + passwordLength + workflowIdLength + certificateLength;
        var txData = new Uint8Array(length + 8);
        var x = 9;
        var i;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_HDR | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = wolfProtHeaders.WP_EXTHEAD_EXT_LEN;
        txData[2] = (cmd >> 8) & 0xFF;
        txData[3] = cmd & 0xFF;
        txData[4] = (length >> 24) & 0xff;
        txData[5] = (length >> 16) & 0xff;
        txData[6] = (length >> 8) & 0xff;
        txData[7] = length & 0xff;
        txData[8] = hostnameLength;

        for (i = 0; i < hostname.length; i++) {
            txData[x] = hostname.charCodeAt(i);
            x++;
        }

        txData[x] = captureAgentLength;
        x++;

        for (i = 0; i < captureAgent.length; i++) {
            txData[x] = captureAgent.charCodeAt(i);
            x++;
        }

        txData[x] = usernameLength;
        x++;

        for (i = 0; i < username.length; i++) {
            txData[x] = username.charCodeAt(i);
            x++;
        }

        txData[x] = passwordLength;
        x++;

        for (i = 0; i < password.length; i++) {
            txData[x] = password.charCodeAt(i);
            x++;
        }

        txData[x] = workflowIdLength;
        x++;

        for (i = 0; i < workflowId.length; i++) {
            txData[x] = workflowId.charCodeAt(i);
            x++;
        }

        txData[x] = data.certificate === true ? 0x01 : 0x00;
        x++;
        txData[x] = (certificateLength >> 8) & 0xff;
        x++;
        txData[x] = certificateLength & 0xff;
        x++;

        txData.set(certificateBlob, x);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Start LCS recording with giben recording type.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLectureCaptureStartRecording: function setLectureCaptureStartRecording(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_LECTURE_CAPTURE_START_RECORDING;
        var type = data.type || 'panopto';
        var ipCameraMode = data.ipCameraMode || 'off';
        var txData;
        var length;
        var x = 7;
        var treeIdLength;
        var tempId;

        if (type.startsWith('panopto')) { //PANOPTO
            var tempFilename = StringConvert.stringToUtf8(data.filename);
            var filenameLength = tempFilename.length;

            tempId = StringConvert.stringToUtf8(data.treeId);
            treeIdLength = tempId.length;

            length = 4 + treeIdLength + filenameLength;
            txData = new Uint8Array(length + 4);

            txData[4] = states.lcsStartRecordingTypes[type];
            txData[5] = states.ipCameraModeToCmd[ipCameraMode];
            txData[6] = treeIdLength;

            txData.set(tempId, x);
            x += treeIdLength;

            txData[x] = filenameLength;
            x++;

            txData.set(tempFilename, x);
        } else { //OPENCAST
            var tempCreatorName = StringConvert.stringToUtf8(data.name);
            var creatorNameLength = tempCreatorName.length;
            var tempTitleName =  StringConvert.stringToUtf8(data.title);
            var titleNameLength = tempTitleName.length;

            tempId = StringConvert.stringToUtf8(data.treeId);
            treeIdLength = tempId.length;

            length = 5 + treeIdLength + creatorNameLength + titleNameLength;
            txData = new Uint8Array(length + 4);

            txData[4] = states.lcsStartRecordingTypes[type];
            txData[5] = states.ipCameraModeToCmd[ipCameraMode];
            txData[6] = treeIdLength;

            txData.set(tempId, x);
            x += treeIdLength;

            txData[x] = creatorNameLength;
            x++;

            txData.set(tempCreatorName, x);
            x += creatorNameLength;

            txData[x] = titleNameLength;
            x++;

            txData.set(tempTitleName, x);
        }

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = length;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Return firmware signature enabled or disabled value.
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @return cmd: the command number
     */
    getFirmwareSignautreEnable: function getFirmwareSignautreEnable(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_FW_SIGNATURE_CHECK;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Returns all Panoptos directories as a tree.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getPanoptoSubDirectories: function getPanoptoSubDirectories(data, socket) {
        const folderId = data.folderId;
        const tempArray = StringConvert.stringToUtf8(folderId);
        const length = folderId ? (5 + tempArray.length) : 4;
        const txData = new Uint8Array(length);
        const cmd = wolfProtCommands.WPC_CB_PANOPTO_SUB_DIRECTORIES;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        if (folderId) {
            txData[3] = tempArray.length + 1;
            txData[4] = tempArray.length;

            txData.set(tempArray, 5);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Returns the Panopto login-state.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getPanoptoLoginStatus: function getPanoptoLoginStatus(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_PANOPTO_LOGIN_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.USER),
            cmd: cmd
        };
    },

    /**
     * Returns the Panopto upload-state.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLcsUploadStatus: function getLcsUploadStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LECTURE_CAPTURE_UPLOAD_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.USER),
            cmd: cmd
        };
    },

    /**
     * Set firmware signature enabled or disabled.
     *
     * @param data.recordingFunction: recording enabled/disabled
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setFirmwareSignatureEnable: function setFirmwareSignatureEnable(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_FW_SIGNATURE_CHECK;
        var enabled = data.fwSignatureEnable ? wolfProtParameters.WPP_CB_ENABLED : wolfProtParameters.WPP_CB_DISABLED;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = enabled;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Return miracast backchannel enabled or disabled value.
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @return cmd: the command number
     */
    getMiracastBackChannel: function getMiracastBackChannel(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPP_CB_MIRACAST_BACK_CHANNEL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Enable or Disable Miracast back channel.
     *
     * @param data.recordingFunction: recording enabled/disabled
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMiracastBackChannel: function setMiracastBackChannel(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPP_CB_MIRACAST_BACK_CHANNEL;
        var enabled = data.enabled;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = enabled;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Return matrix auto open file enabled or disabled value.
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @return cmd: the command number
     */
    getMatrixAutoOpenFile: function getMatrixAutoOpenFile(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_AUTO_OPEN_FILE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Enable or Disable Matrix auto open file.
     *
     * @param data.autoOpenFile: enabled/disabled
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixAutoOpenFile: function setMatrixAutoOpenFile(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_AUTO_OPEN_FILE;
        var enabled = data.enabled;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = enabled;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Return LDAP auth mode enabled or disabled value.
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @return cmd: the command number
     */
    getLdapAuthMode: function getLdapAuthMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LDAP_AUTH_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Enable or Disable LDAP auth mode.
     *
     * @param data.enabled: enabled/disabled
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLdapAuthMode: function setLdapAuthMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LDAP_AUTH_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enabled === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Return LDAP tls mode enabled or disabled value.
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @return cmd: the command number
     */
    getLdapTlsMode: function getLdapTlsMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LDAP_TLS_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Enable or Disable LDAP tls mode.
     *
     * @param data.enabled: enabled/disabled
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLdapTlsMode: function setLdapTlsMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_LDAP_TLS_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enabled === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Return LDAP status.
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @return cmd: the command number
     */
    getLdapStatus: function getLdapStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LDAP_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set LDAP server url.
     *
     * @param data.url: string containing the LDAP URL (max 255 characters)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLdapServerUrl: function setLdapServerUrl(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.url);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_LDAP_SERVER_URL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get LDAP server url.
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLdapServerUrl: function getLdapServerUrl(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LDAP_SERVER_URL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set LDAP server domain.
     *
     * @param data.domain: string containing the LDAP URL (max 255 characters)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLdapServerDomain: function setLdapServerDomain(data, socket) {
        var tempArray = StringConvert.stringToUtf8(data.domain);
        var txData = new Uint8Array(4 + tempArray.length);
        var cmd = wolfProtCommands.WPC_CB_LDAP_SERVER_DOMAIN;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempArray.length;

        txData.set(tempArray, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get LDAP server domain.
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLdapServerDomain: function getLdapServerDomain(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LDAP_SERVER_DOMAIN;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set LDAP login data.
     *
     * @param username: username
     * @param password: password
     * @param socket: the websocket connection
     * @returns cmd: the command number
     */
    setLdapLogin: function setLdapLogin(data, socket) {
        var username = data.username || '';
        var password = data.password || '';
        var tempArrayUn = StringConvert.stringToUtf8(username);
        var tempArrayPw = StringConvert.stringToUtf8(password);
        var txData = new Uint8Array(6 + tempArrayUn.length + tempArrayPw.length);
        var cmd = wolfProtCommands.WPC_CB_LDAP_LOGIN;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 2 + tempArrayUn.length + tempArrayPw.length;
        //Username
        txData[4] = tempArrayUn.length;
        txData.set(tempArrayUn, 5);

        //Password
        txData[5 + tempArrayUn.length] = tempArrayPw.length;
        txData.set(tempArrayPw, 5 + tempArrayUn.length + 1);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the LDAP login.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @returns cmd: the command number
     */
    getLdapLogin: function getLdapLogin(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LDAP_LOGIN;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Return webbrowser viewer support enabled or disabled value.
     * [ritörn webbrauser wiuer sapport änebld oar disebld väliu]
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @return cmd: the command number
     */
    getWebbrowserViewerSupport: function getWebbrowserViewerSupport(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WEBBROWSER_VIEWER_SUPPORT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Enable or Disable webbrowser viewer support.
     *
     * @param data.enabled: enabled/disabled
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWebbrowserViewerSupport: function setWebbrowserViewerSupport(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WEBBROWSER_VIEWER_SUPPORT;
        var enabled = data.enabled;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = enabled;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Return webbrowser viewer PIN enabled or disabled value.
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @return cmd: the command number
     */
    getWebbrowserViewerPinEnabled: function getWebbrowserViewerPinEnabled(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WEBBROWSER_VIEWER_PIN_ENABLED;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Enable or Disable webbrowser viewer PIN.
     *
     * @param data.enabled: enabled/disabled
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWebbrowserViewerPinEnabled: function setWebbrowserViewerPinEnabled(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WEBBROWSER_VIEWER_PIN_ENABLED;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enabled;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Show PIN on Viewer PIN request.
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWebbrowserViewerPinPopup: function setWebbrowserViewerPinPopup(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WEBBROWSER_VIEWER_PIN_POPUP;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * gets the ldap certificate filename
     * @method getLanCertificateFileName
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getLdapCertificateFileName: function getLanCertificateFileName(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_LDAP_CERTIFICATE_NAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the ldap certificate file
     *
     * @param data.name: the filename
     * @param data.file: the file as ByteArray
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setLdapCertificateFile: function setLanCertificateFile(data, socket) {
        var tempArrayName = StringConvert.stringToUtf8(data.name);
        var tempArrayFile = !!data.file ? new Uint8Array(data.file) : new Uint8Array(0);
        var txData = new Uint8Array(10 + tempArrayName.length + tempArrayFile.length);
        var cmd = wolfProtCommands.WPC_CB_LDAP_CERTIFICATE;
        var length = tempArrayFile.length + tempArrayName.length + 2;
        var mapping = {
            'delete': wolfProtParameters.WPP_CB_CERTIFICATE_FILE_DELETE,
            'save': wolfProtParameters.WPP_CB_CERTIFICATE_FILE_SAVE,
            'verify': wolfProtParameters.WPP_CB_CERTIFICATE_FILE_VERIFY
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_HDR | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = wolfProtHeaders.WP_EXTHEAD_EXT_LEN;
        txData[2] = (cmd >> 8) & 0xFF;
        txData[3] = cmd & 0xFF;
        txData[4] = (length >> 24) & 0xff;
        txData[5] = (length >> 16) & 0xff;
        txData[6] = (length >> 8) & 0xff;
        txData[7] = length & 0xff;
        txData[8] = mapping[data.action];
        txData[9] = tempArrayName.length;

        txData.set(tempArrayName, 10);
        txData.set(tempArrayFile, 10 + tempArrayName.length);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Return window settings (Window mode/Auto fullscreen mode/mixed mode)
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @return cmd: the command number
     */
    getWindowSettings: function getWindowSettings(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WINDOW_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set window settings to side-by-side/fullscreen or mixed mode.
     *
     * @param data.enabled: enabled/disabled
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWindowSettings: function setWindowSettings(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WINDOW_SETTINGS;
        var mapping = {
            'sideBySide': wolfProtParameters.WPP_CB_WINDOW_SETTINGS_SIDE_BY_SIDE,
            'fullscreen': wolfProtParameters.WPP_CB_WINDOW_SETTINGS_FULLSCREEN_MODE,
            'mixed': wolfProtParameters.WPP_CB_WINDOW_SETTINGS_MIXED_MODE
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.mode];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Returns if BYOD endless mode enabled/disabled.
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @returns {{promise: *, cmd: number}}
     */
    getByodEndlessMode: function getByodEndlessMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_BYOD_ENDLESS_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set BYOD Endless Mode enabled/disabled.
     *
     * @param data: enabled
     * @param socket: the websocket connection
     *
     * @returns {{promise: *, cmd: number}}
     */
    setByodEndlessMode: function setByodEndlessMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_BYOD_ENDLESS_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enabled;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Returns USB Speaker enabled.
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @returns {{promise: *, cmd: number}}
     */
    getUsbSpeaker: function getUsbSpeaker(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_USB_SPEAKER;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set USB Speaker enabled.
     *
     * @param data: enabled
     * @param data: webcon split
     * @param socket: the websocket connection
     *
     * @returns {{promise: *, cmd: number}}
     */
    setUsbSpeaker: function setUsbSpeaker(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = wolfProtCommands.WPC_CB_USB_SPEAKER;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 2;
        txData[4] = data.enabled;
        txData[5] = data.webconfSplit;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Returns current USB Speaker name.
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @returns {{promise: *, cmd: number}}
     */
    getUsbSpeakerCardName: function getUsbSpeakerCardName(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_USB_SPEAKER_CARD_NAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set active USB Speaker name.
     *
     * @param data: cardName active card name
     * @param socket: the websocket connection
     *
     * @returns {{promise: *, cmd: number}}
     */
    setUsbSpeakerCardName: function setUsbSpeakerCardName(data, socket) {
        var tempName = StringConvert.stringToUtf8(data.name);
        var txData = new Uint8Array(4 + tempName.length);
        var cmd = wolfProtCommands.WPC_CB_USB_SPEAKER_CARD_NAME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = tempName.length;

        txData.set(tempName, 4);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Returns list of USB Speakers.
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @returns {{promise: *, cmd: number}}
     */
    getUsbSpeakerCardList: function getUsbSpeakerCardList(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_USB_SPEAKER_CARD_LIST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Returns USB Speaker volume value.
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @returns {{promise: *, cmd: number}}
     */
    getUsbSpeakerVolume: function getUsbSpeakerVolume(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_USB_SPEAKER_VOLUME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set USB Speaker volume value.
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @returns {{promise: *, cmd: number}}
     */
    setUsbSpeakerVolume: function setUsbSpeakerVolume(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_USB_SPEAKER_VOLUME;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 1;
        txData[4] = data.volume;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Returns Matrix Template Id.
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @returns {{promise: *, cmd: number}}
     */
    getMasterActiveTemplate: function getMasterActiveTemplate(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_ACTIVE_TEMPLATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set Matrix Template Id.
     *
     * @param data: templateId
     * @param socket: the websocket connection
     *
     * @returns {{promise: *, cmd: number}}
     */
    setMasterActiveTemplate: function setMasterActiveTemplate(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_MATRIX_ACTIVE_TEMPLATE;
        var tempArrayTemplateName = StringConvert.stringToUtf8(data.templateName);
        var txData = new Uint8Array(7 + tempArrayTemplateName.length);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_LEN | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (tempArrayTemplateName.length + 2 >> 8) & 0xFF;
        txData[4] = tempArrayTemplateName.length + 2 & 0xFF;
        txData[5] = data.templateId;
        txData[6] = tempArrayTemplateName.length;

        txData.set(tempArrayTemplateName, 7);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Returns all Opencast series as a tree.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getOpencastSeries: function getOpencastSeries(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_OPENCAST_SERIES;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Returns the Opencast login-state.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getOpencastStatus: function getOpencastStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_OPENCAST_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.USER),
            cmd: cmd
        };
    },

    /**
     * Requests the MATRIX Netdrive configuration.
     *
     * @param data: netdrive index
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMatrixNetdrive: function getMatrixNetdrive(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_NETDRIVE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the MATRIX Netdrive configuration.
     *
     * @param data: netdrive
     * @param socket: the websocket connection
     */
    setMatrixNetdrive: function setMatrixNetdrive(data, socket) {
        var tempArrayUser = StringConvert.stringToUtf8(data.user);
        var tempArrayPassword = StringConvert.stringToUtf8(data.password);
        var tempArrayUrl = StringConvert.stringToUtf8(data.url);
        var tempArrayDomain = StringConvert.stringToUtf8(data.domain);
        var length = 7 + tempArrayDomain.length + tempArrayPassword.length + tempArrayUrl.length + tempArrayUser.length + 2;
        var txData = new Uint8Array(5 + length);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_NETDRIVE;

        var mapping = {
            'none': wolfProtParameters.WPP_CB_NETDRIVE_TYPE_NONE,
            'cifs': wolfProtParameters.WPP_CB_NETDRIVE_TYPE_CIFS,
            'nfs': wolfProtParameters.WPP_CB_NETDRIVE_TYPE_NFS
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_LEN | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8) & 0xFF;
        txData[4] = length & 0xFF;
        txData[5] = mapping[data.type];
        txData[6] = tempArrayUrl.length;
        txData.set(tempArrayUrl, 7);
        txData[7 + tempArrayUrl.length] = tempArrayUser.length;
        txData.set(tempArrayUser, 8 + tempArrayUrl.length);
        txData[8 + tempArrayUrl.length + tempArrayUser.length] = tempArrayPassword.length;
        txData.set(tempArrayPassword, 9 + tempArrayUrl.length + tempArrayUser.length);
        txData[9 + tempArrayUrl.length + tempArrayUser.length + tempArrayPassword.length] = tempArrayDomain.length;
        txData.set(tempArrayDomain, 10 + tempArrayUrl.length + tempArrayUser.length + tempArrayPassword.length);
        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the MATRIX Netdrive status.
     *
     * @param data: netdrive index
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMatrixNetdriveStatus: function getMatrixNetdriveStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_NETDRIVE_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * set matrix control token
     *
     * @param data.controlToken: true or false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixControlToken: function setMatrixControlToken(data, socket) {
        var txData = new Uint8Array(12);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_STATION_MASTER_CONTROL_TOKEN;
        var controlToken = parseInt(data.controlToken, 16);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x08;
        txData[4] = Math.floor(controlToken / Math.pow(2, 56)) % 256;
        txData[5] = Math.floor(controlToken / Math.pow(2, 48)) % 256;
        txData[6] = Math.floor(controlToken / Math.pow(2, 40)) % 256;
        txData[7] = Math.floor(controlToken / Math.pow(2, 32)) % 256;
        txData[8] = Math.floor(controlToken / Math.pow(2, 24)) % 256;
        txData[9] = Math.floor(controlToken / Math.pow(2, 16)) % 256;
        txData[10] = Math.floor(controlToken / Math.pow(2, 8)) % 256;
        txData[11] = controlToken % 256;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the Matrix Master control mode.
     *
     * @param data.serial
     * @param data.mod (on/off)
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setMatrixMasterControl: function setMatrixMasterControl(data, socket) {
        var txData = new Uint8Array(9);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_CONTROL_STATION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 5;

        txData[4] = data.mode;
        txData[5] = (data.serial >> 24) & 0xFF;
        txData[6] = (data.serial >> 16) & 0xFF;
        txData[7] = (data.serial >> 8) & 0xFF;
        txData[8] = data.serial & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get matrix groupwork mode (Matrix enable/disable)
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMatrixGroupworkMode: function getMatrixGroupworkMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_GROUPWORK_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets matrix groupwork mode (Matrix enable/disable)
     *
     * @param data.enabled: true/false (enabled/disabled)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixGroupworkMode: function setMatrixGroupworkMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_GROUPWORK_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enabled === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get matrix groupwork forced status (Groupwork on-the-fly enable/disable)
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMatrixGroupworkForced: function getMatrixGroupworkForced(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_GROUPWORK_FORCED;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets matrix groupwork forced status (Groupwork on-the-fly enable/disable)
     *
     * @param data.enabled: true/false (enabled/disabled)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixGroupworkForced: function setMatrixGroupworkForced(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_GROUPWORK_FORCED;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.active === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get Matrix station groupwork status. (Is groupwork active on station / is Master offline).
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMatrixStationGroupworkStatus: function getMatrixStationGroupworkStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_STATION_GROUPWORK_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get matrix groupwork status (info about all stations).
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMatrixGroupworkStationsStatus: function getMatrixGroupworkStationsStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_GROUPWORK_STATIONS_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Matrix groupwork mode: Start or abort pull request to other station.
     *
     * @param data.request true = start request, else stop/abort request
     * @param data.serial Send request to station with serial number
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setMatrixGroupworkRequestPull: function setMatrixGroupworkRequestPull(data, socket) {
        var txData = new Uint8Array(9);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_GROUPWORK_REQ_STATION_PULL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x05;
        txData[4] = data.request === true ?
            wolfProtParameters.WPP_CB_MATRIX_GROUPWORK_REQ_STATION_PULL_START :
            wolfProtParameters.WPP_CB_MATRIX_GROUPWORK_REQ_STATION_PULL_STOP;
        txData[5] = (data.serial >> 24) & 0xFF;
        txData[6] = (data.serial >> 16) & 0xFF;
        txData[7] = (data.serial >> 8) & 0xFF;
        txData[8] = data.serial & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Matrix groupwork mode: Accept or reject pull request from station.
     *
     * @param data.accept true = accept, else reject pull request
     * @param data.serial Receiving request from station with serial number
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setMatrixGroupworkRequestPullAnswer: function setMatrixGroupworkRequestPullAnswer(data, socket) {
        var txData = new Uint8Array(9);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_GROUPWORK_REQ_STATION_PULL_ANSWER;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x05;
        txData[4] = data.accept === true ?
            wolfProtParameters.WPP_CB_MATRIX_GROUPWORK_REQ_STATION_PULL_ANSWER_ACCEPT :
            wolfProtParameters.WPP_CB_MATRIX_GROUPWORK_REQ_STATION_PULL_ANSWER_REJECT;
        txData[5] = (data.serial >> 24) & 0xFF;
        txData[6] = (data.serial >> 16) & 0xFF;
        txData[7] = (data.serial >> 8) & 0xFF;
        txData[8] = data.serial & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Matrix groupwork mode: Stop pushing stream to other station
     *
     * @param data.serial Station with serial which is pulling stream.
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setMatrixGroupworkSendPullStop: function setMatrixGroupworkSendPullStop(data, socket) {
        var txData = new Uint8Array(8);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_GROUPWORK_REQ_STATION_SEND_PULL_STOP;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (data.serial >> 24) & 0xFF;
        txData[5] = (data.serial >> 16) & 0xFF;
        txData[6] = (data.serial >> 8) & 0xFF;
        txData[7] = data.serial & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Matrix groupwork mode: Stop receiving stream from other station.
     *
     * @param data.serial Station with serial which is pulling stream.
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setMatrixGroupworkReceivePullStop: function setMatrixGroupworkReceivePullStop(data, socket) {
        var txData = new Uint8Array(8);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_GROUPWORK_REQ_STATION_RECEIVE_PULL_STOP;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (data.serial >> 24) & 0xFF;
        txData[5] = (data.serial >> 16) & 0xFF;
        txData[6] = (data.serial >> 8) & 0xFF;
        txData[7] = data.serial & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the Matrix Master lock mode.
     *
     * @param data.serial
     * @param data.mode
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setMatrixMasterLock: function setMatrixMasterLock(data, socket) {
        var txData = new Uint8Array(9);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_LOCK_STATION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 5;

        txData[4] = data.mode;
        txData[5] = (data.serial >> 24) & 0xFF;
        txData[6] = (data.serial >> 16) & 0xFF;
        txData[7] = (data.serial >> 8) & 0xFF;
        txData[8] = data.serial & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Returns Matrix Master Limited access.
     *
     * @param data: unused
     * @param socket: the websocket connection
     *
     * @returns {{promise: *, cmd: number}}
     */
    getMatrixMasterLimitedAccess: function getMatrixMasterLimitedAccess(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_LIMITED_ACCESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set Matrix Master limited access.
     *
     * @param data: enabled
     * @param socket: the websocket connection
     *
     * @returns {{promise: *, cmd: number}}
     */
    setMatrixMasterLimitedAccess: function setMatrixMasterLimitedAccess(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_LIMITED_ACCESS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 1;
        txData[4] = data.enabled;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the keyboard layout.
     *
     * @method: setSystemLanguage
     * @param data.language: en, de, ja, fr, zh
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setKeyboardLayout: function setKeyboardLayout(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_KEYBOARD_LAYOUT;

        var mapping = {
            'de': wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_DE,
            'us': wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_US,
            'cn': wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_CN,
            'fr': wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_FR,
            'jp': wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_JP,
            'no': wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_NO,
            'sv': wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_SV,
            'it': wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_IT,
            'ch': wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_CH,
            'uk': wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_UK,
            'fr-ca': wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_FR_CA
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.layout];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get power down mode (suspend / shutdown)
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getPowerDownMode: function getPowerDownMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_POWER_DOWN_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Returns the layout from the Keyboard.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getKeyboardLayout: function getKeybaordLayout(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_KEYBOARD_LAYOUT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set power down mode (suspend / shutdown)
     *
     * @param data.enabled: true/false (enabled/disabled)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setPowerDownMode: function setPowerDownMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_POWER_DOWN_MODE;

        var mapping = {
            'suspend': wolfProtParameters.WPP_CB_POWER_DOWN_SUSPEND,
            'shutdown': wolfProtParameters.WPP_CB_POWER_DOWN_SHUTDOWN,
            'power_saving': wolfProtParameters.WPP_CB_POWER_DOWN_POWER_SAVING
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.mode];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Enable file push mode to push files to all devices.
     *
     * @param data.mode: on/off
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setFilePushMode: function setFilePushMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_FILE_PUSH_MODE;

        var mapping = {
            'on': wolfProtParameters.WPP_CB_FILE_PUSH_MODE_ON,
            'off': wolfProtParameters.WPP_CB_FILE_PUSH_MODE_OFF
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.mode];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the vMeeting / Session Management Meeting List.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getVMeetingMeetingList: function getVMeetingMeetingList(data, socket) {
        var pinLength = data.pin.toString().length;
        var pin = data.pin.toString();
        var txData = !data.id ? new Uint8Array(4) : new Uint8Array(4 + 1 + data.id.length + 1 + pinLength);
        var cmd = wolfProtCommands.WPC_CB_VMEETING_SESSION_LIST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;

        if (!data.id) {
            txData[3] = 0x00;
        } else {
            txData[3] = 1 + data.id.length + 1 + pinLength;
            txData[4] = data.id.length;

            for(var x = 0; x < data.id.length; x++) {
                txData[5 + x] = data.id.charCodeAt(x);
            }

            txData[4 + data.id.length + 1] = pinLength;
            for(var y = 0; y < pinLength; y++)
            {
                txData[6+ data.id.length + y] = pin.charCodeAt(y);
            }
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Returns the count of all connected apps.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAppConnectionCounter: function getAppConnectionCounter(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_APP_CONNECTION_COUNTER;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Start a vMeeting / Session Management session.
     *
     * @param data.id: Session ID
     *        data.pin: Session PIN
     * @param socket: the websocket connection
     */
    setVMeetingStartSession: function setVMeetingStartSession(data, socket) {
        var id = data.id;
        var pin = data.pin;
        var length = 2 + id.length + pin.length;
        var txData = new Uint8Array(4 + length);
        var cmd = wolfProtCommands.WPC_CB_VMEETING_START_SESSION;
        var i = 0;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = length;
        txData[4] = pin.length;

        for (i = 0; i < pin.length; ++i) {
            txData[5 + i] = pin.charCodeAt(i);
        }

        txData[5 + pin.length] = id.length;

        for (i = 0; i < id.length; ++i) {
            txData[6 + pin.length + i] = id.charCodeAt(i);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the vMeeting / Session Management Meeting List.
     *
     * @param data: netdrive index
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVMeetingActiveSession: function getVMeetingActiveSession(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_VMEETING_START_SESSION;
        var txData = new Uint8Array(4);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Start a vMeeting / Session Management adhoc session.
     *
     * @param data.duration: in minutes
     */
    setVMeetingStartAdHocSession: function setVMeetingStartAdHocSession(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_VMEETING_START_SESSION;
        var txData = new Uint8Array(6);
        var duration = parseInt(data.duration);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = (duration >> 8) & 0xFF;
        txData[5] = duration & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Resend PIN from session.
     *
     * @param data.id: Session ID
     * @param socket: the websocket connection
     */
    setVMeetingResendPin: function setVMeetingResendPin(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_VMEETING_RESEND_PIN;
        var length = data.id.length;
        var txData = new Uint8Array(5 + length);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = 1 + length;
        txData[4] = length;

        for (var x = 0; x < length; x++) {
            txData[5 + x] = data.id.charCodeAt(x);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get vMeeting Resource.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getVMeetingResource: function getVMeetingResource(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_VMEETING_RESOURCE;
        var txData = new Uint8Array(4);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set vMeeting Resource.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setVMeetingResource: function setVMeetingResource(data, socket) {
        var len = data.resource.length;
        var cmd = wolfProtCommands.WPC_CB_VMEETING_RESOURCE;
        var txData = new Uint8Array(4  + len);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = len;

        for (var x = 0; x < len; x++) {
            txData[4+x] = data.resource.charCodeAt(x);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get vMeeting OAuth2 Tenant ID.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getVMeetingOAuthTenantId: function getVMeetingOAuthTenantId(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_VMEETING_OAUTH_TENANT_ID;
        var txData = new Uint8Array(4);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set vMeeting OAuth Tenant ID.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setVMeetingOAuthTenantId: function setVMeetingOAuthTenantId(data, socket) {
        var len = data.id.length;
        var cmd = wolfProtCommands.WPC_CB_VMEETING_OAUTH_TENANT_ID;
        var txData = new Uint8Array(4 + len);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = len;

        for (var x = 0; x < len; x++) {
            txData[4+x] = data.id.charCodeAt(x);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get vMeeting OAuth2 secret.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getVMeetingOAuthSecret: function getVMeetingOAuthSecret(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_VMEETING_OAUTH_SECRET;
        var txData = new Uint8Array(4);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set vMeeting OAuth2 secret.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setVMeetingOAuthSecret: function setVMeetingOAuthSecret(data, socket) {
        var len = data.secret.length;
        var cmd = wolfProtCommands.WPC_CB_VMEETING_OAUTH_SECRET;
        var txData = new Uint8Array(4 + len);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = len;

        for (var x = 0; x < len; x++)
        {
            txData[4+x] = data.secret.charCodeAt(x);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get vMeeting Oauth app ID.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getVMeetingOAuthAppId: function getVMeetingOAuthAppId(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_VMEETING_OAUTH_APP_ID;
        var txData = new Uint8Array(4);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set vMeeting OAuth App ID.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setVMeetingOAuthAppId: function setVMeetingOAuthAppId(data, socket) {
        var len = data.id.length;
        var cmd = wolfProtCommands.WPC_CB_VMEETING_OAUTH_APP_ID;
        var txData = new Uint8Array(4 + len);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = len;

        for(var x = 0; x < len; x++) {
            txData[4+x] = data.id.charCodeAt(x);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * vMeeting OAuth login/logout.
     * @param data.login: true/false (login/logout)
     * @param socket
     * @return {{promise, cmd: number}}
     */
    setVMeetingOAuth: function setVMeetingOAuth(data, socket) {
        var txData = new Uint8Array(4 + 1);
        var cmd = wolfProtCommands.WPC_CB_VMEETING_OAUTH;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.login ?
            wolfProtParameters.WPP_CB_VMEETING_OAUTH_LOGIN :
            wolfProtParameters.WPP_CB_VMEETING_OAUTH_LOGOUT;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Returns the time left until the end notification is shown.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getVMeetingDataHoldTimeout: function getVMeetingDataHoldTimeout(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_VMEETING_CLEANUP_TIME;
        var txData = new Uint8Array(4);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the time left until the end notification is shown.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setVMeetingDataHoldTimeout: function setVMeetingDataHoldTimeout(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_VMEETING_CLEANUP_TIME;
        var txData = new Uint8Array(5);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.minutes;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get vMeeting deadline (status)
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getVMeetingDeadlineStatus: function getVMeetingDeadlineStatus(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_VMEETING_DEADLINE;
        var txData = new Uint8Array(4);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set vMeeting deadline enable or disable/ignore.
     *
     * @param data.activate 0x00 (enable - currently not used), 0x01 (disable/ignore)
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setVMeetingDeadlineEnable: function setVMeetingDeadlineEnable(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_VMEETING_DEADLINE;
        var txData = new Uint8Array(5);
        var enabled = data.enabled ?
            wolfProtParameters.WPP_CB_VMEETING_DEADLINE_ENABLE :
            wolfProtParameters.WPP_CB_VMEETING_DEADLINE_DISABLE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = enabled;


        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Returns the vMeeting connection status.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: *}}
     */
    getVMeetingConnectionStatus: function getVMeetingConnectionStatus(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_VMEETING_CONNECTION_STATUS;
        var txData = new Uint8Array(4);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * extend vMeeting session.
     *
     * @param data.minutes 0 - 65535 min
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setVMeetingExtendSession: function setVMeetingExtendSession(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_VMEETING_EXTEND_SESSION;
        var txData = new Uint8Array(6);
        var minutes = data.minutes ? parseInt(data.minutes) : 0;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = (minutes >> 8) & 0xFF;
        txData[5] = minutes & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get vMeeting function (enable/disable)
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVMeetingFunction: function getVMeetingFunction(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_VMEETING_FUNCTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets vMeeting function (enable/disable)
     *
     * @param data.enabled: true/false (enabled/disabled)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVMeetingFunction: function setVMeetingFunction(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_VMEETING_FUNCTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enabled === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get vMeeting mode
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVMeetingMode: function getVMeetingMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_VMEETING_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets vMeeting mode
     *
     * @param: data.mode: WPP_CB_VMEETING_MODE_MS365
     *                    WPP_CB_VMEETING_MODE_MS365_BASIC
     *                    WPP_CB_VMEETING_MODE_GOOGLE_CAL
     *                    WPP_CB_VMEETING_MODE_GOOGLE_CAL_BASIC
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVMeetingMode: function setVMeetingMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_VMEETING_MODE;
        var mapping = {
            [states.vMeetingModes.ms365]: wolfProtParameters.WPP_CB_VMEETING_MODE_MS365,
            [states.vMeetingModes.ms365Basic]: wolfProtParameters.WPP_CB_VMEETING_MODE_MS365_BASIC,
            [states.vMeetingModes.googleCal]: wolfProtParameters.WPP_CB_VMEETING_MODE_GOOGLE_CAL,
            [states.vMeetingModes.googleCalBasic]: wolfProtParameters.WPP_CB_VMEETING_MODE_GOOGLE_CAL_BASIC
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.mode];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests if vMeeting PIN is enabled/disabled
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVMeetingPin: function getVMeetingPin(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_VMEETING_PIN;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Enables/disables vMeeting PIN
     *
     * @param data: data.enabled (true or false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVMeetingPin: function setVMeetingPin(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_VMEETING_PIN;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enabled === true ? wolfProtCodes.parameters.WPP_ON : wolfProtCodes.parameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get vMeeting unbooked duration (time until the next meeting starts).
     *
     * Active meeting: get time between current meeting end time and next meeting
     * NO active meeting: get time between NOW and next meeting
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     *
     */
    getVMeetingUnbookedDuration: function getVMeetingUnbookedDuration(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_VMEETING_UNBOOKED_DURATION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * sets the wifi direct channel
     *
     * @param: data.wifiChannel: WPP_WLAN_DIRECT_CHANNEL_AUTO
     *                           WPP_WLAN_DIRECT_CHANNEL_1
     *                           WPP_WLAN_DIRECT_CHANNEL_6
     *                           WPP_WLAN_DIRECT_CHANNEL_11
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanDirectChannel: function setWlanChannel(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WLAN_AP_DIRECT_CHANNEL;
        var mapping = {
            'auto': wolfProtParameters.WPP_WLAN_DIRECT_CHANNEL_AUTO,
            '1': wolfProtParameters.WPP_WLAN_DIRECT_CHANNEL_1,
            '6': wolfProtParameters.WPP_WLAN_DIRECT_CHANNEL_6,
            '11': wolfProtParameters.WPP_WLAN_DIRECT_CHANNEL_11
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.channel];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the wifi direct channel
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanDirectChannel: function getWlanChannel(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_AP_DIRECT_CHANNEL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the matrix master streaming resolution
     *
     * @param data.streamingResolution: the streaming resolution (e.g.: WPP_RESOLUTION_1080P,
     *                                                                  WPP_RESOLUTION_720P,
     *                                                                  WPP_RESOLUTION_540P,
     *                                                                  WPP_RESOLUTION_360P)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixMasterStreamingResolution: function setMatrixMasterStreamingResolution(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_STREAMING_RESOLUTION;
        var mapping = {
            '360p': wolfProtParameters.WPP_CB_RESOLUTION_360P,
            '540p': wolfProtParameters.WPP_CB_RESOLUTION_540P,
            '720p': wolfProtParameters.WPP_CB_RESOLUTION_720P,
            '1080p': wolfProtParameters.WPP_CB_RESOLUTION_1080P
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.resolution];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Gets the matrix master streaming resolution
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMatrixMasterStreamingResolution: function getMatrixMasterStreamingResolution(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_STREAMING_RESOLUTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the matrix master streaming framerate
     *
     * @param data.streamingFramerate: the streaming framerate (e.g.: WPP_FRAMERATE_LOW,
     *                                                                WPP_FRAMERATE_MEDIUM,
     *                                                                WPP_FRAMERATE_HIGH)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixMasterStreamingFramerate: function setMatrixMasterStreamingFramerate(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_STREAMING_FRAMERATE;
        var mapping = {
            'low': wolfProtParameters.WPP_FRAMERATE_LOW,
            'medium': wolfProtParameters.WPP_FRAMERATE_MEDIUM,
            'high': wolfProtParameters.WPP_FRAMERATE_HIGH
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.framerate];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Gets the matrix master streaming framerate
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMatrixMasterStreamingFramerate: function getMatrixMasterStreamingFramerate(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_STREAMING_FRAMERATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };

    },

    /**
     * Sets the matrix master streaming bitrate mode and bitrate
     *
     * @param data.mode: bitrate mode constant/variable
     * @param data.bitrate: bitrate in kbit
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixMasterStreamingBitrate: function setMatrixMasterStreamingBitrate(data, socket) {
        var txData = new Uint8Array(7);
        var tempBitrate = parseInt(data.bitrate);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_STREAMING_BITRATE;
        var modeMapping = {
            'constant': wolfProtParameters.WPP_STREAMING_BITRATE_MODE_CONSTANT,
            'variable': wolfProtParameters.WPP_STREAMING_BITRATE_MODE_VARIABLE
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x03;
        txData[4] = modeMapping[data.mode];
        txData[5] = (tempBitrate >> 8) & 0xFF;
        txData[6] = tempBitrate & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Gets the matrix master streaming bitrate mode and bitrate
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMatrixMasterStreamingBitrate: function getMatrixMasterStreamingBitrate(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_STREAMING_BITRATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     *
     * Sets the matrix master multicast ip address
     *
     * @param data.ipAddress: the multicast ip address as a string (e.g. '225.0.0.1')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixMasterStreamingMulticastIp: function setMatrixMasterStreamingMulticastIp(data, socket) {
        var txData = new Uint8Array(8);
        var temp = ipv4AddressString2Int(data.ipAddress);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_STREAMING_MC_IP_BASE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (temp >> 24) & 0xFF;
        txData[5] = (temp >> 16) & 0xFF;
        txData[6] = (temp >> 8) & 0xFF;
        txData[7] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the matrix master multicast ip address.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMatrixMasterStreamingMulticastIp: function getMatrixMasterStreamingMulticastIp(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_STREAMING_MC_IP_BASE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the matrix master streaming multicast port.
     *
     * @param data.streamPort: the port as a string (e.g. '8080')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixMasterMulticastStreamingPort: function setMatrixMasterMulticastStreamingPort(data, socket) {
        var txData = new Uint8Array(6);
        var temp = parseInt(data.port);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_STREAMING_MC_PORT_BASE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = (temp >> 8) & 0xFF;
        txData[5] = temp & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set log level.
     *
     * @param data.id: feature id
     * @param data.level: level
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setDebugLevel: function setDebugLevel(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = wolfProtCommands.WPC_CB_DEBUG_LEVEL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = data.id;
        txData[5] = data.level;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * returns the matrix master multicast streaming port
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMatrixMasterMulticastStreamingPort: function getMatrixMasterMulticastStreamingPort(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_STREAMING_MC_PORT_BASE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the matrix master multicast streaming time to live
     *
     * @param: data.ttl
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMatrixMasterStreamingTtl: function setMatrixMasterStreamingTtl(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_STREAMING_MC_TTL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.ttl;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the matrix master streaming time to live
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMatrixMasterStreamingTtl: function getMatrixMasterStreamingTtl(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MATRIX_MASTER_STREAMING_MC_TTL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the log level.
     *
     * @param data.id: id
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getDebugLevel: function getDebugLevel(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_DEBUG_LEVEL;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.id;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set the splash screen trigger mode.
     *
     * @param: data.mode TRUE (splash screen enabled and show on remote browser is true (FE settings)), else FALSE
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setSplashScreenTriggerMode: function setSplashScreenTriggerMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_SPLASH_SCREEN_TRIGGER_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.mode ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the splash screen trigger mode.
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getSplashScreenTriggerMode: function getSplashScreenTriggerMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_SPLASH_SCREEN_TRIGGER_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get wlan access point pure routing mode
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getRoutingMode: function getRoutingMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ROUTING_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set splash screen trigger.
     *
     * @param data.active TRUE (splash screen is triggered and should be shown), else FALSE
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setSplashScreenTrigger: function setSplashScreenTrigger(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_SPLASH_SCREEN_TRIGGER;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Request the splash screen status (active/inactive)
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getSplashScreenStatus: function getSplashScreenStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_SPLASH_SCREEN_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set wlan access point pure routing mode.
     *
     * @param data.activate 0x00 (enable - currently not used), 0x01 (disable/ignore)
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setRoutingMode: function setRoutingMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ROUTING_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = cmd >> 8;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enabled;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set the Annotation Magic Pen paint
     *
     * @param data.painting: continue or finished
     * @param data.positionX: position x
     * @param data.positionY: position y
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAnnotationMagicPenPaint: function setAnnotationMagicPenPaint(data, socket) {
        var txData = new Uint8Array(9);
        var cmd = wolfProtCommands.WPC_CB_ANNOTATION_MAGIC_PEN_PAINT;
        var mapping = {
            'finished': wolfProtParameters.WPP_CB_ANNOTATION_PAINT_FINISHED,
            'continued': wolfProtParameters.WPP_CB_ANNOTATION_PAINT_CONTINUED
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 5;
        txData[4] = mapping[data.painting];
        txData[5] = (data.positionX >> 8) & 0xFF;
        txData[6] = data.positionX & 0xFF;
        txData[7] = (data.positionY >> 8) & 0xFF;
        txData[8] = data.positionY & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set properties for Annotation Magic Pen.
     *
     * @param data.red: 0 - 255
     * @param data.green: 0 - 255
     * @param data.blue: 0 - 255
     * @param data.alpha: pen opacity
     * @param data.penSize: pen strength
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAnnotationMagicPenProperties: function setAnnotationMagicPenProperties(data, socket) {
        var txData = new Uint8Array(9);
        var cmd = wolfProtCommands.WPC_CB_ANNOTATION_MAGIC_PEN_PROPERTIES;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 5;
        txData[4] = data.red;
        txData[5] = data.green;
        txData[6] = data.blue;
        txData[7] = data.alpha;
        txData[8] = data.penSize;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set settings for Annotation Magic Pen.
     *
     * @param data.visibleTime: 1 - 60 sec
     * @param data.remoteUserEnabled: true: allow using magic pen on remote, else false
     * @param data.enabled: true: magic pen function is enabled, else disabled
     * @param data.autoSnapshot: true: take snapshot after timeout, else false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAnnotationMagicPenSettings: function setAnnotationMagicPenSettings(data, socket) {
        var txData = new Uint8Array(8);
        var cmd = wolfProtCommands.WPC_CB_ANNOTATION_MAGIC_PEN_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 4;
        txData[4] = data.visibleTime;
        txData[5] = data.remoteUserEnabled ? wolfProtParameters.WPP_CB_ENABLED : wolfProtParameters.WPP_CB_DISABLED;
        txData[6] = data.enabled ? wolfProtParameters.WPP_CB_ENABLED : wolfProtParameters.WPP_CB_DISABLED;
        txData[7] = data.autoSnapshot ? wolfProtParameters.WPP_CB_ENABLED : wolfProtParameters.WPP_CB_DISABLED;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get settings for Annotation Magic Pen.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAnnotationMagicPenSettings: function getAnnotationMagicPenSettings(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ANNOTATION_MAGIC_PEN_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.USER),
            cmd: cmd
        };
    },

    /**
     * Get Annotation Magic Pen status.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAnnotationMagicPenStatus: function getAnnotationMagicPenStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ANNOTATION_MAGIC_PEN_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData, wolfProtUserLevels.COLLAB),
            cmd: cmd
        };
    },

    /**
     * Set timeout for Annotation Magic Pen (enable/disable).
     *
     * @param data.visibleTime: 1 - 60 sec
     * @param data.remoteUserEnabled: true: allow using magic pen on remote, else false
     * @param data.enabled: true: magic pen function is enabled, else disabled
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAnnotationMagicPenTimeout: function setAnnotationMagicPenTimeout(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ANNOTATION_MAGIC_PEN_TIMEOUT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enabled ? wolfProtParameters.WPP_CB_ENABLED : wolfProtParameters.WPP_CB_DISABLED;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set access point maximum clients.
     *
     * @param data.nrClients: 1-8 (Cynap PURE) || 1-15 (Cynap & Cynap Core)
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setWlanApMaxClients: function setWlanApMaxClients(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_AP_MAXIMUM_CLIENTS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.nrClients;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Request the number of the maximum clients in access point mode.
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanApMaxClients: function getWlanApMaxClients(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_AP_MAXIMUM_CLIENTS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set screensaver off.
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setScreensaverOff: function setScreensaverOff(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_SCREENSAVER_OFF;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get Zoom settings.
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getZoomSettings: function getZoomSettings(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ZOOM_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set ZOOM settings.
     *
     * @param data.enabled: Enable Zoom
     * @param data.enableContent: true/false; Enable content for ZOOM recording/streaming
     * @param data.enableApp: Enable vApp control
     * @param data.enableLocalMeeting: Enable local Zoom account
     * @param data.enableJoinMeeting: Enable join Zoom meeting
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setZoomSettings: function setZoomSettings(data, socket) {
        var txData = new Uint8Array(9);
        var cmd = wolfProtCommands.WPC_CB_ZOOM_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x05;
        txData[4] = data.enabled === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;
        txData[5] = data.enableContent ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;
        txData[6] = data.enableApp ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;
        txData[7] = data.enableLocalMeeting ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;
        txData[8] = data.enableJoinMeeting ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get zoom local account infos.
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getZoomLocalAccountInfo: function getZoomLocalAccountInfo(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ZOOM_LOCAL_ACCOUNT_INFO;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get zoom local account status (disconnected, connected or logged out).
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getZoomLocalAccountStatus: function getZoomLocalAccountStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ZOOM_LOCAL_ACCOUNT_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Login / logout from zoom local account.
     *
     * @param data.login: true (login)/false (logout)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setZoomLocalAccountLogin: function setZoomLocalAccountLogin(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ZOOM_LOCAL_ACCOUNT_LOGIN;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.login ?
            wolfProtParameters.WPP_CB_ZOOM_LOCAL_ACCOUNT_LOGIN :
            wolfProtParameters.WPP_CB_ZOOM_LOCAL_ACCOUNT_LOGOUT;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get the webconference screenshare status.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWebconferenceScreenShareStatus: function getWebconferenceScreenShareStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WEBCONF_SCREENSHARE_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set webconference screen share start.
     *
     * @param data.done true/false (false === cancel)
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setWebconferenceScreenshareStart: function setWebconferenceScreenshareStart(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_WEBCONF_SCREENSHARE_START;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.done ? 0x00 : 0x01;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set the pip size.
     *
     * @param data: size
     * @param socket: the websocket connection
     */
    setPipSize: function setPipSize(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_PIP_SIZE;

        var mapping = {
            '360p': wolfProtParameters.WPP_CB_PIP_SIZE_360P
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.size];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the pip size.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getPipSize: function getPipSize(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PIP_SIZE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set the pip position.
     *
     * @param data: position
     * @param socket: the websocket connection
     */
    setPipPosition: function setPipPosition(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_PIP_POSITION;

        var mapping = {
            'leftBottom': wolfProtParameters.WPP_CB_PIP_POSITION_LEFT_BOTTOM,
            'leftTop': wolfProtParameters.WPP_CB_PIP_POSITION_LEFT_TOP,
            'rightBottom': wolfProtParameters.WPP_CB_PIP_POSITION_RIGHT_BOTTOM,
            'rightTop': wolfProtParameters.WPP_CB_PIP_POSITION_RIGHT_TOP
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.position];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the pip position.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getPipPosition: function getPipPosition(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PIP_POSITION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set the pip function.
     *
     * @param data: enable
     * @param socket: the websocket connection
     */
    setPipFunction: function setPipFunction(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_PIP_FUNCTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enable;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the pip function.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getPipFunction: function getPipFunction(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PIP_FUNCTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set PIP camera latency in ms.
     *
     * @param data.latency in ms
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setPipCameraLatency: function setPipCameraLatency(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_PIP_LATENCY;
        var txData = new Uint8Array(6);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = (data.latency >> 8) & 0xFF;
        txData[5] = data.latency & 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the PIP camera latency in ms.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getPipCameraLatency: function getPipCameraLatency(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PIP_LATENCY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set PIP content.
     *
     * @param data.content
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setPipContent: function setPipContent(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_PIP_CONTENT;
        var txData = new Uint8Array(5);

        var mapping = {
            'camera': wolfProtParameters.WPP_CB_PIP_CONTENT_CAMERA,
            'main': wolfProtParameters.WPP_CB_PIP_CONTENT_MAIN
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.content];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the PIP content.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getPipContent: function getPipContent(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PIP_CONTENT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the routing entry configuration.
     *
     * @param data.index - item index
     * @param data.enabled - item enable/disable
     * @param data.routingIp - routing item ip
     * @param data.prefix - item prefix
     * @param data.gatewayEnabled - item gateway enable/disable
     * @param data.gateway - item gateway ip
     * @param data.device - item device interface (lan1/2, wlan)
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setRoutingEntry: function setRoutingEntry(data, socket) {
        var length = 13;
        var txData = new Uint8Array(4 + length);
        var cmd = wolfProtCommands.WPC_CB_ROUTING_TABLE;
        var ip = ipv4AddressString2Int(data.routingIp);
        var gateway = ipv4AddressString2Int(data.gateway);

        var mapping = {
            'lan1': wolfProtParameters.WPP_CB_ROUTING_DEVICE_LAN1,
            'lan2': wolfProtParameters.WPP_CB_ROUTING_DEVICE_LAN2,
            'wlan': wolfProtParameters.WPP_CB_ROUTING_DEVICE_WLAN
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = length;
        txData[4] = data.index;
        txData[5] = data.enabled;
        txData[6] = (ip >> 24) & 0xFF;
        txData[7] = (ip >> 16) & 0xFF;
        txData[8] = (ip >> 8) & 0xFF;
        txData[9] = ip & 0xFF;
        txData[10] = data.prefix;
        txData[11] = data.gatewayEnabled;
        txData[12] = (gateway >> 24) & 0xFF;
        txData[13] = (gateway >> 16) & 0xFF;
        txData[14] = (gateway >> 8) & 0xFF;
        txData[15] = gateway & 0xFF;
        txData[16] = mapping[data.device];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the routing entry configuration
     *
     * @param data: routing entry index
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getRoutingEntry: function getRoutingEntry(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_ROUTING_TABLE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.index;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set PIP stream input.
     *
     * @param data.index
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setPipStreamInput: function setPipStreamInput(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_PIP_STREAM_INPUT;
        var txData = new Uint8Array(5);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.index;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the PIP stream input.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getPipStreamInput: function getPipStreamInput(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PIP_STREAM_INPUT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the adjustment wlan region.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getAdjustmentWlanRegion: function getAdjustmentWlanRegion(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_ADJUSTMENT_WLAN_REGION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the adjustment wlan region.
     *
     * @param data.region
     * @param data.country
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAdjustmentWlanRegion: function setAdjustmentWlanRegion(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_ADJUSTMENT_WLAN_REGION_ONCE;
        var txData = new Uint8Array(6);

        var mapping = {
            'us': wolfProtParameters.WPP_CB_WLAN_COUNTRY_US,
            'ca': wolfProtParameters.WPP_CB_WLAN_COUNTRY_CA,
            'au': wolfProtParameters.WPP_CB_WLAN_COUNTRY_AU,
            'nz': wolfProtParameters.WPP_CB_WLAN_COUNTRY_NZ,
            'my': wolfProtParameters.WPP_CB_WLAN_COUNTRY_MY,
            'at': wolfProtParameters.WPP_CB_WLAN_COUNTRY_AT,
            'be': wolfProtParameters.WPP_CB_WLAN_COUNTRY_BE,
            'bg': wolfProtParameters.WPP_CB_WLAN_COUNTRY_BG,
            'cy': wolfProtParameters.WPP_CB_WLAN_COUNTRY_CY,
            'cz': wolfProtParameters.WPP_CB_WLAN_COUNTRY_CZ,
            'de': wolfProtParameters.WPP_CB_WLAN_COUNTRY_DE,
            'dk': wolfProtParameters.WPP_CB_WLAN_COUNTRY_DK,
            'ee': wolfProtParameters.WPP_CB_WLAN_COUNTRY_EE,
            'es': wolfProtParameters.WPP_CB_WLAN_COUNTRY_ES,
            'fi': wolfProtParameters.WPP_CB_WLAN_COUNTRY_FI,
            'fr': wolfProtParameters.WPP_CB_WLAN_COUNTRY_FR,
            'gb': wolfProtParameters.WPP_CB_WLAN_COUNTRY_GB,
            'gr': wolfProtParameters.WPP_CB_WLAN_COUNTRY_GR,
            'hr': wolfProtParameters.WPP_CB_WLAN_COUNTRY_HR,
            'hu': wolfProtParameters.WPP_CB_WLAN_COUNTRY_HU,
            'ie': wolfProtParameters.WPP_CB_WLAN_COUNTRY_IE,
            'it': wolfProtParameters.WPP_CB_WLAN_COUNTRY_IT,
            'lt': wolfProtParameters.WPP_CB_WLAN_COUNTRY_LT,
            'lu': wolfProtParameters.WPP_CB_WLAN_COUNTRY_LU,
            'lv': wolfProtParameters.WPP_CB_WLAN_COUNTRY_LV,
            'mt': wolfProtParameters.WPP_CB_WLAN_COUNTRY_MT,
            'nl': wolfProtParameters.WPP_CB_WLAN_COUNTRY_NL,
            'pl': wolfProtParameters.WPP_CB_WLAN_COUNTRY_PL,
            'pt': wolfProtParameters.WPP_CB_WLAN_COUNTRY_PT,
            'ro': wolfProtParameters.WPP_CB_WLAN_COUNTRY_RO,
            'se': wolfProtParameters.WPP_CB_WLAN_COUNTRY_SE,
            'si': wolfProtParameters.WPP_CB_WLAN_COUNTRY_SI,
            'sk': wolfProtParameters.WPP_CB_WLAN_COUNTRY_SK,
            'ad': wolfProtParameters.WPP_CB_WLAN_COUNTRY_AD,
            'ba': wolfProtParameters.WPP_CB_WLAN_COUNTRY_BA,
            'ch': wolfProtParameters.WPP_CB_WLAN_COUNTRY_CH,
            'li': wolfProtParameters.WPP_CB_WLAN_COUNTRY_LI,
            'mc': wolfProtParameters.WPP_CB_WLAN_COUNTRY_MC,
            'me': wolfProtParameters.WPP_CB_WLAN_COUNTRY_ME,
            'rs': wolfProtParameters.WPP_CB_WLAN_COUNTRY_RS,
            'no': wolfProtParameters.WPP_CB_WLAN_COUNTRY_NO,
            'jp': wolfProtParameters.WPP_CB_WLAN_COUNTRY_JP,
            'ru': wolfProtParameters.WPP_CB_WLAN_COUNTRY_RU,
            'kw': wolfProtParameters.WPP_CB_WLAN_COUNTRY_KW,
            'om': wolfProtParameters.WPP_CB_WLAN_COUNTRY_OM,
            'sa': wolfProtParameters.WPP_CB_WLAN_COUNTRY_SA,
            'qa': wolfProtParameters.WPP_CB_WLAN_COUNTRY_QA,
            'cn': wolfProtParameters.WPP_CB_WLAN_COUNTRY_CN,
            'hk': wolfProtParameters.WPP_CB_WLAN_COUNTRY_HK,
            'sg': wolfProtParameters.WPP_CB_WLAN_COUNTRY_SG,
            'in': wolfProtParameters.WPP_CB_WLAN_COUNTRY_IN,
            'th': wolfProtParameters.WPP_CB_WLAN_COUNTRY_TH,
            'ae': wolfProtParameters.WPP_CB_WLAN_COUNTRY_AE
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = data.region;
        txData[5] = mapping[data.country];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set wlan country.
     *
     * @param data.content
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setWlanCountry: function setWlanCountry(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_WLAN_AP_COUNTRY;
        var txData = new Uint8Array(5);

        var mapping = {
            'us': wolfProtParameters.WPP_CB_WLAN_COUNTRY_US,
            'ca': wolfProtParameters.WPP_CB_WLAN_COUNTRY_CA,
            'au': wolfProtParameters.WPP_CB_WLAN_COUNTRY_AU,
            'nz': wolfProtParameters.WPP_CB_WLAN_COUNTRY_NZ,
            'my': wolfProtParameters.WPP_CB_WLAN_COUNTRY_MY,
            'at': wolfProtParameters.WPP_CB_WLAN_COUNTRY_AT,
            'be': wolfProtParameters.WPP_CB_WLAN_COUNTRY_BE,
            'bg': wolfProtParameters.WPP_CB_WLAN_COUNTRY_BG,
            'cy': wolfProtParameters.WPP_CB_WLAN_COUNTRY_CY,
            'cz': wolfProtParameters.WPP_CB_WLAN_COUNTRY_CZ,
            'de': wolfProtParameters.WPP_CB_WLAN_COUNTRY_DE,
            'dk': wolfProtParameters.WPP_CB_WLAN_COUNTRY_DK,
            'ee': wolfProtParameters.WPP_CB_WLAN_COUNTRY_EE,
            'es': wolfProtParameters.WPP_CB_WLAN_COUNTRY_ES,
            'fi': wolfProtParameters.WPP_CB_WLAN_COUNTRY_FI,
            'fr': wolfProtParameters.WPP_CB_WLAN_COUNTRY_FR,
            'gb': wolfProtParameters.WPP_CB_WLAN_COUNTRY_GB,
            'gr': wolfProtParameters.WPP_CB_WLAN_COUNTRY_GR,
            'hr': wolfProtParameters.WPP_CB_WLAN_COUNTRY_HR,
            'hu': wolfProtParameters.WPP_CB_WLAN_COUNTRY_HU,
            'ie': wolfProtParameters.WPP_CB_WLAN_COUNTRY_IE,
            'it': wolfProtParameters.WPP_CB_WLAN_COUNTRY_IT,
            'lt': wolfProtParameters.WPP_CB_WLAN_COUNTRY_LT,
            'lu': wolfProtParameters.WPP_CB_WLAN_COUNTRY_LU,
            'lv': wolfProtParameters.WPP_CB_WLAN_COUNTRY_LV,
            'mt': wolfProtParameters.WPP_CB_WLAN_COUNTRY_MT,
            'nl': wolfProtParameters.WPP_CB_WLAN_COUNTRY_NL,
            'pl': wolfProtParameters.WPP_CB_WLAN_COUNTRY_PL,
            'pt': wolfProtParameters.WPP_CB_WLAN_COUNTRY_PT,
            'ro': wolfProtParameters.WPP_CB_WLAN_COUNTRY_RO,
            'se': wolfProtParameters.WPP_CB_WLAN_COUNTRY_SE,
            'si': wolfProtParameters.WPP_CB_WLAN_COUNTRY_SI,
            'sk': wolfProtParameters.WPP_CB_WLAN_COUNTRY_SK,
            'ad': wolfProtParameters.WPP_CB_WLAN_COUNTRY_AD,
            'ba': wolfProtParameters.WPP_CB_WLAN_COUNTRY_BA,
            'ch': wolfProtParameters.WPP_CB_WLAN_COUNTRY_CH,
            'li': wolfProtParameters.WPP_CB_WLAN_COUNTRY_LI,
            'mc': wolfProtParameters.WPP_CB_WLAN_COUNTRY_MC,
            'me': wolfProtParameters.WPP_CB_WLAN_COUNTRY_ME,
            'rs': wolfProtParameters.WPP_CB_WLAN_COUNTRY_RS,
            'no': wolfProtParameters.WPP_CB_WLAN_COUNTRY_NO,
            'jp': wolfProtParameters.WPP_CB_WLAN_COUNTRY_JP,
            'ru': wolfProtParameters.WPP_CB_WLAN_COUNTRY_RU,
            'kw': wolfProtParameters.WPP_CB_WLAN_COUNTRY_KW,
            'om': wolfProtParameters.WPP_CB_WLAN_COUNTRY_OM,
            'sa': wolfProtParameters.WPP_CB_WLAN_COUNTRY_SA,
            'qa': wolfProtParameters.WPP_CB_WLAN_COUNTRY_QA,
            'cn': wolfProtParameters.WPP_CB_WLAN_COUNTRY_CN,
            'hk': wolfProtParameters.WPP_CB_WLAN_COUNTRY_HK,
            'sg': wolfProtParameters.WPP_CB_WLAN_COUNTRY_SG,
            'in': wolfProtParameters.WPP_CB_WLAN_COUNTRY_IN,
            'th': wolfProtParameters.WPP_CB_WLAN_COUNTRY_TH,
            'ae': wolfProtParameters.WPP_CB_WLAN_COUNTRY_AE
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.country];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the wlan country.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getWlanCountry: function getWlanCountry(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WLAN_AP_COUNTRY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get TLS 1.0 support.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getTls10Support: function getTls10Support(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_TLS_1_0_SUPPORT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set TLS 1.0 support.
     *
     * @param data.enabled true/false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setTls10Support: function setTls10Support(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_TLS_1_0_SUPPORT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enabled ? wolfProtParameters.WPP_CB_ENABLED : wolfProtParameters.WPP_CB_DISABLED;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests firewall for interface (LAN 1, LAN 2, WLAN).
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getFirewall: function getFirewall(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = wolfProtCommands.WPC_CB_FIREWALL;
        var mappingInterface = {
            'lan1': wolfProtParameters.WPP_CB_FIREWALL_INTERFACE_LAN1,
            'lan2': wolfProtParameters.WPP_CB_FIREWALL_INTERFACE_LAN2,
            'wlan': wolfProtParameters.WPP_CB_FIREWALL_INTERFACE_WLAN
        };
        var mappingService = {
            'http_https': wolfProtParameters.WPP_CB_FIREWALL_SERVICE_HTTP_HTTPS,
            'rtsp': wolfProtParameters.WPP_CB_FIREWALL_SERVICE_RTSP
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = mappingInterface[data.interface];
        txData[5] = mappingService[data.service];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set Firewall settings for interface.
     *
     * @param data.interface (LAN 1, LAN 2, WLAN)
     * @param data.service (http/https support, rtsp support)
     * @param data.accept true/false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setFirewall: function setFirewall(data, socket) {
        var txData = new Uint8Array(7);
        var cmd = wolfProtCommands.WPC_CB_FIREWALL;
        var mappingInterface = {
            'lan1': wolfProtParameters.WPP_CB_FIREWALL_INTERFACE_LAN1,
            'lan2': wolfProtParameters.WPP_CB_FIREWALL_INTERFACE_LAN2,
            'wlan': wolfProtParameters.WPP_CB_FIREWALL_INTERFACE_WLAN
        };
        var mappingService = {
            'http_https': wolfProtParameters.WPP_CB_FIREWALL_SERVICE_HTTP_HTTPS,
            'rtsp': wolfProtParameters.WPP_CB_FIREWALL_SERVICE_RTSP
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x03;
        txData[4] = mappingInterface[data.interface];
        txData[5] = mappingService[data.service];
        txData[6] = data.accept ? wolfProtParameters.WPP_CB_FIREWALL_SERVICE_ACCEPT : wolfProtParameters.WPP_CB_FIREWALL_SERVICE_DENY;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get Office365 Teams settings.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getOffice365TeamsSettings: function getOffice365TeamsSettings(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_OFFICE365_TEAMS_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets Office365 Teams Settings.
     *
     * @param data.sourceEnabled: true/false (show in source menu enabled/disabled)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setOffice365TeamsSettings: function setOffice365TeamsSettings(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = wolfProtCommands.WPC_CB_OFFICE365_TEAMS_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = data.sourceEnabled === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;
        txData[5] = data.enableContent === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get Screen Share Window visible.
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getScreenshareWindowVisible: function getScreenshareWindowVisible(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_SCREENSHARE_WINDOW_VISIBLE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get Maximum resolution from HDMI Input.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getHdmiInputMaxResolution: function getHdmiInputMaxResolution(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_HDMI_INPUT_MAX_RESOLUTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.input;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set Maximum resolution of HDMI Input.
     *
     * @param data.input: hdmi1 / hdmi2 input
     * @param data.resolution: 2160p (4K) / 1080p (FullHD)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setHdmiInputMaxResolution: function setHdmiInputMaxResolution(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = wolfProtCommands.WPC_CB_HDMI_INPUT_MAX_RESOLUTION;

        var mapping = {
            '2160p': wolfProtParameters.WPP_CB_HDMI_INPUT_MAX_RESOLUTION_2160P,
            '1080p': wolfProtParameters.WPP_CB_HDMI_INPUT_MAX_RESOLUTION_1080P
        };


        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = data.input;
        txData[5] = mapping[data.resolution];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set Eddystone Discovery.
     *
     * @param data.enabled: true/false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setEddystoneDiscovery: function setEddystoneDiscovery(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_EDDYSTONE_DISCOVERY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enabled ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get Eddystone Discovery.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEddystoneDiscovery: function getEddystoneDiscovery(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_EDDYSTONE_DISCOVERY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set mDNS Discovery.
     *
     * @param data.enabled: true/false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMDNSDiscovery: function setMDNSDiscovery(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MDNS_DISCOVERY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enabled ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get mDNS Discovery.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMDNSDiscovery: function getMDNSDiscovery(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MDNS_DISCOVERY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Sets the eddystone interface
     *
     * @param data.interface: (eg.  'lan1', 'lan2', 'wlan')
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setEddystoneInterface: function setEddystoneInterface(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_EDDYSTONE_INTERFACE;
        var mapping = {
            'lan1': wolfProtParameters.WPP_CB_EDDYSTONE_INTERFACE_LAN1,
            'lan2': wolfProtParameters.WPP_CB_EDDYSTONE_INTERFACE_LAN2,
            'wlan': wolfProtParameters.WPP_CB_EDDYSTONE_INTERFACE_WLAN
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.interface];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set help page.
     *
     * @param data.action: check/open
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setHelpPage: function setHelpPage(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_WWW_HELP_PAGE;
        var mapping = {
            'check': wolfProtParameters.WPP_WWW_HELP_PAGE_CHECK,
            'open': wolfProtParameters.WPP_WWW_HELP_PAGE_OPEN
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.action];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests the eddystone interface.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getEddystoneInterface: function getEddystoneInterface(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_EDDYSTONE_INTERFACE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get help page status.
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getHelpPageStatus: function getHelpPageStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_WWW_HELP_PAGE_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set directory list action.
     *
     * @param data.action: Reset / Update
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setPanoptoDirListAction: function setPanoptoDirListAction(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_DIRECTORY_LIST;
        var mapping = {
            'reset': wolfProtParameters.WPP_DIR_LIST_RESET,
            'update': wolfProtParameters.WPP_DIR_LIST_UPDATE
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.action];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set credentials for Panopto custom user login.
     *
     * @param data.username: Panopto username
     * @param data.password: Panopto password
     * @param socket: the websocket connection
     * @return {{promise, cmd: the command number}}
     */
    setPanoptoCustomUserCredentials: function setPanoptoCustomUserCredentials(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_PANOPTO_CUSTOM_USER_CREDENTIALS;
        var usernameArray = StringConvert.stringToUtf8(data.username) || new Uint8Array(0);
        var passwordArray = StringConvert.stringToUtf8(data.password) || new Uint8Array(0);

        var length = usernameArray.length + passwordArray.length + 2;
        var txData = new Uint8Array(4 + length);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;

        txData[3] = length;

        txData[4] = usernameArray.length;
        txData.set(usernameArray, 5);

        txData[5 + usernameArray.length] = passwordArray.length;
        txData.set(passwordArray, 6 + usernameArray.length);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get information if Panopto custom user login dialog should be displayed.
     *
     * @param socket: the websocket connection
     * @return {{promise, cmd: the command number}}
     */
    getPanoptoLoginDialog: function getPanoptoLoginDialog(data, socket){
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_PANOPTO_LOGIN_DIALOG;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set Panopto browser login
     *
     * @param socket: the websocket connection
     * @return {{promise, cmd: the command number}}
     */
    setPanoptoBrowserLogin: function setPanoptoBrowserLogin(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_PANOPTO_BROWSER_LOGIN;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.login ?
            wolfProtParameters.WPP_CB_PANOPTO_BROWSER_LOGIN :
            wolfProtParameters.WPP_CB_PANOPTO_BROWSER_LOGOUT;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get vLink Pro discovery settings.
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getVLinkProDiscoverySettings: function getVLinkProDiscoverySettings(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_VLINK_PRO_DISCOVERY_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set vLink Pro discovery settings.
     *
     * @param data.mode: OFF/static/DHCP
     * @param data.serverIp: hostname oder IP (if mode is static)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setVLinkProDiscoverySettings: function setVLinkProDiscoverySettings(data, socket) {
        var serverIp = data.mode === 'static' ? data.serverIp :  '';
        var txData = new Uint8Array(6 + serverIp.length);
        var cmd = wolfProtCommands.WPC_CB_VLINK_PRO_DISCOVERY_SETTINGS;
        var mapping = {
            'off': wolfProtParameters.WPP_CB_VLINK_PRO_DISCOVERY_OFF,
            'static': wolfProtParameters.WPP_CB_VLINK_PRO_DISCOVERY_STATIC,
            'dhcp': wolfProtParameters.WPP_CB_VLINK_PRO_DISCOVERY_DHCP
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 2 + serverIp.length;
        txData[4] = mapping[data.mode];
        txData[5] = serverIp.length;

        for (var i = 0; i < serverIp.length; ++i) {
            txData[i + 6] = serverIp.charCodeAt(i);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get management server subscribe status.
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getManagementServerSubscribeStatus: function getManagementServerSubscribeStatus(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MGMT_SUBSCRIBE_STATUS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set HDMI Input for WebRTC.
     *
     * @param data.hdmiInput 1 === HDMI1, 2 === HDMI2
     * @param data.enabled true/false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setHdmiInputForWebRtc: function setHdmiInputForWebRtc(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = wolfProtCommands.WPC_CB_HDMI_INPUT_WEBRTC;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = data.hdmiInput;
        txData[5] = (data.enabled === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get office 365 local account.
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getOffice365LocalAccount: function getOffice365LocalAccount(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_OFFICE365_LOCAL_ACCOUNT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set Office365 local account.
     *
     * @param data.enabled: office local account enaable/disable
     * @param data.user: user
     * @param data.pwd: password
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setOffice365LocalAccount: function setOffice365LocalAccount(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_OFFICE365_LOCAL_ACCOUNT;
        var userArray = StringConvert.stringToUtf8(data.user);
        var pwdArray = StringConvert.stringToUtf8(data.pwd);
        var length = userArray.length + pwdArray.length + 3;
        var txData = new Uint8Array(4 + length);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;

        txData[3] = length;

        txData[4] = data.enabled;

        txData[5] = userArray.length;
        txData.set(userArray, 6);

        txData[6 + userArray.length] = pwdArray.length;
        txData.set(pwdArray, 7 + userArray.length);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get HDMI Input for WebRTC.
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getHdmiInputForWebRtc: function getHdmiInputForWebRtc(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_HDMI_INPUT_WEBRTC;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.hdmiInput;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set office 365 credentials origin.
     *
     * @param data.origin (local or user)
     * @param socket
     * @returns {{promise: *, cmd: *}}
     */
    setOffice365CredentialsOrigin: function setOffice365CredentialsOrigin(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_OFFICE365_CREDENTIALS_ORIGIN;
        var mapping = {
            'local': wolfProtParameters.WPP_CB_OFFICE365_CREDENTIALS_LOCAL,
            'user': wolfProtParameters.WPP_CB_OFFICE365_CREDENTIALS_USER
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.origin];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get office 365 credentials origin.
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getOffice365CredentialsOrigin: function getOffice365CredentialsOrigin(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_OFFICE365_CREDENTIALS_ORIGIN;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get Webcast Youtube Live parameters.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getWebcastYoutubeParameters: function getWebcastYoutubeParameters(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_WEBCAST_YOUTUBE_PARAMETERS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     *
     * Set Webcast Youtube Live parameters.
     *
     * @param data.title (Stream title)
     * @param data.privacy (Stream privacy (private, public, unlisted)
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setWebcastYoutubeParameters: function setWebcastYoutubeParameters(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_WEBCAST_YOUTUBE_PARAMETERS;
        var titleArray = StringConvert.stringToUtf8(data.title);
        var length = titleArray.length + 2;
        var txData = new Uint8Array(4 + length);
        var mapping = {
            'private': wolfProtParameters.WPP_CB_WEBCAST_YOUTUBE_PRIVACY_PRIVATE,
            'public': wolfProtParameters.WPP_CB_WEBCAST_YOUTUBE_PRIVACY_PUBLIC,
            'unlisted': wolfProtParameters.WPP_CB_WEBCAST_YOUTUBE_PRIVACY_UNLISTED
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = length;
        txData[4] = mapping[data.privacy];

        txData[5] = titleArray.length;
        txData.set(titleArray, 6);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get Standby possible
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getStandbyPossible: function getStandbyPossible(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_STANDBY_POSSIBLE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set USB mic settings.
     *
     * @param data (enable, local, webconf, remote)
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setUsbMicSettings: function setUsbMicSettings(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_USB_MIC_SETTINGS;
        var txData = new Uint8Array(8);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x04;
        txData[4] = (data.enable === true) ? 0x01 : 0x00;
        txData[5] = (data.local === true) ? 0x01 : 0x00;
        txData[6] = (data.webconf === true) ? 0x01 : 0x00;
        txData[7] = (data.remote === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get usb mic settings.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getUsbMicSettings: function getUsbMicSettings(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_USB_MIC_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Activates the configured audio settings. Must be called after changing audio settings to take effect.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setAudioSettingsActivate: function setAudioSettingsActivate(data, socket) {
        const txData = new Uint8Array(5);
        const cmd = wolfProtCommands.WPC_CB_AUDIO_SETTINGS_ACTIVATE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get Reverse Shell settings.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getReverseShell: function getReverseShell(data, socket) {
        let cmd = wolfProtCommands.WPC_CB_REVERSE_SHELL;
        let txData = new Uint8Array(4);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set Reverse Shell settings.
     *
     * @param data.enable
     * @param data.port
     * @param data.webpageRedirectEnable
     * @param data.debugPortEnable
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setReverseShell: function setReverseShell(data, socket) {
        let cmd = wolfProtCommands.WPC_CB_REVERSE_SHELL;
        let txData = new Uint8Array(9);
        let port = parseInt(data.port);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x05;
        txData[4] = (data.enable === true) ? 0x01 : 0x00;
        txData[5] = (port >> 8) & 0xFF;
        txData[6] = port & 0xFF;
        txData[7] = (data.webpageRedirectEnable === true) ? 0x01 : 0x00;
        txData[8] = (data.debugPortEnable === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get Admin Message enable.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getAdminMessageEnable: function getAdminMessageEnable(data, socket) {
        let cmd = wolfProtCommands.WPC_CB_ADMIN_MESSAGE_ENABLE;
        let txData = new Uint8Array(4);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set Admin Message Enable.
     *
     * @param data.enable
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setAdminMessageEnable: function setAdminMessageEnable(data, socket) {
        let cmd = wolfProtCommands.WPC_CB_ADMIN_MESSAGE_ENABLE;
        let txData = new Uint8Array(5);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enable;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get Admin Message status.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getAdminMessageStatus: function getAdminMessageStatus(data, socket) {
        let cmd = wolfProtCommands.WPC_CB_ADMIN_MESSAGE_STATUS;
        let txData = new Uint8Array(4);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get presentation status.
     *
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getPresentationStatus: function getPresentationStatus(data, socket) {
        let cmd = wolfProtCommands.WPC_CB_PRESENTATION_STATUS;
        let txData = new Uint8Array(4);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the miracast 4k mode
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMiracast4kMode: function getMiracast4kMode(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MIRACAST_4K_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * enables / disables miracast 4k mode
     * @param data: data.enabled (true or false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMiracast4kMode: function setMiracast4kMode(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MIRACAST_4K_MODE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.enabled === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests setting for Miracast low latency default value
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getMiracastLowLatency: function getMiracastLowLatency(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_MIRACAST_LOW_LATENCY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Enables / disables setting for Miracast low latency default value
     * @param data: data.enabled (true or false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setMiracastLowLatency: function setMiracastLowLatency(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_MIRACAST_LOW_LATENCY;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.enabled === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests if audio is enabled on hdmi 1 input
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getHdmiInput1NoAudio: function getHdmiInput1NoAudio(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_HDMI_INPUT_1_NO_AUDIO;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * enables / disables hdmi input 1 audio
     * @param data: data.enabled (true or false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setHdmiInput1NoAudio: function setHdmiInput1NoAudio(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_HDMI_INPUT_1_NO_AUDIO;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.enabled === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests if HDCP support is enabled on HDMI IN on Cynap Core Pro
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getHdmiInput1HdcpSupport: function getHdmiInput1HdcpSupport(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_HDMI_INPUT_1_HDCP_SUPPORT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Enables / disables HDCP support for HDMI IN on Cynap Core Pro
     * @param data: data.enabled (true or false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setHdmiInput1HdcpSupport: function setHdmiInput1HdcpSupport(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_HDMI_INPUT_1_HDCP_SUPPORT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.enabled === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests connection id.
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getConnectionID: function getConnectionID(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_CONNECTION_ID;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * enables / disables connection id.
     * @param data: data.enabled (true or false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setConnectionIdEnable: function setConnectionIdEnable(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_CONNECTION_ID_ENABLE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.enabled === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the connection id enable.
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getConnectionIdEnable: function getConnectionIdEnable(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_CONNECTION_ID_ENABLE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set connection id interface.
     *
     * @param data.content
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setConnectionIdInterface: function setConnectionIdInterface(data, socket) {
        var cmd = wolfProtCommands.WPC_CB_CONNECTION_ID_INTERFACE;
        var txData = new Uint8Array(5);

        var mapping = {
            'lan1': wolfProtParameters.WPP_CB_INTERFACE_LAN1,
            'lan2': wolfProtParameters.WPP_CB_INTERFACE_LAN2,
            'wlan': wolfProtParameters.WPP_CB_INTERFACE_WLAN
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = mapping[data.interface];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * requests the connection id interface.
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getConnectionIdInterface: function getConnectionIdInterface(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_CONNECTION_ID_INTERFACE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * open webconference local (zoom or teams)
     *
     * @param data data.windowNumber 0 - 3 || -1 (auto)
     *        data.webconfType type
     * @param socket the websocket connection
     * @return cmd: the command number
     */
    setOpenWebconferenceLocal: function setOpenWebconferenceLocal(data, socket) {
        var txData = new Uint8Array(6);
        var cmd = wolfProtCommands.WPC_CB_OPEN_WEBCONFERENCE_LOCAL;

        var mapping = {
            'teams': wolfProtParameters.WPP_CONTENT_OFFICE365_TEAMS,
            'zoom': wolfProtParameters.WPP_FRAMEBOX_CONTENT_ZOOM
        };

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x02;
        txData[4] = data.windowNumber || -1;
        txData[5] = mapping[data.webconfType];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Enable/disable system analysis.
     *
     * @param data: data.enabled (true or false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setSystemAnalysisEnable: function setSystemAnalysisEnable(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_SYSTEM_ANALYSIS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.enabled === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests if system analysis is enabled.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getSystemAnalysisEnable: function getSystemAnalysisEnable(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_SYSTEM_ANALYSIS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Enable/disable camera back (BYOM) function.
     *
     * @param data: data.enabled (true or false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setCameraBackFunction: function setCameraBackFunction(data, socket) {
        let txData = new Uint8Array(5);
        const cmd = wolfProtCommands.WPC_CB_CAMERA_BACK_FUNCTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.enabled === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests if camera back (BYOM) function is enabled.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getCameraBackFunction: function getCameraBackFunction(data, socket) {
        let txData = new Uint8Array(4);
        const cmd = wolfProtCommands.WPC_CB_CAMERA_BACK_FUNCTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Test video of default UVC device in zero config setup.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setTestUvcZeroConfig: function setTestUvcZeroConfig(data, socket) {
        let txData = new Uint8Array(4);
        const cmd = wolfProtCommands.WPC_CB_ZERO_CONFIG_UVC_TEST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests if zero config for input/output devices applies. When no manual configuration was made,
     * devices (camera, mic, speaker) for camera back (BYOM) are provided automatically.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getZeroConfig: function getZeroConfig(data, socket) {
        let txData = new Uint8Array(4);
        const cmd = wolfProtCommands.WPC_CB_ZERO_CONFIG;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Include camera back content in stream / recording / room display.
     *
     * @param data: data.enabled (true or false)
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setCameraBackRecordingEnable: function setCameraBackRecordingEnable(data, socket) {
        let txData = new Uint8Array(5);
        const cmd = wolfProtCommands.WPC_CB_CAMERA_BACK_REC_ENABLE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.enabled === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Requests if include camera back content in stream / recording / room display is enabled.
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getCameraBackRecordingEnable: function getCameraBackRecordingEnable(data, socket) {
        let txData = new Uint8Array(4);
        const cmd = wolfProtCommands.WPC_CB_CAMERA_BACK_REC_ENABLE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get Room Controller settings.
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getRoomControllerSettings: function getRoomControllerSettings(data, socket) {
        const txData = new Uint8Array(4);
        const cmd = wolfProtCommands.WPC_CB_ROOM_CONTROLLER_SETTINGS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get Room Controller path.
     *
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getRoomControllerPath: function getRoomControllerPath(data, socket) {
        const txData = new Uint8Array(4);
        const cmd = wolfProtCommands.WPC_CB_ROOM_CONTROLLER_PATH;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set Room Controller settings.
     *
     * @param data.enabled: Enable Room Controller
     * @param data.roomControllerHost: Room Controller URL
     * @param data.roomControllerPathname: Room Controller Pathname
     * @param data.roomControllerCert: Room Controller Certificate
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    setRoomControllerSettings: function setRoomControllerSettings(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_ROOM_CONTROLLER_SETTINGS;

        const host = stringToBufferWithLength({ str: data.roomControllerHost, lengthBytes: 2 });
        const pathname = stringToBufferWithLength({ str: data.roomControllerPathname, lengthBytes: 2 });
        const cert = stringToBufferWithLength({ str: data.roomControllerCert, lengthBytes: 2 });

        const len = 1 + host.length + pathname.length + cert.length;
        const txData = new Uint8Array(5 + len);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET | wolfProtHeaders.WP_HEAD_EXT_LEN;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (len >> 8) & 0xFF;
        txData[4] = len & 0xFF;

        let offset = 5;
        txData[offset] = data.enabled === true ? wolfProtParameters.WPP_ON : wolfProtParameters.WPP_OFF;
        offset += 1;

        txData.set(host, offset);
        offset += host.length;

        txData.set(pathname, offset);
        offset += pathname.length;

        txData.set(cert, offset);
        offset += cert.length;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get dual projection enable
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getDualProjectionEnable: function getDualProjectionEnable(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_DUAL_PROJECTION_ENABLE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set dual projection enable
     * @param data.enable: true or false
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setDualProjectionEnable: function setDualProjectionEnable(data, socket) {
        var txData = new Uint8Array(5);
        var cmd = wolfProtCommands.WPC_CB_DUAL_PROJECTION_ENABLE;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = data.enable === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get confidence monitor information
     * @param data
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getConfidenceMonitors: function getConfidenceMonitors(data, socket) {
        var txData = new Uint8Array(4);
        var cmd = wolfProtCommands.WPC_CB_CONFIDENCE_MONITORS_INFORMATION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get content (windows, output in edit) information from outputs (HDMI1, HDMI2, Edit, WebRTC)
     *
     * @param data.outputs {Array<String>(hdmi1|hdmi2|edit|webrtc)} - the outputs
     * @param data.simple {(false|true)} - true: common block only - false: also get type specific block (default)
     * @param socket
     * @returns {{promise: *, cmd: number}}
     */
    getContentPerOutput: function getContentPerOutput(data, socket) {
        let txData = new Uint8Array(7);
        const cmd = wolfProtCommands.WPC_CB_CONTENT_PER_OUTPUT;

        if(!data.outputs) {
            data.outputs = ['hdmi1'];
        }

        const outputsMask = data.outputs.reduce((mask, output) => mask | OUTPUT_MAPPING[output], 0);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x03;
        txData[4] = (outputsMask >> 8) & 0xFF;
        txData[5] = outputsMask & 0xFF;
        txData[6] = data.simple === true ? 0x00 : 0x01;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Start application (control screen).
     *
     * @param data.output {(mirroring|hdmi1|hdmi2|prepare)}
     * @param data.replaceAppId - ID of the application to be replaced
     * @param data.prepareIndex - Index of the position within the prepare list where the application should be displayed (only applies if data.output is 'prepare')
     * @param data.restrictedWindow {(false|true)} - false: not restricted - true: restricted (only this client can control the new framebox)
     * @param data.content {(Visualizer|Whiteboard)} - Content of the window
     * @param socket - The websocket connection
     * @return cmd - The command number
     */
    setStartApplication: function setStartApplication(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_START_APPLICATION;

        let length = 13;
        let txData = new Uint8Array(4 + length);
        let replaceAppId = data.replaceAppId || '';

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = length & 0xFF;
        txData[4] = (OUTPUT_MAPPING[data.output] >> 8) & 0xFF;
        txData[5] = OUTPUT_MAPPING[data.output] & 0xFF;
        txData.set(helper.fromHexString(replaceAppId), 6);
        txData[14] = data.prepareIndex >= 0 ? data.prepareIndex : 0xFF;
        txData[15] = data.restrictedWindow === true ? 0x01 : 0x00;
        txData[16] = CONTENT_MAPPING[data.content];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Start application for Microsoft 365 (control screen).
     *
     * @param data.output {(mirroring|hdmi1|hdmi2|prepare)}
     * @param data.replaceAppId - ID of the application to be replaced
     * @param data.prepareIndex - Index of the position within the prepare list where the application should be displayed (only applies if data.output is 'prepare')
     * @param data.restrictedWindow {(false|true)} - false: not restricted - true: restricted (only this client can control the new framebox)
     * @param data.content {(msoutlook|msword|msexcel|mspowerpoint|msonenote|msplanner|mswhiteboard)} - Content of the window
     * @param data.url - URL to be opened. If not present, the default page is opened
     * @param socket - The websocket connection
     * @return cmd - The command number
     */
    setStartApplicationMicrosoft365: function setStartApplicationMicrosoft365(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_START_APPLICATION;

        let url = data.url || '';
        let urlArray = StringConvert.stringToUtf8(url);
        let length = 15 + urlArray.length;
        let txData = new Uint8Array(4 + length);
        let replaceAppId = data.replaceAppId || '';

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = length & 0xFF;
        txData[4] = (OUTPUT_MAPPING[data.output] >> 8) & 0xFF;
        txData[5] = OUTPUT_MAPPING[data.output] & 0xFF;
        txData.set(helper.fromHexString(replaceAppId), 6);
        txData[14] = data.prepareIndex >= 0 ? data.prepareIndex : 0xFF;
        txData[15] = data.restrictedWindow === true ? 0x01 : 0x00;
        txData[16] = CONTENT_MAPPING[data.content];
        txData[17] = (urlArray.length >> 8) & 0xFF;
        txData[18] = urlArray.length & 0xFF;

        if (urlArray.length > 0) {
            txData.set(urlArray, 19);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Start application for inputs (control screen).
     *
     * @param data.output {(mirroring|hdmi1|hdmi2|prepare)}
     * @param data.replaceAppId - ID of the application to be replaced
     * @param data.prepareIndex - Index of the position within the prepare list where the application should be displayed (only applies if data.output is 'prepare')
     * @param data.restrictedWindow {(false|true)} - false: not restricted - true: restricted (only this client can control the new framebox)
     * @param data.content{(HDMI|webcam|streaminput)} - Content of the window
     * @param data.index - Index of the input: 0 = HDMI1/ Webcam1/ Stream1, 1 = HDMI2/ Webcam2/ Stream2, ...
     * @param socket - The websocket connection
     * @return cmd - The command number
     */
    setStartApplicationInput: function setStartApplicationInput(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_START_APPLICATION;

        let length = 14;
        let txData = new Uint8Array(4 + length);
        let replaceAppId = data.replaceAppId || '';

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = length & 0xFF;
        txData[4] = (OUTPUT_MAPPING[data.output] >> 8) & 0xFF;
        txData[5] = OUTPUT_MAPPING[data.output] & 0xFF;
        txData.set(helper.fromHexString(replaceAppId), 6);
        txData[14] = data.prepareIndex >= 0 ? data.prepareIndex : 0xFF;
        txData[15] = data.restrictedWindow === true ? 0x01 : 0x00;
        txData[16] = CONTENT_MAPPING[data.content];
        txData[17] = data.index || 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Start application browser (control screen).
     *
     * @param data.output {(mirroring|hdmi1|hdmi2|prepare)}
     * @param data.replaceAppId - ID of the application to be replaced
     * @param data.prepareIndex - Index of the position within the prepare list where the application should be displayed (only applies if data.output is 'prepare')
     * @param data.restrictedWindow {(false|true)} - false: not restricted - true: restricted (only this client can control the new framebox)
     * @param data.url - URL to be opened
     * @param data.index - Browser shortcut index
     * @param socket - The websocket connection
     * @return cmd - The command number
     */
    setStartApplicationBrowser: function setStartApplicationBrowser(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_START_APPLICATION;

        let url = data.url || '';
        let urlArray = StringConvert.stringToUtf8(url);
        let length = 16 + urlArray.length;
        let txData = new Uint8Array(5 + length);
        let replaceAppId = data.replaceAppId || '';

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_LEN | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8) & 0xFF;
        txData[4] = length & 0xFF;
        txData[5] = (OUTPUT_MAPPING[data.output] >> 8) & 0xFF;
        txData[6] = OUTPUT_MAPPING[data.output] & 0xFF;
        txData.set(helper.fromHexString(replaceAppId), 7);
        txData[15] = data.prepareIndex >= 0 ? data.prepareIndex : 0xFF;
        txData[16] = data.restrictedWindow === true ? 0x01 : 0x00;
        txData[17] = wolfProtParameters.WPP_CONTENT_BROWSER;
        txData[18] = (urlArray.length >> 8) & 0xFF;
        txData[19] = urlArray.length & 0xFF;

        if (urlArray.length > 0) {
            txData.set(urlArray, 20);
        }

        txData[20 + urlArray.length] = data.index !== undefined ? data.index : 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Start application for Teams or Zoom (control screen).
     *
     * @param data.output {(mirroring|hdmi1|hdmi2|prepare)}
     * @param data.replaceAppId - ID of the application to be replaced
     * @param data.prepareIndex - Index of the position within the prepare list where the application should be displayed (only applies if data.output is 'prepare')
     * @param data.restrictedWindow {(false|true)} - false: not restricted - true: restricted (only this client can control the new framebox)
     * @param data.content {(msteams|zoom)} - Content of the window
     * @param data.action {(join|local)} - join: join meeting by URL - local: start local meeting room
     * @param data.url - The meeting URL
     * @param socket - The websocket connection
     * @return cmd - The command number
     */
    setStartApplicationTeamsZoom: function setStartApplicationTeamsZoom(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_START_APPLICATION;

        const actionMapping = {
            'join': wolfProtParameters.WPP_WEBCONF_JOIN,
            'local': wolfProtParameters.WPP_WEBCONF_START_LOCAL
        };

        let url = data.url || '';
        let urlArray = StringConvert.stringToUtf8(url);
        let length = 18 + urlArray.length;
        let txData = new Uint8Array(8 + length);
        let replaceAppId = data.replaceAppId || '';

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_HDR | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = wolfProtHeaders.WP_EXTHEAD_EXT_LEN;
        txData[2] = (cmd >> 8) & 0xFF;
        txData[3] = cmd & 0xFF;
        txData[4] = (length >> 24) & 0xFF;
        txData[5] = (length >> 16) & 0xFF;
        txData[6] = (length >> 8) & 0xFF;
        txData[7] = length & 0xFF;
        txData[8] = (OUTPUT_MAPPING[data.output] >> 8) & 0xFF;
        txData[9] = OUTPUT_MAPPING[data.output] & 0xFF;
        txData.set(helper.fromHexString(replaceAppId), 10);
        txData[18] = data.prepareIndex >= 0 ? data.prepareIndex : 0xFF;
        txData[19] = data.restrictedWindow === true ? 0x01 : 0x00;
        txData[20] = CONTENT_MAPPING[data.content];
        txData[21] = actionMapping[data.action] || 0x00;
        txData[22] = (urlArray.length >> 8) & 0xFF;
        txData[23] = urlArray.length & 0xFF;

        if (urlArray.length > 0) {
            txData.set(urlArray, 24);
        }

        let pointer = 24 + urlArray.length;

        // Private data is not used by Cynap FE
        txData[pointer++] = 0x00;
        txData[pointer++] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Start application for WebRTC (control screen).
     *
     * @param data.output {(mirroring|hdmi1|hdmi2|prepare)}
     * @param data.replaceAppId - ID of the application to be replaced
     * @param data.prepareIndex - Index of the position within the prepare list where the application should be displayed (only applies if data.output is 'prepare')
     * @param data.restrictedWindow {(false|true)} - false: not restricted - true: restricted (only this client can control the new framebox)
     * @param data.url - The web conference URL
     * @param data.nativeScreenSharing {(true|false)} - Enable native screen sharing
     * @param socket - The websocket connection
     * @return cmd - The command number
     */
    setStartApplicationWebconference: function setStartApplicationWebconference(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_START_APPLICATION;

        let url = data.url || '';
        let urlArray = StringConvert.stringToUtf8(url);
        let length = 16 + urlArray.length;
        let txData = new Uint8Array(5 + length);
        let replaceAppId = data.replaceAppId || '';

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_LEN | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8) & 0xFF;
        txData[4] = length & 0xFF;
        txData[5] = (OUTPUT_MAPPING[data.output] >> 8) & 0xFF;
        txData[6] = OUTPUT_MAPPING[data.output] & 0xFF;
        txData.set(helper.fromHexString(replaceAppId), 7);
        txData[15] = data.prepareIndex >= 0 ? data.prepareIndex : 0xFF;
        txData[16] = data.restrictedWindow === true ? 0x01 : 0x00;
        txData[17] = wolfProtParameters.WPP_CONTENT_WEBCONFERENCE;
        txData[18] = (urlArray.length >> 8) & 0xFF;
        txData[19] = urlArray.length & 0xFF;

        if (urlArray.length > 0) {
            txData.set(urlArray, 20);
        }

        txData[20 + urlArray.length] = data.nativeScreenSharing === true ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Start application for files (control screen).
     *
     * @param data.output {(mirroring|hdmi1|hdmi2|prepare)}
     * @param data.replaceAppId - ID of the application to be replaced
     * @param data.prepareIndex - Index of the position within the prepare list where the application should be displayed (only applies if data.output is 'prepare')
     * @param data.restrictedWindow {(false|true)} - false: not restricted - true: restricted (only this client can control the new framebox)
     * @param data.filePath - The file path
     * @param socket - The websocket connection
     * @return cmd - The command number
     */
    setStartApplicationFile: function setStartApplicationFile(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_START_APPLICATION;

        let filePath = data.filePath || '';
        let filePathArray = StringConvert.stringToUtf8(filePath);
        let length = 15 + filePathArray.length;
        let txData = new Uint8Array(5 + length);
        let replaceAppId = data.replaceAppId || '';

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_LEN | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8) & 0xFF;
        txData[4] = length & 0xFF;
        txData[5] = (OUTPUT_MAPPING[data.output] >> 8) & 0xFF;
        txData[6] = OUTPUT_MAPPING[data.output] & 0xFF;
        txData.set(helper.fromHexString(replaceAppId), 7);
        txData[15] = data.prepareIndex >= 0 ? data.prepareIndex : 0xFF;
        txData[16] = data.restrictedWindow === true ? 0x01 : 0x00;
        txData[17] = wolfProtParameters.WPP_CONTENT_FILE;
        txData[18] = (filePathArray.length >> 8) & 0xFF;
        txData[19] = filePathArray.length & 0xFF;

        if (filePathArray.length > 0) {
            txData.set(filePathArray, 20);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Start application for PDFs (control screen).
     *
     * @param {Object} data: request parameter object
     * @param {'mirroring'|'hdmi1'|'hdmi2'|'prepare'} data.output: the output
     * @param {String} data.replaceAppId: ID of the application to be replaced
     * @param {Number} data.prepareIndex: index of the position within the prepare list where the application should be displayed (only applies if data.output is 'prepare')
     * @param {Boolean} data.restrictedWindow: false: not restricted - true: restricted (only this client can control the new framebox)
     * @param {String} data.url: the URL where the pdf document resides
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setStartApplicationPdf: function setStartApplicationPdf(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_START_APPLICATION;

        let url = data.url || '';
        let urlArray = StringConvert.stringToUtf8(url);
        let length = 15 + urlArray.length;
        let txData = new Uint8Array(5 + length);
        let replaceAppId = data.replaceAppId || '';

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_LEN | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8) & 0xFF;
        txData[4] = length & 0xFF;
        txData[5] = (OUTPUT_MAPPING[data.output] >> 8) & 0xFF;
        txData[6] = OUTPUT_MAPPING[data.output] & 0xFF;
        txData.set(helper.fromHexString(replaceAppId), 7);
        txData[15] = data.prepareIndex >= 0 ? data.prepareIndex : 0xFF;
        txData[16] = data.restrictedWindow === true ? 0x01 : 0x00;
        txData[17] = wolfProtParameters.WPP_CONTENT_PDF;
        txData[18] = (urlArray.length >> 8) & 0xFF;
        txData[19] = urlArray.length & 0xFF;

        if (urlArray.length > 0) {
            txData.set(urlArray, 20);
        }

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Control application (control screen).
     *
     * @param data.appId - ID of the application to be controlled
     * @param data.action {(close|fullscreen|normal|auxOn|auxOff|auxToggle|webRtcOutOn|webRtcOutOff|webRtcOutToggle)}
     * @param socket
     * @return {{promise, cmd: number}}
     */
    setControlApplication: function setControlApplication(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_CONTROL_APPLICATION;

        const actionMapping = {
            'close': wolfProtParameters.WPP_APPLICATION_CLOSE,
            'fullscreen': wolfProtParameters.WPP_APPLICATION_FULLSCREEN,
            'normal': wolfProtParameters.WPP_APPLICATION_NORMAL,
            'auxOn': wolfProtParameters.WPP_APPLICATION_AUX_ON,
            'auxOff': wolfProtParameters.WPP_APPLICATION_AUX_OFF,
            'auxToggle': wolfProtParameters.WPP_APPLICATION_AUX_TOGGLE,
            'webRtcOutOn': wolfProtParameters.WPP_APPLICATION_WEBRTC_OUT_ON,
            'webRtcOutOff': wolfProtParameters.WPP_APPLICATION_WEBRTC_OUT_OFF,
            'webRtcOutToggle': wolfProtParameters.WPP_APPLICATION_WEBRTC_OUT_TOGGLE
        };

        let txData = new Uint8Array(13);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x09;
        txData.set(helper.fromHexString(data.appId), 4);
        txData[12] = actionMapping[data.action];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Show application on specified outputs (control screen).
     *
     * @param data.appId - ID of the application to be shown on the specified outputs
     * @param data.output {(mirroring|hdmi1|hdmi2|prepare)}
     * @param data.prepareIndex - Index of the position within the prepare list where the application should be displayed (only applies if data.output is 'prepare')
     * @param socket
     * @return {{promise, cmd: number}}
     */
    setShowApplication: function setShowApplication(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_CONTROL_APPLICATION;

        let txData = new Uint8Array(18);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x0E;
        txData.set(helper.fromHexString(data.appId), 4);
        txData[12] = wolfProtParameters.WPP_APPLICATION_SHOW;
        txData[13] = (OUTPUT_MAPPING['all'] >> 8) & 0xFF;
        txData[14] = OUTPUT_MAPPING['all'] & 0xFF;
        txData[15] = (OUTPUT_MAPPING[data.output] >> 8) & 0xFF;
        txData[16] = OUTPUT_MAPPING[data.output] & 0xFF;
        txData[17] = data.prepareIndex >= 0 ? data.prepareIndex : 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Replace application (control screen).
     *
     * @param data.appId - ID of the application to be moved
     * @param data.replaceAppId - ID of the application to be replaced
     * @param socket
     * @return {{promise, cmd: number}}
     */
    setReplaceApplication: function setReplaceApplication(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_CONTROL_APPLICATION;

        let txData = new Uint8Array(21);

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x11;
        txData.set(helper.fromHexString(data.appId), 4);
        txData[12] = wolfProtParameters.WPP_APPLICATION_REPLACE;
        txData.set(helper.fromHexString(data.replaceAppId), 13);

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Start/Stop edit mode for an application (control screen).
     *
     * @param data.appId - ID of the application to edit
     * @param data.action {(start|stop)} - Action
     * @param socket
     * @return {{promise, cmd: number}}
     */
    setEditMode: function setEditMode(data, socket) {
        const cmd = wolfProtCommands.WPC_CB_CONTROL_APPLICATION;

        let txData = new Uint8Array(18);

        let show = OUTPUT_MAPPING['edit'];
        if(data.action === 'stop'){
            show &= ~OUTPUT_MAPPING['edit'];
        }

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x0E;
        txData.set(helper.fromHexString(data.appId), 4);
        txData[12] = wolfProtParameters.WPP_APPLICATION_SHOW;
        txData[13] = (OUTPUT_MAPPING['edit'] >> 8) & 0xFF;
        txData[14] = OUTPUT_MAPPING['edit'] & 0xFF;
        txData[15] = (show >> 8) & 0xFF;
        txData[16] = show & 0xFF;
        txData[17] = 0xFF;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Returns the items in the prepare list (control screen).
     *
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getPrepareList: function getPrepareList(data, socket) {
        let txData = new Uint8Array(4);
        const cmd = wolfProtCommands.WPC_CB_PREPARE_LIST;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Send input events to application window on specified output.
     *
     * @param data.event {(pointer|keyboard)} - the input event type
     * @param data.eventList - list of up to 16 subsequent input event entries
     * @param data.eventList[i].appId - ID of the application to send input events to
     * @param data.eventList[i].output {(hdmi1|edit|webrtc)} - the output where the application window is open
     * @param data.eventList[i].event {(mousemove|mousedown|mouseup|touchstart|touchmove|touchend|touchcancel)}
     * @param data.eventList[i].positionX - the current pointer position x
     * @param data.eventList[i].positionY - the current pointer position y
     * @param data.eventList[i].keyState {(keydown|keyup)} - the key state
     * @param data.eventList[i].key - the key
     * @param data.eventList[i].keycode - the keycode
     * @param socket -  the websocket connection
     * @return cmd - the command number
     */
    setApplicationMultiEvents: function setApplicationMultiEvents(data, socket) {
        const length = data.eventList.length * 20;
        let txData = new Uint8Array(5 + length);
        let pos = 4;
        const cmd = wolfProtCommands.WPC_CB_APPLICATION_MULTI_EVENTS;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_EXT_LEN | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = (length >> 8) & 0xFF;
        txData[4] = length & 0xFF;

        _.each(data.eventList, function(eventItem) {
            eventItem.output = eventItem.output || 'edit';

            txData[pos + 1] = 19;
            txData.set(helper.fromHexString(eventItem.appId), pos + 2);
            txData[pos + 10] = (OUTPUT_MAPPING[eventItem.output] >> 8) & 0xFF;
            txData[pos + 11] = OUTPUT_MAPPING[eventItem.output] & 0xFF;

            if (data.event === 'pointer') {
                if (eventItem.event.indexOf('touch') >= 0) {
                    txData[pos + 12] = POINTER_INPUT_MAPPING[eventItem.event];
                    txData[pos + 13] = eventItem.identifier >> 8;
                    txData[pos + 14] = eventItem.identifier & 0x00FF;
                } else {
                    txData[pos + 12] = wolfProtParameters.WPP_FRAMEBOX_EVENT_POINTER_ABSOLUTE;
                    txData[pos + 13] = POINTER_INPUT_MAPPING[eventItem.event] >> 8;
                    txData[pos + 14] = POINTER_INPUT_MAPPING[eventItem.event] & 0x00FF;
                }
            } else if (data.event === 'keyboard') {
                txData[pos + 12] = wolfProtParameters.WPP_FRAMEBOX_EVENT_POINTER_ABSOLUTE;
                if (eventItem.keyState === 'keydown') {
                    txData[pos + 12] = wolfProtParameters.WPP_FRAMEBOX_EVENT_KEY_HIT;
                } else if (eventItem.keyState === 'keyup') {
                    txData[pos + 12] = wolfProtParameters.WPP_FRAMEBOX_EVENT_KEY_RELEASE;
                }
                txData[pos + 13] = eventItem.key >> 8;
                txData[pos + 14] = eventItem.key;
            }

            txData[pos + 15] = (eventItem.positionX >> 8) & 0xFF;
            txData[pos + 16] = eventItem.positionX & 0xFF;
            txData[pos + 17] = (eventItem.positionY >> 8) & 0xFF;
            txData[pos + 18] = eventItem.positionY & 0xFF;

            txData[pos + 19] = eventItem.keyCode >> 8;
            txData[pos + 20] = eventItem.keyCode;

            pos += 20;
        }.bind(this));

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Get state of outputs mirroring HDMI1/HDMI2 (control screen).
     *
     * @param data - unused
     * @param socket - the websocket connection
     * @return {{promise, cmd: number}}
     */
    getOutputMirroring: function(data, socket) {
        let txData = new Uint8Array(4);
        const cmd = wolfProtCommands.WPC_CB_OUTPUT_MIRRORING;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set outputs mirroring HDMI1/HDMI2 (control screen).
     *
     * @param data.on {(false|true)}
     * @param socket - the websocket connection
     * @return {{promise, cmd: number}}
     */
    setOutputMirroring: function(data, socket) {
        let txData = new Uint8Array(5);
        const cmd = wolfProtCommands.WPC_CB_OUTPUT_MIRRORING;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.on === true) ? 0x01 : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * Set whole output HDMI1/HDMI2 to edit mode (control screen).
     *
     * @param {'hdmi1'|'hdmi2'} data.output: output to view in edit mode
     * @param {Boolean} data.show: true - show output / false - hide output
     * @param socket - the websocket connection
     * @return {{promise, cmd: number}}
     */
    setOutputToEdit: function(data, socket) {
        const txData = new Uint8Array(5);
        const cmd = wolfProtCommands.WPC_CB_OUTPUT_TO_EDIT;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x01;
        txData[4] = (data.show === true) ? OUTPUT_MAPPING[data.output] : 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * @method setControlScreenResolution
     * @param data.resolution: the resolution that should be set e.g.: '1080p30', '1080p60', '2160p30', 'auto'
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    setControlScreenResolution: function setControlScreenResolution(data, socket) {
        const txData = new Uint8Array(5);
        const cmd = wolfProtCommands.WPC_CB_CONTROL_SCREEN_RESOLUTION;
        const resolution = data.resolution || 'auto';

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_SET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 1;
        txData[4] = states.hdmiResolutions[resolution];

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    },

    /**
     * @method getControlScreenResolution
     * @param data: unused
     * @param socket: the websocket connection
     * @return cmd: the command number
     */
    getControlScreenResolution: function getControlScreenResolution(data, socket) {
        const txData = new Uint8Array(4);
        const cmd = wolfProtCommands.WPC_CB_CONTROL_SCREEN_RESOLUTION;

        txData[0] = wolfProtHeaders.WP_HEAD_EXT_CMD | wolfProtHeaders.WP_HEAD_DIR_GET;
        txData[1] = (cmd >> 8) & 0xFF;
        txData[2] = cmd & 0xFF;
        txData[3] = 0x00;

        return {
            promise: socket.send(txData),
            cmd: cmd
        };
    }
};
