'use strict';

var app = require('../../../app');
var template = require('./cloud-settings.hbs');
var FormActionView = require('./../form-action-view');
var i18n = require('i18next');
var $ = require('jquery');

var ANIMATION_DURATION = 200;

app.component('CloudSettings', {
    template: template,

    /**
     * @method initialize
     */
    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.validate = this.getService('ValidateService');
    },

    /**
     * @method serialize
     */
    serialize: function() {
        var dfd = this.$.Deferred();

        this.loadData()
            .then(function() {
                dfd.resolve({});
            }.bind(this));

        return dfd.promise();
    },

    /**
     * @method loadData
     */
    loadData: function() {
        return this.deviceConnection
            .send([
                'getCloudServicesEnabled',
                'getWebdavSettings'
            ])
            .then(function(services, webdav) {
                this.formData = {
                    'dropbox-enabled': services.dropboxEnabled,
                    'gdrive-enabled': services.gdriveEnabled,
                    'jianguoyun-enabled': services.jianguoyunEnabled,
                    'onedrive-enabled': services.onedrive,
                    'webdav-enabled': services.webdav,
                    'webdav-url': webdav.url,
                    'webdav-name': webdav.name
                };
            }.bind(this));
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.initForm();
        this.initFormAction();
        this.form.setValues(this.formData);
        this.bindEvents();
        this.bindDOMEvents();
        this.handleWebdavSettings(true);
    },

    storeSelectors: function() {
        this.$webdavContainer = this.$el.find('#webdav-container');
    },

    bindEvents: function() {
        this.on('settings.save-changes', this.saveHandler.bind(this));
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
    },

    /**
     * @method initForm
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#cloud-settings'),
            validationContainer: '.input-group'
        });
    },

    /**
     * @method initFormAction
     */
    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    /**
     * @method handleFormChange
     */
    handleFormChange: function() {
        this.handleWebdavSettings(false);
        this.actionView.open();
        this.checkActionForm();
        this.changes = true;
    },

    /**
     * Check action form.
     */
    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * @method saveHandler
     */
    saveHandler: function() {
        if (this.form.validate()) {
            var data = this.form.serialize();

            this.deviceConnection
                .send([
                    {
                        command: 'setCloudServiceEnable',
                        data: {
                            cloudService: 'dropbox',
                            enableService: data['dropbox-enabled']
                        }
                    },
                    {
                        command: 'setCloudServiceEnable',
                        data: {
                            cloudService: 'gdrive',
                            enableService: data['gdrive-enabled']
                        }
                    },
                    {
                        command: 'setCloudServiceEnable',
                        data: {
                            cloudService: 'jianguoyun',
                            enableService: data['jianguoyun-enabled']
                        }
                    },
                    {
                        command: 'setCloudServiceEnable',
                        data: {
                            cloudService: 'onedrive',
                            enableService: data['onedrive-enabled']
                        }
                    },
                    {
                        command: 'setCloudServiceEnable',
                        data: {
                            cloudService: 'webdav',
                            enableService: data['webdav-enabled']
                        }
                    },
                    {
                        command: 'setWebdavSettings',
                        data: {
                            url: data['webdav-url'],
                            name: (data['webdav-name'] !== '' ? data['webdav-name'] : i18n.t('settings.webdav'))
                        }
                    }
                ]);

            this.handleSettingsSave();
            this.form.setDefaultValues();
            this.emit('overlay.remote.focus', true);
        }
    },

    /**
     * @method handleSettingsSave
     */
    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.changes = false;
    },

    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.loadData()
            .then(function() {
                this.form.setValues(this.formData);
                this.changes = false;
            }.bind(this));
    },

    /**
     * Handle WebDAV settings container (show/hide).
     *
     * @param {boolean} show
     */
    handleWebdavSettings: function(init) {
        if (this.form.get('webdav-enabled').getValue()) {
            this.$webdavContainer.find('#webdav-url').attr('data-allow-empty', 'false');

            if (init) {
                this.$webdavContainer.show();
            } else {
                this.$webdavContainer
                    .stop()
                    .slideDown(ANIMATION_DURATION);
            }
        } else {
            this.$webdavContainer.find('#webdav-url').attr('data-allow-empty', 'true');

            if (init) {
                this.$webdavContainer.hide();
            } else {
                this.$webdavContainer
                    .stop()
                    .slideUp(ANIMATION_DURATION);
            }
        }
    },

    /**
     * Destroy.
     */
    destroy: function() {
        this.formManager.destroy(this.form);
        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    hasChanges: function() {
        if (!this.actionView) {
            return {
                hasChanges: false,
                invalid: false
            };
        }

        return {
            hasChanges: this.changes,
            invalid: $(this.actionView.$submitBtn).prop('disabled')
        };
    }
});
