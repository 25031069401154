'use strict';

const $ = require('jquery');
const app = require('../../app');
const helper = require('../../helper.js');
const mainTpl = require('./main.hbs');
const platform = require('../../../platform/platform');
const layoutManager = require('../../../layout/layout');
const { deviceConnectionStates } = require('../../states');

const views = {
    'default': require('./../../../layout/views/Default.js'),
    'aux': require('./../../../layout/views/CboxAux.js')
};

/**
 * Main component holds all other components
 */
app.component('Main', {
    /**
     * @type {Function}
     */
    template: mainTpl,

    /**
     * @type {String}
     */
    className: 'main',

    /**
     * @type {BaseView}
     */
    view: null,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    /**
     * @method initialize
     */
    initialize: function() {
        this.configs = this.getService('ConfigsService');
        this.deviceService = this.getService('DeviceService');
        this.powerService = this.getService('PowerService');
        this.authService = this.getService('AuthenticationService');
        this.adminMessageService = this.getService('AdminMessageService');
        this.bootstrapService = this.getService('BootstrapService');
        this.languageService = this.getService('LanguageService');
        this.modalHandlerService = app.getService('ModalHandlerService');
        this.storage = app.getService('DataStorage');

        this.connectionFactory = this.getService('ConnectionFactoryService');
        this.validate = this.getService('ValidateService');
        this.getService('VolumeService');

        this.connectionState = this.createStateMachine({
            state: deviceConnectionStates.none,
            states: deviceConnectionStates
        });

        this.addStateTransitions();
        this.bindEvents();
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        this.on('app.state.change', this.changeStateHandler.bind(this));
        this.on('admin-message.state.change', this.adminMessageStateHandler.bind(this));
    },

    /**
     * Start all components that we need initially
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.startDeviceConnection()
            .then(function() {
                // If we are executed on a cbox enable the custom cursor.
                if (platform.checks.isCbox === true) {
                    this.app.createComponent('customCursor', {
                        type: 'CustomCursor',
                        container: 'body'
                    });

                    this.emit('app.state.change', {
                        'remote-control': true
                    });
                }
            }.bind(this));

        layoutManager.mainIsInitialized();
    },

    /**
     * Update the state classes
     * @method changeStateHandler
     * @param {Object} stateObj
     */
    changeStateHandler: function(stateObj) {
        var classes = Object.keys(stateObj);

        classes.forEach(function(className) {
            if (stateObj[className]) {
                this.$el.addClass(className);
            } else {
                this.$el.removeClass(className);
            }
        }.bind(this));
    },

    adminMessageStateHandler: function(state) {
        let overlay = this.$el.find('#aspect-ratio-4-3');

        if (state === 'started') {
            overlay.hide();
        } else {
            overlay.show();
        }
    },

    /**
     * Connect to the server.
     */
    startDeviceConnection: function() {
        const storedToken = this.storage.get('token');
        let token = helper.getFirstMatchingUrlParam(['token']).value;
        let jwt = null;

        if (!token && helper.pageReload() && storedToken) {
            token = storedToken;
        }

        if (token || helper.pageReload() && storedToken) {
            jwt = helper.decodeJwt(token);
            helper.removeParamsFromUrl();

            this.storage.set('token', token);
            this.storage.set('sessionId', jwt.sessionId);
        } else {
            this.storage.remove('sessionId');
            this.storage.remove('token');
        }

        var host = jwt ? helper.sprintf(this.configs.get('cynap.hostToken'), [jwt.wsURL, token]) : this.configs.get('cynap.host');
        var deviceConnection = this.connectionFactory.create('device', {
            host: host,
            sessionId: jwt ? jwt.sessionId : null,
            onOpen: function() {
                this.connectionState.changeState(deviceConnectionStates.open);
            }.bind(this),
            onClose: function() {
                this.connectionState.changeState(deviceConnectionStates.closed);
            }.bind(this),
            onTimeout: function() {
                this.connectionState.changeState(deviceConnectionStates.closed);
            }.bind(this)
        });

        return deviceConnection.connect();
    },

    /**
     * Load global config to setup the application.
     */
    loadGlobalConfig: function() {
        var dfd = $.Deferred();

        this.languageService
            .loadDefaultLanguage()
            .then(dfd.resolve);

        return dfd.promise();
    },

    /**
     * Start App with global components and modules.
     */
    startGlobalApp: function() {
        this.deviceService.initDevice();

        this.languageService
            .initLanguage()
            .then(function() {
                this.app.createComponent('RotateMessage', {
                    type: 'RotateMessage',
                    container: '#main-warning'
                });

                this.app.createComponent('mainBackdrop', {
                    type: 'Backdrop',
                    container: '#backdrop-container'
                });

                // Create modal.
                this.app.createComponent('mainModal', {
                    type: 'Modal',
                    container: '#modal-container'
                });

                this.initializeView();

                this.authService.checkLdapLoginRequired().then(
                    this.authService.checkLoginRequired(
                        this.authService.showLoginDialog.bind(this.authService),
                        this.bootstrapService.start.bind(this.bootstrapService)
                    ));
            }.bind(this));
    },

    /**
     * Initialize layoutview.
     */
    initializeView: function() {
        var View = views.default;

        if (platform.checks.isCboxAux) {
            View = views.aux;

            this.emit('rbac.user.changed', {
                key: 'aux'
            });
        }

        this.view = new View(this.$el);
    },

    addStateTransitions: function() {
        this.connectionState.addTransitions({
            '> open': function() {
                this.loadGlobalConfig()
                    .then(this.startGlobalApp.bind(this));
                this.emit('modal.close', { id: 'disconnected' });
            },
            '> closed': function() {
                if (this.modalHandlerService.getOpenModalId() !== 'disconnected') {
                    if (this.powerService.isOnStandby()) {
                        return;
                    }

                    this.getService('AnnotationService').clearAllStoredSettings();
                    this.emit('modal.open', {
                        id: 'disconnected',
                        onConfirm: function() {
                            this.emit('modal.close', { id: 'disconnected' });
                            window.location.reload();
                        }.bind(this)
                    });
                }
            }
        });
    }
});
