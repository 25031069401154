const template = require('./tile-v3.html');

module.exports = {
    template,
    props: {
        small: {
            type: Boolean,
            required: false,
            default: false
        },

        large: {
            type: Boolean,
            required: false,
            default: false
        },

        icon: {
            type: String,
            required: true
        },

        removable: {
            type: Boolean,
            required: false,
            default: false
        },

        focusable: {
            type: Boolean,
            required: false,
            default: true
        },

        defaultFocus: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data: function() {
        return {};
    },

    methods: {
        click: function() {
            this.$emit('click');
        },

        remove: function(ev) {
            this.$emit('remove');
            ev.stopPropagation();
        }
    }
};
