'use strict';

var _ = require('lodash');
var $ = require('jquery');
var app = require('../../app');
var frameBoxesContainerTpl = require('./frameboxes-container.hbs');
var FrameBoxView = require('./framebox-view');

/**
 * Frameboxes blueprint
 * Manages all frameboxes
 */
app.component('Frameboxes', {
    className: 'full-height',

    /**
     * @type {Function}
     */
    template: frameBoxesContainerTpl,

    /**
     * @type {FrameBoxService|null}
     */
    frameBoxService: null,

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    /**
     * In this component we update all frameboxes and handle the Fullscreen toggler.
     */
    initialize: function() {
        this.selectedFullScreenTab = null;
        this.frameBoxes = [];
        this.fullscreenFrameBox = null;
        this.fullScreenHeaderComponentId = null;
        this.focusNextFrameBox = false;
        this.isLoaded = [];
        this.focusStored = null;
        this.remote = this.getService('RemoteService');
        this.frameBoxService = this.getService('FrameBoxService');
        this.liveStream = this.getService('LiveStreamService');
        this.storage = this.getService('DataStorage');
        this.annotationService = this.getService('AnnotationService');
        this.stationService = app.getService('StationService');
        this.isHdmiOut2 = [];

        this.storeSelectors();
        this.frameBoxService.closeFullscreen(); // Fix the state, after you leave Annotations.
        this.liveStream.unpause();
        this.bindEvents();
        this.forceUpdate = true;
    },

    bindEvents: function() {
        this.on('framebox.add', this.openFrameBox.bind(this));
        this.on('framebox.close', this.closeFrameBox.bind(this));
        this.on('framebox.coming', this.frameboxComingHandler.bind(this));
        this.on('frameboxes.update', this.updateFrameBoxes.bind(this));
        this.on('frameboxes.close.all', this.closeAllFrameBoxes.bind(this));
        this.on('framebox.fullscreen.open', this.openFullscreen.bind(this));
        this.on('framebox.fullscreen.close', this.minimizeFullscreen.bind(this));
        this.on('framebox.focus.stored', this.focusStoredHandler.bind(this));
        this.on('control-center.opened', this.blockAutoFocus.bind(this));
        this.on('menu.opened', this.blockAutoFocus.bind(this));
        this.on('file-browser.opened', this.blockAutoFocus.bind(this));
        this.on('overlay.opened-end', this.blockAutoFocus.bind(this));
        this.on('modal.opened-end', this.blockAutoFocus.bind(this));
        this.on('framebox.stored.block', this.blockAutoFocus.bind(this));
        this.on('aspect-ratio.changed', _.debounce(this.resizeHandler.bind(this), 100));
        this.on('framebox.focusFrameboxWithOption', this.focusFrameboxWithOption.bind(this));
        this.on('framebox.toggle.hdmi-out-2', this.toggleAuxFramebox.bind(this));
    },

    /**
     * DOM-Elements are loaded.
     */
    postPlaceAt: function() {
        this.storeSelectors();
        _.delay(this.setHeight.bind(this), 100);
    },

    /**
     * Store all selectors.
     */
    storeSelectors: function() {
        this.$main = $('.main');
    },

    /**
     * This method is called after a resize was triggered.
     *
     * @param {Object} size
     */
    resizeHandler: function(size) {
        this.setHeight(size);
    },

    /**
     * Focus the Framebox that was stored in Browser-cache.
     */
    focusStoredHandler: function() {
        var index = this.storage.get('lastFrameboxIndex', false);

        if (index || index === 0) {
            this.blockAutoFocus();
            this.focusStored = setTimeout(function() {
                this.emit('framebox.focus', {
                    index: index
                });
            }.bind(this), 350);
        }
    },

    /**
     * Blocks the autofocus of a Framebox.
     */
    blockAutoFocus: function() {
        clearTimeout(this.focusStored);
    },

    /**
     * Set height of the Framebox-view.
     *
     * @param {Object} size
     * @param {string} size.height
     */
    setHeight: function(size) {
        var height;

        if (size && size.height) {
            height = size.height;
        } else {
            height = (this.$el.width() / 16) * 9 + 'px';
        }

        this.$el.css({
            height: height
        });
    },

    /**
     * Compares two coordinates from two Frameboxes.
     *
     * @param {Object} coordinatesA
     * @param {Number} coordinatesA.x
     * @param {Number} coordinatesA.y
     * @param {Number} coordinatesA.width
     * @param {Number} coordinatesA.height
     * @param {Object} coordinatesB
     * @param {Number} coordinatesB.x
     * @param {Number} coordinatesB.y
     * @param {Number} coordinatesB.width
     * @param {Number} coordinatesB.height
     *
     * @return {Boolean}
     */
    compareCoordinates: function(coordinatesA, coordinatesB) {
        return (coordinatesA.x === coordinatesB.x)
            && (coordinatesA.y === coordinatesB.y)
            && (coordinatesA.width === coordinatesB.width)
            && (coordinatesA.height === coordinatesB.height);
    },

    /**
     * Compare options from two Frameboxes.
     *
     * @param {Object} oldOptions
     * @param {Object} newOptions
     */
    compareOptions: function(oldOptions, newOptions) {
        oldOptions = oldOptions || {};
        newOptions = newOptions || {};

        return _.isEqual(oldOptions, newOptions);
    },

    /**
     * Check if the framebox has any updates.
     *
     * @param {Object} oldFrameBox
     * @param {Object} oldFrameBox.coordinates
     * @param {Object} oldFrameBox.options
     * @param {String} oldFrameBox.contentType
     * @param {Boolean} oldFrameBox.isFullscreen
     * @param {Object} newFrameBox
     * @param {Object} newFrameBox.coordinates
     * @param {Object} newFrameBox.options
     * @param {String} newFrameBox.contentType
     * @param {Boolean} newFrameBox.isFullscreen
     */
    checkUpdates: function(oldFrameBox, newFrameBox) {
        return !this.compareCoordinates(oldFrameBox.coordinates, newFrameBox.coordinates)
            || !this.compareOptions(oldFrameBox.options, newFrameBox.options)
            || (oldFrameBox.contentType !== newFrameBox.contentType)
            || (oldFrameBox.isFullscreen !== newFrameBox.fullscreen)
            || (oldFrameBox.webRtcOut !== newFrameBox.webRtcOut)
            || (oldFrameBox.volume !== newFrameBox.volume)
            || (oldFrameBox.mute !== newFrameBox.mute)
            || newFrameBox.forceUpdate; // Check force update, is true after touch or mousedown on framebox.
    },

    /**
     * Creates, removes or updates the frameboxes.
     *
     * @param {Object} frameBoxes the array with the new frameboxes or new framebox parameters
     */
    updateFrameBoxes: function(frameBoxes) {
        var i;
        var storedBox;
        var box;
        var fullScreenCoordinates = {
            x: 0,
            y: 0,
            width: 0,
            height: 0
        };

        // Occasionally create, remove, or update frameboxes.
        for (i = 0; (i < this.frameBoxes.length) || (i < frameBoxes.boxes.length); i++) {
            box = this.frameBoxService.forceUpdate(frameBoxes.boxes[i]) || false;
            storedBox = this.frameBoxes[i] || false;

            // Set the fullscreen coordinates if the fullscreen flag is set to true.
            if (box && box.fullscreen) {
                box.coordinates = fullScreenCoordinates;
            }

            if (box) {
                box.isMatrix = box.contentType === 'matrix';

                // Only bind the Webconference update handler if a Webconf Framebox is opened.
                if (['teams', 'zoom', 'webconference', 'otherContent'].indexOf(box.contentType) >= 0) {
                    this.emit('webconference-service.available');
                    this.emit('webconference-service.contentType.update', box.contentType);
                }
            }

            if (!storedBox && box) {
                // Do we have to create a new one?
                this.addFrameBox(i, box, frameBoxes.fullscreenFrameBox);
            } else if (storedBox && !box) {
                // Do we have to delete one?
                this.removeFrameBox(i);
            } else if (storedBox.contentType !== box.contentType) {
                this.removeFrameBox(i);
                this.addFrameBox(i, box, frameBoxes.fullscreenFrameBox);
            } else if (storedBox && this.checkUpdates(storedBox, box)) {
                this.updateFrameBox(i, box);
            }

            if (storedBox && box) {
                storedBox.options = box.options;
                this.checkLoader(i, storedBox.options);
            }

            this.frameBoxService.setActiveAux(i, box.aux !== 0);
            this.frameBoxService.setTransparency(i, box.transparent);
            this.frameBoxService.setWipeOut(i, box.wipeOut);
        }

        this.frameBoxService.sendActiveAuxEvent();

        this.handleFullscreen(frameBoxes);

        this.forceUpdate = false;
    },

    /**
     * Open or Close Fullscreen by command from CYNAP.
     *
     * @param {object} frameBoxes
     */
    handleFullscreen: function(frameBoxes) {
        // Handle fullscreen open and close state.
        this.toggleFullscreen(frameBoxes);

        if (frameBoxes.fullscreenFrameBox && this.selectedFullScreenTab !== frameBoxes.fullscreenFrameBox.index) {
            this.emit('framebox.fullscreen.select-tab', frameBoxes.fullscreenFrameBox.index);

            this.selectedFullScreenTab = frameBoxes.fullscreenFrameBox.index;
        }

        this.fullscreenFrameBox = frameBoxes.fullscreenFrameBox;
    },

    /**
     * Open or Close Fullscreen.
     *
     * @param frameBoxes
     */
    toggleFullscreen: function(frameBoxes) {
        var isFullscreen;

        if (this.forceUpdate) {
            isFullscreen = this.$main.hasClass('is-fullscreen');

            if (frameBoxes.fullscreenFrameBox && !isFullscreen) {
                this.openFullscreenHandler();
            } else if (!frameBoxes.fullscreenFrameBox && isFullscreen) {
                this.closeFullscreenHandler();
            }

            return;
        }

        if (!this.fullscreenFrameBox && frameBoxes.fullscreenFrameBox) {
            this.openFullscreenHandler();
        } else if (this.fullscreenFrameBox && !frameBoxes.fullscreenFrameBox) {
            this.closeFullscreenHandler();
        }
    },

    /**
     * Hide and show the loader from the framebox.
     *
     * @param {Number} index
     * @param {object} box
     */
    checkLoader: function(index, box) {
        var hasFilename = _.has(box, 'filename');

        if (box && box.busy) {
            this.isLoaded[index] = false;
        }

        if ((!this.isLoaded[index] && hasFilename && box.filename === '') || (box && box.busy)) {
            this.showLoader(index);
        } else if (!this.isLoaded[index]) {
            this.isLoaded[index] = true;
            this.hideLoader(index);
        }
    },

    /**
     * @method showLoader
     * @param {Number} index
     */
    showLoader: function(index) {
        if (this.fullscreenFrameBox && this.fullscreenFrameBox.index === index) {
            this.$el.find('#framebox-loader-fullscreen-' + index).show();
        } else {
            this.$el.find('#framebox-loader-fullscreen-' + index).hide();
        }

        this.$el.find('#framebox-loader-' + index).show();
    },

    /**
     * @method hideLoader
     * @param {Number} index
     */
    hideLoader: function(index) {
        if (this.fullscreenFrameBox && this.fullscreenFrameBox.index === index) {
            this.$el.find('#framebox-loader-fullscreen-' + index).hide();
        }

        this.$el.find('#framebox-loader-' + index).hide();
    },

    /**
     * Add a new framebox.
     *
     * @param {Number} index Index of the framebox that should be added.
     * @param {Object} rawFrameBox
     * @param {Boolean} fullscreenExists
     */
    addFrameBox: function(index, rawFrameBox, fullscreenExists) {
        var frameBox = this.frameBoxes[index] = this.createFrameBox(index, rawFrameBox);

        this.isLoaded[index] = false;
        this.callFrameBoxHandler(frameBox.contentType, 'create');

        if (!fullscreenExists) {
            frameBox.view = this.createFrameBoxView(frameBox);
            this.appendFrameBoxView(frameBox);
        }

        this.emit('framebox.focus', {
            index: index
        });

        this.emit('framebox.added', frameBox);
        this.checkHasFrameboxes();
    },

    /**
     * Updates a framebox with the given index.
     *
     * @param {Number} index - The index of the framebox that should be updated.
     * @param {Object} rawFrameBox
     * @param {String} rawFrameBox.contentType
     * @param {Object} rawFrameBox.coordinates
     * @param {Boolean} rawFrameBox.fullscreen
     * @param {Object} rawFrameBox.options
     * @param {Boolean} rawFrameBox.isMatrix
     */
    updateFrameBox: function(index, rawFrameBox) {
        var frameBox = this.frameBoxes[index];

        if (rawFrameBox.options && frameBox.options && this.isInputTypeText(frameBox, rawFrameBox)) {
            this.emit('framebox-control-bar.hide', {
                index: index,
                force: true
            });
        }

        frameBox.index = index;
        frameBox.contentType = rawFrameBox.contentType;
        frameBox.coordinates = rawFrameBox.coordinates;
        frameBox.isFullscreen = rawFrameBox.fullscreen;
        frameBox.webRtcOut = rawFrameBox.webRtcOut;
        frameBox.options = rawFrameBox.options;
        frameBox.isMatrix = rawFrameBox.isMatrix;
        frameBox.volume = rawFrameBox.volume;
        frameBox.mute = rawFrameBox.mute;
        frameBox.aux = rawFrameBox.aux;
        frameBox.restricted = rawFrameBox.restricted;
        frameBox.transparent = rawFrameBox.transparent;
        frameBox.wipeOut = rawFrameBox.wipeOut;

        if (frameBox.view) {
            frameBox.view.setPosition(frameBox.coordinates);
        }

        this.emit('framebox.change.' + index, frameBox);
    },

    /**
     * Check if input field is focused.
     *
     * RELEASE-2686: close controlbar if text input is focused.
     */
    isInputTypeText: function(frameBox, rawFrameBox) {
        return (frameBox.options.inputType && rawFrameBox.options.inputType
            && (frameBox.options.inputType === 'notext' || frameBox.options.inputType === 'unknown')
            && (rawFrameBox.options.inputType !== 'notext' && rawFrameBox.options.inputType !== 'unknown'));
    },

    /**
     * Remove a framebox.
     *
     * @param index - The index of the framebox that should be removed.
     */
    removeFrameBox: function(index) {
        if (this.frameBoxes[index]) {
            if (this.frameBoxes[index].isFullscreen) {
                this.toggleFullscreenToAnotherFramebox();
            }

            this.hideLoader(index);
            delete this.isLoaded[index];

            this.removeFrameBoxView(this.frameBoxes[index]);
            this.callFrameBoxHandler(this.frameBoxes[index].contentType, 'remove', index);
            this.emit('framebox.removed', this.frameBoxes[index]);
            this.checkHasFrameboxes();

            delete this.frameBoxes[index];
        }
    },

    /**
     * Add the class 'has-open-frameboxes' to the main-element when one or more frameboxes are open.
     */
    checkHasFrameboxes: function() {
        if (0 === this.frameBoxService.getNumberOpenFrameboxes()) {
            app.$el.removeClass('has-open-frameboxes');
        } else {
            app.$el.addClass('has-open-frameboxes');
        }
    },

    /**
     * Toggle a fullscreen to a another existing framebox.
     * e.g. Called after a Framebox was removed in fullscreen.
     */
    toggleFullscreenToAnotherFramebox: function() {
        var i;

        for (i = 0; i < this.frameBoxes.length; i++) {
            if (this.frameBoxes[i]) {
                this.openFullscreen({
                    index: i
                });
            }
        }
    },

    /**
     * @method frameboxComingHandler
     */
    frameboxComingHandler: function() {
        this.focusNextFrameBox = true;
    },

    /**
     * Focuses the frambox with the given option if the value is a string it's enough
     * if the given string is part of the string in the options.
     *
     * @param {String} key - The key that sould be contained in the options.
     * @param {Mixed} value - The value that the given key should have.
     *
     * @return {Boolean} - True if a framebox was found, else false.
     */
    focusFrameboxWithOption: function(key, value) {
        var i;

        for (i = 0; i < this.frameBoxes.length; i++) {
            if (!this.frameBoxes[i]
                || !this.frameBoxes[i].options
                || !this.frameBoxes[i].options[key]
                || this.frameBoxes[i].options[key].indexOf(value) !== -1
            ) {
                continue;
            }

            if ((typeof value === 'string'
                && this.frameBoxes[i].options[key].indexOf(value) !== -1)
                || this.frameBoxes[i].options[key] === value
            ) {
                this.emit('framebox.focus', {
                    index: i
                });

                return true;
            }
        }

        return false;
    },

    /**
     * Creates a new framebox with the given coordinates.
     *
     * @param {Number} index the index of the framebox
     * @param {Object} rawFrameBox
     * @param {String} rawFrameBox.contentType
     * @param {Object} rawFrameBox.coordinates
     * @param {Boolean} rawFrameBox.fullscreen
     * @param {Object} rawFrameBox.options
     */
    createFrameBox: function(index, rawFrameBox) {
        var frameBox = {};
        var type = rawFrameBox.contentType;
        var configs;

        // Handle UVC Input. (show VZ controls if UVC Input is a Visualizer)
        if (rawFrameBox.contentType === 'webcam' && rawFrameBox.options.type === 'visualizer') {
            type = 'Visualizer';
        }

        configs = this.frameBoxService.getConfig(type);

        frameBox.index = index;
        frameBox.appId = rawFrameBox.appId;
        frameBox.titleKey = configs.titleKey;
        frameBox.titleIconKey = configs.titleIconKey;
        frameBox.contentType = rawFrameBox.contentType;
        frameBox.coordinates = rawFrameBox.coordinates;
        frameBox.isFullscreen = rawFrameBox.fullscreen;
        frameBox.webRtcOut = rawFrameBox.webRtcOut;
        frameBox.options = rawFrameBox.options;
        frameBox.volume = rawFrameBox.volume;
        frameBox.mute = rawFrameBox.mute;
        frameBox.aux = rawFrameBox.aux;
        frameBox.restricted = rawFrameBox.restricted;
        frameBox.transparent = rawFrameBox.transparent;
        frameBox.wipeOut = rawFrameBox.wipeOut;
        frameBox.component = configs.component;
        frameBox.editMode = configs.editMode;
        frameBox.customClose = configs.customClose;
        frameBox.isMatrix = rawFrameBox.contentType === 'matrix';

        return frameBox;
    },

    /**
     * Instantiate a framebox view class.
     *
     * @param {Object} frameBox
     * @param {Number} frameBox.index
     * @param {String} frameBox.contentType
     * @param {String} frameBox.titleKey
     * @param {String} frameBox.component
     * @param {Boolean} frameBox.editMode
     * @param {Object} frameBox.options
     */
    createFrameBoxView: function(frameBox) {
        var view = new FrameBoxView(this.app, {
            index: frameBox.index,
            appId: frameBox.appId,
            contentType: frameBox.contentType,
            titleKey: frameBox.titleKey,
            titleIconKey: frameBox.titleIconKey,
            component: frameBox.component,
            webRtcOut: frameBox.webRtcOut,
            customClose: frameBox.customClose,
            frameBoxOptions: frameBox.options,
            focus: this.focusNextFrameBox
        });

        if (this.focusNextFrameBox) {
            this.focusNextFrameBox = false;
        }

        view.setPosition(frameBox.coordinates);
        view.render({});

        return view;
    },

    /**
     * Adds the given framebox to the framebox container
     * @method appendFrameBoxView
     * @param {Mixed} frameBox the framebox that should be added
     */
    appendFrameBoxView: function(frameBox) {
        if (frameBox && frameBox.view) {
            frameBox.view.append(this.$el);
        }
    },

    /**
     * @method appendFrameBoxViews
     */
    appendFrameBoxViews: function() {
        // Fix a bug when annotation is closed for multiple clients.
        // Frameboxes are duplicated created.
        this.frameBoxes.forEach(function(frameBox) {
            if (this.frameBoxes[frameBox.index]) {
                this.removeFrameBox(frameBox.index);
            }
        }.bind(this));

        // Now add the frameboxes
        this.frameBoxes.forEach(function(frameBox) {
            frameBox.view = this.createFrameBoxView(frameBox);
            this.appendFrameBoxView(frameBox);
        }.bind(this));
    },

    /**
     * Destroy FrameBoxView.
     *
     * @param {Object} frameBox
     * @param {FrameBoxView} frameBox.view
     */
    removeFrameBoxView: function(frameBox) {
        if (frameBox && frameBox.view) {
            frameBox.view.destroy();
        }
    },

    /**
     * @method removeFrameBoxViews
     */
    removeFrameBoxViews: function() {
        this.frameBoxes.forEach(this.removeFrameBoxView.bind(this));
    },

    /**
     * Open fullscreen.
     */
    openFullscreenHandler: function() {
        this.removeFrameBoxViews();

        if (!this.fullScreenHeaderComponentId) {
            this.fullScreenHeaderComponentId = 'fullScreenHeader';

            this.fullScreenHeaderComponentId = this.createComponent({
                type: 'FrameBoxFullScreenUI',
                container: '.framebox-fullscreen-header-container',
                frameBoxes: this.frameBoxes
            });
        }
    },

    /**
     * Close Fullscreen.
     */
    closeFullscreenHandler: function() {
        this.app.removeComponent(this.fullScreenHeaderComponentId);
        this.fullScreenHeaderComponentId = null;
        this.focusNextFrameBox = false;

        this.appendFrameBoxViews();
    },

    /**
     * Send a open command to the device.
     *
     * @method openFrameBox
     * @param  {Object} data
     * @param  {String} data.frameBoxType
     * @param  {String|number|undefined} data.parameter
     */
    openFrameBox: function(data) {
        this.frameBoxService.openFrameBox(data.frameBoxType, data.parameter);
    },

    /**
     * Tell the device that we want to close a frame.
     *
     * @param  {Object} data
     * @param  {Number} data.index
     */
    closeFrameBox: function(data) {
        this.frameBoxService.closeFrameBox(data.index);
    },

    /**
     * Iterate over all frameboxes and close each defined one.
     */
    closeAllFrameBoxes: function() {
        this.frameBoxes.forEach(function(frameBox) {
            if (frameBox) {
                this.closeFrameBox({
                    index: frameBox.index
                });
            }
        }.bind(this));
    },

    /**
     * Tell the device that we want to have a framebox in fullscreen.
     *
     * @param  {Object} data
     * @param  {Number} data.index
     */
    openFullscreen: function(data) {
        if ((this.fullscreenFrameBox && this.fullscreenFrameBox.index === data.index)
            || this.stationService.getLockStatus()) {
            return;
        }

        this.frameBoxService.fullscreenFrameBox(data.index);
    },

    /**
     * Tell the device that we want to minimize a frame.
     *
     * @param {Object} data
     * @param {Number} data.index
     */
    minimizeFullscreen: function(data) {
        if (this.stationService.getLockStatus()) {
            return;
        }

        this.frameBoxService.minimizeFrameBox(data.index);

        setTimeout(function() {
            this.emit('framebox.focus', data);
        }.bind(this), 200);
    },

    /**
     * Tell the device that we want to toggle aux framebox.
     *
     * @param  {Object} data
     */
    toggleAuxFramebox: function(data) {
        this.frameBoxService.toggleAuxFrameBox(data.index);
    },

    /**
     * Call the framebox handler for the frame content type.
     *
     * @param {String} contentType
     * @param {String} handlerType
     * @param {Number} index
     */
    callFrameBoxHandler: function(contentType, handlerType, index) {
        var handler;

        if (handlerType === 'remove') {
            handler = this.frameBoxRemoveHandlers[contentType];
        } else if (handlerType === 'create') {
            handler = this.frameBoxCreateHandlers[contentType];
        }

        if (handler) {
            handler.call(this, index);
        }
    },

    /**
     * Destroy Frameboxes.
     */
    destroy: function() {
        this.frameBoxService.resetDataStore();
        this.liveStream.pause();
        this.liveStream.clear();

        if (!this.fullscreenFrameBox) {
            _.forEach(this.frameBoxes, function(framebox, id) {
                this.removeFrameBox(id);
            }.bind(this));
        }
    },

    /**
     * Collection of handlers which are called when a framebox is created.
     *
     * @type {Object}
     */
    frameBoxCreateHandlers: {
        'Visualizer': function() {
            this.emit('visualizer.state.change', {
                state: 'open'
            });
        },

        'HdmiIn': function() {
            this.emit('menu.item.disable', {
                type: 'HDMI'
            });
        }
    },

    /**
     * Collection of handlers which are called when a framebox is destroyed
     * @type {Object}
     */
    frameBoxRemoveHandlers: {
        'Visualizer': function() {
            this.emit('visualizer.state.change', {
                state: 'closed'
            });
        },

        'HdmiIn': function() {
            this.emit('menu.item.enable', {
                type: 'HDMI'
            });
        }
    }
});
