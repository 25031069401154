'use strict';

var app = require('../../../app');
var supportPinTpl = require('./support-pin.hbs');
var FormActionView = require('../../settings/form-action-view');
var Platform = require('../../../../platform/platform');
var pad = require('../../../helper').pad;
var _ = require('lodash');

app.component('SupportPin', {
    template: supportPinTpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.authenticationService = this.getService('AuthenticationService');
        this.remote = this.getService('RemoteService');

        this.authenticationService.supportPin = true;
        this.resetAlreadyCalled = false;
    },

    bindDOMEvents: function() {
        this.$document.on('keydown', _.throttle(this.onKeyDown.bind(this), 250));
    },

    bindEvents: function() {
        this.on('modal.opened-end', function() {
            this.remote.focus(this.form.get('new-pin').$el.get(0));
            this.form.get('new-pin').$el.click();
        }.bind(this));
    },

    storeSelectors: function() {
        this.$supportPinValidateContainer = this.$el.find('.input-group');
        this.$supportPin = this.$el.find('.pin-value');
        this.$document = this.$(document);
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.initForm();
        this.bindDOMEvents();
        this.bindEvents();
        this.initFormAction();

        // Center buttons
        this.$el.find('.btn-form').css('margin', '5px');
    },

    serialize: function() {
        var dfd = this.$.Deferred();

        this.load()
            .then(function() {
                dfd.resolve({
                    serial: this.serial,
                    pin: this.pin
                });
            }.bind(this), function() {
                dfd.resolve({
                    error: true
                });
            });

        return dfd.promise();
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#support-pin-form')
        });
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                submitTitleKey: 'modal.reset_password',
                cancelTitleKey: 'action_button.cancel',
                onSubmit: this.onNewPinHandler.bind(this),
                onCancel: this.onCancelHandler.bind(this),
                autoClose: false,
                navArea: false
            });
        }

        this.actionView.render();
        this.actionView.open();
    },

    /**
     * KeyDown handler
     * @param {jQuery.Event} event
     */
    onKeyDown: function(event) {
        var keyCode = typeof event.keyCode === 'undefined' ? event.which : event.keyCode;

        if (keyCode === this.remote.KEYCODES.ENTER
            && event.currentTarget.activeElement
            && event.currentTarget.activeElement.value !== 'Cancel') {
            this.hideValidationInfo();
            this.onNewPinHandler();
        }
    },

    /**
     * Trying to reset the password.
     */
    onNewPinHandler: function() {
        if (!this.resetAlreadyCalled) {
            this.resetAlreadyCalled = true;
            var pin = this.form.get('new-pin').getValue();

            this.deviceConnection
                .send('setSupportPin', {
                    pin: pin
                }).then(function() {
                    this.onUpdate();

                    // Reset after 250ms to prevent from multiple execution
                    setTimeout(function() {
                        this.resetAlreadyCalled = false;
                    }.bind(this), 250);
                }.bind(this));
        }
    },

    /**
     * Close Modal and return to Login.
     */
    onCancelHandler: function() {
        if (!Platform.checks.isCbox && this.authenticationService.getIsCollab()) {
            this.authenticationService.showLoginDialog('admin', true);
        } else if (typeof this.authenticationService.dataStorage.get('access') === 'undefined') {
            this.authenticationService.showLoginDialog('user', true);
        } else if (this.authenticationService.ldap) {
            this.authenticationService.supportPin = false;
            this.authenticationService.showLdapDialog();
        } else if (!Platform.checks.isCbox) {
            this.authenticationService.checkLoginModal();
        }

        this.emit('modal.close', {
            id: 'supportPin'
        });
    },

    /**
     * Load data.
     */
    load: function() {
        return this.deviceConnection
            .send([
                'getSupportPin',
                'getSerialNumber'
            ])
            .then(function(pin, serial) {
                this.serial = serial.serialNumber;
                this.pin = pin.pin.toString();

                this.pin = pad(this.pin, 8);
            }.bind(this),
            function() {
                this.error = true;
            });
    },

    /**
     * Update Reset status.
     */
    onUpdate: function() {
        this.deviceConnection
            .send([
                'getSupportPin'
            ])
            .then(function(pin) {
                this.pin = pin.pin.toString();
                this.pin = pad(this.pin, 8);

                if (this.pin !== this.$supportPin.text()) {
                    this.$supportPin.text(this.pin);
                }

                if (pin.status === 1) {
                    this.showError();
                } else if (pin.status === 0) {
                    this.hideError();

                    if (this.authenticationService.getIsCollab()) {
                        this.onCancelHandler();
                    } else {
                        this.emit('overlay.open', {
                            id: 'login-settings',
                            extendedConfigs: 'settings-list',
                            animation: 'fade'
                        });
                    }

                    this.emit('modal.close', {
                        id: 'supportPin'
                    });

                    if (!Platform.checks.isCbox) {
                        this.authenticationService.checkLoginModal();
                    }
                }
            }.bind(this));
    },

    hideValidationInfo: function() {
        this.$supportPinValidateContainer
            .removeClass('is-invalid')
            .removeClass('is-valid');
    },

    /**
     * Show password reset error.
     */
    showError: function() {
        this.$supportPinValidateContainer
            .removeClass('is-valid')
            .addClass('is-invalid');
    },

    /**
     * Show success message.
     */
    hideError: function() {
        this.$supportPinValidateContainer
            .removeClass('is-invalid')
            .addClass('is-valid');
    },

    /**
     * Destroy.
     */
    destroy: function() {
        this.authenticationService.supportPin = false;
        this.$document.off('keydown');
    }
});
