'use strict';

const _ = require('lodash');
const states = require('../../states');

module.exports.namespaced = true;

module.exports.state = {
    matrixEnabled: false,
    commandButtonsEnabled: false,
    commandButtonsHdmi2: false,
    groupworkActive: false,
    activeTemplateId: -1,
    shareToAllEnabled: false,
    currentGroups: [],
    stations: [],
    matrixStatus: []
};

module.exports.mutations = {
    SET_MATRIX_ENABLED(state, enabled) {
        state.matrixEnabled = enabled;
    },
    SET_COMMAND_BUTTON_SETTINGS(state, settings) {
        state.commandButtonsEnabled = settings.enabled;
        state.commandButtonsHdmi2 = settings.showHdmi2;
    },
    SET_ACTIVE_TEMPLATE_ID(state, activeTemplateId) {
        state.activeTemplateId = activeTemplateId;
    },
    SET_MATRIX_STATUS(state, matrixStatus) {
        state.matrixStatus = matrixStatus;
    },
    SET_CURRENT_TEMPLATE_SETTINGS(state, settings) {
        state.shareToAllEnabled = settings.shareToAllEnabled;
        state.currentGroups = settings.groups;
        state.stations = settings.stations;
    },
    SET_GROUPWORK_ACTIVE(state, active) {
        state.groupworkActive = active;
    }
};

module.exports.actions = {
    setMatrixEnabled({ commit }, enabled) {
        commit('SET_MATRIX_ENABLED', enabled);
    },
    setCommandButtonSettings({ commit }, settings) {
        commit('SET_COMMAND_BUTTON_SETTINGS', settings);
    },
    setActiveTemplateId({ commit }, activeTemplateId) {
        commit('SET_ACTIVE_TEMPLATE_ID', activeTemplateId);
    },
    setMatrixStatus({ commit, state }, matrixStatus) {
        if (!_.isEqual(state.matrixStatus, matrixStatus)) {
            commit('SET_MATRIX_STATUS', matrixStatus);
        }
    },
    setTemplateSettings({ commit, state }, settings) {
        // Only change state when array truly changed (deep comparison) to reduce unnecessary state changes
        if (!_.isEqual(state.currentGroups, settings.groups)
            || !_.isEqual(state.stations, settings.stations)
            || state.shareToAllEnabled !== settings.shareToAllEnabled) {
            commit('SET_CURRENT_TEMPLATE_SETTINGS', settings);
        }
    },
    setGroupworkActive({ commit }, active) {
        commit('SET_GROUPWORK_ACTIVE', active);
    }
};

module.exports.getters = {
    // Get all groups containing at least one switched on station
    getCurrentGroups: (state, getters) => {
        return state.currentGroups.filter(group => group.shareToGroupEnabled
            && getters.getStationsFromGroup(group.id).some(station =>
                state.matrixStatus.some(stationStatus =>
                    stationStatus.serial === Number(station.serial) && stationStatus.present === 1)));
    },
    getStationsFromGroup: state => groupId => {
        return state.stations.filter(station => station.group !== '' && Number(station.group) === groupId);
    },
    getPushableStationsFromGroup: (state, getters) => groupId => {
        return getters.getStationsFromGroup(groupId).filter(station =>
            state.matrixStatus.some(stationStatus =>
                stationStatus.serial === Number(station.serial)
                && stationStatus.present === 1 && stationStatus.pull === 0));
    },
    getShowCommandButtons: state => output => {
        return state.matrixEnabled && state.commandButtonsEnabled && !state.groupworkActive
            && state.activeTemplateId !== -1 && state.stations.length > 0
            && !(!state.commandButtonsHdmi2 && output === 'hdmi2');
    },
    isShareToAllEnabled: state => {
        return state.shareToAllEnabled;
    },
    isShareToAllActive: state => output => {
        return state.matrixStatus.every(station =>
            station.pushIndex === states.pushIndexStates[output] && station.push === 1);
    },
    // Check if all stations of a group have a push active
    isShareToGroupActive: (state, getters) => (output, groupId) => {
        return getters.getStationsFromGroup(groupId).every(station =>
            state.matrixStatus.some(stationStatus =>
                stationStatus.serial === Number(station.serial)
                && stationStatus.pushIndex === states.pushIndexStates[output] && stationStatus.push === 1));
    },
    isPushActive: state => output => {
        return state.matrixStatus.some(station =>
            station.pushIndex === states.pushIndexStates[output] && station.push === 1);
    }
};
