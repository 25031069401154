'use strict';

var app = require('../../../../app');
var platform = require('../../../../../platform/platform');
var $ = require('jquery');

var controlItems = [
    {
        id: 'mute',
        type: 'mute',
        state: 'mute'
    },
    {
        id: 'volume',
        type: 'volume'
    }
];

/**
 * Web Conference/WebRTC
 */
app.component('FrameBoxWebconferenceViewer', {
    extend: 'FrameBoxBaseType',
    className: 'framebox-view framebox-webconference-view',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.hasClicked = true;
        this.openTimeout = null;
        this.clickTimeout = null;
        this.frameBoxService = this.getService('FrameBoxService');
        this.volumeService = this.getService('VolumeService');
        this.liveStream = this.getService('LiveStreamService');
        this.deviceService = this.getService('DeviceService');
        this.storage = this.getService('DataStorage');
        this.remote = this.getService('RemoteService');
        this.webconferenceService = this.getService('WebconferenceService');
        this.componentId = null;
        this.prevInputValue = null;
        this.options.allowedEvents = ['touchstart', 'touchend', 'mousedown', 'mouseup', 'touchmove', 'mousemove'];
    },

    serialize: function() {
        return {
            isCbox: platform.checks.isCbox
        };
    },

    postPlaceAt: function() {
        this.startKeyboard();
        this.startControls();
        this.bindEvents();
        this.startTouchEventsEntity({
            $actionEl: this.options.$actionEl,
            allowedEvents: this.options.allowedEvents,
            disableSelection: false,
            debugging: false
        });

        this.onFrameboxChanged({
            contentType: this.frameBoxService.contentTypeMapping(this.options.type),
            options: this.options.frameBoxOptions
        });

        if (window.navigator.standalone) {
            this.options.$actionEl.addClass('hide-keyboard-btn');
        }

        this.updateTransparentState({
            index: this.options.index,
            transparent: this.frameBoxService.isTransparent(this.options.index)
        });

        this.volumeService.updateMicStatusWidgetHandler();
    },

    bindEvents: function() {
        this.on('framebox.change.' + this.index, this.onFrameboxChanged.bind(this));

        this.on('framebox.standard.keydown', this.keyDownHandler.bind(this));
        this.on('framebox.standard.keyup', this.keyUpHandler.bind(this));

        this.on('framebox.transparent.update', this.updateTransparentState.bind(this));

        this.on('active-framebox.changed', this.activeFrameboxChanged.bind(this));
    },

    updateTransparentState: function(options) {
        if (options.index !== this.options.index || platform.checks.isCbox) {
            return;
        }

        this.transparent = options.transparent;
    },

    /**
     * Initialize Keyboard after active framebox changed
     *
     * @param index framebox index
     * @param keyboardAction focus is on an input field --> update keyboard
     */
    activeFrameboxChanged: function(index, component, keyboardAction) {
        if (index === this.options.index) {
            $(document).find('[data-content-type="webconference"] .framebox-standard-header')
                .attr('data-position', this.webconferenceService.controlBarPosition);
            $(document).find('[data-content-type="webconference"] .framebox-fullscreen-header')
                .attr('data-position', this.webconferenceService.controlBarPosition);

            $(document).find('[data-content-type="webconference"] .framebox-icon-button')
                .attr('data-position', this.webconferenceService.controlBarPosition);

            if (!this.keyboard) {
                this.startKeyboard();

                this.onFrameboxChanged({
                    contentType: this.frameBoxService.contentTypeMapping(this.options.type),
                    options: this.options.frameBoxOptions
                });

                return;
            }

            if (keyboardAction) {
                this.keyboardAction('update', this.box);
            }
        }
    },

    /**
     * @param {Object} box
     */
    onFrameboxChanged: function(box) {
        this.box = box;
        clearTimeout(this.clickTimeout);

        if (this.keyboardAction) {
            this.keyboardAction('update', this.box);
        }
    },

    /**
     * @param {Object} event
     * @param {Number} index
     */
    keyDownHandler: function(event, index) {
        this.keyEventHandler(index, 'keydown', event);
    },

    /**
     * @param {Object} event
     * @param {Number} index
     */
    keyUpHandler: function(event, index) {
        this.keyEventHandler(index, 'keyup', event);
    },

    /**
     * @param {Number} index
     * @param {String} keyEvent
     * @param {Object} event
     */
    keyEventHandler: function(index, keyEvent, event) {
        if (index === this.index && !this.transparent) {
            this.frameBoxService.sendKeyEvent(this.options.index, keyEvent, event);
        }
    },

    /**
     * Checks the keyboard state.
     */
    canOpenKeyboard: function() {
        if ((
            platform.checks.isTouchDevice
                && this.box.options.requestKeyboard
                && this.box.options.requestKeyboard
        ) || (
            platform.checks.isCbox
                && this.remote.isTouchKeyboardEnabled()
                && this.box.options.requestKeyboard
                && this.box.options.requestKeyboard
        )
        ) {
            return true;
        }

        return false;
    },

    startControls: function() {
        this.componentId = this.createComponent({
            type: 'FrameBoxControls',
            container: this.$el,
            index: this.options.index,
            appId: this.options.appId,
            items: controlItems,
            onClick: this.onControlClickHandler.bind(this),
            isFullscreen: this.options.isFullscreen
        });
    },

    onControlClickHandler: function(id) {
        var handler = this.controlHandlers[id];

        if (handler) {
            handler.call(this, {
                index: this.index
            });
        }
    },

    /**
     * Start Keyboard Component.
     */
    startKeyboard: function() {
        this.keyboard = this.createComponent({
            type: 'FrameboxKeyboard',
            container: this.$el,
            index: this.options.index,
            action: function(fn) {
                this.keyboardAction = fn;
            }.bind(this)
        });
    },

    destroy: function() {
        if (this.keyboardAction) {
            this.keyboardAction('close');
        }

        this.$('html, body').scrollTop(0);
        this.$(document).off('mousedown.keyboard-' + this.index);
        this.$(document).off('touchstart.keyboard-' + this.index);

        this.volumeService.updateMicStatusWidgetHandler();
    }
});
