'use strict';

var app = require('../../../app');
var deviceListTpl = require('./discovery-device-list.hbs');
var $ = require('jquery');
var d3 = require('d3');
var _ = require('lodash');
var platform = require('./../../../../platform/platform');
var StateMachine = require('../../../state-machine');

var actions = {
    none: 'none',
    dragstart: 'dragstart',
    drag: 'drag'
};

app.component('DiscoveryDeviceList', {
    template: deviceListTpl,

    getAccessKey: function() {
        return {
            'roleName': 'DiscoveryBox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.matrixService = this.getService('MatrixService');
        this.matrixConfigService = this.getService('MatrixConfiguratorService');
        this.configs = this.getService('MatrixConfigs');
        this.drag = {
            x: 0,
            y: 0
        };

        this.action = new StateMachine({
            context: this,
            state: actions.none,
            states: actions
        });
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();
        this.bindDOMEvents();
    },

    storeSelectors: function() {
        this.$container = this.$el.parent();
        this.$svg = this.$el.find('#svg-grid-devices');
        this.svg = d3.select(this.$svg[0]);
        this.stationForm = this.options.stationForm;
    },

    bindEvents: function() {
        this.on('matrix.discovery-devices.set.form', this.setStationForm.bind(this));
    },

    bindDOMEvents: function() {
        var devices = this.svg.selectAll('.svg-grid-device')._groups[0];

        this.svg.selectAll('.svg-grid-device')
            .data(devices)
            .call(d3.drag()
                .on('start', this.handleDragStart.bind(this))
                .on('drag', this.handleDrag.bind(this))
                .on('end', this.handleDragEnd.bind(this)));
    },

    serialize: function() {
        var dfd = $.Deferred();
        this.startDeviceDiscovery(true)
            .then(function() {
                dfd.resolve({
                    devices: this.devices,
                    width: this.width,
                    height: this.height,
                    itemWidth: parseInt(this.configs.get('matrixBox.itemWidth')),
                    itemHeight: parseInt(this.configs.get('matrixBox.itemHeight'))
                });
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Start device discovery.
     *
     */
    startDeviceDiscovery: function() {
        var dfd = $.Deferred();

        $.when(this.matrixService.getDevices(this), this.matrixService.getInputStreams(this))
            .then(function(data, inputStreams) {
                var configDevices = this.matrixConfigService.getConfigDeviceIds();
                var streams = this.filterInputStreams(configDevices, inputStreams, 0);
                var receiver = this.filterPureReceiver(configDevices, data.devices, streams.height);
                var stations = this.filterStations(configDevices, data.devices, (streams.height + receiver.height));

                this.devices = [
                    streams,
                    receiver,
                    stations
                ];
                this.width = parseInt(this.configs.get('matrixBox.itemWidth'));
                this.height = streams.height + receiver.height + stations.height;
                this.$el.parent().css('height', this.height + 'px');

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Handle pan event.
     *
     */
    onPan: function() {
        var scrollY;
        var scrollTop = this.$container.scrollTop();

        scrollY = d3.event.sourceEvent.touches.length > 0 ? (d3.event.y - this.initY)  * -1 : 0;

        this.$container.scrollTop(scrollTop + scrollY);
    },

    /**
     * Handle drag start event.
     *
     * @param d element/device dragged.
     */
    handleDragStart: function(d) {
        d3.select(d).attr('cx', d3.event.x).attr('cy', d3.event.y);

        if (!platform.checks.isIOS13) {
            d3.select(d).raise().classed('active', true);
        }

        this.action = actions.dragstart;
        this.scrollCounterStart = true;
    },

    /**
     * Handle drag event and highlight nearest grid element/point.
     *
     * @param d element/device dragged.
     */
    handleDrag: function(d) {
        if (this.drag.x <= 30 && this.drag.y <= 30) {
            if (this.scrollCounterStart) {
                this.initY = d3.event.y;
                this.scrollCounterStart = false;
            }

            this.drag.x = this.drag.x + Math.abs(d3.event.dx);
            this.drag.y = this.drag.y + Math.abs(d3.event.dy);

            return;
        }

        if (platform.checks.isTouchDevice && this.drag.y > this.drag.x) {
            this.onPan();

            return;
        }

        if (this.action === actions.dragstart) {
            this.action = actions.none;

            this.hide(false);
            this.matrixConfigService.onDragDeviceStart(d, this.stationForm);
        }

        d3.select(d).attr('cx', d3.event.x).attr('cy', d3.event.y);

        this.matrixConfigService.onDragDevice(d);
        this.action = actions.drag;
    },

    /**
     * Handle drag end event and call drop device event for creating station.
     *
     * @param d element/device dragged.
     */
    handleDragEnd: function(d) {
        if (this.action === actions.drag) {
            d3.select(d).attr('cx', d3.event.x).attr('cy', d3.event.y);
            d3.select(d).classed('active', false);

            this.matrixConfigService.onDragDeviceEnd(d);
        }

        this.action = actions.none;
        this.drag.x = 0;
        this.drag.y = 0;
        this.initY = 0;
    },

    /**
     * Set current station desk form.
     *
     * @param form 'rectangle' or 'circle'
     */
    setStationForm: function(form) {
        this.stationForm = form;
    },

    /**
     * GROUP: STREAMS
     * Check..
     * .. if input streams are already configured and filter if necessary.
     *
     * @param configDevices Already configured devices
     * @param inputStreams Found Input Streams by the device discovery
     * @param yPos Current y position in SVG
     * @returns {Array} Filtered input streams.
     */
    filterInputStreams: function(configDevices, inputStreams, yPos) {
        var streams = {
            title: {},
            height: 0,
            items: []
        };

        var x0 = 0; // Discovery box position
        var y0 = yPos + parseInt(this.configs.get('matrixBox.itemHeight')) * (-1); // Discovery box position

        streams.title = {
            text: 'matrix_configurator.input_streams',
            pos: {
                x: x0,
                y: y0 += parseInt(this.configs.get('matrixBox.itemHeight'))
            }
        };

        var stream = configDevices.find(function(item) {
            return item.startsWith('stream');
        }.bind(this));

        if (!stream || stream.length === 0) {
            _.each(inputStreams, function(input) {
                if (input.mode !== 'none') {
                    input.pos = {
                        x: x0,
                        y: y0 += parseInt(this.configs.get('matrixBox.itemHeight'))
                    };
                    input.devname = input.name;
                    input.id = 'stream' + input.index;
                    input.ip = input.url;

                    input.icon = this.matrixService.getStreamIcon(input.type);

                    streams.items.push(input);
                }
            }.bind(this));
        }

        if (streams.items.length > 0) {
            streams.height = y0 + parseInt(this.configs.get('matrixBox.itemHeight')) - yPos;
        }

        return streams;
    },

    /**
     * GROUP: PURE RECEIVER
     * Check..
     * .. if devices are already configured and filter if necessary.
     * .. if device is a pure receiver (CPR)
     * .. if device has Matrix support
     * .. if device is Matrix master (master mode)
     * .. if device is a Matrix station (station mode)
     * (not necessarily configured in backend!!)
     *
     * @param configDevices Already configured devices
     * @param devices Found devices by the device discovery
     * @param yPos Current y position in SVG
     * @returns {Array} Filtered stations.
     */
    filterPureReceiver: function(configDevices, devices, yPos) {
        var receiver = {
            title: {},
            height: 0,
            items: []
        };

        var x0 = 0; // Discovery box position
        var y0 = yPos + parseInt(this.configs.get('matrixBox.itemHeight')) * (-1); // Discovery box position

        receiver.title = {
            text: 'matrix_configurator.pure_receiver',
            pos: {
                x: x0,
                y: y0 += parseInt(this.configs.get('matrixBox.itemHeight'))
            }
        };

        _.each(devices, function(device) {
            if (!configDevices.includes(device.id) && device.model === 'CPR' && device.matrixSupport && !device.masterMode) {
                device.pos = {
                    x: x0,
                    y: y0 += parseInt(this.configs.get('matrixBox.itemHeight'))
                };
                device.icon = 'icon-v3-pure-receiver';
                receiver.items.push(device);
            }
        }.bind(this));

        if (receiver.items.length > 0) {
            receiver.height = y0 + parseInt(this.configs.get('matrixBox.itemHeight')) - yPos;
        }

        return receiver;
    },

    /**
     * GROUP: STATIONS (without Receiver)
     * Check..
     * .. if devices are already configured and filter if necessary.
     * .. if device isn't a pure receiver (CPR; different group)
     * .. if device has Matrix support
     * .. if device is Matrix master (master mode)
     * .. if device is a Matrix station (station mode)
     * (not necessarily configured in backend!!)
     *
     * @param configDevices Already configured devices
     * @param devices Found devices by the device discovery
     * @param yPos Current y position in SVG
     * @returns {Array} Filtered stations.
     */
    filterStations: function(configDevices, devices, yPos) {
        var stations = {
            title: {},
            height: 0,
            items: []
        };

        var x0 = 0; // Discovery box position
        var y0 = yPos + parseInt(this.configs.get('matrixBox.itemHeight')) * (-1); // Discovery box position

        stations.title = {
            text: 'matrix_configurator.stations',
            pos: {
                x: x0,
                y: y0 += parseInt(this.configs.get('matrixBox.itemHeight'))
            }
        };
        _.each(devices, function(device) {
            if (!configDevices.includes(device.id) && device.model !== 'CPR' && device.matrixSupport && !device.masterMode) {
                device.pos = {
                    x: x0,
                    y: y0 += parseInt(this.configs.get('matrixBox.itemHeight'))
                };
                device.icon = 'icon-device';
                stations.items.push(device);
            }
        }.bind(this));

        if (stations.items.length > 0) {
            stations.height = y0 + parseInt(this.configs.get('matrixBox.itemHeight')) - yPos;
        }

        return stations;
    },

    hide: function(animate) {
        this.emit('matrix-box.hide', animate);
    }

});
