'use strict';

var app = require('../../app');
var _ = require('lodash');
var $ = require('jquery');
var meetingTpl = require('./meeting-list.hbs');
var dateLib = require('../../date.js');
var i18n = require('i18next');
var LayoutManager = require('../../../layout/layout');

const connectionStates = require('../../states').connectionStates;

var timeTexts = {
    now: 'session_management.now',
    next: 'session_management.next',
    on: 'session_management.on'
};

app.component('MeetingList', {
    template: meetingTpl,
    className: 'full-height',

    getAccessKey: function() {
        return {
            'roleName': 'vMeeting',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.sessionMgmtService = this.getService('SessionManagementService');

        this.meetings = [];
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();
        this.bindDOMEvents();

        this.initMeetings();
        this.checkVisibleMeetings();
    },

    bindEvents: function() {
        this.on('session-mgmt.meeting-list.scroll', _.debounce(this.scroll.bind(this), 300, true));
        this.on('main-loop.update', this.checkConnectionState.bind(this));

        LayoutManager.on('window.resize', this.checkVisibleMeetings.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.session-management-btn', this.onAction.bind(this));
    },

    storeSelectors: function() {
        this.$meetingListContainer = this.$el.find('#meeting-list-container');
        this.$checkInButton = this.$el.find('#session-management-check-in-btn');
        this.$adHocButton = this.$el.find('#session-management-ad-hoc-btn');
        this.$noMeetingsContainer = this.$el.find('#session-management-meeting-none');
    },

    serialize: function() {
        var dfd = $.Deferred();

        this.fetchData(dfd);

        return dfd.promise();
    },

    fetchData: function(dfd) {
        this.sessionMgmtService.updateHandler()
            .then(function() {
                this.initMeetings();

                dfd.resolve({
                    meetings: this.meetings,
                    darkUi: this.sessionMgmtService.darkUi
                });
            }.bind(this));
    },

    /**
     * Init meetings for the meeting list.
     * Fetch from Session Management Service, add correct title, date and other info.
     */
    initMeetings: function() {
        var dateFormat = this.sessionMgmtService.getDateFormat();
        var meetingList = this.sessionMgmtService.getMeetings();
        var meetings = [];
        var i = 0;
        var start;
        var end;

        _.each(meetingList, function(meeting) {
            const utcStart = new Date(meeting.start * 1000);
            const utcEnd = new Date(meeting.end * 1000);
            start = new Date(utcStart.getUTCFullYear(), utcStart.getUTCMonth(), utcStart.getUTCDate(),
                utcStart.getUTCHours(), utcStart.getUTCMinutes()); // Already converted to correct time zone (BE)
            end = new Date(utcEnd.getUTCFullYear(), utcEnd.getUTCMonth(), utcEnd.getUTCDate(),
                utcEnd.getUTCHours(), utcEnd.getUTCMinutes()); // Already converted to correct time zone (BE)

            if (!this.sessionMgmtService.startsToday(meeting.start) || meeting.id === 'Ad-Hoc-Meeting') {
                return;
            }

            meetings.push({
                index: i,
                meetingId: meeting.id,
                timeText:
                    (
                        i === 0
                            ? i18n.t(timeTexts.now)
                            : (
                                i === 1
                                    ? i18n.t(timeTexts.next)
                                    : i18n.t(timeTexts.on)
                            )
                    ).toUpperCase(),
                startDate: dateLib.formatDate(
                    dateFormat,
                    start
                ),
                startTime: dateLib.formatTime(
                    dateFormat,
                    {
                        hour: start.getHours(),
                        minute: start.getMinutes()
                    },
                    false),
                endTime: dateLib.formatTime(
                    dateFormat,
                    {
                        hour: end.getHours(),
                        minute: end.getMinutes()
                    },
                    false),
                endDate: dateLib.formatDate(
                    dateFormat,
                    end
                ),
                title: meeting.subject,
                organizer: meeting.private || !meeting.organizer ? '' : meeting.organizer.name,
                private: meeting.private || !meeting.organizer,
                current: i === 0,
                hidden: !(i < 2) // Only show the first 2 meetings
            });

            meetings[i].crossDay = meetings[i].startDate !== meetings[i].endDate;

            i++;
        }.bind(this));

        this.meetings = meetings;

        // No meetings today
        if (this.meetings.length === 0) {
            this.sessionMgmtService.setFocusedMeetingIndex(-1);

            return;
        }

        // Set first meeting as current meeting.
        this.sessionMgmtService.setFocusedMeetingIndex(0);
    },

    /**
     * Scroll in meeting list.
     *
     * @param action 'now/'up'/'down'
     */
    scroll: function(action) {
        var focusedIndex = this.sessionMgmtService.getFocusedMeetingIndex();

        switch (action) {
            case 'now':
                if (this.sessionMgmtService.getFocusedMeetingIndex() <= 0) {
                    return; // No scrolling
                }

                this.handleScroll(
                    0,
                    this.$el.find('#session-management-meeting-0'),
                    this.$el.find('#session-management-meeting-1'));
                break;
            case 'up':
                if (this.sessionMgmtService.getFocusedMeetingIndex() <= 0) {
                    return; // No scrolling
                }

                this.handleScroll(
                    focusedIndex - 1,
                    this.$el.find('#session-management-meeting-' + (focusedIndex - 1)),
                    this.$el.find('#session-management-meeting-' + focusedIndex));
                break;
            case 'down':
                var lastVisibleEl = this.$el.find('.session-management-meeting:not(.hidden)').last();

                if ((lastVisibleEl.data('index') + 1) >= this.meetings.length) {
                    return; // No scrolling
                }

                this.handleScroll(
                    focusedIndex + 1,
                    this.$el.find('#session-management-meeting-' + (focusedIndex + 1)),
                    this.$el.find('#session-management-meeting-' + (focusedIndex + 2)));
                break;
        }
    },

    /**
     * Handle meeting list scrolling.
     *
     * @param firstIndex index of firstEl
     * @param firstEl New meeting on the top
     * @param secondEl New 2. meeting on the top
     */
    handleScroll: function(firstIndex, firstEl, secondEl) {
        this.$meetingListContainer.find('.session-management-meeting')
            .removeClass('current')
            .addClass('hidden');

        // First (focused) meeting to show
        firstEl
            .addClass('current')
            .removeClass('hidden');

        // Second meeting to show
        secondEl.removeClass('hidden');

        this.sessionMgmtService.setFocusedMeetingIndex(firstIndex);
        this.checkVisibleMeetings();
    },

    /**
     * Handle action buttons.
     *
     * @param event
     */
    onAction: function(event) {
        var $el = this.$(event.currentTarget);
        var action = $el.data('action');
        var index = $el.data('index');

        let currentMeeting = this.getCurrentMeeting(index);

        switch (action) {
            case 'check-in':
                this.deviceConnection
                    .send('getVMeetingPin')
                    .then(function(pin) {
                        if (pin.enabled) {
                            this.emit('session-mgmt.meeting-check-in.open', currentMeeting);
                        } else {
                            this.checkInWithoutPin(currentMeeting);
                        }
                    }.bind(this));
                break;
            case 'ad-hoc':
                this.emit('session-mgmt.meeting-ad-hoc.open');
                break;
        }
    },

    /**
     * Get current meeting.
     *
     * @param index Index of focused meeting.
     * @returns {string}
     */
    getCurrentMeeting: function(index) {
        var meeting = $.grep(this.meetings, function(m) {
            return m.index === index;
        });

        return meeting.length === 0 ? undefined : meeting[0];
    },

    /**
     * Check service connection state.
     * Do not show meeting list containers if service is not connected.
     * Show 'Start Ad Hoc Meeting' if service is connected and when there is enough time left before the next meeting.
     */
    checkConnectionState: function() {
        switch (this.sessionMgmtService.connectionState.currentState) {
            case connectionStates.connected:
                this.$meetingListContainer.removeClass('hidden');
                // No meetings today
                if (this.meetings.length === 0 || (this.meetings.length === 1 && this.meetings[0].id === 'Ad-Hoc-Meeting')) {
                    this.$noMeetingsContainer.removeClass('hidden');
                    this.sessionMgmtService.setFocusedMeetingIndex(-1);
                }

                if (this.sessionMgmtService.checkStartAdHoc()) {
                    this.$adHocButton.removeClass('hidden');
                } else {
                    this.$adHocButton.addClass('hidden');
                }
                break;
            default:
                this.$meetingListContainer.addClass('hidden');
                this.$noMeetingsContainer.addClass('hidden');
                this.$adHocButton.addClass('hidden');
        }
    },

    /**
     * Check which meetings should be shown depending on the space left on screen.
     */
    checkVisibleMeetings: function() {
        var focusedIndex = this.sessionMgmtService.getFocusedMeetingIndex();

        var $firstEl = this.$el.find('#session-management-meeting-' + focusedIndex);
        var $secondEl = this.$el.find('#session-management-meeting-' + (focusedIndex + 1));
        var visibleElHeight = ($firstEl.outerHeight() || 0) + ($secondEl.outerHeight() || 0);

        if ($secondEl.length > 0 && this.$meetingListContainer.height() < visibleElHeight) {
            $secondEl.addClass('hidden');
            this.meetings[focusedIndex + 1].hidden = true;
        } else if ($secondEl.length > 0) {
            $secondEl.removeClass('hidden');
            this.meetings[focusedIndex + 1].hidden = false;
        }

        this.updateMeetingList();
        this.emit('session-mgmt.meeting-list.focus.changed', this.meetings);
    },

    /**
     * Update Meeting List.
     */
    updateMeetingList: function() {
        _.each(this.meetings, function(m) {
            var $el = this.$el.find('#session-management-meeting-' + m.index);

            this.meetings[m.index].hidden = $el.hasClass('hidden');
        }.bind(this));
    },

    /**
     * Check-in meeting without PIN.
     * PIN must be disabled.
     *
     * @param meeting
     * @return {*}
     */
    checkInWithoutPin: function(meeting) {
        this.$checkInButton.addClass('busy');
        this.$checkInButton.prop('disabled', true);

        return this.deviceConnection
            .send('setVMeetingStartSession', {
                id: meeting.meetingId,
                pin: ''
            }).then(function() {
                // If check-in takes too long reset check-in button
                this.timeoutHandle = setTimeout(function() {
                    this.$checkInButton.removeClass('busy');
                    this.$checkInButton.prop('disabled', false);
                }.bind(this), 10000);
            }.bind(this), this.handleCheckInFailed.bind(this));
    },

    /**
     * When check-in fails reset check-in button and show error message.
     */
    handleCheckInFailed: function() {
        this.$checkInButton.removeClass('busy');
        this.$checkInButton.prop('disabled', false);

        app.emit('frontend-osd-message.show', {
            osdMessage: i18n.t('session_management.check_in_failed'),
            icon: 'vmeeting'
        });
    },

    /**
     * Destroy.
     */
    destroy: function() {
        if (this.timeoutHandle) {
            clearTimeout(this.timeoutHandle);
            this.timeoutHandle = null;
        }
    }
});
