// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row settings-list-secundary\"\n                    data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                    data-nav-trapped\n                    data-nav-area-default>\n                    <div class=\"col-xs-6\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.matrix_control_mode_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":87,"column":48},"end":{"line":87,"column":91}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input name=\"matrixControlMode\"\n                                id=\"matrixControlMode\"\n                                type=\"checkbox\"\n                                class=\"btn-switch\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"row settings-list-secundary limited-access-container\"\n                    data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                    data-nav-trapped\n                    data-nav-area-default>\n                    <div class=\"col-xs-6\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.matrix_limited_access",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":106,"column":48},"end":{"line":106,"column":86}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input name=\"matrixLimitedAccess\"\n                                id=\"matrixLimitedAccess\"\n                                type=\"checkbox\"\n                                class=\"btn-switch\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row settings-list-secundary\"\n                    data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                    data-nav-trapped\n                    data-nav-area-default>\n                    <div class=\"col-xs-6\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.matrix_command_buttons_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":146,"column":48},"end":{"line":146,"column":94}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input name=\"matrixCommandButtons\"\n                                id=\"matrixCommandButtons\"\n                                type=\"checkbox\"\n                                class=\"btn-switch\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-secundary command-buttons-hdmi2-container\"\n                    data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                    data-nav-trapped\n                    data-nav-area-default>\n                    <div class=\"col-xs-6\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.matrix_command_buttons_hdmi2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":166,"column":48},"end":{"line":166,"column":93}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input name=\"matrixCommandButtonsHdmi2\"\n                                id=\"matrixCommandButtonsHdmi2\"\n                                type=\"checkbox\"\n                                class=\"btn-switch\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row settings-list-secundary matrix-not-supported\">\n                    <div class=\"col-xs-12\">\n                        <p>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"matrix.not_supported",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":246,"column":27},"end":{"line":246,"column":55}}}))
    + "</p>\n                    </div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"matrix-settings\" name=\"matrix-settings\">\n        <div class=\"row settings-list-row\"\n             data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n             data-nav-trapped\n             data-nav-area-default>\n            <div class=\"col-xs-8\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.matrix_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":50}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"matrixEnabled\"\n                           id=\"matrixEnabled\"\n                           type=\"checkbox\"\n                           class=\"btn-switch\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"matrix-settings-main-container\">\n            <div class=\"row settings-list-secundary matrix-auto-start\"\n                 data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.matrix_auto_start",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":44},"end":{"line":29,"column":78}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-6\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"matrixAutoStart\"\n                               id=\"matrixAutoStart\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary matrix-auto-open-file\"\n                 data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.matrix_auto_open_file",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":44},"end":{"line":48,"column":82}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-6\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"matrixAutoOpenFile\"\n                               id=\"matrixAutoOpenFile\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary matrix-collab-enable\"\n                 data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.matrix_collab_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":44},"end":{"line":67,"column":81}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-6\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"matrixCollabEnable\"\n                               id=\"matrixCollabEnable\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDualProjection") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":12},"end":{"line":120,"column":23}}})) != null ? stack1 : "")
    + "            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.matrix_groupwork_mode_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":126,"column":44},"end":{"line":126,"column":89}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-6\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"matrixGroupworkMode\"\n                               id=\"matrixGroupworkMode\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDualProjection") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":140,"column":12},"end":{"line":180,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.matrix_ignore_templates",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":186,"column":44},"end":{"line":186,"column":84}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-6\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"matrixIgnoreTemplates\"\n                               id=\"matrixIgnoreTemplates\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"matrix-netdrive-settings\" class=\"matrix-netdrive-container\"></div>\n\n        <div id=\"form-action-container\"></div>\n\n        <div class=\"matrix-settings-container\">\n            <div class=\"row settings-list-row\"\n                 data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.configuration",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":212,"column":24},"end":{"line":212,"column":54}}}))
    + "</h2>\n                </div>\n            </div>\n\n            <div id=\"matrix-new-configuration\" class=\"settings-list-secundary matrix-new-configuration\"\n                 data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"matrix-add-new-configuration clickable is-focusable\"\n                     tabIndex=\"-1\">\n                    <h2 class=\"matrix-new-configuration-item\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.add_new_configuration_template",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":222,"column":62},"end":{"line":222,"column":109}}}))
    + "</h2>\n                    <span class=\"icon-add-content-button matrix-new-configuration-item\"></span>\n                </div>\n            </div>\n\n            <div id=\"matrix-configuration-list-items\"></div>\n\n            <div class=\"row settings-list-row\">\n                <div class=\"matrix-configurator-settings\"\n                     id=\"matrix-configurator-settings\"\n                     data-nav-area=\".is-focusable:not(:disabled)\"\n                     data-nav-trapped>\n                    <input name=\"start-configurator\"\n                           type=\"button\"\n                           class=\"btn-form form-action-cancel is-focusable start-configurator\"\n                           value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.matrix_configurator_start",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":237,"column":34},"end":{"line":237,"column":76}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isIE") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":237,"column":78},"end":{"line":237,"column":105}}})) != null ? stack1 : "")
    + ">\n                </div>\n            </div>\n            <div class=\"row settings-list-row matrix-loader hidden\">\n                <div class=\"loader small\"></div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isIE") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":243,"column":12},"end":{"line":249,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </form>\n</div>\n";
},"useData":true});
