'use strict';

var app = require('../../app');
var _ = require('lodash');
var containerTpl = require('./container.hbs');
var customSelectTpl = require('./custom-select.hbs');
var Platform = require('../../../platform/platform');
var ScrollView = require('./../../../scroll-view');

var states = {
    closed: 'closed',
    open: 'open'
};

app.component('CustomSelect', {
    template: containerTpl,
    className: 'custom-select-container',

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    /**
     * @method initialize
     */
    initialize: function() {
        this.checkCBox();
        this.remote = this.getService('RemoteService');
        this.selectedIndex = 0;
        this.focusOnClose = true;
        this.serializeData = {
            noLabel: !this.options.label,
            native: this.options.native,
            items: this.options.items,
            name: this.options.name,
            id: this.options.id || this.options.name,
            label: this.options.label,
            validation: this.options.validation,
            error: this.options.error,
            info: this.options.info,
            dataAllowEmpty: this.options.dataAllowEmpty
        };

        this.customState = this.createStateMachine({
            state: states.closed,
            states: states
        });

        this.selectState = this.createStateMachine({
            state: states.closed,
            states: states
        });

        this.addSelectStateTransitions();

        if (typeof this.options.value !== 'undefined') {
            this.select(this.options.value);
        }
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();
        this.bindDOMEvents();

        this.start();
    },

    /**
     * @method storeSelectors
     */
    storeSelectors: function() {
        var parentContainerSelector = this.options.parentContainerSelector || '#overlay-content';

        this.$select = this.$el.find('select');
        this.$container = this.$(parentContainerSelector);
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.$select.on('change', this.onSelectChangeHandler.bind(this));
        // This.$select.on('click', this.onSelectClicked.bind(this));
        this.$select.on('blur', this.onSelectBlur.bind(this));
        this.$select.on('keydown', this.onSelectKeydown.bind(this));
        this.$(document).on('click', this.documentClickHandler.bind(this));
    },

    /**
     * RELEASE-2124: It isn't possible to select an option of drop-down (remote browser).
     * Fixing the bug RELEASE-2116 with this event binding caused this bug. In this case,
     * 2116 would be a nice-to-have.
     */
    // OnSelectClicked: function() {
    //     If (this.selectState.getState() === states.closed) {
    //         This.selectState.changeState(states.open);
    //     }
    // },

    /**
     * Set select-state class on keyboard input.
     *
     * @param {jQuery.Event} event
     */
    onSelectKeydown: function(event) {
        if (event.which === this.remote.KEYCODES.ESC) {
            this.selectState.changeState(states.closed);
        } else if (event.which === this.remote.KEYCODES.WHITESPACE) {
            this.selectState.changeState(states.open);
        } else if (event.which === this.remote.KEYCODES.ENTER) {
            this.selectState.changeState(states.closed);
            this.remote.focus(this.$select);
            event.preventDefault();
        }
    },

    /**
     * Set state to closed when a blur has been triggered on select-item.
     */
    onSelectBlur: function() {
        this.selectState.changeState(states.closed);
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        this.on('remote.focus.change', this.checkFocus.bind(this));
        this.on('select.' + this.serializeData.name + '.update', this.updateSelect.bind(this));
        this.on('select.' + this.serializeData.name + '.is-focusable', this.isFocusable.bind(this));
    },

    /**
     * @method updateSelect
     * @param data
     */
    updateSelect: function(data) {
        this.$select.find('option').remove();

        if (!this.options.native) {
            this.$customSelectOption.remove();
        }

        if (data.items) {
            this.serializeData.items = data.items;
        }

        // Render all items
        _.each(data.items, function(item) {
            var selected = '';

            // Check if this value the selected value set with html attribute selected, if you use this.select() it will trigger a change event
            if (item.value === data.selected) {
                selected = ' selected';
            }

            // TODO: add translations
            this.$select.append('<option value="' + item.value + '"' + selected + '>' + item.text + '</option>');

            if (!this.options.native) {
                this.$customSelectOptions.find('ul').append('<li class="custom-select-option is-focusable" tabIndex="-1" data-value="' + item.value + '">' + item.text + '</li>');
            }
        }.bind(this));

        // Reinit the custom select
        if (!this.options.native) {
            this.$customSelectSelected.off();
            this.$customSelectOption.off();

            this.storeCustomSelectSelectors();
            this.bindCustomSelectDOMEvents();
            this.updateCustomSelect();

            this.scrollView = new ScrollView({
                $container: this.$customSelectOptions,
                $items: this.$customSelectOption
            });
        }
    },

    /**
     * Override native param false to true when the device is cBox
     *
     * @method checkCBox
     */
    checkCBox: function() {
        if (Platform.checks.isCbox === true) {
            this.options.native = false;
        }
    },

    /**
     * @method checkFocus
     * @param  {object} event
     */
    checkFocus: function(event) {
        if (this.customState.getState() === states.open && this.options.native === false) {
            var $el = this.$(event.el);

            if (!$el.hasClass('custom-select-option')) {
                this.focusOnClose = false;
                this.closeCustomSelect();
            }
        }
    },

    /**
     * @method documentClickHandler
     */
    documentClickHandler: function(event) {
        if (!this.$(event.target).closest('.custom-select').length) {
            this.focusOnClose = false;
            this.closeCustomSelect();
        }
    },

    /**
     * @method onSelectChangeHandler
     * @param event
     */
    onSelectChangeHandler: function() {
        var value;

        if (this.options.onChange) {
            value = this.$select.val();
            this.options.onChange(value);
            this.selectedIndex = this.$select.find('option[value="' + value + '"]').index();
        }

        if (!this.options.native) {
            this.updateCustomSelect();
        }
    },

    /**
     * @method serlialize
     * @returns {{native: (*|.postPlaceAt.select.native|.serialize.native),
     *          items: (*|ListGrid.serialize.items|.startList.configs.items|.postPlaceAt.select.items|configs.items|DataTransferItemList), name: *}}
     */
    serialize: function() {
        return this.serializeData;
    },

    /**
     * @method start
     */
    start: function() {
        this.setContainerClass();
        this.startCustomSelect();
        this.selectDefault();

        if (this.options.disabled) {
            this.$select.attr({
                'disabled': 'disabled'
            });
        }

        if (undefined !== this.options.dataAllowEmpty) {
            this.$select.attr('data-allow-empty', this.options.dataAllowEmpty ? 'true' : 'false');
        }
    },

    /**
     * @method startCustomSelect
     */
    startCustomSelect: function() {
        if (!this.options.native) {
            this.renderCustomSelect();
            this.storeCustomSelectSelectors();
            this.bindCustomSelectDOMEvents();
            this.bindCustomSelectEvents();

            this.scrollView = new ScrollView({
                $container: this.$customSelectOptions,
                $items: this.$customSelectOption
            });
        }
    },

    /**
     * @method storeCustomSelectSelectors
     */
    storeCustomSelectSelectors: function() {
        this.$customSelectSelected = this.$customSelect.find('.custom-select-selected-container');
        this.$customSelectOptions = this.$customSelect.find('.custom-select-options');
        this.$customSelectOption = this.$customSelect.find('.custom-select-option');
    },

    /**
     * @method bindCustomSelectDOMEvents
     */
    bindCustomSelectDOMEvents: function() {
        this.$customSelectSelected.on('click', this.onSelectedClickHandler.bind(this));
        this.$customSelectOption.on('click', this.selectOptionHandler.bind(this));

        if (this.options.horizontalControl) {
            this.$customSelectSelected.on('keydown', this.onKeydownHandler.bind(this));
        } else {
            this.$customSelectOption.on('keydown', this.onKeydownOptionsHandler.bind(this));
        }
    },

    /**
     * @method bindCustomSelectDOMEvents
     */
    bindCustomSelectEvents: function() {
        this.on('custom-select.close.all', this.closeCustomSelect.bind(this));
    },

    /**
     * @method selectOptionHandler
     * @param event
     */
    selectOptionHandler: function(event) {
        var $el = this.$(event.currentTarget);
        var value = $el.data('value');

        this.select(value);
        this.closeCustomSelect();
    },

    /**
     * @method onKeydownHandler
     * @param {Object} event
     */
    onKeydownHandler: function(event) {
        if (!this.$select.prop('disabled')) {
            switch (event.which) {
                case this.remote.KEYCODES.LEFT:
                    this.selectPrevItem();
                    break;

                case this.remote.KEYCODES.RIGHT:
                    this.selectNextItem();
                    break;
            }
        }
    },

    /**
     * Focus remote control in select
     * @param {jQuery.Event} event
     */
    onKeydownOptionsHandler: function(event) {
        if ((this.remote.focusedElement.is(':first-child') && event.keyCode === this.remote.KEYCODES.UP)
            || (this.remote.focusedElement.is(':last-child') && event.keyCode === this.remote.KEYCODES.DOWN)) {
            /**
             * Now when the selected element is the first element the up button doesn't work and when the last item is
             * selected, the down button doesn't work
             */
            event.stopPropagation();
        }

        if (event.keyCode === this.remote.KEYCODES.LEFT || event.keyCode === this.remote.KEYCODES.RIGHT) {
            this.focusOnClose = false;
        }

        event.preventDefault();
    },

    /**
     * @method selectPrevItem
     */
    selectPrevItem: function() {
        var index = this.selectedIndex;
        var items = this.serializeData.items;

        if (index <= 0) {
            this.selectedIndex = items.length - 1;
        } else {
            this.selectedIndex--;
        }

        this.select(items[this.selectedIndex].value);
    },

    /**
     * @method selectNextItem
     */
    selectNextItem: function() {
        var index = this.selectedIndex;
        var items = this.serializeData.items;

        if (index >= items.length - 1) {
            this.selectedIndex = 0;
        } else {
            this.selectedIndex++;
        }

        this.select(items[this.selectedIndex].value);
    },

    /**
     * @method onSelectedClickHandler
     * @param {Object} event
     */
    onSelectedClickHandler: function() {
        if (this.customState.getState() === states.open) {
            this.closeCustomSelect();
        } else {
            this.openCustomSelect();
        }
    },

    /**
     * @method openCustomSelect
     */
    openCustomSelect: function() {
        this.emit('custom-select.close.all');

        if (!this.$select.prop('disabled') && this.options.native === false) {
            // Select first item if there is only one item in the list or there is nothing preselected.
            if (this.selectedIndex < 0 || this.serializeData.items.length === 1) {
                this.selectDefault();
                this.updateCustomSelect();
            }

            this.setCustomSelectPosition();
            this.scrollToSelectedOption();
            this.animateOpenCustomSelect();
            this.$customSelect.addClass('is-open');
            this.customState.changeState(states.open);
        }
    },

    /**
     * @method scrollToSelectedOption
     */
    scrollToSelectedOption: function() {
        var $el = this.$customSelectOptions.find('.is-selected');
        var index = $el.index();
        var top = index * 32;
        this.$customSelectOptions.show();
        this.$customSelectOptions.scrollTop(top);
        this.$customSelectOptions.hide();
    },

    /**
     * @method setCustomSelectPosition
     */
    setCustomSelectPosition: function() {
        var containerBottom = this.$container.offset().top + this.$container.height();
        var selectTop = this.$customSelect.offset().top;
        var placeBottom = containerBottom - selectTop;

        this.$customSelectOptions.removeClass('has-bottom-position');
        this.$customSelectOptions.removeClass('has-top-position');

        if (placeBottom > 200) {
            this.$customSelectOptions.addClass('has-bottom-position');
        } else {
            this.$customSelectOptions.addClass('has-top-position');
        }
    },

    /**
     * @method closeCustomSelect
     */
    closeCustomSelect: function() {
        if (this.options.native === false && this.customState.getState() === states.open) {
            this.animateCloseCustomSelect();
            this.$customSelect.removeClass('is-open');
            this.customState.changeState(states.closed);
        }
    },

    /**
     * @method renderCustomSelect
     */
    renderCustomSelect: function() {
        this.$customSelect = this.$(customSelectTpl(this.serializeData));
        this.$el.find('.custom-select-container').html(this.$customSelect);
    },

    /**
     * @method updateCustomSelect
     */
    updateCustomSelect: function() {
        var $el = this.$customSelectOptions.find('[data-value="' + this.$select.val() + '"]');
        var name = $el.text();

        this.$customSelectSelected
            .find('.custom-select-selected')
            .text(name);

        this.selectedIndex = this.$select.find('option:selected').index();
        this.$customSelectOption.removeClass('is-selected');
        $el.addClass('is-selected');
    },

    /**
     * @method setContainerClass
     */
    setContainerClass: function() {
        if (!this.options.native) {
            this.$el.addClass('is-custom');
        } else {
            this.$el.addClass('is-native');
        }

        if (this.options.horizontalControl) {
            this.$el.addClass('is-horizontal');
        }
    },

    /**
     * @method selectDefault
     */
    selectDefault: function() {
        if (this.options.preSelected || 0 === this.options.preSelected) {
            this.select(this.options.preSelected, true);
        } else if (this.serializeData.items.length > 0) {
            this.select(this.serializeData.items[0]
                ? this.serializeData.items[0].value : this.serializeData.items[1].value, true);
        }
    },

    /**
     * @method select
     * @param {string} value
     */
    select: function(value, ignoreEvent) {
        this.$select.val(value);

        if (!ignoreEvent) {
            this.$select.trigger('change');
        }

        if (!this.options.native && (this.options.preSelected || 0 === this.options.preSelected)) {
            this.updateCustomSelect();
        }
    },

    /**
     * @method animateOpenCustomSelect
     */
    animateOpenCustomSelect: function() {
        this.$customSelectOptions
            .velocity('fadeIn', {
                duration: 100,
                complete: function() {
                    var $selected = this.$customSelectOptions.find('.is-selected');
                    this.remote.focus($selected);
                    this.focusOnClose = false;
                }.bind(this)
            });
    },

    /**
     * @method animateCloseCustomSelect
     */
    animateCloseCustomSelect: function() {
        this.$customSelectOptions
            .velocity('fadeOut', {
                duration: 50,
                complete: function() {
                    if (this.focusOnClose) {
                        this.remote.focus(this.$el.find('.custom-select-selected-container').get(0));
                    }

                    this.focusOnClose = true;
                }.bind(this)
            });
    },

    /**
     * Set if custom-select is focusable or not.
     *
     * @param {Boolean} data.focusable true/false
     */
    isFocusable: function(data) {
        if (data.focusable) {
            this.$el.find('.custom-select-selected-container').addClass('is-focusable');
        } else {
            this.$el.find('.custom-select-selected-container').removeClass('is-focusable');
        }
    },

    /**
     * @method addStateTransitions
     */
    addSelectStateTransitions: function() {
        this.selectState.addTransitions({
            'open > closed': function() {
                this.remote.unblock();
                this.$select.addClass('is-closed');
            },
            'closed > open': function() {
                this.remote.block();
                this.$select.removeClass('is-closed');
            }
        });
    },

    /**
     * @method destroy
     */
    destroy: function() {
        if (!this.options.native) {
            this.scrollView.destroy();
        }
    }
});
