// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<nav id=\"framebox-visualizer-focus\"\n     class=\"framebox-nav-container\"\n     data-scrolling-spacing=\"60\">\n    <ul class=\"framebox-controls-submenu list list-centered\">\n        <li>\n            <button id=\"visualizer-focus-in\" name=\"visualizer-focus-in\" class=\"btn-no-style framebox-bar-item wide focusable\" >\n                <span class=\"framebox-header-focus framebox-header-control-state-focus-in\">\n                    <span class=\"framebox-header-control-text\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"visualizer.focus_in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":63},"end":{"line":8,"column":90}}}))
    + "</span>\n                    <span class=\"icon-v2-focus-near\"></span>\n                </span>\n            </button>\n        </li>\n        <li>\n            <button id=\"visualizer-focus-out\" name=\"visualizer-focus-out\" class=\"btn-no-style framebox-bar-item wide focusable\" >\n                <span class=\"framebox-header-focus framebox-header-control-state-focus-out\">\n                    <span class=\"framebox-header-control-text\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"visualizer.focus_out",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":63},"end":{"line":16,"column":91}}}))
    + "</span>\n                    <span class=\"icon-v2-focus-far\"></span>\n                </span>\n            </button>\n        </li>\n    </ul>\n</nav>\n";
},"useData":true});
