// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-row\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.dual_projection",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":24},"end":{"line":33,"column":56}}}))
    + "</h2>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.dual_projection_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":44},"end":{"line":39,"column":83}}}))
    + "</h2>\n                </div>\n\n                <div class=\"col-xs-6\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"dualProjectionEnable\"\n                               id=\"dualProjectionEnable\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\n                           is-focusable\"\n                               tabIndex=\"1\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary settings-list-info\" data-name=\"dualProjectionEnable\">\n                <div class=\"col-xs-12\">\n                    <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.dual_projection_enable_warning",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":23},"end":{"line":59,"column":70}}}))
    + "</p>\n                </div>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable:not(:disabled)\" data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div name=\"url-interface-select\" id=\"url-interface-select\"></div>\n                    </div>\n                    <div class=\"col-xs-6\"></div>\n                </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <h2>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.general_statusbar_projections",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":329,"column":24},"end":{"line":329,"column":70}}}))
    + "</h2>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <h2>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.general_statusbar",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":331,"column":24},"end":{"line":331,"column":58}}}))
    + "</h2>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.display_interface_lan",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":358,"column":48},"end":{"line":358,"column":86}}}))
    + "</h2>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.display_interface_lan1",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":360,"column":48},"end":{"line":360,"column":87}}}))
    + "</h2>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.display_interface_lan2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":380,"column":48},"end":{"line":380,"column":87}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input name=\"statusbarLan2\"\n                                   id=\"statusbarLan2\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch is-focusable\"\n                                   tabIndex=\"1\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"col-xs-12\">\n                        <div id=\"pin-size-select\"></div>\n                    </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"col-xs-6\">\n                        <div id=\"pin-size-select\"></div>\n                    </div>\n                    <div class=\"col-xs-6\"></div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row settings-list-secundary pin-dualprojection-enabled\"\n                    data-nav-area=\".btn-switch\"\n                    data-nav-trapped>\n                    <div class=\"col-xs-8\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.pin_control_screen",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":464,"column":48},"end":{"line":464,"column":83}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input id=\"pinControlScreen\"\n                                name=\"pinControlScreen\"\n                                type=\"checkbox\"\n                                class=\"btn-switch\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"row settings-list-secundary pin-projections-enabled\"\n                    data-nav-area=\".btn-switch\"\n                    data-nav-trapped>\n                    <div class=\"col-xs-8\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.pin_projections",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":482,"column":48},"end":{"line":482,"column":80}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input id=\"pinProjections\"\n                                name=\"pinProjections\"\n                                type=\"checkbox\"\n                                class=\"btn-switch\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row settings-list-secundary pin-main-screen-enabled\"\n                    data-nav-area=\".btn-switch\"\n                    data-nav-trapped>\n                    <div class=\"col-xs-8\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.pin_main_screen",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":501,"column":48},"end":{"line":501,"column":80}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input id=\"pinMainScreen\"\n                                name=\"pinMainScreen\"\n                                type=\"checkbox\"\n                                class=\"btn-switch\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"row settings-list-secundary pin-hdmi2 hidden\"\n                    data-nav-area=\".btn-switch\"\n                    data-nav-trapped>\n                    <div class=\"col-xs-8\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.pin_hdmi2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":519,"column":48},"end":{"line":519,"column":74}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input id=\"pinHdmi2\"\n                                name=\"pinHdmi2\"\n                                type=\"checkbox\"\n                                class=\"btn-switch\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div id=\"pin-dynamic-settings-container\">\n                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\".is-focusable\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-8\">\n                        <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||container.hooks.helperMissing).call(alias1,depth0,{"name":"tr","hash":{"key":"settings.pin_dynamic_generic"},"data":data,"loc":{"start":{"line":543,"column":48},"end":{"line":543,"column":94}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <div class=\"input-group\">\n                            <div class=\"btn-switch-container pull-right no-padding\">\n                                <input name=\"pinDynamicEnabled\"\n                                       id=\"pinDynamicEnabled\"\n                                       type=\"checkbox\"\n                                       class=\"btn-switch is-focusable\"\n                                       tabIndex=\"-1\"/>\n                                <div class=\"btn-switch-toggle-container\">\n                                    <div class=\"btn-switch-toggle\"></div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <div id=\"pin-reset-time\"\n                     class=\"row settings-list-secundary hidden\"\n                     data-nav-area=\".is-focusable\"\n                     data-nav-trapped>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFullsize") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":564,"column":20},"end":{"line":573,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"col-xs-12\">\n                            <div id=\"pin-reset-time-select\"></div>\n                        </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"col-xs-6\">\n                            <div id=\"pin-reset-time-select\"></div>\n                        </div>\n                        <div class=\"col-xs-6\"></div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.general_toolbox_apps_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":588,"column":44},"end":{"line":588,"column":88}}}))
    + "</h2>\n                </div>\n\n                <div class=\"col-xs-6\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"appsEnabled\"\n                               id=\"appsEnabled\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\n                           is-focusable\"\n                               tabIndex=\"1\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(alias1,"settings.general_toolbox_snapshot_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":628,"column":44},"end":{"line":628,"column":92}}}))
    + "</h2>\n                </div>\n\n                <div class=\"col-xs-6\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"snapshotEnabled\"\n                               id=\"snapshotEnabled\"\n                               type=\"checkbox\"\n                               class=\"btn-switch is-focusable\"\n                               tabIndex=\"1\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDualProjection") : depth0),{"name":"unless","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":645,"column":12},"end":{"line":664,"column":23}}})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.general_toolbox_freeze_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":648,"column":48},"end":{"line":648,"column":94}}}))
    + "</h2>\n                    </div>\n\n                    <div class=\"col-xs-6\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input name=\"freezeEnabled\"\n                                id=\"freezeEnabled\"\n                                type=\"checkbox\"\n                                class=\"btn-switch is-focusable\"\n                                tabIndex=\"1\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-row\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.browser",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":685,"column":24},"end":{"line":685,"column":48}}}))
    + "</h2>\n                </div>\n\n                <div class=\"col-xs-6\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"browserEnabled\"\n                               id=\"browserEnabled\"\n                               type=\"checkbox\"\n                               class=\"btn-switch is-focusable\"\n                               tabIndex=\"1\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div data-nav-area=\".is-focusable:not(:disabled), .btn-form\" data-nav-trapped>\n                <div class=\"row settings-list-secundary browser0\">\n                    <div class=\"col-xs-4\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"browserName0\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":706,"column":74},"end":{"line":706,"column":95}}}))
    + "</label>\n                            <input type=\"text\"\n                                   class=\"input is-focusable\"\n                                   name=\"browserName0\"\n                                   id=\"browserName0\"\n                                   placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.browser",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":711,"column":48},"end":{"line":711,"column":72}}}))
    + "\"\n                                   data-max-length=\"64\"\n                                   data-validation=\"checkLength\"\n                                   tabIndex=\"-1\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":716,"column":79},"end":{"line":716,"column":111}}}))
    + "</span>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-7 browser-url-container\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"browserUrl0\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":722,"column":73},"end":{"line":722,"column":93}}}))
    + "</label>\n                            <input type=\"text\"\n                                   class=\"input is-focusable\"\n                                   name=\"browserUrl0\"\n                                   id=\"browserUrl0\"\n                                   placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.url_placeholder",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":727,"column":48},"end":{"line":727,"column":80}}}))
    + "\"\n                                   data-validation=\"checkBrowserShortcutUrl\"\n                                   data-max-length=\"255\"\n                                   tabIndex=\"-1\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\" id=\"url-error\">\n                                    "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":733,"column":36},"end":{"line":733,"column":62}}}))
    + "\n                                </span>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-1 no-padding p-top-15\">\n                        <div class=\"input-group\">\n                            <input type=\"button\"\n                                   class=\"btn-form action-btn-style delete-btn-list-item\"\n                                   data-index=\"0\"\n                                   name=\"browserDelete0\"\n                                   value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":744,"column":42},"end":{"line":744,"column":66}}}))
    + "\"/>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-secundary browser1\" data-nav-trapped>\n                    <div class=\"col-xs-4\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"browserName1\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":752,"column":74},"end":{"line":752,"column":95}}}))
    + "</label>\n                            <input type=\"text\"\n                                   class=\"input is-focusable\"\n                                   name=\"browserName1\"\n                                   id=\"browserName1\"\n                                   placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.browser",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":757,"column":48},"end":{"line":757,"column":72}}}))
    + "\"\n                                   data-max-length=\"63\"\n                                   data-validation=\"checkLength\"\n                                   tabIndex=\"-1\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":762,"column":79},"end":{"line":762,"column":111}}}))
    + "</span>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-7 browser-url-container\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"browserUrl1\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":768,"column":73},"end":{"line":768,"column":93}}}))
    + "</label>\n                            <input type=\"text\"\n                                   class=\"input is-focusable\"\n                                   name=\"browserUrl1\"\n                                   id=\"browserUrl1\"\n                                   placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.url_placeholder",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":773,"column":48},"end":{"line":773,"column":80}}}))
    + "\"\n                                   data-validation=\"checkBrowserShortcutUrl\"\n                                   data-max-length=\"255\"\n                                   tabIndex=\"-1\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\" id=\"url-error\">\n                                    "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":779,"column":36},"end":{"line":779,"column":62}}}))
    + "\n                                </span>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-1 no-padding p-top-15\">\n                        <div class=\"input-group\">\n                            <input type=\"button\"\n                                   class=\"btn-form action-btn-style delete-btn-list-item\"\n                                   data-index=\"1\"\n                                   name=\"browserDelete1\"\n                                   value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":790,"column":42},"end":{"line":790,"column":66}}}))
    + "\"/>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-secundary browser2\" data-nav-trapped>\n                    <div class=\"col-xs-4\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"browserName2\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":798,"column":74},"end":{"line":798,"column":95}}}))
    + "</label>\n                            <input type=\"text\"\n                                   class=\"input is-focusable\"\n                                   name=\"browserName2\"\n                                   id=\"browserName2\"\n                                   placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.browser",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":803,"column":48},"end":{"line":803,"column":72}}}))
    + "\"\n                                   data-max-length=\"63\"\n                                   data-validation=\"checkLength\"\n                                   tabIndex=\"-1\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":808,"column":79},"end":{"line":808,"column":111}}}))
    + "</span>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-7 browser-url-container\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"browserUrl2\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":814,"column":73},"end":{"line":814,"column":93}}}))
    + "</label>\n                            <input type=\"text\"\n                                   class=\"input is-focusable\"\n                                   name=\"browserUrl2\"\n                                   id=\"browserUrl2\"\n                                   placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.url_placeholder",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":819,"column":48},"end":{"line":819,"column":80}}}))
    + "\"\n                                   data-validation=\"checkBrowserShortcutUrl\"\n                                   data-max-length=\"255\"\n                                   tabIndex=\"-1\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\" id=\"url-error\">\n                                    "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":825,"column":36},"end":{"line":825,"column":62}}}))
    + "\n                                </span>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-1 no-padding p-top-15\">\n                        <div class=\"input-group\">\n                            <input type=\"button\"\n                                   class=\"btn-form action-btn-style delete-btn-list-item\"\n                                   data-index=\"2\"\n                                   name=\"browserDelete2\"\n                                   value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":836,"column":42},"end":{"line":836,"column":66}}}))
    + "\"/>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-secundary browser3\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                    <div class=\"col-xs-4\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"browserName3\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":844,"column":74},"end":{"line":844,"column":95}}}))
    + "</label>\n                            <input type=\"text\"\n                                   class=\"input is-focusable\"\n                                   name=\"browserName3\"\n                                   id=\"browserName3\"\n                                   placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.browser",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":849,"column":48},"end":{"line":849,"column":72}}}))
    + "\"\n                                   data-max-length=\"63\"\n                                   data-validation=\"checkLength\"\n                                   tabIndex=\"-1\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":854,"column":79},"end":{"line":854,"column":111}}}))
    + "</span>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-7 browser-url-container\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"browserUrl3\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":860,"column":73},"end":{"line":860,"column":93}}}))
    + "</label>\n                            <input type=\"text\"\n                                   class=\"input is-focusable\"\n                                   name=\"browserUrl3\"\n                                   id=\"browserUrl3\"\n                                   placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.url_placeholder",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":865,"column":48},"end":{"line":865,"column":80}}}))
    + "\"\n                                   data-validation=\"checkBrowserShortcutUrl\"\n                                   data-max-length=\"255\"\n                                   tabIndex=\"-1\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\" id=\"url-error\">\n                                    "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":871,"column":36},"end":{"line":871,"column":62}}}))
    + "\n                                </span>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-1 no-padding p-top-15\">\n                        <div class=\"input-group\">\n                        <input type=\"button\"\n                               class=\"btn-form action-btn-style delete-btn-list-item\"\n                               data-index=\"3\"\n                               name=\"browserDelete3\"\n                               value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":882,"column":38},"end":{"line":882,"column":62}}}))
    + "\"/>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-secundary add-new-direct-link is-focusable clickable\"\n                     id=\"add-new-direct-link\" tabindex=\"-1\">\n                    <div class=\"col-xs-9\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.add_new_direct_link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":890,"column":48},"end":{"line":890,"column":84}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-3 add-new-icon-container\">\n                        <span class=\"icon-add-content-button pull-right\"></span>\n                    </div>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"general-settings\" name=\"input-settings\">\n        <div class=\"row settings-list-secundary\"\n             data-nav-area=\".is-focusable:not(:disabled)\"\n             data-nav-trapped\n             data-nav-area-default>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"boxname\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.general_boxname_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":61},"end":{"line":9,"column":99}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input is-focusable\"\n                           name=\"boxname\"\n                           id=\"boxname\"\n                           data-validation=\"checkBoxname\"\n                           tabIndex=\"-1\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message success-message hidden\" id=\"restart-info\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.general_boxname_restart",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":28},"end":{"line":18,"column":69}}}))
    + "\n                        </span>\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.general_invalid_boxname",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":71},"end":{"line":20,"column":111}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"col-xs-6\">\n                <div id=\"language-select\"></div>\n            </div>\n        </div>\n\n        <!-- Dual Projection -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPro") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":62,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- Keyboard -->\n        <div class=\"row settings-list-row\" data-nav-area=\".is-focusable\" data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.general_keyboard",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":20},"end":{"line":67,"column":53}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-6\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"keyboard\"\n                           id=\"keyboard\"\n                           type=\"checkbox\"\n                           class=\"btn-switch is-focusable\"\n                           tabIndex=\"1\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\"\n             data-nav-area=\".is-focusable:not(:disabled)\"\n             data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div id=\"keyboard-layout-select\"></div>\n            </div>\n            <div class=\"col-xs-6\"></div>\n        </div>\n\n        <!-- Timeserver -->\n        <div class=\"row settings-list-row\" data-nav-area=\".is-focusable\" data-nav-trapped>\n            <div class=\"col-xs-8\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.general_timeserver",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":95,"column":20},"end":{"line":95,"column":55}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"timeserver\"\n                           id=\"timeserver\"\n                           type=\"checkbox\"\n                           class=\"btn-switch is-focusable\"\n                           tabIndex=\"-1\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable:not(:disabled)\" data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"url\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.general_timeserver_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":114,"column":57},"end":{"line":114,"column":96}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input is-focusable\"\n                           name=\"url\"\n                           id=\"url\"\n                           placeholder=\"0.0.0.0\"\n                           data-validation=\"required\"\n                           tabIndex=\"-1\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":123,"column":71},"end":{"line":123,"column":97}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"col-xs-1\">\n                <div id=\"timeserver-test-state\" class=\"timeserver-test-state\">\n                    <span class=\"icon-check-mark timeserver-status-success\"></span>\n                    <span class=\"icon-panic-button timeserver-status-failed\"></span>\n                    <span class=\"timeserver-status-testing\">\n                        <div class=\"loader small\"></div>\n                    </span>\n                </div>\n            </div>\n            <div class=\"col-xs-5\">\n                <div id=\"timeserver-test-btn\" class=\"timeserver-test-btn\" data-nav-area=\".is-focusable:not(:disabled)\"\n                     data-nav-trapped>\n                    <input type=\"button\" name=\"timeserver-test\" id=\"timeserver-test\"\n                           class=\"btn-form form-action-cancel timeserver-test-action is-focusable\"\n                           value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.test_it_now",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":141,"column":34},"end":{"line":141,"column":62}}}))
    + "\"/>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable:not(:disabled)\" data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div id=\"timeformat-select\"></div>\n            </div>\n            <div class=\"col-xs-6\">\n                <div id=\"timezone-select\"></div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable:not(:disabled)\" data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"date\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.general_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":169,"column":58},"end":{"line":169,"column":87}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input is-focusable\"\n                           name=\"date\"\n                           id=\"date\"\n                           data-validation=\"checkIsCorrectDate\"\n                           tabIndex=\"-1\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":177,"column":71},"end":{"line":177,"column":98}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"time\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.general_time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":183,"column":58},"end":{"line":183,"column":87}}}))
    + "</label>\n                    <input type=\"text\" class=\"input is-focusable\" name=\"time\" id=\"time\"\n                           data-validation=\"checkIsCorrectTime\" tabIndex=\"-1\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":187,"column":71},"end":{"line":187,"column":98}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"general-settings-apps\">\n            <!-- QR-Code -->\n            <div class=\"row settings-list-row\">\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.apps",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":197,"column":24},"end":{"line":197,"column":45}}}))
    + "</h2>\n                </div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPureReceiver") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":200,"column":12},"end":{"line":207,"column":23}}})) != null ? stack1 : "")
    + "            <!-- Eddystone -->\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.eddystone_discovery_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":211,"column":44},"end":{"line":211,"column":87}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"eddystone-enable\"\n                               name=\"eddystoneEnable\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div id=\"eddystone-interface-container\"\n                 class=\"row settings-list-secundary\"\n                 data-nav-area=\".is-focusable\"\n                 data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <div id=\"eddystone-interface-select\"></div>\n                </div>\n                <div class=\"col-xs-6\"></div>\n            </div>\n        </div>\n        <!-- mDNS -->\n        <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n            <div class=\"col-xs-8\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.mdns_discovery_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":238,"column":40},"end":{"line":238,"column":78}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input id=\"mdns-enable\"\n                           name=\"mdnsEnable\"\n                           type=\"checkbox\"\n                           class=\"btn-switch\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <!-- vLink Pro Discovery Management Server -->\n        <div class=\"row settings-list-row\">\n            <div class=\"col-xs-12\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.vlink_pro",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":256,"column":20},"end":{"line":256,"column":46}}}))
    + "</h2>\n                <h2>\n                    <span id=\"management-server-connection-state\" class=\"management-server-connection-state\">\n                        <span class=\"management-server-connection-state-init\">\n                            <div class=\"management-server-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.init",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":261,"column":28},"end":{"line":261,"column":49}}}))
    + "\n                        </span>\n                        <span class=\"management-server-connection-state-generic-error\">\n                            <div class=\"management-server-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.generic_error",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":265,"column":28},"end":{"line":265,"column":58}}}))
    + "\n                        </span>\n                        <span class=\"management-server-connection-state-dhcp-error\">\n                            <div class=\"management-server-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.dhcp_error",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":269,"column":28},"end":{"line":269,"column":55}}}))
    + "\n                        </span>\n                        <span class=\"management-server-connection-state-post-error\">\n                            <div class=\"management-server-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.post_error",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":273,"column":28},"end":{"line":273,"column":55}}}))
    + "\n                        </span>\n                        <span class=\"management-server-connection-state-ok\">\n                            <div class=\"management-server-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.online",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":277,"column":28},"end":{"line":277,"column":51}}}))
    + "\n                        </span>\n                        <span class=\"management-server-connection-state-off\">\n                            <div class=\"management-server-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.disabled",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":281,"column":28},"end":{"line":281,"column":53}}}))
    + "\n                        </span>\n                    </span>\n                </h2>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n            <div class=\"col-xs-8\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.auto_management_server",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":290,"column":40},"end":{"line":290,"column":79}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"input-group\">\n                    <div class=\"btn-switch-container pull-right no-padding\">\n                        <input name=\"managementServerDhcp\"\n                               id=\"managementServerDhcp\"\n                               type=\"checkbox\"\n                               class=\"btn-switch is-focusable\"\n                               tabIndex=\"-1\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"vlink-pro-server-ip-container\"\n             class=\"row settings-list-secundary\"\n             data-nav-area=\".is-focusable:not(:disabled)\"\n             data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"vLinkProServerIp\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.vlink_pro_server_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":314,"column":70},"end":{"line":314,"column":107}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input is-focusable\"\n                           name=\"vLinkProServerIp\"\n                           id=\"vLinkProServerIp\"\n                           tabIndex=\"-1\"/>\n                </div>\n            </div>\n            <div class=\"col-xs-6\"></div>\n        </div>\n\n        <!-- Statusbar -->\n        <div class=\"row settings-list-row\" data-nav-area=\".is-focusable\" data-nav-trapped>\n            <div class=\"col-xs-9\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDualProjection") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":328,"column":16},"end":{"line":332,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"col-xs-3\">\n                <div class=\"input-group flex\">\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message statusbar-info\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.statusbar_reload_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":338,"column":28},"end":{"line":338,"column":66}}}))
    + "\n                        </span>\n                    </div>\n                    <div class=\"btn-switch-container pull-right no-padding p-left-15\">\n                        <input name=\"statusbar\"\n                               id=\"statusbar\"\n                               type=\"checkbox\"\n                               class=\"btn-switch is-focusable\"\n                               tabIndex=\"-1\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"status-bar-settings-container\">\n            <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n"
    + ((stack1 = (lookupProperty(helpers,"if_or")||(depth0 && lookupProperty(depth0,"if_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPureOrPro") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"if_or","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":357,"column":20},"end":{"line":361,"column":30}}})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"col-xs-6\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"statusbarLan1\"\n                               id=\"statusbarLan1\"\n                               type=\"checkbox\"\n                               class=\"btn-switch is-focusable\"\n                               tabIndex=\"1\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"unless_or")||(depth0 && lookupProperty(depth0,"unless_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPureOrPro") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"unless_or","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":377,"column":12},"end":{"line":395,"column":26}}})) != null ? stack1 : "")
    + "\n            <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.display_interface_wlan",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":399,"column":44},"end":{"line":399,"column":83}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-6\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"statusbarWlan\"\n                               id=\"statusbarWlan\"\n                               type=\"checkbox\"\n                               class=\"btn-switch is-focusable\"\n                               tabIndex=\"1\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.display_wlan_ssid",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":417,"column":44},"end":{"line":417,"column":78}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-6\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"statusbarSsid\"\n                               id=\"statusbarSsid\"\n                               type=\"checkbox\"\n                               class=\"btn-switch is-focusable\"\n                               tabindex=\"1\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <!-- PIN / Connection ID Flap settings -->\n        <div id=\"pin-flap-settings-container\">\n            <div class=\"row settings-list-row\"\n                 data-nav-area=\".is-focusable\"\n                 data-nav-trapped>\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.pin_connection_id",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":440,"column":24},"end":{"line":440,"column":58}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-6\"></div>\n            </div>\n            <div id=\"pin-size\"\n                 class=\"row settings-list-secundary\"\n                 data-nav-area=\".is-focusable\"\n                 data-nav-trapped>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFullsize") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":448,"column":16},"end":{"line":457,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDualProjection") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":459,"column":12},"end":{"line":533,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n\n        <!-- Dynamic PIN settings -->\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPureReceiver") : depth0),{"name":"unless","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":537,"column":8},"end":{"line":576,"column":19}}})) != null ? stack1 : "")
    + "\n        <!-- Control Center -->\n        <div class=\"row settings-list-row\" data-nav-area=\".is-focusable\" data-nav-trapped>\n            <div class=\"col-xs-12\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.control_center",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":581,"column":20},"end":{"line":581,"column":51}}}))
    + "</h2>\n            </div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPureReceiver") : depth0),{"name":"unless","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":585,"column":8},"end":{"line":605,"column":19}}})) != null ? stack1 : "")
    + "        <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.general_toolbox_help_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":608,"column":40},"end":{"line":608,"column":84}}}))
    + "</h2>\n            </div>\n\n            <div class=\"col-xs-6\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"helpEnabled\"\n                           id=\"helpEnabled\"\n                           type=\"checkbox\"\n                           class=\"btn-switch is-focusable\"\n                           tabIndex=\"1\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"unless_or")||(depth0 && lookupProperty(depth0,"unless_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPure") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"unless_or","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":625,"column":8},"end":{"line":665,"column":22}}})) != null ? stack1 : "")
    + "\n        <!-- Power Down Mode -->\n        <div class=\"row settings-list-row\">\n            <div class=\"col-xs-12\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.power_down",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":670,"column":20},"end":{"line":670,"column":47}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-6\"></div>\n        </div>\n        <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div name=\"power-down-mode-select\" id=\"power-down-mode-select\"></div>\n            </div>\n            <div class=\"col-xs-6\"></div>\n        </div>\n\n        <!-- Browser start page -->\n"
    + ((stack1 = (lookupProperty(helpers,"unless_or")||(depth0 && lookupProperty(depth0,"unless_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPure") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"unless_or","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":682,"column":8},"end":{"line":897,"column":22}}})) != null ? stack1 : "")
    + "\n        <div id=\"form-action-container\" class=\"form-primary-background\"></div>\n    </form>\n</div>\n";
},"useData":true});
