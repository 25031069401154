// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "col-xs-8";
},"3":function(container,depth0,helpers,partials,data) {
    return "col-xs-7";
},"5":function(container,depth0,helpers,partials,data) {
    return "                                    <img src=\"/images/nowifi-black.svg\">\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                                    <img src=\"/images/nowifi.svg\">\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                                    <img src=\"/images/wifi1-black.svg\">\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                                    <img src=\"/images/wifi1.svg\">\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                                    <img src=\"/images/wifi2-black.svg\">\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                                    <img src=\"/images/wifi2.svg\">\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "                                    <img src=\"/images/wifi3-black.svg\">\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "                                    <img src=\"/images/wifi3.svg\">\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "                                    <img src=\"/images/wifi4-black.svg\">\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "                                    <img src=\"/images/wifi4.svg\">\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "                        <span id=\"status-bar-cast-id\" class=\"status-bar-cast-id\">\n                            <span class=\"icon-v2-webcast\"></span>\n                            <span class=\"cast-id text\"></span>\n                        </span>\n                        <span id=\"status-bar-connection-id\" class=\"status-bar-connection-id\">\n                            <span class=\"icon-link\"></span>\n                            <span class=\"connection-id text\"></span>\n                        </span>\n";
},"27":function(container,depth0,helpers,partials,data) {
    return "col-xs-4";
},"29":function(container,depth0,helpers,partials,data) {
    return "col-xs-5";
},"31":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"divider\"></div>\n                        <div id=\"action-widget-container\" class=\"action-widget-container\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header id=\"status-bar\" class=\"status-bar container-fluid\">\n    <div class=\"row\">\n        <div class=\"left "
    + ((stack1 = (lookupProperty(helpers,"if_or")||(depth0 && lookupProperty(depth0,"if_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPure") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"if_or","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":25},"end":{"line":3,"column":95}}})) != null ? stack1 : "")
    + " grid-col-left\">\n            <custom-marquee id=\"status-bar-marquee\" speed=\"5000\" startOffset=\"5000\" isEndless=\"true\">\n                <span class=\"marquee-content\">\n                    <span id=\"device-ssid-container\" class=\"device-ssid-container\">\n                        <span class=\"wlan-quality-state\">\n                            <span class=\"wlan-quality-state-disconnected\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"lightUi") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":9,"column":32},"end":{"line":13,"column":39}}})) != null ? stack1 : "")
    + "                            </span>\n                            <span class=\"wlan-quality-state-low\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"lightUi") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":16,"column":32},"end":{"line":20,"column":39}}})) != null ? stack1 : "")
    + "                            </span>\n                            <span class=\"wlan-quality-state-mid\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"lightUi") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":23,"column":32},"end":{"line":27,"column":39}}})) != null ? stack1 : "")
    + "                            </span>\n                            <span class=\"wlan-quality-state-normal\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"lightUi") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":30,"column":32},"end":{"line":34,"column":39}}})) != null ? stack1 : "")
    + "                            </span>\n                            <span class=\"wlan-quality-state-high\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"lightUi") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":37,"column":32},"end":{"line":41,"column":39}}})) != null ? stack1 : "")
    + "                            </span>\n                        </span>\n                        <span id=\"device-ssid\" class=\"text\"></span>\n                    </span>\n                    <span id=\"device-wlan-ip\" class=\"text\">\n                        <span class=\"device-ip text\"></span>\n                    </span>\n                    <span>\n                        <span class=\"icon-v3-cynap-large\"></span>\n                        <span id=\"device-name\" class=\"text\"></span>\n                    </span>\n                    <span id=\"device-lan1-ip\">\n                        <span class=\"icon-network-drive\"></span>\n                        <span class=\"device-ip text\"></span>\n                    </span>\n                    <span id=\"device-lan2-ip\">\n                        <span class=\"icon-network-drive\"></span>\n                        <span class=\"device-ip text\"></span>\n                    </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":20},"end":{"line":70,"column":27}}})) != null ? stack1 : "")
    + "                    <span id=\"moderator-pin\" class=\"moderator-pin is-hidden\">\n                        <span class=\"icon-v2-moderator\"></span>\n                        <span class=\"moderator-pin-view text\"></span>\n                    </span>\n                    <span id=\"mirror-pin\" class=\"mirror-pin is-hidden\">\n                        <span class=\"icon-mirror\"></span>\n                        <span class=\"mirror-pin-view text\"></span>\n                    </span>\n                    <span id=\"user-pin\" class=\"user-pin is-hidden\">\n                        <span class=\"icon-v2-whiteboard\"></span>\n                        <span class=\"user-pin-view text\"></span>\n                    </span>\n                </span>\n            </custom-marquee>\n        </div>\n        <div class=\" right "
    + ((stack1 = (lookupProperty(helpers,"if_or")||(depth0 && lookupProperty(depth0,"if_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPure") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"if_or","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":86,"column":27},"end":{"line":86,"column":97}}})) != null ? stack1 : "")
    + " grid-col-right\">\n            <custom-marquee id=\"status-bar-marquee\" speed=\"5000\" startOffset=\"5000\" isEndless=\"true\">\n                <div class=\"widget-container\">\n                    <div id=\"status-widget-container\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasActionWidgets") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":20},"end":{"line":93,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </custom-marquee>\n        </div>\n    </div>\n</header>\n";
},"useData":true});
