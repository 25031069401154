'use strict';

var _ = require('lodash');
var eventEmitterMixin = require('../event-emitter').eventEmitterMixin;

var defaultOptions = {
    fps: 20
};

/**
 * Core engine of the redrawEngine
 * @class Engine
 * @constructor
 */
function RedrawEngine() {
    /**
     * Id of the current requestAnimationFrame loop
     * @property requestAnimationFrameId
     * @type Number
     * @private
     */
    this.requestAnimationFrameId = null;

    /**
     * Save the start time to calculate the delta time
     * inside the update loop
     * @property startTime
     * @type Number
     * @private
     */
    this.startTime = null;

    /**
     * Options are overwritten inside the start method
     * @property options
     * @type Object
     * @private
     */
    this.options = {};

    /**
     * State
     * @type {Boolean}
     */
    this.started = false;

    this.fps = defaultOptions.fps;

    /**
     * Add event emitter
     */
    eventEmitterMixin(this);
}

/**
 * The main update loop method
 * @method update
 * @private
 */
RedrawEngine.prototype.update = function update() {
    var currentTime = new Date().getTime();
    var deltaTime = currentTime - this.startTime;
    var interval = 1000 / this.fps;

    this.requestAnimationFrameId = requestAnimationFrame(this.update.bind(this));

    // DeltaTime < 0 can occur if the system time is changed
    if ((deltaTime > interval) || (deltaTime < 0)) {
        this.emit('redraw.update');
        this.startTime = currentTime - (deltaTime % interval);
    }
};

/**
 * Starts the update loop
 * @method update
 * @param {Object} options
 */
RedrawEngine.prototype.start = function start(options) {
    if (this.started) {
        return;
    }

    this.started = true;
    options = options || {};
    this.options = _.defaults(options, defaultOptions);
    this.startTime = new Date().getTime();

    this.fps = this.options.fps;

    if (this.requestAnimationFrameId) {
        cancelAnimationFrame(this.requestAnimationFrameId);
    }

    this.update();
};

/**
 * Stops the update loop
 * @method update
 */
RedrawEngine.prototype.stop = function stop() {
    cancelAnimationFrame(this.requestAnimationFrameId);
    this.started = false;
    this.startTime = null;
};

/**
 * Changes the update speed to 5 fps, to save performance on client side
 * @method slowDown
 */
RedrawEngine.prototype.slowDown = function slowDown() {
    this.fps = 5;
};

/**
 * Changes the update speed back to the normal speed
 * @method fullSpeed
 */
RedrawEngine.prototype.fullSpeed = function fullSpeed() {
    this.fps = this.options.fps;
};

module.exports = RedrawEngine;
