/* jslint bitwise: true */

'use strict';

var $ = require('jquery');
var i18n = require('i18next');
var app = require('../app');

require('jquery-xpath');

var forEach = Array.prototype.forEach;

/**
 * @method setProperty
 * @param {Object} context
 * @param {String} property
 * @param {Mixed} value
 */
function setProperty(context, property, value) {
    if (value || value === 0) {
        context[property] = value;
    }

    return context;
}

function toLowerCase(string) {
    var retValue = null;

    if (typeof string === 'string') {
        retValue = string.toLowerCase();
    }

    return retValue;
}

function parseIntByFormat(string, format) {
    var value = parseInt(string, 16);

    switch (format) {
        case '%d':
            // Mask to figure out if the MSB is set
            // If that returns a nonzero value, you know it is negative
            if ((value & 0x8000) > 0) {
                value = value - 0x10000;
            }

            break;
    }

    return value;
}

/**
 * Helper-Layer for the Visualizer-XML related settings
 * @class VisualizerXMLHelper
 */
function VisualizerXMLHelper() {
    /**
     * Model number of the connected Visualizer
     * @type {String}
     */
    this.visualizerId = null;

    /**
     * Current language code
     * @type {String}
     */
    this.language = i18n.language !== 'de' && i18n.language !== 'en' ? 'en' : i18n.language;

    /**
     * Xpath library reference
     * @type {Object}
     */
    this.xpath = $.xpath;

    /**
     * Reference to the loaded XML
     * @type {Object}
     */
    this.xml = null;
}

/**
 * @method setXML
 * @param {Object} xml
 */
VisualizerXMLHelper.prototype.setXML = function setXML(xml) {
    if (typeof xml === 'string') {
        xml = $.parseXML(xml);
    }

    this.xml = xml;
};

/**
 * @method setVisualizerId
 * @param {String} visualizerId
 */
VisualizerXMLHelper.prototype.setVisualizerId = function setVisualizerId(visualizerId) {
    this.visualizerId = visualizerId;
};

/**
 * @method getEntry
 * @param {String} entryId
 */
VisualizerXMLHelper.prototype.getEntry = function getEntry(entryId) {
    entryId = entryId || 'ROOT';
    var xpathExpression = '/OSD/p[@n="' + entryId + '"][@cynapinc="1"]';
    var xpathResult;

    xpathResult = this.xpath(this.xml, xpathExpression);

    return this.parseXML(xpathResult);
};

/**
 * @method parseXML
 * @param {Object} xml
 */
VisualizerXMLHelper.prototype.parseXML = function parseXML(xml) {
    var parsedEntry = {};
    var $xml;
    var includes;
    var excludes;

    if (!xml) {
        return;
    }

    excludes = this.getLimits(xml, 'exc');
    includes = this.getLimits(xml, 'inc');

    if ((includes && !this.checkLimitExist(includes)) || this.checkLimitExist(excludes)) {
        return null;
    }

    $xml = $(xml);

    setProperty(parsedEntry, 'title', this.xpath(xml, 't[@lang="' + this.language + '"]').text());
    setProperty(parsedEntry, 'description', this.xpath(xml, 'h[@lang="' + this.language + '"]').text());
    setProperty(parsedEntry, 'entries', this.parseEntries(xml, 'l[@cynapinc="1"]'));
    setProperty(parsedEntry, 'options', this.parseEntries(xml, 'v'));
    setProperty(parsedEntry, 'pn', $xml.attr('pn'));
    setProperty(parsedEntry, 'def', parseInt($xml.attr('def'), 16));
    setProperty(parsedEntry, 'par', parseInt($xml.attr('par'), 16));
    setProperty(parsedEntry, 'cmd', parseInt($xml.attr('cmd'), 16));
    setProperty(parsedEntry, 'sub', parseInt($xml.attr('sub'), 16));
    setProperty(parsedEntry, 'fmt', $xml.attr('fmt'));
    setProperty(parsedEntry, 'n', $xml.attr('n'));
    setProperty(parsedEntry, 'min', parseIntByFormat($xml.attr('min'), parsedEntry.fmt));
    setProperty(parsedEntry, 'max', parseIntByFormat($xml.attr('max'), parsedEntry.fmt));
    setProperty(parsedEntry, 'ctrl', $xml.attr('ctrl'));
    setProperty(parsedEntry, 'ctype', $xml.attr('ctype'));
    setProperty(parsedEntry, 'ico', toLowerCase($xml.attr('ico')));

    return parsedEntry;
};

/**
 * @method parseEntries
 * @param {Object} xml
 * @param {String} xpathExpr
 */
VisualizerXMLHelper.prototype.parseEntries = function parseEntries(xml, xpathExpr) {
    var entryNodes = this.xpath(xml, xpathExpr);
    var parsedEntries;

    if (entryNodes && entryNodes.length) {
        parsedEntries = [];

        forEach.call(entryNodes, function(entryNode) {
            var parsedEntry = this.parseXML(entryNode);

            if (parsedEntry) {
                parsedEntries.push(parsedEntry);
            }
        }.bind(this));
    }

    return parsedEntries;
};

/**
 * @method getLimits
 * @param {Object} xml
 * @param {String} limitType
 */
VisualizerXMLHelper.prototype.getLimits = function getLimits(xml, limitType) {
    var limitNodes = this.xpath(xml, limitType);
    var limits = null;

    if (limitNodes.length) {
        limits = [];

        forEach.call(limitNodes, function(limitNode) {
            limits.push($(limitNode).text());
        }.bind(this));
    }

    return limits;
};

/**
 * @method checkLimitExist
 * @param {Object} limits
 */
VisualizerXMLHelper.prototype.checkLimitExist = function checkLimitExist(limits) {
    return !!(limits && limits.length && (limits.indexOf(this.visualizerId) > -1));
};

app.service('VisualizerSettingsService', function() {
    var xmlHelper = new VisualizerXMLHelper();

    return {
        /**
         * @method initialize
         */
        initialize: function() {
            app.getService('ConnectionFactoryService')
                .afterCreated('visualizer', function(visualizerConnection) {
                    this.visualizerConnection = visualizerConnection;
                }.bind(this));
        },

        /**
         * @method getEntry
         * @param {String} entryId
         */
        getEntry: function(entryId) {
            var dfd = $.Deferred();

            if (!xmlHelper.xml) {
                this.load().then(function() {
                    dfd.resolve(xmlHelper.getEntry(entryId));
                });
            } else {
                dfd.resolve(xmlHelper.getEntry(entryId));
            }

            return dfd.promise();
        },

        /**
         * Load the visualizer settings xml
         * @method load
         */
        load: function() {
            return this.visualizerConnection
                .send([
                    'getVzDirectOsd',
                    'getVzDirectModel'
                ])
                .then(function(osdData, vzModelData) {
                    xmlHelper.setXML(osdData.xml);
                    xmlHelper.setVisualizerId(vzModelData.vzModel);
                });
        }
    };
});
