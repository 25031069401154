'use strict';

var app = require('../../../app');
var TouchEvents = require('./entities/TouchEvents');

/**
 * FrameBoxBaseType blueprint
 */
app.component('FrameBoxBaseType', {
    send: function(commandName, commandObject) {
        this.stationService = this.getService('StationService');
        if (this.stationService.getLockStatus()) {
            return;
        }

        this.freezeService = this.getService('FreezeService');
        var freeze = this.freezeService.getFreeze();

        if (!this.frameBoxService.isInBackground && !freeze) {
            this.deviceConnection
                .send(commandName, commandObject);
        }
    },

    startTouchEventsEntity: function(options) {
        if (!options && this.options) {
            options = this.options;
        } else {
            this.on('framebox.unbind-events', this.stopTouchEventsEntitiy.bind(this));
            this.on('framebox.bind-events', this.startTouchEventsEntity.bind(this));
        }
        options.index = this.index;
        options.appId = this.appId;

        this.touchEventsEntity = new TouchEvents(options);
    },

    stopTouchEventsEntitiy: function() {
        if (this.touchEventsEntity) {
            this.touchEventsEntity.destroy();
        }
    },

    destroy: function() {
        this.stopTouchEventsEntitiy();
    }
});
