// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"warning-container\">\n    <div class=\"notification-header\">\n        <span class=\"icon icon-msg-system\"></span>\n        <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":14},"end":{"line":4,"column":35}}}))
    + "</span>\n    </div>\n    <div class=\"warning-message\">\n        <div class=\"centered\">\n            <span id=\"msg-selection\" class=\"input-uppercase\">\n                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wlan_region.message_selection",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":62}}}))
    + "\n            </span>\n            <h1>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"country") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":34}}}))
    + "</h1>\n        </div>\n        <div>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wlan_region.message_not_revised",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":13},"end":{"line":13,"column":61}}}))
    + "</div>\n    </div>\n\n    <div class=\"form-action-container\" style=\"display: block;\">\n        <input id=\"confirm-discare\"\n               type=\"button\"\n               class=\"btn-form form-action-discare focusable\"\n               value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":22},"end":{"line":20,"column":45}}}))
    + "\"/>\n        <input id=\"confirm-submit\"\n               type=\"button\"\n               class=\"btn-form form-action-submit focusable\"\n               value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.save_permanently",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":22},"end":{"line":24,"column":55}}}))
    + "\"/>\n    </div>\n</div>\n";
},"useData":true});
