'use strict';

/**
 * Load some settings before bootstrap has been called.
 *
 * @param app
 */
module.exports.bootstrap = function CPMStartupScriptBootstrap(app) {
    require('./../services/models/CPM-View');
    app.getService('Model-View');

    require('./../services/framebox');
    app.getService('FrameBoxService');
};

module.exports.basic = function CPMStartupScript(rbac, app) {
    if (rbac.hasAccess('MainMenu', 'show')) {
        app.createComponent('mainMenu', {
            type: 'MainMenu',
            container: '#main-menu-container'
        });
    }

    if (!this.authService.getIsCollab()) {
        app.createComponent('statusBar', {
            type: 'StatusBar',
            container: '#status-bar-container'
        });

        if (rbac.hasAccess('ControlCenter', 'show')) {
            app.createComponent('controlCenter', {
                type: 'ControlCenterV3',
                container: '#control-center'
            });
        }
    }

    /**
     * Start splash screen on pure device.
     */
    app.createComponent('splashScreen', {
        type: 'SplashScreen',
        container: '#splash-screen'
    });

    app.emit('startup-script.finished');
};

/**
 * Start Matrix.
 */
module.exports.matrix = function() {
    // Cynap Pure Mini has no Matrix.
};
