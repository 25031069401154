'use strict';

/**
 * Load some settings before bootstrap has been called.
 * If dual projection mode is active load framebox service for dual projection.
 *
 * @param app
 */
module.exports.bootstrap = function CBRStartupScriptBootstrap(app, isDualProjection) {
    require('./../services/models/CBR-View');
    app.getService('Model-View');

    if (isDualProjection) {
        require('./../services/framebox-dual-projection');
    } else {
        require('./../services/framebox');
    }

    app.getService('FrameBoxService');
};

module.exports.basic = function CBRStartupScript(rbac, app) {
    if (rbac.hasAccess('MainMenu', 'show')) {
        app.createComponent('mainMenu', {
            type: 'MainMenu',
            container: '#main-menu-container'
        });
    }

    if (!this.authService.getIsCollab()) {
        if (rbac.hasAccess('SourcesMenu', 'show')) {
            app.createComponent('menu', {
                type: 'SourcesMenu',
                container: '#sources-menu-container'
            });
        }

        if (rbac.hasAccess('FileBrowser', 'show')) {
            app.createComponent('file-browser', {
                type: 'FileBrowserV3',
                container: '#file-browser-container'
            });
        }

        app.createComponent('statusBar', {
            type: 'StatusBar',
            container: '#status-bar-container'
        });

        if (rbac.hasAccess('ControlCenter', 'show')) {
            app.createComponent('controlCenter', {
                type: 'ControlCenterV3',
                container: '#control-center'
            });
        }

        if (rbac.hasAccess('ControlScreen', 'show', true)) {
            app.createComponent('controlScreen', {
                type: 'ControlScreen',
                container: '#control-screen-container'
            });
        }
    }

    /**
     * Start splash screen.
     */
    app.createComponent('splashScreen', {
        type: 'SplashScreen',
        container: '#splash-screen'
    });

    app.emit('startup-script.finished');
};

/**
 * Start Matrix.
 *
 * @param rbac
 * @param app
 */
module.exports.matrix = function(rbac, app) {
    if (rbac.hasAccess('MatrixFlap', 'show')) {
        app.createComponent('matrixFlap', {
            type: 'MatrixFlap',
            container: '#matrix-flap-container'
        });

        app.getService('MatrixConfigs');
    }
};
