'use strict';

var app = require('../../../app');
var annotationTpl = require('./framebox-annotation.hbs');

app.component('FrameboxAnnotation', {
    template: annotationTpl,
    className: 'framebox-annotation-container framebox-submenu-inner',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;

        this.remote = this.getService('RemoteService');
        this.formManager = this.getService('FormManager');
    },

    serialize: function() {
        return {
            index: this.index
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.initForm();
        this.bindEvents();
    },

    storeSelectors: function() {
        this.$document = this.$(document);
        this.$input = this.$el.find('input');
    },

    bindEvents: function() {
        this.on('framebox.annotation.open', this.open.bind(this));
        this.on('framebox.submenu.close', this.close.bind(this));
    },

    unbindDOMEvents: function() {
        this.$document.off('click.framebox-annotation');
    },

    /**
     * Open annotation submenu.
     *
     * @param options.index Framebox ID
     *        options.id Framebox submenu ID (e.g. tool, color etc.)
     */
    open: function(options) {
        if (this.index === options.index) {
            this.showItem(options.id);
            this.loadData();
            this.$el.show();

            this.emit('framebox.submenu.change', this.index, true, this.$el.parent());
        }
    },

    /**
     * Show correct framebox submenu with itemID
     * @param itemId Framebox submenu ID
     */
    showItem: function(itemId) {
        var $annotationElements = this.$el.find('.framebox-annotation');
        var $activeElement = this.$el.find('#framebox-annotation-' + itemId + '-' + this.index);

        $annotationElements.addClass('hidden');
        $activeElement.removeClass('hidden');
    },

    /**
     * Close submenu.
     */
    close: function() {
        this.$el.hide();
        this.remote.destroyArea(this.$el);
        this.unbindDOMEvents();

        this.emit('framebox.submenu.change', this.index, false);
    },

    loadData: function() {
        this.deviceConnection
            .send('getContentPerOutput')
            .then(function(data) {
                this.frameboxData = data.windows.find(w => w.index === this.index);
            }.bind(this));
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('.whiteboard-annotation-bar'),
            submit: true
        });
    }
});
