module.exports={
    // NOTE:
    // 100 = AltGr
    "modifier":
    {
        "&#166;": 100, //¦
        "&#8364;": 100 //€
    },

    "modifier_mobile": {
        "&#64;": 42, //@
        "&#126;": 42, //~
        "&#33;": 42, //!
        "&#36;": 42, //$
        "&#37;": 42, //%
        "&#94;": 42, //^
        "&#38;": 42, //&
        "&#42;": 42, //*
        "&#40;": 42, //(
        "&#41;": 42, //)
        "&#95;": 42, //_
        "&#43;": 42, //+

        "&#123;": 42, //{
        "&#125;": 42, //}

        "&#58;": 42, //:
        "&#34;": 42, //"

        "&#124;": 42, //|
        "&#60;": 42, //<
        "&#62;": 42, //>
        "&#63;": 42 //?
    },

    "ie_specialkeys_mobile": {},

    "ie_specialkeys": {},

    "&#96;": 41, //`
    "&#172;": 41, //¬
    "&#166;": 41, //¦
    "&#34;": 3, //"
    "&#163;": 4, //£
    "&#8364;": 5, //€

    "&#39;": 40, //'
    "&#64;": 40, //@
    "atmark": 40, //@
    "&#35;": 43, //#
    "&#126;": 43, //~

    "&#92;": 86, //\
    "&#124;": 86 //|
}
