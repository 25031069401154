'use strict';

var _ = require('lodash');
var app = require('../../../../app');
var ethernetSettingsTpl = require('./wlan-list.hbs');
var wlanListItemTpl = require('./wlan-list-item.hbs');
var wlanListGroupTpl = require('./wlan-list-group.hbs');
var ScrollView = require('./../../../../../scroll-view');

var wlanStates = {
    'searching': 'searching',
    'success': 'success',
    'failed': 'failed'
};

var connectionStates = {
    'connected': 'connected',
    'disconnected': 'disconnected'
};

var encryptionMapping = {
    '': 'none',
    'wep': 'wep',
    'wpa': 'wpa',
    'rsn': 'wpa'
};

app.component('WlanList', {
    template: ethernetSettingsTpl,
    connectedWlan: null,
    init: false,

    getAccessKey: function() {
        return {
            'roleName': 'WlanSettings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.wlanListRaw = [];
        this.ssidStore = {};
        this.bssidStore = {};
        this.remote = this.getService('RemoteService');
        this.band = this.options.configs.band;

        this.wlanState = this.createStateMachine({
            state: wlanStates.failed,
            states: wlanStates
        });

        this.connectionState = this.createStateMachine({
            state: connectionStates.disconnected,
            states: connectionStates
        });

        this.addStateTransitions();
    },

    postPlaceAt: function() {
        this.scrollView = new ScrollView({
            $container: this.$el,
            $items: this.$el,
            itemSelector: '.is-focusable'
        });

        this.storeSelectors();
        this.refresh();
        this.updateListState();
        this.bindDOMEvents();
        this.bindEvents();

        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_refresh',
            actionBtnType: 'refresh'
        });
    },

    storeSelectors: function() {
        this.$wlanListHeader = this.$el.find('#wlan-list-header');
        this.$wlanList = this.$el.find('#wlan-list-items');
        this.$loader = this.$el.find('#wlan-list-loader');
        this.$noEntry = this.$el.find('#wlan-list-no-entry');
        this.$expandCollapse = this.$wlanListHeader.find('.icon-arrow-options');
    },

    bindEvents: function() {
        this.on('main-loop.update', this.loopUpdateHandler.bind(this));
        this.on('main-loop.update.wlan', this.loopUpdateHandler.bind(this));
        this.on('overlay.action', this.refreshHandler.bind(this));
    },

    bindDOMEvents: function() {
        this.$wlanList.on('click', '.clickable.wlan-list-item', this.selectAccessPoint.bind(this));
        this.$wlanList.on('click', '.clickable.wlan-list-group', this.selectGroup.bind(this));
        this.$wlanListHeader.on('click', '.clickable[data-action="expand-collapse"]', this.toggleExpandCollapseGroups.bind(this));
        this.$wlanListHeader.on('click', '.clickable[data-action="sort"]', this.toggleSortWlanList.bind(this));
    },

    /**
     * Update wlan list state.
     */
    updateListState: function() {
        this.deviceConnection
            .send('getWlanApListStatus')
            .then(function(data) {
                var state = wlanStates[data.apListStatus];

                if (this.wlanState.getState() !== state) {
                    this.wlanState.changeState(state);
                }
            }.bind(this));

        this.deviceConnection
            .send('getWlanStatus')
            .then(function(data) {
                var state = connectionStates[data.wlanStatus];

                if (this.connectionState.getState() !== state) {
                    this.connectionState.changeState(state);
                }
            }.bind(this));
    },

    /**
     * Refresh list clicked.
     *
     * @param data
     */
    refreshHandler: function(data) {
        if (data.type === 'refresh') {
            this.refresh();
        }
    },

    /**
     * Start search for access points and refresh wlan list.
     */
    refresh: function() {
        this.wlanState.changeState(wlanStates.searching);
        this.deviceConnection
            .send('setStartWlanAccesspointScan');
    },

    /**
     * Update handler.
     */
    loopUpdateHandler: function() {
        this.updateListState();
    },

    /**
     * Load wlan settings.
     */
    loadWlanSettings: function() {
        this.deviceConnection
            .send([
                'getInfraSsidName',
                'getWifiApList'
            ])
            .then(this.parse.bind(this));
    },

    /**
     * Parse wlan list we get from the backend.
     *
     * @param ssidName Current connected access point.
     * @param wifiList WLAN access point list
     */
    parse: function(ssidName, wifiList) {
        var data = this.prepareWlanListData(wifiList);

        this.connectedWlan = ssidName.ssidName;
        this.renderWlanList(data);
    },

    /**
     * Show/Hide  WLAN list, loader, or info.
     * Render WLAN access point list.
     *
     * @param data Prepared WLAN list data (filtered (band), sorted and grouped)
     * @param keepFocus keep focus on current button (e.g. sort arrow)
     */
    renderWlanList: function(data, keepFocus) {
        if (data.length) {
            this.$noEntry.hide();
            this.$wlanListHeader.show();
        } else {
            this.$wlanListHeader.hide();
            this.$loader.hide();
            this.$noEntry.show();
            this.remote.focus(this.$('.overlay-action-btn-refresh').get(0));
        }

        this.ssidStore = {};
        this.bssidStore = {};

        // Clear and render new list.
        this.$wlanList.empty();
        this.renderList(data.data);

        this.$wlanList.show();
        this.$loader.hide();

        if (!keepFocus) {
            this.remote.focus(this.$wlanList.find('.is-focusable').get(0));
        }
    },

    /**
     * Render list items depending on type (item - access point list, or group)
     *
     * @param data Prepared WLAN list data (filtered (band), sorted and grouped)
     */
    renderList: function(data) {
        var item;
        var index = 0;
        var groupIndex = 0;

        this.$expandCollapse.hide();

        for (var itemName in data) {
            item = data[itemName];

            if (item.length === 1) {
                this.renderListItem(item[0], index);
                index++;
            } else {
                index = this.renderListGroup(item, groupIndex, itemName, index);
                groupIndex++;
                this.$expandCollapse.show();
            }
        }
    },

    /**
     * Render group and append to list.
     *
     * @param group Current group (group of access points with same SSID)
     * @param key Current group index
     * @param name Name of group
     * @param index Current index
     */
    renderListGroup: function(group, key, name, index) {
        group.key = key;
        group.name = name;
        var $group = this.$(wlanListGroupTpl(group));
        var $itemContainer = $group.find('.wlan-list-item-container');

        for (var itemName in group) {
            if (typeof group[itemName] !== 'object') {
                continue;
            }

            this.renderListItem(group[itemName], index, $itemContainer);
            index++;
        }

        this.$wlanList.append($group);

        return index;
    },

    /**
     * Render item and append to list.
     *
     * @param item Current item (access point)
     * @param key Current index
     * @param $group Group where the item is in (can be undefined if item isn't in a group, i.e. there is only one access point with SSID)
     */
    renderListItem: function(item, key, $group) {
        var $qualityItem;
        var wlanQuality;
        var wlanEncryption;
        var $container = $group || this.$wlanList;

        item.key = key;
        this.ssidStore[key] = item.name;
        this.bssidStore[key] = item.bssid;
        var $item = this.$(wlanListItemTpl(item));

        wlanQuality = parseInt(item.quality);
        $qualityItem = $item.find('.wlan-quality-state');

        if (wlanQuality > 0 && wlanQuality < 26) {
            wlanQuality = 'low';
        } else if (wlanQuality > 25 && wlanQuality < 51) {
            wlanQuality = 'mid';
        } else if (wlanQuality > 50 && wlanQuality < 76) {
            wlanQuality = 'normal';
        } else if (wlanQuality > 75) {
            wlanQuality = 'high';
        }

        $qualityItem.attr('data-wlan-quality-state', wlanQuality);

        wlanEncryption = encryptionMapping[item.encryption];

        if (wlanEncryption && wlanEncryption !== 'none') {
            $item.find('.icon-lock1').show();
            $item.find('h2').removeClass('unlocked');
        } else {
            $item.find('h2').addClass('unlocked');
        }

        if ($group) {
            $item.find('.wlan-list-item').addClass('no-padding');
            $item.find('.wlan-list-item').addClass('subitem');
        }

        if (item.isConnected) {
            $item.find('.wlan-list-item').addClass('is-connected');
        } else {
            $item.find('.wlan-list-item').removeClass('is-connected');
        }

        // Append item to the list.
        $container.append($item);
    },

    /**
     * Pepare Wlan list data for rendering.
     * Check band (if 2.4/5 or both is selected) and call method to sort and group list.
     *
     * @param data Wlan list
     */
    prepareWlanListData: function(data) {
        var preparedData = [];

        // Check if a band is selected (2.4 ghz / 5 ghz)
        _.each(data.apList, function(item) {
            item.isConnected = (item.name === this.connectedWlan);

            if (this.band === 'both' || this.checkBand(this.band, item.frequency)) {
                preparedData.push(item);
            }
        }.bind(this));

        this.wlanListRaw = preparedData; // Save wlan list without sorting and grouping for later use.

        preparedData = this.sortAndGroupWlanList(preparedData, false);

        return preparedData;
    },

    /**
     * Sort and group Wlan access point list by SSID/name and sort items in group by signal level.
     *
     * @param data wlan list.
     * @param desc true - sort descending; false - sort ascending (default)
     */
    sortAndGroupWlanList: function(data, desc) {
        var wlanList;

        data = this.sortListByProperty(data, 'name', desc);
        wlanList = this.groupListByName(data);

        // Sort in group by signal level
        for (var itemName in wlanList.data) {
            wlanList.data[itemName] = this.sortListByProperty(wlanList.data[itemName], 'signallevel', true);
        }

        return wlanList;
    },

    /**
     * Handle click on SSID header and sort list by SSID ascending or descending (toggle).
     *
     * @param event Current event
     */
    toggleSortWlanList: function(event) {
        var list;
        var $item = this.$(event.currentTarget);

        if ($item.hasClass('asc')) {
            list = this.sortAndGroupWlanList(this.wlanListRaw, true);
            $item.removeClass('asc');
        } else {
            list = this.sortAndGroupWlanList(this.wlanListRaw, false);
            $item.addClass('asc');
        }

        this.renderWlanList(list, true);
    },

    /**
     * Sort AP list by property with name propName (e.g. SSID/Name, signallevel)
     *
     * @param data AP list
     * @param desc true - sort descending; false - sort ascending (default)
     */
    sortListByProperty: function(data, propName, desc) {
        data.sort(function(item1, item2) {
            var comparison = 0;
            var prop1 = item1[propName];
            var prop2 = item2[propName];

            if (desc) {
                prop1 = item2[propName];
                prop2 = item1[propName];
            }

            // Sort is case sensitive, so compare lowercase
            if (typeof prop1 === 'string' && typeof prop2 === 'string') {
                prop1 = prop1.toLowerCase();
                prop2 = prop2.toLowerCase();
            }

            if (prop1 > prop2) {
                comparison = 1;
            } else if (prop1 < prop2) {
                comparison = -1;
            }

            return comparison;
        }.bind(this));

        return data;
    },

    /**
     * Group list by SSID/name.
     *
     * @param data wlan list
     */
    groupListByName: function(data) {
        var grouped = {
            length: 0,
            data: []
        };

        data.forEach(function(item) {
            if (!grouped.data[item.name]) {
                grouped.length++;
                grouped.data[item.name] = [];
            }
            grouped.data[item.name].push(item);
        });

        return grouped;
    },

    /**
     * Check band and return if the item should be listed or not.
     *
     * @param band 5 ghz /2.4 ghz
     * @param frequency access point frequency.
     * @returns {boolean} true/false
     */
    checkBand: function(band, frequency) {
        switch (band) {
            case '5ghz':
                return (frequency >= 5.150 && frequency <= 5.350) || (frequency >= 5.470 && frequency <= 5.725);
            case '24ghz':
                return (frequency >= 2.3995 && frequency <= 2.4845);
        }
    },

    /**
     * Handle click on expand/collapse icon in header and expand or collapse (fold or unfold) all groups (toggle).
     *
     * @param event Current event
     */
    toggleExpandCollapseGroups: function(event) {
        var $item = this.$(event.currentTarget);
        var $subItems = this.$el.find('.wlan-list-item-container');
        var $groups = this.$el.find('.wlan-list-group');

        if (!$item.hasClass('folded')) {
            this.collapseGroup($groups, $subItems);

            $item.addClass('folded');
            $item.find('.icon-arrow-options').addClass('folded');
        } else {
            this.expandGroup($groups, $subItems);

            $item.removeClass('folded');
            $item.find('.icon-arrow-options').removeClass('folded');
        }
    },

    /**
     * Select group.
     *
     * @param event Current event
     */
    selectGroup: function(event) {
        var $item = this.$(event.currentTarget);
        var $subItems = this.$el.find('.wlan-list-item-container[data-key="' + $item.data('key') + '"]');

        if (!$item.hasClass('folded')) {
            this.collapseGroup($item, $subItems);
        } else {
            this.expandGroup($item, $subItems);
        }
    },

    /**
     * Expand/unfold selected group.
     *
     * @param $group Current selected group.
     */
    expandGroup: function($group, $subItems) {
        $subItems
            .stop()
            .slideDown(300);

        $group.removeClass('folded');
        $group.find('.icon-arrow-options').removeClass('folded');
    },

    /**
     * Collapse/fold selected group.
     *
     * @param $group Current selected group.
     */
    collapseGroup: function($group, $subItems) {
        $subItems
            .stop()
            .slideUp(300);

        $group.addClass('folded');
        $group.find('.icon-arrow-options').addClass('folded');
    },

    /**
     * Select access point.
     *
     * @param event
     */
    selectAccessPoint: function(event) {
        var $el = this.$(event.currentTarget);
        var encryption = encryptionMapping[$el.data('encryption')];
        var key = $el.data('key');
        var ssid = this.ssidStore[key];
        var bssid = this.bssidStore[key];

        this.emit('overlay.history.back', {
            ssid: ssid,
            bssid: bssid,
            encryption: encryption
        });

        event.preventDefault();
    },

    addStateTransitions: function() {
        this.wlanState.addTransitions({
            '> searching': function() {
                this.$noEntry.hide();

                // SetTimeout is needed to wait for all animations,
                // This will fix some performance issues with the loader.
                setTimeout(function() {
                    if (this.wlanState.getState() === wlanStates.searching) {
                        this.$loader.show();
                        this.$wlanListHeader.hide();
                        this.$wlanList.hide();
                    }
                }.bind(this), 350);
            },
            '> success': function() {
                this.loadWlanSettings();
            },
            '> failed': function() {
                this.$loader.hide();
                this.$wlanListHeader.hide();
                this.$wlanList.hide();
                this.$noEntry.show();
            }
        });
    },

    destroy: function() {
        this.scrollView.destroy();
    }
});
