'use strict';

var app = require('../../../../app');
var ethernetSettingsTpl = require('./ethernet-settings-v6.hbs');
var FormActionView = require('../../form-action-view');

app.component('EthernetSettingsV6', {
    template: ethernetSettingsTpl,
    actionView: null,

    getAccessKey: function() {
        return {
            'roleName': 'EthernetSettings',
            'roleKey': 'show'
        };
    },

    /**
     * @method initialize
     * @returns {*}
     */
    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.device = this.getService('DeviceService');
        this.remote = this.getService('RemoteService');
        this.validate = this.getService('ValidateService');

        return this.loadNetworkSettings();
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.initForm();
        this.initFormAction();
        this.updateForm();
        this.bindDOMEvents();
        this.bindEvents();
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.form.get('dhcp').$el.on('change', this.handleDhcpChange.bind(this));
    },

    bindEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
    },

    /**
     * @method initFormAction
     */
    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(this.app, {
                selector: this.$el.find('#form-action-container-v6'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    /**
     * @method saveHandlerV6
     */
    saveHandler: function() {
        if (this.form.validate()) {
            this.device.saveEthernetSettingsV6(this.form.serialize())
                .done(function() {
                    this.device.activateEthernetSettings(function() {
                        this.handleSettingsSave();
                        this.form.setDefaultValues();
                    }.bind(this));
                }.bind(this));
        }
    },

    /**
     * Calls on clicked the cancel button
     * @method cancelHandler
     */
    cancelHandler: function() {
        this.form.resetValues();
        this.checkActionForm();
    },

    /**
     * @method checkActionForm
     */
    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * @method updateForm
     */
    updateForm: function() {
        this.form.setValues(this.formData);

        if (this.form.get('dhcp').getValue()) {
            this.disableSettings();
        }
    },

    /**
     * @method handleFormChangeV6
     */
    handleFormChange: function() {
        this.actionView.open();
        this.remote.update();
    },

    /**
     * @method handleDhcpChange
     */
    handleDhcpChange: function() {
        var value = this.form.get('dhcp').getValue();

        if (value) {
            this.disableSettings();
        } else {
            this.enableSettings();
        }
    },

    /**
     * @method initForm
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#ethernet-settings-form-v6'),
            validationContainer: '.input-group'
        });
    },

    /**
     * @method enableSettings
     */
    enableSettings: function() {
        this.form.get('ip').enable();
        this.form.get('subnet').enable();
        this.form.get('gateway').enable();
        this.form.get('dns').enable();
    },

    /**
     * @method disableSettings
     */
    disableSettings: function() {
        this.form.get('ip')
            .setValue(this.formData.ip)
            .disable();

        this.form.get('subnet')
            .setValue(this.formData.subnet)
            .disable();

        this.form.get('gateway')
            .setValue(this.formData.gateway)
            .disable();

        this.form.get('dns')
            .setValue(this.formData.dns)
            .disable();
    },

    /**
     * @method handleSettingsSave
     */
    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });
    },

    /**
     * @method loadNetworkSettings
     * @returns {*}
     */
    loadNetworkSettings: function() {
        var dfd = this.$.Deferred();

        this.device.fetchEthernetSettingsV6(function(data) {
            this.formData = {
                dhcp: data.dhcpSetting,
                ip: data.ipAddress,
                subnet: data.subnet,
                gateway: data.gateway,
                dns: data.dns
            };

            dfd.resolve(this.formData);
        }.bind(this));

        return dfd.promise();
    },

    /**
     * @method destroy
     */
    destroy: function() {
        this.form.destroy();
        if (this.actionView) {
            this.actionView.destroy();
        }
    }

});
