'use strict';

var app = require('../app');
var _ = require('lodash');
var StateMachine = require('./../state-machine');

/**
 * VZ connection states
 * @type {{pending: string, connected: string, disconnected: string}}
 */
var connectionStates = {
    pending: 'pending',
    connected: 'connected',
    disconnected: 'disconnected'
};

/**
 * VZ states
 * @type {{open: string, closed: string}}
 */
var states = {
    open: 'open',
    closed: 'closed'
};

var modes = require('./../states').visualizerModes;

/**
 * States for controls (auto-focus, freeze, power)
 * @type {{none: string, on: boolean, off: boolean}}
 */
var controlStates = {
    none: 'none',
    on: true,
    off: false
};

app.service('VisualizerService', function(app) {
    return {
        vzEvents: [],

        /**
         * @method initialize
         */
        initialize: function() {
            this.deviceService = app.getService('DeviceService');
            this.connectionFactory = app.getService('ConnectionFactoryService');
            this.configs = app.getService('ConfigsService');
            this.freezeService = app.getService('FreezeService');
            this.visualizerConnection = null;

            this.state = new StateMachine({
                context: this,
                state: states.closed,
                states: states
            });

            this.connectionState = new StateMachine({
                context: this,
                state: connectionStates.pending,
                states: connectionStates
            });

            this.mode = new StateMachine({
                context: this,
                state: modes.none,
                states: modes
            });

            this.powerState = new StateMachine({
                context: this,
                state: controlStates.none,
                states: controlStates
            });

            this.afState = new StateMachine({
                context: this,
                state: controlStates.none,
                states: controlStates
            });

            this.freezeState = new StateMachine({
                context: this,
                state: controlStates.none,
                states: controlStates
            });

            this.addStateTransitions();

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;

                    this.bindEvents();
                }.bind(this));

            if (!this.deviceService.isCboxPureOrPro() && !this.deviceService.isCboxPureMini()) {
                app.getService('ConnectionFactoryService')
                    .afterCreated('visualizer', function(visualizerConnection) {
                        this.visualizerConnection = visualizerConnection;
                        this.handleVisualizerSettings();
                    }.bind(this));
            }
        },

        /**
         * Bind events.
         */
        bindEvents: function() {
            app.on('main-loop.update', this.updateHandler.bind(this));
            app.on('main-loop.update.visualizer', this.updateHandler.bind(this));
            app.on('visualizer.state.change', this.changeState.bind(this));
        },

        /**
         * Binds the visualizer remote events and saves the destroy method in the vzEvents array.
         */
        bindVzEvents: function() {
            if (this.vzEvents.length === 0) {
                this.vzEvents.push(app.on('remote.autofocus', this.getPressedHandler('auto-focus').bind(this)));
            }
        },

        /**
         * Destroy each saved visualizer remote event
         */
        unbindVzEvents: function() {
            _.each(this.vzEvents, function(event) {
                // Destroy event
                event();
            });

            this.vzEvents = [];
        },

        /**
         * Change state (open/closed)
         *
         * @param options
         */
        changeState: function(options) {
            this.state.changeState(states[options.state]);
        },

        /**
         * Get state.
         *
         * @returns {*|String|string}
         */
        getState: function() {
            return this.state.getState();
        },

        /**
         * Get and update Visualizer states (features, control states, mode, etc.).
         * Handle VZ settings and framebox controls.
         */
        updateHandler: function() {
            this.loadVzStatus()
                .then(function(vzState) {
                    var updateSettings = false;
                    var connection = vzState.vzConnected ? connectionStates.connected : connectionStates.disconnected;

                    this.ip = vzState.vzIp;
                    this.features = vzState.vzFeatures;

                    if (this.connectionState.getState() !== connection) {
                        this.connectionState.changeState(connection);

                        updateSettings = true;
                    }

                    if (this.mode.getState() !== vzState.vzMode) {
                        this.mode.changeState(vzState.vzMode);
                        app.emit('visualizer.mode.changed');
                        updateSettings = true;
                    }

                    if (this.powerState.getState() !== vzState.vzPowerState) {
                        this.powerState.changeState(vzState.vzPowerState);

                        updateSettings = true;
                    }

                    if (this.afState.getState() !== vzState.vzAFState) {
                        this.afState.changeState(vzState.vzAFState);
                    }

                    if (this.freezeState.getState() !== vzState.vzFreezeState) {
                        this.freezeState.changeState(vzState.vzFreezeState);
                    }

                    app.emit('visualizer.controls.update');

                    if (updateSettings) {
                        this.handleVisualizerSettings();
                    }
                }.bind(this));
        },

        /**
         * Handle Visualizer settings. Start connection if VZ is connected, powered on and has full integration support.
         */
        handleVisualizerSettings: function() {
            if (this.isConnected() && this.powerState.currentState && this.mode.currentState === modes.full) {
                this.startVisualizerConnection();
            } else {
                this.stopVisualizerConnection();
            }
        },

        /**
         * Connect to the visualizer websocket server if supported (mode === full (full integration support))
         * Also enable visualizer icon in settings if everything is OK.
         */
        startVisualizerConnection: function() {
            if (this.mode.currentState !== modes.full || this.deviceService.isCboxPureOrPro()
                || this.deviceService.isCboxPureMini() || this.features.pf4) {
                app.emit('visualizer.disconnected');

                return;
            }

            if (!this.visualizerConnection) {
                this.visualizerConnection = this.connectionFactory.create('visualizer', {
                    host: this.configs.get('visualizer.host'),
                    onError: function() {
                        this.stopVisualizerConnection();
                    }.bind(this)
                });

                this.visualizerConnection.connect();
            }

            app.emit('visualizer.connected');
        },

        /**
         * Disconnect to the visualizer websocket server.
         * Also disable visualizer icon in settings.
         */
        stopVisualizerConnection: function() {
            if (this.visualizerConnection) {
                this.visualizerConnection.close();
                this.visualizerConnection = null;
            }

            app.emit('visualizer.disconnected');
        },

        /**
         * Load visualizer status.
         *
         * @returns {jQuery.Deferred}
         */
        loadVzStatus: function() {
            return this.deviceConnection
                .send([
                    'getVzStatus'
                ]);
        },

        /**
         *Add state transitions of connection state.
         */
        addStateTransitions: function() {
            this.connectionState.addTransitions({
                '> connected': function() {
                    this.bindVzEvents();
                },
                'connected > disconnected': function() {
                    this.unbindVzEvents();
                }
            });
        },

        /**
         * Returns true when the visualizer is connected.
         *
         * @returns {boolean}
         */
        isConnected: function() {
            if (this.connectionState.getState() === connectionStates.connected) {
                return true;
            }

            return false;
        },

        /**
         * Zoom in with a certain speed.
         *
         * @param {Object} data.speed Zoom speed
         */
        zoomIn: function(data) {
            if (!data.speed || data.speed === 0) {
                data.speed = 0x0E;
            }

            this.deviceConnection
                .send('setVzControl', {
                    vzControl: 'zoomTele',
                    speed: data.speed,
                    appId: data.appId
                });
        },

        /**
         * Zoom out with a certain speed.
         *
         * @param {Object} data.speed Zoom speed
         */
        zoomOut: function(data) {
            if (!data.speed || data.speed === 0) {
                data.speed = 0x0E;
            }

            this.deviceConnection
                .send('setVzControl', {
                    vzControl: 'zoomWide',
                    speed: data.speed,
                    appId: data.appId
                });
        },

        /**
         * Stop the zooming.
         */
        stopZoom: function(data) {
            this.deviceConnection
                .send('setVzControl', {
                    vzControl: 'zoomWide',
                    speed: 0,
                    appId: data.appId
                });
        },

        /**
         * Focus in with a certain speed of 5.
         */
        focusIn: function(data) {
            this.deviceConnection
                .send('setVzControl', {
                    vzControl: 'focusNear',
                    speed: 5,
                    appId: data.appId
                });

            this.updateState();
        },

        /**
         * Focus out with a certain speed of 5.
         */
        focusOut: function(data) {
            this.deviceConnection
                .send('setVzControl', {
                    vzControl: 'focusFar',
                    speed: 5,
                    appId: data.appId
                });

            this.updateState();
        },

        /**
         * Stop focus.
         */
        stopFocus: function(data) {
            this.deviceConnection
                .send('setVzControl', {
                    vzControl: 'focusFar',
                    speed: 0,
                    appId: data.appId
                });

            this.updateState();
        },

        /**
         * Toggle auto-focus.
         */
        toggleAutoFocus: function(data) {
            this.deviceConnection
                .send('setVzControl', {
                    vzControl: 'AFToggle',
                    appId: data.appId
                });

            this.updateState();
        },

        /**
         * Toggle freeze.
         */
        toggleFreeze: function(data) {
            this.deviceConnection
                .send('setVzControl', {
                    vzControl: 'freezeToggle',
                    appId: data.appId
                });

            this.updateState();
        },

        /**
         * Recall preset 1.
         */
        recallPreset: function(data) {
            this.deviceConnection
                .send('setVzControl', {
                    vzControl: 'presetRecall',
                    appId: data.appId
                });

            this.updateState();
        },

        /**
         * Set preset 1.
         */
        setPreset: function(data) {
            this.deviceConnection
                .send('setVzControl', {
                    vzControl: 'presetSet',
                    appId: data.appId
                });

            this.updateState();
        },

        /**
         * Toggle power.
         */
        power: function(data) {
            this.deviceConnection
                .send('setVzControl', {
                    vzControl: 'powerToggle',
                    appId: data.appId
                });

            this.updateState();
        },

        /**
         * Toggle light.
         */
        light: function(data) {
            this.deviceConnection
                .send('setVzControl', {
                    vzControl: 'lightToggle',
                    appId: data.appId
                });

            this.updateState();
        },

        /**
         * Toggle capture area shift.
         */
        captureAreaShift: function(data) {
            this.deviceConnection
                .send('setVzControl', {
                    vzControl: 'CASToggle',
                    appId: data.appId
                });

            this.updateState();
        },

        updateState: function() {
            app.emit('main-loop.fast.start', {
                id: 'visualizer'
            });
        },

        /**
         * Get handler for pressed events on controls.
         *
         * @param {String} command
         * @param {Object} data
         */
        getPressedHandler: function(command, data) {
            if (this.freezeService.isFreeze()) {
                this.getReleasedHandler(command);

                return app.$.noop;
            }

            return (this.pressed[command] || app.$.noop).bind(this, data);
        },

        /**
         * Get handler for release events on controls.
         *
         * @param command
         * @param {Object} data
         * @returns {Function|*|any|JQuery|(function(this:VisualizerService))}
         */
        getReleasedHandler: function(command, data) {
            return (this.released[command] || app.$.noop).bind(this, data);
        },

        /**
         * Collection of all pressed handlers
         *
         * @type {Object}
         */
        pressed: {
            'zoom-in': function(data) {
                this.zoomIn(data || {});
            },

            'zoom-out': function(data) {
                this.zoomOut(data || {});
            },

            'focus-in': function(data) {
                this.focusIn(data);
            },

            'focus-out': function(data) {
                this.focusOut(data);
            },

            'auto-focus': function(data) {
                this.toggleAutoFocus(data);
            },

            'freeze': function(data) {
                this.toggleFreeze(data);
            },

            'recall-preset': function(data) {
                this.recallPreset(data);
            },

            'set-preset': function(data) {
                this.setPreset(data);
            },

            'power': function(data) {
                this.power(data);
            },

            'light': function(data) {
                this.light(data);
            },

            'capture-area-shift': function(data) {
                this.captureAreaShift(data);
            }
        },

        /**
         * Collection of all released handlers
         *
         * @type {Object}
         */
        released: {
            'zoom-in': function(data) {
                this.stopZoom(data);
            },

            'zoom-out': function(data) {
                this.stopZoom(data);
            },

            'focus-in': function(data) {
                this.stopFocus(data);
            },

            'focus-out': function(data) {
                this.stopFocus(data);
            }
        }
    };
});
