'use strict';

const _ = require('lodash');
const tpl = require('./action.hbs');
const $ = require('jquery');

/**
 * @param app
 * @param id
 * @param options
 * @constructor
 */
function ActionView(app, id, options) {
    /**
     * App reference
     * @type {Object}
     */
    this.app = app;

    /**
     * @type {String}
     */
    this.id = id;

    /**
     * Default options
     * @type {{icon: string, state: string}}
     */
    this.defaultOptions = {
        icon: 'hidden',
        visible: true
    };

    /**
     * @type {Object}
     */
    this.options = _.defaults(options, this.defaultOptions);

    /**
     * @type {*|jQuery|HTMLElement}
     */
    this.$el = this.app.$(tpl());

    this.update();
}

/**
 * @method render
 * @param $container
 */
ActionView.prototype.render = function($container) {
    $container.append(this.$el);

    this.$el
        .velocity({
            scale: 1,
            opacity: 1
        }, {
            duration: 150
        });

    this.bindDOMEvent();
};

/**
 * @method update
 * @param options
 */
ActionView.prototype.update = function(options) {
    const button = this.$el.find('.button');
    // Remove old settings
    this.$el.find('.icon').removeClass(this.options.icon);

    // Override old options with the new options
    if (options) {
        this.options = _.extend(this.options, options);
    }

    if (this.options.isToggle) {
        this.$el.find('span.click-effect').remove();
        if (this.options.active) {
            button.addClass('active');
            this.options.text = this.options.inactiveState.text;
            this.options.icon = this.options.inactiveState.icon;
        } else {
            button.removeClass('active');
            this.options.text = this.options.activeState.text;
            this.options.icon = this.options.activeState.icon;
        }
    }

    if (this.options.hasCustomClass) {
        button.addClass(this.options.customClass);
    } else {
        button.removeClass(this.options.customClass);
    }

    this.$el.find('.text').text(this.options.text);
    this.$el.find('.icon').addClass(this.options.icon);
};

/**
 * @method remove
 */
ActionView.prototype.remove = function() {
    const dfd = $.Deferred();

    this.$el
        .velocity({
            scale: 0,
            opacity: 0
        }, {
            duration: 150,
            complete: function() {
                this.$el.remove();

                dfd.resolve();
            }.bind(this)
        });

    return dfd.promise();
};

/**
 * @method destroy
 */
ActionView.prototype.destroy = function() {
    this.unbindDOMEvent();

    return this.remove();
};

/**
 * @method enable
 */
ActionView.prototype.enable = function() {
    this.unbindDOMEvent();
    this.$el.find('.button').removeClass('disabled');
    this.bindDOMEvent();
};

/**
 * @method disable
 */
ActionView.prototype.disable = function() {
    this.$el.find('.button').addClass('disabled');
    this.unbindDOMEvent();
};

/**
 * @method unbindDOMEvents
 */
ActionView.prototype.unbindDOMEvent = function() {
    this.$el.find('.button').off('click');
};

/**
 * @method bindDOMEvents
 */
ActionView.prototype.bindDOMEvent = function() {
    this.$el.find('.button').on('click', this.onButtonClick.bind(this));
};

/**
 * @method onButtonClick
 */
ActionView.prototype.onButtonClick = function() {
    this.$el.find('.button').append('<span class="click-effect"></span>');

    setTimeout(function() {
        this.$el.find('span.click-effect').remove();
    }.bind(this), 1300);

    if (this.options.isToggle) {
        if (this.options.active) {
            if (typeof this.options.onToggleInactive === 'function') {
                this.options.onToggleInactive();
            }
        } else if (typeof this.options.onToggleActive === 'function') {
            this.options.onToggleActive();
        }
    } else if (typeof this.options.onClick === 'function') {
        this.options.onClick();
    }
};

module.exports = ActionView;
