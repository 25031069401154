'use strict';

var app = require('../app');
var $ = require('jquery');
const StringConvert = require('../../string-convert');

app.service('ValidateService', function(app) {
    const uriService = app.getService('URIService');
    var formManager = app.getService('FormManager');

    var validations = {
        required: function(value) {
            return (!!value && value.length > 0);
        },

        requiredOrDisabled: function(value, input) {
            return (!!value && value.length > 0) || input.$el.prop('disabled');
        },

        /**
         * Validate WLAN password
         *
         * @method checkWLANPassword
         * @param value password
         * @param input
         * @returns {boolean}
         */
        checkWLANPassword: function(value, input) {
            var checkLength = true;
            var type = input.$el.attr('data-pwd-type');
            var allowEmpty = input.$el.attr('data-allow-empty') ? input.$el.attr('data-allow-empty') === 'true' : false;

            switch (type) {
                case 'wpa':
                    checkLength = (value.length >= 8 && value.length <= 63);

                    return allowEmpty ? (checkLength || value.length === 0) : checkLength;
                case 'wep':
                    checkLength = (value.length === 13);

                    return allowEmpty ? (checkLength || value.length === 0) : checkLength;
                default:
                    return allowEmpty ? true : value.length >= 1;
            }
        },

        /**
         * Validate a IP4 address
         *
         * @param ip
         * @returns {boolean}
         */
        checkIPv4: function(ip) {
            // First check the first three section for   ( x | 1x | 1xx | xx | 2xx | 25x + . )
            // Then check the fourth section for         ( x | 1x | 1xx | xx | 2xx | 25x )
            var ipPattern = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]?)\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g;

            return ipPattern.test(ip);
        },

        checkIP: function(ip) {
            return this.checkIPv4(ip);
        },

        /**
         * Validate the AccessPoint ip address
         *
         * @param ip
         * @param input
         * @returns {boolean}
         */
        checkApIp: function(ip, input) {
            var standardCheck = this.checkWlanLanIp(ip, input);

            if (standardCheck) {
                var str = ip.split('.');
                var oct4 = parseInt(str[3]);

                return oct4 === 1;
            }

            return false;
        },

        /**
         * Validate the WLAN/LAN Gateway
         *
         * @param ip
         * @param input
         * @returns {boolean}
         */
        checkWlanLanGateway: function(ip, input) {
            var selector = input.$el.data('main-input-selector');
            var dhcp = selector ? $(selector).prop('checked') : false;

            if (ip === '' || dhcp) {
                return true;
            } else {
                return this.checkWlanLanIp(ip, input, true);
            }
        },

        /**
         * Validate the WLAN/LAN IP4 address
         *
         * @param ip
         * @param input
         * @param allow0 true allow 0.0.0.0
         * @returns {boolean}
         */
        checkWlanLanIp: function(ip, input, allow0) {
            var isIp = this.checkIPv4(ip);
            var selector = input.$el.data('main-input-selector');
            var dhcp = selector ? $(selector).prop('checked') : false;

            if (isIp) {
                var str = ip.split('.');
                var oct1 = parseInt(str[0]);

                if (allow0 && oct1 === 0) {
                    return true;
                }

                if (oct1 && oct1 > 0 && oct1 <= 223 && oct1 !== 127) {
                    return true;
                }
            }

            if (dhcp) {
                return true;
            }

            return false;
        },

        /**
         * Validate the WLAN/LAN DNS address
         *
         * @param ip
         * @returns {boolean}
         */
        checkDNS: function(ip, input) {
            var selector = input.$el.data('main-input-selector');
            var dhcp = selector ? $(selector).prop('checked') : false;

            if (ip === '' || ip === '0.0.0.0' || dhcp) {
                return true;
            } else {
                return this.checkWlanLanIp(ip, input);
            }
        },

        /**
         * @method checkPasswordStrengthIfExists
         * @param {string} password
         * @returns {boolean}
         */
        checkPasswordStrengthIfExists: function(password, input) {
            var validate = input.$el.attr('data-pwd-validate') ? input.$el.attr('data-pwd-validate') === 'true' : true;

            if (validate) {
                var minLength = input.$el.attr('data-pwd-length-min') || 6;
                var maxLength = input.$el.attr('data-pwd-length-max') || 63;
                var allowEmpty = input.$el.attr('data-allow-empty') ? input.$el.attr('data-allow-empty') === 'true' : false;

                var checkLength = (password.length >= minLength && password.length <= maxLength);

                return allowEmpty ? (checkLength || password.length === 0) : checkLength;
            }

            return true;
        },

        /**
         * @method checkMaxStrength
         * @param {string} string
         * @returns {boolean}
         */
        checkMaxStrength: function(string, input) {
            var length = input.$el.attr('data-max-length') || 6;

            return string.length <= length;
        },

        /**
         * @method requiredOrDisabledAndMaxStrength
         * @param {string} string
         * @returns {boolean}
         */
        requiredOrDisabledAndMaxStrength: function(string, input) {
            return (
                this.checkMaxStrength(string, input)
                && this.requiredOrDisabled(string, input)
            );
        },

        /**
         * @method checkPasswordRepeat
         * @param {string} password
         * @param {Input} input
         * @returns {boolean}
         */
        checkPasswordRepeat: function(password, input) {
            var selector = input.$el.data('main-password-selector');
            var $mainEl = $(selector);
            var mainPassword = $mainEl.val();

            return mainPassword === password;
        },

        /**
         * Validate boxname
         *
         * @param boxname
         * @returns {boolean}
         */
        checkBoxname: function(boxname) {
            // Allowed characters: alphanumeric as well as . and - min 3, max 32 characters
            var boxnamePattern = /^([a-zA-Z0-9-.]{3,32})$/g;

            return boxnamePattern.test(boxname);
        },

        /**
         * Validate recording name. do not allow specific characters and validate length
         *
         * @param value
         * @param input
         * @returns {*}
         */
        checkRecordingName: function(value, input) {
            if (value.indexOf('\\') > -1 || value.indexOf('/') > -1 || value.indexOf('.') > -1) {
                return false;
            }

            return this.checkMinMaxLength(value, input);
        },

        /** Validate a number
         *
         * @param text
         * @returns {boolean}
         */
        checkIsNumber: function(number, input) {
            var allowEmpty = input.$el.attr('data-allow-empty') ? input.$el.attr('data-allow-empty') === 'true' : false;
            var numberPattern = /^\d+$/;

            if (allowEmpty && !number) {
                return true;
            }

            return numberPattern.test(number);
        },

        /**
         * Validate time
         *
         * @param time
         * @returns {boolean}
         */
        checkIsCorrectTime: function(time) {
            var timePattern = /^((([0]?[1-9]|1[0-2])(:|\.)[0-5][0-9]?( )?(AM|am|aM|Am|PM|pm|pM|Pm))|(([0]?[0-9]|1[0-9]|2[0-3])(:|\.)[0-5][0-9]?))$/;

            return timePattern.test(time);
        },

        /**
         * Validate date
         *
         * @param date
         * @returns {boolean}
         */
        checkIsCorrectDate: function(date) {
            // MM/DD/YYYY
            var datePatternMDY = /^(?:(?:31(\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
            // DD.MM.YYYY
            var datePatternDMY = /^(?:(?:(?:0?[13578]|1[02])(\/)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\/)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

            return (datePatternMDY.test(date) || datePatternDMY.test(date));
        },

        /**
         * Validate streaming port. Port must be even and between limits.
         *
         * @param port
         * @returns {boolean}
         */
        checkStreamingPort: function(port, input) {
            const minPort = input.$el.attr('data-min-port') || 8800;
            const maxPort = input.$el.attr('data-max-port') || 9000;

            const portNum = Number(port);
            const minPortNum = Number(minPort);
            const maxPortNum = Number(maxPort);

            return portNum % 2 === 0 && portNum >= minPortNum && portNum <= maxPortNum;
        },

        /**
         * Validate input settings (visualizer can only be assigned to one input)
         *
         * @param {string} inputType
         * @param {Input} input
         * @returns {boolean}
         */
        checkVisualizerInput: function(inputType, input) {
            var counter = 0;

            /*
             * Start validation only if we have rendered the second select. If the input at place 5 (type) is rendered, we do
             * have more than one form rendered and can therefore validate them.
             */
            if (inputType === 'visualizer' && input.form.$allInputs.length > 6 && !!input.form.$allInputs[5].value) {
                for (var i = 0; i < input.form.$allInputs.length; i++) {
                    if ('select-one' === input.form.$allInputs[i].type
                        && 'visualizer' === input.form.$allInputs[i].value.toLowerCase()
                    ) {
                        counter++;
                    }
                }
            }

            return counter <= 1;
        },

        /**
         * Validate preferred usb mic
         *
         * @param {string} inputType
         * @param {Input} input
         * @returns {boolean}
         */
        checkPreferredUsbMic: function(inputType, input) {
            var validate = input.$el.attr('data-allow-empty') ? input.$el.attr('data-allow-empty') === 'true' : true;

            if (!validate) {
                return (inputType === null ? !!inputType : inputType !== 'none');
            }

            return true;
        },

        /**
         * Validate if selected item is not empty if data-allow-empty attribute is set to false.
         *
         * @param value
         * @param input
         * @return {boolean}
         */
        checkSelectedItemNotEmpty: function(value, input) {
            const validate = input.$el.attr('data-allow-empty') ? input.$el.attr('data-allow-empty') === 'false' : false;

            if (validate) {
                return (value !== undefined && value !== null && value !== 'none');
            }

            return true;
        },

        /**
         * Validate if file is of type jpeg
         *
         * @param file
         * @returns {boolean}
         */
        checkImage: function(file) {
            return (!file || file.type === 'image/jpeg');
        },

        /**
         * Validate subnet
         *
         * @param subnet mask
         * @returns {boolean}
         */
        checkSubnet: function(subnet, input) {
            var selector = input.$el.data('main-input-selector');
            var dhcp = selector ? $(selector).prop('checked') : false;

            switch (subnet) {
                case '255.255.255.252':
                case '255.255.255.248':
                case '255.255.255.240':
                case '255.255.255.224':
                case '255.255.255.192':
                case '255.255.255.128':
                case '255.255.255.0':
                case '255.255.254.0':
                case '255.255.252.0':
                case '255.255.248.0':
                case '255.255.240.0':
                case '255.255.224.0':
                case '255.255.192.0':
                case '255.255.128.0':
                case '255.255.0.0':
                case '255.254.0.0':
                case '255.252.0.0':
                case '255.248.0.0':
                case '255.240.0.0':
                case '255.224.0.0':
                case '255.192.0.0':
                case '255.128.0.0':
                case '255.0.0.0':
                    return true;
                default:
                    if (dhcp) {
                        return true;
                    }

                    return false;
            }
        },

        /**
         * Validate nickname
         *
         * @param nickname
         * @returns {boolean}
         */
        checkNickname: function(nickname) {
            // Max. 32 bytes
            return this.byteLengthOf(nickname) <= 32;
        },

        /**
         * Validate ip != visualizer
         *
         * @param ip
         * @returns {boolean}
         */
        checkIpNonVz: function(ip, input) {
            var oct1 = null;
            var oct2 = null;
            var oct3 = null;
            var isIp = this.checkWlanLanIp(ip, input);
            var selector = input.$el.data('main-input-selector');
            var dhcp = selector ? $(selector).prop('checked') : false;

            if (dhcp) {
                return true;
            }

            if (!isIp) {
                return false;
            }

            var str = ip.split('.');

            oct1 = parseInt(str[0]);
            oct2 = parseInt(str[1]);
            oct3 = parseInt(str[2]);

            if ((!!oct1 && oct1 === 172) && (!!oct1 && oct2 === 31) && (!!oct1 && oct3 === 255)) {
                return false;
            }

            return true;
        },

        /**
         * CheckMulticast
         *
         * @param streaming interface
         * @param input
         * @returns {boolean}
         */
        checkMulticast: function(ip, input) {
            var selector = input.$el.data('main-input-selector');
            var iface = $(selector).prop('value');
            var isIp = this.checkIPv4(ip);
            var oct1 = null;

            if (!isIp) {
                return false;
            } else {
                var str = ip.split('.');
                oct1 = parseInt(str[0]);
            }

            if (iface === 'wlan') {
                if (!!oct1 && oct1 >= 224 && oct1 <= 239) {
                    return false;
                }
            }

            return true;
        },

        /**
         * IsMulticastIp
         *
         * @param ip
         * @returns {boolean}
         */
        isMulticastIp: function(ip) {
            var isIp = this.checkIPv4(ip);
            var oct1 = null;

            if (!isIp) {
                return false;
            } else {
                var str = ip.split('.');
                oct1 = parseInt(str[0]);
            }

            if (!!oct1 && (oct1 < 224 || oct1 > 239)) {
                return false;
            }

            return true;
        },

        checkPeripheralCmd: function(hexString, input) {
            var hexPattern = /^([0-9A-Fa-f]{2})+$/;

            if (input.$el.prop('disabled')) {
                return true;
            }

            return this.checkLength(hexString, input) && hexPattern.test(hexString);
        },

        /**
         * CheckHexString
         *
         * @param input
         * @returns {boolean}
         */
        checkHexString: function(hexString, input) {
            var hexPattern = /^([0-9A-Fa-f]{2})+$/;

            if (input.$el.prop('disabled')) {
                return true;
            }

            return hexPattern.test(hexString);
        },

        /**
         * CheckPort
         *
         * @param port
         * @returns {boolean}
         */
        checkPort: function(port) {
            if (/\D/.test(port) || port[0] === '0') {
                return false;
            }
            const num = Number(port);

            return num >= 1 && num <= 65535;
        },

        /**
         * CheckEmail
         *
         * @param email
         * @returns {boolean}
         */
        checkEmail: function(email) {
            var mailPattern = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

            return mailPattern.test(email);
        },

        /**
         * CheckLength
         *
         * @param value
         * @param input
         * @returns {boolean}
         */
        checkLength: function(value, input) {
            var maxLength = parseInt(input.$el.data('max-length'));

            return this.byteLengthOf(value) <= maxLength;
        },

        /**
         * CheckMinLength
         *
         * @param value
         * @param input
         * @returns {boolean}
         */
        checkMinLength: function(value, input) {
            var minLength = input.$el.data('min-length');

            return this.byteLengthOf(value) >= minLength;
        },

        /**
         * CheckWhitespaces
         *
         * @param value
         * @returns {boolean}
         */
        checkWhitespaces: function(value) {
            return (value.indexOf(' ') < 0);
        },

        /**
         * RequiredAndCheckWhitespaces
         *
         * @param value
         * @returns {required|boolean}
         */
        requiredAndCheckWhitespaces: function(value) {
            return this.required && (value.indexOf(' ') < 0);
        },

        /**
         * CheckMinMaxLength
         *
         * @param value
         * @param input
         * @returns {boolean}
         */
        checkMinMaxLength: function(value, input) {
            var allowEmpty = input.$el.attr('data-allow-empty') ? input.$el.attr('data-allow-empty') === 'true' : false;
            var $subjection = $(input.$el.attr('data-subjection-selector'));
            var max = this.checkLength(value, input);
            var min = this.checkMinLength(value, input);

            // Release-1765: Allow empty value in this validation if the section is disabled.
            if (0 < $subjection.length && !$subjection.is(':checked')) {
                return true;
            }

            if (allowEmpty) {
                return max;
            }

            return (max && min);
        },

        /**
         * Check min and max length and if input field is disabled.
         * @param value
         * @param input
         * @returns {boolean}
         */
        checkMinMaxLengthOrDisabled: function(value, input) {
            if (input.$el.prop('disabled')) {
                return true;
            }

            return this.checkMinMaxLength(value, input);
        },

        /**
         * Check min and max length of ENCODED value.
         * @param value
         * @param input
         * @returns {boolean}
         */
        checkEncodedMinMaxLength: function(value, input) {
            var allowEmpty = input.$el.attr('data-allow-empty') ? input.$el.attr('data-allow-empty') === 'true' : false;
            var $subjection = $(input.$el.attr('data-subjection-selector'));

            const encodedURI = uriService.encodeSpecial(value);
            const max = this.checkLength(encodedURI, input);
            const min = this.checkMinLength(encodedURI, input);

            // Release-1765: Allow empty value in this validation if the section is disabled.
            if (0 < $subjection.length && !$subjection.is(':checked')) {
                return true;
            }

            if (allowEmpty) {
                return max;
            }

            return (max && min);
        },

        /**
         * Count UTF-8 bytes of a string
         *
         * @param string
         * @returns {integer}
         */
        byteLengthOf: function(s) {
            // Assuming the String is UCS-2(aka UTF-16) encoded
            var n = 0;
            for (var i = 0, l = s.length; i < l; i++) {
                var hi = s.charCodeAt(i);
                if (hi < 0x0080) { // [0x0000, 0x007F]
                    n += 1;
                } else if (hi < 0x0800) { // [0x0080, 0x07FF]
                    n += 2;
                } else if (hi < 0xD800) { // [0x0800, 0xD7FF]
                    n += 3;
                } else if (hi < 0xDC00) { // [0xD800, 0xDBFF]
                    var lo = s.charCodeAt(++i);
                    if (i < l && lo >= 0xDC00 && lo <= 0xDFFF) { // Followed by [0xDC00, 0xDFFF]
                        n += 4;
                    } else {
                        console.log('UCS-2 String malformed');
                    }
                } else if (hi < 0xE000) { // [0xDC00, 0xDFFF]
                    console.log('UCS-2 String malformed');
                } else { // [0xE000, 0xFFFF]
                    n += 3;
                }
            }

            return n;
        },

        /**
         * Validate URL and check length of host and path
         */
        checkUrlHostAndPath: function(url, input) {
            try {
                const allowEmpty = input.$el.attr('data-allow-empty') === 'true';
                const maxHostLength = parseInt(input.$el.attr('max-length-host'));
                const maxPathLength = parseInt(input.$el.attr('max-length-path'));

                if (allowEmpty && url.length === 0) {
                    return true;
                }

                if (!url.startsWith('http://') && !url.startsWith('https://')) {
                    return false;
                }

                const parsedUrl = new URL(url);
                const host = StringConvert.stringToUtf8(parsedUrl.protocol + '//' + parsedUrl.host);
                const path = StringConvert.stringToUtf8(parsedUrl.pathname + parsedUrl.search);

                return host.length <= maxHostLength && path.length <= maxPathLength;
            } catch (e) {
                return false;
            }
        },

        /**
         * CheckUrl
         *
         * @param url
         * @returns {boolean}
         */
        checkUrl: function(url) {
            var urlPattern = /^((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|))$/;
            var encUrl = encodeURI(url);

            if (encUrl.indexOf('(') >= 0 || encUrl.indexOf(')') >= 0) {
                encUrl = encUrl.replace(/\(/g, '%28');
                encUrl = encUrl.replace(/\)/g, '%29');
            }

            return urlPattern.test(encUrl);
        },

        /**
         * CheckUrlOrEmpty
         *
         * @param url
         * @returns {boolean}
         */
        checkUrlOrEmpty: function(url) {
            if (url.length === 0) {
                return true;
            }

            return this.checkUrl(url);
        },

        /**
         * Check URL against http://, https://, ftp:// and length (can also be 0)
         *
         * @param url
         * @param input
         * @returns {boolean}
         */
        checkBrowserShortcutUrl: function(url, input) {
            var pattern = /^\S*$/;

            return this.checkLength(encodeURI(url), input)
                && (url.length === 0 || pattern.test(url));
        },

        /**
         * CheckBssid
         * is MacAddress or can also be empty
         *
         * @param bssid
         * @returns {*}
         */
        checkBssid: function(bssid) {
            return this.checkMacAddress(bssid);
        },

        /**
         * Check if signal level limit is between the two values.
         * @param signalLevel
         * @returns {boolean}
         */
        checkSignalLevelLimit: function(signalLevel) {
            return signalLevel >= -85 && signalLevel <= -40;
        },

        /**
         * CheckMacAddress
         *
         * @method checkMacAddress
         * @param addr
         * @return {boolean}
         */
        checkMacAddress: function(addr) {
            addr = addr.toUpperCase();
            var macPattern = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;

            return macPattern.test(addr);
        },

        /**
         * Checks every character of the given input-field that is allowed.
         *
         * - Add "data-not-allowed"-attribute to your input-field.
         * - All characters seperated with a whitespace are not allowed on that field.
         *
         * @param {String} value
         * @param {Input} input
         *
         * @return {boolean}
         */
        checkCharacters: function(value, input) {
            var notAllowerdCharacters = input.$el.data('not-allowed').split(' ');
            var foundInvalidCharacters = notAllowerdCharacters
                .filter(function(character) {
                    return value.indexOf(character) !== -1;
                });

            return foundInvalidCharacters.length === 0;
        },

        /**
         * Will check if a number is between or equal to a from-to value.
         *
         * - Add "data-from"-attribute to your input-field.
         * - Add "data-to"-attribute to your input-field.
         *
         * @param {String} value
         * @param {Input} input
         *
         * @return {boolean}
         */
        checkNumberFromTo: function(value, input) {
            var from = parseInt(input.$el.data('from'));
            var to = parseInt(input.$el.data('to'));

            if (!this.checkIsNumber(value, input)) {
                return false;
            }

            value = parseInt(value);

            return value >= from && value <= to;
        },

        /**
         * Will check if the WLAN is valid or invalid. The actual validation is done
         * in the splash-screen.js update handler.
         *
         * @param value
         * @param input
         * @returns {boolean}
         */
        checkSplashScreenWlanType: function(value, input) {
            return !input.$el.hasClass('is-wlan-invalid');
        },

        /**
         * Check if something is selected.
         *
         * @param value
         * @returns {boolean}
         */
        checkEmptySelect: function(value) {
            return !(value === null || value === '');
        },

        /**
         * Check if it is a valid ldap/s url depending on tls mode setting.
         *
         * @param value
         * @param input
         */
        checkLdapUrl: function(value, input) {
            var max = this.checkLength(value, input);
            var pattern;

            for (var i = 0; i < input.form.$allInputs.length; i++) {
                if (input.form.$allInputs[i].name === 'tls-mode') {
                    if (input.form.$allInputs[i].checked) {
                        // Do not allow ldaps if tls is enabled
                        pattern = /^(ldap?:\/\/[A-z0-9.-]{5,}(:[0-9] {2,5})?)$/g;
                    } else {
                        pattern = /^(ldaps?:\/\/[A-z0-9.-]{5,}(:[0-9] {2,5})?)$/g;
                    }
                }
            }

            if (value.length === 0) {
                return max;
            } else {
                return (pattern.test(value) && max);
            }
        },

        checkZoomMeetingId: function(value) {
            const pattern = /^([0-9]{9,11})$/g;

            return pattern.test(value.replace(/\s/g, ''));
        },

        checkZoomLink: function(value) {
            const pattern = /^https:\/\/.*.?zoom.us\/[jw]{1}\/[0-9]{9,11}(\D.*|$)$/g;

            return pattern.test(value);
        },

        checkZoomMeetingIdOrLink: function(value) {
            return this.checkZoomMeetingId(value) || this.checkZoomLink(value);
        }
    };

    formManager.addValidation(validations);

    return validations;
});
