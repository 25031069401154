const template = require('./vmeeting-controls.html');
const app = require('../../../app');
const vueUtils = require('../../util.js');

module.exports = {
    template,
    props: {
        options: {
            type: Object
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data: function() {
        return {
            timeRemainingHours: 0,
            timeRemainingMinutes: 0,
            meetingSubject: 'VMeeting',
            isAdHoc: true
        };
    },

    methods: {
        /**
         * Pad number for 2 digits if required
         * @param num number to pad
         */
        padToTwo: function(num) {
            let padded = '0' + num.toFixed(0);
            if (padded.length > 2) {
                return padded.slice(1);
            }

            return padded;
        },

        /**
         * Ask user for end presentation
         */
        askForEndPresentation: function() {
            this.powerService.openEndPresentationModal();
        },

        /**
         * Open modal to show VMeeting details.
         */
        showVMeetingDetails: function() {
            app.emit('modal.open', {
                id: 'vmeetingDetails'
            });
        },

        updateHandler: function() {
            this.timeRemainingHours = parseInt(this.meetingService.timeLeft.h, 10);
            this.timeRemainingMinutes = parseInt(this.meetingService.timeLeft.m, 10);
            const details = this.meetingService.getActiveSessionDetails();
            this.meetingSubject = details.subject;
            this.isAdHoc = !details || details.id === 'Ad-Hoc-Meeting';
        },

        i18n: vueUtils.i18n
    },

    created: function() {
        this.powerService = app.getService('PowerService');
        this.meetingService = app.getService('SessionManagementService');

        this.evctx = vueUtils.eventContext();
        this.evctx.on('main-loop.update', this.updateHandler.bind(this));
        this.evctx.on('session-mgmt.update', this.updateHandler.bind(this));
    },

    destroyed: function() {
        this.evctx.close();
    },

    components: {
        'boxed-control': require('../boxed-control/boxed-control.js'),
        'command-button': require('../command-button/command-button.js')
    }
};
