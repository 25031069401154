'use strict';

var app = require('../../app');
var $ = require('jquery');
var _ = require('lodash');
var ScrollView = require('./../../../scroll-view');

var listGridTpl = require('./list-grid.hbs');

/**
 * @class ListGrid
 * @constructor
 *
 * @param {Function} [options.onSelect] getting called when an item was selected
 */
app.component('ListGrid', {
    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.remote = this.getService('RemoteService');
        this.template = this.options.tpl || listGridTpl;
        this.items = this.options.configs.items;
        this.deviceService = this.getService('DeviceService');
        this.features = null;

        if (!this.deviceService.isCboxPureOrPro() && !this.deviceService.isCboxPureMini()) {
            this.visualizer = this.getService('VisualizerService');
        }

        return this.loadFeatures();
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.bindDOMEvents();
        this.bindEvents();

        this.remote.update();
        this.remote.focus(this.$el.find('.list-item:not(.is-disabled):not(.is-loading)').get(0));

        this.emit('list-grid.loaded');

        this.reRenderLoaders();
    },

    /**
     * Makes some small css adjustments, so the browser has to render the loaders again to prevent issues in certain
     * browsers (e.g. Chrome 61).
     * This can be retested after every chrome release and removed as soon as the loaders aren't blurry anymore.
     * https://jira.wolfvision.at/browse/RELEASE-1740
     */
    reRenderLoaders: function() {
        var $loaders = this.$el.find('.list-item-state span.loader');
        var oldValue;
        var newValue;

        oldValue = $loaders.css('display');
        newValue = 'inline';
        if (newValue === oldValue) {
            newValue = 'block';
        }

        $loaders.css('display', newValue);

        setTimeout(function() {
            $loaders.css('display', oldValue);
        }, 1);
    },

    loadFeatures: function() {
        var dfd = $.Deferred();

        this.deviceConnection
            .send('getLicenseFeatures')
            .then(function(features) {
                this.features = features;

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * @method initScrollView
     */
    initScrollView: function() {
        this.$container = this.$('.overlay-content-view');

        this.scrollView = new ScrollView({
            $container: this.$container,
            $items: this.$el.find('.list-item'),
            entryFocusable: false
        });
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        this.on('overlay.opened-end', this.initScrollView.bind(this));
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.$el.on(
            'click', '.list-item:not(.is-disabled):not(.is-loading)',
            _.debounce(
                this.selectHandler.bind(this),
                500,
                true
            )
        );
    },

    /**
     * @method serialize
     */
    serialize: function() {
        return {
            items: this.items
        };
    },

    /**
     * @method selectHandler
     * @param {Object} event
     */
    selectHandler: function(event) {
        var $selectedItem = this.$(event.currentTarget);
        this.select($selectedItem.data('name'));
    },

    /**
     * Emits the selected item name
     * @method select
     * @param {String} itemName
     */
    select: function(itemName) {
        if (typeof this.options.onSelect === 'function') {
            this.options.onSelect(itemName);
        } else {
            this.emit('overlay.open', {
                id: itemName
            });
        }
    },

    /**
     * @method destroy
     */
    destroy: function() {
        if (this.scrollView) {
            this.scrollView.destroy();
        }
    }
});
