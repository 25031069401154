'use strict';

var _ = require('lodash');
var app = require('../../../app');
var selectionTpl = require('./selection.hbs');
var selectionItem = require('./selection-item.hbs');

app.component('SelectionModal', {
    template: selectionTpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.items = this.options.configs.items;
    },

    serialize: function() {
        return {
            title: this.options.configs.title
        };
    },

    storeSelectors: function() {
        this.$selectionList = this.$el.find('#selection-list-items');
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.renderSelectionList();
        this.bindDOMEvents();
        this.bindEvents();
    },

    bindEvents: function() {
        this.on('backdrop.action.click', this.close.bind(this));
    },

    /**
     * Render item list.
     */
    renderSelectionList: function() {
        _.each(this.items, function(item) {
            var $item = this.$(selectionItem(item));
            this.$selectionList.append($item);
        }.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.selection-item', this.selectItem.bind(this));
    },

    /**
     * Select item.
     *
     * @param event Current item
     */
    selectItem: function(event) {
        var $el = event ? this.$(event.currentTarget) : this.$selectionList.find('.focused');

        this.options.configs.onSelect($el.data('id'));

        if (this.options.configs.autoClose) {
            this.close();
        }
    },

    close: function() {
        this.emit('modal.close');
    }
});

