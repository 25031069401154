'use strict';

var $ = require('jquery');
var _ = require('lodash');
var mickey = require('../../../vendors/mickey/mickey.js');
var app = require('../app');
/** @var Platform platform */
var platform = require('../../platform/platform');
/** @var StateMachine StateMachine */
var StateMachine = require('./../state-machine');
/** @var ScrollHelper scrollHelper */
var scrollHelper = require('./../../scroll-view/scroll-helper.js');

app.service('RemoteService', function(app) {
    var keyTimes = {};
    var HOLD_DOWN_TIME = 2000;
    var holdDownTimout = null;
    var states = {
        none: 'none',
        on: 'on',
        off: 'off'
    };

    // Enter/navigation/standby keys
    var LIMITED_ACCESS_KEYS = [13, 20, 37, 38, 39, 40, 152];

    // If station is in Matrix limited access allow these keys: enter/navigation/ctrl-center
    var MATRIX_LIMITED_ACCESS_KEYS = ['enter', 'left', 'up', 'right', 'down', 'quick-settings'];

    /**
     * Helper method to handle click and hold
     *
     * @param {Function} clickCallback
     * @param {Function} holdDownCallback
     */
    function holdDownHelper(clickCallback, holdDownCallback) {
        return function(eventType) {
            if (eventType === 'keydown' && !keyTimes['keydown']) {
                keyTimes[eventType] = new Date().getTime();

                holdDownTimout = setTimeout(function() {
                    holdDownCallback();
                }.bind(this), HOLD_DOWN_TIME);
            } else if (eventType === 'keyup') {
                keyTimes[eventType] = new Date().getTime();

                clearTimeout(holdDownTimout);

                if (keyTimes['keyup'] < (keyTimes['keydown'] + HOLD_DOWN_TIME)) {
                    clickCallback();
                }

                keyTimes = {};
            }
        };
    }

    return {
        KEYCODES: {
            LEFT: 37,
            UP: 38,
            RIGHT: 39,
            DOWN: 40,
            ENTER: 13,
            BACKSPACE: 8,
            WHITESPACE: 32,
            ESC: 27,
            SHIFT: 42,
            CAPS_LOCK: 20,
            TAB: 9
        },

        ARROW_KEYCODES: [
            37,
            38,
            39,
            40
        ],

        // View bug RELEASE-1180.
        ENABLE_REMOTE_CONTROL_KEYCODES: [
            37,
            38,
            39,
            40,
            66,
            67,
            80,
            81,
            82,
            83,
            84,
            152
        ],

        ARROW_KEYCODES_MAPPER: {
            37: 'left',
            38: 'up',
            39: 'right',
            40: 'down'
        },

        lastRegion: null,

        focusedElement: null,

        mickeyIsBlocked: false,

        blockAll: false,

        initialize: function() {
            this.options = {
                hoverClass: 'focused',
                areaClass: 'focused'
            };

            this.keyboard = app.getService('KeyboardService');
            this.screensaver = app.getService('ScreensaverService');
            this.stationService = app.getService('StationService');
            this.annotationService = app.getService('AnnotationService');
            this.limitedAccess = false;

            this.usingRemoteControl = platform.checks.isCbox;

            if (!platform.checks.isIOS && !platform.checks.isAndroid) {
                this.mickey = window.mickey = mickey(document.body, {
                    hoverClass: this.options.hoverClass,
                    areaClass: this.options.areaClass,
                    beforeFocusChange: this.beforeFocusChange.bind(this),
                    onFocusChange: this.onFocusChangeHandler.bind(this),
                    onEndOfRegion: this.onEndOfRegionHandler.bind(this)
                }).init();
            }

            this.touchKeyboardState = new StateMachine({
                context: this,
                state: states.none,
                states: states
            });

            this.capslockState = new StateMachine({
                context: this,
                state: states.none,
                states: states
            });

            this.addStateTransitions();
            this.storeSelectors();

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.bindEvents();
                    this.loadKeyboardSettings();
                }.bind(this));

            this.bindDOMEvents();
        },

        storeSelectors: function() {
            this.$main = $('#main');
        },

        bindEvents: function() {
            if (platform.checks.isCbox) {
                app.on('main-loop.update', this.loadKeyboardSettings.bind(this));
                app.on('main-loop.update.remote', this.loadKeyboardSettings.bind(this));
            }

            $(document).bind('keydown', this.blockHistoryBackOnBackspace.bind(this));

            app.on('ui.block.events', function() {
                this.blockAll = true;
                this.block();
            }.bind(this));
            app.on('ui.unblock.events', function() {
                this.blockAll = false;
                this.unblock();
            }.bind(this));
        },

        bindDOMEvents: function() {
            app.$document.on('normalized.mousemove touchstart', this.disableRemoteMode.bind(this));
            app.$window.on('keydown keyup', this.keydownHandler.bind(this));
        },

        /**
         * Blocks the history back event when the user will press the backspace key.
         *
         * @param {Object} event
         */
        blockHistoryBackOnBackspace: function(event) {
            var doPrevent = false;
            var fieldTypes = ['text', 'password', 'file', 'search', 'email', 'number', 'date'];
            var fieldTags = ['textarea', 'input'];
            var el;

            if (event.keyCode === 8) {
                el = event.srcElement || event.target;

                if (_.contains(fieldTypes, el.type) || _.contains(fieldTags, el.tagName)) {
                    doPrevent = el.readOnly || el.disabled;
                } else {
                    doPrevent = true;
                }
            }

            if (doPrevent && !!event.originalEvent.preventDefault) {
                event.preventDefault();
            }
        },

        /**
         * Load keyboard-settings from CYNAP.
         */
        loadKeyboardSettings: function() {
            var dfd = $.Deferred();

            this.deviceConnection
                .send([
                    'getLocalTouchKeyboard',
                    'getCapslockStatus'
                ])
                .then(function(keyboard, capslock) {
                    this.onKeyboardData(keyboard, capslock);

                    dfd.resolve(keyboard.enabled);
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Prepare keyboard-data and update states.
         *
         * @param {Object} keyboard
         * @param {Object} capslock
         */
        onKeyboardData: function(keyboard, capslock) {
            var keyboardState = keyboard.enabled ? states.on : states.off;
            var capslockState = capslock.capsLockEnabled ? states.on : states.off;

            if (keyboardState !== this.touchKeyboardState.getState()) {
                this.touchKeyboardState.changeState(keyboardState);
            }

            if (capslockState !== this.capslockState.getState()) {
                this.capslockState.changeState(capslockState);
            }
        },

        /**
         * @returns {Boolean}
         */
        isTouchKeyboardEnabled: function() {
            return this.touchKeyboardState.getState() === states.on;
        },

        /**
         * @returns {Boolean}
         */
        isCapslockOn: function() {
            if (this.capslockState.getState() === states.on) {
                return true;
            }

            return false;
        },

        /**
         * @param {HTMLElement} lastElement
         */
        beforeFocusChange: function(lastElement) {
            var $el = app.$(lastElement);

            if (!$el.attr('disabled') && $el.is('select')) {
                this.focusWillBeChanged = true;

                $el
                    .attr('disabled', true)
                    .addClass('fake-disable');

                setTimeout(function() {
                    $el
                        .attr('disabled', false)
                        .removeClass('fake-disable');
                }, 10);
            }
        },

        willFocusChange: function() {
            return this.focusWillBeChanged;
        },

        /**
         * @param {Object} element
         */
        onFocusChangeHandler: function(element, region, lastElement) {
            var $el = app.$(element);
            var $lastEl = app.$(lastElement);
            var $mainEl = $('#main .main');

            this.lastFocusedElement = $lastEl;
            this.focusedElement = $el;

            $('[data-nav-last-focus]').removeAttr('data-nav-last-focus');

            $el.attr('data-nav-last-focus', true);

            this.inputRemoteHelper($el);

            app.emit('remote.focus.change', {
                el: element
            });

            if ($mainEl.hasClass('remote-control') && (!this.lastRegion || this.lastRegion !== region)) {
                app.emit('remote.focus.change.region', {
                    el: element,
                    region: region
                });
            }

            if ($lastEl && ($lastEl.is('input') || $lastEl.is('select'))) {
                $lastEl.blur();
            }

            $el.focus();
            $el.focusin();

            // Scroll to visible.
            setTimeout(function() {
                scrollHelper.checkFocusedElement($el, scrollHelper.closestScrollableElement($el));
            }, 0);

            this.scrollAreaToTop();

            this.lastRegion = region;
            this.focusWillBeChanged = false;
        },

        /**
         * Scroll area to the top of the scrollview
         */
        scrollAreaToTop: function() {
            var $area = $(this.mickey.ar);
            var $scroll = $area.closest('.scrollable-remote');
            var scrolltop = 0;

            if (this.mickey.firstInArea() !== this.mickey.el || $area.length === 0 || !$area.data('nav-scrolltop')) {
                return;
            }

            scrolltop = $area.position().top + $scroll.scrollTop();

            if (scrolltop > 0) {
                scrolltop += 2;
            }

            $scroll.scrollTop(scrolltop);
        },

        /**
         * @param {String} dir
         * @param {HTMLElement} curEl
         * @param {HTMLElement} area
         */
        onEndOfRegionHandler: function(dir, curEl, area) {
            var $area = $(area);
            var isEndless = $area.data('nav-endless');
            var el;

            if (isEndless) {
                switch (dir) {
                    case 'left':
                        if (this.mickey.firstInArea() === this.mickey.el) {
                            el = this.mickey.lastInArea();
                        } else {
                            el = this.mickey.firstInArea();
                        }
                        break;
                    case 'right':
                        if (this.mickey.lastInArea() === this.mickey.el) {
                            el = this.mickey.firstInArea();
                        } else {
                            el = this.mickey.lastInArea();
                        }
                        break;
                    case 'up':
                        if (this.mickey.firstInArea() === this.mickey.el) {
                            el = this.mickey.lastInArea();
                        } else {
                            el = this.mickey.firstInArea();
                        }
                        break;
                    case 'down':
                        if (this.mickey.lastInArea() === this.mickey.el) {
                            el = this.mickey.firstInArea();
                        } else {
                            el = this.mickey.lastInArea();
                        }
                        break;
                }

                if (el) {
                    this.focus(el);
                }
            }

            // RELEASE-3332: Focus interactive OSD when there is one.
            if (dir === 'up') {
                app.emit('overlay2.focus');
                app.emit('osd-message-interactive.focus');
            }
        },

        /**
         * This method will disable all other inputs on click enter,
         * on the second time the enter key will enable all inputs
         * view buttons logic in form-action-view in "enableSubmitButton" and "disableSubmitButton" methods.
         *
         * @param {jQuery.Element} $el
         */
        inputRemoteHelper: function($el) {
            if (($el.is('.input') && !$el.hasClass('custom-select-selected-container'))
                || $el.hasClass('block-on-ok')
            ) {
                // REMOVE OLD KEYDOWN EVENTS
                if (this.$remoteDisableEl) {
                    this.$remoteDisableEl.off('keydown.remote-input-disable');
                }

                // REGISTER KEYDOWN EVENT ON CURRENT FOCUSED DOM ELEMENT
                this.$remoteDisableEl = $el;
                this.$remoteDisableEl.on('keydown.remote-input-disable', _.throttle(function(event) {
                    if (event.keyCode === this.KEYCODES.ENTER) {
                        // Block remote controller.
                        if (this.mickeyIsBlocked) {
                            this.keyboard.close();
                            this.unblock();
                            $el.focus();
                        } else {
                            this.block();

                            if (!$el.is('.range-input')) {
                                this.keyboard.open();
                            }
                            // Unblock Remote.
                        }
                    }
                }.bind(this), 150));
            }
        },

        /**
         * Emit an event on keydown.
         *
         * @param {Object} event
         */
        keydownHandler: function(event) {
            var keyCode = event.which;
            var keyHandler = this.keyMapping[keyCode];

            if (event.isOnScreenKeyboardEvent) {
                return;
            }

            if ((this.blockAll && !this.limitedAccess)
                || (this.blockAll && (this.limitedAccess && LIMITED_ACCESS_KEYS.indexOf(event.which) === -1))) {
                return;
            }

            if (this.screensaver.isScreensaverActive()
                || this.screensaver.isScreenOffActive()
                || ((this.stationService.getPushStatus() || this.stationService.getLockStatus())
                    && !this.stationService.getAudioStatus() && !this.annotationService.isMatrixAnnotation())) {
                return;
            }

            if (event.altKey && event.ctrlKey && event.shiftKey) {
                keyHandler = this.keyMapping['ctrl+alt+shift+' + keyCode.toString()];
            }

            keyHandler = this.checkArrowKeyHandler(event, keyHandler);

            if (this.stationService.getLimitedAccessStatus() && MATRIX_LIMITED_ACCESS_KEYS.indexOf(keyHandler) === -1
                && !this.isColorKey(event)) {
                return;
            }

            if (keyHandler) {
                if (typeof keyHandler === 'function') {
                    keyHandler.call(this, event.type);
                } else {
                    app.emit('remote.' + keyHandler + '.' + event.type);
                }

                // https://github.com/massiveart/WolfVision-cBox/wiki/Mickey---Remote
                if (!this.usingRemoteControl
                    && this.ENABLE_REMOTE_CONTROL_KEYCODES.indexOf(event.keyCode) !== -1
                ) {
                    this.usingRemoteControl = true;

                    app.emit('app.state.change', {
                        'remote-control': true
                    });
                }
            }
        },

        /**
         * Checks event for isArrowKey is false, then return undefined for a none arrow event.
         * This is a fix for key "%" that will trigger a "leftArrow" key and move in the currently focused input field.
         * (Only in Browser)
         * Event will be triggered in module "KIM" at method "sendInputEvents".
         *
         * @param {jQuery.Event} event
         * @param {mixed} keyHandler
         *
         * @return {mixed}
         */
        checkArrowKeyHandler: function(event, keyHandler) {
            if (event.isArrowKey === undefined) {
                return keyHandler;
            }

            if (['left', 'up', 'right', 'down'].indexOf(keyHandler) !== -1 && event.isArrowKey === false) {
                return;
            }

            return keyHandler;
        },

        /**
         * Check if the key event is a color key.
         *
         * @param event
         * @returns {*|boolean}
         */
        isColorKey: function(event) {
            return event.altKey && event.ctrlKey && event.shiftKey
                && (event.which === 80 || event.which === 81 || event.which === 82 || event.which === 83);
        },

        /**
         * Check if the key event is a special key.
         * RELEASE-3855: Yellow button refreshes browser
         *
         * @param event
         * @returns {boolean}
         */
        isSpecialKey: function(event) {
            if (event.altKey && event.ctrlKey && event.shiftKey) {
                return (typeof this.keyMapping['ctrl+alt+shift+' + event.keyCode.toString()] === 'function')
                    || event.key === 'Shift'; // RELEASE-4196
            }

            return false;
        },

        /**
         * Remove remote state from the main element.
         */
        disableRemoteMode: function() {
            if (this.mickeyIsBlocked) {
                this.unblock(); // RELEASE-1591 - Focus gets stuck in streaming settings.
                this.focusLast(); // Focus clicked element to avoid duplicate focus points.
            }

            if (this.usingRemoteControl) {
                this.usingRemoteControl = false;

                app.emit('app.state.change', {
                    'remote-control': false
                });
            }
        },

        keyMapping: {
            // Mele and Anycon Remote.
            20: function() {
                app.emit('main-loop.fast.start', {
                    id: 'remote'
                });
            },
            13: 'enter',

            33: 'pageUp',

            34: 'pageDown',

            37: 'left',

            38: 'up',

            39: 'right',

            40: 'down',

            '152': 'shutdown',

            'ctrl+alt+shift+65': function(keyEvent) {
                if (!app.getService('MountService').isUSBmounted() || keyEvent !== 'keydown') {
                    return;
                }

                app.emit('file-browser.open', 'USB');
            },

            'ctrl+alt+shift+66': 'quick-settings',

            'ctrl+alt+shift+67': 'menu',

            'ctrl+alt+shift+74': 'zoom-out',

            'ctrl+alt+shift+75': 'zoom-in',

            // Mele Remote
            'ctrl+alt+shift+68': holdDownHelper(
                function() {
                    app.emit('remote.framebox.focus', {
                        index: 0
                    });
                },
                function() {
                    app.emit('remote.autofocus');
                }
            ),

            'ctrl+alt+shift+69': holdDownHelper(
                function() {
                    app.emit('remote.framebox.focus', {
                        index: 1
                    });
                },
                function() {
                    // Block freeze-key when annotation is started.
                    app.emit('remote.freeze');
                }
            ),

            'ctrl+alt+shift+70': holdDownHelper(
                function() {
                    app.emit('remote.framebox.focus', {
                        index: 2
                    });
                },
                function() {
                    app.emit('remote.snapshot');
                }
            ),

            'ctrl+alt+shift+71': holdDownHelper(
                function() {
                    app.emit('remote.framebox.focus', {
                        index: 3
                    });
                },
                function() {
                    app.emit('remote.preset');
                }
            ),

            'ctrl+alt+shift+72': 'focus-in',

            'ctrl+alt+shift+73': 'focus-out',

            // Anycon Remote
            'ctrl+alt+shift+76': 'media-cast',

            'ctrl+alt+shift+77': 'mic-mute',

            'ctrl+alt+shift+78': 'volume-decrease',

            'ctrl+alt+shift+79': 'volume-increase',

            'ctrl+alt+shift+80': holdDownHelper(
                function() {
                    app.emit('remote.framebox.focus', {
                        index: 0
                    });
                },
                function() {
                    app.emit('remote.framebox.focus', {
                        index: 0
                    });
                    app.emit('remote.framebox.toggle', {
                        index: 0
                    });
                }
            ),

            'ctrl+alt+shift+81': holdDownHelper(
                function() {
                    app.emit('remote.framebox.focus', {
                        index: 1
                    });
                },
                function() {
                    app.emit('remote.framebox.focus', {
                        index: 1
                    });
                    app.emit('remote.framebox.toggle', {
                        index: 1
                    });
                }
            ),

            'ctrl+alt+shift+82': holdDownHelper(
                function() {
                    app.emit('remote.framebox.focus', {
                        index: 2
                    });
                },
                function() {
                    app.emit('remote.framebox.focus', {
                        index: 2
                    });
                    app.emit('remote.framebox.toggle', {
                        index: 2
                    });
                }
            ),

            'ctrl+alt+shift+83': holdDownHelper(
                function() {
                    app.emit('remote.framebox.focus', {
                        index: 3
                    });
                },
                function() {
                    app.emit('remote.framebox.focus', {
                        index: 3
                    });
                    app.emit('remote.framebox.toggle', {
                        index: 3
                    });
                }
            ),

            'ctrl+alt+shift+84': holdDownHelper(
                function() {
                    app.emit('overlay.history.back');
                }
            ),

            'ctrl+alt+shift+85': holdDownHelper(
                function() {
                    app.emit('remote.media-cast.keyup');
                },
                function() {
                    app.emit('remote.preset');
                }
            ),

            'ctrl+alt+shift+86': holdDownHelper(
                function() {
                    app.emit('remote.volume-open.keydown');
                },
                function() {
                    app.emit('remote.mic-mute.keyup');
                }
            ),

            'ctrl+alt+shift+87': holdDownHelper(
                function() {
                    app.emit('remote.snapshot');
                },
                function() {
                    var annotationService = app.getService('AnnotationService');
                    var customUiSettings = app.getService('CustomUiSettings');

                    customUiSettings
                        .getSettings(['freezeEnabled'])
                        .then(function(freezeEnabled) {
                            // RELEASE-730: Block freeze-key when annotation is started.
                            // RELEASE-1918: Block freeze-key when freeze is disabled in UI.
                            if (freezeEnabled && !annotationService.isActive()
                                && !annotationService.isMagicPenActive()) {
                                app.emit('remote.freeze');
                            }
                        }.bind(this));
                }
            ),
            '82': holdDownHelper(
                function() {

                },
                function() {
                    if (window.CYNAP_ENV === 'dev') {
                        window.location.reload();
                    }
                }
            )
        },

        /**
         * Set focus to an area.
         *
         * @param {Object} $el
         * @param {Object} options
         * @chainable
         */
        focusArea: function($el, options) {
            var attr = {};

            if (!platform.checks.isIOS && !platform.checks.isAndroid) {
                if (options.area) {
                    attr['data-nav-area'] = options.area;
                } else {
                    throw new Error('No nav area (data-nav-area) defined!');
                }

                if (options.track) {
                    attr['data-nav-track'] = true;
                }

                if (options.selected) {
                    attr['data-nav-selected'] = true;
                }

                if (options.trapped) {
                    attr['data-nav-trapped'] = true;
                }

                if (options.endless) {
                    attr['data-nav-endless'] = true;
                }

                $el.attr(attr);

                this.focus($el);
                this.update();
            }

            return this;
        },

        /**
         * Remove the remote area.
         *
         * @param {Object} el
         * @chainable
         */
        destroyArea: function(el) {
            var $area = app.$(el);
            var $focusedElement = $area.find('.' + this.options.hoverClass);

            if (!platform.checks.isIOS && !platform.checks.isAndroid) {
                $area
                    .removeAttr('data-nav-area')
                    .removeAttr('data-nav-trapped')
                    .removeClass(this.options.areaClass);

                this.unfocus($focusedElement);
            }

            return this;
        },

        /**
         * Remove all the remote area.
         *
         * @chainable
         */
        destroyAllAreas: function() {
            app.$('body [data-nav-area]').each(function(index, el) {
                this.destroyArea(el);
            }.bind(this));

            return this;
        },

        /**
         * Set focus to an element.
         *
         * @param {Object} el
         * @chainable
         */
        focus: function(el) {
            var $el = app.$(el);

            if (!platform.checks.isIOS && !platform.checks.isAndroid) {
                $el.blur();
                $el.focus();
                this.mickey.focus($el.get(0));
            }

            if (!$el.is('input') && !$el.is('textarea')) {
                this.unblock();
            }

            return this;
        },

        /**
         * Returns current focused elment.
         * @returns {*|null}
         */
        getFocus: function() {
            if (!platform.checks.isIOS && !platform.checks.isAndroid) {
                return this.mickey.getFocus();
            }
        },

        /**
         * Remove focus from an element.
         *
         * @param {Object} el
         * @chainable
         */
        unfocus: function(el) {
            var $focusedElement = app.$(el);

            if (!platform.checks.isIOS && !platform.checks.isAndroid) {
                if ($focusedElement.is(this.mickey.el)) {
                    $focusedElement.removeClass(this.options.hoverClass);
                    app.$(this.mickey.el).blur();
                    this.mickey.el = null;
                } else {
                    $focusedElement = $focusedElement.find(this.mickey.el);

                    if ($focusedElement.length) {
                        $focusedElement.removeClass(this.options.hoverClass);
                        app.$(this.mickey.el).blur();
                        this.mickey.el = null;
                    }
                }
            }

            return this;
        },

        /**
         * Disable an item for the keyboard navigation.
         *
         * @param {Object} el
         * @chainable
         */
        disable: function(el) {
            if (!platform.checks.isIOS && !platform.checks.isAndroid) {
                app.$(el).attr({
                    'data-nav-limit': true
                });
            }

            return this;
        },

        /**
         * Enable an item for the keyboard navigation.
         *
         * @param {Object} el
         * @chainable
         */
        enable: function(el) {
            if (!platform.checks.isIOS && !platform.checks.isAndroid) {
                app.$(el).removeAttr('data-nav-limit');
            }

            return this;
        },

        /**
         * @chainable
         */
        update: function() {
            if (!platform.checks.isIOS && !platform.checks.isAndroid) {
                this.mickey.update();
            }

            return this;
        },

        /**
         * Block the mouse movement.
         *
         * @chainable
         */
        block: function() {
            if (!platform.checks.isIOS && !platform.checks.isAndroid) {
                this.mickeyIsBlocked = true;
                this.mickey.block();
            }

            return this;
        },

        /**
         * Unblock the mouse movement.
         *
         * @chainable
         */
        unblock: function() {
            if (this.blockAll) {
                return;
            }

            if (!platform.checks.isIOS && !platform.checks.isAndroid) {
                this.mickeyIsBlocked = false;
                this.mickey.unblock();
            }

            return this;
        },

        stopMove: function() {
            this.mickey.stopMove();
        },

        /**
         * Focus the default element.
         *
         * @chainable
         */
        focusDefault: function() {
            var $el = $('[data-nav-default-focus]');

            if (!platform.checks.isIOS && !platform.checks.isAndroid && $el.length) {
                this.focus($el);
            }

            return this;
        },

        /**
         * Focus the last element.
         *
         * @chainable
         */
        focusLast: function() {
            var $el = $('[data-nav-last-focus]');

            if (!platform.checks.isIOS && !platform.checks.isAndroid && $el.length) {
                this.focus($el);
            } else {
                this.focusDefault();
            }

            return this;
        },

        /**
         * Add the trap data flag.
         *
         * @param {Object} el
         * @chainable
         */
        trap: function(el) {
            if (!platform.checks.isIOS && !platform.checks.isAndroid) {
                app.$(el).attr('data-nav-trapped', true);
            }

            return this;
        },

        /**
         * Remove the trap data flag
         *
         * @param {Object} el
         * @chainable
         */
        unTrap: function(el) {
            if (!platform.checks.isIOS && !platform.checks.isAndroid) {
                app.$(el).removeAttr('data-nav-trapped');
            }

            return this;
        },

        /**
         * Remove the tracking data
         *
         * @param {Object} el
         * @chainable
         */
        unTrack: function(el) {
            var $el = app.$(el);
            $el.removeAttr('data-nav-track data-nav-track-pos');

            return this;
        },

        addStateTransitions: function() {
            this.capslockState.addTransitions({
                '> on': function() {
                    this.$main.addClass('is-capslocked');
                },
                '> off': function() {
                    this.$main.removeClass('is-capslocked');
                }
            });

            this.touchKeyboardState.addTransitions({
                '> on': function() {
                    app.emit('toolbox.item.show', {
                        item: 'keyboard'
                    });

                    app.$('#main').addClass('touch-keyboard-is-enabled');

                    this.keyboard.change({
                        enable: true
                    });
                },
                '> off': function() {
                    app.emit('toolbox.item.hide', {
                        item: 'keyboard'
                    });

                    app.$('#main').removeClass('touch-keyboard-is-enabled');

                    this.keyboard.change({
                        enable: false
                    });
                }
            });
        },

        /**
         * Get last focused element.
         *
         * @returns {*} Last focus
         */
        getLastFocusedEl: function() {
            return this.lastFocusedElement;
        },

        /**
         * Get last focused region.
         *
         * @returns {*} Last focus
         */
        getLastFocusedRegion: function() {
            return this.lastRegion;
        },

        /**
         * Grant remote access to special keys (navigation/enter/standby)
         *
         * @param permit (enable/disable)
         */
        permitLimitedAccess: function(permit) {
            if (!this.mickey) { // Is IOS or Android
                return;
            }

            this.limitedAccess = permit;
            this.mickey.permitLimitedAccess(permit);
        }
    };
});
