'use strict';

var app = require('../../../app');
var tpl = require('./end-annotation.hbs');
var i18n = require('i18next');

app.component('EndAnnotation', {
    template: tpl,
    className: 'end-annotation',

    getAccessKey: function() {
        return {
            'roleName': 'Annotation',
            'roleKey': 'show'
        };
    },

    /**
     * @method initialize
     */
    initialize: function() {
        this.authService = this.getService('AuthenticationService');
        this.annotationService = this.getService('AnnotationService');
        this.storage = app.getService('DataStorage');
    },

    /**
     * @method storeSelectors
     */
    storeSelectors: function() {
        this.$endPresentationQuestion = this.$el.find('.end-title');
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.bindDOMEvents();
        this.storeSelectors();
        this.checkTitle();
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.$el.on('click', '[data-action]', this.onAction.bind(this));
    },

    /**
     * @method checkTitle
     */
    checkTitle: function() {
        this.authService.getLoginRequired().then(function(data) {
            if (data.password) {
                this.$endPresentationQuestion.text(i18n.t('annotation.end_annotation_question'));
            } else {
                this.$endPresentationQuestion.text(i18n.t('annotation.end_annotation_standard_question'));
            }
        }.bind(this));
    },

    /**
     * @method onStandby
     */
    onAction: function(event) {
        var $el = this.$(event.currentTarget);
        var action = $el.data('action');
        var handler = this.actionHandlers[action];

        if (handler) {
            handler.call(this);
            this.emit('modal.close');
        }
    },

    actionHandlers: {
        'discare': function() {
            this.annotationService.stopAnnotationExternal();
        },

        'snapshot': function() {
            this.annotationService.stopAnnotationExternal(true);
        }
    },

    destroy: function() {
        if (typeof this.options.configs.onCancel === 'function') {
            this.options.configs.onCancel();
        }
    }
});

