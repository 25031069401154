// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":39,"column":19}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <foreignObject class=\"svg-grid-devices-title\"\n                               x=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? lookupProperty(stack1,"pos") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                               y=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? lookupProperty(stack1,"pos") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\n                               width=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"itemWidth") : depths[1]), depth0))
    + "\"\n                               height=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"itemHeight") : depths[1]), depth0))
    + "\">\n                    <div class=\"discovery-box-item\">\n                        <div class=\"discovery-box-item-title\">\n                            <span class=\"text\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? lookupProperty(stack1,"text") : stack1),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":47},"end":{"line":12,"column":68}}}))
    + "</span>\n                        </div>\n                    </div>\n                </foreignObject>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":38,"column":25}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <foreignObject id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                                   class=\"svg-grid-device\"\n                                   x=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"pos") : depth0)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                                   y=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"pos") : depth0)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\n                                   width=\""
    + alias2(alias1((depths[2] != null ? lookupProperty(depths[2],"itemWidth") : depths[2]), depth0))
    + "\"\n                                   height=\""
    + alias2(alias1((depths[2] != null ? lookupProperty(depths[2],"itemHeight") : depths[2]), depth0))
    + "\"\n                                   data-ip=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ip") : depth0), depth0))
    + "\"\n                                   data-name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"devname") : depth0), depth0))
    + "\"\n                                   data-icon=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"icon") : depth0), depth0))
    + "\"\n                                   data-type=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\"\n                                   data-model=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"model") : depth0), depth0))
    + "\">\n                        <div class=\"discovery-box-item\">\n                            <div class=\"discovery-box-item-icon\">\n                                <span class=\"icon "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"icon") : depth0), depth0))
    + "\"></span>\n                            </div>\n                            <div class=\"discovery-box-item-text\">\n                                <span class=\"text\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"devname") : depth0), depth0))
    + "</span>\n                                <span class=\"text\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ip") : depth0), depth0))
    + "</span>\n                            </div>\n                        </div>\n                    </foreignObject>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg id=\"svg-grid-devices\" class=\"svg-grid-devices\" viewBox=\"0, 0, "
    + alias4(((helper = (helper = lookupProperty(helpers,"width") || (depth0 != null ? lookupProperty(depth0,"width") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"width","hash":{},"data":data,"loc":{"start":{"line":1,"column":67},"end":{"line":1,"column":76}}}) : helper)))
    + ", "
    + alias4(((helper = (helper = lookupProperty(helpers,"height") || (depth0 != null ? lookupProperty(depth0,"height") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"height","hash":{},"data":data,"loc":{"start":{"line":1,"column":78},"end":{"line":1,"column":88}}}) : helper)))
    + "\">\n    <g class=\"devices-layer\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"devices") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":40,"column":17}}})) != null ? stack1 : "")
    + "    </g>\n</svg>\n";
},"useData":true,"useDepths":true});
