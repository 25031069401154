'use strict';

var app = require('../../../app');
var volumeTpl = require('./framebox-volume.hbs');

var muteStates = {
    none: 'none',
    on: 'on',
    off: 'off'
};

app.component('FrameboxVolume', {
    template: volumeTpl,
    className: 'framebox-volume-container framebox-submenu-inner',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.isMaster = false;
        this.isMasterTimeout = null;

        this.mute = this.createStateMachine({
            state: muteStates.none,
            states: muteStates
        });

        this.addStateTransitions();

        this.deviceService = this.getService('DeviceService');
        this.remote = this.getService('RemoteService');
        this.volumeService = this.getService('VolumeService');
        this.frameboxService = this.getService('FrameBoxService');
        this.submenuOpen = false;
    },

    postPlaceAt: function() {
        this.appendRangeInput();
        this.storeSelectors();
        this.bindEvents();
        this.bindDOMEvents();
        this.updateData();
    },

    storeSelectors: function() {
        this.$document = this.$(document);
        this.$input = this.$el.find('input');
        this.$volumeContainer = app.$el.find('.control-volume');
    },

    bindEvents: function() {
        this.on('framebox.volume.open', this.open.bind(this));
        this.on('framebox.volume.toggle', this.toggle.bind(this));
        this.on('framebox.mute.toggle', this.toggleMute.bind(this));
        this.on('framebox.submenu.close', this.close.bind(this));
        this.on('framebox.change.' + this.index, this.updateData.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.framebox-volume-mute', this.toggleMute.bind(this));
        this.$input.on('mousedown mousemove touchstart touchmove', this.startMasterSlaveHandler.bind(this));
        this.$input.on('mouseup touchend', this.stopMasterSlaveHandler.bind(this));
    },

    unbindDOMEvents: function() {
        this.$document.off('click.framebox-volume');
    },

    /**
     * Toggle mute.
     *
     * @param {object}  options
     * @param {int}     options.index
     */
    toggleMute: function(options) {
        if (this.index === options.index) {
            this.emit('main-loop.fast.start', {
                id: 'framebox-mute'
            });

            switch (this.mute.getState()) {
                case 'off':
                    this.deviceConnection
                        .send('setApplicationMute', {
                            appId: this.appId,
                            mute: 'on'
                        });
                    this.mute.changeState(muteStates.on);
                    break;
                case 'on':
                    this.deviceConnection
                        .send('setApplicationMute', {
                            appId: this.appId,
                            mute: 'off'
                        });
                    this.mute.changeState(muteStates.off);
                    break;
            }
        }
    },

    /**
     * Open volume submenu.
     *
     * @param options.index Framebox index
     */
    open: function(options) {
        if (this.index === options.index) {
            this.updateData();

            this.$el.show();
            this.remote.focus(this.$input.get(0));

            if (this.deviceService.isCboxProDualProjection()) {
                const spaceBetween = 2;
                this.$el.css('left', options.triggerCoords.x);
                this.$el.css('top', options.triggerCoords.y - this.$el.height() - spaceBetween);

                this.$volumeContainer.addClass('is-submenu-open');
                this.emit('osk.close');
            } else {
                this.emit('framebox.submenu.change', this.index, true, this.$el.parent());
            }

            this.submenuOpen = true;
        }
    },

    /**
     * Close volume submenu.
     */
    close: function() {
        this.$el.hide();
        this.unbindDOMEvents();

        if (this.deviceService.isCboxProDualProjection()) {
            this.$volumeContainer.removeClass('is-submenu-open');
        } else {
            this.emit('framebox.submenu.change', this.index, false);
        }

        this.submenuOpen = false;
    },

    /**
     * Toggle volume v3 submenu.
     */
    toggle: function(options) {
        if (this.submenuOpen) {
            this.close();
        } else {
            this.open(options);
        }
    },

    /**
     * Update framebox data.
     */
    updateData: function() {
        const box = this.frameboxService.frameboxes.boxes[this.index];

        if (box) {
            this.frameboxData = box;

            if (!this.sync) {
                this.volume = this.frameboxData.volume;

                if (parseInt(this.$input.val()) !== this.volume) {
                    this.$input.val(this.volume).trigger('change');
                }
            }

            if (this.frameboxData.mute && this.mute.getState() !== muteStates[this.frameboxData.mute]) {
                this.mute.changeState(muteStates[this.frameboxData.mute]);
            }
        }
    },

    /**
     * Append range input.
     */
    appendRangeInput: function() {
        this.createComponent({
            type: 'RangeInput',
            container: this.$el.find('.framebox-volume-range'),
            input: {
                id: 'control-bar-range-input',
                min: 0,
                minIcon: 'icon-v2-speaker-down',
                max: 125,
                maxIcon: 'icon-v2-speaker-up',
                step: 5,
                onChange: this.onChangeHandler.bind(this),
                onEnterPress: this.onEnterPress.bind(this),
                redesign: true
            }
        });
    },

    /**
     * Handle volume changes.
     *
     * @param newValue New volume level value
     */
    onChangeHandler: function(newValue) {
        if (this.volume !== parseInt(newValue)) {
            this.sync = true;
            this.deviceConnection
                .send('setApplicationVolume', {
                    appId: this.appId,
                    volume: newValue
                }).then(function() {
                    this.volume = parseInt(newValue);
                    this.sync = false;

                    if ((newValue <= 0 && this.mute.getState() === muteStates.off)
                        || (newValue > 0 && this.mute.getState() === muteStates.on)) {
                        this.toggleMute({
                            index: this.index
                        });
                    }
                }.bind(this), function() {
                    this.sync = false;
                }.bind(this));
        }

        this.emit('control-bar-timer.start');
    },

    /**
     * Handle enter key press.
     */
    onEnterPress: function() {
        this.emit('framebox.submenu.close');
    },

    /**
     * Start master slave handler.
     */
    startMasterSlaveHandler: function() {
        clearTimeout(this.isMasterTimeout);
        this.isMasterTimeout = setTimeout(this.stopMasterSlaveHandler.bind(this), 2000);
        this.isMaster = true;
    },

    /**
     * Stop master slave handler.
     */
    stopMasterSlaveHandler: function() {
        // Set a timeout before clearing the master timeout,
        // So the value is not immediately overwritten after it is set.
        setTimeout(function() {
            clearTimeout(this.isMasterTimeout);
            this.isMaster = false;
            this.$input.click();
        }.bind(this), 1000);
    },

    addStateTransitions: function() {
        this.mute.addTransitions({
            '> on': function() {
                this.emit('framebox.control-state.change', {
                    index: this.index,
                    id: 'mute',
                    state: 'mute'
                });
            },

            '> off': function() {
                this.emit('framebox.control-state.change', {
                    index: this.index,
                    id: 'mute',
                    state: 'unmute'
                });
            }
        });
    }
});
