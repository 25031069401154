// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div id=\"stream-mode-container\">\n                    <div class=\"row settings-list-secundary\"\n                         data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                         data-nav-trapped>\n                        <div class=\"col-xs-8\">\n                            <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.dynamic_qr_code",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":52},"end":{"line":64,"column":84}}}))
    + "</h2>\n                        </div>\n                        <div class=\"col-xs-4\">\n                            <div class=\"btn-switch-container pull-right halfsize-switch m-top\">\n                                <input id=\"dynamicQrCode\"\n                                       name=\"dynamicQrCode\"\n                                       type=\"checkbox\"\n                                       class=\"btn-switch is-focusable\"\n                                       tabIndex=\"1\"/>\n                                <div class=\"btn-switch-toggle-container\">\n                                    <div class=\"btn-switch-toggle\"></div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n\n                    <!-- RTP Streaming -->\n                    <div class=\"row settings-list-row\"\n                         data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                         data-nav-trapped>\n                        <div class=\"col-xs-8\">\n                            <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.streaming_rtp",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":32},"end":{"line":85,"column":62}}}))
    + "</h2>\n                        </div>\n                        <div class=\"col-xs-4\">\n                            <div class=\"btn-switch-container pull-right\">\n                                <input id=\"enableRtp\" name=\"enableRtp\" type=\"checkbox\" class=\"btn-switch\"/>\n                                <div class=\"btn-switch-toggle-container\">\n                                    <div class=\"btn-switch-toggle\"></div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDualProjection") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":97,"column":20},"end":{"line":290,"column":27}}})) != null ? stack1 : "")
    + "\n                    <!-- RTSP Streaming -->\n                    <div class=\"row settings-list-row\"\n                         data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                         data-nav-trapped>\n                        <div class=\"col-xs-8\">\n                            <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.streaming_rtsp",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":297,"column":32},"end":{"line":297,"column":63}}}))
    + "</h2>\n                        </div>\n                        <div class=\"col-xs-4\">\n                            <div class=\"btn-switch-container pull-right\">\n                                <input id=\"enableRtsp\" name=\"enableRtsp\" type=\"checkbox\" class=\"btn-switch\"/>\n                                <div class=\"btn-switch-toggle-container\">\n                                    <div class=\"btn-switch-toggle\"></div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"rtsp-stream-settings\">\n                        <div class=\"row settings-list-secundary\"\n                             data-nav-area=\".btn-switch\"\n                             data-nav-trapped\n                             data-nav-area-default>\n                            <div class=\"col-xs-8\">\n                                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.enable_lan1",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":314,"column":56},"end":{"line":314,"column":84}}}))
    + "</h2>\n                            </div>\n                            <div class=\"col-xs-4\">\n                                <div class=\"btn-switch-container pull-right\">\n                                    <input name=\"enableRTSPLan1\"\n                                           id=\"enableRTSPLan1\"\n                                           type=\"checkbox\"\n                                           class=\"btn-switch\"/>\n                                    <div class=\"btn-switch-toggle-container\">\n                                        <div class=\"btn-switch-toggle\"></div>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                        <div class=\"row settings-list-secundary\"\n                             data-nav-area=\".btn-switch\"\n                             data-nav-trapped\n                             data-nav-area-default>\n                            <div class=\"col-xs-8\">\n                                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.enable_lan2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":333,"column":56},"end":{"line":333,"column":84}}}))
    + "</h2>\n                            </div>\n                            <div class=\"col-xs-4\">\n                                <div class=\"btn-switch-container pull-right\">\n                                    <input name=\"enableRTSPLan2\"\n                                           id=\"enableRTSPLan2\"\n                                           type=\"checkbox\"\n                                           class=\"btn-switch\"/>\n                                    <div class=\"btn-switch-toggle-container\">\n                                        <div class=\"btn-switch-toggle\"></div>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                        <div class=\"row settings-list-secundary\"\n                             data-nav-area=\".btn-switch\"\n                             data-nav-trapped\n                             data-nav-area-default>\n                            <div class=\"col-xs-8\">\n                                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.enable_wlan",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":352,"column":56},"end":{"line":352,"column":84}}}))
    + "</h2>\n                            </div>\n                            <div class=\"col-xs-4\">\n                                <div class=\"btn-switch-container pull-right\">\n                                    <input name=\"enableRTSPWlan\"\n                                           id=\"enableRTSPWlan\"\n                                           type=\"checkbox\"\n                                           class=\"btn-switch\"/>\n                                    <div class=\"btn-switch-toggle-container\">\n                                        <div class=\"btn-switch-toggle\"></div>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n\n                        <div class=\"row settings-list-secundary\"\n                             data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                             data-nav-trapped>\n                            <div class=\"col-xs-6\">\n                                <div id=\"interface-select\"></div>\n                            </div>\n                            <div class=\"col-xs-6\"></div>\n                        </div>\n                    </div>\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"row settings-list-row rtp-stream-settings\">\n                            <div class=\"col-xs-8\">\n                                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.streaming_hdmi1",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":100,"column":36},"end":{"line":100,"column":68}}}))
    + "</h2>\n                            </div>\n                        </div>\n                        <div class=\"rtp-stream-settings\">\n                            <div class=\"row settings-list-secundary\"\n                                data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                                data-nav-trapped>\n                                <div class=\"col-xs-6\">\n                                    <div class=\"input-group\">\n                                        <label class=\"input-label ip-label\" for=\"ipAddressHdmi1\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ip_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":109,"column":97},"end":{"line":110,"column":67}}}))
    + "</label>\n                                        <input id=\"ipAddressHdmi1\"\n                                            type=\"text\"\n                                            class=\"input ipAddress\"\n                                            name=\"ipAddressHdmi1\"\n                                            placeholder=\"0.0.0.0\"\n                                            data-validation=\"checkMulticast\"\n                                            data-main-input-selector=\"#interface\"/>\n                                        <div class=\"input-info\">\n                                            <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_ip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":119,"column":91},"end":{"line":119,"column":116}}}))
    + "</span>\n                                            <span class=\"input-info-message success-message\"></span>\n                                        </div>\n                                    </div>\n                                </div>\n                                <div class=\"col-xs-6\">\n                                    <div id=\"ttl-selectHdmi1\"></div>\n                                </div>\n                            </div>\n\n                            <div class=\"row settings-list-secundary\"\n                                data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                                data-nav-trapped>\n                                <div class=\"col-xs-6\">\n                                    <div class=\"input-group\">\n                                        <label class=\"input-label\" for=\"portHdmi1\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.port",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":134,"column":83},"end":{"line":134,"column":104}}}))
    + "</label>\n                                        <input id=\"portHdmi1\"\n                                            type=\"text\"\n                                            class=\"input\"\n                                            name=\"portHdmi1\"\n                                            placeholder=\"9000\"\n                                            data-min-port=\""
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"minPort") : depth0), depth0))
    + "\"\n                                            data-max-port=\""
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"maxPort") : depth0), depth0))
    + "\"\n                                            data-validation=\"checkStreamingPort\"/>\n                                        <div class=\"input-info\">\n                                            <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias2).call(alias1,depth0,{"name":"tr","hash":{"key":"settings.wrong_streaming_port"},"data":data,"loc":{"start":{"line":144,"column":91},"end":{"line":145,"column":85}}}))
    + "</span>\n                                        </div>\n                                    </div>\n                                </div>\n                                <div class=\"col-xs-6\">\n                                    <div class=\"input-group\">\n                                        <label class=\"input-label\" for=\"port-audioHdmi1\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.port_audio",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":151,"column":89},"end":{"line":151,"column":116}}}))
    + "</label>\n                                        <input id=\"portAudioHdmi1\" type=\"text\" class=\"input\" name=\"portAudioHdmi1\"\n                                            placeholder=\"9000\"/>\n                                    </div>\n                                </div>\n                            </div>\n                            <div class=\"row settings-list-secundary settings-list-info iface-not-supported hidden\">\n                                <div class=\"col-xs-12\">\n                                    <p class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.iface_mode_error",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":159,"column":80},"end":{"line":159,"column":113}}}))
    + "</p>\n                                </div>\n                            </div>\n                        </div>\n\n                        <div class=\"row settings-list-row rtp-stream-settings\">\n                            <div class=\"col-xs-8\">\n                                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.streaming_hdmi2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":166,"column":36},"end":{"line":166,"column":68}}}))
    + "</h2>\n                            </div>\n                        </div>\n                        <div class=\"rtp-stream-settings\">\n                            <div class=\"row settings-list-secundary\"\n                                data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                                data-nav-trapped>\n                                <div class=\"col-xs-6\">\n                                    <div class=\"input-group\">\n                                        <label class=\"input-label ip-label\" for=\"ipAddressHdmi2\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ip_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":175,"column":97},"end":{"line":176,"column":67}}}))
    + "</label>\n                                        <input id=\"ipAddressHdmi2\"\n                                            type=\"text\"\n                                            class=\"input ipAddress\"\n                                            name=\"ipAddressHdmi2\"\n                                            placeholder=\"0.0.0.0\"\n                                            data-validation=\"checkMulticast\"\n                                            data-main-input-selector=\"#interface\"/>\n                                        <div class=\"input-info\">\n                                            <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_ip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":185,"column":91},"end":{"line":185,"column":116}}}))
    + "</span>\n                                            <span class=\"input-info-message success-message\"></span>\n                                        </div>\n                                    </div>\n                                </div>\n                                <div class=\"col-xs-6\">\n                                    <div id=\"ttl-selectHdmi2\"></div>\n                                </div>\n                            </div>\n\n                            <div class=\"row settings-list-secundary\"\n                                data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                                data-nav-trapped>\n                                <div class=\"col-xs-6\">\n                                    <div class=\"input-group\">\n                                        <label class=\"input-label\" for=\"portHdmi2\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.port",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":200,"column":83},"end":{"line":200,"column":104}}}))
    + "</label>\n                                        <input id=\"portHdmi2\"\n                                            type=\"text\"\n                                            class=\"input\"\n                                            name=\"portHdmi2\"\n                                            placeholder=\"9000\"\n                                            data-min-port=\""
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"minPort") : depth0), depth0))
    + "\"\n                                            data-max-port=\""
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"maxPort") : depth0), depth0))
    + "\"\n                                            data-validation=\"checkStreamingPort\"/>\n                                        <div class=\"input-info\">\n                                            <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias2).call(alias1,depth0,{"name":"tr","hash":{"key":"settings.wrong_streaming_port"},"data":data,"loc":{"start":{"line":210,"column":91},"end":{"line":211,"column":85}}}))
    + "</span>\n                                        </div>\n                                    </div>\n                                </div>\n                                <div class=\"col-xs-6\">\n                                    <div class=\"input-group\">\n                                        <label class=\"input-label\" for=\"port-audioHdmi2\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.port_audio",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":217,"column":89},"end":{"line":217,"column":116}}}))
    + "</label>\n                                        <input id=\"portAudioHdmi2\" type=\"text\" class=\"input\" name=\"portAudioHdmi2\"\n                                            placeholder=\"9000\"/>\n                                    </div>\n                                </div>\n                            </div>\n                            <div class=\"row settings-list-secundary settings-list-info iface-not-supported hidden\">\n                                <div class=\"col-xs-12\">\n                                    <p class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.iface_mode_error",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":225,"column":80},"end":{"line":225,"column":113}}}))
    + "</p>\n                                </div>\n                            </div>\n                        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"rtp-stream-settings\">\n                            <div class=\"row settings-list-secundary\"\n                                data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                                data-nav-trapped>\n                                <div class=\"col-xs-6\">\n                                    <div class=\"input-group\">\n                                        <label class=\"input-label ip-label\" for=\"ipAddress\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ip_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":236,"column":92},"end":{"line":237,"column":67}}}))
    + "</label>\n                                        <input id=\"ipAddress\"\n                                            type=\"text\"\n                                            class=\"input ipAddress\"\n                                            name=\"ipAddress\"\n                                            placeholder=\"0.0.0.0\"\n                                            data-validation=\"checkMulticast\"\n                                            data-main-input-selector=\"#interface\"/>\n                                        <div class=\"input-info\">\n                                            <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_ip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":246,"column":91},"end":{"line":246,"column":116}}}))
    + "</span>\n                                            <span class=\"input-info-message success-message\"></span>\n                                        </div>\n                                    </div>\n                                </div>\n                                <div class=\"col-xs-6\">\n                                    <div id=\"ttl-select\"></div>\n                                </div>\n                            </div>\n\n                            <div class=\"row settings-list-secundary\"\n                                data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                                data-nav-trapped>\n                                <div class=\"col-xs-6\">\n                                    <div class=\"input-group\">\n                                        <label class=\"input-label\" for=\"port\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.port",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":261,"column":78},"end":{"line":261,"column":99}}}))
    + "</label>\n                                        <input id=\"port\"\n                                            type=\"text\"\n                                            class=\"input\"\n                                            name=\"port\"\n                                            placeholder=\"9000\"\n                                            data-min-port=\""
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"minPort") : depth0), depth0))
    + "\"\n                                            data-max-port=\""
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"maxPort") : depth0), depth0))
    + "\"\n                                            data-validation=\"checkStreamingPort\"/>\n                                        <div class=\"input-info\">\n                                            <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias2).call(alias1,depth0,{"name":"tr","hash":{"key":"settings.wrong_streaming_port"},"data":data,"loc":{"start":{"line":271,"column":91},"end":{"line":272,"column":85}}}))
    + "</span>\n                                        </div>\n                                    </div>\n                                </div>\n                                <div class=\"col-xs-6\">\n                                    <div class=\"input-group\">\n                                        <label class=\"input-label\" for=\"port-audio\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.port_audio",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":278,"column":84},"end":{"line":278,"column":111}}}))
    + "</label>\n                                        <input id=\"portAudio\" type=\"text\" class=\"input\" name=\"portAudio\"\n                                            placeholder=\"9000\"/>\n                                    </div>\n                                </div>\n                            </div>\n                            <div class=\"row settings-list-secundary settings-list-info iface-not-supported hidden\">\n                                <div class=\"col-xs-12\">\n                                    <p class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.iface_mode_error",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":286,"column":80},"end":{"line":286,"column":113}}}))
    + "</p>\n                                </div>\n                            </div>\n                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"streaming-settings\" name=\"streaming-settings\">\n        <!-- Streaming info notifications -->\n        <div class=\"row settings-list-secundary settings-list-info matrix-enabled-info hidden\">\n            <div class=\"col-xs-12\">\n                <p class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.streaming_matrix_enabled",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":60},"end":{"line":6,"column":101}}}))
    + "</p>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary settings-list-info matrix-enabled-webcast-info hidden\">\n            <div class=\"col-xs-12\">\n                <p class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.remote_streaming_matrix_enabled",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":60},"end":{"line":12,"column":108}}}))
    + "</p>\n            </div>\n        </div>\n\n        <div id=\"streaming-enable-container\">\n            <div id=\"streaming-enable-container-inner\"\n                 class=\"row settings-list-row\"\n                 data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"col-xs-8\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.streaming_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":24},"end":{"line":23,"column":57}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"streamEnabled\" name=\"streamEnabled\" type=\"checkbox\" class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"streaming-settings-container\">\n            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                 data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <div id=\"resolution-select\"></div>\n                </div>\n                <div class=\"col-xs-6\">\n                    <div id=\"framerate-select\"></div>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                 data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <div id=\"bandwidth-mode-select\"></div>\n                </div>\n                <div class=\"col-xs-6\">\n                    <div id=\"bandwidth-select\"></div>\n                </div>\n            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"supportsStreaming") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":12},"end":{"line":377,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </form>\n\n    <div id=\"remote-streaming-settings-container\"></div>\n\n    <div class=\"row settings-list-row settings-list-info multiple-stream-error hidden\">\n        <div class=\"col-xs-12\">\n            <p class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.multiple_stream_error",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":385,"column":56},"end":{"line":385,"column":94}}}))
    + "</p>\n        </div>\n    </div>\n\n    <div id=\"form-action-container\"></div>\n</div>\n";
},"useData":true});
