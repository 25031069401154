'use strict';

var app = require('../../../../app');

var controlItems = [
    {
        id: 'scroll-down',
        state: 'scroll-down',
        states: {
            'scroll-down': {
                icon: 'icon-v2-cursor-down',
                titleKey: ''
            }
        }
    },
    {
        id: 'scroll-up',
        state: 'scroll-up',
        states: {
            'scroll-up': {
                icon: 'icon-v2-cursor-up',
                titleKey: ''
            }
        }
    },
    {
        id: 'scroll-left',
        state: 'scroll-left',
        states: {
            'scroll-left': {
                icon: 'icon-v2-cursor-left',
                titleKey: ''
            }
        }
    },
    {
        id: 'scroll-right',
        state: 'scroll-right',
        states: {
            'scroll-right': {
                icon: 'icon-v2-cursor-right',
                titleKey: ''
            }
        },
        groupEnd: true
    },
    {
        id: 'prev-page',
        state: 'prev-page',
        states: {
            'prev-page': {
                icon: 'icon-v2-arrow-left',
                titleKey: ''
            }
        }
    },
    {
        id: 'next-page',
        state: 'next-page',
        states: {
            'next-page': {
                icon: 'icon-v2-arrow-right',
                titleKey: ''
            }
        },
        groupEnd: true
    },
    {
        id: 'zoom-out',
        state: 'zoom-out',
        states: {
            'zoom-out': {
                icon: 'icon-v2-zoom-out',
                titleKey: ''
            }
        }
    },
    {
        id: 'zoom-in',
        state: 'zoom-in',
        states: {
            'zoom-in': {
                icon: 'icon-v2-zoom-in',
                titleKey: ''
            }
        }
    },
    {
        id: 'full-page',
        state: 'full-page',
        states: {
            'full-page': {
                icon: 'icon-v2-fit-to-width-height',
                titleKey: ''
            }
        }
    },
    {
        id: 'full-page-width',
        state: 'full-page-width',
        states: {
            'full-page-width': {
                icon: 'icon-v2-fit-to-width',
                titleKey: ''
            }
        }
    }
];

/**
 * Text frame
 */
app.component('FrameBoxTextViewer', {
    extend: 'FrameBoxBaseType',
    className: 'framebox-view framebox-vsolution-view',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.frameBoxService = this.getService('FrameBoxService');
        this.options.allowedEvents = ['touchstart', 'touchend', 'touchmove', 'mousedown', 'mouseup'];
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.startControls();
        this.bindEvents();

        this.startTouchEventsEntity({
            $actionEl: this.options.$actionEl,
            allowedEvents: this.options.allowedEvents,
            disableSelection: true,
            scrollbar: {
                x: 0.013,
                y: 0.030
            },
            debugging: false
        });
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        // Standard-mode Events.
        this.on('framebox.standard.pinchin', this.controlHandlers['zoom-out'].bind(this));
        this.on('framebox.standard.pinchout', this.controlHandlers['zoom-in'].bind(this));
        this.on('framebox.standard.pandown', this.controlHandlers['scroll-up'].bind(this));
        this.on('framebox.standard.panup', this.controlHandlers['scroll-down'].bind(this));
        this.on('framebox.standard.panleft', this.controlHandlers['scroll-right'].bind(this));
        this.on('framebox.standard.panright', this.controlHandlers['scroll-left'].bind(this));

        // Remote-control Events.
        this.on('framebox.standard.left.keydown', this.controlHandlers['cursor-left'].bind(this));
        this.on('framebox.standard.right.keydown', this.controlHandlers['cursor-right'].bind(this));
        this.on('framebox.standard.up.keydown', this.controlHandlers['cursor-up'].bind(this));
        this.on('framebox.standard.down.keydown', this.controlHandlers['cursor-down'].bind(this));
        this.on('framebox.standard.zoom-in.keydown', this.controlHandlers['zoom-in'].bind(this));
        this.on('framebox.standard.zoom-out.keydown', this.controlHandlers['zoom-out'].bind(this));
        this.on('framebox.standard.preset', this.controlHandlers['preset'].bind(this));

        // Mouse Events.
        this.on('framebox.standard.mousewheel-up', this.controlHandlers['scroll-up'].bind(this));
        this.on('framebox.standard.mousewheel-down', this.controlHandlers['scroll-down'].bind(this));
    },

    /**
     * @method startControls
     */
    startControls: function() {
        this.createComponent({
            type: 'FrameBoxControls',
            container: this.$el,
            index: this.options.index,
            appId: this.options.appId,
            items: controlItems,
            onClick: this.onControlClickHandler.bind(this),
            isFullscreen: this.options.isFullscreen
        });
    },

    /**
     * @method onClickHandler
     * @param {string} id
     */
    onControlClickHandler: function(id) {
        var handler = this.controlHandlers[id];

        if (handler) {
            handler.call(this, {
                index: this.index
            });
        }
    },

    controlHandlers: {
        'zoom-in': function(options) {
            this.sendCmd('zoomIn', options.index);
        },
        'zoom-out': function(options) {
            this.sendCmd('zoomOut', options.index);
        },
        'scroll-down': function(options) {
            this.sendCmd('scrollDown', options.index);
        },
        'scroll-up': function(options) {
            this.sendCmd('scrollUp', options.index);
        },
        'scroll-left': function(options) {
            this.sendCmd('scrollLeft', options.index);
        },
        'scroll-right': function(options) {
            this.sendCmd('scrollRight', options.index);
        },
        'cursor-down': function(options) {
            this.sendCmd('cursorDown', options.index);
        },
        'cursor-up': function(options) {
            this.sendCmd('cursorUp', options.index);
        },
        'cursor-left': function(options) {
            this.sendCmd('cursorLeft', options.index);
        },
        'cursor-right': function(options) {
            this.sendCmd('cursorRight', options.index);
        },
        'prev-page': function(options) {
            this.sendCmd('prevPage', options.index);
        },
        'next-page': function(options) {
            this.sendCmd('nextPage', options.index);
        },
        'preset': function(options) {
            this.sendCmd('fullWidth', options.index);
        },
        'full-page': function(options) {
            this.sendCmd('fullPage', options.index);
        },
        'full-page-width': function(options) {
            this.sendCmd('fullWidth', options.index);
        }
    },

    /**
     * @method cmd
     * @param {string} cmd
     * @param {int} index
     */
    sendCmd: function(cmd, index) {
        if (this.index === index) {
            this.send('setOfficeControl', {
                appId: this.appId,
                officeControl: cmd,
                type: 'text'
            });
        }
    },

    destroy: function() {
        this.stopTouchEventsEntitiy();
    }
});
