// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"media-casting-overlay\">\n    <div class=\"row\">\n        <div class=\"col-xs-12\">\n            <h2>\n                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.opencast_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":44}}}))
    + "\n                <span id=\"opencast-login-state\" class=\"opencast-login-state\">\n                    <span class=\"opencast-login-state-logged-in\">\n                        <div class=\"opencast-login-state-point\"></div>\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.logged_in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":9,"column":50}}}))
    + "\n                    </span>\n                    <span class=\"opencast-login-state-not-logged-in\">\n                        <div class=\"opencast-login-state-point\"></div>\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.not_logged_in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":13,"column":54}}}))
    + "\n                    </span>\n                    <span class=\"opencast-login-state-login-failed\">\n                        <div class=\"opencast-login-state-point\"></div>\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.login_failed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":53}}}))
    + "\n                    </span>\n                </span>\n            </h2>\n        </div>\n    </div>\n\n    <div class=\"file-tree opencast-file-tree\">\n        <ul data-tree-id=\"root\"></ul>\n    </div>\n    <form id=\"opencast-form\" class=\"opencast-info-container\" name=\"opencast-form\">\n        <div class=\"input-group\">\n            <label class=\"input-label\" for=\"title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":51},"end":{"line":29,"column":70}}}))
    + "</label>\n            <input type=\"text\"\n                   class=\"input is-focusable\"\n                   name=\"title\"\n                   id=\"title\"\n                   data-validation=\"checkMinMaxLength\"\n                   data-min-length=\"1\"\n                   data-max-length=\"254\"\n                   tabIndex=\"-1\" />\n            <div class=\"input-info\">\n                 <span class=\"input-info-message error-message\">\n                     "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"error_messages.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":21},"end":{"line":40,"column":58}}}))
    + "\n                 </span>\n            </div>\n        </div>\n        <div class=\"input-group\">\n            <label class=\"input-label\" for=\"creator\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.creator_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":53},"end":{"line":45,"column":79}}}))
    + "</label>\n            <input type=\"text\"\n                   class=\"input is-focusable\"\n                   name=\"creator\"\n                   id=\"creator\"\n                   data-validation=\"checkMinMaxLength\"\n                   data-min-length=\"1\"\n                   data-max-length=\"254\"\n                   data-allow-empty=\"true\"\n                   tabIndex=\"-1\" />\n            <div class=\"input-info\">\n                <span class=\"input-info-message error-message\">\n                    "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"error_messages.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":20},"end":{"line":57,"column":57}}}))
    + "\n                </span>\n            </div>\n        </div>\n    </form>\n\n    <div class=\"row start-btn-container\">\n        <input id=\"start-opencast\" class=\"btn-form form-action-submit services-disabled is-focusable\"\n               tabindex=\"-1\" type=\"button\" value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.start_opencast",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":50},"end":{"line":65,"column":78}}}))
    + "\"/>\n    </div>\n</div>\n";
},"useData":true});
