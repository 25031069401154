'use strict';

var Handlebars = require('hbsfy/runtime');
var i18n = require('i18next');

Handlebars.registerHelper('math', function(lvalue, operator, rvalue) {
    lvalue = parseFloat(lvalue);
    rvalue = parseFloat(rvalue);

    return {
        '+': lvalue + rvalue,
        '-': lvalue - rvalue,
        '*': lvalue * rvalue,
        '/': lvalue / rvalue,
        '%': lvalue % rvalue
    }[operator];
});

/**
 * Register t
 *
 * {{t "ethernetSettings.v4.autoConfig" }}
 *
 */
Handlebars.registerHelper('t', function(i18nKey) {
    var result = i18n.t(i18nKey);

    return new Handlebars.SafeString(result);
});

/**
 * Register tr
 * can replace vars
 *
 * "cloud-title": "Cloud Title: __cloud.title__"
 *
 * {{tr this key="cloud-title"}}
 *
 */
Handlebars.registerHelper('tr', function(context, options) {
    var result = i18n.t(options.hash.key, context);

    return new Handlebars.SafeString(result);
});

/**
 * Register if_even
 *
 * Conditionally render a block if the  @index  is an even number
 *
 * {{if_even @index}}
 *
 */
Handlebars.registerHelper('if_even', function(conditional, options) {
    if ((conditional % 2) === 0) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
});

/**
 * Register if_equals
 *
 * Conditionally render a block if the option1 equals option2
 *
 * {{if_equals option1 option2}}
 *
 */
Handlebars.registerHelper('if_equals', function(option1, option2, options) {
    return (option1 === option2) ? options.fn(this) : options.inverse(this);
});

/**
 * Register if_or
 *
 * Conditionally render a block if option1 or option 2  is true
 *
 * {{if_or option1 option2}}
 *
 */
Handlebars.registerHelper('if_or', function(option1, option2, options) {
    return (option1 || option2) ? options.fn(this) : options.inverse(this);
});

/**
 * Register unless_or
 *
 * Conditionally do NOT render a block if option1 or option 2 is true
 *
 * {{unless_or option1 option2}}
 *
 */
Handlebars.registerHelper('unless_or', function(option1, option2, options) {
    return (option1 || option2) ? options.inverse(this) : options.fn(this);
});

/**
 * Register debug
 *
 * output value to console
 *
 * {{debug @value}}
 *
 */
Handlebars.registerHelper('debug', function(value) {
    console.log('Value');
    console.log('====================');
    console.log(value);
});
