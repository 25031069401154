'use strict';

var app = require('../../../app');
var platform = require('./../../../../platform/platform');
var $ = require('jquery');

const strengthMapping = require('../constants').strengthMapping;
const opacityMapping = require('../constants').opacityMapping;

/**
 * Magic Pen
 */
app.component('MagicPen', {
    extend: 'AnnotationAbstract',
    className: 'magic-pen-container annotation-container stop-overscroll is-active',

    initialize: function() {
        this.annotationService = this.getService('AnnotationService');
        this.index = this.getIndex();
        this.loadStoredSettings(this.index);
        this.frameBoxService = this.getService('FrameBoxService');
        this.liveStream = this.getService('LiveStreamService');
        this.remote = this.getService('RemoteService');

        this.stopAction = false;
        this.position.initialize(this, this.$el);
    },

    postPlaceAt: function() {
        this.stopComponents();
        this.liveStream.unpause();

        this.storeSelectors();
        this.bindEvents();
        this.initializeSettings();
        this.drawer();
        this.sendSettings();
        this.updateLayout();
        this.eventCalled = false;

        this.$submenuContainer.show();
    },

    bindEvents: function() {
        this.on('magic-pen.select-color', this.actionHandlers['select-color'].bind(this));
        this.on('magic-pen.select-strength', this.updateStrength.bind(this));
        this.on('magic-pen.update-timeout-enabled', this.updateTimeoutEnabled.bind(this));
        this.on('layout-size.update', this.updateLayout.bind(this));
        this.on('livestream-size.update', this.updateLayout.bind(this));
    },

    storeSelectors: function() {
        this.$submenuContainer = this.$el.parent();
    },

    initializeSettings: function() {
        this.timeoutEnabled = true; // Is set to false when initialized.

        this.updateRGBFromDom();
        this.updateStrength();
        this.updateTimeoutEnabled();
        this.updateProperties();
    },

    /**
     * Set magic pen container size to livestream size.
     * RELEASE-3564
     */
    updateLayout: function() {
        var size = {
            width: $('#aspect-ratio-16-9').width(),
            height: $('#aspect-ratio-16-9').height()
        };

        if (!platform.checks.isCbox
            && !$('#frameboxes-container').hasClass('no-status-bar')
            && !this.frameBoxService.isFullscreenOpen()) {
            size.top = 40;
        } else {
            size.top = 0;
        }

        this.$submenuContainer.css(size);
    },

    /**
     * Update color/opacity or strength (properties).
     * The thinnest line of the magic pen becomes opaque.
     */
    updateProperties: function() {
        if (this.strength === strengthMapping.light) {
            this.opacity = opacityMapping.opaque;
        } else {
            this.opacity = opacityMapping.transparent;
        }

        this.deviceConnection
            .send('setAnnotationMagicPenProperties', {
                red: this.rgb.red,
                green: this.rgb.green,
                blue: this.rgb.blue,
                alpha: this.opacity,
                penSize: this.strength
            });
    },

    hideUi: function() {
        // Do nothing
    },

    showUi: function() {
        // Do nothing
    },

    /**
     * This method gets the currently set rgb values from the active color-selector in the DOM.
     *
     * @returns {object}
     */
    updateRGBFromDom: function() {
        var $el = this.$('.magic-pen-menu-item.color.is-active');
        var color = $el.css('background-color');
        var colorsArray;

        if (color) {
            colorsArray = color.match(/\d+/g);

            this.colorString = $el.data('color');
            this.rgb = {
                red: colorsArray[0],
                green: colorsArray[1],
                blue: colorsArray[2]
            };
        }

        this.updateProperties();
    },

    /**
     * Set strength from DOM.
     */
    updateStrength: function() {
        var $el = this.$('.magic-pen-menu-item.strength.is-active');
        this.strength = strengthMapping[$el.data('value')];

        this.updateProperties();
    },

    /**
     * Update timeout enabled/disabled (if annotation should stay or if it should disappear after defined Magic Pen timeout).
     */
    updateTimeoutEnabled: function() {
        var $el = this.$('.magic-pen-menu-pin');

        if (platform.checks.isRemote) {
            $el.hide();

            return;
        }

        this.timeoutEnabled = !this.timeoutEnabled;

        if (this.timeoutEnabled) {
            $el.addClass('is-active');
        } else {
            $el.removeClass('is-active');
        }

        $el.show();

        this.deviceConnection
            .send('setAnnotationMagicPenTimeout', { enabled: this.timeoutEnabled });
    },

    /**
     * Return annotation settings.
     *
     * @returns {object}
     */
    getSettings: function() {
        return {
            rgb: this.rgb,
            tool: this.tool,
            alpha: this.opacity,
            penSize: this.strength,
            eraserStrength: this.eraserStrength
        };
    },

    /**
     * Stop components (important for whiteboard).
     */
    stopComponents: function() {
        app.removeComponent('frameboxes');
    },

    /**
     * Start components.
     */
    startComponents: function() {
        this.app.createComponent('frameboxes', {
            type: 'Frameboxes',
            container: '#frameboxes-container'
        });
    },

    destroy: function() {
        this.$submenuContainer.hide();
        this.stopDraw();

        // RELEASE-3271: Wait until backend is ready (magic pen is inactive)
        setTimeout(function() {
            this.startComponents();
        }.bind(this), 1500);
    }
});
