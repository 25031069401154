'use strict';

var marqueeTpl = require('./marquee.hbs');
var _ = require('lodash');
var $ = require('jquery');
var LayoutManager = require('../../layout/layout');
var platform = require('./../../platform/platform');

/**
 * A part of RELEASE-1469.
 * jQuery returns a wrong width from contents that are hidden with overflow: hidden and text-overflow: ellipsis.
 * This fix will only work on IE, all other Browser uses the normal `.width()`-method.
 *
 * @param {Object} $containerElement
 * @param {Object} $el
 *
 * @return {Number}
 */
function getContentWidth($containerElement, $el) {
    var width;
    var oldTextOverflow;

    if (!platform.checks.isIe && !platform.checks.isEdge) {
        return $el.width();
    }

    oldTextOverflow = $containerElement.css('text-overflow');
    $containerElement.css('text-overflow', 'clip');
    width = $el.width();
    $containerElement.css('text-overflow', oldTextOverflow);

    return width;
}

/**
 * Creates a marquee element.
 *
 * <custom-marquee content="string"></custom-marquee>
 *
 * @type {HTMLElement}
 */

var MARQUEE_DIRECTION_RIGHT = 'right';
var MARQUEE_DIRECTION_LEFT = 'left';

var CustomMarqueeElementPrototype = Object.create(HTMLElement.prototype);

/**
 * Is called when a new element is created.
 */
CustomMarqueeElementPrototype.connectedCallback = function() {
    this.createdCallback();
};

CustomMarqueeElementPrototype.createdCallback = function() {
    var content = this.getAttribute('content');

    // Tells if the element is still active in the DOM.
    this.isActive = true;

    // Timeout variable for stopping the animation if needed.
    this.startingTimeout = null;

    // Tells if the animation has already started.
    this.isAnimationStarted = false;

    // Describes the difference between content and container, which also defines how far left the animation has to go.
    this.difference = 0;

    // Timeout before the animation should start.
    this.startOffset = 2000;
    if (this.getAttribute('startOffset')) {
        this.startOffset = parseInt(this.getAttribute('startOffset'));
    }

    this.direction = MARQUEE_DIRECTION_LEFT;
    if (this.getAttribute('direction')) {
        this.direction = this.getAttribute('direction');
    }

    // Speed of the animation.
    this.speed = 2000;
    if (this.getAttribute('speed')) {
        this.speed = parseInt(this.getAttribute('speed'));
    }

    // Tells if the animation should stop after the first execution or go on for ever.
    this.isEndless = false;
    if ('true' === this.getAttribute('isEndless')) {
        this.isEndless = true;
    }

    $(this).append(marqueeTpl({
        content: content
    }));

    this.resizeHandler = this.resizeHandler.bind(this);

    this.calculateDifferenceAndStart();
    this.bindDOMEvents();
    this.bindEvents();
};

/**
 * Is called when the element is detached from the DOM.
 */
CustomMarqueeElementPrototype.detachedCallback = function() {
    this.isActive = false;

    this.unbindEvents();
};

/**
 * Bind DOM events.
 */
CustomMarqueeElementPrototype.bindDOMEvents = function() {
    $(this).on('marquee.resize', _.throttle(this.calculateDifferenceAndStart.bind(this), 100));
    $(this).on('start', _.throttle(this.startAnimation.bind(this), 100));
    $(this).on('stop', _.throttle(this.stopAnimation.bind(this), 100));
    $(this).on('reset', _.throttle(this.resetAnimation.bind(this), 100));
};

/**
 * Bind Events.
 */
CustomMarqueeElementPrototype.bindEvents = function() {
    LayoutManager.on('window.resize', this.resizeHandler);
};

CustomMarqueeElementPrototype.unbindEvents = function() {
    LayoutManager.off('window.resize', this.resizeHandler);
};

/**
 * Reinitiates the marquee on window resize.
 */
CustomMarqueeElementPrototype.resizeHandler = function() {
    return _.debounce(function() {
        this.stopAnimation();
        this.resetAnimation();
        this.calculateDifferenceAndStart();
    }.bind(this), 100);
};

/**
 * Calculates the elements, and starts the animation if necessary to show the whole text.
 */
CustomMarqueeElementPrototype.calculateDifferenceAndStart = function() {
    var $container = $(this);
    var containerWidth = $container.width();
    var $content = $(this).find('.marquee-content');
    var contentWidth = getContentWidth($container, $content);

    // RELEASE-1469 - We remove 1px to hide all ellipsis on end of marquee.
    this.difference = containerWidth - contentWidth - 1;

    // If the content fits into the container, we don't have to animate the text.
    if (0 === contentWidth || contentWidth <= containerWidth) {
        /* We stop the animation if there was one before. This can happen when an item got removed and we have enough
           space afterwards. */
        if (this.isAnimationStarted) {
            this.stopAnimation();
            this.resetAnimation(this.speed);
        }

        return;
    }

    this.startAnimation();
};

/**
 * Starts the animation.
 */
CustomMarqueeElementPrototype.startAnimation = function() {
    if (!this.isAnimationStarted) {
        this.startingTimeout = setTimeout(function() {
            this.animateText();
        }.bind(this), this.startOffset);

        this.isAnimationStarted = true;
    }
};

/**
 * Stops the current animation.
 */
CustomMarqueeElementPrototype.stopAnimation = function() {
    var $content = $(this).find('.marquee-content');

    clearTimeout(this.startingTimeout);
    this.isAnimationStarted = false;

    $content.stop();
};

/**
 * Resets the current element to starting position.
 */
CustomMarqueeElementPrototype.resetAnimation = function(speed) {
    var $content = $(this).find('.marquee-content');
    var properties = {
        marginLeft: 0
    };
    speed = speed || 0;

    if (MARQUEE_DIRECTION_RIGHT === this.direction) {
        properties = {
            marginRight: 0
        };
    }

    $content.get(0)
        .style
        .setProperty('--custom-marquee-animation-speed', speed + 'ms');
    $content.css(properties);
};

/**
 * Animates the text to the calculated difference.
 */
CustomMarqueeElementPrototype.animateText = function() {
    var $content = $(this).find('.marquee-content');
    var properties = {
        marginLeft: this.difference
    };

    if (MARQUEE_DIRECTION_RIGHT === this.direction) {
        properties = {
            marginRight: this.difference
        };
    }

    if (this.isActive) {
        $content.get(0)
            .style
            .setProperty('--custom-marquee-animation-speed', this.speed + 'ms');
        $content.css(properties);

        if (this.isEndless) {
            this.startingTimeout = setTimeout(
                this.animateTextToStart.bind(this),
                this.speed + this.startOffset
            );
        }
    }
};

/**
 * Animates the text to the starting point.
 */
CustomMarqueeElementPrototype.animateTextToStart = function() {
    var $content = $(this).find('.marquee-content');
    var properties = {
        marginLeft: 0
    };

    if (MARQUEE_DIRECTION_RIGHT === this.direction) {
        properties = {
            marginRight: 0
        };
    }

    // Check if the element is still active in the DOM.
    if (this.isActive) {
        $content.get(0).style.setProperty('--custom-marquee-animation-speed', this.speed + 'ms');
        $content.css(properties);

        if (this.isEndless) {
            this.startingTimeout = setTimeout(
                this.animateText.bind(this),
                this.speed + this.startOffset
            );
        }
    }
};

// Register element
// Modern Browsers.
if (('customElements' in window) && ('define' in window.customElements)) {
    var CustomMarqueeElement = function() {
        return Reflect.construct(HTMLElement, [], CustomMarqueeElement);
    };

    CustomMarqueeElement.prototype = Object.assign(Object.create(HTMLElement.prototype), CustomMarqueeElementPrototype);

    window.customElements.define('custom-marquee', CustomMarqueeElement);
// Microsoft Browsers :(
} else {
    document.registerElement('custom-marquee', {
        prototype: CustomMarqueeElementPrototype
    });
}

