'use strict';

/**
 * FIXME: Multiple id on type-select.
 */

const app = require('../../../app');
const netdriveSettingsTpl = require('./netdrive-settings.hbs');
const ScrollView = require('./../../../../scroll-view');
const FormActionView = require('../form-action-view');
const _ = require('lodash');
const $ = require('jquery');
const i18n = require('i18next');

const netdriveStates = {
    notMounted: 'notMounted',
    mountFailed: 'mountFailed',
    mounted: 'mounted',
    notResponding: 'notResponding',
    disabled: 'disabled',
    busy: 'busy'
};

app.component('NetdriveSettings', {
    template: netdriveSettingsTpl,
    init: false,

    /**
     * @method initialize
     */
    initialize: function() {
        this.remote = this.getService('RemoteService');
        this.formManager = this.getService('FormManager');
        this.validate = this.getService('ValidateService');
        this.netdriveCount = 10;
        this.netdriveList = [];
        this.actionView = [];
        this.form = [];
        this.formData = [];
        this.updateDefaultDrive = false;
    },

    postPlaceAt: function() {
        this.scrollView = new ScrollView({
            $container: this.$el,
            $items: this.$el,
            entryFocusable: true
        });
        this.createSelects();
        this.initForm();
        this.parse();
        this.updateForm();
        this.initFormAction();
        this.storeSelectors();
        this.bindEvents();
        this.bindDOMEvents();
        this.updateHandler();
    },

    /**
     * Bind all DOM-Events for every Network-Drive.
     */
    bindDOMEvents: function() {
        var index;

        for (index = 0; index < this.netdriveCount; index++) {
            this.form[index].on('change.input', this.handleFormChange.bind(this, index));
        }
    },

    bindEvents: function() {
        this.on('main-loop.update', this.updateHandler.bind(this));
        this.on('main-loop.update.netdrive', this.updateHandler.bind(this));
        this.on('settings.save-changes', this.saveAllHandler.bind(this));
    },

    /**
     * Init all Forms for every network-drive.
     */
    initForm: function() {
        var index;

        for (index = 0; index < this.netdriveCount; index++) {
            this.form[index] = this.formManager.create({
                el: this.$el.find('#netdrive-item-form' + index),
                validationContainer: '.input-group'
            });
        }
    },

    /**
     * Initialize every Save/Cancel-Button for every network-drive.
     */
    initFormAction: function() {
        var index;

        for (index = 0; index < this.netdriveCount; index++) {
            if (!this.actionView[index]) {
                this.actionView[index] = new FormActionView(app, {
                    id: index,
                    selector: this.$el.find('#form-action-container' + index),
                    onSubmit: this.saveHandler.bind(this, index),
                    onCancel: this.cancelHandler.bind(this, index)
                });
                this.actionView[index].render();
            }
        }
    },

    /**
     * Store every connection state for every network-drive.
     */
    storeSelectors: function() {
        var index;
        this.$connectionState = [];

        for (index = 0; index < this.netdriveCount; index++) {
            if (index > 0) {
                this.$connectionState.push();
            }

            this.$connectionState[index] = this.$el.find('#netdrive-connection-state' + index);
        }
    },

    /**
     * Called after any field has been changed.
     */
    handleFormChange: function(index, data) {
        var fieldName = data.input.name;
        this.formData[index][fieldName] = data.value;

        // Handle some checks for some field-types.
        if ('type' === fieldName) {
            this.checkType(index);
        } else if ('readonly' === fieldName) {
            this.checkReadOnly(index);
        }

        if (!this.updateDefaultDrive) {
            this.actionView[index].open();
        }

        this.checkActionForm(index);
        this.changes = true;
    },

    /**
     * Check with type of network connection is active on given index.
     * - NFS
     * - CIFS
     * - DISABLED
     *
     * @param {Number} index
     */
    checkType: function(index) {
        var $configurable = this.$el.find('#netdrive-configurable' + index);
        var type = this.formData[index].type;

        if ('nfs' === type) {
            // Show NFS-Type.
            $configurable.removeClass('hidden');

            this.showNFSInfoText(index);
            this.hideAuthFields(index);
            this.hideDomainField(index);
        } else if ('cifs' === type) {
            // Show CIFS type.
            $configurable.removeClass('hidden');

            this.showCIFSInfoText(index);
            this.showAuthFields(index);
            this.showDomainField(index);
        } else {
            // This network drive is disabled.
            $configurable.addClass('hidden');
        }
    },

    /**
     * Show Infotext for NFS and hide Infotext for CIFS.
     *
     * @param {Number} index
     */
    showNFSInfoText: function(index) {
        this.$el.find('#url-info-message-nfs' + index).removeClass('hidden');
        this.$el.find('#url-info-message-cifs' + index).addClass('hidden');
    },

    /**
     * Show Infotext for CIFS and hide Infotext for NFS.
     *
     * @param {Number} index
     */
    showCIFSInfoText: function(index) {
        this.$el.find('#url-info-message-cifs' + index).removeClass('hidden');
        this.$el.find('#url-info-message-nfs' + index).addClass('hidden');
    },

    /**
     * Check network-drive, by given index, if is marked as readonly.
     *
     * @param {Number} index
     */
    checkReadOnly: function(index) {
        if (this.formData[index].readonly) {
            this.form[index].$el
                .find('.default-upload-switch' + index)
                .addClass('hidden');
        } else {
            this.form[index].$el
                .find('.default-upload-switch' + index)
                .removeClass('hidden');
        }
    },

    /**
     * Hide all auth-fields by given index.
     *
     * @param {Number} index
     */
    hideAuthFields: function(index) {
        var $authSection = this.$el.find('#auth-section' + index);

        $authSection.addClass('hidden');
        this.form[index]
            .get('user')
            .disable();
        this.form[index]
            .get('password')
            .disable();
    },

    /**
     * Show all auth-fields by given index.
     *
     * @param {Number} index
     */
    showAuthFields: function(index) {
        var $authSection = this.$el.find('#auth-section' + index);

        $authSection.removeClass('hidden');
        this.form[index]
            .get('user')
            .enable();
        this.form[index]
            .get('password')
            .enable();
    },

    /**
     * Hide domain-field by given index.
     *
     * @param {Number} index
     */
    hideDomainField: function(index) {
        var $domainSection = this.$el.find('#domain-section' + index);

        $domainSection.addClass('hidden');
        this.form[index]
            .get('domain')
            .disable();
    },

    /**
     * Hide domain-field by given index.
     *
     * @param {Number} index
     */
    showDomainField: function(index) {
        var $domainSection = this.$el.find('#domain-section' + index);

        $domainSection.removeClass('hidden');
        this.form[index]
            .get('domain')
            .enable();
    },

    /**
     * Checks if the name of the current netdrive is unique.
     *
     * @param {string} name
     * @param {Number} index
     *
     * @return {boolean}
     */
    validateUniqueName: function(name, index) {
        var netdriveNames = [];
        var $error = this.$el.find('#netdrive-item-form' + index).find('.duplicate-name');

        _.each(this.netdriveList, function(netdrive, i) {
            /* We want to exclude the current netdrive from the list, otherwise it's not possible to save this netdrive
               without changing its name. */
            if (i !== index) {
                netdriveNames.push(netdrive.name);
            }
        });

        if (netdriveNames.indexOf(name) !== -1) {
            $error.show();
        } else {
            $error.hide();
        }

        return netdriveNames.indexOf(name) === -1;
    },

    /**
     * @method checkActionForm
     */
    checkActionForm: function(index) {
        if (this.form[index].validate()
            && this.validateUniqueName(this.form[index].$el.find('input[name=name]').val(), parseInt(index))
        ) {
            this.actionView[index].enableSubmitButton();
        } else {
            this.actionView[index].disableSubmitButton();
        }
    },

    /**
     * Save all changed network drives
     */
    saveAllHandler: function() {
        _.each(this.actionView, function(view, index) {
            if (view.state.getState() === 'visible') {
                this.saveHandler(index);
            }
        }.bind(this));
    },

    /**
     * @method saveHandler
     */
    saveHandler: function(index) {
        var data;

        if (this.form[index].validate()
            && this.validateUniqueName(this.form[index].$el.find('input[name=name]').val(), parseInt(index))
        ) {
            data = this.form[index].serialize();
            this.deviceConnection
                .send([
                    {
                        command: 'setNetdrive',
                        data: {
                            index: index,
                            name: data.name,
                            url: data.url,
                            domain: data.domain,
                            user: data.user,
                            password: data.password,
                            type: data.type,
                            readonly: data.readonly,
                            uploadDefault: data.defaultUploadDrive
                        }
                    }
                ])
                .done(function() {
                    if (data.defaultUploadDrive) {
                        this.updateDefaultDrive = true;
                    }

                    this.updateNetdriveListName(parseInt(index), data.name);
                    this.handleSettingsSave();
                    this.form[index].setDefaultValues();
                    this.emit('overlay.remote.focus', true);
                }.bind(this));
        }
    },

    /**
     * Updates the name of the given netdrive in the netdriveList.
     *
     * @param {Number} index
     * @param {string} name
     */
    updateNetdriveListName: function(index, name) {
        this.netdriveList[index].name = name;
    },

    /**
     * Calls on clicked the cancel button
     * @method cancelHandler
     */
    cancelHandler: function(index) {
        this.emit('overlay.remote.focus', true);
        this.form[index].resetValues();
        this.changes = false;
    },

    /**
     * @method handleSettingsSave
     */
    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.changes = false;
    },

    /**
     * @method serialize
     */
    serialize: function() {
        var dfd = this.$.Deferred();

        this.load()
            .then(function() {
                dfd.resolve({
                    list: this.netdriveList
                });
            }.bind(this));

        return dfd.promise();
    },

    /**
     * @method load
     */
    load: function() {
        var commands = [];
        var index;

        for (index = 0; index < 10; index++) {
            commands.push({
                command: 'getNetdrive',
                data: {
                    index: index
                }
            });
        }

        return this.deviceConnection
            .send(commands)
            .then(function() {
                this.netdriveList = arguments;
            }.bind(this));
    },

    /**
     * @method parse form data
     */
    parse: function() {
        var index;
        this.formData = [];

        for (index = 0; index < this.netdriveList.length; index++) {
            if (index > 0) {
                this.formData.push();
            }

            this.formData[index] = {
                type: this.netdriveList[index].type,
                name: this.netdriveList[index].name,
                user: this.netdriveList[index].user,
                password: this.netdriveList[index].password,
                url: this.netdriveList[index].url,
                domain: this.netdriveList[index].domain,
                readonly: this.netdriveList[index].readonly,
                defaultUploadDrive: this.netdriveList[index].defaultUpload
            };
        }
    },

    /**
     * @method updateForm
     */
    updateForm: function() {
        var index;

        for (index = 0; index < this.netdriveList.length; index++) {
            this.checkType(index);
            this.checkReadOnly(index);

            this.form[index].setValues(this.formData[index]);
        }
    },

    /**
     * @method updateHandler
     */
    updateHandler: function() {
        this.deviceConnection
            .send('getMounts')
            .then(this.onUpdate.bind(this));
    },

    /**
     * @method onUpdate
     */
    onUpdate: function(mounts) {
        this.updateUploadDefaultDrive();
        this.updateNetdriveConnectionState(mounts);
    },

    /**
     * @method updateUploadDefaultDrive
     */
    updateUploadDefaultDrive: function() {
        if (this.updateDefaultDrive) {
            this.load().then(function() {
                // RELEASE-2226 - Network Drive: Save/discard dialog is always shown after changing to another page.
                // Store changes state and reset after updating.
                var changes = this.changes;

                this.parse();
                this.updateForm();
                this.updateDefaultDrive = false;
                this.changes = changes;
            }.bind(this));
        }
    },

    /**
     * @method updateNetdriveConnectionState
     * @param {object} data
     */
    updateNetdriveConnectionState: function(data) {
        var index;
        var mountIndex;
        var mountList = data.mountList;
        var netdriveState;

        if (mountList) {
            for (index = 0; index < this.netdriveCount; index++) {
                netdriveState = netdriveStates.disabled;

                for (mountIndex = 0; mountIndex < mountList.length; mountIndex++) {
                    if (mountList[mountIndex].type === 'netdrive' && mountList[mountIndex].id === 'netdrive' + index) {
                        netdriveState = netdriveStates[mountList[mountIndex].status];

                        this.$connectionState[index].attr('data-netdrive-connection-state', netdriveState);
                    }
                }
            }
        }
    },

    /**
     * @method creatSelects
     */
    createSelects: function() {
        var index;

        for (index = 0; index < this.netdriveCount; index++) {
            this.createComponent({
                type: 'CustomSelect',
                container: this.$el.find('#netdrive-type-select' + index),
                label: 'settings.drive_type',
                native: true,
                id: 'type-' + index,
                name: 'type',
                items: [
                    {
                        text: i18n.t('settings.none'),
                        value: 'none'
                    },
                    {
                        text: 'CIFS',
                        value: 'cifs'
                    },
                    {
                        text: 'NFS',
                        value: 'nfs'
                    }
                ]
            });
        }
    },

    /**
     * @method destroy
     */
    destroy: function() {
        var index;

        for (index = 0; index < this.netdriveCount; index++) {
            this.formManager.destroy(this.form[index]);

            if (this.actionView[index]) {
                this.actionView[index].destroy();
            }
        }
    },

    hasChanges: function() {
        var changes =  {
            hasChanges: this.changes,
            invalid: false
        };

        if (this.actionView) {
            changes.invalid = $(this.actionView.$submitBtn).prop('disabled');
        }

        return changes;
    }
});
