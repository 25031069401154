// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div id=\"session-management-meeting-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\"\n             class=\"session-management-meeting"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"current") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":46},"end":{"line":5,"column":81}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"hidden") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":81},"end":{"line":5,"column":109}}})) != null ? stack1 : "")
    + "\"\n             data-index=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\">\n            <div id=\"meeting-time\" class=\"meeting-time"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"darkUi") : depths[1]),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":54},"end":{"line":7,"column":85}}})) != null ? stack1 : "")
    + "\">\n                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"timeText") : depth0), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"crossDay") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":34},"end":{"line":8,"column":81}}})) != null ? stack1 : "")
    + "\n                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"startTime") : depth0), depth0))
    + "<span class=\"icon icon-arrow-back"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"darkUi") : depths[1]),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":67},"end":{"line":9,"column":98}}})) != null ? stack1 : "")
    + "\"></span>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"crossDay") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":107},"end":{"line":9,"column":152}}})) != null ? stack1 : "")
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"endTime") : depth0), depth0))
    + "\n            </div>\n            <div id=\"meeting-title\" class=\"meeting-title\">\n                <span class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"darkUi") : depths[1]),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":29},"end":{"line":12,"column":60}}})) != null ? stack1 : "")
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</span>\n            </div>\n            <div id=\"meeting-organizer\"\n                 class=\"meeting-organizer"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"private") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":41},"end":{"line":15,"column":75}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"darkUi") : depths[1]),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":75},"end":{"line":15,"column":106}}})) != null ? stack1 : "")
    + "\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"session_management.by",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":108},"end":{"line":15,"column":137}}}))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"organizer") : depth0), depth0))
    + "\n            </div>\n            <div class=\"session-management-btn-container "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"current") : depth0),{"name":"unless","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":57},"end":{"line":17,"column":98}}})) != null ? stack1 : "")
    + "\">\n                <div id=\"session-management-check-in-btn\" class=\"session-management-btn is-focusable"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"darkUi") : depths[1]),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":100},"end":{"line":18,"column":131}}})) != null ? stack1 : "")
    + "\" data-action=\"check-in\" data-index=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\">\n                    <span class=\"loader\"></span>\n                    <span class=\"text "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"darkUi") : depths[1]),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":38},"end":{"line":20,"column":68}}})) != null ? stack1 : "")
    + "\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"session_management.check_in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":70},"end":{"line":20,"column":105}}}))
    + "</span>\n                </div>\n                <div id=\"session-management-ad-hoc-btn\" class=\"session-management-btn transparent hidden is-focusable"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"darkUi") : depths[1]),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":117},"end":{"line":22,"column":148}}})) != null ? stack1 : "")
    + "\" data-action=\"ad-hoc\">\n                    <span class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"darkUi") : depths[1]),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":33},"end":{"line":23,"column":63}}})) != null ? stack1 : "")
    + "\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"session_management.start_ad_hoc",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":65},"end":{"line":23,"column":104}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " current";
},"4":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"6":function(container,depth0,helpers,partials,data) {
    return " darkUi";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"startDate") : depth0), depth0))
    + " ";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"endDate") : depth0), depth0))
    + " ";
},"12":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"14":function(container,depth0,helpers,partials,data) {
    return "darkUi";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"meeting-list-container\"\n     class=\"meeting-list-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"meetings") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":27,"column":13}}})) != null ? stack1 : "")
    + "    <div id=\"session-management-meeting-none\" class=\"session-management-meeting current hidden\">\n        <div id=\"meeting-time\" class=\"meeting-time"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"darkUi") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":50},"end":{"line":29,"column":78}}})) != null ? stack1 : "")
    + "\"></div>\n        <div id=\"meeting-title\" class=\"meeting-title"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"darkUi") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":52},"end":{"line":30,"column":80}}})) != null ? stack1 : "")
    + "\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"session_management.no_meetings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":82},"end":{"line":30,"column":120}}}))
    + "</div>\n        <div class=\"session-management-btn-container\">\n            <div id=\"session-management-ad-hoc-btn\" class=\"session-management-btn transparent hidden is-focusable"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"darkUi") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":113},"end":{"line":32,"column":141}}})) != null ? stack1 : "")
    + "\" data-action=\"ad-hoc\">\n                <span class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"darkUi") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":29},"end":{"line":33,"column":56}}})) != null ? stack1 : "")
    + "\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"session_management.start_ad_hoc",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":58},"end":{"line":33,"column":97}}}))
    + "</span>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true,"useDepths":true});
