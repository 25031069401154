'use strict';

var _ = require('lodash');
var app = require('../app');
var connect = require('../../connect');

var Socket = connect.Socket;
var RestSocket = connect.RestSocket;
var WolfProtHelper = connect.WolfProtHelper;

var defaultCommandsParser = ['WPC_PICTUREHEADER', 'WPC_PICTUREBLOCK2'];

/**
 * Connection class
 * @class Connection
 * @param {Object} options
 */
function Connection(options) {
    /**
     * @type {object}
     */
    this.options = _.defaults({}, {
        onMessage: function(event) {
            let evtData = this.wolfProtHelper.parseCommand(event.data);
            let commandName = this.wolfProtHelper.getCommandName(evtData.cmd);

            if (-1 !== defaultCommandsParser.indexOf(commandName)) {
                this.wolfProtHelper.executeServerCommand(evtData.cmd);
            } else {
                this.wolfProtHelper.onDataReady(evtData.data);
            }
        }.bind(this),
        onError: function() {
            if (options.onError) {
                options.onError();
            }
            console.log('Connection Error!');
        },
        onClose: function() {
            if (options.onClose) {
                options.onClose();
            }
            this.wolfProtHelper.resetCallbackStore();
        }.bind(this)
    }, options);

    /**
     * Reference to the websocket
     * @type {object}
     */
    if (this.options.host.indexOf('ws') === -1) {
        this.connection = new RestSocket(this.options.host, this.options.sessionId, {
            onOpen: this.options.onOpen,
            onError: this.options.onError,
            onClose: this.options.onClose,
            onMessage: this.options.onMessage,
            onTimeout: this.options.onTimeout
        });
    } else {
        this.connection = new Socket(this.options.host, this.options.sessionId, {
            binaryType: 'arraybuffer',
            onOpen: this.options.onOpen,
            onError: this.options.onError,
            onClose: this.options.onClose,
            onMessage: this.options.onMessage
        });
    }

    /**
     * Reference to the WolfProtHelper instance
     * @type {Object}
     */
    this.wolfProtHelper = new WolfProtHelper(this.connection);

    this.sessionId = this.options.sessionId;
}

/**
 * Start the websocket connection
 * @method connect
 */
Connection.prototype.connect = function connect() {
    return this.connection.connect();
};

/**
 * Start the websocket connection
 * @method connect
 */
Connection.prototype.reconnect = function connect() {
    return this.connection.reconnect();
};

/**
 * Stops the websocket connection
 * @method connect
 */
Connection.prototype.close = function connect() {
    this.destroyConnection();

    return this.connection.close();
};

/**
 * Send a command
 * @method send
 * @param {String | Array} command
 * @param {Mixed} data
 */
Connection.prototype.send = function send(command, data) {
    var dfd;

    if (_.isArray(command)) {
        return this.waitForCommands(command);
    }

    dfd = app.$.Deferred();

    this.wolfProtHelper.executeClientCommand(command, data)
        .waitFor(dfd.resolve, dfd.reject);

    return dfd.promise();
};

/**
 * Wait for multiple commands to respond
 * @method waitForCommands
 * @param {Array} commands
 */
Connection.prototype.waitForCommands = function waitForCommands(commands) {
    var promises = [];
    var cmd;

    commands.forEach(function(command) {
        if (typeof command !== 'string') {
            cmd = this.send(command.command, command.data);
        } else {
            cmd = this.send(command);
        }

        promises.push(cmd);
    }.bind(this));

    return app.$.when.apply(app.$, promises);
};

/**
 * Informs the Service that the connection can be removed.
 *
 * @method destroyConnection
 */
Connection.prototype.destroyConnection = function destroyConnection() {
    if (this.options.name) {
        app.getService('ConnectionFactoryService').remove(this.options.name);
    }
};

app.service('ConnectionFactoryService', function() {
    var connections = {};
    var afterCreatedCallbacks = {};

    return {
        /**
         * Create a new connection instance
         * @method create
         * @param {String} connectionName
         * @param {object} options
         */
        create: function(connectionName, options) {
            var connection = connections[connectionName] = new Connection(
                _.assign(
                    options,
                    {
                        name: connectionName
                    }
                )
            );

            this.runAfterCreatedCallbacks(connectionName);

            return connection;
        },

        /**
         * Return a reference to a connection instance
         * @method get
         * @param {String} connectionName
         */
        get: function(connectionName) {
            return connections[connectionName] || null;
        },

        /**
         * Return a reference to a connection after it was created
         * @method afterCreated
         * @param {String} connectionName
         * @param {Function} callback
         */
        afterCreated: function(connectionName, callback) {
            if (connections[connectionName]) {
                callback(connections[connectionName]);
            } else if (!afterCreatedCallbacks[connectionName]) {
                afterCreatedCallbacks[connectionName] = [callback];
            } else {
                afterCreatedCallbacks[connectionName].push(callback);
            }
        },

        /**
         * @method runAfterCreatedCallbacks
         * @param {String} connectionName
         */
        runAfterCreatedCallbacks: function(connectionName) {
            if (afterCreatedCallbacks[connectionName]) {
                afterCreatedCallbacks[connectionName].forEach(function(callback) {
                    callback(connections[connectionName]);
                });

                delete afterCreatedCallbacks[connectionName];
            }
        },

        /**
         * Cleans up entries for the given connectionName.
         *
         * @param {String} connectionName
         */
        remove: function(connectionName) {
            delete afterCreatedCallbacks[connectionName];
            delete connections[connectionName];
        }
    };
});
