'use strict';

require('./wlan-setting/wlan-settings');
require('./wlan-list/wlan-list');
require('./wlan-access-point/wlan-access-point');
require('./wlan-access-point/wlan-access-point-pure');

const _ = require('lodash');
const $ = require('jquery');
const app = require('../../../app');
const template = require('./wlan.hbs');
const FormActionView = require('./../form-action-view');
const states = require('./../../../states');
const i18n = require('i18next');

app.component('Wlan', {
    template: template,

    getAccessKey: function() {
        return {
            'roleName': 'WlanSettings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.storage = app.getService('DataStorage');
        this.deviceService = app.getService('DeviceService');
        this.frontendSettings = this.getService('FrontendSettings');
        this.changes = false;
        this.band = '';
        this.country = '';
    },

    serialize: function() {
        var dfd = $.Deferred();

        this.loadWlanSettings(dfd);

        return dfd.promise();
    },

    postPlaceAt: function() {
        this.createSelects();
        this.initForm();
        this.initFormAction();
        this.storeSelectors();
        this.createAccessPointSettings();
        this.createOthersSettings();
        this.createPingComponent();
        this.setFormData(true);
        this.updateHandler();
        this.bindEvents();
        this.bindDOMEvents();
    },

    storeSelectors: function() {
        this.$accessPoint = this.$el.find('#access-point-settings-container');
        this.$wlanListBtn = this.$el.find('#open-wlan-list-item');
        this.$wlanSettings = this.$el.find('#wlan-settings');
        this.$connectionStateContainer = this.$el.find('#wlan-connection-state-container');
        this.$connectionState = this.$el.find('#wlan-connection-state');
        this.$wlanCountry = this.$el.find('#country-select');
        this.$wlanBand = this.$el.find('#band-select');
        this.$wlanRouting = this.$el.find('#wlan-routing');
        this.$ping = this.$el.find('#wlan-ping');
    },

    bindDOMEvents: function() {
        this.$el.one('click', '.wlan-open-component:not(.is-disabled)', this.openComponentHandler.bind(this));
    },

    bindEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.on('main-loop.update', this.updateHandler.bind(this));
        this.on('main-loop.update.wlan', this.updateHandler.bind(this));
        this.on('wlan.save', this.saveWlanHandler.bind(this));
        this.on('wlan.cancel', this.cancelWlanHandler.bind(this));
        this.on('wlan.changed.usebssid', this.enableBand.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
        this.on('wlan-settings.changed', this.updateChanges.bind(this));
    },

    /**
     * Enable wlan Band
     * @param data
     */
    enableBand: function(data) {
        var infra = this.form.get('wlan-mode').getValue() === 'infrastructure';

        if (!infra ||  (infra && data.useBssid)) {
            this.$wlanBand
                .stop()
                .slideUp()
                .addClass('hidden');
        } else {
            this.$wlanBand
                .removeClass('hidden')
                .stop()
                .slideDown();
        }
    },

    updateHandler: function() {
        this.refreshMacAddress();
        this.deviceConnection
            .send('getWlanStatus')
            .then(this.updateWlanConnectionState.bind(this));
    },

    /**
     * Update wlan connection state
     *
     * @param {object} data
     */
    updateWlanConnectionState: function(data) {
        var state;
        var mode = this.form.get('wlan-mode').getValue();

        if (mode === this.wlanMode) {
            if (mode === 'off') {
                state = mode;
            } else if (mode === 'access-point') {
                state = data.wlanStatus === 'connected' ? 'running' : 'not-running';
            } else if (mode === 'infrastructure') {
                state  = data.wlanStatus;
            } else {
                state = 'off';
            }
        } else if (mode === 'off') {
            state = 'off';
        } else if (mode === 'access-point') {
            state = 'not-running';
        } else {
            state = 'disconnected';
        }

        this.$connectionState.attr('data-wlan-connection-state', state);
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container-wlan'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#wlan'),
            validationContainer: '.btn-switch-container'
        });

        this.form.get('mac').disable();
    },

    handleFormChange: function(data) {
        var country = this.form.get('country').getValue();
        var band = this.form.get('band').getValue();
        var mode = this.form.get('wlan-mode').getValue();
        var routing = false;

        if (this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) {
            routing = this.form.get('routing').getValue();
        }

        if (country !== this.country || mode !== this.wlanMode || band !== this.band || routing !== this.routing) {
            this.changes = true;

            this.band = band;
            this.country = country;
            this.routing = routing;
        }

        if (data.input.name === 'wlan-mode') {
            this.handleWlanMode(true);
        } else if (data.input.name === 'country') {
            this.emit('wlan.change.country', {
                country: country,
                mode: mode
            });
        } else if (data.input.name === 'band') {
            this.emit('wlan-settings.changed.band', {
                band: band,
                mode: mode
            });
        } else if (data.input.name === 'routing') {
            this.emit('wlan-access-point.changed.routing', {
                routing: routing
            });

            if (routing) {
                app.emit('modal.open', {
                    id: 'warning',
                    messageKey: 'settings.verify_routing_1',
                    successTextKey: 'settings.accept',
                    discareTextKey: 'settings.cancel',
                    onConfirm: function() {
                        app.emit('modal.open', {
                            id: 'login',
                            user: 'admin',
                            navPrevention: true,
                            preventClose: true,
                            isWarning: true,
                            cancelLoginHandler: this.onCancelVerify.bind(this)
                        });
                    }.bind(this),
                    onDiscare: this.onCancelVerify.bind(this)
                });
            }
        }
    },

    /**
     * Reset routing if login was cancelled.
     *
     */
    onCancelVerify: function() {
        this.form.get('routing').setValue(false);
        this.emit('modal.close');
    },

    /**
     * Refresh wlan settings.
     */
    refreshWlanSettings: function() {
        var dfd = $.Deferred();

        var mode = this.form.get('wlan-mode').getValue();

        if (mode === 'off') {
            this.form.setDefaultValues();
        } else {
            this.loadWlanSettings(dfd);
            this.setFormData.bind(this);
        }

        this.emit('overlay.remote.focus', true);

        return dfd.promise();
    },

    /**
     * Load wlan settings.
     *
     * @returns {*}
     */
    loadWlanSettings: function(dfd) {
        this.deviceConnection
            .send([
                'getAdjustmentWlanRegion',
                'getWlanCountry',
                'getInfraWlanFrequencyBand',
                'getInfraWlanSsidType',
                'getWlanMode',
                'getRoutingMode'
            ])
            .then(function(region, country, band, ssidType, wlan, routing) {
                this.formData = {
                    country: country.country,
                    band: band.band,
                    mode: wlan.wlanMode,
                    mac: '',
                    routing: routing.enabled
                };
                this.wlanMode = wlan.wlanMode;
                this.band = band.band;
                this.country = country.country;
                this.showBand = ssidType.type === 'essid';
                this.region = region.region;

                dfd.resolve({
                    isCboxPureOrPro: this.deviceService.isCboxPureOrPro(),
                    isCboxPureMini: this.deviceService.isCboxPureMini()
                });
            }.bind(this));
    },

    /**
     * Refresh Mac address.
     * Depending on WLAN mode Mac address changes.
     */
    refreshMacAddress: function() {
        return this.deviceConnection
            .send('getWlanMacAddress')
            .then(function(wlanMac) {
                this.form.get('mac').setValue(wlanMac.macAddress);
            }.bind(this));
    },

    setFormData: function(init) {
        var band = this.storage.get('wlan.band');

        this.form.get('country').setValue(this.formData.country);
        this.form.get('band').setValue(band  !== undefined ? band : this.formData.band);
        this.form.get('mac').setValue(this.formData.mac);

        if ((this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) && this.formData.mode === 'access-point') {
            this.form.get('routing').setValue(this.formData.routing);
        }

        if (!this.options.configs.historyConfig) {
            this.form.get('wlan-mode').setValue(this.formData.mode);
            this.emit('wlan-mode.changed.' + this.formData.mode, false);
        } else {
            this.options.configs.historyConfig = null;
            this.form.get('wlan-mode').setValue('infrastructure');
        }

        this.updateCountrySelect(this.region);
        this.handleWlanMode(false, init);

        this.storage.remove('wlan.band');
    },

    /**
     * Handle wlan mode.
     *
     * @param  {boolean}       init
     * @param  {boolean}       animate
     */
    handleWlanMode: function(animate, init) {
        var mode = this.form.get('wlan-mode').getValue();

        switch (mode) {
            case 'off':
                this.closeAccessPoint(animate);
                this.closeInfrastructure(animate);
                this.disableWlanList();
                this.$connectionStateContainer.hide();
                this.$wlanCountry.hide();
                this.$wlanBand.hide();

                if (this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) {
                    this.$wlanRouting.hide();
                }

                if (!init && (typeof this.changes === 'boolean' ? this.changes : this.changes.hasChanges)) {
                    this.actionView.open();
                }

                this.$ping.hide();

                break;
            case 'infrastructure':
                this.closeAccessPoint(animate);
                this.openInfrastructure(animate);
                this.enableWlanList();
                this.$connectionStateContainer.show();
                this.actionView.close();
                this.emit('wlan-settings.action.view.open');
                this.$wlanCountry.show();

                if (this.showBand) {
                    this.$wlanBand.show();
                } else {
                    this.$wlanBand.hide();
                }

                if (this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) {
                    this.$wlanRouting.hide();
                }

                this.$ping.show();

                break;
            case 'access-point':
                this.openAccessPoint(animate);
                this.closeInfrastructure(animate);
                this.disableWlanList();
                this.$connectionStateContainer.show();
                this.actionView.close();
                this.emit('wlan-access-point.action.view.open');

                this.$wlanCountry.show();
                this.$wlanBand.hide();

                if (this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) {
                    this.$wlanRouting.show();
                }

                this.$ping.show();

                break;
        }

        this.updateHandler();
    },

    /**
     * Open Access Point module.
     *
     * @param {Boolean} animate
     */
    openAccessPoint: function(animate) {
        if (this.$accessPoint.hasClass('is-disabled')) {
            if (animate) {
                this.$accessPoint
                    .velocity('slideDown', 250);
            } else {
                this.$accessPoint.show();
            }

            this.$accessPoint.removeClass('is-disabled');

            setTimeout(function() {
                this.emit('wlan-mode.changed.access-point');
            }.bind(this), 100);
        }
    },

    /**
     * Close AP Module.
     *
     * @param {Boolean} animate
     */
    closeAccessPoint: function(animate) {
        if (!this.$accessPoint.hasClass('is-disabled')) {
            if (animate) {
                this.$accessPoint
                    .velocity('slideUp', 250);
            } else {
                this.$accessPoint.hide();
            }

            this.$accessPoint.addClass('is-disabled');
        }
    },

    /**
     * Open Infrastructure module.
     *
     * @param {Boolean} animate
     */
    openInfrastructure: function(animate) {
        if (this.$wlanSettings.hasClass('is-disabled')) {
            if (animate) {
                this.$wlanSettings
                    .velocity('slideDown', 250);
            } else {
                this.$wlanSettings.show();
            }

            this.$wlanSettings.removeClass('is-disabled');

            setTimeout(function() {
                this.emit('wlan-mode.changed.infrastructure', false);
            }.bind(this), 100);
        }
    },

    /**
     * Close Infrastructure Module.
     *
     * @param {Boolean} animate
     */
    closeInfrastructure: function(animate) {
        if (!this.$wlanSettings.hasClass('is-disabled')) {
            if (animate) {
                this.$wlanSettings
                    .velocity('slideUp', 250);
            } else {
                this.$wlanSettings.hide();
            }

            this.$wlanSettings.addClass('is-disabled');
        }
    },

    disableWlanList: function() {
        this.$wlanListBtn.addClass('is-disabled');
    },

    enableWlanList: function() {
        this.$wlanListBtn.removeClass('is-disabled');
    },

    /**
     * Called from this actionView if mode is 'off'
     *
     */
    saveHandler: function() {
        var mode = this.form.get('wlan-mode').getValue();

        this.setWlanMode(mode);

        this.changes = false;
    },

    /**
     * Called from this actionView if mode is 'off'
     */
    cancelHandler: function() {
        this.cancelWlanHandler();

        this.changes = false;
    },

    /**
     * Called from components wlan-settings (infrastructure mode) and wlan-access-point
     */
    saveWlanHandler: function() {
        if (this.form.validate()) {
            var country = {
                country: this.form.get('country').getValue()
            };

            var band = {
                band: this.form.get('band').getValue()
            };

            this.deviceConnection
                .send([
                    {
                        command: 'setWlanCountry',
                        data: country
                    },
                    {
                        command: 'setInfraWlanFrequencyBand',
                        data: band
                    }
                ])
                .then(this.handleWlanModeSave.bind(this));
            this.$wlanListBtn.addClass('has-content');

            this.form.setDefaultValues();
            this.emit('overlay.remote.focus', true);
        }
    },

    /**
     * Called from components wlan-settings (infrastructure mode) and wlan-access-point.
     * Calls on clicked the cancel button
     */
    cancelWlanHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
        this.$wlanListBtn.removeClass('has-content');
        this.actionView.close();

        this.emit('wlan-mode.changed.infrastructure', true);
    },

    /**
     * Handle Wlan Mode Save.
     */
    handleWlanModeSave: function() {
        var country = this.form.get('country').getValue();
        var band = this.form.get('band').getValue();
        var mode = this.form.get('wlan-mode').getValue();

        this.emit('wlan-access-point.changed.country', {
            country: country,
            mode: mode
        });

        this.emit('wlan-settings.changed.band', {
            band: band,
            mode: mode
        });

        this.setWlanMode(mode);
        this.$wlanListBtn.removeClass('has-content');
    },

    /**
     * Set Wlan Mode.
     *
     * @param mode off, infrastructure, access-point
     */
    setWlanMode: function(mode) {
        let cmds = [
            {
                command: 'setWlanMode',
                data: {
                    wlanMode: mode
                }
            }
        ];

        if (this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) {
            cmds.push({
                command: 'setRoutingMode',
                data: {
                    enabled: this.form.get('routing').getValue()
                }
            });
        }

        this.deviceConnection
            .send(cmds)
            .then(function() {
                this.waitForWlanModeChanged(mode, 50).then(function() {
                    this.emit('overlay.header.update', {
                        actionButtonKey: 'settings.action_button_saved',
                        actionBtnType: null,
                        actionBtnDelay: 2000,
                        actionBtnFadeout: 500
                    });

                    this.refreshWlanSettings();
                }.bind(this), function() {
                    this.emit('overlay.header.update', {
                        actionButtonKey: 'settings.action_button_failed',
                        actionBtnType: null,
                        actionBtnDelay: 2000,
                        actionBtnFadeout: 500
                    });

                    this.refreshWlanSettings();
                }.bind(this));
            }.bind(this));

        if (mode === 'off') {
            this.deviceConnection
                .send('setWlanSettingsActivate');
        }
    },

    /**
     * Wait for wlan mode changes.
     *
     * @param wlanMode: the new wlan mode
     * @param timeout: the maximum time to wait for wlan mode to be changed
     * @param dfd: deferred or undefined
     */
    waitForWlanModeChanged: function(wlanMode, timeout, dfd) {
        if (!dfd) {
            dfd = this.app.$.Deferred();
        }

        this.deviceConnection.send('getWlanMode')
            .then(function(data) {
                this.wlanMode = data.wlanMode;
                if (data.wlanMode === wlanMode) {
                    dfd.resolve();

                    return;
                } else if (timeout-- <= 0) {
                    dfd.reject();

                    return;
                }

                setTimeout(function() {
                    this.waitForWlanModeChanged(wlanMode, timeout, dfd);
                }.bind(this), 100);
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Open component handler.
     *
     * @param  {object} event
     */
    openComponentHandler: function(event) {
        var $el = this.$(event.currentTarget);
        var name = $el.data('name');

        this.storage.set('wlan.band', this.form.get('band').getValue());
        this.emit('wlan-settings.store.settings');
        this.select(name);
    },

    select: function(itemName) {
        var handler = this.selectionHandlers[itemName];

        if (handler) {
            handler.call(this);
        }
    },

    selectionHandlers: {
        'wlan-list': function() {
            this.changes = false;

            this.emit('overlay.open', {
                id: 'wlan-list',
                band: this.$wlanBand.hasClass('hidden') ? 'both' : this.form.get('band').getValue()
            });
        }
    },

    /**
     * Create Access Point Settings.
     */
    createAccessPointSettings: function() {
        if (this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) {
            this.createComponent({
                type: 'WlanAccessPointPure',
                container: this.$accessPoint
            });

            return;
        }

        this.createComponent({
            type: 'WlanAccessPoint',
            container: this.$accessPoint
        });
    },

    createSelects: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#country-select'),
            label: 'settings.country',
            native: true,
            name: 'country',
            items: []
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#wlan-mode-select'),
            label: 'settings.wlan_mode',
            native: true,
            name: 'wlan-mode',
            items: app.getService('Model-View').getWlanModes()

        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#band-select'),
            label: 'settings.band',
            native: true,
            name: 'band',
            items: [
                {
                    text: 'settings.both',
                    value: 'both'
                },
                {
                    text: 'settings.24ghz',
                    value: '24ghz'
                },
                {
                    text: 'settings.5ghz',
                    value: '5ghz'
                }
            ]

        });
    },

    createPingComponent: function() {
        this.createComponent({
            type: 'Ping',
            container: this.$el.find('#wlan-ping-container'),
            configs: {
                interface: 'wlan'
            }
        });
    },

    /**
     * Create other wlan settings.
     */
    createOthersSettings: function() {
        this.createComponent({
            type: 'WlanSettings',
            container: this.$wlanSettings,
            ssid: (this.options.configs.historyConfig) ? this.options.configs.historyConfig.ssid : false,
            bssid: (this.options.configs.historyConfig) ? this.options.configs.historyConfig.bssid : false,
            encryption: (this.options.configs.historyConfig) ? this.options.configs.historyConfig.encryption : false
        });

        if (this.options.configs.historyConfig) {
            this.openInfrastructure(true);
        }
    },

    destroy: function() {
        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    updateChanges: function(changes) {
        this.changes = changes;
        this.handleWlanMode();
    },

    hasChanges: function() {
        return {
            hasChanges: typeof this.changes === 'boolean' ? this.changes : this.changes.hasChanges,
            invalid: this.changes.invalid ? this.changes.invalid : $(this.actionView.$submitBtn).prop('disabled')
        };
    },

    /**
     * Update country select based on region.
     *
     * @param {bool} region 1 or 2
     */
    updateCountrySelect: function(region) {
        let countryItems = [];
        const tempItems = region === 1 ? states.countriesRegion1 : states.countriesRegion2;

        _.each(tempItems, function(item) {
            countryItems.push({
                text: i18n.t('countries.' + item.value),
                value: item.value
            });
        });

        this.emit('select.country.update', {
            items: countryItems,
            selected: this.country
        });
    }
});
