'use strict';

var $ = require('jquery');
var app = require('../../../app');
var generalSettingsTpl = require('./wallpaper-settings.hbs');
var FormActionView = require('../form-action-view');
var wallpapers = require('./wallpapers');
var platform = require('../../../../platform/platform');

app.component('Wallpaper', {
    template: generalSettingsTpl,
    actionView: null,
    fileToSaveArrayBuffer: null,
    fileToSaveType: 'default',
    fileToSaveName: '',
    initWallpaperType: 'default',
    activeWallpaper: 'default',
    isResetBackgroundEnabled: false,

    getAccessKey: function() {
        return {
            'roleName': 'GeneralSettings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.frontendSettings = app.getService('FrontendSettings');
        this.wallpapers = this.resolveWallpapers(wallpapers);
        this.remote = this.getService('RemoteService');
    },

    postPlaceAt: function() {
        this.load().then(this.setRemoteFocus.bind(this));
        this.storeSelectors();
        this.initForm();
        this.initFormAction();
        this.bindEvents();
        this.bindDOMEvents();
    },

    storeSelectors: function() {
        this.$wallpaperInfo = this.$el.find('#wallpaper-upload-info');
        this.$activeWallpaperInfo = this.$el.find('#wallpaper-active-wallpaper');
        this.$resetbtnContainer = this.$el.find('#wallpaper-rest-container');
    },

    serialize: function() {
        return {
            wallpapers: this.wallpapers,
            isCbox: platform.checks.isCbox
        };
    },

    bindEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.reset-background', this.resetBackground.bind(this));
        this.$el.on('click', '.wallpaper-action', this.uploadWallpaperFromList.bind(this));
    },

    /**
     * Formular has been changed -> show action button.
     */
    handleFormChange: function() {
        this.openActionForm();
        this.changes = true;
        this.isResetBackgroundEnabled = false;

        this.uploadCustomWallpaper();
    },

    /**
     * Add preview and path to the wallpaper objects.
     *
     * @param {Object[]} wallpapers
     *
     * @return {Object[]}
     */
    resolveWallpapers: function(wallpapers) {
        var dir = '/images/wallpapers/';

        return wallpapers.map(function(wallpaper) {
            wallpaper.path = dir + wallpaper.filename;
            wallpaper.previewPath = dir + wallpaper.filename;

            return wallpaper;
        });
    },

    /**
     * Load active wallpaper.
     */
    load: function() {
        return this.frontendSettings.getSetting()
            .then(function() {
                this.initWallpaperType = this.frontendSettings.findSetting('wallpaperType');
                this.activeWallpaper =  this.frontendSettings.findSetting('wallpaperName');

                this.setActiveWallpaper();
                this.checkActiveWallpaperMessage();
                this.checkUploadWarning();
                this.checkResetButton();

                this.emit('save-changes.finished');
            }.bind(this));
    },

    /**
     * Set custom remote control focus on chosen wallpaper.
     */
    setRemoteFocus: function() {
        let el = this.$el.find('[data-file-name="' + this.activeWallpaper + '"]');
        if (el.length === 0) {
            // If no wallpaper is selected focus first wallpaper
            el = this.$el.find('.wallpaper-preview-item')[0];
        }

        this.remote.focus(el);
    },

    /**
     * Will hide the "Reset background" Button when settings already on default.
     */
    checkResetButton: function() {
        if ('default' === this.initWallpaperType) {
            this.$resetbtnContainer
                .removeClass('is-hidden')
                .slideUp();
        } else {
            this.$resetbtnContainer
                .slideDown();
        }
    },

    /**
     * Set the active-class-state to the active wallpaper.
     */
    setActiveWallpaper: function() {
        var wallpaperName = this.activeWallpaper;
        // Override active-name when a wallpaper has been chosen.
        if (this.fileToSaveName) {
            wallpaperName = this.fileToSaveName;
        }

        // Remove old is-active class.
        this.$el.find('[data-file-name]').removeClass('is-active');

        if ('default' === this.activeWallpaper) {
            return;
        }

        var $activeEl = this.$el.find('[data-file-name="' + wallpaperName + '"]');

        if (0 === $activeEl.length) {
            return;
        }

        $activeEl.addClass('is-active');
    },

    /**
     * Shows a warning when a custom wallpaper is active.
     */
    checkUploadWarning: function() {
        if ('custom' === this.initWallpaperType && true === this.changes) {
            this.$wallpaperInfo
                .removeClass('is-hidden')
                .slideDown();
        } else {
            this.$wallpaperInfo
                .slideUp();
        }
    },

    /**
     * Shows a message when a custom wallpaper is active.
     */
    checkActiveWallpaperMessage: function() {
        if ('custom' === this.initWallpaperType) {
            this.$activeWallpaperInfo
                .removeClass('is-hidden')
                .slideDown();
        } else {
            this.$activeWallpaperInfo
                .slideUp();
        }

        this.$el.find('#wallpaper-active-wallpaper-filename').text(this.activeWallpaper);
    },

    /**
     * Initialize Form to handle uploads.
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#wallpaper-settings'),
            validationContainer: '.input-group'
        });
    },

    /**
     * Initialize action button.
     */
    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    /**
     * Upload custom image.
     */
    saveHandler: function() {
        if (this.isResetBackgroundEnabled) {
            this.handleResetBackgroundSave();
        } else {
            this.uploadWallpaper();
        }
    },

    /**
     * Reset changes
     */
    cancelHandler: function() {
        this.changes = false;

        if (!platform.checks.isCbox) {
            this.form.get('wallpaper-file').setValue('');
        }
        this.load();
    },

    /**
     * Upload a Wallpaper that has been choosed from the list.
     */
    uploadWallpaperFromList: function(event) {
        var $el = $(event.currentTarget);
        var path = $el.data('file-path');
        var filename = $el.data('file-name');

        // Load image as blob.
        var oReq = new XMLHttpRequest();
        oReq.open('GET', path, true);
        oReq.responseType = 'blob';

        oReq.onload = function() {
            var blob = oReq.response;
            var fileReader = new FileReader();

            fileReader.onload = function(event) {
                var arrayBuffer = event.currentTarget.result;

                this.setFile(arrayBuffer, filename, 'template');

                this.openActionForm();
                this.setActiveWallpaper();
                this.checkUploadWarning();
                this.isResetBackgroundEnabled = false;
            }.bind(this);
            fileReader.readAsArrayBuffer(blob);
        }.bind(this);

        oReq.onerror = function(error) {
            this.getService('ExceptionsManager').throw(error);
        };

        oReq.send();
    },

    /**
     * Upload a custom Wallpaper get the File from the background-file field.
     */
    uploadCustomWallpaper: function() {
        /** @var {File} file */
        var file = this.form.get('wallpaper-file').getValue();

        var fileReader = new FileReader();

        fileReader.onload = function(event) {
            var arrayBuffer = event.currentTarget.result;

            this.setFile(arrayBuffer, file.name, 'custom');
            this.setActiveWallpaper();
            this.checkUploadWarning();
        }.bind(this);

        fileReader.readAsArrayBuffer(file);
    },

    /**
     * Upload wallpaper by given file.
     *
     * @param {File} file
     */
    uploadWallpaper: function() {
        if (null === this.fileToSaveArrayBuffer) {
            this.emit('save-changes.finished');

            return;
        }

        this.frontendSettings.updateSetting({
            tag: 'wallpaperName',
            value: this.fileToSaveName
        });

        this.frontendSettings.updateSetting({
            tag: 'wallpaperType',
            value: this.fileToSaveType
        });
        this.frontendSettings.saveSettings();

        this.deviceConnection
            .send('setBackgroundImageFile', {
                name: this.fileToSaveName,
                file: this.fileToSaveArrayBuffer
            });

        this.changes = false;

        this.load();
    },

    /**
     * Enable reset background when the save-button has been pressed.
     */
    resetBackground: function() {
        this.isResetBackgroundEnabled = true;
        this.changes = true;
        this.activeWallpaper = 'default';
        this.checkUploadWarning();
        this.setActiveWallpaper();
        this.openActionForm();
    },

    openActionForm: function() {
        this.actionView.open();
        this.checkActionForm();
    },

    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * Reset wallpaper to the default picture.
     */
    handleResetBackgroundSave: function() {
        this.deviceConnection
            .send('setBackgroundImageFile', {
                file: null
            });

        // Set wallpaper to default.
        this.frontendSettings.updateSetting({
            tag: 'wallpaperName',
            value: 'default'
        });
        this.frontendSettings.updateSetting({
            tag: 'wallpaperType',
            value: 'default'
        });
        this.frontendSettings.saveSettings();
        this.initWallpaperType = 'default';

        this.setActiveWallpaper();
        this.checkUploadWarning();
        this.changes = false;
        setTimeout(this.load.bind(this), 150); // Wait until frontend-settings has been saved.
    },

    hasChanges: function() {
        var changes =  {
            hasChanges: this.changes,
            invalid: false,
            waitOnSave: true
        };

        if (this.actionView) {
            changes.invalid = $(this.actionView.$submitBtn).prop('disabled');
        }

        return changes;
    },

    /**
     * @param {ArrayBuffer} arrayBuffer
     * @param {string} filename
     * @param {String} wallpaperType
     */
    setFile: function(arrayBuffer, filename, wallpaperType) {
        if (!arrayBuffer) {
            if (this.fileToSaveType === 'custom') {
                this.actionView.disableSubmitButton();
            }

            return;
        }

        this.fileToSaveArrayBuffer = arrayBuffer;
        this.activeWallpaper = filename;
        this.fileToSaveName = filename;
        this.fileToSaveType = wallpaperType;
        this.changes = true;
    },

    destroy: function() {
        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }
    }
});
