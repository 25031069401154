'use strict';

var $ = require('jquery');
var app = require('../app');
var StateMachine = require('./../state-machine');
var platform = require('../../platform/platform');

/**
 * Screensaver active states
 * @type {{none: string, off: string, screensaver: string, screenOff: string, standby: string}}
 */
const states = {
    none: 'none',
    off: 'off',
    screensaver: 'screensaver',
    screenOff: 'screen-off',
    standby: 'standby'
};

app.service('ScreensaverService', function(app) {
    return {

        /**
         * Initialize service.
         */
        initialize: function() {
            this.authenticationService = app.getService('AuthenticationService');
            this.bootstrapService = app.getService('BootstrapService');
            this.modalHandlerService = app.getService('ModalHandlerService');
            this.overlayHandlerService = app.getService('OverlayHandlerService');
            this._hideScreenOffModal = this.hideScreenOffModal.bind(this);
            this._showScreenOffModal = this.showScreenOffModal.bind(this);
            this._handleModalClosed = this.handleModalClosed.bind(this);
            this.screensaverActive = false;
            this.screensaverActiveUI = false;

            this.screensaverState = new StateMachine({
                context: this,
                state: states.none,
                states: states
            });

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;

                    this.updateHandler();
                    this.bindEvents();
                }.bind(this));

            this.addStateTransitions();
        },

        /**
         * Add state transitions.
         */
        addStateTransitions: function() {
            this.screensaverState.addTransitions({
                '> off': function() {
                    if (!platform.checks.isCbox) {
                        $(document).find('#aspect-ratio-4-3').removeClass('screensaver');
                    }

                    app.emit('screensaver.active-on-ui', false);
                    app.emit('show.ui');
                    app.emit('screensaver.close');
                    app.emit('modal.close', {
                        id: 'box-screen-off'
                    });
                    app.emit('modal.close', {
                        id: 'box-screensaver'
                    });
                    app.emit('modal.close', {
                        id: 'box-screensaver-control-screen'
                    });
                },
                '> screensaver': function() {
                    this.screensaverActive = true;
                    if (platform.checks.isCboxControlScreen) {
                        app.emit('modal.open', {
                            id: 'box-screensaver-control-screen'
                        });
                    }

                    if (!platform.checks.isCbox) {
                        $(document).find('#aspect-ratio-4-3').addClass('screensaver');

                        if (this.modalHandlerService.getOpenModalId() === 'select-viewer-control'
                            || this.modalHandlerService.getOpenModalId() === 'login') {
                            return;
                        }

                        if (!this.authenticationService.getIsViewer()) {
                            app.emit('modal.open', {
                                id: 'box-screensaver',
                                onConfirm: this.deactivateScreensaver.bind(this)
                            });
                        }
                    }

                    this.closeAllOverlays();
                },
                '> screen-off': function() {
                    app.emit('screensaver.active-on-ui', false);

                    if (!platform.checks.isCbox) {
                        app.emit('modal.close', {
                            id: 'box-screensaver'
                        });

                        $(document).find('#aspect-ratio-4-3').addClass('screensaver screen-off');

                        app.on('overlay.opened', this._hideScreenOffModal);
                        app.on('overlay.opened-end', this._hideScreenOffModal);
                        app.on('overlay.closed', this._showScreenOffModal);
                        app.on('modal.closed', this._handleModalClosed);

                        this.showScreenOffModal();
                    }
                },
                'screen-off >': function() {
                    if (!platform.checks.isCbox) {
                        app.off('overlay.opened', this._hideScreenOffModal);
                        app.off('overlay.opened-end', this._hideScreenOffModal);
                        app.off('overlay.closed', this._showScreenOffModal);
                        app.off('modal.closed', this._handleModalClosed);

                        $(document).find('#aspect-ratio-4-3').removeClass('screen-off');
                    }
                },
                'screensaver >': function() {
                    this.screensaverActive = false;
                }
            });
        },

        showScreenOffModal: function() {
            const modalId = this.modalHandlerService.getOpenModalId();
            if (modalId && modalId !== 'box-screen-off'
                || this.overlayHandlerService.hasOpenOverlay() && this.authenticationService.getIsAdmin()) {
                return;
            }

            app.emit('modal.open', {
                id: 'box-screen-off',
                showSettingsButton: this.authenticationService.getIsAdmin() || this.authenticationService.getIsModerator()
            });
        },

        hideScreenOffModal: function() {
            app.emit('modal.close', {
                id: 'box-screen-off'
            });
        },

        handleModalClosed: function(options) {
            if (options?.id !== 'box-screen-off') {
                setTimeout(this.showScreenOffModal.bind(this), 0);
            }
        },

        /**
         * Send Screensaver off command.
         *
         */
        deactivateScreensaver: function() {
            this.deviceConnection
                .send('setScreensaverOff')
                .then(function() {
                    this.deactivateRunning = false;
                }.bind(this), function() {
                    this.deactivateRunning = true;
                }.bind(this));
        },

        /**
         * Set state to off before sending commands. Otherwise it is possible to set state off to late.
         * RELEASE-3128 - Source Menu is gone after Screen Saver.
         */
        prepareScreensaverOff: function() {
            this.screensaverState.changeState(states.off);
        },

        /**
         * Update states and handle if screensaver gets activated.
         */
        updateHandler: function() {
            this.deviceConnection
                .send('getScreensaverState')
                .then(function(screensaver) {
                    var state = screensaver.state;

                    if (this.screensaverState.getState() !== state) {
                        this.screensaverState.changeState(state);
                    }
                }.bind(this));
        },

        /**
         * Close all overlays and modals and also the toolbar and menu before opening the screensaver.
         */
        closeAllOverlays: function() {
            app.emit('show.ui', true);
            app.emit('menu.close');
            app.emit('control-center.close');
            app.emit('file-browser.close');
            app.emit('modal.close', {
                exceptIds: ['box-screensaver']
            });
            app.emit('overlay.close.ignorant'); // RELEASE-3098
            app.emit('overlay.close');
            app.emit('backdrop.hide');
            app.emit('screensaver.open');
        },

        /**
         * Bind update handler to main loop.
         */
        bindEvents: function() {
            app.on('main-loop.update', this.updateHandler.bind(this));
            app.on('main-loop.update.screensaver', this.updateHandler.bind(this));
            app.on('screensaver.active-on-ui', this.setScreensaverActiveUI.bind(this));
        },

        /**
         * When clicked on screensaver button (end presentation dialog), immediately set the screensaver active
         * for the correct UI handling. E.g. avoids that the control bar is opened.
         * @param active true/false
         */
        setScreensaverActiveUI: function(active) {
            this.screensaverActiveUI = active;
        },

        /**
         * Get the screensaver active on UI state to handle the UI correctly.
         *
         * @returns {boolean|*} true/false
         */
        isScreensaverActiveUI: function() {
            return this.screensaverActiveUI;
        },

        /**
         * Is screensaver active
         *
         * @returns {boolean} true/false
         */
        isScreensaverActive: function() {
            return this.screensaverActive;
        },

        /**
         * Is screen off active
         *
         * @returns {boolean} true/false
         */
        isScreenOffActive: function() {
            return !!((this.screensaverState.getState() === states.screenOff
                || this.screensaverState.getState() === states.none));
        },

        /**
         * Get screensaver state via wolfprot.
         *
         * @returns {*}
         */
        getScreensaverState: function() {
            var dfd = $.Deferred();

            this.deviceConnection
                .send('getScreensaverState')
                .then(function(screensaver) {
                    if (this.screensaverState.getState() !== screensaver.state) {
                        this.screensaverState.changeState(screensaver.state);
                    }
                    dfd.resolve(screensaver.state);
                }.bind(this), function() {
                    dfd.resolve(states.none);
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Get screensaver settings via wolfprot.
         *
         * @return {*}
         */
        getScreenSaverSettings: function() {
            return this.deviceConnection
                .send('getScreensaverSettings');
        }
    };
});
