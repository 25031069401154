// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <foreignObject\n                class=\"svg-grid-file"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDownloadable") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":6,"column":36},"end":{"line":7,"column":67}}})) != null ? stack1 : "")
    + "\"\n                x=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"pos") : depth0)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\" y=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"pos") : depth0)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\" width=\""
    + alias3(alias2((depths[1] != null ? lookupProperty(depths[1],"itemWidth") : depths[1]), depth0))
    + "\" height=\""
    + alias3(alias2((depths[1] != null ? lookupProperty(depths[1],"itemHeight") : depths[1]), depth0))
    + "\"\n                data-name=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" data-path=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"path") : depth0), depth0))
    + "\" data-mount-type=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"mountType") : depth0), depth0))
    + "\">\n                <div class=\"file-box-item\">\n                    <div class=\"file-box-item-icon\">\n                        <div class=\"icon-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFile") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":51},"end":{"line":12,"column":88}}})) != null ? stack1 : "")
    + "\">\n                            <div class=\"icon-media "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"icon") : depth0), depth0))
    + "\"></div>\n                        </div>\n                    </div>\n                    <div class=\"file-box-item-text\">\n                        <span class=\"text\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n                        <span class=\"text sub-text\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "</span>\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFolder") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":34,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDownloadable") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":16},"end":{"line":38,"column":23}}})) != null ? stack1 : "")
    + "            </foreignObject>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " downloadable";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isDraggable") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":6,"column":76},"end":{"line":7,"column":60}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "\n                draggable";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isFolder") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":25},"end":{"line":7,"column":60}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return " is-folder";
},"10":function(container,depth0,helpers,partials,data) {
    return "transparent";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"file-box-item-icon btn-forward\" data-path=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"path") : depth0), depth0))
    + "\">\n                            <span class=\"icon icon-chevron-right\"></span>\n                        </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"file-box-item-icon btn-download\" data-action=\"download\">\n                            <span class=\"icon icon-download\" data-action=\"download\"></span>\n                        </div>\n                        <div class=\"file-box-item-icon file-loader-text\">\n                            <span class=\"text\"></span>\n                        </div>\n                        <div class=\"file-box-item-icon btn-share\" data-action=\"share\">\n                            <span class=\"icon icon-share-to-all\" data-action=\"share\"></span>\n                        </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"file-loader\"></div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <foreignObject class=\"svg-grid-file no-files\"\n                           x=\"0\" y=\"0\" width=\"322\" height=\"60\">\n                <div class=\"file-box-item\">\n                    <div class=\"file-box-item-icon\">\n                        <span class=\"icon icon-blocked\"></span>\n                    </div>\n                    <div class=\"file-box-item-text\">\n                        <span class=\"text\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"files.no-files-message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":43},"end":{"line":50,"column":73}}}))
    + "</span>\n                    </div>\n                </div>\n            </foreignObject>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg id=\"svg-grid-files\" class=\"svg-grid-files\" viewBox=\"0, 0, "
    + alias4(((helper = (helper = lookupProperty(helpers,"width") || (depth0 != null ? lookupProperty(depth0,"width") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"width","hash":{},"data":data,"loc":{"start":{"line":1,"column":63},"end":{"line":1,"column":72}}}) : helper)))
    + ", "
    + alias4(((helper = (helper = lookupProperty(helpers,"height") || (depth0 != null ? lookupProperty(depth0,"height") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"height","hash":{},"data":data,"loc":{"start":{"line":1,"column":74},"end":{"line":1,"column":84}}}) : helper)))
    + "\">\n    <g class=\"files-layer\">\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"files") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":40,"column":17}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"noFiles") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":8},"end":{"line":54,"column":15}}})) != null ? stack1 : "")
    + "\n    </g>\n</svg>\n";
},"useData":true,"useDepths":true});
