// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div id=\"modal-header-close\" class=\"modal-header\" data-nav-area=\".close-modal\" data-nav-trapped>\n            <button class=\"close-modal pull-right\" tabindex=\"-1\">\n                <div class=\"icon-v3-close\"></div>\n            </button>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal modal-full\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hideHeader") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "    <div class=\"modal-content\" id=\"modal-full-view\"></div>\n</div>\n";
},"useData":true});
