'use strict';

var app = require('../../../app');
var platform = require('../../../../platform/platform');
var keyboardTpl = require('./keyboard.hbs');

var keyboardStates = {
    closed: 'closed',
    open: 'open'
};

var isHiddenClass = 'is-hidden';

app.component('FrameboxKeyboard', {
    template: keyboardTpl,

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.showKeyboardTouch = this.options.allTouchDevices;
        this.hasClicked = false;

        this.stationService = this.getService('StationService');
        this.frameBoxService = this.getService('FrameBoxService');
        this.keyboard = this.getService('KeyboardService');
        this.keyboardState = this.createStateMachine({
            state: keyboardStates.closed,
            states: keyboardStates
        });

        this.options.action(this.action.bind(this));

        // RELEASE-124: Keyboard should appear when opening Cloud, Browser window with input field focused.
        if (!this.options.avoidOpen) {
            this.action('setHasClick', true);
        }

        this.addStateTransitions();
        this.bindEvents();
    },

    /**
     * Bind all custom events.
     */
    bindEvents: function() {
        this.on('keyboard:closed', this.onKeyboardClosed.bind(this));
    },

    bindDOMEvents: function() {
        this.$inputContainer.on('click', this.handleInputFieldType.bind(this));
    },

    /**
     * Called when the keyboard has been closed.
     */
    onKeyboardClosed: function() {
        this.keyboardState.changeState(keyboardStates.closed);
    },

    /**
     * Set action for Keyboard.
     *
     * @param {string} action
     * @param {*} actionProperties
     */
    action: function(action, actionProperties, doNotOpenKeyboard) {
        switch (action) {
            case 'update':
                this.updateInputField(actionProperties, doNotOpenKeyboard);
                break;
            case 'close':
                this.close();
                break;
            case 'setHasClick':
                this.hasClicked = actionProperties;
                break;
        }
    },

    /**
     * Called after HTML was rendered.
     */
    postPlaceAt: function() {
        this.storeSelectors();
        this.bindDOMEvents();
    },

    /**
     * Stores all selectors.
     */
    storeSelectors: function() {
        this.$inputContainer = this.$('#browser-smartphone-input-' + this.index);
        this.$input = this.$inputContainer.find('input');
    },

    /**
     * Update Position and Sizes of Input-Field.
     */
    updateInputField: function(box, doNotOpenKeyboard) {
        this.box = box;
        var data = this.box.options.inputFieldBoundings;

        // Workaround for Office365 on iOS: to show the keyboard we need to set the inputfield boundings
        if (platform.checks.isIOS && this.frameBoxService.frameboxes.boxes[this.index].contentType === 'Office365') {
            data.top = 0.01;
            data.bottom = 0.02;
            data.right = 0.02;
            data.left = 0.01;
            data.height = 0.1;
            data.width = 0.1;
        }

        if (!data) {
            return;
        }

        this.$inputContainer.css({
            top: data.top * 100 + '%',
            left: data.left * 100 + '%',
            height: (data.bottom - data.top) * 100 + '%',
            width: (data.right - data.left) * 100 + '%'
        });

        if (data.top !== 0 && data.left !== 0 && data.bottom !== 0 && data.right !== 0) {
            this.$inputContainer.removeClass(isHiddenClass);
        }

        if (!doNotOpenKeyboard) {
            this.handleInputFieldType();
        }
    },

    /**
     * Handle input-field type after click on Framebox.
     * - notext No input-field was selected.
     * - default input field was selected.
     */
    handleInputFieldType: function() {
        clearTimeout(this.openTimeout);

        if (this.stationService.getLockStatus()) {
            return;
        }

        if (typeof this.frameBoxService.frameboxes.boxes[this.frameBoxService.getActiveFrameBox()] === 'undefined'
            || this.box.contentType !== this.frameBoxService.frameboxes.boxes[this.frameBoxService.getActiveFrameBox()].contentType
            || this.frameBoxService.getActiveFrameBox() !== this.index
        ) {
            this.$inputContainer.addClass(isHiddenClass);
            this.keyboardState.changeState(keyboardStates.closed);

            return;
        }

        // Workaround for touchdevices and office365 -> not all input fields were recognized.
        if (this.showKeyboardTouch && this.frameBoxService.frameboxes.boxes[this.index].contentType === 'Office365') {
            if (this.hasClicked && this.keyboardState.currentState !== keyboardStates.open) {
                this.$inputContainer.removeClass(isHiddenClass);
                this.openTimeout = setTimeout(function() {
                    this.keyboardState.changeState(keyboardStates.open);
                }.bind(this), 250);
            }

            return;
        }

        switch (this.box.options.inputType) {
            case 'notext':
            case 'unknown':
                this.$inputContainer.addClass(isHiddenClass);
                this.keyboardState.changeState(keyboardStates.closed);
                break;
            default:
                if (this.hasClicked) {
                    this.$inputContainer.removeClass(isHiddenClass);

                    this.openTimeout = setTimeout(function() {
                        // Reset all selectors, this is needed on the fullscreen toggle since ui2.0.
                        // RELEASE-2295 - Sometimes Keyboard appears and disappears when touching the search input.
                        this.storeSelectors();
                        this.keyboardState.changeState(keyboardStates.open);
                    }.bind(this), 250);
                }
                break;
        }
    },

    /**
     * @method addStateTransitions
     */
    addStateTransitions: function() {
        this.keyboardState.addTransitions({
            // Close Keyboard.
            '> closed': function() {
                this.keyboard.setManuallyOpened(false);

                if (platform.checks.isCbox) {
                    this.keyboard.close();
                } else if (platform.checks.isTouchDevice) {
                    this.$input.blur().prop('disabled', true);
                }
            },
            // Open Keyboard
            '> open': function() {
                if (platform.checks.isCbox) {
                    this.keyboard.open(this.$input);
                } else if (platform.checks.isTouchDevice) {
                    this.$input.prop('disabled', false).focus();
                    this.$input.val('').trigger('change');
                }
            }
        });
    },

    /**
     * Close Keyboard.
     */
    close: function() {
        this.keyboardState.changeState(keyboardStates.closed);
    },

    /**
     * Destroy Keyboard-Component.
     */
    destroy: function() {
        this.$input.blur().prop('disabled', true);
        this.keyboardState.changeState(keyboardStates.closed);
    }
});
