'use strict';

const app = require('../app');
const StateMachine = require('./../state-machine');
const states = require('./../states');
const timerFactory = require('../../../modules/app/timer');

/**
 * Record states
 */
const recordingStates = {
    record: 'record',
    stop: 'stop',
    pause: 'pause'
};

/**
 * Record Function states
 */
const recStates = {
    enabled: 'enabled',
    disabled: 'disabled'
};

/**
 * Record State Mapping
 */
const recStateMapping = {
    true: 'enabled',
    false: 'disabled'
};

/**
 * Recording Renames Mapping
 */
const recordingRenames = {
    true: 'enabled',
    false: 'disabled'
};

/**
 * Record Trigger States
 */
const recordTriggerStates = {
    none: 'none',
    manual: 'manual',
    opencast: 'opencast',
    panopto: 'panopto',
    panoptoAuto: 'panoptoAuto'
};

app.service('RecordService', function(app) {
    return {
        /**
         * @method initialize
         */
        initialize: function() {
            this.URIService = app.getService('URIService');
            this.recEnabled = false;
            this.space = null;
            this.lcs = {};
            this.lcsType = states.lcsTypes.disabled;
            this.panopto = {};
            this.panoptoCustomOAuth2Login = false;
            this.state = new StateMachine({
                state: recordingStates.stop,
                states: recordingStates
            });

            this.recordState = new StateMachine({
                context: this,
                state: recStates.enabled,
                states: recStates
            });

            this.recordTriggerState = new StateMachine({
                context: this,
                state: recordTriggerStates.none,
                states: recordTriggerStates
            });

            this.recordingRename = new StateMachine({
                context: this,
                state: recordingRenames.off,
                states: recordingRenames
            });

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.bindEvents();
                    this.updateHandler();
                }.bind(this));

            this.lectureCaptureService = app.getService('LectureCaptureService');
            this.mountService = app.getService('MountService');
            this.userSettingsService = app.getService('UserSettingsService');
            this.deviceService = app.getService('DeviceService');

            this.addStateTransitions();
        },

        /**
         * @method bindEvents
         */
        bindEvents: function() {
            app.on('main-loop.update', this.updateHandler.bind(this));
            app.on('main-loop.update.record', this.updateHandler.bind(this));
        },

        /**
         * @method updateHandler
         */
        updateHandler: function() {
            return this.deviceConnection
                .send([
                    'getVideoRecordingState',
                    {
                        command: 'getStorageSpaceLeft',
                        data: {
                            unit: 'MB'
                        }
                    },
                    'getRecordingFunction',
                    'getRecordingTrigger',
                    'getRecordingRename',
                    'getLectureCaptureSettings',
                    'getPanoptoLoginStatus',
                    'getPanoptoSettings'
                ])
                .then(this.onData.bind(this));
        },

        /**
         * @param {Object} data
         * @param {Number} space
         * @param {Object} rec
         * @param {Object} recTrigger
         */
        onData: function(data, space, rec, recTrigger, recordingRename, lcs,
            panoptoLoginState, panoptoSettings) {
            var trigger = recTrigger.trigger;
            var state = data.recordingMode;

            this.recEnabled = recStateMapping[rec.enabled];

            // If Composer change lecture capture type to disabled if opencast was selected in normal mode
            if (this.deviceService.isCboxProDualProjection()
                && ['opencastAdhoc', 'opencastAuto'].includes(states.lcsCmdToTypeMapping[lcs.type])) {
                lcs.type = 0;
            }

            this.lcs = lcs;
            this.lcsType = states.lcsCmdToTypeMapping[this.lcs.type];
            this.panopto = panoptoSettings;
            this.recordingTimeInMsec = data.recordingTime;

            this.data = data;

            if (this.recordState.getState() !== this.recEnabled) {
                this.recordState.changeState(this.recEnabled);

                app.emit('record.function.changed');
            }

            if (!state) {
                state = 'pause';
            }

            if (this.state.getState() !== recordingStates[state]) {
                this.state.changeState(recordingStates[state]);

                app.getService('PipService').updatePipStatusWidget();

                app.emit('status-bar.update.ipcam-health-status', {
                    status: app.getService('StreamInputService').healthStatus.currentState
                });
            }

            if (this.recordingRename.getState() !== recordingRenames[recordingRename.enabled]) {
                this.recordingRename.changeState(recordingRenames[recordingRename.enabled]);
            }

            this.space = space;

            if (this.recordTriggerState.getState() !== recordTriggerStates[trigger]) {
                this.recordTriggerState.changeState(recordTriggerStates[trigger]);
            }

            if (this.panoptoCustomOAuth2Login && panoptoLoginState.status === states.lectureCaptureLoginStates.loggedIn) {
                this.openPanoptoModal(true);

                this.panoptoCustomOAuth2Login = false;
            }

            if (recordingStates[state] === 'record') {
                if (!this.timer) {
                    this.timer = timerFactory.create({
                        alreadyElapsedMs: this.recordingTimeInMsec
                    });

                    this.timer.on('tick', function() {
                        this.recordingTimeInMsecLocal = this.timer.elapsedMs();
                        app.emit('record.timer.update', this.recordingTimeInMsecLocal);
                    }.bind(this));
                }

                this.timer.syncTime({
                    alreadyElapsedMs: this.recordingTimeInMsec
                });
            } else if (this.timer) {
                this.timer.close();
                this.timer = null;
            }

            this.recordingTimeInMsecLocal = this.timer ? this.timer.elapsedMs() : this.recordingTimeInMsec;

            if (recordingStates[state] !== 'record') {
                app.emit('record.timer.update', this.recordingTimeInMsecLocal);
            }

            app.emit('recording.data.changed');
        },

        isEnabled: function() {
            return this.recEnabled;
        },

        isLcsEnabled: function() {
            return this.lcs.type !== 0;
        },

        isLcsRemoteRecorderActive: function() {
            return this.recordTriggerState.getState() === recordTriggerStates.panoptoAuto
                || this.recordTriggerState.getState() === recordTriggerStates.opencast
                && this.lcsType === states.lcsTypes.opencastAuto;
        },

        isLcsAdHocRecorderEnabled: function() {
            return this.lcsType === states.lcsTypes.panoptoAdhocLegacy
                || this.lcsType === states.lcsTypes.panoptoAdhocOAuth2
                || this.lcsType === states.lcsTypes.opencastAdhoc;
        },

        /**
         * Returns true if state is recording or pause.
         *
         * @returns {boolean}
         */
        isRecording: function() {
            return this.getState() !== recordingStates.stop;
        },

        getSpace: function() {
            return this.space;
        },

        getData: function() {
            return this.data;
        },

        getState: function() {
            return this.state.getState();
        },

        getLcsType: function() {
            return this.lcsType;
        },

        /**
         * Start recording and check if panopto/opencast is enabled.
         * @return {*|void}
         */
        startRecording: function() {
            switch (this.lcsType) {
                case states.lcsTypes.panoptoAdhocLegacy:
                case states.lcsTypes.panoptoAdhocOAuth2:
                    return this.recEnabled === 'enabled' ? this.openRecordingTypeModal('panopto') : this.startPanopto();
                case states.lcsTypes.opencastAdhoc:
                    return this.recEnabled === 'enabled' ? this.openRecordingTypeModal('opencast') : this.startOpencast();
                default:
                    return this.recEnabled === 'enabled' ? this.startLocalRecording() : undefined;
            }
        },

        /**
         * Start panopto recording.
         *
         * @param {String} folderId
         */
        startLcsRecording: function(treeId, name, title) {
            this.deviceConnection
                .send('setLectureCaptureStartRecording', {
                    type: this.lcsType,
                    ipCameraMode: states.ipCameraModeFromCmd[this.lcs.ipCameraMode],
                    treeId: treeId,
                    filename: this.lcs.filename,
                    name: name,
                    title: title
                });

            app.emit('main-loop.fast.start', {
                id: 'record'
            });
        },

        /**
         * Opens recording type modal (choose recording)
         * @param service (lcs service type)
         */
        openRecordingTypeModal: function(service) {
            app.emit('modal.open', {
                id: 'recording-type',
                service: service,
                onStartLocalRecording: this.startLocalRecording.bind(this),
                onStartPanopto: this.startPanopto.bind(this),
                onStartOpencast: this.startOpencast.bind(this)
            });
        },

        /**
         * Start Panopto.
         * Before starting Panopto check if login dialog for custom user should be shown.
         * If so, show login dialog and set custom user credentials before opening the Panopto modal.
         */
        startPanopto: function() {
            return this.deviceConnection.send([
                'getPanoptoLoginDialog'
            ]).then(function(loginDialog) {
                if (loginDialog.dialog !== states.panoptoLoginDialogStates.none
                            && this.lectureCaptureService.getLoginState('panopto') !== states.lectureCaptureLoginStates.loggedIn) {
                    this.panoptoLoginHandler();
                } else {
                    this.openPanoptoModal(this.panopto.customUserMode);
                }
            }.bind(this));
        },

        /**
         * Handle Panopto login.
         * If Panopto user settings are available show user settings dialog otherwise open login dialog.
         */
        panoptoLoginHandler: function() {
            this.mountService.checkIfServiceAvailable('lcs.panopto')
                .then(data => {
                    if (data.serviceAvailable) {
                        this.userSettingsService.openUserSettingsDialog(true, {
                            // Workaround: setTimeout is used to execute callbacks from another context, since
                            // User settings component is destroyed
                            onCancel: function() {
                                setTimeout(() => this.startPanoptoLogin(), 0);
                            }.bind(this),
                            onLoad: function() {
                                setTimeout(() => this.openPanoptoModal(true), 0);
                            }.bind(this),
                            closeAll: false
                        });
                    } else {
                        this.startPanoptoLogin();
                    }
                });
        },

        startPanoptoLogin: function() {
            switch (this.lcsType) {
                case states.lcsTypes.panoptoAdhocOAuth2:
                    this.oauth2LoginPanopto();
                    break;
                case states.lcsTypes.panoptoAdhocLegacy:
                    this.openPanoptoLoginDialog();
                    break;
            }
        },

        oauth2LoginPanopto: function() {
            this.panoptoCustomOAuth2Login = true;

            this.deviceConnection
                .send('setPanoptoBrowserLogin', {
                    login: true
                });
        },

        /**
         * Open Panopto Login Dialog.
         */
        openPanoptoLoginDialog: function() {
            app.emit('modal.open', {
                id: 'genericLogin',
                title: 'Panopto',
                closeAll: false,
                onLogin: function(username, password) {
                    this.deviceConnection.
                        send('setPanoptoCustomUserCredentials',
                            {
                                username: username,
                                password: password
                            }
                        ).then(() => this.openPanoptoModal(true));
                }.bind(this)
            });
        },

        /**
         * Open Panopto Modal.
         * @param isCustomUser: Custom user mode enabled
         */
        openPanoptoModal: function(isCustomUser) {
            app.emit('modal.open', {
                id: 'panopto',
                isCustomUser: isCustomUser,
                lcsType: this.lcsType
            });
        },

        /**
         * Start Opencast.
         */
        startOpencast: function() {
            app.emit('modal.open', {
                id: 'opencast'
            });
        },

        /**
         * Start local recording.
         */
        startLocalRecording: function() {
            if (this.recordingRename.getState() === recordingRenames['true']) {
                app.emit('modal.open', {
                    id: 'start-recording',
                    onConfirm: function(value) {
                        if ('%' === value.charAt(0)) {
                            value = value.substr(1);
                        }
                        this.deviceConnection
                            .send('setVideoRecordingState', {
                                recordingState: 'record',
                                filename: this.URIService.encode(value)
                            });

                        app.emit('main-loop.fast.start', {
                            id: 'record'
                        });
                    }.bind(this)
                });
            } else {
                const promise = this.deviceConnection
                    .send('setVideoRecordingState', {
                        recordingState: 'record'
                    });

                app.emit('main-loop.fast.start', {
                    id: 'record'
                });

                return promise;
            }
        },

        toggleRecordState: function(state) {
            return this.deviceConnection
                .send('setVideoRecordingState', {
                    recordingState: state
                });
        },

        /**
         * Status widget for recording is only used in non-dual-projection mode.
         * In dual-projection mode an action widget is used for recording.
         */
        addStateTransitions: function() {
            this.state.addTransitions({
                '> stop': function() {
                    if (!this.deviceService.isCboxProDualProjection()) {
                        app.emit('status-widget.item.remove', {
                            id: 'record-widget'
                        });
                    }
                }.bind(this),
                'stop > pause': function() {
                    if (!this.deviceService.isCboxProDualProjection()) {
                        app.emit('status-widget.item.append', {
                            id: 'record-widget',
                            accessKey: 'Record',
                            options: {
                                icon: 'icon-v3-record',
                                clickable: true,
                                state: recordingStates.pause
                            }
                        });
                    }
                }.bind(this),
                'stop > record': function() {
                    if (!this.deviceService.isCboxProDualProjection()) {
                        app.emit('status-widget.item.append', {
                            id: 'record-widget',
                            accessKey: 'Record',
                            options: {
                                icon: 'icon-v3-record',
                                clickable: true,
                                state: recordingStates.record,
                                widgetClass: 'record-status-widget'
                            }
                        });
                    }
                }.bind(this),
                'pause > record': function() {
                    if (!this.deviceService.isCboxProDualProjection()) {
                        app.emit('status-widget.item.update', {
                            id: 'record-widget',
                            options: {
                                state: recordingStates.record
                            }
                        });
                    }
                }.bind(this),
                'record > pause': function() {
                    if (!this.deviceService.isCboxProDualProjection()) {
                        app.emit('status-widget.item.update', {
                            id: 'record-widget',
                            options: {
                                state: recordingStates.pause
                            }
                        });
                    }
                }.bind(this)
            });
        }
    };
});
