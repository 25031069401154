/* eslint-disable capitalized-comments */

'use strict';

const app = require('../../app');
const controlCenterTpl = require('./control-center-v3.html');
const vueUtils = require('../../components-vue/util.js');
const platform = require('../../../platform/platform');
const Vuex = require('vuex');

const MAX_POLL_INTERVAL_WHEN_CLOSED = 10000;
const MAX_POLL_INTERVAL_WHEN_OPEN = 0;

// This can be used to test specific layouts
// If fields are commented out, they will be determined by configuration
// In production, all fields should be commented out
const debugOverride = {
    // hasCustomCommands: true,
    //
    // hasStreamingControls: true,
    // hasRecording: true,
    // hasVolume: true,
    // hasPipControls: true,
    // hasMirroringControls: true,
    // hasVMeeting: true,
    //
    // showGroupworkButton: true,
    // downloadAppButtonAvailable: true,
    // showSaveUserSettingsButton: true,
    // showSplashScreenButton: true,
    // showQuicktoolControls: true,
    //
    // limitedAccessMode: true,
    // stationPushStatus: true,
    // stationAudioMode: 'audio'
};

app.component('ControlCenterV3', {
    engine: 'vue',
    template: controlCenterTpl,

    data: {
        components: [{
            type: 'vmeeting-controls',
            options: {}
        }, {
            type: 'recording-controls',
            options: {}
        }, {
            type: 'streaming-controls',
            options: {}
        }, {
            type: 'volume-controls',
            options: {}
        }, {
            type: 'custom-command-controls',
            options: {}
        }, {
            type: 'pip-controls',
            options: {}
        }, {
            type: 'mirroring-controls',
            options: {}
        }],

        numberCustomCommandButtons: 0,

        hasStreamingControls: false,
        hasRecording: false,
        hasVolume: true,
        hasPipControls: false,
        hasMirroringControls: false,
        hasVMeeting: false,
        show: false,

        showGroupworkButton: false,

        downloadAppButtonAvailable: false,

        showSaveUserSettingsButton: false,
        showSplashScreenButton: false,
        showRoomControllerButton: false,
        showCloseAllWindowsButton: true,
        showQuicktoolControls: false,

        limitedAccessMode: true,
        stationPushStatus: true,
        stationAudioMode: 'standard',

        focusArea: '.is-focusable:not(.disabled)'
    },

    methods: {
        close: function() {
            this.show = false;
        },

        open: function() {
            if (this.show || this.isEditModeActive
                || this.authService.getIsLdapActive() && !this.authService.getIsLdapAuthenticated()
                || this.annotationService.isMagicPenActive()) {
                return;
            }

            this.show = true;
        },

        /**
         * Toggle control center open/close
         */
        toggle: function() {
            if (!this.show) {
                this.open();
            } else {
                this.close();
            }
        }
    },

    watch: {
        show: function(val) {
            if (val) {
                this.component.emit('control-center.opened');
                this.pollHelper.throttleInterval(MAX_POLL_INTERVAL_WHEN_OPEN);
                this.component.emit('framebox.speed.slow');
            } else {
                this.component.emit('control-center.closed');
                this.pollHelper.throttleInterval(MAX_POLL_INTERVAL_WHEN_CLOSED);
                this.component.emit('framebox.speed.full');
            }
        }
    },

    computed: {
        prioritizedComponents: function() {
            let result = [];

            if (this.hasVMeeting) {
                result = [...result, {
                    type: 'vmeeting-controls',
                    options: {},
                    disabled: this.limitedAccessMode || this.audioMode
                }];
            }

            if (this.hasRecording) {
                result = [...result, {
                    type: 'recording-controls',
                    options: {},
                    disabled: this.limitedAccessMode || this.audioMode
                }];
            }

            if (this.hasStreamingControls) {
                result = [...result, {
                    type: 'streaming-controls',
                    options: {},
                    disabled: this.limitedAccessMode || this.audioMode
                }];
            }

            if (this.hasVolume) {
                result = [...result, {
                    type: 'volume-controls',
                    options: {},
                    disabled: false
                }];
            }

            if (this.numberCustomCommandButtons > 0) {
                result = [...result, {
                    type: 'custom-command-controls',
                    options: {},
                    disabled: this.limitedAccessMode || this.audioMode
                }];
            }

            if (this.hasPipControls) {
                result = [...result, {
                    type: 'pip-controls',
                    options: {},
                    disabled: this.limitedAccessMode || this.audioMode
                }];
            }

            if (this.hasMirroringControls) {
                result = [...result, {
                    type: 'mirroring-controls',
                    options: {},
                    disabled: this.limitedAccessMode || this.audioMode
                }];
            }

            return result;
        },

        audioMode: function() {
            return this.stationPushStatus && this.stationAudioMode === 'audio';
        },

        /**
         * Optional Buttons:
         * Quick Tools (Annotation, Snapshot, Freeze)
         * Splash Screen
         * Groupwork (Request Screenshare / Manage)
         * Save User Settings
         * Download App
         *
         * NOT INCLUDED (fixed buttons):
         * General Tools (Help, Keyboard, Settings, Close All Windows)
         * End Presentation
         * @returns {function(): number}
         */
        numberOfOptionalButtons: function() {
            let count = 0;

            if (this.showGroupworkButton) {
                count += 1;
            }

            if (this.downloadAppButtonAvailable) {
                count += 1;
            }

            if (this.showRoomControllerButton) {
                count += 1;
            }

            if (this.showSaveUserSettingsButton) {
                count += 1;
            }

            if (this.showSplashScreenButton) {
                count += 1;
            }

            if (this.showQuicktoolControls) {
                count += 1;
            }

            return count;
        },

        /**
         * Layout Type 1 ("Cynap Layout"):
         * If there are prioritized components (blocks) except volume and custom cmd controls
         * OR more than 2 optional buttons* in the general controls (first line).
         *
         * Layout Type 2 ("Pure Layout"):
         * If there are no prioritized components (blocks) except for volume and custom cmd controls
         * AND less or equal than 2 optional buttons* in the general controls (first line).
         *
         * * not optional /fixed buttons: General Tools and End Presentation
         *
         * @returns {number}
         */
        layoutType: function() {
            const hasMoreControls = this.prioritizedComponents.some((comp) => {
                return comp.type !== 'volume-controls' && comp.type !== 'custom-command-controls';
            });

            if (hasMoreControls) {
                return 1;
            }

            if (this.numberOfOptionalButtons > 2) {
                return 1;
            }

            return 2;
        },

        /**
         * Show download app button only if there is enough space left.
         * @returns {boolean}
         */
        showDownloadAppButton: function() {
            if (!this.downloadAppButtonAvailable) {
                return false;
            }

            if (this.layoutType === 2) {
                // Button is always visible in small layout
                return true;
            }

            let count = 0;

            if (this.showGroupworkButton) {
                count += 1;
            }

            if (this.showSaveUserSettingsButton) {
                count += 1;
            }

            if (this.showRoomControllerButton) {
                count += 1;
            }

            return count <= 1;
        },

        ...Vuex.mapGetters('controlScreen', ['isEditModeActive'])
    },

    created: function() {
        this.peripheralService = app.getService('PeripheralService');
        this.meetingService = app.getService('SessionManagementService');
        this.recordService = app.getService('RecordService');
        this.pipService = app.getService('PipService');
        this.streamingService = app.getService('Streaming');
        this.outputService = app.getService('OutputService');
        this.customUiSettings = app.getService('CustomUiSettings');
        this.deviceService = app.getService('DeviceService');
        this.mountService = app.getService('MountService');
        this.frontendSettings = app.getService('FrontendSettings');
        this.modelViewService = app.getService('Model-View');
        this.matrixService = app.getService('MatrixService');
        this.webcastingService = app.getService('WebcastingService');
        this.stationService = app.getService('StationService');
        this.authService = app.getService('AuthenticationService');
        this.annotationService = app.getService('AnnotationService');

        if (!this.deviceService.isCboxPureOrPro() && !this.deviceService.isCboxPureMini()) {
            this.groupworkService = app.getService('GroupworkService');
        }

        this.wolfprot = vueUtils.wolfprot();
        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return Promise.all([
                    this.peripheralService.updateCommands(),
                    this.wolfprot.talkMulti([
                        'getSplashScreenTriggerMode',
                        'getWlanMode',
                        'getMatrixStationStatus',
                        'getRoomControllerPath'
                    ]),
                    this.customUiSettings.getSettings(['audioEnabled']),
                    this.customUiSettings.getSettings(['appsEnabled']),
                    this.frontendSettings.getSettings(['splashScreenEnabled']),
                    this.frontendSettings.getSettings(['splashScreenDisplayWlanType']),
                    this.customUiSettings.getSettings(['annotationEnabled']),
                    this.customUiSettings.getSettings(['freezeEnabled']),
                    this.customUiSettings.getSettings(['snapshotEnabled'])
                ]).then(function(result) {
                    const wolfprotResults = result[1];
                    const [
                        splashScreenTriggerMode,
                        wlanMode,
                        matrixStationStatus,
                        roomControllerPath
                    ] = wolfprotResults;
                    const audioEnabled = result[2];
                    const appsEnabled = result[3];
                    const splashScreenEnabled = result[4];
                    const splashScreenDisplayWlanType = result[5];
                    const annotationEnabled = result[6];
                    const freezeEnabled = result[7];
                    const snapshotEnabled = result[8];

                    const buttonEvents = this.peripheralService.getButtonEvents();

                    return {
                        buttonEvents,
                        splashScreenTriggerMode,
                        wlanMode,
                        matrixStationStatus,
                        audioEnabled,
                        appsEnabled,
                        splashScreenEnabled,
                        splashScreenDisplayWlanType,
                        annotationEnabled,
                        freezeEnabled,
                        snapshotEnabled,
                        roomControllerPath
                    };
                }.bind(this));
            }.bind(this)
        });
        this.pollHelper.on('data', function({
            buttonEvents,
            splashScreenTriggerMode,
            wlanMode,
            matrixStationStatus,
            audioEnabled,
            appsEnabled,
            splashScreenEnabled,
            splashScreenDisplayWlanType,
            annotationEnabled,
            freezeEnabled,
            snapshotEnabled,
            roomControllerPath
        }) {
            let buttonEventsNoDuplicates = [];
            buttonEvents.forEach((buttonEvent) => {
                const hasButtonEvent = buttonEventsNoDuplicates.some((buttonEventNoDuplicate) => {
                    return buttonEventNoDuplicate.event === buttonEvent.event;
                });

                if (!hasButtonEvent) {
                    buttonEventsNoDuplicates = [...buttonEventsNoDuplicates, buttonEvent];
                }
            });

            const customCommandButtons = buttonEventsNoDuplicates.filter(function(buttonEvent) {
                return buttonEvent.enabled;
            });
            this.numberCustomCommandButtons = customCommandButtons.length;

            const hasVolume = audioEnabled;
            const hasVMeeting = this.meetingService.vMeetingEnabled && this.meetingService.isSessionActive();

            let supportsRecording = this.deviceService.isCbox()
                || (this.deviceService.isCboxCorePro && this.deviceService.getFeatures().capture);
            const hasRecording = supportsRecording && (this.recordService.isEnabled() === 'enabled'
                || this.recordService.isLcsAdHocRecorderEnabled()
                || this.recordService.isLcsRemoteRecorderActive());

            let hasStreamingControls;

            if (this.deviceService.isCbox()) {
                if (!this.streamingService.isEnabled()) { // Streaming is disabled (globally)
                    hasStreamingControls = false;
                } else if (this.matrixService.isEnabled()) { // Streaming is enabled (globally) && Matrix is enabled
                    hasStreamingControls = this.webcastingService.isEnabled();
                } else { // Streaming is enabled (globally) && Matrix is disabled
                    hasStreamingControls = this.streamingService.isStreamEnabled() || this.webcastingService.isEnabled();
                }
            } else if (this.deviceService.isCboxCorePro() && this.deviceService.getFeatures().capture) {
                hasStreamingControls = this.webcastingService.isEnabled();
            }

            const hasPipControls = this.pipService.isEnabled()
                && ((this.recordService.isEnabled() === 'enabled') || this.streamingService.isEnabled()) && !this.deviceService.isCboxProDualProjection();
            const hasMirroringControls = !this.outputService.isMirror() && this.outputService.getOverrideMirror() && !this.deviceService.isCboxProDualProjection();

            const showGroupworkButton = !this.deviceService.isCboxPureOrPro() && !this.deviceService.isCboxPureMini()
                && this.groupworkService.isGroupworkActive() !== 'inactive';
            const showSaveUserSettingsButton = this.mountService.isUSBmounted() && !this.deviceService.isCboxPure() && !this.deviceService.isCboxPureMini();
            const showCloseAllWindowsButton = !this.deviceService.isCboxPureReceiver() && !this.deviceService.isCboxProDualProjection();

            if (appsEnabled === null) {
                appsEnabled = app.getService('Model-View').appsSettingsDefault.enabled;
            }
            const downloadAppButtonAvailable = appsEnabled && (platform.checks.isWindowsOS || platform.checks.isCbox)
                && !this.deviceService.isCboxPureReceiver();

            let splashScreenButtonAvailable;

            if (platform.checks.isCbox) {
                if (splashScreenEnabled === null || splashScreenEnabled === '') {
                    splashScreenButtonAvailable = app.getService('Model-View').splashScreenSettingsDefault.enabled;
                } else {
                    splashScreenButtonAvailable = splashScreenEnabled;
                }
            } else {
                splashScreenButtonAvailable = splashScreenTriggerMode.mode;
            }

            const splashScreenButtonEnabled = !(splashScreenDisplayWlanType === 'wlan' && wlanMode.wlanMode === 'off');
            const showSplashScreenButton = splashScreenButtonAvailable && splashScreenButtonEnabled
                && !this.deviceService.isCboxPureReceiver();

            const limitedAccessMode = this.stationService.getLimitedAccessStatus();
            const stationPushStatus = this.stationService.getPushStatus();
            const stationAudioMode = matrixStationStatus.audioStatus ? 'audio' : 'standard';

            const showQuicktoolControls = !this.deviceService.isCboxPureMini()
                && (annotationEnabled && !this.deviceService.isCboxPureReceiver()
                    || freezeEnabled && !this.deviceService.isCboxPure()
                    || snapshotEnabled && !this.deviceService.isCboxPure());

            const showRoomControllerButton = roomControllerPath.enabled;

            const realData = {
                hasVolume,
                hasVMeeting,
                hasRecording,
                hasPipControls,
                hasStreamingControls,
                hasMirroringControls,
                showGroupworkButton,
                showCloseAllWindowsButton,
                downloadAppButtonAvailable,
                showSaveUserSettingsButton,
                showSplashScreenButton,
                showQuicktoolControls,
                limitedAccessMode,
                stationPushStatus,
                stationAudioMode,
                showRoomControllerButton
            };

            const data = Object.assign({}, realData, debugOverride);
            Object.keys(data).forEach((key) => {
                this[key] = data[key];
            });
        }.bind(this));
        this.pollHelper.throttleInterval(MAX_POLL_INTERVAL_WHEN_CLOSED);
        this.pollHelper.schedulePoll();

        this.evctx = vueUtils.eventContext();
        this.evctx.on('main-loop.update', function() {
            this.pollHelper.schedulePoll();
        }.bind(this));
        this.evctx.on('control-center.open', this.open);
        this.evctx.on('remote.quick-settings.keyup', this.toggle);
        this.evctx.on('control-center.close', this.close);
        this.evctx.on('menu.opened', this.close);
        this.evctx.on('file-browser.opened', this.close);
        this.evctx.on('edit-mode.opened', this.close);
        this.evctx.on('magic-pen.state.active', this.close);
        this.evctx.on('modal.open', (modal) => {
            const modalIdsWhereCloseIsNotRequired = [
                'close-all-windows',
                'end-presentation'
            ];

            if (modalIdsWhereCloseIsNotRequired.indexOf(modal.id) < 0) {
                this.close();
            }
        });

        /* Matrix station events */
        this.evctx.on('station-status.push', this.close);
        this.evctx.on('station-status.lock', this.close);
        this.evctx.on('station-status.limited', this.close);
        this.evctx.on('station-status.standard', this.close);
    },

    destroyed: function() {
        this.component.emit('control-center.closed');
        this.close();
        this.evctx.close();
    },

    components: {
        'overlay2': require('../../components-vue/overlay2/overlay2.js'),

        'layout1': require('./layouts/layout1.js'),
        'layout2': require('./layouts/layout2.js'),

        'command-button': require('../../components-vue/control-center-v3/command-button/command-button.js'),
        'vmeeting-controls': require('../../components-vue/control-center-v3/vmeeting-controls/vmeeting-controls.js'),
        'recording-controls': require('../../components-vue/control-center-v3/recording-controls/recording-controls.js'),
        'streaming-controls': require('../../components-vue/control-center-v3/streaming-controls/streaming-controls.js'),
        'mirroring-controls': require('../../components-vue/control-center-v3/mirroring-controls/mirroring-controls.js'),
        'pip-controls': require('../../components-vue/control-center-v3/pip-controls/pip-controls.js'),
        'volume-controls': require('../../components-vue/control-center-v3/volume-controls/volume-controls.js'),
        'custom-command-controls': require('../../components-vue/control-center-v3/custom-command-controls/custom-command-controls.js'),
        'quicktool-controls': require('../../components-vue/control-center-v3/quicktool-controls/quicktool-controls.js')
    }
});
