'use strict';

const app = require('../../../../app');
const matrixNetdriveSettingsTpl = require('./matrix-netdrive.hbs');
const i18n = require('i18next');

const matrixNetdriveStates = {
    'disabled': 'disabled',
    'notMounted': 'notMounted',
    'busy': 'busy',
    'authenticate': 'busy',
    'notResponding': 'notResponding',
    'mountedRead': 'mounted',
    'mountedWrite': 'mounted',
    'mountedReadWrite': 'mounted',
    'failed': 'mountFailed'
};

app.component('MatrixNetdriveSettings', {
    template: matrixNetdriveSettingsTpl,

    initialize: function() {
        this.changes = false;
        this.formData = {};
        this.form = null;
        this.formManager = this.getService('FormManager');
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.createSelects();
        this.initForm();
        this.bindEvents();
        this.checkType();
        this.updateHandler();
    },

    serialize: function() {
        var dfd = this.$.Deferred();

        this.load().then(dfd.resolve);

        return dfd.promise();
    },

    storeSelectors: function() {
        this.$netdriveState = this.$el.find('#netdrive-connection-state');
    },

    bindEvents: function() {
        this.form.on('change.input', this.onInputChanged.bind(this));

        this.on('settings.save-changes', this.saveHandler.bind(this));
        this.on('settings.matrix.saved', this.saveHandler.bind(this));
        this.on('settings.matrix.cancel', this.cancelHandler.bind(this));

        this.on('main-loop.update', this.updateHandler.bind(this));
    },

    /**
     * Called after a input or select field has been changed.
     */
    onInputChanged: function() {
        this.changes = true;
        this.checkType();
        this.emit('settings.matrix.changes');
    },

    updateHandler: function() {
        this.deviceConnection
            .send('getMatrixNetdriveStatus')
            .then(function(data) {
                this.$netdriveState.attr('data-netdrive-connection-state', matrixNetdriveStates[data.status]);
            }.bind(this));
    },

    /**
     * Init all Forms for every network-drive.
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#matrix-netdrive-form'),
            validationContainer: '.input-group'
        });

        this.form.setValues(this.formData);
    },

    /**
     * Load all matrix netdrive setting.
     */
    load: function() {
        return this.deviceConnection
            .send('getMatrixNetdrive')
            .then(function(data) {
                this.formData = {
                    'matrix-netdrive-type': data.type,
                    'matrix-netdrive-name': data.name,
                    'matrix-netdrive-user': data.user,
                    'matrix-netdrive-password': data.password,
                    'matrix-netdrive-url': data.url,
                    'matrix-netdrive-domain': data.domain
                };
            }.bind(this));
    },

    /**
     * Create type select.
     */
    createSelects: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#matrix-netdrive-type-select'),
            label: 'settings.drive_type',
            native: true,
            id: 'matrix-netdrive-type',
            name: 'matrix-netdrive-type',
            items: [
                {
                    text: i18n.t('settings.none'),
                    value: 'none'
                },
                {
                    text: 'CIFS',
                    value: 'cifs'
                },
                {
                    text: 'NFS',
                    value: 'nfs'
                }
            ]
        });
    },

    /**
     * Check with type of network connection is active on given and if it's valid.
     * - NFS
     * - CIFS
     * - DISABLED
     */
    checkType: function() {
        var $configurable = this.$el.find('#matrix-netdrive-configurable');
        var type = this.form.get('matrix-netdrive-type').getValue();

        if ('nfs' === type) {
            // Show NFS-Type.
            $configurable.removeClass('hidden');

            this.showNFSInfoText();
            this.hideAuthFields();
            this.hideDomainField();

            return this.form.get('matrix-netdrive-url').validate();
        } else if ('cifs' === type) {
            // Show CIFS type.
            $configurable.removeClass('hidden');

            this.showCIFSInfoText();
            this.showAuthFields();
            this.showDomainField();

            return this.form.validate();
        } else {
            // This network drive is disabled.
            $configurable.addClass('hidden');

            return true;
        }
    },

    /**
     * Show Infotext for NFS and hide Infotext for CIFS.
     */
    showNFSInfoText: function() {
        this.$el.find('#matrix-netdrive-url-info-message-nfs').removeClass('hidden');
        this.$el.find('#matrix-netdrive-url-info-message-cifs').addClass('hidden');
    },

    /**
     * Show Infotext for CIFS and hide Infotext for NFS.
     */
    showCIFSInfoText: function() {
        this.$el.find('#matrix-netdrive-url-info-message-cifs').removeClass('hidden');
        this.$el.find('#matrix-netdrive-url-info-message-nfs').addClass('hidden');
    },

    /**
     * Hide all auth-fields.
     */
    hideAuthFields: function() {
        var $authSection = this.$el.find('#matrix-netdrive-auth-section');

        $authSection.addClass('hidden');
        this.form
            .get('matrix-netdrive-user')
            .disable();
        this.form
            .get('matrix-netdrive-password')
            .disable();
    },

    /**
     * Show all auth-fields.
     */
    showAuthFields: function() {
        var $authSection = this.$el.find('#matrix-netdrive-auth-section');

        $authSection.removeClass('hidden');
        this.form
            .get('matrix-netdrive-user')
            .enable();
        this.form
            .get('matrix-netdrive-password')
            .enable();
    },

    /**
     * Hide domain-field.
     */
    hideDomainField: function() {
        var $domainSection = this.$el.find('#matrix-netdrive-domain-section');

        $domainSection.addClass('hidden');
        this.form
            .get('matrix-netdrive-domain')
            .disable();
    },

    /**
     * Hide domain-field .
     */
    showDomainField: function() {
        var $domainSection = this.$el.find('#matrix-netdrive-domain-section');

        $domainSection.removeClass('hidden');
        this.form
            .get('matrix-netdrive-domain')
            .enable();
    },

    /**
     * Handle cancel button press.
     */
    cancelHandler: function() {
        this.form.resetValues();
        this.checkType();

        this.changes = false;
    },

    /**
     * Save matrix netdrive settings.
     */
    saveHandler: function() {
        var data;

        if (this.form.validate() || this.form.get('matrix-netdrive-type').getValue() === 'none') {
            this.changes = false;
            data = this.form.serialize();
            this.deviceConnection
                .send([
                    {
                        command: 'setMatrixNetdrive',
                        data: {
                            url: data['matrix-netdrive-url'],
                            domain: data['matrix-netdrive-domain'],
                            user: data['matrix-netdrive-user'],
                            password: data['matrix-netdrive-password'],
                            type: data['matrix-netdrive-type']
                        }
                    }
                ])
                .then(function() {
                    this.form.setDefaultValues();
                }.bind(this));
        }
    },

    /**
     * Check if there are some changes and update action view (Cancel/Save)
     */
    hasChanges: function() {
        return {
            hasChanges: this.changes,
            invalid: !this.checkType()
        };
    }
});
