'use strict';

const app = require('../app');
const d3 = require('d3');
const $ = require('jquery');

// Dimension in pixel * 4 = svg pixels
const PREVIEW_MIN_WIDTH = 160 * 4;
const PREVIEW_TITLE_HEIGHT = 50 * 4;
const PREVIEW_GAP = 10 * 4;

/**
 * Matrix Preview service
 */
app.service('MatrixPreviewService', function() {
    return {

        initialize: function() {
            this.matrixService = app.getService('MatrixService');
            this.matrixMainService = app.getService('MatrixMainService');
            this.configs = app.getService('MatrixConfigs');
            this.redrawEngine = app.getService('MatrixConfigs');
            this.$svg = null;
        },

        setSvg: function(svg) {
            this.$svg = svg;
            this.svg = d3.select(svg[0]);
            this.$canvas = $(document).find('.preview');
        },

        /**
         * Return color of a station
         *
         * @param station {object}
         */
        getStationColor: function(station) {
            if (station.attr('id') === 'master') {
                return '#F59A22';
            }

            const btnCenter = station.select('.btn-center').select('.menu-btn');
            if (btnCenter.classed('push')
                || btnCenter.classed('coachingSrc')
                || btnCenter.classed('coachingDest')) {
                return station.select('.icon-close').style('color');
            } else if (btnCenter.classed('pull')) {
                return btnCenter.style('background-color');
            } else {
                return station.select('.icon-connector').style('color');
            }
        },

        /**
         * Calculate the maximum size previews can have without collision
         * Checking distance station to station and station to wall
         * Find the maximum width for previews without collision
         */
        getPreviewSize: function() {
            const stationsCenterCoords = [...this.svg.selectAll('.svg-station:not(.btn-stream)')._groups[0]].map(s => (
                {
                    cx: parseInt(s.getAttribute('cx')),
                    cy: parseInt(s.getAttribute('cy'))
                }));

            const planeWidth = parseInt(this.configs.get('dimensions.width'));
            const planeHeight = parseInt(this.configs.get('dimensions.height'));

            let maxWidth = 0;
            let widths = [];

            const stationsLength = stationsCenterCoords.length;

            for (let i = 0; i < stationsLength; i++) {
                const s1 = stationsCenterCoords[i];
                let closestCorner = {};

                // Check in which quarter the station is
                if (s1.cx < planeWidth - s1.cx) {
                    if (s1.cy < planeHeight - s1.cy) {
                        // Top left
                        closestCorner = { x: 0, y: 0 };
                    } else {
                        // Bottom left
                        closestCorner = { x: 0, y: planeHeight };
                    }
                } else if (s1.cy < planeHeight - s1.cy) {
                    // Top right
                    closestCorner = { x: planeWidth, y: 0 };
                } else {
                    // Bottom right
                    closestCorner = { x: planeWidth, y: planeHeight };
                }

                // Add the distance to the closest wall to the widths
                widths.push(this.getClosestWall(s1, closestCorner));

                for (let j = i + 1; j < stationsLength; j++) {
                    const s2 = stationsCenterCoords[j];

                    // Add the max possible width between the two points to widths
                    widths.push(this.getMaxWidthBetweenPoints(s1, s2));
                }
            }
            maxWidth = Math.min(...widths);

            // Set to minWidth
            maxWidth = Math.max(maxWidth, PREVIEW_MIN_WIDTH);

            return this.matrixService.convertCoordinates(
                {
                    width: maxWidth,
                    height: this.getHeightByRatio(maxWidth)
                },
                this.$svg.width(), this.$svg.height(),
                this.configs.get('dimensions.width'), this.configs.get('dimensions.height'));
        },

        /**
         * Find the maximum width two rectangles can have before collision, calculated from their center coordinates
         */
        getMaxWidthBetweenPoints: function(p1, p2) {
            const distanceX = Math.abs(p1.cx - p2.cx);
            const distanceY = Math.abs(p1.cy - p2.cy);

            const maxWidthY = this.getWidthByRatio(distanceY) - this.getWidthByRatio(PREVIEW_TITLE_HEIGHT);
            const maxWidthX = distanceX - PREVIEW_GAP;

            return Math.max(maxWidthX, maxWidthY);
        },

        /**
         * Find the maximum width a rectangle can have before collision with a wall
         */
        getClosestWall: function(p1, closestWall) {
            const distanceX = Math.abs(p1.cx - closestWall.x);
            const distanceY = Math.abs(p1.cy - closestWall.y);

            // Distance is only half width -> * 2
            const bottomClosest = (p1.y < closestWall.y ? this.getWidthByRatio(PREVIEW_TITLE_HEIGHT * 2) : PREVIEW_GAP);
            const maxWidthY = this.getWidthByRatio(distanceY) * 2 - bottomClosest;
            const maxWidthX = distanceX * 2 - PREVIEW_GAP;

            return Math.min(maxWidthX, maxWidthY);
        },

        /**
         * Calculate aspect width 16:9.
         */
        getWidthByRatio: function(height) {
            return 16 / 9 * height;
        },

        /**
         * Calculate aspect height 16:9.
         */
        getHeightByRatio: function(width) {
            return width * 9 / 16;
        }
    };
});
