// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"matrix-netdrive-form\" class=\"netdrive-item no-border\">\n    <div data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\"\n         data-nav-scrolltop=\"true\"\n         data-nav-trapped>\n        <div class=\"row settings-list-row\">\n            <div class=\"col-xs-12\">\n                <h2>\n                    "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.matrix_netdrive",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":52}}}))
    + "\n\n                    <span id=\"netdrive-connection-state\" class=\"netdrive-connection-state m-left-10\">\n                        <span class=\"netdrive-connection-state-disabled\">\n                            <div class=\"netdrive-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.disabled",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":28},"end":{"line":13,"column":53}}}))
    + "\n                        </span>\n                        <span class=\"netdrive-connection-state-notMounted\">\n                            <div class=\"netdrive-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.notMounted",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":28},"end":{"line":17,"column":55}}}))
    + "\n                        </span>\n                        <span class=\"netdrive-connection-state-mountFailed\">\n                            <div class=\"netdrive-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.mountFailed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":28},"end":{"line":21,"column":56}}}))
    + "\n                        </span>\n                        <span class=\"netdrive-connection-state-mounted\">\n                            <div class=\"netdrive-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.mounted",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":28},"end":{"line":25,"column":52}}}))
    + "\n                        </span>\n                        <span class=\"netdrive-connection-state-notResponding\">\n                            <div class=\"netdrive-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.notResponding",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":28},"end":{"line":29,"column":58}}}))
    + "\n                        </span>\n                        <span class=\"netdrive-connection-state-busy\">\n                            <div class=\"netdrive-connection-state-point\"></div>\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.busy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":28},"end":{"line":33,"column":49}}}))
    + "\n                        </span>\n                    </span>\n                </h2>\n            </div>\n        </div>\n        <div class=\"row settings-list-secundary\">\n            <div class=\"col-xs-6\">\n                <div id=\"matrix-netdrive-type-select\"></div>\n            </div>\n        </div>\n    </div>\n\n    <div id=\"matrix-netdrive-configurable\">\n        <div class=\"row settings-list-secundary\"\n             data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\"\n             data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"url\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":57},"end":{"line":52,"column":77}}}))
    + "</label>\n                    <input id=\"matrix-netdrive-url\"\n                           type=\"text\"\n                           class=\"input\"\n                           name=\"matrix-netdrive-url\"\n                           data-validation=\"requiredOrDisabled\"/>\n                    <div class=\"input-info\">\n                        <span class=\"info-message success-message\" id=\"matrix-netdrive-url-info-message-cifs\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.url_info_cifs",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":28},"end":{"line":60,"column":59}}}))
    + "\n                        </span>\n                        <span class=\"info-message success-message\" id=\"matrix-netdrive-url-info-message-nfs\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.url_info_nfs",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":28},"end":{"line":63,"column":58}}}))
    + "\n                        </span>\n                        <span class=\"info-message error-message hidden\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.required",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":72},"end":{"line":65,"column":113}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n            <div id=\"matrix-netdrive-domain-section\" class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"domain\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.domain",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":60},"end":{"line":71,"column":83}}}))
    + "</label>\n                    <input id=\"matrix-netdrive-domain\"\n                           type=\"text\"\n                           class=\"input\"\n                           name=\"matrix-netdrive-domain\"\n                           data-validation=\"requiredOrDisabled\"/>\n                    <div class=\"input-info\">\n                        <span class=\"info-message error-message hidden\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.required",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":72},"end":{"line":78,"column":113}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div id=\"matrix-netdrive-auth-section\"\n             class=\"row settings-list-secundary\"\n             data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\"\n             data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"user\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":89,"column":58},"end":{"line":89,"column":79}}}))
    + "</label>\n                    <input id=\"matrix-netdrive-user\"\n                           type=\"text\"\n                           class=\"input\"\n                           name=\"matrix-netdrive-user\"\n                           data-validation=\"requiredOrDisabled\"/>\n                    <div class=\"input-info\">\n                        <span class=\"info-message error-message hidden\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.required",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":96,"column":72},"end":{"line":96,"column":113}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"matrix-netdrive-password\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":102,"column":78},"end":{"line":102,"column":103}}}))
    + "</label>\n                    <input id=\"matrix-netdrive-password\"\n                           type=\"password\"\n                           autocomplete=\"current-password\"\n                           class=\"input\"\n                           name=\"matrix-netdrive-password\"\n                           data-validation=\"requiredOrDisabled\"/>\n                    <div class=\"input-info\">\n                        <span class=\"info-message error-message hidden\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.required",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":110,"column":72},"end":{"line":110,"column":113}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</form>\n";
},"useData":true});
