'use strict';

var app = require('../../../../../app');
var presetTpl = require('./preset.hbs');

app.component('FrameboxPreset', {
    template: presetTpl,
    className: 'framebox-preset-container framebox-submenu-inner',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    /**
     * @method initialize
     */
    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;

        this.remote = this.getService('RemoteService');
        this.formManager = this.getService('FormManager');
        this.visualizer = this.getService('VisualizerService');
        this.visualizer.bindVzEvents();
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.storeSelectors();
        this.initForm();
        this.bindDOMEvents();
    },

    /**
     * @method storeSelectors
     */
    storeSelectors: function() {
        this.$document = this.$(document);
        this.$recallPreset = this.$el.find('#visualizer-recall-preset');
        this.$setPreset = this.$el.find('#visualizer-save-preset');
    },

    bindDOMEvents: function() {
        this.on('framebox.preset.open', this.open.bind(this));
        this.on('framebox.preset.close', this.close.bind(this));
        this.on('framebox.submenu.close', this.close.bind(this));
        this.on('remote.up.keyup', this.close.bind(this));

        this.$el.on('click', '.framebox-bar-item', this.close.bind(this));
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEventsOnOpen: function() {
        this.$recallPreset.on('mousedown.visualizer-recall-preset', this.PressedHandler.bind(this, 'recall-preset'));
        this.$recallPreset.on('touchstart.visualizer-recall-preset', this.PressedHandler.bind(this, 'recall-preset'));
        this.$recallPreset.on('mouseup.visualizer-recall-preset', this.ReleasedHandler.bind(this, 'recall-preset'));
        this.$recallPreset.on('touchend.visualizer-recall-preset', this.ReleasedHandler.bind(this, 'recall-preset'));
        this.$setPreset.on('mousedown.visualizer-save-preset', this.PressedHandler.bind(this, 'set-preset'));
        this.$setPreset.on('touchstart.visualizer-save-preset', this.PressedHandler.bind(this, 'set-preset'));
        this.$setPreset.on('mouseup.visualizer-save-preset', this.ReleasedHandler.bind(this, 'set-preset'));
        this.$setPreset.on('touchend.visualizer-save-preset', this.ReleasedHandler.bind(this, 'set-preset'));
    },

    /**
     * @method unbindDOMEvents
     */
    unbindDOMEvents: function() {
        this.$recallPreset.off('mousedown.visualizer-recall-preset');
        this.$recallPreset.off('touchstart.visualizer-recall-preset');
        this.$recallPreset.off('mouseup.visualizer-recall-preset');
        this.$recallPreset.off('touchend.visualizer-recall-preset');
        this.$setPreset.off('mousedown.visualizer-save-preset');
        this.$setPreset.off('touchstart.visualizer-save-preset');
        this.$setPreset.off('mouseup.visualizer-save-preset');
        this.$setPreset.off('touchend.visualizer-save-preset');
    },

    /**
     * @param {String} command
     * @param {jQuery.Event} event
     */
    PressedHandler: function(command, event) {
        // Prevents the default context menu on touch devices.
        event.preventDefault();
        var handler = this.visualizer.getPressedHandler(command, {
            index: this.index,
            appId: this.appId
        });

        handler.call(this.visualizer);
    },

    /**
     * @param {String} command
     */
    ReleasedHandler: function(command) {
        var handler = this.visualizer.getReleasedHandler(command, {
            index: this.index,
            appId: this.appId
        });

        handler.call(this.visualizer);
    },

    /**
     * Open submenu.
     */
    open: function(options) {
        if (this.index === options.index) {
            this.$el.show();
            this.bindDOMEventsOnOpen();

            this.emit('framebox.submenu.change', this.index, true, this.$el.parent());
        }
    },

    /**
     * Close submenu.
     */
    close: function() {
        this.$el.hide();
        this.remote.destroyArea(this.$el);
        this.unbindDOMEvents();

        this.emit('framebox.submenu.change', this.index, false);
    },

    /**
     * @method initForm
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('.visualizer-preset-bar'),
            submit: true
        });
    }
});
