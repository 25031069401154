'use strict';

var app = require('../../../app');
var tpl = require('./save-changes.hbs');
var $ = require('jquery');

app.component('SettingsSaveChanges', {
    template: tpl,
    className: 'save-changes',

    getAccessKey: function() {
        return {
            'roleName': 'Settings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.onDiscard = this.options.configs.onDiscard || $.noop; // 'Discard changes'
        this.onFinish = this.options.configs.onFinish || $.noop; // After onSave/'Save changes' or onDiscard/'Discard changes'
        this.onSave = this.options.configs.onSave || $.noop; // 'Save changes'
        this.onFinishSave = this.options.configs.onFinishSave || $.noop; // After onSave/'Save changes'
        this.onDestroy = this.options.configs.onDestroy || $.noop; // Last thing before dialog is destroyed
        this.waitOnSave = this.options.configs.waitOnSave;
    },

    postPlaceAt: function() {
        this.bindEvents();
        this.bindDOMEvents();
    },

    serialize: function() {
        return {
            invalid: this.options.configs.invalid
        };
    },

    bindDOMEvents: function() {
        this.$el.on('click', '[data-action]', this.onAction.bind(this));
    },

    bindEvents: function() {
        this.on('save-changes.finished', this.finishSave.bind(this));
        this.on('overlay.history.back', this.close.bind(this));
    },

    /**
     * Handle button click.
     *
     * @param event Current event
     */
    onAction: function(event) {
        var $el = this.$(event.currentTarget);
        var action = $el.data('action');
        var handler = this.actionHandlers[action];

        if (handler) {
            handler.call(this);
        }
    },

    /**
     * Call onFinishSave before finish and close.
     */
    finishSave: function() {
        this.onFinishSave();
        this.finishAndClose();
    },

    /**
     * Call onFinish and close modal.
     */
    finishAndClose: function() {
        this.off('save-changes.finished');

        this.onFinish();
        this.close();
    },

    /**
     * Close modal.
     */
    close: function() {
        this.emit('modal.close', {
            id: 'settings-save-changes'
        });
    },

    /**
     * Action handles.
     */
    actionHandlers: {
        'discare': function() {
            this.onDiscard();
            this.finishAndClose();
        },

        'save': function() {
            this.onSave();

            // RELEASE-3530: Wait for settings to be saved.
            if (!this.waitOnSave) {
                this.finishSave();
            }
        }
    },

    /**
     * Handle destroy.
     */
    destroy: function() {
        this.onDestroy();
    }
});

