module.exports.types = {
    'visualizer': 'Visualizer',
    'hdmi': 'HDMI',
    'computer': 'HDMI',
    'disc': 'HDMI',
    'camera': 'HDMI',
    'browser': 'Browser',
    'whiteboard': 'Whiteboard',
    'webconference': 'webconference',
    'streaminput': 'streaminput',
    'office365': 'Office365',
    'webcam': 'webcam',
    'zoom': 'zoom',
    'msteams': 'msteams',
    'msoutlook': 'msoutlook',
    'msword': 'msword',
    'msexcel': 'msexcel',
    'mspowerpoint': 'mspowerpoint',
    'msonenote': 'msonenote',
    'msplanner': 'msplanner',
    'mswhiteboard': 'mswhiteboard',
    'pdf': 'pdf'
};

module.exports.sourceIcons = {
    'visualizer': 'icon-v3-visualizer',
    'hdmi': 'icon-v3-hdmi',
    'computer': 'icon-v3-laptop',
    'disc': 'icon-v3-disc-player',
    'browser': 'icon-v3-browser',
    'filebrowser': 'icon-v3-files',
    'cloud': 'icon-v3-cloud',
    'mirror': 'icon-v3-mirror-large',
    'whiteboard': 'icon-v3-whiteboard',
    'webconference': 'icon-v3-webcast-large',
    'streaminput': 'icon-v3-stream',
    'office365': 'icon-v3-office-365',
    'zoom': 'icon-v3-webconference',
    'msteams': 'icon-v3-office-teams',
    'streaminputlist': 'icon-v3-stream-record',
    'camera': 'icon-v3-webconference'
};

module.exports.contentTypeIcons = {
    'Visualizer': 'icon-v3-visualizer',
    'HdmiIn': 'icon-v3-hdmi',
    'Browser': 'icon-v3-browser',
    'vSolution': 'icon-v3-mirror-large',
    'Mirroring': 'icon-v3-mirror-large',
    'Video': 'icon-filetype-video',
    'Image': 'icon-filetype-picture1',
    'PDF': 'icon-filetype-pdf1',
    'presentation': 'icon-filetype-presentation1',
    'text': 'icon-filetype-doc',
    'calc': 'icon-filetype-numbers1',
    'whiteboard': 'icon-v3-whiteboard',
    'Audio': 'icon-music',
    'webconference': 'icon-v3-webcast-large',
    'streaminput': 'icon-v3-stream',
    'webcam': 'icon-v3-webconference',
    'Office365': 'icon-v3-office-365',
    'teams': 'icon-v3-office-teams',
    'matrix': 'icon-vmatrix',
    'matrixControl': 'icon-vmatrix',
    'matrixGroupwork': 'icon-vmatrix',
    'zoom': 'icon-v3-webconference'
};

module.exports.contentTypeTitleKeys = {
    'Visualizer': 'visualizer.visualizer',
    'HdmiIn': 'framebox.hdmi',
    'Browser': 'webbrowser.browser',
    'vSolution': 'framebox.vsolution',
    'Mirroring': 'framebox.mirroring',
    'Video': 'framebox.video',
    'Image': 'framebox.image',
    'PDF': 'framebox.pdf',
    'presentation': 'framebox.presentation',
    'text': 'framebox.text',
    'calc': 'framebox.calc',
    'whiteboard': 'framebox.whiteboard',
    'Audio': 'framebox.audio',
    'webconference': 'framebox.webconference',
    'streaminput': 'framebox.streaminput',
    'webcam': 'framebox.webcam',
    'Office365': 'framebox.office365',
    'teams': 'framebox.teams',
    'matrix': 'framebox.matrix',
    'matrixControl': 'framebox.matrix',
    'matrixGroupwork': 'framebox.matrix',
    'zoom': 'framebox.zoom'
};

module.exports.typeIcons = {
    0: 'icon-v3-hdmi',
    1: 'icon-v3-visualizer',
    2: 'icon-v3-disc-player',
    3: 'icon-v3-laptop',
    4: 'icon-v3-browser',
    5: 'icon-v3-files',
    6: 'icon-v3-cloud',
    7: 'icon-v3-mirror-large',
    8: 'icon-v3-whiteboard',
    9: 'icon-v3-webcast-large',
    10: 'icon-v3-webconference',
    11: 'icon-v3-stream',
    12: 'icon-v3-office-365',
    13: 'icon-v3-cynap-large'
};

/**
 * Maps input types to keys of typeIcons
 */
module.exports.inputTypeIconsMapping = {
    'stream': 11,
    'camera': 10,
    'computer': 3,
    'visualizer': 1,
    'hdmi': 0,
    'discPlayer': 2,
    'cynap': 13
};

/**
 * Office 365 icons
 */
module.exports.office365Icons = {
    'msoutlook': 'icon-v3-office-outlook',
    'msword': 'icon-v3-office-word',
    'msexcel': 'icon-v3-office-excel',
    'mspowerpoint': 'icon-v3-office-powerpoint',
    'msonenote': 'icon-v3-office-onenote',
    'msplanner': 'icon-v3-ms-planner',
    'msteams': 'icon-v3-office-teams',
    'mswhiteboard': 'icon-v3-office-whiteboard'
};

/**
 * Office 365 title keys
 */
module.exports.office365TitleKeys = {
    'msoutlook': 'office365.outlook',
    'msword': 'office365.word',
    'msexcel': 'office365.excel',
    'mspowerpoint': 'office365.powerpoint',
    'msonenote': 'office365.onenote',
    'msplanner': 'office365.planner',
    'msteams': 'office365.teams',
    'mswhiteboard': 'office365.whiteboard'
};

/**
 * Web conference icons
 */
module.exports.webconferenceIcons = {
    'local': 'icon-v3-webconference',
    'join': 'icon-join'
};

module.exports.statusIcons = {
    'languageChooser': 'icon-v2-language-chooser',
    'admin': 'icon-v2-admin',
    'cloud': 'icon-v3-cloud',
    'muteSpeakers': 'icon-v3-mute-speaker',
    'muteMic': 'icon-v3-mute-mic',
    'pen': 'icon-v3-pen',
    'stream': 'icon-v3-stream',
    'webcast': 'icon-v2-webcast',
    'webConferenceRecord': 'icon-v2-web-conference-record',
    'webConferenceError': 'icon-v2-web-conference-error',
    'record': 'icon-v3-record',
    'pip': 'icon-v2-pip'
};

module.exports.annotationIcons = {
    'hand': 'icon-v3-hand',
    'pen': 'icon-v3-pen',
    'rubber': 'icon-v2-eraser',
    'circle': 'icon-v3-oval',
    'rectangle': 'icon-v3-rectangle',
    'line': 'icon-v3-line',
    'strength': 'icon-v2-line-width-light',
    'strengthMin': 'icon-v2-line-width-light',
    'strengthMax': 'icon-v2-line-width-strong',
    'opacity': 'icon-v2-opacity',
    'opacityMin': 'icon-v2-opacity-0',
    'opacityMax': 'icon-v2-opacity-100'
};
