// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"matrix-streaming-settings\" name=\"matrix-streaming-settings\">\n        <div class=\"row settings-list-row\"\n             data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n             data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div id=\"resolution-select\"></div>\n            </div>\n            <div class=\"col-xs-6\">\n                <div id=\"framerate-select\"></div>\n            </div>\n        </div>\n        <div class=\"row settings-list-row\"\n             data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n             data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div id=\"bandwidth-mode-select\"></div>\n            </div>\n            <div class=\"col-xs-6\">\n                <div id=\"bandwidth-select\"></div>\n            </div>\n        </div>\n        <div class=\"row settings-list-row\"\n             data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n             data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label ip-label\" for=\"ipAddress\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ip_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":72},"end":{"line":28,"column":99}}}))
    + "</label>\n                    <input id=\"ipAddress\"\n                           type=\"text\"\n                           class=\"input ipAddress\"\n                           name=\"ipAddress\"\n                           placeholder=\"0.0.0.0\"\n                           data-validation=\"isMulticastIp\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_ip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":71},"end":{"line":36,"column":96}}}))
    + "</span>\n                        <span class=\"input-info-message success-message\"></span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"port\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.port",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":58},"end":{"line":43,"column":79}}}))
    + "</label>\n                    <input id=\"port\"\n                           type=\"text\"\n                           class=\"input\"\n                           name=\"port\"\n                           placeholder=\"5500\"\n                           data-min-port=\""
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"minPort") : depth0), depth0))
    + "\"\n                           data-max-port=\""
    + alias3(alias4((depth0 != null ? lookupProperty(depth0,"maxPort") : depth0), depth0))
    + "\"\n                           data-validation=\"checkStreamingPort\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias2).call(alias1,depth0,{"name":"tr","hash":{"key":"settings.wrong_streaming_port"},"data":data,"loc":{"start":{"line":53,"column":71},"end":{"line":53,"column":118}}}))
    + "</span>\n                        <span class=\"input-info-message success-message\"></span>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"row settings-list-row\"\n             data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n             data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div id=\"ttl-select\"></div>\n            </div>\n            <div class=\"col-xs-6\">\n            </div>\n        </div>\n\n        <div id=\"form-action-container\"></div>\n    </form>\n</div>\n";
},"useData":true});
