'use strict';

/**
 * Load some settings before bootstrap has been called.
 *
 * @param app
 */
module.exports.bootstrap = function CVBStartupScriptBootstrap(app) {
    require('./../services/models/CVB-View');
    app.getService('Model-View');

    require('./../services/framebox');
    app.getService('FrameBoxService');
};

module.exports.basic = function CVBStartupScript(rbac, app) {
    if (rbac.hasAccess('SourcesMenu', 'show')) {
        app.createComponent('menu', {
            type: 'SourcesMenu',
            container: '#sources-menu-container'
        });
    }

    if (rbac.hasAccess('MainMenu', 'show')) {
        app.createComponent('mainMenu', {
            type: 'MainMenu',
            container: '#main-menu-container'
        });
    }

    if (rbac.hasAccess('FileBrowser', 'show')) {
        app.createComponent('file-browser', {
            type: 'FileBrowserV3',
            container: '#file-browser-container'
        });
    }

    if (!this.authService.getIsCollab()) {
        app.createComponent('statusBar', {
            type: 'StatusBar',
            container: '#status-bar-container'
        });
    }

    // Create control center
    if (!this.authService.getIsCollab()
        && rbac.hasAccess('ControlCenter', 'show')) {
        app.createComponent('controlCenter', {
            type: 'ControlCenterV3',
            container: '#control-center'
        });
    }

    /**
     * Start splash screen.
     */
    app.createComponent('splashScreen', {
        type: 'SplashScreen',
        container: '#splash-screen'
    });

    app.emit('startup-script.finished');
};

/**
 * Start Matrix.
 *
 * @param rbac
 * @param app
 */
module.exports.matrix = function() {
    // No matrix on Cynap Videobar.
};
