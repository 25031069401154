'use strict';

const mountStates = require('./../../states').mountStates;

var splitMountAndFilename = function(target) {
    const parts = target.split('://');

    return {
        mount: parts[0],
        filename: parts[1]
    };
};

var splitParts = function(path) {
    let pathWithoutTrailingSlash;
    if (path.endsWith('/')) {
        pathWithoutTrailingSlash = path.substring(0, path.length - 1);
    } else {
        pathWithoutTrailingSlash = path;
    }

    return pathWithoutTrailingSlash.split('/');
};

var dirname = function(path) {
    const parts = splitParts(path);
    if (parts.length === 1) {
        return path;
    }

    const oneFolderUp = parts.slice(0, parts.length - 1);
    if (oneFolderUp.length === 1) {
        return '/';
    }

    return oneFolderUp.join('/');
};

var basename = function(path) {
    const parts = splitParts(path);
    if (parts.length === 1) {
        return path;
    }

    return parts[parts.length - 1];
};

const extension = function(path) {
    const filename = basename(path);
    const parts = filename.split('.');

    if (parts.length > 1) {
        return parts[parts.length - 1];
    } else {
        return undefined;
    }
};

const fileopsFactory = function({ deviceConnection, uriService }) {
    const uploadFileViaFTP = function({ source }) {
        return deviceConnection
            .send('sendFtpFile', {
                path: source
            });
    };

    const copyFileToUSB = function({ source }) {
        return deviceConnection
            .send('setCopyFileUsb', {
                path: source
            });
    };

    const uploadFileToNetdrive = function({ source, mount }) {
        return deviceConnection
            .send('setFileUpload', {
                mode: 'start',
                src: source,
                target: mount + ':///'
            });
    };

    const uploadFileToCloud = function({ source, mount }) {
        return deviceConnection
            .send('setCloudUpload', {
                path: source,
                service: mount,
                action: 'start'
            });
    };

    const uploadFile = function({ source, target }) {
        const { mount } = splitMountAndFilename(target.path);

        if (target.type === 'ftp') {
            return uploadFileViaFTP({ source });
        } else if (target.type === 'usb') {
            return copyFileToUSB({ source });
        } else if (target.type === 'netdrive') {
            return uploadFileToNetdrive({ source, mount });
        } else if (target.type === 'cloud') {
            return uploadFileToCloud({ source, mount });
        }
    };

    const deleteFile = function({ file }) {
        switch (file.type) {
            case 'dir':
                deviceConnection
                    .send('setDeleteFolder', {
                        foldername: file.path
                    });
                break;
            default:
                deviceConnection
                    .send('setDeleteFile', {
                        filename: file.path
                    });
                break;
        }
    };

    const restartUpload = function({ index, target }) {
        deviceConnection
            .send('setFileUpload', {
                mode: 'restartIndex',
                index: index,
                target: uriService.decode(target)
            });
    };

    const abortUpload = function({ index, target }) {
        deviceConnection
            .send('setFileUpload', {
                mode: 'abortIndex',
                index: index,
                target: uriService.decode(target)
            });
    };

    return {
        deleteFile,
        uploadFile,
        restartUpload,
        abortUpload
    };
};

const possibleUploadTargets = function(mountList) {
    return mountList.filter(function(mount) {
        const isPossibleTarget = mount.type === 'cloud' || mount.type === 'usb'
            || mount.type === 'netdrive' || mount.type === 'ftp';

        const isWritable = mount.perms ? mount.perms.includes('w') : true;

        return mount.status === mountStates.mounted && isWritable && isPossibleTarget;
    });
};

module.exports = {
    splitMountAndFilename: splitMountAndFilename,
    dirname: dirname,
    basename: basename,
    extension,
    fileopsFactory,
    possibleUploadTargets: possibleUploadTargets
};
