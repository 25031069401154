// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal.end_presentation_question_no_files",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":64}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"tr","hash":{"key":"modal.end_presentation_question"},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":65}}}))
    + "\n            ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row is-file-specific\">\n            <div class=\"col-xs-12 \">\n                <div class=\"btn-switch-container\">\n                    <input name=\"snapshots\"\n                           id=\"snapshots\"\n                           type=\"checkbox\"\n                           class=\"btn-switch focusable\"\n                           tabIndex=\"1\"\n                           checked />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n                <div class=\"end-presentation-label\">\n                    "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.end_presentation_snapshots",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":26,"column":60}}}))
    + "\n                </div>\n            </div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"supportsRecording") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":50,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"row is-file-specific\">\n            <div class=\"col-xs-12 \">\n                <div class=\"btn-switch-container\">\n                    <input name=\"whiteboards\"\n                           id=\"whiteboards\"\n                           type=\"checkbox\"\n                           class=\"btn-switch focusable\"\n                           tabIndex=\"1\"\n                           checked />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n                <div class=\"end-presentation-label\">\n                    "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.end_presentation_whiteboards",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":20},"end":{"line":66,"column":62}}}))
    + "\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row end-presentation-file-push-container is-file-specific file-push-component\">\n            <div class=\"col-xs-12 end-presentation-question\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.end_presentation_push_files",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":61},"end":{"line":72,"column":102}}}))
    + "</div>\n        </div>\n\n        <div class=\"row is-file-specific file-push-component\">\n            <div class=\"col-xs-12\">\n                <div class=\"btn-switch-container\">\n                    <input name=\"pushFiles\"\n                           id=\"push-files\"\n                           type=\"checkbox\"\n                           class=\"btn-switch focusable\"\n                           tabIndex=\"1\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n                <div class=\"end-presentation-label is-hidden\" id=\"file-push-pin\">\n                    <span class=\"icon-v2-cast\"></span>\n                    <span class=\"end-presentation-info-text pin text\"></span>\n                </div>\n            </div>\n        </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row is-file-specific\">\n                <div class=\"col-xs-12 \">\n                    <div class=\"btn-switch-container\">\n                        <input name=\"records\"\n                               id=\"records\"\n                               type=\"checkbox\"\n                               class=\"btn-switch focusable\"\n                               tabIndex=\"1\"\n                               checked />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                    <div class=\"end-presentation-label\">\n                        "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal.end_presentation_records",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":24},"end":{"line":46,"column":62}}}))
    + "\n                    </div>\n                </div>\n            </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"col-xs-12 end-presentation-info-container\">\n                <span class=\"icon-info3 end-presentation-info-icon\"></span>\n                <span class=\"end-presentation-info-text\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal.end_presentation_usb_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":99,"column":57},"end":{"line":99,"column":96}}}))
    + "</span>\n            </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "col-xs-4";
},"12":function(container,depth0,helpers,partials,data) {
    return "col-xs-6";
},"14":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                       "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal.end_presentation_shutdown",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":157,"column":23},"end":{"line":157,"column":63}}}))
    + "\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal.end_presentation_standby",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":160,"column":24},"end":{"line":160,"column":63}}}))
    + "\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal.end_presentation_power_saving",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":163,"column":24},"end":{"line":163,"column":68}}}))
    + "\n                    ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"end-presentation-form\">\n    <div class=\"row is-file-specific\">\n        <div class=\"col-xs-12 end-presentation-question\">\n"
    + ((stack1 = (lookupProperty(helpers,"if_or")||(depth0 && lookupProperty(depth0,"if_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPure") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"if_or","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":8,"column":22}}})) != null ? stack1 : "")
    + "</div>\n    </div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"unless_or")||(depth0 && lookupProperty(depth0,"unless_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPure") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"unless_or","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":93,"column":18}}})) != null ? stack1 : "")
    + "\n    <div class=\"row\">\n"
    + ((stack1 = (lookupProperty(helpers,"unless_or")||(depth0 && lookupProperty(depth0,"unless_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPure") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"unless_or","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":8},"end":{"line":101,"column":22}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"col-xs-12 end-presentation-info-upload-container\">\n        <div class=\"row\">\n            <span class=\"icon-info3 end-presentation-info-icon\"></span>\n            <span class=\"end-presentation-info-upload-text\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.end_presentation_upload_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":107,"column":60},"end":{"line":107,"column":102}}}))
    + "</span>\n        </div>\n        <div class=\"row\">\n            <div class=\"file-upload-state-pending\">\n                <div class=\"file-options-icon\">\n                    <div class=\"upload-progress\">\n                        <span class=\"upload-progress-end-percent\">0%</span>\n                        <span class=\"upload-progress-end-container\">\n                            <span class=\"upload-progress-current\" style=\"width: 0%\"></span>\n                        </span>\n                    </div>\n                </div>\n                <!--<span class=\"file-options-state-text\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"files.download_pending",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":119,"column":58},"end":{"line":119,"column":88}}}))
    + "</span>-->\n            </div>\n        </div>\n    </div>\n\n    <div id=\"end-presentation-push-file-info\" class=\"row is-hidden\">\n        <div class=\"col-xs-12 loader-container\">\n            <span class=\"loader small\"></span>\n            <span class=\"loader-text\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.end_presentation_push_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":127,"column":38},"end":{"line":127,"column":78}}}))
    + "</span>\n            <input id=\"file-push-skip\"\n                   name=\"file-push-skip\"\n                   type=\"button\"\n                   class=\"btn-form end-presentation-skip-btn focusable\"\n                   value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.skip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":132,"column":26},"end":{"line":132,"column":45}}}))
    + "\" />\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"screensaver") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":137,"column":20},"end":{"line":137,"column":70}}})) != null ? stack1 : "")
    + " end-presentation-button-container\">\n            <input id=\"end-presentation-new\"\n                   name=\"new\"\n                   type=\"button\"\n                   class=\"btn-form end-presentation-new focusable\"\n                   value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.end_presentation_new",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":142,"column":26},"end":{"line":142,"column":61}}}))
    + "\" />\n        </div>\n        <div class=\"col-xs-4 end-presentation-button-container "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"screensaver") : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":144,"column":63},"end":{"line":144,"column":103}}})) != null ? stack1 : "")
    + "\">\n            <input id=\"end-presentation-screensaver\"\n                   name=\"screensaver\"\n                   type=\"button\"\n                   class=\"btn-form end-presentation-screensaver focusable\"\n                   value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.screensaver",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":149,"column":26},"end":{"line":149,"column":52}}}))
    + "\" />\n        </div>\n        <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"screensaver") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":151,"column":20},"end":{"line":151,"column":70}}})) != null ? stack1 : "")
    + " end-presentation-button-container\">\n            <input id=\"end-presentation-standby\"\n                   name=\"standby\"\n                   type=\"button\"\n                   class=\"btn-form end-presentation-standby focusable\"\n                   value=\""
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"powerDownMode") : depth0),"shutdown",{"name":"if_equals","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":156,"column":26},"end":{"line":158,"column":34}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"powerDownMode") : depth0),"suspend",{"name":"if_equals","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":159,"column":20},"end":{"line":161,"column":34}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"powerDownMode") : depth0),"power_saving",{"name":"if_equals","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":162,"column":20},"end":{"line":164,"column":34}}})) != null ? stack1 : "")
    + "\"/>\n        </div>\n    </div>\n</form>\n";
},"useData":true});
