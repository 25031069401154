'use strict';

const app = require('../app');
const platform = require('../../platform/platform');
const StateMachine = require('./../state-machine');
const rbac = require('./../../rbac/rbac.js');
const store = require('../store/store');

const annotationStates = {
    started: 'started',
    stopped: 'stopped',
    paused: 'paused'
};

const collabStates = {
    active: 'active',
    inactive: 'inactive'
};

const magicPenStates = {
    active: 'active',
    inactive: 'inactive'
};

const matrixAnnotationStates = {
    disable: 'disable',
    enable: 'enable',
    pause: 'pause'
};

const planeIndices = {
    annotation: '-1',
    matrixAnnotation: '-2',
    magicPen: 255
};

app.service('AnnotationService', function() {
    return {
        /**
         * @method initialize
         */
        initialize: function() {
            this.planeIndex = planeIndices.annotation;
            this.storage = app.getService('DataStorage');
            this.stationService = app.getService('StationService');
            this.frontendSettings = app.getService('FrontendSettings');
            this.deviceService = app.getService('DeviceService');
            this.autoShowAnnotationMenu = true;
            this.states = annotationStates;
            this.state = new StateMachine({
                context: this,
                state: annotationStates.stopped,
                states: annotationStates
            });

            this.globalState = new StateMachine({
                context: this,
                state: null,
                states: annotationStates
            });

            this.collabState = new StateMachine({
                states: collabStates,
                state: collabStates.inactive
            });

            this.magicPenState = new StateMachine({
                context: this,
                states: magicPenStates,
                state: magicPenStates.inactive
            });

            this.matrixAnnotationState = new StateMachine({
                context: this,
                state: matrixAnnotationStates.disable,
                states: matrixAnnotationStates
            });

            this.globalState.addOnStateChanged(function(newState) {
                // RELEASE-730: Sync with the global state, remove if you want to open annotation only on this device.
                if (this.state.getState() !== newState
                    && !(this.stationService.getLockStatus() && newState === annotationStates.started)) {
                    this.state.changeState(newState);
                }

                app.emit('annotation.state.changed', {
                    state: newState
                });
            });

            this.authService = app.getService('AuthenticationService');

            this.storage.set('annotation.state', annotationStates.stopped);

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.updateHandler();
                }.bind(this));

            this.bindEvents();

            this.addStateTransitions();
            this.updateHandler();
        },

        /**
         * @method bindEvents
         */
        bindEvents: function() {
            app.on('main-loop.update', this.updateHandler.bind(this));
            app.on('main-loop.update.annotation', this.updateHandler.bind(this));
            app.on('station-status.lock', this.stopAnnotation.bind(this));
            app.on('collaboration-user.update', function(user) {
                this.collaborationUser = user;
            }.bind(this));
        },

        /**
         * @method updateHandler
         */
        updateHandler: function() {
            this.frontendSettings
                .getSettings([
                    'autoShowAnnotationMenu'
                ])
                .then(function(autoShowAnnotationMenu) {
                    this.autoShowAnnotationMenu = autoShowAnnotationMenu;
                }.bind(this));

            this.deviceConnection
                .send([
                    {
                        command: 'getAnnotationState'
                    },
                    {
                        command: 'getMatrixMasterAnnotationActive'
                    }
                ]).then(function(annotationState, matrixAnnotationState) {
                    var state = null;

                    if (annotationState.state === 'enable' || annotationState.state === 'matrixEnable') {
                        state = annotationStates.started;
                    } else if (annotationState.state === 'pause') {
                        state = annotationStates.paused;
                    } else {
                        state = annotationStates.stopped;
                    }

                    if (!state || state !== this.globalState.getState()) {
                        this.globalState.changeState(state);
                    }

                    if (state === annotationStates.started && !app.getComponent('Annotation')
                        && !this.stationService.getLockStatus()) {
                        this.startAnnotation();
                    }

                    if (this.matrixAnnotationState.getState() !== matrixAnnotationState.status) {
                        this.matrixAnnotationState.changeState(matrixAnnotationState.status);
                    }
                }.bind(this));

            if (this.authService.getIsModerator() || this.authService.getIsAdmin()) {
                this.deviceConnection
                    .send('getMatrixMasterAnnotationEnabled')
                    .then(function(matrixMasterAnnotationCollab) {
                        if (this.matrixMasterAnnotation !== matrixMasterAnnotationCollab.enabled) {
                            this.matrixMasterAnnotation = matrixMasterAnnotationCollab.enabled;
                        }
                    }.bind(this));
            }

            if (!app.getService('DeviceService').isCboxProDualProjection()) {
                // In dual-projection mode there is no magic pen
                this.updateMagicPen();
            }
        },

        /**
         * Update handler for Magic Pen
         */
        updateMagicPen: function() {
            this.deviceConnection
                .send([
                    { command: 'getAnnotationMagicPenSettings' },
                    { command: 'getAnnotationMagicPenStatus' }
                ]).then(function(magicPenSettings, magicPenStatus) {
                    let magicPenChanges = false;

                    if (this.magicPenRemoteEnabled !== magicPenSettings.remoteUserEnabled) {
                        this.magicPenRemoteEnabled = magicPenSettings.remoteUserEnabled;

                        magicPenChanges = true;
                    }

                    if (this.magicPenEnabled !== magicPenSettings.enabled) {
                        this.magicPenEnabled = magicPenSettings.enabled;

                        magicPenChanges = true;
                    }

                    if (this.magicPenState.getState() !== magicPenStatus.status) {
                        this.magicPenState.changeState(magicPenStatus.status);
                    }

                    if (magicPenChanges) {
                        if (this.magicPenEnabled && (!platform.checks.isRemote || this.magicPenRemoteEnabled)) {
                            app.removeComponent('magicPenMenu');
                            app.createComponent('magicPenMenu', {
                                type: 'MagicPenMenu',
                                container: '#magic-pen-menu'
                            });
                        } else {
                            app.removeComponent('magicPenMenu');
                        }
                    }
                }.bind(this));
        },

        /**
         * @method stopAnnotation
         */
        stopAnnotation: function(destroySession) {
            destroySession = (typeof destroySession === 'undefined') ? true : destroySession;

            this.state.changeState(annotationStates.stopped);

            if (destroySession) {
                this.storage.set('annotation.state', annotationStates.stopped);
            }
        },

        /**
         * Set state to started on state.
         */
        startAnnotation: function() {
            if (this.state.getState() !== annotationStates.started) {
                this.state.changeState(annotationStates.started);
            }
        },

        /**
         * Start or Stop Annotation.
         */
        toggleState: function() {
            if (this.state.getState() === annotationStates.started) {
                this.stopAnnotation();
            } else {
                this.startAnnotation();
            }

            app.emit('main-loop.fast.start', {
                id: 'annotation'
            });
        },

        /**
         * Send properties once to activate magic pen and create draw component.
         */
        startMagicPen: function() {
            if (platform.checks.isRemote && !this.magicPenRemoteEnabled) {
                return;
            }

            this.magicPenStarted = true;
            this.planeIndex = planeIndices.magicPen;

            app.emit('main-loop.fast.start', {
                id: 'annotation'
            });

            app.removeComponent('magicPen');
            app.createComponent('magicPen', {
                type: 'MagicPen',
                container: '#magic-pen-draw-container'
            });
        },

        /**
         * @method pauseAnnotation
         */
        pauseAnnotation: function(destroySession) {
            destroySession = (typeof destroySession === 'undefined') ? true : destroySession;

            this.state.changeState(annotationStates.paused);

            if (destroySession) {
                this.storage.set('annotation.state', annotationStates.paused);
            }
        },

        /**
         * Stop annotation.
         *
         * @param {Boolean} takeSnapshot: take snapshot before stopping annotation
         * @param {Boolean} pause: true - pause annotation, false - stop annotation and disconnect user
         * @param {Boolean} finalSnapshot: pause annotation, replace live content with snapshot
         *                                 and close edit mode (dual-projection only)
         * @returns {*}
         */
        stopAnnotationExternal: function(takeSnapshot = false, pause = false, finalSnapshot = false) {
            let actions = [];

            if (finalSnapshot) {
                actions = ['finalSnapshot'];
            } else {
                actions = pause ? ['pause'] : ['stop', 'disconnect'];

                if (takeSnapshot) {
                    actions.unshift('snapshot');
                }
            }

            const cmds = actions.map(a => {
                return {
                    command: 'setAnnotationAction',
                    data: {
                        action: a,
                        planeIndex: this.planeIndex
                    }
                };
            });

            return this.deviceConnection.send(cmds)
                .then(function() {
                    this.stopAnnotation();
                    this.clearStoredSettings(this.planeIndex);
                    this.storage.set('annotation.state', this.states.stopped);

                    app.emit('main-loop.fast.start', {
                        id: 'annotation'
                    });
                }.bind(this));
        },

        /**
         * @method addStateTransitions
         */
        addStateTransitions: function() {
            this.state.addTransitions({
                '> started': function() {
                    app.emit('overlay.close');
                    app.emit('modal.close');
                    app.emit('backdrop.hide');
                    app.emit('annotation.action.start');

                    this.storage.set('annotation.menu.state', annotationStates.started);

                    if (rbac.hasAccess('Annotation', 'show', true)) {
                        app.createComponent('Annotation', {
                            type: 'Annotation',
                            container: '#submenu-container'
                        });
                    } else if (rbac.hasAccess('ControlScreen', 'show', true)) {
                        // Control screen instance does not have the Annotation component, therefore handle enable and connect here
                        this.deviceConnection
                            .send([
                                {
                                    command: 'setAnnotationAction',
                                    data: {
                                        action: 'enableAnnotation',
                                        planeIndex: this.planeIndex
                                    }
                                },
                                {
                                    command: 'setAnnotationAction',
                                    data: {
                                        action: 'connect',
                                        planeIndex: this.planeIndex
                                    }
                                }
                            ]);
                    }
                },
                '> stopped': function() {
                    if (rbac.hasAccess('Annotation', 'show', true)) {
                        app.removeComponent('Annotation', {
                            fadeOut: true
                        });
                    }

                    this.storage.set('annotation.menu.state', annotationStates.stopped);
                    app.emit('annotation.action.stop');
                },
                '> paused': function() {
                    if (rbac.hasAccess('Annotation', 'show', true)) {
                        app.removeComponent('Annotation', {
                            fadeOut: true
                        });
                    }

                    this.storage.set('annotation.menu.state', annotationStates.paused);
                    app.emit('annotation.action.pause');
                }
            });

            this.globalState.addTransitions({
                '> started': function() {
                    app.emit('status-widget.item.append', {
                        id: 'annotation',
                        accessKey: 'Annotation',
                        options: {
                            icon: 'icon-v3-pen',
                            clickable: false,
                            state: 'annotate'
                        }
                    });

                    if (this.authService.getIsCollab() && this.state.getState() === annotationStates.stopped) {
                        this.toggleState();
                    }
                },
                '> stopped': function() {
                    app.emit('status-widget.item.remove', {
                        id: 'annotation'
                    });
                },
                '> paused': function() {
                    app.emit('status-widget.item.remove', {
                        id: 'annotation'
                    });
                }
            });

            this.collabState.addTransitions({
                '> active': function() {
                    app.emit('collaboration.state.change', collabStates.active);
                },
                '> inactive': function() {
                    app.emit('collaboration.state.change', collabStates.inactive);
                }
            });

            this.matrixAnnotationState.addTransitions({
                '> disable': function() {
                    this.stopAnnotationExternal();

                    this.stationService.handlePushStatus();
                    this.planeIndex = planeIndices.annotation;
                },
                '> enable': function() {
                    this.planeIndex = planeIndices.matrixAnnotation;
                    this.toggleState();
                },
                'enable > pause': function() {
                    this.stopAnnotationExternal();
                    this.stationService.handlePushStatus();
                }
            });

            this.magicPenState.addTransitions({
                '> inactive': function() {
                    this.planeIndex = planeIndices.annotation;

                    app.removeComponent('magicPen');

                    app.emit('magic-pen.state.inactive');
                    app.emit('framebox.bind-events');
                }
            });

            this.magicPenState.addPostTransitions({
                '> active': function() {
                    app.emit('magic-pen.state.active');

                    if (platform.checks.isRemote && !this.magicPenRemoteEnabled) {
                        return;
                    }

                    app.emit('activities.hide');
                },

                '> inactive': function() {
                    this.magicPenStarted = false;
                    app.emit('activities.show');
                }
            });
        },

        sendSettings: function(settings) {
            return this.deviceConnection
                .send('setAnnotationSettings', {
                    planeIndex: this.planeIndex,
                    ...settings
                });
        },

        draw: function(coordinates) {
            return this.deviceConnection
                .send('setAnnotationPaint', {
                    painting: 'continued',
                    planeIndex: this.planeIndex,
                    positionX: Math.round(coordinates.x),
                    positionY: Math.round(coordinates.y)
                });
        },

        stopDrawing: function(coordinates) {
            return this.deviceConnection
                .send('setAnnotationPaint', {
                    painting: 'finished',
                    planeIndex: this.planeIndex,
                    positionX: Math.round(coordinates.x),
                    positionY: Math.round(coordinates.y)
                });
        },

        takeSnapshot: function() {
            return this.deviceConnection
                .send('setAnnotationAction', {
                    action: 'snapshot',
                    planeIndex: this.planeIndex
                }).then(function() {
                    app.emit('main-loop.fast.start', {
                        id: 'osd'
                    });
                }.bind(this));
        },

        clearAll: function() {
            return this.deviceConnection
                .send('setAnnotationAction', {
                    action: 'cleanAll',
                    planeIndex: this.planeIndex
                });
        },

        undo: function() {
            return this.deviceConnection
                .send('setAnnotationAction', {
                    action: 'undo',
                    planeIndex: this.planeIndex
                });
        },

        redo: function() {
            return this.deviceConnection
                .send('setAnnotationAction', {
                    action: 'redo',
                    planeIndex: this.planeIndex
                });
        },

        /**
         * @method getState
         */
        getState: function() {
            return this.state.getState();
        },

        isActive: function() {
            return annotationStates.started === this.globalState.getState();
        },

        /**
         * In dual-projection mode we need to check if the annotation is on the output of the current browser instance.
         */
        isActiveOnOutput: function() {
            const editOutput = store.getters['controlScreen/getOutputsInEdit'][0];

            return this.isActive() && (!this.deviceService.isCboxProDualProjection()
                || (platform.checks.isCboxProjectionHdmi1 && editOutput?.outputPort === 'hdmi1'
                    || platform.checks.isCboxProjectionHdmi2 && editOutput?.outputPort === 'hdmi2'));
        },

        isCollaborationUserActive: function() {
            return this.collaborationUser?.visible === 1 && this.state.getState() !== annotationStates.stopped;
        },

        getPlaneIndex: function() {
            return this.planeIndex;
        },

        /**
         * @method clearStoredSettings
         * Clears all drawing settings for the given index
         *
         * @param {int} index
         */
        clearStoredSettings: function(index) {
            this.storage.set('drawSettings-' + index, {});
        },

        /**
         * @method clearAllStoredSettings
         */
        clearAllStoredSettings: function() {
            this.clearStoredSettings(this.planeIndex);
        },

        hasCloseAccess: function() {
            return rbac.hasAccess('Annotation', 'close');
        },

        isCollaboration: function() {
            return this.collabState.getState() === collabStates.active;
        },

        isMatrixAnnotation: function() {
            return this.planeIndex === planeIndices.matrixAnnotation;
        },

        isMatrixMasterAnnotation: function() {
            return this.matrixMasterAnnotation;
        },

        /**
         * Check if Magic Pen function is generally enabled.
         * @returns {*}
         */
        isMagicPenEnabled: function() {
            return this.magicPenEnabled;
        },

        /**
         * Check if Magic Pen is enabled on remote browsers.
         */
        isMagicPenRemoteEnabled: function() {
            return this.magicPenEnabled && this.magicPenRemoteEnabled;
        },

        /**
         * Check if Magic Pen is active.
         */
        isMagicPenActive: function() {
            return this.magicPenState.getState() === magicPenStates.active;
        },

        /**
         * Check if magic pen is started (on this instance).
         */
        isMagicPenStarted: function() {
            return this.magicPenStarted;
        }
    };
});
