'use strict';

var app = require('../../app');
var matrixFlapTpl = require('./push-indicator.hbs');

/**
 * Push Indicator.
 */
app.component('PushIndicator', {
    template: matrixFlapTpl,

    serialize: function() {
        return {
            name: this.options.name,
            coaching: this.options.pushIndex === 253
        };
    }
});
