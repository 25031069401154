// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"splash-screen-viewer\" class=\"splash-screen-viewer is-hidden\">\n    <div class=\"splash-screen-container first\">\n        <div class=\"splash-screen-container-inner\">\n            <div id=\"splash-screen-headline-other\" class=\"splash-screen-headline-group\">\n                <span class=\"splash-screen-headline big\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.other_devices",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":57},"end":{"line":5,"column":93}}}))
    + "</span>\n                <span class=\"splash-screen-subtitle\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.apple_android_windows",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":53},"end":{"line":6,"column":97}}}))
    + "</span>\n            </div>\n\n            <div id=\"splash-screen-headline-connect\" class=\"splash-screen-headline-group\">\n                <span class=\"splash-screen-headline-count\">1</span>\n                <span class=\"splash-screen-headline\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.connect_to_network",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":53},"end":{"line":11,"column":94}}}))
    + "</span>\n            </div>\n\n            <div class=\"splash-screen-wlan-container\">\n                <div id=\"splash-screen-wlan-qr-code\" class=\"splash-screen-wlan-type-container\">\n                    <span class=\"splash-screen-subtitle\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.scan_qr_code",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":57},"end":{"line":16,"column":92}}}))
    + "</span>\n                    <div id=\"splash-screen-qr-code-container\" class=\"splash-screen-wlan-type-info\">\n                        <div class=\"splash-screen-qr-code-container\">\n                            <div id=\"qr-code-wifi\"></div>\n                        </div>\n                    </div>\n                </div>\n\n                <div id=\"splash-screen-wlan-spacer\" class=\"splash-screen-wlan-type-spacer\">\n                    <div class=\"splash-screen-wlan-type-spacer-top\"></div>\n                    <div class=\"splash-screen-wlan-type-spacer-or\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.or",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":67},"end":{"line":26,"column":92}}}))
    + "</div>\n                    <div class=\"splash-screen-wlan-type-spacer-top\"></div>\n                </div>\n\n                <div id=\"splash-screen-wlan-container\" class=\"splash-screen-wlan-type-container\">\n                    <span class=\"splash-screen-subtitle\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.connect_manually",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":57},"end":{"line":31,"column":96}}}))
    + "</span>\n\n                    <div class=\"splash-screen-wlan-type-info is-wlan-data\">\n                        <div class=\"splash-screen-content-headline-group\">\n                        <span class=\"splash-screen-content-headline icon\">\n                            <img src=\"/images/wifi-splash.svg\" alt=\"\">\n                        </span>\n                            <span class=\"splash-screen-content-headline\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.wifi_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":73},"end":{"line":38,"column":105}}}))
    + "</span>\n                        </div>\n                        <dynamic-font class=\"splash-screen-content-value has-bottom-space\" data-view-path=\"wlan.ssid\">\n                        </dynamic-font>\n\n                        <div id=\"splash-screen-wlan-password\">\n                            <div class=\"splash-screen-content-headline-group\">\n                                <span class=\"splash-screen-content-headline icon icon-mmt-logout\"></span>\n                                <span class=\"splash-screen-content-headline\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.passwort",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":77},"end":{"line":46,"column":108}}}))
    + "</span>\n                            </div>\n                            <dynamic-font class=\"splash-screen-content-value\" data-view-path=\"wlan.password\">\n                            </dynamic-font>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"splash-screen-container-inner has-margin-top\">\n            <div id=\"splash-screen-headline-share\" class=\"splash-screent-headline-group\">\n                <span class=\"splash-screen-headline-count\">2</span>\n                <span class=\"splash-screen-headline\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.share_screen",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":53},"end":{"line":59,"column":88}}}))
    + "</span>\n            </div>\n\n            <div class=\"splash-screen-connection\">\n                <div class=\"splash-screen-connection-container\" data-show-path=\"mirror.airplayState\">\n                    <span class=\"splash-screen-connection-icon icon-v2-airplay\"></span>\n                    <span class=\"splash-screen-connection-headline\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.apple",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":68},"end":{"line":65,"column":96}}}))
    + "</span>\n                    <span class=\"splash-screen-connection-description\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.airplay",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":71},"end":{"line":66,"column":101}}}))
    + "</span>\n                </div>\n                <div class=\"splash-screen-connection-container\" data-show-path=\"mirror.chromecastState\">\n                    <span class=\"splash-screen-connection-icon icon-v2-chromecast\"></span>\n                    <span class=\"splash-screen-connection-headline\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.android",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":68},"end":{"line":70,"column":98}}}))
    + "</span>\n                    <span class=\"splash-screen-connection-description\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.chromecast",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":71},"end":{"line":71,"column":104}}}))
    + "</span>\n                </div>\n                <div class=\"splash-screen-connection-container\" data-show-path=\"mirror.windowsState.both\">\n                    <span class=\"splash-screen-connection-icon icon-v2-miracast\"></span>\n                    <span class=\"splash-screen-connection-headline\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.windows",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":68},"end":{"line":75,"column":98}}}))
    + "</span>\n                    <span class=\"splash-screen-connection-description\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.miracast_vcast",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":76,"column":71},"end":{"line":76,"column":108}}}))
    + "</span>\n                </div>\n                <div class=\"splash-screen-connection-container\" data-show-path=\"mirror.windowsState.miracastMiceState\">\n                    <span class=\"splash-screen-connection-icon icon-v2-miracast\"></span>\n                    <span class=\"splash-screen-connection-headline\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.windows",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":80,"column":68},"end":{"line":80,"column":98}}}))
    + "</span>\n                    <span class=\"splash-screen-connection-description\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.miracast",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":81,"column":71},"end":{"line":81,"column":102}}}))
    + "</span>\n                </div>\n                <div class=\"splash-screen-connection-container\" data-show-path=\"mirror.windowsState.vconnectState\">\n                    <span class=\"splash-screen-connection-icon icon-v2-cast\"></span>\n                    <span class=\"splash-screen-connection-headline\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.windows",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":68},"end":{"line":85,"column":98}}}))
    + "</span>\n                    <span class=\"splash-screen-connection-description\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.vcast",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":86,"column":71},"end":{"line":86,"column":99}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class=\"splash-screen-container second\" data-show-path=\"mirror.windowsState.miracastState\">\n        <div id=\"splash-screen-headline-windows\" class=\"splash-screen-headline-group\">\n            <span class=\"splash-screen-headline big\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.windows",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":93,"column":53},"end":{"line":93,"column":83}}}))
    + "</span>\n            <span class=\"splash-screen-subtitle\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.windows_8_1_later",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":94,"column":49},"end":{"line":94,"column":89}}}))
    + "</span>\n        </div>\n        <div class=\"splash-screen-container-inner has-margin-top\">\n            <div id=\"splash-screen-headline-share\" class=\"splash-screent-headline-group\">\n                <span class=\"splash-screen-headline-count\">1</span>\n                <span class=\"splash-screen-headline\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.share_screen",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":99,"column":53},"end":{"line":99,"column":88}}}))
    + "</span>\n            </div>\n\n            <div class=\"splash-screen-connection\">\n                <div class=\"splash-screen-connection-container\" data-show-path=\"mirror.windowsState.miracastState\">\n                    <span class=\"splash-screen-connection-icon icon-v2-miracast\"></span>\n                    <span class=\"splash-screen-description-container\">\n                        <span class=\"splash-screen-connection-headline\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.windows",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":106,"column":72},"end":{"line":106,"column":102}}}))
    + "</span>\n                        <span class=\"splash-screen-connection-description\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"splash_screen.miracast",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":107,"column":75},"end":{"line":107,"column":106}}}))
    + "</span>\n                    </span>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});
