'use strict';

var app = require('../../app');
var navBarTpl = require('./navigation-bar.hbs');
var _ = require('lodash');
var $ = require('jquery');

var visualizerModes = require('./../../states').visualizerModes;

app.component('NavigationBar', {
    template: navBarTpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.items = this.options.items;
        this.deviceService = this.getService('DeviceService');
        this.remote = this.getService('RemoteService');
        this.overlayHandlerService = this.getService('OverlayHandlerService');
        this.features = null;
        this.navArea = null;

        if (!this.deviceService.isCboxPureOrPro() && !this.deviceService.isCboxPureMini()) {
            this.visualizer = this.getService('VisualizerService');
        }

        return $.when(this.loadFeatures()).done(function() {
            this.checkItemHandler();
        }.bind(this));
    },

    serialize: function() {
        this.updateNavigationItems(this.items);

        return {
            items: this.items
        };
    },

    /**
     * Find ethernet-settings and fix the title for cynap pure.
     *
     * @param {Object[]} items
     */
    updateNavigationItems: function(items) {
        items.forEach(function(item) {
            item.titleKey = app.getService('Model-View')[item.specTitleKey] || item.titleKey;
            item.empty = app.getService('Model-View')[item.specEmpty] || item.empty;

            if (item.subitems && 0 < item.subitems.length) {
                this.updateNavigationItems(item.subitems);
            }
        }.bind(this));
    },

    postPlaceAt: function() {
        this.bindDOMEvents();
        this.bindEvents();

        this.$el.find('.nav-item').first().addClass('opened');
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.nav-item:not(.sub-item)', this.checkFolding.bind(this));
        this.$el.on(
            'click', '.nav-item:not(.is-disabled):not(.unselectable)',
            _.debounce(
                this.navItemClicked.bind(this),
                500,
                true
            )
        );
    },

    bindEvents: function() {
        this.on('visualizer.connected', this.checkItem.bind(this, { id: 'visualizer-settings' }));
        this.on('visualizer.disconnected', this.checkItem.bind(this, { id: 'visualizer-settings' }));
        this.on('overlay.opened-end', this.checkFocus.bind(this));
        this.on('navigation-bar.open', this.onOpenNavigation.bind(this));
        this.on('navigation-bar.update.features', this.onUpdateFeatures.bind(this));
    },

    /**
     * Handle item selections on a click event.
     *
     * @param {jQuery.Event} event
     */
    navItemClicked: function(event) {
        var $item = this.$(event.currentTarget);

        this.selectItem($item.data('id'));
    },

    /**
     * Called by custom-events.
     *
     * @param {Object} options
     */
    onOpenNavigation: function(options) {
        this.navArea = options.navArea;

        if (options.autoFoldId) {
            this.autoFold(options.autoFoldId, options.id);
        }

        this.selectItem(options.id, options.configs);
    },

    /**
     * Selects the navigation-item by given id.
     *
     * @param {Number} id
     */
    selectItem: function(id, configs) {
        var $item = this.$el.find('[data-id="' + id + '"]');

        // Already opened - only focus
        if (this.overlayHandlerService.getOpenOverlayId() === id) {
            if (($item.parent()).attr('id') === 'sub-items-container') {
                $item.parent()
                    .show()
                    .removeClass('hidden');
            }

            this.$el.find('.nav-item')
                .attr('data-nav-history-back-focus', 'false')
                .removeClass('opened');

            $item
                .addClass('opened')
                .attr('data-nav-history-back-focus', 'true');

            return;
        }

        this.emit('overlay.open', {
            id: id,
            navArea: this.navArea,
            extendedConfigs: 'settings-list',
            onOpen: function() {
                this.$el.find('.nav-item')
                    .attr('data-nav-history-back-focus', 'false')
                    .removeClass('opened');

                $item
                    .addClass('opened')
                    .attr('data-nav-history-back-focus', 'true');
            }.bind(this),
            ...configs
        });
    },

    /**
     * Check focus change on overlay opening ended.
     */
    checkFocus: function() {
        this.emit('overlay.remote.focus', null, this.options.selected, true);

        // Focus item
        if (this.options.selected) {
            this.selectItem(this.options.selected);
        }
    },

    /**
     * Expand all submenu items.
     *
     * @param id
     */
    autoFold: function(id) {
        var $item = this.$el.find('[data-id="' + id + '"]');
        var $subItems = this.$el.find('#sub-items-container[data-id="' + id + '"]');

        if ($item.hasClass('folded')) {
            $subItems
                .stop()
                .slideDown(300, function() {
                    $subItems.removeClass('hidden');
                }.bind(this));

            $item.removeClass('folded');
            $item.find('.icon-arrow-options').removeClass('folded');
        }
    },

    /**
     * Check folding of group items.
     *
     * @param event Current event
     */
    checkFolding: function(event) {
        var $item = this.$(this.$el.find(event.currentTarget));
        var id = $item.data('id');
        var $subItems = this.$el.find('#sub-items-container[data-id="' + id + '"]');
        var focusItem;

        if ($item.hasClass('is-disabled') || $subItems.children().length < 1) {
            // Do nothing
        } else if (!$item.hasClass('folded')) {
            $subItems
                .stop()
                .slideUp(300, function() {
                    $subItems.addClass('hidden');
                }.bind(this));

            $item.addClass('folded');
            $item.find('.icon-arrow-options').addClass('folded');
        } else {
            $subItems
                .stop()
                .slideDown(300, function() {
                    $subItems.removeClass('hidden');
                }.bind(this));

            $item.removeClass('folded');
            $item.find('.icon-arrow-options').removeClass('folded');

            if ($item.hasClass('unselectable')) {
                focusItem = $subItems.children().first();

                this.navItemClicked({
                    currentTarget: focusItem
                });
            }
        }
    },

    /**
     * Update menu items if feature pack is added.
     */
    onUpdateFeatures: function() {
        $.when(this.loadFeatures()).done(function() {
            this.checkItemHandler();
        }.bind(this));
    },

    /**
     * Load installed license features.
     */
    loadFeatures: function() {
        var dfd = $.Deferred();

        this.deviceConnection
            .send('getLicenseFeatures')
            .then(function(features) {
                this.features = features;

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Check which items should be visible/hidden or enabled/disabled.
     */
    checkItemHandler: function() {
        _.each(this.items, this.isItemVisible.bind(this));
    },

    /**
     * Check if this navigation item is visible on that model.
     *
     * @param item
     */
    isItemVisible: function(item) {
        var isVisible = true;

        // Hide on CB1
        // TODO: isCbox should only return true for CB1, but not for CBR
        if (false === item.shownOnCB1 && this.deviceService.isCbox()) {
            isVisible = false;
        }

        // Hide on CBR
        if (false === item.shownOnCBR && this.deviceService.isCboxPro()) {
            isVisible = false;
        }

        // Hide on CBC
        if (false === item.shownOnCBC && this.deviceService.isCboxCore()) {
            isVisible = false;
        }

        // Hide on CCP
        if (false === item.shownOnCCP && this.deviceService.isCboxCorePro()) {
            isVisible = false;
        }

        // Hide on CBP
        if (false === item.shownOnCBP && this.deviceService.isCboxPure()) {
            isVisible = false;
        }

        // Hide on CPP
        if (false === item.shownOnCPP && this.deviceService.isCboxPurePro()) {
            isVisible = false;
        }

        // Hide on PureReceiver
        if (false === item.shownOnCPR && this.deviceService.isCboxPureReceiver()) {
            isVisible = false;
        }

        // Hide on CPM
        if (false === item.shownOnCPM && this.deviceService.isCboxPureMini()) {
            isVisible = false;
        }

        if (isVisible) {
            this.checkItem(item);
        } else {
            this.hideItem(item.id);
        }

        if (item.subitems) {
            _.each(item.subitems, this.isItemVisible.bind(this));
        }
    },

    /**
     * Call item check handler for current item.
     *
     * @param item Current item
     */
    checkItem: function(item) {
        var handler = this.itemCheckHandlers[item.id] || this.$.noop;

        handler.call(this, item);
    },

    /**
     * Handlers to check which items should be visible/hidden or enabled/disabled.
     */
    itemCheckHandlers: {
        'visualizer-settings': function(item) {
            if (this.visualizer && this.visualizer.isConnected()
                && this.visualizer.powerState.currentState
                && this.visualizer.mode.currentState !== visualizerModes.basicFw) {
                this.enableItem(item.id);
                this.enableItem('visualizer-color-settings');
                this.enableItem('visualizer-exposure-settings');
            } else {
                this.disableItem(item.id);
                this.disableItem('visualizer-color-settings');
                this.disableItem('visualizer-exposure-settings');
            }
        },

        'office365-settings': function(item) {
            if (this.features.office) {
                this.showItem(item.id);
            } else {
                this.hideItem(item.id);
            }
        },

        'matrix-settings': function(item) {
            if (this.features.matrix) {
                this.showItem(item.id);
            } else {
                this.hideItem(item.id);
            }
        },

        'vmeeting-settings': function(item) {
            if (this.features.vmeeting) {
                this.showItem(item.id);
            } else {
                this.hideItem(item.id);
            }
        },

        'record-stream-settings': function(item) {
            if (this.deviceService.isCboxCorePro() && !this.features.capture) {
                this.hideItem(item.id);
            } else {
                this.showItem(item.id);
            }
        },

        'record-settings': function(item) {
            if (this.deviceService.isCboxCorePro() && !this.features.capture) {
                this.hideItem(item.id);
            } else {
                this.showItem(item.id);
            }
        },

        'streaming-settings': function(item) {
            if (this.deviceService.isCboxCorePro() && !this.features.capture) {
                this.hideItem(item.id);
            } else {
                this.showItem(item.id);
            }
        }
    },

    /**
     * Show item in navigation bar.
     *
     * @param id Item ID
     */
    showItem: function(id) {
        var $el = this.$el.find('.nav-item[data-id="' + id + '"]');
        $el.removeClass('hidden');
    },

    /**
     * Hide item in navigation bar.
     *
     * @param id Item ID
     */
    hideItem: function(id) {
        var $el = this.$el.find('.nav-item[data-id="' + id + '"]');
        $el.addClass('hidden');
    },

    /**
     * Enable Item in navigation bar.
     *
     * @param id Item ID
     */
    enableItem: function(id) {
        var $el = this.$el.find('.nav-item[data-id="' + id + '"]');
        $el.removeClass('is-disabled');
    },

    /**
     * Disable Item on navigation bar.
     *
     * @param id Item ID
     */
    disableItem: function(id) {
        var $el = this.$el.find('.nav-item[data-id="' + id + '"]');
        $el.addClass('is-disabled');
    }
});
