// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"overlay-center-content\" id=\"mirroring\">\n    <div class=\"mirroring-search\">\n        <div class=\"mirroring-search-text\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"mirroring.search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":43},"end":{"line":3,"column":67}}}))
    + "</div>\n        <div id=\"loader\"></div>\n    </div>\n</div>\n";
},"useData":true});
