'use strict';

var BaseView = require('./view');
var tpl = require('./state-effect-view.hbs');

/**
 * @class StateEffectView
 * @param app
 * @param configs
 * @constructor
 */
function StateEffectView(app, configs) {
    BaseView.call(this, app, configs);

    this.template = tpl;
    this.componentContainer = '#modal-state-effect-view';
    this.useBackdrop = false;
}

StateEffectView.prototype = new BaseView();

/**
 * @method postRender
 */
StateEffectView.prototype.postRender = function() {
    this.$el
        .find('.modal-state-effect')
        .addClass('modal-state-effect-play');
};

/**
 * @method animateOpen
 * @uber BaseView.animateOpen
 * @param {function} onComplete
 */
StateEffectView.prototype.animateOpen = function(onComplete) {
    this.$el
        .velocity('stop')
        .velocity('fadeIn', {
            duration: 50,
            complete: onComplete
        });
};

module.exports = StateEffectView;
