module.exports={
    "modifier":
    {
        "uppercase": 42
    },

    "modifier_mobile":
    {
        "&#64;": 42, //@
        "&#126;": 42, //~
        "&#33;": 42, //!
        "&#35;": 42, //#
        "&#36;": 42, //$
        "&#37;": 42, //%
        "&#94;": 42, //^
        "&#38;": 42, //&
        "&#42;": 42, //*
        "&#40;": 42, //(
        "&#41;": 42, //)
        "&#95;": 42, //_
        "&#43;": 42, //+

        "&#123;": 42, //{
        "&#125;": 42, //}
        "&#124;": 42, //|

        "&#58;": 42, //:
        "&#34;": 42, //"

        "&#60;": 42, //<
        "&#62;": 42, //>
        "&#63;": 42 //?
    },

    "ie_specialkeys_mobile": {},

    "ie_specialkeys": {},

    "&#96;": 41, //`
    "&#126;": 41, //~
    "&#49;": 2, //1
    "&#33;": 2, //!
    "&#50;": 3, //2
    "&#64;": 3, //@
    "atmark": 3, //@
    "&#51;": 4, //3
    "&#35;": 4, //#
    "&#52;": 5, //4
    "&#36;": 5, //$
    "&#53;": 6, //5
    "&#37;": 6, //%
    "&#54;": 7, //6
    "&#94;": 7, //^
    "&#55;": 8, //7
    "&#38;": 8, //&
    "&#56;": 9, //8
    "&#42;": 9, //*
    "&#57;": 10, //9
    "&#40;": 10, //(
    "&#48;": 11, //0
    "&#41;": 11, //)
    "&#45;": 12, //-
    "&#95;": 12, //_
    "&#61;": 13, //=
    "&#43;": 13, //+

    "&#113;": 16, //q
    "&#119;": 17, //w
    "&#101;": 18, //e
    "&#114;": 19, //r
    "&#116;": 20, //t
    "&#121;": 21, //y
    "&#117;": 22, //u
    "&#105;": 23, //i
    "&#111;": 24, //o
    "&#112;": 25, //p
    "&#91;": 26, //[
    "&#123;": 26, //{
    "&#93;": 27, //]
    "&#125;": 27, //}
    "&#92;": 43, //\
    "&#124;": 43, //|

    "&#97;": 30, //a
    "&#115;": 31, //s
    "&#100;": 32, //d
    "&#102;": 33, //f
    "&#103;": 34, //g
    "&#104;": 35, //h
    "&#106;": 36, //j
    "&#107;": 37, //k
    "&#108;": 38, //l
    "&#59;": 39, //;
    "&#58;": 39, //:
    "&#39;": 40, //'
    "&#34;": 40, //"

    "&#122;": 44, //z
    "&#120;": 45, //x
    "&#99;": 46, //c
    "&#118;": 47, //v
    "&#98;": 48, //b
    "&#110;": 49, //n
    "&#109;": 50, //m
    "&#44;": 51, //,
    "&#46;": 52, //.
    "&#60;": 51, //<
    "&#62;": 52, //>
    "&#47;": 53, ///
    "&#63;": 53, //?

    "tab": 15,
    "capslock": 58,
    "caps_lock": 58,
    "shift": 42,
    "shiftleft": 42,
    "control": 29,
    "alt": 100,
    "altgraph": 100,
    "backspace": 14,
    "&#32;": 57, //" " (space)
    "space": 57,
    "enter": 28,
    "up": 103,
    "arrowup": 103,
    "left": 105,
    "arrowleft": 105,
    "right": 106,
    "arrowright": 106,
    "down": 108,
    "arrowdown": 108
}
