const template = require('./range-input.html');
const app = require('../../app.js');
const $ = require('jquery');

module.exports = {
    template,

    props: {
        value: {
            type: Number,
            required: true
        },

        min: {
            type: Number,
            required: false,
            default: 0
        },

        max: {
            type: Number,
            required: false,
            default: 100
        },

        step: {
            type: Number,
            required: false,
            default: 5
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data: function() {
        return {
            remoteMove: false
        };
    },

    watch: {
        value: function() {
            this.valueInternal = this.value;
        }
    },

    computed: {
        rangeStyle: function() {
            const valueAsFraction = this.value / this.max;

            return {
                background: `-webkit-gradient(linear,0% 0%,100% 0%,color-stop(${valueAsFraction},#f59a22),color-stop(${valueAsFraction},#2d2f33))`
            };
        }
    },

    methods: {
        valueChanged: function(event) {
            this.$emit('input', event.target.valueAsNumber);
        },

        stop: function(event) {
            event.stopPropagation();
        },

        handleKeyDown: function(event) {
            var keyCode = event.keyCode;

            switch (keyCode) {
                case this.remote.KEYCODES.ENTER:
                    this.remoteMove = !this.remoteMove;
                    event.preventDefault();
                    event.stopPropagation();

                    Promise.resolve().then(() => {
                        // Vue will rewrite class attribute, so we need to restore 'focused' class
                        // In order to go after Vue, we need to append a microtask to the queue
                        $(event.target).addClass('focused');
                    });
                    break;
                case this.remote.KEYCODES.UP:
                case this.remote.KEYCODES.DOWN:
                    event.preventDefault();
                    break;
                case this.remote.KEYCODES.LEFT:
                    if (this.remoteMove) {
                        this.remote.stopMove();
                    } else {
                        event.preventDefault();
                    }
                    break;
                case this.remote.KEYCODES.RIGHT:
                    if (this.remoteMove) {
                        this.remote.stopMove();
                    } else {
                        event.preventDefault();
                    }
                    break;
            }
        }
    },

    created: function() {
        this.remote = app.getService('RemoteService');
    }
};
