const template = require('./groupwork-manage.html');
const app = require('../../../app.js');
const vueUtils = require('../../util.js');

module.exports = {
    template,

    props: {},

    data: function() {
        return {
            sharingDevices: [{
                compatibility: 'ok',
                mode: 1,
                present: 1,
                pull: 0,
                push: 1,
                receivedReqStatus: 'not-waiting',
                sentReqStatus: 'accepted',
                serial: 1078953,
                stationName: 'Cynap-Core-01078953'
            }],
            receivingDevices: [{
                compatibility: 'ok',
                mode: 1,
                present: 1,
                pull: 0,
                push: 1,
                receivedReqStatus: 'not-waiting',
                sentReqStatus: 'accepted',
                serial: 1078953,
                stationName: 'Cynap-Core-01078953'
            }]
        };
    },

    methods: {
        /**
         * Stop sharing screen to a specific device.
         * @param device view model of device
         */
        stopScreenShare: function(device) {
            this.groupworkService.stopScreenShare(device.serial);
        },

        /**
         * Stop receiving screen sharing from a specific device.
         * @param device view model of device
         */
        stopScreenReceive: function(device) {
            this.groupworkService.stopScreenReceive(device.serial);
        },

        /**
         * Request screen sharing.
         * @param device view model of device
         */
        requestScreenShare: function() {
            this.$emit('request-screen-share');
        },

        close: function() {
            this.$emit('close');
        },

        i18n: vueUtils.i18n
    },

    created: function() {
        this.groupworkService = app.getService('GroupworkService');

        const updateData = function() {
            this.sharingDevices = this.groupworkService.getSharingDevices();
            this.receivingDevices = this.groupworkService.getReceivingDevices();
        }.bind(this);

        this.evctx = vueUtils.eventContext();
        this.evctx.on('groupwork.devices.update', function() {
            updateData();
        }.bind(this));
        updateData();
    },

    destroyed: function() {
        this.evctx.close();
    },

    components: {
        'tile-v3': require('../../tile-v3/tile-v3.js')
    }
};
