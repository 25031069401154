'use strict';

const app = require('../../../app');
const statusWidgetTpl = require('./status-widget.hbs');
const WidgetView = require('./widget-view');
const rbac = require('../../../../rbac/rbac.js');
const platform = require('../../../../platform/platform');
const statusIcons = require('./../../../framebox-types').statusIcons;

/**
 * StatusWidget Component
 *
 * add new widget: app.emit('status-widget.item.append', { id: 'record-widget', options:{icon: 'icon-record', clickable: true, state: 'record'}});
 * update widget: app.emit('status-widget.item.update', { id: 'record-widget', options:{icon: 'icon-record', clickable: false, state: 'pause'}});
 * remove widget: app.emit('status-widget.item.remove', { id: 'record-widget' });
 *
 * listen on action: app.on('status-widget.record-widget.action', function(event) { console.log('open record widget', event); });
 */
app.component('StatusWidget', {
    template: statusWidgetTpl,
    widgetStore: {},

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();
        this.bindDOMEvents();

        if (platform.checks.isRemote && rbac.hasAccess('LanguageSelect', 'statusWidget', true)) {
            this.addLanguageWidget();
        }

        this.checkWidgetStates();
        this.emit('status-bar.initialized');
    },

    addLanguageWidget: function() {
        var widget = {
            id: 'language',
            accessKey: 'LanguageSelect',
            options: {
                icon: statusIcons['languageChooser'],
                clickable: true,
                state: 'synced'
            }
        };

        this.removeHandler(widget);
        this.appendHandler(widget);
    },

    /**
     * @method checkWidgetStates
     *
     * Check widget states after status was created.
     * It's a fix for differend Services sending the event before status component was created.
     */
    checkWidgetStates: function() {
        var widget = {};

        if (app.getService('AuthenticationService').getIsAdmin()) {
            widget = {
                id: 'admin',
                accessKey: 'Authentication',
                options: {
                    icon: statusIcons['admin'],
                    clickable: false,
                    state: 'admin'
                }
            };

            this.removeHandler(widget);
            this.appendHandler(widget);
        }

        if (app.getService('CloudAuthenticationService').getAnyServiceConnected()) {
            widget = {
                id: 'cloud-connection',
                accessKey: 'CloudLogin',
                options: {
                    icon: statusIcons['cloud'],
                    clickable: false,
                    state: 'synced'
                }
            };

            this.removeHandler(widget);
            this.appendHandler(widget);
        }

        if (app.getService('VolumeService').mute.currentState === 'on') {
            widget = {
                id: 'mute',
                accessKey: 'Volume',
                options: {
                    icon: statusIcons['muteSpeaker'],
                    clickable: false,
                    state: 'mute'
                }
            };

            this.removeHandler(widget);
            this.appendHandler(widget);
        }

        if (app.getService('VolumeService').micMute.currentState !== 'none') {
            widget = {
                id: 'mic-mute',
                accessKey: 'Volume',
                options: {
                    icon: statusIcons['muteMic'],
                    state: 'mute',
                    visible: false
                }
            };

            this.removeHandler(widget);
            this.appendHandler(widget);
        }

        if (app.getService('AnnotationService').globalState.currentState === 'started') {
            widget = {
                id: 'annotation',
                accessKey: 'Annotation',
                options: {
                    icon: statusIcons['pen'],
                    clickable: false,
                    state: 'annotate'
                }
            };

            this.removeHandler(widget);
            this.appendHandler(widget);
        }

        if (app.getService('Streaming').state.currentState !== 'off') {
            widget = {
                id: 'streaming',
                accessKey: 'Streaming',
                options: {
                    icon: statusIcons['stream'],
                    clickable: false,
                    state: 'streaming'
                }
            };

            this.removeHandler(widget);
            this.appendHandler(widget);
        }

        if (app.getService('WebcastingService').isActive()) {
            widget = {
                id: 'webcasting',
                accessKey: 'Webcasting',
                options: {
                    icon: statusIcons['webcast'],
                    clickable: false,
                    state: app.getService('WebcastingService').webcastIconState.getState()
                }
            };

            this.removeHandler(widget);
            this.appendHandler(widget);
        }

        if (app.getService('StreamInputService').healthStatus.currentState !== 'none') {
            widget = {
                id: 'stream-input-health',
                accessKey: 'StreamInput',
                options: {
                    icon: app.getService('StreamInputService').healthStatus.currentState === 'ok'
                        ? statusIcons['webConferenceRecord']
                        : statusIcons['webConferenceError'],
                    clickable: false,
                    state: app.getService('StreamInputService').healthStatus.getState()
                }
            };

            this.removeHandler(widget);
            this.appendHandler(widget);
        }

        if (app.getService('RecordService').state.currentState !== 'stop') {
            widget = {
                id: 'record-widget',
                accessKey: 'Record',
                options: {
                    icon: statusIcons['record'],
                    clickable: true,
                    state: app.getService('RecordService').state.getState()
                }
            };

            this.removeHandler(widget);
            this.appendHandler(widget);
        }

        if (app.getService('PipService').isEnabled()
            && (app.getService('RecordService').state.currentState !== 'stop'
                || app.getService('Streaming').state.currentState !== 'off')) {
            widget = {
                id: 'pip-widget',
                accessKey: 'Pip',
                options: {
                    icon: statusIcons['pip'],
                    clickable: false
                }
            };

            this.removeHandler(widget);
            this.appendHandler(widget);
        }
    },

    storeSelectors: function() {
        this.$container = this.$el.find('#status-widget');
    },

    bindEvents: function() {
        this.on('status-widget.item.append', this.appendHandler.bind(this));
        this.on('status-widget.item.update', this.updateHandler.bind(this));
        this.on('status-widget.item.remove', this.removeHandler.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '#end-presentation', this.endPresentationHandler.bind(this));
    },

    appendHandler: function(params) {
        var id = params.id;
        var options = params.options;
        var accessKey = params.accessKey;

        if (!accessKey || !rbac.hasAccess(accessKey, 'statusWidget')) {
            return;
        }

        if (id && !this.widgetStore[id]) {
            var widget = new WidgetView(this.app, id, options);

            this.widgetStore[id] = {
                widget: widget
            };

            widget.render(this.$container);

            this.emit('status-widget.item.appended', this.getNumberOfDiplayedWidgets());
        }
    },

    updateHandler: function(params) {
        var id = params.id;
        var options = params.options;

        if (id && this.widgetStore[id]) {
            var widget = this.widgetStore[id].widget;

            widget.update(options);

            this.emit('status-widget.item.updated', this.getNumberOfDiplayedWidgets());
        }
    },

    removeHandler: function(params) {
        var id = params.id;

        if (id && this.widgetStore[id]) {
            var widget = this.widgetStore[id].widget;

            widget.destroy().done(function() {
                delete this.widgetStore[id];
                this.emit('status-widget.item.removed', this.getNumberOfDiplayedWidgets());
            }.bind(this));
        }
    },

    getNumberOfDiplayedWidgets: function() {
        return Object.values(this.widgetStore).filter(w => w.widget.options.visible).length;
    },

    endPresentationHandler: function() {
        this.emit('frameboxes.close.all');
    }
});
