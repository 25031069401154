const template = require('./prepare-area-item.html');
const vueUtils = require('../../../../components-vue/util');
const Vuex = require('vuex');
const contentTypeIcons = require('./../../../../framebox-types').contentTypeIcons;
const contentTypeTitleKeys = require('./../../../../framebox-types').contentTypeTitleKeys;
const office365Icons = require('./../../../../framebox-types').office365Icons;
const office365TitleKeys = require('./../../../../framebox-types').office365TitleKeys;
const typeIcons = require('./../../../../framebox-types').typeIcons;
const inputTypeIconsMapping = require('./../../../../framebox-types').inputTypeIconsMapping;
const fileIconsByExtension = require('../../../filelist/icons').iconsByExtension;
const fileIconsByType = require('../../../filelist/icons').iconsByType;
const findFileIcon = require('../../../filelist/icons').findIcon;

module.exports = {
    template,

    props: {
        item: {
            type: Object,
            required: true
        },
        previewUrl: {
            type: String,
            required: false
        },
        inactive: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    computed: {
        icon: function() {
            const unknownTypeIcon = 'icon-question';

            if (this.item.icon) {
                return this.item.icon;
            }

            if (this.item.path) {
                return findFileIcon(this.item) || unknownTypeIcon;
            }

            if (this.item.symbol) {
                return typeIcons[inputTypeIconsMapping[this.item.symbol]]
                    || fileIconsByType[this.item.symbol] || fileIconsByExtension[this.item.symbol]
                    || unknownTypeIcon;
            }

            return contentTypeIcons[this.item.contentType] || office365Icons[this.item.contentType]
                || unknownTypeIcon;
        },

        title: function() {
            if (this.item.name) {
                return this.item.name;
            }

            if (this.item.titleKey) {
                return this.i18n(this.item.titleKey);
            }

            if (this.item.title) {
                return this.item.title;
            }

            return this.i18n(contentTypeTitleKeys[this.item.contentType])
                || this.i18n(office365TitleKeys[this.item.contentType]) || '';
        },

        isDroppedFromLiveArea: function() {
            return this.item?.outputPort === 'hdmi1' || this.item?.outputPort === 'hdmi2';
        },

        ...Vuex.mapGetters('uiSettings', ['getUiScalingSize'])
    },

    watch: {
        getUiScalingSize: function() {
            this.scaleIcon();
        }
    },

    methods: {
        edit: function() {
            this.$emit('edit', {
                appId: this.item.appId
            });
        },

        close: function() {
            this.$emit('close', {
                appId: this.item.appId
            });
        },

        scaleIcon: function() {
            const height = this.$refs['prepare-area-item'].clientHeight;
            const icon = this.$refs['type-icon'];

            if (height && icon) {
                icon.style.fontSize = height * 0.56 + 'px';
            }
        },

        requestPreview: function() {
            if (!this.previewUrl && this.item.appId && this.item.previewAvailable) {
                this.$emit('preview-plz', this.item.appId);
            }
        },

        i18n: vueUtils.i18n
    },

    created: function() {
        this.evctx = vueUtils.eventContext();
        this.evctx.on('livestream-size.update', this.scaleIcon.bind(this));
        this.evctx.on('aspect-ratio.changed', this.scaleIcon.bind(this));

        this.requestPreview();
    },

    beforeUpdate: function() {
        this.requestPreview();
    },

    mounted: function() {
        this.scaleIcon();
    },

    destroyed: function() {
        this.evctx.close();
    }
};
