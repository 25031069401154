// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "limited-access";
},"3":function(container,depth0,helpers,partials,data) {
    return "has-controls";
},"5":function(container,depth0,helpers,partials,data) {
    return "data-nav-area=\"button, .focusable\"";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div id=\"framebox-title-icon\" class=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"titleIcon") : depth0), depth0))
    + "\"></div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFullscreen") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":41,"column":23}}})) != null ? stack1 : "")
    + "\n            <div id=\"framebox-control-bar-options-container\"\n                 class=\"framebox-bar-item framebox-control-bar-options-container\">\n                <button id=\"framebox-option-toggle-hdmi-out-2\"\n                        class=\"btn-no-style framebox-bar-item framebox-option-toggle-hdmi-out-2\"\n                        tabIndex=\"-1\">\n                    <span id=\"hdmi-out-2-on\" class=\"icon-v2-moderator-mode-off\"></span>\n                    <span id=\"hdmi-out-2-off\" class=\"icon-v2-moderator-mode-on\" ></span>\n                </button>\n                <button id=\"framebox-option-toggle-fullscreen\"\n                        class=\"btn-no-style framebox-bar-item framebox-option-fullscreen framebox-option-toggle-fullscreen\"\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":24},"end":{"line":53,"column":66}}})) != null ? stack1 : "")
    + "\n                        tabIndex=\"-1\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFullscreen") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":55,"column":20},"end":{"line":59,"column":27}}})) != null ? stack1 : "")
    + "                </button>\n                <button id=\"framebox-option-close\"\n                        class=\"btn-no-style framebox-bar-item framebox-option-close\"\n                        tabIndex=\"-1\">\n                    <span class=\"icon-v2-close\"></span>\n                </button>\n            </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                <div id=\"framebox-control-bar-toggle-container\"\n                     class=\"framebox-bar-item framebox-control-bar-toggle-container\">\n                    <button id=\"framebox-option-toggle-control-bar\"\n                            class=\"btn-no-style framebox-bar-item framebox-option-toggle-control-bar\"\n                            tabIndex=\"-1\">\n                        <span id=\"close-control-bar\" class=\"icon-v2-minimize-control-bar framebox-option-close-control-bar\"></span>\n                        <span id=\"open-control-bar\" class=\"icon-v2-maximize-control-bar framebox-option-open-control-bar\"></span>\n                    </button>\n                </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "data-nav-focused";
},"14":function(container,depth0,helpers,partials,data) {
    return "                        <span class=\"icon-v2-window-end-fullscreen\"></span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                        <span class=\"icon-v2-window-fullscreen\"></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li id=\"framebox-control-bar-item\"\n    class=\"framebox-control-bar-item "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLimitedAccess") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":37},"end":{"line":2,"column":86}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDualProjection") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":87},"end":{"line":2,"column":135}}})) != null ? stack1 : "")
    + "\"\n    data-index=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\"\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":64}}})) != null ? stack1 : "")
    + ">\n    <div id=\"framebox-control-bar-item-inner\" class=\"framebox-control-bar-item-inner\">\n\n        <div class=\"framebox-progressbar\" id=\"framebox-progressbar-"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\"></div>\n\n        <div id=\"framebox-control-bar-title-container\"\n             class=\"framebox-bar-item framebox-control-bar-title-container\"\n             data-index=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\"\n             tabIndex=\"-1\">\n            <div id=\"framebox-symbol\" class=\"framebox-symbol\" data-index=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\"></div>\n            <div id=\"framebox-title\" class=\"framebox-title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":60},"end":{"line":14,"column":78}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"titleIcon") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":17,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div id=\"framebox-control-bar-submenu-close-container\"\n             class=\"framebox-bar-item framebox-control-bar-title-container hidden\"\n             data-index=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\"\n             tabIndex=\"-1\">\n            <button id=\"framebox-submenu-close\" class=\"btn-no-style framebox-bar-item framebox-submenu-close icon-v2-back\" data-index=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\"></button>\n            <div class=\"framebox-control-bar-separator\"></div>\n        </div>\n\n        <div id=\"framebox-control-bar-controls-container\"\n             class=\"framebox-bar-item framebox-control-bar-controls-container\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDualProjection") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":67,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n</li>\n";
},"useData":true});
