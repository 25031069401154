'use strict';

const app = require('../app');
const d3 = require('d3');
const _ = require('lodash');
const platform = require('../../platform/platform');

let width = 1900;
let height = 1060;

const direction = -1;
let atLength;

const arrowPosition = 500;

let connectionType = d3.curveLinear;
const interpolationTypes = [d3.curveLinear, d3.curveCardinal];

/**
 * Matrix connection service
 */
app.service('MatrixConnectionService', function() {
    return {
        initialize: function() {
            this.configs = app.getService('MatrixConfigs');
            this.matrixService = app.getService('MatrixService');
            this.matrixConfigService = app.getService('MatrixConfiguratorService');
            this.deviceService = app.getService('DeviceService');
            this.isDualProjection = this.deviceService.isCboxProDualProjection();
        },

        /**
         * Set current svg context.
         * @param svg Current SVG context
         */
        setSvg: function(svg) {
            this.$svg = svg;
            this.svg = d3.select(this.$svg[0]);
        },

        /**
         * Set connection format 'curveLinear' or 'curveCardinal'.
         * @param format
         */
        setConnectionFormat: function(format) {
            this.svg.select('#connections-layer').attr('data-format', format);

            switch (format) {
                case 'curveLinear':
                    connectionType = interpolationTypes[0];
                    break;
                case 'curveCardinal':
                    connectionType = interpolationTypes[1];
                    break;
            }

            _.each(this.svg.selectAll('#connections-layer').selectAll('g[data-master]')._groups[0], function(conn) {
                this.line = d3.line();
                this.line.curve(connectionType);

                this.showConnectionHandles(this.svg, conn.id);
            }.bind(this));

            this.hideConnectionHandles(this.svg);
        },

        /**
         * Create connection line between created station and master.
         *
         * @param svg {Object} svg context
         * @param targetId {String} station & connection ID
         * @param x {String} start position x
         * @param y {String} start position y
         */
        createConnection: function(svg, targetId, masterIds) {
            this.pts = {};
            for (let i = 0; i < masterIds.length; i++) {
                const masterId = masterIds[i];
                this.pts[masterId] = [];

                const targetStation = svg.select('#stations-layer').select('#' + targetId);
                const masterStation = svg.select('#stations-layer').select('#' + masterId);
                const masterX = parseInt(masterStation.select('.btn-center').attr('cx'));
                const masterY = parseInt(masterStation.select('.btn-center').attr('cy'));

                const pt = this.matrixService.calculateRotatedPosition(
                    parseInt(targetStation.attr('cx')),
                    parseInt(targetStation.attr('cy')),
                    parseInt(targetStation.select('.btn-center').attr('cx')),
                    parseInt(targetStation.select('.btn-center').attr('cy')),
                    parseFloat(targetStation.attr('angle')));
                const connBtnX = pt[0];
                const connBtnY = pt[1];

                const color = targetStation.attr('color');
                const masterColor = masterStation.attr('color');

                const connectionId = targetId + '-' + masterId;
                // All connections get the id of master station and target station as class
                this.svgEl = svg.selectAll('#connections-layer').append('svg:g')
                    .attr('id', connectionId)
                    .attr('class', targetId + ' ' + masterId)
                    .attr('data-master', masterId)
                    .attr('color', color)
                    .attr('master-color', masterColor);

                width = svg._groups[0][0].viewBox.animVal.width;
                height = svg._groups[0][0].viewBox.animVal.height;

                let xVal = parseInt(Math.abs(masterX - connBtnX) / 4);
                let yVal = parseInt(Math.abs(masterY - connBtnY) / 4);

                if (masterX - connBtnX < 0) {
                    xVal *= -1;
                }

                if (masterY - connBtnY < 0) {
                    yVal *= -1;
                }

                this.pts[masterId].push([connBtnX, connBtnY, connectionId]);

                this.pts[masterId].push([connBtnX + xVal, connBtnY + yVal, connectionId]);

                this.pts[masterId].push([connBtnX + (xVal * 2), connBtnY + (yVal * 2), connectionId]);

                this.pts[masterId].push([connBtnX + (xVal * 3), connBtnY + (yVal * 3), connectionId]);

                this.pts[masterId].push([masterX, masterY, connectionId]);

                this.dragged = null;
                this.selected = this.pts[masterId][0];

                this.initConnection();

                this.svgEl.append('path')
                    .attr('class', 'line');

                if (!platform.checks.isEdge && this.svgEl.node()) {
                    this.svgEl.node().focus();
                }

                const correctArrow = '.arrow' + (masterId.includes('hdmi2') ? '.double' : '');

                const pushStart = this.clone('defs ' + correctArrow, color);
                const pullStart = this.clone('defs ' + correctArrow, color);
                const pushEnd = this.clone('defs ' + correctArrow, color);
                const pullEnd = this.clone('defs ' + correctArrow, color);

                pushStart.classList.add('push');
                pullStart.classList.add('pull');
                pushEnd.classList.add('push', 'end');
                pullEnd.classList.add('pull', 'end');

                this.svgEl.select('path.line')
                    .datum(this.pts[masterId])
                    .call(this.redraw.bind(this, masterId));

                pushStart.setAttribute('transform', this.translateAlong(false, false));
                pushStart.setAttribute('display', 'none');
                pushEnd.setAttribute('transform', this.translateAlong(false, true));
                pushEnd.setAttribute('display', 'none');

                pullStart.setAttribute('transform', this.translateAlong(true, false));
                pullEnd.setAttribute('transform', this.translateAlong(true, true));

                this.setConnectionColor(this.svgEl, color);
                this.redraw(masterId);
            }
        },

        /**
         * Clone connection orientation arrow and append to current selected SVG element.
         *
         * @param selector Selected arrow
         * @param color Connection color
         * @returns {Node} Cloned node
         */
        clone: function(selector, color) {
            const node = this.svg.select(selector).node().cloneNode(true);
            node.style.fill = color;

            this.svgEl.node().appendChild(node);

            return node;
        },

        /**
         * Initialize connection and bind events to the points/circles for editing the associated line.
         */
        initConnection: function() {
            this.line = d3.line();
            this.line.curve(connectionType);
        },

        setConnectionsColor: function(stationId, color) {
            _.each(this.svg.selectAll('#connections-layer').selectAll('.' + stationId)._groups[0], function(conn) {
                this.setConnectionColor(d3.select(conn), color);
            }.bind(this));
        },

        /**
         * Set color of connection and connection points.
         *
         * @param connection Connection group
         * @param color Station color
         */
        setConnectionColor: function(connection, color) {
            connection.attr('color', color);

            connection.selectAll('.line')
                .style('stroke', color);
            connection.selectAll('.connection-handle')
                .style('fill', color);

            connection.selectAll('.arrow')
                .style('fill', color);
        },

        /**
         * Redraw connection line by connecting the edit points/circles.
         */
        redraw: function(masterId) {
            masterId = masterId ?? this.svgEl.attr('data-master');
            this.svgEl.select('path.line')
                .attr('d', this.line);

            const circle = this.svgEl.selectAll('circle')
                .data(this.pts[masterId], function(d) {
                    return d;
                });

            circle.exit().remove();

            circle.enter().append('circle')
                .call(d3.drag()
                    .on('start', this.touchstart.bind(this))
                    .on('drag', this.touchmove.bind(this))
                    .on('end', this.touchend.bind(this)))
                .transition()
                .duration(750)
                .ease(d3.easeElastic);

            circle
                .classed('selected', function(d) {
                    return d === this.selected;
                }.bind(this))
                .attr('class', function(d) {
                    switch (d) {
                        case this.pts[masterId][0]:
                            return 'connection-handle pt-connect';
                        case this.pts[masterId][this.pts[masterId].length - 1]:
                            return 'connection-handle pt-master';
                        default:
                            return 'connection-handle';
                    }
                }.bind(this))
                .attr('cx', function(d) {
                    return d[0];
                })
                .attr('cy', function(d) {
                    return d[1];
                })
                .attr('r', 45)
                .merge(circle);

            if (this.svgEl._groups[0].length > 0) {
                const data = this.svgEl.selectAll('.arrow')._groups[0];

                this.svgEl.selectAll('.arrow')
                    .data(data)
                    .each(function(d) {
                        d.setAttribute('transform', this.translateAlong(d.classList.contains('pull'), d.classList.contains('end')));
                    }.bind(this));

                this.setConnectionColor(this.svgEl, this.svgEl.attr('color'));
            }
        },

        /**
         * Set new position to dragged element.
         *
         * @param d Dragged element
         */
        dragged: function(d) {
            d3.select(this).attr('cx', d.x = d3.event.x).attr('cy', d.y = d3.event.y);
        },

        /**
         * Update start (station point) and end point (master point) of connection line if station is moved
         * and update line.
         * Move master: hide connections while moving and do not call touchstart for redrawing connections.
         *
         * @param svgGrid {Object} svg grid context
         * @param stationId {String} station ID to find connection line
         */
        moveStart: function(svgGrid, stationId) {
            this.hideConnectionHandles(svgGrid);

            if (stationId.startsWith('master')) {
                this.hideConnections(svgGrid);
            } else {
                _.each(d3.select('#connections-layer').selectAll('.' + stationId).selectAll('.pt-connect').data(), function(connectionHandle) {
                    this.showConnectionHandles(svgGrid, connectionHandle[2]);
                    this.touchstart(connectionHandle);
                }.bind(this));
            }
        },

        /**
         * Update connection line if associated station is moved.
         * Move master: connections are hidden while moving, do not call touchmove for redrawing connections.
         *
         * @param stationId {String} station/connection ID
         */
        move: function(svgGrid, stationId, el) {
            const connections = d3.select('#connections-layer').selectAll('.' + stationId)._groups[0];
            if (stationId.startsWith('master') || connections.length <= 0) {
                return;
            }

            // FIXME remove rotation and drag element without rotation. Is set in onMoveEnd - try dagging the rotated element.
            // Const station = svgGrid.select('#stations-layer').select('#' + id);
            // Const pt = this.matrixService.calculateRotatedPosition(
            //     ParseInt(station.attr('cx')),
            //     ParseInt(station.attr('cy')),
            //     ParseInt(el[0].getAttribute('cx')),
            //     ParseInt(el[0].getAttribute('cy')),
            //     ParseFloat(station.attr('angle')));

            const pt = [parseInt(el[0].getAttribute('cx')), parseInt(el[0].getAttribute('cy'))];
            _.each(connections, function(connectionHandle) {
                this.showConnectionHandles(svgGrid, connectionHandle.id);
                this.touchend(pt);
            }.bind(this));
        },

        /**
         * Update connection line if associated station is moved.
         * Move master: redraw all connection lines with end point on new master position.
         * Move station: redraw connection with id and new start point on new station position.
         *
         * @param svgGrid {Object} svg grid context
         * @param stationId {String} station/connection ID
         * @param el {Object} nearest grid point to snap connection line start/end point to.
         */
        moveEnd: function(svgGrid, stationId, el) {
            const connections = d3.select('#connections-layer').selectAll('.' + stationId)._groups[0];
            if (stationId !== 'master' && connections.length <= 0) {
                return;
            }

            const station = svgGrid.select('#stations-layer').select('#' + stationId);
            const pt = this.matrixService.calculateRotatedPosition(
                parseInt(station.attr('cx')),
                parseInt(station.attr('cy')),
                parseInt(el[0].getAttribute('cx')),
                parseInt(el[0].getAttribute('cy')),
                parseFloat(station.attr('angle')));

            _.each(connections, function(conn) {
                this.showConnectionHandles(svgGrid, conn.id);
                if (stationId.startsWith('master')) {
                    this.dragged = svgGrid.select('#connections-layer').select('#' + conn.id).select('.pt-master').data()[0];
                }
                this.touchend(pt);
            }.bind(this));

            this.showConnections(svgGrid);
            this.matrixConfigService.selectStation(stationId);
        },

        /**
         * Select touched edit point of connection line and redraw line.
         *
         * @param connectPt {Object}  connection point
         */
        touchstart: function(connectPt, id) {
            // Multitouch workaround. ID is only set when dragging connection handles
            if (id !== undefined && id !== null) {
                if (this.matrixConfigService.moveEvent) {
                    return;
                } else {
                    this.matrixConfigService.moveEvent = connectPt;
                }
            }

            app.emit('connection-move.start');
            this.selected = this.dragged = connectPt;
            this.svgEl = this.svg.select('#' + connectPt[2]);
            const masterId = this.svgEl.attr('data-master');

            this.redraw(masterId);
        },

        /**
         * Reset position of selected point and redraw connection line.
         * If station is moved, snap to el.
         *
         * @param pt {Object} connection point (station menu btn) to snap connection line start/end point to.
         */
        touchmove: function(pt, id) {
            const event = d3.event ? (d3.event.sourceEvent || d3.event) : undefined;
            const target = event ? event.target : undefined;

            if (!this.dragged) {
                return;
            }

            // Id is only set when dragging connection handles. Check for undefined and null because ID can also be 0
            if (target && d3.event.type === 'drag' && id !== undefined && id !== null) {
                // Multitouch workaround
                if (this.matrixConfigService.moveEvent !== pt) {
                    return;
                }

                const isTouch = typeof d3.event.dataTransfer !== 'undefined';
                const m = isTouch ? d3.touches(this.svgEl.node()) : d3.mouse(this.svgEl.node());

                if (m.length > 0) {
                    this.dragged[0] = Math.max(0, Math.min(width, isTouch ? m[0][0] : m[0]));
                    this.dragged[1] = Math.max(0, Math.min(height, isTouch ? m[0][1] : m[1]));
                }
            } else if (pt) {
                this.dragged[0] = pt[0];
                this.dragged[1] = pt[1];
            }

            this.redraw();
        },

        /**
         * Touch event ended. Call touchmove function to redraw line.
         *
         * @param pt {Object} connection point (station menu btn) to snap connection line start/end point to.
         */
        touchend: function(pt, id) {
            // Multitouch workaround. ID is only set when dragging connection handles
            if (id !== undefined && id !== null) {
                if (this.matrixConfigService.moveEvent !== pt) {
                    return;
                } else {
                    this.matrixConfigService.moveEvent = null;
                }
            }

            app.emit('connection-move.end');

            const event = d3.event ? (d3.event.sourceEvent || d3.event) : undefined;

            if (event && event.target.tagName === 'circle') {
                const nearest = this.matrixConfigService.getNearestElement(
                    d3.select(event.target),
                    this.configs.get('connectionpoints.distanceX'),
                    this.configs.get('connectionpoints.distanceY'),
                    '.connection-point'
                );

                pt = [
                    nearest.attr('cx') ? parseFloat(nearest.attr('cx')) : parseFloat(nearest.getAttribute('cx')),
                    nearest.attr('cy') ? parseFloat(nearest.attr('cy')) : parseFloat(nearest.getAttribute('cy'))
                ];
            }

            if (!this.dragged) {
                return;
            }

            this.touchmove(pt);
            this.dragged = null;
        },

        /**
         * Hide all connection lines.
         *
         * @param svg {Object} svg context
         */
        hideConnections: function(svg, stationId) {
            if (stationId) {
                svg.selectAll('#connections-layer').selectAll('.' + stationId)
                    .style('display', 'none');
            } else {
                svg.selectAll('#connections-layer').selectAll('g[data-master]')
                    .style('display', 'none');
            }
        },

        /**
         * Show all connection lines.
         *
         * @param svg {Object} svg context
         */
        showConnections: function(svg, stationId) {
            if (stationId) {
                svg.selectAll('#connections-layer').selectAll('.' + stationId)
                    .style('display', 'block');
            } else {
                svg.selectAll('#connections-layer').selectAll('g[data-master]')
                    .style('display', 'block');
            }
        },

        /**
         * Hide connection handles (edit points).
         *
         * @param el {Object} Element to hide connection handles from.
         */
        hideConnectionHandles: function(el) {
            if (!el) {
                return;
            }

            el.selectAll('.connection-handle')
                .style('display', 'none');
        },

        /**
         * Show connection handles (edit points).
         *
         * @param svg {Object} svg context
         * @param connectionId {String} station/connection line ID
         */
        showConnectionHandles: function(svg, connectionId) {
            this.pts = {};
            width = svg._groups[0][0].viewBox.animVal.width;
            height = svg._groups[0][0].viewBox.animVal.height;

            this.initConnection();

            const connections = svg.selectAll('#connections-layer').selectAll('g[id*="' + connectionId + '"]');

            connections.selectAll('.connection-handle')
                .style('display', 'block');

            _.each(connections._groups[0], function(connection) {
                this.svgEl = d3.select(connection);
                const masterId = this.svgEl.attr('data-master');

                this.getPtsFromStation(this.svgEl, masterId);
                this.selected = this.dragged = this.pts[masterId][0];

                this.svgEl.select('path.line')
                    .datum(this.pts[masterId])
                    .attr('class', 'line')
                    .call(this.redraw.bind(this, masterId));

                this.redraw(masterId);

                if (!platform.checks.isEdge && this.svgEl.node()) {
                    this.svgEl.node().focus();
                }
            }.bind(this));
        },

        /**
         * Calculate connection arrow rotation.
         *
         * @param pull {Boolean} is pull arrow
         * @param end {Boolean} is arrow at the end of the connection
         */
        translateAlong: function(pull, end) {
            const path = this.svgEl.select('path.line').node();

            if (path.getTotalLength() < (arrowPosition * 4)) {
                atLength = path.getTotalLength() / 2;
            } else {
                atLength = end ? arrowPosition : path.getTotalLength() - arrowPosition;
            }

            const p1 = path.getPointAtLength(atLength);
            const p2 = path.getPointAtLength((atLength) + direction);
            let angle = Math.atan2((p2.y) - (p1.y), p2.x - p1.x) * 180 / Math.PI;

            if (pull) {
                angle -= 180;
            }

            return 'translate(' + p1.x + ',' + p1.y + ')rotate(' + angle + ')';
        },

        getPtsFromStation: function(connection, masterId) {
            this.pts[masterId] = [];
            _.each(connection.selectAll('.connection-handle')._groups[0], function(circle) {
                const cx = parseInt(circle.getAttribute('cx'));
                const cy = parseInt(circle.getAttribute('cy'));

                const values = [cx, cy, connection._groups[0][0].id];
                this.pts[masterId].push(values);
            }.bind(this));
        }
    };
});
