'use strict';

const app = require('../app');
const _ = require('lodash');
const $ = require('jquery');
const StateMachine = require('./../state-machine');
const platform = require('../../platform/platform');
const pollyfillUnidentifiedKey = require('./../../keyboard/pollyfills/unidentified-keys');
const uiStates = require('./../states').uiStates;
const titleKeys = require('../framebox-types').contentTypeTitleKeys;
const helper = require('../helper.js');
const store = require('../store/store');

/** @var {KeyMapper} keyMapper **/
const keyMapper = require('./../../keyboard/key-mapper');
const keyToKeyCodeMappingDe = require('./../../keyboard/key-to-key-code-de.json');
const keyToKeyCodeMappingEn = require('./../../keyboard/key-to-key-code-en.json');
const keyToKeyCodeMappingFr = require('./../../keyboard/key-to-key-code-fr.json');
const keyToKeyCodeMappingNo = require('./../../keyboard/key-to-key-code-no.json');
const keyToKeyCodeMappingSv = require('./../../keyboard/key-to-key-code-sv.json');
const keyToKeyCodeMappingIt = require('./../../keyboard/key-to-key-code-it.json');
const keyToKeyCodeMappingCh = require('./../../keyboard/key-to-key-code-ch.json');
const keyToKeyCodeMappingUk = require('./../../keyboard/key-to-key-code-uk.json');
const keyToKeyCodeMappingJp = require('./../../keyboard/key-to-key-code-jp.json');
const keyToKeyCodeMappingFrCa = require('./../../keyboard/key-to-key-code-fr-ca.json');

const keyDownEvents = ['keydown', 'touchstart'];
const keyUpEvents = ['keyup', 'touchend'];

let keyModifier = ['Alt', 'AltGraph', 'Control'];

/**
 * Map frameBoxComponent to componentType
 * @type {{FrameBoxVSolution}}
 */
const componentMapping = {
    'FrameBoxVisualizer': 'Visualizer',
    'FrameBoxMirroring': 'Mirroring',
    'FrameBoxBrowserViewer': 'Browser',
    'FrameBoxOffice365Viewer': 'Office365',
    'FrameBoxTeamsViewer': 'teams',
    'FrameBoxHdmi': 'HdmiIn',
    'FrameBoxVideoViewer': 'Video',
    'FrameBoxVSolution': 'vSolution',
    'FrameBoxImageViewer': 'Image',
    'FrameBoxPDFViewer': 'PDF',
    'FrameBoxCalcViewer': 'calc',
    'FrameBoxPresentationViewer': 'presentation',
    'FrameBoxTextViewer': 'text',
    'FrameBoxWhiteboardViewer': 'whiteboard',
    'FrameBoxAudioViewer': 'Audio',
    'FrameBoxWebconferenceViewer': 'webconference',
    'FrameBoxStreamInputViewer': 'streaminput',
    'FrameBoxWebcamViewer': 'webcam',
    'FrameBoxMatrix': 'matrix',
    'FrameBoxMatrixControl': 'matrixControl',
    'FrameBoxMatrixGroupwork': 'matrixGroupwork',
    'FrameBoxZoomViewer': 'zoom'
};

app.service('FrameBoxService', function FrameBoxService(app) {
    var componentConfigs = {
        'Visualizer': {
            titleKey: titleKeys['Visualizer'],
            component: 'FrameBoxVisualizer'
        },

        'Mirroring': {
            titleKey: titleKeys['Mirroring'],
            component: 'FrameBoxMirroring'
        },

        'Browser': {
            titleKey: titleKeys['Browser'],
            component: 'FrameBoxBrowserViewer'
        },

        'Office365': {
            titleKey: titleKeys['Office365'],
            component: 'FrameBoxOffice365Viewer'
        },

        'teams': {
            titleKey: titleKeys['teams'],
            component: 'FrameBoxTeamsViewer',
            customClose: true
        },

        'HdmiIn': {
            titleKey: titleKeys['HdmiIn'],
            component: 'FrameBoxHdmi'
        },

        'Video': {
            titleKey: titleKeys['Video'],
            component: 'FrameBoxVideoViewer'
        },

        'vSolution': {
            titleKey: titleKeys['vSolution'],
            component: 'FrameBoxVSolution'
        },

        'otherContent': {
            titleKey: '',
            titleIconKey: 'icon-lock1',
            component: 'FrameBoxOthers'
        },

        'Image': {
            titleKey: titleKeys['Image'],
            component: 'FrameBoxImageViewer'
        },

        'PDF': {
            titleKey: titleKeys['PDF'],
            component: 'FrameBoxPDFViewer'
        },

        'calc': {
            titleKey: titleKeys['calc'],
            component: 'FrameBoxCalcViewer'
        },

        'presentation': {
            titleKey: titleKeys['presentation'],
            component: 'FrameBoxPresentationViewer'
        },

        'text': {
            titleKey: titleKeys['text'],
            component: 'FrameBoxTextViewer'
        },

        'whiteboard': {
            titleKey: titleKeys['whiteboard'],
            component: 'FrameBoxWhiteboardViewer'
        },

        'Audio': {
            titleKey: titleKeys['Audio'],
            component: 'FrameBoxAudioViewer'
        },

        'webconference': {
            titleKey: titleKeys['webconference'],
            component: 'FrameBoxWebconferenceViewer'
        },

        'zoom': {
            titleKey: titleKeys['zoom'],
            component: 'FrameBoxZoomViewer',
            customClose: true
        },

        'streaminput': {
            titleKey: titleKeys['streaminput'],
            component: 'FrameBoxStreamInputViewer'
        },

        'webcam': {
            titleKey: titleKeys['webcam'],
            component: 'FrameBoxWebcamViewer'
        },

        'matrix': {
            titleKey: titleKeys['matrix'],
            component: 'FrameBoxMatrix'
        },

        'matrixControl': {
            titleKey: titleKeys['matrixControl'],
            component: 'FrameBoxMatrixControl'
        },

        'matrixGroupwork': {
            titleKey: titleKeys['matrixGroupwork'],
            component: 'FrameBoxMatrixGroupwork'
        }
    };

    return {
        isInBackground: false,
        menuOpened: false,
        isTouched: false,

        /**
         * @method initialize
         */
        initialize: function() {
            this.resetDataStore();
            this.lastKeyEvent = null;
            this.keyModifierEvent = null;
            this.lastKeydownEvent = null;

            this.frameboxes = {};

            this.uiState = new StateMachine({
                context: this,
                state: uiStates.visible,
                states: uiStates
            });

            this.liveStream = app.getService('LiveStreamService');
            this.freezeService = app.getService('FreezeService');
            this.URIService = app.getService('URIService');
            this.languageService = app.getService('LanguageService');
            this.frontendSettings = app.getService('FrontendSettings');
            this.sourcesService = app.getService('SourcesService');
            this.lastUnresolvedEvents = [];

            // Set default key mapping
            this.initKeyMapping();

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                }.bind(this));

            app.on('framebox.speed.full', this.fullSpeed.bind(this));
            app.on('framebox.speed.slow', this.slowDown.bind(this));
            app.on('keyboard.update', this.initKeyMapping.bind(this));
            app.on('ui.show', function() {
                this.uiState.changeState(uiStates.visible);
            });
            app.on('ui.hide', function() {
                this.uiState.changeState(uiStates.hidden);
            });
            app.on('frameboxes.close.all', this.closeAllFrameBoxes.bind(this));

            $(window).on('blur', this.onWindowInactive.bind(this));
        },

        initKeyMapping: function(layout) {
            this.keyToKeyCodeMappingFallback = keyToKeyCodeMappingEn;

            switch (layout) {
                case 'de':
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingDe;

                    return;
                case 'fr':
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingFr;

                    return;
                case 'fr-ca':
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingFrCa;

                    return;
                case 'no':
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingNo;

                    return;
                case 'sv':
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingSv;

                    return;
                case 'it':
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingIt;

                    return;
                case 'ch':
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingCh;

                    return;
                case 'uk':
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingUk;

                    return;
                case 'jp':
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingJp;

                    return;
                default: // Layout === us = en
                    this.keyToKeyCodeMapping = keyToKeyCodeMappingEn;
            }
        },

        /**
         * Reset all data to start-value.
         * Called after Service-initialize and after annotation was closed.
         */
        resetDataStore: function() {
            this.controlCenterOpened = false;
            this.modalOpened = false;
            this.menuOpened = false;
            this.overlayOpened = false;
            this.filebrowserOpened = false;
            this.referenceDimension = {
                width: 0,
                height: 0
            };
            this.activeFrameBox = '-1';
        },

        openFullsceen: function() {
            // Implementation not needed in dual projection mode
        },

        closeFullscreen: function() {
            // Implementation not needed in dual projection mode
        },

        /**
         * Loads the framebox info from the box and calls handle frames afterwards.
         */
        loadFrameBoxes: function() {
            if (this.freezeService.isFreeze()) {
                return;
            }

            this.deviceConnection
                .send('getContentPerOutput', {
                    outputs: ['hdmi1', 'hdmi2', 'edit']
                })
                .then(function(data) {
                    let boxes = {};

                    data.windows.forEach(window => {
                        const coords = this.convertCoordinates(window.coordinates, window.reference, this.liveStream.getSize());
                        boxes[window.appId] = Object.assign({}, window, {
                            coordinates: coords
                        });

                        if (window.outputPort === 'edit') {
                            this.referenceDimension = window.reference;
                        }
                    });

                    if (data.outputsInEdit.length > 0) {
                        this.referenceDimension = data.outputsInEdit[0].reference;
                    }

                    // TODO: refactor - boxes array still needed (get box via appId)
                    this.frameboxes.boxes = boxes;
                    store.dispatch('controlScreen/setOutputsContent', data);
                    app.emit('frameboxes.update', boxes);
                }.bind(this));
        },

        /**
         * @type {Object}
         */
        Engine: null,

        /**
         * @method setEngine
         * @param {Object} Engine
         */
        setEngine: function(Engine) {
            if (Engine) {
                this.Engine = Engine;
            }

            return this;
        },

        /**
         * Starts the framebox handling.
         */
        start: function() {
            if (!this.Engine) {
                throw new Error('No Engine defined!');
            }

            this.Engine.start();
            this.Engine.on('redraw.update', function() {
                if (!this.paused) {
                    this.loadFrameBoxes();
                }
            }.bind(this));

            return this;
        },

        /**
         * @type {Boolean}
         */
        paused: false,

        /**
         * Pause the stream
         * @method pause
         */
        pause: function() {
            this.paused = true;
        },

        /**
         * Unpause the stream
         * @method pause
         */
        unpause: function() {
            this.paused = false;
        },

        /**
         * Stop the framebox handling
         * @method stop
         */
        stop: function() {
            if (this.Engine) {
                this.Engine.off('redraw.update', this.loadFrameBoxes);
            }

            return this;
        },

        /**
         * @method slowDown
         */
        slowDown: function() {
            if (this.Engine) {
                this.Engine.slowDown();
            }
        },

        /**
         * @method fullSpeed
         */
        fullSpeed: function() {
            if (this.Engine) {
                this.Engine.fullSpeed();
            }
        },

        /**
         * Returns the config for the needed Framebox-Type.
         *
         * @param {String} frameType
         *
         * @return {Object}
         */
        getConfig: function(frameType) {
            return componentConfigs[frameType] || null;
        },

        /**
         * This function scales the coordinates from the reference dimensions
         * to the target dimensions
         * @method convertCoordinates
         * @param {Object} coordinates the coordinates in the reference dimensions
         * @param {Object} referenceDimensions the reference dimension
         * @param {Object} targetDimensions the target dimensions
         * @return {Object} returns the scaled coordinates
         */
        convertCoordinates: function(coordinates, referenceDimensions, targetDimensions) {
            targetDimensions = targetDimensions || this.referenceDimension;

            return helper.convertCoordinates(coordinates, referenceDimensions, targetDimensions);
        },

        /**
         * @method openFrameBox
         * @param {String} frameBoxType: the frame box type
         * @param {Object} options: options object containing required parameters (depending on frame box type)
         * @param {Object} controlInfo: the control information
         * @param {'hdmi1'|'hdmi2'|'prepare'|'edit'} controlInfo.output: the output where the frame box should be opened
         */
        openFrameBox: function(frameBoxType, options, controlInfo) {
            this.sourcesService.open({
                type: frameBoxType,
                ...options
            }, controlInfo);
        },

        /**
         * Open file.
         */
        openFile: function(file, controlInfo) {
            this.sourcesService.open(file, controlInfo);
        },

        /**
         * Returns the number of open frameboxes/windows
         * @returns {*}
         */
        getNumberOpenFrameboxes: function() {
            const nrOpenWindowsHdmi1 = store.getters['controlScreen/getNumberOfWindowsByOutputPort']('hdmi1');
            const nrOpenWindowsHdmi2 = store.getters['controlScreen/getNumberOfWindowsByOutputPort']('hdmi2');

            if (platform.checks.isCboxProjectionHdmi1) {
                return nrOpenWindowsHdmi1;
            } else if (platform.checks.isCboxProjectionHdmi2) {
                return nrOpenWindowsHdmi2;
            }

            return nrOpenWindowsHdmi1 + nrOpenWindowsHdmi2;
        },

        /**
         * @param {object} options
         *
         * @returns {jQuery.Deferred.promise}
         */
        hasFrameboxByName: function(names) {
            var dfd = $.Deferred();
            var hasFramebox = false;

            // TODO
            if (!this.frameboxes || !this.frameboxes.boxes) {
                dfd.resolve({
                    hasFramebox: false
                });

                return dfd.promise();
            }

            _.each(this.frameboxes.boxes, function(box) {
                if (!box) {
                    return;
                }

                for (var i = 0; i < names.length; i++) {
                    if (box.contentType === names[i]) {
                        hasFramebox = true;
                    }
                }
            }.bind(this));

            dfd.resolve({
                hasFramebox: hasFramebox
            });

            return dfd.promise();
        },

        /**
         * Closes frame box by appId.
         *
         * @param  {String} appId
         */
        closeFrameBox: function(appId) {
            return this.deviceConnection
                .send('setControlApplication', {
                    action: 'close',
                    appId: appId
                });
        },

        /**
         * Closes all live frame boxes (HDMI1, HDMI2).
         */
        closeAllFrameBoxes: function() {
            store.getters['controlScreen/getAppIdSetOfLiveWindows'].forEach(appId => {
                this.closeFrameBox(appId);
            });
        },

        /**
         * @method fullscreenFrameBox
         * @param {String} appId
         */
        fullscreenFrameBox: function(appId) {
            this.deviceConnection
                .send('setControlApplication', {
                    action: 'fullscreen',
                    appId: appId
                });
        },

        /**
         * @method setActiveFrameBox
         */
        setActiveFrameBox: function(frameBoxId) {
            this.activeFrameBox = frameBoxId;
        },

        /**
         * @method getActiveFrameBox
         */
        getActiveFrameBox: function() {
            return this.activeFrameBox;
        },

        /**
         * Returns true if content is on HDMI2 out.
         *
         * @return {Boolean}
         */
        isHdmiOut2: function() {
            // TODO: check implications
            return false;
        },

        /**
         * Returns framebox transparent value
         * @method isTransparent
         */
        isTransparent: function() {
            // TODO: check implications
            return false;
        },

        /**
         * Returns framebox wipe out value
         */
        isWipedOut: function() {
            // TODO: check implications
            return false;
        },

        isFullscreen: function() {
            const dfd = $.Deferred();
            dfd.resolve({
                isFullscreen: this.isFullscreenOpen()
            });

            return dfd.promise();
        },

        isFullscreenOpen: function() {
            if (platform.checks.isCboxProjectionHdmi1) {
                return store.getters['controlScreen/isWindowInFullscreenByOutputPort']('hdmi1');
            } else if (platform.checks.isCboxProjectionHdmi2) {
                return store.getters['controlScreen/isWindowInFullscreenByOutputPort']('hdmi2');
            }

            return false;
        },

        supportsDoubleTap: function() {
            // TODO: check implications
            let dfd = $.Deferred();
            dfd.resolve({
                supported: false
            });

            return dfd.promise();
        },

        /**
         * @method minimizeFrameBox
         * @param {String} appId
         */
        minimizeFrameBox: function(appId) {
            this.deviceConnection
                .send('setControlApplication', {
                    action: 'normal',
                    appId: appId
                });
        },

        /**
         * Send mouse events.
         *
         * @param {Number} x: x coordinates
         * @param {Number} y: y coordinates
         * @param {String} eventType: the mouseevent
         * @param {String} appId: Application ID
         */
        sendMouseEvents: function(x, y, eventType, appId, identifier) {
            // Prevent sending mouse events if cbox fullscreen framebox overlay ui is invisible
            // Var normalizedEventType = getNormalizedMouseEventType(eventType);
            var normalizedEventType = eventType;
            var eventList = [];

            eventList.push({
                event: normalizedEventType,
                appId: appId,
                positionX: x,
                positionY: y,
                identifier: identifier
            });

            this.sendMultiEvents(appId, eventList);
        },

        /**
         * This method is called when the window has been unfocused.
         * Here we will check if there is a open checkdown event then we will send a keyup event.
         */
        onWindowInactive: function() {
            var event = this.lastKeyEvent;

            // RELEASE-3867: Delete key modifier if event isn't sent.
            this.keyModifierEvent = undefined;

            if (event) {
                if ('keydown' === event.keyState) {
                    event.keyState = 'keyup';
                } else if ('touchstart' === event.keyState) {
                    event.keyState = 'touchend';
                }
            } else {
                return;
            }

            this.deviceConnection
                .send('setApplicationMultiEvents', {
                    event: 'keyboard',
                    eventList: [event]
                });
        },

        /**
         * Send multiple events.
         *
         * @param {String} appId: Application ID
         * @param {Array} events
         */
        sendMultiEvents: function(appId, events) {
            var resolvedEvents = [];

            // RELEASE-2484 - Wrong touch event in libreoffice window.
            // Removes all events that makes no sense.
            events.forEach(function(event) {
                // RELEASE-3405: Save last touchstart/mousedown events.
                if (event.event === 'touchstart' || event.event === 'mousedown') {
                    this.lastUnresolvedEvents.push(event);
                } else if (event.event === 'touchend' || event.event === 'mouseup') {
                    this.lastUnresolvedEvents = [];
                }

                if ('touchstart' === event.event && !this.isTouched) {
                    this.isTouched = true;
                } else if ('touchend' === event.event && this.isTouched) {
                    this.isTouched = false;
                }

                event.appId = appId;

                resolvedEvents.push(event);
            }.bind(this));

            if (0 === resolvedEvents.length) {
                return;
            }

            this.deviceConnection
                .send('setApplicationMultiEvents', {
                    event: 'pointer',
                    eventList: resolvedEvents
                });
        },

        /**
         * RELEASE-3405: Before Quick Anntotation is started, check if there are some unresloved events
         * ("open" touchstart/mousedown) and send a touchend/mouseup.
         */
        resolveLastEvents: function() {
            if (this.lastUnresolvedEvents.length === 0) {
                return;
            }

            this.lastUnresolvedEvents.forEach(function(event) {
                switch (event.event) {
                    case 'touchstart':
                        event.event = 'touchend';
                        break;
                    case 'mousedown':
                        event.event = 'mouseup';
                        break;
                }
            }.bind(this));

            this.deviceConnection
                .send('setApplicationMultiEvents', {
                    event: 'pointer',
                    eventList: this.lastUnresolvedEvents
                });

            this.lastUnresolvedEvents = [];
        },

        /**
         * Send Key event.
         *
         * @param {String} appId: Application ID
         * @param {String} keyState
         * @param {Object} event
         *
         */
        sendKeyEvent: function(appId, keyState, event) {
            var eventList = [];
            var keyEvent;

            event = pollyfillUnidentifiedKey(event, this.languageService.keyboardLayout);

            // FIREFOX OS special chars (e.g. norwegian: ø, æ, å; also chinese in the future of PO's dream ;-))
            if (event.type.indexOf('composition') > -1) {
                event.key = event.originalEvent.data;
            }

            if (window.CYNAP_ENV === 'dev') {
                console.log('SEND KEY EVENT:');
                console.log(event);
            }

            /**
             * KEY TO KEY CODE MAPPING
             * - Handle ANDROID and iOS.
             */
            if (platform.checks.isAndroid || platform.checks.isIOS) {
                // OFFICE 365 (e.g. space, arrow up/down,...): Handle keyup when we get the icon-element as the event.key.
                if (event.key.trim().indexOf('<i') === 0) {
                    event.key = '';
                }

                // OFFICE 365 (e.g. space, arrow up/down,...): Use data-character when the event key is empty.
                if (event.key.length === 0 && event.$key && event.$key.data('character')) {
                    event.key = event.$key.data('character');
                }

                if (keyState === 'keydown') {
                    this.lastKeydownEvent = event;
                } else if (keyState === 'keyup' && event.key === 'Unidentified' && this.lastKeydownEvent.keyCode === event.keyCode) {
                    event.key = this.lastKeydownEvent.key;
                }

                // Handle key modifier
                keyEvent = this.getKeyEventModifier(appId, keyState, event.key);

                if (keyEvent) {
                    /**
                     * IOS: Send a shift keyup before a modifier key with AltGr is sent
                     * because the shift key and the key to change to the special keys is the same
                     * and it would send Shift + AltGr + Key.
                     */
                    if (platform.checks.isIOS && keyEvent.keyCode === 100) {
                        eventList.push({
                            event: 'keyboard',
                            appId: appId,
                            keyState: 'keyup',
                            key: 0,
                            keyCode: 42
                        });
                    }

                    eventList.push(keyEvent);
                }

                keyEvent = this.getKeyEvent(appId, keyState, event.key);

                if (keyEvent) {
                    eventList.push(keyEvent);
                }

                if (eventList.length > 0) {
                    this.deviceConnection
                        .send('setApplicationMultiEvents', {
                            event: 'keyboard',
                            eventList: eventList
                        });

                    if (window.CYNAP_ENV === 'dev') {
                        console.log('--- NEW MAPPING ---');
                        console.log(eventList);
                    }

                    return;
                }
            }

            // ------- DEFAULT Mapping -------

            // Send default key + key code.
            if (typeof event !== 'undefined' && (event.key || '' === event.key)) {
                if (false === event.originalEvent.repeat) {
                    var keyCode = event.keyCode;

                    // Letter has been overridden via key-code.
                    if (!event.$key || !event.$key.data('key-code')) {
                        keyCode = keyMapper.mapByEvent(event, event.keyCode);
                    }

                    /**
                     * Handle keys with modifier CONTROL, ALTGRAPH, CONTROL + ALT, ALT
                     * (e.g. @, €, $,...)
                     */
                    if (keyState === 'keydown' && -1 !== keyModifier.indexOf(event.key)) { // Save key modifier on keydown
                        this.keyModifierEvent = event;

                        return;
                    } else if (keyState === 'keydown' && this.keyModifierEvent) { // Send key modifier with at the same time as the symbol
                        keyEvent = this.getKeyEvent(appId, keyState, this.keyModifierEvent.key);
                        eventList.push(keyEvent);
                    } else if (keyState === 'keyup' && this.keyModifierEvent) { // Handle modifier on keyup
                        if (-1 !== keyModifier.indexOf(event.key)) { // Delete key modifier on keyup
                            this.keyModifierUp = this.keyModifierEvent;
                            this.keyModifierEvent = undefined;

                            return;
                        }

                        // Send same key modifier on keyup
                        keyEvent = this.getKeyEvent(appId, keyState, this.keyModifierEvent.key);
                        eventList.push(keyEvent);

                        this.keyModifierUp = undefined;
                    } else if (keyState === 'keyup' && this.keyModifierUp) {
                        // Send up event of key modifier if it wasn't sent before (e.g. CONTROL+A; Release a before control)
                        keyEvent = this.getKeyEvent(appId, keyState, this.keyModifierUp.key);
                        eventList.push(keyEvent);

                        this.keyModifierUp = undefined;
                    }

                    /** Edge, IE or 'Process' (some special keys in firefox mobile, e.g. å)
                     *  - Edge, IE: In WindowsOS with touch keyboard (e.g. surface) there are some special keys (€)
                     *    which should be sent with another keycode (ieSpecialkeyMobile)
                     */
                    if (platform.checks.isIe || platform.checks.isEdge || event.key === 'Process') {
                        keyEvent = this.getIEKeyEventModifier(appId, keyState, event.key, keyCode, eventList);
                    } else if (platform.checks.isMacOS && event.key === '@') {
                        keyEvent = this.getKeyEvent(appId, keyState, event.key);
                    } else {
                        keyEvent = {
                            event: 'keyboard',
                            appId: appId,
                            keyState: keyState,
                            key: 0,
                            keyCode: keyCode
                        };
                    }

                    eventList.push(keyEvent);
                }
            }

            if (eventList[0] && -1 !== keyDownEvents.indexOf(eventList[0].keyState)) {
                this.lastKeyEvent = eventList[0];
            } else if (eventList[0] && -1 !== keyUpEvents.indexOf(eventList[0].keyState)) {
                this.lastKeyEvent = null;
            }

            this.deviceConnection
                .send('setApplicationMultiEvents', {
                    event: 'keyboard',
                    eventList: eventList
                });

            if (window.CYNAP_ENV === 'dev') {
                console.log('--- DEFAULT MAPPING ---');
                console.log(eventList);
            }
        },

        /**
         * Get key from key mapping.
         *
         * @param {String} appId: Application ID
         * @param keyState
         * @param key
         * @returns {*}
         */
        getKeyEvent: function(appId, keyState, key) {
            var keyCode;
            var keyEncoded = this.encodeChar(key.toLowerCase());

            if (key.length === 1) {
                keyCode = this.keyToKeyCodeMapping[keyEncoded]
                    || this.keyToKeyCodeMappingFallback[keyEncoded];
            } else {
                keyCode = this.keyToKeyCodeMapping[key.toLowerCase()]
                    || this.keyToKeyCodeMappingFallback[key.toLowerCase()];
            }

            if (keyCode) {
                if (window.CYNAP_ENV === 'dev') {
                    console.log('getKeyEvent KEY ' + key + ' to ' + keyEncoded
                        + ' | send KeyCode ' + keyCode);
                }

                return {
                    event: 'keyboard',
                    appId: appId,
                    keyState: keyState,
                    key: 0,
                    keyCode: keyCode,
                    shift: 0,
                    alt: 0,
                    ctrl: 0
                };
            }

            if (window.CYNAP_ENV === 'dev') {
                console.log('getKeyEvent KEY ' + key + ' to ' + keyEncoded + ' | keyCode is undefined');
            }

            return undefined;
        },

        /**
         * Get key modifier from mapping for Internet Explorer and Microsoft Edge.
         * (E.g. AltGr for german @)
         *
         * @param {String} appId: Application ID
         * @param {String} keyState
         * @param {String} key
         * @param {String} keyCode
         *
         * @returns {Object}
         *
         * TODO: Move to key-mapper.js
         */
        getIEKeyEventModifier: function(appId, keyState, key, keyCode, eventList) {
            var keyModifier;
            var keyEncoded = this.encodeChar(key.toLowerCase());

            if ((eventList.length > 0 && eventList[0].keyCode === 100 && this.keyToKeyCodeMapping['ie_specialkeys_mobile'][keyEncoded])
                || this.ieSpecialkeyMobile) {
                keyModifier = this.keyToKeyCodeMapping['ie_specialkeys_mobile'][keyEncoded];
                this.ieSpecialkeyMobile = !this.ieSpecialkeyMobile;
            } else if (this.keyToKeyCodeMapping['ie_specialkeys'][keyEncoded]) {
                keyModifier = this.keyToKeyCodeMapping['ie_specialkeys'][keyEncoded];
            } else if (this.keyToKeyCodeMapping[keyEncoded]) {
                keyModifier = this.keyToKeyCodeMapping[keyEncoded];
            }

            if (keyModifier) {
                if (window.CYNAP_ENV === 'dev') {
                    console.log('getIEKeyEventModifier KEY ' + key + ' to ' + keyEncoded
                        + ' | send KeyModifier ' + keyModifier);
                }

                return {
                    event: 'keyboard',
                    appId: appId,
                    keyState: keyState,
                    key: 0,
                    keyCode: keyModifier,
                    shift: 0,
                    alt: 0,
                    ctrl: 0
                };
            }

            if (window.CYNAP_ENV === 'dev') {
                console.log('getIEKeyEventModifier KEY ' + key + ' to ' + keyEncoded
                    + ' | send KeyCode ' + keyCode + ' (keyModifier is undefined)');
            }

            return {
                event: 'keyboard',
                appId: appId,
                keyState: keyState,
                key: 0,
                keyCode: keyCode,
                shift: 0,
                alt: 0,
                ctrl: 0
            };
        },

        /**
         * Get key modifier from mapping.
         * (E.g. AltGr for german @)
         *
         * @param {String} appId: Application ID
         * @param keyState
         * @param key
         * @returns {*}
         *
         * TODO: Move to key-mapper.js
         */
        getKeyEventModifier: function(appId, keyState, key) {
            var keyModifier;
            var keyEncoded = this.encodeChar(key.toLowerCase());

            // Used for mobile devices (iOS, Android) when we get only the uppercase character (e.g. A) without the shift key.
            if (this.isUpperCase(key) && (platform.checks.isAndroid || platform.checks.isIOS)) {
                keyModifier = this.keyToKeyCodeMapping['modifier']['uppercase']
                    || this.keyToKeyCodeMappingFallback['modifier']['uppercase'];
            } else if (key.length === 1) {
                keyModifier = this.keyToKeyCodeMapping['modifier'][keyEncoded]
                    || this.keyToKeyCodeMappingFallback['modifier'][keyEncoded];

                if (!keyModifier && (platform.checks.isAndroid || platform.checks.isIOS)) {
                    keyModifier = this.keyToKeyCodeMapping['modifier_mobile'][keyEncoded];
                }
            } else {
                keyModifier = this.keyToKeyCodeMapping['modifier'][key.toLowerCase()]
                    || this.keyToKeyCodeMappingFallback['modifier'][key.toLowerCase()];
            }

            if (window.CYNAP_ENV === 'dev') {
                console.log('getKeyEventModifier KEY ' + key + ' to ' + keyEncoded + ' | send KeyModifier ' + keyModifier);
            }

            if (keyModifier) {
                return {
                    event: 'keyboard',
                    appId: appId,
                    keyState: keyState,
                    key: 0,
                    keyCode: keyModifier,
                    shift: 0,
                    alt: 0,
                    ctrl: 0
                };
            }

            return undefined;
        },

        /**
         * Check if key is uppercase.
         *
         * @param key
         * @returns {boolean}
         */
        isUpperCase: function(key) {
            return key.length === 1 && key === key.toUpperCase() && key !== key.toLowerCase();
        },

        /**
         * Encode character (Character to HTML code).
         *
         * @param char
         * @returns {string}
         */
        encodeChar: function(char) {
            var buf = [];

            for (var i = char.length - 1; i >= 0; i--) {
                buf.unshift(['&#', char[i].charCodeAt(), ';'].join(''));
            }

            return buf.join('');
        },

        /**
         * Decode character (HTML code to readable character)
         * @param char
         * @returns {*}
         */
        decodeChar: function(char) {
            return char.replace(/&#(\d+);/g, function(match, dec) {
                return String.fromCharCode(dec);
            });
        },

        /**
         * Returns true when ui is visible.
         */
        isUiVisible: function() {
            return uiStates.visible === this.uiState.getState();
        },

        /**
         * Map framebox component to component type.
         *
         * @param frameBoxComponent
         * @returns componentType
         */
        contentTypeMapping: function(frameBoxComponent) {
            return componentMapping[frameBoxComponent];
        },

        /**
         * Set box which is curerntly in fullscreen (= selected tab)
         * @param box
         */
        setFullscreenBox: function(box) {
            this.fullscreenBox = box;
        },

        /**
         * Get box which is curerntly in fullscreen (= selected tab).
         */
        getFullscreenBox: function() {
            return this.fullscreenBox;
        }
    };
});
