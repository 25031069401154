// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"browser-url-bar\" name=\"browser-url-bar\">\n    <span class=\"icon-v2-search\"></span>\n    <input name=\"url\"\n           type=\"text\"\n           value=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"frameBoxOptions") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\"\n           placeholder=\""
    + alias1((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"webbrowser.search_type_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":24},"end":{"line":6,"column":58}}}))
    + "\"\n           class=\"browser-viewer-url-input block-on-ok focusable\" />\n</form>\n";
},"useData":true});
