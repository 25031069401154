'use strict';

var app = require('../../../../app');
var tpl = require('./close-configurator.hbs');

app.component('MatrixCloseConfigurator', {
    template: tpl,
    className: 'close-configurator',

    getAccessKey: function() {
        return {
            'roleName': 'MatrixSettings',
            'roleKey': 'show'
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindDOMEvents();
    },

    storeSelectors: function() {
        this.$savingInfo = this.$el.find('.saving-info');
        this.$saveButton = this.$el.find('.close-configurator-button-container.save');
        this.$discareButton = this.$el.find('.close-configurator-button-container.discare');
    },

    bindDOMEvents: function() {
        this.$el.on('click', '[data-action]', this.onAction.bind(this));
    },
    /**
     * Open Matrix settings.
     */
    openMatrixSettings: function() {
        setTimeout(function() {
            this.emit('overlay.open', {
                id: 'matrix-settings',
                extendedConfigs: 'settings-list'
            });
        }.bind(this), 250);
    },

    /**
     * Handle modal when save progress is finished.
     *
     * @param finished true/false
     */
    handleSaveFinished: function(finished) {
        if (finished === this.saveFinished) {
            return;
        }

        if (finished) {
            this.$savingInfo.hide();
            this.enableButtons(true);

            this.emit('overlay.close');
            this.emit('backdrop.hide');
            this.emit('menu.close');
            this.emit('modal.close');

            this.openMatrixSettings();
        } else {
            this.$savingInfo.show();
            this.enableButtons(false);
        }

        this.saveFinished = finished;
    },

    /**
     * Enable/disable buttons while saving.
     *
     * @param enabled true/false
     */
    enableButtons: function(enabled) {
        if (enabled) {
            this.$saveButton
                .removeClass('is-disabled')
                .find('.btn-form').prop('disabled', false);

            this.$discareButton
                .removeClass('is-disabled')
                .find('.btn-form').prop('disabled', false);
        } else {
            this.$saveButton
                .addClass('is-disabled')
                .find('.btn-form').prop('disabled', true);

            this.$discareButton
                .addClass('is-disabled')
                .find('.btn-form').prop('disabled', true);
        }
    },

    /**
     * Handle button click.
     *
     * @param event Current event
     */
    onAction: function(event) {
        var $el = this.$(event.currentTarget);
        var action = $el.data('action');
        var handler = this.actionHandlers[action];

        if (handler) {
            handler.call(this);
        }
    },

    /**
     * Action handles.
     */
    actionHandlers: {
        'discare': function() {
            this.emit('overlay.close');
            this.emit('backdrop.hide');
            this.emit('menu.close');
            this.emit('modal.close');

            this.openMatrixSettings();
        },

        'save': function() {
            this.handleSaveFinished(false);

            this.emit('matrix-grid.save', function() {
                this.on('matrix.save.enable', this.handleSaveFinished.bind(this));
            }.bind(this));
        }
    }
});

