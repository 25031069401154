'use strict';

var instance = null;
var $ = require('jquery');

/**
 * @class ScrollHelper
 * @constructor
 */
var ScrollHelper = function() {
    this.loopCount = 0;
};

/**
 * @param {jQuery.Element} $node
 * @returns {*}
 */
ScrollHelper.prototype.findClosestScrollableElement = function($node) {
    this.loopCount++;

    if (!$node.length || this.loopCount >= 15) {
        return null;
    }

    var scrollSize = $node.get(0).scrollHeight;
    var clientSize = $node.get(0).clientHeight;

    if ($node.hasClass('scroll-horizontal')) {
        scrollSize = $node.get(0).scrollWidth;
        clientSize = $node.get(0).offsetWidth;
    }

    if (scrollSize > clientSize) {
        return $node;
    } else {
        return this.findClosestScrollableElement($node.parent());
    }
};

/**
 * @param {jQuery.Element} $node
 * @returns {*}
 */
ScrollHelper.prototype.closestScrollableElement = function($node) {
    let node = $node[0].parentElement;

    while (node) {
        if (node.scrollHeight > node.clientHeight || node.scrollWidth > node.clientWidth) {
            return $(node);
        }

        node = node.parentElement;
    }

    return undefined;
};

/**
 * Find position of DOM Element inside scrollable div
 * @param item DOM item
 * @param item DOM scrollable div
 */
ScrollHelper.prototype.findPositionInsideScroll = function(item, scrollEl) {
    let offsetOfScroll = {
        top: 0,
        left: 0
    };

    let offsetOfItem = {
        top: 0,
        left: 0
    };

    let node = scrollEl;

    while (node && node !== scrollEl.offsetParent) {
        offsetOfScroll = {
            top: offsetOfScroll.top + node.offsetTop,
            left: offsetOfScroll.left + node.offsetLeft
        };

        node = node.offsetParent;
    }

    node = item;
    while (node && node !== scrollEl.offsetParent) {
        offsetOfItem = {
            top: offsetOfItem.top + node.offsetTop,
            left: offsetOfItem.left + node.offsetLeft
        };

        node = node.offsetParent;
    }

    // Handle virtual scroller
    const virtualScrollerItem = item.closest('.vue-recycle-scroller__item-view');
    if (virtualScrollerItem) {
        const matrix = new window.WebKitCSSMatrix(window.getComputedStyle(virtualScrollerItem).transform);
        const translateY = matrix.m42;
        if (typeof translateY === 'number') {
            offsetOfItem.top += translateY;
        }
    }

    return {
        top: offsetOfItem.top - offsetOfScroll.top,
        left: offsetOfItem.left - offsetOfScroll.left
    };
};

/**
 * Scrolls an element to visible
 * @param {jQuery.Element} $item
 * @param $scrollEl
 */
ScrollHelper.prototype.checkFocusedElement = function($item, $scrollEl) {
    // Block scrollhelper
    if ($scrollEl && typeof $scrollEl.data('scroll-helper') === 'boolean' && $scrollEl.data('scroll-helper') === false) {
        return;
    }

    if (!$item || !$scrollEl) {
        return;
    }

    // Check if items should be scrolled horizontal
    if ($item.hasClass('scroll-horizontal')) {
        var elRight = $item.offset().left + $item.outerWidth();
        var scrollRight = $scrollEl.width();

        // Check right
        if (scrollRight - elRight < 0) {
            $scrollEl.scrollLeft($scrollEl.scrollLeft() - (scrollRight - elRight));

            return;
        }

        if ($item.offset().left < 0) {
            $scrollEl.scrollLeft($scrollEl.scrollLeft() + $item.offset().left);
        }
    } else {
        const el = $item[0];
        const scrollEl = $scrollEl[0];
        const isScrollable = scrollEl.scrollHeight > scrollEl.clientHeight;

        if (isScrollable) {
            const positionInScroll = this.findPositionInsideScroll(el, scrollEl);

            const bottom = positionInScroll.top + el.offsetHeight;
            const scrollBottom = scrollEl.scrollTop + scrollEl.clientHeight;

            // Apply a padding, so scroll element scrolls a bit more than required
            const SCROLL_PADDING_FACTOR = 0.14;
            const scrollPadding = scrollEl.offsetHeight * SCROLL_PADDING_FACTOR;

            let newScrollTop;
            if (positionInScroll.top < scrollEl.scrollTop) {
                newScrollTop = positionInScroll.top - scrollPadding;
            } else if (bottom > scrollBottom) {
                newScrollTop = bottom - scrollEl.offsetHeight + scrollPadding;
            }

            if (typeof scrollEl.scrollTo === 'function') {
                // Use smooth scrolling if possible
                scrollEl.scrollTo({
                    top: newScrollTop,
                    behavior: 'smooth'
                });
            } else {
                scrollEl.scrollTop = newScrollTop;
            }
        }
    }
};

/**
 * @returns {ScrollHelper}
 */
var getInstance = function() {
    if (null === instance) {
        instance = new ScrollHelper();
    }

    return instance;
};

module.exports = getInstance();
