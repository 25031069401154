'use strict';

var app = require('../../../app');
var template = require('./wlan-region.hbs');
var FormActionView = require('../form-action-view');
var _ = require('lodash');
var states = require('./../../../states');

app.component('WlanRegion', {
    template: template,

    getAccessKey: function() {
        return {
            'roleName': 'WlanSettings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.createSelects();
        this.initForm();
        this.initFormAction();
        this.bindEvents();
        this.bindDOMEvents();

        // Clear selection
        this.cancelHandler();
    },

    storeSelectors: function() {
        this.$infoContainer = this.$el.find('#wlan-region-info');
        this.$infoRegion1 = this.$el.find('#region1-info-container');
        this.$infoRegion2 = this.$el.find('#region2-info-container');
    },

    createSelects: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#wlan-region-select'),
            label: 'settings.choose_country',
            native: true,
            name: 'wlanRegionSelect',
            items: this.getCountries()
        });
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#wlan-region'),
            validationContainer: '.input-group'
        });
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this),
                autoClose: false
            });
        }

        this.actionView.render();
    },

    bindEvents: function() {
        this.on('settings.save-changes', this.saveHandler.bind(this));
    },

    bindDOMEvents: function() {
        this.form.on('change.input', this.selectCountry.bind(this));
    },

    /**
     * Get country list for custom select.
     */
    getCountries: function() {
        var countryItems = [];

        _.each(states.countries, function(item) {
            countryItems.push({
                text: item.text,
                value: item.value
            });
        });

        return countryItems;
    },

    /**
     * Handle country selection.
     *
     * @param event Selected item from custom select.
     */
    selectCountry: function(event) {
        this.country = event.value;

        if (!this.country) {
            return;
        }

        this.countryName = this.getCountryNameByValue(this.country);
        this.region = this.getRegionByCountry(this.country);

        this.actionView.enableSubmitButton();
        this.actionView.open();

        this.showInformation();
    },

    /**
     * Show information depending on selected country and region.
     */
    showInformation: function() {
        this.$infoContainer.find('#selected-country').text(this.countryName);

        if (this.region === 1) {
            this.$infoRegion1.show();
            this.$infoRegion2.hide();
        } else {
            this.$infoRegion2.show();
            this.$infoRegion1.hide();
        }

        this.$infoContainer.show();
    },

    /**
     * Get region by country.
     *
     * @param country Selected country.
     * @returns {number} 1 = Region 1,2 = Region 2
     */
    getRegionByCountry: function(country) {
        var isRegion1 = false;

        if (country !== 'world') { // World => region 2
            isRegion1 = states.countriesRegion1.find(function(item) {
                return (item.value === country);
            }.bind(this));
        }

        return isRegion1 ? 1 : 2;
    },

    /**
     * Get country name by value (shortcut).
     *
     * @param value Shortcut from selected country.
     * @returns Country name
     */
    getCountryNameByValue: function(value) {
        var country = states.countries.find(function(item) {
            return (item.value === value);
        }.bind(this));

        return country.text;
    },

    saveHandler: function() {
        if (!this.region || !this.country) {
            this.cancelHandler();

            return;
        }

        this.emit('modal.open', {
            id: 'wlan-region-warning',
            country: this.countryName,
            onConfirm: this.onConfirmRegion.bind(this)
        });
    },

    cancelHandler: function() {
        this.form.get('wlanRegionSelect').setValue('');

        this.country = null;
        this.region = null;

        this.$infoContainer.hide();
        this.actionView.close();
    },

    /**
     * Set Region and close overlay.
     */
    onConfirmRegion: function() {
        this.deviceConnection
            .send([
                {
                    command: 'setAdjustmentWlanRegion',
                    data: {
                        region: this.region,
                        country: this.country
                    }
                }
            ]);

        this.emit('overlay.close');
    },

    /**
     * Do not show has changes dialog.
     * @returns {{hasChanges: boolean, invalid: boolean}}
     */
    hasChanges: function() {
        return {
            hasChanges: false,
            invalid: false
        };
    },

    destroy: function() {
        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }
    }
});
