const template = require('./edit-mode.html');
const vueUtils = require('../../../components-vue/util.js');
const helper = require('../../../helper.js');
const app = require('../../../app');
const platform = require('../../../../platform/platform');
const Vuex = require('vuex');
const $ = require('jquery');
const { resolutions } = require('../../../states');

module.exports = {
    template,
    components: {
        'live-stream': require('../live-stream/live-stream'),
        'edit-mode-annotation': require('./edit-mode-annotation/edit-mode-annotation')
    },

    data: function() {
        return {
            outputPort: 'edit',
            aspectRatioDisparity: 0,
            appInfo: {},
            freeze: null,
            liveStreamSize: {
                width: 0,
                height: 0
            }
        };
    },

    computed: {
        /**
         * Window of an application in edit mode.
         */
        editWindow: function() {
            return this.getWindowsByOutputPort(this.outputPort).map(window => {
                const coords = helper.convertCoordinates(window.coordinates, window.reference, this.liveStreamSize);

                // Edit window aspect ratio difference compared to control monitor aspect ratio
                const controlMonitorAspectRatio = window.reference.width / window.reference.height;
                const editWindowAspectRatio = coords.width / coords.height;
                this.aspectRatioDisparity = editWindowAspectRatio / controlMonitorAspectRatio;

                return Object.assign({}, window, {
                    coordinates: coords
                });
            })[0];
        },

        /**
         * Whole output in edit mode (HDMI1 or HDMI2).
         */
        editOutput: function() {
            return this.getOutputsInEdit.map(output => {
                const coords = helper.convertCoordinates(output.coordinates, output.reference, this.liveStreamSize);
                this.aspectRatioDisparity = 1;

                return Object.assign({}, output, {
                    coordinates: coords
                });
            })[0];
        },

        /**
         * The content in edit mode. Can be a single application window or a whole output (HDMI1 or HDMI2).
         */
        editContent: function() {
            return this.editWindow ?? this.editOutput;
        },

        annotationPlaneCoordinates: function() {
            return {
                x: this.editContent.coordinates.x,
                y: this.editContent.coordinates.y,
                width: this.editContent.coordinates.width,
                height: this.editContent.coordinates.height
            };
        },

        isRemote: function() {
            return !platform.checks.isCboxControlScreen;
        },

        isLiveContent: function() {
            return this.editWindow ? this.isWindowLive(this.editWindow.appId) : false;
        },

        /**
         * Calculate SVG clip path for edit backdrop on cbox.
         * Clip path is used to cut out the inner box where the edit window is located.
         * E.g. 'M 0,0, 1920,0, 1920,1080, 0,1080 Z M 150,40, 150,952, 1770,952, 1770,40 Z'
         */
        backdropClipPath: function() {
            const outerBox = [
                { x: 0, y: 0 },
                { x: this.liveStreamSize.width, y: 0 },
                { x: this.liveStreamSize.width, y: this.liveStreamSize.height },
                { x: 0, y: this.liveStreamSize.height }
            ];

            const innerBox = [
                {
                    x: this.editContent.coordinates.x,
                    y: this.editContent.coordinates.y
                },
                {
                    x: this.editContent.coordinates.x,
                    y: this.editContent.coordinates.y + this.editContent.coordinates.height
                },
                {
                    x: this.editContent.coordinates.x + this.editContent.coordinates.width,
                    y: this.editContent.coordinates.y + this.editContent.coordinates.height
                },
                {
                    x: this.editContent.coordinates.x + this.editContent.coordinates.width,
                    y: this.editContent.coordinates.y
                }
            ];

            const outer = outerBox.map(c => {
                return c.x + ',' + c.y;
            });
            const inner = innerBox.map(c => {
                return c.x + ',' + c.y;
            });

            return 'M ' + outer[0] + ', ' + outer[1] + ', ' + outer[2] + ', ' + outer[3] + ' Z M '
                + inner[0] + ', ' + inner[1] + ', ' + inner[2] + ', ' + inner[3] + ' Z';
        },

        ...Vuex.mapGetters('controlScreen', ['getWindowsByOutputPort', 'getOutputsInEdit',
            'isEditModeActive', 'isWindowLive', 'isOverlayOpen'])
    },

    watch: {
        editWindow: function(val) {
            app.emit('framebox.change.' + val.appId, this.getAppInfo(val.appId, val));
        }
    },

    beforeCreate() {
        this.liveStream = app.getService('LiveStreamService');
        this.frameBoxService = app.getService('FrameBoxService');
        this.annotationService = app.getService('AnnotationService');
        this.keyboard = app.getService('KeyboardService');
    },

    created: function() {
        this.wolfprot = vueUtils.wolfprot();
        this.liveStreamSize = this.liveStream.getSize();
        this.LIVE_STREAM_RESOLUTION = resolutions['1080p']; // Use 1080p resolution for the live stream in edit mode.

        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return this.wolfprot.talk('getEditContentFreeze')
                    .then(function(freezeState) {
                        return {
                            freezeState
                        };
                    }.bind(this));
            }.bind(this)
        });
        this.pollHelper.on('data', function({ freezeState }) {
            this.freeze = freezeState.freeze;
        }.bind(this));
        this.pollHelper.schedulePoll();

        this.evctx = vueUtils.eventContext();
        this.evctx.on('main-loop.update', function() {
            this.pollHelper.schedulePoll();
        }.bind(this));
        this.evctx.on('main-loop.update.edit-mode', function() {
            this.pollHelper.schedulePoll();
        }.bind(this));

        this.evctx.on('livestream-size.update', function() {
            this.liveStreamSize = this.liveStream.getSize();
        }.bind(this));
        this.evctx.on('aspect-ratio.changed', function() {
            this.liveStreamSize = this.liveStream.getSize();
        }.bind(this));
    },

    mounted: function() {
        if (this.editWindow) {
            const appInfo = this.getAppInfo(this.editWindow.appId, this.editWindow);

            app.createComponent('EditFrameBox', {
                type: 'FrameBoxUI',
                container: this.$refs.editFramebox,
                index: appInfo.appId, // Use appId since there is no useful window index in control screen
                appId: appInfo.appId,
                titleKey: appInfo.titleKey,
                titleIconKey: appInfo.titleIconKey,
                component: appInfo.component,
                webRtcOut: appInfo.webRtcOut,
                customClose: appInfo.customClose,
                contentType: appInfo.contentType,
                frameBoxOptions: appInfo.frameBoxOptions,
                focus: appInfo.focus,
                withoutControlBar: true,
                hideBackdrop: true
            });

            app.createComponent('EditControlBar', {
                type: 'FrameboxControlBar',
                container: this.$refs.editBar,
                frameboxComponent: appInfo.component,
                index: appInfo.appId, // Use appId since there is no useful window index in control screen
                appId: appInfo.appId,
                title: appInfo.titleKey,
                titleIcon: appInfo.titleIconKey,
                frameBoxOptions: appInfo.options,
                $actionEl: $(this.$refs.editFramebox),
                isFixed: true
            });
        }
    },

    methods: {
        stopEditMode: function() {
            if (this.annotationService.isActive()) {
                app.emit('modal.open', {
                    id: 'edit-mode-annotation-end',
                    additionalMessageKey: this.isLiveContent ? 'annotation.edit_mode_annotation_end' : 'annotation.edit_mode_annotation_save',
                    onDiscare: function() {
                        // Stop annotation without taking a snapshot (also ends edit mode)
                        this.annotationService.stopAnnotationExternal(false, true);
                    }.bind(this),
                    onConfirm: function() {
                        // Stop annotation and replace live content with snapshot (also ends edit mode)
                        this.annotationService.stopAnnotationExternal(false, false, true)
                            .then(() => {
                                app.emit('main-loop.fast.start', { id: 'osd' });
                                app.emit('main-loop.fast.start', { id: 'prepare-list' });
                            });
                    }.bind(this),
                    onCancel: function() {
                        // Nothing to do here
                    }.bind(this)
                });
            } else {
                this.$emit('stop-edit-mode', {
                    appId: this.editContent.appId
                });
            }
        },

        getAppInfo: function(appId, rawAppInfos) {
            let appInfos = {};
            let type = rawAppInfos.contentType;
            let configs;

            // Handle UVC Input. (show VZ controls if UVC Input is a Visualizer)
            if (rawAppInfos.contentType === 'webcam' && rawAppInfos.options.type === 'visualizer') {
                type = 'Visualizer';
            }

            // TODO Ctrl Screen: move to common file
            configs = this.frameBoxService.getConfig(type);

            if (!configs) {
                return;
            }

            // TODO Ctrl Screen: refactor this method
            appInfos.appId = appId;
            appInfos.contentType = rawAppInfos.contentType;
            appInfos.coordinates = rawAppInfos.coordinates;
            appInfos.options = rawAppInfos.options;
            appInfos.component = configs.component;
            appInfos.titleKey = configs.titleKey;
            appInfos.titleIconKey = configs.titleIconKey;

            return appInfos;
        }
    },

    beforeDestroy: function() {
        app.removeComponent('EditFrameBox');
        app.removeComponent('EditControlBar');

        this.keyboard.close();
    }
};
