'use strict';

var app = require('../../../../app');
var opencastSettingsTpl = require('./opencast-settings.hbs');
var FormActionView = require('../../form-action-view');
var $ = require('jquery');
var platform = require('../../../../../platform/platform');
var states = require('../../../../states');

app.component('OpencastSettings', {
    template: opencastSettingsTpl,
    actionView: null,

    getAccessKey: function() {
        return {
            'roleName': 'RecordSettings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.formData = {};
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.validate = this.getService('ValidateService');
        this.lectureCaptureService = this.getService('LectureCaptureService');
        this.overrideDisableButton = false;
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.initForm();
        this.form.setValues(this.formData);
        this.initFormAction();
        this.bindDOMEvents();
        this.bindEvents();
        this.handleCertModeChanged();
        this.setOpencastState();
    },

    serialize: function() {
        var dfd = this.$.Deferred();

        this.loadOpencastSettings()
            .then(function() {
                dfd.resolve({
                    isCbox: platform.checks.isCbox
                });
            }.bind(this));

        return dfd.promise();
    },

    storeSelectors: function() {
        this.$noEntry = this.$el.find('#certificate-list-no-entry');
        this.$loginState = this.$el.find('#opencast-login-state');
        this.$opencastAdhocSettings = this.$el.find('[data-opencast-adhoc-view]');
    },

    bindEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.on('remote.enter.keydown', this.checkActionForm.bind(this));
        this.on('settings.save-changes', this.saveWithCertificate.bind(this));
        this.on('opencast.login-state.changed', this.onLoginStateChanged.bind(this));
        this.on('lcs-type.changed', this.handleLcsTypeChanged.bind(this));
    },

    bindDOMEvents: function() {
        this.form.get('opencastCertMode').$el.on('change', this.handleCertModeChanged.bind(this));
    },

    /**
     * Called after login-state of the opencast service has been changed.
     */
    onLoginStateChanged: function() {
        this.setOpencastState();
    },

    /**
     * Handle change event after the lcs-type has been changed.
     *
     * @param {Object} options
     */
    handleLcsTypeChanged: function(options) {
        this.checkRecordingType(options.lcsType);
    },

    /**
     * This method is called after type-select has been changed.
     * Here we will show all the correct forms.
     *
     * @param type
     */
    checkRecordingType: function(type) {
        switch (type) {
            case states.lcsTypes.opencastAdhoc:
                this.$opencastAdhocSettings.show();
                break;
            case states.lcsTypes.opencastAuto:
                this.$opencastAdhocSettings.hide();
                break;
        }
    },

    /**
     * Switch between the login states.
     */
    setOpencastState: function() {
        var state = this.lectureCaptureService.getLoginState('opencast');

        this.$loginState.attr('data-opencast-login-state', state);
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container-opencast'),
                onSubmit: this.saveWithCertificate.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    /**
     * Read an save the added certificate.
     * If there is no certificate selected only save the other data.
     */
    saveWithCertificate: function() {
        var file;
        var reader = new FileReader();

        if (platform.checks.isCbox) {
            return this.saveHandler('');
        }

        file = this.form.get('opencastCertificateFile').getValue();

        if (file) {
            reader.onload = function(event) {
                this.saveHandler(event.target.result);
            }.bind(this);

            reader.readAsArrayBuffer(file);

            this.form.get('opencastCertificateFile').setValue(null);
        } else {
            this.saveHandler('', '');
        }
    },

    /**
     * Saves all given data on cynap.
     *
     * @param {ArrayBuffer} fileBuffer
     */
    saveHandler: function(fileBuffer) {
        var data;

        if (this.form.validate()) {
            data = this.form.serialize();
            data = {
                hostname: data.opencastHostname,
                captureAgent: data.opencastCaptureAgent,
                username: data.opencastUsername,
                password: data.opencastPassword,
                workflowId: data.opencastWorkflowId,
                certificate: data.opencastCertMode
            };

            if (fileBuffer) {
                data.certificateBlob = fileBuffer;
            }

            this.deviceConnection
                .send([
                    {
                        command: 'setOpencastSettings',
                        data: data
                    }
                ])
                .then(function() {
                    this.handleSettingsSave();

                    if (!platform.checks.isCbox) {
                        setTimeout(this.loadOpencastSettings.bind(this), 500);
                    }
                }.bind(this));
        }
    },

    /**
     * Called when the certification-mode has been enabled or disabled.
     */
    handleCertModeChanged: function() {
        var certMode;

        if (!platform.checks.isCbox && !!this.form.get('opencastCertificateFile')) {
            certMode = this.form.get('opencastCertMode').getValue();

            if (certMode) {
                this.form.get('opencastCertificateFile').enable();
            } else {
                this.form.get('opencastCertificateFile').disable();
            }
        }
    },

    /**
     * Calls on clicked the cancel button.
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();

        this.handleChanges(false);
    },

    /**
     * Called when some data has been changed in this form.
     */
    handleFormChange: function() {
        this.actionView.open();
        this.remote.update();
        this.checkActionForm();

        this.handleChanges(true);
    },

    checkActionForm: function() {
        if (this.form.validate() && !this.overrideDisableButton) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * Initialize Form-manager.
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#opencast-settings-form'),
            validationContainer: '.input-group'
        });
    },

    /**
     * Update overlay after saving data.
     */
    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });
        this.form.setDefaultValues();
        this.emit('overlay.remote.focus', true);
        this.emit('opencast.saved');

        this.handleChanges(false);
    },

    /**
     * Load all needed settings.
     */
    loadOpencastSettings: function() {
        return this.deviceConnection
            .send([
                'getOpencastSettings'
            ])
            .then(function(opencastSettings) {
                this.formData = {
                    opencastHostname: opencastSettings.hostname,
                    opencastCaptureAgent: opencastSettings.captureAgent,
                    opencastUsername: opencastSettings.username,
                    opencastPassword: opencastSettings.password,
                    opencastWorkflowId: opencastSettings.workflowId,
                    opencastCertMode: opencastSettings.certificate
                };
            }.bind(this));
    },

    destroy: function() {
        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    handleChanges: function(change) {
        var changes = {
            hasChanges: change,
            invalid: $(this.actionView.$submitBtn).prop('disabled')
        };

        this.emit('recording-settings.changed', changes);
    }
});
