// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "col-xs-12";
},"3":function(container,depth0,helpers,partials,data) {
    return "col-xs-6";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"remote-streaming-settings\" name=\"remote-streaming-settings\">\n        <div id=\"remote-stream-mode-container\">\n            <div class=\"row settings-list-row\"\n                 data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"col-xs-8\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.streaming_remote",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":9,"column":57}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"enableRemote\" name=\"enableRemote\" type=\"checkbox\" class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"remote-streaming-settings\">\n                <div class=\"row settings-list-secundary\"\n                     data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                     data-nav-trapped>\n                    <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFullsize") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":24,"column":32},"end":{"line":24,"column":84}}})) != null ? stack1 : "")
    + "\">\n                        <div id=\"mode-select\"></div>\n                    </div>\n                </div>\n\n                <div id=\"youtube-stream-container\"></div>\n                <div id=\"custom-stream-container\"></div>\n            </div>\n        </div>\n    </form>\n</div>\n\n<div class=\"row settings-list-row settings-list-info wc-hdcp-deactivation-info hidden\">\n    <div class=\"col-xs-12\">\n        <p class=\"input-info-message error-message\">\n            "
    + alias3((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias2).call(alias1,depth0,{"name":"tr","hash":{"key":"settings.hdcp_deactivation_info"},"data":data,"loc":{"start":{"line":39,"column":12},"end":{"line":39,"column":61}}}))
    + "\n        </p>\n    </div>\n</div>\n";
},"useData":true});
