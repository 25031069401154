'use strict';

var $ = require('jquery');
var showPasswordButtonTpl = require('./show-password-button.hbs');
var cssClasses = {
    isPasswordField: 'is-password-field'
};

/**
 * @class PasswordField
 *
 * @param {jQuery.Element} $el
 * @param {jQuery.Element} $input
 * @constructor
 */
var PasswordField = function($el, $input) {
    /**
     * @type {jQuery.Element}
     */
    this.$el = $el;

    /**
     * @type {jQuery.Element}
     */
    this.$input = $input;

    /**
     * @type {jQuery.Element}
     */
    this.$showPasswordEl = null;

    this.initialize();
    this.bindDOMEvents();
};

PasswordField.prototype.initialize = function() {
    this.$input.addClass(cssClasses.isPasswordField);

    if (this.$input.is(':focus')) {
        this.appendShowPasswordButton();
    }
};

/**
 * Bind all dom events
 */
PasswordField.prototype.bindDOMEvents = function() {
    this.$input.on('focusin', this.onFocus.bind(this));
    this.$input.on('focusout', this.onFocusOut.bind(this));
};

/**
 * On focus out
 */
PasswordField.prototype.onFocusOut = function() {
    if (this.$showPasswordEl) {
        this.$showPasswordEl.hide();
    }
};

/**
 * On focus
 */
PasswordField.prototype.onFocus = function() {
    this.appendShowPasswordButton();
};

/**
 * Appends the html for show password button
 */
PasswordField.prototype.appendShowPasswordButton = function() {
    if (this.$showPasswordEl) {
        this.$showPasswordEl.show();

        return;
    }

    this.$showPasswordEl = $(showPasswordButtonTpl());

    this.$el.append(this.$showPasswordEl);
    this.bindShowPasswordButtonEvents();
};

/**
 * Bind all dom events for show password button
 */
PasswordField.prototype.bindShowPasswordButtonEvents = function() {
    this.$showPasswordEl.on('mousedown touchstart', this.togglePasswordButtonType.bind(this, 'text'));
    this.$showPasswordEl.on('mouseup touchend', this.togglePasswordButtonType.bind(this, 'password'));
    this.$showPasswordEl.on('mouseleave', this.togglePasswordButtonType.bind(this, 'password'));
};

/**
 * Toggles show/hide text in password fields
 *
 * @param {string} type
 * @param {jQuery.Event} event
 */
PasswordField.prototype.togglePasswordButtonType = function(type, event) {
    this.$input.attr('type', type);

    event.preventDefault();
};

/**
 * Destroy
 */
PasswordField.prototype.destroy = function() {
};

module.exports = PasswordField;
