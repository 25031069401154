'use strict';

var app = require('../../../../app');
var $ = require('jquery');
var _ = require('lodash');
var accessPointTpl = require('./wlan-access-point.hbs');
var FormActionView = require('./../../form-action-view');

var states = {
    visible: 'visible',
    hidden: 'hidden'
};

var connectionStates = {
    disconnected: 'disconnected',
    connected: 'connected',
    scanning: 'scanning',
    authenticating: 'authenticating',
    failed: 'failed'
};

app.component('WlanAccessPoint', {
    template: accessPointTpl,

    getAccessKey: function() {
        return {
            'roleName': 'WlanSettings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.init = false;
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.deviceService = this.getService('DeviceService');
        this.region = null;
        this.wlanRegion2ExtChannels = false;
        this.visibility = this.createStateMachine({
            state: states.hidden,
            states: states
        });

        this.connectionState = this.createStateMachine({
            state: connectionStates.disconnected,
            states: connectionStates
        });

        this.addStateTransitions();
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.createEncryptSelect();
        this.createChannelSelect();
        this.createDbmSelect();
        this.createMaxClientsSelect();
        this.initForm();
        this.$security = this.$el.find('#security-container');
        this.$usernameContainer = this.$security.find('#username-container');
        this.$security.hide();

        this.loadData(true)
            .then(function() {
                this.bindDOMEvents();
                this.bindEvents();
                this.initFormAction();
                this.actionView.disableCancelButton();

                // Delay init --> due to form.setValues(data)
                setTimeout(function() {
                    this.init = true;
                }.bind(this), 500);
            }.bind(this));

        this.updateHandler();
    },

    storeSelectors: function() {
        this.$connectMsg = this.$el.find('#wlan-wait-for-ap');
    },

    bindDOMEvents: function() {
        this.form.get('ssidAuto').$el.on('change', this.handleSsidChange.bind(this));
        this.form.get('encrypt').$el.on('change', this.handleSecurityChange.bind(this));
        this.form.get('channel').$el.on('change', this.updateDbmSelect.bind(this));
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.form.on('wlan.change.country', this.handleFormChange.bind(this));
        this.on('wlan-mode.changed.access-point', this.loadData.bind(this, true));
        this.on('wlan-access-point.action.view.open', this.openActionView.bind(this));
    },

    bindEvents: function() {
        this.on('main-loop.update', this.updateHandler.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container-access-point'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this),
                autoClose: false
            });
        }

        this.actionView.render();

        setTimeout(function() {
            this.actionView.disableCancelButton();
        }.bind(this), 350);
    },

    openActionView: function() {
        if (this.actionView) {
            this.actionView.open();
        } else {
            this.initFormAction();
        }
    },

    loadData: function(loadSelects) {
        if (this.actionView) {
            this.actionView.enableCancelButton();
        }

        return this.deviceConnection
            .send([
                'getSsidAuto',
                'getApSsidName',
                'getWlanChannel',
                'getWlanApEncryptionStandard',
                'getPasswordAccesspoint',
                'getWlanApIpAddress',
                'getWlanApSubnetMask',
                'getWlanPower',
                'getWlanApMaxClients',
                'getAdjustmentWlanRegion',
                'getLicenseFeatures'
            ])
            .then(function(ssidAuto, ssidName, wlanChannel, encryptionStandard, keyAccesspoint, ipAddress,
                subnetMask, dbm, clients, region, features) {
                var data = {
                    ssidAuto: ssidAuto.ssidAuto,
                    ssid: ssidName.ssidName,
                    channel: wlanChannel.wlanChannel,
                    encrypt: encryptionStandard.encryptionStandard,
                    keyAccesspoint: keyAccesspoint.keyAccesspoint,
                    ip: ipAddress.ipAddress,
                    subnet: subnetMask.subnetMask,
                    dbm: dbm.power,
                    maxClients: clients.nrClients
                };

                this.wlanRegion2ExtChannels = features.wlanRegion2ExtChannels;

                if (loadSelects) {
                    this.updateSelects({
                        region: region.region
                    });
                }

                this.setFormData(data);
                this.handleSecurityChange();
            }.bind(this));
    },

    /**
     * @method setFormData
     * @param {object} data
     */
    setFormData: function(data) {
        this.form.setValues(data);
        this.handleSsidChange();
    },

    /**
     * @method updateHandler
     */
    updateHandler: function() {
        this.deviceConnection
            .send('getWlanStatus')
            .then(this.updateWlanConnectionState.bind(this));
    },

    /**
     * @method updateWlanConnectionState
     * @param data
     */
    updateWlanConnectionState: function(data) {
        var state = connectionStates[data.wlanStatus];

        if (state !== this.connectionState.getState()) {
            this.connectionState.changeState(state);
        }
    },

    /**
     * @method showTryToConnectMessage
     */
    showTryToConnectMessage: function() {
        this.$connectMsg
            .velocity('slideDown', 200);

        this.tryToConnectTimeout = setTimeout(function() {
            this.hideTryToConnectMessage();
        }.bind(this), 60000);
    },

    /**
     * @method hideTryToConnectMessage
     */
    hideTryToConnectMessage: function() {
        this.$connectMsg
            .velocity('slideUp', 200);
    },

    /**
     * @method saveHandler
     */
    saveHandler: function() {
        var data = this.form.serialize();

        if (this.form.validate()) {
            this.emit('wlan.save');

            this.deviceConnection
                .send([
                    {
                        command: 'setWlanPower',
                        data: { power: data.dbm }
                    },
                    {
                        command: 'setSsidAuto',
                        data: { ssidAuto: data.ssidAuto }
                    },
                    {
                        command: 'setWlanChannel',
                        data: { wifiChannel: data.channel }
                    },
                    {
                        command: 'setWlanApEncryptionStandard',
                        data: { encryptionStandard: data.encrypt }
                    },
                    {
                        command: 'setKeyAccesspoint',
                        data: { wifiKey: data.keyAccesspoint }
                    },
                    {
                        command: 'setWlanApIpAddress',
                        data: { ipAddress: data.ip }
                    },
                    {
                        command: 'setWlanApSubnetMask',
                        data: { subnetMask: data.subnet }
                    },
                    {
                        command: 'setWlanApMaxClients',
                        data: { nrClients: data.maxClients }
                    }
                ]).then(function() {
                    this.emit('overlay.remote.focus', true);
                    this.emit('overlay.header.update', {
                        actionButtonKey: 'settings.action_button_saved',
                        actionBtnType: null,
                        actionBtnDelay: 2000,
                        actionBtnFadeout: 500
                    });
                }.bind(this));

            if (!data.ssidAuto) {
                this.deviceConnection
                    .send('setApSsidName', { ssidName: data.ssid });
            }

            this.showTryToConnectMessage();
            this.handleSettingsSave();
            this.actionView.disableCancelButton();

            setTimeout(function() {
                this.loadData(false)
                    .then(function() {
                        this.actionView.disableCancelButton.bind(this.actionView);
                        this.handleChanges(false);

                        /**
                         * We must wait for all saves are done.
                         * Most of the time we need to set the wlan-mode. (wlan.js)
                         */
                        this.deviceConnection.send('setWlanSettingsActivate');
                    }.bind(this));
            }.bind(this), 200);
        }
    },

    /**
     * Calls on clicked the cancel button.
     *
     * @method cancelHandler
     */
    cancelHandler: function() {
        this.form.resetValues();
        this.actionView.disableCancelButton();
        this.emit('wlan.cancel');
    },

    /**
     * @method handleSettingsSave
     */
    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });
    },

    /**
     * @method handleSsidChange
     */
    handleSsidChange: function() {
        var value = this.form.get('ssidAuto').getValue();

        if (value) {
            this.form.get('ssid').disable();
        } else {
            this.form.get('ssid').enable();
        }
    },

    /**
     * @method handleFormChange
     */
    handleFormChange: function() {
        this.actionView.enableCancelButton();
        this.checkActionForm();
        this.actionView.open();

        if (this.init) {
            this.handleChanges(true);
        }
    },

    /**
     * @method checkActionForm
     */
    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * @method handleSecurityChange
     */
    handleSecurityChange: function() {
        var value = this.form.get('encrypt').getValue();

        this.form.get('keyAccesspoint').$el.attr('data-pwd-type', value);

        if (value === 'wep' || value === 'wpa' || value === 'wpa2') {
            // Hide username input field
            if (this.visibility.getState() === states.visible) {
                this.$usernameContainer.fadeOut(200);
            } else {
                this.$usernameContainer.hide();
            }

            this.form
                .get('username')
                .disable();

            this.$security.slideDown(200, this.onSecurityVisible.bind(this));
        } else if (value === 'wpa-enterprise' || value === 'wpa2-enterprise') {
            // Show username input field
            if (this.visibility.getState() === states.visible) {
                this.$usernameContainer.fadeIn(200);
            } else {
                this.$usernameContainer.show();
            }

            this.form
                .get('username')
                .enable();

            this.$security.slideDown(200, this.onSecurityVisible.bind(this));
        } else {
            this.$security.slideUp(200, this.onSecurityHidden.bind(this));
        }
    },

    /**
     * @method onSecurityVisible
     */
    onSecurityVisible: function() {
        this.$security.addClass('is-open');
        this.visibility.changeState(states.visible);
    },

    /**
     * @method onSecurityHidden
     */
    onSecurityHidden: function() {
        this.visibility.changeState(states.hidden);
    },

    /**
     * @method initForm
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#wlan-access-point-form'),
            validationContainer: '.input-group'
        });
    },

    /**
     * @method addStateTransitions
     */
    addStateTransitions: function() {
        this.connectionState.addTransitions({
            '> connected': function() {
                clearTimeout(this.tryToConnectTimeout);

                this.hideTryToConnectMessage();
            }
        });
    },

    /**
     * @method destroy
     */
    destroy: function() {
        this.formManager.destroy(this.form);
        this.app.removeComponent('channel-select');
        this.app.removeComponent('encrypt-select');
        this.app.removeComponent('dbm-select');
        this.app.removeComponent('max-clients-select');

        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    /**
     * @method createChannelSelect
     */
    createChannelSelect: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#channel-select'),
            label: 'settings.channel',
            native: true,
            name: 'channel',
            items: [],
            value: this.channel
        });
    },

    /**
     * @method getItemsByRegion
     * @param {int} region
     * @returns {object}
     */
    getItemsByRegion: function(region, wlanRegion2ExtChannels) {
        var items = [
            {
                text: '1',
                value: '1'
            },
            {
                text: '6',
                value: '6'
            },
            {
                text: '11',
                value: '11'
            },
            {
                text: '36',
                value: '36'
            },
            {
                text: '40',
                value: '40'
            },
            {
                text: '44',
                value: '44'
            },
            {
                text: '48',
                value: '48'
            }
        ];

        var itemsUS = [
            {
                text: '149',
                value: '149'
            },
            {
                text: '153',
                value: '153'
            },
            {
                text: '157',
                value: '157'
            },
            {
                text: '161',
                value: '161'
            }
        ];

        if (region === 1) {
            items = _.union(items, itemsUS);
        } else if (wlanRegion2ExtChannels)  {
            items = _.union(items, itemsUS);
        }

        return items;
    },

    /**
     * @method getItemsByChannel
     * @returns {object}
     */
    getItemsByChannel: function() {
        var maxDbm = 17;
        var items = [];
        var channel = this.form ? this.form.get('channel').value : 1;

        if (this.region === 1) {
            switch (parseInt(channel)) {
                case 1:
                case 6:
                case 11:
                    maxDbm = 24;
                    break;
                case 36:
                case 40:
                case 44:
                case 48:
                    maxDbm = 22;
                    break;
                case 149:
                case 153:
                case 157:
                case 161:
                    maxDbm = 24;
                    break;
            }
        } else {
            switch (parseInt(channel)) {
                case 1:
                case 6:
                case 11:
                    maxDbm = 20;
                    break;
                case 36:
                case 40:
                case 44:
                case 48:
                    maxDbm = 22;
                    break;
                case 149:
                case 153:
                case 157:
                case 161:
                    maxDbm = 14;
                    break;
            }
        }

        for (var i = 0; i <= maxDbm; i++) {
            items[i] = {
                text: i + ' dBm',
                value: i
            };
        }

        return items;
    },

    /**
     * @method updateSelects
     * @param data
     */
    updateSelects: function(data) {
        if (this.region !== data.region) {
            this.region = data.region;
            this.updateChannelSelect(this.region, this.wlanRegion2ExtChannels);
            this.updateDbmSelect();
        }
    },

    /**
     * @method updateChannelSelect
     * @param region
     */
    updateChannelSelect: function(region, wlanRegion2ExtChannels) {
        this.emit('select.channel.update', {
            items: this.getItemsByRegion(region, wlanRegion2ExtChannels),
            selected: 1
        });
    },

    /**
     * Update dBm select.
     */
    updateDbmSelect: function() {
        var dbmValues = this.getItemsByChannel();
        this.emit('select.dbm.update', {
            items: dbmValues,
            selected: dbmValues[dbmValues.length - 1].value
        });
    },

    /**
     * Create select for choosing dBm.
     */
    createDbmSelect: function() {
        var items = this.getItemsByChannel();

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#dbm-select'),
            label: 'settings.transmit_power',
            native: true,
            name: 'dbm',
            items: items
        });
    },

    /**
     * Create select for choosing encryption.
     */
    createEncryptSelect: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#encrypt-select'),
            label: 'settings.security',
            native: true,
            name: 'encrypt',
            items: [
                {
                    value: 'none',
                    text: 'settings.none'
                },
                /* {
                    value: 'wep',
                    text: 'settings.wep'
                },*/
                {
                    value: 'wpa',
                    text: 'settings.wpa2'
                }
            ]

        });
    },

    /**
     * Create select for choosing maximum number of clients.
     */
    createMaxClientsSelect: function() {
        var items = [];
        var max = 15;

        for (var i = 1; i <= max; i++) {
            items.push({
                value: i,
                text: i
            });
        }

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#max-clients-select'),
            label: 'settings.max_clients',
            native: true,
            name: 'maxClients',
            items: items
        });
    },

    handleChanges: function(change) {
        var changes = {
            hasChanges: change,
            invalid: false
        };

        if (this.init && this.actionView) {
            changes.invalid = $(this.actionView.$submitBtn).prop('disabled');
        }

        this.emit('wlan-settings.changed', changes);
    }
});
