'use strict';

module.exports = {
    'CB1': require('./CB1.js'),
    'CBR': require('./CBR.js'),
    'CBC': require('./CBC.js'),
    'CBP': require('./CBP.js'),
    'CPP': require('./CPP.js'),
    'CCP': require('./CCP.js'),
    'CPR': require('./CPR.js'),
    'CPM': require('./CPM.js'),
    'CVB': require('./CVB.js')
};
