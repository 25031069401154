// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"proxy-settings\">\n    <form id=\"proxy-settings-form\" name=\"input-settings\">\n        <div class=\"settings-list\">\n\n            <div class=\"row settings-list-row\"\n                 data-nav-area=\".btn-switch, .focusable:not(:disabled)\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"col-xs-8\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.proxy_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":24},"end":{"line":10,"column":53}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"proxyEnabled\" id=\"proxyEnabled\" type=\"checkbox\" class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div id=\"proxy-settings-container\"\n                 class=\"row settings-list-secundary hidden\"\n                 data-nav-area=\".input:not([disabled]), .is-focusable:not(:disabled)\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <div class=\"input-group\">\n                        <label class=\"input-label\" for=\"proxyUrl\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":66},"end":{"line":27,"column":86}}}))
    + "</label>\n                        <input type=\"text\" class=\"input is-focusable\" name=\"proxyUrl\" id=\"proxyUrl\"\n                               data-validation=\"checkMinMaxLength\" data-subjection-selector=\"#proxyEnabled\" data-min-length=\"1\" data-max-length=\"255\"\n                               tabIndex=\"-1\"/>\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":75},"end":{"line":32,"column":101}}}))
    + "</span>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"col-xs-6\">\n                    <div class=\"input-group\">\n                        <label class=\"input-label\" for=\"proxyPort\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.host_port",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":67},"end":{"line":38,"column":93}}}))
    + "</label>\n                        <input type=\"text\" class=\"input is-focusable\" name=\"proxyPort\" id=\"proxyPort\"\n                               data-validation=\"checkPort\" tabIndex=\"-1\"/>\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_port",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":75},"end":{"line":42,"column":103}}}))
    + "</span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch, .focusable:not(:disabled)\"\n                 data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.auth_mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":44},"end":{"line":51,"column":70}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-2 pull-right\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"proxyAuth\" id=\"proxyAuth\" type=\"checkbox\" class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\" id=\"auth-container\"\n                 data-nav-area=\".input:not([disabled]), .is-focusable:not(:disabled)\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <div class=\"input-group\" id=\"username-container\">\n                        <label class=\"input-label\" for=\"proxyUsername\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.username",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":71},"end":{"line":67,"column":96}}}))
    + "</label>\n                        <input type=\"text\" class=\"input is-focusable\" name=\"proxyUsername\" id=\"proxyUsername\"\n                               data-validation=\"checkMinMaxLength\" data-min-length=\"1\" data-max-length=\"63\" tabIndex=\"-1\"/>\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\" id=\"name-error\">\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.wrong_username_length_1_63",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":32},"end":{"line":72,"column":90}}}))
    + "\n                            </span>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"col-xs-6\">\n                    <div class=\"input-group\" id=\"password-container\">\n                        <label class=\"input-label\" for=\"proxyPassword\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":71},"end":{"line":79,"column":96}}}))
    + "</label>\n                        <input type=\"password\"\n                               autocomplete=\"current-password\"\n                               class=\"input is-focusable\"\n                               name=\"proxyPassword\"\n                               id=\"proxyPassword\"\n                               data-validation=\"checkMinMaxLength\"\n                               data-min-length=\"1\"\n                               data-max-length=\"63\"\n                               tabIndex=\"-1\"/>\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\" id=\"name-error\">\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.wrong_password_length_1_63",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":91,"column":32},"end":{"line":91,"column":90}}}))
    + "\n                            </span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary settings-list-info proxy-reboot-error hidden\">\n                <div class=\"col-xs-12\">\n                    <p class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.proxy_reboot_error",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":100,"column":64},"end":{"line":100,"column":99}}}))
    + "</p>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"form-action-container\" class=\"form-primary-background\"></div>\n    </form>\n</div>\n";
},"useData":true});
