'use strict';

var app = require('../../../../app');
var platform = require('../../../../../platform/platform');

var controlItems = [
    {
        id: 'mute',
        type: 'mute',
        state: 'mute'
    },
    {
        id: 'volume',
        type: 'volume'
    }
];

app.component('FrameBoxTeamsViewer', {
    extend: 'FrameBoxBaseType',
    className: 'framebox-view framebox-browser-view',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.clickTimeout = null;
        this.url = null;
        this.frameBoxService = this.getService('FrameBoxService');
        this.liveStream = this.getService('LiveStreamService');
        this.storage = this.getService('DataStorage');
        this.remote = this.getService('RemoteService');
        this.webconferenceService = this.getService('WebconferenceService');
        this.embeddedPdf = false;
        this.componentId = null;
        this.options.allowedEvents = ['touchstart', 'touchend', 'mousedown', 'mouseup', 'touchmove', 'mousemove'];
    },

    serialize: function() {
        return {
            isCbox: platform.checks.isCbox
        };
    },

    postPlaceAt: function() {
        this.startControls();
        this.bindEvents();

        this.startTouchEventsEntity({
            $actionEl: this.options.$actionEl,
            allowedEvents: this.options.allowedEvents,
            disableSelection: false,
            debugging: false
        });

        this.onFrameboxChanged({
            contentType: this.frameBoxService.contentTypeMapping(this.options.type),
            options: this.options.frameBoxOptions
        });

        this.updateTransparentState({
            index: this.options.index,
            transparent: this.frameBoxService.isTransparent(this.options.index)
        });

        if (window.navigator.standalone) {
            this.options.$actionEl.addClass('hide-keyboard-btn');
        }
    },

    bindEvents: function() {
        this.on('framebox.change.' + this.index, this.onFrameboxChanged.bind(this));

        this.on('framebox.standard.keydown', this.keyDownHandler.bind(this));
        this.on('framebox.standard.keyup', this.keyUpHandler.bind(this));

        this.on('framebox.standard.pandown', this.controlHandlers['scroll-up'].bind(this));
        this.on('framebox.standard.panup', this.controlHandlers['scroll-down'].bind(this));
        this.on('framebox.standard.panleft', this.controlHandlers['scroll-right'].bind(this));
        this.on('framebox.standard.panright', this.controlHandlers['scroll-left'].bind(this));

        // Bind Mouse-Events.
        this.on('framebox.standard.mousewheel-up', this.controlHandlers['scroll-up'].bind(this));
        this.on('framebox.standard.mousewheel-down', this.controlHandlers['scroll-down'].bind(this));

        this.on('active-framebox.changed', this.activeFrameboxChanged.bind(this));

        this.on('framebox.close.teams', this.onClose.bind(this));
    },

    /**
     * If framebox is transparent (enable content for teams is disabled) block events.
     *
     * @param options.index framebox index
     * @param options.transparent framebox transparency
     */
    updateTransparentState: function(options) {
        if (options.index !== this.options.index || platform.checks.isCbox) {
            return;
        }

        this.transparent = options.transparent;
    },

    /**
     * Initialize Keyboard after active framebox changed
     *
     * @param index framebox index
     * @param keyboardAction focus is on an input field --> update keyboard
     */
    activeFrameboxChanged: function(index, component, keyboardAction) {
        if (index === this.options.index) {
            if (!this.keyboard) {
                this.startKeyboard();

                this.onFrameboxChanged({
                    contentType: this.frameBoxService.contentTypeMapping(this.options.type),
                    options: this.options.frameBoxOptions
                });

                return;
            }

            if (keyboardAction) {
                this.keyboardAction('update', this.box);
            }
        }
    },

    /**
     * Update status and control bar on framebox changed
     * @param box
     */
    onFrameboxChanged: function(box) {
        this.box = box;
        clearTimeout(this.clickTimeout);

        if (this.keyboardAction) {
            this.keyboardAction('update', box);
        }
    },

    keyDownHandler: function(event, index) {
        this.keyEventHandler(index, 'keydown', event);
    },

    keyUpHandler: function(event, index) {
        this.keyEventHandler(index, 'keyup', event);
    },

    keyEventHandler: function(index, keyEvent, event) {
        if (index === this.index && !this.transparent) {
            this.frameBoxService.sendKeyEvent(this.options.index, keyEvent, event);
        }
    },

    /**
     * Checks the keyboard state.
     */
    canOpenKeyboard: function() {
        if (this.box.options.requestKeyboard) {
            return platform.checks.isTouchDevice || (platform.checks.isCbox && this.remote.isTouchKeyboardEnabled());
        }

        return false;
    },

    /**
     * Start framebox controls
     */
    startControls: function() {
        this.componentId = this.createComponent({
            type: 'FrameBoxControls',
            container: this.$el,
            index: this.options.index,
            appId: this.options.appId,
            items: controlItems,
            onClick: this.onControlClickHandler.bind(this),
            isFullscreen: this.options.isFullscreen
        });
    },

    /**
     * Handle on control click
     * @param id control ID
     */
    onControlClickHandler: function(id) {
        var handler = this.controlHandlers[id];

        if (this.keyboardAction) {
            this.keyboardAction('close');
        }

        if (handler) {
            handler.call(this, {
                index: this.index
            });
        }
    },

    /**
     * Handle custom close of Teams window.
     * Framebox close is sent after the user confirms.
     */
    onClose: function(data) {
        if (this.index !== data.index) {
            return;
        }

        this.emit('modal.open', {
            id: 'confirm-v3',
            messageKey: 'teams.confirm_end',
            successTextKey: 'teams.end',
            discareTextKey: 'modal.cancel',
            backdropClick: true,
            onConfirm: function() {
                this.frameBoxService.closeFrameBox(this.index);
            }.bind(this),
            autoClose: true
        });
    },

    /**
     * Start Keyboard Component.
     */
    startKeyboard: function() {
        this.keyboard = this.createComponent({
            type: 'FrameboxKeyboard',
            container: this.$el,
            index: this.options.index,
            allTouchDevices: true,
            avoidOpen: true,
            action: function(fn) {
                this.keyboardAction = fn;
            }.bind(this)
        });
    },

    /**
     * Control handler.
     */
    controlHandlers: {
        'scroll-up': function(options) {
            this.sendCmd('scrollUp', options.index);
        },

        'scroll-down': function(options) {
            this.sendCmd('scrollDown', options.index);
        },

        'scroll-left': function(options) {
            this.sendCmd('scrollLeft', options.index);
        },

        'scroll-right': function(options) {
            this.sendCmd('scrollRight', options.index);
        }
    },

    /**
     * Send framebox command.
     *
     * @param cmd command/control ID
     * @param index framebox index
     */
    sendCmd: function(cmd, index) {
        if (this.index === index) {
            this.send('setOffice365Control', {
                appId: this.options.appId,
                cmd: cmd,
                type: 'msteams'
            });
        }
    },

    destroy: function() {
        if (this.keyboardAction) {
            this.keyboardAction('close');
        }

        this.$('html, body').scrollTop(0);
        this.$(document).off('mousedown.keyboard-' + this.index);
        this.$(document).off('touchstart.keyboard-' + this.index);
    }
});
