// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <!-- Whiteboard -->\n            <div class=\"row settings-list-row\"\n                 data-nav-area=\".btn-switch\"\n                 data-nav-trapped\n                 data-nav-area-default>\n                <div class=\"col-xs-8\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.whiteboard",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":51}}}))
    + "</h2>\n                </div>\n\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"whiteboardEnabled\"\n                               name=\"whiteboardEnabled\"\n                               type=\"checkbox\"\n                               class=\"btn-switch is-focusable\"\n                               tabIndex=\"1\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <!-- Annotation -->\n            <div class=\"row settings-list-row\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.annotation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":24},"end":{"line":35,"column":51}}}))
    + "</h2>\n                </div>\n\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"annotationEnabled\"\n                               name=\"annotationEnabled\"\n                               type=\"checkbox\"\n                               class=\"btn-switch is-focusable\"\n                               tabIndex=\"1\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.enable_user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":44},"end":{"line":54,"column":72}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"enableUser\" name=\"enableUser\" type=\"checkbox\" class=\"btn-switch\" />\n\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-secundary user-pin-hdmi2\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.collaboration_pin_hdmi2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":86,"column":44},"end":{"line":86,"column":84}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"userPinHdmi2\" name=\"userPinHdmi2\" type=\"checkbox\" class=\"btn-switch\" />\n\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <!-- Magic Pen -->\n            <div class=\"row settings-list-row\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.magic_pen",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":104,"column":24},"end":{"line":104,"column":50}}}))
    + "</h2>\n                </div>\n\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"magicPenEnabled\"\n                               name=\"magicPenEnabled\"\n                               type=\"checkbox\"\n                               class=\"btn-switch is-focusable\"\n                               tabIndex=\"1\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"magic-pen-settings-container\">\n                <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable:not(:disabled)\" data-nav-trapped>\n                    <div class=\"col-xs-3\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"url\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.visible_time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":125,"column":65},"end":{"line":125,"column":94}}}))
    + "</label>\n                            <input type=\"text\"\n                                   class=\"input is-focusable\"\n                                   name=\"visibleTime\"\n                                   id=\"visibleTime\"\n                                   placeholder=\"\"\n                                   tabIndex=\"-1\"\n                                   data-from=\"1\"\n                                   data-to=\"60\"\n                                   data-validation=\"checkNumberFromTo\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">\n                                    "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.wrong_value_between_1_60_seconds",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":137,"column":36},"end":{"line":137,"column":100}}}))
    + "\n                                </span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPure") : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":144,"column":16},"end":{"line":161,"column":27}}})) != null ? stack1 : "")
    + "\n                <div class=\"row settings-list-secundary enable-remote-user\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                    <div class=\"col-xs-8\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.enable_remote_user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":165,"column":48},"end":{"line":165,"column":83}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input id=\"enableRemoteUser\"\n                                   name=\"enableRemoteUser\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch\" />\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                        <div class=\"col-xs-8\">\n                            <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.enable_auto_snapshot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":147,"column":52},"end":{"line":147,"column":89}}}))
    + "</h2>\n                        </div>\n                        <div class=\"col-xs-4\">\n                            <div class=\"btn-switch-container pull-right\">\n                                <input id=\"autoSnapshot\"\n                                       name=\"autoSnapshot\"\n                                       type=\"checkbox\"\n                                       class=\"btn-switch\" />\n                                <div class=\"btn-switch-toggle-container\">\n                                    <div class=\"btn-switch-toggle\"></div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <div id=\"mirroring-compomnent-container\"></div>\n    <form id=\"collaboration-settings\"\n          name=\"collaboration-settings\"\n          data-nav-area=\".input:not([disabled]), .btn-switch, .focusable:not(:disabled)\"\n          data-nav-trapped>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPureReceiver") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":66,"column":19}}})) != null ? stack1 : "")
    + "\n        <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n            <div class=\"col-xs-8\">\n                <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(alias1,"settings.auto_show_annotation_menu",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":40},"end":{"line":70,"column":82}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input id=\"autoShowAnnotationMenu\" name=\"autoShowAnnotationMenu\" type=\"checkbox\" class=\"btn-switch\" />\n\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxNonDualProjection") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":8},"end":{"line":98,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"supportsMagicPen") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":8},"end":{"line":180,"column":15}}})) != null ? stack1 : "")
    + "        <div id=\"form-action-container\" class=\"form-primary-background\"></div>\n    </form>\n</div>\n";
},"useData":true});
