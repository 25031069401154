/* eslint no-nested-ternary: ["warn"] */

'use strict';

var app = require('../../../app');
var fileListTpl = require('./file-list.hbs');
var $ = require('jquery');
var d3 = require('d3');
var _ = require('lodash');
var StateMachine = require('../../../state-machine');

var actions = {
    none: 'none',
    share: 'share',
    dragstart: 'dragstart',
    drag: 'drag'
};

app.component('MatrixFileList', {
    template: fileListTpl,

    getAccessKey: function() {
        return {
            'roleName': 'FileBox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.configs = this.getService('MatrixConfigs');
        this.matrixService = this.getService('MatrixService');
        this.matrixMainService = this.getService('MatrixMainService');
        this.downloadService = this.getService('DownloadsService');
        this.URIService = this.getService('URIService');

        this.path = this.options.path ? this.URIService.decode(this.options.path) : this.options.path;
        this.mountType = this.options.mountType;
        this.drag = {
            x: 0,
            y: 0
        };

        this.action = new StateMachine({
            context: this,
            state: actions.none,
            states: actions
        });
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindDOMEvents();
        this.bindEvents();

        // RELEASE-2105: If mount type is a cloud service, the download states should be updated when file list ist loaded.
        if (this.mountType === 'cloud') {
            this.updateItemStates();
        }
    },

    storeSelectors: function() {
        this.$container = this.$el.parent();
        this.$svg = this.$el.find('#svg-grid-files');
        this.svg = d3.select(this.$svg[0]);
    },

    bindDOMEvents: function(el) {
        if (el) {
            var file = d3.select(el[0])._groups[0];

            if (el.hasClass('draggable')) {
                d3.select(el[0])
                    .data(file)
                    .call(d3.drag()
                        .on('start', this.handleDragStart.bind(this))
                        .on('drag', this.handleDrag.bind(this))
                        .on('end', this.handleDragEnd.bind(this)));
            } else if (el.hasClass('downloadable')) {
                d3.select(el[0])
                    .data(file)
                    .on('click', _.debounce(this.download.bind(this)), 400);
            } else if (el.hasClass('is-folder')) {
                d3.select(el[0])
                    .data(file)
                    .on('click', _.debounce(this.forward.bind(this)), 400);
            }
        } else {
            var filesDrag = this.svg.selectAll('.svg-grid-file.draggable')._groups[0];
            var filesDownload = this.svg.selectAll('.svg-grid-file.downloadable')._groups[0];
            var folders = this.svg.selectAll('.svg-grid-file.is-folder')._groups[0];

            this.svg.selectAll('.svg-grid-file.draggable')
                .data(filesDrag)
                .call(d3.drag()
                    .on('start', this.handleDragStart.bind(this))
                    .on('drag', this.handleDrag.bind(this))
                    .on('end', this.handleDragEnd.bind(this)));

            this.svg.selectAll('.svg-grid-file.downloadable')
                .data(filesDownload)
                .on('click', _.debounce(this.download.bind(this)), 400);

            this.svg.selectAll('.svg-grid-file.is-folder')
                .data(folders)
                .on('click', _.debounce(this.forward.bind(this)), 400);
        }
    },

    unbindDOMEvents: function(el) {
        if (el) {
            d3.select(el[0])
                .call(d3.drag()
                    .on('start', null)
                    .on('drag', null)
                    .on('end', null));
            d3.select(el[0])
                .on('click', null);
        } else {
            this.svg.selectAll('.svg-grid-file')
                .call(d3.drag()
                    .on('start', null)
                    .on('drag', null)
                    .on('end', null));
            this.svg.selectAll('.svg-grid-file')
                .on('click', null);
        }
    },

    bindEvents: function() {
        this.on('download-list.add', this.updateItem.bind(this));
        this.on('download-list.update', this.updateItem.bind(this));
    },

    serialize: function() {
        var dfd = $.Deferred();

        this.getFileList(this.path, this.mountType)
            .then(function() {
                dfd.resolve({
                    files: this.files,
                    width: this.width,
                    height: this.height,
                    noFiles: this.files.length === 0,
                    itemWidth: parseInt(this.configs.get('matrixBox.itemWidth')),
                    itemHeight: parseInt(this.configs.get('matrixBox.itemHeight'))
                });
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Get file list from dir and parse file list.
     *
     * @param dir Directory to get file list from
     */
    getFileList: function(dir, mountType) {
        var dfd = $.Deferred();
        var finished = false;

        setTimeout(function() {
            if (!finished) {
                $(document).find('.file-box-loader').show();
            }
        }.bind(this), 100);

        this.matrixService.getFiles(dir)
            .then(function(data) {
                finished = true;
                this.files = this.parseFileList(data.fileList, dir, mountType);

                $(document).find('.file-box-loader').hide();
                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Parse file list.
     *
     * @param files File list to parse
     * @param path Current location path
     *
     * @returns {Array} Parsed file list to show in UI
     */
    parseFileList: function(files, path, mountType) {
        var fileList = [];
        var x0 = 0; // File box position
        var y0 = parseInt(this.configs.get('matrixBox.itemHeight')) * (-1); // File box position

        _.each(files, function(file) {
            var f = {
                pos: {
                    x: x0,
                    y: y0 += parseInt(this.configs.get('matrixBox.itemHeight'))
                },
                name: file.name,
                path: this.URIService.encode(path ? file.path : file.path + '/'),
                type: (file.type === 'dir') ? '' : file.type,
                mountType: file.mountType ? file.mountType : (mountType ? mountType : ''),
                icon: this.getIcon(file),
                isFile: file.type && file.type !== 'dir',
                isFolder: file.type === 'dir',
                isDraggable: file.type !== 'dir' && file.type !== 'unknown',
                isDownloadable: this.isDownload(file, mountType),
                // For cloud files (download status)
                status: file.downloadStatus,
                preload: file.preload
            };

            fileList.push(f);
        }.bind(this));

        this.width = parseInt(this.configs.get('matrixBox.itemWidth'));
        this.height = files.length === 0 ? (y0 + (parseInt(this.configs.get('matrixBox.itemHeight')) * 2)) : (y0 + parseInt(this.configs.get('matrixBox.itemHeight')));
        this.$el.parent().css('height', this.height + 'px');

        return fileList;
    },

    /**
     * Get icon for file/directory type.
     *
     * @param file Current file
     * @returns {*|string} Icon type
     */
    getIcon: function(file) {
        var icon;

        if (file.id) { // Mount
            icon = this.configs.get('iconMapping.' + [file.id]);
        }

        if (!icon && file.mountType) { // Mount
            icon = this.configs.get('iconMapping.' + [file.mountType]);
        }

        if (!icon && file.type) { // File/dir
            icon = this.configs.get('iconMapping.' + [file.type]);
        }

        return icon || 'icon-blocked';
    },

    /**
     * Check if file is downloadable depending on mount type (cloud service) and file type.
     *
     * @param file File to check
     * @param mountType Files mount type
     * @returns {boolean} TRUE: downloadable; FALSE: local file, ready to share
     */
    isDownload: function(file, mountType) {
        var type = file.mountType ? file.mountType : (mountType ? mountType : '');

        if (type !== 'cloud') {
            return false;
        }

        return file.type !== 'dir' && file.type !== 'unknown' && file.downloadStatus !== 'done';
    },

    /**
     * Handle pan event.
     *
     */
    onPan: function() {
        var scrollY;
        var scrollTop = this.$container.scrollTop();

        scrollY = (d3.event.sourceEvent.touches && d3.event.sourceEvent.touches.length) > 0 ? (d3.event.y - this.initY) * -1 : 0;

        this.$container.scrollTop(scrollTop + scrollY);
    },

    /**
     * Handle drag start event.
     * Make difference between drag file and share file with all.
     *
     * @param d Element/File dragged.
     */
    handleDragStart: function(d) {
        d3.select(d).attr('cx', d3.event.x).attr('cy', d3.event.y);
        d3.select(d).raise().classed('active', true);

        // If share btn is pressed.
        if (d3.event.sourceEvent.target.getAttribute('data-action') === 'share') {
            this.action = actions.share;

            return;
        }

        this.action = actions.dragstart;
        this.scrollCounterStart = true;
    },

    /**
     * Handle drag event and drag file.
     * Stop Share with all event if file gets dragged.
     *
     * @param d Element/File dragged.
     */
    handleDrag: function(d) {
        if (this.drag.x <= 30 && this.drag.y <= 30) {
            if (this.scrollCounterStart) {
                this.initY = d3.event.y;
                this.scrollCounterStart = false;
            }

            this.drag.x = this.drag.x + Math.abs(d3.event.dx);
            this.drag.y = this.drag.y + Math.abs(d3.event.dy);

            return;
        }

        if (this.drag.y > this.drag.x) {
            this.onPan();

            return;
        }

        if (this.action === actions.share || this.action === actions.dragstart) {
            this.action = actions.none;

            this.hide(false);
            this.matrixMainService.onDragFileStart(d);
        }

        this.matrixMainService.onDragFile();
        this.action = actions.drag;
    },

    /**
     * Handle drag end event.
     * If share file with all event is true, call @method shareFileWithAll, else drop file on station and share it.
     *
     * @param d Element/File dragged.
     */
    handleDragEnd: function(d) {
        if (this.action === actions.share) {
            this.hide(false);
            this.shareFileWithAll(d);
        } else {
            d3.select(d).classed('active', false);

            this.matrixMainService.onDragFileEnd(d, this.action === actions.drag);
        }

        this.action = actions.none;
        this.drag.x = 0;
        this.drag.y = 0;
        this.initY = 0;
    },

    /**
     * Handle click on share button.
     * Share file with all stations.
     *
     * @param d Element/File to share.
     */
    shareFileWithAll: function(d) {
        var filePath = this.URIService.decode(d3.select(d).attr('data-path'));

        this.matrixService.shareFile(0, filePath);
    },

    /**
     * Start download file from cloud.
     * @param d
     */
    download: function(d) {
        var path = $(d).data('path');

        if (!$(d).hasClass('is-downloading')) {
            this.downloadService.startDownload(path, 'preload');
        }
    },

    /**
     * Update download status of file list after initializing.
     */
    updateItemStates: function() {
        _.each(this.files, function(file) {
            if (file.status) {
                this.updateItem(file);
            }
        }.bind(this));
    },

    /**
     * Update download status of file from cloud.
     *
     * @param item Current item
     * @param action Action
     */
    updateItem: function(item, action) {
        var name = item.filename || item.path;
        var status = item.status === 'running' ? 'download' : item.status;
        var handler = this.stateHandler[action || status];

        var $el = this.$svg.find('.svg-grid-file[data-path="' + name + '"]');
        var $loader = $el.find('.file-loader');
        var $loaderText = $el.find('.file-loader-text');

        if ($el.length > 0 && handler) {
            handler.call(this, $el, $loader, $loaderText, item);
        }
    },

    stateHandler: {
        'pending': function($el, $loader, $loaderText) {
            $loader
                .show()
                .css({
                    width: '0%'
                });
            $loaderText
                .show()
                .find('.text')
                .text('0%');
            $el
                .removeClass('downloadable')
                .addClass('is-downloading')
                .removeClass('draggable');

            this.unbindDOMEvents($el);
        },
        'download': function($el, $loader, $loaderText, item) {
            $loader
                .show()
                .css({
                    width: 'calc(' + item.preload + '% - 8px)'
                });
            $loaderText
                .show()
                .find('.text')
                .text(item.preload + '%');
            $el
                .removeClass('downloadable')
                .addClass('is-downloading')
                .removeClass('draggable');

            this.unbindDOMEvents($el);
        },
        'done': function($el, $loader, $loaderText) {
            $loader.hide();
            $loaderText.hide();
            $el
                .removeClass('downloadable')
                .removeClass('is-downloading')
                .addClass('draggable');

            this.bindDOMEvents($el);
        },
        'failed': function($el, $loader, $loaderText) {
            $loader.hide();
            $loaderText.hide();
            $el
                .addClass('downloadable')
                .removeClass('is-downloading')
                .removeClass('draggable');

            this.bindDOMEvents($el);
        }
    },

    forward: function(d) {
        var path = $(d).data('path');
        var mountType = $(d).data('mount-type');

        this.emit('matrix-box.update', false, path, mountType);
    },

    hide: function(animate) {
        this.emit('matrix-box.hide', animate);
    }
});
