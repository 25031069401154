'use strict';

var _ = require('lodash');
var app = require('../../../app');
var headerTpl = require('./overlay-header-tabbed.hbs');
var i18n = require('i18next');

app.component('OverlayHeaderTabbed', {
    template: headerTpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function(options) {
        this.options = options;
        this.data = {
            tabButtons: options.tabButtons,
            closeButton: options.closeButton,
            saveButton: options.saveButton
        };

        this.storeSelectors();
        this.bindEvents();
    },

    bindEvents: function() {
        this.on('overlay.tabbed.header.update', this.updateHandler.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.overlay-tabbed-btn', this.actionBtnHandler.bind(this));

        if (this.options.closeButton) {
            this.$el.on('click', '#overlay-close-btn', this.closeOverlay.bind(this));
        }

        if (this.options.saveButton) {
            this.$el.on('click', '#overlay-save-btn', this.saveHandler.bind(this));
        }
    },

    postPlaceAt: function() {
        this.bindDOMEvents();
        this.$tabButtons = this.$el.find('.overlay-header-item');

        this.setFocusedElement(true);
    },

    serialize: function() {
        return {
            tabButtons: this.data.tabButtons,
            closeButton: this.data.closeButton,
            saveButton: this.data.saveButton
        };
    },

    storeSelectors: function() {
        this.setFocusedElement();
    },

    /**
     * Sets the focused element.
     *
     * @param {Boolean} focusFirst true - focus first element
     */
    setFocusedElement: function(focusFirst) {
        if (focusFirst) {
            this.$tabButtons[0].classList.add('focused');

            return;
        }

        var focusedEl = _.find(this.options.tabButtons.tabButtons, function(el) {
            return el.focused === true;
        });

        _.each(this.$el.find('.overlay-header-item'), function(btn) {
            if (btn.getAttribute('data-overlay') === focusedEl.overlay) {
                btn.classList.add('focused');
            }
        }.bind(this));
    },

    /**
     * Handles on close button click and close overlay.
     */
    closeOverlay: function() {
        this.emit('overlay.close');
        this.emit('backdrop.hide');
        this.emit('menu.close');
    },

    /**
     * Handles on disconnect button click and disconnect overlay.
     */
    disconnect: function() {
        this.emit('overlay.disconnect');
    },

    /**
     * Opens a different overlay
     *
     * @param event Button event
     */
    actionBtnHandler: function(event) {
        var $item = this.$(event.currentTarget);
        var overlay = $item.data('overlay');

        this.emit('overlay.open', {
            id: overlay,
            closeButton: this.options.closeButton,
            tabButtons: [
                {
                    name: 'room02',
                    title: 'Room 01/250',
                    overlay: 'matrix-main',
                    focused: overlay === 'matrix-main'
                },
                {
                    name: 'preview',
                    title: 'Preview',
                    overlay: 'matrix-preview',
                    focused: overlay === 'matrix-preview'
                }]
        });
    },

    /**
     * Fades the overlay action button out
     *
     * @param delay: delay in milliseconds until the fadeout should be started
     * @param fadeOut: duration of the fadeOut
     */
    actionBtnFadeout: function(delay, fadeOut) {
        this.$el.find('#overlay-action').velocity('fadeOut', {
            delay: delay,
            duration: fadeOut
        });
    },

    /**
     * Updates the header view based on the given options
     *
     * @param  {Object} options contains new data and view options
     */
    updateHandler: function(options) {
        this.options = options;
        this.render();
        this.setFocusedElement(false);
    },

    /**
     * Handle save btn and call event to save matrix configs.
     */
    saveHandler: function() {
        this.$el.find('#overlay-action-button-save')
            .html(i18n.t('settings.action_button_saved'))
            .stop()
            .fadeOut({
                delay: 2000,
                duration: 500,
                complete: function() {
                    this.$el.find('#overlay-action-button-save')
                        .show()
                        .html(i18n.t('settings.action_button_save'));
                }.bind(this)
            });

        if (this.options.overlayId === 'matrix-configurator') {
            this.emit('matrix-grid.save');
        }
    }
});
