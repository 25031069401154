'use strict';

var app = require('../app');
var StateMachine = require('./../state-machine');
var _ = require('lodash');

const types = require('./../framebox-types').types;
const typeIcons = require('./../framebox-types').typeIcons;
const typeIconsMapping = require('./../framebox-types').inputTypeIconsMapping;
const i18n = require('i18next');

/**
 * Stream Input Health States
 * @type {{none: string, ok: string, error: string}}
 */
var healthState = {
    none: 'none',
    ok: 'ok',
    error: 'error'
};

app.service('StreamInputService', function(app) {
    return {

        initialize: function() {
            this.healthStatus = new StateMachine({
                context: this,
                state: healthState.none,
                states: healthState
            });

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.bindEvents();
                }.bind(this));
        },

        bindEvents: function() {
            app.on('status-bar.initialized', this.appendWidget.bind(this));
            app.on('main-loop.update', this.updateHandler.bind(this));
            app.on('main-loop.update.stream-input', this.updateHandler.bind(this));
        },

        updateHandler: function() {
            this.deviceConnection
                .send('getRecordingStreamInputHealthStatus')
                .then(function(healthStatus) {
                    if (this.healthStatus.getState() !== healthStatus.status) {
                        this.healthStatus.changeState(healthStatus.status);

                        app.emit('status-bar.update.ipcam-health-status', {
                            status: this.healthStatus.getState()
                        });
                    }
                }.bind(this));
        },

        appendWidget: function() {
            app.emit('status-widget.item.append', {
                id: 'stream-input-health',
                accessKey: 'StreamInput',
                options: {
                    icon: '',
                    clickable: false,
                    state: healthState.none,
                    visible: false
                }
            });
        },

        /**
         * Show Modal with list of input streams.
         */
        showModal: function() {
            this.getStreamInputs()
                .then(function(streamInputs) {
                    app.emit('modal.open', {
                        id: 'selection-v3',
                        title: i18n.t('sources.streaminputlist'),
                        items: streamInputs.map(item => {
                            // Map index to id since selection modal expects id
                            item.id = item.index;
                            delete item.index;

                            return item;
                        }),
                        onSelect: function(index) {
                            app.emit('menu.close');
                            app.emit('framebox.add', {
                                frameBoxType: types['streaminput'],
                                parameter: index
                            });
                        }.bind(this),
                        autoClose: true
                    });
                }.bind(this));
        },

        /**
         * Get parsed list of stream inputs.
         */
        getStreamInputs: function() {
            return this.deviceConnection
                .send('getStreamInput')
                .then(function(streamInputs) {
                    return this.parseStreamInputs(streamInputs.list, true);
                }.bind(this));
        },

        /**
         * Parse stream inputs for list.
         *
         * @param streamInputs List of stream inputs
         * @returns {Array}
         */
        parseStreamInputs: function(streamInputs, hideDisabled) {
            var list = [];
            var input;

            _.each(streamInputs, function(item) {
                if (item.mode === 'none' || (hideDisabled && item.inUse)) {
                    return;
                }

                input = {
                    index: item.index,
                    name: item.name,
                    type: types['streaminput'],
                    icon: typeIcons[typeIconsMapping[item.type]]
                };
                if (!input.icon) {
                    // Fallback icon
                    input.icon = typeIcons[11];
                }

                list.push(input);
            }.bind(this));

            return list;
        }
    };
});
