'use strict';

var _ = require('lodash');
var app = require('../app');
var sprintf = require('./../helper').sprintf;
var StateMachine = require('./../state-machine');
var i18n = require('i18next');

var states = {
    closed: 'closed',
    opened: 'opened'
};

var groupworkStates = {
    active: 'active',
    inactive: 'inactive'
};

var compabilityStates = {
    ok: 'ok',
    legacyStation: 'legacy-station',
    outdatedStation: 'outdated-station',
    outdatedMaster: 'outdated-master'
};

var sentReqStates = {
    rejected: 'rejected',
    waiting: 'waiting',
    timeout: 'timeout',
    accepted: 'accepted'
};

var receivedReqStates = {
    notWaiting: 'not-waiting',
    waiting: 'waiting'
};

app.service('GroupworkService', function() {
    return {
        initialize: function() {
            this.groupworkState = new StateMachine({
                context: this,
                states: groupworkStates,
                state: groupworkStates.inactive
            });

            this.state = new StateMachine({
                context: this,
                state: states.closed,
                states: states
            });

            this.requestState = new StateMachine({
                context: this,
                state: states.closed,
                states: states
            });

            this.sharingDevices = [];
            this.receivingDevices = [];
            this.availableDevices = [];
            this.deviceList = [];

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.bindEvents();
                    this.updateHandler();
                }.bind(this));

            this.addStateTransitions();
        },

        bindEvents: function() {
            app.on('main-loop.update', this.updateHandler.bind(this));
            app.on('main-loop.update.groupwork', this.updateHandler.bind(this));
        },

        updateHandler: function() {
            this.deviceConnection
                .send('getMatrixStationGroupworkStatus')
                .then(function(groupworkStatus) {
                    if (this.groupworkState.getState() !== groupworkStatus.status) {
                        this.groupworkState.changeState(groupworkStatus.status);

                        app.emit('groupwork.status.changed');
                    }

                    if (groupworkStatus.status === groupworkStates.active) {
                        this.deviceConnection
                            .send('getMatrixGroupworkStationsStatus')
                            .then(function(stations) {
                                if (!_.isEqual(this.deviceList, stations.statusList)) {
                                    this.updateDevices(stations.statusList);
                                }
                            }.bind(this));
                    }
                }.bind(this));
        },

        toggleRequestState: function() {
            if (this.requestState.getState() === states.opened) {
                this.requestState.changeState(states.closed);
            } else {
                this.requestState.changeState(states.opened);
            }
        },

        toggleState: function() {
            if (this.state.getState() === states.opened) {
                this.state.changeState(states.closed);
            } else {
                this.state.changeState(states.opened);
            }
        },

        addStateTransitions: function() {
            this.state.addTransitions({
                '> opened': function() {
                    app.emit('overlay.close');
                    app.emit('modal.close');

                    app.createComponent('ManageGroupwork', {
                        type: 'ManageGroupwork',
                        container: '#submenu-container'
                    });
                },
                '> closed': function() {
                    app.removeComponent('ManageGroupwork', {
                        fadeOut: true
                    });
                }
            });
            this.requestState.addTransitions({
                '> opened': function() {
                    app.emit('overlay.close');
                    app.emit('modal.close');

                    app.createComponent('RequestGroupwork', {
                        type: 'RequestGroupwork',
                        container: '#submenu-container'
                    });
                },
                '> closed': function() {
                    app.removeComponent('RequestGroupwork', {
                        fadeOut: true
                    });
                }
            });
        },

        /**
         * Update device list.
         *
         */
        updateDevices: function(deviceList) {
            this.sharingDevices = [];
            this.receivingDevices = [];
            this.availableDevices = [];

            _.each(deviceList, function(device) {
                if (device.receivedReqStatus === receivedReqStates.waiting) {
                    this.showOsdMessage(device.serial, device.stationName);
                } else {
                    this.hideOsdMessage(device.serial);
                }

                // If a new request is sent (state waiting) or if the current one changed
                if (device.sentReqStatus === sentReqStates.waiting
                    || (this.sentRequestDevice && this.sentRequestDevice.serial === device.serial
                        && this.sentRequestDevice.sentReqStatus !== device.sentReqStatus)) {
                    this.sentRequestDevice = device;
                }

                if (device.present === 0 || device.mode === 0) {
                    return;
                }

                if (device.compatibility === compabilityStates.legacyStation
                    || device.compatibility === compabilityStates.outdatedStation) {
                    return;
                }

                // This station shares content with device with PULL === 1
                if (device.pull === 1) {
                    this.sharingDevices.push(device);
                }

                // This station gets content from station with PUSH === 1
                if (device.push === 1) {
                    this.receivingDevices.push(device);
                }
            }.bind(this));

            this.availableDevices = deviceList.filter(device =>
                !this.receivingDevices.find(d => d.serial === device.serial)
                && !this.sharingDevices.find(d => d.serial === device.serial));

            this.deviceList = deviceList;

            app.emit('groupwork.devices.update');
        },

        /**
         * Show group work screen share request OSD message.
         *
         * @param serial Device serial
         * @param name Device name
         */
        showOsdMessage: function(serial, name) {
            app.emit('osd-message-interactive.show', {
                id: 'groupwork-' + serial,
                icon: 'groupwork',
                osdMessage: sprintf(i18n.t('groupwork.request'), [name]),
                timeout: -1, // No timeout - show forever ;-),
                info: serial,
                hasAction: true
            });
        },

        /**
         * Hide group work screen share request OSD message.
         *
         * @param serial Device serial
         */
        hideOsdMessage: function(serial) {
            app.emit('osd-message-interactive.hide', {
                id: 'groupwork-' + serial
            });
        },

        /**
         * Return all sharing devices.
         *
         * @returns {null|Array}
         */
        getSharingDevices: function() {
            return this.sharingDevices;
        },

        /**
         * Return all receiving devices.
         *
         * @returns {null|Array}
         */
        getReceivingDevices: function() {
            return this.receivingDevices;
        },

        /**
         * Return all available devices (except sharing/receiving)
         *
         * @returns {null|Array}
         */
        getAvailableDevices: function() {
            return this.availableDevices;
        },

        /**
         * Return device with a running request (waiting for response).
         */
        getSentRequestDevice: function() {
            return this.sentRequestDevice;
        },

        /**
         * Reset device after info is shown.
         *
         * @returns {*}
         */
        resetSentRequestDevice: function() {
            this.sentRequestDevice = undefined;

            this.updateHandler();
        },

        /**
         * Send wolfprot for screen sharing request to device/station with serial.
         * @param serial device serial.
         */
        sendScreenShareRequest: function(serial) {
            this.deviceConnection
                .send('setMatrixGroupworkRequestPull', {
                    request: true,
                    serial: serial
                });

            // RELEASE-3322: Get status update immediately
            this.updateHandler();
        },

        /**
         * Cancel screen share request.
         *
         * @param serial Device serial.
         */
        cancelScreenShareRequest: function(serial) {
            this.deviceConnection
                .send('setMatrixGroupworkRequestPull', {
                    request: false,
                    serial: serial
                }).then(function() {
                    this.resetSentRequestDevice();
                }.bind(this));
        },

        /**
         * Handle incomming screen share request.
         *
         * @param accept: true - accept screen sharing, false - reject
         * @param serial: Device serial
         */
        handleScreenShareRequest: function(accept, serial) {
            this.deviceConnection
                .send('setMatrixGroupworkRequestPullAnswer', {
                    accept: accept,
                    serial: serial
                }).then(function() {
                    this.hideOsdMessage(serial);
                    this.resetSentRequestDevice();
                }.bind(this));
        },

        /**
         * Stop sending screen share from other station.
         *
         * @param serial Serial of station which is receiving the stream.
         */
        stopScreenShare: function(serial) {
            this.deviceConnection
                .send('setMatrixGroupworkSendPullStop', {
                    serial: serial
                });

            app.emit('main-loop.fast.start', {
                id: 'groupwork'
            });
        },

        /**
         * Stop receiving screen share from other station.
         *
         * @param serial Serial of station which is receiving the stream.
         */
        stopScreenReceive: function(serial) {
            this.deviceConnection
                .send('setMatrixGroupworkReceivePullStop', {
                    serial: serial
                });

            app.emit('main-loop.fast.start', {
                id: 'groupwork'
            });
        },

        /**
         * Is groupwork active.
         *
         * @returns {boolean}
         */
        isGroupworkActive: function() {
            return this.groupworkState.getState();
        }
    };
});
