const template = require('./groupwork-controls.html');
const vueUtils = require('../../util.js');
const app = require('../../../app.js');

module.exports = {
    template,

    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data: function() {
        return {
            streamingActive: true
        };
    },

    methods: {
        /*
         * Open modal to manage groupwork
         */
        manageGroupwork: function() {
            app.emit('modal.open', {
                id: 'manageGroupwork',
                action: 'manage'
            });
        },

        /*
         * Open modal to request screen sharing
         */
        requestScreenShare: function() {
            app.emit('modal.open', {
                id: 'manageGroupwork',
                action: 'request-screen-share'
            });
        },

        i18n: vueUtils.i18n
    },

    created: function() {
        this.groupworkService = app.getService('GroupworkService');

        const updateData = function() {
            const anyDevicesSharing = this.groupworkService.getSharingDevices().length > 0;
            const anyDevicesReceiving = this.groupworkService.getReceivingDevices().length > 0;
            this.streamingActive = anyDevicesSharing || anyDevicesReceiving;
        }.bind(this);

        this.evctx = vueUtils.eventContext();
        this.evctx.on('groupwork.devices.update', function() {
            updateData();
        }.bind(this));
        updateData();
    },

    destroyed: function() {
        this.evctx.close();
    },

    components: {
        'command-button': require('../command-button/command-button.js')
    }
};
