'use strict';

var app = require('../app');
var $ = require('jquery');
var i18n = require('i18next');
var _ = require('lodash');

const lcsTypes = require('../../app/states').lcsTypes;
const lcsCmdToTypeMapping = require('../../app/states').lcsCmdToTypeMapping;

app.service('UserSettingsService', function() {
    return {
        initialize: function() {
            this.deviceService = app.getService('DeviceService');

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.loadSettings();
                }.bind(this));
        },

        /**
         * Open User Settings Dialog.
         *
         * @param load true ('load' - load user settings from USB) / false ('save' - save user settings to USB)
         * @param data { loginService, loadBookmarks, tryToConnect, loginOfficeApp }
         */
        openUserSettingsDialog: function(load, data) {
            this.loadSettings()
                .then(this.prepareUserSettings(load)
                    .then(function(userSettings) {
                        if (load && !this.anyServiceConnected) {
                            return;
                        }

                        var configs = {
                            component: 'UserSettings',
                            id: 'userSettings',
                            dialog: load ? 'load' : 'save',
                            serviceList: userSettings,
                            role: {
                                name: 'UserSettings',
                                key: 'load'
                            }
                        };

                        configs = $.extend(configs, data);
                        app.emit('modal.open', configs);
                    }.bind(this)));
        },

        /**
         * Load settings for future checks.
         */
        loadSettings: function() {
            var dfd = $.Deferred();

            this.deviceConnection
                .send([
                    'getLicenseFeatures',
                    'getCloudServicesEnabled',
                    'getOffice365Function',
                    'getLectureCaptureSettings',
                    'getPanoptoSettings'
                ])
                .then(function(features, cloudServices, office365, lcsSettings, panoptoSettings) {
                    this.features = features;
                    this.cloudServices = cloudServices;
                    this.office365 = office365;
                    this.office365Credentials = false;

                    // If Composer change lecture capture type to disabled if opencast was selected in normal mode
                    if (this.deviceService.isCboxProDualProjection()
                        && ['opencastAdhoc', 'opencastAuto'].includes(lcsCmdToTypeMapping[lcsSettings.type])) {
                        lcsSettings.type = 0;
                    }

                    this.lcsType = lcsCmdToTypeMapping[lcsSettings.type];
                    this.panoptoCustomUserMode = panoptoSettings.customUserMode;

                    dfd.resolve();
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Prepare User Settings for dialog (load/save)
         *
         * @param load true/false (load/save dialog)
         */
        prepareUserSettings: function(load) {
            var dfd = $.Deferred();

            this.anyServiceConnected = false;
            this.deviceConnection
                .send([
                    {
                        command: 'getUserSettingsStatus',
                        data: {
                            mode: load ? 'usb' : 'internal'
                        }
                    }
                ]).then(function(userSettingsUsb) {
                    dfd.resolve(this.parse(userSettingsUsb.settings));
                }.bind(this), function() {
                    dfd.resolve([]);
                });

            return dfd.promise();
        },

        /**
         * Parse User Settings.
         *
         * @param data serviceList
         */
        parse: function(data) {
            var serviceList = data;
            var parsedData = [];
            var officeGroup = null;

            _.each(serviceList, function(group, key) {
                this.groupData = {
                    name: i18n.t('modal.' + key),
                    length: group.length,
                    key: key,
                    services: []
                };

                switch (key) {
                    case 'cloud_services':
                        this.groupData.cloudServices = this.cloudServices;
                        break;
                    case 'office365':
                        // Do not show settings for Office 365 if feature pack isn't activated or function is disabled
                        if (!this.features.office || !this.office365.function) {
                            return;
                        }
                        break;
                    case 'lcs':
                        if (!this.features.capture || this.lcsType === lcsTypes.disabled || !this.panoptoCustomUserMode) {
                            return;
                        }
                        break;
                }

                _.each(group, function(service) {
                    _.each(service, function(service, key) {
                        this.serviceData = {
                            name: service.name,
                            status: service.status === 1
                        };

                        if (this.groupData.length === 1) {
                            this.handleSingleGroup(service, key);

                            return;
                        }

                        if (this.groupData.cloudServices) {
                            this.handleCloudServices(service, key);

                            return;
                        }

                        if (this.serviceData.status) {
                            this.anyServiceConnected = true;
                        }

                        if (this.groupData.key === 'office365') {
                            if (service.name === 'credentials') {
                                this.handleSingleGroup(service, this.groupData.key);
                                officeGroup = this.groupData;
                                this.office365Credentials = service.status === 1;
                            }

                            return;
                        }

                        this.groupData.services.push(this.serviceData);
                    }.bind(this));
                }.bind(this));

                // No enabled services in this group (e.g. cloud services --> no service enabled)
                if (!this.groupData.status && this.groupData.services.length === 0) {
                    return;
                }

                if (this.groupData.key !== 'office365') {
                    parsedData.push(this.groupData);
                }
            }.bind(this));

            // Always show office 365 at the end of list.
            if (officeGroup !== null) {
                parsedData.push(officeGroup);
            }

            return parsedData;
        },

        /**
         * If only one service, simplify.
         *
         * @param service Current service
         * @param key Current service key
         */
        handleSingleGroup: function(service, key) {
            this.groupData.name = i18n.t('modal.' + key);
            this.groupData.hasStatus = true;
            this.groupData.status = service.status === 1;

            if (this.groupData.status) {
                this.anyServiceConnected = true;
            }
        },

        /**
         * Do not add disabled cloud services.
         *
         * @param service Current service
         * @param key Current service key
         */
        handleCloudServices: function(service, key) {
            var skipServices = $.map(this.groupData.cloudServices, function(value, serviceName) {
                if (value === false) {
                    return serviceName.replace('Enabled', '');
                }
            });

            if ($.inArray(key.toLowerCase(), skipServices) < 0) {
                this.groupData.services.push(this.serviceData);

                if (this.serviceData.status) {
                    this.anyServiceConnected = true;
                }
            }
        },

        /**
         * Check if any service is connected.
         *
         * @returns {boolean} true/false
         */
        isAnyServiceConnected: function() {
            return this.anyServiceConnected;
        },

        /**
         * Load user settings from USB stick.
         *
         * @param load: true/false
         * @return {*}
         */
        loadUserSettings: function(load) {
            return this.deviceConnection
                .send('setLoadUserData', { load: load });
        }
    };
});
