// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"menu-item\">\n                <div class=\"title-container\">\n                    <span class=\"title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"matrix.power_stations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":40},"end":{"line":7,"column":69}}}))
    + "</span>\n                </div>\n                <div class=\"action-container\">\n                    <div id=\"power-on-btn\" class=\"power-on-btn action-btn\" data-action=\"powerOn\">\n                        <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"matrix.on",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":30},"end":{"line":11,"column":47}}}))
    + "</span>\n                    </div>\n                    <div class=\"separator-small\"></div>\n                    <div id=\"power-off-btn\" class=\"power-on-btn action-btn\" data-action=\"powerOff\">\n                        <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"matrix.off",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":30},"end":{"line":15,"column":48}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"matrix-menu\" class=\"matrix-menu-container\">\n    <div class=\"menu-triangle\"></div>\n    <div class=\"menu-items\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"autoStartEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});
