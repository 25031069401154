'use strict';

const app = require('../app/app');
const _ = require('lodash');
const $ = require('jquery');
const users = require('./users.json');
const baseGroup = require('./base-group.json');
const groups = require('./groups.json');
const RBACException = require('./rbac-exception.js');
const platform = require('./../platform/platform');
require('./rbac-element.js');

var instance = null;
const DEFAULT_USER = users.none.key;

/**
 * Role Based Access Control
 * https://en.wikipedia.org/wiki/Role-based_access_control
 *
 * @constructor
 */
var RBAC = function() {
    // Constants
    this.DEFAULT_ROLE_KEY = 'show';
    this.USERS = users;

    this.user = DEFAULT_USER;
    this.group = null;

    this.setGroup();
    this.bindDOMEvents();
};

/**
 * Binds all DOM Events
 */
RBAC.prototype.bindDOMEvents = function() {
    app.on('rbac.user.changed', this.onUserChanged.bind(this));
};

/**
 * @param {object} options
 */
RBAC.prototype.onUserChanged = function(options) {
    this.user = options.key;

    this.setGroup();
    this.checkAllDOMElements();
};

RBAC.prototype.checkAllDOMElements = function() {
    var $els = $('rbac-access');

    $els.each(function(key, el) {
        el.checkAccess();
    });
};

/**
 * Set the current group from user
 *
 * @returns {boolean}
 */
RBAC.prototype.setGroup = function() {
    try {
        var user = this.USERS[this.user];
        var groupRoles;

        if (platform.checks.isCboxAux) {
            user = this.USERS['aux'];
        }

        // Check user is defined
        if (!user) {
            throw new RBACException('User "' + this.user + '" is not defined in users.json.');
        }

        // Check group is set
        if (!groups[user.group]) {
            throw new RBACException('Group "' + user.group + '" is not defined in group.json.');
        }

        groupRoles = _.extend(_.clone(baseGroup.roles), groups[user.group].roles);

        if (app.getService('DeviceService') && app.getService('DeviceService').isCboxProDualProjection()) {
            if (user.extendGroup) { // User/admin
                groupRoles = _.extend(groupRoles, groups[user.extendGroup].roles);
            }
        }

        groups[user.group].roles = groupRoles;

        this.group = groups[user.group];

        return true;
    } catch (error) {
        var exceptionManager = app.getService('ExceptionsManager');

        exceptionManager.throw(error);

        return false;
    }
};

/**
 * Checks the access with the roleName and roleKey
 *
 * @param {String} role
 * @param {String} roleKey
 * @param {Boolean} silent: true - no exception is thrown
 *
 * @returns {Boolean}
 */
RBAC.prototype.hasAccess = function(roleName, roleKey, silent = false) {
    try {
        if (!roleKey) {
            roleKey = this.DEFAULT_ROLE_KEY;
        }

        // Check group is set
        if (null === this.group) {
            throw new RBACException('Group is currently not set.');
        }

        const role = this.group.roles[roleName];

        // Check role exists
        if (!role) {
            throw new RBACException('Role with name "' + roleName + '" does not exist.');
        }

        const access = role[roleKey];

        // Check access exists
        if (!access) {
            throw new RBACException('Role has no access. ALL OK! (' + this.USERS[this.user].group + ' / ' + roleName + ' / ' + roleKey + ')', true);
        }

        return true;
    } catch (error) {
        if (!silent) {
            const exceptionManager = app.getService('ExceptionsManager');
            exceptionManager.throw(error);
        }

        return false;
    }
};

/**
 * Singletone
 *
 * @returns {RBAC}
 */
function getInstance() {
    if (null === instance) {
        instance = new RBAC();
    }

    return instance;
}

module.exports = getInstance();
