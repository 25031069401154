const template = require('./general-controls.html');
const vueUtils = require('../../util.js');
const app = require('../../../app');
const platform = require('../../../../platform/platform');

module.exports = {
    template,

    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        expand: {
            type: Boolean
        },

        showGroupworkButton: {
            type: Boolean,
            required: true
        },

        showDownloadAppButton: {
            type: Boolean,
            required: true
        },

        showRoomControllerButton: {
            type: Boolean,
            required: true
        },

        showSaveUserSettingsButton: {
            type: Boolean,
            required: true
        },

        showSplashScreenButton: {
            type: Boolean,
            required: true
        },

        showCloseAllWindowsButton: {
            type: Boolean,
            required: true
        }
    },

    data: function() {
        return {
            frozen: false,

            showHelp: true,
            helpEnabled: true,
            showKeyboard: true,
            showSettings: true,
            settingsEnabled: true
        };
    },

    computed: {
        /**
         * Align the small buttons to the right if there is no button on the left to avoid gaps.
         */
        alignRight: function() {
            return !this.showGroupworkButton
                && !this.showDownloadAppButton
                && !this.showSaveUserSettingsButton
                && !this.showSplashScreenButton
                && !this.showRoomControllerButton;
        }
    },

    methods: {
        /**
         * Ask user for end presentation
         */
        askForEndPresentation: function() {
            this.powerService.openEndPresentationModal();
        },

        /**
         * Open the touch keyboard on Cynap
         */
        openKeyboard: function() {
            this.keyboardService.open();
            this.$emit('close');
        },

        /**
         * Open settings overlay and close control center
         */
        openSettings: function() {
            app.emit('overlay.open', {
                id: 'general-settings',
                extendedConfigs: 'settings-list',
                animation: 'fade'
            });
            this.$emit('close');
        },

        /**
         * Close all open windows on Cynap and close control center
         */
        closeAllWindows: function() {
            app.emit('modal.open', {
                id: 'close-all-windows',
                onConfirm: function() {
                    this.annotationService.clearAllStoredSettings();
                    this.$emit('close');
                    app.emit('frameboxes.close.all');
                }.bind(this)
            });
        },

        /**
         * Open help screen and close control center
         */
        openHelp: function() {
            this.wolfprot.talk('setHelpPage', {
                action: 'open'
            });
            this.$emit('close');
        },

        /**
         * Open overlay with app download and close control center
         */
        downloadApp: function() {
            app.emit('overlay.open', {
                id: 'download-apps-list',
                closeButton: 'closeButton'
            });
            this.$emit('close');
        },

        /**
         * Save user settings to USB stick
         */
        saveUserSettings: function() {
            this.userSettingsService.openUserSettingsDialog(false, {
                loginService: ''
            });
        },

        /**
         * Show splash screen
         */
        showSplashScreen: function() {
            if (platform.checks.isCbox) {
                app.emit('splash-screen.show');
            } else {
                this.wolfprot.talk('setSplashScreenTrigger');
            }

            this.$emit('close');
        },

        /**
         * Show room controller
         */
        showRoomController: function() {
            this.wolfprot.talk('getRoomControllerPath').then(function(roomControllerPath) {
                const url = `${window.location.protocol}//${window.location.host}/${roomControllerPath.path}`;

                app.emit('modal.open', {
                    id: 'room-controller',
                    url: url
                });
            }.bind(this));

            this.$emit('close');
        },

        i18n: vueUtils.i18n
    },

    created: function() {
        this.powerService = app.getService('PowerService');
        this.keyboardService = app.getService('KeyboardService');
        this.annotationService = app.getService('AnnotationService');
        this.customUiSettings = app.getService('CustomUiSettings');
        this.mountService = app.getService('MountService');
        this.userSettingsService = app.getService('UserSettingsService');
        this.freezeService = app.getService('FreezeService');
        this.remote = app.getService('RemoteService');
        this.authService = app.getService('AuthenticationService');
        this.deviceService = app.getService('DeviceService');

        this.wolfprot = vueUtils.wolfprot();
        this.wolfprot.talk('setHelpPage', {
            action: 'check'
        });

        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return Promise.all([
                    this.customUiSettings.getSettings(['helpEnabled']),
                    this.wolfprot.talk('getHelpPageStatus'),
                    this.remote.loadKeyboardSettings()
                ]).then((result) => {
                    const helpEnabled = result[0];
                    const helpStatus = result[1];
                    const isTouchKeyboardEnabled = result[2];

                    const isFreeze = this.freezeService.isFreeze();

                    return {
                        helpEnabled,
                        helpStatus,
                        isFreeze,
                        isTouchKeyboardEnabled
                    };
                });
            }.bind(this)
        });
        this.pollHelper.on('data', function({
            helpEnabled,
            helpStatus,
            isFreeze,
            isTouchKeyboardEnabled
        }) {
            this.showHelp = helpEnabled;
            this.showKeyboard = !this.deviceService.isCboxPureReceiver()
                && platform.checks.isCbox && isTouchKeyboardEnabled;
            this.helpEnabled = helpStatus.status === 'available';
            this.showSettings = true;
            this.settingsEnabled = !this.authService.isMatrixControlInstance();
            this.frozen = isFreeze;
        }.bind(this));
        this.pollHelper.schedulePoll();

        this.evctx = vueUtils.eventContext();
        this.evctx.on('main-loop.update', function() {
            this.pollHelper.schedulePoll();
        }.bind(this));
        this.evctx.on('control-center.opened', function() {
            this.wolfprot.talk('setHelpPage', {
                action: 'check'
            });
        }.bind(this));
    },

    destroyed: function() {
        this.evctx.close();
    },

    components: {
        'command-button': require('../command-button/command-button.js'),
        'groupwork-controls': require('../groupwork-controls/groupwork-controls.js')
    }
};
