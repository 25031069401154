// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row settings-list-secundary content-enable has-content\"\n                     data-nav-area=\".btn-switch\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-8\">\n                        <h2 class=\"input-label\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":44,"column":28},"end":{"line":52,"column":35}}})) != null ? stack1 : "")
    + "                        </h2>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input id=\"teamsContentEnabled\"\n                                   name=\"teamsContentEnabled\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch\" />\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.teams_show_window",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":32},"end":{"line":45,"column":66}}}))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"supportsRecording") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":47,"column":32},"end":{"line":51,"column":39}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.teams_show_window_webcast",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":36},"end":{"line":48,"column":78}}}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.teams_show_window_screen_share",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":36},"end":{"line":50,"column":83}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"office365-settings\" name=\"office365-settings\">\n        <div class=\"row settings-list-row\"\n             data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n             data-nav-trapped\n             data-nav-area-default>\n            <div class=\"col-xs-8\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.office365_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":53}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input id=\"office365Enabled\" name=\"office365Enabled\" type=\"checkbox\" class=\"btn-switch\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"office365-settings-container\">\n            <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.teams_source_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":44},"end":{"line":23,"column":80}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"teamsSourceEnabled\"\n                               name=\"teamsSourceEnabled\"\n                               type=\"checkbox\"\n                               class=\"btn-switch is-focusable\"\n                               tabIndex=\"-1\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDualProjection") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":67,"column":23}}})) != null ? stack1 : "")
    + "\n            <div id=\"office-local-container\" class=\"office-local-container\">\n                <div class=\"row settings-list-row\"\n                     data-nav-area=\".btn-switch, .focusable:not(:disabled)\"\n                     data-nav-trapped\n                     data-nav-area-default>\n                    <div class=\"col-xs-8\">\n                        <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.office365_local_account",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":28},"end":{"line":75,"column":68}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input name=\"officeLocalAccountEnable\"\n                                   id=\"officeLocalAccountEnable\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"row settings-list-secundary\"\n                     id=\"localAccountCredentials\"\n                     data-nav-area=\".is-focusable\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"roomId\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":95,"column":68},"end":{"line":95,"column":91}}}))
    + "</label>\n                            <input id=\"officeEmail\"\n                                   type=\"text\"\n                                   class=\"input is-focusable\"\n                                   name=\"officeEmail\"\n                                   tabIndex=\"-1\"\n                                   data-validation=\"requiredOrDisabled\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.required",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":103,"column":79},"end":{"line":103,"column":120}}}))
    + "</span>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"password\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":109,"column":70},"end":{"line":109,"column":96}}}))
    + "</label>\n                            <input id=\"officePwd\"\n                                   autocomplete=\"off\"\n                                   type=\"password\"\n                                   class=\"input is-focusable\"\n                                   name=\"officePwd\"\n                                   tabIndex=\"-1\"\n                                   data-validation=\"requiredOrDisabled\"/>\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.required",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":118,"column":79},"end":{"line":118,"column":120}}}))
    + "</span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"form-action-container\" class=\"form-primary-background\"></div>\n    </form>\n</div>\n";
},"useData":true});
