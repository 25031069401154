'use strict';

var app = require('../../app');
var selectBoxTpl = require('./select-box.hbs');
var _ = require('lodash');
var $ = require('jquery');

var itemTypes = {
    toggle: 'toggle',
    click: 'click',
    singleChoice: 'singleChoice'
};

app.component('SelectBox', {
    template: selectBoxTpl,

    getAccessKey: function getAccessKey() {
        return {
            'roleName': 'MatrixBox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.configs = this.getService('MatrixConfigs');
        this.matrixConfigService = this.getService('MatrixConfiguratorService');
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.svg-grid-item:not(.header), .btn-mini-switch', this.selectItem.bind(this));
    },

    bindEvents: function() {
        this.on('matrix-box.update', this.updateItems.bind(this));
    },

    postPlaceAt: function() {
        this.updateItems();
        this.bindEvents();

        // Workaround: Avoid clicking on menu button and trigger event at the same time (e.g. remove station event when opening menu)
        setTimeout(this.bindDOMEvents.bind(this), 250);
    },

    serialize: function() {
        this.getItemList(this.options.items);

        return {
            items: this.items,
            width: this.width,
            height: this.height,
            isStationMenu: this.options.isStationMenu
        };
    },

    getItemList: function(items) {
        var x0 = 10;

        this.width = parseInt(this.configs.get('matrixBox.itemWidth') - 12); // - scroll bar width
        this.height = 0;

        _.each(items, function(item) {
            item.pos = {
                x: x0,
                y: this.height
            };
            item.dim = {
                width: this.width,
                height: item.header
                    ? parseInt(this.configs.get('matrixBox.itemHeaderHeight'))
                    : parseInt(this.configs.get('matrixBox.itemHeight'))
            };

            this.height += item.dim.height;
        }.bind(this));

        this.options.container.css('height', this.height + 'px');
        this.items = items;
    },

    /**
     * Switch to a new item.
     *
     * @param item - clickEvent
     */
    selectItem: function(data) {
        var item = $(data.currentTarget);
        var id = parseInt(item.attr('id'));

        var selectedItem = $.grep(this.items, function(e) {
            return e.id === id;
        });

        if (selectedItem.length > 0 && selectedItem[0].callback) {
            switch (selectedItem[0].itemType) {
                case itemTypes.click:
                    this.emit('matrix-box.hide');
                    selectedItem[0].callback(selectedItem[0]);
                    break;
                case itemTypes.singleChoice:
                    this.singleChoice(id, selectedItem[0].itemGroup);
                    selectedItem[0].callback(selectedItem[0]);
                    break;
                case itemTypes.toggle:
                    if (item.hasClass('btn-mini-switch')) {
                        selectedItem[0].callback(selectedItem[0]);
                    }
                    break;
            }
        } else { // Matrix Box: Configurations
            this.$el.find('.icon-selected').hide();
            item.find('.icon-selected').show();

            if (id !== this.configs.getCurrentMatrixTemplateId()) {
                if (this.options.isConfigurator) {
                    this.matrixConfigService.checkUnsavedConfig().then(function(unsavedChanges) {
                        this.matrixConfigService.checkStationStatus().then(function(stationsOffline, maxReached) {
                            if ((maxReached || stationsOffline.length > 0) && unsavedChanges) {
                                this.emit('modal.open', {
                                    id: 'matrix-stations-offline',
                                    stations: stationsOffline,
                                    maxReached: maxReached,
                                    closeConfigurator: false,
                                    loadTemplate: true,
                                    onLoadTemplate: function() {
                                        this.configs.setCurrentMatrixTemplate(id, true);
                                        this.changeSelectedIcon.bind(this, item);
                                    }.bind(this),
                                    role: {
                                        name: 'Matrix',
                                        key: 'show'
                                    }
                                });
                            } else {
                                this.configs.changeMatrixTemplate(id, this.options.isConfigurator, this.changeSelectedIcon.bind(this, item));
                            }
                        }.bind(this));
                    }.bind(this));
                } else {
                    this.configs.changeMatrixTemplate(id, this.options.isConfigurator, this.changeSelectedIcon.bind(this, item));
                }
            }

            this.emit('matrix-box.hide');
        }
    },

    /**
     * Choose one item with ID from group.
     *
     * @param id Item ID
     * @param group Group of items to choose from
     */
    singleChoice: function(id, group) {
        var hidden;

        if (group) {
            hidden = this.$el.find('#' + id + '[data-group=' + group + ']').find('.icon-selected').css('display') === 'none';

            this.$el.find('[data-group=' + group + ']').find('.icon-selected').hide();

            if (hidden) {
                this.$el.find('#' + id + '[data-group=' + group + ']').find('.icon-selected').show();
            } else {
                this.$el.find('#' + id + '[data-group=' + group + ']').find('.icon-selected').hide();
            }
        } else {
            hidden = this.$el.find('#' + id).find('.icon-selected').css('display') === 'none';

            this.$el.find('.icon-selected').hide();

            if (hidden) {
                _.each(this.items, function(e) {
                    e.checked = (e.id === id);
                }.bind(this));

                this.$el.find('#' + id).find('.icon-selected').show();
            } else {
                this.$el.find('#' + id).find('.icon-selected').hide();
            }
        }
    },

    /**
     * Check/Uncheck items.
     *
     * @param id Update item with ID
     */
    updateItems: function() {
        _.each(this.items, function(item) {
            var $el = this.$el.find('#' + item.id);

            if (item.checked) {
                $el.find('.icon-selected').show();
            } else {
                $el.find('.icon-selected').hide();
            }

            if (item.icon) {
                $el.find('.icon').show();
            }

            if (item.itemType === itemTypes.toggle) {
                $el.find('.btn-mini-switch').prop('checked', item.checked);
            }

            if ($el.find('.text')[0].scrollWidth > $el.find('.text').width()) {
                $el.find('.text').addClass('two-lines');
            } else {
                $el.removeClass('two-lines');
            }
        }.bind(this));
    },

    updateSelect: function() {
        this.$el.find('.icon-selected').hide();
        this.$el.find('#' + this.configs.getCurrentMatrixTemplateId()).find('.icon-selected').show();

        _.each(this.items, function(item) {
            var $el = this.$el.find('#' + item.id);

            if ($el.find('.text')[0].scrollWidth > $el.find('.text').width()) {
                $el.find('.text').addClass('two-lines');
            } else {
                $el.removeClass('two-lines');
            }
        }.bind(this));
    }
});
