// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"media-casting-overlay\">\n    <div class=\"row title-container\">\n        <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.choose_recording_type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":47}}}))
    + "</h2>\n    </div>\n\n    <div id=\"start-panopto\" class=\"row media-cast-item-container is-focusable hidden\" tabindex=\"-1\">\n        <div class=\"col-xs-12 media-casting-item\">\n            <div class=\"services-disabled\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.start_panopto",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":43},"end":{"line":8,"column":70}}}))
    + "</div>\n        </div>\n    </div>\n\n    <div id=\"start-opencast\" class=\"row media-cast-item-container is-focusable hidden\" tabindex=\"-1\">\n        <div class=\"col-xs-12 media-casting-item\">\n            <div class=\"services-disabled\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.start_opencast",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":43},"end":{"line":14,"column":71}}}))
    + "</div>\n        </div>\n    </div>\n\n    <div id=\"start-local-recording\" class=\"row media-cast-item-container is-focusable\" tabindex=\"-1\">\n        <div class=\"col-xs-12 media-casting-item\">\n            <div class=\"services-disabled\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.start_recording",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":43},"end":{"line":20,"column":72}}}))
    + "</div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});
