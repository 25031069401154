'use strict';

var _ = require('lodash');
var $ = require('jquery');

function BaseView(app, configs) {
    /**
     * Reference to the app instance
     * @property app
     */
    this.app = app;

    /**
     * JQuery Reference to component element
     * @property $el
     */
    this.$el = $('<div/>', {
        'class': 'overlay-content-view scrollable-remote'
    });

    /**
     * The view id
     * @type {String}
     */
    this.id = _.uniqueId('overlayView');

    /**
     * The container selector where the component should be placed at
     * @type {Mixed}
     */
    this.componentContainer = null;

    /**
     * Here we get the overlay config
     */
    this.configs = configs;
}

/**
 * Check for input changes on view.
 *
 * @method hasChanges
 * @chainable
 */
BaseView.prototype.hasChanges = function hasChanges() {
    return this.app.componentHasChanges(this.id);
};

/**
 * Removes the view and the used component instance
 * @method destroy
 * @chainable
 */
BaseView.prototype.destroy = function destroy() {
    this.app.removeComponent(this.id);
    this.$el.off();
    this.$el.remove();

    return this;
};

/**
 * Save the component blueprint name
 * @method addComponent
 * @param  {String}     componentType Name of the component blueprint
 */
BaseView.prototype.addComponent = function addComponent(componentType) {
    this.componentType = componentType;

    return this;
};

/**
 * Render the view and initialize the used component
 * @method render
 */
BaseView.prototype.render = function render() {
    var tpl = this.template();
    this.$el.append(tpl);

    // If you use the scroll-view-list you must disable the scrolling.
    if (this.configs.disableScrolling) {
        this.$el.addClass('overlay-disable-scrolling');
    }

    if (this.configs.fullHeight) {
        this.$el.addClass('overlay-full-height');
    }

    this.app.createComponent(this.id, {
        type: this.componentType,
        container: this.$el.find(this.componentContainer),
        configs: this.configs
    });

    return this;
};

/**
 * After the view has been rendered place it inside the dom
 * @method placeAt
 * @param  {Mixed} container Container element for the view
 * @chainable
 */
BaseView.prototype.placeAt = function placeAt(container) {
    $(container).append(this.$el);
    this.app.getComponent(this.id).$el.trigger('overlay.update');

    return this;
};

/**
 * Detach the view from the DOM
 * @method detach
 * @chainable
 */
BaseView.prototype.detach = function detach() {
    this.$el = this.$el.detach();

    return this;
};

/**
 * Called on history back
 * @method historyBack
 * @chainable
 */
BaseView.prototype.historyBack = function historyBack() {
    this.app.getComponent(this.id).$el.trigger('overlay.history.back');

    return this;
};

module.exports = BaseView;
