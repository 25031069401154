/**
 * Output mapping
 */
const outputMapping = {
    0xFF: 'hdmi1',
    0xFC: 'hdmi2'
};

module.exports = {
    outputMapping
};
