// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"stream-input-settings\"\n      id=\"stream-input-settings-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\" name=\"stream-input-settings\"\n      data-index=\"\"\n      data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\"\n      data-nav-trapped>\n    <div class=\"item-container\" tabIndex=\"-1\">\n        <div class=\"row settings-list-secundary\" >\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"name\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.stream_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":58},"end":{"line":10,"column":86}}}))
    + "</label>\n                    <input type=\"text\" class=\"input\" name=\"name\" id=\"stream-name-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\" data-validation=\"checkEncodedMinMaxLength\" data-min-length=\"1\" data-max-length=\"63\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\" id=\"name-error\">\n                            "
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":28},"end":{"line":14,"column":59}}}))
    + "\n                        </span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"col-xs-6\">\n                <div id=\"stream-mode-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\" class=\"stream-mode\"></div>\n            </div>\n        </div>\n        <div class=\"row settings-list-secundary\">\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"url\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.stream_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":57},"end":{"line":26,"column":84}}}))
    + "</label>\n                    <input type=\"text\" class=\"input\" name=\"url\" id=\"stream-url-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\" data-validation=\"checkUrl\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\" id=\"url-error\">\n                            "
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.wrong_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":28},"end":{"line":30,"column":54}}}))
    + "\n                        </span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"col-xs-6\">\n                <div id=\"stream-type\"></div>\n            </div>\n        </div>\n        <div class=\"row settings-list-secundary\" >\n            <div class=\"col-xs-6 switch-below-text\">\n                <label class=\"input-label\" for=\"lowlatency\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.low_latency",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":60},"end":{"line":41,"column":88}}}))
    + "</label>\n                <div class=\"btn-switch-container\">\n                    <input name=\"lowlatency\"\n                           type=\"checkbox\"\n                           class=\"btn-switch is-focusable\"\n                           id=\"low-latency-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"col-xs-6 switch-below-text\">\n                <label class=\"input-label\" for=\"mute\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.mute_audio",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":54},"end":{"line":53,"column":81}}}))
    + "</label>\n                <div class=\"btn-switch-container\">\n                    <input name=\"mute\"\n                           type=\"checkbox\"\n                           class=\"btn-switch is-focusable\"\n                           id=\"mute-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"row settings-list-secundary text-centered\" id=\"pip-warning\">\n            <span class=\"input-info-message error-message\" id=\"name-error-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\">\n                "
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.error_messages.input_stream_in_use",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":16},"end":{"line":67,"column":67}}}))
    + "\n            </span>\n        </div>\n    </div>\n    <div id=\"form-action-container\" class=\"form-primary-background\" data-index=\"\"></div>\n</form>\n";
},"useData":true});
