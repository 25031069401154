// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-secundary\" data-nav-area=\".line-out-volume-range\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.line_out",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":44},"end":{"line":13,"column":69}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4 pull-right\">\n                    <div class=\"line-out-volume\">\n                        <div class=\"line-out-volume-range\" data-nav-area=\".focusable\"></div>\n                    </div>\n                </div>\n            </div>\n\n            <!-- Line In -->\n            <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.line_in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":44},"end":{"line":25,"column":68}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"line-in-level\">\n                        <div class=\"line-in-level-range\" data-nav-area=\".focusable\"></div>\n                    </div>\n                </div>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-secundary\" data-nav-area=\".hdmi-out2-volume-range\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.hdmi_out2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":44},"end":{"line":50,"column":70}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4 pull-right\">\n                    <div class=\"hdmi-out2-volume\">\n                        <div class=\"hdmi-out2-volume-range\" data-nav-area=\".focusable\"></div>\n                    </div>\n                </div>\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-secundary\" data-nav-area=\".usb-mic-volume-range\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.usb_mic_volume",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":44},"end":{"line":64,"column":75}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4 pull-right\">\n                    <div class=\"usb-mic-volume\">\n                        <div class=\"usb-mic-volume-range\" data-nav-area=\".focusable\"></div>\n                    </div>\n                </div>\n            </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-row\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.line_in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":89,"column":24},"end":{"line":89,"column":48}}}))
    + "</h2>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".btn-switch\"\n                 data-nav-trapped\n                 data-nav-default-focus\n                 data-nav-area-default>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.line_in_local_output",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":99,"column":44},"end":{"line":99,"column":81}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"line-in-local-output\"\n                               name=\"line-in-local-output\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <!-- Remote Output -->\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":118,"column":24},"end":{"line":126,"column":31}}})) != null ? stack1 : "")
    + "                    </h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input  id=\"line-in-remote-output\"\n                                name=\"line-in-remote-output\"\n                                type=\"checkbox\"\n                                class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.line_in_remote_output",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":119,"column":28},"end":{"line":119,"column":66}}}))
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCoreProLcs") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":121,"column":28},"end":{"line":125,"column":35}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.line_in_remote_output_webcast",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":122,"column":32},"end":{"line":122,"column":78}}}))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.line_in_webconf_output",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":124,"column":32},"end":{"line":124,"column":71}}}))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-row\" data-nav-area=\".is-focusable, .btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.echo_cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":147,"column":24},"end":{"line":147,"column":52}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"enable-echo-cancel\"\n                               name=\"enable-echo-cancel\"\n                               type=\"checkbox\"\n                               class=\"btn-switch\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary echo-cancel-settings\" data-nav-area=\".echo-cancel-delay-range\"\n                 data-nav-trapped>\n                <div class=\"col-xs-7\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.echo_cancel_delay",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":164,"column":44},"end":{"line":164,"column":78}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-1 no-padding\">\n                    <h2 class=\"input-label echo-cancel-delay\" id=\"echo-cancel-delay\"></h2>\n                </div>\n                <div class=\"col-xs-4 pull-right\">\n                    <div class=\"echo-cancel-delay-container\">\n                        <div class=\"echo-cancel-delay-range\" data-nav-area=\".focusable\"></div>\n                    </div>\n                </div>\n            </div>\n\n            <!-- USB Microfone -->\n            <div class=\"row settings-list-row\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.usb_mic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":179,"column":24},"end":{"line":179,"column":48}}}))
    + "</h2>\n                    <span class=\"preferred-device\"></span>\n                </div>\n                <div class=\"col-xs-6\"></div>\n            </div>\n\n            <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.usb_mic_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":187,"column":44},"end":{"line":187,"column":75}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"usb-mic\"\n                               id=\"usbMic\"\n                               type=\"checkbox\"\n                               class=\"btn-switch is-focusable\"\n                               tabIndex=\"-1\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.preferred_usb_mic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":205,"column":44},"end":{"line":205,"column":78}}}))
    + "</h2>\n                </div>\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input name=\"preferredUsbMic\"\n                               id=\"preferredUsbMic\"\n                               type=\"checkbox\"\n                               class=\"btn-switch is-focusable\"\n                               tabIndex=\"-1\"/>\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\"\n                 id=\"preferredDeviceList\"\n                 data-nav-area=\".is-focusable\"\n                 data-nav-trapped>\n                <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFullsize") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":225,"column":28},"end":{"line":225,"column":80}}})) != null ? stack1 : "")
    + "\">\n                    <div id=\"preferred-usb-mic-select\"></div>\n                </div>\n            </div>\n\n            <div class=\"usb-mic-container\">\n                <div class=\"row settings-list-secundary webconf-only\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                    <div class=\"col-xs-8\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.usb_mic_webconf_only",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":233,"column":48},"end":{"line":233,"column":85}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input name=\"usb-mic-webconf-output\"\n                                   id=\"usbMicWebconfOutput\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch is-focusable\"\n                                   tabIndex=\"-1\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"row settings-list-secundary local-output\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                    <div class=\"col-xs-8\">\n                        <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.usb_mic_local_output",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":251,"column":48},"end":{"line":251,"column":85}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input name=\"usb-mic-local-output\"\n                                   id=\"usbMicLocalOutput\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch is-focusable\"\n                                   tabIndex=\"-1\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"if_or")||(depth0 && lookupProperty(depth0,"if_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),(depth0 != null ? lookupProperty(depth0,"isCoreProLcs") : depth0),{"name":"if_or","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":267,"column":16},"end":{"line":291,"column":26}}})) != null ? stack1 : "")
    + "\n                <div class=\"row settings-list-secundary settings-list-info usb-mic-warning\"\n                     data-nav-area=\".is-focusable\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-12\">\n                        <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.usb_mic_warning",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":297,"column":27},"end":{"line":297,"column":59}}}))
    + "</p>\n                    </div>\n                </div>\n            </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "col-xs-12";
},"18":function(container,depth0,helpers,partials,data) {
    return "col-xs-6";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"row settings-list-secundary remote-output\" data-nav-area=\".is-focusable\" data-nav-trapped>\n                        <div class=\"col-xs-8\">\n                            <h2 class=\"input-label\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCoreProLcs") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":271,"column":32},"end":{"line":275,"column":39}}})) != null ? stack1 : "")
    + "                            </h2>\n                        </div>\n                        <div class=\"col-xs-4\">\n                            <div class=\"btn-switch-container pull-right\">\n                                <input name=\"usb-mic-remote-output\"\n                                       id=\"usbMicRemoteOutput\"\n                                       type=\"checkbox\"\n                                       class=\"btn-switch is-focusable\"\n                                       tabIndex=\"-1\"/>\n                                <div class=\"btn-switch-toggle-container\">\n                                    <div class=\"btn-switch-toggle\"></div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.usb_mic_remote_output_webcast",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":272,"column":36},"end":{"line":272,"column":82}}}))
    + "\n";
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.usb_mic_remote_output",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":274,"column":36},"end":{"line":274,"column":74}}}))
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"row settings-list-secundary\"\n                     id=\"preferredSpeakerWebconfSplit\"\n                     data-nav-area=\".is-focusable\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-8\">\n                        <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.usb_webconf_split",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":345,"column":48},"end":{"line":345,"column":82}}}))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <div class=\"btn-switch-container pull-right\">\n                            <input name=\"webconf-split\"\n                                   id=\"webconfSplit\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch is-focusable\"\n                                   tabIndex=\"-1\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"audio-settings\" name=\"audio-settings\">\n        <div class=\"row settings-list-row\">\n            <div class=\"col-xs-12\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.volume",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":43}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-6\"></div>\n        </div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"unless_or")||(depth0 && lookupProperty(depth0,"unless_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPureOrPro") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"unless_or","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":33,"column":22}}})) != null ? stack1 : "")
    + "\n        <div class=\"row settings-list-secundary\" data-nav-area=\".hdmi-out1-volume-range\" data-nav-trapped>\n            <div class=\"col-xs-8\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"hdmi1TranslationKey") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":40},"end":{"line":37,"column":65}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4 pull-right\">\n                <div class=\"hdmi-out1-volume\">\n                    <div class=\"hdmi-out1-volume-range\" data-nav-area=\".focusable\"></div>\n                </div>\n            </div>\n        </div>\n\n        <!-- HDMI2 out volume -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":8},"end":{"line":58,"column":15}}})) != null ? stack1 : "")
    + "\n        <!-- USB Microfone Volume -->\n"
    + ((stack1 = (lookupProperty(helpers,"unless_or")||(depth0 && lookupProperty(depth0,"unless_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPure") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"unless_or","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":8},"end":{"line":72,"column":22}}})) != null ? stack1 : "")
    + "\n        <div class=\"row settings-list-secundary\" data-nav-area=\".usb-speaker-volume-range\" data-nav-trapped>\n            <div class=\"col-xs-8\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.usb_speaker_volume",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":76,"column":40},"end":{"line":76,"column":75}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4 pull-right\">\n                <div class=\"usb-speaker-volume\">\n                    <div class=\"usb-speaker-volume-range\" data-nav-area=\".focusable\"></div>\n                </div>\n            </div>\n        </div>\n\n        <!-- Line IN -->\n"
    + ((stack1 = (lookupProperty(helpers,"unless_or")||(depth0 && lookupProperty(depth0,"unless_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPureOrPro") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"unless_or","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":8},"end":{"line":141,"column":22}}})) != null ? stack1 : "")
    + "\n        <!-- Echo Cancellation -->\n"
    + ((stack1 = (lookupProperty(helpers,"unless_or")||(depth0 && lookupProperty(depth0,"unless_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPure") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"unless_or","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":144,"column":8},"end":{"line":301,"column":22}}})) != null ? stack1 : "")
    + "\n        <div class=\"row settings-list-row\" data-nav-area=\".is-focusable\" data-nav-trapped>\n            <div class=\"col-xs-12\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.usb_speaker",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":305,"column":20},"end":{"line":305,"column":48}}}))
    + "</h2>\n                <span class=\"preferred-speaker-device\"></span>\n            </div>\n            <div class=\"col-xs-6\"></div>\n        </div>\n\n        <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n            <div class=\"col-xs-8\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.usb_speaker_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":313,"column":40},"end":{"line":313,"column":75}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"usb-speaker\"\n                           id=\"usbSpeaker\"\n                           type=\"checkbox\"\n                           class=\"btn-switch is-focusable\"\n                           tabIndex=\"-1\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"usb-speaker-settings\">\n            <div class=\"row settings-list-secundary\"\n                 id=\"preferredSpeakerDeviceList\"\n                 data-nav-area=\".is-focusable\"\n                 data-nav-trapped>\n                <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFullsize") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":334,"column":28},"end":{"line":334,"column":80}}})) != null ? stack1 : "")
    + "\">\n                    <div id=\"preferred-usb-speaker-select\"></div>\n                </div>\n            </div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"unless_or")||(depth0 && lookupProperty(depth0,"unless_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPure") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"unless_or","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":339,"column":12},"end":{"line":360,"column":26}}})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\"row settings-list-row\" data-nav-area=\".is-focusable, .btn-switch\" data-nav-trapped>\n            <div class=\"col-xs-8\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.control_center",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":365,"column":20},"end":{"line":365,"column":51}}}))
    + "</h2>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n            <div class=\"col-xs-8\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.audio_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":371,"column":40},"end":{"line":371,"column":69}}}))
    + "</h2>\n            </div>\n\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"audioEnabled\"\n                           id=\"audioEnabled\"\n                           type=\"checkbox\"\n                           class=\"btn-switch is-focusable\"\n                           tabIndex=\"-1\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"form-action-container\"></div>\n    </form>\n\n    <div class=\"row\" id=\"audio-level-settings\"></div>\n    <div class=\"audio-settings-test\" data-nav-area=\".is-focusable:not(:disabled)\" data-nav-trapped>\n        <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.test_audio",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":393,"column":12},"end":{"line":393,"column":39}}}))
    + "</h2>\n\n        <input id=\"audio-settings-test-btn\"\n               type=\"button\"\n               class=\"btn-form form-action-cancel is-focusable audio-test-action\"\n               value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.test_it_now",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":398,"column":22},"end":{"line":398,"column":50}}}))
    + "\">\n    </div>\n</div>\n";
},"useData":true});
