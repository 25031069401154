'use strict';

var $ = require('jquery');
var app = require('../../../app');
var selectAccountTpl = require('./select-account.hbs');
var FormActionView = require('../../settings/form-action-view');
var Platform = require('../../../../platform/platform');
var i18n = require('i18next');
var users = require('./../../../../rbac/users.json');

var states = {
    selectUser: 'select-user',
    signin: 'signin'
};

app.component('Login', {
    extend: 'LoginAbstract',
    template: selectAccountTpl,

    /**
     * @method initialize
     */
    initialize: function() {
        this.user = null;
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.authService = this.getService('AuthenticationService');
        this.dataStorage = this.getService('DataStorage');
        this.deviceService = this.getService('DeviceService');

        this.loaded = false;
        this.state = this.createStateMachine({
            state: states.selectUser,
            states: states
        });
        this.loginIsRunning = false;

        this.addStateTransitions();
    },

    serialize: function() {
        var dfd = $.Deferred();

        this.fetchData(dfd);

        return dfd.promise();
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.storeLoginSelectors();
        this.initForm();
        this.bindDOMEvents();
        this.bindEvents();
        this.initFormAction();
        this.changeClass('select-user');

        this.openRole();
    },

    /**
     * Fetch data for login form.
     *
     * @param {jQuery.Deferred} dfd
     */
    fetchData: function(dfd) {
        this.authService
            .getCollaborationActive()
            .then(function(data) {
                var switchUser = users.user.key;
                var switchBtn = this.options.configs.switchBtn !== undefined
                    ? this.options.configs.switchBtn : this.options.configs.user !== users.admin.key;

                if (data
                    && this.options.configs.user !== users.admin.key
                    && this.options.configs.user !== users.viewer.key) {
                    switchUser = users.collab.key;
                    this.options.configs.user = switchUser;
                } else if (this.options.configs.user === users.viewer.key) {
                    switchUser = users.viewer.key;
                    this.options.configs.user = switchUser;
                } else if (this.options.configs.user !== users.admin.key) {
                    this.options.configs.user = switchUser;
                }

                dfd.resolve({
                    switchBtn: switchBtn,
                    collaboration: (this.options.configs.user === users.collab.key),
                    isWarning: this.options.configs.isWarning,
                    isRemote: this.options.configs.isWarning && !Platform.checks.isCbox ? false : !Platform.checks.isCbox,
                    verifyMessage: this.options.configs.verifyMessage,
                    isLivaQ2: this.deviceService.isLivaQ2Hardware()
                });
            }.bind(this));

        /* After End-presentation sometimes the connections is to slow.
           call this function every 500ms, so long we have no connection. */
        setTimeout(function() {
            if (!this.dataLoaded) {
                this.fetchData(dfd);
            }
        }.bind(this), 500);
    },

    storeLoginSelectors: function() {
        this.$loginValidateContainerPwd = this.$el.find('#login-validate-container');

        this.$userName = this.$el.find('#login-username');
        this.$switchUserName = this.$el.find('#login-switch-username');
        this.$switchEl = this.$el.find('.login-switch-user');
        this.$nickname = this.$el.find('.nickname');

        this.$loginIcon = this.$el.find('.current-role');
        this.$loginSwitchIcon = this.$switchEl.find('.login-icon');

        this.$loginForm = this.$el.find('.login-form-content');
        this.$loginFormContent = this.$el.find('#login-form-content-inner');
        this.$loginFormInfo = this.$el.find('#login-form-info');
    },

    bindEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));

        /*
         * RELEASE-2049 - Window full screen: Keyboard appears for a few seconds when opening Settings user dialog.
         * Focus when modal is visible.
         */
        this.on('modal.opened-end', function() {
            this.remote.focus(this.form.get('password').$el.get(0));
            this.form.get('password').$el.click();
        }.bind(this));
    },

    /**
     * @method handleNicknameChange
     */
    handleFormChange: function() {
        if (this.user === 'collab') {
            this.checkActionForm();
        }
    },

    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    onSignInHandler: function() {
        this.authService.checkLdapLoginRequired().then(function() { // Update LDAP state
            if (this.user === 'user' && !Platform.checks.isCbox && this.authService.getIsLdapAuthenticated()) {
                this.toggleLdapActive(this.user);

                return;
            }

            var user = this.user;
            var password = this.form.get('password').getValue();
            var nick = this.form.get('nickname').getValue();
            var adminPin = this.form.get('adminPin').getValue();

            this.form.get('password').$el.blur();
            this.form.get('nickname').$el.blur();

            if (!this.loginIsRunning && this.form.validate()) {
                this.loginIsRunning = true;
                this.dataStorage.set('nickname', nick);

                // If type is pin change password to number.
                if (this.isPin) {
                    if (isNaN(parseInt(password))) {
                        this.loginIsRunning = false;
                        this.remote.unblock();
                        this.remote.focus(this.form.get('password').$el.get(0));
                        this.showError();

                        return;
                    }
                }

                this.authService.login(user, password, adminPin)
                    .then(function() {
                        this.emit('modal.close');
                        this.emit('rbac.user.changed', {
                            key: user
                        });

                        if (this.options.configs.loginHandler) {
                            this.options.configs.loginHandler(user);
                        }

                        this.authService.storeAccessToken().then(function() {
                            if (this.getService('ScreensaverService').screensaverState.getState() === 'screensaver') {
                                window.location.reload();
                            }
                        }.bind(this));
                        this.remote.unblock();
                    }.bind(this))
                    .fail(function() {
                        this.loginIsRunning = false;
                        this.remote.unblock();
                        this.remote.focus(this.form.get('password').$el.get(0));
                        this.showError();
                    }.bind(this));
            }
        }.bind(this));
    },

    /**
     * Clear form values (password, etc.)
     */
    clearForm: function() {
        if (this.form.get('password')) {
            this.form.get('password').setValue('');
        }

        if (this.form.get('adminPin')) {
            this.form.get('adminPin').setValue('');
        }

        if (this.form.get('nickname')) {
            this.form.get('nickname').setValue('');
        }
    },

    /**
     * Check if LDAP is already authenticated or not.
     * If authenticated locally, it's not possible to login again as moderator remotely.
     * Currently only one LDAP user is allowed.
     *
     * @param user Current selected user.
     */
    toggleLdapActive: function(user) {
        if (user === 'user' && !Platform.checks.isCbox && this.authService.getIsLdapAuthenticated()) {
            this.$loginFormContent.hide();
            this.$loginFormInfo.show();
            this.actionView.disableSubmitButton();
        } else {
            this.$loginFormContent.show();
            this.$loginFormInfo.hide();
            this.checkActionForm();
        }
    },

    /**
     * @method setLoginRole
     * @param {string} user
     */
    setLoginRole: function(user) {
        var switchUserName = users.admin.key;

        // Always clear nickname if form is invalid.
        if (!this.form.validate()) {
            this.$nickname.find('#nickname').val('');
            this.checkActionForm();
        }

        this.authService.getCollaborationActive().then(function(data) {
            if (user.toLowerCase() === users.admin.key) {
                switchUserName = this.switchUserRoleToAdmin(data);
            } else if (user.toLocaleLowerCase() === users.user.key) {
                switchUserName = this.switchUserRoleToUser();
            } else if (user.toLocaleLowerCase() === users.viewer.key) {
                switchUserName = this.switchUserRoleToViewer();
            } else {
                switchUserName = this.switchUserRoleToCollaboration();
            }

            if (!Platform.checks.isIOS) {
                this.$el.find('#password').focus();
                this.remote.focus(this.$el.find('#password').get(0));
            }

            // Set login user.
            this.user = user;
            switch (user) {
                case users.admin.key:
                    this.$userName.text(i18n.t('login.admin'));
                    break;
                case users.collab.key:
                    this.$userName.text(i18n.t('login.collab'));
                    break;
                case users.user.key:
                    this.$userName.text(i18n.t('login.guest'));
                    break;
                case users.viewer.key:
                    this.$userName.text(i18n.t('login.viewer'));
                    break;
            }

            if (user === users.user.key) {
                this.checkGuestPasswordType();
            } else if (user === users.collab.key || user === users.viewer.key) {
                this.changePasswordType({
                    passwordType: 'userPin'
                });
            } else {
                this.changePasswordType({
                    passwordType: 'password'
                });
            }

            this.$loginSwitchIcon.attr('data-user', user.toLowerCase());

            this.toggleForgotPassword(user);
            this.toggleLdapActive(user);

            // Set switch login user.
            this.$switchEl.data('user', switchUserName);
        }.bind(this));
    },

    /**
     * This method is called if the user wants to log in as collaborator.
     */
    switchUserRoleToCollaboration: function() {
        var switchUserName = users.user.key;

        this.$nickname.removeClass('hidden');

        this.$switchUserName
            .find('.icon-user')
            .removeClass('hidden');

        this.$switchUserName
            .find('.icon-annotate')
            .addClass('hidden');

        this.$switchUserName
            .find('.icon-settings-1')
            .addClass('hidden');

        this.$loginIcon.find('.icon-settings-1').addClass('hidden');
        this.$loginIcon.find('.icon-user').addClass('hidden');
        this.$loginIcon.find('.icon-annotate').removeClass('hidden');
        this.$loginIcon.find('.icon-iris_l').addClass('hidden');

        this.$loginForm.addClass('is-collab');
        this.$adminPinField.addClass('hidden');

        return switchUserName;
    },

    /**
     * This method is called if the user wants to log in as viewer.
     */
    switchUserRoleToViewer: function() {
        var switchUserName = users.user.key;

        this.$nickname.addClass('hidden');

        this.$switchUserName
            .find('.icon-settings-1')
            .removeClass('hidden');

        this.$switchUserName
            .find('.icon-user')
            .addClass('hidden');

        this.$switchUserName
            .find('.icon-annotate')
            .addClass('hidden');

        this.$loginIcon.find('.icon-settings-1').addClass('hidden');
        this.$loginIcon.find('.icon-user').addClass('hidden');
        this.$loginIcon.find('.icon-annotate').addClass('hidden');
        this.$loginIcon.find('.icon-iris_l').removeClass('hidden');

        this.$loginForm.removeClass('is-collab');
        this.$adminPinField.addClass('hidden');

        return switchUserName;
    },

    /**
     * This method is called if the user wants to log in as moderator.
     */
    switchUserRoleToUser: function() {
        var switchUserName = users.admin.key;

        this.$nickname.addClass('hidden');

        this.$switchUserName
            .find('.icon-settings-1')
            .removeClass('hidden');

        this.$switchUserName
            .find('.icon-user')
            .addClass('hidden');

        this.$switchUserName
            .find('.icon-annotate')
            .addClass('hidden');

        this.$loginIcon.find('.icon-settings-1').addClass('hidden');
        this.$loginIcon.find('.icon-user').removeClass('hidden');
        this.$loginIcon.find('.icon-annotate').addClass('hidden');
        this.$loginIcon.find('.icon-iris_l').addClass('hidden');

        this.$loginForm.removeClass('is-collab');
        this.$adminPinField.addClass('hidden');

        return switchUserName;
    },

    /**
     * This method is called if the user wants to log in as admin.
     */
    switchUserRoleToAdmin: function(data) {
        var switchUserName = users.collab.key;

        if (data) {
            this.$nickname.addClass('hidden');

            this.$switchUserName
                .find('.icon-annotate')
                .removeClass('hidden');

            this.$switchUserName
                .find('.icon-user')
                .addClass('hidden');
        } else {
            this.$switchUserName
                .find('.icon-annotate')
                .addClass('hidden');

            this.$switchUserName
                .find('.icon-user')
                .removeClass('hidden');

            switchUserName = users.user.key;
        }

        this.$switchUserName
            .find('.icon-settings-1')
            .addClass('hidden');

        this.$loginIcon.find('.icon-settings-1').removeClass('hidden');
        this.$loginIcon.find('.icon-user').addClass('hidden');
        this.$loginIcon.find('.icon-annotate').addClass('hidden');
        this.$loginIcon.find('.icon-iris_l').addClass('hidden');

        this.$loginForm.removeClass('is-collab');

        this.setShowAdminPin();

        return switchUserName;
    },

    /**
     * @method initForm
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#login-form'),
            validationContainer: '.input-group',
            submit: true
        });
    },

    /**
     * @method initFormAction
     */
    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                submitTitleKey: this.options.configs.isWarning ? 'settings.confirm' : 'action_button.login',
                cancelTitleKey: 'action_button.cancel',
                onSubmit: this.onSignInHandler.bind(this),
                onCancel: this.onLoginCancelHandler.bind(this),
                autoClose: false,
                navArea: false
            });
        }

        this.actionView.render();
    },

    changeClass: function(state) {
        this.$el.removeClass('is-' + this.state.getState());
        this.$el.addClass('is-' + state);
    },

    addStateTransitions: function() {
        this.state.addTransitions({
            '> select-user': function() {
                this.changeClass('select-user');
            },
            '> signin': function() {
                this.changeClass('signin');
                this.hideError();
            }
        });
    }
});
