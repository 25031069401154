'use strict';

const app = require('../../../app');
const office365SettingsTpl = require('./office365-settings.hbs');
const FormActionView = require('../form-action-view');

const connectionStates = {
    disconnected: 'disconnected',
    connected: 'connected',
    connecting: 'connecting'
};

app.component('Office365Settings', {
    template: office365SettingsTpl,
    actionView: null,

    initialize: function() {
        this.connectionState = this.createStateMachine({
            state: null,
            states: connectionStates
        });

        this.deviceService = this.getService('DeviceService');
        this.isDualProjection = this.deviceService.isCboxProDualProjection();
        this.formManager = this.getService('FormManager');
        this.formData = {};
    },

    serialize: function() {
        return {
            supportsRecording: this.deviceService.isCbox()
                || (this.deviceService.isCboxCorePro && this.deviceService.getFeatures().capture),
            isCbox: this.deviceService.isCbox(),
            isDualProjection: this.isDualProjection
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.createSelects();
        this.initForm();
        this.initFormAction();
        this.loadSettings()
            .then(function() {
                this.bindEvents();
                this.bindDOMEvents();
            }.bind(this));
    },

    bindEvents: function() {
        this.on('settings.save-changes', this.saveHandler.bind(this));
    },

    bindDOMEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
    },

    storeSelectors: function() {
        this.$office365SettingsContainer = this.$el.find('#office365-settings-container');
        this.$localOfficeAccountCredentials = this.$el.find('#localAccountCredentials');
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#office365-settings'),
            validationContainer: '.input-group'
        });
    },

    saveHandler: function() {
        if (this.form.validate()) {
            const officeEnabled = this.form.get('office365Enabled').getValue();
            const teamsSourceEnabled = this.form.get('teamsSourceEnabled').getValue();
            const teamsContentEnabled = this.isDualProjection ? this.formData.teamsContentEnabled
                : this.form.get('teamsContentEnabled').getValue();
            const office365LocalEnabled = this.form.get('officeLocalAccountEnable').getValue();
            const office365LocalUser = this.form.get('officeEmail').getValue();
            const office365LocalPwd = this.form.get('officePwd').getValue();

            this.deviceConnection
                .send([
                    {
                        command: 'setOffice365Function',
                        data: {
                            function: officeEnabled
                        }
                    },
                    {
                        command: 'setOffice365TeamsSettings',
                        data: {
                            sourceEnabled: teamsSourceEnabled,
                            enableContent: teamsContentEnabled
                        }
                    },
                    {
                        command: 'setOffice365LocalAccount',
                        data: {
                            enabled: office365LocalEnabled,
                            user: office365LocalUser,
                            pwd: office365LocalPwd
                        }
                    }
                ])
                .done(function() {
                    this.emit('save-changes.finished');

                    this.handleSettingsSave();
                    this.form.setDefaultValues();
                    this.emit('overlay.remote.focus', true);
                }.bind(this));
        }
    },

    /**
     * Calls on clicked the cancel button
     * @method cancelHandler
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
        this.updateLocalAccount();
        this.handleOffice365FunctionChange();
        this.changes = false;
    },

    /**
     * @method handleSettingsSave
     */
    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.changes = false;
    },

    /**
     * @method handleFormChange
     */
    handleFormChange: function(data) {
        if (data.input.name === 'office365Enabled') {
            this.handleOffice365FunctionChange();
        } else if (data.input.name === 'officeLocalAccountEnable') {
            this.updateLocalAccount();
        }

        this.actionView.open();
        this.checkActionForm();
        this.changes = true;
    },

    /**
     * @method handleOffice365FunctionChange
     */
    handleOffice365FunctionChange: function() {
        const enable = this.form.get('office365Enabled').getValue();

        if (enable) {
            this.showOffice365Settings();
        } else {
            this.hideOffice365Settings();
        }
    },

    /**
     * @method checkActionForm
     */
    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    loadSettings: function() {
        return this.deviceConnection
            .send([
                'getOffice365Function',
                'getOffice365TeamsSettings',
                'getOffice365LocalAccount'
            ])
            .then(function(func, office365Teams, office365Local) {
                this.formData = {
                    'office365Enabled': func.function,
                    'teamsSourceEnabled': office365Teams.sourceEnabled,
                    'teamsContentEnabled': office365Teams.enableContent,
                    'officeLocalAccountEnable': office365Local.enabled,
                    'officeEmail': office365Local.user,
                    'officePwd': office365Local.pwd
                };

                this.updateForm();
                this.form.setDefaultValues();
                this.handleOffice365FunctionChange();
                this.updateLocalAccount();
            }.bind(this));
    },

    /**
     * @method updateForm
     */
    updateForm: function() {
        this.form.setValues(this.formData);
        this.checkActionForm();
    },

    /**
     * @method hideOffice365Settings
     */
    hideOffice365Settings: function() {
        this.$office365SettingsContainer
            .stop()
            .slideUp(300);

        this.$office365SettingsContainer.addClass('hidden');
    },

    /**
     * @method showOffice365Settings
     */
    showOffice365Settings: function() {
        this.$office365SettingsContainer
            .stop()
            .slideDown(230);

        this.$office365SettingsContainer.removeClass('hidden');
    },

    /**
     * Checks if the local account container should be hidden or displayed.
     *
     * @param {Boolean} isInitialCall - Tells if the method is called in init.
     */
    updateLocalAccount: function() {
        const localAccountEnabled = this.form.get('officeLocalAccountEnable').getValue();

        if (localAccountEnabled) {
            this.$el.find('#officePwd').attr('data-allow-empty', 'false');
            this.$el.find('#officeEmail').attr('data-allow-empty', 'false');
            this.form.get('officeEmail').enable();
            this.form.get('officePwd').enable();
            this.$localOfficeAccountCredentials.show();
        } else {
            this.$el.find('#officePwd').attr('data-allow-empty', 'true');
            this.$el.find('#officeEmail').attr('data-allow-empty', 'true');
            this.form.get('officeEmail').disable();
            this.form.get('officePwd').disable();
            this.$localOfficeAccountCredentials.hide();
        }
    },

    /**
     * @method createSelects
     */
    createSelects: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#video-quality-select'),
            label: 'settings.video_quality',
            native: true,
            name: 'videoQuality',
            items: [
                {
                    text: 'settings.video_low',
                    value: 'low'
                },
                {
                    text: 'settings.video_medium',
                    value: 'medium'
                },
                {
                    text: 'settings.video_high',
                    value: 'high'
                }
            ]
        });
    },

    /**
     * @method destroy
     */
    destroy: function() {
        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    hasChanges: function() {
        const changes =  {
            hasChanges: this.changes,
            invalid: !this.form.validate(),
            waitOnSave: true
        };

        return changes;
    }
});
