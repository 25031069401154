/* jshint -W109 */

'use strict';

var _ = require('lodash');

// Add key here when input does not stop to add keys.
var mappings = {
    default: {
        226: '<',
        221: '`',
        190: '.',
        188: ',',
        187: '+'
    },
    us: {
        186: ';',
        189: '-',
        191: '/',
        192: '\'',
        219: '[',
        221: ']',
        222: '#'
    },
    de: {
        186: 'ö',
        192: 'ö',
        219: 'ü',
        220: '^',
        222: 'ä'
    },
    ru: {
        50: '"',
        52: ';',
        54: ':',
        56: '*',
        89: 'н',
        90: 'я',
        186: 'ж',
        187: '=',
        188: 'б',
        189: '-',
        190: 'ю',
        191: ',',
        192: 'ö',
        219: 'х',
        220: '\\',
        221: 'ъ',
        222: 'ä',
        223: 'ё',
        226: '\\'
    },
    fr: {
        186: '¤',
        219: ']'
    },
    no: {
        186: '^',
        189: '-',
        191: '*',
        192: 'ø',
        219: '`',
        221: 'å',
        222: 'æ',
        220: '|'
    }
};

/**
 * This pollyfill is for IE and Edge.
 * On some keyup events the key is unidentified, that always the same special keys.
 * On that case we will map the keyCode to the key.
 *
 * FIXME: @microsoft.
 * https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/742263/
 *
 * @param {jQuery.Event} event
 *
 * @return {jQuery.Event}
 */
module.exports = function pollyfillUnidentifiedKey(event, keyboardLayout) {
    if ('Unidentified' !== event.key) {
        return event;
    }

    var mapping = _.assign(mappings.default, mappings[keyboardLayout]);

    if (mapping[event.keyCode]) {
        event.key = mapping[event.keyCode];
    }

    return event;
};
