// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"matrix.share_with_all",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":52},"end":{"line":6,"column":81}}}));
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"matrix.share_with_all_stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":89},"end":{"line":6,"column":125}}}));
},"5":function(container,depth0,helpers,partials,data) {
    return "no-border";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"interaction-box-item has-btn "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"classed") : depth0), depth0))
    + "\">\n                <div class=\"interaction-box-item-inner fixed\">\n                    <span class=\"item-text group-name\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n                </div>\n                <div class=\"interaction-box-item-inner\">\n                    <div id=\"interaction-start-share\" class=\"interaction-box-item-inner clickable\" data-action=\"shareGroup\" data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n                        <span class=\"item-text\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"matrix.share",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":48},"end":{"line":19,"column":68}}}))
    + "</span>\n                    </div>\n                    <div class=\"separator-small\"></div>\n                    <div id=\"interaction-box-stop-share\" class=\"interaction-box-item-inner clickable\"  data-action=\"stopGroup\" data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n                        <span class=\"item-text\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"matrix.stop",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":48},"end":{"line":23,"column":67}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"matrix-interaction-container\">\n    <div class=\"interaction-triangle interaction-triangle-top\"></div>\n    <div class=\"interaction-items\">\n        <div id=\"interaction-box-start-share-all\" class=\"interaction-box-item clickable\" data-action=\"shareAll\">\n            <span class=\"icon icon-connect-with-all\"></span>\n            <span class=\"item-text\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMaster") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":36},"end":{"line":6,"column":132}}})) != null ? stack1 : "")
    + "</span>\n        </div>\n        <div id=\"interaction-box-stop-share-all\" class=\"interaction-box-item clickable "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"groups") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":87},"end":{"line":8,"column":125}}})) != null ? stack1 : "")
    + "\" data-action=\"stopAll\">\n            <span class=\"icon icon-disconnect-with-all\"></span>\n            <span class=\"item-text\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(alias1,"matrix.stop_sharing",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":36},"end":{"line":10,"column":63}}}))
    + "</span>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"groups") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":27,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"interaction-triangle interaction-triangle-bottom\"></div>\n</div>\n";
},"useData":true});
