module.exports={
    "none": {
        "roles": {
        }
    },
    "UserGroup": {
        "roles": {
        }
    },
    "AdminGroup": {
        "roles": {
            "About": {
                "show": true
            },
            "AudioSettings": {
                "show": true
            },
            "CloudLogin": {
                "show": true,
                "statusWidget": true
            },
            "CloudSettings": {
                "show": true
            },
            "CollaborationSettings": {
                "show": true
            },
            "ColorPicker": {
                "show": true
            },
            "DiscoveryBox": {
                "show": true
            },
            "Ethernet2Settings": {
                "show": true
            },
            "EthernetSettings": {
                "show": true
            },
            "EventList": {
                "show": true
            },
            "FactoryReset": {
                "show": true
            },
            "FileBox": {
                "show": true
            },
            "FirewallSettings": {
                "show": true
            },
            "FtpSettings": {
                "show": true
            },
            "FwUpdate": {
                "show": true,
                "update": true
            },
            "GeneralSettings": {
                "show": true
            },
            "HDCPSettings": {
                "show": true
            },
            "InputSettings": {
                "show": true
            },
            "LockIndicator": {
                "show": true
            },
            "login": {
                "show": true
            },
            "LoginSettings": {
                "show": true
            },
            "Matrix": {
                "show": true
            },
            "MatrixBox": {
                "show": true
            },
            "MatrixFlap": {
                "show": true
            },
            "MatrixGrid": {
                "show": true
            },
            "MatrixMain": {
                "show": true
            },
            "MatrixPreview": {
                "show": true
            },
            "MatrixSettings": {
                "show": true
            },
            "MirrorSettings": {
                "show": true
            },
            "NetdriveSettings": {
                "show": true
            },
            "Office365Settings": {
                "show": true
            },
            "OutputSettings": {
                "show": true
            },
            "PeripheralSettings": {
                "show": true
            },
            "ProxySettings": {
                "show": true
            },
            "PushIndicator": {
                "show": true
            },
            "RecordSettings": {
                "show": true
            },
            "RecordStreamSettings": {
                "show": true
            },
            "RoutingSettings": {
                "show": true
            },
            "SecuritySettings": {
                "show": true
            },
            "SetRegion": {
                "save": true
            },
            "Settings": {
                "show": true
            },
            "StreamingSettings": {
                "show": true
            },
            "SupportPin": {
                "show": true
            },
            "SystemReboot": {
                "show": true
            },
            "vMeeting": {
                "show": true,
                "admin": true
            },
            "vMeetingSettings": {
                "show": true
            },
            "VisualizerPreview": {
                "show": true
            },
            "VisualizerSettings": {
                "show": true
            },
            "VolumeSettings": {
                "show": true
            },
            "WebconferenceSettings": {
                "show": true
            },
            "WlanSettings": {
                "show": true
            },
            "ZoomSettings": {
                "show": true
            },
            "Troubleshoot": {
                "show": true
            },
            "RoomControllerSettings": {
                "show": true
            }
        }
    },
    "CollabGroup": {
        "roles": {
            "Annotation": {
                "show": true,
                "clearSnapshot": false,
                "close": false,
                "collab": true,
                "statusWidget": true
            },
            "AppRecording": {
                "show": false
            },
            "Apps": {
                "show": false
            },
            "Authentication": {
                "statusWidget": false
            },
            "Bookmarks": {
                "show": false
            },
            "CloseAllWindows": {
                "show": false
            },
            "CloudLogin": {
                "show": false,
                "statusWidget": false
            },
            "ColorPicker": {
                "show": true
            },
            "controlCenterWidgetRecord": {
                "show": false
            },
            "DiscoveryBox": {
                "show": false
            },
            "DownloadApps": {
                "show": false
            },
            "DualViewerMode": {
                "show": true
            },
            "EndPresentation": {
                "show": false
            },
            "EventList": {
                "show": false
            },
            "frameboxes": {
                "show": false
            },
            "FileBox": {
                "show": false
            },
            "FileBrowser": {
                "show": false
            },
            "FileBrowserV3": {
                "show": false
            },
            "Filelist": {
                "show": false
            },
            "Freeze": {
                "show": false,
                "statusWidget": true
            },
            "FwUpdate": {
                "show": false,
                "update": false
            },
            "Groupwork": {
                "show": false
            },
            "HelpPage": {
                "show": false
            },
            "Keyboard": {
                "show": false
            },
            "LockIndicator": {
                "show": false
            },
            "login": {
                "show": true
            },
            "MagicPen": {
                "show": true
            },
            "MainMenu": {
                "show": true
            },
            "Matrix": {
                "show": false
            },
            "MatrixBox": {
                "show": false
            },
            "MatrixFlap": {
                "show": false
            },
            "MatrixGrid": {
                "show": false
            },
            "MatrixMain": {
                "show": false
            },
            "MatrixPreview": {
                "show": false
            },
            "MatrixSettings": {
                "show": false
            },
            "MediaCast": {
                "show": false
            },
            "mediaListCloud": {
                "show": false
            },
            "mediaListLocal": {
                "show": false
            },
            "MeetingDetails": {
                "show": false
            },
            "Mirroring": {
                "show": false
            },
            "Office365": {
                "show": false
            },
            "OverrideMirror": {
                "show": false,
                "statusWidget": false
            },
            "Pip": {
                "show": false,
                "statusWidget": false
            },
            "PushIndicator": {
                "show": false
            },
            "QrCode": {
                "show": false
            },
            "Record": {
                "show": false,
                "statusWidget": false
            },
            "Settings": {
                "show": false
            },
            "Snapshot": {
                "show": false
            },
            "SourcesMenu": {
                "show": false
            },
            "SplashScreen": {
                "show": false
            },
            "StreamInput": {
                "show": false,
                "statusWidget": false
            },
            "Streaming": {
                "show": false,
                "statusWidget": false
            },
            "SupportPin": {
                "show": true
            },
            "UpDownloads": {
                "show": false,
                "statusWidget": false
            },
            "UserSettings": {
                "show": false,
                "load": false
            },
            "vMeeting": {
                "show": false,
                "admin": false
            },
            "VisualizerSettings": {
                "show": false
            },
            "Volume": {
                "show": false,
                "statusWidget": false
            },
            "VolumeSettings": {
                "show": false
            },
            "Webcasting": {
                "show": false,
                "statusWidget": false
            }
        }
    },
    "onlyStatusbar": {
        "roles": {
            "Annotation": {
                "show": false,
                "clearSnapshot": false,
                "close": false,
                "collab": false,
                "statusWidget": true
            },
            "AppRecording": {
                "show": false
            },
            "Backdrop": {
                "show": false
            },
            "Bookmarks": {
                "show": false
            },
            "CloseAllWindows": {
                "show": false
            },
            "CloudLogin": {
                "show": false,
                "statusWidget": true
            },
            "ColorPicker": {
                "show": false
            },
            "ControlCenter": {
                "show": false
            },
            "controlCenterWidgetRecord": {
                "show": false
            },
            "DiscoveryBox": {
                "show": false
            },
            "DownloadApps": {
                "show": false
            },
            "EndPresentation": {
                "show": false
            },
            "EventList": {
                "show": false
            },
            "FileBox": {
                "show": false
            },
            "FileBrowser": {
                "show": false
            },
            "FileBrowserV3": {
                "show": false
            },
            "Filelist": {
                "show": false
            },
            "Framebox": {
                "show": false
            },
            "Frameboxes": {
                "show": false
            },
            "Freeze": {
                "show": false,
                "statusWidget": true
            },
            "FwUpdate": {
                "show": false,
                "update": false
            },
            "Groupwork": {
                "show": false
            },
            "HelpPage": {
                "show": false
            },
            "Keyboard": {
                "show": false
            },
            "Livestream": {
                "load": false
            },
            "LockIndicator": {
                "show": false
            },
            "login": {
                "show": false
            },
            "MagicPen": {
                "show": false
            },
            "MainMenu": {
                "show": false
            },
            "Matrix": {
                "show": false
            },
            "MatrixBox": {
                "show": false
            },
            "MatrixFlap": {
                "show": false
            },
            "MatrixGrid": {
                "show": false
            },
            "MatrixMain": {
                "show": false
            },
            "MatrixPreview": {
                "show": false
            },
            "MatrixSettings": {
                "show": false
            },
            "MediaCast": {
                "show": false
            },
            "mediaListCloud": {
                "show": false
            },
            "mediaListLocal": {
                "show": false
            },
            "MeetingDetails": {
                "show": false
            },
            "Mirroring": {
                "show": false
            },
            "Modal": {
                "show": true
            },
            "OSDMessage": {
                "show": true
            },
            "Office365": {
                "show": false
            },
            "OverrideMirror": {
                "show": false,
                "statusWidget": true
            },
            "Pip": {
                "show": false,
                "statusWidget": true
            },
            "PushIndicator": {
                "show": false
            },
            "QrCode": {
                "show": true
            },
            "Record": {
                "show": false,
                "statusWidget": true
            },
            "Settings": {
                "show": false
            },
            "Snapshot": {
                "show": false
            },
            "SoftwarePackages": {
                "show": false
            },
            "SourcesMenu": {
                "show": false
            },
            "SplashScreen": {
                "show": false
            },
            "StreamInput": {
                "show": false,
                "statusWidget": true
            },
            "Streaming": {
                "show": false,
                "statusWidget": true
            },
            "SupportPin": {
                "show": false
            },
            "UpDownloads": {
                "show": false,
                "statusWidget": true
            },
            "UserSettings": {
                "show": false,
                "load": false
            },
            "vMeeting": {
                "show": false,
                "admin": false
            },
            "VisualizerPreview": {
                "show": false
            },
            "Volume": {
                "show": false,
                "statusWidget": true
            },
            "Webcasting": {
                "show": false,
                "statusWidget": true
            }
        }
    },

    "ControlScreenGroup": {
        "roles": {
            "ControlScreen": {
                "show": true
            },
            "Framebox": {
                "show": true
            },
            "OutputMirror": {
                "show": true,
                "actionWidget": true
            },
            "LanguageSelect": {
                "show": true,
                "statusWidget": false,
                "actionWidget": true
            },
            "RecordWidget": {
                "show": true,
                "actionWidget": true
            },
            "Annotation": {
                "show": false,
                "clearSnapshot": true,
                "close": true,
                "collab": false,
                "statusWidget": true
            }
        }
    }
}
