'use strict';

var app = require('../../../app');
var confirmTpl = require('./groupwork-v3.html');
const vueUtils = require('../../../components-vue/util.js');

app.component('GroupworkV3', {
    engine: 'vue',
    template: confirmTpl,

    className: 'groupwork-v3-modal-container',

    data: {
        action: 'manage'
    },

    created: function() {
        this.groupworkService = app.getService('GroupworkService');
        this.action = this.component.options.configs.action;

        this.evctx = vueUtils.eventContext();
        this.evctx.on('main-loop.update', function() {
            if (this.groupworkService.isGroupworkActive() === 'inactive') {
                app.emit('modal.close');
            }
        }.bind(this));
    },

    methods: {
        requestScreenShare: function() {
            this.action = 'request-screen-share';
        },

        showManageScreen: function() {
            this.action = 'manage';
        },

        close: function() {
            this.component.emit('modal.close');
        }
    },

    destroyed: function() {
        this.evctx.close();
    },

    components: {
        'groupwork-device-selection': require('../../../components-vue/groupwork-v3/groupwork-device-selection/groupwork-device-selection.js'),
        'groupwork-manage': require('../../../components-vue/groupwork-v3/groupwork-manage/groupwork-manage.js')
    }
});
