/* eslint-disable */

'use strict';

const app = require('./../../../app/app');
const wolfProtCodes = require('./wolfprot-codes');
const StringConvert = require('../../../string-convert');
const states = require('../../../app/states');
const helper = require('../../../app/helper');

const wolfProtParameters = wolfProtCodes.parameters;
const wolfProtHeaders = wolfProtCodes.headers;

const MASK = {
    bit0: 0x01,
    bit1: 0x02,
    bit2: 0x04,
    bit3: 0x08,
    bit4: 0x10,
    bit5: 0x20,
    bit6: 0x40,
    bit7: 0x80
};

/*
 * @return the header length of a valid wolfprot frame
 */
function wpCalcHeaderlen(buf) {
    var len = 3;

    if (buf[0] & wolfProtHeaders.WP_HEAD_EXT_HDR) {
        len++;
    }
    if (buf[0] & wolfProtHeaders.WP_HEAD_EXT_CMD) {
        len++;
    }
    if (!(buf[0] & wolfProtHeaders.WP_HEAD_ERR)) {
        if ((buf[0] & wolfProtHeaders.WP_HEAD_EXT_HDR) && (buf[1] & wolfProtHeaders.WP_EXTHEAD_EXT_LEN)) {
            len += 3;
        } else if (buf[0] & wolfProtHeaders.WP_HEAD_EXT_LEN) {
            len++;
        }
    }
    return len;
}

/*
 * @return the payload data length of a valid wolfprot frame
 */
function wpCalcDatalen(buf) {
    var len = 0;
    var headerLen = wpCalcHeaderlen(buf);

    if (buf[0] & wolfProtHeaders.WP_HEAD_ERR) {
        len = 0;
    } else if ((buf[0] & wolfProtHeaders.WP_HEAD_EXT_HDR) && (buf[1] & wolfProtHeaders.WP_EXTHEAD_EXT_LEN)) {
        len = ((buf[headerLen - 4]) << 24) | ((buf[headerLen - 3]) << 16) | ((buf[headerLen - 2]) << 8) | (buf[headerLen - 1]);
    } else if (buf[0] & wolfProtHeaders.WP_HEAD_EXT_LEN) {
        len = ((buf[headerLen - 2]) << 8) | (buf[headerLen - 1]);
    } else {
        len = buf[headerLen - 1];
    }
    return len;
}

/**
 * converts from an ip address stored as a 32bit int to a string in the format ('x.x.x.x')

 * @param ipAddressAsInt32: the ip address as a 32bit integer
 */
function ipv4AddressInt2String(ipAddressAsInt32){
    var ipString;

    ipString = ((ipAddressAsInt32 >> 24) & 0xFF) + '.' +
        ((ipAddressAsInt32 >> 16) & 0xFF) + '.' +
        ((ipAddressAsInt32 >> 8) & 0xFF) + '.' +
        ((ipAddressAsInt32) & 0xFF);

    return ipString;
}

/**
 *
 * parses a string from a byte array with the given length

 * @param array: the array with the value
 * @param startPosition: the array position where we should start to parse
 * @param length: the number of bytes we want to read from the array
 */
function parseStringFromArray(array, startPosition, length) {

    return StringConvert.utf8ToString(array.subarray(startPosition, startPosition + length));
}

/**
 * Parses a value from a byte array with the given length in big endian order

 * @param array: the array with the value
 * @param startPosition: the array position where we should start to parse
 * @param length: the number of bytes we want to read from the array
 */
function parseValueFromArrayBigEndian(array, startPosition, length) {
    var value = 0;
    var i;

    for (i = 0; i < length; i++) {
        value = value | (array[startPosition + i] << (8 * (length - i - 1)));
    }

    return value;
}

/**
 * Parses a value from a byte array in 64 bit integer.

 * @param array: the array with the value
 * @param startPosition: the array position where we should start to parse
 * @param length: the number of bytes we want to read from the array
 */
function parseValueFromArrayInt64(array, startPosition) {
    var low  = parseValueFromArrayBigEndian(array, startPosition+4, 4);
    var high  = parseValueFromArrayBigEndian(array, startPosition, 4);
    // shifting with << 32 does not work...
    return ((high * Math.pow(2, 32)) + low);
}


/**
 * parses a signed value from a 2-byte array in big endian order

 * @param array: the array with the value
 * @param startPosition: the array position where we should start to parse
 * @param length: the number of bytes we want to read from the array
 */
function parseSignedValueFromArray(array, startPosition) {
    var sign = array[startPosition] & (1 << 7);
    var value = (((array[startPosition] & 0xFF) << 8) | (array[startPosition + 1] & 0xFF));

    if (sign) {
        return (0xFFFF0000 | value);  // fill in most significant bits with 1's
    }

    return value;
}

function office (inFrame, position) {
    var returnValue = {
        options: {}
    };
    var pointer = position;
    var lengthFilename, lengthWorksheetTitle;

    returnValue.options.currentPage = parseValueFromArrayBigEndian(inFrame, pointer, 4);
    pointer = pointer + 4;

    returnValue.options.totalPages = parseValueFromArrayBigEndian(inFrame, pointer, 4);
    pointer = pointer + 4;

    lengthFilename = parseValueFromArrayBigEndian(inFrame, pointer, 2);
    pointer = pointer + 2;

    returnValue.options.filename = parseStringFromArray(inFrame, pointer, lengthFilename);
    pointer = pointer + lengthFilename;

    returnValue.options.worksheetPages = parseValueFromArrayBigEndian(inFrame, pointer, 4);
    pointer = pointer + 4;

    lengthWorksheetTitle = parseValueFromArrayBigEndian(inFrame, pointer, 2);

    pointer = pointer + 2;
    returnValue.options.worksheetTitle = parseStringFromArray(inFrame, pointer, lengthWorksheetTitle);

    returnValue.dataLength = lengthFilename + lengthWorksheetTitle + 16;

    return returnValue;
}

function mirroring(inFrame, position, contentTypePosition) {
    var pointer = position;
    var returnValue = {
        options: {}
    };

    var lengthName = parseValueFromArrayBigEndian(inFrame, pointer, 2);
    var isVSolutionCast = inFrame[contentTypePosition] === wolfProtParameters.WPP_FRAMEBOX_CONTENT_VSOLUTION;

    pointer = pointer + 2;
    returnValue.options.DeviceName = parseStringFromArray(inFrame, pointer, lengthName);
    returnValue.options.isVSolutionCast = isVSolutionCast;
    returnValue.dataLength = 2 + lengthName;
    pointer += lengthName;

    if (isVSolutionCast) {
        // vCast
        returnValue.options.cameraBackActive = inFrame[pointer] === 0x01;
        returnValue.dataLength++;
    } else {
        // Miracast
        returnValue.options.lowLatencyMode = inFrame[pointer] === 0x01 ? 'on' : 'off';
        returnValue.dataLength++;
    }

    return returnValue;
}

function hdmiIn (inFrame, position) {
    var returnValue = {
        options: {}
    };
    var pointer = position;
    var lengthTitle;

    pointer += 4;
    lengthTitle = parseValueFromArrayBigEndian(inFrame, pointer, 2);
    pointer += 2;

    returnValue.options.DeviceName = parseStringFromArray(inFrame, pointer, lengthTitle);

    return returnValue;
}

function browser(inFrame, position) {
    var returnValue = {
        options: {}
    };
    var pointer = position;
    var lengthUrl, lengthTitle;
    var inputTypeMapping = [];

    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_NOTEXT] = 'notext';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_TEXT] = 'text';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_TEXTAREA] = 'textarea';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_PASSWORD] = 'password';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_NUMBER] = 'number';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_URL] = 'url';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_DATE] = 'date';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_MONTH] = 'month';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_WEEK] = 'week';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_TIME] = 'time';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_DATETIME] = 'datetime';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_DATETIME_LOCAL] = 'datetime-local';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_EMAIL] = 'email';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_SEARCH] = 'search';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_TEL] = 'tel';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_UNKNOWN] = 'unknown';

    lengthUrl = parseValueFromArrayBigEndian(inFrame, pointer, 2);
    pointer = pointer + 2;

    returnValue.options.url = parseStringFromArray(inFrame, pointer, lengthUrl);
    pointer = pointer + lengthUrl;

    lengthTitle = parseValueFromArrayBigEndian(inFrame, pointer, 2);
    pointer = pointer + 2;

    returnValue.options.title = parseStringFromArray(inFrame, pointer, lengthTitle);
    pointer = pointer + lengthTitle;

    returnValue.options.inputType = inputTypeMapping[inFrame[pointer]];
    pointer++;

    returnValue.options.embeddedPdf = inFrame[pointer] > 0 ? true : false;
    pointer++;

    returnValue.options.pdfCurrentPage = parseValueFromArrayBigEndian(inFrame, pointer, 4);
    pointer = pointer + 4;

    returnValue.options.pdfTotalPages = parseValueFromArrayBigEndian(inFrame, pointer, 4);
    pointer = pointer + 4;

    returnValue.options.busy = inFrame[pointer] > 0? true : false;
    pointer++;

    returnValue.options.inputFieldBoundings = {};
    returnValue.options.inputFieldBoundings.top = parseValueFromArrayBigEndian(inFrame, pointer, 4) / 100000;
    pointer = pointer + 4;

    returnValue.options.inputFieldBoundings.bottom = parseValueFromArrayBigEndian(inFrame, pointer, 4) / 100000;
    pointer = pointer + 4;

    returnValue.options.inputFieldBoundings.left = parseValueFromArrayBigEndian(inFrame, pointer, 4) / 100000;
    pointer = pointer + 4;

    returnValue.options.inputFieldBoundings.right = parseValueFromArrayBigEndian(inFrame, pointer, 4) / 100000;
    returnValue.dataLength = lengthUrl + lengthTitle + 31;

    return returnValue;
}

function matrix(inFrame, position) {
    const uriService = app.getService('URIService');
    var pointer = position;
    var returnValue = {
        options: {}
    };

    var lengthName = parseValueFromArrayBigEndian(inFrame, pointer, 2);
    pointer = pointer + 2;
    returnValue.options.DeviceName = uriService.decodeFull(parseStringFromArray(inFrame, pointer, lengthName), true);
    returnValue.dataLength = 3 + lengthName;

    return returnValue;
}

function webconference(inFrame, position) {
    var returnValue = {
        options: {}
    };
    var pointer = position;
    var lengthUrl, lengthTitle;
    var inputTypeMapping = [];

    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_NOTEXT] = 'notext';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_TEXT] = 'text';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_TEXTAREA] = 'textarea';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_PASSWORD] = 'password';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_NUMBER] = 'number';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_URL] = 'url';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_DATE] = 'date';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_MONTH] = 'month';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_WEEK] = 'week';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_TIME] = 'time';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_DATETIME] = 'datetime';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_DATETIME_LOCAL] = 'datetime-local';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_EMAIL] = 'email';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_SEARCH] = 'search';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_TEL] = 'tel';
    inputTypeMapping[wolfProtParameters.WPP_BROWSER_INPUT_UNKNOWN] = 'unknown';

    lengthUrl = parseValueFromArrayBigEndian(inFrame, pointer, 2);
    pointer = pointer + 2;

    returnValue.options.url = parseStringFromArray(inFrame, pointer, lengthUrl);
    pointer = pointer + lengthUrl;

    lengthTitle = parseValueFromArrayBigEndian(inFrame, pointer, 2);
    pointer = pointer + 2;

    returnValue.options.title = parseStringFromArray(inFrame, pointer, lengthTitle);
    pointer = pointer + lengthTitle;

    returnValue.options.inputType = inputTypeMapping[inFrame[pointer]];
    pointer++;

    returnValue.options.embeddedPdf = inFrame[pointer] > 0 ? true : false;
    pointer++;

    returnValue.options.pdfCurrentPage = parseValueFromArrayBigEndian(inFrame, pointer, 4);
    pointer = pointer + 4;

    returnValue.options.pdfTotalPages = parseValueFromArrayBigEndian(inFrame, pointer, 4);
    pointer = pointer + 4;

    returnValue.options.busy = inFrame[pointer] > 0? true : false;
    pointer++;

    returnValue.options.inputFieldBoundings = {};
    returnValue.options.inputFieldBoundings.top = parseValueFromArrayBigEndian(inFrame, pointer, 4) / 100000;
    pointer = pointer + 4;

    returnValue.options.inputFieldBoundings.bottom = parseValueFromArrayBigEndian(inFrame, pointer, 4) / 100000;
    pointer = pointer + 4;

    returnValue.options.inputFieldBoundings.left = parseValueFromArrayBigEndian(inFrame, pointer, 4) / 100000;
    pointer = pointer + 4;

    returnValue.options.inputFieldBoundings.right = parseValueFromArrayBigEndian(inFrame, pointer, 4) / 100000;

    returnValue.dataLength = lengthUrl + lengthTitle + 31;

    return returnValue;
}

var frameBoxOptionsHandler = {
    'Browser': browser,
    'Office365': browser,
    'HdmiIn': hdmiIn,
    'Visualizer': hdmiIn,

    'Video': function(inFrame, position) {
        const uriService = app.getService('URIService');
        var pointer = position;
        var returnValue = {
            options: {}
        };
        var lengthTitle;

        returnValue.options.videoDuration = parseValueFromArrayBigEndian(inFrame, pointer, 4);
        pointer += 4;

        returnValue.options.videoPosition = parseValueFromArrayBigEndian(inFrame, pointer, 4);
        pointer += 4;

        returnValue.options.videoSpeed = (inFrame[pointer] >> 7) * -256 + inFrame[pointer];  //convert to signed
        pointer += 1;

        returnValue.options.videoLoop = inFrame[pointer];
        pointer += 1;

        lengthTitle = parseValueFromArrayBigEndian(inFrame, pointer, 2);
        pointer += 2;

        returnValue.options.title = uriService.decodeFull(parseStringFromArray(inFrame, pointer, lengthTitle), true);
        pointer += lengthTitle;

        returnValue.options.playbackState = helper.findKeyFromValue(states.playbackStates, inFrame[pointer]);

        returnValue.dataLength = 13 + lengthTitle;

        return returnValue;
    },

    'Image': function(inFrame, position) {
        const uriService = app.getService('URIService');
        var pointer = position;
        var returnValue = {
            options: {}
        };
        var lengthFileName;

        returnValue.options.imageNumber = parseValueFromArrayBigEndian(inFrame, pointer, 4);
        pointer += 4;

        returnValue.options.totalImages = parseValueFromArrayBigEndian(inFrame, pointer, 4);
        pointer += 4;

        lengthFileName = parseValueFromArrayBigEndian(inFrame, pointer, 2);
        pointer = pointer + 2;

        returnValue.options.imageName = uriService.decodeFull(parseStringFromArray(inFrame, pointer, lengthFileName), true);
        pointer = pointer + lengthFileName;

        returnValue.dataLength = 10 + lengthFileName;

        return returnValue;
    },

    'presentation': office,
    'text': office,
    'calc': office,

    'PDF': function (inFrame, position) {
        var pointer = position;
        var returnValue = {
            options: {}
        };

        returnValue.options.pdfCurrentPage = parseValueFromArrayBigEndian(inFrame, pointer, 4);
        pointer = pointer + 4;
        returnValue.options.pdfTotalPages = parseValueFromArrayBigEndian(inFrame, pointer, 4);
        pointer = pointer + 4;
        returnValue.options.zoomLevel = parseValueFromArrayBigEndian(inFrame, pointer, 4);
        returnValue.dataLength = 12;

        return returnValue;
    },

    'vSolution': mirroring,
    'Mirroring': mirroring,

    'Audio': function(inFrame, position) {
        const uriService = app.getService('URIService');
        var pointer = position;
        var returnValue = {
            options: {}
        };
        var lengthTitle;

        returnValue.options.audioDuration = parseValueFromArrayBigEndian(inFrame, pointer, 4);
        pointer += 4;

        returnValue.options.audioPosition = parseValueFromArrayBigEndian(inFrame, pointer, 4);
        pointer += 4;

        returnValue.options.audioSpeed = (inFrame[pointer] >> 7) * -256 + inFrame[pointer];  //convert to signed
        pointer += 1;

        returnValue.options.audioLoop = inFrame[pointer];
        pointer += 1;

        lengthTitle = parseValueFromArrayBigEndian(inFrame, pointer, 2);
        pointer += 2;

        returnValue.options.title = uriService.decodeFull(parseStringFromArray(inFrame, pointer, lengthTitle), true);
        pointer += lengthTitle;

        returnValue.options.playbackState = helper.findKeyFromValue(states.playbackStates, inFrame[pointer]);

        returnValue.dataLength = 13 + lengthTitle;

        return returnValue;
    },

    'teams': webconference,
    'webconference': webconference,
    'zoom': webconference,

    'streaminput': function(inFrame, position) {
        const uriService = app.getService('URIService');
        var pointer = position;
        var returnValue = {
            options: {}
        };
        var typeMapping = {};
        typeMapping[wolfProtParameters.WPP_CB_FRAMEBOX_INFO_STREAM_INPUT_TYPE_HDMI] = 'hdmi';
        typeMapping[wolfProtParameters.WPP_CB_FRAMEBOX_INFO_STREAM_INPUT_TYPE_VZ] = 'visualizer';
        typeMapping[wolfProtParameters.WPP_CB_FRAMEBOX_INFO_STREAM_INPUT_TYPE_DISC_PLAYER] = 'discplayer';
        typeMapping[wolfProtParameters.WPP_CB_FRAMEBOX_INFO_STREAM_INPUT_TYPE_COMPUTER] = 'computer';
        typeMapping[wolfProtParameters.WPP_CB_FRAMEBOX_INFO_STREAM_INPUT_TYPE_CAMERA] = 'camera';
        typeMapping[wolfProtParameters.WPP_CB_FRAMEBOX_INFO_STREAM_INPUT_TYPE_STREAM] = 'streaminput';
        typeMapping[wolfProtParameters.WPP_CB_FRAMEBOX_INFO_STREAM_INPUT_TYPE_CYNAP] = 'cynap';

        returnValue.options.type = typeMapping[inFrame[pointer]];
        pointer += 1;

        var lengthName = parseValueFromArrayBigEndian(inFrame, pointer, 2);
        pointer = pointer + 2;
        returnValue.options.DeviceName = uriService.decodeFull(parseStringFromArray(inFrame, pointer, lengthName), true);
        returnValue.dataLength = 3 + lengthName;

        return returnValue;
    },

    'matrix': matrix,
    'matrixGroupwork': matrix,
    'matrixControl': browser,

    'webcam': function(inFrame, position) {
        const uriService = app.getService('URIService');
        var pointer = position;
        var returnValue = {
            options: {}
        };
        var typeMapping = {};
        typeMapping[wolfProtParameters.WPP_CB_FRAMEBOX_INFO_UVC_TYPE_HDMI] = 'hdmi';
        typeMapping[wolfProtParameters.WPP_CB_FRAMEBOX_INFO_UVC_TYPE_VZ] = 'visualizer';
        typeMapping[wolfProtParameters.WPP_CB_FRAMEBOX_INFO_UVC_TYPE_DISC_PLAYER] = 'discplayer';
        typeMapping[wolfProtParameters.WPP_CB_FRAMEBOX_INFO_UVC_TYPE_COMPUTER] = 'computer';
        typeMapping[wolfProtParameters.WPP_CB_FRAMEBOX_INFO_UVC_TYPE_CAMERA] = 'camera';

        returnValue.options.type = typeMapping[inFrame[pointer]];
        pointer += 1;

        var lengthName = parseValueFromArrayBigEndian(inFrame, pointer, 2);
        pointer = pointer + 2;
        returnValue.options.DeviceName = uriService.decodeFull(parseStringFromArray(inFrame, pointer, lengthName), true);
        returnValue.dataLength = 3 + lengthName;

        return returnValue;
    },
    'whiteboard': browser,
};

/**
 * @method handleFrameBoxOptions
 * @param {String} frameBoxType
 * @param {Array} inFrame
 */
function handleFrameBoxOptions(frameBoxType, inFrame, position, contentTypePosition) {
    var options;
    var handler = frameBoxOptionsHandler[frameBoxType];

    if (handler) {
        options = handler(inFrame, position, contentTypePosition);
    }

    return options || { dataLength: 0 };
}

function getContentMapping() {
    const contentMapping = {};
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_NONE] = null;
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_VZ] = 'Visualizer';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_HDMI] = 'HdmiIn';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_BROWSER] = 'Browser';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_MIRACAST] = 'vSolution';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_AIRPLAY] = 'Mirroring';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_VIDEO] = 'Video';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_VSOLUTION] = 'vSolution';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_IMAGE] = 'Image';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_PDF] = 'PDF';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_PRESENTATION] = 'presentation';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_TEXT] = 'text';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_CALC] = 'calc';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_WHITEBOARD] = 'whiteboard';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_AUDIO] = 'Audio';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_WEBCONFERENCE] = 'webconference';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_STREAM_INPUT] = 'streaminput';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_WEBCAM] = 'webcam';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_OFFICE365_OUTLOOK] = 'Office365';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_OFFICE365_WORD] = 'Office365';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_OFFICE365_EXCEL] = 'Office365';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_OFFICE365_POWERPOINT] = 'Office365';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_OFFICE365_ONENOTE] = 'Office365';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_OFFICE365_TEAMS] = 'teams';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_OFFICE365_WHITEBOARD] = 'Office365';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_OFFICE365_PLANNER] = 'Office365';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_OTHERS] = 'otherContent';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_MATRIX] = 'matrix';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_MATRIX_CONTROL_SWITCH] = 'matrixControl';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_MATRIX_GROUPWORK] = 'matrixGroupwork';
    contentMapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_ZOOM] = 'zoom';

    return contentMapping;
}

function getOffice365Mapping() {
    const office365Mapping = {};
    office365Mapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_OFFICE365_OUTLOOK] = 'msoutlook';
    office365Mapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_OFFICE365_WORD] = 'msword';
    office365Mapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_OFFICE365_EXCEL] = 'msexcel';
    office365Mapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_OFFICE365_POWERPOINT] = 'mspowerpoint';
    office365Mapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_OFFICE365_ONENOTE] = 'msonenote';
    office365Mapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_OFFICE365_TEAMS] = 'msteams';
    office365Mapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_OFFICE365_WHITEBOARD] = 'mswhiteboard';
    office365Mapping[wolfProtParameters.WPP_FRAMEBOX_CONTENT_OFFICE365_PLANNER] = 'msplanner';

    return office365Mapping;
}

function getContentTypeMapping() {
    const contentTypeMapping = {};
    contentTypeMapping[wolfProtParameters.WPP_CONTENT_TYPE_WINDOW] = 'window';
    contentTypeMapping[wolfProtParameters.WPP_CONTENT_TYPE_OUTPUT] = 'output';

    return contentTypeMapping;
}

function getOutputMapping() {
    const outputMapping = {};
    outputMapping[wolfProtParameters.WPP_OUTPUT_HDMI1] = 'hdmi1';
    outputMapping[wolfProtParameters.WPP_OUTPUT_HDMI2] = 'hdmi2';
    outputMapping[wolfProtParameters.WPP_OUTPUT_PREPARE] = 'prepare';
    outputMapping[wolfProtParameters.WPP_OUTPUT_EDIT] = 'edit';
    outputMapping[wolfProtParameters.WPP_OUTPUT_WEBRTC] = 'webrtc';

    return outputMapping;
}

function getSymbolMapping() {
    const symbolMapping = {};
    symbolMapping[wolfProtParameters.WPP_SYMBOL_HDMI] = 'hdmi';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_VISUALIZER] = 'visualizer';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_DISCPLAYER] = 'discPlayer';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_COMPUTER] = 'computer';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_CAMERA] = 'camera';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_CYNAP] = 'cynap';

    symbolMapping[wolfProtParameters.WPP_SYMBOL_IMAGE] = 'image';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_VIDEO] = 'video';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_NUMBERS] = 'calc';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_PRESENTATION] = 'presentation';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_AUDIO] = 'audio';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_TEXT] = 'text';

    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_TXT] = 'txt';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_AI] = 'ai';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_CSS] = 'css';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_DOC] = 'doc';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_EXE] = 'exe';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_GIF] = 'gif';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_HTML] = 'html';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_JPG] = 'jpg';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_MOV] = 'mov';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_MP3] = 'mp3';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_MP4] = 'mp4';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_PDF] = 'pdf';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_PNG] = 'png';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_PPT] = 'ppt';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_PSD] = 'psd';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_RAR] = 'rar';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_SVG] = 'svg';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_XLS] = 'xls';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_ZIP] = 'zip';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_AVI] = 'avi';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_BMP] = 'bmp';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_DOCX] = 'docx';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_M4V] = 'm4v';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_MKV] = 'mkv';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_PPTX] = 'pptx';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_WMA] = 'wma';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_WMV] = 'wmv';
    symbolMapping[wolfProtParameters.WPP_SYMBOL_FORMAT_XLSX] = 'xlsx';

    symbolMapping[wolfProtParameters.WPP_SYMBOL_NONE] = null;

    return symbolMapping;
}

/**
 * Parse output block of WPC_CB_CONTENT_PER_OUTPUT
 *
 * @param inFrame
 * @param pos
 * @returns {*}
 */
function parseOutputBlock(inFrame, pos) {
    const data = {};
    const outputMapping = getOutputMapping();

    data.outputPort = outputMapping[inFrame[pos]];
    pos += 1;

    data.reference = {
        width: (inFrame[pos] << 8) | inFrame[pos+1],
        height: (inFrame[pos+2] << 8) | inFrame[pos+3]
    };
    pos += 4;

    data.coordinates = {
        x: ((inFrame[pos] << 8) | inFrame[pos+1]),
        y: ((inFrame[pos+2] << 8) | inFrame[pos+3]),
        width: ((inFrame[pos+4] << 8) | inFrame[pos+5]),
        height: ((inFrame[pos+6] << 8) | inFrame[pos+7])
    };
    pos += 8;

    return {
        data: data,
        pos: pos
    };
}

/**
 * Parse window block of WPC_CB_CONTENT_PER_OUTPUT
 *
 * @param inFrame
 * @param pos
 * @returns {*}
 */
function parseWindowBlock(inFrame, pos) {
    const data = {};
    const contentMapping = getContentMapping();
    const outputMapping = getOutputMapping();
    const authService = app.getService('AuthenticationService');
    let contentTypePosition = pos;

    data.contentType = contentMapping[inFrame[pos]] || 'otherContent';
    pos += 1;

    data.appId = helper.toHexString(inFrame.slice(pos, pos+8));
    pos += 8;

    data.index = inFrame[pos];
    pos += 1;

    data.outputPort = outputMapping[((inFrame[pos] << 8) | inFrame[pos+1])];
    pos += 2;

    data.reference = {
        width: (inFrame[pos] << 8) | inFrame[pos+1],
        height: (inFrame[pos+2] << 8) | inFrame[pos+3]
    };
    pos += 4;

    data.coordinates = {
        x: ((inFrame[pos] << 8) | inFrame[pos+1]),
        y: ((inFrame[pos+2] << 8) | inFrame[pos+3]),
        width: ((inFrame[pos+4] << 8) | inFrame[pos+5]),
        height: ((inFrame[pos+6] << 8) | inFrame[pos+7])
    };
    pos += 8;

    data.mute = inFrame[pos] === 0x01 ? 'on' : 'off';
    pos += 1;

    data.volume = inFrame[pos];
    pos += 1;

    data.transparent = inFrame[pos] === 0x01;
    pos += 1;

    data.webRtcOut = inFrame[pos];
    pos += 1;

    data.fullscreen = inFrame[pos] === 0x01;
    pos += 1;

    data.restricted = inFrame[pos];
    pos += 1;

    data.aux = inFrame[pos];
    pos += 1;

    let typeSpecificBlockLength = ((inFrame[pos] << 8) | inFrame[pos+1]);
    pos += 2;

    if (typeSpecificBlockLength > 0) {
        data.options = handleFrameBoxOptions(data.contentType, inFrame, pos, contentTypePosition).options;
        pos += typeSpecificBlockLength;
    }

    data.wipeOut = ['vSolution', 'Mirroring'].includes(data.contentType) && authService.cat
        && (data.coordinates.width > 0 || data.fullscreen);

    if (data.restricted) {
        data.contentType = 'otherContent';
    }

    return {
        data: data,
        pos: pos
    };
}

/**
 * Collection of all server commands
 */
module.exports = {

    visualizerSettingsResponse: function visualizerSettingsResponse(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var length;

        receivedData.cmd = inFrame[1];
        length = inFrame[2];
        receivedData.parameter = parseValueFromArrayBigEndian(inFrame, 3, length);

        invokeCallbackOf(command, receivedData);
    },

    DUMMY_SET_CMD: function DUMMY_SET_CMD(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the picture reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_GET_PICTURE: function WPC_CB_GET_PICTURE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.pictureWidth = (inFrame[8] << 8) | inFrame[9];
        receivedData.pictureHeight = (inFrame[10] << 8) | inFrame[11];
        receivedData.pictureLength = (inFrame[12] << 24) | (inFrame[13] << 16) | (inFrame[14] << 8) | (inFrame[15]);
        receivedData.picture = inFrame.subarray(16);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get boxname reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_BOXNAME: function WPC_CB_BOXNAME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.boxName = parseStringFromArray(inFrame, 4, inFrame[3]);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get dhcp setting reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ETH_DHCP_SETTING: function WPC_CB_ETH_DHCP_SETTING(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.dhcpSetting = inFrame[4];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get ip address ethernet reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ETH_IP_ADDRESS: function WPC_CB_ETH_IP_ADDRESS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.ipAddress = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get subnet mask ethernet reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ETH_SUBNET_MASK: function WPC_CB_ETH_SUBNET_MASK(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.subnetMask = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get gateway address ethernet reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ETH_GATEWAY_ADDRESS: function WPC_CB_ETH_GATEWAY_ADDRESS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.gatewayAddress = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get dns address ethernet reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ETH_DNS_ADDRESS: function WPC_CB_ETH_DNS_ADDRESS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.dnsAddress = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get dns2 address ethernet reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ETH_DNS2_ADDRESS: function WPC_CB_ETH_DNS2_ADDRESS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.dnsAddress = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get macaddress ethernet reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ETH_GET_MAC_ADDRESS: function WPC_CB_ETH_GET_MAC_ADDRESS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.macAddress = parseStringFromArray(inFrame,4,inFrame[3]);
        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get dhcpState ethernet reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ETH_GET_DHCP_STATE: function WPC_CB_ETH_GET_DHCP_STATE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.dhcpState = inFrame[4];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get wifi mode reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_MODE: function WPC_CB_WLAN_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {
            0: 'off',
            1: 'infrastructure',
            2: 'access-point'
        };

        receivedData.wlanMode = mapping[inFrame[4]];
        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get wifi channel reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_CHANNEL: function WPC_CB_WLAN_CHANNEL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = [];
        mapping[wolfProtParameters.WPP_WLAN_CHANNEL_1] = 1;
        mapping[wolfProtParameters.WPP_WLAN_CHANNEL_6] = 6;
        mapping[wolfProtParameters.WPP_WLAN_CHANNEL_11] = 11;
        mapping[wolfProtParameters.WPP_WLAN_CHANNEL_36] = 36;
        mapping[wolfProtParameters.WPP_WLAN_CHANNEL_40] = 40;
        mapping[wolfProtParameters.WPP_WLAN_CHANNEL_44] = 44;
        mapping[wolfProtParameters.WPP_WLAN_CHANNEL_48] = 48;
        mapping[wolfProtParameters.WPP_WLAN_CHANNEL_149] = 149;
        mapping[wolfProtParameters.WPP_WLAN_CHANNEL_153] = 153;
        mapping[wolfProtParameters.WPP_WLAN_CHANNEL_157] = 157;
        mapping[wolfProtParameters.WPP_WLAN_CHANNEL_161] = 161;

        receivedData.wlanChannel = mapping[inFrame[4]];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get wifi ssid auto setting reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_SSID_AUTO: function WPC_CB_WLAN_SSID_AUTO(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.ssidAuto = inFrame[4];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get wifi ssid name reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_INFRA_SSID_NAME: function WPC_CB_WLAN_INFRA_SSID_NAME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.ssidName = parseStringFromArray(inFrame,4,inFrame[3]);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get access point wifi ssid name reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_AP_SSID_NAME: function WPC_CB_WLAN_AP_SSID_NAME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.ssidName = parseStringFromArray(inFrame,4,inFrame[3]);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wifi dhcp setting reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_DHCP_SETTING: function WPC_CB_WLAN_DHCP_SETTING(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.dhcpSetting = inFrame[4];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wifi ip address reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_IP_ADDRESS: function WPC_CB_WLAN_IP_ADDRESS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.ipAddress = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wifi subnet mask reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_SUBNET_MASK: function WPC_CB_WLAN_SUBNET_MASK(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.subnetMask = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get access point wifi ip address reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_AP_IP_ADDRESS: function WPC_CB_WLAN_AP_IP_ADDRESS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.ipAddress = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get access point wifi subnet mask reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_AP_SUBNET_MASK: function WPC_CB_WLAN_AP_SUBNET_MASK(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.subnetMask = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wifi gateway address reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_GATEWAY_ADDRESS: function WPC_CB_WLAN_GATEWAY_ADDRESS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.gatewayAddress = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wifi dns address reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_DNS_ADDRESS: function WPC_CB_WLAN_DNS_ADDRESS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.dnsAddress = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wifi dns2 address reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_DNS2_ADDRESS: function WPC_CB_WLAN_DNS2_ADDRESS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.dnsAddress = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wifi mac address reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_GET_MAC_ADDRESS: function WPC_CB_WLAN_GET_MAC_ADDRESS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.macAddress = parseStringFromArray(inFrame,4,inFrame[3]);
        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get wifi dhcp state reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_GET_DHCP_STATE: function WPC_CB_WLAN_GET_DHCP_STATE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.dhcpState = inFrame[4];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wifi key infrastructure reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_KEY_INFRASTRUCTURE: function WPC_CB_WLAN_KEY_INFRASTRUCTURE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.passwordInfrastructure = parseStringFromArray(inFrame,4,inFrame[3]);
        invokeCallbackOf(command ,receivedData);
    },

    /**
     * parses the get wifi encryption standard reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_ENCRYPTION_STANDARD: function WPC_CB_WLAN_ENCRYPTION_STANDARD(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};
        mapping[wolfProtParameters.WPP_WLAN_ENCRYPTION_NONE] = 'none';
        mapping[wolfProtParameters.WPP_WLAN_ENCRYPTION_WEP] = 'wep';
        mapping[wolfProtParameters.WPP_WLAN_ENCRYPTION_WPA] = 'wpa';
        mapping[wolfProtParameters.WPP_WLAN_ENCRYPTION_WPA_ENTERPRISE] = 'wpa-enterprise';

        receivedData.encryptionStandard = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get access point wifi encryption standard reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_AP_ENCRYPTION_STANDARD: function WPC_CB_WLAN_AP_ENCRYPTION_STANDARD(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};
        mapping[wolfProtParameters.WPP_WLAN_ENCRYPTION_NONE] = 'none';
        mapping[wolfProtParameters.WPP_WLAN_ENCRYPTION_WPA] = 'wpa';

        receivedData.encryptionStandard = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wifi key accesspoint reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_KEY_ACCESSPOINT: function WPC_CB_WLAN_KEY_ACCESSPOINT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.keyAccesspoint = parseStringFromArray(inFrame,4, inFrame[3]);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wifi accesspoint list reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_AP_LIST: function WPC_CB_WLAN_AP_LIST(inFrame, connection, command, invokeCallbackOf) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = parseValueFromArrayBigEndian(inFrame, 3, 2);
        var jsonString = parseStringFromArray(inFrame, 5, length);

        receivedData.apList = JSONService.parse(jsonString);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wlan ssid type (ESSID or BSSID) and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_SSID_TYPE: function WPC_CB_WLAN_SSID_TYPE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_WLAN_SSID_TYPE_ESSID] = 'essid';
        mapping[wolfProtParameters.WPP_WLAN_SSID_TYPE_BSSID] = 'bssid';

        receivedData.type = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wlan bssid and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_BSSID: function WPC_CB_WLAN_BSSID(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.bssid = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wlan frequency band (both, 2.4GHz, 5GHz) and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_FREQUENCY_BAND: function WPC_CB_WLAN_FREQUENCY_BAND(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_WLAN_FREQUENCY_BAND_BOTH] = 'both';
        mapping[wolfProtParameters.WPP_WLAN_FREQUENCY_BAND_24] = '24ghz';
        mapping[wolfProtParameters.WPP_WLAN_FREQUENCY_BAND_5] = '5ghz';

        receivedData.band = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get ap list status reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_AP_LIST_STATUS: function WPC_CB_WLAN_AP_LIST_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};
        mapping[wolfProtParameters.WPP_WLAN_AP_SCAN_STATUS_SEARCHING] = 'searching';
        mapping[wolfProtParameters.WPP_WLAN_AP_SCAN_STATUS_FAILED] = 'failed';
        mapping[wolfProtParameters.WPP_WLAN_AP_SCAN_STATUS_SUCCESS] = 'success';

        receivedData.apListStatus = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wlan certificate mode reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_CERTIFICATE_MODE: function WPC_CB_WLAN_CERTIFICATE_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.mode = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wifi certificate file name reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_CERTICICATE_FILE_NAME: function WPC_CB_WLAN_CERTICICATE_FILE_NAME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.certificateFileName = parseStringFromArray(inFrame,4, inFrame[3]);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get time source reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_TIME_SOURCE: function WPC_CB_TIME_SOURCE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.timeSource = (inFrame[4] === wolfProtParameters.WPP_TIME_SOURCE_EXTERNAL ? true : false);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get timeserver url reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_TIMESERVER_URL: function WPC_CB_TIMESERVER_URL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.url = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the timeserver status
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_TIMESERVER_TEST_STATUS: function WPC_CB_TIMESERVER_TEST_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        var mapping = {};
        mapping[wolfProtParameters.WPP_CB_TIMESERVER_STATUS_TESTING] = 'testing';
        mapping[wolfProtParameters.WPP_CB_TIMESERVER_STATUS_FAILED] = 'failed';
        mapping[wolfProtParameters.WPP_CB_TIMESERVER_STATUS_SUCCESS] = 'success';

        receivedData.status = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get date format reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_DATE_FORMAT: function WPC_CB_DATE_FORMAT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {
            '0': 'DDMMYYYY/24H',
            '1': 'MMDDYYYY/12H'
        };

        receivedData.dateFormat = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get date time reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_DATE_TIME: function WPC_CB_DATE_TIME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.year = inFrame[4] + 2000;
        receivedData.month = inFrame[5];
        receivedData.day = inFrame[6];
        receivedData.hour = inFrame[7];
        receivedData.minute = inFrame[8];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get streaming ip address reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_STREAMING_IP_ADDRESS: function WPC_CB_STREAMING_IP_ADDRESS(inFrame, connection, command, invokeCallbackOf) {
        const receivedData = {};
        const length = inFrame[3];

        if (length === 8) {
            receivedData.ipAddressHdmi1 = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
            receivedData.ipAddressHdmi2 = ipv4AddressInt2String((inFrame[8] << 24) | (inFrame[9] << 16) | (inFrame[10] << 8) | (inFrame[11]));
        } else {
            receivedData.ipAddress = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        }


        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get streaming port reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_STREAMING_PORT: function WPC_CB_STREAMING_PORT(inFrame, connection, command, invokeCallbackOf) {
        const receivedData = {};
        const length = inFrame[3];

        if (length === 4) {
            receivedData.portHdmi1 = '' + ((inFrame[4] << 8) | inFrame[5]);
            receivedData.portHdmi2 = '' + ((inFrame[6] << 8) | inFrame[7]);
        } else {
            receivedData.port = '' + ((inFrame[4] << 8) | inFrame[5]);
        }


        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get streaming mode reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_STREAMING_MODE: function WPC_CB_STREAMING_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.mode = inFrame[4];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get streaming resolution reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_STREAMING_RESOLUTION: function WPC_CB_STREAMING_RESOLUTION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_RESOLUTION_360P] = '360p';
        mapping[wolfProtParameters.WPP_CB_RESOLUTION_540P] = '540p';
        mapping[wolfProtParameters.WPP_CB_RESOLUTION_720P] = '720p';
        mapping[wolfProtParameters.WPP_CB_RESOLUTION_1080P] = '1080p';

        receivedData.resolution = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get streaming framerate reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_STREAMING_FRAMERATE: function WPC_CB_STREAMING_FRAMERATE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_FRAMERATE_LOW] = 'low';
        mapping[wolfProtParameters.WPP_FRAMERATE_MEDIUM] = 'medium';
        mapping[wolfProtParameters.WPP_FRAMERATE_HIGH] = 'high';

        receivedData.framerate = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get streaming bitrate reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_STREAMING_BITRATE: function WPC_CB_STREAMING_BITRATE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var modeMapping = {};

        modeMapping[wolfProtParameters.WPP_STREAMING_BITRATE_MODE_CONSTANT] = 'constant';
        modeMapping[wolfProtParameters.WPP_STREAMING_BITRATE_MODE_VARIABLE] = 'variable';

        receivedData.mode = modeMapping[inFrame[4]];
        receivedData.bitrate = ((inFrame[5] << 8) | inFrame[6]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get streaming function reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_STREAMING_FUNCTION: function WPC_CB_STREAMING_FUNCTION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.function = inFrame[4] === 0x00 ? false : true;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get recording mode reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_RECORDING_STATE: function WPC_CB_RECORDING_STATE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_START] = 'record';
        mapping[wolfProtParameters.WPP_PAUSE] = 'pause';
        mapping[wolfProtParameters.WPP_STOP] = 'stop';

        receivedData.recordingMode = mapping[inFrame[4]];
        receivedData.recordingTime = parseValueFromArrayBigEndian(inFrame, 5, 4);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get recording function reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_RECORDING_FUNCTION: function WPC_CB_RECORDING_FUNCTION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === 0x00 ? false : true;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get recording rename reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_RECORDING_RENAME: function WPC_CB_RECORDING_RENAME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === 0x00 ? false : true;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get recording framerate reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_RECORDING_FRAMERATE: function WPC_CB_RECORDING_FRAMERATE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};
        mapping[wolfProtParameters.WPP_CB_RECORDING_FRAMERATE_LOW] = 'low';
        mapping[wolfProtParameters.WPP_CB_RECORDING_FRAMERATE_MEDIUM] = 'medium';
        mapping[wolfProtParameters.WPP_CB_RECORDING_FRAMERATE_HIGH] = 'high';

        receivedData.framerate = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get recording resolution reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_RECORDING_RESOLUTION: function WPC_CB_RECORDING_RESOLUTION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};
        mapping[wolfProtParameters.WPP_CB_RESOLUTION_360P] = '360p';
        mapping[wolfProtParameters.WPP_CB_RESOLUTION_540P] = '540p';
        mapping[wolfProtParameters.WPP_CB_RESOLUTION_720P] = '720p';
        mapping[wolfProtParameters.WPP_CB_RESOLUTION_1080P] = '1080p';

        receivedData.recordingResolution = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the block command and calls the given callback
     *
     * @method WPC_CB_BLOCK
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_BLOCK: function WPC_CB_BLOCK(inFrame, connection, command, invokeCallbackOf) {
        var i;
        var wlanLength = inFrame[10];
        var numberOfSources = inFrame[11 + wlanLength + 2];
        var receivedData = {
            streamingState : inFrame[4],
            recordingState : inFrame[5],
            mute : inFrame[6],
            freeze : inFrame[7],
            volume : inFrame[8],
            systemState : inFrame[9],
            wlanName : parseStringFromArray(inFrame, 11, wlanLength),
            wlanStatus : inFrame[11 + wlanLength],
            signalStength : inFrame[11 + wlanLength + 1],
            sources: [],
        };

        for (i = 0; i < numberOfSources; i++) {
            receivedData.sources[i] = {
                type: inFrame[11 + wlanLength + 2 + i],
                state: inFrame[11 + wlanLength + 2 + i + numberOfSources],
            };
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get miracast state reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MIRACAST: function WPC_CB_MIRACAST(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.miracastState = inFrame[4];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get mice state reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MICE: function WPC_CB_MICE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_MICE_INTERFACE_ALL] = 'all';
        mapping[wolfProtParameters.WPP_CB_MICE_INTERFACE_LAN1] = 'lan1';
        mapping[wolfProtParameters.WPP_CB_MICE_INTERFACE_LAN2] = 'lan2';
        mapping[wolfProtParameters.WPP_CB_MICE_INTERFACE_WLAN] = 'wlan';

        receivedData.miceState = inFrame[4];
        receivedData.interface = mapping[inFrame[5]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get airplay state reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_AIRPLAY: function WPC_CB_AIRPLAY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.airplayState = inFrame[4];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get airplay mode reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_AIRPLAY_MODE: function WPC_CB_AIRPLAY_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.airplayPin = inFrame[4];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get vCast PIN reply and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_VCAST_PIN: function WPC_CB_VCAST_PIN(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.vCastPin = inFrame[4];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get Miracast PIN reply and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MIRACAST_PIN: function WPC_CB_MIRACAST_PIN(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.miracastPin = inFrame[4];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get vconnect state reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_VCONNECT: function WPC_CB_VCONNECT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.vconnectState = inFrame[4];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get mirror state reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MIRROR: function WPC_CB_MIRROR(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.mirrorState = inFrame[4];
        receivedData.time = parseValueFromArrayBigEndian(inFrame,5,2);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get presentation mode reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_PRESENTATION_MODE: function WPC_CB_PRESENTATION_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.presentationMode = inFrame[4] === 0 ? 'meeting' : 'lecture';
        receivedData.timeout = inFrame[5];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get presentation ID reply and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_PRESENTATION_ID: function WPC_CB_PRESENTATION_ID(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.id = parseValueFromArrayBigEndian(inFrame, 4, 4);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get video status reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_VIDEO_STATUS: function WPC_CB_VIDEO_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.frameBox = [];

        if (inFrame[3] === 36) {
            for (var i = 0; i < 4; i++) {
                receivedData.frameBox[i] = {};
                receivedData.frameBox[i].videoDuration = parseValueFromArrayBigEndian(inFrame, 4 + i * 9, 4);
                receivedData.frameBox[i].videoPosition = parseValueFromArrayBigEndian(inFrame, 8 + i * 9, 4);
                receivedData.frameBox[i].speed = (inFrame[12 + i * 9] >> 7) * -256 + inFrame[12 + i * 9];  //convert to signed
            }
        } else {
            console.error('WPC_CB_VIDEO_STATUS: invalid length');
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get mounts list reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MOUNTS_LIST: function WPC_CB_MOUNTS_LIST(inFrame, connection, command, invokeCallbackOf) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = parseValueFromArrayBigEndian(inFrame, 3, 2);
        var jsonString = parseStringFromArray(inFrame, 5, length);

        receivedData.mountList = JSONService.parse(jsonString);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get file list reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_FILE_LIST: function WPC_CB_FILE_LIST(inFrame, connection, command, invokeCallbackOf) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = wpCalcDatalen(inFrame);
        var jsonString = parseStringFromArray(inFrame, wpCalcHeaderlen(inFrame), length);

        receivedData = JSONService.parse(jsonString);

        invokeCallbackOf(command, receivedData.fileList ? receivedData : {
            fileList: receivedData
        });
    },

    /**
     * parses the get cloud service enabled command and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CLOUD_SERVICE_ENABLE: function WPC_CB_CLOUD_SERVICE_ENABLE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.gdriveEnabled = inFrame[4] === 0x01 ? true : false;
        receivedData.dropboxEnabled = inFrame[5] === 0x01 ? true : false;
        receivedData.jianguoyunEnabled = inFrame[7] === 0x01 ? true : false;
        receivedData.onedrive = inFrame[8] === 0x01 ? true : false;
        receivedData.webdav = inFrame[9] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get cloud status command and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CLOUD_STATUS: function WPC_CB_CLOUD_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var i;
        var cloudServices = ['dropbox', 'gdrive', 'box', 'jianguoyun', 'onedrive', 'webdav'];
        var mapping = {};

        mapping[wolfProtParameters.WPP_CLOUD_STATUS_DISABLED] = 'disabled';
        mapping[wolfProtParameters.WPP_CLOUD_STATUS_CONNECTED] = 'connected';
        mapping[wolfProtParameters.WPP_CLOUD_STATUS_DISCONNECTED] = 'disconnected';
        mapping[wolfProtParameters.WPP_CLOUD_STATUS_OAUTH] = 'oauth';
        mapping[wolfProtParameters.WPP_CLOUD_STATUS_FAILED] = 'failed';
        mapping[wolfProtParameters.WPP_CLOUD_STATUS_SYNCED] = 'synced';
        mapping[wolfProtParameters.WPP_CLOUD_STATUS_NOT_RESPONDING] = 'not-responding';

        var length = inFrame[3];
        receivedData.cloudStatus = [];

        for (i = 0; i < length; i++) {
            if (cloudServices[i] !== undefined) {
                receivedData.cloudStatus[i] = {
                    name: cloudServices[i],
                    state: mapping[inFrame[4 + i]]
                };
            }
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get webdav settings reply and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WEBDAV_SETTINGS: function WPC_CB_WEBDAV_SETTINGS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var lengthUrl = inFrame[5];
        var lengthName = inFrame[6 + lengthUrl];

        receivedData.url = parseStringFromArray(inFrame, 6, lengthUrl);
        receivedData.name = parseStringFromArray(inFrame, 7 + lengthUrl, lengthName);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get meeting mode password reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MEETING_MODE_PASSWORD_REQUIRED: function WPC_CB_MEETING_MODE_PASSWORD_REQUIRED(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.password = (inFrame[4] === wolfProtParameters.WPP_CB_PASSWORD_REQUIRED ? true : false);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get login level reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LOGIN_LEVEL: function WPC_CB_LOGIN_LEVEL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_LOGIN_NONE] = 'none';
        mapping[wolfProtParameters.WPP_LOGIN_USER] = 'user';
        mapping[wolfProtParameters.WPP_LOGIN_ADMIN] = 'admin';
        mapping[wolfProtParameters.WPP_LOGIN_COLLAB] = 'collab';
        mapping[wolfProtParameters.WPP_LOGIN_VIEWER] = 'viewer';

        receivedData.loginLevel = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the password type and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_PASSWORD_TYPE: function WPC_CB_PASSWORD_TYPE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_PASSWORD_TYPE_PASSWORD] = 'password';
        mapping[wolfProtParameters.WPP_PASSWORD_TYPE_PIN] = 'pin';

        receivedData.passwordType = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the pin destination and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_PIN_DESTINATION: function WPC_CB_PIN_DESTINATION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.destinations = [];

        if (inFrame[4] === 0x01) {
            receivedData.destinations.push('vz');
        }

        if (inFrame[5] === 0x01) {
            receivedData.destinations.push('box');
        }

        if (inFrame[6] === 0x01) {
            receivedData.destinations.push('local');
        }

        if (inFrame[7] === 0x01) {
            receivedData.destinations.push('room');
        }

        if (inFrame[8] === 0x01) {
            receivedData.destinations.push('hdmi2');
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the pin status local reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_PIN_STATUS_LOCAL: function WPC_CB_PIN_STATUS_LOCAL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var length = inFrame[3];

        if (inFrame[4] === 0x01) {
            receivedData.showPin = true;
            receivedData.pin = parseStringFromArray(inFrame, 5, length - 1);
        } else {
            receivedData.showPin = false;
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the browser status reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_BROWSER_STATUS: function WPC_CB_BROWSER_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        //var length = parseValueFromArrayBigEndian(inFrame, 3, 2);
        var pointer = 5;
        var lengthUrl;
        var lengthTitle;

        receivedData.window = [];

        for (var i = 0; i < 4; i++) {
            receivedData.window[i] = {};
            lengthUrl = parseValueFromArrayBigEndian(inFrame, pointer, 2);

            pointer = pointer + 2;
            receivedData.window[i].url = parseStringFromArray(inFrame, pointer, lengthUrl);

            pointer = pointer + lengthUrl;
            lengthTitle = parseValueFromArrayBigEndian(inFrame, pointer, 2);

            pointer = pointer + 2;
            receivedData.window[i].title = parseStringFromArray(inFrame, pointer, lengthTitle);

            pointer = pointer + lengthTitle;
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get mic mute state
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MIC_MUTE: function WPC_CB_MIC_MUTE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.mute = inFrame[4];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get master mute state
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MASTER_MUTE: function WPC_CB_MASTER_MUTE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.mute = inFrame[4];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get master volume
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MASTER_VOLUME: function WPC_CB_MASTER_VOLUME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.volume = inFrame[4];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get main freeze
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MAIN_FREEZE: function WPC_CB_MAIN_FREEZE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.freeze = inFrame[4];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get edit content freeze
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_EDIT_CONTENT_FREEZE: function WPC_CB_EDIT_CONTENT_FREEZE(inFrame, connection, command, invokeCallbackOf) {
        const receivedData = {};
        const mapping = {};

        mapping[wolfProtParameters.WPP_FREEZE_OFF] = 'off';
        mapping[wolfProtParameters.WPP_FREEZE_ON] = 'on';

        receivedData.freeze = mapping[inFrame[4]];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the aux mode
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_AUX_MODE: function WPC_CB_AUX_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_AUX_MODE_CONFIDENCE_MIRROR] = 'confidenceMirror';
        mapping[wolfProtParameters.WPP_AUX_MODE_CONFIDENCE_FULLHD] = 'confidenceFullHD';
        mapping[wolfProtParameters.WPP_AUX_MODE_CONFIDENCE_HD] = 'confidenceHD';
        mapping[wolfProtParameters.WPP_AUX_MODE_CONTENT_FULLHD] = 'contentFullHD';
        mapping[wolfProtParameters.WPP_AUX_MODE_CONTENT_HD] = 'contentHD';
        mapping[wolfProtParameters.WPP_AUX_MODE_MODERATOR_FULLHD] = 'moderatorFullHD';
        mapping[wolfProtParameters.WPP_AUX_MODE_MODERATOR_HD] = 'moderatorHD';

        receivedData.auxMode = mapping[inFrame[4]];
        receivedData.contentMode = inFrame[4] > 0x02 ? true : false;

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the main resolution
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MAIN_RESOLUTION: function WPC_CB_MAIN_RESOLUTION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_MAIN_RESOLUTION_AUTO] = 'auto';
        mapping[wolfProtParameters.WPP_CB_MAIN_RESOLUTION_720P60] = '720p60';
        mapping[wolfProtParameters.WPP_CB_MAIN_RESOLUTION_1080P30] = '1080p30';
        mapping[wolfProtParameters.WPP_CB_MAIN_RESOLUTION_1080P60] = '1080p60';
        mapping[wolfProtParameters.WPP_CB_MAIN_RESOLUTION_2160P30] = '2160p30';
        mapping[wolfProtParameters.WPP_CB_MAIN_RESOLUTION_2160P60] = '2160p60';

        receivedData.resolution = mapping[inFrame[4]];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the streaming inteface
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_STREAMING_INTERFACE: function WPC_CB_STREAMING_INTERFACE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_STREAMING_INTERFACE_LAN1] = 'lan1';
        mapping[wolfProtParameters.WPP_STREAMING_INTERFACE_WLAN] = 'wlan';

        receivedData.interface = mapping[inFrame[4]];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the wlan region
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_REGION: function WPC_CB_WLAN_REGION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_WLAN_REGION_US] = 'us';
        mapping[wolfProtParameters.WPP_WLAN_REGION_OTHERS] = 'others';

        receivedData.interface = mapping[inFrame[4]];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the image status reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_IMAGE_STATUS: function WPC_CB_IMAGE_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        //var length = parseValueFromArrayBigEndian(inFrame, 3, 2);
        var pointer = 5;
        var filenameLength;

        receivedData.window = [];

        for (var i = 0; i < 4; i++) {
            receivedData.window[i] = {};

            receivedData.window[i].imageNumber = parseValueFromArrayBigEndian(inFrame, pointer, 4);
            pointer = pointer + 4;

            receivedData.window[i].totalNumber = parseValueFromArrayBigEndian(inFrame, pointer, 4);
            pointer = pointer + 4;

            filenameLength = parseValueFromArrayBigEndian(inFrame, pointer, 2);
            pointer = pointer + 2;

            receivedData.window[i].filename = decodeURIComponent(parseStringFromArray(inFrame, pointer, filenameLength));

            pointer = pointer + filenameLength;
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the status bar settings
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_STATUSBAR_SETTINGS: function WPC_CB_STATUSBAR_SETTINGS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_STATUSBAR_SETTINGS_NONE] = 'nothing';
        mapping[wolfProtParameters.WPP_STATUSBAR_SETTINGS_LAN] = 'lan';
        mapping[wolfProtParameters.WPP_STATUSBAR_SETTINGS_WLAN] = 'wlan';
        mapping[wolfProtParameters.WPP_STATUSBAR_SETTINGS_BOTH] = 'both';

        receivedData.networkInterface = mapping[inFrame[4]];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the streaming url
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_STREAMING_URL: function WPC_CB_STREAMING_URL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.streamingUrl = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the xml from the Visualizer
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_OSD_LAYOUT: function WPC_OSD_LAYOUT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var length = parseValueFromArrayBigEndian(inFrame, 3, 4);

        receivedData.xml = parseStringFromArray(inFrame, 7, length);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the visualizer model
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_MODEL: function WPC_MODEL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var length = inFrame[2];

        receivedData.vzModel = parseStringFromArray(inFrame, 3, length);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the autofocus state
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_AF: function WPC_AF(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.afEnabled = (inFrame[3] === 0x00 ? false : true);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the freeze state
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_FREEZE: function WPC_FREEZE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.freezeEnabled = (inFrame[3] === 0x00 ? false : true);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the power state
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_POWER: function WPC_POWER(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.powerStatus = (inFrame[3] === 0x00 ? false : true);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the light state
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_LIGHT: function WPC_LIGHT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.lightStatus = (inFrame[3] === 0x01 ? true : false);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the capture area shift state of the VZ
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CAPTURE_AREA_SHIFT: function WPC_CAPTURE_AREA_SHIFT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.casStatus = (inFrame[3] === 0x01 ? true : false);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the block command and calls the given callback
     * @method WPC_FEATURES
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_FEATURES: function WPC_FEATURES(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var bit0 = 0x01;
        var bit1 = 0x02;
        var bit2 = 0x04;
        var bit3 = 0x08;
        var bit4 = 0x10;
        var bit5 = 0x20;
        var bit6 = 0x40;
        var bit7 = 0x80;

        receivedData.extInt = ((inFrame[3] & bit0) > 0 ? true : false);
        receivedData.light = ((inFrame[3] & bit1) > 0 ? true : false);
        receivedData.power = ((inFrame[3] & bit2) > 0 ? true : false);
        receivedData.af = ((inFrame[3] & bit3) > 0 ? true : false);
        receivedData.focus = ((inFrame[3] & bit4) > 0 ? true : false);
        receivedData.mirror = ((inFrame[3] & bit5) > 0 ? true : false);
        receivedData.iris = ((inFrame[3] & bit6) > 0 ? true : false);
        receivedData.zoom = ((inFrame[3] & bit7) > 0 ? true : false);
        receivedData.preset = ((inFrame[5] & bit7) > 0 ? true : false);
        receivedData.captureAreaShift = ((inFrame[6] & bit7) > 0 ? true : false);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the ftp mode
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_FTP_MODE: function WPC_CB_FTP_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.mode = (inFrame[4] === 0x00 ? false : true);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the ftp url
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_FTP_URL: function WPC_CB_FTP_URL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.url = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the ftp user
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_FTP_USER: function WPC_CB_FTP_USER(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.user = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the ftp password
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_FTP_PASS: function WPC_CB_FTP_PASS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.password = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the user password
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_USER_PASSWORD: function WPC_CB_USER_PASSWORD(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.password = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the rms password
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ROOM_PASSWORD: function WPC_CB_ROOM_PASSWORD(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.password = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the ftp status
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_FTP_TEST_STATUS: function WPC_CB_FTP_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        var mapping = {};
        mapping[wolfProtParameters.WPP_CB_FTP_STATUS_TESTING] = 'testing';
        mapping[wolfProtParameters.WPP_CB_FTP_STATUS_FAILED] = 'failed';
        mapping[wolfProtParameters.WPP_CB_FTP_STATUS_SUCCESS] = 'success';

        receivedData.status = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the vz status (connected/disconnected, mode, RC features, IP, power state, autofocus state, freeze state
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_VZ_STATUS: function WPC_CB_VZ_STATUS(inFrame, connection, command, invokeCallbackOf) {
        const receivedData = {};

        const connectionMapping = {};
        connectionMapping[wolfProtParameters.WPP_CB_VZ_CONNECTED] = true;
        connectionMapping[wolfProtParameters.WPP_CB_VZ_DISCONNECTED] = false;

        const modeMapping = {};
        modeMapping[wolfProtParameters.WPP_CB_VZ_MODE_NONE] = 'none';
        modeMapping[wolfProtParameters.WPP_CB_VZ_MODE_BASIC] = 'basic';
        modeMapping[wolfProtParameters.WPP_CB_VZ_MODE_FULL] = 'full';
        modeMapping[wolfProtParameters.WPP_CB_VZ_MODE_BASIC_FIRMWARE] = 'basicFw';
        modeMapping[wolfProtParameters.WPP_CB_VZ_MODE_SECURITY] = 'security';

        const features = {};

        receivedData.vzConnected = connectionMapping[inFrame[4]];
        receivedData.vzMode = modeMapping[inFrame[5]];

        features.extInt = (inFrame[6] & MASK.bit0) !== 0;
        features.light = (inFrame[6] & MASK.bit1) !== 0;
        features.power = (inFrame[6] & MASK.bit2) !== 0;
        features.af = (inFrame[6] & MASK.bit3) !== 0;
        features.focus = (inFrame[6] & MASK.bit4) !== 0;
        features.mirror = (inFrame[6] & MASK.bit5) !== 0;
        features.iris = (inFrame[6] & MASK.bit6) !== 0;
        features.zoom = (inFrame[6] & MASK.bit7) !== 0;
        features.preset = (inFrame[8] & MASK.bit7) !== 0;
        features.captureAreaShift = (inFrame[9] & MASK.bit7) !== 0;
        features.opaf = (inFrame[10] & MASK.bit3) !== 0;
        features.opaf9 = (inFrame[13] & MASK.bit3) !== 0;

        receivedData.vzFeatures = features;
        receivedData.vzIp = ipv4AddressInt2String((inFrame[14] << 24) | (inFrame[15] << 16) | (inFrame[16] << 8) | (inFrame[17]));
        receivedData.vzPowerState = (inFrame[18] === wolfProtParameters.WPP_ON);
        receivedData.vzAFState = (inFrame[19] === wolfProtParameters.WPP_ON);
        receivedData.vzFreezeState = (inFrame[20] === wolfProtParameters.WPP_ON);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get osd message reply
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_OSD_MSG: function WPC_CB_OSD_MSG(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var paramLength = inFrame[7];
        var pointer;
        var mapping = {};
        var count = 10;
        var i = 0;

        mapping[wolfProtParameters.WPP_OSD_MESSAGE_ICON_NONE] = 'none';
        mapping[wolfProtParameters.WPP_OSD_MESSAGE_ICON_WARNING] = 'warning';
        mapping[wolfProtParameters.WPP_OSD_MESSAGE_ICON_ERROR] = 'error';
        mapping[wolfProtParameters.WPP_OSD_MESSAGE_ICON_INFO] = 'info';
        mapping[wolfProtParameters.WPP_OSD_MESSAGE_ICON_MATRIX] = 'matrix';
        mapping[wolfProtParameters.WPP_OSD_MESSAGE_ICON_VMEETING] = 'vmeeting';
        mapping[wolfProtParameters.WPP_OSD_MESSAGE_ICON_GROUPWORK] = 'groupwork';

        receivedData.osdMessage = inFrame[5];
        receivedData.icon = mapping[inFrame[6]];

        pointer = 8;
        receivedData.parameter = {};

        while (count > 0) {
            if (inFrame[pointer] === undefined) {
                break;
            }

            receivedData.parameter[i] = parseStringFromArray(inFrame, pointer, paramLength);
            pointer += paramLength;

            i++;
            count --;
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get hdmi input message reply
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_HDMI_INPUT: function WPC_CB_HDMI_INPUT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};
        mapping[wolfProtParameters.WPP_HDMI_INPUT_IS_VZ] = 'visualizer';
        mapping[wolfProtParameters.WPP_HDMI_INPUT_IS_HDMI] = 'hdmi';
        mapping[wolfProtParameters.WPP_HDMI_INPUT_IS_COMPUTER] = 'computer';
        mapping[wolfProtParameters.WPP_HDMI_INPUT_IS_DISC_PLAYER] = 'discPlayer';
        mapping[wolfProtParameters.WPP_HDMI_INPUT_IS_CAMERA] = 'camera';

        var length = inFrame[3] - 2;

        receivedData.index = inFrame[4];
        receivedData.type = mapping[inFrame[5]];
        receivedData.name = parseStringFromArray(inFrame,6,length);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get osd message reply
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_SUPPORT_YEAR: function WPC_CB_SUPPORT_YEAR(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.supportYear = parseValueFromArrayBigEndian(inFrame, 4, 2);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get www update status reply
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WWW_UDATE_STATUS: function WPC_CB_WWW_UDATE_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var length = inFrame[3] - 2;
        var mapping = {};
        mapping[wolfProtParameters.WPP_WWW_UPDATE_STATUS_IDLE] = 'idle';
        mapping[wolfProtParameters.WPP_WWW_UPDATE_STATUS_FAILED] = 'failed';
        mapping[wolfProtParameters.WPP_WWW_UPDATE_STATUS_NO_CONNECTION] = 'noconnect';
        mapping[wolfProtParameters.WPP_WWW_UPDATE_STATUS_CHECKING] = 'checking';
        mapping[wolfProtParameters.WPP_WWW_UPDATE_STATUS_UPTODATE] = 'uptodate';
        mapping[wolfProtParameters.WPP_WWW_UPDATE_STATUS_OUTOFDATE] = 'outofdate';
        mapping[wolfProtParameters.WPP_WWW_UPDATE_STATUS_DOWNLOADING] = 'downloading';
        mapping[wolfProtParameters.WPP_WWW_UPDATE_STATUS_UPDATING] = 'updating';

        receivedData.status = mapping[inFrame[4]];
        receivedData.progress = inFrame[5];
        receivedData.version = parseStringFromArray(inFrame, 6, length);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get RAUC firmware update status reply
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_RAUC_UPDATE_STATUS: function WPC_CB_RAUC_UPDATE_STATUS(inFrame, connection, command, invokeCallbackOf) {
        let receivedData = {};

        let sourceStatusMapping = {};
        sourceStatusMapping[wolfProtParameters.WPP_RAUC_SOURCE_STATUS_NOT_FOUND] = states.raucSourceStates.notFound;
        sourceStatusMapping[wolfProtParameters.WPP_RAUC_SOURCE_STATUS_FOUND] = states.raucSourceStates.found;
        sourceStatusMapping[wolfProtParameters.WPP_RAUC_SOURCE_STATUS_BUSY] = states.raucSourceStates.busy;

        let updateStatusMapping = {};
        updateStatusMapping[wolfProtParameters.WPP_RAUC_UPDATE_STATUS_IDLE] = states.raucUpdateStates.idle;
        updateStatusMapping[wolfProtParameters.WPP_RAUC_UPDATE_STATUS_UPDATING] = states.raucUpdateStates.updating;
        updateStatusMapping[wolfProtParameters.WPP_RAUC_UPDATE_STATUS_FINISHED] = states.raucUpdateStates.finished;
        updateStatusMapping[wolfProtParameters.WPP_RAUC_UPDATE_STATUS_FAILED] = states.raucUpdateStates.failed;
        updateStatusMapping[wolfProtParameters.WPP_RAUC_UPDATE_STATUS_DOWNLOADING] = states.raucUpdateStates.downloading;

        receivedData.updateCheckStatus = sourceStatusMapping[inFrame[5]];

        let versionLength = inFrame[6];
        receivedData.version = parseStringFromArray(inFrame, 7, versionLength);

        let pointer = 7 + versionLength;
        receivedData.updateStatus = updateStatusMapping[inFrame[pointer++]];
        receivedData.progress = inFrame[pointer];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get download list reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CLOUD_DOWNLOAD_LIST: function WPC_CB_CLOUD_DOWNLOAD_LIST(inFrame, connection, command, invokeCallbackOf) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = parseValueFromArrayBigEndian(inFrame, 3, 2);
        var jsonString = parseStringFromArray(inFrame, 5, length);

        receivedData.downloadList = JSONService.parse(jsonString);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get power status reply
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_POWER: function WPC_CB_POWER(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};
        mapping[wolfProtParameters.WPP_POWER_OFF] = 'off';
        mapping[wolfProtParameters.WPP_POWER_ON] = 'on';

        receivedData.powerStatus = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get power status reply
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_EULA_ACCEPT: function WPC_CB_EULA_ACCEPT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};
        mapping[wolfProtParameters.WPP_EULA_ACCEPT] = true;
        mapping[wolfProtParameters.WPP_EULA_NOT_ACCEPTED] = false;

        receivedData.eulaAccepted = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get hdcp main out reply
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_HDCP_MAIN_OUT: function WPC_CB_HDCP_MAIN_OUT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.hdcpEnabled = inFrame[4] === 0x01 ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get hdcp main out reply
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_HDCP_AUX_OUT: function WPC_CB_HDCP_AUX_OUT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.hdcpEnabled = inFrame[4] === 0x01 ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get hdcp in1 reply
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_HDCP_IN1: function WPC_CB_HDCP_IN1(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.hdcpEnabled = inFrame[4] === 0x01 ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get hdcp in2 reply
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_HDCP_IN2: function WPC_CB_HDCP_IN2(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.hdcpEnabled = inFrame[4] === 0x01 ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the wlan username
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_USERNAME: function WPC_CB_WLAN_USERNAME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.wlanUsername = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the wlan anonymous identity
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_ANONYMOUS_IDENTITY: function WPC_CB_WLAN_ANONYMOUS_IDENTITY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.wlanAnonymousIdentity = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the wlan authentication method
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_AUTH_METHOD: function WPC_CB_WLAN_AUTH_METHOD(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_WLAN_AUTH_METHOD_PEAP_MSCHAPV2] = 'peap-mschapv2';
        mapping[wolfProtParameters.WPP_CB_WLAN_AUTH_METHOD_TTLS_PAP] = 'ttls-pap';

        receivedData.authMethod = mapping[inFrame[4]];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the wlan status
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_STATUS: function WPC_CB_WLAN_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_WLAN_STATUS_DISCONNECTED_NOTRUNNING] = 'disconnected';
        mapping[wolfProtParameters.WPP_WLAN_STATUS_CONNECTED_RUNNING] = 'connected';
        mapping[wolfProtParameters.WPP_WLAN_STATUS_SCANNING] = 'scanning';
        mapping[wolfProtParameters.WPP_WLAN_STATUS_AUTHENTICATING] = 'authenticating';
        mapping[wolfProtParameters.WPP_WLAN_STATUS_FAILED] = 'failed';

        receivedData.wlanStatus = mapping[inFrame[4]];
        receivedData.qualityValue = inFrame[5];
        receivedData.signalLevel = inFrame[6] > 127 ? inFrame[6] - 256 : inFrame[6];
        receivedData.counterApLoss = parseValueFromArrayBigEndian(inFrame, 7, 2);
        receivedData.counterSignalLevel = parseValueFromArrayBigEndian(inFrame, 9, 2);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the ethernet status
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ETH_STATUS: function WPC_CB_ETH_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_LAN_STATUS_DISCONNECTED] = 'disconnected';
        mapping[wolfProtParameters.WPP_LAN_STATUS_CONNECTED] = 'connected';
        mapping[wolfProtParameters.WPP_LAN_STATUS_SCANNING] = 'scanning';
        mapping[wolfProtParameters.WPP_LAN_STATUS_AUTHENTICATING] = 'authenticating';
        mapping[wolfProtParameters.WPP_LAN_STATUS_FAILED] = 'failed';

        receivedData.ethStatus = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get storage space left reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_STORAGE_SPACE_LEFT: function WPC_CB_STORAGE_SPACE_LEFT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};
        mapping[wolfProtParameters.WPP_STORAGE_SPACE_UNIT_MB] = 'MB';

        receivedData.unit = mapping[inFrame[4]];
        receivedData.space = parseValueFromArrayBigEndian(inFrame,5,4);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get firmware version and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_FIRMWARE_VERSION: function WPC_CB_FIRMWARE_VERSION(inFrame, connection, command, invokeCallbackOf) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = parseValueFromArrayBigEndian(inFrame, 3, 2);
        var jsonString = parseStringFromArray(inFrame, 5, length);

        receivedData.version = JSONService.parse(jsonString, {});

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get local touch keyboard answer and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LOCAL_TOUCH_KEYBOARD: function WPC_CB_LOCAL_TOUCH_KEYBOARD(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x00 ? false : true;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get capslock status reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CAPSLOCK_STATUS: function WPC_CB_CAPSLOCK_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.capsLockEnabled = inFrame[4] === 0x00 ? false : true;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get timezone status reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_TIMEZONE: function WPC_CB_TIMEZONE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.timezoneIndex = inFrame[4];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get qr code visible reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_QR_CODE: function WPC_CB_QR_CODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var length = inFrame[3] - 1;

        receivedData.visible = inFrame[4] === 0x00 ? false : true;
        receivedData.link = parseStringFromArray(inFrame, 5, length);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get access token reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ACCESS_TOKEN: function WPC_CB_ACCESS_TOKEN(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var value = 0;

        for (var i = 0; i < 8; i++) {
            value += inFrame[i + 5] * Math.pow(2, 56 - i * 8);
        }
        receivedData.accessToken = value.toString(16);
        receivedData.loginLevel = inFrame[4];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get extra settings reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_XTRA_SETTINGS: function WPC_CB_XTRA_SETTINGS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var length = parseValueFromArrayBigEndian(inFrame, 4, 4);

        if (length > 0) {

            //check if a jpeg file is returned.
            if (inFrame[8] === 0xFF && inFrame[9] === 0xD8 &&
                inFrame[inFrame.length -2] === 0xFF && inFrame[inFrame.length -1] === 0xD9) {
                receivedData.file = inFrame.subarray(8);
            } else {
                receivedData.string = parseStringFromArray(inFrame,8, length);
            }
        } else {
            receivedData = null;
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get user data status reply
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LOAD_USER_DATA: function WPC_CB_LOAD_USER_DATA(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_USER_DATA_MISSING] = 'missing';
        mapping[wolfProtParameters.WPP_USER_DATA_AVAILABLE] = 'available';
        mapping[wolfProtParameters.WPP_USER_DATA_LOADED] = 'loaded';
        mapping[wolfProtParameters.WPP_USER_DATA_REJECTED] = 'rejected';

        receivedData.userData = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get content sources reply
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_GET_CONTENT_SOURCES: function WPC_CB_GET_CONTENT_SOURCES(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};
        var length;
        var numSources;
        var blocklength;
        var blockStart;
        var nameLength;
        var pointer;
        var count = 0;

        receivedData.sources = [];

        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_HDMI] = 'hdmi';
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_VZ] = 'visualizer';
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_DISC] = 'disc';
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_COMPUTER] = 'computer';
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_BROWSER] = 'browser';
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_LOCAL_FILES] = 'filebrowser';
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_CLOUD] = 'cloud';
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_MIRROR] = 'mirror';
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_WHITEBOARD] = 'whiteboard';
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_WEBCONFERENCE] = 'webconference';
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_WEBCAM] = 'webcam';
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_STREAM_INPUT] = 'streaminput';
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_OFFICE365] = 'office365';
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_OFFICE365_TEAMS] = 'msteams';
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_CYNAP] = 'cynap';
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_ZOOM] = 'zoom';
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_STREAM_INPUT_LIST] = 'streaminputlist';
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_CAMERA] = 'camera';
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_BROWSER_SHORTCUT] = "browser-shortcut";
        mapping[wolfProtParameters.WPP_CONTENT_SOURCES_TYPE_UNDEFINED] = 'undefined';

        length = parseValueFromArrayBigEndian(inFrame, 3, 2);
        numSources = inFrame[5];

        receivedData.numSources = numSources;

        pointer = 6;

        while (count < receivedData.numSources) {
            receivedData.sources[count] = {};
            blockStart = pointer;
            blocklength = inFrame[pointer];
            pointer++;

            receivedData.sources[count].present = inFrame[pointer] === wolfProtParameters.WPP_CONTENT_SOURCES_NOT_PRESENT ? false : true;
            pointer++;

            receivedData.sources[count].type = mapping[inFrame[pointer]];
            pointer++;

            nameLength = inFrame[pointer];
            pointer++;

            receivedData.sources[count].name = parseStringFromArray(inFrame, pointer, nameLength);
            pointer += nameLength;

            if (receivedData.sources[count].type === 'hdmi' ||
                receivedData.sources[count].type === 'visualizer' ||
                receivedData.sources[count].type === 'disc' ||
                receivedData.sources[count].type === 'computer' ||
                receivedData.sources[count].type === 'camera')
            {
                receivedData.sources[count].hdmiIn = inFrame[pointer];
            }

            if (receivedData.sources[count].type === 'streaminput' || receivedData.sources[count].type === 'webcam')
            {
                receivedData.sources[count].index = inFrame[pointer];
                pointer++;

                receivedData.sources[count].icon = inFrame[pointer];
            }

            if (receivedData.sources[count].type === 'browser-shortcut') {
                receivedData.sources[count].index = inFrame[pointer];
            }

            pointer = blockStart + blocklength + 1;
            count++;
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get capture agent name reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_GET_WINDOW_FREE: function WPC_CB_GET_WINDOW_FREE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.freeWindow = inFrame[4] === 0x00 ? false : true;

        invokeCallbackOf(command, receivedData);
    },

    WPC_CB_CA_NAME: function WPC_CB_CA_NAME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.Name = parseStringFromArray(inFrame, 4, inFrame[3]);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get capture agent user reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CA_USER: function WPC_CB_CA_USER(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.user = parseStringFromArray(inFrame, 4, inFrame[3]);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get capture agent password reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CA_PASS: function WPC_CB_CA_PASS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.password = parseStringFromArray(inFrame, 4, inFrame[3]);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get capture agent url reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CA_URL: function WPC_CB_CA_URL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.url = parseStringFromArray(inFrame, 4, inFrame[3]);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get capture agent recording path reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CA_REC_PATH: function WPC_CB_CA_REC_PATH(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_OPENCAST_REC_INTERN] = 'intern';
        mapping[wolfProtParameters.WPP_CB_OPENCAST_REC_EXTERN] = 'extern';

        receivedData.mode =  mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get capture agent enable reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CA: function WPC_CB_CA(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === 0x00 ? false : true;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get capture agent lookahead reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CA_LOOKAHEAD: function WPC_CB_CA_LOOKAHEAD(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.lookahead = inFrame[4];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the capture agent ingest and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CA_INGEST: function WPC_CB_CA_INGEST(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_CA_TIME_MODE_AUTO] = 'auto';
        mapping[wolfProtParameters.WPP_CB_CA_TIME_MODE_MANUAL] = 'manual';

        receivedData.hour = inFrame[4];
        receivedData.minute = inFrame[5];
        receivedData.timemode = mapping[inFrame[6]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the capture agent state and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CA_STATE: function WPC_CB_CA_STATE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_CA_STATE_DISABLE] = 'disable';
        mapping[wolfProtParameters.WPP_CB_CA_STATE_IDLE] = 'idle';
        mapping[wolfProtParameters.WPP_CB_CA_STATE_RECORDING] = 'recording';
        mapping[wolfProtParameters.WPP_CB_CA_STATE_FAIL] = 'fail';
        mapping[wolfProtParameters.WPP_CB_CA_STATE_FAIL_SERVICE] = 'fail_service';
        mapping[wolfProtParameters.WPP_CB_CA_STATE_FAIL_DISK_FULL] = 'fail_disk_full';
        mapping[wolfProtParameters.WPP_CB_CA_STATE_FAIL_SSL] = 'fail_ssl';

        receivedData.state = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the annotation settings and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ANNOTATION_SETTINGS: function WPC_CB_ANNOTATION_SETTINGS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_ANNOTATION_CLEAR_ALL_DISABLE] = 'disable';
        mapping[wolfProtParameters.WPP_CB_ANNOTATION_CLEAR_ALL_ENABLE] = 'enable';

        receivedData.status = inFrame[4];
        receivedData.redoOps = inFrame[5];
        receivedData.undoOps = inFrame[6];
        receivedData.clearAll = mapping[inFrame[7]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the annotation state and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ANNOTATION_STATE: function WPC_CB_ANNOTATION_STATE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_ANNOTATION_DISABLED] = 'disable';
        mapping[wolfProtParameters.WPP_CB_ANNOTATION_ENABLED] = 'enable';
        mapping[wolfProtParameters.WPP_CB_ANNOTATION_PAUSED] = 'pause';
        mapping[wolfProtParameters.WPP_CB_ANNOTATION_MATRIX] = 'matrixEnable';

        receivedData.state = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get front panel login reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_FRONTPANEL_LOGIN: function WPC_CB_FRONTPANEL_LOGIN(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === 0x00 ? false : true;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get usb stick support reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_USB_STICK_SUPPORT: function WPC_CB_USB_STICK_SUPPORT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === 0x00 ? false : true;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the netdrive configuration
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_NETDRIVE: function WPC_CB_NETDRIVE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};
        var lengthName, lengthUrl, lengthUser, lengthPassword, lengthDomain;

        mapping[wolfProtParameters.WPP_CB_NETDRIVE_TYPE_NONE] = 'none';
        mapping[wolfProtParameters.WPP_CB_NETDRIVE_TYPE_CIFS] = 'cifs';
        mapping[wolfProtParameters.WPP_CB_NETDRIVE_TYPE_NFS] = 'nfs';

        receivedData.id = inFrame[5];
        receivedData.type = mapping[inFrame[6]];

        if (receivedData.type === 'none') {
            receivedData.notConfigurable = true;
        } else {
            receivedData.notConfigurable = false;
        }

        lengthName = inFrame[7];
        receivedData.name = {};
        receivedData.name = parseStringFromArray(inFrame, 8, lengthName);

        if (receivedData.name === '') {
            receivedData.name = 'Network Drive ' + inFrame[5];
        }

        lengthUrl = inFrame[8 + lengthName];
        receivedData.url = {};
        receivedData.url = parseStringFromArray(inFrame, 9 + lengthName, lengthUrl);

        lengthUser = inFrame[9 + lengthName + lengthUrl];
        receivedData.user = {};
        receivedData.user = parseStringFromArray(inFrame, 10 + lengthName + lengthUrl, lengthUser);

        lengthPassword = inFrame[10 + lengthName + lengthUrl + lengthUser];
        receivedData.password = {};
        receivedData.password = parseStringFromArray(inFrame, 11 + lengthName + lengthUrl + lengthUser, lengthPassword);

        lengthDomain = inFrame[11 + lengthName + lengthUrl + lengthUser + lengthPassword];
        receivedData.domain = {};
        receivedData.domain = parseStringFromArray(inFrame, 12 + lengthName + lengthUrl + lengthUser + lengthPassword, lengthDomain);

        receivedData.readonly = inFrame[12 + lengthName + lengthUrl + lengthUser + lengthPassword + lengthDomain] === 0x00 ? true : false;
        receivedData.defaultUpload = inFrame[13 + lengthName + lengthUrl + lengthUser + lengthPassword + lengthDomain] === 0x00 ? false : true;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the netdrive configuration
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_NETDRIVE: function WPC_CB_MATRIX_NETDRIVE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};
        var lengthUrl, lengthUser, lengthPassword, lengthDomain;

        mapping[wolfProtParameters.WPP_CB_NETDRIVE_TYPE_NONE] = 'none';
        mapping[wolfProtParameters.WPP_CB_NETDRIVE_TYPE_CIFS] = 'cifs';
        mapping[wolfProtParameters.WPP_CB_NETDRIVE_TYPE_NFS] = 'nfs';

        receivedData.type = mapping[inFrame[5]];

        lengthUrl = inFrame[6];
        receivedData.url = {};
        receivedData.url = parseStringFromArray(inFrame, 7, lengthUrl);

        lengthUser = inFrame[7 + lengthUrl];
        receivedData.user = {};
        receivedData.user = parseStringFromArray(inFrame, 8 + lengthUrl, lengthUser);

        lengthPassword = inFrame[8 + lengthUrl + lengthUser];
        receivedData.password = {};
        receivedData.password = parseStringFromArray(inFrame, 9 + lengthUrl + lengthUser, lengthPassword);

        lengthDomain = inFrame[9 + lengthUrl + lengthUser + lengthPassword];
        receivedData.domain = {};
        receivedData.domain = parseStringFromArray(inFrame, 10 + lengthUrl + lengthUser + lengthPassword, lengthDomain);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the MATRIX netdrive status
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_NETDRIVE_STATUS: function WPC_CB_MATRIX_NETDRIVE_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_MATRIX_NETDRIVE_STATUS_DISABLED] = 'disabled';
        mapping[wolfProtParameters.WPP_CB_MATRIX_NETDRIVE_STATUS_NOTMOUNTED] = 'notMounted';
        mapping[wolfProtParameters.WPP_CB_MATRIX_NETDRIVE_STATUS_AUTHENTICATE] = 'authenticate';
        mapping[wolfProtParameters.WPP_CB_MATRIX_NETDRIVE_STATUS_BUSY] = 'busy';
        mapping[wolfProtParameters.WPP_CB_MATRIX_NETDRIVE_STATUS_NOTRESPONDING] = 'notResponding';
        mapping[wolfProtParameters.WPP_CB_MATRIX_NETDRIVE_STATUS_MOUNTED_READ_ONLY] = 'mountedRead';
        mapping[wolfProtParameters.WPP_CB_MATRIX_NETDRIVE_STATUS_MOUNTED_WRITE_ONLY] = 'mountedWrite';
        mapping[wolfProtParameters.WPP_CB_MATRIX_NETDRIVE_STATUS_MOUNTED_READ_WRITE] = 'mountedReadWrite';
        mapping[wolfProtParameters.WPP_CB_MATRIX_NETDRIVE_STATUS_MOUNTED_FAILED] = 'failed';

        receivedData.status = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the support year status
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_SUPPORT_STATUS: function WPC_CB_SUPPORT_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_SUPPORT_STATUS_NONE] = 'none';
        mapping[wolfProtParameters.WPP_CB_SUPPORT_STATUS_FOUND] = 'found';

        receivedData.state = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the feature pack status
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_FEATURE_STATUS: function WPC_CB_FEATURE_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_FEATURE_STATUS_NONE] = 'none';
        mapping[wolfProtParameters.WPP_CB_FEATURE_STATUS_FOUND] = 'found';

        receivedData.state = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the feature pack online update status
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_SUPPORT_ONLINE_STATUS: function WPC_CB_SUPPORT_ONLINE_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_SUPPORT_ONLINE_STATUS_NONE] = 'none';
        mapping[wolfProtParameters.WPP_CB_SUPPORT_ONLINE_STATUS_FOUND] = 'found';
        mapping[wolfProtParameters.WPP_CB_SUPPORT_ONLINE_STATUS_CHECKING] = 'checking';
        mapping[wolfProtParameters.WPP_CB_SUPPORT_ONLINE_STATUS_FAILED] = 'failed';

        receivedData.state = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the feature pack online update status
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_FEATURE_ONLINE_STATUS: function WPC_CB_FEATURE_ONLINE_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_FEATURE_ONLINE_STATUS_NONE] = 'none';
        mapping[wolfProtParameters.WPP_CB_FEATURE_ONLINE_STATUS_FOUND] = 'found';
        mapping[wolfProtParameters.WPP_CB_FEATURE_ONLINE_STATUS_CHECKING] = 'checking';
        mapping[wolfProtParameters.WPP_CB_FEATURE_ONLINE_STATUS_FAILED] = 'failed';

        receivedData.state = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the license features
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LICENSE_FEATURES: function WPC_CB_LICENSE_FEATURES(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var bit0 = 0x01;
        var bit1 = 0x02;
        var bit2 = 0x04;
        var bit3 = 0x08;
        var bit4 = 0x10;
        var bit5 = 0x20;
        var bit6 = 0x40;
        // var bit7 = 0x80;

        // receivedData.webcasting = ((inFrame[8] & bit0) > 0 ? true : false); unused
        receivedData.capture = ((inFrame[8] & bit1) > 0 ? true : false);
        receivedData.demo = ((inFrame[8] & bit2) > 0 ? true : false);
        receivedData.office = ((inFrame[8] & bit3) > 0 ? true : false);
        receivedData.matrix = ((inFrame[8] & bit4) > 0 ? true : false);
        receivedData.vmeeting = ((inFrame[8] & bit5) > 0 ? true : false);
        receivedData.wlanRegion2ExtChannels = ((inFrame[8] & bit6) > 0 ? true : false);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get line out volume
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LINEOUT_VOLUME: function WPC_CB_LINEOUT_VOLUME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.volume = inFrame[4];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get hdmi out 1 volume
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_HDMI_OUT1_VOLUME: function WPC_CB_HDMI_OUT1_VOLUME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.volume = inFrame[4];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get hdmi out 2 volume
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_HDMI_OUT2_VOLUME: function WPC_CB_HDMI_OUT2_VOLUME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.volume = inFrame[4];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the content priority
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_AUX_CONTENT_PRIORITY: function WPC_CB_AUX_CONTENT_PRIORITY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_NONE] = 'none';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_VISUALIZER] = 'visualizer';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_HDMI_INPUT] = 'hdmiInput';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_BROWSER] = 'browser';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_MIRACAST] = 'miracast';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_AIRPLAY] = 'airplay';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_VIDEO] = 'video';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_VSOLUTIONCAST] = 'vSolutionCast';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_IMAGE] = 'image';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_PDF] = 'pdf';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_OFFICE_PRESENTATION] = 'officePresentation';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_OFFICE_TEXT] = 'officeText';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_OFFICE_CALC] ='officeCalc';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_WHITEBOARD] ='whiteboard';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_AUDIO] = 'audio';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_WEBCONFERENCE] = 'webconference';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_WEBCAM] = 'webcam';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_STREAM_INPUT] = 'streamInput';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_OFFICE365_OUTLOOK] = 'office365Outlook';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_OFFICE365_WORD] = 'office365Word';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_OFFICE365_EXCEL] = 'office365Excel';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_OFFICE365_POWER_POINT] = 'office365PowerPoint';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_ONE_NOTE] = 'office365OneNote';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_MATRIX] = 'matrix';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_OFFICE365_TEAMS] = 'office365Teams';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_ZOOM] = 'zoom';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_HDMI1_INPUT] = 'hdmiInput1';
        mapping[wolfProtParameters.WPP_CB_CONTENT_PRIORITY_HDMI2_INPUT] = 'hdmiInput2';

        receivedData.index = inFrame[4];
        receivedData.type = mapping[inFrame[5]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the cast id's
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */

    WPC_CB_CAST_ID: function WPC_CB_CAST_ID(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var lengthLan1, lengthWlan, lengthLan2;

        lengthLan1 = parseValueFromArrayBigEndian(inFrame, 5, 2);
        lengthWlan = parseValueFromArrayBigEndian(inFrame, 7 + lengthLan1, 2);
        lengthLan2 = parseValueFromArrayBigEndian(inFrame, 9 + lengthLan1 + lengthWlan, 2);

        if (lengthLan1) {
            receivedData.lan1Id = parseStringFromArray(inFrame, 7, lengthLan1);
        }

        if (lengthWlan) {
            receivedData.wlanId = parseStringFromArray(inFrame, 9 + lengthLan1, lengthWlan);
        }

        if (lengthLan2) {
            receivedData.lan2Id = parseStringFromArray(inFrame, 11 + lengthLan1 + lengthWlan, lengthLan2);
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the connect id enable
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CONNECTION_ID_ENABLE: function WPC_CB_CONNECTION_ID_ENABLE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enable = inFrame[4] === 0x00 ? false : true;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the connect id interface
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CONNECTION_ID_INTERFACE: function WPC_CB_CONNECTION_ID_INTERFACE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_INTERFACE_LAN1] = 'lan1';
        mapping[wolfProtParameters.WPP_CB_INTERFACE_LAN2] = 'lan2';
        mapping[wolfProtParameters.WPP_CB_INTERFACE_WLAN] = 'wlan';

        receivedData.interface = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the connect id
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CONNECTION_ID: function WPC_CB_CONNECTION_ID(inFrame, connection, command, invokeCallbackOf) {
        let receivedData = {};
        let lengthId = inFrame[3];

        if (lengthId > 0) {
            receivedData.connectionId = parseStringFromArray(inFrame, 4, lengthId);
        } else {
            receivedData.connectionId = null;
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get webconference function reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WEBCONFERENCE_FUNCTION: function WPC_CB_WEBCONFERENCE_FUNCTION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.function = inFrame[4] === 0x00 ? false : true;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get chromecast state reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CHROMECAST: function WPC_CB_CHROMECAST(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.chromecastState = inFrame[4];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get chromecast state reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WEBCONFERENCE_CAMERA: function WPC_CB_WEBCONFERENCE_CAMERA(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.autostart = inFrame[4];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get webconference services list, reply and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_WEBCONFERENCE_ENTRIES: function WPC_CB_WEBCONFERENCE_ENTRIES(
        inFrame,
        connection,
        command,
        invokeCallbackOf
    ) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = wpCalcDatalen(inFrame);
        var jsonString = parseStringFromArray(inFrame, wpCalcHeaderlen(inFrame), length);

        receivedData.entries = JSONService.parse(jsonString);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get hdmi input message reply
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_HDMI_INPUT_AUTOSTART: function WPC_CB_HDMI_INPUT_AUTOSTART(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.index = inFrame[4];
        receivedData.autostart = inFrame[5] === 0x00 ? false : true;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get file upload status
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_FILE_UPLOAD_STATUS: function WPC_CB_FILE_UPLOAD_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.isUploading = inFrame[4];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the file upload percentage
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_FILE_UPLOAD_PERCENTAGE: function WPC_CB_FILE_UPLOAD_PERCENTAGE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.percentage = inFrame[4];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get file upload list reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_FILE_UPLOAD_LIST: function WPC_CB_FILE_UPLOAD_LIST(inFrame, connection, command, invokeCallbackOf) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = wpCalcDatalen(inFrame);
        var jsonString = parseStringFromArray(inFrame, wpCalcHeaderlen(inFrame), length);

        receivedData.fileUploadList = JSONService.parse(jsonString);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get lan authentication mode reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN_AUTH_MODE: function WPC_CB_LAN_AUTH_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.mode = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the lan authentication method
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN_AUTH_METHOD: function WPC_CB_LAN_AUTH_METHOD(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_LAN_AUTH_METHOD_PEAP_MSCHAPV2] = 'peap-mschapv2';
        mapping[wolfProtParameters.WPP_CB_LAN_AUTH_METHOD_TTLS_PAP] = 'ttls-pap';

        receivedData.authMethod = mapping[inFrame[4]];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the lan anonymous identity
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN_ANONYMOUS_IDENTITY: function WPC_CB_LAN_ANONYMOUS_IDENTITY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.anonymousIdentity = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the lan anonymous identity
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN_IDENTITY: function WPC_CB_LAN_IDENTITY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.identity = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get lan password reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN_PASSWORD: function WPC_CB_LAN_PASSWORD(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.password = parseStringFromArray(inFrame,4,inFrame[3]);
        invokeCallbackOf(command ,receivedData);
    },

    /**
     * parses the get lan certificate mode reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN_CERT_MODE: function WPC_CB_LAN_CERT_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.mode = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get lan certificate file name reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN_CERT_NAME: function WPC_CB_LAN_CERT_NAME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.fileName = parseStringFromArray(inFrame,4, inFrame[3]);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get lan certificates reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN_CERT_FILE: function WPC_CB_LAN_CERT_FILE(inFrame, connection, command, invokeCallbackOf) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = parseValueFromArrayBigEndian(inFrame, 3, 2);
        var jsonString = parseStringFromArray(inFrame, 5, length);

        receivedData.certList = JSONService.parse(jsonString);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wlan certificates reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_CERTIFICATE_FILE: function WPC_CB_WLAN_CERTIFICATE_FILE(inFrame, connection, command, invokeCallbackOf) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = parseValueFromArrayBigEndian(inFrame, 3, 2);
        var jsonString = parseStringFromArray(inFrame, 5, length);

        receivedData.certList = JSONService.parse(jsonString);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the result of the get ping reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_PING: function WPC_CB_PING(inFrame, connection, command, invokeCallbackOf) {
        let receivedData = {};
        let mapping = {};

        mapping[wolfProtParameters.WPP_CB_PING_STATUS_STOPPED] = states.pingStates.stopped;
        mapping[wolfProtParameters.WPP_CB_PING_STATUS_STARTED] = states.pingStates.started;
        mapping[wolfProtParameters.WPP_CB_PING_STATUS_FAILED] = states.pingStates.failed;
        mapping[wolfProtParameters.WPP_CB_PING_STATUS_SUCCEEDED] = states.pingStates.succeeded;

        receivedData.status = mapping[inFrame[5]];

        const responseLength = parseValueFromArrayBigEndian(inFrame, 6, 2);
        receivedData.response = parseStringFromArray(inFrame, 8, responseLength - 1);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the annotation users list reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ANNOTATION_USERS: function WPC_CB_ANNOTATION_USERS(inFrame, connection, command, invokeCallbackOf) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = wpCalcDatalen(inFrame);
        var jsonString = parseStringFromArray(inFrame, wpCalcHeaderlen(inFrame), length);

        receivedData.userList = JSONService.parse(jsonString);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the annotation pin status reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ANNOTATION_PIN_STATUS: function WPC_CB_ANNOTATION_PIN_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var length = inFrame[3];

        if (inFrame[4] === 0x01) {
            receivedData.showPin = true;
            receivedData.pin = parseStringFromArray(inFrame, 5, length - 1);
        } else {
            receivedData.showPin = false;
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get the rms password mode reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ROOM_PASSWORD_MODE: function WPC_CB_ROOM_PASSWORD_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.passwordRequired = (inFrame[4] === wolfProtParameters.WPP_CB_PASSWORD_REQUIRED ? true : false);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wlan power reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_AP_POWER: function WPC_CB_WLAN_AP_POWER(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.power = inFrame[4];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get aux allow mirror override reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_AUX_MIRROR_ALLOW_OVERRIDE: function WPC_CB_AUX_MIRROR_ALLOW_OVERRIDE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.allow = inFrame[4] === wolfProtParameters.WPP_YES ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get aux mirror override reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_AUX_MIRROR_OVERRIDE: function WPC_CB_AUX_MIRROR_OVERRIDE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.override = inFrame[4];
        invokeCallbackOf(command, receivedData);
    },


    /**
     * parses the get https enable reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_FORCE_HTTPS: function WPC_CB_FORCE_HTTPS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.httpsEnabled = inFrame[4] === 0x01 ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get rtsp streaming mode enabled command and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_RTSP_MODE: function WPC_CB_RTSP_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.rtsp = inFrame[4] === 0x01 ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get rtp streaming mode enabled command and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_RTP_MODE: function WPC_CB_RTP_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.rtp = inFrame[4] === 0x01 ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get multicast streaming ttl command and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MULTICAST_TTL: function WPC_CB_MULTICAST_TTL(inFrame, connection, command, invokeCallbackOf) {
        const receivedData = {};
        const length = inFrame[3];

        if (length === 2) {
            receivedData.ttlHdmi1 = inFrame[4];
            receivedData.ttlHdmi2 = inFrame[5];
        } else {
            receivedData.ttl = inFrame[4];
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get unicast streaming ttl command and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_UNICAST_TTL: function WPC_CB_UNICAST_TTL(inFrame, connection, command, invokeCallbackOf) {
        const receivedData = {};
        const length = inFrame[3];

        if (length === 2) {
            receivedData.ttlHdmi1 = inFrame[4];
            receivedData.ttlHdmi2 = inFrame[5];
        } else {
            receivedData.ttl = inFrame[4];
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get line in local output command and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LINE_IN_LOCAL_OUTPUT: function WPC_CB_LINE_IN_LOCAL_OUTPUT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === 0x01 ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get line in remote output command and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LINE_IN_REMOTE_OUTPUT: function WPC_CB_LINE_IN_REMOTE_OUTPUT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === 0x01 ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get usb mic webconf only command and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_USB_MIC_WEBCONF_ONLY: function WPC_CB_USB_MIC_WEBCONF_ONLY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === 0x01 ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get usb mic enable command and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_USB_MIC_ENABLE: function WPC_CB_USB_MIC_ENABLE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === 0x01 ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get opencast certificate mode reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_OPENCAST_CERTIFICATE_MODE: function WPC_CB_OPENCAST_CERTIFICATE_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.mode = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get opencast certificate file name reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_OPENCAST_CERTIFICATE_NAME: function WPC_CB_OPENCAST_CERTIFICATE_NAME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.fileName = parseStringFromArray(inFrame,4, inFrame[3]);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get opencast certificates reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_OPENCAST_CERTIFICATE_FILE: function WPC_CB_OPENCAST_CERTIFICATE_FILE(inFrame, connection, command, invokeCallbackOf) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = parseValueFromArrayBigEndian(inFrame, 3, 2);
        var jsonString = parseStringFromArray(inFrame, 5, length);

        receivedData.certList = JSONService.parse(jsonString);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the rtsp streaming inteface
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_RTSP_URL_INTERFACE: function WPC_CB_RTSP_URL_INTERFACE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_STREAMING_INTERFACE_LAN1] = 'lan1';
        mapping[wolfProtParameters.WPP_STREAMING_INTERFACE_WLAN] = 'wlan';
        mapping[wolfProtParameters.WPP_STREAMING_INTERFACE_LAN2] = 'lan2';

        receivedData.interface = mapping[inFrame[4]];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get collaboration user visibility command and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_USER_VISIBILITY: function WPC_CB_USER_VISIBILITY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.visible = inFrame[4] === 0x01 ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the airplay pin status reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MIRROR_PIN_STATUS: function WPC_CB_MIRROR_PIN_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var length = inFrame[3];

        if (inFrame[4] === 0x00) {
            receivedData.showPin = false;
        } else {
            receivedData.pin = parseStringFromArray(inFrame, 5, length - 1);
            if (inFrame[4] === 0x01) {
                receivedData.showPin = true;
                receivedData.popPin = false;
            } else {
                receivedData.showPin = true;
                receivedData.popPin = true;
            }
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the airplay pin status reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MIRROR_PIN_DYNAMIC: function WPC_CB_MIRROR_PIN_DYNAMIC(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === 0x00 ? false : true;
        receivedData.resetTime = inFrame[5];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the hdmi2 pin status reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_PIN_STATUS_HDMI2: function WPC_CB_PIN_STATUS_HDMI2(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var length = inFrame[3];

        if (inFrame[4] === 0x01) {
            receivedData.showPin = true;
            receivedData.pin = parseStringFromArray(inFrame, 5, length - 1);
        } else {
            receivedData.showPin = false;
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get mixer meter
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MIXER_METER: function WPC_CB_MIXER_METER(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.left = inFrame[4];
        receivedData.right = inFrame[5];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get line in level
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LINE_IN_LEVEL: function WPC_CB_LINE_IN_LEVEL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.level = inFrame[4];
        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the rtsp streaming inteface
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MIC_STATUS: function WPC_CB_MIC_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_MIC_STATUS_DISABLED] = 'disabled';
        mapping[wolfProtParameters.WPP_CB_MIC_STATUS_WORKING] = 'working';
        mapping[wolfProtParameters.WPP_CB_MIC_STATUS_QUIET] = 'quiet';

        receivedData.status = mapping[inFrame[4]];
        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the user settings status and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_USER_SETTINGS_STATUS: function WPC_CB_USER_SETTINGS_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = parseValueFromArrayBigEndian(inFrame, 3, 2);
        var jsonString = parseStringFromArray(inFrame, 5, length);

        receivedData.settings = JSONService.parse(jsonString);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get lan priority
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN_PRIORITY: function WPC_CB_LAN_PRIORITY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.priority = inFrame[4] + 1;
        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get wlan priority
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_PRIORITY: function WPC_CB_WLAN_PRIORITY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.priority = inFrame[4] + 1;
        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get ssh enable and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_SSH_ENABLE: function WPC_CB_SSH_ENABLE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x00 ? false : true;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get usb mic volume
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_USB_MIC_VOLUME: function WPC_CB_USB_MIC_VOLUME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.volume = inFrame[4];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get webcast mode
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_WEBCAST_MODE: function WPC_CB_WEBCAST_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_WEBCAST_MODE_OFF] = 'off';
        mapping[wolfProtParameters.WPP_CB_WEBCAST_MODE_CUSTOM] = 'custom';
        mapping[wolfProtParameters.WPP_CB_WEBCAST_MODE_YOUTUBE] = 'youtube';
        mapping[wolfProtParameters.WPP_CB_WEBCAST_MODE_CUSTOM2] = 'custom';

        receivedData.mode = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get webcast streaming  mode (start/stop)
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WEBCAST_STREAMING_MODE: function WPC_CB_WEBCAST_STREAMING_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.mode = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get webcast custom urls
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_WEBCAST_CUSTOM_URLS: function WPC_CB_WEBCAST_CUSTOM_URLS(inFrame, connection, command, invokeCallbackOf) {
        const receivedData = {};

        let offset = 5;

        const lengthHdmi1 = parseValueFromArrayBigEndian(inFrame, offset, 2);
        offset += 2;

        receivedData.urlHdmi1 = parseStringFromArray(inFrame, offset, lengthHdmi1);
        offset += lengthHdmi1;

        const lengthExt = parseValueFromArrayBigEndian(inFrame, offset, 2);
        offset += 2;

        receivedData.urlExt = parseStringFromArray(inFrame, offset, lengthExt);
        offset += lengthExt;

        receivedData.extType = helper.findKeyFromValue(states.webcastExtTypes, inFrame[offset]);
        offset += 1;

        const lengthHdmi2 = parseValueFromArrayBigEndian(inFrame, offset, 2);
        offset += 2

        receivedData.urlHdmi2 = parseStringFromArray(inFrame, offset, lengthHdmi2);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get webcast youtube stream name
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_WEBCAST_YOUTUBE_STREAM_NAME: function WPC_CB_WEBCAST_YOUTUBE_STREAM_NAME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.streamName = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get webcast youtube login state
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_WEBCAST_YOUTUBE_LOGIN_STATE: function WPC_CB_WEBCAST_YOUTUBE_LOGIN_STATE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_WEBCAST_YOUTUBE_LOGIN_STATE_DISCONNECTED] = 'disconnected';
        mapping[wolfProtParameters.WPP_CB_WEBCAST_YOUTUBE_LOGIN_STATE_AUTHENTICATING] = 'authenticating';
        mapping[wolfProtParameters.WPP_CB_WEBCAST_YOUTUBE_LOGIN_STATE_AUTHENTICATED] = 'authenticated';
        mapping[wolfProtParameters.WPP_CB_WEBCAST_YOUTUBE_LOGIN_STATE_TOKEN_EXPIRED] = 'token-expired';
        mapping[wolfProtParameters.WPP_CB_WEBCAST_YOUTUBE_LOGIN_STATE_FAILED] = 'failed';

        receivedData.state = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get webcast youtube stream state
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_WEBCAST_YOUTUBE_STREAM_STATE: function WPC_CB_WEBCAST_YOUTUBE_STREAM_STATE(inFrame, connection, command, invokeCallbackOf) {
        // Combining Hdmi states to single state using value as priority ranking of youtubeStreamStates
        const state = helper.findKeyFromValue(states.youtubeStreamStates, Math.max(inFrame[4], inFrame[5]));

        invokeCallbackOf(command, { state });
    },

    /**
     * parses the get webcast state
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_WEBCAST_STATE: function WPC_CB_WEBCAST_STATE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        // Combining Hdmi states to single state using value as priority ranking: error > active > ready > disabled
        receivedData.stateHdmi = helper.findKeyFromValue(states.webcastStates, Math.max(inFrame[4], inFrame[7]));
        receivedData.stateExtIn = helper.findKeyFromValue(states.webcastStates, inFrame[5]);
        receivedData.stateExtOut = helper.findKeyFromValue(states.webcastStates, inFrame[6]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get webcast state
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_WEBCAST_ACTIVE_PROTOCOL: function WPC_CB_WEBCAST_ACTIVE_PROTOCOL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_WEBCAST_PROTOCOL_NONE] = 'none';
        mapping[wolfProtParameters.WPP_CB_WEBCAST_PROTOCOL_CUSTOM] = 'custom';
        mapping[wolfProtParameters.WPP_CB_WEBCAST_PROTOCOL_YOUTUBE] = 'youtube';
        mapping[wolfProtParameters.WPP_CB_WEBCAST_PROTOCOL_LCS] = 'lcs';

        receivedData.protocol = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the rms client configuration
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_RMS_CLIENT: function WPC_CB_RMS_CLIENT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};
        var mappingProt = {};
        var mappingWolfprot = {};
        var lengthName, lengthDescription, lengthCommand, lengthPassword;

        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_NONE] = 'none';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_POWER_ON] = 'powerOn';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_POWER_OFF] = 'powerOff';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_1] = 'button1';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_2] = 'button2';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_3] = 'button3';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_4] = 'button4';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_5] = 'button5';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_6] = 'button6';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_7] = 'button7';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_8] = 'button8';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_SCREEN_ON] = 'screenOn';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_SCREEN_OFF] = 'screenOff';

        mappingProt[wolfProtParameters.WPP_CB_RMS_CLIENT_PROT_UDP] = 'udp';
        mappingProt[wolfProtParameters.WPP_CB_RMS_CLIENT_PROT_TCP] = 'tcp';
        mappingProt[wolfProtParameters.WPP_CB_RMS_CLIENT_PROT_PJLINK] = 'PJLink';
        mappingProt[wolfProtParameters.WPP_CB_RMS_CLIENT_PROT_WOLFPROT] = 'wolfprot';

        mappingWolfprot[wolfProtParameters.WPP_CB_RMS_CLIENT_WOLFPROT_CMD_NONE] = 'none';
        mappingWolfprot[wolfProtParameters.WPP_CB_RMS_CLIENT_WOLFPROT_CMD_MIRRORING_PERM_ALLOW] = 'mirroring';

        receivedData.id = inFrame[5];
        receivedData.event = mapping[inFrame[6]];

        lengthName = inFrame[7];
        receivedData.name = {};
        receivedData.name = parseStringFromArray(inFrame, 8, lengthName);

        lengthDescription = inFrame[8 + lengthName];
        receivedData.description = {};
        receivedData.description = parseStringFromArray(inFrame, 9 + lengthName, lengthDescription);

        receivedData.prot = mappingProt[inFrame[9 + lengthName + lengthDescription]];

        var tmp  = 10 + lengthName + lengthDescription;
        receivedData.ip = ipv4AddressInt2String((inFrame[tmp] << 24) | (inFrame[tmp + 1] << 16) | (inFrame[tmp + 2] << 8) | (inFrame[tmp + 3]));

        receivedData.port = '' + ((inFrame[tmp + 4] << 8) | inFrame[tmp + 5]);

        lengthCommand = inFrame[tmp + 6];
        receivedData.cmd = {};
        receivedData.cmd = parseStringFromArray(inFrame, tmp + 7, lengthCommand);

        receivedData.enabled = inFrame[tmp + lengthCommand + 7] === 0x01 ? true : false;

        lengthPassword = inFrame[18 + lengthName + lengthDescription + lengthCommand];

        if (lengthPassword > 0) {
            receivedData.password = parseStringFromArray(inFrame, 19 + lengthName + lengthDescription + lengthCommand, lengthPassword);
        }

        receivedData.wolfprot = mappingWolfprot[inFrame[19 + lengthName + lengthDescription + lengthCommand + lengthPassword]];

        receivedData.delay = inFrame[20 + lengthName + lengthDescription + lengthCommand + lengthPassword];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the rms client configuration
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_RMS_CLIENT_ENABLED: function WPC_CB_RMS_CLIENT_ENABLED(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_NONE] = 'none';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_POWER_ON] = 'powerOn';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_POWER_OFF] = 'powerOff';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_1] = 'button1';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_2] = 'button2';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_3] = 'button3';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_4] = 'button4';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_5] = 'button5';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_6] = 'button6';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_7] = 'button7';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_8] = 'button8';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_SCREEN_ON] = 'screenOn';
        mapping[wolfProtParameters.WPP_CB_RMS_CLIENT_EVENT_SCREEN_OFF] = 'screenOff';

        receivedData.event = mapping[inFrame[5]];
        receivedData.enabled = inFrame[6] === 0x01;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get dhcp setting reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_DHCP: function WPC_CB_ETH2_DHCP(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.dhcpSetting = inFrame[4];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get ip address ethernet reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_IP: function WPC_CB_ETH2_IP(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.ipAddress = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get subnet mask ethernet reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_SUBNET: function WPC_CB_ETH2_SUBNET(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.subnetMask = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get gateway address ethernet reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_GATEWAY: function WPC_CB_ETH2_GATEWAY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.gatewayAddress = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get dns address ethernet reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_NAMESERVER1: function WPC_CB_ETH2_NAMESERVER1(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.dnsAddress = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get dns2 address ethernet reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_NAMESERVER2: function WPC_CB_ETH2_NAMESERVER2(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.dnsAddress = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get macaddress ethernet reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_MAC: function WPC_CB_ETH2_MAC(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.macAddress = parseStringFromArray(inFrame,4,inFrame[3]);
        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get dhcpState ethernet reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_DHCP_STATUS: function WPC_CB_ETH2_DHCP_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.dhcpState = inFrame[4];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get 2nd lan priority
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_PRIORITY: function WPC_CB_LAN2_PRIORITY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.priority = inFrame[4] + 1;
        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get 2nd lan authentication mode reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_AUTH_MODE: function WPC_CB_LAN2_AUTH_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.mode = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the 2nd lan authentication method
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_AUTH_METHOD: function WPC_CB_LAN2_AUTH_METHOD(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_LAN_AUTH_METHOD_PEAP_MSCHAPV2] = 'peap-mschapv2';
        mapping[wolfProtParameters.WPP_CB_LAN_AUTH_METHOD_TTLS_PAP] = 'ttls-pap';

        receivedData.authMethod = mapping[inFrame[4]];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the 2ndlan anonymous identity
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_ANONYMOUS_IDENTITY: function WPC_CB_LAN2_ANONYMOUS_IDENTITY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.anonymousIdentity = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the 2nd lan anonymous identity
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_IDENTITY: function WPC_CB_LAN2_IDENTITY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.identity = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get 2nd lan password reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_PASSWORD: function WPC_CB_LAN2_PASSWORD(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.password = parseStringFromArray(inFrame,4,inFrame[3]);
        invokeCallbackOf(command ,receivedData);
    },

    /**
     * parses the get 2nd lan certificate mode reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_CERT_MODE: function WPC_CB_LAN2_CERT_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.mode = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get 2nd lan certificate file name reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_CERT_NAME: function WPC_CB_LAN2_CERT_NAME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.fileName = parseStringFromArray(inFrame,4, inFrame[3]);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get 2nd lan certificates reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_CERT_FILE: function WPC_CB_LAN2_CERT_FILE(inFrame, connection, command, invokeCallbackOf) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = parseValueFromArrayBigEndian(inFrame, 3, 2);
        var jsonString = parseStringFromArray(inFrame, 5, length);

        receivedData.certList = JSONService.parse(jsonString);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the 2nd lan authentication method
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_MODE: function WPC_CB_LAN2_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_LAN2_MODE_VZ] = 'vz';
        mapping[wolfProtParameters.WPP_CB_LAN2_MODE_LAN] = 'lan';

        receivedData.mode = mapping[inFrame[4]];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the ethernet status
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LAN2_STATUS: function WPC_CB_LAN2_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_LAN_STATUS_DISCONNECTED] = 'disconnected';
        mapping[wolfProtParameters.WPP_LAN_STATUS_CONNECTED] = 'connected';
        mapping[wolfProtParameters.WPP_LAN_STATUS_SCANNING] = 'scanning';
        mapping[wolfProtParameters.WPP_LAN_STATUS_AUTHENTICATING] = 'authenticating';
        mapping[wolfProtParameters.WPP_LAN_STATUS_FAILED] = 'failed';

        receivedData.ethStatus = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the stream input configuration
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_STREAM_INPUT: function WPC_CB_STREAM_INPUT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var pos = wpCalcHeaderlen(inFrame);
        var blockLength = 0;
        var count = 0;
        var lengthName, lengthUrl;
        var mapping = {};
        var mappingType = {};

        mapping[wolfProtParameters.WPP_CB_STREAM_INPUT_NONE] = 'none';
        mapping[wolfProtParameters.WPP_CB_STREAM_INPUT_GENERIC] = 'generic';
        mapping[wolfProtParameters.WPP_CB_STREAM_INPUT_RTSP_RTP] = 'rtspRtp';
        mapping[wolfProtParameters.WPP_CB_STREAM_INPUT_RTSP_RTP_MULTICAST] = 'multicast';

        mappingType[wolfProtParameters.WPP_STREAM_INPUT_TYPE_STREAM] = 'stream';
        mappingType[wolfProtParameters.WPP_STREAM_INPUT_TYPE_CAMERA] = 'camera';
        mappingType[wolfProtParameters.WPP_STREAM_INPUT_TYPE_COMPUTER] = 'computer';
        mappingType[wolfProtParameters.WPP_STREAM_INPUT_TYPE_VISUALIZER] = 'visualizer';
        mappingType[wolfProtParameters.WPP_STREAM_INPUT_TYPE_HDMI] = 'hdmi';
        mappingType[wolfProtParameters.WPP_STREAM_INPUT_TYPE_DISCPLAYER] = 'discPlayer';
        mappingType[wolfProtParameters.WPP_STREAM_INPUT_TYPE_CYNAP] = 'cynap';

        receivedData.list = [];

        while (pos < inFrame.length) {
            var stream = {};

            blockLength = parseValueFromArrayBigEndian(inFrame, pos, 2);
            pos += 2;

            stream.index = inFrame[pos];
            pos++;

            stream.mode = mapping[inFrame[pos]];
            pos++;

            lengthName = inFrame[pos];
            pos++;

            stream.name = {};
            stream.name = parseStringFromArray(inFrame, pos, lengthName);
            pos += lengthName;

            lengthUrl = inFrame[pos];
            pos++;

            stream.url = {};
            stream.url = parseStringFromArray(inFrame, pos, lengthUrl);
            pos += lengthUrl;

            stream.type = mappingType[inFrame[pos]];
            pos++;

            stream.lowlatency = inFrame[pos];
            pos++;

            stream.mute = inFrame[pos] === 0x00 ? false : true;
            pos++;

            stream.inUse = inFrame[pos] === 0x00 ? true : false;
            pos++;

            receivedData.list[count] = stream;
            count++;
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the recording stream input configuration
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_RECORDING_STREAM_INPUT: function WPC_CB_RECORDING_STREAM_INPUT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};
        var lengthUrl;

        mapping[wolfProtParameters.WPP_CB_STREAM_INPUT_NONE] = 'none';
        mapping[wolfProtParameters.WPP_CB_STREAM_INPUT_GENERIC] = 'generic';
        mapping[wolfProtParameters.WPP_CB_STREAM_INPUT_RTSP_RTP] = 'rtspRtp';
        mapping[wolfProtParameters.WPP_CB_STREAM_INPUT_RTSP_RTP_MULTICAST] = 'multicast';

        receivedData.mode = mapping[inFrame[5]];
        receivedData.audio = inFrame[6] === 0x00 ? false : true;

        receivedData.offset = ((inFrame[7] << 8) | inFrame[8]);

        lengthUrl = inFrame[9];
        receivedData.url = {};
        receivedData.url = parseStringFromArray(inFrame, 10, lengthUrl);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the recording trigger and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_RECORDING_TRIGGER: function WPC_CB_RECORDING_TRIGGER(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_RECORDING_TRIGGER_NONE] = 'none';
        mapping[wolfProtParameters.WPP_CB_RECORDING_TRIGGER_MANUAL] = 'manual';
        mapping[wolfProtParameters.WPP_CB_RECORDING_TRIGGER_OPENCAST] = 'opencast';
        mapping[wolfProtParameters.WPP_CB_RECORDING_TRIGGER_PANOPTO] = 'panopto';
        mapping[wolfProtParameters.WPP_CB_RECORDING_TRIGGER_PANOPTO_AUTO] = 'panoptoAuto';

        receivedData.trigger = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the recording stream input health status and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_RECORDING_STREAM_INPUT_HEALTH: function WPC_CB_RECORDING_STREAM_INPUT_HEALTH(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_RECORDING_STREAM_INPUT_HEALTH_NONE] = 'none';
        mapping[wolfProtParameters.WPP_CB_RECORDING_STREAM_INPUT_HEALTH_OK] = 'ok';
        mapping[wolfProtParameters.WPP_CB_RECORDING_STREAM_INPUT_HEALTH_ERROR] = 'error';

        receivedData.status = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the usb device list reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_USB_DEVICE_LIST: function WPC_CB_USB_DEVICE_LIST(inFrame, connection, command, invokeCallbackOf) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = parseValueFromArrayBigEndian(inFrame, 3, 2);
        var jsonString = parseStringFromArray(inFrame, 5, length);

        receivedData.usbDeviceList = JSONService.parse(jsonString);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get app recording enabled reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_APP_RECORDING: function WPC_CB_APP_RECORDING(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get webconference recording enable reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WEBCONFERENCE_ENABLE_RECORDING: function WPC_CB_WEBCONFERENCE_ENABLE_RECORDING(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get hdmi output swap reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_SWAP_HDMI_OUTPUTS: function WPC_CB_SWAP_HDMI_OUTPUTS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.swap = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get webconference handling reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WEBCONFERENCE_HDMI2: function WPC_CB_WEBCONFERENCE_HDMI2(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.hdmi2 = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get system language reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_SYSTEM_LANGUAGE: function WPC_CB_SYSTEM_LANGUAGE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_SYSTEM_LANGUAGE_ENGLISH] = 'en';
        mapping[wolfProtParameters.WPP_CB_SYSTEM_LANGUAGE_GERMAN] = 'de';
        mapping[wolfProtParameters.WPP_CB_SYSTEM_LANGUAGE_FRENCH] = 'fr';
        mapping[wolfProtParameters.WPP_CB_SYSTEM_LANGUAGE_CHINESE] = 'zh';
        mapping[wolfProtParameters.WPP_CB_SYSTEM_LANGUAGE_JAPANESE] = 'ja';
        mapping[wolfProtParameters.WPP_CB_SYSTEM_LANGUAGE_NORWEGIAN] = 'nor';
        mapping[wolfProtParameters.WPP_CB_SYSTEM_LANGUAGE_RUSSIAN] = 'ru';

        receivedData.language = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get timezone UTC offset in minutes reply and calls the given callback.
     *
     * NOTE: "The offset is positive if the local timezone is west of the Prime Meridian and
     * negative if it is east." http://man7.org/linux/man-pages/man3/tzset.3.html
     * E.g. America/Los Angeles (GMT-8): We get +480 Minutes.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_TIMEZONE_UTC_OFFSET_MINUTES: function WPC_TIMEZONE_UTC_OFFSET_MINUTES(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.offset = parseSignedValueFromArray(inFrame, 4);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get model reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MODEL: function WPC_CB_MODEL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.model = parseStringFromArray(inFrame, 5, inFrame[4]);
        receivedData.hwType = inFrame[5 + inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the usb mic card list configuration
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_USB_MIC_CARD_LIST: function WPC_CB_USB_MIC_CARD_LIST(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var pos = 5;
        var card;
        var count = 0;

        receivedData.cardList = [];

        while (pos < inFrame.length)
        {
            var len = inFrame[pos];
            card = parseStringFromArray(inFrame, pos + 1, len);
            pos += len + 1;
            receivedData.cardList[count] = card;
            count +=1;
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get usb mic card name reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_USB_MIC_CARD_NAME: function WPC_CB_USB_MIC_CARD_NAME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.cardName = parseStringFromArray(inFrame, 4, inFrame[3]);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the Airplay bluetooth device discovery reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_AIRPLAY_BLE_DISCOVERY: function WPC_CB_AIRPLAY_BLE_DISCOVERY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enable = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the Airplay interface reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_AIRPLAY_INTERFACE: function WPC_CB_AIRPLAY_INTERFACE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_AIRPLAY_INTERFACE_LAN1] = 'lan1';
        mapping[wolfProtParameters.WPP_CB_AIRPLAY_INTERFACE_LAN2] = 'lan2';
        mapping[wolfProtParameters.WPP_CB_AIRPLAY_INTERFACE_WLAN] = 'wlan';

        receivedData.interface = mapping[inFrame[4]];
        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the usb fw update status
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_USB_FW_UPDATE_STATUS: function WPC_CB_USB_FW_UPDATE_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_USB_FW_NO_FILE_FOUND] = 'none';
        mapping[wolfProtParameters.WPP_CB_USB_FW_FILE_FOUND] = 'found';

        receivedData.state = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the Airplay bluetooth device discovery reply and calls the given callback
     * parses the get office 365 function reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_OFFICE365_FUNCTION: function WPC_CB_OFFICE365_FUNCTION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.function = inFrame[4] === 0x00 ? false : true;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the uvc device list
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_UVC_DEVICE_LIST_3: function WPC_CB_UVC_DEVICE_LIST_3(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var pos = wpCalcHeaderlen(inFrame);
        var count = 0;

        receivedData.uvcDeviceList = [];

        while (pos < inFrame.length)
        {
            var device = {};
            var lenName = inFrame[pos];
            device.name = parseStringFromArray(inFrame, pos + 1, lenName);
            pos += lenName + 1;
            device.autostartSupport = inFrame[pos];
            pos += 1;

            var lenVideoModesJson = inFrame[pos + 0] << 24 | inFrame[pos + 1] << 16 | inFrame[pos + 2] << 8 | inFrame[pos + 3] << 0;
            var videoModesJson = parseStringFromArray(inFrame, pos + 4, lenVideoModesJson);
            device.videoModes = JSON.parse(videoModesJson);
            pos += lenVideoModesJson + 4;

            receivedData.uvcDeviceList[count] = device;
            count +=1;
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the uvc device input
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_UVC_INPUT_3: function WPC_CB_UVC_INPUT_3(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};
        var lengthName, lengthDevice, lengthVideoModeJson;
        var videoModeJson;
        var pos;
        var mappingAutostart = {};

        mapping[wolfProtParameters.WPP_UVC_INPUT_NONE] = 'none';
        mapping[wolfProtParameters.WPP_UVC_INPUT_CAMERA] = 'camera';
        mapping[wolfProtParameters.WPP_UVC_INPUT_COMPUTER] = 'computer';
        mapping[wolfProtParameters.WPP_UVC_INPUT_DISCPLAYER] = 'discPlayer';
        mapping[wolfProtParameters.WPP_UVC_INPUT_HDMI] = 'hdmi';
        mapping[wolfProtParameters.WPP_UVC_INPUT_VZ] = 'visualizer';

        mappingAutostart[wolfProtParameters.WPP_UVC_INPUT_AUTOSTART_NONE] = 'none';
        mappingAutostart[wolfProtParameters.WPP_UVC_INPUT_AUTOSTART_OFF] = 'off';
        mappingAutostart[wolfProtParameters.WPP_UVC_INPUT_AUTOSTART_ON] = 'on';

        receivedData.index = inFrame[5];
        receivedData.type = mapping[inFrame[6]];
        receivedData.useForWebRTC = inFrame[7];

        lengthName = inFrame[8];
        pos = 9;

        receivedData.name = {};
        receivedData.name = parseStringFromArray(inFrame, pos, lengthName);
        pos = 9 + lengthName;

        lengthDevice = inFrame[pos];
        pos += 1;

        receivedData.device = {};
        receivedData.device = parseStringFromArray(inFrame, pos, lengthDevice);
        pos += lengthDevice;

        receivedData.audio = inFrame[pos] === wolfProtParameters.WPP_ON ? true : false;
        pos += 1;

        receivedData.autostart = mappingAutostart[inFrame[pos]];
        pos += 1;

        lengthVideoModeJson = inFrame[pos];
        pos += 1;

        videoModeJson = parseStringFromArray(inFrame, pos, lengthVideoModeJson);
        receivedData.videoMode = JSON.parse(videoModeJson);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get proxy function reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_PROXY_FUNCTION: function WPC_CB_PROXY_FUNCTION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.function = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get proxy url reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_PROXY_URL: function WPC_CB_PROXY_URL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.url = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get proxy port reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_PROXY_PORT: function WPC_CB_PROXY_PORT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.port = '' + ((inFrame[4] << 8) | inFrame[5]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get proxy authentication mode reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_PROXY_AUTH_MODE: function WPC_CB_PROXY_AUTH_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.mode = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get proxy username reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_PROXY_USERNAME: function WPC_CB_PROXY_USERNAME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.username = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get proxy password reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_PROXY_PASSWORD: function WPC_CB_PROXY_PASSWORD(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.password = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get startpage url reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_BROWSER_STARTPAGE: function WPC_CB_BROWSER_STARTPAGE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.url = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get browser shortcuts config reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_BROWSER_SHORTCUTS_CONFIG: function WPC_BROWSER_SHORTCUTS_CONFIG(inFrame, connection, command, invokeCallbackOf) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = wpCalcDatalen(inFrame);
        var jsonString = parseStringFromArray(inFrame, wpCalcHeaderlen(inFrame), length);

        receivedData = JSONService.parse(jsonString);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the bookmark list
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_BOOKMARK_LIST: function WPC_CB_BOOKMARK_LIST(inFrame, connection, command, invokeCallbackOf) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = wpCalcDatalen(inFrame);
        var jsonString = parseStringFromArray(inFrame, wpCalcHeaderlen(inFrame), length);

        receivedData.list = JSONService.parse(jsonString);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parse the download link list
     *
     * @param inFrame the received data
     * @param connection the websocket connection
     * @param invokeCallbackOf the callback to where the command is passed
     */
    WPC_CB_DOWNLOAD_LINK_LIST: function WPC_CB_DOWNLOAD_LINK_LIST(inFrame, connection, command, invokeCallbackOf) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = wpCalcDatalen(inFrame);
        var jsonString = parseStringFromArray(inFrame, wpCalcHeaderlen(inFrame), length);

        receivedData.list = JSONService.parse(jsonString);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the url interface for the qr-codes
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_URL_INTERFACE: function WPC_CB_URL_INTERFACE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_URL_INTERFACE_LAN1] = 'lan1';
        mapping[wolfProtParameters.WPP_CB_URL_INTERFACE_LAN2] = 'lan2';
        mapping[wolfProtParameters.WPP_CB_URL_INTERFACE_WLAN] = 'wlan';

        receivedData.interface = mapping[inFrame[4]];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * Parses the sdp mode for the stream (static or unique dynamic link)
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_STREAM_SDP_MODE: function WPC_CB_STREAM_SDP_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_STREAM_SDP_MODE_STATIC] = 'static';
        mapping[wolfProtParameters.WPP_CB_STREAM_SDP_MODE_DYNAMIC] = 'dynamic';

        receivedData.mode = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the content mode recording source
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CONTENT_MODE_REC_SRC: function WPC_CB_CONTENT_MODE_REC_SRC(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_CONTENT_MODE_REC_SRC_MAIN] = 'main';
        mapping[wolfProtParameters.WPP_CB_CONTENT_MODE_REC_SRC_AUX] = 'aux';

        receivedData.mode = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get lan mirroring enable reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MIRRORING_LAN_ENABLE: function WPC_CB_MIRRORING_LAN_ENABLE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === 0x00 ? false : true;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get event list reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_EVENTS: function WPC_CB_EVENTS(inFrame, connection, command, invokeCallbackOf) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var eventlistLength = parseValueFromArrayBigEndian(inFrame, 8, 4);
        var jsonString = parseStringFromArray(inFrame, 12, eventlistLength);

        receivedData.eventList = JSONService.parse(jsonString);
        receivedData.eventListLength = eventlistLength;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get lan2 mirroring enable reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MIRRORING_LAN2_ENABLE: function WPC_CB_MIRRORING_LAN2_ENABLE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === 0x00 ? false : true;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wlan mirroring enable reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MIRRORING_WLAN_ENABLE: function WPC_CB_MIRRORING_WLAN_ENABLE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === 0x00 ? false : true;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get screensaver state.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_SCREENSAVER_STATE: function WPC_CB_SCREENSAVER_STATE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_SCREENSAVER_STATE_OFF] = 'off';
        mapping[wolfProtParameters.WPP_CB_SCREENSAVER_STATE_SCREENSAVER] = 'screensaver';
        mapping[wolfProtParameters.WPP_CB_SCREENSAVER_STATE_SCREEN_OFF] = 'screen-off';
        mapping[wolfProtParameters.WPP_CB_SCREENSAVER_STATE_STANDBY] = 'standby';

        receivedData.state = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get screensaver settings.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_SCREENSAVER_SETTINGS: function WPC_CB_SCREENSAVER_SETTINGS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.standby = inFrame[8] === 0x00 ? false : true;
        receivedData.screenOff = inFrame[9] === 0x00 ? false : true;
        receivedData.screensaver = inFrame[10] === 0x00 ? false : true;

        receivedData.delayStandby = ((inFrame[11] << 8) | inFrame[12]);
        receivedData.delayScreenOff = ((inFrame[13] << 8) | inFrame[14]);

        var urlLength = ((inFrame[15] << 8) | inFrame[16]);
        receivedData.url = parseStringFromArray(inFrame, 17, urlLength);

        var pos = 17 + urlLength;
        receivedData.autoScreensaver = inFrame[pos];
        receivedData.keepFilesAutoScreensaver = inFrame[pos + 1];
        receivedData.delayAutoScreensaver = ((inFrame[pos + 2] << 8) | inFrame[pos + 3]);
        receivedData.interactiveMode = inFrame[pos + 4] === 0x00 ? false : true;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get screen off when no content open setting and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_SCREEN_OFF_NO_CONTENT: function WPC_CB_SCREEN_OFF_NO_CONTENT(inFrame, connection, command, invokeCallbackOf) {
        let receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get local screensaver status.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_LOCAL_SCREENSAVER: function WPC_CB_LOCAL_SCREENSAVER(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_LOCAL_SCREENSAVER_NOT_AVAILABLE] = states.localScreensaverStates.notAvailable;
        mapping[wolfProtParameters.WPP_CB_LOCAL_SCREENSAVER_AVAILABLE] = states.localScreensaverStates.available;

        receivedData.state = mapping[inFrame[4]];
        receivedData.name = parseStringFromArray(inFrame,6, inFrame[5]);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get ip address ethernet reply and calls the given callback.
     *
     * @param Uint8Array inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_SERIALNUMBER: function WPC_CB_SERIALNUMBER(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.serialNumber = (inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get active session reply and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_SESSION_ACTIVE: function WPC_CB_SESSION_ACTIVE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var length = parseValueFromArrayBigEndian(inFrame, 5, 2);
        var eventId = parseStringFromArray(inFrame, 7, length);

        receivedData.id = eventId;

        if (receivedData.id.length === 0 || (isNaN(receivedData.id) && !eventId.includes('x'))) {
            receivedData.id = 0;
        } else {
            receivedData.id = receivedData.id.replace(/ /g,'');
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get wlan signal level limit reply and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_SIGNAL_LEVEL_LIMIT: function WPC_CB_WLAN_SIGNAL_LEVEL_LIMIT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.signalLevel = inFrame[4] > 127 ? inFrame[4] - 256 : inFrame[4];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get pin reply and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_SUPPORT_PIN: function WPC_CB_SUPPORT_PIN(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.status = inFrame[4];
        receivedData.pin = parseStringFromArray(inFrame, 6, 8);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the picture header
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_PICTUREHEADER: function WPC_PICTUREHEADER(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.width = parseValueFromArrayBigEndian(inFrame, 3, 2);
        receivedData.height = parseValueFromArrayBigEndian(inFrame, 5, 2);
        receivedData.formatMode = inFrame[8];
        receivedData.formatFlags = inFrame[7];
        receivedData.maxBlockSize = parseValueFromArrayBigEndian(inFrame, 9, 4);
        receivedData.extendedByte1 = inFrame[13];
        receivedData.reserved = inFrame[14];
        receivedData.picSize = parseValueFromArrayBigEndian(inFrame, 15, 4);
        receivedData.memPage = parseValueFromArrayBigEndian(inFrame, 19, 2);
        receivedData.offset16by9 = parseValueFromArrayBigEndian(inFrame, 21, 2);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the picture block
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_PICTUREBLOCK2: function WPC_PICTUREBLOCK2(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        var length = parseValueFromArrayBigEndian(inFrame, 3, 4);

        if (length > 0 && inFrame.length === (length + 7)) {
            receivedData.picture = inFrame.subarray(7);
        } else {
            receivedData = null;
        }

        // receivedData.width = parseValueFromArrayBigEndian(inFrame, 3, 2);
        // receivedData.height = parseValueFromArrayBigEndian(inFrame, 5, 2);
        // receivedData.formatFlags = inFrame[8];
        // receivedData.formatMode = inFrame[7];
        // receivedData.maxBlockSize = parseValueFromArrayBigEndian(inFrame, 9, 4);
        // receivedData.extendedByte1 = inFrame[13];
        // receivedData.reserved = inFrame[14];
        // receivedData.picSize = parseValueFromArrayBigEndian(inFrame, 15, 4);
        // receivedData.memPage = parseValueFromArrayBigEndian(inFrame, 19, 2);
        // receivedData.offset16by9 = parseValueFromArrayBigEndian(inFrame, 21, 2);

        if (receivedData) {
            invokeCallbackOf(command, receivedData);
        }
    },

    /**
     * Parses the get echo cancel status and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ECHO_CANCEL: function WPC_CB_ECHO_CANCEL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get echo cancel delay and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ECHO_CANCEL_DELAY: function WPC_CB_ECHO_CANCEL_DELAY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.delay = (inFrame[4] << 8) | inFrame[5];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get master discovered devices and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_MASTER_DISCOVERED_DEVICES: function WPC_CB_MATRIX_MASTER_DISCOVERED_DEVICES(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var blockLength = 0;
        var currentPosition = 5;
        var totalLength = (inFrame[3] << 8) + inFrame[4];
        var temp = inFrame.subarray(currentPosition);
        var m = 0;

        receivedData.deviceList = [];

        while ((totalLength + 5) > currentPosition) {
            var device = {id: '', matrixSupport: 0, masterMode: 0, stationMode: 0, serial: 0, ip: '', version: '', devname: '', model: ''};
            blockLength = (temp[0] << 8) + temp[1];

            if (blockLength > 0) {
                device.matrixSupport = ((temp[2] & 0x01) > 0 ? true : false);
                device.masterMode = ((temp[2] & 0x02) > 0 ? true : false);
                device.stationMode = ((temp[2] & 0x04) > 0 ? true : false);
                device.serial = ((temp[3] << 24) + (temp[4] << 16) + (temp[5] << 8) + temp[6]);
                device.id = 'sn' + device.serial;
                device.ip = temp[10].toString() + '.' + temp[9].toString() + '.' + temp[8].toString() + '.' + temp[7].toString();

                for (var i = 0; i < temp[11]; i++) {
                    device.version += String.fromCharCode(temp[i + 12]);
                }

                var n = i + 12;
                for (i = 0; i < ((temp[n] << 8) + temp[n + 1]); i++) {
                    device.devname += String.fromCharCode(temp[i + n + 2]);
                }

                n += i + 2;
                for (i = 0; i < temp[n]; i++) {
                    device.model += String.fromCharCode(temp[i + n + 1]);
                }

                receivedData.deviceList[m] = device;
                m++;
            }

            currentPosition += blockLength + 2;
            temp = inFrame.subarray(currentPosition);
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get master setup stations and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_MASTER_SETUP_STATIONS: function WPC_CB_MATRIX_MASTER_SETUP_STATIONS(inFrame, connection, command, invokeCallbackOf) {
        var totalLength = (inFrame[3] << 8) + inFrame[4];
        var currentPosition = 5;
        var temp = inFrame.subarray(currentPosition);
        var receivedData = {};
        var m = 0;

        receivedData.stationList = [];
        receivedData.audioEnableList = [];

        while((totalLength + 5) > currentPosition)
        {
            var station = ((temp[0] << 24) + (temp[1] << 16) + (temp[2] << 8) + temp[3]);

            receivedData.stationList.push(station);
            receivedData.audioEnableList.push(temp[6]);

            currentPosition += 7;
            temp = inFrame.subarray(currentPosition);
            m++;
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get master status calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_GROUPWORK_STATIONS_STATUS: function WPC_CB_MATRIX_GROUPWORK_STATIONS_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var blockStart = 0;
        var blockLength = 0;
        var pos = 5;
        var totalLength = parseValueFromArrayBigEndian(inFrame, 3, 2);
        var m = 0;

        var compabilityMapping = {};
        compabilityMapping[wolfProtParameters.WPP_CB_MATRIX_COMPABILITY_OK] = 'ok';
        compabilityMapping[wolfProtParameters.WPP_CB_MATRIX_COMPABILITY_LEGACY_STATION] = 'legacy-station';
        compabilityMapping[wolfProtParameters.WPP_CB_MATRIX_COMPABILITY_OUTDATED_STATION] = 'outdated-station';
        compabilityMapping[wolfProtParameters.WPP_CB_MATRIX_COMPABILITY_OUTDATED_MASTER] = 'outdated-master';

        var sentReqStatusMapping = {};
        sentReqStatusMapping[wolfProtParameters.WPP_CB_MATRIX_GROUPWORK_SENT_REQ_STATUS_REJECTED] = 'rejected';
        sentReqStatusMapping[wolfProtParameters.WPP_CB_MATRIX_GROUPWORK_SENT_REQ_STATUS_WAITING] = 'waiting';
        sentReqStatusMapping[wolfProtParameters.WPP_CB_MATRIX_GROUPWORK_SENT_REQ_STATUS_TIMEOUT] = 'timeout';
        sentReqStatusMapping[wolfProtParameters.WPP_CB_MATRIX_GROUPWORK_SENT_REQ_STATUS_ACCEPTED] = 'accepted';

        var receivedReqStatusMapping = {};
        receivedReqStatusMapping[wolfProtParameters.WPP_CB_MATRIX_GROUPWORK_RECEIVED_REQ_STATUS_NOT_WAITING] = 'not-waiting';
        receivedReqStatusMapping[wolfProtParameters.WPP_CB_MATRIX_GROUPWORK_RECEIVED_REQ_STATUS_WAITING] = 'waiting';

        receivedData.statusList = [];

        while ((totalLength + 5) > pos) {
            var status = {
                present: 0,
                mode: 0,
                serial: 0,
                stationName: '',
                push: 0,
                pull: 0,
                compatibility: -1,
                sentReqStatus: 0,
                receivedReqStatus: 0
            };

            blockStart = pos;
            blockLength = parseValueFromArrayBigEndian(inFrame, pos, 2);
            pos += 2;

            status.present = inFrame[pos];
            pos ++;

            status.mode = inFrame[pos];
            pos ++;

            status.serial = parseValueFromArrayBigEndian(inFrame,pos, 4);
            pos += 4;

            for (var i = 0; i < ((inFrame[pos] << 8) + inFrame[pos+1]); i++) {
                status.stationName += String.fromCharCode(inFrame[pos + 2 + i]);
            }
            pos += status.stationName.length + 2;

            status.push = inFrame[pos];
            pos++;

            status.pull = inFrame[pos];
            pos++;

            status.compatibility = compabilityMapping[inFrame[pos]];
            pos++;

            status.sentReqStatus = sentReqStatusMapping[inFrame[pos]];
            pos++;

            status.receivedReqStatus = receivedReqStatusMapping[inFrame[pos]];
            pos++;

            pos = blockStart + blockLength + 2;
            receivedData.statusList[m] = status;
            m++;
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get master status calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_MASTER_STATUS: function WPC_CB_MATRIX_MASTER_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var blockStart = 0;
        var blockLength = 0;
        var pos = 5;
        var totalLength = parseValueFromArrayBigEndian(inFrame, 3, 2);
        var m = 0;

        const outputMapping = getOutputMapping();

        receivedData.statusList = [];
        receivedData.coaching = {
            dest: undefined,
            src: undefined
        };

        while ((totalLength + 5) > pos) {
            var status = {
                present: 0,
                serial: 0,
                stationName: '',
                pull: 0,
                push: 0,
                masterName: '',
                compatibility: -1,
                pushAudio: 0,
                pushIndex: undefined,
                accessLevel: 0,
                control: 0
            };

            blockStart = pos;
            blockLength = parseValueFromArrayBigEndian(inFrame, pos, 2);
            pos += 2;

            if (blockLength > 0) {
                status.present = inFrame[pos];
                pos++;
                status.serial = parseValueFromArrayBigEndian(inFrame,pos, 4);
                pos += 4;

                for (var i = 0; i < ((inFrame[pos] << 8) + inFrame[pos+1]); i++) {
                    status.stationName += String.fromCharCode(inFrame[pos + 2 + i]);
                }
                pos += status.stationName.length + 2;

                status.pull = inFrame[pos];
                pos++;
                status.push = inFrame[pos];
                pos++;

                for (var j = 0; j < ((inFrame[pos] << 8) + inFrame[pos + 1]); j++){
                    status.masterName += String.fromCharCode(inFrame[pos + j + 2]);
                }
                pos += status.masterName.length + 2;

                status.pushAudio = inFrame[pos];
                pos++;

                /**
                 * 0 .. OK
                 * 1 .. legacy station
                 * 2 .. outdated station
                 * 3 .. outdated master
                 */
                status.compatibility = inFrame[pos];
                pos++;

                /**
                 * 0/1/2/3 .. index of stream input
                 * 255 .. master
                 * 254 .. station to station (src)
                 * 253 .. station to station (dest)
                 */
                status.pushIndex = inFrame[pos];
                pos++;

                switch (status.pushIndex) {
                    case 253:
                        receivedData.coaching.dest = status.serial;
                        break;
                    case 254:
                        receivedData.coaching.src = status.serial;
                        break;
                }

                status.accessLevel = inFrame[pos];
                pos++;

                status.control = inFrame[pos];
                pos++;

                status.pullOutput = outputMapping[inFrame[pos]];
                pos++;

                pos = blockStart + blockLength + 2;
                receivedData.statusList[m] = status;
                m++;
            }
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get matrix master mode status and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_MASTER_MODE: function WPC_CB_MATRIX_MASTER_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get matrix groupwork mode status and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_GROUPWORK_MODE: function WPC_CB_MATRIX_GROUPWORK_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parse the get matrix groupwork forced status and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_MATRIX_GROUPWORK_FORCED: function WPC_CB_MATRIX_GROUPWORK_FORCED(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.active = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the stations groupwork state and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_STATION_GROUPWORK_STATUS: function WPC_CB_MATRIX_STATION_GROUPWORK_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_DISABLED] = 'inactive';
        mapping[wolfProtParameters.WPP_CB_ENABLED] = 'active';

        receivedData.status = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get matrix master preview picture calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_MASTER_PREVIEW: function WPC_CB_MATRIX_MASTER_PREVIEW(inFrame, connection, command, invokeCallbackOf) {
        var temp = inFrame.subarray(8);
        var receivedData = { serial:0, width:0, height:0, data:[]};

        receivedData.serial = ((temp[0] << 24) + (temp[1] << 16) + (temp[2] << 8) + temp[3]);
        receivedData.width = ((temp[4] << 8) + temp[5]);
        receivedData.height = ((temp[6] << 8) + temp[7]);
        receivedData.picture = temp.subarray(8);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get matrix station status and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_MATRIX_STATION_STATUS: function WPC_CB_MATRIX_STATION_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var length = parseValueFromArrayBigEndian(inFrame, 7, 2);

        receivedData.stationMode = inFrame[5] === 0x01 ? true : false;
        receivedData.pushStatus = inFrame[6] === 0x01 ? true : false;
        receivedData.stationName = parseStringFromArray(inFrame, 9, length);
        receivedData.audioStatus = inFrame[9 + length];
        receivedData.pushIndex = inFrame[10 + length];

        var streamNameLength = parseValueFromArrayBigEndian(inFrame, 11 + length, 2);
        receivedData.streamName = parseStringFromArray(inFrame, 13 + length, streamNameLength);

        receivedData.accessLevel = inFrame[13 + length + streamNameLength];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get matrix master annotation.
     * (If annotation is enabled on master station)
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_MATRIX_MASTER_ANNOTATION: function WPC_CB_MATRIX_MASTER_ANNOTATION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_MATRIX_MASTER_ANNOTATION_DISABLED] = 'disable';
        mapping[wolfProtParameters.WPP_CB_MATRIX_MASTER_ANNOTATION_ENABLED] = 'enable';
        mapping[wolfProtParameters.WPP_CB_MATRIX_MASTER_ANNOTATION_PAUSED] = 'pause';

        receivedData.status = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get matrix master annotation collaboration.
     * (If annotation collaboration is enabled on master station)
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_MATRIX_MASTER_ANNOTATION_COLLAB: function WPC_CB_MATRIX_MASTER_ANNOTATION_COLLAB(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === 0x00 ? false : true;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get admin-pin and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_ADMIN_PIN_STATUS: function WPC_CB_ADMIN_PIN_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var pinStatus = false;

        if (inFrame[4] === wolfProtParameters.WPP_ADMIN_PIN_ENABLED) {
            pinStatus = true;
        }

        receivedData.status = pinStatus;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get admin-pin only while presenting and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_ADMIN_PIN_ONLY_WHILE_PRESENTING: function WPC_CB_ADMIN_PIN_ONLY_WHILE_PRESENTING(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the lectrue captrue settings get/set method.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_LECTURE_CAPTURE_SETTINGS: function WPC_CB_LECTURE_CAPTURE_SETTINGS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.type = inFrame[4];
        receivedData.mode = inFrame[5];
        receivedData.startHour = inFrame[6];
        receivedData.startMinute = inFrame[7];
        receivedData.path = inFrame[8];
        receivedData.ipCameraMode = inFrame[9];
        receivedData.filenameLength = inFrame[10];
        receivedData.filename = parseStringFromArray(inFrame, 11, inFrame[10]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the lectrue captrue settings get/set method.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_LECTURE_CAPTURE_START_RECORDING: function WPC_CB_LECTURE_CAPTURE_START_RECORDING(
        inFrame,
        connection,
        command,
        invokeCallbackOf
    ) {
        var receivedData = {};
        var uploadFolderIdLength = inFrame[6];
        var uploadFolderIdPos = 7;
        var filenameLength = inFrame[8 + uploadFolderIdLength];
        var filenamePos = inFrame[9 + uploadFolderIdLength];

        receivedData.type = inFrame[4];
        receivedData.ipCameraMode = inFrame[5];
        receivedData.uploadFolderIdLength = uploadFolderIdLength;
        receivedData.uploadFolderId = inFrame[uploadFolderIdPos];
        receivedData.filenameLength = filenameLength;
        receivedData.filename = parseStringFromArray(inFrame, filenamePos, filenameLength);

        invokeCallbackOf(command, receivedData);
    },

    WPC_CB_DISK_ENCRYPTION_ENABLE: function WPC_CB_DISK_ENCRYPTION_ENABLE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_DISK_ENCRYPTION_MODE_ON] = states.encryptionEnable.true;
        mapping[wolfProtParameters.WPP_CB_DISK_ENCRYPTION_MODE_OFF] = states.encryptionEnable.false;

        receivedData.enabled = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the panopto settings get/set method.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param command
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_PANOPTO_SETTINGS: function WPC_CB_PANOPTO_SETTINGS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var hostnameLengthPos = 5;
        var hostnameLength = inFrame[hostnameLengthPos];
        var usernameLengthPos = 6 + hostnameLength;
        var usernameLength = inFrame[usernameLengthPos];
        var passwordLengthPos = 7 + hostnameLength + usernameLength;
        var passwordLength = inFrame[passwordLengthPos];
        var certificatePos = 8 + hostnameLength + usernameLength + passwordLength;
        var remoteRecorderRegKeyLengthPos = 9 + hostnameLength + usernameLength + passwordLength;
        var remoteRecorderRegKeyLength = inFrame[remoteRecorderRegKeyLengthPos];
        var clientIdLengthPos = 10 + hostnameLength + usernameLength + passwordLength + remoteRecorderRegKeyLength;
        var clientIdLength = inFrame[clientIdLengthPos];
        var clientSecretLengthPos = 11 + hostnameLength + usernameLength + passwordLength + remoteRecorderRegKeyLength + clientIdLength;
        var clientSecretLength = inFrame[clientSecretLengthPos];
        var customUserModePos = 12 + hostnameLength + usernameLength + passwordLength + remoteRecorderRegKeyLength + clientIdLength + clientSecretLength;
        var customUserMode = inFrame[customUserModePos];

        receivedData.hostnameLength = hostnameLength;
        receivedData.hostname = parseStringFromArray(inFrame, hostnameLengthPos + 1, hostnameLength);

        receivedData.usernameLength = usernameLength;
        receivedData.username = parseStringFromArray(inFrame, usernameLengthPos + 1, usernameLength);

        receivedData.passwordLength = passwordLength;
        receivedData.password = parseStringFromArray(inFrame, passwordLengthPos + 1, passwordLength);

        receivedData.certificate = inFrame[certificatePos] === wolfProtParameters.WPP_ON;

        receivedData.remoteRecorderRegKeyLength = remoteRecorderRegKeyLength;
        receivedData.remoteRecorderRegKey = parseStringFromArray(inFrame, remoteRecorderRegKeyLengthPos + 1, remoteRecorderRegKeyLength);

        receivedData.clientIdLength = clientIdLength;
        receivedData.clientId = parseStringFromArray(inFrame, clientIdLengthPos + 1, clientIdLength);

        receivedData.clientSecretLength = clientSecretLength;
        receivedData.clientSecret = parseStringFromArray(inFrame, clientSecretLengthPos + 1, clientSecretLength);

        receivedData.customUserMode = customUserMode === wolfProtParameters.WPP_ON;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the opencast settings get/set method.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param command
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_OPENCAST_SETTINGS: function WPC_CB_OPENCAST_SETTINGS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var hostnameLengthPos = 5;
        var hostnameLength = inFrame[hostnameLengthPos];
        var captureAgentLengthPos = 6 + hostnameLength;
        var captureAgentLength = inFrame[captureAgentLengthPos];
        var usernameLengthPos = 7 + hostnameLength + captureAgentLength;
        var usernameLength = inFrame[usernameLengthPos];
        var passwordLengthPos = 8 + hostnameLength + captureAgentLength + usernameLength;
        var passwordLength = inFrame[passwordLengthPos];
        var workflowIdLengthPos = 9 + hostnameLength + captureAgentLength + usernameLength + passwordLength;
        var workflowIdLength = inFrame[workflowIdLengthPos];
        var certificateLengthPos = 11 + hostnameLength + captureAgentLength + usernameLength + passwordLength + workflowIdLength;
        var certificateLength = inFrame[certificateLengthPos];

        receivedData.hostnameLength = hostnameLength;
        receivedData.hostname = parseStringFromArray(inFrame, hostnameLengthPos + 1, hostnameLength);

        receivedData.captureAgentLength = captureAgentLength;
        receivedData.captureAgent = parseStringFromArray(inFrame, captureAgentLengthPos + 1, captureAgentLength);

        receivedData.usernameLength = usernameLength;
        receivedData.username = parseStringFromArray(inFrame, usernameLengthPos + 1, usernameLength);

        receivedData.passwordLength = passwordLength;
        receivedData.password = parseStringFromArray(inFrame, passwordLengthPos + 1, inFrame[passwordLengthPos]);

        receivedData.workflowIdLength = workflowIdLength;
        receivedData.workflowId = parseStringFromArray(inFrame, workflowIdLengthPos + 1, inFrame[workflowIdLengthPos]);

        receivedData.certificate = inFrame[certificateLengthPos - 1] === wolfProtParameters.WPP_ON;

        receivedData.certificateLength = certificateLength;
        receivedData.certificateBlob = parseStringFromArray(
            inFrame,
            certificateLengthPos + 1,
            inFrame[certificateLengthPos]
        );

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get disk encryption and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param command
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_DISK_ENCRYPTION_STATUS: function WPC_CB_DISK_ENCRYPTION_STATUS(
        inFrame,
        connection,
        command,
        invokeCallbackOf
    ) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_DISK_ENCRYPTION_STATUS_FAILED] = states.encryptionStates.failed;
        mapping[wolfProtParameters.WPP_CB_DISK_ENCRYPTION_STATUS_INPROGRESS] = states.encryptionStates.inProgress;
        mapping[wolfProtParameters.WPP_CB_DISK_ENCRYPTION_STATUS_ENCRYPTED] = states.encryptionStates.encrypted;
        mapping[wolfProtParameters.WPP_CB_DISK_ENCRYPTION_STATUS_DECRYPTED] = states.encryptionStates.decrypted;

        receivedData.status = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get signature check and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_FW_SIGNATURE_CHECK: function WPC_CB_FW_SIGNATURE_CHECK(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var signatureCheck = false;

        if (inFrame[4] === wolfProtParameters.WPP_CB_ENABLED) {
            signatureCheck = true;
        }

        receivedData.enabled = signatureCheck;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get panoptop directories list reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_PANOPTO_SUB_DIRECTORIES: function WPC_CB_PANOPTO_SUB_DIRECTORIES(
        inFrame,
        connection,
        command,
        invokeCallbackOf
    ) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = wpCalcDatalen(inFrame);
        var jsonString = parseStringFromArray(inFrame, wpCalcHeaderlen(inFrame), length);

        receivedData = JSONService.parse(jsonString);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get panoptop login state and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_PANOPTO_LOGIN_STATUS: function WPC_CB_PANOPTO_LOGIN_STATUS(
        inFrame,
        connection,
        command,
        invokeCallbackOf
    ) {
        var receivedData = {};
        var mapping = {};
        var lenUsername = inFrame[7];

        mapping[wolfProtParameters.WPP_CB_PANOPTO_STATUS_NOT_LOGGED_IN] = states.lectureCaptureLoginStates.notLoggedIn;
        mapping[wolfProtParameters.WPP_CB_PANOPTO_STATUS_LOGGED_IN] = states.lectureCaptureLoginStates.loggedIn;
        mapping[wolfProtParameters.WPP_CB_PANOPTO_STATUS_LOGIN_FAILED] = states.lectureCaptureLoginStates.loginFailed;

        receivedData.status = mapping[inFrame[5]];
        receivedData.statusRemoteRecorder = mapping[inFrame[6]];
        receivedData.username = parseStringFromArray(inFrame, 8, lenUsername);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get panoptop upload state and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_LECTURE_CAPTURE_UPLOAD_STATUS: function WPC_CB_LECTURE_CAPTURE_UPLOAD_STATUS(
        inFrame,
        connection,
        command,
        invokeCallbackOf
    ) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_LECTURE_CAPTURE_UPLOAD_STATUS_UNKNOWN] = states.lectureCaptureUploadStates.unknown;
        mapping[wolfProtParameters.WPP_CB_LECTURE_CAPTURE_UPLOAD_STATUS_OK] = states.lectureCaptureUploadStates.ok;
        mapping[wolfProtParameters.WPP_CB_LECTURE_CAPTURE_UPLOAD_STATUS_FAILED] = states.lectureCaptureUploadStates.failed;

        receivedData.status = mapping[inFrame[5]];
        receivedData.uploadListCount = inFrame[4];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Returns the state of the miracast back channel.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPP_CB_MIRACAST_BACK_CHANNEL: function WPP_CB_MIRACAST_BACK_CHANNEL(
        inFrame,
        connection,
        command,
        invokeCallbackOf
    ) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_MIRACAST_BACK_CHANNEL_ON] = states.miracastBackChannelEnable.true;
        mapping[wolfProtParameters.WPP_CB_MIRACAST_BACK_CHANNEL_OFF] = states.miracastBackChannelEnable.false;

        receivedData.enabled = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the generate logfile status.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_GENERATE_LOGFILE_STATUS: function WPC_CB_GENERATE_LOGFILE_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_GENERATE_LOGFILE_STATUS_INIT] = 'init';
        mapping[wolfProtParameters.WPP_CB_GENERATE_LOGFILE_STATUS_BUSY] = 'busy';
        mapping[wolfProtParameters.WPP_CB_GENERATE_LOGFILE_STATUS_READY] = 'ready';
        mapping[wolfProtParameters.WPP_CB_GENERATE_LOGFILE_STATUS_FAIL] = 'fail';

        receivedData.status = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses matrix auto open file.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_AUTO_OPEN_FILE: function WPC_CB_MATRIX_AUTO_OPEN_FILE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses LDAP auth mode.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LDAP_AUTH_MODE: function WPC_CB_LDAP_AUTH_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses LDAP tls mode.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LDAP_TLS_MODE: function WPC_CB_LDAP_TLS_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses LDAP status.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LDAP_STATUS: function WPC_CB_LDAP_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};
        mapping[wolfProtParameters.WPP_CB_LDAP_STATUS_NOT_AUTHENTICATED] = 'not-authenticated';
        mapping[wolfProtParameters.WPP_CB_LDAP_STATUS_AUTHENTICATED] = 'authenticated';

        receivedData.ldapStatus = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the LDAP server url.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LDAP_SERVER_URL: function WPC_CB_LDAP_SERVER_URL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.serverUrl = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the LDAP server domain.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LDAP_SERVER_DOMAIN: function WPC_CB_LDAP_SERVER_DOMAIN(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.serverDomain = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get LDAP login.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_LDAP_LOGIN: function WPC_CB_LDAP_LOGIN(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var lenUsername = inFrame[5];
        var lenPassword = inFrame[6 + lenUsername];

        receivedData.username = parseStringFromArray(inFrame, 6, lenUsername);
        receivedData.password = parseStringFromArray(inFrame, 7 + lenUsername, lenPassword);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses webbrowser viewer support.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WEBBROWSER_VIEWER_SUPPORT: function WPC_CB_WEBBROWSER_VIEWER_SUPPORT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses webbrowser viewer PIN enable.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WEBBROWSER_VIEWER_PIN_ENABLED: function WPC_CB_WEBBROWSER_VIEWER_PIN_ENABLED(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses ldap certificate name.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_LDAP_CERTIFICATE_NAME: function WPC_CB_LDAP_CERTIFICATE_NAME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.fileName = parseStringFromArray(inFrame,4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses window settings command.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WINDOW_SETTINGS: function WPC_CB_WINDOW_SETTINGS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_WINDOW_SETTINGS_SIDE_BY_SIDE] = 'sideBySide';
        mapping[wolfProtParameters.WPP_CB_WINDOW_SETTINGS_FULLSCREEN_MODE] = 'fullscreen';
        mapping[wolfProtParameters.WPP_CB_WINDOW_SETTINGS_MIXED_MODE] = 'mixed';

        receivedData.mode = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the BYOD enldess mode.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_BYOD_ENDLESS_MODE: function WPC_CB_BYOD_ENDLESS_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the usb speaker enable/disable
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_USB_SPEAKER: function WPC_CB_USB_SPEAKER(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;
        receivedData.webconfSplit = inFrame[5] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the usb speaker card list configuration
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_USB_SPEAKER_CARD_LIST: function WPC_CB_USB_SPEAKER_CARD_LIST(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var pos = 5;
        var card;
        var count = 0;

        receivedData.cardList = [];

        while (pos < inFrame.length) {
            var len = inFrame[pos];
            card = parseStringFromArray(inFrame, pos + 1, len);
            pos += len + 1;
            receivedData.cardList[count] = card;
            count +=1;
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get usb speaker card name reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_USB_SPEAKER_CARD_NAME: function WPC_CB_USB_SPEAKER_CARD_NAME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.cardName = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get usb speaker volume
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_USB_SPEAKER_VOLUME: function WPC_CB_USB_SPEAKER_VOLUME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.volume = inFrame[4];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get matrix template id
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_ACTIVE_TEMPLATE: function WPC_CB_MATRIX_ACTIVE_TEMPLATE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.templateId = inFrame[4];
        receivedData.templateName = parseStringFromArray(inFrame, 6, inFrame[5]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get opencast series reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_OPENCAST_SERIES: function WPC_CB_OPENCAST_SERIES(
        inFrame,
        connection,
        command,
        invokeCallbackOf
    ) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = wpCalcDatalen(inFrame);
        var jsonString = parseStringFromArray(inFrame, wpCalcHeaderlen(inFrame), length);

        receivedData.tree = JSONService.parse(jsonString);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get opencast login state and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_OPENCAST_STATUS: function WPC_CB_OPENCAST_STATUS(
        inFrame,
        connection,
        command,
        invokeCallbackOf
    ) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_PANOPTO_STATUS_NOT_LOGGED_IN] = states.lectureCaptureLoginStates.notLoggedIn;
        mapping[wolfProtParameters.WPP_CB_PANOPTO_STATUS_LOGGED_IN] = states.lectureCaptureLoginStates.loggedIn;
        mapping[wolfProtParameters.WPP_CB_PANOPTO_STATUS_LOGIN_FAILED] = states.lectureCaptureLoginStates.loginFailed;

        receivedData.status = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get matrix master limited access mode
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_MASTER_LIMITED_ACCESS: function WPC_CB_MATRIX_MASTER_LIMITED_ACCESS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * Parses the get system language reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_KEYBOARD_LAYOUT: function WPC_CB_KEYBOARD_LAYOUT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_DE] = 'de';
        mapping[wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_US] = 'us';
        mapping[wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_CN] = 'cn';
        mapping[wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_FR] = 'fr';
        mapping[wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_JP] = 'jp';
        mapping[wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_NO] = 'no';
        mapping[wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_SV] = 'sv';
        mapping[wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_IT] = 'it';
        mapping[wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_CH] = 'ch';
        mapping[wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_UK] = 'uk';
        mapping[wolfProtParameters.WPP_CB_KEYBOARD_LAYOUT_FR_CA] = 'fr-ca';

        receivedData.layout = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses Power down mode.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_POWER_DOWN_MODE: function WPC_CB_POWER_DOWN_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_POWER_DOWN_SUSPEND] = 'suspend';
        mapping[wolfProtParameters.WPP_CB_POWER_DOWN_SHUTDOWN] = 'shutdown';
        mapping[wolfProtParameters.WPP_CB_POWER_DOWN_POWER_SAVING] = 'power_saving';

        receivedData.mode = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parse file push mode.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_FILE_PUSH_MODE: function WPC_CB_FILE_PUSH_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_FILE_PUSH_MODE_ON] = 'on';
        mapping[wolfProtParameters.WPP_CB_FILE_PUSH_MODE_OFF] = 'off';

        receivedData.mode = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get app conection counter.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_APP_CONNECTION_COUNTER: function WPC_APP_CONNECTION_COUNTER(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.count = inFrame[4];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get vMeeting / Session Management meeting list and calls the given callback.
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_VMEETING_SESSION_LIST: function WPC_CB_VMEETING_SESSION_LIST(inFrame, connection, command, invokeCallbackOf) {
        var JSONService = app.getService('JSONService');
        var receivedData = {};
        var length = wpCalcDatalen(inFrame);
        var jsonString = parseStringFromArray(inFrame, wpCalcHeaderlen(inFrame), length);

        receivedData.list = JSONService.parse(jsonString);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the active session id reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_VMEETING_START_SESSION: function WPC_CB_VMEETING_START_SESSION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.activeSessionId = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the vMeeting resource reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_VMEETING_RESOURCE: function WPC_CB_VMEETING_RESOURCE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.resource = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the vMeeting OAuth2 tenant id reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_VMEETING_OAUTH_TENANT_ID: function WPC_CB_VMEETING_OAUTH_TENANT_ID(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.id = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the vMeeting OAuth2 secret reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_VMEETING_OAUTH_SECRET: function WPC_CB_VMEETING_OAUTH_SECRET(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.secret = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the vMeeting OAuth2 app ID reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_VMEETING_OAUTH_APP_ID: function WPC_CB_VMEETING_OAUTH_APP_ID(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.id = parseStringFromArray(inFrame, 4, inFrame[3]);

        invokeCallbackOf(command, receivedData);
    },


    /**
     * Parses the vMeeting cleanup time reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_VMEETING_CLEANUP_TIME: function WPC_CB_VMEETING_CLEANUP_TIME(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.minutes = inFrame[4];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the vMeeting Deadline status reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_VMEETING_DEADLINE: function WPC_CB_VMEETING_DEADLINE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};
        mapping[wolfProtParameters.WPP_CB_VMEETING_DEADLINE_STATUS_OK] = 'ok';
        mapping[wolfProtParameters.WPP_CB_VMEETING_DEADLINE_STATUS_WARNING] = 'warning';
        mapping[wolfProtParameters.WPP_CB_VMEETING_DEADLINE_STATUS_CRITICAL] = 'critical';

        receivedData.status = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the vMeeting connection status reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_VMEETING_CONNECTION_STATUS: function WPC_CB_VMEETING_CONNECTION_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_VMEETING_CONNECTION_STATUS_DISCONNECTED] = states.connectionStates.disconnected;
        mapping[wolfProtParameters.WPP_CB_VMEETING_CONNECTION_STATUS_UNKNOWN] = states.connectionStates.loggingIn;
        mapping[wolfProtParameters.WPP_CB_VMEETING_CONNECTION_STATUS_CONNECTING] = states.connectionStates.connecting;
        mapping[wolfProtParameters.WPP_CB_VMEETING_CONNECTION_STATUS_FAILURE] = states.connectionStates.failed;
        mapping[wolfProtParameters.WPP_CB_VMEETING_CONNECTION_STATUS_SUCCESS] = states.connectionStates.connected;

        receivedData.status = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get vMeeting function and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_VMEETING_FUNCTION: function WPC_CB_VMEETING_FUNCTION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get vMeeting mode and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_VMEETING_MODE: function WPC_CB_VMEETING_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = [];
        mapping[wolfProtParameters.WPP_CB_VMEETING_MODE_MS365] = states.vMeetingModes.ms365;
        mapping[wolfProtParameters.WPP_CB_VMEETING_MODE_MS365_BASIC] = states.vMeetingModes.ms365Basic;
        mapping[wolfProtParameters.WPP_CB_VMEETING_MODE_GOOGLE_CAL] = states.vMeetingModes.googleCal;
        mapping[wolfProtParameters.WPP_CB_VMEETING_MODE_GOOGLE_CAL_BASIC] = states.vMeetingModes.googleCalBasic;

        receivedData.mode = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get vMeeting PIN reply and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_VMEETING_PIN: function WPC_CB_VMEETING_PIN(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === wolfProtParameters.WPP_ON;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get vMeeting unbooked duration and calls the given callback.
     *
     * Active meeting: get time between current meeting end time and next meeting
     * NO active meeting: get time between NOW and next meeting
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_VMEETING_UNBOOKED_DURATION: function WPC_CB_VMEETING_UNBOOKED_DURATION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.duration = parseValueFromArrayBigEndian(inFrame, 4, 2);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get wifi direct channel reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_WLAN_AP_DIRECT_CHANNEL: function WPC_CB_WLAN_AP_DIRECT_CHANNEL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = [];
        mapping[wolfProtParameters.WPP_WLAN_DIRECT_CHANNEL_AUTO] = 'auto';
        mapping[wolfProtParameters.WPP_WLAN_DIRECT_CHANNEL_1] = '1';
        mapping[wolfProtParameters.WPP_WLAN_DIRECT_CHANNEL_6] = '6';
        mapping[wolfProtParameters.WPP_WLAN_DIRECT_CHANNEL_11] = '11';

        receivedData.channel = mapping[inFrame[4]];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * Parses the get matrix master streaming resolution reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_MASTER_STREAMING_RESOLUTION: function WPC_CB_MATRIX_MASTER_STREAMING_RESOLUTION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_RESOLUTION_360P] = '360p';
        mapping[wolfProtParameters.WPP_CB_RESOLUTION_540P] = '540p';
        mapping[wolfProtParameters.WPP_CB_RESOLUTION_720P] = '720p';
        mapping[wolfProtParameters.WPP_CB_RESOLUTION_1080P] = '1080p';

        receivedData.resolution = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get matrix master streaming framerate reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_MASTER_STREAMING_FRAMERATE: function WPC_CB_MATRIX_MASTER_STREAMING_FRAMERATE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_FRAMERATE_LOW] = 'low';
        mapping[wolfProtParameters.WPP_FRAMERATE_MEDIUM] = 'medium';
        mapping[wolfProtParameters.WPP_FRAMERATE_HIGH] = 'high';

        receivedData.framerate = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get matrix master streaming bitrate reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_MASTER_STREAMING_BITRATE: function WPC_CB_MATRIX_MASTER_STREAMING_BITRATE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var modeMapping = {};

        modeMapping[wolfProtParameters.WPP_STREAMING_BITRATE_MODE_CONSTANT] = 'constant';
        modeMapping[wolfProtParameters.WPP_STREAMING_BITRATE_MODE_VARIABLE] = 'variable';

        receivedData.mode = modeMapping[inFrame[4]];
        receivedData.bitrate = ((inFrame[5] << 8) | inFrame[6]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get matrix master streaming port reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_MASTER_STREAMING_MC_PORT_BASE: function WPC_CB_MATRIX_MASTER_STREAMING_MC_PORT_BASE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.port = '' + ((inFrame[4] << 8) | inFrame[5]);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get matrix amster multicast streaming ttl command and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_MASTER_STREAMING_MC_TTL: function WPC_CB_MATRIX_MASTER_STREAMING_MC_TTL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.ttl = inFrame[4];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the matrix streaming multicast ip address reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MATRIX_MASTER_STREAMING_MC_IP_BASE: function WPC_CB_MATRIX_MASTER_STREAMING_MC_IP_BASE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.ipAddress = ipv4AddressInt2String((inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | (inFrame[7]));
        invokeCallbackOf(command, receivedData);
    },


    /**
     * parses the get log level reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_DEBUG_LEVEL: function WPC_CB_DEBUG_LEVEL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.id = inFrame[4];
        receivedData.level = inFrame[5];

        invokeCallbackOf(command,receivedData);
    },

    /**
     * parses the get splash screen trigger mode reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_SPLASH_SCREEN_TRIGGER_MODE: function WPC_SPLASH_SCREEN_TRIGGER_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.mode = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the get splash screen status  reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_SPLASH_SCREEN_STATUS: function WPC_SPLASH_SCREEN_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.active = inFrame[4] === 0x01 ? true : false;
        invokeCallbackOf(command,receivedData);
    },

    /**
     * Parses the get routing mode reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ROUTING_MODE: function WPC_CB_ROUTING_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the settings for Annotation Magic Pen and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ANNOTATION_MAGIC_PEN_SETTINGS: function WPC_CB_ANNOTATION_MAGIC_PEN_SETTINGS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.visibleTime = inFrame[4];
        receivedData.remoteUserEnabled = inFrame[5] === wolfProtParameters.WPP_CB_ENABLED ? true : false;
        receivedData.enabled = inFrame[6] === wolfProtParameters.WPP_CB_ENABLED ? true : false;
        receivedData.autoSnapshot = inFrame[7] === wolfProtParameters.WPP_CB_ENABLED ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the annotation state and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ANNOTATION_MAGIC_PEN_STATUS: function WPC_CB_ANNOTATION_MAGIC_PEN_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_DISABLED] = 'inactive';
        mapping[wolfProtParameters.WPP_CB_ENABLED] = 'active';

        receivedData.status = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get access point maximum clients.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_AP_MAXIMUM_CLIENTS: function WPC_CB_AP_MAXIMUM_CLIENTS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.nrClients = inFrame[4];
        invokeCallbackOf(command, receivedData);
    },

    /**
    * parses the get miracast p2p role reply and calls the given callback
    *
    * @param inFrame: the received data
    * @param connection: the websocket connection
    * @param invokeCallbackOf: the callback to where the command is passed
    *
    */
    WPC_CB_MIRACAST_P2P_ROLE: function WPC_CB_MIRACAST_P2P_ROLE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_MIRACAST_CLIENT] = 'client';
        mapping[wolfProtParameters.WPP_CB_MIRACAST_GO] = 'go';

        receivedData.role = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the ZOOM settings reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ZOOM_SETTINGS: function WPC_CB_ZOOM_SETTINGS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[5] === wolfProtParameters.WPP_ON ? true : false;
        receivedData.enableContent = inFrame[6] === wolfProtParameters.WPP_ON ? true : false;
        receivedData.enableApp = inFrame[7] === wolfProtParameters.WPP_ON ? true : false;
        receivedData.enableLocalMeeting = inFrame[8] === wolfProtParameters.WPP_ON ? true : false;
        receivedData.enableJoinMeeting = inFrame[9] === wolfProtParameters.WPP_ON ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the Zoom local account info reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ZOOM_LOCAL_ACCOUNT_INFO: function WPC_CB_ZOOM_LOCAL_ACCOUNT_INFO(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var pos = 5;

        let lengthName = inFrame[pos];
        receivedData.name = parseStringFromArray(inFrame, ++pos, lengthName);
        pos += lengthName;

        let lengthEmail = inFrame[pos];
        receivedData.email = parseStringFromArray(inFrame, ++pos, lengthEmail);
        pos += lengthEmail;

        let lengthUrl = parseValueFromArrayBigEndian(inFrame, pos, 2);
        pos += 2;
        receivedData.meetingUrl = parseStringFromArray(inFrame, pos, lengthUrl);
        pos += lengthUrl;

        let lengthMeetingId = inFrame[pos];
        receivedData.meetingId = parseStringFromArray(inFrame, ++pos, lengthMeetingId);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the Zoom local account status reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ZOOM_LOCAL_ACCOUNT_STATUS: function WPC_CB_ZOOM_LOCAL_ACCOUNT_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_ZOOM_LOCAL_ACCOUNT_UNDEFINED] = 'undefined';
        mapping[wolfProtParameters.WPP_CB_ZOOM_LOCAL_ACCOUNT_DISCONNECTED] = 'disconnected';
        mapping[wolfProtParameters.WPP_CB_ZOOM_LOCAL_ACCOUNT_CONNECTED] = 'connected';
        mapping[wolfProtParameters.WPP_CB_ZOOM_LOCAL_ACCOUNT_LOGGED_OUT] = 'loggedOut';

        receivedData.status = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the Webconference screenshare status reply and calls the given callback.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_WEBCONF_SCREENSHARE_STATUS: function WPC_CB_WEBCONF_SCREENSHARE_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_WEBCONF_SCREENSHARE_MODE_NATIVE] = 'native';
        mapping[wolfProtParameters.WPP_CB_WEBCONF_SCREENSHARE_MODE_VIDEO] = 'video';

        receivedData.active = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        receivedData.mode = mapping[inFrame[5]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the PIP function.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_PIP_FUNCTION: function WPC_CB_PIP_FUNCTION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enable = inFrame[4];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the PIP position.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_PIP_POSITION: function WPC_CB_PIP_POSITION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_PIP_POSITION_LEFT_BOTTOM] = 'leftBottom';
        mapping[wolfProtParameters.WPP_CB_PIP_POSITION_LEFT_TOP] = 'leftTop';
        mapping[wolfProtParameters.WPP_CB_PIP_POSITION_RIGHT_BOTTOM] = 'rightBottom';
        mapping[wolfProtParameters.WPP_CB_PIP_POSITION_RIGHT_TOP] = 'rightTop';

        receivedData.position = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the PIP size.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_PIP_SIZE: function WPC_CB_PIP_SIZE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_PIP_SIZE_360P] = '360p';

        receivedData.size = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the PIP content.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_PIP_CONTENT: function WPC_CB_PIP_CONTENT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_PIP_CONTENT_CAMERA] = 'camera';
        mapping[wolfProtParameters.WPP_CB_PIP_CONTENT_MAIN] = 'main';

        receivedData.content = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the PIP latency.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_PIP_LATENCY: function WPC_CB_PIP_LATENCY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.latency = ((inFrame[4] << 8) | inFrame[5]);
        invokeCallbackOf(command, receivedData);
    },

   /**
    * Parses the ip routing table configuration
    *
    * @param inFrame: the received data
    * @param connection: the websocket connection
    * @param invokeCallbackOf: the callback to where the command is passed
    */
    WPC_CB_ROUTING_TABLE: function WPC_CB_ROUTING_TABLE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_ROUTING_DEVICE_LAN1] = 'lan1';
        mapping[wolfProtParameters.WPP_CB_ROUTING_DEVICE_LAN2] = 'lan2';
        mapping[wolfProtParameters.WPP_CB_ROUTING_DEVICE_WLAN] = 'wlan';

        receivedData.index = inFrame[5];
        receivedData.enabled = inFrame[6];
        receivedData.routingIp = ipv4AddressInt2String((inFrame[7] << 24) | (inFrame[8] << 16) | (inFrame[9] << 8) | (inFrame[10]));
        receivedData.prefix = inFrame[11];
        receivedData.gatewayEnabled = inFrame[12];
        receivedData.gateway = ipv4AddressInt2String((inFrame[13] << 24) | (inFrame[14] << 16) | (inFrame[15] << 8) | (inFrame[16]));
        receivedData.device = mapping[inFrame[17]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the PIP stream input.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_PIP_STREAM_INPUT: function WPC_CB_PIP_STREAM_INPUT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.index = inFrame[4];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the adjustment wlan region.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_ADJUSTMENT_WLAN_REGION: function WPC_CB_ADJUSTMENT_WLAN_REGION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.region = inFrame[4];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the wlan ap country.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_WLAN_AP_COUNTRY: function WPC_CB_WLAN_AP_COUNTRY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = {};

        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_US] = 'us';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_CA] = 'ca';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_AU] = 'au';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_NZ] = 'nz';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_MY] = 'my';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_AT] = 'at';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_BE] = 'be';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_BG] = 'bg';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_CY] = 'cy';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_CZ] = 'cz';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_DE] = 'de';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_DK] = 'dk';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_EE] = 'ee';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_ES] = 'es';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_FI] = 'fi';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_FR] = 'fr';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_GB] = 'gb';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_GR] = 'gr';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_HR] = 'hr';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_HU] = 'hu';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_IE] = 'ie';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_IT] = 'it';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_LT] = 'lt';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_LU] = 'lu';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_LV] = 'lv';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_MT] = 'mt';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_NL] = 'nl';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_PL] = 'pl';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_PT] = 'pt';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_RO] = 'ro';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_SE] = 'se';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_SI] = 'si';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_SK] = 'sk';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_AD] = 'ad';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_BA] = 'ba';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_CH] = 'ch';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_LI] = 'li';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_MC] = 'mc';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_ME] = 'me';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_RS] = 'rs';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_NO] = 'no';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_JP] = 'jp';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_RU] = 'ru';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_KW] = 'kw';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_OM] = 'om';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_SA] = 'sa';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_QA] = 'qa';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_CN] = 'cn';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_HK] = 'hk';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_SG] = 'sg';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_IN] = 'in';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_TH] = 'th';
        mapping[wolfProtParameters.WPP_CB_WLAN_COUNTRY_AE] = 'ae';

        receivedData.country = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses TLS 1.0 support.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_TLS_1_0_SUPPORT: function WPC_CB_TLS_1_0_SUPPORT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the firewall settings.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_FIREWALL: function WPC_CB_FIREWALL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mappingInterface = [];
        var mappingService = [];

        mappingInterface[wolfProtParameters.WPP_CB_FIREWALL_INTERFACE_LAN1] = 'lan1';
        mappingInterface[wolfProtParameters.WPP_CB_FIREWALL_INTERFACE_LAN2] = 'lan2';
        mappingInterface[wolfProtParameters.WPP_CB_FIREWALL_INTERFACE_WLAN] = 'wlan';

        mappingService[wolfProtParameters.WPP_CB_FIREWALL_SERVICE_HTTP_HTTPS] = 'http_https';
        mappingService[wolfProtParameters.WPP_CB_FIREWALL_SERVICE_RTSP] = 'rtsp';

        receivedData.interface = mappingInterface[inFrame[4]];
        receivedData.service = mappingService[inFrame[5]];
        receivedData.accept = (inFrame[6] === wolfProtParameters.WPP_CB_FIREWALL_SERVICE_ACCEPT);
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the office365 teams settings.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_OFFICE365_TEAMS_SETTINGS: function WPC_CB_OFFICE365_TEAMS_SETTINGS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.sourceEnabled = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        receivedData.enableContent = inFrame[5] === wolfProtParameters.WPP_ON ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the get screen share window visible reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_SCREENSHARE_WINDOW_VISIBLE: function WPC_CB_SCREENSHARE_WINDOW_VISIBLE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = [];

        mapping[wolfProtParameters.WPP_ON] = 'visible';
        mapping[wolfProtParameters.WPP_OFF] = 'invisible';

        receivedData.visible = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the maximum resolution of HDMI input.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_HDMI_INPUT_MAX_RESOLUTION: function WPC_CB_HDMI_INPUT_MAX_RESOLUTION(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = [];

        mapping[wolfProtParameters.WPP_CB_HDMI_INPUT_MAX_RESOLUTION_2160P] = '2160p';
        mapping[wolfProtParameters.WPP_CB_HDMI_INPUT_MAX_RESOLUTION_1080P] = '1080p';

        receivedData.input = inFrame[4];
        receivedData.resolution = mapping[inFrame[5]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the eddystone discovery.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_EDDYSTONE_DISCOVERY:  function WPC_CB_EDDYSTONE_DISCOVERY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the mDNS discovery.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_MDNS_DISCOVERY:  function WPC_CB_MDNS_DISCOVERY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[4] === wolfProtParameters.WPP_ON ? true : false;
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the eddystone interface settings.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_EDDYSTONE_INTERFACE: function WPC_CB_EDDYSTONE_INTERFACE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = [];

        mapping[wolfProtParameters.WPP_CB_EDDYSTONE_INTERFACE_LAN1] = 'lan1';
        mapping[wolfProtParameters.WPP_CB_EDDYSTONE_INTERFACE_LAN2] = 'lan2';
        mapping[wolfProtParameters.WPP_CB_EDDYSTONE_INTERFACE_WLAN] = 'wlan';

        receivedData.interface = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
    * Parses the help page status.
    *
    * @param inFrame: the received data
    * @param connection: the websocket connection
    * @param invokeCallbackOf: the callback to where the command is passed
    */
    WPC_WWW_HELP_PAGE_STATUS: function WPC_WWW_HELP_PAGE_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = [];

        mapping[wolfProtParameters.WPP_WWW_HELP_PAGE_STATUS_UNAVAILABLE] = 'unavailable';
        mapping[wolfProtParameters.WPP_WWW_HELP_PAGE_STATUS_AVAILABLE] = 'available';

        receivedData.status = mapping[inFrame[4]];
        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses Airplay 4k mode.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_AIRPLAY_4K_MODE: function WPC_CB_AIRPLAY_4K_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the Panopto show login status.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param command
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_PANOPTO_LOGIN_DIALOG: function WPC_CB_PANOPTO_LOGIN_DIALOG(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = [];

        mapping[wolfProtParameters.WPP_CB_PANOPTO_LOGIN_DIALOG_NONE] = 'none';
        mapping[wolfProtParameters.WPP_CB_PANOPTO_LOGIN_DIALOG_USERNAME_PASSWORD] = 'usernamePassword';
        mapping[wolfProtParameters.WPP_CB_PANOPTO_LOGIN_DIALOG_BROWSER] = 'browser';

        receivedData.dialog = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the vLink Pro discovery settings.
     *
     */
    WPC_CB_VLINK_PRO_DISCOVERY_SETTINGS: function WPC_CB_VLINK_PRO_DISCOVERY_SETTINGS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = [];

        mapping[wolfProtParameters.WPP_CB_VLINK_PRO_DISCOVERY_OFF] = 'off';
        mapping[wolfProtParameters.WPP_CB_VLINK_PRO_DISCOVERY_STATIC] = 'static';
        mapping[wolfProtParameters.WPP_CB_VLINK_PRO_DISCOVERY_DHCP] = 'dhcp';

        receivedData.mode = mapping[inFrame[4]];
        receivedData.serverIp = parseStringFromArray(inFrame, 6, inFrame[5]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the vLink Pro discovery settings.
     */
    WPC_CB_MGMT_SUBSCRIBE_STATUS: function WPC_CB_MGMT_SUBSCRIBE_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = [];

        mapping[wolfProtParameters.WPP_CB_MGMT_SUBSCRIBE_STATUS_INIT] = 'init';
        mapping[wolfProtParameters.WPP_CB_MGMT_SUBSCRIBE_STATUS_GENERIC_ERROR] = 'generic-error';
        mapping[wolfProtParameters.WPP_CB_MGMT_SUBSCRIBE_STATUS_DHCP_ERROR] = 'dhcp-error';
        mapping[wolfProtParameters.WPP_CB_MGMT_SUBSCRIBE_STATUS_POST_ERROR] = 'post-error';
        mapping[wolfProtParameters.WPP_CB_MGMT_SUBSCRIBE_STATUS_OK] = 'ok';

        receivedData.status = mapping[inFrame[4]];
        receivedData.server = parseStringFromArray(inFrame, 8, inFrame[7]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the HDMI Input for WebRTC.
     */
    WPC_CB_HDMI_INPUT_WEBRTC: function WPC_CB_HDMI_INPUT_WEBRTC(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.hdmiInput = inFrame[4];
        receivedData.enabled = inFrame[5];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the Office365 local account.
     */
    WPC_CB_OFFICE365_LOCAL_ACCOUNT: function WPC_CB_OFFICE365_LOCAL_ACCOUNT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enabled = inFrame[5];
        receivedData.user = parseStringFromArray(inFrame,7, inFrame[6]);
        receivedData.pwd = parseStringFromArray(inFrame, 8 + inFrame[6], 7 + inFrame[6]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the Office365 credentials origin value.
     */
    WPC_CB_OFFICE365_CREDENTIALS_ORIGIN: function WPC_CB_OFFICE365_CREDENTIALS_ORIGIN(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = [];

        mapping[wolfProtParameters.WPP_CB_OFFICE365_CREDENTIALS_LOCAL] = 'local';
        mapping[wolfProtParameters.WPP_CB_OFFICE365_CREDENTIALS_USER] = 'user';

        receivedData.origin = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the Webcast Youtube parameters (title and privacy).
     */
    WPC_CB_WEBCAST_YOUTUBE_PARAMETERS: function WPC_CB_WEBCAST_YOUTUBE_PARAMETERS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = [];

        mapping[wolfProtParameters.WPP_CB_WEBCAST_YOUTUBE_PRIVACY_PRIVATE] = 'private';
        mapping[wolfProtParameters.WPP_CB_WEBCAST_YOUTUBE_PRIVACY_PUBLIC] = 'public';
        mapping[wolfProtParameters.WPP_CB_WEBCAST_YOUTUBE_PRIVACY_UNLISTED] = 'unlisted';

        receivedData.privacy = mapping[inFrame[5]];
        receivedData.title = parseStringFromArray(inFrame, 7, inFrame[6]);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the Standby Possible value (true/false)
     */
    WPC_CB_STANDBY_POSSIBLE: function WPC_CB_STANDBY_POSSIBLE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.possible = inFrame[4] === 0x00 ? false : true;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the usb mic settings (enable, remote, webconf, local)
     */
    WPC_CB_USB_MIC_SETTINGS: function WPC_CB_USB_MIC_SETTINGS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enable = inFrame[4] === 0x00 ? false : true;
        receivedData.local = inFrame[5] === 0x00 ? false : true;
        receivedData.webconf = inFrame[6] === 0x00 ? false : true;
        receivedData.remote = inFrame[7] === 0x00 ? false : true;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the reverse shell settings.
     */
    WPC_CB_REVERSE_SHELL: function WPC_CB_REVERSE_SHELL(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = [];

        mapping[wolfProtParameters.WPP_CB_REVERSE_SHELL_STATUS_STOPPED] = 'stopped';
        mapping[wolfProtParameters.WPP_CB_REVERSE_SHELL_STATUS_STARTED] = 'started';
        mapping[wolfProtParameters.WPP_CB_REVERSE_SHELL_STATUS_FAILED] = 'failed';

        receivedData.port = ((inFrame[4] << 8) | inFrame[5]);
        receivedData.status = mapping[inFrame[6]];
        receivedData.webpageRedirectEnable = inFrame[7] !== 0x00;
        receivedData.debugPortEnable = inFrame[8] !== 0x00;
        receivedData.enable = inFrame[9] !== 0x00;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the Admin Message Enable setting.
     */
    WPC_CB_ADMIN_MESSAGE_ENABLE: function WPC_CB_ADMIN_MESSAGE_ENABLE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.enable = inFrame[4] === 0x00 ? false : true;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the admin message status.
     */
    WPC_CB_ADMIN_MESSAGE_STATUS: function WPC_CB_ADMIN_MESSAGE_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        var mapping = [];

        mapping[wolfProtParameters.WPP_CB_STOPPED] = 'stopped';
        mapping[wolfProtParameters.WPP_CB_STARTED] = 'started';

        receivedData.status = mapping[inFrame[4]];

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the presentation status (active/inactive).
     */
    WPC_CB_PRESENTATION_STATUS: function WPC_CB_PRESENTATION_STATUS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};

        receivedData.active = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses Miracast 4k mode.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MIRACAST_4K_MODE: function WPC_CB_MIRACAST_4K_MODE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses Miracast Low Latency default value.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_MIRACAST_LOW_LATENCY: function WPC_CB_MIRACAST_LOW_LATENCY(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses HDMI Input 1 No Audio
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_HDMI_INPUT_1_NO_AUDIO: function WPC_CB_HDMI_INPUT_1_NO_AUDIO(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses HDMI Input 1 HDCP Support enabled on Cynap Core Pro
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_HDMI_INPUT_1_HDCP_SUPPORT: function WPC_CB_HDMI_INPUT_1_HDCP_SUPPORT(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the system analysis (enabled/disabled)
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_SYSTEM_ANALYSIS: function WPC_CB_SYSTEM_ANALYSIS(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the camera back function setting (enabled/disabled)
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CAMERA_BACK_FUNCTION: function WPC_CB_CAMERA_BACK_FUNCTION(inFrame, connection, command, invokeCallbackOf) {
        let receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the zero config active state.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ZERO_CONFIG: function WPC_CB_ZERO_CONFIG(inFrame, connection, command, invokeCallbackOf) {
        const JSONService = app.getService('JSONService');
        let receivedData = {};

        receivedData.active = inFrame[5] === 0x01;
        const jsonLength = (inFrame[6] << 8) + inFrame[7];
        const jsonString = parseStringFromArray(inFrame, 8, jsonLength);
        receivedData.usbDefaultDevices = JSONService.parse(jsonString);

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the camera back function setting (enabled/disabled)
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     */
    WPC_CB_CAMERA_BACK_REC_ENABLE: function WPC_CB_CAMERA_BACK_REC_ENABLE(inFrame, connection, command, invokeCallbackOf) {
        let receivedData = {};
        receivedData.enabled = inFrame[4] === 0x01;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the Room Controller settings reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ROOM_CONTROLLER_SETTINGS: function WPC_CB_ROOM_CONTROLLER_SETTINGS(inFrame, connection, command, invokeCallbackOf) {
        const receivedData = {};
        let offset = 5;

        receivedData.enabled = inFrame[offset] === wolfProtParameters.WPP_ON ? true : false;
        offset += 1;

        const hostLength = (inFrame[offset + 0] << 8) + inFrame[offset + 1];
        offset += 2;

        receivedData.roomControllerHost = StringConvert.utf8ToString(inFrame.slice(offset, offset + hostLength));
        offset += hostLength;

        const pathnameLength = (inFrame[offset + 0] << 8) + inFrame[offset + 1];
        offset += 2;

        receivedData.roomControllerPathname = StringConvert.utf8ToString(inFrame.slice(offset, offset + pathnameLength));
        offset += pathnameLength;

        const certLength = (inFrame[offset + 0] << 8) + inFrame[offset + 1];
        offset += 2;

        receivedData.roomControllerCert = StringConvert.utf8ToString(inFrame.slice(offset, offset + certLength));
        offset += certLength;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses the Room Controller path reply and calls the given callback
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_ROOM_CONTROLLER_PATH: function WPC_CB_ROOM_CONTROLLER_PATH(inFrame, connection, command, invokeCallbackOf) {
        const receivedData = {};
        let offset = 5;

        receivedData.enabled = inFrame[offset] === wolfProtParameters.WPP_ON ? true : false;
        offset += 1;

        const pathLength = (inFrame[offset + 0] << 8) + inFrame[offset + 1];
        offset += 2;

        receivedData.path = StringConvert.utf8ToString(inFrame.slice(offset, offset + pathLength));
        offset += pathLength;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses dual projection enable.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_DUAL_PROJECTION_ENABLE: function WPC_CB_DUAL_PROJECTION_ENABLE(inFrame, connection, command, invokeCallbackOf) {
        var receivedData = {};
        receivedData.enable = inFrame[4] === 0x01 ? true : false;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses control screen confidence monitor information.
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CONFIDENCE_MONITORS_INFORMATION: function WPC_CB_CONFIDENCE_MONITORS_INFORMATION(inFrame, connection, command, invokeCallbackOf) {
        const receivedData = {};
        const totalLength = wpCalcDatalen(inFrame);
        let blockLength = 0;
        let pos = 9;

        const outputMapping = {};

        outputMapping[wolfProtParameters.WPP_OUTPUT_HDMI1] = 'hdmi1';
        outputMapping[wolfProtParameters.WPP_OUTPUT_HDMI2] = 'hdmi2';

        receivedData.referenceWidth = ((inFrame[5] << 8) | inFrame[6]);
        receivedData.referenceHeight = ((inFrame[7] << 8) | inFrame[8]);
        receivedData.monitors = [];

        while ((totalLength + 4) > pos) {
            const data = {};

            blockLength = ((inFrame[pos] << 8) | inFrame[pos + 1]);
            pos += 2;

            if (blockLength > 0) {
                data.x = ((inFrame[pos] << 8) | inFrame[pos + 1]);
                pos += 2;

                data.y = ((inFrame[pos] << 8) | inFrame[pos + 1]);
                pos += 2;

                data.width = ((inFrame[pos] << 8) | inFrame[pos + 1]);
                pos += 2;

                data.height = ((inFrame[pos] << 8) | inFrame[pos + 1]);
                pos += 2;

                data.outputPort = outputMapping[inFrame[pos]];
                pos += 1;

            }

            receivedData.monitors.push(data);
        }

        invokeCallbackOf(command, receivedData);
    },

    /**
     * Parses content per output information (windows / output in edit)
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CONTENT_PER_OUTPUT: function WPC_CB_CONTENT_PER_OUTPUT(inFrame, connection, command, invokeCallbackOf) {
        const receivedData = {
                windows: [],
                outputsInEdit: []
        };
        const contentTypeMapping = getContentTypeMapping();
        const totalLength = parseValueFromArrayBigEndian(inFrame, 3, 2);
        let blockLength = 0;
        let pos = 5;

        while ((totalLength + 5) > (pos)) {
            const type = contentTypeMapping[inFrame[pos]];
            pos += 1;

            blockLength = ((inFrame[pos] << 8) | inFrame[pos+1]);
            pos += 2;

            if (blockLength > 0) {
                let data;

                if (type === 'window') {
                    ({ data, pos } = parseWindowBlock(inFrame, pos));
                    receivedData.windows.push(data);
                } else if (type === 'output') {
                    ({ data, pos } = parseOutputBlock(inFrame, pos));
                    receivedData.outputsInEdit.push(data);
                } else {
                    // unknown block type
                    pos += blockLength;
                }
            }
        }

        invokeCallbackOf(command, receivedData);
    },

    WPC_CB_PREPARE_LIST: function WPC_CB_PREPARE_LIST(inFrame, connection, command, invokeCallbackOf) {
        let receivedData = {
            items: []
        };
        const uriService = app.getService('URIService');
        const contentMapping = getContentMapping();
        const office365Mapping = getOffice365Mapping();
        const symbolMapping = getSymbolMapping();
        const totalLength = wpCalcDatalen(inFrame);
        let pos = 5;

        while ((totalLength + 5) > pos) {
            const data = {};

            let blockLength = ((inFrame[pos] << 8) | inFrame[pos+1]);
            pos += 2;

            if (blockLength > 0) {
                data.appId = helper.toHexString(inFrame.slice(pos, pos+8));
                pos += 8;

                data.contentType = contentMapping[inFrame[pos]];
                if(data.contentType === 'Office365') {
                    data.contentType = office365Mapping[inFrame[pos]];
                }
                pos++;

                data.previewAvailable = inFrame[pos] === 0x01;
                pos++;

                data.symbol = symbolMapping[inFrame[pos]];
                pos++;

                const titleLength = ((inFrame[pos] << 8) | inFrame[pos+1]);
                pos += 2;

                data.title = uriService.decodeFull(parseStringFromArray(inFrame, pos, titleLength));
                pos += titleLength;
            }

            receivedData.items.push(data);
        }

        invokeCallbackOf(command, receivedData);
    },

    WPC_CB_OUTPUT_MIRRORING: function WPC_CB_OUTPUT_MIRRORING(inFrame, connection, command, invokeCallbackOf) {
        let receivedData = {};
        receivedData.on = inFrame[4] === 0x01;

        invokeCallbackOf(command, receivedData);
    },

    /**
     * parses the control screen resolution
     *
     * @param inFrame: the received data
     * @param connection: the websocket connection
     * @param invokeCallbackOf: the callback to where the command is passed
     *
     */
    WPC_CB_CONTROL_SCREEN_RESOLUTION: function WPC_CB_CONTROL_SCREEN_RESOLUTION(inFrame, connection, command, invokeCallbackOf) {
        const resolution = helper.findKeyFromValue(states.hdmiResolutions, inFrame[4]);
        invokeCallbackOf(command, { resolution });
    }
};
