module.exports={
    "roles": {
        "allAccess": {
            "show": true
        },
        "About": {
            "show": false
        },
        "Annotation": {
            "show": true,
            "clearSnapshot": true,
            "close": true,
            "collab": false,
            "statusWidget": true
        },
        "AppRecording": {
            "show": true
        },
        "Apps": {
            "show": true
        },
        "AudioSettings": {
            "show": false
        },
        "Authentication": {
            "statusWidget": true
        },
        "Backdrop": {
            "show": true
        },
        "Bookmarks": {
            "show": true
        },
        "CloseAllWindows": {
            "show": true
        },
        "CloudLogin": {
            "show": true,
            "statusWidget": true
        },
        "CloudSettings": {
            "show": false
        },
        "CollaborationSettings": {
            "show": false
        },
        "ColorPicker": {
            "show": false
        },
        "ControlCenter": {
            "show": true
        },
        "ControlCenterV3": {
            "show": true
        },
        "controlCenterWidgetRecord": {
            "show": true
        },
        "ControlScreen": {
            "show": false
        },
        "DiscoveryBox": {
            "show": false
        },
        "DownloadApps": {
            "show": true
        },
        "DualViewerMode": {
            "show": false
        },
        "EndPresentation": {
            "show": true
        },
        "Ethernet2Settings": {
            "show": false
        },
        "EthernetSettings": {
            "show": false
        },
        "EventList": {
            "show": true
        },
        "FactoryReset": {
            "show": false
        },
        "FileBox": {
            "show": true
        },
        "FileBrowser": {
            "show": true
        },
        "FileBrowserV3": {
            "show": true
        },
        "Filelist": {
            "show": true
        },
        "FirewallSettings": {
            "show": false
        },
        "Framebox": {
            "show": true
        },
        "frameboxes": {
            "show": true
        },
        "Freeze": {
            "show": true,
            "statusWidget": true
        },
        "FtpSettings": {
            "show": false
        },
        "FwUpdate": {
            "show": true,
            "update": false
        },
        "GeneralSettings": {
            "show": false
        },
        "Groupwork": {
            "show": true
        },
        "GroupworkV3": {
            "show": true
        },
        "HDCPSettings": {
            "show": false
        },
        "HelpPage": {
            "show": true
        },
        "InputSettings": {
            "show": false
        },
        "Keyboard": {
            "show": true
        },
        "LanguageSelect": {
            "show": true,
            "statusWidget": true,
            "actionWidget": false
        },
        "Livestream": {
            "load": true
        },
        "LockIndicator": {
            "show": true
        },
        "login": {
            "show": true
        },
        "LoginSettings": {
            "show": false
        },
        "MagicPen": {
            "show": true
        },
        "MainMenu": {
            "show": true
        },
        "Matrix": {
            "show": true
        },
        "MatrixBox": {
            "show": true
        },
        "MatrixFlap": {
            "show": true
        },
        "MatrixGrid": {
            "show": true
        },
        "MatrixMain": {
            "show": true
        },
        "MatrixNetdriveSettings": {
            "show": true
        },
        "MatrixPreview": {
            "show": true
        },
        "MatrixSettings": {
            "show": false
        },
        "MediaCast": {
            "show": true
        },
        "mediaListCloud": {
            "show": true
        },
        "mediaListLocal": {
            "show": true
        },
        "MeetingDetails": {
            "show": true
        },
        "Menu": {
            "show": true
        },
        "MirrorSettings": {
            "show": false
        },
        "Mirroring": {
            "show": true
        },
        "Modal": {
            "show": true
        },
        "NetdriveSettings": {
            "show": false
        },
        "OSDMessage": {
            "show": true
        },
        "Office365": {
            "show": true
        },
        "Office365Settings": {
            "show": true
        },
        "OutputSettings": {
            "show": false
        },
        "OverrideMirror": {
            "show": true,
            "statusWidget": true
        },
        "PeripheralSettings": {
            "show": false
        },
        "Pip": {
            "show": true,
            "statusWidget": true
        },
        "ProxySettings": {
            "show": false
        },
        "PushIndicator": {
            "show": true
        },
        "QrCode": {
            "show": true
        },
        "Record": {
            "show": true,
            "statusWidget": true
        },
        "RecordSettings": {
            "show": false
        },
        "RecordStreamSettings": {
            "show": false
        },
        "RoutingSettings": {
            "show": false
        },
        "SecuritySettings": {
            "show": false
        },
        "SelectionModalV3": {
            "show": true
        },
        "SetRegion": {
            "save": false
        },
        "Settings": {
            "show": false
        },
        "Snapshot": {
            "show": true
        },
        "SoftwarePackages": {
            "show": true
        },
        "SourcesMenu": {
            "show": true
        },
        "SplashScreen": {
            "show": true
        },
        "StreamInput": {
            "show": true,
            "statusWidget": true
        },
        "Streaming": {
            "show": true,
            "statusWidget": true
        },
        "StreamingSettings": {
            "show": false
        },
        "SupportPin": {
            "show": true
        },
        "SystemReboot": {
            "show": false
        },
        "UpDownloads": {
            "show": true,
            "statusWidget": true
        },
        "UploadProgress": {
            "show": true
        },
        "UploadTargetSelection": {
            "show": true
        },
        "UserSettings": {
            "show": true,
            "load": true
        },
        "vMeeting": {
            "show": true,
            "admin": false
        },
        "VMeetingDetails": {
            "show": true
        },
        "vMeetingSettings": {
            "show": false
        },
        "VisualizerPreview": {
            "show": false
        },
        "VisualizerSettings": {
            "show": false
        },
        "Volume": {
            "show": true,
            "statusWidget": true
        },
        "VolumeSettings": {
            "show": false
        },
        "Webcasting": {
            "show": true,
            "statusWidget": true
        },
        "WebconferenceSettings": {
            "show": false
        },
        "WlanSettings": {
            "show": false
        },
        "ZoomSettings": {
            "show": false
        },
        "Troubleshoot": {
            "show": false
        },
        "RoomControllerSettings": {
            "show": false
        }
    }
}
