// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"qr-code-header\" data-nav-area=\".close-modal\" data-nav-trapped>\n                <button class=\"request-close pull-right\" tabindex=\"-1\">\n                    <div class=\"icon-v3-close\"></div>\n                </button>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"qr-code-modal\">\n    <div class=\"qr-code-content\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxAux") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + "\n        <div class=\"col-xs-1 qr-code-arrow\" >\n            <button class=\"btn-no-style qr-code-back focusable\" id=\"qr-code-back\">\n                <div class=\"icon-back1\"></div>\n            </button>\n        </div>\n        <div class=\"col-xs-10\">\n            <h2 class=\"qr-code-title\"></h2>\n            <div class=\"qr-code-info hidden\">\n                <div class=\"qr-code-subtitle\"></div>\n                <div class=\"qr-code-view\" id=\"qr-code-container\">\n                    <div class=\"qr-code-big\">\n                        <div id=\"qr-code-big\" class=\"qr-code-container\"></div>\n                    </div>\n                    <div class=\"qr-code-small\">\n                        <div id=\"qr-code-small\" class=\"qr-code-container\"></div>\n                    </div>\n                </div>\n                <div class=\"qr-code-url\" id=\"qr-code-url\"></div>\n                <p class=\"qr-code-network-info\" id=\"qr-code-network-info\"></p>\n            </div>\n            <div class=\"qr-code-error-msg hidden\" id=\"qr-code-msg\" nav-area=\".focusable\">\n                <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.turn_on_streaming",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":19},"end":{"line":32,"column":50}}}))
    + "</p>\n                <input type=\"button\" class=\"btn-form form-action-submit start-streaming focusable\"\n                       value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"control_center.streaming_start",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":30},"end":{"line":34,"column":68}}}))
    + "\"/>\n            </div>\n            <div class=\"qr-code-error-msg hidden\" id=\"qr-code-msg-error\" nav-area=\".focusable\">\n                <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.qr_code_warning",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":19},"end":{"line":37,"column":48}}}))
    + "</p>\n                <input type=\"button\" class=\"btn-form form-action-submit open-streaming focusable\"\n                       value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.qr_code_warning_button",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":30},"end":{"line":39,"column":66}}}))
    + "\"/>\n            </div>\n            <div class=\"qr-code-error-msg hidden\" id=\"qr-code-msg-error-interface\" nav-area=\".focusable\">\n                <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.qr_code_warning_interface",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":19},"end":{"line":42,"column":58}}}))
    + "</p>\n                <input type=\"button\" class=\"btn-form form-action-submit open-settings focusable\"\n                       value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.qr_code_warning_button",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":30},"end":{"line":44,"column":66}}}))
    + "\"/>\n            </div>\n        </div>\n        <div class=\"col-xs-1 qr-code-arrow\">\n            <button class=\"btn-no-style qr-code-forward focusable\" id=\"qr-code-forward\">\n                <div class=\"icon-forward1\"></div>\n            </button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});
