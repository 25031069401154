'use strict';

var app = require('../../../app');
var rebootTpl = require('./system-reboot.hbs');

app.component('SystemReboot', {
    template: rebootTpl,

    initialize: function() {
        this.remote = this.getService('RemoteService');
    },

    serialize: function() {
        return {
            'cynapName': app.getService('Model-View').name
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindDOMEvents();
    },

    bindDOMEvents: function() {
        this.$rebootBtn.on('click', this.rebootHandler.bind(this));
        this.$stopBtn.on('click', this.stopHandler.bind(this));
        this.$actionBtn.on('click', this.showWarningHandler.bind(this));
    },

    storeSelectors: function() {
        this.$actionBtn = this.$el.find('.show-warning-action');
        this.$stopBtn = this.$el.find('.stop-action');
        this.$rebootBtn = this.$el.find('.reboot-action');
        this.$step1 = this.$el.find('.system-reboot-step-1');
        this.$step2 = this.$el.find('.system-reboot-step-2');
        this.$step3 = this.$el.find('.system-reboot-step-3');
    },

    /**
     * Reboot Cynap.
     */
    rebootHandler: function() {
        this.$actionBtn.hide();

        this.deviceConnection
            .send('setSystemReboot')
            .then(this.handleReboot.bind(this));

        this.$step2.slideUp(150);
        this.$step3.slideDown(150);
    },

    /**
     * Don't do reboot and go back to the startscreen.
     */
    stopHandler: function() {
        this.$step2.slideUp();
        this.$step1.slideDown();

        setTimeout(function() {
            this.remote.focus(this.$actionBtn.get(0));
        }.bind(this), 100);
    },

    /**
     * Show the second warning before rebooting.
     */
    showWarningHandler: function() {
        this.$step1.slideUp();
        this.$step2.slideDown();

        setTimeout(function() {
            this.remote.focus(this.$stopBtn.get(0));
        }.bind(this), 100);
    },

    handleReboot: function() {
        this.emit('system-reboot.started');
    },

    hasChanges: function() {
        return {
            hasChanges: false,
            invalid: false
        };
    }
});
