'use strict';

var app = require('../../app');

var mirroringTpl = require('./mirroring.hbs');

app.component('Mirroring', {
    template: mirroringTpl,

    /**
     * @method initialize
     */
    initialize: function() {
        this.bindEvents();
        this.emit('framebox.coming');

        return this.startMirroring();
    },

    bindEvents: function() {
        this.on('main-loop.update', this.onUpdate.bind(this));

        this.on('overlay.opened-end', function() {
            this.$el.find('#loader').addClass('loader');
        }.bind(this));

        this.on('menu.closed', this.onMenuClosed.bind(this));
    },

    onMenuClosed: function() {
        this.emit('overlay.close');
    },

    onUpdate: function() {
        this.deviceConnection
            .send('getMirrorState')
            .then(this.onMirrorState.bind(this));
    },

    /**
     * @method onMirrorState
     */
    onMirrorState: function(data) {
        if (data.mirrorState === 0) {
            this.emit('overlay.close');
            this.emit('menu.close');
        }
    },

    /**
     * @method startMirroring
     * @returns {*}
     */
    startMirroring: function() {
        this.deviceConnection
            .send('setMirrorState', {
                enable: true
            });
    },

    /**
     * @method destroy
     */
    destroy: function() {
        this.deviceConnection
            .send('setMirrorState', {
                enable: false
            });
    }
});
