// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <form id=\"debug-level-item-form"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" name=\"debug-level-item-form"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n            <div class=\"settings-list\">\n                <div class=\"row settings-list-secundary\" data-nav-area=\"select, .is-focusable\" data-nav-trapped>\n                    <div class=\"col-xs-2\">\n                        <h2 class=\"input-label\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</h2>\n                    </div>\n                    <div class=\"col-xs-4\">\n                        <div id=\"level-select-container"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"></div>\n                    </div>\n                </div>\n                <div id=\"form-action-container"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\"form-primary-background\"></div>\n            </div>\n        </form>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"debug-settings\" class=\"settings-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"list") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":16,"column":13}}})) != null ? stack1 : "")
    + "\n    <form id=\"debug-settings-form\" name=\"debug-settings-form\">\n        <!-- System analysis -->\n        <div class=\"row settings-list-secundary\"\n             data-nav-area=\".btn-switch\"\n             data-nav-trapped\n             data-nav-area-default>\n            <div class=\"col-xs-4\">\n                <h2 class=\"input-label\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(alias1,"settings.system_analysis",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":40},"end":{"line":25,"column":72}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-2\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"systemAnalysis\"\n                           id=\"systemAnalysis\"\n                           type=\"checkbox\"\n                           class=\"btn-switch\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"form-action-container\" class=\"form-primary-background\"></div>\n    </form>\n</div>\n";
},"useData":true});
