// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return " has-title-right";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"title-left",{"name":"if_equals","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":12,"column":34}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":41,"column":31}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"volume",{"name":"if_equals","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":20},"end":{"line":50,"column":34}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"mute",{"name":"if_equals","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":20},"end":{"line":63,"column":34}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"pin",{"name":"if_equals","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":20},"end":{"line":76,"column":34}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"url",{"name":"if_equals","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":20},"end":{"line":82,"column":34}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"message",{"name":"if_equals","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":20},"end":{"line":88,"column":34}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"annotation",{"name":"if_equals","hash":{},"fn":container.program(36, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":20},"end":{"line":181,"column":34}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"groupEnd") : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":183,"column":20},"end":{"line":185,"column":27}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"framebox-control-bar-separator left\"></div>\n                        <li class=\"framebox-header-type-control framebox-header-type-control-title left\"\n                            id=\"framebox-"
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-control-title-left\"></li>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li id=\"framebox-"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-control-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"hasSubMenu") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":28},"end":{"line":18,"column":35}}})) != null ? stack1 : "")
    + "                            <button class=\"btn-no-style framebox-bar-item "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"wide") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":74},"end":{"line":19,"column":102}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"noIcon") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":103},"end":{"line":19,"column":136}}})) != null ? stack1 : "")
    + "\"\n                                    id=\"framebox-"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-control-button-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"data-nav-limit") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":36},"end":{"line":21,"column":84}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"states") : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":32},"end":{"line":38,"column":41}}})) != null ? stack1 : "")
    + "                            </button>\n                        </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"sub-menu-container is-"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"></div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "wide";
},"11":function(container,depth0,helpers,partials,data) {
    return "no-icon";
},"13":function(container,depth0,helpers,partials,data) {
    return "data-nav-limit";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <span class=\"framebox-header-control framebox-header-control-state-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":23,"column":103},"end":{"line":23,"column":111}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"titleKey") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":40},"end":{"line":30,"column":47}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"icon") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":40},"end":{"line":33,"column":47}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"infoKey") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":40},"end":{"line":36,"column":47}}})) != null ? stack1 : "")
    + "                                    </span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <span id=\"framebox-btn-title\" class=\"framebox-header-control-text\n                                            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isActiveText") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":44},"end":{"line":26,"column":91}}})) != null ? stack1 : "")
    + "\n                                            "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"icon") : depth0),{"name":"unless","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":44},"end":{"line":27,"column":84}}})) != null ? stack1 : "")
    + "\">\n                                                "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"titleKey") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":48},"end":{"line":28,"column":67}}}))
    + "\n                                            </span>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return " is-active-text";
},"19":function(container,depth0,helpers,partials,data) {
    return " no-icon";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <span id=\"framebox-btn-icon\" class=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"icon") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isActive") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":93},"end":{"line":32,"column":131}}})) != null ? stack1 : "")
    + "\"></span>\n";
},"22":function(container,depth0,helpers,partials,data) {
    return " is-active";
},"24":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <span id=\"framebox-btn-info\" class=\"framebox-header-control-text-info\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"infoKey") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":115},"end":{"line":35,"column":133}}}))
    + "</span>\n";
},"26":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"framebox-header-type-control control-volume\" id=\"framebox-"
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-control-volume\">\n                            <div class=\"volume-v3\"></div>\n                            <button class=\"btn-no-style framebox-bar-item\">\n                                <span class=\"icon-v2-volume\"></span>\n                            </button>\n                        </li>\n";
},"28":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"framebox-header-type-control\" id=\"framebox-"
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-control-mute\">\n                            <button class=\"btn-no-style framebox-bar-item\">\n                                <span class=\"framebox-header-control framebox-header-control-state-mute\">\n                                    <span class=\"icon-v2-mute-speaker is-active\"></span>\n                                </span>\n                                <span class=\"framebox-header-control framebox-header-control-state-unmute\">\n                                    <span class=\"icon-v2-mute-speaker\"></span>\n                                </span>\n                            </button>\n                        </li>\n";
},"30":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"framebox-header-type-control\" id=\"framebox-"
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-control-pin\">\n                            <button class=\"btn-no-style framebox-bar-item\">\n                                <span class=\"framebox-header-control framebox-header-control-state-pinned\">\n                                    <span class=\"icon-qa-pin-outline is-active\"></span>\n                                </span>\n                                <span class=\"framebox-header-control framebox-header-control-state-unpinned\">\n                                    <span class=\"icon-qa-pin-outline\"></span>\n                                </span>\n                            </button>\n                        </li>\n";
},"32":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"framebox-header-type-control\" id=\"framebox-"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-control-url\">\n                            <div class=\"framebox-bar-item type-input framebox-"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-url\"></div>\n                        </li>\n";
},"34":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"framebox-header-type-control\" id=\"framebox-"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-control-message\">\n                            <div class=\"framebox-bar-item type-input framebox-"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-message transparent\"></div>\n                        </li>\n";
},"36":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),"pen",{"name":"if_equals","hash":{},"fn":container.program(37, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":24},"end":{"line":100,"column":38}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),"tool",{"name":"if_equals","hash":{},"fn":container.program(39, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":24},"end":{"line":109,"column":38}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),"rubber",{"name":"if_equals","hash":{},"fn":container.program(41, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":24},"end":{"line":120,"column":38}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),"color",{"name":"if_equals","hash":{},"fn":container.program(43, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":123,"column":24},"end":{"line":132,"column":38}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),"stroke-strength",{"name":"if_equals","hash":{},"fn":container.program(45, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":134,"column":24},"end":{"line":141,"column":38}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),"opacity",{"name":"if_equals","hash":{},"fn":container.program(47, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":143,"column":24},"end":{"line":150,"column":38}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),"snapshot",{"name":"if_equals","hash":{},"fn":container.program(49, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":152,"column":24},"end":{"line":160,"column":38}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),"undo",{"name":"if_equals","hash":{},"fn":container.program(51, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":162,"column":24},"end":{"line":170,"column":38}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),"redo",{"name":"if_equals","hash":{},"fn":container.program(53, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":172,"column":24},"end":{"line":180,"column":38}}})) != null ? stack1 : "");
},"37":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class=\"framebox-header-type-control framebox-annotation-control\"\n                                id=\"framebox-"
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-control-pen\">\n                                <button class=\"btn-no-style framebox-bar-item active\"\n                                        data-action=\"set-tool\"\n                                        data-tool=\"pen\">\n                                    <span class=\"icon-v2-pen\"></span>\n                                </button>\n                            </li>\n";
},"39":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class=\"framebox-header-type-control framebox-annotation-control js-toggle-annotation\"\n                                id=\"framebox-"
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-control-tool\">\n                                <button class=\"btn-no-style framebox-bar-item\">\n                                    <span class=\"icon-v2-oval tool-icon\"></span>\n                                </button>\n                            </li>\n";
},"41":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class=\"framebox-header-type-control framebox-annotation-control\"\n                                id=\"framebox-"
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-control-rubber\">\n                                <button class=\"btn-no-style framebox-bar-item\"\n                                        data-action=\"set-tool\"\n                                        data-tool=\"rubber\">\n                                    <span class=\"icon-v2-eraser\"></span>\n                                </button>\n                            </li>\n";
},"43":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class=\"framebox-header-type-control framebox-annotation-control js-toggle-annotation\"\n                                id=\"framebox-"
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-control-color\">\n                                <div class=\"annotation-color\">\n                                    <button class=\"btn-no-style framebox-bar-item\">\n                                        <span class=\"color red active\" data-color=\"red\"></span>\n                                    </button>\n                                </div>\n                            </li>\n";
},"45":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class=\"framebox-header-type-control framebox-annotation-control js-toggle-annotation\"\n                                id=\"framebox-"
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-control-stroke-strength\">\n                                <button class=\"btn-no-style framebox-bar-item\">\n                                    <span class=\"icon-v2-line-width-light\"></span>\n                                </button>\n                            </li>\n";
},"47":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class=\"framebox-header-type-control framebox-annotation-control js-toggle-annotation\"\n                                id=\"framebox-"
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-control-opacity\">\n                                <button class=\"btn-no-style framebox-bar-item\">\n                                    <span class=\"icon-v2-opacity\"></span>\n                                </button>\n                            </li>\n";
},"49":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class=\"framebox-header-type-control framebox-annotation-control\"\n                                id=\"framebox-"
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-control-snapshot\"\n                                data-action=\"snapshot\">\n                                <button class=\"btn-no-style framebox-bar-item\">\n                                    <span class=\"icon-v2-snapshot\"></span>\n                                </button>\n                            </li>\n";
},"51":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class=\"framebox-header-type-control framebox-annotation-control\"\n                                id=\"framebox-"
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-control-undo\"\n                                data-action=\"undo\">\n                                <button class=\"btn-no-style framebox-bar-item\">\n                                    <span class=\"icon-v2-backward\"></span>\n                                </button>\n                            </li>\n";
},"53":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class=\"framebox-header-type-control framebox-annotation-control\"\n                                id=\"framebox-"
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"index") : depths[1]), depth0))
    + "-header-control-redo\"\n                                data-action=\"redo\">\n                                <button class=\"btn-no-style framebox-bar-item\">\n                                    <span class=\"icon-v2-forward\"></span>\n                                </button>\n                            </li>\n";
},"55":function(container,depth0,helpers,partials,data) {
    return "                        <li class=\"framebox-header-control-spacing\"></li>\n";
},"57":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div id=\"framebox-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"index","hash":{},"data":data,"loc":{"start":{"line":191,"column":30},"end":{"line":191,"column":39}}}) : helper)))
    + "-header-control-page-count\" class=\"framebox-header-page-count-container\">\n                <div class=\"framebox-control-bar-separator right\"></div>\n            </div>\n";
},"59":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"framebox-header-type-control-title-container\">\n                <div class=\"framebox-header-type-control framebox-header-type-control-title right\"\n                     id=\"framebox-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"index","hash":{},"data":data,"loc":{"start":{"line":199,"column":34},"end":{"line":199,"column":43}}}) : helper)))
    + "-header-control-title-right\"></div>\n                <div class=\"framebox-control-bar-separator right\"></div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"framebox-bar framebox-control-bar-controls\" data-index=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":1,"column":68},"end":{"line":1,"column":79}}}) : helper)))
    + "\">\n    <div id=\"framebox-controls-container-main\" class=\"framebox-controls-container"
    + ((stack1 = (lookupProperty(helpers,"if_or")||(depth0 && lookupProperty(depth0,"if_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"titleRight") : depth0),(depth0 != null ? lookupProperty(depth0,"pageCount") : depth0),{"name":"if_or","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":81},"end":{"line":2,"column":138}}})) != null ? stack1 : "")
    + "\">\n        <nav id=\"nav-controls-container\" class=\"framebox-nav-container"
    + ((stack1 = (lookupProperty(helpers,"if_or")||(depth0 && lookupProperty(depth0,"if_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"titleRight") : depth0),(depth0 != null ? lookupProperty(depth0,"pageCount") : depth0),{"name":"if_or","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":70},"end":{"line":3,"column":127}}})) != null ? stack1 : "")
    + "\">\n            <ul id=\"controls-list\" class=\"framebox-bar framebox-controls list list-centered\"\n                data-scrolling-spacing=\"60\">\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":186,"column":25}}})) != null ? stack1 : "")
    + "            </ul>\n        </nav>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"pageCount") : depth0),{"name":"if","hash":{},"fn":container.program(57, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":190,"column":8},"end":{"line":194,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"titleRight") : depth0),{"name":"if","hash":{},"fn":container.program(59, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":196,"column":8},"end":{"line":202,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <div id=\"framebox-controls-container-submenu\" class=\"framebox-controls-container\">\n        <div id=\"framebox-header-url\" class=\"framebox-header-url framebox-submenu list\"></div>\n        <div id=\"framebox-header-message\" class=\"framebox-header-message framebox-submenu list\"></div>\n        <div id=\"framebox-header-volume\" class=\"framebox-header-volume framebox-submenu\"></div>\n        <div id=\"framebox-header-annotation\" class=\"framebox-header-annotation framebox-submenu\"></div>\n        <div id=\"framebox-header-preset\" class=\"framebox-header-preset framebox-submenu\"></div>\n        <div id=\"framebox-header-focus\" class=\"framebox-header-focus framebox-submenu\"></div>\n    </div>\n</div>\n\n";
},"useData":true,"useDepths":true});
