'use strict';

var app = require('../../../../../app');
var youtubeStreamSettingsTpl = require('./youtube-stream-settings.hbs');
var platform = require('../../../../../../platform/platform');
var $ = require('jquery');

var loginStates = {
    none: 'none',
    disconnected: 'disconnected',
    authenticating: 'authenticating',
    authenticated: 'authenticated',
    tokenExpired: 'token-expired',
    failed: 'failed'
};

app.component('YouTubeStreamSettings', {
    template: youtubeStreamSettingsTpl,

    /**
     * Get access key.
     * @returns {{roleName: string, roleKey: string}}
     */
    getAccessKey: function() {
        return {
            'roleName': 'StreamingSettings',
            'roleKey': 'show'
        };
    },

    /**
     * Initialize.
     */
    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.webcastingService = this.getService('WebcastingService');
    },

    /**
     * @returns {Object}
     */
    serialize: function() {
        return {
            showFullsize: platform.checks.isFullsize()
        };
    },

    /**
     * Called after DOM is ready.
     */
    postPlaceAt: function() {
        this.storeSelectors();
        this.createSelects();
        this.initForm();
        this.bindEvents();

        this.loadBackendSettings()
            .then(function() {
                this.bindDOMEvents();
                this.updateLoginState({
                    state: this.webcastingService.youtubeLoginState.currentState
                });
            }.bind(this));
    },

    /**
     * Init form.
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#youtube-stream-settings'),
            validationContainer: '.input-group'
        });

        this.handleLoginLogoutBtn(true);
    },

    /**
     * Store all selectors.
     */
    storeSelectors: function() {
        this.$youtubeLoginBtnContainer = this.$el.find('#youtube-stream-login-btn-container');
        this.$youtubeLoginBtn = this.$el.find('.youtube-login');
        this.$youtubeLogoutBtnContainer = this.$el.find('#youtube-stream-logout-btn-container');
        this.$youtubeLogoutBtn = this.$el.find('.youtube-logout');
        this.$youtubeSettingsContainer = this.$el.find('#youtube-stream-event-container');
    },

    createSelects: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#youtube-privacy-select'),
            label: 'settings.privacy',
            native: true,
            name: 'youtubePrivacy',
            items: [
                {
                    text: 'settings.private',
                    value: 'private'
                },
                {
                    text: 'settings.public',
                    value: 'public'
                },
                {
                    text: 'settings.unlisted',
                    value: 'unlisted'
                }
            ]
        });
    },

    /**
     * Bind DOM events.
     */
    bindDOMEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.$el.on('click', '.youtube-login', function() {
            this.emit('streaming-settings.unsaved-changes.handle', this.login.bind(this));
        }.bind(this));
        this.$el.on('click', '.youtube-logout', this.logout.bind(this));
    },

    /**
     * Bind events.
     */
    bindEvents: function() {
        this.on('youtube-stream.login-state.changed', this.updateLoginState.bind(this));
        this.on('youtube-stream.checkActionForm', this.checkActionForm.bind(this));
        this.on('youtube-stream.cancel', this.cancelHandler.bind(this));
        this.on('youtube-stream.save', this.saveHandler.bind(this));
    },

    /**
     * Handle form change.
     */
    handleFormChange: function() {
        this.checkActionForm();
        this.emit('streaming-settings.openActionView', true);
    },

    /**
     * Check action form and call event for handling the action form.
     */
    checkActionForm: function() {
        this.emit('streaming-settings.checkActionFormComponent', (this.form.validate()));
    },

    /**
     * Handle login if login btn is pressed.
     * Login window is shown and settings overlay is closed.
     *
     * If token is expired, the login data is still saved in backend. In this case, do not close the overlay
     * because the login window isn't appearing and the login is done automatically.
     *
     */
    login: function() {
        this.changes = false;
        this.emit('youtube-stream.login');

        this.deviceConnection
            .send('setWebcastYoutubeConnect')
            .then(function() {
                if (this.webcastingService.youtubeLoginState.currentState !== loginStates.tokenExpired) {
                    this.emit('overlay.close');
                }
            }.bind(this));
    },

    /**
     * Handle logout if logout btn is pressed.
     */
    logout: function() {
        this.emit('overlay.remote.focus', true);

        if (this.webcastingService.isActive()) {
            this.webcastingService.setWebcastActiveState('off');
        }

        this.deviceConnection
            .send('setWebcastYoutubeLogout')
            .done(function() {
                // RELEASE-2262 - Webcast: Save/discard dialog appears when pressing on Login button (YouTube)
                if (this.changes) {
                    this.form.resetValues();
                }

                // Disable logout btn as long as logout is in progress.
                if (this.webcastingService.youtubeLoginState.currentState !== loginStates.disconnected) {
                    this.toggleLogoutState('logging-out');
                    this.$youtubeLogoutBtn.prop('disabled', true);
                    this.hideYoutubeSettingsContainer();
                }
            }.bind(this));
    },

    /**
     * Handle login and logout button (hidden or shown - depending on login status)
     * @param showLogin true: show login btn, false: show logout btn and hide event list
     */
    handleLoginLogoutBtn: function(showLogin) {
        if (showLogin) {
            this.$youtubeLoginBtn.prop('disabled', false);
            this.$youtubeLoginBtnContainer.show();
            this.$youtubeLogoutBtnContainer.hide();
            this.hideYoutubeSettingsContainer();
        } else {
            this.$youtubeLoginBtnContainer.hide();
            this.$youtubeLogoutBtnContainer.show();
            this.showYoutubeSettingsContainer(true);
        }
    },

    saveHandler: function() {
        if (this.form.validate()) {
            var data = this.form.serialize();

            this.emit('overlay.remote.focus', true);

            this.deviceConnection
                .send([
                    {
                        command: 'setWebcastYoutubeParameters',
                        data: {
                            privacy: data.youtubePrivacy,
                            title: data.youtubeTitle
                        }
                    }
                ])
                .done(function() {
                    this.form.setDefaultValues();
                }.bind(this));
        }
    },

    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
    },

    /**
     * Show youtube settings (title, privacy) if logged in.
     * @param animate
     */
    showYoutubeSettingsContainer: function(animate) {
        if (animate) {
            this.$youtubeSettingsContainer
                .stop()
                .slideDown(230);
        } else {
            this.$youtubeSettingsContainer.show();
        }

        this.$youtubeSettingsContainer.css('height', 'auto');
        this.$el.parent().css('height', 'auto');
    },

    /**
     * Hide youtube settings (title, privacy) if logged out.
     */
    hideYoutubeSettingsContainer: function() {
        this.$youtubeSettingsContainer
            .stop()
            .slideUp(300);

        this.$youtubeSettingsContainer.css('height', '');
        this.$el.parent().css('height', '');
    },

    /**
     * Update login state and handle login/logout btn.
     *
     * @param state current state
     */
    updateLoginState: function(state) {
        switch (state.state) {
            case loginStates.tokenExpired:
            case loginStates.disconnected:
                this.toggleLoginState('disconnected');
                this.handleLoginLogoutBtn(true);
                this.$youtubeLoginBtn.prop('disabled', false);
                break;
            case loginStates.failed:
                this.toggleLoginState('failed');
                this.handleLoginLogoutBtn(true);
                this.$youtubeLoginBtn.prop('disabled', false);
                break;
            case loginStates.authenticating:
                this.toggleLoginState('authenticating');
                this.handleLoginLogoutBtn(true);
                this.$youtubeLoginBtn.prop('disabled', true);
                break;
            case loginStates.authenticated:
                this.toggleLogoutState('authenticated');
                this.toggleLoginState('authenticated');
                this.handleLoginLogoutBtn(false);
                this.$youtubeLoginBtn.prop('disabled', true);
                this.$youtubeLogoutBtn.prop('disabled', false);
                break;
        }
    },

    /**
     * Toggle login state.
     *
     * @param {string} name
     */
    toggleLoginState: function(name) {
        this.$youtubeLoginBtnContainer.attr('data-youtube-login-status', 'is-' + name);
    },

    /**
     * Toggle logout state.
     * @param {string} name
     */
    toggleLogoutState: function(name) {
        this.$youtubeLogoutBtnContainer.attr('data-youtube-logout-status', 'is-' + name);
    },

    /**
     * Load backend settings via wolfprot.
     */
    loadBackendSettings: function() {
        var dfd = $.Deferred();

        this.deviceConnection
            .send([
                'getWebcastYoutubeParameters'
            ])
            .then(function(youtubeParam) {
                this.formData = {
                    'youtubePrivacy': youtubeParam.privacy,
                    'youtubeTitle': youtubeParam.title
                };

                this.form.setValues(this.formData);
                this.form.setDefaultValues();

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Destroy
     */
    destroy: function() {
        this.formManager.destroy(this.form);
    }
});
