'use strict';

module.exports.namespaced = true;

module.exports.state = {
    uiScalingSize: ''
};

module.exports.mutations = {
    SET_UI_SCALING_SIZE(state, size) {
        state.uiScalingSize = size;
    }
};

module.exports.actions = {
    fetchUiScalingSize({ commit }, app) {
        app.getService('CustomUiSettings').getSetting('uiScalingSize')
            .then(size => {
                commit('SET_UI_SCALING_SIZE', size);
            });
    }
};

module.exports.getters = {
    getUiScalingSize: state => {
        return state.uiScalingSize;
    }
};
