'use strict';

var $ = require('jquery');
var Velocity = require('velocity');

/*
 * Using velocity.js without globalizing jQuery
 */
if (!$.fn.velocity) {
    $.fn.velocity = Velocity.animate;
}
