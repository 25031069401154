'use strict';

const RedrawEngine = require('../redraw-engine');
const rbac = require('../rbac/rbac');
const { pollHelper, wolfprot } = require('../app/components-vue/util');

const sourceMapping = {
    'hdmi1': 'main',
    'hdmi2': 'hdmi2Out'
};

class PreviewEngine {
    constructor(
        { fps = 5 },
        { width = 1920, height = 1080, output = 'hdmi1' },
        renderCallback
    ) {
        this.redrawOptions = { fps };
        this.size = { width, height };
        this.output = sourceMapping[output];
        this.renderCallback = renderCallback;
        this.stopped = false;
        this.redrawEngine = new RedrawEngine();
        this.image = new Image();

        this.wolfprot = wolfprot();
        this.pollHelper = pollHelper({
            load: this.loadNextFrame.bind(this)
        });
    }

    start() {
        this.redrawEngine.on('redraw.update', function() {
            this.pollHelper.schedulePoll();
        }.bind(this));
        this.redrawEngine.start(this.redrawOptions);
        this.stopped = false;
    }

    stop() {
        this.stopped = true;
        this.redrawEngine.off('redraw.update', this.loadNextFrame.bind(this));
        this.redrawEngine.stop();
    }

    setSize(size) {
        this.size = size;
    }

    setFps(fps) {
        this.redrawOptions.fps = fps;
        this.stop();
        this.start();
    }

    loadNextFrame() {
        if (!rbac.hasAccess('Livestream', 'load')) {
            return;
        }

        this.wolfprot.talk('getPictureCbox', {
            pictureWidth: this.size.width,
            pictureHeight: this.size.height,
            pictureSource: this.output
        }).then(function(data) {
            this.createFrame(data.picture, function(image) {
                if (image && !this.stopped) {
                    this.renderCallback(image);
                }
            }.bind(this));
        }.bind(this));
    }

    createFrame(data, callback) {
        const blob = new Blob([data], { type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(blob);

        this.image.onload = function() {
            callback(this);
            this.onload = null;
            this.src = '';
            URL.revokeObjectURL(imageUrl);
        };

        this.image.src = imageUrl;
    }
}

module.exports = PreviewEngine;
