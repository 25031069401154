'use strict';

var app = require('../../../app');
var selectViewerControlTpl = require('./select-viewer-control.hbs');
var $ = require('jquery');

app.component('SelectViewerControl', {
    template: selectViewerControlTpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.authService = this.getService('AuthenticationService');
        this.deviceService = this.getService('DeviceService');
    },

    serialize: function() {
        return {
            isLivaQ2: this.deviceService.isLivaQ2Hardware()
        };
    },

    postPlaceAt: function() {
        this.bindEvents();
        this.bindDOMEvents();

        if (this.options.configs.userRole) {
            $('.modal').addClass('hidden');
        }
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.select-viewer-control-item', this.selectItem.bind(this));
        this.$el.on('click', '.regulatory-info', this.showRegulatoryInfo.bind(this));
    },

    bindEvents: function() {
        this.on('modal.opened-end', this.checkRole.bind(this));
    },

    /**
     * If role is set as a URL parameter, login immediately.
     * e.g. role=viewer, role=control
     */
    checkRole: function() {
        if (this.options.configs.userRole) {
            this.selectItem();
        }
    },

    /**
     * Select Item and call function from option configs.
     *
     * @param event Current event
     */
    selectItem: function(event) {
        var $el;
        var selected;

        if (event) {
            $el = this.$(event.currentTarget);
            selected = $el.data('item');
        } else {
            selected = this.options.configs.userRole;
        }

        this.emit('modal.close');

        switch (selected) {
            case 'viewer':
                this.options.configs.onViewer();
                break;
            case 'control':
                this.options.configs.onControl();
                break;
        }
    },

    /**
     * Show regulatory info.
     */
    showRegulatoryInfo: function() {
        this.emit('modal.open', {
            id: 'regulatory-info',
            options: {
                user: 'none'
            }
        });
    }
});
