'use strict';

var app = require('../../../app');
var matrixMenu = require('./matrix-menu.hbs');
var $ = require('jquery');

var DURATION = 250;

var powerStates = {
    on: 1,
    off: 0,
    none: -1
};

var TIMEOUT_POWER_ON = 30000;
var TIMEOUT_POWER_OFF = 40000;

app.component('MatrixMenu', {
    template: matrixMenu,

    getAccessKey: function() {
        return {
            'roleName': 'MatrixMain',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.frontendSettings = app.getService('FrontendSettings');
        this.matrixMainService = this.getService('MatrixMainService');
        this.matrixService = this.getService('MatrixService');
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindDOMEvents();
        this.bindEvents();
    },

    serialize: function() {
        var dfd = $.Deferred();

        this.frontendSettings
            .getSettings('matrixAutoStart')
            .then(function(autoStart) {
                dfd.resolve({
                    autoStartEnabled: autoStart
                });
            }.bind(this));

        return dfd.promise();
    },

    storeSelectors: function() {
        this.$matrixMenu = this.$el.find('#matrix-menu');
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.action-btn', this.actionBtnHandler.bind(this));
        this.on('overlay-backdrop.click', this.closeMatrixMenu.bind(this));
    },

    bindEvents: function() {
        this.on('matrix-menu.toggle', this.toggleMatrixMenu.bind(this));
        this.on('matrix-menu.hide', this.closeMatrixMenu.bind(this));
    },

    /**
     * Close or open matrix menu.
     */
    toggleMatrixMenu: function() {
        if (this.$matrixMenu.hasClass('is-open')) {
            this.closeMatrixMenu();
        } else {
            this.openMatrixMenu();
        }
    },

    /**
     * Animate opening Matrix menu.
     */
    openMatrixMenu: function() {
        if (this.$matrixMenu.hasClass('is-open')) {
            return;
        }

        // Hide others.
        this.emit('matrix-box.hide', true);

        this.$matrixMenu
            .addClass('is-open')
            .show()
            .stop()
            .fadeIn({
                duration: DURATION,
                complete: function() {
                    this.emit('overlay-backdrop.show');
                }.bind(this)
            });
    },

    /**
     * Animate closing Matrix menu.
     */
    closeMatrixMenu: function() {
        if (!this.$matrixMenu.hasClass('is-open')) {
            return;
        }
        this.emit('overlay-backdrop.hide');

        this.$matrixMenu
            .removeClass('is-open')
            .stop()
            .fadeOut({
                duration: DURATION
            });
    },

    /**
     * Handle action button click.
     *
     * @param event Current event
     */
    actionBtnHandler: function(event) {
        var $item = this.$(event.currentTarget);
        var action = $item.data('action');

        switch (action) {
            case 'powerOn':
                this.emit('matrix.previews.close');
                this.matrixMainService.handlePowerState(powerStates.on, TIMEOUT_POWER_ON);
                this.matrixService.powerOnStations();
                break;
            case 'powerOff':
                this.emit('matrix.previews.close');
                this.matrixMainService.handlePowerState(powerStates.off, TIMEOUT_POWER_OFF);
                this.matrixService.powerOffStations();
                break;
        }

        this.closeMatrixMenu();
    }
});
