const template = require('./tool-select.html');

module.exports = {
    template,

    props: {
        value: {
            type: String,
            required: false,
            default: 'pen'
        },
        items: {
            type: Array,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        close: {
            type: Boolean,
            required: false,
            default: true
        },
        active: {
            type: Boolean,
            required: true
        }
    },

    data: function() {
        return {
            open: false,
            triggerCoords: { x: 0, y: 0, height: 0 }
        };
    },

    computed: {
        selectedToolIcon: function() {
            return this.items.find(i => i.id === this.value)?.icon;
        }
    },

    watch: {
        close: function(val) {
            if (val) {
                this.open = false;
            }
        },
        open: function(val) {
            if (val) {
                const { x, y, height } = this.$refs.trigger.getBoundingClientRect();
                this.triggerCoords = { x, y, height };
            }
        }
    },

    methods: {
        selectTool: function(item) {
            this.$emit('input', item.id);
            this.open = false;
        },

        menuClick: function() {
            if (!this.active) {
                this.$emit('annotation-start');
            } else {
                this.toggleSubMenu();
            }
        },

        toggleSubMenu: function() {
            this.open = !this.open;
            this.$emit('sub-menu-changed', {
                id: this.id,
                open: this.open
            });
        }
    }
};
