'use strict';

const app = require('../../../../app');
const $ = require('jquery');
const platform = require('../../../../../platform/platform');
const mediaCastSettingsTpl = require('./media-cast-settings.hbs');
const FormActionView = require('../../form-action-view');
const states = require('../../../../states');
const i18n = require('i18next');

app.component('MediaCastSettings', {
    template: mediaCastSettingsTpl,
    actionView: null,

    getAccessKey: function() {
        return {
            'roleName': 'RecordSettings',
            'roleKey': 'show'
        };
    },

    /**
     * @method initialize
     * @author Dominik Matt
     */
    initialize: function() {
        this.formData = {};
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.init = true;
        this.runAnimations = false;

        return this.loadMediaCastSettings();
    },

    /**
     * @method postPlaceAt
     * @author Dominik Matt
     */
    postPlaceAt: function() {
        this.storeSelectors();
        this.createSelects();
        this.initForm();
        this.initFormAction();
        this.bindEvents();
        this.updateForm();
        this.bindDomEvents();
        this.checkStreamInputMode();

        this.init = false;
    },

    /**
     * @method storeSelectors
     */
    storeSelectors: function() {
        this.$streamInput = this.$el.find('.stream-input');
        this.$streamInputAudio = this.$el.find('.stream-input-audio');
        this.$testButtonContainer = this.$el.find('.stream-test-btn-container');
    },

    /**
     * @method bindDomEvents
     */
    bindDomEvents: function() {
        this.form.$el.on('keyup, change', this.handleFormChange.bind(this));
        this.$el.on('click', '.stream-test-action', this.testStream.bind(this));
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.on('overlay.action', this.saveHandler.bind(this));
        this.on('remote.enter.keydown', this.checkActionForm.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
        this.on('settings.cancel-changes', this.cancelHandler.bind(this));
        this.on('lcs-type.changed', this.handleLcsTypeChanged.bind(this));
    },

    /**
     * @method initFormAction
     */
    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container-media-cast'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    /**
     * @method initForm
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#media-cast-settings'),
            validationContainer: '.input-group'
        });
    },

    /**
     * Handle change event after the lcs-type has been changed.
     *
     * @param {Object} options
     */
    handleLcsTypeChanged: function(options) {
        this.checkRecordingType(options.lcsType);
    },

    /**
     * This method is called after type-select has been changed.
     * Here we will show all the correct forms.
     *
     * @param type
     */
    checkRecordingType: function(type) {
        if (type === this.openType) {
            return;
        }

        this.openType = type;

        switch (type) {
            case states.lcsTypes.disabled:
                this.hide();
                break;
            case states.lcsTypes.panoptoRemoteRecorder:
            case states.lcsTypes.panoptoAdhocLegacy:
            case states.lcsTypes.panoptoAdhocOAuth2:
                this.$streamInputAudio.hide();
                this.show();
                break;
            case states.lcsTypes.opencastAdhoc:
            case states.lcsTypes.opencastAuto:
                this.$streamInputAudio.show();
                this.show();
                break;
        }

        this.runAnimations = true;
    },

    /**
     * @method saveHandler
     */
    saveHandler: function() {
        var data = this.form.serialize();

        if (!this.form.validate()) {
            return;
        }

        return this.deviceConnection
            .send([
                {
                    command: 'setRecordingStreamInput',
                    data: {
                        mode: data.recordingStreamMode,
                        audio: data.recordStreamAudio,
                        url: data.recordStreamUrl,
                        offset: 0
                    }
                }
            ])
            .then(function() {
                this.handleMediaCastSave();
                this.form.setDefaultValues();
                this.emit('record-settings.saved');
                this.emit('overlay.remote.focus', true);
            }.bind(this));
    },

    /**
     * @method testStream
     */
    testStream: function() {
        this.deviceConnection
            .send([
                {
                    command: 'setTestMediaCasting'
                }
            ]);

        this.emit('overlay.close');
        this.emit('backdrop.hide');
    },

    /**
     * Calls on clicked the cancel button
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
        this.actionView.close();

        this.$testButtonContainer.show();

        this.handleChanges(false);
    },

    /**
     * @method handleRecordSave
     */
    handleMediaCastSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.$testButtonContainer.show();
        this.actionView.close();
        this.handleChanges(false);
    },

    /**
     * @method handleFormChange
     */
    handleFormChange: function() {
        if (!this.init) {
            this.actionView.open();
            if (platform.checks.isCbox) {
                this.remote.update();
            }
            this.checkActionForm();
            this.checkStreamInputMode();

            this.$testButtonContainer.hide();

            this.handleChanges(true);
        }
    },

    /**
     * @method checkActionForm
     */
    checkActionForm: function() {
        if (!this.init) {
            if (this.form.validate()) {
                this.actionView.enableSubmitButton();
            } else {
                this.actionView.disableSubmitButton();
            }
        }
    },

    /**
     * @method loadRecordSettings
     * @returns {*}
     */
    loadMediaCastSettings: function() {
        return this.deviceConnection
            .send([
                'getRecordingStreamInput'
            ])
            .then(function(recStreamInput) {
                this.formData = {
                    recordingStreamMode: recStreamInput.mode,
                    recordStreamAudio: recStreamInput.audio,
                    recordStreamUrl: recStreamInput.url
                };
            }.bind(this));
    },

    /**
     * @method updateForm
     */
    updateForm: function() {
        this.form.setValues(this.formData);
    },

    /**
     * @method createSelects
     */
    createSelects: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#recording-stream-mode'),
            label: 'settings.record_stream_mode',
            native: true,
            name: 'recordingStreamMode',
            items: [
                {
                    text: i18n.t('settings.none'),
                    value: 'none'
                },
                {
                    text: i18n.t('settings.generic'),
                    value: 'generic'
                },
                {
                    text: i18n.t('settings.rtsp_rtp_tcp'),
                    value: 'rtspRtp'
                },
                {
                    text: i18n.t('settings.rtsp_rtp_udp_multicast'),
                    value: 'multicast'
                }
            ]
        });
    },

    /**
     * @method checkStreamInputMode
     */
    checkStreamInputMode: function() {
        if (this.form.get('recordingStreamMode').value !== 'none') {
            this.$streamInput
                .stop()
                .slideDown();
        } else {
            this.$streamInput
                .stop()
                .slideUp();
        }
    },

    /**
     * Hide media-cast-storage setting.
     */
    hide: function() {
        if (this.runAnimations) {
            this.$el.stop().slideUp(300);
        } else {
            this.$el.stop().hide();
        }
    },

    /**
     * Show media-cast-storage setting.
     */
    show: function() {
        if (this.runAnimations) {
            this.$el.stop().slideDown(300);
        } else {
            this.$el.stop().show();
        }
    },

    /**
     * @method destroy
     */
    destroy: function() {
        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    handleChanges: function(change) {
        var changes = {
            hasChanges: change,
            invalid: false
        };

        if (this.actionView) {
            changes.invalid = $(this.actionView.$submitBtn).prop('disabled');
        }

        this.$el.parent().data('has-changes', changes.hasChanges);
        this.$el.parent().data('has-changes-invalid', changes.invalid);
    }
});
