'use strict';

var app = require('../../../../app');

var controlItems = require('./controls');

/**
 * Mirroring frame
 */
app.component('FrameBoxMirroring', {
    extend: 'FrameBoxBaseType',
    className: 'framebox-view framebox-mirroring-view',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.options.allowedEvents = ['touchstart', 'touchend', 'mousedown', 'mouseup', 'touchmove', 'mousemove'];
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.startControls();
        this.startTouchEventsEntity({
            $actionEl: this.options.$actionEl,
            allowedEvents: this.options.allowedEvents,
            disableSelection: false,
            debugging: false
        });
    },

    /**
     * @method startControls
     */
    startControls: function() {
        this.createComponent({
            type: 'FrameBoxControls',
            container: this.$el,
            index: this.options.index,
            appId: this.options.appId,
            items: controlItems,
            isFullscreen: this.options.isFullscreen
        });
    },

    /**
     * Start Keyboard Component.
     */
    startKeyboard: function() {
        this.keyboard = this.createComponent({
            type: 'FrameboxKeyboard',
            container: this.$el,
            index: this.options.index,
            appId: this.options.appId,
            allTouchDevices: true,
            avoidOpen: true,
            action: function(fn) {
                this.keyboardAction = fn;
            }.bind(this)
        });
    },

    destroy: function() {
        this.stopTouchEventsEntitiy();
    }
});
