/* jslint bitwise: true */

/**
 * THIS FILE IS USED AS FALLBACK FOR VISUALIZER GETPICTURE COMMANDS.
 */

'use strict';

var wolfProtCodes = require('./wolfprot-codes');

var wolfProtHeaders = wolfProtCodes.headers;

/**
 * @class WolfProtCommandParser
 * @constructor
 */
var WolfProtCommandParser = function() {
    this.lastCommandNotFinished = false;
};

/**
 * Expects a complete wolfprot frame as input parameter
 * @method parse
 */
WolfProtCommandParser.prototype.parse = function parse(inFrame) {
    var headerLenCalc = 3; // Minimum wolfprot header length
    var frameLen = 0;
    var payloadLenCalc = 0;
    var payloadLenRcv = 0;
    var payloadStart = 0;
    var extHdr = 0;
    var extCmd = 0;
    var extLen = 0;
    var extHdrextLen = 0;
    var hdrErr = 0;
    var protOptions = 0;
    var command = 0;
    var errorCode = 0;

    // First we get the header-flags and calculate the header length
    if (inFrame[0] & wolfProtHeaders.WP_HEAD_EXT_HDR) {
        extHdr = 1;
        headerLenCalc++;
    }

    if (inFrame[0] & wolfProtHeaders.WP_HEAD_EXT_CMD) {
        extCmd = 1;
        headerLenCalc++;
    }

    if (!(inFrame[0] & wolfProtHeaders.WP_HEAD_ERR)) {
        if ((extHdr === 1) && (inFrame[1] & wolfProtHeaders.WP_EXTHEAD_EXT_LEN)) {
            extHdrextLen = 1;
            headerLenCalc += 3;
        } else if (inFrame[0] & wolfProtHeaders.WP_HEAD_EXT_LEN) {
            extLen = 1;
            headerLenCalc++;
        }
    } else {
        hdrErr = 1;
    }

    // Calculate characteristic value out of the various protocol bits (by truth
    // Table)
    protOptions = (hdrErr << 4) | (extHdrextLen << 3) | (extHdr << 2) | (extCmd << 1) | extLen;

    // Now check the frame consistency
    frameLen = inFrame.length;
    // Calculate paload length from what we've received
    payloadLenCalc = frameLen - headerLenCalc;

    if (payloadLenCalc < 0) {
        console.log(payloadLenCalc);
        throw new Error('ERROR: frame too short according to header');
    }

    switch (protOptions) {
        case 0:
        case 8:
            command = inFrame[1];
            payloadLenRcv = inFrame[2];
            payloadStart = 3;
            break;

        case 1:
        case 9:
            command = inFrame[1];
            payloadLenRcv = (inFrame[2] << 8) | inFrame[3];
            payloadStart = 4;
            break;

        case 2:
        case 10:
            command = (inFrame[1] << 8) | inFrame[2];
            payloadLenRcv = inFrame[3];
            payloadStart = 4;
            break;

        case 3:
        case 11:
            command = (inFrame[1] << 8) | inFrame[2];
            payloadLenRcv = (inFrame[3] << 8) | inFrame[4];
            payloadStart = 5;
            break;

        case 4:
            command = inFrame[2];
            payloadLenRcv = inFrame[3];
            payloadStart = 4;
            break;

        case 5:
            command = inFrame[2];
            payloadLenRcv = (inFrame[3] << 8) | inFrame[4];
            payloadStart = 5;
            break;

        case 6:
            command = (inFrame[2] << 8) | inFrame[3];
            payloadLenRcv = inFrame[4];
            payloadStart = 5;
            break;

        case 7:
            command = (inFrame[2] << 8) | inFrame[3];
            payloadLenRcv = (inFrame[4] << 8) | inFrame[5];
            payloadStart = 6;
            break;

        case 12:
        case 13:
            command = inFrame[2];
            payloadLenRcv = (inFrame[3] << 24) | (inFrame[4] << 16) | (inFrame[5] << 8) | inFrame[6];
            payloadStart = 7;
            break;

        case 14:
        case 15:
            command = (inFrame[2] << 8) + inFrame[3];
            payloadLenRcv = (inFrame[4] << 24) | (inFrame[5] << 16) | (inFrame[6] << 8) | inFrame[7];
            payloadStart = 8;
            break;

        // Error replies (16..31)
        case 16:
        case 17:
        case 24:
        case 25:
            command = inFrame[1];
            errorCode = inFrame[2];
            break;

        case 18:
        case 19:
        case 26:
        case 27:
            command = (inFrame[1] << 8) + inFrame[2];
            errorCode = inFrame[3];
            break;

        case 20:
        case 21:
        case 28:
        case 29:
            command = inFrame[2];
            errorCode = inFrame[3];
            break;

        case 22:
        case 23:
        case 30:
        case 31:
            command = (inFrame[2] << 8) + inFrame[3];
            errorCode = inFrame[4];
            break;

        default:
            throw new Error('ERROR: Unknown proctocol options');
    }

    if (hdrErr === 0) {
        inFrame.subarray(payloadStart, inFrame.length);

        if (payloadLenCalc !== payloadLenRcv) {
            // Command not completed
            this.lastCommandNotFinished = true;
        } else {
            this.lastCommandNotFinished = false;
        }
    } else {
        var mapping = {
            1: 'Timeout',
            2: 'Invalid Command',
            3: 'Invalid Parameter',
            4: 'Invalid Length',
            5: 'Fifo Full',
            6: 'Firmware Update Error',
            7: 'Access Denied',
            8: 'auth required',
            9: 'busy',
            10: 'sip required',
            11: 'power off'
        };

        console.log(
            'Error-Status: Cmd: ' + command.toString(16).toUpperCase() + ', Error: ' + mapping[errorCode] + ' ErrorCode:' + errorCode
            + ' payloadLenCalc: ' + payloadLenCalc + 'payloadLenRcv' + payloadLenRcv
        );
    }

    return command;
};

module.exports = WolfProtCommandParser;
