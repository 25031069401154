// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row bookmark-item\"\n     data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "\"\n     data-nav-area=\".is-focusable\"\n     data-nav-trapped\n     tabIndex=\"-1\">\n    <div class=\"bookmark-info bookmark-action-open is-focusable\" data-url=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"URL") : depth0), depth0))
    + "\">\n        <div class=\"col-xs-6\">\n            <span class=\"bookmark-col is-focusable\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"URL") : depth0), depth0))
    + "</span>\n        </div>\n        <div class=\"col-xs-5\">\n            <span class=\"bookmark-col\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"NAME") : depth0), depth0))
    + "</span>\n        </div>\n        <div class=\"col-xs-1\">\n            <div class=\"bookmark-action-container\">\n                <span class=\"icon icon-trash1 bookmark-action-delete is-focusable\"\n                      data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "\">\n                </span>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});
