// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"text\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"download_apps.mirror_app",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":42},"end":{"line":7,"column":74}}}))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a class=\"text download-link\" target=\"_blank\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"download_apps.mirror_app",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":70},"end":{"line":9,"column":102}}}))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"download-apps-overlay\">\n    <div class=\"download-apps-form\" id=\"download-apps-form\" name=\"download-apps-form\" data-nav-area=\".is-focusable:not(:disabled)\" data-nav-trapped>\n        <div class=\"col-xs-2 no-padding\">\n            <div class=\"download-apps-menu\">\n                <div class=\"download-apps-menu-item windows-cast\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":10,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n        <div class=\"col-xs-10 no-padding instruction-page\" id=\"instruction-page\">\n\n        </div>\n    </div>\n</div>\n";
},"useData":true});
