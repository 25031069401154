'use strict';

var app = require('../../../app');
var listTpl = require('./media-cast.hbs');
var $ = require('jquery');

/**
 * @type {object}
 */
var recordStates = {
    record: 'record',
    stop: 'stop',
    pause: 'pause',
    disable: 'disable'
};

app.component('MediaCast', {
    template: listTpl,

    initialize: function() {
        this.streaming = this.getService('Streaming');
        this.recordService = this.getService('RecordService');
        this.webcastingService = this.getService('WebcastingService');
        this.deviceService = this.getService('DeviceService');
        this.recordState = this.createStateMachine({
            states: recordStates
        });

        this.bindEvents();
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindDOMEvents();
        this.checkAvailibility();
    },

    storeSelectors: function() {
        this.$record = this.$el.find('#record-item');
        this.$streaming = this.$el.find('#streaming-item');
        this.$webcasting = this.$el.find('#webcasting-item');
        this.$disabledMessage = this.$el.find('#services-disabled');
    },

    bindEvents: function() {
        this.on('widget.record.state.changed', this.checkAvailibility.bind(this));
        this.on('streaming.state.changed', this.checkAvailibility.bind(this));
        this.on('webcasting.active.state.changed', this.checkAvailibility.bind(this));
        this.on('recording.data.changed', this.checkAvailibility.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.clickable', this.onClickHandler.bind(this));
    },

    onClickHandler: function(event) {
        var $el = this.$(event.currentTarget);
        var action = $el.data('action');

        if (action) {
            this.actionHandler(action);
        }
    },

    actionHandler: function(action) {
        var handler = this.mapping[action];

        if (handler) {
            handler.call(this);
        }
    },

    /**
     * Check availability of services (recording, streaming and webcasting)
     */
    checkAvailibility: function() {
        $.when(
            this.checkStreamingFunction(),
            this.checkWebcastingFunction(),
            this.checkRecordingFunction()
        ).then(function(streamingEnabled, webcastingEnabled, recordingEnabled) {
            var isAnyFunctionEnabled = streamingEnabled || webcastingEnabled || recordingEnabled;

            if (isAnyFunctionEnabled) {
                this.$disabledMessage.hide();

                return true;
            }

            this.$disabledMessage.show();

            return false;
        }.bind(this));
    },

    /**
     * Checks if webcasting is enabled an changes the state to the current state.
     * - At first we will check if functionality is enabled and mode is on.
     * - Finally, we set the current state to the button.
     */
    checkWebcastingFunction: function() {
        return this.deviceConnection
            .send('getWebcastMode')
            .then(function(webcasting) {
                // Webcasting function is not enabled or Webcasting is disabled - Remove button from DOM.
                if (!this.webcastingService.supportsWebcasting()
                    || 'off' === webcasting.mode
                    || false === this.streaming.isEnabled()
                ) {
                    this.onWebcastingActiveStateChanged({
                        state: 'disable'
                    });

                    return false;
                }

                // Set on or off state.
                this.onWebcastingActiveStateChanged({
                    state: this.webcastingService.getActiveState()
                });

                return true;
            }.bind(this));
    },

    /**
     * Checks the streaming functionality is enabled or disabled.
     * - If matrix is active streaming is always disabled, webcast can be enabled.
     * - Finally, we set the current state.
     */
    checkStreamingFunction: function() {
        return this.deviceConnection
            .send('getMatrixMasterMode')
            .then(function(matrix) {
                // Matrix is enabled - disable streaming always.
                if (true === matrix.enabled || false === this.streaming.isEnabled()) {
                    this.onStreamingStateChanged({
                        state: 'disable'
                    });

                    return false;
                }

                // Set on or off state.
                this.onStreamingStateChanged({
                    state: this.streaming.getState()
                });

                return true;
            }.bind(this));
    },

    /**
     * Checks the recording state
     * - At first we will check if recording is enabled.
     * - Finally, we set the the current recording state.
     */
    checkRecordingFunction: function() {
        return this.deviceConnection
            .send([
                'getRecordingFunction'
            ])
            .then(function(recording) {
                let recEnabled = recording.enabled;

                if (!recEnabled) {
                    this.onRecordingStateChanged({
                        state: recordStates.disable
                    });

                    return false;
                }

                this.onRecordingStateChanged({
                    state: this.recordService.getState()
                });

                return true;
            }.bind(this));
    },

    onRecordingStateChanged: function(data) {
        var state = data.state || data.recordingMode;

        switch (state) {
            case 'record':
                this.$record.removeClass('is-stopped');
                this.$record.addClass('is-recording');
                break;
            case 'stop':
            case 'pause':
                this.$record.removeClass('is-recording');
                this.$record.addClass('is-stopped');
                break;
            case 'disable':
                this.$record.remove();
                break;
        }

        this.recordState.changeState(recordStates[state]);
    },

    onStreamingStateChanged: function(options) {
        var state = options.state;

        switch (state) {
            case 'on':
                this.$streaming.removeClass('is-stopped');
                this.$streaming.addClass('is-streaming');
                break;
            case 'off':
                this.$streaming.removeClass('is-streaming');
                this.$streaming.addClass('is-stopped');
                break;
            case 'disable':
                this.$streaming.remove();
                break;
        }
    },

    onWebcastingActiveStateChanged: function(options) {
        var state = options.state;

        switch (state) {
            case 'on':
                this.$webcasting.removeClass('is-stopped');
                this.$webcasting.addClass('is-streaming');
                break;
            case 'off':
                this.$webcasting.removeClass('is-streaming');
                this.$webcasting.addClass('is-stopped');
                break;
            case 'disable':
                this.$webcasting.remove();
                break;
        }
    },

    mapping: {
        'streaming-settings': function() {
            this.streaming.toggleState();

            this.checkStreamingFunction();

            this.emit('modal.close', {
                id: 'mediaCast'
            });
        },
        'webcasting-settings': function() {
            this.webcastingService.toggleActiveState();

            this.checkWebcastingFunction();

            this.emit('modal.close', {
                id: 'mediaCast'
            });
        },
        'recording': function() {
            switch (this.recordState.getState()) {
                case 'record':
                    this.recordService.toggleRecordState('stop');
                    break;
                case 'pause':
                    this.recordService.toggleRecordState('pause');
                    break;
                case 'stop':
                    this.recordService.startRecording();
                    break;
            }

            this.emit('modal.close', {
                id: 'mediaCast'
            });
        }
    }
});
