// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"row\">\n                    <div id=\"select-regulatory-info\" class=\"regulatory-info focusable\">\n                        <span class=\"icon-v3-info\"></span>\n                    </div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"select-viewer-control-container\">\n    <div class=\"select-viewer-control-header\">\n        <img src=\"/images/cynap-logo.svg\" alt=\"cynap\" />\n    </div>\n    <div class=\"select-viwer-control-container\">\n        <div class=\"select-viewer-control\" id=\"select-viewer-control\">\n            <div class=\"row\">\n                <div class=\"col-xs-6 select-viewer-control-item focusable\" tabindex=\"-1\" data-item=\"viewer\">\n                    <div class=\"login-icon\" >\n                        <span class=\"icon-iris_l\"></span>\n                    </div>\n                    <span class=\"login-username\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"login.viewer",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":49},"end":{"line":12,"column":69}}}))
    + "</span>\n                </div>\n\n                <div class=\"col-xs-6 select-viewer-control-item focusable\" tabindex=\"-1\" data-item=\"control\">\n                    <div class=\"login-icon\" >\n                        <span class=\"icon-user\"></span>\n                    </div>\n                    <span class=\"login-username\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"login.control",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":49},"end":{"line":19,"column":70}}}))
    + "</span>\n                </div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLivaQ2") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":28,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});
