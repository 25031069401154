'use strict';

var instance = null;
var $ = require('jquery');
var characterByKeyCode = require('./../app/states').characterByKeyCode;
var keyMapper = require('./key-mapper');

/**
 * Get start point of input selection.
 *
 * @param {jQuery.Element} $input
 * @returns {number}
 */
var getSelectionStart = function($input) {
    // Initialize
    var iCaretPos = 0;
    var oSel;

    // IE Support
    if (document.selection) {
        // To get cursor position, get empty selection range
        oSel = document.selection.createRange();

        // Move selection start to 0 position
        oSel.moveStart('character', -$input.val().length);

        // The caret position is selection length
        iCaretPos = oSel.text.length;
    } else if ($input.get(0).selectionStart || 0 === $input.get(0).selectionStart) {
        iCaretPos = $input.get(0).selectionStart;
    }

    // Return results
    return iCaretPos;
};

/**
 * Get the end point of input selection.
 *
 * @param {jQuery.Element} $input
 *
 * @returns {number}
 */
var getSelectionEnd = function($input) {
    // Initialize
    var iCaretPos = 0;
    var oSel;

    // IE Support
    if (document.selection) {
        // To get cursor position, get empty selection range
        oSel = document.selection.createRange();

        // Move selection start to 0 position
        oSel.moveStart('character', -$input.val().length);

        // The caret position is selection length
        iCaretPos = oSel.text.length;
    } else if ($input.get(0).selectionEnd || 0 === $input.get(0).selectionEnd) {
        iCaretPos = $input.get(0).selectionEnd;
    }

    // Return results
    return iCaretPos;
};

/**
 * Is one or more characters slected?
 *
 * @param {jQuery.Element} $input
 *
 * @returns {boolean}
 */
var hasSelection = function($input) {
    var positionStart = getSelectionStart($input);
    var positionEnd = getSelectionEnd($input);

    return (positionStart !== positionEnd);
};

/**
 * Checks if key is a arrow.
 *
 * @param {string} character
 *
 * @return {boolean}
 */
var isArrowKey = function(character) {
    return ['LEFT', 'UP', 'RIGHT', 'DOWN'].indexOf(character) !== -1;
};

/**
 * Handles Key input.
 *
 * @constructor
 */
var KeyboardInputManager = function() {
    this.$input = null;
    this.remote = null;

    this.bindDOMEvents();
};

/**
 * Binds all DOM Events for Input fields.
 */
KeyboardInputManager.prototype.bindDOMEvents = function() {
    $(document).on('touchstart', 'input', this.handleTouchFocus.bind(this));
};

/**
 * Set remoteService.
 *
 * @param {RemoteService} remote
 */
KeyboardInputManager.prototype.setRemote = function(remote) {
    this.remote = remote;
};

/**
 * Focus the input event where it was touched.
 *
 * @param {jQuery.Event} event
 */
KeyboardInputManager.prototype.handleTouchFocus = function(event) {
    var $input = $(event.currentTarget);

    this.remote.focus($input);
};

/**
 * Must be called before the keyCode is set (onInput).
 *
 * @param {jQuery.Element} $input
 */
KeyboardInputManager.prototype.setInputElement = function($input) {
    this.$input = $input;
};

/**
 * Send event on key up.
 *
 * @param keyCode
 * @param character
 */
KeyboardInputManager.prototype.sendKeyUp = function(keyCode, character) {
    var keyUpEvent;
    var isArrowKeyBool = isArrowKey(character);

    if ('' === character || 1 < character.length) {
        character = this.getCharacterByKeyCode(keyCode);
    }

    keyUpEvent = $.Event('keyup');
    keyUpEvent.$key = event ? $(event.target) : null;
    keyUpEvent.which = keyCode;
    keyUpEvent.keyCode = keyCode;
    keyUpEvent.isArrowKey = isArrowKeyBool;
    keyUpEvent.key = character;
    keyUpEvent.originalEvent = {
        repeat: false,
        code: keyMapper.characterToKeyCharacter(character), // KeyA
        key: character, // A
        keyCode: keyCode, // 65
        type: 'keyup',
        which: keyCode // 65
    };

    $.event.trigger(keyUpEvent);
};

/**
 * Sets a character to the right position on the given input.
 *
 * @param {int} keyCode
 * @param {string} character
 */
KeyboardInputManager.prototype.onInput = function(keyCode, character) {
    if ('' === character || 1 < character.length) {
        character = this.getCharacterByKeyCode(keyCode);
    }

    if (this.$input && this.$input.length > 0) {
        this.handleKey(keyCode, character);
    }

    this.sendInputEvents(keyCode, character);
};

/**
 * Returns the character-name by given keyCode.
 * RELEASE-2118 - Touch screen: Enter key doesn't work on browser.
 *
 * @param {Number} keyCode
 * @return {String}
 */
KeyboardInputManager.prototype.getCharacterByKeyCode = function(keyCode) {
    if (characterByKeyCode[keyCode]) {
        return characterByKeyCode[keyCode];
    }

    return '';
};

/**
 * Mapping for keytypes.
 *
 * @param {int} keyCode
 * @param {string} character
 */
KeyboardInputManager.prototype.handleKey = function(keyCode, character) {
    var output = this.$input.val();
    var result = {
        output: output,
        outputLength: output.length,
        positionStart: getSelectionStart(this.$input),
        positionEnd: getSelectionEnd(this.$input)
    };

    if (keyCode === this.remote.KEYCODES.BACKSPACE) {
        result = this.runBackspace(result);
    } else if (keyCode === this.remote.KEYCODES.ENTER) {
        this.$input.submit();
    } else if (keyCode === this.remote.KEYCODES.WHITESPACE) {
        result = this.runByKeyCode(result, ' ', keyCode);
    } else if (keyCode === this.remote.KEYCODES.SHIFT
        || keyCode === this.remote.KEYCODES.CAPS_LOCK
    ) {
        // Do nothing on shift, capslock or tab.
    } else if (keyCode === this.remote.KEYCODES.TAB) {
        result = this.runByKeyCode(result, '\t', keyCode);
    } else if (isArrowKey(character)) {
        result = this.runArrow(result, keyCode);
    } else {
        result = this.runByKeyCode(result, character, keyCode);
    }

    if (result.output !== false) {
        this.$input.val(result.output);
    }

    if (result.positionStart !== false) {
        this.selectPosition(result);
    }
};

/**
 * Send event on input element.
 *
 * @param {int} keyCode
 */
KeyboardInputManager.prototype.sendInputEvents = function(keyCode, character) {
    var isArrowKeyBool = isArrowKey(character);
    var keyDownEvent;
    var keyPressEvent;
    var changeEvent;

    // Simulate Remote.
    keyDownEvent = $.Event('keydown');
    keyDownEvent.$key = !event ? null : $(event.target);
    keyDownEvent.which = keyCode;
    keyDownEvent.keyCode = keyCode;
    keyDownEvent.isArrowKey = isArrowKeyBool;
    keyDownEvent.key = character;
    keyDownEvent.originalEvent = {
        repeat: false,
        code: keyMapper.characterToKeyCharacter(character), // KeyA
        key: character, // A
        keyCode: keyCode, // 65
        type: 'keydown',
        which: keyCode // 65
    };

    keyPressEvent = $.Event('keypress');
    keyPressEvent.which = keyCode;
    keyPressEvent.keyCode = keyCode;
    keyPressEvent.isArrowKey = isArrowKeyBool;
    keyPressEvent.key = character;
    keyPressEvent.originalEvent = {
        repeat: false,
        code: keyMapper.characterToKeyCharacter(character), // KeyA
        key: character, // A
        keyCode: keyCode, // 65
        type: 'keypress',
        which: keyCode // 65
    };

    changeEvent = $.Event('change');
    changeEvent.which = keyCode;
    changeEvent.keyCode = keyCode;
    changeEvent.isArrowKey = isArrowKeyBool;
    changeEvent.key = character;

    $.event.trigger(keyDownEvent);

    // Only needed for arrow keys.
    // Workaround for Office365 -> prevent from sending 'return' key two times
    if (!isArrowKeyBool && keyCode !== 13) {
        $.event.trigger(keyPressEvent);

        // Trigger change on input.
        this.$input.trigger(changeEvent);
    }
};

/**
 * Select position on input.
 *
 * @param {object} result
 */
KeyboardInputManager.prototype.selectPosition = function(result) {
    var range;
    var input;

    if (!this.$input.length) {
        return;
    }

    this.$input.addClass('select-handler');

    input = this.$input.get(0);
    input.focus();

    if (input.createTextRange) {
        range = input.createTextRange();

        range.move('character', result.positionStart);
        range.select();
        this.$input.removeClass('select-handler');

        return;
    }

    if (input.setSelectionRange) {
        input.focus();
        input.setSelectionRange(result.positionStart, result.positionEnd);
        this.$input.removeClass('select-handler');
    } else {
        this.$input.focus();
    }
};

/**
 * Backspace-key handler.
 *
 * @param {object} result
 * @returns {object}
 */
KeyboardInputManager.prototype.runBackspace = function(result) {
    /* Check cursor is on position 0 and no text is selected
       then return the same result. */
    if (0 === result.positionStart && 0 === result.positionEnd) {
        return result;
    }

    if (result.positionStart === result.positionEnd) {
        result.output = [
            result.output.slice(0, result.positionStart - 1),
            result.output.slice(result.positionStart)
        ].join('');

        result.positionStart--;
    } else {
        result.output = [
            result.output.slice(0, result.positionStart),
            result.output.slice(result.positionEnd, result.length)
        ].join('');
    }

    result.positionEnd = result.positionStart;
    result.outputLength = result.output.length;

    return result;
};

/**
 * Key-handler (abcdefghi…, 123…,+*#-,).
 *
 * @param {object} result
 * @param {string} character
 * @returns {object}
 */
KeyboardInputManager.prototype.runByKeyCode = function(result, character, keyCode) {
    if (hasSelection(this.$input)) {
        result = this.runBackspace(result);
        this.selectPosition(result);
    }

    // Replace chatarcters that looks lile "Equals", "BRACKET",…
    if (1 < character.length) {
        character = String.fromCharCode(keyCode);
    }

    result.output = [
        result.output.slice(0, result.positionStart),
        character,
        result.output.slice(result.positionStart)
    ].join('');

    result.positionStart++;
    result.positionEnd++;
    result.outputLength = result.output.length;

    return result;
};

/**
 * Handle arrow keys.
 *
 * @param {object} result
 * @param {int} keyCode
 * @returns {object}
 */
KeyboardInputManager.prototype.runArrow = function(result, keyCode) {
    result.output = false;

    // LEFT
    if (keyCode === 37) {
        result.positionStart--;
        result.positionEnd--;
    // RIGHT
    } else if (keyCode === 39) {
        result.positionStart++;
        result.positionEnd++;
    }

    // Up
    if (keyCode === 38) {
        result.positionStart = 0;
        result.positionEnd = 0;
    // DOWN
    } else if (keyCode === 40) {
        result.positionStart = result.outputLength;
        result.positionEnd = result.outputLength;
    }

    return result;
};

/**
 * Returns the single character from the given characters.
 *
 * @param {string} character
 */
KeyboardInputManager.prototype.getMappedCharacter = function(character) {
    var mapper = {
        '&lt;': '<',
        '&gt;': '>',
        '&amp;': '&'
    };

    if (mapper[character]) {
        character = mapper[character];
    }

    return character;
};

/**
 * Singleton pattern.
 */
function getInstance() {
    if (!instance) {
        instance = new KeyboardInputManager();
    }

    return instance;
}

module.exports = getInstance();
