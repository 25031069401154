'use strict';

var app = require('../../../../app');
var $ = require('jquery');
var i18n = require('i18next');
var lectureCaptureTpl = require('./lecture-capture-settings.hbs');
var FormActionView = require('../../form-action-view');
var states = require('../../../../states');
var pad = require('./../../../../helper').pad;

app.component('LectureCaptureSettings', {
    template: lectureCaptureTpl,
    actionView: null,

    getAccessKey: function() {
        return {
            'roleName': 'RecordSettings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.init = true;
        this.formData = {};
        this.formManager = this.getService('FormManager');
        this.URIService = this.getService('URIService');
        this.deviceService = this.getService('DeviceService');

        return this.loadLectureCaptureSettings();
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.createSelects();
        this.initForm();
        this.initFormAction();
        this.bindEvents();
        this.updateForm();
        this.bindDomEvents();
        this.handleTypeChange();
        this.handleStartTimeModeChange();
        this.init = false;
    },

    storeSelectors: function() {
        this.$lcsSettings = this.$el.find('#lcs-settings');
        this.$lcsSettingsContainer = this.$el.find('#lecture-capture-settings-container');
        this.$lcsSettingsPanopto = this.$el.find('[data-panopto-view]');
        this.$lcsSettingsOpencast = this.$el.find('[data-opencast-view]');
        this.$ingestTimeSettings = this.$el.find('#ingest-time-settings-container');
        this.$hdcpDeactivationInfo = this.$el.find('.lcs-hdcp-deactivation-info');
    },

    bindDomEvents: function() {
        this.form.$el.on('keyup, change', this.handleFormChange.bind(this));
        this.form.get('type').$el.on('keyup, change', this.handleTypeChange.bind(this));
    },

    bindEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.on('overlay.action', this.saveHandler.bind(this));
        this.on('remote.enter.keydown', this.checkActionForm.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
        this.on('settings.cancel-changes', this.cancelHandler.bind(this));
    },

    serialize: function() {
        return {
            functions: i18n.t('settings.lecture_capture')
        };
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$lcsSettings,
            validationContainer: '.input-group'
        });
    },

    /**
     * Enable or Disable startTime-Field when the mode has been changed.
     */
    handleStartTimeModeChange: function() {
        var value = this.form.get('mode').getValue();

        if (value === states.lcsModes.manual) {
            this.enableStartTime();
        } else {
            this.disableStartTime();
        }
    },

    /**
     * Disable StartTime Field.
     */
    disableStartTime: function() {
        this.form.get('startTime').disable();
    },

    /**
     * Enable StartTime Field.
     */
    enableStartTime: function() {
        this.form.get('startTime').enable();
    },

    /**
     * Save Lecture capture form.
     */
    saveHandler: function() {
        var data;
        var startTimes;

        if (this.form.validate()) {
            data = this.form.serialize();
            startTimes = data.startTime.split(':');

            data.filename = this.URIService.encodeFull(data.filename);
            data.startHour = pad(startTimes[0], 2);
            data.startMinute = pad(startTimes[1], 2);

            delete data.startTime;

            return this.deviceConnection
                .send('setLectureCaptureSettings', data)
                .then(function() {
                    this.handleLCSSave();
                    this.form.setDefaultValues();
                    this.handleHdcpSupport(true);
                    this.emit('record-settings.saved');
                    this.emit('overlay.remote.focus', true);
                }.bind(this));
        }
    },

    /**
     * This method is called after the form has been saved.
     */
    handleLCSSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.actionView.close();
        this.handleChanges(false);
    },

    /**
     * Reset all data to the value from the cynap.
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
        this.actionView.close();
        this.handleHdcpSupport();

        this.handleChanges(false);
    },

    /**
     * Show/hide HDCP deactivation info message.
     *
     * @param forceHide If true info message will be hidden anyway
     */
    handleHdcpSupport: function(forceHide) {
        if (!this.deviceService.isCboxCorePro()) {
            return;
        }

        if (forceHide) {
            this.$hdcpDeactivationInfo.hide();

            return;
        }

        if (this.form.get('type').getValue() !== states.lcsTypes.disabled && this.hdmi1HdcpSupportEnabled) {
            this.$hdcpDeactivationInfo.show();
        } else {
            this.$hdcpDeactivationInfo.hide();
        }
    },

    /**
     * Called after a form-element has been changed.
     */
    handleFormChange: function(data) {
        if (!this.init) {
            this.actionView.open();
            this.checkActionForm();

            if (data?.input?.name === 'type') {
                this.handleHdcpSupport();
            }

            this.handleChanges(true);
            this.handleStartTimeModeChange();
        }
    },

    /**
     * Gets the correct action from the type-name.
     */
    handleTypeChange: function() {
        switch (this.form.get('type').getValue()) {
            case states.lcsTypes.disabled:
                if (this.init) {
                    this.$lcsSettingsContainer.hide();
                } else {
                    this.$lcsSettingsContainer.slideUp(300);
                }
                break;
            case states.lcsTypes.panoptoRemoteRecorder:
                this.$ingestTimeSettings.hide();
                this.$lcsSettingsPanopto.show();
                this.$lcsSettingsOpencast.hide();

                if (this.init) {
                    this.$lcsSettingsContainer.show();
                } else {
                    this.$lcsSettingsContainer.slideDown(300);
                }
                break;
            case states.lcsTypes.panoptoAdhocLegacy:
            case states.lcsTypes.panoptoAdhocOAuth2:
                this.$ingestTimeSettings.show();
                this.$lcsSettingsPanopto.show();
                this.$lcsSettingsOpencast.hide();

                if (this.init) {
                    this.$lcsSettingsContainer.show();
                } else {
                    this.$lcsSettingsContainer.slideDown(300);
                }
                break;
            case states.lcsTypes.opencastAdhoc:
            case states.lcsTypes.opencastAuto:
                this.$ingestTimeSettings.show();
                this.$lcsSettingsOpencast.show();
                this.$lcsSettingsPanopto.hide();

                if (this.init) {
                    this.$lcsSettingsContainer.show();
                } else {
                    this.$lcsSettingsContainer.slideDown(300);
                }
                break;
        }
    },

    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * @return {Connection}
     */
    loadLectureCaptureSettings: function() {
        return this.deviceConnection
            .send([
                'getLectureCaptureSettings',
                'getHdmiInput1HdcpSupport'
            ])
            .then(function(lcs, hdmi1HdcpSupport) {
                // If Composer change lecture capture type to disabled if opencast was selected in normal mode
                if (this.deviceService.isCboxProDualProjection()
                    && ['opencastAdhoc', 'opencastAuto'].includes(states.lcsCmdToTypeMapping[lcs.type])) {
                    lcs.type = 0;
                }

                this.formData = {
                    type: states.lcsCmdToTypeMapping[lcs.type],
                    mode: states.lcsCmdToModeMapping[lcs.mode],
                    startTime: pad(lcs.startHour, 2) + ':' + pad(lcs.startMinute, 2),
                    ipCameraMode: states.ipCameraModeFromCmd[lcs.ipCameraMode],
                    filename: this.URIService.decodeFull(lcs.filename)
                };

                this.hdmi1HdcpSupportEnabled = hdmi1HdcpSupport.enabled;

                this.emit('lcs-type.changed', {
                    lcsType: this.formData.type
                });
            }.bind(this));
    },

    /**
     * Set data to the form.
     */
    updateForm: function() {
        this.form.setValues(this.formData);
    },

    /**
     * Create all needed CustomSelect components.
     */
    createSelects: function() {
        var typeItems = [
            {
                text: i18n.t('settings.none'),
                value: states.lcsTypes.disabled
            },
            {
                text: i18n.t('settings.panopto_remote_rec'),
                value: states.lcsTypes.panoptoRemoteRecorder
            },
            {
                text: i18n.t('settings.panopto_adhoc_oauth2'),
                value: states.lcsTypes.panoptoAdhocOAuth2
            },
            {
                text: i18n.t('settings.panopto_adhoc_legacy'),
                value: states.lcsTypes.panoptoAdhocLegacy
            }
        ];

        if (!this.deviceService.isCboxProDualProjection()) {
            typeItems.push({
                text: i18n.t('settings.opencast_adhoc'),
                value: states.lcsTypes.opencastAdhoc
            },
            {
                text: i18n.t('settings.opencast_auto'),
                value: states.lcsTypes.opencastAuto
            });
        }

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#lcs-type-select'),
            label: 'settings.lcs_type',
            native: true,
            name: 'type',
            items: typeItems
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#lcs-mode-select'),
            label: 'settings.lcs_mode',
            native: true,
            name: 'mode',
            items: [
                {
                    text: 'settings.auto',
                    value: states.lcsModes.auto
                },
                {
                    text: 'settings.manual',
                    value: states.lcsModes.manual
                }
            ]
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#lcs-ip-camera-mode-select'),
            label: 'settings.lcs_ip_camera_mode',
            native: true,
            name: 'ipCameraMode',
            items: [
                {
                    text: 'settings.disabled',
                    value: states.ipCameraMode.off
                },
                {
                    text: 'settings.enabled',
                    value: states.ipCameraMode.on
                }
            ]
        });
    },

    destroy: function() {
        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    handleChanges: function(change) {
        var changes = {
            hasChanges: change,
            invalid: $(this.actionView.$submitBtn).prop('disabled')
        };

        this.emit('lcs-type.changed', {
            lcsType: this.form.get('type').getValue()
        });

        this.$el.parent().data('has-changes', changes.hasChanges);
        this.$el.parent().data('has-changes-invalid', changes.invalid);
    }
});
