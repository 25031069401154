'use strict';

var app = require('../../../app');
var recordSettingsTpl = require('./record-settings.hbs');
var states = require('../../../states');
var _ = require('lodash');
var $ = require('jquery');
require('./record-general-settings/record-general-settings');
require('./media-cast-settings/media-cast-settings');
require('./lecture-capture-settings/lecture-capture-settings');
require('./panopto-settings/panopto-settings');
require('./opencast-settings/opencast-settings');

app.component('RecordSettings', {
    template: recordSettingsTpl,

    initialize: function() {
        this.openType = null;
        this.init = true;

        this.deviceService = this.getService('DeviceService');

        this.loadSettings();
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.bindEvents();
        this.storeSelectors();
        this.createComponentRecordGeneral();
    },

    bindEvents: function() {
        this.on('lcs-type.changed', this.handleLcsTypeChanged.bind(this));
        this.on('record-settings.unsaved-changes.handle', this.handleUnsavedChanges.bind(this));
        this.on('record-settings.saved', this.handleChanges.bind(this));
    },

    /**
     * @method storeSelectors
     */
    storeSelectors: function() {
        this.$recordGeneral = this.$el.find('#record-settings');
        this.$lcsWebcasting = this.$el.find('#lcs-webcasting-settings');
        this.$lcsContainer = this.$el.find('#lcs-container');
        this.$panoptoContainer = this.$el.find('#panopto-container');
        this.$opencastContainer = this.$el.find('#opencast-container');
        this.$mediaCast = this.$el.find('#media-cast-settings-container');
    },

    /**
     * @method loadStreamingSettings
     */
    loadSettings: function() {
        return this.deviceConnection
            .send(['getLicenseFeatures'])
            .then(function(features) {
                if (features.capture) {
                    this.createComponentLectureCapture();
                }

                this.init = false;
            }.bind(this));
    },

    /**
     * Handle change event after the lcs-type has been changed.
     *
     * @param {Object} options
     */
    handleLcsTypeChanged: function(options) {
        this.checkRecordingType(options.lcsType);
    },

    /**
     * Handle unsaved changes and call postSaveHandler afterwards.
     * @param postSaveHandler (optionally) called after saving changes and also if no changes were made.
     */
    handleUnsavedChanges: function(postSaveHandler) {
        if (this.hasChanges().hasChanges) {
            this.emit('modal.open', {
                id: 'settings-save-changes',
                invalid: this.hasChanges().invalid,
                onSave: function() {
                    this.emit('settings.save-changes');
                }.bind(this),
                onDiscard: function() {
                    this.emit('settings.cancel-changes');
                }.bind(this),
                onFinishSave: function() {
                    (postSaveHandler || $.noop)();
                },
                waitOnSave: true
            });
        } else {
            (postSaveHandler || $.noop)();
        }
    },

    /**
     * This method is called after type-select has been changed.
     * Here we will show all the correct forms.
     *
     * @param type
     */
    checkRecordingType: function(type) {
        if (type === this.openType) {
            return;
        }

        this.openType = type;

        switch (type) {
            case states.lcsTypes.disabled:
                this.hideOpencast();
                this.hidePanopto();
                break;
            case states.lcsTypes.panoptoRemoteRecorder:
            case states.lcsTypes.panoptoAdhocLegacy:
            case states.lcsTypes.panoptoAdhocOAuth2:
                this.showPanopto();
                this.hideOpencast();
                break;
            case states.lcsTypes.opencastAdhoc:
            case states.lcsTypes.opencastAuto:
                this.showOpencast();
                this.hidePanopto();
                break;
        }
    },

    /**
     * Show opencast form.
     */
    showOpencast: function() {
        if (this.init) {
            this.$opencastContainer.show();
        } else {
            this.$opencastContainer.stop().slideDown(300);
        }
    },

    /**
     * Hide opencast form.
     */
    hideOpencast: function() {
        if (this.init) {
            this.$opencastContainer.hide();
        } else {
            this.$opencastContainer.stop().slideUp(300);
        }
    },

    /**
     * Show panopto form.
     */
    showPanopto: function() {
        if (this.init) {
            this.$panoptoContainer.show();
        } else {
            this.$panoptoContainer.stop().slideDown(300);
        }
    },

    /**
     * Hide panopto form.
     */
    hidePanopto: function() {
        if (this.init) {
            this.$panoptoContainer.hide();
        } else {
            this.$panoptoContainer.stop().slideUp(300);
        }
    },

    /**
     * @method RecordGeneralSettings
     */
    createComponentRecordGeneral: function() {
        this.createComponent({
            type: 'RecordGeneralSettings',
            container: this.$recordGeneral
        });
    },

    /**
     * Create lecture capture components.
     */
    createComponentLectureCapture: function() {
        this.createComponent({
            type: 'MediaCastSettings',
            container: this.$mediaCast
        });

        this.createComponent({
            type: 'PanoptoSettings',
            container: this.$panoptoContainer
        });

        if (!this.deviceService.isCboxProDualProjection()) {
            this.createComponent({
                type: 'OpencastSettings',
                container: this.$opencastContainer
            });
        }

        this.createComponent({
            type: 'LectureCaptureSettings',
            container: this.$lcsContainer
        });
    },

    /**
     * Check after saving if there are still some changes to be sure that everything is saved
     * before calling onFinish-event.
     */
    handleChanges: function() {
        if (!this.hasChanges().hasChanges) {
            this.emit('save-changes.finished');
        }
    },

    hasChanges: function() {
        this.changes = {
            hasChanges: false,
            invalid: false,
            waitOnSave: true
        };

        _.each(this.$el.find('[data-has-changes]'), function(comp) {
            let changes = $(comp).data('has-changes');
            if (changes) {
                this.changes.hasChanges = changes;
            }

            let invalid = $(comp).data('has-changes-invalid');
            if (invalid) {
                this.changes.invalid = invalid;
            }
        }.bind(this));

        return this.changes;
    }
});
