'use strict';

var app = require('../../../../app');
var platform = require('../../../../../platform/platform');
var $ = require('jquery');
var _ = require('lodash');

var TouchEvents = function(options) {
    this.index = options.index;
    this.appId = options.appId;
    this.options = options;
    this.debugging = options.debugging === true && window.CYNAP_ENV === 'dev';
    this.isOnScrollingTimeout = null;
    this.isTouchOnScrollbar = false;
    this.autoRepeatTimer = false;
    this.repeatEvent = null;
    this.repeatCounter = 0;

    /* SERVICES */
    this.keyboardService = app.getService('KeyboardService');
    this.liveStream = app.getService('LiveStreamService');
    this.frameBoxService = app.getService('FrameBoxService');
    this.stationService = app.getService('StationService');

    this.bindEvents();
};

TouchEvents.prototype.bindEvents = function() {
    this._mouseEventsHandler = this.mouseEventsHandler.bind(this);
    app.on('framebox.standard.mouseevent', this._mouseEventsHandler);
};

TouchEvents.prototype.clearRepeatTimer = function() {
    clearInterval(this.autoRepeatTimer);
    this.autoRepeatTimer = false;
};

TouchEvents.prototype.touchEventsHandler = function(event) {
    var eventList = [];

    // RELEASE-3366: We need to continuously send the last touch event to prevent weston crashes.
    if (event.type !== 'touchend' && this.repeatCounter <= 2) {
        this.repeatEvent = event;

        if (!this.autoRepeatTimer) {
            this.autoRepeatTimer = setInterval(function() {
                this.clearRepeatTimer();
                if (event === this.repeatEvent) {
                    this.repeatCounter += 1;
                }
                this.touchEventsHandler(this.repeatEvent);
            }.bind(this), 1000);
        }
    } else {
        if (this.repeatCounter > 2) {
            this.repeatCounter = 0;

            event.type = 'touchend';
            this.touchEventsHandler(event);
        }

        this.repeatCounter = 0;
        this.clearRepeatTimer();
    }

    _.each(event.originalEvent.changedTouches, function(changedTouch) {
        var offset = this.liveStream.getOffset();
        var sourceDimension = this.liveStream.getSize();
        var client = {
            x: changedTouch.pageX,
            y: changedTouch.pageY
        };

        var coordinates = {
            width: sourceDimension.width,
            height: sourceDimension.height,
            x: client.x - offset.left,
            y: client.y - offset.top
        };

        var targetCoordinates = this.frameBoxService.convertCoordinates(coordinates, sourceDimension);

        eventList.push({
            positionX: Math.round(targetCoordinates.x),
            positionY: Math.round(targetCoordinates.y),
            event: event.type,
            identifier: changedTouch.identifier,
            frameBoxId: this.index
        });

        this.disableSelection(event, targetCoordinates);
    }.bind(this));

    this.frameBoxService.sendMultiEvents(this.index, eventList);
};

/**
 * Sends a mouse event using the frameBoxService.
 *
 * @param {jQuery.Event} event - the mouseevent
 */
TouchEvents.prototype.mouseEventsHandler = function(event, index) {
    var $el;
    var offset;
    var sourceDimension;
    var client;
    var targetCoordinates;
    var coordinates;

    if (index !== this.index || this.stationService.getLockStatus()) {
        return;
    }

    if (event.type.indexOf('touch') >= 0) {
        this.touchEventsHandler(event);

        return;
    }

    $el = $(event.target);
    offset = this.liveStream.getOffset();
    sourceDimension = this.liveStream.getSize();
    client = this.getClientCoordinates(event);
    coordinates = {
        width: sourceDimension.width,
        height: sourceDimension.height,
        x: client.x - offset.left,
        y: client.y - offset.top
    };

    targetCoordinates = this.frameBoxService.convertCoordinates(coordinates, sourceDimension);

    if ($el.hasClass('has-action') && (-1 !== this.options.allowedEvents.indexOf(event.type) || this.isTouchOnScrollbar)) {
        this.isTouchOnScrollbar = false;
        this.isTouchOnScrollbarChecker(coordinates.x, 'x');
        this.isTouchOnScrollbarChecker(coordinates.y, 'y');
        this.frameBoxService.sendMouseEvents(
            Math.round(targetCoordinates.x),
            Math.round(targetCoordinates.y),
            event.type,
            this.index
        );

        this.disableSelection(event, targetCoordinates);
    }

    this.handleKeyboard(event);
};

/**
 * Open or close keyboard with the keyborad button on the right-bottom of the framebox.
 * FIXME: This code is not in use and does not work.
 * FIXME: this.keyboardAction is never defined.
 *
 * @param event
 */
TouchEvents.prototype.handleKeyboard = function(event) {
    /*
     *  RELEASE-2253
     *  Browser of an iOS device cannot control Cynap when Cynap has an open AirPlay connection or MS-Office file.
     */
    if (!this.keyboardAction) {
        return;
    }

    if ((this.keyboardService.getManuallyOpened()) || platform.checks.isIOS) {
        if ((event.type === 'mouseup' || event.type === 'touchend') && platform.checks.isIOS) {
            this.keyboardAction('update', this.box);
        }

        if (event.type === 'mousedown' || event.type === 'touchstart') {
            this.keyboardAction('setHasClick', true);

            clearTimeout(this.clickTimeout);
            this.clickTimeout = setTimeout(function() {
                this.keyboardAction('update', this.box);
                this.keyboardAction('setHasClick', false);
            }.bind(this), 300);
        }

        this.keyboardService.setManuallyOpened(false);
    }
};

/**
 * Disable selection. This mehtod will trigger a mouseup-event after a mouse-down event has been triggered.
 */
TouchEvents.prototype.disableSelection = function(event, targetCoordinates) {
    if (('touchstart' !== event.type && 'mousedown' !== event.type)
        || !this.options.disableSelection
    ) {
        return;
    }

    if (!this.isTouchOnScrollbar) {
        setTimeout(function() {
            this.frameBoxService.sendMouseEvents(
                Math.round(targetCoordinates.x),
                Math.round(targetCoordinates.y),
                'touchend',
                this.index
            );
        }.bind(this), 20);
    }
};

/**
 * Returns the clien coordinates of the current event.
 *
 * @param {jQuery.Event} event
 *
 * @returns {object}
 */
TouchEvents.prototype.getClientCoordinates = function(event) {
    var coordinates = {};

    switch (event.type) {
        case 'touchstart':
        case 'touchmove':
            coordinates.x = event.originalEvent.touches[0].pageX;
            coordinates.y = event.originalEvent.touches[0].pageY;
            break;
        case 'touchend':
            coordinates.x = event.originalEvent.changedTouches[0].pageX;
            coordinates.y = event.originalEvent.changedTouches[0].pageY;
            break;
        default:
            coordinates.x = event.clientX;
            coordinates.y = event.clientY;
            break;
    }

    return coordinates;
};

/**
 * Checks if the touch is on a scrollbar-area.
 *
 * @param {Number} position
 * @param {String} posToCheck
 *
 * @return {Boolean}
 */
TouchEvents.prototype.isTouchOnScrollbarChecker = function(position, posToCheck) {
    var windowDimensions = 0;
    var scrollbarDimension = 0;

    if (!this.options.scrollbar) {
        return;
    }

    if ('x' === posToCheck) {
        windowDimensions = this.options.$actionEl.width();
        scrollbarDimension = windowDimensions * this.options.scrollbar.x; // 1.3% from windowsDimensions
    }

    if ('y' === posToCheck) {
        windowDimensions = this.options.$actionEl.height();
        scrollbarDimension = windowDimensions * this.options.scrollbar.y; // 2.3% from windowsDimensions
    }

    this.showScrollbarDebugger(scrollbarDimension, posToCheck);
    this.isTouchOnScrollbar = this.isTouchOnScrollbar || windowDimensions - scrollbarDimension < position;
};

/**
 * This will visible the scrollarea for all scrollbars on the framebox.
 *
 * @param scrollbarDimension
 * @param posToCheck
 */
TouchEvents.prototype.showScrollbarDebugger = function(scrollbarDimension, posToCheck) {
    var $el = this.options.$actionEl.find('#scrollbar-' + posToCheck);

    if (true !== this.debugging) {
        return;
    }

    if (0 === $el.length) {
        this.options.$actionEl.append('<div id="scrollbar-' + posToCheck + '"></div>');
        $el = this.options.$actionEl.find('#scrollbar-' + posToCheck);
    }

    $el.css({
        position: 'absolute',
        right: 0,
        bottom: 0,
        border: '1px solid red',
        pointerEvents: 'none'
    });

    if ('y' === posToCheck) {
        $el.css({
            width: '100%',
            height: scrollbarDimension
        });
    } else {
        $el.css({
            width: scrollbarDimension,
            height: '100%'
        });
    }
};

TouchEvents.prototype.destroy = function() {
    app.off('framebox.standard.mouseevent', this._mouseEventsHandler);
};

module.exports = TouchEvents;
