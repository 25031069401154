'use strict';

const app = require('../../../app');
const selectionTpl = require('./selection-v3.html');

/**
 * General purpose selection modal, expects at minimum
 * title : String
 * items : Array
 *   - id
 *   - name
 *   - icon
 *
 * Callback for selection
 *   onSelect(item.id) receives the ID of the selected item
 *
 * Callback for action switch
 *   onToggle(actionSwitch.checked) receives if action switch is checked
 */
app.component('SelectionModalV3', {
    engine: 'vue',
    template: selectionTpl,
    className: 'selection-v3-modal-container',

    data: function() {
        return {
            title: '',
            items: [],
            actionSwitch: {
                title: '',
                show: false,
                checked: false
            }
        };
    },

    computed: {
        useSmallItems: function() {
            return this.items.length > 12;
        }
    },

    methods: {
        select: function(item) {
            this.component.options.configs.onSelect(item.id);
            this.close();
        },

        actionSwitchToggled: function() {
            this.component.options.configs.onToggle(this.actionSwitch.checked);
        },

        close: function() {
            this.component.emit('modal.close', {
                id: 'selection-v3'
            });
        }
    },

    created: function() {
        this.title = this.component.options.configs.title;
        this.items = this.component.options.configs.items;

        if (this.component.options.configs.actionSwitch) {
            this.actionSwitch = this.component.options.configs.actionSwitch;
        }
    },

    components: {
        'tile-v3': require('../../../components-vue/tile-v3/tile-v3')
    }
});
