// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"ftp-settings\" name=\"ftp-settings\">\n        <div class=\"row settings-list-row\"\n             data-nav-area=\".btn-switch\"\n             data-nav-trapped\n             data-nav-area-default>\n            <div class=\"col-xs-8\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ftp_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":47}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input id=\"ftp-mode\" name=\"mode\" type=\"checkbox\" class=\"btn-switch\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable:not(:disabled)\" data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"url\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ftp_server",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":57},"end":{"line":22,"column":84}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input is-focusable\"\n                           name=\"url\"\n                           id=\"ftp-url\"\n                           placeholder=\"\"\n                           data-validation=\"required\"\n                           tabIndex=\"-1\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.enter_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":71},"end":{"line":31,"column":97}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable:not(:disabled)\" data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"user\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ftp_username",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":58},"end":{"line":39,"column":87}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input is-focusable\"\n                           name=\"user\"\n                           id=\"ftp-user\"\n                           placeholder=\"\"\n                           tabIndex=\"-1\" />\n                </div>\n            </div>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"password\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ftp_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":62},"end":{"line":50,"column":91}}}))
    + "</label>\n                    <input type=\"password\"\n                           autocomplete=\"current-password\"\n                           class=\"input is-focusable\"\n                           name=\"password\"\n                           id=\"ftp-password\"\n                           placeholder=\"\"\n                           tabIndex=\"-1\"\n                           data-validation=\"required\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.wrong_password_require",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":28},"end":{"line":61,"column":83}}}))
    + "\n                        </span>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"ftp-form-action-container\" class=\"form-primary-background\"></div>\n    </form>\n\n    <div id=\"ftp-settings-test\"\n         class=\"ftp-settings-test\"\n         data-nav-area=\".is-focusable:not(:disabled)\"\n         data-nav-trapped>\n        <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.test_ftp_connection",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":12},"end":{"line":75,"column":48}}}))
    + "</h2>\n        <div class=\"ftp-settings-test-state\">\n            <span class=\"icon-check-mark ftp-connection-ok\"></span>\n            <span class=\"icon-panic-button ftp-connection-failed\"></span>\n            <span class=\"ftp-connection-testing\">\n                <div class=\"loader small\"></div>\n            </span>\n        </div>\n        <input id=\"ftp-settings-test-btn\"\n               type=\"button\"\n               class=\"btn-form form-action-cancel is-focusable ftp-connection-test-action\"\n               value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.test_it_now",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":86,"column":22},"end":{"line":86,"column":50}}}))
    + "\">\n    </div>\n</div>\n";
},"useData":true});
