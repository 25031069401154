'use strict';

var app = require('../../app');

var mediaListTpl = require('./media-list.html');
var mediaListIcons = require('./icons.js');
const filebrowserUtils = require('../filebrowser/utils.js');
const uploadProgressComponent = require('../../components-vue/upload-progress/upload-progress.js');
const vueUtils = require('../../components-vue/util.js');
const Vuex = require('vuex');

const MAX_POLL_INTERVAL_WHEN_CLOSED = 60000;
const MAX_POLL_INTERVAL_WHEN_OPEN = 0;

var cloudStates = {
    synced: 'synced',
    connected: 'connected',
    disconnected: 'disconnected',
    failed: 'failed',
    oauth: 'oauth'
};

var mountStates = require('./../../states').mountStates;
var uploadStates = require('./../../states').uploadStates;

app.component('MediaList', {
    engine: 'vue',
    template: mediaListTpl,
    className: 'media-list-container',

    data: function() {
        return {
            uploads: [{
                status: uploadStates.done,
                file: 'Internal:///snapshots/Cynap-01077727_20201001_093513.jpg',
                target: 'dropbox:///wolfvision/',
                progress: 50,
                index: 1
            }, {
                status: uploadStates.running,
                file: 'Internal:///snapshots/Cynap-01077727_20201001_093514.jpg',
                target: 'dropbox:///wolfvision/',
                progress: 80,
                index: 2
            }],
            list: [{
                icon: 'icon-google-drive',
                titleKey: 'Google Drive',
                iconConnection: 'icon-lock1',
                selected: true,
                type: 'cloud',
                connection: cloudStates.disconnected
            }],

            focusable: false
        };
    },

    methods: {
        /**
         * Handle clicks on media
         */
        mediaClicked: function(mount) {
            if (mount.type === 'cloud') {
                app.emit('main-loop.fast.start', {
                    id: 'cloud'
                });

                if (mount.connection === cloudStates.disconnected || mount.connection === cloudStates.failed) {
                    /*
                     * When the cloud service is not authenitcated, we need to
                     * trigger the authentication process. Our component will be
                     * closed during this process, so we don't need to check for
                     * return values or errors.
                     */
                    this.component.authenticateCloudService(mount);
                } else if (mount.connection === cloudStates.synced) {
                    /*
                     * When the cloud service is connected and ready we treat
                     * it like a normal drive.
                     */
                    this.selectMedia(mount);
                } else {
                    /*
                     * Cloud is authenticated but not available
                     * We don't do anything in this case
                     */
                }
            } else {
                this.selectMedia(mount);
            }
        },

        /**
         * Selects a media
         */
        selectMedia: function(mount) {
            this.list.forEach(function(item) {
                item.selected = false;
            });
            mount.selected = true;

            this.component.emit('filebrowser.open', {
                path: mount.id + ':///',
                isCloudProvider: mount.type === 'cloud',
                mountName: mount.titleKey
            });
        },

        /**
         * Selects a media by its ID
         */
        selectMediaById: function(mediaId) {
            let mount = this.list.find(m => m.id === mediaId);
            if (mount) {
                this.selectMedia(mount);
            }
        },

        /**
         * Authenticate a cloud service media by its ID
         * @param mediaId
         */
        authenticateCloudServiceById: function(mediaId) {
            let mount = this.list.find(m => m.id === mediaId);
            if (mount && mount.type === 'cloud'
                && (mount.connection === cloudStates.disconnected || mount.connection === cloudStates.failed)) {
                this.component.authenticateCloudService(mount);
            }
        },

        /**
         * Find icon for media
         * @param media media object
         */
        findMediaIcon: function(media) {
            return mediaListIcons.pictogram(media);
        },

        /**
         * Find icon for media connection state
         * @param media media object
         */
        findConnectionIcon: function(media) {
            return mediaListIcons.connection(media);
        },

        /**
         * Determine whether logout button should be shown
         * @param media media object
         */
        shouldShowLogout: function(media) {
            return media.type === 'cloud' && media.connection === cloudStates.synced;
        },

        /**
         * Disconnect cloud media
         * @param media media object
         */
        disconnectMedia: function(event, media) {
            event.stopPropagation();

            this.component.cloudAuth.disconnectProvider(media.id);

            if (media.id === this.selectedMedia.id) {
                this.selectMediaById('Internal');
            }
        },

        /**
         * Opens modal to show details of uploads
         */
        showUploadDetails: function() {
            this.component.emit('modal.open', {
                id: 'uploadProgress',
                messageKey: 'abc'
            });
        },

        /**
         * Retry failed uploads
         *
         * @param uploads list of uploads (view model) to retry
         */
        tryUploadsAgain: function({ uploads }) {
            uploads.forEach(function(upload) {
                this.component.fileops.restartUpload({
                    index: upload.index,
                    target: upload.target
                });
            }.bind(this));
        }
    },

    computed: {
        selectedMedia: function() {
            return this.list.find(media => media.selected === true);
        },
        nonAbortedUploads: function() {
            return this.uploads.filter(upload => upload.status !== uploadStates.aborted);
        },
        ...Vuex.mapGetters('uiSettings', ['getUiScalingSize'])
    },

    watch: {
        /**
         * If no media is selected, select Internal by default.
         * E.g. when USB stick is removed.
         * If selected media gets unmounted, also select Internal media.
         * @param val
         */
        selectedMedia: function(newVal, oldVal) {
            if (!newVal || (oldVal && newVal.id === oldVal.id
                    && newVal.status === mountStates.notMounted && oldVal.stat !== mountStates.notMounted)) {
                this.selectMediaById('Internal');
            }
        }
    },

    created: function() {
        this.evctx = vueUtils.eventContext();
        this.evctx.on('media-list.select', this.selectMediaById);
        this.evctx.on('media-list.authenticate', this.authenticateCloudServiceById);
        this.evctx.on('file-browser.opened', function() {
            this.focusable = true;
            this.component.pollHelper.throttleInterval(MAX_POLL_INTERVAL_WHEN_OPEN);
        }.bind(this));
        this.evctx.on('file-browser.closed', function() {
            this.focusable = false;
            this.component.pollHelper.throttleInterval(MAX_POLL_INTERVAL_WHEN_CLOSED);
        }.bind(this));
    },

    components: {
        'upload-progress': uploadProgressComponent
    },

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.cloudAuth = this.getService('CloudAuthenticationService');
        this.mountService = this.getService('MountService');
        this.userSettingsService = this.getService('UserSettingsService');
        this.uriService = app.getService('URIService');
        this.authService = app.getService('AuthenticationService');
        this.lastRequest = {};
        this.updateMediaListInProgress = false;

        this.fileops = filebrowserUtils.fileopsFactory({
            deviceConnection: this.deviceConnection,
            uriService: this.uriService
        });

        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return this.load();
            }.bind(this)
        });
        this.pollHelper.on('data', function(data) {
            this.$viewModel.list = this.copyPreservingSelection(this.$viewModel.list, data.list);
            this.$viewModel.uploads = data.uploads;
        }.bind(this));
        this.pollHelper.throttleInterval(MAX_POLL_INTERVAL_WHEN_CLOSED);
    },

    serialize: function() {
        return vueUtils.promiseToSerialize(this.pollHelper.fetch().then(function(data) {
            let focusedMedia;

            data.list.forEach(function(item) {
                item.selected = false;
            });

            if (this.options.configs.focusItem) {
                focusedMedia = data.list.find(function(mount) {
                    return mount.id === this.options.configs.focusItem;
                }.bind(this));
            }

            if (!focusedMedia) {
                focusedMedia = data.list.find(function(mount) {
                    return mount.id === 'Internal';
                });
            }

            focusedMedia.selected = true;

            this.emit('filebrowser.open', {
                path: focusedMedia.id + ':///',
                isCloudProvider: focusedMedia.type === 'cloud',
                mountName: focusedMedia.titleKey
            });

            return data;
        }.bind(this), function() {
            // In case the first fetch fails, provide dummy data, so component is loaded correctly
            return {
                list: []
            };
        }));
    },

    postPlaceAt: function() {
        this.bindEvents();
    },

    bindEvents: function() {
        this.on('main-loop.update', this.pollHelper.schedulePoll);
    },

    /**
     * Authenticate cloud service if necessary.
     *
     * @param mout cloud service to authenticate
     */
    authenticateCloudService: function(mount) {
        this.mountService.checkIfServiceAvailable('cloud_services.' + mount.id)
            .then(function(res) {
                if (res.serviceAvailable) {
                    /*
                    * If a USB stick has authentication data for the required
                    * cloud service, ask user if he wants to load user settings
                    * from the stick.
                    * The user settings dialog triggers manual input as
                    * fallback, so we don't need to check any results.
                    */
                    this.userSettingsService.openUserSettingsDialog(true, {
                        loginService: mount.id,
                        loginServiceTitle: mount.titleKey,
                        tryToConnect: true
                    });

                    this.emit('file-browser.close');
                } else if (mount.oauthUrl) {
                    /*
                     * If the cloud service uses OAuth, we can use
                     * connectProvider() directly
                     */
                    this.cloudAuth.connectProvider(mount.id);

                    this.emit('file-browser.close');
                } else {
                    /*
                     * For non-OAuth cloud services, we need to open the cloud
                     * login dialog.
                     */
                    this.emit('cloud-login.show', {
                        service: mount.id,
                        title: mount.titleKey
                    });
                }
            }.bind(this));
    },

    /**
     * Copy current view state to new data from backend
     * e.g. collapsed value
     *
     * @param old old view model with view state
     * @param data new data to apply view state on
     */
    copyPreservingSelection: function(old, data) {
        return data.map(function(mount) {
            var previousMount = old.find(function(prevMount) {
                return prevMount.id === mount.id;
            });

            return Object.assign({}, mount, {
                selected: previousMount ? previousMount.selected : false,
                collapsed: previousMount ? previousMount.collapsed : false,
                folders: previousMount && previousMount.folders,
                busy: previousMount ? previousMount.busy : false
            });
        });
    },

    /**
     * Load Mounts from CYNAP.
     */
    load: function() {
        var mountsDfd = this.$.Deferred();
        var cloudsDfd = this.$.Deferred();

        try {
            this.deviceConnection
                .send([
                    'getMounts',
                    {
                        command: 'getFileUploadList',
                        data: {
                            allUploads: this.authService.getIsAdmin()
                        }
                    }
                ])
                .then(function(mounts, uploadList) {
                    mountsDfd.resolve(Object.assign({}, mounts, uploadList));
                }.bind(this), function(err) {
                    mountsDfd.reject(err);
                });
        } catch (ex) {
            mountsDfd.reject(ex);
        }

        try {
            this.cloudAuth.fetchProviders(function() {
                cloudsDfd.resolve(this.cloudAuth.providers);
            }.bind(this));
        } catch (ex) {
            cloudsDfd.reject(ex);
        }

        return this.$.when(mountsDfd.promise(), cloudsDfd.promise())
            .then(this.parse.bind(this));
    },

    /**
     * Parse the incoming data for the list component.
     *
     * @param {Array} mountsResponse
     * @param {Array} cloudsResponse
     */
    parse: function(mountsResponse, cloudsResponse) {
        var mounts = mountsResponse.mountList;
        const uploads = mountsResponse.fileUploadList;

        var enabledMounts = mounts.filter(function(mount) {
            return mount.status !== mountStates.disabled;
        });

        const parsedData = enabledMounts.map(function(mount) {
            var cloudData = cloudsResponse.find(function(entry) {
                return entry.id === mount.id;
            });

            return {
                id: mount.id,
                titleKey: mount.name,
                type: mount.type,
                status: mount.status,
                perms: mount.perms,
                oauthUrl: (cloudData || {}).oauthUrl,
                connection: (cloudData || {}).connection
            };
        });

        const cloudMedias = parsedData.filter(function(mount) {
            return mount.type === 'cloud';
        });

        const otherMedias = parsedData.filter(function(mount) {
            return cloudMedias.indexOf(mount) < 0;
        });

        const otherMediasMountedAndPermitted = otherMedias.filter(function(mount) {
            return mount.status === mountStates.mounted
                && !!mount.perms
                && mount.perms !== 'wo'
                && 'matrix_master' !== mount.type;
        });

        const mediasDisplayed = [
            ...otherMediasMountedAndPermitted,
            ...cloudMedias
        ];

        return {
            uploads,
            list: mediasDisplayed
        };
    }
});
