// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"media-cast-settings\" name=\"input-settings\">\n    <div class=\"row settings-list-row\" data-nav-area=\".is-focusable\" data-nav-trapped>\n        <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.stream_record",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":42}}}))
    + "</h2>\n    </div>\n\n    <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n        <div class=\"col-xs-6\">\n            <div id=\"recording-stream-mode\"></div>\n        </div>\n\n        <div class=\"col-xs-6\" />\n    </div>\n\n    <div class=\"row settings-list-secundary stream-input\" data-nav-area=\"select, input, .is-focusable\" data-nav-trapped>\n        <div class=\"col-xs-6\">\n            <div class=\"input-group\">\n                <label class=\"input-label\" for=\"url\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.record_stream_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":53},"end":{"line":17,"column":87}}}))
    + "</label>\n                <input type=\"text\" class=\"input\" name=\"recordStreamUrl\" id=\"record-stream-url\" data-validation=\"checkUrl\"/>\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\" id=\"url-error\">\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":24},"end":{"line":21,"column":50}}}))
    + "\n                    </span>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"col-xs-4\">\n            <div class=\"input-group stream-input-audio\">\n                <label class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.record_stream_audio",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":43},"end":{"line":29,"column":79}}}))
    + "</label>\n            </div>\n        </div>\n        <div class=\"col-xs-2\">\n            <div class=\"input-group stream-input-audio\">\n                <div class=\"btn-switch-container halfsize-switch pull-right\">\n                    <input name=\"recordStreamAudio\" id=\"recordStreamAudio\" type=\"checkbox\" class=\"btn-switch is-focusable\" tabIndex=\"-1\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row settings-list-secundary stream-test-btn-container\" data-nav-area=\".is-focusable\" data-nav-trapped>\n        <input type=\"button\" name=\"stream-test\" class=\"btn-form form-action-cancel is-focusable stream-test-action\" value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.test_stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":123},"end":{"line":45,"column":151}}}))
    + "\">\n    </div>\n\n    <div id=\"form-action-container-media-cast\" class=\"form-primary-background\"></div>\n</form>\n";
},"useData":true});
