/*
 * Modified by Massive Art.
 *
 * Original version:
 * On Screen Keyboard jQuery Plugin version 1.0.1
 * Chris Cook - chris@chris-cook.co.uk
 */
const $ = require('jquery');
const keyboardTpl = require('./keyboard.hbs');
const keyboardTplDe = require('./keyboard-de.hbs');
const keyboardTplNo = require('./keyboard-no.hbs');
const keyboardTplFr = require('./keyboard-fr.hbs');
const keyboardTplSv = require('./keyboard-sv.hbs');
const keyboardTplIt = require('./keyboard-it.hbs');
const keyboardTplCh = require('./keyboard-ch.hbs');
const keyboardTplUk = require('./keyboard-uk.hbs');
const keyboardTplJp = require('./keyboard-jp.hbs');
const keyboardTplFrCa = require('./keyboard-fr-ca.hbs');

/** @var {KeyMapper} keyMapper **/
const keyMapper = require('./../../modules/keyboard/key-mapper');
const kim = require('./../../modules/keyboard/keyboard-input-manager');

const shiftLeftKey = {
    character: 'ShiftLeft',
    keyCode: 42,
    pressedCharacterValue: ''
};

const shiftKey = {
    character: 'Shift',
    keyCode: 54,
    pressedCharacterValue: ''
};

const altGraphKey = {
    character: 'AltGraph',
    keyCode: 100,
    pressedCharacterValue: ''
};

(function($) {

    'use strict';

    $.fn.onScreenKeyboard = function(options) {
        var state = 'closed';
        var settings = $.extend({
                draggable: false,
                rewireReturn: false,
                rewireTab: false,
                bottomPosition: '10%',
                leftPosition: '30%'
            }, options),
            $keyboardTriggers = this,
            $input = $(),
            $keyboard = renderKeyboard('osk-container', options.language),
            $keys = $keyboard.children('li'),
            $letterKeys = $keyboard.find('li.osk-letter'),
            $capslockUppercase = $keyboard.find('li.capslock-uppercase'),
            $symbolKeys = $keyboard.find('li.osk-symbol'),
            $returnKey = $keyboard.find('li.osk-return'),
            $tabKey = $keyboard.find('li.osk-tab'),
            capslock = false,
            inputOptions = [],
            browserInPercent = $tabKey.css('marginRight').indexOf('%') > -1;

        this.$lastInput = null;

        this.remote = options.remote;

        function isCollide(a, b) {
            return !(
                ((a.offset().top + a.height()) < (b.offset().top)) ||
                (a.offset().top > (b.offset().top + b.height())) ||
                ((a.offset().left + a.width()) < b.offset().left) ||
                (a.offset().left > (b.offset().left + b.width()))
            );
        }

        /**
         * Focuses and customises the keyboard for the current input object.
         *
         * @param {jQueryObject} $input The input object to focus on.
         */
        function activateInput($input) {
            var inputOptionsString = $input.attr('data-osk-options');

            $keys.removeClass('osk-disabled');
            $keyboardTriggers.removeClass('osk-focused');

            if (inputOptionsString !== undefined) {
                inputOptions = inputOptionsString.split(' ');

                if ($.inArray('disableSymbols', inputOptions) > -1) {
                    $symbolKeys.addClass('osk-disabled');
                }

                if ($.inArray('disableTab', inputOptions) > -1) {
                    $tabKey.addClass('osk-disabled');
                }

                if ($.inArray('disableReturn', inputOptions) > -1) {
                    $returnKey.addClass('osk-disabled');
                }
            }

            $input.addClass('osk-focused');

            if (!$input.is(':focus')) {
                $input.focus();
            }
        }

        /**
         * Returns px padding from keyboard to the left side.
         *
         * @return {number}
         */
        function getKeyboardLeftPosition() {
            return ($(window).width() - $keyboard.width()) / 2;
        }

        /**
         * Resets the top position of the keyboard based on the input position.
         */
        function resetPosition() {
            if (0 === $input.length) {
                // Set default options.
                $keyboard.css({
                    bottom: settings.bottomPosition,
                    top: 'auto',
                    left: getKeyboardLeftPosition() + 'px',
                    right: 'auto'
                });

                return;
            }

            var inputTop = $input.offset().top + $input.height();
            var top;

            if (inputTop + $keyboard.height() + 50 > $(window).height()) {
                top = inputTop - $keyboard.height() - $input.height() - 70;
            } else {
                top = inputTop + 50;
            }

            // Fallback, if the screen is too small to display input + keyboard.
            if (top < 0) {
                top = 0;
            }

            $keyboard.css({
                top: top + 'px',
                bottom: 'auto'
            });
        }

        /**
         * Fixes the width of the keyboard in browsers which round down part-pixel values (all
         * except Firefox). Most browsers which do this return CSS margins in pixels rather than
         * percent, so this is used to determine whether or not to use this function. Opera does not
         * however, so for now this function does not work in that browser.
         */
        function fixWidths() {
            var $key = $(),
                keyboardWidth = $keyboard.width(),
                totalKeysWidth = 0,
                difference;
            if (browserInPercent) {
                $keys.each(function() {
                    $key = $(this);
                    if (!$key.hasClass('osk-dragger') && !$key.hasClass('osk-space')) {
                        totalKeysWidth += $key.width() + Math.floor((parseFloat($key.css('marginRight')) / 100) * keyboardWidth);
                        if ($key.hasClass('osk-last-item')) {
                            difference = keyboardWidth - totalKeysWidth;
                            if (difference > 0) {
                                $key.width($key.width() + difference);
                            }
                            difference = 0;
                            totalKeysWidth = 0;
                        }
                    }
                });
            }

            $keyboard.css({
                left: getKeyboardLeftPosition() + 'px'
            });
        }

        if (settings.draggable && $.ui) {
            $keyboard.find('.osk-dragger').show();
            $keyboard.css('paddingTop', '0').draggable({
                containment: 'document',
                handle: 'li.osk-dragger'
            });
        }

        $keyboard.css('bottom', settings.bottomPosition).css('left', settings.leftPosition);

        fixWidths();

        $keyboard.hide().css('visibility', 'visible');

        $(window).resize(function() {
            fixWidths();
        });

        this.currentlyPressedKey = null;
        this.repeatTimeout = null;
        this.keyDownInterval = null;

        this.bindDOMEvents = function() {
            $keyboard.off('mousedown touchstart', 'li');
            $keyboard.off('mouseup touchend', 'li');
            $keyboard.off('click', '.osk-hide');
            $keyboard.off('mousedown touchstart', '.osk-capslock');
            $keyboard.off('mousedown touchstart', '.osk-shift');
            $keyboard.off('mouseup touchend', '.osk-shift');
            $keyboard.off('touchstart touchend', 'li:not(.osk-dragger)');

            $keyboard.on('mousedown touchstart', 'li', this.onMouseDown.bind(this));
            $keyboard.on('mouseup touchend', 'li', this.onMouseUp.bind(this));
            $keyboard.on('click', '.osk-hide', this.onCloseClicked.bind(this));
            $keyboard.on('mousedown touchstart', '.osk-capslock', this.onCapslockClicked.bind(this));
            $keyboard.on('mousedown touchstart', '.osk-shift', this.onShiftStart.bind(this));
            $keyboard.on('mouseup touchend', '.osk-shift', this.onShiftEnd.bind(this));
            $keyboard.on('touchstart touchend', 'li:not(.osk-dragger)', this.onTouchHandler);
        };

        this.onCapslockClicked = function() {
            $letterKeys.toggleClass('osk-uppercase');
            $capslockUppercase.toggleClass('osk-uppercase');

            // FIXME: Typing special characters with capslock is not working (only with shift) - BE
            // $keyboard.find('.osk-off').toggleClass('is-hidden');
            // $keyboard.find('.osk-on').toggleClass('is-hidden');

            capslock = !capslock;
        };

        this.onShiftStart = function() {
            if (!capslock) {
                $letterKeys.addClass('osk-uppercase');
            } else {
                $letterKeys.removeClass('osk-uppercase');
            }

            $keyboard.find('.osk-off').addClass('is-hidden');
            $keyboard.find('.osk-on')
                .removeClass('is-hidden')
                .addClass('is-visible');
        };

        this.onShiftEnd = function() {
            if (!capslock) {
                $letterKeys.removeClass('osk-uppercase');
            } else {
                $letterKeys.addClass('osk-uppercase');
            }

            $keyboard.find('.osk-off').removeClass('is-hidden');
            $keyboard.find('.osk-on')
                .addClass('is-hidden');
        };

        /**
         * On close keyboard button clicked.
         */
        this.onCloseClicked = function() {
            this.close(true);
        };

        /**
         * Called after mousdown/touchstart event has been fired.
         * Starts the send character interval. (This interval sends every 150 seconds the called character to the input)
         *
         * @param {jQuery.Event} event
         */
        this.onMouseDown = function(event) {
            this.currentlyPressedKey = this.getKeyFromEvent(event);

            // `getKeyFromEvent` returns false if touch is on dragger or button is disabled.
            if (!this.currentlyPressedKey) {
                return;
            }

            if (event.currentTarget.attributes['data-modifier']) {
                switch (event.currentTarget.attributes['data-modifier'].value) {
                    case 'AltGr':
                        /**
                         * If shift left is pressed and capslock isn't active, send a keyup event just for this modifier key.
                         * Applies only for osk-letters (e.g. french keyboard: ° needs the shift key ("osk-on")
                         */
                        if ($(event.currentTarget).hasClass('osk-uppercase') && !capslock
                            && $(event.currentTarget).hasClass('osk-letter')) {
                            this.currentlyPressedKey = ('input-helper' === $input.attr('name')) ? shiftLeftKey : shiftKey;
                            this.sendCharacter('keyup');
                        }
                        this.currentlyPressedKey = altGraphKey;
                        break;

                    case 'Shift':
                        this.currentlyPressedKey = shiftLeftKey;
                        break;
                }
                this.sendCharacter('keydown');

                this.currentlyPressedKey = this.getKeyFromEvent(event);
            }

            clearInterval(this.keyDownInterval);
            this.repeatTimeout = setTimeout(function() {
                clearInterval(this.keyDownInterval);
                this.keyDownInterval = setInterval(this.sendCharacter.bind(this, 'keydown'), 150);
            }.bind(this), 450);

            this.sendCharacter('keydown');

            event.preventDefault();

            //Immediately send capslock "keyup" event to prevent synchronization problems between osk and backend (capslock is a status not a trigger like shift).
            if ($(event.currentTarget).hasClass('osk-capslock')) {
                this.onMouseUp(event);
            }
        };

        /**
         * Called after mouseup/touchend event has been fired.
         * Stops the send character interval and send a character to the input-field.
         *
         * @param {jQuery.Event} event
         */
        this.onMouseUp = function(event) {
            clearTimeout(this.repeatTimeout);
            clearInterval(this.keyDownInterval);

            var $el = $(event.currentTarget);

            this.sendCharacter('keyup');

            // Send shift up after release.
            if ($el.hasClass('osk-shift')) {
                this.currentlyPressedKey = {
                    $key: $el,
                    ...shiftLeftKey
                };

                this.sendCharacter('keyup');
            } else if (event.currentTarget.attributes['data-modifier']) {
                switch (event.currentTarget.attributes['data-modifier'].value) {
                    case 'AltGr':
                        this.currentlyPressedKey = altGraphKey;
                        break;

                    case 'Shift':
                        this.currentlyPressedKey = shiftLeftKey;
                        break;
                }

                this.sendCharacter('keyup');

                this.currentlyPressedKey = this.getKeyFromEvent(event);

                /**
                 * If shift left is pressed and capslock isn't active, send a keydown event to "re-activate" the shift key.
                 * Applies only for osk-letters (e.g. french keyboard: ° needs the shift key ("osk-on")
                 */
                if ($(event.currentTarget).hasClass('osk-uppercase') && !capslock
                    && $(event.currentTarget).hasClass('osk-letter')) {
                    this.currentlyPressedKey = ('input-helper' === $input.attr('name')) ? shiftLeftKey : shiftKey;
                    this.sendCharacter('keydown');
                }
            }

            this.currentlyPressedKey = null;
            event.preventDefault();
        };

        /**
         * Sends the touched character to the focused input-field.
         */
        this.sendCharacter = function(keyEvent) {
            if (!this.currentlyPressedKey) {
                return;
            }

            var event = $.Event(
                keyEvent,
                {
                    $key: this.currentlyPressedKey.$key,
                    which: this.currentlyPressedKey.keyCode, // 65
                    key: this.currentlyPressedKey.character, // a
                    keyCode: this.currentlyPressedKey.keyCode, // 65
                    isOnScreenKeyboardEvent: true,
                    originalEvent: {
                        repeat: false,
                        code: keyMapper.characterToKeyCharacter(this.currentlyPressedKey.character), // KeyA
                        key: this.currentlyPressedKey.character, // a
                        keyCode: this.currentlyPressedKey.keyCode, // 65
                        type: keyEvent,
                        which: this.currentlyPressedKey.keyCode // 65
                    }
                }
            );

            // Send to framebox.
            if ('input-helper' === $input.attr('name')) {
                $input.trigger(event);
                // Send to a native input.
            } else if ('keydown' === keyEvent || 'keyup' === keyEvent) {
                settings.onKeyPress.call(
                    keyEvent,
                    $input,
                    this.currentlyPressedKey.pressedCharacterValue,
                    this.currentlyPressedKey.keyCode,
                    keyEvent
                );
            }
        };

        /**
         * Handles click on key.
         *
         * @param {jQuery.Event} event
         *
         * @return {boolean}
         */
        this.getKeyFromEvent = function(event) {
            var $key = $(event.currentTarget);
            var originalCharacter = $key.html();
            var character = '';
            var keyCode;

            // Disabled keys/dragger
            if ($key.hasClass('osk-dragger') || $key.hasClass('osk-disabled')) {
                $input.focus();

                return false;
            }

            // Symbol/number keys
            if ($key.hasClass('osk-symbol') || $key.hasClass('osk-number')) {
                originalCharacter = $('span:visible', $key).html();
            }

            // Button has a specific character.
            if (true === capslock && $key.find('.osk-on').data('character')) {
                originalCharacter = $key.find('.osk-on').data('character');
            } else if (false === capslock && $key.find('.osk-off').data('character')) {
                originalCharacter = $key.find('.osk-off').data('character');
            } else if ($key.data('character')) {
                character = $key.data('character');
            }

            if ('' === character) {
                character = originalCharacter;
            }

            if ($key.hasClass('osk-uppercase')) {
                character = character.toUpperCase();
                originalCharacter = originalCharacter.toUpperCase();
            }

            keyCode = keyMapper.characterToKeyCode(character);

            /*
             * Button has a specific character.
             * !$input[0]: Office 365 workaround
             * $input[0].name === 'input-helper': Browser workaround
             */
            if ($key.data('keyCode') && (!$input[0] || $input[0].name === 'input-helper')) {
                keyCode = $key.data('key-code');
            }

            return {
                $key: $key,
                pressedCharacterValue: kim.getMappedCharacter(originalCharacter),
                character: character,
                keyCode: parseInt(keyCode)
            };
        };

        /**
         * Handles touch on key to display visual effect.
         *
         * @param {jQuery.Event} event
         */
        this.onTouchHandler = function(event) {
            $(event.currentTarget).toggleClass('is-active');
        };

        /**
         * @method open
         *
         * @param  {object} $el input element
         */
        this.open = function($el) {
            if (state === 'closed') {
                state = 'open';
                $input = $el;
                activateInput($input);
                $keyboard.show();

                if (settings.onOpen) {
                    settings.onOpen.call(null, $input);
                }
            }

            resetPosition();

            var app = require('../../modules/app/app');
            app.emit('keyboard:opened');
        };

        this.setInput = function($el) {
            $input = $el;
            activateInput($input);
        };

        /**
         * @param {Boolean} focusLastInput - This forces to focus the last input field if the keyboard is closed
         * by clicking on the x button.
         * See: https://jira.wolfvision.at/browse/RELEASE-920.
         *
         * We don't want to do this always because this leads to unwanted side effects as described in
         * https://jira.wolfvision.at/browse/RELEASE-1074.
         */
        this.close = function(focusLastInput) {
            if (state === 'closed') {
                return;
            }

            $keyboard.hide();
            $input.blur();
            $keyboardTriggers.removeClass('osk-focused');

            state = 'closed';

            if (!!settings.onClose) {
                settings.onClose.call();
            }

            if (this.$lastInput) {
                this.$lastInput.off('focusout.auto-close');
            }

            if (focusLastInput && !$input.hasClass('block-on-ok')) {
                this.remote.focus($input.get(0));
            }

            var app = require('../../modules/app/app');
            app.emit('keyboard:closed');
        };

        this.destroy = function() {
            this.close();
            try {
                $keyboard
                    .draggable('destroy')
                    .off()
                    .remove();
            } catch (error) {
                console.log(error);
            }
        };

        this.bindDOMEvents();

        return this;

    };

    /**
     * Renders the keyboard.
     *
     * @param {String} keyboardId
     * @param {String} language
     *
     * @return {jQuery} the keyboard jQuery instance
     */
    function renderKeyboard(keyboardId, language) {
        var $keyboard = $('#' + keyboardId);
        var keyBoardTemplate = keyboardTpl;

        if ($keyboard.length) {
            return $keyboard;
        }

        switch (language) {
            case 'de':
                keyBoardTemplate = keyboardTplDe;
                break;
            case 'no':
                keyBoardTemplate = keyboardTplNo;
                break;
            case 'fr':
                keyBoardTemplate = keyboardTplFr;
                break;
            case 'fr-ca':
                keyBoardTemplate = keyboardTplFrCa;
                break;
            case 'sv':
                keyBoardTemplate = keyboardTplSv;
                break;
            case 'it':
                keyBoardTemplate = keyboardTplIt;
                break;
            case 'ch':
                keyBoardTemplate = keyboardTplCh;
                break;
            case 'uk':
                keyBoardTemplate = keyboardTplUk;
                break;
            case 'jp':
                keyBoardTemplate = keyboardTplJp;
                break;
            default:
                keyBoardTemplate = keyboardTpl;
                break;
        }

        $keyboard = $(keyBoardTemplate({
            keyboardId: keyboardId
        }));

        $keyboard.find('.osk-on').addClass('is-hidden');

        $('body').append($keyboard);
        return $keyboard;
    }

})($);
