const Vuex = require('vuex');
const template = require('./live-window.html');
const draggable = require('vuedraggable');
const vueUtils = require('../../../../../components-vue/util.js');
const platform = require('../../../../../../platform/platform');
const app = require('../../../../../app');
const { checkDropOnOutputValid, checkGroupValid } = require('../../../rules');

module.exports = {
    template,
    components: {
        draggable
    },

    props: {
        monitor: {
            type: Object,
            required: true
        },
        window: {
            type: Object,
            required: true
        },
        nrOpenWindows: {
            type: Number,
            required: true
        },
        source: {
            type: String,
            required: true
        }
    },

    data: function() {
        return {
            isDragging: false,
            replaceBlocked: false,
            addBlocked: false
        };
    },

    computed: {
        draggableOptionsWindow: function() {
            return {
                group: {
                    name: 'live-items',
                    pull: true,
                    put: function(to, from, dragEl) {
                        const putAllowed = checkGroupValid(from.options.group.name) && this.nrOpenWindows === 1;

                        this.addBlocked = !putAllowed
                            || this.addBlockedDefault
                            || !checkDropOnOutputValid(dragEl._underlying_vm_, this.monitor.outputPort);

                        return putAllowed;
                    }.bind(this)
                }
            };
        },
        draggableOptionsReplace: function() {
            return {
                group: {
                    name: 'replace',
                    pull: false,
                    put: function(to, from, dragEl) {
                        // Check if put (replace) should be allowed here. Replace is not allowed when window is a web
                        // Conference or a vCast window with camera back active.
                        // If window is a matrix stream it can only be replaced with another live item.
                        const putAllowed = checkGroupValid(from.options.group.name);

                        this.replaceBlocked = !putAllowed
                            || this.replaceBlockedDefault
                            || (from.options.group.name !== 'live-items' && this.window.contentType === 'matrix')
                            || !checkDropOnOutputValid(dragEl._underlying_vm_, this.monitor.outputPort);

                        return putAllowed;
                    }.bind(this)
                }
            };
        },
        isCbox: function() {
            return platform.checks.isCboxControlScreen;
        },
        /**
         * Default value of drag & drop add blocked based on the window props
         */
        addBlockedDefault: function() {
            return ['webconference', 'zoom', 'teams'].includes(this.window.contentType)
                || this.window?.options?.cameraBackActive;
        },
        /**
         * Default value of drag & drop replace blocked based on the window props
         */
        replaceBlockedDefault: function() {
            return ['webconference', 'zoom', 'teams'].includes(this.window.contentType)
                || this.window?.options?.cameraBackActive;
        },
        ...Vuex.mapGetters('controlScreen', ['getDragging', 'getDragToId'])
    },

    methods: {
        dragStart: function() {
            this.isDragging = true;
            this.$store.dispatch('controlScreen/setDragging', true);
            this.getPreviewImage(function(image) {
                const draggedElement = document.getElementsByClassName('sortable-drag')[0];
                if (draggedElement) {
                    const draggedImage = draggedElement.children[0];
                    draggedImage.src = this.cropImage(image);
                    draggedImage.width = this.window.coordinates.width;
                    draggedImage.height = this.window.coordinates.height;
                }
            }.bind(this));
        },

        dragEnd: function() {
            this.isDragging = false;
            this.$store.dispatch('controlScreen/setDragging', false);
        },

        openWindow: function(e) {
            if (this.addBlocked) {
                app.emit('main-loop.fast.start', {
                    id: 'prepare-list'
                });

                return;
            }

            this.$emit('open-window', e);
        },

        replaceWindow: function(e, replaceAppId) {
            if (this.replaceBlocked) {
                app.emit('main-loop.fast.start', {
                    id: 'prepare-list'
                });

                return;
            }

            // The view model of the dragged item
            const vm = e.item._underlying_vm_;
            const type = vm.type ? vm.type : vm.contentType;
            console.log('Replace window with app ID ' + replaceAppId + ' with ' + (vm.appId ? vm.appId + ' ' : '') + type);

            if (vm.appId) {
                // If the application has an app id call replace operation
                this.wolfprot.talk('setReplaceApplication', {
                    appId: vm.appId,
                    replaceAppId: replaceAppId
                });

                app.emit('main-loop.fast.start', {
                    id: 'prepare-list'
                });
            } else {
                // Otherwise, application needs to be started before replacing the other
                this.sourcesService.open(vm, {
                    output: this.monitor.outputPort,
                    replaceAppId: replaceAppId
                });
            }
        },

        getPreviewImage: function(callback) {
            this.wolfprot.talk('getPictureCbox', {
                pictureWidth: this.monitor.width,
                pictureHeight: this.monitor.height,
                pictureSource: this.source
            }).then(data => {
                this.createFrame(data.picture, callback);
            });
        },

        createFrame: function(data, callback) {
            const blob = new Blob([data], { type: 'image/jpeg' });
            const imageUrl = URL.createObjectURL(blob);

            this.IMAGE.onload = function() {
                callback(this);
                this.onload = null;
                this.src = '';
                URL.revokeObjectURL(imageUrl);
            };

            this.IMAGE.src = imageUrl;
        },

        cropImage: function(img) {
            // Stretch img onto monitor sized canvas
            const baseCanvas = document.createElement('canvas');
            baseCanvas.width = this.monitor.width;
            baseCanvas.height = this.monitor.height;
            baseCanvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height,
                0, 0, this.monitor.width, this.monitor.height);

            // Create window sized canvas
            const windowCanvas = document.createElement('canvas');
            windowCanvas.width = this.window.coordinates.width;
            windowCanvas.height = this.window.coordinates.height;
            // Tolerance needed as pixel conversion from scaling creates inaccuracies
            // To crop from bottom and right cropTolerance * 2 is needed
            const cropTolerance = 1;
            windowCanvas.getContext('2d').drawImage(baseCanvas, this.window.coordinates.x + cropTolerance,
                this.window.coordinates.y + cropTolerance, this.window.coordinates.width - cropTolerance * 2,
                this.window.coordinates.height - cropTolerance * 2, 0, 0,
                this.window.coordinates.width, this.window.coordinates.height);

            return windowCanvas.toDataURL('image/jpeg');
        }
    },

    created: function() {
        this.IMAGE = new Image();
        this.wolfprot = vueUtils.wolfprot();
        this.sourcesService = app.getService('SourcesService');

        this.replaceBlocked = this.replaceBlockedDefault;
        this.addBlocked = this.addBlockedDefault;
    },

    destroy: function() {
        this.IMAGE = null;
    }
};
