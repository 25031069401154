module.exports=[
    {
        "titleKey": "wallpaper.abstract_1",
        "filename": "wallpaper_abstract_01.jpg"
    },
    {
        "titleKey": "wallpaper.abstract_2",
        "filename": "wallpaper_abstract_02.jpg"
    },
    {
        "titleKey": "wallpaper.abstract_3",
        "filename": "wallpaper_abstract_03.jpg"
    },
    {
        "titleKey": "wallpaper.abstract_4",
        "filename": "wallpaper_abstract_04.jpg"
    },
    {
        "titleKey": "wallpaper.abstract_5",
        "filename": "wallpaper_abstract_05.jpg"
    },
    {
        "titleKey": "wallpaper.abstract_6",
        "filename": "wallpaper_abstract_06.jpg"
    },
    {
        "titleKey": "wallpaper.abstract_7",
        "filename": "wallpaper_abstract_07.jpg"
    },
    {
        "titleKey": "wallpaper.abstract_8",
        "filename": "wallpaper_abstract_08.jpg"
    },
    {
        "titleKey": "wallpaper.abstract_9",
        "filename": "wallpaper_abstract_09.jpg"
    }
]
