'use strict';

const app = require('../../../app');
const previewTpl = require('./preview.hbs');
const RedrawEngine = require('../../../../redraw-engine');
const rbac = require('../../../../rbac/rbac');
const $ = require('jquery');

app.component('MatrixPreview', {
    template: previewTpl,

    Engine: null,

    $canvas: null,

    $icon: null,

    $previewTitleContainer: null,

    $stationPreview: null,

    context: null,

    initialize: function(options) {
        this.options = options;
        this.matrixPreviewService = this.getService('MatrixPreviewService');
        this.matrixService = this.getService('MatrixService');
        this.matrixConfigs = this.getService('MatrixConfigs');
        this.matrixMainService = this.getService('MatrixMainService');
        this.frame = new Image();
        this.nextFrameLoadedTimeout = 0;
        this.previewEngine = new RedrawEngine();

        this.data = {
            station: options.station,
            size: options.size,
            serial: options.serial,
            isMaster: options.id.startsWith('master'),
            position: this.matrixService.convertCoordinates(
                {
                    x: options.cx,
                    y: options.cy
                },
                $(document).find('.svg-grid').width(), $(document).find('.svg-grid').height(),
                this.matrixConfigs.get('dimensions.width'), this.matrixConfigs.get('dimensions.height'))
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.showPreview();

        this.$el.prop({
            'id': 'matrix-preview-' + this.options.id
        });
    },

    storeSelectors: function() {
        this.$stationPreview = this.$el.find('.station-preview');
    },

    /**
     * Show preview of selected station.
     */
    showPreview: function() {
        this.$stationPreview.append($(document.createElement('canvas'))
            .addClass('preview')
            .width(this.data.size.width)
            .height(this.data.size.height)
            .css('position', 'absolute')
            .css('left', (this.data.position.x - (this.data.size.width / 2) + 'px'))
            .css('top', (this.data.position.y - (this.data.size.height / 2) + 'px')));

        this.$canvas = this.$el.find('.preview');

        this.context = this.$canvas.get(0).getContext('2d');

        this.$stationPreview.append($(document.createElement('div'))
            .addClass('preview-station-title-container')
            .width(this.data.size.width)
            .css('left', parseInt(this.$canvas.css('left')))
            .css('top', (parseInt(this.$canvas.css('top')) + this.$canvas.height()) + 'px'));

        this.$previewTitleContainer = this.$el.find('.preview-station-title-container');

        this.$previewTitleContainer.append($(document.createElement('div'))
            .addClass('preview-station-color')
            .css('background-color', this.matrixPreviewService.getStationColor(this.data.station)));

        this.$previewTitleContainer.append($(document.createElement('div'))
            .addClass('preview-station-title')
            .html(this.data.isMaster ? 'Presenter' : this.data.station.attr('name')));

        this.previewEngine.start({ fps: 5 });
        this.previewEngine.on('redraw.update', this.loadNextFrame.bind(this));
    },

    /**
     * Close preview of selected station.
     */
    closePreview: function() {
        this.previewEngine.stop();
        this.previewEngine.off('redraw.update', this.loadNextFrame.bind(this));
    },

    /**
     * Requests the next frame from the server and
     * call the render method.
     *
     * @return {Object}
     */
    loadNextFrame: function() {
        if (!rbac.hasAccess('Livestream', 'load')) {
            return {};
        }

        if (this.nextFrameLoadedTimeout <= 0 && !this.paused) {
            this.nextFrameLoadedTimeout = 100;

            this.matrixService.getMatrixMasterPreviewPicture(this.data.serial, this.data.size)
                .then(function(data) {
                    this.prepareImage(data);
                }.bind(this));
        } else {
            this.nextFrameLoadedTimeout--;
        }

        return this;
    },

    /**
     * Render method of the livestream.
     *
     * @param data {Mixed}
     * @param callback {Function} called after the image has loaded
     */
    createFrame: function(data, callback) {
        const blob = new Blob([data], { type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(blob);

        if (imageUrl) {
            this.frame.src = imageUrl;
        }

        this.frame.onload = function() {
            callback(this);
            this.onload = null;
            this.src = '';
            URL.revokeObjectURL(imageUrl);
        };
    },

    /**
     * Render method of the livestream.
     *
     * @return {object}
     */
    renderImage: function(image) {
        if (image) {
            this.context.canvas.width = image.width;
            this.context.canvas.height = image.height;
            this.context.drawImage(image, 0, 0, image.width, image.height);

            this.nextFrameLoadedTimeout = 0;
        }

        return this;
    },

    /**
     * Prepare image-data for canvas and store it.
     *
     * @param {object} data
     *
     * @return {object}
     */
    prepareImage: function(data) {
        this.createFrame(data.imageData.picture, function(image) {
            this.renderImage(image);
        }.bind(this));

        return this;
    },

    destroy: function() {
        this.closePreview();
    }
});
