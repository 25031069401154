// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"collaboration-user-item double-action-row\"\n     data-nav-area=\".btn-mini-switch, .focusable:not(.is-disabled), .is-focusable:not(.is-disabled)\"\n     data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"clientId") : depth0), depth0))
    + "\">\n    <div class=\"col-xs-8\">\n        <h3 class=\"nickname\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"nick") : depth0), depth0))
    + "</h3>\n    </div>\n    <div class=\"col-xs-2\">\n        <div class=\"btn-mini-switch-container pull-right m-left-10\">\n            <input type=\"checkbox\"\n                   class=\"btn-mini-switch\"\n                   data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"clientId") : depth0), depth0))
    + "\"\n                   data-action=\"toggle-user\"\n                   tabindex=\"-1\"/>\n            <div class=\"btn-mini-switch-toggle-container\">\n                <div class=\"btn-mini-switch-toggle\"></div>\n            </div>\n        </div>\n    </div>\n    <div class=\"col-xs-2 icon-container\">\n        <span class=\"icon icon-close focusable pull-left\"\n              data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"clientId") : depth0), depth0))
    + "\"\n              data-action=\"remove-user\"\n              tabindex=\"-1\"></span>\n        <span class=\"icon icon-circle-active hidden pull-left\" data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"clientId") : depth0), depth0))
    + "\" tabindex=\"-1\"></span>\n    </div>\n</div>\n";
},"useData":true});
