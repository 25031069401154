// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"file-box-header\" data-action=\"back\">\n    <div class=\"file-box-header-icon btn-back\">\n        <span class=\"icon icon-btn-back\"></span>\n    </div>\n    <div class=\"file-box-header-text\">\n        <span class=\"text\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"sources.filebrowser",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":27},"end":{"line":6,"column":54}}}))
    + "</span>\n    </div>\n\n    <div class=\"file-box-loader\" id=\"file-box-loader\">\n        <div class=\"loader small\"></div>\n    </div>\n</div>\n\n<div id=\"file-list\" class=\"file-list matrix-box-list\"></div>\n";
},"useData":true});
