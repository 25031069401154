'use strict';

var app = require('../../../app');
var $ = require('jquery');
var template = require('./ftp-settings.hbs');
var FormActionView = require('./../form-action-view');
var ftpTestsStatus = {
    none: 'none',
    testing: 'testing',
    failed: 'failed',
    success: 'success'
};

app.component('FtpSettings', {
    template: template,

    /**
     * @method initialize
     */
    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.pollFactory = this.getService('PollFactoryService');
        this.remote = this.getService('RemoteService');
        this.passwordChanged = false;
        this.ftpTestPolling = null;
        this.ftpTest = this.createStateMachine({
            state: ftpTestsStatus.none,
            states: ftpTestsStatus
        });

        this.loadData();
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.storeSelectors();
        this.initForm();
        this.initFormAction();
        this.bindEvents();
        this.bindDOMEvents();
    },

    /**
     * @method storeSelectors
     */
    storeSelectors: function() {
        this.$connectionTest = this.$el.find('#ftp-settings-test');
        this.$testBtn = this.$el.find('.ftp-connection-test-action');
    },

    /**
     * @method fucntion
     */
    bindEvents: function() {
        this.form.on('change.input', this.changeFormHandler.bind(this));
        this.on('remote.enter.keydown', this.checkActionForm.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.form.get('password').$el.on('keydown touchstart', this.onPasswordChangeHandler.bind(this));
        this.$el.on('click', '.ftp-connection-test-action', this.testFTPConnection.bind(this));
    },

    /**
     * @method testFTPConnection
     */
    testFTPConnection: function() {
        this.deviceConnection
            .send('setFtpTest');

        this.ftpTestPolling = this.ftpTestPolling || this.pollFactory.create({
            pollHandler: this.fetchFTPTestStatus.bind(this)
        });

        this.ftpTestPolling.addCheck(function(stop) {
            switch (this.ftpTest.getState()) {
                case ftpTestsStatus.testing:
                    this.toggleFTPState('testing');
                    break;
                case ftpTestsStatus.failed:
                    this.toggleFTPState('failed');
                    this.$testBtn.prop('disabled', false);
                    stop();
                    break;
                case ftpTestsStatus.success:
                    this.toggleFTPState('success');
                    this.$testBtn.prop('disabled', false);
                    stop();
                    break;
            }
        }.bind(this));

        this.toggleFTPState('testing');
        this.$testBtn.prop('disabled', true);

        if (!this.ftpTestPolling.isPolling) {
            this.ftpTestPolling.start();
        }
    },

    /**
     * @method toggleFTPState
     * @param {string} name
     */
    toggleFTPState: function(name) {
        this.$connectionTest.attr('data-ftp-test-status', 'is-' + name);
    },

    /**
     * @method fetchFTPStatus
     * @param {function} done
     */
    fetchFTPTestStatus: function(done) {
        this.deviceConnection
            .send('getFtpTestStatus')
            .then(function(data) {
                this.ftpTest.changeState(ftpTestsStatus[data.status]);
                done();
            }.bind(this));
    },

    /**
     * @method onPasswordChangeHandler
     */
    onPasswordChangeHandler: function() {
        this.passwordChanged = true;
    },

    /**
     * @method loadData
     */
    loadData: function() {
        return this.deviceConnection
            .send([
                'getFtpMode',
                'getFtpUrl',
                'getFtpUser',
                'getFtpPassword'
            ])
            .then(function(mode, url, user, password) {
                this.formData = {
                    mode: mode.mode,
                    url: url.url,
                    user: user.user,
                    password: password.password
                };

                this.form.setValues(this.formData);
            }.bind(this));
    },

    /**
     * @method changeFormHandler
     */
    changeFormHandler: function() {
        this.actionView.open();
        this.checkActionForm();
        this.changes = true;
    },

    /**
     * @method initForm
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#ftp-settings'),
            validationContainer: '.input-group'
        });
    },

    /**
     * @method initFormAction
     */
    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#ftp-form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    /**
     * @method checkActionForm
     */
    checkActionForm: function() {
        // RELEASE-1275: Block form-validate on history back.
        if (this.remote.mickey && 'overlay-history-back' === this.remote.getFocus().id) {
            return;
        }

        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * @method saveHandler
     */
    saveHandler: function() {
        var data = this.form.serialize();
        var commands = [
            {
                command: 'setFtpMode',
                data: {
                    mode: data.mode
                }
            },
            {
                command: 'setFtpUrl',
                data: {
                    url: data.url
                }
            },
            {
                command: 'setFtpUser',
                data: {
                    user: data.user
                }
            }
        ];

        if (!!this.passwordChanged && !!/[^*]/i.test(data.password)) {
            commands.push(
                {
                    command: 'setFtpPassword',
                    data: {
                        password: data.password
                    }
                }
            );
        }

        this.deviceConnection
            .send(commands);

        this.handleFtpSave();
        this.form.setDefaultValues();
        this.emit('overlay.remote.focus', true);
    },

    /**
     * @method handleWlanModeSave
     */
    handleFtpSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.changes = false;
    },

    /**
     * Calls on clicked the cancel button
     * @method cancelHandler
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
        this.changes = false;
    },

    /**
     * @method destroy
     */
    destroy: function() {
        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    hasChanges: function() {
        if (!this.actionView) {
            return {
                hasChanges: false,
                invalid: false
            };
        }

        return {
            hasChanges: this.changes,
            invalid: $(this.actionView.$submitBtn).prop('disabled')
        };
    }
});
