const template = require('./slider.html');

module.exports = {
    template,
    props: {
        value: {
            type: Boolean,
            default: false,
            required: false
        },

        disabled: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data: function() {
        return {};
    }
};
