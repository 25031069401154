// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div id=\"admin-settings-btn\" class=\"admin-settings-btn\">\n        <span class=\"icon icon-settings-1\"></span>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2>"
    + container.escapeExpression((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||container.hooks.helperMissing).call(alias1,depth0,{"name":"tr","hash":{"key":(depth0 != null ? lookupProperty(depth0,"messageKey") : depth0)},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":30}}}))
    + "</h2>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSettingsButton") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":6,"column":7}}})) != null ? stack1 : "");
},"useData":true});
