'use strict';

var $ = require('jquery');
var WolfProtHelper = require('./wolfprot/wolfprot-helper');
var app = require('../../app/app.js');

/**
 * Connection states.
 */
var states = {
    '-1': 'inactive',
    0: 'connecting',
    1: 'open',
    2: 'closing',
    3: 'closed'
};

var RECONNECT_INTERVAL = 300;

/**
 * @class Socket
 * @param {String} host The host URL for the websocket connection
 * @param {Object} options
 * @constructor
 */
function Socket(host, sessionId, options) {
    this.options = options || {};
    this.host = host || 'ws://' + document.location.host;
    this.closed = false;
    this.sessionId = sessionId;

    this.authService = app.getService('AuthenticationService');
}

/**
 * Create a WS instance and connect to the server
 * @method connect
 * @return {Promise}
 */
Socket.prototype.connect = function connect() {
    var dfd = $.Deferred();

    if (this.getState() === 'closed') {
        this.connection = null;
    }

    if (this.getState() !== 'inactive') {
        return this.reconnect();
    }

    try {
        this.connection = new WebSocket(this.host);
        this.connection.binaryType = this.options.binaryType;
        this.wolfProtHelper = new WolfProtHelper(this);
    } catch (err) {
        return this.reconnect();
    }

    this.connection.onopen = function() {
        dfd.resolve();
        (this.options.onOpen || $.noop)();
    }.bind(this);

    this.connection.onmessage = (this.options.onMessage || $.noop).bind(this);

    this.connection.onerror = function onerror(err) {
        (this.options.onError || $.noop)();
        dfd.reject(err);
        this.reconnect();
    }.bind(this);

    this.connection.onclose = function onclose() {
        (this.options.onClose || $.noop)();
        this.reconnect();
    }.bind(this);

    return dfd.promise();
};

/**
 * Reconnect method
 * @method reconnect
 * @private
 */
Socket.prototype.reconnect = function reconnect() {
    if (!this.closed) {
        setTimeout(this.connect.bind(this), RECONNECT_INTERVAL);
    }
};

/**
 * Check the current WS connection state
 * @method getState
 * @return {String} state
 */
Socket.prototype.getState = function getState() {
    return states[this.connection ? this.connection.readyState : '-1'];
};

/**
 * Close the WS connection
 * @method close
 */
Socket.prototype.close = function close() {
    this.connection.onclose = $.noop;
    this.connection.onerror = $.noop;
    this.closed = true;
    this.connection.close();
};

/**
 * WS send wrapper method
 * @param {Mixed}
 * @method send
 */
Socket.prototype.send = function send(data, userLevel) {
    var dfd = $.Deferred();

    try {
        if (!!data && this.getState() === 'open') {
            var cmdCheck = this.wolfProtHelper.checkCmdLength(data);

            if (cmdCheck.slice) {
                data = data.slice(0, cmdCheck.definedLen);
            }

            if (this.sessionId) {
                data = this.wolfProtHelper.insertHeaderID(data, this.sessionId);
            }

            if (!userLevel || userLevel <= this.authService.getUserLevel()) {
                this.connection.send(data);
                dfd.resolve();
            } else {
                dfd.reject();
            }

            return dfd.promise();
        } else {
            throw new Error('socket.send failed.' + data);
        }
    } catch (error) {
        if (window.SHOW_ERROR === true) {
            console.error(error);
        }
    }
    dfd.reject();

    return dfd.promise();
};

module.exports = Socket;
