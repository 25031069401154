'use strict';

var app = require('../../../app');
var endScreensaver = require('./end-screensaver.hbs');

app.component('EndScreensaver', {
    template: endScreensaver,
    className: 'modal-end-screensaver',

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    serialize: function() {
        return {
            messageKey: this.options.configs.messageKey,
            icon: this.options.configs.icon
        };
    }
});

