const template = require('./upload-progress.html');
const vueUtils = require('../../components-vue/util.js');
const uploadStates = require('./../../states').uploadStates;

module.exports = {
    template,
    props: {
        uploads: {
            type: Array,
            required: true
        },
        reduced: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: function() {
        return {};
    },

    methods: {
        /**
         * Retry failed uploads
         */
        tryFailedUploadsAgain: function(ev) {
            ev.stopPropagation();

            this.$emit('retry', {
                uploads: this.failedUploads
            });
        },

        i18n: vueUtils.i18n
    },

    computed: {
        failedUploads: function() {
            return this.uploads.filter(function(upload) {
                return upload.status === uploadStates.failed;
            });
        },
        nonAbortedUploads: function() {
            return this.uploads.filter(upload => upload.status !== uploadStates.aborted);
        },
        uploadProgressInPercent: function() {
            let total = 0.0;

            const uploads = this.uploads.filter(function(upload) {
                return [
                    uploadStates.done,
                    uploadStates.pending,
                    uploadStates.running
                ].indexOf(upload.status) >= 0;
            });

            uploads.forEach(function(upload) {
                const progress = upload.progress ? upload.progress : 0;
                total += progress / uploads.length;
            }.bind(this));

            return total;
        },
        overallUploadStatus: function() {
            let status = uploadStates.done;

            const states = [
                uploadStates.done,
                uploadStates.pending,
                uploadStates.running,
                uploadStates.aborted,
                uploadStates.failed
            ];

            this.uploads.forEach(function(upload) {
                if (states.indexOf(status) < states.indexOf(upload.status)) {
                    status = upload.status;
                }
            }.bind(this));

            return status;
        },
        numberOfFilesUploading: function() {
            return this.uploads.filter(function(upload) {
                return [
                    uploadStates.pending,
                    uploadStates.running
                ].indexOf(upload.status) >= 0;
            }.bind(this)).length;
        }
    }
};
