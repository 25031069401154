const fsUtils = require('../filebrowser/utils.js');

const iconsByExtension = {
    'txt': 'icon-v3-filetype-txt',
    'ai': 'icon-v3-filetype-ai',
    'css': 'icon-v3-filetype-css',
    'doc': 'icon-v3-filetype-doc',
    'exe': 'icon-v3-filetype-exe',
    'gif': 'icon-v3-filetype-gif',
    'html': 'icon-v3-filetype-html',
    'htm': 'icon-v3-filetype-htm',
    'jpg': 'icon-v3-filetype-jpg',
    'mov': 'icon-v3-filetype-mov',
    'mp3': 'icon-v3-filetype-mp3',
    'mp4': 'icon-v3-filetype-mp4',
    'pdf': 'icon-v3-filetype-pdf',
    'png': 'icon-v3-filetype-png',
    'ppt': 'icon-v3-filetype-ppt',
    'psd': 'icon-v3-filetype-psd',
    'rar': 'icon-v3-filetype-rar',
    'svg': 'icon-v3-filetype-svg',
    'xls': 'icon-v3-filetype-xls',
    'zip': 'icon-v3-filetype-zip',
    'avi': 'icon-v3-filetype-avi',
    'bmp': 'icon-v3-filetype-bmp',
    'docx': 'icon-v3-filetype-docx',
    'm4v': 'icon-v3-filetype-m4v',
    'mkv': 'icon-v3-filetype-mkv',
    'pptx': 'icon-v3-filetype-pptx',
    'wma': 'icon-v3-filetype-wma',
    'wmv': 'icon-v3-filetype-wmv',
    'xlsx': 'icon-v3-filetype-xlsx',
    'divx': 'icon-v3-filetype-divx',
    'jpeg': 'icon-v3-filetype-jpeg',
    'mka': 'icon-v3-filetype-mka',
    'oga': 'icon-v3-filetype-oga',
    'ogg': 'icon-v3-filetype-ogg',
    'ogv': 'icon-v3-filetype-ogv',
    'tif': 'icon-v3-filetype-tif'
};

const iconsByType = {
    'image': 'icon-v3-filetype-generic-image',
    'video': 'icon-v3-filetype-generic-video',
    'calc': 'icon-v3-filetype-generic-numbers',
    'presentation': 'icon-v3-filetype-generic-presentation',
    'audio': 'icon-v3-filetype-generic-audio',
    'text': 'icon-v3-filetype-generic-text'
};

const findIcon = function(file) {
    if (file.type === 'dir') {
        return 'icon-v3-folder';
    } else {
        const extension = (fsUtils.extension(file.path) || '').toLowerCase();

        if (iconsByExtension[extension]) {
            return iconsByExtension[extension];
        }

        if (iconsByType[file.type]) {
            return iconsByType[file.type];
        }

        return 'icon-blocked';
    }
};

module.exports = {
    iconsByExtension,
    iconsByType,
    findIcon
};
