// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"wlanRegion") : depth0),1,{"name":"if_equals","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":16,"column":30}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal.eula.wlan_region1_english",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":23},"end":{"line":13,"column":62}}}))
    + "</p>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal.eula.wlan_region2_english",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":23},"end":{"line":15,"column":62}}}))
    + "</p>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"wlanRegion") : depth0),1,{"name":"if_equals","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":27,"column":30}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal.eula.wlan_region1_german",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":23},"end":{"line":24,"column":61}}}))
    + "</p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal.eula.wlan_region2_german",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":23},"end":{"line":26,"column":61}}}))
    + "</p>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row eula-action-container\" data-nav-area=\".focusable\">\n            <div class=\"col-xs-12 eula-info\" id=\"eula-info\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal.eula_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":60},"end":{"line":37,"column":83}}}))
    + "</div>\n        </div>\n        <div class=\"eula-action-container\" data-nav-area=\".focusable\">\n            <div class=\"countdown small\">\n                <div class=\"circle center\">\n                    <div class=\"counter\"></div>\n                    <div class=\"l-half\"></div>\n                    <div class=\"r-half\"></div>\n                </div>\n            </div>\n        </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row eula-action-container\" data-nav-area=\".focusable\">\n                <div class=\"col-xs-12 eula-action-btn-container\">\n                    <input id=\"eula-close\"\n                            name=\"accept\"\n                           type=\"button\"\n                           class=\"btn-form eula-close focusable\"\n                           value=\""
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"modal.eula_close",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":34},"end":{"line":55,"column":59}}}))
    + "\"\n                           tabIndex=\"-1\" />\n                </div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-xs-12 eula-title\">\n        <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.eula_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":36}}}))
    + "</h2>\n    </div>\n</div>\n\n<div class=\"eula-text-container\" data-nav-area=\".focusable\" data-nav-trapped>\n    <div class=\"eula-text-scroller focusable\" tabIndex=\"-1\">\n        <div class=\"english\">\n            <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.eula.title_english",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":15},"end":{"line":10,"column":47}}}))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"wlanRegion") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":17,"column":19}}})) != null ? stack1 : "")
    + "            <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.eula.english",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":15},"end":{"line":18,"column":41}}}))
    + "</p>\n        </div>\n        <div class=\"german\">\n            <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.eula.title_german",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":15},"end":{"line":21,"column":46}}}))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"wlanRegion") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":28,"column":19}}})) != null ? stack1 : "")
    + "            <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.eula.german",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":15},"end":{"line":29,"column":40}}}))
    + "</p>\n        </div>\n    </div>\n</div>\n\n<form id=\"eula-form\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"configs") : depth0)) != null ? lookupProperty(stack1,"isInfo") : stack1),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":35,"column":4},"end":{"line":59,"column":15}}})) != null ? stack1 : "")
    + "</form>\n";
},"useData":true});
