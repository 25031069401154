// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-row\">\n                <div class=\"col-xs-12\">\n                    <h2>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCorePro") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":6,"column":24},"end":{"line":6,"column":100}}})) != null ? stack1 : "")
    + "</h2>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".input, .select, .is-focusable, .focusable\"\n                 data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <div class=\"input-group\">\n                        <label class=\"input-label\" for=\"input0-name\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.input_name_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":69},"end":{"line":15,"column":102}}}))
    + "</label>\n                        <input type=\"text\"\n                               class=\"input\"\n                               name=\"input0-name\"\n                               id=\"input0-name\"\n                               placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.input_placeholder_1",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":44},"end":{"line":20,"column":80}}}))
    + "\"\n                               data-validation=\"checkMinMaxLength\"\n                               data-min-length=\"1\"\n                               data-max-length=\"254\" />\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\" id=\"name-error\">\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":32},"end":{"line":26,"column":63}}}))
    + "\n                            </span>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"col-xs-6\">\n                    <div id=\"input0-type-container\"></div>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".input, .select, .is-focusable, .focusable\"\n                 data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <div id=\"input0-max-resolution-container\"></div>\n                </div>\n                <div class=\"col-xs-6\"></div>\n            </div>\n            <div id=\"hdmi1-use-for-webrtc-container\"\n                 class=\"row settings-list-secundary\"\n                 data-nav-area=\".btn-switch\"\n                 data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.use_for_webconf",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":44},"end":{"line":48,"column":76}}}))
    + "</h2>\n                </div>\n\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"hdmi1UseForWebRtc\"\n                               name=\"hdmi1UseForWebRtc\"\n                               type=\"checkbox\"\n                               class=\"btn-switch webrtc-input\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCorePro") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":12},"end":{"line":110,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.autostart",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":113,"column":44},"end":{"line":113,"column":70}}}))
    + "</h2>\n                </div>\n\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"autostartHdmi1\" name=\"autostartHdmi1\" type=\"checkbox\" class=\"btn-switch\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.hdmi_in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":41},"end":{"line":6,"column":65}}}));
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.in1",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":73},"end":{"line":6,"column":93}}}));
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".btn-switch\"\n                 data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.audio_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":44},"end":{"line":68,"column":73}}}))
    + "</h2>\n                </div>\n\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"hdmi1Audio\"\n                               name=\"hdmi1Audio\"\n                               type=\"checkbox\"\n                               class=\"btn-switch is-focusable\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary\"\n                 id=\"hdmi1-hdcp-support-container\"\n                 data-nav-area=\".btn-switch\"\n                 data-nav-trapped>\n                <div class=\"col-xs-4\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.hdcp_support",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":88,"column":44},"end":{"line":88,"column":73}}}))
    + "</h2>\n                </div>\n\n                <div class=\"col-xs-8 pull-right\">\n                    <div class=\"input-group flex\">\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message\" data-id=\"hdmi1Hdcp\">\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.hdcp_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":95,"column":32},"end":{"line":95,"column":58}}}))
    + "\n                            </span>\n                        </div>\n                        <div id=\"hdmi1-hdcp-enable\" class=\"btn-switch-container pull-right no-padding p-left-15\">\n                            <input id=\"hdmi1Hdcp\"\n                                   name=\"hdmi1Hdcp\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch is-focusable\" />\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-row\">\n                <div class=\"col-xs-12\">\n                    <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.in2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":130,"column":24},"end":{"line":130,"column":44}}}))
    + "</h2>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".input, .select, .is-focusable, .focusable\"\n                 data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <div class=\"input-group\">\n                        <label class=\"input-label\" for=\"input1-name\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.input_name_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":138,"column":69},"end":{"line":138,"column":102}}}))
    + "</label>\n                        <input type=\"text\"\n                               class=\"input\"\n                               name=\"input1-name\"\n                               id=\"input1-name\"\n                               placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.input_placeholder_2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":143,"column":44},"end":{"line":143,"column":80}}}))
    + "\"\n                               data-validation=\"checkMinMaxLength\"\n                               data-min-length=\"1\"\n                               data-max-length=\"254\" />\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\" id=\"name-error\">\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":149,"column":32},"end":{"line":149,"column":63}}}))
    + "\n                            </span>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"col-xs-6\">\n                    <div id=\"input1-type-container\"></div>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".input, .select, .is-focusable, .focusable\"\n                 data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <div id=\"input1-max-resolution-container\"></div>\n                </div>\n                <div class=\"col-xs-6\"></div>\n            </div>\n            <div id=\"hdmi2-use-for-webrtc-container\"\n                 class=\"row settings-list-secundary\"\n                 data-nav-area=\".btn-switch\"\n                 data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.use_for_webconf",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":171,"column":44},"end":{"line":171,"column":76}}}))
    + "</h2>\n                </div>\n\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"hdmi2UseForWebRtc\"\n                               name=\"hdmi2UseForWebRtc\"\n                               type=\"checkbox\"\n                               class=\"btn-switch webrtc-input\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row settings-list-secundary\" data-nav-area=\".btn-switch\" data-nav-trapped>\n                <div class=\"col-xs-8\">\n                    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.autostart",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":188,"column":44},"end":{"line":188,"column":70}}}))
    + "</h2>\n                </div>\n\n                <div class=\"col-xs-4\">\n                    <div class=\"btn-switch-container pull-right\">\n                        <input id=\"autostartHdmi2\" name=\"autostartHdmi2\" type=\"checkbox\" class=\"btn-switch\" />\n                        <div class=\"btn-switch-toggle-container\">\n                            <div class=\"btn-switch-toggle\"></div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "data-nav-area-default";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"input-settings\" name=\"input-settings\">\n"
    + ((stack1 = (lookupProperty(helpers,"if_or")||(depth0 && lookupProperty(depth0,"if_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),(depth0 != null ? lookupProperty(depth0,"isCorePro") : depth0),{"name":"if_or","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":125,"column":18}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":8},"end":{"line":200,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"row settings-list-row\">\n            <div class=\"col-xs-8\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.uvc_0",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":204,"column":20},"end":{"line":204,"column":42}}}))
    + "</h2>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\"\n             data-nav-area=\".input, .select, .is-focusable, .focusable\"\n             data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"uvc-input0-name\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.usb_input_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":213,"column":69},"end":{"line":213,"column":100}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input\"\n                           name=\"uvc-input0-name\"\n                           id=\"uvc-input0-name\"\n                           data-validation=\"checkMinMaxLength\"\n                           data-min-length=\"1\"\n                           data-max-length=\"254\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\" id=\"name-error\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":223,"column":28},"end":{"line":223,"column":59}}}))
    + "\n                        </span>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"col-xs-6\">\n                <div id=\"uvc-input0-type-container\"></div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\"\n             data-nav-area=\".btn-switch .input, .select, .is-focusable, .focusable\"\n             data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div id=\"uvc-input0-device\"></div>\n            </div>\n\n            <div class=\"col-xs-6\">\n                <div id=\"uvc-input0-resolution\"></div>\n            </div>\n        </div>\n\n        <div id=\"uvc-input0-use-for-webrtc-container\"\n             class=\"row settings-list-secundary\"\n             data-nav-area=\".btn-switch\"\n             data-nav-trapped\n             "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":250,"column":13},"end":{"line":250,"column":63}}})) != null ? stack1 : "")
    + ">\n            <div class=\"col-xs-8\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.use_for_webconf",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":252,"column":40},"end":{"line":252,"column":72}}}))
    + "</h2>\n            </div>\n\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input id=\"uvc-input0-use-for-webRTC\"\n                           name=\"uvc-input0-use-for-webRTC\"\n                           type=\"checkbox\"\n                           class=\"btn-switch webrtc-input\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\"\n             data-nav-area=\".btn-switch\"\n             data-nav-trapped>\n            <div class=\"col-xs-8\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.audio_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":272,"column":40},"end":{"line":272,"column":69}}}))
    + "</h2>\n            </div>\n\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"uvc-input0-audio\"\n                           type=\"checkbox\"\n                           class=\"btn-switch is-focusable\"\n                           id=\"uvc-input0-audio\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary uvc-input0-autostart-container hidden\"\n             data-supported=\"false\"\n             data-nav-area=\".btn-switch .input, .select, .is-focusable, .focusable\"\n             data-nav-trapped>\n            <div class=\"col-xs-8\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.autostart",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":293,"column":40},"end":{"line":293,"column":66}}}))
    + "</h2>\n            </div>\n\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"uvc-input0-autostart\"\n                           type=\"checkbox\"\n                           class=\"btn-switch is-focusable\"\n                           id=\"uvc-input0-autostart\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-row\">\n            <div class=\"col-xs-8\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.uvc_1",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":311,"column":20},"end":{"line":311,"column":42}}}))
    + "</h2>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\"\n             data-nav-area=\".input, .select, .is-focusable, .focusable\"\n             data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"uvc-input1-name\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.usb_input_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":320,"column":69},"end":{"line":320,"column":100}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input\"\n                           name=\"uvc-input1-name\"\n                           id=\"uvc-input1-name\"\n                           data-validation=\"checkMinMaxLength\"\n                           data-min-length=\"1\"\n                           data-max-length=\"254\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\" id=\"name-error\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":330,"column":28},"end":{"line":330,"column":59}}}))
    + "\n                        </span>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"col-xs-6\">\n                <div id=\"uvc-input1-type-container\"></div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\"\n             data-nav-area=\".btn-switch .input, .select, .is-focusable, .focusable\"\n             data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div id=\"uvc-input1-device\"></div>\n            </div>\n\n            <div class=\"col-xs-6\">\n                <div id=\"uvc-input1-resolution\"></div>\n            </div>\n        </div>\n\n        <div id=\"uvc-input1-use-for-webrtc-container\"\n             class=\"row settings-list-secundary\"\n             data-nav-area=\".btn-switch\"\n             data-nav-trapped\n             "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":357,"column":13},"end":{"line":357,"column":63}}})) != null ? stack1 : "")
    + ">\n            <div class=\"col-xs-8\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.use_for_webconf",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":359,"column":40},"end":{"line":359,"column":72}}}))
    + "</h2>\n            </div>\n\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input id=\"uvc-input1-use-for-webRTC\"\n                           name=\"uvc-input1-use-for-webRTC\"\n                           type=\"checkbox\"\n                           class=\"btn-switch webrtc-input\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\"\n             data-nav-area=\".btn-switch\"\n             data-nav-trapped>\n            <div class=\"col-xs-8\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.audio_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":379,"column":40},"end":{"line":379,"column":69}}}))
    + "</h2>\n            </div>\n\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"uvc-input1-audio\"\n                           type=\"checkbox\"\n                           class=\"btn-switch is-focusable\"\n                           id=\"uvc-input1-audio\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary uvc-input1-autostart-container hidden\"\n             data-supported=\"false\"\n             data-nav-area=\".btn-switch .input, .select, .is-focusable, .focusable\"\n             data-nav-trapped>\n            <div class=\"col-xs-8\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.autostart",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":400,"column":40},"end":{"line":400,"column":66}}}))
    + "</h2>\n            </div>\n\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"uvc-input1-autostart\"\n                           type=\"checkbox\"\n                           class=\"btn-switch is-focusable\"\n                           id=\"uvc-input1-autostart\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"form-action-container\"\n             class=\"form-primary-background\"\n             data-nav-area=\".input, .is-focusable, .focusable\"\n             data-nav-trapped></div>\n    </form>\n\n    <div class=\"row settings-list-row\">\n        <div class=\"col-xs-12\">\n            <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":424,"column":16},"end":{"line":424,"column":39}}}))
    + "</h2>\n        </div>\n    </div>\n\n    <div data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\" data-nav-trapped>\n        <div class=\"row settings-list-secundary add-new-stream clickable is-focusable\" id=\"add-new-stream\" tabIndex=\"-1\">\n            <div class=\"col-xs-9\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.add_new_stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":431,"column":20},"end":{"line":431,"column":51}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-3 add-new-icon-container\">\n                <span class=\"icon-add-content-button pull-right\"></span>\n            </div>\n        </div>\n    </div>\n\n    <div id=\"stream-input-items\" name=\"stream-input-items\"></div>\n</div>\n";
},"useData":true});
