/**
 * This framebox content-types has interactions with a keyboard.
 * - Add type to this array to enable keyboard-button on the framebox type.
 *
 * @type {string[]}
 */
module.exports.contentTypeKeyboardEnabled = [
    'vSolution',
    'Office365',
    'teams'
];

/**
 * This components of the framebox-types has interactions with a keyboard.
 * - Add component-name on this array to enable keyboard on that framebox.
 *
 * @type {string[]}
 */
module.exports.keyboardViews = [
    'FrameBoxBrowserViewer',
    'FrameBoxWebconferenceViewer',
    'FrameBoxOffice365Viewer',
    'FrameBoxTeamsViewer',
    'FrameBoxVSolution',
    'FrameBoxZoomViewer',
    'FrameBoxWhiteboardViewer'
];

/**
 * This framebox content-types don't have a control bar.
 * @type {string[]}
 */
module.exports.componentControlBarDisabled = [
    'FrameBoxMatrixControl'
];

/**
 * This framebox content-types which support pinning the control bar in fullscreen mode.
 * (Control bar is not hidden if status is 'pinned')
 *
 * @type {string[]}
 */
module.exports.contentControlBarPinSupport = [
    'whiteboard'
];

module.exports.controlBarPositions = {
    lowerBottom: {
        name: 'lowerBottom',
        value: 0
    },
    bottom: {
        name: 'bottom',
        value: 34
    },
    upperBottom: {
        name: 'upperBottom',
        value: 68
    }
};
