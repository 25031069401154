'use strict';

const app = require('../../../app');
const template = require('./groupwork-device-selection.html');
const vueUtils = require('../../util.js');

var compabilityStates = {
    ok: 'ok',
    legacyStation: 'legacy-station',
    outdatedStation: 'outdated-station',
    outdatedMaster: 'outdated-master'
};

module.exports = {
    template,

    props: {},

    data: function() {
        return {
            loading: true,
            waiting: false,
            shareRequestInProgress: false,
            numberOfOpenFrameBoxes: 0,

            devicesNotSharedYet: [
                {
                    serial: '123',
                    stationName: 'Cynap-Core 1'
                }
            ],

            selectedDevice: {
                serial: '123',
                stationName: 'Cynap-Core 1'
            },

            deviceStates: [
                {
                    serial: '123',
                    sentReqStatus: 'timeout'
                }
            ],

            statusList: []
        };
    },

    methods: {
        /**
         * Request pull from device
         * @param device view model of device
         */
        selectDevice: function(device) {
            if (this.shareRequestInProgress) {
                return;
            }

            this.shareRequestInProgress = true;
            this.selectedDevice = device;

            this.pollHelper.sync(() => {
                return this.wolfprot.talk('setMatrixGroupworkRequestPull', {
                    request: true,
                    serial: device.serial
                });
            })
                .then(() => {
                    this.waiting = true;
                }).finally(() => {
                    this.shareRequestInProgress = false;
                });
        },

        back: function() {
            if (this.waitStatus === 'waiting' && this.selectedDevice) {
                this.pollHelper.sync(() => {
                    return this.wolfprot.talk('setMatrixGroupworkRequestPull', {
                        request: false,
                        serial: this.selectedDevice.serial
                    });
                })
                    .then(() => {
                        this.waiting = false;
                    }).finally(() => {
                        this.shareRequestInProgress = false;
                    });
            }

            if (this.requestUnlucky) {
                this.waiting = false;
            } else {
                this.$emit('done');
            }
        },

        close: function() {
            this.$emit('close');
        },

        i18n: vueUtils.i18n
    },

    computed: {
        waitStatus: function() {
            const deviceWaitingFor = this.deviceStates.find(function(devState) {
                return devState.serial === this.selectedDevice.serial;
            }.bind(this));

            if (!deviceWaitingFor) {
                return 'error';
            }

            return deviceWaitingFor.sentReqStatus;
        },

        requestUnlucky: function() {
            return this.waiting
                && (this.waitStatus === 'rejected' || this.waitStatus === 'timeout' || this.waitStatus === 'error');
        },

        maxNumberOfFrameboxesOpen: function() {
            return this.numberOfOpenFrameBoxes >= 4;
        },

        sharingDevices: function() {
            return this.statusList.filter(d => d.pull === 1);
        },

        receivingDevices: function() {
            return this.statusList.filter(d => d.push === 1);
        },

        streamingActive: function() {
            return this.sharingDevices.length > 0 || this.receivingDevices.length > 0;
        },

        devicesNotSharedYetOrdered: function() {
            return this.devicesNotSharedYet.sort((a, b) => a.stationName.localeCompare(b.stationName));
        }
    },

    created: function() {
        this.frameBoxService = app.getService('FrameBoxService');

        this.wolfprot = vueUtils.wolfprot();
        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return Promise.all([
                    this.frameBoxService.getNumberOpenFrameboxes(),
                    this.wolfprot.talk('getMatrixGroupworkStationsStatus')
                ]).then(function(result) {
                    const numberOfOpenFrameboxes = result[0];
                    const matrixGroupworkStationStatus = result[1];

                    return {
                        numberOfOpenFrameboxes,
                        matrixGroupworkStationStatus
                    };
                }.bind(this));
            }.bind(this)
        });
        this.jumpTimeoutHandle = null;

        this.pollHelper.on('data', function({
            numberOfOpenFrameboxes,
            matrixGroupworkStationStatus
        }) {
            this.numberOfOpenFrameBoxes = numberOfOpenFrameboxes;
            this.statusList = matrixGroupworkStationStatus.statusList;

            const compatibleDevicesPresent = this.statusList.filter(function(device) {
                if (device.present === 0 || device.mode === 0) {
                    return false;
                }

                if (device.compatibility === compabilityStates.legacyStation
                    || device.compatibility === compabilityStates.outdatedStation) {
                    return false;
                }

                return true;
            });

            this.devicesNotSharedYet = compatibleDevicesPresent.filter(function(device) {
                return device.push !== 1;
            });
            this.deviceStates = compatibleDevicesPresent;

            if (!this.jumpTimeoutHandle && this.waiting && this.waitStatus === 'accepted') {
                this.jumpTimeoutHandle = setTimeout(() => {
                    this.$emit('done');
                }, 2000);
            }

            this.loading = false;
        }.bind(this));
        this.pollHelper.schedulePoll();

        this.evctx = vueUtils.eventContext();
        this.evctx.on('main-loop.update', function() {
            this.pollHelper.schedulePoll();
        }.bind(this));
    },

    destroyed: function() {
        this.evctx.close();
    },

    components: {
        'tile-v3': require('../../tile-v3/tile-v3.js')
    }
};
