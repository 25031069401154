'use strict';

var app = require('../../../../app');
var platform = require('../../../../../platform/platform');

var controlItems = [
    {
        id: 'zoom-out',
        state: 'zoom-out',
        states: {
            'zoom-out': {
                icon: 'icon-v2-zoom-out',
                titleKey: ''
            }
        }
    },
    {
        id: 'zoom-in',
        state: 'zoom-in',
        states: {
            'zoom-in': {
                icon: 'icon-v2-zoom-in',
                titleKey: ''
            }
        },
        groupEnd: true
    },
    {
        id: 'backward',
        state: 'backward',
        states: {
            'backward': {
                icon: 'icon-v2-arrow-left',
                titleKey: ''
            }
        }
    },
    {
        id: 'forward',
        state: 'forward',
        states: {
            'forward': {
                icon: 'icon-v2-arrow-right',
                titleKey: ''
            }
        },
        groupEnd: true
    },
    {
        id: 'reload',
        state: 'reload',
        states: {
            'reload': {
                icon: 'icon-v2-reload',
                titleKey: ''
            }
        }
    }
];

app.component('FrameBoxOffice365Viewer', {
    extend: 'FrameBoxBaseType',
    className: 'framebox-view framebox-browser-view',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.clickTimeout = null;
        this.url = null;
        this.frameBoxService = this.getService('FrameBoxService');
        this.liveStream = this.getService('LiveStreamService');
        this.storage = this.getService('DataStorage');
        this.remote = this.getService('RemoteService');
        this.webconferenceService = this.getService('WebconferenceService');
        this.embeddedPdf = false;
        this.componentId = null;
        this.options.allowedEvents = ['touchstart', 'touchend', 'mousedown', 'mouseup', 'touchmove', 'mousemove'];
    },

    serialize: function() {
        return {
            isCbox: platform.checks.isCbox
        };
    },

    postPlaceAt: function() {
        this.startControls();
        this.bindEvents();

        this.startTouchEventsEntity({
            $actionEl: this.options.$actionEl,
            allowedEvents: this.options.allowedEvents,
            disableSelection: false,
            debugging: false
        });

        this.onFrameboxChanged({
            contentType: this.frameBoxService.contentTypeMapping(this.options.type),
            options: this.options.frameBoxOptions
        });

        if (window.navigator.standalone) {
            this.options.$actionEl.addClass('hide-keyboard-btn');
        }
    },

    bindEvents: function() {
        this.on('framebox.change.' + this.index, this.onFrameboxChanged.bind(this));

        this.on('framebox.standard.keydown', this.keyDownHandler.bind(this));
        this.on('framebox.standard.keyup', this.keyUpHandler.bind(this));

        this.on('framebox.standard.pinchin', this.controlHandlers['zoom-out'].bind(this));
        this.on('framebox.standard.pinchout', this.controlHandlers['zoom-in'].bind(this));
        this.on('framebox.standard.pandown', this.controlHandlers['scroll-up'].bind(this));
        this.on('framebox.standard.panup', this.controlHandlers['scroll-down'].bind(this));
        this.on('framebox.standard.panleft', this.controlHandlers['scroll-right'].bind(this));
        this.on('framebox.standard.panright', this.controlHandlers['scroll-left'].bind(this));

        // Bind Remote-control events.
        this.on('framebox.standard.zoom-in.keydown', this.controlHandlers['zoom-in'].bind(this));
        this.on('framebox.standard.zoom-out.keydown', this.controlHandlers['zoom-out'].bind(this));

        // Bind Mouse-Events.
        this.on('framebox.standard.mousewheel-up', this.controlHandlers['scroll-up'].bind(this));
        this.on('framebox.standard.mousewheel-down', this.controlHandlers['scroll-down'].bind(this));

        this.on('active-framebox.changed', this.activeFrameboxChanged.bind(this));
    },

    /**
     * Initialize Keyboard after active framebox changed
     *
     * @param index framebox index
     * @param keyboardAction focus is on an input field --> update keyboard
     */
    activeFrameboxChanged: function(index, component, keyboardAction) {
        if (index === this.options.index) {
            if (!this.keyboard) {
                this.startKeyboard();

                this.onFrameboxChanged({
                    contentType: this.frameBoxService.contentTypeMapping(this.options.type),
                    options: this.options.frameBoxOptions
                });

                return;
            }

            if (keyboardAction) {
                this.keyboardAction('update', this.box);
            }
        }
    },

    /**
     * @param {Object} box
     */
    onFrameboxChanged: function(box) {
        this.box = box;

        if (this.url !== this.box.options.url) {
            this.url = this.box.options.url;
        }

        clearTimeout(this.clickTimeout);

        if (this.keyboardAction) {
            this.keyboardAction('update', box);
        }
    },

    keyDownHandler: function(event, index) {
        this.keyEventHandler(index, 'keydown', event);
    },

    keyUpHandler: function(event, index) {
        this.keyEventHandler(index, 'keyup', event);
    },

    /**
     * @param {Number} keyCode
     * @param {Number} index
     * @param {String} keyEvent
     * @param {Object} event
     */
    keyEventHandler: function(index, keyEvent, event) {
        if (index === this.index) {
            this.frameBoxService.sendKeyEvent(this.options.index, keyEvent, event);
        }
    },

    /**
     * Checks the keyboard state.
     */
    canOpenKeyboard: function() {
        if (this.box.options.requestKeyboard) {
            return platform.checks.isTouchDevice || (platform.checks.isCbox && this.remote.isTouchKeyboardEnabled());
        }

        return false;
    },

    startControls: function() {
        this.componentId = this.createComponent({
            type: 'FrameBoxControls',
            container: this.$el,
            index: this.options.index,
            appId: this.options.appId,
            items: controlItems,
            onClick: this.onControlClickHandler.bind(this),
            isFullscreen: this.options.isFullscreen
        });
    },

    /**
     * @param {String} id
     */
    onControlClickHandler: function(id) {
        var handler = this.controlHandlers[id];

        if (this.keyboardAction) {
            this.keyboardAction('close');
        }

        if (handler) {
            handler.call(this, {
                index: this.index
            });
        }
    },

    /**
     * Start Keyboard Component.
     */
    startKeyboard: function() {
        this.keyboard = this.createComponent({
            type: 'FrameboxKeyboard',
            container: this.$el,
            index: this.options.index,
            allTouchDevices: true,
            avoidOpen: true,
            action: function(fn) {
                this.keyboardAction = fn;
            }.bind(this)
        });
    },

    controlHandlers: {
        'zoom-out': function(options) {
            this.sendCmd('zoomOut', options.index);
        },

        'zoom-in': function(options) {
            this.sendCmd('zoomIn', options.index);
        },

        'scroll-up': function(options) {
            this.sendCmd('scrollUp', options.index);
        },

        'scroll-down': function(options) {
            this.sendCmd('scrollDown', options.index);
        },

        'scroll-left': function(options) {
            this.sendCmd('scrollLeft', options.index);
        },

        'scroll-right': function(options) {
            this.sendCmd('scrollRight', options.index);
        },

        'forward': function(options) {
            this.sendCmd('forward', options.index);
        },

        'backward': function(options) {
            this.sendCmd('back', options.index);
        },

        'reload': function(options) {
            this.sendCmd('reload', options.index);
        }
    },

    /**
     * @param {String} cmd
     * @param {Number} index
     */
    sendCmd: function(cmd, index) {
        if (this.index === index) {
            this.send('setOffice365Control', {
                appId: this.options.appId,
                cmd: cmd
            });
        }
    },

    destroy: function() {
        if (this.keyboardAction) {
            this.keyboardAction('close');
        }

        this.$('html, body').scrollTop(0);
        this.$(document).off('mousedown.keyboard-' + this.index);
        this.$(document).off('touchstart.keyboard-' + this.index);
    }
});
