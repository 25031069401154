'use strict';

var $ = require('jquery');
var _ = require('lodash');
var app = require('../../app');
var states = require('../../states');
var template = require('./form-action.hbs');
var StateMachine = require('../../state-machine');

function FormActionView(app, options) {
    /**
     * @type {Object}
     */
    this.options = _.defaults(options, {
        id: Math.floor(Math.random() * 1000) + 100,
        autoClose: true,
        navArea: true
    });

    this.authService = app.getService('AuthenticationService');

    /**
     * @type {jQuery}
     */
    this.$containerEl = $(options.selector);

    /**
     * @type {string}
     */
    this.state = this.createStateMachine({
        state: states.actionViewStates.removed,
        states: states.actionViewStates
    });

    /**
     * @type {method}
     */
    this.onSubmit = options.onSubmit || $.noop;

    /**
     * @type {method}
     */
    this.onCancel = options.onCancel || $.noop;

    /**
     * App reference
     * @type {Object}
     */
    this.app = app;

    /**
     * Block enable submit or cancel button.
     *
     * @type {boolean}
     */
    this.block = false;

    /**
     * @type {*|jQuery|HTMLElement}
     */
    this.$el = $(template({
        cancelId: 'form-action-cancel' + this.options.id,
        submitId: 'form-action-submit' + this.options.id,
        submitTitleKey: options.submitTitleKey || 'settings.save',
        cancelTitleKey: options.cancelTitleKey || 'settings.cancel',
        navArea: this.options.navArea
    }));
}

/**
 * Render the view
 * @method render
 */
FormActionView.prototype.render = function render() {
    if (this.state.getState() === states.actionViewStates.removed) {
        this.$containerEl.html(this.$el);
        this.state.changeState(states.actionViewStates.hidden);
        this.bindDOMEvents();
        this.bindEvents();
        this.storeSelectors();
        this.onDiskEncryptChanged();
    }
};

/**
 * @method storeSelectors
 */
FormActionView.prototype.storeSelectors = function storeSelectors() {
    this.$submitBtn = this.$el.find('.form-action-submit');
    this.$cancelBtn = this.$el.find('.form-action-cancel');
};

/**
 * Destroys the view
 * @method destroy
 */
FormActionView.prototype.destroy = function destroy() {
    this.$el.off();
    this.$el.remove();
    this.state.changeState(states.actionViewStates.removed);
};

/**
 * Bind the events on the form buttons
 * @method bindDomEvents
 */
FormActionView.prototype.bindDOMEvents = function() {
    this.$el.on('click', '.form-action-cancel', this.onCancelHandler.bind(this));
    this.$el.on('click', '.form-action-submit', this.onSubmitHandler.bind(this));
};

/**
 * Bind the events on the form buttons
 */
FormActionView.prototype.bindEvents = function() {
    app.on('disk-encryption.status.changed', this.onDiskEncryptChanged.bind(this));
};

/**
 * Check disk encryption Status.
 */
FormActionView.prototype.onDiskEncryptChanged = function() {
    if (!this.authService.getIsAdmin()) {
        return;
    }

    if (!this.securityService) {
        this.securityService = app.getService('SecurityService');
    }

    if (this.securityService.getStatus() === states.encryptionStates.inProgress) {
        this.block = true;
        this.disableCancelButton();
        this.disableSubmitButton();
    } else if (this.block) {
        this.enableCancelButton();
        this.enableSubmitButton();
    }
};

/**
 * Show buttons.
 */
FormActionView.prototype.open = function open() {
    if (this.state.getState() === states.actionViewStates.hidden) {
        this.animateOpenAction();
        this.state.changeState(states.actionViewStates.visible);
        this.onDiskEncryptChanged();
    }
};

/**
 * Hide all buttons.
 */
FormActionView.prototype.close = function close() {
    if (this.state.getState() === states.actionViewStates.visible) {
        this.animateCloseAction();
    }
};

/**
 * @param {jQuery.Event} event
 */
FormActionView.prototype.onCancelHandler = function(event) {
    this.onCancel(event);

    if (this.options.autoClose) {
        this.close();
    }

    event.preventDefault();
};

/**
 * @method onSubmitHandler
 * @param event
 */
FormActionView.prototype.onSubmitHandler = function(event) {
    this.onSubmit(event);

    if (this.options.autoClose) {
        this.close();
    }

    event.preventDefault();
};

/**
 * @method animateOpenAction
 */
FormActionView.prototype.animateOpenAction = function() {
    this.onDiskEncryptChanged();

    this.$el
        .velocity('stop')
        .velocity('slideDown', 100);
};

/**
 * Slide Up container with all buttons.
 */
FormActionView.prototype.animateCloseAction = function() {
    this.$el
        .velocity('stop')
        .velocity('slideUp', {
            duration: 100,
            complete: function() {
                this.state.changeState(states.actionViewStates.hidden);
            }.bind(this)
        });
};

/**
 * @method enableSubmitButton
 */
FormActionView.prototype.enableSubmitButton = function() {
    if (this.block) {
        this.disableSubmitButton();

        return;
    }

    if (!this.$submitBtn.hasClass('fake-disable')) {
        this.$submitBtn
            .removeAttr('disabled')
            .removeAttr('data-nav-limit');
    }
};

/**
 * @method disableSubmitButton
 */
FormActionView.prototype.disableSubmitButton = function() {
    this.$submitBtn
        .removeClass('fake-disable')
        .attr('disabled', 'disabled');
};

/**
 * @method enableCancelButton
 */
FormActionView.prototype.enableCancelButton = function() {
    if (this.block) {
        this.disableCancelButton();

        return;
    }

    if (!this.$cancelBtn.hasClass('fake-disable')) {
        this.$cancelBtn
            .removeAttr('disabled')
            .removeAttr('data-nav-limit');
    }
};

/**
 * @method disableCancelButton
 */
FormActionView.prototype.disableCancelButton = function() {
    this.$cancelBtn
        .removeClass('fake-disable')
        .attr('disabled', 'disabled');
};

/**
 * Returns a new instance of the statemachine
 * @method getState
 * @param {Object} options
 */
FormActionView.prototype.createStateMachine = function createStateMachine(options) {
    options.context = this;

    return new StateMachine(options);
};

module.exports = FormActionView;
