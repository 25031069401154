'use strict';

var _ = require('lodash');
var $ = require('jquery');
var app = require('../../../app');
var routingTpl = require('./routing-settings.hbs');
var routingListItemTpl = require('./routing-item.hbs');
var FormActionView = require('../form-action-view');

var MAX_ROUTING_ENTRIES = 10;

app.component('RoutingSettings', {
    template: routingTpl,
    init: false,

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.deviceService = app.getService('DeviceService');
        this.form = [];
        this.actionView = [];
        this.routingEntries = [];
    },

    storeSelectors: function() {
        this.$routingList = this.$el.find('#routing-list-items');
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();
        this.updateCommands();
    },

    /**
     * Load all ip routing items.
     */
    updateCommands: function() {
        return this.deviceConnection
            .send(this.getAllEntries())
            .then(this.onDataLoaded.bind(this));
    },

    /**
     * Create routing entries array.
     */
    onDataLoaded: function() {
        this.routingEntries = Array.from(arguments);

        this.renderRoutingList();
    },

    /**
     * Create routing list from template.
     */
    renderRoutingList: function() {
        this.routingEntries.forEach(function(item) {
            var formData = [];
            var $item = this.$(routingListItemTpl(item));

            this.createSelects($item);

            formData = {
                routingEnabled: item.enabled,
                routingIp: item.routingIp,
                prefix: item.prefix,
                gatewayEnabled: item.gatewayEnabled,
                gateway: item.gateway,
                device: item.device
            };

            // Update select id's to be unique.
            $item.find('#device').prop('id', 'device-' + item.index);
            $item.find('#prefix').prop('id', 'prefix-' + item.index);

            this.$routingList.append($item);

            this.initForm(item.index);
            this.updateForm(formData, item.index);
            this.initFormAction($item, item.index);
            this.updateContentVisibility(item.index, item.enabled);
            this.updateGatewayInput(item.index, item.gatewayEnabled);
        }.bind(this));
    },

    bindEvents: function() {
        this.on('settings.save-changes', this.saveAllHandler.bind(this));
    },

    initFormAction: function($item, index) {
        if (!this.actionView[index]) {
            this.actionView[index] = new FormActionView(app, {
                id: index,
                selector: $item.find('.js-form-action-container'),
                onSubmit: this.saveHandler.bind(this, index),
                onCancel: this.cancelHandler.bind(this, index)
            });
        }
        $item.find('.reboot-info').hide();
        this.actionView[index].render();
    },

    initForm: function(index) {
        this.form[index] = this.formManager.create({
            el: this.$el.find('.routing-settings[data-index="' + index + '"] .routing-item-form'),
            validationContainer: '.input-group'
        });
        this.form[index].on('change.input', this.handleFormChange.bind(this));
    },

    /**
     * Returns a list with all routing entries commands.
     */
    getAllEntries: function() {
        var routingList = [];

        // Generate routing table
        for (var i = 0; i < MAX_ROUTING_ENTRIES; i++) {
            routingList[i] = {
                command: 'getRoutingEntry',
                data: {
                    index: i
                }
            };
        }

        return routingList;
    },

    /**
     * Save all changed network drives
     */
    saveAllHandler: function() {
        _.each(this.actionView, function(view, index) {
            if (view.state.getState() === 'visible') {
                this.saveHandler(index);
            }
        }.bind(this));
    },

    saveHandler: function(index) {
        if (this.form[index].validate()) {
            var data = this.form[index].serialize();

            this.deviceConnection
                .send([
                    {
                        command: 'setRoutingEntry',
                        data: {
                            index: index,
                            enabled: data.routingEnabled,
                            routingIp: data.routingIp,
                            prefix: data.prefix,
                            gatewayEnabled: data.gatewayEnabled,
                            gateway: data.gateway,
                            device: data.device
                        }
                    }
                ])
                .done(function() {
                    this.handleSettingsSave(index);
                    this.updateContentVisibility(index, data.routingEnabled);
                    this.updateGatewayInput(index, data.gatewayEnabled);
                    this.emit('overlay.remote.focus', true);
                }.bind(this));
        }
    },

    /**
     * Calls on clicked the cancel button.
     *
     * @param index
     */
    cancelHandler: function(index) {
        this.emit('overlay.remote.focus', true);

        this.form[index].resetValues();
        this.$el.find('.routing-settings[data-index="' + index + '"] .reboot-info').hide();

        this.updateContentVisibility(index, this.form[index].get('routingEnabled').getValue());
        this.updateGatewayInput(index, this.form[index].get('gatewayEnabled').getValue());

        this.changes = false;
    },

    handleSettingsSave: function(index) {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.form[index].setDefaultValues();
        this.changes = false;

        this.$el.find('.routing-settings[data-index="' + index + '"] .reboot-info').hide();
    },

    handleFormChange: function(data) {
        var index = data.input.form.$el.data('index');

        if (data.input.name === 'gatewayEnabled') {
            this.updateGatewayInput(index, data.input.value);
        } else if (data.input.name === 'routingEnabled') {
            this.updateContentVisibility(index, data.input.value);
            this.updateGatewayInput(index, this.form[index].get('gatewayEnabled').getValue());
        }

        if (this.actionView[index]) {
            this.$el.find('.routing-settings[data-index="' + index + '"] .reboot-info').show();
            this.actionView[index].open();
            this.checkActionForm(index);
            this.changes = true;
        }

        this.checkActionForm(index);
    },

    checkActionForm: function(index) {
        if (!this.actionView[index]) {
            return;
        }

        if (this.form[index].validate()) {
            this.actionView[index].enableSubmitButton();
        } else {
            this.actionView[index].disableSubmitButton();
        }
    },

    updateForm: function(formData, index) {
        this.form[index].setValues(formData);
    },

    createSelects: function($item) {
        var items = [
            {
                text: 'settings.lan1',
                value: 'lan1'
            },
            {
                text: 'settings.lan2',
                value: 'lan2'
            },
            {
                text: 'settings.wlan',
                value: 'wlan'
            }
        ];

        if (this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) {
            items.splice(1, 1);
            items[0].text = 'settings.lan';
        }

        this.createComponent({
            type: 'CustomSelect',
            container: $item.find('.device-select'),
            label: 'settings.lan_interface',
            native: true,
            name: 'device',
            items: items
        });

        items = [];
        for (var i = 1; i <= 32; i++) {
            items[i] = {
                text: i,
                value: i
            };
        }

        this.createComponent({
            type: 'CustomSelect',
            container: $item.find('.prefix-select'),
            label: 'settings.prefix',
            native: true,
            name: 'prefix',
            items: items
        });
    },

    /**
     * Show/Hide routing item content.
     *
     * @param index - routing item index
     * @param visible - true/false
     */
    updateContentVisibility: function(index, visible) {
        if (visible) {
            this.$el.find('#routing-content-' + index).show();
        } else {
            this.$el.find('#routing-content-' + index).hide();
        }
    },

    /**
     * Enable/Disable Gateway ip.
     *
     * @param index
     * @param enabled - gateway ip enable
     */
    updateGatewayInput: function(index, enabled) {
        this.$el.find('#gateway-' + index).prop('disabled', !enabled);
    },

    destroy: function() {
        _.each(this.form, function(item) {
            if (item) {
                var index = item.$el.data('index');

                this.formManager.destroy(item);

                if (this.actionView[index]) {
                    this.actionView[index].destroy();
                }
            }
        }.bind(this));
    },

    hasChanges: function() {
        var changes = {
            hasChanges: this.changes,
            invalid: false
        };

        if (this.actionView) {
            changes.invalid = $(this.actionView.$submitBtn).prop('disabled');
        }

        return changes;
    }
});
