'use strict';

const app = require('../../../app');
const loginTpl = require('./generic-login.html');
const vueUtils = require('../../../components-vue/util');
const formManager = require('./../../../../../modules/form/form-manager.js');

app.component('GenericLogin', {
    engine: 'vue',
    template: loginTpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    data: function() {
        return {
            title: 'Login',
            icon: '',
            requiresEmail: false,
            username: '',
            password: '',
            valid: false
        };
    },

    created: function() {
        this.title = this.component.options.configs.title;
        this.icon = this.component.options.configs.icon;
        this.requiresEmail = this.component.options.configs.requiresEmail;

        this.formManagerService = app.getService('FormManager');
        this.keyboard = app.getService('KeyboardService');
    },

    mounted: function() {
        formManager.update();

        this.form = this.formManagerService.create({
            el: this.$refs['generic-login-form'],
            validationContainer: '.input-group',
            submit: true
        });

        this.form.on('change.input', this.handleFormChange);
    },

    methods: {
        login: function() {
            if (typeof this.component.options.configs.onLogin === 'function') {
                this.component.options.configs.onLogin(this.username, this.password);
            }

            this.close();
        },

        cancel: function() {
            if (typeof this.component.options.configs.onCancel === 'function') {
                this.component.options.configs.onCancel();
            }

            this.close();
        },

        handleFormChange(event) {
            this.valid = this.form.validate();
            switch (event.input.name) {
                case 'username':
                    this.username = event.value;
                    break;
                case 'password':
                    this.password = event.value;
                    break;
            }
        },

        close: function() {
            this.component.emit('modal.close', { id: 'genericLogin' });
            this.keyboard.close();
        },

        i18n: vueUtils.i18n
    }
});
