'use strict';

var app = require('../app');
var StateMachine = require('./../state-machine');

var pipStates = {
    enabled: 1,
    disabled: 0,
    none: -1
};

var pipContents = {
    main: 'main',
    camera: 'camera'
};

app.service('PipService', function(app) {
    return {
        initialize: function() {
            this.pipFunction = new StateMachine({
                context: this,
                state: pipStates.none,
                states: pipStates
            });

            this.pipContent = new StateMachine({
                context: this,
                state: pipContents.camera,
                states: pipContents
            });

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.bindEvents();
                    this.updateHandler();
                }.bind(this));
        },

        bindEvents: function() {
            app.on('main-loop.update', this.updateHandler.bind(this));
            app.on('main-loop.update.record', this.updateHandler.bind(this));
        },

        updateHandler: function() {
            if (app.getService('DeviceService').isCboxProDualProjection()) {
                // In dual-projection mode there is no pip
                return;
            }

            this.deviceConnection
                .send([
                    'getPipFunction',
                    'getPipContent'
                ])
                .then(function(func, content) {
                    if (this.pipFunction.getState() !== func.enable) {
                        this.pipFunction.changeState(func.enable);
                    }

                    if (this.pipContent.getState() !== content.content) {
                        this.pipContent.changeState(content.content);
                    }
                }.bind(this));
        },

        /**
         * Is PIP enabled.
         */
        isEnabled: function() {
            return (this.pipFunction.getState() === pipStates.enabled);
        },

        /**
         * Get PIP content (main or camera).
         */
        getContent: function() {
            return this.pipContent.getState();
        },

        /**
         * Hide/Show PIP status bar widget.
         */
        updatePipStatusWidget: function() {
            if (app.getService('DeviceService').isCboxProDualProjection()) {
                // In dual-projection mode there is no pip widget
                return;
            }

            this.deviceConnection
                .send('getPipFunction')
                .then(function(pip) {
                    if (pip.enable === pipStates.enabled
                        && (app.getService('RecordService').state.currentState !== 'stop'
                            || app.getService('Streaming').state.currentState !== 'off')) {
                        app.emit('status-widget.item.append', {
                            id: 'pip-widget',
                            accessKey: 'Pip',
                            options: {
                                icon: 'icon-v2-pip',
                                clickable: false
                            }
                        });

                        return;
                    }

                    app.emit('status-widget.item.remove', {
                        id: 'pip-widget'
                    });
                }.bind(this));
        }
    };
});
