'use strict';

var _ = require('lodash');
var app = require('../../app');
var sprintf = require('./../../helper').sprintf;
var i18n = require('i18next');
var meetingAdHocTpl = require('./meeting-ad-hoc.hbs');
var platform = require('./../../../platform/platform');

app.component('AdHoc', {
    template: meetingAdHocTpl,

    getAccessKey: function() {
        return {
            'roleName': 'vMeeting',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.keyboard = this.getService('KeyboardService');
        this.sessionMgmtService = this.getService('SessionManagementService');
    },

    serialize: function() {
        return {
            timeframes: this.sessionMgmtService.timeframes
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.initForm();
        this.bindEvents();
        this.bindDOMEvents();

        this.open(true);
    },

    storeSelectors: function() {
        this.$container = this.$el.find('#meeting-ad-hoc-container');
        this.$adHocTimeframeInputContainer = this.$el.find('#ad-hoc-container-timeframe-input');
        this.$hoursInput = this.$el.find('#timeframe-input-hour');
        this.$minutesInput = this.$el.find('#timeframe-input-min');
        this.$adHocTimeframesContainer = this.$el.find('#ad-hoc-container-timeframes');
        this.$inputContainer = this.$el.find('.session-management-input');
        this.$timeframesContainer = this.$el.find('#ad-hoc-timeframes-container');
        this.$errorMsg = this.$el.find('#ad-hoc-error');
        this.$loader = this.$el.find('#ad-hoc-loader');
        this.$checkInBtn = this.$el.find('#session-management-check-in-btn');
        this.$timeInfo = this.$el.find('#ad-hoc-time-info');
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#meeting-ad-hoc-form')
        });
    },

    bindEvents: function() {
        this.on('session-mgmt.meeting-ad-hoc.open', _.debounce(this.open.bind(this), 300, true));
        this.on('session-mgmt.close', this.close.bind(this));
        this.on('main-loop.update', this.updateTime.bind(this));
        this.on('session-mgmt.update-timeframes', this.updateTimeframeButtons.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.clickable:not(.is-disabled)', this.onAction.bind(this));
        this.$inputContainer.on('click', this.onInputFieldClick.bind(this));
        this.$el.find('.session-management-timeframe-input .input').on('submit', this.startAdHocSession.bind(this));
        this.$hoursInput.on('input', this.restrictHoursInput.bind(this));
        this.$hoursInput.on('change', this.restrictHoursInput.bind(this));
        this.$minutesInput.on('input', this.restrictMinutesInput.bind(this));
        this.$minutesInput.on('change', this.restrictMinutesInput.bind(this));
    },

    /**
     * Open Ad-Hoc overlay.
     *
     * @param animate true/false
     */
    open: function(animate) {
        this.resetAdHoc();

        if (animate) {
            this.$container
                .stop()
                .slideDown(200, this.focusRemoteArea.bind(this))
                .removeClass('hidden');
        } else {
            this.$container.removeClass('hidden');
            this.focusRemoteArea();
        }
    },

    /**
     * Focus area.
     */
    focusRemoteArea: function() {
        if (!platform.checks.isCbox) {
            return;
        }

        this.remote.focusArea(this.$container, {
            area: '.is-focusable, .clickable:not(.hidden)',
            trapped: true
        });
    },

    /**
     * Close Ad-Hoc overlay.
     *
     * @param slideUp true/false (else: slideDown)
     */
    close: function(slideUp) {
        this.keyboard.close();

        if (slideUp) {
            this.$container
                .animate({
                    top: '-' + this.$container.height() + 'px'
                },
                {
                    duration: 200,
                    complete: function() {
                        this.$container
                            .addClass('hidden')
                            .css('top', 'unset');

                        this.remote.destroyArea(this.$container);
                        this.emit('session-mgmt.meeting-ad-hoc.closed');
                    }.bind(this)
                });
        } else {
            this.$container
                .stop()
                .slideUp(200, function() {
                    this.remote.destroyArea(this.$container);
                    this.emit('session-mgmt.meeting-ad-hoc.closed');
                }.bind(this))
                .addClass('hidden');
        }
    },

    /**
     * Handle actions on buttons.
     *
     * @param event
     */
    onAction: function(event) {
        var $el = this.$(event.currentTarget);
        var action = $el.data('action');

        this.$checkInBtn.addClass('hidden');
        this.$timeframesContainer.find('.session-management-btn').removeClass('highlighted');

        switch (action) {
            case 'choose-timeframe':
                this.duration = $el.data('minutes');

                $el.addClass('highlighted');
                this.$checkInBtn.removeClass('hidden');
                this.remote.focus(this.$checkInBtn);
                break;
            case 'other-timeframe':
                this.duration = 0;

                this.$adHocTimeframesContainer.addClass('hidden');
                this.$adHocTimeframeInputContainer.removeClass('hidden');
                break;
            case 'check-in':
                this.startAdHocSession();
                break;
            case 'close':
                this.close();
                break;
        }
    },

    /**
     * Empty timeframe input field if there was an error before.
     */
    onInputFieldClick: function() {
        if (this.hasError) {
            this.resetTimeframeInput();
        }
    },

    /**
     * Reset Ad-Hoc. Hide error msg and empty timeframe field.
     */
    resetAdHoc: function() {
        this.duration = 0;
        this.$timeframesContainer.find('.session-management-btn').removeClass('highlighted');
        this.$checkInBtn.addClass('hidden');

        this.updateTime();
        this.updateTimeframeButtons(this.sessionMgmtService.timeframes);
        this.resetTimeframeInput();

        this.$loader.hide();
        this.$adHocTimeframesContainer.removeClass('hidden');
        this.$adHocTimeframeInputContainer.addClass('hidden');
        this.$el.find('.check-in-btn').removeClass('is-disabled');
    },

    /**
     * Reset input field of timeframe.
     */
    resetTimeframeInput: function() {
        this.form.get('timeframeInputHour').setValue('');
        this.form.get('timeframeInputMin').setValue('');
        this.hideErrorMsg();
    },

    /**
     * Transform a string to a two digits string.
     */
    toTwoDigitsNumeric: function(val) {
        return val.replace(/[^0-9]/g, '').substring(0, 2);
    },

    /**
     * Restrict hours input field to two digits numeric [00-99] value only.
     */
    restrictHoursInput: function(e) {
        e.target.value = this.toTwoDigitsNumeric(e.target.value);
    },

    /**
     * Restrict input field to two digits minutes [00-59] value only.
     */
    restrictMinutesInput: function(e) {
        const number = this.toTwoDigitsNumeric(e.target.value);
        e.target.value = parseInt(number) > 59 ? '59' : number;
    },

    /**
     * Start Ad-Hoc session.
     */
    startAdHocSession: function() {
        this.$checkInBtn.addClass('is-disabled');

        if (!this.duration && !this.getDurationFromInput()) {
            this.showErrorMsg();

            return;
        }

        if (this.duration && this.duration > this.sessionMgmtService.unbookedDuration || this.duration > 60000) { // Wolfprot limitation of 65535.
            this.showErrorMsg();

            return;
        }

        this.$loader.show();

        this.deviceConnection
            .send('setVMeetingStartAdHocSession', {
                duration: this.duration
            }).then(function() {
                // Session Management overlay is closed when we get the active session ID.
                this.$checkInBtn.removeClass('is-disabled');
            }.bind(this),
            function() {
                this.showErrorMsg();
            }.bind(this));
    },

    /**
     * Get duration from input field (in minutes).
     *
     * @returns {number}
     */
    getDurationFromInput: function() {
        var hour = this.form.get('timeframeInputHour').getValue() === ''
            ? 0 : parseInt(this.form.get('timeframeInputHour').getValue());
        var min = this.form.get('timeframeInputMin').getValue() === ''
            ? 0 : parseInt(this.form.get('timeframeInputMin').getValue());

        this.duration = !this.form.validate() ? 0 : (min + (hour * 60));

        return this.duration;
    },

    /**
     * Show error message.
     */
    showErrorMsg: function() {
        var errorText;
        var hours = this.sessionMgmtService.unbookedDurationTime.h;
        var minutes = this.sessionMgmtService.unbookedDurationTime.m;

        if (!this.form.validate() || this.duration === 0 || this.duration > 60000) {
            errorText = i18n.t('session_management.error_timeframe_invalid');
        } else {
            errorText = sprintf(i18n.t('session_management.error_timeframe'), [hours, minutes]);
        }

        this.$inputContainer.addClass('has-error');
        this.$loader.hide();
        this.$errorMsg
            .html(errorText)
            .show();

        this.duration = 0;
        this.hasError = true;
    },

    /**
     * Hide error message.
     */
    hideErrorMsg: function() {
        this.$inputContainer.removeClass('has-error');
        this.$errorMsg
            .text('')
            .hide();
        this.hasError = false;
    },

    /**
     * Update remaining time until next meeting.
     */
    updateTime: function() {
        var meetingList = this.sessionMgmtService.getMeetings() || [];

        if (meetingList.length === 0
            || !this.sessionMgmtService.startsToday(meetingList[0].start)
            || this.sessionMgmtService.unbookedDurationTime.h > 11
        ) {
            this.$timeInfo.html('');

            return;
        }

        var hours = this.sessionMgmtService.unbookedDurationTime.h;
        var minutes = this.sessionMgmtService.unbookedDurationTime.m;

        this.$timeInfo.html(sprintf(i18n.t('session_management.until_next_meeting'), [hours, minutes]));
    },

    /**
     * Update timeframe buttons if something changes (show/hide).
     *
     * @param timeframes
     */
    updateTimeframeButtons: function(timeframes) {
        var $btn;

        if (!this.sessionMgmtService.hasValidTimeframes) {
            this.close();

            return;
        }

        _.each(timeframes, function(frame) {
            $btn = this.$timeframesContainer.find('#session-management-btn-' + frame.min);

            if (frame.valid) {
                $btn.removeClass('hidden');
            } else {
                $btn.addClass('hidden');

                // If a btn is highlighted and then gets hidden..
                if (this.duration === $btn.data('minutes')) {
                    this.resetAdHoc();
                }
            }
        }.bind(this));
    }
});
