'use strict';

var app = require('../app');
var states = require('../states');
var StateMachine = require('./../state-machine');

app.service('SecurityService', function(app) {
    return {
        /**
         * @method initialize
         */
        initialize: function() {
            this.status = new StateMachine({
                context: this,
                state: states.encryptionStates.encrypted,
                states: states.encryptionStates
            });

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;

                    this.bindEvents();
                }.bind(this));
        },

        bindEvents: function() {
            app.on('main-loop.update', this.updateHandler.bind(this));
        },

        updateHandler: function() {
            this.deviceConnection
                .send('getDiskEncryptionStatus')
                .then(function(diskEncryptionStatus) {
                    var newState = states.encryptionStates[diskEncryptionStatus.status];

                    if (this.status.getState() !== newState) {
                        this.status.changeState(newState);

                        app.emit('disk-encryption.status.changed', {
                            state: this.status.getState()
                        });
                    }
                }.bind(this));
        },

        getStatus: function() {
            return this.status.getState();
        }
    };
});
