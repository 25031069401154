'use strict';

var certListItemTpl = require('./certificate-list-item.hbs');
var _ = require('lodash');
var app = require('../../../../app');
var ethernetSettingsTpl = require('./ethernet-settings-v4.hbs');
var platform = require('../../../../../platform/platform');
var FormActionView = require('../../form-action-view');
var $ = require('jquery');
var i18n = require('i18next');

var states = {
    disconnected: 'disconnected',
    connected: 'connected',
    scanning: 'scanning',
    authenticating: 'authenticating',
    failed: 'failed'
};

app.component('EthernetSettingsV4', {
    template: ethernetSettingsTpl,
    actionView: null,

    getAccessKey: function() {
        return {
            'roleName': 'EthernetSettings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.validate = this.getService('ValidateService');
        this.deviceService = this.getService('DeviceService');
        this.certificateError = false;
        this.certList = null;
        this.resetCertList = null;
        this.settingsSaved = true;

        this.connectionState = this.createStateMachine({
            state: states.scanning,
            states: states
        });

        return this.loadNetworkSettings(false);
    },

    postPlaceAt: function() {
        this.createAuthMethodSelect();
        this.createPrioritySelect();
        this.createPingComponent();
        this.storeSelectors();
        this.initForm();
        this.initFormAction();
        this.updateForm();
        this.bindDOMEvents();
        this.bindEvents();

        this.handleDhcpChange();
        this.handleAuthChange();
        this.handleCertModeChanged();
        this.renderCertificateList();
        this.resetCertList = this.certList;
        this.updateHandler();
    },

    storeSelectors: function() {
        this.$dnsEl = this.$el.find('#dns-input-container');
        this.$authEl = this.$el.find('#auth-container');
        this.$certEl = this.$el.find('.certificate-file-container');
        this.$certList = this.$el.find('#certificate-list-items');
        this.$noEntry = this.$el.find('#certificate-list-no-entry');
        this.$connectionState = this.$el.find('#lan-connection-state');
        this.$dhcpContainer = this.$el.find('.dhcp-container');
    },

    serialize: function() {
        return {
            isCbox: platform.checks.isCbox
        };
    },

    bindDOMEvents: function() {
        this.form.get('dhcp').$el.on('change', this.handleDhcpChange.bind(this));
        this.form.get('authMode').$el.on('change', this.handleAuthChange.bind(this));
        this.form.get('certMode').$el.on('change', this.handleCertModeChanged.bind(this));
        this.$el.on('click', '.activate', this.activateCertificate.bind(this));
        this.$el.on('click', '.delete', this.deleteCertificate.bind(this));
    },

    bindEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.on('remote.enter.keydown', this.checkActionForm.bind(this));
        this.on('main-loop.update', this.updateHandler.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
        $('input[type=file]').on('change', this.verifyCertificate.bind(this));
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container-v4'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this),
                navArea: false
            });
        }

        this.actionView.render();
    },

    /**
     * Handle click on activate certificate.
     *
     * @param data Current event
     */
    activateCertificate: function(data) {
        _.each(this.certList, function(item) {
            if (item.name === data.currentTarget.name) {
                item.delete = false;
                item.active = true;
            } else {
                item.active = false;
            }
        });

        this.renderCertificateList();
        this.handleFormChange();
    },

    /**
     * Handle click on delete certificate.
     *
     * @param data Current event
     */
    deleteCertificate: function(data) {
        _.each(this.certList, function(item) {
            if (item.name === data.currentTarget.name) {
                if (item.delete) {
                    item.delete = false;
                } else {
                    item.delete = true;
                }
                item.active = false;
            }
        });

        this.renderCertificateList();
        this.handleFormChange();
    },

    updateHandler: function() {
        if (!!this.form && this.form.get('dhcp').getValue()) {
            this.loadDhcpAutoSettings();
            this.deviceConnection
                .send('getEthStatus')
                .then(this.updateLanConnectionState.bind(this));
        }
    },

    updateLanConnectionState: function(data) {
        var state = states[data.ethStatus];

        if (state !== this.connectionState.getState()) {
            this.connectionState.changeState(state);
            this.$connectionState.attr('data-lan-connection-state', data.ethStatus);
        }
    },

    saveHandler: function() {
        if (this.form.validate()) {
            var data = this.form.serialize();
            this.deviceConnection
                .send([
                    {
                        command: 'setEthDhcpSetting',
                        data: { dhcpSetting: data.dhcp }
                    },
                    {
                        command: 'setEthIpAddress',
                        data: { ipAddress: data.ip }
                    },
                    {
                        command: 'setEthSubnetMask',
                        data: { subnetMask: data.subnet }
                    },
                    {
                        command: 'setEthGateway',
                        data: { gatewayAddress: data.gateway }
                    },
                    {
                        command: 'setEthDnsAddress',
                        data: { dnsAddress: data.dns }
                    },
                    {
                        command: 'setEthDnsAddress2',
                        data: { dnsAddress: data.dns2 }
                    },
                    {
                        command: 'setLanAuthMode',
                        data: { mode: data.authMode }
                    },
                    {
                        command: 'setLanAuthMethod',
                        data: { authMethod: this.form.get('auth-method-select').getValue() }
                    },
                    {
                        command: 'setLanIdentity',
                        data: { name: data.identity }
                    },
                    {
                        command: 'setLanAnonymousIdentity',
                        data: { name: data.anonymous }
                    },
                    {
                        command: 'setLanPassword',
                        data: { password: data.password }
                    },
                    {
                        command: 'setLanCertificateMode',
                        data: { mode: data.certMode }
                    },
                    {
                        command: 'setLanPriority',
                        data: { priority: this.form.get('priority').getValue() }
                    }
                ])
                .done(function() {
                    this.settingsSaved = true;
                    this.handleChanges(false);
                    this.updateCertificates();

                    this.deviceConnection
                        .send('setEthActivate')
                        .then(function() {
                            this.handleSettingsSave();
                            this.form.setDefaultValues();
                            this.emit('overlay.remote.focus', true);
                            this.loadNetworkSettings(true);
                        }.bind(this));

                    this.emit('save-changes.finished');
                }.bind(this));

            if (!platform.checks.isCbox) {
                this.saveCertificate();
                this.loadNetworkSettings(true);
            }
        }
    },

    /**
     * Verify certificate if file is uploaded.
     */
    verifyCertificate: function() {
        var file = this.form.get('certificate-file').getValue();
        var reader = new FileReader();

        if (file) {
            reader.onload = function(event) {
                this.deviceConnection
                    .send([
                        {
                            command: 'setLanCertificateFile',
                            data: {
                                name: file.name,
                                file: event.target.result,
                                action: 'verify'
                            }
                        }
                    ]).then(function() {
                        this.$certEl.removeClass('is-invalid');
                        this.$certEl.addClass('is-valid');
                        this.certificateError = false;
                        this.checkActionForm();
                    }.bind(this)).fail(function() {
                        this.$certEl.removeClass('is-valid');
                        this.$certEl.addClass('is-invalid');
                        this.actionView.disableSubmitButton();
                        this.certificateError = true;
                    }.bind(this)) ;
            }.bind(this);

            reader.readAsArrayBuffer(file);
        }
    },

    /**
     * Update certificate list (delete/activate).
     */
    updateCertificates: function() {
        var change = false;

        _.each(this.certList, function(item) {
            if (item.delete) {
                this.deviceConnection.send([
                    {
                        command: 'setLanCertificateFile',
                        data: {
                            name: item.name,
                            action: 'delete'
                        }
                    }
                ]);
                change = true;
            } else if (item.active) {
                this.deviceConnection.send([
                    {
                        command: 'setLanCertificateFileName',
                        data: { name: item.name }
                    }
                ]);
                change = true;
            }
        }.bind(this));

        if (change) {
            this.reloadCertificateList();
        }
    },

    /**
     * Save certificate and update list when save is clicked.
     */
    saveCertificate: function() {
        var file = this.form.get('certificate-file').getValue();
        var reader = new FileReader();

        if (file) {
            reader.onload = function(event) {
                this.deviceConnection
                    .send([
                        {
                            command: 'setLanCertificateFile',
                            data: {
                                name: file.name,
                                file: event.target.result,
                                action: 'save'
                            }
                        }
                    ]).then(function() {
                        this.reloadCertificateList();
                    }.bind(this));
            }.bind(this);

            reader.readAsArrayBuffer(file);

            this.form.get('certificate-file')
                .setValue(null);
        }
    },

    /**
     * Reload certificate list from Backend.
     */
    reloadCertificateList: function() {
        this.deviceConnection
            .send('getLanCertificates')
            .then(function(certificates) {
                this.certList = certificates.certList;
                this.renderCertificateList();
            }.bind(this));
    },

    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
        this.certList = this.resetCertList;
        this.renderCertificateList();
        this.settingsSaved = true;
        this.handleChanges(false);
    },

    updateForm: function() {
        this.form.setValues(this.formData);

        if (this.form.get('dhcp').getValue()) {
            this.disableSettings();
        }
    },

    handleFormChange: function() {
        this.actionView.open();
        this.remote.update();
        this.checkActionForm();
        this.handleChanges(true);
    },

    checkActionForm: function() {
        if (!this.certificateError) {
            if (this.form.validate()) {
                this.actionView.enableSubmitButton();
            } else {
                this.actionView.disableSubmitButton();
            }
        }
    },

    /**
     * Handle DHCP changes.
     *
     * @param init true/false
     */
    handleDhcpChange: function(init) {
        var value = this.form.get('dhcp').getValue();

        if (value) {
            this.disableSettings();
        } else {
            this.loadDhcpAutoSettings();
            this.enableSettings();
        }

        if (init) {
            this.settingsSaved = false;
        }
    },

    /**
     * Handle authentication mode change.
     */
    handleAuthChange: function() {
        var value = this.form.get('authMode').getValue();

        if (value) {
            this.showAuth();
        } else {
            this.hideAuth();
        }
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#ethernet-settings-form-v4'),
            validationContainer: '.input-group'
        });

        this.form.get('mac').disable();
    },

    /**
     * Enable settings.
     */
    enableSettings: function() {
        this.form.get('ip').enable();
        this.form.get('subnet').enable();
        this.form.get('gateway').enable();
        this.form.get('dns').enable();
        this.form.get('dns2').enable();
    },

    /**
     * Disable settings.
     */
    disableSettings: function() {
        this.form.get('ip').disable();
        this.form.get('subnet').disable();
        this.form.get('gateway').disable();
        this.form.get('dns').disable();
        this.form.get('dns2').disable();
        this.form.get('mac').disable();
    },

    /**
     * Hide authentication if disabled.
     */
    hideAuth: function() {
        this.$authEl.find('#password').attr('data-allow-empty', 'true');

        this.$authEl.addClass('hidden');
        this.$authEl
            .stop()
            .slideUp();

        this.checkActionForm();
    },

    /**
     * Show authentication if enabled.
     */
    showAuth: function() {
        this.$authEl.find('#password').removeAttr('data-allow-empty');

        this.$authEl.removeClass('hidden');
        this.$authEl
            .stop()
            .slideDown();

        this.checkActionForm();
    },

    /**
     * Handle settings save.
     */
    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        if (this.form.get('dhcp').getValue()) {
            this.$dhcpContainer
                .stop()
                .slideUp(200);
        }
    },

    /**
     * Load Network settings from backend.
     *
     * @param reloadCertificateList true/false
     */
    loadNetworkSettings: function(reloadCertificateList) {
        var dhcp = this.form ? this.form.get('dhcp').getValue() : false;

        return this.deviceConnection
            .send([
                'getEthDhcpSetting',
                {
                    command: 'getEthIpAddress',
                    data: { intefaceIp: dhcp }
                },
                'getEthSubnetMask',
                'getEthGatewayAddress',
                {
                    command: 'getEthSettingsDnsAddress',
                    data: { dynamic: dhcp }
                },
                {
                    command: 'getEthSettingsDnsAddress2',
                    data: { dynamic: dhcp }
                },
                'getEthMacAddress',
                'getLanAuthMode',
                'getLanAuthMethod',
                'getLanIdentity',
                'getLanAnonymousIdentity',
                'getLanPassword',
                'getLanCertificateMode',
                'getLanCertificates',
                'getLanPriority'
            ])
            .then(function(dhcpSetting, ipAddress, subnetMask, gatewayAddress, dnsAddress, dnsAddress2, ethMac, authMode, authMethod, identity, anonymousIdentity, password, certMode, certificates, ethPriority) {
                this.formData = {
                    dhcp: dhcpSetting.dhcpSetting,
                    ip: ipAddress.ipAddress,
                    subnet: subnetMask.subnetMask,
                    gateway: gatewayAddress.gatewayAddress,
                    dns: dnsAddress.dnsAddress === '' ? '0.0.0.0' : dnsAddress.dnsAddress,
                    dns2: dnsAddress2.dnsAddress === '' ? '0.0.0.0' : dnsAddress2.dnsAddress,
                    mac: ethMac.macAddress,
                    authMode: authMode.mode,
                    'auth-method-select': authMethod.authMethod,
                    identity: identity.identity,
                    anonymous: anonymousIdentity.anonymousIdentity,
                    password: password.password,
                    certMode: certMode.mode,
                    'priority': ethPriority.priority
                };

                this.certList = certificates.certList;

                if (reloadCertificateList) {
                    this.resetCertList = this.certList;
                    this.renderCertificateList();
                }
            }.bind(this));
    },

    /**
     * Load DHCP auto settings.
     */
    loadDhcpAutoSettings: function() {
        var dhcp = this.form.get('dhcp').getValue();

        return this.deviceConnection
            .send([
                {
                    command: 'getEthSettingsIpAddress',
                    data: { dynamic: dhcp }
                },
                {
                    command: 'getEthSettingsSubnetMask',
                    data: { dynamic: dhcp }
                },
                {
                    command: 'getEthSettingsGatewayAddress',
                    data: { dynamic: dhcp }
                },
                {
                    command: 'getEthSettingsDnsAddress',
                    data: { dynamic: dhcp }
                },
                {
                    command: 'getEthSettingsDnsAddress2',
                    data: { dynamic: dhcp }
                }
            ]).then(function(ipAddress, subnetMask, gatewayAddress, dnsAddress, dnsAddress2) {
                if (dhcp && this.settingsSaved) {
                    this.form.setValues({
                        ip: ipAddress.ipAddress,
                        subnet: subnetMask.subnetMask,
                        gateway: gatewayAddress.gatewayAddress,
                        dns: dnsAddress.dnsAddress === '' ? '0.0.0.0' : dnsAddress.dnsAddress,
                        dns2: dnsAddress2.dnsAddress === '' ? '0.0.0.0' : dnsAddress2.dnsAddress
                    });
                }

                this.checkActionForm();

                if (ipAddress.ipAddress !== '0.0.0.0' && this.connectionState.getState() === states.connected && this.$dhcpContainer.css('display') === 'none') {
                    this.$dhcpContainer
                        .stop()
                        .slideDown(200, function() {
                            this.settingsSaved = false;
                        });
                }
            }.bind(this));
    },

    createAuthMethodSelect: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#auth-method-select-container'),
            label: 'settings.auth_method',
            native: true,
            name: 'auth-method-select',
            items: [
                {
                    value: 'peap-mschapv2',
                    text: 'settings.peap_mschapv2'
                },
                {
                    value: 'ttls-pap',
                    text: 'settings.ttls_pap'
                }
            ]
        });
    },

    createPrioritySelect: function() {
        var itemCount = 3;
        var items = [];

        if (this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini()) {
            itemCount = 2;
        }

        for (var i = 1; i <= itemCount; i++) {
            items.push({
                value: i,
                text: i
            });
        }

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#priority-select'),
            label: 'settings.priority_interface_access',
            native: true,
            info: (this.deviceService.isCboxPureOrPro() || this.deviceService.isCboxPureMini())
                ? i18n.t('settings.interface_info_pure_lan') : i18n.t('settings.interface_info_lan'),
            name: 'priority',
            items: items
        });
    },

    createPingComponent: function() {
        this.createComponent({
            type: 'Ping',
            container: this.$el.find('#lan1-ping-container'),
            configs: {
                interface: 'lan1'
            }
        });
    },

    /**
     * Render certificate list. Show no entry if certificate list is empty.
     */
    renderCertificateList: function() {
        this.$certList.find('.certificate-list-item').data('updated', false);

        if (!this.certList.length) {
            this.$noEntry.show();
        } else {
            this.$noEntry.hide();
        }

        _.each(this.certList, function(item, key) {
            var $found = this.$certList.find('.certificate-list-item[data-key="' + key + '"]');
            var $item = null;

            if ($found.length === 0) {
                // Create new
                item.key = key;
                item.delete = false;
                item.isCbox = platform.checks.isCbox;
                $item = this.$(certListItemTpl(item));
            } else {
                // Update
                $item = $found;
                $item.find('h2').html(item.name);
                $item.find('.activate').attr('name', item.name);
                $item.find('.delete').attr('name', item.name);
            }

            $item.find('h2').removeClass('has-no-icon');
            $item.find('.icon-check-mark').hide();
            $item.find('.icon-close').hide();

            if (item.active) {
                $item.find('.icon-check-mark').show();
            } else if (item.delete) {
                $item.find('.icon-close').show();
            } else {
                $item.find('h2').addClass('has-no-icon');
            }

            this.$certList.append($item);

            $item.data('updated', true);
        }.bind(this));

        // Remove all items with data-updated=false
        _.each(this.$certList.find('.certificate-list-item'), function(el) {
            var $el = this.$(el);
            var updated = $el.data('updated');

            if (!updated) {
                $el.remove();
            }
        }.bind(this));

        this.$certList.show();
        this.remote.focus(this.$certList.find('.is-focusable').get(0));
    },

    /**
     * Handle certificate mode change.
     */
    handleCertModeChanged: function() {
        var certMode;

        if (!platform.checks.isCbox && !!this.form.get('certificate-file')) {
            certMode = this.form.get('certMode').getValue();

            if (certMode) {
                this.form.get('certificate-file').enable();
            } else {
                this.form.get('certificate-file').disable();
            }
        }
    },

    destroy: function() {
        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    handleChanges: function(change) {
        var changes =  {
            hasChanges: change,
            invalid: false,
            waitOnSave: true
        };

        if (this.actionView) {
            changes.invalid = $(this.actionView.$submitBtn).prop('disabled');
        }

        this.emit('ethernet-settings.changed', changes);
    }
});
