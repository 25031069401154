// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":55},"end":{"line":3,"column":61}}}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "redesign";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-group range-input-container\">\n    <span class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"minIcon") || (depth0 != null ? lookupProperty(depth0,"minIcon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"minIcon","hash":{},"data":data,"loc":{"start":{"line":2,"column":17},"end":{"line":2,"column":28}}}) : helper)))
    + " range-input-before\"></span>\n    <input class=\"range-input focusable\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":69}}})) != null ? stack1 : "")
    + " type=\"range\" min=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"min") || (depth0 != null ? lookupProperty(depth0,"min") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"min","hash":{},"data":data,"loc":{"start":{"line":3,"column":88},"end":{"line":3,"column":95}}}) : helper)))
    + "\" max=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"max") || (depth0 != null ? lookupProperty(depth0,"max") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"max","hash":{},"data":data,"loc":{"start":{"line":3,"column":102},"end":{"line":3,"column":109}}}) : helper)))
    + "\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":3,"column":118},"end":{"line":3,"column":127}}}) : helper)))
    + "\" tabindex=\"-1\" step=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"step") || (depth0 != null ? lookupProperty(depth0,"step") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"step","hash":{},"data":data,"loc":{"start":{"line":3,"column":149},"end":{"line":3,"column":157}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"redesign") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":159},"end":{"line":3,"column":190}}})) != null ? stack1 : "")
    + " />\n    <span class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"maxIcon") || (depth0 != null ? lookupProperty(depth0,"maxIcon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"maxIcon","hash":{},"data":data,"loc":{"start":{"line":4,"column":17},"end":{"line":4,"column":28}}}) : helper)))
    + " range-input-after\"></span>\n</div>\n";
},"useData":true});
