'use strict';

const app = require('../app');
const $ = require('jquery');
const StateMachine = require('./../state-machine');
const i18n = require('i18next');
const loginStates = require('./../states').zoomLoginStates;
const frameboxTypes = require('./../framebox-types').types;
const webconferenceIcons = require('./../framebox-types').webconferenceIcons;

const JOIN_URL_PREFIX = 'https://zoom.us/wc/join/';

app.service('ZoomService', function() {
    return {
        initialize: function() {
            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.webconferenceService = app.getService('WebconferenceService');
                    this.updateHandler();
                    this.bindEvents();
                }.bind(this));

            this.loginState = new StateMachine({
                context: this,
                state: null,
                states: loginStates
            });

            this.zoomLocalAccountLogin = false;
        },

        bindEvents: function() {
            app.on('main-loop.update.zoom', this.updateHandler.bind(this));
            app.on('main-loop.update', this.updateHandler.bind(this));

            app.on('zoom-local-account.login', function(data) {
                this.zoomLocalAccountLogin = data.login;
            }.bind(this));
        },

        updateHandler: function() {
            let dfd = $.Deferred();

            this.deviceConnection
                .send([
                    'getZoomLocalAccountStatus',
                    'getZoomLocalAccountInfo'
                ])
                .then(function(status, info) {
                    app.emit('zoom.update.status', status, info);

                    if (status.status !== this.loginState.getState()) {
                        this.loginState.changeState(status.status);

                        if (this.zoomLocalAccountLogin && status.status !== loginStates.loggedOut) {
                            app.emit('overlay.open', {
                                id: 'zoom-settings',
                                extendedConfigs: 'settings-list'
                            });

                            this.zoomLocalAccountLogin = false;
                        }
                    }

                    dfd.resolve();

                    return dfd.promise();
                }.bind(this));
        },

        /**
         * Get status if authentication is in progress.
         */
        getZoomLocalAccountLogin: function() {
            return this.zoomLocalAccountLogin;
        },

        /**
         * Get control bar position for zoom.
         * Zoom bar height has ~ 5% of the total height (zoom FHD framebox).
         */
        getControlBarPosition: function() {
            let height =  $(document).find('[data-content-type="zoom"]').height();

            return height * 0.05;
        },

        /**
         * Return list of Zoom actions
         */
        getListOfActions: function() {
            return [{
                type: frameboxTypes['zoom'],
                action: 'local',
                icon: webconferenceIcons['local'],
                name: i18n.t('zoom.local')
            },
            {
                type: frameboxTypes['zoom'],
                action: 'join',
                icon: webconferenceIcons['join'],
                name: i18n.t('zoom.join')
            }];
        },

        /**
         * Show Zoom selection modal for
         *  - start local meeting room
         *  - join meeting
         */
        showModal: function() {
            app.emit('modal.open', {
                id: 'selection-v3',
                title: i18n.t('sources.zoom'),
                items: this.getListOfActions().map(action => {
                    // Map action to id since selection modal expects id
                    action.id = action.action;
                    delete action.action;

                    return action;
                }),
                onSelect: function(id) {
                    app.emit('menu.close');

                    switch (id) {
                        case 'join':
                            this.showJoinMeetingModal();
                            break;
                        case 'local':
                            this.webconferenceService.startWebconferenceLocal('zoom');
                            break;
                        default:
                            this.webconferenceService.startWebconferenceLocal('zoom');
                            break;
                    }
                }.bind(this)
            });
        },

        /**
         * Construct Zoom join link from meeting ID
         */
        getZoomLink: function(meetingId) {
            return JOIN_URL_PREFIX + meetingId;
        },

        /**
         * Show Zoom join meeting modal.
         */
        showJoinMeetingModal: function(onJoinHandler) {
            app.emit('modal.open', {
                id: 'zoom-join',
                onJoin: typeof onJoinHandler === 'function' ?  onJoinHandler : function(meetingId, link) {
                    app.emit('framebox.add', {
                        frameBoxType: frameboxTypes.zoom,
                        parameter: link ? link : this.getZoomLink(meetingId)
                    });
                }.bind(this)
            });
        }
    };
});
