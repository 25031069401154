'use strict';

var app = require('../../../app');
var tpl = require('./pause-annotation.hbs');

app.component('PauseAnnotation', {
    template: tpl,
    className: 'pause-annotation',

    getAccessKey: function() {
        return {
            'roleName': 'Annotation',
            'roleKey': 'show'
        };
    },

    /**
     * @method initialize
     */
    initialize: function() {
        this.annotationService = this.getService('AnnotationService');
        this.deviceService = this.getService('DeviceService');
        this.storage = app.getService('DataStorage');
    },

    serialize: function() {
        return {
            isCboxPure: this.deviceService.isCboxPure()
        };
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.bindDOMEvents();
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.$el.on('click', '[data-action]', this.onAction.bind(this));
    },

    /**
     * @method onAction
     */
    onAction: function(event) {
        var $el = this.$(event.currentTarget);
        var action = $el.data('action');
        var handler = this.actionHandlers[action];

        if (handler) {
            handler.call(this);
            this.emit('modal.close');
        }
    },

    actionHandlers: {
        'discare': function() {
            this.deviceConnection
                .send('setAnnotationAction', {
                    action: 'pause',
                    planeIndex: '-1'
                });

            this.annotationService.stopAnnotation();
            this.storage.set('annotation.state', this.annotationService.states.stopped);
        },

        'snapshot': function() {
            this.deviceConnection
                .send([
                    {
                        command: 'setAnnotationAction',
                        data: {
                            action: 'snapshot',
                            planeIndex: '-1'
                        }
                    },
                    {
                        command: 'setAnnotationAction',
                        data: {
                            action: 'pause',
                            planeIndex: '-1'
                        }
                    }
                ]);

            this.annotationService.stopAnnotation();
            this.storage.set('annotation.state', this.annotationService.states.stopped);
        }
    },

    destroy: function() {
        if (typeof this.options.configs.onCancel === 'function') {
            this.options.configs.onCancel();
        }
    }
});

