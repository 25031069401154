'use strict';

var app = require('../app');
var StateMachine = require('./../state-machine');
var $ = require('jquery');

const frameboxTypes = require('./../framebox-types').types;
const typeIcons = require('./../framebox-types').typeIcons;
const i18n = require('i18next');
const controlBarPositions = require('./../settings').controlBarPositions;

const visibleStates = {
    none: 'none',
    visible: 'visible',
    invisible: 'invisible',
    cancelPending: 'cancelPending'
};

app.service('WebconferenceService', function() {
    return {

        initialize: function() {
            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.oneTimeIgnore = false;
                    this.modalHandlerService = app.getService('ModalHandlerService');
                    this.frontendSettings = app.getService('FrontendSettings');
                    this.screenShareWindowVisible = new StateMachine({
                        context: this,
                        state: visibleStates.none,
                        states: visibleStates
                    });

                    this.getControlBarPosition();
                    this.bindEvents();
                    this.addStateTransitions();
                }.bind(this));
        },

        bindEvents: function() {
            app.once('webconference-service.available', this.bindUpdate.bind(this));
            app.on('webconference-service.contentType.update', function(contentType) {
                this.contentType = contentType;
            }.bind(this));
        },

        bindUpdate: function() {
            app.on('main-loop.update', this.updateHandler.bind(this));
            app.on('main-loop.update.webconference', this.updateHandler.bind(this));
        },

        addStateTransitions: function() {
            this.screenShareWindowVisible.addTransitions({
                '> invisible': function() {
                    app.emit('screenshare.automatic.close');
                    setTimeout(() => {
                        app.emit('modal.close', {
                            id: 'screenShare'
                        });
                    }, 100);
                },

                '> visible': function() {
                    app.emit('modal.open', {
                        id: 'screenShare'
                    });
                },

                'visible > cancelPending': function() {
                    this.cancelTimeout = setTimeout(() => {
                        this.screenShareWindowVisible.changeState(visibleStates.none);
                    }, 3000);
                }
            });
        },

        /**
         * Check status and visibility of screen share window.
         */
        updateHandler: function() {
            this.deviceConnection
                .send([
                    'getWebconferenceScreenShareStatus',
                    'getScreenshareWindowVisible'
                ]).then(function(status, screenshareWindow) {
                    const active = status.active;
                    const visible = screenshareWindow.visible;

                    const currentState = this.screenShareWindowVisible.getState();
                    let doStateChange = false;

                    if (currentState === visibleStates.cancelPending && visible === visibleStates.invisible) {
                        if (this.cancelTimeout) {
                            clearTimeout(this.cancelTimeout);
                            this.cancelTimeout = null;
                        }
                        doStateChange = true;
                    } else if (currentState !== visibleStates.cancelPending && currentState !== visible) {
                        doStateChange = true;
                    }

                    if (doStateChange && this.contentType !== 'otherContent') {
                        this.screenShareWindowVisible.changeState(visible);
                    }

                    if (this.screenShareActive !== active) {
                        this.screenShareActive = active;
                        app.emit('webconference.screenshare.state.change', active);
                    }

                    this.getControlBarPosition();
                }.bind(this));
        },

        /**
         * Load data and parse entries.
         *
         */
        getWebconferenceService: function() {
            this.availableWebconferenceServices().then(function(entries) {
                this.entries = entries;

                if (this.entries.length === 1) {
                    this.selectWebconference(0);
                    app.emit('menu.close');

                    return;
                } else if (this.entries.length === 0) {
                    this.selectedWebconference = null;
                    this.startWebconference();
                    app.emit('menu.close');

                    return;
                }

                this.showModal();
            }.bind(this));
        },

        /**
         * Load available webconference services.
         *
         */
        availableWebconferenceServices: function() {
            return this.loadData().then(entries => entries.filter(e => e.URL !== '' && e.Name !== ''));
        },

        /**
         * Get screen sharing active.
         */
        isScreenShareActive: function() {
            return this.screenShareActive;
        },

        /**
         * Load all needed settings.
         *
         * @return {PromiseLike<T> | Promise<T>}
         */
        loadData: function() {
            var dfd = $.Deferred();

            this.deviceConnection
                .send('getWebconferenceEntries')
                .then(function(entries) {
                    dfd.resolve(entries.entries);
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Start webconference and close modal.
         */
        startWebconference: function() {
            var url = '';

            if (this.selectedWebconference) {
                url = this.selectedWebconference.URL;
            }

            app.emit('framebox.add', {
                frameBoxType: frameboxTypes['webconference'],
                parameter: url
            });

            app.emit('modal.close');
        },

        startWebconferenceLocal: function(type) {
            this.deviceConnection
                .send('setOpenWebconferenceLocal', {
                    webconfType: type
                });
        },

        /**
         * Select current focused item.
         *
         * @param {Number} index
         */
        selectWebconference: function(index) {
            this.selectedWebconference = this.entries[index];

            this.startWebconference();
        },

        /**
         * Open selection modal.
         */
        showModal: function() {
            let selectionItems = [];

            this.entries.forEach((entry, index) => {
                selectionItems.push(Object.assign({}, {
                    id: index,
                    name: entry.Name,
                    icon: typeIcons[9]
                }));
            });

            app.emit('modal.open', {
                id: 'selection-v3',
                title: i18n.t('sources.webconference'),
                items: selectionItems,
                onSelect: function(index) {
                    app.emit('menu.close');
                    this.selectWebconference(index);
                }.bind(this)
            });
        },

        /**
         * Get Settings Position.
         */
        getControlBarPosition: function() {
            this.frontendSettings
                .getSettings([
                    'webrtcControlBarPosition'
                ])
                .then(function(webrtcControlBarPosition) {
                    this.controlBarPosition = webrtcControlBarPosition;
                }.bind(this));
        },

        /**
         * Return the px value for the webrtc control bar position.
         *
         * @returns {number}
         */
        getControlBarPositionValue: function() {
            switch (this.controlBarPosition) {
                case controlBarPositions.lowerBottom.name:
                    return controlBarPositions.lowerBottom.value;
                case controlBarPositions.bottom.name:
                    return controlBarPositions.bottom.value;
                case controlBarPositions.upperBottom.name:
                    return controlBarPositions.upperBottom.value;
            }
        },

        /**
         * Start or cancel screen share and send action.
         * @param done = true, cancel = false
         */
        startScreenShare: function(done) {
            if (!done) {
                this.screenShareWindowVisible.changeState(visibleStates.cancelPending);
            }

            return this.deviceConnection
                .send('setWebconferenceScreenshareStart', {
                    done: done
                });
        }
    };
});
