'use strict';

var $ = require('jquery');
var app = require('../app');
var platform = require('../../platform/platform');
var StateMachine = require('./../state-machine');
var rbac = require('./../../rbac/rbac.js');
var helper = require('../helper.js');

var roles = {
    none: 'none',
    admin: 'admin',
    user: 'user',
    collab: 'collab',
    viewer: 'viewer',
    ldap: 'ldap'
};

var ldapStates = {
    notAuthenticated: 'not-authenticated',
    authenticated: 'authenticated'
};

app.service('AuthenticationService', function(app) {
    return {
        ROLES: {
            none: {
                id: 0,
                name: 'None'
            },
            user: {
                id: 1,
                name: 'User',
                level: 3
            },
            admin: {
                id: 2,
                name: 'Admin',
                level: 4
            },
            collab: {
                id: 3,
                name: 'Collab',
                level: 2
            },
            viewer: {
                id: 4,
                name: 'Viewer',
                level: 1
            }
        },

        initialize: function() {
            this.role = null;
            this.ldap = null;
            this.cat = false;
            this.matrixControlInstance = false;
            this.connectionFactory = app.getService('ConnectionFactoryService');
            this.dataStorage = app.getService('DataStorage');
            this.bootstrapService = app.getService('BootstrapService');
            this.powerService = app.getService('PowerService');
            this.screensaverService = app.getService('ScreensaverService');
            this.modalHandlerService = app.getService('ModalHandlerService');
            this.bindEvents();

            this.userRole = new StateMachine({
                context: this,
                state: roles.user,
                states: roles
            });

            this.ldapStatus = new StateMachine({
                state: ldapStates.notAuthenticated,
                states: ldapStates
            });

            this.addStateTransitions();
        },

        bindEvents: function() {
            app.on('main-loop.update', this.updateHandler.bind(this));
            app.on('main-loop.update.authentication', this.updateHandler.bind(this));
            app.on('overlay.disconnect', this.disconnect.bind(this));
        },

        /**
         * Check if login is required.
         *
         * @param {Function} onLoginRequired
         * @param {Function} onAuthorized
         */
        checkLoginRequired: function(onLoginRequired, onAuthorized) {
            var token = helper.getFirstMatchingUrlParam(['mmat']).value; // Matrix access token (control window (control station) on master)

            if (token) {
                helper.removeParamsFromUrl();

                this.setParamToken(token)
                    .done(onAuthorized)
                    .fail(function() {
                        throw new Error('Failed to set control token: "' + token + '"');
                    }.bind(this));
            } else {
                this.checkAuthToken()
                    .done(onAuthorized)
                    .fail(function() {
                        if (platform.checks.isCbox) {
                            this.storeAccessToken();
                            onAuthorized();
                        } else {
                            this.getWebbrowserViewerActive()
                                .done(function(viewerActive, viewerPinEnabled) {
                                    if (viewerActive && !this.viewerLoginRunning && !this.loginIsRunning) {
                                        app.emit('modal.open', {
                                            id: 'select-viewer-control',
                                            onViewer: this.onViewerClickHandler.bind(this, viewerPinEnabled),
                                            onControl: this.onControlClickHandler.bind(this, onLoginRequired, onAuthorized),
                                            userRole: helper.getFirstMatchingUrlParam(['role']).value
                                        });
                                    } else {
                                        this.checkPasswordRequired(onLoginRequired, onAuthorized);
                                    }
                                }.bind(this));
                        }
                    }.bind(this));
            }
        },

        /**
         * Set the Matrix control token to indicate if this is the Matrix master which controls the station.
         *
         * @param token Parameter token
         * @returns {*}
         */
        setParamToken: function(token) {
            var dfd = $.Deferred();

            if (!token) {
                this.matrixControlInstance = false;
                dfd.resolve();
            } else {
                this.connectionFactory.get('device')
                    .send('setMatrixControlToken', {
                        controlToken: Number(token).toString(16)
                    }).then(function() {
                        this.matrixControlInstance = true;

                        this.connectionFactory.get('device')
                            .send('getLoginLevel')
                            .then(function(data) {
                                this.changeRoleState(data.loginLevel);

                                if (data.loginLevel === roles.user) {
                                    dfd.resolve();
                                } else {
                                    dfd.reject();
                                }
                            }.bind(this), function() {
                                dfd.reject();
                            });
                    }.bind(this));
            }

            return dfd.promise();
        },

        /**
         * Check authentication token.
         *
         * @returns {*}
         */
        checkAuthToken: function() {
            var dfd = $.Deferred();
            var access = this.dataStorage.get('access');
            var token;
            var loginLevel;
            var role;
            var dataLoaded = false;
            var dataLoadCounter = 0;
            var accessToken = helper.getFirstMatchingUrlParam(['mtat', 'cat']);

            if (accessToken.value) {
                if (!access) {
                    access = {
                        accessToken: '',
                        loginLevel: 1
                    };
                }
                this.cat = true;

                access.accessToken = accessToken.value;
                this.dataStorage.set('access', access);

                helper.removeParamsFromUrl();
            }

            // Check the correct loginlevel if we have set access token
            var checkLoginLevel = function() {
                this.connectionFactory.get('device')
                    .send('getLoginLevel')
                    .then(function(data) {
                        dataLoaded = true;
                        role = this.ROLES[data.loginLevel];
                        this.changeRoleState(data.loginLevel);

                        if (role.id === loginLevel) {
                            dfd.resolve();
                        } else {
                            dfd.reject();
                        }
                    }.bind(this), function() {
                        dataLoaded = true;
                        dfd.reject();
                    });

                // After End-presentation sometimes the connections is to slow.
                // Call this function every 500ms, so long we have no connection
                setTimeout(function() {
                    if (!dataLoaded) {
                        // After third try, we reload the page.
                        if (dataLoadCounter < 3) {
                            checkLoginLevel.call(this);
                            dataLoadCounter++;
                        } else {
                            window.location.reload();
                        }
                    }
                }.bind(this), 500
                );
            };

            // Check local saved access data and send to the box
            var checkToken = function() {
                if (token && loginLevel) {
                    this.connectionFactory.get('device')
                        .send('setAccessToken', {
                            accessToken: token
                        });

                    checkLoginLevel.call(this);
                } else {
                    dfd.reject();
                }
            };

            // Check if we have local saved token and loginlevel
            var checkAccessData = function() {
                if (access) {
                    token = access.accessToken;
                    loginLevel = access.loginLevel;

                    checkToken.call(this);
                } else {
                    dfd.reject();
                }
            };

            checkAccessData.call(this);

            return dfd.promise();
        },

        /**
         * Check Access of the given Module if user has access.
         * Like User call Settings.
         *
         * @method checkAccess
         *
         * @param {string} userKey
         * @param {object} role
         * @param {function} onAuthorized
         * @param {function} onCancelLogin
         */
        checkAccess: function(role, onAuthorized, onCancelLogin) {
            var user = rbac.USERS['admin']; // TODO no static user key

            if (!rbac.hasAccess(role.name, role.key)) {
                app.emit('modal.open', {
                    id: 'login',
                    user: user.key,
                    loginHandler: onAuthorized,
                    cancelLoginHandler: onCancelLogin
                });
            } else if (onAuthorized) {
                onAuthorized();
            }
        },

        /**
         * On control button click handler.
         *
         * @param {function} onLoginRequired
         * @param {function} onAuthorized
         */
        onControlClickHandler: function(onLoginRequired, onAuthorized) {
            this.checkLdapLoginRequired()
                .then(this.checkPasswordRequired.bind(this, onLoginRequired, onAuthorized));
        },

        /**
         * Click on viewer button and open login dialog if necessary.
         *
         * @param viewerPinEnabled true/false
         */
        onViewerClickHandler: function(viewerPinEnabled) {
            if (viewerPinEnabled) {
                this.connectionFactory.get('device').send('setWebbrowserViewerPinPopup');
                this.showLoginDialog('viewer', false);

                return;
            }

            this.login('viewer', '', '')
                .then(function() {
                    app.emit('rbac.user.changed', {
                        key: 'viewer'
                    });
                    this.bootstrapService.start('viewer');
                    this.storeAccessToken();
                }.bind(this));
        },

        /**
         * @method checkPasswordRequired
         * @param {Function} onLoginRequired
         * @param {Function} onAuthorized
         */
        checkPasswordRequired: function(onLoginRequired, onAuthorized) {
            this.connectionFactory.get('device')
                .send('getRemotePasswordRequired')
                .then(function(data) {
                    if (!data.password) {
                        if (this.ldap) {
                            if (this.loginIsRunning) {
                                setTimeout(function() {
                                    this.checkLoginRequired(onLoginRequired, onAuthorized);
                                }.bind(this), 500);
                            } else if (this.ldapStatus.currentState !== ldapStates.authenticated) {
                                this.loginIsRunning = true;
                                this.login(roles.user, '', '')
                                    .then(function() {
                                        setTimeout(function() {
                                            this.storeAccessToken();

                                            if (this.screensaverService.screensaverState.getState() === 'screensaver'
                                                || this.screensaverService.screensaverState.getState() === 'screen-off') {
                                                window.location.reload();

                                                return;
                                            }

                                            this.checkLoginRequired(onLoginRequired, onAuthorized);
                                        }.bind(this), 500);
                                    }.bind(this))
                                    .fail(function() {
                                        this.loginIsRunning = false;
                                        this.checkLoginRequired(onLoginRequired, onAuthorized);
                                    }.bind(this));
                            } else {
                                this.showLdapDialog();
                            }
                        } else {
                            onAuthorized();
                            this.storeAccessToken();
                        }
                    } else {
                        this.getGuestLoginType()
                            .then(function(pwdType) {
                                if (pwdType === 'password' || pwdType === 'pin') {
                                    onLoginRequired();
                                } else if (pwdType === 'none') {
                                    onAuthorized();
                                } else {
                                    throw new Error('Password-Type: "' + pwdType + '" is not handled.');
                                }
                            }.bind(this));
                    }
                }.bind(this));
        },

        /**
         * Store access token in data storage.
         */
        storeAccessToken: function() {
            var dfd = $.Deferred();

            this.connectionFactory.get('device')
                .send('getAccessToken')
                .then(function(data) {
                    this.dataStorage.set('access', data);

                    // Check again for the correct access token (teams token)
                    this.checkAuthToken();

                    dfd.resolve();
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Login.
         *
         * @param {String} role Current user role / level.
         * @param {String} password Entered password
         * @param {String} adminPin Entered admin password
         */
        login: function(role, password, adminPin) {
            return this.connectionFactory.get('device')
                .send('setLoginPassword', {
                    accessLevel: role,
                    password: password,
                    adminPin: adminPin
                }).then(function() {
                    app.emit('main-loop.fast.start', {
                        id: 'authentication'
                    });
                }.bind(this));
        },

        /**
         * Login as LDAP user.
         *
         * @param username LDAP username
         * @param password LDAP password
         */
        ldapLogin: function(username, password) {
            return this.connectionFactory.get('device')
                .send('setLdapLogin', {
                    username: username,
                    password: password
                }).then(function() {
                    app.emit('main-loop.fast.start', {
                        id: 'ldap-authentication'
                    });
                }.bind(this));
        },

        /**
         * Get current password type (password or pin).
         */
        getGuestLoginType: function() {
            var dfd = $.Deferred();

            this.connectionFactory.get('device')
                .send('getPasswordType')
                .then(function(data) {
                    if (data.passwordType) {
                        dfd.resolve(data.passwordType);
                    } else {
                        dfd.resolve('none');
                    }
                });

            return dfd.promise();
        },

        /**
         * Check if remote password is required.
         */
        getLoginRequired: function() {
            var dfd = $.Deferred();

            this.connectionFactory.get('device')
                .send('getRemotePasswordRequired')
                .then(function(data) {
                    dfd.resolve(data);
                });

            return dfd.promise();
        },

        /**
         * Returns if  webbrowser viewer is active.
         *
         * @returns {bool} true/false
         */
        getWebbrowserViewerActive: function() {
            var dfd = $.Deferred();

            this.connectionFactory.get('device')
                .send([
                    'getWebbrowserViewerSupport',
                    'getWebbrowserViewerPinEnabled'
                ]).then(function(data, viewerPin) {
                    this.isViewerActive = data.enabled;
                    dfd.resolve(data.enabled, viewerPin.enabled);
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Check if collaboration is active.
         *
         * @returns {bool} true/false
         */
        getCollaborationActive: function() {
            var dfd = $.Deferred();

            this.connectionFactory.get('device')
                .send('getAnnotationPinStatus')
                .then(function(data) {
                    dfd.resolve(data.showPin);
                });

            return dfd.promise();
        },

        /**
         * Handle updates.
         */
        updateHandler: function() {
            this.checkIsAdmin();
            this.checkLoginModal();
            this.handleLdap();
            this.handleVMeeting();
        },

        /**
         * Handle vSolution Meeting.
         */
        handleVMeeting: function() {
            $.when(this.getWebbrowserViewerActive(this), this.checkVMeeting(this))
                .done(function(viewerActive, vMeetingActiveSession) {
                    if (!vMeetingActiveSession && this.userRole.getState() === roles.viewer) {
                        if (this.powerService.isOnStandby()
                            || this.screensaverService.isScreensaverActive()
                            || this.screensaverService.isScreenOffActive()) {
                            return;
                        }

                        app.emit('modal.open', {
                            id: 'box-no-presentation',
                            role: {
                                name: 'allAccess',
                                key: 'show'
                            }
                        });

                        this.dataStorage.remove('access');
                    }
                }.bind(this));
        },

        /**
         * Check if vSolution Meeting is enabled and a Session is running.
         * @returns {*} true if a session is active or vMeeting is NOT enabled else false
         */
        checkVMeeting: function() {
            var dfd = $.Deferred();

            this.connectionFactory.get('device')
                .send([
                    'getVMeetingFunction',
                    'getVMeetingActiveSession'
                ])
                .then(function(vMeeting, activeSession) {
                    if (!vMeeting.enabled) {
                        dfd.resolve(true);
                    }

                    var active = !!activeSession.activeSessionId;

                    if (active && this.vMeetingActiveSession !== active) {
                        app.emit('modal.close', {
                            id: 'box-no-presentation'
                        });
                    }

                    this.vMeetingActiveSession = active;

                    if (vMeeting.enabled && this.vMeetingActiveSession) {
                        dfd.resolve(true);
                    } else {
                        dfd.resolve(false);
                    }
                }.bind(this))
                .fail(function() {
                    this.dataStorage.remove('access');
                    dfd.reject();
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Handle LDAP.
         * Check if LDAP is active and update.
         */
        handleLdap: function() {
            $.when(this.getWebbrowserViewerActive(this), this.checkLdapLoginRequired(this))
                .done(function(viewerActive, showLdap) {
                    if (showLdap && this.userRole.getState() === roles.viewer) {
                        if (this.powerService.isOnStandby()
                        || this.screensaverService.isScreensaverActive()
                        || this.screensaverService.isScreenOffActive()) {
                            return;
                        }

                        app.emit('modal.open', {
                            id: 'box-no-presentation',
                            role: {
                                name: 'allAccess',
                                key: 'show'
                            }
                        });

                        this.dataStorage.remove('access');
                    } else if (showLdap && this.userRole.getState() === roles.user) {
                        this.showLdapDialog();
                    } else {
                        this.checkIsAdmin();
                        this.checkLoginModal();
                    }
                }.bind(this));
        },

        /**
         * Check if LDAP login is required and update the LDAP status and mode.
         */
        checkLdapLoginRequired: function() {
            var dfd = $.Deferred();

            this.connectionFactory.get('device')
                .send([
                    'getLdapAuthMode',
                    'getLdapStatus'
                ])
                .then(function(authMode, status) {
                    // RELEASE-4014: Hide Ldap Login Modal on HDMI
                    if (this.ldap && !authMode.enabled) {
                        app.emit('modal.close', {
                            id: 'ldap-login'
                        });
                    }

                    this.ldap = authMode.enabled;

                    if (this.ldapStatus.currentState !== status.ldapStatus) {
                        this.ldapStatus.changeState(status.ldapStatus);
                    }

                    if (authMode.enabled && status.ldapStatus !== ldapStates.authenticated) {
                        dfd.resolve(true);
                    } else {
                        dfd.resolve(false);
                    }
                }.bind(this))
                .fail(function() {
                    /**
                     * If an LDAP user is logging in somewhere else, delete the access token to avoid a black screen.
                     * This Wolfprot Commands fail, if the instance isn't logged in as LDAP but as Moderator.
                     */
                    this.dataStorage.remove('access');
                    dfd.reject();
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Open Login modal after loginLevel was changed to "none".
         */
        checkLoginModal: function() {
            this.connectionFactory.get('device')
                .send('getLoginLevel')
                .then(function(data) {
                    if (this.userRole.getState() === data.loginLevel
                        || data.loginLevel !== roles.none
                    ) {
                        return;
                    }

                    this.checkLoginRequired(
                        this.showLoginDialog.bind(this),
                        $.noop
                    );
                }.bind(this));
        },

        /**
         * Check if curernt login level is Admin.
         */
        checkIsAdmin: function() {
            this.connectionFactory.get('device')
                .send('getLoginLevel')
                .then(function(data) {
                    if (this.userRole.getState() !== data.loginLevel) {
                        this.changeRoleState(data.loginLevel);
                    }
                }.bind(this));
        },

        /**
         * Change the current role / login level.
         *
         * @param loginLevel New login level
         */
        changeRoleState: function(loginLevel) {
            if (loginLevel.toLowerCase() === 'admin') {
                this.userRole.changeState(roles.admin);
                app.emit('rbac.user.changed', {
                    key: roles.admin
                });
            } else if (loginLevel.toLowerCase() === 'user') {
                this.userRole.changeState(roles.user);
                app.emit('rbac.user.changed', {
                    key: roles.user
                });
            } else if (loginLevel.toLowerCase() === 'viewer') {
                this.userRole.changeState(roles.viewer);
                app.emit('rbac.user.changed', {
                    key: roles.viewer
                });
            } else if (loginLevel.toLowerCase() === 'collab') {
                this.userRole.changeState(roles.collab);
                app.emit('rbac.user.changed', {
                    key: roles.collab
                });
            } else {
                app.emit('rbac.user.changed', {
                    key: roles.none
                });
            }
        },

        /**
         * Get Current user level.
         */
        getUserLevel: function() {
            return this.ROLES[this.userRole.getState()].level;
        },

        /**
         * Check if Moderator is logged in.
         *
         * @returns {boolean} true/false
         */
        getIsModerator: function() {
            return (this.userRole.getState() === roles.user);
        },

        /**
         * Check if Admin is logged in.
         *
         * @returns {boolean} true/false
         */
        getIsAdmin: function() {
            return (this.userRole.getState() === roles.admin);
        },

        /**
         * Check if Collaboration User is logged in.
         *
         * @returns {boolean} true/false
         */
        getIsCollab: function() {
            return (this.userRole.getState() === roles.collab);
        },

        /**
         * Check if Webbrowser Viewer is logged in.
         *
         * @returns {boolean} true/false
         */
        getIsViewer: function() {
            return (this.userRole.getState() === roles.viewer);
        },

        /**
         * Check if LDAP is active and someone is authenticated.
         *
         * @returns {*|boolean} true/false
         */
        getIsLdapAuthenticated: function() {
            return (this.ldap && this.ldapStatus.currentState === ldapStates.authenticated);
        },

        /**
         * Check if LDAP is active. Only important for Moderator (login level user).
         *
         * @returns {boolean} true/false
         */
        getIsLdapActive: function() {
            return this.userRole.getState() === roles.user && this.ldap;
        },

        /**
         * Logout.
         */
        logout: function() {
            this.role = null;
        },

        /**
         * Logout from admin account.
         */
        disconnect: function() {
            this.connectionFactory.get('device')
                .send('setDisconnect')
                .then(function() {
                    window.location.reload();
                });
        },

        /**
         * Show Moderator/Admin login dialog.
         */
        showLoginDialog: function(user, switchBtn) {
            var onCancelLogin = null;

            this.bootstrapService.stop();
            this.getWebbrowserViewerActive()
                .then(function(data) {
                    this.viewerLoginRunning = data;

                    if (this.viewerLoginRunning) {
                        onCancelLogin = function() {
                            this.viewerLoginRunning = false;
                            window.location.href =  window.location.href.split('?role')[0];

                            this.checkLoginModal();
                        }.bind(this);
                    }

                    app.emit('modal.open', {
                        id: 'login',
                        user: user,
                        switchBtn: switchBtn,
                        loginHandler: this.bootstrapService.start.bind(this.bootstrapService),
                        cancelLoginHandler: onCancelLogin
                    });
                }.bind(this));
        },

        /**
         * Show LDAP/Admin login dialog.
         */
        showLdapDialog: function() {
            var onCancelLogin = null;
            // Do not show LDAP login if screensaver is active, end presentation or support pin modal is opened
            if (this.modalHandlerService.getOpenModalId() === 'end-presentation' || this.supportPin
                || (
                    (this.screensaverService.isScreensaverActive() || this.screensaverService.isScreensaverActiveUI())
                    && !this.screensaverService.deactivateRunning
                )) {
                return;
            }

            if (this.isViewerActive && !platform.checks.isCbox) {
                onCancelLogin = function() {
                    this.dataStorage.remove('access');
                    window.location.reload();
                }.bind(this);
            }

            // RELEASE-3117
            $.when(this.screensaverService.getScreensaverState())
                .done(function(screensaverState) {
                    if (screensaverState !== 'screensaver' || this.screensaverService.deactivateRunning) {
                        app.emit('modal.open', {
                            id: 'ldap-login',
                            user: roles.ldap,
                            loginHandler: function(loginLevel) {
                                // RELEASE-3117
                                if (app.getService('ScreensaverService').deactivateRunning) {
                                    app.getService('ScreensaverService').deactivateScreensaver();
                                }

                                this.changeRoleState(loginLevel);
                                this.bootstrapService.start();
                            }.bind(this), // TODO: Add success callback to open control-center.
                            cancelLoginHandler: onCancelLogin
                        });
                    }
                }.bind(this));
        },

        /**
         * @returns {boolean} true if instance is the Matrix Master control instance.
         */
        isMatrixControlInstance: function() {
            return this.matrixControlInstance;
        },

        /**
         * Add state transitions.
         */
        addStateTransitions: function() {
            this.userRole.addTransitions({
                '> user': function() {
                    this.handleLdap();

                    app.emit('status-widget.item.remove', {
                        id: 'admin'
                    });
                },

                '> admin': function() {
                    app.getService('SecurityService');

                    app.emit('status-widget.item.append', {
                        id: 'admin',
                        accessKey: 'Authentication',
                        options: {
                            icon: 'icon-v2-admin',
                            clickable: false,
                            state: 'admin'
                        }
                    });
                }
            });

            this.ldapStatus.addTransitions({
                '> authenticated': function() {
                    // RELEASE-3117
                    if (app.getService('ScreensaverService').deactivateRunning) {
                        app.getService('ScreensaverService').deactivateScreensaver();
                    }

                    app.emit('modal.close', {
                        id: 'box-no-presentation'
                    });

                    app.emit('modal.close', {
                        id: 'ldap-login'
                    });
                }
            });
        }
    };
});
