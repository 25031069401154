'use strict';

const $ = require('jquery');
const app = require('../app');
const _ = require('lodash');
const i18n = require('i18next');

const height = 4120;
const width = 7680;
const pointsHorizontal = 23;
const pointsVertical = 15;

/**
 * Calculate the horizontal grid point distance.
 *
 * @returns {number} Grid point distance x
 */
function distanceX() {
    return width / (pointsHorizontal + 1);
}

/**
 * Calculate the vertical grid point distance.
 *
 * @returns {number} Grid point distance y
 */
function distanceY() {
    return height / (pointsVertical + 1);
}

/**
 * Calculate the horizontal connection point distance (every 10th point is a station point).
 *
 * @returns {number} Connection point distance x
 */
function distanceConnectionPointsX() {
    return width / ((pointsHorizontal + 1) * 5);
}

/**
 * Calculate the vertical connection point distance (every 8th point is a station point)
 *
 * @returns {number} Connection point distance y
 */
function distanceConnectionPointsY() {
    return height / ((pointsVertical + 1) * 4);
}

const configs = {
    'filesharing': {
        path: 'matrixMaster:///'
    },

    'dimensions': {
        'width': width,
        'height': height
    },

    'matrixBox': {
        'itemWidth': 322,
        'itemHeight': 60,
        'itemHeaderHeight': 40
    },

    'mainInteraction': {
        'width': 1100,
        'height': 550
    },

    'stationpoints': {
        horizontal: pointsHorizontal,
        vertical: pointsVertical,
        distanceX: distanceX(),
        distanceY: distanceY()
    },

    'station': {
        'minWidthHeight': 600,
        'btnWidth': 220,
        'btnHeight': 220,
        'handlesWidth': 190,
        'handlesHeight': 190
    },

    'receiver': {
        'btnWidth': 180,
        'btnHeight': 180,
        'handlesWidth': 150,
        'handlesHeight': 150
    },

    'connectionpoints': {
        horizontal: (pointsHorizontal * 5) + 4,
        vertical: (pointsVertical * 4) + 3,
        distanceX: distanceConnectionPointsX(),
        distanceY: distanceConnectionPointsY()
    },

    iconMapping: {
        'image': 'icon-v3-filetype-generic-image',
        'video': 'icon-v3-filetype-generic-video',
        'pdf': 'icon-v3-filetype-pdf',
        'text': 'icon-v3-filetype-txt',
        'calc': 'icon-v3-filetype-generic-numbers',
        'presentation': 'icon-v3-filetype-generic-presentation',
        'html': 'icon-v3-filetype-html',
        'htm': 'icon-v3-filetype-htm',
        'audio': 'icon-v3-filetype-generic-audio',
        'dir': 'icon-v3-folder',
        'local': 'icon-v3-storage',
        'usb': 'icon-v3-usb',
        'dropbox': 'icon-v3-cloud-dropbox',
        'gdrive': 'icon-v3-cloud-google-drive',
        'jianguoyun': 'icon-v3-cloud-jianguoyun',
        'onedrive': 'icon-v3-cloud-onedrive',
        'webdav': 'icon-v3-cloud-webdav',
        'netdrive': 'icon-v3-network-drive',
        'recent_files': 'icon-v3-recent-files'
    },

    'colorpicker': {
        'palette': [
            ['#8E4218', '#c46200', '#FF6600', '#FFD821'],
            ['#940F23', '#FF0000', '#F65A5B', '#FF8A65'],
            ['#833AB4', '#914361', '#F54785', '#E697EE'],
            ['#565098', '#222DF5', '#4169E1', '#A69ECA'],
            ['#006E96', '#008BDD', '#00AEFF', '#1CC7D0'],
            ['#00A98F', '#7FBB00', '#A0AC48', '#B39A3E'],
            ['#537B35', '#616F67', '#757575', '#918978']
        ]
    },

    'groups': {
        'max': 5
    },

    /**
     * Maximum number of allowed stations per configuration.
     * Don't forget to change the 'max_stations_exceeded' text when changing this nr.
     */
    'stations': {
        'max': 40
    }
};

app.service('MatrixConfigs', function(app) {
    return {
        initialize: function() {
            this.stations = [];
            this.dfdReady = $.Deferred();

            this.configs = app.getService('ConfigsService');
            this.deviceService = app.getService('DeviceService');
            this.matrixService = app.getService('MatrixService');
            this.JSONService = app.getService('JSONService');
            this.frontendSettings = app.getService('FrontendSettings');
            this.matrixConfigService = app.getService('MatrixConfiguratorService');
            this.isDualProjection = this.deviceService.isCboxProDualProjection();
            this.matrixFileName = this.isDualProjection
                ? this.configs.get('cynap.matrixDualFilename')
                : this.configs.get('cynap.matrixFilename');
            this.matrixBackground = this.isDualProjection
                ? this.configs.get('cynap.matrixDualBackground')
                : this.configs.get('cynap.matrixBackground');
            this.matrixConfig = null;
            this.$matrixLayer = null;
            this.matrixTemplateId = -1;
            this.chooseTemplate = false;
            this.ignoreMatrixTemplates = false;

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.dfdReady.resolve();

                    this.deviceConnection
                        .send('getMatrixMasterMode')
                        .then(function(masterMode) {
                            if (masterMode.enabled) {
                                this.parseStations();
                                this.loadMatrixTemplates();
                            }
                        }.bind(this));
                }.bind(this));
        },

        /**
         * Generate a promise that resolves when device connection is ready
         */
        whenReady: function() {
            return this.dfdReady.promise();
        },

        /**
         * Change Matrix template to template with ID id.
         * If configurator is openend, check for unsaved changes.
         *
         * @param id ID of template to change to.
         * @param isConfigurator true if Configurator / false if Main
         */
        changeMatrixTemplate: function(id, isConfigurator, onFinish) {
            if (isConfigurator) {
                if (!this.matrixConfigService) {
                    this.matrixConfigService = app.getService('MatrixConfiguratorService');
                }

                this.matrixConfigService.checkUnsavedConfig()
                    .then(function(unsavedChanges) {
                        if (unsavedChanges) {
                            app.emit('modal.open', {
                                id: 'matrix-change-config',
                                role: {
                                    name: 'Matrix',
                                    key: 'show'
                                },
                                onFinish: function() {
                                    this.setCurrentMatrixTemplate(id, isConfigurator);

                                    if (onFinish) {
                                        onFinish();
                                    }
                                }.bind(this)
                            });
                        } else {
                            this.setCurrentMatrixTemplate(id, isConfigurator);

                            if (onFinish) {
                                onFinish();
                            }
                        }
                    }.bind(this));
            } else {
                this.setCurrentMatrixTemplate(id, isConfigurator);

                if (onFinish) {
                    onFinish();
                }
            }
        },

        /**
         * Change Matrix Template.
         *
         * @param id ID of template to set to current.
         * @param {Boolean} [isConfigurator] origin of function call
         */
        setCurrentMatrixTemplate: function(id, isConfigurator = null) {
            this.frontendSettings.updateSetting({
                tag: 'matrixCurrentTemplate',
                value: id
            });

            this.frontendSettings.saveSettings();

            this.matrixTemplateId = id;

            if (isConfigurator === null) {
                return;
            }

            this.parseStations().then(function() {
                if (isConfigurator) {
                    app.emit('matrix-configurator-template.changed', id);
                } else {
                    this.matrixService.setMatrixActiveTemplate(id);
                    app.emit('matrix-main-template.changed', id);
                }
            }.bind(this));

            app.emit('matrix-config-selection.changed', this.getCurrentMatrixTemplateName());
        },

        /**
         * Check current matrix template (frontend) with active template (backend) and update if necessary.
         *
         * @returns {*} true if current template was updated
         */
        checkCurrentMatrixTemplate: function() {
            const dfd = $.Deferred();

            if (this.matrixTemplateId === -1) {
                dfd.resolve(false);
            } else {
                this.matrixService.getMatrixActiveTemplate()
                    .then(function(templateId) {
                        if (this.matrixTemplateId !== templateId && this.templateExists(templateId)) {
                            this.setCurrentMatrixTemplate(templateId, true);

                            dfd.resolve(true);
                        } else if (this.templateExists(templateId)) {
                            dfd.resolve(false);
                        } else {
                            this.setCurrentMatrixTemplate(-1, true);

                            dfd.resolve(true);
                        }
                    }.bind(this));
            }

            return dfd.promise();
        },

        /**
         * Load frontend settings.
         */
        loadCurrentMatrixTemplate: function() {
            const dfd = $.Deferred();

            this.frontendSettings
                .getSettings([
                    'matrixCurrentTemplate'
                ])
                .then(function(template) {
                    this.matrixTemplateId = template;

                    dfd.resolve();
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Load Matrix configuration file.
         *
         * @param defaultConfig Fallback/default config
         */
        loadMatrixConfiguration: function(defaultConfig) {
            const dfd = $.Deferred();

            this.loadCurrentMatrixTemplate()
                .then(function() {
                    this.deviceConnection
                        .send([
                            {
                                command: 'getExtraSettings',
                                data: { filename: this.matrixFileName + this.getCurrentMatrixTemplateId() }
                            }
                        ])
                        .then(function(config) {
                            if (config) {
                                dfd.resolve(config.string);
                            } else {
                                dfd.resolve(defaultConfig ?? '');
                            }
                        }.bind(this));
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Save matrix configuration file.
         *
         * @param config HTML-config to save
         * @param onFinish {function} do after save
         */
        saveMatrixConfiguration: function(config, onFinish) {
            this.deviceConnection
                .send([
                    {
                        command: 'setExtraSettings',
                        data: {
                            filename: this.matrixFileName + this.matrixTemplateId,
                            string: config
                        }
                    }
                ]).then(function() {
                    if (onFinish) {
                        onFinish();
                    } else {
                        this.parseStations();
                    }
                }.bind(this));
        },

        /**
         * Load Matrix templates list (JSON).
         */
        loadMatrixTemplates: function() {
            const dfd = $.Deferred();

            this.deviceConnection
                .send([
                    {
                        command: 'getExtraSettings',
                        data: { filename: this.configs.get('cynap.matrixTemplates') }
                    }
                ]).then(function(templates) {
                    if (templates) {
                        const { Templates = [], DualTemplates = [] } = this.JSONService
                            .parse(templates.string, {
                                'Templates': [],
                                'DualTemplates': []
                            });

                        if (this.isDualProjection) {
                            this.matrixTemplates = DualTemplates;
                            this.inactiveTemplates = Templates;
                        } else {
                            this.matrixTemplates =  Templates;
                            this.inactiveTemplates = DualTemplates;
                        }

                        dfd.resolve(this.matrixTemplates);
                    } else {
                        this.initMatrixTemplates()
                            .then(function() {
                                dfd.resolve(this.matrixTemplates);
                            }.bind(this));
                    }
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Save matrix templates list (JSON).
         */
        saveMatrixTemplates: function() {
            const templates = {};

            if (this.isDualProjection) {
                templates.DualTemplates = this.matrixTemplates;
                templates.Templates = this.inactiveTemplates;
            } else {
                templates.DualTemplates =  this.inactiveTemplates;
                templates.Templates = this.matrixTemplates;
            }

            this.deviceConnection
                .send([
                    {
                        command: 'setExtraSettings',
                        data: {
                            filename: this.configs.get('cynap.matrixTemplates'),
                            string: JSON.stringify(templates)
                        }
                    }
                ]).then(function() {
                    app.emit('settings.matrix-templates.changed');
                }.bind(this));
        },

        /**
         * Init Matrix templates file (JSON) and if there are some existing configurations, add them to the list.
         */
        initMatrixTemplates: function() {
            const dfd = $.Deferred();

            this.matrixTemplates = [];

            this.deviceConnection
                .send([
                    {
                        command: 'getExtraSettings',
                        data: { filename: this.configs.get('cynap.matrixFilenameDeprecated') }
                    }
                ]).then(function(config) {
                    if (config) { // There is an 'old' configuration
                        this.deviceConnection
                            .send([
                                {
                                    command: 'setExtraSettings',
                                    data: {
                                        filename: this.matrixFileName + 0,
                                        string: config.string
                                    }
                                }
                            ]);

                        const template = {
                            id: 0,
                            name: i18n.t('settings.matrix_configuration') + ' 1',
                            shareToAllEnabled: true,
                            config: this.matrixFileName + 0,
                            groups: []
                        };

                        this.addMatrixTemplate(template);
                    }

                    this.saveMatrixTemplates();

                    dfd.resolve();
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Add new template in Matrix templates list.
         *
         * @param template New template (JSON)
         */
        addMatrixTemplate: function(template) {
            if (this.matrixTemplates) {
                const exists = this.templateExists(template.id);

                if (exists) {
                    let updateIndex = -1;

                    $.each(this.matrixTemplates, function(index, item) {
                        if (template.id === item.id) {
                            updateIndex = index;
                        }
                    });

                    this.matrixTemplates[updateIndex] = template;
                } else {
                    this.matrixTemplates.push(template);

                    // Get and save default configuration file if a new template is added.
                    this.deviceConnection
                        .send([
                            {
                                command: 'setExtraSettings',
                                data: {
                                    filename: this.matrixFileName + template.id,
                                    string: this.matrixConfigService.getDefaultConfig()
                                }
                            }
                        ]);
                }

                this.saveMatrixTemplates();
            }
        },

        /**
         * Check if template exists.
         *
         * @param id ID of template
         * @returns {*}
         */
        templateExists: function(id) {
            const exists = $.grep(this.matrixTemplates, function(e) {
                return id === e.id;
            });

            return exists.length > 0;
        },

        /**
         * Delete template in Matrix templates list.
         *
         * @param template remove template (JSON)
         */
        deleteMatrixTemplate: function(template) {
            if (this.matrixTemplates) {
                const removeItem = $.grep(this.matrixTemplates, function(item) {
                    return item.id === template.id;
                });

                if (removeItem.length > 0) {
                    this.matrixTemplates.splice(this.matrixTemplates.indexOf(removeItem[0]), 1);
                }

                this.saveMatrixTemplates();

                this.deviceConnection
                    .send([
                        {
                            command: 'setExtraSettings',
                            data: {
                                filename: this.matrixFileName + template.id,
                                string: ''
                            }
                        },
                        {
                            command: 'setExtraSettings',
                            data: {
                                filename: this.matrixBackground + template.id,
                                string: ''
                            }
                        }
                    ])
                    .then(function() {
                        this.matrixTemplateId = template.id;
                        this.matrixService.saveStationSetup([]);
                        this.parseStations();
                    }.bind(this));
            }
        },

        /**
         * Get configurations based on the device.
         *
         * @param {String} key
         */
        get: function(key) {
            const pathList = key.split('.');
            const pathRoot = pathList[0];
            const subPath = key.replace(pathRoot + '.', '');
            const rootConfig = _.clone(configs[pathRoot]);
            let returnValue = null;
            let tmpConfig;

            if (rootConfig) {
                tmpConfig = rootConfig;
                tmpConfig = _.defaults(tmpConfig, rootConfig['defaults']);

                returnValue = this.getValueByPath(tmpConfig, subPath);
            }

            return returnValue;
        },

        /**
         * Check if the path exists in the object and return the value.
         *
         * @param {Object} object
         * @param {String} path
         */
        getValueByPath: function(object, path) {
            let value = object;

            for (let i = 0, pathList = path.split('.'), len = pathList.length; i < len; i++) {
                value = value[pathList[i]];

                if (!value) {
                    break;
                }
            }

            return value;
        },

        /**
         * Parse stations from configuration file.
         *
         */
        parseStations: function() {
            const dfd = $.Deferred();

            this.loadMatrixConfiguration().then(function(config) {
                if (config !== '') {
                    this.matrixConfig = config;

                    this.stations = [];
                    this.config = $.parseHTML(config);
                    const stations = $(this.config).find('.station');

                    _.each(stations, function(station) {
                        if (!station.getAttribute('id').startsWith('master')) {
                            const tempStation = {};

                            tempStation.id = station.getAttribute('id');
                            tempStation.serial = station.getAttribute('id').replace('sn', '');
                            tempStation.name = station.getAttribute('name');
                            tempStation.color = station.getAttribute('color');
                            tempStation.audio = station.getAttribute('audio') ? 0 : station.getAttribute('audio');
                            tempStation.group = station.getAttribute('group') ? station.getAttribute('group') : '';

                            this.stations.push(tempStation);
                        }
                    }.bind(this));
                }
                dfd.resolve();
            }.bind(this));

            return dfd.promise();
        },

        /**
         * Get specific station.
         *
         * @param {string} name
         */
        getStationConfig: function(name) {
            let tempStation = null;

            _.each(this.stations, function(station) {
                if (station.name === name) {
                    tempStation = station;
                }
            }.bind(this));

            return tempStation;
        },

        /**
         * Check if the station name has to be updated.
         *
         * @param station
         * @returns {boolean}
         */
        updateStationName: function(station) {
            if (station.serial === 'master' || station.stationName === '') {
                return false;
            }

            const compareStation = $.grep(this.stations, function(e) {
                if (station.serial === parseInt(e.id.replace('sn', ''))) {
                    return station.stationName !== e.name;
                }
            });

            return compareStation.length > 0;
        },

        /**
         * Rename and save configuration changes.
         */
        renameStations: function(stations) {
            let stationIndex = -1;
            let index = 0;

            if (!this.config) {
                return;
            }

            this.$matrixLayer = this.$matrixLayer === null ? $(document).find('#matrix-layer') : this.$matrixLayer;
            this.$matrixLayer.html(this.matrixConfig);

            _.each(this.config, function(conf) {
                if (typeof conf.getAttribute !== 'undefined') {
                    if (conf.getAttribute('id') === 'stations-layer') {
                        stationIndex = index;
                    }
                }
                index++;
            }.bind(this));

            _.each(stations, function(station) {
                const $station = $(this.config[stationIndex]).find('#sn' + station.serial);

                if ($station.get(0)) {
                    $station.get(0).setAttribute('name', station.stationName);
                    $station.find('.matrix-station-name').html(station.stationName);
                }
            }.bind(this));

            if (stations.length > 0) {
                this.$matrixLayer.find('#stations-layer').get(0).innerHTML = this.config[stationIndex].innerHTML;
                this.saveMatrixConfiguration(this.$matrixLayer.get(0).innerHTML);

                app.emit('matrix.reload-config');
            }
        },

        /**
         * Return current Matrix template id.
         *
         * @returns {template id}
         */
        getCurrentMatrixTemplateId: function() {
            return this.matrixTemplateId;
        },

        /**
         * Setup and return Matrix templates.
         *
         * @returns {Array}
         */
        setupMatrixTemplates: function() {
            const dfd = $.Deferred();
            if (this.matrixTemplates) {
                dfd.resolve(this.matrixTemplates);
            } else {
                this.loadMatrixTemplates()
                    .then(function() {
                        dfd.resolve(this.matrixTemplates);
                    }.bind(this));
            }

            return dfd.promise();
        },

        /**
         * Return current Matrix Template name.
         *
         * @returns {template name}
         */
        getCurrentMatrixTemplateName: function() {
            if (!this.matrixTemplates) {
                return '';
            }

            const template = $.grep(this.matrixTemplates, function(e) {
                return parseInt(this.matrixTemplateId) === e.id;
            }.bind(this));

            if (!template[0]) {
                template[0] = this.matrixTemplates[0];
            }

            return template[0] ? template[0].name : '';
        },

        /**
         * Return current Matrix Template share to all state.
         *
         * @returns {template name}
         */
        getShareToAllEnabled: function() {
            if (!this.matrixTemplates) {
                return false;
            }

            const template = $.grep(this.matrixTemplates, function(e) {
                return parseInt(this.matrixTemplateId) === e.id;
            }.bind(this));

            if (!template[0]) {
                template[0] = this.matrixTemplates[0];
            }

            return template[0] ? template[0].shareToAllEnabled : false;
        },

        /**
         * Get stations from current template.
         *
         * @returns {template stations} List of stations
         */
        getCurrentMatrixTemplateStations: function() {
            return this.stations;
        },

        /**
         * Get defined groups from current template.
         *
         * @param exclEmpty true/false exclude empty groups
         * @returns {template groups} List of groups
         */
        getCurrentMatrixTemplateGroups: function(exclEmpty) {
            let groups;

            if (!this.matrixTemplates) {
                return '';
            }

            const template = $.grep(this.matrixTemplates, function(e) {
                return parseInt(this.matrixTemplateId) === e.id;
            }.bind(this));

            if (!template[0]) {
                template[0] = this.matrixTemplates[0];
            }

            if (template[0] && template[0].groups) {
                groups = template[0].groups;
            } else {
                groups = [];
            }

            if (exclEmpty) {
                const tempGroups = [];

                _.each(groups, function(group) {
                    const inGroup = $.grep(this.stations, function(s) {
                        return parseInt(s.group) === group.id;
                    }.bind(this));

                    if (inGroup.length > 0) {
                        tempGroups.push(group);
                    }
                }.bind(this));

                groups = tempGroups;
            }

            return groups;
        },

        /**
         * Get name of group from ID.
         *
         * @param {int} id Group ID
         * @returns {string} Group name
         */
        getGroupNameFromId: function(id) {
            const group = $.grep(this.getCurrentMatrixTemplateGroups(), function(group) {
                return group.id === id;
            }.bind(this));

            if (group.length > 0) {
                return group[0].name;
            }
        },

        /**
         * Update old matrix configuration.
         */
        updateOldMatrixConfiguration: function($matrixLayer) {
            const stations = $matrixLayer.find('.station');

            _.each(stations, function(station) {
                // Replace icon-delete with icon-v2-control-center from station
                $(station).find('.icon.icon-delete').removeClass('icon-delete').addClass('icon-v2-control-center');
            }.bind(this));
        },

        /**
         * Update and save all Matrix configuration files because a function/feature has been added to the Matrix feature.
         * (e.g. Enable Matrix Control Mode)
         */
        updateTemplates: function(addControlMode = false) {
            $.each(this.matrixTemplates, function(index, item) {
                if (item.config) {
                    this.deviceConnection
                        .send([
                            {
                                command: 'getExtraSettings',
                                data: { filename: item.config }
                            }
                        ])
                        .then(function(config) {
                            if (config && config.string) {
                                const tempConfig = $(config.string);

                                const stations = tempConfig.find('.station');

                                // Adding Control Mode
                                _.each(stations, function(station) {
                                    if (addControlMode) {
                                        $(station).find('.btn-color').addClass('btn-control');
                                    }
                                }.bind(this));

                                const master = tempConfig.find('#master');

                                // Check if master is from new matrix (Cynap Pro firmware) or old matrix, thus applying changes if needed
                                // Check for master is easiest way to check matrix version
                                if (master && !master.hasClass('master')) {
                                    master.addClass('master');

                                    const connections = tempConfig.find('> g:not(.station)');

                                    _.each(connections, function(connection) {
                                        $(connection)
                                            .attr('class', $(connection).attr('id') + ' master')
                                            .attr('data-master', 'master')
                                            .attr('master-color', master.attr('color'));
                                    }.bind(this));

                                    const updatedConfig = $('<div></div>').append(tempConfig).html();

                                    this.deviceConnection
                                        .send([
                                            {
                                                command: 'setExtraSettings',
                                                data: {
                                                    filename: item.config,
                                                    string: updatedConfig
                                                }
                                            }
                                        ]);
                                }
                            }
                        }.bind(this));
                }
            }.bind(this));
        },

        /**
         * Check if a new Matrix configuration files (matrixConfigs 0 - 4) should be loaded / setup in the backend.
         *
         */
        loadMatrixTemplate: function() {
            const dfd = $.Deferred();

            this.frontendSettings
                .getSettings([
                    'matrixPresentationId',
                    'matrixCurrentTemplate',
                    'matrixIgnoreTemplates'
                ])
                .then(function(presentationId, templateId, ignoreTemplates) {
                    this.ignoreMatrixTemplates = ignoreTemplates;

                    if (this.ignoreMatrixTemplates) {
                        this.deviceConnection
                            .send('getMasterActiveTemplate')
                            .then(function(template) {
                                if (this.matrixTemplateId === template.templateId) {
                                    dfd.resolve(false);

                                    return;
                                }

                                this.matrixTemplateId = template.templateId;
                                dfd.resolve(true);
                            }.bind(this));
                    } else {
                        this.deviceService.updatePresentationId()
                            .then(function() {
                                if (presentationId !== this.deviceService.presentationId || this.chooseTemplate) {
                                    dfd.resolve(true);
                                } else {
                                    this.matrixTemplateId = templateId;
                                    dfd.resolve(false);
                                }
                            }.bind(this));
                    }
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Update the presentation ID for Matrix.
         */
        updateMatrixPresentationId: function() {
            this.frontendSettings.updateSetting({
                tag: 'matrixPresentationId',
                value: this.deviceService.presentationId
            });

            this.frontendSettings.saveSettings();
        },

        /**
         * Save background file to storage and save in a Matrix Template.
         *
         * @param id Template ID
         * @param file Uploaded background file
         */
        setMatrixTemplateBackground: function(id, file) {
            if (file) {
                const reader = new FileReader();

                reader.onload = function(event) {
                    this.deviceConnection
                        .send([
                            {
                                command: 'setExtraSettingsFile',
                                data: {
                                    filename: this.matrixBackground + id,
                                    file: event.target.result
                                }
                            }
                        ]);
                }.bind(this);

                reader.readAsArrayBuffer(file);
            } else {
                this.deviceConnection
                    .send([
                        {
                            command: 'setExtraSettingsFile',
                            data: {
                                filename: this.matrixBackground + id,
                                file: undefined
                            }
                        }
                    ]);
            }
        },

        /**
         * Load Matrix background from storage and create object URL from image data.
         *
         * @returns {*}
         */
        loadMatrixBackground: function() {
            const dfd = $.Deferred();

            this.deviceConnection
                .send([
                    {
                        command: 'getExtraSettingsFile',
                        data: {
                            filename: this.matrixBackground + this.getCurrentMatrixTemplateId()
                        }
                    }
                ])
                .then(function(data) {
                    if (data) {
                        const blob = new Blob([data.file], {
                            type: 'image/jpeg'
                        });

                        dfd.resolve(URL.createObjectURL(blob));
                    } else {
                        dfd.resolve('');
                    }
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Get Matrix control mode
         *
         * @return {boolean} true = enabled, false = disabled
         */
        getMatrixControlMode: function() {
            const dfd = $.Deferred();

            if (this.isDualProjection) {
                dfd.resolve(false);
            } else {
                this.frontendSettings
                    .getSettings([
                        'matrixControlMode'
                    ])
                    .then(function(mode) {
                        dfd.resolve(mode);
                    }.bind(this));
            }

            return dfd.promise();
        },

        /**
         * Get matrix command buttons enabled
         *
         * @return {boolean} true = enabled, false = disabled
         */
        getMatrixCommandButtonsSettings: function() {
            const dfd = $.Deferred();

            this.frontendSettings
                .getSettings([
                    'matrixCommandButtons',
                    'matrixCommandButtonsHdmi2'
                ])
                .then(function(enabled, showHdmi2) {
                    dfd.resolve({ enabled, showHdmi2 });
                }.bind(this));

            return dfd.promise();
        }
    };
});
