'use strict';

var app = require('../../../app');
var ldapLoginTpl = require('./ldap-login.hbs');
var FormActionView = require('../../settings/form-action-view');
var Platform = require('../../../../platform/platform');
var i18n = require('i18next');
var users = require('./../../../../rbac/users.json');

var states = {
    selectUser: 'select-user',
    signin: 'signin'
};

app.component('LdapLogin', {
    extend: 'LoginAbstract',
    template: ldapLoginTpl,

    initialize: function() {
        this.user = null;
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.authService = this.getService('AuthenticationService');
        this.dataStorage = this.getService('DataStorage');

        this.loaded = false;
        this.state = this.createStateMachine({
            state: states.selectUser,
            states: states
        });
        this.loginIsRunning = false;

        this.emit('ui.block.events');
        this.remote.permitLimitedAccess(true);
    },

    serialize: function() {
        return {
            isRemote: !Platform.checks.isCbox
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.storeLdapSelectors();
        this.initForm();
        this.bindDOMEvents();
        this.initFormAction();

        this.openRole();

        this.toggleLoader(false);
        this.toggleForgotPassword();
        this.toggleLdapActive('ldap');
    },

    storeLdapSelectors: function() {
        this.$loginValidateContainerUser = this.$el.find('#login-validate-container-user');
        this.$loginValidateContainerPwd = this.$el.find('#login-validate-container-pwd');

        this.$userName = this.$el.find('#login-username');
        this.$switchUserName = this.$el.find('#login-switch-username');
        this.$userCtrl = this.$el.find('#user');
        this.$switchEl = this.$el.find('.login-switch-user');

        this.$loginIcon = this.$el.find('.current-role');
        this.$loginSwitchIcon = this.$switchEl.find('.login-icon');

        this.$loginFormContent = this.$el.find('#login-form-content-inner');
        this.$loginFormInfo = this.$el.find('#login-form-info');

        this.$loaderContainer = this.$el.find('.ldap-login-loader');
        this.$loader = this.$el.find('.loader');
    },

    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }

        if (!this.options.configs.cancelLoginHandler) {
            this.actionView.disableCancelButton();
        } else {
            this.actionView.enableCancelButton();
        }
    },

    onSignInHandler: function() {
        if (this.user === 'ldap' && !Platform.checks.isCbox && this.authService.getIsLdapAuthenticated()) {
            this.toggleLdapActive(this.user);

            return;
        }

        var user =  this.form.get('user').getValue();
        var password = this.form.get('password').getValue();
        var adminPin = this.form.get('adminPin').getValue();

        this.form.get('password').$el.blur();
        this.form.get('user').$el.blur();

        if (!this.loginIsRunning && this.form.validate()) {
            this.loginIsRunning = true;

            // If type is pin change password to number.
            if (this.isPin) {
                password = parseInt(password);

                if (isNaN(password)) {
                    this.loginIsRunning = false;
                    this.remote.unblock();
                    this.showError();
                }
            }
            if (this.user === 'ldap') {
                this.toggleLoader(true);

                this.authService.ldapLogin(user, password)
                    .then(function() {
                        app.emit('modal.close', {
                            id: 'ldap-login'
                        });
                        this.toggleLoader(false);
                        this.authService.storeAccessToken();
                        this.remote.unblock();
                    }.bind(this))
                    .fail(function() {
                        this.loginIsRunning = false;
                        this.remote.unblock();
                        this.remote.focus(this.form.get('user').$el.get(0));
                        this.toggleLoader(false);
                        this.showError();
                    }.bind(this));
            } else {
                this.authService.login(this.user, password, adminPin)
                    .then(function() {
                        this.emit('modal.close');
                        this.emit('rbac.user.changed', {
                            key: this.user
                        });

                        this.options.configs.loginHandler(this.user);
                        this.authService.storeAccessToken();
                        this.remote.unblock();
                    }.bind(this))
                    .fail(function() {
                        this.loginIsRunning = false;
                        this.remote.unblock();
                        this.remote.focus(this.form.get('password').$el.get(0));
                        this.showError();
                    }.bind(this));
            }
        }
    },

    /**
     * Clear form values (password, etc.)
     */
    clearForm: function() {
        if (this.form.get('password')) {
            this.form.get('password').setValue('');
        }

        if (this.form.get('adminPin')) {
            this.form.get('adminPin').setValue('');
        }

        if (this.form.get('user')) {
            this.form.get('user').setValue('');
        }
    },

    /**
     * Show or hide the loader.
     *
     * @param user
     */
    toggleLoader: function(show) {
        if (show) {
            this.$loaderContainer.show();
            this.actionView.disableSubmitButton();
            this.actionView.disableCancelButton();

            // If the login is fast, do not show the loader (avoid "blinking")
            setTimeout(function() {
                this.$loader.show();
            }.bind(this), 300);
        } else {
            this.$loaderContainer.hide();
            this.checkActionForm();

            setTimeout(function() {
                this.$loader.hide();
            }.bind(this), 300);
        }
    },

    /**
     * Check if LDAP is already authenticated or not.
     * If authenticated locally, it's not possible to login again as moderator remotely.
     * Currently only one LDAP user is allowed.
     *
     * @param user Current selected user.
     */
    toggleLdapActive: function(user) {
        if (user === 'ldap' && !Platform.checks.isCbox && this.authService.getIsLdapAuthenticated()) {
            this.$loginFormContent.hide();
            this.$loginFormInfo.show();
            this.actionView.disableSubmitButton();
        } else {
            this.$loginFormContent.show();
            this.$loginFormInfo.hide();
            this.checkActionForm();
        }
    },

    /**
     * @method setLoginRole
     * @param {string} user
     */
    setLoginRole: function(user) {
        var switchUserName = users.admin.key;

        if (user.toLowerCase() === users.admin.key) {
            switchUserName = this.switchUserRoleToAdmin();
        } else if (user.toLocaleLowerCase() === 'ldap') {
            switchUserName = this.switchUserRoleToUser();
        }

        // Set login user.
        this.user = user;
        switch (user) {
            case users.admin.key:
                this.$userName.text(i18n.t('login.admin'));
                this.$passwordError.text(i18n.t('login.wrong_password'));
                this.remote.focus(this.form.get('password').$el.get(0));
                break;
            case 'ldap':
                this.$userName.text(i18n.t('login.ldap'));
                this.$passwordError.text(i18n.t('error_messages.invalid_user_password'));
                this.remote.focus(this.form.get('user').$el.get(0));
                break;
        }

        if (user === users.user.key) {
            this.checkGuestPasswordType();
        }

        this.$loginSwitchIcon.attr('data-user', user.toLowerCase());

        // Set switch login user.
        this.$switchEl.data('user', switchUserName);
    },

    /**
     * This method is called if the user wants to log in as moderator.
     */
    switchUserRoleToUser: function() {
        var switchUserName = users.admin.key;

        this.$switchUserName
            .find('.icon-settings-1')
            .removeClass('hidden');

        this.$switchUserName
            .find('.icon-user')
            .addClass('hidden');

        this.$loginIcon.find('.icon-settings-1').addClass('hidden');
        this.$loginIcon.find('.icon-user').removeClass('hidden');

        this.$userCtrl.show();

        this.$adminPinField.addClass('hidden');

        return switchUserName;
    },

    /**
     * This method is called if the user wants to log in as admin.
     */
    switchUserRoleToAdmin: function() {
        var switchUserName = 'ldap';

        this.$switchUserName
            .find('.icon-user')
            .removeClass('hidden');

        this.$switchUserName
            .find('.icon-settings-1')
            .addClass('hidden');

        this.$userCtrl.hide();
        this.$loginIcon.find('.icon-settings-1').removeClass('hidden');
        this.$loginIcon.find('.icon-user').addClass('hidden');

        this.setShowAdminPin();

        return switchUserName;
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#login-form'),
            validationContainer: '.input-group',
            submit: true
        });
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                submitTitleKey: 'action_button.login',
                cancelTitleKey: 'action_button.cancel',
                onSubmit: this.onSignInHandler.bind(this),
                onCancel: this.onLoginCancelHandler.bind(this),
                autoClose: false,
                navArea: false
            });
        }

        this.actionView.render();

        if (!this.options.configs.cancelLoginHandler) {
            this.actionView.disableCancelButton();
        }
    },

    destroy: function() {
        this.remote.permitLimitedAccess(false);
        this.emit('ui.unblock.events');
    }
});
