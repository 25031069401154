'use strict';

var app = require('../../../app');
var tplMapping = {
    freezeTpl: require('./freeze.hbs'),
    defaultTpl: require('./notification.hbs')
};

app.component('Notification', {
    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    /**
     * @method initialize
     */
    initialize: function() {
        var tplName = this.options.configs.tplName || 'defaultTpl';
        this.template = tplMapping[tplName];
    },

    /**
     * @method serialize
     */
    serialize: function() {
        return this.options.configs;
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();

        if (!this.options.configs.messageKey) {
            this.$el
                .find('.notification-modal')
                .append(this.$(this.options.tpl));
        }

        this.startAutoHide();
    },

    /**
     * @method storeSelectors
     */
    storeSelectors: function() {
        this.$message = this.$el.find('#notification-message');
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        this.on('notification-modal.update', this.onUpdate.bind(this));
    },

    /**
     * @method onUpdate
     * @param options
     */
    onUpdate: function(options) {
        this.$message.text(options.message);
    },

    /**
     * @method startAutoHide
     */
    startAutoHide: function() {
        setTimeout(function() {
            this.emit('modal.close', {
                id: this.options.configs.id
            });
        }.bind(this), this.options.configs.hold || 1500);
    }
});

