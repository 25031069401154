'use strict';

var app = require('../app');
var StateMachine = require('./../state-machine');
var $ = require('jquery');

/**
 * Mute states
 */
var muteState = {
    none: 'none',
    on: 'on',
    off: 'off'
};

/**
 * Mute status
 */
var micWorkingState = {
    'disabled': 'disabled',
    'working': 'working',
    'quiet': 'quiet'
};

/**
 * Visible states
 */
var visibleState = {
    visible: 'visible',
    hidden: 'hidden'
};

/**
 * @type {object}
 */
var muteMapping = {
    0: 'off',
    1: 'on'
};

/**
 * @type {object}
 */
var mappingCmd = {
    off: 0,
    on: 1,
    toggle: 2
};

var lines = {
    LEFT: 'left',
    RIGHT: 'right'
};

var mixerMeterChannels = {
    left: 0,
    right: 0
};

app.service('VolumeService', function(app) {
    return {
        /**
         * @method initialize
         */
        initialize: function() {
            this.customUiSettings = app.getService('CustomUiSettings');
            this.clientIsControlingVolume = false;
            this.clientIsControlingVolumeTimeout = null;
            this.audioEnabled = true;
            this.mute = new StateMachine({
                context: this,
                state: muteState.off,
                states: muteState
            });

            this.micMute = new StateMachine({
                context: this,
                state: muteState.off,
                states: muteState
            });

            this.micWorking = new StateMachine({
                context: this,
                state: micWorkingState.disabled,
                states: micWorkingState
            });

            this.volumeModalState = new StateMachine({
                context: this,
                state: visibleState.hidden,
                states: visibleState
            });

            this.micMuteOptions = {};

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;

                    this.updateHandler();
                    this.loadVolume();
                    this.bindEvents();
                }.bind(this));

            this.addStateTransitions();
        },

        /**
         * @method bindEvents
         */
        bindEvents: function() {
            app.on('main-loop.update', this.updateHandler.bind(this));
            app.on('main-loop.update.volume', this.updateHandler.bind(this));
            app.on('main-loop.update.mixer', this.updateMixerMeter.bind(this));
            app.on('remote.volume-increase.keydown', this.volumeIncreaseHandler.bind(this));
            app.on('remote.volume-decrease.keydown', this.volumeDecreaseHandler.bind(this));
            app.on('remote.mic-mute.keyup', this.toggleMicMute.bind(this));
            app.on('modal.closed', this.onModalClosed.bind(this));
            app.on('remote.volume-open.keydown', this.openModal.bind(this));
            app.on('status-bar.initialized', this.appendWidget.bind(this));
        },

        appendWidget: function() {
            app.emit('status-widget.item.append', {
                id: 'mic-mute',
                accessKey: 'Volume',
                options: {
                    icon: 'icon-v2-mic',
                    clickable: false,
                    state: 'mic'
                }
            });

            this.updateMicStatusWidgetHandler();
        },

        /**
         * @method loadVolume
         */
        loadVolume: function() {
            var dfd = $.Deferred();

            if (!this.clientIsControlingVolume) {
                this.deviceConnection
                    .send('getMasterVolume')
                    .then(function(data) {
                        if (!data.volume && data.volume !== 0) {
                            return;
                        }

                        this.volume = data.volume;

                        app.emit('volume.master.changed', {
                            volume: this.volume
                        });

                        dfd.resolve();
                    }.bind(this));
            } else {
                dfd.resolve();
            }

            return dfd.promise();
        },

        /**
         * @method volumeIncreaseHandler
         */
        volumeIncreaseHandler: function() {
            if (this.volume < 100) {
                this.volume += 5;
            }

            this.setVolume(this.volume);

            app.emit('volume.master.changed', {
                volume: this.volume
            });

            this.clientIsControling();
            this.openModal();
        },

        /**
         * @method volumeDecreaseHandler
         */
        volumeDecreaseHandler: function() {
            if (this.volume > 0) {
                this.volume -= 5;
            }

            this.setVolume(this.volume);

            app.emit('volume.master.changed', {
                volume: this.volume
            });

            this.clientIsControling();
            this.openModal();
        },

        /**
         * We must block the update handler for a smoother control
         * @method clientIsControling
         */
        clientIsControling: function() {
            this.clientIsControlingVolume = true;

            clearTimeout(this.clientIsControlingVolumeTimeout);
            this.clientIsControlingVolumeTimeout = setTimeout(function() {
                this.clientIsControlingVolume = false;
            }.bind(this), 1000);
        },

        /**
         * @method updateHandler
         */
        updateHandler: function() {
            app.getService('CustomUiSettings')
                .getSettings(['audioEnabled'])
                .then(function(audioEnabled) {
                    this.audioEnabled = audioEnabled;
                });

            return this.deviceConnection
                .send([
                    'getMicMute',
                    'getMicStatus',
                    'getMasterMute'
                ])
                .then(function(micMute, micStatus, masterMute) {
                    this.updateMasterMute(masterMute.mute);
                    this.updateMicMute(micMute.mute, micStatus.status);
                }.bind(this));
        },

        isAudioEnabled: function() {
            return this.audioEnabled;
        },

        /**
         * @method updateMixerMeter
         */
        updateMixerMeter: function() {
            this.deviceConnection
                .send([
                    'getMixerMeter'
                ])
                .then(function(mixerMeter) {
                    mixerMeterChannels = mixerMeter;
                }.bind(this));
        },

        /**
         * @method updateMicStatusWidgetHandler
         */
        updateMicStatusWidgetHandler: function() {
            app.getService('FrameBoxService')
                .hasFrameboxByName(['webconference'])
                .then(function(data) {
                    this.updateMicStatusWidget(data.hasFramebox);
                }.bind(this));
        },

        /**
         * @method updateMicStatusWidget
         */
        updateMicStatusWidget: function(hasWebconference) {
            if (this.micMute.getState() === muteState['on'] && this.micWorking.getState() !== micWorkingState['disabled']) {
                this.micMuteOptions = {
                    icon: 'icon-v3-mute-mic',
                    state: 'mute',
                    visible: true
                };
            } else if (this.micWorking.getState() === micWorkingState['disabled']) {
                var visible = hasWebconference;

                if (this.micMute.getState() === muteState['on']) {
                    this.micMuteOptions = {
                        icon: 'icon-v3-mute-mic',
                        state: 'disabled-mic',
                        visible: visible
                    };
                } else {
                    this.micMuteOptions = {
                        icon: 'icon-v2-mic',
                        state: 'disabled-mic',
                        visible: visible
                    };
                }
            } else if (this.micMute.getState() === muteState['off'] && this.micWorking.getState() === micWorkingState['working']) {
                this.micMuteOptions = {
                    icon: 'icon-v2-mic',
                    state: 'mic',
                    visible: true
                };
            } else if (this.micMute.getState() === muteState['off'] && this.micWorking.getState() === micWorkingState['quiet']) {
                this.micMuteOptions = {
                    icon: 'icon-v2-mic',
                    state: 'quiet',
                    visible: true
                };
            }

            if (this.micMuteOptions.icon && this.micMuteOptions.state) {
                app.emit('status-widget.item.update', {
                    id: 'mic-mute',
                    options: this.micMuteOptions
                });
            }
        },

        /**
         * @method updateMasterMute
         * @param {string} mute
         */
        updateMasterMute: function(mute) {
            var state = muteMapping[mute];

            if (this.mute.getState() !== muteState[state]) {
                this.mute.changeState(muteState[state]);
            }
        },

        /**
         * @method updateMicMute
         * @param {string} mute
         */
        updateMicMute: function(mute, working) {
            var stateMute = muteMapping[mute];
            var stateWorking = micWorkingState[working];

            if (this.micMute.getState() !== muteState[stateMute]) {
                this.micMute.changeState(muteState[stateMute]);
                this.updateMicStatusWidgetHandler();
            }

            if (this.micWorking.getState() !== stateWorking) {
                this.micWorking.changeState(stateWorking);
                this.updateMicStatusWidgetHandler();
            }
        },

        /**
         * @method toggleMute
         */
        toggleMute: function() {
            this.setMute(mappingCmd.toggle)
                .then(function() {
                    this.updateHandler();
                }.bind(this));
        },

        /**
         * @method toggleMicMute
         */
        toggleMicMute: function() {
            this.deviceConnection
                .send('setMicMute', {
                    mute: 'toggle'
                }).then(function() {
                    this.updateHandler();
                }.bind(this));
        },

        /**
         * @method setMute
         * @param mute
         */
        setMute: function(mute) {
            app.emit('main-loop.fast.start', {
                id: 'volume'
            });

            return this.deviceConnection
                .send('setMasterMute', {
                    mute: mute
                });
        },

        /**
         * @method setVolume
         * @param {number} volume
         */
        setVolume: function(volume) {
            this.volume = parseInt(volume);

            this.deviceConnection
                .send('setMasterVolume', {
                    volume: volume
                });

            if (volume <= 0 && this.mute.getState() === muteState.off) {
                this.setMute(mappingCmd.on);
                this.mute.changeState(muteState.on);
            } else if (volume > 0 && this.mute.getState() === muteState.on) {
                this.setMute(mappingCmd.off);
                this.mute.changeState(muteState.off);
            }
        },

        /**
         * @method getVolume
         * @returns {number}
         */
        getVolume: function() {
            return this.volume;
        },

        /**
         * @param {string} line
         * @returns {number}
         */
        getVolumeLevel: function getVolumeLevel(line) {
            if (lines.LEFT === line) {
                return mixerMeterChannels.left;
            } else if (lines.RIGHT === line) {
                return mixerMeterChannels.right;
            }

            return mixerMeterChannels;
        },

        /**
         * @method openModal
         */
        openModal: function() {
            this.volumeModalState.changeState(visibleState.visible);
        },

        /**
         * @method onModalClosed
         * @param {object} options
         */
        onModalClosed: function(options) {
            if (options.id === 'volume') {
                this.volumeModalState.changeState(visibleState.hidden);
            }
        },

        /**
         * @method addStateTransitions
         */
        addStateTransitions: function() {
            this.mute.addTransitions({
                '> off': function() {
                    app.emit('status-widget.item.remove', {
                        id: 'mute'
                    });

                    app.emit('mute.state.changed', {
                        state: muteState.off
                    });
                },

                '> on': function() {
                    app.emit('status-widget.item.append', {
                        id: 'mute',
                        accessKey: 'Volume',
                        options: {
                            icon: 'icon-volume-mute',
                            clickable: false,
                            state: 'mute'
                        }
                    });

                    app.emit('mute.state.changed', {
                        state: muteState.on
                    });
                }
            });

            this.micMute.addTransitions({
                '> off': function() {
                    app.emit('mic-mute.state.changed', {
                        state: muteState.off
                    });
                },

                '> on': function() {
                    app.emit('mic-mute.state.changed', {
                        state: muteState.on
                    });
                }
            });

            this.volumeModalState.addTransitions({
                '> visible': function() {
                    this.customUiSettings
                        .getSettings(['audioEnabled'])
                        .then(function(audioEnabled) {
                            if (audioEnabled) {
                                app.emit('modal.open', {
                                    id: 'volume'
                                });
                            }
                        }.bind(this));
                }
            });
        },

        startUpdate: function() {
            app.emit('main-loop.fast.start', {
                id: 'volume',
                maxCalls: 0
            });
        },

        stopUpdate: function() {
            app.emit('main-loop.fast.stop', {
                id: 'volume'
            });
        }
    };
});
