const template = require('./boxed-control.html');

module.exports = {
    template,
    props: {
        small: {
            type: Boolean,
            default: false,
            required: false
        },

        title: {
            type: String,
            required: false
        },

        icon: {
            type: String,
            required: false
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data: function() {
        return {};
    },

    methods: {
        iconClick: function() {
            this.$emit('icon-click');
        }
    }
};
