'use strict';

var _ = require('lodash');
var $ = require('jquery');
var app = require('../app');
var platform = require('../../platform/platform');

var CONNECTION_STATES = {
    connected: 'connected',
    disconnected: 'disconnected',
    oauth: 'oauth',
    failed: 'failed',
    synced: 'synced',
    notResponding: 'notResponding'
};

app.service('CloudAuthenticationService', function() {
    var defaultProviderModel = {
        id: null,
        name: null,
        type: 'cloud',
        connection: CONNECTION_STATES.disconnected,
        oauthUrl: null
    };

    return {
        /**
         * @method initialize
         */
        initialize: function() {
            this.providers = [];
            this.providerStates = {};
            this.byId = {};
            this.configs = app.getService('ConfigsService');
            this.dataStorage = app.getService('DataStorage');
            this.cloudLogin = false;
            this.cloudLoginService = null;
            this.cloudLoginServiceTitle = '';
            this.anyServiceConnected = false;
            this.openOverlayAfterConnection = false;

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;

                    this.fetchProviders();
                    this.bindEvents();
                }.bind(this));
        },

        /**
         * @method bindEvents
         */
        bindEvents: function() {
            app.on('main-loop.update', this.fetchProviders.bind(this));
            app.on('main-loop.update.cloud', this.fetchProviders.bind(this));
            app.on('cloud-login.show', this.showCloudLogin.bind(this));
        },

        /**
         * @method createProvider
         * @param {Object} options
         */
        createProvider: function(options) {
            var providerModel = _.defaults(options, defaultProviderModel);

            return providerModel;
        },

        /**
         * @method addProvider
         * @param {Object} providerModel
         */
        addProvider: function(providerModel) {
            this.byId[providerModel.id] = providerModel;
            this.providers.push(providerModel);
        },

        /**
         * Return a provider by id
         * @method getProvider
         * @param {String} id
         */
        getProvider: function(id) {
            return this.byId[id] || null;
        },

        /**
         * Get the available cloud services
         * and update their connection state
         * @method fetchProviders
         * @param {Function} callback
         */
        fetchProviders: function(callback) {
            if (!!this.cloudLogin && !!this.cloudLoginService) {
                this.cloudLogin = false;

                app.emit('modal.open', {
                    id: 'cloudLogin',
                    service: this.cloudLoginService,
                    name: this.cloudLoginServiceTitle,
                    role: {
                        name: 'CloudLogin',
                        key: 'show'
                    }
                });

                this.cloudLoginService = null;
                this.cloudLoginServiceTitle = '';
            }

            this.deviceConnection
                .send('getCloudStatus')
                .then(function(data) {
                    this.updateProviders(data);
                    this.checkCloudState();
                    (callback || $.noop)();
                }.bind(this));
        },

        /**
         * Update the internal provider references
         * @method updateProviders
         * @param {Object} response
         */
        updateProviders: function(response) {
            var providers = response.cloudStatus;

            providers.forEach(function(provider) {
                var providerModel = this.getProvider(provider.name);
                var connectionState = provider.state;

                if (providerModel) {
                    providerModel.connection = connectionState;
                } else {
                    providerModel = this.createProvider({
                        id: provider.name,
                        name: provider.name,
                        connection: connectionState,
                        oauthUrl: this.configs.get('cynap.cloudProviderOauthUrl.' + provider.name)
                    });

                    this.addProvider(providerModel);
                }
            }.bind(this));

            return this.providers;
        },

        /**
         * @method getAnyServiceConnected
         */
        getAnyServiceConnected: function() {
            return this.anyServiceConnected;
        },

        /**
         * @method checkCloudState
         */
        checkCloudState: function() {
            var anyCloudIsConnected = false;

            _.forEach(this.providers, function(provider) {
                var state = CONNECTION_STATES[provider.connection];

                if (!this.providerStates[provider.id] || this.providerStates[provider.id] !== state) {
                    if (this.openOverlayAfterConnection
                        && (state === CONNECTION_STATES.connected || state === CONNECTION_STATES.synced)
                    ) {
                        app.emit('file-browser.open', provider.id);

                        this.openOverlayAfterConnection = false;
                    }

                    if (this.providerStates[provider.id] !== state) {
                        app.emit('cloud-provider.changed', {
                            state: state,
                            id: provider.id
                        });

                        this.providerStates[provider.id] = state;
                    }

                    if (state === CONNECTION_STATES.synced && !platform.checks.isCboxAux) {
                        app.emit('status-widget.item.append', {
                            id: 'cloud-connection',
                            accessKey: 'CloudLogin',
                            options: {
                                icon: 'icon-v2-cloud',
                                clickable: false,
                                state: CONNECTION_STATES.synced
                            }
                        });
                    }

                    /* RELEASE-1130 - If a new presentation was initiated from a different browser,
                       we need to remove the saved users from local storage */
                    if (this.providerStates[provider.id] === CONNECTION_STATES.disconnected && provider.id === 'jianguoyun') {
                        this.dataStorage.remove('jianguoyunuser');
                    } else if (this.providerStates[provider.id] === CONNECTION_STATES.disconnected && provider.id === 'webdav') {
                        this.dataStorage.remove('webdavuser');
                    }
                }

                if (this.providerStates[provider.id] === CONNECTION_STATES.synced) {
                    anyCloudIsConnected = true;
                    this.anyServiceConnected = true;
                }
            }.bind(this));

            if (!anyCloudIsConnected) {
                app.emit('status-widget.item.remove', {
                    id: 'cloud-connection'
                });
            }
        },

        /**
         * @method connectProvider
         * @param {String} providerId
         */
        connectProvider: function(providerId) {
            if ('webdav' !== providerId && 'jianguoyun' !== providerId) {
                this.openOverlayAfterConnection = true;
            }

            return this.setConnectionState(providerId, true);
        },

        /**
         * @method disconnectProvider
         * @param {String} providerId
         */
        disconnectProvider: function(providerId) {
            app.emit('main-loop.fast.start', {
                id: 'cloud'
            });

            this.setConnectionState(providerId, false);
        },

        /**
         * @method setConnectionState
         * @param {String} providerId
         * @param {Boolean} connectionState
         */
        setConnectionState: function(providerId, connectionState) {
            app.emit('main-loop.fast.start', {
                id: 'osd'
            });

            return this.deviceConnection
                .send('setCloudConnection', {
                    cloudService: providerId,
                    connectionStatus: connectionState
                });
        },

        /**
         * @method showCloudLogin
         */
        showCloudLogin: function(data) {
            if (!this.getProvider(data.service).oauthUrl) {
                this.cloudLogin = true;
                this.cloudLoginService = data.service;
                this.cloudLoginServiceTitle = data.title;
            }
        },

        /**
         * Set cloud data.
         *
         * @param {String} username
         * @param {String} password
         * @param {String} provider
         */
        setCloudData: function(username, password, provider) {
            this.getProvider(provider).loginIsRunning = true; // RELEASE-1706

            return this.deviceConnection
                .send('setWebdavCredentials', {
                    service: provider,
                    user: username,
                    password: password
                }).then(function() {
                    this.setConnectionState(provider, true);

                    app.emit('file-browser.open', provider);
                }.bind(this));
        },

        /**
         * @method fetchProviderFilesList
         * @param {String} providerId
         * @param {Function} onListFetch
         * @param {Function} onListReady
         */
        fetchProviderFilesList: function(providerId, providerTitle, onListFetch, onListReady) {
            var provider = this.getProvider(providerId);

            if ((provider.connection === CONNECTION_STATES.disconnected || provider.connection === CONNECTION_STATES.failed) && !provider.oauthUrl) {
                app.emit('modal.open', {
                    id: 'cloudLogin',
                    service: provider.id,
                    name: providerTitle,
                    role: {
                        name: 'CloudLogin',
                        key: 'show'
                    }
                });
            } else {
                this.fetchProviders(function() {
                    if (provider.connection !== CONNECTION_STATES.synced) {
                        onListFetch(provider);
                    } else if (onListReady) {
                        onListReady(provider);
                    }
                }.bind(this));
            }
        }
    };
});
