'use strict';

var app = require('../app');

var defaultOptions = {
    'networkDeviceDiscovery': 'supported',
    'showStatusbar': true,
    'streamingInterface': 'lan',
    'streamingMode': 'multicast',
    'airplayPinHdmi2': true,
    'userPinHdmi2': true,
    'showNotificationHdmi2': false,
    'usePreferredUsbMic': false,
    'qrCodeSource': '',
    'activeSessionPin': '',
    'activeSessionId': 0,
    'matrixCurrentTemplate': -1,
    'matrixAutoStart': 0,
    'matrixPresentationId': -1,
    'matrixControlMode': false,
    'matrixCommandButtons': false,
    'matrixCommandButtonsHdmi2': true,
    'matrixIgnoreTemplates': false,
    'statusBarSettings': {
        lan1: true,
        lan2: null,
        wlan: true,
        ssid: true
    },
    'vMeetingDarkUi': false,
    'vMeetingWallpaper': 'wvlight',
    'showVMeetingStatusBar': true,
    'wallpaperName': 'default',
    'wallpaperType': 'default',
    'splashScreenVisible': false,
    'splashScreenEnabled': null,
    'splashScreenShowOnRemote': false,
    'splashScreenShowQrCode': true,
    'splashScreenDisplayWlan': true,
    'splashScreenDisplayWlanType': 'wlan',
    'splashScreenDisplayWlanSSID': '',
    'splashScreenDisplayWlanPassword': '',
    'splashScreenDisplayWlanEncryption': 'none',
    'splashScreenManuallyTimeout': 10,
    'customCommandButtons': {
        button1: {
            name: 'C1',
            commandIndex: '-1'
        },
        button2: {
            name: 'C2',
            commandIndex: '-1'
        },
        button3: {
            name: 'C3',
            commandIndex: '-1'
        },
        button4: {
            name: 'C4',
            commandIndex: '-1'
        },
        button5: {
            name: 'C5',
            commandIndex: '-1'
        },
        button6: {
            name: 'C6',
            commandIndex: '-1'
        },
        button7: {
            name: 'C7',
            commandIndex: '-1'
        },
        button8: {
            name: 'C8',
            commandIndex: '-1'
        }
    },
    'mirrorPinSize': 'small',
    'mirrorPinMainScreen': true,
    'showPinProjections': true,
    'showPinControlScreen': true,
    'webrtcControlBarPosition': 'bottom',
    'avoidInfinityMirrorVApp': true,
    'autoShowAnnotationMenu': true,
    'viewerResolution': '720p'
};

app.service('FrontendSettings', function(app) {
    return {
        /**
         * @method initialize
         */
        initialize: function() {
            this.configs = app.getService('ConfigsService');
            this.JSONService = app.getService('JSONService');

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.setDefaultSettings();
                    this.getSettings();

                    // Send event delayed to get rid of timing issues.
                    setTimeout(function() {
                        app.emit('frontend-settings.initialized');
                    }, 500);
                }.bind(this));
        },

        /**
         * FIXME: This will be called 4 Times every second.
         *
         * @method getSetting
         * @returns {string}
         */
        getSetting: function(settingName) {
            return this.deviceConnection
                .send([
                    {
                        command: 'getExtraSettings',
                        data: {
                            filename: this.configs.get('cynap.settingsFilename')
                        }
                    }
                ])
                .then(function(extraSettings) {
                    if (extraSettings) {
                        this.extraSettings = this.JSONService
                            .parse(extraSettings.string, {
                                'Settings': []
                            });
                    }

                    if (settingName) {
                        return this.findSetting(settingName);
                    }

                    return this.extraSettings;
                }.bind(this));
        },

        /**
         * Wait for multiple settings to respond
         * @method getSettings
         * @param {Array} settings
         */
        getSettings: function(settings) {
            var promises = [];
            var cmd;

            if (settings && typeof (settings) !== 'string') {
                settings.forEach(function(setting) {
                    cmd = this.getSetting(setting);
                    promises.push(cmd);
                }.bind(this));
            } else {
                cmd = this.getSetting(settings);
                promises.push(cmd);
            }

            return app.$.when.apply(app.$, promises);
        },

        /**
         * @method saveSettings
         */
        saveSettings: function() {
            return this.deviceConnection
                .send([
                    {
                        command: 'setExtraSettings',
                        data: {
                            filename: this.configs.get('cynap.settingsFilename'),
                            string: JSON.stringify(this.extraSettings)
                        }
                    }
                ]);
        },

        /**
         * @method findSetting
         * @name settingsName
         */
        findSetting: function(name) {
            for (var i = 0; i < this.extraSettings.Settings.length; i++) {
                var obj = this.extraSettings.Settings[i];

                for (var key in obj) {
                    if (key === name) {
                        return obj[key];
                    }
                }
            }

            return this.setDefaultSetting(name);
        },

        /**
         * @method updateSetting
         * @data: name, value
         */
        updateSetting: function(data) {
            var attrExists = false;

            for (var i = 0; i < this.extraSettings.Settings.length; i++) {
                var obj = this.extraSettings.Settings[i];

                for (var key in obj) {
                    if (key === data.tag) {
                        attrExists = true;
                        obj[key] = data.value;
                    }
                }
            }

            if (!attrExists) {
                var setting = {};

                setting[data.tag] = data.value;
                this.extraSettings.Settings.push(setting);
            }
        },

        /**
         * @method setDefaultSettings
         */
        setDefaultSettings: function() {
            this.extraSettings = {
                'Settings': []
            };

            this.setDefaultSetting('networkDeviceDiscovery');
            this.setDefaultSetting('showStatusbar');
            this.setDefaultSetting('streamingInterface');
            this.setDefaultSetting('streamingMode');
            this.setDefaultSetting('airplayPinHdmi2');
            this.setDefaultSetting('userPinHdmi2');
            this.setDefaultSetting('showNotificationHdmi2');
            this.setDefaultSetting('usePreferredUsbMic');
            this.setDefaultSetting('qrCodeSource');
            this.setDefaultSetting('activeSessionPin');
            this.setDefaultSetting('activeSessionId');
            this.setDefaultSetting('statusBarSettings');
            this.setDefaultSetting('matrixCurrentTemplate');
            this.setDefaultSetting('matrixAutoStart');
            this.setDefaultSetting('matrixPresentationId');
            this.setDefaultSetting('matrixControlMode');
            this.setDefaultSetting('matrixIgnoreTemplates');
            this.setDefaultSetting('vMeetingDarkUi');
            this.setDefaultSetting('vMeetingWallpaper');
            this.setDefaultSetting('showVMeetingStatusBar');
            this.setDefaultSetting('wallpaperName');
            this.setDefaultSetting('wallpaperType');
            this.setDefaultSetting('splashScreenVisible');
            this.setDefaultSetting('splashScreenEnabled');
            this.setDefaultSetting('splashScreenShowOnRemote');
            this.setDefaultSetting('splashScreenShowQrCode');
            this.setDefaultSetting('splashScreenDisplayWlan');
            this.setDefaultSetting('splashScreenDisplayWlanType');
            this.setDefaultSetting('splashScreenDisplayWlanSSID');
            this.setDefaultSetting('splashScreenDisplayWlanPassword');
            this.setDefaultSetting('splashScreenDisplayWlanEncryption');
            this.setDefaultSetting('splashScreenManuallyTimeout');
            this.setDefaultSetting('customCommandButtons');
            this.setDefaultSetting('mirrorPinSize');
            this.setDefaultSetting('mirrorPinMainScreen');
            this.setDefaultSetting('showPinProjections');
            this.setDefaultSetting('showPinControlScreen');
            this.setDefaultSetting('avoidInfinityMirrorVApp');
            this.setDefaultSetting('autoShowAnnotationMenu');
        },

        /**
         * @method setDefaultSetting
         */
        setDefaultSetting: function(name) {
            var tmpSetting = {};

            // Check if value has a default option.
            if ('undefined' === typeof defaultOptions[name]) {
                app.getService('ExceptionsManager')
                    .throw('Setting with name "' + name + '" has no default setting.');

                return;
            }

            tmpSetting[name] = defaultOptions[name];
            this.extraSettings.Settings.push(tmpSetting);

            return tmpSetting[name];
        },

        /**
         * Get default option of setting with name
         * @param name name of setting
         */
        getDefaultSetting: function(name) {
            return defaultOptions[name];
        }
    };
});
