var timezones = [
    'Pacific / Apia',
    'Pacific / Honolulu',
    'America / Anchorage',
    'America / Los Angeles',
    'America / Tijuana',
    'America / Phoenix',
    'America / Denver',
    'America / Mazatlan',
    'America / Chicago',
    'America / Regina',
    'America / Mexico City',
    'America / Bogota',
    'America / New York',
    'America / Caracas',
    'America / Halifax',
    'America / Santiago',
    'America / La Paz',
    'America / St Johns',
    'America / Sao Paulo',
    'America / Buenos Aires',
    'America / Montevideo',
    'Atlantic / South Georgia',
    'Atlantic / Azores',
    'Atlantic / Cape Verde',
    'Africa / Casablanca',
    'UTC',
    'Europe / London',
    'Europe / Berlin',
    'Africa / Kinshasa',
    'Europe / Istanbul',
    'Africa / Cairo',
    'Asia / Jerusalem',
    'Africa / Johannesburg',
    'Europe / Moscow',
    'Africa / Nairobi',
    'Asia / Baghdad',
    'Asia / Tehran',
    'Asia / Muscat',
    'Asia / Baku',
    'Asia / Kabul',
    'Asia / Karachi',
    'Asia / Kolkata',
    'Asia / Katmandu',
    'Asia / Almaty',
    'Asia / Dhaka',
    'Asia / Rangoon',
    'Asia / Bangkok',
    'Asia / Hong Kong',
    'Asia / Singapore',
    'Australia / Perth',
    'Asia / Taipei',
    'Asia / Tokyo',
    'Asia / Seoul',
    'Australia / Adelaide',
    'Australia / Darwin',
    'Australia / Sydney',
    'Australia / Brisbane',
    'Pacific / Noumea',
    'Pacific / Auckland',
    'Pacific / Kwajalein',
    'Europe / Helsinki'
];

module.exports = timezones;
