'use strict';

var app = require('../../../app');
var recordingTypeTpl = require('./recording-type.hbs');

app.component('RecordingTypeModal', {
    template: recordingTypeTpl,

    initialize: function() {
        this.service = this.options.configs.service;
    },

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    postPlaceAt: function() {
        this.bindDOMEvents();

        if (this.service === 'opencast') {
            this.$el.find('#start-opencast').removeClass('hidden');
        } else {
            this.$el.find('#start-panopto').removeClass('hidden');
        }
    },

    bindDOMEvents: function() {
        this.$el.on('click', '#start-local-recording', this.onStartLocalRecording.bind(this));
        this.$el.on('click', '#start-panopto', this.onStartPanopto.bind(this));
        this.$el.on('click', '#start-opencast', this.onStartOpencast.bind(this));
    },

    /**
     * Close modal.
     */
    close: function() {
        this.emit('modal.close', {
            id: 'recording-type'
        });
    },

    /**
     * Start local recording.
     */
    onStartLocalRecording: function() {
        this.close();
        this.options.configs.onStartLocalRecording();
    },

    /**
     * Start Panopto recording.
     */
    onStartPanopto: function() {
        this.close();
        this.options.configs.onStartPanopto();
    },

    /**
     * Start Opencast recording.
     */
    onStartOpencast: function() {
        this.close();
        this.options.configs.onStartOpencast();
    }
});
