'use strict';

var app = require('../../app');
var tpl = require('./osd-extend-interactive.hbs');
var $ = require('jquery');

app.component('OSDExtendInteractive', {
    template: tpl,

    getAccessKey: function() {
        return {
            'roleName': 'OSDMessage',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.onAllow = this.options.onAllow || $.noop;
        this.onDecline = this.options.onDecline || $.noop;
    },

    serialize: function() {
        return {
            allowText: this.options.allowText || 'osd_messages.allow',
            declineText: this.options.declineText || 'osd_messages.decline'
        };
    },

    postPlaceAt: function() {
        this.bindDOMEvents();
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.clickable:not(.is-disabled)', this.onAction.bind(this));
    },

    onAction: function(event) {
        var $el = this.$(event.currentTarget);
        var action = $el.data('action');

        switch (action) {
            case 'allow':
                this.onAllow();
                break;
            case 'decline':
                this.onDecline();
                break;
        }
    }
});
