'use strict';

var app = require('../../../app');
var textInputTpl = require('./text-input.hbs');
var $ = require('jquery');

app.component('TextInput', {
    template: textInputTpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.confirmed = false;
        this.formManager = this.getService('FormManager');
    },

    serialize: function() {
        return {
            successTextKey: this.options.configs.successTextKey,
            discareTextKey: this.options.configs.discareTextKey,
            titleKey: this.options.configs.titleKey,
            labelKey: this.options.configs.labelKey,
            validation: this.options.configs.validation,
            validationErrorMsg: this.options.configs.validationErrorMsg,
            placeholderKey: this.options.configs.placeholderKey
        };
    },

    postPlaceAt: function() {
        this.initForm();
        this.bindDOMEvents();
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#text-input-form'),
            validationContainer: '.input-group'
        });
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.form-action-submit', this.onConfirmHandler.bind(this));
        this.$el.on('keydown', this.keydownHandler.bind(this));
        this.$el.on('click', '.form-action-discare', this.onDiscareHandler.bind(this));
    },

    // NOTE: If you're searching for ENTER handling.. look here
    keydownHandler: function(event) {
        if (event.keyCode === 13 && !$(event.target).hasClass('form-action-discare')) {
            this.onConfirmHandler();
        }
    },

    /**
     * This method is called after the submit-button has been clicked.
     *
     * Calls the (onConfirm) callback from the options.
     */
    onConfirmHandler: function() {
        var value = '';

        if (this.form.validate()) {
            value = this.form.get('field').getValue();

            this.confirmed = true;
            this.emit('modal.close');

            if (this.options.configs.onConfirm) {
                this.options.configs.onConfirm(value);
            }
        }
    },

    onDiscareHandler: function() {
        this.confirmed = true;
        this.emit('modal.close');

        if (this.options.configs.onDiscare) {
            this.options.configs.onDiscare();
        }
    },

    destroy: function() {
        var handler = this.options.configs.onDiscare;

        if (!!handler && !this.confirmed) {
            handler();
        }
    }
});

