'use strict';

var _ = require('lodash');
var app = require('../../../app');
var tpl = require('./software-packages.hbs');
var LayoutManager = require('../../../../layout/layout');

app.component('SoftwarePackages', {
    template: tpl,
    className: 'software-packages-container',
    idName: 'software-packages',

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.scrolledToBottom = false;
    },

    bindEvents: function() {
        LayoutManager.on('window.resize', _.debounce(this.resizeHandler.bind(this), 100));
    },

    postPlaceAt: function() {
        this.initForm();
        this.bindDOMEvents();
        this.resizeHandler();
    },

    serialize: function() {
        return {
            configs: this.options.configs
        };
    },

    resizeHandler: function() {
        this.$el.css({
            height: this.$('#modal-view-container').height() + 'px'
        });
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#software-packages-form'),
            validationContainer: '.input-group'
        });
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.$el.on('click', '.software-packages-close', this.onClose.bind(this));
        this.$el.find('.software-packages-text-scroller').on('keydown', this.onKeyDown.bind(this));
        this.$el.find('.software-packages-text-scroller').on('scroll', this.onScroll.bind(this));
    },

    onKeyDown: function(event) {
        var keyCode = event.keyCode;

        if (keyCode === this.remote.KEYCODES.ENTER) {
            if (!this.blockedEl) {
                var el = this.$el.find('.software-packages-action-container').get(0);

                this.remote.block();
                this.blockedEl = el;
            } else {
                this.remote.unblock();
                this.blockedEl = null;
            }

            this.remote.focus(this.$el.find('.software-packages-action-container'));
        } else if (keyCode === 0 || keyCode === this.remote.KEYCODES.LEFT || keyCode === this.remote.KEYCODES.RIGHT
            || (keyCode === this.remote.KEYCODES.DOWN && this.scrolledToBottom)) {
            this.remote.focus(this.$el.find('.software-packages-action-container'));
        }
    },

    /**
     * On scroll handler checks if scrolled to bottom.
     * @param event
     */
    onScroll: function(event) {
        this.scrolledToBottom = event.target.offsetHeight + event.target.scrollTop > event.target.scrollHeight;
    },

    onClose: function() {
        this.emit('modal.close', {
            id: this.options.configs.id
        });
    }
});

