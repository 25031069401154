'use strict';

const $ = require('jquery');
const app = require('../../../app');
const streamingSettingsTpl = require('./streaming-settings.hbs');
const FormActionView = require('../form-action-view');
const i18n = require('i18next');

const states = {
    visible: 'visible',
    hidden: 'hidden'
};

const sdpModes = {
    static: 'static',
    dynamic: 'dynamic'
};

const modes = {
    multicast: 'multicast',
    unicast: 'unicast'
};

const bandwidthValues = [
    {
        text: 'settings.bandwidth_0_5mbit',
        value: 500
    },
    {
        text: 'settings.bandwidth_1mbit',
        value: 1000
    },
    {
        text: 'settings.bandwidth_1_5mbit',
        value: 1500
    },
    {
        text: 'settings.bandwidth_2mbit',
        value: 2000
    },
    {
        text: 'settings.bandwidth_2_5mbit',
        value: 2500
    },
    {
        text: 'settings.bandwidth_4mbit',
        value: 4000
    },
    {
        text: 'settings.bandwidth_5mbit',
        value: 5000
    },
    {
        text: 'settings.bandwidth_7_5mbit',
        value: 7500
    },
    {
        text: 'settings.bandwidth_10mbit',
        value: 10000
    },
    {
        text: 'settings.bandwidth_15mbit',
        value: 15000
    },
    {
        text: 'settings.bandwidth_20mbit',
        value: 20000
    },
    {
        text: 'settings.bandwidth_25mbit',
        value: 25000
    },
    {
        text: 'settings.bandwidth_30mbit',
        value: 30000
    },
    {
        text: 'settings.bandwidth_35mbit',
        value: 35000
    },
    {
        text: 'settings.bandwidth_40mbit',
        value: 40000
    }
];

require('./remote-streaming-settings/remote-streaming-settings');

app.component('StreamingSettings', {
    template: streamingSettingsTpl,
    actionView: null,

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.validate = this.getService('ValidateService');
        this.streaming = this.getService('Streaming');
        this.matrixService = this.getService('MatrixService');
        this.deviceService = this.getService('DeviceService');
        this.isDualProjection = this.deviceService.isCboxProDualProjection();
        this.validFormComponent = true;
        this.supportsStreaming = this.deviceService.isCbox() || this.deviceService.isCboxPro();

        this.streamingState = this.createStateMachine({
            state: states.visible,
            states: states
        });

        this.addStateTransitions();
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.createComponentRemoteStreaming();
        this.createSelects();
        this.initForm();
        this.initFormAction();
        this.bindEvents();

        $.when(this.loadStreamingSettings()).done(function() {
            this.bindDOMEvents();
            this.checkStreamingEnabled();
            this.checkStreamFormatEnabled();
            this.showMatrixEnabledInfo();
        }.bind(this));
    },

    serialize: function() {
        return {
            supportsStreaming: this.supportsStreaming,
            isDualProjection: this.isDualProjection,
            minPort: 8800,
            maxPort: 9000
        };
    },

    storeSelectors: function() {
        this.$remoteStreamingSettingsContainer = this.$el.find('#remote-streaming-settings-container');

        if (this.supportsStreaming) {
            this.$streamModeContainer = this.$el.find('#stream-mode-container');
            this.$multiStreamError = this.$el.find('.multiple-stream-error');
            this.$rtpStreamSettings = this.$el.find('.rtp-stream-settings');
            this.$rtspStreamSettings = this.$el.find('.rtsp-stream-settings');
        }
    },

    showMatrixEnabledInfo: function() {
        if (true === this.matrixService.isEnabled()) {
            this.$el.find('.matrix-enabled-info').show();
            this.$el.find('#streaming-settings-container').hide();
            this.$el.find('.matrix-enabled-webcast-info').show();
        }
    },

    /**
     * Create Webcasting component
     */
    createComponentRemoteStreaming: function() {
        this.createComponent({
            type: 'RemoteStreamingSettings',
            container: this.$remoteStreamingSettingsContainer,
            remoteContainer: this.options.configs.remoteContainer
        });
    },

    bindEvents: function() {
        this.on('streaming-settings.remote-enabled', this.setRemoteStreamingEnable.bind(this));
        this.on('streaming-settings.openActionView', this.openActionView.bind(this));
        this.on('streaming-settings.checkActionFormComponent', this.checkActionFormComponent.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
        this.on('streaming-settings.unsaved-changes.handle', this.handleUnsavedChanges.bind(this));
    },

    bindDOMEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });

            this.actionView.render();
        }
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#streaming-settings'),
            validationContainer: '.input-group'
        });
    },

    saveHandler: function() {
        const dfd = $.Deferred();
        let data;

        if (this.form.validate()) {
            data = this.form.serialize();

            if (true === this.matrixService.isEnabled() || this.deviceService.isCboxCorePro()) {
                data.streamEnabled = this.enableRemote;
            }

            this.emit('remote-streaming.save');

            let cmds = [
                {
                    command: 'setStreamingResolution',
                    data: {
                        resolution: data.resolution
                    }
                },
                {
                    command: 'setStreamingFramerate',
                    data: {
                        framerate: data.framerate
                    }
                },
                {
                    command: 'setStreamingFunction',
                    data: {
                        function: data.streamEnabled
                    }
                },
                {
                    command: 'setStreamingBitrate',
                    data: {
                        mode: data.bandwidthMode,
                        bitrate: data.bandwidth
                    }
                }
            ];

            if (this.supportsStreaming) {
                if (this.isDualProjection) {
                    cmds = [
                        ...cmds,
                        ...[
                            {
                                command: 'setStreamingIpAddressOutput',
                                data: {
                                    ipAddress: data.ipAddressHdmi1,
                                    output: 'hdmi1'
                                }
                            },
                            {
                                command: 'setStreamingPortOutput',
                                data: {
                                    port: data.portHdmi1,
                                    output: 'hdmi1'
                                }
                            },
                            {
                                command: 'setStreamingTtlOutput',
                                data: {
                                    ttl: data.ttlHdmi1,
                                    output: 'hdmi1',
                                    mode: this.checkIpAddress('ipAddressHdmi1', data.ipAddressHdmi1)
                                }
                            },
                            {
                                command: 'setStreamingIpAddressOutput',
                                data: {
                                    ipAddress: data.ipAddressHdmi2,
                                    output: 'hdmi2'
                                }
                            },
                            {
                                command: 'setStreamingPortOutput',
                                data: {
                                    port: data.portHdmi2,
                                    output: 'hdmi2'
                                }
                            },
                            {
                                command: 'setStreamingTtlOutput',
                                data: {
                                    ttl: data.ttlHdmi2,
                                    output: 'hdmi2',
                                    mode: this.checkIpAddress('ipAddressHdmi2', data.ipAddressHdmi2)
                                }
                            }
                        ]
                    ];
                } else {
                    cmds = [
                        ...cmds,
                        ...[
                            {
                                command: 'setStreamingIpAddress',
                                data: {
                                    ipAddress: data.ipAddress
                                }
                            },
                            {
                                command: 'setStreamingPort',
                                data: {
                                    port: data.port
                                }
                            },
                            {
                                command: 'setStreamingTtl',
                                data: {
                                    ttl: data.ttl,
                                    mode: this.checkIpAddress('ipAddress', data.ipAddress)
                                }
                            }
                        ]
                    ];
                }

                cmds = [
                    ...cmds,
                    ...[
                        {
                            command: 'setRtspStreamingMode',
                            data: {
                                rtsp: data.enableRtsp
                            }
                        },
                        {
                            command: 'setRtpStreamingMode',
                            data: {
                                rtp: data.enableRtp
                            }
                        },
                        {
                            command: 'setStreamSDPMode',
                            data: {
                                mode: data.dynamicQrCode ? sdpModes.dynamic : sdpModes.static
                            }
                        },
                        {
                            command: 'setRtspStreamingInterface',
                            data: {
                                interface: data.streamingInterface
                            }
                        },
                        {
                            command: 'setFirewall',
                            data: {
                                interface: 'lan1',
                                service: 'rtsp',
                                accept: data.enableRTSPLan1
                            }
                        },
                        {
                            command: 'setFirewall',
                            data: {
                                interface: 'lan2',
                                service: 'rtsp',
                                accept: data.enableRTSPLan2
                            }
                        },
                        {
                            command: 'setFirewall',
                            data: {
                                interface: 'wlan',
                                service: 'rtsp',
                                accept: data.enableRTSPWlan
                            }
                        }
                    ]
                ];
            }

            this.deviceConnection
                .send(cmds)
                .done(function() {
                    this.handleSettingsSave();
                    this.form.setDefaultValues();
                    this.emit('overlay.remote.focus', true);

                    dfd.resolve();
                }.bind(this));
        } else {
            dfd.resolve();
        }

        return dfd.promise();
    },

    /**
     * Calls on clicked the cancel button.
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.form.resetValues();
        this.checkStreamingEnabled();
        this.checkStreamFormatEnabled();

        this.emit('remote-streaming.cancel');

        this.changes = false;
    },

    handleSettingsSave: function() {
        app.emit('main-loop.fast.start', {
            id: 'streaming'
        });

        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.changes = false;
    },

    /**
     * Open action view.
     *
     * @param webcastChanged Webcast setting changed - true/false
     */
    openActionView: function(webcastChanged) {
        // Check if cancel/save should be openend when webcasting service changed
        if (webcastChanged && (!this.form.get('streamEnabled').getValue() || !this.enableRemote)) {
            return;
        }

        this.changes = true;
        this.actionView.open();
    },

    /**
     * Handle form change.
     */
    handleFormChange: function() {
        this.actionView.open();
        this.checkActionForm();
        this.checkStreamingEnabled();
        this.checkStreamFormatEnabled();
        this.changes = true;
    },

    /**
     * Check if IP address and display streaming info.
     *
     * @param ipAddress
     * @returns {string}
     */
    checkIpAddress: function(ipAddressId, ipAddress) {
        let mode;
        var ip = this.form.validate() ? this.form.serialize()[ipAddressId] : ipAddress;

        if (ip) {
            if (this.streaming.isMulticastIp(ip)) {
                this.updateIpAddressInfo(ipAddressId, true);
                mode = modes.multicast;
            } else {
                this.updateIpAddressInfo(ipAddressId, false);
                mode = modes.unicast;
            }
        } else {
            $(this.form.get(ipAddressId).el.offsetParent).find('.success-message').hide();
        }

        return mode;
    },

    /**
     * Updates the IP address info label.
     *
     * @param isMulticast
     */
    updateIpAddressInfo: function(ipAddressId, isMulticast) {
        if (isMulticast) {
            $(this.form.get(ipAddressId).el.offsetParent).find('.success-message').html(i18n.t('settings.multicast'));
            $(this.form.get(ipAddressId).el.offsetParent).find('.success-message').show();
        } else {
            $(this.form.get(ipAddressId).el.offsetParent).find('.success-message').html(i18n.t('settings.unicast'));
            $(this.form.get(ipAddressId).el.offsetParent).find('.success-message').show();
        }
    },

    /**
     * Check if action form is valid and enable/disable submit button.
     *
     * @param valid true/false
     */
    checkActionFormComponent: function(valid) {
        this.validFormComponent = this.enableRemote ? valid : true;

        if (this.validFormComponent) {
            this.actionView.enableSubmitButton();
            this.checkActionForm();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * Check action form.
     */
    checkActionForm: function() {
        if ((this.form.validate() && this.validFormComponent)
            || this.form.validate() && !this.form.get('streamEnabled').getValue()
        ) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }

        if (this.supportsStreaming) {
            if (this.isDualProjection) {
                this.form.setValues({
                    'portAudioHdmi1': parseInt(this.form.get('portHdmi1').getValue()) + 2
                });
                this.form.setValues({
                    'portAudioHdmi2': parseInt(this.form.get('portHdmi2').getValue()) + 2
                });
                this.checkIpAddress('ipAddressHdmi1', this.form.get('ipAddressHdmi1').getValue());
                this.checkIpAddress('ipAddressHdmi2', this.form.get('ipAddressHdmi2').getValue());
            } else {
                this.form.setValues({
                    'portAudio': parseInt(this.form.get('port').getValue()) + 2
                });

                this.checkIpAddress('ipAddress', this.form.get('ipAddress').getValue());
            }

            this.checkMultiStream();
        }
    },

    /**
     * Load streaming settings and update form.
     */
    loadStreamingSettings: function() {
        return $.when(this.loadBackendSettings(this))
            .done(function() {
                this.updateForm();
                this.form.setDefaultValues();
            }.bind(this));
    },

    /**
     * Load settings from backend via wolfprot.
     */
    loadBackendSettings: function() {
        const dfd = $.Deferred();

        let cmds = [
            'getStreamingFunction',
            'getStreamingFramerate',
            'getStreamingResolution',
            'getStreamingBitrate'
        ];

        if (this.supportsStreaming) {
            cmds = [
                ...cmds,
                ...[
                    'getStreamingIpAddress',
                    'getStreamingPort',
                    'getRtspStreamingMode',
                    'getRtpStreamingMode',
                    {
                        command: 'getStreamingTtl',
                        data: {
                            mode: 'unicast'
                        }
                    },
                    {
                        command: 'getStreamingTtl',
                        data: {
                            mode: 'multicast'
                        }
                    },
                    'getRtspStreamingInterface',
                    'getStreamSDPMode',
                    {
                        command: 'getFirewall',
                        data: {
                            interface: 'lan1',
                            service: 'rtsp'
                        }
                    },
                    {
                        command: 'getFirewall',
                        data: {
                            interface: 'lan2',
                            service: 'rtsp'
                        }
                    },
                    {
                        command: 'getFirewall',
                        data: {
                            interface: 'wlan',
                            service: 'rtsp'
                        }
                    }
                ]
            ];
        }

        this.deviceConnection
            .send(cmds)
            .then(function(streamingFunction, framerate, resolution, bitrate, ipAddress, port, rtsp, rtp, unicastTtl,
                multicastTtl, streamingInterface, streamSDPMode, rtspLan1, rtspLan2, rtspWlan) {
                this.formData = {
                    streamEnabled: streamingFunction.function,
                    framerate: framerate.framerate,
                    resolution: resolution.resolution,
                    bandwidthMode: bitrate.mode,
                    bandwidth: bitrate.bitrate
                };

                if (this.supportsStreaming) {
                    if (this.isDualProjection) {
                        this.formData = {
                            ...this.formData,
                            ... {
                                ipAddressHdmi1: ipAddress.ipAddressHdmi1,
                                portHdmi1: port.portHdmi1,
                                portAudioHdmi1: parseInt(port.portHdmi1) + 2,
                                ttlHdmi1: this.checkIpAddress('ipAddressHdmi1', ipAddress.ipAddressHdmi1) === modes.multicast ? multicastTtl.ttlHdmi1 : unicastTtl.ttlHdmi1,
                                ipAddressHdmi2: ipAddress.ipAddressHdmi2,
                                portHdmi2: port.portHdmi2,
                                portAudioHdmi2: parseInt(port.portHdmi2) + 2,
                                ttlHdmi2: this.checkIpAddress('ipAddressHdmi2', ipAddress.ipAddressHdmi2) === modes.multicast ? multicastTtl.ttlHdmi2 : unicastTtl.ttlHdmi2
                            }
                        };
                    } else {
                        this.formData = {
                            ...this.formData,
                            ... {
                                ipAddress: ipAddress.ipAddress,
                                port: port.port,
                                portAudio: parseInt(port.port) + 2,
                                ttl: this.checkIpAddress('ipAddress', ipAddress.ipAddress) === modes.multicast ? unicastTtl.ttl : multicastTtl.ttl
                            }
                        };
                    }

                    this.formData = {
                        ...this.formData,
                        ... {
                            enableRtsp: rtsp.rtsp,
                            enableRtp: rtp.rtp,
                            dynamicQrCode: streamSDPMode.mode === sdpModes.dynamic,
                            streamingInterface: streamingInterface.interface,
                            enableRTSPLan1: rtspLan1.accept,
                            enableRTSPLan2: rtspLan2.accept,
                            enableRTSPWlan: rtspWlan.accept
                        }
                    };
                }

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    updateForm: function() {
        this.form.setValues(this.formData);

        if (this.supportsStreaming) {
            if (this.isDualProjection) {
                this.form.get('portAudioHdmi1').disable();
                this.form.get('portAudioHdmi2').disable();
            } else {
                this.form.get('portAudio').disable();
            }
        }
    },

    createSelects: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#resolution-select'),
            label: 'settings.resolution',
            native: true,
            name: 'resolution',
            items: [
                {
                    text: '360p',
                    value: '360p'
                },
                {
                    text: '540p',
                    value: '540p'
                },
                {
                    text: '720p',
                    value: '720p'
                },
                {
                    text: '1080p',
                    value: '1080p'
                }
            ]
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#framerate-select'),
            label: 'settings.framerate',
            native: true,
            name: 'framerate',
            items: [
                {
                    text: 'settings.low',
                    value: 'low'
                },
                {
                    text: 'settings.medium',
                    value: 'medium'
                },
                {
                    text: 'settings.high',
                    value: 'high'
                }
            ]
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#bandwidth-mode-select'),
            label: 'settings.bandwidth_mode',
            native: true,
            name: 'bandwidthMode',
            items: [
                {
                    text: 'settings.constant',
                    value: 'constant'
                },
                {
                    text: 'settings.variable',
                    value: 'variable'
                }
            ]
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#bandwidth-select'),
            label: 'settings.bandwidth',
            native: true,
            name: 'bandwidth',
            items: function() {
                if (this.deviceService.isCboxCorePro()) {
                    return bandwidthValues.filter(b => b.value <= 4000);
                }

                return bandwidthValues;
            }.bind(this).call()
        });

        if (this.supportsStreaming) {
            const ttlItems = [];
            let i;

            for (i = 0; i <= 254; i++) {
                ttlItems[i] = {
                    text: (i + 1).toString(),
                    value: i + 1
                };
            }

            if (this.isDualProjection) {
                this.createComponent({
                    type: 'CustomSelect',
                    container: this.$el.find('#ttl-selectHdmi1'),
                    label: 'settings.ttl',
                    native: true,
                    name: 'ttlHdmi1',
                    items: ttlItems
                });

                this.createComponent({
                    type: 'CustomSelect',
                    container: this.$el.find('#ttl-selectHdmi2'),
                    label: 'settings.ttl',
                    native: true,
                    name: 'ttlHdmi2',
                    items: ttlItems
                });
            } else {
                this.createComponent({
                    type: 'CustomSelect',
                    container: this.$el.find('#ttl-select'),
                    label: 'settings.ttl',
                    native: true,
                    name: 'ttl',
                    items: ttlItems
                });
            }

            this.createComponent({
                type: 'CustomSelect',
                container: this.$el.find('#interface-select'),
                label: 'settings.qr_code_interface',
                native: true,
                name: 'streamingInterface',
                items: [
                    {
                        text: 'settings.lan1',
                        value: 'lan1'
                    },
                    {
                        text: 'settings.lan2',
                        value: 'lan2'
                    },
                    {
                        text: 'settings.wlan',
                        value: 'wlan'
                    }
                ]
            });
        }
    },

    /**
     * Check streaming state and show container.
     */
    showContainer: function() {
        if (this.streamingState.getState() !== states.visible) {
            this.showStreamModeContainer();
            this.showRemoteStreamingSettingsContainer();
            this.streamingState.changeState(states.visible);
        }
    },

    /**
     * Show streaming mode container.
     */
    showStreamModeContainer: function() {
        if (!this.supportsStreaming) {
            return;
        }

        if (this.init) {
            this.$streamModeContainer
                .stop()
                .slideDown(230);
        } else {
            this.$streamModeContainer.show();
        }
    },

    /**
     * Show Webcasting container.
     */
    showRemoteStreamingSettingsContainer: function() {
        if (this.init) {
            this.$remoteStreamingSettingsContainer
                .stop()
                .slideDown(230);
        } else {
            this.$remoteStreamingSettingsContainer.show();
        }
    },

    /**
     * Check stremaing state and hide container.
     */
    hideContainer: function() {
        if (this.streamingState.getState() !== states.hidden) {
            this.hideStreamModeContainer();
            this.hideRemoteStreamingSettingsContainer();
            this.streamingState.changeState(states.hidden);
        }
    },

    /**
     * Hide Webcasting container.
     */
    hideRemoteStreamingSettingsContainer: function() {
        if (this.init) {
            this.$remoteStreamingSettingsContainer
                .stop()
                .slideUp(300);
        } else {
            this.$remoteStreamingSettingsContainer.hide();
        }
    },

    /**
     * Hide streaming mode container.
     */
    hideStreamModeContainer: function() {
        if (!this.supportsStreaming) {
            return;
        }

        if (this.init) {
            this.$streamModeContainer
                .stop()
                .slideUp(300);
        } else {
            this.$streamModeContainer.hide();
        }
    },

    /**
     * Check if streaming is enabled and if Matrix is enabled (--> Matrix streaming is running).
     */
    checkStreamingEnabled: function() {
        const streamEnabled = this.form.get('streamEnabled').getValue();

        if (streamEnabled || true === this.matrixService.isEnabled() || this.deviceService.isCboxCorePro()) {
            this.showContainer();
        } else {
            this.hideContainer();
        }

        if (true === this.matrixService.isEnabled() || this.deviceService.isCboxCorePro()) {
            this.hideStreamingButton();
        }

        this.checkValidationInputs();
        this.checkActionForm();
    },

    hideStreamingButton: function() {
        this.$el.find('#streaming-enable-container').hide();
        this.$el.find('#streaming-enable-container-inner').removeAttr('data-nav-area-default');
    },

    /**
     * Check if RTP or RTSP is enabled and show or hide specific settings container.
     */
    checkStreamFormatEnabled: function() {
        if (!this.supportsStreaming) {
            return;
        }

        const rtpStream = this.form.get('enableRtp').getValue();
        const rtspStream = this.form.get('enableRtsp').getValue();

        if (rtpStream) {
            this.$rtpStreamSettings.show();
        } else {
            this.$rtpStreamSettings.hide();
        }

        if (rtspStream) {
            this.$rtspStreamSettings.show();
        } else {
            this.$rtspStreamSettings.hide();
        }

        this.checkValidationInputs();
        this.checkActionForm();
    },

    /**
     * Check if RTP or RTSP is enabled. Enabling both is not valid --> show error.
     */
    checkMultiStream: function() {
        if (!this.supportsStreaming) {
            return;
        }

        if (this.form.get('enableRtsp').getValue() && this.form.get('enableRtp').getValue()) {
            this.actionView.disableSubmitButton();
            this.$multiStreamError.show();
        } else {
            this.$multiStreamError.hide();
        }

        this.checkValidationInputs();
    },

    addStateTransitions: function() {
        this.streamingState.addTransitions({
            '> visible': function() {
                this.checkValidationInputs();
                this.checkActionForm();
            },
            '> hidden': function() {
                this.checkValidationInputs();
                this.checkActionForm();
            }
        });
    },

    /**
     * Enable or disable fields with validation, when RTP or streaming is disabled.
     */
    checkValidationInputs: function() {
        if (!this.supportsStreaming) {
            return;
        }

        const rtpStream = this.form.get('enableRtp').getValue();
        const streamEnabled = this.form.get('streamEnabled').getValue();

        if (rtpStream && streamEnabled) {
            if (this.isDualProjection) {
                this.form.get('ipAddressHdmi1').enable();
                this.form.get('portHdmi1').enable();

                this.form.get('ipAddressHdmi2').enable();
                this.form.get('portHdmi2').enable();
            } else {
                this.form.get('ipAddress').enable();
                this.form.get('port').enable();
            }
        } else if (this.isDualProjection) {
            this.form.get('ipAddressHdmi1').disable();
            this.form.get('portHdmi1').disable();
            this.form.get('portAudioHdmi1').disable();

            this.form.get('ipAddressHdmi2').disable();
            this.form.get('portHdmi2').disable();
            this.form.get('portAudioHdmi2').disable();
        } else {
            this.form.get('ipAddress').disable();
            this.form.get('port').disable();
            this.form.get('portAudio').disable();
        }
    },

    destroy: function() {
        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    /**
     * Update if remote streaming (webcasting) is enabled/disabled.
     *
     * @param enabled true/false
     */
    setRemoteStreamingEnable: function(enabled) {
        this.enableRemote = enabled;
    },

    /**
     * Handle unsaved changes and call postSaveHandler afterwards.
     * @param postSaveHandler (optionally) called after saving changes and also if no changes were made.
     */
    handleUnsavedChanges: function(postSaveHandler) {
        if (this.changes) {
            this.emit('modal.open', {
                id: 'settings-save-changes',
                invalid: this.hasChanges().invalid,
                onSave: function() {
                    this.saveHandler()
                        .then(function() {
                            this.emit('save-changes.finished');

                            (postSaveHandler || $.noop)();
                        }.bind(this));
                }.bind(this),
                waitOnSave: true,
                onFinish: function() {
                    this.cancelHandler();
                    this.actionView.close();
                }.bind(this)
            });
        } else {
            (postSaveHandler || $.noop)();
        }
    },

    hasChanges: function() {
        const changes =  {
            hasChanges: this.changes,
            invalid: false
        };

        if (this.actionView) {
            changes.invalid = $(this.actionView.$submitBtn).prop('disabled');
        }

        return changes;
    }
});
