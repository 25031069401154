// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"input-group certificate-file-container\">\n                        <label class=\"input-label\" for=\"certificate-file\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.certificate_file",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":153,"column":74},"end":{"line":153,"column":107}}}))
    + "</label>\n                        <input type=\"file\"\n                               class=\"input\"\n                               name=\"certificate-file\"\n                               id=\"certificate-file\"\n                               tabIndex=\"-1\"/>\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_certificate",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":160,"column":75},"end":{"line":160,"column":111}}}))
    + "</span>\n                        </div>\n                    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "col-xs-6";
},"5":function(container,depth0,helpers,partials,data) {
    return "col-xs-12";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"wlan-settings-form\" name=\"wlan-settings-form\">\n    <div data-nav-area=\".is-focusable\" data-nav-trapped data-nav-track>\n        <div class=\"row settings-list-primary has-content\">\n            <div class=\"col-xs-12\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.infrastructure",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":51}}}))
    + "</h2>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-primary wlan-open-component clickable is-focusable\"\n             id=\"open-wlan-list-item\"\n             data-name=\"wlan-list\"\n             tabIndex=\"-1\">\n            <div class=\"col-xs-9\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.go_to_wlan_list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":52}}}))
    + "</h2>\n            </div>\n\n            <div class=\"col-xs-3 wlan-forward-icon-container\">\n                <span class=\"icon-arrow-forward pull-right\"></span>\n            </div>\n        </div>\n    </div>\n\n    <div data-nav-area=\".input:not(:disabled), .btn-switch, select, .is-focusable\" data-nav-trapped data-nav-track>\n        <div class=\"row settings-list-secundary\">\n            <div class=\"col-xs-6\">\n                <div id=\"priority-select\"></div>\n            </div>\n\n            <div class=\"col-xs-6 switch-below-text\">\n                <label class=\"input-label\" for=\"useBssid\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.use_bssid",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":58},"end":{"line":30,"column":84}}}))
    + "</label>\n                <div class=\"btn-switch-container\">\n                    <input name=\"useBssid\" type=\"checkbox\" class=\"btn-switch\" id=\"useBssid\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\">\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\" id=\"ssid-container\">\n                    <label class=\"input-label\" for=\"ssid\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ssid",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":58},"end":{"line":43,"column":79}}}))
    + "</label>\n                    <input type=\"text\" class=\"input\" name=\"ssid\" id=\"ssid\" data-validation=\"required\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_ssid",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":71},"end":{"line":46,"column":100}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\" id=\"bssid-container\">\n                    <label class=\"input-label\" for=\"bssid\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.bssid",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":59},"end":{"line":53,"column":81}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input input-uppercase\"\n                           name=\"bssid\"\n                           id=\"bssid\"\n                           data-validation=\"checkBssid\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_bssid",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":71},"end":{"line":60,"column":101}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\">\n            <div class=\"col-xs-6\">\n                <div id=\"encrypt-select\"></div>\n            </div>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\" id=\"signal-level-limit-container\">\n                    <label class=\"input-label\" for=\"signalLevelLimit\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.signal_level_limit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":70},"end":{"line":72,"column":105}}}))
    + "</label>\n                    <input type=\"text\" class=\"input input-uppercase\" name=\"signalLevelLimit\" id=\"signalLevelLimit\"\n                           data-validation=\"checkSignalLevelLimit\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_signalLevelLimit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":76,"column":71},"end":{"line":76,"column":112}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div data-nav-area=\".input:not(:disabled), .btn-switch, select, .is-focusable\"\n         data-nav-trapped\n         data-nav-track>\n        <div class=\"row settings-list-secundary hidden\" id=\"security\">\n            <div class=\"col-xs-6\" id=\"other-username-container\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"username\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.identity",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":89,"column":62},"end":{"line":89,"column":87}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input\"\n                           name=\"username\"\n                           id=\"username\"/>\n                </div>\n            </div>\n\n            <div class=\"col-xs-6\" id=\"other-password-container\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"password\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":99,"column":62},"end":{"line":99,"column":87}}}))
    + "</label>\n                    <input type=\"password\"\n                           autocomplete=\"current-password\"\n                           class=\"input\"\n                           name=\"password\"\n                           id=\"encrypt-password\"\n                           data-validation=\"checkWLANPassword\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.wrong_password_length_8_63",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":108,"column":28},"end":{"line":108,"column":86}}}))
    + "\n                        </span>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"hidden\"\n         id=\"certificate-container\"\n         data-nav-area=\".input:not(:disabled), .btn-switch, select, .is-focusable\"\n         data-nav-trapped\n         data-nav-track>\n        <div class=\"row settings-list-secundary\">\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"anonymous\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.anonymous_identity",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":124,"column":63},"end":{"line":124,"column":98}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input\"\n                           name=\"anonymous\"\n                           id=\"anonymous\"/>\n                </div>\n            </div>\n            <div class=\"col-xs-6\">\n                <div id=\"auth-method-select\"></div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\">\n            <div class=\"col-xs-6 switch-below-text\">\n                <label class=\"input-label\" for=\"certificate-mode\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.certificate_mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":138,"column":66},"end":{"line":138,"column":99}}}))
    + "</label>\n                <div class=\"btn-switch-container\">\n                    <input name=\"certificate-mode\"\n                           type=\"checkbox\"\n                           class=\"btn-switch\"\n                           id=\"certificate-mode\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"col-xs-6\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":151,"column":16},"end":{"line":163,"column":27}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\">\n            <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":168,"column":24},"end":{"line":168,"column":70}}})) != null ? stack1 : "")
    + "\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"certificates\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.certificates",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":170,"column":66},"end":{"line":170,"column":95}}}))
    + "</label>\n                    <div id=\"certificate-list-items\" class=\"hidden\"></div>\n                    <div id=\"certificate-list-no-entry\" class=\"certificate-list-no-entry hidden\">\n                        <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.no_certificates",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":173,"column":27},"end":{"line":173,"column":59}}}))
    + "</p>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div data-nav-area=\".btn-switch\" data-nav-trapped data-nav-track>\n        <div class=\"row settings-list-primary has-content\">\n            <div class=\"col-xs-8\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ipv4_autoconfig",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":183,"column":20},"end":{"line":183,"column":52}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-4\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"dhcp\" id=\"dhcp\" type=\"checkbox\" class=\"btn-switch\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"dhcp-container\"\n         data-nav-area=\".input:not(:disabled), .btn-switch, select, .is-focusable\"\n         data-nav-trapped\n         data-nav-track>\n        <div class=\"row settings-list-secundary\">\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"ipv4\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ip_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":203,"column":58},"end":{"line":203,"column":85}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input\"\n                           name=\"ip\"\n                           id=\"ipv4\"\n                           placeholder=\"0.0.0.0\"\n                           data-validation=\"checkIpNonVz\"\n                           data-main-input-selector=\"#dhcp\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ip_is_wrong",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":212,"column":71},"end":{"line":212,"column":99}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"subnet\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.subnet",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":219,"column":60},"end":{"line":219,"column":83}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input\"\n                           name=\"subnet\"\n                           id=\"subnet\"\n                           placeholder=\"0.0.0.0\"\n                           data-validation=\"checkSubnet\"\n                           data-main-input-selector=\"#dhcp\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.subnet_is_wrong",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":228,"column":71},"end":{"line":228,"column":103}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\">\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"gateway\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.gateway",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":237,"column":61},"end":{"line":237,"column":85}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input\"\n                           name=\"gateway\"\n                           id=\"gateway\"\n                           placeholder=\"0.0.0.0\"\n                           data-validation=\"checkWlanLanGateway\"\n                           data-main-input-selector=\"#dhcp\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ip_is_wrong",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":246,"column":71},"end":{"line":246,"column":99}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\" id=\"dns-input-container\">\n                    <label class=\"input-label\" for=\"dns\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.dns",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":253,"column":57},"end":{"line":253,"column":77}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input\"\n                           name=\"dns\"\n                           id=\"dns\"\n                           placeholder=\"0.0.0.0\"\n                           data-validation=\"checkDNS\"\n                           data-main-input-selector=\"#dhcp\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ip_is_wrong",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":262,"column":71},"end":{"line":262,"column":99}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\">\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"signalLevel\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.signal_level",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":271,"column":65},"end":{"line":271,"column":94}}}))
    + "</label>\n                    <input type=\"text\" class=\"input\" name=\"signalLevel\" id=\"signalLevel\" disabled/>\n                </div>\n            </div>\n\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\" id=\"dns-input-container\">\n                    <label class=\"input-label\" for=\"dns\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.dns2",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":278,"column":57},"end":{"line":278,"column":78}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input\"\n                           name=\"dns2\"\n                           id=\"dns2\"\n                           placeholder=\"0.0.0.0\"\n                           data-validation=\"checkDNS\"\n                           data-main-input-selector=\"#dhcp\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ip_is_wrong",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":287,"column":71},"end":{"line":287,"column":99}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-secundary\">\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\"\n                           for=\"counterConnectionLoss\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.counter_connection",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":297,"column":55},"end":{"line":297,"column":90}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input\"\n                           name=\"counterConnectionLoss\"\n                           id=\"counterConnectionLoss\"\n                           disabled/>\n                </div>\n            </div>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\"\n                           for=\"counterLowSignalLevel\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.counter_signal_level",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":308,"column":55},"end":{"line":308,"column":92}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input\"\n                           name=\"counterLowSignalLevel\"\n                           id=\"counterLowSignalLevel\"\n                           disabled/>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div id=\"wlan-try-to-connect\" class=\"row settings-list-primary wlan-list-item wlan-info hidden\">\n        <div class=\"col-xs-12\">\n            <p><span class=\"loader small\"></span> <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.try_to_connect",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":321,"column":56},"end":{"line":321,"column":87}}}))
    + "</span></p>\n        </div>\n    </div>\n\n    <div id=\"wlan-infrastructure-miracast-info\" class=\"row wlan-list-item wlan-info hidden\">\n            <div class=\"col-xs-12\">\n            <p><span class=\"icon-msg-warning\"></span>   <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wlan_infra_miracast",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":327,"column":62},"end":{"line":327,"column":98}}}))
    + "</span></p>\n        </div>\n    </div>\n\n    <div id=\"form-action-container-wlan-settings\" class=\"form-primary-background\"></div>\n</form>\n";
},"useData":true});
