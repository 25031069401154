'use strict';

const app = require('../../../../app');
const tpl = require('./matrix-configuration-item.hbs');
const groupItem = require('./matrix-group-item.hbs');
const FormActionView = require('../../form-action-view');
const i18n = require('i18next');
const _ = require('lodash');
const $ = require('jquery');
const platform = require('../../../../../platform/platform');

app.component('MatrixConfigurationItem', {
    template: tpl,

    getAccessKey: function() {
        return {
            'roleName': 'MatrixSettings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.configs = this.getService('MatrixConfigs');

        this.changes = false;
        this.id = this.options.id;
        this.isDualProjection = this.options.isDualProjection;
        this.newConfig = this.options.newConfig;
        this.groups = this.options.groups;
        this.background = this.options.background;
        this.showCommandButtons = false;
        this.activeCommandButtons = 0;
    },

    serialize: function() {
        return {
            id: this.options.id,
            isCbox: platform.checks.isCbox
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.initForm();
        this.initFormAction();
        this.bindDOMEvents();
        this.bindEvents();
    },

    storeSelectors: function() {
        this.$deleteTemplate = this.$el.find('.delete-template');
        this.$deleteInfo = this.$el.find('.delete-info');
        this.$configTemplate = this.$el.find('.configuration-template');
        this.$addNewGroup = this.$el.find('.matrix-new-group');
        this.$groupList = this.$el.find('.matrix-group-list-items');
        this.$backgroundText = this.$el.find('.background-file-name');
        this.$backgroundSelect = this.$el.find('.background-file-select');
        this.$backgroundContainer = this.$el.find('#input-group-background');
    },

    /**
     * Init form for this template.
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('.matrix-configuration-item'),
            validationContainer: '.input-group'
        });

        this.updateBackgroundField(!platform.checks.isCbox && !this.background);

        // If shareToAllEnabled undefined or null (old template without share to all functionality) set to false
        this.form.setValues({
            shareToAllEnable: this.options.shareToAllEnabled ?? false,
            templateName: this.options.name,
            backgroundFileName: this.options.background ? this.options.background.backgroundName : ''
        });
        this.form.setDefaultValues();
        this.form.get('shareToAllEnable').$el.on('change', this.updateActiveCommandButtons.bind(this));

        this.initGroups();
        this.toggleCommandButtonSettings(this.options.showCommandButtons);
    },

    /**
     * Update form.
     */
    updateForm: function() {
        this.form = this.formManager.update(this.form);
    },

    /**
     * Init action view.
     */
    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();

        if (this.newConfig) {
            this.$deleteTemplate.hide();
            this.openActionForm();
        }
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.delete-template', this.deleteTemplate.bind(this));
        this.$el.on('click', '.matrix-new-group:not(.is-disabled)', this.addGroup.bind(this));
        this.$el.on('click', '.delete-group', this.deleteGroup.bind(this));
        this.$el.on('click', '.reset-background', this.resetBackground.bind(this));
    },

    bindEvents: function() {
        this.on('matrix.command-buttons.changed', this.toggleCommandButtonSettings.bind(this));
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.on('settings.save-changes-' + this.componentId, this.saveHandler.bind(this));
    },

    /**
     * Handle form change.
     */
    handleFormChange: function() {
        this.openActionForm();
        this.emit('settings.matrix-templates.changed');
    },

    /**
     * Open action view and check validation.
     */
    openActionForm: function() {
        this.$deleteTemplate.hide();

        this.actionView.open();
        this.checkActionForm();
        this.changes = true;

        this.emit('settings.matrix-templates.changed');
    },

    /**
     * Check if action form buttons should be enabled or disabled (form validation).
     *
     * @returns {boolean} true/false
     */
    checkActionForm: function() {
        // Valid or in "delete state"
        if (this.form.validate() || this.$configTemplate.css('display') === 'none') {
            if (!platform.checks.isCbox && this.form.get('background-file-select').getValue()) {
                const file = this.form.get('background-file-select').getValue();
                const img = new Image();

                img.onload = function() {
                    const width = img.naturalWidth;
                    const height = img.naturalHeight;

                    URL.revokeObjectURL(img.src);

                    if (width !== 1920 || height !== 1080) {
                        this.$el.find('#input-group-background').removeClass('is-valid');
                        this.$el.find('#input-group-background').addClass('is-invalid');

                        this.actionView.disableSubmitButton();

                        return false;
                    }

                    this.actionView.enableSubmitButton();

                    return true;
                }.bind(this);

                img.src = URL.createObjectURL(file);
            } else {
                this.actionView.enableSubmitButton();

                return true;
            }
        } else {
            this.actionView.disableSubmitButton();

            return false;
        }
    },

    /**
     * Handle save.
     */
    saveHandler: function() {
        if (this.form.validate()) {
            const name = this.form.get('templateName').getValue();
            const shareToAllEnabled = this.form.get('shareToAllEnable').getValue();

            const template = {
                id: this.id,
                name: name,
                shareToAllEnabled: shareToAllEnabled,
                config: this.configs.matrixFileName + this.id,
                groups: this.parseGroups(),
                background: this.background
            };

            // Delete template
            if (this.$configTemplate.css('display') === 'none') {
                this.configs.deleteMatrixTemplate(template);

                if (template.id === this.configs.matrixTemplateId) {
                    this.configs.setCurrentMatrixTemplate(-1, true);
                }

                this.app.removeComponent(this.componentId);
            } else {
                if (!platform.checks.isCbox) {
                    template.background = this.saveBackground();
                }

                if (this.activeCommandButtons <= 5) {
                    this.configs.addMatrixTemplate(template);
                }
                this.$deleteTemplate.show();

                this.form.setDefaultValues();
            }

            this.options.background = this.background;
            this.changes = false;
            this.configs.chooseTemplate = true;

            this.emit('settings.matrix-templates.changed');
        }
    },

    /**
     * Handle cancel.
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);

        this.resetGroups();
        this.form.resetValues();

        this.background = this.options.background;
        this.updateBackgroundField(!platform.checks.isCbox && !this.background);

        this.$deleteTemplate.show();
        this.$deleteInfo.hide();
        this.$configTemplate.show();

        // Delete template if it's not existing in the list (e.g. added and cancelled right after)
        if (!this.configs.templateExists(this.id)) {
            this.app.removeComponent(this.componentId);
        }

        this.changes = false;

        this.emit('settings.matrix-templates.changed');
    },

    /**
     * Delete Configuration Template.
     */
    deleteTemplate: function() {
        this.$deleteInfo.show();
        this.$configTemplate.hide();

        this.openActionForm();
    },

    /**
     * Add new group to template.
     */
    addGroup: function(event, index, name, shareToGroupEnabled) {
        const isNewGroup = typeof index === 'undefined';
        if (isNewGroup) {
            index = this.getNextFreeGroupIndex();

            if (index >= 0) {
                name = i18n.t('settings.matrix_group') + ' ' + (index + 1);
                shareToGroupEnabled = false;

                this.$groupList.append(groupItem({
                    id: index,
                    number: index + 1
                }));
            }
        } else {
            this.$groupList.append(groupItem({
                id: index,
                number: index + 1
            }));
        }
        this.updateForm();

        this.form.get('groupName' + index).setValue(name);
        this.form.get('shareToGroupEnable' + index).setValue(shareToGroupEnabled);
        this.form.get('shareToGroupEnable' + index).$el.on('change', this.updateActiveCommandButtons.bind(this));

        if (isNewGroup) {
            this.openActionForm();
        }
        this.toggleCommandButtonSettings(this.showCommandButtons);

        this.checkMaxGroupInputs();
    },

    /**
     * Get next free index for a group.
     */
    getNextFreeGroupIndex: function() {
        for (let i = 0; i < this.configs.get('groups.max'); i++) {
            if (this.$el.find('.matrix-group-item[data-index="' + i + '"]').length <= 0) {
                return i;
            }
        }
    },

    /**
     * Init existing groups and add to UI.
     */
    initGroups: function() {
        _.each(this.groups, function(group) {
            // If shareToGroupEnabled undefined or null (old template without this functionality) set to false
            this.addGroup(null, group.id, group.name, group.shareToGroupEnabled ?? false);
        }.bind(this));
    },

    /**
     * Reset groups.
     */
    resetGroups: function() {
        _.each(this.$groupList.children(), function(group) {
            $(group).remove();
        }.bind(this));

        this.initGroups();
    },

    /**
     * Parse groups from UI.
     *
     * @returns {Array} Groups
     */
    parseGroups: function() {
        this.groups = [];

        _.each(this.$groupList.children(), function(group) {
            const index = $(group).data('index');

            this.groups.push({
                id: index,
                name: this.form.get('groupName' + index).getValue(),
                shareToGroupEnabled: this.form.get('shareToGroupEnable' + index).getValue()
            });
        }.bind(this));

        return this.groups;
    },

    /**
     * Handle delete group.
     *
     * @param event Current event
     */
    deleteGroup: function(event) {
        const id = $(event.currentTarget).data('index');
        const group = this.$el.find('.matrix-group-item[data-index="' + id + '"]');

        if (group) {
            group.remove();
            this.updateActiveCommandButtons();
        }

        this.openActionForm();
        this.checkMaxGroupInputs();
    },

    /**
     * Check maximum configuration inputs and disable add config button.
     */
    checkMaxGroupInputs: function() {
        if (this.getNextFreeGroupIndex() === undefined) {
            this.$addNewGroup.addClass('is-disabled');
        } else {
            this.$addNewGroup.removeClass('is-disabled');
        }
    },

    toggleCommandButtonSettings: function(showButtons) {
        this.showCommandButtons = showButtons;
        this.$el.find('.command-button-container').toggleClass('hidden', !showButtons);
        this.$el.find('.command-button-maximum').toggleClass('hidden', !showButtons);

        this.updateActiveCommandButtons();
    },

    updateActiveCommandButtons: function() {
        const commandButtons = this.$el.find('.command-button-switch');
        const activeCommandButtons = commandButtons.filter(function() {
            return this.checked;
        });
        const inactiveCommandButtons = commandButtons.filter(function() {
            return !this.checked;
        });
        this.activeCommandButtons = activeCommandButtons.length;

        const maximumReached = this.activeCommandButtons >= 5;

        inactiveCommandButtons.each(function(index, el) {
            $(el).attr('disabled', maximumReached);
        }.bind(this));

        this.$el.find('.command-button-maximum').toggleClass('hidden', !maximumReached);
    },

    /**
     * Call save background file to storage.
     *
     * @returns {*|string}  background file info for template file (JSON)
     */
    saveBackground: function() {
        const file = this.form.get('background-file-select').getValue();
        const savedBackground = this.form.get('backgroundFileName').getValue();

        if (file) { // Save new background.
            this.configs.setMatrixTemplateBackground(this.id, file);

            this.background = {
                filename: 'matrixBackground' + this.id,
                backgroundName: file.name
            };
        } else if (savedBackground.length > 0) { // Background already set. Get name to update/"resave" template list.
            this.background = {
                filename: 'matrixBackground' + this.id,
                backgroundName: savedBackground
            };
        } else { // Delete background (file === undefined, no saved background).
            this.configs.setMatrixTemplateBackground(this.id, undefined);

            this.background = undefined;
        }

        this.updateBackgroundField(!this.background);

        return this.background;
    },

    /**
     * Handle reset background click.
     */
    resetBackground: function() {
        this.background = undefined;

        this.updateBackgroundField(true);
        this.openActionForm();
    },

    /**
     * Update background fields. Show select if no background file is choosen and a browser instance (for uploading files).
     * Else show background name.
     *
     * @param showSelect true: Show select filed (type file)
     */
    updateBackgroundField: function(showSelect) {
        if (showSelect) {
            this.$backgroundText.addClass('hidden');
            this.$backgroundSelect.removeClass('hidden');
            this.$backgroundContainer.removeClass('is-disabled');
        } else {
            this.$backgroundText.removeClass('hidden');
            this.$backgroundSelect.addClass('hidden');
            this.$backgroundContainer.addClass('is-disabled');
        }

        if (this.background) {
            this.form.get('backgroundFileName').setValue(this.background.backgroundName);
        } else {
            this.form.get('backgroundFileName').setValue('');
            this.form.get('background-file-select').setValue('');
        }
    },

    /**
     * Check if there are some changes and update action view (Cancel/Save)
     */
    hasChanges: function() {
        return {
            hasChanges: this.changes,
            invalid: !this.checkActionForm()
        };
    },

    destroy: function() {
        this.formManager.destroy(this.form);
        this.form = null;

        this.actionView.destroy();
        this.actionView = null;
    }
});
