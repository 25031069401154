'use strict';

var app = require('../../app');
var fileBrowserTemplate = require('./filebrowser.hbs');
var fsUtils = require('./utils.js');

app.component('FileBrowser', {
    template: fileBrowserTemplate,
    className: 'filebrowser-component',

    initialize: function() {
        this.selectedPath = null;
        this.fileListHistory = [];
    },

    bindEvents: function() {
        this.on('filebrowser.open', this.fileBrowserNavigateTo.bind(this));
        this.on('menu.closed', this.onMenuClosed.bind(this));
    },

    onMenuClosed: function() {
        this.emit('overlay.close');
    },

    /**
     * Navigate to media and folder
     *
     * @param target path e.g. Internal:///abc/def
     * @param isFileBrowserClosed Is file browser currently closed
     */
    fileBrowserNavigateTo: function(target, isFileBrowserClosed) {
        let oldLocation;
        let useBackAnimation = false;
        const location = fsUtils.splitMountAndFilename(target.path);

        if (this.selectedPath) {
            oldLocation = fsUtils.splitMountAndFilename(this.selectedPath);
        }

        if (oldLocation) {
            if (location.mount === oldLocation.mount) {
                if (location.filename === oldLocation.filename) {
                    return;
                }

                const filename = location.filename;
                const oldFilename = oldLocation.filename;
                useBackAnimation = filename.length < oldFilename.length && oldFilename.startsWith(filename);
            }
        }

        this.selectedPath = target.path;

        const globalContainer = this.$el.find('.container-for-filelist');
        const container = this.$('<div class="content overlay-content-view"></div>');

        const listId = this.createComponent({
            type: 'Filelist',
            container: container,
            configs: {
                id: location.mount,
                isCloudProvider: target.isCloudProvider,
                path: target.path,
                mountName: target.mountName,
                focusable: !isFileBrowserClosed
            }
        });

        const old = this.fileListHistory[this.fileListHistory.length - 1];

        this.fileListHistory = this.fileListHistory.concat([{
            container: container,
            listId: listId
        }]);

        if (this.fileListHistory.length > 1) {
            if (useBackAnimation) {
                old.container.addClass('setup-back-animation');
            } else {
                container.addClass('setup-update-animation');
            }

            requestAnimationFrame(function() {
                // Wait for at least one rendered frame, so animation will work
                requestAnimationFrame(function() {
                    if (useBackAnimation) {
                        old.container.addClass('run-back-animation');
                    } else {
                        container.addClass('run-update-animation');
                    }

                    setTimeout(function() {
                        this.fileListHistory = this.fileListHistory.slice(1);

                        container.removeClass('setup-update-animation');
                        container.removeClass('run-update-animation');

                        old.container.remove();
                        this.app.removeComponent(old.listId);
                    }.bind(this), 300);
                }.bind(this));
            }.bind(this));
        }

        if (useBackAnimation) {
            globalContainer.prepend(container);
        } else {
            globalContainer.append(container);
        }
    },

    postPlaceAt: function() {
        this.createComponent({
            type: 'MediaList',
            container: this.$el.find('.container-for-medialist'),
            configs: {
                focusItem: this.options.configs.focusItem
            }
        });

        this.fileBrowserNavigateTo({
            path: 'Internal:///',
            mountName: 'Internal'
        }, this.options.configs.isClosed);

        this.bindEvents();
    }
});
