'use strict';

require('../../audio-video-statusbar/audio-video-statusbar');

const app = require('../../../../app');
const { playStates, playStatesMapping } = require('../../../../states');

const controlItems = [
    {
        id: 'toggle',
        state: 'play',
        states: {
            play: {
                icon: 'icon-v2-play',
                titleKey: ''
            },
            pause: {
                icon: 'icon-v2-pause',
                titleKey: ''
            },
            stop: {
                icon: 'icon-v2-play',
                titleKey: ''
            }
        }
    },
    {
        id: 'stop',
        state: 'stop',
        states: {
            stop: {
                icon: 'icon-v2-stop',
                titleKey: ''
            }
        },
        groupEnd: true
    },
    {
        id: 'rewind',
        state: 'rewind',
        states: {
            rewind: {
                icon: 'icon-v2-rewind',
                titleKey: '',
                infoKey: ' ' // NOTE: not empty - el needed vor speed text
            }
        }
    },
    {
        id: 'forward',
        state: 'forward',
        states: {
            forward: {
                icon: 'icon-v2-fast-forward',
                titleKey: '',
                infoKey: ' ' // NOTE: not empty - el needed vor speed text
            }
        },
        groupEnd: true
    },
    {
        id: 'loop',
        state: 'loopOff',
        states: {
            loopOff: {
                icon: 'icon-v2-loop',
                titleKey: ''
            },
            loopOn: {
                icon: 'icon-v2-loop',
                isActive: true,
                titleKey: ''
            }
        }
    },
    {
        id: 'mute',
        type: 'mute',
        state: 'mute'
    },
    {
        id: 'volume',
        type: 'volume'
    }
];

const commands = {
    play: 'play',
    pause: 'pause',
    stop: 'stop',
    rewind: 'rewind',
    forward: 'forward',
    loop: 'loopToggle'
};

const loopStates = {
    loopOn: 'loopOn',
    loopOff: 'loopOff'
};

/**
 * Audio frame
 */
app.component('FrameBoxAudioViewer', {
    extend: 'FrameBoxBaseType',
    className: 'framebox-view framebox-audio-view',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.frameBoxService = this.getService('FrameBoxService');
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.animateStateEffect = false;
        this.isActive = true;
        this.playState = this.createStateMachine({
            state: playStates.none,
            states: playStates
        });
        this.loopState = this.createStateMachine({
            state: loopStates.loopOff,
            states: loopStates
        });

        this.addStateTransitions();
    },

    serialize: function() {
        return {
            isFullscreen: this.options.isFullscreen
        };
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.storeSelectors();

        this.startControls();
        this.updateAudioStateHandler(this.options.frameBoxOptions);
        this.bindEvents();
    },

    /**
     * @method storeSelectors
     */
    storeSelectors: function() {
        this.$statusbar = this.options.$actionEl.find('#framebox-progressbar-' + this.index);
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        this.on('framebox.change.' + this.index, this.onFrameBoxChangeHandler.bind(this));
        this.on('framebox.tab.changed', this.onTabChanged.bind(this));

        // Remote
        this.on('framebox.standard.left.keydown', this.controlHandlers['rewind'].bind(this));
        this.on('framebox.standard.right.keydown', this.controlHandlers['forward'].bind(this));
        this.on('framebox.standard.enter.keydown', this.controlHandlers['toggle'].bind(this));
        this.on('framebox.standard.tap', this.controlHandlers['toggle'].bind(this));

        // Standard-mode
        this.on('framebox.standard.tap', this.onClickHandler.bind(this));
    },

    /**
     * @method onFrameBoxChangeHandler
     * @param {Object} frameBox
     */
    onFrameBoxChangeHandler: function(frameBox) {
        this.updateAudioStateHandler(frameBox.options);
    },

    /**
     * Handle tab change (fullscreen)
     *
     * @param options.index Active framebox/tab index
     */
    onTabChanged: function(options) {
        if (this.index === options.index) {
            this.isActive = true;
        } else {
            this.isActive = false;
        }
    },

    /**
     * @method updateAudioStateHandler
     * @param {Object} data
     */
    updateAudioStateHandler: function(data) {
        var state = playStatesMapping[data.playbackState];
        var loopState = data.audioLoop === 1 ? loopStates.loopOn : loopStates.loopOff;

        if ((state === playStates.pause) && (data.audioPosition === 0)) {
            state = playStates.stop;
        }

        if (this.playState.getState() !== state) {
            this.playState.changeState(state);
        }

        if (this.loopState.getState() !== loopState) {
            this.loopState.changeState(loopState);
        }

        this.setAudioSpool(data.audioSpeed);

        if (!this.isActive && this.options.isFullscreen && this.animateStateEffect === true) {
            this.animateStateEffect = false;
        } else if (this.isActive && !this.animateStateEffect) {
            this.animateStateEffect = true;
        }
    },

    /**
     * Set audio spool text.
     *
     * @param {number} speed
     */
    setAudioSpool: function(speed) {
        var txt = Math.abs(speed) + 'x';

        // Reset
        this.emit('framebox.controls.rewind', {
            index: this.options.index,
            text: ''
        });

        this.emit('framebox.controls.forward', {
            index: this.options.index,
            text: ''
        });

        if (speed > 1) {
            this.emit('framebox.controls.forward', {
                index: this.options.index,
                text: txt
            });
        } else if (speed < 0) {
            this.emit('framebox.controls.rewind', {
                index: this.options.index,
                text: txt
            });
        }
    },

    /**
     * @method startControls
     */
    startControls: function() {
        this.createComponent({
            type: 'FrameBoxControls',
            container: this.$el,
            index: this.options.index,
            appId: this.options.appId,
            items: controlItems,
            onClick: this.onControlClickHandler.bind(this),
            isFullscreen: this.options.isFullscreen
        });
    },

    /**
     * @method onClickHandler
     */
    onClickHandler: function(options) {
        if (options.index === this.index) {
            this.controlHandlers['toggle'].call(this, options);
        }
    },

    /**
     * @method onClickHandler
     * @param {string} id
     */
    onControlClickHandler: function(id) {
        var handler = this.controlHandlers[id];

        if (handler) {
            handler.call(this, {
                index: this.index
            });
        }
    },

    /**
     * @type {Object}
     */
    controlHandlers: {
        /**
         * @method toggle
         */
        'toggle': function(options) {
            switch (this.playState.getState()) {
                default:
                case playStates.play:
                    this.controlHandlers['pause'].call(this, options);
                    break;
                case playStates.pause:
                case playStates.stop:
                    this.controlHandlers['play'].call(this, options);
                    break;
                case playStates.forward:
                case playStates.rewind:
                    this.controlHandlers['play'].call(this, options);
            }
        },

        /**
         * @method play
         */
        'play': function(options) {
            if (this.playState.getState() !== playStates.play) {
                this.sendCmd(commands.play, options.index);
            }
        },

        /**
         * @method pause
         */
        'pause': function(options) {
            if (this.playState.getState() !== playStates.pause) {
                this.sendCmd(commands.pause, options.index);
            }
        },

        /**
         * @method stop
         */
        'stop': function(options) {
            if (this.playState.getState() !== playStates.stop) {
                this.sendCmd(commands.stop, options.index);
            }
        },

        /**
         * @method rewind
         */
        'rewind': function(options) {
            this.sendCmd(commands.rewind, options.index);
        },

        /**
         * @method forward
         */
        'forward': function(options) {
            this.sendCmd(commands.forward, options.index);
        },

        /**
         * @method toggle
         */
        'loop': function(options) {
            this.sendCmd(commands.loop, options.index);
        }
    },

    /**
     * @method sendCmd
     * @param {string} cmd
     * @param {int} index
     */
    sendCmd: function(cmd, index) {
        if (this.index === index) {
            app.emit('main-loop.fast.start', {
                id: 'audio'
            });

            this.send('setAudioCmd', {
                cmd: cmd,
                appId: this.options.appId
            });
        }
    },

    /**
     * @method addStateTransitions
     */
    addStateTransitions: function() {
        this.playState.addTransitions({
            '> play': function() {
                this.emit('framebox.control-state.change', {
                    index: this.index,
                    id: 'toggle',
                    state: playStates.pause
                });

                if (this.animateStateEffect) {
                    this.emit('framebox.play-state-effect', {
                        index: this.options.index,
                        className: 'audio-state-play-effect'
                    });
                }
            },

            '> pause': function() {
                this.emit('framebox.control-state.change', {
                    index: this.index,
                    id: 'toggle',
                    state: playStates.play
                });

                if (this.animateStateEffect) {
                    this.emit('framebox.play-state-effect', {
                        index: this.options.index,
                        className: 'audio-state-pause-effect'
                    });
                }
            },

            '> stop': function() {
                this.emit('framebox.control-state.change', {
                    index: this.index,
                    id: 'toggle',
                    state: playStates.stop
                });

                if (this.animateStateEffect) {
                    this.emit('framebox.play-state-effect', {
                        index: this.options.index,
                        className: 'audio-state-stop-effect'
                    });
                }
            },

            '> rewind': function() {
                this.emit('framebox.control-state.change', {
                    index: this.index,
                    id: 'toggle',
                    state: playStates.play
                });
            },

            '> forward': function() {
                this.emit('framebox.control-state.change', {
                    index: this.index,
                    id: 'toggle',
                    state: playStates.play
                });
            }
        });

        this.loopState.addTransitions({
            '> loopOn': function() {
                this.emit('framebox.control-state.change', {
                    index: this.index,
                    id: 'loop',
                    state: loopStates.loopOn
                });
            },

            '> loopOff': function() {
                this.emit('framebox.control-state.change', {
                    index: this.index,
                    id: 'loop',
                    state: loopStates.loopOff
                });
            }
        });
    }
});
