module.exports={

    // NOTE:
    // 100 = AltGr
    "modifier":
    {
        "&#124;": 100, //|
        "&#64;": 100, //@
        "atmark": 100, //@
        "&#35;": 100, //#
        "&#172;": 100, //¬
        "&#166;": 100, //¦
        "&#162;": 100, //¢
        "&#180;": 100, //´
        "&#126;": 100, //~
        "&#8364;": 100, //€
        "&#91;": 100, //[
        "&#93;": 100, //]
        "&#123;": 100, //{
        "&#125;": 100, //}
        "&#92;": 100 //\
    },

    "modifier_mobile":
    {
        "&#176;": 42, //°
        "&#167;": 42, //§
        "&#43;": 42, //+
        "&#34;": 42, //"
        "&#42;": 42, //*
        "&#231;": 42, //ç
        "&#37;": 42, //%
        "&#38;": 42, //&
        "&#47;": 42, ///
        "&#40;": 42, //(
        "&#41;": 42, //)
        "&#61;": 42, //=
        "&#63;": 42, //?
        "&#96;": 42, //`
        "&#232;": 42, //è
        "&#33;": 42, //!
        "&#224;": 42, //à
        "&#163;": 42, //£
        "&#62;": 42, //>
        "&#59;": 42, //;
        "&#58;": 42, //:
        "&#95;": 42 //_
    },


    "ie_specialkeys_mobile": {},

    "ie_specialkeys": {},

    "&#167;": 41, //§
    "&#176;": 41, //°
    "&#43;": 2, //+
    "&#166;": 2, //¦
    "&#34;": 3, //"
    "&#42;": 4, //*
    "&#231;": 5, //ç
    "&#38;": 7, //&
    "&#172;": 7, //¬
    "&#47;": 8, ///
    "&#124": 8, //|
    "&#40;": 9, //(
    "&#162;": 9, //¢
    "&#41;": 10, //)
    "&#61;": 11, //=
    "&#39;": 12, //'
    "&#63;": 12, //?
    "&#180;": 12, //´
    "&#94;": 13, //^
    "&#96;": 13, //`
    "&#126;": 13, //~

    "&#122;": 21, //z
    "&#252;": 26, //ü
    "&#232;": 26, //è
    "&#91;": 26, //[
    "&#96;": 27, //¨
    "&#33;": 27, //!

    "&#246;": 39, //ö
    "&#233;": 39, //é
    "&#228;": 40, //ä
    "&#224;": 40, //à
    "&#123;": 40, //{
    "&#36;": 43, //$
    "&#163;": 43, //£
    "&#125;": 43, //}

    "&#60;": 86, //<
    "&#62;": 86, //>
    "&#92;": 86, //\
    "&#121;": 44, //y
    "&#44;": 51, //,
    "&#59;": 51, //;
    "&#46;": 52, //.
    "&#58;": 52, //:
    "&#45;": 53, //-
    "&#95;": 53, //_

    "&#8364;": 18 //€
}
