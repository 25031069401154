// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <div class=\"row settings-list-secundary clickable folded is-focusable wlan-list-group no-padding\"\n         tabIndex=\"-1\" data-key=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\">\n        <div class=\"col-xs-12 wlan-list-item has-border p-right-left-25\">\n            <div class=\"col-xs-1 wlan-group-icon\">\n                <div class=\"col-xs-4\">\n                    <span class=\"icon-arrow-options folded\"></span>\n                </div>\n                <div class=\"col-xs-8\"></div>\n            </div>\n            <div class=\"col-xs-4\">\n                <h2 class=\"group-name\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</h2>\n            </div>\n            <div class=\"col-xs-7\"></div>\n        </div>\n    </div>\n    <div class=\"row settings-list-secundary no-padding wlan-list-item-container\" data-key=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\"></div>\n</div>\n";
},"useData":true});
