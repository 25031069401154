'use strict';

var BaseView = require('./view');
var tpl = require('./empty.hbs');

function EmptyView(app, configs) {
    BaseView.call(this, app, configs);

    this.template = tpl;
    this.className = configs.className || '';
    this.idName = configs.idName || '';
    this.componentContainer = '#modal-empty-view';
}

EmptyView.prototype = new BaseView();

module.exports = EmptyView;
