'use strict';

const app = require('../../app');
const tpl = require('./annotation.hbs');
const _ = require('lodash');
const platform = require('./../../../platform/platform');
const { quadraticFunction, reverseQuadraticFunction } = require('../../helper');

const annotationStates = {
    started: 'started',
    stopped: 'stopped'
};

const menuStates = {
    open: 'open',
    closed: 'closed'
};

const collabStates = {
    active: 'active',
    inactive: 'inactive'
};

const DURATION = 250;
const SHORT_COLORS = ['red', 'green', 'gold', 'deep-sky-blue'];

/**
 * Annotation
 */
app.component('Annotation', {
    extend: 'AnnotationAbstract',
    template: tpl,
    className: 'submenu-container annotation-container stop-overscroll is-active',

    initialize: function() {
        this.annotationService = this.getService('AnnotationService');
        this.index = this.getIndex();
        this.storage = app.getService('DataStorage');
        this.loadStoredSettings(this.index);
        this.frameBoxService = this.getService('FrameBoxService');
        this.remote = this.getService('RemoteService');
        this.authService = this.getService('AuthenticationService');
        this.liveStream = this.getService('LiveStreamService');
        this.stationService = this.getService('StationService');
        this.deviceService = this.getService('DeviceService');

        this.menuState = this.createStateMachine({
            states: menuStates,
            state: menuStates.closed
        });
        this.addStateTransitions();

        this.pauseDialogShown = false;
        this.stopAction = false;
        this.position.initialize(this, this.$el);
    },

    serialize: function() {
        return {
            collabUser: this.authService.getIsCollab(),
            matrixCollabUser: this.annotationService.isMatrixAnnotation(),
            isCboxPure: this.deviceService.isCboxPure()
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.startAnnotation();
        this.bindEvents();
        this.bindDOMEvents();
        this.createRangeInputs();
        this.setActiveSettings();

        this.toolUpdateHandler();
        this.drawer();
        this.sendSettings();
        this.eventCalled = false;
        this.checkCollaboration();
        this.checkGroups();
        this.updateUiScaling({
            scaling: this.liveStream.scaling
        });

        if (this.annotationService.autoShowAnnotationMenu) {
            this.openMenu();
        }
    },

    storeSelectors: function() {
        this.$submenuContainer = this.$el.parent();
        this.$menu = this.$el.find('.annotation-menu-container');
        this.$menuInner = this.$el.find('#annotation-menu-container-inner');
        this.$menuBtn = this.$el.find('.toggle-annotation-menu');
        this.$undoEl = this.$el.find('[data-action="undo"]');
        this.$redoEl = this.$el.find('[data-action="redo"]');
        this.$clearAllEl = this.$el.find('[data-action="clear-all"]');
        this.$collab = this.$el.find('.annotation-collaboration');
        this.$collabSwitch = this.$el.find('.enable-collaboration');
        this.$pinEl = this.$el.find('.pin-container');
        this.$userContainer = this.$el.find('.user-container');
        this.$userList = this.$el.find('.user-list-container');
        this.$userName = this.$el.find('.annotation-username');
        this.$userState = this.$el.find('.annotation-state');
        this.$userStateIcon = this.$el.find('.annotation-user-state-point');
        this.$strength = this.$el.find('#range-stroke-strength');
        this.$collabTools = this.$el.find('#collaboration-group-container');
        this.$collabUsers = this.$el.find('#collaboration-user-group-container');
        this.$closeButton = this.$el.find('.close-button');
    },

    bindEvents: function() {
        this.on('remote.quick-settings.keyup', _.debounce(this.toggleMenu.bind(this), 400, true));
        this.$(window).on('beforeunload', this.stopDraw.bind(this));
        this.on('remote.snapshot', this.actionHandlers['snapshot'].bind(this));
        this.on('remote.framebox.focus', this.selectColor.bind(this));
        this.on('annotation.cleared', this.openMenu.bind(this));
        this.on('annotation.open.menu', this.openMenu.bind(this));
        this.on('modal.opened', this.modalOpenedHandler.bind(this));
        this.on('collaboration.state.change', this.showPin.bind(this));
        this.on('ui-scaling.update', this.updateUiScaling.bind(this));
        this.on('control-center.open', this.toggleMenu.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.find('#annotation-select-color .color, #annotation-select-color .annotation-color').on('click', this.actionHandlers['select-color'].bind(this));
        this.$collabSwitch.on('click', this.toggleCollaboration.bind(this));
        this.$userList.find('[data-action]').on('click', this.actionHandler.bind(this));
        this.$menu.find('[data-action]').on('click', this.actionHandlerClick.bind(this));
        this.$closeButton.on('click', this.toggleMenu.bind(this));
        this.$(document).on('keyup', this.keycodeHandler.bind(this));

        // Deactivate action handler during drawing.
        this.$el.on('touchmove', function() {
            this.stopAction = true;
        }.bind(this));

        // Activate action handler after use has stopped drawing. (RELEASE-963)
        this.$el.on('touchend', function() {
            this.stopAction = false;
        }.bind(this));

        if (!platform.checks.isEdge) {
            this.$menu.find('[data-action]').on('touchend', this.actionHandlerTouch.bind(this));
        }
    },

    /**
     * Sets the new scaling to the menu items.
     *
     * @param options
     */
    updateUiScaling: function(options) {
        var scaling = options.scaling;

        this.$menuInner.css({
            transform: 'scale(' + scaling + ')',
            '-webkit-transform': 'scale(' + scaling + ')',
            '-moz-transform': 'scale(' + scaling + ')',
            'width': (this.$menu.width() - 20) / scaling + 'px', // 20px padding,
            'padding-top': '40px'
        });

        this.$menu.css({
            height: 302 * scaling + 'px'
        });
    },

    checkGroups: function() {
        _.each(this.$el.find('.group-title'), function(group) {
            if (group.getBoundingClientRect().height > 18) {
                group.classList.add('multiline');
            }
        }.bind(this));
    },

    /**
     * @param {object} event
     */
    keycodeHandler: function(e) {
        if (e.keyCode === 27) { // Stop drawing when pressing esc RELEASE-994
            this.stopDraw();

            this.currentMode = null;
            this.position.state = 'watching';
        }
    },

    /**
     * Show hide collaboration.
     */
    checkCollaboration: function() {
        this.authService.getLoginRequired().then(function(data) {
            if ((data.password || this.annotationService.isMatrixMasterAnnotation())
                && !this.authService.getIsCollab()) {
                this.$collab.removeClass('hidden');
                this.$userContainer.removeClass('hidden');

                if (this.annotationService.isMatrixMasterAnnotation()) {
                    this.$collabTools.addClass('hidden');
                    this.$collabUsers.addClass('has-title');

                    // No collaboartion user allowed if matrix collaboration is active.
                    this.updateAnnotationPin(false);
                } else {
                    this.$collabTools.removeClass('hidden');
                    this.$collabUsers.removeClass('has-title');
                }
            } else {
                this.$collab.addClass('hidden');
                this.$userContainer.addClass('hidden');
                this.$collabTools.addClass('hidden');
                this.$collabUsers.addClass('hidden');
            }
        }.bind(this));
    },

    /**
     * Change collaboration state
     *
     * @param {object} event
     */
    toggleCollaboration: function($event) {
        this.updateAnnotationPin($event.target.checked);
    },

    updateAnnotationPin: function(state) {
        if (state) {
            this.annotationService.collabState.changeState(collabStates.active);
        } else {
            this.annotationService.collabState.changeState(collabStates.inactive);
        }

        this.deviceConnection
            .send('setAnnotationPin', {
                collaboration: state
            });

        this.emit('main-loop.fast.start', {
            id: 'annotation'
        });

        if (!this.annotationService.isMatrixMasterAnnotation()) {
            this.blockUpdates(1000);
        }
    },

    /**
     * Update collaboration state.
     *
     * @param {object} data.pin
     * @param {object} data.showPin
     */
    updateCollabState: function(data) {
        if (data.showPin !== this.$collabSwitch.is(':checked') || data.pin !== this.$pinEl.find('.annotation-pin').html()) {
            this.$pinEl.find('.annotation-pin').html(data.pin ? data.pin : '');

            if (this.blockUpdate) {
                return;
            }

            this.$collabSwitch.prop('checked', data.showPin);
            this.annotationService.collabState.changeState(data.showPin ? collabStates.active : collabStates.inactive);
        }
    },

    /**
     * @param {object} options.index
     */
    selectColor: function(options) {
        var color = SHORT_COLORS[options.index];
        var $el = this.$('#annotation-select-color .color[data-color="' + color + '"]');

        this.$el.find('.color').removeClass('active');
        this.$el.parent().find('.color').removeClass('active');
        $el.addClass('active');
        $el.parent().addClass('active');
        this.updateRGBFromDom();

        this.sendSettings();
    },

    hideUi: function() {
        if (this.menuState.getState() === menuStates.open) {
            this.$menu.removeClass('open');
        }

        this.emit('annotation.action.painting');
    },

    showUi: function() {
        if (this.menuState.getState() === menuStates.open) {
            this.$menu.addClass('open');

            if (platform.checks.isCbox) {
                this.remote.focusLast.bind(this.remote);
            }

            this.emit('control-center.opened');
        }

        this.emit('annotation.action.watching');
    },

    /**
     * Return annotation settings.
     *
     * @returns {object}
     */
    getSettings: function() {
        return {
            rgb: this.rgb,
            tool: this.tool,
            alpha: this.opacity,
            penSize: this.strength,
            eraserStrength: this.eraserStrength
        };
    },

    /**
     * Set active annotation settings.
     *
     * This method set the active settings in the DOM.
     */
    setActiveSettings: function() {
        var $toolEl;
        var $colorEl;

        if (this.tool) {
            $toolEl = this.$el.find('.annotation-control[data-tool="' + this.tool + '"]');
            this.$('[data-tool]').removeClass('active');
            $toolEl.addClass('active');
        }

        if (this.colorString) {
            $colorEl = this.$el.find('.annotation-color [data-color="' + this.colorString + '"]');
            this.$el.find('.color').removeClass('active');
            $colorEl.addClass('active');
        }
    },

    /**
     * This method gets the currently set rgb values from the active color-selector in the DOM
     *
     * @returns {object}
     */
    updateRGBFromDom: function() {
        var $el = this.$('#annotation-select-color .color.active');
        var color = $el.css('background-color');
        var colorsArray;

        if (color) {
            colorsArray = color.match(/\d+/g);

            this.colorString = $el.data('color');
            this.rgb = {
                red: colorsArray[0],
                green: colorsArray[1],
                blue: colorsArray[2]
            };
        }
    },

    /**
     * Open/Close Annotation menu.
     */
    toggleMenu: function() {
        if (this.annotationService.getState() === annotationStates.started) {
            if (this.menuState.getState() === menuStates.closed) {
                this.openMenu();
            } else {
                this.closeMenu();
            }
        }
    },

    /**
     * Open Annotation menu.
     */
    openMenu: function() {
        this.menuState.changeState(menuStates.open);
    },

    /**
     * Close Annotation menu.
     */
    closeMenu: function() {
        this.menuState.changeState(menuStates.closed);

        if (this.authService.getIsCollab()
            && this.deviceConnection
            && this.deviceConnection.connection
            && this.deviceConnection.connection.connection
            && this.deviceConnection.connection.connection.connection
            && this.deviceConnection.connection.connection.connection.readyState === this.deviceConnection.connection.connection.connection.CLOSED
        ) {
            window.location.reload();
        }
    },

    modalOpenedHandler: function() {
        const modalId = this.getService('ModalHandlerService').getOpenModalId();
        const modalIdsWhereCloseIsNotRequired = [
            'end-annotation',
            'pause-annotation'
        ];

        if (modalIdsWhereCloseIsNotRequired.indexOf(modalId) < 0) {
            this.closeMenu();
        }
    },

    addStateTransitions: function() {
        this.menuState.addTransitions({
            '> open': function() {
                this.$menu.addClass('open');
                this.emit('modal.close');
                this.emit('control-center.opened');

                if (platform.checks.isCbox) {
                    this.remote.focusLast();
                }
            },

            '> closed': function() {
                this.$menu.removeClass('open');
                this.emit('control-center.closed');

                if (platform.checks.isCbox) {
                    this.remote.focusDefault();
                }
            }
        });
    },

    /**
     * Start Annotation.
     */
    startAnnotation: function() {
        if (this.annotationService.getState() !== annotationStates.started) {
            this.storage.set('annotation.state', annotationStates.started);
            app.$el.find('#submenu-container').addClass('is-active');

            this.animateStart();
            this.stopComponents();
            this.liveStream.unpause();

            if (!this.authService.getIsCollab()) {
                this.deviceConnection
                    .send('setAnnotationAction', {
                        action: 'enableAnnotation',
                        planeIndex: this.index
                    });
            }

            this.deviceConnection
                .send('setAnnotationAction', {
                    action: 'connect',
                    planeIndex: this.index
                });

            if (!this.annotationService.isMatrixAnnotation()) {
                if (!platform.checks.isCbox) {
                    this.deviceConnection
                        .send('setAnnotationAction', {
                            action: 'nickname',
                            planeIndex: this.index,
                            nickname: this.storage.get('nickname')
                        });
                }

                this.deviceConnection
                    .send('getAnnotationUsers', {
                        planeIndex: this.index,
                        allUsers: false
                    })
                    .then(function(data) {
                        if (!!data.userList && data.userList.length === 1) {
                            this.storage.set('clientId', data.userList[0].clientId);
                            this.$userName.html(data.userList[0].nick);
                            this.updateUserState(data.userList[0]);
                        }

                        this.emit('main-loop.fast.start', {
                            id: 'annotation'
                        });
                    }.bind(this));
            }

            this.emit('show.ui', true);
        }
    },

    /**
     * Stop Annotation.
     */
    stopAnnotation: function() {
        if (this.annotationService.getState() !== annotationStates.stopped) {
            this.animateStop();

            this.startComponents();
        }
    },

    /**
     * Remove Components.
     */
    stopComponents: function() {
        app.removeComponent('frameboxes');
        app.removeComponent('menu');
        app.removeComponent('controlCenter');
        app.removeComponent('file-browser');
    },

    /**
     * Start Components.
     */
    startComponents: function() {
        if (!this.authService.getIsCollab()) {
            this.emit('main-loop.fast.start', {
                id: 'freeze'
            });

            this.emit('main-loop.fast.start', {
                id: 'status-bar'
            });

            app.createComponent('frameboxes', {
                type: 'Frameboxes',
                container: '#frameboxes-container'
            });

            app.createComponent('menu', {
                type: 'SourcesMenu',
                container: '#sources-menu-container'
            });

            app.createComponent('controlCenter', {
                type: 'ControlCenterV3',
                container: '#control-center'
            });

            app.createComponent('file-browser', {
                type: 'FileBrowserV3',
                container: '#file-browser-container'
            });
        } else {
            this.stopComponents();
        }
    },

    /**
     * @param event
     */
    actionHandler: function(event) {
        var $el = this.$(event.currentTarget);
        var action = $el.data('action');
        var handler = this.actionHandlers[action];

        if (!!handler && !this.stopAction) {
            handler.call(this, $el);
        }

        this.stopAction = false;
    },

    /**
     * This method is used to check if a touch or click event was already fired within 200ms
     * (needed especially for chrome on windows devices with touch and mouse enabled)
     *
     * @param event
     */
    actionHandlerClick: function(event) {
        if (!this.eventCalled) {
            this.eventCalled = true;

            setTimeout(function() {
                this.eventCalled = false;
            }.bind(this), 200);

            this.actionHandler(event);
        }
    },

    /**
     * This method is used to check if a touch or click event was already fired within 200ms
     * (needed especially for chrome on windows devices with touch and mouse enabled)
     *
     * @param event
     */
    actionHandlerTouch: function(event) {
        if (!this.eventCalled) {
            this.eventCalled = true;

            setTimeout(function() {
                this.eventCalled = false;
            }.bind(this), 200);

            this.actionHandler(event);
        }
    },

    /**
     * Show menu animation.
     */
    animateStart: function() {
        app.$el.find('#submenu-container').addClass('is-active');

        this.$el
            .fadeIn({
                duration: DURATION
            });

        this.$submenuContainer.show();
    },

    /**
     * Hide menu animation.
     */
    animateStop: function() {
        this.$el
            .fadeOut({
                duration: DURATION,
                complete: function() {
                    // RELEASE-2624: If Annotation is started again
                    if (this.annotationService.getState() === annotationStates.started) {
                        return;
                    }

                    app.$el.find('#submenu-container').removeClass('is-active');
                }.bind(this)
            });
    },

    createRangeInputs: function() {
        var step = 5;
        var strokeStrength = 'rubber' === this.tool ? this.eraserStrength : this.strength;

        this.createComponent({
            type: 'RangeInput',
            container: this.$el.find('#range-stroke-strength'),
            input: {
                redesign: true,
                changeOnEnter: true,
                // Calculate multiplier of step by given strength
                value: Math.ceil(reverseQuadraticFunction(parseInt(strokeStrength)) / step) * step,
                min: 0,
                minIcon: 'icon-v2-line-width-light',
                max: 100,
                maxIcon: 'icon-v2-line-width-strong',
                step: step,
                onChange: this.onChangeStrength.bind(this),
                onEnterPress: this.onEnterPress.bind(this)
            }
        });

        this.createComponent({
            type: 'RangeInput',
            container: this.$el.find('#range-stroke-opacity'),
            input: {
                redesign: true,
                changeOnEnter: true,
                value: this.opacity,
                min: 0,
                minIcon: 'icon-v2-opacity-0',
                max: 255,
                maxIcon: 'icon-v2-opacity-100',
                step: 5,
                onChange: this.onChangeOpacityHandler.bind(this),
                onEnterPress: this.onEnterPress.bind(this)
            }
        });
    },

    /**
     * Change Strength.
     *
     * @param {int} strength
     */
    onChangeStrength: function(strength) {
        if ('rubber' === this.tool) {
            this.eraserStrength = quadraticFunction(parseInt(strength));
        } else {
            this.strength = quadraticFunction(parseInt(strength));
        }

        this.position.currentMode = null;

        this.sendSettings();
    },

    /**
     * Change Opacity.
     *
     * @param {int} opacity
     */
    onChangeOpacityHandler: function(opacity) {
        this.opacity = opacity;
        this.position.currentMode = null;

        this.sendSettings();
    },

    onEnterPress: function() {
        this.sendSettings();
    },

    /**
     * Show/Hide pin button.
     *
     * @param state
     */
    showPin: function(state) {
        if (state === collabStates.active) {
            this.$pinEl.removeClass('hidden');
        } else {
            this.$pinEl.addClass('hidden');
        }
    },

    destroy: function() {
        if (this.checkComponentAccess()) {
            this.hideUi();
            this.stopDraw();
            this.stopAnnotation();
        }

        this.$(window).off('beforeunload');
        app.emit('control-center.closed');
    }
});
