'use strict';

var app = require('../../../app');
var tpl = require('./matrix.hbs');

app.component('MatrixModal', {
    template: tpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.timeout = this.options.configs.timeout || 5000;
        this.onFinish = this.options.configs.onFinish || this.$.noop;
    },

    serialize: function() {
        return {
            loader: this.options.configs.loader,
            messageKey: this.options.configs.messageKey
        };
    },

    postPlaceAt: function() {
        this.startAutoClose();
    },

    /**
     * Start timeout for automatically closing this modal.
     */
    startAutoClose: function() {
        clearTimeout(this.closeTimeout);

        this.closeTimeout = setTimeout(function() {
            this.onFinish();

            this.emit('modal.close', {
                id: 'matrix'
            });
        }.bind(this), this.timeout);
    },

    destroy: function() {
        clearTimeout(this.closeTimeout);
    }
});
