'use strict';

var $ = require('jquery');
var mirrorPinTpl = require('./pin-flap.hbs');
var app = require('../../app');
var platform = require('../../../platform/platform');

/**
 * Mirror Pin (Status Bar)
 */
app.component('PinFlap', {
    template: mirrorPinTpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.deviceService = app.getService('DeviceService');
        this.authService = this.getService('AuthenticationService');

        this.bindEvents();
    },

    bindEvents: function() {
        this.on('mirror-pin.update', this.updateMirrorPinHandler.bind(this));
        this.on('moderator-pin.update', this.updateModeratorPinHandler.bind(this));
        this.on('connection-id.update', this.updateConnectionIdHandler.bind(this));
        this.on('status-bar.destroy', this.repositionFlap.bind(this));
    },

    postPlaceAt: function() {
        this.storeSelectors();

        if (true === platform.checks.isCbox) {
            this.$container.addClass('is-big');
        }

        this.$container.attr('data-size', this.options.pinSize);
        this.$pinContainer.hide();
        this.$mirrorPin.hide();
        this.$moderatorPin.hide();
        this.$connectionId.hide();

        this.width = this.$pinContainer.width();
    },

    storeSelectors: function() {
        this.$container = this.$el.parent();
        this.$pinContainer = this.$el.find('#pin-container-inner');
        this.$moderatorPin = this.$el.find('#moderator-pin');
        this.$mirrorPin = this.$el.find('#mirror-pin');
        this.$connectionId = this.$el.find('#connection-id');
    },

    /**
     * Handle update Mirror PIN.
     *
     * @param show true - show PIN, false - hide PIN
     * @param pin Mirror PIN
     */
    updateMirrorPinHandler: function(show, pin) {
        this.mirrorPinShown = show;

        $.when(this.showHideComponent()).done(function() {
            if (this.mirrorPinShown) {
                this.$mirrorPin.find('.pin-view').html(pin);
                this.$mirrorPin.show();
            } else {
                this.$mirrorPin.hide();
            }
        }.bind(this));
    },

    /**
     * Handle update Moderator PIN.
     *
     * @param show true - show PIN, false - hide PIN
     * @param pin Moderator PIN
     */
    updateModeratorPinHandler: function(show, pin) {
        this.moderatorPinShown = show;
        $.when(this.showHideComponent()).done(function() {
            if (this.moderatorPinShown) {
                this.$moderatorPin.find('.pin-view').html(pin);
                this.$moderatorPin.show();
            } else {
                this.$moderatorPin.hide();
            }
        }.bind(this));
    },

    /**
     * Handle update Connection ID.
     *
     * @param show true - show ID, false - hide ID
     * @param id Connection ID
     */
    updateConnectionIdHandler: function(show, id) {
        this.connectionIdShown = show;
        $.when(this.showHideComponent()).done(function() {
            if (this.connectionIdShown) {
                this.$connectionId.find('.pin-view').html(id);
                this.$connectionId.show();
            } else {
                this.$connectionId.hide();
            }
        }.bind(this));
    },

    /**
     * Show or hide pin component (flap)
     */
    showHideComponent: function() {
        let dfd = this.$.Deferred();

        if (!this.mirrorPinShown && !this.moderatorPinShown && !this.connectionIdShown) {
            this.$pinContainer
                .stop()
                .slideUp(300, function() {
                    dfd.resolve();
                }.bind(this));

            return dfd.promise();
        }

        this.$pinContainer
            .stop()
            .slideDown(300);

        dfd.resolve();

        return dfd.promise();
    },

    /**
     * Reposition flap to the top when status bar gets destroyed.
     */
    repositionFlap: function() {
        this.$container.css('top', 0);
    }
});
