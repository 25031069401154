'use strict';

var _ = require('lodash');
var app = require('../app');
var sprintf = require('./../helper').sprintf;
var DateLib = require('./../date');
var $ = require('jquery');
var dateLib = require('../date.js');
var StateMachine = require('./../state-machine');
var i18n = require('i18next');
var platform = require('../../platform/platform');

const connectionStates = require('../states').connectionStates;

var meetingDeadlineStates = {
    ok: 'ok',
    warning: 'warning',
    critical: 'critical'
};

var AD_HOC_MIN_TIME = 15; // In minutes

/**
 * Session Management Service.
 */
app.service('SessionManagementService', function() {
    return {
        initialize: function() {
            this.authService = app.getService('AuthenticationService');
            this.frontendSettings = app.getService('FrontendSettings');
            this.screensaverService = app.getService('ScreensaverService');
            this.deviceService = app.getService('DeviceService');

            this.loadFrontendSettings();

            this.darkUi = false;
            this.wallpaper = '';
            this.activeSessionPin = null;
            this.hasValidTimeframes = true;
            this.timeframes = {
                15: {
                    min: 15,
                    text: i18n.t('session_management.15_min'),
                    valid: true
                },
                30: {
                    min: 30,
                    text: i18n.t('session_management.30_min'),
                    valid: true
                },
                45: {
                    min: 45,
                    text: i18n.t('session_management.45_min'),
                    valid: true
                },
                60: {
                    min: 60,
                    text: i18n.t('session_management.60_min'),
                    valid: true
                }
            };

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;

                    // Only check on devices which do support vMeeting
                    if (!this.deviceService.isCboxPure() && !this.deviceService.isCboxPureMini()) {
                        this.updateHandler();
                    }

                    this.bindEvents();
                }.bind(this));

            this.deadlineState = new StateMachine({
                context: this,
                state: meetingDeadlineStates.ok,
                states: meetingDeadlineStates
            });

            this.connectionState = new StateMachine({
                context: this,
                state: null,
                states: connectionStates
            });

            this.addStateTransitions();

            this.timeLeft = {
                'h': '00',
                'm': '00',
                's': '00'
            };

            this.vmeetingSettingsLogin = false;
        },

        bindEvents: function() {
            app.on('main-loop.update.session-management', this.updateHandler.bind(this));
            app.on('main-loop.update', this.updateHandler.bind(this));

            app.on('vmeeting-settings.login', function(data) {
                this.vmeetingSettingsLogin = data.login;
            }.bind(this));
        },

        /**
         * Load Frontend Settings and update ui theme.
         *
         */
        loadFrontendSettings: function() {
            this.frontendSettings.getSettings([
                'vMeetingDarkUi',
                'vMeetingWallpaper',
                'showVMeetingStatusBar',
                'activeSessionPin'
            ])
                .then(function(colorThemeDark, wallpaper, statusBar, pin) {
                    if (colorThemeDark !== this.darkUi || statusBar !== this.showStatusBar) {
                        this.darkUi = colorThemeDark;
                        this.showStatusBar = statusBar;

                        app.emit('session-mgmt.update-ui');
                    }

                    this.activeSessionPin = pin;

                    if (wallpaper !== this.wallpaper) {
                        this.wallpaper = wallpaper;
                        $.when(this.loadWallpaper()).done(function(result) {
                            app.emit('session-mgmt.update-wallpaper', result);
                        }.bind(this));
                    }
                }.bind(this));
        },

        /**
         * Load vMeeting custom Wallpaper from storage and create object URL from image data.
         *
         * @returns {*}
         */
        loadWallpaper: function() {
            var dfd = $.Deferred();

            const lightYellowUrl = '../images/vmeeting_bg_light_yellow.jpg';
            const lightRedUrl = '../images/vmeeting_bg_light_red.jpg';

            const defaultWallpaperUrl = this.deviceService.isCboxVideoBar() ? lightRedUrl : lightYellowUrl;

            switch (this.wallpaper) {
                case 'custom':
                    this.loadCustomWallpaper(defaultWallpaperUrl)
                        .then(function(url) {
                            dfd.resolve('url(' + url + ')');
                        });
                    break;
                case 'wvlight':
                    dfd.resolve(`url(${defaultWallpaperUrl})`);
                    break;
            }

            return dfd.promise();
        },

        /**
         * Load custom wallpaper and returns as data url.
         *
         * @param {String | null} defaultUrl
         * @return {PromiseLike<T> | Promise<T>}
         */
        loadCustomWallpaper: function(defaultUrl) {
            return this.deviceConnection
                .send([
                    {
                        command: 'getExtraSettingsFile',
                        data: {
                            filename: 'vMeetingCustomWallpaper'
                        }
                    }
                ])
                .then(function(data) {
                    if (data) {
                        var blob = new Blob([data.file], {
                            type: 'image/jpeg'
                        });

                        return URL.createObjectURL(blob);
                    } else {
                        return defaultUrl;
                    }
                }.bind(this));
        },

        /**
         * Save wallpaper file to storage.
         *
         * @param id Template ID
         * @param file Uploaded wallpaper file
         */
        setVMeetingWallpaper: function(file) {
            if (file) {
                var reader = new FileReader();

                reader.onload = function(event) {
                    this.deviceConnection
                        .send([
                            {
                                command: 'setExtraSettingsFile',
                                data: {
                                    filename: 'vMeetingCustomWallpaper',
                                    file: event.target.result
                                }
                            }
                        ]);
                }.bind(this);

                reader.readAsArrayBuffer(file);
            }
        },

        updateHandler: function() {
            var dfd = $.Deferred();

            this.deviceConnection
                .send([
                    'getVMeetingFunction',
                    'getVMeetingConnectionStatus'
                ])
                .then(function(vMeeting, status) {
                    // RELEASE-3805: send session-mgmt.close if we are on a remote browser an no statusbar is initialized.
                    if (this.vMeetingEnabled !== vMeeting.enabled || (platform.checks.isRemote && !app.componentInstances['statusBar'])) {
                        this.vMeetingEnabled = vMeeting.enabled;

                        if (!this.vMeetingEnabled) {
                            this.activeSessionId = '';
                            this.meetingList = [];

                            app.emit('session-mgmt.close', true);

                            app.emit('frontend-osd-message.hide', {
                                id: 'vmeeting-service'
                            });
                        }
                    }

                    if (status.status !== this.connectionState.getState()) {
                        this.connectionState.changeState(status.status);

                        app.emit('session-mgmt.update.status', status.status);

                        // Re-open vMeeting settings overlay after authentication completed
                        if (this.vmeetingSettingsLogin && status.status !== connectionStates.loggingIn) {
                            app.emit('overlay.open', {
                                id: 'vmeeting-settings',
                                extendedConfigs: 'settings-list'
                            });

                            this.vmeetingSettingsLogin = false;
                        }
                    }

                    if (!this.vMeetingEnabled) {
                        dfd.resolve();

                        return dfd.promise();
                    }

                    var tempId = null;
                    var tempPin = '';

                    if (this.activeSessionId) {
                        tempId = this.activeSessionId;
                        tempPin = this.activeSessionId === 'Ad-Hoc-Meeting' ? ''
                            : !this.activeSessionPin ? 0 : this.activeSessionPin;
                    }

                    this.deviceConnection
                        .send([
                            'getTimeDate',
                            'getDateFormat',
                            'getTimezoneUTCOffset',
                            {
                                command: 'getVMeetingMeetingList',
                                data: {
                                    id: tempId,
                                    pin: tempPin
                                }
                            },
                            'getVMeetingActiveSession',
                            'getVMeetingDeadlineStatus',
                            'getVMeetingUnbookedDuration'
                        ])
                        .then(function(dateTime, dateFormat, timezone, data, activeSession, deadline, unbookedDuration) {
                            var update = false;
                            var screensaverActive = (
                                this.screensaverService.isScreensaverActiveUI()
                                || this.screensaverService.isScreensaverActive()
                            );

                            if (!this.wallpaper) {
                                this.loadFrontendSettings();
                            }

                            this.unbookedDuration = unbookedDuration.duration;
                            this.updateTimeframes();

                            if (this.dateTime && dateTime.day !== this.dateTime.day) {
                                update = true;
                            }

                            this.dateTime = dateTime;

                            if (this.timezoneOffset !== timezone.offset) {
                                this.timezoneOffset = timezone.offset;

                                update = true;
                            }

                            if (!DateLib.equalDates(this.date, dateTime)) {
                                this.date = dateTime;
                                this.date.hour = this.date.minute = 0;
                            }

                            this.frontendSettings.getSettings('activeSessionPin').then(function(result) {
                                if (result && result !== this.activeSessionPin && !this.activeSessionPin && !this.activeSessionId
                                || (result && this.activeSessionId)) {
                                    this.activeSessionPin = result;
                                }
                            }.bind(this));

                            if (this.screensaverActive !== screensaverActive) {
                                this.screensaverActive = screensaverActive;

                                if (this.screensaverActive) {
                                    app.emit('session-mgmt.close', true);
                                } else if (!this.activeSessionId && !this.authService.getIsAdmin()) {
                                    app.emit('session-mgmt.open', true);
                                }
                            }

                            if (this.screensaverActive) {
                                dfd.resolve();

                                return dfd.promise();
                            }

                            if (this.activeSessionId !== activeSession.activeSessionId) {
                                this.activeSessionId = activeSession.activeSessionId;

                                if (this.activeSessionId) {
                                    app.emit('session-mgmt.close', true);

                                    app.emit('control-center.close');
                                } else if (!this.authService.getIsAdmin()) {
                                    app.emit('session-mgmt.open', true);
                                }
                            } else if (this.isAdmin !== this.authService.getIsAdmin()) {
                                this.isAdmin = this.authService.getIsAdmin();

                                if (this.isAdmin) {
                                    app.emit('session-mgmt.close', true);

                                    app.emit('frontend-osd-message.hide', {
                                        id: 'vmeeting-service'
                                    });
                                }
                            }

                            this.dateFormat = dateFormat.dateFormat;

                            if (this.activeSessionId) {
                                var timeLeft = this.calcRemainingTimeFromNow(this.getActiveSessionDetails().end);

                                if (!_.isEqual(timeLeft, this.timeLeft)) {
                                    this.timeLeft = timeLeft;

                                    if (parseInt(this.timeLeft.m) > 0) {
                                        app.emit('osd-message-interactive.update', {
                                            id: 'vmeeting',
                                            osdMessage: sprintf(i18n.t('session_management.meeting_ends'), [this.timeLeft.m]),
                                            icon: 'vmeeting'
                                        });
                                    }
                                }
                            } else if (data.list && data.list.length > 0) {
                                // Filter already ended meetings
                                data.list = data.list.filter(function(item) {
                                    return item.state === 0;
                                }.bind(this));
                            }

                            if (!_.isEqual(this.meetingList, data.list)) {
                                this.meetingList = data.list;

                                update = true;
                            }

                            if (this.deadlineState.getState() !== deadline.status) {
                                this.deadlineState.changeState(deadline.status);
                            }

                            // If timezone or meeting list changed.
                            if (update) {
                                app.emit('session-mgmt.update', this.meetingList);
                            }

                            dfd.resolve();
                        }.bind(this), function() {
                            dfd.reject();
                        });
                }.bind(this));

            return dfd.promise();
        },

        /**
         * Get current date.
         * @returns {
         *              year: {Number},
         *              month: {Number},
         *              day: {Number},
         *              hour: 0 {Number},
         *              minute: 0 {Number}
         *          }
         */
        getDate: function() {
            return this.date;
        },

        /**
         * Get current date and time.
         * @returns {
         *              year: {Number},
         *              month: {Number},
         *              day: {Number},
         *              hour: {Number},
         *              minute: {Number}
         *          }
         */
        getDateTime: function() {
            return this.dateTime;
        },

        /**
         * Get timezone offset in minutes.
         * @returns Offset in minutes.
         */
        getTimezoneOffset: function() {
            return this.timezoneOffset;
        },

        /**
         * Check if meeting starts today.
         *
         * @param timestamp
         */
        startsToday: function(timestamp) {
            var start = new Date(timestamp * 1000);

            return (start.getUTCFullYear() <= this.date.year
                && start.getUTCMonth() + 1 <= this.date.month
                && start.getUTCDate() <= this.date.day);
        },

        /**
         * Calculate timediff (hh:mm) from now.
         *
         * @param tsCalc (Timestamp to subtract from now)
         * @returns {*|{h: *, m: *, s: *}}
         */
        calcRemainingTimeFromNow: function(tsCalc) {
            return this.secondsToTime(this.getSecondsFromNow(tsCalc));
        },

        /**
         * Get timediff in seconds from now.
         *
         * @param tsCalc (Timestamp to subtract from now)
         * @returns {seconds}
         */
        getSecondsFromNow: function(tsCalc) {
            return (tsCalc - dateLib.convertToUnixTimestamp(this.dateTime, this.timezoneOffset));
        },

        /**
         * Convert seconds to h/m/s object
         *
         * @param secs
         * @returns {{h: *, m: *, s: *}}
         */
        secondsToTime: function(secs) {
            if (!secs) {
                return {
                    'h': '00',
                    'm': '00',
                    's': '00'
                };
            }

            secs = Math.round(secs);
            var hours = Math.floor(secs / (60 * 60));

            var divisorForMinutes = secs % (60 * 60);
            var minutes = Math.floor(divisorForMinutes / 60);

            var divisorForSeconds = divisorForMinutes % 60;
            var seconds = Math.ceil(divisorForSeconds);

            if (hours < 0 || minutes < 0 || seconds < 0) {
                return {
                    'h': '00',
                    'm': '00',
                    's': '00'
                };
            }

            return {
                'h': hours < 10 ? '0' + hours : hours,
                'm': minutes < 10 ? '0' + minutes : minutes,
                's': seconds < 10 ? '0' + seconds : seconds
            };
        },

        /**
         * Get date format.
         * @returns 'MMDDYYYY/12H' or 'DDMMYYYY/24H'
         */
        getDateFormat: function() {
            return this.dateFormat;
        },

        /**
         * Get list of meetings.
         *
         * @returns {*[]|*}
         */
        getMeetings: function() {
            return this.meetingList;
        },

        /**
         * Set focused meeting index (first meeting which is shown in meeting list)
         *
         * @param index Meeting index
         */
        setFocusedMeetingIndex: function(index) {
            this.focusedMeetingIndex = index;

            app.emit('session-mgmt.meeting-list.focus.changed', this.meetingList);
        },

        /**
         * Get focused meeting index (first meeting which is shown in meeting list)
         *
         * @returns {*} index of focused meeting
         */
        getFocusedMeetingIndex: function() {
            if (this.focusedMeetingIndex === undefined) {
                return (this.focusedMeetingIndex = -1);
            }

            return this.focusedMeetingIndex;
        },

        /**
         * Get active session id.
         * @returns active session id
         */
        getActiveSessionId: function() {
            return this.activeSessionId;
        },

        /**
         * Check if there is an active session running.
         *
         * @returns {boolean} true/false
         */
        isSessionActive: function() {
            return (this.activeSessionId !== '' && this.activeSessionId !== undefined);
        },

        addStateTransitions: function() {
            this.deadlineState.addTransitions({
                '> ok': function() {
                    app.emit('osd-message-interactive.hide', {
                        id: 'vmeeting'
                    });
                },
                '> warning': function() {
                    app.emit('osd-message-interactive.show', {
                        id: 'vmeeting',
                        osdMessage: sprintf(i18n.t('session_management.meeting_ends'), [this.timeLeft.m]),
                        icon: 'vmeeting'
                    });
                },
                '> critical': function() {
                    app.emit('osd-message-interactive.show', {
                        id: 'vmeeting',
                        osdMessage: sprintf(i18n.t('session_management.meeting_ended'), [this.timeLeft.m]),
                        icon: 'vmeeting'
                    });
                }
            });

            this.connectionState.addTransitions({
                '> disconnected': function() {
                    this.showOsdMessage();
                },
                '> loggingIn': function() {
                    this.showOsdMessage();
                },
                '> connecting': function() {
                    setTimeout(function() {
                        // Show error when connecting takes too long.
                        if (this.connectionState.getState() === connectionStates.connecting) {
                            this.showOsdMessage();
                        }
                    }.bind(this), 5000);
                },
                '> failed': function() {
                    this.showOsdMessage();
                },
                '> connected': function() {
                    this.hideOsdMessage();
                }
            });
        },

        /**
         * Check if the vMeeting service error OSD Message should be shown.
         */
        showOsdMessage: function() {
            if (this.vMeetingEnabled && !this.isAdmin) {
                app.emit('frontend-osd-message.show', {
                    id: 'vmeeting-service',
                    icon: 'vmeeting',
                    osdMessageKey: i18n.t('session_management.service_connection_failed'),
                    timeout: -1 // No timeout - show forever ;-)
                });
            } else {
                this.hideOsdMessage();
            }
        },

        /**
         * Hide vMeeting server error OSD Message.
         */
        hideOsdMessage: function() {
            app.emit('frontend-osd-message.hide', {
                id: 'vmeeting-service'
            });
        },

        /**
         * Get active session details.
         */
        getActiveSessionDetails: function() {
            var id = this.getActiveSessionId();

            if (!this.meetingList || this.meetingList.length <= 0) {
                return 0;
            }

            if (this.meetingList.length === undefined) {
                return this.meetingList;
            }

            this.meeting = this.meetingList.filter(function(meeting) {
                return meeting.id === id;
            }.bind(this));

            return this.meeting.length > 0 ? this.meeting[0] : 0;
        },

        /**
         * Check if there is enough time to start an Ad Hoc meeting.
         */
        checkStartAdHoc: function() {
            this.updateTimeframes();

            if (!this.hasValidTimeframes) {
                return false;
            }

            if (this.meetingList.length === 0) { // No meeting planned.
                return true;
            }

            return this.unbookedDuration >= AD_HOC_MIN_TIME;
        },

        /**
         * Update timeframes for ad-hoc meeting or extend meeting.
         */
        updateTimeframes: function() {
            var update = false;
            this.hasValidTimeframes = false;

            this.unbookedDurationTime = this.secondsToTime(this.unbookedDuration * 60);

            _.each(this.timeframes, function(frame) {
                var valid = frame.min <= this.unbookedDuration;

                if (frame.valid !== valid) {
                    frame.valid = valid;
                    update = true;
                }

                if (frame.valid) {
                    this.hasValidTimeframes = true;
                }
            }.bind(this));

            if (update) {
                app.emit('session-mgmt.update-timeframes', this.timeframes);
            }
        }
    };
});
