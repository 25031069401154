// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"ethernet-settings-form-v6\" name=\"ethernet-settings\">\n    <!-- ipv6 -->\n    <div class=\"row side-padding\" data-nav-area=\".input:not([disabled]), .btn-switch\" data-nav-trapped>\n        <div class=\"col-xs-8\">\n            <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ipv6_autoconfig",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":49}}}))
    + "</h2>\n        </div>\n        <div class=\"col-xs-4\">\n            <div class=\"btn-switch-container pull-right\">\n                <input name=\"dhcp\" type=\"checkbox\" class=\"btn-switch\"/>\n                <div class=\"btn-switch-toggle-container\">\n                    <div class=\"btn-switch-toggle\"></div>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class=\"row overlay-content-primary side-padding\" data-nav-area=\".input:not([disabled]), .btn-switch\"\n         data-nav-trapped>\n        <div class=\"col-xs-6\">\n            <div class=\"input-group\">\n                <label class=\"input-label\" for=\"ipv6\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ip_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":54},"end":{"line":20,"column":82}}}))
    + "</label>\n                <input type=\"text\" class=\"input\" name=\"ip\" id=\"ipv6\" placeholder=\"::1\" data-validation=\"checkIPv6\"/>\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_ip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":67},"end":{"line":23,"column":93}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n        <div class=\"col-xs-6\">\n            <div class=\"input-group\">\n                <label class=\"input-label\" for=\"subnetV6\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.subnet",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":58},"end":{"line":29,"column":82}}}))
    + "</label>\n                <input type=\"text\" class=\"input\" name=\"subnet\" id=\"subnetV6\" placeholder=\"::1\"\n                       data-validation=\"checkIPv6\"/>\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_ip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":67},"end":{"line":33,"column":93}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class=\"row overlay-content-primary side-padding\" data-nav-area=\".input:not([disabled]), .btn-switch\"\n         data-nav-trapped>\n        <div class=\"col-xs-6\">\n            <div class=\"input-group\">\n                <label class=\"input-label\" for=\"gatewayV6\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.gateway",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":59},"end":{"line":42,"column":84}}}))
    + "</label>\n                <input type=\"text\" class=\"input\" name=\"gateway\" id=\"gatewayV6\" placeholder=\"::1\"\n                       data-validation=\"checkIPv6\"/>\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_ip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":67},"end":{"line":46,"column":93}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n        <div class=\"col-xs-6\">\n            <div class=\"input-group\">\n                <label class=\"input-label\" for=\"dnsV6\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.dns",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":55},"end":{"line":52,"column":76}}}))
    + "</label>\n                <input type=\"text\" class=\"input\" name=\"dns\" id=\"dnsV6\" placeholder=\"::1\" data-validation=\"checkIPv6\"/>\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wrong_ip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":67},"end":{"line":55,"column":93}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div id=\"form-action-container-v6\" class=\"form-primary-background\"></div>\n</form>\n";
},"useData":true});
