'use strict';

var _ = require('lodash');
var $ = require('jquery');
var app = require('../../app');
var overlayTpl = require('./overlay.hbs');
var SimpleView = require('./views/simple-view');
var HistoryStore = require('./history-store');
var formManager = require('./../../../form/form-manager.js');
var platform = require('../../../platform/platform');

var ANIMATION_SPEED = 1; // Change also in _overlay.scss.

/**
 * Overlay states
 * @type {Object}
 */
var states = {
    open: 'open',
    closed: 'closed'
};

/**
 * Simple view name mapping to simplify the overlay options
 * @type {Object}
 */
var viewsMapping = {
    'simple': SimpleView
};

require('./overlay-header/overlay-header');
require('./overlay-header-tabbed/overlay-header-tabbed');
require('./overlay-header-matrix/overlay-header-matrix');
require('./overlay-header-update-notification/overlay-header-update-notification');

/**
 * The Overlay component is just a wrapper.
 * It manages the overlay header the views and the history.
 * Each overlay has defined settings inside the 'Overlay'
 */
app.component('Overlay', {
    /**
     * Handlebars template
     * @type {Function}
     */
    template: overlayTpl,

    /**
     * Component view class
     * @type {String}
     */
    className: 'overlay',

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    /**
     * @method initialize
     */
    initialize: function() {
        this.historyStore = new HistoryStore();
        this.liveStream = this.getService('LiveStreamService');
        this.overlayConfigs = this.getService('OverlayConfigsService');
        this.remote = this.getService('RemoteService');
        this.authService = this.getService('AuthenticationService');
        this.keyboard = this.getService('KeyboardService');
        this.overlayHandlerService = this.getService('OverlayHandlerService');
        this.matrixMainService = this.getService('MatrixMainService');
        this.view = null;
        this.state = this.createStateMachine({
            state: states.closed,
            states: states
        });
        this.addStateTransitions();

        this.bindEvents();
    },

    /**
     * Called after Component was placed inside the dom
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.storeSelectors();
    },

    /**
     * Save a reference to all used selectors
     * @method storeSelectors
     */
    storeSelectors: function() {
        this.$overlayHeaderContainer = this.$el.find('#overlay-header-container');
        this.$overlayNavigation = this.$el.find('#overlay-navigation');
        this.$overlayContent = this.$el.find('#overlay-content');
        this.$overlayWrapper = this.$el.find('#overlay-wrapper');
        this.$overlayBackdrop = this.$el.find('#overlay-backdrop');
    },

    /**
     * Bind all custon events
     * @method bindEvents
     */
    bindEvents: function() {
        this.on('overlay.open', this.openHandler.bind(this));
        this.on('overlay.close', this.close.bind(this));
        this.on('overlay.close.ignorant', this.ignorantClose.bind(this));
        this.on('overlay.history.back', this.historyBack.bind(this));
        this.on('overlay.remote.focus', this.focusRemoteArea.bind(this));
        this.on('menu.opened', this.ignorantClose.bind(this));
        this.on('control-center.opened', this.ignorantClose.bind(this));
        this.on('modal.opened', this.ignorantClose.bind(this));
        this.on('overlay-backdrop.show', this.showOverlayBackdrop.bind(this));
        this.on('overlay-backdrop.hide', this.hideOverlayBackdrop.bind(this));
    },

    /**
     * Overlay open handler method
     * @method openHandler
     */
    openHandler: function(configs) {
        if (this.getService('StationService').getPushStatus()) {
            return;
        }

        if (this.getService('StationService').getLockStatus()) {
            return;
        }

        if (this.state.getState() === states.open && !configs.openNew) {
            this.update(configs);
        } else {
            this.open(configs);
        }
    },

    /**
     * Check if a default overlay config exists and extend the
     * current configs with the default ones.
     *
     * @method extendConfigs
     * @param {Object} configs
     */
    extendConfigs: function(configs) {
        var defaultConfigs = this.overlayConfigs.get(configs.id) || {};
        var extendedConfigs = configs.extendedConfigs ? this.overlayConfigs.get(configs.extendedConfigs) || {} : {};

        configs = _.defaults(configs, defaultConfigs);
        configs = _.defaults(configs, extendedConfigs);

        configs.titleKey = app.getService('Model-View')[configs.specTitleKey] || configs.titleKey;

        return configs;
    },

    /**
     * Append given History-Data from config-item "historyExtend".
     *
     * @param {Object} configs
     */
    extendToHistory: function(configs) {
        if (configs.historyExtend) {
            _.forEach(configs.historyExtend, function(history) {
                var extendedConfigs = this.extendConfigs(history);

                this.addToHistory(extendedConfigs);
            }.bind(this));
        }
    },

    /**
     * @param {Object} configs
     */
    addToHistory: function(configs) {
        var historyObj = {
            configs: configs
        };

        this.historyStore.add(historyObj);
    },

    /**
     * Overlay history back handler
     * @method historyBack
     */
    historyBack: function(historyConfigs) {
        if ('focus-nav' === this.historyMode) {
            this.historyBackFocus();
        } else {
            this.historyBackLastOverlay(historyConfigs);
        }
    },

    historyBackFocus: function() {
        this.remote.focus($('[data-nav-history-back-focus="true"]').eq(0));
    },

    historyBackLastOverlay: function(historyConfigs) {
        var lastItem = this.historyStore.back();
        var historyObj = this.historyStore.getCurrent();
        var nextItem = this.historyStore.getNext();
        var configs = null;
        var extendedConfigs = null;
        var view = this.view;

        if (lastItem) {
            configs = historyObj.configs;
            extendedConfigs = this.extendConfigs(configs);
            extendedConfigs.historyConfig = historyConfigs || {};

            this.view = this.createView(extendedConfigs.view, extendedConfigs.component, configs);

            view.$el.addClass('setup-back-animation');

            var oldZIndex = this.view.$el.css('zIndex');
            this.appendView(null, 10);

            // Wait for render last component is done.
            setTimeout(function() {
                this.updateHeader(
                    extendedConfigs.titleKey,
                    extendedConfigs.actionButton,
                    extendedConfigs.disconnectButton,
                    extendedConfigs.saveButton,
                    extendedConfigs.navigation
                );

                this.playBackAnimation(view, function() {
                    var hasBackBtn = !!nextItem && nextItem.configs.titleKey;

                    view.destroy();
                    this.destroyOldContent();

                    // FIX for https://jira.wolfvision.at/browse/RELEASE-1699
                    this.remote.focusLast();

                    this.focusRemoteArea(hasBackBtn, lastItem.configs.id);
                    this.emit('overlay.ready');
                    formManager.update();

                    /*
                     * RELEASE-2258 - When downloading a file sometimes UI reloads .
                     *
                     * Sometimes you will press the back-button and
                     * an other command will close the overlay on the same time.
                     * In that case we do not have "this.view" anymore.
                     */
                    if (this.view) {
                        // Restore old zIndex value.
                        this.view.$el.css('zIndex', oldZIndex);
                    }
                }.bind(this));
            }.bind(this), 70);
        }
    },

    /**
     * Fixed an issue where the old component not destroyed
     * @method destroyOldContent
     */
    destroyOldContent: function() {
        if (this.view && this.view.$el) {
            this.view.$el.siblings().each(function(key, el) {
                var $el = this.$(el);
                var componentId = $el.data('component-id');

                if (componentId) {
                    app.removeComponent(componentId);
                    $el.remove();
                }
            }.bind(this));
        }
    },

    /**
     * Cleanup the history store and destroy stored the views
     * @method destroyHistory
     */
    destroyHistory: function() {
        this.historyStore.destroy();
    },

    /**
     * Initialize the header component
     * @method createHeader
     */
    createHeader: function(titleKey, actionButtonKey) {
        var nextItem = this.historyStore.getNext();
        var back = !!nextItem && nextItem.configs.titleKey;

        this.app.createComponent('overlayHeader', {
            type: 'OverlayHeader',
            container: this.$el.find('#overlay-header-container'),
            titleKey: titleKey,
            actionButtonKey: actionButtonKey,
            closeButton: this.closeButton,
            disconnectButton: this.disconnectButton,
            saveButton: this.saveButton,
            overlayTitle: this.overlayTitle,
            back: back,
            hideHeader: this.hideHeader,
            showActionSwitch: this.showActionSwitch,
            actionSwitchChecked: this.actionSwitchChecked
        });

        if (this.notification) {
            this.app.createComponent('overlayHeaderUpdateNotification', {
                type: 'OverlayHeaderUpdateNotification',
                container: this.$el.find('#overlay-header-update-notification-container')
            });
        }
    },

    /**
     * Initialize the tabbed header component.
     */
    createTabbedHeader: function(extendedConfig) {
        this.app.createComponent('overlayHeaderTabbed', {
            type: 'OverlayHeaderTabbed',
            container: this.$el.find('#overlay-header-container'),
            closeButton: this.closeButton,
            saveButton: this.saveButton,
            overlayId: extendedConfig.id,
            tabButtons: extendedConfig.tabButtons
        });
    },

    /**
     * Initialize the matrix header component.
     *
     * @param extendedConfig Extendec overlay config
     */
    createMatrixHeader: function(extendedConfig) {
        this.app.createComponent('overlayHeaderMatrix', {
            type: 'OverlayHeaderMatrix',
            container: this.$el.find('#overlay-header-container'),
            isConfigurator: extendedConfig.isConfigurator,
            noTemplate: extendedConfig.noTemplate,
            overlayId: extendedConfig.id,
            menuButton: extendedConfig.menuButton,
            closeButton: extendedConfig.closeButton
        });
    },

    /**
     * Updates the header view.
     *
     * @param titleKey Title key
     * @param actionButtonKey Action button key
     * @param disconnectButton Disconnect button
     * @param saveButton Save button
     * @param navigation Navigation bar
     */
    updateHeader: function(titleKey, actionButtonKey, disconnectButton, saveButton, navigation) {
        var nextItem = this.historyStore.getNext();
        var back = !navigation && nextItem && nextItem.configs.titleKey;

        this.emit('overlay.header.update', {
            titleKey: titleKey,
            actionButtonKey: actionButtonKey,
            back: back,
            closeButton: this.closeButton,
            disconnectButton: disconnectButton,
            saveButton: saveButton
        });
    },

    /**
     * Updates the tabbed header view
     * @method updateTabbedHeader
     */
    updateTabbedHeader: function(extendedConfig) {
        this.emit('overlay.tabbed.header.update', {
            tabButtons: extendedConfig
        });
    },

    /**
     * Destroys the header component
     * @method removeHeader
     */
    removeHeader: function() {
        this.app.removeComponent('overlayHeader', {});
        this.app.removeComponent('overlayHeaderTabbed', {});
        this.app.removeComponent('overlayHeaderMatrix', {});
        this.app.removeComponent('overlayHeaderUpdateNotification', {});
    },

    /**
     * Create a new overlayview instance and render it.
     *
     * @param {String} viewName
     * @param {String} componentName
     * @param {Object} configs
     */
    createView: function(viewName, componentName, configs) {
        var view = new viewsMapping[viewName || 'simple'](this.app, configs);

        view.addComponent(componentName);
        view.render();
        view.$el.data('component-id', view.id);

        return view;
    },

    /**
     * Inserts the view into the DOM.
     *
     * @param {Object} view
     * @param {Number} forcedZIndex can be used to force a z-index on the view.
     */
    appendView: function(view, forcedZIndex) {
        view = (view || this.view);

        // First hide component element.
        view.$el.hide();

        if (forcedZIndex || 0 === forcedZIndex) {
            view.$el.css('zIndex', forcedZIndex);
        }

        // Then show it after css and html has been rendered.
        setTimeout(function() {
            view.$el.show();
        }, 150);

        return view.placeAt('#overlay-content');
    },

    /**
     * Destroy the current overlay view
     * @method destroyView
     */
    destroyView: function() {
        if (this.view) {
            this.view.destroy();
            this.view = null;
        }
    },

    /**
     * Initial open method for the overlay
     * @method open
     */
    open: function(configs) {
        var extendedConfigs;

        if (configs.openNew) {
            if (platform.checks.isCbox && this.keyboard.isOpened()) {
                this.keyboard.close();
            }

            this.overlayHandlerService.setOpenOverlayId(null);
            this.removeHeader();
            this.destroyView();
            this.destroyHistory();
        }

        extendedConfigs = this.extendConfigs(configs);
        this.animation = extendedConfigs.animation || 'slide';
        this.closeButton = extendedConfigs.closeButton || false;
        this.disconnectButton = extendedConfigs.disconnectButton || false;
        this.saveButton = extendedConfigs.saveButton || false;
        this.overlayTitle = extendedConfigs.overlayTitle || false;
        this.closingAllowed = true;
        this.notification = extendedConfigs.notification || false;
        this.hideHeader = extendedConfigs.hideHeader || false;
        this.showActionSwitch = extendedConfigs.showActionSwitch || false;
        this.actionSwitchChecked = extendedConfigs.actionSwitchChecked || false;

        if ('undefined' !== typeof extendedConfigs.closingAllowed) {
            this.closingAllowed = extendedConfigs.closingAllowed;
        }

        this.checkAccess(extendedConfigs, function() {
            this.overlayHandlerService.setOpenOverlayId(extendedConfigs.id);
            this.view = this.createView(extendedConfigs.view, extendedConfigs.component, configs);

            this.extendToHistory(extendedConfigs);

            if (extendedConfigs.focusItem !== 'VMeeting') {
                this.addToHistory(extendedConfigs);
            }

            if (extendedConfigs.fullScreen) {
                this.$el.addClass('is-full');
            } else {
                this.$el.removeClass('is-full');
            }

            if (extendedConfigs.isMatrix) {
                this.$overlayWrapper.addClass('matrix');
                this.view.$el.addClass('matrix');

                this.createMatrixHeader(extendedConfigs);
            } else if (extendedConfigs.tabButtons && extendedConfigs.tabButtons.length > 0) {
                this.createTabbedHeader(extendedConfigs);
            } else {
                this.createHeader(extendedConfigs.titleKey, extendedConfigs.actionButton);
            }

            if (extendedConfigs.navigation) {
                this.addNavigationBar(extendedConfigs);
            } else {
                this.removeNavigationBar();
            }

            this.appendView();
            this.state.changeState(states.open, configs);

            this.emit('overlay.opened', configs);
            this.emit('overlay.ready');

            this.emit('show.ui', null, configs);

            this.emit('backdrop.show');
            this.emit('backdrop.layer.set', {
                zIndex: 10
            });

            if (configs.onOpen) {
                configs.onOpen();
            }
        }.bind(this));
    },

    /**
     * Create navigation bar.
     *
     * @param extendedConfigs Extended overlay configs
     */
    addNavigationBar: function(extendedConfigs) {
        this.app.createComponent('navigationBar', {
            type: 'NavigationBar',
            container: this.$overlayNavigation,
            items: extendedConfigs.items,
            selected: extendedConfigs.id
        });

        this.$overlayNavigation
            .show()
            .addClass('col-xs-2 col-xs-3-table col-xs-3-phone');

        this.$overlayContent.addClass('col-xs-10 col-xs-9-table col-xs-9-phone');
        this.$el.addClass('has-navigation');
    },

    removeNavigationBar: function() {
        this.app.removeComponent('navigationBar');

        this.$overlayNavigation
            .hide()
            .removeClass('col-xs-2 col-xs-3-table col-xs-3-phone');

        this.$overlayContent.removeClass('col-xs-10 col-xs-9-table col-xs-9-phone');
        this.$el.removeClass('has-navigation');
    },

    /**
     * If the overlay is already open the update method is getting called
     */
    update: function(configs) {
        var extendedConfigs = this.extendConfigs(configs);
        var view = this.view;

        this.checkAccess(extendedConfigs, function() {
            if (view.hasChanges().hasChanges) {
                this.openChangesModal(this.updateHandler.bind(this, extendedConfigs, view), view.hasChanges());
            } else {
                this.updateHandler(extendedConfigs, view);
            }
        }.bind(this));
    },

    updateHandler: function(extendedConfigs, view) {
        this.historyMode = extendedConfigs.historyMode || 'back';
        this.overlayHandlerService.setOpenOverlayId(extendedConfigs.id);
        this.addToHistory(extendedConfigs);

        if (extendedConfigs.isMatrix) {
            this.$overlayWrapper.addClass('matrix');
            this.view.$el.addClass('matrix');

            this.createMatrixHeader(extendedConfigs);
        } else if (extendedConfigs.tabButtons && extendedConfigs.tabButtons.length > 0) {
            this.updateTabbedHeader(extendedConfigs);
        } else {
            this.updateHeader(
                extendedConfigs.titleKey,
                extendedConfigs.actionButton,
                extendedConfigs.disconnectButton,
                extendedConfigs.saveButton,
                extendedConfigs.navigation
            );
        }

        this.view = this.createView(extendedConfigs.view, extendedConfigs.component, extendedConfigs);
        this.view.$el.hide();

        this.appendView();

        this.playUpdateAnimation(this.view, function() {
            view.destroy();

            if (this.view) { // RELEASE-1703: view could be null at this time (if downloading is very fast)
                this.view.$el.siblings().remove();
            }

            if (!this.view.configs.customRemoteFocus) {
                this.focusRemoteArea();
            }
            this.emit('overlay.ready');

            formManager.update();
        }.bind(this));

        if (extendedConfigs.onOpen) {
            extendedConfigs.onOpen();
        }

        this.emit('overlay.updated');
    },

    /**
     * Ignore settings changes and close overlay.
     * E.g. if a USB gets connected, remote control handling (button like power on/off, media cast, etc. is pressed)
     *
     * @param {Object} options
     */
    ignorantClose: function(options) {
        this.close(options, true);
    },

    /**
     * Close Overlay and check if all settings has been saved.
     *
     * @param {Object} options
     */
    close: function(options, ignoreChanges) {
        options = options || {};

        if (!this.closingAllowed || !this.view) {
            return;
        }

        if (platform.checks.isCbox && this.keyboard.isOpened()) {
            this.keyboard.close();
        }

        if (!ignoreChanges && this.view.hasChanges().hasChanges) {
            this.openChangesModal(this.closeHandler.bind(this, options), this.view.hasChanges());
        } else {
            this.closeHandler(options);
        }
    },

    /**
     * Starts Animation, sets states, cleaning up the history,… to close the Overlay.
     *
     * @param {Object} options
     */
    closeHandler: function(options) {
        this.overlayHandlerService.setOpenOverlayId(null);
        this.removeHeader();
        this.removeNavigationBar();
        this.destroyView();
        this.destroyHistory();
        this.playClosingAnimation(function() {
            this.state.changeState(states.closed);
            this.emit('overlay.closed');
            formManager.remove();
        }.bind(this));

        this.emit('overlay-backdrop.hide');
        this.emit('backdrop.hide');
        this.emit('show.ui');

        if (options.onClose) {
            options.onClose();
        }
    },

    /**
     * @method setStateClass
     */
    setStateClass: function(state) {
        this.$el.addClass('is-' + state);
        this.$el.removeClass('is-' + this.state.getState());
    },

    /**
     * @param {Boolean} focusHeader
     * @param {String} id
     */
    focusRemoteArea: function(focusHeader, id, focusNavBar) {
        var $container = this.$overlayContent;
        var $remoteArea;

        if (focusHeader) {
            $container = this.$overlayHeaderContainer;
        } else if (focusNavBar) {
            $container = this.$overlayNavigation;
        }

        if (id && !focusHeader) {
            $remoteArea = $container.find('[data-id="' + id + '"]');
        } else {
            // Select default overlay focus.
            $remoteArea = $container.find('[data-nav-area]').eq(0);
        }

        if (this.view && this.view.configs.navArea) {
            $remoteArea = $container.find(this.view.configs.navArea);
        }

        if ($remoteArea.length === 0) {
            $remoteArea = this.$overlayHeaderContainer.find('[data-nav-area]').eq(0);
        }

        this.remote.focus($remoteArea);
    },

    /**
     * Open Modal with the question to save unsaved settings.
     *
     * @param {Function} onFinish
     * @param {Object} hasChanges
     */
    openChangesModal: function(onFinish, hasChanges) {
        this.emit('modal.open', {
            id: 'settings-save-changes',
            invalid: hasChanges.invalid,
            onSave: function() {
                this.emit('settings.save-changes');
            },
            onFinish: onFinish,
            waitOnSave: hasChanges.waitOnSave,
            role: {
                name: 'Settings',
                key: 'show'
            }
        });
    },

    /**
     * @param {Object} config
     * @param {Function} onAccess
     */
    checkAccess: function(config, onAccess) {
        // Check login state, if loginstate is guest open login-modal.
        this.authService.checkAccess(
            config.role,
            onAccess.bind(this),
            function() {
                this.emit('modal.close');
            }.bind(this)
        );
    },

    /**
     * @method addStateTransitions
     */
    addStateTransitions: function() {
        this.state.addTransitions({
            'closed > open': function(configs) {
                this.setStateClass(states.open);
                this.playOpenAnimation(function() {
                    var $focusItem;

                    this.liveStream.pause();
                    this.emit('framebox.speed.slow');
                    this.focusRemoteArea();

                    if (configs && configs.focusItem) {
                        $focusItem = this.$el.find('[data-name="' + configs.focusItem + '"]');
                        this.remote.focus($focusItem);
                    }

                    // Added for automation testing
                    if (configs && configs.type) {
                        this.$overlayContent.attr('data-source-type', configs.type);
                    }

                    this.emit('overlay.opened-end');
                    formManager.update();
                }.bind(this));
            },

            'open > closed': function() {
                this.setStateClass(states.closed);
                this.$overlayContent.removeAttr('data-source-type');

                this.liveStream.unpause();
                this.emit('framebox.speed.full');

                this.emit('framebox.focus.stored');
                formManager.remove();
            }
        });
    },

    /**
     * Open overlay with animation.
     * @method playOpenAnimation
     * @param {Function} complete
     */
    playOpenAnimation: function(complete) {
        switch (this.animation) {
            case 'slideToRight':
                this.$el.css('left', '-' + this.$el.width() + 'px');
                this.playOpenSlideRightAnimation(complete);
                break;
            case 'fade':
                this.$el.css('left', 0);
                this.playOpenFadeAnimation(complete);
                break;
            default:
            case 'slide':
                this.$el.css('left', 0);
                this.playOpenSlideAnimation(complete);
                break;
        }
    },

    /**
     * Open overlay with animation (slide right).
     *
     * @param {Function} complete
     */
    playOpenSlideRightAnimation: function(complete) {
        this.$overlayWrapper
            .velocity('fadeOut', 0)
            .velocity({
                scale: 1
            }, 0)
            .velocity('fadeIn');

        this.$el
            .animate({
                left: '0px'
            },
            {
                duration: 500 * ANIMATION_SPEED,
                complete: complete
            });
    },

    /**
     * Open overlay with animation (fade in).
     *
     * @param {Function} complete
     */
    playOpenFadeAnimation: function(complete) {
        this.$overlayWrapper
            .velocity('fadeOut', 0)
            .velocity({
                scale: 1
            }, 0)
            .velocity('fadeIn', {
                duration: 250 * ANIMATION_SPEED,
                complete: complete
            });
    },

    /**
     * Open overlay with animation (slide).
     *
     * @param {Function} complete
     */
    playOpenSlideAnimation: function(complete) {
        this.$overlayWrapper
            .velocity({
                scale: 0
            }, 0)
            .velocity('fadeIn', 0)
            .velocity({
                scale: [1, [250, 25]]
            }, {
                duration: 400 * ANIMATION_SPEED,
                complete: complete
            });
    },

    /**
     * Closing overlay with animation.
     *
     * @param {Function} complete
     */
    playClosingAnimation: function(complete) {
        switch (this.animation) {
            case 'slideToRight':
                this.playClosingSlideRightAnimation(complete);
                break;
            case 'fade':
                this.playClosingFadeAnimation(complete);
                break;
            default:
            case 'slide':
                this.playClosingSlideAnimation(complete);
                break;
        }
    },

    /**
     * Close overlay with animation (slide right).
     *
     * @param {Function} complete
     */
    playClosingSlideRightAnimation: function(complete) {
        this.$el
            .animate({
                left: '-' + this.$el.width() + 'px'
            },
            {
                duration: 500 * ANIMATION_SPEED, // If changed, change timeout in MatrixFlap.setFlapVisible()
                complete: complete
            });
    },

    /**
     * Close overlay with animation (slide).
     *
     * @param {Function} complete
     */
    playClosingSlideAnimation: function(complete) {
        this.$overlayWrapper
            .velocity({
                scale: 0
            }, {
                duration: 200 * ANIMATION_SPEED,
                complete: complete
            });
    },

    /**
     * Close overlay with animation (fade out).
     *
     * @param {Function} complete
     */
    playClosingFadeAnimation: function(complete) {
        this.$overlayWrapper
            .velocity('fadeOut', {
                duration: 200 * ANIMATION_SPEED,
                complete: complete
            });
    },

    /**
     * @param {Object} view
     * @param {Function} complete
     */
    playUpdateAnimation: function(view, complete) {
        view.$el
            .addClass('setup-update-animation')
            .show()
            .addClass('run-update-animation');

        setTimeout(function() {
            view.$el
                .removeClass('setup-update-animation')
                .removeClass('run-update-animation');
            complete();
        }, 300 * ANIMATION_SPEED);
    },

    /**
     * @param {Object} view
     * @param {Function} complete
     */
    playBackAnimation: function(view, complete) {
        view.$el.addClass('run-back-animation');

        setTimeout(function() {
            view.$el.hide();

            complete();
        }, 300 * ANIMATION_SPEED);
    },

    /**
     * Show overlay backdrop.
     *
     * @param invisible To avoid click events, show backdrop but invisible
     */
    showOverlayBackdrop: function(invisible) {
        if (invisible) {
            this.$overlayBackdrop.addClass('invisible');
        }

        this.$overlayBackdrop.show();
    },

    /**
     * Hide overlay backdrop.
     */
    hideOverlayBackdrop: function() {
        if (this.matrixMainService.showAllPreviews) {
            return;
        }

        this.$overlayBackdrop.hide();

        this.$overlayBackdrop.removeClass('invisible');
    }
});
