// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"warning-header\">\n            <span class=\"icon icon-warning\"></span>\n            <span>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.warning",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":18},"end":{"line":5,"column":42}}}))
    + "</span>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"login-header\">\n            <div id=\"login-select-admin-item\"\n                 class=\"login-select-admin-item pull-right focusable\"\n                 tabindex=\"-1\"\n                 data-user=\"admin\">\n                <span class=\"icon-settings-1\"></span>\n            </div>\n            <img src=\"/images/cynap-logo.svg\" alt=\"cynap\" />\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "col-xs-6";
},"7":function(container,depth0,helpers,partials,data) {
    return "col-xs-12";
},"9":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"11":function(container,depth0,helpers,partials,data) {
    return "warning";
},"13":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"login-form-select-user login-switch-user\" data-user=\"admin\">\n                        <div class=\"login-username\" id=\"login-switch-username\">\n                            <div class=\"login-icon\">\n                                <span class=\"icon-settings-1\"></span>\n                                <span class=\"icon-user hidden\"></span>\n                                <span class=\"icon-annotate hidden\"></span>\n                            </div>\n                        </div>\n                    </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"warning-text\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"verifyMessage") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":50},"end":{"line":52,"column":69}}}))
    + "</div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isWarning") : depth0),{"name":"unless","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":24},"end":{"line":107,"column":35}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    return "                            <div id=\"regulatory-info\" class=\"regulatory-info focusable\">\n                                <span class=\"icon-v3-info\"></span>\n                            </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"login-info-container\">\n                    <div id=\"forgot-your-password\" class=\"forgot-your-password focusable\" tabindex=\"-1\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"login.forgot_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":119,"column":104},"end":{"line":119,"column":133}}}))
    + "</div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"login-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isWarning") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":17,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"login-content-container\">\n        <div class=\"login-select-content\" id=\"login-select-content\">\n            <div class=\"row\">\n                <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"collaboration") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":21,"column":28},"end":{"line":21,"column":81}}})) != null ? stack1 : "")
    + " login-select-item focusable\" tabindex=\"-1\" data-user=\"user\">\n                    <div class=\"login-icon\" >\n                        <span class=\"login-user-character\">M</span>\n                    </div>\n                    <span class=\"login-username\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"login.guest",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":49},"end":{"line":25,"column":68}}}))
    + "</span>\n                </div>\n\n                <div class=\"col-xs-6 login-select-item focusable "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"collaboration") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":65},"end":{"line":28,"column":107}}})) != null ? stack1 : "")
    + "\" tabindex=\"-1\" data-user=\"collab\">\n                    <div class=\"login-icon\" >\n                        <span class=\"login-user-character\">U</span>\n                    </div>\n                    <span class=\"login-username\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"login.collab",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":49},"end":{"line":32,"column":69}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"login-content "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isWarning") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":34},"end":{"line":37,"column":65}}})) != null ? stack1 : "")
    + "\" id=\"login-content\">\n            <form id=\"login-form\" name=\"login-form\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"switchBtn") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":49,"column":23}}})) != null ? stack1 : "")
    + "                <div class=\"login-form-content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isWarning") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":20},"end":{"line":53,"column":27}}})) != null ? stack1 : "")
    + "                    <div class=\"login-icon current-role\" >\n                        <span class=\"icon-user\"></span>\n                        <span class=\"icon-settings-1 hidden\"></span>\n                        <span class=\"icon-annotate hidden\"></span>\n                        <span class=\"icon-iris_l hidden\"></span>\n                    </div>\n                    <div id=\"login-form-content-inner\" class=\"login-form\">\n                        <div class=\"input-group login-password-field\" id=\"login-validate-container\">\n                            <label class=\"login-username\" for=\"password\" id=\"login-username\"></label>\n                            <input type=\"password\"\n                                   id=\"password\"\n                                   class=\"input focusable\"\n                                   name=\"password\"\n                                   autocomplete=\"current-password\"\n                                   placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"login.password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":48},"end":{"line":68,"column":70}}}))
    + "\" />\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\" id=\"password-error\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"login.wrong_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":70,"column":99},"end":{"line":70,"column":127}}}))
    + "</span>\n                            </div>\n                        </div>\n\n                        <div class=\"input-group login-password-field hidden\" id=\"admin-pin\" >\n                            <label class=\"login-username\" for=\"adminPin\"></label>\n                            <input type=\"password\"\n                                   name=\"adminPin\"\n                                   class=\"input focusable\"\n                                   autocomplete=\"current-password\"\n                                   placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"login.pin",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":80,"column":48},"end":{"line":80,"column":65}}}))
    + "\" />\n                            <div class=\"input-info\">\n                                <span class=\"input-info-message error-message\" id=\"password-error\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"login.or_wrong_pin",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":82,"column":99},"end":{"line":82,"column":125}}}))
    + "</span>\n                            </div>\n                        </div>\n\n                        <div class=\"nickname hidden\" id=\"login-nickname\">\n                            <div class=\"input-group\">\n                                <label class=\"login-nickname\" id=\"login-nickname\"></label>\n                                <input type=\"text\"\n                                       name=\"nickname\"\n                                       id=\"nickname\"\n                                       class=\"input focusable\"\n                                       autocomplete=\"username\"\n                                       placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"login.nickname",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":94,"column":52},"end":{"line":94,"column":74}}}))
    + "\"\n                                       data-validation=\"checkNickname\"/>\n                                <div class=\"input-info\">\n                                    <span class=\"input-info-message error-message\" id=\"nickname-error\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"login.wrong_nickname",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":97,"column":103},"end":{"line":97,"column":131}}}))
    + "</span>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLivaQ2") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":20},"end":{"line":108,"column":27}}})) != null ? stack1 : "")
    + "                    <div id=\"login-form-info\" class=\"login-form-info\">\n                        <div id=\"ldap-active-message\" class=\"ldap-active-message\" tabindex=\"-1\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"login.moderator_active",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":111,"column":28},"end":{"line":111,"column":58}}}))
    + "\n                        </div>\n                    </div>\n                </div>\n                <div id=\"form-action-container\"></div>\n            </form>\n"
    + ((stack1 = (lookupProperty(helpers,"unless_or")||(depth0 && lookupProperty(depth0,"unless_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isWarning") : depth0),(depth0 != null ? lookupProperty(depth0,"isRemote") : depth0),{"name":"unless_or","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":12},"end":{"line":121,"column":26}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});
