'use strict';

const _ = require('lodash');
const StringConvert = require('../string-convert');

/**
 * Pad 0 left on a number to get the needed length.
 *
 * @param {Number} number
 * @param {Number} length
 *
 * @return {string}
 */
function pad(number, length) {
    var str = '' + number;

    while (str.length < length) {
        str = '0' + str;
    }

    return str;
}

module.exports.pad = pad;

/**
 * Add parameter to text.
 *
 * @param {string} text
 * @param {array} parameter
 */
module.exports.sprintf = function(text, parameter) {
    var counter = 0;
    var args = parameter;

    return text.replace(/%s/g, function() {
        return args[counter++];
    });
};

/**
 * Replace umlaut with (ae, ue, oe).
 *
 * @param stringInput
 * @returns {*}
 */
module.exports.replaceUmlaut = function(stringInput) {
    let value = stringInput.toLowerCase();
    value = value.replace(/ä/g, 'ae');
    value = value.replace(/ö/g, 'oe');
    value = value.replace(/ü/g, 'ue');

    return value;
};

/**
 * Add spaces between every third character.
 *
 * @param id - Connection Id
 * @returns {*}
 */
module.exports.formatConnectionId = function(id) {
    let count = Math.abs(id.length / 3);
    let iteration = 1;
    let rest = id.length % 3;
    let formattedId = '';

    // Special behaviour if only one character is left
    if (rest === 1) {
        count -= 1;
    }

    formattedId = id.substring(0, 3);

    while (iteration <= count) {
        formattedId += ' ' + id.substring(3 * iteration, 3 * iteration + 3);

        iteration++;
    }

    if (rest === 1) {
        formattedId += id.substring(id.length - 1, id.length);
    }

    return formattedId;
};

/**
 * Check for URL params and return the first matching.
 * @params param array to check
 * @returns {*}
 */
module.exports.getFirstMatchingUrlParam = function(params) {
    let result;
    let urlParam = null;

    _.each(params, function(param) {
        const url = window.location.href;
        let tmpResult = new RegExp('[?&]' + param + '=([^&#]*)').exec(url);

        if (tmpResult) {
            result = tmpResult[1];
            urlParam = param;

            return false;
        }
    }.bind(this));

    if (!result) {
        return { param: null, value: null };
    } else {
        return { param: urlParam, value: result };
    }
};

/**
 * Decode JSON Web Token.
 *
 * @param jwt
 * @returns {any}
 */
module.exports.decodeJwt = function(jwt) {
    var base64Url = jwt.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

/**
 * Convert value to an array Buffer.
 *
 * @param value
 * @param buffer
 * @param startPosition
 * @param numBytes
 */
module.exports.valueToBuffer = function(value, buffer, startPosition, numBytes) {
    var i;

    for (i = 0; i < numBytes; i++) {
        buffer[i + startPosition] = (value >> ((numBytes - i - 1) * 8)) & 0xFF;
    }
};

/**
 * Rounds a number to given number of decimal digits
 *
 * @param {Number} num Number to be rounded
 * @param {Number} digits Decimal digits rounded to
 * @return {Number} returns the rounded number
 */
function round(num, digits = 0) {
    const factor = Math.pow(10, digits);

    return Math.round((num + Number.EPSILON) * factor) / factor;
}

module.exports.round = round;

/**
 * This function scales the coordinates (x, y, width, height) from the reference dimensions
 * to the target dimensions
 *
 * @param {Object} coordinates.x
 *                 coordinates.y the coordinates in the reference dimensions
 *                 coordinates.width the coordinates in the reference dimensions
 *                 coordinates.height the coordinates in the reference dimensions
 * @param {Object} referenceDimensions the reference dimension
 * @param {Object} targetDimensions the target dimensions
 * @return {Object} returns the scaled coordinates
 */
module.exports.convertCoordinates = function(coords, referenceDimensions, targetDimensions) {
    const factorX = targetDimensions.width / referenceDimensions.width;
    const factorY = targetDimensions.height / referenceDimensions.height;
    const calcCoords = {};

    calcCoords.width = round(coords.width * factorX, 0);
    calcCoords.height = round(coords.height * factorY, 0);
    calcCoords.x = round(coords.x * factorX, 0);
    calcCoords.y = round(coords.y * factorY, 0);

    return calcCoords;
};

/**
 * Format bytes.
 *
 * @param bytes
 * @param decimals
 * @return {string}
 */
module.exports.formatBytes = function(bytes, decimals = 2) {
    if (bytes === 0) {
        return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

/**
 * Returns if page was reloaded or not.
 *
 * @returns {boolean}
 */
module.exports.pageReload = function() {
    return (
        (window.performance.navigation && window.performance.navigation.type === 1)
        || window.performance
            .getEntriesByType('navigation')
            .map((nav) => nav.type)
            .includes('reload')
    );
};

/**
 * Remove all params from URL.
 *
 */
module.exports.removeParamsFromUrl = function() {
    const nextURL = window.location.href.replace(window.location.search, '');
    const nextState = { additionalInformation: 'updated URL' };

    window.history.replaceState(nextState, '', nextURL);
};

/**
 * Converts a String into a UTF-8 encoded byte array.
 * The length of the resulting byte array is added before the actual data. Length can be 1 byte, 2 bytes, 4 bytes.
 *
 * @param {String} str: the string to be converted into a UTF-8 encoded byte array
 * @param {1|2|4} lengthBytes: the number of bytes for the length field
 * @returns {Uint8Array}: resulting byte array with length + converted string
 */
module.exports.stringToBufferWithLength = function({ str, lengthBytes }) {
    const strAsBuffer = StringConvert.stringToUtf8(str);
    const result = new Uint8Array(lengthBytes + strAsBuffer.length);

    if (lengthBytes === 1) {
        result[0] = strAsBuffer.length;
    } else if (lengthBytes === 2) {
        result[0] = (strAsBuffer.length >> 8) & 0xFF;
        result[1] = strAsBuffer.length & 0xFF;
    } else if (lengthBytes === 4) {
        result[0] = (strAsBuffer.length >> 24) & 0xFF;
        result[1] = (strAsBuffer.length >> 16) & 0xFF;
        result[2] = (strAsBuffer.length >> 8) & 0xFF;
        result[3] = strAsBuffer.length & 0xFF;
    } else {
        throw new Error('only lengths of 1, 2 and 4 supported');
    }

    result.set(strAsBuffer, lengthBytes);

    return result;
};

/**
 * Find parent (ancestor) node of DOM element by ID.
 *
 * @param el
 * @param id
 * @return {null|*}
 */
module.exports.findParentNodeById = function(el, id) {
    if (!el || el.id === id) {
        return el;
    }
    while (el.parentNode) {
        el = el.parentNode;
        if (el.id === id) {
            return el;
        }
    }

    return null;
};

/**
 * Converts a byte array to a hex string.
 * E.g. [0xFF, 0xFF, 0xFF] -> 'ffffff'
 *
 * @param byteArray
 * @return {string}
 */
module.exports.toHexString = function toHexString(byteArray) {
    return Array.from(byteArray, function(byte) {
        return ('0' + (byte & 0xFF).toString(16)).slice(-2);
    }).join('');
};

/**
 * Converts a hex string to a byte array.
 * E.g. 'ffffff' -> [0xFF, 0xFF, 0xFF]
 *
 * @param hex
 * @return {*[]}
 */
module.exports.fromHexString = function fromHexString(hex) {
    let byteArray = [];
    for (let c = 0; c < hex.length; c += 2) {
        byteArray.push(parseInt(hex.substr(c, 2), 16));
    }

    return byteArray;
};

/**
 * Formats the input to a Timer (hh:mm:ss)
 *
 * @param msec
 * @returns {string}
 */
module.exports.formatTimer = function formatTimer(msec) {
    const sec = pad(parseInt(Math.floor(msec / 1000) % 60), 2);
    const min = pad(parseInt(Math.floor(msec / 60000) % 60), 2);
    const hrs = pad(parseInt(Math.floor(msec / 3600000)), 2);

    return hrs + ':' + min + ':' + sec;
};

/**
 * Finds the key of object from value
 *
 * @param object
 * @param value
 * @return {undefined|string|number|symbol}
 */
module.exports.findKeyFromValue = function(object, value) {
    return Object.keys(object).find(key => object[key] === value);
};

/**
 * Result for value x for quadratic function where 1 = 1, 50 = 30 and 100 = 100.
 * http://www.arndt-bruenner.de/mathe/10/parabeldurchdreipunkte.htm
 *
 * @param x
 * @returns {number}
 */
module.exports.quadraticFunction = function quadraticFunction(x) {
    return (2 / 245) * Math.pow(x, 2) + (43 / 245) * x + (40 / 49);
};

/**
 * Reverse for quardratic function to get the right value for the range input
 * http://www.onlinemathe.de/mathe/inhalt/Mitternachtsformel
 *
 * @param y
 * @returns {number}
 */
module.exports.reverseQuadraticFunction = function reverseQuadraticFunction(y) {
    return (-(43 / 245) + Math.sqrt(Math.pow(43 / 245, 2) - 4 * (2 / 245) * ((40 / 49) - y))) / (2 * (2 / 245));
};

/**
 * Calculates the centered point from two or more touch-points.
 *
 * @param {TouchList} points
 * @return {{x: number, y: number}}
 */
module.exports.averageMultiplePoints = function averageMultiplePoints(points) {
    var x = 0;
    var y = 0;
    var index = 0;

    for (var i = 0; i < points.length; i++) {
        var point = points[i];

        if (0 === index) {
            x = point.clientX;
            y = point.clientY;
        } else {
            x = (point.clientX + x) / 2;
            y = (point.clientY + y) / 2;
        }

        index++;
    }

    return {
        x: x,
        y: y
    };
};
