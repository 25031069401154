'use strict';

var app = require('../../../../app');
var volumeTpl = require('./audio-level-settings.hbs');
var $ = require('jquery');

app.component('AudioLevelSettings', {
    template: volumeTpl,

    getAccessKey: function getAccessKey() {
        return {
            'roleName': 'Volume',
            'roleKey': 'show'
        };
    },

    /**
     * Initialize
     */
    initialize: function initialize() {
        this.volumeService = this.getService('VolumeService');
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function postPlaceAt() {
        this.storeSelectors();
        this.updateLevel();
    },

    /**
     * StoreSelectors
     */
    storeSelectors: function storeSelectors() {
        this.$itemsLeft = this.$el.find('#audio-level-left').find('li');
        this.$itemsRight = this.$el.find('#audio-level-right').find('li');
    },

    /**
     * Updatelevel
     */
    updateLevel: function updateLevel() {
        if (!this.isAlive()) {
            return;
        }

        this.volumeService
            .updateHandler()
            .then(this.renderLevel.bind(this));

        setTimeout(this.updateLevel.bind(this), 300);
    },

    /**
     * RenderLevel
     */
    renderLevel: function renderLevel() {
        var levels = this.volumeService.getVolumeLevel();

        this.renderBar(this.$itemsLeft, levels.left);
        this.renderBar(this.$itemsRight, levels.right);
    },

    /**
     * @param {jQuery.Element} $items
     * @param {number} level
     */
    renderBar: function renderBar($items, level) {
        $items
            .each(function(key, el) {
                var $el = $(el);

                // Index 1…20 (5% steps) are smaller or equal to level/5
                // Level = percent audio
                // The dividator 5 we need to calculate the steps (1…20)
                if (level >= 5 && $el.index() <= (level / 5)) {
                    $el.addClass('has-volume');
                } else {
                    $el.removeClass('has-volume');
                }
            });
    },

    /**
     * Destroy
     */
    destroy: function destroy() {
        this.volumeService.stopUpdate();
    }
});
