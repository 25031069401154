'use strict';

const app = require('../../../app');
const tpl = require('./zoom-join.html');
const vueUtils = require('../../../components-vue/util');
const formManager = require('./../../../../../modules/form/form-manager.js');

app.component('ZoomJoin', {
    engine: 'vue',
    template: tpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    data: function() {
        return {
            meetingIdOrLink: '',
            valid: false
        };
    },

    computed: {
        isLink: function() {
            return this.validationService.checkZoomLink(this.meetingIdOrLink);
        },
        link: function() {
            return this.isLink ? this.meetingIdOrLink : '';
        },
        isMeetingId: function() {
            return this.validationService.checkZoomMeetingId(this.meetingIdOrLink);
        },
        meetingId: function() {
            return this.isMeetingId ? this.meetingIdOrLink.replace(/\s/g, '') : '';
        }
    },

    created: function() {
        this.formManagerService = app.getService('FormManager');
        this.validationService = app.getService('ValidateService');
        this.keyboard = app.getService('KeyboardService');
    },

    mounted: function() {
        formManager.update();

        this.form = this.formManagerService.create({
            el: this.$refs['zoom-join-form'],
            validationContainer: '.input-group',
            submit: true
        });

        this.form.on('change.input', this.handleFormChange);
    },

    methods: {
        join: function() {
            if (typeof this.component.options.configs.onJoin === 'function') {
                this.component.options.configs.onJoin(this.meetingId, this.link);
            }

            this.close();
        },

        cancel: function() {
            if (typeof this.component.options.configs.onCancel === 'function') {
                this.component.options.configs.onCancel();
            }

            this.close();
        },

        handleFormChange(event) {
            this.valid = this.form.validate();
            if ('meetingIdOrLink' === event.input.name) {
                this.meetingIdOrLink = event.value;
            }
        },

        close: function() {
            this.component.emit('modal.close', { id: 'zoom-join' });
            this.keyboard.close();
        },

        i18n: vueUtils.i18n
    }
});
