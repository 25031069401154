'use strict';

var app = require('../../../app');
var i18n = require('i18next');
var Platform = require('../../../../platform/platform');

var states = {
    selectUser: 'select-user',
    signin: 'signin'
};

var DURATION = 250;

/**
 * Abstract login.
 *
 * Extended by LDAP-Login and Login modal.
 */
app.component('LoginAbstract', {
    isPin: false,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    storeSelectors: function() {
        this.$select = this.$el.find('#login-select-content');
        this.$login = this.$el.find('#login-content');

        this.$password = this.$el.find('#password');
        this.$passwordError = this.$el.find('#password-error');

        this.$adminSelectItem = this.$el.find('.login-select-admin-item');
        this.$forgotPassword = this.$el.find('.forgot-your-password');
        this.$adminPinField = this.$el.find('#admin-pin');
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.login-select-item', this.selectUser.bind(this));
        this.$el.on('click', '.login-select-admin-item', this.selectUser.bind(this));
        this.$el.on('click', '.login-switch-user', this.switchRole.bind(this));
        this.$el.on('click', '.forgot-your-password', this.forgotPassword.bind(this));
        this.$el.find('#login-form').on('submit', this.onSignInHandler.bind(this));
        this.$el.find('#login-form .input').on('keydown', this.onKeyDownHandler.bind(this));
        this.$el.on('click', '.regulatory-info', this.showRegulatoryInfo.bind(this));
    },

    /**
     * This is a Firefox for Android fix.
     * - Submit-event not thrown on next-Button.
     * @param {jQuery.Event} event
     */
    onKeyDownHandler: function(event) {
        if (event.keyCode === 9 && Platform.checks.isFirefox && Platform.checks.isAndroid) {
            this.onSignInHandler();
            event.preventDefault();
        }
    },

    /**
     * Check is there a custom cancel handler, is no cancel handler defined go back to user select.
     */
    onLoginCancelHandler: function() {
        this.form.get('password').setValue('');
        this.showPinCbox(false);

        if (this.options.configs.cancelLoginHandler) {
            this.options.configs.cancelLoginHandler();
        } else {
            this.hideLogin();
            this.showSelectRole();
        }
    },

    /**
     * Open role from options configs and show login form.
     */
    openRole: function() {
        var user = this.options.configs.user;

        if (user) {
            this.setLoginRole(user);

            this.hideSelectRole(true);
            this.showLogin(true);
        }
    },

    /**
     * Switch user role and show login form or show select role component.
     * @param event.currentTarget.user Selected user
     */
    switchRole: function(event) {
        if (this.loginIsRunning) {
            return;
        }

        var $el = this.$(event.currentTarget);
        var user = $el.data('user');

        this.toggleForgotPassword(user);
        this.toggleLdapActive(user);

        this.clearForm();

        if (this.state.getState() === states.signin) {
            this.setLoginRole(user);
        } else {
            this.selectUser(event);
        }
    },

    /**
     * Select user and show login form.
     * @param event.currentTarget.user Selected user
     */
    selectUser: function(event) {
        if (this.state.getState() === states.selectUser) {
            var $el = this.$(event.currentTarget);
            var user = $el.data('user');

            this.toggleForgotPassword(user);
            this.toggleLdapActive(user);

            this.setLoginRole(user);

            this.hideSelectRole();
            this.showLogin(true);
        }
    },

    /**
     * Show select role component.
     */
    showSelectRole: function() {
        this.$adminSelectItem.removeClass('hidden');
        this.state.changeState(states.selectUser);

        this.$select
            .velocity('fadeIn', {
                duration: DURATION,
                complete: function() {
                    this.$select
                        .css({
                            position: 'relative'
                        });
                }.bind(this)
            });
    },

    /**
     * Hide select role component.
     * @param direct
     */
    hideSelectRole: function(direct) {
        this.$adminSelectItem.addClass('hidden');
        this.$select
            .css({
                position: 'absolute',
                zIndex: '22'
            });

        if (direct) {
            this.$select.hide();
        } else {
            this.$select
                .velocity('fadeOut', {
                    duration: DURATION
                });
        }
    },

    /**
     * Check type of Moderator password (password/PIN)
     */
    checkGuestPasswordType: function() {
        this.deviceConnection
            .send('getPasswordType')
            .then(this.changePasswordType.bind(this));
    },

    /**
     * Change password type and update placeholder and error text.
     * @param data.passwordType password/pin
     */
    changePasswordType: function(data) {
        switch (data.passwordType) {
            case 'password':
                this.isPin = false;
                this.$password.attr('placeholder', i18n.t('login.password'));
                this.$passwordError.text(i18n.t('login.wrong_password'));
                this.showPinCbox(false);
                break;
            case 'pin':
                this.isPin = true;
                this.$password.attr('placeholder', i18n.t('login.pin'));
                this.$passwordError.text(i18n.t('login.wrong_pin'));
                this.showPinCbox(true);
                break;
            case 'userPin':
                this.isPin = true;
                this.$password.attr('placeholder', i18n.t('login.pin'));
                this.$passwordError.text(i18n.t('login.wrong_pin'));
                this.showPinCbox(false);
                break;
        }
    },

    /**
     * Checks if the admin-pin mode is enabled. If mode is enabled send show-pin command.
     */
    setShowAdminPin: function() {
        if (Platform.checks.isCbox) {
            return;
        }

        this.deviceConnection
            .send([
                'getAdminPinStatus',
                'getAdminPinOnlyWhilePresenting',
                'getPresentationStatus'
            ])
            .then(function(adminPinStatus, adminPinOnlyWhilePresenting, presentationStatus) {
                let show = (adminPinStatus.status && adminPinOnlyWhilePresenting.enabled) ? (presentationStatus.active) : adminPinStatus.status;

                if (show) {
                    this.showPinCbox(true);
                    this.$adminPinField.removeClass('hidden');
                } else {
                    this.showPinCbox(false);
                    this.$adminPinField.addClass('hidden');
                }
            }.bind(this));
    },

    /**
     * Show/hide Moderator/Admin PIN flap on HDMI 1/2 (cbox).
     * @param enable: true => pin is displayed, false not
     */
    showPinCbox: function(enable) {
        this.deviceConnection.send('setPinVisibility', {
            showPin: enable
        });
    },

    /**
     * Opens reset password modal to enter support PIN.
     */
    forgotPassword: function() {
        app.emit('modal.open', {
            id: 'supportPin'
        });
    },

    /**
     * Show or hide reset password button.
     *
     * @param user
     */
    toggleForgotPassword: function(user) {
        if (user === 'admin') {
            this.$forgotPassword.show();
        } else {
            this.$forgotPassword.hide();
        }
    },

    /**
     * Show login form.
     */
    showLogin: function(direct) {
        this.state.changeState(states.signin);
        this.actionView.open();

        if (direct) {
            this.$login
                .css({
                    top: 0
                })
                .show();
        } else {
            this.$login
                .show()
                .css({
                    height: '-=70px'
                });

            this.$login
                .velocity({
                    'top': 0,
                    'height': '+=70px'
                }, {
                    duration: DURATION,
                    complete: function() {
                        if (!Platform.checks.isIOS) {
                            this.remote.focus(this.form.get('password').$el.get(0));
                            this.form.get('password').$el.click();
                        }
                    }.bind(this)
                });
        }
    },

    /**
     * Hide login form.
     */
    hideLogin: function() {
        this.$login
            .velocity({
                top: '-35px',
                height: '-=70px'
            }, {
                duration: DURATION,
                complete: function() {
                    this.$login
                        .hide()
                        .css({
                            height: 'auto'
                        });
                }.bind(this)
            });
    },

    /**
     * Show error message if login is invalid.
     */
    showError: function() {
        if (this.$loginValidateContainerUser) {
            this.$loginValidateContainerUser
                .removeClass('is-valid')
                .addClass('is-invalid');
        }

        this.$loginValidateContainerPwd
            .removeClass('is-valid')
            .addClass('is-invalid');
        this.$adminPinField
            .removeClass('is-valid')
            .addClass('is-invalid');
    },

    /**
     * Hide error messages of login form.
     */
    hideError: function() {
        if (this.$loginValidateContainerUser) {
            this.$loginValidateContainerUser
                .removeClass('is-invalid');
        }

        this.$loginValidateContainerPwd
            .removeClass('is-invalid');
        this.$adminPinField
            .removeClass('is-invalid');
    },

    /**
     * Show Legal&Regulatory info modal.
     */
    showRegulatoryInfo: function() {
        this.emit('modal.open', {
            id: 'regulatory-info',
            options: {
                user: this.options.configs.user
            }
        });
    }
});
