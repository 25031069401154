'use strict';

var app = require('../app');

/**
 * @class Poll
 * @param {Object} options
 */
function Poll(options) {
    /**
     * @type {Object}
     */
    this.options = options;

    /**
     * Reference to global app object
     * @type {Object}
     */
    this.app = app;

    /**
     * List of all check-handlers
     * @type {Array}
     */
    this.checks = [];

    /**
     * @type {Boolean}
     */
    this.isPolling = false;

    /**
     * @type {Function}
     */
    this.stopPolling = null;
}

/**
 * Start the polling
 * @method start
 */
Poll.prototype.start = function start() {
    this.stopPolling = app.on('main-loop.update', function() {
        var runDoneCallback = true;
        var done = function() {
            var checksClone = this.checks.slice(0);

            runDoneCallback = true;

            checksClone.some(function(check, index) {
                var checked = check.call(this, function stop() {
                    this.stopPolling();
                    this.isPolling = false;
                    this.checks = [];
                }.bind(this));

                if (checked) {
                    this.checks.splice(index, 1);
                }
            }.bind(this));
        };

        if (!this.checks.length) {
            this.destroy();

            return;
        }

        if (runDoneCallback) {
            runDoneCallback = false;
            this.options.pollHandler.call(this, done.bind(this));
        }
    }.bind(this));

    this.isPolling = true;
};

/**
 * @method addCheck
 * @param {Function} check
 */
Poll.prototype.addCheck = function addCheck(check) {
    this.checks.push(check);

    return {
        addCheck: this.addCheck.bind(this)
    };
};

/**
 * @method destroy
 * @param {Function} destroy
 */
Poll.prototype.destroy = function destroy() {
    this.stopPolling();
    this.checks = [];
    this.isPolling = false;
};

app.service('PollFactoryService', function() {
    return {
        /**
         * @method create
         * @param {Object} options
         */
        create: function(options) {
            return new Poll(options);
        },

        /**
         * @method destroy
         * @param {Object} poll
         */
        destroy: function(poll) {
            if (poll && poll.destroy) {
                poll.destroy();
            }
        }
    };
});
