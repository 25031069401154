'use strict';

var app = require('../../../../app');
var platform = require('../../../../../platform/platform');
var _ = require('lodash');
var $ = require('jquery');

/**
 * Bookmark state (current url is saved as bookmark or not)
 * @type {{notSaved: string, saved: string}}
 */
var bookmarkStates = {
    'notSaved': 'notSaved',
    'saved': 'saved'
};

var controlItems = [
    {
        id: 'backward',
        state: 'backward',
        states: {
            'backward': {
                icon: 'icon-v2-arrow-left',
                titleKey: ''
            }
        },
        showOnCBP: true
    },
    {
        id: 'forward',
        state: 'forward',
        states: {
            'forward': {
                icon: 'icon-v2-arrow-right',
                titleKey: ''
            }
        },
        groupEnd: true,
        showOnCBP: true
    },
    {
        id: 'reload',
        state: 'reload',
        states: {
            'reload': {
                icon: 'icon-v2-reload',
                titleKey: ''
            }
        },
        showOnCBP: true
    },
    {
        id: 'mute',
        type: 'mute',
        state: 'mute'
    },
    {
        id: 'volume',
        type: 'volume',
        groupEnd: true
    },
    {
        id: 'zoom-out',
        state: 'zoom-out',
        states: {
            'zoom-out': {
                icon: 'icon-v2-zoom-out',
                titleKey: ''
            }
        },
        showOnCBP: true
    },
    {
        id: 'zoom-in',
        state: 'zoom-in',
        states: {
            'zoom-in': {
                icon: 'icon-v2-zoom-in',
                titleKey: ''
            }
        },
        showOnCBP: true
    }
];

var pdfControlItems = $.merge([
    {
        id: 'prevPage',
        state: 'prevPage',
        states: {
            'prevPage': {
                icon: 'icon-arrow-left6',
                titleKey: ''
            }
        }
    },
    {
        id: 'nextPage',
        state: 'nextPage',
        states: {
            'nextPage': {
                icon: 'icon-uniE709',
                titleKey: ''
            }
        }
    },
    {
        id: 'full-page',
        state: 'full-page',
        states: {
            'full-page': {
                icon: 'icon-v2-fit-to-width-height',
                titleKey: ''
            }
        }
    },
    {
        id: 'full-page-width',
        state: 'full-page-width',
        states: {
            'full-page-width': {
                icon: 'icon-v2-fit-to-width',
                titleKey: ''
            }
        }
    }
], controlItems);

/**
 * Video frame
 */
app.component('FrameBoxBrowserViewer', {
    extend: 'FrameBoxBaseType',
    className: 'framebox-view framebox-browser-view',
    prevInputValue: null,
    isOnScrolling: false,
    isOnScrollingTimeout: null,
    currentInputType: 'notext',

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.index = this.options.index;
        this.appId = this.options.appId;
        this.clickTimeout = null;
        this.hasManualClick = false;
        this.url = null;
        this.frameBoxService = this.getService('FrameBoxService');
        this.liveStream = this.getService('LiveStreamService');
        this.storage = this.getService('DataStorage');
        this.remote = this.getService('RemoteService');
        this.mountService = this.getService('MountService');
        this.keyEventHandlerService = this.getService('KeyEventHandlerService');
        this.customUiSettings = app.getService('CustomUiSettings');
        this.deviceService = app.getService('DeviceService');
        this.userSettingsService = app.getService('UserSettingsService');
        this.screensaverService = this.getService('ScreensaverService');

        this.embeddedPdf = false;
        this.componentId = null;
        this.debugging = false;
        this.options.allowedEvents = ['touchstart', 'touchend', 'mousedown', 'mouseup', 'touchmove', 'mousemove'];

        this.bookmarkState = this.createStateMachine({
            state: bookmarkStates.notSaved,
            states: bookmarkStates
        });

        this.updateBookmarkStatus();
        this.addStateTransitions();
    },

    /**
     * @method addStateTransitions
     */
    addStateTransitions: function() {
        this.bookmarkState.addTransitions({
            '> notSaved': function() {
                this.emit('framebox.control-state.change', {
                    index: this.index,
                    id: 'bookmarks',
                    state: bookmarkStates.notSaved
                });
            },
            '> saved': function() {
                this.emit('framebox.control-state.change', {
                    index: this.index,
                    id: 'bookmarks',
                    state: bookmarkStates.saved
                });
            }
        });
    },

    serialize: function() {
        return {
            isCbox: !!platform.checks.isCbox
        };
    },

    postPlaceAt: function() {
        this.bindEvents();
        this.startTouchEventsEntity({
            $actionEl: this.options.$actionEl,
            allowedEvents: this.options.allowedEvents,
            disableSelection: false,
            debugging: false
        });

        this.startControls();

        if (window.navigator.standalone) {
            this.options.$actionEl.addClass('hide-keyboard-btn');
        }
    },

    bindEvents: function() {
        this.on('framebox.change.' + this.index, this.onFrameboxChanged.bind(this));

        this.on('framebox.standard.keydown', this.keyDownHandler.bind(this));
        this.on('framebox.standard.keyup', this.keyUpHandler.bind(this));

        // Mouse
        this.on('framebox.standard.mouseevent', this.onMouseevent.bind(this));
        this.on('framebox.standard.mousewheel-up', this.controlHandlers['scroll-up'].bind(this));
        this.on('framebox.standard.mousewheel-down', this.controlHandlers['scroll-down'].bind(this));

        // Bookmarks update
        this.on('main-loop.update', this.updateBookmarkStatus.bind(this));
        this.on('main-loop.update.browser', this.updateBookmarkStatus.bind(this));

        this.on('active-framebox.changed', this.activeFrameboxChanged.bind(this));

        // Interations.
        this.on('framebox.standard.zoom-in.keydown', this.controlHandlers['zoom-in'].bind(this));
        this.on('framebox.standard.zoom-out.keydown', this.controlHandlers['zoom-out'].bind(this));
        this.on('framebox.standard.preset', this.controlHandlers['preset'].bind(this));
    },

    /**
     * Initialize Keyboard after active framebox changed
     *
     * @param index framebox index
     * @param keyboardAction focus is on an input field --> update keyboard
     */
    activeFrameboxChanged: function(index, component, keyboardAction) {
        if (index === this.options.index) {
            if (!this.keyboard) {
                this.startKeyboard();

                this.onFrameboxChanged({
                    contentType: this.frameBoxService.contentTypeMapping(this.options.type),
                    options: this.options.frameBoxOptions
                });

                return;
            }

            if (keyboardAction) {
                this.keyboardAction('update', this.box);
            }
        }
    },

    /**
     * @param {Object} box
     */
    onFrameboxChanged: function(box) {
        this.box = box;

        if (this.url !== this.box.options.url) {
            this.url = this.box.options.url;
            this.updateBookmarkStatus();
            this.emit('framebox.url.change');
        }

        if (this.title !== this.box.options.title) {
            this.title = this.box.options.title;
        }

        if (this.box.options.embeddedPdf !== this.embeddedPdf) {
            app.destroyComponent(this.componentId);
            this.embeddedPdf = this.box.options.embeddedPdf;
            this.emit('framebox.controls.focus', {
                index: this.options.index
            });
            this.remote.focus(this.$('#framebox-' + this.index + '-header-control-button-backward'));
            this.startControls();
        }

        var hasChangedKeyboard = box.options.inputType !== this.currentInputType || this.hasManualClick;
        // RELEASE-4588 Do not show keyboard when screensaver starts (only on tap into input field)
        var doNotOpenKeyboard = !this.hasManualClick && (this.screensaverService.isScreensaverActive() || this.screensaverService.isScreensaverActiveUI());

        if (this.keyboardAction && hasChangedKeyboard) {
            this.currentInputType = box.options.inputType;
            this.keyboardAction('update', box, doNotOpenKeyboard);
        }
    },

    /**
     * Updates the status of the bookmark control icon. Url is already saved ('full' star) in bookmarks or not
     * ('empty' star).
     */
    updateBookmarkStatus: function() {
        if (this.url) {
            this.deviceConnection
                .send('getBookmarkList')
                .then(function(bookmarks) {
                    var bookmarkStatus = this.getBookmarkStatus(bookmarks.list.bookmarks, this.url);

                    if (bookmarkStatus !== this.bookmarkState.getState()) {
                        this.bookmarkState.changeState(bookmarkStatus);
                    }
                }.bind(this));
        }
    },

    /**
     * Get bookmark status of current url (saved/not saved in bookmarks).
     *
     * @param list bookmark list
     * @param url current url
     */
    getBookmarkStatus: function(list, url) {
        var state = bookmarkStates.notSaved;

        _.each(list, function(bookmark) {
            if (this.getUrl(bookmark.URL) === this.getUrl(url)) {
                state = bookmarkStates.saved;

                return false;
            }
        }.bind(this));

        return state;
    },

    /**
     * Get URL for comparing.
     *
     * @param url URL
     * @returns {string|XML|void|*} URL to compare
     */
    getUrl: function(url) {
        var prefixHttps = /^https?:\/\//i;
        var prefixHttp = /^http?:\/\//i;

        url = url.replace(prefixHttps, '');
        url = url.replace(prefixHttp, '');

        if (url.charAt(url.length - 1) === '/') {
            url = url.substr(0, url.length - 1);
        }

        return url;
    },

    /**
     * Called when a mouse or touch event has been triggered on active framebox.
     *
     * @param {jQuery.MouseEvent} event
     * @param {Number} index
     */
    onMouseevent: function(event, index) {
        if (index !== this.index) {
            return;
        }

        this.hasManualClick = true;
        clearTimeout(this.clickTimeout);
        this.clickTimeout = setTimeout(function() {
            this.hasManualClick = false;
        }.bind(this), 300);
    },

    /**
     * @param {Object} event
     * @param {Number} index
     */
    keyDownHandler: function(event, index) {
        this.keyEventHandler(index, 'keydown', event);
    },

    /**
     * @param {Object} event
     * @param {Number} index
     */
    keyUpHandler: function(event, index) {
        this.keyEventHandler(index, 'keyup', event);
    },

    /**
     * @param {Number} index
     * @param {String} keyEvent
     * @param {Object} event
     */
    keyEventHandler: function(index, keyEvent, event) {
        if (index === this.index) {
            this.frameBoxService.sendKeyEvent(this.options.index, keyEvent, event);
        }
    },

    startControls: function() {
        var items = _.clone(controlItems);

        if (this.embeddedPdf) {
            items = _.clone(pdfControlItems);
        }

        if (this.deviceService.isCboxPure() || this.deviceService.isCboxPureMini()) {
            items = _.clone($.grep(controlItems, function(item) {
                return item.showOnCBP;
            }.bind(this)));
        }

        this.isBrowserEnabled()
            .then(function(isEnabled) {
                // Add home-button, bookmark-button and url-field when browser is enabled.
                if (isEnabled && !this.embeddedPdf && !this.deviceService.isCboxPure() && !this.deviceService.isCboxPureMini()) {
                    items.splice(0, 0, {
                        id: 'startpage',
                        state: 'startpage',
                        states: {
                            'startpage': {
                                icon: 'icon-v2-home',
                                titleKey: ''
                            }
                        },
                        groupEnd: true
                    });

                    items.splice(3, 0, {
                        id: 'url',
                        type: 'url',
                        state: 'url',
                        groupEnd: true
                    });

                    items.splice(5, 0, {
                        id: 'bookmarks',
                        state: 'notSaved',
                        states: {
                            'notSaved': {
                                icon: 'icon-v2-star',
                                titleKey: ''
                            },
                            'saved': {
                                icon: 'icon-star',
                                titleKey: ''
                            }
                        }
                    });
                }

                // Destroy old control-compoent.
                if (this.componentId) {
                    this.app.destroyComponent(this.componentId);
                }

                // Start controls.
                this.componentId = this.createComponent({
                    type: 'FrameBoxControls',
                    container: this.$el,
                    index: this.options.index,
                    appId: this.options.appId,
                    items: items,
                    onClick: this.onControlClickHandler.bind(this),
                    isFullscreen: this.options.isFullscreen,
                    frameBoxOptions: this.options.frameBoxOptions
                });
            }.bind(this));
    },

    /**
     * @param {String} id
     */
    onControlClickHandler: function(id) {
        var handler = this.controlHandlers[id];

        if (this.keyboardAction) {
            this.keyboardAction('close');
        }

        if (handler) {
            handler.call(this, {
                index: this.index
            });
        }
    },

    /**
     * Start Keyboard Component.
     */
    startKeyboard: function() {
        this.keyboard = this.createComponent({
            type: 'FrameboxKeyboard',
            container: this.$el,
            index: this.options.index,
            action: function(fn) {
                this.keyboardAction = fn;
            }.bind(this)
        });
    },

    /**
     * Checks if the touch is on a scrollbar-area.
     *
     * @param {Number} position
     * @param {String} posToCheck
     *
     * @return {Boolean}
     */
    isScrollbar: function(position, posToCheck) {
        var windowDimensions = this.options.$actionEl.width();
        var scrollbarDimension = windowDimensions * 0.013; // 1.3% from windowsDimensions

        if ('y' === posToCheck) {
            windowDimensions = this.options.$actionEl.height();
            scrollbarDimension = windowDimensions * 0.023; // 2.3% from windowsDimensions
        } else {
            position -= this.frameBoxService.frameboxes.boxes[this.frameBoxService.getActiveFrameBox()].coordinates.x;
        }

        this.showScrollbarDebugger(scrollbarDimension, posToCheck);

        return windowDimensions - scrollbarDimension < position;
    },

    /**
     * This will visible the scrollarea for all scrollbars on the framebox.
     *
     * @param scrollbarDimension
     * @param posToCheck
     */
    showScrollbarDebugger: function(scrollbarDimension, posToCheck) {
        var $el = this.options.$actionEl.find('#scrollbar-' + posToCheck);

        if (true !== this.debugging) {
            return;
        }

        if (0 === $el.length) {
            this.options.$actionEl.append('<div id="scrollbar-' + posToCheck + '"></div>');
            $el = this.options.$actionEl.find('#scrollbar-' + posToCheck);
        }

        $el.css({
            position: 'absolute',
            right: 0,
            bottom: 0,
            border: '1px solid red',
            pointerEvents: 'none'
        });

        if ('y' === posToCheck) {
            $el.css({
                width: '100%',
                height: scrollbarDimension
            });
        } else {
            $el.css({
                width: scrollbarDimension,
                height: '100%'
            });
        }
    },

    /**
     * @type {Object}
     */
    controlHandlers: {
        /**
         * @method startpage
         */
        'startpage': function(options) {
            this.sendCmd('startpage', options.index);
            this.emit('framebox.url.change');
        },
        /**
         * @method bookmarks
         */
        'bookmarks': function() {
            this.mountService.checkIfServiceAvailable('browser.bookmarks')
                .then(this.bookmarksAvailableHandler.bind(this));
        },
        /**
         * @method zoom-out
         */
        'zoom-out': function(options) {
            this.sendCmd('zoomOut', options.index);
        },

        /**
         * @method zoom-in
         */
        'zoom-in': function(options) {
            this.sendCmd('zoomIn', options.index);
        },

        /**
         * @method scroll-up
         */
        'scroll-up': function(options) {
            if (this.isScrollbar(options.x, 'x')) {
                return;
            }

            this.setIsOnScrolling();
            this.sendCmd('scrollUp', options.index);
        },

        /**
         * @method scroll-down
         */
        'scroll-down': function(options) {
            if (this.isScrollbar(options.x, 'x')) {
                return;
            }

            this.setIsOnScrolling();
            this.sendCmd('scrollDown', options.index);
        },

        /**
         * @method scroll-left
         */
        'scroll-left': function(options) {
            if (this.isScrollbar(options.y, 'y')) {
                return;
            }

            this.setIsOnScrolling();
            this.sendCmd('scrollLeft', options.index);
        },

        /**
         * @method scroll-right
         */
        'scroll-right': function(options) {
            if (this.isScrollbar(options.y, 'y')) {
                return;
            }

            this.setIsOnScrolling();
            this.sendCmd('scrollRight', options.index);
        },

        /**
         * @method forward
         */
        'forward': function(options) {
            this.sendCmd('forward', options.index);
            this.emit('framebox.url.change');
        },

        /**
         * @method backward
         */
        'backward': function(options) {
            this.sendCmd('back', options.index);
            this.emit('framebox.url.change');
        },

        /**
         * @method reload
         */
        'reload': function(options) {
            this.sendCmd('reload', options.index);
            this.emit('framebox.url.change');
        },

        /**
         * @method preset
         */
        'preset': function(options) {
            this.sendCmd('pdfZoomFullWidth', options.index);
        },

        /**
         * @method full page
         */
        'full-page': function(options) {
            this.sendCmd('pdfZoomFullPage', options.index);
        },

        /**
         * @method full page width
         */
        'full-page-width': function(options) {
            this.sendCmd('pdfZoomFullWidth', options.index);
        },

        /**
         * @method prev page
         */
        'prevPage': function(options) {
            this.sendCmd('pdfPrevPage', options.index);
        },

        /**
         * @method next page
         */
        'nextPage': function(options) {
            this.sendCmd('pdfNextPage', options.index);
        }
    },

    /**
     * @param {String} cmd
     * @param {Number} index
     */
    sendCmd: function(cmd, index) {
        if (this.index === index) {
            this.send('setBrowserControl', {
                appId: this.options.appId,
                browserControl: cmd
            });
        }
    },

    /**
     * Check if bookmark service is available on usb (user settings) and if bookmarks user data is loaded
     * Open bookmarks overlay if OK.
     *
     * @param {Object} data
     * @param {Boolean} data.serviceAvailable
     */
    bookmarksAvailableHandler: function(data) {
        this.deviceConnection
            .send([
                {
                    command: 'getUserSettingsStatus',
                    data: {
                        mode: 'internal'
                    }
                }
            ])
            .then(function(userSettings) {
                var browserSettings = userSettings.settings.browser;
                var loaded = false;

                var loadBookmarks = {
                    framebox: this.index,
                    url: this.url,
                    title: this.title
                };

                if (browserSettings) {
                    loaded = browserSettings[0].bookmarks.status === 1;
                }

                if (data.serviceAvailable && !loaded) {
                    this.userSettingsService.openUserSettingsDialog(true, {
                        loadBookmarks: loadBookmarks,
                        tryToConnect: true
                    });

                    return;
                }

                this.openBookmarksOverlay();
            }.bind(this));
    },

    /**
     * Open bookmarks overlay if bookmark control is pressed and usb settings are OK.
     */
    openBookmarksOverlay: function() {
        this.emit('overlay.open', {
            component: 'Bookmarks',
            titleKey: 'bookmarks.bookmarks',
            closeButton: true,
            framebox: this.index,
            appId: this.appId,
            url: this.url,
            embeddedPdf: this.embeddedPdf,
            title: this.title,
            role: {
                name: 'Bookmarks',
                key: 'show'
            }
        });
    },

    /**
     * Set "is scrolling" to true and after 200ms to false.
     */
    setIsOnScrolling: function() {
        this.isOnScrolling = true;

        clearTimeout(this.isOnScrollingTimeout);
        this.isOnScrollingTimeout = setTimeout(function() {
            this.isOnScrolling = false;
        }.bind(this), 500);
    },

    /**
     * Returns a promise that will store a boolean if the browser is enabled in general-settings.
     *
     * @return {Promise}
     */
    isBrowserEnabled: function() {
        return this.customUiSettings.getSettings('browserEnabled');
    },

    destroy: function() {
        if (this.keyboardAction) {
            this.keyboardAction('close');
        }

        this.stopTouchEventsEntitiy();

        this.$('html, body').scrollTop(0);
        this.$(document).off('mousedown.keyboard-' + this.index);
        this.$(document).off('touchstart.keyboard-' + this.index);
    }
});
