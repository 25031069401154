// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"cloud-settings\" name=\"cloud-settings\">\n        <div class=\"row settings-list-row\"\n             data-nav-area=\".btn-switch\"\n             data-nav-trapped\n             data-nav-area-default>\n            <div class=\"col-xs-6\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.dropbox",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":44}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-2 pull-right\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"dropbox-enabled\" id=\"dropbox-enabled\" type=\"checkbox\" class=\"btn-switch\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-row\" data-nav-area=\".btn-switch\" data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.google_drive",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":22,"column":49}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-2 pull-right\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"gdrive-enabled\" id=\"gdrive-enabled\" type=\"checkbox\" class=\"btn-switch\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-row\" data-nav-area=\".btn-switch\" data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.jianguoyun",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":20},"end":{"line":36,"column":47}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-2 pull-right\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"jianguoyun-enabled\" id=\"jianguoyun-enabled\" type=\"checkbox\" class=\"btn-switch\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-row\" data-nav-area=\".btn-switch\" data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.onedrive",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":20},"end":{"line":50,"column":45}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-2 pull-right\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"onedrive-enabled\" id=\"onedrive-enabled\" type=\"checkbox\" class=\"btn-switch\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"row settings-list-row\" data-nav-area=\".btn-switch\" data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.webdav",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":20},"end":{"line":64,"column":43}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-2 pull-right\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"webdav-enabled\" id=\"webdav-enabled\" type=\"checkbox\" class=\"btn-switch\" />\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"row settings-list-secundary hidden\" id=\"webdav-container\" data-nav-area=\".is-focusable:not(:disabled)\" data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"webdav-url\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.webdav_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":64},"end":{"line":78,"column":91}}}))
    + "</label>\n                    <input type=\"text\" class=\"input is-focusable\" name=\"webdav-url\" id=\"webdav-url\" tabIndex=\"-1\"\n                           data-validation=\"checkMinMaxLength\" data-min-length=\"1\" data-max-length=\"255\"/>\n                    <div class=\"input-info\">\n                       <span class=\"input-info-message error-message\"> "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":82,"column":71},"end":{"line":82,"column":102}}}))
    + " </span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"webdav-name\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.webdav_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":88,"column":65},"end":{"line":88,"column":93}}}))
    + "</label>\n                    <input type=\"text\" class=\"input is-focusable\" name=\"webdav-name\" id=\"webdav-name\"\n                           placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.webdav",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":90,"column":40},"end":{"line":90,"column":63}}}))
    + "\" tabIndex=\"-1\" data-validation=\"checkLength\"\n                           data-max-length=\"63\"/>\n                    <div class=\"input-info\">\n                       <span class=\"input-info-message error-message\"> "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.invalid_length_0_63",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":93,"column":71},"end":{"line":93,"column":122}}}))
    + " </span>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"form-action-container\"></div>\n    </form>\n</div>\n";
},"useData":true});
