'use strict';

module.exports = [
    {
        id: 'scroll-down',
        state: 'scroll-down',
        states: {
            'scroll-down': {
                icon: 'icon-v2-cursor-down',
                titleKey: ''
            }
        }
    },
    {
        id: 'scroll-up',
        state: 'scroll-up',
        states: {
            'scroll-up': {
                icon: 'icon-v2-cursor-up',
                titleKey: ''
            }
        }
    },
    {
        id: 'scroll-left',
        state: 'scroll-left',
        states: {
            'scroll-left': {
                icon: 'icon-v2-cursor-left',
                titleKey: ''
            }
        }
    },
    {
        id: 'scroll-right',
        state: 'scroll-right',
        states: {
            'scroll-right': {
                icon: 'icon-v2-cursor-right',
                titleKey: ''
            }
        },
        groupEnd: true
    },
    {
        id: 'prev-worksheet',
        state: 'prev-worksheet',
        states: {
            'prev-worksheet': {
                icon: 'icon-v2-arrow-left',
                titleKey: ''
            }
        }
    },
    {
        id: 'next-worksheet',
        state: 'next-worksheet',
        states: {
            'next-worksheet': {
                icon: 'icon-v2-arrow-right',
                titleKey: ''
            }
        },
        groupEnd: true
    },
    {
        id: 'zoom-out',
        state: 'zoom-out',
        states: {
            'zoom-out': {
                icon: 'icon-v2-zoom-out',
                titleKey: ''
            }
        }
    },
    {
        id: 'zoom-in',
        state: 'zoom-in',
        states: {
            'zoom-in': {
                icon: 'icon-v2-zoom-in',
                titleKey: ''
            }
        }
    },
    {
        id: 'full-page-width',
        state: 'full-page-width',
        states: {
            'full-page-width': {
                icon: 'icon-v2-fit-to-width',
                titleKey: ''
            }
        }
    },
    {
        id: 'full-page',
        state: 'full-page',
        states: {
            'full-page': {
                icon: 'icon-v2-fit-to-width-height',
                titleKey: ''
            }
        }
    }
];
