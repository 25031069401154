// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"icon-jianguoyun\"></span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"icon-cloud2\"></span>\n                <span class=\"title\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":8,"column":36},"end":{"line":8,"column":45}}}) : helper)))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"text\" name=\"username\" class=\"input focusable cloud-login-username\" autocomplete=\"off\"\n                           for=\"username\" id=\"username\" placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"login.email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":69},"end":{"line":15,"column":88}}}))
    + "\"\n                           data-validation=\"checkEmail\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\" id=\"user-error\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"login.wrong_email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":28},"end":{"line":19,"column":53}}}))
    + "\n                        </span>\n                    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <input type=\"text\" name=\"username\" class=\"input focusable cloud-login-username\" autocomplete=\"off\"\n                           for=\"username\" id=\"username\" placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"login.username_email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":69},"end":{"line":24,"column":97}}}))
    + "\" data-validation=\"required\"/>\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\" id=\"user-error\">\n                            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"error_messages.wrong_username_email_require",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":28},"end":{"line":27,"column":79}}}))
    + "\n                        </span>\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"cloud-login-container\">\n    <form id=\"cloud-login-form\" name=\"cloud-login-form\" data-nav-area=\".focusable:not(:disabled)\">\n        <h2 class=\"has-icon\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isJianguoyun") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + "        </h2>\n        <div class=\"cloud-login-form\">\n            <div class=\"input-group\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isWebdav") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":30,"column":27}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"input-group\">\n                <input type=\"password\" name=\"password\" id=\"password\" class=\"input focusable\"\n                       placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"login.password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":36},"end":{"line":34,"column":58}}}))
    + "\" data-validation=\"checkMinLength\" data-min-length=\"1\"/>\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\" id=\"password-error\">\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"login.wrong_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":24},"end":{"line":37,"column":52}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n        <div id=\"form-action-container\"></div>\n    </form>\n</div>\n";
},"useData":true});
