// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"matrix-group-item\" data-index=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n    <div class=\"row\"\n        data-nav-area=\".is-focusable, .btn-form\">\n        <div class=\"col-xs-6\">\n            <div class=\"input-group\">\n                <label class=\"input-label\" for=\"groupName"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias4).call(alias3,depth0,{"name":"tr","hash":{"key":"settings.group_name"},"data":data,"loc":{"start":{"line":6,"column":70},"end":{"line":6,"column":107}}}))
    + "</label>\n                <input type=\"text\"\n                    class=\"input group-name is-focusable\"\n                    name=\"groupName"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                    data-validation=\"checkMinMaxLength\"\n                    data-min-length=\"1\"\n                    data-max-length=\"254\"/>\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\">\n                        "
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":55}}}))
    + "\n                    </span>\n                </div>\n            </div>\n        </div>\n        <div class=\"command-button-container col-xs-4\">\n            <h2 class=\"input-label\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.share_to_group_enable",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":36},"end":{"line":21,"column":74}}}))
    + "</h2>\n            <div class=\"btn-switch-container pull-left\">\n                <input name=\"shareToGroupEnable"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                        type=\"checkbox\"\n                        class=\"btn-switch command-button-switch\"/>\n                <div class=\"btn-switch-toggle-container\">\n                    <div class=\"btn-switch-toggle\"></div>\n                </div>\n            </div>\n        </div>\n        <div class=\"col-xs-2 pull-right\">\n            <input type=\"button\"\n                class=\"btn-form action-btn-style delete-group pull-right\"\n                data-index=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                name=\"groupDelete\"\n                value=\""
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":23},"end":{"line":36,"column":47}}}))
    + "\"/>\n        </div>\n    </div>\n</div>\n";
},"useData":true});
