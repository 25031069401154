// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"matrix-image-container\">\n            <div class=\"loader\"></div>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"matrix-icon-container\">\n            <span class=\"icon icon-vmatrix\"></span>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"matrix-modal-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loader") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"matrix-message-container\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"messageKey") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":42},"end":{"line":11,"column":58}}}))
    + "</div>\n</div>\n";
},"useData":true});
