'use strict';

const $ = require('jquery');
const app = require('../app');
const platform = require('../../platform/platform');
const RedrawEngine = require('../../redraw-engine');
const StateMachine = require('./../state-machine');
const rbac = require('../../rbac/rbac');

/**
 * Admin message states
 */
var state = {
    stopped: 'stopped',
    started: 'started'
};

app.service('AdminMessageService', function(app) {
    return {

        initialize: function() {
            this.liveStream = app.getService('LiveStreamService');
            this.authService = app.getService('AuthenticationService');
            this.deviceService = app.getService('DeviceService');

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.adminMessageState = new StateMachine({
                        context: this,
                        state: state.stopped,
                        states: state
                    });

                    this.deviceConnection = connection;

                    this.annotationService = app.getService('AnnotationService');

                    this.addStateTransitions();

                    setTimeout(function() {
                        this.checkAdminMessageEnable();
                    }.bind(this), 2000);
                }.bind(this));
        },

        /**
         * Only start status polling if Admin Message setting is enabled.
         */
        checkAdminMessageEnable: function() {
            this.deviceConnection
                .send([
                    'getAdminMessageEnable'
                ])
                .then(function(adminMessage) {
                    if (adminMessage.enable) {
                        // Start a separate loop instead of the main loop to prevent problems if the main loop is not running anymore.
                        this.adminMessageLoop = setInterval(this.updateHandler.bind(this), 1000);
                    } else {
                        // RELEASE-4570: do not always check if Admin Message is disabled.
                        this.adminMessageLoop = setInterval(this.updateHandler.bind(this), 10000);
                    }
                }.bind(this));
        },

        updateHandler: function() {
            this.deviceConnection
                .send([
                    'getAdminMessageStatus'
                ])
                .then(function(adminMessage) {
                    if (adminMessage.status !== this.adminMessageState.getState()) {
                        this.adminMessageState.changeState(adminMessage.status);
                    }
                }.bind(this));
        },

        addStateTransitions: function() {
            this.adminMessageState.addTransitions({
                '> started': function() {
                    if (this.annotationService.globalState.getState() === 'started') {
                        this.annotationService.stopAnnotationExternal(true);
                    }

                    app.emit('admin-message.state.change', 'started');

                    // If live preview is not running start it manually.
                    if (!platform.checks.isCbox && !this.liveStream.Engine
                        && !rbac.hasAccess('ControlScreen', 'show')) {
                        this.$mainEl = $('#main');

                        const redrawEngine = new RedrawEngine();

                        this.liveStream
                            .setCanvas(this.$mainEl.find('#livestream'))
                            .setEngine(redrawEngine)
                            .start();

                        console.log('start engine and live preview');
                    }
                },
                'started > stopped': function() {
                    app.emit('admin-message.state.change', 'stopped');
                    clearInterval(this.adminMessageLoop);
                    window.location.reload();
                }
            });
        },

        isAdminMessageStarted: function() {
            return this.adminMessageState.getState() === state.started;
        }
    };
});
