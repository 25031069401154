'use strict';

var _ = require('lodash');
var $ = require('jquery');

/**
 * @constructor
 * @param {object} options
 */
function ScrollView(options) {
    var defaultOptions = {
        $container: null,
        $items: null,
        itemSelector: null,
        entryFocusable: true
    };

    this.id = _.uniqueId('ScrollView');

    this.options = _.extend(defaultOptions, options);

    this.$document = $(document);

    this.bindDOMEvents();
}

/**
 * @method bindDOMEvents
 */
ScrollView.prototype.bindDOMEvents = function bindDOMEvents() {
    this.$document.on('keydown.scroll-view.' + this.id, this.scrollHandler.bind(this));
};

/**
 * @method scrollHandler
 * @param {Object} event
 */
ScrollView.prototype.scrollHandler = function scrollHandler(event) {
    var $container = this.options.$container;
    var $items = this.getItems();
    var $item = $items.filter(function(index, el) {
        var $el = $(el);

        if (this.options.entryFocusable) {
            return $el.hasClass('focused');
        } else {
            return $el.hasClass('focused') || $el.find('.is-focusable.focused').length > 0;
        }
    }.bind(this));
    var itemHeight = $item.outerHeight(true);
    var scrollTo;
    var top;
    var bottom;
    var left;
    var right;
    var spacing = $container.data('scrolling-spacing');

    if ($item.length > 0) {
        switch (event.keyCode) {
            case 38:
                // Up.
                top = $item.position().top;

                if (top <= 0) {
                    // -2 so there will be a small offset.
                    scrollTo = $container.scrollTop() + top - 2;
                    $container.scrollTop(scrollTo);
                }

                event.preventDefault();
                break;
            case 40:
                // Down
                bottom = $item.position().top + itemHeight;

                if (bottom >= $container.height()) {
                    scrollTo = $container.scrollTop() + bottom - $container.height() + 1;
                    $container.scrollTop(scrollTo);
                }

                event.preventDefault();
                break;
            case 37:
                // Left
                left = $item.position().left;

                if (spacing) {
                    left -= spacing;
                }

                if (left <= 0) {
                    $container.scrollLeft($container.scrollLeft() + left - 2);
                }

                event.preventDefault();
                break;
            case 39:
                // Right
                right = $item.position().left + $item.outerWidth(true);

                if (spacing) {
                    right += spacing;
                }

                if (right >= $container.width()) {
                    scrollTo = $container.scrollLeft() + right - $container.width() + 2;
                    $container.scrollLeft(scrollTo);
                }

                event.preventDefault();
                break;
        }
    }
};

ScrollView.prototype.getItems = function() {
    var $items = this.options.$items;

    if (this.options.itemSelector) {
        $items = this.options.$items.find(this.options.itemSelector);
    }

    return $items;
};

/**
 * @method destroy
 */
ScrollView.prototype.destroy = function destroy() {
    this.$document.off('keydown.scroll-view.' + this.id);
};

module.exports = ScrollView;
