const template = require('./options-menu.html');
const vueUtils = require('../../../components-vue/util');
const app = require('../../../app.js');

module.exports = {
    template,
    props: {
        show: {
            type: Boolean,
            required: false,
            default: false
        },
        item: {
            type: Object,
            required: true,
            default: function() {
                return {
                    path: 'Internal:///test.png',
                    type: 'image',
                    icon: 'icon-filetype-picture',
                    name: 'test.png',
                    selected: false
                };
            }
        },
        positionX: {
            type: Number,
            required: false
        },
        positionY: {
            type: Number,
            required: false
        },
        containerLeft: {
            type: Number,
            required: false
        },
        containerTop: {
            type: Number,
            required: false
        },
        hasAnyCopyTargets: {
            type: Boolean,
            required: false,
            default: false
        },
        scalingFactor: {
            type: Number,
            required: false,
            default: 1
        }
    },

    data: function() {
        return {
            width: 0,
            height: 0
        };
    },

    computed: {
        relativePositionLeft: function() {
            return (this.positionX - this.containerLeft) / this.scalingFactor + 15 - this.width;
        },
        relativePositionTop: function() {
            return (this.positionY - this.containerTop) / this.scalingFactor + 15 - this.height;
        }
    },

    methods: {
        uploadItem: function() {
            this.$emit('upload-item', this.item);
        },
        deleteItem: function() {
            this.$emit('delete-item', this.item);
        },

        i18n: vueUtils.i18n
    },

    watch: {
        show: function(val) {
            if (val) {
                this.remote.focus(this.$refs.itemUpload || this.$refs.itemDelete);
            }
        }
    },

    created: function() {
        this.remote = app.getService('RemoteService');
    },

    updated: function() {
        this.width = this.$el.offsetWidth;
        this.height = this.$el.offsetHeight;
    }
};
