// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return " darkUi";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"session-management\"\n     class=\"session-management-container"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"darkUi") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":40},"end":{"line":2,"column":68}}})) != null ? stack1 : "")
    + "\">\n    <div id=\"session-management-meeting-list-container\"\n         class=\"session-management-meeting-list-container\">\n        <div class=\"session-management-btn-container nav-btn\">\n            <div id=\"session-management-now\"\n                 class=\"session-management-btn is-focusable"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"darkUi") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":59},"end":{"line":7,"column":87}}})) != null ? stack1 : "")
    + " hidden\"\n                 data-action=\"now\">\n                <span>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(alias1,"session_management.now",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":22},"end":{"line":9,"column":53}}}))
    + "</span>\n            </div>\n        </div>\n        <div id=\"session-management-meeting-list\" class=\"session-management-meeting-list\"></div>\n        <div class=\"session-management-btn-container nav-btn\">\n            <div id=\"session-management-down\"\n                 class=\"session-management-btn round is-focusable"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"darkUi") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":65},"end":{"line":15,"column":93}}})) != null ? stack1 : "")
    + " hidden\"\n                 data-action=\"down\">\n                <span class=\"icon icon-arrow-back\"></span>\n            </div>\n            <div id=\"session-management-up\"\n                 class=\"session-management-btn round is-focusable"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"darkUi") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":65},"end":{"line":20,"column":93}}})) != null ? stack1 : "")
    + " hidden\"\n                 data-action=\"up\">\n                <span class=\"icon icon-arrow-back\"></span>\n            </div>\n        </div>\n    </div>\n    <div class=\"session-management-common-btn-container\">\n        <div class=\"session-management-btn-container common-btn\">\n            <div id=\"session-management-end-presentation\"\n                 class=\"session-management-btn round is-focusable"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"darkUi") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":65},"end":{"line":29,"column":93}}})) != null ? stack1 : "")
    + "\"\n                 data-action=\"end-presentation\">\n                <span class=\"icon-v2-power\"></span>\n            </div>\n        </div>\n        <div class=\"session-management-btn-container common-btn\">\n            <div id=\"session-management-admin-login\"\n                 class=\"session-management-btn round is-focusable"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"darkUi") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":65},"end":{"line":36,"column":93}}})) != null ? stack1 : "")
    + "\"\n                 data-action=\"admin-login\">\n                <span class=\"icon-settings-1\"></span>\n            </div>\n        </div>\n    </div>\n    <div id=\"session-management-timeline\" class=\"session-management-timeline\"></div>\n    <div id=\"session-management-check-in\" class=\"session-management-check-in\"></div>\n    <div id=\"session-management-ad-hoc\" class=\"session-management-ad-hoc\"></div>\n</div>\n\n<div id=\"session-management-status-bar-container\" class=\"status-bar-container is-vmeeting\"></div>\n";
},"useData":true});
