'use strict';

const app = require('../../../app');
const audioVideoStatusbarTpl = require('./audio-video-statusbar.hbs');
const $ = require('jquery');

/**
 * Frameboxes blueprint
 * Manages all frameboxes
 */
app.component('AudioVideoStatusbar', {
    template: audioVideoStatusbarTpl,

    getAccessKey: function() {
        return {
            'roleName': 'Framebox',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.duration = this.options.frameboxOptions.videoDuration ?? this.options.frameboxOptions.audioDuration;
        this.position = this.options.frameboxOptions.videoPosition ?? this.options.frameboxOptions.audioPosition;
        this.statusbarWidth = 0;
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindDOMEvents();
        this.bindEvents();
    },

    storeSelectors: function() {
        this.$statusbar = this.$el.find('.audio-video-statusbar-container');
        this.$bar = this.$el.find('.audio-video-statusbar-current');
        this.$pin = this.$el.find('.statusbar-pin-container');
        this.$time = this.$el.find('.audio-video-statusbar-time-view');
    },

    bindDOMEvents: function() {
        this.$pin.on('mousedown touchstart', this.onMouseDown.bind(this));
        this.$pin.on('mouseover mouseout', this.onChangeSize.bind(this));

        $(document).on('mouseup touchend', this.onMouseUp.bind(this));
        $(document).on('mousemove touchmove', this.onMouseMove.bind(this));

        this.statusbarObserver = new ResizeObserver((entries) => {
            this.statusbarWidth = entries[0].borderBoxSize[0].inlineSize;
            this.updateSeekPosition();
        });
        this.statusbarObserver.observe(this.$statusbar[0]);
    },

    bindEvents: function() {
        this.on('framebox.change.' + this.options.index, this.onFrameBoxChangeHandler.bind(this));
    },

    onChangeSize: function(event) {
        if (event.type === 'mouseover') {
            this.$pin.addClass('focused');

            return;
        }

        this.$pin.removeClass('focused');
    },

    /**
     * Handle mousedown on statusbar pin.
     */
    onMouseDown: function() {
        this.isActive = true;

        this.$pin.addClass('is-active');
        this.$time.addClass('is-active');
    },

    /**
     * Handle mousemove on statusbar pin.
     *
     * @param event Current event.
     */
    onMouseMove: function(event) {
        if (!this.isActive) {
            return;
        }

        var pos = this.getPosByEvent(event);

        this.updatePositionTime(pos);
        this.updatePositionPin(pos.x);
    },

    /**
     * Handle mouseup on statusbar pin.
     *
     * @param event Current event.
     */
    onMouseUp: function(event) {
        if (!this.isActive) {
            return;
        }

        this.onAudioVideoSeekHandler(event);

        this.$pin.removeClass('is-active');
        this.$time.removeClass('is-active');
        this.isActive = false;
    },

    /**
     * Handle changes in status bar and set audio/video seek.
     *
     * @param event Current event.
     */
    onAudioVideoSeekHandler: function(event) {
        var pos = this.getPosByEvent(event);
        var ms = this.getMsByPosition(pos);

        var cmd = this.options.isAudio ? 'setAudioSeek' : 'setVideoSeek';

        this.deviceConnection
            .send(cmd, {
                appId: this.options.appId,
                time: ms
            })
            .then(function() {
                this.updatePositionBar(pos.x);
                this.updatePositionPin(pos.x);
            }.bind(this));
    },

    /**
     * Get current position by event (mouse or touch event)
     *
     * @param event Current event.
     * @returns {{x: number, y: number}}
     */
    getPosByEvent: function(event) {
        var pageX;
        var pageY;
        var pos;

        switch (event.type) {
            case 'touchmove':
                pageX = event.originalEvent.touches[0].pageX;
                pageY = event.originalEvent.touches[0].pageY;
                break;
            case 'touchend':
                pageX = event.originalEvent.changedTouches[0].pageX;
                pageY = event.originalEvent.changedTouches[0].pageY;
                break;
            default:
                pageX = event.pageX;
                pageY = event.pageY;
                break;
        }

        pos = {
            x: pageX - this.$el.offset().left,
            y: pageY - this.$statusbar.offset().top
        };

        return pos;
    },

    /**
     * Update position of time view.
     *
     * @param pos Current position.
     */
    updatePositionTime: function(pos) {
        var percent = this.getPercentByPos(pos);
        var ms = this.getMsByPosition(pos);
        var time = this.msToTime(ms);

        this.$time
            .text(time)
            .css({
                left: percent + '%',
                marginLeft: -(this.$time.width() / 2)
            });
    },

    /**
     * Get percent of video progress by position.
     *
     * @param pos Current position
     * @returns {number} Percent
     */
    getPercentByPos: function(pos) {
        var percent = (pos.x / this.statusbarWidth) * 100;

        if (100 < percent) {
            return 100;
        }

        if (0 > percent) {
            return 0;
        }

        return percent;
    },

    /**
     * Get milliseconds of video progress by position.
     *
     * @param pos Current position.
     * @returns {*|number}
     */
    getMsByPosition: function(pos) {
        var percent = this.getPercentByPos(pos);
        var ms = this.getMsByPercent(percent);

        return ms;
    },

    /**
     * Get milliseconds of video progress by percent.
     *
     * @param percent
     * @returns {number}
     */
    getMsByPercent: function(percent) {
        if (100 < percent) {
            return 100;
        }

        if (0 > percent) {
            return 0;
        }

        return (this.duration / 100) * percent;
    },

    /**
     * Get milliseconds as date time.
     *
     * @param ms
     * @returns {*}
     */
    msToTime: function(ms) {
        var date = new Date(ms);
        var offsetHours = Math.trunc(date.getTimezoneOffset() / 60);
        var offsetMinutes = date.getTimezoneOffset() % 60;

        if ((date.getMinutes() + offsetMinutes) / 60 >= 1) {
            offsetHours += 1;
        } else if ((date.getMinutes() + offsetMinutes) / 60 < 0) {
            offsetHours -= 1;
        }

        var hour = this.padZero((date.getHours() + offsetHours) % 24);
        var min = this.padZero((date.getMinutes() + offsetMinutes) % 60);
        var sec = this.padZero(date.getSeconds());
        var time;

        if (((date.getHours() + offsetHours) % 24) === 0) {
            time = min + ':' + sec;
        } else {
            time = hour + ':' + min + ':' + sec;
        }

        return time;
    },

    /**
     * Pad a leading zero if necessary.
     *
     * @param number Current number
     * @returns {*}
     */
    padZero: function(number) {
        if (number < 10) {
            number = '0' + number;
        }

        return number;
    },

    /**
     * Handle framebox change.
     *
     * @param frameBox.options.videoDuration Current duration
     *        frameBox.options.videoPosition Current position
     */
    onFrameBoxChangeHandler: function(frameBox) {
        this.duration = frameBox.options.videoDuration ?? frameBox.options.audioDuration;
        this.position = frameBox.options.videoPosition ?? frameBox.options.audioPosition;

        this.updateSeekPosition();
    },

    /**
     * Update seek position and position of statusbar and pin.
     *
     * @param data.videoDuration Current duration
     *        data.videoPosition Current position
     */
    updateSeekPosition: function() {
        const percent = (this.position / this.duration) * 100;
        const length = (this.statusbarWidth / 100) * percent;

        this.updatePositionBar(length);

        if (!this.$pin.hasClass('is-active')) {
            this.updatePositionPin(length);
        }
    },

    /**
     * Update position of statusbar.
     *
     * @param posX New position in x direction / new width of bar
     */
    updatePositionBar: function(posX) {
        if (posX > this.statusbarWidth) {
            posX = this.statusbarWidth;
        }

        if (0 > posX) {
            posX = 0;
        }

        this.$bar
            .css({
                width: posX + 'px'
            });
    },

    /**
     * Update position of statusbar pin.
     *
     * @param posX New position in x direction.
     */
    updatePositionPin: function(posX) {
        var leftMax = this.statusbarWidth - 19; // Remove margin and stroke width.
        var leftMin = -15;
        var left = posX - 15; // NOTE: 15px === half the statusbar-pin-container width.

        if (leftMin > left) {
            left = leftMin;
        }

        if (leftMax < left) {
            left = leftMax;
        }

        this.$pin
            .css({
                'margin-left': left + 'px'
            });
    },

    destroy: function() {
        this.off('framebox.change.' + this.options.index, this.onFrameBoxChangeHandler.bind(this));

        if (this.statusbarObserver) {
            this.statusbarObserver.disconnect();
        }
    }
});
