'use strict';

const app = require('../app');
const LayoutManager = require('../../layout/layout');
const platform = require('../../platform/platform');
const rbac = require('../../rbac/rbac.js');
const $ = require('jquery');
const pictureSources = require('../states').pictureSources;

app.service('LiveStreamService', function(app) {
    var width;
    var height;
    var imgWidth;
    var imgHeight;
    var frame = new Image();

    var offset = {
        top: 0,
        left: 0,
        bottom: 0
    };

    if (!platform.checks.isCbox) {
        offset.top = 40;
    }

    return {
        /**
         * @type {Object}
         */
        Engine: null,

        /**
         * The picture source is main (HDMI1 OUT) by default.
         */
        source: pictureSources.main,

        /**
         * Canvas element
         * @type {Object}
         */
        $canvas: null,

        /**
         * @type {Object}
         */
        context: null,

        /**
         * @type {Number}
         */
        scaling: 1,

        /**
         * @method initialize
         */
        initialize: function() {
            this.authService = app.getService('AuthenticationService');
            this.frontendSettings = app.getService('FrontendSettings');
            this.screensaverService = app.getService('ScreensaverService');
            this.showStatusBar = true;
            this.pictureIsLoading = false;
            this.viewerResolution = '720p';

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.bindEvents();
                    this.storeSelectors();
                    this.deviceConnection = connection;
                }.bind(this));
        },

        bindEvents: function() {
            app.on('frontend-settings.initialized', this.checkSettings.bind(this));
        },

        storeSelectors: function() {
            if (platform.checks.isSafari) {
                this.$livestream = app.$el.find('#livestream-image');
            } else {
                this.$livestream = app.$el.find('#livestream');
            }
        },

        /**
         * Check frontend settings and restart redraw engine if necessary to fit the image.
         */
        checkSettings: function() {
            this.frontendSettings
                .getSettings(['showStatusbar', 'viewerResolution'])
                .then(function(showStatusBar, viewerResolution) {
                    if (this.viewerResolution !== viewerResolution) {
                        this.viewerResolution = viewerResolution;
                        if (this.Engine) {
                            this.stop();
                            this.start();
                        }
                    }

                    this.showStatusBar = showStatusBar;

                    if (!this.showStatusBar && !platform.checks.isCbox) {
                        offset.top = 0;

                        $(document).find('#frameboxes-container').addClass('no-status-bar');

                        // Update menu layer size
                        app.emit('layout-size.update');

                        if (this.Engine) {
                            this.stop();
                            this.start();
                        }
                    }
                }.bind(this));
        },

        /**
         * Set the source where preview images should be queried from.
         */
        setSource: function(source) {
            if (source) {
                this.source = pictureSources[source];
            }

            return this;
        },

        /**
         * @method setEngine
         * @param {Object} Engine
         */
        setEngine: function(Engine) {
            if (Engine) {
                this.Engine = Engine;
            }

            return this;
        },

        /**
         * @method setCanvas
         * @param {Object} canvas
         */
        setCanvas: function(canvas) {
            if (canvas) {
                this.$canvas = app.$(canvas);
                this.context = this.$canvas.get(0).getContext('2d');
            }

            return this;
        },

        /**
         * Start the livestream
         * @method start
         * @chainable
         */
        start: function() {
            if (!this.Engine) {
                throw new Error('No Engine defined!');
            }

            this.Engine.start();
            this.setOffset(offset);
            this.Engine.on('redraw.update', this.loadNextFrame.bind(this));

            return this;
        },

        /**
         * @type {Boolean}
         */
        paused: false,

        /**
         * Pause the stream
         * @method pause
         */
        pause: function() {
            this.paused = true;
            this.pictureIsLoading = false;
        },

        /**
         * Unpause the stream
         * @method pause
         */
        unpause: function() {
            this.paused = false;
        },

        /**
         * Stop the livestream
         * @method stop
         * @chainable
         */
        stop: function() {
            if (this.Engine) {
                this.Engine.off('redraw.update', this.loadNextFrame);
            }

            this.pictureIsLoading = false;

            return this;
        },

        /**
         * Render method of the livestream.
         *
         * @private
         * @chainable
         */
        render: function() {
            if (this.image) {
                this.context.drawImage(this.image, 0, 0, width, height);
                this.pictureIsLoading = false;
            }

            return this;
        },

        /**
         * Prepare image-data for canvas and store it.
         *
         * @param {object} imageData
         *
         * @return {object}
         */
        prepareImage: function(imageData) {
            this.createFrame(imageData.picture, function(image) {
                this.image = image;
                this.render();

                image = null;
            }.bind(this));

            return this;
        },

        /**
         * Clear canvas
         */
        clear: function() {
            if (this.context) {
                this.context.clearRect(0, 0, width, height);
            }
        },

        /**
         * Set the livestream size.
         *
         * @param {Object} size
         *
         * @chainable
         */
        setSize: function(size) {
            width = imgWidth = size.width || width;
            height = imgHeight = size.height || height;

            if (this.authService.getIsViewer()) {
                switch (this.viewerResolution) {
                    case '720p':
                        imgWidth = 1280;
                        imgHeight = 720;
                        break;
                    case '1080p':
                        imgWidth = 1920;
                        imgHeight = 1080;
                        break;
                    default:
                        imgWidth = 1280;
                        imgHeight = 720;
                        break;
                }
            }

            this.$livestream.show();

            app.$el.find('#aspect-ratio-16-9')
                .css({
                    width: width,
                    height: height
                });

            if (!platform.checks.isCbox) {
                app.$el.find('#submenu-container')
                    .css({
                        width: width + 'px',
                        height: height + 'px'
                    });
            }

            this.$livestream
                .attr({
                    width: width,
                    height: height
                });

            this.render();

            return this;
        },

        /**
         * Set the livestream offset.
         *
         * @param {Object} offsetObj
         */
        setOffset: function(offsetObj) {
            if (this.authService.getIsViewer()) {
                offset.top = 0;
                offset.left = offsetObj.left;
                offset.bottom = 0;
            } else if (!platform.checks.isCbox) {
                offset.top = this.showStatusBar ? offsetObj.top : 0;
                offset.left = offsetObj.left;
                offset.bottom = offsetObj.bottom;
            }

            app.$el.find('#aspect-ratio-16-9')
                .css({
                    top: offset.top,
                    left: offset.left,
                    bottom: offset.bottom
                });

            this.updateSize();
        },

        /**
         * Calculate ui scaling for all ui elements.
         *
         * @param size
         * @return {number}
         */
        getScaling: function(size) {
            var scaling = size.width / 1240;
            // Always scaling 1 when windows has a width > 1240px.
            if (1 <= scaling) {
                return 1;
            }

            if (0.5 >= scaling) {
                return 0.5;
            }

            return scaling;
        },

        /**
         * Send update ui scaling event.
         */
        updateScaling: function() {
            app.emit('ui-scaling.update', {
                scaling: this.scaling
            });
        },

        /**
         * Update size based on window width but keep aspect ratio
         * @method updateSize
         */
        updateSize: function() {
            // Window width stored inside of LayoutManager.
            var liveStreamContainerSize = LayoutManager.calculateSizeByAspectRatio(16 / 9, {
                offset: offset
            });

            this.setSize(liveStreamContainerSize);

            this.scaling = this.getScaling(liveStreamContainerSize);
            this.updateScaling();

            app.emit('livestream-size.update', liveStreamContainerSize);
        },

        /**
         * Returns the size of the canvas.
         *
         * @return {object} size
         */
        getSize: function() {
            var size = {
                width: width || LayoutManager.windowWidth,
                height: height || LayoutManager.windowHeight
            };

            return size;
        },

        /**
         * @method getOffset
         */
        getOffset: function() {
            var locOffset = {
                top: 0,
                left: 0,
                bottom: 0
            };

            if (this.$livestream) {
                locOffset.top = this.$livestream.offset().top;
                locOffset.bottom = offset.bottom;
                locOffset.left = this.$livestream.offset().left;
            }

            return locOffset;
        },

        /**
         * Requests the next frame from the server and
         * call the render method
         *
         * @private
         * @chainable
         *
         * @return {Object}
         */
        loadNextFrame: function() {
            var calcHeight;
            var calcWidth;

            if (!rbac.hasAccess('Livestream', 'load')
                || this.screensaverService.isScreenOffActive()
                || (this.authService.getIsCollab() && app.getService('AnnotationService').getState() !== 'started')) {
                return {};
            }

            if (!this.paused && !this.pictureIsLoading) {
                this.pictureIsLoading = true;

                if (imgHeight < 64) {
                    calcHeight = 64;
                } else {
                    calcHeight = imgHeight - imgHeight % 64;
                }

                if (imgWidth < 64) {
                    calcWidth = 64;
                } else {
                    calcWidth = imgWidth - imgWidth % 64;
                }

                this.deviceConnection
                    .send('getPictureCbox', {
                        pictureSource: this.source,
                        pictureWidth: calcWidth,
                        pictureHeight: calcHeight
                    })
                    .then(
                        this.prepareImage.bind(this),
                        function() {
                            this.pictureIsLoading = false;
                        }.bind(this));
            }

            return this;
        },

        /**
         * Render method of the livestream
         * @method createFrame
         * @param data {Mixed}
         * @param callback {Function} called after the image has loaded
         * @private
         * @chainable
         */
        createFrame: function(data, callback) {
            let handler = 'canvas';
            if (platform.checks.isSafari) {
                handler = 'image';
            }
            this.createFrameHandlers[handler].call(this, data, callback);
        },

        createFrameHandlers: {
            canvas: function(data, callback) {
                let binary = '';

                for (let i = 0; i < data.length; i++) {
                    binary += String.fromCharCode(data[i]);
                }
                const imageUrl = `data:image/jpg;base64,${btoa(binary)}`;

                frame.onerror = function onError() {
                    console.log('Error while loading image');
                };

                frame.onload = function onload() {
                    callback(this);
                };

                frame.src = imageUrl;
            },

            image: function(data) {
                const imageUrl = URL.createObjectURL(new Blob([data], {
                    type: 'image/jpeg'
                }));

                const img = this.$livestream.get(0);

                img.onload = function() {
                    URL.revokeObjectURL(imageUrl);
                };

                img.src = imageUrl;

                if (platform.checks.isSafari) {
                    this.pictureIsLoading = false;
                }
            }
        }
    };
});
