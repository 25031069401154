// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return " data-nav-area=\".btn-form:not([disabled])\" data-nav-trapped";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-action-container\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"navArea") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":34},"end":{"line":1,"column":115}}})) != null ? stack1 : "")
    + ">\n    <input id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cancelId") || (depth0 != null ? lookupProperty(depth0,"cancelId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cancelId","hash":{},"data":data,"loc":{"start":{"line":2,"column":15},"end":{"line":2,"column":27}}}) : helper)))
    + "\" type=\"button\" class=\"btn-form form-action-cancel focusable\" value=\""
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"cancelTitleKey") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":96},"end":{"line":2,"column":117}}}))
    + "\" />\n    <input id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"submitId") || (depth0 != null ? lookupProperty(depth0,"submitId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"submitId","hash":{},"data":data,"loc":{"start":{"line":3,"column":15},"end":{"line":3,"column":27}}}) : helper)))
    + "\" type=\"button\" class=\"btn-form form-action-submit focusable\" value=\""
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"submitTitleKey") : depth0),{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":96},"end":{"line":3,"column":117}}}))
    + "\" />\n</div>\n";
},"useData":true});
