'use strict';

var _ = require('lodash');
var app = require('../../../app');
var headerTpl = require('./overlay-header.hbs');
var platform = require('../../../../platform/platform');

/**
 * Overlay states
 * @type {Object}
 */
var loaderStates = {
    show: 'show',
    hide: 'hide'
};

/**
 * The OverlayHeader component manages the overlay title
 * the 'back button‘ and visibility of the 'action button'
 */
app.component('OverlayHeader', {
    /**
     * Handlebars template
     * @type {Function}
     */
    template: headerTpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    /**
     * @param {Object} options
     */
    initialize: function(options) {
        this.options = options;
        this.data = {
            actionButtonKey: options.actionButtonKey,
            titleKey: options.titleKey,
            back: options.back,
            closeButton: options.closeButton,
            disconnectButton: options.disconnectButton,
            saveButton: options.saveButton,
            overlayTitle: options.overlayTitle,
            smallScreen: (platform.checks.isMediumSize() || platform.checks.isSmallSize()),
            hideHeader: options.hideHeader,
            showActionSwitch: options.showActionSwitch,
            actionSwitchTitle: 'modal.' + this.options.titleKey,
            actionSwitchChecked: this.options.actionSwitchChecked || false
        };

        this.loaderState = this.createStateMachine({
            state: loaderStates.hide,
            states: loaderStates
        });

        this.storeSelectors();
        this.addStateTransitions();
        this.bindEvents();
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.bindDOMEvents();

        if (this.options.showActionSwitch) {
            this.$el.find('#actionSwitch').prop('checked', this.options.actionSwitchChecked);
        }
    },

    /**
     * @method serialize
     * @return {Object}
     */
    serialize: function() {
        return {
            data: this.data
        };
    },

    /**
     * Stores all selectors
     */
    storeSelectors: function() {
        this.$overlayLoader = this.$el.find('#overlay-loader');
        this.$actionSwitch = this.$el.find('#actionSwitch');
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        this.on('overlay.header.update', this.updateHandler.bind(this));
        this.on('component.loader.change', this.changeLoaderState.bind(this));
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.$el.on('click', '#overlay-history-back', this.backHandler.bind(this));
        this.$el.on('click', '#overlay-action', this.actionBtnHandler.bind(this));
        this.$el.on('click', '#overlay-disconnect-btn', this.disconnect.bind(this));

        if (this.options.closeButton) {
            this.$el.on('click', '#overlay-close-btn', this.closeOverlay.bind(this));
        }

        if (this.options.showActionSwitch) {
            this.$el.on('change', '#actionSwitch', this.actionSwitchHandler.bind(this));
        }
    },

    actionSwitchHandler: function(data) {
        this.emit('overlay.header.action-switch.' + this.options.titleKey + '.change', data.currentTarget.checked);
    },

    /**
     * Changing the state of the loader by trigger event "component.loader.change".
     *
     * @param {object} options
     */
    changeLoaderState: function(options) {
        var state = loaderStates[options.state];

        if (state !== this.loaderState.getState()) {
            this.loaderState.changeState(state);
        }
    },

    closeOverlay: function() {
        this.emit('overlay.close');
    },

    /**
     * @method disconnect
     */
    disconnect: function() {
        this.emit('overlay.close', {
            onClose: function() {
                this.emit('overlay.disconnect');
            }.bind(this)
        });
    },

    /**
     * @method backHandler
     */
    backHandler: function() {
        this.emit('overlay.history.back');
    },

    /**
     * @method actionBtnHandler
     */
    actionBtnHandler: function() {
        this.emit('overlay.action', {
            type: this.data.actionBtnType
        });
    },

    /**
     * Fades the overlay action button out
     *
     * @method actionBtnFadeout
     * @param delay: delay in milliseconds until the fadeout should be started
     * @param fadeOut: duration of the fadeOut
     */
    actionBtnFadeout: function(delay, fadeOut) {
        this.$el.find('#overlay-action').velocity('fadeOut', {
            delay: delay,
            duration: fadeOut
        });
    },

    /**
     * Changes focusable state of overlay-action-button
     *
     * @method actionBtnSetFocusable
     * @param enable: true => focusable, false => not
     */
    actionBtnSetFocusable: function(enable) {
        if (enable) {
            this.$el.find('#overlay-action-button').addClass('focusable');
        } else {
            this.$el.find('#overlay-action-button').removeClass('focusable');
        }
    },

    /**
     * Updates the header view based on the given options.
     *
     * @param  {Object} Options contains new data and view options.
     */
    updateHandler: function(data) {
        this.data = _.extend(this.data, data);
        this.render();

        this.actionBtnSetFocusable(data.actionBtnType !== null);

        if ((!!data.actionBtnDelay) || (!!data.actionBtnFadeout)) {
            this.actionBtnFadeout(data.actionBtnDelay, data.actionBtnFadeout);
        }
    },

    addStateTransitions: function() {
        this.loaderState.addTransitions({
            'hide > show': function() {
                this.storeSelectors();
                this.$overlayLoader.css({
                    'display': 'inline-block'
                });
            },
            'show > hide': function() {
                this.$overlayLoader.hide();
            }
        });
    }
});
