'use strict';

var _ = require('lodash');
var app = require('../app');
var StateMachine = require('./../state-machine');
var platform = require('./../../platform/platform');

/**
 * Upload states
 */
var uploadState = {
    idle: 'idle',
    active: 'active',
    background: 'background'
};

/**
 * @type {object}
 */
var uploadMapping = {
    0: 'idle',
    1: 'active',
    2: 'background'
};

var filesStates = {
    pending: 'pending',
    running: 'running',
    done: 'done',
    failed: 'failed',
    abort: 'aborted'
};

/**
 * Upload file.
 *
 * Following states are given for Uploads:
 * - pending - Wait to upload. (only a X to abort download is displayed)
 * - running - File is currently on uploading. (process-bar is displayed)
 * - done    - Upload is ready.
 * - failed  - Upload has failed, (e.g. connection lost,…)
 * - abort   - Upload has been canceled by an user-action.
 */
app.service('UploadService', function(app) {
    return {
        uploadList: {},

        /**
         * @method initialize
         */
        initialize: function() {
            this.upload = new StateMachine({
                context: this,
                state: uploadState.none,
                states: uploadState
            });

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    this.deviceConnection = connection;
                    this.bindEvents();
                }.bind(this));

            this.restartIndex = -1;
            this.authService = app.getService('AuthenticationService');
            this.URIService = app.getService('URIService');
            this.addStateTransitions();
            this.uploadPercentage = 0;
        },

        /**
         * @method bindEvents
         */
        bindEvents: function() {
            app.on('main-loop.update', this.updateHandler.bind(this));
            app.on('main-loop.update.upload', this.updateHandler.bind(this));
            app.on('reinit.updownloads', this.clearUploadList.bind(this));
        },

        /**
         * @method updateHandler
         */
        updateHandler: function() {
            this.deviceConnection
                .send([
                    'getFileUploadStatus',
                    'getFileUploadPercentage',
                    {
                        command: 'getFileUploadList',
                        data: {
                            allUploads: this.authService.getIsAdmin()
                        }
                    }
                ])
                .then(function(status, uploadPercentage, uploadList) {
                    var state = uploadMapping[status.isUploading];

                    if (state === 'background' && !this.authService.getIsAdmin()) {
                        return;
                    }

                    if (!!state && this.upload.getState() !== uploadState[state]) {
                        if (state !== uploadState.idle) {
                            app.emit('upload-state.update', {
                                upload: true
                            });
                        } else {
                            app.emit('upload-state.update', {
                                upload: false
                            });
                        }
                        this.upload.changeState(uploadState[state]);
                    }
                    this.uploadPercentage = uploadPercentage.percentage;

                    // Set all uptodate to false.
                    this.uploadList = _.mapValues(this.uploadList, function(item) {
                        return _.extend(item, {
                            uptodate: false
                        });
                    });

                    // Update/add items.
                    _.forEach(uploadList.fileUploadList, function(item) {
                        var listItem = this.uploadList[item.index];
                        item.uptodate = false;
                        item.name = this.getFileNameByPath(item.file);

                        // Add file if status is not abort.
                        if (!listItem && item.status !== filesStates.abort) {
                            this.uploadList[item.index] = item;
                            app.emit('upload-list.add', item);
                            // Remove item on abort and file not in cache.
                        } else if (!listItem && item.status === filesStates.abort) {
                            app.emit('upload-list.remove', item);
                            // Update file.
                        } else if (this.uploadList[item.index].status !== item.status
                            || this.uploadList[item.index].progress !== item.progress
                        ) {
                            if (this.uploadList[item.index].status === filesStates.done) {
                                app.emit('upload-list.remove', item);

                                this.uploadList[item.index] = item;
                                app.emit('upload-list.add', item);
                            } else if (item.status === filesStates.abort) {
                                app.emit('upload-list.remove', item);
                                delete this.uploadList[item.index];
                            } else if (item.status === filesStates.running) {
                                app.emit('upload-list.remove', item);

                                this.uploadList[item.index] = item;
                                app.emit('upload-list.add', item);
                            } else {
                                this.uploadList[item.index] = item;
                                app.emit('upload-list.update', item);
                            }
                        }

                        if (this.uploadList[item.index]) {
                            this.uploadList[item.index].uptodate = true;
                        }
                    }.bind(this));

                    // Remove items.
                    _.forEach(this.uploadList, function(item, key) {
                        if (!item.uptodate) {
                            app.emit('upload-list.remove', item);
                            delete this.uploadList[key];
                        }
                    }.bind(this));
                }.bind(this));
        },

        /**
         * Count all failed uploads in upload-list.
         *
         * @returns {number}
         */
        countUploadFailed: function() {
            var count = 0;

            _.forEach(this.uploadList, function(item) {
                if (item.status === filesStates.failed) {
                    count++;
                }
            });

            return count;
        },

        /**
         * Count all files in uploads-list with state "pending" or "running".
         *
         * @return {int}
         */
        countUploadList: function() {
            var count = 0;

            _.forEach(this.uploadList, function(item) {
                if (item.status === filesStates.pending || item.status === filesStates.running) {
                    count++;
                }
            });

            return count;
        },

        /**
         * Count all uploaded files with state "done".
         *
         * @return {int}
         */
        countUploadedList: function() {
            var count = 0;

            _.forEach(this.uploadList, function(item) {
                if (item.status === filesStates.done) {
                    count++;
                }
            });

            return count;
        },

        /**
         * @return {Array}
         */
        getUploadList: function() {
            return this.uploadList;
        },

        /**
         * Remove all files from uploads-list.
         */
        clearUploadList: function() {
            _.forEach(this.uploadList, function(item, key) {
                delete this.uploadList[key];
            }.bind(this));

            app.emit('main-loop.fast.start', {
                id: 'upload'
            });
        },

        /**
         * Checks if a upload has been failed.
         *
         * @returns {boolean}
         */
        checkIfAllUploadReady: function() {
            var allReady = true;

            _.forEach(this.getUploadList(), function(item) {
                if (item.status === 'failed') {
                    allReady = false;
                }
            });

            return allReady;
        },

        /**
         * Abort an upload.
         *
         * @param {string} index
         * @param {string} target
         */
        abortUpload: function(index, target) {
            this.deviceConnection
                .send('setFileUpload', {
                    mode: 'abortIndex',
                    index: index,
                    target: this.URIService.decode(target)
                });

            app.emit('main-loop.fast.start', {
                id: 'upload'
            });
        },

        /**
         * Restart an upload.
         *
         * @param {string} index
         * @param {string} target
         */
        restartUpload: function(index, target) {
            this.deviceConnection
                .send('setFileUpload', {
                    mode: 'restartIndex',
                    index: index,
                    target: this.URIService.decode(target)
                });

            app.emit('main-loop.fast.start', {
                id: 'upload'
            });

            this.restartIndex = index;
        },

        /**
         * Gets the filename from the filpath and returns them as string.
         *
         * @param {string} filepath
         *
         * @returns {string} filename
         */
        getFileNameByPath: function(filepath) {
            var filename = filepath.replace(/^.*[\\\/]/, '');

            return filename;
        },

        /**
         * @method getUpload
         */
        getUpload: function() {
            return this.upload.getState() === uploadState.active
                || (this.upload.getState() === uploadState.background
                && this.authService.getIsAdmin());
        },

        /**
         * @method getUploadPercentage
         */
        getUploadPercentage: function() {
            return this.uploadPercentage;
        },

        /**
         * Removes a file from uploads list. (e.g. in toolbox)
         *
         * @param {Object} file
         */
        removeFromUploadList: function(file) {
            this.deviceConnection
                .send('setFileUpload', {
                    mode: 'abortIndex',
                    index: file.index,
                    target: this.URIService.decode(file.target)
                });

            app.emit('main-loop.fast.start', {
                id: 'upload'
            });
        },

        /**
         * @method addStateTransitions
         */
        addStateTransitions: function() {
            this.upload.addTransitions({
                '> idle': function() {
                    app.emit('status-widget.item.remove', {
                        id: 'upload'
                    });

                    app.emit('upload.state.changed', {
                        state: uploadState.idle
                    });
                },

                '> active': function() {
                    if (!platform.checks.isCboxAux) {
                        app.emit('status-widget.item.append', {
                            id: 'upload',
                            accessKey: 'UpDownloads',
                            options: {
                                icon: 'icon-v2-upload',
                                clickable: false,
                                state: 'upload'
                            }
                        });
                    }

                    app.emit('upload.state.changed', {
                        state: uploadState.active
                    });
                },

                '> background': function() {
                    if (this.authService.getIsAdmin()) {
                        app.emit('status-widget.item.append', {
                            id: 'upload',
                            accessKey: 'UpDownloads',
                            options: {
                                icon: 'icon-v2-upload',
                                clickable: false,
                                state: 'upload'
                            }
                        });

                        app.emit('upload.state.changed', {
                            state: uploadState.active
                        });
                    }
                }
            });
        }
    };
});
