'use strict';

var BaseView = require('./view');
var tpl = require('./small-view.hbs');

function SmallView(app, configs) {
    BaseView.call(this, app, configs);

    this.template = tpl;
    this.className = configs.className || '';
    this.idName = configs.idName || '';
    this.componentContainer = '#modal-small-view';
}

SmallView.prototype = new BaseView();

module.exports = SmallView;
