'use strict';

const _ = require('lodash');
const $ = require('jquery');
const aboutTpl = require('./about.hbs');
const aboutFaktValueItemTpl = require('./about-fakt-value-item.hbs');
const app = require('../../../app');
const i18n = require('i18next');
const platform = require('./../../../../platform/platform');

const updateProgressStates = require('../../../states').updateProgressStates;

const activateStates = {
    none: 'none',
    found: 'found',
    failed: 'failed',
    checking: 'checking'
};

const copyLogStates = {
    uninit: 'uninit',
    busy: 'busy',
    ready: 'ready',
    failed: 'failed'
};

app.component('About', {
    template: aboutTpl,

    initialize: function() {
        this.remote = this.getService('RemoteService');
        this.updateService = this.getService('UpdateService');
        this.configs = this.getService('ConfigsService');
        this.sourcesService = this.getService('SourcesService');
        this.mountService = this.getService('MountService');
        this.deviceService = this.getService('DeviceService');

        this.pollFactory = this.getService('PollFactoryService');

        this.activateSupportPolling = null;
        this.activateSupportState = false;

        this.copyLogPolling = null;
        this.copyLogState = this.createStateMachine({
            state: copyLogStates.failed,
            states: copyLogStates
        });

        this.activateFeaturePolling = null;
        this.activateFeatureState = false;

        this.updateProgress = this.createStateMachine({
            state: updateProgressStates.failed,
            states: updateProgressStates
        });
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();
        this.bindDOMEvents();

        $.when(this.loadData()).done(function() {
            this.destroyHandler = this.updateService.addUpdaterHandler(this.onUpdateHandler.bind(this));

            // Skip firmware update check when found already in overlay-header-update-notification.js
            if (this.options.configs.fwUpdateChecked) {
                this.showFirmwareUpdateAvailable({
                    version: this.updateService.getNewFirmwareVersion()
                });
            } else {
                this.updateService.startUpdateCheck();
            }

            this.emit('main-loop.fast.start', {
                id: 'update'
            });

            this.$copyLogLoader.hide();
        }.bind(this));
    },

    serialize: function() {
        return {
            version: window.CYNAP_BUILD_VERSION,
            'cynapName': app.getService('Model-View').name
        };
    },

    bindEvents: function() {
        app.once('update.available', this.showFirmwareUpdateAvailable.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.about-do-update', this.showUpdateQuestion.bind(this));
        this.$el.on('click', '.about-abort-update', this.abortUpdate.bind(this));
        this.$el.on('click', '.about-do-update-really', this.doUpdate.bind(this));
        this.$el.on('click', '.about-show-eula', this.onShowEula.bind(this));
        this.$el.on('click', '.about-show-software-packages', this.onShowSwPkgs.bind(this));
        this.$el.on('click', '.about-show-software-compliance', this.onComplianceClick.bind(this));
        this.$el.on('click', '.about-show-privacy-policy', this.onPrivacyPolicyClick.bind(this));
        this.$el.on('click', '.about-activate-feature', this.onActivateFeature.bind(this));
        this.$el.on('click', '.about-activate-support', this.onActivateSupport.bind(this));
        this.$el.on('click', '.about-copy-log', this.onCopyLog.bind(this));
    },

    storeSelectors: function() {
        this.$updateEl = this.$el.find('.about-new-version');
        this.$update = this.$el.find('#cbox-update-container');
        this.$updateState = this.$update.find('#cbox-update-state');
        this.$downloadLoader = this.$update.find('.download-progress');
        this.$updatePercent = this.$update.find('.download-progress-percent');
        this.$updateLoader = this.$update.find('.download-progress-current');
        this.$abortBtn = this.$update.find('.about-abort-update');
        this.$question = this.$el.find('#cbox-update-question');
        this.$supportEl = this.$el.find('.about-new-support');
        this.$featureEl = this.$el.find('.about-new-feature');
        this.$copyLogLoader = this.$el.find('.about-copy-log-loader');
        this.$copyLogBtn = this.$el.find('.about-copy-log');
    },

    /**
     * Show Eula dialog.
     */
    onShowEula: function() {
        this.emit('modal.open', {
            id: 'eula-info'
        });
    },

    /**
     * Activate support handler.
     */
    onActivateSupport: function() {
        this.activateSupportPolling = this.activateSupportPolling || this.pollFactory.create({
            pollHandler: this.fetchSupportYear.bind(this)
        });

        this.activateSupportPolling.addCheck(function(stop) {
            if (this.activateSupportState) {
                this.activateSupportState = false;
                stop();
            }
        }.bind(this));

        if (!this.activateSupportPolling.isPolling) {
            this.activateSupportPolling.start();
        }

        this.deviceConnection
            .send('setWwwSupportYearUpdate', { load: true });
    },

    /**
     * Copy log to usb/download local
     */
    onCopyLog: function() {
        this.copyLogPolling = this.pollFactory.create({
            pollHandler: this.fetchCopyLog.bind(this)
        });

        this.copyLogPolling.addCheck(function(stop) {
            if (this.copyLogState.getState() === copyLogStates.ready) {
                this.$copyLogBtn.hide();
                this.$copyLogLoader.hide();

                if (!platform.checks.isCbox) {
                    this.download();
                }
                stop();
            }

            if (platform.checks.isCbox) {
                this.$el.find('#copy-log').text(i18n.t('about.copy_to_usb') + ' - ' + this.copyLogState.getState().toUpperCase());
            } else if (platform.checks.isFirefox) {
                this.$el.find('#copy-log').text(i18n.t('about.generate_logfile') + ' - ' + this.copyLogState.getState().toUpperCase());
            } else {
                this.$el.find('#copy-log').text(i18n.t('about.download_log') + ' - ' + this.copyLogState.getState().toUpperCase());
            }
        }.bind(this));

        if (!this.copyLogPolling.isPolling) {
            this.$copyLogBtn.hide();
            this.$copyLogLoader.show();
            this.copyLogPolling.start();
        }

        this.deviceConnection
            .send('setGenerateLogfile', { write: platform.checks.isCbox ? 'usb' : 'local' });
    },

    /**
     * Fetch log file generation status.
     *
     * @param done
     */
    fetchCopyLog: function(done) {
        this.deviceConnection
            .send([
                'getGenerateLogfileStatus'
            ])
            .then(function(status) {
                if (!_.isEqual(this.copyLogState.getState(), status.status)) {
                    this.copyLogState.changeState(status.status);
                    done();
                }
            }.bind(this));
    },

    /**
     * Download logfile.
     */
    download: function() {
        const download = this.deviceService.getBoxName() + '.log';
        const href = window.location.protocol + '//' + window.location.host + '/logfile/' + download;

        const doNotAddDownloadAttribute = window.location.protocol === 'https:'
            && (platform.checks.isChrome || platform.checks.isEdge);

        if (!platform.checks.isFirefox) {
            var element = document.createElement('a');
            element.setAttribute('href', href);

            if (!doNotAddDownloadAttribute) {
                element.setAttribute('download', download);
            }

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        } else {
            var link = $('#download-link');

            link.attr('download', download);
            link.attr('href', href);
            link.show();
        }
    },

    /**
     * Fetch support year.
     * @param done
     */
    fetchSupportYear: function(done) {
        this.deviceConnection
            .send([
                'getSupportYear'
            ])
            .then(function(support) {
                if (this.supportYear !== support.supportYear) {
                    this.activateSupportState = true;
                    this.supportYear = support.supportYear;

                    this.$supportEl.hide();
                    this.$el.find('#support-year').text(this.supportYear);
                    done();
                }
            }.bind(this));
    },

    /**
     * Activate feature handler.
     */
    onActivateFeature: function() {
        this.activateFeaturePolling = this.activateFeaturePolling || this.pollFactory.create({
            pollHandler: this.fetchLicenseFeatures.bind(this)
        });

        this.activateFeaturePolling.addCheck(function(stop) {
            if (this.activateFeatureState) {
                this.activateFeatureState = false;
                stop();
            }
        }.bind(this));

        if (!this.activateFeaturePolling.isPolling) {
            this.activateFeaturePolling.start();
        }

        this.deviceConnection
            .send('setWwwFeaturePackUpdate', {  load: true });
    },

    /**
     * Fetch license features.
     *
     * @param done
     */
    fetchLicenseFeatures: function(done) {
        this.deviceConnection
            .send([
                {
                    command: 'getLicenseFeatures',
                    data: {
                        excludeDefault: true
                    }
                }
            ])
            .then(function(features) {
                if (!_.isEqual(this.activeFeatures, features)) {
                    this.activateFeatureState = true;
                    this.activeFeatures = features;

                    this.$featureEl.hide();
                    this.renderLicenseFeatures(this.activeFeatures);
                    this.emit('navigation-bar.update.features');
                    done();
                }
            }.bind(this));
    },

    onShowSwPkgs: function() {
        this.emit('modal.open', {
            id: 'software-packages'
        });
    },

    onComplianceClick: function() {
        this.sourcesService.open({
            type: 'pdf',
            url: this.configs.get('cynap.complianceUrl')
        }, { output: 'edit' });
        this.emit('overlay.close');
        this.emit('backdrop.hide');
    },

    onPrivacyPolicyClick: function() {
        this.sourcesService.open({
            type: 'pdf',
            url: this.configs.get('cynap.privacyPolicyUrl')
        }, { output: 'edit' });
        this.emit('overlay.close');
        this.emit('backdrop.hide');
    },

    loadData: function() {
        return this.deviceConnection
            .send([
                'getSupportYear',
                'getFirmwareVersion',
                'getSerialNumber',
                {
                    command: 'getLicenseFeatures',
                    data: {
                        excludeDefault: true
                    }
                }
            ])
            .then(this.onDataLoaded.bind(this));
    },

    onDataLoaded: function(support, firmware, serialNumber, licenseFeatures) {
        this.supportYear = support.supportYear;
        this.activeFeatures = licenseFeatures;

        this.$el.find('#support-year').text(this.supportYear);
        this.$el.find('#cbox-version').text(firmware.version.cynap.version);
        this.$el.find('#cbox-build').text(firmware.version.cynap.buildnumber);
        this.$el.find('#cbox-serial-number').text(serialNumber.serialNumber);

        if (platform.checks.isCbox && this.mountService.isUSBmounted()) {
            this.$el.find('.about-copy-logfile').removeClass('hidden');
        } else if (platform.checks.isIOS) {
            this.$el.find('.about-copy-logfile').addClass('hidden');
        } else if (!platform.checks.isCbox && platform.checks.isFirefox) {
            this.$el.find('#copy-log').text(i18n.t('about.generate_logfile'));
            this.$el.find('.about-copy-log').val(i18n.t('about.generate'));
            this.$el.find('.about-copy-logfile').removeClass('hidden');
        } else if (!platform.checks.isCbox) {
            this.$el.find('#copy-log').text(i18n.t('about.download_log'));
            this.$el.find('.about-copy-log').val(i18n.t('about.download'));
            this.$el.find('.about-copy-logfile').removeClass('hidden');
        }

        this.renderLicenseFeatures(this.activeFeatures);
        this.onCheckOnlineUpdates();

        // Timeout will avoid blinking on remote focus.
        setTimeout(this.checkFocus.bind(this), 300);
    },

    renderLicenseFeatures: function(licenseFeatures) {
        var $container = this.$el.find('#cbox-license-features-container');
        var $element = this.$el.find('#cbox-license-features');
        var items = [];
        var $item;

        _.each(licenseFeatures, function(feature, key) {
            if (true === feature) {
                var content = i18n.t('about.cynap_license_feature_' + key);

                $item = this.$(aboutFaktValueItemTpl({
                    content: content
                }));

                items.push($item);
            }
        }.bind(this));

        $element.html(items);

        if (items.length > 0) {
            $container.removeClass('hidden');
        } else {
            $container.addClass('hidden');
        }
    },

    checkFeatureOnlineUpdate: function(featureState) {
        if (featureState.state === activateStates.found) {
            if (this.$featureEl.css('display') === 'none') {
                this.$featureEl
                    .stop()
                    .slideDown({
                        complete: function() {
                            this.updateFocus(this.$featureEl);
                        }.bind(this)
                    });
            }
        } else if (featureState.state === activateStates.checking) {
            this.onCheckOnlineUpdates();
        }
    },

    checkSupportOnlineUpdate: function(supportState) {
        if (supportState.state === activateStates.found) {
            if (this.$supportEl.css('display') === 'none') {
                this.$supportEl
                    .stop()
                    .slideDown({
                        complete: function() {
                            this.updateFocus(this.$supportEl);
                        }.bind(this)
                    });
            }
        } else if (supportState.state === activateStates.checking) {
            this.onCheckOnlineUpdates();
        }
    },

    /**
     * Updates the remote focus only if there is no navArea set.
     *
     * @param $el
     */
    updateFocus: function($el) {
        if (!platform.checks.isCbox) {
            return;
        }

        if (this.options.configs.navArea) {
            this.remote.focus(this.$el.find(this.options.configs.navArea));

            return;
        }

        this.remote.focusArea(this.$el, {
            area: '.focusable'
        });
        this.remote.focus($el.find('.focusable').get(0));
    },

    showFirmwareUpdateAvailable: function(data) {
        if (this.$updateEl.css('display') === 'none') {
            this.$updateEl
                .stop()
                .slideDown({
                    complete: function() {
                        this.updateFocus(this.$updateEl);
                    }.bind(this)
                })
                .find('#cbox-update-version').text(data.version);
        }
    },

    onCheckOnlineUpdates: function() {
        this.deviceConnection
            .send([
                'getWwwFeatureUpdateStatus',
                'getWwwSupportUpdateStatus'
            ])
            .then(function(feature, support) {
                this.checkFeatureOnlineUpdate(feature);
                this.checkSupportOnlineUpdate(support);
            }.bind(this));
    },

    showUpdateQuestion: function() {
        this.$updateEl.hide();

        this.$question
            .stop()
            .slideDown({
                complete: function() {
                    this.remote.update();
                    this.remote.focus(this.$question.find('.focusable').get(0));
                }.bind(this)
            });
    },

    doUpdate: function() {
        this.$question.hide();
        this.updateService.startUpdate();

        this.emit('main-loop.fast.start', {
            id: 'update'
        });
    },

    abortUpdate: function() {
        this.updateService.abortUpdate();

        this.emit('main-loop.fast.start', {
            id: 'update'
        });
    },

    onUpdateHandler: function(data) {
        const state = updateProgressStates[data.status];

        switch (state) {
            case updateProgressStates.downloading:
                this.onDownloadUpdate(data.progress);
                break;
            case updateProgressStates.updating:
                this.onUpdateUpdate(data.progress);
                break;
            case updateProgressStates.failed:
                this.onFailedUpdate();
                break;
        }

        this.updateProgress.changeState(state);
    },

    onDownloadUpdate: function(progress) {
        this.$downloadLoader.show();
        this.$updateState.text(i18n.t('about.state_downloading'));
        this.$updatePercent.text(progress + '%');
        this.$updateLoader.css({
            width: progress + '%'
        });

        if (this.updateProgress.getState() !== updateProgressStates.downloading) {
            this.$question.hide();

            this.$update
                .stop()
                .slideDown({
                    complete: function() {
                        this.remote.update();
                        this.remote.focus(this.$update.find('.focusable').get(0));
                    }.bind(this)
                });
        }
    },

    onUpdateUpdate: function(progress) {
        this.$abortBtn.hide();
        this.$downloadLoader.hide();

        this.$updateState.text(i18n.t('about.state_updating'));
        this.$updatePercent.text(progress + '%');
        this.$updateLoader.css({
            width: progress + '%'
        });

        if (this.updateProgress.getState() !== updateProgressStates.updating) {
            this.$update
                .stop()
                .slideDown({
                    complete: function() {
                        this.remote.update();
                        this.remote.focus(this.$update.find('.focusable').get(0));
                    }.bind(this)
                });
        }
    },

    onFailedUpdate: function() {
        if (this.updateProgress.getState() !== updateProgressStates.failed) {
            this.$update.hide();
        }
    },

    /**
     * Checks the focus after all data has been loaded.
     * If focus is still on the navigation-bar we will focus the second area on this component.
     * RELEASE-2179 - Focus should jump to the "Show license terms" button when clicking on About.
     * If a navArea focus is set then focus this element.
     */
    checkFocus: function() {
        var $focus = $(this.remote.getFocus());
        var $remoteArea = this.$el.find('.about-info-buttons-container');

        if ($focus.is('#nav-bar-about')
            && (!this.remote.getLastFocusedRegion() || this.remote.getLastFocusedRegion().id !== 'nav-item-container')) { // RELEASE-3033
            this.remote.focus($remoteArea);
        }

        if (this.options.configs.navArea) {
            this.remote.focus(this.$el.find(this.options.configs.navArea));
        }
    },

    destroy: function() {
        if (this.activateSupportPolling) {
            this.activateSupportPolling.stopPolling();
            this.activateSupportPolling = null;
        }

        if (this.activateFeaturePolling) {
            this.activateFeaturePolling.stopPolling();
            this.activateFeaturePolling = null;
        }

        if (this.copyLogPolling) {
            this.copyLogPolling.stopPolling();
            this.copyLogPolling = null;
        }

        if (this.destroyHandler) {
            this.destroyHandler();
        }
    },

    hasChanges: function() {
        return {
            hasChanges: false,
            invalid: false
        };
    }
});
