// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div id=\"framebox-show-keyboard\" class=\"framebox-show-keyboard framebox-icon-button\">\n        <span class=\"icon-v2-keyboard\"></span>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Framebox controls container -->\n<ul id=\"framebox-standard-header\" class=\"framebox-standard-header\"></ul>\n\n<div class=\"framebox-state-effect has-action\">\n    <span class=\"framebox-state-effect-icon has-action\"></span>\n</div>\n\n<div class=\"framebox-focus-state-effect\">\n    <div class=\"framebox-state-effect-background\"></div>\n    <span class=\"icon-v2-focuscopy icon\"></span>\n</div>\n\n<div class=\"framebox-loader has-action\" id=\"framebox-loader-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"index") : stack1), depth0))
    + "\">\n    <div class=\"loader has-action\"></div>\n</div>\n\n<div class=\"framebox-backdrop has-action\" id=\"framebox-backdrop-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"index") : stack1), depth0))
    + "\"></div>\n<div class=\"framebox-transparent has-action\" id=\"framebox-transparent-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"index") : stack1), depth0))
    + "\"></div>\n<div class=\"framebox-wipe-out has-action\" id=\"framebox-wipe-out-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"index") : stack1), depth0))
    + "\">\n    <span class=\"wipe-out-text\"></span>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasShowKeyboard") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":0},"end":{"line":27,"column":7}}})) != null ? stack1 : "")
    + "\n<div id=\"framebox-show-hdmi-out-2\" class=\"framebox-show-hdmi-out-2 hidden framebox-icon-button shift-up\">\n    <span class=\"icon-v2-moderator-mode-on\"></span>\n</div>\n\n<div class=\"browser-smartphone-input-helper has-action is-hidden\" id=\"browser-smartphone-input-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"index") : stack1), depth0))
    + "\">\n    <input type=\"password\" name=\"input-helper\" autocomplete=\"off\" class=\"input has-action\" />\n    <span class=\"framebox-capslock has-action\"></span>\n</div>\n\n";
},"useData":true});
