// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-xs-12 close-configurator-question\">\n        <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.unsaved_changes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":44}}}))
    + "</h2>\n        <div class=\"additional-message saving-info hidden\">\n            "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"matrix_configurator.saving",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":48}}}))
    + "<span>.</span><span>.</span><span>.</span>\n        </div>\n    </div>\n</div>\n<div class=\"close-configurator-buttons\">\n    <div class=\"close-configurator-button-container discare\">\n        <input data-action=\"discare\" type=\"button\" class=\"btn-form focusable\" value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"matrix_configurator.close_discare",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":85},"end":{"line":11,"column":127}}}))
    + "\" />\n    </div>\n    <div class=\"close-configurator-button-container save\">\n        <input data-action=\"save\" type=\"button\" class=\"btn-form focusable\" value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"matrix_configurator.close_save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":82},"end":{"line":14,"column":121}}}))
    + "\" />\n    </div>\n</div>\n";
},"useData":true});
