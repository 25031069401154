'use strict';

var app = require('../../../app');
var interactionMenu = require('./interaction.hbs');
var platform = require('../../../../platform/platform');
var $ = require('jquery');
var _ = require('lodash');

app.component('MainInteraction', {
    template: interactionMenu,

    getAccessKey: function() {
        return {
            'roleName': 'MatrixMain',
            'roleKey': 'show'
        };
    },

    initialize: function(options) {
        this.matrixMainService = this.getService('MatrixMainService');
        this.matrixService = this.getService('MatrixService');
        this.configs = this.getService('MatrixConfigs');
        this.options = options;
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindDOMEvents();

        this.setPosition();
        this.addScrollbar();
    },

    serialize: function() {
        var groups = this.configs.getCurrentMatrixTemplateGroups(true);

        if (groups.length > 0) {
            groups[groups.length - 1].classed += ' no-border';
        }

        return {
            isMaster: this.options.id.startsWith('master'),
            groups: groups
        };
    },

    storeSelectors: function() {
        this.$container = this.$el.find('.matrix-interaction-container');
        this.$itemContainer = this.$el.find('.interaction-items');
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.clickable', this.interactionStart.bind(this));
    },

    /**
     * Add a scrollbar.
     */
    addScrollbar: function() {
        if (platform.checks.isEdge || platform.checks.isFirefox) {
            this.$itemContainer.addClass('auto-scroll');
        } else {
            this.$itemContainer.addClass('show-scroll');
        }
    },

    /**
     * Handle interaction clicks.
     */
    interactionStart: function(event) {
        const item = event.currentTarget;
        let stations;
        let pushIndex;

        if (this.options.id.startsWith('stream')) {
            pushIndex = parseInt(this.options.id.replace('stream', ''));
        } else {
            pushIndex = this.options.id === 'master' ? 'hdmi1' : this.options.id.replace('master-', '');
        }

        switch (item.getAttribute('data-action')) {
            case 'shareAll':
                this.matrixService.setMatrixMasterPush(0, 1, pushIndex);
                break;
            case 'stopAll':
                this.matrixService.setMatrixMasterPush(0, 0, pushIndex);
                break;
            case 'shareGroup':
                stations = this.matrixMainService.getStationsFromGroup(item.getAttribute('data-id'));

                _.each(stations, function(station) {
                    this.matrixService.setMatrixMasterPush(station.id.replace('sn', ''), 1, pushIndex);
                }.bind(this));
                break;
            case 'stopGroup':
                stations = this.matrixMainService.getStationsFromGroup(item.getAttribute('data-id'));

                _.each(stations, function(station) {
                    this.matrixService.setMatrixMasterPush(station.id.replace('sn', ''), 0, pushIndex);
                }.bind(this));
                break;
        }

        app.emit('main-loop.fast.start', {
            id: 'matrix'
        });

        this.matrixMainService.hideGroupNames();

        this.app.removeComponent('master');
        this.app.removeComponent('stream');
    },

    /**
     * Set position of interaction menu.
     */
    setPosition: function() {
        var offset = 0;
        var width = $(document).find('.svg-grid').width();
        var height = $(document).find('.svg-grid').height();
        var matrixContainerHeight = $(document).find('.matrix-container').height();
        var $btnMaster = $(document).find('#matrix-layer').find('#' + this.options.id).find('.btn-center');

        var masterSize = this.matrixService.convertCoordinates(
            {
                width: $btnMaster.attr('width'),
                height: $btnMaster.attr('height')
            },
            width, height,
            this.configs.get('dimensions.width'), this.configs.get('dimensions.height')
        );

        var pos = this.matrixService.convertCoordinates(
            {
                x: this.options.cx,
                y: this.options.cy
            },
            width, height,
            this.configs.get('dimensions.width'), this.configs.get('dimensions.height')
        );

        var calcPosX = pos.x - (this.$container.width() / 2);
        var calcPosY = pos.y - this.$container.height() - (masterSize.width / 2);

        // Standard
        this.$container
            .css('left', calcPosX)
            .css('top', calcPosY);

        if (calcPosY < 0) {
            if ((matrixContainerHeight - pos.y) > pos.y) {
                var top = pos.y + masterSize.width / 2;

                this.$container.find('.interaction-triangle-top').css('visibility', 'visible');
                this.$container.find('.interaction-triangle-bottom').css('visibility', 'hidden');

                this.$container.css('top', top);

                // RELEASE-2697: Fixed menu button height
                if ((this.$itemContainer.height() + pos.y) > matrixContainerHeight) {
                    this.$itemContainer.css('height', matrixContainerHeight - top - 20);
                }
            } else {
                this.$itemContainer.css('height', this.$itemContainer.height() + calcPosY - 20);
                this.$container.css('top', 0);
            }
        }

        if (calcPosX < 0) {
            offset = (this.$container.width() / 2 - 20) - Math.abs(calcPosX);

            this.$container.css('left', 0);

            this.$container.find('.interaction-triangle-top').css('margin-left', Math.abs(offset));
            this.$container.find('.interaction-triangle-bottom').css('margin-left', Math.abs(offset));
        } else if (calcPosX + this.$container.width() > width) {
            var newWidth = width - this.$container.width();

            offset = (this.$container.width() / 2 - 20) + Math.abs(width - (calcPosX + this.$container.width()));

            this.$container.css('left', newWidth);

            this.$container.find('.interaction-triangle-top').css('margin-left', Math.abs(offset));
            this.$container.find('.interaction-triangle-bottom').css('margin-left', Math.abs(offset));
        }
    }
});
