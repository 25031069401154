// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"language-select-modal\">\n    <div class=\"row\">\n        <div class=\"col-xs-12\">\n            <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"modal.select_language",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":45}}}))
    + "</h2>\n        </div>\n    </div>\n    <div class=\"row clickable focusable is-stopped\" data-action=\"zh\" tabIndex=\"-1\">\n        <div class=\"col-xs-12 language-item\">\n            <div class=\"language-chinese\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"language.zh",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":42},"end":{"line":9,"column":61}}}))
    + "</div>\n        </div>\n    </div>\n    <div class=\"row clickable focusable is-stopped\" data-action=\"en\" tabIndex=\"-1\">\n        <div class=\"col-xs-12 language-item\">\n            <div class=\"language-english\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"language.en",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":42},"end":{"line":14,"column":61}}}))
    + "</div>\n        </div>\n    </div>\n    <div class=\"row clickable focusable is-stopped\" data-action=\"fr\" tabIndex=\"-1\">\n        <div class=\"col-xs-12 language-item\">\n            <div class=\"language-french\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"language.fr",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":41},"end":{"line":19,"column":60}}}))
    + "</div>\n        </div>\n    </div>\n    <div class=\"row clickable focusable is-stopped\" data-action=\"de\" tabIndex=\"-1\">\n        <div class=\"col-xs-12 language-item\">\n            <div class=\"language-german\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"language.de",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":41},"end":{"line":24,"column":60}}}))
    + "</div>\n        </div>\n    </div>\n    <div class=\"row clickable focusable is-stopped\" data-action=\"ja\" tabIndex=\"-1\">\n        <div class=\"col-xs-12 language-item\">\n            <div class=\"language-japanese\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"language.ja",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":43},"end":{"line":29,"column":62}}}))
    + "</div>\n        </div>\n    </div>\n    <div class=\"row clickable focusable is-stopped\" data-action=\"nor\" tabIndex=\"-1\">\n        <div class=\"col-xs-12 language-item\">\n            <div class=\"language-norwegian\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"language.nor",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":44},"end":{"line":34,"column":64}}}))
    + "</div>\n        </div>\n    </div>\n    <div class=\"row clickable focusable is-stopped\" data-action=\"ru\" tabIndex=\"-1\">\n        <div class=\"col-xs-12 language-item\">\n            <div class=\"language-russian\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"language.ru",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":42},"end":{"line":39,"column":61}}}))
    + "</div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});
