'use strict';

var $ = require('jquery');
var _ = require('lodash');
var EventEmitter = require('../event-emitter').EventEmitter;
var platform = require('../platform/platform');
var instance;

/**
 * @class LayoutManager
 * @constructor
 */
function LayoutManager() {
    /**
     * JQuery reference to window.
     * @property $window
     * @private
     */
    this.$window = $(window);

    /**
     * JQuery reference to document.
     * @property $document
     * @private
     */
    this.$document = $(document);

    /**
     * @property windowWidth
     * @private
     */
    this.windowWidth = this.$window.width();

    /**
     * @property windowHeight
     * @private
     */
    this.windowHeight = this.$window.height();

    /**
     * If this property is on false the layout will not resizing.
     *
     * @property running
     * @type {boolean}
     */
    this.running = true;

    /**
     * @property size
     * @type {{}}
     */
    this.size = {};

    /**
     * @property $main
     * @type {*|jQuery|HTMLElement}
     */
    this.$main = $('#main');

    /**
     * @type {jQuery|Element}
     */
    this.$ratios = null;

    this.detectResizeEvent();

    /**
     * Initialize keyboard detection.
     */
    this.detectKeyboard();
}

/**
 * Extending from EventEmitter.
 */
LayoutManager.prototype = new EventEmitter();

/**
 * This function is called after main-component was initialized and rendered.
 */
LayoutManager.prototype.mainIsInitialized = function() {
    this.$ratios = $('#aspect-ratio-4-3, #aspect-ratio-16-9');
    this.bindDOMEvents();
};

/**
 * Bind all DOM-Events.
 */
LayoutManager.prototype.bindDOMEvents = function() {
    this.$ratios.on('scroll', this.onScrollHandler.bind(this));
};

/**
 * This function will reset the scroll-position after ratio-container was scrolled somewhere.
 */
LayoutManager.prototype.onScrollHandler = function() {
    this.$ratios
        .scrollLeft(0)
        .scrollTop(0);
};

/**
 * @private
 */
LayoutManager.prototype.onResizeHandler = function onResizeHandler() {
    if (this.running) {
        var windowSize = this.updateWindowSize();
        this.emit('window.resize', windowSize);
    }
};

/**
 * @private
 * @return {Object} windowSize
 */
LayoutManager.prototype.updateWindowSize = function updateWindowSize() {
    var windowSize = {};
    windowSize.width = this.windowWidth = this.$window.width();
    windowSize.height = this.windowHeight = this.$window.height();

    return windowSize;
};

/**
 * Calculate size to fill the container but still hold the aspect ratio.
 *
 * @param {Number} aspectRatio
 * @param {Object} containerSize
 */
LayoutManager.prototype.calculateSizeByAspectRatio = function calculateSizeByAspectRatio(aspectRatio, options) {
    options = options || {};

    _.defaults(options, {
        containerSize: null,
        offset: {}
    });

    var containerSize = options.containerSize || this.updateWindowSize();
    var offset = options.offset;
    offset.top = offset.top || 0;
    offset.left = offset.left || 0;
    offset.bottom = offset.bottom || 0;
    var currentAspectRatio = (containerSize.width - offset.left) / (containerSize.height - offset.top - offset.bottom);
    var size = {
        width: containerSize.width - offset.left,
        height: containerSize.height - offset.top - offset.bottom
    };

    if (currentAspectRatio > aspectRatio) {
        // Container width is too wide relative to desired width
        size.width = Math.floor((containerSize.height - offset.top - offset.bottom) * aspectRatio);
    } else {
        // Container height is too high relative to desired height
        size.height = Math.floor((containerSize.width - offset.left) / aspectRatio);
    }

    this.size = size;

    return this.size;
};

/**
 * Disable aut resize.
 */
LayoutManager.prototype.disableResize = function() {
    if (platform.checks.isAndroid) {
        this.running = false;

        this.$main.css({
            overflow: 'auto'
        });
    }
};

/**
 * Enable auto resize.
 */
LayoutManager.prototype.enableResize = function() {
    this.running = true;

    this.$main.css({
        overflow: 'hidden'
    });
};

/**
 * Detect focus on input fields (keyboard open).
 *
 * @method detectKeyboard
 */
LayoutManager.prototype.detectKeyboard = function() {
    this.$document.on('focus', 'input', this.disableResize.bind(this));
    this.$document.on('focusout', 'input', this.enableResize.bind(this));
};

/**
 * Detects the resizeEvent, listens to it and calls a debounced handler.
 */
LayoutManager.prototype.detectResizeEvent = function() {
    this.$window.on('resize', _.debounce(this.onResizeHandler.bind(this), 600));
};

/**
 * Scroll Input-field into view.
 *
 * @readme https://developer.mozilla.org/de/docs/Web/API/Element/scrollIntoViewIfNeeded
 */
LayoutManager.prototype.androidFocusMovement = function androidFocusMovement() {
    if (!platform.checks.isAndroid || !document.activeElement.scrollIntoViewIfNeeded) {
        return;
    }

    window.addEventListener('resize', function() {
        if (('INPUT' === document.activeElement.tagName || 'TEXTAREA' === document.activeElement.tagName)) {
            window.setTimeout(function() {
                document.activeElement.scrollIntoViewIfNeeded(true);
            }, 0);
        }
    });
};

/**
 * Singleton pattern.
 */
function getInstance() {
    if (!instance) {
        instance = new LayoutManager();
        instance.androidFocusMovement();
    }

    return instance;
}

module.exports = getInstance();
