// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p id=\"factory-reset\" class=\"factory-reset-warning\">"
    + alias3((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias2).call(alias1,depth0,{"name":"tr","hash":{"key":"settings.factory_reset_warning"},"data":data,"loc":{"start":{"line":1,"column":52},"end":{"line":1,"column":100}}}))
    + "</p>\n\n<div class=\"col-xs-12 restore-btn-container factory-reset-step-1\"\n     data-nav-area=\".btn-form\"\n     data-nav-area=\".is-focusable\"\n     data-nav-trapped\n     data-nav-area-default>\n    <input id=\"factory-reset-step-1\" type=\"button\" class=\"btn-form is-focusable show-warning-action\" value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.restore",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":108},"end":{"line":8,"column":132}}}))
    + "\">\n</div>\n\n<div class=\"col-xs-12 restore-btn-container factory-reset-step-2 is-hidden\"\n     data-nav-area=\".btn-form\"\n     data-nav-area=\".is-focusable\"\n     data-nav-trapped\n     data-nav-area-default>\n    <p class=\"factory-reset-warning\">"
    + alias3((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias2).call(alias1,depth0,{"name":"tr","hash":{"key":"settings.factory_reset_warning_step2"},"data":data,"loc":{"start":{"line":16,"column":37},"end":{"line":16,"column":92}}}))
    + "</p>\n    <input id=\"factory-reset-cancel\" type=\"button\" class=\"btn-form is-focusable stop-action\" value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":100},"end":{"line":17,"column":123}}}))
    + "\">\n    <input id=\"factory-reset-step-2\" type=\"button\" class=\"btn-form is-focusable restore-action\" value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.restore",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":103},"end":{"line":18,"column":127}}}))
    + "\">\n</div>\n\n<div class=\"col-xs-12 restore-btn-container factory-reset-step-3 is-hidden\">\n    <p class=\"factory-reset-warning\">"
    + alias3((lookupProperty(helpers,"tr")||(depth0 && lookupProperty(depth0,"tr"))||alias2).call(alias1,depth0,{"name":"tr","hash":{"key":"settings.factory_reset_warning_step3"},"data":data,"loc":{"start":{"line":22,"column":37},"end":{"line":22,"column":92}}}))
    + "</p>\n</div>\n";
},"useData":true});
