'use strict';

var mountIconsById = {
    'dropbox': 'icon-v3-cloud-dropbox',
    'gdrive': 'icon-v3-cloud-google-drive',
    'jianguoyun': 'icon-v3-cloud-jianguoyun',
    'onedrive': 'icon-v3-cloud-onedrive',
    'webdav': 'icon-v3-cloud-webdav',
    'vmeeting': 'icon-office_calendar'
};

var mountIconsByType = {
    'recent_files': 'icon-v3-recent-files',
    'usb': 'icon-v3-usb',
    'local': 'icon-v3-storage',
    'netdrive': 'icon-v3-network-drive',
    'vmeeting': 'icon-office_calendar'
};

var pictogram = function(mount) {
    return mountIconsById[mount.id]
        || mountIconsByType[mount.type]
        || 'icon-v3-folder';
};

var mountStateIconMapping = {
    'synced': 'icon-checkmark',
    'disconnected': 'icon-v3-locked',
    'oauth': 'loader',
    'failed': 'icon-v3-locked',
    'connected': 'loader',
    'fetching': 'loader',
    'not-responding': 'icon-no-wlan'
};

var connection = function(mount) {
    if (mount.type === 'cloud') {
        return mountStateIconMapping[mount.connection] || 'icon-panic-button';
    } else {
        return 'icon-none';
    }
};

module.exports = {
    pictogram: pictogram,
    connection: connection
};
