const app = require('../../../app');
const template = require('./recording-controls.html');
const vueUtils = require('../../util.js');
const pad = require('./../../../helper').pad;

module.exports = {
    template,
    props: {
        options: {
            type: Object
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data: function() {
        return {
            recordingMode: 'stop',
            freeSpace: 0,
            freeSpaceUnit: 'MB',
            recordingTimeInMsec: 0
        };
    },

    methods: {
        /**
         * Pad number for 2 digits if required
         * @param num number to pad
         */
        padToTwo: function(num) {
            return pad(parseInt(num), 2);
        },

        /**
         * Execute Wolfprot command to change recording mode
         * @param mode mode to change to
         */
        changeRecordingModeTo: function(mode) {
            if (mode === 'record') {
                this.recordService.startRecording();
            } else {
                this.recordService.toggleRecordState(mode);
            }

            app.emit('main-loop.fast.start', {
                id: 'record'
            });
        },

        updateRecordingData: function(time) {
            this.recordingTimeInMsec = time;

            const recCurrentState = this.recordService.recordTriggerState.currentState;

            this.recordingType = recCurrentState === 'opencast' ? this.recordService.getLcsType() : recCurrentState;
            this.recordingMode = this.recordService.getData()?.recordingMode;
            this.freeSpace = this.recordService.getSpace().space;
            this.freeSpaceUnit = this.recordService.getSpace().unit;
        },

        i18n: vueUtils.i18n
    },

    computed: {
        recordingTimeSeconds: function() {
            return Math.floor(this.recordingTimeInMsec / 1000) % 60;
        },

        recordingTimeMinutes: function() {
            return Math.floor(this.recordingTimeInMsec / 60000) % 60;
        },

        recordingTimeHours: function() {
            return Math.floor(this.recordingTimeInMsec / 3600000);
        },

        title: function() {
            if (this.recordingMode !== 'stop') {
                return this.i18n('control_center.now_recording');
            }

            return this.recordService.isEnabled() !== 'enabled'
                ? this.i18n('control_center.lecture_capture') : this.i18n('control_center.record');
        }
    },

    created: function() {
        this.recordService = app.getService('RecordService');
        this.deviceService = app.getService('DeviceService');

        this.evctx = vueUtils.eventContext();
        this.evctx.on('record.timer.update', this.updateRecordingData.bind(this));
    },

    destroyed: function() {
        this.evctx.close();
    },

    components: {
        'boxed-control': require('../boxed-control/boxed-control.js'),
        'command-button': require('../command-button/command-button.js')
    }
};
