'use strict';

var app = require('../../../app');
var $ = require('jquery');
var discoveryBoxTpl = require('./discovery-box.hbs');

/**
 * Matrix configurator: discovery box.
 */
app.component('DiscoveryBox', {
    template: discoveryBoxTpl,

    initialize: function() {
        this.matrixConnectionService = this.getService('MatrixConnectionService');
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();
        this.bindDOMEvents();
        this.createDeviceList();
    },

    /**
     * Create device list component.
     */
    createDeviceList: function() {
        this.app.createComponent('DiscoveryDeviceList', {
            type: 'DiscoveryDeviceList',
            container: this.$deviceList,
            stationForm: this.stationForm
        });
    },

    storeSelectors: function() {
        this.$deviceList = this.$el.find('#device-list');
        this.stationForm = this.$el.find('.matrix-tool[data-action="set-form"].active').data('form');
    },

    bindEvents: function() {
        this.on('matrix-box.update', this.refreshDeviceList.bind(this));
        this.on('matrix.discovery-box.set.format', this.setConnectionFormat.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.refresh-discovery', this.actionHandlerClick.bind(this));
        this.$el.on('click', '.matrix-tool', this.actionHandlerClick.bind(this));
        this.$el.on('click', '.matrix-connection-format', this.actionHandlerClick.bind(this));
    },

    /**
     * Handle actions click and call the specific action handler.
     *
     * @param event Event
     */
    actionHandlerClick: function(event) {
        if (!this.eventCalled) {
            this.eventCalled = true;

            setTimeout(function() {
                this.eventCalled = false;
            }.bind(this), 200);

            this.actionHandler(event);
        }
    },

    /**
     * Handle specific action.
     *
     * @param event Event
     */
    actionHandler: function(event) {
        var $el = $(event.currentTarget);
        var action = $el.data('action');

        switch (action) {
            case 'clear-all':
                this.emit('modal.open', {
                    id: 'matrix-clear-config',
                    role: {
                        name: 'Matrix',
                        key: 'show'
                    },
                    onConfirm: function() {
                        this.refreshDeviceList();

                        this.emit('matrix-grid.clear');
                    }.bind(this)
                });
                break;
            case 'set-form':
                $('[data-form]').removeClass('active');
                $el.addClass('active');

                this.stationForm = $el.data('form');

                this.emit('matrix.discovery-devices.set.form', this.stationForm);
                break;
            case 'set-connection':
                this.setConnectionFormat($el.data('connection'));
                break;
            case 'refresh':
                this.refreshDeviceList(false, $el);
                break;
        }
    },

    /**
     * Set connection format ('curveLinear' or 'curveCardinal')
     *
     * @param format Connection format
     */
    setConnectionFormat: function(format) {
        $('[data-connection]').removeClass('active');
        this.$el.find('.matrix-connection-format[data-connection="' + format + '"]').addClass('active');

        this.matrixConnectionService.setConnectionFormat(format);
    },

    /**
     * Refresh device list with a fake loading timeout.
     *
     * @param btn Refresh button (undefined if refresh isn't triggered by button click
     * @param onOpen Unused
     */
    refreshDeviceList: function(onOpen, btn) {
        this.app.removeComponent('DiscoveryDeviceList');

        if (btn) {
            btn.prop('disabled', true);
            btn.addClass('active');

            setTimeout(function() {
                btn.removeClass('active');
                btn.prop('disabled', false);

                this.createDeviceList();
            }.bind(this), 1000);
        } else {
            this.createDeviceList();
        }
    },

    destroy: function() {
        this.app.removeComponent('DiscoveryDeviceList');
    }
});
