'use strict';

var app = require('../app');
var StateMachine = require('./../state-machine');

/**
 * Streaming states
 */
var states = {
    on: 'on',
    off: 'off'
};

/**
 * Streaming function states
 */
var functionStates = {
    enabled: 'enabled',
    disabled: 'disabled'
};

/**
 * @type {object}
 */
var functionMapping = {
    true: 'enabled',
    false: 'disabled'
};

/**
 * @type {object}
 */
var mapping = {
    0: 'off',
    1: 'on'
};

/**
 * @type {object}
 */
var modalStates = {
    closed: 'closed',
    open: 'open'
};

app.service('Streaming', function(app) {
    /**
     * @method initialize
     */
    this.initialize = function() {
        this.state = new StateMachine({
            context: this,
            state: states.off,
            states: states
        });

        this.functionState = new StateMachine({
            context: this,
            state: functionStates.disabled,
            states: functionStates
        });

        this.streamingState = new StateMachine({
            context: this,
            state: functionStates.disabled,
            states: functionStates
        });

        this.modalState = new StateMachine({
            context: this,
            state: modalStates.closed,
            states: modalStates
        });

        this.appRecordingState = new StateMachine({
            context: this,
            state: functionStates.disabled,
            states: functionStates
        });

        this.deviceService = app.getService('DeviceService');
        this.authService = app.getService('AuthenticationService');
        this.recordService = app.getService('RecordService');

        app.getService('ConnectionFactoryService')
            .afterCreated('device', function(connection) {
                this.deviceConnection = connection;

                this.updateHandler();
                this.bindEvents();
            }.bind(this));
    };

    /**
     * @method bindEvents
     */
    this.bindEvents = function() {
        app.on('main-loop.update', this.updateHandler.bind(this));
        app.on('main-loop.update.streaming', this.updateHandler.bind(this));
        app.on('modal.mediaCast.closed', this.closeModal.bind(this));
        app.on('remote.media-cast.keyup', this.openModal.bind(this));
    };

    /**
     * @method toggleStreamingModal
     */
    this.toggleStreamingModal = function() {
        if (this.modalState.getState() === modalStates.open) {
            this.closeModal(true);
        } else {
            this.openModal();
        }
    };

    /**
     * @method openModal
     */
    this.openModal = function() {
        if ((this.authService.getIsLdapActive() && !this.authService.getIsLdapAuthenticated())
            || !(this.deviceService.isCbox() || this.deviceService.isCboxPro()
                || this.deviceService.isCboxCorePro() && this.deviceService.getFeatures().capture)) {
            return;
        }

        app.emit('modal.open', {
            id: 'mediaCast'
        });

        app.once('modal.closed', this.onModalClosed.bind(this));

        this.modalState.changeState(modalStates.open);
    };

    /**
     * @method closeModal
     */
    this.closeModal = function() {
        app.emit('modal.close');
    };

    /**
     * @method onModalClosed
     */
    this.onModalClosed = function() {
        this.modalState.changeState(modalStates.closed);
    };

    /**
     * @method toggleState
     * @return {string}
     */
    this.toggleState = function() {
        var state = null;

        switch (this.state.getState()) {
            case states.off:
                state = states.on;
                this.setState(state);
                break;
            case states.on:
                state = states.off;
                this.setState(state);
                break;
        }

        return state;
    };

    /**
     * @method setState
     * @param {string} state
     */
    this.setState = function(state) {
        if (this.state.getState !== states[state]) {
            this.state.changeState(states[state]);

            app.emit('main-loop.fast.start', {
                id: 'streaming'
            });

            this.deviceConnection
                .send('setStreamingMode', {
                    mode: (state === states.on)
                });

            app.emit('streaming.state.changed', {
                state: state
            });

            this.updateHeaderNotification(state);
        }
    };

    /**
     * Get state (Start streaming)
     * @returns {string}
     */
    this.getState = function() {
        return this.state.getState();
    };

    /**
     * Checks if streaming function is enabled or disabled (streaming enabled)
     *
     * @returns {boolean}
     */
    this.isEnabled = function() {
        return this.functionState.getState() === functionStates.enabled;
    };

    /**
     * Checks if streaming function is enabled or disabled (rtp & rtsp stream enabled)
     *
     * @returns {boolean}
     */
    this.isStreamEnabled = function() {
        return this.streamingState.getState() === functionStates.enabled;
    };

    /**
     * @method updateHandler
     */
    this.updateHandler = function() {
        this.deviceConnection
            .send(['getStreamingFunction',
                'getStreamingMode',
                'getRtpStreamingMode',
                'getRtspStreamingMode',
                'getAppRecordingEnable'])
            .then(this.onUpdate.bind(this));
    };

    /**
     * @method onUpdate
     * @param {int} modeKey
     */
    this.onUpdate = function(streamingFunction, streamingMode, rtp, rtsp, appRecording) {
        var mode = mapping[streamingMode.mode];
        var streamingState = !(rtp.rtp === false && rtsp.rtsp === false);
        var func = functionMapping[streamingFunction.function];
        var funcState;
        var state;

        if (functionMapping[appRecording.enabled] !== this.appRecordingState.getState()) {
            this.appRecordingState.changeState(functionMapping[appRecording.enabled]);
            app.emit('app-recording.state.changed', {
                enabled: this.appRecordingState.getState()
            });
        }

        if (func) {
            funcState = functionStates[func];
            if (this.functionState.getState() !== funcState) {
                this.functionState.changeState(funcState);

                app.emit('streaming.function.changed', {
                    function: func
                });
            }
        }

        if (mode) {
            state = states[mode];

            // Stop streaming if rtp and rtsp is off.
            if (!streamingState && this.state.getState() !== states.off) {
                this.setState(states.off);
            }

            if (this.state.getState() !== state) {
                this.state.changeState(state);

                app.emit('streaming.state.changed', {
                    state: state
                });

                this.updateHeaderNotification(state);
            }
        }

        if (functionMapping[streamingState] !== this.streamingState.getState()) {
            this.streamingState.changeState(functionMapping[streamingState]);
            app.emit('streaming.mode.changed', {
                mode: this.streamingState.getState()
            });
        }
    };

    /**
     * Send the upate event for the header widget
     * @method updateHeaderNotification
     * @param state
     */
    this.updateHeaderNotification = function(state) {
        switch (state) {
            case states.on:
                app.emit('status-widget.item.append', {
                    id: 'streaming',
                    accessKey: 'Streaming',
                    options: {
                        icon: 'icon-v3-stream',
                        clickable: false,
                        state: 'streaming'
                    }
                });
                break;
            case states.off:
                app.emit('status-widget.item.remove', {
                    id: 'streaming'
                });
                break;
        }

        app.getService('PipService').updatePipStatusWidget();
    };

    /**
     * Checks if the ip is a multicast ip
     *
     * @param {string} ip address
     */
    this.isMulticastIp = function(ipAddress) {
        var str = ipAddress.split('.');
        var oct1 = parseInt(str[0]);

        if (oct1 && oct1 >= 224 && oct1 <= 239) {
            return true;
        }

        return false;
    };
});
