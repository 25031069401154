'use strict';

var $ = require('jquery');
var app = require('../app');
var StateMachine = require('./../state-machine');

var states = {
    running: 'running',
    pause: 'pause'
};

app.service('ActivitiesService', function(app) {
    var TIMEOUT = 6000;

    return {
        /**
         * @method initialize
         */
        initialize: function() {
            this.state = new StateMachine({
                context: this,
                state: states.running,
                states: states
            });
            this.bindEvents();
            this.bindDOMEvents();
            this.startTimer();
        },

        /**
         * @method bindDOMEvents
         */
        bindDOMEvents: function() {
            $(document).on('normalized.mousemove click touchstart', this.activityHandler.bind(this));
            $(document).on('focusin', this.focusInHandler.bind(this));
            $(document).on('focusout', this.focusOutHandler.bind(this));
        },

        /**
         * @method bindEvents
         */
        bindEvents: function() {
            app.events.on('activities.update', this.activityHandler.bind(this));
            app.events.on('activities.pause', this.pause.bind(this));
            app.events.on('activities.start', this.start.bind(this));
        },

        /**
         * @method focusInHandler
         * @param {Object} event
         */
        focusInHandler: function(event) {
            if (app.$(event.target).is('input')) {
                this.pause();
            } else {
                this.activityHandler();
            }
        },

        /**
         * @method focusOutHandler
         * @param {Object} event
         */
        focusOutHandler: function(event) {
            var $el = app.$(event.target);

            if ($el.is('input:not(:disabled)')) {
                this.start();
            }
        },

        /**
         * @method activityHandler
         * @param {Object} event
         */
        activityHandler: function() {
            if (this.state.getState() === states.running) {
                this.clearTimeout();
                app.events.emit('activities.show');
                this.startTimer();
            }
        },

        /**
         * @method startTimer
         */
        startTimer: function() {
            if (this.state.getState() === states.running) {
                this.timer = setTimeout(function() {
                    app.events.emit('activities.hide');
                }.bind(this), TIMEOUT);
            }
        },

        /**
         * @method clearTimeout
         */
        clearTimeout: function() {
            clearTimeout(this.timer);
        },

        /**
         * @method pause
         */
        pause: function() {
            this.state.changeState(states.pause);
            this.clearTimeout();
        },

        /**
         * @method stop
         */
        start: function() {
            this.state.changeState(states.running);
            this.clearTimeout();
            this.startTimer();
        }
    };
});
