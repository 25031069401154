'use strict';

var _ = require('lodash');
var app = require('../app');
var StateMachine = require('./../state-machine');

var states = {
    stopped: 'stopped',
    running: 'running',
    paused: 'paused'
};

app.service('MainLoopService', function(app) {
    var state = new StateMachine({
        context: this,
        state: states.stopped,
        states: states
    });
    var fastLoopState = new StateMachine({
        context: this,
        state: states.stopped,
        states: states
    });
    var fastHandlers = {};

    return {
        mainEngine: null,
        fastEngine: null,

        /**
         * @method setEngine
         * @param {RedrawEngine} mainEngine
         */
        setMainEngine: function(mainEngine) {
            this.mainEngine = mainEngine;
        },

        /**
         * @method setFastEngine
         * @param {RedrawEngine} fastEngine
         */
        setFastEngine: function(fastEngine) {
            this.fastEngine = fastEngine;
        },

        /**
         * @method start
         */
        start: function() {
            this.mainEngine.start({
                fps: 0.5
            });

            if (state.getState() === states.stopped) {
                this.bindEvents();
            }

            state.changeState(states.running);
        },

        /**
         * @method bindEvents
         */
        bindEvents: function() {
            this.mainEngine.on('redraw.update', this.onUpdate.bind(this));
            this.fastEngine.on('redraw.update', this.onFastUpdate.bind(this));
            app.on('main-loop.fast.start', this.startFast.bind(this));
            app.on('main-loop.fast.stop', this.stopFast.bind(this));

            // Do not wait for the regular update event, call it after browser-tab is focused.
            window.onfocus = this.onUpdate();
        },

        /**
         * @method onUpdate
         */
        onUpdate: function() {
            if (state.getState() === states.running) {
                app.emit('main-loop.update');
            }
        },

        /**
         * @method pause
         */
        pause: function() {
            state.changeState(states.pause);
        },

        /**
         * @method unpause
         */
        unpause: function() {
            state.changeState(states.running);
        },

        /**
         * @method stop
         */
        stop: function() {
            state.changeState(states.stopped);
        },

        /**
         * @method startFast
         * @param {object} options
         * @returns {string}
         */
        startFast: function(options) {
            var id = options.id || _.uniqueId();

            if (!fastHandlers[id]) {
                fastHandlers[id] = {
                    id: id,
                    calls: 0,
                    maxCalls: (options.maxCalls || options.maxCalls === 0) ? options.maxCalls : 4
                };

                this.fastEngine.start({
                    fps: 5
                });
                fastLoopState.changeState(states.running);
            }

            return id;
        },

        /**
         * @method stopFast
         * @param {object} options
         */
        stopFast: function(options) {
            var id = options.id;

            delete fastHandlers[id];

            if (_.keys(fastHandlers).length === 0) {
                this.fastEngine.stop();
                fastLoopState.changeState(states.stopped);
            }
        },

        /**
         * @method onFastUpdate
         */
        onFastUpdate: function() {
            if (fastLoopState.getState() === states.running) {
                _.each(fastHandlers, function(handler) {
                    app.emit('main-loop.update.' + handler.id);
                    fastHandlers[handler.id].calls++;
                    this.stopFastUpdateInstance(handler.id);
                }.bind(this));
            }
        },

        /**
         * @method stopFastUpdateInstance
         * @param {string} id
         */
        stopFastUpdateInstance: function(id) {
            var handler = fastHandlers[id];

            if (!!handler && (handler.maxCalls !== 0 && handler.maxCalls <= handler.calls)) {
                this.stopFast({
                    id: id
                });
            }
        }
    };
});
