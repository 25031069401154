'use strict';

var EventEmitter = require('eventemitter2').EventEmitter2;

/**
 * Configured event emitter module.
 * @class CustomizedEventEmitter
 * @constructor
 */
function CustomizedEventEmitter() {
    return new EventEmitter({
        /**
         * Use wildcards.
         */
        wildcard: true,

        /**
         * If you want to emit the newListener event set to true.
         */
        newListener: false,

        /**
         * Max listeners that can be assigned to an event, default 10.
         */
        maxListeners: 40
    });
}

module.exports = CustomizedEventEmitter;
