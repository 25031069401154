// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"instruction-container\">\n    <div class=\"download-instruction\">\n        <div class=\"icon-download4\"></div>\n        <span class=\"instruction-header m-top-10\">\n            <label>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"download_apps.download_app",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":19},"end":{"line":5,"column":53}}}))
    + "</label>\n        </span>\n        <span class=\"instruction-text\">\n            <label>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"download_apps.enter_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":19},"end":{"line":8,"column":50}}}))
    + "</label>\n        </span>\n        <a class=\"instruction-url\"></a>\n        <span class=\"instruction-text m-bottom-30\">\n            <label>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"download_apps.follow_instructions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":19},"end":{"line":12,"column":60}}}))
    + "</label>\n        </span>\n    </div>\n    <div class=\"next-instruction\">\n        <div class=\"icon-pfeil\"></div>\n    </div>\n    <div class=\"start-instruction\">\n        <div class=\"icon-mirroring\"></div>\n        <span class=\"instruction-header m-top-10\">\n            <label>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"download_apps.start_mirroring",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":19},"end":{"line":21,"column":56}}}))
    + "</label>\n        </span>\n        <span class=\"instruction-text\">\n            <label>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"download_apps.start_vcast",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":19},"end":{"line":24,"column":52}}}))
    + "</label>\n        </span>\n        <span class=\"instruction-text\">\n            <label>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"download_apps.choose_cynap",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":19},"end":{"line":27,"column":53}}}))
    + "</label>\n        </span>\n    </div>\n</div>\n";
},"useData":true});
