'use strict';

var app = require('../../app');
var meetingCheckInTpl = require('./meeting-check-in.hbs');
var platform = require('./../../../platform/platform');

app.component('CheckIn', {
    template: meetingCheckInTpl,

    getAccessKey: function() {
        return {
            'roleName': 'vMeeting',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.remote = this.getService('RemoteService');
        this.keyboard = this.getService('KeyboardService');
        this.sessionMgmtService = this.getService('SessionManagementService');
        this.frontendSettings = this.getService('FrontendSettings');

        this.meeting = this.options.meeting;
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.initForm();
        this.bindEvents();
        this.bindDOMEvents();

        this.open(true);
    },

    storeSelectors: function() {
        this.$container = this.$el.find('#meeting-check-in-container');
        this.$inputContainer = this.$el.find('.session-management-input');
        this.$pinInput = this.$el.find('#check-in-pin-input');
        this.$infoContainer = this.$el.find('.check-in-info-container');
        this.$errorMsg = this.$el.find('#check-in-error');
        this.$loader = this.$el.find('#check-in-loader');
        this.$resendPin = this.$el.find('#check-in-resend-pin');
        this.$resentInfo = this.$el.find('#check-in-resent-info');
        this.$checkInBtn = this.$el.find('#check-in-btn');
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#meeting-check-in-form')
        });
    },

    bindEvents: function() {
        this.on('session-mgmt.close', this.close.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.clickable:not(.is-disabled)', this.onAction.bind(this));
        this.$pinInput.on('click', this.onInputFieldClick.bind(this));
        this.$el.find('#check-in-pin-input').on('submit', this.startSession.bind(this));
    },

    /**
     * Open Check-In overlay.
     *
     * @param animate true/false
     * @param meeting current meeting
     */
    open: function(animate) {
        this.resetCheckIn();

        if (animate) {
            this.$container
                .stop()
                .slideDown(200, this.focusRemoteArea.bind(this))
                .removeClass('hidden');
        } else {
            this.$container.removeClass('hidden');
            this.focusRemoteArea();
        }
    },

    /**
     * Focus area.
     */
    focusRemoteArea: function() {
        if (!platform.checks.isCbox) {
            return;
        }
        this.remote.focusArea(this.$container, {
            area: '.is-focusable, .clickable:not(.hidden)',
            trapped: true
        });
    },

    /**
     * Close Check-In overlay.
     *
     * @param animate true/false
     * @param slideUp true/false (else: slideDown)
     */

    close: function(slideUp) {
        this.meeting = undefined;

        this.keyboard.close();

        if (slideUp) {
            this.$container
                .animate({
                    top: '-' + this.$container.height() + 'px'
                },
                {
                    duration: 200,
                    complete: function() {
                        this.$container
                            .addClass('hidden')
                            .css('top', 'unset');

                        this.remote.destroyArea(this.$container);
                        this.emit('session-mgmt.meeting-check-in.closed');
                    }.bind(this)
                });
        } else {
            this.$container
                .stop()
                .slideUp(200, function() {
                    this.remote.destroyArea(this.$container);
                    this.emit('session-mgmt.meeting-check-in.closed');
                }.bind(this))
                .addClass('hidden');
        }
    },

    /**
     * Handle actions on buttons.
     *
     * @param event
     */
    onAction: function(event) {
        var $el = this.$(event.currentTarget);
        var action = $el.data('action');

        switch (action) {
            case 'check-in':
                this.startSession();
                break;
            case 'close':
                this.close();
                break;
            case 'resend-pin':
                this.resendPin();
                break;
        }
    },

    /**
     * Empty PIN input field if there was an error before.
     */
    onInputFieldClick: function() {
        if (this.hasError) {
            this.resetCheckIn();
        }
    },

    /**
     * Reset Check-In. Hide error msg and empty PIN field.
     */
    resetCheckIn: function() {
        this.form.get('checkInPin').setValue('');
        this.$infoContainer.addClass('hidden');
        this.$loader.hide();
        this.$resendPin.show();
        this.$resentInfo.hide();
        this.$checkInBtn.removeClass('is-disabled');
        this.hideErrorMsg();
    },

    /**
     * Start session with entered PIN and Meeting ID.
     */
    startSession: function() {
        this.$checkInBtn.addClass('is-disabled');
        var pin = this.form.get('checkInPin').getValue();

        // RELEASE-3746: PIN is always 4 digits.
        if (!this.meeting || this.meeting.meetingId === '' || pin.length > 4) {
            this.showErrorMsg();

            return;
        }

        this.$infoContainer.removeClass('hidden');
        this.$loader.show();

        this.deviceConnection
            .send('setVMeetingStartSession', {
                id: this.meeting.meetingId,
                pin: pin
            }).then(function() {
                this.sessionMgmtService.activeSessionPin = pin;

                this.frontendSettings.updateSetting({
                    tag: 'activeSessionPin',
                    value: this.sessionMgmtService.activeSessionPin
                });
                this.frontendSettings.saveSettings();

                // Session Management overlay is closed when we get the active session ID.
                this.hideErrorMsg();
                this.$checkInBtn.removeClass('is-disabled');
            }.bind(this),
            function() {
                this.$checkInBtn.removeClass('is-disabled');
                this.showErrorMsg();
            }.bind(this));
    },

    resendPin: function() {
        this.deviceConnection
            .send('setVMeetingResendPin', {
                id: this.meeting.meetingId
            }).then(function() {
                this.$resendPin.hide();
                this.$resentInfo
                    .fadeIn(300);
            }.bind(this));
    },

    /**
     * Show error message.
     */
    showErrorMsg: function() {
        this.$inputContainer.addClass('has-error');
        this.$infoContainer.removeClass('hidden');
        this.$loader.hide();
        this.$errorMsg.show();
        this.hasError = true;
    },

    /**
     * Hide error message.
     */
    hideErrorMsg: function() {
        this.$inputContainer.removeClass('has-error');
        this.$errorMsg.hide();
        this.hasError = false;
    }
});
