/* jslint bitwise: true */
/* jshint -W030 */

'use strict';

/*
 * Wolfprot helpers & server interface
 *
 * 2011, mfend (c source)
 * 2014, mmittelberger (translation to JavaScript)
 */

/*
 * Commands
 */
var commands = {
    WPC_NONE: 0x00,
    WPC_ADMIN_PWD: 0x01,
    WPC_GUEST_PWD: 0x02,
    WPC_MASTER_PWD: 0x03,
    WPC_WHITE_BALANCE_R: 0x04,
    WPC_WHITE_BALANCE_B: 0x05,
    WPC_EXT_FREEZE_OUT: 0x06,
    WPC_FEATURE_PACK_KEY: 0x07,
    WPC_APERTURE: 0x08,
    WPC_PREVIEW_MODE: 0x09,
    WPC_CAMERA_ASPECT_RATIO: 0x0A,
    WPC_ALWAYS_LIVE_TO_USBETH: 0x0B,
    WPC_FW_WWW_CHECK: 0x0C,
    WPC_FW_WWW_STATUS: 0x0D,
    WPC_FW_WWW_UPDATE: 0x0E,
    WPC_FW_WWW_VERSION: 0x0F,
    WPC_BLK: 0x10,
    WPC_MODEL: 0x11,
    WPC_SERIALNUMBER: 0x12,
    WPC_VERSION: 0x13,
    WPC_ADJUSTER: 0x14,
    WPC_DATE: 0x15,
    WPC_FEATURES: 0x16,
    WPC_RESTABLE: 0x17,
    WPC_NUMBER_IR_CODES: 0x18,
    WPC_MOUNTING_POS: 0x19,
    WPC_DESCRIPTION: 0x1A,
    WPC_HDTV_MASK_POS: 0x1B,
    WPC_BUILD_NUMBER: 0x1C,
    WPC_SINGLE_IP_AUTH: 0x1D,
    WPC_MAINS_FREQ: 0x1E,
    WPC_PIP_MODE: 0x1F,
    WPC_ZOOM: 0x20,
    WPC_FOCUS: 0x21,
    WPC_IRIS: 0x22,
    WPC_ARM: 0x23,
    WPC_MIRROR: 0x24,
    WPC_MIRROR_XY_POS: 0x25,
    WPC_LIGHT_FOCUS_POS: 0x26,
    WPC_FIX_PATT_NOISE_CORR: 0x27,
    WPC_DIG_ZOOM_POS: 0x28,
    WPC_DIG_ZOOM_MODE: 0x29,
    WPC_DIG_ZOOM_WARN: 0x2A,
    WPC_MACRO: 0x2B,
    WPC_CBOX_MODE: 0x2C,
    WPC_HEIGHT_ADJ: 0x2D,
    WPC_HEIGHT_ADJ_TIME_LEFT: 0x2E,
    WPC_STOP_ALL: 0x2F,
    WPC_POWER: 0x30,
    WPC_AF: 0x31,
    WPC_AE: 0x32,
    WPC_IRIS_PRIORITY: 0x33,
    WPC_POWER_ON_PRESET: 0x34,
    WPC_DISPLAY_LOGO: 0x35,
    WPC_MAINS_ON: 0x36,
    WPC_AUTO_POWER_OFF_TIME: 0x37,
    WPC_POWER_OFF_CONFIRMATION: 0x38,
    WPC_POWER_DOWN_MODE: 0x39,
    WPC_NAMESERVER_IP: 0x3A,
    WPC_OPTICAL_ZOOM_MODE: 0x3B,
    WPC_AMBI_LIGHT: 0x3C,
    WPC_OSD_ANIMATION: 0x3D,
    WPC_OUTPUT_RANGE: 0x3E,
    WPC_MODEL_DISPLAY: 0x3F,
    WPC_RECALL_PRESET: 0x40,
    WPC_STORE_PRESET: 0x41,
    WPC_PRESET_SFK: 0x42,
    WPC_PRESET_SFK_LIST: 0x43,
    WPC_TIMESOURCE: 0x44,
    WPC_DATEFORMAT: 0x45,
    WPC_2D3D: 0x46,
    WPC_OUTPUT_VERGENCE: 0x48,
    WPC_HOSTNAME: 0x49,
    WPC_STORE_MIRROR_POS: 0x4D,
    WPC_PRESET_HEIGHT: 0x4E,
    WPC_MULTICAST_FORMAT: 0x4F, // This is new in PF1 an,
    // Describes the encoding
    WPC_RESOLUTION_RGB: 0x50,
    WPC_RESOLUTION_DVI: 0x51,
    WPC_RESINFO_RGB: 0x52,
    WPC_DETAIL: 0x53,
    WPC_POSNEGBLUE: 0x54,
    WPC_RESINFO_DVI: 0x55,
    WPC_FREEZE: 0x56,
    WPC_EXT_INT: 0x57,
    WPC_AUTOSENSE_EXT_INT: 0x58,
    WPC_EXT_FREEZE_OUT_DEPRECATED: 0x59,
    WPC_PREVIEW: 0x5A,
    WPC_IMAGE_MIRROR: 0x5B,
    WPC_KEYSTONE: 0x5C,
    WPC_PIP: 0x5D,
    WPC_NETWORK: 0x5E,
    WPC_UNICAST: 0x5F,
    WPC_GAIN: 0x60,
    WPC_SHUTTER: 0x61,
    WPC_SHUTTER_SPEED_STEP: 0x62,
    WPC_SHUTTER_SPEED_VAR: 0x63,
    WPC_IMAGE_BRIGHTNESS: 0x64,
    WPC_WHITE_BALANCE: 0x65,
    WPC_WHITE_BALANCE_R_DEPRECATED: 0x66,
    WPC_WHITE_BALANCE_B_DEPRECATED: 0x67,
    WPC_GAMMA_NORMAL: 0x68,
    WPC_MAC: 0x69,
    WPC_TRIGGER_MODE: 0x6A,
    WPC_TRIGGER_EDGE: 0x6B,
    WPC_BACKLIGHT_COMP: 0x6C,
    WPC_COLORMODE: 0x6D,
    WPC_SATURATION: 0x6E,
    WPC_DHCP_STATE: 0x6F,
    WPC_DHCP: 0x70,
    WPC_IP_ADDRESS: 0x71,
    WPC_SUBNET_MASK: 0x72,
    WPC_GATEWAY_IP: 0x73,
    WPC_ETH_MODE: 0x74,
    WPC_MULTICAST_MODE: 0x75,
    WPC_MULTICAST_RUNNING: 0x76,
    WPC_MULTICAST_IP: 0x77,
    WPC_MULTICAST_PORT: 0x78,
    WPC_MULTICAST_RESOLUTION: 0x79, // This was calle,
    // WPC_MULTICAST_FORMAT in FB4
    WPC_MULTICAST_FRAMERATE: 0x7A,
    WPC_MULTICAST_RESTABLE: 0x7B,
    WPC_TIMESERVER_IP: 0x7C,
    WPC_TIMEZONE: 0x7D,
    WPC_DATE_TIME: 0x7E,
    WPC_TEMPERATURE: 0x7F,
    WPC_KEYLOCK: 0x80,
    WPC_IR_CODE: 0x81,
    WPC_OSD_LEVEL: 0x82,
    WPC_IMAGE_TURN: 0x83,
    WPC_IMAGE_TURN_ROT: 0x84,
    WPC_TEXT_ENHANCER: 0x85,
    WPC_IMAGE: 0x86,
    WPC_NOISE_SUPPRESSION: 0x87,
    WPC_DEBUG: 0x88,
    WPC_BAUDRATE: 0x89,
    WPC_FADING: 0x8A,
    WPC_ZOOM_WHEEL_CAL: 0x8B,
    WPC_PIXEL_CAL: 0x8C,
    WPC_ADJUSTMENT_MENU: 0x8D,
    WPC_SERVICE_MENU: 0x8E,
    WPC_DEFAULT_SETTINGS: 0x8F,
    WPC_MEMORY_OFF: 0x90,
    WPC_MEMORY_RECALL: 0x91,
    WPC_MEMORY_STORE: 0x92,
    WPC_SHOWALL: 0x93,
    WPC_ERASEMEM: 0x94,
    WPC_SNAPSHOT: 0x95,
    WPC_CONTINUOUS_VIDEO_5V: 0x96,
    WPC_MENU: 0x98,
    WPC_MENU_CTRL: 0x99,
    WPC_MENU_UNLOCK: 0x9A,
    WPC_MENU_UNLOCK_EXTRA: 0x9B,
    WPC_CMD_CHECK: 0x9C,
    WPC_VGA_PREVIEW_CAL: 0x9D,
    WPC_SOURCE: 0x9E,
    WPC_FACTORY_RESET: 0x9F,
    WPC_LIGHT: 0xA0,
    WPC_CAPTURE_AREA_SHIFT: 0xF2,
    WPC_LAMP_VOLTAGE: 0xA1,
    WPC_LASER: 0xA2,
    WPC_LAMP1_HOURS: 0xA3,
    WPC_LAMP2_HOURS: 0xA4,
    WPC_LAMP_BLOWN: 0xA5,
    WPC_LIGHTBOX: 0xA6,
    WPC_LASER_TM16: 0xA7,
    WPC_TEST_RESOLUTION_DVI: 0xA8,
    WPC_TEST_RESOLUTION_RGB: 0xA9,
    WPC_FW_UPLOAD_ERASE_FLASH: 0xB0,
    WPC_FW_UPLOAD_DATA_START: 0xB1,
    WPC_FW_UPLOAD_DATA: 0xB2,
    WPC_FW_UPLOAD_DATA_STOP: 0xB3,
    WPC_FW_DOWNGRADE: 0xB4,
    WPC_PICTUREHEADER: 0xB8,
    WPC_PICTUREBLOCK: 0xB9,
    WPC_PICTUREBLOCK2: 0xBA,
    WPC_OSD_TRANSPARENCY: 0xC0,
    WPC_OSD_SIZE: 0xC1,
    WPC_OSD_MENU_HOR_POS: 0xC2,
    WPC_OSD_MENU_VER_POS: 0xC3,
    WPC_OSD_STATUS_POS: 0xC4,
    WPC_OSD_SELECTION_BAR_COLOR: 0xC5,
    WPC_OSD_SELECTED_TEXT_COLOR: 0xC6,
    WPC_OSD_MENU_TEXT_COLOR: 0xC7,
    WPC_OSD_MENU_HEADLINE_COLOR: 0xC8,
    WPC_OSD_STATUS_TEXT_COLOR: 0xC9,
    WPC_OSD_LAYOUT: 0xCA,
    WPC_USB_MOUNT: 0xCB,
    WPC_LANGUAGE: 0xCC,
    WPC_OSD_LAYOUT_SUPPORTED: 0xCD,
    WPC_USB_DOC_DIRECTORY: 0xD0,
    WPC_USB_PICTURE_NUMBER: 0xD1,
    WPC_USB_PICTURE_FORMAT: 0xD2,
    WPC_SESSION_ID: 0xD3,
    WPC_LOGIN: 0xD4,
    WPC_LOGOUT: 0xD5,
    WPC_USB_LOAD_SETTINGS: 0xD6,
    WPC_USB_STORE_SETTINGS: 0xD7,
    WPC_ANNOTATION_VZ: 0xD8,
    WPC_PRESENTATION: 0xD9,
    WPC_FTP_SERVER_IP: 0xDA,
    WPC_FTP_USERNAME: 0xDB,
    WPC_FTP_PASSWORD: 0xDC,
    WPC_FTP_INTERVAL: 0xDD,
    WPC_USB_TRANSFER: 0xDE,
    WPC_SUPPLY_SOURCE: 0xDF,
    WPC_TCU_FB4: 0xE0,
    WPC_WLAN_MODE: 0xE1,
    WPC_WLAN_CHANNEL: 0xE2,
    WPC_WLAN_SSID_AUTO: 0xE3,
    WPC_WLAN_SSID: 0xE4,
    WPC_WLAN_DHCP: 0xE5,
    WPC_WLAN_IP_ADDRESS: 0xE6,
    WPC_WLAN_SUBNET_MASK: 0xE7,
    WPC_WLAN_GATEWAY_IP: 0xE8,
    WPC_WLAN_NAMESERVER_IP: 0xE9,
    WPC_WLAN_ACTIVATE: 0xEA,
    WPC_WLAN_MAC: 0xEB,
    WPC_WLAN_DHCP_STATE: 0xEC,
    WPC_WLAN_PASSWORD: 0xED,
    WPC_WLAN_ENCRYPTION: 0xEE,
    WPC_WLAN_PASSWORD_AP: 0xEF,
    WPC_FTP_TIMESTAMP: 0xF0,
    WPC_DISPARITY_GRID: 0xFC,
    WPC_UNKNOWN: 0xFF,

    WPC_DIR: 0x0100,
    WPC_SEL_DIR: 0x0101,
    WPC_SET_DIR: 0x0102,
    WPC_CREATE_DIR: 0x0103,
    WPC_DELETE_DIR: 0x0104,
    WPC_MOVE_DIR: 0x0105,
    WPC_DELETE_DIR_EXE: 0x0106,
    WPC_CREATE_NAME: 0x0110,
    WPC_CREATE: 0x0111,
    WPC_PRES_INTERVAL: 0x0120,
    WPC_PRES_START_AUTO: 0x0121,
    // WPC_PRES_START_MAN: 0x012,
    WPC_DELETE_PIC: 0x0130,
    WPC_FORMAT_USB: 0x0131,
    WPC_DELETE_PIC_NAME: 0x0132,
    WPC_DELETE_DIR_NAME: 0x0133,
    WPC_LOAD_SETTINGS: 0x0134,
    WPC_USB_FW_UPDATE: 0x0135,
    WPC_LOAD_FEATURE_PACK: 0x0136,

    WPC_FEATURE_PACK_STREAMING: 0x0200,
    WPC_FEATURE_PACK_PIP: 0x0201,
    WPC_FEATURE_PACK_IR_REMOTE: 0x0202,
    WPC_FEATURE_PACK_INT_MEMORY: 0x0203,
    WPC_FEATURE_PACK_USB_HOST: 0x0204,
    WPC_FEATURE_PACK_FADING: 0x0205,
    WPC_FEATURE_PACK_60FPS: 0x0206,
    WPC_FEATURE_PACK_WLAN: 0x0207,
    WPC_FEATURE_PACK_NET_SOURCE: 0x0208,
    WPC_FEATURE_PACK_DEMO: 0x0209,

    WPC_LECTURE_VIDEO_RECORDING: 0x0301,
    WPC_LECTURE_ROOM_CAM_STATE: 0x0302,
    WPC_LECTURE_ROOM_CAM_PIC_POS: 0x0303,
    WPC_LECTURE_ROOM_CAM_PIC_SIZE: 0x0304,
    WPC_LECTURE_STREAM_RESO: 0x0305,
    WPC_LECTURE_REC_TIME_LEFT: 0x0306,

    WPC_AUDIO_MICROPHONE: 0x0401,
    WPC_AUDIO_MUTE: 0x0402,
    WPC_AUDIO_VOLUME: 0x0403,
    WPC_AUDIO_ENABLE_HDMI: 0x0404,
    WPC_AUDIO_ENABLE_STREAM: 0x0405,
    WPC_AUDIO_ENABLE_LINEOUT: 0x0406,
    WPC_AUDIO_ENABLE_RECORD: 0x0407,

    WPC_FTP_TRANSFER: 0x0500,
    WPC_FTP_TRANSFER_TIME: 0x0501,
    WPC_FTP_SEND_VIDEOS: 0x0502,

    WPC_FLANGEBACK: 0x0A01,
    WPC_QUICK_SETUP_COUNT: 0x0A02,
    WPC_ADJUSTMENT_LOGIN: 0x0A03,
    WPC_ADJUSTMENT_LOGOUT: 0x0A04,
    WPC_ADJUSTMENT_FACTORY_RESET: 0x0A05,
    WPC_ADJUSTMENT_FEATURE_PACK: 0x0A06,
    WPC_FLANGEBACK_LIGHT: 0x0A07,
    WPC_PAN_LIMITS_DETECT: 0x0A08,
    WPC_ADJUSTMENT_DISPLACEMENT: 0x0A09,
    WPC_ADJUSTMENT_CLEAR_COUNTERS: 0x0A0A,
    WPC_ADJUSTMENT_LIGHTFIELD_OVERSIZE: 0x0A0B,

    WPC_CB_MODEL: 0xCB00,
    WPC_CB_GET_PICTURE: 0xCB02,
    WPC_CB_BOXNAME: 0xCB03,

    WPC_CB_ETH_DHCP_SETTING: 0xCB04,
    WPC_CB_ETH_IP_ADDRESS: 0xCB05,
    WPC_CB_ETH_SUBNET_MASK: 0xCB06,
    WPC_CB_ETH_GATEWAY_ADDRESS: 0xCB07,
    WPC_CB_ETH_DNS_ADDRESS: 0xCB08,
    WPC_CB_ETH_DNS2_ADDRESS: 0xCBD1,
    WPC_CB_ETH_GET_MAC_ADDRESS: 0xCB09,
    WPC_CB_ETH_GET_DHCP_STATE: 0xCB0A,
    WPC_CB_ETH_SET_ALL: 0xCB0B,
    WPC_CB_ETH_ACTIVATE: 0xCB35,
    WPC_CB_ETH_STATUS: 0xCB81,

    WPC_CB_WLAN_MODE: 0xCB0D,
    WPC_CB_WLAN_CHANNEL: 0xCB0E,
    WPC_CB_WLAN_SSID_TYPE: 0xCC44,
    WPC_CB_WLAN_BSSID: 0xCC45,
    WPC_CB_WLAN_FREQUENCY_BAND: 0xCC46,
    WPC_CB_WLAN_SSID_AUTO: 0xCB0F,
    WPC_CB_WLAN_INFRA_SSID_NAME: 0xCB10,
    WPC_CB_WLAN_DHCP_SETTING: 0xCB11,
    WPC_CB_WLAN_IP_ADDRESS: 0xCB12,
    WPC_CB_WLAN_SUBNET_MASK: 0xCB13,
    WPC_CB_WLAN_GATEWAY_ADDRESS: 0xCB14,
    WPC_CB_WLAN_DNS_ADDRESS: 0xCB15,
    WPC_CB_WLAN_DNS2_ADDRESS: 0xCBD2,
    WPC_CB_WLAN_ACTIVATE: 0xCB16,
    WPC_CB_WLAN_GET_MAC_ADDRESS: 0xCB17,
    WPC_CB_WLAN_GET_DHCP_STATE: 0xCB18,
    WPC_CB_WLAN_KEY_INFRASTRUCTURE: 0xCB19,
    WPC_CB_WLAN_ENCRYPTION_STANDARD: 0xCB1A,
    WPC_CB_WLAN_KEY_ACCESSPOINT: 0xCB1B,
    WPC_CB_WLAN_AP_LIST: 0xCB44,
    WPC_CB_WLAN_REGION: 0xCB5D,
    WPC_CB_WLAN_CERTIFICATE_MODE: 0xCB73,
    WPC_CB_WLAN_CERTIFICATE_FILE_NAME: 0xCB75,
    WPC_CB_WLAN_CERTIFICATE_FILE: 0xCB74,
    WPC_CB_WLAN_AP_LIST_STATUS: 0xCB6D,
    WPC_CB_WLAN_USERNAME: 0xCB6E,
    WPC_CB_WLAN_STATUS: 0xCB6C,
    WPC_CB_WLAN_AP_SSID_NAME: 0xCBB4,
    WPC_CB_WLAN_AP_IP_ADDRESS: 0xCBB5,
    WPC_CB_WLAN_AP_SUBNET_MASK: 0xCBB6,
    WPC_CB_WLAN_AP_ENCRYPTION_STANDARD: 0xCBB7,
    WPC_CB_WLAN_AP_DIRECT_CHANNEL: 0xCCDE,

    WPC_CB_TIME_SOURCE: 0xCB1D,
    WPC_CB_DATE_FORMAT: 0xCB1E,
    WPC_CB_TIMESERVER_URL: 0xCC42,
    WPC_CB_DATE_TIME: 0xCB1C,
    WPC_CB_TIMESERVER_TEST: 0xCC77,
    WPC_CB_TIMESERVER_TEST_STATUS: 0xCC78,

    WPC_CB_FRAMEBOX_CONTROL: 0xCB28,
    WPC_CB_FRAMEBOX_EVENT: 0xCB2D,

    WPC_CB_STREAMING_IP_ADDRESS: 0xCB21,
    WPC_CB_STREAMING_PORT: 0xCB22,
    WPC_CB_STREAMING_MODE: 0xCB20,
    WPC_CB_STREAMING_RESOLUTION: 0xCB23,
    WPC_CB_STREAMING_FRAMERATE: 0xCB24,
    WPC_CB_STREAMING_BITRATE: 0xCC75,
    WPC_CB_STREAMING_INTERFACE: 0xCB5C,
    WPC_CB_STREAMING_FUNCTION: 0xCB93,

    WPC_CB_RECORDING_STATE: 0xCB25,
    WPC_CB_RECORDING_RESOLUTION: 0xCB26,
    WPC_CB_RECORDING_FUNCTION: 0xCB92,
    WPC_CB_RECORDING_FRAMERATE: 0xCB94,
    WPC_CB_RECORDING_RENAME: 0xCC9B,

    WPC_CB_BLOCK: 0xCB34,

    WPC_CB_MIRACAST: 0xCB36,
    WPC_CB_MICE: 0xCCC4,
    WPC_CB_AIRPLAY: 0xCB37,
    WPC_CB_AIRPLAY_MODE: 0xCBD6,
    WPC_CB_CHROMECAST: 0xCBB2,
    WPC_CB_VCONNECT: 0xCB86,
    WPC_CB_MIRROR: 0xCB3B,
    WPC_CB_VCAST_PIN: 0xCC98,
    WPC_CB_MIRACAST_PIN: 0xCC1F,
    WPP_CB_MIRACAST_BACK_CHANNEL: 0xCB33,

    WPC_CB_PRESENTATION_MODE: 0xCB38,
    WPC_CB_PRESENTATION_ID: 0xCCBA,

    WPC_CB_VIDEO_STATUS: 0xCB3A,
    WPC_CB_MOUNTS_LIST: 0xCB3D,
    WPC_CB_FILE_LIST: 0xCD3F,
    WPC_CB_OPEN_FILE: 0xCB3C,

    WPC_CB_LOGIN: 0xCB42,

    WPC_CB_CLOUD_SERVICE_ENABLE: 0xCB8F,
    WPC_CB_CLOUD_CONNECT: 0xCB45,
    WPC_CB_CLOUD_PRELOAD: 0xCB46,
    WPC_CB_CLOUD_STATUS: 0xCB4C,

    WPC_CB_SNAPSHOT: 0xCB32,

    WPC_CB_MAIN_RESOLUTION: 0xCB4D,
    WPC_CB_CONTROL_SCREEN_RESOLUTION: 0xCD6D,
    WPC_CB_MEETING_MODE_PASSWORD_REQUIRED: 0xCB41,
    WPC_CB_LOGIN_LEVEL: 0xCB55,
    WPC_CB_PASSWORD_TYPE: 0xCB51,
    WPC_CB_PIN_DESTINATION: 0xCB52,
    WPC_CB_PIN_STATUS_LOCAL: 0xCB53,
    WPC_CB_ADMIN_PASSWORD: 0xCB3F,
    WPC_CB_USER_PASSWORD: 0xCB40,
    WPC_CB_USER_SETTINGS: 0xCB48,
    WPC_CB_END_PRESENTATION: 0xCB49,
    WPC_CB_BROWSER_STATUS: 0xCB4F,

    WPC_CB_MIC_MUTE: 0xCB59,
    WPC_CB_MASTER_MUTE: 0xCB58,
    WPC_CB_MASTER_VOLUME: 0xCB56,

    WPC_CB_MAIN_FREEZE: 0xCB5A,
    WPC_CB_EDIT_CONTENT_FREEZE: 0xCD7A,

    WPC_CB_AUX_MODE: 0xCB5B,

    WPC_CB_IMAGE_STATUS: 0xCB57,

    WPC_CB_STATUSBAR_SETTINGS: 0xCB5E,

    WPC_CB_FTP_MODE: 0xCB62,
    WPC_CB_FTP_URL: 0xCB63,
    WPC_CB_FTP_USER: 0xCB64,
    WPC_CB_FTP_PASS: 0xCB65,
    WPC_CB_FTP_PATH: 0xCB66,
    WPC_CB_FTP_TEST_STATUS: 0xCB67,
    WPC_CB_FACTORY_RESET: 0xCB61,
    WPC_CB_SYSTEM_REBOOT: 0xCCF5,
    WPC_CB_VZ_STATUS: 0xCB68,
    WPC_CB_FTP_TEST: 0xCB69,
    WPC_CB_USB_COPY_FILE: 0xCB6A,
    WPC_CB_OSD_MSG: 0xCB78,
    WPC_CB_HDMI_INPUT: 0xCB79,
    WPC_CB_SUPPORT_YEAR: 0xCB77,
    WPC_CB_WWW_UDATE_STATUS: 0xCB7A,
    WPC_CB_CLOUD_DOWNLOAD_LIST: 0xCB7B,
    WPC_CB_DELETE_FILE: 0xCB7C,
    WPC_CB_DELETE_FOLDER: 0xCC2C,
    WPC_CB_POWER: 0xCB0C,
    WPC_CB_HDCP_MAIN_OUT: 0xCB6F,
    WPC_CB_HDCP_AUX_OUT: 0xCB70,
    WPC_CB_HDCP_IN1: 0xCB71,
    WPC_CB_HDCP_IN2: 0xCB72,
    WPC_CB_BACKGROUND_IMAGE: 0xCB80,
    WPC_CB_STORAGE_SPACE_LEFT: 0xCB7E,
    WPC_CB_EULA_ACCEPT: 0xCB7D,
    WPC_CB_FIRMWARE_VERSION: 0xCB50,
    WPC_CB_SERIALNUMBER: 0xCB60,
    WPC_CB_LOCAL_TOUCH_KEYBOARD: 0xCB84,
    WPC_CB_CAPSLOCK_STATUS: 0xCB85,
    WPC_CB_RM_FROM_DOWNLOAD_LIST: 0xCB87,
    WPC_CB_TIMEZONE: 0xCB82,
    WPC_CB_QR_CODE: 0xCB88,
    WPC_CB_XTRA_SETTINGS: 0xCB89,
    WPC_CB_ACCESS_TOKEN: 0xCB8A,
    WPC_CB_LOAD_USER_DATA: 0xCB8B,
    WPC_CB_SHOW_PIN: 0xCB91,
    WPC_CB_GET_WINDOW_FREE: 0xCB8D,
    WPC_CB_GET_CONTENT_SOURCES: 0xCB90,

    WPC_CB_ANNOTATION_PAINT: 0xCB8C,
    WPC_CB_ANNOTATION_SETTINGS: 0xCB95,
    WPC_CB_ANNOTATION_ACTION: 0xCB96,
    WPC_CB_ANNOTATION_STATE: 0xCB97,

    WPC_CB_CLOUD_UPLOAD: 0xCBA1,

    WPC_CB_FRONTPANEL_LOGIN: 0xCB8E,
    WPC_CB_USB_STICK_SUPPORT: 0xCBAC,

    WPC_CB_NETDRIVE: 0xCBAB,
    WPC_CB_MATRIX_NETDRIVE: 0xCCC2,
    WPC_CB_MATRIX_NETDRIVE_STATUS: 0xCCC3,

    WPC_CB_FEATURE_STATUS: 0xCBA3,
    WPC_CB_FEATURE_UPDATE: 0xCBA4,
    WPC_CB_SUPPORT_STATUS: 0xCBA5,
    WPC_CB_SUPPORT_UPDATE: 0xCBA6,
    WPC_CB_FEATURE_ONLINE_UPDATE: 0xCBA7,
    WPC_CB_FEATURE_ONLINE_STATUS: 0xCBA8,
    WPC_CB_SUPPORT_ONLINE_UPDATE: 0xCBA9,
    WPC_CB_SUPPORT_ONLINE_STATUS: 0xCBAA,
    WPC_CB_LICENSE_FEATURES: 0xCB7F,

    WPC_CB_LINEOUT_VOLUME: 0xCBAF,
    WPC_CB_HDMI_OUT1_VOLUME: 0xCBB0,
    WPC_CB_HDMI_OUT2_VOLUME: 0xCBB1,

    WPC_CB_AUX_CONTENT_PRIORITY: 0xCBB3,

    WPC_CB_CAST_ID: 0xCBAE,

    WPC_CB_WEBCONFERENCE_FUNCTION: 0xCBB9,
    WPC_CB_WEBCONFERENCE_CAMERA: 0xCBBB,
    WPC_CB_WEBCONFERENCE_ENTRIES: 0xCCA6,
    WPC_CB_HDMI_INPUT_AUTOSTART: 0xCBBC,

    WPC_CB_FILE_UPLOAD_STATUS: 0xCBBE,
    WPC_CB_FILE_UPLOAD: 0xCBBF,
    WPC_CB_FILE_UPLOAD_PERCENTAGE: 0xCBC0,
    WPC_CB_FILE_UPLOAD_LIST: 0xCBC1,

    WPC_CB_WLAN_AUTH_METHOD: 0xCBC2,
    WPC_CB_WLAN_ANONYMOUS_IDENTITY: 0xCBC3,

    WPC_CB_LAN_AUTH_MODE: 0xCBC4,
    WPC_CB_LAN_AUTH_METHOD: 0xCBC5,
    WPC_CB_LAN_IDENTITY: 0xCBC6,
    WPC_CB_LAN_ANONYMOUS_IDENTITY: 0xCBC7,
    WPC_CB_LAN_PASSWORD: 0xCBC8,
    WPC_CB_LAN_CERT_MODE: 0xCBC9,
    WPC_CB_LAN_CERT_NAME: 0xCBCA,
    WPC_CB_LAN_CERT_FILE: 0xCBCB,

    WPC_CB_ANNOTATION_USERS: 0xCBBD,
    WPC_CB_ANNOTATION_PIN: 0xCBCD,
    WPC_CB_ANNOTATION_PIN_STATUS: 0xCBCE,
    WPC_CB_ROOM_PASSWORD_MODE: 0xCBD0,
    WPC_CB_ROOM_PASSWORD: 0xCBCF,
    WPC_CB_WLAN_AP_POWER: 0xCBD3,

    WPC_CB_AUX_MIRROR_ALLOW_OVERRIDE: 0xCBD7,
    WPC_CB_AUX_MIRROR_OVERRIDE: 0xCBD8,

    WPC_CB_FORCE_HTTPS: 0xCBDB,

    WPC_CB_RTP_MODE: 0xCBD4,
    WPC_CB_RTSP_MODE: 0xCBD5,
    WPC_CB_UNICAST_TTL: 0xCBD9,
    WPC_CB_MULTICAST_TTL: 0xCBDA,

    WPC_CB_LINE_IN_LOCAL_OUTPUT: 0xCBDD,
    WPC_CB_LINE_IN_REMOTE_OUTPUT: 0xCBDE,
    WPC_CB_USB_MIC_ENABLE: 0xCBDF,
    WPC_CB_USB_MIC_WEBCONF_ONLY: 0xCBE0,
    WPC_CB_USB_MIC_SETTINGS: 0xCBDF,
    WPC_CB_AUDIO_SETTINGS_ACTIVATE: 0xCD81,

    WPC_CB_OPENCAST_CERTIFICATE_FILE: 0xCBE2,
    WPC_CB_OPENCAST_CERTIFICATE_MODE: 0xCBE1,
    WPC_CB_OPENCAST_CERTIFICATE_NAME: 0xCB9D,

    WPC_CB_RTSP_URL_INTERFACE: 0xCBE5,

    WPC_CB_USER_VISIBILITY: 0xCBE6,
    WPC_CB_MIRROR_PIN_STATUS: 0xCBE4,
    WPC_CB_MIRROR_PIN_DYNAMIC: 0xCD0F,
    WPC_CB_AUDIO_TEST: 0xCBE3,
    WPC_CB_PIN_STATUS_HDMI2: 0xCBE7,

    WPC_CB_LINE_IN_LEVEL: 0xCBE8,
    WPC_CB_MIXER_METER: 0xCBE9,
    WPC_CB_MIC_STATUS: 0xCBEA,

    WPC_CB_USER_SETTINGS_STATUS: 0xCBEB,

    WPC_CB_LAN_PRIORITY: 0xCBEC,
    WPC_CB_WLAN_PRIORITY: 0xCBED,

    WPC_CB_WEBDAV_CREDENTIALS: 0xCC73,
    WPC_CB_WEBDAV_SETTINGS: 0xCC74,

    WPC_CB_DISCONNECT: 0xCBEE,

    WPC_CB_SSH_ENABLE: 0xCBF0,

    WPC_CB_USB_MIC_VOLUME: 0xCBF1,

    WPC_CB_WEBCAST_MODE: 0xCC08,
    WPC_CB_WEBCAST_STREAMING_MODE: 0xCC5C,
    WPC_CB_WEBCAST_CUSTOM_URL: 0xCC09,
    WPC_CB_WEBCAST_CUSTOM_URLS: 0xCC0A,
    WPC_CB_WEBCAST_YOUTUBE_STREAM_NAME: 0xCC13,
    WPC_CB_WEBCAST_YOUTUBE_CONNECT: 0xCC5B,
    WPC_CB_WEBCAST_YOUTUBE_LOGIN_STATE: 0xCC58,
    WPC_CB_WEBCAST_YOUTUBE_LOGOUT: 0xCC5D,
    WPC_CB_WEBCAST_YOUTUBE_STREAM_STATE: 0xCC59,
    WPC_CB_WEBCAST_STATE: 0xCC57,
    WPC_CB_WEBCAST_ACTIVE_PROTOCOL: 0xCC4F,

    WPC_CB_LAN2_MODE: 0xCBF2,
    WPC_CB_LAN2_DHCP: 0xCBF3,
    WPC_CB_LAN2_IP: 0xCBF4,
    WPC_CB_LAN2_SUBNET: 0xCBF5,
    WPC_CB_LAN2_GATEWAY: 0xCBF6,
    WPC_CB_LAN2_NAMESERVER1: 0xCBF7,
    WPC_CB_LAN2_NAMESERVER2: 0xCBF8,
    WPC_CB_LAN2_MAC: 0xCBF9,
    WPC_CB_LAN2_DHCP_STATUS: 0xCbFA,
    WPC_CB_LAN2_STATUS: 0xCBFB,
    WPC_CB_LAN2_AUTH_MODE: 0xCBFC,
    WPC_CB_LAN2_AUTH_METHOD: 0xCBFD,
    WPC_CB_LAN2_IDENTITY: 0xCBFE,
    WPC_CB_LAN2_ANONYMOUS_IDENTITY: 0xCBFF,
    WPC_CB_LAN2_PASSWORD: 0xCC00,
    WPC_CB_LAN2_CERT_MODE: 0xCC01,
    WPC_CB_LAN2_CERT_NAME: 0xCC02,
    WPC_CB_LAN2_CERT_FILE: 0xCC03,
    WPC_CB_LAN2_PRIORITY: 0xCC04,
    WPC_CB_LAN2_ACTIVATE: 0xCC05,

    WPC_CB_STREAM_INPUT: 0xCC19,

    WPC_CB_RECORDING_STREAM_INPUT: 0xCC22,
    WPC_CB_RECORDING_TRIGGER: 0xCC26,
    WPC_CB_RECORDING_STREAM_INPUT_HEALTH: 0xCC2D,
    WPC_CB_USB_DEVICE_LIST: 0xCC20,

    WPC_CB_RMS_CLIENT: 0xCC06,
    WPC_CB_RMS_CLIENT_ENABLED: 0xCCDD,
    WPC_CB_RMS_CLIENT_TEST: 0xCC07,
    WPC_CB_RMS_CLIENT_TRIGGER: 0xCCDC,

    WPC_CB_TEST_STREAM_RECORD: 0xCC24,

    WPC_CB_APP_RECORDING: 0xCC25,

    WPC_CB_WEBCONFERENCE_ENABLE_RECORDING: 0xCC34,

    WPC_CB_SWAP_HDMI_OUTPUTS: 0xCC3A,

    WPC_CB_WEBCONFERENCE_HDMI2: 0xCD6C,

    WPC_CB_SYSTEM_LANGUAGE: 0xCC43,

    WPC_TIMEZONE_UTC_OFFSET_MINUTES: 0xCC49,

    WPC_CB_USB_MIC_CARD_NAME: 0xCB4E,
    WPC_CB_USB_MIC_CARD_LIST: 0xCB4F,

    WPC_CB_AIRPLAY_BLE_DISCOVERY: 0xCC52,
    WPC_CB_AIRPLAY_INTERFACE: 0xCC53,

    WPC_CB_OFFICE365_FUNCTION: 0xCC50,

    WPC_CB_USB_FW_UPDATE_STATUS: 0xCC1A,
    WPC_CB_USB_FW_UPDATE_SKIP: 0xCC1B,
    WPC_CB_USB_FW_UPDATE_START: 0xCC1C,

    WPC_CB_BROWSER_STARTPAGE: 0xCC5F,
    WPC_CB_BOOKMARK_LIST: 0xCC66,

    WPC_CB_PROXY_FUNCTION: 0xCC64,
    WPC_CB_PROXY_URL: 0xCC5E,
    WPC_CB_PROXY_PORT: 0xCC65,
    WPC_CB_PROXY_AUTH_MODE: 0xCC60,
    WPC_CB_PROXY_USERNAME: 0xCC61,
    WPC_CB_PROXY_PASSWORD: 0xCC62,
    WPC_CB_PROXY_ACTIVATE: 0xCC63,

    WPC_CB_DOWNLOAD_LINK_LIST: 0xCC67,
    WPC_CB_URL_INTERFACE: 0xCC68,

    WPC_CB_STREAM_SDP_MODE: 0xCC69,
    WPC_CB_CONTENT_MODE_REC_SRC: 0xCC6D,

    WPC_CB_MIRRORING_LAN_ENABLE: 0xCC6E,
    WPC_CB_MIRRORING_LAN2_ENABLE: 0xCC6F,
    WPC_CB_MIRRORING_WLAN_ENABLE: 0xCC70,

    WPC_CB_SCREEN_OFF_NO_CONTENT: 0xCD6F,
    WPC_CB_SCREENSAVER_SETTINGS: 0xCC72,
    WPC_CB_SCREENSAVER_STATE: 0xCC76,

    WPC_CB_EVENTS: 0xCC36,
    WPC_CB_SESSION_MIDDLEWARE_TRIGGER: 0xCC38,
    WPC_CB_SESSION_ACTIVE: 0xCC39,

    WPC_CB_WLAN_SIGNAL_LEVEL_LIMIT: 0xCC7B,

    WPC_CB_SUPPORT_PIN: 0xCC79,

    WPC_CB_ECHO_CANCEL: 0xCC7C,
    WPC_CB_ECHO_CANCEL_DELAY: 0xCC7D,

    WPC_CB_MATRIX_MASTER_DISCOVERED_DEVICES: 0xCC7E,
    WPC_CB_MATRIX_MASTER_SETUP_STATIONS: 0xCC80,
    WPC_CB_MATRIX_MASTER_PULL: 0xCC81,
    WPC_CB_MATRIX_MASTER_STATUS: 0xCC7F,
    WPC_CB_MATRIX_MASTER_PUSH: 0xCC82,
    WPC_CB_MATRIX_MASTER_PREVIEW: 0xCC83,
    WPC_CB_MATRIX_MASTER_FILE_SHARE_ADD: 0xCC87,
    WPC_CB_MATRIX_MASTER_FILE_SHARE_REMOVE: 0xCC88,
    WPC_CB_MATRIX_MASTER_MODE: 0xCC89,
    WPC_CB_MATRIX_STATION_STATUS: 0xCC90,
    WPC_CB_MATRIX_MASTER_ANNOTATION: 0xCCB4,
    WPC_CB_MATRIX_MASTER_ANNOTATION_COLLAB: 0xCCB5,
    WPC_CB_MATRIX_POWER_STATION: 0xCCB2,
    WPC_CB_MATRIX_ACTIVE_TEMPLATE: 0xCC8D,
    WPC_CB_MATRIX_MASTER_CONTROL_STATION: 0xCC8C,
    WPC_CB_MATRIX_MASTER_LOCK_STATION: 0xCCBC,
    WPC_CB_MATRIX_GROUPWORK_MODE: 0xCCF2,
    WPC_CB_MATRIX_STATION_GROUPWORK_STATUS: 0xCCF6,
    WPC_CB_MATRIX_GROUPWORK_STATIONS_STATUS: 0xCCF7,
    WPC_CB_MATRIX_GROUPWORK_REQ_STATION_PULL: 0xCCF8,
    WPC_CB_MATRIX_GROUPWORK_REQ_STATION_PULL_ANSWER: 0xCCF9,
    WPC_CB_MATRIX_GROUPWORK_REQ_STATION_SEND_PULL_STOP: 0xCCFA,
    WPC_CB_MATRIX_GROUPWORK_REQ_STATION_RECEIVE_PULL_STOP: 0xCCFE,
    WPC_CB_MATRIX_GROUPWORK_REQ_STATUS: 0xCCFB,
    WPC_CB_MATRIX_GROUPWORK_FORCED: 0xCD3A,

    WPC_CB_ADMIN_PIN_STATUS: 0xCC95,
    WPC_CB_ADMIN_PIN_ONLY_WHILE_PRESENTING: 0xCD2F,

    WPC_CB_GENERATE_LOGFILE: 0xCC96,
    WPC_CB_GENERATE_LOGFILE_STATUS: 0xCC97,

    WPC_CB_LECTURE_CAPTURE_SETTINGS: 0xCC9C,
    WPC_CB_LECTURE_CAPTURE_START_RECORDING: 0xCC9D,
    WPC_CB_LECTURE_CAPTURE_UPLOAD_STATUS: 0xCCA4,

    WPC_CB_PANOPTO_SUB_DIRECTORIES: 0xCCA0,
    WPC_CB_PANOPTO_SETTINGS: 0xCCA1,
    WPC_CB_PANOPTO_LOGIN_STATUS: 0xCCA3,

    WPC_CB_OPENCAST_SETTINGS: 0xCCBE,

    WPC_CB_DISK_ENCRYPTION_ENABLE: 0xCC9E,
    WPC_CB_DISK_ENCRYPTION_STATUS: 0xCC9F,

    WPC_CB_FW_SIGNATURE_CHECK: 0xCCA2,
    WPC_CB_MATRIX_AUTO_OPEN_FILE: 0xCC8A,

    WPC_CB_LDAP_AUTH_MODE: 0xCCA7,
    WPC_CB_LDAP_SERVER_URL: 0xCCA8,
    WPC_CB_LDAP_SERVER_DOMAIN: 0xCCA9,
    WPC_CB_LDAP_TLS_MODE: 0xCCAA,
    WPC_CB_LDAP_LOGIN: 0xCCAB,
    WPC_CB_LDAP_STATUS: 0xCCAC,
    WPC_CB_WEBBROWSER_VIEWER_SUPPORT: 0xCCAD,
    WPC_CB_WEBBROWSER_VIEWER_PIN_ENABLED: 0xCD19,
    WPC_CB_WEBBROWSER_VIEWER_PIN_POPUP: 0xCD1F,

    WPC_CB_MULTI_EVENTS: 0xCCA5,

    WPC_CB_LDAP_CERTIFICATE: 0xCCAF,
    WPC_CB_LDAP_CERTIFICATE_NAME: 0xCCB0,

    WPC_CB_WINDOW_SETTINGS: 0xCCB3,
    WPC_CB_BYOD_ENDLESS_MODE: 0xCD22,

    WPC_CB_USB_SPEAKER: 0xCCB6,
    WPC_CB_USB_SPEAKER_CARD_LIST: 0xCCB8,
    WPC_CB_USB_SPEAKER_CARD_NAME: 0xCCB7,
    WPC_CB_USB_SPEAKER_VOLUME: 0xCCB9,
    WPC_CB_OPENCAST_SERIES: 0xCCBD,
    WPC_CB_OPENCAST_STATUS: 0xCCC0,
    WPC_CB_MATRIX_STATION_MASTER_CONTROL_TOKEN: 0xCC8E,
    WPC_CB_MATRIX_MASTER_LIMITED_ACCESS: 0xCCC6,
    WPC_CB_KEYBOARD_LAYOUT: 0xCCC5,
    WPC_CB_POWER_DOWN_MODE: 0xCCC7,
    WPC_CB_FILE_PUSH_MODE: 0xCCD9,
    WPC_APP_CONNECTION_COUNTER: 0xCCDA,

    WPC_CB_VMEETING_SESSION_LIST: 0xCCC9,
    WPC_CB_VMEETING_RESEND_PIN: 0xCCCA,
    WPC_CB_VMEETING_START_SESSION: 0xCCCB,
    WPC_CB_VMEETING_RESOURCE: 0xCCD3,
    WPC_CB_VMEETING_OAUTH_TENANT_ID: 0xCCD0,
    WPC_CB_VMEETING_OAUTH_SECRET: 0xCCD1,
    WPC_CB_VMEETING_OAUTH_APP_ID: 0xCCD6,
    WPC_CB_VMEETING_OAUTH: 0xCD31,

    WPC_CB_VMEETING_CLEANUP_TIME: 0xCCC8,
    WPC_CB_VMEETING_DEADLINE: 0xCCD8,
    WPC_CB_VMEETING_EXTEND_SESSION: 0xCCD5,
    WPC_CB_VMEETING_CONNECTION_STATUS: 0xCCD4,
    WPC_CB_VMEETING_UNBOOKED_DURATION: 0xCCCC,
    WPC_CB_VMEETING_FUNCTION: 0xCCD7,
    WPC_CB_VMEETING_MODE: 0xCCCD,
    WPC_CB_VMEETING_PIN: 0xCD38,

    WPC_CB_MATRIX_MASTER_STREAMING_BITRATE: 0xCCDF,
    WPC_CB_MATRIX_MASTER_STREAMING_RESOLUTION: 0xCCE0,
    WPC_CB_MATRIX_MASTER_STREAMING_FRAMERATE: 0xCCE1,
    WPC_CB_MATRIX_MASTER_STREAMING_MC_IP_BASE: 0xCCE2,
    WPC_CB_MATRIX_MASTER_STREAMING_MC_PORT_BASE: 0xCCE3,
    WPC_CB_MATRIX_MASTER_STREAMING_MC_TTL: 0xCCE4,

    WPC_SPLASH_SCREEN_TRIGGER_MODE: 0xCCEF,
    WPC_SPLASH_SCREEN_STATUS: 0xCCED,
    WPC_SPLASH_SCREEN_TRIGGER: 0xCCEE,

    WPC_CB_DEBUG_LEVEL: 0xCCE9,

    WPC_CB_ROUTING_MODE: 0xCCEA,

    WPC_CB_ANNOTATION_MAGIC_PEN_PAINT: 0xCCE6,
    WPC_CB_ANNOTATION_MAGIC_PEN_SETTINGS: 0xCCE7,
    WPC_CB_ANNOTATION_MAGIC_PEN_PROPERTIES: 0xCCE8,
    WPC_CB_ANNOTATION_MAGIC_PEN_STATUS: 0xCCEB,
    WPC_CB_ANNOTATION_MAGIC_PEN_TIMEOUT: 0xCCF0,

    WPC_CB_AP_MAXIMUM_CLIENTS: 0xCCF1,
    WPC_CB_MIRACAST_P2P_ROLE: 0xCCFD,

    WPC_CB_SCREENSAVER_OFF: 0xCD00,

    WPC_CB_ZOOM_SETTINGS: 0xCD49,
    WPC_CB_ZOOM_LOCAL_ACCOUNT_INFO: 0xCD54,
    WPC_CB_ZOOM_LOCAL_ACCOUNT_STATUS: 0xCD55,
    WPC_CB_ZOOM_LOCAL_ACCOUNT_LOGIN: 0xCD4A,

    WPC_CB_WEBCONF_SCREENSHARE_STATUS: 0xCD0E,
    WPC_CB_WEBCONF_SCREENSHARE_START: 0xCD44,

    WPC_CB_PIP_FUNCTION: 0xCD06,
    WPC_CB_PIP_CONTENT: 0xCD07,
    WPC_CB_PIP_LATENCY: 0xCD08,
    WPC_CB_PIP_SIZE: 0xCD0B,
    WPC_CB_PIP_POSITION: 0xCD0C,
    WPC_CB_PIP_STREAM_INPUT: 0xCD0D,

    WPC_CB_ROUTING_TABLE: 0xCD0A,

    WPC_CB_ADJUSTMENT_WLAN_REGION: 0xCD10,
    WPC_CB_ADJUSTMENT_WLAN_REGION_ONCE: 0xCD11,
    WPC_CB_WLAN_AP_COUNTRY: 0xCD12,

    WPC_CB_TLS_1_0_SUPPORT: 0xCD13,
    WPC_CB_FIREWALL: 0xCD14,

    WPC_CB_OFFICE365_TEAMS_SETTINGS: 0xCD15,

    WPC_CB_HDMI_INPUT_MAX_RESOLUTION: 0xCD16,

    WPC_CB_SCREENSHARE_WINDOW_VISIBLE: 0xCD18,

    WPC_CB_EDDYSTONE_DISCOVERY: 0xCD1A,
    WPC_CB_EDDYSTONE_INTERFACE: 0xCD1B,

    WPC_HELP_PAGE_SUPPORT: 0xCD1C,
    WPC_WWW_HELP_PAGE: 0xCD1D,
    WPC_WWW_HELP_PAGE_STATUS: 0xCD1E,

    WPC_CB_VLINK_PRO_DISCOVERY_SETTINGS: 0xCD20,

    WPC_CB_OFFICE365_LOCAL_ACCOUNT: 0xCD21,

    WPC_CB_AIRPLAY_4K_MODE: 0xCD25,

    WPC_CB_DIRECTORY_LIST: 0xCD27,

    WPC_CB_WINDOW_START_PRIVATE: 0xCD28,
    WPC_CB_MGMT_SUBSCRIBE_STATUS: 0xCD29,

    WPC_CB_HDMI_INPUT_WEBRTC: 0xCD2A,
    WPC_CB_OFFICE365_CREDENTIALS_ORIGIN: 0xCC8F,

    WPC_CB_WEBCAST_YOUTUBE_PARAMETERS: 0xCCAE,

    WPC_CB_STANDBY_POSSIBLE: 0xCD17,

    WPC_CB_REVERSE_SHELL: 0xCD2B,
    WPC_CB_ADMIN_MESSAGE_STATUS: 0xCD2D,
    WPC_CB_ADMIN_MESSAGE_ENABLE: 0xCD2E,

    WPC_CB_PRESENTATION_STATUS: 0xCD30,

    WPC_CB_MDNS_DISCOVERY: 0xCD32,

    WPC_CB_PANOPTO_CUSTOM_USER_CREDENTIALS: 0xCD33,
    WPC_CB_PANOPTO_LOGIN_DIALOG: 0xCD34,
    WPC_CB_PANOPTO_BROWSER_LOGIN: 0xCD3E,

    WPC_CB_MIRACAST_4K_MODE: 0xCD36,

    WPC_CB_HDMI_INPUT_1_NO_AUDIO: 0xCC14,
    WPC_CB_HDMI_INPUT_1_HDCP_SUPPORT: 0xCD64,

    WPC_CB_OPEN_WEBCONFERENCE_LOCAL: 0xCD45,

    WPC_CB_CONNECTION_ID: 0xCD46,
    WPC_CB_CONNECTION_ID_ENABLE: 0xCD47,
    WPC_CB_CONNECTION_ID_INTERFACE: 0xCD48,

    WPC_BROWSER_SHORTCUTS_CONFIG: 0xCD52,
    WPC_CB_BROWSER_SHORTCUT_OPEN: 0xCD53,

    WPC_CB_UVC_DEVICE_LIST_3: 0xCD50,
    WPC_CB_UVC_INPUT_3: 0xCD51,

    WPC_CB_MIRACAST_LOW_LATENCY: 0xCD4C,

    WPC_CB_LOCAL_SCREENSAVER: 0xCD5C,

    WPC_CB_SYSTEM_ANALYSIS: 0xCD65,

    WPC_CB_PING: 0xCD66,

    WPC_CB_RAUC_UPDATE_STATUS: 0xCD67,

    WPC_CB_CAMERA_BACK_FUNCTION: 0xCD71,
    WPC_CB_ZERO_CONFIG_UVC_TEST: 0xCD74,
    WPC_CB_ZERO_CONFIG: 0xCD75,
    WPC_CB_CAMERA_BACK_REC_ENABLE: 0xCD76,

    WPC_CB_ROOM_CONTROLLER_SETTINGS: 0xCD78,
    WPC_CB_ROOM_CONTROLLER_PATH: 0xCD79,

    WPC_CB_CONTENT_PER_OUTPUT: 0xCD5D,
    WPC_CB_CONFIDENCE_MONITORS_INFORMATION: 0xCD5E,
    WPC_CB_DUAL_PROJECTION_ENABLE: 0xCD5F,
    WPC_CB_START_APPLICATION: 0xCD60,
    WPC_CB_CONTROL_APPLICATION: 0xCD61,
    WPC_CB_CONTROL_SPECIFIC_APPLICATION: 0xCD62,
    WPC_CB_PREPARE_LIST: 0xCD63,
    WPC_CB_OUTPUT_MIRRORING: 0xCD6A,
    WPC_CB_APPLICATION_MULTI_EVENTS: 0xCD6B,
    WPC_CB_OUTPUT_TO_EDIT: 0xCD77
};

/**
 * Header details.
 */
var headers = {
    WP_HEAD_DIR: 0x01, // 0=GET, 1=SET,
    WP_HEAD_DIR_GET: 0x00, // GET,
    WP_HEAD_DIR_SET: 0x01, // SET,
    WP_HEAD_EXT_HDR: 0x02, // 0=1 Byte Header, 1=2 Byte Header
    WP_HEAD_EXT_LEN: 0x04, // 0=1 Byte Length, 1=2 Byte Length
    WP_HEAD_EXT_CMD: 0x08, // 0=1 Byte Command, 1=2 Byte Command
    WP_HEAD_ID: 0x10, // 0=no ID, 1=4 Byte ID
    WP_HEAD_PROT2: 0xF0, // Maske fuer Prot,
    WP_HEAD_ERR: 0x80, // 0=OK, 1=ER,

    WP_EXTHEAD_EXT_LEN: 0x01 // 32bit lengt,
};

/*
 * Errors
 */
var errors = {
    WPE_TIMEOUT: 0x01,
    WPE_UNKNOWN_CMD: 0x02,
    WPE_UNKNOWN_PAR: 0x03,
    WPE_INVALID_LEN: 0x04,
    WPE_FIFO_FULL: 0x05,
    WPE_FW_UPDATE: 0x06,
    WPE_ACCESS_DENIED: 0x07,
    WPE_AUTH_REQUIRED: 0x08,
    WPE_BUSY: 0x09,
    WPE_SIP_REQUIRED: 0x0A
};

var userLevels = {
    NONE: 0,
    VIEWER: 1,
    COLLAB: 2,
    USER: 3,
    ADMIN: 4
};

/*
 * Parameters
 */
var parameters = {
    WPP_BLK_POSITIONS_DEPRECATED: 0x00,
    WPP_BLK_FLAGS_AND_UNIT_DEPRECATED: 0x01,
    WPP_BLK_POSITIONS: 0x02,
    WPP_BLK_FLAGS_AND_UNIT: 0x03,
    WPP_BLK_MENU1: 0x04,
    WPP_BLK_MENU2: 0x05,
    WPP_BLK_ETH: 0x06,
    WPP_BLK_TOUCHHEAD: 0x07,
    WPP_BLK_TOUCHHEAD_FW: 0x08,
    WPP_BLK_TOUCHHEAD_MOTORS: 0x09, // Initialisierungwerte,
    // Die Motoren (DR und AR)
    WPP_BLK_TOUCHREMOTE: 0x0A,
    WPP_BLK_FLAGS_AND_UNIT2: 0x0B, // Extension o,
    // WPP_BLK_FLAGS_AND_UNIT
    WPP_BLK_ETH2: 0x0C, // Extension of WPP_BLK_ET,
    WPP_BLK_POSITIONS2: 0x0D, // Extension o,
    // WPP_BLK_POSITIONS

    WPP_OFF: 0,
    WPP_ON: 1,
    WPP_TOGGLE: 2,
    WPP_NO: 0,
    WPP_YES: 1,
    WPP_NA: 2,
    WPP_NONE: 0xFF,

    WPP_POWER_OFF: 0x00,
    WPP_POWER_ON: 0x01,
    WPP_POWER_TOGGLE: 0x02,

    WPP_ZOOM_WIDE: 0x01,
    WPP_ZOOM_TELE: 0x02,
    WPP_ZOOM_START_WIDE: 0x11,
    WPP_ZOOM_START_TELE: 0x12,
    // Digital Zoom
    WPP_DIG_ZOOM_MODE_OFF: 0x00,
    WPP_DIG_ZOOM_MODE_2X: 0x01,
    WPP_DIG_ZOOM_MODE_4X: 0x02,
    // Digital Zoom Warning
    WPP_DIG_ZOOM_WARN_STOP: 0x00,
    WPP_DIG_ZOOM_WARN_MSG: 0x01,
    WPP_DIG_ZOOM_WARN_NONE: 0x02,

    WPP_CBOX_MODE_OFF: 0x00,
    WPP_CBOX_MODE_ON: 0x01,

    WPP_FOCUS_FAR: 0x01,
    WPP_FOCUS_NEAR: 0x02,
    WPP_FOCUS_START_FAR: 0x11,
    WPP_FOCUS_START_NEAR: 0x12,
    WPP_AF_OFF: 0x00,
    WPP_AF_ON: 0x01,
    WPP_AF_TOGGLE: 0x02,
    WPP_ONE_PUSH_AF: 0x10,

    WPP_IRIS_OPEN: 0x01,
    WPP_IRIS_CLOSE: 0x02,
    WPP_IRIS_START_OPEN: 0x11,
    WPP_IRIS_START_CLOSE: 0x12,
    WPP_AE_OFF: 0x00,
    WPP_AE_ON: 0x01,
    WPP_AE_TOGGLE: 0x02,
    WPP_IRIS_PRIORITY_AUTO: 0x00,
    WPP_IRIS_PRIORITY_MAN: 0x01,
    WPP_IRIS_PRIORITY_TOGGLE: 0x02,

    WPP_PRESET1: 0x01,
    WPP_PRESET2: 0x02,
    WPP_PRESET3: 0x03,
    // User programmierbare Presets (= 0x00..= 0x7F)
    WPP_RECALL_PRESET0: 0x00,
    WPP_RECALL_PRESET1: 0x01,
    WPP_RECALL_PRESET2: 0x02,
    WPP_RECALL_PRESET3: 0x03,
    WPP_RECALL_PRESET_DEFAULT: 0x04,
    // Factory Presets (= 0x80..= 0xFF)
    WPP_RECALL_PRESET_MAX_WIDE: 0xE5,
    WPP_RECALL_PRESET_DINA4: 0xE6,
    WPP_RECALL_PRESET_DINA5: 0xE7,
    WPP_RECALL_PRESET_DINA6: 0xE8,
    WPP_RECALL_PRESET_DINA7: 0xE9,
    WPP_RECALL_PRESET_DINA8: 0xEA,
    WPP_RECALL_PRESET_MAX_TELE: 0xEB,
    WPP_RECALL_PRESET_SLIDE: 0xEC,
    WPP_RECALL_PRESET_X_DINA4: 0xED,
    WPP_RECALL_PRESET_X_DINA5: 0xEE,

    WPP_STORE_PRESET0: 0x00,
    WPP_STORE_PRESET1: 0x01,
    WPP_STORE_PRESET2: 0x02,
    WPP_STORE_PRESET3: 0x03,
    // Special Function Keys (Preset 1-3)
    WPP_SFK_PRESET: 0x00, // Normale Funktion de,
    // Presets
    WPP_SFK_POS_NEG: 0x01,
    WPP_SFK_BLUE: 0x02,
    WPP_SFK_BLACK_WHITE: 0x03,
    WPP_SFK_WB: 0x04,
    WPP_SFK_FREEZE: 0x05,
    WPP_SFK_IMAGE: 0x06,
    WPP_SFK_ONEPUSHAF: 0x07,
    WPP_SFK_LIGHT_ON_OFF: 0x08,
    WPP_SFK_SLIDE_ON_OFF: 0x09, // Slidebox wird ein- bzw,
    // Ausgeschaltet, beim
    // Ausschalten wird der letzte
    // Zustand wiederhergestellt, im
    // Zweifelsfall Light On
    WPP_SFK_TEXT: 0x0A,
    WPP_SFK_LIGHT: 0x0B, // Durchschalte,
    // Light,Lightbox,Slidebox
    WPP_SFK_IMAGE_TURN: 0x0C,
    WPP_SFK_PIP_MODE: 0x0D,
    WPP_SFK_ASPECT_RATIO: 0x0E,
    WPP_SFK_RECORDING_START: 0x0F,
    WPP_SFK_RECORDING_PAUSE: 0x10,
    WPP_SFK_RECORDING_STOP: 0x11,
    WPP_SFK_FORCE_LASER: 0x12,

    WPP_PRESET_HEIGHT_DISABLED: 0x00,
    WPP_PRESET_HEIGHT_DEFAULT: 0x01,
    WPP_PRESET_HEIGHT_SET: 0x02,
    WPP_PRESET_HEIGHT_AUTO: 0x03,
    WPP_PRESET_HEIGHT_MAN: 0x04,

    WPP_HEIGHT_START_AUTO: 0x03,
    WPP_HEIGHT_START_MAN: 0x04,
    WPP_HEIGHT_CANCEL: 0x05,
    WPP_HEIGHT_ACCEPT: 0x06,
    WPP_HEIGHT_NEXT_STEP_1: 0x07,
    WPP_HEIGHT_NEXT_STEP_2: 0x08,
    WPP_HEIGHT_NEXT_STEP_3: 0x09,

    WPP_HEIGHT_STOP: 0x00,
    WPP_HEIGHT_RUNNING: 0x01,
    WPP_HEIGHT_CENTER_LIGHT_FIELD: 0x02,
    WPP_HEIGHT_ADJUST_CAMERA_FOCUS: 0x03,
    WPP_HEIGHT_ADJUST_LIGHT_FOCUS: 0x04,
    WPP_HEIGHT_DONE: 0x05,

    WPP_POWER_ON_PRESET_OFF: 0x00,
    WPP_POWER_ON_PRESET_ON: 0x01,
    WPP_POWER_ON_PRESET_TOGGLE: 0x02,
    WPP_POWER_ON_PRESET_DEFAULT: 0x03,

    WPP_DISPLAY_LOGO_OFF: 0x00,
    WPP_DISPLAY_LOGO_ON: 0x01,
    WPP_DISPLAY_LOGO_TOGGLE: 0x02,

    WPP_MAINS_ON_STANDBY: 0x00,
    WPP_MAINS_ON_POWER_ON: 0x01,
    WPP_MAINS_ON_TOGGLE: 0x02,

    WPP_IMAGE_TURN_OFF: 0x00,
    WPP_IMAGE_TURN_ON: 0x01,
    WPP_IMAGE_TURN_TOGGLE: 0x02,

    WPP_IMAGE_TURN_ROT_CYCLE: 0x00,
    WPP_IMAGE_TURN_ROT_M90: 0x01,
    WPP_IMAGE_TURN_ROT_180: 0x02,
    WPP_IMAGE_TURN_ROT_P90: 0x03,

    WPP_AUTO_POWER_OFF_TIME_OFF: 0xFF,
    WPP_AUTO_POWER_OFF_TIME_OFF_ARM: 0xFE,
    WPP_AUTO_POWER_OFF_TIME_30M: 0x1E,
    WPP_AUTO_POWER_OFF_TIME_60M: 0x3C,
    WPP_AUTO_POWER_OFF_TIME_120M: 0x78,
    WPP_AUTO_POWER_OFF_TIME_180M: 0xB4,
    WPP_AUTO_POWER_OFF_TIME_240M: 0xF0,

    WPP_POWER_DOWN_MODE_NORMAL: 0x00,
    WPP_POWER_DOWN_MODE_DEEP: 0x01,
    WPP_POWER_DOWN_MODE_TOGGLE: 0x02,
    WPP_POWER_DOWN_MODE_ECO: 0x03,

    WPP_OPTICAL_ZOOM_MODE_OFF: 0x00,
    WPP_OPTICAL_ZOOM_MODE_9X: 0x01,
    WPP_OPTICAL_ZOOM_MODE_12X: 0x02,
    WPP_OPTICAL_ZOOM_MODE_15X: 0x03,

    WPP_AMBI_LIGHT_OFF: 0x00,
    WPP_AMBI_LIGHT_ON: 0x01,

    WPP_KEYLOCK_OFF: 0x00,
    WPP_KEYLOCK_ON: 0x01,
    WPP_KEYLOCK_TOGGLE: 0x02,

    WPP_IR_CODE_A: 0x1C,
    WPP_IR_CODE_B: 0x1D,
    WPP_IR_CODE_C: 0x1E,
    WPP_IR_CODE_D: 0x1F,
    WPP_IR_CODE_1: 0x96,
    WPP_IR_CODE_2: 0x98,
    WPP_IR_CODE_3: 0x99,
    WPP_IR_CODE_4: 0x9A,
    WPP_IR_CODE_5: 0x9B,
    WPP_IR_CODE_6: 0x9C,
    WPP_IR_CODE_7: 0x9D,
    WPP_IR_CODE_8: 0x9E,
    WPP_IR_CODE_9: 0x9F,

    WPP_OSD_LEVEL_QUIET: 0x00,
    WPP_OSD_LEVEL_TALK: 0x01,
    WPP_OSD_LEVEL_VERBOSE: 0x02,

    WPP_IMAGE_OFF: 0x00,
    WPP_IMAGE_ON: 0x01,
    WPP_IMAGE_TOGGLE: 0x02,

    WPP_DEBUG_OFF: 0x00,
    WPP_DEBUG_ON: 0x01,

    WPP_CONTINUOUS_VIDEO_5V_OFF: 0x00,
    WPP_CONTINUOUS_VIDEO_5V_ON: 0x01,

    // Command Parameters
    WPP_MENU_OFF: 0x00,
    WPP_MENU_ON: 0x01,
    WPP_MENU_TOGGLE: 0x02,

    // Status Reply Values
    WPP_MENU_NONE: 0x00,
    WPP_MENU_STANDARD: 0x01,
    WPP_MENU_EXTRA: 0x02,
    WPP_MENU_VIEW: 0x03,

    WPP_RESOLUTION_OFF: 0x000000FF,
    WPP_RESOLUTION_AUTO: 0x00000000,
    WPP_RESOLUTION_SVGA: 0x00000001,
    WPP_RESOLUTION_SVGA_60: 0x00003C01,
    WPP_RESOLUTION_XGA: 0x00000004,
    WPP_RESOLUTION_XGA_60: 0x00003C04,
    WPP_RESOLUTION_SXGA_M: 0x00000008,
    WPP_RESOLUTION_SXGA_M_60: 0x00003C08,
    WPP_RESOLUTION_SXGA: 0x0000000A,
    WPP_RESOLUTION_SXGA_60: 0x00003C0A,
    WPP_RESOLUTION_UXGA: 0x0000000D,
    WPP_RESOLUTION_UXGA_60: 0x00003C0D,
    WPP_RESOLUTION_SXGA_P: 0x00000012,
    WPP_RESOLUTION_SXGA_P_60: 0x00003C12,
    WPP_RESOLUTION_VGA: 0x00000014,
    WPP_RESOLUTION_VGA_60: 0x00003C14,
    WPP_RESOLUTION_720P: 0x00000016,
    WPP_RESOLUTION_720P_60: 0x00003C16,
    WPP_RESOLUTION_1080P: 0x00000018,
    WPP_RESOLUTION_1080P_30: 0x00001E18,
    WPP_RESOLUTION_1080P_60: 0x00003C18,
    WPP_RESOLUTION_WSXGA: 0x0000001A,
    WPP_RESOLUTION_WSXGA_60: 0x00003C1A,
    WPP_RESOLUTION_WXGA: 0x0000001B,
    WPP_RESOLUTION_WXGA_60: 0x00003C1B,
    WPP_RESOLUTION_WUXGA: 0x0000001C,
    WPP_RESOLUTION_WUXGA_60: 0x00003C1C,
    WPP_RESOLUTION_WXGA_P: 0x0000001D,
    WPP_RESOLUTION_WXGA_P_60: 0x00003C1D,
    WPP_RESOLUTION_WXGA1: 0x0000001E,
    WPP_RESOLUTION_WXGA1_60: 0x00003C1E,
    WPP_RESOLUTION_540P: 0x00000020, // QH,
    WPP_RESOLUTION_360P: 0x00000021, // NH,

    /*
     * // Index für JPEG Auflösung- ACHTUNG: Im FPGA hartcodiert. var
     * WPP_RESOLUTION_NATIVE 0 //Native wird später durch die Kameraauflösung
     * ersetzt //4:3 WPP_RESOLUTION_SXGA_M 0 WPP_RESOLUTION_XGA 1 var
     * WPP_RESOLUTION_768x576 2 WPP_RESOLUTION_VGA 3 WPP_RESOLUTION_QVGA 4
     * WPP_RESOLUTION_1440p 5 //16:9 WPP_RESOLUTION_720p
     * WPP_RESOLUTION_SXGA_M + 8 //HD 128= 0x720 WPP_RESOLUTION_576p
     * WPP_RESOLUTION_XGA + 8 WPP_RESOLUTION_432p WPP_RESOLUTION_768x576 + 8 var
     * WPP_RESOLUTION_288p WPP_RESOLUTION_VGA + 8 WPP_RESOLUTION_144p
     * WPP_RESOLUTION_QVGA + 8 WPP_RESOLUTION_1080p WPP_RESOLUTION_1440p + 8
     * //FHD 192= 0x1080 WPP_RESOLUTION_540p WPP_RESOLUTION_1080p + 1 //qHD 96=
     * 0x540 WPP_RESOLUTION_360p WPP_RESOLUTION_1080p + 2 //nHD 64= 0x360
     * //16:10 WPP_RESOLUTION_WXGA_16_10 WPP_RESOLUTION_SXGA_M + 16 var
     * WPP_RESOLUTION_XGA_16_10 WPP_RESOLUTION_XGA + 16 var
     * WPP_RESOLUTION_SVGA_16_10 WPP_RESOLUTION_768x576 + 16 var
     * WPP_RESOLUTION_VGA_16_10 WPP_RESOLUTION_VGA + 16 var
     * WPP_RESOLUTION_QVGA_16_10 WPP_RESOLUTION_QVGA + 16
     *
     * WPP_RESOLUTION_END 255
     */

    WPP_DETAIL_OFF: 0x00,
    WPP_DETAIL_LOW: 0x01,
    WPP_DETAIL_MEDIUM: 0x02,
    WPP_DETAIL_HIGH: 0x03,

    WPP_POSNEGBLUE_POSITIVE: 0x00,
    WPP_POSNEGBLUE_NEGATIVE: 0x01,
    WPP_POSNEGBLUE_BLUE: 0x02,
    WPP_POSNEGBLUE_TOGGLE: 0x03,
    WPP_POSNEGBLUE_BLUE_OFF: 0x04,

    WPP_COLORBW_COLOR: 0x00,
    WPP_COLORBW_BW: 0x01,
    WPP_COLORBW_TOGGLE: 0x02,

    WPP_FREEZE_OFF: 0x00,
    WPP_FREEZE_ON: 0x01,
    WPP_FREEZE_TOGGLE: 0x02,

    WPP_MEMORY1: 0x01,
    WPP_MEMORY2: 0x02,
    WPP_MEMORY3: 0x03,
    WPP_MEMORY4: 0x04,
    WPP_MEMORY5: 0x05,
    WPP_MEMORY6: 0x06,
    WPP_MEMORY7: 0x07,
    WPP_MEMORY8: 0x08,
    WPP_MEMORY9: 0x09,
    WPP_MEMORY_AUTOFILL: 0x10,
    WPP_MEMORY_ERASE: 0x20,

    WPP_SHOWALL_OFF: 0x00,
    WPP_SHOWALL_ON: 0x01,
    WPP_SHOWALL_TOGGLE: 0x02,

    WPP_ERASEMEM_MAN: 0x00,
    WPP_ERASEMEM_STANDBY: 0x01,
    WPP_ERASEMEM_TOGGLE: 0x02,

    WPP_GAIN_AUTO_OFF: 0x00,
    WPP_GAIN_0DB: 0x00,
    WPP_GAIN_1DB: 0x01,
    WPP_GAIN_2DB: 0x02,
    WPP_GAIN_3DB: 0x03,
    WPP_GAIN_4DB: 0x04,
    WPP_GAIN_5DB: 0x05,
    WPP_GAIN_6DB: 0x06,
    WPP_GAIN_7DB: 0x07,
    WPP_GAIN_8DB: 0x08,
    WPP_GAIN_9DB: 0x09,
    WPP_GAIN_10DB: 0x0A,
    WPP_GAIN_11DB: 0x0B,
    WPP_GAIN_12DB: 0x0C,
    WPP_GAIN_13DB: 0x0D,
    WPP_GAIN_14DB: 0x0E,
    WPP_GAIN_15DB: 0x0F,
    WPP_GAIN_16DB: 0x10,
    WPP_GAIN_17DB: 0x11,
    WPP_GAIN_18DB: 0x12,
    WPP_GAIN_19DB: 0x13,
    WPP_GAIN_20DB: 0x14,
    WPP_GAIN_21DB: 0x15,
    WPP_GAIN_22DB: 0x16,
    WPP_GAIN_23DB: 0x17,
    WPP_GAIN_24DB: 0x18,
    WPP_GAIN_AUTO_LOW: 0x40,
    WPP_GAIN_AUTO_MED: 0x80,
    WPP_GAIN_AUTO_HIGH: 0xC0,
    WPP_GAIN_AUTO_MASK: 0xC0,

    WPP_SHUTTER_STEP: 0x00,
    WPP_SHUTTER_VAR: 0x01,
    WPP_SHUTTER_AUTO: 0x02,
    WPP_SHUTTER_OFF: 0x03,

    WPP_SHUTTER_SPEED_STEP_1_30: 0x00,
    WPP_SHUTTER_SPEED_STEP_FL: 0x01,
    WPP_SHUTTER_SPEED_STEP_1_50: 0x02,
    WPP_SHUTTER_SPEED_STEP_1_60: 0x03,
    WPP_SHUTTER_SPEED_STEP_1_100: 0x04,
    WPP_SHUTTER_SPEED_STEP_1_120: 0x05,
    WPP_SHUTTER_SPEED_STEP_1_250: 0x06,
    WPP_SHUTTER_SPEED_STEP_1_500: 0x07,
    WPP_SHUTTER_SPEED_STEP_1_1000: 0x08,
    WPP_SHUTTER_SPEED_STEP_1_2000: 0x09,
    WPP_SHUTTER_SPEED_STEP_1_3000: 0x0A,

    WPP_FEATURE_PACK_KEY_FAILED: 0x00,
    WPP_FEATURE_PACK_KEY_OK: 0x01,

    WPP_APERTURE_AUTO: 0x00,
    WPP_APERTURE_F_2_8: 0x01,
    WPP_APERTURE_F_4_0: 0x02,
    WPP_APERTURE_F_5_6: 0x03,
    WPP_APERTURE_F_8_0: 0x04,
    WPP_APERTURE_F_11_0: 0x05,
    WPP_APERTURE_F_16_0: 0x06,

    WPP_TRIGGER_MODE_OFF: 0x00,
    WPP_TRIGGER_MODE_ON: 0x01,
    WPP_TRIGGER_MODE_TOGGLE: 0x02,

    WPP_TRIGGER_EDGE_POS: 0x00,
    WPP_TRIGGER_EDGE_NEG: 0x01,
    WPP_TRIGGER_EDGE_TOGGLE: 0x02,

    WPP_WHITE_BALANCE_AUTO: 0x00,
    WPP_WHITE_BALANCE_ONE_PUSH: 0x01,
    WPP_WHITE_BALANCE_MAN: 0x02,
    WPP_WHITE_BALANCE_PERFORM: 0x10,

    WPP_DHCP_OFF: 0x00,
    WPP_DHCP_ON: 0x01,
    WPP_DHCP_TOGGLE: 0x02,

    WPP_ETH_MODE_OFF: 0x00,
    WPP_ETH_MODE_IMG: 0x01,
    WPP_ETH_MODE_CTRL: 0x02,
    WPP_ETH_MODE_CTRL_IMG: 0x03,
    WPP_ETH_MODE_FW: 0x04,
    WPP_ETH_MODE_FW_IMG: 0x05,
    WPP_ETH_MODE_FW_CTRL: 0x06,
    WPP_ETH_MODE_FW_CTRL_IMG: 0x07,
    WPP_ETH_MODE_AUTH: 0x08,

    WPP_MULTICAST_MODE_OFF: 0x00,
    WPP_MULTICAST_MODE_AUTO: 0x01,
    WPP_MULTICAST_MODE_CONTINUOUS: 0x02,

    WPP_MULTICAST_FORMAT_MJPG: 0x00,
    WPP_MULTICAST_FORMAT_RTP_MJPG: 0x01,
    WPP_MULTICAST_FORMAT_RTP_H264: 0x02,

    WPP_MULTICAST_FRAMERATE_LOW: 0x00,
    WPP_MULTICAST_FRAMERATE_MED: 0x01,
    WPP_MULTICAST_FRAMERATE_HIGH: 0x02,

    WPP_BACKLIGHT_COMP_OFF: 0x00,
    WPP_BACKLIGHT_COMP_MED: 0x01,
    WPP_BACKLIGHT_COMP_HIGH: 0x02,

    WPP_COLORMODE_BW: 0x00,
    WPP_COLORMODE_PRESENTATION: 0x01,
    WPP_COLORMODE_NATURAL: 0x02,
    WPP_COLORMODE_VIDEOCONF: 0x03,
    WPP_COLORMODE_MAN: 0x04,
    WPP_COLORMODE_BW_TOGGLE: 0x05,

    WPP_GAMMA_NORMAL_0: 0x00,
    WPP_GAMMA_NORMAL_1: 0x01,
    WPP_GAMMA_NORMAL_2: 0x02,
    WPP_GAMMA_NORMAL_3: 0x03,

    WPP_COLORMODE_BW_GAMMA: 0x02,
    WPP_COLORMODE_BW_SAT: 0,
    WPP_COLORMODE_PRESENTATION_GAMMA: 0x01,
    WPP_COLORMODE_PRESENTATION_SAT: 105, // 14,
    WPP_COLORMODE_NATURAL_GAMMA: 0x03,
    WPP_COLORMODE_NATURAL_SAT: 100, // 11,
    WPP_COLORMODE_VIDEOCONF_GAMMA: 0x01,
    WPP_COLORMODE_VIDEOCONF_SAT: 84, // 9,

    WPP_OSD_TRANSPARENCY_OFF: 0x00,
    WPP_OSD_TRANSPARENCY_ON: 0x01,
    WPP_OSD_TRANSPARENCY_TOGGLE: 0x02,

    WPP_OSD_ANIMATION_OFF: 0x00,
    WPP_OSD_ANIMATION_ON: 0x01,

    WPP_OUTPUT_RANGE_FULL: 0x00,
    WPP_OUTPUT_RANGE_STANDARD: 0x01,

    WPP_OSD_STATUS_POS_TOP: 0x00,
    WPP_OSD_STATUS_POS_BOTTOM: 0x01,
    WPP_OSD_STATUS_POS_TOGGLE: 0x02,

    WPP_OSD_SIZE_SMALL: 0x00,
    WPP_OSD_SIZE_LARGE: 0x01,
    WPP_OSD_SIZE_TOGGLE: 0x02,

    WPP_BAUDRATE_9600: 0x00,
    WPP_BAUDRATE_19200: 0x01,
    WPP_BAUDRATE_38400: 0x02,
    WPP_BAUDRATE_57600: 0x03,
    WPP_BAUDRATE_115200: 0x04,

    WPP_NUMBER_IR_CODES_4: 0x00,
    WPP_NUMBER_IR_CODES_9: 0x01,

    WPP_MOUNTING_POS_REGULAR: 0x00,
    WPP_MOUNTING_POS_FLIPPED: 0x01,

    WPP_TEXT_ENHANCER_OFF: 0x00,
    WPP_TEXT_ENHANCER_ON: 0x01,
    WPP_TEXT_ENHANCER_TOGGLE: 0x02,

    WPP_LIGHT_OFF: 0x00,
    WPP_LIGHT_L_ON_LB_OFF: 0x01,
    WPP_LIGHT_LB_ON_L_OFF: 0x02,
    WPP_LIGHT_SB_ON: 0x03,
    WPP_LIGHT_SEQ_TOGGLE: 0x10,
    WPP_LIGHT_OFF_LB_TOGGLE: 0x11,
    WPP_LIGHT_TOGGLE: 0x12,
    WPP_LAMPCHANGE: 0x80,

    WPP_CAPTURE_AREA_SHIFT_OFF: 0x00,
    WPP_CAPTURE_AREA_SHIFT_ON: 0x01,
    WPP_CAPTURE_AREA_SHIFT_TOGGLE: 0x02,

    WPP_EXTERN_OFF: 0x00,
    WPP_EXTERN_ON: 0x01,
    WPP_EXTERN_TOGGLE: 0x02,
    WPP_EXTERN2_ON: 0x03,

    WPP_STORE_MIRROR_POS_OFF: 0x00,
    WPP_STORE_MIRROR_POS_ON: 0x01,
    WPP_STORE_MIRROR_POS_TOGGLE: 0x02,

    WPP_FADING_OFF: 0x00,
    WPP_FADING_SLOW: 0x02,
    WPP_FADING_STANDARD: 0x04,
    // WPP_FADING_BLACK: 0x0,
    // WPP_FADING_DIRECT: 0x0,
    // WPP_FADING_NORMAL: 0x0,
    WPP_FADING_FAST: 0x08,
    // WPP_FADING_PIP: 0x2 // Bit1 in Fading Mode kennzeichnet, dass Picture i,
    // Picture Fading in der CalcEngine gemacht wird.

    WPP_AUTOSENSE_EXT_INT_OFF: 0x00,
    WPP_AUTOSENSE_EXT_INT_ON: 0x01,
    WPP_AUTOSENSE_EXT_INT_TOGGLE: 0x02,

    WPP_FREEZE_RGB: 0x00,
    WPP_FREEZE_DVI: 0x01,
    WPP_FREEZE_ETHUSB: 0x02,
    WPP_FREEZE_LCD: 0x03,
    WPP_EXT_RGB: 0x04,
    WPP_EXT_DVI: 0x05,
    WPP_EXT_LCD: 0x06,

    WPP_FREEZE_NO: 0x00,
    WPP_FREEZE_YES: 0x01,

    WPP_EXTIN_NO: 0x00,
    WPP_EXTIN_YES: 0x01,

    WPP_PREVIEW_PREVIEW: 0x00,
    WPP_PREVIEW_REGULAR: 0x01,
    WPP_PREVIEW_TOGGLE: 0x02,

    WPP_LAMP_VOLTAGE_BRIGHT: 0x00,
    WPP_LAMP_VOLTAGE_ECONOMIC: 0x01,
    WPP_LAMP_VOLTAGE_LONGLIFE: 0x02,

    WPP_LAMP1: 0x00,
    WPP_LAMP2: 0x01,
    WPP_RESET_LAMP: 0x81,

    WPP_TIMEZONE_PACIFIC_APIA: 0x00,
    WPP_TIMEZONE_PACIFIC_HONOLULU: 0x01,
    WPP_TIMEZONE_AMERICA_ANCHORAGE: 0x02,
    WPP_TIMEZONE_AMERICA_LOS_ANGELES: 0x03,
    WPP_TIMEZONE_AMERICA_TIJUANA: 0x04,
    WPP_TIMEZONE_AMERICA_PHOENIX: 0x05,
    WPP_TIMEZONE_AMERICA_DENVER: 0x06,
    WPP_TIMEZONE_AMERICA_MAZATLAN: 0x07,
    WPP_TIMEZONE_AMERICA_CHICAGO: 0x08,
    WPP_TIMEZONE_AMERICA_REGINA: 0x09,
    WPP_TIMEZONE_AMERICA_MEXICO_CITY: 0x0A,
    WPP_TIMEZONE_AMERICA_BOGOTA: 0x0B,
    WPP_TIMEZONE_AMERICA_NEW_YORK: 0x0C,
    WPP_TIMEZONE_AMERICA_CARACAS: 0x0D,
    WPP_TIMEZONE_AMERICA_HALIFAX: 0x0E,
    WPP_TIMEZONE_AMERICA_SANTIAGO: 0x0F,
    WPP_TIMEZONE_AMERICA_LA_PAZ: 0x10,
    WPP_TIMEZONE_AMERICA_ST_JOHNS: 0x11,
    WPP_TIMEZONE_AMERICA_SAO_PAULO: 0x12,
    WPP_TIMEZONE_AMERICA_BUENOS_AIRES: 0x13,
    WPP_TIMEZONE_AMERICA_MONTEVIDEO: 0x14,
    WPP_TIMEZONE_ATLANTIC_SOUTH_GEORGIA: 0x15,
    WPP_TIMEZONE_ATLANTIC_AZORES: 0x16,
    WPP_TIMEZONE_ATLANTIC_CAPE_VERDE: 0x17,
    WPP_TIMEZONE_AFRICA_CASABLANCA: 0x18,
    WPP_TIMEZONE_UTC: 0x19,
    WPP_TIMEZONE_EUROPE_LONDON: 0x1A,
    WPP_TIMEZONE_EUROPE_BERLIN: 0x1B,
    WPP_TIMEZONE_AFRICA_KINSHASA: 0x1C,
    WPP_TIMEZONE_EUROPE_ISTANBUL: 0x1D,
    WPP_TIMEZONE_AFRICA_CAIRO: 0x1E,
    WPP_TIMEZONE_ASIA_JERUSALEM: 0x1F,
    WPP_TIMEZONE_AFRICA_JOHANNESBURG: 0x20,
    WPP_TIMEZONE_EUROPE_MOSCOW: 0x21,
    WPP_TIMEZONE_AFRICA_NAIROBI: 0x22,
    WPP_TIMEZONE_ASIA_BAGHDAD: 0x23,
    WPP_TIMEZONE_ASIA_TEHRAN: 0x24,
    WPP_TIMEZONE_ASIA_MUSCAT: 0x25,
    WPP_TIMEZONE_ASIA_BAKU: 0x26,
    WPP_TIMEZONE_ASIA_KABUL: 0x27,
    WPP_TIMEZONE_ASIA_KARACHI: 0x28,
    WPP_TIMEZONE_ASIA_KOLKATA: 0x29,
    WPP_TIMEZONE_ASIA_KATMANDU: 0x2A,
    WPP_TIMEZONE_ASIA_ALMATY: 0x2B,
    WPP_TIMEZONE_ASIA_DHAKA: 0x2C,
    WPP_TIMEZONE_ASIA_RANGOON: 0x2D,
    WPP_TIMEZONE_ASIA_BANGKOK: 0x2E,
    WPP_TIMEZONE_ASIA_HONG_KONG: 0x2F,
    WPP_TIMEZONE_ASIA_SINGAPORE: 0x30,
    WPP_TIMEZONE_AUSTRALIA_PERTH: 0x31,
    WPP_TIMEZONE_ASIA_TAIPEI: 0x32,
    WPP_TIMEZONE_ASIA_TOKYO: 0x33,
    WPP_TIMEZONE_ASIA_SEOUL: 0x34,
    WPP_TIMEZONE_AUSTRALIA_ADELAIDE: 0x35,
    WPP_TIMEZONE_AUSTRALIA_DARWIN: 0x36,
    WPP_TIMEZONE_AUSTRALIA_SYDNEY: 0x37,
    WPP_TIMEZONE_AUSTRALIA_BRISBANE: 0x38,
    WPP_TIMEZONE_PACIFIC_NOUMEA: 0x39,
    WPP_TIMEZONE_PACIFIC_AUCKLAND: 0x3A,
    WPP_TIMEZONE_PACIFIC_KWAJALEIN: 0x3B,
    WPP_TIMEZONE_EUROPE_HELSINKI: 0x3C,

    WPP_TIME_SOURCE_INTERNAL: 0x00,
    WPP_TIME_SOURCE_EXTERNAL: 0x01,

    WPP_MENU_CTRL_BACKWARD: 0x01,
    WPP_MENU_CTRL_UP: 0x02,
    WPP_MENU_CTRL_FORWARD: 0x03,
    WPP_MENU_CTRL_LEFT: 0x04,
    WPP_MENU_CTRL_ENTER: 0x05,
    WPP_MENU_CTRL_RIGHT: 0x06,
    WPP_MENU_CTRL_DOWN: 0x08,
    WPP_MENU_CTRL_HELP: 0x10,
    WPP_MENU_CTRL_FACTORY_RESET: 0x80,
    WPP_MENU_CTRL_ITEM_RESET: 0x90,

    WPP_PLAYBACK_PAUSE_RESUME: 0x91,
    WPP_PLAYBACK_FAST_FORWARD: 0x01,
    WPP_PLAYBACK_FAST_BACKWARD: 0x02,

    WPP_PLAYBACK_STATE_STOPPED: 0x00,
    WPP_PLAYBACK_STATE_PAUSED: 0x01,
    WPP_PLAYBACK_STATE_SEEK_BACKWARD: 0x02,
    WPP_PLAYBACK_STATE_SEEK_FORWARD: 0x03,
    WPP_PLAYBACK_STATE_PLAYING: 0x04,

    WPP_KEYSTONE_OFF: 0x00,
    WPP_KEYSTONE_ON: 0x01,
    WPP_KEYSTONE_TOGGLE: 0x02,

    WPP_LASER_TM16_OFF: 0x00,
    WPP_LASER_TM16_ON: 0x01,
    WPP_LASER_TM16_TOGGLE: 0x02,
    WPP_LASER_TM16_ADJUST: 0x03,

    WPP_USB_LOAD_SETTINGS_OFF: 0x00,
    WPP_USB_LOAD_SETTINGS_AUTO: 0x01,
    WPP_USB_LOAD_SETTINGS_CONFIRM: 0x02,

    WPP_NOISE_SUPPRESSION_OFF: 0x00,
    WPP_NOISE_SUPPRESSION_ON: 0x01,

    WPP_POWER_OFF_CONFIRMATION_OFF: 0x00,
    WPP_POWER_OFF_CONFIRMATION_ON: 0x01,
    WPP_POWER_OFF_CONFIRMATION_TOGGLE: 0x02,

    WPP_TIMESOURCE_INT: 0x00,
    WPP_TIMESOURCE_EXT: 0x01,

    WPP_DATEFORMAT_24H: 0x00,
    WPP_DATEFORMAT_12H: 0x01,

    WPP_IMAGE_MIRROR_OFF: 0x00,
    WPP_IMAGE_MIRROR_HORIZONTAL: 0x01,
    WPP_IMAGE_MIRROR_VERTICAL: 0x02,
    WPP_IMAGE_MIRROR_HV: 0x03,
    WPP_IMAGE_MIRROR_H_TOGGLE: 0x04,
    WPP_IMAGE_MIRROR_V_TOGGLE: 0x08,
    WPP_IMAGE_MIRROR_H_OFF: 0x10,
    WPP_IMAGE_MIRROR_V_OFF: 0x20,

    WPP_MIRROR_UP: 0x01,
    WPP_MIRROR_DOWN: 0x02,
    WPP_MIRROR_START_UP: 0x11,
    WPP_MIRROR_START_DOWN: 0x12,

    WPP_MIRROR_XY_POS_UP: 0x02,
    WPP_MIRROR_XY_POS_LEFT: 0x04,
    WPP_MIRROR_XY_POS_RIGHT: 0x06,
    WPP_MIRROR_XY_POS_DOWN: 0x08,
    WPP_MIRROR_XY_ABS_X: 0x10,
    WPP_MIRROR_XY_ABS_Y: 0x12,

    WPP_LIGHT_FOCUS_POS_NEAR: 0x01,
    WPP_LIGHT_FOCUS_POS_FAR: 0x02,
    WPP_LIGHT_FOCUS_POS_ABSOLUTE: 0x03,

    WPP_LIGHT_FOCUS_POS_STOP: 0x00,
    WPP_LIGHT_FOCUS_POS_SLOW: 0x01,
    WPP_LIGHT_FOCUS_POS_FAST: 0x02,

    WPP_ARM_UP: 0x00,
    WPP_ARM_DOWN: 0x01,
    WPP_ARM_TOGGLE: 0x02,

    WPP_PIP_OFF: 0x00,
    WPP_PIP_ON: 0x01,
    WPP_PIP_TOGGLE: 0x02,

    WPP_PIP_MODE_PIP: 0x00,
    WPP_PIP_MODE_SIDEBYSIDE: 0x01,

    WPP_PRESENTATION_OFF: 0x00,
    WPP_PRESENTATION_ON: 0x01,

    WPP_MEM_ZOOM_UP_LEFT: 0x01,
    WPP_MEM_ZOOM_UP: 0x02,
    WPP_MEM_ZOOM_UP_RIGHT: 0x03,
    WPP_MEM_ZOOM_LEFT: 0x04,
    WPP_MEM_ZOOM_RIGHT: 0x06,
    WPP_MEM_ZOOM_DOWN_LEFT: 0x07,
    WPP_MEM_ZOOM_DOWN: 0x08,
    WPP_MEM_ZOOM_DOWN_RIGHT: 0x09,

    WPP_MEM_ZOOM_STOP: 0x00,
    WPP_MEM_ZOOM_SPEED2: 0x02,
    WPP_MEM_ZOOM_SPEED5: 0x05,
    WPP_MEM_ZOOM_SPEED10: 0x0A,
    WPP_MEM_ZOOM_SPEED20: 0x14,

    WPP_SNAPSHOT_SNAPSHOT: 0x00,
    WPP_SNAPSHOT_OVERWRITE: 0x01,

    WPP_LANG_ENGLISH: 0x00,
    WPP_LANG_GERMAN: 0x01,
    WPP_LANG_TURKISH: 0x02,

    WPP_SOURCE_VZ: 0x00,
    WPP_SOURCE_MEM: 0x01,
    WPP_SOURCE_USB: 0x02,
    WPP_SOURCE_EXT: 0x03,
    WPP_SOURCE_EXT2: 0x04,
    WPP_SOURCE_NET: 0x05,

    WPP_UNICAST_STOP: 0x00,
    WPP_UNICAST_START: 0x01,

    WPP_DHCP_STATE_OFF: 0x00,
    WPP_DHCP_STATE_FAILED: 0x01,
    WPP_DHCP_STATE_TRYING: 0x02,
    WPP_DHCP_STATE_OK: 0x03,
    WPP_DHCP_STATE_UNKNOWN: 0x04,

    WPP_FEATURE_PACK_60FPS: 0x00000001,
    WPP_FEATURE_PACK_FADING: 0x00000002,
    WPP_FEATURE_PACK_USB_HOST: 0x00000004,
    WPP_FEATURE_PACK_INTERNAL_MEM: 0x00000008,
    WPP_FEATURE_PACK_IR_REMOTE: 0x00000010,
    WPP_FEATURE_PACK_PIP: 0x00000020,
    WPP_FEATURE_PACK_STREAMING: 0x00000040,
    WPP_FEATURE_PACK_WLAN: 0x00000080,
    WPP_FEATURE_PACK_NET_SOURCE: 0x00000100,
    WPP_FEATURE_PACK_DEMO: 0x00000200,

    WPP_PREVIEW_MODE_CONTENT: 0x00,
    WPP_PREVIEW_MODE_CONFIDENCE: 0x01,

    WPP_CAMERA_ASPECT_RATIO_16_9: 0x00,
    WPP_CAMERA_ASPECT_RATIO_4_3: 0x01,

    WPP_ALWAYS_LIVE_TO_USBETH_OFF: 0x00,
    WPP_ALWAYS_LIVE_TO_USBETH_ON: 0x01,

    WPP_FLANGEBACK_READY: 0x00,
    WPP_FLANGEBACK_IDLE: 0x01,
    WPP_FLANGEBACK_BUSY: 0x02,
    WPP_FLANGEBACK_ERROR: 0x03,

    WPP_PAN_LIMITS_DETECT_READY: 0x00,
    WPP_PAN_LIMITS_DETECT_IDLE: 0x01,
    WPP_PAN_LIMITS_DETECT_BUSY: 0x02,
    WPP_PAN_LIMITS_DETECT_ERROR: 0x03,

    WPP_FWSTATUS_CHECKING: 0x00,
    WPP_FWSTATUS_NOCONNECT: 0x01,
    WPP_FWSTATUS_OUTOFDATE: 0x02,
    WPP_FWSTATUS_UPTODATE: 0x03,

    WPP_FTP_INTERVAL_OFF: 0x00,
    WPP_FTP_INTERVAL_1M: 0x01,
    WPP_FTP_INTERVAL_5M: 0x02,
    WPP_FTP_INTERVAL_10M: 0x03,
    WPP_FTP_INTERVAL_15M: 0x04,
    WPP_FTP_INTERVAL_30M: 0x05,
    WPP_FTP_INTERVAL_1H: 0x06,

    WPP_FTP_TIMESTAMP_OFF: 0x00,
    WPP_FTP_TIMESTAMP_ON: 0x01,

    WPP_FTP_TRANSFER_OFF: 0x00,
    WPP_FTP_TRANSFER_DELETE: 0x01,
    WPP_FTP_TRANSFER_KEEP: 0x02,

    WPP_SUPPLY_SOURCE_NA: 0x00,
    WPP_SUPPLY_SOURCE_MAINS: 0x01,
    WPP_SUPPLY_SOURCE_LPS: 0x02,
    WPP_SUPPLY_SOURCE_POE: 0x03,
    WPP_SUPPLY_SOURCE_POEPLUS: 0x04,

    WPP_WLAN_MODE_OFF: 0x00,
    WPP_WLAN_MODE_INFRASTRUCTURE: 0x01,
    WPP_WLAN_MODE_ACCESSPOINT: 0x02,

    WPP_WLAN_ENCRYPTION_NONE: 0x00,
    WPP_WLAN_ENCRYPTION_WEP: 0x01,
    WPP_WLAN_ENCRYPTION_WPA: 0x02,
    WPP_WLAN_ENCRYPTION_WPA_ENTERPRISE: 0x03,

    WPP_WLAN_DHCP_OFF: 0x00,
    WPP_WLAN_DHCP_ON: 0x01,
    WPP_WLAN_DHCP_TOGGLE: 0x02,

    WPP_WLAN_CHANNEL_1: 0x00,
    WPP_WLAN_CHANNEL_6: 0x01,
    WPP_WLAN_CHANNEL_11: 0x02,
    WPP_WLAN_CHANNEL_36: 0x03,
    WPP_WLAN_CHANNEL_40: 0x04,
    WPP_WLAN_CHANNEL_44: 0x05,
    WPP_WLAN_CHANNEL_48: 0x06,
    WPP_WLAN_CHANNEL_149: 0x07,
    WPP_WLAN_CHANNEL_153: 0x08,
    WPP_WLAN_CHANNEL_157: 0x09,
    WPP_WLAN_CHANNEL_161: 0x0a,

    WPP_WLAN_SSID_TYPE_ESSID: 0x00,
    WPP_WLAN_SSID_TYPE_BSSID: 0x01,
    WPP_WLAN_SSID_AUTO_OFF: 0x00,
    WPP_WLAN_SSID_AUTO_ON: 0x01,
    WPP_WLAN_FREQUENCY_BAND_BOTH: 0x00,
    WPP_WLAN_FREQUENCY_BAND_24: 0x01,
    WPP_WLAN_FREQUENCY_BAND_5: 0x02,

    WPP_LECTURE_VIDEO_RECORDING_STOP: 0x00,
    WPP_LECTURE_VIDEO_RECORDING_START: 0x01,
    WPP_LECTURE_VIDEO_RECORDING_PAUSE: 0x02,

    WPP_LECTURE_VIDEO_RECORDING_STOPPED: 0x00,
    WPP_LECTURE_VIDEO_RECORDING_RECORDING: 0x01,
    WPP_LECTURE_VIDEO_RECORDING_PAUSED: 0x02,

    WPP_AUDIO_MICROPHONE_OFF: 0x00,
    WPP_AUDIO_MICROPHONE_INTERNAL: 0x01,
    WPP_AUDIO_MICROPHONE_LINEIN: 0x02,
    WPP_AUDIO_MICROPHONE_BLUETOOTH: 0x03,

    WPP_AUDIO_MUTE_OFF: 0x00,
    WPP_AUDIO_MUTE_ON: 0x01,
    WPP_AUDIO_MUTE_TOGGLE: 0x02,

    WPP_AUDIO_VOLUME_UP: 0x01,
    WPP_AUDIO_VOLUME_DOWN: 0x02,

    WPP_AUDIO_ENABLE_OFF: 0x00,
    WPP_AUDIO_ENABLE_ALLSOURCES: 0x01,
    WPP_AUDIO_ENABLE_NOMIC: 0x02,

    WPP_FTP_STRLEN: 10,
    STR_PWD_INVISIBLE: '**********',
    WPP_WLAN_PASSWORD_STRLEN: 17,
    WPP_USB_DOC_DIRECTORY_LEN: 10,
    WPP_HOSTNAME_LEN: 20,

    WPP_ANNOTATION_MOUSEDOWN: 1,
    WPP_ANNOTATION_MOUSEMOVE: 2,
    WPP_ANNOTATION_MOUSEUP: 3,

    WPP_CB_RESOLUTION_1080P: 0x00,
    WPP_CB_RESOLUTION_720P: 0x01,
    WPP_CB_RESOLUTION_540P: 0x02,
    WPP_CB_RESOLUTION_360P: 0x03,

    WPP_FRAMERATE_LOW: 0x00,
    WPP_FRAMERATE_MEDIUM: 0x01,
    WPP_FRAMERATE_HIGH: 0x02,

    WPP_CONTENT_VZ: 0x01,
    WPP_CONTENT_HDMI: 0x02,
    WPP_CONTENT_BROWSER: 0x03,
    WPP_CONTENT_MIRACAST: 0x04,
    WPP_CONTENT_VIDEO: 0x06,
    WPP_CONTENT_IMAGE: 0x08,
    WPP_CONTENT_PDF: 0x09,
    WPP_CONTENT_OFFICE_PRESENTATION: 0x0A,
    WPP_CONTENT_OFFICE_TEXT: 0x0B,
    WPP_CONTENT_OFFICE_CALC: 0x0C,
    WPP_CONTENT_WHITEBOARD: 0x0D,
    WPP_CONTENT_AUDIO: 0x0E,
    WPP_CONTENT_WEBCONFERENCE: 0x0F,
    WPP_CONTENT_WEBCAM: 0x10,
    WPP_CONTENT_STREAM_INPUT: 0x11,
    WPP_CONTENT_OFFICE365_OUTLOOK: 0x13,
    WPP_CONTENT_OFFICE365_WORD: 0x14,
    WPP_CONTENT_OFFICE365_EXCEL: 0x15,
    WPP_CONTENT_OFFICE365_POWERPOINT: 0x16,
    WPP_CONTENT_OFFICE365_ONENOTE: 0x17,
    WPP_CONTENT_OFFICE365_TEAMS: 0x1B,
    WPP_CONTENT_OFFICE365_WHITEBOARD: 0x1E,
    WPP_CONTENT_OFFICE365_PLANNER: 0x1F,
    WPP_CONTENT_FILE: 0x20,

    WPP_FRAMEBOX_CLOSE: 0x00,
    WPP_FRAMEBOX_FULLSCREEN: 0x01,
    WPP_FRAMEBOX_NORMAL: 0x02,
    WPP_FRAMEBOX_MUTE: 0x03,
    WPP_FRAMEBOX_VOLUME: 0x04,
    WPP_FRAMEBOX_AUX_ON: 0x06,
    WPP_FRAMEBOX_AUX_OFF: 0x07,
    WPP_FRAMEBOX_AUX_TOGGLE: 0x08,
    WPP_FRAMEBOX_WEBRTC_OUT_ON: 0x09,
    WPP_FRAMEBOX_WEBRTC_OUT_OFF: 0x0A,
    WPP_FRAMEBOX_WEBRTC_OUT_TOGGLE: 0x0B,

    WPP_FRAMEBOX_EVENT_KEY_HIT: 0x00,
    WPP_FRAMEBOX_EVENT_KEY_TIP: 0x01,
    WPP_FRAMEBOX_EVENT_KEY_RELEASE: 0x02,
    WPP_FRAMEBOX_EVENT_POINTER_RELATIVE: 0x03,
    WPP_FRAMEBOX_EVENT_POINTER_ABSOLUTE: 0x04,
    WPP_FRAMEBOX_EVENT_TOUCH_DOWN: 0x05,
    WPP_FRAMEBOX_EVENT_TOUCH_MOTION: 0x06,
    WPP_FRAMEBOX_EVENT_TOUCH_UP: 0x07,
    WPP_FRAMEBOX_EVENT_TOUCH_CANCEL: 0x08,

    WPP_BROWSER_URL: 0x00,
    WPP_BROWSER_RELOAD: 0x01,
    WPP_BROWSER_STOP: 0x02,
    WPP_BROWSER_ZOOM_IN: 0x03,
    WPP_BROWSER_ZOOM_OUT: 0x04,
    WPP_BROWSER_CURSOR_DOWN: 0x05,
    WPP_BROWSER_CURSOR_UP: 0x06,
    WPP_BROWSER_CURSOR_RIGHT: 0x07,
    WPP_BROWSER_CURSOR_LEFT: 0x08,
    WPP_BROWSER_BACK: 0x09,
    WPP_BROWSER_FORWARD: 0x0A,
    WPP_BROWSER_PDF_NEXT_PAGE: 0x0B,
    WPP_BROWSER_PDF_PREV_PAGE: 0x0C,
    WPP_BROWSER_PDF_SET_PAGE: 0x0D,
    WPP_BROWSER_PDF_FULL_HEIGHT: 0x0E,
    WPP_BROWSER_PDF_FULL_WIDTH: 0x0F,
    WPP_BROWSER_PDF_FULL_PAGE: 0x10,
    WPP_BROWSER_SCROLL_DOWN: 0x11,
    WPP_BROWSER_SCROLL_UP: 0x12,
    WPP_BROWSER_SCROLL_RIGHT: 0x13,
    WPP_BROWSER_SCROLL_LEFT: 0x14,
    WPP_BROWSER_STARTPAGE: 0x15,

    WPP_OFFICE365_RELOAD: 0x01,
    WPP_OFFICE365_STOP: 0x02,
    WPP_OFFICE365_ZOOM_IN: 0x03,
    WPP_OFFICE365_ZOOM_OUT: 0x04,
    WPP_OFFICE365_CURSOR_DOWN: 0x05,
    WPP_OFFICE365_CURSOR_UP: 0x06,
    WPP_OFFICE365_CURSOR_RIGHT: 0x07,
    WPP_OFFICE365_CURSOR_LEFT: 0x08,
    WPP_OFFICE365_BACK: 0x09,
    WPP_OFFICE365_FORWARD: 0x0A,
    WPP_OFFICE365_SCROLL_DOWN: 0x0B,
    WPP_OFFICE365_SCROLL_UP: 0x0C,
    WPP_OFFICE365_SCROLL_RIGHT: 0x0D,
    WPP_OFFICE365_SCROLL_LEFT: 0x0E,

    WPP_OFFICE_PATH: 0x00,
    WPP_OFFICE_ZOOM_IN: 0x01,
    WPP_OFFICE_ZOOM_OUT: 0x02,
    WPP_OFFICE_CURSOR_DOWN: 0x03,
    WPP_OFFICE_CURSOR_UP: 0x04,
    WPP_OFFICE_CURSOR_RIGHT: 0x05,
    WPP_OFFICE_CURSOR_LEFT: 0x06,
    WPP_OFFICE_NEXT_PAGE: 0x07,
    WPP_OFFICE_PREV_PAGE: 0x08,
    WPP_OFFICE_NEXT_WORKSHEET: 0x09,
    WPP_OFFICE_PREV_WORKSHEET: 0x0A,
    WPP_OFFICE_FULL_PAGE: 0x0B,
    WPP_OFFICE_FULL_WIDTH: 0x0C,
    WPP_OFFICE_SCROLL_DOWN: 0x0D,
    WPP_OFFICE_SCROLL_UP: 0x0E,
    WPP_OFFICE_SCROLL_RIGHT: 0x0F,
    WPP_OFFICE_SCROLL_LEFT: 0x10,

    WPP_CB_VZ_ZOOM_WIDE: 0x00,
    WPP_CB_VZ_ZOOM_TELE: 0x01,
    WPP_CB_VZ_FOCUS_FAR: 0x02,
    WPP_CB_VZ_FOCUS_NEAR: 0x03,
    WPP_CB_VZ_AF_ON: 0x04,
    WPP_CB_VZ_AF_OFF: 0x05,
    WPP_CB_VZ_FREEZE_ON: 0x06,
    WPP_CB_VZ_FREEZE_OFF: 0x07,
    WPP_CB_VZ_PRESET_SET: 0x08,
    WPP_CB_VZ_PRESET_RECALL: 0x09,
    WPP_CB_VZ_AF_TOGGLE: 0x0A,
    WPP_CB_VZ_FREEZE_TOGGLE: 0x0B,
    WPP_CB_VZ_POWER_ON: 0x0C,
    WPP_CB_VZ_POWER_OFF: 0x0D,
    WPP_CB_VZ_POWER_TOGGLE: 0x0E,
    WPP_CB_VZ_LIGHT_ON: 0x0F,
    WPP_CB_VZ_LIGHT_OFF: 0x10,
    WPP_CB_VZ_LIGHT_TOGGLE: 0x11,
    WPP_CB_VZ_CAS_ON: 0x12,
    WPP_CB_VZ_CAS_OFF: 0x13,
    WPP_CB_VZ_CAS_TOGGLE: 0x14,
    WPP_CB_VZ_FOCUS_ONEPUSH: 0x15,

    WPP_CB_MIRACAST_LOW_LATENCY_OFF: 0x00,
    WPP_CB_MIRACAST_LOW_LATENCY_ON: 0x01,
    WPP_CB_MIRACAST_LOW_LATENCY_TOGGLE: 0x02,

    WPP_START: 0x00,
    WPP_PAUSE: 0x01,
    WPP_STOP: 0x02,
    WPP_FORWARD: 0x03,
    WPP_REWIND: 0x04,

    WPP_IMAGE_VIEWER_PATH: 0x00,
    WPP_IMAGE_VIEWER_PREVIOUS: 0x01,
    WPP_IMAGE_VIEWER_NEXT: 0x02,
    WPP_IMAGE_VIEWER_FIRST: 0x03,
    WPP_IMAGE_VIEWER_LAST: 0x04,

    WPP_VIDEO_VIEWER_PLAY: 0x00,
    WPP_VIDEO_VIEWER_PAUSE: 0x01,
    WPP_VIDEO_VIEWER_STOP: 0x02,
    WPP_VIDEO_VIEWER_FORWARD: 0x03,
    WPP_VIDEO_VIEWER_REWIND: 0x04,
    WPP_VIDEO_VIEWER_PATH: 0x05,
    WPP_VIDEO_VIEWER_SEEK: 0x06,
    WPP_VIDEO_VIEWER_LOOP_OFF: 0x07,
    WPP_VIDEO_VIEWER_LOOP_ON: 0x08,
    WPP_VIDEO_VIEWER_LOOP_TOGGLE: 0x09,

    WPP_AUDIO_VIEWER_PLAY: 0x00,
    WPP_AUDIO_VIEWER_PAUSE: 0x01,
    WPP_AUDIO_VIEWER_STOP: 0x02,
    WPP_AUDIO_VIEWER_FORWARD: 0x03,
    WPP_AUDIO_VIEWER_REWIND: 0x04,
    WPP_AUDIO_VIEWER_PATH: 0x05,
    WPP_AUDIO_VIEWER_SEEK: 0x06,
    WPP_AUDIO_VIEWER_LOOP_OFF: 0x07,
    WPP_AUDIO_VIEWER_LOOP_ON: 0x08,
    WPP_AUDIO_VIEWER_LOOP_TOGGLE: 0x09,

    WPP_LOGIN_NONE: 0x00,
    WPP_LOGIN_USER: 0x01,
    WPP_LOGIN_ADMIN: 0x02,
    WPP_LOGIN_COLLAB: 0x03,
    WPP_LOGIN_VIEWER: 0x04,

    WPP_CLOUD_SERVICE_DROPBOX: 0x00,
    WPP_CLOUD_SERVICE_GDRIVE: 0x01,
    WPP_CLOUD_SERVICE_JIANGUOYUN: 0x03,
    WPP_CLOUD_SERVICE_ONEDRIVE: 0x04,
    WPP_CLOUD_SERVICE_WEBDAV: 0x05,

    WPP_CLOUD_STATUS_DISCONNECTED: 0x00,
    WPP_CLOUD_STATUS_OAUTH: 0x01,
    WPP_CLOUD_STATUS_CONNECTED: 0x02,
    WPP_CLOUD_STATUS_FAILED: 0x03,
    WPP_CLOUD_STATUS_SYNCED: 0x04,
    WPP_CLOUD_STATUS_DISABLED: 0x05,
    WPP_CLOUD_STATUS_NOT_RESPONDING: 0x06,

    WPP_CLOUD_WEBDAV_CREDENTIALS_JIANGUOYUN: 0x00,
    WPP_CLOUD_WEBDAV_CREDENTIALS_WEBDAV: 0x02,

    WPP_FILE_PATH: 0x00,
    WPP_ZOOM_IN: 0x01,
    WPP_ZOOM_OUT: 0x02,
    WPP_MOVE_DOWN: 0x03,
    WPP_MOVE_UP: 0x04,
    WPP_MOVE_RIGHT: 0x05,
    WPP_MOVE_LEFT: 0x06,
    WPP_PDF_NEXT_PAGE: 0x07,
    WPP_PDF_PREV_PAGE: 0x08,
    WPP_PDF_SET_PAGE: 0x09,
    WPP_PDF_FULL_HEIGHT: 0x0A,
    WPP_PDF_FULL_WIDTH: 0x0B,
    WPP_PDF_FULL_PAGE: 0x0C,
    WPP_PDF_SCROLL_DOWN: 0x0D,
    WPP_PDF_SCROLL_UP: 0x0E,
    WPP_PDF_SCROLL_RIGHT: 0x0F,
    WPP_PDF_SCROLL_LEFT: 0x10,

    WPP_CB_MAIN_RESOLUTION_AUTO: 0x00,
    WPP_CB_MAIN_RESOLUTION_1080P60: 0x01,
    WPP_CB_MAIN_RESOLUTION_2160P30: 0x02,
    WPP_CB_MAIN_RESOLUTION_2160P60: 0x03,
    WPP_CB_MAIN_RESOLUTION_1080P30: 0x04,
    WPP_CB_MAIN_RESOLUTION_720P60: 0x05,

    WPP_CB_PASSWORD_NOT_REQUIRED: 0x00,
    WPP_CB_PASSWORD_REQUIRED: 0x01,

    WPP_PASSWORD_TYPE_PASSWORD: 0x00,
    WPP_PASSWORD_TYPE_PIN: 0x01,

    WPP_ADMIN_PIN_DISABLED: 0x00,
    WPP_ADMIN_PIN_ENABLED: 0x01,

    WPP_AUX_MODE_CONFIDENCE_MIRROR: 0x00,
    WPP_AUX_MODE_CONFIDENCE_FULLHD: 0x01,
    WPP_AUX_MODE_CONFIDENCE_HD: 0x02,
    WPP_AUX_MODE_CONTENT_FULLHD: 0x03,
    WPP_AUX_MODE_CONTENT_HD: 0x04,
    WPP_AUX_MODE_MODERATOR_FULLHD: 0x05,
    WPP_AUX_MODE_MODERATOR_HD: 0x06,

    WPP_STREAMING_INTERFACE_LAN1: 0x00,
    WPP_STREAMING_INTERFACE_WLAN: 0x01,
    WPP_STREAMING_INTERFACE_LAN2: 0x02,
    WPP_STREAMING_FUNCTION_DISABLE: 0x00,
    WPP_STREAMING_FUNCTION_ENABLE: 0x01,
    WPP_STREAMING_BITRATE_MODE_CONSTANT: 0x00,
    WPP_STREAMING_BITRATE_MODE_VARIABLE: 0x01,

    WPP_RECORDING_FUNCTION_DISABLE: 0x00,
    WPP_RECORDING_FUNCTION_ENABLE: 0x01,

    WPP_WLAN_REGION_US: 0x00,
    WPP_WLAN_REGION_OTHERS: 0x01,

    WPP_WLAN_AP_SCAN_STATUS_SEARCHING: 0x00,
    WPP_WLAN_AP_SCAN_STATUS_FAILED: 0x01,
    WPP_WLAN_AP_SCAN_STATUS_SUCCESS: 0x02,

    WPP_STATUSBAR_SETTINGS_NONE: 0x00,
    WPP_STATUSBAR_SETTINGS_LAN: 0x01,
    WPP_STATUSBAR_SETTINGS_WLAN: 0x02,
    WPP_STATUSBAR_SETTINGS_BOTH: 0x03,

    WPP_CB_FTP_STATUS_TESTING: 0x00,
    WPP_CB_FTP_STATUS_FAILED: 0x01,
    WPP_CB_FTP_STATUS_SUCCESS: 0x02,

    WPP_CB_VZ_CONNECTED: 0x01,
    WPP_CB_VZ_DISCONNECTED: 0x00,

    WPP_CB_VZ_MODE_NONE: 0x00,
    WPP_CB_VZ_MODE_BASIC: 0x01,
    WPP_CB_VZ_MODE_FULL: 0x02,
    WPP_CB_VZ_MODE_BASIC_FIRMWARE: 0x03,
    WPP_CB_VZ_MODE_SECURITY: 0x04,

    WPP_CONTENT_TYPE_WINDOW: 0x00,
    WPP_CONTENT_TYPE_OUTPUT: 0x01,

    WPP_FRAMEBOX_CONTENT_NONE: 0x00,
    WPP_FRAMEBOX_CONTENT_VZ: 0x01,
    WPP_FRAMEBOX_CONTENT_HDMI: 0x02,
    WPP_FRAMEBOX_CONTENT_BROWSER: 0x03,
    WPP_FRAMEBOX_CONTENT_MIRACAST: 0x04,
    WPP_FRAMEBOX_CONTENT_AIRPLAY: 0x05,
    WPP_FRAMEBOX_CONTENT_VIDEO: 0x06,
    WPP_FRAMEBOX_CONTENT_VSOLUTION: 0x07,
    WPP_FRAMEBOX_CONTENT_IMAGE: 0x08,
    WPP_FRAMEBOX_CONTENT_PDF: 0x09,
    WPP_FRAMEBOX_CONTENT_PRESENTATION: 0x0A,
    WPP_FRAMEBOX_CONTENT_TEXT: 0x0B,
    WPP_FRAMEBOX_CONTENT_CALC: 0x0C,
    WPP_FRAMEBOX_CONTENT_WHITEBOARD: 0x0D,
    WPP_FRAMEBOX_CONTENT_AUDIO: 0x0E,
    WPP_FRAMEBOX_CONTENT_WEBCONFERENCE: 0x0F,
    WPP_FRAMEBOX_CONTENT_WEBCAM: 0x10,
    WPP_FRAMEBOX_CONTENT_STREAM_INPUT: 0x11,
    WPP_FRAMEBOX_CONTENT_OFFICE365_OUTLOOK: 0x13,
    WPP_FRAMEBOX_CONTENT_OFFICE365_WORD: 0x14,
    WPP_FRAMEBOX_CONTENT_OFFICE365_EXCEL: 0x15,
    WPP_FRAMEBOX_CONTENT_OFFICE365_POWERPOINT: 0x16,
    WPP_FRAMEBOX_CONTENT_OFFICE365_ONENOTE: 0x17,
    WPP_FRAMEBOX_CONTENT_MATRIX: 0x18,
    WPP_FRAMEBOX_CONTENT_MATRIX_CONTROL_SWITCH: 0x1A,
    WPP_FRAMEBOX_CONTENT_OFFICE365_TEAMS: 0x1B,
    WPP_FRAMEBOX_CONTENT_MATRIX_GROUPWORK: 0x1C,
    WPP_FRAMEBOX_CONTENT_ZOOM: 0x1D,
    WPP_FRAMEBOX_CONTENT_OFFICE365_WHITEBOARD: 0x1E,
    WPP_FRAMEBOX_CONTENT_OFFICE365_PLANNER: 0x1F,
    WPP_FRAMEBOX_CONTENT_OTHERS: 0xFF,

    WPP_HDMI_INPUT1: 0x00,
    WPP_HDMI_INPUT2: 0x01,
    WPP_HDMI_INPUT_IS_VZ: 0x00,
    WPP_HDMI_INPUT_IS_HDMI: 0x01,
    WPP_HDMI_INPUT_IS_COMPUTER: 0x02,
    WPP_HDMI_INPUT_IS_DISC_PLAYER: 0x03,
    WPP_HDMI_INPUT_IS_CAMERA: 0x04,

    WPP_UVC_INPUT_NONE: 0x00,
    WPP_UVC_INPUT_CAMERA: 0x01,
    WPP_UVC_INPUT_VZ: 0x02,
    WPP_UVC_INPUT_HDMI: 0x03,
    WPP_UVC_INPUT_COMPUTER: 0x04,
    WPP_UVC_INPUT_DISCPLAYER: 0x05,
    WPP_UVC_INPUT_AUTOSTART_NONE: 0x00,
    WPP_UVC_INPUT_AUTOSTART_OFF: 0x01,
    WPP_UVC_INPUT_AUTOSTART_ON: 0x02,

    WPP_WWW_UPDATE_CHECK: 0x00,
    WPP_WWW_UPDATE_PERFORM: 0x01,
    WPP_WWW_UPDATE_CANCEL: 0x02,

    WPP_WWW_UPDATE_STATUS_IDLE: 0x00,
    WPP_WWW_UPDATE_STATUS_FAILED: 0x01,
    WPP_WWW_UPDATE_STATUS_NO_CONNECTION: 0x02,
    WPP_WWW_UPDATE_STATUS_CHECKING: 0x03,
    WPP_WWW_UPDATE_STATUS_UPTODATE: 0x04,
    WPP_WWW_UPDATE_STATUS_OUTOFDATE: 0x05,
    WPP_WWW_UPDATE_STATUS_DOWNLOADING: 0x06,
    WPP_WWW_UPDATE_STATUS_UPDATING: 0x07,

    WPP_RAUC_SOURCE_STATUS_NOT_FOUND: 0x00,
    WPP_RAUC_SOURCE_STATUS_FOUND: 0x01,
    WPP_RAUC_SOURCE_STATUS_BUSY: 0x02,

    WPP_RAUC_UPDATE_STATUS_IDLE: 0x00,
    WPP_RAUC_UPDATE_STATUS_UPDATING: 0x01,
    WPP_RAUC_UPDATE_STATUS_FINISHED: 0x02,
    WPP_RAUC_UPDATE_STATUS_FAILED: 0x03,
    WPP_RAUC_UPDATE_STATUS_DOWNLOADING: 0x04,

    WPP_CLOUD_PRELOAD_ABORT: 0x00,
    WPP_CLOUD_PRELOAD_START: 0x01,
    WPP_CLOUD_PRELOAD_START_AND_OPEN: 0x02,

    WPP_HDMI_INPUT_1: 0x00,
    WPP_HDMI_INPUT_2: 0x01,

    WPP_EULA_ACCEPT: 0x00,
    WPP_EULA_NOT_ACCEPTED: 0x01,

    WPP_STORAGE_SPACE_UNIT_MB: 0x00,

    WPP_PICTURE_MAIN: 0x00,
    WPP_PICTURE_HDMI1: 0x01,
    WPP_PICTURE_HDMI2: 0x02,
    WPP_PICTURE_MAIN_OSD: 0x03,
    WPP_PICTURE_VZ: 0x04,
    WPP_PICTURE_EDIT: 0x07,
    WPP_PICTURE_HDMI2_OUT: 0x08,

    WPP_BROWSER_INPUT_NOTEXT: 0x00,
    WPP_BROWSER_INPUT_TEXT: 0x01,
    WPP_BROWSER_INPUT_TEXTAREA: 0x02,
    WPP_BROWSER_INPUT_PASSWORD: 0x03,
    WPP_BROWSER_INPUT_NUMBER: 0x04,
    WPP_BROWSER_INPUT_URL: 0x05,
    WPP_BROWSER_INPUT_DATE: 0x06,
    WPP_BROWSER_INPUT_MONTH: 0x07,
    WPP_BROWSER_INPUT_WEEK: 0x08,
    WPP_BROWSER_INPUT_TIME: 0x09,
    WPP_BROWSER_INPUT_DATETIME: 0x0A,
    WPP_BROWSER_INPUT_DATETIME_LOCAL: 0x0B,
    WPP_BROWSER_INPUT_EMAIL: 0x0C,
    WPP_BROWSER_INPUT_SEARCH: 0x0D,
    WPP_BROWSER_INPUT_TEL: 0x0E,
    WPP_BROWSER_INPUT_UNKNOWN: 0xFF,

    WPP_USER_DATA_MISSING: 0x00,
    WPP_USER_DATA_AVAILABLE: 0x01,
    WPP_USER_DATA_LOADED: 0x02,
    WPP_USER_DATA_REJECTED: 0x03,

    WPP_CONTENT_SOURCES_NOT_PRESENT: 0x00,
    WPP_CONTENT_SOURCES_PRESENT: 0x01,

    WPP_CONTENT_SOURCES_TYPE_HDMI: 0x00,
    WPP_CONTENT_SOURCES_TYPE_VZ: 0x01,
    WPP_CONTENT_SOURCES_TYPE_DISC: 0x02,
    WPP_CONTENT_SOURCES_TYPE_COMPUTER: 0x03,
    WPP_CONTENT_SOURCES_TYPE_BROWSER: 0x04,
    WPP_CONTENT_SOURCES_TYPE_LOCAL_FILES: 0x05,
    WPP_CONTENT_SOURCES_TYPE_CLOUD: 0x06,
    WPP_CONTENT_SOURCES_TYPE_MIRROR: 0x07,
    WPP_CONTENT_SOURCES_TYPE_WHITEBOARD: 0x08,
    WPP_CONTENT_SOURCES_TYPE_WEBCONFERENCE: 0x09,
    WPP_CONTENT_SOURCES_TYPE_WEBCAM: 0x0A,
    WPP_CONTENT_SOURCES_TYPE_STREAM_INPUT: 0x0B,
    WPP_CONTENT_SOURCES_TYPE_OFFICE365: 0x0C,
    WPP_CONTENT_SOURCES_TYPE_CYNAP: 0x0D,
    WPP_CONTENT_SOURCES_TYPE_ZOOM: 0x0E,
    WPP_CONTENT_SOURCES_TYPE_OFFICE365_TEAMS: 0x0F,
    WPP_CONTENT_SOURCES_TYPE_STREAM_INPUT_LIST: 0x10,
    WPP_CONTENT_SOURCES_TYPE_CAMERA: 0x11,
    WPP_CONTENT_SOURCES_TYPE_BROWSER_SHORTCUT: 0x12,
    WPP_CONTENT_SOURCES_TYPE_UNDEFINED: 0xFF,

    WPP_CB_CA_TIME_MODE_AUTO: 0x00,
    WPP_CB_CA_TIME_MODE_MANUAL: 0x01,

    WPP_CB_CA_DISABLE: 0x00,
    WPP_CB_CA_ENABLE: 0x01,

    WPP_CB_CA_STATE_DISABLE: 0x00,
    WPP_CB_CA_STATE_IDLE: 0x01,
    WPP_CB_CA_STATE_RECORDING: 0x02,
    WPP_CB_CA_STATE_FAIL: 0x03,
    WPP_CB_CA_STATE_FAIL_SERVICE: 0x04,
    WPP_CB_CA_STATE_FAIL_DISK_FULL: 0x05,
    WPP_CB_CA_STATE_FAIL_SSL: 0x06,

    WPP_CB_RECORDING_DISABLE: 0x00,
    WPP_CB_RECORDING_ENABLE: 0x01,

    WPP_CB_RECORDING_FRAMERATE_LOW: 0x00,
    WPP_CB_RECORDING_FRAMERATE_MEDIUM: 0x01,
    WPP_CB_RECORDING_FRAMERATE_HIGH: 0x02,

    WPP_CB_RECORDING_TRIGGER_NONE: 0x00,
    WPP_CB_RECORDING_TRIGGER_MANUAL: 0x01,
    WPP_CB_RECORDING_TRIGGER_OPENCAST: 0x02,
    WPP_CB_RECORDING_TRIGGER_PANOPTO: 0x03,
    WPP_CB_RECORDING_TRIGGER_PANOPTO_AUTO: 0x04,

    WPP_CB_ANNOTATION_PAINT_FINISHED: 0x00,
    WPP_CB_ANNOTATION_PAINT_CONTINUED: 0x01,

    WPP_CB_ANNOTATION_ACTION_REDO: 0x00,
    WPP_CB_ANNOTATION_ACTION_UNDO: 0x01,
    WPP_CB_ANNOTATION_ACTION_CLEAN: 0x02,
    WPP_CB_ANNOTATION_ACTION_CLEAN_ALL: 0x03,
    WPP_CB_ANNOTATION_ACTION_ENABLE_ANNOTATION: 0x04,
    WPP_CB_ANNOTATION_ACTION_STOP: 0x05,
    WPP_CB_ANNOTATION_ACTION_SNAPSHOT: 0x06,
    WPP_CB_ANNOTATION_ACTION_CONNECT: 0x07,
    WPP_CB_ANNOTATION_ACTION_DISCONNECT: 0x08,
    WPP_CB_ANNOTATION_ACTION_DISCONNECT_ALL_USERS: 0x09,
    WPP_CB_ANNOTATION_ACTION_NICKNAME: 0x0A,
    WPP_CB_ANNOTATION_ACTION_PAUSE: 0x0B,
    WPP_CB_ANNOTATION_ACTION_FINAL_SNAPSHOT: 0x0D,

    WPP_CB_ANNOTATION_SETTINGS_PEN: 0x00,
    WPP_CB_ANNOTATION_SETTINGS_LINE: 0x01,
    WPP_CB_ANNOTATION_SETTINGS_CIRCLE: 0x02,
    WPP_CB_ANNOTATION_SETTINGS_RECTANGLE: 0x03,
    WPP_CB_ANNOTATION_SETTINGS_RUBBER: 0x04,

    WPP_CB_ANNOTATION_CLEAR_ALL_DISABLE: 0x00,
    WPP_CB_ANNOTATION_CLEAR_ALL_ENABLE: 0x01,

    WPP_CB_ANNOTATION_DISABLED: 0x00,
    WPP_CB_ANNOTATION_ENABLED: 0x01,
    WPP_CB_ANNOTATION_PAUSED: 0x02,
    WPP_CB_ANNOTATION_MATRIX: 0x03,

    WPP_CB_MATRIX_MASTER_ANNOTATION_DISABLED: 0x00,
    WPP_CB_MATRIX_MASTER_ANNOTATION_ENABLED: 0x01,
    WPP_CB_MATRIX_MASTER_ANNOTATION_PAUSED: 0x02,

    WPP_CLOUD_UPLOAD_START: 0x01,
    WPP_CLOUD_UPLOAD_ABORT: 0x00,

    WPP_CB_DISABLED: 0x00,
    WPP_CB_ENABLED: 0x01,

    WPP_OSD_MESSAGE_ICON_NONE: 0x00,
    WPP_OSD_MESSAGE_ICON_INFO: 0x01,
    WPP_OSD_MESSAGE_ICON_WARNING: 0x02,
    WPP_OSD_MESSAGE_ICON_ERROR: 0x03,
    WPP_OSD_MESSAGE_ICON_MATRIX: 0x04,
    WPP_OSD_MESSAGE_ICON_VMEETING: 0x05,
    WPP_OSD_MESSAGE_ICON_GROUPWORK: 0x06,

    WPP_CB_NETDRIVE_TYPE_NONE: 0x00,
    WPP_CB_NETDRIVE_TYPE_CIFS: 0x01,
    WPP_CB_NETDRIVE_TYPE_NFS: 0x02,

    WPP_CB_NETDRIVE_ACCESS_MODE_READONLY: 0x00,
    WPP_CB_NETDRIVE_ACCESS_MODE_READ_WRITE: 0x01,

    WPP_CB_FEATURE_STATUS_NONE: 0x00,
    WPP_CB_FEATURE_STATUS_FOUND: 0x01,

    WPP_CB_FEATURE_ONLINE_STATUS_NONE: 0x00,
    WPP_CB_FEATURE_ONLINE_STATUS_FOUND: 0x01,
    WPP_CB_FEATURE_ONLINE_STATUS_CHECKING: 0x02,
    WPP_CB_FEATURE_ONLINE_STATUS_FAILED: 0x03,

    WPP_CB_SUPPORT_STATUS_NONE: 0x00,
    WPP_CB_SUPPORT_STATUS_FOUND: 0x01,

    WPP_CB_SUPPORT_ONLINE_STATUS_NONE: 0x00,
    WPP_CB_SUPPORT_ONLINE_STATUS_FOUND: 0x01,
    WPP_CB_SUPPORT_ONLINE_STATUS_CHECKING: 0x02,
    WPP_CB_SUPPORT_ONLINE_STATUS_FAILED: 0x03,

    WPP_CB_CONTENT_PRIORITY_NONE: 0x00,
    WPP_CB_CONTENT_PRIORITY_VISUALIZER: 0x01,
    WPP_CB_CONTENT_PRIORITY_HDMI_INPUT: 0x02,
    WPP_CB_CONTENT_PRIORITY_BROWSER: 0x03,
    WPP_CB_CONTENT_PRIORITY_MIRACAST: 0x04,
    WPP_CB_CONTENT_PRIORITY_AIRPLAY: 0x05,
    WPP_CB_CONTENT_PRIORITY_VIDEO: 0x06,
    WPP_CB_CONTENT_PRIORITY_VSOLUTIONCAST: 0x07,
    WPP_CB_CONTENT_PRIORITY_IMAGE: 0x08,
    WPP_CB_CONTENT_PRIORITY_PDF: 0x09,
    WPP_CB_CONTENT_PRIORITY_OFFICE_PRESENTATION: 0x0A,
    WPP_CB_CONTENT_PRIORITY_OFFICE_TEXT: 0x0B,
    WPP_CB_CONTENT_PRIORITY_OFFICE_CALC: 0x0C,
    WPP_CB_CONTENT_PRIORITY_WHITEBOARD: 0x0D,
    WPP_CB_CONTENT_PRIORITY_AUDIO: 0x0E,
    WPP_CB_CONTENT_PRIORITY_WEBCONFERENCE: 0x0F,
    WPP_CB_CONTENT_PRIORITY_WEBCAM: 0x10,
    WPP_CB_CONTENT_PRIORITY_STREAM_INPUT: 0x11,
    WPP_CB_CONTENT_PRIORITY_OFFICE365_OUTLOOK: 0x13,
    WPP_CB_CONTENT_PRIORITY_OFFICE365_WORD: 0x14,
    WPP_CB_CONTENT_PRIORITY_OFFICE365_EXCEL: 0x15,
    WPP_CB_CONTENT_PRIORITY_OFFICE365_POWER_POINT: 0x16,
    WPP_CB_CONTENT_PRIORITY_ONE_NOTE: 0x17,
    WPP_CB_CONTENT_PRIORITY_MATRIX: 0x18,
    WPP_CB_CONTENT_PRIORITY_OFFICE365_TEAMS: 0x1B,
    WPP_CB_CONTENT_PRIORITY_ZOOM: 0x1D,
    WPP_CB_CONTENT_PRIORITY_HDMI1_INPUT: 0x21,
    WPP_CB_CONTENT_PRIORITY_HDMI2_INPUT: 0x22,

    WPP_CB_FILE_UPLOAD_MODE_ABORT: 0x00,
    WPP_CB_FILE_UPLOAD_MODE_START: 0x01,
    WPP_CB_FILE_UPLOAD_MODE_ABORT_INDEX: 0x02,
    WPP_CB_FILE_UPLOAD_MODE_RESTART_INDEX: 0x03,

    WPP_CB_WLAN_AUTH_METHOD_PEAP_MSCHAPV2: 0x00,
    WPP_CB_WLAN_AUTH_METHOD_TTLS_PAP: 0x01,

    WPP_CB_LAN_AUTH_METHOD_PEAP_MSCHAPV2: 0x00,
    WPP_CB_LAN_AUTH_METHOD_TTLS_PAP: 0x01,

    WPP_CB_CERTIFICATE_FILE_DELETE: 0x00,
    WPP_CB_CERTIFICATE_FILE_SAVE: 0x01,
    WPP_CB_CERTIFICATE_FILE_VERIFY: 0x02,

    WPP_CB_LOCAL_SCREENSAVER_DELETE: 0x00,
    WPP_CB_LOCAL_SCREENSAVER_SAVE: 0x01,

    WPP_WLAN_STATUS_DISCONNECTED_NOTRUNNING: 0x00,
    WPP_WLAN_STATUS_CONNECTED_RUNNING: 0x01,
    WPP_WLAN_STATUS_SCANNING: 0x02,
    WPP_WLAN_STATUS_AUTHENTICATING: 0x03,
    WPP_WLAN_STATUS_FAILED: 0x04,

    WPP_LAN_STATUS_DISCONNECTED: 0x00,
    WPP_LAN_STATUS_CONNECTED: 0x01,
    WPP_LAN_STATUS_SCANNING: 0x02,
    WPP_LAN_STATUS_AUTHENTICATING: 0x03,
    WPP_LAN_STATUS_FAILED: 0x04,

    WPP_CB_OPENCAST_REC_INTERN: 0x00,
    WPP_CB_OPENCAST_REC_EXTERN: 0x01,

    WPP_CB_MIC_STATUS_DISABLED: 0x00,
    WPP_CB_MIC_STATUS_WORKING: 0x01,
    WPP_CB_MIC_STATUS_QUIET: 0x02,

    WPP_CB_USER_SETTINGS_USB: 0x04,
    WPP_CB_USER_SETTINGS_INTERNAL: 0x05,

    WPP_CB_WEBCAST_MODE_OFF: 0x00,
    WPP_CB_WEBCAST_MODE_CUSTOM: 0x01,
    WPP_CB_WEBCAST_MODE_YOUTUBE: 0x04,
    WPP_CB_WEBCAST_MODE_CUSTOM2: 0x05,

    WPP_CB_WEBCAST_PROTOCOL_NONE: 0x00,
    WPP_CB_WEBCAST_PROTOCOL_CUSTOM: 0x01,
    WPP_CB_WEBCAST_PROTOCOL_YOUTUBE: 0x04,
    WPP_CB_WEBCAST_PROTOCOL_LCS: 0x06,

    WPP_CB_WEBCAST_YOUTUBE_LOGIN_STATE_DISCONNECTED: 0x00,
    WPP_CB_WEBCAST_YOUTUBE_LOGIN_STATE_AUTHENTICATING: 0x01,
    WPP_CB_WEBCAST_YOUTUBE_LOGIN_STATE_AUTHENTICATED: 0x02,
    WPP_CB_WEBCAST_YOUTUBE_LOGIN_STATE_TOKEN_EXPIRED: 0x03,
    WPP_CB_WEBCAST_YOUTUBE_LOGIN_STATE_FAILED: 0x04,

    WPP_CB_WEBCAST_YOUTUBE_STREAM_STATE_IDLE: 0x00,
    WPP_CB_WEBCAST_YOUTUBE_STREAM_STATE_READY: 0x01,
    WPP_CB_WEBCAST_YOUTUBE_STREAM_STATE_TEST_STARTING: 0x02,
    WPP_CB_WEBCAST_YOUTUBE_STREAM_STATE_LIVE_STARTING: 0x03,
    WPP_CB_WEBCAST_YOUTUBE_STREAM_STATE_LIVE: 0x04,
    WPP_CB_WEBCAST_YOUTUBE_STREAM_STATE_COMPLETE: 0x05,
    WPP_CB_WEBCAST_YOUTUBE_STREAM_STATE_ERROR: 0x06,

    WPP_CB_WEBCAST_STATE_DISABLED: 0x00,
    WPP_CB_WEBCAST_STATE_READY: 0x01,
    WPP_CB_WEBCAST_STATE_ACTIVE: 0x02,
    WPP_CB_WEBCAST_STATE_ERROR: 0x03,

    WPP_CB_WEBCAST_EXTERNAL_TYPE_HDMI_IN: 0x00,

    WPP_CB_RMS_CLIENT_EVENT_NONE: 0x00,
    WPP_CB_RMS_CLIENT_EVENT_POWER_ON: 0x01,
    WPP_CB_RMS_CLIENT_EVENT_POWER_OFF: 0x02,
    WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_1: 0x03,
    WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_2: 0x04,
    WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_3: 0x05,
    WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_4: 0x06,
    WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_5: 0x07,
    WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_6: 0x08,
    WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_7: 0x09,
    WPP_CB_RMS_CLIENT_EVENT_CUSTOM_BUTTON_8: 0x0A,
    WPP_CB_RMS_CLIENT_EVENT_SCREEN_ON: 0x0B,
    WPP_CB_RMS_CLIENT_EVENT_SCREEN_OFF: 0x0C,

    WPP_CB_RMS_CLIENT_WOLFPROT_CMD_NONE: 0x00,
    WPP_CB_RMS_CLIENT_WOLFPROT_CMD_MIRRORING_PERM_ALLOW: 0x01,

    WPP_CB_RMS_CLIENT_PROT_TCP: 0x00,
    WPP_CB_RMS_CLIENT_PROT_UDP: 0x01,
    WPP_CB_RMS_CLIENT_PROT_PJLINK: 0x02,
    WPP_CB_RMS_CLIENT_PROT_WOLFPROT: 0x03,

    WPP_CB_STREAM_INPUT_NONE: 0x00,
    WPP_CB_STREAM_INPUT_GENERIC: 0x01,
    WPP_CB_STREAM_INPUT_RTSP_RTP: 0x02,
    WPP_CB_STREAM_INPUT_RTSP_RTP_MULTICAST: 0x03,

    WPP_CB_MATRIX_NETDRIVE_STATUS_DISABLED: 0x00,
    WPP_CB_MATRIX_NETDRIVE_STATUS_NOTMOUNTED: 0x01,
    WPP_CB_MATRIX_NETDRIVE_STATUS_AUTHENTICATE: 0x02,
    WPP_CB_MATRIX_NETDRIVE_STATUS_BUSY: 0x03,
    WPP_CB_MATRIX_NETDRIVE_STATUS_NOTRESPONDING: 0x04,
    WPP_CB_MATRIX_NETDRIVE_STATUS_MOUNTED_READ_ONLY: 0x05,
    WPP_CB_MATRIX_NETDRIVE_STATUS_MOUNTED_WRITE_ONLY: 0x06,
    WPP_CB_MATRIX_NETDRIVE_STATUS_MOUNTED_READ_WRITE: 0x07,
    WPP_CB_MATRIX_NETDRIVE_STATUS_MOUNTED_FAILED: 0x08,

    WPP_CB_MATRIX_COMPABILITY_OK: 0x00,
    WPP_CB_MATRIX_COMPABILITY_LEGACY_STATION: 0x01,
    WPP_CB_MATRIX_COMPABILITY_OUTDATED_STATION: 0x02,
    WPP_CB_MATRIX_COMPABILITY_OUTDATED_MASTER: 0x03,

    WPP_CB_MATRIX_GROUPWORK_REQ_STATION_PULL_STOP: 0x00,
    WPP_CB_MATRIX_GROUPWORK_REQ_STATION_PULL_START: 0x01,

    WPP_CB_MATRIX_GROUPWORK_REQ_STATION_PULL_ANSWER_REJECT: 0x00,
    WPP_CB_MATRIX_GROUPWORK_REQ_STATION_PULL_ANSWER_ACCEPT: 0x01,

    WPP_CB_MATRIX_GROUPWORK_SENT_REQ_STATUS_REJECTED: 0x00,
    WPP_CB_MATRIX_GROUPWORK_SENT_REQ_STATUS_WAITING: 0x01,
    WPP_CB_MATRIX_GROUPWORK_SENT_REQ_STATUS_TIMEOUT: 0x02,
    WPP_CB_MATRIX_GROUPWORK_SENT_REQ_STATUS_ACCEPTED: 0x03,
    WPP_CB_MATRIX_GROUPWORK_RECEIVED_REQ_STATUS_NOT_WAITING: 0x00,
    WPP_CB_MATRIX_GROUPWORK_RECEIVED_REQ_STATUS_WAITING: 0x01,

    WPP_CB_MATRIX_PUSH_HDMI1: 0xFF,
    WPP_CB_MATRIX_PUSH_HDMI2: 0xFC,

    WPP_CB_LAN2_MODE_VZ: 0x00,
    WPP_CB_LAN2_MODE_LAN: 0x01,

    WPP_STREAM_INPUT_TYPE_STREAM: 0x00,
    WPP_STREAM_INPUT_TYPE_CAMERA: 0x01,
    WPP_STREAM_INPUT_TYPE_COMPUTER: 0x02,
    WPP_STREAM_INPUT_TYPE_VISUALIZER: 0x03,
    WPP_STREAM_INPUT_TYPE_HDMI: 0x04,
    WPP_STREAM_INPUT_TYPE_DISCPLAYER: 0x05,
    WPP_STREAM_INPUT_TYPE_CYNAP: 0x06,

    WPP_CB_RECORDING_STREAM_INPUT_HEALTH_NONE: 0x00,
    WPP_CB_RECORDING_STREAM_INPUT_HEALTH_OK: 0x01,
    WPP_CB_RECORDING_STREAM_INPUT_HEALTH_ERROR: 0x02,

    WPP_CB_SYSTEM_LANGUAGE_ENGLISH: 0x00,
    WPP_CB_SYSTEM_LANGUAGE_GERMAN: 0x01,
    WPP_CB_SYSTEM_LANGUAGE_JAPANESE: 0x02,
    WPP_CB_SYSTEM_LANGUAGE_CHINESE: 0x03,
    WPP_CB_SYSTEM_LANGUAGE_FRENCH: 0x04,
    WPP_CB_SYSTEM_LANGUAGE_NORWEGIAN: 0x05,
    WPP_CB_SYSTEM_LANGUAGE_RUSSIAN: 0x06,

    WPP_CB_MIRACAST_CLIENT: 0x00,
    WPP_CB_MIRACAST_GO: 0x01,

    WPP_CB_MICE_INTERFACE_ALL: 0x00,
    WPP_CB_MICE_INTERFACE_LAN1: 0x01,
    WPP_CB_MICE_INTERFACE_LAN2: 0x02,
    WPP_CB_MICE_INTERFACE_WLAN: 0x03,

    WPP_CB_AIRPLAY_INTERFACE_LAN1: 0x00,
    WPP_CB_AIRPLAY_INTERFACE_WLAN: 0x01,
    WPP_CB_AIRPLAY_INTERFACE_LAN2: 0x02,

    WPP_CB_USB_FW_NO_FILE_FOUND: 0x00,
    WPP_CB_USB_FW_FILE_FOUND: 0x01,

    WPP_CB_FRAMEBOX_INFO_UVC_TYPE_HDMI: 0x00,
    WPP_CB_FRAMEBOX_INFO_UVC_TYPE_VZ: 0x01,
    WPP_CB_FRAMEBOX_INFO_UVC_TYPE_DISC_PLAYER: 0x02,
    WPP_CB_FRAMEBOX_INFO_UVC_TYPE_COMPUTER: 0x03,
    WPP_CB_FRAMEBOX_INFO_UVC_TYPE_CAMERA: 0x0A,

    WPP_CB_FRAMEBOX_INFO_STREAM_INPUT_TYPE_HDMI: 0x00,
    WPP_CB_FRAMEBOX_INFO_STREAM_INPUT_TYPE_VZ: 0x01,
    WPP_CB_FRAMEBOX_INFO_STREAM_INPUT_TYPE_DISC_PLAYER: 0x02,
    WPP_CB_FRAMEBOX_INFO_STREAM_INPUT_TYPE_COMPUTER: 0x03,
    WPP_CB_FRAMEBOX_INFO_STREAM_INPUT_TYPE_CAMERA: 0x0A,
    WPP_CB_FRAMEBOX_INFO_STREAM_INPUT_TYPE_STREAM: 0x0B,
    WPP_CB_FRAMEBOX_INFO_STREAM_INPUT_TYPE_CYNAP: 0x0D,

    WPP_CB_DOWNLOAD_LINK_TYPE_VCAST_WINDOWS: 0x00,
    WPP_CB_DOWNLOAD_LINK_TYPE_VCAST_ANDROID: 0x01,
    WPP_CB_DOWNLOAD_LINK_TYPE_VCAST_IOS: 0x02,
    WPP_CB_DOWNLOAD_LINK_TYPE_VCAPTURE_WINDOWS_32: 0x03,
    WPP_CB_DOWNLOAD_LINK_TYPE_VCAPTURE_WINDOWS_64: 0x04,
    WPP_CB_DOWNLOAD_LINK_TYPE_VCAPTURE_ANDROID: 0x05,
    WPP_CB_DOWNLOAD_LINK_TYPE_VCAPTURE_IOS: 0x06,

    WPP_CB_DOWNLOAD_LINK_SOURCE_LOCAL: 0x00,
    WPP_CB_DOWNLOAD_LINK_SOURCE_REMOTE: 0x01,

    WPP_CB_URL_INTERFACE_LAN1: 0x00,
    WPP_CB_URL_INTERFACE_WLAN: 0x01,
    WPP_CB_URL_INTERFACE_LAN2: 0x02,

    WPP_CB_STREAM_SDP_MODE_STATIC: 0x00,
    WPP_CB_STREAM_SDP_MODE_DYNAMIC: 0x01,

    WPP_CB_CONTENT_MODE_REC_SRC_MAIN: 0x00,
    WPP_CB_CONTENT_MODE_REC_SRC_AUX: 0x01,

    WPP_CB_END_PRESENTATION_NEW_PRESENTATION: 0x00,
    WPP_CB_END_PRESENTATION_STANDBY: 0x01,
    WPP_CB_END_PRESENTATION_SCREEN_OFF: 0x02,
    WPP_CB_END_PRESENTATION_SCREENSAVER: 0x03,

    WPP_CB_SCREENSAVER_STATE_OFF: 0x00,
    WPP_CB_SCREENSAVER_STATE_STANDBY: 0x01,
    WPP_CB_SCREENSAVER_STATE_SCREEN_OFF: 0x02,
    WPP_CB_SCREENSAVER_STATE_SCREENSAVER: 0x03,

    WPP_CB_LOCAL_SCREENSAVER_NOT_AVAILABLE: 0x00,
    WPP_CB_LOCAL_SCREENSAVER_AVAILABLE: 0x01,

    WPP_CB_TIMESERVER_STATUS_TESTING: 0x00,
    WPP_CB_TIMESERVER_STATUS_FAILED: 0x01,
    WPP_CB_TIMESERVER_STATUS_SUCCESS: 0x02,

    WPP_CB_SESSION_MIDDLEWARE_TRIGGER_STOP: 0x00,
    WPP_CB_SESSION_MIDDLEWARE_TRIGGER_START: 0x01,

    WPP_CB_GENERATE_LOGFILE_USB: 0x00,
    WPP_CB_GENERATE_LOGFILE_LOCAL: 0x01,

    WPP_CB_GENERATE_LOGFILE_STATUS_INIT: 0x00,
    WPP_CB_GENERATE_LOGFILE_STATUS_BUSY: 0x01,
    WPP_CB_GENERATE_LOGFILE_STATUS_READY: 0x02,
    WPP_CB_GENERATE_LOGFILE_STATUS_FAIL: 0x03,

    WPP_CB_LECTURE_CAPTURE_SETTINGS_TYPE_DISABLED: 0x00,
    WPP_CB_LECTURE_CAPTURE_SETTINGS_TYPE_PANOPTO: 0x01,
    WPP_CB_LECTURE_CAPTURE_SETTINGS_TYPE_OPENCAST_ADHOC: 0x02,
    WPP_CB_LECTURE_CAPTURE_SETTINGS_TYPE_OPENCAST_AUTO: 0x03,

    WPP_CB_LECTURE_CAPTURE_SETTINGS_MODE_AUTO: 0x00,
    WPP_CB_LECTURE_CAPTURE_SETTINGS_MODE_MANUAL: 0x01,

    WPC_CB_LECTURE_CAPTURE_SETTINGS_PATH_INTERNAL: 0x00,
    WPC_CB_LECTURE_CAPTURE_SETTINGS_PATH_EXTERNAL: 0x01,

    WPC_CB_LECTURE_CAPTURE_SETTINGS_IP_CAMERA_MODE_OFF: 0x00,
    WPC_CB_LECTURE_CAPTURE_SETTINGS_IP_CAMERA_MODE_ON: 0x01,

    WPP_CB_DISK_ENCRYPTION_MODE_OFF: 0x00,
    WPP_CB_DISK_ENCRYPTION_MODE_ON: 0x01,

    WPP_CB_DISK_ENCRYPTION_STATUS_FAILED: 0x00,
    WPP_CB_DISK_ENCRYPTION_STATUS_INPROGRESS: 0x01,
    WPP_CB_DISK_ENCRYPTION_STATUS_ENCRYPTED: 0x02,
    WPP_CB_DISK_ENCRYPTION_STATUS_DECRYPTED: 0x03,

    WPP_CB_MIRACAST_BACK_CHANNEL_OFF: 0x00,
    WPP_CB_MIRACAST_BACK_CHANNEL_ON: 0x01,

    WPP_CB_PANOPTO_STATUS_NOT_LOGGED_IN: 0x00,
    WPP_CB_PANOPTO_STATUS_LOGGED_IN: 0x01,
    WPP_CB_PANOPTO_STATUS_LOGIN_FAILED: 0x02,
    WPP_CB_PANOPTO_STATUS_LOGGING_IN: 0x03,

    WPP_CB_PANOPTO_BROWSER_LOGOUT: 0x00,
    WPP_CB_PANOPTO_BROWSER_LOGIN: 0x01,

    WPP_CB_PANOPTO_LOGIN_DIALOG_NONE: 0x00,
    WPP_CB_PANOPTO_LOGIN_DIALOG_USERNAME_PASSWORD: 0x01,
    WPP_CB_PANOPTO_LOGIN_DIALOG_BROWSER: 0x02,

    WPP_CB_OPENCAST_CERTIFICATE_DISABLED: 0x00,
    WPP_CB_OPENCAST_CERTIFICATE_ENABLED: 0x01,

    WPP_CB_OPENCAST_STATUS_NOT_LOGGED_IN: 0x00,
    WPP_CB_OPENCAST_STATUS_LOGGED_IN: 0x01,
    WPP_CB_OPENCAST_STATUS_LOGIN_FAILED: 0x02,

    WPP_CB_LECTURE_CAPTURE_UPLOAD_STATUS_UNKNOWN: 0x00,
    WPP_CB_LECTURE_CAPTURE_UPLOAD_STATUS_OK: 0x01,
    WPP_CB_LECTURE_CAPTURE_UPLOAD_STATUS_FAILED: 0x02,

    WPP_CB_LDAP_STATUS_NOT_AUTHENTICATED: 0x00,
    WPP_CB_LDAP_STATUS_AUTHENTICATED: 0x01,

    WPP_CB_KEYBOARD_LAYOUT_DE: 0x00,
    WPP_CB_KEYBOARD_LAYOUT_US: 0x01,
    WPP_CB_KEYBOARD_LAYOUT_CN: 0x02,
    WPP_CB_KEYBOARD_LAYOUT_FR: 0x03,
    WPP_CB_KEYBOARD_LAYOUT_JP: 0x04,
    WPP_CB_KEYBOARD_LAYOUT_NO: 0x05,
    WPP_CB_KEYBOARD_LAYOUT_RU: 0x06,
    WPP_CB_KEYBOARD_LAYOUT_SV: 0x07,
    WPP_CB_KEYBOARD_LAYOUT_IT: 0x08,
    WPP_CB_KEYBOARD_LAYOUT_CH: 0x09,
    // WPP_KEYBOARD_LAYOUT_CH_FR: 0x0A,
    WPP_CB_KEYBOARD_LAYOUT_UK: 0x0B,
    WPP_CB_KEYBOARD_LAYOUT_FR_CA: 0x0C,

    WPP_CB_POWER_DOWN_SUSPEND: 0x00,
    WPP_CB_POWER_DOWN_SHUTDOWN: 0x01,
    WPP_CB_POWER_DOWN_POWER_SAVING: 0x02,

    WPP_CB_FILE_PUSH_MODE_OFF: 0x00,
    WPP_CB_FILE_PUSH_MODE_ON: 0x01,

    WPP_CB_VMEETING_OAUTH_LOGOUT: 0x00,
    WPP_CB_VMEETING_OAUTH_LOGIN: 0x01,

    WPP_CB_VMEETING_DEADLINE_ENABLE: 0x00,
    WPP_CB_VMEETING_DEADLINE_DISABLE: 0x01,
    WPP_CB_VMEETING_DEADLINE_STATUS_OK: 0x00,
    WPP_CB_VMEETING_DEADLINE_STATUS_WARNING: 0x01,
    WPP_CB_VMEETING_DEADLINE_STATUS_CRITICAL: 0x02,

    WPP_CB_VMEETING_CONNECTION_STATUS_DISCONNECTED: 0x00,
    WPP_CB_VMEETING_CONNECTION_STATUS_UNKNOWN: 0x01,
    WPP_CB_VMEETING_CONNECTION_STATUS_FAILURE: 0x02,
    WPP_CB_VMEETING_CONNECTION_STATUS_SUCCESS: 0x03,
    WPP_CB_VMEETING_CONNECTION_STATUS_CONNECTING: 0x04,

    WPP_CB_VMEETING_MODE_MS365: 0x02,
    WPP_CB_VMEETING_MODE_MS365_BASIC: 0x03,
    WPP_CB_VMEETING_MODE_GOOGLE_CAL: 0x04,
    WPP_CB_VMEETING_MODE_GOOGLE_CAL_BASIC: 0x05,

    WPP_WLAN_DIRECT_CHANNEL_AUTO: 0x00,
    WPP_WLAN_DIRECT_CHANNEL_1: 0x01,
    WPP_WLAN_DIRECT_CHANNEL_6: 0x02,
    WPP_WLAN_DIRECT_CHANNEL_11: 0x03,

    WPP_CB_PIP_SIZE_360P: 0x00,

    WPP_CB_PIP_POSITION_LEFT_TOP: 0x00,
    WPP_CB_PIP_POSITION_RIGHT_TOP: 0x01,
    WPP_CB_PIP_POSITION_LEFT_BOTTOM: 0x02,
    WPP_CB_PIP_POSITION_RIGHT_BOTTOM: 0x03,

    WPP_CB_PIP_CONTENT_CAMERA: 0x00,
    WPP_CB_PIP_CONTENT_MAIN: 0x01,

    WPP_CB_ROUTING_DEVICE_LAN1: 0x00,
    WPP_CB_ROUTING_DEVICE_LAN2: 0x01,
    WPP_CB_ROUTING_DEVICE_WLAN: 0x02,

    WPP_CB_WLAN_COUNTRY_US: 0x01,
    WPP_CB_WLAN_COUNTRY_CA: 0x02,
    WPP_CB_WLAN_COUNTRY_AU: 0x03,
    WPP_CB_WLAN_COUNTRY_NZ: 0x04,
    WPP_CB_WLAN_COUNTRY_MY: 0x05,

    WPP_CB_WLAN_COUNTRY_AT: 0x06,
    WPP_CB_WLAN_COUNTRY_BE: 0x07,
    WPP_CB_WLAN_COUNTRY_BG: 0x08,
    WPP_CB_WLAN_COUNTRY_CY: 0x09,
    WPP_CB_WLAN_COUNTRY_CZ: 0x0A,
    WPP_CB_WLAN_COUNTRY_DE: 0x0B,
    WPP_CB_WLAN_COUNTRY_DK: 0x0C,
    WPP_CB_WLAN_COUNTRY_EE: 0x0D,
    WPP_CB_WLAN_COUNTRY_ES: 0x0E,
    WPP_CB_WLAN_COUNTRY_FI: 0x0F,
    WPP_CB_WLAN_COUNTRY_FR: 0x10,
    WPP_CB_WLAN_COUNTRY_GB: 0x11,
    WPP_CB_WLAN_COUNTRY_GR: 0x12,
    WPP_CB_WLAN_COUNTRY_HR: 0x13,
    WPP_CB_WLAN_COUNTRY_HU: 0x14,
    WPP_CB_WLAN_COUNTRY_IE: 0x15,
    WPP_CB_WLAN_COUNTRY_IT: 0x16,
    WPP_CB_WLAN_COUNTRY_LT: 0x17,
    WPP_CB_WLAN_COUNTRY_LU: 0x18,
    WPP_CB_WLAN_COUNTRY_LV: 0x19,
    WPP_CB_WLAN_COUNTRY_MT: 0x1A,
    WPP_CB_WLAN_COUNTRY_NL: 0x1B,
    WPP_CB_WLAN_COUNTRY_PL: 0x1C,
    WPP_CB_WLAN_COUNTRY_PT: 0x1D,
    WPP_CB_WLAN_COUNTRY_RO: 0x1E,
    WPP_CB_WLAN_COUNTRY_SE: 0x1F,
    WPP_CB_WLAN_COUNTRY_SI: 0x20,
    WPP_CB_WLAN_COUNTRY_SK: 0x21,
    WPP_CB_WLAN_COUNTRY_AD: 0x22,
    WPP_CB_WLAN_COUNTRY_BA: 0x23,
    WPP_CB_WLAN_COUNTRY_CH: 0x24,
    WPP_CB_WLAN_COUNTRY_LI: 0x25,
    WPP_CB_WLAN_COUNTRY_MC: 0x26,
    WPP_CB_WLAN_COUNTRY_ME: 0x27,
    WPP_CB_WLAN_COUNTRY_RS: 0x28,
    WPP_CB_WLAN_COUNTRY_NO: 0x29,
    WPP_CB_WLAN_COUNTRY_JP: 0x2A,
    WPP_CB_WLAN_COUNTRY_RU: 0x2B,
    WPP_CB_WLAN_COUNTRY_KW: 0x2C,
    WPP_CB_WLAN_COUNTRY_OM: 0x2D,
    WPP_CB_WLAN_COUNTRY_SA: 0x2E,
    WPP_CB_WLAN_COUNTRY_QA: 0x2F,
    WPP_CB_WLAN_COUNTRY_CN: 0x30,
    WPP_CB_WLAN_COUNTRY_HK: 0x31,
    WPP_CB_WLAN_COUNTRY_SG: 0x32,
    WPP_CB_WLAN_COUNTRY_IN: 0x33,
    WPP_CB_WLAN_COUNTRY_TH: 0x34,
    WPP_CB_WLAN_COUNTRY_AE: 0x35,

    WPP_CB_FIREWALL_INTERFACE_LAN1: 0x00,
    WPP_CB_FIREWALL_INTERFACE_LAN2: 0x01,
    WPP_CB_FIREWALL_INTERFACE_WLAN: 0x02,

    WPP_CB_FIREWALL_SERVICE_HTTP_HTTPS: 0x00,
    WPP_CB_FIREWALL_SERVICE_RTSP: 0x01,
    WPP_CB_FIREWALL_SERVICE_DENY: 0x00,
    WPP_CB_FIREWALL_SERVICE_ACCEPT: 0x01,

    WPP_CB_HDMI_INPUT_MAX_RESOLUTION_HDMI1: 0x00,
    WPP_CB_HDMI_INPUT_MAX_RESOLUTION_HDMI2: 0x01,
    WPP_CB_HDMI_INPUT_MAX_RESOLUTION_2160P: 0x00,
    WPP_CB_HDMI_INPUT_MAX_RESOLUTION_1080P: 0x01,

    WPP_CB_WEBCONF_SCREENSHARE_MODE_NATIVE: 0x00,
    WPP_CB_WEBCONF_SCREENSHARE_MODE_VIDEO: 0x01,

    WPP_CB_EDDYSTONE_INTERFACE_LAN1: 0x00,
    WPP_CB_EDDYSTONE_INTERFACE_LAN2: 0x01,
    WPP_CB_EDDYSTONE_INTERFACE_WLAN: 0x02,

    WPP_WWW_HELP_PAGE_CHECK: 0x00,
    WPP_WWW_HELP_PAGE_OPEN: 0x01,

    WPP_HELP_PAGE_SUPPORT_DISABLE: 0x00,
    WPP_HELP_PAGE_SUPPORT_ENABLE: 0x01,

    WPP_WWW_HELP_PAGE_STATUS_UNAVAILABLE: 0x00,
    WPP_WWW_HELP_PAGE_STATUS_AVAILABLE: 0x01,

    WPP_DIR_LIST_RESET: 0x00,
    WPP_DIR_LIST_UPDATE: 0x01,

    WPP_DIR_LIST_STATUS_UNKNOWN: 0x00,
    WPP_DIR_LIST_STATUS_UPDATING: 0x01,
    WPP_DIR_LIST_STATUS_OK: 0x02,
    WPP_DIR_LIST_STATUS_FAIL: 0x03,

    WPP_CB_WINDOW_SETTINGS_SIDE_BY_SIDE: 0x00,
    WPP_CB_WINDOW_SETTINGS_FULLSCREEN_MODE: 0x01,
    WPP_CB_WINDOW_SETTINGS_MIXED_MODE: 0x02,

    WPP_CB_VLINK_PRO_DISCOVERY_OFF: 0x00,
    WPP_CB_VLINK_PRO_DISCOVERY_STATIC: 0x01,
    WPP_CB_VLINK_PRO_DISCOVERY_DHCP: 0x02,

    WPP_CB_MGMT_SUBSCRIBE_STATUS_INIT: 0x00,
    WPP_CB_MGMT_SUBSCRIBE_STATUS_GENERIC_ERROR: 0x01,
    WPP_CB_MGMT_SUBSCRIBE_STATUS_DHCP_ERROR: 0x02,
    WPP_CB_MGMT_SUBSCRIBE_STATUS_POST_ERROR: 0x03,
    WPP_CB_MGMT_SUBSCRIBE_STATUS_OK: 0x04,

    WPP_CB_OFFICE365_CREDENTIALS_LOCAL: 0x00,
    WPP_CB_OFFICE365_CREDENTIALS_USER: 0x01,

    WPP_CB_WEBCAST_YOUTUBE_PRIVACY_PRIVATE: 0x00,
    WPP_CB_WEBCAST_YOUTUBE_PRIVACY_PUBLIC: 0x01,
    WPP_CB_WEBCAST_YOUTUBE_PRIVACY_UNLISTED: 0x02,

    WPP_CB_REVERSE_SHELL_STATUS_STOPPED: 0x00,
    WPP_CB_REVERSE_SHELL_STATUS_STARTED: 0x01,
    WPP_CB_REVERSE_SHELL_STATUS_FAILED: 0x02,

    WPP_CB_STOPPED: 0x00,
    WPP_CB_STARTED: 0x01,

    WPP_CB_INTERFACE_LAN1: 0x00,
    WPP_CB_INTERFACE_WLAN: 0x01,
    WPP_CB_INTERFACE_LAN2: 0x02,

    WPP_CB_PING_STATUS_STOPPED: 0x00,
    WPP_CB_PING_STATUS_STARTED: 0x01,
    WPP_CB_PING_STATUS_FAILED: 0x02,
    WPP_CB_PING_STATUS_SUCCEEDED: 0x03,

    WPP_CB_ZOOM_LOCAL_ACCOUNT_LOGOUT: 0x00,
    WPP_CB_ZOOM_LOCAL_ACCOUNT_LOGIN: 0x01,
    WPP_CB_ZOOM_LOCAL_ACCOUNT_UNDEFINED: 0x00,
    WPP_CB_ZOOM_LOCAL_ACCOUNT_LOGGED_OUT: 0x02,
    WPP_CB_ZOOM_LOCAL_ACCOUNT_CONNECTED: 0x03,
    WPP_CB_ZOOM_LOCAL_ACCOUNT_DISCONNECTED: 0x04,

    WPP_OUTPUT_HDMI1: 0x01,
    WPP_OUTPUT_HDMI2: 0x02,
    WPP_OUTPUT_PREPARE: 0x04,
    WPP_OUTPUT_EDIT: 0x08,
    WPP_OUTPUT_WEBRTC: 0x10,

    WPP_APPLICATION_CLOSE: 0x00,
    WPP_APPLICATION_FULLSCREEN: 0x01,
    WPP_APPLICATION_NORMAL: 0x02,
    WPP_APPLICATION_MUTE: 0x03,
    WPP_APPLICATION_VOLUME: 0x04,
    WPP_APPLICATION_AUX_ON: 0x06,
    WPP_APPLICATION_AUX_OFF: 0x07,
    WPP_APPLICATION_AUX_TOGGLE: 0x08,
    WPP_APPLICATION_WEBRTC_OUT_ON: 0x09,
    WPP_APPLICATION_WEBRTC_OUT_OFF: 0x0A,
    WPP_APPLICATION_WEBRTC_OUT_TOGGLE: 0x0B,
    WPP_APPLICATION_SHOW: 0x0C,
    WPP_APPLICATION_REPLACE: 0x0D,

    WPP_WEBCONF_JOIN: 0x00,
    WPP_WEBCONF_START_LOCAL: 0x01,

    WPP_SYMBOL_HDMI: 0x00,
    WPP_SYMBOL_VISUALIZER: 0x01,
    WPP_SYMBOL_DISCPLAYER: 0x02,
    WPP_SYMBOL_COMPUTER: 0x03,
    WPP_SYMBOL_CAMERA: 0x04,
    WPP_SYMBOL_CYNAP: 0x05,

    WPP_SYMBOL_IMAGE: 0x10,
    WPP_SYMBOL_VIDEO: 0x11,
    WPP_SYMBOL_NUMBERS: 0x12,
    WPP_SYMBOL_PRESENTATION: 0x13,
    WPP_SYMBOL_AUDIO: 0x14,
    WPP_SYMBOL_TEXT: 0x15,

    WPP_SYMBOL_FORMAT_TXT: 0x20,
    WPP_SYMBOL_FORMAT_AI: 0x21,
    WPP_SYMBOL_FORMAT_CSS: 0x22,
    WPP_SYMBOL_FORMAT_DOC: 0x23,
    WPP_SYMBOL_FORMAT_EXE: 0x24,
    WPP_SYMBOL_FORMAT_GIF: 0x25,
    WPP_SYMBOL_FORMAT_HTML: 0x26,
    WPP_SYMBOL_FORMAT_JPG: 0x27,
    WPP_SYMBOL_FORMAT_MOV: 0x28,
    WPP_SYMBOL_FORMAT_MP3: 0x29,
    WPP_SYMBOL_FORMAT_MP4: 0x2A,
    WPP_SYMBOL_FORMAT_PDF: 0x2B,
    WPP_SYMBOL_FORMAT_PNG: 0x2C,
    WPP_SYMBOL_FORMAT_PPT: 0x2D,
    WPP_SYMBOL_FORMAT_PSD: 0x2E,
    WPP_SYMBOL_FORMAT_RAR: 0x2F,
    WPP_SYMBOL_FORMAT_SVG: 0x30,
    WPP_SYMBOL_FORMAT_XLS: 0x31,
    WPP_SYMBOL_FORMAT_ZIP: 0x32,
    WPP_SYMBOL_FORMAT_AVI: 0x33,
    WPP_SYMBOL_FORMAT_BMP: 0x34,
    WPP_SYMBOL_FORMAT_DOCX: 0x35,
    WPP_SYMBOL_FORMAT_M4V: 0x36,
    WPP_SYMBOL_FORMAT_MKV: 0x37,
    WPP_SYMBOL_FORMAT_PPTX: 0x38,
    WPP_SYMBOL_FORMAT_WMA: 0x39,
    WPP_SYMBOL_FORMAT_WMV: 0x3A,
    WPP_SYMBOL_FORMAT_XLSX: 0x3B,

    WPP_SYMBOL_NONE: 0xFF
};

module.exports = {
    headers: headers,
    errors: errors,
    commands: commands,
    parameters: parameters,
    userLevels: userLevels
};
