'use strict';

const app = require('../../../app');
const confirmTpl = require('./message.hbs');

app.component('Message', {
    template: confirmTpl,
    className: 'modal-message',

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    postPlaceAt: function() {
        this.bindDOMEvents();
    },

    bindDOMEvents: function() {
        if (this.options.configs.showSettingsButton) {
            this.$el.find('.admin-settings-btn').on('click', this.openSettings.bind(this));
        }
    },

    serialize: function() {
        return {
            messageKey: this.options.configs.messageKey,
            showSettingsButton: this.options.configs.showSettingsButton,
            cynapName: app.getService('Model-View') ? app.getService('Model-View').name : 'Device'
        };
    },

    openSettings: function() {
        app.emit('overlay.open', {
            id: 'general-settings',
            extendedConfigs: 'settings-list',
            animation: 'fade'
        });
    }
});
