'use strict';

var app = require('./../../app');
var rangeTpl = require('./range.hbs');
var platform = require('../../../platform/platform');

app.component('RangeInput', {
    template: rangeTpl,
    className: 'range-input',

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    /**
     * @method initialize
     */
    initialize: function() {
        this.remote = this.getService('RemoteService');
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.storeSelectors();
        this.storeData();
        this.bindDOMEvents();
        this.value = this.options.input.value;
        this.resize(this.options.input.value);
        this.valueChanged = false;
        this.changeOnEnter = this.options.input.changeOnEnter; // If flag is true, range value only changes after pressing enter on the remote.

        if (platform.checks.isEdge) {
            this.$input.addClass('is-edge');
        }
    },

    /**
     * @method serialize
     * @returns {{min: int, max: int, minIcon: (*|.postPlaceAt.input.minIcon|.serialize.minIcon), maxIcon: (*|.postPlaceAt.input.maxIcon|.serialize.maxIcon)}}
     */
    serialize: function() {
        return {
            id: this.options.input.id,
            value: this.options.input.value,
            min: this.options.input.min,
            max: this.options.input.max,
            minIcon: this.options.input.minIcon,
            maxIcon: this.options.input.maxIcon,
            step: this.options.input.step || 1,
            redesign: this.options.input.redesign
        };
    },

    /**
     * @method storeSelectors
     */
    storeSelectors: function() {
        this.$input = this.$el.find('input');
        this.$inputGroup = this.$el.find('.input-group');
    },

    /**
     * @method storeData
     */
    storeData: function() {
        this.min = this.$input.attr('min');
        this.max = this.$input.attr('max');
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.$input.on('change click', this.changeHandler.bind(this));
        this.$inputGroup.on('mouseenter focusin', this.onFocusHandler.bind(this));
        this.$inputGroup.on('mouseleave focusout', this.onLeaveHandler.bind(this));
        this.$inputGroup.on('input', this.onInputHandler.bind(this));
        this.$input.on('keydown', this.onKeyDownHandler.bind(this));

        // Hammerjs fix on safari an firefox browser.
        var stop = function(event) {
            event.stopPropagation();
        };

        this.$input.on('click', stop);
        this.$input.on('mousemove', stop);
        this.$input.on('touchmove', stop);
        this.$input.on('touchstart', stop);
        this.$input.on('touchend', stop);
    },

    /**
     * @method onKeyDownHandler
     * @param {jQuery.Event} event
     * @returns {boolean}
     */
    onKeyDownHandler: function(event) {
        var keyCode = event.keyCode;

        switch (keyCode) {
            case this.remote.KEYCODES.ENTER:
                if (this.$el.hasClass('remote-move')) {
                    this.$el.removeClass('remote-move');
                } else if (this.changeOnEnter) {
                    this.$el.addClass('remote-move');
                }

                var handler = this.options.input.onEnterPress || this.$.noop;

                handler();
                event.preventDefault();
                event.stopPropagation();

                if (event.currentTarget.id === 'control-bar-range-input') {
                    this.emit('framebox.submenu.close');
                }

                break;
            case this.remote.KEYCODES.UP:
            case this.remote.KEYCODES.DOWN:
                event.preventDefault();
                break;
            case this.remote.KEYCODES.LEFT:
                if (this.$el.hasClass('remote-move')) {
                    event.preventDefault();
                    break;
                }

                this.remote.stopMove();

                if (this.$input.hasClass('focused')) {
                    if (platform.checks.isFirefox && parseInt(this.$input.val()) > 0) {
                        this.value = parseInt(this.$input.val()) - parseInt(this.options.input.step);
                        this.options.input.onChange(this.value);
                    }
                } else {
                    event.preventDefault();
                }
                break;
            case this.remote.KEYCODES.RIGHT:
                if (this.$el.hasClass('remote-move')) {
                    event.preventDefault();
                    break;
                }

                this.remote.stopMove();

                if (this.$input.hasClass('focused')) {
                    if (platform.checks.isFirefox) {
                        this.value = parseInt(this.$input.val()) + parseInt(this.options.input.step);
                        this.options.input.onChange(this.value);
                    }
                } else {
                    event.preventDefault();
                }
                break;
        }
    },

    /**
     * @method onLeaveHandler
     */
    onLeaveHandler: function() {
        this.$el.removeClass('is-active');
    },

    /**
     * @method onFocusHandler
     */
    onFocusHandler: function() {
        this.$el.addClass('is-active');

        if (this.changeOnEnter) {
            this.$el.addClass('remote-move');
        }
    },

    /**
     * @method onInputHandler
     */
    onInputHandler: function() {
        // Firefox fix: sometimes an additional event occurs after changing the value which updates the input to the old value
        if (this.valueChanged && platform.checks.isFirefox) {
            this.$input.val(this.value);
            this.valueChanged = false;
        }

        if (typeof this.options.input.onInput !== 'undefined') {
            this.options.input.onInput();
        }
    },

    /**
     * @method slideHandler
     */
    changeHandler: function() {
        this.resize();
        this.callbackHandler();
    },

    /**
     * @method callbackHandler
     */
    callbackHandler: function() {
        var value = this.$input.val();

        if (value !== this.value) {
            this.options.input.onChange(value);
            this.$input.get(0).setAttribute('value', value);
        }

        this.value = value;
        this.valueChanged = true;
    },

    /**
     * @method resize
     */
    resize: function(value) {
        if (!value) {
            value = this.$input.val();
        }

        var colorBefore = '#D8D8D8';
        var colorAfter = '#484A51';

        // Use different colors for control center volume slider
        if (this.$input[0].hasAttribute('redesign')) {
            colorBefore = '#F59A22';
            colorAfter = '#2D2F33';
        }

        var val = (value - this.min) / (this.max - this.min);

        if (!platform.checks.isEdge) {
            this.$input
                .css({
                    'background': '-webkit-gradient(linear, left top, right top, color-stop(' + val + ', ' + colorBefore + '), color-stop(' + val + ', ' + colorAfter + '))'
                })
                .css({
                    'background': '-moz-linear-gradient(45deg, ' + colorBefore + ' 0%, ' + colorBefore + ' ' + val * 99 + '%, ' + colorAfter + ' ' + val * 99 + '%)'
                });
        }
    },

    /**
     * @method destroy
     */
    destroy: function() {
        this.$input.off();
    }
});
