'use strict';

var _ = require('lodash');
var app = require('../../app');

var states = {
    hidden: 'hidden',
    visible: 'visible'
};

var defaultEventOptions = {
    key: ''
};

/**
 * Backdrop blueprint
 */
app.component('Backdrop', {
    className: 'backdrop',

    /**
     * @method initialize
     */
    initialize: function() {
        this.toggleTimeout = null;
        this.state = this.createStateMachine({
            state: states.hidden,
            states: states
        });
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.bindEvents();
        this.bindDOMEvents();
    },

    /**
     * @method bindEvents
     */
    bindEvents: function() {
        this.on('backdrop.show', this.show.bind(this));
        this.on('backdrop.hide', this.hide.bind(this));
        this.on('backdrop.layer.set', this.setLayer.bind(this));
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.$el.on('click', this.clickHandler.bind(this));
    },

    /**
     * @method clickHandler
     */
    clickHandler: function() {
        this.emit('backdrop.action.click');
    },

    /**
     * @param {object} options
     */
    hide: function(options) {
        options = _.extend(defaultEventOptions, options);
        clearTimeout(this.toggleTimeout);

        // If modal is a 'second layer'-modal
        if (this.getService('OverlayHandlerService').hasOpenOverlay()) {
            this.setLayer({ zIndex: 10 });

            return;
        }

        if (this.state.getState() === states.visible && options.key === this.key) {
            this.toggleTimeout = setTimeout(this.hideAnimation.bind(this), 50);
        }
    },

    /**
     * @param {object} options
     */
    show: function(options) {
        options = _.extend(defaultEventOptions, options);
        this.key = options.key;

        clearTimeout(this.toggleTimeout);

        if (this.state.getState() === states.hidden) {
            this.toggleTimeout = setTimeout(this.showAnimation.bind(this), 50);
        }
    },

    /**
     * @method setLayer
     * @param {object} layer
     */
    setLayer: function(layer) {
        var style = _.extend({}, layer);

        this.$el.css(style, 0);
    },

    /**
     * @method showAnimation
     */
    showAnimation: function() {
        this.$el
            .velocity('stop')
            .velocity({
                opacity: 0.6
            }, {
                display: 'block',
                duration: 150
            });

        this.state.changeState(states.visible);
    },

    /**
     * @method hideAnimation
     */
    hideAnimation: function() {
        this.$el
            .velocity('stop')
            .velocity('fadeOut', {
                duration: 150
            });

        this.state.changeState(states.hidden);
    }
});
