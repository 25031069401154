'use strict';

var _ = require('lodash');
var tpl = require('./widget.hbs');
var $ = require('jquery');

/**
 * @param app
 * @param id
 * @param options
 * @constructor
 */
function WidgetView(app, id, options) {
    /**
     * App reference
     * @type {Object}
     */
    this.app = app;

    /**
     * @type {String}
     */
    this.id = id;

    /**
     * Default options
     * @type {{clickable: boolean, icon: string, state: string}}
     */
    this.defaultOptions = {
        clickable: false,
        icon: 'hidden',
        state: '',
        widgetClass: '',
        visible: true
    };

    /**
     * @type {Object}
     */
    this.options = _.defaults(options, this.defaultOptions);

    /**
     * @type {*|jQuery|HTMLElement}
     */
    this.$el = this.app.$(tpl());

    this.update();
}

/**
 * @method render
 * @param $container
 */
WidgetView.prototype.render = function($container) {
    this.$el.addClass(this.options.widgetClass);
    this.$el.attr('id', 'status-widget-item-' + this.id);
    $container.append(this.$el);

    this.$el
        .velocity({
            scale: 1,
            opacity: 1
        }, {
            duration: 150
        });

    if (this.options.visible === false) {
        this.$el
            .stop()
            .hide();
    }
};

/**
 * @method update
 * @param options
 */
WidgetView.prototype.update = function(options) {
    // Remove old settings
    this.unbindDOMEvent();
    this.$el.removeClass('is-' + this.options.state);
    this.$el.find('span').removeClass(this.options.icon);

    // Override old options with the new options
    if (options) {
        this.options = _.extend(this.options, options);
    }

    // Add new settings
    this.$el.addClass('is-' + this.options.state);
    this.$el.find('span').addClass(this.options.icon);
    this.bindDOMEvent();

    if (this.options.visible === false) {
        this.$el
            .stop()
            .fadeOut(150);
    } else {
        this.$el
            .stop()
            .fadeIn(150);

        this.$el
            .velocity({
                scale: 1,
                opacity: 1
            });
    }
};

/**
 * @method remove
 */
WidgetView.prototype.remove = function() {
    var dfd = $.Deferred();

    this.$el
        .velocity({
            scale: 0,
            opacity: 0
        }, {
            duration: 150,
            complete: function() {
                this.$el.remove();

                dfd.resolve();
            }.bind(this)
        });

    return dfd.promise();
};

/**
 * @method destroy
 */
WidgetView.prototype.destroy = function() {
    this.unbindDOMEvent();

    return this.remove();
};

/**
 * @method unbindDOMEvents
 */
WidgetView.prototype.unbindDOMEvent = function() {
    this.$el.off('click');
};

/**
 * @method bindDOMEvents
 */
WidgetView.prototype.bindDOMEvent = function() {
    if (this.options.clickable) {
        this.$el.on('click', this.onWidgetClick.bind(this));
    }
};

/**
 * @method onWidgetClick
 */
WidgetView.prototype.onWidgetClick = function() {
    var eventName = 'status-widget.' + this.id + '.action';

    this.app.emit(eventName, {
        action: 'click'
    });
};

module.exports = WidgetView;
