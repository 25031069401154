// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <form id=\"netdrive-item-form"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\"netdrive-item\" name=\"netdrive-item-form"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n            <div data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\"\n                 data-nav-trapped\n                "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":67}}})) != null ? stack1 : "")
    + "> <!-- NOTE: only id 0 has attr data-nav-area-default -->\n                <div class=\"row settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias4).call(alias3,(data && lookupProperty(data,"index")),{"name":"if_even","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":7,"column":46},"end":{"line":7,"column":97}}})) != null ? stack1 : "")
    + "\">\n                    <div class=\"col-xs-12\">\n                        <h2>\n                            <span id=\"netdrive-connection-state"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\"netdrive-connection-state\">\n                                <span class=\"netdrive-connection-state-notMounted\">\n                                    <div class=\"netdrive-connection-state-point\"></div>\n                                    "
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.notMounted",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":36},"end":{"line":13,"column":63}}}))
    + "\n                                </span>\n                                <span class=\"netdrive-connection-state-mountFailed\">\n                                    <div class=\"netdrive-connection-state-point\"></div>\n                                    "
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.mountFailed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":36},"end":{"line":17,"column":64}}}))
    + "\n                                </span>\n                                <span class=\"netdrive-connection-state-mounted\">\n                                    <div class=\"netdrive-connection-state-point\"></div>\n                                    "
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.mounted",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":36},"end":{"line":21,"column":60}}}))
    + "\n                                </span>\n                                <span class=\"netdrive-connection-state-notResponding\">\n                                    <div class=\"netdrive-connection-state-point\"></div>\n                                    "
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.notResponding",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":36},"end":{"line":25,"column":66}}}))
    + "\n                                </span>\n                                <span class=\"netdrive-connection-state-disabled\">\n                                    <div class=\"netdrive-connection-state-point\"></div>\n                                    "
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.disabled",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":36},"end":{"line":29,"column":61}}}))
    + "\n                                </span>\n                                <span class=\"netdrive-connection-state-busy\">\n                                    <div class=\"netdrive-connection-state-point\"></div>\n                                    "
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.busy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":36},"end":{"line":33,"column":57}}}))
    + "\n                                </span>\n                            </span>\n                        </h2>\n                    </div>\n                </div>\n                <div class=\"row settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias4).call(alias3,(data && lookupProperty(data,"index")),{"name":"if_even","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":39,"column":46},"end":{"line":39,"column":97}}})) != null ? stack1 : "")
    + "\">\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"name\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.drive_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":66},"end":{"line":42,"column":93}}}))
    + "</label>\n                            <input id=\"name-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                                   type=\"text\"\n                                   class=\"input\"\n                                   name=\"name\"\n                                   tabindex=\"-1\"\n                                   data-validation=\"requiredOrDisabled\" />\n                            <div class=\"input-info\">\n                                <span class=\"duplicate-name info-message error-message hidden\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.name_already_exists",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":95},"end":{"line":51,"column":69}}}))
    + "</span>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div id=\"netdrive-type-select"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"></div>\n                    </div>\n                </div>\n            </div>\n            <div id=\"netdrive-configurable"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"notConfigurable") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":62},"end":{"line":60,"column":103}}})) != null ? stack1 : "")
    + "\">\n                <div class=\"row settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias4).call(alias3,(data && lookupProperty(data,"index")),{"name":"if_even","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":61,"column":46},"end":{"line":61,"column":97}}})) != null ? stack1 : "")
    + "\"\n                     data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"url\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":65},"end":{"line":66,"column":85}}}))
    + "</label>\n                            <input id=\"url-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                                   type=\"text\"\n                                   class=\"input\"\n                                   name=\"url\"\n                                   data-validation=\"requiredOrDisabled\" />\n                            <div class=\"input-info\">\n                                <span class=\"info-message success-message\" id=\"url-info-message-cifs"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.url_info_cifs",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":113},"end":{"line":74,"column":63}}}))
    + "</span>\n                                <span class=\"info-message success-message\" id=\"url-info-message-nfs"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.url_info_nfs",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":112},"end":{"line":76,"column":62}}}))
    + "</span>\n                                <span class=\"info-message error-message hidden\">\n                                    "
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.error_messages.required",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":36},"end":{"line":78,"column":77}}}))
    + "\n                                </span>\n                            </div>\n                        </div>\n                    </div>\n                    <div id=\"domain-section"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\"col-xs-6\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"domain\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.domain",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":68},"end":{"line":85,"column":91}}}))
    + "</label>\n                            <input id=\"domain-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                                   type=\"text\"\n                                   class=\"input\"\n                                   name=\"domain\"/>\n                        </div>\n                    </div>\n                </div>\n                <div id=\"auth-section"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                     class=\"row settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias4).call(alias3,(data && lookupProperty(data,"index")),{"name":"if_even","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":94,"column":46},"end":{"line":94,"column":97}}})) != null ? stack1 : "")
    + "\"\n                     data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"user\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":99,"column":66},"end":{"line":99,"column":87}}}))
    + "</label>\n                            <input id=\"user-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                                   type=\"text\"\n                                   class=\"input\"\n                                   name=\"user\"\n                                   data-validation=\"requiredOrDisabled\" />\n                            <div class=\"input-info\">\n                                <span class=\"info-message error-message hidden\">\n                                    "
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.error_messages.required",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":107,"column":36},"end":{"line":107,"column":77}}}))
    + "\n                                </span>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <div class=\"input-group\">\n                            <label class=\"input-label\" for=\"password\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":114,"column":70},"end":{"line":114,"column":95}}}))
    + "</label>\n                            <input id=\"password-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                                   type=\"password\"\n                                   autocomplete=\"current-password\"\n                                   class=\"input\"\n                                   name=\"password\"\n                                   data-validation=\"requiredOrDisabled\" />\n                            <div class=\"input-info\">\n                                <span class=\"info-message error-message hidden\">\n                                    "
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.error_messages.required",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":123,"column":36},"end":{"line":123,"column":77}}}))
    + "\n                                </span>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"row settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias4).call(alias3,(data && lookupProperty(data,"index")),{"name":"if_even","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":129,"column":46},"end":{"line":129,"column":97}}})) != null ? stack1 : "")
    + "\"\n                     data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\"\n                     data-nav-trapped>\n                    <div class=\"col-xs-4\">\n                        <label class=\"input-label\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.read_only",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":133,"column":51},"end":{"line":133,"column":77}}}))
    + "</label>\n                    </div>\n                    <div class=\"col-xs-2\">\n                        <div class=\"btn-switch-container pull-right halfsize-switch\">\n                            <input id=\"readonly-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                                   name=\"readonly\"\n                                   type=\"checkbox\"\n                                   class=\"btn-switch is-focusable\" />\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"default-upload-switch"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n                        <div class=\"col-xs-4\">\n                            <label class=\"input-label m-left-20\">"
    + alias2((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias4).call(alias3,"settings.default_upload_drive",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":148,"column":65},"end":{"line":148,"column":102}}}))
    + "</label>\n                        </div>\n                        <div class=\"col-xs-2\">\n                            <div class=\"btn-switch-container pull-right halfsize-switch\">\n                                <input id=\"defaultUploadDrive-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n                                       name=\"defaultUploadDrive\"\n                                       type=\"checkbox\"\n                                       class=\"btn-switch is-focusable\" />\n                                <div class=\"btn-switch-toggle-container\">\n                                    <div class=\"btn-switch-toggle\"></div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row settings-list-"
    + ((stack1 = (lookupProperty(helpers,"if_even")||(depth0 && lookupProperty(depth0,"if_even"))||alias4).call(alias3,(data && lookupProperty(data,"index")),{"name":"if_even","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":164,"column":42},"end":{"line":164,"column":93}}})) != null ? stack1 : "")
    + "\">\n                <div id=\"form-action-container"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"></div>\n            </div>\n        </form>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "data-nav-area-default";
},"4":function(container,depth0,helpers,partials,data) {
    return "secundary";
},"6":function(container,depth0,helpers,partials,data) {
    return "row";
},"8":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"netdrive-settings\" class=\"settings-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"list") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":168,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});
