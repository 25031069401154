'use strict';

var app = require('../app');

/**
 * Check if this Overlay can be open.
 */
app.service('OverlayHandlerService', function() {
    var openOverlayId = null;

    /**
     * Set the id of the overlay, that will open at this moment.
     * @param id
     */
    var setOpenOverlayId = function(id) {
        openOverlayId = id;
    };

    /**
     * Returns the id of the currently open overlay.
     * @returns {string}
     */
    var getOpenOverlayId = function() {
        return openOverlayId;
    };

    /**
     * Checks if any overlay is open.
     * @returns {boolean}
     */
    var hasOpenOverlay = function() {
        return openOverlayId !== null;
    };

    /**
     * Public functions.
     */
    return {
        /**
         * {@inheritDoc}
         */
        setOpenOverlayId: setOpenOverlayId,

        /**
         * {@inheritDoc}
         */
        getOpenOverlayId: getOpenOverlayId,

        /**
         * {@inheritDoc}
         */
        hasOpenOverlay: hasOpenOverlay
    };
});
