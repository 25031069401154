'use strict';

var app = require('../app');
var $ = require('jquery');

/**
 * Check if this Modal can be open.
 */
app.service('ModalHandlerService', function() {
    var _openModalId = null;

    /**
     * Set the id of the modal, that will open at this moment.
     * @param openModalId
     */
    var setOpenModalId = function(openModalId) {
        _openModalId = openModalId;
    };

    /**
     * Returns the id of the currently open modal.
     * @returns {string}
     */
    var getOpenModalId = function() {
        return _openModalId;
    };

    /**
     * Checks if any modal is open.
     * @returns {boolean}
     */
    var hasOpenModal = function() {
        return _openModalId !== null;
    };

    /**
     * Only open State-Effect if no modal is open.
     * See #RELEASE-931.
     *
     * @return {boolean}
     */
    var blockStateEffect = function blockStateEffect() {
        var dfd = $.Deferred();

        if (null === getOpenModalId()) {
            dfd.resolve();
        } else {
            dfd.reject();
        }

        return dfd.promise();
    };

    /**
     * Check some Modals.
     */
    var modalHandler = {
        'disconnected': function() {
            var dfd = $.Deferred();

            if ('box-standby' !== getOpenModalId()) {
                dfd.resolve();
            } else {
                dfd.reject();
            }

            return dfd.promise();
        },
        'box-no-presentation': function() {
            var dfd = $.Deferred();

            /* RELEASE-3118 - Viewer user dialog starts blinking when enabling it on Login Settings.
               Block no presentation modal when select viewer control is open. */
            if ('select-viewer-control' !== getOpenModalId() && 'box-no-presentation' !== getOpenModalId()) {
                dfd.resolve();
            } else {
                dfd.reject();
            }

            return dfd.promise();
        },
        'default': function(options) {
            var dfd = $.Deferred();

            if (options.id !== getOpenModalId()) {
                dfd.resolve();
            } else {
                dfd.reject();
            }

            return dfd.promise();
        },
        'freeze-on': blockStateEffect,
        'freeze-off': blockStateEffect,
        'volume': function() {
            var dfd = $.Deferred();

            if (app.getService('VolumeService').isAudioEnabled() && 'volume' !== getOpenModalId()) {
                dfd.resolve();
            } else {
                dfd.reject();
            }

            return dfd.promise();
        },
        'webconference': function(options) {
            var dfd = $.Deferred();
            var entries = options.entries.filter(function(entry) {
                return '' !== entry.URL;
            });

            if (0 < entries.length) {
                dfd.resolve();
            } else {
                dfd.reject();
                app.getService('WebconferenceService').startWebconference();
                app.emit('backdrop.hide');
            }

            return dfd.promise();
        }
    };

    /**
     * Public functions.
     */
    return {
        /**
         * Check Modal by given options.
         *
         * @param options
         */
        check: function(options, successCallback) {
            var handler =  modalHandler[options.id] || modalHandler['default'];

            if (handler) {
                handler(options)
                    .done(successCallback);
            }
        },

        /**
         * {@inheritDoc}
         */
        setOpenModalId: setOpenModalId,

        /**
         * {@inheritDoc}
         */
        getOpenModalId: getOpenModalId,

        /**
         * {@inheritDoc}
         */
        hasOpenModal: hasOpenModal
    };
});
