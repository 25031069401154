'use strict';

var app = require('../../../../app');
var template = require('./splash-screen-settings.hbs');
var FormActionView = require('./../../form-action-view');
var $ = require('jquery');
var i18n = require('i18next');

app.component('SplashScreenSettings', {
    template: template,
    actionView: null,
    changes: false,

    getAccessKey: function() {
        return {
            'roleName': 'GeneralSettings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.formManager = this.getService('FormManager');
        this.frontendSettings = this.getService('FrontendSettings');
        this.isInitalized = false;
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.createSelects();
        this.initForm();
        this.initFormAction();
        this.loadFrontendSettings();
        this.bindDOMEvents();
        this.bindEvents();
    },

    storeSelectors: function() {
        this.$wlanSettingsContainer = this.$el.find('#splash-screen-wlan-settings');
        this.$allSettingsContainer = this.$el.find('#splash-screen-all-settings');
        this.$customWlanSettings = this.$el.find('#splash-screen-wlan-type-settings');
        this.$wlanTypeContainer = this.$el.find('#display-wlan-type-select');
    },

    serialize: function() {
        return {};
    },

    bindEvents: function() {
        this.on('settings.save-changes', this.saveHandler.bind(this));
    },

    bindDOMEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this));
    },

    /**
     * Called when a input/select has been changes.
     * - Open action view.
     * - Set changes var to true.
     */
    handleFormChange: function(data) {
        if (this.isInitalized) {
            this.actionView.open();
            this.changes = true;
        }

        if (data.input && 'enabled' === data.input.name) {
            this.handleEnableChange();
        }

        this.handleDisplayWlanTypeChange();
        this.checkActionForm();
    },

    /**
     * Hide or show settings for splash screen.
     */
    handleEnableChange: function() {
        var isEnabled = this.form.get('enabled').getValue();

        if (true === isEnabled && true === this.isInitalized) {
            this.$allSettingsContainer
                .stop()
                .slideDown();
        } else if (true === isEnabled) {
            this.$allSettingsContainer.show();
        } else if (true === this.isInitalized) {
            this.$allSettingsContainer
                .stop()
                .slideUp();
        } else {
            this.$allSettingsContainer.hide();
        }
    },

    /**
     * Hide or show settings for custom wlan mode.
     */
    handleDisplayWlanTypeChange: function() {
        var type = this.form.get('splashScreenDisplayWlanType').getValue();

        if (!this.form.get('enabled').getValue()) {
            this.$el.find('#splashScreenDisplayWlanType').removeClass('is-wlan-invalid');

            return;
        }

        switch (type) {
            case 'custom':
                if (true === this.isInitalized) {
                    this.$customWlanSettings
                        .stop()
                        .slideDown();
                } else {
                    this.$customWlanSettings.show();
                }

                this.$el.find('#splashScreenDisplayWlanType')
                    .removeClass('is-wlan-invalid');
                break;
            case 'wlan':
                this.checkWlanMode();

                if (true === this.isInitalized) {
                    this.$customWlanSettings
                        .stop()
                        .slideUp();
                } else {
                    this.$customWlanSettings.hide();
                }
                break;
        }
    },

    /**
     * Check WLAN mode and show/hide error message.
     * @returns {*}
     */
    checkWlanMode: function() {
        return this.deviceConnection
            .send([
                'getWlanMode',
                'getWlanStatus'
            ])
            .then(function(wlanMode, wlanStatus) {
                if ('off' === wlanMode.wlanMode || 'connected' !== wlanStatus.wlanStatus) {
                    this.$el.find('#splashScreenDisplayWlanType')
                        .addClass('is-wlan-invalid');
                }

                this.checkActionForm();
            }.bind(this));
    },

    /**
     * Called when the save button has been clicked.
     */
    saveHandler: function() {
        if (this.form.validate()) {
            var triggerMode = this.form.get('enabled').getValue() && this.form.get('showOnRemote').getValue();

            this.frontendSettings.updateSetting({
                tag: 'splashScreenEnabled',
                value: this.form.get('enabled').getValue()
            });
            this.frontendSettings.updateSetting({
                tag: 'splashScreenShowOnRemote',
                value: this.form.get('showOnRemote').getValue()
            });
            this.frontendSettings.updateSetting({
                tag: 'splashScreenShowQrCode',
                value: this.form.get('showQRCode').getValue()
            });
            this.frontendSettings.updateSetting({
                tag: 'splashScreenDisplayWlan',
                value: this.form.get('displayWlan').getValue()
            });
            this.frontendSettings.updateSetting({
                tag: 'splashScreenDisplayWlanType',
                value: this.form.get('splashScreenDisplayWlanType').getValue()
            });
            this.frontendSettings.updateSetting({
                tag: 'splashScreenDisplayWlanSSID',
                value: this.form.get('splashScreenDisplayWlanSSID').getValue()
            });
            this.frontendSettings.updateSetting({
                tag: 'splashScreenDisplayWlanPassword',
                value: this.form.get('splashScreenDisplayWlanPassword').getValue()
            });
            this.frontendSettings.updateSetting({
                tag: 'splashScreenDisplayWlanEncryption',
                value: this.form.get('splashScreenDisplayWlanEncryption').getValue()
            });
            this.frontendSettings.updateSetting({
                tag: 'splashScreenManuallyTimeout',
                value: this.form.get('splashScreenManuallyTimeout').getValue()
            });

            this.frontendSettings.saveSettings();

            this.deviceConnection
                .send('setSplashScreenTriggerMode', { mode: triggerMode });

            this.handleSettingsSave();
            this.form.setDefaultValues();
        }
    },

    /**
     * Handels the feedback after saving settings.
     */
    handleSettingsSave: function() {
        app.emit('main-loop.fast.start', {
            id: 'volume'
        });

        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.changes = false;
    },

    /**
     * Calls on clicked the cancel button
     */
    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);

        this.form.resetValues();
        this.handleEnableChange();
        this.handleDisplayWlanTypeChange();

        this.changes = false;
    },

    /**
     * Update data on Formular.
     */
    updateForm: function() {
        this.form.setValues(this.formData);
        this.handleEnableChange();
        this.handleDisplayWlanTypeChange();

        this.form.setDefaultValues();
        this.isInitalized = true;
    },

    /**
     * Enable or disable "Save"-button.
     */
    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * Initialize Formular manager.
     */
    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#splash-screen-settings'),
            validationContainer: '.input-group'
        });
    },

    /**
     * @method initFormAction
     */
    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#splash-screen-form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    /**
     * Loads all settings for the splash screen from the frontend-settings.
     */
    loadFrontendSettings: function() {
        var dfd = $.Deferred();

        this.frontendSettings
            .getSettings([
                'splashScreenEnabled',
                'splashScreenShowOnRemote',
                'splashScreenShowQrCode',
                'splashScreenDisplayWlan',
                'splashScreenDisplayWlanType',
                'splashScreenDisplayWlanSSID',
                'splashScreenDisplayWlanPassword',
                'splashScreenDisplayWlanEncryption',
                'splashScreenManuallyTimeout'
            ])
            .then(function(
                isEnabled,
                showOnRemote,
                showQrCode,
                displayWlan,
                splashScreenDisplayWlanType,
                wlanSSid,
                wlanPassword,
                wlandEncryption,
                timeout
            ) {
                var isEnabledDefault = app.getService('Model-View').splashScreenSettingsDefault.enabled;
                this.formData = {
                    'enabled': null === isEnabled ? isEnabledDefault : isEnabled,
                    'showOnRemote': showOnRemote,
                    'showQRCode': showQrCode,
                    'displayWlan': displayWlan,
                    'splashScreenDisplayWlanType': splashScreenDisplayWlanType,
                    'splashScreenDisplayWlanSSID': wlanSSid,
                    'splashScreenDisplayWlanPassword': wlanPassword,
                    'splashScreenDisplayWlanEncryption': wlandEncryption,
                    'splashScreenManuallyTimeout': timeout
                };

                this.updateForm();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Create all selects for the formular.
     */
    createSelects: function() {
        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#display-wlan-encryption-select'),
            label: 'settings.security',
            native: true,
            name: 'splashScreenDisplayWlanEncryption',
            items: [
                {
                    value: 'none',
                    text: 'settings.none'
                },
                {
                    value: 'wep',
                    text: 'settings.wep'
                },
                {
                    value: 'wpa',
                    text: 'settings.wpa2'
                },
                {
                    value: 'wpa-enterprise',
                    text: 'settings.wpa2_enterprise'
                }
            ]
        });

        this.createComponent({
            type: 'CustomSelect',
            container: this.$wlanTypeContainer,
            label: 'settings.splash_screen_display_type',
            native: true,
            name: 'splashScreenDisplayWlanType',
            items: [
                {
                    text: 'settings.custom',
                    value: 'custom'
                },
                {
                    text: 'settings.wlan',
                    value: 'wlan'
                }
            ],
            error: i18n.t('settings.wlan_disconnected'),
            validation: 'checkSplashScreenWlanType'
        });
    },

    hasChanges: function() {
        var changes =  {
            hasChanges: this.changes,
            invalid: false
        };

        if (this.actionView) {
            changes.invalid = $(this.actionView.$submitBtn).prop('disabled');
        }

        return changes;
    }
});
