// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"audio-level col-xs-8\">\n    <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.master_output_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":64}}}))
    + "</h2>\n    <div class=\"audio-level-bar-container\">\n        <div class=\"audio-level-bar-title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.left",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":43},"end":{"line":4,"column":64}}}))
    + "</div>\n        <ul class=\"audio-level-bar\" id=\"audio-level-left\">\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n        </ul>\n    </div>\n    <h2 class=\"input-label no-padding\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.unbalanced_audio",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":39},"end":{"line":28,"column":72}}}))
    + "</h2>\n    <div class=\"audio-level-bar-container\">\n        <div class=\"audio-level-bar-title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.right",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":43},"end":{"line":30,"column":65}}}))
    + "</div>\n        <ul class=\"audio-level-bar\" id=\"audio-level-right\">\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n            <li class=\"audio-level-bar-item\"></li>\n        </ul>\n    </div>\n    <div class=\"audio-level-bar-vrms\">\n        <div class=\"vrms-item vrms-0\">\n            0VRMS<br />\n            -&infin;db@10k\n        </div>\n        <div class=\"vrms-item vrms-07\">\n            0.7VRMS<br />\n            -13db@10k\n        </div>\n        <div class=\"vrms-item vrms-1\">\n            1VRMS<br />\n            -10db@10k\n        </div>\n    </div>\n</div>\n";
},"useData":true});
