'use strict';

var app = require('../app');

app.service('ModalConfigsService', function() {
    var configs = {
        /**
         * 'modalId': Modal ID
         * {
         *     component: string (required), component ID,
         *     view: string (required), small/full/empty/stateEffect/transparent,
         *     role: object (required), { name: string, key: string }, RBAC
         *     className: string, classes to add to modal component element
         *     hideHeader: false: DEFAULT, true: do not show modal header (x/close button)
         *     idName: string, specific ID for modal component
         *
         *     navArea: string, remote control focus area (data-nav-area)
         *     navPrevention: boolean, false: DEFAULT, true: keep remote control focus in modal (data-nav-prevention)
         *     defaultFocus: string, set default focus of remote control when opening modal to element with class/attribute/... defaultFocus
         *
         *     ignoreBackdrop: boolean, false: DEFAULT, true: do not show backdrop,
         *     backdropClick: boolean, true: DEFAULT, close on backdrop click, false: backdrop not clickable,
         *     preventClose: boolean, false: DEFAULT, true: 'modal.close' doesn't close this modal, there has to be an explicit button click
         *
         *     messageKey: string, translation text key for main message
         *     additionalMessageKey: string, translation text key for additional message
         *     titleKey: string, translation text key for modal title
         *     labelKey: string, translation text key for text input field label
         *     placeholderKey: string, translation text key for placeholder in a text input field
         *     validation: string, name of validation function (text input field)
         *     validationErrorMsg: string, translation text key for error message if form is not valid (text input)
         *     successTextKey: string (required), name of success button (translation)
         *     discareTextKey:  string, name of discard/cancel button (translation); if undefined, the button will not be shown
         *
         *     isStatusEffect: boolean, false: DEFAULT, true: is status effect (e.g. freeze)
         *
         *     loader: string (enabled/disabled),if modal is rendered, show loader if 'enabled' or hide if 'disabled' (e.g. login)
         *     hold: number, autohide modal after x milliseconds
         *
         *     pushLockLimitedStateExc: boolean, false: DEFAULT, true: do not show if Matrix push, lock or limited state is active
         *     vMeetingExc: boolean, false: DEFAULT, true: do not show if vMeeting is aktive and no Presentation is started
         * }
         */

        'volume': {
            component: 'VolumeSettings',
            view: 'small',
            navArea: '.focusable',
            role: {
                name: 'Volume',
                key: 'show'
            }
        },
        'qr-code': {
            component: 'QrCode',
            className: 'modal-v3',
            view: 'full',
            navArea: '.focusable',
            hideHeader: true,
            role: {
                name: 'QrCode',
                key: 'show'
            }
        },
        'end-presentation': {
            component: 'EndPresentation',
            view: 'small',
            className: 'end-presentation-modal',
            navArea: '.focusable',
            navPrevention: true,
            vMeetingExc: true,
            role: {
                name: 'EndPresentation',
                key: 'show'
            }
        },
        'close-all-windows': {
            component: 'ConfirmV3',
            view: 'small',
            messageKey: 'modal.close_all_windows',
            successTextKey: 'modal.yes',
            discareTextKey: 'modal.cancel',
            navArea: '.focusable',
            navPrevention: true,
            hideHeader: true,
            role: {
                name: 'CloseAllWindows',
                key: 'show'
            }
        },
        'record-warning': {
            component: 'ConfirmV3',
            view: 'small',
            messageKey: 'modal.record_warning',
            successTextKey: 'modal.ok',
            navArea: '.focusable',
            hideHeader: true,
            backdropClick: false,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'start-recording': {
            component: 'TextInput',
            view: 'small',
            className: 'text-input-modal',
            titleKey: 'control_center.record',
            labelKey: 'modal.recording_naming_title',
            validation: 'checkRecordingName',
            validationErrorMsg: 'error_messages.invalid_rec_name',
            placeholderKey: 'modal.recording_custom_name',
            successTextKey: 'modal.start_recording',
            discareTextKey: 'modal.cancel',
            navArea: '.focusable',
            hideHeader: true,
            backdropClick: false,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'recording-type': {
            component: 'RecordingTypeModal',
            view: 'small',
            navArea: '.is-focusable',
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'panopto': {
            component: 'PanoptoModal',
            view: 'small',
            navArea: '.is-focusable:not(:disabled)',
            className: 'has-long-content',
            backdropClick: false,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'opencast': {
            component: 'OpencastModal',
            view: 'small',
            navArea: '.is-focusable:not(:disabled)',
            className: 'has-long-content',
            backdropClick: false,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'upload-warning': {
            component: 'ConfirmV3',
            view: 'small',
            messageKey: 'modal.upload_warning',
            discareTextKey: 'modal.cancel',
            successTextKey: 'modal.yes',
            navArea: '.focusable',
            hideHeader: true,
            backdropClick: false,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'login': {
            component: 'Login',
            view: 'empty',
            className: 'login-modal-v3',
            backdropClick: false,
            navArea: '.focusable',
            loader: 'disabled',
            pushLockLimitedStateExc: true,
            vMeetingExc: true,
            role: {
                name: 'login',
                key: 'show'
            }
        },
        'select-viewer-control': {
            component: 'SelectViewerControl',
            view: 'empty',
            className: 'login-modal-v3',
            backdropClick: false,
            navArea: '.focusable',
            loader: 'disabled',
            pushLockLimitedStateExc: true,
            vMeetingExc: true,
            role: {
                name: 'login',
                key: 'show'
            }
        },
        'mediaCast': {
            component: 'MediaCast',
            view: 'small',
            navArea: '.focusable',
            navPrevention: true,
            role: {
                name: 'MediaCast',
                key: 'show'
            }
        },
        'pin-viewer': {
            component: 'PinViewer',
            view: 'small',
            className: 'login-modal-v3',
            navArea: '.focusable',
            backdropClick: false,
            hold: 5000,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'disconnected': {
            component: 'ConfirmV3',
            view: 'small',
            idName: 'disconnected-modal',
            messageKey: 'modal.disconnected_message',
            successTextKey: 'modal.try_reconnect',
            navArea: '.focusable',
            hideHeader: true,
            backdropClick: false,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'box-standby': {
            component: 'Message',
            view: 'small',
            className: 'box-standby-modal',
            hideHeader: true,
            backdropClick: false,
            vMeetingExc: true,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'box-screensaver': {
            component: 'ConfirmV3',
            view: 'small',
            className: 'box-screensaver-modal',
            messageKey: 'modal.screensaver_active',
            successTextKey: 'modal.deactivate',
            discareTextKey: 'modal.cancel',
            hideHeader: true,
            backdropClick: false,
            vMeetingExc: true,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'box-screensaver-control-screen': {
            component: 'EndScreensaver',
            view: 'full',
            className: 'box-screensaver-control-screen-modal',
            messageKey: 'modal.screensaver_control_screen_active',
            icon: 'icon-v3-trackpad',
            hideHeader: true,
            backdropClick: false,
            vMeetingExc: true,
            showSettingsButton: false,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'box-screen-off': {
            component: 'Message',
            view: 'small',
            messageKey: 'modal.screen_off_message',
            className: 'box-standby-modal',
            hideHeader: true,
            backdropClick: false,
            vMeetingExc: true,
            showSettingsButton: false,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'box-no-presentation': {
            component: 'Message',
            view: 'small',
            messageKey: 'modal.no_presentation_message',
            className: 'box-no-presentation-modal',
            hideHeader: true,
            backdropClick: false,
            vMeetingExc: true,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'freeze-off': {
            component: 'Notification',
            tplName: 'freezeTpl',
            messageKey: '',
            modalClass: 'freeze-is-off',
            view: 'stateEffect',
            hold: 850,
            navArea: '.focusable',
            isStatusEffect: true,
            ignoreBackdrop: false,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'freeze-on': {
            component: 'Notification',
            tplName: 'freezeTpl',
            messageKey: '',
            modalClass: 'freeze-is-on',
            view: 'stateEffect',
            hold: 850,
            navArea: '.focusable',
            isStatusEffect: true,
            ignoreBackdrop: false,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'eula': {
            component: 'Eula',
            view: 'full',
            hideHeader: true,
            backdropClick: false,
            navArea: false,
            isInfo: false,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'eula-info': {
            component: 'Eula',
            view: 'full',
            hideHeader: true,
            navArea: false,
            isInfo: true,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'software-packages': {
            component: 'SoftwarePackages',
            view: 'full',
            hideHeader: true,
            navArea: false,
            isInfo: true,
            role: {
                name: 'SoftwarePackages',
                key: 'show'
            }
        },
        'end-annotation': {
            component: 'EndAnnotation',
            view: 'small',
            className: 'end-annotation-modal has-long-content',
            navArea: '.focusable',
            pushLockLimitedStateExc: true,
            role: {
                name: 'Annotation',
                key: 'close'
            }
        },
        'pause-annotation': {
            component: 'PauseAnnotation',
            view: 'small',
            className: 'pause-annotation-modal has-long-content',
            navArea: '.focusable',
            pushLockLimitedStateExc: true,
            role: {
                name: 'Annotation',
                key: 'close'
            }
        },
        'pause-collaboration': {
            component: 'ConfirmV3',
            view: 'small',
            messageKey: 'modal.pause_warning',
            successTextKey: 'modal.ok',
            navArea: '.focusable',
            hideHeader: true,
            ignoreBackdrop: true,
            backdropClick: false,
            pushLockLimitedStateExc: true,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'collaboration-users': {
            component: 'CollaborationUsers',
            view: 'empty',
            className: 'collaboration-users-modal modal-v3 ui-scaling has-long-content',
            backdropClick: true,
            role: {
                name: 'ControlScreen',
                key: 'show'
            }
        },
        'userSettings': {
            component: 'UserSettings',
            view: 'small',
            className: 'user-settings-modal',
            backdropClick: false,
            navArea: '.focusable',
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'cloudLogin': {
            component: 'CloudLogin',
            view: 'small',
            className: 'cloud-login-modal',
            backdropClick: false,
            hideHeader: true,
            navPrevention: true,
            navArea: '.focusable',
            role: {
                name: 'CloudLogin',
                key: 'show'
            }
        },
        'genericLogin': {
            component: 'GenericLogin',
            view: 'small',
            backdropClick: false,
            hideHeader: true,
            navPrevention: true,
            navArea: '.focusable',
            defaultFocus: '[data-nav-default-focus]',
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'languageSelect': {
            component: 'LanguageSelect',
            view: 'small',
            className: 'language-select-modal',
            hideHeader: false,
            navArea: '.focusable',
            role: {
                name: 'LanguageSelect',
                key: 'show'
            }
        },
        'displaySettings': {
            isStatusEffect: false,
            component: 'ConfirmV3',
            view: 'small',
            messageKey: 'settings.display_settings_text',
            discareTextKey: 'settings.display_settings_revert',
            successTextKey: 'settings.display_settings_keep',
            navArea: '.focusable',
            hideHeader: true,
            backdropClick: false,
            hold: 10000,
            role: {
                name: 'CloseAllWindows',
                key: 'show'
            }
        },
        'supportPin': {
            component: 'SupportPin',
            view: 'empty',
            className: 'login-modal-v3',
            hideHeader: true,
            navArea: '.focusable',
            backdropClick: false,
            vMeetingExc: true,
            role: {
                name: 'SupportPin',
                key: 'show'
            }
        },
        'matrix-clear-config': {
            component: 'ConfirmV3',
            view: 'small',
            messageKey: 'modal.matrix_clear_config_main',
            additionalMessageKey: 'modal.matrix_clear_config_info',
            successTextKey: 'modal.yes',
            discareTextKey: 'modal.cancel',
            navArea: '.focusable',
            hideHeader: true,
            preventClose: true,
            role: {
                name: 'Matrix',
                key: 'show'
            }
        },
        'matrix-close-config': {
            component: 'MatrixCloseConfigurator',
            view: 'small',
            navArea: '.focusable',
            preventClose: true,
            role: {
                name: 'MatrixSettings',
                key: 'show'
            }
        },
        'matrix-change-config': {
            component: 'MatrixChangeConfiguration',
            view: 'small',
            navArea: '.focusable',
            preventClose: true,
            role: {
                name: 'MatrixSettings',
                key: 'show'
            }
        },
        'matrix-stations-offline': {
            component: 'MatrixStationsOffline',
            view: 'small',
            navArea: '.focusable',
            preventClose: true,
            role: {
                name: 'MatrixSettings',
                key: 'show'
            }
        },
        'settings-save-changes': {
            component: 'SettingsSaveChanges',
            view: 'small',
            navArea: '.focusable',
            navPrevention: true,
            preventClose: true,
            role: {
                name: 'Settings',
                key: 'show'
            }
        },
        'ui-blocker': {
            component: 'UiBlocker',
            view: 'small',
            navArea: '.focusable',
            hideHeader: true,
            preventClose: true,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'ldap-login': {
            component: 'LdapLogin',
            view: 'empty',
            navArea: '.focusable',
            defaultFocus: '#user',
            hideHeader: true,
            preventClose: true,
            vMeetingExc: true,
            className: 'ldap-login login-modal-v3',
            role: {
                name: 'login',
                key: 'show'
            }
        },
        'selection': {
            component: 'SelectionModal',
            view: 'small',
            navArea: '.is-focusable',
            navPrevention: true,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'selection-v3': {
            component: 'SelectionModalV3',
            view: 'empty',
            className: 'selection-v3-modal modal-v3 ui-scaling',
            navArea: '.is-focusable',
            defaultFocus: '[data-nav-default-focus]',
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'matrix': {
            component: 'MatrixModal',
            view: 'transparent',
            navArea: '.focusable',
            hideHeader: true,
            preventClose: true,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'info': {
            component: 'Info',
            view: 'small',
            navArea: '.focusable',
            hideHeader: true,
            preventClose: true,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'warning': {
            component: 'Warning',
            view: 'empty',
            navPrevention: true,
            preventClose: true,
            navArea: '.focusable',
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'wlan-region-warning': {
            component: 'WlanRegionWarning',
            view: 'empty',
            className: 'wlan-region-warning',
            navPrevention: true,
            preventClose: true,
            navArea: '.focusable',
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'screenShare': {
            component: 'ScreenShare',
            view: 'small',
            className: 'screen-share-modal',
            navArea: '.focusable',
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'uploadTargetSelection': {
            component: 'UploadTargetSelection',
            view: 'empty',
            className: 'upload-target-selection-modal modal-v3 ui-scaling',
            navArea: '.focusable',
            navPrevention: true,
            backdropClick: false,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'uploadProgress': {
            component: 'UploadProgress',
            view: 'empty',
            className: 'upload-progress-modal modal-v3 ui-scaling',
            navArea: '.focusable',
            navPrevention: true,
            defaultFocus: '[data-nav-default-focus]',
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'confirmDelete': {
            component: 'ConfirmV3',
            view: 'empty',
            className: 'confirm-delete-modal modal-v3 ui-scaling',
            navArea: '.focusable',
            navPrevention: true,
            role: {
                name: 'allAccess',
                key: 'show'
            },
            backdropClick: false,
            successTextKey: 'filebrowser.delete',
            discareTextKey: 'modal.cancel'
        },
        'vmeetingDetails': {
            component: 'VMeetingDetails',
            view: 'empty',
            className: 'vmeeting-details-modal modal-v3 ui-scaling',
            navArea: '.focusable',
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'manageGroupwork': {
            component: 'GroupworkV3',
            view: 'empty',
            className: 'groupwork-v3-modal modal-v3 ui-scaling',
            navArea: '.focusable',
            backdropClick: false,
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'firmwareUpdate': {
            component: 'ConfirmV3',
            view: 'small',
            hideHeader: true,
            messageKey: 'modal.usb_update_fw_message',
            successTextKey: 'modal.usb_update_fw',
            discareTextKey: 'modal.cancel',
            navArea: '.focusable',
            role: {
                name: 'FwUpdate',
                key: 'show'
            }
        },
        'activateSupport': {
            component: 'ConfirmV3',
            view: 'small',
            hideHeader: true,
            messageKey: 'modal.usb_activate_support_message',
            successTextKey: 'modal.usb_activate_support',
            discareTextKey: 'modal.cancel',
            navArea: '.focusable',
            role: {
                name: 'UserSettings',
                key: 'load'
            }
        },
        'activateFeature': {
            component: 'ConfirmV3',
            view: 'small',
            hideHeader: true,
            messageKey: 'modal.usb_activate_feature_message',
            successTextKey: 'modal.usb_activate_feature',
            discareTextKey: 'modal.cancel',
            navArea: '.focusable',
            role: {
                name: 'UserSettings',
                key: 'load'
            }
        },
        'confirm-v3': {
            component: 'ConfirmV3',
            view: 'small',
            hideHeader: true,
            className: 'modal-v3 ui-scaling',
            navArea: '.focusable',
            navPrevention: true,
            successTextKey: 'modal.yes',
            discareTextKey: 'modal.cancel',
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'zoom-join': {
            component: 'ZoomJoin',
            view: 'small',
            backdropClick: true,
            hideHeader: true,
            navPrevention: true,
            navArea: '.focusable',
            defaultFocus: '[data-nav-default-focus]',
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'regulatory-info': {
            component: 'RegulatoryInfo',
            view: 'empty',
            className: 'regulatory-v3-modal modal-v3 ui-scaling',
            backdropClick: false,
            navArea: '.focusable',
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'room-controller': {
            component: 'RoomController',
            view: 'empty',
            className: 'room-controller-v3-modal modal-v3 ui-scaling',
            backdropClick: true,
            navArea: '.focusable',
            role: {
                name: 'allAccess',
                key: 'show'
            }
        },
        'edit-mode-annotation-end': {
            component: 'ConfirmV3',
            view: 'empty',
            className: 'end-edit-annotation-modal modal-v3 ui-scaling',
            messageKey: 'annotation.edit_mode_annotation_end_title',
            additionalMessageKey: 'annotation.edit_mode_annotation_save',
            successTextKey: 'action_button.save',
            discareTextKey: 'action_button.delete',
            navArea: '.focusable',
            navPrevention: true,
            backdropClick: true,
            showCloseButton: true,
            role: {
                name: 'Annotation',
                key: 'close'
            }
        }
    };

    return {
        get: function(modalId) {
            return configs[modalId] || null;
        }
    };
});
