const template = require('./viewer-live-stream.html');

const sourceMapping = {
    'hdmi1': 'main',
    'hdmi2': 'hdmi2Out'
};

const resolutionMapping = {
    '720p': { width: 1280, height: 720 },
    '1080p': { width: 1920, height: 1080 }
};

module.exports = {
    template,
    components: {
        'live-stream': require('../../control-screen/live-stream/live-stream')
    },

    props: {
        monitor: {
            type: Object,
            required: true
        },
        resolution: {
            type: String,
            required: true
        },
        isFullscreenMode: {
            type: Boolean,
            required: true
        }
    },

    data: function() {
        return {
            isFullscreen: false
        };
    },

    computed: {
        source: function() {
            return sourceMapping[this.monitor.outputPort];
        },
        dimensions: function() {
            let { width, height } = resolutionMapping[this.resolution];
            width = this.isFullscreenMode ? width : width / 2;
            height = this.isFullscreenMode ? height : height / 2;

            return { width, height };
        }
    },

    methods: {
        toggleFullscreen: function() {
            this.isFullscreen = !this.isFullscreen;
            this.$emit('toggle-fullscreen-mode', this.isFullscreen);
        }
    }
};
