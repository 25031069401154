'use strict';

var app = require('../../../app');
var confirmTpl = require('./wlan-region-warning.hbs');

app.component('WlanRegionWarning', {
    template: confirmTpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    serialize: function() {
        return {
            country: this.options.configs.country
        };
    },

    postPlaceAt: function() {
        this.bindDOMEvents();
        this.checkFormActionSpace();
    },

    /**
     * Check if one button is below the other and remove margin (10px)
     */
    checkFormActionSpace: function() {
        if (this.$el.find('.form-action-container').width()
            < (this.$el.find('#confirm-discare').width() + this.$el.find('#confirm-submit').width() + 10)) {
            this.$el.find('.btn-form').css('margin', '5px 0');
        }
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.form-action-submit', this.onConfirmHandler.bind(this));
        this.$el.on('click', '.form-action-discare', this.onDiscareHandler.bind(this));
    },

    /**
     * Handle confirm button click.
     */
    onConfirmHandler: function() {
        this.emit('modal.close');

        if (this.options.configs.onConfirm) {
            this.options.configs.onConfirm();
        }
    },

    /**
     * Handle discare button click.
     */
    onDiscareHandler: function() {
        this.emit('modal.close');
    }
});

