const i18n = require('i18next');
const EventEmitter = require('eventemitter2');
const ConnectionHelper = require('../connection-helper');
const app = require('../app');

/**
 * Helper for poll operations
 */
const pollHelper = function({ load }) {
    const emitter = new EventEmitter();

    let minimumInterval = 0;

    let scheduled = false;
    let syncInProgress = false;
    let runToken;
    let runStartedAt;
    let intervalWaitHandle;

    let listeners = [];

    const maybeWaitForMinimumInterval = function() {
        const now = performance.now();
        const timeElapsed = now - runStartedAt;
        const timeToWait = Math.max(minimumInterval - timeElapsed, 0);

        // Wait until we start next fetch, so we are not faster than min interval
        intervalWaitHandle = setTimeout(() => {
            intervalWaitHandle = undefined;

            runToken = undefined;
            maybeFetchNext(); // eslint-disable-line
        }, timeToWait);
    };

    /**
     * Start next fetch if neccessary
     */
    const maybeFetchNext = function() {
        if (runToken || !scheduled || syncInProgress) {
            return;
        }

        const listenersCurrentIteration = listeners;
        listeners = [];

        scheduled = false;

        const myRunToken = {};
        runToken = myRunToken;

        try {
            runStartedAt = performance.now();

            Promise.resolve(load()).then(function(data) {
                if (runToken !== myRunToken) {
                    return;
                }

                emitter.emit('data', data);
                listenersCurrentIteration.forEach((listener) => {
                    listener.resolve(data);
                });

                maybeWaitForMinimumInterval();
            }, function(err) {
                if (runToken !== myRunToken) {
                    return;
                }

                runToken = undefined;

                listenersCurrentIteration.forEach((listener) => {
                    listener.reject(err);
                });

                maybeFetchNext();
            });
        } catch (ex) {
            runToken = undefined;
            throw ex;
        }
    };

    /**
     * Perform load operation (uses ES6 Promise)
     */
    const fetch = function() {
        if (runToken) {
            throw new Error('fetch already running, parallel fetch not supported');
        }

        runToken = {};

        return Promise.resolve(load()).finally(function() {
            runToken = undefined;
            maybeFetchNext();
        });
    };

    /**
     * Perform load operation (uses internal )
     */
    const serialize = function() {
        const promise = fetch();

        return {
            done: promise.then.bind(promise)
        };
    };

    /**
     * Schedule next poll
     * If a poll is already running, it waits until it is over and then performs
     * the poll.
     */
    const schedulePoll = function() {
        scheduled = true;
        maybeFetchNext();
    };

    const sync = function(fn) {
        runToken = undefined;
        syncInProgress = true;

        try {
            Promise.resolve(fn()).finally(function() {
                syncInProgress = false;
                scheduled = true;
                maybeFetchNext();
            });
        } catch (ex) {
            syncInProgress = false;
            scheduled = true;
            maybeFetchNext();

            return Promise.reject(ex);
        }

        return new Promise((resolve, reject) => {
            listeners = [...listeners, {
                resolve,
                reject
            }];
        });
    };

    const throttleInterval = function(interval) {
        minimumInterval = interval;
        if (intervalWaitHandle) {
            // If we are already waiting for the minimum interval, restart timer based on new value
            clearTimeout(intervalWaitHandle);
            maybeWaitForMinimumInterval();
        }
    };

    return {
        fetch,
        serialize,
        schedulePoll,
        sync,
        throttleInterval,
        on: emitter.on.bind(emitter)
    };
};

/**
 * Convert ES6 Promise to internal promise
 *
 * @param ES6 Promise
 */
const promiseToSerialize = function(promise) {
    return {
        done: promise.then.bind(promise)
    };
};

const wolfprot = function() {
    const helper = new ConnectionHelper(app, {
        isAlive() {
            return true;
        }
    });

    const talk = function(command, ...args) {
        return new Promise(function(resolve, reject) {
            helper.send(command, ...args).then(function(value) {
                resolve(value);
            }, reject);
        });
    };

    const talkMulti = function(commands) {
        return new Promise(function(resolve, reject) {
            helper.send(commands).then(function(...values) {
                resolve(values);
            }, reject);
        });
    };

    return {
        talk,
        talkMulti
    };
};

const eventContext = function() {
    let listeners = [];

    const on = function(event, cb) {
        app.on(event, cb);

        listeners = [...listeners, {
            event,
            cb
        }];
    };

    const close = function() {
        listeners.forEach(function(listener) {
            app.off(listener.event, listener.cb);
        });
        listeners = [];
    };

    return {
        on,
        close
    };
};

module.exports = {
    i18n: i18n.t.bind(i18n),
    pollHelper,
    promiseToSerialize,
    wolfprot,
    eventContext
};
