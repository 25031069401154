'use strict';

require('./audio-level-settings/audio-level-settings');
var $ = require('jquery');
var _ = require('lodash');
var app = require('../../../app');
var platform = require('./../../../../platform/platform');
var template = require('./audio-settings.hbs');
var FormActionView = require('../form-action-view');
var i18n = require('i18next');

var visibleStates = {
    visible: 'visible',
    hidden: 'hidden'
};

app.component('AudioSettings', {
    template: template,

    initialize: function() {
        this.lineOut = 0;
        this.hdmiOut1 = 0;
        this.hdmiOut2 = 0;
        this.lineInLevel = 0;
        this.usbMicVolume = 0;
        this.usbMicList = [];
        this.usbSpeakerList = [];
        this.echoCancelDelay = 0;
        this.formData = {};
        this.formManager = this.getService('FormManager');
        this.volumeService = this.getService('VolumeService');
        this.deviceService = this.getService('DeviceService');
        this.frontendSettings = this.getService('FrontendSettings');
        this.customUiSettings = this.getService('CustomUiSettings');
        this.remote = this.getService('RemoteService');
        /** @var URIService this.URIService  **/
        this.URIService = app.getService('URIService');

        this.mixerInterval = setInterval(this.volumeService.updateMixerMeter.bind(this), 300);

        this.usbMicListState = this.createStateMachine({
            state: visibleStates.hidden,
            states: visibleStates
        });

        this.usbSpeakerListState = this.createStateMachine({
            state: visibleStates.hidden,
            states: visibleStates
        });
    },

    serialize: function() {
        var dfd = $.Deferred();

        this.loadData()
            .then(function() {
                dfd.resolve({
                    isCboxPure: this.deviceService.isCboxPure(),
                    isCboxPureMini: this.deviceService.isCboxPureMini(),
                    isCboxPureOrPro: this.deviceService.isCboxPureOrPro(),
                    isCbox: this.deviceService.isCbox(),
                    isCoreProLcs: this.deviceService.isCboxCorePro() && this.features.capture,
                    showFullsize: platform.checks.isFullsize(),
                    hdmi1TranslationKey: app.getService('Model-View').hdmi1OutTitleKey
                });
            }.bind(this));

        return dfd.promise();
    },

    bindDOMEvents: function() {
        this.form.$el.on('keyup, change', this.handleFormChange.bind(this));
        this.form.on('change.input', this.handleFormChange.bind(this));
        this.$el.on('click', '.audio-test-action', this.testAudio.bind(this));
    },

    bindEvents: function() {
        this.on('main-loop.update', this.updateLoop.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
        this.on('overlay.ready', function() {
            this.remote.focusDefault();
        }.bind(this));
    },

    updateLoop: function() {
        this.deviceConnection
            .send([
                'getUsbMicCardName',
                'getUsbSpeakerCardName'
            ])
            .then(function(cardName, speakerCardName) {
                if (cardName.cardName) {
                    this.$preferredDevice.html('(' + this.URIService.unescape(cardName.cardName) + ')');
                } else {
                    this.$preferredDevice.html('');
                }

                if (speakerCardName.cardName) {
                    this.$preferredSpeakerDevice.html('(' + this.URIService.unescape(speakerCardName.cardName) + ')');
                } else {
                    this.$preferredSpeakerDevice.html('');
                }
            }.bind(this));
    },

    /**
     * Append audio-level-settings component to DOM.
     */
    appendAudioLevelSettings: function() {
        this.createComponent({
            type: 'AudioLevelSettings',
            container: this.$el.find('#audio-level-settings')
        });
    },

    loadData: function() {
        return $.when(this.loadBackendSettings(this), this.loadCustomUiSettings(this), this.loadFrontendSettings(this));
    },

    /**
     * @returns {JQueryDeferred}
     */
    loadBackendSettings: function() {
        var dfd = $.Deferred();

        this.deviceConnection
            .send([
                'getLineOutVolume',
                'getHdmiOut1Volume',
                'getHdmiOut2Volume',
                'getLineInLocalOutput',
                'getLineInRemoteOutput',
                'getUsbMicSettings',
                'getLineInLevel',
                'getUsbMicVolume',
                'getUsbMicCardName',
                'getUsbMicCardList',
                'getEchoCancel',
                'getEchoCancelDelay',
                'getUsbSpeaker',
                'getUsbSpeakerCardName',
                'getUsbSpeakerCardList',
                'getUsbSpeakerVolume',
                'getLicenseFeatures'
            ])
            .then(function(line, hdmi1, hdmi2, localOutput, remoteOutput, usbMic, lineInLevel,
                usbMicVolume, cardName, cardList, echoCancel, echoCancelDelay, usbSpeaker,
                usbSpeakerCardName, usbSpeakerCardList, usbSpeakerVolume, features) {
                this.lineOut = line.volume;
                this.hdmiOut1 = hdmi1.volume;
                this.hdmiOut2 = hdmi2.volume;
                this.lineInLevel = lineInLevel.level;
                this.usbMicVolume = usbMicVolume.volume;
                this.preferredUsbMic = cardName.cardName;
                this.echoCancelDelay = echoCancelDelay.delay;

                this.usbSpeakerVolume = usbSpeakerVolume.volume;
                this.usbSpeakerEnabled = usbSpeaker.enabled;
                this.webconfSplit = usbSpeaker.webconfSplit;
                this.preferredUsbSpeaker = usbSpeakerCardName.cardName;
                this.features = features;

                _.forEach(usbSpeakerCardList.cardList, function(item) {
                    if (this.usbSpeakerList.filter(function(e) {
                        return e.value === item;
                    }).length === 0) {
                        this.usbSpeakerList.push({
                            text: this.URIService.unescape(item),
                            value: item
                        });
                    }
                }.bind(this));

                _.forEach(cardList.cardList, function(item) {
                    if (this.usbMicList.filter(function(e) {
                        return e.value === item;
                    }).length === 0) {
                        this.usbMicList.push({
                            text: this.URIService.unescape(item),
                            value: item
                        });
                    }
                }.bind(this));

                this.formData['line-in-local-output'] = localOutput.enabled;
                this.formData['line-in-remote-output'] = remoteOutput.enabled;
                this.formData['usb-mic'] = usbMic.enable;
                this.formData['usb-speaker'] = this.usbSpeakerEnabled;
                this.formData['webconf-split'] = this.webconfSplit;
                this.formData['usb-mic-webconf-output'] = usbMic.webconf;
                this.formData['usb-mic-local-output'] = usbMic.local;
                this.formData['usb-mic-remote-output'] = usbMic.remote;
                this.formData['line-out'] = 'off';
                this.formData['enable-echo-cancel'] = echoCancel.enabled;

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Load frontend-setting fron extra-settings.
     */
    loadFrontendSettings: function() {
        var dfd = $.Deferred();

        this.frontendSettings
            .getSettings([
                'usePreferredUsbMic'
            ])
            .then(function(preferredMic) {
                this.formData.preferredUsbMic = preferredMic;

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    /**
     * Load setting from custom-ui file.
     */
    loadCustomUiSettings: function() {
        var dfd = $.Deferred();

        this.customUiSettings
            .getSettings([
                'audioEnabled'
            ])
            .then(function(audioEnabled) {
                this.formData.audioEnabled = audioEnabled;

                dfd.resolve();
            }.bind(this));

        return dfd.promise();
    },

    postPlaceAt: function() {
        this.createSelects();
        this.storeSelectors();
        this.initForm();
        this.bindEvents();
        this.bindDOMEvents();
        this.initFormAction();
        this.createRangeInputs();
        this.appendAudioLevelSettings();
        this.updateForm();
        this.updateLoop();

        if (!this.deviceService.isCboxPure() && !this.deviceService.isCboxPureMini) {
            this.handleEchoCancel(this.form.get('enable-echo-cancel').getValue());
            this.handleUsbMicList(this.formData.preferredUsbMic);
        }

        this.handleUsbSpeakerList(this.usbSpeakerEnabled);
        this.checkActionForm();
        this.$usbMicWarning.hide();
    },

    /**
     * Store all jQuery selectors to variables.
     */
    storeSelectors: function() {
        this.$usbMicSettings = this.$el.find('.usb-mic-container');
        this.$usbMicWarning = this.$el.find('.usb-mic-warning');
        this.$prefUsbMicList = this.$el.find('#preferredDeviceList');
        this.$preferredDevice = this.$el.find('.preferred-device');
        this.$prefUsbSpeakerList = this.$el.find('#preferredSpeakerDeviceList');
        this.$prefUsbSpeakerWebconfSplit = this.$el.find('#preferredSpeakerWebconfSplit');
        this.$usbSpeakerSettings = this.$el.find('#usb-speaker-settings');
        this.$preferredSpeakerDevice = this.$el.find('.preferred-speaker-device');
        this.$echoCancelSettings = this.$el.find('.echo-cancel-settings');
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#audio-settings'),
            validationContainer: '.input-group'
        });

        if (!this.deviceService.isCboxPure() && !this.deviceService.isCboxPureMini()) {
            if (this.preferredUsbMic) {
                this.handleUsbMicList(true);
            } else {
                this.handleUsbMicList(false);
            }
        }
    },

    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this)
            });
        }

        this.actionView.render();
    },

    /**
     * Run audio test.
     */
    testAudio: function() {
        this.deviceConnection
            .send('setAudioTest');
    },

    createSelects: function() {
        if (!this.deviceService.isCboxPure() && !this.deviceService.isCboxPureMini()) {
            this.createComponent({
                type: 'CustomSelect',
                container: this.$el.find('#preferred-usb-mic-select'),
                native: true,
                name: 'prefUsbMic',
                items: this.usbMicList.length === 0
                    ? [
                        {
                            text: 'settings.no_device_found',
                            value: 'none'
                        }
                    ]
                    : this.usbMicList,
                error: i18n.t('settings.device_not_available'),
                validation: 'checkPreferredUsbMic'
            });
        }

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#preferred-usb-speaker-select'),
            native: true,
            name: 'prefUsbSpeaker',
            items: this.usbSpeakerList.length === 0
                ? [
                    {
                        text: 'settings.no_device_found',
                        value: 'none'
                    }
                ]
                : this.usbSpeakerList,
            error: i18n.t('settings.device_not_available'),
            validation: 'checkPreferredUsbSpeaker'
        });
    },

    /**
     * Create the RangeInput Components for line out/hdmi out 1/hdmi out 2 volume
     */
    createRangeInputs: function() {
        var step = 5;
        var step10 = 10;

        this.createComponent({
            type: 'RangeInput',
            container: this.$el.find('.line-out-volume-range'),
            input: {
                id: 'line-out-volume-range-input',
                value: this.lineOut,
                min: 0,
                minIcon: 'icon-volume-down',
                max: 100,
                maxIcon: 'icon-volume-up',
                step: step,
                onChange: this.onChangeHandler.bind(this, 'lineOut')
            }
        });

        this.createComponent({
            type: 'RangeInput',
            container: this.$el.find('.hdmi-out1-volume-range'),
            input: {
                id: 'hdmi-out1-volume-range-input',
                value: this.hdmiOut1,
                min: 0,
                minIcon: 'icon-volume-down',
                max: 100,
                maxIcon: 'icon-volume-up',
                step: step,
                onChange: this.onChangeHandler.bind(this, 'hdmi1')
            }
        });

        this.createComponent({
            type: 'RangeInput',
            container: this.$el.find('.hdmi-out2-volume-range'),
            input: {
                id: 'hdmi-out2-volume-range-input',
                value: this.hdmiOut2,
                min: 0,
                minIcon: 'icon-volume-down',
                max: 100,
                maxIcon: 'icon-volume-up',
                step: step,
                onChange: this.onChangeHandler.bind(this, 'hdmi2')
            }
        });

        this.createComponent({
            type: 'RangeInput',
            container: this.$el.find('.line-in-level-range'),
            input: {
                id: 'line-in-level-range-input',
                value: this.lineInLevel,
                min: 0,
                minIcon: 'icon-volume-down',
                max: 100,
                maxIcon: 'icon-volume-up',
                step: step,
                onChange: this.onChangeHandler.bind(this, 'lineInLevel')
            }
        });

        this.createComponent({
            type: 'RangeInput',
            container: this.$el.find('.usb-mic-volume-range'),
            input: {
                id: 'usb-mic-volume-range-input',
                value: this.usbMicVolume,
                min: 0,
                minIcon: 'icon-volume-down',
                max: 100,
                maxIcon: 'icon-volume-up',
                step: step,
                onChange: this.onChangeHandler.bind(this, 'usbMicVolume')
            }
        });

        this.createComponent({
            type: 'RangeInput',
            container: this.$el.find('.usb-speaker-volume-range'),
            input: {
                id: 'usb-speaker-volume-range-input',
                value: this.usbSpeakerVolume,
                min: 0,
                minIcon: 'icon-volume-down',
                max: 100,
                maxIcon: 'icon-volume-up',
                step: step,
                onChange: this.onChangeHandler.bind(this, 'usbSpeakerVolume')
            }
        });

        this.createComponent({
            type: 'RangeInput',
            container: this.$el.find('.echo-cancel-delay-range'),
            input: {
                id: 'echo-cancel-delay-range',
                value: this.echoCancelDelay,
                min: 20,
                minIcon: 'icon-minus2',
                max: 500,
                maxIcon: 'icon-plus2',
                step: step10,
                onChange: this.onChangeHandler.bind(this, 'echoCancelDelay')
            }
        });
    },

    /**
     * @param data
     * @param newValue
     */
    onChangeHandler: function(data, newValue) {
        var valueChanged = false;

        switch (data) {
            case 'lineOut':
                if (this.lineOut !== parseInt(newValue)) {
                    valueChanged = true;
                }

                this.lineOut = newValue;
                break;
            case 'hdmi1':
                if (this.hdmiOut1 !== parseInt(newValue)) {
                    valueChanged = true;
                }

                this.hdmiOut1 = newValue;
                break;
            case 'hdmi2':
                if (this.hdmiOut2 !== parseInt(newValue)) {
                    valueChanged = true;
                }

                this.hdmiOut2 = newValue;
                break;
            case 'lineInLevel':
                if (this.lineInLevel !== parseInt(newValue)) {
                    valueChanged = true;
                }

                this.lineInLevel = newValue;
                break;
            case 'usbMicVolume':
                if (this.usbMicVolume !== parseInt(newValue)) {
                    valueChanged = true;
                }

                this.usbMicVolume = newValue;
                break;
            case 'usbSpeakerVolume':
                if (this.usbSpeakerVolume !== parseInt(newValue)) {
                    valueChanged = true;
                }

                this.usbSpeakerVolume = newValue;
                break;
            case 'echoCancelDelay':
                if (this.echoCancelDelay !== parseInt(newValue)) {
                    valueChanged = true;
                }

                this.echoCancelDelay = newValue;
                this.$el.find('#echo-cancel-delay').html(this.echoCancelDelay + ' ms');
                break;
        }

        if (valueChanged) {
            this.handleFormChange();
        }
    },

    handleFormChange: function(data) {
        if (data && data.$el
            && (data.$el.attr('name') === 'usb-mic'
                || data.$el.attr('name') === 'usb-mic-webconf-output'
                || data.$el.attr('name') === 'usb-mic-local-output'
                || data.$el.attr('name') === 'usb-mic-remote-output')
        ) {
            this.$usbMicWarning.show();
        } else if (data && data.input && data.input.name === 'lineOut') {
            if (this.form.get('lineOut').getValue().toString() === 'on') {
                this.lineOut = 100;
            } else {
                this.lineOut = 0;
            }
        } else if (data && data.input && data.input.name === 'preferredUsbMic') {
            this.handleUsbMicList(data.$el.is(':checked'));
            this.$usbMicWarning.show();
        } else if (data && data.input && data.input.name === 'prefUsbMic') {
            this.$usbMicWarning.show();
        } else if (data && data.input && data.input.name === 'usb-speaker') {
            this.handleUsbSpeakerList(data.$el.is(':checked'));
        } else if (data && data.input && data.input.name === 'prefUsbSpeaker') {
            this.handleUsbSpeakerWebconfSplit(data.input.value);
        } else if (data && data.input && data.input.name === 'enable-echo-cancel') {
            this.handleEchoCancel(data.$el.is(':checked'));
        }

        this.checkUsbMic();

        if (this.componentState.getState() === this.componentStates.initalized) {
            this.actionView.open();
            this.changes = true;
        }

        this.checkActionForm();
    },

    /**
     * Switch between show and hide usb-mic-list.
     *
     * @param {Boolean} checked
     */
    handleUsbMicList: function(checked) {
        if (this.deviceService.isCboxPure() || this.deviceService.isCboxPureMini()) {
            return;
        }

        var defaultMicEntry;
        var prefUsbMicValue = '';

        if (checked) {
            defaultMicEntry = this.usbMicList.length > 0 ? this.usbMicList[0] : { 'value': 'none' };
            prefUsbMicValue = this.preferredUsbMic === '' ? defaultMicEntry.value : this.preferredUsbMic;

            this.showUsbMicList(defaultMicEntry);
        } else {
            this.hideUsbMicList();
        }

        this.form
            .get('prefUsbMic')
            .setValue(prefUsbMicValue);
    },

    /**
     * Show usb mic list.
     */
    showUsbMicList: function(defaultMicEntry) {
        this.usbMicListState.changeState(visibleStates.visible);

        this.$prefUsbMicList
            .stop()
            .slideDown(function() {
                if (this.usbMicList.length === 0) {
                    // Workaround for the custom select to check if the validation failed
                    this.form
                        .get('prefUsbMic')
                        .setValue(this.preferredUsbMic === '' ? defaultMicEntry.value : this.preferredUsbMic);
                }
            }.bind(this));
    },

    /**
     * Hide usb mic list.
     */
    hideUsbMicList: function() {
        this.usbMicListState.changeState(visibleStates.hidden);

        if (this.componentState.getState() === this.componentStates.initalized) {
            this.$prefUsbMicList
                .stop()
                .slideUp();
        } else {
            this.$prefUsbMicList.hide();
        }
    },

    /**
     * Switch between show and hide usb-speaker-list.
     *
     * @param {Boolean} checked
     */
    handleUsbSpeakerList: function(checked) {
        var defaultSpeakerEntry;
        var prefUsbSpeakerValue = '';

        if (checked) {
            defaultSpeakerEntry = this.usbSpeakerList.length > 0 ? this.usbSpeakerList[0] : { 'value': 'none' };
            prefUsbSpeakerValue = this.preferredUsbSpeaker === '' ? defaultSpeakerEntry.value : this.preferredUsbSpeaker;

            this.showUsbSpeakerSettings(defaultSpeakerEntry);
        } else {
            this.hideUsbSpeakerSettings();
        }

        this.form
            .get('prefUsbSpeaker')
            .setValue(prefUsbSpeakerValue);
    },

    /**
     * Show usb speaker list.
     */
    showUsbSpeakerSettings: function(defaultSpeakerEntry) {
        var speaker;
        this.usbSpeakerListState.changeState(visibleStates.visible);

        this.$usbSpeakerSettings
            .stop()
            .slideDown(function() {
                if (this.usbSpeakerList.length === 0) {
                    speaker = this.preferredUsbSpeaker === '' ? defaultSpeakerEntry.value : this.preferredUsbSpeaker;

                    // Workaround for the custom select to check if the validation failed
                    this.form
                        .get('prefUsbSpeaker')
                        .setValue(speaker);

                    this.handleUsbSpeakerWebconfSplit(speaker);
                }
            }.bind(this));
    },

    /**
     * Show or hide toggle switch for Webconference to USB speaker.
     * @param prefUsbSpeaker Current speaker
     */
    handleUsbSpeakerWebconfSplit: function(prefUsbSpeaker) {
        // Hide Web Conference split if there is no speaker selected.
        if (prefUsbSpeaker === '' || prefUsbSpeaker === 'none') {
            this.$prefUsbSpeakerWebconfSplit.hide();
        } else {
            this.$prefUsbSpeakerWebconfSplit.show();
        }
    },

    /**
     * Hide usb speaker list.
     */
    hideUsbSpeakerSettings: function() {
        this.usbSpeakerListState.changeState(visibleStates.hidden);

        if (this.componentState.getState() === this.componentStates.initalized) {
            this.$usbSpeakerSettings
                .stop()
                .slideUp();
        } else {
            this.$usbSpeakerSettings.hide();
        }
    },

    /**
     * Handle echo cancel enable/disable. Show/hide echo cancel settings and set delay.
     *
     * @param enabled
     */
    handleEchoCancel: function(enabled) {
        if (enabled) {
            this.showEchoCancel();

            this.$el.find('#echo-cancel-delay').html(this.echoCancelDelay + ' ms');
        } else {
            this.hideEchoCancel();
        }
    },

    /**
     * Show Echo-Cancel section.
     */
    showEchoCancel: function() {
        this.$echoCancelSettings
            .stop()
            .slideDown();
    },

    /**
     * Hide Echo-Cancel section.
     */
    hideEchoCancel: function() {
        if (this.componentState.getState() === this.componentStates.initalized) {
            this.$echoCancelSettings
                .stop()
                .slideUp();
        } else {
            this.$echoCancelSettings.hide();
        }
    },

    /**
     * Check if action form is valid and enable/disable submit buttons.
     */
    checkActionForm: function() {
        this.setPrefUsbMicValidation();

        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * Allow empty usb mic if hidden
     */
    setPrefUsbMicValidation: function() {
        if (this.usbMicListState.getState() === visibleStates.visible) {
            this.$prefUsbMicList.find('#prefUsbMic').attr('data-allow-empty', 'false');
        } else {
            this.$prefUsbMicList.find('#prefUsbMic').attr('data-allow-empty', 'true');
        }
    },

    /**
     * Allow empty usb speaker if hidden
     */
    setPrefUsbSpeakerValidation: function() {
        if (this.usbSpeakerListState.getState() === visibleStates.visible) {
            this.$prefUsbSpeakerList.find('#prefUsbSpeaker').attr('data-allow-empty', 'false');
        } else {
            this.$prefUsbSpeakerList.find('#prefUsbSpeaker').attr('data-allow-empty', 'true');
        }
    },

    saveHandler: function() {
        var usbSpeaker = this.form.get('usb-speaker').getValue();
        var preferredUsbSpeaker = this.form.get('prefUsbSpeaker').getValue();
        var commands = [];

        // Commands for CYNAP and CYNAP Core, Pure and Pure Pro.
        commands = commands.concat([
            {
                command: 'setHdmiOut1Volume',
                data: {
                    volume: this.hdmiOut1
                }
            },
            {
                command: 'setUsbSpeakerVolume',
                data: {
                    volume: this.usbSpeakerVolume
                }
            },
            {
                command: 'setUsbSpeakerCardName',
                data: {
                    name: preferredUsbSpeaker === null ? '' : preferredUsbSpeaker
                }
            }
        ]);

        // Commands only on CYNAP Pure.
        if (this.deviceService.isCboxPure() || this.deviceService.isCboxPureMini()) {
            commands = commands.concat([
                {
                    command: 'setUsbSpeaker',
                    data: {
                        enabled: usbSpeaker,
                        webconfSplit: false
                    }
                }
            ]);
        }

        // Commands that does not run on CYNAP Pure.
        if (!this.deviceService.isCboxPure() && !this.deviceService.isCboxPureMini()) {
            var usbMic = this.form.get('usb-mic').getValue();
            var usbMicWebconf = this.form.get('usb-mic-webconf-output').getValue();
            var usbMicLocal = this.form.get('usb-mic-local-output').getValue();
            var usbMicRemote = this.deviceService.isCbox() || (this.deviceService.isCboxCorePro() && this.features.capture)
                ? this.form.get('usb-mic-remote-output').getValue() : false;
            var preferredUsbMic = this.form.get('prefUsbMic').getValue();
            var echoCancel = this.form.get('enable-echo-cancel').getValue();
            var webconfSplit = this.form.get('webconf-split').getValue();

            commands = commands.concat([
                {
                    command: 'setUsbMicSettings',
                    data: {
                        enable: usbMic,
                        local: usbMicLocal,
                        webconf: usbMicWebconf,
                        remote: usbMicRemote
                    }
                },
                {
                    command: 'setUsbMicVolume',
                    data: {
                        volume: this.usbMicVolume
                    }
                },
                {
                    command: 'setUsbMicCardName',
                    data: {
                        name: preferredUsbMic === null ? '' : preferredUsbMic
                    }
                },
                {
                    command: 'setEchoCancel',
                    data: {
                        enabled: echoCancel
                    }
                },
                {
                    command: 'setEchoCancelDelay',
                    data: {
                        delay: this.echoCancelDelay
                    }
                },
                {
                    command: 'setUsbSpeaker',
                    data: {
                        enabled: usbSpeaker,
                        webconfSplit: webconfSplit
                    }
                }
            ]);
        }

        // Commands that does not run on CYNAP Pure and Pure Pro
        if (!this.deviceService.isCboxPureOrPro() && !this.deviceService.isCboxPureMini()) {
            var localOutput = this.form.get('line-in-local-output').getValue();
            var remoteOutput = this.form.get('line-in-remote-output').getValue();

            commands = commands.concat([
                {
                    command: 'setLineInLocalOutput',
                    data: {
                        enabled: localOutput
                    }
                },
                {
                    command: 'setLineInRemoteOutput',
                    data: {
                        enabled: remoteOutput
                    }
                },
                {
                    command: 'setLineInLevel',
                    data: {
                        level: this.lineInLevel
                    }
                },
                {
                    command: 'setLineOutVolume',
                    data: { volume: this.lineOut }
                }
            ]);
        }

        // Commands that does not run on CYNAP Core, Pure and Pure Pro
        if (this.deviceService.isCbox()) {
            commands = commands.concat([
                {
                    command: 'setHdmiOut2Volume',
                    data: {
                        volume: this.hdmiOut2
                    }
                }
            ]);
        }

        this.deviceConnection.send(commands)
            .done(this.deviceConnection.send('setAudioSettingsActivate'));

        this.saveFrontendAndCustomSettings();
        this.handleSettingsSave();
    },

    /**
     * Save Frontend-Settings and Custom-ui-Settings.
     */
    saveFrontendAndCustomSettings: function() {
        if (!this.deviceService.isCboxPure() && !this.deviceService.isCboxPureMini()) {
            // Save frontend-settings.
            this.frontendSettings.updateSetting({
                tag: 'usePreferredUsbMic',
                value: this.form.get('preferredUsbMic').getValue()
            });
        }

        this.frontendSettings.saveSettings();

        this.customUiSettings.updateSetting({
            tag: 'audioEnabled',
            value: this.form.get('audioEnabled').getValue()
        });

        this.customUiSettings.saveSettings();

        this.emit('main-loop.fast.start', {
            id: 'volume'
        });
    },

    handleSettingsSave: function() {
        this.emit('overlay.remote.focus', true);
        this.$usbMicWarning.hide();

        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.changes = false;
    },

    updateForm: function() {
        this.form.setValues(this.formData);
        this.checkUsbMic();
    },

    cancelHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.loadData()
            .then(function() {
                this.$el.find('#line-out-volume-range-input').val(this.lineOut).trigger('change');
                this.$el.find('#hdmi-out1-volume-range-input').val(this.hdmiOut1).trigger('change');
                this.$el.find('#hdmi-out2-volume-range-input').val(this.hdmiOut2).trigger('change');
                this.$el.find('#line-in-level-range-input').val(this.lineInLevel).trigger('change');
                this.$el.find('#usb-mic-volume-range-input').val(this.usbMicVolume).trigger('change');
                this.$el.find('#usb-speaker-volume-range-input').val(this.usbSpeakerVolume).trigger('change');
                this.$el.find('#echo-cancel-delay-range').val(this.echoCancelDelay).trigger('change');
                this.$el.find('#echo-cancel-delay').val(this.echoCancelDelay).trigger('change');
                this.form.resetValues();
                this.$usbMicWarning.hide();
                this.handleUsbMicList(this.formData.preferredUsbMic);
                this.changes = false;
            }.bind(this));
    },

    /**
     * Show/hide USB mic settings.
     *
     */
    checkUsbMic: function() {
        if (this.deviceService.isCboxPure() || this.deviceService.isCboxPureMini()) {
            return;
        }

        if (this.form.get('usb-mic').getValue()) {
            this.$usbMicSettings.show();
        } else {
            this.$usbMicSettings.hide();
        }
    },

    destroy: function() {
        clearInterval(this.mixerInterval);
        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    hasChanges: function() {
        if (!this.actionView) {
            return {
                hasChanges: false,
                invalid: false
            };
        }

        return {
            hasChanges: this.changes,
            invalid: $(this.actionView.$submitBtn).prop('disabled')
        };
    }
});
