// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"col-xs-6 switch-below-text\" id=\"wlan-routing\">\n                        <label class=\"input-label\" for=\"routing\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.routing",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":65},"end":{"line":63,"column":89}}}))
    + "</label>\n                        <div class=\"btn-switch-container\">\n                            <input name=\"routing\" type=\"checkbox\" class=\"btn-switch\" id=\"routing\"/>\n                            <div class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </div>\n                        </div>\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"wlan\" name=\"wlan\">\n        <div data-nav-area=\"select, .btn-switch, .is-focusable:not(:disabled)\"\n             data-nav-scrolltop=\"true\"\n             data-nav-trapped\n             data-nav-area-default>\n            <div id=\"wlan-connection-state-container\" class=\"row settings-list-row\">\n                <div class=\"col-xs-8\">\n                    <h2>\n                        <span id=\"wlan-connection-state\" class=\"wlan-connection-state\">\n                            <span class=\"wlan-connection-state-disconnected\">\n                                <div class=\"wlan-connection-state-point\"></div>\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.disconnected",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":32},"end":{"line":13,"column":61}}}))
    + "\n                            </span>\n                            <span class=\"wlan-connection-state-connected\">\n                                <div class=\"wlan-connection-state-point\"></div>\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.connected",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":32},"end":{"line":17,"column":58}}}))
    + "\n                            </span>\n                            <span class=\"wlan-connection-state-running\">\n                                <div class=\"wlan-connection-state-point\"></div>\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ap_connected",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":32},"end":{"line":21,"column":61}}}))
    + "\n                            </span>\n                            <span class=\"wlan-connection-state-not-running\">\n                                <div class=\"wlan-connection-state-point\"></div>\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ap_disconnected",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":32},"end":{"line":25,"column":64}}}))
    + "\n                            </span>\n                            <span class=\"wlan-connection-state-failed\">\n                                <div class=\"wlan-connection-state-point\"></div>\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.failed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":32},"end":{"line":29,"column":55}}}))
    + "\n                            </span>\n                            <span class=\"wlan-connection-state-scanning\">\n                                <div class=\"wlan-connection-state-point\"></div>\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.scanning",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":32},"end":{"line":33,"column":57}}}))
    + "\n                            </span>\n                            <span class=\"wlan-connection-state-authenticating\">\n                                <div class=\"wlan-connection-state-point\"></div>\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.authenticating",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":32},"end":{"line":37,"column":63}}}))
    + "\n                            </span>\n                        </span>\n                    </h2>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\">\n                <div class=\"col-xs-6\">\n                    <div id=\"wlan-mode-select\"></div>\n                </div>\n                <div class=\"col-xs-6\">\n                    <div class=\"input-group\" id=\"mac-container\">\n                        <label class=\"input-label\" for=\"mac\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.mac",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":61},"end":{"line":50,"column":82}}}))
    + "</label>\n                        <input type=\"text\" class=\"input\" name=\"mac\" id=\"mac\" disabled />\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"row settings-list-secundary\">\n                <div class=\"col-xs-6\">\n                    <div id=\"country-select\"></div>\n                </div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"if_or")||(depth0 && lookupProperty(depth0,"if_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCboxPureOrPro") : depth0),(depth0 != null ? lookupProperty(depth0,"isCboxPureMini") : depth0),{"name":"if_or","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":16},"end":{"line":71,"column":26}}})) != null ? stack1 : "")
    + "                <div class=\"col-xs-6\">\n                    <div id=\"band-select\"></div>\n                </div>\n            </div>\n        </div>\n\n        <div id=\"access-point-settings-container\" class=\"wlan-access-point-settings-container is-disabled\"></div>\n\n        <div id=\"wlan-settings\"></div>\n\n        <div id=\"form-action-container-wlan\" class=\"form-primary-background\"></div>\n    </form>\n\n    <div id=\"wlan-ping\">\n        <div class=\"row settings-list-row\">\n            <div class=\"col-xs-12\">\n                <h2>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.ping",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":88,"column":20},"end":{"line":88,"column":42}}}))
    + "</h2>\n            </div>\n        </div>\n        <div class=\"row settings-list-secundary\">\n            <div id=\"wlan-ping-container\" class=\"ping-container-outer\"></div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});
