'use strict';

var app = require('../../app');
var matrixboxTpl = require('./matrix-box.hbs');
var platform = require('../../../platform/platform');
var LayoutManager = require('../../../layout/layout');
var DURATION = 300;

var boxType = {
    documents: 'FileBox',
    devices: 'DiscoveryBox',
    configs: 'SelectBox',
    station: 'SelectBox'
};
/**
 * Matrix box (for device discovery, file handling)
 */
app.component('MatrixBox', {
    template: matrixboxTpl,

    /**
     * Create box and component.
     * Configurator: device discovery component
     * Room/Main view: file handling component
     */
    createBox: function() {
        this.createComponent({
            type: boxType[this.options.matrixBox],
            container: this.$matrixBox,
            isConfigurator: this.options.isConfigurator,
            items: this.options.items,
            isStationMenu: this.options.matrixBox === 'station',
            checked: this.options.checked
        });
    },

    storeSelectors: function() {
        this.$matrixBox = this.$el.find('#matrix-box');
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();
        this.createBox();
    },

    bindEvents: function() {
        this.on('matrix-box.toggle.' + this.options.matrixBox, this.toggleMatrixBox.bind(this));
        this.on('matrix-box.hide', this.closeMatrixBox.bind(this));
        this.on('overlay-backdrop.click', this.closeMatrixBox.bind(this, true));
        this.on('matrix-box.open.' + this.options.matrixBox, this.openMatrixBox.bind(this));

        LayoutManager.on('window.resize', this.closeMatrixBox.bind(this));
    },

    /**
     * Close or open matrix box.
     *
     * @param animate {boolean} true/false
     */
    toggleMatrixBox: function(animate, posX) {
        if (this.$matrixBox.hasClass('is-open')) {
            this.closeMatrixBox(animate);
        } else {
            this.openMatrixBox(animate, posX, 0);
        }
    },

    /**
     * Open matrix box and start device discovery if this is the configurator.
     *
     * @param animate {boolean} true/false
     */
    openMatrixBox: function(animate, posX, posY) {
        // Hide others.
        this.emit('matrix-box.hide', animate);
        this.emit('matrix-menu.hide', animate);

        if (animate) {
            this.$matrixBox
                .css('margin-left', posX + 'px')
                .addClass('is-open')
                .show()
                .stop()
                .animate({
                    top: posY ? posY + 'px' : '1px'
                }, {
                    duration: DURATION,
                    complete: function() {
                        this.addScrollbar();
                    }.bind(this)
                });
        } else {
            this.$matrixBox
                .addClass('is-open')
                .show()
                .css('top', posY ? posY + 'px' : '1px')
                .css('margin-left', posX + 'px');

            this.addScrollbar();
        }

        this.emit('overlay-backdrop.show');
        this.emit('matrix-box.update', true);
    },

    /**
     * Close matrix box.
     *
     * @param animate {boolean} true/false
     */
    closeMatrixBox: function(animate) {
        if (!this.$matrixBox.hasClass('is-open')) {
            return;
        }

        this.removeScrollbar();

        if (animate && this.options.matrixBox !== 'station') {
            this.$matrixBox
                .removeClass('is-open')
                .stop()
                .animate({
                    top: '-' + this.$matrixBox.height() + 'px'
                }, {
                    duration: DURATION,
                    complete: function() {
                        this.$matrixBox.hide();
                    }.bind(this)
                });
        } else {
            this.$matrixBox
                .removeClass('is-open')
                .css('top', '-' + this.$matrixBox.height() + 'px')
                .hide();
        }

        this.emit('overlay-backdrop.hide');

        if (this.options.onClose) {
            this.options.onClose();
        }
    },

    /**
     * RELEASE-2003
     * Add a scrollbar.
     */
    addScrollbar: function() {
        if (platform.checks.isEdge || platform.checks.isFirefox) {
            this.$el.find('.matrix-box-list').addClass('auto-scroll');
        } else {
            this.$el.find('.matrix-box-list').addClass('show-scroll');
        }
    },

    /**
     * RELEASE-2003
     * Remove scrollbar.
     */
    removeScrollbar: function() {
        this.$el.find('.matrix-box-list')
            .removeClass('show-scroll')
            .removeClass('auto-scroll');
    }
});
