'use strict';

var _ = require('lodash');
var $ = require('jquery');
var app = require('../../../../app');
var accessPointTpl = require('./wlan-access-point-pure.hbs');
var FormActionView = require('./../../form-action-view');
var regionStates = require('./../../../../states');

var states = {
    visible: 'visible',
    hidden: 'hidden'
};

var connectionStates = {
    disconnected: 'disconnected',
    connected: 'connected',
    scanning: 'scanning',
    authenticating: 'authenticating',
    failed: 'failed'
};

app.component('WlanAccessPointPure', {
    template: accessPointTpl,

    getAccessKey: function() {
        return {
            'roleName': 'WlanSettings',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.init = false;
        this.remote = this.getService('RemoteService');
        this.formManager = this.getService('FormManager');
        this.country = '';
        this.visibility = this.createStateMachine({
            state: states.hidden,
            states: states
        });

        this.connectionState = this.createStateMachine({
            state: connectionStates.disconnected,
            states: connectionStates
        });

        this.addStateTransitions();
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.createChannelSelect();
        this.createMaxClientsSelect();
        this.initForm();

        this.loadData()
            .then(function() {
                this.initFormAction();
                this.bindEvents();
                this.bindDOMEvents();
                this.actionView.disableCancelButton();

                // Delay init --> due to form.setValues(data)
                setTimeout(function() {
                    this.init = true;
                }.bind(this), 500);

                this.openActionView();

                this.updateHandler();
            }.bind(this));
    },

    storeSelectors: function() {
        this.$connectMsg = this.$el.find('#wlan-wait-for-ap');
    },

    /**
     * Bind all custom events.
     */
    bindEvents: function() {
        this.on('wlan-mode.changed.access-point', this.handleFormChange.bind(this, true));
        this.on('wlan-access-point.action.view.open', this.openActionView.bind(this));
        this.on('main-loop.update', this.updateHandler.bind(this));
        this.on('settings.save-changes', this.saveHandler.bind(this));
        this.on('wlan-access-point.changed.routing', this.handleFormChange.bind(this, false));
    },

    bindDOMEvents: function() {
        this.form.on('change.input', this.handleFormChange.bind(this, true));
    },

    initForm: function() {
        this.form = this.formManager.create({
            el: this.$el.find('#wlan-access-point-pure-form'),
            validationContainer: '.input-group'
        });
    },

    /**
     * Initialize Save and Cancel button.
     */
    initFormAction: function() {
        if (!this.actionView) {
            this.actionView = new FormActionView(app, {
                selector: this.$el.find('#form-action-container-access-point'),
                onSubmit: this.saveHandler.bind(this),
                onCancel: this.cancelHandler.bind(this),
                autoClose: false
            });
        }

        this.actionView.render();

        setTimeout(function() {
            this.actionView.disableCancelButton();
        }.bind(this), 350);
    },

    handleFormChange: function(hasChanges) {
        this.actionView.enableCancelButton();
        this.checkActionForm();
        this.actionView.open();

        if (this.init && hasChanges) {
            this.handleChanges(true);
        }
    },

    checkActionForm: function() {
        if (this.form.validate()) {
            this.actionView.enableSubmitButton();
        } else {
            this.actionView.disableSubmitButton();
        }
    },

    /**
     * Open Action View.
     * If action view is not initialized do that.
     */
    openActionView: function() {
        if (this.actionView) {
            this.actionView.open();
        } else {
            this.initFormAction();
        }
    },

    /**
     * Update wlan status to hide the loaden when the access-point has been connected.
     */
    updateHandler: function() {
        this.deviceConnection
            .send('getWlanStatus')
            .then(this.updateWlanConnectionState.bind(this));
    },

    /**
     * Change connection state.
     */
    updateWlanConnectionState: function(data) {
        var state = connectionStates[data.wlanStatus];
        if (state !== this.connectionState.getState()) {
            this.connectionState.changeState(state);
        }
    },

    /**
     * Show loader and message from the connection-message
     */
    showTryToConnectMessage: function() {
        this.$connectMsg
            .velocity('slideDown', 200);

        this.tryToConnectTimeout = setTimeout(function() {
            this.hideTryToConnectMessage();
        }.bind(this), 60000);
    },

    /**
     * Hide connection loader and message.
     */
    hideTryToConnectMessage: function() {
        this.$connectMsg
            .velocity('slideUp', 200);
    },

    /**
     * Activate access-point.
     */
    saveHandler: function() {
        this.emit('overlay.remote.focus', true);
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });

        this.emit('wlan.save');
        this.showTryToConnectMessage();
        this.handleSettingsSave();

        var data = this.form.serialize();

        this.deviceConnection
            .send([
                {
                    command: 'setWlanDirectChannel',
                    data: { channel: data.channel }
                },
                {
                    command: 'setWlanApIpAddress',
                    data: { ipAddress: data.ip }
                },
                {
                    command: 'setWlanApSubnetMask',
                    data: { subnetMask: data.subnet }
                },
                {
                    command: 'setWlanApMaxClients',
                    data: { nrClients: data.maxClients }
                }
            ]).then(function() {
                this.emit('overlay.remote.focus', true);
                this.emit('overlay.header.update', {
                    actionButtonKey: 'settings.action_button_saved',
                    actionBtnType: null,
                    actionBtnDelay: 2000,
                    actionBtnFadeout: 500
                });
            }.bind(this));

        setTimeout(function() {
            this.connectionState.changeState(connectionStates.disconnected);
            this.handleChanges(false);
            this.actionView.disableCancelButton();
            this.deviceConnection.send('setWlanSettingsActivate');
        }.bind(this), 500);
    },

    /**
     * Load access point data.
     *
     * @returns {*}
     */
    loadData: function() {
        return this.deviceConnection
            .send([
                'getWlanDirectChannel',
                'getWlanApIpAddress',
                'getWlanApSubnetMask',
                'getWlanApMaxClients',
                'getWlanCountry',
                'getAdjustmentWlanRegion'
            ])
            .then(function(channel, ip, subnet, clients, country, region) {
                var data = {
                    channel: channel.channel,
                    ip: ip.ipAddress,
                    subnet: subnet.subnetMask,
                    maxClients: clients.nrClients
                };

                this.form.setValues(data);
                this.country = country.country;

                this.updateCountrySelect(region.region);
            }.bind(this));
    },

    /**
     * Calls on clicked the cancel button.
     *
     * @method cancelHandler
     */
    cancelHandler: function() {
        this.form.resetValues();
        this.actionView.disableCancelButton();
        this.emit('wlan.cancel');
        this.handleChanges(false);
    },

    /**
     * Show success message on header.
     */
    handleSettingsSave: function() {
        this.emit('overlay.header.update', {
            actionButtonKey: 'settings.action_button_saved',
            actionBtnType: null,
            actionBtnDelay: 2000,
            actionBtnFadeout: 500
        });
    },

    /**
     * @method addStateTransitions
     */
    addStateTransitions: function() {
        this.connectionState.addTransitions({
            '> connected': function() {
                clearTimeout(this.tryToConnectTimeout);

                this.hideTryToConnectMessage();
            }
        });
    },

    createChannelSelect: function() {
        var items = [
            {
                text: 'Auto',
                value: 'auto'
            },
            {
                text: '1',
                value: '1'
            },
            {
                text: '6',
                value: '6'
            },
            {
                text: '11',
                value: '11'
            }];

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#channel-select-pure'),
            label: 'settings.channel',
            native: true,
            name: 'channel',
            items: items
        });
    },

    /**
     * Create select for choosing maximum number of clients.
     */
    createMaxClientsSelect: function() {
        var items = [];
        var max = 8;

        for (var i = 1; i <= max; i++) {
            items.push({
                value: i,
                text: i
            });
        }

        this.createComponent({
            type: 'CustomSelect',
            container: this.$el.find('#max-clients-select-pure'),
            label: 'settings.max_clients',
            native: true,
            name: 'maxClients',
            items: items
        });
    },

    /**
     * Update Country select.
     *
     * @param {bool} region1
     */
    updateCountrySelect: function(region) {
        var countryItems = [];
        var tempItems = region === 1 ? regionStates.countriesRegion1 : regionStates.countriesRegion2;

        _.each(tempItems, function(item) {
            countryItems.push({
                text: item.text,
                value: item.value
            });
        });

        this.emit('select.country.update', {
            items: countryItems,
            selected: this.country
        });
    },

    destroy: function() {
        this.formManager.destroy(this.form);

        if (this.actionView) {
            this.actionView.destroy();
        }
    },

    /**
     * Handle settings changes. This method tells to the wlan component if there is some changes.
     *
     * @param {boolean} change
     */
    handleChanges: function(change) {
        var changes = {
            hasChanges: change,
            invalid: false
        };

        if (this.init && this.actionView) {
            changes.invalid = $(this.actionView.$submitBtn).prop('disabled');
        }

        this.emit('wlan-settings.changed', changes);
    }
});
