module.exports={
    "modifier":
    {
        "uppercase": 42,
        "&#93;": 100, //]
        "&#91;": 100, //[
        "&#125;": 100, //}
        "&#123;": 100, //{
        "&#164;": 100, //¤

        "&#64;": 100, //@
        "&#35;": 100, //#
        "&#124;": 100, //|
        "&#92;": 100, //\
        "&#126;": 100, //~
        "&#96;": 100, //`
        "&#8364;": 100, //€
        "&#94;": 100 //^
    },

    "modifier_mobile":
    {
        "&#48;": 42, //0
        "&#49;": 42, //1
        "&#50;": 42, //2
        "&#51;": 42, //3
        "&#52;": 42, //4
        "&#53;": 42, //5
        "&#54;": 42, //6
        "&#55;": 42, //7
        "&#56;": 42, //8
        "&#57;": 42, //9
        "&#46;": 42, //.
        "&#47;": 42, ///
        "&#37;": 42, //%
        "&#63;": 42, //?
        "&#43;": 42, //+
        "&#62;": 42, //>
        "&#163;": 42, //£
        "&#167;": 42, //§
        "&#176;": 42 //°
    },

    "ie_specialkeys_mobile": {},

    "ie_specialkeys": {
        "&#96;": 26 //¨
    },

    "&#178;": 1, //²
    "&#49;": 2, //1
    "&#38;": 2, //&
    "&#126;": 3, //~
    "&#233;": 3, //é
    "&#50;": 3, //2
    "&#51;": 4, //3
    "&#34;": 4, //"
    "&#35;": 4, //#
    "&#52;": 5, //4
    "&#123;": 5, //{
    "&#39;": 5, //'
    "&#53;": 6, //5
    "&#40;": 6, //(
    "&#91;": 6, //[
    "&#54;": 7, //6
    "&#45;": 7, //-
    "&#124;": 7, //|
    "&#55;": 8, //7
    "&#232;": 8, //è
    "&#96;": 8, //`
    "&#56;": 9, //8
    "&#95;": 9, //_
    "&#92;": 9, //\
    "&#57;": 10, //9
    "&#94;": 10, //^
    "&#48;": 11, //0
    "&#224;": 11, //à
    "&#64;": 11, //@
    "&#231;": 10, //ç
    "&#41;": 12, //)
    "&#176;": 12, //°
    "&#93;": 12, //]
    "&#125;": 13, //}
    "&#43;": 13, //+

    "&#97;": 16, //a
    "&#122;": 17, //z
    "&#8364;": 18, //€
    "&#121;": 21, //z
    "&#36;": 27, //$
    "&#163;": 27, //£
    "&#164;": 27, //¤

    "&#113;": 30, //q

    "&#109;": 39, //m

    "&#249;": 40, //m
    "&#37;": 40, //m
    "&#42;": 43, //*
    "&#119;": 44, //w
    "&#44;": 50, //,
    "&#63;": 50, //?
    "&#46;": 51, //;
    "&#59;": 51, //.
    "&#58;": 52, //:
    "&#47;": 52, ///
    "&#33;": 53, //!
    "&#167;": 53, //§
    "&#60;": 86, //<
    "&#62;": 86, //>

    "tab": 15,
    "capslock": 58,
    "caps_lock": 58,
    "shift": 42,
    "shiftleft": 42,
    "control": 29,
    "backspace": 14,
    "&#32;": 57, //" " (space)
    "space": 57,
    "enter": 28,
    "up": 103,
    "arrowup": 103,
    "left": 105,
    "arrowleft": 105,
    "right": 106,
    "arrowright": 106,
    "down": 108,
    "arrowdown": 108
}
