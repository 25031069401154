// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"col-xs-11\"></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"col-xs-2 col-0 header-matrix-item"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isConfigurator") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":61},"end":{"line":7,"column":103}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"btnCount") : depth0),"1",{"name":"if_equals","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":69}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isConfigurator") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":25,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n\n                <div class=\"col-xs-2 col-1 header-matrix-item "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isConfigurator") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":62},"end":{"line":28,"column":103}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"btnCount") : depth0),"2",{"name":"if_equals","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":29,"column":69}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isConfigurator") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":46,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isConfigurator") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":16},"end":{"line":56,"column":23}}})) != null ? stack1 : "")
    + "\n                <div class=\"col-xs-1 header-matrix-item close-button-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"menuButton") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":20},"end":{"line":64,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"closeButton") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":20},"end":{"line":71,"column":27}}})) != null ? stack1 : "")
    + "\n                    <!-- Groupwork/Teaching Mode -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showGroupworkTeachingToggle") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":20},"end":{"line":85,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " configurator";
},"6":function(container,depth0,helpers,partials,data) {
    return " is-last-btn";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"toolbar-item action-btn\" id=\"overlay-devices-btn\" data-action=\"devices\">\n                            <span class=\"text-container\" data-overlay=\"devices\"> "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"matrix_configurator.devices",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":81},"end":{"line":11,"column":116}}}))
    + " </span>\n                            <span class=\"icon-container\">\n                                <span class=\"icon icon-device\"></span>\n                                <span class=\"icon icon-arrow-nav\"></span>\n                            </span>\n                        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"toolbar-item action-btn "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isGroupworkActive") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":60},"end":{"line":18,"column":103}}})) != null ? stack1 : "")
    + "\" id=\"overlay-documents-btn\" data-action=\"documents\">\n                            <span class=\"text-container\" data-overlay=\"documents\"> "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(alias1,"matrix.documents",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":83},"end":{"line":19,"column":107}}}))
    + " </span>\n                            <span class=\"icon-container\">\n                                <span class=\"icon icon-document-stack\"></span>\n                                <span class=\"icon icon-arrow-nav\"></span>\n                            </span>\n                        </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "is-disabled";
},"13":function(container,depth0,helpers,partials,data) {
    return "configurator";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"toolbar-item\" id=\"overlay-save-btn\" data-action=\"save\">\n                            <span class=\"text-container\"> "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"settings.save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":58},"end":{"line":32,"column":79}}}))
    + " </span>\n                                <span class=\"loader small hidden\"></span>\n                                <span class=\"icon icon-save\"></span>\n                        </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"toolbar-item\">\n                            <span class=\"text-container\"> "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"matrix.show_all",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":58},"end":{"line":38,"column":81}}}))
    + " </span>\n                            <span class=\"btn-switch-container pull-left\">\n                            <input name=\"showAll\" id=\"showAll\" type=\"checkbox\" class=\"btn-switch\"/>\n                            <span class=\"btn-switch-toggle-container\">\n                                <div class=\"btn-switch-toggle\"></div>\n                            </span>\n                        </span>\n                        </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"col-xs-2 col-2 header-matrix-item"
    + ((stack1 = (lookupProperty(helpers,"if_equals")||(depth0 && lookupProperty(depth0,"if_equals"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"btnCount") : depth0),"3",{"name":"if_equals","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":65},"end":{"line":50,"column":118}}})) != null ? stack1 : "")
    + "\">\n                        <div class=\"toolbar-item action-btn\" id=\"overlay-configs-btn\" data-action=\"configs\">\n                            <span id=\"selected-config\" class=\"text-container\" data-overlay=\"configs\"></span>\n                            <span class=\"icon icon-arrow-nav\"></span>\n                        </div>\n                    </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-xs-1 action-btn toolbar-item\" id=\"overlay-menu-btn\" data-action=\"menu\">\n                            <span class=\"menu-text\" data-overlay=\"menu\"> "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"matrix.menu",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":73},"end":{"line":61,"column":92}}}))
    + " </span>\n                            <span class=\"icon icon-burger-menu\"></span>\n                        </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "                        <div id=\"overlay-close-btn\" class=\"overlay-close-btn\">\n                            <button class=\"btn btn-no-style btn-no-active pull-right p-right-30 overlay-header-item overlay-action-btn focusable\">\n                                <span class=\"icon-btn-back\"></span>\n                            </button>\n                        </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"col-xs-2 col-1 header-matrix-item toggle-btn is-last-btn\">\n                            <div class=\"toolbar-item action-btn "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isGroupworkActive") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":64},"end":{"line":76,"column":102}}})) != null ? stack1 : "")
    + "\" id=\"groupwork-mode-btn\" data-action=\"groupwork\">\n                                <span class=\"text-container\" data-overlay=\"groupwork\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"matrix.groupwork_mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":77,"column":86},"end":{"line":77,"column":115}}}))
    + "</span>\n                            </div>\n                        </div>\n                        <div class=\"col-xs-2 col-0 header-matrix-item toggle-btn\">\n                            <div class=\"toolbar-item action-btn "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isGroupworkActive") : depth0),{"name":"unless","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":64},"end":{"line":81,"column":110}}})) != null ? stack1 : "")
    + "\" id=\"teaching-mode-btn\" data-action=\"teaching\">\n                                <span class=\"text-container\" data-overlay=\"teaching\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"matrix.teaching_mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":82,"column":85},"end":{"line":82,"column":113}}}))
    + "</span>\n                            </div>\n                        </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "active";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"overlay-header-matrix\" class=\"overlay-header-matrix\" data-count=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"btnCount") || (depth0 != null ? lookupProperty(depth0,"btnCount") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"btnCount","hash":{},"data":data,"loc":{"start":{"line":1,"column":74},"end":{"line":1,"column":86}}}) : helper)))
    + "\">\n    <form id=\"header-matrix\" name=\"header-matrix\">\n        <div class=\"row\">\n"
    + ((stack1 = (lookupProperty(helpers,"if_or")||(depth0 && lookupProperty(depth0,"if_or"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isIE") : depth0),(depth0 != null ? lookupProperty(depth0,"noTemplate") : depth0),{"name":"if_or","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":87,"column":22}}})) != null ? stack1 : "")
    + "        </div>\n    </form>\n</div>\n";
},"useData":true});
