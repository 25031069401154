'use strict';

var _ = require('lodash');
var app = require('../../../app');
var ScrollView = require('../../../../scroll-view');
var mainTpl = require('./visualizer-settings.hbs');
var parentEntryTpl = require('./parent-entry.hbs');
var controlEntryTpl = require('./control-entry.hbs');
var btnEntryTpl = require('./btn-entry.hbs');

var entryConfigs = {
    parent: {
        tpl: parentEntryTpl
    },
    control: {
        tpl: controlEntryTpl,
        componentOptions: {
            type: 'CustomSelect',
            container: '.settings-input-container',
            native: true
        }
    },
    btn: {
        tpl: btnEntryTpl,
        componentOptions: {
            type: 'Button',
            container: '.settings-input-container'
        }
    }
};

app.component('VisualizerSettings', {
    template: mainTpl,
    className: 'full-height',

    /**
     * @method initialize
     */
    initialize: function() {
        this.$lastAppendEl = null;
        this.scrollView = null;
        this.visualizerSettings = this.getService('VisualizerSettingsService');
        this.remote = this.getService('RemoteService');

        app.getService('ConnectionFactoryService')
            .afterCreated('visualizer', function(visualizerConnection) {
                this.visualizerConnection = visualizerConnection;
            }.bind(this));
    },

    /**
     * @method destroy
     */
    destroy: function() {
        if (this.scrollView) {
            this.scrollView.destroy();
        }
    },

    /**
     * @method postRender
     */
    postPlaceAt: function() {
        this.storeSelectors();
        this.bindDOMEvents();
        this.startPreview();

        this.visualizerSettings.getEntry(this.options.configs.entryId)
            .then(this.getEntryHandler.bind(this));
    },

    startPreview: function() {
        var options = {
            type: 'VisualizerPreview',
            container: this.$el.find('.visualizer-preview-container')
        };

        this.createComponent(options);
    },

    /**
     * @method storeSelectors
     */
    storeSelectors: function() {
        this.$entriesContainer = this.$el.find('#visualizer-settings-list');
    },

    /**
     * @method bindDOMEvents
     */
    bindDOMEvents: function() {
        this.$el.on('click', '.settings-list-row', this.entrySelectHandler.bind(this));
    },

    /**
     * @method entrySelectHandler
     * @param {Object} event
     */
    entrySelectHandler: function(event) {
        var $element = this.$el.find(event.currentTarget);
        var type = $element.data('type');
        var handler = this.entrySelectHandlers[type];

        if (handler) {
            handler.call(this, $element);
        }
    },

    /**
     * Go to the settings
     * @type {Object}
     */
    entrySelectHandlers: {
        'parent': function($entry) {
            this.emit('overlay.open', {
                id: 'visualizer-settings',
                titleKey: $entry.data('title'),
                entryId: $entry.data('pn')
            });
        }
    },

    /**
     * @method getEntryHandler
     * @param {Object} entry
     */
    getEntryHandler: function(entry) {
        if (entry.entries && entry.entries.length) {
            this.prepareEntries(entry.entries);
        }
    },

    /**
     * Loads values of the entries
     * @method prepareEntries
     * @param {Array} entries
     */
    prepareEntries: function(entries) {
        var $entrieslist = this.$('<div/>', {
            class: 'settings-list settings-list-scroll-container full-height'
        });

        entries = this.createEntryRelations(entries);

        this.loadEntryValues(entries, {
            onValueLoaded: function(entry) {
                if (entry.ctrl !== 'exit') {
                    entry = this.prepareEntry(entry);

                    if (!entry.hidden) {
                        $entrieslist.append(this.renderEntry(entry));
                    }
                }
            }.bind(this),
            onDone: function() {
                this.$entriesContainer.html($entrieslist);

                this.styleListByEntry(entries);
                this.scrollView = new ScrollView({
                    $container: this.$el.find('.settings-list-scroll-container'),
                    $items: this.$el.find('.visualizer-list-entry'),
                    entryFocusable: false
                });
            }.bind(this)
        });
    },

    /**
     * Defines the entry type
     * @method prepareEntry
     * @param {Object} entry
     */
    prepareEntry: function(entry) {
        var handlerName;

        if (entry.options && entry.options[0].min !== void 0 && entry.options[0].max !== void 0) {
            handlerName = 'min-max';
        } else if (entry.subRelation) {
            handlerName = 'relation';
        }

        if (this.preparations[handlerName]) {
            entry = this.preparations[handlerName].call(this, entry);
        }

        return entry;
    },

    /**
     * @type {Object}
     */
    preparations: {
        'min-max': function(entry) {
            var min = entry.options[0].min;
            var max = entry.options[0].max;
            var fmt = entry.options[0].fmt;
            var options = [];
            var i;

            // When we have numbers e.g. from -10 to 10, or the numbers are minus values, we need the paramLength of 2 else it will be calculated in wolport-client-commands.
            if (min < 0) {
                entry.paramLength = 2;
            }

            for (i = min; i <= max; i++) {
                var value = i;

                // Calculate decimal value instead of -100 we get (65536 - 100)
                if (value < 0) {
                    value = Math.pow(2, entry.paramLength * 8) + i;
                }

                options.push({
                    par: value,
                    title: fmt.replace(/%(u|d)(%|)/, i.toString())
                });
            }

            entry.options = options;

            return entry;
        },

        /**
         * Render new sub entries
         * @param entry
         * @returns {*}
         */
        'relation': function(entry) {
            this.$lastAppendEl = null;

            entry.subRelation.forEach(function(subEntry) {
                var hidden = (subEntry.sub !== entry.par);

                if (subEntry.$el) {
                    if (hidden && !subEntry.hidden) {
                        subEntry.$el.remove();
                        app.removeComponent(subEntry.componentId);
                        subEntry.componentId = null;
                    } else if (!hidden && subEntry.hidden) {
                        this.replaceEntry(entry, this.renderEntry(subEntry));
                    }
                } else if (!hidden && subEntry.hidden) {
                    this.replaceEntry(entry, this.renderEntry(subEntry));
                }

                subEntry.hidden = hidden;
            }.bind(this));

            this.styleListByEntry([entry]);

            return entry;
        }
    },

    /**
     * When a entry has been changed and this has new sub entries place this entries to after the parent node
     * @param {object} parent
     * @param {jQuery.Element} $newSubEntry
     */
    replaceEntry: function(parent, $el) {
        var $parent = this.$lastAppendEl || parent.$el;
        $parent.after($el);
        this.$lastAppendEl = $el;
    },

    /**
     * Added primary and secundary style on the list
     * @param entries
     */
    styleListByEntry: function(entries) {
        _.forEach(entries, function(parent) {
            var hasSub = false;

            if (parent.pn) {
                return;
            }

            if (parent.subRelation) {
                _.forEach(parent.subRelation, function(child) {
                    if (!!child.$el && !child.hidden) {
                        hasSub = true;
                        child.$el
                            .removeClass('settings-list-row')
                            .addClass('settings-list-secundary');
                    }
                });

                if (hasSub) {
                    parent.$el.addClass('has-content');
                } else if (parent.$el) {
                    parent.$el.removeClass('has-content');
                }
            } else if (parent.$el) {
                parent.$el.removeClass('has-content');
            }
        });
    },

    /**
     * @method createEntryRelations
     * @param {Array} entries
     */
    createEntryRelations: function(entries) {
        var subRelation = null;
        var isSubEntry = false;
        var parentEntry = null;

        entries.forEach(function(entry, idx) {
            if (entry.sub !== void 0) {
                if (!isSubEntry) {
                    parentEntry = entries[idx - 1];
                }

                isSubEntry = true;
                subRelation = subRelation || [];
                subRelation.push(entry);
            }

            if (isSubEntry && entry.sub === void 0) {
                parentEntry.subRelation = subRelation;
                subRelation = null;
                isSubEntry = false;
            }
        }.bind(this));

        return entries;
    },

    /**
     * @method loadEntryValues
     * @param {Array} entries
     * @param {Object} options
     */
    loadEntryValues: function(entries, options) {
        var entriesLength = entries.length;
        var index = 0;
        var visualizerConnection = this.visualizerConnection;
        var load = function(entry) {
            var doneCb = (index === (entriesLength - 1)) ? options.onDone : app.$.noop;

            if (index > (entriesLength - 1)) {
                return;
            }

            if (entry.cmd && entry.options && entry.ctrl !== 'btn') {
                visualizerConnection
                    .send('getVzDirectSetting', {
                        cmd: entry.cmd
                    })
                    .then(function(data) {
                        entry.par = data.parameter;
                        options.onValueLoaded(entry);
                        doneCb();
                        load(entries[++index]);
                    });
            } else {
                options.onValueLoaded(entry);
                doneCb();
                load(entries[++index]);
            }
        };

        load(entries[index]);
    },

    /**
     * @method renderEntry
     * @param {Object} entry
     */
    renderEntry: function(entry) {
        var entryConfig = this.getEntryConfig(entry);
        var $entry = this.$(entryConfig.tpl(entry));

        if (!entry.$el) {
            entry.$el = $entry;
        }

        if (entryConfig.componentOptions && !entry.componentId) {
            entryConfig.componentOptions.container = entry.$el.find(entryConfig.componentOptions.container);
            entryConfig.componentOptions.disabled = (entry.ctype === 'info');
            entry.componentId = this.createComponent(entryConfig.componentOptions);
        }

        if (entry.ctype === 'info') {
            this.disableEntry(entry, $entry);
        }

        entry.$el.find('.input-info').remove();

        return entry.$el;
    },

    /**
     * @method disableEntry
     * @param {Object} entry
     * @param {Object} $entry
     */
    disableEntry: function(entry, $entry) {
        $entry.find('input, select').attr({
            'disabled': 'disabled',
            'data-nav-limit': 'true'
        });

        if (entry.componentId) {
            $entry
                .find('.settings-input-container')
                .addClass('is-disabled');
        }
    },

    /**
     * @method getEntryConfig
     * @param {Object} entry
     */
    getEntryConfig: function(entry) {
        var config;

        if (entry.pn) {
            entry.nodeType = 'parent';

            return entryConfigs[entry.nodeType];
        }

        if (entry.ctrl === 'btn') {
            entry.nodeType = 'btn';
            config = _.cloneDeep(entryConfigs[entry.nodeType]);
            config.componentOptions.title = entry.title;
            config.componentOptions.onClick = function() {
                this.onSettingBtnClick(entry);
            }.bind(this);

            return config;
        }

        if (entry.cmd) {
            entry.nodeType = 'control';
            config = _.cloneDeep(entryConfigs[entry.nodeType]);
            config.componentOptions.name = entry.title;
            config.componentOptions.items = this.createSelectItems(entry.options);
            config.componentOptions.preSelected = entry.par;
            config.componentOptions.onChange = function(value) {
                this.onSettingChange(value, entry);
            }.bind(this);

            return config;
        }
    },

    /**
     * @method createSelectItems
     * @param {Array} options
     */
    createSelectItems: function(options) {
        var items = [];

        options.forEach(function(option) {
            items.push({
                text: option.title,
                value: option.par
            });
        }.bind(this));

        return items;
    },

    /**
     * @method onSettingChange
     * @param {String} param
     * @param {Object} entry
     */
    onSettingChange: function(param, entry) {
        entry.par = parseInt(param, 10);

        this.remote.stopMove();

        this.visualizerConnection
            .send('setVzDirectSetting', {
                cmd: entry.cmd,
                param: entry.par,
                paramLength: entry.paramLength || false
            })
            .then(function() {
                if (entry.subRelation) {
                    this.updateSubEntriesVisibility(entry);
                }
            }.bind(this));
    },

    /**
     * This method will be called when the component button was clicked
     * @param {object} entry
     */
    onSettingBtnClick: function(entry) {
        entry.par = parseInt(entry.options[0].par, 10);

        this.visualizerConnection
            .send('setVzDirectSetting', {
                cmd: entry.cmd,
                param: entry.par
            });
    },

    /**
     * @method updateSubEntriesVisibility
     * @param {Object} entry
     */
    updateSubEntriesVisibility: function(entry) {
        this.preparations.relation.call(this, entry);
    },

    hasChanges: function() {
        return {
            hasChanges: false,
            invalid: false
        };
    }
});
