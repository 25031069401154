'use strict';

var app = require('../app');

/**
 * Exceptions Manager
 * @constructor
 */
var ExceptionsManager = function() {
    this.bindEvents();
};

/**
 * @method bindEvents
 */
ExceptionsManager.prototype.bindEvents = function() {
    window.onerror = this.onError.bind(this);
};

/**
 * @method onError
 * @param {Object} err
 */
ExceptionsManager.prototype.onError = function(error) {
    console.log('on error');
    if (window.CYNAP_ENV === 'prod') {
        if (typeof error === 'string' && error.includes('ResizeObserver')) {
            // ResizeObserver error can safely be ignored
            return false;
        }
        window.location.reload();

        return false;
    } else {
        console.error(error);
    }
};

/**
 * @method throw
 * @param {mixed} msg
 */
ExceptionsManager.prototype.throw = function(error, force) {
    if ('prod' !== window.CYNAP_ENV || true === window.SHOW_ERROR || true === force) {
        if ('object' === typeof error) {
            if (error.isInfo) {
                console.info(error.name + ': ' + error.message);
            } else {
                console.error(error.name + ': ' + error.message);
            }
        } else {
            console.error(error);
        }
    }
};

app.service('ExceptionsManager', ExceptionsManager);
