'use strict';

const _ = require('lodash');

module.exports.namespaced = true;

module.exports.state = {
    dragging: false,
    dragToId: '',
    overlayOpen: false,
    outputMirroringActive: false,
    preparedContent: [],
    windows: [],
    outputsInEdit: []
};

module.exports.mutations = {
    SET_DRAGGING(state, dragging) {
        state.dragging = dragging;
        if (!dragging) {
            state.dragToId = '';
        }
    },
    SET_DRAG_TO_ID(state, id) {
        state.dragToId = id;
    },
    SET_PREPARED_CONTENT(state, content) {
        state.preparedContent = content;
    },
    SET_OUTPUTS_CONTENT(state, data) {
        state.windows = Object.freeze(data.windows);
        state.outputsInEdit = Object.freeze(data.outputsInEdit);
    },
    SET_OVERLAY_OPEN(state, overlayOpen) {
        state.overlayOpen = overlayOpen;
    },
    SET_OUTPUT_MIRRORING_ACTIVE(state, outputMirroringActive) {
        state.outputMirroringActive = outputMirroringActive;
    }
};

module.exports.actions = {
    setDragging({ commit }, dragging) {
        commit('SET_DRAGGING', dragging);
    },
    setDragToId({ commit }, id) {
        commit('SET_DRAG_TO_ID', id);
    },
    setOverlayOpen({ commit }, overlayOpen) {
        commit('SET_OVERLAY_OPEN', overlayOpen);
    },
    setPreparedContent({ commit, state }, content) {
        if (!_.isEqual(state.preparedContent, content)) {
            commit('SET_PREPARED_CONTENT', content);
        }
    },
    setOutputsContent({ commit, state }, data) {
        if (!_.isEqual(state.windows, data.windows) || !_.isEqual(state.outputsInEdit, data.outputsInEdit)) {
            commit('SET_OUTPUTS_CONTENT', data);
        }
    },
    setOutputMirroringActive({ commit }, outputMirroringActive) {
        commit('SET_OUTPUT_MIRRORING_ACTIVE', outputMirroringActive);
    }
};

module.exports.getters = {
    getDragging: state => {
        return state.dragging;
    },
    getDragToId: state => {
        return state.dragToId;
    },
    getPreparedContent: state => {
        return state.preparedContent;
    },
    getPrepareAppIdsWithPreviews: state => {
        return state.preparedContent.filter(p => p.appId && p.previewAvailable).map(p => p.appId);
    },
    getOutputsInEdit: state => {
        return state.outputsInEdit;
    },
    getWindows: state => {
        return state.windows;
    },
    getWindowsByOutputPort: state => outputPort => {
        return state.windows.filter(w => w.outputPort === outputPort);
    },
    getNumberOfWindowsByOutputPort: (state, getters) => outputPort => {
        return getters.getWindowsByOutputPort(outputPort).length;
    },
    isOutputMirroringActive: state => {
        return state.outputMirroringActive;
    },
    isOverlayOpen: state => {
        return state.overlayOpen;
    },
    isWebconferenceInPrepare: (state) => {
        return state.preparedContent.some(p => ['webconference', 'zoom', 'teams', 'otherContent'].includes(p.contentType));
    },
    isWindowInFullscreenByOutputPort: (state, getters) => outputPort => {
        return getters.getWindowsByOutputPort(outputPort).some(w => w.fullscreen);
    },
    isWindowOpen: (state, getters) => (appId, outputPort) => {
        return getters.getWindowsByOutputPort(outputPort).some(w => w.appId === appId);
    },
    isEditModeActive: state => {
        return state.windows.some(w => w.outputPort === 'edit') || state.outputsInEdit.length > 0;
    },
    isOutputInEdit: state => {
        return state.outputsInEdit.length > 0;
    },
    isWindowLive: state => appId => {
        return state.windows.some(w => w.appId === appId && (w.outputPort === 'hdmi1' || w.outputPort === 'hdmi2'));
    },
    getWindowsByAppId: state => appId => {
        return state.windows.filter(w => w.appId === appId);
    },
    getAppIdSetOfLiveWindows: state => {
        const appIds = new Set();

        state.windows.filter(w => w.outputPort === 'hdmi1' || w.outputPort === 'hdmi2')
            .forEach(liveWindow => {
                appIds.add(liveWindow.appId);
            });

        return appIds;
    },
    isWebConferenceRunning: state => {
        return state.windows.some(w => ['teams', 'zoom', 'webconference'].includes(w.contentType));
    },
    isCameraBackActive: state => {
        return state.windows.some(w => w?.options?.cameraBackActive);
    }
};
