'use strict';

const $ = require('jquery');
const app = require('../app');
const RedrawEngine = require('../../redraw-engine');
const rbac = require('./../../rbac/rbac.js');
const platform = require('../../platform/platform');
const startupScripts = require('./../startup-scripts/index');
const pictureSources = require('../states').pictureSources;

const Bootstrap = function() {
    this.bindEvents();
    this.startComponentStatus = false;
    this.adjustmentWlanRegion = 0;
};

Bootstrap.prototype.bindEvents = function() {
    app.on('session-mgmt.open', this.stopComponents.bind(this));
    app.on('session-mgmt.close', this.startComponents.bind(this));
};

Bootstrap.prototype.start = function(role) {
    this.authService = app.getService('AuthenticationService');
    this.deviceService = app.getService('DeviceService');
    this.mainLoopService = app.getService('MainLoopService');

    var mainEngine = new RedrawEngine();
    var fastEngine = new RedrawEngine();

    this.mainLoopService.setMainEngine(mainEngine);
    this.mainLoopService.setFastEngine(fastEngine);

    this.mainLoopService.start();

    startupScripts[this.deviceService.getModel()].bootstrap.call(this, app,
        this.deviceService.isCboxProDualProjection());

    if (this.authService.getIsCollab() || role === 'collab'
        || this.authService.getIsViewer() || role === 'viewer') {
        this.startComponents(role);

        // OSD MESSAGES
        if (rbac.hasAccess('OSDMessage', 'show')) {
            app.createComponent('osdMessage', {
                type: 'OSDMessage',
                container: '#osd-message-container'
            });
        }

        return;
    }

    app.getService('ConnectionFactoryService')
        .afterCreated('device', function(connection) {
            connection
                .send([
                    'getLicenseFeatures',
                    'getAdjustmentWlanRegion'
                ]).then(function(features, region) {
                    this.adjustmentWlanRegion = region.region;
                    app.getService('OverlayConfigsService').onAdjustmentWlanRegionChanged(this.adjustmentWlanRegion);

                    if (rbac.hasAccess('vMeeting', 'show') && features.vmeeting && role !== 'admin') {
                        // VSOLUTION MEETING
                        app.createComponent('sessionManagement', {
                            type: 'SessionManagement',
                            container: '#session-management-container'
                        });
                    } else {
                        this.startComponents(role);
                    }

                    // OSD MESSAGES
                    if (rbac.hasAccess('OSDMessage', 'show')) {
                        app.createComponent('osdMessage', {
                            type: 'OSDMessage',
                            container: '#osd-message-container'
                        });
                    }
                }.bind(this));
        }.bind(this));
};

/**
 * @method startComponents
 */
Bootstrap.prototype.startComponents = function(role) {
    if (this.startComponentStatus) {
        return;
    }

    this.startComponentStatus = true;

    // Do not remove this, as it initializes the service
    this.streamQrCodeService = app.getService('StreamQrCodeService');

    this.liveStream = app.getService('LiveStreamService');

    this.$mainEl = $('#main');

    var redrawEngine = new RedrawEngine();

    if (role && typeof role !== 'boolean') {
        this.authService.changeRoleState(role);
    }

    this.frameBoxService = app.getService('FrameBoxService');
    this.frontendSettings = app.getService('FrontendSettings');
    this.frontendSettings.getSettings();

    if (!this.deviceService.isCboxPureOrPro() && !this.deviceService.isCboxPureMini()) {
        this.visualizer = app.getService('VisualizerService');
    }

    if (this.authService.getIsCollab()) {
        app.createComponent('statusBar', {
            type: 'StatusBar',
            container: '#status-bar-container'
        });
        this.annotationService = app.getService('AnnotationService');
        this.annotationService.toggleState();
    }

    if (this.authService.getIsViewer()) {
        if (this.deviceService.isCboxProDualProjection()) {
            app.createComponent('dualViewerMode', {
                type: 'DualViewerMode',
                container: '#dual-viewer-mode-container'
            });
        } else {
            this.liveStream
                .setCanvas(this.$mainEl.find('#livestream'))
                .setEngine(redrawEngine)
                .start();
        }

        app.createComponent('viewerLogout', {
            type: 'ViewerLogout',
            container: '#viewer-logout-container'
        });
    } else {
        this.eula = app.getService('EulaService');

        if (app.getComponent('mainOverlay')) {
            app.removeComponent('mainOverlay');
        }

        app.createComponent('mainOverlay', {
            type: 'Overlay',
            container: '#overlay-container'
        });

        this.eula.onAccept = function() {
            this.cloudAuth = app.getService('CloudAuthenticationService');
            this.mountService = app.getService('MountService');
            this.powerService = app.getService('PowerService');

            if (rbac.hasAccess('Framebox', 'show') && !this.deviceService.isCboxProDualProjection()) {
                app.createComponent('frameboxes', {
                    type: 'Frameboxes',
                    container: '#frameboxes-container'
                });
            }

            startupScripts[this.deviceService.getModel()].basic.call(this, rbac, app);

            if (!this.authService.getIsCollab()) {
                this.annotationService = app.getService('AnnotationService');
                this.frameBoxService
                    .setEngine(redrawEngine)
                    .start();
            }

            if (!platform.checks.isCbox && !rbac.hasAccess('ControlScreen', 'show', true)) {
                let source = pictureSources.main;

                if (this.authService.getIsCollab() && this.deviceService.isCboxProDualProjection()) {
                    // Annotation collaboration user must annotate on edit output in dual-projection mode
                    source = pictureSources.edit;
                }

                this.liveStream
                    .setSource(source)
                    .setCanvas(this.$mainEl.find('#livestream'))
                    .setEngine(redrawEngine)
                    .start();
            }

            app.getService('StationService');

            app.getService('ConnectionFactoryService')
                .afterCreated('device', function(connection) {
                    connection
                        .send('getLicenseFeatures')
                        .then(function(features) {
                            if (features.matrix) {
                                startupScripts[this.deviceService.getModel()].matrix.call(this, rbac, app);
                            }
                        }.bind(this));
                }.bind(this));
        }.bind(this);

        this.eula.callUpdateHandler();
    }

    setTimeout(function() {
        app.emit('layout-size.update');
        app.emit('frontend-settings.initialized');
    }, 250);
};

Bootstrap.prototype.stop = function() {
    app.removeComponent('sessionManagement');
    app.removeComponent('osdMessage');

    if (this.mainLoopService) {
        this.mainLoopService.stop();
    }

    this.stopComponents();
};

/**
 * Removes all base components.
 * This method will be called before the login dialog is shown.
 */
Bootstrap.prototype.stopComponents = function() {
    app.removeComponent('statusBar');
    app.removeComponent('frameboxes');
    app.removeComponent('menu');
    app.removeComponent('mainMenu');
    app.removeComponent('controlCenter');
    app.removeComponent('matrixFlap');

    this.startComponentStatus = false;

    if (this.annotationService) {
        this.annotationService.stopAnnotation(true);
    }
};

app.service('BootstrapService', Bootstrap);
