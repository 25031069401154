'use strict';

var app = require('../../app');
var tpl = require('./visualizer-preview.hbs');
var RedrawEngine = require('../../../redraw-engine');
var frame = new Image();
var MAX_WIDTH = 850;

app.component('VisualizerPreview', {
    className: 'visualizer-preview-canvas-container',
    template: tpl,

    /**
     * @method initialize
     */
    initialize: function() {
        /** @var RedrawEngine previewEngine **/
        this.previewEngine = new RedrawEngine();
        this.liveStreamService = this.getService('LiveStreamService');
        this.nextFrameLoaded = true;
        this.waitOnStart = 0;

        app.getService('ConnectionFactoryService')
            .afterCreated('visualizer', function(visualizerConnection) {
                this.visualizerConnection = visualizerConnection;
            }.bind(this));
    },

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.loadCanvas();

        this.setContainerHeight();
        this.setSize();
        this.start();
    },

    /**
     * Start the livestream.
     *
     * @chainable
     */
    start: function() {
        this.liveStreamService.pause();
        this.previewEngine.start({});
        this.previewEngine.on('redraw.update', this.loadNextFrame.bind(this));

        return this;
    },

    /**
     * Set size of the black container.
     */
    setContainerHeight: function() {
        var height;
        var calc;
        var width = this.$('.overlay-view-content').width();

        if (width > MAX_WIDTH) {
            width = MAX_WIDTH;
        }

        height = (width / 16) * 9;
        calc = height - height % 64;

        this.$el.css({
            height: calc + 'px'
        });

        this.height = calc;
    },

    /**
     * @method loadCanvas
     */
    loadCanvas: function() {
        this.$canvas = this.$el.find('.visualizer-preview');
        this.context = this.$canvas.get(0).getContext('2d');
    },

    /**
     * Set size of the preview-picture.
     */
    setSize: function() {
        var width;

        if (this.height < 64) {
            this.rest = 0;
            this.calcHeight = 64;
        } else {
            this.rest = this.height % 64;
            this.calcHeight = this.height - this.rest;
        }

        width = (this.calcHeight / 9) * 16;

        this.calcWidth = width;
    },

    /**
     * @method loadNextFrame
     */
    loadNextFrame: function() {
        // RELEASE-1983 - VZ image is moved when opening the "Exposure Settings" button.
        // Wait for a correct initialize and stop the blinking of the image at the start.
        // "I looks like the overlay-animation blocks the correct rendering on the initializing."
        if (3 > this.waitOnStart) {
            this.waitOnStart++;

            return;
        }

        if (this.nextFrameLoaded) {
            this.nextFrameLoaded = false;
            this.setSize();

            this.visualizerConnection
                .send([
                    {
                        command: 'getVZPicHeader',
                        data: {
                            formatMode: 64,
                            resMode: 0,
                            resFrequency: 0,
                            resId: 22
                        }
                    }
                ])
                .then(function(picHeader) {
                    this.visualizerConnection
                        .send([
                            {
                                command: 'getVZPicture',
                                data: {
                                    formatFlags: picHeader.formatFlags,
                                    formatMode: picHeader.formatMode,
                                    picSize: picHeader.picSize,
                                    width: this.calcWidth,
                                    height: this.calcHeight,
                                    memPage: picHeader.memPage,
                                    offset16by9: picHeader.offset16by9
                                }
                            }
                        ])
                        .then(this.onImage.bind(this, this.onImageLoaded.bind(this)), this.onImageFailed.bind(this));
                }.bind(this));
        }
    },

    /**
     * @method onImage
     */
    onImage: function(callback, data) {
        var blob;
        var imageUrl;

        this.nextFrameLoaded = true;

        blob = new Blob([data.picture], {
            type: 'image/jpeg'
        });
        imageUrl = URL.createObjectURL(blob);

        frame.onload = function onload() {
            callback(this);
            URL.revokeObjectURL(this.src);
        };

        frame.onerror = function onerror() {
            URL.revokeObjectURL(this.src);
        };

        frame.src = imageUrl;
    },

    /**
     * @method onImageLoaded
     */
    onImageLoaded: function(image) {
        this.$canvas.attr({
            width: this.calcWidth,
            height: this.calcHeight
        });
        this.context.clearRect(0, 0, this.width, this.height);
        this.context.drawImage(image, 0, 0, this.calcWidth, this.calcHeight);
    },

    onImageFailed: function() {
        if (this.isAlive()) {
            this.nextFrameLoaded = true;

            return;
        }

        this.$canvas.attr({
            width: this.width,
            height: this.height
        });

        this.context.clearRect(0, 0, this.width, this.height);
    },

    /**
     * @method destroy
     */
    destroy: function() {
        this.previewEngine.stop();
        this.liveStreamService.unpause();
    }
});
