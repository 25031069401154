// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"input-group certificate-file-container\">\n                    <label class=\"input-label\"\n                           for=\"panoptoCertificateFile\">\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.certificate_file",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":187,"column":24},"end":{"line":187,"column":57}}}))
    + "\n                    </label>\n                    <input type=\"file\"\n                           class=\"input focusable\"\n                           name=\"panoptoCertificateFile\"\n                           id=\"panoptoCertificateFile\"\n                           tabIndex=\"-1\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_certificate",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":195,"column":71},"end":{"line":195,"column":107}}}))
    + "</span>\n                    </div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"panopto-settings-form\" name=\"input-settings\">\n    <div class=\"row settings-list-row\" data-nav-area=\".is-focusable\" data-nav-trapped>\n        <div class=\"col-xs-8\">\n            <h2>\n                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.panopto",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":40}}}))
    + "\n                <span id=\"panopto-login-state\" class=\"panopto-login-state\">\n                    <span class=\"panopto-login-state-logged-in\">\n                        <div class=\"panopto-login-state-point\"></div>\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.logged_in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":9,"column":50}}}))
    + "\n                    </span>\n                    <span class=\"panopto-login-state-not-logged-in\">\n                        <div class=\"panopto-login-state-point\"></div>\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.not_logged_in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":13,"column":54}}}))
    + "\n                    </span>\n                    <span class=\"panopto-login-state-login-failed\">\n                        <div class=\"panopto-login-state-point\"></div>\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.login_failed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":53}}}))
    + "\n                    </span>\n                </span>\n            </h2>\n        </div>\n    </div>\n\n    <!-- Hostname & Registration Key -->\n    <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n        <div class=\"col-xs-6\">\n            <div class=\"input-group\">\n                <label class=\"input-label\" for=\"panoptoHostname\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.hostname",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":65},"end":{"line":28,"column":91}}}))
    + "</label>\n                <input type=\"text\"\n                       class=\"input is-focusable\"\n                       name=\"panoptoHostname\"\n                       id=\"panoptoHostname\"\n                       data-validation=\"required\"\n                       tabIndex=\"-1\" />\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.enter_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":67},"end":{"line":36,"column":93}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n        <div class=\"col-xs-6\">\n            <div id=\"reg-key-container\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"panoptoRegKey\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.reg_key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":67},"end":{"line":43,"column":92}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input is-focusable\"\n                           name=\"panoptoRegKey\"\n                           id=\"panoptoRegKey\"\n                           data-validation=\"checkMinMaxLengthOrDisabled\"\n                           data-min-length=\"1\"\n                           data-max-length=\"254\"\n                           tabIndex=\"-1\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":71},"end":{"line":53,"column":102}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div id=\"panopto-adhoc-settings-container\">\n        <!-- OAuth: Client ID / Secret -->\n        <div id=\"panopto-oauth-container\" class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"panoptoClientId\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.client_id",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":69},"end":{"line":65,"column":96}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input is-focusable\"\n                           name=\"panoptoClientId\"\n                           id=\"panoptoClientId\"\n                           data-validation=\"requiredOrDisabled\"\n                           tabIndex=\"-1\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.required",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":71},"end":{"line":73,"column":111}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"panoptoClientSecret\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.client_secret",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":79,"column":73},"end":{"line":79,"column":104}}}))
    + "</label>\n                    <input type=\"password\"\n                           class=\"input is-focusable\"\n                           id=\"panoptoClientSecret\"\n                           name=\"panoptoClientSecret\"\n                           data-validation=\"requiredOrDisabled\"\n                           tabIndex=\"-1\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.error_messages.required",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":87,"column":71},"end":{"line":87,"column":111}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <!-- Custom User mode -->\n        <div id=\"panopto-custom-user-mode-container\"\n             class=\"row settings-list-secundary\"\n             data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n             data-nav-trapped\n             data-nav-area-default>\n            <div class=\"col-xs-6\">\n                <h2 class=\"input-label\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.custom_user_mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":100,"column":40},"end":{"line":100,"column":73}}}))
    + "</h2>\n            </div>\n            <div class=\"col-xs-6\">\n                <div class=\"btn-switch-container pull-right\">\n                    <input name=\"panoptoCustomUserMode\"\n                           id=\"panoptoCustomUserMode\"\n                           type=\"checkbox\"\n                           class=\"btn-switch\"/>\n                    <div class=\"btn-switch-toggle-container\">\n                        <div class=\"btn-switch-toggle\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <!-- OAuth: Login/Logout button -->\n        <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n            <div id=\"panopto-oauth-login-logout-container\" class=\"col-xs-12 settings-oauth-container\">\n                <div id=\"panopto-login-btn-container\" class=\"row settings-list-secundary panopto-login-btn-container\"\n                     data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\" data-nav-trapped>\n                    <input id=\"panopto-login\" type=\"button\" name=\"panopto-login\" class=\"btn-form form-action-cancel is-focusable panopto-login\"\n                           value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.login_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":121,"column":34},"end":{"line":121,"column":62}}}))
    + "\">\n                </div>\n                <div id=\"panopto-logout-btn-container\" class=\"row settings-list-secundary panopto-logout-btn-container\"\n                     data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\" data-nav-trapped>\n                    <input id=\"panopto-logout\" type=\"button\" name=\"panopto-logout\" class=\"btn-form form-action-cancel is-focusable panopto-logout\"\n                           value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.logout_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":126,"column":34},"end":{"line":126,"column":63}}}))
    + "\">\n                </div>\n            </div>\n        </div>\n\n        <!-- Credentials (Username/Password) -->\n        <div id=\"credentials-container\" class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"panoptoUsername\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.username",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":135,"column":69},"end":{"line":135,"column":95}}}))
    + "</label>\n                    <input type=\"text\"\n                           class=\"input is-focusable\"\n                           name=\"panoptoUsername\"\n                           id=\"panoptoUsername\"\n                           data-validation=\"checkMinMaxLengthOrDisabled\"\n                           data-max-length=\"254\"\n                           data-min-length=\"1\"\n                           tabIndex=\"-1\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":145,"column":71},"end":{"line":145,"column":102}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"col-xs-6\">\n                <div class=\"input-group\">\n                    <label class=\"input-label\" for=\"panoptoPassword\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":151,"column":69},"end":{"line":151,"column":95}}}))
    + "</label>\n                    <input type=\"password\"\n                           id=\"panoptoPassword\"\n                           autocomplete=\"current-password\"\n                           class=\"input is-focusable\"\n                           name=\"panoptoPassword\"\n                           data-validation=\"checkMinMaxLengthOrDisabled\"\n                           data-max-length=\"254\"\n                           data-min-length=\"1\"\n                           tabIndex=\"-1\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":162,"column":71},"end":{"line":162,"column":102}}}))
    + "</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <!-- Certificate -->\n    <div class=\"row settings-list-secundary\"\n         data-nav-area=\".input:not([disabled]), .btn-switch, .select, .focusable:not(:disabled)\"\n         data-nav-trapped>\n        <div class=\"col-xs-6 switch-below-text\">\n            <label class=\"input-label\" for=\"certMode\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.certificate_mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":174,"column":54},"end":{"line":174,"column":87}}}))
    + "</label>\n            <div class=\"btn-switch-container\">\n                <input name=\"panoptoCertMode\" type=\"checkbox\" class=\"btn-switch\" id=\"panoptoCertMode\" />\n                <div class=\"btn-switch-toggle-container\">\n                    <div class=\"btn-switch-toggle\"></div>\n                </div>\n            </div>\n        </div>\n        <div class=\"col-xs-6\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":183,"column":12},"end":{"line":198,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n\n    <div id=\"form-action-container-panopto\" class=\"form-primary-background\"></div>\n</form>\n";
},"useData":true});
