'use strict';

var app = require('../../../app');
var ethernetSettingsTpl = require('./ethernet2-settings.hbs');
require('./ethernet2-settings-v4/ethernet2-settings-v4');

app.component('Ethernet2Settings', {
    template: ethernetSettingsTpl,

    /**
     * @method postPlaceAt
     */
    postPlaceAt: function() {
        this.storeSelectors();
        this.createComponentV4();
        this.bindEvents();
    },

    bindEvents: function() {
        this.on('ethernet-settings.changed', this.updateChanges.bind(this));
    },

    /**
     * @method storeSelectors
     */
    storeSelectors: function() {
        this.$v4 = this.$el.find('#ethernet-settings-container-v4');
    },

    /**
     * @method createComponentV4
     */
    createComponentV4: function() {
        this.createComponent({
            type: 'Ethernet2SettingsV4',
            container: this.$v4
        });
    },

    updateChanges: function(changes) {
        this.changes = changes;
    },

    hasChanges: function() {
        if (this.changes === undefined) {
            return {
                hasChanges: false,
                invalid: false
            };
        }

        return this.changes;
    }
});
