'use strict';

module.exports = [
    {
        id: 'mute',
        type: 'mute',
        state: 'mute'
    },
    {
        id: 'volume',
        type: 'volume'
    }
];
