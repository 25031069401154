'use strict';

var app = require('./app');

// Polyfills
require('./polyfill/Array.from');

// Abstract
require('./components/annotation/abstract');

// Services
require('./services/configs');
require('./services/livestream');
require('./services/remote');
require('./services/form-manager');
require('./services/validate');
require('./services/overlay-configs');
require('./services/visualizer');
require('./services/visualizer-settings');
require('./services/activities');
require('./services/modal-configs');
require('./services/main-loop');
require('./services/poll-factory');
require('./services/cloud-auth');
require('./services/authentication');
require('./services/data-storage');
require('./services/keyboard');
require('./services/streaming');
require('./services/volume');
require('./services/freeze');
require('./services/mobile');
require('./services/connection');
require('./services/eula');
require('./services/downloads');
require('./services/power');
require('./services/update');
require('./services/stream-qrcode');
require('./services/mount');
require('./services/exceptions-manager');
require('./services/annotation');
require('./services/frontend-settings');
require('./services/custom-ui-settings');
require('./services/upload');
require('./services/output');
require('./services/record');
require('./services/upDownloads');
require('./services/modal-handler');
require('./services/stream-input');
require('./services/bootstrap');
require('./services/language');
require('./services/device');
require('./services/webcasting');
require('./services/uri');
require('./services/overlay-handler');
require('./services/screensaver');
require('./services/matrix');
require('./services/matrix-configs');
require('./services/matrix-configurator');
require('./services/matrix-connection');
require('./services/matrix-main');
require('./services/matrix-preview');
require('./services/station');
require('./services/json');
require('./services/key-event-handler');
require('./services/security');
require('./services/lectureCapture');
require('./services/webconference');
require('./services/peripheral');
require('./services/session-management');
require('./services/groupwork');
require('./services/pip');
require('./services/user-settings');
require('./services/office365');
require('./services/admin-message');
require('./services/zoom');
require('./services/sources');

// Used components
require('./components/main/main');
require('./components/status-bar/status-bar');
require('./components/status-bar/pin-flap');
require('./components-vue/sources-menu/sources-menu');
require('./components/overlay/overlay');
require('./components/modal');
require('./components/settings');
require('./components/frameboxes');
require('./components/backdrop/backdrop');
require('./components/list-grid/list-grid');
require('./components/navigation-bar/navigation-bar');
require('./components/control-center-v3');
require('./components/custom-cursor/custom-cursor');
require('./components/filelist/filelist');
require('./components/filebrowser/filebrowser');
require('./components-vue/file-browser-v3/file-browser-v3');
require('./components/range-input/range-input');
require('./components/media-list/media-list');
require('./components/mirroring/mirroring');
require('./components/custom-select/custom-select');
require('./components/rotate-message/rotate-message');
require('./components/button/button');
require('./components/visualizer-preview/visualizer-preview');
require('./components/osd-message/osd-message');
require('./components/osd-message/osd-extend-vmeeting');
require('./components/osd-message/osd-extend-interactive');
require('./components/annotation');
require('./components/bookmarks/bookmarks');
require('./components/download-apps/download-apps');
require('./components-vue/main-menu/main-menu');
require('./components/matrix/matrix');
require('./components/matrix/matrix-box');
require('./components/matrix/select-box');
require('./components/matrix/matrix-flap');
require('./components/matrix/configurator/discovery-box');
require('./components/matrix/configurator/discovery-device-list');
require('./components/matrix/configurator/matrix-configurator-grid');
require('./components/matrix/configurator/close-configurator/close-configurator');
require('./components/matrix/configurator/change-configuration/change-configuration');
require('./components/matrix/configurator/stations-offline/stations-offline');
require('./components/matrix/main/file-box');
require('./components/matrix/main/file-list');
require('./components/matrix/main/files-menu');
require('./components/matrix/main/matrix-main-grid');
require('./components/matrix/main/preview');
require('./components/matrix/main/matrix-menu');
require('./components/matrix/push-indicator');
require('./components/matrix/lock-indicator');
require('./components/matrix/main/interaction');
require('./components-vue/screensaver-end/screensaver-end');
require('./components/viewer-logout/viewer-logout');
require('./components/splash-screen/splash-screen');
require('./components/session-management/session-management');
require('./components/session-management/meeting-list');
require('./components/session-management/timeline');
require('./components/session-management');
require('./components/session-management/session-management');
require('./components/session-management/meeting-list');
require('./components/session-management/timeline');
require('./components/power-warning/power-warning');
require('./components-vue/regulatory-info/regulatory-info');
require('./components/control-screen/control-screen');
require('./components/dual-viewer-mode/dual-viewer-mode');

// Modules
window.formManager = require('./../form/form-manager.js');
require('../custom-elements/index');

var platform = require('../platform/platform');
var Renderer = require('../renderer/renderer.js');

var bootstrap = function(options) {
    app.start(options);

    app.getService('ExceptionsManager');
    app.getService('ActivitiesService');
    app.getService('MobileService');
    app.getService('LanguageService');
    app.getService('LectureCaptureService');
    app.getService('PeripheralService');

    app.createComponent('main', {
        type: 'Main',
        container: '#main'
    });

    platform.setPlatformClass(app.$el);
};

new Renderer();

module.exports = {
    bootstrap: bootstrap
};
