// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"input-group certificate-file-container\">\n                    <label class=\"input-label\"\n                           for=\"opencastCertificateFile\">\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.certificate_file",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":135,"column":24},"end":{"line":135,"column":57}}}))
    + "\n                    </label>\n                    <input type=\"file\"\n                           class=\"input focusable\"\n                           name=\"opencastCertificateFile\"\n                           id=\"opencastCertificateFile\"\n                           tabIndex=\"-1\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_certificate",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":143,"column":71},"end":{"line":143,"column":107}}}))
    + "</span>\n                    </div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"opencast-settings-form\" name=\"input-settings\">\n    <div class=\"row settings-list-row\" data-nav-area=\".is-focusable\" data-nav-trapped>\n        <div class=\"col-xs-8\">\n            <h2>\n                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.opencast",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":41}}}))
    + "\n                <span id=\"opencast-login-state\" class=\"opencast-login-state\">\n                    <span class=\"opencast-login-state-logged-in\">\n                        <div class=\"opencast-login-state-point\"></div>\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.logged_in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":9,"column":50}}}))
    + "\n                    </span>\n                    <span class=\"opencast-login-state-not-logged-in\">\n                        <div class=\"opencast-login-state-point\"></div>\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.not_logged_in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":13,"column":54}}}))
    + "\n                    </span>\n                    <span class=\"opencast-login-state-login-failed\">\n                        <div class=\"opencast-login-state-point\"></div>\n                        "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.login_failed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":53}}}))
    + "\n                    </span>\n                </span>\n            </h2>\n        </div>\n    </div>\n\n    <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n        <div class=\"col-xs-6\">\n            <div class=\"input-group\">\n                <label class=\"input-label\" for=\"opencastHostname\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.hostname",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":66},"end":{"line":27,"column":92}}}))
    + "</label>\n                <input type=\"text\"\n                       class=\"input is-focusable\"\n                       name=\"opencastHostname\"\n                       id=\"opencastHostname\"\n                       placeholder=\"\"\n                       data-validation=\"required\"\n                       tabIndex=\"-1\" />\n                    <div class=\"input-info\">\n                        <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.enter_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":71},"end":{"line":36,"column":97}}}))
    + "</span>\n                    </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n        <div class=\"col-xs-6\">\n            <div class=\"input-group\">\n                <label class=\"input-label\" for=\"opencastUsername\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.username",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":66},"end":{"line":45,"column":92}}}))
    + "</label>\n                <input type=\"text\"\n                       class=\"input is-focusable\"\n                       name=\"opencastUsername\"\n                       id=\"opencastUsername\"\n                       placeholder=\"\"\n                       data-validation=\"checkMinMaxLength\"\n                       data-max-length=\"254\"\n                       data-min-length=\"1\"\n                       tabIndex=\"-1\" />\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":67},"end":{"line":56,"column":98}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n        <div class=\"col-xs-6\">\n            <div class=\"input-group\">\n                <label class=\"input-label\" for=\"opencastPassword\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":66},"end":{"line":62,"column":92}}}))
    + "</label>\n                <input type=\"password\"\n                       id=\"opencastPassword\"\n                       autocomplete=\"current-password\"\n                       class=\"input is-focusable\"\n                       name=\"opencastPassword\"\n                       placeholder=\"\"\n                       data-validation=\"checkMinMaxLength\"\n                       data-max-length=\"254\"\n                       data-min-length=\"1\"\n                       tabIndex=\"-1\" />\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":67},"end":{"line":74,"column":98}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable\" data-nav-trapped>\n        <div class=\"col-xs-6\">\n            <div class=\"input-group\">\n                <label class=\"input-label\" for=\"opencastCaptureAgent\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.capture_agent_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":83,"column":70},"end":{"line":83,"column":106}}}))
    + "</label>\n                <input type=\"text\"\n                       class=\"input is-focusable\"\n                       name=\"opencastCaptureAgent\"\n                       id=\"opencastCaptureAgent\"\n                       placeholder=\"\"\n                       data-validation=\"checkMinMaxLength\"\n                       data-max-length=\"254\"\n                       data-min-length=\"1\"\n                       tabIndex=\"-1\" />\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":94,"column":67},"end":{"line":94,"column":98}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n        <div class=\"col-xs-6\" data-opencast-adhoc-view>\n            <div class=\"input-group\">\n                <label class=\"input-label\" for=\"opencastWorkflowId\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.workflow_id",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":100,"column":68},"end":{"line":100,"column":97}}}))
    + "</label>\n                <input type=\"text\"\n                       id=\"opencastWorkflowId\"\n                       class=\"input is-focusable\"\n                       name=\"opencastWorkflowId\"\n                       placeholder=\"\"\n                       data-validation=\"checkMinMaxLength\"\n                       data-max-length=\"254\"\n                       data-min-length=\"1\"\n                       data-allow-empty=\"true\"\n                       tabIndex=\"-1\" />\n                <div class=\"input-info\">\n                    <span class=\"input-info-message error-message\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":112,"column":67},"end":{"line":112,"column":98}}}))
    + "</span>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"row settings-list-secundary\"\n         data-nav-area=\".input:not([disabled]), .btn-switch, .select, .focusable:not(:disabled)\"\n         data-nav-trapped>\n        <div class=\"col-xs-6 switch-below-text\">\n            <label class=\"input-label\" for=\"certMode\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.certificate_mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":122,"column":54},"end":{"line":122,"column":87}}}))
    + "</label>\n            <div class=\"btn-switch-container\">\n                <input name=\"opencastCertMode\" type=\"checkbox\" class=\"btn-switch\" id=\"opencastCertMode\" />\n                <div class=\"btn-switch-toggle-container\">\n                    <div class=\"btn-switch-toggle\"></div>\n                </div>\n            </div>\n        </div>\n        <div class=\"col-xs-6\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":131,"column":12},"end":{"line":146,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n\n    <div id=\"form-action-container-opencast\" class=\"form-primary-background\"></div>\n</form>\n";
},"useData":true});
