'use strict';

var app = require('../../app');
var viewerLogoutTpl = require('./viewer-logout.hbs');

app.component('ViewerLogout', {
    template: viewerLogoutTpl,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.authenticationService = this.getService('AuthenticationService');
        this.dataStorage = app.getService('DataStorage');
    },

    postPlaceAt: function() {
        this.bindDOMEvents();
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.viewer-logout', this.logout.bind(this));
    },

    logout: function() {
        this.authenticationService.logout();
        this.dataStorage.remove('access');

        window.location.href =  window.location.href.split('?role')[0]; // Also reloads page.
    }
});
