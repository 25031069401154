const template = require('./byod-protected-mode-item.html');
const vueUtils = require('../../../../components-vue/util');
const app = require('../../../../app');

module.exports = {
    template,

    data: function() {
        return {
            protectedMirrorSearching: false
        };
    },

    methods: {
        startSearch: function() {
            this.wolfprot.talk('setMirrorState', {
                enable: true
            });

            app.emit('main-loop.fast.start', {
                id: 'mirror-protected'
            });
        },
        cancel: function() {
            this.wolfprot.talk('setMirrorState', {
                enable: false
            });

            app.emit('main-loop.fast.start', {
                id: 'mirror-protected'
            });
        },

        i18n: vueUtils.i18n
    },

    created: function() {
        this.wolfprot = vueUtils.wolfprot();

        this.evctx = vueUtils.eventContext();
        this.protectedMirrorSearching = app.getService('PeripheralService').getMirrorState();
        this.evctx.on('mirror.dual-projection', function(mirrorState) {
            this.protectedMirrorSearching = mirrorState;
        }.bind(this));
    }
};
