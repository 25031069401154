// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row settings-list-secundary\" data-nav-area=\".is-focusable:not(:disabled)\" data-nav-trapped>\n                <div class=\"col-xs-6\">\n                    <div class=\"input-group\">\n                        <label class=\"input-label\" for=\"wallpaper-file\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wallpaper",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":72},"end":{"line":8,"column":98}}}))
    + "</label>\n                        <input type=\"file\"\n                               class=\"input is-focusable\"\n                               name=\"wallpaper-file\"\n                               id=\"wallpaper-file\"\n                               tabIndex=\"-1\"\n                               accept=\".jpg, .jpeg\"\n                               data-validation=\"checkImage\"/>\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\">\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.image_error_type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":32},"end":{"line":18,"column":65}}}))
    + "\n                            </span>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"col-xs-4 wallpaper-preview-item wallpaper-action is-focusable\"\n                     data-file-path=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"path") : depth0), depth0))
    + "\"\n                     data-file-name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"filename") : depth0), depth0))
    + "\">\n                    <img src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"previewPath") : depth0), depth0))
    + "\" alt=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"titleKey") : depth0), depth0))
    + "\"/>\n                    <div class=\"wallpaper-preview-item-active\">\n                        <span class=\"icon-v2-checkmark\"></span>\n                    </div>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"wallpaper-settings\" name=\"input-settings\">\n        <!-- Wallpaper Custom Image -->\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCbox") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "\n        <div id=\"wallpaper-active-wallpaper\" class=\"row settings-list-secundary is-hidden wallpaper-active-wallpaper\">\n            <span class=\"icon-info3 wallpaper-info-icon\"></span>\n            <span class=\"wallpaper-info-upload-text\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wallpaper_active_wallpaper",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":53},"end":{"line":28,"column":96}}}))
    + "</span>\n            <span id=\"wallpaper-active-wallpaper-filename\" class=\"wallpaper-info-upload-text\"></span>\n        </div>\n\n        <div class=\"row wallpaper-preview\" data-nav-area=\".is-focusable\" data-nav-trapped>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"wallpapers") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":42,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n\n        <div id=\"wallpaper-rest-container\"\n             class=\"row settings-list-secundary wallpaper-preview\"\n             data-nav-area=\".is-focusable\"\n             data-nav-trapped>\n            <div class=\"col-xs-12 reset-image-btn-container\">\n                <input type=\"button\" name=\"reset-image\" class=\"btn-form reset-background is-focusable\"\n                       value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.reset_image",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":30},"end":{"line":51,"column":58}}}))
    + "\"/>\n            </div>\n        </div>\n\n        <div id=\"wallpaper-upload-info\" class=\"row is-hidden\">\n            <span class=\"icon-info3 wallpaper-info-icon\"></span>\n            <span class=\"wallpaper-info-upload-text\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.wallpaper_upload_info",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":53},"end":{"line":57,"column":91}}}))
    + "</span>\n        </div>\n\n        <div id=\"form-action-container\" class=\"form-primary-wallpaper\"></div>\n    </form>\n</div>\n";
},"useData":true});
