const template = require('./filelist-item.html');
const vueUtils = require('../../../components-vue/util');
const filelistIcons = require('../icons.js');

module.exports = {
    template,
    props: {
        item: {
            type: Object,
            required: true,
            default: {
                path: 'Internal:///test.png',
                type: 'image',
                icon: 'icon-filetype-picture',
                name: 'test.png',
                selected: false
            }
        },
        isCloudItem: {
            type: Boolean,
            required: false,
            default: false
        },
        focusable: {
            type: Boolean,
            required: false,
            default: false
        },
        multiselectActive: {
            type: Boolean,
            required: false,
            default: false
        },
        fileOperationsAvailable: {
            type: Boolean,
            required: false,
            default: false
        },
        hasAnyCopyTargets: {
            type: Boolean,
            required: false,
            default: false
        },
        uiScalingSize: {
            type: String,
            required: false,
            default: ''
        },
        supportsDragging: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        isDownloadable: function() {
            return this.isCloudItem && this.item.type !== 'dir';
        },
        isDraggable: function() {
            return this.supportsDragging && !this.item.isFolder
                && !(this.item.unknownFileType || this.isCloudItem && !this.item.isDownloaded);
        }
    },

    methods: {
        selectItem: function() {
            this.$emit('select-item', this.item);
        },
        selectMoreOption: function(event) {
            this.$emit('more-option', {
                item: this.item,
                positionX: event.clientX,
                positionY: event.clientY
            });
        },
        uploadItem: function() {
            this.$emit('upload-item', this.item);
        },
        deleteItem: function() {
            this.$emit('delete-item', this.item);
        },

        /**
         * Provide helper function to resolve icon
         */
        findIcon: filelistIcons.findIcon,

        i18n: vueUtils.i18n
    },

    components: {
        'progress-circle': require('../../../components-vue/progress-circle/progress-circle.js')
    }
};
