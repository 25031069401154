/* jslint bitwise: true */

'use strict';

var _ = require('lodash');
var $ = require('jquery');
var WolfProtCommandParser = require('./wolfprot-command-parser');
var serverCommands = require('./wolfprot-server-commands');
var clientCommands = require('./wolfprot-client-commands');
var wolfProtCodes = require('./wolfprot-codes');
var valueToBuffer = require('./../../../app/helper').valueToBuffer;

var commands = _.invert(wolfProtCodes.commands);
var wolfProtHeaders = wolfProtCodes.headers;

/**
 * Helper service for the WolfProt protocol
 * @class WolfProtHelper
 * @constructor
 */
var WolfProtHelper = function(socket) {
    this.inFrame = null;
    this.callbackStore = {};
    this.socket = socket;
    this.parser = new WolfProtCommandParser();
};

if (Uint8Array.prototype.slice === undefined) {
    Uint8Array.prototype.slice = function(start, end) {
        if (start < 0) {
            start = this.length + start;
        }
        if (end === undefined) {
            end = this.length;
        } else if (end < 0) {
            end = this.length + end;
        }
        var newArray = new Uint8Array(end - start);
        for (var i = 0; i < newArray.length; i++) {
            newArray[i] = this[i + start];
        }

        return newArray;
    };
}

/*
 * @return the header length of a valid wolfprot frame
 */
function wpCalcHeaderlen(buf) {
    var len = 3;

    if (buf[0] & wolfProtHeaders.WP_HEAD_EXT_HDR) {
        len++;
    }
    if (buf[0] & wolfProtHeaders.WP_HEAD_EXT_CMD) {
        len++;
    }
    if (!(buf[0] & wolfProtHeaders.WP_HEAD_ERR)) {
        if ((buf[0] & wolfProtHeaders.WP_HEAD_EXT_HDR) && (buf[1] & wolfProtHeaders.WP_EXTHEAD_EXT_LEN)) {
            len += 3;
        } else if (buf[0] & wolfProtHeaders.WP_HEAD_EXT_LEN) {
            len++;
        }
    }

    return len;
}

/*
 * @return the payload data length of a valid wolfprot frame
 */
function wpCalcDatalen(buf, headerLength) {
    var len = 0;
    var headerLen = headerLength ? headerLength : wpCalcHeaderlen(buf);

    if (buf[0] & wolfProtHeaders.WP_HEAD_ERR) {
        len = 0;
    } else if ((buf[0] & wolfProtHeaders.WP_HEAD_EXT_HDR) && (buf[1] & wolfProtHeaders.WP_EXTHEAD_EXT_LEN)) {
        len = ((buf[headerLen - 4]) << 24) | ((buf[headerLen - 3]) << 16) | ((buf[headerLen - 2]) << 8) | (buf[headerLen - 1]);
    } else if (buf[0] & wolfProtHeaders.WP_HEAD_EXT_LEN) {
        len = ((buf[headerLen - 2]) << 8) | (buf[headerLen - 1]);
    } else {
        len = buf[headerLen - 1];
    }

    return len;
}

/**
 * Extracts the first wolfprot frame from the given array
 *
 * @param data: Uint8Array
 *
 * @return: object with the frame and the remaining data
 */
function wpGetFrame(data) {
    var frame;
    var remain;
    var framelength;

    framelength = wpCalcHeaderlen(data) + wpCalcDatalen(data);
    if (data.length > framelength) {
        frame = data.slice(0, framelength);
        remain = data.slice(framelength);
    } else if (data.length === framelength) {
        frame = data;
        remain = undefined;
    } else {
        frame = undefined;  // Splitted commands are not allowed
        remain = undefined;
    }

    return {
        frame: frame,
        remain: remain
    };
}

/**
 * Parses the command from the given wolfprot Frame
 *
 * @param wpFrame: Uint8Array
 *
 * @return: the command number
 */
function wpParseCommand(wpFrame) {
    var command;

    if (wpFrame[0] & wolfProtHeaders.WP_HEAD_EXT_HDR) {
        if (wpFrame[0] & wolfProtHeaders.WP_HEAD_EXT_CMD) {
            command = (wpFrame[2] << 8 | wpFrame[3]);
        } else {
            command = wpFrame[2];
        }
    } else if (wpFrame[0] & wolfProtHeaders.WP_HEAD_EXT_CMD) {
        command = (wpFrame[1] << 8 | wpFrame[2]);
    } else {
        command = wpFrame[1];
    }

    return command;
}

WolfProtHelper.prototype.checkHeaderID = function checkHeaderID(buf) {
    let data;

    if (buf instanceof ArrayBuffer) {
        data = new Uint8Array(buf);
    } else {
        throw new Error('Unexpected WebSocket data type.');
    }

    // Remove session id from command.
    if (data[0] & wolfProtHeaders.WP_HEAD_ID) {
        let len = 0;
        let mask = 1 << 4;
        data[0] &= ~mask;

        // Header 2 Bytes
        if (data[0] & wolfProtHeaders.WP_HEAD_EXT_HDR) {
            len++;
        }

        let payload = new Uint8Array(buf, 5 + len, buf.byteLength - (5 + len));

        // Header 2 Bytes
        let header;
        if (len) {
            header = new Uint8Array([data[0], data[1]]);
        } else {
            header = new Uint8Array([data[0]]);
        }

        let newData = new Uint8Array(header.length + payload.length);
        newData.set(header);
        newData.set(payload, header.length);

        data = newData;
    }

    return data;
};

WolfProtHelper.prototype.insertHeaderID = function insertHeaderID(buf, sessionId) {
    let joinedBuffer = null;
    let locId = sessionId;
    let sessionBuf = new Uint8Array(4);
    let len = 0;

    valueToBuffer(locId, sessionBuf, 0, 4);

    joinedBuffer = new Uint8Array(buf.length + 4);
    joinedBuffer[0] = (buf[0] ^ wolfProtHeaders.WP_HEAD_ID);

    // Handle 2 Byte Headers
    if (buf[0] & wolfProtHeaders.WP_HEAD_EXT_HDR) {
        len++;
        joinedBuffer[1] = buf[1];
    }

    joinedBuffer.set(sessionBuf, 1 + len);
    joinedBuffer.set(buf.slice(1 + len, buf.length), 5 + len);

    return joinedBuffer;
};

/**
 * Check the length of the command. If the length defined in the command is not the same as the actual length
 * of the array, the array is cut to the correct size.
 * @param data
 * @returns {{slice: boolean, definedLen: *}}
 */
WolfProtHelper.prototype.checkCmdLength = function checkCmdLength(data) {
    var slice = false;
    var headerLen = wpCalcHeaderlen(data);
    var paramLen = wpCalcDatalen(data, headerLen);
    var definedLen = (headerLen + paramLen);

    if (data.length > definedLen) {
        console.warn('WARNING: check length from cmd {' + data.slice(0, headerLen) + '}');
        slice = true;
    }

    return {
        slice: slice,
        definedLen: definedLen
    };
};

/**
 * Checks the data type and calls the appropriate function
 * @method onDataReady
 * @param Uint8ArrayBuffer
 */
WolfProtHelper.prototype.onDataReady = function onDataReady(data) {
    let reply;
    let commandKey;
    let command;
    let serverCommand;

    while (data) {
        reply = wpGetFrame(data);

        data = reply.remain;
        if (!reply.frame) {
            break;
        }
        command = this.parser.parse(reply.frame);
        commandKey = commands[command];

        if (reply.frame[0] & wolfProtHeaders.WP_HEAD_ERR) {
            this.onError(command);
        } else {
            // If command is a set command.
            if (reply.frame[0] & wolfProtHeaders.WP_HEAD_DIR_SET) {
                serverCommand = serverCommands.DUMMY_SET_CMD;
            } else if (serverCommands[commandKey]) {
                serverCommand = serverCommands[commandKey];
            } else {
                serverCommand = serverCommands['visualizerSettingsResponse'];
            }

            if (serverCommand) {
                serverCommand(reply.frame, this.socket, command, this.invokeCallback.bind(this));
            } else {
                throw 'Error: Command "' + command + '" isn\'t defined in server-commands.';
            }
        }
    }
};

/**
 * @method executeClientCommand
 * @param {String} command
 * @param {Object} data
 */
WolfProtHelper.prototype.executeClientCommand = function executeClientCommand(command, data) {
    var clientCommand = clientCommands[command];
    var wpCmd;
    var obj;
    var promise;
    var dfd;

    data = data || {};

    if (clientCommand) {
        obj = clientCommand(data, this.socket);
        promise = obj.promise;
        wpCmd = obj.cmd;
    } else {
        dfd = $.Deferred();
        promise = dfd.promise();
        dfd.reject();
        throw 'Error: Command "' + command + '" isn\'t defined in client-commands.';
    }

    return {
        waitFor: function(callback, onErrorCallback) {
            promise.then(function() {
                if (!this.callbackStore[wpCmd]) {
                    this.callbackStore[wpCmd] = [];
                }

                this.callbackStore[wpCmd].push({
                    callback: callback,
                    onErrorCallback: onErrorCallback
                });
            }.bind(this), onErrorCallback);
        }.bind(this)
    };
};

WolfProtHelper.prototype.onError = function onError(command) {
    if (this.callbackStore[command]) {
        if (this.callbackStore[command].length > 0) {
            this.callbackStore[command][0].onErrorCallback();
        } else {
            throw 'Error no callbackfunction in memory';
        }

        this.dequeueCommand(command);
    }
};

/**
 * Runs the callback function which was given to the executeClientCommand method
 * @method invokeCallback
 * @param {String} callbackName Actually it's the name of the client command
 */
WolfProtHelper.prototype.invokeCallback = function invokeCallback(command, data) {
    if (this.callbackStore[command]) {
        if (this.callbackStore[command].length > 0) {
            this.callbackStore[command][0].callback.call(null, data);
        } else {
            throw 'Error no callbackfunction in memory';
        }

        this.dequeueCommand(command);
    }
};

/**
 * After callback was called dequeue command from callback store.
 */
WolfProtHelper.prototype.dequeueCommand = function dequeueCommand(command) {
    if (this.callbackStore[command].length > 1) {
        this.callbackStore[command].splice(0, 1);
    } else if (this.callbackStore[command].length === 1) {
        delete this.callbackStore[command];
    }
};

/**
 * Resets the callback store and calls for each remaining callback the on error callback
 */
WolfProtHelper.prototype.resetCallbackStore = function resetCallbackStore() {
    for (var key in this.callbackStore) {
        if (this.callbackStore[key]) {
            this.onError(key);
        }
    }
};

/**
 * THE FOLLOWING TWO METHODS ARE USED AS FALLBACK FOR THE VISUALIZER COMMANDS AS THEY CAN BE SPLIT
 * IN MULTIPLE FRAMES.
 */

/**
 * Checks the data type and calls the parse method of the parser
 * @method parse
 * @return {Number}
 */
WolfProtHelper.prototype.parseCommand = function parseCommand(inFrameArrayBuffer) {
    let tempFrame;
    let command;

    if (this.parser.lastCommandNotFinished === false) {
        inFrameArrayBuffer = this.checkHeaderID(inFrameArrayBuffer);
        this.inFrame = new Uint8Array(inFrameArrayBuffer);
    } else {
        tempFrame = new Uint8Array(this.inFrame.byteLength + inFrameArrayBuffer.byteLength);
        tempFrame.set(this.inFrame, 0);
        tempFrame.set(new Uint8Array(inFrameArrayBuffer), this.inFrame.byteLength);
        this.inFrame = tempFrame;
    }

    command = wpParseCommand(this.inFrame);

    return { cmd: command, data: this.inFrame };
};

/**
 * Checks the data type and calls the parse method of the parser
 * @method parse
 * @return {Number}
 */
WolfProtHelper.prototype.getCommandName = function getCommandName(command) {
    return commands[command];
};

/**
 * Intended for internal use only
 */
WolfProtHelper.prototype.executeServerCommand = function executeServerCommand(command) {
    var commandKey = commands[command];
    var serverCommand;

    if (this.inFrame[0] & wolfProtHeaders.WP_HEAD_ERR) {
        this.onError(command);
    } else {
        // If command is a set command.
        if (this.inFrame[0] & wolfProtHeaders.WP_HEAD_DIR_SET) {
            serverCommand = serverCommands.DUMMY_SET_CMD;
        } else if (serverCommands[commandKey]) {
            serverCommand = serverCommands[commandKey];
        } else {
            serverCommand = serverCommands['visualizerSettingsResponse'];
        }

        if (serverCommand) {
            serverCommand(this.inFrame, this.socket, command, this.invokeCallback.bind(this));
        } else {
            throw 'Error: Command "' + command + '" isn\'t defined in server-commands.';
        }
    }
};

module.exports = WolfProtHelper;
