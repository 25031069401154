// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "col-xs-12";
},"3":function(container,depth0,helpers,partials,data) {
    return "col-xs-6";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"settings-list\">\n    <form id=\"youtube-stream-settings\" name=\"youtube-stream-settings\">\n        <div id=\"youtube-stream-login-btn-container\" class=\"row settings-list-secundary youtube-stream-login-btn-container\" data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\" data-nav-trapped>\n            <div class=\"youtube-stream-login-state\">\n                <span class=\"icon-panic-button youtube-login-failed\"></span>\n                <span class=\"youtube-login-authenticating\">\n                    <div class=\"loader small\"></div>\n                 </span>\n            </div>\n            <input id=\"youtube-login\" type=\"button\" name=\"youtube-stream-login\" class=\"btn-form form-action-cancel is-focusable youtube-login\"\n                   value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.login_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":26},"end":{"line":11,"column":54}}}))
    + "\">\n        </div>\n        <div id=\"youtube-stream-logout-btn-container\" class=\"row settings-list-secundary youtube-stream-logout-btn-container\"  data-nav-area=\"btn-switch, .focusable, .input, select, .is-focusable:not(:disabled)\" data-nav-trapped>\n            <div class=\"youtube-stream-logout-state\">\n                <span class=\"youtube-logging-out\">\n                    <div class=\"loader small\"></div>\n                 </span>\n            </div>\n            <input id=\"youtube-logout\" type=\"button\" name=\"youtube-stream-logout\" class=\"btn-form form-action-cancel is-focusable youtube-logout\"\n                   value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.logout_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":26},"end":{"line":20,"column":55}}}))
    + "\">\n        </div>\n        <div id=\"youtube-stream-event-container\">\n            <div class=\"row settings-list-secundary\"\n                 data-nav-area=\".focusable:not(:disabled), .is-focusable:not([disabled]), .input:not([disabled]), .btn-switch\"\n                 data-nav-trapped>\n                <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFullsize") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":26,"column":28},"end":{"line":26,"column":80}}})) != null ? stack1 : "")
    + "\">\n                    <div class=\"input-group\">\n                        <label class=\"input-label\" for=\"youtubeTitle\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.event_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":70},"end":{"line":28,"column":99}}}))
    + "</label>\n                        <input type=\"text\"\n                               class=\"input\"\n                               name=\"youtubeTitle\"\n                               id=\"youtubeTitle\"\n                               data-validation=\"checkMinMaxLength\"\n                               data-min-length=\"1\"\n                               data-max-length=\"100\" />\n                        <div class=\"input-info\">\n                            <span class=\"input-info-message error-message\" id=\"name-error\">\n                                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"settings.invalid_length",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":32},"end":{"line":38,"column":63}}}))
    + "\n                            </span>\n                        </div>\n                    </div>\n                </div>\n                <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFullsize") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":43,"column":28},"end":{"line":43,"column":80}}})) != null ? stack1 : "")
    + "\">\n                    <div id=\"youtube-privacy-select\"></div>\n                </div>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});
