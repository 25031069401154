const template = require('./quicktool-controls.html');
const vueUtils = require('../../util.js');
const app = require('../../../app');

module.exports = {
    template,

    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        limited: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data: function() {
        return {
            frozen: false,
            annotationButtonEnabled: true,
            freezeButtonEnabled: true,
            snapshotButtonEnabled: true,
            isDualProjection: false
        };
    },

    methods: {
        /**
         * Execute Wolfprot command to take a snapshot
         */
        takeSnapshot: function() {
            if (this.snapshotButtonEnabled) {
                this.wolfprot.talk('setSnapshot').then(function() {
                    app.emit('main-loop.fast.start', {
                        id: 'osd'
                    });
                });
            }
        },

        /**
         * Execute Wolfprot command to toggle main freeze
         */
        toggleFreeze: function() {
            this.pollHelper.sync(function() {
                return this.wolfprot.talk('setMainFreeze', {
                    freeze: 'toggle'
                });
            }.bind(this));
        },

        /**
         * Start annotation mode
         */
        startAnnotation: function() {
            this.annotationService.toggleState();
            app.emit('control-center.close');
        }
    },

    created: function() {
        this.annotationService = app.getService('AnnotationService');
        this.customUiSettings = app.getService('CustomUiSettings');
        this.deviceService = app.getService('DeviceService');
        this.isDualProjection = this.deviceService.isCboxProDualProjection();

        this.wolfprot = vueUtils.wolfprot();
        this.pollHelper = vueUtils.pollHelper({
            load: function() {
                return Promise.allSettled([
                    this.isDualProjection ? Promise.reject() : this.wolfprot.talk('getMainFreeze'),
                    this.customUiSettings.getSettings(['annotationEnabled']),
                    this.customUiSettings.getSettings(['freezeEnabled']),
                    this.customUiSettings.getSettings(['snapshotEnabled'])
                ]).then(([mainFreeze, annotationEnabled, freezeEnabled, snapshotEnabled]) => {
                    return {
                        mainFreeze: mainFreeze.value,
                        annotationEnabled: annotationEnabled.value,
                        freezeEnabled: freezeEnabled.value,
                        snapshotEnabled: snapshotEnabled.value
                    };
                });
            }.bind(this)
        });
        this.pollHelper.on('data', function({ mainFreeze, annotationEnabled, freezeEnabled, snapshotEnabled }) {
            this.annotationButtonEnabled = annotationEnabled
                && !this.deviceService.isCboxPureReceiver() && !this.deviceService.isCboxPureMini();
            this.freezeButtonEnabled = freezeEnabled
                && !this.deviceService.isCboxPure() && !this.deviceService.isCboxPureMini()
                && !this.deviceService.isCboxProDualProjection();
            this.snapshotButtonEnabled = snapshotEnabled
                && !this.deviceService.isCboxPure() && !this.deviceService.isCboxPureMini();
            this.frozen = mainFreeze?.freeze === 1;
        }.bind(this));
        this.pollHelper.schedulePoll();

        this.evctx = vueUtils.eventContext();
        this.evctx.on('remote.snapshot', this.takeSnapshot);
        this.evctx.on('main-loop.update', function() {
            this.pollHelper.schedulePoll();
        }.bind(this));
    },

    destroyed: function() {
        this.evctx.close();
    },

    components: {
        'command-button': require('../command-button/command-button.js')
    }
};
