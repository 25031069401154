'use strict';

var _ = require('lodash');
var app = require('../../app');
var tpl = require('./osd-extend-vmeeting.hbs');

app.component('VMeetingOSDMessage', {
    template: tpl,

    getAccessKey: function() {
        return {
            'roleName': 'OSDMessage',
            'roleKey': 'show'
        };
    },

    initialize: function() {
        this.sessionMgmtService = this.getService('SessionManagementService');
    },

    serialize: function() {
        return {
            timeframes: this.sessionMgmtService.timeframes,
            hasTimeframes: this.sessionMgmtService.hasValidTimeframes
        };
    },

    postPlaceAt: function() {
        this.storeSelectors();
        this.bindEvents();
        this.bindDOMEvents();

        this.updateTimeframeButtons(this.sessionMgmtService.timeframes);
    },

    storeSelectors: function() {
        this.$extendContainer = this.$el.find('#osd-extend-container');
    },

    bindEvents: function() {
        this.on('session-mgmt.update-timeframes', this.updateTimeframeButtons.bind(this));
    },

    bindDOMEvents: function() {
        this.$el.on('click', '.clickable:not(.is-disabled)', this.onAction.bind(this));
    },

    onAction: function(event) {
        var $el = this.$(event.currentTarget);
        var action = $el.data('action');

        switch (action) {
            case 'ignore':
                this.deviceConnection
                    .send('setVMeetingDeadlineEnable', {
                        enabled: false
                    })
                    .then(function() {
                        this.emit('osd-message-interactive.hide', {
                            id: 'vmeeting'
                        });
                    }.bind(this));
                break;
            case 'extend':
                this.$extendContainer
                    .stop()
                    .slideDown(200)
                    .removeClass('hidden');
                break;
            case 'choose-timeframe':
                this.deviceConnection
                    .send('setVMeetingExtendSession', {
                        minutes: $el.data('minutes')
                    })
                    .then(function() {
                        this.emit('osd-message-interactive.hide', {
                            id: 'vmeeting'
                        });
                    }.bind(this));
                break;
        }
    },

    /**
     * Update timeframe buttons if something changes (show/hide).
     *
     * @param timeframes
     */
    updateTimeframeButtons: function(timeframes) {
        var $btn;

        if (!this.sessionMgmtService.hasValidTimeframes) {
            this.$el.find('#osd-btn-extend').addClass('is-disabled');
            this.$extendContainer.addClass('hidden');
        } else {
            this.$el.find('#osd-btn-extend').removeClass('is-disabled');
        }

        _.each(timeframes, function(frame) {
            $btn = this.$el.find('#osd-btn-timeframe-' + frame.min);
            $btn.removeClass('last');

            if (frame.valid) {
                $btn.removeClass('hidden');
            } else {
                $btn.addClass('hidden');
            }
        }.bind(this));

        this.$el.find('.osd-action-btn:not(.hidden)').last().addClass('last');
    }
});
