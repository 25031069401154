'use strict';

var DATE_FORMATS = {
    'h24': 'DDMMYYYY/24H',
    'h12': 'MMDDYYYY/12H'
};

/**
 * Add 0 to a number if this number has only one character.
 *
 * @param {string} string
 * @param {string} numberSigns
 * @param {string} paddingString
 *
 * @returns {string}
 */
var padString = function(string, numberSigns, paddingString) {
    while (string.length < numberSigns) {
        string = paddingString + string;
    }

    return string;
};

/**
 * @param {string} dateFormat: the date format 'MMDDYYYY/12H' or 'DDMMYYYY/24H'
 * @param {object} date: the date
 *
 * @return: {string} the correct formatted date
 */
var formatDate = function(dateFormat, date) {
    var dateString;

    if (date instanceof Date) {
        const tmpDate = date;

        date = {};
        date.month = tmpDate.getMonth() + 1; // Zero based (0 == January)
        date.day = tmpDate.getDate();
        date.year = tmpDate.getFullYear();
    }

    switch (dateFormat) {
        case DATE_FORMATS.h12:
            dateString
                = padString(String(date.month), 2, '0') + '/'
                + padString(String(date.day), 2, '0') + '/'
                + padString(String(date.year), 4, '0');
            break;
        default:
            dateString
                = padString(String(date.day), 2, '0') + '.'
                + padString(String(date.month), 2, '0') + '.'
                + padString(String(date.year), 4, '0');
            break;
    }

    return dateString;
};

/**
 * @param {string} dateFormat: the date format 'MMDDYYYY/12H' or 'DDMMYYYY/24H'
 * @param {object} time: the time
 * @param {boolean} minimal: true use minimal time (only hour, e.g. 9 pm instead of 09:00 pm)
 *
 * @return: {string} the correct formatted
 */
var formatTime = function(dateFormat, time, minimal) {
    var timeString;

    switch (dateFormat) {
        case DATE_FORMATS.h12:
            if (minimal) {
                timeString
                    = String((time.hour + 11) % 12 + 1) + ' '
                    + (time.hour >= 12 ? 'PM' : 'AM');
            } else {
                timeString
                    = padString(String((time.hour + 11) % 12 + 1), 2, '0') + ':'
                    + padString(String(time.minute), 2, '0') + ' '
                    + (time.hour >= 12 ? 'PM' : 'AM');
            }
            break;
        default:
            timeString
                = padString(String(time.hour), 2, '0') + ':'
                + padString(String(time.minute), 2, '0');
            break;
    }

    return timeString;
};

/**
 * @param {string} dateFormat: the date format 'MMDDYYYY/12H' or 'DDMMYYYY/24H'
 * @param {string} dateString: string containing the date
 *
 * @return: {object} the date
 */
var parseDate = function(dateFormat, dateString) {
    var date = {};
    var temp = dateString.match(/(\d+)/g);

    if (temp.length >= 3) {
        if (dateFormat === 'MMDDYYYY/12H') {
            date.month = temp[0];
            date.day = temp[1];
            date.year = temp[2];
        } else {
            date.day = temp[0];
            date.month = temp[1];
            date.year = temp[2];
        }

        if (date.day > 31) {
            date.day = 31;
        } else if (date.day < 1) {
            date.day = 1;
        }

        if (date.month > 12) {
            date.month = 12;
        } else if (date.month < 1) {
            date.month = 1;
        }

        if (date.year > 2099) {
            date.year = 2099;
        } else if (date.year < 2000) {
            date.year = 2000;
        }
    } else {
        date.year = 2000;
        date.month = 1;
        date.day = 1;
    }

    return date;
};

/**
 * @param {string} dateFormat: the date format 'MMDDYYYY/12H' or 'DDMMYYYY/24H'
 * @param {string} timeString: string containing the time
 *
 * @return: {object} the time as object
 */
var parseTime = function(dateFormat, timeString) {
    var time = {};
    var temp = timeString.match(/(\d+)/g);

    temp[0] = parseInt(temp[0]);
    temp[1] = parseInt(temp[1]);

    if (dateFormat === 'MMDDYYYY/12H') {
        if (temp.length >= 2) {
            if (temp[0] >= 12) {
                temp[0] = 0;
            }

            if (temp[1] > 59) {
                temp[1] = 59;
            }

            if (timeString.toLowerCase().indexOf('pm') !== -1) {
                temp[0] += 12;
            }

            time.hour = temp[0];
            time.minute = temp[1];
        } else {
            time.hour = 0;
            time.minute = 0;
        }
    } else if (temp.length >= 2) {
        if (temp[0] >= 24) {
            temp[0] = 0;
        }

        if (temp[1] > 59) {
            temp[1] = 59;
        }

        time.hour = temp[0];
        time.minute = temp[1];
    } else {
        time.hour = 0;
        time.minute = 0;
    }

    return time;
};

/**
 * @param dateTime1
 * @param dateTime2
 * @returns {boolean}
 */
var equalDates = function(dateTime1, dateTime2) {
    if ((!dateTime1 && dateTime2) || (dateTime1 && !dateTime2)) {
        return false;
    }

    return dateTime1.year === dateTime2.year
        && dateTime1.month === dateTime2.month
        && dateTime1.day === dateTime2.day;
};

var convertToUnixTimestamp = function(dateTime) {
    return Date.UTC(
        dateTime.year,
        Math.abs(dateTime.month - 1) % 12,
        dateTime.day,
        dateTime.hour,
        dateTime.minute, 0) / 1000;
};

module.exports = {
    DATE_FORMATS: DATE_FORMATS,
    padString: padString,
    formatDate: formatDate,
    formatTime: formatTime,
    parseDate: parseDate,
    parseTime: parseTime,
    equalDates: equalDates,
    convertToUnixTimestamp: convertToUnixTimestamp
};
