'use strict';

var app = require('../app');
var StateMachine = require('./../state-machine');

var updownloadStates = {
    closed: 'closed',
    opened: 'opened'
};

app.service('UpDownloadService', function(app) {
    return {
        /**
         * @method initialize
         */
        initialize: function() {
            this.downloadService = app.getService('DownloadsService');
            this.uploadService = app.getService('UploadService');

            this.state = new StateMachine({
                context: this,
                state: updownloadStates.closed,
                states: updownloadStates
            });

            this.addStateTransitions();
        },

        /**
         * Checks all Downloads and Upload for visibility
         *
         * @returns {boolean}
         */
        hasUpDownloads: function() {
            var uploads = this.uploadService.countUploadList()
                + this.uploadService.countUploadFailed()
                + this.uploadService.countUploadedList();
            var downloads = this.downloadService.countDownloadList()
                + this.downloadService.countDownloadedFailed()
                + this.downloadService.countDownloadedList();

            return (uploads > 0 || downloads > 0);
        },

        toggleState: function() {
            if (this.state.getState() === updownloadStates.opened) {
                this.state.changeState(updownloadStates.closed);
            } else {
                this.state.changeState(updownloadStates.opened);
            }
        },

        addStateTransitions: function() {
            this.state.addTransitions({
                '> opened': function() {
                    app.emit('overlay.close');
                    app.emit('modal.close');

                    app.createComponent('UpDownloads', {
                        type: 'UpDownloads',
                        container: '#submenu-container'
                    });
                },
                '> closed': function() {
                    app.removeComponent('UpDownloads', {
                        fadeOut: true
                    });
                }
            });
        }
    };
});
