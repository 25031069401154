'use strict';

var app = require('../../../app');
var previewTpl = require('./preview.hbs');
const helper = require('../../../helper');

app.component('ScreenSharePreview', {
    template: previewTpl,
    $canvas: null,
    context: null,

    getAccessKey: function() {
        return {
            'roleName': 'allAccess',
            'roleKey': 'show'
        };
    },

    initialize: function(options) {
        this.frameBoxService = this.getService('FrameBoxService');

        this.options = options;
    },

    postPlaceAt: function() {
        this.bindEvents();
        this.storeSelectors();

        if (this.options.index >= 0 && this.frameBoxService.isFullscreenOpen()) {
            this.$canvas.hide();
            this.$icon.show();
        } else {
            this.$icon.hide();
            this.$canvas.show();
        }
    },

    serialize: function() {
        return {
            icon: this.options.icon
        };
    },

    storeSelectors: function() {
        this.$canvas = this.$el.find('.preview');
        this.$icon = this.$el.find('.screen-share-icon');
    },

    bindEvents: function() {
        this.on('screen-share.preview.update', this.showPreview.bind(this));
    },

    /**
     * Show Preview from specific framebox.
     */
    showPreview: function(data) {
        var width = this.$el.width();
        var height = width / (16 / 9);

        this.$el.height(height);

        this.$canvas.width(width); // 16:9 Aspect Ratio
        this.$canvas.height(height);
        this.$canvas.css('position', 'absolute');

        this.context = this.$canvas.get(0).getContext('2d');
        this.renderImage(data.image, data.reference);
    },

    /**
     * Framebox case: Draw framebox preview cropped from entire cbox image.
     * Desktop case: Draw entire cbox image on canvas.
     */
    renderImage: function(image, reference) {
        if (!image) {
            return;
        }

        if (this.options.coordinates) {
            // Framebox case
            const convertedCoordinates = helper.convertCoordinates(this.options.coordinates, reference, {
                width: image.width,
                height: image.height
            });
            this.draw(image, convertedCoordinates);
        } else {
            // Desktop case
            this.draw(image, {
                x: 0,
                y: 0,
                width: image.width,
                height: image.height
            });
        }
    },

    /**
     * Draw sub-rectangle of the image onto the canvas.
     *
     * If the specified coordinates match the dimensions of the image,
     * the full image is drawn onto the canvas.
     */
    draw: function(image, coordinates) {
        this.context.canvas.width = coordinates.width;
        this.context.canvas.height = coordinates.height;

        this.context.drawImage(image,
            coordinates.x,
            coordinates.y,
            coordinates.width,
            coordinates.height,
            0, 0,
            coordinates.width,
            coordinates.height);
        this.nextFrameLoadedTimeout = 0;
    }
});
